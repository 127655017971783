import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { ApplicationSettingSwitch } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-switch/_index";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const nameOfLookupItem = nameOfFactory<ILookupItem>();

export type IRegistersNonComplainceFormProps = {
  formRenderProps: FormRenderProps;
};

export const RegistersNonComplainceForm = observer(
  ({ formRenderProps }: IRegistersNonComplainceFormProps) => {
    const { configData, getConfigDataField } = useSettingPPRStore();
    const { valueGetter, onChange } = formRenderProps;
    const { isEditing } = useSettingPPRManageStore();

    const fieldSendEmailOnNCDue = getConfigDataField(
      ECorporateSettingsField.TP_SendEmailOnNCDue
    );
    const fieldNCDueActualDays = getConfigDataField(
      ECorporateSettingsField.TP_NCDueActualDays
    );
    const fieldEnableAllocateToPlanner_NC = getConfigDataField(
      ECorporateSettingsField.TP_EnableAllocateToPlanner_NC
    );
    const fieldSendAllocationEmailToPlanner_NC = getConfigDataField(
      ECorporateSettingsField.TP_SendAllocationEmailToPlanner_NC
    );
    const fieldSendRequesterEmailWhenAllocatePlanner_NC = getConfigDataField(
      ECorporateSettingsField.TP_SendRequesterEmailWhenAllocatePlanner_NC
    );
    const fieldNotifyAdminByCCWhenAllocatedToRequestor_NC = getConfigDataField(
      ECorporateSettingsField.TP_NotifyAdminByCCWhenAllocatedToRequestor_NC
    );
    const fieldEnableNCComplitionCheckList = getConfigDataField(
      ECorporateSettingsField.TP_Enable_NC_Complition_CheckList
    );

    const fieldNCProductList = getConfigDataField(
      ECorporateSettingsField.TP_NC_ProductList
    );

    const fieldNCProductListData: string = formRenderProps.valueGetter(
      ECorporateSettingsField.TP_NC_ProductList.toString()
    );

    const productListValue = useMemo(() => {
      let retVal = false;
      if (fieldNCProductListData) {
        const products: string[] = fieldNCProductListData.split("|");
        products.forEach((product) => {
          if (
            parseInt(product) ===
            parseInt(PRODUCT_TYPE_NUMBER.Building.toString())
          ) {
            retVal = true;
          }
        });
      }
      return retVal;
    }, [fieldNCProductListData]);

    const fieldMenu_Label_NonCompliance = getConfigDataField(
      ECorporateSettingsField.TP_Menu_Label_NonCompliance
    );
    const fieldNC_IsDateofOffenceMandatory = getConfigDataField(
      ECorporateSettingsField.TP_NC_IsDateofOffenceMandatory
    );
    const fieldNC_IsDueDateMandatory = getConfigDataField(
      ECorporateSettingsField.TP_NC_IsDueDateMandatory
    );
    const fieldNC_DefaultIPRole = getConfigDataField(
      ECorporateSettingsField.TP_NC_DefaultIPRole
    );
    const fieldDefaultIPCategory = getConfigDataField(
      ECorporateSettingsField.TP_DefaultIPCategory
    );
    const labelMandatoryField = configData
      ? configData["MandatoryFields"]
      : undefined;

    const handleOnProductListChange = (event: SwitchChangeEvent) => {
      if (isNil(fieldNCProductListData) || !fieldNCProductList) return;
      const productListArray: string[] = fieldNCProductListData
        .split("|")
        .filter((product: string) => !isEmpty(product));
      if (event.value) {
        productListArray.push(PRODUCT_TYPE_NUMBER.Building.toString());
        onChange(fieldNCProductList.FieldName, {
          value: `|${productListArray.join("|")}`,
        });
        onChange(`_option.${fieldNCProductList.FieldName}`, {
          value: event.value,
        });
      } else {
        onChange(fieldNCProductList.FieldName, {
          value: `|${productListArray
            .filter(
              (product: string) =>
                product !== PRODUCT_TYPE_NUMBER.Building.toString()
            )
            .join("|")}`,
        });
        onChange(`_option.${fieldNCProductList.FieldName}`, {
          value: event.value,
        });
      }
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {fieldSendEmailOnNCDue && (
            <div className="cc-field">
              <CCLabel title={fieldSendEmailOnNCDue?.Title ?? ""} />
              <Field
                name={fieldSendEmailOnNCDue.FieldName}
                component={CCSwitch}
                checked={valueGetter(fieldSendEmailOnNCDue.FieldName)}
                disabled={!isEditing}
              />
            </div>
          )}
          {fieldEnableAllocateToPlanner_NC && (
            <div className="cc-field">
              <CCLabel title={fieldEnableAllocateToPlanner_NC?.Title ?? ""} />
              <Field
                name={fieldEnableAllocateToPlanner_NC.FieldName}
                component={CCSwitch}
                checked={valueGetter(fieldEnableAllocateToPlanner_NC.FieldName)}
                disabled={!isEditing}
              />
            </div>
          )}
        </div>
        {fieldNCDueActualDays && (
          <div className="cc-field">
            <CCLabel title={fieldNCDueActualDays?.Title ?? ""} />
            {fieldNCDueActualDays.Description && (
              <label className="cc-settings-description">
                {sanitizeHtml(fieldNCDueActualDays.Description ?? "")}
              </label>
            )}
            <Field
              name={fieldNCDueActualDays.FieldName}
              component={CCNumericTextBox}
              disabled={!isEditing}
            />
          </div>
        )}
        <div className="cc-form-cols-3">
          {fieldEnableAllocateToPlanner_NC &&
            valueGetter(fieldEnableAllocateToPlanner_NC.FieldName) && (
              <>
                {fieldSendAllocationEmailToPlanner_NC && (
                  <div className="cc-field">
                    <CCLabel
                      title={fieldSendAllocationEmailToPlanner_NC?.Title ?? ""}
                    />
                    <Field
                      name={fieldSendAllocationEmailToPlanner_NC.FieldName}
                      component={CCSwitch}
                      checked={valueGetter(
                        fieldSendAllocationEmailToPlanner_NC.FieldName
                      )}
                      disabled={!isEditing}
                    />
                  </div>
                )}
                {fieldSendRequesterEmailWhenAllocatePlanner_NC && (
                  <div className="cc-field">
                    <CCLabel
                      title={
                        fieldSendRequesterEmailWhenAllocatePlanner_NC?.Title ??
                        ""
                      }
                    />
                    <Field
                      name={
                        fieldSendRequesterEmailWhenAllocatePlanner_NC.FieldName
                      }
                      component={CCSwitch}
                      checked={valueGetter(
                        fieldSendRequesterEmailWhenAllocatePlanner_NC.FieldName
                      )}
                      disabled={!isEditing}
                    />
                  </div>
                )}
                {fieldNotifyAdminByCCWhenAllocatedToRequestor_NC && (
                  <div className="cc-field">
                    <CCLabel
                      title={
                        fieldNotifyAdminByCCWhenAllocatedToRequestor_NC?.Title ??
                        ""
                      }
                      isMandatory={
                        fieldNotifyAdminByCCWhenAllocatedToRequestor_NC.IsMandatory
                      }
                    />
                    <Field
                      name={
                        fieldNotifyAdminByCCWhenAllocatedToRequestor_NC.FieldName
                      }
                      component={CCInput}
                      disabled={!isEditing}
                      validator={
                        fieldNotifyAdminByCCWhenAllocatedToRequestor_NC.IsMandatory
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                )}
              </>
            )}

          {fieldEnableNCComplitionCheckList && (
            <div className="cc-field">
              <CCLabel title={fieldEnableNCComplitionCheckList?.Title ?? ""} />
              <Field
                name={fieldEnableNCComplitionCheckList.FieldName}
                component={CCSwitch}
                checked={valueGetter(
                  fieldEnableNCComplitionCheckList.FieldName
                )}
                disabled={!isEditing}
              />
            </div>
          )}
          {fieldNCProductList && (
            <div className="cc-field">
              <CCLabel title={fieldNCProductList.Title ?? ""} />
              <Field
                name={`_option.${fieldNCProductList.FieldName}`}
                component={CCSwitch}
                checked={productListValue}
                disabled={!isEditing}
                onChange={handleOnProductListChange}
              />
            </div>
          )}
          {fieldMenu_Label_NonCompliance && (
            <div className="cc-field">
              <CCLabel
                title={fieldMenu_Label_NonCompliance?.Title ?? ""}
                isMandatory={fieldMenu_Label_NonCompliance.IsMandatory}
              />
              <Field
                name={fieldMenu_Label_NonCompliance.FieldName}
                component={CCInput}
                disabled={!isEditing}
                validator={
                  fieldMenu_Label_NonCompliance.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_EnableAssessmentRiskMatrix}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_EnableAssessmentRiskMatrix}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_EnableAutoCalculateNCDueDate}
            formRenderProps={formRenderProps}
          />
        </div>
        {labelMandatoryField && (
          <div>
            <label className="cc-label">{labelMandatoryField?.Value}</label>
            <div className="cc-custom-sub-panel-bar">
              <div className="cc-form-cols-3">
                {fieldNC_IsDateofOffenceMandatory && (
                  <div className="cc-field">
                    <CCLabel
                      title={fieldNC_IsDateofOffenceMandatory?.Title ?? ""}
                    />
                    <Field
                      name={fieldNC_IsDateofOffenceMandatory.FieldName}
                      component={CCSwitch}
                      checked={valueGetter(
                        fieldNC_IsDateofOffenceMandatory.FieldName
                      )}
                      disabled={!isEditing}
                    />
                  </div>
                )}
                {fieldNC_IsDueDateMandatory && (
                  <div className="cc-field">
                    <CCLabel title={fieldNC_IsDueDateMandatory?.Title ?? ""} />
                    <Field
                      name={fieldNC_IsDueDateMandatory.FieldName}
                      component={CCSwitch}
                      checked={valueGetter(
                        fieldNC_IsDueDateMandatory.FieldName
                      )}
                      disabled={!isEditing}
                    />
                  </div>
                )}

                {fieldNC_DefaultIPRole && (
                  <div className="cc-field">
                    <CCLabel
                      title={fieldNC_DefaultIPRole.Title ?? ""}
                      isMandatory={fieldNC_DefaultIPRole.IsMandatory}
                    />
                    <Field
                      name={fieldNC_DefaultIPRole?.FieldName}
                      component={CCSearchComboBox}
                      data={fieldNC_DefaultIPRole?.LookupItems}
                      textField={nameOfLookupItem("Name")}
                      dataItemKey={nameOfLookupItem("Id")}
                      disabled={!isEditing}
                      isUseDefaultOnchange
                      validator={
                        fieldNC_DefaultIPRole.IsMandatory
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                )}
                {fieldDefaultIPCategory && (
                  <div className="cc-field">
                    <CCLabel
                      title={fieldDefaultIPCategory.Title ?? ""}
                      isMandatory={fieldDefaultIPCategory.IsMandatory}
                    />
                    <Field
                      name={fieldDefaultIPCategory?.FieldName}
                      component={CCSearchComboBox}
                      data={fieldDefaultIPCategory?.LookupItems}
                      textField={nameOfLookupItem("Name")}
                      dataItemKey={nameOfLookupItem("Id")}
                      disabled={!isEditing}
                      isUseDefaultOnchange
                      validator={
                        fieldDefaultIPCategory.IsMandatory
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
);
