import { IExternalGisMenuItems } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/modules/forms/launch-external-gis/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React from "react";

interface IAddExternalGisItemsDialogProps {
  onClose: () => void;
  onAdd: (data: IExternalGisMenuItems) => void;
}

const nameOf = nameOfFactory<IExternalGisMenuItems>();

const urlValidator = (value: string) => {
  if (!value) {
    return "URL is required.";
  }
  if (!/^https?:\/\//.test(value)) {
    return "URL must start with 'https://' or 'http://'.";
  }
  return "";
};

export const AddExternalGisItemsDialog = ({
  onClose,
  onAdd,
}: IAddExternalGisItemsDialogProps) => {
  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const data = event.values as IExternalGisMenuItems;
    const submitData = {
      ID: 0,
      Name: data?.Name ?? "",
      URL: data?.URL?.replaceAll(" ", "") ?? "",
    } as IExternalGisMenuItems;
    onAdd(submitData);
    onClose();
  };

  return (
    <Form
      onSubmitClick={handleOnSubmit}
      render={(formRenderProps: FormRenderProps) => {
        const { valid, onSubmit } = formRenderProps;

        return (
          <CCDialog
            maxWidth="30%"
            height={"auto"}
            titleHeader="Add External GIS Menu Items"
            onClose={onClose}
            bodyElement={
              <FormElement className="cc-form">
                <div className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <CCLabel title="Name" isMandatory />
                      <Field
                        name={nameOf("Name")}
                        component={CCInput}
                        placeholder="Name"
                        validator={requiredValidator}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="URL" isMandatory />
                      <Field
                        name={nameOf("URL")}
                        component={CCTextArea}
                        rows={3}
                        placeholder="URL"
                        validator={[requiredValidator, urlValidator]}
                      />
                    </div>
                  </div>
                </div>
              </FormElement>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  themeColor="primary"
                  disabled={!valid}
                  onClick={onSubmit}
                  className="cc-dialog-button"
                >
                  Save
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
