import { Svc_Contact } from "@app/core/contacts/_id/model";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { Keyword, Keyword_Tags } from "@common/models/keyword";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export interface ILocalGovernment {
  ID: number;
  Name: string;
  FSRCode: string;
  BVO_CounciCode: number | null;
  HM_CouncilPrefix: string;
  LGA: string;
  Region: string;
  State: string;
  CouncilAddress: string;
  CouncilPostalAddress: string;
  Officer: string;
  OfficerEmail: string;
  Website: string;
}

export interface CouncilTabDetails {
  Council_ID: number;
  CouncilName?: string;
  ContactName?: string;
  CouncilDetails?: string;
  Officer?: string;
  CreatedDate: Date;
  CreatedBy: string;
}

export interface Svc_Contact_Relation {
  RID: number;
  ContactRelationshipType_ENUM: string;
  ContactRelationshipType_Name: string;
  Contact: Svc_Contact;
  DisplayName: string;
  BubbleUps: BubbleUpIdentifier[];
  _ExtensionType: string[];
  ExtensionType: string[];
  Extension?: string;
  PersonRole_KWD: number | null;
  PersonRole: Keyword;
  RelationshipComment: string;
  _KeywordTags: Keyword_Tags[];
  KeywordTags: Keyword_Tags[];
  SaveTriggers: string[];
}

export enum LocalGovernmentSubmitActions {
  Save = "Save",
  New = "New",
  Delete = "Delete",
}

export enum ApplicationUpdateTriggers {
  UpdateCouncilOfficer,
  UpdateCouncilDetails,
}

export interface LocalGovernment extends DTOBaseClass_Standard {
  Council_ID: number;
  CouncilName: string;
  Council_RID: number | null;
  CouncilDetails: Svc_Contact_Relation;
  Officer_RID: number | null;
  Officer: Svc_Contact_Relation;
  SaveTriggers: ApplicationUpdateTriggers[];
}

export interface ILocalGovernmentParentSection {
  notification: IAppNotificationItemAddProps[];
}
