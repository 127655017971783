import { history } from "@/AppRoutes";
import {
  getBuildingRegistersEnquiryById,
  postBuildingRegistersEnquiry,
  putBuildingRegistersEnquiry,
} from "@app/products/building/registers/enquiries/[id]/api";
import { ENQUIRIES_MANAGE_ROUTE } from "@app/products/building/registers/enquiries/[id]/constant";
import {
  BuildingRegistersEnquiryRecordsState,
  IBuildingRegistersEnquiryFormData,
} from "@app/products/building/registers/enquiries/[id]/model";
import { IBuilding } from "@app/products/building/[id]/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class BuildingRegistersEnquiryStore {
  private _buildingRegistersEnquiry?: IBuildingRegistersEnquiryFormData =
    undefined;
  private _buildingRegistersEnquiryRecords?: BuildingRegistersEnquiryRecordsState =
    undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get buildingRegistersEnquiry() {
    return toJS(this._buildingRegistersEnquiry);
  }
  setBuildingRegistersEnquiry = (
    buildingRegistersEnquiry?: IBuildingRegistersEnquiryFormData
  ) => {
    runInAction(() => {
      this._buildingRegistersEnquiry = buildingRegistersEnquiry;
    });
  };

  get buildingRegistersEnquiryRecords() {
    return toJS(this._buildingRegistersEnquiryRecords);
  }
  setBuildingRegistersEnquiryRecords = (
    buildingRegistersEnquiryRecords?: BuildingRegistersEnquiryRecordsState
  ) => {
    runInAction(() => {
      this._buildingRegistersEnquiryRecords = buildingRegistersEnquiryRecords;
    });
  };

  get buildingRegistersEnquiryId() {
    return toJS(this.buildingRegistersEnquiry?.ID);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._buildingRegistersEnquiry = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadBuildingRegistersEnquiry = async (buildingRegistersEnquiryId: number) => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    const response = await getBuildingRegistersEnquiryById(
      buildingRegistersEnquiryId
    );
    if (Array.isArray(response)) {
      const [buildingRegistersEnquiry, buildingRegistersEnquiryRecords] =
        response;
      this.setBuildingRegistersEnquiry(buildingRegistersEnquiry.data);
      this.setBuildingRegistersEnquiryRecords(
        buildingRegistersEnquiryRecords.data
      );
    } else {
      const responseError = response as APIResponse;
      errorResponse = {
        status: responseError.status,
        error: responseError.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  createBuildingRegistersEnquiry = async (
    buildingRegistersEnquiry: IBuilding
  ) => {
    this.setIsLoading(true);
    await postBuildingRegistersEnquiry(buildingRegistersEnquiry);
    appNotificationStore.pushNotification({
      title: "Building registers enquiry created successfully",
      type: "success",
      onClose: () => {
        history.replace(
          `${ENQUIRIES_MANAGE_ROUTE}/${buildingRegistersEnquiry?.ID ?? 1}`
        );
      },
    });
    this.setIsLoading(false);
  };

  updateBuildingRegistersEnquiry = async (
    buildingRegistersEnquiry: IBuilding
  ) => {
    this.setIsLoading(true);
    await putBuildingRegistersEnquiry(buildingRegistersEnquiry);
    appNotificationStore.pushNotification({
      title: "Building registers enquiry updated successfully",
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const buildingRegistersEnquiryStoreContext = createContext(
  new BuildingRegistersEnquiryStore()
);
export const useBuildingRegistersEnquiryStore = () => {
  return useContext(buildingRegistersEnquiryStoreContext);
};
