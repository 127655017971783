import { DTO_LOV } from "@common/models/odataResponse";

export const mockResultViewData: DTO_LOV[] = [
  {
    Code: "1",
    Name: "Arrangement",
  },
  {
    Code: "2",
    Name: "Arrangement - Active",
  },
  {
    Code: "3",
    Name: "Arrangement - All",
  },
  {
    Code: "4",
    Name: "Arrangement - Instalment Due",
  },
  {
    Code: "5",
    Name: "Arrangement - Letters Produced",
  },
  {
    Code: "6",
    Name: "Assessment (Non Financial)",
  },
  {
    Code: "7",
    Name: "Assessment (with Balances)",
  },
  {
    Code: "8",
    Name: "Assessment Balance Summary",
  },
  {
    Code: "9",
    Name: "Assessment Category",
  },
  {
    Code: "10",
    Name: "Assessment Compliance",
  },
  {
    Code: "11",
    Name: "Assessment Debt Recovery",
  },
  {
    Code: "12",
    Name: "Assessment Debt Recovery Exemption",
  },
  {
    Code: "13",
    Name: "Assessment Land Tax Exemption",
  },
  {
    Code: "14",
    Name: "Assessment Last Accessed",
  },
  {
    Code: "15",
    Name: "Assessment Levies",
  },
  {
    Code: "16",
    Name: "Assessment Mailbox List",
  },
  {
    Code: "17",
    Name: "Assessment Memos",
  },
  {
    Code: "18",
    Name: "Assessment Name&Address",
  },
  {
    Code: "19",
    Name: "Assessment New Years Eve Passes",
  },
  {
    Code: "20",
    Name: "Assessment Notices",
  },
  {
    Code: "21",
    Name: "Assessment Pensioner Records",
  },
  {
    Code: "22",
    Name: "Assessment Rebate Entitlement",
  },
  {
    Code: "23",
    Name: "Assessment Rebate Entitlements",
  },
  {
    Code: "24",
    Name: "Assessment Valuations",
  },
  {
    Code: "25",
    Name: "Assessment Valuations with Ratepayer ",
  },
  {
    Code: "26",
    Name: "Assessments Missing Concession Cards ",
  },
  {
    Code: "27",
    Name: "Certificate Request Summary ",
  },
  {
    Code: "28",
    Name: "Certificates Last Accessed",
  },
  {
    Code: "29",
    Name: "Certificates Status",
  },
  {
    Code: "30",
    Name: "Certificates Summary",
  },
  {
    Code: "31",
    Name: "Charges",
  },
  {
    Code: "32",
    Name: "Concession Cards",
  },
  {
    Code: "33",
    Name: "Debt Recoveries",
  },
  {
    Code: "34",
    Name: "Debt Recovery Actions",
  },
  {
    Code: "35",
    Name: "Debt Recovery Exemptions",
  },
  {
    Code: "36",
    Name: "Debt Recovery Payments Received",
  },
  {
    Code: "37",
    Name: "Deferred Duty Accounts",
  },
  {
    Code: "38",
    Name: "Development Neighbour Notification",
  },
  {
    Code: "39",
    Name: "Contact Name & Address",
  },
  {
    Code: "40",
    Name: "Financial Summaries",
  },
  {
    Code: "41",
    Name: "Mail Boxes",
  },
  {
    Code: "42",
    Name: "Master Property",
  },
  {
    Code: "43",
    Name: "Meters Last Accessed",
  },
  {
    Code: "44",
    Name: "Outstanding Charges",
  },
  {
    Code: "45",
    Name: "Parcel",
  },
  {
    Code: "46",
    Name: "Parcel Addresses",
  },
  {
    Code: "47",
    Name: "Parcel and GIS Reference",
  },
  {
    Code: "48",
    Name: "Parcel Last Accessed",
  },
  {
    Code: "49",
    Name: "Ratepayer History",
  },
  {
    Code: "50",
    Name: "Rebate Claims",
  },
  {
    Code: "51",
    Name: "Scheme Accounts",
  },
  {
    Code: "52",
    Name: "Supplementary",
  },
  {
    Code: "53",
    Name: "Title",
  },
  {
    Code: "54",
    Name: "Title Last Accessed",
  },
  {
    Code: "55",
    Name: "Transactions",
  },
];
