import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const postIssueRenewalNoticeMarkAsSent = async (
  animalRegistrationIds: number[]
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `/api/animals/internal/renewal/issue-renewal-notice-and-mark-as-sent`,
      animalRegistrationIds
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postResetRenewalFeeFlag = async (
  animalRegistrationIds: number[]
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `/api/animals/internal/renewal/reset-renewal-fee-flag`,
      animalRegistrationIds
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
