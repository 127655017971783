import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class PlansToComplySendLetterToRAandApplicantButtonStore {
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
}
export const plansToComplySendLetterToRAandApplicantButtonStore =
  new PlansToComplySendLetterToRAandApplicantButtonStore();
const plansToComplySendLetterToRAandApplicantButtonContext = createContext(
  plansToComplySendLetterToRAandApplicantButtonStore
);
export const usePlansToComplySendLetterToRAandApplicantButtonStore = () => {
  return useContext(plansToComplySendLetterToRAandApplicantButtonContext);
};
