import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSFeesPaymentPlans: IColumnFields[] = [
  {
    field: CRSFieldMapping.REGISTRATION_AppNo,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.CHILD_NAME, title: "Child Name", width: 150 },
  {
    field: CRSFieldMapping.PARENT1_DEBTORNO,
    title: "Debtor Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.PARENT1_NAMENUMBER,
    title: "Name Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.REGISTRATION_PAYMENTPLAN,
    title: "Payment Plan Description",
  },
  {
    field: CRSFieldMapping.Last_Payment_Date,
    title: "Last Payment Date",
  },
  { field: CRSFieldMapping.Amnt_TotalOwing, title: "Amount Due", width: 150 },
  { field: CRSFieldMapping.CBCRegistrationStatus, title: "CBC", width: 150 },
  { field: CRSFieldMapping.HBCRegistrationStatus, title: "HBC", width: 150 },
  { field: CRSFieldMapping.TYORegistrationStatus, title: "3yo", width: 150 },
  {
    field: CRSFieldMapping.tyo_Year_Attending,
    title: "3yo Year Attending",
  },
  { field: CRSFieldMapping.FYORegistrationStatus, title: "4yo", width: 150 },
  {
    field: CRSFieldMapping.fyo_Year_Attending,
    title: "4yo Year Attending",
  },
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Gender",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: CRSFieldMapping.CHILD_BIRTHDATE,
    title: "Date of Birth",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.CHILD_AGE,
    title: "Age",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.PARENT1_NAME, title: "Parent 1 Name", width: 150 },
  { field: CRSFieldMapping.PARENT2_NAME, title: "Parent 2 Name", width: 150 },
  {
    field: CRSFieldMapping.PARENT1_CONTACTNO,
    title: "Phone",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.Preferred_Contact_Method,
    title: "Preference Method",
  },
];
