import {
  AddressClassification,
  Address_BuildAddress,
} from "@common/input-pickers/address/model";

export const defaultBuildAddress = {
  Filters: {
    ClassificationFilters: [
      AddressClassification.International,
      AddressClassification.Consultant_Address,
      AddressClassification.Generic,
      AddressClassification.POBox,
    ],
  },
} as Address_BuildAddress;
