import { colAssociationsMeter } from "@app/products/property/components/associations/components/meter/config";
import { DTO_AssociatedItem_Meter } from "@app/products/property/components/associations/components/meter/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsMeterProps {
  associationsMeterInfo: DTO_AssociatedItem_Meter[] | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_Meter>();

export const AssociationsMeter = ({
  associationsMeterInfo,
}: IAssociationsMeterProps) => {
  return (
    <CCGrid
      className="cc-meter-grid"
      data={associationsMeterInfo || []}
      columnFields={colAssociationsMeter}
      primaryField={nameOf("Id")}
    />
  );
};
