import { eComponent } from "@app/products/property/components/associations/model";
import { DTO_Collection } from "@app/products/property/components/child-screen/collections/model";
import { useCollectionsStore } from "@app/products/property/components/child-screen/collections/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

const nameOfCollection = nameOfFactory<DTO_Collection>();

export interface IPropertyCollectionsProps {
  componentNumber: eComponent;
  componentId: number;
}

export const PropertyCollections = observer(
  ({ componentNumber, componentId }: IPropertyCollectionsProps) => {
    const {
      isLoading,
      collections,
      responseLoadError,
      colCollection,
      loadCollections,
      resetStore,
    } = useCollectionsStore();

    useEffect(() => {
      if (!isNil(componentNumber) && !isNil(componentId)) {
        loadCollections(componentNumber, componentId);
      }
      return () => {
        resetStore();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentId, componentNumber]);

    if (isLoading) return <Loading isLoading={isLoading} />;

    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadCollections(componentNumber, componentId);
          }}
        />
      );

    return (
      <div className="cc-property-collections cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCGrid
                data={collections ?? []}
                columnFields={colCollection ?? []}
                primaryField={nameOfCollection("CollectionId")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
