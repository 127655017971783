import { FIRE_PREVENTION_ROUTE } from "@app/products/property/fire-prevention/hazld-case/[id]/constant";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colInspections: IColumnFields[] = [
  {
    field: "CaseId",
    title: "Case ID",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => {
      return `${FIRE_PREVENTION_ROUTE}/` + dataItem.CaseId;
    },
    locked: true,
  },
  {
    field: "InspectionDate",
    title: "Inspection Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  { field: "Clearance", title: "Clearance" },
  { field: "Outcome", title: "Outcome" },
  {
    field: "ToBeClearedBy",
    title: "To Be Cleared By",
    format: DATE_FORMAT.DATE,
  },
  { field: "SpecificInstructions", title: "Specific Instructions" },
  { field: "Locality", title: "Locality" },
  {
    field: "HouseNumber",
    title: "House Number",
  },
  { field: "Street", title: "Street" },
  { field: "StreetType", title: "Street Type" },

  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  { field: "Owners", title: "Owners" },
  {
    field: "Period",
    title: "Period",
  },
  {
    field: "InspectorUserId",
    title: "Inspector User ID",
  },
  {
    field: "ParcelId",
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "InspectionId",
    title: "Inspection ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
