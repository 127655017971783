import { ContactRelationshipForm } from "@common/pages/contacts/components/contact-relationship-form/_index";
import { splitCamelCase } from "@common/utils/formatting";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import React from "react";
import { useLocation } from "react-router-dom";

interface IProps {
  setIsLoading: (status: boolean) => void;
}

export const NewContactRelationship = ({ setIsLoading }: IProps) => {
  const location: any = useLocation();
  const title = location.state?.title;
  return (
    <>
      <FormNavigation title={"Contact Relationship"} />
      <FormTitle
        title={"Contact Relationship"}
        badge={splitCamelCase(title || "")}
      />
      <div className="cc-product-action-bar">
        <div className="cc-action-bar-row">
          <label className="cc-label-button" htmlFor={"btn-submit"}>
            Save
          </label>
        </div>
      </div>
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <div className="cc-manage-form-body">
            <CCGeneralPanel
              component={
                <ContactRelationshipForm isNew setIsLoading={setIsLoading} />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
