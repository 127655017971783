import { NoticeRunDetail } from "@app/products/property/schemes/notice-runs/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { formatDate } from "@telerik/kendo-intl";
import React, { useEffect, useState } from "react";

interface IExportSchemeNoticeDialogProps {
  onClose: () => void;
  onSubmit: (values: any) => void;
  NoticeRunInfo: NoticeRunDetail | undefined;
}

export const ExportSchemeNoticeDialog = ({
  onClose,
  onSubmit,
  NoticeRunInfo,
}: IExportSchemeNoticeDialogProps) => {
  const [noticeRunInfo, setNoticeRunInfo] = useState<
    NoticeRunDetail | undefined
  >(NoticeRunInfo);

  useEffect(() => {
    setNoticeRunInfo({
      ...NoticeRunInfo,
      Filename: `${NoticeRunInfo?.NoticeRunName} (${formatDate(
        new Date(NoticeRunInfo?.IssueDate ?? new Date()),
        "ddMMyyyy"
      )}).xml`,
    });
  }, [NoticeRunInfo]);

  return (
    <Form
      key={JSON.stringify(noticeRunInfo)}
      initialValues={noticeRunInfo}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter } = formRenderProps;

        return (
          <CCDialog
            maxWidth="25%"
            height={"auto"}
            titleHeader={"Scheme Notice Output"}
            onClose={onClose}
            bodyElement={
              <>
                <FormElement className="cc-form">
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Notice run</label>
                        <Field
                          component={CCDatePicker}
                          readOnly
                          name={"NoticeRun"}
                          value={`${valueGetter("NoticeRunName")} (${formatDate(
                            new Date(valueGetter("IssueDate")),
                            DATE_FORMAT.DATE_CONTROL
                          )})`}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Filename</label>
                        <Field
                          component={CCInput}
                          name={"Filename"}
                          value={valueGetter("Filename")}
                        />
                      </div>
                    </div>
                  </section>
                </FormElement>
              </>
            }
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  themeColor="primary"
                  id="cc-next-step-button"
                  className={"cc-dialog-button"}
                  onClick={(event) => {
                    onSubmit(valueGetter("Filename"));
                  }}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
