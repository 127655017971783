import { Comments } from "@app/core/comments/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const deleteRebateClaim = async (
  rebateClaimId: number,
  comment: Comments
): Promise<APIResponse<any>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/core/internal/rebate/delete/${rebateClaimId}`,
      comment
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
