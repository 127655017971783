import { useParcelTitleStore } from "@app/products/property/parcels/[id]/components/child-screens/titles/store";
import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";
import { colsParcelAssociatedTitles } from "./config";

export const ParcelTitles = observer(() => {
  const { isLoading, title, loadTitle } = useParcelTitleStore();
  const { parcelId } = useParcelsStore();

  useEffectOnce(() => {
    if (!parcelId) return;
    loadTitle(parcelId);
  });
  return (
    <div className="cc-property-parcel-titles cc-form">
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Associated</label>
            <CCGrid
              isLoading={isLoading}
              className="cc-property-parcel-associated-grid"
              columnFields={colsParcelAssociatedTitles}
              data={title}
              primaryField="TitleId"
            />
          </div>
        </div>

        {/* TODO: No need at the moment */}
        {/* <section className="cc-field-group">
          <div className="cc-field">
            <label className="cc-label">Details</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Legal description</label>
                  <div className="cc-label-field">
                    <CCTextArea
                      className="cc-form-control"
                      rows={1}
                      value={mockTitles.LegalDescription || ""}
                      disabled
                    />
                  </div>
                </div>
                <div className="cc-field">
                  <label className="cc-label">Owners</label>
                  <div className="cc-label-field">
                    <CCTextArea
                      className="cc-form-control"
                      rows={1}
                      value={mockTitles.Owners || ""}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Title ID</label>
                  <div className="cc-label-field">
                    {populateValue(mockTitles.ID)}
                  </div>
                </div>
                <div className="cc-field">
                  <label className="cc-label">Plan type</label>
                  <div className="cc-label-field">
                    {populateValue(mockTitles.PlanType)}
                  </div>
                </div>
                <div className="cc-field">
                  <label className="cc-label">Plan number</label>
                  <div className="cc-label-field">
                    {populateValue(mockTitles.PlanNumber)}
                  </div>
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Lot</label>
                  <div className="cc-label-field">
                    {populateValue(mockTitles.Lot)}
                  </div>
                </div>
                <div className="cc-field">
                  <label className="cc-label">Is part lot</label>
                  <div className="cc-label-field">
                    <Switch value={mockTitles.IsPartLot} disabled />
                  </div>
                </div>
                <div className="cc-field">
                  <label className="cc-label">Volume</label>
                  <div className="cc-label-field">
                    {populateValue(mockTitles.Volume)}
                  </div>
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Folio</label>
                  <div className="cc-label-field">
                    {populateValue(mockTitles.Folio)}
                  </div>
                </div>
                <div className="cc-field">
                  <label className="cc-label">Title status</label>
                  <div className="cc-label-field">
                    {populateValue(mockTitles.TitleStatus)}
                  </div>
                </div>
                <div className="cc-field">
                  <label className="cc-label">Title type</label>
                  <div className="cc-label-field">
                    {populateValue(mockTitles.TitleType)}
                  </div>
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Land area</label>
                  <div className="cc-label-field">
                    {populateValue(mockTitles.LandArea)}
                  </div>
                </div>
                <div className="cc-field">
                  <label className="cc-label">Is area surveyed</label>
                  <div className="cc-label-field">
                    <Switch value={mockTitles.IsAreaSurveyed} disabled />
                  </div>
                </div>
                <div className="cc-field">
                  <label className="cc-label">GIS reference</label>
                  <div className="cc-label-field">
                    {populateValue(mockTitles.GISReference)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </section>
    </div>
  );
});
