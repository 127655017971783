import { colOccurrencesLeakage } from "@app/products/property/meters/[id]/components/child-screens/leakage/config";
import {
  ILeakage,
  IOccurrences,
} from "@app/products/property/meters/[id]/components/child-screens/leakage/model";
import { IColumnFields } from "@components/cc-grid/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import {
  deleteLeakageAPI,
  getMeterLeakage,
  postOccurrenceAPI,
  putOccurrenceAPI,
} from "./api";

configure({ enforceActions: "always" });

class MeterLeakageStore {
  private _leakage: ILeakage = {};
  private _isLoading: boolean = false;
  private _selectedLeakage: IOccurrences[] = [];
  private _gridColumnsOccurrences: IColumnFields[] = colOccurrencesLeakage;
  constructor() {
    makeAutoObservable(this);
  }

  get leakage() {
    return this._leakage;
  }
  get isLoading() {
    return this._isLoading;
  }

  get selectedLeakage() {
    return this._selectedLeakage;
  }
  get gridColumnsOccurrences() {
    return this._gridColumnsOccurrences;
  }

  setLeakage = (leakage: ILeakage) => {
    runInAction(() => {
      this._leakage = leakage;
    });
  };
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  setSelectedLeakage = (selectedLeakage: IOccurrences[]) => {
    runInAction(() => {
      this._selectedLeakage = selectedLeakage;
    });
  };
  setGridColumnsOccurrences = (gridColumnsOccurrences: IColumnFields[]) => {
    runInAction(() => {
      this._gridColumnsOccurrences = gridColumnsOccurrences;
    });
  };

  //Action
  loadLeakage = async () => {
    this.setIsLoading(true);
    const leakage = await getMeterLeakage();
    this.setLeakage(leakage);
    this.setIsLoading(false);
  };

  addOccurrence = async (newOccurrence: IOccurrences) => {
    this.setIsLoading(true);
    const newLeakage = await postOccurrenceAPI(newOccurrence, this.leakage);
    this.setLeakage(newLeakage);
    this.setIsLoading(false);
  };

  editOccurrence = async (editDataOccurrence: IOccurrences) => {
    this.setIsLoading(true);
    if (this.leakage.Occurrences) {
      const newLeakage = await putOccurrenceAPI(
        editDataOccurrence,
        this.leakage
      );

      this.setLeakage(newLeakage);
      this.setSelectedLeakage([]);
      this.setIsLoading(false);
    }
  };

  removeLeakage = async (removeOccurrence?: IOccurrences[]) => {
    this.setIsLoading(true);
    if (removeOccurrence) {
      const newLeakage = await deleteLeakageAPI(removeOccurrence, this.leakage);
      this.setLeakage(newLeakage);
    }
    this.setSelectedLeakage([]);
    this.setIsLoading(false);
  };
}
const meterLeakageStoreContext = createContext(new MeterLeakageStore());
export const useMeterLeakageStore = () => {
  return useContext(meterLeakageStoreContext);
};
