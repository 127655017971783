import { history } from "@/AppRoutes";
import { CRSFormData, CRSRequest } from "@app/products/crs/model";
import { getCRSSummaryById, postCRS, putCRS } from "@app/products/crs/[id]/api";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class CRSStore {
  private _crs?: CRSFormData = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get crs() {
    return this._crs;
  }
  setCRS = (crs?: CRSFormData | undefined) => {
    runInAction(() => {
      this._crs = crs;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get crsId() {
    return this.crs?.ID;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._crs = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadCRS = async (crsId: string) => {
    this.setIsLoading(true);
    const response = await getCRSSummaryById(crsId);
    let errorResponse = undefined;
    let newCRS = undefined;
    if (isSuccessResponse(response)) {
      newCRS = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setCRS(newCRS);
    this.setIsLoading(false);
  };

  createCRS = async (crs: CRSRequest) => {
    this.setIsLoading(true);
    await postCRS(crs);

    appNotificationStore.pushNotification({
      title: "The CRS was created successfully.",
      type: "success",
      onClose: () => {
        history.replace(`${CRS_MANAGE_ROUTE}/${crs?.ID ?? 1}`);
      },
    });
    this.setIsLoading(false);
  };

  updateCRS = async (crs: CRSRequest) => {
    this.setIsLoading(true);
    await putCRS(crs);
    appNotificationStore.pushNotification({
      title: "The CRS was updated successfully.",
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const crsStoreContext = createContext(new CRSStore());
export const useCRSStore = () => {
  return useContext(crsStoreContext);
};
