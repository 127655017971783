import { IExecutionData } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/execution/model";

export const executionData: Array<IExecutionData> = [
  { label: "Run immediately", value: "1" },
  { label: "Schedule to run at", value: "2" },
];

export enum EExecutionOption {
  RunImmediately = "1",
  ScheduleToRunAt = "2",
}
