import { PropertyComplianceGeneral } from "@app/products/property/assessments/compliance/[id]/components/child-screens/general/_index";
import { useComplianceStore } from "@app/products/property/assessments/compliance/[id]/store";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewPropertyCompliance = observer(() => {
  const { isLoading } = useComplianceStore();
  return (
    <>
      <Loading isLoading={isLoading} />
      <FormNavigation title="Compliance" />
      <FormTitle title="New Compliance" />

      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          ></CCNavButton>,
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

          <CCNavButton title={"Add"} type="sub">
            <AddActionButton />
            <CCNavButton title={"Add comments"} />
            <CCNavButton title={"Add contacts"} />
            <CCNavButton title={"Add documents"} />
            <CCNavButton title={"Add fees"} />
            <CCNavButton title={"Add samples"} />
            <CCNavButton title={"Add inspection"} />
          </CCNavButton>,
          <CCNavButton title={"View"} />,
          <CCNavButton title={"Print"} />,

          <CCNavButton title={"More Options"} type="more"></CCNavButton>,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <div className="cc-manage-form-body">
            <CCGeneralPanel component={<PropertyComplianceGeneral />} />
          </div>
        </div>
      </div>
    </>
  );
});
