import { DTO_LOV_JournalConverted } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { DTO_LOV_Number } from "@common/models/odataResponse";

export interface INewJournalStepsLOVs {
  JournalTypes?: DTO_LOV_JournalConverted[];
  Officer?: DTO_LOV_Number[];
}

export enum eJournalType {
  "Arrangement Re-Scheduling of Amounts Owing" = 50,
  "Assessment Journal" = 0,
  "CashLink Assessment Direct Debits" = 24,
  "CashLink Assessment Payments" = 20,
  "CashLink Deferred Duty Scheme Payments" = 26,
  "CashLink Direct Debit Reversals & Fees" = 25,
  "CashLink Direct Debit Reversals & Fees (Def. Duty)" = 79,
  "CashLink Direct Debit Reversals & Fees (Register)" = 65,
  "CashLink Direct Debit Reversals & Fees (Schemes)" = 75,
  "CashLink Interest Charges" = 21,
  "CashLink Register Payments" = 22,
  "CashLink Scheme Account Payments" = 23,
  "Change of Charges Instalment Plan" = 80,
  "Charge Adjustments" = 3,
  "Charge Balance Adjustment" = 8,
  "Charge Runs" = 11,
  "Charge Runs - Allocation of Prepayments" = 12,
  "Charge Write-Offs" = 5,
  "Debt Recovery Costs" = 16,
  "Deferment of Amounts Owing" = 55,
  "Deferred Duty Account Deferral" = 78,
  "Deferred Duty Account Interest & Instalments" = 77,
  "Deferred Duty Account Journal" = 76,
  "Generated Rebate Adjustments" = 56,
  "Imported Utility Water Usage Charges" = 30,
  "Interest Charges" = 10,
  "Journal Reversals" = 6,
  "Meter Usages" = 31,
  "Move unallocated Credits to Land Tax" = 41,
  "Penalty Interest" = 13,
  "Raise Charges" = 4,
  "Raise Supplementary Charges" = 7,
  "Re-allocation of Credits" = 40,
  "Receipting Assessment Payments" = 100,
  "Receipting Deferred Duty Scheme Payments" = 102,
  "Receipting Scheme Account Payments" = 101,
  "Refunds Generated from Procure" = 90,
  "Register Account Charges raised on Creation" = 61,
  "Register Account Journal" = 63,
  "Register Account Usage Charges" = 62,
  "Register Raising periodic Account Charges" = 60,
  "Scheme Account Estimates/Actual Costs" = 72,
  "Scheme Account Interest & Instalments" = 71,
  "Scheme Account Journal" = 70,
  "Supplementary Charges" = 9,
  "Transaction Reversals" = 2,
  "Transaction Transfers" = 1,
  "Transfer Fees" = 15,
  "Tribe Assssment Payments" = 105,
  "WA Balances transferred from Schemes" = 82,
  "WA Balances written off after being transferred to Assessments" = 83,
  "WA Instalment Processing" = 81,
  "PIC Journal" = 210,
}
