import { DTO_Journal_RaisePenaltyInterest } from "@app/products/property/journals/components/form-elements/raise-penalty-interest/model";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { ResponseMessage } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postRaisePenaltyInterest = async (
  data: DTO_Journal_RaisePenaltyInterest
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/journal/raisepenaltyinterest`,
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

// reuse the API api/property/internal/journal/lovs for get Installment plan
export const getRaisePenaltyInterestLOVs = async (): Promise<
  APIResponse<DTO_Journal_LOVs>
> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Journal_LOVs>(
      `api/property/internal/journal/lovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
