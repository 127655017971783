import { ExistManagePowerBIReport } from "@common/pages/report/powerbi/[id]/components/power-bi-report/_index";
import { usePowerBIReportStore } from "@common/pages/report/powerbi/[id]/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const ManagePowerBIReport = observer(() => {
  const params: { id: string } = useParams();
  const reportId = params.id;
  const { state } = useLocation<any>();
  const { resetStore, setReport, loadEmbedToken, reloadReportWithoutState } =
    usePowerBIReportStore();
  const { resetMenu } = useCCSubActionBarStore();

  useEffect(() => {
    if (state) {
      setReport(state);
      loadEmbedToken(state?.id);
      window.history.replaceState({}, "");
    } else {
      reloadReportWithoutState(reportId);
    }
    // eslint-disable-next-line
  }, [reportId, state]);

  useEffect(() => {
    return () => {
      resetStore();
      resetMenu();
    };
    // eslint-disable-next-line
  });

  return <ExistManagePowerBIReport />;
});

export default ManagePowerBIReport;
