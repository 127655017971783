import { IOptionsLov } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/options/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NoticeRunOptionStepStore {
  private _optionLOVs?: IOptionsLov = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get optionLOVs() {
    return toJS(this._optionLOVs);
  }
  setOptionLOVs = (optionLOVs?: IOptionsLov) => {
    runInAction(() => {
      this._optionLOVs = optionLOVs;
    });
  };
}

const noticeRunOptionStepStoreContext = createContext(
  new NoticeRunOptionStepStore()
);
export const useNoticeRunOptionStepStore = () => {
  return useContext(noticeRunOptionStepStoreContext);
};
