import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  PHONE_MESSAGE_OPEN_MESSAGES_URL,
  UNRESOLVED_EVENTS_MY_EVENT_URL,
} from "@common/pages/home/components/crms/constant";
import { useCrmsDashboardStore } from "@common/pages/home/components/crms/store";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useCommonProductStore } from "@common/stores/products/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { checkIsActiveCrmsPhoneMessage } from "@components/layout/cc-header/components/buttons/phone/util";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

const CrmsActivity = observer(() => {
  const history = useHistory();
  const { crmsEventDashboard, isLoadingCrmsEventDashboard } =
    useCrmsDashboardStore();

  const { activeProductsMap } = useCommonProductStore();
  const { settings } = useCommonCoreStore();

  let isActiveCrmsPhoneMessage =
    checkIsActiveCrmsPhoneMessage(activeProductsMap);
  const cxLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_ProductName]
  );
  if (isLoadingCrmsEventDashboard) return <Loading isLoading />;

  return (
    <div className="cc-activity-group">
      <div className="cc-activity-group-title">
        {cxLabel ?? CRMS_PRODUCT_NAME}
      </div>
      {crmsEventDashboard?.Activities ? (
        <div className="cc-activity-group-container">
          <div
            className="cc-activity-item"
            onClick={() => {
              history.push(UNRESOLVED_EVENTS_MY_EVENT_URL);
            }}
          >
            <div className="cc-activity-name">MY EVENTS</div>
            <div className="cc-activity-number">
              {crmsEventDashboard.Activities?.TotalMyUnresolvedEvents}
            </div>
          </div>

          {isActiveCrmsPhoneMessage && (
            <div
              className="cc-activity-item"
              onClick={() => {
                history.push(PHONE_MESSAGE_OPEN_MESSAGES_URL);
              }}
            >
              <div className="cc-activity-name">OPEN MESSAGES</div>
              <div className="cc-activity-number">
                {crmsEventDashboard.Activities?.TotalOpenPhoneMessages}
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
});

export default CrmsActivity;
