import { IPicCertificate } from "@app/products/property/pic/[id]/components/child-screens/certificates/model";

export const mockPicCertificate: IPicCertificate[] = [
  {
    Type: "Planning 149(5) Certificate",
    Number: 22584,
    OriginatedDateTime: new Date("21-Dec-2019 13:07"),
    ApplicantName: "Sekel Oshry Lawyers",
    ApplicantReference: "33702547:55924060",
    BalanceOwing: 962,
    IssuedDate: new Date("21-Dec-2019 16:53"),
    IssuedBy: "SmithT",
    Status: "Certificate Issued",
    Id: 7378,
  },
  {
    Type: "Planning 149(5) Certificate",
    Number: 21021,
    OriginatedDateTime: new Date("12-Feb-2014 11:42"),
    ApplicantName: "Stacey Evans",
    BalanceOwing: 656.4,
    IssuedDate: new Date("16-Feb-2014 12:15"),
    IssuedBy: "SmithT",
    Status: "Certificate Issued",
    Id: 2625,
  },
  {
    Type: "Planning 149(5) Certificate",
    Number: 20010,
    OriginatedDateTime: new Date("17-May-2010 12:45"),
    ApplicantName: "M.J Duffy & Son",
    ApplicantReference: "PD:SH/27058",
    BalanceOwing: 0,
    IssuedDate: new Date("24-May-2010 11:19"),
    IssuedBy: "WrightJ",
    Status: "Certificate Issued",
    Id: 29,
  },
  {
    Type: "Rates 603 Certificate",
    Number: 17076,
    OriginatedDateTime: new Date("5-May-2020 13:58"),
    ApplicantName: "Russell C Byrnes Solicitor",
    ApplicantReference: "3259",
    BalanceOwing: 481.34,
    IssuedDate: new Date("9-May-2020 15:30"),
    IssuedBy: "HoganB",
    Status: "Certificate Issued",
    Id: 7605,
  },
  {
    Type: "Rates 603 Certificate",
    Number: 15251,
    OriginatedDateTime: new Date("8-Apr-2014 12:16"),
    ApplicantName: "LEAP Searching",
    ApplicantReference: "1735/662711",
    BalanceOwing: 328.24,
    IssuedDate: new Date("9-Apr-2014 11:04"),
    IssuedBy: "HoganB",
    Status: "Certificate Issued",
    Id: 2766,
  },
  {
    Type: "Rates 603 Certificate",
    Number: 14332,
    OriginatedDateTime: new Date("30-Aug-2010 12:41"),
    ApplicantName: "Russell C Byrnes Solicitor",
    ApplicantReference: "EVANS:60132",
    BalanceOwing: 906,
    IssuedDate: new Date("31-Aug-2010 16:35"),
    IssuedBy: "HoganB",
    Status: "Certificate Issued",
    Id: 227,
  },
];
