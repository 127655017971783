import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import { AccessControlSuperAdmin } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/security/forms/security-settings/config";

export const postSaveAccessControl = async (
  data: AccessControlSuperAdmin
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `api/core/internal/settings/save-super-admin`,
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
