import { DTO_Workflow_Journal__AddAssessmentTransaction } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/new-transaction/model";
import { WorkflowProcessMode } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { sleep } from "@common/utils/common";

export const postProcessNewBulkLevyUpdate = async (
  workflowType: WorkflowProcessMode,
  data: any
): Promise<any> => {
  await sleep(2000);
  // ): Promise<APIResponse<ResponseMessage>> => {
  //TODO: Waiting api
  // try {
  //   const response = await CoreAPIService.getClient().post(
  //     `${workflowType}`,
  //     data
  //   );
  //   return response;
  // } catch (error) {
  //   const errorResponse: APIResponse = error as any;
  //   return {
  //     ...errorResponse,
  //     error: errorResponse.data?.Message,
  //   };
  // }
  return {
    status: 200,
    data: {
      ID: 0,
      IsSuccess: true,
    },
  };
};

export type IGetInitialDataBulkLevyUpdate =
  | [APIResponse<any>]
  | APIResponse<any>
  | undefined;
export const getInitialDataBulkLevyUpdate = async (
  workflowDraftId: number | undefined = 0,
  isFromActionList: boolean = false,
  assessmentId: number[] | undefined
): Promise<any> => {
  try {
    //TODO: Waiting api
    return await Promise.all([
      CoreAPIService.getClient().get<any>(`api/property/internal//lovs`),
      isFromActionList
        ? getWorkflowBulkLevyUpdate(workflowDraftId)
        : getNewWorkflowNewBulkLevyUpdate({
            Assessment_Ids: assessmentId,
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowNewBulkLevyUpdate = async (
  requestObj: any
): Promise<APIResponse<DTO_Workflow_Journal__AddAssessmentTransaction>> => {
  try {
    //TODO: Waiting api
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow//new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowBulkLevyUpdate = async (
  workflowDraftId: number
): Promise<APIResponse<any>> => {
  try {
    //TODO: Waiting api
    const response = await CoreAPIService.getClient().get(
      `api/property/internal/workflow//${workflowDraftId}`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
