import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";
import { WriteOffSmallBalancesDialog } from "@app/products/property/assessments/components/dialogs/write-off-small-balances/_index";

export const WriteOffSmallBalancesButton = () => {
  const [showWriteOffSmallBalancesDialog, setShowWriteOffSmallBalancesDialog] =
    useState(false);

  return (
    <>
      <CCNavButton
        title="Write off small balances"
        onClick={() => setShowWriteOffSmallBalancesDialog(true)}
      />

      {showWriteOffSmallBalancesDialog && (
        <WriteOffSmallBalancesDialog
          onClose={() => {
            setShowWriteOffSmallBalancesDialog(false);
          }}
        />
      )}
    </>
  );
};
