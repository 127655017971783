import { eventEmitter } from "@/App";
import {
  getDirectDebitSessionLovs,
  saveDirectDebitSession,
} from "@app/products/direct-debit/sessions/components/dialogs/create-session/api";
import { TRANSFER_DETAILS_STEP } from "@app/products/direct-debit/sessions/components/dialogs/create-session/constant";
import { DirectDebitSessionLovs } from "@app/products/direct-debit/sessions/components/dialogs/create-session/model";
import { DDSessionTransferDetailsStep } from "@app/products/direct-debit/sessions/components/dialogs/create-session/steps/transfer-details/_index";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { useEffectOnce } from "react-use";

export interface ICreateSessionDialogProps {
  onClose: () => void;
}

export const DDCreateSessionDialog = ({
  onClose,
}: ICreateSessionDialogProps) => {
  const { pushNotificationPortal } = useNotificationPortalStore();
  const { setInitialDataForms } = useFlexibleFormStore();
  const { cancelToken } = useCancelRequest();

  const steps: IStep[] = [
    {
      label: "Transfer details",
      component: DDSessionTransferDetailsStep,
      visible: true,
      key: TRANSFER_DETAILS_STEP,
    },
  ];

  const ddCreateSessionDialogLoadSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: () => getDirectDebitSessionLovs(cancelToken()),
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<DirectDebitSessionLovs> =
            dataFromApi?.data;
          setInitialDataForms({
            GeneralFormLovs: response,
          });
        },
      },
    ],
  });

  const ddCreateSessionDialogSubmitSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: ({ initialData }) =>
          saveDirectDebitSession(initialData, cancelToken()),
        handleSuccess: () => {
          onClose();
          pushNotificationPortal({
            title: "Direct debit session successfully saved.",
            type: "success",
            placeId: TypePlaceId.Main,
          });

          eventEmitter.emit(CCGridEventType.RefreshOData);
        },
        handleError: ({ errorFromApi }) => {
          pushNotificationPortal({
            title: "Direct debit session could not be saved.",
            type: "error",
            description: errorFromApi?.error,
            placeId: "DDCreateSessionDialog",
            autoClose: false,
          });
        },
      },
    ],
  });

  const handleSubmit = async (data: any) => {
    const includeGroupIds = data?.IncludeGroupIdsTemp?.map(
      (item: any) => item?.Key
    );
    const includePaymentFrequencies = data?.IncludePaymentFrequenciesTemp?.map(
      (item: any) => item?.Key
    );
    const valueSubmit = {
      ...data,
      IncludeGroupIds: includeGroupIds,
      IncludePaymentFrequencies: includePaymentFrequencies,
    };

    delete valueSubmit.IncludeGroupIdsTemp;
    delete valueSubmit.IncludePaymentFrequenciesTemp;
    await ddCreateSessionDialogSubmitSlider.fetchApi({
      initialData: valueSubmit,
    });
  };

  const handleReload = () => {
    ddCreateSessionDialogLoadSlider.fetchApi();
  };

  useEffectOnce(() => {
    ddCreateSessionDialogLoadSlider.fetchApi();
  });

  return (
    <CCFormStep
      onSubmit={handleSubmit}
      initialSteps={steps}
      initialValues={{}}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="50%"
          maxHeight="45%"
          yieldNotificationPortal="DDCreateSessionDialog"
          titleHeader={"Create Direct Debit Session"}
          onClose={onClose}
          isLoading={ddCreateSessionDialogLoadSlider.isFetching}
          disabled={ddCreateSessionDialogSubmitSlider.isFetching}
          bodyElement={
            ddCreateSessionDialogLoadSlider.errorResponse ? (
              <CCLoadFailed
                responseError={ddCreateSessionDialogLoadSlider?.errorResponse}
                onReload={handleReload}
              />
            ) : (
              renderProps.children
            )
          }
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              <Button
                className={"cc-dialog-button"}
                onClick={onClose}
                disabled={ddCreateSessionDialogLoadSlider.isFetching}
              >
                Cancel
              </Button>
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                iconClass={
                  ddCreateSessionDialogSubmitSlider?.isFetching
                    ? "fas fa-spinner fa-spin"
                    : ""
                }
                disabled={
                  renderProps.nextButton.disabled ||
                  ddCreateSessionDialogLoadSlider.isFetching
                }
                className={"cc-dialog-button"}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.isLastStep
                  ? "Submit"
                  : renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
