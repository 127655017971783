import { POWER_BI_REPORT_ROUTE } from "@common/pages/report/powerbi/list/constant";
import { IReportPowerBI } from "@common/pages/report/powerbi/list/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IReportPowerBI>();
export const colTestPowerBICustomReports: IColumnFields[] = [
  {
    field: nameOf("name"),
    title: "Report Name",
    linkTo: (dataItem: IReportPowerBI) => ({
      pathname: `${POWER_BI_REPORT_ROUTE}/${dataItem?.[nameOf("id")]}`,
      state: dataItem,
    }),
    minWidth: 400,
  },
  {
    field: nameOf("reportType"),
    title: "Report Type",
    minWidth: 400,
  },
  {
    field: nameOf("id"),
    title: "ID",
    minWidth: 400,
  },
];
