import { mockSingleBuildingRegistersCertificates } from "@app/products/building/registers/certificates/[id]/mock";
import { IBuildingRegistersCertificatesFormData } from "@app/products/building/registers/certificates/[id]/model";
import { sleep } from "@common/utils/common";
import { cloneDeep } from "lodash";

export const getBuildingRegistersCertificatesById = async (
  id?: string | number
): Promise<IBuildingRegistersCertificatesFormData | undefined> => {
  await sleep(1000);
  const response = cloneDeep(mockSingleBuildingRegistersCertificates);
  return response;
};
