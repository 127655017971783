import {
  DTO_ChangeOfOwnership_Assessment,
  DTO_ChangeOfOwnership_Assessment_Rebates,
  DTO_ChangeOfOwnership_RetainNames,
  RequestChangeOfOwnershipObj,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const loadChangeOfOwnerShipAssessment = async (
  searchString: string | number
): Promise<APIResponse<DTO_ChangeOfOwnership_Assessment>> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `/api/property/int/changeofownership/propertydetails?assessment_Id=${searchString}`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const loadRebateEntitlementsAssessment = async (
  assessmentId: string | number
): Promise<APIResponse<DTO_ChangeOfOwnership_Assessment_Rebates>> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `/api/property/int/changeofownership/${assessmentId}/rebateentitlements`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const loadRetainNames = async (
  requestObj: RequestChangeOfOwnershipObj
): Promise<APIResponse<DTO_ChangeOfOwnership_RetainNames>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/int/changeofownership/retainnames`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
