import { loadMasterPropertyAssessments } from "@app/products/property/assessments/master-properties/[id]/components/child-screens/assessments/api";
import {
  DTO_MP_Assessment_Total,
  MasterProperty_Assessment,
} from "@app/products/property/assessments/master-properties/[id]/components/child-screens/assessments/model";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { NumberCell } from "@components/cc-grid/components/grid-cells/number/_index";
import { IColumnFields } from "@components/cc-grid/model";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { cloneDeep, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { colMasterPropertyAssessment } from "./config";

const MasterPropertyAssessments = () => {
  const nameOf = nameOfFactory<MasterProperty_Assessment>();
  const params: { id: string } = useParams();
  const masterPropertyId = parseInt(params.id);

  const [isLoading, setIsLoading] = useState(false);
  const [assessmentData, setAssessmentData] = useState<
    MasterProperty_Assessment[] | undefined
  >(undefined);
  const [processedAssessmentCol, setProcessedAssessmentCol] = useState<
    IColumnFields[]
  >([]);

  const loadData = (masterPropertyId: number) => {
    setIsLoading(true);
    loadMasterPropertyAssessments(
      ViewConfiguration.MasterProperty_Assessment,
      masterPropertyId
    ).then((response) => {
      if (Array.isArray(response)) {
        const [assessmentResponse, viewConfigResponse] = response;
        const assessmentResponseData = assessmentResponse?.data;
        const viewConfigResponseData = viewConfigResponse?.data;
        if (
          isSuccessResponse(response[0]) &&
          isSuccessResponse(response[1]) &&
          assessmentResponseData &&
          viewConfigResponseData
        ) {
          const assessments: MasterProperty_Assessment[] | undefined =
            assessmentResponseData.Assessments;
          const viewConfig: ICCViewColumn[] | undefined =
            viewConfigResponseData.ColumnDefinitions?.Columns;
          const totals: DTO_MP_Assessment_Total | undefined =
            assessmentResponseData?.Totals;
          if (
            !viewConfig ||
            viewConfig?.length === 0 ||
            !assessments ||
            assessments?.length === 0 ||
            !totals
          ) {
            setIsLoading(false);
            return;
          }
          //Add total footercell
          const tempCol = cloneDeep(colMasterPropertyAssessment);
          tempCol.forEach((item: IColumnFields) => {
            if (Object.keys(totals).includes(item.field)) {
              const colField = item.field as keyof DTO_MP_Assessment_Total;
              if (!isNil(totals?.[colField])) {
                if (colField === "Unit_Entitlement") {
                  item.footerCell = (
                    <NumberCell value={totals?.[colField] as number} />
                  );
                } else {
                  item.footerCell = (
                    <CurrencyCell value={totals?.[colField] as number} />
                  );
                }
              }
            }
          });
          //Process dynamic columns
          setProcessedAssessmentCol(processDynamicColumns(tempCol, viewConfig));
          setAssessmentData(assessments);
        }
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (masterPropertyId) {
      loadData(masterPropertyId);
    }
    // eslint-disable-next-line
  }, [masterPropertyId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!assessmentData || processedAssessmentCol.length === 0)
    return <NoData vertical />;

  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Assessments in master property</label>
            <CCGrid
              data={assessmentData}
              columnFields={processedAssessmentCol}
              primaryField={nameOf("Assessment_Id")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MasterPropertyAssessments;
