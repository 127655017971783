import { FunctionGroupPicker } from "@app/products/property/assessments/components/form-steps/new-assessment/dialog/group-picker/_index";
import { IOfficerAllocation } from "@app/products/property/assessments/components/form-steps/new-assessment/dialog/officer-allocation/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IOfficerAllocationDialogProps {
  onClose: () => void;
  onSubmitData: (data: IOfficerAllocation) => void;
  isShowWarning?: boolean;
  isLoadingAllocate?: boolean;
  isLoadingOfficer?: boolean;
  dataOfficer: IKeyValuePacket[];
  titleDialog?: string;
}
const nameOf = nameOfFactory<IOfficerAllocation>();
export const OfficerAllocationDialog = observer(
  ({
    onClose,
    onSubmitData,
    isShowWarning = true,
    isLoadingAllocate = false,
    isLoadingOfficer = false,
    dataOfficer = [],
    titleDialog = "Officer Allocation",
  }: IOfficerAllocationDialogProps) => {
    return (
      <Form
        onSubmit={(values) => {
          onSubmitData(values as IOfficerAllocation);
        }}
        render={(formRenderProps: FormRenderProps) => {
          const { onChange, onSubmit, valid } = formRenderProps;

          const handleSearchResult = (event: ComboBoxChangeEvent) => {
            onChange(nameOf("Officer"), {
              value: event.value,
            });
            onChange(nameOf("OfficerName"), {
              value: event.value.OrgUnit_Name,
            });
            onChange(nameOf("OfficerId"), { value: event.value.ID });
          };

          return (
            <CCDialog
              titleHeader={titleDialog}
              onClose={onClose}
              closeIcon={!isLoadingAllocate}
              maxWidth="40%"
              height="auto"
              bodyElement={
                <div className="cc-form">
                  <FormElement className="cc-field-group">
                    {isShowWarning && (
                      <h6 className="cc-label">
                        Be careful! This action cannot be undone.
                      </h6>
                    )}
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">
                          Reason
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("Reason")}
                          placeholder="Reason"
                          component={CCInput}
                          validator={requiredValidator}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Group
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          placeholder="Group"
                          name={nameOf("Officer")}
                          textField={"OrgUnit_Name"}
                          dataItemKey={"ID"}
                          component={FunctionGroupPicker}
                          validator={requiredValidator}
                          onChange={handleSearchResult}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Comments</label>
                        <Field
                          name={nameOf("Comments")}
                          rows={3}
                          component={CCTextArea}
                          placeholder="Comments"
                        />
                      </div>
                    </div>
                  </FormElement>
                </div>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    className="cc-dialog-button"
                    onClick={onClose}
                    disabled={isLoadingAllocate}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    iconClass={
                      isLoadingAllocate ? "fas fa-spinner fa-spin" : ""
                    }
                    onClick={onSubmit}
                    disabled={!valid || isLoadingAllocate}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
