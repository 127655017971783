import { IColumnFields } from "@components/cc-grid/model";
import { getFilterType } from "@components/cc-grid/util";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import {
  BooleanFilter,
  DateFilter,
  EnumFilter,
  FieldSettings,
  Filter,
  FilterChangeEvent,
  NumericFilter,
  Operators,
  TextFilter,
} from "@progress/kendo-react-data-tools";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

interface ISearchDetailFilterProps {
  initialFilter: CompositeFilterDescriptor;
  columnFields: IColumnFields[];
  onFilterDataChange: any;
}

export const SearchDetailFilter = observer(
  ({
    initialFilter,
    columnFields,
    onFilterDataChange,
  }: ISearchDetailFilterProps) => {
    const [filterColumnData, setFilterColumnData] = useState<FieldSettings[]>(
      []
    );

    const getColumDataType = (dataType: string) => {
      var dataTypeMap = new Map();
      dataTypeMap.set("boolean", {
        filter: BooleanFilter,
        operators: Operators.boolean,
      });
      dataTypeMap.set("numeric", {
        filter: NumericFilter,
        operators: Operators.numeric,
      });
      dataTypeMap.set("date", {
        filter: DateFilter,
        operators: Operators.date,
      });
      dataTypeMap.set("text", {
        filter: TextFilter,
        operators: Operators.text,
      });
      dataTypeMap.set("enum", {
        filter: EnumFilter,
        operators: Operators.text,
      });

      return dataTypeMap.get(dataType ? dataType : "text");
    };

    useEffect(() => {
      let columnFieldsClone = columnFields.map((item) =>
        Object.assign({}, item)
      );
      let columnDataClone: any = [];
      columnFieldsClone.forEach((item: IColumnFields) => {
        columnDataClone.push({
          name: item.field,
          label: item.title,
          ...getColumDataType(getFilterType(item)),
        });
      });
      setFilterColumnData(columnDataClone);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFields]);

    const onFilterChange = (event: FilterChangeEvent) => {
      onFilterDataChange(event.filter);
    };

    return (
      <div className="cc-produce-filter">
        <div className="cc-produce-filter-body">
          <Filter
            value={initialFilter}
            onChange={onFilterChange}
            fields={filterColumnData}
          />
        </div>
      </div>
    );
  }
);
