import ArrangementProduceLetterDialog from "@app/products/property/assessments/arrangements/components/dialogs/produce-letter/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const ProduceLetterButton = () => {
  const [showProduceLetterDialog, setShowProduceLetterDialog] = useState(false);

  return (
    <>
      <CCNavButton
        title="Produce Letter"
        onClick={() => setShowProduceLetterDialog(true)}
      />
      {showProduceLetterDialog && (
        <ArrangementProduceLetterDialog
          onClose={() => {
            setShowProduceLetterDialog(false);
          }}
        />
      )}
    </>
  );
};
