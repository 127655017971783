import { EPrefixField } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/components/element-steps/rates-department-details/config";
import { DTO_Certificate_UDAs } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/components/element-steps/rates-department-details/model";
import { useRateDepartmentDetailStepStore } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/components/element-steps/rates-department-details/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
export const RateDepartmentDetailFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { rateDepartmentDetailStepLOVs } = useRateDepartmentDetailStepStore();
    const listFieldUDA = rateDepartmentDetailStepLOVs?.UDAs;
    const dataLovs = rateDepartmentDetailStepLOVs?.UDALOvs;
    const renderPairUDA = (field: DTO_Certificate_UDAs) => {
      if (isNil(field)) return null;
      return (
        <>
          <div className="cc-field">
            <label className="cc-label">
              {field?.UDA_Label ?? field?.Name}
            </label>
            <Field
              name={nameOf(`_option.${EPrefixField.Comboboxes}_${field?.Code}`)}
              component={CCSearchComboBox}
              data={dataLovs?.[field?.Code] ?? []}
              textField="Name"
              dataItemKey="Code"
              isUseDefaultOnchange
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              {field?.UDA_Label ?? field?.Name} details
            </label>
            <Field
              name={nameOf(`_option.${EPrefixField.TextArea}_${field?.Code}`)}
              component={CCTextArea}
              placeholder={`${field?.UDA_Label ?? field?.Name} details`}
              readOnly={options?.isReadOnly}
              rows="3"
            />
          </div>
        </>
      );
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          {listFieldUDA?.map((item: DTO_Certificate_UDAs, idx: number) => {
            return (
              <div className="cc-form-cols-1" key={idx}>
                {renderPairUDA(item)}
              </div>
            );
          })}
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Payment date</label>
              <Field
                name={nameOf("PaymentDate")}
                component={CCDatePicker}
                disabled={options?.isReadOnly}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Completed</label>
              <Field
                name={nameOf("Completed")}
                component={CCSwitch}
                disabled={options?.isReadOnly}
                checked={getFieldValue("Completed")}
              />
            </div>
          </div>
          <div className="cc-field">
            <label className="cc-label">Comments</label>
            <Field
              name={nameOf("Comments")}
              component={CCTextArea}
              placeholder="Comments"
              readOnly={options?.isReadOnly}
              rows="3"
            />
          </div>
          <br />
        </div>
      </section>
    );
  }
);
