import { ApplicationSettingSwitch } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-switch/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type IRegistersWorkflowFormProps = {
  formRenderProps: FormRenderProps;
};

export const RegistersWorkflowForm = observer(
  ({ formRenderProps }: IRegistersWorkflowFormProps) => {
    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_EnableRequestFI_On_Registers_Workflow_Bar
            }
            formRenderProps={formRenderProps}
          />
        </div>
      </section>
    );
  }
);
