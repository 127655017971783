export const communicationRouteStateGlobalSettings = [
  {
    name: "Communication_CommunicationSettings",
    component: require("./forms/communication-settings").default,
  },
  {
    name: "Communication_SmsAndFaxSettings",
    component: require("./forms/sms-and-fax-settings").default,
  },
];
