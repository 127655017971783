import { IInterestRateDialogData } from "@app/products/property/schemes/[id]/components/forms/existed/components/dialogs/add-interest-rate/components/dialogs/interest-rate/model";
import { handleValidateForm } from "@app/products/property/schemes/[id]/components/forms/existed/components/dialogs/add-interest-rate/components/dialogs/interest-rate/util";
import { eSchemesInterestRateStatus } from "@app/products/property/schemes/[id]/model";
import {
  DATE_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { Button } from "@progress/kendo-react-buttons";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";

interface IInterestRateDialogProps {
  data: IInterestRateDialogData;
  onClose: () => void;
  onSubmit: (data: any) => void;
}
export const InterestRateDialog = ({
  data,
  onClose,
  onSubmit,
}: IInterestRateDialogProps) => {
  const [initialValues, setInitialValues] = useState<any | undefined>(
    undefined
  );

  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    const { values } = event;
    if (data.IsNew) {
      onSubmit([...data.InterestRateData, values]);
    } else {
      onSubmit([
        ...data.InterestRateData.filter(
          (item) => item?.Id !== data.InterestRate?.Id
        ),
        {
          EffectiveFrom: values.EffectiveFrom,
          InterestRate: values.InterestRate,
          PenaltyRate: values.PenaltyRate,
          Status: eSchemesInterestRateStatus.NEW,
          Id: values.Id,
        },
      ]);
    }
  };

  useEffect(() => {
    if (!data.InterestRate) return;
    setInitialValues(data.InterestRate);
  }, [data.InterestRate]);

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      validator={(values: any) => handleValidateForm(values, data)}
      render={(formRenderProps: FormRenderProps) => {
        return (
          <CCDialog
            titleHeader={"Interest Rate"}
            onClose={onClose}
            maxWidth="25%"
            maxHeight="37%"
            bodyElement={
              <div className="cc-new-arrangement cc-form">
                <FormElement
                  onSubmit={formRenderProps.onSubmit}
                  className="cc-field-group"
                >
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">Effective from</label>
                      <Field
                        name={"EffectiveFrom"}
                        component={CCDatePicker}
                        format={DATE_FORMAT.DATE_CONTROL}
                        onChange={(event: DatePickerChangeEvent) => {
                          formRenderProps.onChange("EffectiveFrom", {
                            value: event.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">Interest rate</label>
                      <Field
                        name={"InterestRate"}
                        min={0}
                        format={PERCENTAGE_FORMAT.PERCENTAGE}
                        max={100}
                        step={0.1}
                        component={NumericTextBox}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">Penalty rate</label>
                      <Field
                        name={"PenaltyRate"}
                        min={0}
                        format={PERCENTAGE_FORMAT.PERCENTAGE}
                        max={100}
                        step={0.1}
                        component={NumericTextBox}
                      />
                    </div>
                  </div>
                </FormElement>
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  type={"submit"}
                  onClick={formRenderProps.onSubmit}
                  disabled={!formRenderProps.valid}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
