import { PROPERTY_CHARGE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/constant";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { VO_ChargeRun } from "@app/products/property/charge-and-notice-runs/charge-runs/model";

const nameOf = nameOfFactory<VO_ChargeRun>();
export const colListChargeRuns: IColumnFields[] = [
  {
    field: nameOf("Charge_Run_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
    locked: true,
    linkTo: (dataItem) =>
      `${PROPERTY_CHARGE_RUNS_ROUTE}/${dataItem.Charge_Run_Id}`,
  },
  {
    field: nameOf("CRT_Name"),
    title: "Type",
  },
  {
    field: nameOf("RP_Name"),
    title: "Rating Period",
  },
  {
    field: nameOf("IP_Name"),
    title: "Instalment Plan",
  },
  {
    field: nameOf("CR_Charge_Date"),
    title: "Charge Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CR_Name"),
    title: "Name",
  },
  {
    field: nameOf("CR_Code"),
    title: "Code",
  },
  {
    field: nameOf("CR_Description"),
    title: "Description",
  },
  {
    field: nameOf("CR_Created_By_UserId"),
    title: "Created By",
  },
  {
    field: nameOf("CR_Created_On_DateTime"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("CR_Updated_By_UserId"),
    title: "Updated By",
  },
  {
    field: nameOf("CR_Updated_On_DateTime"),
    title: "Updated On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Journal_Number"),
    title: "Journal Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("CR_No_of_Assessments"),
    title: "Number of Assessments",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("CR_No_of_Levies"),
    title: "Number of Levies",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("CR_Total_Gross_Levy_Amount"),
    title: "Total Gross Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("CR_Total_Rebate_Amount"),
    title: "Total Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("CR_Total_Discount_Amount_1"),
    title: "Total Discount Amount1",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("CR_Total_Discount_Amount_2"),
    title: "Total Discount Amount2",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("CR_Total_Postponed_Amount"),
    title: "Total Postponed Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("CR_Total_Nett_Levy_Amount"),
    title: "Total Nett Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
