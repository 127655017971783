import { mockSingleBuildingRegistersSitingApprovalsJSON } from "@app/products/building/registers/siting-approvals/[id]/mock";
import { IBuildingRegistersSitingApprovalsFormData } from "@app/products/building/registers/siting-approvals/[id]/model";
import { IResponseData } from "@common/models/service";

export const loadBuildingRegistersSitingApprovalsById = async (
  id?: string
): Promise<IBuildingRegistersSitingApprovalsFormData> => {
  const response: IResponseData = JSON.parse(
    JSON.stringify(mockSingleBuildingRegistersSitingApprovalsJSON)
  );
  return response.data;
};
