import {
  getPriorityColour,
  getWorkOrderFinishDate,
  getWorkOrderStartDate,
} from "@app/products/ams/api/data/workorders/workorder-helper";
import { useStores } from "@app/products/ams/api/helpers/use-stores";
import { IWorkOrderValues } from "@app/products/ams/model/workOrder";
import React, { Fragment } from "react";

export interface IProps {
  workOrderInfo: IWorkOrderValues;
  title?: string;
}

export const WorkOrderInfoFragment: React.FC<IProps> = ({ workOrderInfo }) => {
  const {
    dataStores: { lookupStore },
  } = useStores();
  return (
    <Fragment>
      <h4 className="mt-3 ml-1">{workOrderInfo.WorkOrderNumber}</h4>
      <br />
      <div
        className="container-fluid ml-1 disabled"
        style={{ fontSize: "larger" }}
      >
        <div className="row form-group">
          &nbsp;
          <div className="font-weight-bolder">{workOrderInfo.Description}</div>
        </div>
        <div className="row form-group">
          Status:&nbsp;&nbsp;&nbsp;
          <div className="font-weight-bolder mr-3 text-success">
            {lookupStore.getLookupItemDescription(
              "WorkOrderStatus",
              workOrderInfo.StatusId
            )}
          </div>
        </div>
        {workOrderInfo.PriorityId ? (
          <div className="row form-group">
            Priority:&nbsp;&nbsp;&nbsp;
            <div className={getPriorityColour(workOrderInfo.PriorityId)}>
              {lookupStore.getLookupItemDescription(
                "WorkOrderPriority",
                workOrderInfo.PriorityId
              )}
            </div>
          </div>
        ) : null}
        {getWorkOrderStartDate(workOrderInfo).Name.length > 0 ? (
          <div className="row form-group">
            {getWorkOrderStartDate(workOrderInfo).Name}:&nbsp;&nbsp;&nbsp;
            <div className="font-weight-bolder">
              {getWorkOrderStartDate(workOrderInfo).Date}
            </div>
          </div>
        ) : null}
        {getWorkOrderFinishDate(workOrderInfo).Name.length > 0 ? (
          <div className="row form-group">
            {getWorkOrderFinishDate(workOrderInfo).Name}:&nbsp;&nbsp;&nbsp;
            <div className="font-weight-bolder">
              {getWorkOrderFinishDate(workOrderInfo).Date}
            </div>
          </div>
        ) : null}
        {workOrderInfo.DivisionId ? (
          <div className="row form-group">
            Division:&nbsp;&nbsp;&nbsp;
            {/*               <div className="font-weight-bolder text-success">{lookupStore.getLookupItemDescription('Division', workOrderInfo.DivisionId)}</div> */}
            <div className="font-weight-bolder">
              {lookupStore.getLookupItemDescription(
                "Division",
                workOrderInfo.DivisionId
              )}
            </div>
          </div>
        ) : null}
        {workOrderInfo.ProjectId ? (
          <div className="row form-group">
            Project:&nbsp;&nbsp;&nbsp;
            <div className="font-weight-bolder">
              {lookupStore.getLookupItemDescription(
                "Project",
                workOrderInfo.ProjectId
              )}
            </div>
          </div>
        ) : null}
        {workOrderInfo.BudgetId ? (
          <div className="row form-group">
            Budget:&nbsp;&nbsp;&nbsp;
            <div className="font-weight-bolder">
              {lookupStore.getLookupItemDescription(
                "BudgetCode",
                workOrderInfo.BudgetId
              )}
            </div>
          </div>
        ) : null}
        {workOrderInfo.MaintenanceTypeId ? (
          <div className="row form-group">
            Maintenance Type:&nbsp;&nbsp;&nbsp;
            <div className="font-weight-bolder">
              {lookupStore.getLookupItemDescription(
                "MaintenanceType",
                workOrderInfo.MaintenanceTypeId
              )}
            </div>
          </div>
        ) : null}
        {workOrderInfo.ActioningOfficerId ? (
          <div className="row form-group">
            Actioning Officer:&nbsp;&nbsp;&nbsp;
            <div className="font-weight-bolder">
              {lookupStore.getLookupItemDescription(
                "ActioningOfficer",
                workOrderInfo.ActioningOfficerId
              )}
            </div>
          </div>
        ) : null}
        {workOrderInfo.isOverdue ? (
          <span className="badge badge-pill badge-danger">Overdue</span>
        ) : null}
      </div>
    </Fragment>
  );
};

WorkOrderInfoFragment.defaultProps = {
  title: "Work Order",
};

export default WorkOrderInfoFragment;
