import { ICCRoute } from "@common/constants/ICCRoute";

export const councilConsentRoute: ICCRoute = {
  path: "council-consent",
  name: "Council Consent",
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
