import { IMetersDetail } from "@app/products/property/registers/[id]/components/child-screens/meters/components/detail/model";

export const mockMetersDetail: IMetersDetail[] = [
  {
    Utility: "Water",
    FromDate: new Date("30-Sep-2010"),
    ToDate: new Date("31-Dec-2010"),
    NumberOfDays: 92,
    AssessmentNumber: "2229",
    MeterNumber: "90441",
    Percentage: 100,
    GrossUsage: 0,
    ChargeableUsage: 0,
    DailyAverageUsage: 0,
    TariffTotal: 0.0,
    Amount: 0.0,
    Tariff: "Water and Sewerage",
    JournalNumber: "2",
    UsageId: 34382,
  },
  {
    Utility: "Water",
    FromDate: new Date("31-Dec-2010"),
    ToDate: new Date("31-Mar-2011"),
    NumberOfDays: 90,
    AssessmentNumber: "2229",
    MeterNumber: "90441",
    Percentage: 100.0,
    GrossUsage: 0,
    ChargeableUsage: 0,
    DailyAverageUsage: 0,
    TariffTotal: 0.0,
    Amount: 0.0,
    Tariff: "Water and Sewerage",
    JournalNumber: "2",
    UsageId: 35827,
  },
  {
    Utility: "Water",
    FromDate: new Date("31-Mar-2011"),
    ToDate: new Date("30-Jun-2011"),
    NumberOfDays: 91,
    AssessmentNumber: "2229",
    MeterNumber: "90441",
    Percentage: 100.0,
    GrossUsage: 0,
    ChargeableUsage: 0,
    DailyAverageUsage: 0,
    TariffTotal: 0.0,
    Amount: 0.0,
    Tariff: "Water and Sewerage",
    JournalNumber: "2",
    UsageId: 37295,
  },
  {
    Utility: "Water",
    FromDate: new Date("30-Jun-2011"),
    ToDate: new Date("30-Sep-2011"),
    NumberOfDays: 92,
    AssessmentNumber: "2229",
    MeterNumber: "90441",
    Percentage: 100.0,
    GrossUsage: 0,
    ChargeableUsage: 0,
    DailyAverageUsage: 0,
    TariffTotal: 0.0,
    Amount: 0.0,
    Tariff: "Water and Sewerage",
    JournalNumber: "2",
    UsageId: 38783,
  },
  {
    Utility: "Water",
    FromDate: new Date("30-Sep-2011"),
    ToDate: new Date("31-Dec-2011"),
    NumberOfDays: 92,
    AssessmentNumber: "2229",
    MeterNumber: "90441",
    Percentage: 100.0,
    GrossUsage: 0,
    ChargeableUsage: 0,
    DailyAverageUsage: 0,
    TariffTotal: 0.0,
    Amount: 0.0,
    Tariff: "Water and Sewerage",
    JournalNumber: "2",
    UsageId: 40303,
  },
  {
    Utility: "Water",
    FromDate: new Date("31-Dec-2011"),
    ToDate: new Date("31-Mar-2012"),
    NumberOfDays: 91,
    AssessmentNumber: "2229",
    MeterNumber: "90441",
    Percentage: 100.0,
    GrossUsage: 0,
    ChargeableUsage: 0,
    DailyAverageUsage: 0,
    TariffTotal: 0.0,
    Amount: 0.0,
    Tariff: "Water and Sewerage",
    JournalNumber: "2",
    UsageId: 41861,
  },
  {
    Utility: "Water",
    FromDate: new Date("31-Mar-2012"),
    ToDate: new Date("30-Jun-2012"),
    NumberOfDays: 91,
    AssessmentNumber: "2229",
    MeterNumber: "90441",
    Percentage: 100.0,
    GrossUsage: 7,
    ChargeableUsage: 7,
    DailyAverageUsage: 0.0769,
    TariffTotal: 0.0,
    Amount: 0.0,
    Tariff: "Water and Sewerage",
    JournalNumber: "2",
    UsageId: 43447,
  },
];
