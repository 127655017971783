import { apiCoreGetKeywordById } from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Keyword } from "@common/models/keyword";

// TODO: Need change
export const getKeywordById = async (
  id: number
): Promise<APIResponse<Keyword | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(apiCoreGetKeywordById(id));
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getKeywordNameById = async (
  id: number
): Promise<APIResponse<string>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/core/internal/keyword-name-by-id/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
