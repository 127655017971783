import { FeeTypesPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/fee-types-picker/_index";
import { IFeeTypes } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/add-fee-types/model";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { SwitchSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/switch/_index";
import { ICommonFormProps } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-application/_index";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfLookupItem = nameOfFactory<ILookupItem>();

export const CertificationForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { isEditing } = useSettingPPRManageStore();
    const { getConfigDataField } = useSettingPPRStore();
    const nameOfFeeType = nameOfFactory<IFeeTypes>();

    //Get setting fields
    const fieldEnableCertificationReferral = getConfigDataField(
      ECorporateSettingsField.TP_EnableCertificationReferral
    );
    const fieldEnableCommunicationOnCertificationReferral = getConfigDataField(
      ECorporateSettingsField.TP_EnableCommunication_OnResponse_CertificationReferral
    );
    const fieldEnableCertification = getConfigDataField(
      ECorporateSettingsField.TP_EnableCertification
    );
    const fieldUseSDChecklist = getConfigDataField(
      ECorporateSettingsField.TP_UseSDChecklist
    );
    const fieldCertificationDefaultTab = getConfigDataField(
      ECorporateSettingsField.TP_CertificationDefaultTab
    );
    const fieldCertificationFeeTypes = getConfigDataField(
      ECorporateSettingsField.TP_SubdivisionFeeTypeCSV
    );
    const fieldRecommendationOption = getConfigDataField(
      ECorporateSettingsField.TP_Certification_RecommendationCustomList
    );
    const fieldRecommendationTypesOption = getConfigDataField(
      ECorporateSettingsField.TP_Certification_RecommendationTypeCustomList
    );
    const fieldShowSubDivRelatedTitles = getConfigDataField(
      ECorporateSettingsField.TP_ShowSubDivRelatedTitles
    );
    const fieldShowSubDivQuery = getConfigDataField(
      ECorporateSettingsField.TP_ShowSubDivQuery
    );

    const feeTypesValueDisplayDetails: IFeeTypes[] =
      formRenderProps?.valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_SubdivisionFeeTypeCSV}`
      ) ?? [];

    const handleOnChangeFeeTypes = (feeType: IFeeTypes[]) => {
      formRenderProps?.onChange(fieldCertificationFeeTypes?.FieldName ?? "", {
        value: feeType
          .reduce((pre, cur) => pre + "^" + cur.ID, "")
          .slice(1)
          .trim(),
      });
      formRenderProps?.onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_SubdivisionFeeTypeCSV}`,
        {
          value: feeType,
        }
      );
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {fieldEnableCertificationReferral && (
            <div className="cc-field">
              <CCLabel title={fieldEnableCertificationReferral?.Title ?? ""} />
              <Field
                name={fieldEnableCertificationReferral?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={formRenderProps?.valueGetter(
                  fieldEnableCertificationReferral?.FieldName
                )}
              />
            </div>
          )}
          {formRenderProps?.valueGetter(
            fieldEnableCertificationReferral?.FieldName ?? ""
          ) && (
            <SwitchSettings
              data={fieldEnableCommunicationOnCertificationReferral}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
          <SwitchSettings
            data={fieldEnableCertification}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          {fieldUseSDChecklist && (
            <div className="cc-field">
              <CCLabel title={fieldUseSDChecklist?.Title ?? ""} />
              <Field
                name={fieldUseSDChecklist?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={formRenderProps?.valueGetter(
                  fieldUseSDChecklist?.FieldName
                )}
              />
            </div>
          )}
          {fieldCertificationDefaultTab && (
            <div className="cc-field">
              <CCLabel
                title={fieldCertificationDefaultTab?.Title ?? ""}
                isMandatory={fieldCertificationDefaultTab.IsMandatory}
              />
              <Field
                name={fieldCertificationDefaultTab?.FieldName}
                component={CCSearchComboBox}
                data={fieldCertificationDefaultTab?.LookupItems}
                textField={nameOfLookupItem("Name")}
                dataItemKey={nameOfLookupItem("Id")}
                disabled={!isEditing}
                isUseDefaultOnchange
                validator={
                  fieldCertificationDefaultTab.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
          {fieldCertificationFeeTypes && (
            <div className="cc-field">
              <CCLabel
                title={fieldCertificationFeeTypes?.Title ?? ""}
                isMandatory={fieldCertificationFeeTypes.IsMandatory}
              />
              <Field
                name={fieldCertificationFeeTypes?.FieldName ?? ""}
                placeholder={fieldCertificationFeeTypes?.Title ?? ""}
                value={feeTypesValueDisplayDetails}
                component={FeeTypesPicker}
                isGetDetail={false}
                disabled={!isEditing}
                selectableMode="multiple"
                onPickFeeTypes={handleOnChangeFeeTypes}
                textField={nameOfFeeType("Name")}
                textProduce={(data: IFeeTypes[]) =>
                  data
                    .reduce((pre, cur) => pre + ", " + cur.Name, "")
                    .slice(1)
                    .trim()
                }
              />
            </div>
          )}
          {fieldRecommendationOption && (
            <div className="cc-field">
              <CCLabel
                title={fieldRecommendationOption?.Title ?? ""}
                isMandatory={fieldRecommendationOption.IsMandatory}
              />
              <Field
                name={fieldRecommendationOption?.FieldName}
                component={CCSearchComboBox}
                data={fieldRecommendationOption?.LookupItems}
                textField={nameOfLookupItem("Name")}
                dataItemKey={nameOfLookupItem("Id")}
                disabled={!isEditing}
                isUseDefaultOnchange
                validator={
                  fieldRecommendationOption.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
          {fieldRecommendationTypesOption && (
            <div className="cc-field">
              <CCLabel
                title={fieldRecommendationTypesOption?.Title ?? ""}
                isMandatory={fieldRecommendationTypesOption.IsMandatory}
              />
              <Field
                name={fieldRecommendationTypesOption?.FieldName}
                component={CCSearchComboBox}
                data={fieldRecommendationTypesOption?.LookupItems}
                textField={nameOfLookupItem("Name")}
                dataItemKey={nameOfLookupItem("Id")}
                disabled={!isEditing}
                isUseDefaultOnchange
                validator={
                  fieldRecommendationTypesOption.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
          {fieldShowSubDivRelatedTitles && (
            <div className="cc-field">
              <CCLabel title={fieldShowSubDivRelatedTitles?.Title ?? ""} />
              <Field
                name={fieldShowSubDivRelatedTitles?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={formRenderProps?.valueGetter(
                  fieldShowSubDivRelatedTitles?.FieldName
                )}
              />
            </div>
          )}
        </div>
        {formRenderProps?.valueGetter(
          fieldShowSubDivRelatedTitles?.FieldName ?? ""
        ) &&
          fieldShowSubDivQuery && (
            <div className="cc-field">
              <CCLabel
                title={fieldShowSubDivQuery.Title ?? ""}
                isMandatory={fieldShowSubDivQuery.IsMandatory}
              />
              {fieldShowSubDivQuery.Description && (
                <label className="cc-settings-description">
                  {sanitizeHtml(fieldShowSubDivQuery.Description ?? "")}
                </label>
              )}
              <Field
                name={fieldShowSubDivQuery.FieldName}
                component={CCTextArea}
                disabled={!isEditing}
                validator={
                  fieldShowSubDivQuery.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
      </section>
    );
  }
);
