import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { Svc_ActionType } from "@common/models/actionType";
import { SETTINGS_LOOKUPS_ACTION_TYPES_ROUTE } from "@common/pages/settings/lookups/action-types/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_ActionType>();

export const colLookupsActionTypes: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    locked: true,
    linkTo: (dataItem: Svc_ActionType) =>
      `${SETTINGS_LOOKUPS_ACTION_TYPES_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("Flag_HasOutcome"),
    title: "Has Outcome",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Flag_UserSelection"),
    title: "User Selection",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("IsApproval"),
    title: "Is Approval",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Flag_IsReferral"),
    title: "Is Referral",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Flag_ExternalOfficer"),
    title: "Is External",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("RestrictActionOfficerSelection"),
    title: "Officer Restricted",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("DefaultActionOfficer"),
    title: "Default Officer",
  },
  {
    field: nameOf("TargetDays"),
    title: "Target Days",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("ActionTypeTags_CSV"),
    title: "Tags",
  },
];
