import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IntegratedReportImport } from "@common/pages/report/integrated-reports/component/buttons/export-xml/model";

export const getExportXml = async (
  ids: number[]
): Promise<
  APIResponse<{ IntegratedReportImports: IntegratedReportImport } | undefined>
> => {
  try {
    return await CoreAPIService.getClient().post<{
      IntegratedReportImports: IntegratedReportImport;
    }>(`/api/core/internal/integratedreports/export`, JSON.stringify(ids));
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
