import { DTO_ChangeOfOwnership } from "@app/products/property/assessments/[id]/components/child-screens/transfer/model";
import { isFieldVisible } from "@app/products/property/util";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";

interface ITransferDetailProps {
  dataItem: DTO_ChangeOfOwnership;
}

export const TransferDetail: React.FC<GridDetailRowProps> = ({
  dataItem,
}: ITransferDetailProps) => {
  return (
    <div className="cc-assessment-transfer-detail">
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCValueField label="Change type" value={dataItem.Type} />
            {isFieldVisible(dataItem?.ReferenceVisibility) && (
              <CCValueField label="Reference" value={dataItem.Reference} />
            )}
            {isFieldVisible(dataItem?.ContractDateVisibility) && (
              <CCValueField
                label="Contract date"
                value={dataItem.ContractDate}
                format={DATE_FORMAT.DATE}
              />
            )}
            {isFieldVisible(dataItem?.EffectiveDateVisibility) && (
              <CCValueField
                label="Effective date"
                value={dataItem.EffectiveDate}
                format={DATE_FORMAT.DATE}
              />
            )}
            {isFieldVisible(dataItem?.SalePriceVisibility) && (
              <CCValueField
                label="Sale price"
                value={dataItem.SalePrice}
                format={CURRENCY_FORMAT.CURRENCY1}
              />
            )}
            {isFieldVisible(dataItem?.ChattelsVisibility) && (
              <CCValueField
                label="Chattels"
                value={dataItem.Chattels}
                format={CURRENCY_FORMAT.CURRENCY1}
              />
            )}
            {isFieldVisible(dataItem?.TransferFeesVisibility) && (
              <CCValueField
                label="Transfer fee"
                value={dataItem.TransferFee}
                format={CURRENCY_FORMAT.CURRENCY1}
              />
            )}
            {isFieldVisible(dataItem?.NotesVisibility) && (
              <CCValueField label="Notes" value={dataItem.Notes} />
            )}
          </div>
        </div>
        {(isFieldVisible(dataItem.NewNameAndAddressVisibility) ||
          isFieldVisible(dataItem.OldNameAndAddressVisibility)) && (
          <>
            <hr className="cc-divider" />
            <div className="cc-field-group">
              <div className="cc-form-cols-2">
                {isFieldVisible(dataItem?.NewNameAndAddressVisibility) && (
                  <div className="cc-field">
                    <label className="cc-label">New name and address</label>
                    <CCTextArea
                      readOnly
                      className="cc-form-control"
                      rows={3}
                      value={dataItem.NewNameAndAddress}
                    />
                  </div>
                )}
                {isFieldVisible(dataItem?.OldNameAndAddressVisibility) && (
                  <div className="cc-field">
                    <label className="cc-label">Old name and address</label>
                    <CCTextArea
                      readOnly
                      className="cc-form-control"
                      rows={3}
                      value={dataItem.OldNameAndAddress}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
