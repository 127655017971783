import { DATETIME_FORMAT } from "@common/constants/common-format";
import { Log } from "@common/pages/settings/system-admin/system-logs/model";
import { nameOfFactory } from "@common/utils/common";
import { formatDateByKendo } from "@common/utils/formatting";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";

interface ISystemLogsDetailDialogProps {
  LogDetailData: Log;
  onClose: () => void;
}

const nameOf = nameOfFactory<Log>();

export const SystemLogsDetailDialog = ({
  LogDetailData,
  onClose,
}: ISystemLogsDetailDialogProps) => {
  return (
    <Form
      initialValues={LogDetailData}
      key={JSON.stringify(LogDetailData)}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter } = formRenderProps;
        return (
          <CCDialog
            maxWidth="50%"
            height="auto"
            titleHeader={"Log Entry"}
            badge={[{ content: `ID: ${valueGetter("ID")}` }]}
            onClose={onClose}
            bodyElement={
              <FormElement className="cc-form">
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <CCValueField
                      name={nameOf("System_Name")}
                      label="System"
                      value={valueGetter("System_Name")}
                    />
                    <CCValueField
                      name={nameOf("Sys_CreatedBy")}
                      label="Created by"
                      value={valueGetter("Sys_CreatedBy")}
                    />
                    <CCValueField
                      name={nameOf("Sys_CreatedDate")}
                      label="Created date"
                      value={formatDateByKendo(
                        valueGetter("Sys_CreatedDate"),
                        DATETIME_FORMAT.DATETIME
                      )}
                    />
                    <div className="cc-field">
                      <label className="cc-label">Event 1</label>
                      <Field
                        name={nameOf("Event1")}
                        rows={2}
                        value={valueGetter("Event1")}
                        component={CCTextArea}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Event 2</label>
                      <Field
                        name={nameOf("Event2")}
                        rows={2}
                        value={valueGetter("Event2")}
                        component={CCTextArea}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Event 3</label>
                      <Field
                        name={nameOf("Event3")}
                        rows={2}
                        value={valueGetter("Event3")}
                        component={CCTextArea}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">Message</label>
                      <Field
                        name={nameOf("Message")}
                        rows={4}
                        value={valueGetter("Message")}
                        component={CCTextArea}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Stack trace</label>
                      <Field
                        name={nameOf("StackTrace")}
                        rows={15}
                        value={valueGetter("StackTrace")}
                        component={CCTextArea}
                        readOnly
                      />
                    </div>
                  </div>
                </section>
              </FormElement>
            }
          />
        );
      }}
    />
  );
};
