import { BELL_CHARACTER } from "@common/constants/characters";
import { ChecklistAnswerType } from "@common/constants/enumerations";
import { isNil } from "lodash";

export const convertToObject = (
  listValues: string,
  stepKey: string,
  propKey: string
) => {
  const resultObj = listValues
    .split(BELL_CHARACTER)
    .reduce((currentObj, currentValue, currentIndex) => {
      return {
        ...currentObj,
        [`${propKey}${currentIndex}`]: currentValue,
      };
    }, {});
  return { [stepKey]: resultObj };
};

export const isShowCustomAnswerType = (
  checklistAnswerType: ChecklistAnswerType
) =>
  !isNil(checklistAnswerType) &&
  checklistAnswerType !== ChecklistAnswerType.TextBox &&
  checklistAnswerType !== ChecklistAnswerType.Default &&
  checklistAnswerType !== ChecklistAnswerType.Label &&
  checklistAnswerType !== ChecklistAnswerType.DatePicker;

export const isShowLabelIsHeading = (
  checklistAnswerType: ChecklistAnswerType
) => checklistAnswerType === ChecklistAnswerType.Label;
