import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { WorkflowTypes } from "@app/products/property/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { Label } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const AssessmentAdjustmentsButton = observer(() => {
  const { assessmentsSelected } = useSupplementaryRatesStore();
  const { setListDialog } = usePropertyWorkflowStore();

  //Get label
  const assessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessment
  );
  return (
    <>
      <CCNavButton
        disabled={assessmentsSelected?.length !== 1}
        title={`${assessmentLabel} adjustments`}
        onClick={() =>
          setListDialog([
            {
              type: WorkflowTypes.AssessmentAdjustment_Supplementary,
              data: {},
            },
          ])
        }
      />
    </>
  );
});
