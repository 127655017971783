import { ExistedBuildingPoolsSpas } from "@app/products/building/pools-or-spas/[id]/components/forms/existed/_index";
import { NewBuildingPoolsSpas } from "@app/products/building/pools-or-spas/[id]/components/forms/new/_index";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React from "react";

const ManageBuildingPoolsSpas = observer(() => {
  const isNew = useIsNew();

  if (isNew) {
    return <NewBuildingPoolsSpas />;
  }

  return <ExistedBuildingPoolsSpas />;
});

export default ManageBuildingPoolsSpas;
