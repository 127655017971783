import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { colMeterReadingDevices } from "@app/products/property/meters/reading-devices/config";
import { mockMeterReadingDevices } from "@app/products/property/meters/reading-devices/mock";
import { MeterReadingDevicesDetailTab } from "@app/products/property/meters/reading-devices/[id]/components/reference-sidebar/detail/_index";
import { metersRoute } from "@app/products/property/meters/route";
import { propertyRoute } from "@app/products/property/route";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={metersRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      //  Holding now but can use later
      // <ListViewBookmarkIcon url={PROPERTY_METERS_LIST_ROUTE} />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <MeterReadingDevicesDetailTab /> },
    ],
  });

  return (
    <CCProductListView
      data={mockMeterReadingDevices}
      columnFields={colMeterReadingDevices}
      primaryField={"ReadingDeviceId"}
    />
  );
};
