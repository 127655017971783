export enum UITriggerRegister {
  Officer,
  Contact,
  Contact2,
  Address,
  Source,
  Category,
  Type,
  AddComment,
  AddInspection,
  AddAttachment,
  AddAdditonalAddress,
  AddSurveyor,
  AllocateToPlanner,
  PlannerAssessment,
  ViewAssessment,
  AddMeeting,
  LinkApplication,
  Items,
  EPlanning,
  FileNumber,
  TRIMAdmin,
  SecondaryConsent_Lodge,
  SecondaryConsent_PreliminaryAssessment,
  SecondaryConsent_Issue,
  WithdrawnComments,
  Refuse,
  Lodge,
  ReadyForDecision,
  Appealed,
  NotAppealed,
  AppealComplete,
  AdvertisingNotRequired,
  StatusChange,
  LodgeDateChange,
  CompleteDateChange,
  NC_RiskAssess,
  NC_RiskAssess_ImportanceRating,
  NC_RiskAssess_CapacityToEscalate,
  NC_RiskAssess_ScaleOfBreach,
  NC_RiskAssess_UrgencyRating,
  NC_RiskAssess_LevelOfEnforcement,
  Close,
  IPFromProperty,
  AddIPFromFile,
  Issue,
  PickDescription,
  ResponsibleAuthority,
  TeamLeader,
  PickStateRoad,
  PickRegion,
  SendResponse,
  SendForApproval,
  ClosureReason,
  SendResponseToApplicant,
  NotForVR,
  PickRegulation,
  BatchStatusChange,
  PickStateLocalRoad,
  ChangeStatus,
  ChangeLodgeDate,
  ChangeDecision,
  PickBarrierTypes,
  ConfirmIssue,
  ConfirmRefuse,
  AddRelated,
  BatchComplete,
  PickLGA,
  PickZone,
  AddReferral,
  AddReason,
  Amend,
  Delegate,
  SeniorOfficer,
  PickDynamicQuestionList,
  Transfer,
  ApplicationNumber,
  LodgedDateChange,
  AddIPFromMap,
  RejectedResponse,
  ApprovedResponse,
  PickDebtorNo,
  EnquirySendForApprovalComments,
}
