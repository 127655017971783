import { CORE_GET_TRIM_CONTAINERS_ODATA } from "@app/core/components/common/constant";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { getSettings } from "@common/apis/setting";
import { isSuccessResponse } from "@common/apis/util";
import { Svc_TrimContainer } from "@common/models/corporateSetting";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import { ICCGridProps } from "@components/cc-grid/_index";

export const getTrimContainerODataURL = (
  searchText: string = "",
  showNone: boolean = true,
  electronicOnly: boolean = false
) =>
  `${CORE_GET_TRIM_CONTAINERS_ODATA}(searchText='${searchText}',showNone=${showNone},electronicOnly=${electronicOnly})?$count=true&`;

export const getTrimContainerInputPickerOption = (
  option?: Partial<IOptionInputPicker>,
  grid?: Partial<ICCGridProps>
): IOptionInputPicker => {
  const nameOfTrimContainer = nameOfFactory<Svc_TrimContainer>();
  let recordLabel = "Containers";

  getSettings([ECorporateSettingsField.CorporateSettings_RecordsLabel]).then(
    (response) => {
      if (response && isSuccessResponse(response)) {
        recordLabel = `${response.data?.[0].Value?.toString()} Containers`;
      }
    }
  );
  return {
    dialog: {
      titleHeader: option?.dialog?.titleHeader ?? recordLabel,
      maxWidth: option?.dialog?.maxWidth ?? "60%",
    },
    boxSearch: option?.boxSearch ?? {
      colSearch: nameOfTrimContainer("Name"),
      colShowComboboxSearch: [
        {
          title: "Name",
          field: nameOfTrimContainer("Name"),
        },
      ],
    },
    grid: {
      columnFields: [
        { title: recordLabel, field: nameOfTrimContainer("Name") },
        { title: "Type", field: nameOfTrimContainer("Type") },
        { title: "Description", field: nameOfTrimContainer("Description") },
      ],
      primaryField: nameOfTrimContainer("ID"),
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: [{ field: nameOfTrimContainer("Name"), dir: "asc" }],
      },
      ...grid,
    },
  };
};
