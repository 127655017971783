import { ExistedNoticeRun } from "@app/products/property/schemes/notice-runs/[id]/components/forms/existed/_index";
import { useSchemesNoticeRunsStore } from "@app/products/property/schemes/notice-runs/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import NewNoticeRun from "./components/forms/new/_index";

function ManageNoticeRunRequest() {
  const params: { id: string } = useParams();
  const id = params.id;
  const isNew = useIsNew();
  const { loadNoticeRuns } = useSchemesNoticeRunsStore();

  useEffect(() => {
    if (isNew) return;
    loadNoticeRuns(parseInt(id));
  }, [id, isNew, loadNoticeRuns]);

  if (isNew) {
    return <NewNoticeRun />;
  }
  return <ExistedNoticeRun />;
}

export default observer(ManageNoticeRunRequest);
