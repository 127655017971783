import { proposedAssessmentMaxNumberValidator } from "@app/products/town-planning/ppr/components/action-bar/dialogs/officer-assessment/components/form-steps/proposed-assessment/util";
import { DECIMAL_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";

export const PROPOSED_ASSESSMENT_FORM_STEP = "ProposedAssessment";
export const ProposedAssessmentFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { valueGetter } = formRenderProps;

  return (
    <div className="cc-form">
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Transport data proposed in the planning permit application
            </label>
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Proposed application details has been filled in
            </label>
            <Field
              name={"Flag_ProposedApplicationDetailsFilled"}
              component={CCSwitch}
              checked={valueGetter("Flag_ProposedApplicationDetailsFilled")}
            />
          </div>
        </div>
      </section>
      <br />
      {!valueGetter("Flag_ProposedApplicationDetailsFilled") && (
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">
                Additional dwellings
                <CCTooltip
                  type="validator"
                  position="right"
                  content={"Total number of additional dwellings on site"}
                />
              </label>
              <Field
                name={"TotalNumberOfAdditionalDwellings_Proposed"}
                component={CCNumericTextBox}
                format={DECIMAL_FORMAT.DECIMAL1}
                placeholder={"Additional dwellings"}
                validator={[
                  requiredValidator,
                  proposedAssessmentMaxNumberValidator,
                ]}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Additional bicycle spaces
                <CCTooltip
                  type="info"
                  position="right"
                  content={
                    "Total number of additional bicycle spaces/parking on site"
                  }
                />
              </label>
              <Field
                name={"TotalNumberOfAdditionalBicycleSpaces_Proposed"}
                component={CCNumericTextBox}
                format={DECIMAL_FORMAT.DECIMAL1}
                placeholder={"Additional bicycle spaces"}
                validator={proposedAssessmentMaxNumberValidator}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">
                Number of showers
                <CCTooltip
                  type="info"
                  position="right"
                  content={
                    "Total number of showers (for end of trip facilities only)"
                  }
                />
              </label>
              <Field
                name={"TotalNumberOfShowers_Proposed"}
                component={CCNumericTextBox}
                format={DECIMAL_FORMAT.DECIMAL1}
                placeholder={"Number of showers"}
                validator={proposedAssessmentMaxNumberValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Additional car parks
                <CCTooltip
                  type="info"
                  position="right"
                  content={"Total number of additional car parks on site"}
                />
              </label>
              <Field
                name={"TotalNumberOfAdditionalCarParks_Proposed"}
                component={CCNumericTextBox}
                format={DECIMAL_FORMAT.DECIMAL1}
                placeholder={"Additional car parks"}
                validator={proposedAssessmentMaxNumberValidator}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Additional crossovers</label>
              <Field
                name={"TotalNumberOfAdditionalCrossovers_Proposed"}
                component={CCNumericTextBox}
                format={DECIMAL_FORMAT.DECIMAL1}
                placeholder={"Additional crossovers"}
                validator={proposedAssessmentMaxNumberValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Additional commercial space
                <CCTooltip
                  type="info"
                  position="right"
                  content={"Total additional commercial floor area (sqm)"}
                />
              </label>
              <Field
                name={"TotalAdditionalFloorSpace_Commercial_Proposed"}
                component={CCNumericTextBox}
                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                placeholder={"Additional commercial space"}
                validator={proposedAssessmentMaxNumberValidator}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">
                Additional retail space
                <CCTooltip
                  type="info"
                  position="right"
                  content={"Total additional retail floor area (sqm)"}
                />
              </label>
              <Field
                name={"TotalAdditionalFloorSpace_Retail_Proposed"}
                component={CCNumericTextBox}
                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                placeholder={"Additional retail space"}
                validator={proposedAssessmentMaxNumberValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Additional industrial space
                <CCTooltip
                  type="info"
                  position="right"
                  content={"Total additional industrial floor area (sqm)"}
                />
              </label>
              <Field
                name={"TotalAdditionalFloorSpace_Industrial_Proposed"}
                component={CCNumericTextBox}
                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                placeholder={"Additional industrial space"}
                validator={proposedAssessmentMaxNumberValidator}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">
                Additional government space
                <CCTooltip
                  type="info"
                  position="right"
                  content={"Total additional government floor area (sqm)"}
                />
              </label>
              <Field
                name={"TotalAdditionalFloorSpace_Government_Proposed"}
                component={CCNumericTextBox}
                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                placeholder={"Additional government space"}
                validator={proposedAssessmentMaxNumberValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Additional education space</label>
              <Field
                name={"TotalAdditionalFloorSpace_Education_Proposed"}
                component={CCNumericTextBox}
                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                placeholder={"Additional education space"}
                validator={proposedAssessmentMaxNumberValidator}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Additional health space</label>
              <Field
                name={"TotalAdditionalFloorSpace_Health_Proposed"}
                component={CCNumericTextBox}
                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                placeholder={"Additional health space"}
                validator={proposedAssessmentMaxNumberValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Additional other space
                <CCTooltip
                  type="info"
                  position="right"
                  content={"Total additional other floor area (sqm)"}
                />
              </label>
              <Field
                name={"TotalAdditionalFloorSpace_Other_Proposed"}
                component={CCNumericTextBox}
                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                placeholder={"Additional other space"}
                validator={proposedAssessmentMaxNumberValidator}
              />
            </div>
          </div>
        </section>
      )}
    </div>
  );
};
