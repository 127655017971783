import { EventBatchCloseDialog } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/event-bactch-close/_index";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useSelectionListener } from "@components/cc-grid/hooks/useSelectionListener";
import { SelectTypeEnum } from "@components/cc-grid/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface ICloseEventsButton {
  isDisabled?: boolean;
  isVisible?: boolean;
  gridId?: string;
}

export const CloseEventsButton = observer(
  ({ isDisabled = false, isVisible = true, gridId }: ICloseEventsButton) => {
    const { gridSelectedIds } = useCCProductListViewStore();

    const {
      isLoadingBatchCloseEvent,
      batchCloseEventHandler,
      isShowBatchCloseEventsDialog,
      eventBatchClose,
      setIsShowBatchCloseEventsDialog,
      setEventBatchClose,
    } = useCRMSEventStore();
    const { loader } = useSelectionListener(gridId);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleOnSubmit = async (data: any) => {
      setIsLoading(true);
      let rowIds = gridSelectedIds;
      if (loader.current.peek.type === SelectTypeEnum.ALL) {
        rowIds = (
          await loader.current.process({ $select: ["ID"], $flat: true })
        ).data;
      }
      setIsLoading(false);
      batchCloseEventHandler({
        EventBatchClose: data,
        SelectedEvent: rowIds,
      });
    };

    if (!isVisible) return null;

    return (
      <>
        <CCNavButton
          title={"Close Event(s)"}
          onClick={() => {
            setEventBatchClose({
              DateClosed: new Date(),
              ClosureComment: "",
              FinalNotification: false,
              Flag_HardRubbish: false,
            });
            setIsShowBatchCloseEventsDialog(true);
          }}
          name={ActionSubmitActions.CloseEvent}
          disabled={
            isLoadingBatchCloseEvent ||
            gridSelectedIds.length === 0 ||
            isDisabled
          }
        />

        {isShowBatchCloseEventsDialog && (
          <EventBatchCloseDialog
            eventBatchClose={eventBatchClose}
            onClosePopup={() => {
              setIsShowBatchCloseEventsDialog(false);
              setEventBatchClose(undefined);
            }}
            onSubmit={handleOnSubmit}
            isLoading={isLoadingBatchCloseEvent || isLoading}
          />
        )}
      </>
    );
  }
);
