import { mockRegisterAccountGrid } from "@app/products/property/registers/[id]/components/dialogs/form-steps/transfer-to-another-account/components/form-elements/to-account/mock";
import { IRegisterAccountSearch } from "@app/products/property/registers/[id]/components/dialogs/form-steps/transfer-to-another-account/components/form-elements/to-account/model";
import { sleep } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";

export const loadRegisterAccountLookup = async (filter: FilterDescriptor) => {
  const registerAccountLookupList = mockRegisterAccountGrid.map(
    (register: IRegisterAccountSearch) => {
      return {
        ...register,
        _SearchAccountDescription:
          register.AccountId + " - " + register.AccountDescription,
        _SearchAddress: register.AccountId + " - " + register.PropertyAddress,
        _SearchHistoricalReferenceTag:
          register.AccountId + " - " + register.HistoricalReference,
        _SearchReferenceTag:
          register.AccountId + " - " + register.AccountReference,
        _SearchName: register.AccountId + " - " + register.AccountName,
      };
    }
  );
  const data = registerAccountLookupList.slice();
  await sleep(1000);
  return filterBy(data, filter);
};
