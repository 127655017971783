import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const deleteReport = async (
  listId?: number[]
): Promise<APIResponse<IdentityPacket | any>> => {
  try {
    return await CoreAPIService.getClient().delete(
      `/api/core/internal/integratedreports/removereports`,
      {
        data: JSON.stringify(listId),
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
