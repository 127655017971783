import { CCTooltip } from "@components/cc-tooltip/_index";
import React from "react";
interface IDescriptionLabel {
  title: string;
  description: string;
}

export const DescriptionLabel = ({ title, description }: IDescriptionLabel) => {
  return (
    <>
      {description && description?.length ? (
        <>
          <label className="cc-label">{title}</label>
          <CCTooltip type="info" position="right" content={description} />
        </>
      ) : (
        <label className="cc-label">{title}</label>
      )}
    </>
  );
};
