import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const leaseTransfersRoute: ICCRoute = {
  path: "lease-transfers",
  name: "Lease transfers",
  enum: eMenuAction.Change_of_Ownership_Lease_Transfers,
  children: [
    {
      path: "to-be-processed",
      name: "To be processed",
      enum: eMenuAction.Change_of_Ownership_Folder_Notice_Of_Sales_TobeProcessed,
      component: require("./to-be-processed/_index").default,
    },
    {
      path: "list-by-import-file",
      name: "List by import file",
      enum: eMenuAction.Change_of_Ownership_Folder_Notice_Of_Sales_ListbyImportFile,
      component: require("./list-by-import-file/_index").default,
    },
  ],
};
