import { ISpecialReadingDialogData } from "@app/products/property/meters/components/dialogs/enter-special-reading/model";
import { mockEditSpecialReadingData } from "@app/products/property/meters/special-reading-list/components/action-bar/buttons/edit-special-reading/mock";
import { findDataItem } from "@app/products/property/meters/special-reading-list/components/action-bar/buttons/enter-special-reading/util";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { sleep } from "@common/utils/common";

export const loadEditSpecialReadingData = async (
  readingData: any
): Promise<ISpecialReadingDialogData> => {
  await sleep(500);
  let fee: number;
  typeof readingData.FeeAmount === "number"
    ? (fee = readingData.FeeAmount)
    : (fee = 0);
  const specialReadType: IKeyValuePacket | undefined = findDataItem(
    mockEditSpecialReadingData._option!.ReasonForTheSpecialReading.Data,
    readingData.SpecialReadType
  );
  return {
    ...mockEditSpecialReadingData,
    ...readingData,
    ReasonForTheSpecialReading: specialReadType,
    Fee: fee,
  };
};
