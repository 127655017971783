import { history } from "@/AppRoutes";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { postSavePermitType } from "@app/products/local-laws/system-admin/permit-types/[id]/api";
import { LOCAL_LAWS_SYSTEM_ADMIN_PERMIT_TYPES_ROUTE } from "@app/products/local-laws/system-admin/permit-types/[id]/constant";
import { PermitType } from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { IdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const SaveButtonSystemAdminPermitType = observer(() => {
  //#region STORE ========/
  const { submitFormGetData, isLoadingForm, setIsLoadingForm } =
    useFlexibleFormStore();
  //#endregion STORE =====/

  //#region HOOK ========/
  const { pushNotificationPortal } = useNotificationPortalStore();
  const isNew = useIsNew();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.LLPermits_Form_PermitType,
    productType: PRODUCT_TYPE_NUMBER.LLPermits,
  });
  const { cancelToken } = useCancelRequest();
  //#endregion HOOK =====/

  const savePermitTypeSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: ({ initialData }) => {
          const permitType: PermitType = initialData;
          return postSavePermitType(permitType, cancelToken());
        },
        handleSuccess: async ({ dataFromApi }) => {
          const response: APIResponse<IdentityPacket> = dataFromApi;
          if (isNew) {
            pushNotificationPortal({
              title: "Permit type saved successfully.",
              type: "success",
              callBack: () => {
                history.replace(
                  LOCAL_LAWS_SYSTEM_ADMIN_PERMIT_TYPES_ROUTE +
                    `/${response?.data?.ID}`
                );
              },
            });
          } else {
            pushNotificationPortal({
              title: "Permit type saved successfully.",
              type: "success",
            });
          }
          return false;
        },
        handleError: (error) => {
          const { errorFromApi, initialData } = error;
          pushNotificationPortal({
            title: initialData?.errorMsg ?? "Save permit type failed.",
            type: "error",
            description: errorFromApi.error,
            autoClose: false,
          });
        },
      },
    ],
  });

  const handleClickButton = async () => {
    const permitType: PermitType = await submitFormGetData("GeneralForm");

    if (permitType) {
      setIsLoadingForm(true);
      await savePermitTypeSlider.fetchApi({
        initialData: permitType,
      });
      setIsLoadingForm(false);
    }
  };

  return (
    <CCNavButton
      title="Save"
      onClick={handleClickButton}
      disabled={
        isLoadingForm ||
        isLoadingPermission ||
        !checkPermissions([
          FormAction.CORE_ALLOW_SAVE,
          FormAction.CORE_ALLOW_EDIT,
        ])
      }
    />
  );
});
