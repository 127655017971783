import { BOOLEAN_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { IOptionInputPicker } from "@components/cc-input-picker-search-multi-select/model";

export { iconColumnViewConfigAlert as colHMPremisesAlert } from "@app/products/crms/config";

// @TODO: Need to integate later
const MOCK_URL = "";
export const HMUrl = {
  // HM CRUD API
  CREATE_HM: "",
  GET_HM: "",
  UPDATE_HM: "",
  DELETE_HM: "",

  // HM Form OData
  GET_FORM_HM: "",

  // HM View OData
  GET_VIEW_HM_PREMISES_REGISTER:
    "/odata/hm/internal/hmregister/GetHMPremisesRegister()?$count=true&",
  GET_VIEW_HM_PREMISES:
    "/odata/hm/internal/hmpremises/GetHMPremisesView()?$count=true&",
  GET_VIEW_HM_PREMISES_BYSTARRATING:
    "/odata/hm/internal/hmregister/GetHMPremisesRegister()?$count=true&",
  GET_VIEW_HM_PREMISES_PCBA:
    "/odata/hm/internal/hmpremises/GetHMPCBAPremisesView()?$count=true&",
  GET_VIEW_HM_PREMISES_PRESCRIBEDACCOM:
    "/odata/hm/internal/hmpremises/GetHMPremisesPrescribedAccom()?$count=true&",
  GET_VIEW_HM_PREMISES_POOLSSPAS:
    "/odata/hm/internal/hmregister/GetHMPremisesRegister()?$count=true&",
  GET_VIEW_HM_PREMISES_COOLINGTOWERS:
    "/odata/hm/internal/hmregister/GetHMPremisesRegister()?$count=true&",
  GET_VIEW_HM_PREMISES_DRINKINGWATER:
    "/odata/hm/internal/hmregister/GetHMPremisesRegister()?$count=true&",
  GET_VIEW_HM_PREMISES_FASTCHOICES:
    "/odata/hm/internal/hmregister/GetHMPremisesRegister()?$count=true&",
  GET_VIEW_HM_PREMISES_FOODSTALLS:
    "/odata/hm/internal/hmregister/GetHMPremisesRegister()?$count=true&",
  GET_VIEW_HM_PREMISES_FOODVEHICLES:
    "/odata/hm/internal/hmregister/GetHMPremisesRegister()?$count=true&",
  GET_VIEW_HM_PREMISES_OSFEES:
    "/odata/hm/internal/hmregister/GetHMPremisesRegister()?$count=true&",
  GET_VIEW_HM_PREMISES_NONRENEWABLE:
    "/odata/hm/internal/hmpremises/GetHMPremisesNonRenewableView()?$count=true&",
  GET_VIEW_HM_PREMISES_STREATRADER:
    "/odata/hm/internal/hmregister/GetHMPremisesRegister()?$count=true&",
  GET_VIEW_HM_PREMISES_PRELODGEMENTS:
    "/odata/hm/internal/hmpremises/GetHMPrelodgementsPremisesView()?$count=true&",
  GET_VIEW_HM_PREMISES_CLOSURES:
    "/odata/hm/internal/hmpremises/GetHMPremisesClose()?$count=true&",
  GET_VIEW_HM_PREMISES_PENDINGTRANSFERS:
    "/odata/hm/internal/hmregister/GetHMPremisesRegister()?$count=true&",
  GET_VIEW_HM_PREMISES_DUECONDITIONS:
    "/odata/hm/internal/hmregister/GetHMPremisesRegister()?$count=true&",
  GET_VIEW_HM_PREMISES_COMPCONDITIONS:
    "/odata/hm/internal/hmregister/GetHMPremisesRegister()?$count=true&",
  GET_VIEW_HM_PREMISES_ALERTS:
    "/odata/hm/internal/hmpremises/GetHMPremisesAlertsView()?$count=true&",
  GET_VIEW_HM_TOBACCO_REGISTER:
    "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_TOBACCO_BYTYPE: "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_TOBACCO_BYSUBURB:
    "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_TOBACCO_BYAREA: "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_TOBACCO_BYOFFICER:
    "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_TOBACCO_PRELODGEMENTS:
    "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_TOBACCO_CLOSURES:
    "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_TOBACCO_PENDINGTRANSFERS:
    "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_FOOD_PICK_LIST:
    "/odata/hm/internal/healthmanager/GetFoodPickList()",
  GET_VIEW_HM_TOBACCO_PICK_LIST:
    "/odata/hm/internal/healthmanager/GetTobaccoPickList()",
  GET_VIEW_HM_NOTICES:
    "/odata/hm/internal/hmnotices/GetHMNoticesView()?$count=true&",

  // Mock HM View OData
  GET_VIEW_HM_RENEWALS_ALLBYDUESTATUS: MOCK_URL,
  GET_VIEW_HM_RENEWALS_ALLBYTYPE: MOCK_URL,
  GET_VIEW_HM_RENEWALS_REQUIRESRENEWALFEE: MOCK_URL,
  GET_VIEW_HM_RENEWALS_REQUIRESNOTICE: MOCK_URL,
  GET_VIEW_HM_RENEWALS_NOTICESENT: MOCK_URL,
  GET_VIEW_HM_RENEWALS_NOTICERECEIVED: MOCK_URL,
  GET_VIEW_HM_RENEWALS_FEESOUTSTANDING: MOCK_URL,
  GET_VIEW_HM_RENEWALS_REQUIRESCERTIFICATEPAID: MOCK_URL,
  GET_VIEW_HM_RENEWALS_REQUIRESCERTIFICATE: MOCK_URL,
  GET_VIEW_HM_INSPSCHEDULE_CALENDAR: MOCK_URL,
  GET_VIEW_HM_COMPLAINTS_BYREFNO: MOCK_URL,
  GET_VIEW_HM_COMPLAINTS_BYCATEGORY: MOCK_URL,
  GET_VIEW_HM_COMPLAINTS_BYSTATUS: MOCK_URL,
  GET_VIEW_HM_SAMPLES_BYDATE: MOCK_URL,
  GET_VIEW_HM_SAMPLES_BYTYPE: MOCK_URL,
  GET_VIEW_HM_SAMPLES_BYOFFICER: MOCK_URL,
  GET_VIEW_HM_SAMPLES_BYMONTH: MOCK_URL,
  GET_VIEW_HM_SAMPLES_BYSTATUS: MOCK_URL,
  GET_VIEW_HM_SAMPLES_BYSURVEY: MOCK_URL,
  GET_VIEW_HM_SAMPLES_SURVEYS: MOCK_URL,
  GET_VIEW_HM_SAMPLES_LASTSAMPLED: MOCK_URL,
  GET_VIEW_HM_RECALLS_BYTRADINGNAME: MOCK_URL,
  GET_VIEW_HM_RECALLS_BYMONTH: MOCK_URL,
  GET_VIEW_HM_RECALLS_BYNUMBER: MOCK_URL,
  GET_VIEW_HM_FINANCE_OSFEES: MOCK_URL,
  GET_VIEW_HM_FINANCE_PAIDFEES: MOCK_URL,
  GET_VIEW_HM_FINANCE_FEESALL: MOCK_URL,
  GET_VIEW_HM_FINANCE_NOTSENTTOFINANCE: MOCK_URL,
  GET_VIEW_HM_FINANCE_SENTTOFINANCE: MOCK_URL,
  GET_VIEW_HM_FSR_LOG: MOCK_URL,
  GET_VIEW_HM_FSR_PREMISESREPORT: MOCK_URL,
  GET_VIEW_HM_FSR_PREMISESTYPES: MOCK_URL,
  GET_VIEW_HM_FSR_INSPECTIONSTYPES: MOCK_URL,
  GET_VIEW_HM_FSR_NOTICETYPES: MOCK_URL,
  GET_VIEW_HM_FSR_COMPLAINTTYPES: MOCK_URL,
  GET_VIEW_HM_FSR_KEYWORDS: MOCK_URL,
  GET_VIEW_HM_GOVTREPORTS_TOBACCORETAILERS: MOCK_URL,
  GET_VIEW_HM_GOVTREPORTS_TOBACCOINSPECTIONS: MOCK_URL,
  GET_VIEW_HM_GOVTREPORTS_VENDINGMACHINES: MOCK_URL,
  GET_VIEW_HM_GOVTREPORTS_EATINFACILITIES: MOCK_URL,
  GET_VIEW_HM_GOVTREPORTS_OUTDOORDINING: MOCK_URL,
  GET_VIEW_HM_GOVTREPORTS_LICENCED: MOCK_URL,
  GET_VIEW_HM_SYSTEMADMIN_PREMISESTYPES: MOCK_URL,
  GET_VIEW_HM_SYSTEMADMIN_CLASSIFICATIONS: MOCK_URL,
  GET_VIEW_HM_SYSTEMADMIN_RISKS: MOCK_URL,
  GET_VIEW_HM_SYSTEMADMIN_NCITEMS: MOCK_URL,
  GET_VIEW_HM_SYSTEMADMIN_INSPCOMMENTS: MOCK_URL,
  GET_VIEW_HM_SYSTEMADMIN_SYSTEMSETTINGS: MOCK_URL,
  GET_VIEW_HM_RECYCLEBIN_PREMISES: MOCK_URL,
  GET_VIEW_HM_RECYCLEBIN_PREMISESTYPES: MOCK_URL,
  GET_VIEW_HM_RECYCLEBIN_FEES: MOCK_URL,
  GET_VIEW_HM_RECYCLEBIN_INSPECTIONS: MOCK_URL,
  GET_VIEW_HM_RECYCLEBIN_SAMPLES: MOCK_URL,
};

//#region TODO: remove after merge branch CHM-180 ========/
export interface Svc_HMPremisesType {
  ID: number;
  PremisesClassification: string;
  RiskClassification: string;
  RenewalMethod_Name: string;
  InspectionFrequency: number | null;
  RegistrationFee_Amount: number | null;
  RenewalFee_Amount: number | null;
  TransferFee_Amount: number | null;
  ApplicationFee_Amount: number | null;
  Flag_NoOfEmployees: boolean;
  Flag_NoOfUnits: boolean;
  Flag_NoOfBeds: boolean;
  Flag_TemporaryPremises: boolean;
  Flag_TobaccoRetail: boolean;
  Flag_FoodVehicle: boolean;
  Name: string;
  Description: string;
  Category: string;
  PremisesCount: number;
}
const nameOf = nameOfFactory<Svc_HMPremisesType>();

const colPremisesTypes: IColumnFields[] = [
  { title: "Classification", field: nameOf("PremisesClassification") },
  { title: "Name", field: nameOf("Name") },
  { title: "Risk", field: nameOf("RiskClassification") },
  { title: "Category", field: nameOf("Category") },
  { title: "Renewal", field: nameOf("RenewalMethod_Name") },
  { title: "Inspection Frequency", field: nameOf("InspectionFrequency") },
  { title: "Registration Fee", field: nameOf("RegistrationFee_Amount") },
  { title: "Renewal Fee", field: nameOf("RenewalFee_Amount") },
  { title: "Transfer Fee", field: nameOf("TransferFee_Amount") },
  {
    title: "Employees",
    field: nameOf("Flag_NoOfEmployees"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    title: "Units",
    field: nameOf("Flag_NoOfUnits"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    title: "Beds",
    field: nameOf("Flag_NoOfBeds"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    title: "Food stall",
    field: nameOf("Flag_FoodVehicle"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    title: "Tobacco",
    field: nameOf("Flag_TobaccoRetail"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];
//#endregion TODO: remove after merge branch CHM-180 =====/

export const optionPremisesTypes: IOptionInputPicker = {
  dialog: {
    titleHeader: "Premises Types",
  },
  boxSearch: {
    colSearch: nameOf("Name"),
    colShowComboboxSearch: [
      {
        title: "Name",
        field: nameOf("Name"),
      },
    ],
  },
  grid: {
    columnFields: colPremisesTypes,
    primaryField: nameOf("ID"),
    dataUrl: "odata/hm/internal/hmpremisestypes/GetPremisesTypes",
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 15,
    state: {
      sort: [
        {
          field: nameOf("PremisesClassification"),
          dir: "asc",
        },
      ],
    },
  },
};
