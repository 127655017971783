import { TownPlanning } from "@app/products/town-planning/[id]/model";
import { mockTownPlanning } from "./mock";

export const loadAllPlannings = (): TownPlanning[] => {
  const plannings: TownPlanning[] = JSON.parse(
    JSON.stringify(mockTownPlanning)
  );

  return plannings;
};
