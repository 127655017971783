import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type IRegistersSecondaryConsentFormProps = {
  formRenderProps: FormRenderProps;
};

export const RegistersSecondaryConsentForm = observer(
  ({ formRenderProps }: IRegistersSecondaryConsentFormProps) => {
    const { valueGetter } = formRenderProps;
    const { getConfigDataField } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();

    const fieldSendAllocationEmailToPlanner_SC = getConfigDataField(
      ECorporateSettingsField.TP_SendAllocationEmailToPlanner_SC
    );
    const fieldSendRequesterEmailWhenAllocatePlanner_SC = getConfigDataField(
      ECorporateSettingsField.TP_SendRequesterEmailWhenAllocatePlanner_SC
    );
    const fieldNotifyAdminByCCWhenAllocatedToRequestor_SC = getConfigDataField(
      ECorporateSettingsField.TP_NotifyAdminByCCWhenAllocatedToRequestor_SC
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {fieldSendAllocationEmailToPlanner_SC && (
            <div className="cc-field">
              <CCLabel
                title={fieldSendAllocationEmailToPlanner_SC?.Title ?? ""}
              />
              <Field
                name={fieldSendAllocationEmailToPlanner_SC.FieldName}
                component={CCSwitch}
                checked={valueGetter(
                  fieldSendAllocationEmailToPlanner_SC.FieldName
                )}
                disabled={!isEditing}
              />
            </div>
          )}
          {fieldSendRequesterEmailWhenAllocatePlanner_SC && (
            <div className="cc-field">
              <CCLabel
                title={
                  fieldSendRequesterEmailWhenAllocatePlanner_SC?.Title ?? ""
                }
              />
              <Field
                name={fieldSendRequesterEmailWhenAllocatePlanner_SC.FieldName}
                component={CCSwitch}
                checked={valueGetter(
                  fieldSendRequesterEmailWhenAllocatePlanner_SC.FieldName
                )}
                disabled={!isEditing}
              />
            </div>
          )}
          {fieldNotifyAdminByCCWhenAllocatedToRequestor_SC && (
            <div className="cc-field">
              <CCLabel
                title={
                  fieldNotifyAdminByCCWhenAllocatedToRequestor_SC?.Title ?? ""
                }
                isMandatory={
                  fieldNotifyAdminByCCWhenAllocatedToRequestor_SC.IsMandatory
                }
              />
              <Field
                name={fieldNotifyAdminByCCWhenAllocatedToRequestor_SC.FieldName}
                component={CCInput}
                disabled={!isEditing}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
