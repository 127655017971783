import {
  Application,
  Application_Decision_Int,
} from "@app/products/town-planning/ppr/[id]/model";
import { getPPRDecisions } from "@app/products/town-planning/ppr/components/action-bar/dialogs/change-decision/api";
import { ChangeDecisionData } from "@app/products/town-planning/ppr/components/action-bar/dialogs/change-decision/model";
import { ApplicationDecisionDetails } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/application-decision/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<ChangeDecisionData>();

interface IChangeDecisionDialogProps {
  onClose: () => void;
  onSubmit: (data: ChangeDecisionData) => void;
  isLoading: boolean;
  notifications?: IAppNotificationItem[];
  application?: Application;
}

export const ChangeDecisionDialog = observer(
  ({
    onClose,
    onSubmit,
    notifications,
    isLoading,
    application,
  }: IChangeDecisionDialogProps) => {
    const [decisions, setDecisions] = useState<IKeyValuePacket[]>([]);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [isGettingData, setIsGettingData] = useState(false);
    const onChangeRef = useRef<any>();
    const initialValues = useMemo<ChangeDecisionData>(() => {
      const applicationDecisionInt = application?.Decision_ENUM
        ? Application_Decision_Int[application.Decision_ENUM]
        : null;
      setIsGettingData(true);
      if (
        decisions.length > 0 &&
        applicationDecisionInt &&
        decisions.some(
          ({ Key }: IKeyValuePacket) => Key === applicationDecisionInt
        )
      ) {
        setIsGettingData(false);
        if (onChangeRef?.current) {
          onChangeRef.current(nameOf("Decision_ENUM"), {
            value: applicationDecisionInt,
          });
        }
        return {
          Decision_ENUM: applicationDecisionInt,
        };
      }
      setIsGettingData(false);
      return { Decision_ENUM: null };
    }, [application, decisions]);

    const loadDecisions = () => {
      setIsGettingData(true);
      getPPRDecisions().then((response) => {
        let errorResponse = undefined;
        let newDecision: IKeyValuePacket[] = [];
        if (isSuccessResponse(response)) {
          newDecision = response.data;
        } else {
          errorResponse = {
            status: response.status,
            error: response.error,
          };
        }
        setDecisions(newDecision);
        setResponseLoadError(errorResponse);
        setIsGettingData(false);
      });
    };

    useEffectOnce(() => {
      loadDecisions();
    });

    return (
      <Form
        initialValues={initialValues}
        onSubmitClick={(event) =>
          onSubmit(event.values as ApplicationDecisionDetails)
        }
        render={({
          onSubmit: onFormSubmit,
          valid,
          onChange,
        }: FormRenderProps) => {
          onChangeRef.current = onChange;
          return (
            <FormElement>
              <CCDialog
                titleHeader={"Application Decision"}
                onClose={onClose}
                maxWidth={"25%"}
                height={"auto"}
                disabled={isLoading}
                bodyElement={
                  isGettingData ? (
                    <Loading isLoading />
                  ) : responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={loadDecisions}
                    />
                  ) : (
                    <div className="cc-form">
                      <CCLocalNotification
                        defaultNotifications={notifications}
                      />
                      <section className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">
                              Status
                              <CCTooltip type="validator" position="right" />
                            </label>
                            <Field
                              name={nameOf("Decision_ENUM")}
                              component={CCSearchComboBox}
                              data={decisions}
                              isUseDefaultOnchange
                              validator={requiredValidator}
                              textField="Value"
                              dataItemKey="Key"
                            />
                          </div>
                        </div>
                      </section>
                    </div>
                  )
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button className="cc-dialog-button" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      disabled={!valid || !!responseLoadError}
                      onClick={onFormSubmit}
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
