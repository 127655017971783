import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colAdditionalParcelDetailsGrid1: IColumnFields[] = [
  {
    field: "StandardParcelIdentifiers",
    title: "Standard Parcel Identifiers",
  },
];
export const colAdditionalParcelDetailsGrid2: IColumnFields[] = [
  {
    field: "VolumeFolio",
    title: "Volume Folio",
  },
  {
    field: "TitleViewLink",
    title: "Title View Link",
  },
];
export const colAdditionalParcelDetailsGrid3: IColumnFields[] = [
  {
    field: "MapName",
    title: "Map Name",
  },
  {
    field: "Page",
    title: "Page",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Grid",
    title: "Grid",
  },
];
export const colCrownAllotmentAndMemorial: IColumnFields[] = [
  {
    field: "Lots",
    title: "Lots",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "PlanNumber",
    title: "Plan Number",
  },
  {
    field: "ViewPlanLink",
    title: "View Plan Link",
  },
  {
    field: "Section",
    title: "Section",
  },
  {
    field: "Block",
    title: "Block",
  },
  {
    field: "Allotment",
    title: "Allotment",
  },
  {
    field: "Portion",
    title: "Portion",
  },
  {
    field: "SubDivision",
    title: "Sub Division",
  },
  {
    field: "ParishDescription",
    title: "Parish Description",
  },
];
export const colOtherIdentifiers: IColumnFields[] = [
  {
    field: "Type",
    title: "Type",
  },
  {
    field: "Name",
    title: "Name",
  },
  {
    field: "ReferenceNumber",
    title: "Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
