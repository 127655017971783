import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import { filter } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(
  ({ dataSetting, formRenderProps }: IGlobalComponentFormProps) => {
    const { isEditing } = useSettingGlobalManageStore();
    const { valueGetter, onChange } = formRenderProps;
    const data = dataSetting.SettingFields;
    const changeTypeValue = (value: string) =>
      filter(data[0]?.LookupItems, { Id: value })[0];
    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">{data[0]?.Title}</label>
            <Field
              name={data[0]?.FieldName}
              component={CCSearchComboBox}
              data={data[0]?.LookupItems}
              value={changeTypeValue(valueGetter(data[0].FieldName))}
              textField="Name"
              dataItemKey="Id"
              disabled={!isEditing}
              onChange={(e: ComboBoxChangeEvent) => {
                onChange(data[0]?.FieldName, {
                  value: e.value?.Id ?? null,
                });
              }}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">{data[1]?.Title}</label>
            <Field
              name={data[1]?.FieldName}
              component={CCInput}
              placeholder={data[1]?.Title}
              disabled={!isEditing}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">{data[2]?.Title}</label>
            <Field
              name={data[2]?.FieldName}
              component={CCInput}
              placeholder={data[2]?.Title}
              disabled={!isEditing}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">{data[3]?.Title}</label>
            <Field
              name={data[3]?.FieldName}
              component={CCInput}
              placeholder={data[3]?.Title}
              disabled={!isEditing}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">{data[4]?.Title}</label>
            <Field
              name={data[4]?.FieldName}
              component={CCNumericTextBox}
              placeholder={data[4]?.Title}
              disabled={!isEditing}
            />
          </div>
        </div>
      </section>
    );
  }
);
