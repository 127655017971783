import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { NoticeDetailTab } from "@app/core/notices/[id]/components/reference-sidebar/details/_index";
import { NoticeHistoryTab } from "@app/core/notices/[id]/components/reference-sidebar/history/_index";
import { NOTICES_ROUTE } from "@app/core/notices/[id]/constant";
import { AnimalActionBarNavDropdown } from "@app/products/animals/components/action-bar/nav-dropdown/_index";
import { AnimalNoticesByDateBookmark } from "@app/products/animals/notices/by-date/util";
import { ANIMALS_NOTICES_LIST_VIEW_URL } from "@app/products/animals/notices/constant";
import { Svc_Animals_Notice } from "@app/products/animals/notices/model";
import { animalRoute } from "@app/products/animals/route";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";
import { noticesRoute } from "../route";
import { colAnimalsNoticesByDate } from "./config";

const nameOf = nameOfFactory<Svc_Animals_Notice>();

export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_Notice,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });

  useCCListViewActionBar({
    title: animalRoute.name,
    leftComponents: [
      <AnimalActionBarNavDropdown category={noticesRoute.path} />,
    ],
    centerComponents: [
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.Animals}
        dataSetFilter={MAILMERGEDATASET.Animals_Notices}
        recordType={RECORDTYPE.CORE_Notice}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <DeleteButton
        recordType={RECORDTYPE.CORE_Notice}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={NOTICES_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_Notice}
        detail={AnimalNoticesByDateBookmark.getBookmarkListViewDisplayName}
        displayName={
          AnimalNoticesByDateBookmark.getBookmarkListViewDetailRecord
        }
        listViewDetail={AnimalNoticesByDateBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          AnimalNoticesByDateBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <NoticeDetailTab /> },
      {
        title: "History",
        component: <NoticeHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={colAnimalsNoticesByDate}
        dataUrl={ANIMALS_NOTICES_LIST_VIEW_URL}
        primaryField={nameOf("ID")}
        state={{
          sort: [{ field: nameOf("DateServed"), dir: "desc" }],
        }}
      />
    </LoadingPermissionWrapper>
  );
});
