import { DTO_Component_Entities } from "@app/products/property/titles/[id]/components/child-screens/owners/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const getTitleOwners = async (
  titleId: number,
  includePastNames: boolean
): Promise<DTO_Component_Entities | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Component_Entities>(
        `api/property/internal/title/${titleId}/${includePastNames}/entities`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
