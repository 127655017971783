import { CRMSViews } from "@app/products/crms/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";

export const allEventsRoute: ICCRoute = {
  path: "all-events",
  name: "All Events",
  enum: CORPORATE_SETTINGS_FIELD.CUSTOMERSERVICE_Views_AllEvents,
  children: [
    {
      path: "by-action-person",
      name: "By Action Officer",
      enum: CRMSViews.All_ByActionOfficer,
      component: require("./by-action-person/_index").default,
    },
    {
      path: "by-coordinator-person",
      name: "By Coordinator",
      enum: CRMSViews.All_ByCoordinator,
      component: require("./by-coordinator-person/_index").default,
    },
    {
      path: "by-refno",
      name: "By Ref No",
      enum: CRMSViews.All_ByRefNo,
      component: require("./by-refno/_index").default,
    },
    {
      path: "by-category",
      name: "By Category",
      enum: CRMSViews.All_ByCategory,
      component: require("./by-category/_index").default,
    },
    {
      path: "by-street",
      name: "By Street",
      enum: CRMSViews.All_ByStreetName,
      component: require("./by-street/_index").default,
    },
    {
      path: "by-suburb",
      name: "By Suburb",
      enum: CRMSViews.All_BySuburb,
      component: require("./by-suburb/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      enum: CRMSViews.All_ByStatus,
      component: require("./by-status/_index").default,
    },
    {
      path: "by-org-structure",
      name: "By Org Structure",
      enum: CRMSViews.All_ByOrgStructure,
      component: require("./by-org-structure/_index").default,
    },
    {
      path: "by-recorded-by",
      name: "By Recorded By",
      enum: CRMSViews.All_ByRecordedBy,
      component: require("./by-recorded-by/_index").default,
    },
    {
      path: "my-coordinator-person",
      name: "My Coordinator Person",
      enum: CRMSViews.All_MyCoordinatorEvents,
      component: require("./my-coordinator-person/_index").default,
    },
    {
      path: "by-asset",
      name: "By Asset",
      enum: CRMSViews.All_ByAsset,
      component: require("./by-asset/_index").default,
    },
    {
      path: "hard-rubbish",
      name: "Hard Rubbish",
      enum: CRMSViews.All_HardRubbish,
      component: require("./hard-rubbish/_index").default,
    },
    {
      path: "after-hours-service",
      name: "After Hours Service",
      enum: CRMSViews.All_AfterHoursService,
      component: require("./after-hours-service/_index").default,
    },
    {
      path: "vip",
      name: "VIP",
      enum: CRMSViews.All_VIP,
      component: require("./vip/_index").default,
    },
    {
      path: "quality-check",
      name: "Quality Check",
      enum: CRMSViews.All_QualityCheck,
      component: require("./quality-check/_index").default,
    },
    {
      path: "incidents",
      name: "Incidents",
      enum: CRMSViews.All_Incident,
      component: require("./incidents/_index").default,
    },
    {
      path: "safety",
      name: "Safety",
      enum: CRMSViews.All_Safety,
      component: require("./safety/_index").default,
    },
    {
      path: "risk",
      name: "Risk",
      enum: CRMSViews.All_Risk,
      component: require("./risk/_index").default,
    },
    {
      path: "unread-mail",
      name: "Unread Mail",
      enum: CRMSViews.UnreadMailMessagingAllEvents,
      component: require("./unread-mail/_index").default,
    },
  ],
};
