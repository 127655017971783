import { NSWPointSuggestion } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/validation-adress-search/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const searchAddressValidationConfig = {
  typeSpeed: 300,
  minCharacters: 4,
};

const nameOf = nameOfFactory<NSWPointSuggestion>();

export const colAddressValidationSearch: IColumnFields[] = [
  {
    field: nameOf("address"),
    title: "Address",
    width: 1000,
  },
];
