export const mockImportSchemeAccountsResult = [
  {
    ImportId: 1,
    AccountNumber: "32050.000100",
    ImportedAssessmentNumber: "3054054100",
    AssessmentId: "11495",
    AssessmentNumber: "3054054100",
    PropertyAddress: "71 Bold Hill Rd, Pakenham",
    LegalDescription: "",
    Description: "",
    Comment: "",
    EstimatedShareOfCosts: 23118.48,
    ActualShareOfCosts: 23118.48,
    Coefficient: "",
    Size: 34,
    NoOfUnits: 0,
    Errors: "",
  },
];
