import { useCslStore } from "@app/products/csl/[id]/store";
import { convertCSLFormToRegisterRequest } from "@app/products/csl/[id]/util";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { CSLFormElement } from "./components/form-element/_index";

export const CSLForm = observer(() => {
  const isNew = useIsNew();
  const { csl, createCSL, updateCSL } = useCslStore();
  const handleSubmit = async (data: any) => {
    const requestBody = convertCSLFormToRegisterRequest(data);
    if (isNew) {
      createCSL(requestBody);
    } else {
      updateCSL(requestBody);
    }
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={csl}
        render={(formRenderProps: FormRenderProps) => (
          <CSLFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
