import { mockSingleBuilding } from "@app/products/building/[id]/mock";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";
import { cloneDeep } from "lodash";

export const getAssessmentDetailsTabById = async (
  assessmentId: number
): Promise<any | undefined> => {
  try {
    const response = await CoreAPIService.getClient().get<any>(
      `/api/property/internal/assessment/${assessmentId}/details`
    );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const getBuildingById = async (id: string) => {
  const response = cloneDeep(mockSingleBuilding);
  await sleep(1000);
  if (!response) {
    return undefined;
  }
  return response;
};
