import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colCandidatesList: IColumnFields[] = [
  {
    field: "ID",
    title: "Notice Name",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "FullNames",
    title: "Full Names",

    width: 350,
  },
  {
    field: "Address",
    title: "Address",
  },
  {
    field: "Assessments",
    title: "Assessments",
  },
  {
    field: "BalanceOutstanding",
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },

  {
    field: "BalanceDue",
    title: "Balance Due",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "2017/2018",
    title: "2017/2018",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "2016/2017",
    title: "2016/2017",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "LastPaymentDate",
    title: "Last Payment Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "LastPaymentAmount",
    title: "Last Payment Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "LastReminderNoticeRun",
    title: "Last Reminder Notice Run",

    width: 350,
  },
  {
    field: "LastReminderIssueDate",
    title: "Last Reminder Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "NumberOfAssessments",
    title: "Number of Assessments",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "NumberOfDebtRecoveries",
    title: "Number of Debt Recoveries",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "DebtRecoveryExemption",
    title: "Debt Recovery Exemption",
  },
  {
    field: "DirectDebitAuthority",
    title: "Direct Debit Authority",
  },
  {
    field: "DebtRecovery",
    title: "Debt Recovery",
  },
  {
    field: "RebateEntitlement",
    title: "Rebate Entitlement",
  },
  {
    field: "Arrangement",
    title: "Arrangement",
  },
  {
    field: "RatepayerInANoticeGroup",
    title: "Ratepayer in A Notice Group",
  },
  {
    field: "OverdueDate",
    title: "Overdue Date",
    format: DATE_FORMAT.DATE,
  },
];

export const colCandidatesAssessment: IColumnFields[] = [
  {
    field: "Include",
    title: "Include",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
    style: formatStyleProminent,
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "BalanceOutstanding",
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "BalanceDue",
    title: "Balance Due",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "2017/2018",
    title: "2017/2018",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "2016/2017",
    title: "2016/2017",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "LastPaymentDate",
    title: "Last Payment Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "LastPaymentAmount",
    title: "Last Payment Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "RebateEntitlements",
    title: "Rebate Entitlements",
  },
  {
    field: "LastReminderNoticeRun",
    title: "Last Reminder Notice Run",
  },
  {
    field: "LastReminderIssueDate",
    title: "Last Reminder Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "Id",
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colCandidatesBalancesByRating: IColumnFields[] = [
  {
    field: "RatingPeriod",
    title: "Rating Period",
  },
  {
    field: "DueDate",
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },

  {
    field: "Balance",
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "InterestBalance",
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Total",
    title: "Total",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
