export enum BillingGroupData {
  Item1 = "Monthly Billing",
  Item2 = "Standard Periodic Billing",
}
export enum SearchType {
  AssessmentNumber = "Assessment Number",
  AssessmentId = "Assessment Id",
}
export enum SearchField {
  AssessmentNumber = "_searchByAssessmentNumber",
  AssessmentId = "_searchByAssessmentId",
}
export const textFieldMapping: {
  [key: string]: string;
} = {
  [SearchType.AssessmentNumber]: SearchField.AssessmentNumber,
  [SearchType.AssessmentId]: SearchField.AssessmentId,
};

export enum MeterStatus {
  NotInService = "Not in service",
}
