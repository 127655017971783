import {
  IMeterSearch,
  IMeterSearchBy,
} from "@components/cc-search-meter/model";

export const MeterSearchBy: IMeterSearchBy[] = [
  {
    Key: "MeterId",
    Value: "Meter Id",
  },
  {
    Key: "MeterNumber",
    Value: "Meter Number",
  },
];

export const meterSearchMinCharacter: {
  [key in keyof IMeterSearch]: number;
} = {
  MeterId: 1,
  MeterNumber: 4,
};
