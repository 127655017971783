import { CSLSystemAdminConditionsFormData } from "@app/products/csl/[id]/model";
import { useIsNew } from "@common/hooks/useIsNew";
import { Toast } from "@components/toast";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import ExistManageCSLSystemAdminConditions from "./components/forms/existed/_index";
import { NewManageCSLSystemAdminConditions } from "./components/forms/new/_index";
const ManageCSLSystemAdminConditions = observer(() => {
  const isNew = useIsNew();
  const history = useHistory<any>();

  const [isLoading, setIsLoading] = useState(false);

  const [cslSystemAdminConditionsInfo] = useState<
    CSLSystemAdminConditionsFormData | undefined
  >(undefined);

  useEffectOnce(() => {
    const { state } = history.location;
    if (state?.status === "success") {
      Toast.displayToast({
        content: "🚀 Save CSL System Admin Conditions successfully !",
        type: "success",
      });
      history.replace(window.location.pathname); // remove history states
    }
  });

  if (isNew) {
    return <NewManageCSLSystemAdminConditions setIsLoading={setIsLoading} />;
  }
  return (
    <ExistManageCSLSystemAdminConditions
      setIsLoading={setIsLoading}
      isLoading={isLoading}
      cslSystemAdminConditionsInfo={cslSystemAdminConditionsInfo}
    />
  );
});
export default ManageCSLSystemAdminConditions;
