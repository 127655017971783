import { PPRApplicationTabDetails } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getPSARDetailsTabById = async (
  id: number
): Promise<APIResponse<PPRApplicationTabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<PPRApplicationTabDetails>(
      `/api/tp/ppr/internal/application/${id}/tabdetails/?isPSAR=true`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
