import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { loadViewConfiguresColumns } from "@app/products/property/api";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { DEFERRED_DUTY_NOTICE_RUNS_ROUTE } from "@app/products/property/deferred-duty/notice-runs/[id]/constant";
import { PROPERTY_DEFERRED_DUTY_NOTICE_RUNS__LIST_VIEW_URL } from "@app/products/property/deferred-duty/notice-runs/constant";
import { VO_DeferredDuty_NoticeRuns } from "@app/products/property/deferred-duty/notice-runs/model";
import { deferredDutyRoute } from "@app/products/property/deferred-duty/route";
import { getBookmarkListViewDisplayName } from "@app/products/property/deferred-duty/util";
import { ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { APIResponseError } from "@common/apis/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { DeferredDutyNoticeRunDetailTab } from "./[id]/components/reference-sidebar/details/_index";
import { NewDeferredDutyNoticeRunButton } from "./components/buttons/new-deferred-duty-notice-run/_index";
import { colListNoticeRuns } from "./config";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
} from "./util";

const nameOf = nameOfFactory<VO_DeferredDuty_NoticeRuns>();

export default () => {
  const [colsConfigured, setColsConfigured] =
    useState<IColumnFields[]>(colListNoticeRuns);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.OData_DeferredDuty_NoticeRuns,
      colListNoticeRuns
    );
    if (Array.isArray(response)) {
      setColsConfigured(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };
  useEffectOnce(() => {
    loadViewConfig();
  });

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={deferredDutyRoute.path} />,
    ],
    centerComponents: [
      <NewDeferredDutyNoticeRunButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Deferred_Duty}
        recordType={RECORDTYPE.CommunityProperty_Notice_Run_Assessment}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Notice_Run_Assessment}
        keyField="NoticeRunId"
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={DEFERRED_DUTY_NOTICE_RUNS_ROUTE}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        productType={PRODUCT_TYPE.CommunityProperty}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <DeferredDutyNoticeRunDetailTab /> },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Notice_Run_Assessment}
          />
        ),
      },
    ],
  });

  if (isLoading) {
    return <Loading isLoading isFullScreen />;
  }
  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );
  }

  return (
    <CCProductListView
      columnFields={colsConfigured}
      primaryField={nameOf("DDS_Notice_Run_Id")}
      state={{ sort: [{ field: nameOf("DDS_Notice_Run_Id"), dir: "desc" }] }}
      dataUrl={PROPERTY_DEFERRED_DUTY_NOTICE_RUNS__LIST_VIEW_URL}
    />
  );
};
