import { EPPRProductType } from "@app/core/view-on-map/components/buttons/view-vic-plan/config";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface ViewVICPlanButtonProps {
  pprType: EPPRProductType;
}

export const ViewVICPlanButton = observer(
  ({ pprType }: ViewVICPlanButtonProps) => {
    const { settings } = useCommonCoreStore();
    const { ppr } = usePPRStore();
    const { pprEnquiry } = usePPREnquiryStore();
    const { ppr: pprPSAR } = usePSARStore();
    const vicPlanUrl: string =
      settings[ECorporateSettingsField.CorporateSettings_VicPlanURL]?.Value ??
      "";
    const locality_PFI = useMemo(() => {
      switch (pprType) {
        case EPPRProductType.PPR:
          return ppr?.SiteAddress.PropertyAssessment?.Locality_PFI;
        case EPPRProductType.Enquiries:
          return pprEnquiry?.Address.PropertyAssessment?.Locality_PFI;
        case EPPRProductType.PSAR:
          return pprPSAR?.SiteAddress.PropertyAssessment?.Locality_PFI;
        default:
          return "";
      }
    }, [ppr, pprEnquiry, pprPSAR, pprType]);
    return (
      <CCNavButton
        title="View VICPlan"
        onClick={() => {
          window.open(`${vicPlanUrl}${locality_PFI ?? ""}`, "_blank");
        }}
      />
    );
  }
);
