import { useJournalSessionDetailsStore } from "@app/products/property/journals/session-list/[id]/components/reference-sidebar/detail/store";
import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const JournalSectionDetailTab = observer(() => {
  const {
    isLoading,
    journalSessionDetails,
    resetStore,
    loadJournalSessionDetails,
    responseLoadError,
  } = useJournalSessionDetailsStore();
  const { lastSelectedRow } = useCCProductListViewStore();

  const params: { id: string } = useParams();
  const journalId: any = params.id || lastSelectedRow?.Session_Number;

  useEffectOnce(() => {
    return () => resetStore();
  });

  useEffect(() => {
    loadJournalSessionDetails(journalId);
  }, [journalId, loadJournalSessionDetails]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => loadJournalSessionDetails(journalId)}
      />
    );

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Session number:"}
              value={journalSessionDetails?.SessionNumber + ""}
              primary
            />
          }
        >
          <>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Name:"}
                value={journalSessionDetails?.SessionName}
              />
              <ProductReferenceRow
                title={"Created on:"}
                value={formatDisplayValue(
                  journalSessionDetails?.CreatedOn,
                  DATETIME_FORMAT.DATETIME
                )}
              />
              <ProductReferenceRow
                title={"Created by:"}
                value={journalSessionDetails?.CreatedBy_Name}
              />
              <ProductReferenceRow
                title={"Status:"}
                value={journalSessionDetails?.SessionStatus}
              />
              <ProductReferenceRow
                title={"Journal included:"}
                value={journalSessionDetails?.JournalsIncluded}
              />
              <ProductReferenceRow
                title={"Journal count:"}
                value={formatDisplayValue(
                  journalSessionDetails?.JournalCount,
                  NUMBER_FORMAT.NUMBER1
                )}
              />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
