import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { GET_PRODUCTS } from "@common/configs/setting";
import { globalStoreInstance } from "@common/stores/global/store";
import { ProductListingDTO } from "@common/stores/products/model";

export const getActiveProducts = async (): Promise<
  APIResponse<ProductListingDTO[]>
> => {
  try {
    return await CoreAPIService.getClient().get<ProductListingDTO[]>(
      `${globalStoreInstance.allSetting.baseAPIUrl}${GET_PRODUCTS}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
