import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const recycleBinRoute: ICCRoute = {
  path: "recycle-bin",
  name: "Recycle Bin",
  enum: HealthManagerMenu.RecycleBin,
  children: [
    {
      path: "premises",
      name: "Premises",
      enum: HealthManagerMenu.RecycleBinPremises,
      component: require("./premises/_index").default,
    },
    {
      path: "premises-types",
      name: "Premises Types",
      enum: HealthManagerMenu.RecycleBinPremisesTypes,
      component: require("./premises-types/_index").default,
    },
    {
      path: "fees",
      name: "Fees",
      enum: HealthManagerMenu.RecycleBinFees,
      component: require("./fees/_index").default,
    },
    {
      path: "inspections",
      name: "Inspections",
      enum: HealthManagerMenu.RecycleBinInspections,
      component: require("./inspections/_index").default,
    },
    {
      path: "samples",
      name: "Samples",
      enum: HealthManagerMenu.RecycleBinSamples,
      component: require("./samples/_index").default,
    },
  ],
};
