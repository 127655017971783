import { DOCUMENT_TEMPLATE_ROUTE } from "@app/products/property/system-admin/document-template/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewDocumentButton = observer(() => {
  const history = useHistory();

  return (
    <>
      <CCNavButton
        title="New"
        onClick={() => history.push(`${DOCUMENT_TEMPLATE_ROUTE}/new`)}
      />
    </>
  );
});
