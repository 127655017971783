import {
  CURRENCY_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleProminent } from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { VO_Charge_Run_Rebate_LevySummary } from "@app/products/property/charge-and-notice-runs/charge-runs/components/detail/components/rebate-summary-detail/model";

const nameOf = nameOfFactory<VO_Charge_Run_Rebate_LevySummary>();
export const colRebateSummaryDetail: IColumnFields[] = [
  {
    field: nameOf("LevyName"),
    title: "Levy",
  },
  {
    field: nameOf("LC_Rate"),
    title: "Rate",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Levy_Amount"),
    title: "Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_Charge_Run_Rebate_LevySummary[]
    ) => totalColumnField(nameOf("Levy_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("No_of_Levies"),
    title: "Number of Levies",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Quantity"),
    title: "Quantity",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Valuation"),
    title: "Valuation",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_Charge_Run_Rebate_LevySummary[]
    ) => totalColumnField(nameOf("Valuation"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Base_Amount"),
    title: "Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_Charge_Run_Rebate_LevySummary[]
    ) => totalColumnField(nameOf("Base_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("RateByValue_Amount"),
    title: "Rate by Valuation Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_Charge_Run_Rebate_LevySummary[]
    ) => totalColumnField(nameOf("RateByValue_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Increments_to_Minimum"),
    title: "Increments to Minimum",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_Charge_Run_Rebate_LevySummary[]
    ) => totalColumnField(nameOf("Increments_to_Minimum"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("No_of_Increments_to_Minimum"),
    title: "Number of Increments to Minimum",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Gross_Levy_Amount"),
    title: "Gross Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_Charge_Run_Rebate_LevySummary[]
    ) => totalColumnField(nameOf("Gross_Levy_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("RebatesForType"),
    title: "Rebates for Type",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_Charge_Run_Rebate_LevySummary[]
    ) => totalColumnField(nameOf("RebatesForType"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Nett_Levy_Amount"),
    title: "Nett Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_Charge_Run_Rebate_LevySummary[]
    ) => totalColumnField(nameOf("Nett_Levy_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Attributable_Value"),
    title: "Attributable Value",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_Charge_Run_Rebate_LevySummary[]
    ) => totalColumnField(nameOf("Attributable_Value"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Postponed_Amount"),
    title: "Postponed Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_Charge_Run_Rebate_LevySummary[]
    ) => totalColumnField(nameOf("Postponed_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("No_of_Postponed"),
    title: "Number of Postponed Amount",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
