import { isSuccessIdentityPacket } from "@common/apis/util";
import { postShareReport } from "@common/pages/report/integrated-reports/component/buttons/share-report/api";
import {
  IntegratedReportSecurity,
  IReportInfo,
} from "@common/pages/report/integrated-reports/component/dialogs/share-report/model";
import { ShareReportDialog } from "@common/pages/report/integrated-reports/component/dialogs/share-report/_index";
import { IntegratedReport } from "@common/pages/report/integrated-reports/custom-reports/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useState } from "react";

interface ShareReportIconProps {
  disabled?: boolean;
}
export const ShareReportIcon = observer(
  ({ disabled }: ShareReportIconProps) => {
    const { isEmptyGridSelectedRow, gridSelectedRows, gridSelectedIds } =
      useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reportInfoFromGrid, setReportInfoFromGrid] = useState<IReportInfo[]>(
      []
    );
    const handleOnClick = () => {
      setIsOpenDialog(true);
      const data: IReportInfo[] = gridSelectedRows.map(
        (row: IntegratedReport) => {
          return {
            ReportName: row?.ReportName,
            Owner: row?.CreatedBy,
            LastModifiedBy: row?.ModifiedBy,
            LastModifiedDate: row?.ModifiedDate,
            Category: row?.Category,
          };
        }
      );
      setReportInfoFromGrid(data);
    };
    const handleSubmitShare = async (data: any) => {
      const payload: IntegratedReportSecurity = {};
      if (
        data.values?.ReportViewers &&
        data.values?.ReportViewers?.length > 0
      ) {
        payload["Readers"] = data.values?.ReportViewers;
      }
      if (
        data.values?.ReportWriters &&
        data.values?.ReportWriters?.length > 0
      ) {
        payload["Writers"] = data.values?.ReportWriters;
      }
      if (gridSelectedRows.length === 1) {
        payload["IntegratedReport_ID"] = gridSelectedRows[0].ID;
        payload["ReportName"] = gridSelectedRows?.[0]?.ReportName;
        payload["ReportOwner"] = gridSelectedRows?.[0]?.CreatedBy;
        payload["Owner_ID"] = gridSelectedRows?.[0]?.Owner_ID;
      }
      if (gridSelectedRows.length > 1) {
        payload["IntegratedReports"] = gridSelectedIds;
        payload["ReplaceExistingRights"] =
          data.values?.ReplaceExisting ?? false;
      }
      setIsLoading(true);
      const response = await postShareReport(payload);
      setIsOpenDialog(false);
      if (isSuccessIdentityPacket(response)) {
        setIsLoading(false);
        pushNotification({
          title: ` ${pluralize(
            "Report",
            gridSelectedRows.length
          )} shared successfully`,
          type: "success",
        });
      } else {
        setIsLoading(false);
        pushNotification({
          autoClose: false,
          title:
            response?.error ??
            `Share ${pluralize("report", gridSelectedRows.length)} failed`,
          type: "error",
        });
      }
    };
    return (
      <>
        <CCNavButton
          iconClass="fal fa-share-alt"
          title="Share Report"
          disabled={isEmptyGridSelectedRow || disabled}
          onClick={handleOnClick}
        />
        {isOpenDialog && (
          <ShareReportDialog
            isLoading={isLoading}
            onClose={() => setIsOpenDialog(false)}
            onSubmit={handleSubmitShare}
            reportInfoFromGrid={reportInfoFromGrid}
          />
        )}
      </>
    );
  }
);
