import { nameOfHMPremisesView } from "@app/products/hm/premises/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IGenerateColFieldBySetting } from "@common/models/settings";
import { getStringValueSetting } from "@common/stores/products/util";
import { convertToTitleCase } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

export const generateHMPremisesPrescribedAccomColumnsBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateColFieldBySetting) => {
  if (!settings) return defaultConfig;

  const getModifiedColumnTitle = (
    settingKey: ECorporateSettingsField,
    defaultTitle: string
  ) => {
    const label = getStringValueSetting(settings[settingKey]);
    return label ? convertToTitleCase(label) : defaultTitle;
  };

  const columnMapper = (column: IColumnFields) => {
    switch (column.field) {
      case nameOfHMPremisesView("RegNo"):
        return {
          ...column,
          title: getModifiedColumnTitle(
            ECorporateSettingsField.HealthManager_RegistrationNoLabel,
            column.title
          ),
        };
      case nameOfHMPremisesView("DebtorNo"):
        return {
          ...column,
          title: getModifiedColumnTitle(
            ECorporateSettingsField.CorporateSettings_DebtorNumberLabel,
            column.title
          ),
        };
      default:
        return column;
    }
  };

  return cloneDeep(defaultConfig).map(columnMapper);
};
