import { useStores } from "@app/products/ams/api/helpers/use-stores";
import { BoxCanvas } from "@app/products/ams/components/BoxCanvas";
import {
  GroupPanelWrapper,
  ItemTracker,
} from "@app/products/ams/components/GroupPanelWrapper";
import { PageTitleBackNav } from "@app/products/ams/components/page/PageTitleBackNav";
import { PageTitleHeader } from "@app/products/ams/components/page/PageTitleHeader";
import { PageTitleMenuButton } from "@app/products/ams/components/page/PageTitleMenuButton";
import { PageTitleMenuRow } from "@app/products/ams/components/page/PageTitleMenuRow";
import PanelBarTitle from "@app/products/ams/components/PanelBarTitle";
import {
  IAssessmentGroup,
  IAssessmentModel,
} from "@app/products/ams/model/store";
import { ItemTypes } from "@app/products/ams/util/itemTypes";
import Loading from "@components/loading/Loading";
import { PanelBar } from "@progress/kendo-react-layout";
import { useObserver } from "mobx-react-lite";
import React, { Fragment, useEffect } from "react";
import { IGroupDragItem } from "./GroupLayoutElement";
import InspectionAssessmentRow from "./InspectionAssessmentRow";
import InspectionPanelElements from "./InspectionPanelElements";
import { IQuestionDragItem } from "./QuestionLayoutElement";

function Questions(mdl: IAssessmentModel, grp: IAssessmentGroup) {
  return (
    <Fragment>
      {grp.sortedQuestions.map((q) => {
        return (
          <InspectionAssessmentRow
            questionCfg={q}
            parentGroup={grp}
            parentModel={mdl}
          />
        );
      })}
    </Fragment>
  );
}

function Groups(
  mdl: IAssessmentModel,
  handleDrop: (item: any, groupTarget: ItemTracker) => void
) {
  return (
    <Fragment>
      {mdl.sortedGroups.map((grp: IAssessmentGroup) => {
        return (
          <GroupPanelWrapper
            //if i add a key it will hose the dnd when you drag n drop the groups, yet on the questions its ok. not sure why?
            key={grp.groupHeading}
            currentItem={{
              name: grp.groupHeading,
              ordinalPosition: grp.ordinalPosition,
            }}
            expanded={true}
            title={<PanelBarTitle title={grp.groupHeading} />}
            moveItem={mdl.moveGroup}
            acceptItems={[
              {
                itemType: ItemTypes.EntityFieldLayoutElement,
                handleDrop: handleDrop,
              },
            ]}
          >
            {Questions(mdl, grp)}
          </GroupPanelWrapper>
        );
      })}
    </Fragment>
  );
}

const InspectionAssessment = () => {
  const {
    dataStores: { inspectionAssessmentStore },
    uiStores: { inspectionAssessmentUi },
  } = useStores();
  const inspCfg = inspectionAssessmentUi.editingInspectionTemplate;

  //shift this to the ui store
  const handleDropQuestion = (item: any, groupTarget: ItemTracker) => {
    const qdi = item as IQuestionDragItem;
    if (qdi) {
      const sourceModel = inspectionAssessmentStore.assessmentTemplates.find(
        (mdl) => mdl.assessmentHeading === qdi.parentModelName
      );
      const sourceGroup = sourceModel?.assessmentGroups.find(
        (grp) => grp.groupHeading === qdi.parentGroupName
      );
      const sourceQuest = sourceGroup?.questions.find(
        (q) => q.questionNo === qdi.questionNo
      );

      const targetGroup =
        inspectionAssessmentUi.editingInspectionTemplate?.assessmentGroups.find(
          (grp) => grp.groupHeading === groupTarget.name
        );
      if (
        targetGroup &&
        sourceQuest &&
        inspectionAssessmentUi.editingInspectionTemplate
      ) {
        inspectionAssessmentUi.editingInspectionTemplate.addQuestion(
          targetGroup.groupHeading,
          sourceQuest
        );
      }
    }
  };

  const handleDropGroup = (item: any) => {
    const gdi = item as IGroupDragItem;
    if (gdi) {
      const sourceModel = inspectionAssessmentStore.assessmentTemplates.find(
        (m) => m.assessmentHeading === gdi.parentModelName
      );
      const sourceGroup = sourceModel?.assessmentGroups.find(
        (g) => g.groupHeading === gdi.parentGroupName
      );

      if (sourceGroup && inspectionAssessmentUi.editingInspectionTemplate) {
        inspectionAssessmentUi.editingInspectionTemplate.addGroup(sourceGroup);
      }
    }
  };

  useEffect(() => {
    if (inspectionAssessmentStore.initialising) {
      inspectionAssessmentStore.loadTemplates();
      inspectionAssessmentUi.setEditingTemplate(
        inspectionAssessmentStore.assessmentTemplates[0]
      );
    }
  }, [
    inspectionAssessmentStore.initialising,
    inspectionAssessmentStore.assessmentTemplates,
    inspectionAssessmentStore,
    inspectionAssessmentUi,
  ]);

  return useObserver(() => {
    if (
      inspectionAssessmentStore.initialising ||
      inspectionAssessmentUi.editingInspectionTemplate === null
    ) {
      return <Loading isLoading />;
    }

    return (
      <Fragment>
        <PageTitleBackNav title="Inspection" />
        <div className="container-fluid" style={{ height: "85vh" }}>
          <PageTitleHeader descriptiveHeading={inspCfg!.assessmentHeading} />
          <PageTitleMenuRow>
            <PageTitleMenuButton buttonText="Save Template" />
            <PageTitleMenuButton buttonText="More Options" />
          </PageTitleMenuRow>
          <br />

          <div className="row">
            <div className="col-md-3 border-right pt-1">
              <InspectionPanelElements />
            </div>

            <div className="col-md-9">
              <BoxCanvas
                accept={ItemTypes.PanelGroupLayoutElement}
                handleDrop={handleDropGroup}
              >
                <PanelBar expandMode={"multiple"}>
                  {Groups(inspCfg!, handleDropQuestion)}
                </PanelBar>
              </BoxCanvas>
            </div>
          </div>
        </div>
      </Fragment>
    );
  });
};

export default InspectionAssessment;
