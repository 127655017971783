import { eventEmitter } from "@/App";
import { saveMailMergeDocument } from "@app/core/new-mail-merge/buttons/components/buttons/api";
import { MailMergeSelectDocument } from "@app/core/new-mail-merge/buttons/components/buttons/model";

import { MailMergeDialog } from "@app/core/new-mail-merge/dialogs/_index";
import {
  MAILMERGEDATASET,
  MAILMERGESAVEFORMAT,
  MailMerge_SelectMergeDocument,
  MailMerge_SelectMergeDocument_Ext,
} from "@app/core/new-mail-merge/dialogs/model";
import { setUpMailMergeFromView } from "@app/core/new-mail-merge/dialogs/util";
import { postUpdateRenewalNoticeSent } from "@app/products/animals/kennels-renewals/requires-renewal-notice/components/api";
import { FormAction } from "@app/products/crms/model";
import { isSuccessResponse } from "@common/apis/util";
import { DownloadFile } from "@common/constants/downloadfile";

import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { compact, isNil, map } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface IIssueRenewalNoticeButtonProps {
  count?: number;
  councilId?: number;
  isMultiple?: boolean;
  recordType: RECORDTYPE;
  productType: PRODUCT_TYPE_NUMBER;
  dataSetFilter: MAILMERGEDATASET;
  ancestorId?: number;
  ancestorType?: RECORDTYPE;
  titleButton?: string;
  isVisible?: boolean;
  isDisabled?: boolean;
  initMailMergeData?: Partial<MailMerge_SelectMergeDocument>;
}

export const IssueRenewalNoticeButton = observer(
  ({
    productType,
    recordType,
    dataSetFilter,
    isMultiple = true,
    ancestorId,
    ancestorType,
    titleButton = "Issue Renewal Notice",
    isVisible = true,
    isDisabled = false,
    initMailMergeData,
  }: IIssueRenewalNoticeButtonProps) => {
    const {
      isEmptyGridSelectedRow,
      gridSelectedRows,
      gridSelectedIds,
      setGridSelectedRows,
    } = useCCProductListViewStore();
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const [showMailMergeDialog, setShowMergeDialog] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [initialMailMerge, setInitialMailMerge] =
      useState<MailMerge_SelectMergeDocument_Ext>();

    const handleOnClick = () => {
      const mailMerge = setUpMailMergeFromView();
      mailMerge.Dataset_Filters.push(dataSetFilter);
      setInitialMailMerge({
        ...mailMerge,
        ...initMailMergeData,
        _options: {
          SaveAsPDF: mailMerge.SaveFormat === MAILMERGESAVEFORMAT.Pdf,
        },
      });
      setShowMergeDialog(true);
    };

    const handleOnSubmit = async (
      mailMerge: MailMerge_SelectMergeDocument_Ext
    ) => {
      const requestBody: MailMergeSelectDocument = {
        SelectedRecordIDs: compact(map(gridSelectedRows, "ID")),
        SelectedMailMergeDocumentIDs: mailMerge.SelectedDocumentIDs,
        RecordType: recordType,
        AppendToRecordSource: mailMerge.AppendToRecordSource,
        BatchPrint: mailMerge.BatchPrint,
        Collate: mailMerge.Collate,
        SaveAsPdf: mailMerge?._options?.SaveAsPDF ?? false,
        Category: mailMerge.Category_KWD,
        AncestorType: ancestorId ?? null,
        Ancestor_ID: ancestorType ?? null,
      };
      setIsSubmitting(true);
      const response = await saveMailMergeDocument(
        productType,
        requestBody,
        FormAction.CORE_ALLOW_MAILMERGE
      );

      if (isSuccessResponse(response)) {
        clearErrorNotification();

        const responseUpdateRenewal = await postUpdateRenewalNoticeSent({
          kennelsIds: gridSelectedIds,
          isNoticeSent: true,
        });

        if (isSuccessResponse(responseUpdateRenewal)) {
          pushNotification({
            type: "success",
            description: responseUpdateRenewal.data?.Notifications,
          });
          // refresh list view
          eventEmitter.emit(CCGridEventType.RefreshOData);
          setGridSelectedRows([]);
        } else {
          pushNotification({
            autoClose: false,
            type: "error",
            description:
              responseUpdateRenewal.data?.Errors ??
              responseUpdateRenewal.error ??
              "The renewal notice update failed",
          });
        }

        if (
          requestBody?.Collate === true &&
          !isNil(response.data?.Data) &&
          response.data?.Data.length > 0
        ) {
          DownloadFile(
            response.data.Data,
            response.data.ContentType,
            response.data.FileName
          );
        }
      } else {
        pushNotification({
          autoClose: false,
          title: response.statusText ?? "Mail merge process failed",
          type: "error",
        });
      }
      setIsSubmitting(false);
      setShowMergeDialog(false);
    };

    // set button is invisible
    if (!isVisible) return null;

    return (
      <>
        <CCNavButton
          title={titleButton}
          disabled={isEmptyGridSelectedRow || isDisabled}
          onClick={handleOnClick}
        />
        {showMailMergeDialog && (
          <MailMergeDialog
            isSubmitting={isSubmitting}
            initialMailMerge={initialMailMerge}
            onClose={() => {
              setShowMergeDialog(false);
            }}
            onSubmit={(mailMerge: MailMerge_SelectMergeDocument_Ext) =>
              handleOnSubmit(mailMerge)
            }
            productType={productType}
            isMultiple={isMultiple}
          />
        )}
      </>
    );
  }
);
