import { useNoticeRunsStore } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/store";
import { OutputToFileDialog } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/notice-run-output/_index";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { usePageType } from "@common/hooks/usePageType";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const OutputToFileButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const { gridSelectedRows } = useCCProductListViewStore();
  const { isProductListView } = usePageType();
  const { pushNotification } = useCCAppNotificationStore();
  const { loadNoticeRuns, noticeRuns } = useNoticeRunsStore();
  const { id } = useParams<{ id: string }>();

  const isDisabled = useMemo(() => {
    return isProductListView
      ? gridSelectedRows?.length !== 1 ||
          isNil(gridSelectedRows[0]?.NR_No_of_Notices) ||
          gridSelectedRows[0]?.NR_No_of_Notices === 0
      : isNil(noticeRuns?.NumberOfNotices) || noticeRuns?.NumberOfNotices === 0;
  }, [gridSelectedRows, isProductListView, noticeRuns?.NumberOfNotices]);

  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
        privilegesEnum={EPrivilegesWorkflow.Export_Notice_Output_Run}
      >
        <CCNavButton
          title="Output to file"
          onClick={() => {
            setIsShowDialog(true);
          }}
          disabled={isDisabled}
        />
      </PrivilegeWrapper>
      {isShowDialog && (
        <OutputToFileDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          actionAfterSuccessFinish={(event) => {
            const notificationContent: IAppNotificationItemAddProps = {
              title:
                event?.SuccessMessage ??
                event?.Notification ??
                "New notice run was created successfully.",
              type: "success",
            };
            isProductListView
              ? pushNotification(notificationContent)
              : loadNoticeRuns(+id, notificationContent);
          }}
        />
      )}
    </>
  );
});
