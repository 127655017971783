import {
  getOdataHearingCalendarByOfficerId,
  getPickSiteUserList,
} from "@app/products/town-planning/ppr/psa-referrals/hearing-calendar/api";
import { defaultCalendarDropDownData } from "@app/products/town-planning/ppr/psa-referrals/hearing-calendar/config";
import { IPickSiteUser } from "@app/products/town-planning/ppr/psa-referrals/hearing-calendar/model";
import { processHearingCalendarData } from "@app/products/town-planning/ppr/psa-referrals/hearing-calendar/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { ICalendarData } from "@components/cc-calendar/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class HearingCalendarStore {
  private _hearingCalendar?: ICalendarData[] = undefined;
  private _siteUser?: IPickSiteUser[] = undefined;
  private _selectedSiteUser?: IPickSiteUser = defaultCalendarDropDownData;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get hearingCalendar() {
    return toJS(this._hearingCalendar);
  }
  setHearingCalendar = (hearingCalendar?: ICalendarData[]) => {
    runInAction(() => {
      this._hearingCalendar = hearingCalendar;
    });
  };

  get siteUser() {
    return toJS(this._siteUser);
  }
  setSiteUser = (siteUser?: IPickSiteUser[]) => {
    runInAction(() => {
      this._siteUser = siteUser;
    });
  };

  get selectedSiteUser() {
    return toJS(this._selectedSiteUser);
  }
  setSelectedSiteUser = (selectedSiteUser?: IPickSiteUser) => {
    runInAction(() => {
      this._selectedSiteUser = selectedSiteUser;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._hearingCalendar = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._siteUser = undefined;
    });
  };

  loadHearingCalendar = async () => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    const [hearingCalendarRes, pickSiteUserListRes] = await Promise.all([
      getOdataHearingCalendarByOfficerId(),
      getPickSiteUserList(),
    ]);

    let newHearingCalendar = undefined;
    let newPickSiteUserList = undefined;
    if (
      isSuccessResponse(hearingCalendarRes) &&
      isSuccessResponse(pickSiteUserListRes) &&
      hearingCalendarRes.data?.value &&
      pickSiteUserListRes.data?.value
    ) {
      newHearingCalendar = processHearingCalendarData(
        hearingCalendarRes.data.value
      );
      newPickSiteUserList = pickSiteUserListRes.data.value;
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }

    this.setHearingCalendar(newHearingCalendar);
    this.setSiteUser(newPickSiteUserList);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  loadHearingCalendarById = async (officerId: number) => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    const hearingCalendarRes = await getOdataHearingCalendarByOfficerId(
      officerId
    );
    let newHearingCalendar = undefined;
    if (
      isSuccessResponse(hearingCalendarRes) &&
      hearingCalendarRes.data?.value
    ) {
      newHearingCalendar = processHearingCalendarData(
        hearingCalendarRes.data?.value
      );
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }

    this.setHearingCalendar(newHearingCalendar);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };
}

export const hearingCalendarStoreInstance = new HearingCalendarStore();
const hearingCalendarStoreContext = createContext(hearingCalendarStoreInstance);
export const useHearingCalendarStore = () => {
  return useContext(hearingCalendarStoreContext);
};
