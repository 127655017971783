import { DeleteButtonCommon } from "@app/core/delete/buttons/common/_index";
import { DBRowState } from "@app/products/crms/[id]/model";
import { DirectDebitStatusCodeView } from "@app/products/direct-debit/system-admin/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IDirectDebitStatusCodeDeleteButtonProps {
  isDisabled?: boolean;
}
export const DirectDebitStatusCodeDeleteButton = observer(
  ({ isDisabled = false }: IDirectDebitStatusCodeDeleteButtonProps) => {
    const { gridSelectedRows } = useCCProductListViewStore();

    const ids = useMemo(() => {
      if (gridSelectedRows?.length === 0) return [];

      const selectedRow: DirectDebitStatusCodeView[] = gridSelectedRows.filter(
        (row) => row.Sys_DBRowState === DBRowState.Active
      );
      const data = selectedRow.map((row) => row.Direct_Debit_Status_Code_ID);
      return data ?? [];
    }, [gridSelectedRows]);

    return (
      <DeleteButtonCommon
        disabled={isDisabled || ids.length === 0}
        recordType={RECORDTYPE.Receipting_DirectDebit_Status_Code}
        ids={ids ?? []}
      />
    );
  }
);
