import { useWasteWaterStore } from "@app/products/waste-water/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistedWastewater } from "./components/forms/existed/_index";
import { NewWastewater } from "./components/forms/new/_index";
import "./_index.scss";

export default observer(() => {
  const { id } = useParams<{ id: string }>();
  const { resetStore, loadWasteWaterInfo } = useWasteWaterStore();
  const isNew = useIsNew();

  useEffect(() => {
    loadWasteWaterInfo(id, isNew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew]);

  useEffectOnce(() => {
    return () => resetStore();
  });

  if (isNew) {
    return <NewWastewater />;
  }
  return <ExistedWastewater />;
});
