import { ViewRelatedRecord } from "@app/products/waste-water/[id]/components/dialog/related/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<ViewRelatedRecord>();

export const colAddRelated: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("RecordType"),
    title: "Record Type",
  },
];
