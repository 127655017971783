import { PaymentFrequency } from "@app/products/direct-debit/model";

export const addDays = (date: Date, days: number) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getNextTransferDate = (
  startDate: Date,
  frequency: PaymentFrequency
) => {
  switch (frequency) {
    case PaymentFrequency.OneOff:
      return startDate;
    case PaymentFrequency.Weekly:
      return addDays(startDate, 7);
    case PaymentFrequency.Fortnightly:
      return addDays(startDate, 14);
    case PaymentFrequency.Monthly:
      let result = new Date(startDate);
      result.setMonth(result.getMonth() + 1);
      return result;
    case PaymentFrequency.Quarterly:
      let resultQuarterly = new Date(startDate);
      resultQuarterly.setMonth(resultQuarterly.getMonth() + 3);
      return resultQuarterly;
    case PaymentFrequency.HalfYearly:
      let resultHalfYearly = new Date(startDate);
      resultHalfYearly.setMonth(resultHalfYearly.getMonth() + 6);
      return resultHalfYearly;
    case PaymentFrequency.Yearly:
      let resultYearly = new Date(startDate);
      resultYearly.setFullYear(resultYearly.getFullYear() + 1);
      return resultYearly;
    default:
      return null;
  }
};
