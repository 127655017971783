import { getViewConfigurations } from "@app/products/property/api";
import { DTO_DeferredDuty_Account_Transaction } from "@app/products/property/deferred-duty/model";
import { getJournalDeferredDutyAccountTransactions } from "@app/products/property/journals/[id]/components/child-screens/deferred-duty-account-transactions/api";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class JournalDeferredDutyAccountTransactionStore {
  private _journalDDAccountTransaction: DTO_DeferredDuty_Account_Transaction[] =
    [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _viewConfiguration: ICCViewColumn[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get journalDDAccountTransaction() {
    return this._journalDDAccountTransaction;
  }
  setJournalDDAccountTransaction = (
    journalDDAccountTransaction: DTO_DeferredDuty_Account_Transaction[]
  ) => {
    runInAction(() => {
      this._journalDDAccountTransaction = journalDDAccountTransaction;
    });
  };

  get viewConfiguration() {
    return this._viewConfiguration;
  }
  setViewConfiguration = (viewConfiguration: ICCViewColumn[]) => {
    runInAction(() => {
      this._viewConfiguration = viewConfiguration;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  loadJournalDeferredDutyAccountTransactions = async (
    id: number | undefined
  ) => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    const [responseConfiguration, responseJournalDDAcountTransactionData] =
      await Promise.all([
        getViewConfigurations(ViewConfiguration.DeferredDuty_Transactions),
        getJournalDeferredDutyAccountTransactions(id),
      ]);
    if (
      isSuccessResponse(responseConfiguration) &&
      isSuccessResponse(responseJournalDDAcountTransactionData) &&
      responseConfiguration?.data &&
      responseJournalDDAcountTransactionData?.data?.DeferredDutyTransactions
        ?.Transactions
    ) {
      this.setViewConfiguration(
        responseConfiguration.data.ColumnDefinitions?.Columns
      );
      this.setJournalDDAccountTransaction(
        responseJournalDDAcountTransactionData.data.DeferredDutyTransactions
          .Transactions
      );
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._journalDDAccountTransaction = [];
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._viewConfiguration = [];
    });
  };
}

const journalDeferredDutyAccountTransactionsStoreContext = createContext(
  new JournalDeferredDutyAccountTransactionStore()
);
export const useJournalDeferredDutyAccountTransactionsStore = () => {
  return useContext(journalDeferredDutyAccountTransactionsStoreContext);
};
