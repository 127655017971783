import { INSPECTIONS_MANAGE_ROUTE } from "@app/core/inspections/[id]/constant";
import { HMInspectionView } from "@app/products/hm/inspections/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<HMInspectionView>();
export const colHMInspectionsByMonth: IColumnFields[] = [
  {
    field: nameOf("DateInspected"),
    title: "Date",
    locked: true,
    linkTo: (dataItem: HMInspectionView) => {
      return `${INSPECTIONS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("InspectionType"),
    title: "Inspection Type",
  },
  {
    field: nameOf("Reason"),
    title: "Reason",
  },
  {
    field: nameOf("Compliance"),
    title: "Compliance",
  },
  {
    field: nameOf("ReferenceNumber"),
    title: "Registration Number",
  },
  {
    field: nameOf("TradingName"),
    title: "Name",
  },
  {
    field: nameOf("PremisesClassification"),
    title: "Classification",
  },
  {
    field: nameOf("RiskClassification"),
    title: "Risk",
  },
  {
    field: nameOf("PremisesType"),
    title: "Premises Type",
  },
  {
    field: nameOf("Officer"),
    title: "Officer",
  },
  {
    field: nameOf("Address"),
    title: "Address",
  },
  {
    field: nameOf("Area"),
    title: "Area",
  },
  {
    field: nameOf("DateInspectedMonth"),
    title: "Date Inspected Month",
    format: DATE_FORMAT.MONTH_YEAR,
  },
];
