export enum MAILMERGEDATASET {
  SystemInitialise = 0, // Use this value to represent initial value ONLY. This is not a valid RECORDTYPE

  //Core Datasets - 1..100
  CORE_Action = 1,
  CORE_Complaint = 2,
  CORE_Fee = 3,
  CORE_Notice = 4,
  CORE_LiveData = 5,
  CORE_Contact = 6,
  CORE_InspectionPlanner = 7,
  CORE_Recall = 8,
  CORE_ComplaintInspection = 9,
  CORE_Interview = 10,
  CORE_Complaint_Interview = 11,
  CORE_Complaint_Notice = 12,
  CORE_Undertaking = 13,
  CORE_Investigation = 14,
  CORE_ActionPlan = 15,
  CORE_SearchWarrant = 16,
  CORE_PaymentPlan = 17,
  CORE_EducationProvider = 18,
  CORE_Education = 19,
  CORE_Prosecution = 20,
  CORE_Invoice = 21,

  //Health Manager Datasets - 101..200
  HealthManager_Premises = 102,
  HealthManager_Prosecution = 120,
  HealthManager_Notice = 121,
  HealthManager_Inspection = 122,
  HealthManager_Sample = 123,
  HealthManager_Fees = 124,
  HealthManager_Recall = 125,
  HealthManager_Action = 126,
  HealthManager_SwimmingPool = 127,
  HealthManager_CoolingTower = 128,
  HealthManager_Complaint = 129,
  HealthManager_ActivityReport = 130,
  HealthManager_Invoice = 131,

  //Wastewater Datasets - 201..300

  WasteWater_System = 201,
  WasteWater_Fees = 202,
  WasteWater_Sample = 203,
  WasteWater_Prosecution = 204,
  WasteWater_Notice = 205,
  WasteWater_Inspection = 206,
  WasteWater_Complaint = 207,
  WasteWater_Product = 208,
  WasteWater_Product_Fees = 209,
  WasteWater_Product_Sample = 210,
  WasteWater_Product_Prosecution = 211,
  WasteWater_Product_Notice = 212,
  WasteWater_Product_Inspection = 213,
  WasteWater_Product_Complaint = 214,
  WasteWater_Scheme = 215,
  WasteWater_Scheme_Fees = 216,
  WasteWater_Scheme_Sample = 217,
  WasteWater_Scheme_Prosecution = 218,
  WasteWater_Scheme_Notice = 219,
  WasteWater_Scheme_Inspection = 220,
  WasteWater_Scheme_Complaint = 221,
  WasteWater_Installation = 222,
  WasteWater_Installation_Fees = 223,
  WasteWater_Installation_Sample = 224,
  WasteWater_Installation_Prosecution = 225,
  WasteWater_Installation_Notice = 226,
  WasteWater_Installation_Inspection = 227,
  WasteWater_Installation_Complaint = 228,
  WasteWater_Action = 229,
  WasteWater_System_FurtherInformationRequests = 230,

  //CRS Datasets - 301..400
  CRS_Registration = 301,
  CRS_CBCRegistration = 302,
  CRS_3yoRegistration = 303,
  CRS_4yoRegistration = 304,
  CRS_HBCRegistration = 305,
  CRS_AcknowledgementService = 306,
  CRS_4yoRegistration_Invoice = 307,
  CRS_Registration_LateCollection = 308,
  //CRS_4yoRegistration_ZeroInvoice = 309,
  CRS_CBCRegistrationOffer = 310,
  CRS_3yoRegistrationOffer = 311,
  CRS_4yoRegistrationOffer = 312,
  CRS_Debtor_Statement = 313,
  CRS_3yoRegistration_Invoice = 314,
  //CRS Portal Datasets
  //CRS Portal  - Dev - Communication Template - Job Postponed
  //CRS_Portal_ForgotPassword = 399,

  //DISRES Datasets - 401..500
  DISRES_Permit = 401,
  DISRES_PropertyException = 402,
  DISRES_PropertyRestriction = 403,
  DISRES_ResidentParkingAreaDefinition = 404,
  DISRES_StaffParkingAreaDefinition = 405,
  DISRES_PermitLabel = 406,
  DISRES_Fees = 407,

  //Customer Service - 701..800
  CUSTOMERSERVICE_Event = 701,
  //CUSTOMERSERVICE_ColdChainBreach = 702,
  CUSTOMERSERVICE_Event_Actions = 703,
  CUSTOMERSERVICE_BulletinBoard_Actions = 704,
  CUSTOMERSERVICE_KBPage = 705,
  CUSTOMERSERVICE_PhoneMessage = 706,
  CUSTOMERSERVICE_Event_Inspections = 707,

  //Town Planning/Building - 801..900
  TOWNPLANNING_Application = 801,
  TOWNPLANNING_Application_Advertising = 802,
  TOWNPLANNING_Application_Appeals = 803,
  TOWNPLANNING_Application_Fees = 804,
  TOWNPLANNING_Application_FurtherInformationRequests = 805,
  TOWNPLANNING_Application_Inspections = 806,
  TOWNPLANNING_Application_Notices = 807,
  TOWNPLANNING_Application_Contacts = 808,
  TOWNPLANNING_Application_Complaints = 809,
  TOWNPLANNING_Application_Prosecution = 810,
  TOWNPLANNING_Application_Actions = 811,
  TOWNPLANNING_Subdivision = 812,
  TOWNPLANNING_BuildingApplication = 813,
  TOWNPLANNING_Building_Fees = 814,
  TOWNPLANNING_Building_Inspections = 816,
  TOWNPLANNING_Building_Notices = 817,
  TOWNPLANNING_Building_Complaints = 819,
  TOWNPLANNING_Building_Prosecution = 820,
  TOWNPLANNING_Building_Actions = 821,
  TOWNPLANNING_PSA_Applications = 822,
  TOWNPLANNING_PPR_Applications = 823,
  TOWNPLANNING_PPR_Actions = 824,
  TOWNPLANNING_PPR_Appeals = 825,
  TOWNPLANNING_PPR_FurtherInformationRequests = 826,
  TOWNPLANNING_Building_Interviews = 827,
  TOWNPLANNING_PNF_Applications = 828,
  TOWNPLANNING_PNF_DueDiligence = 829,
  TOWNPLANNING_PNF_Operation = 830,
  TOWNPLANNING_PNF_Actions = 831,
  TOWNPLANNING_PNF_FieldObservation = 832,
  BUILDING_Application_FurtherInformationRequests = 833,

  //MCH Datasets 1001-1100
  MCH_Registration = 1001,
  MCH_Appointment = 1002,

  //Registers - 1101..1200
  Register_CouncilConsent = 1101,
  Register_Demolition = 1102,
  Register_ESBuilding = 1103,
  Register_NonCompliance = 1104,
  Register_PlanningCertficate = 1105,
  Register_PlanningEnquiry = 1106,
  Register_PlansToComply = 1107,
  Register_POPE = 1108,
  Register_SecondaryConsent = 1109,
  Register_StreetProtection = 1110,
  Register_Fees = 1111,
  Register_Inspections = 1112,
  Register_Notices = 1113,
  Register_Prosecution = 1114,
  Register_Action = 1115,
  Register_MultiTenancy = 1116,
  Register_BuildingPoolsSpa = 1117,
  Register_BuildingEnquiry = 1118,
  Register_BuildingStormwater = 1119,
  Register_BuildingEasements = 1120,
  Register_BuildingEnforcements = 1121,
  Register_BuildingSitingApprovals = 1122,
  Register_Appeals = 1123,
  Register_EOT = 1124,
  Register_Advertising = 1125,
  Register_FurtherInformationRequests = 1126,
  Register_BuildingCertificate = 1127,
  Register_Contacts = 1128,
  Register_HealthEnquiry = 1129,

  //LLPermits - 1201..1300
  LLP_Permit = 1201,
  LLP_Application_Fees = 1204,
  LLP_Application_Inspections = 1206,
  LLP_Application_Notices = 1207,
  LLP_Application_Prosecution = 1210,
  LLP_Application_Action = 1211,

  //  Animals - 1301..1400
  Animals_Registration = 1301,
  Animals_Fees = 1304,
  Animals_Inspections = 1306,
  Animals_Notices = 1307,
  Animals_Prosecution = 1310,
  Animals_Actions = 1311,
  Animals_PoundRegister = 1312,
  Animals_PoundRegister_Actions = 1313,
  Animals_PoundRegister_Fees = 1314,
  Animals_InspectionPlannerInspections = 1315,
  Animals_Kennel = 1316,
  Animals_Kennel_Actions = 1317,
  Animals_Kennel_Fees = 1318,
  Animals_Invoice = 1319,

  //CSM - 1401..1500
  CSM_Licence = 1401,
  CSM_Fees = 1404,
  CSM_Inspections = 1406,
  CSM_Samples = 1407,
  CSM_Prosecution = 1410,
  CSM_Notice = 1411,
  CSM_Action = 1412,

  //AMS - 1501 .. 1600
  AMS_Appointment = 1501,

  //EMS - 1601 .. 1700
  EMS_EventBooking = 1601,
  EMS_GroupEventBooking = 1602,

  //Training Manager 1701 .. 1800
  TrainingManager_CourseOutline = 1701,
  TrainingManager_CourseSchedule = 1702,
  TrainingManager_TrainingRequest = 1703,

  //NSPR - 1801 .. 1900
  NSP_Register = 1801,
  NSP_Actions = 1802,

  //Inspection Planner - 1901 .. 1925
  InspectionPlanner_Inspection = 1901,

  //RAD - 1926 .. 2000
  RAD_Register = 1926,
  RAD_Notice = 1927,
  RAD_Inspection = 1928,
  RAD_Fees = 1929,
  RAD_Action = 1930,
  RAD_Register_Licence = 1931,
  RAD_Register_Source = 1932,
  RAD_Register_Place = 1933,
  RAD_Register_Accreditation = 1934,
  RAD_Incident = 1935,
  RAD_ResearchProject = 1936,
  RAD_Refund = 1937,

  //SSMS - 2001..2100
  SSMS_ChangeRequest = 2001,

  //SRU - 3001..3100
  SRU_Audit = 3001,
  SRU_Application = 3002, //SRS_Amendment = 609,SRS_ReportableIncident=607
  SRU_Organisation = 3003,
  SRU_Issue = 3004,
  SRU_Registration = 3005, //SRS_Registration = 601
  SRU_Seizure = 3006, //SRS_Seizure = 606,
  SRU_Notice = 3007,
  SRU_Statement = 3008,
  SRU_SRSUndertaking = 3009, //SRS_RegistrationUndertaking=603
  SRU_Sanction = 3010, //SRS_RegistrationSanction=602
  SRU_AppointAdministrator = 3011,
  SRU_ReportableTransaction = 3012, //SRS_ReportableTransaction = 608
  //SRU_SRSReportableIncident = 3013,   //SRS_ReportableIncident=607 -REMOVED BECAUSE THIS IS AN APPLICATION
  SRU_RegistrationStatement = 3014, //SRS_RegistrationStatement = 604,
  SRU_RegistrationInspection = 3015, //SRS_RegistrationInspection = 610
  SRU_RegistrationNotice = 3016, //SRS_RegistrationNotice = 611
  SRU_RegistrationProsecution = 3017, //SRS_RegistrationProsecution = 612
  SRU_RegistrationFees = 3018, //SRS_RegistrationFees = 613
  SRU_ComplianceInstruction = 3019, //SRS_ComplianceInstruction = 614
  SRU_Inspections = 3020, //SRS_ComplianceInstruction = 614

  //DWCMS - 3101..3200
  DWCMS_Audit = 3101,
  DWCMS_Application = 3102,
  DWCMS_Worker = 3203,
  DWCMS_Registration = 3105,
  DWCMS_Employment = 3106,
  DWCMS_DisciplinaryAction = 3107,
  DWCMS_Impairment = 3108,
  DWCMS_Insurance = 3109,
  DWCMS_CriminalHistory = 3110,
  DWCMS_ServiceProvider = 3111,
  DWCMS_Worker_Undertaking = 3113,
  DWCMS_Division = 3114,
  DWCMS_Address_History = 3115,
  DWCMS_RegistrationType = 3116,
  DWCMS_OtherNamesPracticed = 3117,
  DWCMS_ProfessionalRegistration = 3118,
  DWCMS_ProfessionalCompetence = 3119,
  DWCMS_PrimaryPlaceOfPractice = 3120,
  DWCMS_Worker_Request = 3121,
  DWCMS_Condition = 3122,
  DWCMS_Enquiry = 3123,

  //CEM - 3201..3300
  CEM_RegisterApplication = 3201,
  CEM_Entry = 3202,

  //Infringements - 3301..3400
  INFRINGEMENTS_TICKET = 3301,
  INFRINGEMENTS_Ticket_Appeals = 3302,
  Register_InfringementCourts = 3303,

  //FLEET - 3401 .. 3500
  FLEET_EventBooking = 3401,
  FLEET_GroupEventBooking = 3402,

  //LandManagement - 3501 .. 3600
  LANDMANAGEMENT_LandHoldingApplications = 3501,
  LANDMANAGEMENT_Certification = 3502,
  LANDMANAGEMENT_Notification = 3503,
  LANDMANAGEMENT_Enquiry = 3504,
  LANDMANAGEMENT_Inspection = 3505,
  LANDMANAGEMENT_FurtherInformationRequests = 3506,

  //CommunityProperty - 3601 .. 3800
  COMMUNITYPROPERTY_Assessment = 3601,
  COMMUNITYPROPERTY_Parcel = 3602,
  COMMUNITYPROPERTY_Title = 3603,
  COMMUNITYPROPERTY_Supplementary = 3604,
  COMMUNITYPROPERTY_Scheme = 3605,
  COMMUNITYPROPERTY_Meter = 3606,
  COMMUNITYPROPERTY_Journal = 3607,
  COMMUNITYPROPERTY_Change_of_Ownership = 3608,
  COMMUNITYPROPERTY_Entity = 3609,
  COMMUNITYPROPERTY_Certificate = 3610,
  COMMUNITYPROPERTY_Deferred_Duty = 3611,
  COMMUNITYPROPERTY_Fire_Prevention = 3612,
  COMMUNITYPROPERTY_Register = 3613,
  COMMUNITYPROPERTY_Compliance = 3614,
  COMMUNITYPROPERTY_Debt_Recovery = 3615,
}
