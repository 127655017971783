import { apiCoreAddConditions } from "@app/core/components/common/utils";
import { Svc_ConditionRecord } from "@app/core/condition/components/dialogs/list-condition-references/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { RecordCountPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const addConditions = async (
  body: Svc_ConditionRecord[],
  cancelToken?: CancelToken
): Promise<APIResponse<RecordCountPacket>> => {
  try {
    return await CoreAPIService.getClient().post(apiCoreAddConditions(), body, {
      cancelToken,
    });
  } catch (error) {
    return handleCatchResponse(error);
  }
};
