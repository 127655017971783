import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { actionsAndLettersRoute } from "./actions-and-letters/route";
import { entitiesWithMultipleExemptionsRoute } from "./entities-with-multiple-exemptions/route";

export const complianceRoute: ICCRoute = {
  path: "compliance",
  name: "Compliance",
  enum: eMenuAction.Compliance_Folder,
  children: [
    {
      path: "active-with-assessments",
      name: "Active with Assessments",
      enum: eMenuAction.Compliance_ActiveWithAssessment,
      component: require("./active-with-assessments/_index").default,
    },
    {
      path: "all",
      name: "All",
      enum: eMenuAction.Compliance_All,
      component: require("./all/_index").default,
    },

    {
      path: "all-without-assessments",
      name: "All without Assessments",
      enum: eMenuAction.Compliance_AllWithoutAssessment,
      component: require("./all-without-assessments/_index").default,
    },

    {
      path: "active-without-assessments",
      name: "Active without Assessments",
      enum: eMenuAction.Compliance_ActiveWithoutssessment,
      component: require("./active-without-assessments/_index").default,
    },
    {
      path: "land-tax-exemptions",
      name: "Land Tax Exemptions",
      enum: eMenuAction.Compliance_LandTaxExemption,
      component: require("./land-tax-exemptions/_index").default,
    },
    {
      path: "candidates",
      name: "Candidates",
      enum: eMenuAction.Compliance_Candidates,
      component: require("./candidates/_index").default,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
    entitiesWithMultipleExemptionsRoute,
    {
      path: "candidates-land-rent",
      name: "Candidates Land Rent",
      enum: eMenuAction.Compliance_LandRent,
      component: require("./candidates-land-rent/_index").default,
    },
    actionsAndLettersRoute,
  ],
};
