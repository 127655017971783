import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const rebateEntitlementsRoute: ICCRoute = {
  path: "rebate-entitlement-exceptions",
  name: "Rebate Entitlement Exceptions",
  enum: eMenuAction.RebateEntitlement_List_Exceptions,
  children: [
    {
      path: "all",
      name: "All",
      enum: eMenuAction.RebateEntitlement_List_Exceptions_All,
      component: require("./all/_index").default,
    },
    {
      path: "only-active-rebate-entitlements",
      name: "Only Active Rebate Entitlements",
      enum: eMenuAction.RebateEntitlement_List_Exceptions_OnlyActive,
      component: require("./only-active-rebate-entitlements/_index").default,
    },
  ],
};
