import {
  IPPRRelated,
  IRelatedParams,
} from "@app/products/town-planning/ppr/[id]/components/child-screens/related/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getPPRRelated = async (
  relatedParam: IRelatedParams
): Promise<APIResponse<IPPRRelated[]>> => {
  try {
    return await CoreAPIService.getClient().get<IPPRRelated[]>(
      `api/core/internal/refusage/relatedrecords/`,
      {
        params: {
          recordType: relatedParam.RecordType,
          recordId: relatedParam.RecordID,
          invoiceNumber: relatedParam.InvoiceNumber || null,
          assessmentNumberKey: relatedParam.AssessmentNumberKey || null,
          addressLine1:
            relatedParam?.AddressLine1 &&
            relatedParam?.AddressLine1?.trim().length > 0
              ? relatedParam.AddressLine1
              : null,
          suburb: relatedParam.Suburb || null,
          personKey: relatedParam.PersonKey,
          wasteWaterSchemeKey: relatedParam.WasteWaterSchemeKey,
          csmRefNoKey: relatedParam.CsmRefNoKey || null,
          registerType: relatedParam.RegisterType,
          ancestorRecordType: relatedParam.AncestorRecordType,
          ancestorRecordId: relatedParam.AncestorRecordId,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const getRegisterType = async (
  registerId: number
): Promise<APIResponse<number>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/core/internal/refusage/register/${registerId}/type`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
