import { Journal } from "@app/core/journal/_index";
import { getCSLById } from "@app/products/csl/[id]/components/reference-sidebar/api";
import { CSLRegisterForm, CSLResponse } from "@app/products/csl/[id]/model";
import { convertCSLResponseToForm } from "@app/products/csl/[id]/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import { default as React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const CSLHistoryTab = observer(() => {
  const [cslInfo, setCslInfo] = useState<CSLRegisterForm>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { lastSelectedRow } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const cslId = id || lastSelectedRow.ID;
  useEffect(() => {
    setIsLoading(true);
    getCSLById(cslId).then((data?: CSLResponse) => {
      if (data) setCslInfo(convertCSLResponseToForm(data));
      setIsLoading(false);
    });
  }, [cslId]);

  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <Journal id={cslInfo?.Licence_ID} recordType={RECORDTYPE.CSM_Licence} />
  );
});
