import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import {
  getStateRoadByIdNo,
  searchStateRoadsOnly,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/state-roads-picker-search/api";
import { isSuccessResponse } from "@common/apis/util";
import { useDebounce } from "@common/hooks/useDebounce";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCDialog, ICCDialogProps } from "@components/cc-dialog/_index";
import { CCGrid, ICCGridProps } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCInput } from "@components/cc-input/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBox,
  ComboBoxFilterChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent, InputProps } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { isArray, isNull, isUndefined } from "lodash";
import {
  MouseEventHandler,
  default as React,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./_index.scss";

export interface ICCInputPickerSearchChangeEvent {
  value: any;
}

export interface ICCStateRoadsPickerSearch
  extends Omit<InputProps, "validationMessage" | "value" | "onChange"> {
  options: IOptionInputPicker;
  validationMessage?: string | null;
  visited?: boolean;
  value: any;
  onChange?: (event: any) => void;
  onInputChange?: (event: InputChangeEvent) => void;
  onError?: (value: any) => void;
  onLoadData?: () => void;
  textField?: string;
  textProduce?: (value: any) => string | undefined;
  valueField?: string;
  valueProduce?: (value: any) => any | undefined;
  dialog?: Omit<
    ICCDialogProps,
    "bodyElement" | "onClose" | "footerElement" | "width" | "height"
  >;
  customDialog?: (
    value: any,
    onClose: () => void,
    onSubmit: (value: any) => void
  ) => ReactElement;
  grid?: ICCGridProps;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  showClearButton?: boolean;
  onClickCancel?: () => void;
  currentCouncil?: string;
  uniqueKey: string;
}

export const StateRoadsPickerSearch = (props: ICCStateRoadsPickerSearch) => {
  const {
    options,
    validationMessage,
    visited,
    className,
    value,
    textField,
    textProduce,
    onChange,
    disabled,
    customDialog,
    onButtonClick,
    onLoadData,
    showClearButton,
    placeholder,
    name,
    onError,
    currentCouncil,
    uniqueKey,
    ...others
  } = props;
  const councilName = requiredValidator(currentCouncil).length
    ? "the council"
    : currentCouncil;
  const [searchKey, setSearchKey] = useState("");
  const [resultsSearch, setResultsSearch] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearch = useDebounce(searchKey, 500);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isReadMode, setIsReadMode] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [gridSelectedRows, setGridSelectedRows] = useState<any[]>(
    props.grid?.selectedRows ?? []
  );
  const [isLoadingYes, setIsLoadingYes] = useState(false);

  const inputValue = useMemo(() => {
    //order condition was checked base on requirement of this field
    if (textProduce) return textProduce(value);
    if (isUndefined(value) || isNull(value)) return "";
    if (textField && !isArray(value) && textField in value)
      return value[textField];
    return value ?? "";
  }, [value, textField, textProduce]);

  const valueDisplayCombobox = useMemo(() => {
    return value?.RoadName ? value?.RoadName : "";
  }, [value]);

  const handleOnClickSelectButton = (value: any) => {
    setShowDialog(false);
    setIsReadMode(true);
    if (onChange) onChange(value);
  };

  const handleOnClickButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (onButtonClick) return onButtonClick(event);
    setSearchKey("");
    setShowDialog(true);
    setGridSelectedRows(props.grid?.selectedRows ?? []);
    if (onLoadData) return onLoadData();
  };

  useEffect(() => {
    (async () => {
      if (debouncedSearch && options.grid.dataUrl) {
        setIsSearching(true);
        const response = await searchStateRoadsOnly(
          debouncedSearch,
          options.boxSearch.colSearch,
          options.grid.dataUrl
        );
        setResultsSearch(response.data.value || []);
        setIsSearching(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const ItemRender = (
    li: ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const { dataItem } = itemProps;
    const itemChildren = (
      <div className="cc-search-item">
        {options.boxSearch.colShowComboboxSearch.map((col: IColumnFields) => (
          <div key={col.field}>{sanitizeHtml(dataItem[col.field] ?? "")}</div>
        ))}
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    const searchText = event.filter.value;
    setSearchKey(searchText);
  };

  const handleOnClickCancel = () => {
    setShowDialog(false);
    setShowConfirmDialog(true);
  };

  const handleConfirmNo = () => {
    setShowConfirmDialog(false);
    setIsReadMode(false);
    if (onChange) onChange(null);
  };

  const handleConfirmYes = () => {
    setIsLoadingYes(true);
    getStateRoadByIdNo().then((response) => {
      if (isSuccessResponse(response)) {
        if (response.data) {
          if (onChange) onChange(response.data);
          setIsReadMode(true);
        } else {
          if (onError) onError(response.error);
        }
      } else {
        if (onError) onError(response.error);
      }
      setIsLoadingYes(false);
      setShowConfirmDialog(false);
    });
  };

  const handleOnChangeSearch = (event: any) => {
    if (onChange) {
      if (event?.target?.value) {
        onChange(event?.target?.value);
        setIsReadMode(true);
      } else {
        onChange(null);
      }
    }
  };

  return (
    <>
      <div
        className={`${
          className ?? ""
        } cc-state-roads-search cc-input-picker-new ${
          !others.valid ? "cc-input-picker-invalid" : ""
        }`}
      >
        <div
          style={{ display: `${!isReadMode ? "none" : "block"}` }}
          className={`${
            !others.valid ? "cc-html-invalid" : ""
          } cc-input-picker-html k-textbox`}
          onClick={() => {
            //set for auto focus field when convert html to combobox
            const comboboxElement = document.getElementById(uniqueKey);
            setTimeout(() => {
              comboboxElement?.focus();
            }, 100);
            setIsReadMode(false);
          }}
        >
          {sanitizeHtml(inputValue)}
        </div>

        <div
          className="cc-combobox-state-roads"
          style={{ display: `${isReadMode ? "none" : "block"}` }}
        >
          <ComboBox
            id={uniqueKey}
            placeholder={placeholder}
            disabled={disabled}
            required={!others.valid}
            filterable
            suggest
            data={resultsSearch}
            loading={isSearching}
            onFilterChange={handleSearch}
            itemRender={ItemRender}
            value={valueDisplayCombobox}
            onChange={handleOnChangeSearch}
            popupSettings={{ className: "cc-state-roads-picker-search-popup" }}
            onBlur={() => setIsReadMode(true)}
          />
        </div>
        {showClearButton && (
          <Button
            disabled={disabled}
            className="cc-input-picker-button cc-clear-button"
            icon="close"
            onClick={(event: any) => {
              event.preventDefault();
              if (onChange) onChange(null);
            }}
          />
        )}
        <Button
          disabled={disabled}
          className="cc-input-picker-button"
          iconClass="fa fa-ellipsis-h"
          onClick={handleOnClickButton}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
      {showConfirmDialog && (
        <ConfirmDialog
          title="Local Road"
          subMessage={`Is this LOCAL Road for ${councilName}?`}
          onClosePopup={() => setShowConfirmDialog(false)}
          onConfirmNo={handleConfirmNo}
          onConfirmYes={handleConfirmYes}
          btnCancelVisible={false}
          isLoadingYes={isLoadingYes}
        />
      )}
      {showDialog &&
        (customDialog ? (
          customDialog(
            value,
            () => setShowDialog(false),
            handleOnClickSelectButton
          )
        ) : (
          <CCDialog
            dialogWrapperClassName="cc-state-roads-picker-search-dialog"
            {...options.dialog}
            onClose={() => setShowDialog(false)}
            bodyElement={
              <div className="cc-search-result">
                <div className="cc-search-result-title">
                  <label className="cc-label">Search</label>
                  <Field
                    name={"searchKey"}
                    component={CCInput}
                    placeholder="Search"
                    onChange={(e: InputChangeEvent) => {
                      setSearchKey(e.value);
                    }}
                  />
                </div>
                <div className="cc-search-result-body">
                  <CCGrid
                    {...options.grid}
                    data={searchKey ? resultsSearch : undefined}
                    dataUrl={searchKey ? undefined : options.grid.dataUrl}
                    isLoading={isSearching}
                    selectedRows={gridSelectedRows}
                    onSelectionChange={(dataItems) => {
                      setGridSelectedRows(dataItems);
                    }}
                  />
                </div>
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  onClick={handleOnClickCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  disabled={gridSelectedRows.length === 0}
                  onClick={() => {
                    handleOnClickSelectButton(
                      options.grid.selectableMode === "multiple"
                        ? gridSelectedRows
                        : gridSelectedRows[0]
                    );
                  }}
                >
                  Select
                </Button>
              </div>
            }
          />
        ))}
    </>
  );
};
