import { ICustomEditCell } from "@components/cc-grid/model";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import React from "react";

export const ProvisionalHoldingNumberCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
  format,
}: ICustomEditCell) => {
  return (
    <NumericTextBox
      value={value}
      format={format}
      min={1}
      onChange={(event) => {
        if (onChange)
          onChange({
            field: field,
            dataIndex: dataIndex || 0,
            dataItem,
            syntheticEvent: event.syntheticEvent,
            value: event.value ?? 1,
          });
      }}
    />
  );
};
