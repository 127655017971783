import { IColumnFields } from "@components/cc-grid/model";

export const colMetersUserFields: IColumnFields[] = [
  {
    field: "userField",
    title: "Attribute",
  },
  {
    field: "value",
    title: "Value",
    editable: true,
  },
];
