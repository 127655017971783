import { getDisplayTextWithDashes } from "@common/utils/common";

export const SecurityOrgStructureBookmark = {
  getBookmarkListViewDisplayName() {
    return "Settings - Security";
  },

  getBookmarkListViewDetail() {
    return "Settings - Security - Org Structure";
  },

  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.OrgUnit_Name
      ? `- ${selectedRow.ID}`
      : "";
    return `Settings - Org Structure ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.ID + ", " + selectedRow.OrgUnit_Name,
    ]);
  },
};
