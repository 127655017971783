export const mockNewCertificate = [
  {
    Assessment: "678282 - Abrehart St Archies Creek",
    Parcel: "12 - 287 Abrehart St Archies Creek",
    ParcelDetail: {
      ParcelId: 12,
      Reference: 12,
      Address: "287 Abrehart St Archies Creek",
      LegalDescription: "Lot 25 DP 9243",
      Owners: "D PFluger & B & L Russell",
    },
    AssessmentDetail: {
      AssessmentNumber: 678282,
      AssessmentReference: 749811,
      PropertyAddress: "287 Abrehart St Archies Creek",
      LegalDescription: "Lot 25 DP 9243",
      Owners: "D PFluger & B & L Russell",
      AssessmentId: 11,
    },
  },
  {
    Assessment: "749819 - 3295 Abrehart St Archies Creek",
    Parcel: "20 - 3295 Abrehart St Archies Creek",
    ParcelDetail: {
      ParcelId: 20,
      Reference: 20,
      Address: "3295 Abrehart St Archies Creek",
      LegalDescription: "Lot 69 DP13389",
      Owners: "Alpha Autism Incorporated",
    },
    AssessmentDetail: {
      AssessmentNumber: 749819,
      AssessmentReference: 749819,
      PropertyAddress: "3295 Abrehart St Archies Creek",
      LegalDescription: "Lot 69 DP 13389",
      Owners: "Alpha Autism Incorporated",
      AssessmentId: 19,
    },
  },
  {
    Assessment: "749817 - 710 Abrehart St Archies Creek",
    Parcel: "18 - 710 Abrehart St Archies Creek",
    ParcelDetail: {
      ParcelId: 18,
      Reference: 18,
      Address: "710 Abrehart St Archies Creek",
      LegalDescription: "Lot 69 DP12139",
      Owners: "P J & C PASTORE",
    },
    AssessmentDetail: {
      AssessmentNumber: 749817,
      AssessmentReference: 749817,
      PropertyAddress: "710 Abrehart St Archies Creek",
      LegalDescription: "Lot 69 DP 12139",
      Owners: "P J & C PASTORE",
      AssessmentId: 17,
    },
  },
];

export const certificateTypes = [
  {
    Code: "1",
    Name: "Planning 149 Certificate",
  },
  {
    Code: "2",
    Name: "Planning 149(5) Certificate",
  },
  {
    Code: "3",
    Name: "Rates 603 Certificate",
  },
];
