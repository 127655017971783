import { UnitDateCell } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/rebates/components/unit-date-cell/_index";
import {
  DTO_RebateEntitlementOwners,
  DTO_Rebates,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfRebate = nameOfFactory<DTO_Rebates>();
const nameOfOwner = nameOfFactory<DTO_RebateEntitlementOwners>();

export const colRebate: IColumnFields[] = [
  {
    field: nameOfRebate("is_InActive"),
    title: "Inactive",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: nameOfRebate("Loss_Of_Eligibility_Date"),
    title: "Loss Of Eligibility Date",
    format: DATE_FORMAT.DATE,
    editCell: UnitDateCell,
    editable: true,
  },
  {
    field: nameOfRebate("Rebate_Type"),
    title: "Rebate Type",
  },
  {
    field: nameOfRebate("Percentage"),
    title: "Percentage of Full Rebate",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOfRebate("Application_Date"),
    title: "Application Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfRebate("Effective_Date"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfRebate("Rebate_Entitlement_Id"),
    title: "Assessment Rebate Entitlement ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colRebateOwner: IColumnFields[] = [
  {
    field: nameOfOwner("Owner"),
    title: "Owner",
  },
  {
    field: nameOfOwner("Entitled_To_Rebate"),
    title: "Entitled to Rebate",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfOwner("ConcessionCard_Held"),
    title: "Concession Cards",
  },
  {
    field: nameOfOwner("Entity_Id"),
    title: "Entity ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
