import { IReportPowerBI } from "@common/pages/report/powerbi/list/model";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const ReportBookmark = {
  getBookmarkDisplayNameReport(selectedRow: IReportPowerBI) {
    return getDisplayTextWithDashes([selectedRow.name, selectedRow.id]);
  },

  getBookmarkDetailReport(selectedRow: IReportPowerBI) {
    return `Reports - Power BI`;
  },

  getBookmarkListViewDisplayNameReport() {
    return "Reports - Power BI";
  },

  getBookmarkListViewDetailReport(): string {
    return `Reports - Power BI`;
  },
};
