export const mockConditions = [
  {
    ID: "1",
    SortOrder: 1.0,
    Group: "Radiation Safety",
    Title: "",
    EnterableFields: "",
    Condition: "",
  },
];
