import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { DescriptionLabel } from "@components/description-label/DescriptionLabel";
import { SettingButtonEdit } from "@components/setting/setting-button-edit/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import React from "react";
import "./_index.scss";
interface ISettingSwitchInputProps {
  name: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (arg0: SwitchChangeEvent) => void;
  handleOnEdit?: any;
  handleOnCancel?: any;
  description?: string;
  isMandatory?: boolean;
  isEnabledEditButton?: boolean;
  formRenderProps?: FormRenderProps;
  isLoading?: boolean;
}

export const SettingSwitchInput: React.FC<ISettingSwitchInputProps> = ({
  name,
  label,
  checked,
  disabled,
  onChange,
  description = "",
  isMandatory,
  isEnabledEditButton,
  handleOnCancel = () => {},
  handleOnEdit = () => {},
  formRenderProps = undefined,
  isLoading,
}) => {
  checked = checked === null || checked === undefined ? false : checked;
  return (
    <div className="cc-setting-switch-input">
      <div className="cc-label-flex">
        <DescriptionLabel title={label} description={description} />
        {isMandatory && <CCTooltip type="validator" position="right" />}
      </div>

      <div className="cc-field-flex">
        <CCSwitch
          name={name}
          checked={checked}
          disabled={disabled ?? false}
          onChange={onChange}
        />
        {isEnabledEditButton ? (
          <SettingButtonEdit
            isEditing={!disabled}
            handleOnEdit={handleOnEdit}
            handleOnCancel={handleOnCancel}
            formRenderProps={formRenderProps}
            isLoading={isLoading}
          />
        ) : null}
      </div>
    </div>
  );
};
