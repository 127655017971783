import { localityUsedFields } from "@app/products/csl/[id]/components/child-screens/general/components/form-element/config";
import { CSLRegisterForm } from "@app/products/csl/[id]/model";
import { nameOfFactory, populateValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export const CSLFormBodyR25 = (formRenderProps: FormRenderProps) => {
  const getValue = formRenderProps.valueGetter;
  const nameOf = nameOfFactory<CSLRegisterForm>();
  return (
    <>
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">
            Location/Site used
            <CCTooltip type="validator" position="right" />
          </label>
          <Field
            component={InputPicker}
            {...localityUsedFields(formRenderProps)}
            name={"LocalityUsed"}
            validator={requiredValidator}
          />
        </div>
      </div>

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            Reg 25 poisons
            <CCTooltip type="validator" position="right" />
          </label>
          <Field
            rows={5}
            name={nameOf("Reg25Poisons")}
            value={getValue(nameOf("Reg25Poisons"))}
            component={CCTextArea}
          />
        </div>
      </div>

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            Purpose of
            <CCTooltip type="validator" position="right" />
          </label>
          <Field
            rows={5}
            name={nameOf("PurposeOf")}
            value={getValue(nameOf("PurposeOf"))}
            component={CCTextArea}
          />
        </div>
      </div>

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Max quantity</label>
          <Field name={nameOf("MaxQuantity")} component={CCInput} />
        </div>
      </div>

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            Persons application to access
            <CCTooltip type="validator" position="right" />
          </label>
          <Field
            rows={5}
            name={nameOf("PersonsApprovedToAccess")}
            value={getValue(nameOf("PersonsApprovedToAccess"))}
            component={CCTextArea}
          />
        </div>
      </div>

      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">
            Supplier
            <CCTooltip type="validator" position="right" />
          </label>
          <Field
            disabled={true}
            autoComplete="off"
            name={nameOf("tradingName")}
            className="disabled"
            component={InputPicker}
            validator={requiredValidator}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Address</label>
          <div className="cc-label-field">
            {populateValue(getValue(nameOf("address")))}
          </div>
        </div>
        <div className="cc-field">
          <label className="cc-label">Contact</label>
          <div className="cc-label-field">
            {populateValue(getValue(nameOf("postalAddress")))}
          </div>
        </div>
      </div>
    </>
  );
};
