import {
  PERMIT_TYPE_BY_ID_API,
  PERMIT_TYPE_LOVS_API,
} from "@app/products/local-laws/system-admin/permit-types/[id]/constant";
import {
  PermitType,
  PermitTypeLOVs,
} from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IdentityPacket, IIdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const getPermitTypeById = async (
  permitTypeId: number,
  cancelToken?: CancelToken
): Promise<APIResponse<IIdentityPacket<PermitType>> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<IIdentityPacket<PermitType>>(
      PERMIT_TYPE_BY_ID_API.replace("[id]", `${permitTypeId}`),
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const getPermitTypeLOVs = async (
  cancelToken: CancelToken
): Promise<APIResponse<PermitTypeLOVs> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<PermitTypeLOVs>(
      PERMIT_TYPE_LOVS_API,
      {
        cancelToken,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postSavePermitType = async (
  permitTypeObj: PermitType,
  cancelToken: CancelToken
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "api/locallaw/internal/permit-type",
      permitTypeObj,
      {
        cancelToken,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
