import {
  IAlternateAddress,
  IProprietor,
  IPurchaser,
} from "@app/products/property/certificates/e-certificate-requests/[id]/components/child-screens/general/components/form-element/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfIPurchaser = nameOfFactory<IPurchaser>();
const nameOfIProprietor = nameOfFactory<IProprietor>();
const nameOfIAlternateAddress = nameOfFactory<IAlternateAddress>();

export const colProprietor: IColumnFields[] = [
  {
    field: nameOfIProprietor("ProprietorNames"),
    title: "Proprietor Names",
  },
];

export const colPurchaser: IColumnFields[] = [
  {
    field: nameOfIPurchaser("PurchaserNames"),
    title: "Purchaser Names",
  },
];

export const colAlternateAddresses: IColumnFields[] = [
  {
    field: nameOfIAlternateAddress("AlternateAddresses"),
    title: "Alternate Addresses",
  },
];
