import { TreeListLineage } from "@app/products/property/components/lineage/components/lineage-tree-list/_index";
import { useLineageStore } from "@app/products/property/components/lineage/store";
import { PROPERTY_TITLES_ROUTE } from "@app/products/property/titles/[id]/constant";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

interface ILineageProps {
  apiUrl: string;
  urlDetail?: string;
}

export const Lineage = observer(
  ({ apiUrl, urlDetail = PROPERTY_TITLES_ROUTE }: ILineageProps) => {
    const { isLoading, lineage, loadLineage, resetStore } = useLineageStore();

    useEffect(() => {
      if (!apiUrl) return;
      loadLineage(apiUrl);
      return () => {
        resetStore();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiUrl, loadLineage]);

    if (isLoading) return <Loading isLoading={isLoading} />;

    return (
      <div className="cc-lineage cc-form">
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <TreeListLineage
                currentTitle="Parents"
                url={urlDetail}
                data={lineage?.LineageParents ?? []}
              />
            </div>
            <div className="cc-field">
              <TreeListLineage
                currentTitle="Children"
                url={urlDetail}
                data={lineage?.LineageChildren ?? []}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
);
