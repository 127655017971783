import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { NewPremiseButton } from "@app/products/hm/components/action-bar/buttons/new-premises/_index";
import { HMActionBarNavDropdown } from "@app/products/hm/components/action-bar/nav-dropdown/_index";
import { HMUrl } from "@app/products/hm/config";
import { InspectionContactsTab } from "@app/products/hm/inspections/[id]/components/reference-sidebar/contacts/_index";
import { InspectionDetailsTab } from "@app/products/hm/inspections/[id]/components/reference-sidebar/details/_index";
import { InspectionHistoryTab } from "@app/products/hm/inspections/[id]/components/reference-sidebar/history/_index";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
} from "@app/products/hm/premises/util";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { hmRoute } from "@app/products/hm/route";
import { colHMTobaccoPrelodgements } from "@app/products/hm/tobacco/prelodgements/config";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/hm/tobacco/prelodgements/utils";
import { tobaccoRoute } from "@app/products/hm/tobacco/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

export default () => {
  useCCListViewActionBar({
    title: hmRoute.name,
    leftComponents: [<HMActionBarNavDropdown category={tobaccoRoute.path} />],
    centerComponents: [
      <NewPremiseButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.HealthManager}
        dataSetFilter={MAILMERGEDATASET.HealthManager_Premises}
        recordType={RECORDTYPE.HealthManager_Premises}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.HealthManager}
        recordType={RECORDTYPE.HealthManager_Premises}
      />,
      <DeleteButton />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PREMISES_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.HealthManager}
        recordType={RECORDTYPE.HealthManager_Premises} // TODO: Change RECORDTYPE
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <InspectionDetailsTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <InspectionContactsTab /> },
      {
        title: "History",
        component: <InspectionHistoryTab />,
      },
    ],
  });
  return (
    <CCProductListView
      columnFields={colHMTobaccoPrelodgements}
      dataUrl={HMUrl.GET_VIEW_HM_TOBACCO_PRELODGEMENTS}
      primaryField="ID"
    />
  );
};
