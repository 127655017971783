import { PremisesClosure } from "@app/products/hm/premises/[id]/components/dialogs/close-premises/model";
import { HM_PREMISES_CLOSE_DIALOG_PLACE_ID } from "@app/products/hm/premises/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import {
  requiredValidator,
  validatorDateValueWithMinMax,
} from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { isEmpty } from "lodash";
import React from "react";

interface IHMPremisesCloseDialogProps {
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (data: PremisesClosure) => void;
  closureTypeLovs: IKeyValuePacket[];
  initData: PremisesClosure;
}

const nameOfPremisesCloseDialog = nameOfFactory<PremisesClosure>();
const nameOfIKeyValuePacket = nameOfFactory<IKeyValuePacket>();
export const HMPremisesCloseDialog = ({
  isLoading = false,
  onClose,
  onSubmit,
  closureTypeLovs,
  initData,
}: IHMPremisesCloseDialogProps) => {
  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    onSubmit(event?.values as PremisesClosure);
  };

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      key={JSON.stringify(initData)}
      initialValues={initData}
      render={(formRenderProps: FormRenderProps) => {
        const { onSubmit, valueGetter, onChange } = formRenderProps;
        return (
          <CCDialog
            maxWidth="35%"
            height="auto"
            disabled={isLoading}
            yieldNotificationPortal={HM_PREMISES_CLOSE_DIALOG_PLACE_ID}
            titleHeader="Close Premises"
            onClose={onClose}
            bodyElement={
              <FormElement className="cc-form">
                <section className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <CCLabel title="Closure type" isMandatory />
                      <Field
                        name={nameOfPremisesCloseDialog("ClosureType_KWD")}
                        component={CCComboBox}
                        textField={nameOfIKeyValuePacket("Value")}
                        dataItemKey={nameOfIKeyValuePacket("Key")}
                        data={closureTypeLovs ?? []}
                        validator={requiredValidator}
                        onChange={(value: ComboBoxChangeEvent) => {
                          onChange(
                            nameOfPremisesCloseDialog("ClosureType_KWD"),
                            {
                              value: value?.value?.Key,
                            }
                          );
                        }}
                        value={getDropdownValue(
                          valueGetter(
                            nameOfPremisesCloseDialog("ClosureType_KWD")
                          ),
                          closureTypeLovs ?? [],
                          nameOfIKeyValuePacket("Key")
                        )}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Reason" />
                      <Field
                        name={nameOfPremisesCloseDialog("ClosureReason")}
                        placeholder="Closure reason"
                        component={CCTextArea}
                        rows={3}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Date closed" />
                      <Field
                        name={nameOfPremisesCloseDialog("Date_Closed")}
                        component={CCDatePicker}
                        format={DATE_FORMAT.DATE_CONTROL}
                        max={new Date()}
                        validator={(value: Date) =>
                          validatorDateValueWithMinMax(value, null, new Date())
                        }
                      />
                    </div>
                    {valueGetter(
                      nameOfPremisesCloseDialog("ShowNotification")
                    ) && (
                      <div className="cc-field">
                        <CCLabel
                          title={
                            !isEmpty(initData?.NotifyText) &&
                            initData?.NotifyText
                              ? initData?.NotifyText
                              : "Notification"
                          }
                        />
                        <Field
                          name={nameOfPremisesCloseDialog("SendNotification")}
                          component={CCSwitch}
                          checked={valueGetter(
                            nameOfPremisesCloseDialog("SendNotification")
                          )}
                        />
                      </div>
                    )}
                  </div>
                </section>
              </FormElement>
            }
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  themeColor="primary"
                  iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                  disabled={!formRenderProps?.valid || isLoading}
                  className={"cc-dialog-button"}
                  onClick={onSubmit}
                >
                  Save
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
