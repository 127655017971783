import { eventEmitter } from "@/App";
import { getCloneDocumentTemplate } from "@app/products/property/system-admin/document-template/list/component/buttons/clone-new-document/api";
import { postDocumentTemplate } from "@app/products/property/system-admin/document-template/[id]/api";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const CloneDocumentButton = observer(() => {
  const { gridSelectedIds } = useCCProductListViewStore();
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification } = useCCAppNotificationStore();
  const handleCloneDocument = async () => {
    setIsLoading(true);
    const cloneObject = await getCloneDocumentTemplate(gridSelectedIds[0]);
    if (isSuccessResponse(cloneObject) && cloneObject.data) {
      cloneObject.data.Name = cloneObject.data.Name + " Copy";

      const response = await postDocumentTemplate(cloneObject.data);
      if (isSuccessResponse(response)) {
        eventEmitter.emit(CCGridEventType.RefreshOData);
        pushNotification({
          type: "success",
          title: "Document template cloned successfully",
        });
      } else {
        pushNotification({
          type: "error",
          title: "Document template clone failed",
        });
      }
    } else {
      pushNotification({
        type: "error",
        title: "Document template clone failed",
      });
    }
    setIsLoading(false);
  };
  return (
    <>
      <CCNavButton
        title="Clone"
        isLoading={isLoading}
        onClick={handleCloneDocument}
        disabled={gridSelectedIds.length !== 1}
      />
    </>
  );
});
