export const PropertyJournalSessionListBookmark = {
  getBookmarkListViewDisplayName() {
    return "Animal - Finance";
  },

  getBookmarkListViewDetail() {
    return "Animal - Finance - Fees All";
  },

  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.sessionNumber
      ? `- ${selectedRow.sessionNumber}`
      : "";
    return `Property - Journals - Session List ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    const dynamicDetail = "Session " + (selectedRow.sessionNumber ?? "");
    return `${dynamicDetail}`;
  },
};
