import { HMFieldMapping } from "@app/products/hm/model";
import { DATE_FORMAT } from "@common/constants/common-format";

export const colHMRecycleBinSamples = [
  {
    field: HMFieldMapping.DeletedOn,
    title: "Deleted On",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.DeletedBy,
    title: "Deleted By",
    width: 150,
  },
  {
    field: HMFieldMapping.DateSampled,
    title: "Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.SampleNumber,
    title: "Sample ID",
    width: 150,
  },
  {
    field: HMFieldMapping.SampleType,
    title: "Sample Type",
    width: 150,
  },
  {
    field: HMFieldMapping.Reason,
    title: "Reason",
    width: 150,
  },
  {
    field: HMFieldMapping.Status,
    title: "Status",
    width: 150,
  },
  {
    field: HMFieldMapping.Officer,
    title: "Officer",
    width: 150,
  },
  {
    field: HMFieldMapping.ReferenceNumber,
    title: "Registration Number",
    width: 150,
  },
  {
    field: HMFieldMapping.TradingName,
    title: "Name",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesClassification,
    title: "Classification",
    width: 150,
  },
  {
    field: HMFieldMapping.RiskClassification,
    title: "Risk",
    width: 150,
  },
  {
    field: HMFieldMapping.Address,
    title: "Address",
    width: 150,
  },
  {
    field: HMFieldMapping.Area,
    title: "Area",
    width: 150,
  },
  {
    field: HMFieldMapping.Survey,
    title: "Survey",
    width: 150,
  },
];
