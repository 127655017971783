import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { colProcessorSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/processor/forms/processor-settings/config";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IProcessorSettingsProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<IKeyValuePacket>();
export default observer(({ formRenderProps }: IProcessorSettingsProps) => {
  const { configData } = useSettingGlobalStore();
  const data = useDataSetting(configData, [
    "CorporateSettings_LogDirectoryPath",
    "CorporateSettings_EnableAgentLogging",
    "CorporateSettings_EnableDebugLogging",
  ]);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <InputGlobalSettings
          data={data.CorporateSettings_LogDirectoryPath}
          isEditing={false}
        />
        <SwitchGlobalSettings
          data={data.CorporateSettings_EnableAgentLogging}
          isEditing={false}
          formRenderProps={formRenderProps}
        />
        <SwitchGlobalSettings
          data={data.CorporateSettings_EnableAgentLogging}
          isEditing={false}
          formRenderProps={formRenderProps}
        />
      </div>
      <div className="cc-field">
        <CCGrid
          columnFields={colProcessorSettings}
          primaryField={nameOf("Key")}
          data={configData && configData["ProcessorEnabled"]?.LookupItems}
        />
      </div>
    </section>
  );
});
