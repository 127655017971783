import { getApplicationCategoriesDetailsTabById } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/reference-sidebar/detail/api";
import { ApplicationCategoriesTabDetails } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/reference-sidebar/detail/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { formatDateByKendo } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ApplicationCategoryDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [applicationCategoriesInfo, setApplicationCategoriesInfo] =
    useState<ApplicationCategoriesTabDetails>();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const applicationCategoriesId = params.id || lastSelectedRow.ID;
  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();

  const loadDetailData = async () => {
    setIsLoading(true);
    const response = await getApplicationCategoriesDetailsTabById(
      applicationCategoriesId
    );
    if (!isSuccessResponse(response) || !response.data) {
      setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    } else {
      setApplicationCategoriesInfo(response.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!applicationCategoriesId) return;
    loadDetailData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationCategoriesId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => loadDetailData()}
      />
    );
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Created:"}
          value={
            applicationCategoriesInfo?.CreatedDate
              ? formatDateByKendo(
                  new Date(applicationCategoriesInfo?.CreatedDate)
                )
              : null
          }
        />
        <ProductReferenceRow
          title={"Created by:"}
          value={applicationCategoriesInfo?.CreatedBy}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
