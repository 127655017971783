import { postImportLeaseTransfer } from "@app/products/property/changes-of-ownership/lease-transfers/components/action-bar/import-lease-transfer/components/form-elements/api";
import {
  IMPORT_FILE_FORM_STEP,
  ImportFileFormStep,
} from "@app/products/property/changes-of-ownership/lease-transfers/components/action-bar/import-lease-transfer/components/form-elements/import-file/_index";
import { EListSubmitButton } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";

interface IImportLeaseTransferDialogProps {
  onClose: () => void;
}

export const ImportLeaseTransferDialog = observer(
  ({ onClose }: IImportLeaseTransferDialogProps) => {
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const { pushNotification } = useCCAppNotificationStore();
    const [isShowConfirmDialog, setIsShowConfirmDialog] = useState<boolean>();
    const [file, setFile] = useState<string | Blob>();
    const [isLoadingConfirmYes, setIsLoadingConfirmYes] = useState(false);

    const steps: IStep[] = [
      {
        label: "Import files",
        component: ImportFileFormStep,
        initialValues: {},
        visible: true,
        key: IMPORT_FILE_FORM_STEP,
      },
    ];

    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          setFile(data?.ImportFile?.File[0]?.getRawFile());
          setIsShowConfirmDialog(true);
          break;
        default:
          break;
      }
    };

    const handleConfirmFinishProcess = async () => {
      if (!file) return;
      setIsLoadingConfirmYes(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await postImportLeaseTransfer(formData);
      setIsLoadingConfirmYes(false);
      setIsShowConfirmDialog(false);
      if (isSuccessResponse(response) && response.data?.IsSuccess) {
        onClose();
        pushNotification({
          title:
            response?.data?.SuccessMessage ??
            "Import lease transfer was completed successfully.",
          type: "success",
        });
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title:
              response?.data?.ErrorMessage ??
              "Import lease transfer could not be completed.",
            type: "error",
            autoClose: false,
          });
      }
    };

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          onSubmit={handleSubmit}
          initialSteps={steps}
          renderForm={(renderProps: ICCFormStepRender) => (
            <CCDialog
              maxWidth="60%"
              maxHeight="70%"
              titleHeader="Import Lease Transfer"
              onClose={onClose}
              bodyElement={renderProps.children}
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    themeColor="primary"
                    id={renderProps.nextButton.idButton}
                    disabled={renderProps.nextButton.disabled}
                    className={"cc-dialog-button"}
                    onClick={renderProps.nextButton.onClick}
                  >
                    {renderProps.nextButton.label}
                  </Button>
                </div>
              }
            />
          )}
        />
        {isShowConfirmDialog && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={"Are you sure you want to submit?"}
            onClosePopup={() => setIsShowConfirmDialog(false)}
            onAsyncConfirm={() => {
              return handleConfirmFinishProcess();
            }}
            isLoadingYes={isLoadingConfirmYes}
          />
        )}
      </>
    );
  }
);
