import { mockEditComment } from "@app/products/property/fire-prevention/components/dialogs/edit-comment/mock";
import { IEditComment } from "@app/products/property/fire-prevention/components/dialogs/edit-comment/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useMemo } from "react";

interface IEditCommentDialogProps {
  onClose: () => void;
  onSubmit: (data: IEditComment) => void;
}
const nameOf = nameOfFactory<IEditComment>();

export const EditCommentDialog = ({
  onClose,
  onSubmit,
}: IEditCommentDialogProps) => {
  const { gridSelectedRows } = useCCProductListViewStore();
  const selectedRow = gridSelectedRows[0];
  const editCommentData = useMemo(() => {
    if (selectedRow) {
      return {
        ...mockEditComment,
        DateCleared: selectedRow.ClearedOn ?? null,
        ActualComment: selectedRow.Comment ?? null,
        NotificationDate: selectedRow.NotifiedOn ?? null,
      };
    } else {
      return mockEditComment;
    }
  }, [selectedRow]);
  return (
    <Form
      onSubmitClick={(event: FormSubmitClickEvent) => {
        onSubmit(event.values as IEditComment);
      }}
      initialValues={editCommentData}
      render={(formRenderProps: FormRenderProps) => {
        const { onChange, onSubmit, valueGetter } = formRenderProps;
        //const getFieldValue = (name: string) => valueGetter(nameOf(name));
        const onChangeActualComment = (data: any) => {
          onChange(nameOf("ActualComment"), { value: data });
        };
        const handleSendDataToActualComment = (
          defaultCommentDataOld: string[],
          defaultCommentDataNew: string[]
        ) => {
          if (
            defaultCommentDataOld &&
            defaultCommentDataNew &&
            defaultCommentDataOld.length < defaultCommentDataNew.length
          ) {
            if (valueGetter("ActualComment")) {
              const actualCommentData =
                valueGetter("ActualComment").split("\n");
              const defaultCommentDataAdd = defaultCommentDataNew.filter(
                (item) => !actualCommentData.includes(item)
              );
              onChangeActualComment(
                [...actualCommentData, ...defaultCommentDataAdd].join("\n")
              );
            } else {
              onChangeActualComment(defaultCommentDataNew.join("\n"));
            }
          } else {
            if (defaultCommentDataOld === null) {
              onChangeActualComment(defaultCommentDataNew.join("\n"));
            }
          }
        };
        return (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="30%"
              titleHeader={"Edit Comment"}
              onClose={onClose}
              bodyElement={
                <div className="cc-form">
                  <section className="cc-field-group">
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <label className="cc-label">Notification date</label>
                        <Field
                          name={nameOf("NotificationDate")}
                          format={DATE_FORMAT.DATE_CONTROL}
                          component={CCDatePicker}
                          disabled
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Date cleared</label>
                        <Field
                          name={nameOf("DateCleared")}
                          format={DATE_FORMAT.DATE_CONTROL}
                          component={CCDatePicker}
                          disabled
                        />
                      </div>
                      <div className="cc-field cc-col-span-3">
                        <label className="cc-label">Default comment</label>
                        <Field
                          name={nameOf("DefaultComment")}
                          component={CCMultiSelectDropdown}
                          defaultValue={valueGetter(nameOf("DefaultComment"))}
                          data={valueGetter(nameOf("DefaultCommentData"))}
                          onChange={(e: MultiSelectChangeEvent) => {
                            handleSendDataToActualComment(
                              valueGetter(nameOf("DefaultComment")),
                              e.value
                            );
                            onChange(nameOf("DefaultComment"), {
                              value: e.value,
                            });
                          }}
                          placeholder={"Default comment"}
                        />
                      </div>
                      <div className="cc-field cc-col-span-3">
                        <label className="cc-label">Actual comment</label>
                        <Field
                          name={nameOf("ActualComment")}
                          component={CCTextArea}
                          rows={3}
                          placeholder={"Actual comment"}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>

                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={onSubmit}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
