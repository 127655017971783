import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useLookupStateRoadStore } from "@common/pages/settings/lookups/state-roads/_id/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const LookupStateRoadTabHistory = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { stateRoad } = useLookupStateRoadStore();
  const stateRoadId: number =
    stateRoad?.StateRoad_ID ?? lastSelectedRow?.ID ?? 0;
  return (
    <Journal
      id={stateRoadId}
      triggersReload={stateRoad}
      recordType={RECORDTYPE.CORE_StateRoad}
    />
  );
});
