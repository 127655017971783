import { DTO_AssociatedItem_Meter } from "@app/products/property/components/associations/components/meter/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_Meter>();

export const colAssociationsMeter: IColumnFields[] = [
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("Utility"),
    title: "Utility",
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
