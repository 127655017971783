import { loadSearchStreetLocality } from "@app/products/property/assessments/components/form-steps/new-assessment/components/street-search/api";
import {
  colSearchStreet,
  searchStreetsConfig,
} from "@app/products/property/assessments/components/form-steps/new-assessment/components/street-search/config";
import { VO_Street_Locality } from "@app/products/property/assessments/components/form-steps/new-assessment/components/street-search/model";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import { isArray, isNil } from "lodash";
import React, { ReactElement, useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface IStreetSearchProps extends ComboBoxProps {
  visited?: boolean;
  onError?: (value: any) => void;
  textField?: string;
  textProduce?: (value: any) => string | undefined;
  suburbId?: number;
  isLoadingParent?: boolean;
}
export const StreetSearch = (props: IStreetSearchProps) => {
  const {
    visited,
    validationMessage,
    onChange,
    className,
    value,
    onError,
    textField = value?.Full_Street_Name ?? "",
    textProduce,
    suburbId,
    isLoadingParent,
    ...others
  } = props;

  const refTimeOut = useRef<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<VO_Street_Locality[]>([]);

  const inputDisplayName = useMemo(() => {
    if (isNil(value)) return "";
    if (textProduce) return textProduce(value);
    if (textField && !isArray(value) && value?.hasOwnProperty(textField))
      return value[textField];
    return value?.Full_Street_Name ?? "";
  }, [value, textField, textProduce]);

  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    const searchText = event.filter.value;
    if (searchText.length < searchStreetsConfig.minCharacters) {
      setData([]);
      return;
    }

    if (refTimeOut.current) clearTimeout(refTimeOut.current);
    refTimeOut.current = setTimeout(() => {
      setIsLoading(true);
      loadSearchStreetLocality(searchText, suburbId).then((response) => {
        if (isSuccessResponse(response)) {
          setData(response.data?.value ?? []);
        } else {
          if (onError) onError(response.error);
        }
        setIsLoading(false);
      });
    }, searchStreetsConfig.typeSpeed);
  };
  const handleOnChange = (event: ComboBoxChangeEvent) => {
    if (!onChange) return;
    const newValue = event.value as VO_Street_Locality | null;
    if (!newValue) return onChange({ ...event, value: null });
    return onChange({
      ...event,
      value: newValue,
    });
  };
  useEffectOnce(() => {
    return () => {
      if (refTimeOut.current) clearTimeout(refTimeOut.current);
    };
  });
  return (
    <>
      <div className={`${className ?? ""}`}>
        <ComboBox
          {...others}
          filterable
          suggest
          data={data}
          itemRender={ItemRender}
          loading={isLoading || isLoadingParent}
          onFilterChange={handleSearch}
          value={inputDisplayName}
          onChange={handleOnChange}
          popupSettings={{ className: "cc-street-picker-search" }}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};

const ItemRender = (
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const { dataItem } = itemProps;
  const itemChildren = (
    <div className="cc-search-item">
      {colSearchStreet.map((col: IColumnFields) => (
        <div key={col.field} style={{ width: col.width }}>
          {dataItem[col.field] ?? ""}
        </div>
      ))}
    </div>
  );
  return React.cloneElement(li, li.props, itemChildren);
};
