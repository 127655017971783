import { ResponseMessage } from "@app/products/property/model";
import { DTO_Supplementary_AssessmentLevy } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/assessment-levies/dialogs/assessment-levy/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const deleteSupplementaryAssessmentLevy = async (
  supplementaryAssessmentId: number,
  levyId: number
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().delete(
      `api/property/internal/supplementary/deleteassessessmentlevy/${supplementaryAssessmentId}/${levyId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const saveSupplementaryAssessmentLevy = async (
  supplementaryAssessmentId: number,
  assessmentLevy: DTO_Supplementary_AssessmentLevy
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/supplementary/assessessmentlevy/${supplementaryAssessmentId}`,
      assessmentLevy
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
