export enum FSR_CODE {
  NA = 0,
  VERDICT__NOT_GUILTY = 110,
  VERDICT__GUILTY_NO_CONVICTION_RECORDED = 111,
  VERDICT__GUILTY_CONVICTION_RECORDED = 112,
  VERDICT__ANY_OTHER_SENTENCE_IMPOSED_EG_CBO = 113,
  FSP__DEPARTMENT_FSP_TEMPLATE = 210,
  FSP__APPROVED_PRIVATE_FSP_TEMPLATE = 211,
  FSP__PROPRIETORY_FSP_TEMPLATE = 212,
  FSP__OTHER_NON_STANDARD = 213,
  AUDIT__COUNCIL = 310,
  AUDIT__THIRD_PARTY = 311,
  INSPCOMPLIANCE__COMPLIANT = 410,
  INSPCOMPLIANCE__MAJOR_NON_COMPLIANCE = 411,
  INSPCOMPLIANCE__CRITICAL_NON_COMPLIANCE = 412,
  INSPCOMPLIANCE__PAID_INFRINGEMENT_NOTICE = 413,
  INSPCOMPLIANCE__WITHDRAWN_INFRINGEMENT_NOTICE = 414,
  INSPCOMPLIANCE__UNPAID_ENFORCEMENT_UNDER_INFRINGEMENT_ACT = 415,
  PROSTYPE__PROSECUTION_HANDLING_FOOD_IN_AN_UNSAFE_MANNER = 668,
  PROSTYPE__PROSECUTION_KNOWINGLY_SELLING_UNSAFE_FOOD = 669,
  PROSTYPE__PROSECUTION_KNOWINGLY_FALSELY_DESCRIBING_FOOD = 670,
  PROSTYPE__PROSECUTION_HANDLING_AND_SALE_OF_UNSAFE_FOOD = 671,
  PROSTYPE__PROSECUTION_HANDLING_AND_SALE_OF_UNSUITABLE_FOOD = 672,
  PROSTYPE__PROSECUTION_MISLEADING_CONDUCT_RELATING_TO_SALE_OF_FOOD = 673,
  PROSTYPE__PROSECUTION_SALE_OF_FOOD_NOT_COMPLYING_WITH_PURCHASERS_DEMAND = 674,
  PROSTYPE__PROSECUTION_SALE_OF_UNFIT_EQUIPMENT_OR_PACKAGING_OR_LABELLING_MATERIAL = 675,
  PROSTYPE__PROSECUTION_COMPLIANCE_WITH_FOOD_STANDARD_CODES = 676,
  PROSTYPE__PROSECUTION_OTHERS = 677,
  AUDITCOMPLIANCE__COMPLIANT = 710,
  AUDITCOMPLIANCE__MAJOR_NON_COMPLIANCE = 711,
  AUDITCOMPLIANCE__CRITICAL_NON_COMPLIANCE = 712,
}

export enum FSR_INSPECTIONCOMPLIANCE {
  COMPLIANT = 10,
  MAJOR_NON_COMPLIANCE = 11,
  CRITICAL_NON_COMPLIANCE = 12,
  PAID_INFRINGEMENT_NOTICE = 13,
  WITHDRAWN_INFRINGEMENT_NOTICE = 14,
  UNPAID_ENFORCEMENT_UNDER_INFRINGEMENT_ACT = 15,
}

export enum FSR_VERDICT {
  NA = 0,
  NOT_GUILTY = 10,
  GUILTY_NO_CONVICTION_RECORDED = 11,
  GUILTY_CONVICTION_RECORDED = 12,
  ANY_OTHER_SENTENCE_IMPOSED_EG_CBO = 13,
}

export enum FSR_PROSECUTIONTYPE {
  NA = 0,
  PROSECUTION_HANDLING_FOOD_IN_AN_UNSAFE_MANNER = 68,
  PROSECUTION_KNOWINGLY_SELLING_UNSAFE_FOOD = 69,
  PROSECUTION_KNOWINGLY_FALSELY_DESCRIBING_FOOD = 70,
  PROSECUTION_HANDLING_AND_SALE_OF_UNSAFE_FOOD = 71,
  PROSECUTION_HANDLING_AND_SALE_OF_UNSUITABLE_FOOD = 72,
  PROSECUTION_MISLEADING_CONDUCT_RELATING_TO_SALE_OF_FOOD = 73,
  PROSECUTION_SALE_OF_FOOD_NOT_COMPLYING_WITH_PURCHASERS_DEMAND = 74,
  PROSECUTION_SALE_OF_UNFIT_EQUIPMENT_OR_PACKAGING_OR_LABELLING_MATERIAL = 75,
  PROSECUTION_COMPLIANCE_WITH_FOOD_STANDARD_CODES = 76,
  PROSECUTION_OTHERS = 77,
}

export enum FSR_FSPTEMPLATES {
  NA = 0,
  DEPARTMENT_FSP_TEMPLATE = 10,
  APPROVED_PRIVATE_FSP_TEMPLATE = 11,
  PROPRIETORY_FSP_TEMPLATE = 12,
  OTHER_NON_STANDARD = 13,
}

export enum FSR_FSPAUDITTYPE {
  NA = 0,
  COUNCIL = 10,
  THIRD_PARTY = 11,
}

export enum FSR_AUDITCOMPLIANCE {
  NA = 0,
  COMPLIANT = 10,
  MAJOR_NON_COMPLIANCE = 11,
  CRITICAL_NON_COMPLIANCE = 12,
}

export enum FSR_COMPLAINTTYPE {
  NA = 0,
  FOOD_ADULTERATION_FOOD_COMPLAINT_RECEIVED_FROM_THE_COMMUNITY_CONCERNING_FOOD_WHICH_HAS_BEEN_ALLEGED_TO_HAVE_BEEN_ADULTERATED_WITH_A_FOREIGN_OBJECT = 10,
  MICROBIOLOGICAL_ALLEGED_FOOD_POISONING_OR_FOOD_SPOILAGE_COMPLAINT_RECEIVED_FROM_THE_COMMUNITY = 11,
  CHEMICAL_ADULTERATION_FOOD_COMPLAINT_RECEIVED_FROM_THE_COMMUNITY_CONCERNING_FOOD_WHICH_HAS_BEEN_ADULTERATED_BY_CHEMICALS = 12,
  FOOD_PREMISES_RELATED_FOOD_COMPLAINT_RECEIVED_FROM_THE_COMMUNITY_CONCERNING_A_FOOD_PREMISES = 13,
}

export enum FSR_INSPECTIONTYPE {
  NA = 0,
  ROUTINE_INSPECTION = 1210,
  MANDATORY_INSPECTION = 1211,
  NON_COMPLIANCE_INSPECTION__FOLLOW_UP_TO_COUNCIL_AUDIT = 1212,
  FOOD_COMPLAINT_INSPECTION__FOOD_ADULTERATION_FOREIGN_OBJECT = 1213,
  FOOD_COMPLAINT_INSPECTION_MICROBIOLOGICAL = 1214,
  FOOD_COMPLAINT_INSPECTION_CHEMICAL_ADULTERATION = 1215,
  FOOD_COMPLAINT_INSPECTION_FOOD_PREMISES_RELATED = 1216,
  FOOD_POISONING_INSPECTION = 1217,
  UNDERTAKING_APPLICATION = 1218,
  AN_INSPECTION_TO_DETERMINE_THAT_THE_UNDERTAKING_APPLICATION_GRANTED_BY_THE_REGULATORY_AUTHORITY_OR_BY_THE_SECRETARY_HAS_BEEN_COMPLIED_WITHIN_THE_TIMEFRAME_SPECIFIED_IN_THE_ACCEPTED_UNDERTAKING = 1219,
  FOLLOW_UP_INSPECTION = 1220,
  COUNCIL_FSP_AUDIT = 310,
  THIRD_PARTY_FSP_AUDIT = 311,
}

export enum FSR_NOTICETYPE {
  NA = 0,
  SECTION_19_W_DIRECTIONS_POWERS_CONCERNING_FOOD_SAFETY = 10,
  NOTICE_SECTION_19_2_ORDER = 11,
  SECTION_19_3_CLOSURE_ORDER = 12,
  SECTION_19_A_FOOD_VENDING_MACHINES_AND_OTHER_EQUIPMENT = 13,
  SECTION_19_B_UNCLEAN_FOOD_HANDLERS = 14,
  SEIZURE = 16,
  REVOCATION_OR_SUSPENSION_OF_REGISTRATION = 17,
  UNDERTAKING_PROCEEDING = 18,
  SECTION_19H_AUDITASSESSMENT_FREQUENCY = 19,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_52A = 20,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_S52B = 21,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_61A = 22,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_62 = 23,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_71B = 24,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_72 = 25,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_81 = 26,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_82B = 27,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_82C = 28,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_84 = 29,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_85A = 30,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_85B = 31,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_10B = 32,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_10C = 33,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_113 = 34,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_114 = 35,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_12 = 36,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_163 = 37,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_17 = 38,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_19 = 39,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_201 = 40,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_212 = 41,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_22A = 42,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_23BII = 43,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_241A = 44,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_2_CLAUSE_241B = 45,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE42 = 46,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE5A = 47,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE6A = 48,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE6B = 49,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE_102A = 50,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE_102B = 51,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE_102C = 52,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE_114A = 53,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE_122B = 54,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE_122C = 55,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE_123 = 56,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE_124 = 57,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE_13 = 58,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE_14 = 59,
  OFFENCE_S161_CONTRAVENTION_WITH_FSC_3_2_3_CLAUSE_152 = 60,
  OFFENCE_S17_FOOD_ACT = 61,
  OFFENCE_S19CB6_FOOD_ACT = 62,
  OFFENCE_S19F_FOOD_ACT = 63,
  OFFENCE_S_SECTION_19GB_FOOD_ACT = 64,
  OFFENCE_SECTION_35A1_FOOD_ACT = 65,
  OFFENCE_SECTION_35A2_FOOD_ACT = 66,
  OFFENCE_S_35A3_FOOD_ACT = 67,
  PROSECUTION_HANDLING_FOOD_IN_AN_UNSAFE_MANNER = 68,
  PROSECUTION_KNOWINGLY_SELLING_UNSAFE_FOOD = 69,
  PROSECUTION_KNOWINGLY_FALSELY_DESCRIBING_FOOD = 70,
  PROSECUTION_HANDLING_AND_SALE_OF_UNSAFE_FOOD = 71,
  PROSECUTION_HANDLING_AND_SALE_OF_UNSUITABLE_FOOD = 72,
  PROSECUTION_MISLEADING_CONDUCT_RELATING_TO_SALE_OF_FOOD = 73,
  PROSECUTION_SALE_OF_FOOD_NOT_COMPLYING_WITH_PURCHASERS_DEMAND = 74,
  PROSECUTION_SALE_OF_UNFIT_EQUIPMENT_OR_PACKAGING_OR_LABELLING_MATERIAL = 75,
  PROSECUTION_COMPLIANCE_WITH_FOOD_STANDARD_CODES = 76,
  PROSECUTION_OTHERS = 77,
}
