import { IAttachmentRecords } from "@app/core/communication/dialogs/components/form-elememts/attachment/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IAttachmentRecords>();

export const colAttachmentRecord: IColumnFields[] = [
  {
    field: nameOf("FileFolderNumber"),
    title: "File Number",
  },
  {
    field: nameOf("Title"),
    title: "Title",
  },
  {
    field: nameOf("FileName"),
    title: "File Name",
  },

  {
    field: nameOf("AttachmentSize"),
    title: "Attachment Size",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("RecordedDate"),
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
];
