import { Register } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { getKeywordById } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/api";
import { isSuccessResponse } from "@common/apis/util";

export const convertToEnquiryFormData = async (register?: Register) => {
  let newEnquiry: any = register;
  if (register?.Category_KWD) {
    const resCategory = await getKeywordById(register?.Category_KWD);
    if (isSuccessResponse(resCategory))
      newEnquiry = {
        ...register,
        _options: {
          CategoryName: resCategory.data?.Name,
        },
      };
  }
  return newEnquiry;
};
