import {
  IParcelsStep,
  SearchType,
} from "@app/products/property/registers/list/components/action-bar/form-steps/create-register/components/parcels/model";

export const defaultParcel: IParcelsStep = {
  _option: {
    SearchParcel: {
      Data: [],
      Loading: false,
    },
  },
  SearchType: SearchType.ParcelId,
  Parcels: [],
};
