import { getDynamicQuestionByChecklistType } from "@app/products/town-planning/ppr/[id]/components/input-picker/dynamic-question-picker/api";
import { colDynamicQuestionPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/dynamic-question-picker/config";
import { isSuccessResponse } from "@common/apis/util";
import { DynamicQuestionList_Light } from "@common/models/dynamicQuestion";
import { nameOfFactory } from "@common/utils/common";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React, { useState } from "react";

const nameOf = nameOfFactory<DynamicQuestionList_Light>();

export interface IDynamicQuestionPicker extends ICCInputPickerProps {
  onPickDynamicQuestion: (value: DynamicQuestionList_Light) => void;
  checklistType: number;
  selectableMode?: "single" | "multiple" | "none";
}
export const DynamicQuestionPicker = (props: IDynamicQuestionPicker) => {
  const {
    checklistType,
    onPickDynamicQuestion,
    onChange,
    onError,
    selectableMode,
    textField,
    textProduce,
    valueField,
    valueProduce,
    ...other
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [dynamicQuestionListData, setDynamicQuestionListData] = useState<
    DynamicQuestionList_Light[]
  >([]);

  const handleOnLoadData = () => {
    setIsLoading(true);
    getDynamicQuestionByChecklistType(checklistType).then((response) => {
      setIsLoading(false);
      if (isSuccessResponse(response)) {
        if (response?.data) {
          setDynamicQuestionListData(response.data);
        } else {
          const error = response?.error ?? "Dynamic Question list load failed";
          if (onError) onError(error);
        }
      }
    });
  };

  const handleOnChange = (event: ICCInputPickerChangeEvent) => {
    const value: DynamicQuestionList_Light = event.value;
    if (onPickDynamicQuestion) onPickDynamicQuestion(value);
  };

  return (
    <CCInputPicker
      {...other}
      isLoading={isLoading}
      dialog={{
        titleHeader: "Dynamic Question List",
        maxWidth: "50%",
      }}
      grid={{
        columnFields: colDynamicQuestionPicker,
        primaryField: nameOf("DynamicQuestionList_ID"),
        selectableMode: selectableMode,
        data: dynamicQuestionListData,
        isAutoHiddenPager: false,
      }}
      onLoadData={handleOnLoadData}
      onChange={handleOnChange}
      valueField={valueField}
      valueProduce={valueProduce}
      textField={textField}
      textProduce={textProduce}
    />
  );
};
