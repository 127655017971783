import { BuildingRegistersEnquiryFormElement } from "@app/products/building/registers/enquiries/[id]/components/child-screens/general/components/form-element/_index";
import { useBuildingRegistersEnquiryStore } from "@app/products/building/registers/enquiries/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const BuildingRegistersEnquiryForm = observer(() => {
  const isNew = useIsNew();
  const {
    buildingRegistersEnquiry,
    createBuildingRegistersEnquiry,
    updateBuildingRegistersEnquiry,
  } = useBuildingRegistersEnquiryStore();

  const handleSubmit = (data: any) => {
    if (isNew) {
      createBuildingRegistersEnquiry(data);
    } else {
      updateBuildingRegistersEnquiry(data);
    }
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={buildingRegistersEnquiry}
        render={(formRenderProps: FormRenderProps) => (
          <BuildingRegistersEnquiryFormElement
            formRenderProps={formRenderProps}
          />
        )}
      />
    </div>
  );
});
