import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkListViewDisplayName = () => {
  return "PPR - System Admin";
};

export const getBookmarkListViewDetail = () => {
  return "PPR - System Admin - Application Categories";
};

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `PPR - Application Categories ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.ID + ", " + selectedRow.ApplicationCategory_Name,
  ]);
};
