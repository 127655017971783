import { ICCRoute } from "@common/constants/ICCRoute";

export const locationRegisterRoute: ICCRoute = {
  path: "location-register",
  name: "location register",
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
