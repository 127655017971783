import {
  DTO_Entity_Nominated_Property,
  DTO_Entity_VotingEntitlement_By_Election,
} from "@app/products/property/contacts-central-names/[id]/components/child-screens/voting/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getNominatedProperties = async (
  contactId: number
): Promise<APIResponse<DTO_Entity_Nominated_Property | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Entity_Nominated_Property>(
      `/api/property/internal/entity/${contactId}/nominatedProperties`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getVotingEntitlements = async (
  contactId: number
): Promise<
  APIResponse<DTO_Entity_VotingEntitlement_By_Election | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Entity_VotingEntitlement_By_Election>(
      `/api/property/internal/entity/${contactId}/votingentitlements`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
