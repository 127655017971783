import { DTO_GL_Posting } from "@app/products/property/journals/session-list/[id]/components/child-screens/general-ledger-postings/model";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOfGLPosting = nameOfFactory<DTO_GL_Posting>();
export const colGeneralLedgerPostings: IColumnFields[] = [
  {
    field: nameOfGLPosting("Account"),
    title: "Account",
  },
  {
    field: nameOfGLPosting("DebtorAccount"),
    title: "Debtor Account",
  },
  {
    field: nameOfGLPosting("Account_Type"),
    title: "Account Type",
  },
  {
    field: nameOfGLPosting("SGP_Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfGLPosting("Journal_Number"),
    title: "Journal Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfGLPosting("Jnl_Reference"),
    title: "Journal Reference",
  },
];
