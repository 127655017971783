import { ILookupItem } from "@app/products/property/system-admin/settings/model";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { requiredValidator } from "@common/utils/field-validators";
import {
  arrayDecimalToDecimal,
  decimalToArrayDecimal,
} from "@common/utils/number";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCEditor } from "@components/cc-editor/_index";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
} from "@components/cc-input-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { EditorChangeEvent } from "@progress/kendo-react-editor";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";

export type IRegisterSettingsFormProps = {
  formRenderProps: FormRenderProps;
};

export const RegisterSettingsForm = observer(
  ({ formRenderProps }: IRegisterSettingsFormProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { configData = {} } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();

    //#region  fields
    const fieldPublicRegisterEnable =
      configData[ECorporateSettingsField.TP_PublicRegister_Enable.toString()];
    const fieldApplicationURL =
      configData[
        ECorporateSettingsField.TP_EPlanning_ApplicationURL.toString()
      ];
    const fieldShowDisclaimer =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Flag_ShowDisclaimer.toString()
      ];
    const fieldDisclaimerText =
      configData[
        ECorporateSettingsField.TP_PublicRegister_DisclaimerText.toString()
      ];
    const fieldDisplayApplicant =
      configData[
        ECorporateSettingsField.TP_PublicRegister_DisplayApplicant.toString()
      ];
    const fieldDisplayCostOfWorks =
      configData[
        ECorporateSettingsField.TP_PublicRegister_DisplayCostOfWorks.toString()
      ];
    const fieldDisplayWardLabel =
      configData[
        ECorporateSettingsField.TP_PublicRegister_DisplayWardLabel.toString()
      ];
    const fieldMinimumHistoricalDate =
      configData[
        ECorporateSettingsField.TP_PublicRegister_MinimumHistoricalDate.toString()
      ];
    const fieldDisplayLagInDays =
      configData[
        ECorporateSettingsField.TP_PublicRegister_DisplayLagInDays.toString()
      ];
    //Binary Fields
    const fieldApplicationTypes =
      configData[
        ECorporateSettingsField.TP_AllowedRegisterApplicationTypes.toString()
      ];
    const fieldApplicationStatus =
      configData[
        ECorporateSettingsField.TP_AllowedRegisterApplicationStatus.toString()
      ];
    //#endregion

    //#region boolean value
    const isPublicRegisterEnable = valueGetter(
      ECorporateSettingsField.TP_PublicRegister_Enable.toString()
    );
    const isShowDisclaimer = valueGetter(
      ECorporateSettingsField.TP_PublicRegister_Flag_ShowDisclaimer.toString()
    );
    const isDisplayApplicant = valueGetter(
      ECorporateSettingsField.TP_PublicRegister_DisplayApplicant.toString()
    );
    const isDisplayCostOfWorks = valueGetter(
      ECorporateSettingsField.TP_PublicRegister_DisplayCostOfWorks.toString()
    );
    //#endregion

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title={fieldPublicRegisterEnable?.Title ?? ""} />
            <Field
              name={fieldPublicRegisterEnable?.FieldName}
              component={CCSwitch}
              disabled={!isEditing}
              checked={isPublicRegisterEnable}
            />
          </div>
          {isPublicRegisterEnable && (
            <>
              <div className="cc-field">
                <CCLabel
                  title={fieldApplicationURL?.Title ?? ""}
                  isMandatory={fieldApplicationURL.IsMandatory}
                />
                <Field
                  name={fieldApplicationURL?.FieldName}
                  component={CCInput}
                  disabled={!isEditing}
                  validator={
                    fieldApplicationURL.IsMandatory
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <CCLabel title={fieldShowDisclaimer?.Title ?? ""} />
                <Field
                  name={fieldShowDisclaimer?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={isShowDisclaimer}
                />
              </div>
            </>
          )}
        </div>
        {isPublicRegisterEnable && (
          <>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel
                  title={fieldDisclaimerText?.Title ?? ""}
                  isMandatory={fieldDisclaimerText.IsMandatory}
                />
                <Field
                  name={fieldDisclaimerText?.FieldName}
                  component={CCEditor}
                  disabled={!isEditing}
                  validator={
                    fieldDisclaimerText?.IsMandatory
                      ? requiredValidator
                      : undefined
                  }
                  onChange={(event: EditorChangeEvent) => {
                    onChange(fieldDisclaimerText.FieldName, {
                      value: event.html,
                    });
                  }}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title={fieldDisplayApplicant?.Title ?? ""} />
                <Field
                  name={fieldDisplayApplicant?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={isDisplayApplicant}
                />
              </div>
              <div className="cc-field">
                <CCLabel title={fieldDisplayCostOfWorks?.Title ?? ""} />
                <Field
                  name={fieldDisplayCostOfWorks?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={isDisplayCostOfWorks}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel
                  title={fieldDisplayWardLabel?.Title ?? ""}
                  isMandatory={fieldDisplayWardLabel.IsMandatory}
                />
                <Field
                  name={fieldDisplayWardLabel?.FieldName}
                  component={CCInput}
                  disabled={!isEditing}
                  validator={
                    fieldDisplayWardLabel.IsMandatory
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title={fieldMinimumHistoricalDate?.Title ?? ""}
                  isMandatory={fieldMinimumHistoricalDate.IsMandatory}
                />
                <Field
                  name={fieldMinimumHistoricalDate?.FieldName}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE}
                  disabled={!isEditing}
                  validator={
                    fieldMinimumHistoricalDate.IsMandatory
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title={fieldDisplayLagInDays?.Title ?? ""}
                  isMandatory={fieldDisplayLagInDays.IsMandatory}
                />
                <Field
                  name={fieldDisplayLagInDays?.FieldName}
                  component={CCNumericTextBox}
                  disabled={!isEditing}
                  validator={
                    fieldDisplayLagInDays.IsMandatory
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <BinaryPicker
                field={fieldApplicationTypes}
                formRenderProps={formRenderProps}
                isEditing={isEditing}
              />
              <BinaryPicker
                field={fieldApplicationStatus}
                formRenderProps={formRenderProps}
                isEditing={isEditing}
              />
            </div>
          </>
        )}
      </section>
    );
  }
);
interface IBinaryPickerProps {
  field: SettingField;
  isEditing?: boolean;
  formRenderProps: FormRenderProps;
}
const BinaryPicker = ({
  field,
  isEditing,
  formRenderProps,
}: IBinaryPickerProps) => {
  const { onChange, valueGetter } = formRenderProps;
  const value = valueGetter(field.FieldName);
  const data = field.LookupItems;

  const selectedRows = useMemo(
    () =>
      decimalToArrayDecimal(+value)
        .map((num) => data?.find((item) => item.Id === num.toString()))
        .filter((item) => item),
    [value, data]
  );

  const valueDisplay = useMemo(
    () =>
      selectedRows
        .reduce((p, c) => `${p}, ${c?.Name}`, "")
        .slice(1)
        .trim(),
    [selectedRows]
  );

  const onChangeComboBoxValue = useCallback(
    (event: ICCInputPickerChangeEvent) => {
      onChange(field.FieldName, {
        value: event.value
          ? arrayDecimalToDecimal(
              event.value.map((item: ILookupItem) => +item.Id)
            ).toString()
          : 0,
      });
    },
    [onChange, field.FieldName]
  );

  return (
    <div className="cc-field">
      <CCLabel title={field?.Title ?? ""} isMandatory={field.IsMandatory} />
      <Field
        name={field?.FieldName}
        value={valueDisplay}
        disabled={!isEditing}
        component={CCInputPicker}
        dialog={{
          maxWidth: "35%",
          height: "auto",
          titleHeader: field?.Title,
        }}
        grid={{
          columnFields: [
            {
              field: "Name",
              title: "Name",
            },
          ],
          selectableMode: "multiple",
          itemPerPage: 50,
          selectedRows,
          primaryField: "Id",
          data,
        }}
        showClearButton
        onChange={onChangeComboBoxValue}
      />
    </div>
  );
};
