import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSPayrollMyEventsBookmark = {
  getBookmarkListViewDisplayName() {
    return `${CRMS_PRODUCT_NAME} - Payroll`;
  },
  getBookmarkListViewDetail() {
    return `${CRMS_PRODUCT_NAME} - Payroll - My Events`;
  },
};
