export class StatDays {
  StatDays: number = 0;
  GrossDays: number = 0;
  AppealDays: number = 0;
  TargetDays: number = 0;
  StartDateDescription: string = "";
  EndDateDescription: string = "";
}
export class AddressBook {
  Sys_TimeStamp: string = "";
  _ExtensionType: any[] = [];
  Sys_EnterpriseKey: string = "";
  Sys_TransactionDate: Date = new Date();
  AddressBook_ID: number = 0;
  AddressBook_Name: string = "";
  AddressBookSection_ENUM: string = "";
  ProductType_ENUM: string = "";
  IsSystemRecord: boolean = false;
}
export class PropertyAssessment {
  PropertyAssessment_ID: number = 0;
  Flag_PrimaryAddress: boolean = false;
  Flag_RuralHouseNumber: boolean = false;
  Flag_OutsideProperty: boolean = false;
  CrownAllotment: string = "";
  LotNo: string = "";
  County: string = "";
  CertOfTitle: string = "";
  Valuation_Number: string = "";
  Owners_Name: string = "";
  Owners_Attention_Of: string = "";
  Owners_Care_Of: string = "";
  Owners_Address: string = "";
  Owners_Locality: string = "";
  Owners_State: string = "";
  Owners_Postcode: string = "";
  RatePayers_Name: string = "";
  RatePayers_Attention_Of: string = "";
  RatePayers_Care_Of: string = "";
  RatePayers_Address: string = "";
  RatePayers_Locality: string = "";
  RatePayers_State: string = "";
  RatePayers_Postcode: string = "";
  RatePayers_Phone: string = "";
  RatePayers_Email: string = "";
  RatePayers_Mobile: string = "";
  RatePayers_Fax: string = "";
}

export class PreferredAddress {
  _BubbleUps: any[] = [];
  Sys_TimeStamp: string = "";
  Address_ID: number = 0;
  AddressClassification_ENUM: string = "";
  ContactAddressType_Name: string = "";
  PropertyName: string = "";
  StreetNo: string = "";
  StreetName?: string = "";
  Suburb: string = "";
  State: string = "";
  Postcode: string = "";
  LGA: string = "";
  Region: string = "";
  PoBoxNo: string = "";
  AddressLine1: string = "";
  AddressLine2: string = "";
  AddressLine3: string = "";
  ComplexAddress: boolean = false;
  Flag_StreetName_HasParts: boolean = false;
  StreetNamePart_Name: string = "";
  StreetNamePart_Type: string = "";
  PropertyAssessment: PropertyAssessment = new PropertyAssessment();
  PropertyAlert: string = "";
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate: Date = new Date();
  Sys_CreatedBy: string = "";
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate: Date = new Date();
  Sys_ModifiedDate: Date = new Date();
  Sys_ModifiedBy_ID: number = 0;
  Sys_StatClockStatus_ENUM: string = "";
}

export class PostalAddress {
  _BubbleUps: any[] = [];
  Sys_TimeStamp: string = "";
  Address_ID: number = 0;
  AddressClassification_ENUM: string = "";
  ContactAddressType_Name: string = "";
  PropertyName: string = "";
  StreetNo: string = "";
  StreetName: string = "";
  Suburb: string = "";
  State: string = "";
  Postcode: string = "";
  LGA: string = "";
  Region: string = "";
  PoBoxNo: string = "";
  AddressLine1: string = "";
  AddressLine2: string = "";
  AddressLine3: string = "";
  ComplexAddress: boolean = false;
  Flag_StreetName_HasParts: boolean = false;
  StreetNamePart_Name: string = "";
  StreetNamePart_Type: string = "";
  PropertyAssessment: PropertyAssessment = new PropertyAssessment();
  PropertyAlert: string = "";
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate: Date = new Date();
  Sys_CreatedBy: string = "";
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate: Date = new Date();
  Sys_ModifiedDate: Date = new Date();
  Sys_ModifiedBy_ID: number = 0;
  Sys_StatClockStatus_ENUM: string = "";
}

export class Extension {
  Organisation: string = "";
}

export class Contact {
  Sys_TimeStamp: string = "";
  _PreferredMethod_Formatted: string = "";
  _WorkPhone: string = "";
  _HomePhone: string = "";
  _Fax: string = "";
  _Mobile: string = "";
  _ExtensionType: string[] = [];
  _SaveTriggers: any[] = [];
  Contact_ID: number = 0;
  DisplayName: string = "";
  FirstName: string = "";
  LastName: string = "";
  ContactClassification_ENUM: string = "";
  PreferredMethod_ENUM: string = "";
  Flag_SendSMSAlerts: boolean = false;
  Email: string = "";
  ABN: string = "";
  ACN: string = "";
  ContactRoles_Count: number = 0;
  PreferredAddress?: PreferredAddress = new PreferredAddress();
  Organisation: string = "";
  PostalAddress: PostalAddress = new PostalAddress();
  AddressBook: AddressBook = new AddressBook();
  AddressBookFlag_ENUM: string = "";
  CopiedFrom_Contact_ID: number = 0;
  SynchDate: Date = new Date();
  Extension: Extension = new Extension();
  IsContactCopied: boolean = false;
  ABNValid: boolean = false;
  ACNValid: boolean = false;
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate: Date = new Date();
  Sys_CreatedBy: string = "";
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate: Date = new Date();
  Sys_ModifiedDate: Date = new Date();
  Sys_ModifiedBy_ID: number = 0;
  Sys_StatClockStatus_ENUM: string = "";
}

export class Applicant {
  _BubbleUps: BubbleUp[] = [];
  _ExtensionType: any[] = [];
  _KeywordTags: any[] = [];
  _SaveTriggers: any[] = [];
  Sys_TimeStamp: string = "";
  RID: number = 0;
  ContactRelationshipType_ENUM: string = "";
  Contact: Contact = new Contact();
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate: Date = new Date();
  Sys_CreatedBy: string = "";
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate: Date = new Date();
  Sys_ModifiedDate: Date = new Date();
  Sys_ModifiedBy_ID: number = 0;
  Sys_StatClockStatus_ENUM: string = "";
}

export class PreferredAddress2 {
  _BubbleUps: any[] = [];
  Sys_TimeStamp: string = "";
  Address_ID: number = 0;
  AddressClassification_ENUM: string = "";
  ContactAddressType_Name: string = "";
  PropertyName: string = "";
  StreetNo: string = "";
  StreetName: string = "";
  Suburb: string = "";
  State: string = "";
  Postcode: string = "";
  LGA: string = "";
  Region: string = "";
  PoBoxNo: string = "";
  AddressLine1: string = "";
  AddressLine2: string = "";
  AddressLine3: string = "";
  ComplexAddress: boolean = false;
  Flag_StreetName_HasParts: boolean = false;
  StreetNamePart_Name: string = "";
  StreetNamePart_Type: string = "";
  PropertyAssessment: PropertyAssessment = new PropertyAssessment();
  PropertyAlert: string = "";
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate: Date = new Date();
  Sys_CreatedBy: string = "";
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate: Date = new Date();
  Sys_ModifiedDate: Date = new Date();
  Sys_ModifiedBy_ID: number = 0;
  Sys_StatClockStatus_ENUM: string = "";
}

export class PostalAddress2 {
  _BubbleUps: any[] = [];
  Sys_TimeStamp: string = "";
  Address_ID: number = 0;
  AddressClassification_ENUM: string = "";
  ContactAddressType_Name: string = "";
  PropertyName: string = "";
  StreetNo: string = "";
  StreetName: string = "";
  Suburb: string = "";
  State: string = "";
  Postcode: string = "";
  LGA: string = "";
  Region: string = "";
  PoBoxNo: string = "";
  AddressLine1: string = "";
  AddressLine2: string = "";
  AddressLine3: string = "";
  ComplexAddress: boolean = false;
  Flag_StreetName_HasParts: boolean = false;
  StreetNamePart_Name: string = "";
  StreetNamePart_Type: string = "";
  PropertyAssessment: PropertyAssessment = new PropertyAssessment();
  PropertyAlert: string = "";
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate: Date = new Date();
  Sys_CreatedBy: string = "";
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate: Date = new Date();
  Sys_ModifiedDate: Date = new Date();
  Sys_ModifiedBy_ID: number = 0;
  Sys_StatClockStatus_ENUM: string = "";
}

export class AddressBook2 {
  Sys_TimeStamp: string = "";
  _ExtensionType: any[] = [];
  Sys_EnterpriseKey: string = "";
  Sys_TransactionDate: Date = new Date();
  AddressBook_ID: number = 0;
  AddressBook_Name: string = "";
  AddressBookSection_ENUM: string = "";
  ProductType_ENUM: string = "";
  IsSystemRecord: boolean = false;
}

export class Extension2 {
  Organisation: string = "";
}

export class Contact2 {
  Sys_TimeStamp: string = "";
  _PreferredMethod_Formatted: string = "";
  _WorkPhone: string = "";
  _HomePhone: string = "";
  _Fax: string = "";
  _Mobile: string = "";
  _ExtensionType: string[] = [];
  _SaveTriggers: any[] = [];
  Contact_ID: number = 0;
  DisplayName: string = "";
  FirstName: string = "";
  LastName: string = "";
  ContactClassification_ENUM: string = "";
  PreferredMethod_ENUM: string = "";
  Flag_SendSMSAlerts: boolean = false;
  Email: string = "";
  ABN: string = "";
  ACN: string = "";
  ContactRoles_Count: number = 0;
  PreferredAddress?: PreferredAddress2 = new PreferredAddress2();
  Organisation: string = "";
  PostalAddress: PostalAddress2 = new PostalAddress2();
  AddressBook: AddressBook2 = new AddressBook2();
  AddressBookFlag_ENUM: string = "";
  CopiedFrom_Contact_ID: number = 0;
  SynchDate: Date = new Date();
  Extension: Extension2 = new Extension2();
  IsContactCopied: boolean = false;
  ABNValid: boolean = false;
  ACNValid: boolean = false;
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate: Date = new Date();
  Sys_CreatedBy: string = "";
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate: Date = new Date();
  Sys_ModifiedDate: Date = new Date();
  Sys_ModifiedBy_ID: number = 0;
  Sys_StatClockStatus_ENUM: string = "";
}

export class PreferredContact {
  _BubbleUps: BubbleUp2[] = [];
  _ExtensionType: any[] = [];
  _KeywordTags: any[] = [];
  _SaveTriggers: any[] = [];
  Sys_TimeStamp: string = "";
  RID: number = 0;
  ContactRelationshipType_ENUM: string = "";
  Contact: Contact2 = new Contact2();
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate: Date = new Date();
  Sys_CreatedBy: string = "";
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate: Date = new Date();
  Sys_ModifiedDate: Date = new Date();
  Sys_ModifiedBy_ID: number = 0;
  Sys_StatClockStatus_ENUM: string = "";
}

export class PropertyAssessment5 {
  PropertyAssessment_ID: number = 0;
  Flag_PrimaryAddress: boolean = false;
  UnitSuffix1: string = "";
  HouseNumber1: number = 0;
  Flag_RuralHouseNumber: boolean = false;
  Flag_OutsideProperty: boolean = false;
  Locality_Name: string = "";
  Locality_Postcode: string = "";
  Street_Name: string = "";
  Street_TypeAbbreviation: string = "";
  PID_No: string = "";
  Ward: string = "";
  Parish: string = "";
  CrownAllotment: string = "";
  Section: string = "";
  PlanRef: string = "";
  Volume: string = "";
  Folio: string = "";
  County: string = "";
  Legal_Description: string = "";
  CertOfTitle: string = "";
  Valuation_Number: string = "";
  Owners_Name: string = "";
  Owners_Attention_Of: string = "";
  Owners_Care_Of: string = "";
  Owners_Address: string = "";
  Owners_Locality: string = "";
  Owners_State: string = "";
  Owners_Postcode: string = "";
  Owners_Phone: string = "";
  RatePayers_Name: string = "";
  RatePayers_Attention_Of: string = "";
  RatePayers_Care_Of: string = "";
  RatePayers_Address: string = "";
  RatePayers_Locality: string = "";
  RatePayers_State: string = "";
  RatePayers_Postcode: string = "";
  RatePayers_Phone: string = "";
  RatePayers_Email: string = "";
  RatePayers_Mobile: string = "";
  RatePayers_Fax: string = "";
  Zones: string = "";
  Overlay: string = "";
  RIN: string = "";
  Assessment_LandSize: string = "";
  LotNo: string = "";
}

export class SiteAddress {
  _BubbleUps: BubbleUp3[] = [];
  Sys_TimeStamp: string = "";
  Address_ID: number = 0;
  AddressClassification_ENUM: string = "";
  ContactAddressType_Name: string = "";
  PropertyName: string = "";
  UnitNo: string = "";
  StreetNo: string = "";
  StreetName: string = "";
  Suburb: string = "";
  State: string = "";
  Postcode: string = "";
  LGA: string = "";
  AreaCode: string = "";
  Region: string = "";
  AssessmentNo: string = "";
  PoBoxNo: string = "";
  AddressLine1: string = "";
  AddressLine2: string = "";
  AddressLine3: string = "";
  ComplexAddress: boolean = false;
  Flag_StreetName_HasParts: boolean = false;
  StreetNamePart_Name: string = "";
  StreetNamePart_Type: string = "";
  PropertyAssessment_ID: number = 0;
  PropertyAssessment: PropertyAssessment5 = new PropertyAssessment5();
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate: Date = new Date();
  Sys_CreatedBy: string = "";
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate: Date = new Date();
  Sys_ModifiedDate: Date = new Date();
  Sys_ModifiedBy_ID: number = 0;
  Sys_StatClockStatus_ENUM: string = "";
  Legal_Description: string = "";
}

export class BuildingDetails {
  BuildingDetails_ID: number = 0;
  Application_ID: number = 0;
  InspectionsRequired: any[] = [];
  Building_UseOfBuildings: any[] = [];
  Building_BCA: any[] = [];
  Building_BCAVersion: any[] = [];
  Building_TotalFloorArea: number = 0;
  Building_LandType_Name: string = "";
  Building_LandOwnership_Name: string = "";
  Flag_Section80: boolean = false;
  Flag_HistoricalSite: boolean = false;
  ReportingAuthorities: any[] = [];
  ReportingAuthorities_Display: string = "";
  Building_PolicyCovered: number = 0;
  Building_SpecificWork: any[] = [];
  Building_BuildingWorks_Display: any[] = [];
}

export class AssessmentPlanner {
  AssessmentPlanner_ID: number = 0;
  Application_ID: number = 0;
  AssessmentPlanner_Zones: any[] = [];
  AssessmentPlanner_Overlays: any[] = [];
  AssessmentPlanner_PermitTriggers: any[] = [];
  AssessmentPlanner_IncreaseOfDwellings: string = "";
  AssessmentPlanner_CulturalHeritageManagementPlant_ENUM: string = "";
  AssessmentPlanner_StrategicObjectives: any[] = [];
  AssessmentPlanner_Images: any[] = [];
}

export class PrescribeEvents {
  PrescribeEvents_ID: number = 0;
  Application_ID: number = 0;
  Sent_Counter: number = 0;
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate: Date = new Date();
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate: Date = new Date();
  Sys_ModifiedDate: Date = new Date();
  Sys_ModifiedBy_ID: number = 0;
  Sys_StatClockStatus_ENUM: string = "";
}

export class PPRDetails {
  PPRApplicationDetails_ID: number = 0;
  Application_ID: number = 0;
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate: Date = new Date();
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate: Date = new Date();
  Sys_ModifiedDate: Date = new Date();
  Sys_ModifiedBy_ID: number = 0;
  Sys_StatClockStatus_ENUM: string = "";
}
export class TownPlanningResponse {
  _SaveTriggers: any[] = [];
  Sys_TimeStamp: string = "";
  Application_ID: number = 0;
  RefNo: string = "";
  FileNo: string = "";
  ApplicationNumber: number = 0;
  ApplicationYear: number = 0;
  ApplicationNumberDisplay: string = "";
  IsAmendment: boolean = false;
  PrivateSurveyorRegID: string = "";
  PlanningPrivateSurveyorRegID: string = "";
  Date_Created: Date = new Date();
  LodgedDate: Date = new Date();
  DecisionDate: Date = new Date();
  FinalDecisionDate: Date = new Date();
  Rating_KWD: number = 0;
  Rating_Name: string = "";
  ApplicationCategories: ApplicationCategory[] = [];
  FeeSchedules: any[] = [];
  ApplicationCategories_Namelist: string[] = [];
  FeeSchedules_Namelist: any[] = [];
  ApplicationDescription: any[] = [];
  ApplicationDescription_Namelist: any[] = [];
  StatDays: StatDays = new StatDays();
  CostOfWorks: number = 0;
  Flag_LandDivision: boolean = false;
  Flag_IsTownPlanning: boolean = false;
  Flag_IsBuilding: boolean = false;
  Flag_IsDevApps: boolean = false;
  Flag_PreApplicationMeeting: boolean = false;
  Flag_EnforcementIssue: boolean = false;
  Flag_PrivateSurveyor: boolean = false;
  Flag_PlansToComply: boolean = false;
  Flag_PlanningPrivateSurveyor: boolean = false;
  Flag_EOT: boolean = false;
  Flag_LodgementFeeRequired: boolean = false;
  RecordType: string = "";
  ApplicationTypeFlags: string = "";
  Status_ENUM: string = "";
  Decision_ENUM: string = "";
  DecisionType_ENUM: string = "";
  FinalDecision_ENUM: string = "";
  FinalDecisionType_ENUM: string = "";
  Applicant_RID: number = 0;
  Applicant: Applicant = new Applicant();
  PreferredContact_RID: number = 0;
  PreferredContact: PreferredContact = new PreferredContact();
  SiteAddress_ID: number = 0;
  SiteAddress: SiteAddress = new SiteAddress();
  AdditionalAddresses: any[] = [];
  ReasonForPermit: string = "";
  DateReferral: Date = new Date();
  PrivateCertifier: string = "";
  DebtorNumber: string = "";
  BuildingDetails_ID: number = 0;
  BuildingDetails: BuildingDetails = new BuildingDetails();
  AssessmentPlanner_ID: number = 0;
  AssessmentPlanner: AssessmentPlanner = new AssessmentPlanner();
  Flag_EPlanning_Update: boolean = false;
  Flag_IsPSA: boolean = false;
  Flag_IsOA: boolean = false;
  PrescribeEvents: PrescribeEvents = new PrescribeEvents();
  PPRDetails: PPRDetails = new PPRDetails();
  Flag_IsPPR: boolean = false;
  Flag_IsStateGovtApp: boolean = false;
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate: Date = new Date();
  Sys_CreatedBy: string = "";
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate: Date = new Date();
  Sys_ModifiedDate: Date = new Date();
  Sys_ModifiedBy: string = "";
  Sys_ModifiedBy_ID: number = 0;
  Sys_ReferenceNumber: string = "";
  Sys_StatClockStatus_ENUM: string = "";

  // missing fields
  Subdivision: string = "";
  ConventDisclaimer: string = "";
  Planner: string = "";
  OsFees: string = "";
  FurtherInfo = "";
  Advertising = "";
  Meeting: string = "";
  PreApplicationMeeting_CouncilOfficer: string = "";
  PreApplicationMeeting_Date: string = "";
  EnforcementIssue_CouncilOfficer: string = "";
  EnforcementIssue_Date: string = "";
}

export class ApplicationCategory {
  Sys_TimeStamp: string = "";
  ApplicationCategory_ID: number = 0;
  ApplicationCategory_Name: string = "";
  ApplicationCategory_Description: string = "";
  SortIndex: number = 0;
  TownPlanning: boolean = false;
  Building: boolean = false;
  LandDivision: boolean = false;
  Code: string = "";
  isPSA: boolean = false;
  WorkFlowAutoActionTypes: any[] = [];
  ApplicationCategoryFees: any[] = [];
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate: Date = new Date();
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate: Date = new Date();
  Sys_ModifiedDate: Date = new Date();
  Sys_ModifiedBy_ID: number = 0;
  Sys_StatClockStatus_ENUM: string = "";
}

export class SourceIdentifier {
  _RecordSource_ID: number = 0;
  _RecordSourceType_ENUM: string = "";
  _RecordSource_ColumnName: string = "";
  _RecordSource_StringID: string = "";
}

export class BubbleUp {
  PKID: number = 0;
  SourceIdentifier: SourceIdentifier = new SourceIdentifier();
  BubbleUpType_ENUM: string = "";
  Sys_CreatedDate: Date = new Date();
}

export class SourceIdentifier2 {
  _RecordSource_ID: number = 0;
  _RecordSourceType_ENUM: string = "";
  _RecordSource_ColumnName: string = "";
  _RecordSource_StringID: string = "";
}

export class BubbleUp2 {
  PKID: number = 0;
  SourceIdentifier: SourceIdentifier2 = new SourceIdentifier2();
  BubbleUpType_ENUM: string = "";
  Sys_CreatedDate: Date = new Date();
}

export class SourceIdentifier3 {
  _RecordSource_ID: number = 0;
  _RecordSourceType_ENUM: string = "";
  _RecordSource_ColumnName: string = "";
  _RecordSource_StringID: string = "";
}

export class BubbleUp3 {
  PKID: number = 0;
  SourceIdentifier: SourceIdentifier3 = new SourceIdentifier3();
  BubbleUpType_ENUM: string = "";
  Sys_CreatedDate: Date = new Date();
}

export class TownPlanningRegisterRequest extends TownPlanningResponse {}

export class TownPlanningRegisterForm extends TownPlanningResponse {
  ApplicantName: string = "";
  ApplicantAddress: string = "";
  ApplicantContact: string = "";

  PreferredName: string = "";
  PreferredAddress: string = "";
  PreferredContactNumber: string = "";

  SiteAddressOwner: string = "";
  Zone: string = "";
  Overlay: string = "";

  OwnerName: string = "";
  Details: string = "";
  Subdivision: string = "";

  Ward: string = "";
  Rin: string = "";
  LandSize: string = "";

  LotNo: string = "";

  FinalOutcomeProposed: string = "";
}

export class TownPlanning {
  ID: string | number = "";
  Application_ID: any;

  Applicant: string = "";
  ApplicantPostalAddress: string = "";
  ApplicantContact: string = "";
  PreferredContactName: string = "";
  PreferredPostalAddress: string = "";
  PreferredContact: string = "";

  SiteAddress: string = "";
  Zone: string = "";
  Overlay: string = "";
  Owner: string = "";
  Details: string = "";
  Subdivision: string = "";
  LotNo: string = "";

  AppCategory: string = "";
  FeesSchedule: string = "";
  ReasonForPermit: string = "";
  IsPreAppMeeting: boolean = false;
  IsEnforcementIssue: boolean = false;
  ConventDisclaimer: string = "";
  CostOfWork: number = 0;
  FileNumber: string = "";
  DebtorNumber: string = "";
  OsFees: string = "";

  ProposedFinalOutcome: any;
}

export interface ITownPlanningForm {
  Application_ID: string;
  Status_ENUM: string;
  Sys_StatClockStatus_ENUM: string;
  Sys_CreatedDate: string;
  Date_Created: string;
  LodgedDate: string;
  StatDays: {
    TargetDate: string;
  };
  Applicant: {
    Contact: {
      DisplayName: string;
      _Mobile: string;
      PreferredAddress: {
        StreetName: string;
      };
    };
  };
  PreferredContact: {
    Contact: {
      DisplayName: string;
      _Mobile: string;
      PreferredAddress: {
        StreetName: string;
      };
    };
  };
  SiteAddress: {
    PropertyAssessment: {
      Owners_Address: string;
      Zones: string;
      Overlay: string;
      Owners_Name: string;
      Legal_Description: string;
      Ward: string;
      RIN: string;
      Assessment_LandSize: string;
      LotNo: string;
    };
    ReasonForPermit: string;
    CostOfWorks: number;
    Planner: string;
    FileNo: string;
    DebtorNumber: string;
    OsFees: number;
    Decision_ENUM: string;
    DecisionDate: string;
    DecisionType_ENUM: string;
  };
}

export class TownPlanningRecordsState {
  addresses: number = 0;
  actions: number = 0;
  comments: number = 0;
  contacts: number = 0;
  documents: number = 0;
  fees: number = 0;
  inspections: number = 0;
  registers: number = 0;
  related: number = 0;
  events: number = 0;
  conditionsRestrictions: number = 0;
  clauses: number = 0;
  samples: number = 0;
}
