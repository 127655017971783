import { WorkflowTypes } from "@app/products/property/model";

export enum workflowListType {
  Parked = 0, //WorkflowStatus = Parked or Incomplete
  Submitted = 1, //WorkflowStatus = Completed and WorkflowApprovalStatus=ToBeApproved
  SentForApproval = 2, //WorkflowStatus = Completed and WorkflowApprovalStatus=ToBeApproved
  Cancelled = 3, //WorkflowStatus = Cancelled
  Rejected = 4, //WorkflowApprovalStatus= Rejected
  Approved = 5, //ApprovalStatus = Approved
  MyApprovals = 6, //WorkflowStatus = Completed
}

export interface VO_Workflow_Draft {
  Rejected_On: Date | null;
  Approved_On: Date | null;
  Approved_By: string;
  Cancelled_Reason: string;
  Cancelled_By: string;
  Cancelled_On: Date | null;
  Current_Step_Approval_Group: string;
  Assessment_Group: string;
  Workflow_Approval_Status: number | null;
  Workflow_Approval_Status_Name: string;
  Parent_Id: number | null;
  Modified_On: Date;
  Created_On: Date;
  Created_By: string;
  WD_Officer_User_Id: number;
  Component_Name: string;
  WD_Component_Number: number;
  WD_Component_Id: number | null;
  Workflow_Status_Name: string;
  Workflow_Status: number;
  Workflow_Type_Enum: WorkflowTypes | null;
  Workflow: string;
  Workflow_Draft_Id: number;
  Rejected_By: string;
  Rejected_Reason: string;
  WD_Completion_DateTime?: Date | null;
}

// TODO: Recheck this later
export enum EWorkflow_Status {
  Parked = 1,
  Incomplete = 2,
  Completed = 3,
}

// TODO: Recheck this later
export enum EWorkflow_Status_Name {
  Parked = "Parked",
  Incomplete = "Incomplete",
  Completed = "Completed",
}

// TODO: Recheck this later
export enum EWorkflow_Approval_Status_Name {
  To_Be_Approved = "To Be Approved",
}
