import { DTO_ChargeRun_TabDetails } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/reference-sidebar/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getChargeRunDetailTabId = async (
  id: number
): Promise<APIResponse<DTO_ChargeRun_TabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_ChargeRun_TabDetails>(
      `api/property/internal/chargerun/${id}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
