import { DTO_Workflow_CreateAssessment } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import {
  ICCViewConfiguration,
  IOptionalViewConfigParams,
  RequestObjFromSpatial,
  ViewConfiguration,
  WorkflowTypes,
} from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import { isNil } from "lodash";

export const getNewWorkflowCreateAssessment = async (
  requestObjSpatial: RequestObjFromSpatial | object = {}
): Promise<APIResponse<DTO_Workflow_CreateAssessment>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/createassessment/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowCreateAssessment = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_CreateAssessment>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/createassessment/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const getNewWorkflow = async (
  workflowType: WorkflowTypes,
  requestObjSpatial: RequestObjFromSpatial | object = {}
): Promise<APIResponse<DTO_Workflow_CreateAssessment>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/${workflowType}/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getViewConfigurations = async (
  viewConfigurationId: ViewConfiguration
): Promise<APIResponse<ICCViewConfiguration | undefined>> => {
  try {
    const response = await CoreAPIService.getClient().get<ICCViewConfiguration>(
      `/api/property/internal/common/viewconfiguration/${viewConfigurationId}`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getViewConfigurationsWithPayload = async (
  viewConfigurationId: ViewConfiguration,
  payload: any
): Promise<APIResponse<ICCViewConfiguration | undefined>> => {
  try {
    const response =
      await CoreAPIService.getClient().post<ICCViewConfiguration>(
        `/api/property/internal/common/viewconfiguration/${viewConfigurationId}`,
        payload
      );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const loadViewConfiguresColumns = async (
  viewConfigureEnum: ViewConfiguration,
  colConfig: IColumnFields[],
  optionalParams?: IOptionalViewConfigParams
): Promise<IColumnFields[] | APIResponseError> => {
  const payload = optionalParams?.payload;
  const dynamicFormat = optionalParams?.dynamicFormat;
  const response = isNil(payload)
    ? await getViewConfigurations(viewConfigureEnum)
    : await getViewConfigurationsWithPayload(viewConfigureEnum, payload);
  if (
    isSuccessResponse(response) &&
    response?.data?.ColumnDefinitions?.Columns
  ) {
    const viewConfiguration = response.data.ColumnDefinitions.Columns;
    return processDynamicColumns(colConfig, viewConfiguration, dynamicFormat);
  } else {
    return {
      status: response?.status,
      error: response?.error,
    } as APIResponseError;
  }
};
