import { applicationCategoriesRoute } from "@app/products/town-planning/ppr/system-admin/application-categories/route";
import { keywordsRoute } from "@app/products/town-planning/ppr/system-admin/keywords/route";
import { regionsRoute } from "@app/products/town-planning/ppr/system-admin/regions/route";
import { settingsPPRRoute } from "@app/products/town-planning/ppr/system-admin/settings/route";
import { ICCRoute } from "@common/constants/ICCRoute";
export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  children: [
    applicationCategoriesRoute,
    regionsRoute,
    keywordsRoute,
    settingsPPRRoute,
  ],
};
