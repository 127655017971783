import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCPercentInput } from "@components/cc-percent-input/_index";
import Loading from "@components/loading/Loading";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";

export const OtherInformationFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  nameOf,
  formRenderProps,
  options = { isReadOnly: false, workflowDraftId: 0 },
  isLoadingStep,
}: IFormStepElement) => {
  const { onChange } = formRenderProps;

  if (isLoadingStep) {
    return <Loading isLoading={isLoadingStep} />;
  }

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">TRS</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">Client Id</label>
                <Field
                  name={nameOf("ClientId")}
                  placeholder={"Client Id"}
                  spinners={false}
                  min={0}
                  component={CCNumericTextBox}
                  format={NUMBER_FORMAT.NUMBER2}
                  onChange={(event: any) => {
                    onChange(nameOf("ClientId"), {
                      value: event.value,
                    });
                  }}
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">TRS assessment Id</label>
                <Field
                  name={nameOf("AssessmentId")}
                  placeholder={"TRS assessment Id"}
                  min={0}
                  component={CCNumericTextBox}
                  spinners={false}
                  format={NUMBER_FORMAT.NUMBER2}
                  onChange={(event: any) => {
                    onChange(nameOf("AssessmentId"), {
                      value: event.value,
                    });
                  }}
                  disabled={options?.isReadOnly}
                />
              </div>
            </div>

            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">Lodgement number</label>
                <Field
                  name={nameOf("LodgementNumber")}
                  placeholder={"Lodgement number"}
                  spinners={false}
                  min={0}
                  component={CCNumericTextBox}
                  format={NUMBER_FORMAT.NUMBER2}
                  onChange={(event: any) => {
                    onChange(nameOf("LodgementNumber"), {
                      value: event.value,
                    });
                  }}
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Revenue type</label>
                <Field
                  name={nameOf("RevenueType")}
                  placeholder={"Revenue type"}
                  component={CCInput}
                  disabled={options?.isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="cc-field">
          <label className="cc-label">Interest</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Interest rate</label>
              <Field
                name={nameOf("InterestRate")}
                placeholder={"Interest rate"}
                min={0}
                max={100}
                step={0.1}
                spinners={false}
                component={CCPercentInput}
                disabled={options?.isReadOnly}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Minimun amount for interest</label>
              <Field
                name={nameOf("MinimumAmountForInterest")}
                placeholder={"Minimun amount"}
                format={CURRENCY_FORMAT.CURRENCY1}
                component={CCInput}
                disabled={options?.isReadOnly}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Premium rate</label>
              <Field
                name={nameOf("PremiumRate")}
                placeholder={"Premium rate"}
                min={0}
                max={100}
                step={0.1}
                spinners={false}
                component={CCPercentInput}
                disabled={options?.isReadOnly}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
