import { getCRMSKnowledgeBaseDetailsTabById } from "@app/products/crms/knowledge-base/[id]/components/forms/components/reference-sidebar/details/api";
import { ICRMSKnowledgeBaseDetailsTab } from "@app/products/crms/knowledge-base/[id]/components/forms/components/reference-sidebar/details/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue, splitCamelCase } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const CRMSKnowledgeBaseDetailTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [crmsKnowledgeBaseInfo, setCrmsKnowledgeBaseInfo] = useState<
    ICRMSKnowledgeBaseDetailsTab | undefined
  >();
  const params: { id: string } = useParams();
  const crmsId: string =
    lastSelectedRow && lastSelectedRow.ID ? lastSelectedRow.ID : params.id;

  useEffect(() => {
    if (crmsId) {
      setIsLoading(true);
      getCRMSKnowledgeBaseDetailsTabById(parseInt(crmsId)).then(
        (data: ICRMSKnowledgeBaseDetailsTab) => {
          setCrmsKnowledgeBaseInfo(data);
          setIsLoading(false);
        }
      );
    }
  }, [lastSelectedRow, crmsId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!crmsKnowledgeBaseInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Version:"}
        value={crmsKnowledgeBaseInfo?.Version}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Status:"}
          value={splitCamelCase(crmsKnowledgeBaseInfo?.Status?.toString())}
          success
        />
        <ProductReferenceRow
          title={"Created:"}
          value={formatDisplayValue(
            crmsKnowledgeBaseInfo?.Created,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Next Review:"}
          value={formatDisplayValue(
            crmsKnowledgeBaseInfo?.NextReview,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Expiry:"}
          value={formatDisplayValue(
            crmsKnowledgeBaseInfo?.Expiry,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Hit Count:"}
          value={crmsKnowledgeBaseInfo?.HitCount}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
