import { useFirePreventionStore } from "@app/products/property/fire-prevention/hazld-case/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistedFirePrevention } from "./components/forms/existed/_index";
import { NewFirePrevention } from "./components/forms/new/_index";

export const ManageFirePreventionExisted = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const firePreventionId = params.id;
  const { loadFirePrevention, resetStore, isLoading } =
    useFirePreventionStore();

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });
  useEffect(() => {
    if (isNew) return;
    loadFirePrevention(firePreventionId);
  }, [firePreventionId, isNew, loadFirePrevention]);

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  if (isNew) {
    return <NewFirePrevention />;
  }
  return <ExistedFirePrevention />;
};

export default observer(ManageFirePreventionExisted);
