import { colGroupsOrgUser } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/special-interest/config";
import {
  IConfirmDeleteDialogName,
  ServiceStandardBatchUpdateHandlerRequest,
  ServiceStandardBatchUpdateMapObj,
  Svc_FormAction_ServiceStandardBatchUpdate,
  eConfirmDeleteDialogName,
} from "@app/products/crms/service-standards/batch-update/model";
import { useSvcStandardBatchUpdateStore } from "@app/products/crms/service-standards/batch-update/store";
import { getNameOfSvcStandardBatchUpdate } from "@app/products/crms/service-standards/batch-update/util";
import { AccessControl } from "@common/models/accessControl";
import { PickUserGroupOrgButton } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/buttons/pick-user-group-org/_index";
import { IDataUserGroupOrgForm } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface IWorkflowSecurityProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}

const nameOfSvcStandardBatchUpdateMapObj =
  nameOfFactory<ServiceStandardBatchUpdateMapObj>();
const nameOfAccessControl = nameOfFactory<AccessControl>();

const getIds = (
  listDeleteSelected: AccessControl[],
  listData: AccessControl[]
) => {
  const ids = listDeleteSelected.map((item: AccessControl) => item.EntityID);
  const newList = listData.filter(
    (item: AccessControl) => !ids.includes(item.EntityID)
  );
  return newList;
};

export const WorkflowSecurity = observer(
  ({ formRenderProps, isDisabled }: IWorkflowSecurityProps) => {
    const { valueGetter, onChange } = formRenderProps;

    const {
      svcStandardBatchUpdateChangeHandler,
      setIsExpandedWorkflowSecurity,
    } = useSvcStandardBatchUpdateStore();

    const SvcStandardBatchUpdateMapObj = valueGetter(
      nameOfSvcStandardBatchUpdateMapObj("SvcStandardBatchUpdate")
    );
    const stdReaders = valueGetter(
      `${getNameOfSvcStandardBatchUpdate("Readers")}`
    );
    const stdWriters = valueGetter(
      `${getNameOfSvcStandardBatchUpdate("Writers")}`
    );
    const stdEventReaders = valueGetter(
      `${getNameOfSvcStandardBatchUpdate("EventReaders")}`
    );
    const stdEventWriters = valueGetter(
      `${getNameOfSvcStandardBatchUpdate("EventWriters")}`
    );

    const [stdReaderslistSelected, setStdReaderslistSelected] = useState<
      AccessControl[]
    >([]);
    const [stdWriterslistSelected, setStdWriterslistSelected] = useState<
      AccessControl[]
    >([]);
    const [stdEventReaderslistSelected, setStdEventReaderslistSelected] =
      useState<AccessControl[]>([]);
    const [stdEventWriterslistSelected, setStdEventWriterslistSelected] =
      useState<AccessControl[]>([]);

    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<
      IConfirmDeleteDialogName | undefined
    >();

    const paramsDelete = (
      svcFormAction: Svc_FormAction_ServiceStandardBatchUpdate
    ) => {
      return {
        ServiceStandardBatchUpdateFormAction: svcFormAction,
        ServiceStandardBatchUpdate: SvcStandardBatchUpdateMapObj,
        EventArgs: {
          Siteusers: [],
          Hierarchies: [],
        },
        IsFirstTimeLoad: false,
      };
    };

    const handleDeleteStdReaders = async () => {
      const newList = getIds(stdReaderslistSelected, stdReaders);
      onChange(`${getNameOfSvcStandardBatchUpdate("Readers")}`, {
        value: newList,
      });
      const params: ServiceStandardBatchUpdateHandlerRequest = paramsDelete(
        Svc_FormAction_ServiceStandardBatchUpdate.PickSecurityReader
      );
      const isSuccess = await svcStandardBatchUpdateChangeHandler(
        params,
        "Delete service standard reader was failed."
      );
      if (isSuccess) {
        setStdReaderslistSelected([]);
      }
      setIsExpandedWorkflowSecurity(true);
    };

    const handleDeleteStdWriters = async () => {
      const newList = getIds(stdWriterslistSelected, stdWriters);
      onChange(getNameOfSvcStandardBatchUpdate("Writers"), {
        value: newList,
      });
      const params: ServiceStandardBatchUpdateHandlerRequest = paramsDelete(
        Svc_FormAction_ServiceStandardBatchUpdate.PickSecurityWriter
      );
      const isSuccess = await svcStandardBatchUpdateChangeHandler(
        params,
        "Delete service standard writer was failed."
      );
      if (isSuccess) {
        setStdWriterslistSelected([]);
      }
      setIsExpandedWorkflowSecurity(true);
    };

    const handleDeleteStdEventReaders = async () => {
      const newList = getIds(stdEventReaderslistSelected, stdEventReaders);
      onChange(`${getNameOfSvcStandardBatchUpdate("EventReaders")}`, {
        value: newList,
      });
      const params: ServiceStandardBatchUpdateHandlerRequest = paramsDelete(
        Svc_FormAction_ServiceStandardBatchUpdate.PickSecurityEventReader
      );
      const isSuccess = await svcStandardBatchUpdateChangeHandler(
        params,
        "Delete event reader was failed."
      );
      if (isSuccess) {
        setStdEventReaderslistSelected([]);
      }
      setIsExpandedWorkflowSecurity(true);
    };

    const handleDeleteStdEventWriters = async () => {
      const newList = getIds(stdEventWriterslistSelected, stdEventWriters);

      onChange(`${getNameOfSvcStandardBatchUpdate("EventWriters")}`, {
        value: newList,
      });
      const params: ServiceStandardBatchUpdateHandlerRequest = paramsDelete(
        Svc_FormAction_ServiceStandardBatchUpdate.PickSecurityEventWriter
      );
      const isSuccess = await svcStandardBatchUpdateChangeHandler(
        params,
        "Delete event writer was failed."
      );
      if (isSuccess) {
        setStdEventWriterslistSelected([]);
      }
      setIsExpandedWorkflowSecurity(true);
    };

    const handleConfirmDelete = () => {
      switch (showConfirmDeleteDialog?.dialogType) {
        case eConfirmDeleteDialogName.StdReaders:
          handleDeleteStdReaders();
          break;
        case eConfirmDeleteDialogName.StdWriters:
          handleDeleteStdWriters();
          break;
        case eConfirmDeleteDialogName.EventReaders:
          handleDeleteStdEventReaders();
          break;
        case eConfirmDeleteDialogName.EventWriters:
          handleDeleteStdEventWriters();
          break;
        default:
          break;
      }
    };

    const handleAddNew = (
      value: IDataUserGroupOrgForm,
      svcFormAction: Svc_FormAction_ServiceStandardBatchUpdate
    ) => {
      const groups = value?.Groups ?? [];
      const orgStructure = value?.OrgStructure ?? [];
      const users = value?.Users ?? [];
      const data = [...groups, ...orgStructure];
      let hierarchiesIds = data.map((item) => item.ID);
      const userIds = users.map((item) => item.ID);

      if (hierarchiesIds.length === 0 && userIds.length === 0) return;
      const params: ServiceStandardBatchUpdateHandlerRequest = {
        ServiceStandardBatchUpdateFormAction: svcFormAction,
        ServiceStandardBatchUpdate: SvcStandardBatchUpdateMapObj,
        EventArgs: {
          SiteUsers: userIds ?? [],
          Hierarchies: hierarchiesIds ?? [],
        },
        IsFirstTimeLoad: false,
      };
      svcStandardBatchUpdateChangeHandler(
        params,
        "Select users, groups and org structures was failed"
      );
      setIsExpandedWorkflowSecurity(true);
    };

    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update service standard readers" />
              <Field
                name={getNameOfSvcStandardBatchUpdate("Update_Readers")}
                checked={valueGetter(
                  getNameOfSvcStandardBatchUpdate("Update_Readers")
                )}
                component={CCSwitch}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="cc-field-group cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_Readers")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Service standard readers" />
                  <CCGrid
                    readOnly={isDisabled}
                    columnFields={colGroupsOrgUser}
                    primaryField={nameOfAccessControl("EntityID")}
                    data={valueGetter(
                      `${getNameOfSvcStandardBatchUpdate("Readers")}`
                    )}
                    selectableMode={"multiple"}
                    selectedRows={stdReaderslistSelected}
                    toolbar={
                      <div className="cc-grid-tools-bar">
                        <PickUserGroupOrgButton
                          onSubmit={(values: IDataUserGroupOrgForm) =>
                            handleAddNew(
                              values,
                              Svc_FormAction_ServiceStandardBatchUpdate.PickSecurityReader
                            )
                          }
                          maxHeight="85%"
                        />
                        <Button
                          type="button"
                          iconClass="fas fa-minus"
                          title="Remove"
                          onClick={() => {
                            setShowConfirmDeleteDialog({
                              dialogType: eConfirmDeleteDialogName.StdReaders,
                              isSelectedMuiltiple:
                                stdReaderslistSelected.length > 1,
                            });
                          }}
                          disabled={stdReaderslistSelected.length === 0}
                        />
                      </div>
                    }
                    onSelectionChange={(dataItem: AccessControl[]) => {
                      setStdReaderslistSelected(dataItem ?? []);
                    }}
                    itemPerPage={50}
                    isAutoHiddenPager={false}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </section>

        <hr className="cc-divider" />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update service standard writers" />
              <Field
                name={getNameOfSvcStandardBatchUpdate("Update_Writers")}
                checked={valueGetter(
                  getNameOfSvcStandardBatchUpdate("Update_Writers")
                )}
                component={CCSwitch}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="cc-field-group cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_Writers")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Service standard writers" />
                  <CCGrid
                    readOnly={isDisabled}
                    columnFields={colGroupsOrgUser}
                    primaryField={nameOfAccessControl("EntityID")}
                    data={valueGetter(
                      `${getNameOfSvcStandardBatchUpdate("Writers")}`
                    )}
                    selectableMode={"multiple"}
                    selectedRows={stdWriterslistSelected}
                    toolbar={
                      <div className="cc-grid-tools-bar">
                        <PickUserGroupOrgButton
                          onSubmit={(values: IDataUserGroupOrgForm) =>
                            handleAddNew(
                              values,
                              Svc_FormAction_ServiceStandardBatchUpdate.PickSecurityWriter
                            )
                          }
                          maxHeight="85%"
                        />
                        <Button
                          type="button"
                          iconClass="fas fa-minus"
                          title="Remove"
                          onClick={() => {
                            setShowConfirmDeleteDialog({
                              dialogType: eConfirmDeleteDialogName.StdWriters,
                              isSelectedMuiltiple:
                                stdWriterslistSelected.length > 1,
                            });
                          }}
                          disabled={stdWriterslistSelected.length === 0}
                        />
                      </div>
                    }
                    onSelectionChange={(dataItem: AccessControl[]) => {
                      setStdWriterslistSelected(dataItem ?? []);
                    }}
                    itemPerPage={50}
                    isAutoHiddenPager={false}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </section>

        <hr className="cc-divider" />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update event readers" />
              <Field
                name={getNameOfSvcStandardBatchUpdate("Update_EventReaders")}
                checked={valueGetter(
                  getNameOfSvcStandardBatchUpdate("Update_EventReaders")
                )}
                component={CCSwitch}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="cc-field-group cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_EventReaders")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Event readers" />
                  <CCGrid
                    readOnly={isDisabled}
                    columnFields={colGroupsOrgUser}
                    primaryField={nameOfAccessControl("EntityID")}
                    data={valueGetter(
                      `${getNameOfSvcStandardBatchUpdate("EventReaders")}`
                    )}
                    selectableMode={"multiple"}
                    selectedRows={stdEventReaderslistSelected}
                    toolbar={
                      <div className="cc-grid-tools-bar">
                        <PickUserGroupOrgButton
                          onSubmit={(values: IDataUserGroupOrgForm) =>
                            handleAddNew(
                              values,
                              Svc_FormAction_ServiceStandardBatchUpdate.PickSecurityEventReader
                            )
                          }
                          maxHeight="85%"
                        />
                        <Button
                          type="button"
                          iconClass="fas fa-minus"
                          title="Remove"
                          onClick={() => {
                            setShowConfirmDeleteDialog({
                              dialogType: eConfirmDeleteDialogName.EventReaders,
                              isSelectedMuiltiple:
                                stdEventReaderslistSelected.length > 1,
                            });
                          }}
                          disabled={stdEventReaderslistSelected.length === 0}
                        />
                      </div>
                    }
                    onSelectionChange={(dataItem: AccessControl[]) => {
                      setStdEventReaderslistSelected(dataItem ?? []);
                    }}
                    itemPerPage={50}
                    isAutoHiddenPager={false}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </section>

        <hr className="cc-divider" />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update event writers" />
              <Field
                name={getNameOfSvcStandardBatchUpdate("Update_EventWriters")}
                checked={valueGetter(
                  getNameOfSvcStandardBatchUpdate("Update_EventWriters")
                )}
                component={CCSwitch}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="cc-field-group cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_EventWriters")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Event writers" />
                  <CCGrid
                    readOnly={isDisabled}
                    columnFields={colGroupsOrgUser}
                    primaryField={nameOfAccessControl("EntityID")}
                    data={valueGetter(
                      `${getNameOfSvcStandardBatchUpdate("EventWriters")}`
                    )}
                    selectableMode={"multiple"}
                    selectedRows={stdEventWriterslistSelected}
                    toolbar={
                      <div className="cc-grid-tools-bar">
                        <PickUserGroupOrgButton
                          onSubmit={(values: IDataUserGroupOrgForm) =>
                            handleAddNew(
                              values,
                              Svc_FormAction_ServiceStandardBatchUpdate.PickSecurityEventWriter
                            )
                          }
                          maxHeight="85%"
                        />
                        <Button
                          type="button"
                          iconClass="fas fa-minus"
                          title="Remove"
                          onClick={() => {
                            setShowConfirmDeleteDialog({
                              dialogType: eConfirmDeleteDialogName.EventWriters,
                              isSelectedMuiltiple:
                                stdEventWriterslistSelected.length > 1,
                            });
                          }}
                          disabled={stdEventWriterslistSelected.length === 0}
                        />
                      </div>
                    }
                    onSelectionChange={(dataItem: AccessControl[]) => {
                      setStdEventWriterslistSelected(dataItem ?? []);
                    }}
                    itemPerPage={50}
                    isAutoHiddenPager={false}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </section>

        {showConfirmDeleteDialog && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={`Are you sure you want to delete ${
              showConfirmDeleteDialog.isSelectedMuiltiple
                ? "these items"
                : "this item"
            }?`}
            onClosePopup={() => setShowConfirmDeleteDialog(undefined)}
            onConfirmYes={handleConfirmDelete}
          />
        )}
      </>
    );
  }
);
