import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { FormRenderProps } from "@progress/kendo-react-form";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export interface ISwitchCustomeFieldProps {
  isEditing?: boolean;
  eSettingsField: ECorporateSettingsField;
  isVisible?: boolean;
}

export interface SwitchValueOptions {
  TRUE: string;
  FALSE: string;
}

export enum EAnimalSettingsCategory {
  //General
  General_General = "General_General",
  General_Registration = "General_Registration",
  General_Renewals = "General_Renewals",
  General_Views = "General_Views",
  General_InspectionPlanner = "General_InspectionPlanner",
  General_MailMerge = "General_MailMerge",

  //Numbering
  Numbering_Details = "Numbering_Details",

  // Pound Register
  PoundRegister_Detail = "PoundRegister_Detail",
  PoundRegister_Numbering = "PoundRegister_Numbering",

  // Infringements
  Infringements_Details = "Infringements_Details",

  // Kennels
  Kennels_Detail = "Kennels_Detail",
  Kennels_Numbering = "Kennels_Numbering",
  Kennels_Fees = "Kennels_Fees",

  // Records
  Records_AttachmentTitleSettings = "Records_AttachmentTitleSettings",
  Records_AnimalSettings = "Records_AnimalSettings",
  Records_KennelsSettings = "Records_KennelsSettings",

  // Dog settings
  // DogSettings Details
  DogSettings_Details_PureBreed = "DogSettings_Details_PureBreed",
  DogSettings_Details_ClubMember = "DogSettings_Details_ClubMember",
  DogSettings_Details_Organisation = "DogSettings_Details_Organisation",
  DogSettings_Details_Menacing = "DogSettings_Details_Menacing",
  DogSettings_Details_HuntingDog = "DogSettings_Details_HuntingDog",
  DogSettings_Details_Greyhound = "DogSettings_Details_Greyhound",
  DogSettings_Details_GuardDog = "DogSettings_Details_GuardDog",
  DogSettings_Details_DogHomeAdoption = "DogSettings_Details_DogHomeAdoption",
  DogSettings_Details_ObedienceTrained = "DogSettings_Details_ObedienceTrained",
  DogSettings_Details_CustomsDog = "DogSettings_Details_CustomsDog",
  DogSettings_Details_SecondaryColour = "DogSettings_Details_SecondaryColour",
  DogSettings_Details_HealthCareCard = "DogSettings_Details_HealthCareCard",
  DogSettings_Details_Breed = "DogSettings_Details_Breed",
  DogSettings_Details_GuideDogAssistance = "DogSettings_Details_GuideDogAssistance",
  DogSettings_Details_Colour = "DogSettings_Details_Colour",
  DogSettings_Details_DOB = "DogSettings_Details_DOB",
  DogSettings_Details_AssessmentNumber = "DogSettings_Details_AssessmentNumber",
  DogSettings_Details_OwnerPostalAddress = "DogSettings_Details_OwnerPostalAddress",
  DogSettings_Details_OwnerMobile = "DogSettings_Details_OwnerMobile",
  DogSettings_Details_AnimalName = "DogSettings_Details_AnimalName",
  DogSettings_Details_Gender = "DogSettings_Details_Gender",
  DogSettings_Details_Dangerous = "DogSettings_Details_Dangerous",
  DogSettings_Details_Working = "DogSettings_Details_Working",
  DogSettings_Details_SterlisedProof = "DogSettings_Details_SterlisedProof",
  DogSettings_Details_ObedienceProof = "DogSettings_Details_ObedienceProof",
  DogSettings_Details_GuideOrAssistanceDogProof = "DogSettings_Details_GuideOrAssistanceDogProof",
  DogSettings_Details_WorkingProof = "DogSettings_Details_WorkingProof",
  DogSettings_Details_CustomsDogProof = "DogSettings_Details_CustomsDogProof",
  DogSettings_Details_PureBreedProof = "DogSettings_Details_PureBreedProof",
  DogSettings_Details_GuardDogProof = "DogSettings_Details_GuardDogProof",
  DogSettings_Details_DogHomeAdoptionProof = "DogSettings_Details_DogHomeAdoptionProof",
  DogSettings_Details_HuntingDogProof = "DogSettings_Details_HuntingDogProof",
  DogSettings_Details_GreyhoundProof = "DogSettings_Details_GreyhoundProof",
  DogSettings_Details_CertifiedAssistance = "DogSettings_Details_CertifiedAssistance",
  DogSettings_Details_RestrictedBreed = "DogSettings_Details_RestrictedBreed",

  //  DogSettings_AnimalClassificationLabel
  DogSettings_AnimalClassificationLabel = "DogSettings_AnimalClassificationLabel",

  //  DogSettings_RestrictedBreed
  DogSettings_RestrictedBreed = "DogSettings_RestrictedBreed",
}
