import { useSessionListStore } from "@app/products/property/journals/session-list/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ExistedPropertyJournal } from "./components/forms/existed/_index";
import { NewPropertyJournal } from "./components/forms/new/_index";

function ManagePropertyJournalSessionList() {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const journalId = params.id;
  const { loadSessionList, resetStore } = useSessionListStore();
  useEffect(() => {
    if (isNew) return;
    loadSessionList(+journalId);
    return () => {
      resetStore();
    };
  }, [isNew, journalId, loadSessionList, resetStore]);

  if (isNew) {
    return <NewPropertyJournal />;
  }

  return <ExistedPropertyJournal />;
}

export default observer(ManagePropertyJournalSessionList);
