import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ContactRegisterForm } from "@common/pages/contacts/manage-contact/model";
import { ReferenceSidebar } from "@components/reference-sidebar/ReferenceSidebar";
import React from "react";
import { ContactReference } from "./components/contact-reference/_index";

export interface IContactReferenceSidebarProps {
  data?: ContactRegisterForm;
  contactReference: any;
  title?: string;
}

export const ContactReferenceSidebar = ({
  contactReference,
  title,
}: IContactReferenceSidebarProps) => (
  <ReferenceSidebar
    title={title}
    isViewMap={false}
    nodeReference={<ContactReference {...contactReference} />}
    // nodeContactsSideBar={<ContactsSidebar data={animalContact} />}
    nodeJournal={
      <Journal
        id={contactReference.Id}
        recordType={RECORDTYPE.Animals_Registration} //TODO
      />
    }
  />
);
