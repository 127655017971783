import { getViewConfigurations } from "@app/products/property/api";
import { eComponent } from "@app/products/property/components/associations/model";
import { DTO_Assessment_Collection } from "@app/products/property/components/child-screen/collections/model";
import {
  ICCViewConfiguration,
  ViewConfiguration,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IGetPropertyCollections =
  | [
      APIResponse<DTO_Assessment_Collection>,
      APIResponse<ICCViewConfiguration | undefined>
    ]
  | APIResponse<DTO_Assessment_Collection | ICCViewConfiguration>;

export const getPropertyCollections = async (
  componentNumber: eComponent,
  componentId: number
): Promise<IGetPropertyCollections> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Assessment_Collection>(
        `api/property/int/collection/${componentNumber}/${componentId}`
      ),
      getViewConfigurations(ViewConfiguration.Collections),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
