import { APIResponse } from "@common/apis/model";
import { GoogleMapSettings } from "@common/models/settings";
import { GeoCoderResponse } from "@components/map/model";
import axios from "axios";

export const getCoordinateFromAddress = async (
  address: string,
  googleMapSetting: GoogleMapSettings
): Promise<APIResponse<GeoCoderResponse | undefined>> => {
  try {
    return await axios.get<GeoCoderResponse>(
      `https://maps.googleapis.com/maps/api/geocode/json?v=3.48&language=${
        googleMapSetting.language || "au"
      }&region=${googleMapSetting.region || "au"}&address=${address}&key=${
        googleMapSetting.key
      }`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
