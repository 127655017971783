import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { InputSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/input/_index";
import { listFieldsConfig } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/psa/custom-labels/config";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const CustomLabelsForm = observer(() => {
  const { isEditing } = useSettingPPRManageStore();
  const { getConfigDataField } = useSettingPPRStore();
  const configField = listFieldsConfig.map((field) =>
    getConfigDataField(field)
  );
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        {configField &&
          configField.map(
            (item) =>
              item && <InputSettings data={item} isEditing={isEditing} />
          )}
      </div>
    </section>
  );
});
