import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { RECORD_TYPE_ASSESSMENT } from "@app/products/property/contacts-central-names/voters-list/components/detail/constant";
import { DTO_VoterDetails } from "@app/products/property/contacts-central-names/voters-list/components/detail/model";
import { PROPERTY_TITLES_ROUTE } from "@app/products/property/titles/[id]/constant";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_VoterDetails>();
export const voterDetailCols: IColumnFields[] = [
  {
    field: nameOf("ID"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem: DTO_VoterDetails) =>
      `${
        dataItem.Type === RECORD_TYPE_ASSESSMENT //Use constant for now. Will compare with the label name when label is applied for General mode
          ? PROPERTY_ASSESSMENT_ROUTE
          : PROPERTY_TITLES_ROUTE
      }/${dataItem.ID}`,
  },
  {
    field: nameOf("Role"),
    title: "Role",
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Reference"),
    title: "Reference",
  },
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("Legal_Description"),
    title: "Legal Description",
  },
  {
    field: nameOf("Is_Active"),
    title: "Active?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];
