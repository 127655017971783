import { getBuildingById } from "@app/products/building/[id]/components/reference-sidebar/detail/api";
import { IBuildingRegister } from "@app/products/building/[id]/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue, splitCamelCase } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

export const BuildingDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [buildingInfo, setBuildingInfo] = useState<
    IBuildingRegister | undefined
  >();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const buildingId: any = lastSelectedRow?.ID ?? params.id;
  useEffect(() => {
    setIsLoading(true);
    getBuildingById(buildingId).then((data) => {
      setBuildingInfo(data);
      setIsLoading(false);
    });
  }, [buildingId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!buildingInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Register Number:"}
              value={buildingInfo.Number?.toString()}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Status:"}
              value={splitCamelCase(buildingInfo.Status)}
              success
            />
            <ProductReferenceRow
              title={"OS Fees:"}
              value={formatDisplayValue(
                buildingInfo.OSFees,
                CURRENCY_FORMAT.CURRENCY1
              )}
              format="currency"
            />
            <ProductReferenceRow
              title={"Created Date:"}
              value={formatDisplayValue(
                buildingInfo.CreatedDate,
                DATE_FORMAT.DATE
              )}
            />
            <ProductReferenceRow
              title={"Lodged Date:"}
              value={formatDisplayValue(
                buildingInfo.LodgedDate,
                DATE_FORMAT.DATE
              )}
            />
            <ProductReferenceRow
              title={"Clock Start:"}
              value={formatDisplayValue(
                buildingInfo.ClockStart,
                DATE_FORMAT.DATE
              )}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
