import { ElementControl, LookupTable } from "@app/products/crms/[id]/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";

export enum Svc_FormAction_Lookuptable {
  SystemInitialise,
  ChangeLookupType,
  PickSpecialInterest,
  PickOrgStructure,
}

export interface LookuptableHandlerRequest {
  LookuptableFormAction: Svc_FormAction_Lookuptable;
  Lookuptable: LookupTable;
  EventArgs: any;
  IsFirstTimeLoad: boolean;
}

export interface LookuptableUIControl {
  DivParentLookup: ElementControl;
  DdlEnumeratedValue: ElementControl;
}

export interface LookuptableHandlerResponse {
  Lookuptable: LookupTable;
  UIControl: LookuptableUIControl;
}

export interface LookuptablePickOrgAndSiteuser {
  Siteusers: number[];
  Hierarchies: number[];
}
export interface LookupTableLovs {
  LookupTableTypes: KeyValuePacket[];
}
