import {
  getLovsUpdateNoticeGroup,
  postUpdateNoticeGroup,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/update-notice-group/api";
import { DTO_Assessment_NoticeGroups } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/update-notice-group/model";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { nameOfLov } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DTO_LOV } from "@common/models/odataResponse";
import { getDropdownValue } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAddToNoticeGroupDialogProps {
  onClose: () => void;
}

export const AddToNoticeGroupDialog = observer(
  ({ onClose }: IAddToNoticeGroupDialogProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
    const [initAddToLovData, setInitAddToLovData] = useState<DTO_LOV[]>([]);
    const [initNoticeGroupLovData, setInitNoticeGroupLovData] = useState<
      DTO_LOV[]
    >([]);

    const [responseLoadError, setResponseError] = useState<
      APIResponseError | undefined
    >();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const { pushNotification } = useCCAppNotificationStore();
    const { reLoadAssessment, assessmentId } = useAssessmentStore();

    const loadLovs = async () => {
      setIsLoading(true);
      setResponseError(undefined);
      const response = await getLovsUpdateNoticeGroup();
      setIsLoading(false);
      if (
        isSuccessResponse(response) &&
        response?.data?.Roles?.length > 0 &&
        response?.data?.NoticeGroups?.length > 0
      ) {
        setInitAddToLovData(response.data.Roles);
        setInitNoticeGroupLovData(response.data.NoticeGroups);
      } else {
        setResponseError({
          status: response?.status,
          error: response?.error ?? "Load lov add to notice group failed",
        });
      }
    };

    const handleSubmit = async (value: any) => {
      setIsLoadingSubmit(true);
      const response = await postUpdateNoticeGroup({
        Assessment_Id: assessmentId,
        Role: value?.Add_To,
        Notice_Group_Id: value?.Notice_Group,
      } as DTO_Assessment_NoticeGroups);
      setIsLoadingSubmit(false);
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        onClose();
        reLoadAssessment().then(() => {
          pushNotification({
            title: "Notice group updated successfully",
            type: "success",
          });
        });
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title:
            response.data?.ErrorMessage ?? "Notice group could not be updated",
          type: "error",
        });
      }
    };

    useEffectOnce(() => {
      loadLovs();
    });

    return (
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const {
            valueGetter,
            onChange,
            onSubmit: onFormSubmit,
          } = formRenderProps;
          return (
            <CCDialog
              titleHeader="Update Notice Group"
              onClose={onClose}
              disabled={isLoading}
              maxWidth="40%"
              height="auto"
              bodyElement={
                isLoading ? (
                  <Loading isLoading />
                ) : responseLoadError ? (
                  <div>
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={loadLovs}
                    />
                  </div>
                ) : (
                  <div className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <FormElement className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <section className="cc-field-group">
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <label className="cc-label">Add to</label>
                              <Field
                                name="Add_To"
                                data={initAddToLovData}
                                component={CCSearchComboBox}
                                textField={nameOfLov("Name")}
                                dataItemKey={nameOfLov("Code")}
                                value={getDropdownValue(
                                  valueGetter("Add_To"),
                                  initAddToLovData,
                                  nameOfLov("Code")
                                )}
                                onChange={(event: ComboBoxChangeEvent) => {
                                  onChange("Add_To", {
                                    value: event.target.value?.Code ?? null,
                                  });
                                }}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">Notice group</label>
                              <Field
                                name="Notice_Group"
                                data={initNoticeGroupLovData}
                                component={CCSearchComboBox}
                                textField={nameOfLov("Name")}
                                dataItemKey={nameOfLov("Code")}
                                value={getDropdownValue(
                                  valueGetter("Notice_Group"),
                                  initNoticeGroupLovData,
                                  nameOfLov("Code")
                                )}
                                onChange={(event: ComboBoxChangeEvent) => {
                                  onChange("Notice_Group", {
                                    value: event.target.value?.Code ?? null,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </section>
                      </div>
                    </FormElement>
                  </div>
                )
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={isLoadingSubmit || !isNil(responseLoadError)}
                    iconClass={isLoadingSubmit ? "fas fa-spinner fa-spin" : ""}
                    onClick={onFormSubmit}
                  >
                    Finish
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
