import { InspectionRegisterForm } from "@app/products/hm/model";

export const validate = (inspections: InspectionRegisterForm | undefined) => {
  const errors: { [k: string]: any } = {};
  if (inspections?.Officer_ID === 0) {
    errors.officer = "You must select an existing Officer.";
  }
  if (inspections?.Reason_ID === 0) {
    errors.Reason = "You must select an existing Reason.";
  }
  if (inspections?.InspectionType_ID === 0) {
    errors.inspectionType = "You must select an existing Inspection Type.";
  }
  if (inspections?.Compliance_ID === 0) {
    errors.Compliance = "You must select an existing Compliance.";
  }
  if (inspections?.TimeCompleted === null) {
    errors.Time = "You must select an existing Compliance.";
  }
  if (inspections?.TimeStarted === null) {
    errors.Time = "You must select an existing Compliance.";
  }
  if (inspections?.DateInspected === null) {
    errors.Time = "You must select an existing Compliance.";
  }
  return errors;
};
