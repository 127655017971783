import { filterBy } from "@progress/kendo-data-query";
import {
  MultiSelect,
  MultiSelectFilterChangeEvent,
  MultiSelectProps,
} from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import { isNil } from "lodash";
import React, { useState } from "react";
interface ICCMultiSelectDropdownProps {
  disabledDateInput?: boolean;
  validationMessage?: string | null;
  visited?: boolean;
}
export const CCMultiSelectDropdown = (
  props: MultiSelectProps & ICCMultiSelectDropdownProps
) => {
  const {
    onChange,
    data,
    visited,
    validationMessage,
    textField,
    dataItemKey,
    ...others
  } = props;
  const [isOpened, setIsOpened] = useState(false);

  const handleOpen = () => {
    setIsOpened(true);
  };

  const handleClose = () => {
    setIsOpened(false);
  };

  const [dataSelection, setDataSelection] = useState<any[]>(
    data ? [...data] : []
  );

  const handleSearch = (event: MultiSelectFilterChangeEvent) => {
    const dataSearch = data ? [...data] : [];
    if (event.filter?.value?.length === 0) setDataSelection(dataSearch);

    if (isNil(textField) || isNil(dataItemKey)) {
      setDataSelection(filterBy(dataSelection.slice(), event.filter));
    } else {
      const filterValue = event.filter.value.toLocaleLowerCase();
      const filterData = dataSearch?.filter((item) =>
        item?.[textField ?? ""].toLocaleLowerCase().includes(filterValue)
      );
      setDataSelection(filterData ?? []);
    }
  };

  return (
    <div className="cc-multiple-selection-dropdown">
      <MultiSelect
        {...others}
        onOpen={handleOpen}
        opened={isOpened}
        onBlur={handleClose}
        onChange={onChange}
        filterable
        onFilterChange={handleSearch}
        data={dataSelection}
        dataItemKey={dataItemKey}
        textField={textField}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
