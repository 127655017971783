import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colContactAssociationAssessmentRebateEntitlement } from "@app/products/property/components/associations/components/assessment-rebate-entitlement/contact-assessment-rebate/config";
import { DTO_AssociatedItem_AssessmentRebateEntitlement } from "@app/products/property/components/associations/components/assessment-rebate-entitlement/model";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IContactAssessmentRebateEntitlementProps {
  data: DTO_AssociatedItem_AssessmentRebateEntitlement[];
}

const nameOf = nameOfFactory<DTO_AssociatedItem_AssessmentRebateEntitlement>();
export const ContactAssociationAssessmentRebateEntitlement = ({
  data,
}: IContactAssessmentRebateEntitlementProps) => {
  const [processedCols, setProcessedCols] = useState<IColumnFields[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Associations_RebateEntitlement,
      colContactAssociationAssessmentRebateEntitlement
    );
    if (Array.isArray(response)) {
      setProcessedCols(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <CCGrid
      data={data}
      columnFields={processedCols}
      primaryField={nameOf("Id")}
    />
  );
};
