import { RaisePenaltyInterestDialog } from "@app/products/property/journals/components/form-elements/raise-penalty-interest/_index";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";
interface IRaisePenaltyInterestButtonProps {
  visible?: boolean;
}
export const RaisePenaltyInterestButton = ({
  visible,
}: IRaisePenaltyInterestButtonProps) => {
  const [isShowDialog, setIsShowDialog] = useState(false);

  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Workflow}
        privilegesEnum={EPrivilegesWorkflow.Journal_Raise_Penalty_Interest}
      >
        <CCNavButton
          title="Raise penalty interest"
          onClick={() => setIsShowDialog(true)}
          invisible={!visible}
        />
      </PrivilegeWrapper>
      {isShowDialog && (
        <RaisePenaltyInterestDialog onClose={() => setIsShowDialog(false)} />
      )}
    </>
  );
};
