import { colAssociationsMasterProperty } from "@app/products/property/components/associations/components/master-property/config";
import { DTO_AssociatedItem_MasterProperty } from "@app/products/property/components/associations/components/master-property/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsMasterPropertyProps {
  associationsMasterPropertyInfo:
    | DTO_AssociatedItem_MasterProperty[]
    | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_MasterProperty>();

export const AssociationsMasterProperty = ({
  associationsMasterPropertyInfo,
}: IAssociationsMasterPropertyProps) => {
  return (
    <CCGrid
      className="cc-master-property-grid"
      data={associationsMasterPropertyInfo || []}
      columnFields={colAssociationsMasterProperty}
      primaryField={nameOf("Id")}
    />
  );
};
