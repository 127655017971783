import { BuildingFieldMapping } from "@app/products/building/model";
import {
  barrierStandardsFields,
  officerFields,
  regulationsFields,
} from "@app/products/building/pools-or-spas/[id]/components/child-screens/general/components/form-element/config";
import {
  dropdownStatusData,
  dropdownTypeData,
  parentPage,
} from "@app/products/building/pools-or-spas/[id]/components/child-screens/general/components/form-element/mock";
import { IBuildingPoolsSpas } from "@app/products/building/pools-or-spas/[id]/model";
import { RegisterAddressPopup } from "@app/products/property/components/register-address-popup/_index";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory, sortByField } from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const nameOf = nameOfFactory<IBuildingPoolsSpas>();
interface IProps {
  formRenderProps: FormRenderProps;
}

export const BuildingPoolsSpasFormElement = observer(
  ({ formRenderProps }: IProps) => {
    const [showAddressPopup, setShowAddressPopup] = useState(false);
    const history = useHistory();
    const { pushNotification } = useCCAppNotificationStore();

    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }

      if (!formRenderProps.valid) return;

      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    const getValue = formRenderProps.valueGetter;

    return (
      <FormElement>
        {showAddressPopup && (
          <RegisterAddressPopup
            onClosePopup={() => setShowAddressPopup(false)}
          />
        )}

        {/* Reference by a label in WastewaterActionBar.tsx */}
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Contact
                <CCTooltip
                  type="validator"
                  position="right"
                  content={formRenderProps.errors.Contact}
                />
              </label>
              <Field
                onClick={() => {
                  history.push(`/managecontactrelationship/${"123"}`, {
                    parentPage,
                    recordType: "11000",
                  });
                }}
                placeholder={"Contact"}
                autoComplete="off"
                name={"Contact"}
                className="disabled"
                component={InputPicker}
                validator={validateRequired}
              />
            </div>
            <CCValueField label="Address" value={getValue("Contact_Address")} />
            <CCValueField label="Contact" value={getValue("Contact_Contact")} />
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Site address
                <CCTooltip
                  type="validator"
                  position="right"
                  content={formRenderProps.errors.Address}
                />
              </label>
              <Field
                onClick={() => setShowAddressPopup(true)}
                name={nameOf("Address")}
                validator={validateRequired}
                component={InputPicker}
                placeholder={"Site address"}
                readOnly
                className="disabled"
              />
            </div>
            <CCValueField label="Owner" value={getValue(nameOf("Owner"))} />
            <CCValueField label="Details" value={getValue(nameOf("Details"))} />
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-3">
              <label className="cc-label">Description</label>
              <Field
                name={BuildingFieldMapping.Description}
                placeholder="Description"
                rows={8}
                value={getValue(BuildingFieldMapping.Description)}
                component={CCTextArea}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Type</label>
              <Field
                name={nameOf("Type")}
                dataItemKey="Key"
                textField="Value"
                data={sortByField(dropdownTypeData, "Value")}
                isUseDefaultOnchange
                component={CCSearchComboBox}
              />
            </div>
            <div className="cc-field">
              <label className="label">Status</label>
              <Field
                name={nameOf("Status")}
                dataItemKey="Key"
                textField="Value"
                data={sortByField(dropdownStatusData, "Value")}
                isUseDefaultOnchange
                component={CCSearchComboBox}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Officer</label>
              <Field
                autoComplete="off"
                component={InputPicker}
                {...officerFields(formRenderProps)}
                name={nameOf("Officer")}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Reference number</label>
              <Field
                name={nameOf("RefNo")}
                placeholder={"Reference number"}
                component={CCInput}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Enforcement number</label>
              <Field
                name={nameOf("EnforcementNo")}
                placeholder={"Enforcement number"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Lodged</label>
              <Field
                name={nameOf("Lodged")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Registered date</label>
              <Field
                name={nameOf("Complete")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">File number</label>
              <Field
                name={nameOf("FileNumber")}
                placeholder={"File number"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Barrier standards</label>
              <Field
                component={InputPicker}
                {...barrierStandardsFields(formRenderProps)}
                name={nameOf("BarrierTypes")}
              />
            </div>
            <CCValueField
              className="cc-col-span-2"
              label="Description"
              value={getValue(nameOf("BarrierTypesDesc"))}
            />
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">BCA or regulations applicable</label>
              <Field
                component={InputPicker}
                {...regulationsFields(formRenderProps)}
                name={nameOf("Regulations")}
              />
            </div>
            <CCValueField
              className="cc-col-span-2"
              label="Description"
              value={getValue(nameOf("RegulationsDesc"))}
            />
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Date of construction</label>
              <Field
                name={nameOf("Construction")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">
                Due date for certificate of barrier compliance
              </label>
              <div className="cc-form-cols-2">
                <Field
                  name={nameOf("ComplianceRequired")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                />
              </div>
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Lodgement date of certificate of barrier compliance
              </label>
              <Field
                name={nameOf("RegisteredDate")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Lodgement date of certificate of barrier non-compliance
              </label>
              <Field
                name={nameOf("LodgementCertificateBarrierNC")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Permit number issued on the barrier after date of construction
              </label>
              <Field
                name={nameOf("BuildingPermitNo")}
                placeholder="Permit number issued on the barrier after date of construction"
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Date of works issued on the barrier after date of construction
              </label>
              <Field
                name={nameOf("WorksIssued")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <CCValueField
              label="OS fees"
              value={getValue(BuildingFieldMapping.OutstandingFees)}
              format={CURRENCY_FORMAT.CURRENCY1}
              style={formatOSFees(
                getValue(BuildingFieldMapping.OutstandingFees)
              )}
            />
            <div className="cc-field">
              <label className="cc-label">Debtor number</label>
              <Field
                name={BuildingFieldMapping.DebtorNumber}
                placeholder={"Debtor number"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <CCValueField
              label="Last inspection"
              value={getValue(nameOf("LastInspection"))}
              format={DATE_FORMAT.DATE}
            />
            <CCValueField
              label="Last inspection compliance"
              value={getValue(nameOf("LastInspectionCompliance"))}
              format={DATE_FORMAT.DATE}
            />
            <CCValueField
              label="Last inspection date"
              value={getValue(nameOf("LastInspectionDate"))}
              format={DATE_FORMAT.DATE}
            />
          </div>
        </section>
      </FormElement>
    );
  }
);
