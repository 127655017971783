import { CORE_GET_ACTION_TYPES_ODATA } from "@app/core/components/common/constant";
import { nameOfFactory } from "@common/utils/common";
import {
  CCInputPicker,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import { colPickOrgUnits } from "@components/cc-pick-org-units/config";
import { OrgUnit } from "@components/cc-pick-org-units/model";
import React from "react";

export interface IFunctionGroupPickerProps extends ICCInputPickerProps {}

export const FunctionGroupPicker = (props: IFunctionGroupPickerProps) => {
  const nameOf = nameOfFactory<OrgUnit>();
  const { onError, ...others } = props;

  return (
    <CCInputPicker
      {...others}
      dialog={{
        titleHeader: "Pick Function Group",
        maxWidth: "40%",
      }}
      textField={"Hierarchy"}
      grid={{
        columnFields: colPickOrgUnits,
        primaryField: nameOf("ID"),
        selectableMode: "single",
        dataUrl: `${CORE_GET_ACTION_TYPES_ODATA}(isAdhoc=true,orgLevel=null)?$count=true&`,
        isAutoHiddenPager: false,
        state: {
          sort: [{ field: nameOf("Hierarchy"), dir: "asc" }],
        },
      }}
    />
  );
};
