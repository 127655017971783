import { getViewConfigurations } from "@app/products/property/api";
import { AddTitleLookupDialog } from "@app/products/property/components/dialogs/add-title-lookup/_index";
import { getSearchTitleLookup } from "@app/products/property/components/dialogs/add-title-lookup/api";
import { eOptionSearchTitleLookup } from "@app/products/property/components/dialogs/add-title-lookup/config";
import {
  DTO_Title as DTO_Title_Lookup,
  fnt_Title_LookupResult,
} from "@app/products/property/components/dialogs/add-title-lookup/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  ICCViewColumn,
  nameOfLov,
  ViewConfiguration,
} from "@app/products/property/model";
import { processCombineData } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { colAssociatedTitles } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/form-elements/associated-titles/config";
import {
  DTO_Title,
  EGridLoadingType,
} from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/model";
import { useCreateTitleStore } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/store";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { Label } from "@common/stores/products/config";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { DropDownEditCell } from "@components/cc-grid/components/grid-cells/components/drop-down-edit-cell/_index";
import { IColumnFields, ICustomEditCell } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

export const AssociatedTitles = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfTitles = nameOfFactory<DTO_Title>();

const FormStepElement = observer(
  ({
    formRenderProps,
    localNotificationRef,
    nameOf,
    options = {
      isReadOnly: false,
      setIsLoadingDialog: () => {},
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { lovsCreateTitle } = useCreateTitleStore();
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);

    //Get labels
    const [titleLabelLowercase] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.TitleLowercase,
    ]);

    // Variable for title
    const titles: DTO_Title_Lookup[] = getFieldValue("") ?? [];
    const selectedTitle = getFieldValue("_option.TitlesSelected") ?? [];
    const isLoading = getFieldValue("_option.Loading");
    const [processedColumns, setProcessedColumns] =
      useState<IColumnFields[]>(colAssociatedTitles);
    // State
    const [isShowDialog, setIsShowDialog] = useState<boolean>();

    const loadViewConfiguration = async () => {
      onChange(nameOf("_option.Loading"), {
        value: EGridLoadingType.ViewConfig,
      });
      options?.setIsLoadingDialog(true);
      getViewConfigurations(ViewConfiguration.TitleSearch).then((response) => {
        if (isSuccessResponse(response)) {
          const viewConfig: ICCViewColumn[] | undefined =
            response?.data?.ColumnDefinitions?.Columns;
          if (!viewConfig || viewConfig?.length === 0) {
            setProcessedColumns([]);
            onChange(nameOf("_option.Loading"), { value: undefined });
            options?.setIsLoadingDialog(false);

            return;
          }
          setProcessedColumns(
            processDynamicColumns(colAssociatedTitles, viewConfig)
          );
        } else {
          setResponseLoadError({
            status: response.status,
            error: response.error ?? "Load failed",
          });
        }
        onChange(nameOf("_option.Loading"), { value: undefined });
        options?.setIsLoadingDialog(false);
      });
    };

    const associatedTitleCols = useMemo(() => {
      return processedColumns.map((col: IColumnFields) => {
        if (col.field === nameOfTitles("Association_Type")) {
          return {
            ...col,
            width: 200,
            editCell: (props: ICustomEditCell) => (
              <DropDownEditCell
                {...props}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                dropdownList={lovsCreateTitle?.Title_Association_Type ?? []}
              />
            ),
            editable: true,
          };
        }
        return col;
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDataChangeGrid = (dataRow: any, fieldChange: string) => {
      const titleId = dataRow?.TitleId;
      let newTitle = [...titles];
      newTitle = newTitle.map((item: DTO_Title_Lookup) => {
        if (
          item.TitleId === titleId &&
          fieldChange === nameOfTitles("Association_Type")
        ) {
          return {
            ...item,
            Association_Type: dataRow?.["Association_Type"] ?? null,
          };
        }
        return item;
      });
      onChange(nameOf(""), { value: newTitle });
    };

    // Handle logic for Title grid
    /**
     * handle add item for Title)
     * @param data
     */
    const handleAddItemTitles = async (data: fnt_Title_LookupResult[]) => {
      const listTitleId = data.map((item) => item.Title_Id).toString();
      onChange(nameOf("_option.Loading"), {
        value: EGridLoadingType.Grid,
      });
      options?.setIsLoadingDialog(true);
      const responseTitleLookup = await getSearchTitleLookup({
        LookupKey: eOptionSearchTitleLookup.TitleId,
        LookupValue: listTitleId,
        Statuses: [0], //default task 12866
      });
      onChange(nameOf("_option.Loading"), {
        value: false,
      });
      options?.setIsLoadingDialog(false);
      if (isSuccessResponse(responseTitleLookup) && responseTitleLookup?.data) {
        const resTitle = responseTitleLookup?.data?.Titles ?? [];
        const newData: DTO_Title_Lookup[] =
          resTitle?.map((item: DTO_Title_Lookup) => {
            return {
              ...item,
              RowId: getUUID(),
            };
          }) ?? [];
        const previousTitles = titles?.length ? [...titles] : [];
        const newDataCombine = processCombineData(
          previousTitles,
          newData,
          nameOfTitles("TitleId")
        );
        onChange(nameOf(""), {
          value: newDataCombine,
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${titleLabelLowercase} failed`,
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsLoadingDialog(false);
      onChange(nameOf("_option.Loading"), {
        value: false,
      });
    };

    /**
     * handle delete item (Title)
     */
    const handleDeleteItem = () => {
      const titleId = selectedTitle?.[0]?.TitleId;
      //filter new list Title without selected record
      const newTitles = titles.filter(
        (item: DTO_Title_Lookup) => item?.TitleId !== titleId
      );
      onChange(nameOf(""), {
        value: newTitles,
      });
      onChange(nameOf("_option.TitlesSelected"), {
        value: [],
      });
    };

    useEffectOnce(() => {
      (async () => {
        await loadViewConfiguration();
      })();
    });

    return (
      <section className="cc-field-group">
        <CCLabel title={`Associated ${titleLabelLowercase}`} />
        {isLoading === EGridLoadingType.ViewConfig ? (
          <Loading isLoading />
        ) : responseLoadError ? (
          <CCLoadFailed
            responseError={responseLoadError}
            onReload={() => {
              loadViewConfiguration();
            }}
          />
        ) : (
          <CCGrid
            toolbar={
              !options?.isReadOnly ? (
                <div className="cc-grid-tools-bar">
                  <Button
                    type="button"
                    iconClass="fas fa-plus"
                    title="Add"
                    onClick={() => {
                      setIsShowDialog(true);
                    }}
                  />
                  <Button
                    type="button"
                    iconClass="fas fa-minus"
                    title="Remove"
                    onClick={() => {
                      handleDeleteItem();
                    }}
                    disabled={selectedTitle?.length !== 1}
                  />
                </div>
              ) : null
            }
            selectedRows={selectedTitle}
            readOnly={options?.isReadOnly}
            primaryField={nameOfTitles("TitleId")}
            columnFields={associatedTitleCols ?? []}
            data={titles ?? []}
            isLoading={isLoading === EGridLoadingType.Grid}
            selectableMode={!options?.isReadOnly ? "single" : undefined}
            editableMode={!options?.isReadOnly ? "cell" : undefined}
            onDataRowChange={(dataRow: any, fieldChange: string) => {
              handleDataChangeGrid(dataRow, fieldChange);
            }}
            onSelectionChange={(dataItem: any[]) => {
              onChange(nameOf("_option.TitlesSelected"), {
                value: dataItem ?? [],
              });
            }}
          />
        )}
        {isShowDialog && (
          <AddTitleLookupDialog
            onClose={() => setIsShowDialog(false)}
            handleAddTitle={(data: fnt_Title_LookupResult[]) => {
              setIsShowDialog(false);
              handleAddItemTitles(data);
            }}
          />
        )}
      </section>
    );
  }
);
