import { getNoticeRunDetailsTabById } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/reference-sidebar/detail/api";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { parseInt } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { DTO_NoticeRun_TabDetails } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/reference-sidebar/detail/model";

export const NoticeRunInfoDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { lastSelectedId } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const [noticeRunInfo, setNoticeRunInfo] = useState<
    DTO_NoticeRun_TabDetails | undefined
  >();
  const noticeRunId: string | number = useMemo(() => {
    return id ?? lastSelectedId;
  }, [lastSelectedId, id]);

  useEffect(() => {
    if (noticeRunId) {
      setIsLoading(true);
      getNoticeRunDetailsTabById(parseInt(noticeRunId)).then((data) => {
        setNoticeRunInfo(data);
        setIsLoading(false);
      });
    }
  }, [noticeRunId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!noticeRunInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Number of notices: "}
          value={noticeRunInfo?.NumberOfNotice}
        />

        <ProductReferenceRow
          title={"Total due: "}
          value={formatDisplayValue(
            noticeRunInfo?.TotalDue,
            CURRENCY_FORMAT.CURRENCY1
          )}
          format="currency"
        />
        <ProductReferenceRow
          title={"Created on: "}
          value={formatDisplayValue(noticeRunInfo?.CraetedOn, DATE_FORMAT.DATE)}
        />
        <ProductReferenceRow
          title={"Created by: "}
          value={noticeRunInfo?.CreatedBy}
        />
        <ProductReferenceRow
          title={"Produced on: "}
          value={formatDisplayValue(
            noticeRunInfo?.ProducedOn,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Produced by: "}
          value={noticeRunInfo?.ProducedBy} // TODO: Checking field name when have API
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
