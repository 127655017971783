import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { VO_ACT_Lease_Transfer } from "@app/products/property/changes-of-ownership/lease-transfers/model";

export const ChangeOfOwnershipLeaseTransfersDetailTab = observer(() => {
  const [changeOfOwnershipInfo, setChangeOfOwnershipInfo] = useState<
    VO_ACT_Lease_Transfer | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { lastSelectedRow } = useCCProductListViewStore();

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      if (!lastSelectedRow) return setChangeOfOwnershipInfo(undefined);
      setChangeOfOwnershipInfo(lastSelectedRow);
      setIsLoading(false);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [lastSelectedRow]);

  if (!changeOfOwnershipInfo) return <NoData />;
  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Lease Transfer ID:"}
              value={
                changeOfOwnershipInfo?.ACT_Lease_Transfer_Id?.toString() ?? ""
              }
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Property Address:"}
              value={
                changeOfOwnershipInfo?.Property_Name_Address_Locality ?? ""
              }
            />
            <ProductReferenceRow
              title={"Settlement Date:"}
              value={changeOfOwnershipInfo?.ALT_Settlement_Date ?? ""}
            />
            <ProductReferenceRow
              title={"Document Type:"}
              value={
                changeOfOwnershipInfo?.ACT_Lease_Transfer_Document_Type ?? ""
              }
            />
            <ProductReferenceRow
              title={"Document Number:"}
              value={changeOfOwnershipInfo?.ALT_Document_Number ?? ""}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
