import { IColumnFields } from "@components/cc-grid/model";
import { GridSelectionDialog } from "@components/dialog/dialog-grid-selection/_index";
import { Button } from "@progress/kendo-react-buttons";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import "./InputPicker.scss";

export interface IInputPickerProps {
  id: number;
  isMultiSelect: boolean;
  titleHeader: string;
  placeholder: string;
  tableTypes: IColumnFields[];
  url: string;
  disabled: boolean;
  fieldName: string;
  value: string;
  gridPrimaryField?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setData?: (value: unknown) => void;
  onClick?: () => void;
  onSelect?: (selectedData: any) => void;
}

const InputPicker = (props: IInputPickerProps & FieldRenderProps) => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setIsValid(props.visited && props.validationMessage ? false : true);
  }, [props.validationMessage, props.visited]);

  //#endregion
  //#region HANDLING FUNCTIONS
  const handleOnBlurInput = async () => {
    if (props.id === undefined) {
      setIsValid(props.visited && props.validationMessage ? false : true);
      return;
    }
    if (props.id !== 0) {
      return;
    }
  };

  const handleOnChangeInput = (event: any) => {
    // Fire from grid selection

    // eslint-disable-next-line
    // @ts-ignore
    if (event.detail) {
      setIsValid(true);
    }

    props.onChange(event);
  };

  //#endregion
  return (
    <div className="cc-input-picker">
      <div className="cc-input-picker-form-row">
        <Input
          className="cc-input-picker-field"
          autoComplete="off"
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleOnChangeInput}
          name={props.fieldName}
          disabled={props.disabled}
          readOnly={props.readOnly}
          onBlur={handleOnBlurInput}
          valid={isValid}
          defaultValue={props.defaultValue}
        />
        <div>
          <Button
            disabled={props.disabledButton}
            className="cc-input-picker-button"
            iconClass="fa fa-ellipsis-h"
            onClick={(event) => {
              event.preventDefault();
              if (!props.onClick) {
                return setIsShowDialog(true);
              }
              props.onClick();
            }}
          />
        </div>
      </div>

      {isShowDialog && (
        <GridSelectionDialog
          titleHeader={props.titleHeader}
          onCloseDialog={() => {
            setIsShowDialog(false);
          }}
          columnFields={props.tableTypes}
          dataUrl={props.url}
          selectableMode={props.isMultiSelect ? "multiple" : "single"}
          flag_btnClearSelection={props.flag_btnClearSelection}
          setShowDialog={(isShow: boolean) => {
            setIsShowDialog(isShow);
          }}
          primaryField={props.gridPrimaryField}
          onSubmit={(selectedData: any) => {
            if (props.onSelect) props.onSelect(selectedData);
          }}
        />
      )}
    </div>
  );
};

export default InputPicker;
