import { IRelated } from "@app/core/related/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IRelated>();
export const colRelateds: IColumnFields[] = [
  {
    field: nameOf("Desciption"),
    title: "Desciption",
  },
  {
    field: nameOf("Ralationship"),
    title: "Ralationship",
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
];
