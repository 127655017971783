import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { IPSARApplicationParentSection } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistedCRMSServiceStandard } from "./components/forms/existed/_index";
import { NewCRMSServiceStandard } from "./components/forms/new/_index";

const ManageCRMSServiceStandard = observer(() => {
  const isNew = useIsNew();
  const { resetStore, setParentSection } = useCRMSServiceStandardStore();
  const location = useLocation();
  const notificationState = location.state as IPSARApplicationParentSection;

  useEffect(() => {
    if (notificationState && notificationState?.notification) {
      setParentSection(notificationState);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationState]);

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  if (isNew) {
    return <NewCRMSServiceStandard />;
  }
  return <ExistedCRMSServiceStandard />;
});

export default ManageCRMSServiceStandard;
