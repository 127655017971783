import React, { Fragment } from "react";

interface IProps {
  title: string;
  counter?: number;
}

const PanelBarTitle: React.FC<IProps> = ({ title, counter }) => {
  return (
    <Fragment>
      <div style={{width: "100%"}}>
        <h6 style={{ cursor: "pointer" }}>
          {title}&nbsp;
          {counter &&  <span className="disabled">( {counter} )</span>}
        </h6>
        <hr style={{ borderColor: "var(--component-color)" }} />
      </div>
    </Fragment>
  );
};

export default PanelBarTitle;
