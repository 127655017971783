import { RecallOfficerSection } from "@app/core/recall/[id]/components/general/form-element/components/officer-section/_index";
import { RecallParentSection } from "@app/core/recall/[id]/components/general/form-element/components/parent-section/_index";
import { RecallSection } from "@app/core/recall/[id]/components/general/form-element/components/recall-section/_index";
import { getRecallGeneralForm } from "@app/core/recall/[id]/util";
import { Recall } from "@app/core/recall/model";
import "@app/products/animals/components/forms/components/form-element/_index.scss";
import { DBRowState } from "@app/products/crms/[id]/model";
import { mapEnum } from "@common/utils/common";
import { FormElement, FormRenderProps } from "@progress/kendo-react-form";
import React, { useMemo } from "react";

interface IRecallFormElementProps {
  formRenderProps: FormRenderProps;
}

export const RecallFormElement = ({
  formRenderProps,
}: IRecallFormElementProps) => {
  const recall = getRecallGeneralForm() as Recall;

  const isInactive = useMemo(() => {
    return !!(
      mapEnum(recall?.Sys_DBRowState, DBRowState) === DBRowState.Inactive &&
      recall?.Recall_ID
    );
  }, [recall?.Sys_DBRowState, recall?.Recall_ID]);

  return (
    <FormElement>
      <RecallParentSection formRenderProps={formRenderProps} />
      <hr className="cc-divider" />
      <RecallSection isInactive={isInactive} />
      <hr className="cc-divider" />
      <RecallOfficerSection
        isInactive={isInactive}
        formRenderProps={formRenderProps}
      />
    </FormElement>
  );
};
