import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IParentSection } from "@common/pages/contacts/model";

export const mockDropdownLevyPayerData: IKeyValuePacket[] = [
  { Key: 1, Value: "Committee" },
  { Key: 2, Value: "Council" },
  { Key: 3, Value: "Delegate" },
  { Key: 4, Value: "Development Control Unit" },
  { Key: 5, Value: "DPC" },
  { Key: 6, Value: "Land and Environment Court" },
  { Key: 7, Value: "Local Planning Panel" },
  { Key: 8, Value: "Minister" },
  { Key: 9, Value: "Senior Statutory Planner" },
  { Key: 10, Value: "Sydney Nth Planning Panel(JRPP)" },
  { Key: 11, Value: "Tribunal" },
];

export const mockParentPage: IParentSection[] = [
  {
    key: 1,
    label: "Application",
    value: "19/TP/000284 50 Sarton Link Pakenham VIC 3810",
  },
  {
    key: 2,
    label: "Category",
    value: "[EBuilding] - New dwelling, garage and retaining wall",
  },
  {
    key: 3,
    label: "Applicant",
    value: "Peter John Hassan",
  },
];

export const mockDropdownLandTypeData: IKeyValuePacket[] = [
  { Key: 1, Value: "Agricuture" },
  { Key: 2, Value: "City" },
  { Key: 3, Value: "Country" },
  { Key: 4, Value: "Highway" },
];

export const mockDropdownLandOwnershipData: IKeyValuePacket[] = [
  { Key: 1, Value: "Andrew Type" },
  { Key: 2, Value: "Agricuture" },
  { Key: 3, Value: "Highway" },
  { Key: 4, Value: "Master Yi" },
];

export const mockDropdownInsuranceCompanyData: IKeyValuePacket[] = [
  { Key: 1, Value: "AIA" },
  { Key: 2, Value: "AXA" },
  { Key: 3, Value: "Berkshire Hathaway" },
  { Key: 4, Value: "China Life Insurance" },
  { Key: 5, Value: "Ping An Insurance" },
];

export const mockDropdownCertRequiredeData: IKeyValuePacket[] = [
  { Key: 1, Value: "Cert of final Insp" },
  { Key: 2, Value: "Occ Final" },
  { Key: 3, Value: "Occ Permit" },
  { Key: 4, Value: "Occupacy Permit" },
];
