import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export enum PSARSubmitActions {
  New = "New",
  Save = "Save",
  LodgeApplication = "LodgeApplication",
  CreateAndLodgeApplication = "CreateAndLodgeApplication",
  WithdrawnApplication = "WithdrawnApplication",
  FiNotRequired = "FiNotRequired",
  RequestFI = "RequestFI",
  Withdrawn = "Withdrawn",
  OfficerAssessment = "OfficerAssessment",
  AllocateToOfficer = "AllocateToOfficer",
  AllocateToOfficerPSAR = "AllocateToOfficerPSAR",
  AppealComplete = "AppealComplete",
  AmendApplication = "AmendApplication",
  SendReptAndRespLetterForApproval = "SendReptAndRespLetterForApproval",
  ApproveReportAndResponse = "ApproveReportAndResponse",
  ReportAndResponseNotApproved = "ReportAndResponseNotApproved",
  RequestAction = "RequestAction",
  PlansToComply = "PlansToComply",
  AmendPermit = "AmendPermit",
  ProponentDecision = "ProponentDecision",
  ApplicationComplete = "ApplicationComplete",
  Referral = "Referral",
  SendHearingSubmissionReportForApproval = "SendHearingSubmissionReportForApproval",
  RequestToBeHeard = "RequestToBeHeard",
  NotApprovePanelReport = "NotApprovePanelReport",
  SentToPanelHearing = "SentToPanelHearing",
  ApprovePanelReport = "ApprovePanelReport",
  PanelHearingDecision = "PanelHearingDecision",
  SendApprovedResponseReportLetterToRAAndApplicant = "SendApprovedResponseReportLetterToRAAndApplicant",
}

export const psarSubmitWithValidateActions: PSARSubmitActions[] = [
  PSARSubmitActions.FiNotRequired,
  PSARSubmitActions.ApproveReportAndResponse,
  PSARSubmitActions.ReportAndResponseNotApproved,
  PSARSubmitActions.ProponentDecision,
  PSARSubmitActions.Referral,
  PSARSubmitActions.SendReptAndRespLetterForApproval,
  PSARSubmitActions.SentToPanelHearing,
  PSARSubmitActions.ApplicationComplete,
  PSARSubmitActions.SendHearingSubmissionReportForApproval,
  PSARSubmitActions.RequestToBeHeard,
  PSARSubmitActions.ApprovePanelReport,
  PSARSubmitActions.NotApprovePanelReport,
  PSARSubmitActions.PanelHearingDecision,
  PSARSubmitActions.SendApprovedResponseReportLetterToRAAndApplicant,
  PSARSubmitActions.LodgeApplication,
  PSARSubmitActions.CreateAndLodgeApplication,
  PSARSubmitActions.WithdrawnApplication,
  PSARSubmitActions.AllocateToOfficer,
];

export interface IPSARApplicationParentSection {
  notification: IAppNotificationItemAddProps[];
}
