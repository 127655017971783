import { colContactRoles } from "@app/core/contacts/_id/components/child-screens/contact-roles/config";
import { Svc_Contact_ContactRole } from "@app/core/contacts/_id/components/child-screens/contact-roles/model";
import { eProductType } from "@common/stores/products/enum";
import { useCommonProductStore } from "@common/stores/products/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IContactCoreProps {
  contactRoleData: Svc_Contact_ContactRole[];
}

const nameOf = nameOfFactory<Svc_Contact_ContactRole>();
export const ContactRoles = observer(
  ({ contactRoleData }: IContactCoreProps) => {
    const { activeProductsMap } = useCommonProductStore();
    const isFlagCommunityCentral =
      activeProductsMap[eProductType.CommunityProperty]
        ?.Flag_CommunityCentral ?? false;

    const renderNamesColumn = (cols: IColumnFields[]) => {
      return cols.map((col: IColumnFields) => {
        if (col.field === nameOf("LookupRecord_ID")) {
          col.linkTo = undefined; // Clear linkTo
          // Add href if we have dataItem?.CommunityHubUrl
          col.href = (dataItem) => {
            if (dataItem?.CommunityHubUrl) {
              return {
                href: dataItem?.CommunityHubUrl,
                target: "_blank",
              };
            } else {
              return undefined;
            }
          };
        }
        return col;
      });
    };

    const contactRoleCol = useMemo(() => {
      if (isFlagCommunityCentral) {
        return colContactRoles;
      } else {
        return renderNamesColumn(colContactRoles);
      }
    }, [isFlagCommunityCentral]);

    return (
      <CCGrid
        primaryField={nameOf("LookupRecord_ID")}
        columnFields={contactRoleCol ?? []}
        data={contactRoleData ?? []}
      />
    );
  }
);
