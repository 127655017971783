import {
  ContactUpdateTriggers,
  Svc_Contact,
} from "@app/core/contacts/_id/model";
import { isEqual } from "lodash";

export const produceContactSaveTrigger = (
  newContact: Svc_Contact,
  oldContact: Svc_Contact | undefined
): Svc_Contact => {
  if (!oldContact) return newContact;
  newContact.SaveTriggers = [];
  if (!newContact.PreferredAddress) {
    newContact.SaveTriggers.push(ContactUpdateTriggers.ClearPrimaryAddress);
  } else {
    if (!isEqual(oldContact.PreferredAddress, newContact.PreferredAddress))
      newContact.SaveTriggers.push(ContactUpdateTriggers.UpdatePrimaryAddress);
  }
  if (!newContact.PostalAddress) {
    newContact.SaveTriggers.push(ContactUpdateTriggers.ClearSecondaryAddress);
  } else {
    if (!isEqual(oldContact.PostalAddress, newContact.PostalAddress))
      newContact.SaveTriggers.push(
        ContactUpdateTriggers.UpdateSecondaryAddress
      );
  }
  if (!newContact.AlternateAddress) {
    newContact.SaveTriggers.push(ContactUpdateTriggers.ClearOtherAddress);
  } else {
    if (!isEqual(oldContact.AlternateAddress, newContact.AlternateAddress))
      newContact.SaveTriggers.push(ContactUpdateTriggers.UpdateOtherAddress);
  }
  if (!isEqual(oldContact.Community_Group_Ids, newContact.Community_Group_Ids))
    newContact.SaveTriggers.push(ContactUpdateTriggers.UpdateCommunityGroup);
  return newContact;
};
