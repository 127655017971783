import {
  DATE_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colInterestRate: IColumnFields[] = [
  {
    field: "EffectiveFrom",
    title: "Effective From",
    format: DATE_FORMAT.DATE,
    editable: true,
    width: 180,
  },
  {
    field: "InterestRate",
    title: "Interest Rate",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: "PenaltyRate",
    title: "Penalty Rate",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: "Status",
    title: "Status",
  },
];
