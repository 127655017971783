import { HMPremisesInspectionScheduleView } from "@app/products/hm/insp-schedule/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket } from "@common/models/identityPacket";

export const getHMInspScheduleCalendarByOfficer = async (
  officerName?: string
): Promise<
  APIResponse<IIdentityPacket<HMPremisesInspectionScheduleView[]> | undefined>
> => {
  try {
    let requestOfficerName = officerName ?? "";
    return await CoreAPIService.getClient().post(
      "api/hm/internal/inspectionschedule/calendar",
      JSON.stringify(requestOfficerName)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
