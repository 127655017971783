import { postSendCommunication } from "@app/core/communication/buttons-list-view/components/buttons/api";
import { CommunicationDialog } from "@app/core/communication/dialogs/_index";
import { recipientTypesCommunicationDialog } from "@app/core/communication/dialogs/config";
import { ICommunication } from "@app/core/communication/dialogs/model";
import { DOCUMENTSTATUS } from "@app/core/documents/model";
import {
  FURTHERINFOSTATUS,
  FurtherInfoSubmitActions,
} from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { putSendLetterToRAandApplicant } from "@app/core/further-info/components/buttons/more/send-letter-to-RA-Applicant/api";
import { useSendLetterToRAandApplicantButtonStore } from "@app/core/further-info/components/buttons/more/send-letter-to-RA-Applicant/store";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { customLogger } from "@common/utils/logger";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const SendLetterToRAandApplicantButton = observer(() => {
  const { isShowDialog, setIsShowDialog, isLoading } =
    useSendLetterToRAandApplicantButtonStore();
  const { clearErrorNotification, pushNotification } =
    useCCAppNotificationStore();
  const { furtherInfo, reLoadFurtherInfo, furtherInfoId, onSubmit } =
    useFurtherInfoStore();

  const isVisible = useMemo(
    () => furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.InfoApproved,
    [furtherInfo]
  );

  const handleCommunicationSubmit = async (data: ICommunication) => {
    clearErrorNotification();
    const response = await postSendCommunication(
      data,
      RECORDTYPE.CORE_FurtherInformationRequest
    );
    if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
      if (furtherInfo?.FurtherInformationRequest_ID) {
        const resp = await putSendLetterToRAandApplicant(
          furtherInfo?.FurtherInformationRequest_ID
        );
        customLogger(
          "Core Further info, SendLetterToRAandApplicantButton, postSendCommunication"
        ).info(resp?.data);
        setIsShowDialog(false);
        if (resp.data && resp.data.ErrorStatus === "HasErrors") {
          clearErrorNotification();
          pushNotification({
            autoClose: false,
            type: "error",
            description: resp.data?.Errors,
          });
        } else {
          reLoadFurtherInfo().then(() => {
            pushNotification({
              type: "success",
              title:
                "The letter was sent to responsible authority and applicant successfully.",
            });
          });
        }
      }
    } else {
      setIsShowDialog(false);
      clearErrorNotification();
      pushNotification({
        autoClose: false,
        type: "error",
        description: response.data?.Errors,
      });
    }
  };

  return isVisible ? (
    <>
      <CCNavButton
        title={"Send letter to responsible authority and applicant"}
        isLoading={isLoading}
        disabled={isLoading}
        onClick={onSubmit}
        name={FurtherInfoSubmitActions.SendLetterToRAandApplicant}
      />
      {isShowDialog && (
        <CommunicationDialog
          productType={PRODUCT_TYPE_NUMBER.Core}
          recordType={RECORDTYPE.CORE_FurtherInformationRequest}
          recipientTypes={recipientTypesCommunicationDialog(
            RECORDTYPE.CORE_FurtherInformationRequest
          )}
          onClose={() => setIsShowDialog(false)}
          onSubmit={handleCommunicationSubmit}
          recordTypeAttachment={RECORDTYPE.CORE_FurtherInformationRequest}
          recordID={furtherInfoId}
          recordIdAttachment={furtherInfoId}
          documentStatus={DOCUMENTSTATUS.Final}
        />
      )}
    </>
  ) : null;
});
