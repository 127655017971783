import { LOOKUPNAME } from "@app/products/ams/constants/ams.lookups";
import { IAsset } from "@app/products/ams/model/asset";

export function initIAsset(): IAsset {
  return {
    id: 0,
    assetNumber: "",
    assetDescription: "",
    assetCategoryId: 0,
    equipmentNumber: "",
    serialNumber: "",
    assetStatusId: 0,
    designLife: 0,
    warrantyEndDate: new Date(),
    employeeCustodian: "",
    employeeNumber: "",
    costCentreId: 0,
    departmentId: 0,
    locationId: 0,
    osProductId: 0,
  };
}
export interface AmsAssetRegister {
  Id?: number | null;
  CatalogueId?: number | null;
  AssetNumber?: string;
  AssetDescription?: string;
  AssetCategoryId?: number | null;
  AssetStatusId?: number | null;
  DesignLife?: number | null;
  EquipmentNumber?: string;
  SerialNumber?: string;
  WarrantyEndDate?: Date | null;
  EmployeeCustodian?: string;
  EmployeeNumber?: string;
  DepartmentId?: number | null;
  LocationId?: number | null;
  CostCentreId?: number | null;
  OsProductId?: number | null;
  Comments?: string;
  Status?: string;
}
export type AmsAssetRegisterLookup = {
  [key in keyof AmsAssetRegister]: string;
};
export interface AmsLookup {
  lookup: LOOKUPNAME;
  id: number;
  lookupType: string;
  name: string;
  items: AmsLookupItem[];
}
export interface AmsLookupItem {
  id: number;
  description: string;
  active: boolean;
}
export const lookupList: string[] = [
  LOOKUPNAME.AssetCategory,
  LOOKUPNAME.AssetStatus,
  LOOKUPNAME.costCentre,
  LOOKUPNAME.department,
  LOOKUPNAME.osProduct,
  LOOKUPNAME.location,
  "AssetCategory",
];
