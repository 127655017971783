import { history } from "@/AppRoutes";
import {
  getBuildingRegistersCouncilConsentById,
  postBuildingRegistersCouncilConsent,
  putBuildingRegistersCouncilConsent,
} from "@app/products/building/registers/council-consent/[id]/api";
import { COUNCIL_CONSENT_MANAGE_ROUTE } from "@app/products/building/registers/council-consent/[id]/constant";
import { IBuildingRegistersCouncilConsentFormData } from "@app/products/building/registers/council-consent/[id]/model";
import { IBuilding } from "@app/products/building/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class BuildingRegistersCouncilConsentStore {
  private _councilConsent?: IBuildingRegistersCouncilConsentFormData =
    undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get councilConsent() {
    return this._councilConsent;
  }
  setCouncilConsent = (
    councilConsent: IBuildingRegistersCouncilConsentFormData | undefined
  ) => {
    runInAction(() => {
      this._councilConsent = councilConsent;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get councilConsentId() {
    return this._councilConsent?.ID;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._councilConsent = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadCouncilConsent = async (councilConsentId: number) => {
    this.setIsLoading(true);
    const response = await getBuildingRegistersCouncilConsentById(
      councilConsentId
    );
    let errorResponse, newCouncilConsent;

    if (isSuccessResponse(response)) {
      newCouncilConsent = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }

    this.setResponseLoadError(errorResponse);
    this.setCouncilConsent(newCouncilConsent);
    this.setIsLoading(false);
  };

  createBuildingRegistersCouncilConsent = async (building: IBuilding) => {
    this.setIsLoading(true);
    await postBuildingRegistersCouncilConsent(building);

    appNotificationStore.pushNotification({
      title: "Building register council consent created successfully",
      type: "success",
      onClose: () => {
        history.replace(`${COUNCIL_CONSENT_MANAGE_ROUTE}/${building?.id ?? 1}`);
      },
    });
    this.setIsLoading(false);
  };

  updateBuildingRegistersCouncilConsent = async (building: IBuilding) => {
    this.setIsLoading(true);
    await putBuildingRegistersCouncilConsent(building);
    appNotificationStore.pushNotification({
      title: "Building register council consent updated successfully",
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const buildingRegistersCouncilConsentStoreContext = createContext(
  new BuildingRegistersCouncilConsentStore()
);
export const useBuildingRegistersCouncilConsentStore = () => {
  return useContext(buildingRegistersCouncilConsentStoreContext);
};
