import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { initialValuesForm } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/config";
import {
  ConfigUserGroupOrg,
  IDataUserGroupOrgForm,
} from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/model";

import { colCrmsUsersPick } from "@app/products/crms/components/dialogs/pick-users-groups/config";
import { ICrmsUsersPicker } from "@app/products/crms/components/dialogs/pick-users-groups/model";
import { CrmsUsersGrid } from "@app/products/crms/components/dialogs/pick-users-groups/users-picker/_index";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import React, { useState } from "react";
export const nameOfUsers = nameOfFactory<ICrmsUsersPicker>();
interface ICrmsPickUserGroupOrgDialogProps {
  handleSubmit: (value: IDataUserGroupOrgForm) => void;
  onClose: () => void;
  isNotHavePanel?: boolean;
  title?: string;
}

export const CrmsPickUserGroupOrgDialog = ({
  handleSubmit,
  onClose,
  isNotHavePanel = false,
  title = "Select Users, Groups and Org Structures",
}: ICrmsPickUserGroupOrgDialogProps) => {
  const [selectedUsers, setSelectedUser] = useState<ICrmsUsersPicker[]>([]);
  const [validForm, setValidForm] = useState(false);

  return (
    <div className="cc-form">
      <Form
        initialValues={initialValuesForm}
        onSubmit={(values) => handleSubmit(values as IDataUserGroupOrgForm)}
        render={({ onSubmit, onChange }: FormRenderProps) => {
          if (selectedUsers.length) {
            setValidForm(true);
          } else {
            setValidForm(false);
          }

          const handleChangeUsers = (data: ICrmsUsersPicker[]) => {
            if (data.length) {
              setSelectedUser(data);
              onChange(ConfigUserGroupOrg.Users, { value: data });
            } else {
              setSelectedUser([]);
              onChange(ConfigUserGroupOrg.Users, { value: [] });
            }
          };

          const listPanelBar: ITabProps[] = [
            {
              title: "Users",
              isExpanded: true,
              component: (
                <CrmsUsersGrid
                  handleSelectionChange={handleChangeUsers}
                  columnFields={colCrmsUsersPick}
                  primaryField={nameOfUsers("Id")}
                  selectableMode="multiple"
                  selectedRows={selectedUsers ?? []}
                />
              ),
            },
          ];
          return (
            <CCDialog
              height="auto"
              width="50%"
              unsetMinHeight={true}
              titleHeader={title}
              onClose={onClose}
              bodyElement={
                isNotHavePanel ? (
                  <CrmsUsersGrid
                    handleSelectionChange={handleChangeUsers}
                    columnFields={colCrmsUsersPick}
                    primaryField={nameOfUsers("Id")}
                    selectableMode="multiple"
                    selectedRows={selectedUsers ?? []}
                  />
                ) : (
                  <TabTable
                    recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
                    initialPanels={listPanelBar}
                  />
                )
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={onSubmit}
                    disabled={!validForm}
                  >
                    Add
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    </div>
  );
};
