import { eFileFormatData } from "./model";

export const IMPORT_FILE_FORM_STEP = "ImportFile";

export const ImportNOSFileRestrictions = {
  DEFAULT_MAX_FILE_SIZE: 26214400, //25MB
};

export const fileFormatData = [
  {
    Key: eFileFormatData.DAT,
    Value: ".DAT",
  },
  {
    Key: eFileFormatData.XML,
    Value: ".XML",
  },
];
