import {
  RadioButtonProps,
  RadioGroup,
  RadioGroupProps,
} from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { cloneDeep } from "lodash";
import React from "react";

interface ICCRadioGroupProps {
  validationMessage?: string | null;
  visited?: boolean;
  disabledOptions?: Array<RadioButtonProps>;
}

export const CCRadioGroup = ({
  value,
  onChange,
  disabledOptions,
  ...props
}: ICCRadioGroupProps & RadioGroupProps) => {
  const { validationMessage, visited, data, ...others } = props;
  let dataProcessed: Array<RadioButtonProps> | undefined = cloneDeep(data);
  if (disabledOptions && disabledOptions?.length) {
    const disabledValues = disabledOptions.map(
      (option: RadioButtonProps) => option.value
    );
    dataProcessed =
      dataProcessed?.map((option: RadioButtonProps) => ({
        ...option,
        disabled: disabledValues.includes(option.value),
      })) ?? undefined;
  }

  return (
    <>
      <RadioGroup
        {...others}
        value={value}
        onChange={onChange}
        data={dataProcessed ? dataProcessed : data}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
