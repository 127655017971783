import { isSuccessResponse } from "@common/apis/util";
import { DownloadFile } from "@common/constants/downloadfile";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { postExportActionTypeList } from "@common/pages/settings/lookups/action-types/components/action-bar/buttons/export/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { useSelectionListener } from "@components/cc-grid/hooks/useSelectionListener";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const actionTypeContentTypeExport =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

interface IExportActionTypesButtonProps {
  gridId?: string;
}

export const ExportActionTypesButton = observer(
  ({ gridId }: IExportActionTypesButtonProps) => {
    const { cancelToken } = useCancelRequest();
    const { pushNotification } = useCCAppNotificationStore();
    const { loader } = useSelectionListener(gridId);
    const { gridSelectedIds } = useCCProductListViewStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleOnClick = async () => {
      setIsLoading(true);
      let idsExport: number[] =
        gridSelectedIds?.length > 0 ? gridSelectedIds : [];
      if (loader.current.available) {
        idsExport = (
          await loader.current.process({ $select: ["ID"], $flat: true })
        ).data;
      }
      const response = await postExportActionTypeList(idsExport, cancelToken());
      setIsLoading(false);
      if (isSuccessResponse(response) && response?.data) {
        DownloadFile(
          response.data?.FileContent ?? "",
          response.data?.ContentType ?? actionTypeContentTypeExport,
          response.data?.FileName ?? "ExportActionType.xlsx"
        );
      } else {
        pushNotification({
          title: `Export action type failed.`,
          type: "error",
          autoClose: false,
        });
      }
    };
    return (
      <CCNavButton
        title="Export selected action types"
        onClick={handleOnClick}
        isLoading={isLoading}
        disabled={gridSelectedIds?.length === 0}
      />
    );
  }
);
