import { AssessmentDetailTabCommon } from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/common/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IAssessmentDetailTabProps {
  id?: string | number;
}

export const AssessmentDetailTab = observer(
  ({ id }: IAssessmentDetailTabProps) => {
    const { lastSelectedRow } = useCCProductListViewStore();
    const assessmentId: any = useMemo(() => {
      return id ?? lastSelectedRow?.Assessment_Id;
    }, [lastSelectedRow, id]);
    return <AssessmentDetailTabCommon id={assessmentId} />;
  }
);
