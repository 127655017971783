import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const RecordsFileITAmendmentForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const { valueGetter } = formRenderProps;
    const showPPRAmendment =
      valueGetter(
        ECorporateSettingsField.FileitAdmin_PPR_AmendmentInheritApplicationFileNumber.toString()
      ) === false;
    if (!configData) return <></>;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <SwitchGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .FileitAdmin_PPR_AmendmentInheritApplicationFileNumber
              ]
            }
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          {showPPRAmendment && (
            <>
              <InputGlobalSettings
                data={
                  configData[
                    ECorporateSettingsField
                      .CorporateSettings_Fileit_PPRAmendmentTitleFormat
                  ]
                }
                isEditing={isEditing}
              />
              <InputGlobalSettings
                data={
                  configData[
                    ECorporateSettingsField
                      .CorporateSettings_Fileit_PPRAmendmentTitleDescription
                  ]
                }
                isEditing={isEditing}
              />
            </>
          )}
        </div>
      </section>
    );
  }
);
