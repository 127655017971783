import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class ActionBatchUpdateButtonStore {
  private _isFirstCheckSuperAdmin: boolean = true;
  private _isShowDialog: boolean = false;
  private _isSuperAdmin: boolean = false;
  private _prevPathName: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  get isFirstCheckSuperAdmin() {
    return toJS(this._isFirstCheckSuperAdmin);
  }
  setIsFirstCheckSuperAdmin = (value: boolean) => {
    runInAction(() => {
      this._isFirstCheckSuperAdmin = value;
    });
  };

  get prevPathName() {
    return toJS(this._prevPathName);
  }
  setPrevPathName = (value: string) => {
    runInAction(() => {
      this._prevPathName = value;
    });
  };

  get isShowDialog() {
    return toJS(this._isShowDialog);
  }
  setIsShowDialog = (value: boolean) => {
    runInAction(() => {
      this._isShowDialog = value;
    });
  };

  get isSuperAdmin() {
    return toJS(this._isSuperAdmin);
  }
  setIsSuperAdmin = (value: boolean) => {
    runInAction(() => {
      this._isSuperAdmin = value;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isFirstCheckSuperAdmin = true;
      this._isSuperAdmin = false;
      this._isShowDialog = false;
      this._prevPathName = "";
    });
  };
}
export const actionBatchUpdateStore = new ActionBatchUpdateButtonStore();
const actionBatchUpdateStoreContext = createContext(actionBatchUpdateStore);
export const useActionBatchUpdateButtonStore = () => {
  return useContext(actionBatchUpdateStoreContext);
};
