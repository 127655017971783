import {
  DTO_Workflow_JournalAssessmentRaiseCharge,
  RequestJournalAssessmentRaiseChargeObj,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/model";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { WorkflowProcessMode } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postProcessRaiseCharges = async (
  workflowType: WorkflowProcessMode,
  holdingData: DTO_Workflow_JournalAssessmentRaiseCharge
): Promise<APIResponse<any>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/assessmentraisecharges/process/${workflowType}`,
      holdingData
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialRaiseCharges =
  | [
      APIResponse<DTO_Journal_LOVs>,
      APIResponse<DTO_Workflow_JournalAssessmentRaiseCharge>
    ]
  | APIResponse<DTO_Journal_LOVs | DTO_Workflow_JournalAssessmentRaiseCharge>
  | undefined;
export const getInitialRaiseCharges = async (
  workflowDraftId: number | undefined,
  assessmentId: number | undefined = 0,
  isNewJournal: boolean = false
): Promise<IGetInitialRaiseCharges> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_LOVs>(
        `api/property/internal/journal/lovs`
      ),
      !isNewJournal && !isNil(workflowDraftId)
        ? getWorkflowRaiseCharges(workflowDraftId)
        : getNewWorkflowRaiseCharges({
            AssessmentId: assessmentId,
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowRaiseCharges = async (
  requestObj: RequestJournalAssessmentRaiseChargeObj | object = {}
): Promise<APIResponse<DTO_Workflow_JournalAssessmentRaiseCharge>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/assessmentraisecharges/new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowRaiseCharges = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_JournalAssessmentRaiseCharge>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/assessmentraisecharges/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
