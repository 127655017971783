import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { VO_ACT_Lease_Transfer } from "@app/products/property/changes-of-ownership/lease-transfers/model";

const nameOf = nameOfFactory<VO_ACT_Lease_Transfer>();
export const colListToBeProcessed: IColumnFields[] = [
  {
    field: nameOf("ALT_Suburb_Code"),
    title: "Suburb",
  },
  {
    field: nameOf("ALT_Section"),
    title: "Section",
  },
  {
    field: nameOf("ALT_Block"),
    title: "Block",
  },
  {
    field: nameOf("ALT_Unit"),
    title: "Unit",
  },
  {
    field: nameOf("Property_Name_Address_Locality"),
    title: "Property Address",
  },
  {
    field: nameOf("ALT_Agreement_For_Sale_Date"),
    title: "Agreement Date",
  },
  {
    field: nameOf("ALT_Settlement_Date"),
    title: "Settlement Date",
  },
  {
    field: nameOf("Assessment_Group_Name"),
    title: "Assessment Group",
  },
  {
    field: nameOf("ACT_Lease_Transfer_Document_Type"),
    title: "Document Type",
  },
  {
    field: nameOf("ALT_Document_Number"),
    title: "Document Number",
  },
  {
    field: nameOf("ALT_Number_of_Warnings"),
    title: "Number of Warnings",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("ALT_Number_of_Errors"),
    title: "Number of Errors",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("ALT_Prevent_Update"),
    title: "Prevent Update",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("ALT_Is_Cancelled"),
    title: "Is Cancelled",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("ALT_Is_Suspended"),
    title: "Is Suspended",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("ALT_Comments"),
    title: "Comments",
  },
  {
    field: nameOf("ALT_Consideration"),
    title: "Consideration",
  },
  { field: nameOf("ALT_Volume_Number"), title: "Volume" },
  { field: nameOf("ALT_Folio_Number"), title: "Folio" },
  { field: nameOf("Current_Owner"), title: "Current Owner" },
  { field: nameOf("ALT_Previous_Owner_Name"), title: "Previous Owner Name" },
  {
    field: nameOf("ALT_Previous_Settlement_Date"),
    title: "Previous Owner Settlement Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment Id",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("ALT_Modified_On"),
    title: "Modified On",
    format: DATETIME_FORMAT.DATETIME,
  },
  { field: nameOf("ALT_Modified_By"), title: "Modified By" },
  {
    field: nameOf("ACT_LTO_Import_Id"),
    title: "LTO Import Id",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("ACT_Lease_Transfer_Id"),
    title: "Lease Transfer Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
