import { ConfirmVerifyReadingsDialog } from "@app/products/property/meters/last-reading-details/components/dialogs/confirm-verify-readings/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const VerifyReadingButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { gridSelectedRows } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();

  return (
    <React.Fragment>
      <CCNavButton
        title="Verify Readings"
        disabled={gridSelectedRows.length <= 0}
        onClick={() => {
          setIsShowDialog(true);
        }}
      />
      {isShowDialog && (
        <ConfirmVerifyReadingsDialog
          onClosePopup={() => {
            setIsShowDialog(false);
          }}
          onConfirmYes={() => {
            setIsShowDialog(false);
            pushNotification({
              title: "Verify selected readings successfully",
              type: "success",
            });
          }}
        />
      )}
    </React.Fragment>
  );
});
