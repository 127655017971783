import { DirectDebitSessionHeader } from "@app/products/direct-debit/sessions/model";

export const ddSessionsBookmark = (viewName: string) => {
  return {
    getBookmarkListViewDisplayName() {
      return "Direct Debit - Sessions";
    },

    getBookmarkRecordDisplayName() {
      return "Direct Debit - Session";
    },

    getBookmarkListViewDetail() {
      return `Direct Debit - Sessions - ${viewName}`;
    },

    getBookmarkListViewDetailRecord(selectedRow: DirectDebitSessionHeader) {
      const dynamicDisplayName = selectedRow?.Session_ID
        ? `- ${selectedRow.Session_ID}`
        : "";
      return `Direct Debit - Session ${dynamicDisplayName}`;
    },
  };
};
