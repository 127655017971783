import React from "react";
import "./_index.scss";

export interface IImageCardNewProps {
  handleAddImage: () => void;
}

export const ImageCardNew = ({ handleAddImage }: IImageCardNewProps) => {
  return (
    <>
      <section className="cc-field-group cc-image-card-container">
        <div className="cc-form-cols-1 cc-custom-col">
          <div className="cc-image-upload-container">
            <div className="cc-image-upload">
              <div className="cc-image-upload-control">
                <i
                  className="fa fa-plus-circle icon-plus fa-xs"
                  onClick={handleAddImage}
                  title="Add Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
