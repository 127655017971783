import { DeleteButton } from "@app/core/delete/buttons/_index";
import { FinanceFeeDetailTab } from "@app/core/fees/[id]/components/reference-sidebar/details/_index";
import { FinanceFeeHistoryTab } from "@app/core/fees/[id]/components/reference-sidebar/history/_index";
import { FINANCE_FEE_MANAGE_ROUTE } from "@app/core/fees/[id]/constant";
import { AnimalActionBarNavDropdown } from "@app/products/animals/components/action-bar/nav-dropdown/_index";
import { getAnimalsFinanceListViewOdata } from "@app/products/animals/finance/constant";
import { AnimalFinanceFeesAllBookmark } from "@app/products/animals/finance/fees-all/util";
import {
  AnimalFinanceViewType,
  Svc_Animals_Finance,
} from "@app/products/animals/finance/model";
import { animalRoute } from "@app/products/animals/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { financeRoute } from "../route";
import { colAnimalsFinanceFeesAll } from "./config";

const nameOf = nameOfFactory<Svc_Animals_Finance>();

export default () => {
  useCCListViewActionBar({
    title: animalRoute.name,
    leftComponents: [
      <AnimalActionBarNavDropdown category={financeRoute.path} />,
    ],
    centerComponents: [<DeleteButton recordType={RECORDTYPE.CORE_Fees} />],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={FINANCE_FEE_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_Fees}
        detail={AnimalFinanceFeesAllBookmark.getBookmarkListViewDisplayName}
        displayName={
          AnimalFinanceFeesAllBookmark.getBookmarkListViewDetailRecord
        }
        listViewDetail={AnimalFinanceFeesAllBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          AnimalFinanceFeesAllBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <FinanceFeeDetailTab /> },
      {
        title: "History",
        component: <FinanceFeeHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colAnimalsFinanceFeesAll}
      dataUrl={getAnimalsFinanceListViewOdata(AnimalFinanceViewType.FeesAll)}
      primaryField={nameOf("ID")}
      state={{
        sort: [{ field: nameOf("ReferenceNumber"), dir: "asc" }],
      }}
    />
  );
};
