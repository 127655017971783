import { DTO_Session_GL_Postings } from "@app/products/property/journals/session-list/[id]/components/child-screens/general-ledger-postings/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getGeneralLedgerPostings = async (
  sessionId: number | string
): Promise<APIResponse<DTO_Session_GL_Postings | undefined>> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Session_GL_Postings>(
        `/api/property/int/session/${sessionId}/glpostings`
      );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
