import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DTO_DynamicQuestionnaireResponses } from "@app/products/property/certificates/[id]/components/child-screens/responses/model";

export const getCertificateResponses = async (
  componentNumber: number,
  certificateId: number | string
): Promise<APIResponse<DTO_DynamicQuestionnaireResponses>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/int/questionnaire/${componentNumber}/${certificateId}/responses`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
