import { PremisesClosureWorkflowRequest } from "@app/products/hm/premises/[id]/components/dialogs/close-premises/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const postHMClosePremises = async (
  data: PremisesClosureWorkflowRequest,
  cancelToken?: CancelToken
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `api/hm/internal/premises/closure`,
      data,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
