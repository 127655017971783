import { UITownPlanning } from "@app/products/town-planning/model";
import { IRejectApplicationDialogData } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reject-application/model";
import { RejectApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reject-application/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putRejectReportResponseLetter } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/report-and-response-not-approved/api";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const ReportAndResponseNotApprovedButton = observer(() => {
  const { ppr, onSubmit, pprId, setPPRWithLoading } = usePPRStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.ResponseReportLetterNotApproved.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const isVisible = useMemo(() => {
    return Application_Status.ReadyForDecision === ppr?.Status_ENUM;
  }, [ppr]);

  const handleSubmit = async (id?: number, comment?: string) => {
    if (!id) return;
    setIsLoading(true);
    const response = await putRejectReportResponseLetter(id, comment);
    setIsShowDialog(false);
    if (isSuccessIdentityPacket(response)) {
      appNotificationStore.clearErrorNotification();
      setPPRWithLoading(response.data.Application);
      appNotificationStore.pushNotification({
        type: "success",
        title: "Application rejected.",
      });
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: "Reject application failed",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        },
      ]);
    }
    setIsLoading(false);
  };
  return isVisible ? (
    <>
      <CCNavButton
        title="Reject report/response"
        onClick={onSubmit}
        isLoading={isLoading}
        name={PPRSubmitActions.ReportAndResponseNotApproved}
      />

      {isShowDialog && (
        <RejectApplicationDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={(value: IRejectApplicationDialogData) => {
            handleSubmit(pprId, value.Comment);
          }}
          isSubmitLoading={isLoading}
          notifications={notifications}
        />
      )}
    </>
  ) : null;
});
