import { UITownPlanning } from "@app/products/town-planning/model";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { ReasonDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/_index";
import { ISendForApproval } from "@app/products/town-planning/ppr/components/action-bar/dialogs/send-for-approval/model";
import { SendForApprovalDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/send-for-approval/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { useSendForTLApprovalButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-for-tl-approval/store";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const SendForTLApprovalButton = observer(() => {
  const { ppr, onSubmit, pprId } = usePPRStore();
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const {
    isLoading,
    selectedDocumentIDs,
    isShowCommentDialog,
    setSelectedDocumentIDs,
    setIsShowCommentDialog,
    handleSendForTLApproval,
    setIsLoading,
    setNotifications,
  } = useSendForTLApprovalButtonStore();

  const objReason = useMemo(() => {
    const retVal = new ProvideReason();
    retVal.Title_Text = "Approval Notes";
    retVal.ReasonLabel_Text = "Comments";
    retVal.Flag_ShowDate = false;
    retVal.Reason = "";
    return retVal;
  }, []);

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.PlansToComplySendForApprovalNotes.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const isVisible = useMemo(() => {
    if (
      ppr?.Status_ENUM === Application_Status.Lodged &&
      ppr?.Flag_PlansToComply
    ) {
      return true;
    }

    return false;
  }, [ppr]);

  const handleSelectDocumentSubmit = (data: ISendForApproval) => {
    setSelectedDocumentIDs(data.SelectedDocumentIDs);
    setIsShowDialog(false);
    setNotifications([]);
    setIsShowCommentDialog(true);
  };

  const handleProvideReasonSubmit = (
    value: ProvideReason,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    handleSendForTLApproval(
      notificationRef,
      pprId,
      selectedDocumentIDs,
      value.Reason
    );
  };

  return (
    <>
      {isVisible ? (
        <CCNavButton
          title="Send for delegated approver approval"
          onClick={onSubmit}
          isLoading={isLoading}
          name={PPRSubmitActions.SendForTLApproval}
        />
      ) : null}

      {isShowDialog && (
        <SendForApprovalDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleSelectDocumentSubmit}
          parentData={{
            Id: pprId,
            recordType: RECORDTYPE.TP_PPRApplication,
          }}
        />
      )}

      {isShowCommentDialog && (
        <ReasonDialog
          onClose={() => setIsShowCommentDialog(false)}
          onSubmit={handleProvideReasonSubmit}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          initialData={objReason}
        />
      )}
    </>
  );
});
