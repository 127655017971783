import { ICombineAttachmentDialog } from "@app/core/view/components/dialogs/view-documents/components/dialogs/combine-attachment/model";
import { IViewAttachments } from "@app/core/view/components/dialogs/view-documents/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React from "react";

interface ICombineAttachmentDialogProps {
  isLoading?: boolean;
  isCombining?: boolean;
  notifications?: IAppNotificationItem[];
  attachmentInfo: IViewAttachments[] | undefined;
  onSubmit: (combinedFileName: string) => void;
  onClose: () => void;
}
export const CombineAttachmentDialog = ({
  isLoading = false,
  isCombining = false,
  notifications,
  attachmentInfo,
  onClose,
  onSubmit,
}: ICombineAttachmentDialogProps) => {
  const nameOf = nameOfFactory<ICombineAttachmentDialog>();

  return (
    <Form
      initialValues={attachmentInfo}
      key={JSON.stringify(attachmentInfo)}
      onSubmitClick={(event: FormSubmitClickEvent) => {
        onSubmit(event.values.CombinedFileName);
      }}
      render={({ onSubmit, valid }: FormRenderProps) => {
        return (
          <CCDialog
            titleHeader={"Combined File Name"}
            height={"auto"}
            maxWidth={"25%"}
            onClose={onClose}
            disabled={isCombining}
            bodyElement={
              isLoading ? (
                <Loading isLoading={isLoading} />
              ) : (
                <FormElement className="cc-form">
                  <CCLocalNotification defaultNotifications={notifications} />
                  <section className="cc-field-group">
                    <div>
                      <p>File(s) listed below to be combined into one PDF</p>
                      <div>
                        {attachmentInfo?.map((item: IViewAttachments) => (
                          <p> - {item?.FileName}</p>
                        ))}
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Please specify the combined file name
                          <CCTooltip type="validator" />
                        </label>
                        <Field
                          name={nameOf("CombinedFileName")}
                          component={CCInput}
                          validator={requiredValidator}
                        />
                      </div>
                    </div>
                  </section>
                </FormElement>
              )
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={onSubmit}
                  disabled={!valid}
                  iconClass={isCombining ? "fas fa-spinner fa-spin" : ""}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
