import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { AddressLovs } from "@common/input-pickers/address/model";

export const getAddressLovs = async (): Promise<
  APIResponse<AddressLovs | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<AddressLovs | undefined>(
      `/api/core/internal/getaddresslovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
