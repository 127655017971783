import { IRouteStateForm } from "@common/pages/settings/system-admin/global-settings/_id/main/routeState";

export const inspectionsRouteStateGlobalSettings: IRouteStateForm[] = [
  {
    name: "Inspection_InspectionSettings",
    component: require("./forms/inspection-settings").default,
  },

  {
    name: "Inspection_NonComplyingScores",
    component: require("./forms/non-complying-scores").default,
  },
  {
    name: "Inspection_InspectionExportSettings",
    component: require("./forms/inspection-export-settings").default,
  },
];
