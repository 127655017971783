import { isSuccessResponse } from "@common/apis/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { getGenerateNewKey } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/security/forms/web-service-security-settings/api";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { NoData } from "@components/no-data/NoData";
import { Button } from "@progress/kendo-react-buttons";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";
export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  //store
  const { getConfigDataField } = useSettingGlobalStore();
  const {
    isEditing,
    isLoadingButtonGenerateKey,
    setIsLoadingButtonGenerateKey,
  } = useSettingGlobalManageStore();
  const { pushNotification } = useCCAppNotificationStore();
  //props
  const { valueGetter, onChange } = formRenderProps;

  const fieldAPIKey = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_APIKey
  );

  /**
   * Function generate new api key
   * call api return string
   */
  const handleGenerateNewKey = async () => {
    setIsLoadingButtonGenerateKey(true);
    const responseAPIKey = await getGenerateNewKey();
    if (isSuccessResponse(responseAPIKey)) {
      onChange(fieldAPIKey?.FieldName ?? "", {
        value: responseAPIKey?.data ?? "",
      });
      pushNotification({
        title: `New API key generated successfully`,
        type: "success",
      });
    } else {
      onChange(fieldAPIKey?.FieldName ?? "", { value: "" });
      pushNotification({
        title: `Error occurred`,
        autoClose: false,
        type: "error",
      });
    }
    setIsLoadingButtonGenerateKey(false);
  };

  if (!fieldAPIKey) return <NoData vertical />;
  return (
    <div className="cc-sll-settings">
      <div className="cc-form-cols-3">
        {fieldAPIKey && (
          <>
            <div className="cc-field">
              <CCLabel
                title={fieldAPIKey?.Title ?? ""}
                isMandatory={fieldAPIKey?.IsMandatory}
              />
              <Field
                name={fieldAPIKey?.FieldName}
                checked={valueGetter(fieldAPIKey?.FieldName)}
                component={CCInput}
                onChange={(e: InputChangeEvent) => {
                  onChange(fieldAPIKey?.FieldName, {
                    value: e.target.value ? e.target.value : null,
                  });
                }}
                validator={
                  fieldAPIKey?.IsMandatory ? requiredValidator : undefined
                }
                disabled={!isEditing}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">&nbsp;</label>
              <div>
                <Button
                  type="button"
                  onClick={handleGenerateNewKey}
                  disabled={isLoadingButtonGenerateKey || !isEditing}
                >
                  {isLoadingButtonGenerateKey ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : null}
                  Generate New Key
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});
