import { ICCRoute } from "@common/constants/ICCRoute";

export const poolsOrSpasRenewalsRoute: ICCRoute = {
  path: "pools-or-spas-renewals",
  name: "Pools / Spas Renewals",
  children: [
    {
      path: "all-by-due-status",
      name: "All By Due Status",
      component: require("./all-by-due-status/_index").default,
    },
    {
      path: "requires-renewal-fee",
      name: "Requires Renewal Fee",
      component: require("./requires-renewal-fee/_index").default,
    },
    {
      path: "requires-notice",
      name: "Requires Notice",
      component: require("./requires-notice/_index").default,
    },
    {
      path: "notice-sent",
      name: "Notice Sent",
      component: require("./notice-sent/_index").default,
    },
  ],
};
