import { Svc_SamplesAccordion } from "@app/core/samples/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_SamplesAccordion>();
export const colSamples: IColumnFields[] = [
  {
    field: nameOf("DateSampled"),
    title: "Sampled",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Number"),
    title: "ID",
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Officer"),
    title: "Officer",
  },
  {
    field: nameOf("Reason"),
    title: "Reason",
  },
];
