import { SiteUserView } from "@common/pages/settings/security/site-users/model";
import { SITE_USER_ROUTE } from "@common/pages/settings/security/site-users/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<SiteUserView>();

export const colSiteUsersBySecurityTemplate: IColumnFields[] = [
  {
    field: nameOf("UserName"),
    title: "Login Name",

    locked: true,
    linkTo: (dataItem) => {
      return `${SITE_USER_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: nameOf("FirstName"),
    title: "First Name",
  },
  {
    field: nameOf("LastName"),
    title: "Last Name",
  },
  {
    field: nameOf("Email"),
    title: "Email",
  },
  {
    field: nameOf("SecurityTemplates"),
    title: "Security Templates",
  },
  {
    field: nameOf("IsActive"),
    title: "Active",
  },
  {
    field: nameOf("IsLocked"),
    title: "Locked",
  },
];
