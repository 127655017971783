import { eventEmitter } from "@/App";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { postImportXML } from "@common/pages/report/integrated-reports/component/buttons/import/api";
import { ImportReportDialog } from "@common/pages/report/integrated-reports/component/dialogs/import/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ImportButton = observer(() => {
  const [isShowImportDialog, setIsShowImportDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const { pushNotification } = useCCAppNotificationStore();
  const handleOnSubmit = async (data: any) => {
    setIsLoading(true);
    const response = await postImportXML(data.FileData);
    if (isSuccessIdentityPacket(response)) {
      eventEmitter.emit(CCGridEventType.RefreshOData);
      setIsLoading(false);
      // TODO: wait for API to be fixed
      setIsShowImportDialog(false);
      pushNotification({
        title: `Report imported successfully`,
        type: "success",
      });
    } else {
      setIsLoading(false);
      setIsError(response.data?.Message ?? "Importing report failed");
    }
  };
  return (
    <>
      <CCNavButton
        title="Import"
        onClick={() => {
          setIsError("");
          setIsShowImportDialog(true);
        }}
      />
      {isShowImportDialog && (
        <ImportReportDialog
          onSubmit={handleOnSubmit}
          onClose={() => setIsShowImportDialog(false)}
          isLoadingButton={isLoading}
          isErrorAPI={isError}
        />
      )}
    </>
  );
});
