import {
  apiCoreGetNoticeTypesById,
  apiCoreGetNoticeTypesLovs,
  apiCorePostNoticeTypes,
} from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket } from "@common/models/identityPacket";
import {
  NoticeType,
  NoticesTypeLovs,
} from "@common/pages/settings/lookups/notice-types/_id/model";

export const getNoticeTypeById = async (
  id: number
): Promise<APIResponse<IIdentityPacket<NoticeType> | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IIdentityPacket<NoticeType>>(
      apiCoreGetNoticeTypesById(id)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNoticeType = async (
  requestBody: NoticeType
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post<NoticeType>(
      apiCorePostNoticeTypes(),
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNoticeTypeLovs = async (): Promise<
  APIResponse<IIdentityPacket<NoticesTypeLovs> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<NoticesTypeLovs>
    >(apiCoreGetNoticeTypesLovs());
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
