import { useFurtherInfoDetailTabStore } from "@app/core/further-info/[id]/components/reference-sidebar/detail/store";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { RECORDTYPE } from "@common/constants/recordtype";
import { Svc_BubbleupIdentifier } from "@common/pages/actions/[id]/components/reference-sidebar/details/model";
import { formatDisplayValue } from "@common/utils/formatting";
import { getPageLinkByRecordType } from "@common/utils/getPageLinkByRecordType";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

export const FurtherInfoDetailTab = observer(() => {
  const {
    loadFurtherInfoDetailTab,
    furtherInfoDetailTab,
    furtherInfoBubbleUpParent,
    furtherInfoBubbleUpAncestor,
    isLoading,
  } = useFurtherInfoDetailTabStore();
  const { lastSelectedRow } = useCCProductListViewStore();
  const { furtherInfo } = useFurtherInfoStore();
  const lastSelectedRowId: number = lastSelectedRow?.ID;

  const isVisiblePPRDetails: boolean = useMemo(() => {
    if (!furtherInfoDetailTab) return false;
    return (
      furtherInfoDetailTab?.Ancestor?.RecordSourceIdentifier
        ?._RecordSourceType_ENUM === RECORDTYPE.TP_PPRApplication
    );
  }, [furtherInfoDetailTab]);

  useEffect(() => {
    const furtherInfoId =
      lastSelectedRowId ?? furtherInfo?.FurtherInformationRequest_ID;
    if (furtherInfoId) {
      loadFurtherInfoDetailTab(furtherInfoId);
    }
    //eslint-disable-next-line
  }, [lastSelectedRowId, furtherInfo]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!furtherInfoDetailTab) return <NoData />;

  const getBubbleupValue = (bubbleUpObj: Svc_BubbleupIdentifier) => {
    return (
      <Link
        className="cc-detail-tab-link"
        to={{
          pathname: getPageLinkByRecordType(
            bubbleUpObj.RecordType_ENUM,
            bubbleUpObj.RecordID
          ),
        }}
      >
        {bubbleUpObj.RecordNumber}
      </Link>
    );
  };
  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title="Further information ID:"
              value={furtherInfoDetailTab?.FurtherInformationRequest_ID.toString()}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title="Application ID:"
              value={furtherInfoDetailTab?.Parent_ID}
              primary
            />
            <ProductReferenceRow
              title="Status:"
              value={furtherInfoDetailTab?.StatusName}
              success
            />
            {furtherInfoDetailTab.Ancestor.lblParent1 !== "" && (
              <>
                <ProductReferenceRow
                  title={`${furtherInfoDetailTab.Ancestor.lblParent1}:`}
                  value={sanitizeHtml(furtherInfoDetailTab.Ancestor.litParent1)}
                />
                <ProductReferenceRow
                  title={`${furtherInfoDetailTab.Ancestor.lblParent2}:`}
                  value={sanitizeHtml(furtherInfoDetailTab.Ancestor.litParent2)}
                />
                <ProductReferenceRow
                  title={`${furtherInfoDetailTab.Ancestor.lblParent3}:`}
                  value={sanitizeHtml(furtherInfoDetailTab.Ancestor.litParent3)}
                />
              </>
            )}
            {isVisiblePPRDetails && (
              <ProductReferenceRow
                title={`${furtherInfoDetailTab.Ancestor.lblParent4}:`}
                value={sanitizeHtml(furtherInfoDetailTab.Ancestor.litParent4)}
              />
            )}
            {furtherInfoBubbleUpAncestor && (
              <ProductReferenceRow
                title={furtherInfoBubbleUpAncestor.RecordTypeName}
                value={getBubbleupValue(furtherInfoBubbleUpAncestor)}
              />
            )}
            {furtherInfoBubbleUpParent && (
              <ProductReferenceRow
                title={furtherInfoBubbleUpParent.RecordTypeName}
                value={getBubbleupValue(furtherInfoBubbleUpParent)}
              />
            )}
          </ProductReferenceBlock>
        </PanelBarItem>
        {/* Date information panel bar */}
        <PanelBarItem
          title={<ProductReferenceHeading title="Date information:" primary />}
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title="Created:"
              value={formatDisplayValue(
                furtherInfoDetailTab?.CreatedDate,
                DATE_FORMAT.DATE
              )}
            />
            <ProductReferenceRow
              title="Requested:"
              value={formatDisplayValue(
                furtherInfoDetailTab?.DateRequested,
                DATE_FORMAT.DATE
              )}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
