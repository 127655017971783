import { IssueDocumentDialog } from "@app/products/property/components/dialogs/issue-document/_index";
import { useDocumentIssuedStore } from "@app/products/property/components/grid/document-issued-grid/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface IIssueDocumentButtonProps {
  titleDialog?: string;
  titleButton?: string;
  disabled?: boolean;
  reloadManagePage?: (notification?: IAppNotificationItemAddProps) => void;
}
export const IssueDocumentButton = observer(
  ({
    titleDialog,
    titleButton,
    reloadManagePage,
    disabled,
  }: IIssueDocumentButtonProps) => {
    const { documentIssueSelectedRow } = useDocumentIssuedStore();
    const [isShowDialog, setIsShowDialog] = useState(false);

    return (
      <>
        <CCNavButton
          title={titleButton ?? "Issue document"}
          disabled={disabled ? disabled : isNil(documentIssueSelectedRow)}
          onClick={() => setIsShowDialog(true)}
        />
        {isShowDialog && (
          <IssueDocumentDialog
            onClose={() => setIsShowDialog(false)}
            title={titleDialog}
            reloadManagePage={reloadManagePage}
          />
        )}
      </>
    );
  }
);
