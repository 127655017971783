import { ISecondaryWorkflows } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-meter/components/form-elements/secondary-workflow/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { FieldArray } from "@progress/kendo-react-form";
import React from "react";
import { colSecondaryWorkflow } from "./config";

export const SECONDARY_WORKFLOW_FORM_STEP = "SecondaryWorkflow";

const nameOfGridData = nameOfFactory<ISecondaryWorkflows>();

export const SecondaryWorkflowFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  return (
    <section className="cc-field-group ">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCGrid
            data={getFieldValue("_option.Data.SecondaryWorkflows") || []}
            columnFields={colSecondaryWorkflow}
            primaryField={nameOfGridData("Key")}
            selectableMode="single"
            onSelectionChange={(event) => {
              if (event[0]?.Key) {
                onChange("SecondaryWorkflow.SelectedSecondaryWorkflow", {
                  value: event[0].Key,
                });
              } else {
                onChange("SecondaryWorkflow.SelectedSecondaryWorkflow", {
                  value: 0,
                });
              }
            }}
            selectedRows={[
              {
                Key: valueGetter("SecondaryWorkflow.SelectedSecondaryWorkflow"),
              },
            ]}
          />
        </div>
      </div>
    </section>
  );
};
