import { IMeterFormGeneral } from "@app/products/property/meters/[id]/model";

export const mockMeterFormGeneral: IMeterFormGeneral = {
  //Meter Details
  Id: 1,
  PropertyAddress: "5 Jones Road, Cannonvale",
  MeterNumber: "C013224",
  Category: "Whitsunday Water Usage (W1)",
  AccountNames: ", Proserpine",
  Make: "",
  Type: "Mains",
  Condition: "",
  Status: "Not in service",
  InstalledOn: new Date("22-Dec-2010"),
  NumberOfDials: 4,
  MeterSize: 25,
  AverageDaily: 0.7587,
  UnitOfMeasure: "kl",
  EstimatedUsageSinceLast: "423kl",
  //Reading Details
  RouteName: "Cannonvale 2",
  SequenceWithinRoute: "211500",
  AssignedReadingDevice: "PDA_ALL",
  DeviceAssignedTo: "Meter Reader 1",
  MeterLocation: "",
  ReadingInstructions: "",
  SpecialInstructions: "",
  PhoneBeforeReading: false,
  KeysAreRequired: false,
  ContactName: "",
  ContactPhone: "",
  EmailBeforeReading: false,
  EmailAddress: "",
  NextScheduledReadingDate: new Date("28-Oct-2020"),
  Reading: 4,
  //Last Reading
  ReadingDate: new Date("2-Jun-2020 7:24"),
  LastReading: 2480,
  Usage: 73,
  LRDStatus: "",
  Reader: "Meter Reader 1",
  Comment: "",
  NumberOfDays: 96,
  LRDAverageDaily: 0.7604,
  //Assessements Accounts Associated
  AssessmentsAccountsAssociated: [
    {
      Type: "Assessment",
      AssessmentNumber: "32160450",
      BillingGroup: "Standard Periodic Billing",
      UsageEntitlement: 100,
      UsagePercentage: 100,
      PropertyAddress: ",",
      Improvements: "DWELLING",
      PrimaryLandUse: "20-Single Unit Dwelling",
      AssessmentType: "Normal Assessment",
      Status: "Active",
      AssessmentId: 854,
    },
  ],
};
