import { eToAssessmentStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-transaction/components/form-elements/to-assessments/model";
import { useToAssessmentStepStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-transaction/components/form-elements/to-assessments/store";
import { assessmentValidator } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-transaction/components/form-elements/to-assessments/util";
import { INVALID_WORKFLOW_DRAFT_ID } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { DTO_MP_Assessment } from "@app/products/property/assessments/master-properties/components/form-steps/add-new-master-property/model";
import { AddAssessmentLookupDialog } from "@app/products/property/components/dialogs/add-assessment-lookup/_index";
import { getSearchAssessmentLookup } from "@app/products/property/components/dialogs/add-assessment-lookup/api";
import { eOptionSearchAssessmentLookup } from "@app/products/property/components/dialogs/add-assessment-lookup/config";
import {
  DTO_Assessment,
  fnt_Assessment_LookupResult,
} from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { colJournalAssessment } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/assessment-journal/config";
import { processCombineData } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { isSuccessResponse } from "@common/apis/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
export const TO_ASSESSMENT_FORM_STEP = "ToAssessment";

export const ToAssessmentsFormStep = (props: IFormStepElement) => {
  const {
    options: { isReadOnly = false },
  } = props;
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!isReadOnly ? assessmentValidator : undefined}
    />
  );
};
const nameOfAssessment = nameOfFactory<DTO_MP_Assessment>();
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    localNotificationRef,
    options = {
      isReadOnly: false,
      workflowDraftId: INVALID_WORKFLOW_DRAFT_ID,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const { toAssessmentStepLOVs } = useToAssessmentStepStore();
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const currentAssessments = getFieldValue("Assessments") ?? [];
    const selectedAssessments =
      getFieldValue("_option.AssessmentSelected") ?? [];
    const transactionAmount =
      getFieldValue("Transactions")?.[0]?.Amount ?? null;

    const [showAddAssessmentRangeDialog, setShowAddAssessmentRangeDialog] =
      useState(false);
    const [isShowConfirmDeletion, setIsShowConfirmDeletion] =
      useState<boolean>(false);

    //Get labels
    const [
      assessmentLabel,
      assessmentLowercaseLabel,
      assessmentNumberLabel,
      assessmentIDLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Assessment,
      ECustomColNameProperty.AssessmentLowercase,
      ECustomColNameProperty.AssessmentNumber,
      ECustomColNameProperty.AssessmentID,
    ]);

    const handleAddAssessments = async (
      assessmentList: fnt_Assessment_LookupResult[]
    ) => {
      setShowAddAssessmentRangeDialog(false);
      options?.setIsLoadingStep(true);
      const listAssessmentId = assessmentList.map((item) => item.Assessment_Id);
      onChange(nameOf("_option.Loading"), {
        value: eToAssessmentStep.Assessment,
      });
      const response = await getSearchAssessmentLookup({
        LookupKey: eOptionSearchAssessmentLookup.AssessmentId,
        LookupValue: listAssessmentId.toString(),
        Statuses: [0], //Fixed now (0: Active)
      });
      onChange(nameOf("_option.Loading"), { value: undefined });
      if (isSuccessResponse(response) && response?.data) {
        const resAssessment = response?.data?.Assessments ?? [];
        if (resAssessment?.length) {
          const previousAssessment = currentAssessments?.length
            ? [...currentAssessments]
            : [];
          const newData = processCombineData(
            previousAssessment,
            resAssessment,
            "Assessment_Id"
          );
          onChange(nameOf("Assessments"), {
            value: newData,
          });
        }
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${assessmentLabel} failed`,
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsLoadingStep(false);
    };

    const handleDelete = () => {
      const selectedIDs = selectedAssessments?.map(
        (assessment: DTO_Assessment) => assessment.Assessment_Id
      );
      const newAssessments = currentAssessments?.filter(
        (assessment: DTO_Assessment) =>
          !selectedIDs.includes(assessment.Assessment_Id)
      );
      onChange(nameOf("Assessments"), { value: newAssessments });
      onChange(nameOf("_option.AssessmentSelected"), { value: [] });
    };

    const canSelect = () => {
      if (!selectedAssessments) return false;
      return selectedAssessments?.length > 0;
    };

    const handleGridSelectionChange = (dataItem: any, field: string) => {
      if (dataItem) {
        onChange(nameOf(field), {
          value: dataItem,
        });
      }
    };

    const processColJournalAssessment = useMemo(() => {
      return colJournalAssessment.map((item: IColumnFields) => {
        switch (item.field) {
          case nameOfAssessment("Assess_Number"):
            return {
              ...item,
              title: assessmentNumberLabel ?? item.title,
            };
          case nameOfAssessment("Assessment_Id"):
            return {
              ...item,
              title: assessmentIDLabel ?? item.title,
            };
          default:
            return item;
        }
      });
    }, [assessmentNumberLabel, assessmentIDLabel]);

    return (
      <>
        <section className="cc-field-group">
          <CCLabel
            title={`${assessmentLabel}`}
            isMandatory
            isLoading={
              getFieldValue("_option.Loading") === eToAssessmentStep.Assessment
            }
            errorMessage={
              errors?.AssessmentTo?.length
                ? errors?.AssessmentTo.replace(
                    "{assessment}",
                    assessmentLowercaseLabel
                  )
                : undefined
            }
          />
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              <CCGrid
                data={getFieldValue("Assessments") || []}
                columnFields={processColJournalAssessment}
                primaryField={"Assessment_Id"}
                selectableMode={"multiple"}
                readOnly={options?.isReadOnly}
                onSelectionChange={(dataItem: any) => {
                  if (options?.isReadOnly) return;
                  handleGridSelectionChange(
                    dataItem,
                    "_option.AssessmentSelected"
                  );
                }}
                selectedRows={
                  selectedAssessments ? [...selectedAssessments] : undefined
                }
                onDataChange={(dataItem: DTO_Assessment[]) => {
                  onChange(nameOf("Assessments"), { value: dataItem });
                }}
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      className="cc-edit-field-button"
                      iconClass="fa fa-plus"
                      title={`Associate ${assessmentLowercaseLabel}(s)`}
                      disabled={
                        options?.isReadOnly || isNil(options?.workflowDraftId)
                      }
                      onClick={() => {
                        setShowAddAssessmentRangeDialog(true);
                      }}
                    />
                    <Button
                      iconClass="fas fa-minus"
                      title={`Un-associate ${assessmentLowercaseLabel}(s)`}
                      disabled={options?.isReadOnly || !canSelect()}
                      onClick={() => {
                        setIsShowConfirmDeletion(true);
                      }}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Transaction date</label>
              <Field
                name={nameOf("TransactionDate")}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
                disabled={
                  getFieldValue("IsOriginalTransactionDate") ||
                  options?.isReadOnly
                }
                component={CCDateTimePicker}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Use original transaction date</label>
              <Field
                name={nameOf("IsOriginalTransactionDate")}
                checked={getFieldValue("IsOriginalTransactionDate")}
                component={CCSwitch}
                disabled={options?.isReadOnly}
                onChange={(event: SwitchChangeEvent) => {
                  onChange(nameOf("IsOriginalTransactionDate"), {
                    value: event?.value,
                  });
                  if (event?.value) {
                    onChange(nameOf("TransactionDate"), { value: null });
                  }
                }}
              />
            </div>
          </div>
          {transactionAmount && transactionAmount > 0 ? (
            <>
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">Charge date</label>
                  <Field
                    name={nameOf("ChargeDate")}
                    format={DATETIME_FORMAT.DATETIME_CONTROL}
                    component={CCDateTimePicker}
                    disabled={options?.isReadOnly}
                  />
                </div>
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Rating period</label>
                  <Field
                    name={nameOf("RatingPeriodId")}
                    textField="Name"
                    dataItemKey="Code"
                    data={toAssessmentStepLOVs?.RatingPeriod ?? []}
                    placeholder="Type rating period"
                    component={CCSearchComboBox}
                    disabled={options?.isReadOnly}
                    isUseDefaultOnchange
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Levy" isMandatory />
                  <Field
                    name={nameOf("LevyId")}
                    textField="Name"
                    dataItemKey="Code"
                    validator={
                      !options?.isReadOnly ? requiredValidator : undefined
                    }
                    data={toAssessmentStepLOVs?.Levy ?? []}
                    placeholder="Type levy"
                    component={CCSearchComboBox}
                    disabled={options?.isReadOnly}
                    isUseDefaultOnchange
                  />
                </div>
              </div>
            </>
          ) : null}
        </section>
        {isShowConfirmDeletion && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={`Are you sure you wish to un-associate the selected ${assessmentLowercaseLabel}(s) from this journal?`}
            onClosePopup={() => {
              setIsShowConfirmDeletion(false);
            }}
            onConfirmYes={handleDelete}
          />
        )}
        {showAddAssessmentRangeDialog && (
          <AddAssessmentLookupDialog
            onClose={() => setShowAddAssessmentRangeDialog(false)}
            handleAddAssessment={handleAddAssessments}
          />
        )}
      </>
    );
  }
);
