import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IParentActionSection } from "@common/pages/actions/model";
import { ACTIONS_ROUTE } from "@common/pages/actions/[id]/constant";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

interface IAddPPRActionButton {
  id?: number;
  recordType: RECORDTYPE;
  bubbleUps?: BubbleUpIdentifier[];
  disabled?: boolean;
}

export const AddActionWithParentButton = ({
  id,
  recordType,
  bubbleUps,
  disabled = false,
}: IAddPPRActionButton) => {
  const history = useHistory();
  const { setParentBubbleUp } = useActionStore();

  return (
    <CCNavButton
      title={"Action"}
      onClick={() => {
        if (bubbleUps) {
          setParentBubbleUp(bubbleUps);
        }
        history.push(`${ACTIONS_ROUTE}/new`, {
          parent: {
            id,
            recordType,
          } as IParentActionSection,
        });
      }}
      disabled={disabled}
    />
  );
};
