import { RaiseInterestDialog } from "@app/products/property/schemes/components/dialogs/raise-interest/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const RaiseInterestButton = () => {
  const [showDialog, setShowDialog] = useState(false);

  const handleSubmit = () => {
    setShowDialog(false);
  };

  return (
    <>
      <CCNavButton title="Raise Interest" onClick={() => setShowDialog(true)} />
      {showDialog && (
        <RaiseInterestDialog
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};
