import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IConfirmReadingDialog {
  onClosePopup: () => void;
  onConfirmYes: () => void;
}

export const ConfirmReadingDialog = ({
  onClosePopup,
  onConfirmYes,
}: IConfirmReadingDialog) => {
  return (
    <ConfirmDialog
      width={"20%"}
      height={"auto"}
      title={"Confirm Reading"}
      message={"Are you sure Meter Reading is correct?"}
      subMessage={
        "The usage is greater than the estimated usage and a % margin. "
      }
      onClosePopup={onClosePopup}
      onConfirmYes={onConfirmYes}
    />
  );
};
