export interface DTO_Assessment {
  DebtRecoveryId: number;
  AssessmentId: number;
  AssessmentReference: string;
  AssessmentNumber: string;
  OwnerName: string;
  PropertyAddress: string;
  PropertyAddressLocality: string;
  InitialCharges: number | null;
  InitialInterest: number | null;
  CostIncurred: number | null;
  TotalDebt: number | null;
  DebtCostBalance: number | null;
  AssessmentBalance: number | null;
}

export interface DTO_DebtRecovery_Assessment {
  DebtRecoveryAssessment: DTO_Assessment[];
}

export interface DTO_DebtRecovery_Summary {
  DebtRecovery: DTO_DebtRecovery;
  DebtRecoveryDetail?: DTO_DebtRecovery_Detail;
}
export interface DTO_DebtRecovery {
  DebtRecoveryId: number;
  DebtRecoveryType: string;
  ResponsibleOfficer: string;
  ActiveFrom: Date | null;
  ActiveTo: Date | null;
  Status: string;
  CreatedOn: Date | null;
  CreatedBy: string;
  ChangedOn: Date | null;
  ChangedBy: string;
  RatePayer: string;
  SolicitorReference: string;
  FunctionalityRestrictions: DTO_FunctionalityRestriction[];
  Actions: DTO_DebtRecovery_Action[];
  Balance: DTO_Balance;
  Owners: DTO_Owner[];
}

export interface DTO_DebtRecovery_Balance {
  Type: BalanceTypesEnum;
  InitialDept: number;
  DebtBalances: number;
}

export enum BalanceTypesEnum {
  Charges = "Charges",
  Interest = "Interest",
  Cost = "Cost",
  Total = "Total",
}
export interface DTO_FunctionalityRestriction {
  FunctionalityName: string;
  IsRestricted: boolean | null;
  RestrictedFrom: Date | null;
  RestrictedTo: Date | null;
}

export interface DTO_DebtRecovery_Action {
  DebtRecoveryActionId: number;
  ActionsDateAndTime: Date;
  Type: string;
  Cost: number;
  DocumentIssuedBy: string;
  DocumentIssuedOn: Date | null;
  ModifiedOn: Date | null;
  ModifiedBy: string;
  CreatedOn: Date | null;
  CreatedBy: string;
}

export interface DTO_Balance {
  InitialDebtCharges: number | null;
  InitialDebtInterests: number | null;
  InitialDebtCosts: number | null;
  InitialDebtTotal: number | null;
  DebtbalanceCharges: number | null;
  DebtbalanceInterests: number | null;
  DebtbalanceCosts: number | null;
  DebtbalanceTotal: number | null;
}

export interface DTO_Owner {
  Name: string;
  Address: string;
  EntityId: number;
}

export interface DTO_DebtRecovery_Detail {
  DebtRecoveryId: number;
  DebtRecoveryType: string;
  Charges: number | null;
  Interests: number | null;
  Costs: number | null;
  Total: number | null;
  RatePayer: string;
}

export interface DebtRecoveryAction {
  DebtRecoveryActionId: number;
  ActionsDateAndTime?: Date;
  Cost: number;
  CreatedBy: string;
  CreatedOn?: Date;
  DocumentIssuedBy: string;
  DocumentIssuedOn?: Date;
  ModifiedBy: string;
  ModifiedOn?: Date;
  Type: string;
  Description?: string;
}
