import { EventManagementFieldMapping } from "@app/products/event-management/constant";
import { EVENT_MANAGEMENT_MANAGE_ROUTE } from "@app/products/event-management/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colEventManagementFinanceNotSentToFinance: IColumnFields[] = [
  {
    field: EventManagementFieldMapping.ReferenceNumber,
    title: "Reference Number",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => {
      return `${EVENT_MANAGEMENT_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: EventManagementFieldMapping.Description,
    title: "Event Description",
    width: 200,
  },
  {
    field: EventManagementFieldMapping.CreatedDate,
    title: "Recorded",
    width: 200,
    format: DATE_FORMAT.DATE,
  },
  { field: EventManagementFieldMapping.FeeType, title: "Type", width: 200 },
  {
    field: EventManagementFieldMapping.AmountIssued,
    title: "Issued",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: EventManagementFieldMapping.AmountOutstanding,
    title: "Outstanding",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: EventManagementFieldMapping.CostCode,
    title: "Cost Code",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: EventManagementFieldMapping.InvoiceNumber,
    title: "Invoice Number",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: EventManagementFieldMapping.DebtorNumber,
    title: "Debtor Number",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
