import { mockSchemeAccountMemos } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/memos/mock";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
import { colSchemeAccountMemos } from "./config";

const SchemeAccountMemos = () => {
  return (
    <div className="cc-scheme-account-memos">
      <CCGrid
        className="cc-scheme-account-memos"
        data={mockSchemeAccountMemos}
        columnFields={colSchemeAccountMemos}
        primaryField="MemoId"
      />
    </div>
  );
};

export default SchemeAccountMemos;
