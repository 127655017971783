import { getMetersDetail } from "@app/products/property/registers/[id]/components/child-screens/meters/components/detail/api";
import { colMetersDetail } from "@app/products/property/registers/[id]/components/child-screens/meters/components/detail/config";
import { IMetersDetail } from "@app/products/property/registers/[id]/components/child-screens/meters/components/detail/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const RegisterMetersDetails = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataMetersDetail, setDataMetersDetail] = useState<IMetersDetail[]>();

  const nameOf = nameOfFactory<IMetersDetail>();

  useEffectOnce(() => {
    setIsLoading(true);
    getMetersDetail().then((data) => {
      setDataMetersDetail(data);
      setIsLoading(false);
    });
  });
  return (
    <CCGrid
      isLoading={isLoading}
      data={dataMetersDetail}
      columnFields={colMetersDetail}
      primaryField={nameOf("UsageId")}
    />
  );
};
