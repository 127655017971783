import { putInfoReceived } from "@app/core/further-info/components/buttons/work-flow/info-received/api";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { customLogger } from "@common/utils/logger";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";

export interface IInfoReceived {
  DateInfoReceived: string | null;
}

export interface IInfoReceivedDialogProps {
  onClosePopup: () => void;
  onSubmit: (dataItem: IInfoReceived) => void;
}

export const InfoReceivedDialog = ({
  onClosePopup,
  onSubmit,
}: IInfoReceivedDialogProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const nameOf = nameOfFactory<IInfoReceived>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();

  const handleSubmit = async (event: FormSubmitClickEvent) => {
    const dateInfoReceived = event.values.DateInfoReceived;
    setIsLoading(true);
    const response = await putInfoReceived(+id, dateInfoReceived);
    customLogger("Core Further info, InfoReceivedDialog, putInfoReceived").info(
      response?.data
    );
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        onSubmit(dateInfoReceived);
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: "Further information could not be received.",
          type: "error",
          description: response.data?.Errors,
        });
      }
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title:
          response.statusText ?? "Further information could not be received.",
        type: "error",
      });
    }
    setIsLoading(false);
  };

  return (
    <Form
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => {
        const { onSubmit: onFormSubmit, modified, valid } = formRenderProps;
        return (
          <FormElement>
            <CCDialog
              maxWidth="35%"
              height="auto"
              disabled={isLoading}
              titleHeader={"Enter The Date"}
              onClose={onClosePopup}
              bodyElement={
                <div className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Further information received
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("DateInfoReceived")}
                          component={CCDatePicker}
                          validator={requiredValidator}
                          format={DATE_FORMAT.DATE_CONTROL}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    className={"cc-dialog-button"}
                    onClick={() => {
                      onClosePopup();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!modified || !valid}
                    themeColor="primary"
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    onClick={onFormSubmit}
                    className={"cc-dialog-button"}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
