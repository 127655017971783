import { IRecipientsRewrite } from "@app/core/communication/dialogs/components/form-elememts/to/model";
import { ContactMethodPreferred } from "@common/constants/enumerations";
import { find, result } from "lodash";

export const validatorToStep = (values: any) => {
  if (!values.UseSMS && !values.UsePreferred && !values.UseEmail) {
    return "Please choose options";
  }

  if (!values.SelectedToRows || values.SelectedToRows?.length <= 0) {
    return "Please select at least one type";
  }

  if (values?.ErrorCounter > 0) {
    return "Please enter valid email or mobile number";
  }
  return undefined;
};

export const recipientsAddRewrite = (
  data: any,
  parentIdRecipients: number,
  parentRecordType?: string
): IRecipientsRewrite => {
  return {
    _WorkPhone: data._WorkPhone,
    _HomePhone: data._HomePhone,
    _Fax: data._Fax,
    _Mobile: data.Mobile,
    Parent_ID: parentIdRecipients,
    Parent_RecordType: parentRecordType,
    Contact_ID: data.ID,
    Contact_RID: data.ID,
    Contact_FullName: data.DisplayName,
    ContactRelationshipType_Name: "Other",
    Email: data.Email,
    PreferredMethod_Enum: data.PreferredMethod_Enum,
    PreferredMethod_Name: data.PreferredMethod_Name,
    PreferredMethod_Formatted: data.PreferredMethod_Formatted,
    SendCopyToMe: false,
    SendCopyToMeBCC: false,
    selected: true,
    expanded: false,
    SendToEmail: true,
    SendToSMS: true,
  };
};

export const findSourceIdentifierForKey = (data: any, key: string) => {
  return result(
    find(data.SelectedRecords_Details[0]?._BubbleUps, {
      BubbleUpType_ENUM: "Parent",
    })?.SourceIdentifier,
    key
  );
};

export const isPhonePreferMethod = (preferMethod: ContactMethodPreferred) => {
  return (
    preferMethod === ContactMethodPreferred.Mobile ||
    preferMethod === ContactMethodPreferred.HomePhone ||
    preferMethod === ContactMethodPreferred.WorkPhone
  );
};
