import {
  MaskedTextBox,
  MaskedTextBoxProps,
} from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import React from "react";

interface ICCMaskedTextBoxProps {
  validationMessage?: string | null;
  visited?: boolean;
}

export const CCMaskedTextBox = (
  props: ICCMaskedTextBoxProps & MaskedTextBoxProps
) => {
  const { validationMessage, visited, ...others } = props;

  return (
    <>
      <MaskedTextBox {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
