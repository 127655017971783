import { ImportCentrelinkFileDialog } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/dialogs/import-centrelink-file/_index";
import { ImportCentrelinkFileMode } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/dialogs/import-centrelink-file/model";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { PrivilegeWrapperHideOnNoAccess } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const ImportRejectionButton = () => {
  const [showImportRejectionFileDialog, setShowImportRejectionFileDialog] =
    useState<boolean>(false);

  return (
    <>
      <PrivilegeWrapperHideOnNoAccess
        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
        privilegesEnum={EPrivilegesWorkflow.Centrelink_Import_Rejection}
      >
        <CCNavButton
          title="Import rejections"
          onClick={() => setShowImportRejectionFileDialog(true)}
        />
      </PrivilegeWrapperHideOnNoAccess>
      {showImportRejectionFileDialog && (
        <ImportCentrelinkFileDialog
          onClose={() => {
            setShowImportRejectionFileDialog(false);
          }}
          ImportFileMode={ImportCentrelinkFileMode.Rejection}
        />
      )}
    </>
  );
};
