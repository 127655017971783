import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { SearchComboBoxSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/search-combobox/_index";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const AssessmentChecklistForm = observer(() => {
  const { isEditing } = useSettingPPRManageStore();
  const { configData } = useSettingPPRStore();
  const data = useDataSetting(configData, [
    "TP_Assessment_UseChecklistDetails",
  ]);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <SearchComboBoxSettings
          data={data.TP_Assessment_UseChecklistDetails}
          isEditing={isEditing}
        />
      </div>
    </section>
  );
});
