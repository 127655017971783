import { IGridSummary } from "@app/products/property/assessments/financial-summaries/[id]/model";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IGridSummary>();

export const colFinancialSummaries: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Trans"),
    title: "Number of Transactions",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Charges"),
    title: "Number of Charges",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Interest"),
    title: "Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Total"),
    title: "Total",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
