import { useSessionTransactionStore } from "@app/products/property/journals/session-list/[id]/components/child-screens/transactions/store";
import { DTO_Transaction_Item } from "@app/products/property/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import Loading from "@components/loading/Loading";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const nameOf = nameOfFactory<DTO_Transaction_Item>();

export const SessionTransactionDetail = observer(
  ({ dataItem }: GridDetailRowProps) => {
    const { id } = useParams<{ id: string }>();
    const {
      itemsDetail,
      columnFieldsDetail,
      loadJournalItemDetail,
      resetTransactionDetail,
      isLoadingDetail,
    } = useSessionTransactionStore();

    useEffect(() => {
      if (!id) return;
      loadJournalItemDetail(parseInt(id), dataItem.Transaction_Id);
      return () => resetTransactionDetail();
    }, [
      id,
      dataItem.Transaction_Id,
      loadJournalItemDetail,
      resetTransactionDetail,
    ]);

    if (isLoadingDetail) return <Loading isLoading />;

    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Transaction allocation</label>
              <CCGrid
                data={itemsDetail ?? []}
                columnFields={columnFieldsDetail}
                primaryField={nameOf("ChargeBalancesId")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
