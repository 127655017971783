import { FURTHER_INFO_ROUTE } from "@app/core/further-info/[id]/constant";
import {
  ActionTypes,
  IPPRActions,
} from "@app/products/town-planning/ppr/actions/model";
import { ACTIONS_ROUTE } from "@common/pages/actions/[id]/constant";
import { APPEAL_ROUTE } from "@common/pages/appeal/_id/constant";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `PPR - Current Actions ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([selectedRow.ID, selectedRow.Address]);
};

export const getUrlAction = (dataItem: IPPRActions) => {
  switch (dataItem.Type) {
    case ActionTypes.FurtherInfoRequest:
      return `${FURTHER_INFO_ROUTE}/${dataItem.ID}`;
    case ActionTypes.Appeal:
      return `${APPEAL_ROUTE}/${dataItem.ID}`;
    default:
      return `${ACTIONS_ROUTE}/${dataItem.ID}`;
  }
};
