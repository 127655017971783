import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import {
  iconColumnViewConfigAlert,
  iconColumnViewConfigCallReturn,
  iconColumnViewConfigUrgent,
} from "@app/products/crms/config";
import { CRMSFieldMapping } from "@app/products/crms/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colHardRubbish: IColumnFields[] = [
  {
    field: CRMSFieldMapping.RefNo,
    title: "Reference Number",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => `${CRMS_ROUTE}/${dataItem.ID}`,
  },
  ...iconColumnViewConfigAlert,
  ...iconColumnViewConfigUrgent,
  {
    field: CRMSFieldMapping.Description,
    title: "Description",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Location,
    title: "Location",
    width: 150,
  },
  ...iconColumnViewConfigCallReturn,
  {
    field: CRMSFieldMapping.RequestedBy,
    title: "Contact",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Location_Suburb,
    title: "Suburb",
    width: 150,
  },
  {
    field: CRMSFieldMapping.DateTarget,
    title: "Target",
    width: 150,

    format: DATE_FORMAT.DATE,
  },
  {
    field: CRMSFieldMapping.CollectionDate,
    title: "Collection Date",
    width: 150,

    format: DATE_FORMAT.DATE,
  },
  {
    field: CRMSFieldMapping.DocSentDate,
    title: "Doc Sent Date",
    width: 150,

    format: DATE_FORMAT.DATE,
  },
  {
    field: CRMSFieldMapping.CollectionItems,
    title: "Collection Items",
    width: 150,
  },
  {
    field: CRMSFieldMapping.NumItems,
    title: "Number of Items",
    width: 150,

    format: DATE_FORMAT.DATE,
  },
  {
    field: CRMSFieldMapping.AdminOverride,
    title: "Admin Override",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Collected,
    title: "Collected",
    width: 150,
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: CRMSFieldMapping.Missed,
    title: "Missed",
    width: 150,
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: CRMSFieldMapping.Source,
    title: "Source",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Category,
    title: "Category",
    width: 150,
  },
  {
    field: CRMSFieldMapping.ContactDetails,
    title: "Contact Number",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Status,
    title: "Status",
    width: 150,
  },
  {
    field: CRMSFieldMapping.DueStatus,
    title: "Due Status",
    width: 150,
  },
  {
    field: CRMSFieldMapping.DateRecorded,
    title: "Recorded",
    width: 150,

    format: DATE_FORMAT.DATE,
  },
  {
    field: CRMSFieldMapping.RecordedBy,
    title: "Recorded By",
    width: 150,
  },
  {
    field: CRMSFieldMapping.OrgStructure,
    title: "Org Structure",
    width: 150,
  },
  {
    field: CRMSFieldMapping.ActionOfficer,
    title: "Action Officer",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Coordinator,
    title: "Coordinator",
    width: 150,
  },
  {
    field: CRMSFieldMapping.DateWork,
    title: "Work Date",
    width: 150,

    format: DATE_FORMAT.DATE,
  },
  {
    field: CRMSFieldMapping.EnquiryRefNo,
    title: "Assets Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
