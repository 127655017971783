import {
  AnimalType,
  RegistrationFeeAdditionalChargeRule,
} from "@app/products/animals/model";
import { AddFeeRuleButton } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/components/buttons/add-rule-button/_index";
import { DeleteRulesButton } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/components/buttons/delete-rule-button/_index";
import {
  getColRegistrationFeeAdditionalChargeRules,
  nameOfRegistrationFeeAdditionalChargeList,
} from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/config";
import {
  RegistrationFeeAdditionalChargeList,
  RegistrationFeeRulesType,
} from "@app/products/animals/system-admin/animal-types/[id]/model";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import { generateRFAdditionalChargeRulesData } from "@app/products/animals/system-admin/animal-types/[id]/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface IRegistrationFeeAdditionalChargeRulesProps {
  animalTypeFormObject: AnimalType;
  formRenderProps: FormRenderProps;
}

const nameOfAnimalType = nameOfFactory<AnimalType>();

export const RegistrationFeeAdditionalChargeRules = observer(
  ({
    animalTypeFormObject,
    formRenderProps,
  }: IRegistrationFeeAdditionalChargeRulesProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { setupRegistrationFeeRulesDialog } = useAnimalTypeStore();
    const [gridSelectedRows, setGridSelectedRows] = useState<
      RegistrationFeeAdditionalChargeList[]
    >([]);

    const feeRulesList = valueGetter(nameOfAnimalType("FeeRulesList"));
    const registrationFeeAdditionalChargeRulesData = useMemo(() => {
      return generateRFAdditionalChargeRulesData(
        animalTypeFormObject?.FeeRulesList?.RegistrationFeeAdditionalChargeRules
      );
    }, [
      animalTypeFormObject?.FeeRulesList?.RegistrationFeeAdditionalChargeRules,
    ]);

    const submitDeleteHandler = () => {
      const rulesEnum = gridSelectedRows.map(
        (item: RegistrationFeeAdditionalChargeList) => item.RuleType_Enum
      );
      let registrationFeeAdditionalChargeRulesList = cloneDeep(
        animalTypeFormObject.FeeRulesList
          ?.RegistrationFeeAdditionalChargeRules ?? []
      );
      registrationFeeAdditionalChargeRulesList =
        registrationFeeAdditionalChargeRulesList.filter(
          (feeRule: RegistrationFeeAdditionalChargeRule) =>
            !rulesEnum.includes(feeRule.AdditionalChargeRuleType_ENUM)
        );
      onChange(nameOfAnimalType("FeeRulesList"), {
        value: {
          ...feeRulesList,
          RegistrationFeeAdditionalChargeRules:
            registrationFeeAdditionalChargeRulesList,
        },
      });
      setGridSelectedRows([]);
    };

    return (
      <>
        <CCLabel
          title={`Registration fee additional charge rules${
            registrationFeeAdditionalChargeRulesData?.length > 0
              ? ` (${registrationFeeAdditionalChargeRulesData?.length})`
              : ""
          }`}
        />
        <CCGrid
          selectableMode="multiple"
          primaryField={nameOfRegistrationFeeAdditionalChargeList(
            "RuleType_Enum"
          )}
          columnFields={getColRegistrationFeeAdditionalChargeRules(
            setupRegistrationFeeRulesDialog,
            animalTypeFormObject
          )}
          data={registrationFeeAdditionalChargeRulesData ?? []}
          selectedRows={gridSelectedRows}
          onSelectionChange={(
            dataItem: RegistrationFeeAdditionalChargeList[]
          ) => {
            setGridSelectedRows([...dataItem]);
          }}
          toolbar={
            <div className="cc-grid-tools-bar">
              <AddFeeRuleButton
                feeRulesType={
                  RegistrationFeeRulesType.RegistrationFeeAdditionalChargeRules
                }
              />
              <DeleteRulesButton
                gridSelectedRows={gridSelectedRows}
                submitDeleteHandler={submitDeleteHandler}
              />
            </div>
          }
        />
      </>
    );
  }
);
