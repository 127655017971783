import { useAppealCompleteButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/appeal-complete-application/store";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { AppealCompleteApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/appeal-complete-application/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const appealApplicationList = [Application_Status.Appealed];
export const AppealCompleteApplicationButton = observer(() => {
  const { isShowDialog, setIsShowDialog, handleAppealComplete } =
    useAppealCompleteButtonStore();
  const { ppr, pprId, onSubmit } = usePPRStore();

  const isVisible = useMemo(() => {
    return appealApplicationList.some((item) => item === ppr?.Status_ENUM);
  }, [ppr]);

  return isVisible ? (
    <>
      <CCNavButton
        title="Appeal complete"
        onClick={onSubmit}
        name={PPRSubmitActions.AppealComplete}
      />

      {isShowDialog && (
        <AppealCompleteApplicationDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={(data) => {
            handleAppealComplete(data, pprId);
          }}
        />
      )}
    </>
  ) : null;
});
