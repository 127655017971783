import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { Journal } from "@app/core/journal/_index";
import { BuildingRegistersCertificatesForm } from "@app/products/building/registers/certificates/[id]/components/child-screens/general/_index";
import { CertificatesContactsTab } from "@app/products/building/registers/certificates/[id]/components/reference-sidebar/contacts/_index";
import { CertificatesDetailTab } from "@app/products/building/registers/certificates/[id]/components/reference-sidebar/detail/_index";
import { BuildingRegistersCertificatesRecordsState } from "@app/products/building/registers/certificates/[id]/model";
import { useBuildingRegistersCertificatesStore } from "@app/products/building/registers/certificates/[id]/store";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ExistedBuildingRegistersCertificates = observer(() => {
  const { id } = useParams<{ id: string }>();
  const {
    loadCertificates,
    resetStore,
    certificates,
    isLoading,
    certificateId,
    responseLoadError,
  } = useBuildingRegistersCertificatesStore();
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const managePageUrl = window.location.pathname;

  const { currentUserInfo } = useGlobalStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Building - Registers - Certificates - ${certificateId ?? 0}`,
      Text:
        `${getDisplayTextWithDashes([
          certificates?.RegisterNumber,
          certificates?.Contact,
        ])}` || "N/A",
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Building,
      RecordType_ENUM: RECORDTYPE.BUILDING,
      Record_ID: certificateId ? +certificateId : 0,
    },
  ];

  useEffectOnce(() => {
    loadCertificates(parseInt(id));
    return () => {
      resetStore();
    };
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CertificatesDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <CertificatesContactsTab /> },
      {
        title: "History",
        component: <Journal id={0} recordType={RECORDTYPE.BUILDING} />,
      },
    ],
  });
  const [buildingRegistersCertificatesRecords] = useState<
    BuildingRegistersCertificatesRecordsState | undefined
  >(undefined);

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Actions",
      totalRecord: buildingRegistersCertificatesRecords?.actions,
      component: (
        <Actions id={certificateId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Comments",
      totalRecord: buildingRegistersCertificatesRecords?.comments,
      component: (
        <Comments id={certificateId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Contacts",
      totalRecord: buildingRegistersCertificatesRecords?.contacts,
      component: (
        <Contacts id={certificateId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Documents",
      totalRecord: buildingRegistersCertificatesRecords?.documents,
      component: (
        <Documents
          id={parseInt(certificateId + "")}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Related",
      totalRecord: buildingRegistersCertificatesRecords?.related,
      component: <Fees id={certificateId} recordType={RECORDTYPE.BUILDING} />,
    },
  ];

  const formTitle = useMemo(() => {
    const registerNumber = certificates?.RegisterNumber;
    const contact = certificates?.Contact;

    // If these two are not falsy, return both, otherwise return one or default
    if (registerNumber && contact) {
      return `${registerNumber} - ${contact}`;
    }

    return registerNumber?.toString() || contact || "N/A";
  }, [certificates]);

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />

      <FormNavigation title="Building Certificate" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCertificates(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={formTitle} badge={certificates?.Status} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              ></CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Comment"} />
                <CCNavButton title={"Document"} />
              </CCNavButton>,

              <CCNavButton title={"View"} type="sub">
                <CCNavButton title={"Documents"} />
                <CCNavButton title={"Journal"} />
                <CCNavButton title={"Project"} />
              </CCNavButton>,
              <CCNavButton title={"Print"} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {certificates && (
                  <CCGeneralPanel
                    component={<BuildingRegistersCertificatesForm />}
                  />
                )}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
