import { SecondaryWorkflows } from "@app/products/property/actions/components/detail/components/secondary-workflow/_index";
import { TypesActionsWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import React from "react";
import "./_index.scss";

export interface IWorkflowListProps {
  isShowSecondaryWorkflow?: boolean;
  workflowDraftId: number;
  typeList?: TypesActionsWorkflow;
}

export const WorkflowListDetails = ({
  isShowSecondaryWorkflow = true,
  workflowDraftId,
  typeList,
}: IWorkflowListProps) => {
  return (
    <div className="cc-detail-list">
      <div className="cc-field-group">
        {isShowSecondaryWorkflow && (
          <>
            <div className="cc-field-group">
              <label className="cc-label cc-label-detail-part">
                Additional workflows
              </label>
              <div className="cc-custom-sub-panel-bar">
                <div className="cc-form-cols-1">
                  <SecondaryWorkflows
                    workflowDraftId={workflowDraftId}
                    typeList={typeList}
                  />
                </div>
              </div>
            </div>
            <br />
          </>
        )}
        <div className="cc-field-group">
          <label className="cc-label cc-label-detail-part">Related</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              <PropertyAssociations
                componentNumber={eComponent.Workflow}
                id={workflowDraftId}
                inActive
                isNoControl
                isShowEmptyWhenError
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
