import { CCGrid } from "@components/cc-grid/_index";
import React, { useState } from "react";
import { colNoticeRunsNoticeGroups } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/child-screens/notice-groups/config";
import { NoticeGroupsDetail } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/child-screens/notice-groups/components/notice-groups-details/_index";
import { useEffectOnce } from "react-use";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { getNoticeRunsLovs } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/api";
import { DTO_LOV } from "@common/models/odataResponse";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { nameOfLov } from "@app/products/property/model";
import Loading from "@components/loading/Loading";

export const NoticeRunsNoticeGroups = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noticeGroupsData, setNoticeGroupsData] = useState<DTO_LOV[]>();
  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();

  const loadLovs = async () => {
    setIsLoading(true);
    const response = await getNoticeRunsLovs();
    let errorResponse = undefined;
    if (isSuccessResponse(response)) {
      setNoticeGroupsData(response?.data?.NoticeGroups);
    } else {
      const responseError = response as APIResponse;
      errorResponse = {
        status: responseError.status,
        error: responseError.error ?? "Load failed",
      };
    }
    setResponseLoadError(errorResponse);
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadLovs();
  });

  if (isLoading) return <Loading isLoading />;
  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadLovs();
        }}
      />
    );
  }

  return (
    <CCGrid
      isLoading={isLoading}
      data={noticeGroupsData ?? []}
      columnFields={colNoticeRunsNoticeGroups}
      primaryField={nameOfLov("Code")}
      detail={NoticeGroupsDetail}
    />
  );
};
