import { PPRApplication } from "@app/products/town-planning/ppr/permit-referrals/all/register/model";
import { handlePermitReferralCurrentLinkTo } from "@app/products/town-planning/ppr/permit-referrals/current/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { IBookmark } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";

const nameOfBookmark = nameOfFactory<IBookmark>();
export const colBookmark: IColumnFields[] = [
  {
    field: nameOfBookmark("LinkText"),
    title: "Link",
    linkTo: (dataItem) => {
      return dataItem.LinkUrl;
    },
  },
  {
    field: nameOfBookmark("Text"),
    title: "Detail",
  },
];

export const colMyMessage: IColumnFields[] = [
  {
    field: "Description",
    title: "Description",
  },
  {
    field: "TargetDate",
    title: "",
  },
  {
    field: "Status",
    title: "Status",
  },
];

export const colMyRecordedEvents: IColumnFields[] = [
  {
    field: "Description",
    title: "Description",
  },
  {
    field: "TargetDate",
    title: "",
  },
  {
    field: "Status",
    title: "Status",
  },
];

const nameOfPPRMyApplications = nameOfFactory<PPRApplication>();
export const colPPRMyApplications: IColumnFields[] = [
  {
    field: nameOfPPRMyApplications("ApplicationNumber"),
    title: "Number",
    locked: true,
    linkTo: handlePermitReferralCurrentLinkTo,
  },
  {
    field: nameOfPPRMyApplications("LodgedDate"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPPRMyApplications("SiteAddress"),
    title: "Site Address",
  },
  {
    field: nameOfPPRMyApplications("Status_Name"),
    title: "Status",
  },
];
