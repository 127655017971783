import { useAssessmentDefermentsStore } from "@app/products/property/assessments/[id]/components/child-screens/deferments/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { sleep } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const DeleteDeferment = observer(() => {
  const [isShowConfirmDialog, setIsShowConfirmDialog] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { pushNotification } = useCCAppNotificationStore();
  const { selectedAssessmentDeferments, reloadAssessmentDeferments } =
    useAssessmentDefermentsStore();
  const { assessmentId } = useAssessmentStore();

  const handleDeleteDeferment = async () => {
    setIsLoading(true);
    await sleep(2000);
    setIsLoading(false);
    setIsShowConfirmDialog(false);
    reloadAssessmentDeferments(assessmentId);
    pushNotification({
      type: "success",
      title: "Delete deferment was completed successfully",
    });
    // Remove comment after integrated api
    // const response = await postDeleteDeferment(parseInt(id));
    // if (response?.data?.IsSuccess) {
    //     pushNotification({
    //       type: "success",
    //       title:
    //         response?.data?.SuccessMessage ??
    //         "Delete deferment was completed successfully",
    //     });
    // } else {
    //   pushNotification({
    //     type: "error",
    //     title: response?.data?.ErrorMessage ?? " Delete deferment could not be completed",
    //     autoClose: false,
    //   });
    // }
  };

  return (
    <>
      <CCNavButton
        title="Delete deferment"
        disabled={selectedAssessmentDeferments?.length !== 1}
        onClick={() => setIsShowConfirmDialog(true)}
      />
      {isShowConfirmDialog && (
        <ConfirmDialog
          title="Confirmation"
          subMessage={"Are you sure you wish to delete the selected deferment?"}
          isLoadingYes={isLoading}
          onClosePopup={() => setIsShowConfirmDialog(false)}
          onAsyncConfirm={() => {
            return handleDeleteDeferment();
          }}
        />
      )}
    </>
  );
});
