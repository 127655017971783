import { getMailMergeDocumentTemplate } from "@app/core/communication/dialogs/components/form-elememts/template/api";
import { IMailMergeSelection } from "@app/core/mail-merge/form-steps/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { NAVIGATIONIDENTFIER } from "@common/constants/navigation";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { customLogger } from "@common/utils/logger";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class MailMergeStore {
  private _mailMergeSelection: IMailMergeSelection = {};
  private _navIdentifier: NAVIGATIONIDENTFIER | null = null;
  private _documentTemplateData: string | null = null;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (value: boolean) => {
    runInAction(() => {
      this._isLoading = value;
    });
  };

  get mailMergeSelection() {
    return this._mailMergeSelection;
  }
  setMailMergeSelection = (value: {}) => {
    runInAction(() => {
      this._mailMergeSelection = value;
    });
  };

  get navIdentifier() {
    return this._navIdentifier;
  }
  setNavIdentifier = (nav: NAVIGATIONIDENTFIER | null) => {
    runInAction(() => {
      this._navIdentifier = nav;
    });
  };

  get documentTemplateData() {
    return this._documentTemplateData;
  }
  setDocumentTemplateData = (data: string | null) => {
    runInAction(() => {
      this._documentTemplateData = data;
    });
  };

  //Action

  resetStore = () => {
    runInAction(() => {
      this._mailMergeSelection = {};
      this._navIdentifier = null;
      this._documentTemplateData = null;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadMailMergeDocumentTemplate = async (
    id: number,
    productType: PRODUCT_TYPE
  ) => {
    this.setIsLoading(true);
    const response = await getMailMergeDocumentTemplate(id, productType);
    customLogger("Core Mail Merge, getMailMergeDocumentTemplate").info(
      response?.data
    );
    let newMailMergeDocumentTemplate = undefined;
    let errorResponse = undefined;
    if (isSuccessResponse(response)) {
      newMailMergeDocumentTemplate = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setDocumentTemplateData(newMailMergeDocumentTemplate);
    this.setIsLoading(false);
  };
}
const mailMergeStoreContext = createContext(new MailMergeStore());

export const useMailMergeStore = () => {
  return useContext(mailMergeStoreContext);
};
