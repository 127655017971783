import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colRatingLevies } from "@app/products/property/assessments/[id]/components/child-screens/rating/components/current-levies/config";
import { DTO_Assessment_Levy } from "@app/products/property/assessments/[id]/components/child-screens/rating/model";
import { useAssessmentRatingStore } from "@app/products/property/assessments/[id]/components/child-screens/rating/store";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<DTO_Assessment_Levy>();
export const CurrentLevies = observer(() => {
  const [columnLevies, setColumnLevies] =
    useState<IColumnFields[]>(colRatingLevies);
  const { assessmentLevies } = useAssessmentRatingStore();

  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const loadRatingCurrentLeviesViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Assessment_Rating_Levies,
      colRatingLevies
    );
    if (Array.isArray(response)) {
      setColumnLevies(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadRatingCurrentLeviesViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadRatingCurrentLeviesViewConfig();
        }}
      />
    );

  return (
    <div className="cc-current-levies">
      <label className="cc-label">Current levies</label>
      <div className="cc-field">
        <div className="cc-form-cols-1">
          <CCGrid
            className="cc-current-levies-grid"
            data={assessmentLevies || []}
            columnFields={columnLevies}
            primaryField={nameOf("Id")}
          />
        </div>
      </div>
    </div>
  );
});
