import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { ICommonFormProps } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-application/_index";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCInput } from "@components/cc-input/_index";
import { Field } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const PPRCustomLabelForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { isEditing } = useSettingPPRManageStore();
    const { configData } = useSettingPPRStore();

    const fieldLabelForTeamLeader = useMemo(() => {
      if (configData) {
        return configData[ECorporateSettingsField.TP_PPRLabelForTL.toString()];
      }
      return undefined;
    }, [configData]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          {fieldLabelForTeamLeader && (
            <div className="cc-field">
              <label className="cc-label">
                {fieldLabelForTeamLeader?.Title ?? ""}
              </label>
              <Field
                name={fieldLabelForTeamLeader?.FieldName}
                component={CCInput}
                disabled={!isEditing}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
