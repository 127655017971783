import {
  apiCoreGetHomepageShortcutsById,
  apiCoreSaveHomepageShortcuts,
} from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import { HomePageFunction } from "@common/pages/settings/homepage/homepage-shortcuts/model";

export const getHomepageShortcutsById = async (
  homepageId: number
): Promise<APIResponse<HomePageFunction | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<HomePageFunction>(
      apiCoreGetHomepageShortcutsById(homepageId)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSaveHomepageShortcuts = async (
  homepageShortcuts: HomePageFunction
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreSaveHomepageShortcuts(),
      homepageShortcuts
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
