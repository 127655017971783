import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleProminent } from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";
import {
  VO_ChargeRun_FinancialArea_Summary,
  VO_Charge_Run_Rebate_Summary,
} from "@app/products/property/charge-and-notice-runs/charge-runs/components/detail/model";

const nameOfFinancialAreaSummary =
  nameOfFactory<VO_ChargeRun_FinancialArea_Summary>();
const nameOfRebateSummary = nameOfFactory<VO_Charge_Run_Rebate_Summary>();
export const colFinancialAreaSummary: IColumnFields[] = [
  {
    field: nameOfFinancialAreaSummary("FA_Name"),
    title: "Financial Area",
  },
  {
    field: nameOfFinancialAreaSummary("FG_Name"),
    title: "Group",
  },
  {
    field: nameOfFinancialAreaSummary("No_of_Levies"),
    title: "Number of Levies",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOfFinancialAreaSummary("Base_Amount"),
    title: "Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_ChargeRun_FinancialArea_Summary[]
    ) => totalColumnField(nameOfFinancialAreaSummary("Base_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfFinancialAreaSummary("RateByValue_Amount"),
    title: "Rate by Valuation Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_ChargeRun_FinancialArea_Summary[]
    ) =>
      totalColumnField(
        nameOfFinancialAreaSummary("RateByValue_Amount"),
        gridData
      ),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfFinancialAreaSummary("Increments_to_Minimum"),
    title: "Increments to Minimum",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_ChargeRun_FinancialArea_Summary[]
    ) =>
      totalColumnField(
        nameOfFinancialAreaSummary("Increments_to_Minimum"),
        gridData
      ),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfFinancialAreaSummary("Gross_Levy_Amount"),
    title: "Gross Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_ChargeRun_FinancialArea_Summary[]
    ) =>
      totalColumnField(
        nameOfFinancialAreaSummary("Gross_Levy_Amount"),
        gridData
      ),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfFinancialAreaSummary("Rebates"),
    title: "Rebates",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_ChargeRun_FinancialArea_Summary[]
    ) => totalColumnField(nameOfFinancialAreaSummary("Rebates"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfFinancialAreaSummary("Nett_Levy_Amount"),
    title: "Nett Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_ChargeRun_FinancialArea_Summary[]
    ) =>
      totalColumnField(
        nameOfFinancialAreaSummary("Nett_Levy_Amount"),
        gridData
      ),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfFinancialAreaSummary("Attributable_Value"),
    title: "Attributable Value",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_ChargeRun_FinancialArea_Summary[]
    ) =>
      totalColumnField(
        nameOfFinancialAreaSummary("Attributable_Value"),
        gridData
      ),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfFinancialAreaSummary("Postponed_Amount"),
    title: "Postponed Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_ChargeRun_FinancialArea_Summary[]
    ) =>
      totalColumnField(
        nameOfFinancialAreaSummary("Postponed_Amount"),
        gridData
      ),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
];

export const colRebateSummary: IColumnFields[] = [
  {
    field: nameOfRebateSummary("RT_Name"),
    title: "Type",
  },
  {
    field: nameOfRebateSummary("No_of_Rebates"),
    title: "Number Of",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOfRebateSummary("Total_Rebates"),
    title: "Total",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (
      props: GridFooterCellProps,
      gridData: VO_Charge_Run_Rebate_Summary[]
    ) => totalColumnField(nameOfRebateSummary("Total_Rebates"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
];
