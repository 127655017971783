import { ExistedBuildingRegisterEasement } from "@app/products/building/registers/easements/[id]/components/forms/existed/_index";
import { NewBuildingRegisterEasement } from "@app/products/building/registers/easements/[id]/components/forms/new/_index";
import { useIsNew } from "@common/hooks/useIsNew";
import React from "react";

const ManageBuildingRegisterEasement = () => {
  const isNew = useIsNew();

  if (isNew) {
    return <NewBuildingRegisterEasement />;
  }

  return <ExistedBuildingRegisterEasement />;
};

export default ManageBuildingRegisterEasement;
