import "@app/products/animals/components/forms/components/form-element/_index.scss";
import { DDBankAccountDirectDebitsSection } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/components/general/components/form-element/direct-debits/_index";
import { DDBankAccountGeneralSection } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/components/general/components/form-element/general-section/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { FormElement, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export interface IDirectDebitBankAccountFormElementProps {
  isInactive?: boolean;
  formRenderProps: FormRenderProps;
}

export const DirectDebitBankAccountFormElement = ({
  isInactive = false,
  formRenderProps
}: IDirectDebitBankAccountFormElementProps) => {
  return (
    <FormElement id="cc-form-manage-page-general">
      <DDBankAccountGeneralSection isInactive={isInactive} />
      <CCGeneralPanel
        title="Direct debits"
        isSubPanel
        component={
          <DDBankAccountDirectDebitsSection isInactive={isInactive} formRenderProps={formRenderProps}/>
        }
      />
    </FormElement>
  );
};
