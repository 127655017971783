import { LOOKUP_REGION_ROUTE } from "@common/pages/settings/lookups/regions/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

interface NewRegionButtonProps {
  disabled?: boolean;
}
export const NewRegionButton = ({ disabled }: NewRegionButtonProps) => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New"
      onClick={() => {
        history.push(`${LOOKUP_REGION_ROUTE}/new`);
      }}
      disabled={disabled}
    />
  );
};
