import { FIRE_PREVENTION_ROUTE } from "@app/products/property/fire-prevention/hazld-case/[id]/constant";
import { IFirePreventionWorkOrder } from "@app/products/property/fire-prevention/historical/work-orders/model";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IFirePreventionWorkOrder>();
export const colWorkOrders: IColumnFields[] = [
  {
    field: nameOf("CaseId"),
    title: "Case ID",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => {
      return `${FIRE_PREVENTION_ROUTE}/` + dataItem.CaseId;
    },
    locked: true,
  },
  {
    field: nameOf("WorkOrderId"),
    title: "Work Order ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AssignedContractor"),
    title: "Assigned Contractor",
  },
  { field: nameOf("Clearance"), title: "Clearance" },
  {
    field: nameOf("ToBeClearedBy"),
    title: "To Be Cleared By",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateCleared"),
    title: "Date Cleared",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("NotifiedByContractorOn"),
    title: "Notified by Contractor On",
    format: DATE_FORMAT.DATE,
  },
  { field: nameOf("Locality"), title: "Locality" },
  { field: nameOf("Street"), title: "Street" },
  {
    field: nameOf("HouseNumber"),
    title: "House Number",
  },
  { field: nameOf("Owners"), title: "Owners" },
  {
    field: nameOf("AssessmentNumber"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("SentOn"),
    title: "Sent On",
    format: DATETIME_FORMAT.DATETIME,
  },
  { field: nameOf("SentBy"), title: "Sent By" },
  {
    field: nameOf("CreatedOn"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  { field: nameOf("CreatedBy"), title: "Created By" },
  {
    field: nameOf("CancelledOn"),
    title: "Cancelled On",
    format: DATE_FORMAT.DATE,
  },
  { field: nameOf("Period"), title: "Period" },
  {
    field: nameOf("ParcelId"),
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
