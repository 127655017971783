import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { Form } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { PropertyParcelFormElement } from "./components/form-element/_index";

export const PropertyParcelForm = observer(() => {
  const { parcel } = useParcelsStore();
  return (
    <div className="cc-form">
      <Form
        initialValues={parcel}
        render={() => <PropertyParcelFormElement />}
      />
    </div>
  );
});
