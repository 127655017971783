import { sleep } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { mockNames } from "./mock";

export const loadSearchName = async (filter: FilterDescriptor) => {
  const data = mockNames.slice();
  await sleep(1000);

  let result: any = filterBy(data, filter);
  if (result.length === 0) {
    result = [
      {
        ID: -1,
        Name: filter.value,
        State: "",
        Address: "",
        Locality: "",
        NameAndAddress: "",
        Postcode: "",
        IsExist: false,
      },
    ];
  }
  return result;
};
