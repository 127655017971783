import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { BOOLEAN_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { State } from "@progress/kendo-data-query";

const nameOf = nameOfFactory<EventView>();
export const colAllEventsUnreadMail: IColumnFields[] = [
  {
    field: nameOf("Flag_Unread"),
    title: "Unread Mail",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];

export const allEventsUnreadMailState: State = {
  sort: [{ field: nameOf("ID"), dir: "desc" }],
  filter: {
    logic: "and",
    filters: [{ field: nameOf("Flag_Unread"), operator: "eq", value: true }],
  },
};
