import { stringLengthValidator } from "@app/products/direct-debit/system-admin/util";
import {
  validateNotNegativeNumber,
  validatorDateValueWithMinMax,
} from "@common/utils/field-validators";

const SQL_SERVER_INT_MAX = 2147483647;

const sqlServerMaxIntValidator = (value: any) => {
  if (value && value > SQL_SERVER_INT_MAX)
    return "The maximum value is " + SQL_SERVER_INT_MAX;
  return "";
};

export const paymentStartDateValidator = (
  value: Date,
  valueGetter: (name: string) => any
) => {
  return validatorDateValueWithMinMax(value, null, valueGetter("End_Date"));
};

export const paymentEndDateValidator = (
  value: Date,
  valueGetter: (name: string) => any
) => {
  return validatorDateValueWithMinMax(value, valueGetter("Start_Date"), null);
};

export const positiveAndMaxIntValidator = (value: any) => {
  const positiveValidationResult = validateNotNegativeNumber(value);
  if (positiveValidationResult !== "") return positiveValidationResult;
  return sqlServerMaxIntValidator(value);
};

export const validatePositiveCurrency = (data: number | null | undefined) => {
  if (data === null || data === undefined) return "";
  return data >= 0 ? "" : "The value for this field is not negative number";
};

export const manualDescriptionValidator = (value: any) =>
  stringLengthValidator(value, 0, 100);

export const accountReferenceValidator = (value: any) =>
  stringLengthValidator(value, 0, 50);

export const assessmentNumberValidator = (value: any) =>
  stringLengthValidator(value, 0, 50);

export const assessmentReferenceValidator = (value: any) =>
  stringLengthValidator(value, 0, 50);

export const propertyAddressValidator = (value: any) =>
  stringLengthValidator(value, 0, 100);

export const financeCustomerNumberValidator = (value: any) =>
  stringLengthValidator(value, 0, 20);

export const financeInvoiceNumberValidator = (value: any) =>
  stringLengthValidator(value, 0, 20);
