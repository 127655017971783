import { SPEAR_Result } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/spear/spear-settings/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getVerifySPEAR = async (
  spearEchoMsg: string
): Promise<APIResponse<SPEAR_Result | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<SPEAR_Result | undefined>(
      `/api/tp/internal/settings/verify-spear`,
      {
        params: {
          spearEchoMsg,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
