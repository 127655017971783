import { ICustomEditCell } from "@components/cc-grid/model";
import {
  MaskedTextBox,
  MaskedTextBoxProps,
} from "@progress/kendo-react-inputs";
import React from "react";

interface ICCMaskedEditCellProps
  extends ICustomEditCell,
    Pick<MaskedTextBoxProps, "mask" | "className"> {}

export const CCMaskedEditCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
  mask,
  className,
}: ICCMaskedEditCellProps) => {
  return (
    <MaskedTextBox
      className={className}
      value={value}
      onChange={(event) => {
        if (onChange) {
          onChange({
            field: field,
            dataIndex: dataIndex || 0,
            dataItem,
            syntheticEvent: event.syntheticEvent,
            value: event.value,
          });
        }
      }}
      mask={mask}
    />
  );
};
