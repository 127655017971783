import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { CCValueField } from "@components/cc-value-field/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useDeferredDutyStore } from "@app/products/property/deferred-duty/[id]/store";
import { useDeferredDutyBalanceStore } from "@app/products/property/deferred-duty/[id]/components/child-screens/balances/store";
import { getDeferredDutyBalances } from "@app/products/property/deferred-duty/[id]/components/child-screens/balances/api";
import { CCLoadFailed } from "@components/cc-load-failed/_index";

export const DeferredDutyBalances = observer(() => {
  const {
    deferredDuty,
    isLoading,
    responseLoadError,
    resetStore,
    loadDeferredDuty,
  } = useDeferredDutyBalanceStore();
  const { deferredDutyAccountId } = useDeferredDutyStore();

  useEffect(() => {
    deferredDutyAccountId && loadDeferredDuty(deferredDutyAccountId);
    return () => {
      resetStore();
    };
  }, [deferredDutyAccountId, loadDeferredDuty, resetStore]);

  return isLoading ? (
    <Loading isLoading={isLoading} />
  ) : responseLoadError ? (
    <CCLoadFailed
      responseError={responseLoadError}
      onReload={() => {
        deferredDutyAccountId && getDeferredDutyBalances(deferredDutyAccountId);
      }}
    />
  ) : (
    <div className="cc-deferred-duty-balances">
      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Totals</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCValueField
                  label="Balance owing"
                  name="BalanceOwing"
                  value={deferredDuty?.BalanceOwing}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Pending interest"
                  name="PendingInterest"
                  value={deferredDuty?.PendingInterest}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Payout amount"
                  name="PayoutAmount"
                  value={deferredDuty?.PayoutAmount}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cc-field">
          <label className="cc-label">Original duty amounts</label>
          <div className="cc-custom-sub-panel-bar cc-form-col-1">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCValueField
                  label="Assessed"
                  name="Assessed"
                  value={deferredDuty?.OriginalDutyAssessed}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Deferred"
                  name="Deferred"
                  value={deferredDuty?.OriginalDutyDutyDeferred}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cc-field">
          <label className="cc-label">Balances</label>
          <div className="cc-custom-sub-panel-bar cc-form-col-1">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCValueField
                  label="Duty balance"
                  name="DutyBalance"
                  value={deferredDuty?.DutyBalance}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Interest balance"
                  name="InterestBalance"
                  value={deferredDuty?.InterestBalance}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Other balance"
                  name="OtherBalance"
                  value={deferredDuty?.OtherBalance}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Balance outstanding"
                  name="BalanceOutstanding"
                  value={deferredDuty?.BalanceOutstanding}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});
