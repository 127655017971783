import {
  colAllowFilePicker,
  IAllowedFileTypes,
} from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-portal/document-settings/model";
import { nameOfFactory } from "@common/utils/common";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React from "react";
const nameOf = nameOfFactory<IAllowedFileTypes>();

export interface IKeyWordsPicker extends ICCInputPickerProps {
  onPickKeyWords: (value: IAllowedFileTypes[]) => void;
  isLoading?: boolean;
  listData?: any[];
  selectedRows?: any;
  selectableMode?: "single" | "multiple" | "none";
}
export const AllowFileTypesPicker = (props: IKeyWordsPicker) => {
  const {
    onPickKeyWords,
    onChange,
    isLoading,
    selectableMode,
    textField,
    textProduce,
    listData,
    selectedRows,
    ...other
  } = props;

  const handleOnChange = (event: ICCInputPickerChangeEvent) => {
    const value: any = event.value;
    if (onPickKeyWords) onPickKeyWords(value);
  };

  return (
    <CCInputPicker
      {...other}
      isLoading={isLoading}
      dialog={{
        titleHeader: "Allowed File Types Picker",
        maxWidth: "40%",
      }}
      grid={{
        columnFields: colAllowFilePicker,
        primaryField: nameOf("Id"),
        selectableMode: selectableMode ?? "multiple",
        data: listData ?? [],
        isAutoHiddenPager: false,
        selectedRows: selectedRows,
        itemPerPage: 10,
      }}
      onChange={handleOnChange}
      textField={textField}
      textProduce={textProduce}
    />
  );
};
