import { getFirePreventionDetailsTabById } from "@app/products/property/fire-prevention/hazld-case/[id]/components/reference-sidebar/detail/api";
import { IFirePreventionDetailsTab } from "@app/products/property/fire-prevention/hazld-case/[id]/components/reference-sidebar/detail/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const FirePreventionDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firePreventionInfo, setFirePreventionInfo] = useState<
    IFirePreventionDetailsTab | undefined
  >();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const firePreventionId =
    lastSelectedRow && lastSelectedRow?.CaseId
      ? lastSelectedRow?.CaseId
      : params.id;
  useEffect(() => {
    setIsLoading(true);
    if (firePreventionId) {
      getFirePreventionDetailsTabById(firePreventionId).then((data) => {
        setFirePreventionInfo(data);
        setIsLoading(false);
      });
    }
  }, [firePreventionId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Case ID:"}
              value={firePreventionInfo?.CaseId}
              primary
            />
          }
        >
          <>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Legal Description:"}
                value={firePreventionInfo?.LegalDescription}
              />
              <ProductReferenceRow
                title={"Owners:"}
                value={firePreventionInfo?.Owners}
              />
              <ProductReferenceRow
                title={"Last Inspection Date:"}
                value={formatDisplayValue(
                  firePreventionInfo?.InspectionDate,
                  DATE_FORMAT.DATE
                )}
              />
              <ProductReferenceRow
                title={"Outcome:"}
                value={firePreventionInfo?.Outcome}
              />
              <ProductReferenceRow
                title={"Clearance:"}
                value={firePreventionInfo?.Clearance}
              />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
