import { IAssessmentMeters } from "@app/products/property/assessments/[id]/components/child-screens/meters/model";
import { useAssessmentMeterStore } from "@app/products/property/assessments/[id]/components/child-screens/meters/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";
import MetersDetail from "./components/detail/_index";
import { colMeters } from "./config";

interface IMetersProps {
  onSelectionChange?: (data: any) => void;
}

const nameOf = nameOfFactory<IAssessmentMeters>();

export const Meters = observer(({ onSelectionChange }: IMetersProps) => {
  const { loadMeter, meter, setMeterSelected, isLoading } =
    useAssessmentMeterStore();

  useEffectOnce(() => {
    loadMeter();
  });

  return (
    <div className="cc-property-assessment-meters">
      <CCGrid
        isLoading={isLoading}
        selectableMode="multiple"
        data={meter}
        columnFields={colMeters}
        primaryField={nameOf("MeterId")}
        detail={MetersDetail}
        onSelectionChange={(data) => setMeterSelected(data)}
      />
    </div>
  );
});
