import { documentTemplateRoute } from "@app/products/property/system-admin/document-template/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { settingsRoute } from "./settings/route";

export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  enum: eMenuAction.SettingsCategory_Folder,
  children: [settingsRoute, documentTemplateRoute],
};
