import { SwitchSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/switch/_index";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { dataLoggingModel } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/logging/forms/general-logging/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const GeneralLoggingForm = ({ formRenderProps }: ICommonFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter } = formRenderProps;
  const data = useDataSetting(configData, [
    "CorporateSettings_EnableAgentLogging",
    "CorporateSettings_EnableDebugLogging",
    "Logging_LoggingModel",
  ]);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <SwitchSettings
          data={data.CorporateSettings_EnableAgentLogging}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        {valueGetter(
          data.CorporateSettings_EnableAgentLogging?.FieldName as string
        ) && (
          <>
            <SwitchSettings
              data={data.CorporateSettings_EnableDebugLogging}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
            <div className="cc-field">
              <CCLabel
                title={data.Logging_LoggingModel?.Title ?? ""}
                isMandatory={data.Logging_LoggingModel?.IsMandatory}
              />
              <Field
                name={data.Logging_LoggingModel?.FieldName ?? ""}
                component={CCDropDownList}
                data={dataLoggingModel}
                textField={"Value"}
                dataItemKey={"Key"}
                disabled={!isEditing}
                isKeyValueDropdown
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default observer(GeneralLoggingForm);
