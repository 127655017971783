import { loadViewConfiguresColumns } from "@app/products/property/api";
import { NoticeRunInfoDetailTab } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/reference-sidebar/detail/_index";
import { LastNoticeRunButton } from "@app/products/property/charge-and-notice-runs/notice-runs/components/buttons/last-charge-run/_index";
import { NewNoticeRunButton } from "@app/products/property/charge-and-notice-runs/notice-runs/components/buttons/new-notice-run/_index";
import { OutputToFileButton } from "@app/products/property/charge-and-notice-runs/notice-runs/components/buttons/output-to-file/_index";
import { colListNoticeRuns } from "@app/products/property/charge-and-notice-runs/notice-runs/config";
import { NOTICERUNS_LIST_VIEW_URL } from "@app/products/property/charge-and-notice-runs/notice-runs/constant";
import { VO_Notice_Run } from "@app/products/property/charge-and-notice-runs/notice-runs/model";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
} from "@app/products/property/charge-and-notice-runs/notice-runs/util";
import { chargeAndNoticeRunsRoute } from "@app/products/property/charge-and-notice-runs/route";
import { getBookmarkListViewDisplayName } from "@app/products/property/charge-and-notice-runs/util";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { PROPERTY_ENTITY_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { APIResponseError } from "@common/apis/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_Notice_Run>();
export default () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [colsConfigured, setColsConfigured] = useState<IColumnFields[]>([]);

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={chargeAndNoticeRunsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <NewNoticeRunButton />
        <OutputToFileButton />
      </CCNavButton>,
      <LastNoticeRunButton />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_ENTITY_ROUTE}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Notice_Run_Assessment}
      />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <NoticeRunInfoDetailTab /> },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Notice_Run_Assessment}
          />
        ),
      },
    ],
  });

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.NoticeRuns_List,
      colListNoticeRuns
    );
    if (Array.isArray(response)) {
      setColsConfigured(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <CCProductListView
      dataUrl={NOTICERUNS_LIST_VIEW_URL}
      columnFields={colsConfigured}
      primaryField={nameOf("Notice_Run_Id")}
      state={{ sort: [{ field: nameOf("Notice_Run_Id"), dir: "desc" }] }}
    />
  );
};
