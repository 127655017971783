import { ACTIONSTATUS } from "@common/pages/actions/[id]/model";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { useReturnToSenderButtonStore } from "@common/pages/actions/components/action-bar/buttons/return-to-sender/store";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IReturnToSenderButtonProps {
  disabled?: boolean;
}

export const ReturnToSenderButton = observer(
  ({ disabled }: IReturnToSenderButtonProps) => {
    const { action, onSubmit } = useActionStore();
    const { isLoading } = useReturnToSenderButtonStore();

    const isVisible = useMemo(() => {
      return (
        action?.Status_ENUM === ACTIONSTATUS.ALLOCATED &&
        action?.ActionRequired &&
        action?.DateSent !== undefined &&
        action?.ActionType &&
        !action?.ActionType.Flag_IsReferral
      );
    }, [action]);

    return isVisible ? (
      <CCNavButton
        title="Return to sender"
        isLoading={isLoading}
        onClick={onSubmit}
        disabled={disabled}
        name={ActionSubmitActions.ReturnToSender}
      />
    ) : null;
  }
);
