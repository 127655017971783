import { IRegistersList } from "@app/products/property/registers/list/model";

export const mockRegistersList: IRegistersList[] = [
  {
    Register: "Cemetery",
    AccountReference: "MGW-28",
    Date: new Date("26 Aug 2019"),
    Name: "L J GILES",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Aug 2019"),
    Id: 2010,
    PropertyName: "MGW Hand",
    PropertyAddress: "27 Aberdeen St, Archies Creek",
  },
  {
    Register: "Cemetery",
    AccountReference: "NDD-19",
    Date: new Date("26 Aug 2019"),
    Name: "L J GEBERT & L M HANLON",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Aug 2019"),
    Id: 2009,
  },
  {
    Register: "Cemetery",
    AccountReference: "G3-145B",
    Date: new Date("09 Aug 2019"),
    Name: "L F & M J BESANKO",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("09 Aug 2019"),
    Id: 2008,
  },
  {
    Register: "Cemetery",
    AccountReference: "LWNC-0000",
    Description: "Available Plot",
    Date: new Date("29 Jul 2019"),
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jul 2019"),
    Id: 2007,
  },
  {
    Register: "Cemetery",
    AccountReference: "C-9B",
    Date: new Date("26 Jul 2019"),
    Name: "L E SPYRIADIS",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Jul 2019"),
    Id: 2006,
  },
  {
    Register: "Cemetery",
    AccountReference: "LWN-24B",
    Date: new Date("26 Jul 2019"),
    Name: "L E Otter",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Jul 2019"),
    Id: 2005,
  },
  {
    Register: "Cemetery",
    AccountReference: "GNW2-5",
    Date: new Date("26 Jul 2019"),
    Name: "LE O'BRIEN",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Jul 2019"),
    Id: 2004,
  },
  {
    Register: "Cemetery",
    AccountReference: "RCA-149D",
    Date: new Date("26 Jul 2019"),
    Name: "L E NORTON",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Jul 2019"),
    Id: 2003,
  },
  {
    Register: "Cemetery",
    AccountReference: "RCA-149C",
    Date: new Date("26 Jul 2019"),
    Name: "L E MURRAY",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Jul 2019"),
    Id: 2002,
  },
  {
    Register: "Cemetery",
    AccountReference: "LWNB-125",
    Date: new Date("26 Jul 2019"),
    Name: "L E MILLAR",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Jul 2019"),
    Id: 2001,
  },
  {
    Register: "Cemetery",
    AccountReference: "MGW-73B",
    Date: new Date("26 Jul 2019"),
    Name: "L E MCCAULEY",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Jul 2019"),
    Id: 2000,
  },
  {
    Register: "Cemetery",
    AccountReference: "MGW-82",
    Date: new Date("26 Jul 2019"),
    Name: "L E LLOYD",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Jul 2019"),
    Id: 1999,
  },
  {
    Register: "Cemetery",
    AccountReference: "MGW-83",
    Date: new Date("26 Jul 2019"),
    Name: "LE LEA",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Jul 2019"),
    Id: 1998,
  },
  {
    Register: "Cemetery",
    AccountReference: "GNW2-13",
    Date: new Date("26 Jul 2019"),
    Name: "L E KRUSE",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Jul 2019"),
    Id: 1997,
  },
  {
    Register: "Cemetery",
    AccountReference: "GNW-28",
    Date: new Date("26 Jul 2019"),
    Name: "LE KONONEN",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Jul 2019"),
    Id: 1996,
  },
  {
    Register: "Cemetery",
    AccountReference: "LWNB-113",
    Date: new Date("26 Jul 2019"),
    Name: "L E KASAL",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Jul 2019"),
    Id: 1995,
  },
  {
    Register: "Cemetery",
    AccountReference: "LWNB-91",
    Date: new Date("26 Jul 2019"),
    Name: "L E Jamieson",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("26 Jul 2019"),
    Id: 1994,
  },
  {
    Register: "Cemetery",
    AccountReference: "MGW-18",
    Date: new Date("23 Jul 2019"),
    Name: "L E CROFT",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("23 Jul 2019"),
    Id: 1993,
  },
  {
    Register: "Cemetery",
    AccountReference: "AA-161",
    Date: new Date("29 Jun 2019"),
    Name: "L D Tyler",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1992,
  },
  {
    Register: "Cemetery",
    AccountReference: "AA-215",
    Date: new Date("29 Jun 2019"),
    Name: "L D TYLER",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1991,
  },
  {
    Register: "Cemetery",
    AccountReference: "LWN-57",
    Date: new Date("29 Jun 2019"),
    Name: "E c & CT Maluccio",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1990,
  },
  {
    Register: "Cemetery",
    AccountReference: "RCA-156",
    Date: new Date("29 Jun 2019"),
    Name: "L D Strachov & M Gajic",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1989,
  },
  {
    Register: "Cemetery",
    AccountReference: "AA-199",
    Date: new Date("29 Jun 2019"),
    Name: "L D Shawyer & J A Gray",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1988,
  },
  {
    Register: "Cemetery",
    AccountReference: "G-56",
    Date: new Date("29 Jun 2019"),
    Name: "LD HOBBS",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("15 May 1959"),
    Id: 1987,
  },
  {
    Register: "Cemetery",
    AccountReference: "LWN-56",
    Date: new Date("29 Jun 2019"),
    Name: "E c & CT Maluccio",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1986,
  },
  {
    Register: "Cemetery",
    AccountReference: "G-57",
    Date: new Date("29 Jun 2019"),
    Name: "L D HARTNEY",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("15 May 1959"),
    Id: 1985,
  },
  {
    Register: "Cemetery",
    AccountReference: "UA-176",
    Date: new Date("29 Jun 2019"),
    Name: "L D FOLEY",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1984,
  },
  {
    Register: "Cemetery",
    AccountReference: "AA-214",
    Date: new Date("29 Jun 2019"),
    Name: "L D CLANCY & N SULLIVAN",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1983,
  },
  {
    Register: "Cemetery",
    AccountReference: "LWN-55",
    Date: new Date("29 Jun 2019"),
    Name: "E c & CT Maluccio",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1982,
  },
  {
    Register: "Cemetery",
    AccountReference: "AA-196",
    Date: new Date("29 Jun 2019"),
    Name: "L D CHAMBERLAIN",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1981,
  },
  {
    Register: "Cemetery",
    AccountReference: "UA-121",
    Date: new Date("29 Jun 2019"),
    Name: "L D BURNNARD",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1980,
  },
  {
    Register: "Cemetery",
    AccountReference: "LWN-54",
    Date: new Date("29 Jun 2019"),
    Name: "E c & CT Maluccio",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1979,
  },
  {
    Register: "Cemetery",
    AccountReference: "AB-3",
    Date: new Date("29 Jun 2019"),
    Name: "L D Budgen",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1978,
  },
  {
    Register: "Cemetery",
    AccountReference: "G-54",
    Date: new Date("29 Jun 2019"),
    Name: "L D BENHAM",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("28 Mar 1961"),
    Id: 1977,
  },
  {
    Register: "Cemetery",
    AccountReference: "UA-213",
    Date: new Date("29 Jun 2019"),
    Name: "L D Adams & J p Howes",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1976,
  },
  {
    Register: "Cemetery",
    AccountReference: "LWN-47",
    Date: new Date("29 Jun 2019"),
    Name: "L D & w J COOPER",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1975,
  },
  {
    Register: "Cemetery",
    AccountReference: "UA-121",
    Date: new Date("29 Jun 2019"),
    Name: "L D & s CHARD",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1974,
  },
  {
    Register: "Cemetery",
    AccountReference: "AA-197",
    Date: new Date("29 Jun 2019"),
    Name: "L D & p D J NAVAL",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1973,
  },
  {
    Register: "Cemetery",
    AccountReference: "GNW-5",
    Date: new Date("29 Jun 2019"),
    Name: "L D & N L Coultish",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("02 Apr 2008"),
    Id: 1972,
  },
  {
    Register: "Cemetery",
    AccountReference: "NW-86/87",
    Date: new Date("29 Jun 2019"),
    Name: "K J J Fitzgibbon & L J Fitzgib",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1971,
  },
  {
    Register: "Cemetery",
    AccountReference: "UA-207",
    Date: new Date("29 Jun 2019"),
    Name: "L D & H Morrow",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1970,
  },
  {
    Register: "Cemetery",
    AccountReference: "AA-213",
    Date: new Date("29 Jun 2019"),
    Name: "L D & c THOMAS",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1969,
  },
  {
    Register: "Cemetery",
    AccountReference: "UA-154",
    Date: new Date("29 Jun 2019"),
    Name: "L D & c R LAUDER",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("28 Aug 1964"),
    Id: 1968,
  },
  {
    Register: "Cemetery",
    AccountReference: "GNW-4",
    Date: new Date("29 Jun 2019"),
    Name: "L CVITKOVIC",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("21 Nov 2007"),
    Id: 1967,
  },
  {
    Register: "Cemetery",
    AccountReference: "UA-214",
    Date: new Date("29 Jun 2019"),
    Name: "L CRULCICH",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1966,
  },
  {
    Register: "Cemetery",
    AccountReference: "AA-202",
    Date: new Date("29 Jun 2019"),
    Name: "L COVERDALE",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1965,
  },
  {
    Register: "Cemetery",
    AccountReference: "UA-206",
    Date: new Date("29 Jun 2019"),
    Name: "L Cop & (Estate Of M Cop)",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1964,
  },
  {
    Register: "Cemetery",
    AccountReference: "GNW-7",
    Date: new Date("29 Jun 2019"),
    Name: "L CONSTANTINOU",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("21 Nov 2007"),
    Id: 1963,
  },
  {
    Register: "Cemetery",
    AccountReference: "AA-163",
    Date: new Date("29 Jun 2019"),
    Name: "L CONSTANTINOU",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1962,
  },
  {
    Register: "Cemetery",
    AccountReference: "AA-221",
    Date: new Date("29 Jun 2019"),
    Name: "L CONDON",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1961,
  },
  {
    Register: "Cemetery",
    AccountReference: "GNW-6",
    Date: new Date("29 Jun 2019"),
    Name: "L COLUSSI & L GRANI",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("21 Nov 2007"),
    Id: 1960,
  },
  {
    Register: "Cemetery",
    AccountReference: "NW-44/45",
    Date: new Date("29 Jun 2019"),
    Name: "Kellibanco P/I-Acn 087 527 5",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1959,
  },
  {
    Register: "Cemetery",
    AccountReference: "UA-175",
    Date: new Date("29 Jun 2019"),
    Name: "L CIABARRA",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1958,
  },
  {
    Register: "Cemetery",
    AccountReference: "AA-212",
    Date: new Date("29 Jun 2019"),
    Name: "L CHIRICHIELLO",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("29 Jun 2019"),
    Id: 1957,
  },
  {
    Register: "Cemetery",
    AccountReference: "UA-172",
    Date: new Date("29 Jun 2019"),
    Name: "L CHIRIANO",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("30 May 1966"),
    Id: 1956,
  },
  {
    Register: "Cemetery",
    AccountReference: "LWN-107",
    Date: new Date("29 Jun 2019"),
    Name: "L Chetcuti",
    Balance: 0.0,
    Status: "Active",
    CreatedOn: new Date("09 Jan 2008"),
    Id: 1955,
  },
];
