import { ExistedCRS } from "@app/products/crs/[id]/components/forms/existed/_index";
import { NewCRS } from "@app/products/crs/[id]/components/forms/new/_index";
import { useCRSStore } from "@app/products/crs/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const ManageCRS = () => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { resetStore, loadCRS } = useCRSStore();

  useEffect(() => {
    if (isNew) return;
    loadCRS(id);
    return () => {
      resetStore();
    };
  }, [isNew, loadCRS, resetStore, id]);

  if (isNew) {
    return <NewCRS />;
  }
  return <ExistedCRS />;
};

export default ManageCRS;
