import { iconColumnViewConfigAlert } from "@app/products/crms/config";
import { HMPremisesInspectionScheduleView } from "@app/products/hm/insp-schedule/model";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfPremisesInspScheduleView =
  nameOfFactory<HMPremisesInspectionScheduleView>();
export const colHMInspScheduleAllByOfficer: IColumnFields[] = [
  {
    field: nameOfPremisesInspScheduleView("TradingName"),
    title: "Trading Name",
    locked: true,
    linkTo: (dataItem: HMPremisesInspectionScheduleView) => {
      return `${PREMISES_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  ...iconColumnViewConfigAlert,
  {
    field: nameOfPremisesInspScheduleView("RegNo"),
    title: "Registration Number",
  },
  {
    field: nameOfPremisesInspScheduleView("LastInspection"),
    title: "Last Inspection",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesInspScheduleView("NextInspection"),
    title: "Next Inspection",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesInspScheduleView("StreetNo"),
    title: "Number",
  },
  {
    field: nameOfPremisesInspScheduleView("Street"),
    title: "Street",
  },
  {
    field: nameOfPremisesInspScheduleView("Suburb"),
    title: "Suburb",
  },
  {
    field: nameOfPremisesInspScheduleView("Area"),
    title: "Area",
  },
  {
    field: nameOfPremisesInspScheduleView("Phone"),
    title: "Phone",
  },
  {
    field: nameOfPremisesInspScheduleView("PremisesType"),
    title: "Type",
  },
  {
    field: nameOfPremisesInspScheduleView("Risk"),
    title: "Risk",
  },
  {
    field: nameOfPremisesInspScheduleView("Officer"),
    title: "Officer",
  },
  {
    field: nameOfPremisesInspScheduleView("RefNo"),
    title: "Reference Number",
  },
  {
    field: nameOfPremisesInspScheduleView("FileNumber"),
    title: "File Number",
  },
  {
    field: nameOfPremisesInspScheduleView("DebtorNo"),
    title: "Debtor Number",
  },
  {
    field: nameOfPremisesInspScheduleView("AssessmentNo"),
    title: "Assessment Number",
  },
  {
    field: nameOfPremisesInspScheduleView("PostalAddress"),
    title: "Postal Address",
  },
  {
    field: nameOfPremisesInspScheduleView("PostalAddress2"),
    title: "Postal Address 2",
  },
  {
    field: nameOfPremisesInspScheduleView("Proprietor"),
    title: "Proprietor",
  },
  {
    field: nameOfPremisesInspScheduleView("NoEmployees"),
    title: "Emp",
  },
  {
    field: nameOfPremisesInspScheduleView("PrimaryContact"),
    title: "Contact",
  },
  {
    field: nameOfPremisesInspScheduleView("FSPContact"),
    title: "FSS Contact",
  },
  {
    field: nameOfPremisesInspScheduleView("DateLodged"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesInspScheduleView("DateRegistrationExpires"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesInspScheduleView("Fax"),
    title: "Fax",
  },
  {
    field: nameOfPremisesInspScheduleView("AmountOS"),
    title: "Outstanding",
  },
  {
    field: nameOfPremisesInspScheduleView("NextInspectionMonth"),
    title: "Next Inspection Month",
    format: DATE_FORMAT.MONTH_YEAR,
  },
];
