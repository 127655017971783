//#region FSR
import { HMFieldMapping } from "@app/products/hm/model";
export const colHMSamplesSurveys = [
  {
    field: HMFieldMapping.Title,
    title: "Title",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.Number,
    title: "Number",
    width: 150,
  },
  {
    field: HMFieldMapping.EnforcementAgency,
    title: "Enforcement Agency",
    width: 150,
  },
  {
    field: HMFieldMapping.Officer,
    title: "Officer",
    width: 150,
  },
  {
    field: HMFieldMapping.Outcome,
    title: "Outcome",
    width: 150,
  },
  {
    field: HMFieldMapping.Date_Start,
    title: "Start Date",
    width: 150,
  },
  {
    field: HMFieldMapping.Date_Completetion,
    title: "Comp Date",
    width: 150,
  },
  {
    field: HMFieldMapping.RefNo,
    title: "Reference Number",
    width: 150,
  },
];
