import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRS4yoKinderCancellations: IColumnFields[] = [
  {
    field: CRSFieldMapping.Registration_ReferenceNo,
    title: "Application Number",

    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.YearAttending,
    title: "Year Attending",

    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.ChildName, title: "Child Name" },
  {
    field: CRSFieldMapping.Reg_Weight,
    title: "Weighting",

    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.RegistrationDate,
    title: "Registered",

    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.ChildDateOfBirth,
    title: "Date of Birth",

    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.Deferred,
    title: "Deferred",

    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.SecondYearEntrolment,
    title: "2nd Yr Enrol",

    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.ChildGender,
    title: "Gender",

    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: CRSFieldMapping.FirstPreference, title: "First Preference" },
  {
    field: CRSFieldMapping.LodgementDate,
    title: "Lodged",

    format: DATE_FORMAT.DATE,
  },
  { field: CRSFieldMapping.Reg_Status, title: "Status" },
  { field: CRSFieldMapping.Suburb, title: "Suburb" },
  { field: CRSFieldMapping.ParentName, title: "Parent Name" },
  { field: CRSFieldMapping.Relationship, title: "Relationship" },
  {
    field: CRSFieldMapping.CancelledDate,
    title: "Cancelled Date",

    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.Phone,
    title: "Phone",

    format: NUMBER_FORMAT.NUMBER2,
  },
];
