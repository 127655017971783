import { OfficerAuthorisationDialog } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/authorise/components/form-elements/authorise/components/dialogs/officer-authorisation/_index";
import { ASSIGN_AUTHORISATION_ID } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/authorise/components/form-elements/authorise/config";
import { AuthoriseType } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/authorise/model";
import { nameOfLov } from "@app/products/property/model";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCNote } from "@components/cc-note/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  RadioButton,
  RadioButtonChangeEvent,
} from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import "./_index.scss";

export const AuthoriseFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  localNotificationRef,
  options = {
    isReadOnly: false,
  },
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const authorise = getFieldValue("_options.Authorise");

  const [
    isShowOfficerAuthorisationDialog,
    setIsShowOfficerAuthorisationDialog,
  ] = useState<boolean>(false);

  const handleVerifiedOfficer = () => {
    onChange(nameOf("Verified"), { value: true });
  };

  const handleChangeOptionValue = (event: RadioButtonChangeEvent) => {
    onChange(nameOf("_options.Authorise"), { value: event.value });
    onChange(nameOf("Journal.Jnl_Can_Be_Authorised"), {
      value: event.value === AuthoriseType.AuthoriseJournals,
    });

    if (event.value === AuthoriseType.AuthoriseJournals) {
      //Choose AuthoriseJournals => Reset Assigned_to_Authorise_Officer_Id
      onChange(nameOf("Journal.Assigned_to_Authorise_Officer_Id"), {
        value: null,
      });
    }
    if (event.value === AuthoriseType.AssignAuthorisationTo) {
      //Focus to comboBox when we selected AssignAuthorisationTo option
      const comboBoxElement = document.getElementById(ASSIGN_AUTHORISATION_ID);
      setTimeout(() => {
        comboBoxElement?.focus();
      }, 100);
    }
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Entered by</label>
          <Field
            name={nameOf("Journal.Officer_Name")}
            component={CCInput}
            readOnly
          />
        </div>
      </div>
      <section className="cc-field-group">
        <label className="cc-label">Action</label>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <div className="cc-control-item">
              <Field
                name={nameOf("_options.Authorise")}
                value={AuthoriseType.AssignAuthorisationTo}
                checked={authorise === AuthoriseType.AssignAuthorisationTo}
                label="Assign authorisation to"
                component={RadioButton}
                disabled={
                  getFieldValue("Verified") ||
                  options.isReadOnly ||
                  options.isLoadingOnNext
                }
                onChange={handleChangeOptionValue}
              />
            </div>
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <div className="cc-control-item">
              <Field
                id={ASSIGN_AUTHORISATION_ID}
                name={nameOf("Journal.Assigned_to_Authorise_Officer_Id")}
                component={CCSearchComboBox}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                isUseDefaultOnchange
                data={getFieldValue("_options.AssignAuthorisationToData") ?? []}
                disabled={
                  authorise !== AuthoriseType.AssignAuthorisationTo ||
                  options.isReadOnly ||
                  options.isLoadingOnNext
                }
                validator={
                  authorise === AuthoriseType.AssignAuthorisationTo
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <div className="cc-control-item">
              <Field
                name={nameOf("Authorise")}
                value={AuthoriseType.AuthoriseJournals}
                checked={authorise === AuthoriseType.AuthoriseJournals}
                label="Authorise journal"
                component={RadioButton}
                disabled={
                  getFieldValue("Verified") ||
                  options.isReadOnly ||
                  options.isLoadingOnNext
                }
                onChange={handleChangeOptionValue}
              />
            </div>
          </div>
        </div>
        {/* Currently, it is only allowed 2 options:
           + Assign authorisation to
           + Authorise journal So 
          Hide the "Authorise by" until we have a further design. 
          TODO: waiting for further design*/}
        {/* <div className="cc-form-cols-1">
            <div className="cc-field">
              <div className="cc-control-item">
                <Field
                  name={nameOf("Authorise")}
                  value={"AuthoriseBy"}
                  checked={
                    getFieldValue("Authorise") === AuthoriseType.AuthoriseBy
                  }
                  label={
                    <span>
                      Authorise by&nbsp;
                      <i>
                        (Under development, in the interim please use the
                        'Assign authorisation to' option)
                      </i>
                      &nbsp;
                      {getFieldValue("Verified") && (
                        <span className="fa fa-check-circle cc-green-tick-circle" />
                      )}
                    </span>
                  }
                  component={RadioButton}
                  disabled={getFieldValue("Verified")}
                />
              </div>
            </div>
          </div> */}
        {/* <div className="cc-form-cols-2">
            <div className="cc-field">
              <div className="cc-control-item">
                <Field
                  name={nameOf("AuthorisedBy")}
                  component={CCSearchComboBox}
                  dataItemKey="Key"
                  textField="Value"
                  isUseDefaultOnchange
                  data={mockAuthorised}
                />
              </div>
            </div>
            {getFieldValue("Authorise") === AuthoriseType.AuthoriseBy && (
              <div className="cc-field">
                <div className="cc-col-span-2">
                  <Button
                    className="cc-dialog-button"
                    type="button"
                    onClick={() => setIsShowOfficerAuthorisationDialog(true)}
                  >
                    Send verification code
                  </Button>
                </div>
              </div>
            )}
          </div> */}
      </section>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            Create a session for this journal to update the general ledger
          </label>
          <Field
            name={nameOf("CreateSession")}
            component={CCSwitch}
            checked={getFieldValue("CreateSession")}
            disabled={options.isReadOnly || options.isLoadingOnNext}
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field cc-custom-note">
          <CCNote message="Authorising a Journal, changes the status of the journals transactions from Proposed to Active, whereby the transaction is included in balances." />
        </div>
      </div>
      {isShowOfficerAuthorisationDialog && (
        <OfficerAuthorisationDialog
          onClose={() => setIsShowOfficerAuthorisationDialog(false)}
          localNotificationRef={localNotificationRef}
          onVerifiedOfficer={handleVerifiedOfficer}
        />
      )}
    </section>
  );
};
