import {
  IDocument,
  MAILMERGEDATASET,
} from "@app/core/new-mail-merge/dialogs/model";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { ISelectDocumentsTemplateDialog } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/ppr/planning-permit-referrals/components/dialogs/select-documents-template/model";
import { SelectDocumentsTemplateDialog } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/ppr/planning-permit-referrals/components/dialogs/select-documents-template/_index";
import {
  colResponseReportLetter,
  nameOfDocument,
} from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/ppr/planning-permit-referrals/config";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { KeyWordsPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/_index";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { unionBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export type IPPRPlanningPermitReferralsSettingsFormProps = {
  formRenderProps: FormRenderProps;
};

const nameOfCoreKeyword = nameOfFactory<CoreKeyword>();

export const PPRPlanningPermitReferralsForm = observer(
  ({ formRenderProps }: IPPRPlanningPermitReferralsSettingsFormProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { isEditing } = useSettingPPRManageStore();
    const { getConfigDataField } = useSettingPPRStore();
    const [showDeleteLetterDialog, setShowDeleteLetterDialog] =
      useState<ECorporateSettingsField | null>(null);
    const [showMailMergeDialog, setShowMailMergeDialog] =
      useState<ECorporateSettingsField | null>(null);
    const [reportLetterSelected, setReportLetterSelected] = useState<
      IDocument[]
    >([]);
    const [responseLetterSelected, setResponseLetterSelected] = useState<
      IDocument[]
    >([]);

    const reportLetterValueDisplayDetails: IDocument[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_PPRReportLetterDocuments}`
      ) ?? [];
    const responseLetterValueDisplayDetails: IDocument[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_PPRResponseLetterDocuments}`
      ) ?? [];
    const referralTriggerValueDisplayDetails: CoreKeyword[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_PPRReferralTriggerForAmendedApplication}`
      ) ?? [];

    const fieldReferralTrigger = getConfigDataField(
      ECorporateSettingsField.TP_PPRReferralTriggerForAmendedApplication
    );
    const fieldReportLetter = getConfigDataField(
      ECorporateSettingsField.TP_PPRReportLetterDocuments
    );
    const fieldResponseLetter = getConfigDataField(
      ECorporateSettingsField.TP_PPRResponseLetterDocuments
    );
    const fieldEnablePPR = getConfigDataField(
      ECorporateSettingsField.TP_Application_PPR
    );
    const fieldReportLetterTag = getConfigDataField(
      ECorporateSettingsField.TP_PPRReportLetterTagLabel
    );
    const fieldResponseLetterTag = getConfigDataField(
      ECorporateSettingsField.TP_PPRResponseLetterTagLabel
    );
    const fieldVCATTemplateURL = getConfigDataField(
      ECorporateSettingsField.TP_PPRVCATTemplateURL
    );
    const fieldVCATEmailAddress = getConfigDataField(
      ECorporateSettingsField.TP_PPRVCATEmailAddress
    );
    const fieldVicPlanURL = getConfigDataField(
      ECorporateSettingsField.CorporateSettings_VicPlanURL
    );
    const fieldShowNonComplianceMenuItem = getConfigDataField(
      ECorporateSettingsField.TP_Show_NonCompliance_Menu
    );
    const fieldAttachDocumentsToTLEmail = getConfigDataField(
      ECorporateSettingsField.PPR_SendTo_TLforApproval
    );

    const valueDisplayDetailsByField = (
      field: ECorporateSettingsField | null
    ) => {
      switch (field) {
        case ECorporateSettingsField.TP_PPRReportLetterDocuments:
          return {
            LetterValueDisplayDetails: reportLetterValueDisplayDetails,
            SelectedLetter: reportLetterSelected,
          };
        case ECorporateSettingsField.TP_PPRResponseLetterDocuments:
          return {
            LetterValueDisplayDetails: responseLetterValueDisplayDetails,
            SelectedLetter: responseLetterSelected,
          };
        default:
          return {
            LetterValueDisplayDetails: [],
            SelectedLetter: [],
          };
      }
    };

    const handleOnDeleteLetter = (field: ECorporateSettingsField) => {
      const fieldName = field.toString();
      const letterValueDisplayDetails: IDocument[] =
        valueDisplayDetailsByField(field)?.LetterValueDisplayDetails;
      const selectedLetter: IDocument[] =
        valueDisplayDetailsByField(field)?.SelectedLetter;

      const newDocumentsTemplate = letterValueDisplayDetails?.filter(
        (item) =>
          item.Document_ID !==
          selectedLetter?.map((item) => item?.Document_ID)?.[0]
      );
      const newDocumentsTemplateIds = newDocumentsTemplate?.map(
        (item) => item?.Document_ID
      );

      onChange(fieldName, {
        value: newDocumentsTemplateIds?.join("|"),
      });
      onChange(`option.ValueDisplayDetails_${fieldName}`, {
        value: newDocumentsTemplate,
      });

      if (field === ECorporateSettingsField.TP_PPRReportLetterDocuments) {
        setReportLetterSelected([]);
      } else {
        setResponseLetterSelected([]);
      }
    };

    const handleOnAddLetter = (data: ISelectDocumentsTemplateDialog) => {
      setShowMailMergeDialog(null);
      const fieldName = showMailMergeDialog?.toString() ?? "";
      const newDocumentsTemplate =
        unionBy(
          valueDisplayDetailsByField(showMailMergeDialog)
            ?.LetterValueDisplayDetails,
          data?.DocumentsTemplateSelected,
          nameOfDocument("Document_ID")
        ) ?? [];

      const newDocumentsTemplateIds = newDocumentsTemplate?.map(
        (item) => item?.Document_ID
      );

      onChange(fieldName, {
        value: newDocumentsTemplateIds?.join("|"),
      });
      onChange(`option.ValueDisplayDetails_${fieldName}`, {
        value: newDocumentsTemplate,
      });
    };

    const handleOnChangeReferralTrigger = (keyword: CoreKeyword) => {
      onChange(fieldReferralTrigger?.FieldName ?? "", {
        value: keyword.Keyword_ID?.toString(),
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_PPRReferralTriggerForAmendedApplication}`,
        { value: [keyword] }
      );
    };

    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            {fieldEnablePPR && (
              <div className="cc-field">
                <label className="cc-label">
                  {fieldEnablePPR?.Title ?? ""}
                </label>
                <Field
                  name={fieldEnablePPR?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={valueGetter(fieldEnablePPR?.FieldName)}
                />
              </div>
            )}
          </div>
          {valueGetter(fieldEnablePPR?.FieldName ?? "") && (
            <>
              <div className="cc-form-cols-2">
                {fieldReportLetter && (
                  <div className="cc-field">
                    <label className="cc-label" onClick={() => {}}>
                      {fieldReportLetter?.Title ?? ""}
                    </label>
                    <CCGrid
                      toolbar={
                        <div className="cc-grid-tools-bar">
                          <Button
                            type="button"
                            iconClass="fa fa-plus"
                            title="Add Report Letter"
                            onClick={() => {
                              setShowMailMergeDialog(
                                ECorporateSettingsField.TP_PPRReportLetterDocuments
                              );
                            }}
                            disabled={!isEditing}
                          />
                          <Button
                            type="button"
                            iconClass="fas fa-minus"
                            title="Remove Report Letter"
                            disabled={
                              !(reportLetterSelected?.length === 1 && isEditing)
                            }
                            onClick={() =>
                              setShowDeleteLetterDialog(
                                ECorporateSettingsField.TP_PPRReportLetterDocuments
                              )
                            }
                          />
                        </div>
                      }
                      columnFields={colResponseReportLetter}
                      primaryField={nameOfDocument("Document_ID")}
                      data={
                        reportLetterValueDisplayDetails?.map(
                          (item: IDocument) => {
                            return {
                              ...item,
                              FileName: item?.FileEntry?.FileName ?? "",
                            };
                          }
                        ) ?? []
                      }
                      selectableMode="multiple"
                      selectedRows={reportLetterSelected}
                      onSelectionChange={(dataItem: any[]) => {
                        if (dataItem) setReportLetterSelected([...dataItem]);
                      }}
                    />
                  </div>
                )}
                {fieldResponseLetter && (
                  <div className="cc-field">
                    <label className="cc-label">Response letter</label>
                    <CCGrid
                      toolbar={
                        <div className="cc-grid-tools-bar">
                          <Button
                            type="button"
                            iconClass="fa fa-plus"
                            title="Add Response Letter"
                            onClick={() => {
                              setShowMailMergeDialog(
                                ECorporateSettingsField.TP_PPRResponseLetterDocuments
                              );
                            }}
                            disabled={!isEditing}
                          />
                          <Button
                            type="button"
                            iconClass="fas fa-minus"
                            title="Remove Response Letter"
                            disabled={
                              !(
                                responseLetterSelected?.length === 1 &&
                                isEditing
                              )
                            }
                            onClick={() =>
                              setShowDeleteLetterDialog(
                                ECorporateSettingsField.TP_PPRResponseLetterDocuments
                              )
                            }
                          />
                        </div>
                      }
                      columnFields={colResponseReportLetter}
                      primaryField={nameOfDocument("Document_ID")}
                      data={
                        responseLetterValueDisplayDetails?.map(
                          (item: IDocument) => {
                            return {
                              ...item,
                              FileName: item?.FileEntry?.FileName ?? "",
                            };
                          }
                        ) ?? []
                      }
                      selectableMode="multiple"
                      selectedRows={responseLetterSelected}
                      onSelectionChange={(dataItem: any[]) => {
                        if (dataItem) setResponseLetterSelected([...dataItem]);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">
                    {fieldReferralTrigger?.Title ?? ""}
                  </label>
                  <Field
                    name={fieldReferralTrigger?.FieldName ?? ""}
                    placeholder={fieldReferralTrigger?.Title ?? ""}
                    value={referralTriggerValueDisplayDetails[0] ?? null}
                    component={KeyWordsPicker}
                    keyWordType={KEYWORD_TYPE.Core_ReferralTrigger}
                    productType={PRODUCT_TYPE_NUMBER.TownPlanning}
                    onPickKeyWords={handleOnChangeReferralTrigger}
                    grid={{
                      selectedRows: valueGetter(
                        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_PPRReferralTriggerForAmendedApplication}`
                      ),
                    }}
                    textField={nameOfCoreKeyword("Name")}
                    isGetDetail={false}
                    disabled={!isEditing}
                  />
                </div>
                <CCValueField
                  label="Number of days"
                  value={
                    referralTriggerValueDisplayDetails[0]?.TargetDays ?? null
                  }
                />
              </div>
              <div className="cc-form-cols-2">
                {fieldReportLetterTag && (
                  <div className="cc-field">
                    <label className="cc-label">
                      {fieldReportLetterTag?.Title ?? ""}
                    </label>
                    <Field
                      name={fieldReportLetterTag?.FieldName}
                      component={CCInput}
                      disabled={!isEditing}
                    />
                  </div>
                )}
                {fieldResponseLetterTag && (
                  <div className="cc-field">
                    <label className="cc-label">
                      {fieldResponseLetterTag?.Title ?? ""}
                    </label>
                    <Field
                      name={fieldResponseLetterTag?.FieldName}
                      component={CCInput}
                      disabled={!isEditing}
                    />
                  </div>
                )}
              </div>
              <div className="cc-form-cols-2">
                {fieldVCATTemplateURL && (
                  <div className="cc-field">
                    <label className="cc-label">
                      {fieldVCATTemplateURL?.Title ?? ""}
                    </label>
                    <Field
                      name={fieldVCATTemplateURL?.FieldName}
                      component={CCInput}
                      disabled={!isEditing}
                    />
                  </div>
                )}
                {fieldVCATEmailAddress && (
                  <div className="cc-field">
                    <label className="cc-label">
                      {fieldVCATEmailAddress?.Title ?? ""}
                    </label>
                    <Field
                      name={fieldVCATEmailAddress?.FieldName}
                      component={CCInput}
                      disabled={!isEditing}
                    />
                  </div>
                )}
              </div>
              <div className="cc-form-cols-2">
                {fieldVicPlanURL && (
                  <div className="cc-field">
                    <label className="cc-label">
                      {fieldVicPlanURL?.Title ?? ""}
                    </label>
                    <Field
                      name={fieldVicPlanURL?.FieldName}
                      component={CCInput}
                      disabled={!isEditing}
                    />
                  </div>
                )}
              </div>
              <div className="cc-form-cols-2">
                {fieldShowNonComplianceMenuItem && (
                  <div className="cc-field">
                    <label className="cc-label">
                      {fieldShowNonComplianceMenuItem?.Title ?? ""}
                    </label>
                    <Field
                      name={fieldShowNonComplianceMenuItem?.FieldName}
                      component={CCSwitch}
                      disabled={!isEditing}
                      checked={valueGetter(
                        fieldShowNonComplianceMenuItem?.FieldName
                      )}
                    />
                  </div>
                )}
                {fieldAttachDocumentsToTLEmail && (
                  <div className="cc-field">
                    <label className="cc-label">
                      {fieldAttachDocumentsToTLEmail?.Title ?? ""}
                    </label>
                    <Field
                      name={fieldAttachDocumentsToTLEmail?.FieldName}
                      component={CCSwitch}
                      disabled={!isEditing}
                      checked={valueGetter(
                        fieldAttachDocumentsToTLEmail?.FieldName
                      )}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </section>
        {showMailMergeDialog && (
          <SelectDocumentsTemplateDialog
            onClose={() => {
              setShowMailMergeDialog(null);
            }}
            onSubmit={(data: ISelectDocumentsTemplateDialog) =>
              handleOnAddLetter(data)
            }
            dataSetFilters={MAILMERGEDATASET.TOWNPLANNING_PPR_Applications}
            productType={PRODUCT_TYPE_NUMBER.TownPlanning}
            isMultiple
          />
        )}
        {showDeleteLetterDialog && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={"Are you sure you want to delete this item?"}
            onClosePopup={() => setShowDeleteLetterDialog(null)}
            onConfirmYes={() => handleOnDeleteLetter(showDeleteLetterDialog)}
          />
        )}
      </>
    );
  }
);
