import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { fnt_Assess_Supp_ValuationResult } from "./model";

const nameOf = nameOfFactory<fnt_Assess_Supp_ValuationResult>();

export const colValuations: IColumnFields[] = [
  {
    field: nameOf("RP_Name"),
    title: "Rating Period",
  },
  {
    field: nameOf("Val_Effective_Date"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Val_Issue_Date"),
    title: "Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Val_Date_of_Valuation"),
    title: "Date of Valuation",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Val_Comment"),
    title: "Comment",
  },
  {
    field: nameOf("Valuation_1"),
    title: "Valuation_1",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_2"),
    title: "Valuation_2",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_3"),
    title: "Valuation_3",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_4"),
    title: "Valuation_4",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_5"),
    title: "Valuation_5",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_6"),
    title: "Valuation_6",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_7"),
    title: "Valuation_7",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_8"),
    title: "Valuation_8",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_9"),
    title: "Valuation_9",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_10"),
    title: "Valuation_10",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_11"),
    title: "Valuation_11",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_12"),
    title: "Valuation_12",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_13"),
    title: "Valuation_13",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_14"),
    title: "Valuation_14",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_15"),
    title: "Valuation_15",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_16"),
    title: "Valuation_16",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_17"),
    title: "Valuation_17",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_18"),
    title: "Valuation_18",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_19"),
    title: "Valuation_19",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_20"),
    title: "Valuation_20",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Supplementary_Id"),
    title: "Supplementary ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Supp_Reference"),
    title: "Supplementary Reference",
  },
  {
    field: nameOf("Supp_Date"),
    title: "Supplementary Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Supp_Name"),
    title: "Supplementary Name",
  },
  {
    field: nameOf("Valuation_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
