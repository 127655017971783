import { Svc_Animals_Kennel } from "@app/products/animals/kennels-renewals/model";

export const AnimalKennelsRenewalsRequiresRenewalsNoticeBookmark = {
  getBookmarkListViewDisplayName() {
    return "Animals - Kennels Renewals";
  },

  getBookmarkListViewDetail() {
    return "Animals - Kennels Renewals - Requires Renewals Notice";
  },

  getBookmarkListViewDetailRecord(selectedRow: Svc_Animals_Kennel) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Animal - Requires Renewals Notice ${dynamicDisplayName}`;
  },
};
