import { DTO_Assessment } from "@app/products/property/assessments/[id]/model";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { VO_Assessment } from "@app/products/property/assessments/list/model";
import { MapSpatialLinkTab } from "@app/products/property/components/reference-sidebar/map-with-spatial-link/_index";
import { generateGISViewUrl } from "@app/products/property/components/reference-sidebar/map-with-spatial-link/util";
import { usePageType } from "@common/hooks/usePageType";
import { nameOfFactory } from "@common/utils/common";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { IMapTabProps } from "@components/cc-reference-sidebar/components/Map/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const nameOfVOAssessment = nameOfFactory<VO_Assessment>();
const nameOfDTOAssessment = nameOfFactory<DTO_Assessment>();
interface IAssessmentMapTabProps extends IMapTabProps {}

export const AssessmentMapTab = observer((props: IAssessmentMapTabProps) => {
  const { lastSelectedRow, lastSelectedId } = useCCProductListViewStore();
  const { assessment, assessmentId } = useAssessmentStore();
  const { isProductListView } = usePageType();

  const assessmentGISViewUrl: string | undefined = useMemo(() => {
    if (lastSelectedRow && !isNil(lastSelectedId))
      return generateGISViewUrl(
        lastSelectedId,
        lastSelectedRow?.[nameOfVOAssessment("GIS_View_Url")]
      );
    if (assessmentId && assessment?.Assessment?.GISViewUrl) {
      return generateGISViewUrl(
        assessmentId,
        assessment?.Assessment?.GISViewUrl
      );
    }
    return undefined;
  }, [assessment, lastSelectedRow, assessmentId, lastSelectedId]);

  const addressFieldName = useMemo(() => {
    if (isProductListView) return nameOfVOAssessment("Property_Address");
    return nameOfDTOAssessment("PropertyName_Address_Locality");
  }, [isProductListView]);

  return (
    <MapSpatialLinkTab
      {...props}
      addressField={addressFieldName}
      spatialLink={assessmentGISViewUrl}
    />
  );
});
