import {
  DTO_Workflow_Approval_Step,
  DTO_WorkflowHeader,
  EApprovalStatusName,
} from "@app/products/property/model";
import { isNil, maxBy } from "lodash";

export const isRefreshData = (
  workflowHeader: DTO_WorkflowHeader | undefined
): boolean => {
  // Refresh the manage page if finish or approve the workflow
  // Need to check the Requires_Approval to identify the workflow to approve or not to refresh
  if (!isNil(workflowHeader)) {
    // workflowHeader?.Flags?.Requires_Approval = false => Finish stage
    if (!workflowHeader.Flags?.Requires_Approval) return true;

    // If this is the last approval step => When user done the workflow, refresh
    const lastApprovalStep = maxBy(
      workflowHeader?.WorkflowApprovals,
      (item: DTO_Workflow_Approval_Step) => item.WorkflowStepSequence
    );

    return lastApprovalStep?.ApprovalStatusName === EApprovalStatusName.Current;
  }
  return false;
};
