import { ILeakage } from "@app/products/property/meters/[id]/components/child-screens/leakage/model";

export const mockLeakage: ILeakage = {
  Occurrences: [
    {
      LeakageOccurredApproxFrom: new Date("20 Jan 2022"),
      LeakageOccurredUpTo: new Date("25 Jan 2022"),
      EstimatedLeakage: 0,
      DescriptionOfLeak: "Description",
      EnteredBy: "communitycentral_dev",
      EnteredOn: new Date("25 Jan 2022 3:46"),
      MeterLeakageId: 3,
      IsLeakageTaken: true,
    },
  ],
  Report: [
    {
      Id: 1,
      DateTime: new Date("1 March 2015 0:00"),
      Memo: "Possible leakage identified by meter reader.water coming out of value",
    },
  ],
};
