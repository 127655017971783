import { CORE_GET_SCHEDULING_JOBS_ODATA } from "@app/core/components/common/constant";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { DeleteJobsSchedulingButton } from "@common/pages/settings/system-admin/task-scheduling/components/action-bar/delete-button/_index";
import { JobsSchedulingButton } from "@common/pages/settings/system-admin/task-scheduling/components/action-bar/new-button/_index";
import { ReinstateJobsButton } from "@common/pages/settings/system-admin/task-scheduling/components/action-bar/reinstate-button/_index";
import { RunJobsSchedulingButton } from "@common/pages/settings/system-admin/task-scheduling/components/action-bar/run-button/_index";
import { TaskSchedulingHistoryTab } from "@common/pages/settings/system-admin/task-scheduling/components/reference-sidebar/history/_index";
import { jobsSchedulingCol } from "@common/pages/settings/system-admin/task-scheduling/config";
import { nameOfScheduleJobsView } from "@common/pages/settings/system-admin/task-scheduling/model";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export default observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    const responseCheckGlobalSettings = await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );

    if (responseCheckGlobalSettings) {
      await checkPermissionForCurrentMenu(
        FormActionCheckPermission.SETTINGS_MENU,
        1
      );
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });
  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [
      <JobsSchedulingButton disabled={!hasPermission} />,
      <JobsSchedulingButton isNew={false} disabled={!hasPermission} />,
      <ReinstateJobsButton isDisabled={!hasPermission} />,
      <RunJobsSchedulingButton isDisabled={!hasPermission} />,
      <DeleteJobsSchedulingButton isDisabled={!hasPermission} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "History",
        component: <TaskSchedulingHistoryTab />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;
  return (
    <CCProductListView
      columnFields={jobsSchedulingCol}
      primaryField={nameOfScheduleJobsView("ID")}
      dataUrl={CORE_GET_SCHEDULING_JOBS_ODATA}
      disabled={!hasPermission}
      state={{
        sort: [
          {
            field: nameOfScheduleJobsView("JobName"),
            dir: "asc",
          },
        ],
      }}
    />
  );
});
