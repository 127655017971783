import { useIsNew } from "@common/hooks/useIsNew";
import { CheckPermissionSettingWrapper } from "@common/pages/settings/components/check-permission-wrapper/_index";
import { ExistedLookupKeyword } from "@common/pages/settings/lookups/keywords/_id/components/forms/existed/_index";
import { NewLookupKeyword } from "@common/pages/settings/lookups/keywords/_id/components/forms/new/_index";
import { ILookupKeywordsNotification } from "@common/pages/settings/lookups/keywords/_id/model";
import { useKeywordsStore } from "@common/pages/settings/lookups/keywords/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageLookupKeyword = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const location = useLocation();
  const keywordId = parseInt(params.id);
  const { resetStore, loadKeyword, setKeywordsNotification } =
    useKeywordsStore();
  const { resetMenu } = useCCSubActionBarStore();

  const [isHasPermission, setIsHasPermission] = useState<boolean>(false);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  useEffect(() => {
    if (location.state) {
      setKeywordsNotification(location.state as ILookupKeywordsNotification);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line
  }, [isNew]);

  useEffect(() => {
    if (isHasPermission) {
      loadKeyword(keywordId, isNew);
    }
  }, [keywordId, isNew, loadKeyword, isHasPermission]);

  return (
    <CheckPermissionSettingWrapper setIsHasPermission={setIsHasPermission}>
      {isNew ? <NewLookupKeyword /> : <ExistedLookupKeyword />}
    </CheckPermissionSettingWrapper>
  );
};

export default observer(ManageLookupKeyword);
