import { putApproveLetterPlansToComply } from "@app/products/town-planning/ppr/components/action-bar/dialogs/approve-letter-application/api";
import { DecisionDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/approve-letter-application/components/dialogs/decision/_index";
import { PPRApplicationIdentityPacket } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { APIResponse } from "@common/apis/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";

interface IApproveLetterApplicationDialogProps {
  onClose: () => void;
  onSubmit: (response: APIResponse<PPRApplicationIdentityPacket>) => void;
}

export const ApproveLetterApplicationDialog = observer(
  ({ onClose, onSubmit }: IApproveLetterApplicationDialogProps) => {
    const { pprId } = usePPRStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const handleOnSubmit = async (infoInadequate: boolean) => {
      if (!pprId) return;
      setIsLoading(true);
      const response = await putApproveLetterPlansToComply(
        pprId,
        infoInadequate
      );
      if (isSuccessIdentityPacket(response)) {
        onSubmit(response);
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: "Approve letter application failed",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
      setIsLoading(false);
    };

    return (
      <DecisionDialog
        onClose={onClose}
        onSubmitData={handleOnSubmit}
        isLoading={isLoading}
        notificationRef={notificationRef}
      />
    );
  }
);
