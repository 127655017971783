import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { renderInfoExpertContainerComponent } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/config";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { INFOXPERTSTORAGEMETHOD } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const RecordsInfoExpertEOTForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const { valueGetter } = formRenderProps;
    const showNewTrim =
      parseInt(
        valueGetter(
          ECorporateSettingsField.infoXpert_EOTStorageMethod.toString()
        )
      ) === INFOXPERTSTORAGEMETHOD.CREATE;
    if (!configData) return <></>;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <CCSearchComboBoxGlobalSettings
            data={
              configData[ECorporateSettingsField.infoXpert_EOTStorageMethod]
            }
            isEditing={isEditing}
          />
          {renderInfoExpertContainerComponent(
            formRenderProps,
            isEditing,
            configData[ECorporateSettingsField.infoXpert_EOTPrimaryFolderID],
            configData[ECorporateSettingsField.infoXpert_EOTPrimaryFolderName]
          )}
          {showNewTrim && (
            <InputGlobalSettings
              data={
                configData[ECorporateSettingsField.infoXpert_EOTTitleFormat]
              }
              isEditing={isEditing}
            />
          )}
        </div>
      </section>
    );
  }
);
