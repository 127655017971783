import { CCGrid } from "@components/cc-grid/_index";
import React, { useEffect, useState } from "react";
import { loadAttributes } from "./api";
import { colAttributes } from "./config";

const DeferredDutyAttributes = () => {
  const [data, setData] = useState<any[]>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    loadAttributes().then((data) => {
      setData(data);
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="cc-assessment-attributes-uda">
      <CCGrid
        isLoading={isLoading}
        className="cc-attributes-uda-grid"
        data={data || []}
        columnFields={colAttributes}
        primaryField="ID"
        editableMode="row"
        itemPerPage={10}
      />
    </div>
  );
};

export default DeferredDutyAttributes;
