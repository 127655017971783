import { colRegisterNames } from "@app/products/property/registers/[id]/components/child-screens/names/config";
import { useRegisterNamesStore } from "@app/products/property/registers/[id]/components/child-screens/names/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";
import { IRegisterNamesFields, IRegisterNamesGrid } from "./model";

const nameOf = nameOfFactory<IRegisterNamesFields>();
const nameOfGrid = nameOfFactory<IRegisterNamesGrid>();

export const RegisterNames = observer(() => {
  const { loadRegisterNames, registerNames, isLoading } =
    useRegisterNamesStore();

  useEffectOnce(() => {
    loadRegisterNames();
  });
  const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <>
      <div className="cc-grid-control-between">
        <div className="cc-grid-control-left">
          <label className="cc-label">Associated contacts</label>
        </div>
        <div className="cc-grid-control-right">
          <Checkbox
            title="Include previous contacts"
            label="Include previous contacts"
          />
        </div>
      </div>
      <CCGrid
        data={registerNames?.RegisterNamesGrid || []}
        columnFields={colRegisterNames}
        primaryField={nameOfGrid("EntityNameAddrId")}
      />
      <br />
      <div className="cc-form">
        <Form
          initialValues={registerNames?.RegisterNamesFields}
          render={(formRenderProps: FormRenderProps) => {
            const { onChange } = formRenderProps;
            return (
              <FormElement>
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Name</label>
                      <Field
                        name={nameOf("Name")}
                        component={CCInput}
                        placeholder="Name"
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Gender</label>
                      <Field
                        name={nameOf("Gender")}
                        component={CCInput}
                        placeholder="Gender"
                        readOnly
                      />
                    </div>

                    <div className="cc-field">
                      <label className="cc-label">Occupation</label>
                      <Field
                        name={nameOf("Occupation")}
                        component={CCInput}
                        placeholder="Occupation"
                        readOnly
                      />
                    </div>

                    <div className="cc-field">
                      <label className="cc-label">Date of birth</label>
                      <Field
                        name={nameOf("DateOfBirth")}
                        format={DATE_FORMAT.DATE_CONTROL}
                        component={CCDatePicker}
                        disabled
                      />
                    </div>

                    <div className="cc-field">
                      <Field
                        label="Email address"
                        name={nameOf("EmailAddress")}
                        placeholder="Email address"
                        readOnly
                        component={CCInputEmail}
                        validator={validateEmail}
                        onChangeForm={onChange}
                      />
                    </div>
                  </div>

                  <section>
                    <label className="cc-label">Phone numbers</label>
                    <div className="cc-custom-sub-panel-bar">
                      <section className="cc-field-group">
                        <div className="cc-form-cols-3">
                          <div className="cc-field">
                            <label className="cc-label">Private</label>
                            <Field
                              name={nameOf("Private")}
                              placeholder="Private"
                              readOnly
                              component={CCInputPhone}
                              validator={validatePhoneNumber}
                              onChangeForm={onChange}
                              skipValidatePhoneMask={
                                !isEnableValidatePhoneNumber
                              }
                              mode={EModePhoneNumber.Private}
                            />
                          </div>

                          <div className="cc-field">
                            <label className="cc-label">Business</label>
                            <Field
                              name={nameOf("Business")}
                              placeholder="Business"
                              readOnly
                              component={CCInputPhone}
                              validator={validatePhoneNumber}
                              onChangeForm={onChange}
                              skipValidatePhoneMask={
                                !isEnableValidatePhoneNumber
                              }
                              mode={EModePhoneNumber.Business}
                            />
                          </div>

                          <div className="cc-field">
                            <label className="cc-label">Mobile</label>
                            <Field
                              name={nameOf("Mobile")}
                              placeholder="Mobile"
                              readOnly
                              component={CCInputPhone}
                              validator={validatePhoneNumber}
                              onChangeForm={onChange}
                              skipValidatePhoneMask={
                                !isEnableValidatePhoneNumber
                              }
                              mode={EModePhoneNumber.Mobile}
                            />
                          </div>

                          <div className="cc-field">
                            <label className="cc-label">Fax</label>
                            <Field
                              name={nameOf("Fax")}
                              component={CCInput}
                              placeholder="Fax"
                              readOnly
                              validator={validatePhoneNumber}
                              onChangeForm={onChange}
                              skipValidatePhoneMask={
                                !isEnableValidatePhoneNumber
                              }
                              mode={EModePhoneNumber.Fax}
                            />
                          </div>
                        </div>
                      </section>
                    </div>
                  </section>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Address</label>
                      <Field
                        name={nameOf("Address")}
                        component={CCTextArea}
                        placeholder="Address"
                        readOnly
                        rows={3}
                      />
                    </div>

                    <div className="cc-field">
                      <label className="cc-label">Alternate address</label>
                      <Field
                        name={nameOf("AlternateAddress")}
                        component={CCTextArea}
                        placeholder="Alternate address"
                        readOnly
                        rows={3}
                      />
                    </div>

                    <div className="cc-field">
                      <label className="cc-label">Notes</label>
                      <Field
                        name={nameOf("Notes")}
                        component={CCTextArea}
                        placeholder="Notes"
                        readOnly
                        rows={3}
                      />
                    </div>
                  </div>
                </section>
              </FormElement>
            );
          }}
        />
      </div>
    </>
  );
});
