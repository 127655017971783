import { FirePreventionMemo } from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/memos/model";
import { useFirePreventionMemoStore } from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/memos/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { colMemos } from "./config";

const nameOf = nameOfFactory<FirePreventionMemo>();

export const FirePreventionMemos = observer(() => {
  const { isLoading, memos, loadMemo } = useFirePreventionMemoStore();
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    if (id) loadMemo(parseInt(id));
  }, [loadMemo, id]);

  return (
    <CCGrid
      isLoading={isLoading}
      data={memos || []}
      columnFields={colMemos}
      primaryField={nameOf("MemoId")}
    />
  );
});
