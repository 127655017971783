import { CORE_OFFICERS_ODATA } from "@app/core/components/common/constant";
import { odataCoreGetKeywordsByType } from "@app/core/components/common/utils";
import { Officers } from "@app/core/inspections/[id]/model";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { KeywordLite } from "@common/models/keyword";
import { nameOfFactory } from "@common/utils/common";

const nameOfOfficer = nameOfFactory<Officers>();
export const permitOptionOfficer: IOptionInputPicker = {
  dialog: {
    titleHeader: "Pick Officer",
    maxWidth: "60%",
  },
  boxSearch: {
    colSearch: nameOfOfficer("Name"),
    colShowComboboxSearch: [{ field: nameOfOfficer("Name"), title: "Name" }],
  },
  grid: {
    columnFields: [
      { field: nameOfOfficer("Name"), title: "Name" },
      {
        field: nameOfOfficer("Position"),
        title: "Position",
      },
      { field: nameOfOfficer("Email"), title: "Email" },
      { field: nameOfOfficer("WorkPhone"), title: "Phone" },
    ],
    primaryField: nameOfOfficer("ID"),
    dataUrl: CORE_OFFICERS_ODATA,
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
    state: {
      sort: [
        {
          field: nameOfOfficer("Name"),
          dir: "asc",
        },
      ],
    },
  },
};

const nameOfKeyword = nameOfFactory<KeywordLite>();
export const permitOptionPlacement: IOptionInputPicker = {
  dialog: {
    titleHeader: "Placement",
    maxWidth: "60%",
  },
  boxSearch: {
    colSearch: nameOfKeyword("Name"),
    colShowComboboxSearch: [{ field: nameOfKeyword("Name"), title: "Name" }],
  },
  grid: {
    columnFields: [{ field: nameOfKeyword("Name"), title: "Name" }],
    primaryField: nameOfKeyword("Keyword_ID"),
    dataUrl: odataCoreGetKeywordsByType(
      KEYWORD_TYPE.LLP_Placement,
      PRODUCT_TYPE_NUMBER.LLPermits
    ),
    selectableMode: "multiple",
    isAutoHiddenPager: false,
    itemPerPage: 10,
    state: {
      sort: [
        {
          field: nameOfKeyword("Name"),
          dir: "asc",
        },
      ],
    },
  },
};

export const permitOptionPurpose: IOptionInputPicker = {
  dialog: {
    titleHeader: "Purpose",
    maxWidth: "60%",
  },
  boxSearch: {
    colSearch: nameOfKeyword("Name"),
    colShowComboboxSearch: [{ field: nameOfKeyword("Name"), title: "Name" }],
  },
  grid: {
    columnFields: [{ field: nameOfKeyword("Name"), title: "Name" }],
    primaryField: nameOfKeyword("Keyword_ID"),
    dataUrl: odataCoreGetKeywordsByType(
      KEYWORD_TYPE.LLP_Purpose,
      PRODUCT_TYPE_NUMBER.LLPermits
    ),
    selectableMode: "multiple",
    isAutoHiddenPager: false,
    itemPerPage: 10,
    state: {
      sort: [
        {
          field: nameOfKeyword("Name"),
          dir: "asc",
        },
      ],
    },
  },
};
