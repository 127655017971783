import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSPriorityofAccessGroups: IColumnFields[] = [
  {
    field: CRSFieldMapping.CategoryName,
    title: "Group Name",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  { field: CRSFieldMapping.SortOrder, title: "Group Sort" },
];
