import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import {
  DTO_Parcel_LOVs,
  DTO_Workflow_ModifyParcel,
  RequestParcelModifyObj,
} from "@app/products/property/parcels/components/form-steps/modify-parcel/model";

import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export type IGetInitialDataParcel =
  | [APIResponse<DTO_Parcel_LOVs>, APIResponse<DTO_Workflow_ModifyParcel>]
  | APIResponse<DTO_Parcel_LOVs | DTO_Workflow_ModifyParcel>
  | undefined;
export const getInitialDataParcel = async (
  requestObjFromSpatial: RequestParcelModifyObj,
  workflowDraftId: number | undefined
): Promise<IGetInitialDataParcel> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Parcel_LOVs>(
        `/api/property/internal/parcel/0/lovs` //Default 0 using for all Parcel
      ),
      !isNil(workflowDraftId)
        ? getWorkflowParcel(workflowDraftId)
        : getNewWorkflowParcel(requestObjFromSpatial),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowParcel = async (
  requestObjSpatial: RequestParcelModifyObj
): Promise<APIResponse<DTO_Workflow_ModifyParcel>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/modifyparcel/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowParcel = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_ModifyParcel>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/modifyparcel/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessWorkflowParcel = async (
  modeProcess: WorkflowProcessMode,
  dataParcel: DTO_Workflow_ModifyParcel
): Promise<APIResponse<ResponseMessageWorkflow<DTO_Workflow_ModifyParcel>>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/modifyparcel/process/${modeProcess}`,
      dataParcel
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
