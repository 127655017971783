import { ILovDetail } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/details/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NewPICDetailStepStore {
  private _lovDetail?: ILovDetail = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get lovDetail() {
    return toJS(this._lovDetail);
  }
  setLovDetail = (lov: ILovDetail) => {
    runInAction(() => {
      this._lovDetail = lov;
    });
  };
}

const newPICDetailStepStoreContext = createContext(new NewPICDetailStepStore());
export const useNewPICDetailStepStoreContext = () => {
  return useContext(newPICDetailStepStoreContext);
};
