import { VO_Notice_Assessment } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/child-screens/notices/model";
import { useNoticeRunNoticesStore } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/child-screens/notices/store";
import { useNoticeRunsStore } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<VO_Notice_Assessment>();
export const NoticeRunsNotices = observer(() => {
  const {
    isLoading,
    loadConfigs,
    responseLoadError,
    reLoadApiConfig,
    columnNotice,
    resetStore,
  } = useNoticeRunNoticesStore();
  const { noticeRunsId } = useNoticeRunsStore();

  useEffectOnce(() => {
    loadConfigs();
    return () => resetStore();
  });

  //show Loading
  if (isLoading) return <Loading isLoading />;

  //Show load failed and reload
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          reLoadApiConfig();
        }}
      />
    );

  return (
    <CCGrid
      isLoading={isLoading}
      dataUrl={`/odata/property/internal/noticerunassessmentregister/${noticeRunsId}?$count=true&`}
      columnFields={columnNotice}
      primaryField={nameOf("Notice_Id")}
    />
  );
});
