import { IGroup } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/groups-grid/model";
import { IUsers } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/users-grid/model";

export interface IDataUserGroupOrgForm {
  OrgStructure?: IGroup[];
  Users?: IUsers[];
  Groups?: IGroup[];
}

export const ConfigUserGroupOrg = {
  OrgStructure: "OrgStructure",
  Users: "Users",
  Groups: "Groups",
};
