import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const noticeRunsListRoute: ICCRoute = {
  path: "notice-runs",
  name: "Notice Runs",
  enum: eMenuAction.DeferredDuty_List_Statement_Runs,
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
