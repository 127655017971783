import { getParcelTitles } from "@app/products/property/parcels/[id]/components/child-screens/titles/api";
import { DTO_Title_Ext } from "@app/products/property/parcels/[id]/components/child-screens/titles/model";
import { convert } from "@app/products/property/parcels/[id]/components/child-screens/titles/util";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class ParcelTitleStore {
  private _title?: DTO_Title_Ext[] = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get title() {
    return this._title;
  }
  setTitle = (title?: DTO_Title_Ext[]) => {
    runInAction(() => {
      this._title = title;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  loadTitle = async (parcelId: number) => {
    this.setIsLoading(true);

    await getParcelTitles(parcelId).then((response) => {
      if (
        response?.AssociatedTitles &&
        response.AssociatedTitles.length !== 0
      ) {
        let associatedTitles: DTO_Title_Ext[] = [];
        response?.AssociatedTitles?.forEach((item) => {
          associatedTitles.push(convert(item));
        });
        this.setTitle(associatedTitles);
      }
    });

    this.setIsLoading(false);
  };
}

const ParcelTitleStoreContext = createContext(new ParcelTitleStore());
export const useParcelTitleStore = () => {
  return useContext(ParcelTitleStoreContext);
};
