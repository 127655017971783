import { eComponent } from "@app/products/property/components/associations/model";
import { getContact } from "@app/products/property/components/reference-sidebar/contact/api";
import {
  DTO_Component_Contacts,
  DTO_Contact,
} from "@app/products/property/components/reference-sidebar/contact/model";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { isFieldVisible } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { isNil } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./_index.scss";

export interface IPropertyContactTabCommonProps {
  id?: number | null;
  componentNumber: eComponent;
}

export const PropertyContactTabCommon = ({
  id,
  componentNumber,
}: IPropertyContactTabCommonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<DTO_Component_Contacts | undefined>();
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const loadContactData = useCallback(async () => {
    if (isNil(id)) {
      setData(undefined);
      return;
    }
    setIsLoading(true);
    await getContact(id, componentNumber).then((response) => {
      let newData: DTO_Component_Contacts;
      let errorResponse = undefined;
      if (isSuccessResponse(response) && response.data) {
        newData = response.data;
        setData(newData);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error ?? "Load contact failed",
        };
      }
      setIsLoading(false);
      setResponseLoadError(errorResponse);
    });
  }, [id, componentNumber]);

  useEffect(() => {
    loadContactData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, componentNumber]);

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadContactData();
        }}
      />
    );

  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <ContactsSidebar
      data={data?.Contact?.filter((contact) => contact?.IsActive) || []}
    />
  );
};

export interface IContactsSidebarProps {
  data: DTO_Contact[];
}
export const ContactsSidebar: React.FC<IContactsSidebarProps> = ({ data }) => {
  return (
    <div className="cc-contact-side-page">
      {(!data || data.length === 0) && <NoData />}
      {data.length > 0 &&
        data.map((item: DTO_Contact, index) => (
          <ContactItemRender dataItem={item} key={index} />
        ))}
    </div>
  );
};

export interface IContactItemProps {
  dataItem: DTO_Contact;
}
const ContactItemRender = (props: IContactItemProps) => {
  const dataItem: DTO_Contact = props.dataItem;
  return (
    <div className="cc-contact-card">
      <div className="cc-contact-field cc-contact-field-title">
        <Link
          className="cc-contact-link"
          to={`${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem.Id}`}
        >
          {dataItem.Name || "N/A"}
        </Link>
        <span>&nbsp; ({dataItem.EntityRole})</span>
      </div>

      <div className="cc-contact-field">
        Email:{" "}
        {(
          <a
            className={`cc-contact-link ${
              dataItem.EntityRole === "3" ? "cc-contact-link-preferred" : " "
            }`}
            href={`mailto:${dataItem.EmailAddress}`}
          >
            {dataItem.EmailAddress}
          </a>
        ) || "N/A"}
      </div>

      {isFieldVisible(dataItem.PhoneNumber.MobilePhoneNumberVisibility) && (
        <div className="cc-contact-field">
          Mobile:{" "}
          {(
            <a
              href={`tel:${dataItem.PhoneNumber.PhoneNumberMobile}`}
              className={`cc-contact-link`}
            >
              {dataItem.PhoneNumber.PhoneNumberMobile}
            </a>
          ) || "N/A"}
        </div>
      )}

      {isFieldVisible(dataItem.PhoneNumber.BusinessPhoneNumberVisibility) && (
        <div className="cc-contact-field">
          Work Phone:{" "}
          {(
            <a
              href={`tel:${dataItem.PhoneNumber.PhoneNumberBusiness}`}
              className={`cc-contact-link`}
            >
              {dataItem.PhoneNumber.PhoneNumberBusiness}
            </a>
          ) || "N/A"}
        </div>
      )}
    </div>
  );
};
