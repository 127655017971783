import { DTO_ChangeOfOwnership } from "@app/products/property/assessments/[id]/components/child-screens/transfer/model";
import { OwnershipDetail } from "@app/products/property/parcels/[id]/components/child-screens/ownership/components/detail/_index";
import { colFieldsParcelOwnership } from "@app/products/property/parcels/[id]/components/child-screens/ownership/config";
import { useParcelOwnershipStore } from "@app/products/property/parcels/[id]/components/child-screens/ownership/store";
import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

const nameOf = nameOfFactory<DTO_ChangeOfOwnership>();
export const ParcelOwnership = observer(() => {
  const { isLoading, loadParcelOwnership, parcelOwnership, resetStore } =
    useParcelOwnershipStore();
  const { parcelId } = useParcelsStore();
  useEffect(() => {
    if (!parcelId) return;
    loadParcelOwnership(parcelId);
    return () => {
      resetStore();
    };
  }, [loadParcelOwnership, parcelId, resetStore]);

  return (
    <div className="cc-parcel-ownership">
      <label className="cc-label">Changes of ownership</label>
      <CCGrid
        isLoading={isLoading}
        className="cc-parcel-ownership-grid"
        data={parcelOwnership ?? []}
        columnFields={colFieldsParcelOwnership}
        primaryField={nameOf("Id")}
        isLimitDetailWidth
        detail={OwnershipDetail}
      />
    </div>
  );
});
