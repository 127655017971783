import { DirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/model";
import { DIRECT_DEBIT_AUTHORISATIONS } from "@app/products/direct-debit/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DirectDebitAuthorisation>();

export const colDirectDebitAuthorisation: IColumnFields[] = [
  {
    field: nameOf("Authorisation_Number"),
    title: "Authorisation Number",
    locked: true,
    linkTo: (dataItem: DirectDebitAuthorisation) => {
      return `${DIRECT_DEBIT_AUTHORISATIONS}/` + dataItem.Authorisation_Id;
    },
  },
  {
    field: nameOf("Authorised_By_Name"),
    title: "Authorised by Name",
  },
  {
    field: nameOf("Authorised_Date"),
    title: "Authorised Date",
    format: DATE_FORMAT.DATE,
  },
  { field: nameOf("EMail"), title: "Email" },
  { field: nameOf("Phone_No"), title: "Phone Number" },
  {
    field: nameOf("Mobile_Phone_No"),
    title: "Mobile Phone Number",
  },
  {
    field: nameOf("Authorisation_Id"),
    title: "Authorisation ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
