import { CSLActionBarNavDropdown } from "@app/products/csl/components/action-bar/nav-dropdown/_index";
import { systemAdminRoute } from "@app/products/csl/system-admin/route";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { NoData } from "@components/no-data/NoData";
import React from "react";

export default function () {
  useCCListViewActionBar({
    title: systemAdminRoute.name,
    leftComponents: [
      <CSLActionBarNavDropdown category={systemAdminRoute.path} />,
    ],
  });
  return <NoData />;
}
