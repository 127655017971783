import { useIsNew } from "@common/hooks/useIsNew";
import { ACTIONSTATUS } from "@common/pages/actions/[id]/model";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { useCancelMeetingButtonStore } from "@common/pages/actions/components/action-bar/buttons/cancel-meeting/store";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IActionCancelMeetingButtonProps {
  disabled?: boolean;
}

export const ActionCancelMeetingButton = observer(
  ({ disabled }: IActionCancelMeetingButtonProps) => {
    const { action, onSubmit, isMeeting } = useActionStore();
    const { isLoading } = useCancelMeetingButtonStore();
    const isNew = useIsNew();

    const isVisible = useMemo(() => {
      const isApproval = action?.ActionType?.IsApproval;
      if (
        (isNew || action?.Status_ENUM === ACTIONSTATUS.NEW) &&
        !action?.ActionType?.IsRegulatoryAction
      ) {
        if (!isApproval && (isMeeting || action?.ActionType?.Flag_IsMeeting)) {
          return true;
        }
      }

      return false;
    }, [action, isNew, isMeeting]);

    return isVisible ? (
      <CCNavButton
        title="Cancel meeting"
        onClick={onSubmit}
        name={
          isNew
            ? ActionSubmitActions.CreateAndCancelMeeting
            : ActionSubmitActions.CancelMeeting
        }
        isLoading={isLoading}
        disabled={disabled}
      />
    ) : null;
  }
);
