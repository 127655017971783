import { currentRoute } from "@app/products/property/fire-prevention/current/route";
import { historicalRoute } from "@app/products/property/fire-prevention/historical/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const firePreventionRoute: ICCRoute = {
  path: "fire-prevention",
  name: "Fire Prevention",
  enum: eMenuAction.Fire_Prevention_Folder,
  children: [currentRoute, historicalRoute],
};

export const firePreventionManagePageRoute: ICCRoute = {
  path: "fire-prevention/hazld-case",
  name: "Hazld Case",
  enum: eMenuAction.Fire_Prevention_Folder,
  isVisible: false,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./hazld-case/[id]/_index").default,
    },
  ],
};

export const propertyFirePreventionRoute: ICCRoute[] = [
  firePreventionRoute,
  firePreventionManagePageRoute,
];
