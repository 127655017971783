import {
  getCertificateBalance,
  getCertificateBalanceViews,
} from "@app/products/property/certificates/[id]/components/child-screens/balances/api";
import {
  colBalancesFinancial,
  colRebates,
} from "@app/products/property/certificates/[id]/components/child-screens/balances/config";
import {
  CertificateMode,
  DTO_Certificate_AreaBalances,
  IErrorResponseCertificate,
} from "@app/products/property/certificates/[id]/components/child-screens/balances/model";
import { processDynamicColumns } from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { IColumnFields } from "@components/cc-grid/model";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class CertificateBalanceStore {
  private _isLoading: CertificateMode | undefined = undefined;
  private _data: DTO_Certificate_AreaBalances | undefined = undefined;
  private _colRebate: IColumnFields[] = colRebates;
  private _colBalances: IColumnFields[] = colBalancesFinancial;
  private _responseLoadError?: IErrorResponseCertificate = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading?: CertificateMode) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get colRebate() {
    return this._colRebate;
  }
  setColRebate = (columnFields: IColumnFields[]) => {
    runInAction(() => {
      this._colRebate = columnFields;
    });
  };

  get colBalances() {
    return this._colBalances;
  }
  setColBalance = (columnFields: IColumnFields[]) => {
    runInAction(() => {
      this._colBalances = columnFields;
    });
  };

  get data() {
    return toJS(this._data);
  }
  setData = (data?: DTO_Certificate_AreaBalances) => {
    runInAction(() => {
      this._data = data;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: IErrorResponseCertificate) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  loadViews = async () => {
    this.setIsLoading(CertificateMode.ALL);
    let errorResponse = undefined;
    const response = await getCertificateBalanceViews();

    if (Array.isArray(response) && response[0]?.data && response[1]?.data) {
      const [viewBalance, viewRebate] = response;
      if (viewBalance?.data) {
        this.setColBalance(
          processDynamicColumns(
            colBalancesFinancial,
            viewBalance?.data?.ColumnDefinitions?.Columns ?? []
          ) ?? []
        );
      }
      if (viewRebate?.data) {
        this.setColRebate(
          processDynamicColumns(
            colRebates,
            viewRebate?.data?.ColumnDefinitions?.Columns ?? []
          ) ?? []
        );
      }
    } else {
      errorResponse = {
        status: APIResponseStatus.BAD_REQUEST,
        error: "Load views failed.",
      } as any;
    }
    this.setResponseLoadError({ View: errorResponse });
    this.setIsLoading();
  };

  loadCertificate = async (certificateId?: number) => {
    if (isNil(certificateId)) return;
    this.setIsLoading(CertificateMode.DATA);
    const newCertificate = await getCertificateBalance(certificateId);
    let errorResponse = undefined;
    if (isSuccessResponse(newCertificate) && newCertificate?.data) {
      this.setData(newCertificate.data);
    } else {
      errorResponse = {
        status: newCertificate.status,
        error: newCertificate.error,
      };
    }
    this.setIsLoading();
    this.setResponseLoadError({ Data: errorResponse });
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = undefined;
      this._responseLoadError = undefined;
      this._data = undefined;
    });
  };
}

export const certificateBalanceStoreInstance = new CertificateBalanceStore();
const certificateBalanceStoreContext = createContext(
  certificateBalanceStoreInstance
);
export const useCertificateBalanceStore = () => {
  return useContext(certificateBalanceStoreContext);
};
