import { Svc_Contact_ContactRole } from "@app/core/contacts/_id/components/child-screens/contact-roles/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { getPageLinkByRecordType } from "@common/utils/getPageLinkByRecordType";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_Contact_ContactRole>();
export const colContactRoles: IColumnFields[] = [
  {
    field: nameOf("LookupRecord_ID"),
    title: "Association ID",
    locked: true,
    linkTo: (dataItem: Svc_Contact_ContactRole) =>
      getPageLinkByRecordType(
        dataItem.LookupRecordType_ENUM,
        dataItem.LookupRecord_ID
      ),
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("LookupRecordType"),
    title: "Association",
  },
  {
    field: nameOf("Relationship"),
    title: "Relationship",
  },
  {
    field: nameOf("LookupDescription"),
    title: "Description",
  },
];
