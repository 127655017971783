import { getFIRequestDetailTab } from "@app/core/further-info/[id]/components/reference-sidebar/detail/api";
import { FIRequestTabDetails } from "@app/core/further-info/[id]/components/reference-sidebar/detail/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  BubbleupType_ENUM,
  Svc_BubbleupIdentifier,
} from "@common/pages/actions/[id]/components/reference-sidebar/details/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class FurtherInfoDetailTabStore {
  private _furtherInfoDetailTab?: FIRequestTabDetails = undefined;
  private _furtherInfoBubbleUpParent?: Svc_BubbleupIdentifier = undefined;
  private _furtherInfoBubbleUpAncestor?: Svc_BubbleupIdentifier = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get furtherInfoDetailTab() {
    return toJS(this._furtherInfoDetailTab);
  }
  setFurtherInfoDetailTab = (
    furtherInfoDetailTab: FIRequestTabDetails | undefined
  ) => {
    runInAction(() => {
      this._furtherInfoDetailTab = furtherInfoDetailTab;
    });
  };

  get furtherInfoBubbleUpParent() {
    return toJS(this._furtherInfoBubbleUpParent);
  }
  setFurtherInfoBubbleUpParent = (
    furtherInfoBubbleUpParent: Svc_BubbleupIdentifier | undefined
  ) => {
    runInAction(() => {
      this._furtherInfoBubbleUpParent = furtherInfoBubbleUpParent;
    });
  };

  get furtherInfoBubbleUpAncestor() {
    return toJS(this._furtherInfoBubbleUpAncestor);
  }
  setFurtherInfoBubbleUpAncestor = (
    furtherInfoBubbleUpAncestor: Svc_BubbleupIdentifier | undefined
  ) => {
    runInAction(() => {
      this._furtherInfoBubbleUpAncestor = furtherInfoBubbleUpAncestor;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._furtherInfoDetailTab = undefined;
      this._isLoading = false;
      this._furtherInfoBubbleUpParent = undefined;
      this._furtherInfoBubbleUpParent = undefined;
    });
  };

  loadFurtherInfoDetailTab = async (furtherInfoId: number) => {
    this.setIsLoading(true);
    getFIRequestDetailTab(furtherInfoId).then((response) => {
      if (Array.isArray(response)) {
        const [detailTabResponse, bubbleupDetailsResponse] = response;
        const detailTabData = detailTabResponse?.data;
        const bubbleupDetailsData = bubbleupDetailsResponse?.data;
        if (
          isSuccessResponse(detailTabResponse) &&
          isSuccessResponse(bubbleupDetailsResponse) &&
          detailTabData
        ) {
          this.setFurtherInfoDetailTab(detailTabData);

          if (bubbleupDetailsData) {
            //Set parent info
            this.setFurtherInfoBubbleUpParent(
              bubbleupDetailsData.BubbleList.find(
                (bubbleUpItem: Svc_BubbleupIdentifier) =>
                  bubbleUpItem.BubbleupType_ENUM === BubbleupType_ENUM.Parent
              )
            );

            //Set ancestor info
            if (!bubbleupDetailsData.AncestorIsSameAsParent) {
              this.setFurtherInfoBubbleUpAncestor(
                bubbleupDetailsData.BubbleList.find(
                  (bubbleUpItem: Svc_BubbleupIdentifier) =>
                    bubbleUpItem.BubbleupType_ENUM ===
                    BubbleupType_ENUM.Ancestor
                )
              );
            }
          }
        } else {
          //TODO:Enhance
          //console.log("Detail load failed");
        }
        this.setIsLoading(false);
      }
    });
  };
}
const FurtherInfoDetailTabStoreContext = createContext(
  new FurtherInfoDetailTabStore()
);
export const useFurtherInfoDetailTabStore = () => {
  return useContext(FurtherInfoDetailTabStoreContext);
};
