import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const paymentsRoute: ICCRoute = {
  path: "payments",
  name: "Payments",
  enum: eMenuAction.DebtRecovery_ListPaymentsReceived,
  children: [
    {
      path: "all",
      name: "All",
      component: require("./all/_index").default,
      enum: eMenuAction.DebtRecovery_ListPaymentsReceived_All,
    },
    {
      path: "last",
      name: "Last",
      enum: eMenuAction.DebtRecovery_ListPaymentsReceived_Last,
      component: require("./last/_index").default,
    },
  ],
};
