import { ChatSidebar } from "@app/core/chat/ask-ai/reference-sidebar/_index";
import { useAskAIServiceStore } from "@app/core/chat/ask-ai/store";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { CRMSPhoneMessageForm } from "@app/products/crms/phone-messages/[id]/components/forms/components/child-screens/general/_index";
import { CRMSPhoneMessageDetailsTab } from "@app/products/crms/phone-messages/[id]/components/forms/components/reference-sidebar/details/_index";
import { CRMSPhoneMessageHistoryTab } from "@app/products/crms/phone-messages/[id]/components/forms/components/reference-sidebar/history/_index";
import { PhoneMessageStatus } from "@app/products/crms/phone-messages/[id]/model";
import { useCRMSPhoneMessageStore } from "@app/products/crms/phone-messages/[id]/store";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { splitCamelCase } from "@common/utils/formatting";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedCRMSPhoneMessage = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const managePageUrl = window.location.pathname;
  const {
    loadCRMSPhoneMessage,
    existCrmsPhoneMessage,
    responseLoadError,
    isLoadingExisted,
    crmsPhoneMessageId,
    onSubmit,
    setIsShowConvertToEventDialog,
    setIsShowForwardOnDialog,
  } = useCRMSPhoneMessageStore();
  const { currentUserInfo } = useGlobalStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_PhoneMessage,
    productType: PRODUCT_TYPE_NUMBER.CRMS_PhoneMessages,
  });
  const { settings } = useCommonCoreStore();
  const actionOfficerLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_ActionOfficerLabel]
  );
  const cxLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_ProductName]
  );
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${CRMS_PRODUCT_NAME} Phone Message`,
      LinkUrl: managePageUrl,
      LinkText: `${CRMS_PRODUCT_NAME} - Phone Messages - ${
        crmsPhoneMessageId ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CRMS_PhoneMessages,
      RecordType_ENUM: RECORDTYPE.CUSTOMERSERVICE_PhoneMessage,
      Record_ID: crmsPhoneMessageId ? +crmsPhoneMessageId : 0,
    },
  ];

  const isDisable =
    existCrmsPhoneMessage?.Status_ENUM === PhoneMessageStatus.Completed;
  const listPanelBar: ITabProps[] = [
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments
          id={parseInt(id)}
          recordType={RECORDTYPE.CUSTOMERSERVICE_PhoneMessage}
        />
      ),
    },
  ];

  return (
    <>
      <Loading
        isLoading={isLoadingExisted || isLoadingPermission}
        isFullScreen
      />
      <FormNavigation title={`${cxLabel ?? CRMS_PRODUCT_NAME} Phone Message`} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCRMSPhoneMessage(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle
            title={existCrmsPhoneMessage?.Event_ReferenceNo}
            badge={
              existCrmsPhoneMessage &&
              splitCamelCase(
                PhoneMessageStatus[existCrmsPhoneMessage?.Status_ENUM] || ""
              )
            }
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
                isDefaultActive
              >
                <CCNavButton
                  title={"Convert to event"}
                  onClick={() => {
                    setIsShowConvertToEventDialog(true);
                  }}
                  disabled={isDisable}
                />
                <CCNavButton
                  title={"Message complete"}
                  onClick={onSubmit}
                  name={ActionSubmitActions.CompleteMessage}
                  disabled={isDisable}
                />
                <CCNavButton
                  title={"Forward on"}
                  onClick={() => {
                    setIsShowForwardOnDialog(true);
                  }}
                  disabled={isDisable}
                />
                <CCNavButton
                  title={
                    actionOfficerLabel
                      ? `${actionOfficerLabel} comment`
                      : "Action officer comment"
                  }
                  name={ActionSubmitActions.ActionOfficerComment}
                  disabled={isDisable}
                  onClick={onSubmit}
                />
              </CCNavButton>,
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
                disabled={
                  isDisable || !checkPermissions(FormAction.CORE_ALLOW_EDIT)
                }
              />,
              <CCNavButton title={"Add"} type="sub">
                <AddCommentButton
                  id={id}
                  recordType={RECORDTYPE.CUSTOMERSERVICE_PhoneMessage}
                  isDisabled={isDisable}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {existCrmsPhoneMessage && (
                  <>
                    <CRMSPhoneMessageForm />
                    <TabTable
                      id={parseInt(id)}
                      recordType={RECORDTYPE.CUSTOMERSERVICE_PhoneMessage}
                      initialPanels={listPanelBar}
                    />
                  </>
                )}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <CRMSPhoneMessageDetailsTab />,
                    },
                    {
                      title: "History",
                      component: <CRMSPhoneMessageHistoryTab />,
                    },
                    {
                      title: "Ask AI",
                      isVisible: useAskAIServiceStore().isShowAskAI,
                      component: (
                        <ChatSidebar
                          productId={PRODUCT_TYPE_NUMBER.CustomerService}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
