import { Svc_ConditionReference } from "@app/core/condition/components/dialogs/list-condition-references/model";
import { AddConditionButton } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/components/form-element/components/conditions-section/components/button/add-condition/_index";
import { RemoveConditionsButton } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/components/form-element/components/conditions-section/components/button/remove-condition/_index";
import { colPermitTypeConditions } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/components/form-element/components/conditions-section/config";
import {
  PermitType,
  PermitTypeUiControl,
} from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IConditionsSectionProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<PermitType>();

const nameOfPermitTypeConditions = nameOfFactory<IKeyValuePacket>();

export const ConditionsSection = observer(
  ({ formRenderProps }: IConditionsSectionProps) => {
    const { dataForms } = useFlexibleFormStore();
    const { isDeleted } = dataForms?.GeneralUIControl as PermitTypeUiControl;

    const { valueGetter, onChange } = formRenderProps;
    const getValue = (nameOfField: keyof PermitType) =>
      valueGetter(nameOf(nameOfField));
    const onChangeField = (nameOfField: keyof PermitType, value: any) => {
      onChange(nameOf(nameOfField), { value: value });
    };

    const [gridSelectedRows, setGridSelectedRows] = useState<IKeyValuePacket[]>(
      []
    );

    // List current conditions
    const conditions: IKeyValuePacket[] = getValue("Conditions");

    const submitAddConditionsHandler = (
      conditionsAdded: Svc_ConditionReference[]
    ) => {
      const conditionsAddedIDList = conditionsAdded.map(
        (condition: Svc_ConditionReference) => condition.ID
      );
      const newConditions: IKeyValuePacket[] = conditions.filter(
        (condition: IKeyValuePacket) =>
          !conditionsAddedIDList.includes(condition.Key)
      );
      const refactorConditions = conditionsAdded.map(
        (condition: Svc_ConditionReference) =>
          ({ Key: condition.ID, Value: condition.Title } as IKeyValuePacket)
      );
      newConditions.push(...refactorConditions);
      onChangeField("Conditions", newConditions);
    };

    const submitRemoveConditionsHandler = () => {
      const conditionsRemovedIDList = gridSelectedRows.map(
        (condition: IKeyValuePacket) => condition.Key
      );
      const newConditions = conditions.filter(
        (condition: IKeyValuePacket) =>
          !conditionsRemovedIDList.includes(condition.Key)
      );
      onChangeField("Conditions", newConditions);
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Conditions" />
            <CCGrid
              readOnly={isDeleted}
              selectableMode="multiple"
              primaryField={nameOfPermitTypeConditions("Key")}
              columnFields={colPermitTypeConditions}
              data={conditions ?? []}
              selectedRows={gridSelectedRows}
              onSelectionChange={(dataItem: IKeyValuePacket[]) => {
                setGridSelectedRows([...dataItem]);
              }}
              state={{
                sort: [
                  {
                    field: nameOfPermitTypeConditions("Value"),
                    dir: "asc",
                  },
                ],
              }}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <AddConditionButton onSubmit={submitAddConditionsHandler} />
                  <RemoveConditionsButton
                    onSubmit={submitRemoveConditionsHandler}
                    isDisabled={gridSelectedRows.length === 0}
                  />
                </div>
              }
            />
          </div>
        </div>
      </section>
    );
  }
);
