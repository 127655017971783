import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export interface OrgUnit extends DTOBaseClass_Standard {
  OrgUnit_ID: number;
  OrgUnit_Name: string;
  LeftExtent: number;
  RightExtent: number;
  Node_Index: number | null;
  Parent_Index: number | null;
  AdhocGroup: boolean;
  Hierarchy: string;
  Manager_ID: number | null;
  Manager_Name: string;
  Phone: string;
  Users: IKeyValuePacket[];
  Level1_OrgUnitID: number | null;
  Level2_OrgUnitID: number | null;
  Level3_OrgUnitID: number | null;
  Level4_OrgUnitID: number | null;
  Level5_OrgUnitID: number | null;
  _SaveTriggers: OrgUnitUpdateTriggers[];
}

export enum OrgUnitUpdateTriggers {
  UpdateUsers,
}

export interface OrgNode_JSON {
  Node_Index: number;
  Node_Name: string;
  ChildNodes: OrgNode_JSON[];
  OrgUnit_ID: number;
}

export interface OrgUnit_ListView {
  ID: number;
  OrgUnit_Name: string;
  OrgUnit_InternalUse: string;
  OrgUnit_LeftExtent: number | null;
  OrgUnit_RightExtent: number | null;
  Sys_DBRowState: number;
  Parent_Index: number | null;
  AdhocGroup: boolean;
  Hierarchy: string;
  OrgLevel: number | null;
  Manager: string;
  Phone: string;
}

export interface ISecurityOrgStructureNotification {
  notification: IAppNotificationItemAddProps[];
}

export enum SecurityOrgStructureSubmitActions {
  Save = "Save",
  CreateTopLevel = "CreateTopLevel",
  CreateAdhocGroup = "CreateAdhocGroup",
  AddThisOrgUnit = "AddThisOrgUnit",
}

export enum OrgStructureLevel {
  Level1 = 1,
  Level2 = 2,
  Level3 = 3,
  Level4 = 4,
  Level5 = 5,
}
