import { ContactsTab } from "@app/core/contacts/components/contact-tab/_index";
import { useInspectionsStore } from "@app/core/inspections/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

interface IInspectionContactsTabProps {
  // Get the data by this field
  primaryField?: string;
}
export const InspectionContactsTab = observer(
  ({ primaryField }: IInspectionContactsTabProps) => {
    const { id } = useParams<{ id: string }>();
    const { inspections } = useInspectionsStore();
    const { lastSelectedId, lastSelectedRow } = useCCProductListViewStore();

    // primaryField use for case: records have the same ID
    // Use another field to make the primary field
    const inspectionsId =
      primaryField &&
      !isNil(lastSelectedRow?.[primaryField]) &&
      lastSelectedRow[primaryField] !== ""
        ? lastSelectedRow[primaryField]
        : id ?? lastSelectedId;

    return (
      <ContactsTab
        id={parseInt(inspectionsId)}
        recordType={RECORDTYPE.CORE_Inspection}
        triggerReload={inspections}
      />
    );
  }
);
