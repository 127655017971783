export interface DTO_Dashboard {
  KPIs: DTO_Dashboard_KPI[];
  Graphs: DTO_Dashboard_Graph[];
}

export interface DTO_Dashboard_KPI {
  Id: number | null;
  Name: string;
  Value: number | null;
  ErrorMessage: string;
}

export interface DTO_Dashboard_Graph {
  Id: number;
  Name: string;
  Values: DTO_Dashboard_GraphValue[];
  ErrorMessage: string;
}

export interface DTO_Dashboard_GraphValue {
  Label: string;
  Value: number | null;
}
export enum EPropertyKPI {
  OutstandingOwnershipChanges = 100,
  ActiveJournals = 101,
  ActiveSupplementaries = 102,
}
