import { history } from "@/AppRoutes";
import { mockCRMSServiceStandardForm } from "@app/products/crms/service-standards/[id]/mock";
import { CRMSSystemSettingFormData } from "@app/products/crms/system-admin/[id]/model";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";

export const loadCRMSSystemSetting = async (
  id?: string
): Promise<CRMSSystemSettingFormData> => {
  const records: any[] = JSON.parse(
    JSON.stringify(mockCRMSServiceStandardForm)
  );
  return records[0];
};

export const postCRMSSystemSetting = async (
  requestBody: CRMSSystemSettingFormData
): Promise<CRMSSystemSettingFormData | undefined> => {
  await mockApiAlwaySuccess(requestBody);
  return;
};

export const putCRMSSystemSetting = async (
  requestBody: CRMSSystemSettingFormData
): Promise<CRMSSystemSettingFormData | undefined> => {
  await mockApiAlwaySuccess(requestBody);
  return;
};

const mockApiAlwaySuccess = (requestBody: CRMSSystemSettingFormData) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      history.replace(`${CRMS_ROUTE}/${requestBody?.ID || 0}`, {
        status: "success",
      });

      reloadAfterSuccess();
      resolve("done");
    }, 2000);
  });
};

const reloadAfterSuccess = () => {
  history.replace(window.location.pathname, {
    status: "success",
  });
  history.go(0);
};
