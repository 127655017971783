import { colAssociationsCertificate } from "@app/products/property/components/associations/components/certificate/config";
import { DTO_AssociatedItem_Certificate } from "@app/products/property/components/associations/components/certificate/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsCertificateProps {
  associationsCertificateInfo: DTO_AssociatedItem_Certificate[] | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_Certificate>();

export const AssociationsCertificate = ({
  associationsCertificateInfo,
}: IAssociationsCertificateProps) => {
  return (
    <CCGrid
      className="cc-certificate-grid"
      data={associationsCertificateInfo || []}
      columnFields={colAssociationsCertificate}
      primaryField={nameOf("Id")}
    />
  );
};
