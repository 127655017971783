import { getComputerWarranty } from "@app/products/ams/assets/reports/computer-warranty-report/api";
import { AmsLLSComputerWarrantyReport } from "@app/products/ams/assets/reports/computer-warranty-report/model";
import { AmsRoute } from "@app/products/ams/constants/ams.route";
import { amsRoute } from "@app/products/ams/route";
import { animalRoute } from "@app/products/animals/route";
import { isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { getDisplayTextWithDashes, nameOfFactory } from "@common/utils/common";
import { CCActionBarNavDropdown } from "@components/cc-list-view-action-bar/components/nav-dropdown/_index";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import React from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { amsLLSComputerWarrantyReportColumns } from "./config";

const nameOf = nameOfFactory<AmsLLSComputerWarrantyReport>();

export default (props: any) => {
  const history = useHistory();
  const [dataAsset, setDataAsset] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState<any>();
  const getAssetReport = async () => {
    setIsLoading(true);
    const response = await getComputerWarranty();
    if (isSuccessResponse(response)) {
      setIsLoading(false);
      setDataAsset(response.data ?? []);
    } else {
      const errorResponse = {
        status: response.status,
        error: response.error,
      };
      setIsError(errorResponse);
    }
  };
  useEffectOnce(() => {
    getAssetReport();
  });
  useCCListViewActionBar({
    title: animalRoute.name,
    leftComponents: [
      <CCActionBarNavDropdown
        category={"reports"}
        product={amsRoute.path}
        route={amsRoute}
      />,
    ],
    centerComponents: [
      <CCNavButton
        title="New Asset"
        onClick={() => history.push(AmsRoute.ASSET_NEW_PATH)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ListViewBookmarkIcon
        linkUrl={AmsRoute.COMPUTER_WARRANTY_REPORT_PATH}
        productType={PRODUCT_TYPE.CommunityAsset}
        isDisabledRecordListView
        recordType={RECORDTYPE.AssetManager_AssetRegister}
        detail={(selectedRow: any) => {
          return getDisplayTextWithDashes([
            selectedRow.AssetNumber,
            selectedRow.AssetDescription,
          ]);
        }}
        displayName={(selectedRow: any) => {
          const dynamicDisplayName = selectedRow.Id
            ? `- ${selectedRow.Id}`
            : "";
          return `Asset - Reports ${dynamicDisplayName}`;
        }}
        listViewDisplayName={() => "Asset - Reports"}
        listViewDetail={() => "Asset - Reports - Computer Warranty Report"}
      />,
    ],
  });

  return (
    <>
      {isError ? (
        <CCLoadFailed
          responseError={isError}
          onReload={() => {
            getAssetReport();
          }}
        />
      ) : (
        <CCProductListView
          {...props}
          columnFields={amsLLSComputerWarrantyReportColumns}
          data={dataAsset}
          isLoading={isLoading}
          //dataUrl={AmsUrl.GET_COMPUTER_WARRANTY_REPORT}
          primaryField={nameOf("Asset_Number")}
        />
      )}
    </>
  );
};
