import { IAccountsTransaction } from "@app/products/property/registers/[id]/components/dialogs/form-steps/raise-charge/components/form-elements/accounts/model";
import { cloneDeep } from "lodash";

export const convertToAccountTransactions = (response: any) => {
  let result: IAccountsTransaction = cloneDeep(response);
  if (response?.AccountReference) {
    result.AccountNo = response?.AccountReference;
  }
  if (response?.Id) {
    result.AccountId = response?.Id;
  }
  return result;
};
