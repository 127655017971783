import { getDisplayTextWithDashes } from "@common/utils/common";
import { getLabel } from "@app/products/property/components/label/config";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `Property - Financial Summaries ${dynamicDisplayName}`;
};

//@TODO Don't have Manage data in grid mock data, recheck when integrated API
export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([selectedRow.Manage]);
};

export const getBookmarkListViewDetail = () => {
  return `Property - ${getLabel.assessments()} - Financial Summaries`;
};

export const getBookmarkListViewDisplayName = () => {
  return `Property - ${getLabel.assessments()}`;
};
