import { loadViewConfiguresColumns } from "@app/products/property/api";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import {
  EPrivilegesWorkflow,
  ViewConfiguration,
  WorkflowTypes,
} from "@app/products/property/model";
import { MasterPropertyAssessmentDetail } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/components/detail/_index";
import { colSupplementMasterProperty } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/config";
import { VO_Supplementary_MasterProperty } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/model";
import { useSupplementaryRatesMasterPropertyStore } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/store";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { formatDynamicValuationsForColumn } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const SUPP_MASTER_PROPERTIES_ACCORDION_GRID_ID = getUUID();
const nameOf = nameOfFactory<VO_Supplementary_MasterProperty>();
export const SupplementaryMasterProperties = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { setSelectedMasterProperties, selectedMasterProperties } =
    useSupplementaryRatesMasterPropertyStore();
  const { setListDialog } = usePropertyWorkflowStore();
  const { supplementaryAssessments } = useSupplementaryRatesStore();

  const [processedColumn, setProcessedColumn] = useState<IColumnFields[]>(
    colSupplementMasterProperty
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const isDisabled = useMemo(() => {
    const { SupplementaryDetail } = supplementaryAssessments || {};
    const isComplete = SupplementaryDetail?.IsComplete;

    return !(isComplete === false && selectedMasterProperties.length === 1);
  }, [selectedMasterProperties.length, supplementaryAssessments]);

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Supplementary_MasterProperties,
      colSupplementMasterProperty,
      {
        dynamicFormat: formatDynamicValuationsForColumn,
      }
    );
    if (Array.isArray(response)) {
      setProcessedColumn(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  return isLoading ? (
    <Loading isLoading={isLoading} />
  ) : responseLoadError ? (
    <CCLoadFailed
      responseError={responseLoadError}
      onReload={() => {
        loadViewConfig();
      }}
    />
  ) : (
    <div className="cc-supplementary-rates-master-property">
      <CCGrid
        gridId={SUPP_MASTER_PROPERTIES_ACCORDION_GRID_ID}
        onSelectionChange={(dataItem: VO_Supplementary_MasterProperty[]) => {
          setSelectedMasterProperties(dataItem ? [...dataItem] : []);
        }}
        selectableMode="multiple"
        selectedRows={selectedMasterProperties}
        dataUrl={`/odata/property/internal/supplementaryratesmasterpropertyregister/${id}?$count=true&`}
        columnFields={processedColumn}
        primaryField={nameOf("Master_Property_Id")}
        detail={MasterPropertyAssessmentDetail}
        toolbar={
          <div className="cc-grid-tools-bar">
            <PrivilegeWrapper
              dialogType={ePermissionPrivilegeTypeCheck.Dialog}
              privilegesEnum={
                EPrivilegesWorkflow.Master_Property_Adjustment_Supplementary
              }
            >
              <Button
                className="cc-edit-field-button"
                iconClass="fa fa-pencil"
                title="Master Property Adjustment"
                disabled={isDisabled}
                onClick={() => {
                  setListDialog([
                    {
                      type: WorkflowTypes.Master_Property_Adjustment_Supplementary,
                      data: {},
                    },
                  ]);
                }}
              />
            </PrivilegeWrapper>
          </div>
        }
      />
    </div>
  );
});
