import {
  DTO_Assessment,
  DTO_AssessmentTransactionCharge,
} from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { DTO_Journal } from "@app/products/property/journals/model";
import {
  DTO_WorkflowDetail_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";
import { DTO_Supplementary_Create } from "@app/products/property/supplementary-rates/components/form-elements/new-supplementary/model";

export interface RequestJournalAssessmentRaiseChargeObj {
  AssessmentId: number;
}

export interface DTO_Workflow_JournalAssessmentRaiseCharge {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_JournalAssessmentRaiseCharge;
}

interface DTO_AssessmentTransactionRaiseCharge
  extends DTO_AssessmentTransactionCharge {
  ProrataChargeOfRatingPeriod: boolean | null;
  Quantity: number | null;
  LevyAmount: number | null;
  NetAmount: number | null;
  NetAmountFrom: Date | null;
}

export interface DTO_WorkflowDetail_JournalAssessmentRaiseCharge
  extends DTO_WorkflowDetail_Base {
  Journal_Number: number | null;
  JournalMode: number;
  SupplementaryMode: number;
  CreateSupplementary: DTO_Supplementary_Create;
  CreateJournal: DTO_Journal;
  Assessments: DTO_Assessment[];
  NewCharge: DTO_AssessmentTransactionRaiseCharge;
}

export enum RaiseChargesKeysOfSteps {
  WhichJournal = "WhichJournal",
  CreateJournal = "CreateJournal",
  CreateSupplementary = "CreateSupplementary",
  Assessments = "Assessments",
  NewCharge = "NewCharge",
  Comments = "Comments",
  Documents = "Documents",
}

export const raiseChargesKeysOfSendSteps = [
  RaiseChargesKeysOfSteps.CreateJournal,
  RaiseChargesKeysOfSteps.CreateSupplementary,
  RaiseChargesKeysOfSteps.Assessments,
  RaiseChargesKeysOfSteps.NewCharge,
];
