export const getMultipleSelectValue = (
  listKeyFilters: string[] | number[],
  arr: any,
  dataItemKey: string = "Code"
): any => {
  if (listKeyFilters === undefined || listKeyFilters?.length === 0) {
    return [];
  }
  return (
    arr?.filter((item: any) => {
      return listKeyFilters?.includes(item?.[dataItemKey] as never);
    }) ?? []
  );
};
