import { NUMBER_FORMAT } from "@common/constants/common-format";
import { DBRowState } from "@common/models/baseClassStandard";
import { KeywordLite } from "@common/models/keyword";
import { SETTINGS_LOOKUPS_KEYWORDS_ROUTE } from "@common/pages/settings/lookups/keywords/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<KeywordLite>();

export const colLookupsKeywords: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    locked: true,
    linkTo: (dataItem: KeywordLite) =>
      `${SETTINGS_LOOKUPS_KEYWORDS_ROUTE}/${dataItem.Keyword_ID}`,
  },
  {
    field: nameOf("KeywordType_Name"),
    title: "Type",
  },
  {
    field: nameOf("Sys_DBRowState"),
    title: "Status",
    filterByEnum: DBRowState,
  },
  {
    field: nameOf("Sys_SortOrderIndex"),
    title: "Sort",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("Code"),
    title: "Code",
  },
  {
    field: nameOf("SystemFilter_Names"),
    title: "Modules",
  },
  {
    field: nameOf("Category"),
    title: "Category",
  },
];
