import { StateDeclaredRoad } from "@app/products/town-planning/ppr/[id]/components/input-picker/state-roads-picker/model";
import { SETTINGS_LOOKUPS_STATE_ROADS_ROUTE } from "@common/pages/settings/lookups/state-roads/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<StateDeclaredRoad>();

export const colLookupsStateRoads: IColumnFields[] = [
  {
    field: nameOf("RoadName"),
    title: "Road Name",
    locked: true,
    linkTo: (dataItem: StateDeclaredRoad) =>
      `${SETTINGS_LOOKUPS_STATE_ROADS_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("RoadNumber"),
    title: "Road Number",
  },
  {
    field: nameOf("RoadIDNo"),
    title: "Road Id Number",
  },
  {
    field: nameOf("RoadClass"),
    title: "Road Class",
  },
  {
    field: nameOf("RoadStartNo"),
    title: "Road Start Number",
  },
  {
    field: nameOf("RoadStartName"),
    title: "Road Start Name",
  },
  {
    field: nameOf("RoadStartType"),
    title: "Road Start Type",
  },
  {
    field: nameOf("RoadStartLocality"),
    title: "Road Start Locality",
  },
  {
    field: nameOf("RoadEnd"),
    title: "Road End",
  },
  {
    field: nameOf("RoadEndName"),
    title: "Road End Name",
  },
  {
    field: nameOf("RoadEndType"),
    title: "Road End Type",
  },
  {
    field: nameOf("RoadEndLocality"),
    title: "Road End Locality",
  },
  {
    field: nameOf("RoadState"),
    title: "State",
  },
];
