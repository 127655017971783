import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<VO_Workflow_Draft>();

export const colToBeApprovedList: IColumnFields[] = [
  {
    field: nameOf("Workflow_Draft_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
  },
  {
    field: nameOf("Component_Name"),
    title: "Component",
  },
  {
    field: nameOf("WD_Component_Id"),
    title: "Component ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Workflow"),
    title: "Workflow",
  },
  {
    // Region for NSW LLS and Assessment Group for all other
    field: nameOf("Assessment_Group"),
    title: "Assessment Group",
  },
  {
    field: nameOf("Workflow_Status_Name"),
    title: "Workflow Status",
  },
  {
    field: nameOf("Created_By"),
    title: "Officer",
  },
  {
    field: nameOf("Created_On"),
    title: "Created",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Modified_On"),
    title: "Modified",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Workflow_Approval_Status_Name"),
    title: "Approval Status",
  },
  { field: nameOf("Current_Step_Approval_Group"), title: "Approval Group" },
  {
    field: nameOf("Approved_By"),
    title: "Approved By",
  },
  {
    field: nameOf("Approved_On"),
    title: "Approved",
    format: DATE_FORMAT.DATE,
  },

  {
    field: nameOf("Parent_Id"),
    title: "Parent ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
