import { PPROfficerAllocation } from "@app/products/town-planning/ppr/components/action-bar/dialogs/allocate-to-officer/model";
import { PPRApplicationIdentityPacket } from "@app/products/town-planning/ppr/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const putOfficerAllocate = async (
  applicationID: number | undefined,
  reqBody: PPROfficerAllocation
): Promise<APIResponse<PPRApplicationIdentityPacket>> => {
  try {
    const response =
      await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
        `api/tp/ppr/internal/application/psar/${applicationID}/allocatetoofficer`,
        reqBody
      );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
