import { DTO_Entity } from "@app/products/property/assessments/compliance/[id]/model";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfOwner = nameOfFactory<DTO_Entity>();

export const colOwners: IColumnFields[] = [
  {
    field: nameOfOwner("ENAName"),
    title: "Name",
    linkTo: (dataItem: DTO_Entity) => {
      return `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem.EntityId}`;
    },
  },
  {
    field: nameOfOwner("ENAAddress"),
    title: "Current Address",
  },
  {
    field: nameOfOwner("EntityId"),
    title: "Entity ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
