import { AddInterestRateDialog } from "@app/products/property/schemes/[id]/components/forms/existed/components/dialogs/add-interest-rate/_index";
import { sortByEffectiveForm } from "@app/products/property/schemes/[id]/components/forms/existed/components/dialogs/add-interest-rate/components/dialogs/interest-rate/util";
import {
  ISchemesInterestRate,
  eSchemesInterestRateStatus,
} from "@app/products/property/schemes/[id]/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

interface IInterestRateButtonProps {
  initialData: ISchemesInterestRate[];
  onSave: (value: any[]) => void;
}

export const AddInterestRateButton = ({
  initialData,
  onSave,
}: IInterestRateButtonProps) => {
  const [showInterestRate, setShowInterestRate] = useState<boolean>(false);
  const handleDataOnSave = (data: ISchemesInterestRate[]) => {
    return sortByEffectiveForm(
      data
        .filter((item) => item.Status !== eSchemesInterestRateStatus.NEW)
        .concat(
          data
            .filter((item) => item.Status === eSchemesInterestRateStatus.NEW)
            .map((el) => ({
              EffectiveFrom: el.EffectiveFrom,
              InterestRate: el.InterestRate,
              PenaltyRate: el.PenaltyRate,
              Status: eSchemesInterestRateStatus.PROPOSED,
              Id: el.Id,
            }))
        )
    );
  };

  return (
    <>
      <CCNavButton
        title="Add interest rate"
        onClick={() => setShowInterestRate(true)}
      />

      {showInterestRate && (
        <AddInterestRateDialog
          initialData={initialData}
          onClose={() => {
            setShowInterestRate(false);
          }}
          onSubmit={(data: ISchemesInterestRate[]) => {
            onSave(handleDataOnSave(data));
            setShowInterestRate(false);
          }}
        />
      )}
    </>
  );
};
