export interface DTO_Entity_Lookup {
  Entities: DTO_Entity[];
}

export interface DTO_Entity {
  Entity_Id: number;
  Entity_Name_Address_Id: number;
  Name: string;
  Address: string;
  Formatted_Name_Address: string;
  Email?: string;
  Mobile?: string;
  Gender?: string | null;
  EntityId_Name?: string;
  Locality?: string;
  Postcode?: string;
  State?: string;
  Country?: string;
  ENA_ACN?: string;
  ENA_ABN?: string;
}

export interface DTO_COO_EntitySummary {
  Entity_Name_Address: DTO_Entity_Name_Address;
}

export interface DTO_Entity_Name_Address {
  Entity_Id: number;
  Entity_Name_Address_Id: number;
  Name: string;
  Surname: string;
  GivenName: string;
  Title: string;
  Attention_Of: string;
  Care_Of: string;
  Address: string;
  Locality: string;
  State: string;
  Country: string;
  Postcode: string;
  DPID?: number;
  Formatted_Name_Address: string;
  Full_Address: string;
}

export interface DTO_Entity_Create {
  Entity_Name_Address: DTO_Entity_Name_Address;
}

export enum EAddNameMode {
  New = "New", // Create new contact
  Update = "Update", // Update existing contact
  Existing = "Existing", // Add existing contact
}

export enum CONTACTSEARCHFILTER {
  NONE = 0,
  STARTSWITH = 1,
  CONTAINS = 2,
  ENDSWITH = 3,
  EXACTMATCH = 4,
}
