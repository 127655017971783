import { colAction } from "@app/core/actions/config";
import { IAction } from "@app/core/actions/model";
import { CORE_GET_ACTIONS_ODATA } from "@app/core/components/common/constant";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IActionProps {
  id?: number | string;
  recordType: RECORDTYPE;
}
const nameOf = nameOfFactory<IAction>();
const Actions: React.FC<IActionProps> = ({ id, recordType }) => {
  return (
    <div className="cc-actions">
      <CCGrid
        columnFields={colAction}
        primaryField={nameOf("ID")}
        dataUrl={
          id === undefined
            ? undefined
            : `${CORE_GET_ACTIONS_ODATA}(key=${id},recordType=${recordType})?$count=true&`
        }
      />
    </div>
  );
};

export default Actions;
