export const mockTransactionDetail = [
  {
    Amount: 50.6,
    DueDate: "13-Sep-2014",
    RatingPeriod: "2013/2014",
    ChargeName: "Water Consumption",
    ChargeDate: "11-Aug-2014",
    InstalmentNumber: 1,
    LevyName: "Water Usage Whitsunday",
    LevyCode: "502W",
    Calculation: "94x$0.55",
    ChargeBalancesId: 198104,
  },
];
