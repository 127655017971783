import { CORE_STATEROADS_ODATA } from "@app/core/components/common/constant";
import { StateDeclaredRoad } from "@app/products/town-planning/ppr/[id]/components/input-picker/state-roads-picker/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { DeleteStateRoadsButton } from "@common/pages/settings/lookups/state-roads/components/action-bar/buttons/delete-state-roads/_index";
import { ImportStateRoadsButton } from "@common/pages/settings/lookups/state-roads/components/action-bar/buttons/import-state-roads/_index";
import { NewStateRoadButton } from "@common/pages/settings/lookups/state-roads/components/action-bar/buttons/new-state-roads/_index";
import { colLookupsStateRoads } from "@common/pages/settings/lookups/state-roads/config";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@common/pages/settings/lookups/state-roads/util";
import { LookupStateRoadTabDetails } from "@common/pages/settings/lookups/state-roads/_id/components/reference-sidebar/detail/_index";
import { LookupStateRoadTabHistory } from "@common/pages/settings/lookups/state-roads/_id/components/reference-sidebar/history/_index";

import { SETTINGS_LOOKUPS_STATE_ROADS_ROUTE } from "@common/pages/settings/lookups/state-roads/_id/constant";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<StateDeclaredRoad>();

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [
      <NewStateRoadButton disabled={!hasPermission} />,
      <DeleteStateRoadsButton disabled={!hasPermission} />,
      <ImportStateRoadsButton disabled={!hasPermission} />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={SETTINGS_LOOKUPS_STATE_ROADS_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_StateRoad}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        isDisabledRecordListView={!hasPermission}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <LookupStateRoadTabDetails /> },
      {
        title: "History",
        component: <LookupStateRoadTabHistory />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colLookupsStateRoads}
      primaryField={nameOf("ID")}
      dataUrl={CORE_STATEROADS_ODATA}
      state={{
        sort: [{ field: nameOf("RoadName"), dir: "asc" }],
      }}
      disabled={!hasPermission}
    />
  );
};
