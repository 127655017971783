import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCInput } from "@components/cc-input/_index";
import { Field } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ dataSetting }: IGlobalComponentFormProps) => {
  const data = dataSetting.SettingFields;
  const { isEditing } = useSettingGlobalManageStore();
  return (
    <div className="cc-form-cols-1">
      <div className="cc-field">
        <label className="cc-label">{data[0]?.Title}</label>
        <Field
          name={data[0]?.FieldName}
          component={CCInput}
          placeholder={data[0]?.Title}
          disabled={!isEditing}
        />
      </div>
      <div className="cc-field">
        <label className="cc-label">{data[1]?.Title}</label>
        <Field
          name={data[1]?.FieldName}
          component={CCInput}
          placeholder={data[1]?.Title}
          disabled={!isEditing}
        />
      </div>
      <div className="cc-field">
        <label className="cc-label">{data[2]?.Title}</label>
        <Field
          name={data[2]?.FieldName}
          component={CCInput}
          placeholder={data[2]?.Title}
          disabled={!isEditing}
        />
      </div>
    </div>
  );
});
