import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { VO_Assessment_Mailbox } from "@app/products/property/assessments/mailbox-list/all/model";

const nameOf = nameOfFactory<VO_Assessment_Mailbox>();
export const colMailBoxListAll: IColumnFields[] = [
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    locked: true,
    linkTo: (dataItem) =>
      `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Assessment_Id}`,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Assess_NumberX"),
    title: "Assessment Number",
  },
  {
    field: nameOf("Property_Name_Address_Locality"),
    title: "Property Address",
  },
  { field: nameOf("Type"), title: "Name Type" },
  { field: nameOf("Name"), title: "Name" },
  {
    field: nameOf("Mailbox_Id"),
    title: "Mailbox ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Mailbox_Customer_Id"),
    title: "Customer ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: nameOf("Mailbox_Service"), title: "Service Name" },
  { field: nameOf("Mailbox_Status"), title: "Mailbox Status" },
  {
    field: nameOf("Mailbox_Created_On"),
    title: "Mailbox Created",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Mailbox_Email_Address"),
    title: "Email Address",
  },
];
