import { ISearches } from "./model";

export const mockSeacrchAdvandcedSearch: ISearches[] = [
  {
    Name: "Assessments not in valuation group",
    CreatedOn: "23-NOV-2013 12:25",
    Component: "Assessment",
    Type: "Functional Group",
    IsActive: true,
    Officer: "Steven SPAGNOLO",
    SetApplicationRole: false,
    ListView: "Assessment (Non Financial)",
  },
  {
    Name: "Assessments w Levies and w/o Current Values",
    CreatedOn: "8-Oct-2021 21:52",
    Component: "Assessment",
    Type: "All Officers",
    IsActive: true,
    SetApplicationRole: false,
    ListView: "Assessment (with Balances)",
  },
  {
    Name: "Assessments w Levies and w/o Current Values, Prev Value > 0",
    CreatedOn: "8-Oct-2021 21:52",
    Component: "Assessment",
    Type: "All Officers",
    IsActive: true,
    SetApplicationRole: false,
    ListView: "Assessment (with Balances)",
  },
  {
    Name: "Assessments w/o Levies and w Values",
    CreatedOn: "8-Oct-2021 21:52",
    Component: "Assessment",
    Type: "All Officers",
    IsActive: true,
    SetApplicationRole: false,
    ListView: "Assessment (with Balances)",
  },
  {
    Name: "Assessments w/o Levies and w Values > 0",
    CreatedOn: "8-Oct-2021 21:52",
    Component: "Assessment",
    Type: "All Officers",
    IsActive: true,
    SetApplicationRole: false,
    ListView: "Assessment (with Balances)",
  },
  {
    Name: "Missing Concession Cards",
    CreatedOn: "8-Oct-2021 21:52",
    Component: "Assessment",
    Type: "All Officers",
    IsActive: true,
    SetApplicationRole: false,
    ListView: "Assessments Missing Concession Cards",
  },
  {
    Name: "Mixed Development search",
    CreatedOn: "18-NOV-2013 12:20",
    Component: "Assessment",
    Type: "Functional Group",
    IsActive: true,
    Officer: "Steven SPAGNOLO",
    SetApplicationRole: false,
    ListView: "Assessment (Non Financial)",
  },
];
