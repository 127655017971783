import { IChargesStepsLOVs } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/components/form-elements/charge/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ChargeStepStore {
  private _chargesStepLOVs?: IChargesStepsLOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get chargesStepLOVs() {
    return toJS(this._chargesStepLOVs);
  }
  setChargesStepLOVs = (chargesStepLOVs?: IChargesStepsLOVs) => {
    runInAction(() => {
      this._chargesStepLOVs = chargesStepLOVs;
    });
  };
}

const chargeStepStoreContext = createContext(new ChargeStepStore());
export const useChargeStepStore = () => {
  return useContext(chargeStepStoreContext);
};
