import { PropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/_index";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { PAGE_TYPE, divPropPageType } from "@common/hooks/usePageType";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import { useEffectOnce } from "react-use";
import { CCSlideActionBar } from "../cc-slide-action-bar/_index";
import { ToggleSlideActionBarButton } from "../cc-slide-action-bar/components/toggle-slide/_index";
import { CCSubActionBar } from "../cc-sub-action-bar/_index";
import "./_index.scss";

export interface ICCManagePageActionBarProps {
  leftActions?: ReactElement | ReactElement[];
  rightActions?: ReactElement | ReactElement[];
}

export const CCManagePageActionBar = observer(
  ({ leftActions, rightActions }: ICCManagePageActionBarProps) => {
    const { resetData } = usePropertyWorkflowStore();

    useEffectOnce(() => {
      return () => resetData();
    });

    const renderElement = (object?: ReactElement | ReactElement[]) => {
      if (!object) return undefined;
      if (Array.isArray(object)) {
        return object.map((element: ReactElement, index: number) => (
          <React.Fragment key={index}>{element}</React.Fragment>
        ));
      }
      return object;
    };

    return (
      <>
        <div
          {...divPropPageType(PAGE_TYPE.ManagePage)}
          className="cc-manage-page-action-bar"
        >
          <CCSlideActionBar>{renderElement(leftActions)}</CCSlideActionBar>
          <div className="cc-right-actions">
            <>
              {renderElement(rightActions)}
              <ToggleSlideActionBarButton />
              <PropertyWorkflow />
            </>
          </div>
        </div>
        <CCSubActionBar />
      </>
    );
  }
);
