import { INewJournalStepsLOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NewJournalStepStore {
  private _newJournalStepLOVs?: INewJournalStepsLOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get newJournalStepLOVs() {
    return this._newJournalStepLOVs;
  }
  setNewJournalStepLOVs = (newJournalStepLOVs?: INewJournalStepsLOVs) => {
    runInAction(() => {
      this._newJournalStepLOVs = newJournalStepLOVs;
    });
  };
}

const newJournalStepStoreContext = createContext(new NewJournalStepStore());
export const useNewJournalStepStore = () => {
  return useContext(newJournalStepStoreContext);
};
