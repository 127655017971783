import {
  Registration,
  Svc_Registration,
  Svc_Registration_Workflow_Issue,
} from "@app/products/animals/[id]/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { AnimalsIssueDialog } from "@app/products/animals/components/forms/components/form-element/components/workflow-elements/animal-registration-details/_index";
import { ReasonDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/_index";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DateToFromDialog } from "@common/dialog/date-to-from/_index";
import { DateFromDateTo } from "@common/dialog/date-to-from/model";
import { PickDebtorNumberDialog } from "@common/dialog/debtor-number/_index";
import { RefNumberUsage } from "@common/input-pickers/debtor-number/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { addDays, addYears } from "date-fns";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface IAnimalFormProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<Registration>();
const nameOfMapObj = nameOfFactory<Svc_Registration>();
const getNameOf = (name: keyof Registration) =>
  `${nameOfMapObj("Registration")}.${nameOf(name)}`;

export const AnimalsRegistrationWorkflowElements = observer(
  (props: IAnimalFormProps) => {
    const { valueGetter } = props.formRenderProps;
    const {
      animalInfo,
      animalId,
      setIsWorkflowLoading,
      isWorkflowLoading,
      isShowWorkflowDeregister,
      isShowWorkflowLostTag,
      isShowWorkflowRenew,
      isShowWorkflowDeceased,
      isShowWorkflowReactivate,
      isShowIssueDialog,
      isShowPickDebtorDialog,
      isShowUpdateRegistrationDialog,
      setIsShowWorkflowDeregister,
      setIsShowWorkflowLostTag,
      setIsShowWorkflowRenew,
      setIsShowWorkflowDeceased,
      setIsShowWorkflowReactivate,
      setIsShowIssueDialog,
      setIsShowPickDebtorDialog,
      setIsShowUpdateRegistrationDialog,
      handleWorkflowDeregister,
      handleWorkflowLostTag,
      handleWorkflowRenew,
      handleWorkflowDeceased,
      handleWorkflowReactivate,
      handleWorkflowIssueRegistration,
      handlePickDebtor,
      handleUpdateRegistration,
    } = useAnimalStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { settings } = useCommonCoreStore();
    const [dataRenew, setDataRenew] = useState<DateFromDateTo>();

    const objReasonDeregister = useMemo(() => {
      const retVal = new ProvideReason();
      retVal.Title_Text = "Deactivate Registration";
      retVal.ReasonLabel_Text = "Optional details";
      retVal.Flag_ShowDate = false;
      retVal.Flag_ShowKWD = true;
      retVal.Flag_KWDIsMandatory = false;
      retVal.KWDLabel_RequiredErrorMessage = "Please select a reason";
      retVal.KWDLabel_Text = "Reason";
      retVal.KWDType = KEYWORD_TYPE.Animals_DeactivateReason;
      retVal.Reason = "";
      return retVal;
    }, []);

    const objReasonLostTag = useMemo(() => {
      const retVal = new ProvideReason();
      retVal.Title_Text = "Lost Tag";
      retVal.ReasonLabel_Text = "Details";
      retVal.Flag_ShowDate = false;
      return retVal;
    }, []);

    const objReasonDeceased = useMemo(() => {
      const retVal = new ProvideReason();
      retVal.Title_Text = "Animal Deceased";
      retVal.ReasonLabel_Text = "Details";
      retVal.DateLabel_Text = "Date of death";
      retVal.Flag_ShowDate = true;
      retVal.DateOfAction = new Date();
      return retVal;
    }, []);

    const objReasonReactivate = useMemo(() => {
      const retVal = new ProvideReason();
      retVal.Title_Text = "Reactivate";
      retVal.ReasonLabel_Text = "Details";
      retVal.Flag_ShowDate = false;
      return retVal;
    }, []);

    const objReasonRenew = () => {
      const animalsRenewalDateValue = getStringValueSetting(
        settings[ECorporateSettingsField.Animals_RenewalDate]
      );
      var animalsRenewalDate: Date | null =
        !isNil(animalsRenewalDateValue) && animalsRenewalDateValue !== ""
          ? new Date(animalsRenewalDateValue)
          : null;

      const retVal = new DateFromDateTo();
      retVal.Title_Text = "Renew Registration";
      retVal.DateLabelFrom_Text = "Effective from";
      retVal.DateLabelFrom_To = "Effective to";

      //Renewal Period Anniversary options -Shire Of Murray
      var conditionRenewalPeriod =
        valueGetter(getNameOf("AnimalType")) !== null &&
        valueGetter(`${getNameOf("AnimalType")}.RenewalPeriod_ID`) != null &&
        valueGetter(`${getNameOf("AnimalType")}.RenewalPeriod_ID`) !== 1 &&
        valueGetter(`${getNameOf("AnimalType")}.Flag_LifeTimeTag`) === false;

      const dateEffectTo = valueGetter(getNameOf("Date_EffectiveTo"))
        ? new Date(valueGetter(getNameOf("Date_EffectiveTo")))
        : null;

      if (dateEffectTo !== null) {
        retVal.DateFrom = addDays(dateEffectTo, 1);

        if (conditionRenewalPeriod) {
          let years: number = valueGetter(
            `${getNameOf("AnimalType")}.RenewalPeriod_ID`
          );
          retVal.DateTo = addYears(new Date(dateEffectTo), years);
        } else {
          retVal.DateTo = addYears(new Date(dateEffectTo), 1);
        }
      } else {
        retVal.DateFrom = new Date();

        if (conditionRenewalPeriod) {
          let years: number = valueGetter(
            `${getNameOf("AnimalType")}.RenewalPeriod_ID`
          );
          retVal.DateTo = addYears(new Date(), years);
        } else {
          retVal.DateTo = addYears(new Date(), 1);
        }
      }

      if (animalsRenewalDate !== null && animalsRenewalDate < new Date()) {
        do {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          animalsRenewalDate = addYears(animalsRenewalDate, 1);
        } while (animalsRenewalDate < new Date());
      }

      if (animalsRenewalDate !== null) retVal.DateTo = animalsRenewalDate;
      setDataRenew(retVal);
    };

    const isOpenDialogRenew = useMemo(() => {
      setDataRenew(undefined);
      if (
        isShowWorkflowRenew &&
        valueGetter(getNameOf("AnimalType")) !== null &&
        valueGetter(`${getNameOf("AnimalType")}.Flag_LifeTimeTag`)
      ) {
        pushNotification({
          autoClose: false,
          title: "Renewals are not applicable for Life Time Registrations.",
          type: "error",
        });
        return false;
      }
      if (isShowWorkflowRenew) objReasonRenew();
      return isShowWorkflowRenew;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueGetter(getNameOf("AnimalType")), isShowWorkflowRenew]);

    return (
      <>
        {isShowWorkflowDeregister && (
          <ReasonDialog
            onClose={() => setIsShowWorkflowDeregister(false)}
            onSubmit={(
              value: ProvideReason,
              notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
            ) =>
              handleWorkflowDeregister(
                valueGetter(nameOfMapObj("Registration")),
                value,
                notificationRef
              )
            }
            setIsLoading={setIsWorkflowLoading}
            isLoading={isWorkflowLoading}
            initialData={objReasonDeregister}
          />
        )}
        {isShowWorkflowLostTag && (
          <ReasonDialog
            onClose={() => setIsShowWorkflowLostTag(false)}
            onSubmit={(
              value: ProvideReason,
              notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
            ) =>
              handleWorkflowLostTag(
                valueGetter(nameOfMapObj("Registration")),
                value,
                notificationRef
              )
            }
            setIsLoading={setIsWorkflowLoading}
            isLoading={isWorkflowLoading}
            initialData={objReasonLostTag}
          />
        )}
        {isOpenDialogRenew && (
          <DateToFromDialog
            onClose={() => setIsShowWorkflowRenew(false)}
            onSubmit={(
              value: DateFromDateTo,
              notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
            ) =>
              handleWorkflowRenew(
                valueGetter(nameOfMapObj("Registration")),
                value,
                notificationRef
              )
            }
            isLoading={isWorkflowLoading}
            data={dataRenew}
          />
        )}
        {isShowWorkflowDeceased && (
          <ReasonDialog
            onClose={() => setIsShowWorkflowDeceased(false)}
            onSubmit={(
              value: ProvideReason,
              notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
            ) =>
              handleWorkflowDeceased(
                valueGetter(nameOfMapObj("Registration")),
                value,
                notificationRef
              )
            }
            setIsLoading={setIsWorkflowLoading}
            isLoading={isWorkflowLoading}
            initialData={objReasonDeceased}
          />
        )}
        {isShowPickDebtorDialog && (
          <PickDebtorNumberDialog
            onClose={() => setIsShowPickDebtorDialog(false)}
            onSubmit={(value: RefNumberUsage[]) => handlePickDebtor(value)}
            contactId={parseInt(
              valueGetter(`${getNameOf("Contact")}.Contact.Contact_ID`)
            )}
            recordId={animalId}
            recordType={RECORDTYPE.Animals_Registration}
          />
        )}
        {isShowIssueDialog && (
          <AnimalsIssueDialog
            onClose={() => setIsShowIssueDialog(false)}
            onSubmit={(
              value: Svc_Registration_Workflow_Issue,
              notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
            ) => {
              handleWorkflowIssueRegistration(value, notificationRef);
            }}
            isLoading={isWorkflowLoading}
            data={animalInfo}
            isShowFee={true}
          />
        )}
        {isShowWorkflowReactivate && (
          <ReasonDialog
            onClose={() => setIsShowWorkflowReactivate(false)}
            onSubmit={(
              value: ProvideReason,
              notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
            ) =>
              handleWorkflowReactivate(
                valueGetter(nameOfMapObj("Registration")),
                value,
                notificationRef
              )
            }
            setIsLoading={setIsWorkflowLoading}
            isLoading={isWorkflowLoading}
            initialData={objReasonReactivate}
          />
        )}
        {isShowUpdateRegistrationDialog && (
          <AnimalsIssueDialog
            isOnlyUpdateRegistration
            isShowLodgeDate
            onClose={() => setIsShowUpdateRegistrationDialog(false)}
            onSubmit={(
              value: Svc_Registration_Workflow_Issue,
              notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
            ) => {
              handleUpdateRegistration(value, notificationRef);
            }}
            isLoading={isWorkflowLoading}
            data={valueGetter(nameOfMapObj("Registration"))}
            dialogTitle="Update Registration"
          />
        )}
      </>
    );
  }
);
