import { Svc_Contact_PickContactNAR } from "@app/core/contacts/components/dialogs/contacts-from-nar/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { NARSYSTEM } from "@common/constants/enumerations";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_Contact_PickContactNAR>();
const colConfig: IColumnFields[] = [
  {
    field: nameOf("ContactID"),
    title: "NAR Number",
  },
  {
    field: nameOf("DisplayName"),
    title: "Display Name",
  },
  {
    field: nameOf("FirstName"),
    title: "First Name",
  },
  {
    field: nameOf("LastName"),
    title: "Last Name / Organisation ",
  },
  {
    field: nameOf("DOB"),
    title: "Date Of Birth",
    format: DATE_FORMAT.DATE_CONTROL,
  },
  {
    field: nameOf("Address"),
    title: "Address",
  },
  {
    field: nameOf("TypeName"),
    title: "Type Name",
  },
  {
    field: nameOf("Email"),
    title: "Email",
  },
  {
    field: nameOf("Phone"),
    title: "Phone",
  },
  {
    field: nameOf("WorkPhone"),
    title: "Work Phone",
  },
  {
    field: nameOf("HomePhone"),
    title: "Home Phone",
  },
  {
    field: nameOf("Mobile"),
    title: "Mobile",
  },
];

export const generateColNARContactGrid = (
  narSystem: NARSYSTEM,
  detailSearchEnabled: boolean,
  useAPI: boolean,
  showDOBFilter: boolean
) => {
  let invisibleColumn: string[] = [];
  if (narSystem === NARSYSTEM.PNR && detailSearchEnabled) {
    invisibleColumn = [
      nameOf("DisplayName"),
      nameOf("TypeName"),
      nameOf("WorkPhone"),
      nameOf("Mobile"),
      nameOf("Phone"),
    ];
  } else if (
    (narSystem === NARSYSTEM.Custom || narSystem === NARSYSTEM.CustomDynamic) &&
    detailSearchEnabled
  ) {
    invisibleColumn = showDOBFilter
      ? [
          nameOf("DisplayName"),
          nameOf("TypeName"),
          nameOf("WorkPhone"),
          nameOf("Mobile"),
          nameOf("HomePhone"),
        ]
      : [
          nameOf("DisplayName"),
          nameOf("TypeName"),
          nameOf("DOB"),
          nameOf("WorkPhone"),
          nameOf("Mobile"),
          nameOf("HomePhone"),
        ];
  } else if (useAPI) {
    invisibleColumn = [
      nameOf("FirstName"),
      nameOf("LastName"),
      nameOf("DOB"),
      nameOf("Phone"),
    ];
  } else {
    invisibleColumn = [
      nameOf("FirstName"),
      nameOf("LastName"),
      nameOf("DOB"),
      nameOf("Phone"),
    ];
  }

  return colConfig.filter(
    (columnField: IColumnFields) => !invisibleColumn.includes(columnField.field)
  );
};
