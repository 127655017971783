import { colSystemType } from "@app/products/waste-water/[id]/components/child-screen/general/config";
import {
  dropdownValidatorObject,
  getAssessmentDisplay,
  getPreferredMethod_Formatted,
} from "@app/products/waste-water/[id]/components/child-screen/general/util";
import { WastewaterAddressPicker } from "@app/products/waste-water/[id]/components/inputs/address-picker/_index";
import {
  SystemTypeSelection,
  WasteWaterSystemRegisterSummary,
} from "@app/products/waste-water/[id]/model";
import { useWasteWaterStore } from "@app/products/waste-water/[id]/store";
import { WastewaterUrl } from "@app/products/waste-water/config";
import { DATE_FORMAT, DECIMAL_FORMAT } from "@common/constants/common-format";
import { FormRoute } from "@common/constants/menu";
import { useIsNew } from "@common/hooks/useIsNew";
import { IParentSection } from "@common/pages/contacts/model";
import { getDisplayTextWithDashes, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { getFullAddressHTML } from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

const nameOf = nameOfFactory<WasteWaterSystemRegisterSummary>();
interface IWastewaterParentSectionProps {
  formRenderProps: FormRenderProps;
}
export const WastewaterParentSection = observer(
  ({ formRenderProps }: IWastewaterParentSectionProps) => {
    const getValue = formRenderProps.valueGetter;
    const { onChange } = formRenderProps;
    const { wastewaterLov } = useWasteWaterStore();
    const history = useHistory();
    const isNew = useIsNew();
    const portalAddress = getValue("Applicant.Contact.PostalAddress");
    const propertyAssessment = getValue("Address.PropertyAssessment");
    const contact = getValue("Applicant.Contact");

    const applicantContactDisplayName = getValue(
      "Applicant.Contact.DisplayName"
    );
    const applicantContactExtensionOrganisation = getValue(
      "Applicant.Contact.Extension.Organisation"
    );
    const contractorContactDisplayName = getValue(
      "Contractor.Contact.DisplayName"
    );
    const contractorContactExtensionOrganisation = getValue(
      "Contractor.Contact.Extension.Organisation"
    );
    const plumberContactDisplayName = getValue("Plumber.Contact.DisplayName");

    const plumberContactExtensionOrganisation = getValue(
      "Plumber.Contact.Extension.Organisation"
    );
    const plumberContactExtensionLicenceNo = getValue(
      "Plumber.Contact.Extension.LicenceNo"
    );

    const getDisplayApplicant = useMemo(() => {
      return getDisplayTextWithDashes([
        contractorContactDisplayName,
        contractorContactExtensionOrganisation,
      ]);
    }, [contractorContactDisplayName, contractorContactExtensionOrganisation]);

    const getDisplayContractor = useMemo(() => {
      return getDisplayTextWithDashes([
        applicantContactDisplayName,
        applicantContactExtensionOrganisation,
      ]);
    }, [applicantContactDisplayName, applicantContactExtensionOrganisation]);

    const getDisplayPlumber = useMemo(() => {
      getDisplayTextWithDashes([
        plumberContactDisplayName,
        plumberContactExtensionOrganisation,
        plumberContactExtensionLicenceNo,
      ]);
    }, [
      plumberContactDisplayName,
      plumberContactExtensionOrganisation,
      plumberContactExtensionLicenceNo,
    ]);

    const getContact = useMemo(() => {
      return getPreferredMethod_Formatted(contact);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact]);

    const getDetal = useMemo(() => {
      return getAssessmentDisplay(propertyAssessment);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyAssessment]);

    const getPortalAddress = useMemo(() => {
      return getFullAddressHTML(portalAddress);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portalAddress]);

    const navigateToApplicantContactRelationship = () => {
      const parentPage: IParentSection[] = [
        { key: 1, label: "Permit number", value: "20183" },
        { key: 2, label: "Type", value: "A & A Worm Farm" },
        {
          key: 3,
          label: "Address",
          value: "470 Beaconsfield-Emerald GUYS HILL VIC 3807",
        },
      ];
      const applicant_rid = getValue("Applicant_RID");
      const isNull = !getValue("Applicant");
      if (isNull) {
        return history.push(
          `${FormRoute.FORM_CONTACT_REPLATIONSHIP}/${
            isNew ? "new" : applicant_rid
          }`,
          {
            flag_ContactFromNar: true,
            title: "Applicant",
          }
        );
      } else {
        return history.push(
          `${FormRoute.FORM_CONTACT_REPLATIONSHIP}/${applicant_rid}`,
          {
            parentPage,
            flag_ContactFromNar: true,
            title: "Applicant",
          }
        );
      }
    };

    const navigateToOwnerContactRelationship = () => {
      const parentPage: IParentSection[] = [
        { key: 1, label: "Permit number", value: "20183" },
        { key: 2, label: "Type", value: "A & A Worm Farm" },
        {
          key: 3,
          label: "Address",
          value: "470 Beaconsfield-Emerald GUYS HILL VIC 3807",
        },
      ];

      const isNull = !getValue("Owner");
      if (isNull) {
        return history.push(`${FormRoute.FORM_CONTACT_REPLATIONSHIP}/new`, {
          flag_ContactFromNar: true,
          title: "Owner",
        });
      } else {
        return history.push(`${FormRoute.FORM_CONTACT_REPLATIONSHIP}/new`, {
          parentPage,
          flag_ContactFromNar: true,
          title: "Owner",
        });
      }
    };

    const navigateToContractorContactRelationship = () => {
      const parentPage: IParentSection[] = [
        { key: 1, label: "Permit number", value: "20183" },
        { key: 2, label: "Type", value: "A & A Worm Farm" },
        {
          key: 3,
          label: "Address",
          value: "470 Beaconsfield-Emerald GUYS HILL VIC 3807",
        },
      ];
      const isNull = !getValue("Contractor");
      if (isNull) {
        return history.push(`${FormRoute.FORM_CONTACT_REPLATIONSHIP}/new`, {
          flag_ContactFromNar: true,
          title: "Contractor",
        });
      } else {
        return history.push(`${FormRoute.FORM_CONTACT_REPLATIONSHIP}/new`, {
          parentPage,
          flag_ContactFromNar: true,
          title: "Contractor",
        });
      }
    };

    return (
      <>
        {/* SECTION 1 */}
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Site address
                <CCTooltip type="validator" position="right" />
              </label>

              <Field
                name={nameOf("Address")}
                placeholder="Address"
                disabled
                validator={requiredValidator}
                component={WastewaterAddressPicker}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">
                Owner
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                onClick={navigateToOwnerContactRelationship}
                name={"Owner.Contact.DisplayName"}
                placeholder="Owner"
                readOnly
                disabledButton
                component={InputPicker}
              />
            </div>

            <div className="cc-field">
              <CCValueField label="Details" value={sanitizeHtml(getDetal)} />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Applicant
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                onClick={navigateToApplicantContactRelationship}
                name={nameOf("Applicant")}
                placeholder="Applicant"
                readOnly
                disabledButton
                component={InputPicker}
                value={getDisplayApplicant}
                validator={requiredValidator}
              />
            </div>

            <div className="cc-field">
              <CCValueField
                label="Postal address"
                value={sanitizeHtml(getPortalAddress)}
              />
            </div>

            <div className="cc-field">
              <CCValueField label="Contact" value={getContact} />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Allotment size</label>
              <div className="cc-custom-input-group">
                <Field
                  autoComplete="off"
                  name={nameOf("AllotmentSize")}
                  placeholder="Allotment size"
                  spinners={false}
                  format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                  component={NumericTextBox}
                />
                <div className="cc-input-group-postfix">m²</div>
              </div>
            </div>

            <div className="cc-field">
              <label className="cc-label">Received</label>
              <Field
                name={nameOf("DateReceived")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={DatePicker}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">GPS of tank</label>
              <Field
                autoComplete="off"
                name={nameOf("GPSofTank")}
                placeolder="GPS of Tank"
                component={Input}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />

        {/* SECTION 2 */}
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Primary system
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={"SystemType.Name"}
                titleHeader="Pick Primary System"
                fieldName={"SystemType.Name"}
                component={InputPicker}
                isMultiSelect={false}
                url={WastewaterUrl.GET_WASTEWATER_SYSTEM_TYPE}
                validator={requiredValidator}
                placeholder="Primary system"
                tableTypes={colSystemType}
                gridPrimaryField={"SystemType_ID"}
                value={getValue("SystemType.Name")}
                onSelect={(selectedData: SystemTypeSelection[]) => {
                  onChange("SystemType.", { value: selectedData[0] });
                  onChange("SystemSize", { value: selectedData[0].Size });
                }}
                readOnly
              />
            </div>

            <div className="cc-field">
              <CCValueField
                label="Manufacturer"
                value={getValue("SystemType.Manufacturer")}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Size</label>
              <div className="cc-custom-input-group">
                <Field
                  autoComplete="off"
                  name={nameOf("SystemSize")}
                  placeholder="Size"
                  spinners={false}
                  component={NumericTextBox}
                />
                <div className="cc-input-group-postfix">litres</div>
              </div>
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Secondary system</label>
              <Field
                name={"SecondarySystemType.Name"}
                titleHeader="Pick Secondary System"
                fieldName={"SecondarySystemType.Name"}
                component={InputPicker}
                gridPrimaryField={"SystemType_ID"}
                isMultiSelect={false}
                url={WastewaterUrl.GET_WASTEWATER_SYSTEM_TYPE}
                placeholder="Secondary system"
                tableTypes={colSystemType}
                value={getValue("SecondarySystemType.Name")}
                onSelect={(selectedData: SystemTypeSelection[]) => {
                  onChange("SecondarySystemType", {
                    value: selectedData[0],
                  });
                  onChange("SecondarySystemSize", {
                    value: selectedData[0].Size,
                  });
                }}
                readOnly
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Manufacturer</label>
              <div className="cc-label-field">
                {getValue("SecondarySystemType.Manufacturer")}
              </div>
            </div>

            <div className="cc-field">
              <label className="cc-label">Size</label>
              <div className="cc-custom-input-group">
                <Field
                  name={"SecondarySystemSize"}
                  placeholder="Size"
                  spinners={false}
                  component={NumericTextBox}
                  value={
                    getValue("SecondarySystemSize") ||
                    getValue("SecondarySystemType.Size")
                  }
                />
                <div className="cc-input-group-postfix">litres</div>
              </div>
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Tertiary system</label>
              <Field
                name={"TertiarySystemType.Name"}
                titleHeader="Pick Tertiary System"
                fieldName={"TertiarySystemType.Name"}
                component={InputPicker}
                isMultiSelect={false}
                url={WastewaterUrl.GET_WASTEWATER_SYSTEM_TYPE}
                gridPrimaryField={"SystemType_ID"}
                placeholder="Tertiary system"
                tableTypes={colSystemType}
                value={getValue("TertiarySystemType.Name")}
                onSelect={(selectedData: SystemTypeSelection[]) => {
                  onChange("TertiarySystemType", {
                    value: selectedData[0],
                  });
                  onChange("TertiarySystemSize", {
                    value: selectedData[0].Size,
                  });
                }}
                readOnly
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Manufacturer</label>
              <div className="cc-label-field">
                {getValue("TertiarySystemType.Manufacturer")}
              </div>
            </div>

            <div className="cc-field">
              <label className="cc-label">Size</label>
              <div className="cc-custom-input-group">
                <Field
                  name={"TertiarySystemSize"}
                  placeholder="Size"
                  spinners={false}
                  component={NumericTextBox}
                  value={
                    getValue("TertiarySystemSize") ||
                    getValue("TertiarySystemType.Size")
                  }
                />
                <div className="cc-input-group-postfix">litres</div>
              </div>
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Water authority</label>
              <Field
                name={nameOf("WaterAuthority")}
                dataItemKey="Key"
                textField="Value"
                data={wastewaterLov?.WaterAuthority ?? []}
                component={CCSearchComboBox}
                value={
                  getValue("WaterAuthority")
                    ? {
                        Key: getValue("WaterAuthority.Keyword_ID"),
                        Value: getValue("WaterAuthority.Name"),
                      }
                    : null
                }
                onChange={(event: ComboBoxChangeEvent) => {
                  if (!event.value) {
                    onChange("WaterAuthority", {
                      value: null,
                    });
                  } else {
                    onChange("WaterAuthority.Keyword_ID", {
                      value: event.value?.Key ?? null,
                    });
                    onChange("WaterAuthority.Name", {
                      value: event.value?.Value ?? null,
                    });
                  }
                }}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Plumber</label>
              <Field
                name={nameOf("Plumber")}
                placeholder="Plumber"
                readOnly
                disabledButton
                component={InputPicker}
                value={getDisplayPlumber}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Contractor</label>
              <Field
                onClick={navigateToContractorContactRelationship}
                name={nameOf("Contractor")}
                placeholder="Contractor"
                readOnly
                disabledButton
                component={InputPicker}
                value={getDisplayContractor}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Status
                <CCTooltip type="validator" />
              </label>
              <Field
                name={nameOf("Status")}
                dataItemKey="Key"
                textField="Value"
                data={wastewaterLov?.Status ?? []}
                component={CCSearchComboBox}
                validator={dropdownValidatorObject}
                value={
                  getValue("Status")
                    ? {
                        Key: getValue("Status.Keyword_ID"),
                        Value: getValue("Status.Name"),
                      }
                    : null
                }
                onChange={(event: ComboBoxChangeEvent) => {
                  if (!event.value) {
                    onChange("Status", {
                      value: null,
                    });
                  } else {
                    onChange("Status.Keyword_ID", {
                      value: event.value?.Key ?? null,
                    });
                    onChange("Status.Name", {
                      value: event.value?.Value ?? null,
                    });
                  }
                }}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Risk level</label>
              <Field
                name={nameOf("RiskLevel")}
                dataItemKey="Key"
                textField="Value"
                data={wastewaterLov?.RiskLevel ?? []}
                component={CCSearchComboBox}
                value={
                  getValue("RiskLevel")
                    ? {
                        Key: getValue("RiskLevel.Keyword_ID"),
                        Value: getValue("RiskLevel.Name"),
                      }
                    : null
                }
                onChange={(event: ComboBoxChangeEvent) => {
                  if (!event.value) {
                    onChange("RiskLevel", {
                      value: null,
                    });
                  } else {
                    onChange("RiskLevel.Keyword_ID", {
                      value: event.value?.Key ?? null,
                    });
                    onChange("RiskLevel.Name", {
                      value: event.value?.Value ?? null,
                    });
                  }
                }}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Permit type
                <CCTooltip type="validator" />
              </label>
              <Field
                name={nameOf("TypeOfApplication")}
                dataItemKey="Key"
                textField="Value"
                data={wastewaterLov?.PermitType ?? []}
                component={CCSearchComboBox}
                validator={dropdownValidatorObject}
                value={
                  getValue("TypeOfApplication")
                    ? {
                        Key: getValue("TypeOfApplication.Keyword_ID"),
                        Value: getValue("TypeOfApplication.Name"),
                      }
                    : null
                }
                onChange={(event: ComboBoxChangeEvent) => {
                  if (!event.value) {
                    onChange("TypeOfApplication", {
                      value: null,
                    });
                  } else {
                    onChange("TypeOfApplication.Keyword_ID", {
                      value: event.value?.Key ?? null,
                    });
                    onChange("TypeOfApplication.Name", {
                      value: event.value?.Value ?? null,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />
      </>
    );
  }
);
