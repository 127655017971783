import { CreateActionDialog } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/dialog/create-action/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const CreateActionButton = () => {
  const [isShowDialog, setIsShowDialog] = useState(false);

  return (
    <>
      <CCNavButton
        title="Create action"
        onClick={() => setIsShowDialog(true)}
      />

      {isShowDialog && (
        <CreateActionDialog onCloseDialog={() => setIsShowDialog(false)} />
      )}
    </>
  );
};
