import { LLPermitsMenu } from "@app/products/local-laws/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const permitsRoute: ICCRoute = {
  path: "permits",
  name: "Permits",
  enum: LLPermitsMenu.Permits,
  children: [
    {
      path: "register",
      name: "Register",
      enum: LLPermitsMenu.PermitsRegister,
      component: require("./register/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      enum: LLPermitsMenu.PermitsByType,
      component: require("./by-type/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      enum: LLPermitsMenu.PermitsByStatus,
      component: require("./by-status/_index").default,
    },
    {
      path: "by-location",
      name: "By Location",
      enum: LLPermitsMenu.PermitsByLocation,
      component: require("./by-location/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      enum: LLPermitsMenu.PermitsByOfficer,
      component: require("./by-officer/_index").default,
    },
    {
      path: "by-area",
      name: "By Area",
      enum: LLPermitsMenu.PermitsByArea,
      component: require("./by-area/_index").default,
    },
    {
      path: "new-applications",
      name: "New Applications",
      enum: LLPermitsMenu.PermitsNew,
      component: require("./new-applications/_index").default,
    },
    {
      path: "lodged",
      name: "Lodged",
      enum: LLPermitsMenu.PermitsLodged,
      component: require("./lodged/_index").default,
    },
    {
      path: "refused",
      name: "Refused",
      enum: LLPermitsMenu.PermitsRefused,
      component: require("./refused/_index").default,
    },
    {
      path: "expired",
      name: "Expired",
      enum: LLPermitsMenu.PermitsExpired,
      component: require("./expired/_index").default,
    },
    {
      path: "deactivated",
      name: "Deactivated",
      enum: LLPermitsMenu.PermitsDeactivated,
      component: require("./deactivated/_index").default,
    },
    {
      path: "withdrawn",
      name: "Withdrawn",
      enum: LLPermitsMenu.PermitsWithdrawn,
      component: require("./withdrawn/_index").default,
    },
    {
      path: "insurance-status",
      name: "Insurance Status",
      enum: LLPermitsMenu.PermitsInsurance,
      component: require("./insurance-status/_index").default,
    },
  ],
};
