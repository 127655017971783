import { DTO_RebateClaim } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/reference-sidebar/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const getRebateClaimDetailsTabById = async (
  RebateClaimsId: number
): Promise<DTO_RebateClaim | undefined> => {
  try {
    const response = await CoreAPIService.getClient().get<DTO_RebateClaim>(
      `/api/property/internal/rebate/${RebateClaimsId}/tabdetails`
    );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
