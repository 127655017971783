export const mockSchemesAccountInstalments = [
  {
    Instalment: new Date("31-Aug-2017"),
    DueDate: new Date("30-Sep-2017"),
    InstalmentNumber: 2,
    InstalmentAmount: 1219.99,
    InstalmentBalance: 0.0,
    PaymentReceivedDate: new Date("10-Oct-2017"),
    PrincipalIncludesOther: 1219.99,
    PrincipalBalance: 0.0,
    InterestBalance: 0.0,
    OtherBalance: 0.0,
    Id: 58,
  },
  {
    Instalment: new Date("31-Oct-2017"),
    DueDate: new Date("30-Nov-2017"),
    InstalmentNumber: 3,
    InstalmentAmount: 1219.99,
    InstalmentBalance: 0.0,
    PaymentReceivedDate: new Date("4-Dec-2017"),
    PrincipalIncludesOther: 1219.99,
    PrincipalBalance: 0.0,
    InterestBalance: 0.0,
    OtherBalance: 0.0,
    Id: 59,
  },
  {
    Instalment: new Date("31-Jan-2018"),
    DueDate: new Date("28-Feb-2018"),
    InstalmentNumber: 4,
    InstalmentAmount: 1219.99,
    InstalmentBalance: 0.0,
    PaymentReceivedDate: new Date("5-Mar-2017"),
    PrincipalIncludesOther: 1219.99,
    PrincipalBalance: 0.0,
    InterestBalance: 0.0,
    OtherBalance: 0.0,
    Id: 60,
  },
  {
    Instalment: new Date("30-Apr-2018"),
    DueDate: new Date("31-May-2018"),
    InstalmentNumber: 5,
    InstalmentAmount: 1214.47,
    InstalmentBalance: 0.0,
    PaymentReceivedDate: new Date("7-Jun-2017"),
    PrincipalIncludesOther: 1214.47,
    PrincipalBalance: 0.0,
    InterestBalance: 0.0,
    OtherBalance: 0.0,
    Id: 61,
  },
  {
    Instalment: new Date("31-Aug-2018"),
    DueDate: new Date("30-Sep-2018"),
    InstalmentNumber: 6,
    InstalmentAmount: 1214.47,
    InstalmentBalance: 0.0,
    PaymentReceivedDate: new Date("5-Oct-2018"),
    PrincipalIncludesOther: 1214.47,
    PrincipalBalance: 0.0,
    InterestBalance: 0.0,
    OtherBalance: 0.0,
    Id: 62,
  },
  {
    Instalment: new Date("31-Oct-2018"),
    DueDate: new Date("30-Nov-2018"),
    InstalmentNumber: 7,
    InstalmentAmount: 1214.47,
    InstalmentBalance: 0.0,
    PaymentReceivedDate: new Date("27-Feb-2019"),
    PrincipalIncludesOther: 1214.47,
    PrincipalBalance: 0.0,
    InterestBalance: 0.0,
    OtherBalance: 0.0,
    Id: 63,
  },
];
