import {
  DTO_Journal_LOVs,
  DTO_Workflow_JournalCreate,
  RequestJournalCreateObj,
} from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postProcessCreateJournal = async (
  workflowType: WorkflowProcessMode,
  holdingData: DTO_Workflow_JournalCreate
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_JournalCreate>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/createjournal/process/${workflowType}`,
      holdingData
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataJournal =
  | [APIResponse<DTO_Journal_LOVs>, APIResponse<DTO_Workflow_JournalCreate>]
  | APIResponse<DTO_Journal_LOVs | DTO_Workflow_JournalCreate>
  | undefined;
export const getInitialDataJournal = async (
  workflowDraftId: number | undefined,
  journalType?: number,
  accountId?: number
): Promise<IGetInitialDataJournal> => {
  let payload: any = {
    Journal_Number: 0, // Default for new
  };
  if (journalType !== undefined) {
    payload = {
      ...payload,
      Journal_Type: journalType,
      DD_AccountId: accountId,
    };
  }
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_LOVs>(
        `api/property/internal/journal/lovs`
      ),
      !isNil(workflowDraftId)
        ? getWorkflowCreateJournal(workflowDraftId)
        : getNewWorkflowCreateJournal(payload),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowCreateJournal = async (
  requestObj: RequestJournalCreateObj | object = {}
): Promise<APIResponse<DTO_Workflow_JournalCreate>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/createjournal/new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowCreateJournal = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_JournalCreate>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/createjournal/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
