import { putRequestToBeHeardApplication } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/request-to-be-heard/api";
import { PSARStoreInstance } from "@app/products/town-planning/ppr/psa-referrals/_id/store";

import { isSuccessIdentityPacket } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class RequestToBeHeardButtonStore {
  private _isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  requestToBeHeard = async (id?: number) => {
    this.setIsLoading(true);
    if (!id) return;
    const response = await putRequestToBeHeardApplication(id);
    if (isSuccessIdentityPacket(response) && response.data?.Application) {
      PSARStoreInstance.setPPRWithLoading(response.data.Application);
      appNotificationStore.clearNotifications();
      appNotificationStore.pushNotification({
        title: "Request to be heard successfully",
        type: "success",
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Request to be heard failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }

    this.setIsLoading(false);
  };
}

export const requestToBeHeardButtonStore = new RequestToBeHeardButtonStore();
const RequestToBeHeardButtonContext = createContext(
  requestToBeHeardButtonStore
);
export const useRequestToBeHeardButtonStore = () => {
  return useContext(RequestToBeHeardButtonContext);
};
