import { IWithdrawCommentDialogData } from "@app/products/town-planning/ppr/components/action-bar/dialogs/withdraw-comment/model";
import { submitWithdrawComment } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/withdraw-application/api";
import { PPRStoreInstance } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class WithdrawApplicationStore {
  private _isLoading: boolean = false;
  private _isShowDialog: boolean = false;
  private _isShowWithDrawDialog: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isShowDialog() {
    return toJS(this._isShowDialog);
  }
  setIsShowDialog = (isShowDialog: boolean) => {
    runInAction(() => {
      this._isShowDialog = isShowDialog;
    });
  };

  get isShowWithDrawDialog() {
    return toJS(this._isShowWithDrawDialog);
  }
  setIsShowWithDrawDialog = (isShowWithDrawDialog: boolean) => {
    runInAction(() => {
      this._isShowWithDrawDialog = isShowWithDrawDialog;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isShowDialog = false;
      this._isLoading = false;
    });
  };

  handleSubmitWithdraw = async (
    data?: IWithdrawCommentDialogData,
    pprId?: number
  ) => {
    if (pprId) {
      this.setIsLoading(true);
      const response = await submitWithdrawComment(pprId, data?.Comment);
      this.setIsShowWithDrawDialog(false);
      this.setIsLoading(false);
      if (isSuccessIdentityPacket(response)) {
        appNotificationStore.clearErrorNotification();
        PPRStoreInstance.setPPRWithLoading(response.data.Application);
        appNotificationStore.pushNotification({
          type: "success",
          title: "Application withdrawn successfully",
        });
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Withdraw application failed",
          description: response.data?.Errors ?? response.statusText,
          type: "error",
        });
      }
    }
  };
}
export const withdrawApplicationStore = new WithdrawApplicationStore();
const withdrawApplicationStoreContext = createContext(withdrawApplicationStore);
export const useWithdrawApplicationStore = () => {
  return useContext(withdrawApplicationStoreContext);
};
