import { ISchemeAccountDetailTab } from "./model";

export const mockSchemeAccountDetailTab: ISchemeAccountDetailTab = {
  AccountId: "807",
  AssessmentId: "27172",
  Scheme: "Wheeler Rood Marvknoll Special Charge Scheme",
  Overdue: 0,
  Due: 8208.56,
  BalanceOwing: 8208.56,
  PayoutAmount: 8208.56,
};
