import "@app/products/animals/components/forms/components/form-element/_index.scss";
import { DDAuthorisationBankingSection } from "@app/products/direct-debit/authorisations/[id]/components/general/form-element/banking-session/_index";
import { DDAuthorisationGeneralSection } from "@app/products/direct-debit/authorisations/[id]/components/general/form-element/general-session/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { FormElement, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export interface IDirectDebitAuthorisationFormElementProps {
  formRenderProps: FormRenderProps;
}

export const DirectDebitAuthorisationFormElement = ({
  formRenderProps,
}: IDirectDebitAuthorisationFormElementProps) => {
  return (
    <FormElement>
      <DDAuthorisationGeneralSection formRenderProps={formRenderProps} />
      <CCGeneralPanel
        title="Banking"
        isSubPanel
        component={<DDAuthorisationBankingSection />}
      />
    </FormElement>
  );
};
