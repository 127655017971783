import { getSiteUser } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/api";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IActionComplete } from "@common/pages/actions/components/action-bar/dialog/complete-action/model";
import { UserInfo } from "@components/layout/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class ActionCompleteButtonStore {
  private _isShowDialog: boolean = false;
  private _isLoadingLoadActionComplete: boolean = false;
  private _isLoadingOnDialog: boolean = false;
  private _initCompleteBy: string = "";
  private _initCompleteByID?: number = undefined;
  private _initialValues?: IActionComplete = undefined;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get isShowDialog() {
    return toJS(this._isShowDialog);
  }
  setIsShowDialog = (isShowDialog: boolean) => {
    runInAction(() => {
      this._isShowDialog = isShowDialog;
    });
  };

  get initCompleteBy() {
    return toJS(this._initCompleteBy);
  }
  setInitCompleteBy = (initCompleteBy: string) => {
    runInAction(() => {
      this._initCompleteBy = initCompleteBy;
    });
  };

  get initCompleteByID() {
    return toJS(this._initCompleteByID);
  }
  setInitCompleteByID = (setInitCompleteByID: number) => {
    runInAction(() => {
      this._initCompleteByID = setInitCompleteByID;
    });
  };

  get initialValues() {
    return toJS(this._initialValues);
  }
  setInitialValues = (initialValues: IActionComplete) => {
    runInAction(() => {
      this._initialValues = initialValues;
    });
  };

  get isLoadingLoadActionComplete() {
    return toJS(this._isLoadingLoadActionComplete);
  }
  setIsLoadingLoadActionComplete = (isLoadingLoadActionComplete: boolean) => {
    runInAction(() => {
      this._isLoadingLoadActionComplete = isLoadingLoadActionComplete;
    });
  };

  get isLoadingOnDialog() {
    return toJS(this._isLoadingOnDialog);
  }
  setIsLoadingOnDialog = (isLoadingOnDialog: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialog = isLoadingOnDialog;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  loadActionCompleteDetails = async (
    currentUserInfo: UserInfo | null,
    isReload?: boolean
  ) => {
    if (currentUserInfo?.UserPKID) {
      isReload
        ? this.setIsLoadingOnDialog(true)
        : this.setIsLoadingLoadActionComplete(true);
      const response = await getSiteUser(parseInt(currentUserInfo.UserPKID));
      isReload
        ? this.setIsLoadingOnDialog(false)
        : this.setIsLoadingLoadActionComplete(false);
      if (isSuccessResponse(response)) {
        if (response.data) {
          this.setInitCompleteBy(
            response.data.FirstName + " " + response.data.LastName
          );
          this.setInitCompleteByID(response.data.Contact_ID);
          this.setInitialValues({
            DateCompleted: new Date(),
            CompletedBy: response.data,
            AlertRequestor: true,
          });
        }
      } else {
        this.setResponseLoadError({
          status: response.status,
          error: response.error,
        });
      }
      this.setIsShowDialog(true);
    }
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isShowDialog = false;
      this._isLoadingLoadActionComplete = false;
      this._initCompleteBy = "";
      this._initCompleteByID = undefined;
      this._initialValues = undefined;
      this._isLoadingOnDialog = false;
    });
  };
}
export const actionCompleteStore = new ActionCompleteButtonStore();
const actionCompleteStoreContext = createContext(actionCompleteStore);
export const useActionCompleteButtonStore = () => {
  return useContext(actionCompleteStoreContext);
};
