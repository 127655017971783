import {
  DTO_WorkflowDetail_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";
import { DTO_Supplementary_Create } from "@app/products/property/supplementary-rates/components/form-elements/new-supplementary/model";

export enum EKeysOfStepsNewTransaction {
  WhichJournal = "WhichJournal",
  NewJournal = "CreateNewJournal",
  NewSupplementary = "NewSupplementary",
  AssessmentJournal = "AssessmentTransaction",
  NewChargeDetail = "NewCharge",
  AllocationChargeBalances = "AllocationChargeBalance",
  Documents = "Documents",
  Comments = "Comments",
}

export const keysOfStepsNewTransaction = [
  EKeysOfStepsNewTransaction.NewJournal,
  EKeysOfStepsNewTransaction.NewSupplementary,
  EKeysOfStepsNewTransaction.AssessmentJournal,
  EKeysOfStepsNewTransaction.NewChargeDetail,
  EKeysOfStepsNewTransaction.AllocationChargeBalances,
];

export interface RequestJournalAddAssessmentTransactionObj {
  Assessment_Id: number[];
}

export interface DTO_Workflow_Journal__AddAssessmentTransaction {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_Journal__AddAssessmentTransaction;
}

export interface DTO_WorkflowDetail_Journal__AddAssessmentTransaction
  extends DTO_WorkflowDetail_Base {
  SupplementaryMode: number;
  JournalMode: number;
  AssessmentTransaction: DTO_AssessmentTransaction;
  Assessments: DTO_Assessment[];
  CreateNewJournal: DTO_Journal;
  NewCharge: DTO_AssessmentTransactionCharge;
  NewSupplementary: DTO_Supplementary_Create;
  AllocationChargeBalance: DTO_AllocationChargeBalance;
}

export interface DTO_Journal {
  Journal_Status: number | null;
  Officer_Name: string;
  Officer_Id: number;
  Jnl_Credit_Total: number;
  Jnl_Debit_Total: number;
  Jnl_No_of_Transactions: number;
  Jnl_Notes: string;
  Supplementary_Id: number | null;
  Jnl_Reference: string;
  Jnl_DateTime: Date;
  Jnl_Can_Be_Authorised: boolean | null;
  Assigned_to_Authorise_Officer_Id: number | null;
  Authorising_Officer_Id: number | null;
  Journal_Type: number;
  Journal_Number: number;
  Jnl_Description: string;
}

export enum TransactionAllocationMode {
  NewCharge = 0,
  Manual = 1,
  Sequence = 2,
}

export interface DTO_AssessmentTransactionCharge {
  ChargeDate: Date | null;
  TransactionDate: Date | null;
  TransactionType: number | null;
  RatingPeriod: number | null;
  LevyId: number;
  Code: string;
  Name: string;
  Description: string;
  Amount: number;
  InstalmentPlanId: number | null;
  ApplyRemainingEntitlements: boolean;
}

export interface DTO_ManualChargeBalance {
  Charge_Balances_Id: number;
  BalanceAmount: number | null;
  Interest: number | null;
}

export interface DTO_DDTransaction {
  AccountId: number;
  TransactionTypeId: number | null;
  TransactionDate: Date | null;
  Reference: string;
  Description: string;
  Notes: string;
  DeferredDutyAmount: number | null;
  InterestAmount: number | null;
  OtherAmount: number | null;
}

export interface DTO_AssessmentTransaction {
  Journal_Number: number;
  Transaction_Type: number;
  Amount: number;
  TransactionDateTime: Date;
  TransactionReference: string;
  TransactionDescription: string;
  TransactionNotes: string;
  AllocationMode: number;
  PaymentAllocationNo: number | null;
  Instalment_Plan_Id: number | null;
  AssessmentRebateEntitlementId: number | null;
}

export interface DTO_AllocationChargeBalance {
  ChargeBalance: DTO_ChargeBalance[];
  ManualChargeBalances: DTO_ManualChargeBalance[];
}

export interface DTO_Assessment {
  Valuation_3: number | null;
  Valuation_4: number | null;
  Valuation_5: number | null;
  Valuation_6: number | null;
  Valuation_7: number | null;
  Valuation_8: number | null;
  Valuation_9: number | null;
  Valuation_10: number | null;
  Valuation_11: number | null;
  Valuation_12: number | null;
  Valuation_13: number | null;
  Valuation_14: number | null;
  Valuation_15: number | null;
  Valuation_16: number | null;
  Valuation_17: number | null;
  Valuation_18: number | null;
  Valuation_2: number | null;
  Valuation_1: number | null;
  Primary_Assess_Land_Use_Name: string;
  Assessment_Status: number;
  Assessment_Id: number;
  Assess_Number: number | null;
  Assess_Reference: string;
  Assess_Property_Address: string;
  Ratepayer_Name: string;
  Assess_Legal_Description: string;
  Unit_Entitlement: number | null;
  Valuation_19: number | null;
  Unit_Entitlement_Percentage: number | null;
  Unit_Type_Percentage: number | null;
  Assess_Balance: number;
  Assess_Balance_Outstanding: number | null;
  Assess_Being_Postponed: boolean;
  Assessment_Group_Name: string;
  Valuation_Group_Name: string;
  Assessment_Type: number;
  Unit_Type: string;
  Valuation_20: number | null;
}

export interface DTO_Assessment_ChargeBalance {
  AssessmentId: number;
  ChargeBalances: DTO_ChargeBalance[];
}

export interface DTO_ChargeBalance {
  Charge_Balances_Id: number;
  CB_Original_Amount: number | null;
  CB_Balance_Amount: number | null;
  CB_Interest: number | null;
  CA_Charge_Date: Date | null;
  CI_Instalment_Number: number | null;
  Levy_Name: string;
  CI_Due_Date: Date | null;
  CA_Name: string;
  CA_Code: string;
  Adjusted_Amount: number | null;
  Adjusted_Interest: number | null;
}
