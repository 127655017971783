import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IWithdrawConfirmDialogProps {
  onClosePopup: () => void;
  onConfirmYes: () => void;
}
export default function WithdrawConfirmDialog({
  onClosePopup,
  onConfirmYes,
}: IWithdrawConfirmDialogProps) {
  return (
    <ConfirmDialog
      title={"Withdraw Application"}
      message={"Are you sure you want to withdraw this application?"}
      height={"auto"}
      maxWidth={"25%"}
      onClosePopup={onClosePopup}
      onConfirmYes={onConfirmYes}
    />
  );
}
