import { colMaterMeters } from "@app/products/property/meters/[id]/components/child-screens/installation/components/mater-and-sub-meters/config";
import { AssociationMeter } from "@app/products/property/meters/[id]/components/child-screens/installation/model";
import { useMeterInstallationStore } from "@app/products/property/meters/[id]/components/child-screens/installation/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<AssociationMeter>();
export const InstallationMaterAndSubMeters = observer(() => {
  const {
    setSelectedAssociationMeter,
    associationMeter,
    isLoadingAssociation,
  } = useMeterInstallationStore();

  useEffectOnce(() => {
    return () => {
      setSelectedAssociationMeter([]);
    };
  });
  return (
    <div className="cc-mater-and-sub-meters">
      <label className="cc-label">Master and sub meters</label>
      <div className="cc-custom-sub-panel-bar cc-form-cols-1">
        <div className="cc-field">
          <CCGrid
            isLoading={isLoadingAssociation}
            className="cc-grid-mater-and-sub-meters"
            columnFields={colMaterMeters}
            data={associationMeter ?? []}
            primaryField={nameOf("Id")}
            selectableMode="multiple"
            onSelectionChange={(dataItems) => {
              setSelectedAssociationMeter(
                dataItems.map((meter) => ({
                  ...meter,
                  AssociatedTo: meter.AssociatedFrom,
                }))
              );
            }}
          />
        </div>
      </div>
    </div>
  );
});
