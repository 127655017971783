import { useIsNew } from "@common/hooks/useIsNew";
import { Toast } from "@components/toast";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistedContactRelationship } from "./components/existed-contact-relationship/_index";
import { NewContactRelationship } from "./components/new-contact-relationship/_index";

interface IProps {
  contactRelationshipInfo?: any;
}

export const ManageContactRelationship = ({
  contactRelationshipInfo,
}: IProps): any => {
  const isNew = useIsNew();
  const history: any = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffectOnce(() => {
    const { state } = history.location;
    if (state?.status === "success") {
      Toast.displayToast({
        content: "🚀 Save Contact Relationship successfully !",
        type: "success",
      });
      history.replace(window.location.pathname);
    }
  });

  if (isNew) {
    return <NewContactRelationship setIsLoading={setIsLoading} />;
  }

  return (
    <ExistedContactRelationship
      setIsLoading={setIsLoading}
      isLoading={isLoading}
      contactRelationshipInfo={contactRelationshipInfo}
    />
  );
};
