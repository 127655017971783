import { useMeterReadingsStore } from "@app/products/property/meters/[id]/components/child-screens/reading/store";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

export const MetersReadings = observer(() => {
  const {
    readings,
    isLoading,
    loadReadings,
    setSelectedReadings,
    gridColumns,
  } = useMeterReadingsStore();

  useEffectOnce(() => {
    loadReadings();
  });

  return (
    <div className="cc-property-meters-readings">
      <CCGrid
        isLoading={isLoading}
        className="cc-property-meters-readings-grid"
        data={readings}
        state={{
          sort: [{ field: "ReadingDate", dir: "desc" }],
        }}
        columnFields={gridColumns}
        primaryField="MeterReadingId"
        selectableMode="multiple"
        onSelectionChange={(dataItem: any) => {
          setSelectedReadings([...dataItem]);
        }}
      />
    </div>
  );
});
