import { CreateDefermentCommonButton } from "@app/products/property/assessments/deferments/components/action-bar/buttons/create-deferment/common/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const CreateDefermentListButton = observer(() => {
  const { gridSelectedRows, isEmptyData, lastSelectedId } =
    useCCProductListViewStore();
  const isDisabled = useMemo(() => {
    return !isEmptyData && gridSelectedRows.length !== 1;
  }, [gridSelectedRows, isEmptyData]);

  return (
    <CreateDefermentCommonButton
      isDisabled={isDisabled}
      recordID={lastSelectedId}
    />
  );
});
