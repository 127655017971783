import { assessmentGISValidator } from "@app/products/property/assessments/components/util";
import { GISReferenceDialog } from "@app/products/property/components/dialogs/gis-reference/_index";
import { colGISReference } from "@app/products/property/components/dialogs/gis-reference/config";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { DTO_GISReferences } from "@app/products/property/components/grid/gis-references/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { nameOfLov } from "@app/products/property/model";
import { isFieldVisible } from "@app/products/property/util";
import { Label } from "@common/stores/products/config";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { formatAssessmentNumberInMasked } from "@common/utils/formatting";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMaskedTextBox } from "@components/cc-masked-textbox/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { MaskedTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";

const nameOfGIS = nameOfFactory<DTO_GIS>();
export const ParcelDetailsFormStep = (props: IFormStepElement) => {
  const formValidator = useCallback((value) => {
    return assessmentGISValidator(
      value?.GisReferences ?? [],
      nameOfGIS("Effective_From"),
      nameOfGIS("Effective_To")
    );
  }, []);
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? formValidator : undefined}
    />
  );
};
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isLLS: false,
      isGeneral: false,
      parcelLovs: {},
    },
  }: IFormStepElement) => {
    //Get labels
    const [legalDescriptionLabel, parcelReference, titleLowerCase] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.Legal_Description,
        ECustomColNameProperty.ParcelReference,
        ECustomColNameProperty.TitleLowercase,
      ]);
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    // State
    const [isShowDialog, setIsShowDialog] = useState<boolean>();

    // Variable for gis
    const gis: DTO_GIS[] = getFieldValue("GisReferences") ?? [];
    const selectedGIS = getFieldValue("_option.GISSelected") ?? [];

    const isShowElectoralDivisionLocal: boolean = isFieldVisible(
      valueGetter("ElectoralDivisionLocalVisibility")
    );
    const isShowElectoralDivisionStateCode: boolean = isFieldVisible(
      valueGetter("ElectoralDivisionStateCodeVisibility")
    );
    const isShowElectoralDivisionFederal: boolean = isFieldVisible(
      valueGetter("ElectoralDivisionFederalVisibility")
    );
    // Hide for all now
    //       const isShowLandCategory: boolean = isFieldVisible(
    //         valueGetter("LandCategoryVisibility")
    //       );
    // const isShowSwimmingPool: boolean = isFieldVisible(
    //   valueGetter("SwimmingPoolFlagVisibility")
    // );
    // const isShowPropertyCategories: boolean = isFieldVisible(
    //   valueGetter("PropertyCategoriesVisibility")
    // );
    // const isShowClassifications: boolean = isFieldVisible(
    //   valueGetter("ClassificationsVisibility")
    // );
    // const isShowDistricts: boolean = isFieldVisible(
    //   valueGetter("DistrictsVisibility")
    // );
    // Hide for all now
    // const isShowEstate: boolean = isFieldVisible(
    //   valueGetter("EstateVisibility")
    // );
    const isShowElectoralDivision: boolean =
      isShowElectoralDivisionLocal ||
      isShowElectoralDivisionStateCode ||
      isShowElectoralDivisionFederal;
    // Hide for all now
    // const isShowFireServices: boolean =
    //   isShowPropertyCategories || isShowClassifications || isShowDistricts;

    // Handle logic for GIS grid
    /**
     * handle add item for (GIS Reference)
     * @param data
     */
    const handleAddItem = async (data: DTO_GIS) => {
      //get previous data GIS
      const previousGIS = gis?.length ? [...gis] : [];
      //add more temporary ID for new record
      const newGIS = { ...data, Id: `new_${getUUID()}` };
      //merge previous data and new one
      const gisData = [...previousGIS, newGIS];
      onChange(nameOf("GisReferences"), {
        value: gisData,
      });
    };

    /**
     * handle delete item (GIS Reference)
     */
    const handleDeleteItem = () => {
      const gisId = selectedGIS?.[0]?.Id;
      //filter new list GIS without selected record
      const newGIS = gis.filter((item: DTO_GIS) => item.Id !== gisId);
      onChange(nameOf("GisReferences"), {
        value: newGIS,
      });
      onChange(nameOf("_option.GISSelected"), {
        value: [],
      });
    };

    const handleDataChangeGrid = async (
      dataRow: DTO_GIS,
      fieldChange: string
    ) => {
      //GIS Reference
      const gisId = dataRow?.Id;
      let newGIS = [...gis];
      // update value Effective_To and Effective_From
      newGIS = newGIS.map((item: DTO_GIS) => {
        if (
          item.Id === gisId &&
          (fieldChange === nameOfGIS("Effective_To") ||
            fieldChange === nameOfGIS("Effective_From"))
        ) {
          return {
            ...item,
            Effective_To: dataRow?.Effective_To ?? null,
            Effective_From: dataRow?.Effective_From ?? null,
          };
        }
        return item;
      });
      onChange(nameOf("GisReferences"), { value: newGIS });
    };

    return (
      <section className="cc-field-group">
        <div className="cc-field-group">
          <label className="cc-label">Classification</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">{parcelReference}</label>
                {(options?.isLLS || options?.isGeneral) && (
                  <Field
                    name={nameOf("ParcelReference")}
                    component={CCInput}
                    readOnly={options?.isReadOnly}
                    placeholder={parcelReference}
                  />
                )}
                {options?.isActro && (
                  <Field
                    name={nameOf("ParcelReference")}
                    readOnly={options?.isReadOnly}
                    component={CCMaskedTextBox}
                    mask="000-000-0000-000"
                    onChange={(event: MaskedTextBoxChangeEvent) => {
                      const rawDataOnlyNumber =
                        event.target.value?.replace(/\D/g, "") ?? "";
                      const newValue =
                        formatAssessmentNumberInMasked(rawDataOnlyNumber);
                      if (getFieldValue("ParcelReference") !== newValue) {
                        onChange(nameOf("ParcelReference"), {
                          value: newValue,
                        });
                      }
                    }}
                    placeholder={parcelReference}
                  />
                )}
              </div>
              <div className="cc-field">
                {/* Just display => Read Only field */}
                <label className="cc-label">Land area</label>
                <Field
                  name={nameOf("LandArea")}
                  component={CCInput}
                  readOnly
                  placeholder="Land area"
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Stage</label>
                <Field
                  name={nameOf("EstateStage")}
                  placeholder={"Stage"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cc-field-group">
          <CCLabel
            title={legalDescriptionLabel}
            isMandatory={!getFieldValue("AutoUpdateLegalDescription")}
          />
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  {`Automatically update legal description from ${titleLowerCase} legal
                  descriptions`}
                </label>
                <Field
                  name={nameOf("AutoUpdateLegalDescription")}
                  checked={getFieldValue("AutoUpdateLegalDescription")}
                  component={CCSwitch}
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <Field
                  name={nameOf("LegalDescription")}
                  placeholder={"Legal description"}
                  component={CCTextArea}
                  readOnly={
                    getFieldValue("AutoUpdateLegalDescription") ||
                    options?.isReadOnly
                  }
                  rows={4}
                  validator={
                    !(
                      options?.isReadOnly ||
                      getFieldValue("AutoUpdateLegalDescription")
                    )
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {/* Hide for all */}
        {/*
        <div className="cc-form-cols-2">
          {!options.isLLS && (
            <>
              <div className="cc-field">
                <label className="cc-label">Census district</label>
                <Field
                  name={nameOf("CensusDistrict")}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  isUseDefaultOnchange
                  component={CCSearchComboBox}
                  data={[]}
                  disabled={options?.isReadOnly}
                />
              </div>
               {isShowEstate && (
                <div className="cc-field">
                  <label className="cc-label">Estate</label>
                  <Field
                    name={nameOf("Estate")}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    isUseDefaultOnchange
                    component={CCSearchComboBox}
                    data={[]}
                    disabled={options?.isReadOnly}
                  />
                </div>
              )}
              <div className="cc-field">
                <label className="cc-label">Estate stage</label>
                <Field
                  name={nameOf("EstateStage")}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  isUseDefaultOnchange
                  component={CCSearchComboBox}
                  data={[]}
                  disabled={options?.isReadOnly}
                />
              </div> 
            </>
          )}
        </div>
        */}
        {isShowElectoralDivision && options?.isGeneral && (
          <div className="cc-field-group">
            <label className="cc-label">Electoral division</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                {isShowElectoralDivisionLocal && (
                  <div className="cc-field">
                    <label className="cc-label">Local</label>
                    <Field
                      name={nameOf("LocalElectoralDivision")}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      data={[]}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                )}
                {isShowElectoralDivisionStateCode && (
                  <div className="cc-field">
                    <label className="cc-label">State code</label>
                    <Field
                      name={nameOf("StateElectoralDivision")}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      data={[]}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                )}
                {isShowElectoralDivisionFederal && (
                  <div className="cc-field">
                    <label className="cc-label">Federal</label>
                    <Field
                      name={nameOf("FederalElectoralDivision")}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      data={[]}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {/* Hide for all */}
        {/* {isShowFireServices && (
          <div className="cc-field-group">
            <label className="cc-label">Fire services</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                {isShowPropertyCategories && (
                  <div className="cc-field">
                    <label className="cc-label">Property category</label>
                    <Field
                      name={nameOf("Fire_Property_Category")}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      disabled={options?.isReadOnly}
                      data={[]}
                    />
                  </div>
                )}
                {isShowClassifications && (
                  <div className="cc-field">
                    <label className="cc-label">Classification</label>
                    <Field
                      name={nameOf("Fire_Service_Classification")}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      data={[]}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                )}
                {isShowDistricts && (
                  <div className="cc-field">
                    <label className="cc-label">District</label>
                    <Field
                      name={nameOf("Fire_Service_District")}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      isUseDefaultOnchange
                      component={CCSearchComboBox}
                      data={[]}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )} */}
        {/* Hide for all */}
        {/* <div className="cc-form-cols-2">
          {isShowLandCategory && (
            <div className="cc-field">
              <label className="cc-label">Land category</label>
              <Field
                name={nameOf("LandCategory")}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                isUseDefaultOnchange
                component={CCSearchComboBox}
                data={[]}
                disabled={options?.isReadOnly}
              />
            </div>
          )}
          {isShowSwimmingPool && (
            <div className="cc-field">
              <label className="cc-label">Has a swimming pool</label>
              <Field
                name={nameOf("HasSwimmingPool")}
                checked={getFieldValue("HasASwimmingPool")}
                component={CCSwitch}
                disabled={options?.isReadOnly}
              />
            </div>
          )}
        </div> */}
        <div className="cc-field">
          <CCLabel title="GIS references" errorMessage={errors?.[nameOf("")]} />
          <CCGrid
            readOnly={options?.isReadOnly}
            toolbar={
              !options?.isReadOnly ? (
                <div className="cc-grid-tools-bar">
                  <Button
                    type="button"
                    iconClass="fas fa-plus"
                    title="Add"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsShowDialog(true);
                    }}
                  />
                  <Button
                    type="button"
                    iconClass="fas fa-minus"
                    title="Remove"
                    onClick={(event) => {
                      event.preventDefault();
                      handleDeleteItem();
                    }}
                    disabled={selectedGIS?.length !== 1}
                  />
                </div>
              ) : null
            }
            onDataRowChange={(dataRow: DTO_GIS, fieldChange: string) => {
              handleDataChangeGrid(dataRow, fieldChange);
            }}
            editableMode={!options?.isReadOnly ? "cell" : undefined}
            className="cc-gis-reference-grid-data"
            data={gis ?? []}
            columnFields={colGISReference}
            primaryField={nameOfGIS("Id")}
            selectableMode="single"
            onSelectionChange={(dataItem: DTO_GISReferences[]) => {
              onChange(nameOf("_option.GISSelected"), {
                value: dataItem,
              });
            }}
            selectedRows={selectedGIS}
          />
        </div>
        {isShowDialog && (
          <GISReferenceDialog
            onClose={() => {
              setIsShowDialog(false);
            }}
            onSubmit={(data: DTO_GIS) => {
              handleAddItem(data);
              setIsShowDialog(false);
            }}
            gisReferenceData={gis ?? []}
            gisTypes={options?.parcelLovs?.GISType ?? []}
          />
        )}
      </section>
    );
  }
);
