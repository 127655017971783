import { DTO_GIS_Reference } from "@app/products/property/components/gis-accordion/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfGIS = nameOfFactory<DTO_GIS_Reference>();
export const colGISReferenceAccordion: IColumnFields[] = [
  {
    field: nameOfGIS("GIS_Interface"),
    title: "GIS Reference",
  },
  {
    field: nameOfGIS("Type"),
    title: "Type",
  },
  {
    field: nameOfGIS("Valid_From"),
    title: "From Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfGIS("Valid_To"),
    title: "To Date",
    format: DATE_FORMAT.DATE,
  },
];
