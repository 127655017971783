import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      //<ActionBarBookmarkIcon />,
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <>
      <CCAppNotification />
      <NoData />
    </>
  );
};
