interface ICertificateType {
  [key: string]: string;
}

export const certificateDetailValidator = (
  value: any,
  isLLS: boolean
): string => {
  const certificateType: ICertificateType = {
    Assessment: "Assessments",
    Parcel: isLLS ? "AssociateTitles" : "Parcels",
    PIC: "PICs",
  };
  const certificateTypeName: string =
    certificateType?.[value?._option?.CertificateTypeComponent];

  return certificateTypeName && !value?.[certificateTypeName]?.length
    ? "Invalid step"
    : "";
};
