import { history } from "@/AppRoutes";
import { AnimalType } from "@app/products/animals/model";
import {
  getAnimalTypeByID,
  getAnimalTypeLOVs,
  getSetupRegistrationFeeRulesDialog,
  postAnimalTypeSave,
  postCalculationNonRegistrationFee,
  postCalculationRegistrationFee,
} from "@app/products/animals/system-admin/animal-types/[id]/api";
import {
  Svc_CalculateRegistrationFee,
  TestFees,
} from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/dialogs/test-fee/model";
import { ANIMAL_TYPE_ROUTE } from "@app/products/animals/system-admin/animal-types/[id]/constant";
import {
  AnimalsTypeLOVs,
  RegistrationFeeRulesLOVs,
  RegistrationFeeRulesType,
  SVC_FeeCalculator_NonRegistrationFee_RuleType,
  SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType,
} from "@app/products/animals/system-admin/animal-types/[id]/model";
import { getRegistrationFeeRuleTypeFriendlyName } from "@app/products/animals/system-admin/animal-types/[id]/util";
import { IPSARApplicationParentSection } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class AnimalTypeStore {
  private _animalType?: AnimalType = undefined;
  private _animalTypeDetails?: AnimalType = undefined;
  private _feeRuleObj?: object = undefined;
  private _animalTypeLOVs?: AnimalsTypeLOVs = undefined;
  private _isLoading: boolean = false;
  private _isLoadingDialog: boolean = false;
  private _isLoadingDetails: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _responseLoadErrorDialog?: APIResponseError = undefined;
  private _notification?: IAppNotificationItemAddProps = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _parentSection?: IPSARApplicationParentSection = undefined;
  private _initValueFeeRuleDialog?: object = undefined;
  private _feeRuleDialogLOVs?: RegistrationFeeRulesLOVs = undefined;
  private _feeRuleTypeEnum: RegistrationFeeRulesType =
    RegistrationFeeRulesType.Default;
  private _isShowTestFeeDialog: boolean = false;
  private _isLoadingCalculationFee: boolean = false;
  private _testFees?: TestFees = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get responseLoadErrorDialog() {
    return toJS(this._responseLoadErrorDialog);
  }
  setResponseLoadErrorDialog = (responseLoadErrorDialog?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadErrorDialog = responseLoadErrorDialog;
    });
  };

  get animalType() {
    return this._animalType;
  }
  setAnimalType = (animalType: AnimalType | undefined) => {
    runInAction(() => {
      this._animalType = animalType;
    });
  };

  get animalTypeDetails() {
    return this._animalTypeDetails;
  }
  setAnimalTypeDetails = (animalTypeDetails: AnimalType | undefined) => {
    runInAction(() => {
      this._animalTypeDetails = animalTypeDetails;
    });
  };

  get feeRuleObj() {
    return this._feeRuleObj;
  }
  setFeeRuleObj = (feeRuleObj: object | undefined) => {
    runInAction(() => {
      this._feeRuleObj = feeRuleObj;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingDialog() {
    return this._isLoadingDialog;
  }
  setIsLoadingDialog = (isLoadingDialog: boolean) => {
    runInAction(() => {
      this._isLoadingDialog = isLoadingDialog;
    });
  };

  get isLoadingDetails() {
    return this._isLoadingDetails;
  }
  setIsLoadingDetails = (isLoadingDetails: boolean) => {
    runInAction(() => {
      this._isLoadingDetails = isLoadingDetails;
    });
  };

  get animalTypeLOVs() {
    return this._animalTypeLOVs;
  }
  setAnimalTypeLOVs = (animalTypeLOVs: AnimalsTypeLOVs) => {
    runInAction(() => {
      this._animalTypeLOVs = animalTypeLOVs;
    });
  };

  get notification() {
    return this._notification;
  }
  setNotification = (
    notification: IAppNotificationItemAddProps | undefined
  ) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: IPSARApplicationParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get initValueFeeRuleDialog() {
    return this._initValueFeeRuleDialog;
  }
  setInitValueFeeRuleDialog = (initValueFeeRuleDialog: object) => {
    runInAction(() => {
      this._initValueFeeRuleDialog = initValueFeeRuleDialog;
    });
  };

  get feeRuleDialogLOVs() {
    return this._feeRuleDialogLOVs;
  }
  setFeeRuleDialogLOVs = (feeRuleDialogLOVs: RegistrationFeeRulesLOVs) => {
    runInAction(() => {
      this._feeRuleDialogLOVs = feeRuleDialogLOVs;
    });
  };

  get feeRuleTypeEnum() {
    return this._feeRuleTypeEnum;
  }
  setFeeRuleTypeEnum = (feeRuleTypeEnum: RegistrationFeeRulesType) => {
    runInAction(() => {
      this._feeRuleTypeEnum = feeRuleTypeEnum;
    });
  };

  get animalTypeID() {
    return this.animalType?.AnimalType_ID;
  }

  get isShowTestFeeDialog() {
    return this._isShowTestFeeDialog;
  }
  setIsShowTestFeeDialog = (isShowTestFeeDialog: boolean) => {
    runInAction(() => {
      this._isShowTestFeeDialog = isShowTestFeeDialog;
    });
  };

  get isLoadingCalculationFee() {
    return this._isLoadingCalculationFee;
  }
  setIsLoadingCalculationFee = (isLoadingCalculationFee: boolean) => {
    runInAction(() => {
      this._isLoadingCalculationFee = isLoadingCalculationFee;
    });
  };

  get testFees() {
    return this._testFees;
  }
  setTestFees = (testFees?: TestFees) => {
    runInAction(() => {
      this._testFees = testFees;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._animalType = undefined;
      this._animalTypeDetails = undefined;
      this._animalTypeLOVs = undefined;
      this._isLoading = false;
      this._isLoadingDetails = false;
      this._responseLoadError = undefined;
      this._notification = undefined;
      this._initValueFeeRuleDialog = undefined;
      this._feeRuleDialogLOVs = undefined;
      this._responseLoadErrorDialog = undefined;
      this._isLoadingDialog = false;
      this._feeRuleTypeEnum = RegistrationFeeRulesType.Default;
      this._isShowTestFeeDialog = false;
      this._isLoadingCalculationFee = false;
      this._testFees = undefined;
    });
  };

  loadAnimalType = async (loadAnimalTypeID: number, isNew: boolean = false) => {
    let errorResponse = undefined;
    if (!isNew) {
      this.setIsLoading(true);
      const [responseAnimalType, responseAnimalTypeLOVs] = await Promise.all([
        getAnimalTypeByID(loadAnimalTypeID),
        getAnimalTypeLOVs(),
      ]);
      this.setIsLoading(false);
      if (
        isSuccessResponse(responseAnimalType) &&
        responseAnimalType.data &&
        isSuccessResponse(responseAnimalTypeLOVs) &&
        responseAnimalTypeLOVs?.data
      ) {
        this.setAnimalType(responseAnimalType.data);
        this.setAnimalTypeLOVs(responseAnimalTypeLOVs?.data);
      } else {
        errorResponse = {
          status: responseAnimalType.status ?? responseAnimalTypeLOVs.status,
          error: responseAnimalType.error ?? responseAnimalTypeLOVs.error,
        };
      }
      // Push notification
      if (this.parentSection?.notification) {
        this.parentSection?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setParentSection({ ...this._parentSection, notification: [] });
      }

      if (this.notification) {
        appNotificationStore.pushNotification(this.notification);
        this.setNotification(undefined);
      }
    } else {
      this.setIsLoading(true);
      const [responseAnimalType, responseAnimalTypeLOVs] = await Promise.all([
        getAnimalTypeByID(0),
        getAnimalTypeLOVs(),
      ]);
      this.setIsLoading(false);
      if (
        isSuccessResponse(responseAnimalType) &&
        responseAnimalType?.data &&
        isSuccessResponse(responseAnimalTypeLOVs) &&
        responseAnimalTypeLOVs?.data
      ) {
        this.setAnimalType({
          ...responseAnimalType.data,
          // Pass initial value to prevent the validation message
          Flag_AnimalType: false,
          Flag_BreedingCat: false,
        });
        this.setAnimalTypeLOVs(responseAnimalTypeLOVs?.data);
      } else {
        errorResponse = {
          status: responseAnimalType.status ?? responseAnimalTypeLOVs.status,
          error: responseAnimalType.error ?? responseAnimalTypeLOVs.error,
        };
      }
    }
    this.setResponseLoadError(errorResponse);
  };

  reLoadAnimalType = async (isNew: boolean) => {
    if (this.animalTypeID) {
      await this.loadAnimalType(this.animalTypeID, isNew);
    }
  };

  loadAnimalTypeDetails = async (animalTypeID: number) => {
    let errorResponse = undefined;
    this.setIsLoadingDetails(true);
    const response = await getAnimalTypeByID(animalTypeID);
    this.setIsLoadingDetails(false);

    if (isSuccessResponse(response) && response.data) {
      this.setAnimalTypeDetails(response.data);
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }

    this.setResponseLoadError(errorResponse);
  };

  submitAction = (
    animalType: AnimalType,
    isNew: boolean = false,
    action: ActionSubmitActions
  ) => {
    if (action === ActionSubmitActions.Save) {
      appNotificationStore.clearNotifications();
      this.saveAnimalType(animalType, isNew);
    }

    if (action === ActionSubmitActions.TestFees) {
      this.setIsShowTestFeeDialog(true);
    }
  };

  saveAnimalType = async (animalType: AnimalType, isNew: boolean) => {
    // Change description to empty string if it is null
    if (!animalType.Description) {
      animalType.Description = "";
    }

    this.setIsLoading(true);
    const response = await postAnimalTypeSave(animalType);
    this.setIsLoading(false);

    if (isSuccessResponse(response)) {
      if (isNew) {
        history.replace(
          ANIMAL_TYPE_ROUTE + "/" + response.data?.ID.toString(),
          {
            notification: [
              {
                title: "Record successfully saved.",
                type: "success",
              },
            ],
          }
        );
      } else {
        this.reLoadAnimalType(isNew);
        this.setNotification({
          title: "Record successfully saved.",
          type: "success",
        });
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Record could not be saved.",
        type: "error",
        description: response.data?.Errors ?? response.error,
      });
    }
  };

  setupRegistrationFeeRulesDialog = async (
    feeRuleType: RegistrationFeeRulesType,
    existedFeeRule?: object,
    isErrorReload?: boolean
  ) => {
    this.setResponseLoadErrorDialog(undefined);
    isErrorReload ? this.setIsLoadingDialog(true) : this.setIsLoading(true);
    const response = await getSetupRegistrationFeeRulesDialog(feeRuleType);
    isErrorReload ? this.setIsLoadingDialog(false) : this.setIsLoading(false);

    if (isSuccessResponse(response) && response.data) {
      let feeRules = response.data.RegistrationFeeRule;

      // Trigger required validation
      if (feeRuleType === RegistrationFeeRulesType.RegistrationFeeRules) {
        feeRules = {
          ...feeRules,
          RVPEndMon:
            feeRules?.RVPEndMon === 0 ? undefined : feeRules?.RVPEndMon,
          RVPStartMon:
            feeRules?.RVPStartMon === 0 ? undefined : feeRules?.RVPStartMon,
        };
      }

      if (
        feeRuleType ===
        RegistrationFeeRulesType.RegistrationFeeAdditionalChargeRules
      ) {
        feeRules = {
          ...feeRules,
          AdditionalChargeRuleType_ENUM:
            feeRules.AdditionalChargeRuleType_ENUM ===
            SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType.Default
              ? undefined
              : feeRules.AdditionalChargeRuleType_ENUM,
        };
      }

      this.setInitValueFeeRuleDialog(
        existedFeeRule ? existedFeeRule : feeRules
      );
      this.setFeeRuleDialogLOVs(response.data.LOVs);
      this.setFeeRuleObj(undefined);
    } else {
      if (existedFeeRule) this.setFeeRuleObj(existedFeeRule);
      this.setResponseLoadErrorDialog({
        status: response.status,
        error: response.error,
      });
    }
    // Show dialog
    if (!isErrorReload) {
      this.setFeeRuleTypeEnum(feeRuleType);
    }
  };

  calculationNonRegistrationFeeRule = async (
    nonRegistrationFeeRuleType: SVC_FeeCalculator_NonRegistrationFee_RuleType,
    animalTypeObj: AnimalType,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    this.setIsLoadingCalculationFee(true);
    const response = await postCalculationNonRegistrationFee(
      nonRegistrationFeeRuleType,
      animalTypeObj
    );
    this.setIsLoadingCalculationFee(false);

    if (isSuccessResponse(response) && response.data) {
      const calculationResult = response.data.ReturnObj;
      const mapCalculationObj = {
        CalculationSteps: calculationResult.FeeDetails?._CalculationSteps,
        FeeType_Name: calculationResult.FeeType_Name,
        TotalAmount: calculationResult.FeeDetails?.TotalAmount,
        Status_ENUM: calculationResult.Status_ENUM,
      };
      const newTestFee = this.testFees
        ? { ...this.testFees, ...mapCalculationObj }
        : { ...mapCalculationObj };
      this.setTestFees(newTestFee as TestFees);
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Fee calculation failed.",
        type: "error",
        description: response.data?.Errors ?? response.error,
      });
    }
  };

  calculationRegistrationFeeRule = async (
    calculateRegistrationFeeRequest: Svc_CalculateRegistrationFee,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    this.setIsLoadingCalculationFee(true);
    const response = await postCalculationRegistrationFee(
      calculateRegistrationFeeRequest
    );
    this.setIsLoadingCalculationFee(false);

    if (isSuccessResponse(response) && response.data) {
      const calculationResult = response.data.ReturnObj;
      const AppliedRegistrationFeeRule_ENUM =
        calculationResult.FeeDetails?.AppliedRegistrationFeeRule_ENUM ??
        undefined;
      const calculationSteps = calculationResult.FeeDetails?._CalculationSteps;

      // Add Rule to Calculation Steps
      if (AppliedRegistrationFeeRule_ENUM) {
        calculationSteps?.unshift(
          `Rule: ${getRegistrationFeeRuleTypeFriendlyName(
            AppliedRegistrationFeeRule_ENUM
          )}`
        );
      }

      const mapCalculationObj = {
        CalculationSteps: calculationSteps,
        FeeType_Name: calculationResult.FeeType_Name,
        TotalAmount: calculationResult.FeeDetails?.TotalAmount,
        Status_ENUM: calculationResult.Status_ENUM,
      };

      const newTestFee = this.testFees
        ? { ...this.testFees, ...mapCalculationObj }
        : { ...mapCalculationObj };

      this.setTestFees({ ...newTestFee } as TestFees);
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Calculation fee failed.",
        type: "error",
        description: response.data?.Errors ?? response.error,
      });
    }
  };
}

const animalTypeStoreContext = createContext(new AnimalTypeStore());
export const useAnimalTypeStore = () => {
  return useContext(animalTypeStoreContext);
};
