import { loadViewConfiguresColumns } from "@app/products/property/api";
import {
  DTO_AssociatedItem_Assessment,
  DTO_Valuation_Value,
  PropertyUnion,
} from "@app/products/property/components/associations/components/assessment/model";
import { colAssociationsAssessment } from "@app/products/property/components/associations/components/assessment/standard-assessment/config";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { unionBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAssociationsAssessmentProps {
  associationsAssessmentInfo: DTO_AssociatedItem_Assessment[] | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_Assessment>();

export const AssociationsAssessment = observer(
  ({ associationsAssessmentInfo }: IAssociationsAssessmentProps) => {
    const [
      associationsAssessmentInfoUpdate,
      setAssociationsAssessmentInfoUpdate,
    ] = useState(associationsAssessmentInfo);
    const [configuredCols, setConfiguredCols] = useState<IColumnFields[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();

    const loadViewConfig = async () => {
      setIsLoading(true);
      setResponseLoadError(undefined);
      const response = await loadViewConfiguresColumns(
        ViewConfiguration.Associations_Assessment,
        colAssociationsAssessment
      );
      if (Array.isArray(response)) {
        setConfiguredCols(response);
      } else {
        setResponseLoadError(response);
      }
      setIsLoading(false);
    };

    useEffectOnce(() => {
      loadViewConfig();
    });

    useEffect(() => {
      if (!associationsAssessmentInfo) return;
      let valuationUnionData: DTO_Valuation_Value[] = [];

      associationsAssessmentInfo.forEach((item) => {
        if (!item.ValuationValues) return;
        valuationUnionData = unionBy(
          valuationUnionData,
          item?.ValuationValues,
          PropertyUnion
        );
      });

      const assessmentInfoUpdate = [...associationsAssessmentInfo];
      assessmentInfoUpdate.map((item: any) => {
        if (!item.ValuationValues) return undefined;
        return item.ValuationValues.forEach((element: DTO_Valuation_Value) => {
          item[element.FieldName] = element.Value;
        });
      });

      setAssociationsAssessmentInfoUpdate(assessmentInfoUpdate);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [associationsAssessmentInfo]);

    if (isLoading) return <Loading isLoading={isLoading} />;
    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadViewConfig();
          }}
        />
      );

    return (
      <CCGrid
        className="cc-assessment-grid"
        data={associationsAssessmentInfoUpdate || []}
        columnFields={configuredCols}
        primaryField={nameOf("Id")}
      />
    );
  }
);
