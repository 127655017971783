import { AmsUrl } from "@app/products/ams/constants/ams.url";
import { ILookup, ILookupItem } from "@app/products/ams/model/lookup";
import authHeader from "@common/apis/auth-header";
//import { createContext } from 'react';
import { APIResponseStatus } from "@common/constants/response-status";
import { IResponseData } from "@common/models/service";
import { globalStoreInstance } from "@common/stores/global/store";
import axios from "axios";
import {
  action,
  computed,
  configure,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" }); // Strict mode: runInAction

export default class LookupStore {
  lookups = new Map<string, ILookup>(); //: ILookup[] | null = null;

  getLookup = (name: string, includeNotSet: boolean = false) => {
    try {
      if (this.lookups.entries.length === 0) {
        this.loadFakeLookups();
      }
      let lookup = this.lookups?.get(name);
      if (lookup) {
        if (includeNotSet)
          lookup.items.unshift(this.createLookupItem(-999, "<Not Set>"));
        return lookup;
      } else {
        let lookup1 = this.loadLookup(name);
        if (!!lookup1) {
          //return lookup1;
        } else {
          console.warn("Error loading lookup: Lookup not found: " + { name });
          return null;
        }
      }
    } catch (error) {
      console.error(
        "Error loading lookup: Lookup not found:" + { name } + error
      );
    }
    return null;
  };

  getAssetLookup = async (name: string, includeNotSet: boolean = false) => {
    try {
      if (this.lookups.size === 0) {
        await this.loadLookups([name]);
      }
      let lookup = this.lookups?.get(name);
      if (lookup) {
        if (includeNotSet)
          lookup.items.unshift(this.createLookupItem(-999, "<Not Set>"));
        return lookup;
      } else {
        let lookup1 = (await this.loadLookups([name])) as ILookup[];
        if (lookup1 && lookup1.length > 0) {
          return lookup1[0];
        } else {
          console.warn("Error loading lookup: Lookup not found: " + { name });

          return null;
        }
      }
    } catch (error) {
      console.error(
        "Error loading lookup: Lookup not found: " + { name } + error
      );
    }
    return null;
  };

  getAssetLookupItems = async (
    name: string,
    includeNotSet: boolean = false
  ) => {
    var lookup = await this.getAssetLookup(name, includeNotSet);
    if (!lookup) {
      let blankLookup: ILookup = {
        id: 0,
        name: name,
        lookupType: "internal",
        items: [],
      };
      lookup = blankLookup;
    }
    console.debug("getAssetLookupItems", toJS(lookup).items);

    return toJS(lookup).items;
  };

  getLookupItems = (name: string, includeNotSet: boolean = false) => {
    var lookup = this.getLookup(name, includeNotSet);
    if (!lookup) {
      let blankLookup: ILookup = {
        id: 0,
        name: name,
        lookupType: "internal",
        items: [],
      };
      lookup = blankLookup;
    }
    return lookup;
  };

  getLookupItemDescription = (
    lookupName: string,
    itemId: number | null
  ): string => {
    let description: string = "(item missing)";
    if (!itemId) return "";
    let lookup = this.getLookup(lookupName);
    if (lookup) {
      description =
        lookup.items.find((a) => a.id === itemId)?.description ??
        "(item missing)";
    }
    return description;
  };

  getLookupItem = (lookupName: string, itemId: number | null): ILookupItem => {
    let description: string = "";
    var lookupMissingItem: ILookupItem = {
      id: 0,
      description: description,
      active: false,
    };
    var lookupItem: ILookupItem = lookupMissingItem;
    if (!itemId) return lookupMissingItem;
    let lookup = this.getLookup(lookupName);
    if (lookup) {
      lookupItem =
        lookup.items.find((a) => a.id === itemId) ?? lookupMissingItem;
    }
    return lookupItem;
  };

  loadLookup = async (name: string) => {
    let response: IResponseData = {
      data: null,
      headers: null,
      status: APIResponseStatus.BAD_REQUEST,
    };
    try {
      var lookupNames: string; //ILookupNames = { lookupNames: []};
      //lookupNames.lookupNames.push(name); //Pass multiple names eventually
      lookupNames = name;
      const existingLookup = this.lookups.get(name);
      if (existingLookup) return existingLookup;

      response = await axios.get(
        `${globalStoreInstance.allSetting.baseAPIUrl}${AmsUrl.GET_AMS_LOOKUPS}${lookupNames}`,
        {
          headers: authHeader(),
        }
      );
      if (response.status === APIResponseStatus.SUCCESS && response.data) {
        runInAction(() => {
          const item = response.data[0];
          if (item) {
            if (this.lookups.get(item.name) === undefined) {
              this.lookups.set(item.name, item);
            }
            return this.lookups.get(item.name);
          }
        });
      }
    } catch (error) {
      const err = error as any;
      console.warn("GetLookupData fail ", err);
      response.data = err.response.data;
      response.headers = err.response.headers;
      response.status = err.response.status;
      //return response;
    }
    //return null;
  };

  loadLookups = async (names: string[]) => {
    let response: IResponseData = {
      data: null,
      headers: null,
      status: APIResponseStatus.BAD_REQUEST,
    };
    try {
      let result: ILookup[] = [];
      let notExistedArray: string[] = [];
      names.forEach((item: string) => {
        const existingLookup = this.lookups.get(item);
        if (existingLookup) {
          result.push(toJS(existingLookup));
        } else {
          notExistedArray.push(item);
        }
      });

      const notFoundLookupList: string = notExistedArray.join(","); //ILookupNames = { lookupNames: []};

      if (notFoundLookupList.length > 0) {
        // response = mockLockups(names);
        response = await axios.get(
          `${globalStoreInstance.allSetting.baseAPIUrl}${AmsUrl.GET_AMS_LOOKUPS}${notFoundLookupList}`,
          {
            headers: authHeader(),
          }
        );

        if (response.status === APIResponseStatus.SUCCESS && response.data) {
          runInAction(() => {
            response.data.forEach((item: any) => {
              if (item) {
                this.lookups.set(item.name, item);
                result.push(item);
              }
            });
          });
        }
      }

      return result;
    } catch (error) {
      const err = error as any;
      console.error("GetLookupData fail ", err);
      response.data = err.response.data;
      response.headers = err.response.headers;
      response.status = err.response.status;
      //return response;
    }
    return [];
  };

  loadFakeLookups = () => {
    let lookup: ILookup;

    lookup = this.createLookup(1, "WorkOrderStatus", "Status");
    lookup.items.push(this.createLookupItem(1, "Open"));
    lookup.items.push(this.createLookupItem(2, "In Progress"));
    lookup.items.push(this.createLookupItem(3, "Completed"));
    lookup.items.push(this.createLookupItem(4, "Cancelled"));
    lookup.items.push(this.createLookupItem(5, "Closed"));
    this.lookups.set(lookup.name, lookup);

    lookup = this.createLookup(2, "WorkOrderPriority", "Priority");
    lookup.items.push(this.createLookupItem(1, "High"));
    lookup.items.push(this.createLookupItem(2, "Medium"));
    lookup.items.push(this.createLookupItem(3, "Low"));
    lookup.items.push(this.createLookupItem(4, "None"));
    this.lookups.set(lookup.name, lookup);

    lookup = this.createLookup(3, "Division", "Division");
    lookup.items.push(this.createLookupItem(1, "Roads"));
    lookup.items.push(this.createLookupItem(2, "Parks"));
    lookup.items.push(this.createLookupItem(3, "Admin"));
    lookup.items.push(this.createLookupItem(4, "Facilities"));
    this.lookups.set(lookup.name, lookup);

    lookup = this.createLookup(4, "ActioningOfficer", "Actioning Officer");
    lookup.items.push(this.createLookupItem(1, "Brian Blessed"));
    lookup.items.push(this.createLookupItem(2, "John Doe"));
    lookup.items.push(this.createLookupItem(3, "Karen Smith"));
    this.lookups.set(lookup.name, lookup);

    lookup = this.createLookup(5, "MaintenanceType", "Maintenance Type");
    lookup.items.push(this.createLookupItem(1, "Planned"));
    lookup.items.push(this.createLookupItem(2, "Unplanned"));
    this.lookups.set(lookup.name, lookup);

    lookup = this.createLookup(6, "BudgetCode", "Budget Code");
    lookup.items.push(this.createLookupItem(1, "BB2019"));
    lookup.items.push(this.createLookupItem(2, "BB2020"));
    lookup.items.push(this.createLookupItem(3, "RA2019"));
    lookup.items.push(this.createLookupItem(4, "RA2020"));
    lookup.items.push(this.createLookupItem(5, "ZA2019/1"));
    lookup.items.push(this.createLookupItem(6, "ZA2019/2"));
    lookup.items.push(this.createLookupItem(7, "ZA2020/1"));
    lookup.items.push(this.createLookupItem(8, "ZA2020/2"));
    this.lookups.set(lookup.name, lookup);

    lookup = this.createLookup(7, "Project", "Project");
    lookup.items.push(this.createLookupItem(1, "Roads Maintenance 2019"));
    lookup.items.push(this.createLookupItem(2, "Roads Maintenance 2020"));
    lookup.items.push(
      this.createLookupItem(3, "Buildings Capital Expenditure")
    );
    this.lookups.set(lookup.name, lookup);

    lookup = this.createLookup(8, "WorkOrderTaskType", "Task Type");
    lookup.items.push(this.createLookupItem(1, "Repair"));
    lookup.items.push(this.createLookupItem(2, "Replacement"));
    lookup.items.push(this.createLookupItem(3, "Maintenance"));
    lookup.items.push(this.createLookupItem(4, "Service"));
    this.lookups.set(lookup.name, lookup);

    lookup = this.createLookup(9, "ResourceType", "Resource Type");
    lookup.items.push(this.createLookupItem(1, "Labour"));
    lookup.items.push(this.createLookupItem(2, "Plant"));
    lookup.items.push(this.createLookupItem(3, "Material"));
    this.lookups.set(lookup.name, lookup);

    lookup = this.createLookup(10, "Supplier", "Supplier");
    lookup.items.push(this.createLookupItem(1, "Bills Shop Of Stuff"));
    lookup.items.push(this.createLookupItem(2, "Carls Concrete"));
    lookup.items.push(this.createLookupItem(3, "Igors Metal Suppliers"));
    lookup.items.push(this.createLookupItem(4, "BioTech Chemicals"));
    lookup.items.push(this.createLookupItem(5, "Willys Wood Shop"));
    lookup.items.push(this.createLookupItem(6, "Xtra Stuff"));
    this.lookups.set(lookup.name, lookup);

    lookup = this.createLookup(11, "AssetStatus1", "Status");
    lookup.items.push(this.createLookupItem(1, "Pending"));
    lookup.items.push(this.createLookupItem(2, "Current"));
    lookup.items.push(this.createLookupItem(3, "Historic"));
    this.lookups.set(lookup.name, lookup);

    lookup = this.createLookup(12, "AssetCategory", "Asset Category");
    lookup.items.push(this.createLookupItem(1, "Bridges"));
    lookup.items.push(this.createLookupItem(2, "Roads"));
    lookup.items.push(this.createLookupItem(3, "Fleet"));
    lookup.items.push(this.createLookupItem(4, "Buildings"));
    this.lookups.set(lookup.name, lookup);

    lookup = this.createLookup(13, "Ownerships", "Ownership");
    lookup.items.push(this.createLookupItem(1, "Owned"));
    lookup.items.push(this.createLookupItem(2, "Private"));
    lookup.items.push(this.createLookupItem(3, "Public"));
    this.lookups.set(lookup.name, lookup);

    lookup = this.createLookup(14, "AssetPriorities", "Priority");
    lookup.items.push(this.createLookupItem(0, "N/A"));
    lookup.items.push(this.createLookupItem(1, "High"));
    lookup.items.push(this.createLookupItem(2, "Medium"));
    lookup.items.push(this.createLookupItem(3, "Low"));
    this.lookups.set(lookup.name, lookup);

    lookup = this.createLookup(15, "AssetManagers", "Manager");
    lookup.items.push(this.createLookupItem(1, "Brian Blessed"));
    lookup.items.push(this.createLookupItem(2, "John Doe"));
    lookup.items.push(this.createLookupItem(3, "Karen Smith"));
    this.lookups.set(lookup.name, lookup);
  };

  createLookup = (id: number, name: string, description: string) => {
    let lookup: ILookup = {
      id: id,
      name: name,
      lookupType: "internal",
      items: [],
    };
    return lookup;
  };

  createLookupItem = (id: number, description: string) => {
    let item: ILookupItem = { id: id, description: description, active: true };
    return item;
  };

  getDataLookup = (lookupName: string): ILookup => {
    let lookup: ILookup = {
      id: 0,
      name: "name",
      lookupType: "internal",
      items: [],
    };
    return computed(() => this.getLookup(lookupName)).get() || lookup;
  };

  constructor() {
    makeObservable(this, {
      lookups: observable,
      getLookup: action,
      getAssetLookup: action,
      getAssetLookupItems: action,
      getLookupItems: action,
      getLookupItemDescription: action,
      getLookupItem: action,
      loadLookup: action,
      loadLookups: action,
      loadFakeLookups: action,
      createLookup: action,
      createLookupItem: action,
    });
  }
}
export const assetLookupsStore = new LookupStore();
const assetLookupsStoreContext = createContext(new LookupStore());
export const useAssetLookupsStore = () => {
  return useContext(assetLookupsStoreContext);
};
