import { CoreAPIService } from "@/common/apis/coreAPIService";
import { IMailMergeSelectDocument } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/assessment-report/model";
import { PPRApplicationIdentityPacket } from "@app/products/town-planning/ppr/[id]/model";
import { APIResponse } from "@common/apis/model";

export const putCreateReportLetter = async (
  data: IMailMergeSelectDocument,
  applicationID: number
): Promise<APIResponse<PPRApplicationIdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
      `/api/tp/ppr/internal/application/psar/${applicationID}/report`,
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
