import { ContactMethodPreferred } from "@app/core/contacts/_id/model";
import { generateText } from "@app/products/hm/premises/[id]/util";
import { Contact } from "@common/models/contact";

export const getLeaseeDisplay = (contact: Contact) => {
  let result = "";
  if (
    contact?.Extension?.Organisation &&
    contact?.Extension?.Organisation !== contact?.DisplayName
  ) {
    result = generateText(result, contact?.Extension?.Organisation);
  }
  if (contact?.PreferredMethod_ENUM !== ContactMethodPreferred.None) {
    result = generateText(
      result,
      contact?.PreferredMethod_Formatted ??
        contact?._PreferredMethod_Formatted ??
        ""
    );
  } else {
    result = generateText(result, contact?.WorkPhone ?? "");
  }

  if (contact?.Extension?.SecondLanguage) {
    result = generateText(
      result,
      "Lang: " + contact?.Extension?.SecondLanguage ?? ""
    );
  }
  return result;
};
