import {
  VO_NoticeOfSale_Title_OwnerDetail,
  VO_NoticeOfSale_Title_PreviousOwnerDetail,
  VO_NoticeOfSale_Title_Street_Address,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/detail/components/lot-details/components/lot-details-children/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfStreetAddress =
  nameOfFactory<VO_NoticeOfSale_Title_Street_Address>();
export const colLRSStreetAddress: IColumnFields[] = [
  {
    field: nameOfStreetAddress("Date_Created"),
    title: "Date Created",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfStreetAddress("Building_Name"),
    title: "Building Name",
  },
  {
    field: nameOfStreetAddress("Unit_Type"),
    title: "Unit Type",
  },
  {
    field: nameOfStreetAddress("Unit_Number"),
    title: "Unit Number",
  },
  {
    field: nameOfStreetAddress("Address_Line1"),
    title: "Address Line 1",
  },
  {
    field: nameOfStreetAddress("Address_Line2"),
    title: "Address Line 2",
  },
  {
    field: nameOfStreetAddress("Address_Line3"),
    title: "Address Line 3",
  },
  {
    field: nameOfStreetAddress("Address_Line4"),
    title: "Address Line 4",
  },
  {
    field: nameOfStreetAddress("House_Number1"),
    title: "House Number 1",
  },
  {
    field: nameOfStreetAddress("House_Prefix1"),
    title: "House Prefix 1",
  },
  {
    field: nameOfStreetAddress("House_Suffix1"),
    title: "House Suffix 1",
  },
  {
    field: nameOfStreetAddress("House_Number2"),
    title: "House Number 2",
  },
  {
    field: nameOfStreetAddress("House_Prefix2"),
    title: "House Prefix 2",
  },
  {
    field: nameOfStreetAddress("House_Suffix2"),
    title: "House Suffix 2",
  },
  {
    field: nameOfStreetAddress("Floor_Number"),
    title: "Floor Number",
  },
  {
    field: nameOfStreetAddress("Floor_Type"),
    title: "Floor Type",
  },
  {
    field: nameOfStreetAddress("Floor_Suffix"),
    title: "Floor Suffix",
  },
  {
    field: nameOfStreetAddress("Location_Description"),
    title: "Location Descriptor",
  },
  {
    field: nameOfStreetAddress("Street_Name1"),
    title: "Street Name 1",
  },
  {
    field: nameOfStreetAddress("Street_Type1"),
    title: "Street Type 1",
  },
  {
    field: nameOfStreetAddress("Street_Suffix1"),
    title: "Street Suffix 1",
  },
  {
    field: nameOfStreetAddress("Street_Name2"),
    title: "Street Name 2",
  },
  {
    field: nameOfStreetAddress("Street_Type2"),
    title: "Street Type 2",
  },
  {
    field: nameOfStreetAddress("Street_Suffix2"),
    title: "Street Suffix 2",
  },
  {
    field: nameOfStreetAddress("Suburb"),
    title: "Suburb",
  },
  {
    field: nameOfStreetAddress("Postcode"),
    title: "Postcode",
  },
  {
    field: nameOfStreetAddress("State"),
    title: "State",
  },
];

const nameOfLRSOwnersGroupAndOwnersNames =
  nameOfFactory<VO_NoticeOfSale_Title_OwnerDetail>();
export const colLRSOwnersGroupAndOwnersNames: IColumnFields[] = [
  {
    field: nameOfLRSOwnersGroupAndOwnersNames("Tenancy"),
    title: "Tenancy",
  },
  {
    field: nameOfLRSOwnersGroupAndOwnersNames("Group_Description"),
    title: "Group Description",
  },
  {
    field: nameOfLRSOwnersGroupAndOwnersNames("Share_Numerator"),
    title: "Share Numerator",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfLRSOwnersGroupAndOwnersNames("Share_Denominator"),
    title: "Share Denominator",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfLRSOwnersGroupAndOwnersNames("Name_Type"),
    title: "Name Type",
  },
  {
    field: nameOfLRSOwnersGroupAndOwnersNames("Given_Name"),
    title: "Given Name",
  },
  {
    field: nameOfLRSOwnersGroupAndOwnersNames("Family_Name"),
    title: "Family Name",
  },
  {
    field: nameOfLRSOwnersGroupAndOwnersNames("Corporate_Miscellaneous_Name"),
    title: "Corporate MiscellaneousName",
  },
];

const nameOfLRSPreviousOwners =
  nameOfFactory<VO_NoticeOfSale_Title_PreviousOwnerDetail>();
export const colLRSPreviousOwners: IColumnFields[] = [
  {
    field: nameOfLRSPreviousOwners("Name_Type"),
    title: "Name Type",
  },
  {
    field: nameOfLRSPreviousOwners("Given_Name"),
    title: "Given Name",
  },
  {
    field: nameOfLRSPreviousOwners("Family_Name"),
    title: "Family Name",
  },
  {
    field: nameOfLRSPreviousOwners("Corporate_Miscellaneous_Name"),
    title: "Corporate Miscellaneous Name",
  },
];
