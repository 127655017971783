import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
import { colAddress } from "@app/products/property/assessments/[id]/components/child-screens/general/components/form-element/components/addresses-for-assessment/config";
import { DTO_Address } from "@app/products/property/assessments/[id]/model";

interface IAddressesForAssessment {
  label: string;
  addressData: DTO_Address[];
}

const nameOfAddress = nameOfFactory<DTO_Address>();
export const AddressesForAssessment = ({
  label,
  addressData,
}: IAddressesForAssessment) => {
  return (
    <div className="cc-field cc-address-for-parcel">
      <label className="cc-label">{label}</label>
      <CCGrid
        className="cc-address-for-parcel-grid"
        columnFields={colAddress}
        data={addressData}
        primaryField={nameOfAddress("AddressId")}
      />
    </div>
  );
};
