import { GISReferenceDialog } from "@app/products/property/components/dialogs/gis-reference/_index";
import { colGISReference } from "@app/products/property/components/dialogs/gis-reference/config";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { SwineBrandDialog } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/pic-details/components/dialogs/swine-brands/_index";
import { colSwineBrand } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/pic-details/config";
import {
  EPICDetailGridMode,
  EPicDetailDialog,
  IPicDetailDialogProps,
} from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/pic-details/model";
import { DTO_PIC_Swine } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { picDetailsValidator } from "@app/products/property/pic/list/util";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";

const nameOfGIS = nameOfFactory<DTO_GIS>();
const nameOfSwine = nameOfFactory<DTO_PIC_Swine>();
export const PicDetailsAmalgamateFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? picDetailsValidator : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isFromModifyPIC: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const formErrors = errors?.[nameOf("")]
      ? JSON.parse(errors?.[nameOf("")])
      : undefined;

    //store
    const [showDialog, setShowDialog] = useState<
      IPicDetailDialogProps | undefined
    >();

    //get value fields
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const gis = getFieldValue("GisReferences") ?? [];
    const swineBrand = getFieldValue("Swines") ?? [];

    const selectedGIS = getFieldValue("_option.GISSelected") ?? [];
    const selectedSwineBrand =
      getFieldValue("_option.SwineBrandSelected") ?? [];

    /**
     * handle add item for (GIS Reference and Swine Brand)
     * @param data
     * @param field
     */
    const handleAddItem = (data: any, mode: EPicDetailDialog) => {
      if (mode === EPicDetailDialog.NewGISReference) {
        //get previous data GIS
        const previousGIS = gis?.length ? [...gis] : [];
        //add more temporary ID for new record
        const newGIS = { ...data, Id: `new_${getUUID()}` };
        //merge previous data and new one
        const gisData = [newGIS, ...previousGIS];
        onChange(nameOf("GisReferences"), {
          value: gisData,
        });
      } else if (mode === EPicDetailDialog.NewSwineBrand) {
        //add more temporary ID for new record
        const newSwineBrand = { ...data, Id: `new_${getUUID()}` };
        //get previous data Swine Brand
        const previousSwineBrand = swineBrand?.length ? [...swineBrand] : [];
        //merge previous data and new one
        const swineBrandData = [newSwineBrand, ...previousSwineBrand];
        onChange(nameOf("Swines"), {
          value: swineBrandData,
        });
      }
    };

    /**
     * handle delete item (GIS Reference and Swine Brand)
     * @param field
     */
    const handleDeleteItem = (mode: EPICDetailGridMode) => {
      if (mode === EPICDetailGridMode.GISReference) {
        const gisId = selectedGIS?.[0]?.Id;
        //filter new list GIS without selected record
        const newGIS = gis.filter((item: DTO_GIS) => item.Id !== gisId);
        onChange(nameOf("GisReferences"), {
          value: newGIS,
        });
        onChange(nameOf("_option.GISSelected"), {
          value: [],
        });
      } else if (mode === EPICDetailGridMode.SwineBrand) {
        const swineBrandId = selectedSwineBrand?.[0]?.Id;
        //filter new list SwineBrand without selected record
        const newSwineBrand = swineBrand.filter(
          (item: DTO_PIC_Swine) => item.Id !== swineBrandId
        );
        onChange(nameOf("Swines"), {
          value: newSwineBrand,
        });
        onChange(nameOf("_option.SwineBrandSelected"), {
          value: [],
        });
      }
    };

    /**
     * handle data change grid for (GIS and SwineBrand)
     * using when editing mode (in row)
     * @param mode
     * @param dataRow
     * @param valueChange
     */
    const handleDataChangeGrid = (
      mode: EPICDetailGridMode,
      dataRow: any,
      valueChange: string
    ) => {
      //GIS Reference
      if (mode === EPICDetailGridMode.GISReference) {
        const gisId = dataRow?.Id;
        let newGIS = [...gis];
        // update value Effective_To and Effective_From
        newGIS = newGIS.map((item: DTO_GIS) => {
          if (
            item.Id === gisId &&
            (valueChange === "Effective_To" || valueChange === "Effective_From")
          ) {
            return {
              ...item,
              Effective_To: dataRow["Effective_To"] ?? null,
              Effective_From: dataRow["Effective_From"] ?? null,
            };
          }
          return item;
        });
        onChange(nameOf("GisReferences"), { value: newGIS });
        //Swine Brand
      } else if (mode === EPICDetailGridMode.SwineBrand) {
        const swineId = dataRow?.Id;
        let newSwine = [...swineBrand];
        newSwine = newSwine.map((item: DTO_PIC_Swine) => {
          if (
            item.Id === swineId &&
            (valueChange === "Effective_To" || valueChange === "Effective_From")
          ) {
            // update value Effective_To and Effective_From
            return {
              ...item,
              Effective_To: dataRow["Effective_To"] ?? null,
              Effective_From: dataRow["Effective_From"] ?? null,
            };
          }
          return item;
        });
        onChange(nameOf("Swines"), { value: newSwine });
      }
    };

    const handleConditionValidate = useCallback(
      (conditionValue: boolean) => {
        return !(options?.isReadOnly || conditionValue);
      },
      [options?.isReadOnly]
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field cc-col-span-2">
            {/* Always readOnly */}
            <CCValueField
              label={"Primary holding"}
              value={getFieldValue("_option.Primary_Holding")}
            />
          </div>
          <div className="cc-field">
            {/* Always readOnly */}
            <CCValueField
              label={"Occupier"}
              value={getFieldValue("_option.Occupier")}
            />
          </div>
          <div className="cc-field cc-col-span-2">
            {/* Always readOnly */}
            {/* RLP Assessment (PrimaryHolding) */}
            <CCValueField
              label={"RLP board"}
              value={getFieldValue("_option.RLP_Boards")}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field cc-col-span-2">
            {/* Always readOnly */}
            <CCValueField
              label={"PIC address"}
              value={getFieldValue("_option.PIC_Address")}
            />
          </div>
          <div className="cc-field">
            {/* Always readOnly */}
            <CCValueField
              label={"Trading name"}
              value={getFieldValue("Selected_Trading_Name")}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="PIC number" isMandatory />
            <Field
              name={nameOf("Selected_PIC_Number")}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              component={CCSearchComboBox}
              data={getFieldValue("PIC_Numbers") ?? []}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel
              title="PIC manager"
              isMandatory={getFieldValue("_option.isMandatoryPicManager")}
            />
            <Field
              name={nameOf("Selected_PIC_Manager_Id")}
              component={CCSearchComboBox}
              data={getFieldValue("_option.Managers") ?? []}
              disabled={options?.isReadOnly}
              validator={
                handleConditionValidate(
                  !getFieldValue("_option.isMandatoryPicManager")
                )
                  ? requiredValidator
                  : undefined
              }
              textField="Name"
              dataItemKey="Code"
              isUseDefaultOnchange
            />
          </div>
          <CCValueField
            label={"Type"}
            value={getFieldValue("Selected_PIC_Type_Name")}
          />
          <div className="cc-field">
            <CCLabel
              title="Land use"
              isMandatory={getFieldValue("_option.isMandatoryLandUse")}
            />
            <Field
              name={nameOf("Selected_PIC_Land_Use_Id")}
              component={CCSearchComboBox}
              data={getFieldValue("_option.PIC_Land_Uses") ?? []}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly}
              isUseDefaultOnchange
              validator={
                handleConditionValidate(
                  !getFieldValue("_option.isMandatoryLandUse")
                )
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
          {getFieldValue("Default_PIC_Type_Is_Agent") ? (
            <div className="cc-field">
              <CCLabel
                title="Agent license"
                isMandatory={getFieldValue("_option.isMandatoryAgentLicense")}
              />
              <Field
                name={nameOf("Selected_Agent_License_Number")}
                component={CCSearchComboBox}
                data={getFieldValue("Agent_License_Numbers") ?? []}
                disabled={options?.isReadOnly}
                validator={
                  handleConditionValidate(
                    !getFieldValue("_option.isMandatoryAgentLicense")
                  )
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          ) : null}
          <div className="cc-field">
            <CCLabel
              title="District"
              isMandatory={getFieldValue("_option.isMandatoryDistrict")}
            />
            <Field
              name={nameOf("Selected_District_Id")}
              component={CCSearchComboBox}
              data={getFieldValue("_option.Districts") ?? []}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly}
              isUseDefaultOnchange
              validator={
                handleConditionValidate(
                  !getFieldValue("_option.isMandatoryDistrict")
                )
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel
              title="GIS references"
              errorMessage={formErrors?.GISReferences}
            />
            <CCGrid
              readOnly={options?.isReadOnly}
              toolbar={
                !options?.isReadOnly ? (
                  <div className="cc-grid-tools-bar">
                    <Button
                      type="button"
                      iconClass="fas fa-plus"
                      title="Add"
                      onClick={(event) => {
                        event.preventDefault();
                        setShowDialog({
                          mode: EPicDetailDialog.NewGISReference,
                        });
                      }}
                    />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      title="Remove"
                      onClick={(event) => {
                        event.preventDefault();
                        handleDeleteItem(EPICDetailGridMode.GISReference);
                      }}
                      disabled={selectedGIS?.length !== 1}
                    />
                  </div>
                ) : null
              }
              onDataRowChange={(dataRow: DTO_GIS, fieldName: string) => {
                handleDataChangeGrid(
                  EPICDetailGridMode.GISReference,
                  dataRow,
                  fieldName
                );
              }}
              editableMode={!options?.isReadOnly ? "row" : undefined}
              className="cc-gis-reference-grid-data"
              data={gis ?? []}
              columnFields={colGISReference}
              primaryField={nameOfGIS("Id")}
              selectableMode="single"
              onSelectionChange={(dataItem: DTO_GIS[]) => {
                onChange(nameOf("_option.GISSelected"), {
                  value: dataItem,
                });
              }}
              selectedRows={selectedGIS}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Swine brand" errorMessage={formErrors?.Swines} />
            <CCGrid
              readOnly={options?.isReadOnly}
              toolbar={
                !options?.isReadOnly ? (
                  <div className="cc-grid-tools-bar">
                    <Button
                      type="button"
                      iconClass="fas fa-plus"
                      title="Add"
                      onClick={(event) => {
                        event.preventDefault();
                        setShowDialog({ mode: EPicDetailDialog.NewSwineBrand });
                      }}
                    />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      title="Remove"
                      onClick={(event) => {
                        event.preventDefault();
                        handleDeleteItem(EPICDetailGridMode.SwineBrand);
                      }}
                      disabled={selectedSwineBrand?.length !== 1}
                    />
                  </div>
                ) : null
              }
              editableMode={!options?.isReadOnly ? "row" : undefined}
              data={swineBrand ?? []}
              columnFields={colSwineBrand}
              primaryField={nameOfSwine("Id")}
              selectableMode="single"
              onSelectionChange={(dataItem: DTO_PIC_Swine[]) => {
                onChange(nameOf("_option.SwineBrandSelected"), {
                  value: dataItem,
                });
              }}
              onDataRowChange={(dataRow: DTO_PIC_Swine, fieldName: string) => {
                handleDataChangeGrid(
                  EPICDetailGridMode.SwineBrand,
                  dataRow,
                  fieldName
                );
              }}
              selectedRows={selectedSwineBrand}
            />
          </div>
        </div>
        {showDialog?.mode === EPicDetailDialog.NewGISReference ? (
          <GISReferenceDialog
            onClose={() => {
              setShowDialog(undefined);
            }}
            onSubmit={(data: DTO_GIS) => {
              handleAddItem(data, EPicDetailDialog.NewGISReference);
              setShowDialog(undefined);
            }}
            gisReferenceData={gis ?? []}
            gisTypes={getFieldValue("_option.GISType") ?? []}
          />
        ) : null}
        {showDialog?.mode === EPicDetailDialog.NewSwineBrand ? (
          <SwineBrandDialog
            onClose={() => {
              setShowDialog(undefined);
            }}
            onSubmit={(data: DTO_PIC_Swine) => {
              handleAddItem(data, showDialog?.mode);
              setShowDialog(undefined);
            }}
          />
        ) : null}
      </section>
    );
  }
);
