import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

export const renderList: ECorporateSettingsField[] = [
  ECorporateSettingsField.TRIMAdmin_TP_CustomField1_TRIM,
  ECorporateSettingsField.TRIMAdmin_TP_CustomField1_OO,
  ECorporateSettingsField.TRIMAdmin_TP_CustomField2_TRIM,
  ECorporateSettingsField.TRIMAdmin_TP_CustomField2_OO,
  ECorporateSettingsField.TRIMAdmin_TP_CustomField3_TRIM,
  ECorporateSettingsField.TRIMAdmin_TP_CustomField3_OO,
  ECorporateSettingsField.TRIMAdmin_TP_CustomField4_TRIM,
  ECorporateSettingsField.TRIMAdmin_TP_CustomField4_OO,
  ECorporateSettingsField.TRIMAdmin_TP_CustomField5_TRIM,
  ECorporateSettingsField.TRIMAdmin_TP_CustomField5_OO,
];
