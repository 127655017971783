import { ChangeStatus } from "@app/products/town-planning/ppr/enquiries/components/action-bar/buttons/batch-complete/model";
import { putEnquiryStatus } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/tools/change-status/api";
import { PPREnquiryUpdateStatusDialog } from "@app/products/town-planning/ppr/enquiries/_id/components/dialogs/change-status/_index";
import { RegisterStatus } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const PPREnquiryChangeStatusButton = observer(() => {
  const { pprEnquiryId, setPPREnquiryWithLoading, pprEnquiry } =
    usePPREnquiryStore();
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();

  const [isUpdating, setIsUpdating] = useState(false);
  const [showUpdateStatusDialog, setShowUpdateStatusDialog] = useState(false);

  const handleOnSubmit = async (data?: ChangeStatus) => {
    if (!pprEnquiryId || !data?.RegisterStatus) return;
    setIsUpdating(true);
    const resUpdateStatus = await putEnquiryStatus(pprEnquiryId, data);
    setIsUpdating(false);
    setShowUpdateStatusDialog(false);
    if (isSuccessIdentityPacket(resUpdateStatus)) {
      clearErrorNotification();
      setPPREnquiryWithLoading(resUpdateStatus?.data?.Register);
      pushNotification({
        type: "success",
        title: "Status changed successfully",
      });
    } else {
      pushNotification({
        title: "Change status failed",
        type: "error",
        autoClose: false,
        description: resUpdateStatus.data?.Errors,
      });
    }
  };

  const isVisible = useMemo(() => {
    return (
      pprEnquiry?.Status_ENUM !== RegisterStatus.New &&
      pprEnquiry?.Status_ENUM !== RegisterStatus.NA
    );
  }, [pprEnquiry]);

  return isVisible ? (
    <>
      <CCNavButton
        title="Change status"
        onClick={() => setShowUpdateStatusDialog(true)}
      />
      {showUpdateStatusDialog && (
        <PPREnquiryUpdateStatusDialog
          isUpdating={isUpdating}
          onClose={() => setShowUpdateStatusDialog(false)}
          onSubmit={handleOnSubmit}
        />
      )}
    </>
  ) : null;
});
