export const mockGender = [
  {
    Key: 0,
    Value: "F",
  },
  {
    Key: 1,
    Value: "M",
  },
];
