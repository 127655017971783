import { useIsNew } from "@common/hooks/useIsNew";
import { CheckPermissionSettingWrapper } from "@common/pages/settings/components/check-permission-wrapper/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { ExistedSecurityOrgStructure } from "@common/pages/settings/security/org-structure/_id/components/forms/existed/_index";
import { NewSecurityOrgStructure } from "@common/pages/settings/security/org-structure/_id/components/forms/new/_index";
import { ISecurityOrgStructureNotification } from "@common/pages/settings/security/org-structure/_id/model";
import { useOrgStructureStore } from "@common/pages/settings/security/org-structure/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageSecurityOrgStructure = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const location = useLocation();
  const orgUnitId = parseInt(params.id);
  const { resetStore, loadOrgUnit, setOrgStructureNotification } =
    useOrgStructureStore();
  const { resetMenu } = useCCSubActionBarStore();

  const [isHasPermission, setIsHasPermission] = useState<boolean>(false);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  useEffect(() => {
    if (location.state) {
      setOrgStructureNotification(
        location.state as ISecurityOrgStructureNotification
      );
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line
  }, [isNew]);

  useEffect(() => {
    if (isHasPermission) {
      loadOrgUnit(orgUnitId, isNew);
    }
  }, [orgUnitId, isNew, loadOrgUnit, isHasPermission]);

  return (
    <CheckPermissionSettingWrapper
      formAction={FormActionCheckPermission.SECURITY_MENU}
      setIsHasPermission={setIsHasPermission}
    >
      {isNew ? <NewSecurityOrgStructure /> : <ExistedSecurityOrgStructure />}
    </CheckPermissionSettingWrapper>
  );
};

export default observer(ManageSecurityOrgStructure);
