import { ChecklistQuestion } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/model";
import { BELL_CHARACTER } from "@common/constants/characters";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useIsNew } from "@common/hooks/useIsNew";
import {
  COMMENT_FORM_STEP,
  CommentStep,
} from "@common/pages/settings/lookups/dynamic-lists/_id/components/child-screens/general/components/dialog/dynamic-question-dialog/comment/_index";
import {
  HINT_FORM_STEP,
  HintStep,
} from "@common/pages/settings/lookups/dynamic-lists/_id/components/child-screens/general/components/dialog/dynamic-question-dialog/hint/_index";
import {
  QUESTION_FORM_STEP,
  QuestionStep,
} from "@common/pages/settings/lookups/dynamic-lists/_id/components/child-screens/general/components/dialog/dynamic-question-dialog/question/_index";
import { useDynamicQuestionListsStore } from "@common/pages/settings/lookups/dynamic-lists/_id/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";
import { useEffectOnce } from "react-use";

export interface ICheckListQuestionDialogProps {
  dynamicQuestionId: number;
  checkListQuestionId: number;
  onClose: () => void;
}
export const CheckListQuestionDialog = observer(
  (props: ICheckListQuestionDialogProps) => {
    const { onClose, dynamicQuestionId, checkListQuestionId } = props;
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const {
      responseLoadErrorCheckList,
      isLoadingCheckListQuestionDialog,
      isFirstLoadingCheckListQuestionDialog,
      checkListQuestion,
      resetStore,
      loadCheckListQuestion,
      saveCheckListQuestion,
      dynamicQuestionListsId,
      dynamicQuestionLists,
    } = useDynamicQuestionListsStore();

    const isNew = useIsNew();

    const isDisabled =
      isLoadingCheckListQuestionDialog || isFirstLoadingCheckListQuestionDialog;

    const steps: IStep[] = [
      {
        label: "Question",
        component: QuestionStep,
        visible: true,
        key: QUESTION_FORM_STEP,
        options: {
          isDisabled,
        },
      },
      {
        label: "Comment",
        component: CommentStep,
        visible: true,
        key: COMMENT_FORM_STEP,
        options: {
          isDisabled,
        },
      },
      {
        label: "Hint",
        component: HintStep,
        visible: true,
        key: HINT_FORM_STEP,
        options: {
          isDisabled,
        },
      },
    ];

    const handleOnSubmit = (checklistQuestion: ChecklistQuestion) => {
      let newChecklistQuestion = JSON.parse(JSON.stringify(checklistQuestion));
      delete newChecklistQuestion[HINT_FORM_STEP];

      const answerField = Object.values(
        newChecklistQuestion[QUESTION_FORM_STEP] ?? {}
      ).join(BELL_CHARACTER);

      const commentLabels = Object.values(
        newChecklistQuestion[COMMENT_FORM_STEP] ?? {}
      ).join(BELL_CHARACTER);

      newChecklistQuestion = {
        ...newChecklistQuestion,
        AnswerField: answerField,
        CommentLabels: commentLabels,
        DynamicQuestionList_ID: dynamicQuestionListsId,
        DynamicQuestionList_Name:
          dynamicQuestionLists?.DynamicQuestionList_Name,
        ProductType_ENUM: PRODUCT_TYPE_NUMBER.Core,
      };

      delete newChecklistQuestion[QUESTION_FORM_STEP];
      delete newChecklistQuestion[COMMENT_FORM_STEP];
      saveCheckListQuestion(
        newChecklistQuestion,
        isNew,
        notificationFormStepRef.current?.getNotificationFormStep()
      );
    };

    useEffectOnce(() => {
      loadCheckListQuestion(checkListQuestionId, dynamicQuestionId);
      return () => {
        resetStore(true);
      };
    });

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        onSubmit={handleOnSubmit}
        initialSteps={steps}
        key={JSON.stringify(checkListQuestion)}
        initialValues={checkListQuestion}
        renderForm={(renderProps: ICCFormStepRender) =>
          checkListQuestion && (
            <CCDialog
              maxWidth="60%"
              maxHeight="70%"
              titleHeader={"Dynamic Question"}
              onClose={onClose}
              bodyElement={
                responseLoadErrorCheckList ? (
                  <CCLoadFailed
                    responseError={responseLoadErrorCheckList}
                    onReload={() => {}}
                  />
                ) : (
                  renderProps.children
                )
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    className={"cc-dialog-button"}
                    onClick={onClose}
                    disabled={
                      isLoadingCheckListQuestionDialog ||
                      isFirstLoadingCheckListQuestionDialog
                    }
                  >
                    Cancel
                  </Button>
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                      disabled={
                        isLoadingCheckListQuestionDialog ||
                        isFirstLoadingCheckListQuestionDialog
                      }
                    >
                      Previous
                    </Button>
                  )}
                  <Button
                    themeColor="primary"
                    id={renderProps.nextButton.idButton}
                    disabled={
                      renderProps.nextButton.disabled ||
                      isLoadingCheckListQuestionDialog ||
                      isFirstLoadingCheckListQuestionDialog
                    }
                    className={"cc-dialog-button"}
                    iconClass={
                      isLoadingCheckListQuestionDialog
                        ? "fas fa-spinner fa-spin"
                        : ""
                    }
                    onClick={renderProps.nextButton.onClick}
                  >
                    {renderProps.isLastStep
                      ? "Save"
                      : renderProps.nextButton.label}
                  </Button>
                </div>
              }
            />
          )
        }
      />
    );
  }
);
