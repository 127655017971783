import { ENoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/model";
import { JournalMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/model";
import { VO_JournalList } from "@app/products/property/journals/list/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfJournal = nameOfFactory<VO_JournalList>();

export const colJournals: IColumnFields[] = [
  { field: nameOfJournal("Description"), title: "Journal" },
  { field: nameOfJournal("Journal_Type_Name"), title: "Journal Type" },
];

export const dataWhichJournal = [
  { label: "New journal", value: JournalMode.CreateNew },
  {
    label: "Existing journal created by me",
    value: JournalMode.SelectFromMyList,
  },
  {
    label: "Existing journal",
    value: JournalMode.SelectFromList,
  },
];

export const listTypeTurnOnSwitch = [
  ENoticeType.NOTICES_FOR_JOURNALS_OF_ASSESSMENT_CHARGES,
  ENoticeType.RATE_NOTICE_FROM_CHARGE_RUN_AND_JOURNALS,
];

export const getJournalOdata = (isMyList: boolean) => {
  return `odata/property/internal/journalregister/GetByCloudUser(mylist=${isMyList})?$count=true&`;
};
