import { loadEntitiesWithMultipleExemptionsById } from "@app/products/property/assessments/compliance/entities-with-multiple-exemptions/api";
import { IEntitiesWithMultipleExemptions } from "@app/products/property/assessments/compliance/entities-with-multiple-exemptions/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const EntitiesWithMultipleExemptionsDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [
    entitiesWithMultipleExemptionsInfo,
    setEntitiesWithMultipleExemptionsInfo,
  ] = useState<IEntitiesWithMultipleExemptions | undefined>(undefined);
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const assessmentId = params.id;
  useEffect(() => {
    if (lastSelectedRow?.AssessmentId || assessmentId) {
      const id = lastSelectedRow?.AssessmentId || assessmentId;
      setIsLoading(true);
      loadEntitiesWithMultipleExemptionsById(id + "").then((data) => {
        setEntitiesWithMultipleExemptionsInfo(data);
        setIsLoading(false);
      });
    }
    setIsLoading(false);
  }, [lastSelectedRow, assessmentId]);

  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem expanded={true} title={""}>
          <>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Assessment (S-S-B-U):"}
                value={entitiesWithMultipleExemptionsInfo?.Assessment}
              />
              <ProductReferenceRow
                title={"Exemption Type:"}
                value={entitiesWithMultipleExemptionsInfo?.ExemptionType}
              />
              <ProductReferenceRow
                title={"Active From:"}
                value={formatDateByKendo(
                  entitiesWithMultipleExemptionsInfo?.ActiveFrom,
                  DATE_FORMAT.DATE
                )}
              />
              <ProductReferenceRow
                title={"Active To:"}
                value={formatDateByKendo(
                  entitiesWithMultipleExemptionsInfo?.ActiveTo,
                  DATE_FORMAT.DATE
                )}
              />
              <ProductReferenceRow
                title={"Created By:"}
                value={entitiesWithMultipleExemptionsInfo?.CreatedBy}
              />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
