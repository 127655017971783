import { DTO_Supplementary_Assessment } from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const getSupplementaryRatesAssessmentDetail = async (
  supplementaryId: number
): Promise<DTO_Supplementary_Assessment | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Supplementary_Assessment>(
        `/api/property/internal/Supplementary/assessmentschild/${supplementaryId}`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
