import { ICCRoute } from "@common/constants/ICCRoute";

export const integratedReportsRoute: ICCRoute = {
  path: "integrated-reports",
  name: "Integrated Reports",
  children: [
    {
      path: "custom-reports",
      name: "Custom Reports",
      component: require("./custom-reports/_index").default,
    },
  ],
};
