import { ChatSidebar } from "@app/core/chat/ask-ai/reference-sidebar/_index";
import { useAskAIServiceStore } from "@app/core/chat/ask-ai/store";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { CrmsActionBarNavDropdown } from "@app/products/crms/components/action-bar/nav-dropdown/_index";
import { crmsServiceStandardListViewURL } from "@app/products/crms/inspections/util";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { crmsRoute } from "@app/products/crms/route";
import { CRMSServiceStandardsDetailsTab } from "@app/products/crms/service-standards/[id]/components/forms/components/reference-sidebar/details/_index";
import { CRMS_SERVICE_STANDARDS_ROUTE } from "@app/products/crms/service-standards/[id]/constant";
import { colByName } from "@app/products/crms/service-standards/by-name/config";
import { CRMSServiceStandardsByNameBookmark } from "@app/products/crms/service-standards/by-name/util";
import { SvcStandardBatchUpdateButton } from "@app/products/crms/service-standards/components/action-bar/buttons/batch-update/_index";
import { CloneNewButton } from "@app/products/crms/service-standards/components/action-bar/buttons/clone-new/_index";
import { NewButton } from "@app/products/crms/service-standards/components/action-bar/buttons/new/_index";
import { PublishToWebButton } from "@app/products/crms/service-standards/components/action-bar/buttons/publish-to-web/_index";
import { generateServiceStandardViewsBySetting } from "@app/products/crms/service-standards/config";
import { ServiceStandardView } from "@app/products/crms/service-standards/model";
import { serviceStandardsRoute } from "@app/products/crms/service-standards/route";
import { CRMSServiceStandardsBookmark } from "@app/products/crms/service-standards/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<ServiceStandardView>();
const gridId = getUUID();

export default observer(() => {
  const { settings } = useCommonCoreStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_ServiceStandard,
    productType: PRODUCT_TYPE_NUMBER.CustomerService,
  });

  useCCListViewActionBar({
    title: crmsRoute.name,
    leftComponents: [
      <CrmsActionBarNavDropdown category={serviceStandardsRoute.path} />,
    ],
    centerComponents: [
      <NewButton isDisabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)} />,
      <CloneNewButton
        isDisabled={!checkPermissions(FormAction.CRMS_CLONE_NEW)}
      />,
      <DeleteButton
        recordType={RECORDTYPE.CUSTOMERSERVICE_ServiceStandard}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
        refreshGridIds={[gridId]}
        gridId={gridId}
      />,
      <CCNavButton title="Tools" type="more">
        <SvcStandardBatchUpdateButton
          isDisabled={!checkPermissions(FormAction.CRMS_TOOLS_BATCHUPDATE)}
          gridId={gridId}
        />
        <PublishToWebButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CRMS_SERVICE_STANDARDS_ROUTE}
        productType={PRODUCT_TYPE.CustomerService}
        recordType={RECORDTYPE.CUSTOMERSERVICE_ServiceStandard}
        detail={CRMSServiceStandardsBookmark.getBookmarkDetail}
        displayName={CRMSServiceStandardsBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          CRMSServiceStandardsByNameBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={
          CRMSServiceStandardsByNameBookmark.getBookmarkListViewDetail
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CRMSServiceStandardsDetailsTab /> },
      {
        title: "Ask AI",
        isVisible: useAskAIServiceStore().isShowAskAI,
        component: (
          <ChatSidebar productId={PRODUCT_TYPE_NUMBER.CustomerService} />
        ),
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        gridId={gridId}
        columnFields={generateServiceStandardViewsBySetting({
          settings,
          defaultConfig: colByName,
        })}
        primaryField={nameOf("ID")}
        dataUrl={crmsServiceStandardListViewURL(
          FormAction.CUSTOMERSERVICE_MENU_VIEW_SERVICESTANDARDBYNAME
        )}
        state={{ sort: [{ field: nameOf("FullName"), dir: "asc" }] }}
      />
    </LoadingPermissionWrapper>
  );
});
