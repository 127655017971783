import { DTO_Entity_Details } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { RolePICManagerCustomEditCell } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/contacts/components/role-custom-edit-cell/_index";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Entity_Details>();
export const colNameContactStep: IColumnFields[] = [
  { field: nameOf("Name"), title: "Name" },
  {
    field: nameOf("Entity_Id"),
    title: "Contact ID",
    format: NUMBER_FORMAT.NUMBER2,
    href: (dataItem: DTO_Entity_Details) => ({
      href: `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem.Entity_Id}`,
      target: "_blank",
    }),
  },
  {
    field: nameOf("Role_Name"),
    title: "Role",
    editCell: RolePICManagerCustomEditCell,
    editable: true,
    width: 150,
  },
  { field: nameOf("Address"), title: "Address" },
  {
    field: nameOf("Entity_Name_Address_Id"),
    title: "Contact Name/Address ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
