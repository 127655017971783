import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import {
  ElementDisplayStatus,
  IDataStateURLToNewEventPage,
} from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { CloneNewEventDialog } from "@app/products/crms/components/dialogs/clone-new-event/_index";
import { setUpCopyNewEvent } from "@app/products/crms/components/dialogs/clone-new-event/api";
import {
  CS_RequestSection,
  KeyValuePacketWithStatus,
} from "@app/products/crms/components/dialogs/clone-new-event/model";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { FormSubmitClickEvent } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export const NewEventWithSameIssueButton = observer(() => {
  const [showNewEventWithSameIssueDialog, setShowNewEventWithSameIssueDialog] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initData, setInitData] = useState({});
  const [optionList, setOptionList] = useState<KeyValuePacketWithStatus[]>();
  const [optionListEnable, setOptionListEnable] =
    useState<KeyValuePacketWithStatus[]>();

  const { gridSelectedRows, lastSelectedId } = useCCProductListViewStore();
  const history = useHistory();
  const { pushNotification } = useCCAppNotificationStore();
  const { setIsNewEventWithSameDetailOrIssue } = useCRMSEventStore();

  const setUpCloneNewEvent = async () => {
    setIsLoading(true);
    const response = await setUpCopyNewEvent(lastSelectedId, true);

    if (isSuccessResponse(response) && response.data) {
      const initFormData = response.data.ReturnObj?.reduce(
        (result, item: KeyValuePacketWithStatus) => {
          if (item.DisplayStatus !== ElementDisplayStatus.Hidden)
            return { ...result, [item.Key.toString()]: item.Checked };
          return { ...result };
        },
        {}
      );
      setInitData(initFormData);

      setOptionListEnable(
        response.data.ReturnObj.filter(
          (item: KeyValuePacketWithStatus) =>
            item.DisplayStatus === ElementDisplayStatus.Visible
        )
      );

      setOptionList(
        response.data.ReturnObj.filter(
          (item: KeyValuePacketWithStatus) =>
            item.DisplayStatus !== ElementDisplayStatus.Hidden
        )
      );
      setShowNewEventWithSameIssueDialog(true);
    } else {
      if (response?.data?.Errors && response?.data?.Errors?.length > 0) {
        pushNotification({
          title: response.data?.Errors[0],
          type: "warning",
          autoClose: false,
        });
      } else {
        pushNotification({
          type: "error",
          title: response?.error ?? "Set new event with same issue fail.",
          autoClose: false,
        });
      }
    }
    setIsLoading(false);
  };

  const handleSubmit = (event: FormSubmitClickEvent) => {
    const options = event?.values;
    const optionKeys = Object.keys(event?.values);
    const optionsChecked = optionKeys.reduce(
      (optionsCheckedReduce: number[], optionKey) => {
        if (
          options[optionKey] === true &&
          parseInt(optionKey) in CS_RequestSection
        ) {
          optionsCheckedReduce.push(parseInt(optionKey));
        }
        return optionsCheckedReduce;
      },
      []
    );

    setIsNewEventWithSameDetailOrIssue(true);
    history.push(`${CRMS_ROUTE}/new`, {
      cloneNewEventState: {
        id: lastSelectedId ?? 0,
        isClone: true,
        detailsToCopy: optionsChecked,
        sameIssue: true,
      },
    } as IDataStateURLToNewEventPage);
  };

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />

      <CCNavButton
        title="New Event with Same Issue"
        onClick={() => setUpCloneNewEvent()}
        disabled={gridSelectedRows.length !== 1}
      />

      {showNewEventWithSameIssueDialog && (
        <CloneNewEventDialog
          initData={initData}
          onClosePopup={() => setShowNewEventWithSameIssueDialog(false)}
          handleSubmit={handleSubmit}
          optionListEnable={optionListEnable}
          optionList={optionList}
        />
      )}
    </>
  );
});
