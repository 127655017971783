import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { getPowerBIReportList, getWorkspaceId } from "@common/pages/report/powerbi/list/api";
import { IReportPowerBI } from "@common/pages/report/powerbi/list/model";
import { isUndefined } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class PowerBIReportsListStore {
  private _reports?: IReportPowerBI[] = undefined;
  private _workspaceId: string | undefined = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get reports() {
    return this._reports;
  }
  setReports = (reports?: IReportPowerBI[]) => {
    runInAction(() => {
      this._reports = reports;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get workspaceId() {
    return this._workspaceId;
  }
  setWorkspaceId = (workspaceId: string) => {
    runInAction(() => {
      this._workspaceId = workspaceId;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }

  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  resetStorePowerBIConfigure = () => {
    runInAction(() => {
      this._isLoading = false;
      this._reports = undefined;
      this._responseLoadError = undefined;
    });
  };

  loadReports = async (): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    const responseGetWorkspaceID = await getWorkspaceId();
    if (isSuccessResponse(responseGetWorkspaceID)) {
      this.setWorkspaceId(responseGetWorkspaceID?.data ?? []);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Get workspace id failed",
      };
    }
    const response = await getPowerBIReportList();
    if (isSuccessResponse(response)) {
      this.setReports(response?.data?.value ?? []);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Get reports failed",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return isUndefined(errorResponse);
  };
}

export const powerBIReportsListStore = new PowerBIReportsListStore();
const PowerBIReportsListStoreContext = createContext(powerBIReportsListStore);
export const usePowerBIReportsListStore = () => {
  return useContext(PowerBIReportsListStoreContext);
};
