import { eventEmitter } from "@/App";
import { isSuccessResponse } from "@common/apis/util";
import { getRefreshAllSharing } from "@common/pages/report/integrated-reports/component/buttons/refresh-all-sharing/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const RefreshAllSharingButton = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  const handleOnClickRefresh = async () => {
    setIsLoadingButton(true);
    const response = await getRefreshAllSharing();
    const defaultSuccessMessage = "Refreshed All Sharing successfully";
    const defaultFailedMessage = "Refresh All Sharing failed";
    if (isSuccessResponse(response)) {
      setIsLoadingButton(false);
      pushNotification({
        title: response?.data ?? defaultSuccessMessage,
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
    } else {
      setIsLoadingButton(false);
      pushNotification({
        autoClose: false,
        title: response?.error ?? defaultFailedMessage,
        type: "error",
      });
    }
  };
  return (
    <CCNavButton
      title="Refresh all sharing"
      onClick={handleOnClickRefresh}
      isLoading={isLoadingButton}
    />
  );
});
