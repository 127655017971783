import { DTO_Parcel } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Parcel>();
export const colParcels: IColumnFields[] = [
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("ParcelReference"),
    title: "Parcel Reference",
  },
  {
    field: nameOf("AssociationFactorPercentage"),
    title: "Association Factor",
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOf("LandArea"),
    title: "Land Area",
  },
  {
    field: nameOf("Parcel_Id"),
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
