import { mockKnowledgeBaseDetailsTab } from "@app/products/crms/knowledge-base/[id]/components/forms/components/reference-sidebar/details/mock";
import { ICRMSKnowledgeBaseDetailsTab } from "@app/products/crms/knowledge-base/[id]/components/forms/components/reference-sidebar/details/model";
import { mockCRMS } from "@app/products/crms/mock";
import { sleep } from "@common/utils/common";

export const getCRMSKnowledgeBaseDetailsTabById = async (id: number) => {
  await sleep(1000);
  const crmsKnowledgeBase = mockCRMS.find((x) => x.ID === id);
  return {
    ...mockKnowledgeBaseDetailsTab,
    Status: crmsKnowledgeBase?.Status,
    NextReview: crmsKnowledgeBase?.NextReviewDate,
    Expiry: crmsKnowledgeBase?.ExpiryDate,
    HitCount: crmsKnowledgeBase?.HitCount,
  } as ICRMSKnowledgeBaseDetailsTab;
};
