import { CCPanel, ICCPanelProps } from "@components/cc-panel/_index";
import React, { useCallback, useState } from "react";
import "./_index.scss";

export interface ICCPanelsBarProps {
  initialPanels: ICCPanelProps[];
  mode?: "single" | "multiple";
  className?: string;
  //For custom control
  expandedState?: boolean[];
  onChangeExpandedState?: (
    expandedState: boolean[],
    currentIndex?: number
  ) => void;
}
export const CCPanelsBar = ({
  initialPanels,
  mode = "single",
  className,
  expandedState: expandedStateProp,
  onChangeExpandedState,
}: ICCPanelsBarProps) => {
  // TODO: check impact later
  // const [panels, setPanels] = useState(initialPanels);
  const [expandedState, setExpandedState] = useState<boolean[]>(
    initialPanels.map((p) => p.isExpanded ?? false)
  );

  const handleOnChange = useCallback(
    (index: number) => {
      let newExpandedState: boolean[] = [];
      const newState = !expandedState[index];
      if (mode === "multiple") {
        newExpandedState = [...expandedState];
        newExpandedState[index] = newState;
      } else {
        newExpandedState = expandedState.map((_oldValue, order) =>
          order === index ? newState : false
        );
      }
      if (onChangeExpandedState) onChangeExpandedState(newExpandedState, index);
      setExpandedState(newExpandedState);
    },
    [expandedState, mode, onChangeExpandedState]
  );

  // TODO: check impact later
  // useEffect(() => {
  //   if (!isEqual(initialPanels, panels)) setPanels(initialPanels);
  //   // eslint-disable-next-line
  // }, [initialPanels]);

  return (
    <div className={`${className ? `${className} ` : ``}cc-panels-bar`}>
      {initialPanels?.map((panel, index) => (
        <CCPanel
          {...panel}
          isExpanded={
            expandedStateProp ? expandedStateProp[index] : expandedState[index]
          }
          onToggle={() => handleOnChange(index)}
          key={index}
        />
      ))}
    </div>
  );
};
