import { useContactStore } from "@app/core/contacts/_id/store";
import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const ContactHistoryTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { contact } = useContactStore();
  const id = lastSelectedRow?.Id ?? contact?.Contact_Id;

  return (
    <Journal
      id={id}
      triggersReload={contact}
      recordType={RECORDTYPE.CORE_Contact}
    />
  );
});
