import WastewaterFormElement from "@app/products/waste-water/[id]/components/child-screen/general/components/form-element/_index";
import { convertFormDataToPutData } from "@app/products/waste-water/[id]/components/child-screen/general/util";
import { useWasteWaterStore } from "@app/products/waste-water/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";


export const WastewaterForm = observer(() => {
  const { wastewater, createWasteWater, updateWasteWater } =
    useWasteWaterStore();
  const isNew = useIsNew();

  const handleSubmit = (data: any) => {
    if (isNew) {
      createWasteWater(data);
    } else {
      updateWasteWater(convertFormDataToPutData(data));
    }
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        key={JSON.stringify(wastewater)}
        initialValues={wastewater}
        render={(formRenderProps: FormRenderProps) => (
          <WastewaterFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
