import { useStores } from "@app/products/ams/api/helpers/use-stores";
import { IEditingQuestion } from "@app/products/ams/api/ui/inspections-assessments-models/store";
import {
  IAssessmentGroup,
  IAssessmentModel,
  IAssessmentQuestion,
  responseCode,
} from "@app/products/ams/model/store";
import { ItemTypes } from "@app/products/ams/util/itemTypes";
import { useObserver } from "mobx-react-lite";
import React, { Fragment, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import InspectionQuestionDataEntry from "./InspectionQuestionDataEntry";
import InspectionQuestionEditor from "./InspectionQuestionEditor";

interface IProps {
  questionCfg: IAssessmentQuestion;
  parentGroup: IAssessmentGroup;
  parentModel: IAssessmentModel;
}

interface radioButtonDataItem {
  label: string;
  value: responseCode;
}
const InspectionAssessmentRow: React.FC<IProps> = ({
  questionCfg,
  parentGroup,
  parentModel,
}) => {
  const {
    uiStores: { inspectionAssessmentUi },
  } = useStores();

  const edQuestion: IEditingQuestion = {
    groupName: parentGroup.groupHeading,
    questionNo: questionCfg.questionNo,
  };

  const data: radioButtonDataItem[] = [];
  questionCfg.allowedResponses.forEach((r) => {
    data.push({ label: r, value: r });
  });

  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: ItemTypes.EntityQuestionLayoutRow,
    hover(draggingItem: any, monitor) {
      if (!ref.current) {
        return;
      }

      if (draggingItem.parentGroup !== parentGroup) {
        return;
      }

      const dragIndex = draggingItem.questionNo;
      const hoverIndex = questionCfg.questionNo;

      if (dragIndex === hoverIndex) {
        return;
      }

      if (null !== ref.current) {
        const hoveredRect = ref.current.getBoundingClientRect();
        const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
        const mousePosition = monitor.getClientOffset();

        if (null !== mousePosition) {
          const hoverClientY = mousePosition.y - hoveredRect.top;

          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
          }

          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
          }

          parentModel.moveQuestion(
            parentGroup.groupHeading,
            dragIndex,
            hoverIndex
          );
          draggingItem.questionNo = hoverIndex;
        }
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.EntityQuestionLayoutRow,
      questionNo: questionCfg.questionNo,
      parentGroup: parentGroup,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return useObserver(() => {
    const isInDesignMode = inspectionAssessmentUi.isInDesignMode(edQuestion);
    return (
      <Fragment>
        <div
          ref={ref}
          className={`py-1 ${
            inspectionAssessmentUi.isAlternateQuestion(parentGroup, questionCfg)
              ? "bg-secondary"
              : ""
          }`}
        >
          <button
            type="button"
            className="link-button mr-1 mt-1 float-right"
            onClick={() => {
              inspectionAssessmentUi.toggleEditingQuestion(edQuestion);
            }}
          >
            <i
              className={
                isInDesignMode ? "fa fa-check-circle" : "fas fa-pencil-alt"
              }
            />
          </button>

          {isInDesignMode ? (
            <InspectionQuestionEditor
              questionCfg={questionCfg}
              isDragging={isDragging}
              parentGroup={parentGroup}
            />
          ) : (
            <InspectionQuestionDataEntry
              questionCfg={questionCfg}
              isDragging={isDragging}
              parentGroup={parentGroup}
            />
          )}
        </div>
      </Fragment>
    );
  });
};

export default InspectionAssessmentRow;
