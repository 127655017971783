import { getTitleLovs } from "@app/core/comments/api";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DTO_LOV } from "@common/models/odataResponse";
import { customLogger } from "@common/utils/logger";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class CommentDialogStore {
  private _isLoadingOnDialog: boolean = false;
  private _titleLovs: DTO_LOV[] = [];
  private _responseLoadError?: APIResponseError = undefined;
  private _isShowCommentDialog: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoadingOnDialog(): boolean {
    return this._isLoadingOnDialog;
  }
  setIsLoadingOnDialog = (isLoadingOnDialog: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialog = isLoadingOnDialog;
    });
  };

  get titleLovs(): DTO_LOV[] {
    return this._titleLovs;
  }
  setTitleLovs = (titleLovs: DTO_LOV[]) => {
    runInAction(() => {
      this._titleLovs = titleLovs;
    });
  };

  get isShowCommentDialog(): boolean {
    return this._isShowCommentDialog;
  }
  setIsShowCommentDialog = (isShowCommentDialog: boolean) => {
    runInAction(() => {
      this._isShowCommentDialog = isShowCommentDialog;
    });
  };

  loadTitleLovs = async (recordType: RECORDTYPE) => {
    this.setIsLoadingOnDialog(true);
    let errorResponse = undefined;
    const response = await getTitleLovs(recordType);
    customLogger("Core comment, get comment categories api").info(
      response?.data
    );
    if (isSuccessResponse(response)) {
      this.setTitleLovs(response?.data ?? []);
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoadingOnDialog(false);
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._titleLovs = [];
      this._isLoadingOnDialog = false;
      this._responseLoadError = undefined;
      this._isShowCommentDialog = false;
    });
  };
}

export const commentStore = new CommentDialogStore();
const commentDialogStoreContext = createContext(commentStore);
export const useCommentDialogStore = () => {
  return useContext(commentDialogStoreContext);
};
