import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<CoreKeyword>();

export const colKeyWordsPicker: IColumnFields[] = [
  { title: "Name", field: nameOf("Name") },
  { title: "Description", field: nameOf("Description") },
];

export const keyWordsPickerConfigMap: {
  [key in KEYWORD_TYPE]?: {
    title: string;
    selectableMode?: "single" | "multiple";
    textField?: string;
    textProduce?: (value: any) => string | undefined;
    valueField?: string;
    valueProduce?: (value: any) => any | undefined;
    width?: string;
    minWidth?: number | string;
  };
} = {
  [KEYWORD_TYPE.TP_ApplicationRating]: {
    title: "Rating",
    selectableMode: "single",
  },
  [KEYWORD_TYPE.Core_ReferralTrigger]: {
    title: "Referral Trigger",
    selectableMode: "single",
  },
  [KEYWORD_TYPE.Core_Region]: {
    title: "Region",
    selectableMode: "single",
  },
  [KEYWORD_TYPE.Core_ReasonForPermit]: {
    title: "Reason for Permit",
    selectableMode: "multiple",
    valueProduce: (data: CoreKeyword[]) =>
      data
        .reduce((pre, cur) => pre + ", " + cur.Name, "")
        .slice(1)
        .trim(),
  },
  [KEYWORD_TYPE.Core_ContactRole]: {
    title: "Contact Role",
    selectableMode: "multiple",
    textProduce: (data: CoreKeyword[]) =>
      data
        .reduce((pre, cur) => pre + ", " + cur.Name, "")
        .slice(1)
        .trim(),
  },
  [KEYWORD_TYPE.Core_FIDays]: {
    title: "Further Information Days",
    selectableMode: "single",
    valueProduce: (data: CoreKeyword) => parseInt(data.Name),
  },
  [KEYWORD_TYPE.TP_AssessmentPlanner_Zone]: {
    title: "Zone",
    width: "57%",
    selectableMode: "multiple",
  },
  [KEYWORD_TYPE.TP_AssessmentPlanner_Overlay]: {
    title: "Overlay",
    width: "57%",
    selectableMode: "multiple",
  },
  [KEYWORD_TYPE.Core_RegisterPlanningEnquiryCategory]: {
    title: "Planning Permit Referral Enquiry Category",
    selectableMode: "single",
  },
  [KEYWORD_TYPE.Core_ReferralInfoAttached]: {
    title: "Information Attached",
    selectableMode: "multiple",
    textProduce: (data: IKeyValuePacket[]) =>
      data
        .reduce((pre, cur) => pre + ", " + cur.Value, "")
        .slice(1)
        .trim(),
  },
  [KEYWORD_TYPE.Core_ReferralPolicy]: {
    title: "Policy",
    selectableMode: "multiple",
    minWidth: 578,
    textProduce: (data: IKeyValuePacket[]) =>
      data
        .reduce((pre, cur) => pre + ", " + cur.Value, "")
        .slice(1)
        .trim(),
  },
  [KEYWORD_TYPE.TP_EnquiryClosureReason]: {
    title: "Closure Reason",
    selectableMode: "single",
  },
  [KEYWORD_TYPE.Core_ActionTypeTag]: {
    title: "Action Type Tags",
    selectableMode: "multiple",
  },
};
