import { DTO_DeferredDuty_Account_Transaction } from "@app/products/property/deferred-duty/model";
import { colJournalDeferredDutyAccountTransaction } from "@app/products/property/journals/[id]/components/child-screens/deferred-duty-account-transactions/config";
import { useJournalDeferredDutyAccountTransactionsStore } from "@app/products/property/journals/[id]/components/child-screens/deferred-duty-account-transactions/store";
import { useJournalStore } from "@app/products/property/journals/[id]/store";
import { processDynamicColumns } from "@app/products/property/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

const nameOf = nameOfFactory<DTO_DeferredDuty_Account_Transaction>();
export const JournalDeferredDutyAccountTransactions = observer(() => {
  const {
    responseLoadError,
    journalDDAccountTransaction,
    isLoading,
    viewConfiguration,
    loadJournalDeferredDutyAccountTransactions,
    resetStore,
  } = useJournalDeferredDutyAccountTransactionsStore();
  const { journalId } = useJournalStore();

  useEffect(() => {
    loadJournalDeferredDutyAccountTransactions(journalId);
    return () => {
      resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journalId]);

  return isLoading ? (
    <Loading isLoading={isLoading} />
  ) : responseLoadError ? (
    <CCLoadFailed
      responseError={responseLoadError}
      onReload={() => {
        loadJournalDeferredDutyAccountTransactions(journalId);
      }}
    />
  ) : (
    <div className="cc-property-journal-grid">
      <CCGrid
        data={journalDDAccountTransaction ?? []}
        columnFields={processDynamicColumns(
          colJournalDeferredDutyAccountTransaction,
          viewConfiguration
        )}
        primaryField={nameOf("AccountTransactionId")}
      />
    </div>
  );
});
