export interface DTO_Assessment_AmountsOwing {
  AssessmentId: number;
  InstalmentOption: DTO_InstalmentOption;
  BalancesByStatus: DTO_AmountOwing_StatusSummary[];
  Totals: DTO_AmountOwing_Totals_Orig;
  AmountsOwingParams: DTO_AmountsOwing_Params;
  AmountsOwing: DTO_AmountsOwing_Group;
  AmountsOwingViews: DTO_Assessment_AmountsOwingViews;
}

export interface DTO_AmountOwing_Totals_Orig {
  AssessmentBalance: DTO_Balances;
  Postponed: DTO_Balances;
  Deferred: DTO_Balances;
  Arrears: DTO_Balances;
  Current: DTO_Balances;
  Totals: DTO_Balances;
  CurrentPeriodRates: DTO_CurrentPeriodRates;
  DeferredDutyBalance: number | null;
}

export interface DTO_CurrentPeriodRates {
  ChargesRaised: number | null;
  ExistingRebates: number | null;
  PendingRebates: number | null;
  Rebates: number | null;
  NettRates: number | null;
  ExistingDiscount: number | null;
  PendingDiscount: number | null;
  Discount: number | null;
  DiscountedRates: number;
}

export interface DTO_Balances {
  Balance: number | null;
  InterestBalance: number | null;
  Total: number | null;
}

export interface DTO_InstalmentOption {
  AdminFee: number | null;
  InterestAmount: number | null;
  CostOfInstalment: number | null;
  FirstInstalmentAmount: number | null;
  SubsequentInstalmentAmount: number | null;
}

export interface DTO_AmountsOwing_Breakdown_Orig {
  Charges: DTO_Charge[];
  SchemeAccounts: DTO_SchemeAccount[];
  RegisterAccounts: DTO_RegisterAccount[];
  CommunityFinanceCustomerBalance: DTO_CommunityFinanceCustomerBalance[];
}

export interface DTO_Charge {
  Discount: number | null;
  TotalBalance: number;
  InterestBalance: number | null;
  Balance: number | null;
  InstalmentAmount: number | null;
  DueDate: string | null;
  EffectiveDate: string | null;
  ChargeInstalmentId: number | null;
  Description: string;
  Reference: string;
  BalanceStatus: eBalanceStatus | null;
  FinancialGroup: string;
  FinancialArea: string;
  LevyType: string;
  LevyCategory: string;
  LevyName: string;
  InstalmentPlanName: string;
  InstalmentNumber: number | null;
  ChargeName: string;
  ChargeDate: string | null;
  RatingPeriod: string;
  PostponedBalance: number | null;
  PostponedInterestBalance: number | null;
}

export interface DTO_SchemeAccount {
  BalanceStatus: eBalanceStatus | null;
  Balance: number | null;
  InterestBalance: number | null;
  TotalBalance: number;
  Discount: number | null;
}

export interface DTO_RegisterAccount {
  BalanceStatus: eBalanceStatus | null;
  Balance: number | null;
  TotalBalance: number;
}

export interface DTO_CommunityFinanceCustomerBalance {
  BalanceStatus: eBalanceStatus | null;
  Balance: number | null;
  InterestBalance: number | null;
  TotalBalance: number;
}

export interface DTO_AmountOwing_StatusSummary {
  Name: string;
  BalanceStatuses: eBalanceStatus | null;
  TotalAmount: number;
}

export interface DTO_AmountsOwing_Group {
  AllViews: KeyValuePair<number, string>[];
  CurrentLevel: AmountsOwingGroupTypes;
  CurrentLevelCaption: string;
  CurrentLevelName: string;
  ChildLevel: AmountsOwingGroupTypes;
  ChildLevelCaption: string;
  ParentLevelNames: string[];
  Totals: DTO_AmountOwingTotals;
  ChildGroups: DTO_AmountsOwing_Group[];
  IsTopLevel: boolean;
  IsLastLevel: boolean;
  Charges: DTO_Charge[];
}

export interface DTO_AmountOwingTotals {
  Balance: number | null;
  InterestBalance: number | null;
  Total: number | null;
  InstalmentAmount: number | null;
}

export interface DTO_Charge {
  Discount: number | null;
  TotalBalance: number;
  InterestBalance: number | null;
  Balance: number | null;
  InstalmentAmount: number | null;
  DueDate: string | null;
  EffectiveDate: string | null;
  ChargeInstalmentId: number | null;
  Description: string;
  Reference: string;
  BalanceStatus: eBalanceStatus | null;
  PostponedBalance: number | null;
  FinancialGroup: string;
  LevyType: string;
  LevyCategory: string;
  LevyName: string;
  InstalmentPlanName: string;
  InstalmentNumber: number | null;
  ChargeName: string;
  ChargeDate: string | null;
  RatingPeriod: string;
  Id: number | null;
  FinancialArea: string;
  PostponedInterestBalance: number | null;
}

export interface DTO_AmountsOwing_Params {
  SelectedView: AssessmentAmountsOwingView;
  SelectedViewName: string;
  ShowPaidCharges: boolean;
}
export interface DTO_Assessment_AmountsOwingViews {
  Views: mAssessmentAmountsOwingView[];
}

export interface mAssessmentAmountsOwingView {
  View: AssessmentAmountsOwingView;
  Id: number;
  Name: string;
}

export enum AssessmentAmountsOwingView {
  Balance_Status_then_Due_Date = "Balance_Status_then_Due_Date",
  Financial_Area_then_Due_Date = "Financial_Area_then_Due_Date",
  Financial_Group_then_Financial_Area_then_Due_Date = "Financial_Group_then_Financial_Area_then_Due_Date",
  Rating_Period_then_Charge = "Rating_Period_then_Charge",
  Details = "Details",
  Custom = "Custom",
}

export enum eBalanceStatus {
  Paid = "Paid",
  Overdue = "Overdue",
  Due = "Due",
  Pending = "Pending",
  Unallocated_Credit = "Unallocated_Credit",
  Postponed = "Postponed",
  Credit_Balance_based_on_Estimated_Costs = "Credit_Balance_based_on_Estimated_Costs",
  Discount_on_full_payment = "Discount_on_full_payment",
  Pending_Rebate = "Pending_Rebate",
  Deferred = "Deferred",
  Pending_Due_if_not_paid_by_EOY = "Pending_Due_if_not_paid_by_EOY",
  Pending_Rebate_Adjustment = "Pending_Rebate_Adjustment",
  Pending_Interest_on_Overdue_Amounts = "Pending_Interest_on_Overdue_Amounts",
  Other = "Other",
  Register_Account_Balance = "Register_Account_Balance",
  Scheme_Account_Balance = "Scheme_Account_Balance",
  Community_Finance_Customer_Balance = "Community_Finance_Customer_Balance",
}

export enum AmountsOwingGroupTypes {
  Root = "Root",
  Details = "Details",
  Balance_Status = "Balance_Status",
  Due_Date = "Due_Date",
  Financial_Group = "Financial_Group",
  Financial_Area = "Financial_Area",
  Rating_Period = "Rating_Period",
  Charge = "Charge",
}

export interface KeyValuePair<TKey, TValue> {
  Key: TKey;
  Value: TValue;
}
