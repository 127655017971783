import { getDisplayTextWithDashes } from "@common/utils/common";

export const siteUsersBySecurityTemplate = {
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.ID,
      selectedRow.UserName + ", " + selectedRow.DisplayName,
    ]);
  },
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Security - Site Users by Security Template ${dynamicDisplayName}`;
  },
  getBookmarkListViewDisplayName() {
    return "Security - Site Users by Security Template";
  },
  getBookmarkListViewDetail() {
    return "Security - Site Users by Security Template";
  },
};
