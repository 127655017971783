import { inspectionsRoute } from "@app/core/inspections/route";
import {
  convertRouteDynamic,
  IConvertRouteDynamic,
} from "@common/utils/convertRouteDynamic";
import React from "react";
import { match } from "react-router-dom";
import { PrivateRoute } from "../AppRoutes";
import { ProductRouterSwitch } from "../ProductRouterSwitch";

interface IProps {
  match: match;
}

export function InspectionsRouter(props: IProps) {
  return (
    <ProductRouterSwitch>
      {inspectionsRoute.children &&
        convertRouteDynamic(inspectionsRoute.children).map(
          (route: IConvertRouteDynamic, index: number): JSX.Element => {
            return (
              route.component && (
                <PrivateRoute
                  exact
                  key={index}
                  path={`${props.match.url}/${route.path}`}
                  component={route.component}
                />
              )
            );
          }
        )}
    </ProductRouterSwitch>
  );
}
