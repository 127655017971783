import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { DTO_AssociatedItem_AssociatedName } from "@app/products/property/components/associations/components/associated-name/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_AssociatedName>();

export const colAssociationsAssociatedName: IColumnFields[] = [
  {
    field: nameOf("AssessmentNumber"),
    title: "Assessment Number",
    linkTo: (dataItem: DTO_AssociatedItem_AssociatedName) =>
      `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.AssessmentId}`,
  },
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("Role"),
    title: "Role",
  },
  {
    field: nameOf("Address"),
    title: "Address",
  },
  {
    field: nameOf("CreatedOn"),
    title: "Created On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CreatedBy"),
    title: "Created By",
  },
  {
    field: nameOf("DeletedOn"),
    title: "Deleted On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DeletedBy"),
    title: "Deleted By",
  },
  {
    field: nameOf("IsActive"),
    title: "Is Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("AssessmentReference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
