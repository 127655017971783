import { eventEmitter } from "@/App";
import { FormIdentifier } from "@app/products/crms/model";
import { DirectDebitAccount } from "@app/products/direct-debit/accounts/model";
import { postDDAuthorisation } from "@app/products/direct-debit/authorisations/[id]/components/dialogs/create-authorisation/api";
import { DDAuthorisationDetailsStep } from "@app/products/direct-debit/authorisations/[id]/components/dialogs/create-authorisation/components/steps/authorisation-details/_index";
import { DDAuthorisationBankingStep } from "@app/products/direct-debit/authorisations/[id]/components/dialogs/create-authorisation/components/steps/banking/_index";
import { DDAuthorisationSelectAccountsStep } from "@app/products/direct-debit/authorisations/[id]/components/dialogs/create-authorisation/components/steps/select-accounts/_index";
import {
  AUTHORISATION_NOTIFICATION_PORTAL_PLACE_ID,
  DD_AUTHORISATION_BANKING_STEP,
  DD_AUTHORISATION_DETAILS_STEP,
  DD_AUTHORISATION_SELECT_ACCOUNTS_STEP,
} from "@app/products/direct-debit/authorisations/[id]/components/dialogs/create-authorisation/constant";
import { DirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

export interface IDDCreateAuthorisationDialogProps {
  onClose: () => void;
}

export const DDCreateAuthorisationDialog = observer(
  ({ onClose }: IDDCreateAuthorisationDialogProps) => {
    const { pushNotificationPortal, clearNotifications } =
      useNotificationPortalStore();
    const { checkPermissions, isLoadingPermission } = useCheckPermissions({
      formIdentifier: FormIdentifier.DirectDebit_Dialog_Account,
      productType: PRODUCT_TYPE_NUMBER.DirectDebit,
    });

    const { cancelToken } = useCancelRequest();

    const steps: IStep[] = [
      {
        label: "Authorisation details",
        component: DDAuthorisationDetailsStep,
        visible: true,
        key: DD_AUTHORISATION_DETAILS_STEP,
      },
      {
        label: "Banking",
        component: DDAuthorisationBankingStep,
        visible: true,
        key: DD_AUTHORISATION_BANKING_STEP,
      },
      {
        label: "Select accounts",
        component: DDAuthorisationSelectAccountsStep,
        visible: true,
        key: DD_AUTHORISATION_SELECT_ACCOUNTS_STEP,
        options: {
          checkPermissions,
          isLoadingPermission,
        },
      },
    ];

    const ddCreateAuthorisationDialogSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const payload: DirectDebitAuthorisation = initialData;
            return postDDAuthorisation(payload, cancelToken());
          },
          handleSuccess: ({ dataFromApi }) => {
            onClose();
            pushNotificationPortal({
              title: "Direct debit authorisation successfully saved.",
              type: "success",
              placeId: TypePlaceId.Main,
            });
            eventEmitter.emit(CCGridEventType.RefreshOData);
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "Direct debit authorisation could not be saved.",
              type: "error",
              description: errorFromApi?.error,
              placeId: AUTHORISATION_NOTIFICATION_PORTAL_PLACE_ID,
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleSubmit = (data: any) => {
      const newData = cloneDeep(data);
      delete newData._options;
      let newAccounts: DirectDebitAccount[] = [];
      if (newData.Accounts?.length > 0) {
        newAccounts = newData.Accounts.map((account: any) => {
          delete account._options;
          return { ...account, Account_ID: 0 };
        });
      }
      ddCreateAuthorisationDialogSlider.fetchApi({
        initialData: { ...newData, Accounts: newAccounts },
      });
    };

    useEffectOnce(() => {
      return () => {
        clearNotifications();
      };
    });

    return (
      <CCFormStep
        onSubmit={handleSubmit}
        initialSteps={steps}
        initialValues={{}}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="55%"
            maxHeight="50%"
            titleHeader={"Create Direct Debit Authorisation"}
            onClose={onClose}
            yieldNotificationPortal={AUTHORISATION_NOTIFICATION_PORTAL_PLACE_ID}
            disabled={ddCreateAuthorisationDialogSlider.isFetching}
            bodyElement={renderProps.children}
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Cancel
                </Button>
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                <Button
                  themeColor="primary"
                  id="cc-next-step-button"
                  iconClass={
                    ddCreateAuthorisationDialogSlider?.isFetching
                      ? "fas fa-spinner fa-spin"
                      : ""
                  }
                  disabled={renderProps.nextButton.disabled}
                  className={"cc-dialog-button"}
                  onClick={renderProps.nextButton.onClick}
                >
                  {renderProps.isLastStep
                    ? "Submit"
                    : renderProps.nextButton.label}
                </Button>
              </div>
            }
          />
        )}
      />
    );
  }
);
