import { RECORDTYPE } from "@common/constants/recordtype";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export interface IPPRActions {
  Date: string | null;
  ID: number;
  Type: string;
  Officer: string;
  Description: string;
  DueDate: string;
  Status: string;
  CompletedDate: string;
  ActionType_ID: number;
  DateSent: string | null;
  DueStatus: string | null;
  ParentDescription: string | null;
  RecordedBy_ID: number;
  RecordedDate: string;
  RecordedBy: string;
  ActionOfficer_ID: number | null;
  Parent_ID: number | null;
  ParentType_ENUM: RECORDTYPE | null;
  Flag_ActionRequired: boolean;
  Tag?: string;
  FormIdentifier_ENUM: number;
}

export enum ActionTypes {
  ChangeRequest = "Change Request",
  Communication = "Communication",
  Email = "Email",
  FurtherInfoRequest = "Further Info Request",
  InternalReferral = "Internal Referral ",
  FollowUp = "Follow Up",
  ExternalReferral = "External Referral ",
  PhoneCall = "Phone call",
  PPRMeeting = "PPR Meeting",
  RoadDiscussion = "Road Discussion",
  Appeal = "Appeal",
}

export interface Svc_ActionTypes {
  ActionTypes: IKeyValuePacket[];
  NoticeTypes: IKeyValuePacket[];
}
