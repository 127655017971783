import { getParcelZones } from "@app/products/property/parcels/[id]/components/child-screens/zones/api";
import { DTO_Parcel_Zones } from "@app/products/property/parcels/[id]/components/child-screens/zones/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ParcelZonesStore {
  private _parcelZones?: DTO_Parcel_Zones = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get parcelZones() {
    return toJS(this._parcelZones);
  }

  setParcelZones = (parcelZones?: DTO_Parcel_Zones) => {
    runInAction(() => {
      this._parcelZones = parcelZones;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  resetStore = () => {
    this.setParcelZones(undefined);
    this.setIsLoading(false);
    this.setResponseLoadError(undefined);
  };

  loadParcelZones = async (parcelId: number) => {
    this.setIsLoading(true);
    const response = await getParcelZones(parcelId);

    if (isSuccessResponse(response)) {
      this.setParcelZones(response?.data);
    } else {
      this.setResponseLoadError({
        status: response.status,
        error: response.error,
      });
    }
    this.setIsLoading(false);
  };
}

const parcelZonesStoreContext = createContext(new ParcelZonesStore());
export const useParcelZonesStore = () => {
  return useContext(parcelZonesStoreContext);
};
