import { getCertificateAreaOfResponsibility } from "@app/products/property/certificates/[id]/components/child-screens/compliance/api";
import { ILOVsCertificate } from "@app/products/property/certificates/[id]/components/child-screens/rates-department/model";
import { EComponentAreaResponsibility } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/config";
import { DTO_Certificate_AreaOfResponsibility } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/model";
import { DTO_LOV_UDAL } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/model";
import { APIResponseError } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { cloneDeep, isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class CertificateDefermentsStore {
  private _lovsCertificate: ILOVsCertificate | undefined = undefined;
  private _data: DTO_Certificate_AreaOfResponsibility | undefined = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get data() {
    return toJS(this._data);
  }
  setData = (data?: DTO_Certificate_AreaOfResponsibility) => {
    runInAction(() => {
      this._data = data;
    });
  };

  get lovsCertificate() {
    return toJS(this._lovsCertificate);
  }
  setLovsCertificate = (data?: ILOVsCertificate) => {
    runInAction(() => {
      this._lovsCertificate = data;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  loadCertificateDeferments = async (certificationId: number) => {
    if (isNil(certificationId)) return;
    this.setIsLoading(true);
    let errorResponse = undefined;
    const response = await getCertificateAreaOfResponsibility(
      certificationId,
      EComponentAreaResponsibility.Deferments
    );

    if (Array.isArray(response) && response[0]?.data && response[1]?.data) {
      const [lovs, deferments] = response;
      let udaDataLovs: any = cloneDeep(lovs?.data?.UDAL);
      udaDataLovs = udaDataLovs.reduce((group: any, item: DTO_LOV_UDAL) => {
        const { UDA_Attribute_Id } = item;
        group[UDA_Attribute_Id] = group[UDA_Attribute_Id] || [];
        group[UDA_Attribute_Id].push(item);
        return group;
      }, {});
      this.setLovsCertificate(udaDataLovs);
      this.setData(deferments?.data);
    } else {
      errorResponse = {
        status: APIResponseStatus.BAD_REQUEST,
        error: "Load data failed.",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._data = undefined;
      this._lovsCertificate = undefined;
    });
  };
}

export const certificateDefermentsStoreInstance =
  new CertificateDefermentsStore();
const certificateDefermentsStoreContext = createContext(
  certificateDefermentsStoreInstance
);
export const useCertificateDefermentsStore = () => {
  return useContext(certificateDefermentsStoreContext);
};
