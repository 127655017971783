import { history } from "@/AppRoutes";
import { PUBLIC_HOLIDAYS_ROUTE } from "@common/pages/settings/lookups/public-holidays/_id/constant";
import React from "react";
import "./_index.scss";

export interface IYearControlProps {
  yearDefault: number;
  resetSelectedRows?: React.Dispatch<React.SetStateAction<any[]>>;
  setShowSlideBar?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const YearControl = ({
  yearDefault,
  resetSelectedRows,
  setShowSlideBar,
}: IYearControlProps) => {
  const decreaseYear = () => {
    resetSelectedRows?.([]);
    setShowSlideBar?.(false);
    history.replace(`${PUBLIC_HOLIDAYS_ROUTE}/${yearDefault - 1}`);
  };
  const increaseYear = () => {
    resetSelectedRows?.([]);
    setShowSlideBar?.(false);
    history.replace(`${PUBLIC_HOLIDAYS_ROUTE}/${yearDefault + 1}`);
  };
  return (
    <div className="cc-year-control">
      <i
        className="far fa-arrow-circle-left cc-arrow-icon"
        onClick={decreaseYear}
      ></i>
      <p>{yearDefault}</p>
      <i
        className="far fa-arrow-circle-right cc-arrow-icon"
        onClick={increaseYear}
      ></i>
    </div>
  );
};
