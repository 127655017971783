import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  enum: HealthManagerMenu.Administration,
  children: [
    {
      path: "premises-types",
      name: "Premises Types",
      enum: HealthManagerMenu.PremisesTypes,
      component: require("./premises-types/_index").default,
    },
    {
      path: "classifications",
      name: "Classifications",
      enum: HealthManagerMenu.Classifications,
      component: require("./classifications/_index").default,
    },
    {
      path: "risks",
      name: "Risks",
      enum: HealthManagerMenu.RiskClass,
      component: require("./risks/_index").default,
    },
    {
      path: "business-activities",
      name: "Business Activities",
      enum: HealthManagerMenu.BusinessActivities,
      //mock route: not implemented yet
      //component: require("./business-activities/_index").default,
    },
    {
      path: "volume-scores",
      name: "Volume Scores",
      enum: HealthManagerMenu.VolumeScores,
      //mock route: not implemented yet
      //component: require("./volume-scores/_index").default,
    },
    {
      path: "nc-items",
      name: "NC Items",
      enum: HealthManagerMenu.AdminNCItems,
      component: require("./nc-items/_index").default,
    },
    {
      path: "insp-comments",
      name: "Insp Comments",
      enum: HealthManagerMenu.AdminAFSAComments,
      component: require("./insp-comments/_index").default,
    },
    {
      path: "conditions",
      name: "Conditions",
      enum: HealthManagerMenu.Conditions,
      //mock route: not implemented yet
      //component: require("./conditions/_index").default,
    },
    {
      path: "settings",
      name: "System Settings",
      enum: HealthManagerMenu.Settings,
      component: require("./settings/_index").default,
    },
  ],
};
