import { AmsRoute } from "@app/products/ams/constants/ams.route";
import { getProductTitle } from "@common/constants/product-control";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { getAssetActivityDashboard } from "@common/pages/home/api";
import { IAmsDashBoardActivity } from "@common/pages/home/model";
import { useGlobalStore } from "@common/stores/global/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

const AssetManagementActivity = observer(() => {
  const history = useHistory();
  const { isVisible } = useGlobalStore();

  const [assetData, setAssetData] = useState<IAmsDashBoardActivity>({
    Asset_Count: 0,
    Asset_Category_Count: 0,
    Warranty_3_month: 0,
  });

  useEffectOnce(() => {
    if (isVisible(PRODUCT_TYPE.CommunityAsset)) {
      getAssetActivityDashboard()
        .then((response: IAmsDashBoardActivity) => {
          setAssetData(response);
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  });

  return (
    <div className="cc-activity-group">
      <div className="cc-activity-group-title">
        {getProductTitle(PRODUCT_TYPE.CommunityAsset)}
      </div>
      <div className="cc-activity-group-container">
        <div className="cc-activity-item">
          <div className="cc-activity-name">ASSET COUNT</div>
          <div className="cc-activity-number">{assetData.Asset_Count}</div>
        </div>
        <div className="cc-activity-item">
          <div className="cc-activity-name">ASSET CATEGORY COUNT</div>
          <div className="cc-activity-number">
            {assetData.Asset_Category_Count}
          </div>
        </div>
        <div
          className="cc-activity-item"
          onClick={(e) => {
            history.push(AmsRoute.COMPUTER_ASSET_REPORT_PATH);
          }}
        >
          <div className="cc-activity-name">WARRANTY END SOON</div>
          <div className="cc-activity-number">{assetData.Warranty_3_month}</div>
        </div>
      </div>
    </div>
  );
});

export default AssetManagementActivity;
