import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export default ({ formRenderProps }: ICommonFormProps) => {
  const { configData } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();

  if (!configData) return <></>;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField
                .CorporateSettings_DisplayAutoStandardInConditionsTAB
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField
                .CorporateSettings_Conditions_Adhoc_EnableAdvancedMode
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
      </div>
    </section>
  );
};
