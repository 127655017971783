import { mockBuildingRegisterEasement } from "@app/products/building/registers/easements/[id]/mock";
import {
  IBuildingRegisterEasementFormData,
  IBuildingRegisterEasementRequest,
} from "@app/products/building/registers/easements/[id]/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";

export const getBuildingRegisterEasementById = async (
  id?: number
): Promise<APIResponse<IBuildingRegisterEasementFormData | undefined>> => {
  await sleep(1000);
  return {
    data: {
      ...mockBuildingRegisterEasement,
      ID: id,
    } as IBuildingRegisterEasementFormData,
    status: APIResponseStatus.SUCCESS,
    statusText: "Ok",
    headers: {},
    config: {},
  };
};
export const postBuildingRegisterEasement = async (
  requestBody: IBuildingRegisterEasementRequest
) => {
  await sleep(2000);
};

export const putBuildingRegisterEasement = async (
  requestBody: IBuildingRegisterEasementRequest
) => {
  await sleep(2000);
};
