import { getDisplayTextWithDashes } from "@common/utils/common";

export const securityTemplate = {
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.SecurityTemplate_ID,
      selectedRow.TemplateName + ", " + selectedRow.TemplateDescription,
    ]);
  },
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.SecurityTemplate_ID
      ? `- ${selectedRow.SecurityTemplate_ID}`
      : "";
    return `Security - Security Template ${dynamicDisplayName}`;
  },
  getBookmarkListViewDisplayName() {
    return "Security - Security Template";
  },
  getBookmarkListViewDetail() {
    return "Security - Security Template";
  },
};
