import { DTO_LOV } from "@common/models/odataResponse";

export interface INewInspectionFormData {
  Property?: any[];
  CommentsInPreviousCases?: any[];
  CommentsForThisCases?: string;
  //InspectionDetail
  CaseId?: number;
  Date?: Date | string;
  LastInspection?: Date | string;
  OutcomeValues?: DTO_LOV[];
  ClearanceValues?: DTO_LOV[];
  AdditionNotes?: string;
  SpecificInstructions?: string;
  CopyInstructionsFromPreviousInspection?: boolean;
  AddAttachment?: boolean;
  Outcome?: string;
  _searchByCaseId?: number | string;
}

export interface IPropertyData {
  Locality: string;
  Street: string;
  StreetType: string;
  HouseNumber: string;
  PropertyName: string;
  Owner: string;
  OwnerAddress: string;
  Ratepayer: string;
  RatepayerAddress: string;
  LandArea: string;
  LegalDescription: string;
  Clearance: string;
  Outcome: string;
  AssessmentNumber: string;
  ParcelId: number;
  CaseId: string;
}

export interface IPropertyGridData extends Array<IPropertyData> {}

export enum OUTCOME_TYPE {
  NO_HAZARD = "No Hazard",
}
