import { getAdhocStepLovs } from "@app/products/property/components/action-bar/dialog/add-workflow/api";
import { DTO_AdhocStep_LOVs } from "@app/products/property/components/action-bar/dialog/add-workflow/model";
import {
  DTO_Workflow_Approval_Step,
  nameOfLov,
} from "@app/products/property/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAddWorkflowDialog {
  onClose: () => void;
  handleSubmit: (values: any) => void;
  isLoadingOnDialog: boolean;
}

const nameOfApproval = nameOfFactory<DTO_Workflow_Approval_Step>();
export const AddWorkflowDialog = ({
  onClose,
  handleSubmit,
  isLoadingOnDialog,
}: IAddWorkflowDialog) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();
  const [adhocStepLOVs, setAdhocStepLOVs] = useState<DTO_AdhocStep_LOVs>();

  const loadLovs = async () => {
    setIsLoading(true);
    const response = await getAdhocStepLovs();
    let errorResponse = undefined;
    if (isSuccessResponse(response)) {
      setAdhocStepLOVs(response?.data);
    } else {
      const responseError = response as APIResponse;
      errorResponse = {
        status: responseError.status,
        error: responseError.error ?? "Load failed",
      };
    }
    setResponseLoadError(errorResponse);
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadLovs();
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadLovs();
        }}
      />
    );

  return (
    <Form
      onSubmitClick={({ values }: FormSubmitClickEvent) => {
        handleSubmit(values);
      }}
      render={({ onSubmit, modified, valid }: FormRenderProps) => {
        return (
          <CCDialog
            titleHeader="Add workflow step"
            maxWidth="35%"
            height="auto"
            isLoading={isLoading}
            onClose={onClose}
            bodyElement={
              <FormElement className="cc-form cc-add-assessment-range-dialog">
                <section className="cc-field-group">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-field">
                    <CCLabel title="Workflow step" isMandatory />
                    <Field
                      name={nameOfApproval("WorkflowStepName")}
                      placeholder={"Workflow step"}
                      component={CCInput}
                      validator={requiredValidator}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Workflow details" isMandatory />
                    <Field
                      name={nameOfApproval("WorkflowDetails")}
                      placeholder={"Workflow details"}
                      component={CCInput}
                      validator={requiredValidator}
                    />
                  </div>
                  {/* TODO: Not using it right now. Recheck later */}
                  {/* <div className="cc-field">
                    <CCLabel title="Assign to" />
                    <Field
                      name={"Assign_to"}
                      placeholder={"Assign to"}
                      component={CCSearchComboBox}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      data={mockAssignToData}
                      isUseDefaultOnchange
                    />
                  </div> */}
                  {/* TODO: Not using it right now. Recheck later */}
                  {/* {isEqual(
                    getValueName(
                      valueGetter("Assign_to"),
                      mockAssignToData,
                      nameOfLov("Name")
                    ),
                    EAssignTo.Officer
                  ) && (
                    <div className="cc-field">
                      <CCLabel title="Officer" />
                      <Field
                        name={nameOfApproval("AssessmentGroup")}
                        placeholder={"Officer"}
                        component={CCSearchComboBox}
                        textField={nameOfLov("Name")}
                        dataItemKey={nameOfLov("Code")}
                        data={contactLOVs?.Officer}
                        isUseDefaultOnchange
                      />
                    </div>
                  )} */}
                  {/* TODO: Not using it right now. Recheck later */}
                  {/* {isEqual(
                    getValueName(
                      valueGetter("Assign_to"),
                      mockAssignToData,
                      nameOfLov("Name")
                    ),
                    EAssignTo.Functional_Group
                  ) && ( */}
                  <div className="cc-field">
                    <CCLabel title="Functional group" isMandatory />
                    <Field
                      name={nameOfApproval("WDA_Approval_Security_Group_Id")}
                      placeholder={"Functional group"}
                      component={CCSearchComboBox}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      data={adhocStepLOVs?.FunctionalGroup}
                      isUseDefaultOnchange
                      validator={requiredValidator}
                    />
                  </div>
                  {/* )} */}
                </section>
              </FormElement>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={onSubmit}
                  iconClass={isLoadingOnDialog ? "fas fa-spinner fa-spin" : ""}
                  disabled={!valid || !modified || isLoadingOnDialog}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
