import { history } from "@/AppRoutes";
import { ICON_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
import { isNil } from "lodash";
export interface ICategoryPropertyConfig {
  category: string;
  requestUrl: string;
  product: string;
}

export const SettingFieldMapping = {
  FirstName: "FirstName",
  LastName: "LastName",
  LoginName: "UserName",
  Email: "Email",
  SecurityTemplates: "SecurityTemplates",
  Active: "IsActive",
  Locked: "IsLocked",
  OnLeave: "OnLeave",
  EXTUser: "IsExternalUser",
  Councillor: "IsCouncillor",
  Title: "Title",
  FieldName: "FieldName",
  DisplayOrder: "DisplayOrder",
  MaxLength: "MaxLength",
  EditGroupId: "EditGroupId",
  DataType: "DataType",
  ItemId: "ItemId",
  SettingCategoryName: "SettingCategoryName",
  IsEditable: "IsEditable",
  Name: "Name",
};

export const settingListIsActiveHasItemID = (
  title: string = "Title",
  description: string = "Description"
): IColumnFields[] => {
  return [
    {
      field: SettingFieldMapping.Title,
      title: title,
      locked: true,
      disableFieldName: "IsEditable",
      linkTo: (dataItem) => {
        return {
          pathname: history.location.pathname,
          state: {
            requestUrl: `/${dataItem.ProductType_Enum}/${dataItem.SettingCategoryName}/${dataItem.Name}/${dataItem.ItemId}`,
            product: dataItem.ProductType_Enum,
            category: dataItem.SettingCategoryName,
          } as ICategoryPropertyConfig,
        };
      },
    },
    {
      field: "Description",
      title: description,
    },
    {
      field: "IsActive",
      title: "Is Active",
      format: ICON_FORMAT.ICON_BOOLEAN,
    },
  ];
};

export const settingListHasItemID = (
  title: string = "Title",
  description: string = "Description"
): IColumnFields[] => {
  return [
    {
      field: SettingFieldMapping.Title,
      title: title,
      locked: true,
      disableFieldName: "IsEditable",
      linkTo: (dataItem) => {
        return {
          pathname: history.location.pathname,
          state: {
            requestUrl: `/${dataItem.ProductType_Enum}/${dataItem.SettingCategoryName}/${dataItem.Name}/${dataItem.ItemId}`,
            product: dataItem.ProductType_Enum,
            category: dataItem.SettingCategoryName,
          } as ICategoryPropertyConfig,
        };
      },
    },
    {
      field: "Description",
      title: description,
    },
  ];
};

export const settingList = (
  title: string = "Title",
  description: string = "Description"
): IColumnFields[] => {
  return [
    {
      field: SettingFieldMapping.Title,
      title: title,
      disableFieldName: "IsEditable",
      locked: true,
      linkTo: (dataItem) => {
        return {
          pathname: history.location.pathname,
          state: {
            requestUrl: `/${dataItem.ProductType_Enum}/${dataItem.SettingCategoryName}/${dataItem.Name}`,
            product: dataItem.ProductType_Enum,
            category: dataItem.SettingCategoryName,
          } as ICategoryPropertyConfig,
        };
      },
    },

    {
      field: "Description",
      title: description,
    },
  ];
};

export const settingListIsActive = (
  title: string = "Title",
  description: string = "Description"
): IColumnFields[] => {
  return [
    {
      field: SettingFieldMapping.Title,
      title: title,
      disableFieldName: "IsEditable",
      locked: true,
      linkTo: (dataItem) => {
        return {
          pathname: history.location.pathname,
          state: {
            requestUrl: `/${dataItem.ProductType_Enum}/${dataItem.SettingCategoryName}/${dataItem.Name}`,
            product: dataItem.ProductType_Enum,
            category: dataItem.SettingCategoryName,
          } as ICategoryPropertyConfig,
        };
      },
    },
    {
      field: "Description",
      title: description,
    },
    {
      field: "IsActive",
      title: "Is Active",
      format: ICON_FORMAT.ICON_BOOLEAN,
    },
  ];
};

export const settingColumnField = (
  isActive: boolean,
  title: string,
  description: string,
  isItemId?: boolean
): IColumnFields[] => {
  if (isActive) {
    if (isItemId) {
      return settingListIsActiveHasItemID(title, description);
    } else {
      return settingListIsActive(title, description);
    }
  } else {
    if (isItemId) {
      return settingListHasItemID(title, description);
    } else {
      return settingList(title, description);
    }
  }
};

export const getCurrentStateCategoryData = (state: any, params: any) => {
  return !isNil(state) ? state : params?.category?.replaceAll("-", "");
};
