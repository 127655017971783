import { odataCoreGetPublicHolidaysByYear } from "@app/core/components/common/utils";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CheckPermissionSettingWrapper } from "@common/pages/settings/components/check-permission-wrapper/_index";
import { PublicHolidaysDialog } from "@common/pages/settings/lookups/public-holidays/_id/components/public-holidays-dialog/_index";
import { PublicHolidayTabDetails } from "@common/pages/settings/lookups/public-holidays/_id/components/reference-sidebar/detail/_index";
import { YearControl } from "@common/pages/settings/lookups/public-holidays/_id/components/year-control/_index";
import { generateColPublicHolidaysGrid } from "@common/pages/settings/lookups/public-holidays/config";
import {
  SettingState,
  Svc_View_LookUpDatesList,
} from "@common/pages/settings/lookups/public-holidays/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { getDisplayTextWithDashes, nameOfFactory } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { FormElement } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router";
import "./_index.scss";

const nameOf = nameOfFactory<Svc_View_LookUpDatesList>();

const ManagePublicHolidays = () => {
  const { id } = useParams<{ id: string }>();
  const { currentUserInfo } = useGlobalStore();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const { settings } = useCommonCoreStore();
  const [publicHolidayID, setPublicHolidayID] = useState<number>(0);
  const [showSlideBar, setShowSlideBar] = useState<boolean>(false);
  const [isShowPublicHolidayDialog, setIsShowPublicHolidayDialog] =
    useState<boolean>(false);
  const settingState: SettingState = useMemo(() => {
    return {
      isHaveACT: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_ACTHOLIDAYS]
      ),
      isHaveNSW: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_NSWHOLIDAYS]
      ),
      isHaveVIC: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_VICHOLIDAYS]
      ),
      isHaveSA: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_SAHOLIDAYS]
      ),
      isHaveTAS: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_TASHOLIDAYS]
      ),
      isHaveWA: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_WAHOLIDAYS]
      ),
      isHaveNT: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_NTHOLIDAYS]
      ),
      isHaveQLD: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_QLDHOLIDAYS]
      ),
    };
  }, [settings]);

  const defaultItemPerPage: number = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_FlatGridPageSize]
  );

  const managePageUrl = window.location.pathname;

  const year = parseInt(id);
  const selectedID = selectedRows?.[0]?.ID;

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([year])}`,
      LinkUrl: managePageUrl,
      LinkText: `Settings - Lookups - Public Holidays - ${year ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.Core_Bookmark,
      Record_ID: year ? +year : 0,
    },
  ];

  return (
    <CheckPermissionSettingWrapper>
      <FormNavigation title={"Public Holiday"} />

      <>
        <FormTitle title={"Public Holiday"} badge={year} />
        <CCManagePageActionBar
          rightActions={[
            <CCNavButton
              title={"Slide Bar"}
              iconClass="fal fa-info-circle"
              isActive={showSlideBar && selectedID}
              disabled={!selectedID}
              onClick={() => {
                setShowSlideBar(!showSlideBar);
              }}
            />,
            <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
          ]}
        />
        <div className="cc-manage-container">
          <div className="cc-form cc-manage-left">
            <CCAppNotification />
            <div className="cc-manage-form-body">
              <div className="cc-form">
                <CCGeneralPanel
                  component={
                    <>
                      {isShowPublicHolidayDialog && (
                        <PublicHolidaysDialog
                          publicHolidayID={publicHolidayID}
                          onCloseDialog={() => {
                            setIsShowPublicHolidayDialog(false);
                          }}
                          setShowSlideBar={setShowSlideBar}
                        />
                      )}

                      <FormElement>
                        <section className="cc-field-group">
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <CCLabel title="Holidays for the year" />
                            </div>
                            <YearControl
                              yearDefault={year}
                              resetSelectedRows={setSelectedRows}
                              setShowSlideBar={setShowSlideBar}
                            />
                          </div>
                        </section>
                        <section className="cc-field-group">
                          <CCGrid
                            className="cc-grid-public-holidays"
                            selectableMode="single"
                            itemPerPage={defaultItemPerPage}
                            columnFields={generateColPublicHolidaysGrid(
                              true,
                              settingState,
                              setPublicHolidayID,
                              setIsShowPublicHolidayDialog
                            )}
                            primaryField={nameOf("ID")}
                            dataUrl={odataCoreGetPublicHolidaysByYear(year)}
                            selectedRows={selectedRows}
                            onSelectionChange={(selectedRows: any[]) => {
                              setSelectedRows(selectedRows);
                            }}
                          />
                        </section>
                      </FormElement>
                    </>
                  }
                />
              </div>
            </div>
          </div>
          {showSlideBar && selectedID && (
            <div className="cc-manage-right">
              <ReferenceSideBar
                components={[
                  {
                    title: "Details",
                    component: (
                      <PublicHolidayTabDetails selectedRows={selectedRows} />
                    ),
                  },
                ]}
              />
            </div>
          )}
        </div>
      </>
    </CheckPermissionSettingWrapper>
  );
};

export default observer(ManagePublicHolidays);
