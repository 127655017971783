import { ExistManageAnimalPoundRegister } from "@app/products/animals/pound-register/[id]/components/form/existed/_index";
import { NewManageAnimalPoundRegister } from "@app/products/animals/pound-register/[id]/components/form/new/_index";
import { IParentPoundRegisterSection } from "@app/products/animals/pound-register/[id]/model";
import { useAnimalPoundRegisterStore } from "@app/products/animals/pound-register/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useGlobalStore } from "@common/stores/global/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

export default observer(() => {
  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();
  const { resetMenu } = useCCSubActionBarStore();
  const { currentUserInfo } = useGlobalStore();
  const { loadPoundRegisterInfo, checkIsSuperAdmin, resetStore, setParent } =
    useAnimalPoundRegisterStore();

  const { state } = useLocation<{
    parent?: IParentPoundRegisterSection;
  }>();

  useEffect(() => {
    if (currentUserInfo?.UserPKID)
      checkIsSuperAdmin(parseInt(currentUserInfo.UserPKID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserInfo?.UserPKID]);

  useEffect(() => {
    if (state?.parent) {
      setParent(state?.parent);
      window.history.replaceState({}, "");
    }

    loadPoundRegisterInfo(parseInt(id), isNew);
    return () => {
      resetStore();
      resetMenu();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew, loadPoundRegisterInfo]);

  if (isNew) {
    return <NewManageAnimalPoundRegister />;
  }
  return <ExistManageAnimalPoundRegister />;
});
