import { IParcels } from "@app/products/property/registers/list/components/action-bar/form-steps/create-register/components/parcels/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IParcels>();
export const colParcel: IColumnFields[] = [
  {
    field: nameOf("ParcelReference"),
    title: "Parcel Reference",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: nameOf("Address"), title: "Parcel Address" },
  { field: nameOf("Name"), title: "Owner" },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOf("ParcelId"),
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
