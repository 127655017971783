import { IRegisterNamesGrid } from "@app/products/property/registers/[id]/components/child-screens/names/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IRegisterNamesGrid>();

export const colRegisterNames: IColumnFields[] = [
  {
    field: nameOf("Role"),
    title: "Role",
  },
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Address"),
    title: "Address",
  },
  {
    field: nameOf("Gender"),
    title: "Gender",
  },
  {
    field: nameOf("DateOfBirth"),
    title: "Date of Birth",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("IsActive"),
    title: "Is Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("EntityNameAddrId"),
    title: "Entity Name/Addr ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
