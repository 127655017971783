import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colValuations } from "@app/products/property/assessments/[id]/components/child-screens/valuations/config";
import { ViewConfiguration } from "@app/products/property/model";
import { formatDynamicValuationsForColumn } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import {
  RadioButton,
  RadioButtonChangeEvent,
} from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { fnt_Assess_Supp_ValuationResult } from "./model";

enum ValuationFilterEnum {
  Valuation = 1,
  RatingPeriod = 2,
}
interface IValuationProps {
  assessmentId: number;
  valuationNumber?: string;
}

const nameOf = nameOfFactory<fnt_Assess_Supp_ValuationResult>();
export const Valuations = ({
  assessmentId,
  valuationNumber = "",
}: IValuationProps) => {
  const [selectedFilterValue, setSelectedFilterValue] = useState(
    ValuationFilterEnum.Valuation
  );
  const [processedColumn, setProcessedColumn] =
    useState<IColumnFields[]>(colValuations);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Assessment_Valuations,
      colValuations,
      {
        payload: [
          {
            Key: "id",
            Value: selectedFilterValue,
          },
        ],
        dynamicFormat: formatDynamicValuationsForColumn,
      }
    );
    if (Array.isArray(response)) {
      setProcessedColumn(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadViewConfig();
    // eslint-disable-next-line
  }, [selectedFilterValue, assessmentId]);

  const handleChangeFilterValue = (event: RadioButtonChangeEvent) => {
    setSelectedFilterValue(event.value);
  };

  return (
    <div className="cc-property-valuations">
      <div className="cc-grid-control-between">
        <div className="cc-grid-control-left">
          <div className="cc-control-item">
            <RadioButton
              name="filterByGroup"
              value={ValuationFilterEnum.Valuation}
              checked={selectedFilterValue === ValuationFilterEnum.Valuation}
              label="By valuation"
              title="By valuation"
              onChange={handleChangeFilterValue}
              disabled={isLoading}
            />
          </div>
          <div className="cc-control-item">
            <RadioButton
              name="filterByGroup"
              value={ValuationFilterEnum.RatingPeriod}
              checked={selectedFilterValue === ValuationFilterEnum.RatingPeriod}
              label="By rating period"
              title="By rating period"
              onChange={handleChangeFilterValue}
              disabled={isLoading}
            />
          </div>
        </div>
        <div className="cc-grid-control-right">
          <div className="cc-control-item">
            <label className="cc-label">Valuation number</label>
            <CCInput
              readOnly
              name="SearchValuationNumber"
              value={valuationNumber}
            />
          </div>
        </div>
      </div>
      <label className="cc-label">Valuations</label>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadViewConfig();
          }}
        />
      ) : (
        <CCGrid
          className="cc-property-valuations-grid"
          dataUrl={`odata/property/internal/assessmentvaluationsregister?key=${selectedFilterValue}&id=${assessmentId}&$count=true&`}
          columnFields={processedColumn}
          primaryField={nameOf("PrimaryId")}
          state={{
            sort: [{ field: nameOf("Val_Effective_Date"), dir: "desc" }],
          }}
        />
      )}
    </div>
  );
};
