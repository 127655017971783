import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSSystemAdminBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `${CRMS_PRODUCT_NAME} - System Admin ${dynamicDisplayName}`;
  },
  getBookmarkDetail() {
    return `${CRMS_PRODUCT_NAME} System Admin`;
  },
};
