import { BuildingFieldMapping } from "@app/products/building/model";
import { POOLS_OR_SPAS_MANAGE_ROUTE } from "@app/products/building/pools-or-spas/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingPoolsSpasRegister: IColumnFields[] = [
  {
    field: BuildingFieldMapping.Register_Number,
    title: "Number",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem: any) => {
      return `${POOLS_OR_SPAS_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.Date_Lodged,
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Address,
    title: "Address",
  },
  {
    field: BuildingFieldMapping.Locality,
    title: "Locality",
  },
  {
    field: BuildingFieldMapping.Contact,
    title: "Contact",
  },
  {
    field: BuildingFieldMapping.Owner,
    title: "Owner",
  },
  {
    field: BuildingFieldMapping.Type,
    title: "Type",
  },
  {
    field: BuildingFieldMapping.Description,
    title: "Description",
  },
  {
    field: BuildingFieldMapping.Status,
    title: "Status",
  },
  {
    field: BuildingFieldMapping.Officer,
    title: "Officer",
  },
  {
    field: BuildingFieldMapping.ReferenceNumber,
    title: "Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: BuildingFieldMapping.Date_Complete,
    title: "Registered Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.LegalDescription,
    title: "Legal Description",
  },
  {
    field: BuildingFieldMapping.Date_Construction,
    title: "Date of Construction",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.BarrierStandard,
    title: "Barrier Standard",
  },
  {
    field: BuildingFieldMapping.BarrierType,
    title: "BCA/Regulation Applicable",
  },
  {
    field: BuildingFieldMapping.Date_CertificateOfCompliance,
    title: "Barrier Compliance Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Pool_RegisteredDate,
    title: "Certified Barrier Compliance Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Date_Lodgement_CertificateOfBarrierNC,
    title: "Certified Barrier NC Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.BuildingPermitNo,
    title: "Building Permit Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: BuildingFieldMapping.Date_WorkIssued,
    title: "Works Altered Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Work_CarriedOut,
    title: "Work Carried Out",
  },
];
