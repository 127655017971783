import { getViewConfigurations } from "@app/products/property/api";
import { colNoticeRunsNotices } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/child-screens/notices/config";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class NoticeRunNoticesStore {
  private _columnNotice: IColumnFields[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get columnNotice() {
    return this._columnNotice;
  }
  setColumnNotice = (columnConfig: IColumnFields[]) => {
    runInAction(() => {
      this._columnNotice = columnConfig;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  loadConfigs = async () => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    const response = await getViewConfigurations(
      ViewConfiguration.OData_Notices
    );
    let configColumn: IColumnFields[] = [...colNoticeRunsNotices];
    if (isSuccessResponse(response)) {
      const viewConfig: ICCViewColumn[] | undefined =
        response?.data?.ColumnDefinitions?.Columns;
      if (viewConfig?.length) {
        configColumn = processDynamicColumns(configColumn, viewConfig);
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }

    this.setColumnNotice(configColumn);
    this.setIsLoading(false);
    this.setResponseLoadError(errorResponse);
  };

  //restore
  resetStore = () => {
    runInAction(() => {
      this.setColumnNotice([]);
      this.setIsLoading(false);
      this.setResponseLoadError();
    });
  };

  //reload
  reLoadApiConfig = () => {
    this.loadConfigs();
  };
}

const NoticeRunNoticesStoreContext = createContext(new NoticeRunNoticesStore());
export const useNoticeRunNoticesStore = () => {
  return useContext(NoticeRunNoticesStoreContext);
};
