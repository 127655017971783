import { loadViewConfiguresColumns } from "@app/products/property/api";
import { getJournalSessionListDetailById } from "@app/products/property/journals/session-list/[id]/api";
import { colJournalsInPropertySession } from "@app/products/property/journals/session-list/[id]/components/child-screens/general/components/form-element/journals-in-property-session/config";
import { DTO_Session_Journals } from "@app/products/property/journals/session-list/[id]/model";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { IColumnFields } from "@components/cc-grid/model";
import { isArray } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class SessionListStore {
  private _sessionList?: DTO_Session_Journals = undefined;
  private _sessionListJournalCols: IColumnFields[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get sessionList() {
    return this._sessionList;
  }
  setSessionList = (sessionList?: DTO_Session_Journals) => {
    runInAction(() => {
      this._sessionList = sessionList;
    });
  };

  get sessionListJournalCols() {
    return this._sessionListJournalCols;
  }
  setSessionListJournalCols = (sessionListJournalCols: IColumnFields[]) => {
    runInAction(() => {
      this._sessionListJournalCols = sessionListJournalCols;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get sessionNumber() {
    return this._sessionList?.SessionNumber;
  }
  //Action
  loadSessionList = async (sessionListNumber: number) => {
    this.setIsLoading(true);
    const [SessionListDetailData, SessionListJournalCols] = await Promise.all([
      getJournalSessionListDetailById(sessionListNumber),
      loadViewConfiguresColumns(
        ViewConfiguration.Session_List_JournalSummary,
        colJournalsInPropertySession
      ),
    ]);
    let errorResponse = undefined;
    let newSessionList = undefined;
    let newSessionListJournalCols: IColumnFields[] = [];
    if (
      isSuccessResponse(SessionListDetailData) &&
      isArray(SessionListJournalCols)
    ) {
      newSessionList = SessionListDetailData.data;
      newSessionListJournalCols = SessionListJournalCols;
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setSessionList(newSessionList);
    this.setSessionListJournalCols(newSessionListJournalCols);
    this.setIsLoading(false);
  };

  reloadSessionList = async () => {
    const sessionNumber = this.sessionList?.SessionNumber;
    if (sessionNumber) await this.loadSessionList(sessionNumber);
  };

  resetStore = () => {
    runInAction(() => {
      this._sessionList = undefined;
      this._isLoading = false;
      this._sessionListJournalCols = [];
      this._responseLoadError = undefined;
    });
  };
}

const sessionListStoreContext = createContext(new SessionListStore());
export const useSessionListStore = () => {
  return useContext(sessionListStoreContext);
};
