import { CONDITION_MANAGE_ROUTE } from "@app/core/condition/[id]/constant";
import { ConditionSortIndexCell } from "@app/core/condition/condition-accordion/components/sort-index-cell/_index";
import { ConditionView } from "@app/core/condition/condition-accordion/model";
import { IInspection } from "@app/core/inspections/inspections-accordion/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
} from "@common/constants/common-format";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<ConditionView>();

export const renderConditionColumnConfig = (
  recordType: RECORDTYPE,
  adhocConditionsEnableAdvancedModeSetting: boolean
) => {
  let visibleColumns = [];
  let enableAdvancedMode = false;
  if (
    recordType === RECORDTYPE.SRU_Application ||
    recordType === RECORDTYPE.SRU_Organisation ||
    recordType === RECORDTYPE.SRU_Registration
  ) {
    enableAdvancedMode = adhocConditionsEnableAdvancedModeSetting;
  }

  let colConditions: IColumnFields[] = [
    {
      field: nameOf("ID"),
      title: "ID",
      locked: true,
      linkTo: (dataItem: IInspection) => {
        return `${CONDITION_MANAGE_ROUTE}/${dataItem?.ID}`;
      },
    },
    {
      field: nameOf("SortIndex"),
      title: "Sort",
      editable: !enableAdvancedMode,
      format: DECIMAL_FORMAT.DECIMAL_2_DIGIT,
      editCell: !enableAdvancedMode ? ConditionSortIndexCell : undefined,
      width: 120,
    },
    {
      field: nameOf("ConditionType"),
      title: "Type",
    },
    {
      field: nameOf("Theme"),
      title: "Theme",
    },
    {
      field: nameOf("ConditionCategory"),
      title: "Category",
    },
    {
      field: nameOf("Title"),
      title: "Title",
    },
    {
      field: nameOf("Condition"),
      title: "Condition",
      isGrow: true,
    },
    {
      field: nameOf("AllowPublish"),
      title: "Publish",
      format: BOOLEAN_FORMAT.BOOLEAN,
    },
    {
      field: nameOf("DateCreated"),
      title: "Created",
      format: DATE_FORMAT.DATE,
    },
    {
      field: nameOf("DateCommenced"),
      title: "Commenced",
      format: DATE_FORMAT.DATE,
    },
    {
      field: nameOf("DateDue"),
      title: "Due",
      format: DATE_FORMAT.DATE,
    },
    {
      field: nameOf("DateClosed"),
      title: "Closed",
      format: DATE_FORMAT.DATE,
    },
    {
      field: nameOf("AutoCreate"),
      title: "Auto",
      format: BOOLEAN_FORMAT.BOOLEAN,
    },
    {
      field: nameOf("Standard"),
      title: "Standard",
      format: BOOLEAN_FORMAT.BOOLEAN,
    },
  ];

  if (enableAdvancedMode) {
    visibleColumns = [
      nameOf("ID"),
      nameOf("ConditionType"),
      nameOf("Theme"),
      nameOf("ConditionCategory"),
      nameOf("Condition"),
      nameOf("AllowPublish"),
      nameOf("DateCreated"),
      nameOf("DateCommenced"),
      nameOf("DateDue"),
      nameOf("DateClosed"),
    ];
  } else {
    visibleColumns = [
      nameOf("ID"),
      nameOf("SortIndex"),
      nameOf("AutoCreate"),
      nameOf("Standard"),
      nameOf("Title"),
      nameOf("Condition"),
    ];
  }

  colConditions = colConditions.filter((col: IColumnFields) => {
    return visibleColumns.includes(col.field as keyof ConditionView);
  });

  return colConditions;
};
