import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";
import { DTO_Assessment_Rating } from "./model";

export const loadPropertyAssessmentRating = async (
  assessmentId: number,
  includeHistorical: boolean = false
): Promise<DTO_Assessment_Rating | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Assessment_Rating>(
        `/api/property/internal/assessment/${assessmentId}/rating`,
        {
          params: {
            includeHistorical,
          },
        }
      );

    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
