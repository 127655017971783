import { DTO_Assessments_DebtRecovery } from "@app/products/property/components/action-bar/dialog/modify-debt-recovery/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Assessments_DebtRecovery>();
export const colAssessmentsDebtRecovery: IColumnFields[] = [
  {
    field: nameOf("AssessmentNumber"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("Ratepayer"),
    title: "Ratepayer",
  },

  {
    field: nameOf("UnitType"),
    title: "UnitType",
  },
];
