import { eventEmitter } from "@/App";
import { history } from "@/AppRoutes";
import { UITownPlanning } from "@app/products/town-planning/model";
import {
  getApplicantTypes,
  getCouncilContacts,
  getHasOSReferral,
  getPSARSummaryById,
  putPSARValidateSave
} from "@app/products/town-planning/ppr/psa-referrals/_id/api";
import { requestToBeHeardButtonStore } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/request-to-be-heard/store";
import { sendHearingSubmissionReportButtonStore } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/send-hearing-submission-report/store";
import { sendForApprovalPSARButtonStore } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/send-rept-and-resp-letter-for-approval/store";
import { PSARCouncilContacts } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/model";
import { psarApplicationForm } from "@app/products/town-planning/ppr/psa-referrals/_id/config";
import { TOWN_PLANNING_PSAR_ROUTE } from "@app/products/town-planning/ppr/psa-referrals/_id/constant";
import {
  IPSARApplicationParentSection,
  PSARSubmitActions,
  psarSubmitWithValidateActions
} from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { postPSAR } from "@app/products/town-planning/ppr/[id]/api";
import { Application } from "@app/products/town-planning/ppr/[id]/model";
import { convertApplicationToPPRForm } from "@app/products/town-planning/ppr/[id]/util";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { DBRowState } from "@common/models/baseClassStandard";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class PSARStore {
  private _ppr?: Application = undefined;
  private _applicantTypes?: IKeyValuePacket[] = [];
  private _hasOSReferral?: boolean = false;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _parentSection?: IPSARApplicationParentSection = undefined;
  private _isInactive: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isInactive() {
    return this._isInactive;
  }
  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get applicantTypes() {
    return toJS(this._applicantTypes);
  }
  setApplicantTypes = (applicantTypes: IKeyValuePacket[]) => {
    runInAction(() => {
      this._applicantTypes = applicantTypes;
    });
  };

  get hasOSReferral() {
    return toJS(this._hasOSReferral);
  }
  setHasOSReferral = (hasOSReferral: boolean) => {
    runInAction(() => {
      this._hasOSReferral = hasOSReferral;
    });
  };

  get ppr() {
    return toJS(this._ppr);
  }
  setPPR = (ppr?: any) => {
    runInAction(() => {
      this._ppr = ppr;
    });
  };

  setPPRWithLoading = async (ppr?: Application) => {
    if (!ppr) return;
    const newPPR = (await this.convertPPR(ppr)) as any;
    runInAction(() => {
      this._isLoading = true;
      this._ppr = newPPR;
      this._isLoading = false;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: IPSARApplicationParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get pprId() {
    return toJS(this.ppr?.Application_ID);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._ppr = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._onSubmit = undefined;
      this._parentSection = undefined;
      this._applicantTypes = undefined;
    });
  };

  //Return isReloadSuccess
  reLoadPPR = async (isPSAR: boolean = false): Promise<boolean> => {
    if (this.pprId) {
      return await this.loadPPR(this.pprId, undefined, isPSAR);
    }
    return false;
  };

  loadPPR = async (
    pprId: number,
    isNew?: boolean,
    isPSAR: boolean = false
  ): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    // load LOVs data
    const applicantTypesResponse = await getApplicantTypes();
    if (isSuccessResponse(applicantTypesResponse)) {
      this.setApplicantTypes(applicantTypesResponse.data);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: applicantTypesResponse?.error ?? "Applicant types load failed",
        type: "error",
      });
    }

    if (isNew) {
      this.setPPR(psarApplicationForm);
    } else {
      const hasOSReferralResponse = await getHasOSReferral(pprId);
      if (isSuccessResponse(hasOSReferralResponse)) {
        this.setHasOSReferral(hasOSReferralResponse.data);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title:
            hasOSReferralResponse?.error ??
            "Has out standing referral load failed",
          type: "error",
        });
      }
      const response = await getPSARSummaryById(pprId, isPSAR);
      if (isSuccessResponse(response) && response?.data) {
        const newPPR = await this.convertPPR(response.data);
        this.setPPR(newPPR);
        if (response.data?.Sys_DBRowState === DBRowState.Inactive) {
          this.setIsInactive(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important note",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      if (this.parentSection?.notification) {
        this.parentSection?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setParentSection({ ...this._parentSection, notification: [] });
      }
    }

    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  convertPPR = async (ppr: Application) => {
    const resCouncilContacts = await getCouncilContacts(ppr.Application_ID);
    let newPPR = undefined;
    if (isSuccessResponse(resCouncilContacts)) {
      const councilContacts: PSARCouncilContacts[] =
        resCouncilContacts.data || [];
      newPPR = {
        ...convertApplicationToPPRForm(ppr),
        _option: {
          CouncilApplicant: councilContacts,
        },
      };
      return newPPR;
    } else {
      this.setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
  };

  savePSAR = async (pprInfo: Application, action: PSARSubmitActions) => {
    this.setIsLoading(true);
    if (psarSubmitWithValidateActions.includes(action)) {
      await this.validateSavePSAR(action, pprInfo);
      this.setIsLoading(false);
    } else {
      const response = await postPSAR(pprInfo);
      this.setIsLoading(false);

      if (isSuccessIdentityPacket(response)) {
        //Reload After save
        this.runActions(action, response);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Save PSA referral failed",
          description: response.data?.Errors ?? response.statusText,
          type: "error",
        });
      }
    }
  };

  runActions = async (action: PSARSubmitActions, response?: APIResponse) => {
    switch (action) {
      case PSARSubmitActions.Save:
        if (this.pprId) await this.loadPPR(this.pprId, undefined, true);
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Application saved successfully",
          type: "success",
        });
        break;
      case PSARSubmitActions.New:
        appNotificationStore.clearNotifications();
        history.replace(`${TOWN_PLANNING_PSAR_ROUTE}/${response?.data?.ID}`, {
          notification: [
            {
              title: "Application saved successfully",
              type: "success",
            },
          ],
        });
        break;
      default:
        break;
    }
  };

  handlePSARValidateSave = async (
    id: number,
    saveApplication: boolean,
    application: Application,
    workflow: UITownPlanning
  ) => {
    const response = await putPSARValidateSave(
      workflow,
      saveApplication,
      id,
      application
    );
    if (isSuccessIdentityPacket(response)) {
      PSARStoreInstance.setPPR(response.data?.Application);
      eventEmitter.emit(workflow.toString());
      appNotificationStore.clearErrorNotification();
    } else {
      if (response?.data?.Application) {
        PSARStoreInstance.setPPR(response?.data?.Application);
      }
      appNotificationStore.pushNotification({
        autoClose: false,
        description: response.data?.Errors ?? response.statusText,
        type: "error",
      });
    }
    this.setIsLoading(false);
  };

  validateSavePSAR = async (
    action: PSARSubmitActions,
    pprInfo?: Application
  ) => {
    switch (action) {
      case PSARSubmitActions.FiNotRequired:
        if (this.pprId && pprInfo) {
          await this.handlePSARValidateSave(
            this.pprId,
            false,
            pprInfo,
            UITownPlanning.ReferralNotRequired
          );
        }
        break;
      case PSARSubmitActions.ApproveReportAndResponse:
        if (this.pprId && pprInfo) {
          await this.handlePSARValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.ApproveReport
          );
        }
        break;
      case PSARSubmitActions.ReportAndResponseNotApproved:
        if (this.pprId && pprInfo) {
          await this.handlePSARValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.ResponseReportLetterNotApproved
          );
        }
        break;
      case PSARSubmitActions.ProponentDecision:
        if (this.pprId && pprInfo) {
          await this.handlePSARValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.PSARProponentResponse
          );
        }
        break;
      case PSARSubmitActions.Referral:
        if (this.pprId && pprInfo) {
          await this.handlePSARValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.AddMultiReferrals
          );
        }
        break;
      case PSARSubmitActions.SendReptAndRespLetterForApproval:
        if (this.pprId && pprInfo) {
          await sendForApprovalPSARButtonStore.handleSendReptAndRespLetterForApprovalWorkflow(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.SendReportResponseForApproval
          );
        }
        break;
      case PSARSubmitActions.ApplicationComplete:
        if (this.pprId && pprInfo) {
          await this.handlePSARValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.Complete
          );
        }
        break;
      case PSARSubmitActions.SendHearingSubmissionReportForApproval:
        if (this.pprId && pprInfo) {
          await sendHearingSubmissionReportButtonStore.handleWorkflow(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.SendHearingSubmissionReportForApproval
          );
        }
        break;
      case PSARSubmitActions.SentToPanelHearing:
        if (this.pprId && pprInfo) {
          await this.handlePSARValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.SendReportToPanelHearing
          );
        }
        break;
      case PSARSubmitActions.LodgeApplication:
        if (this.pprId && pprInfo) {
          await this.handlePSARValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.Lodge
          );
        }
        break;
      case PSARSubmitActions.CreateAndLodgeApplication:
        if (pprInfo) {
          eventEmitter.emit(UITownPlanning.Lodge.toString(), pprInfo);
        }
        break;
      case PSARSubmitActions.AllocateToOfficer:
        if (this.pprId && pprInfo) {
          await this.handlePSARValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.AllocateToPlanner
          );
        }
        break;
      case PSARSubmitActions.WithdrawnApplication:
        if (this.pprId && pprInfo) {
          await this.handlePSARValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.Withdraw
          );
        }
        break;
      case PSARSubmitActions.NotApprovePanelReport:
        if (this.pprId && pprInfo) {
          await this.handlePSARValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.PanelReportNotApproved
          );
        }
        break;
      case PSARSubmitActions.ApprovePanelReport:
        if (this.pprId && pprInfo) {
          await this.handlePSARValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.ApprovePanelReport
          );
        }
        break;
      case PSARSubmitActions.PanelHearingDecision:
        if (this.pprId && pprInfo) {
          await this.handlePSARValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.PanelHearingDecision
          );
        }
        break;
      case PSARSubmitActions.RequestToBeHeard:
        if (this.pprId) {
          requestToBeHeardButtonStore.requestToBeHeard(this.pprId);
        }
        break;
      case PSARSubmitActions.SendApprovedResponseReportLetterToRAAndApplicant:
        if (this.pprId && pprInfo) {
          await this.handlePSARValidateSave(
            this.pprId,
            true,
            pprInfo,
            UITownPlanning.SendApprovedResponseReportLetterToRAAndApplicant
          );
        }
        break;
      default:
        break;
    }
  };
}

export const PSARStoreInstance = new PSARStore();
const PSARStoreContext = createContext(PSARStoreInstance);
export const usePSARStore = () => {
  return useContext(PSARStoreContext);
};
