import { IAttributeData } from "@app/products/property/model";
import { useParcelAttributesUDAStore } from "@app/products/property/parcels/[id]/components/child-screens/attributes-uda/store";
import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { colParcelAttributesUDA } from "./config";

const nameOf = nameOfFactory<IAttributeData>();
export const ParcelAttributesUDA = observer(() => {
  const { parcelId } = useParcelsStore();
  const { isLoading, attributesUDA, loadAttributesUDA, resetStore } =
    useParcelAttributesUDAStore();

  useEffect(() => {
    if (parcelId) loadAttributesUDA(parcelId);
    return () => resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelId]);

  return (
    <div className="cc-parcel-attributes-uda">
      <div className="cc-grid-control-between">
        <div className="cc-grid-control-left">
          <label className="cc-label">User defined attributes</label>
        </div>
      </div>
      <CCGrid
        isLoading={isLoading}
        className="cc-attributes-uda-grid"
        data={attributesUDA ?? []}
        columnFields={colParcelAttributesUDA}
        primaryField={nameOf("Id")}
        itemPerPage={10}
      />
    </div>
  );
});
