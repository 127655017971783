export const dataSelectedAssessment = [
  { label: "Add a levy", value: "1", description: "Bulk Levy Update" },
  {
    label: "Add a levy and make inactive any other levies in category",
    value: "2",
    description: "Adding levies for selected assessments",
  },
  {
    label: "Amend in active levy",
    value: "3",
    description: "Amending levies for selected assessments",
  },
  {
    label: "Amend all active levies in category",
    value: "4",
    description: "Amending levies for selected assessments",
  },
  {
    label: "Make inactive an active levy",
    value: "5",
    description: "Inactivating levy for selected assessments",
  },
  {
    label: "Make inactive all active levies",
    value: "6",
    description: "Inactivating levies for selected assessments",
  },
];

export const listOptionHideLevy = ["4", "5", "6"];
export const listOptionIsMakeInactive = ["5", "6"];
