import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import {
  DTO_Workflow_Supplementary_Modify,
  RequestSupplementaryModifyObj,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/modify-supplementary/model";
import { DTO_Create_Supplementary_LOVs_Response } from "@app/products/property/supplementary-rates/components/form-elements/new-supplementary/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const getWorkflowModifySupplementary = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_Supplementary_Modify>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/modifysupplementary/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowModifySupplementary = async (
  requestObj: RequestSupplementaryModifyObj
): Promise<APIResponse<DTO_Workflow_Supplementary_Modify>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/modifysupplementary/new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataSupplementary =
  | [
      APIResponse<DTO_Create_Supplementary_LOVs_Response>,
      APIResponse<DTO_Workflow_Supplementary_Modify>
    ]
  | APIResponse<
      DTO_Create_Supplementary_LOVs_Response | DTO_Workflow_Supplementary_Modify
    >;

export const getInitialDataModifySupplementary = async (
  supplementaryId: number,
  workflowDraftId?: number
): Promise<IGetInitialDataSupplementary> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Create_Supplementary_LOVs_Response>(
        `/api/property/internal/Supplementary/lovs`
      ),
      !isNil(workflowDraftId)
        ? getWorkflowModifySupplementary(workflowDraftId)
        : getNewWorkflowModifySupplementary({
            SupplementaryId: supplementaryId,
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postModifySupplementary = async (
  mode: WorkflowProcessMode,
  newSupplementary: DTO_Workflow_Supplementary_Modify | undefined
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_Supplementary_Modify>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/modifysupplementary/process/${mode}`,
      newSupplementary
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
