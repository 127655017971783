import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { isSuccessResponse } from "@common/apis/util";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import {
  getAssetVersion,
  getAssetVersionHelptext,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/assets/forms/asset-integration-settings/api";
import { AssetsSystem } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/assets/model";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IAssetIntegrationSettingsProps {
  formRenderProps: FormRenderProps;
}

const AssetIntegrationSettings = ({
  formRenderProps,
}: IAssetIntegrationSettingsProps) => {
  const { isEditing, setIsLoading } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const data = useDataSetting(configData, [
    "CorporateSettings_AssetsSystem",
    "CorporateSettings_AssetsSystem_Label",
    "CorporateSettings_Assets_ConnectionMethod",
    "CorporateSettings_Assets_WorkOrder_ConnectionString",
    "CorporateSettings_Assets_URL",
    "CorporateSettings_Assets_ConnectionString",
    "CorporateSettings_AssetsSystem_UserName",
    "CorporateSettings_AssetsSystem_Password",
    "CorporateSettings_Assets_UserName",
    "CorporateSettings_Assets_Password",
    "CorporateSettings_AssetsSystem_Database",
    "CorporateSettings_Assets_AsseticSystem_WorkRequest_URL",
    "CorporateSettings_Assets_AsseticSystem_FetchComments",
    "CorporateSettings_AssetsSystem_ExternalReference",
  ]);

  const { valueGetter, onChange } = formRenderProps;
  const assetsSystem = +valueGetter(
    data.CorporateSettings_AssetsSystem?.FieldName as string
  );
  const url = valueGetter(
    data.CorporateSettings_Assets_URL?.FieldName as string
  );
  const username = valueGetter(
    data.CorporateSettings_Assets_UserName?.FieldName as string
  );
  const password = valueGetter(
    data.CorporateSettings_Assets_Password?.FieldName as string
  );

  //Local state
  const [AssetVersionHelptext, setAssetVersionHelptext] = useState<
    string | undefined
  >(undefined);
  const [isGettingAssetVersion, setIsGettingAssetVersion] =
    useState<boolean>(false);

  useEffectOnce(() => {
    setIsLoading(true);
    getAssetVersionHelptext().then((response) => {
      if (isSuccessResponse(response) && response?.data) {
        setAssetVersionHelptext(response?.data);
      }
    });
    setIsLoading(false);
  });

  const handleGetAssetVersion = () => {
    if (isEmpty(username) || isEmpty(password) || isEmpty(url)) {
      onChange("AssetVersion", {
        value: "Asset connection details must be completed.",
      });
      return;
    }
    setIsGettingAssetVersion(true);
    getAssetVersion(username, password, url).then((response) => {
      if (isSuccessResponse(response) && response?.data) {
        onChange("AssetVersion", {
          value: response?.data,
        });
      }
      setIsGettingAssetVersion(false);
    });
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <CCSearchComboBoxGlobalSettings
          data={data.CorporateSettings_AssetsSystem}
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={data.CorporateSettings_AssetsSystem_Label}
          isEditing={isEditing}
        />

        {(assetsSystem === AssetsSystem.Custom ||
          assetsSystem === AssetsSystem.AssetMaster) && (
          <CCSearchComboBoxGlobalSettings
            data={data.CorporateSettings_Assets_ConnectionMethod}
            isEditing={isEditing}
          />
        )}

        {assetsSystem === AssetsSystem.Confirm && (
          <InputGlobalSettings
            data={data.CorporateSettings_AssetsSystem_UserName}
            isEditing={isEditing}
          />
        )}

        {(assetsSystem === AssetsSystem.RAMM ||
          assetsSystem === AssetsSystem.Assetic ||
          assetsSystem === AssetsSystem.Reflect ||
          assetsSystem === AssetsSystem.Conquest) && (
          <InputGlobalSettings
            data={data.CorporateSettings_Assets_URL}
            isEditing={isEditing}
          />
        )}
      </div>

      <div className="cc-form-cols-3">
        {(assetsSystem === AssetsSystem.Custom ||
          assetsSystem === AssetsSystem.AssetMaster) && (
          <>
            <InputGlobalSettings
              data={data.CorporateSettings_Assets_WorkOrder_ConnectionString}
              isEditing={isEditing}
            />
            <InputGlobalSettings
              data={data.CorporateSettings_Assets_ConnectionString}
              isEditing={isEditing}
            />
          </>
        )}

        {assetsSystem === AssetsSystem.Confirm && (
          <>
            <InputGlobalSettings
              data={data.CorporateSettings_AssetsSystem_Password}
              isEditing={isEditing}
            />
            <div className="cc-field">
              <CCLabel title="Database ID" />
              <Field
                name={
                  data.CorporateSettings_AssetsSystem_Database
                    ?.FieldName as string
                }
                component={CCInput}
                disabled={!isEditing}
              />
            </div>
            <InputGlobalSettings
              data={data.CorporateSettings_AssetsSystem_ExternalReference}
              isEditing={isEditing}
            />
          </>
        )}

        {(assetsSystem === AssetsSystem.RAMM ||
          assetsSystem === AssetsSystem.Assetic ||
          assetsSystem === AssetsSystem.Reflect ||
          assetsSystem === AssetsSystem.Conquest) && (
          <>
            <InputGlobalSettings
              data={data.CorporateSettings_Assets_UserName}
              isEditing={isEditing}
            />
            <InputGlobalSettings
              data={data.CorporateSettings_Assets_Password}
              isEditing={isEditing}
            />
            <InputGlobalSettings
              data={data.CorporateSettings_Assets_AsseticSystem_WorkRequest_URL}
              isEditing={isEditing}
            />
          </>
        )}
      </div>

      {(assetsSystem === AssetsSystem.RAMM ||
        assetsSystem === AssetsSystem.Assetic ||
        assetsSystem === AssetsSystem.Reflect ||
        assetsSystem === AssetsSystem.Conquest) && (
        <>
          <div className="cc-form-cols-3">
            <SwitchGlobalSettings
              data={data.CorporateSettings_Assets_AsseticSystem_FetchComments}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          </div>
          <div className="cc-form-cols-3">
            <CCValueField
              name="AssetVersion"
              label="Asset version"
              tooltip={
                AssetVersionHelptext ? (
                  <CCTooltip
                    type="custom"
                    content=" "
                    position="auto"
                    clickToOpen
                    customTemplate={<>{sanitizeHtml(AssetVersionHelptext)}</>}
                  >
                    <i className="fa fa-info-circle ml-1 text-accent" />
                  </CCTooltip>
                ) : undefined
              }
              value={valueGetter("AssetVersion")}
            />
            <div className="cc-field">
              <label className="cc-label">&nbsp;</label>
              <Button
                type="button"
                disabled={isGettingAssetVersion || !isEditing}
                iconClass={
                  isGettingAssetVersion ? "fas fa-spinner fa-spin" : ""
                }
                onClick={handleGetAssetVersion}
              >
                Get Version
              </Button>
            </div>
          </div>
        </>
      )}

      {assetsSystem === AssetsSystem.RAMM && (
        <>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Database name" />
              <Field
                name={
                  data.CorporateSettings_AssetsSystem_Database
                    ?.FieldName as string
                }
                component={CCInput}
                disabled={!isEditing}
              />
            </div>
            <InputGlobalSettings
              data={data.CorporateSettings_AssetsSystem_ExternalReference}
              isEditing={isEditing}
            />
          </div>
        </>
      )}

      {assetsSystem === AssetsSystem.Reflect && (
        <>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Client key" />
              <Field
                name={
                  data.CorporateSettings_AssetsSystem_ExternalReference
                    ?.FieldName as string
                }
                component={CCInput}
                disabled={!isEditing}
              />
            </div>
          </div>
        </>
      )}

      {assetsSystem === AssetsSystem.Conquest && (
        <>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Connection name" />
              <Field
                name={
                  data.CorporateSettings_AssetsSystem_ExternalReference
                    ?.FieldName as string
                }
                component={CCInput}
                disabled={!isEditing}
              />
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default observer(AssetIntegrationSettings);
