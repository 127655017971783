import { loadPropertyAssessmentAllInterest } from "@app/products/property/assessments/[id]/components/child-screens/interest/api";
import {
  colInterest,
  colPendingInterest,
} from "@app/products/property/assessments/[id]/components/child-screens/interest/config";
import {
  addDynamicColumnsWithStandardColumns,
  processDynamicColumns,
} from "@app/products/property/assessments/[id]/components/child-screens/interest/util";
import { cloneDeep } from "lodash";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import {
  DTO_AssessmentPendingInterest,
  DTO_Assessment_Interest,
  DTO_OutStandingChargeInstalments,
} from "./model";

configure({ enforceActions: "always" });
class AssessmentInterestStore {
  private _interest?: DTO_Assessment_Interest = undefined;
  private _colPendingInterestUpdate: any[] = colPendingInterest;
  private _colInterestUpdate: any[] = colInterest;
  private _outStandingChargeInstalments?: DTO_OutStandingChargeInstalments;
  private _pendingInterests?: DTO_AssessmentPendingInterest;
  private _isLoading: boolean = false;
  private _isDetailLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get interest() {
    return this._interest;
  }

  get isLoading() {
    return this._isLoading;
  }

  get isDetailLoading() {
    return this._isDetailLoading;
  }

  get colPendingInterestUpdate() {
    return this._colPendingInterestUpdate;
  }

  get colInterestUpdate() {
    return this._colInterestUpdate;
  }

  get outStandingChargeInstalments() {
    return this._outStandingChargeInstalments;
  }
  get pendingInterests() {
    return this._pendingInterests;
  }

  setInterest = (interest?: DTO_Assessment_Interest) => {
    runInAction(() => {
      this._interest = interest;
    });
  };

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  setIsDetailLoading = (isDetailLoading: boolean) => {
    runInAction(() => {
      this._isDetailLoading = isDetailLoading;
    });
  };

  setColInterestUpdate = (colInterestUpdate: any[]) => {
    runInAction(() => {
      this._colInterestUpdate = colInterestUpdate;
    });
  };

  setColPendingInterestUpdate = (colPendingInterestUpdate: any[]) => {
    runInAction(() => {
      this._colPendingInterestUpdate = colPendingInterestUpdate;
    });
  };

  setOutStandingChargeInstalments = (
    outStandingChargeInstalments: DTO_OutStandingChargeInstalments
  ) => {
    runInAction(() => {
      this._outStandingChargeInstalments = outStandingChargeInstalments;
    });
  };
  setPendingInterests = (pendingInterests: DTO_AssessmentPendingInterest) => {
    runInAction(() => {
      this._pendingInterests = pendingInterests;
    });
  };

  //Action

  resetStore = () => {
    runInAction(() => {
      this._interest = undefined;
      this._colPendingInterestUpdate = colPendingInterest;
      this._colInterestUpdate = colInterest;
      this._outStandingChargeInstalments = undefined;
      this._pendingInterests = undefined;
      this._isLoading = false;
      this._isDetailLoading = false;
    });
  };
  loadInterests = async (
    assessmentId: number,
    onlyShowWhenInterestApplies: boolean,
    fromInterestDate: string | null,
    toDueDate: string | null
  ) => {
    if (!this.interest) {
      this.setIsLoading(true);
    } else {
      this.setIsDetailLoading(true);
    }
    const interest = await loadPropertyAssessmentAllInterest({
      assessmentId,
      onlyShowWhenInterestApplies,
      fromInterestDate,
      toDueDate,
    });

    if (
      !interest?.OutstandingChargeInstalments &&
      !interest?.PendingInterests
    ) {
      this.setIsLoading(false);
      return;
    }

    this.setOutStandingChargeInstalments(
      interest?.OutstandingChargeInstalments
    );
    this.setInterest(interest);
    this.setPendingInterests(interest?.PendingInterests);

    const dynamicCols: any = this.pendingInterests?.DynamicColumns;
    if (Array.isArray(dynamicCols) && dynamicCols.length > 0) {
      const columnsAfterProcessing = processDynamicColumns(dynamicCols);
      const standardColumn = cloneDeep(colPendingInterest);
      const newColumns = addDynamicColumnsWithStandardColumns(
        columnsAfterProcessing,
        standardColumn
      );
      this.setColPendingInterestUpdate(newColumns);
    }

    const chargeInstalmentsTotals: any =
      this.outStandingChargeInstalments?.Totals;
    const newColInterestUpdate = this.colInterestUpdate;
    for (const totalItem in chargeInstalmentsTotals) {
      newColInterestUpdate.forEach((item) => {
        if (totalItem === item.field) {
          item.calculateFooterCellValue = () =>
            chargeInstalmentsTotals[totalItem];
        }
      });
    }
    this.setColInterestUpdate(newColInterestUpdate);

    this.setIsLoading(false);
    this.setIsDetailLoading(false);
  };
}

const assessmentsInterestStoreContext = createContext(
  new AssessmentInterestStore()
);

export const useAssessmentsInterestStore = () => {
  return useContext(assessmentsInterestStoreContext);
};
