import { JournalMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/model";

export const defaultWhichJournal = {
  WhichJournal: JournalMode.CreateNew,
};

export enum DeferredDutyReverseTransactionKeysOfSteps {
  WhichJournal = "WhichJournal",
  NewJournal = "CreateJournal",
  Transactions = "Transactions",
  Comments = "Comments",
  Documents = "Documents",
}

export const deferredReverseTransactionKeysOfSendSteps = [
  DeferredDutyReverseTransactionKeysOfSteps.NewJournal,
  DeferredDutyReverseTransactionKeysOfSteps.Transactions,
];
