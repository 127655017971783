import {
  CS_IssueLocationType,
  Event,
  EventUIControl,
} from "@app/products/crms/[id]/model";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { compact } from "lodash";
import React from "react";

const LABEL_NO_LOCATION = "No Location";

export const AssetLocation = {
  byStreetNameAndSuburb({
    crmsEvent,
    uiControl,
  }: {
    crmsEvent?: Event;
    uiControl?: EventUIControl;
  }) {
    switch (crmsEvent?.IssueLocation?.LocationType_ENUM) {
      case CS_IssueLocationType.NoLocation:
        return LABEL_NO_LOCATION;
      case CS_IssueLocationType.Assets:
        return compact([
          crmsEvent?.Asset?.StreetName,
          crmsEvent?.Asset?.SuburbArea,
        ]).join(" ");
      default:
        const isPossiblyEventForm = !!uiControl;
        if (isPossiblyEventForm) {
          return <>{sanitizeHtml(uiControl.LblLocation?.Value ?? "")}</>;
        } else {
          return (
            <>
              {sanitizeHtml(
                crmsEvent?.IssueLocation?.LocationAddress
                  ?.Formatted_SingleLine ?? ""
              )}
            </>
          );
        }
    }
  },
};
