import { BuildingFieldMapping } from "@app/products/building/model";
import { STORMWATER_MANAGE_ROUTE } from "@app/products/building/registers/stormwater/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingRegistersStormwater: IColumnFields[] = [
  {
    field: BuildingFieldMapping.Register_Number,
    title: "Number",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${STORMWATER_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.Date_Lodged,
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Address,
    title: "Address",
  },
  {
    field: BuildingFieldMapping.Locality,
    title: "Locality",
  },
  {
    field: BuildingFieldMapping.Contact,
    title: "Contact",
  },
  {
    field: BuildingFieldMapping.Description,
    title: "Event",
  },
  {
    field: BuildingFieldMapping.Status,
    title: "Status",
  },
  {
    field: BuildingFieldMapping.Date_Complete,
    title: "Issued",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.LegalDescription,
    title: "Legal Description",
  },
];
