import { ePathLevel } from "@common/stores/products/enum";
import { ICCMenuItem } from "@common/stores/products/model";
import {
  getFirstChild,
  getPathByLevel,
  getPathLevel,
} from "@common/stores/products/util";
import { IDropDownItem } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/model";
import { mapTree } from "@progress/kendo-react-common";

export const convertMenuToTreeData = (
  menu: ICCMenuItem,
  parentValueName: string | undefined = "",
  secondaryDropDownLevel: ePathLevel | undefined = ePathLevel.Category2
): IDropDownItem => {
  let newValueName = menu.name;

  if (getPathLevel(menu.path) > secondaryDropDownLevel) {
    newValueName = parentValueName + " > " + menu.name;
  }
  const newDropDownItem: IDropDownItem = {
    key: menu.path ?? "",
    path: getFirstChild(menu).path,
    name: menu.name,
    valueName: newValueName,
    expanded: true,
    selected: false,
    children: menu.children?.map((child) =>
      convertMenuToTreeData(child, newValueName, secondaryDropDownLevel)
    ),
  };
  return newDropDownItem;
};

export const processTreeData = (
  data: IDropDownItem[],
  state: {
    expanded: string[];
    value: IDropDownItem | null;
  }
) => {
  const { expanded, value } = state;

  return mapTree(data, "children", (item) => {
    const props = {
      expanded: expanded.includes(item.key),
      selected: value && item.key === value.key,
    };
    return { ...item, ...props };
  });
};
export const expandedState = (item: IDropDownItem, expanded: string[]) => {
  const nextExpanded = expanded.slice();
  const itemKey = item.key;
  const index = expanded.indexOf(itemKey);
  index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);
  return nextExpanded;
};

export const getTreeValueByPathLevel = (
  data: IDropDownItem[],
  pathname: string,
  pathLevel: ePathLevel
): {
  value: IDropDownItem | null;
  expanded: string[];
} => {
  const findItem = data?.find(
    (item) => item.key === getPathByLevel(pathname, pathLevel)
  ) as IDropDownItem;
  if (findItem) {
    if (
      pathname.split("/").length === pathLevel ||
      !findItem.children ||
      !findItem.children[0]
    )
      return {
        value: findItem,
        expanded: [findItem.key],
      };
    const child = getTreeValueByPathLevel(
      findItem.children ?? [],
      pathname,
      pathLevel + 1
    );
    return {
      value: child.value,
      expanded: [findItem.key, ...child.expanded],
    };
  }
  return {
    value: null,
    expanded: [],
  };
};
