import { SaveButtonSystemAdminPermitType } from "@app/products/local-laws/system-admin/permit-types/[id]/components/buttons/save/_index";
import { PermitTypeForm } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/_index";
import { TitlePermitTypeManagePage } from "@app/products/local-laws/system-admin/permit-types/[id]/constant";
import { PermitType } from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { PermitTypeContactsTab } from "@app/products/local-laws/system-admin/permit-types/components/sidebar/contacts/_index";
import { PermitTypeDetailsTab } from "@app/products/local-laws/system-admin/permit-types/components/sidebar/details/_index";
import { PermitTypeHistoryTab } from "@app/products/local-laws/system-admin/permit-types/components/sidebar/history/_index";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const ExistManagePermitType = observer(() => {
  //#region STORE ========/
  const { currentUserInfo } = useGlobalStore();
  const { dataForms } = useFlexibleFormStore();
  //#endregion STORE =====/

  //#region STATE ========/
  const [showSlideBar, setShowSlideBar] = useState(true);
  //#endregion STATE =====/

  //#region HOOK ========/
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  //#endregion HOOK =====/

  const permitType = dataForms.GeneralForm as PermitType;

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Permit type - ${id ?? 0}`,
      Text: `${getDisplayTextWithDashes([permitType?.Name])}`,
      LinkUrl: location.pathname,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.LLPermits,
      RecordType_ENUM: RECORDTYPE.LLP_PermitType,
      Record_ID: +id ?? 0,
    },
  ];

  return (
    <>
      <FormTitle title={`${TitlePermitTypeManagePage} - ${permitType?.Name}`} />
      <CCManagePageActionBar
        leftActions={[<SaveButtonSystemAdminPermitType />]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={showSlideBar}
            onClick={() => {
              setShowSlideBar(!showSlideBar);
            }}
          />,
          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {permitType && <PermitTypeForm />}
          </div>
        </div>

        {showSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                {
                  title: "Details",
                  component: <PermitTypeDetailsTab />,
                },
                {
                  title: "Contact",
                  component: <PermitTypeContactsTab />,
                },
                {
                  title: "History",
                  component: <PermitTypeHistoryTab />,
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
});
