import { useModifyMasterPropertyDialogStore } from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/store";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    nameOf,
    formRenderProps,
    options = { isReadOnly: false },
  }: IFormStepElement) => {
    const { onChange, valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { masterPropertyLOVs } = useModifyMasterPropertyDialogStore();

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Reason for change
              <CCTooltip
                type="validator"
                content="You must have reason for change to go to the next step"
              />
            </label>
            <Field
              name={nameOf("ReasonForChange")}
              component={CCSearchComboBox}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly}
              data={masterPropertyLOVs?.ReasonForChange ?? []}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              value={getDropdownValue(
                "" + getFieldValue("ReasonForChange"),
                masterPropertyLOVs?.ReasonForChange ?? [],
                "Code"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("ReasonForChange"), {
                  value: event.target.value?.Code ?? null,
                });
              }}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Master property number
              <CCTooltip
                type="validator"
                content="You must have master property number to go to the next step"
              />
            </label>
            <Field
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              name={nameOf("MP_Assess_Number")}
              placeholder={"Assessment Number"}
              component={CCNumericTextBox}
              min={0}
              format={NUMBER_FORMAT.NUMBER2}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Property name</label>
            <Field
              name={nameOf("MP_Property_Name")}
              placeholder={"Property name"}
              component={CCInput}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Property type
              <CCTooltip
                type="validator"
                content="You must have property type to go to the next step"
              />
            </label>
            <Field
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              name={nameOf("Master_Property_Type_Id")}
              component={CCSearchComboBox}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly}
              data={masterPropertyLOVs?.PropertyType ?? []}
              value={getDropdownValue(
                "" + getFieldValue("Master_Property_Type_Id"),
                masterPropertyLOVs?.PropertyType ?? [],
                "Code"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Master_Property_Type_Id"), {
                  value: event.target.value?.Code ?? null,
                });
              }}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Property address
              <CCTooltip
                type="validator"
                content="You must have property address to go to the next step"
              />
            </label>
            <Field
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              name={nameOf("MP_Property_Address")}
              placeholder={"Property address"}
              component={CCTextArea}
              disabled={options?.isReadOnly}
              rows={3}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Locality</label>
            <Field
              name={nameOf("Locality_Id")}
              component={CCSearchComboBox}
              textField="Name"
              disabled={options?.isReadOnly}
              dataItemKey="Code"
              data={masterPropertyLOVs?.Locality ?? []}
              value={getDropdownValue(
                "" + getFieldValue("Locality_Id"),
                masterPropertyLOVs?.Locality ?? [],
                "Code"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Locality_Id"), {
                  value: event.target.value?.Code ?? null,
                });
              }}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              name={nameOf("MP_Description")}
              placeholder={"Description"}
              component={CCTextArea}
              disabled={options?.isReadOnly}
              rows={4}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Effective date</label>
            <Field
              name={nameOf("MP_Effective_Date")}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
              component={CCDatePicker}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Letters sent on</label>
            <Field
              name={nameOf("MP_Letter_Date")}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
              component={CCDatePicker}
            />
          </div>
        </div>
      </section>
    );
  }
);
