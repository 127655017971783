import { CSLUrl } from "@app/products/csl/config";
import { CSLRegisterForm } from "@app/products/csl/[id]/model";
import { IParentSection } from "@common/pages/contacts/model";
import { nameOfFactory } from "@common/utils/common";
import { FormRenderProps } from "@progress/kendo-react-form";

export const LicenceCategoryColumns = [
  { title: "Name", field: "Name" },
  { title: "Licence Code", field: "LICCode" },
  { title: "Pestcontrol", field: "PestControl" },
  { title: "Classification", field: "Classification" },
];
export const OfficerColumns = [
  { title: "Name", field: "Name" },
  { title: "Position", field: "Position" },
  { title: "Email", field: "Email" },
  { title: "Phone", field: "Phone" },
];

export const SystemColumns = [
  { title: "Manufacturer", field: "Name" },
  { title: "Name", field: "Name" },
  { title: "System Type", field: "SystemType" },
  { title: "Size", field: "Name" },
  { title: "Description", field: "Description" },
];

export const parentPage: IParentSection[] = [
  {
    key: 1,
    label: "Type",
    value: "Licence to Possess Regulation 25 Poisons – Sodium Fluoroacetate",
  },
  { key: 2, label: "Applicant", value: "CAYGILL, Vanderina" },
  { key: 3, label: "Licence number", value: "3/16" },
];

const nameOf = nameOfFactory<CSLRegisterForm>();
export const localityUsedFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Location/Site Used",
  readOnly: true,
  placeholder: "Location/Site Used",
  tableTypes: OfficerColumns,
  url: CSLUrl.GET_CSL_ISSUING_OFFICERS,
  fieldName: "LocationSiteUsed_Field",
  value: formRenderProps.valueGetter(nameOf("LocalityUsed")),
  onChange: (event: CustomEvent) => {
    formRenderProps.onChange(nameOf("LocalityUsed"), {
      value: event.detail.Position,
    });
  },
});

export const assessingOfficerFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Assessing Officer",
  readOnly: true,
  placeholder: "Assessing Officer",
  tableTypes: OfficerColumns,
  url: CSLUrl.GET_CSL_ISSUING_OFFICERS,
  fieldName: "AssessingOfficer_Field",
  value: formRenderProps.valueGetter(nameOf("assessingOfficer")),
  onChange: (event: CustomEvent) => {
    formRenderProps.onChange(nameOf("assessingOfficer"), {
      value: event.detail.Name,
    });
  },
});

export const approvingOfficerFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Approving Officer",
  readOnly: true,
  placeholder: "Approving Officer",
  tableTypes: OfficerColumns,
  url: CSLUrl.GET_CSL_ISSUING_OFFICERS,
  fieldName: "ApprovingOfficer_Field",
  value: formRenderProps.valueGetter(nameOf("approvingOfficer")),
  onChange: (event: CustomEvent) => {
    formRenderProps.onChange(nameOf("approvingOfficer"), {
      value: event.detail.Name,
    });
  },
});

export const licenceCategoryFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Licence Categories",
  readOnly: true,
  placeholder: "Licence Category",
  tableTypes: LicenceCategoryColumns,
  url: CSLUrl.GET_CSL_ISSUING_OFFICERS,
  fieldName: "LicenceCategory_Field",
  value: formRenderProps.valueGetter(nameOf("licenceCategory")),
  onChange: (event: CustomEvent) => {
    formRenderProps.onChange(nameOf("licenceCategory"), {
      value: event.detail.Name,
    });
  },
});
