import { apiCoreStateroadsGetByRoadIdNo } from "@app/core/components/common/utils";
import { getUrlSearchInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/config";
import { StateDeclaredRoad } from "@app/products/town-planning/ppr/[id]/components/input-picker/state-roads-picker-search/model";
import { StateRoad } from "@app/products/town-planning/ppr/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const searchStateRoadsOnly = async (
  searchText: string,
  colSearch: string,
  dataUrl: string
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      getUrlSearchInputPicker(searchText, colSearch, dataUrl)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getStateRoadByIdNo = async (): Promise<
  APIResponse<StateRoad & StateDeclaredRoad>
> => {
  try {
    return await CoreAPIService.getClient().get(
      apiCoreStateroadsGetByRoadIdNo()
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
