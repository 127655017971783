import { ImportSchemeAccountsDialog } from "@app/products/property/schemes/[id]/components/forms/existed/components/form-step/import-scheme-accounts/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IImportSchemeAccountsProps {
  disabled?: boolean;
  setIsUpdateSchemeAccount: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ImportSchemeAccountsButton = observer(
  ({ disabled, setIsUpdateSchemeAccount }: IImportSchemeAccountsProps) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const { pushNotification } = useCCAppNotificationStore();
    return (
      <>
        <CCNavButton
          disabled={disabled}
          title="Import scheme accounts"
          onClick={() => setShowDialog(true)}
        />
        {showDialog && (
          <ImportSchemeAccountsDialog
            onSubmit={() => {
              pushNotification({
                title: "Scheme accounts imported successfully",
                type: "success",
              });
              setIsUpdateSchemeAccount(true);
              setShowDialog(false);
            }}
            onClose={() => {
              setShowDialog(false);
            }}
          />
        )}
      </>
    );
  }
);
