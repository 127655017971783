import {
  ApplicationCategory,
  Application_Decision,
} from "@app/products/town-planning/ppr/[id]/model";
import {
  Contact,
  ContactMethodPreferred,
} from "@app/products/waste-water/[id]/model";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { isEmpty } from "lodash";

export const pprProduceContactValue = (contact?: Contact) => {
  if (!contact) return "";
  const preferredMethodEnum = contact.PreferredMethod_ENUM;

  let value = "";
  if (!isEmpty(contact?._WorkPhone)) {
    value += contact?._WorkPhone;
    value +=
      preferredMethodEnum === ContactMethodPreferred?.WorkPhone
        ? ` <b>(W)</b>`
        : ` (W)`;
  }

  if (!isEmpty(contact?._Mobile)) {
    if (!isEmpty(value)) value += `<br/>`;
    value += contact?._Mobile;
    value +=
      preferredMethodEnum === ContactMethodPreferred?.Mobile
        ? ` <b>(M)</b>`
        : ` (M)`;
  }

  if (!isEmpty(contact?.Email)) {
    if (!isEmpty(value)) value += `<br/>`;
    value += contact?.Email;
    value +=
      preferredMethodEnum === ContactMethodPreferred?.Email
        ? ` <b>(E)</b>`
        : ` (E)`;
  }

  return sanitizeHtml(value);
};

export const pprProduceRespAuthorityPostalAddressValue = (
  respAuthorityContact: Contact
) => {
  let value = "";

  if (
    respAuthorityContact?.PostalAddress &&
    respAuthorityContact?.PostalAddress?.Formatted_SingleLine
  )
    value += respAuthorityContact?.PostalAddress?.Formatted_SingleLine;

  if (!isEmpty(respAuthorityContact?.PostalAddress?.AssessmentNo))
    value += `<br/>` + respAuthorityContact?.PostalAddress?.AssessmentNo;

  return sanitizeHtml(value);
};

export const pprApplicationCategoryDisplay = (
  applicationCategory: ApplicationCategory[] | undefined
) => {
  if (!applicationCategory) {
    return "";
  }

  return applicationCategory
    ?.reduce((pre, cur) => pre + ", " + cur?.ApplicationCategory_Name, "")
    ?.slice(1)
    ?.trim();
};

export const getDecisionName = (
  type: Application_Decision | null | undefined
) => {
  if (!type) return "";

  switch (type) {
    case Application_Decision.Unspecified:
      return "Unspecified";
    case Application_Decision.RefusedByPlanning:
      return "Refused By Planning";
    case Application_Decision.PSASupported:
      return "Supported";
    case Application_Decision.PSANotSupported:
      return "Not Supported";
    case Application_Decision.PSAAdopted:
      return "Adopted";
    case Application_Decision.PSAAbandoned:
      return "Abandoned";
    case Application_Decision.PSADeferred:
      return "Deferred";
    case Application_Decision.PSAAdoptedWithChanges:
      return "Adopted with changes";

    case Application_Decision.Authorised:
      return "Authorised";
    case Application_Decision.AuthorisedWithCondition:
      return "Authorised with conditions";
    case Application_Decision.AuthorisationRefused:
      return "Authorisation refused";

    //PPR
    case Application_Decision.OBJECTION:
      return "Objection";
    case Application_Decision.NOOBJECTION:
      return "No Objection";
    case Application_Decision.NOOBJECTIONSUBJECTTOCONDITIONS:
      return "No Objection Subject To Conditions";
    case Application_Decision.VRRESPONDED:
      return "DoT Responded";

    //Assessement
    case Application_Decision.ApprovedSubjectToCondition:
      return "Approved subject to condition";
    case Application_Decision.ApprovedSubjectToStandardCondition:
      return "Approved subject to standard condition";

    //PSAR
    case Application_Decision.PanelReportRefused:
      return "Panel Report Refused";
    case Application_Decision.SentToPanelHearing:
      return "Sent To Panel Hearing";
    case Application_Decision.PSARSupportedWithChanges:
      return "Support With Changes";
    case Application_Decision.Gazetted:
      return "Gazetted";

    //Planning Permit Decision
    case Application_Decision.GrantPermit:
      return "Grant a Permit";
    case Application_Decision.GrantPermitWithConditions:
      return "Grant a Permit with Conditions";
    case Application_Decision.PermitRefused:
      return "Permit Refused";
    case Application_Decision.Withdrawn:
      return "Application Withdrawn";

    case Application_Decision.DeferredCommencement:
      return "Deferred Commencement";

    case Application_Decision.Cancelled:
      return "Application Cancelled";
    default:
      return type.toString();
  }
};
