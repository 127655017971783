import { Svc_Animals_InspectionPlanner } from "@app/products/animals/inspection-planner/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_Animals_InspectionPlanner>();

export const colAnimalsInspectionPlanner: IColumnFields[] = [
  {
    field: nameOf("Area"),
    title: "Area",
    // TODO; Implement later when it have manage page
    // linkTo: (dataItem) => {
    //   return `/` + dataItem.ID;
    // },
  },
  { field: nameOf("Address"), title: "Address" },
  {
    field: nameOf("InspectionFrequency"),
    title: "Frequency",
  },
  {
    field: nameOf("Priority"),
    title: "Priority",
  },
  { field: nameOf("Officer"), title: "Officer" },
  {
    field: nameOf("LastInspection"),
    title: "Last Inspection",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateRecorded"),
    title: "Date Recorded",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("StartDate"),
    title: "Start Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("NextInspection"),
    title: "Next Inspection",
    format: DATE_FORMAT.DATE,
  },
];
