export interface IScheduleSpecialReadings {
  PropertyAddress?: string | null;
  AccountName?: string | null;
  Reason?: string | null;
  WhenSpecialReadingDone?: Date | null;
  SearchType?: string | null;
  Assessment?: number | null;
}

export enum SearchType {
  AccountReference = "Account Reference",
  AssessmentNumber = "Assessment Number",
}
export enum SearchField {
  AccountReference = "_searchByAccountReference",
  AssessmentNumber = "_searchByAssessmentNumber",
}

export const textFieldMapping: {
  [key: string]: string;
} = {
  [SearchType.AccountReference]: SearchField.AccountReference,
  [SearchType.AssessmentNumber]: SearchField.AssessmentNumber,
};
