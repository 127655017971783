import {
  mockCrmsSetting,
  mockGlobalSetting,
} from "@app/products/crms/system-admin/[id]/components/forms/mock";

export const loadGlobalSetting = () => {
  const response: any = JSON.parse(JSON.stringify(mockGlobalSetting));
  return response;
};

export const loadCrmsSetting = () => {
  const response: any = JSON.parse(JSON.stringify(mockCrmsSetting));
  return response;
};
