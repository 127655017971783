import { PremisesBatchUpdateFormElement } from "@app/products/hm/components/batch-update/components/general/form-element/_index";
import { IPremisesBatchUpdate } from "@app/products/hm/components/batch-update/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useFlexibleFormState } from "@common/stores/flexible-form/useFlexibleFormState";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const PremisesBatchUpdateForm = observer(() => {
  //#region STORE ========/
  const { middlewareSubmitForm } = useFlexibleFormStore();
  const { setFormRef, initialDataForm } = useFlexibleFormState();
  const { pushNotificationPortal } = useNotificationPortalStore();
  //#endregion STORE =====/

  //#region METHOD ========/
  //#region setOnSubmitFlexible ========/
  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { whenNotModified, whenNotValid } = middlewareSubmitForm(event);
    whenNotValid(() => {
      pushNotificationPortal({
        title: "Please enter all required information.",
        type: "warning",
      });
    });
    whenNotModified(() => {
      pushNotificationPortal({
        title: "The form is not modified.",
        type: "warning",
      });
    });
  };
  //#endregion setOnSubmitFlexible ========/
  //#endregion METHOD =====/

  //#region INITIAL_VALUES ========/
  const formPremisesBatchUpdate = initialDataForm as IPremisesBatchUpdate;
  //#endregion INITIAL_VALUES =====/

  return (
    <div className="cc-form">
      <Form
        ref={setFormRef}
        onSubmitClick={handleOnSubmit}
        initialValues={formPremisesBatchUpdate}
        key={JSON.stringify(formPremisesBatchUpdate)}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <PremisesBatchUpdateFormElement
                  formRenderProps={formRenderProps}
                />
              }
            />
          );
        }}
      />
    </div>
  );
});
