import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useRef } from "react";

export interface INewSearchKeyDialogProps {
  onClose: () => void;
  onSubmit: (name: string) => void;
  titleDialog: string;
  isLoadingButton?: boolean;
  data?: string;
}

export const NewSearchKeyDialog = ({
  onClose,
  onSubmit,
  isLoadingButton = false,
  titleDialog = "",
  data,
}: INewSearchKeyDialogProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  return (
    <Form
      initialValues={{ Name: data }}
      onSubmitClick={(event: FormSubmitClickEvent) =>
        onSubmit(event?.values?.Name)
      }
      render={(formRenderProps: FormRenderProps) => {
        return (
          <CCDialog
            titleHeader={titleDialog}
            height={"auto"}
            maxWidth={"25%"}
            onClose={onClose}
            disabled={isLoadingButton}
            bodyElement={
              <FormElement className="cc-form">
                <CCLocalNotification ref={notificationRef} />
                <section className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <CCLabel title="Search key" isMandatory />
                      <Field
                        name={"Name"}
                        component={CCInput}
                        validator={requiredValidator}
                      />
                    </div>
                  </div>
                </section>
              </FormElement>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  themeColor="primary"
                  className={"cc-dialog-button"}
                  disabled={!formRenderProps.valid}
                  type={"submit"}
                  onClick={formRenderProps.onSubmit}
                  iconClass={isLoadingButton ? "fas fa-spinner fa-spin" : ""}
                >
                  Save
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
