import { TownPlanningApiUrl } from "@app/products/town-planning/config";
import { colOfficerPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/config";
import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { nameOfFactory } from "@common/utils/common";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React from "react";

const nameOf = nameOfFactory<IOfficer>();

export interface IOfficerPickerProps extends ICCInputPickerProps {
  onPickOfficer: (value: IOfficer | null, name: string) => void;
  title?: string;
  selectableMode?: "single" | "multiple" | "none";
}
export const OfficerPicker = (props: IOfficerPickerProps) => {
  const {
    title,
    selectableMode,
    onChange,
    onError,
    onPickOfficer,
    name,
    textField,
    textProduce,
    valueField,
    valueProduce,
    grid,
    ...others
  } = props;

  const handleOnChangeOfficer = (event: ICCInputPickerChangeEvent) => {
    const value: IOfficer | null = event.value;
    if (onPickOfficer) onPickOfficer(value, name ?? "");
  };

  return (
    <CCInputPicker
      {...others}
      dialog={{
        titleHeader: title ?? "Pick Officer",
        maxWidth: "60%",
      }}
      grid={{
        columnFields: colOfficerPicker,
        primaryField: nameOf("ID"),
        selectableMode: selectableMode ?? "single",
        dataUrl: TownPlanningApiUrl.GET_PLANNERS_TOWNPLANNING,
        isAutoHiddenPager: false,
        state: { sort: [{ field: nameOf("Name"), dir: "asc" }] },
        itemPerPage: 10,
        ...grid,
      }}
      onChange={handleOnChangeOfficer}
      valueField={valueField}
      valueProduce={valueProduce}
      textField={textField}
      textProduce={textProduce}
    />
  );
};
