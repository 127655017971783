import { eventEmitter } from "@/App";
import { Attachment } from "@app/core/documents/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { isSuccessResponse } from "@common/apis/util";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { importStateRoads } from "@common/pages/settings/lookups/state-roads/components/action-bar/buttons/import-state-roads/api";
import { ImportStateRoadsDialog } from "@common/pages/settings/lookups/state-roads/components/action-bar/dialogs/import-state-roads/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface ImportStateRoadsButtonProps {
  disabled?: boolean;
}
export const ImportStateRoadsButton = observer(
  ({ disabled }: ImportStateRoadsButtonProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const [isShowDialog, setIsShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (
      data: Attachment,
      notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
    ) => {
      setIsLoading(true);
      const response = await importStateRoads(data);
      if (isSuccessResponse(response)) {
        if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
          pushNotification({
            title: "State roads imported successfully ",
            type: "success",
          });
          setIsShowDialog(false);
          eventEmitter.emit(CCGridEventType.RefreshOData);
          eventEmitter.emit(CCJournalEventType.RefreshData);
        } else {
          notificationRef?.current?.pushNotification({
            autoClose: false,
            title: response.data?.Errors,
            type: "error",
          });
        }
      } else {
        notificationRef?.current?.pushNotification({
          autoClose: false,
          title: response.data?.Errors ?? response.error,
          type: "error",
        });
      }
      setIsLoading(false);
    };
    return (
      <>
        <CCNavButton
          title="Import State Roads"
          onClick={() => setIsShowDialog(true)}
          disabled={disabled}
        />
        {isShowDialog && (
          <ImportStateRoadsDialog
            onClose={() => setIsShowDialog(false)}
            onSubmit={onSubmit}
            isLoadingButton={isLoading}
          />
        )}
      </>
    );
  }
);
