import { CreateRegisterDialog } from "@app/products/property/registers/list/components/action-bar/form-steps/create-register/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { TYPE_NOTIFICATION } from "@components/cc-notification/comp-notification/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const CreateRegisterButton = observer(() => {
  const [showCreateRegister, setShowCreateRegister] = useState<boolean>(false);
  const { pushNotifications } = useCCProductListViewStore();
  return (
    <>
      <CCNavButton
        title="Create Account "
        onClick={() => setShowCreateRegister(true)}
      />
      {showCreateRegister && (
        <CreateRegisterDialog
          onSubmit={() => {
            setShowCreateRegister(false);
            pushNotifications([
              {
                primaryContent: "Create Register Account Successfully.",
                type: TYPE_NOTIFICATION.SUCCESS,
                isAutoClose: true,
              },
            ]);
          }}
          onClose={() => {
            setShowCreateRegister(false);
          }}
        />
      )}
    </>
  );
});
