import { PermitContactsTab } from "@app/products/local-laws/components/sidebar/contacts/_index";
import { PermitDetailsTab } from "@app/products/local-laws/components/sidebar/details/_index";
import { PermitHistoryTab } from "@app/products/local-laws/components/sidebar/history/_index";
import { LocalLawsActionBarNavDropdown } from "@app/products/local-laws/components/toolbar/nav-dropdown/_index";
import { llsPermitsRoute } from "@app/products/local-laws/lls-permits/route";
import { localLawsRoute } from "@app/products/local-laws/route";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { colLocalLawsPermitsRegister } from "./config";

export default () => {
  useCCListViewActionBar({
    title: localLawsRoute.name,
    leftComponents: [
      <LocalLawsActionBarNavDropdown category={llsPermitsRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <PermitDetailsTab />,
      },
      { title: "Contacts", component: <PermitContactsTab /> },
      { title: "History", component: <PermitHistoryTab /> },
    ],
  });

  return (
    <CCProductListView
      data={[]}
      columnFields={colLocalLawsPermitsRegister}
      primaryField={"ID"}
    />
  );
};
