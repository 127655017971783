import { ICCRoute } from "@common/constants/ICCRoute";

export const contactDuplicatesRoute: ICCRoute = {
  path: "duplicates",
  name: "Duplicates",
  children: [
    {
      path: "list",
      name: "List",
      component: require("./list/_index").default,
    },
  ],
};
