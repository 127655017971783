import { getSharePointDocumentLibrary } from "@app/core/sharepoint/document-libraries/api";
import { getSharepointDocumentLibraryInputPickerOption } from "@app/core/sharepoint/document-libraries/config";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { Svc_SharepointDocumentLibrary } from "@common/models/corporateSetting";
import { nameOfFactory } from "@common/utils/common";
import React from "react";

interface ITrimContainerProps {
  value: any;
  siteCollectionURL?: string;
  electronicOnly?: boolean;
}

export const SharePointDocumentLibraryInputPicker = (
  props: ITrimContainerProps
) => {
  const { value, siteCollectionURL = "", ...others } = props;
  const nameOf = nameOfFactory<Svc_SharepointDocumentLibrary>();

  return (
    <InputPickerSearch
      nameDisplayMerge={nameOf("ID")}
      options={getSharepointDocumentLibraryInputPickerOption(undefined, {
        selectableMode: "single",
      })}
      value={value}
      localSearchOptions={{
        enableLocalSearch: true,
        searchingPropKey: nameOf("Title"),
      }}
      customGetData={async () =>
        await getSharePointDocumentLibrary(siteCollectionURL)
      }
      showClearButton={true}
      {...others}
    />
  );
};
