import { eventEmitter } from "@/App";
import {
  postBatchRenew,
  postCalculateRenewalDates,
} from "@app/products/animals/renewals/components/button/batch-renew/api";
import {
  AnimalsRenewalsBatchRenewRequest,
  PreRenewalAnimalsDetails,
} from "@app/products/animals/renewals/components/button/batch-renew/model";
import { AnimalsDetailListDialog } from "@app/products/animals/renewals/components/dialog/animals-details-list/_index";
import { AnimalsReceiptBoxDialog } from "@app/products/animals/renewals/components/dialog/receipt-dialog-box/_index";
import { APIResponse } from "@common/apis/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { DateToFromDialog } from "@common/dialog/date-to-from/_index";
import { DateFromDateTo } from "@common/dialog/date-to-from/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const AnimalsRenewalBatchRenew = observer(() => {
  const { gridSelectedRows, gridSelectedIds, clearSelectedItems } =
    useCCProductListViewStore();
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();
  const { settings } = useCommonCoreStore();
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [showAnimalLisDetailDialog, setShowAnimalLisDetailDialog] =
    useState(false);
  const [showDateFromDateToDialog, setShowDateFromDateToDialog] =
    useState(false);
  const [showReceiptDialog, setShowReceiptDialog] = useState<boolean>(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
  const [dataRenew, setDataRenew] = useState<DateFromDateTo>();

  const animalsEnableQuickEntryBatchRenew = getBoolValueSetting(
    settings[ECorporateSettingsField.Animals_EnableQuickEntryBatchRenew]
  );
  const animalsRenewalMethod = getNumberValueSetting(
    settings[ECorporateSettingsField.Animals_RenewalMethod]
  );
  const animalsEnableBatchRenewRecieptDialogBox = getBoolValueSetting(
    settings[ECorporateSettingsField.Animals_EnableBatchRenewRecieptDialogBox]
  );

  const handleOnClick = async () => {
    if (!gridSelectedRows || isEmpty(gridSelectedRows)) return;
    if (animalsEnableQuickEntryBatchRenew) {
      setShowAnimalLisDetailDialog(true);
    } else {
      setIsLoadingButton(true);
      setDataRenew(undefined);
      //Call Api Calculate Renewal Date to get initial data for dialog
      const response = await postCalculateRenewalDates(gridSelectedIds);
      setIsLoadingButton(false);
      if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
        setDataRenew(response.data.ReturnObj);
        setShowDateFromDateToDialog(true);
      } else {
        clearErrorNotification();
        pushNotification({
          autoClose: false,
          type: "error",
          title: "Calculate renewal dates failed.",
          description: response.error ?? response.statusText,
        });
      }
    }
  };

  const handleSubmitDateFromDateToDialog = async (
    value: DateFromDateTo,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    setIsLoadingDialog(true);
    const bodyRequest = {
      RegistrationIDs: gridSelectedIds,
      DateFromDateTo: value,
      UpdatePreRenewalAnimalsDetails: [],
      GenerateFee: false,
    } as AnimalsRenewalsBatchRenewRequest;
    const response = await postBatchRenew(bodyRequest, false);
    setIsLoadingDialog(false);
    if (isSuccessIdentityPacket(response)) {
      setShowDateFromDateToDialog(false);
      if (animalsEnableBatchRenewRecieptDialogBox) {
        setShowReceiptDialog(true);
      } else {
        handleSuccessProcess();
      }
    } else {
      const responseError = response as APIResponse;
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Batch renew failed.",
        type: "error",
        description: responseError.data?.Errors ?? response?.statusText,
      });
    }
  };

  const handleOnSubmitAnimalsDetailListDialog = async (
    value: PreRenewalAnimalsDetails[],
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    setIsLoadingDialog(true);
    const bodyRequest = {
      RegistrationIDs: gridSelectedIds,
      DateFromDateTo: null,
      UpdatePreRenewalAnimalsDetails: value,
      GenerateFee: false,
    } as AnimalsRenewalsBatchRenewRequest;
    const response = await postBatchRenew(bodyRequest, true);
    setIsLoadingDialog(false);
    if (isSuccessIdentityPacket(response)) {
      setShowAnimalLisDetailDialog(false);
      if (animalsEnableBatchRenewRecieptDialogBox) {
        setShowReceiptDialog(true);
      } else {
        handleSuccessProcess();
      }
    } else {
      const responseError = response as APIResponse;
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Updated registration failed.",
        type: "error",
        description: responseError.data?.Errors ?? response?.statusText,
      });
    }
  };

  const handleSuccessProcess = () => {
    pushNotification({
      type: "success",
      title: "Batch renew successfully.",
    });
    clearSelectedItems();
    eventEmitter.emit(CCGridEventType.RefreshOData);
  };

  return (
    <>
      <CCNavButton
        title="Batch Renew"
        onClick={handleOnClick}
        isLoading={isLoadingButton}
        disabled={isLoadingButton || isEmpty(gridSelectedIds)}
      />

      {showAnimalLisDetailDialog && (
        <AnimalsDetailListDialog
          onClose={() => setShowAnimalLisDetailDialog(false)}
          onSubmit={(
            value: PreRenewalAnimalsDetails[],
            notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
          ) => handleOnSubmitAnimalsDetailListDialog(value, notificationRef)}
          isLoading={isLoadingDialog}
          animalRegisterIDs={gridSelectedIds}
          renewalMethod={animalsRenewalMethod}
        />
      )}
      {showDateFromDateToDialog && (
        <DateToFromDialog
          onClose={() => setShowDateFromDateToDialog(false)}
          onSubmit={(
            value: DateFromDateTo,
            notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
          ) => handleSubmitDateFromDateToDialog(value, notificationRef)}
          isLoading={isLoadingDialog}
          data={dataRenew}
        />
      )}
      {showReceiptDialog && (
        <AnimalsReceiptBoxDialog
          onClose={() => {
            setShowReceiptDialog(false);
            handleSuccessProcess();
          }}
          animalRegisterIDs={gridSelectedIds}
          titleDialog="Animal Renewal Details"
        />
      )}
    </>
  );
});
