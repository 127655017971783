import { DTO_Certificate_Detail_LOVs } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/certificate-details/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class CertificateDetailStepStore {
  private _certificateDetailStepLOVs?: DTO_Certificate_Detail_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get certificateDetailStepLOVs() {
    return toJS(this._certificateDetailStepLOVs);
  }
  setCertificateDetailStepLOVs = (data?: DTO_Certificate_Detail_LOVs) => {
    runInAction(() => {
      this._certificateDetailStepLOVs = data;
    });
  };
}

const certificateDetailStepStoreContext = createContext(
  new CertificateDetailStepStore()
);
export const useCertificateDetailStepStore = () => {
  return useContext(certificateDetailStepStoreContext);
};
