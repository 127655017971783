import { DTO_Contact } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { Collection } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { DTO_Assessment } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { DTO_Title } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import {
  DTO_Request_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";
import { DTO_PIC_Swine } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfStepsReactivatePIC {
  PICID = "PIC_Id",
  Association = "Association",
  PICDetail = "PICDetails",
  Detail = "Details",
  Contact = "Contact",
  Collection = "SelectedCollection",
  Comment = "Comments",
  Document = "Documents",
  Workflow = "Workflow",
}

export enum ELabelReactivatePIC {
  Related = "Related",
  PICDetail = "PIC details",
  Detail = "Details",
  Collection = "Collections",
  Comment = "Comments",
  Document = "Documents",
  Workflow = "Workflow",
}

export enum EPICStatus {
  Inactive = "Inactive",
}

export const keysOfStepsReactivatePIC = [
  EKeysOfStepsReactivatePIC.PICID,
  EKeysOfStepsReactivatePIC.Association,
  EKeysOfStepsReactivatePIC.PICDetail,
  EKeysOfStepsReactivatePIC.Contact,
  EKeysOfStepsReactivatePIC.Detail,
  EKeysOfStepsReactivatePIC.Collection,
];

export interface DTO_Workflow_PIC_Reactivate {
  WorkflowDetail: DTO_WorkflowDetail_PIC_Reactivate;
  WorkflowHeader: DTO_WorkflowHeader;
}

export interface DTO_WorkflowDetail_PIC_Reactivate {
  PIC_Id: number | null;
  Details: DTO_PIC_Detail;
  Association: DTO_PIC_ReactivateAssociation;
  PICDetails: DTO_PIC_ReactivateDetails;
  SelectedCollection: Collection[];
  Contact: DTO_Contact;
  LOVs: DTO_PIC_ReactivateLOVs;
  // TODO: Recheck when have API
  SelectedSecondaryWorkflow: number[];
}

export interface DTO_PIC_ReactivateLOVs {
  District: DTO_LOV[];
}

export interface DTO_PIC_Detail {
  ApplicantName: string;
  ReasonId: number;
  Reference: string;
  PICStatus: number;
}
export interface DTO_PIC_ReactivateAssociation {
  AssociationDate: Date;
  PrimaryHolding?: number;
  Holdings: DTO_Assessment[];
  Folios: DTO_Title[];
}
export interface DTO_PIC_ReactivateDetails {
  Effective_From_Date: Date | null;
  Effective_To_Date: Date | null;
  Centroid_Lng: number | null;
  Centroid_Lat: number | null;
  GISLandArea: number | null;
  GISLandAreaUnitCode: string;
  LLSProperty_Ref_No: string;
  Status: string;
  PrimaryLandUseId: number | null;
  PrimaryLandUse: string;
  SecondaryLandUseId: number | null;
  SecondaryLandUse: string;
  PICDescription: string;
  QuarantineStatus: string;
  ResidueStatus: string;
  Created_On: Date | null;
  GisReferences: DTO_GIS[];
  GIS_GlobalID: string;
  Postcode: string;
  StateCode: string;
  PropertyLocality: string;
  PICNumber: string;
  PICTypeId: number;
  PICTypeName: string;
  PICLandUseId: number | null;
  PICLandUseName: string;
  AgentLicenseNumber: string;
  RLPBoardId: number | null;
  RLPBoardName: string;
  Lot_GIS_Reference: string[];
  DistrictId: number | null;
  Occupier: string;
  TradingNameId: number | null;
  TradingName: string;
  PrimaryHolding: string;
  PrimaryHoldingId: number | null;
  PrimaryHoldingNumber: string;
  PropertyName: string;
  PropertyAddress: string;
  DistrictName: string;
  Swines: DTO_PIC_Swine[];
}

export interface RequestPICReactivateObj extends DTO_Request_Base {
  PIC_Id: number;
}
