import { TownPlanningActionBarNavDropdown } from "@app/products/town-planning/components/action-bar/nav-dropdown/_index";
import { townPlanningRoute } from "@app/products/town-planning/route";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { NoData } from "@components/no-data/NoData";
import React from "react";
import { appealsRoute } from "../route";

export default () => {
  useCCListViewActionBar({
    title: townPlanningRoute.name,
    leftComponents: [
      <TownPlanningActionBarNavDropdown category={appealsRoute.path} />,
    ],
    centerComponents: [],
    rightComponents: [<ActionBarExportIcon />, <ActionBarFilterIcon />],
  });

  return <NoData />;
};
