import { useIsNew } from "@common/hooks/useIsNew";
import React from "react";
import { ExistedBuildingRegistersCertificates } from "./components/forms/existed/_index";
import { NewBuildingRegistersCertificates } from "./components/forms/new/_index";

const ManageBuildingRegistersCertificates = () => {
  const isNew = useIsNew();

  if (isNew) {
    return <NewBuildingRegistersCertificates />;
  }

  return <ExistedBuildingRegistersCertificates />;
};

export default ManageBuildingRegistersCertificates;
