import { getSharePointContentTypes } from "@app/core/sharepoint/content-types/api";
import { getSharepointContentTypeInputPickerOption } from "@app/core/sharepoint/content-types/config";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { Svc_SharepointContentType } from "@common/models/corporateSetting";
import { nameOfFactory } from "@common/utils/common";
import React from "react";

interface ITrimContainerProps {
  value: any;
  siteCollectionURL?: string;
}

export const SharePointContentTypesInputPicker = (
  props: ITrimContainerProps
) => {
  const { value, siteCollectionURL = "", ...others } = props;
  const nameOfSharepointContentType =
    nameOfFactory<Svc_SharepointContentType>();

  return (
    <InputPickerSearch
      nameDisplayMerge={nameOfSharepointContentType("ID")}
      options={getSharepointContentTypeInputPickerOption(undefined, {
        selectableMode: "single",
      })}
      value={value}
      localSearchOptions={{
        enableLocalSearch: true,
        searchingPropKey: nameOfSharepointContentType("Title"),
      }}
      customGetData={async () =>
        await getSharePointContentTypes(siteCollectionURL)
      }
      showClearButton={true}
      {...others}
    />
  );
};
