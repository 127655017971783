import { IBuildingRegistersEnquiryFormData } from "@app/products/building/registers/enquiries/[id]/model";
import { mockSingleBuildingRegistersSitingApprovalsJSON } from "@app/products/building/registers/siting-approvals/[id]/mock";
import { IBuilding } from "@app/products/building/[id]/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";

export const postBuildingRegistersSitingApprovals = async (
  requestBody: IBuilding
) => {
  await sleep(2000);
};

export const putBuildingRegistersSitingApprovals = async (
  requestBody: IBuilding
) => {
  await sleep(2000);
};

export const getBuildingRegistersSitingApprovalsById = async (
  id: number
): Promise<APIResponse<IBuildingRegistersEnquiryFormData | undefined>> => {
  await sleep(1000);
  return {
    data: {
      ...mockSingleBuildingRegistersSitingApprovalsJSON,
      ID: id,
    } as IBuildingRegistersEnquiryFormData,
    status: APIResponseStatus.SUCCESS,
    statusText: "Ok",
    headers: {},
    config: {},
  };
};
