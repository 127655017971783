import { Ancestor } from "@app/core/further-info/[id]/model";
import { useInvoiceStore } from "@app/core/invoice/[id]/store";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { RECORDTYPE } from "@common/constants/recordtype";
import { mapEnum } from "@common/utils/common";
import { formatDisplayValue } from "@common/utils/formatting";
import { getPageLinkByRecordType } from "@common/utils/getPageLinkByRecordType";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const getBubbleUpValue = (
  bubbleUpObj: BubbleUpIdentifier,
  ancestor: Ancestor | undefined
) => {
  return (
    <Link
      className="cc-detail-tab-link"
      to={{
        pathname: getPageLinkByRecordType(
          mapEnum(
            bubbleUpObj.SourceIdentifier?._RecordSourceType_ENUM,
            RECORDTYPE
          ),
          bubbleUpObj.SourceIdentifier?._RecordSource_ID
        ),
      }}
    >
      {ancestor?.LinkText_Ancestor ?? ancestor?.linktext}
    </Link>
  );
};

export const InvoiceDetailsTab = observer(() => {
  const { lastSelectedId } = useCCProductListViewStore();
  const {
    loadInvoiceDetails,
    isLoadingDetails,
    invoice,
    parentBubbleUpDetails,
    ancestor,
    responseLoadError,
  } = useInvoiceStore();

  const params: { id: string } = useParams();
  const invoiceId = params.id ?? lastSelectedId;
  const isCRMS =
    mapEnum(
      parentBubbleUpDetails?.SourceIdentifier?._RecordSourceType_ENUM,
      RECORDTYPE
    ) === RECORDTYPE.CUSTOMERSERVICE_Event;
  useEffect(() => {
    if (invoiceId) {
      loadInvoiceDetails(parseInt(invoiceId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  if (isLoadingDetails) return <Loading isLoading={isLoadingDetails} />;
  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          invoiceId && loadInvoiceDetails(+invoiceId);
        }}
      />
    );
  }
  if (!invoice) return <NoData />;
  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Invoice number:"}
        value={invoice.InvoiceNumber}
        primary
      />
      <ProductReferenceBlock>
        {invoice.InvoiceAmount ? (
          <ProductReferenceRow
            title={"Invoice amount:"}
            value={formatDisplayValue(
              invoice.InvoiceAmount,
              CURRENCY_FORMAT.CURRENCY1
            )}
          />
        ) : null}
        {invoice.InvoiceDate ? (
          <ProductReferenceRow
            title={"Invoice date:"}
            value={formatDisplayValue(invoice.InvoiceDate, DATE_FORMAT.DATE)}
          />
        ) : null}
        {invoice.InvoiceActiveDate ? (
          <ProductReferenceRow
            title={"Active date:"}
            value={formatDisplayValue(
              invoice.InvoiceActiveDate,
              DATE_FORMAT.DATE
            )}
          />
        ) : null}
        {invoice.InvoiceDueDate ? (
          <ProductReferenceRow
            title={"Due date:"}
            value={formatDisplayValue(invoice.InvoiceDueDate, DATE_FORMAT.DATE)}
          />
        ) : null}
        <ProductReferenceRow
          title={"Recorded by:"}
          value={invoice.Sys_CreatedBy}
        />
        <ProductReferenceRow
          title={"Recorded on:"}
          value={formatDisplayValue(invoice.Sys_CreatedDate, DATE_FORMAT.DATE)}
        />
      </ProductReferenceBlock>
      {parentBubbleUpDetails ? (
        <>
          <hr />
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={isCRMS ? "Event:" : "Parent:"}
              value={getBubbleUpValue(parentBubbleUpDetails, ancestor)}
            />
          </ProductReferenceBlock>
        </>
      ) : null}
    </ProductReferenceBody>
  );
});
