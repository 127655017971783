import { CORE_INSPECTION_TYPES_ODATA } from "@app/core/components/common/constant";
import {
  IContactManager,
  IOrgUnit,
} from "@app/products/crms/knowledge-base/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { ICRMSKnowledgeBaseSummary } from "@app/products/crms/knowledge-base/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { FormRenderProps } from "@progress/kendo-react-form";

const nameOf = nameOfFactory<ICRMSKnowledgeBaseSummary>();
const nameOfOrgUnit = nameOfFactory<IOrgUnit>();
const nameOfContactManager = nameOfFactory<IContactManager>();

export const colOrgUnit: IColumnFields[] = [
  //TODO temp columns need remove after mock
  { title: "Hierarchy", field: nameOfOrgUnit("Name") },
  { title: "Org Level", field: nameOfOrgUnit("OrgLevel") },
  { title: "Org Unit Name", field: nameOfOrgUnit("OrgUnitName") },
  { title: "Manager", field: nameOfOrgUnit("Manager") },
];

export const colContactManager: IColumnFields[] = [
  { title: "Name", field: nameOfContactManager("Name") },
  { title: "Position", field: nameOfContactManager("Position") },
  {
    title: "Org Structure",
    field: nameOfContactManager("ORGStructure"),
  },
  { title: "Email", field: nameOfContactManager("Email") },
  { title: "Work Phone", field: nameOfContactManager("WorkPhone") },
  { title: "Home Phone", field: nameOfContactManager("HomePhone") },
  { title: "Mobile", field: nameOfContactManager("Mobile") },
];

export const orgStructureFields = (
  props: FormRenderProps,
  isClearSelection: boolean
) => ({
  Id: "0",
  isMultiSelect: false,
  isClearSelection: isClearSelection,
  titleHeader: "Pick Org Units",
  placeholder: "Org Structure",
  tableTypes: colOrgUnit,
  url: CORE_INSPECTION_TYPES_ODATA,
  disabled: true,
  fieldName: nameOf("OrgStructure"),
  value: props.valueGetter(nameOf("OrgStructure")),
  onChange: (event: any) => {
    if (event.target !== null) {
      props.onChange("", {
        value: event.target.value,
      });
    } else {
      props.onChange(nameOf("OrgStructure"), {
        value: event.detail.Name,
      });
    }
  },
});

export const reviewerFields = (props: FormRenderProps) => ({
  Id: "1",
  isMultiSelect: false,
  titleHeader: "Contact Manager",
  placeholder: "Pick Reviewer",
  tableTypes: colContactManager,
  url: CORE_INSPECTION_TYPES_ODATA,
  disabled: true,
  fieldName: nameOf("Reviewer"),
  value: props.valueGetter(nameOf("Reviewer")),
  onChange: (event: any) => {
    if (event.target !== null) {
      props.onChange("", {
        value: event.target.value,
      });
    } else {
      props.onChange(nameOf("Reviewer"), {
        value: event.detail.Name,
      });
    }
  },
});

export const managerFields = (props: FormRenderProps) => ({
  Id: "2",
  isMultiSelect: false,
  titleHeader: "Contact Manager",
  placeholder: "Pick Manager",
  tableTypes: colContactManager,
  url: CORE_INSPECTION_TYPES_ODATA,
  disabled: true,
  fieldName: nameOf("Manager"),
  value: props.valueGetter(nameOf("Manager")),
  onChange: (event: any) => {
    if (event.target !== null) {
      props.onChange("", {
        value: event.target.value,
      });
    } else {
      props.onChange(nameOf("Manager"), {
        value: event.detail.Name,
      });
    }
  },
});

export const contactFields = (props: FormRenderProps) => ({
  Id: "3",
  isMultiSelect: false,
  titleHeader: "Contact Manager",
  placeholder: "Pick Contact",
  tableTypes: colContactManager,
  url: CORE_INSPECTION_TYPES_ODATA,
  disabled: true,
  fieldName: nameOf("Contact"),
  value: props.valueGetter(nameOf("Contact")),
  onChange: (event: any) => {
    if (event.target !== null) {
      props.onChange("", {
        value: event.target.value,
      });
    } else {
      props.onChange(nameOf("Contact"), {
        value: event.detail.Name,
      });
    }
  },
});
