import { getFromDate } from "@app/products/ams/api/helpers/date-helper";
import { EAssessmentError } from "@app/products/property/assessments/components/constant";
import {
  DTO_ModifyAssessment_Parcel,
  DTO_Title,
} from "@app/products/property/assessments/components/form-steps/modify-assessment/components/form-elements/associations-parcel/model";
import { DTO_Address } from "@app/products/property/assessments/components/form-steps/modify-assessment/model";
import { isBefore } from "date-fns";
import { isNil, isNumber } from "lodash";

export const checkNumberOnlyValidator = (value: any) => {
  if (isNil(value) || value === "") return;
  const message = "This field only accepts numerical values";
  const regexOnlyNumber = /^\d+$/;

  if (!regexOnlyNumber.test(value)) return message;
  return;
};

export const validatorPhysicalAddress = (value: any): string | undefined => {
  if (!value?.length) {
    return "Primary address needs to be set";
  }
  const isContainPrimary = value?.some((item: DTO_Address) => {
    return item?.IsAddressPrimary;
  });
  if (!isContainPrimary) {
    return "Primary address needs to be set";
  }
  return undefined;
};

export const lotsValidator = (value: any): string | undefined => {
  if (value?.AssociateTitles?.length) {
    let isError = false;
    isError = value?.AssociateTitles?.some((item: DTO_Title) => {
      return (
        !isNil(item?.Active_From) &&
        !isNil(item?.Active_To) &&
        isBefore(getFromDate(item.Active_To), getFromDate(item.Active_From))
      );
    });
    if (isError) {
      return `"Active To" must be greater than or equal to "Active From"`;
    }
    return undefined;
  }
};

const hasNoParcels = (
  parcels: DTO_ModifyAssessment_Parcel[] | undefined
): boolean => {
  return isNil(parcels) || parcels?.length === 0;
};

const isInvalidParcelAreaSet = (
  parcel: DTO_ModifyAssessment_Parcel | undefined
): boolean => {
  const areaSet = parcel?.AssociatedAssessmentAreaSet;
  return isNumber(areaSet) && (areaSet <= 0 || areaSet > 100);
};

export const validateParcel = (value: any): string | undefined => {
  const parcel = value?.AssociateParcels;
  if (hasNoParcels(parcel)) {
    return EAssessmentError.ValidateExistParcel;
  }
  if (parcel?.some(isInvalidParcelAreaSet)) {
    return EAssessmentError.ValidateFactorPercentage;
  }
  return undefined;
};
export const ASSESSMENT_ROUTE = "/property/assessments";
