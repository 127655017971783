import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import "./SidebarContainerAutoTabPage.scss";

export const SidebarContainer: React.FC = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="sidebar-container-autotabpage">
      <TabStrip
        selected={selectedTab}
        onSelect={(e) => {
          setSelectedTab(e.selected);
        }}
      >
        {React.Children.map(children, (child) => (
          <TabStripTab title={"Details"}>{child}</TabStripTab>
        ))}
      </TabStrip>
    </div>
  );
};

export default observer(SidebarContainer);
