import { AssetDetailTab } from "@app/products/ams/assets/components/reference-sidebar/detail/_index";
import { AssetManagerForm } from "@app/products/ams/assets/[id]/child-screen/general/_index";
import { useAssetManagerStore } from "@app/products/ams/assets/[id]/store";
import { AmsRoute } from "@app/products/ams/constants/ams.route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedManageAsset = observer(() => {
  const { id } = useParams<{ id: string }>();

  const { currentUserInfo } = useGlobalStore();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { loadAsset, asset, isLoading, responseLoadError } =
    useAssetManagerStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        asset?.AssetNumber,
        asset?.AssetDescription,
      ])}`,
      LinkUrl: AmsRoute.ASSESSMENT_MANAGE_PATH,
      LinkText: `Asset Manager - Asset Register - ${asset?.Id ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: asset?.Id ?? 0,
    },
  ];

  const listPanelBar: IListPanelBar[] = [];

  return (
    <Fragment>
      <FormNavigation title="ASSET" />
      <Loading isLoading={isLoading} isFullScreen />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadAsset(+id)}
        />
      ) : (
        <>
          <FormTitle
            prefixTitle={(asset && asset?.AssetNumber) ?? ""}
            title={(asset && asset?.AssetDescription) ?? ""}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {asset && <CCGeneralPanel component={<AssetManagerForm />} />}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <AssetDetailTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </Fragment>
  );
});
