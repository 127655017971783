import { DTO_TitleLrsOwner } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { observer } from "mobx-react-lite";
import React from "react";
import { titleOwnersCol } from "./config";
const nameOf = nameOfFactory<DTO_TitleLrsOwner>();
export const TitleDetail = observer((props: GridDetailRowProps) => {
  const dataItem = props.dataItem;

  return (
    <CCGrid
      className="cc-title-detail-grid"
      data={dataItem.LRS_Title_Owners ?? []}
      columnFields={titleOwnersCol}
      primaryField={nameOf("Id")}
      isLimitDetailWidth
    />
  );
});
