import { history } from "@/AppRoutes";
import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import {
  getCRMSKnowledgeBaseSummaryById,
  postCRMSKnowledgeBase,
  putCRMSKnowledgeBase,
} from "@app/products/crms/knowledge-base/[id]/api";
import { CRMS_KB_BASE_ROUTE } from "@app/products/crms/knowledge-base/[id]/constant";
import {
  ICRMSKnowledgeBaseRequest,
  ICRMSKnowledgeBaseSummary,
} from "@app/products/crms/knowledge-base/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class CRMSKnowledgeBaseStore {
  private _crmsKnowledgeBase?: ICRMSKnowledgeBaseSummary = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get crmsKnowledgeBase() {
    return this._crmsKnowledgeBase;
  }
  setCRMSKnowledgeBase = (
    crmsKnowledgeBase: ICRMSKnowledgeBaseSummary | undefined
  ) => {
    runInAction(() => {
      this._crmsKnowledgeBase = crmsKnowledgeBase;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get crmsKnowledgeBaseId() {
    return this._crmsKnowledgeBase?.ID;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._crmsKnowledgeBase = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadCRMSKnowledgeBase = async (
    crmsKnowledgeBaseId: number,
    isNew: boolean = false
  ) => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    let newCRMSKnowledgeBase = undefined;
    if (!isNew) {
      const response = await getCRMSKnowledgeBaseSummaryById(
        crmsKnowledgeBaseId
      );
      if (isSuccessResponse(response)) {
        newCRMSKnowledgeBase = response.data;
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
      this.setCRMSKnowledgeBase(newCRMSKnowledgeBase);
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  createCRMSKnowledgeBase = async (
    crmsKnowledgeBase: ICRMSKnowledgeBaseRequest
  ) => {
    this.setIsLoading(true);
    await postCRMSKnowledgeBase(crmsKnowledgeBase);
    appNotificationStore.pushNotification({
      title: `The ${CRMS_PRODUCT_NAME} knowledge base was created successfully.`,
      type: "success",
      onClose: () => {
        history.replace(`${CRMS_KB_BASE_ROUTE}/${crmsKnowledgeBase?.ID ?? 1}`);
      },
    });
    this.setIsLoading(false);
  };

  updateCRMSKnowledgeBase = async (
    crmsKnowledgeBase: ICRMSKnowledgeBaseRequest
  ) => {
    this.setIsLoading(true);
    await putCRMSKnowledgeBase(crmsKnowledgeBase);
    appNotificationStore.pushNotification({
      title: `The ${CRMS_PRODUCT_NAME} knowledge base was updated successfully.`,
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const crmsKnowledgeBaseStoreContext = createContext(
  new CRMSKnowledgeBaseStore()
);
export const useCRMSKnowledgeBaseStore = () => {
  return useContext(crmsKnowledgeBaseStoreContext);
};
