import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IOptionsDefault } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/infringements-notices/forms/infringement-integration-settings/model";
import { NOTICE_EXPORT_SYSTEM } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/infringements-notices/forms/notice-export-settings/model";
import { FormRenderProps } from "@progress/kendo-react-form";

export const optionsNoticeExportSettings = (
  options: IOptionsDefault,
  formRenderProps: FormRenderProps
) => {
  const { valueGetter } = formRenderProps;
  /* ====  Notice Export System  ==== */
  const enableAllowNoticeExport = valueGetter(
    ECorporateSettingsField.CorporateSettings_EnableNoticeExportSystem.toString()
  );

  const enableNoticeExportSystem =
    valueGetter(
      ECorporateSettingsField.CorporateSettings_NoticeExportSystem.toString()
    ) === NOTICE_EXPORT_SYSTEM.TechOne;

  const isNoticeExportSystem =
    ECorporateSettingsField.CorporateSettings_NoticeExportSystem ===
    options.enumFieldName;

  if (enableAllowNoticeExport) {
    if (isNoticeExportSystem) {
      options.showField = true; // show Notice Export System
    }

    if (
      enableNoticeExportSystem &&
      [
        ECorporateSettingsField.CorporateSettings_NoticeExportSystemURL, // Web Service URL
        ECorporateSettingsField.CorporateSettings_NoticeExportSystemEnvironment, // Environment
        ECorporateSettingsField.CorporateSettings_NoticeExportSystemUserName, // User Name
        ECorporateSettingsField.CorporateSettings_NoticeExportSystemPassword, // Password
      ].includes(options.enumFieldName)
    ) {
      options.showField = true;
    }
  }
  /* ====  / Notice Export System  ==== */

  if (
    ECorporateSettingsField.CorporateSettings_EnableNoticeExportSystem ===
    options.enumFieldName // Allow Notice Export always show
  ) {
    options.showField = true;
  }
  return options;
};
