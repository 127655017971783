import { ProductRouterSwitch } from "@/ProductRouterSwitch";
import { ManageCRMSSystemSettings } from "@app/products/crms/system-admin/[id]/_index";
import {
  AdminSettingRoute,
  SettingListRoute,
} from "@common/pages/settings/constant";
import { helpDocumentHelper } from "@common/pages/settings/lookups/help-documents/util";
import { settingsRoute } from "@common/pages/settings/route";
import {
  convertRouteDynamic,
  IConvertRouteDynamic,
} from "@common/utils/convertRouteDynamic";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import React from "react";
import { PrivateRoute } from "../AppRoutes";

export function SettingRouter(props: any) {
  return (
    <ProductRouterSwitch>
      {settingsRoute.children &&
        convertRouteDynamic(settingsRoute.children).map(
          (route: IConvertRouteDynamic, index: number): JSX.Element | null => {
            if (
              helpDocumentHelper.isHelpDocumentPath(route) &&
              !helpDocumentHelper.isVisibleHelpDocument()
            )
              return null;
            return (
              route.component && (
                <PrivateRoute
                  exact
                  key={index}
                  path={`${props.match.url}/${route.path}`}
                  component={route.component}
                />
              )
            );
          }
        )}

      <PrivateRoute
        path={[AdminSettingRoute.SETTINGS_CRMS_SYSTEMADMIN_SYSTEMSETTINGS]}
        component={ManageCRMSSystemSettings}
      />

      {SettingListRoute.map((item, index) => (
        <PrivateRoute
          exact
          key={index}
          path={item.path}
          component={(localprops: any) => (
            <CCProductListView
              {...localprops}
              columnFields={item.initialColumnFields}
              dataUrl={item.dataUrl}
              primaryField="ID"
            />
          )}
        />
      ))}

      {/* <Route
        exact
        path={[props.match.url + `/systemadmin/:product/:category/new`]}
        component={(routeProps: any) => <SystemAdminSetting {...routeProps} />}
      /> */}
      {/* <Route
        exact
        path={[props.match.url + `/systemadmin/:product/:category`]}
        component={(routeProps: any) => <SystemAdminSetting {...routeProps} />}
      /> */}
    </ProductRouterSwitch>
  );
}
