import {
  getNewSchemeDetailById,
  getSchemeDetailById,
} from "@app/products/property/schemes/[id]/api";
import { ISchemeForm } from "@app/products/property/schemes/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ICCNotification } from "@components/cc-notification/components/cc-notification-item/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class SchemesStore {
  private _schemes?: ISchemeForm = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _notifications: ICCNotification[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };
  get schemes() {
    return this._schemes;
  }
  setSchemes = (schemes?: ISchemeForm) => {
    runInAction(() => {
      this._schemes = schemes;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get schemesId() {
    return this.schemes?.General?.SchemeId;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._schemes = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };
  loadSchemes = async (schemesId: number, isNew: boolean) => {
    let response;
    this.setIsLoading(true);
    if (isNew) {
      response = await getNewSchemeDetailById(schemesId);
    } else {
      response = await getSchemeDetailById(schemesId);
    }
    let errorResponse = undefined;
    let newSchemes = undefined;
    if (isSuccessResponse(response)) {
      newSchemes = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setSchemes(newSchemes);
    this.setIsLoading(false);
  };
}

const schemesStoreContext = createContext(new SchemesStore());
export const useSchemesStore = () => {
  return useContext(schemesStoreContext);
};
