import { Svc_CombineAttachment } from "@app/core/view/components/dialogs/view-documents/components/dialogs/combine-attachment/model";
import { Svc_ChangeSort } from "@app/core/view/components/dialogs/view-documents/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

// TODO: Just mock APIs. Jisha will provide the new API for this
export const putSortOrder = async (
  requestBody: Svc_ChangeSort
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().put(
      `api/core/internal/documents/changesort`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const combineAttachments = async (
  attachment: Svc_CombineAttachment | any
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().put(
      `api/core/internal/documents/combine`,
      attachment
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
