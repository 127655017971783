import React, { useRef } from "react";
import { useEffectOnce } from "react-use";

export const CCShadowRootWrapper = (props: { children: React.ReactNode }) => {
  const hostRef = useRef<HTMLElement>(null);
  useEffectOnce(() => {
    if (!props.children) return;
    const hostElement = hostRef.current;
    if (hostElement && !hostElement.shadowRoot) {
      const shadowRoot = hostElement.attachShadow({ mode: "open" });
      if (shadowRoot) {
        shadowRoot.innerHTML = hostElement.innerHTML;
        hostElement.innerHTML = "";
      }
    }
  });
  return props.children ? <span ref={hostRef}>{props.children}</span> : null;
};
