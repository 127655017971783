import { getDisplayTextWithDashes } from "@common/utils/common";

export const HomepageUsersByViewsDetailsBookmark = {
  getBookmarkDisplayName: (selectedRow: any) => {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Settings - Security - Site Users ${dynamicDisplayName}`;
  },
  getBookmarkDetail: (selectedRow: any) => {
    return getDisplayTextWithDashes([selectedRow.ID, selectedRow.DisplayName]);
  },
};

export const HomepageUserByViewsBookmark = {
  getBookmarkListViewDisplayName() {
    return "Settings - Homepage";
  },
  getBookmarkListViewDetail() {
    return "Settings - Homepage - User by Views";
  },
};
