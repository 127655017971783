export enum JOURNAL_TYPE {
  METER_USAGES = "Meter Usages",
  DeferredDutyAccountJournal = "Deferred Duty Account Journal",
}

export interface DTO_Journal_Summary {
  Journal_Number: number;
  Journal_Type: number;
  Authorising_Officer_Id: number | null;
  Session_Number: number | null;
  Supplementary_Id: number | null;
  Register_Id: number | null;
  Utility_Id: number | null;
  Jnl_DateTime: Date;
  Jnl_Reference: string;
  Jnl_Description: string;
  Jnl_No_of_Transactions: number | null;
  Jnl_Debit_Total: number;
  Jnl_Credit_Total: number;
  Jnl_Notes: string;
  Jnl_Authorised_on: Date | null;
  Officer_Id: number;
  Journal_Status: number;
  Assigned_to_Authorise_Officer_Id: number | null;
  Journal_Total: number;
  Journal_Status_Name: string;
  Journal_Type_Name: string;
  Component_Number: number;
  Supp_Reference: string;
  Supp_Name: string;
  Supp_Notes: string;
  Supp_Date: Date | null;
  Register_Name: string;
  Utility_Name: string;
  Officer_Name: string;
  Officer_Surname_w_Given_Names: string;
  Officer_UserId: string;
  Authorised_By_Name: string;
  Authorised_By_Surname_w_Given: string;
  Authorised_By_UserId: string;
  Jnl_Can_Be_Authorised: boolean;
}
