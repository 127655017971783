import { DTO_Progeny } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/model";
import { groupBy } from "lodash";

export const validatorProgenySubdivide = (values: any): string | undefined => {
  //Validate progeny list (require at least 1 record)
  if (!values?.Progenies?.length) {
    return "At least one progeny is needed";
  }

  //Validate Lot (mandatory)
  if (values?.Progenies?.some((progeny: DTO_Progeny) => !progeny.Lot)) {
    return `Lot is required`;
  }

  //Validate Lot (unique)
  const groupedProgeniesByLot = groupBy(
    values?.Progenies,
    (progeny: DTO_Progeny) => progeny.Lot
  );
  const progeniesWithSameLot = Object.values(groupedProgeniesByLot).filter(
    (group) => group.length > 1
  );
  if (progeniesWithSameLot?.length > 0) {
    return "Duplicate lot";
  }

  //Validate Street and Locality (mandatory)
  if (
    values?.Progenies?.some(
      (progeny: DTO_Progeny) => !progeny.Street_And_Locality
    )
  ) {
    return `Street & Locality is required`;
  }

  //Validate Parcel Reference (must have unique values or left empty)
  const progeniesHavingParcelRef = values?.Progenies.filter(
    (progeny: DTO_Progeny) => progeny.Parcel_Reference
  );
  const groupedProgeniesByParcelRef = groupBy(
    progeniesHavingParcelRef,
    (progeny: DTO_Progeny) => progeny.Parcel_Reference
  );
  const progeniesWithSameParcelRef = Object.values(
    groupedProgeniesByParcelRef
  ).filter((group) => group.length > 1);
  if (progeniesWithSameParcelRef?.length > 0) {
    return "Duplicate parcel reference";
  }

  return undefined;
};
