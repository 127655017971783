import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { DTO_Amount_Owing } from "@app/products/property/contacts-central-names/[id]/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Amount_Owing>();
export const colAmountsOwing: IColumnFields[] = [
  {
    field: nameOf("Assessment_Number"),
    title: "Assessment Number",
    format: DECIMAL_FORMAT.DECIMAL1,
    linkTo: (dataItem: DTO_Amount_Owing) =>
      `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Assessment_Id}`,
  },
  {
    field: nameOf("Association_Name"),
    title: "Association",
  },
  {
    field: nameOf("Overdue"),
    title: "Overdue",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Due"),
    title: "Due",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Pending"),
    title: "Pending",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Payout_Amount"),
    title: "Payout Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Balance_Owing"),
    title: "Balance Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assessment_Reference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("RatePayer"),
    title: "Ratepayer",
  },
  {
    field: nameOf("Legal_Description"),
    title: "Legal Description",
  },
  {
    field: nameOf("Balance_Owing"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Balance_Outstanding"),
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Being_Postponed"),
    title: "Being Postponed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Assessment_Group_Name"),
    title: "Group",
  },
  {
    field: nameOf("Valuation_Group_Name"),
    title: "Valuation Group",
  },
  {
    field: nameOf("Assessment_Type_Name"),
    title: "Type",
  },
  {
    field: nameOf("Assessment_Status_Name"),
    title: "Status",
  },
  {
    field: nameOf("Land_Uses"),
    title: "Land Uses",
  },
  {
    field: nameOf("Assessment_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
