import { DTO_Title } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { calculationMaxPercentage } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { PERCENTAGE_FORMAT } from "@common/constants/common-format";
import { ICustomEditCell } from "@components/cc-grid/model";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import React from "react";
import "./_index.scss";
export const PercentageAreaTextBoxCustomCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
}: ICustomEditCell) => {
  const dataRow: DTO_Title = dataItem;
  //calculate Area Percentage maximum can be allocated =>
  // In Create PIC the Maximum = 100% - AssociatedPICAreaTotal
  // In Modify/Reactivate PIC the Maximum = AssociatedPICAreaRemaining + AssociatedPICArea
  // The AssociatedPICArea will return in the API & it is the stable variable
  const maxPercentage = calculationMaxPercentage(dataRow);

  //auto reset Area Percentage need get maxPercentage can be allocated when it is null
  const calValuePercentage = (value: number | null) => {
    return isNil(value) || value <= 0 || value > maxPercentage
      ? maxPercentage
      : value;
  };
  return (
    <div className="cc-percentage-text-box-custom-cell">
      <CCNumericTextBox
        className="cc-percentage-text-box-custom"
        value={calValuePercentage(value)}
        format={PERCENTAGE_FORMAT.PERCENTAGE_NOT_DECIMAL}
        max={maxPercentage}
        onChange={(event: NumericTextBoxChangeEvent) => {
          if (onChange) {
            onChange({
              field: field,
              dataIndex: dataIndex || 0,
              dataItem,
              syntheticEvent: event.syntheticEvent,
              value: calValuePercentage(event.value),
            });
          }
        }}
      />
      {/* Tooltip provides max percentage user can pick */}
      <CCTooltip
        type="info"
        position="right"
        content={`Maximum percentage available is ${maxPercentage}%. If the Lot is already linked 100% to another PIC the value will be defaulted back to 0%.`}
      />
    </div>
  );
};
