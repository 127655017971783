import { dropdownDataForIconEditCell } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/components/icon-edit-cell/config";
import { getDropdownValue } from "@common/utils/common";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { ICustomEditCell } from "@components/cc-grid/model";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import React from "react";

export const IconEditCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
}: ICustomEditCell) => {
  const handleOnChange = (event: DropDownListChangeEvent) => {
    if (onChange)
      onChange({
        field: field,
        dataIndex: dataIndex || 0,
        dataItem,
        syntheticEvent: event.syntheticEvent,
        value: event.value?.Code ?? "",
      });
  };

  return (
    <CCDropDownList
      textField="Name"
      dataItemKey="Code"
      value={getDropdownValue(value, dropdownDataForIconEditCell, "Code")}
      data={dropdownDataForIconEditCell}
      onChange={handleOnChange}
    />
  );
};
