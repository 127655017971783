import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingApplicationsCILPendingPayment: IColumnFields[] = [
  {
    field: BuildingFieldMapping.ApplicationNumber,
    title: "Number",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.SiteAddress,
    title: "Site Address",
  },
  {
    field: BuildingFieldMapping.Status,
    title: "Status",
  },
  {
    field: BuildingFieldMapping.CILFee,
    title: "CIL Fee",
  },
  {
    field: BuildingFieldMapping.Flag_CILPaid,
    title: "Paid?",
  },
  {
    field: BuildingFieldMapping.Applicant,
    title: "Applicant",
  },
];
