import { DTO_DeferredDuty_Transaction } from "@app/products/property/deferred-duty/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getDeferredDutyTransactions = async (
  deferredDutyAccountId: number,
  showcancelled: boolean = true
): Promise<APIResponse<DTO_DeferredDuty_Transaction>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_DeferredDuty_Transaction>(
      `api/property/internal/deferredduty/account/${deferredDutyAccountId}/transaction?showcancelled=${showcancelled}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
