import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LookupStateRoadForm } from "@common/pages/settings/lookups/state-roads/_id/components/child-screens/general/_index";
import { LookupStateRoadTabDetails } from "@common/pages/settings/lookups/state-roads/_id/components/reference-sidebar/detail/_index";
import { LookupStateRoadTabHistory } from "@common/pages/settings/lookups/state-roads/_id/components/reference-sidebar/history/_index";
import { LookupStateRoadActions } from "@common/pages/settings/lookups/state-roads/_id/model";
import { useLookupStateRoadStore } from "@common/pages/settings/lookups/state-roads/_id/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedLookupStateRoad = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { currentUserInfo } = useGlobalStore();
  const {
    stateRoadId,
    stateRoad,
    loadStateRoad,
    isLoading,
    responseLoadError,
    onSubmit,
    isInactive,
  } = useLookupStateRoadStore();

  const [showSlideBar, setShowSlideBar] = useState(true);

  const managePageUrl = window.location.pathname;

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        stateRoad?.StateRoad_ID,
        stateRoad?.RoadName,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Settings - State Roads - ${stateRoad?.StateRoad_ID ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_StateRoad,
      Record_ID: stateRoadId ?? 0,
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"State Road"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadStateRoad(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={stateRoad?.RoadName || ""} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                name={LookupStateRoadActions.Save}
                onClick={onSubmit}
                disabled={isInactive}
              />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
                disabled={isInactive}
              />,
              <ActionBarBookmarkIcon
                bookmarkList={bookmarkList}
                disabled={isInactive}
              />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {stateRoad && <LookupStateRoadForm />}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <LookupStateRoadTabDetails />,
                    },
                    {
                      title: "History",
                      component: <LookupStateRoadTabHistory />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
