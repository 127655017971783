import { ETownPlanningSettingsCategory } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { ApplicationAdvertisingForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/application_advertising/_index";
import { ApplicationCommunicationForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/application_communication/_index";
import { ApplicationCustomLabelsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/application_customlabels/_index";
import { ApplicationEplanningForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/application_eplanning/_index";
import { ApplicationGeneralForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/application_general/_index";
import { ApplicationLandscapeInspectionPermitAuditsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/application_landscapeinspectionandpermitaudits/_index";
import { ApplicationMailMergeForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/application_mailmerge/_index";
import { ApplicationMandatoryFieldsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/application_mandatoryfields/_index";
import { ApplicationPlanningOnlineMapForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/application_planningonlinemap/_index";
import { ApplicationRegistersForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/application_registers/_index";
import { ApplicationWorkflowForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/application_workflow/_index";
import { AssessmentChecklistForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/assessment-checklist/_index";
import { AssessmentExtendedSettingsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/assessment-extended-settings/_index";
import { AssessmentGeneral1Form } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/assessment-general1/_index";
import { AssessmentGeneral2Form } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/assessment-general2/_index";
import { AssessmentPrelimAssessmentForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/assessment-prelim-assessment/_index";
import { CertificationForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/certification/certification/_index";
import { CustomLabelForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/certification/custom-label/_index";
import { NSWEplanningPortalForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/eplanning-external/nsw-eplanning-portal/_index";
import { NumberingApplicationForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-application/_index";
import { NumberingCDCForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-comply-development-certificate/_index";
import { NumberingEnquiryForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-enquiry/_index";
import { NumberingEOTForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-extension-of-time/_index";
import { NumberingNonComplianceForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-non-compliance/_index";
import { NumberingOAsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-oa/_index";
import { NumberingPSAForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-psa/_index";
import { NumberingSecondaryConsentForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-secondary-consent/_index";
import { NumberingSubdivisionForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-subdivision/_index";
import { OAsApplicationForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/oas/oas-application/_index";
import { OAsViewsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/oas/oas-views/_index";
import { PPARSSettingsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/ppars/ppars-settings/_index";
import { PPRCustomLabelForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/ppr/custom-label/_index";
import { PPRPlanningPermitReferralsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/ppr/planning-permit-referrals/_index";
import { CustomLabelsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/psa/custom-labels/_index";
import { FieldSettingsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/psa/field-settings/_index";
import { PlanningSchemeAmendmentReferralSettingsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/psa/planning-scheme-amendment-referral/_index";
import { PublicPortalDocumentSettingForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-portal/document-settings/_index";
import { PublicPortalLiveMergeTemplatesForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-portal/live-merge-templates/_index";
import { PublicPortalPortalApplicationSettingForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-portal/portal-application-settings/_index";
import { PublicPortalSettingForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-portal/portal-settings/_index";
import { PublicPortalPortalSubmissionSettingForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-portal/portal-submission-settings/_index";
import { PublicPortalTPSettingsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-portal/townplanning-settings/_index";
import { ExportRegisterSettingsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-register/export-register-settings/_index";
import { RegisterSettingsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-register/register-settings/_index";
import { RegisterStyleSettingsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-register/register-style-settings/_index";
import { RecordsAttachmentTitleFormatForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-attachmenttitleformats/_index";
import { RecordsCustomRecordsFieldForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-customrecordsfield/_index";
import { RecordsFileITAmendmentForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-fileit-amendment/_index";
import { RecordsFileITApplicationForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-fileit-application/_index";
import { RecordsFileITPlanningEnquiryForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-fileit-planningenquiry/_index";
import { RecordsFileITPlansToComplyForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-fileit-planstocomply/_index";
import { RecordsInfoExpertAmendmentsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-infoexpertamendments/_index";
import { RecordsInfoExpertApplicationForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-infoexpertapplication/_index";
import { RecordsInfoExpertEnquiryForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-infoexpertenquiry/_index";
import { RecordsInfoExpertEOTForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-infoexperteot/_index";
import { RecordsInfoExpertNonComplianceForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-infoexpertnoncompliance/_index";
import { RecordsInfoExpertOAForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-infoexpertoa/_index";
import { RecordsInfoExpertSecondaryConsentForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-infoexpertsecondaryconsent/_index";
import { RecordsInfoExpertSubdivisionForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-infoexpertsubdivision/_index";
import { RecordsManagementSettingsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-recordsmanagementsettings/_index";
import { RecordsSPApplicationForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-sp-application/_index";
import { RecordsSPContainerPartsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-sp-containerparts/_index";
import { RecordsSPEnquiryForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-sp-enquiry/_index";
import { RecordsSPExtensionOfTimeForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-sp-eot/_index";
import { RecordsSPNonComplianceForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-sp-noncompliance/_index";
import { RecordsSPPlansToComplyForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-sp-planstocomply/_index";
import { RecordsSPSecondaryConsentForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-sp-secondaryconsent/_index";
import { RecordsSPSubDivisionForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-sp-subdivision/_index";
import { RecordsTrimAmendmentForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-trimamendment/_index";
import { RecordsTrimApplicationForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-trimapplication/_index";
import { RecordsTrimCustomFieldsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-trimcustomfields/_index";
import { RecordsTrimEnquiryForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-trimenquiry/_index";
import { RecordsTrimEPlanningForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-trimeplanning/_index";
import { RecordsTrimExtensionOfTimeForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-trimextensionoftime/_index";
import { RecordsTrimNonComplianceForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-trimnoncompliance/_index";
import { RecordsTrimOtherApplicationsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-trimotherapplication/_index";
import { RecordsTrimPlansToComplyForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-trimplanstocomply/_index";
import { RecordsTrimPSAForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-trimpsa/_index";
import { RecordsTrimSecondaryConsentForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-trimsecondaryconsent/_index";
import { RecordsTrimSubdivisionForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-trimsubdivision/_index";
import { RegistersExtensionOfTimeForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/registers/registers-extension-of-time/_index";
import { RegistersGeneralForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/registers/registers-general/_index";
import { RegistersNonComplainceForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/registers/registers-non-complaince/_index";
import { RegistersPlanningEnquiryForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/registers/registers-planning-enquiry/_index";
import { RegistersSecondaryConsentForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/registers/registers-secondary-consent/_index";
import { RegistersWorkflowForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/registers/registers-workflow/_index";
import { SpearSettingsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/spear/spear-settings/_index";
import { StatDaysSettingsForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/stat-days/stat-days-settings/_index";
import { NoData } from "@components/no-data/NoData";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IComponentFormProps {
  formRenderProps: FormRenderProps;
}

export const ComponentSettingForm = observer(
  ({ formRenderProps }: IComponentFormProps) => {
    const { dataSetting } = useSettingPPRStore();
    const { Name, SettingCategoryName } = dataSetting;
    switch (SettingCategoryName) {
      case ETownPlanningSettingsCategory.Numbering:
        switch (Name) {
          case ETownPlanningSettingsCategory.Numbering_Application:
            return (
              <NumberingApplicationForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Numbering_Subdivision:
            return (
              <NumberingSubdivisionForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Numbering_Enquiry:
            return <NumberingEnquiryForm formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.Numbering_NonCompliance:
            return (
              <NumberingNonComplianceForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Numbering_SecondaryConsent:
            return (
              <NumberingSecondaryConsentForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Numbering_ExtensionOfTime:
            return <NumberingEOTForm formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.Numbering_PSA:
            return <NumberingPSAForm formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.Numbering_OAs:
            return <NumberingOAsForm formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.Numbering_ComplyDevelopmentCertificate:
            return <NumberingCDCForm formRenderProps={formRenderProps} />;
          default:
            return <NoData vertical />;
        }

      case ETownPlanningSettingsCategory.Application: {
        switch (Name) {
          case ETownPlanningSettingsCategory.Application_Registers:
            return (
              <ApplicationRegistersForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Application_General:
            return <ApplicationGeneralForm formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.Application_MandatoryFields:
            return (
              <ApplicationMandatoryFieldsForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Application_Workflow:
            return (
              <ApplicationWorkflowForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Application_PlanningOnlineMap:
            return (
              <ApplicationPlanningOnlineMapForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Application_LandscapeInspectionsPermitAudits:
            return (
              <ApplicationLandscapeInspectionPermitAuditsForm
                formRenderProps={formRenderProps}
              />
            );

          case ETownPlanningSettingsCategory.Application_Advertising:
            return (
              <ApplicationAdvertisingForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Application_MailMerge:
            return (
              <ApplicationMailMergeForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Application_CustomLabels:
            return (
              <ApplicationCustomLabelsForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Application_EPlanning:
            return (
              <ApplicationEplanningForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Application_Communication:
            return (
              <ApplicationCommunicationForm formRenderProps={formRenderProps} />
            );
          default:
            return <NoData vertical />;
        }
      }
      case ETownPlanningSettingsCategory.Registers:
        switch (Name) {
          case ETownPlanningSettingsCategory.Registers_NonCompliance:
            return (
              <RegistersNonComplainceForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Registers_General:
            return <RegistersGeneralForm formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.Registers_ExtensionOfTime:
            return (
              <RegistersExtensionOfTimeForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Registers_SecondaryConsent:
            return (
              <RegistersSecondaryConsentForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Registers_PlanningEnquiry:
            return (
              <RegistersPlanningEnquiryForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Registers_Workflow:
            return <RegistersWorkflowForm formRenderProps={formRenderProps} />;
          default:
            return <NoData vertical />;
        }
      case ETownPlanningSettingsCategory.Assessment:
        switch (Name) {
          case ETownPlanningSettingsCategory.Assessment_General1:
            return <AssessmentGeneral1Form formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.Assessment_General2:
            return <AssessmentGeneral2Form formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.Assessment_ExtendedSettings:
            return (
              <AssessmentExtendedSettingsForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Assessment_PrelimAssessment:
            return (
              <AssessmentPrelimAssessmentForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Assessment_Checklist:
            return <AssessmentChecklistForm />;
          default:
            return <NoData vertical />;
        }
      case ETownPlanningSettingsCategory.StatDays:
        switch (Name) {
          case ETownPlanningSettingsCategory.StatDays_Settings:
            return <StatDaysSettingsForm formRenderProps={formRenderProps} />;
          default:
            return <NoData vertical />;
        }
      case ETownPlanningSettingsCategory.PublicPortal:
        switch (Name) {
          case ETownPlanningSettingsCategory.PublicPortal_TownPlanningSettings:
            return (
              <PublicPortalTPSettingsForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.PublicPortal_PortalSettings:
            return <PublicPortalSettingForm />;
          case ETownPlanningSettingsCategory.PublicPortal_PortalApplicationSettings:
            return (
              <PublicPortalPortalApplicationSettingForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.PublicPortal_LiveMergeTemplates:
            return (
              <PublicPortalLiveMergeTemplatesForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.PublicPortal_DocumentSettings:
            return (
              <PublicPortalDocumentSettingForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.PublicPortal_PortalSubmissionSettings:
            return (
              <PublicPortalPortalSubmissionSettingForm
                formRenderProps={formRenderProps}
              />
            );
          default:
            return <NoData vertical />;
        }
      case ETownPlanningSettingsCategory.PublicRegister:
        switch (Name) {
          case ETownPlanningSettingsCategory.PublicRegister_RegisterSettings:
            return <RegisterSettingsForm formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.PublicRegister_ExportRegisterSettings:
            return (
              <ExportRegisterSettingsForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.PublicRegister_RegisterStyleSettings:
            return (
              <RegisterStyleSettingsForm formRenderProps={formRenderProps} />
            );
          default:
            return <NoData vertical />;
        }
      case ETownPlanningSettingsCategory.PPARS:
        switch (Name) {
          case ETownPlanningSettingsCategory.PPARS_Settings:
            return <PPARSSettingsForm formRenderProps={formRenderProps} />;
          default:
            return <NoData vertical />;
        }
      case ETownPlanningSettingsCategory.PSA:
      case ETownPlanningSettingsCategory.PSAR:
        switch (Name) {
          case ETownPlanningSettingsCategory.PSA_PlanningSchemeAmendment:
            return (
              <PlanningSchemeAmendmentReferralSettingsForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.PSA_FieldSettings:
            return <FieldSettingsForm formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.PSA_CustomLabels:
            return <CustomLabelsForm />;
          default:
            return <NoData vertical />;
        }
      case ETownPlanningSettingsCategory.OAs:
        switch (Name) {
          case ETownPlanningSettingsCategory.OAs_Applications:
            return <OAsApplicationForm formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.OAs_Views:
            return <OAsViewsForm formRenderProps={formRenderProps} />;
          default:
            return <NoData vertical />;
        }
      case ETownPlanningSettingsCategory.Certification:
        switch (Name) {
          case ETownPlanningSettingsCategory.Certification_Certification:
            return <CertificationForm formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.Certification_CustomLabel:
            return <CustomLabelForm formRenderProps={formRenderProps} />;
          default:
            return <NoData vertical />;
        }
      case ETownPlanningSettingsCategory.SPEAR:
        switch (Name) {
          case ETownPlanningSettingsCategory.SPEAR_Settings:
            return <SpearSettingsForm formRenderProps={formRenderProps} />;
          default:
            return <NoData vertical />;
        }
      case ETownPlanningSettingsCategory.PPR:
        switch (Name) {
          case ETownPlanningSettingsCategory.PPR_PlanningPermitReferrals:
            return (
              <PPRPlanningPermitReferralsForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.PPR_CustomLabel:
            return <PPRCustomLabelForm formRenderProps={formRenderProps} />;
          default:
            return <NoData vertical />;
        }
      case ETownPlanningSettingsCategory.EPlanningPortal:
        switch (Name) {
          case ETownPlanningSettingsCategory.EPlanningPortal_NSWEPlanningPortal:
            return <NSWEplanningPortalForm formRenderProps={formRenderProps} />;
          default:
            return <NoData vertical />;
        }
      case ETownPlanningSettingsCategory.Records:
        switch (Name) {
          case ETownPlanningSettingsCategory.Records_AttachmentTitleFormats:
            return (
              <RecordsAttachmentTitleFormatForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Records_RecordsManagementSettings:
            return (
              <RecordsManagementSettingsForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Records_TrimApplication:
            return (
              <RecordsTrimApplicationForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_TrimAmendment:
            return (
              <RecordsTrimAmendmentForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_TrimSubdivision:
            return (
              <RecordsTrimSubdivisionForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_TrimEnquiry:
            return <RecordsTrimEnquiryForm formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.Records_TrimNonCompliance:
            return (
              <RecordsTrimNonComplianceForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_TrimSecondaryConsent:
            return (
              <RecordsTrimSecondaryConsentForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Records_TrimExtensionOfTime:
            return (
              <RecordsTrimExtensionOfTimeForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Records_TrimPlanToComply:
            return (
              <RecordsTrimPlansToComplyForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_TrimPSA:
            return <RecordsTrimPSAForm formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.Records_TrimOtherApplications:
            return (
              <RecordsTrimOtherApplicationsForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Records_TrimCustomFields:
            return (
              <RecordsTrimCustomFieldsForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_TrimEPlanning:
            return (
              <RecordsTrimEPlanningForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_InfoExpertApplication:
            return (
              <RecordsInfoExpertApplicationForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Records_InfoExpertSubdivision:
            return (
              <RecordsInfoExpertSubdivisionForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Records_InfoExpertEnquiry:
            return (
              <RecordsInfoExpertEnquiryForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_InfoExpertNonCompliance:
            return (
              <RecordsInfoExpertNonComplianceForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Records_InfoExpertSecondaryConsent:
            return (
              <RecordsInfoExpertSecondaryConsentForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Records_InfoExpertEOT:
            return (
              <RecordsInfoExpertEOTForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_InfoExpertOA:
            return (
              <RecordsInfoExpertOAForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_InfoExpertAmendments:
            return (
              <RecordsInfoExpertAmendmentsForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Records_FileITApplication:
            return (
              <RecordsFileITApplicationForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_FileITAmendment:
            return (
              <RecordsFileITAmendmentForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_FileITPlansToComply:
            return (
              <RecordsFileITPlansToComplyForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Records_FileITPlanningEnquiry:
            return (
              <RecordsFileITPlanningEnquiryForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Records_SPContainerParts:
            return (
              <RecordsSPContainerPartsForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_SPApplication:
            return (
              <RecordsSPApplicationForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_SPSubDivision:
            return (
              <RecordsSPSubDivisionForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_SPEnquiry:
            return <RecordsSPEnquiryForm formRenderProps={formRenderProps} />;
          case ETownPlanningSettingsCategory.Records_SPNonCompliance:
            return (
              <RecordsSPNonComplianceForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_SPSecondaryConsent:
            return (
              <RecordsSPSecondaryConsentForm
                formRenderProps={formRenderProps}
              />
            );
          case ETownPlanningSettingsCategory.Records_SPExtensionOfTime:
            return (
              <RecordsSPExtensionOfTimeForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_SPPlansToComply:
            return (
              <RecordsSPPlansToComplyForm formRenderProps={formRenderProps} />
            );
          case ETownPlanningSettingsCategory.Records_CustomRecordsField:
            return (
              <RecordsCustomRecordsFieldForm
                formRenderProps={formRenderProps}
              />
            );
          default:
            return <NoData vertical />;
        }
      default:
        return <NoData vertical />;
    }
  }
);
