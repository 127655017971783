import { IAttachment } from "@app/core/attachment/components/buttons/add-attachment/model";
import { convertToFileStreamEntry } from "@app/core/attachment/components/dialogs/util";
import {
  ElementDisplayStatus,
  EventMapObj,
  ServiceStandardActionTypeLink,
} from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import {
  ATTACHMENT_STEP,
  AttachmentStep,
} from "@app/products/crms/components/dialogs/new-simple-event/components/attachment/_index";
import {
  DETAIL_STEP,
  DetailStep,
} from "@app/products/crms/components/dialogs/new-simple-event/components/details/_index";
import {
  LOCATION_STEP,
  LocationStep,
} from "@app/products/crms/components/dialogs/new-simple-event/components/location/_index";
import {
  EListSimpleEventSubmitButton,
  listSimpleEventSubmitButton,
} from "@app/products/crms/components/dialogs/new-simple-event/constants";
import { PickActionTypeDialog } from "@app/products/crms/components/dialogs/pick-action-type/_index";
import { covertToActionTypeSelected } from "@app/products/crms/components/dialogs/pick-action-type/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import { useGlobalStore } from "@common/stores/global/store";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { Button } from "@progress/kendo-react-buttons";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef } from "react";
import { useEffectOnce } from "react-use";

export interface INewSimpleEventProps {
  onClose: () => void;
}

const nameOfWorkflowActions = nameOfFactory<ServiceStandardActionTypeLink>();
const nameOfRespActionType = nameOfFactory<IRespActionType>();

export const NewSimpleEventDialog = observer(
  ({ onClose }: INewSimpleEventProps) => {
    const {
      crmsEvent,
      isLoading,
      uiControl,
      responseLoadError,
      isLoadingSendForAction,
      isLoadingSubmitSimpleEvent,
      isShowPickActionTypeDialog,
      saveSimpleEvent,
      loadCRMSSimpleEvent,
      resetStore,
      sendForActionSimpleEvent,
      setIsShowPickActionTypeDialog,
      simpleEventRemovalAutoActionHandler,
    } = useCRMSEventStore();
    const { currentUserInfo } = useGlobalStore();
    const { cancelToken } = useCancelRequest();
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    const initialValues: EventMapObj = useMemo(() => {
      return { Event: crmsEvent } as EventMapObj;
    }, [crmsEvent]);

    const steps: IStep[] = [
      {
        label: "Details",
        component: DetailStep,
        visible: true,
        key: DETAIL_STEP,
      },
      {
        label: "Location",
        component: LocationStep,
        visible: true,
        key: LOCATION_STEP,
      },
      {
        label: "Attachment",
        component: AttachmentStep,
        visible: true,
        key: ATTACHMENT_STEP,
      },
    ];

    const handleSubmit = async (data: any, buttonId?: string) => {
      const attachmentsValidated = data.Event.Attachment?.filter((item: any) =>
        isNil(item.validationErrors)
      );

      const attachmentsConverted = await Promise.all(
        attachmentsValidated?.map(async (item: any) => {
          return {
            Attachment_ID: 0,
            Category_KWD: null,
            FileEntry: await convertToFileStreamEntry(item),
            TRIMUri: "",
            Title: data.Title ?? item?.name,
          } as IAttachment;
        })
      );

      const event = { ...data.Event, Attachment: attachmentsConverted };
      if (buttonId === EListSimpleEventSubmitButton.SubmitEvent) {
        saveSimpleEvent(
          event,
          notificationFormStepRef.current?.getNotificationFormStep()
        );
      } else if (buttonId === EListSimpleEventSubmitButton.SendForAction) {
        var isSuccessSend = await sendForActionSimpleEvent(
          event,
          currentUserInfo?.UserPKID!,
          notificationFormStepRef.current?.getNotificationFormStep()
        );
        if (isSuccessSend) onClose();
      }
    };

    const handleRemovalAutoAction = async (
      workflowActions: IRespActionType[]
    ) => {
      const workflowActionIds = workflowActions.map(
        (workflowAction: IRespActionType) => workflowAction.ID
      );
      var isSuccessSend = await simpleEventRemovalAutoActionHandler(
        workflowActionIds,
        currentUserInfo?.UserPKID!,
        notificationFormStepRef.current?.getNotificationFormStep()
      );
      if (isSuccessSend) onClose();
    };

    useEffectOnce(() => resetStore);

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        listButtonId={listSimpleEventSubmitButton}
        onSubmit={handleSubmit}
        initialSteps={steps}
        initialValues={initialValues}
        renderForm={(renderProps: ICCFormStepRender) => {
          return (
            <>
              <CCDialog
                maxWidth="60%"
                maxHeight="70%"
                titleHeader={"New Event"}
                onClose={onClose}
                bodyElement={
                  responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={() => loadCRMSSimpleEvent(cancelToken())}
                    />
                  ) : (
                    renderProps.children
                  )
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button
                      className={"cc-dialog-button"}
                      onClick={onClose}
                      disabled={
                        isLoading ||
                        isLoadingSubmitSimpleEvent ||
                        isLoadingSendForAction
                      }
                    >
                      Cancel
                    </Button>
                    {renderProps.isLastStep &&
                      uiControl?.BtnSimpleSendForAction?.DisplayStatus !==
                        ElementDisplayStatus.Hidden && (
                        <Button
                          className={"cc-dialog-button"}
                          disabled={
                            isLoading ||
                            isLoadingSubmitSimpleEvent ||
                            isLoadingSendForAction ||
                            uiControl?.BtnSimpleSendForAction?.DisplayStatus ===
                              ElementDisplayStatus.Disable
                          }
                          iconClass={
                            isLoadingSendForAction
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                          id={EListSimpleEventSubmitButton.SendForAction}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Send for action
                        </Button>
                      )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                        disabled={
                          isLoading ||
                          isLoadingSubmitSimpleEvent ||
                          isLoadingSendForAction
                        }
                      >
                        Previous
                      </Button>
                    )}
                    <Button
                      themeColor="primary"
                      id={
                        renderProps.isLastStep
                          ? EListSimpleEventSubmitButton.SubmitEvent
                          : "cc-next-step-button"
                      }
                      iconClass={
                        isLoadingSubmitSimpleEvent
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      disabled={
                        renderProps.nextButton.disabled ||
                        isLoading ||
                        isLoadingSubmitSimpleEvent ||
                        isLoadingSendForAction
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {renderProps.isLastStep
                        ? "Submit event"
                        : renderProps.nextButton.label}
                    </Button>
                  </div>
                }
              />

              {/* pick action type dialog */}
              {isShowPickActionTypeDialog && (
                <PickActionTypeDialog
                  onCloseDialog={() => setIsShowPickActionTypeDialog(false)}
                  onSubmit={handleRemovalAutoAction}
                  productType={PRODUCT_TYPE.CustomerService}
                  serviceStandardId={
                    crmsEvent?.ServiceStandard?.ServiceStandard_Id
                  }
                  selectedActionTypes={covertToActionTypeSelected(
                    crmsEvent?.ServiceStandard?.WorkflowActions ?? [],
                    nameOfWorkflowActions("ActionType_ID"),
                    nameOfRespActionType("ID")
                  )}
                />
              )}
            </>
          );
        }}
      />
    );
  }
);
