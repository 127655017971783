import { ContactsTab } from "@app/core/contacts/components/contact-tab/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const ActionsContactsTab = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { action } = useActionStore();
  const { lastSelectedRow } = useCCProductListViewStore();
  const actionId = id ? parseInt(id) : lastSelectedRow?.ID;

  return (
    <ContactsTab
      id={actionId}
      recordType={RECORDTYPE.CORE_Action}
      triggerReload={action}
    />
  );
});
