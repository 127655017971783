import { RebateSummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/components/rebate-summary/_index";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React, { useState } from "react";
import { usePageType } from "@common/hooks/usePageType";
import { observer } from "mobx-react-lite";
import { useEffectOnce } from "react-use";
import Loading from "@components/loading/Loading";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import {
  DTO_LevySummary,
  eLevySummaryTypes,
} from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/model";
import { getChargeRunLevySummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/api";
import { APIResponseError } from "@common/apis/model";
import { colLevySummary } from "@app/products/property/charge-and-notice-runs/charge-runs/components/detail/components/levies-summary/config";
import { isSuccessResponse } from "@common/apis/util";
import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ViewConfiguration } from "@app/products/property/model";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfLevySummaryGrid = nameOfFactory<DTO_LevySummary>();
export const LeviesSummary = observer((data: any) => {
  const { isManagePage } = usePageType();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [levySummaryData, setLevySummaryData] = useState<
    DTO_LevySummary[] | undefined
  >([]);
  const [colsConfigured, setColsConfigured] =
    useState<IColumnFields[]>(colLevySummary);

  const loadChargeRunLevySummary = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const [responseConfiguration, response] = await Promise.all([
      loadViewConfiguresColumns(
        ViewConfiguration.Charge_Run_LevySummary_FinancialArea,
        colLevySummary
      ),
      getChargeRunLevySummary(
        data?.dataItem?.Charge_Run_Id ?? data?.data?.Charge_Run_Id,
        eLevySummaryTypes.Financial_Area
      ),
    ]);
    if (isSuccessResponse(response) && Array.isArray(responseConfiguration)) {
      setColsConfigured(responseConfiguration);
      setLevySummaryData(response?.data?.LevySummary);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    (async () => await loadChargeRunLevySummary())();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadChargeRunLevySummary();
        }}
      />
    );

  return (
    <div className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          {isManagePage && <label className="cc-label">Levy summary</label>}
          <CCGrid
            className="cc-charge-run-levies-summary-gird"
            data={levySummaryData}
            columnFields={colsConfigured}
            primaryField={nameOfLevySummaryGrid("GroupId")}
            detail={isManagePage ? RebateSummary : undefined}
          />
        </div>
      </div>
    </div>
  );
});
