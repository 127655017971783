export enum LinkType {
  Default = 0,
  CoreAttachmentPublished_SingleUserAccess = 1,
  CoreInspectionType_ActionTypes = 2,
  Inspection_AutoActions = 3,
  CoreInspectionType_TechOneActionType = 4,
  CoreSample_Inspection = 5,
  CoreHelp_Document = 6,
  Core_BubbleUps_ParentBubble = 7,
  Core_BubbleUps_AncestorBubble = 8,
  Core_BubbleUps_AssociationBubble = 9,
  Core_Undertaking_Legislation = 10,
  Core_ActionTypeTag = 11,
  Core_ActionType_NextAction = 12,
  Core_Investigation_ActionsTaken = 13,
  Core_Investigation_Subjects = 14,
  Core_SearchWarrant_Provision = 15,
  Core_SearchWarrant_ProvisionReason = 16,
  Core_SearchWarrant_WarrantTeam = 17,
  Core_InformationSharing_VCAT_Orders = 18,
  RelatedNCItem = 19,
  Core_Investigation_RelatedEntitiesNotified = 20,
  Core_Contact_Extension_Category = 21,
  Core_Contact_Extension_SubCategory = 22,
  Core_UndertakingSource = 23,
  Core_Assessor = 24,
  Core_Education_RequiredFor = 25,
  Core_MailMessaging = 26,
  Core_Product_Renewalfee = 27,
  Core_InspectionType_Assessment_QuestionList = 28,
  CoreInspectionType_Fees = 29,
  Radiation_TypeOfDealing = 101,
  Radiation_CompetencyRequired = 102,
  Radiation_TypeOfSource = 103,
  Radiation_UseCategory = 104,
  Radiation_UseSubCategory = 105,
  Radiation_AccreditationType = 106,
  Radiation_AccreditationCategory = 107,
  Radiation_BusinessRuleCondition = 108,
  Radiation_Occupation = 109,
  Radiation_PrerequisiteSupportInformation = 110,
  Radiation_AuthorisedContact = 111,
  Radiation_BusinessRuleTypeOfDealings = 112,
  Radiation_Source_UnlinkLicence = 113,
  Radiation_DisposalPathwayUnsealedSourceRadionuclide = 114,
  Radiation_RegisterTransferSite = 115,
  Building_Practitioner = 201,
  Building_ReportingAuthorities = 202,
  Building_BuildingWorksView = 203,
  Building_PermitTypeFees = 204,
  Building_PSPermit_AutoAction = 205,
  Building_PSCertificate_AutoAction = 206,
  HealthManager_FixedPrimaryInspection = 301,
  HealthManager_TemporaryPrimaryInspection = 302,
  HealthManager_MobilePrimaryInspection = 303,
  HealthManager_InspectionAssessmentSummary = 304,
  HealthManager_TypeOfAnaesthesia = 305,
  EMS_ChildBooking = 401,
  TP_PPR_ReportLetter = 501,
  TP_PPR_ResponseLetter = 502,
  TP_Application_Category_AutoAction = 503,
  TP_Application_Assessmet_Q1 = 503,
  TP_Application_Assessmet_Q2 = 504,
  TP_Application_Assessmet_Q3 = 505,
  TP_Application_Assessmet_Q4 = 506,
  TP_Application_Assessmet_Q5 = 507,
  TP_Application_Assessmet_Q6 = 508,
  TP_Application_Assessmet_Q7 = 509,
  TP_Application_Assessmet_Q8 = 510,
  TP_Application_Assessmet_Q9 = 511,
  TP_PSAR_ResponseLetter = 512,
  TP_PSAR_ReportLetter = 513,
  TP_PSAR_PanelReportLetter = 514,
  TP_PSAR_Councils = 515,
  TP_ApplicationCategoryFees = 516,
  TP_PNF_WrokFlowAction = 517,
  TP_PNF_MailMergeCategory = 518,
  TP_PNF_Application = 519,
  TP_EPlanning_ApplicationCategoryFeeType = 520,
  TP_Application_Category_Assessment_QuestionList = 521,
  TrainingManager_SKillTypes = 601,
  SRU_Subcontractor = 701,
  SRU_HssScope = 702,
  SRU_RegistrationCategory = 703,
  SRU_FundedDivision = 704,
  SRU_RegisteredProvider = 705,
  SRU_PanelMember = 706,
  SRU_Delegate = 707,
  SRU_EnforcementDivision = 708,
  SRU_LinkedConsortium = 709,
  SRU_IssueLocation = 710,
  SRU_UnregisteredActivity = 711,
  SRU_ComplianceMonitoring_DraftReport = 712,
  SRU_ComplianceMonitoring_FinalReport = 713,
  SRU_ComplianceMonitoring_ProgressReport = 714,
  SRU_ServiceType = 715,
  SRU_ClientType = 716,
  SRU_RegistrationStatementType = 717,
  SRU_RegistrationStatementStatus = 718,
  SRU_RegistrationUndertakingLegislation = 719,
  SRU_RelatedReportableIncident = 720,
  SRU_RelatedReportableTransaction = 721,
  SRU_IssueCategory = 722,
  SRU_IssueSource = 723,
  SRU_IssueSubSource = 724,
  SRU_IssueType = 725,
  SRU_IssueLegislativeRef = 726,
  SRU_EnforcementAssociate = 727,
  HSR_Seizure = 728,
  HSR_ProvisionOfAct = 729,
  CUST_EventParent = 801,
  CUST_CD_DW_Service_User_Disability_Type = 802,
  CUST_CD_DW_Service_User_Disability_Category = 803,
  CUST_CD_DW_Necessary_Actions = 804,
  CUST_CD_DW_Human_Rights_Issues = 805,
  CUST_CD_DW_Practise_Issues = 806,
  CUST_CD_DW_How_Heard_About_Us = 807,
  CUST_CD_DW_Outcome_Actions = 808,
  CUST_CD_DW_Preferred_Contact_Time = 809,
  CUST_Issues_Issue_Outcome = 810,
  CUST_Issues_Actions_Taken = 811,
  CUST_CD_DW_Place = 812,
  CUST_CD_DW_Worker_Who_Has = 813,
  CUST_CD_DW_Service_User_Disability_Category_Sub = 814,
  CUST_CD_DW_Agencies = 815,
  CUST_CD_DW_How_Were_Service_Users_Harmed = 816,
  CUST_CD_DW_ConductContravened = 817,
  CUST_Event_ShellRecord = 818,
  SSMS_MOADocument = 901,
  CSM_Activity = 951,
  CSM_SpecialistSpeciality = 952,
  CSM_PoisonRegisterOwner = 953,
  CSM_PoisonOtherACO = 954,
  CSM_PestObservation_RecommendedAction = 955,
  CSM_PoisonAssociatedACO = 956,
  CSM_PestObservation_ObservationType = 957,
  CSM_PestObservation_Holding = 958,
  DWCMS_CompetentLanguage = 1001,
  DWCMS_ProficientLanguage = 1002,
  DWCMS_OtherLanguage = 1003,
  DWCMS_ProfessionalMembersip = 1004,
  DWCMS_ProhibitionSource = 1005,
  DWCMS_ImpairmentType = 1006,
  DWCMS_Pathway = 1007,
  FLEET_ChildBooking = 1051,
  LM_EnquiryType = 1101,
  LM_WrokFlowAction = 1102,
  LM_AssociateEvent = 1103,
  Animals_RestrictedBreed = 1201,
}
