import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RecordCountPacket } from "@common/models/recordCountPacket";

export const postChangeOwner = async (
  IntegratedReports: number[],
  Owner_ID: number
): Promise<APIResponse<RecordCountPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/core/internal/integratedreports/changeowner`,
      {
        IntegratedReports,
        Owner_ID,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
