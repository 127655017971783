import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { NoticeDetailTab } from "@app/core/notices/[id]/components/reference-sidebar/details/_index";
import { NoticeHistoryTab } from "@app/core/notices/[id]/components/reference-sidebar/history/_index";
import { NOTICES_ROUTE } from "@app/core/notices/[id]/constant";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { LocalLawsComplianceNoticesByDueStatusBookmark } from "@app/products/local-laws/compliance-notices/by-due-status/util";
import {
  LOCAL_LAW_NOTICES_LIST_VIEW_URL,
  nameOfLLNoticesView,
} from "@app/products/local-laws/compliance-notices/constant";
import { complianceNoticesRoute } from "@app/products/local-laws/compliance-notices/route";
import { LocalLawsActionBarNavDropdown } from "@app/products/local-laws/components/toolbar/nav-dropdown/_index";
import { localLawsRoute } from "@app/products/local-laws/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";
import { colLocalLawsComplianceNoticesByDueStatus } from "./config";

export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_Notice,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });

  useCCListViewActionBar({
    title: localLawsRoute.name,
    leftComponents: [
      <LocalLawsActionBarNavDropdown category={complianceNoticesRoute.path} />,
    ],
    centerComponents: [
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.LLPermits}
        dataSetFilter={MAILMERGEDATASET.LLP_Application_Notices}
        recordType={RECORDTYPE.CORE_Notice}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <DeleteButton
        recordType={RECORDTYPE.CORE_Notice}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={NOTICES_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_Notice}
        detail={
          LocalLawsComplianceNoticesByDueStatusBookmark.getBookmarkListViewDisplayName
        }
        displayName={
          LocalLawsComplianceNoticesByDueStatusBookmark.getBookmarkListViewDetailRecord
        }
        listViewDetail={
          LocalLawsComplianceNoticesByDueStatusBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          LocalLawsComplianceNoticesByDueStatusBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <NoticeDetailTab />,
      },
      { title: "History", component: <NoticeHistoryTab /> },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={colLocalLawsComplianceNoticesByDueStatus}
        dataUrl={LOCAL_LAW_NOTICES_LIST_VIEW_URL}
        primaryField={nameOfLLNoticesView("ID")}
        state={{
          group: [{ field: nameOfLLNoticesView("DueStatus"), dir: "asc" }],
          sort: [{ field: nameOfLLNoticesView("DateServed"), dir: "desc" }],
        }}
      />
    </LoadingPermissionWrapper>
  );
});
