import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { VO_Assessment } from "@app/products/property/assessments/list/model";
import {
  IPropertyContactTabCommonProps,
  PropertyContactTabCommon,
} from "@app/products/property/components/reference-sidebar/contact/common/_index";
import { nameOfFactory } from "@common/utils/common";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const nameOf = nameOfFactory<VO_Assessment>();
export const PropertyAssessmentCollectionContactTab = observer(
  ({ id, componentNumber }: IPropertyContactTabCommonProps) => {
    const { lastSelectedRow } = useDetailAssessmentListViewStore();

    const recordId = useMemo(() => {
      return id ?? lastSelectedRow?.[nameOf("Assessment_Id")];
    }, [id, lastSelectedRow]);

    return (
      <PropertyContactTabCommon
        id={recordId}
        componentNumber={componentNumber}
      />
    );
  }
);
