import { BuildingRegisterEasementFormElement } from "@app/products/building/registers/easements/[id]/components/child-screens/general/components/form-element/_index";
import { useBuildingRegistersEasementsStore } from "@app/products/building/registers/easements/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const BuildingRegisterEasementForm = observer(() => {
  const isNew = useIsNew();
  const {
    easements,
    createBuildingRegistersEasement,
    updateBuildingRegistersEasement,
  } = useBuildingRegistersEasementsStore();

  const handleSubmit = (data: any) => {
    if (isNew) {
      createBuildingRegistersEasement(data);
    } else {
      updateBuildingRegistersEasement(data);
    }
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={easements}
        render={(formRenderProps: FormRenderProps) => (
          <BuildingRegisterEasementFormElement
            formRenderProps={formRenderProps}
          />
        )}
      />
    </div>
  );
});
