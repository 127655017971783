import { eventEmitter } from "@/App";
import { postResetRenewalFlagFee } from "@app/products/local-laws/renewals/requires-renewal-notice/components/buttons/reset-renewal-flag-fee/api";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { IdentityPacket } from "@common/models/identityPacket";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useSelectionListener } from "@components/cc-grid/hooks/useSelectionListener";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

interface IPermitResetRenewalFeeFlagProps {
  isDisabled?: boolean;
  gridId?: string;
}

export const PermitResetRenewalFeeFlagButton = observer(
  ({ isDisabled = false, gridId }: IPermitResetRenewalFeeFlagProps) => {
    const { isEmptyGridSelectedRow, gridSelectedIds, setGridSelectedRows } =
      useCCProductListViewStore();
    const { loader } = useSelectionListener(gridId);
    const { pushNotificationPortal } = useNotificationPortalStore();

    const resetRenewalFeeFlagSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }: { initialData: number[] }) => {
            return postResetRenewalFlagFee(initialData);
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<IdentityPacket> = dataFromApi;
            pushNotificationPortal({
              type: "success",
              description: response.data?.Notifications,
            });
            // refresh list view
            eventEmitter.emit(CCGridEventType.RefreshOData, {
              gridIds: [gridId],
            });
            setGridSelectedRows([]);
          },
          handleError: ({ errorFromApi }) => {
            // refresh list view
            eventEmitter.emit(CCGridEventType.RefreshOData, {
              gridIds: [gridId],
            });
            pushNotificationPortal({
              type: "error",
              description: errorFromApi?.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleOnClick = async () => {
      let submitIDs = gridSelectedIds;
      if (loader.current.available) {
        submitIDs = (
          await loader.current.process({ $select: ["ID"], $flat: true })
        ).data;
      }
      resetRenewalFeeFlagSlider.fetchApi({ initialData: submitIDs });
    };

    return (
      <CCNavButton
        title="Reset Renewal Fee Flag"
        disabled={
          isEmptyGridSelectedRow ||
          isDisabled ||
          resetRenewalFeeFlagSlider.isFetching
        }
        onClick={handleOnClick}
        isLoading={resetRenewalFeeFlagSlider.isFetching}
      />
    );
  }
);
