import { PPRApplicationCategory } from "@app/products/town-planning/ppr/system-admin/application-categories/model";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<PPRApplicationCategory>();

export const colCategoryPicker: IColumnFields[] = [
  { title: "Category Name", field: nameOf("ApplicationCategory_Name") },
  {
    title: "Target Days",
    field: nameOf("TargetDays"),
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    title: "Certification",
    field: nameOf("LandDivision"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    title: "PSAR",
    field: nameOf("PSA"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    title: "PPR",
    field: nameOf("PPR"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];
