import {
  DO_AnimalClassificationLabelCorporateSetting,
  DO_DogSettingsDetailsAnimalNameCorporateSetting,
  DO_DogSettingsDetailsAssessmentNumberCorporateSetting,
  DO_DogSettingsDetailsBreedCorporateSetting,
  DO_DogSettingsDetailsCertifiedAssistanceCorporateSetting,
  DO_DogSettingsDetailsClubMemberCorporateSetting,
  DO_DogSettingsDetailsColourCorporateSetting,
  DO_DogSettingsDetailsCustomsDogCorporateSetting,
  DO_DogSettingsDetailsCustomsDogProofCorporateSetting,
  DO_DogSettingsDetailsDOBCorporateSetting,
  DO_DogSettingsDetailsDangerousCorporateSetting,
  DO_DogSettingsDetailsDogHomeAdoptionCorporateSetting,
  DO_DogSettingsDetailsDogHomeAdoptionProofCorporateSetting,
  DO_DogSettingsDetailsGenderCorporateSetting,
  DO_DogSettingsDetailsGreyhoundCorporateSetting,
  DO_DogSettingsDetailsGreyhoundProofCorporateSetting,
  DO_DogSettingsDetailsGuardDogCorporateSetting,
  DO_DogSettingsDetailsGuardDogProofCorporateSetting,
  DO_DogSettingsDetailsGuideAssistanceDogProofCorporateSetting,
  DO_DogSettingsDetailsGuideDogAssistanceCorporateSetting,
  DO_DogSettingsDetailsHealthCareCardCorporateSetting,
  DO_DogSettingsDetailsHuntingDogCorporateSetting,
  DO_DogSettingsDetailsHuntingDogProofCorporateSetting,
  DO_DogSettingsDetailsMenacingCorporateSetting,
  DO_DogSettingsDetailsObedienceProofCorporateSetting,
  DO_DogSettingsDetailsObedienceTrainedCorporateSetting,
  DO_DogSettingsDetailsOrganisationCorporateSetting,
  DO_DogSettingsDetailsOwnerPostalAddressCorporateSetting,
  DO_DogSettingsDetailsPureBreedCorporateSetting,
  DO_DogSettingsDetailsPureBreedProofCorporateSetting,
  DO_DogSettingsDetailsRestrictedBreedCorporateSetting,
  DO_DogSettingsDetailsSterlisedProofCorporateSetting,
  DO_DogSettingsDetailsWorkingCorporateSetting,
  DO_DogSettingsDetailsWorkingProofCorporateSetting,
  DO_RestrictedBreedCorporateSetting,
  GE_GeneralCorporateSetting,
  GE_InspectionPlannerCorporateSetting,
  GE_MailMergePlannerCorporateSetting,
  GE_RegistrationCorporateSetting,
  GE_ViewsCorporateSetting,
  IN_DetailsCorporateSetting,
  KN_DetailCorporateSetting,
  KN_FeesCorporateSetting,
  KN_NumberingCorporateSetting,
  NU_DetailsCorporateSetting,
  PO_DetailCorporateSetting,
  PO_NumberingCorporateSetting,
  RE_AnimalSettingsCorporateSetting,
  RE_AttachmentTitleSettingsCorporateSetting,
  RE_KennelsSettingsCorporateSetting,
} from "@app/products/animals/system-admin/settings/_id/main/constant";
import { EAnimalSettingsCategory } from "@app/products/animals/system-admin/settings/_id/main/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

export const mappingCorporateSettingAnimal: {
  [key: string]: (ECorporateSettingsField | string)[];
} = {
  // General
  [EAnimalSettingsCategory.General_General]: GE_GeneralCorporateSetting,
  [EAnimalSettingsCategory.General_Registration]:
    GE_RegistrationCorporateSetting,
  [EAnimalSettingsCategory.General_Views]: GE_ViewsCorporateSetting,
  [EAnimalSettingsCategory.General_InspectionPlanner]:
    GE_InspectionPlannerCorporateSetting,
  [EAnimalSettingsCategory.General_MailMerge]:
    GE_MailMergePlannerCorporateSetting,

  // Numbering
  [EAnimalSettingsCategory.Numbering_Details]: NU_DetailsCorporateSetting,

  // PounRegister
  [EAnimalSettingsCategory.PoundRegister_Detail]: PO_DetailCorporateSetting,
  [EAnimalSettingsCategory.PoundRegister_Numbering]:
    PO_NumberingCorporateSetting,

  //Infringements
  [EAnimalSettingsCategory.Infringements_Details]: IN_DetailsCorporateSetting,

  //Kennels
  [EAnimalSettingsCategory.Kennels_Detail]: KN_DetailCorporateSetting,
  [EAnimalSettingsCategory.Kennels_Numbering]: KN_NumberingCorporateSetting,
  [EAnimalSettingsCategory.Kennels_Fees]: KN_FeesCorporateSetting,

  // Records
  [EAnimalSettingsCategory.Records_AttachmentTitleSettings]:
    RE_AttachmentTitleSettingsCorporateSetting,
  [EAnimalSettingsCategory.Records_AnimalSettings]:
    RE_AnimalSettingsCorporateSetting,
  [EAnimalSettingsCategory.Records_KennelsSettings]:
    RE_KennelsSettingsCorporateSetting,

  // Dog Setting
  // Dog Setting Details
  [EAnimalSettingsCategory.DogSettings_Details_PureBreed]:
    DO_DogSettingsDetailsPureBreedCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_ClubMember]:
    DO_DogSettingsDetailsClubMemberCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_Organisation]:
    DO_DogSettingsDetailsOrganisationCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_Menacing]:
    DO_DogSettingsDetailsMenacingCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_HuntingDog]:
    DO_DogSettingsDetailsHuntingDogCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_Greyhound]:
    DO_DogSettingsDetailsGreyhoundCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_GuardDog]:
    DO_DogSettingsDetailsGuardDogCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_DogHomeAdoption]:
    DO_DogSettingsDetailsDogHomeAdoptionCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_ObedienceTrained]:
    DO_DogSettingsDetailsObedienceTrainedCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_CustomsDog]:
    DO_DogSettingsDetailsCustomsDogCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_HealthCareCard]:
    DO_DogSettingsDetailsHealthCareCardCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_Breed]:
    DO_DogSettingsDetailsBreedCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_GuideDogAssistance]:
    DO_DogSettingsDetailsGuideDogAssistanceCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_Colour]:
    DO_DogSettingsDetailsColourCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_DOB]:
    DO_DogSettingsDetailsDOBCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_AssessmentNumber]:
    DO_DogSettingsDetailsAssessmentNumberCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_OwnerPostalAddress]:
    DO_DogSettingsDetailsOwnerPostalAddressCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_AnimalName]:
    DO_DogSettingsDetailsAnimalNameCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_Gender]:
    DO_DogSettingsDetailsGenderCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_Dangerous]:
    DO_DogSettingsDetailsDangerousCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_Working]:
    DO_DogSettingsDetailsWorkingCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_SterlisedProof]:
    DO_DogSettingsDetailsSterlisedProofCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_ObedienceProof]:
    DO_DogSettingsDetailsObedienceProofCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_GuideOrAssistanceDogProof]:
    DO_DogSettingsDetailsGuideAssistanceDogProofCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_WorkingProof]:
    DO_DogSettingsDetailsWorkingProofCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_CustomsDogProof]:
    DO_DogSettingsDetailsCustomsDogProofCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_PureBreedProof]:
    DO_DogSettingsDetailsPureBreedProofCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_GuardDogProof]:
    DO_DogSettingsDetailsGuardDogProofCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_DogHomeAdoptionProof]:
    DO_DogSettingsDetailsDogHomeAdoptionProofCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_HuntingDogProof]:
    DO_DogSettingsDetailsHuntingDogProofCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_GreyhoundProof]:
    DO_DogSettingsDetailsGreyhoundProofCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_CertifiedAssistance]:
    DO_DogSettingsDetailsCertifiedAssistanceCorporateSetting,
  [EAnimalSettingsCategory.DogSettings_Details_RestrictedBreed]:
    DO_DogSettingsDetailsRestrictedBreedCorporateSetting,

  //  Animal Classification Label
  [EAnimalSettingsCategory.DogSettings_AnimalClassificationLabel]:
    DO_AnimalClassificationLabelCorporateSetting,

  // Restricted Breed
  [EAnimalSettingsCategory.DogSettings_RestrictedBreed]:
    DO_RestrictedBreedCorporateSetting,
};
