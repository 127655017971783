import { ELocalLawsSettingsCategory } from "@app/products/local-laws/system-admin/settings/_id/main/model";
import { IRouteStateForm } from "@app/products/local-laws/system-admin/settings/_id/main/routeState";

export const generalRouteStateLocalLawsSettings: IRouteStateForm[] = [
  {
    name: ELocalLawsSettingsCategory.General_GeneralSettings,
    component: require("./forms/general-settings/_index").default,
  },
  {
    name: ELocalLawsSettingsCategory.General_MailMerge,
    component: require("./forms/mail-merge/_index").default,
  },
  {
    name: ELocalLawsSettingsCategory.General_Communication,
    component: require("./forms/communication/_index").default,
  },
];
