import { AssociateDialog } from "@app/products/property/meters/[id]/components/action-bar/dialogs/associate/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const AssociateButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState(false);

  return (
    <>
      <CCNavButton title="Associate" onClick={() => setIsShowDialog(true)} />
      {isShowDialog && (
        <AssociateDialog onClose={() => setIsShowDialog(false)} />
      )}
    </>
  );
});
