import { loadViewConfiguresColumns } from "@app/products/property/api";
import { DTO_DeferredDuty_Account } from "@app/products/property/deferred-duty/components/action-bar/form-steps/write-off-balances/model";
import { ViewConfiguration } from "@app/products/property/model";
import { colAccountsTransaction } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/accounts/config";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { FieldArray } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const ACCOUNTS_FORM_STEP = "Accounts";

const nameOfAccounts = nameOfFactory<DTO_DeferredDuty_Account>();

export const AccountsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

export const FormStepElement = ({
  options = { accounts: [] },
}: IFormStepElement) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [colsConfig, setColsConfig] = useState<IColumnFields[]>([]);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.DeferredDuty_AccountLookup,
      colAccountsTransaction
    );
    if (Array.isArray(response)) {
      setColsConfig(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            Accounts to receive new transaction
          </label>
          {isLoading ? (
            <Loading isLoading={isLoading} />
          ) : responseLoadError ? (
            <CCLoadFailed
              responseError={responseLoadError}
              onReload={() => {
                loadViewConfig();
              }}
            />
          ) : (
            <CCGrid
              data={options.accounts}
              primaryField={nameOfAccounts("AccountId")}
              columnFields={colsConfig}
            />
          )}
        </div>
      </div>
    </section>
  );
};
