import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colRebateCalcForSelectedEntitlements: IColumnFields[] = [
  {
    field: "Charge",
    title: "Charge",
  },
  {
    field: "Levy",
    title: "Levy",
  },
  {
    field: "New",
    title: "New",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "ExistingRebates",
    title: "Existing Rebates",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Difference",
    title: "Difference",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Adjustment",
    title: "Adjustment",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
