import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfStepsNewRebateClaim {
  RebateClaim = "RebateClaimDetails",
  Comments = "Comments",
  Documents = "Documents",
}

export const createRebateClaimKeysOfSendSteps = [
  EKeysOfStepsNewRebateClaim.RebateClaim,
];

export interface DTO_Workflow_CreateRebateClaim {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_CreateRebateClaim;
}

export interface DTO_WorkflowDetail_CreateRebateClaim {
  RebateClaimDetails: DTO_RebateClaim_Detail;
}

export interface DTO_RebateClaim_Detail {
  Rebate_Claim_Id: number;
  Name: string;
  Authority_Id: number;
  Rating_Period_Id: number;
  IncludeClaims_From: Date | null;
  IncludeClaims_To: Date | null;
  RC_Is_Active: boolean;
  UserId: string;
  CreatedOn: Date | null;
  DeletedBy: string;
  DeletedOn: Date | null;
  DeletedReason: string;
  TransactionTypes: number[];
}

export interface DTO_RebateClaim_LOVs {
  RatingPeriods: DTO_LOV[];
  TransactionTypes: DTO_LOV_Transaction[];
  RebateAuthority: DTO_LOV[];
}

export interface DTO_LOV_Transaction {
  Code: string;
  Name: string;
  IsIncluded: boolean;
}

export interface RequestObjCreateRebateClaim {
  RebateClaimId: number;
}

export interface TransactionType {
  Id: number;
  IsInclude: boolean;
  TransactionType: string;
}
