import { listRoute } from "@app/products/property/schemes/list/route";
import { noticeRunsListRoute } from "@app/products/property/schemes/notice-runs/route";
import { schemesManageRoute } from "@app/products/property/schemes/[id]/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const schemesRoute: ICCRoute = {
  path: "schemes",
  name: "Schemes",
  enum: eMenuAction.Schemes,
  children: [
    listRoute,
    noticeRunsListRoute,
    {
      path: "processes",
      name: "Processes",
      enum: eMenuAction.Schemes_Generate_Menu_Items_for_Processes,
      component: require("./processes/_index").default,
    },
    schemesManageRoute,
  ],
};
