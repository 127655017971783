import { Svc_Contact } from "@app/core/contacts/_id/model";
import { isHTML } from "@common/pages/home/components/event-section/components/dashboard-view/util";
import { nameOfFactory } from "@common/utils/common";
import { isNumber } from "lodash";
const nameOf = nameOfFactory<Svc_Contact>();
export const partnerValidator = (
  values: Svc_Contact,
  valueGetter: (name: string) => any
) => {
  const ids = [
    values?.Partnership1_Contact_Id,
    values?.Partnership2_Contact_Id,
    values?.Partnership3_Contact_Id,
    values?.Partnership4_Contact_Id,
  ];

  const checkIsDuplicate = (ids: any[], position: number) => {
    let cloneIds = [...ids];
    const id = ids[position - 1];
    cloneIds.splice(position - 1, 1);
    return isNumber(id) && cloneIds.includes(id)
      ? `Partner ${position} must be different from other Partners`
      : "";
  };
  return {
    [nameOf("Partnership1_Contact_Id")]: checkIsDuplicate(ids, 1),
    [nameOf("Partnership2_Contact_Id")]: checkIsDuplicate(ids, 2),
    [nameOf("Partnership3_Contact_Id")]: checkIsDuplicate(ids, 3),
    [nameOf("Partnership4_Contact_Id")]: checkIsDuplicate(ids, 4),
  };
};

export const notAllowHTMLValidator = (value: string) => {
  if (isHTML(value)) {
    return "This field must be a string";
  }
  return "";
};
