import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IAssessmentReAllocationDialogProps {
  isLoadingYes: boolean;
  onClosePopup: () => void;
  onAsyncConfirm: () => void;
}
export default function AssessmentReAllocationDialog({
  isLoadingYes,
  onClosePopup,
  onAsyncConfirm,
}: IAssessmentReAllocationDialogProps) {
  return (
    <ConfirmDialog
      title="Reallocation of Credit"
      message="Do you wish to run the credit reallocation tool?"
      subMessage="The tool will run in the background, and a notification will be displayed upon completion."
      onClosePopup={onClosePopup}
      onAsyncConfirm={onAsyncConfirm}
      isLoadingYes={isLoadingYes}
    />
  );
}
