import { DirectDebitAccount } from "@app/products/direct-debit/accounts/model";
import { DIRECT_DEBIT_ACCOUNTS } from "@app/products/direct-debit/constant";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DirectDebitAccount>();
export const colDirectDebitAuthorisationAccounts: IColumnFields[] = [
  {
    field: nameOf("Account_Number"),
    title: "Account Number",
    linkTo: (dataItem: DirectDebitAccount) =>
      `${DIRECT_DEBIT_ACCOUNTS}/${dataItem[nameOf("Account_ID")]}`,
    locked: true,
  },
  {
    field: nameOf("Integration_Module_Type_ENUM"),
    title: "Module Type",
  },
  {
    field: nameOf("Item_Type"),
    title: "Item Type",
  },
  {
    field: nameOf("Account_Reference"),
    title: "Account Reference",
  },
  {
    field: nameOf("Manual_Description"),
    title: "Manual Description",
  },
  {
    field: nameOf("Balance"),
    format: CURRENCY_FORMAT.CURRENCY1,
    title: "Balance",
  },
  {
    field: nameOf("Balance_Date"),
    title: "Balance Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Instalment"),
    title: "Instalment",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Instalment_Date"),
    title: "Instalment Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Payment_Frequency_Name"),
    title: "Payment Frequency",
  },
  {
    field: nameOf("Start_Date"),
    title: "Start Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("End_Date"),
    title: "End Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Next_Transfer_Date"),
    title: "Next Transfer Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("AccountStatus_Name"),
    title: "Status",
  },
  {
    field: nameOf("Direct_Debit_Group_Name"),
    title: "Direct Debit Group",
  },
];
