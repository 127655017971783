import { colParcelLandUses } from "@app/products/property/parcels/[id]/components/child-screens/land-uses/config";
import { useParcelClassificationStore } from "@app/products/property/parcels/[id]/components/child-screens/land-uses/store";
import { DTO_ParcelZone } from "@app/products/property/parcels/[id]/components/child-screens/zones/model";
import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

export const ParcelLandUses = observer(() => {
  const nameOf = nameOfFactory<DTO_ParcelZone>();
  const { isLoading, loadParcelClassification, resetStore, classification } =
    useParcelClassificationStore();
  const { parcelId } = useParcelsStore();

  useEffectOnce(() => {
    if (!parcelId) {
      return;
    }
    loadParcelClassification(parcelId);
    return () => {
      resetStore();
    };
  });

  return (
    <CCGrid
      isLoading={isLoading}
      className="cc-parcel-zones-grid"
      data={classification?.LandUses ?? []}
      columnFields={colParcelLandUses}
      primaryField={nameOf("Id")}
    />
  );
});
