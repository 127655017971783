import { mockSingleBuildingEssentialSafety } from "@app/products/building/registers/essential-safety/[id]/mock";
import {
  IBuildingEssentialSafetyRequest,
  IBuildingEssentialSafetyResponse,
} from "@app/products/building/registers/essential-safety/[id]/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";
export const postBuildingEssentialSafety = async (
  requestBody: IBuildingEssentialSafetyRequest
) => {
  await sleep(2000);
};

export const putBuildingEssentialSafety = async (
  requestBody: IBuildingEssentialSafetyRequest
) => {
  await sleep(2000);
};

export const getBuildingEssentialSafetyById = async (
  id: number
): Promise<APIResponse<IBuildingEssentialSafetyResponse | undefined>> => {
  await sleep(1000);
  return {
    data: {
      ...mockSingleBuildingEssentialSafety,
      ID: id,
    } as IBuildingEssentialSafetyResponse,
    status: APIResponseStatus.SUCCESS,
    statusText: "Ok",
    headers: {},
    config: {},
  };
};
