import { PropertyCertificateForm } from "@app/products/property/certificates/[id]/components/child-screens/general/_index";
import { NewCertificateDialog } from "@app/products/property/certificates/[id]/components/dialogs/new-certificate/_index";
import { PROPERTY_CERTIFICATE_ROUTE } from "@app/products/property/certificates/[id]/constant";
import { useCertificateStore } from "@app/products/property/certificates/[id]/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router";
import { ReplaceCertificateButton } from "@app/products/property/certificates/[id]/components/action-bar/buttons/replace-certificate/_index";

export const NewPropertyCertificate = observer(() => {
  const { isLoading } = useCertificateStore();
  const history = useHistory();

  return (
    <>
      <Loading isLoading={isLoading} />
      <FormNavigation title="Certificate" />
      <FormTitle title={"New Certificate"} badge={"Being Completed"} />

      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Preview/Issue certificate"}
            htmlFor={"applicant-form-submit"}
            disabled
          />,
          <CCNavButton title={"Cancel certificate"} disabled />,
          <ReplaceCertificateButton />,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <div className="cc-manage-form-body">
            <CCGeneralPanel component={<PropertyCertificateForm />} />
          </div>
        </div>
      </div>

      <NewCertificateDialog
        onClose={() => {
          history.goBack();
        }}
        onSubmit={(certificate) => {
          history.push(`${PROPERTY_CERTIFICATE_ROUTE}/` + 8888, certificate);
        }}
      />
    </>
  );
});
