import { TOWN_PLANNING_PSAR_ROUTE } from "@app/products/town-planning/ppr/psa-referrals/_id/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewPSAReferralButton = () => {
  const history = useHistory();
  return (
    <CCNavButton
      title="New PSA Referral"
      onClick={() => history.push(`${TOWN_PLANNING_PSAR_ROUTE}/new`)}
    />
  );
};
