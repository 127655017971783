import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

export interface IRescheduleAction {
  TargetDate?: Date;
  Details?: string;
  AlertRequestor?: boolean;
}

export interface IRescheduleActionDialogProps {
  onClosePopup: () => void;
  onSubmit: (data: IRescheduleAction) => void;
  isSubmitting?: boolean;
}

export const RescheduleActionDialog = observer(
  ({ onClosePopup, onSubmit, isSubmitting }: IRescheduleActionDialogProps) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const [initialValues, setInitialValues] = useState<IRescheduleAction>();
    const nameOf = nameOfFactory<IRescheduleAction>();

    const handleSubmit = async (event: FormSubmitClickEvent) => {
      onSubmit(event.values);
    };

    useEffectOnce(() => {
      setInitialValues({
        AlertRequestor: true,
      });
    });

    return (
      <Form
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        onSubmitClick={handleSubmit}
        render={({
          onSubmit,
          modified,
          valid,
          valueGetter,
        }: FormRenderProps) => (
          <FormElement>
            <CCDialog
              maxWidth="35%"
              height="auto"
              disabled={isSubmitting}
              titleHeader={"Reschedule Details"}
              onClose={onClosePopup}
              bodyElement={
                <div className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Target date
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("TargetDate")}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE_CONTROL}
                          validator={requiredValidator}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Reschedule details
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          placeholder={"Reschedule Details"}
                          name={nameOf("Details")}
                          rows={4}
                          component={CCTextArea}
                          validator={requiredValidator}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Alert requestor of reschedule
                        </label>
                        <Field
                          name={nameOf("AlertRequestor")}
                          component={CCSwitch}
                          checked={valueGetter(nameOf("AlertRequestor"))}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    className={"cc-dialog-button"}
                    onClick={() => {
                      onClosePopup();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!modified || !valid}
                    themeColor="primary"
                    iconClass={isSubmitting ? "fas fa-spinner fa-spin" : ""}
                    onClick={onSubmit}
                    className={"cc-dialog-button"}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        )}
      />
    );
  }
);
