import { DefermentMethodFieldValue } from "@app/products/property/assessments/deferments/components/action-bar/dialogs/create-deferment/model";
import { useCreateDefermentDialogStore } from "@app/products/property/assessments/deferments/components/action-bar/dialogs/create-deferment/store";
import { nameOfLov } from "@app/products/property/model";
import {
  DATE_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const { createDefermentLOVs } = useCreateDefermentDialogStore();
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const methodFieldValue = getFieldValue("MethodId");

    useEffect(() => {
      if (
        methodFieldValue === DefermentMethodFieldValue.ONE_OFF_AMOUNT ||
        methodFieldValue === DefermentMethodFieldValue.TASMANIAN_DEFERMENT
      ) {
        onChange(nameOf("DefermentDate"), { value: new Date() });
      }
      // eslint-disable-next-line
    }, [methodFieldValue]);

    const renderField = () => {
      switch (methodFieldValue) {
        case DefermentMethodFieldValue.MANUALLY_JOURNALLED_AMOUNTS:
          return (
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCLabel
                  title="Deferment of levies is to apply from"
                  isMandatory
                />
                <Field
                  name={nameOf("IsApplyFrom")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  defaultValue={new Date()}
                  max={new Date(getFieldValue("IsApplyTo"))}
                  disabled={options?.isReadOnly}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">to</label>
                <Field
                  name={nameOf("IsApplyTo")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  min={new Date()}
                  disabled={options?.isReadOnly}
                />
              </div>
            </div>
          );
        case DefermentMethodFieldValue.ONE_OFF_AMOUNT:
        case DefermentMethodFieldValue.TASMANIAN_DEFERMENT:
          return (
            <>
              <div className="cc-field">
                <CCLabel title="Deferment date" isMandatory />
                <Field
                  name={nameOf("DefermentDate")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="One off amount" isMandatory />
                <Field
                  name={nameOf("OneOffAmount")}
                  component={CCCurrencyInput}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                  disabled={options?.isReadOnly}
                />
              </div>
            </>
          );
        default:
          return (
            <>
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <CCLabel
                    title="Deferment of levies is to apply from"
                    isMandatory
                  />
                  <Field
                    name={nameOf("IsApplyFrom")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                    defaultValue={new Date()}
                    max={
                      getFieldValue("IsApplyTo")
                        ? new Date(getFieldValue("IsApplyTo"))
                        : undefined
                    }
                    disabled={options?.isReadOnly}
                    validator={
                      !options?.isReadOnly ? requiredValidator : undefined
                    }
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">to</label>
                  <Field
                    name={nameOf("IsApplyTo")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                    min={new Date()}
                    disabled={options?.isReadOnly}
                  />
                </div>
              </div>
              <div className="cc-field">
                <CCLabel
                  title="Percentage of deferrable levies to defer"
                  isMandatory
                />
                <Field
                  name={nameOf("Percentage")}
                  placeholder={"Percentage of deferrable levies to defer"}
                  component={CCNumericTextBox}
                  format={PERCENTAGE_FORMAT.PERCENTAGE}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">Maximum Amount</label>
                  <Field
                    name={nameOf("MaximumAmount")}
                    component={CCCurrencyInput}
                    disabled={options?.isReadOnly}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Overall Maximum Amount</label>
                  <Field
                    name={nameOf("OverallMaximumAmount")}
                    component={CCCurrencyInput}
                    disabled={options?.isReadOnly}
                  />
                </div>
              </div>
            </>
          );
      }
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Assessment Id" isMandatory />
            <Field
              name={nameOf("Assessment_Id")}
              component={CCInput}
              readOnly
            />
          </div>
        </div>
        <div className="cc-field">
          <CCLabel title="Type" isMandatory />
          <Field
            name={nameOf("TypeId")}
            component={CCSearchComboBox}
            data={createDefermentLOVs?.Type ?? []}
            textField={nameOfLov("Name")}
            dataItemKey={nameOfLov("Code")}
            disabled={options?.isReadOnly}
            validator={!options?.isReadOnly ? requiredValidator : undefined}
            value={getDropdownValue(
              valueGetter(nameOf("TypeId"))?.toString(),
              createDefermentLOVs?.Type ?? [],
              "Code"
            )}
            onChange={(event: ComboBoxChangeEvent) => {
              onChange(nameOf("TypeId"), {
                value: event.target.value?.Code ?? null,
              });
            }}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Method" isMandatory />
          <Field
            name={nameOf("MethodId")}
            component={CCSearchComboBox}
            textField={nameOfLov("Name")}
            dataItemKey={nameOfLov("Code")}
            data={createDefermentLOVs?.Method ?? []}
            disabled={options?.isReadOnly}
            validator={!options?.isReadOnly ? requiredValidator : undefined}
            value={getDropdownValue(
              valueGetter(nameOf("MethodId"))?.toString(),
              createDefermentLOVs?.Method ?? [],
              "Code"
            )}
            onChange={(event: ComboBoxChangeEvent) => {
              onChange(nameOf("MethodId"), {
                value: event.target.value?.Code ?? null,
              });
            }}
          />
        </div>
        {renderField()}
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Application Date</label>
            <Field
              name={nameOf("ApplicationDate")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Approval Date</label>
            <Field
              name={nameOf("ApprovalDate")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-field">
          <label className="cc-label">Notes</label>
          <Field
            name={nameOf("Notes")}
            placeholder={"Notes"}
            component={CCTextArea}
            rows={5}
            readOnly={options?.isReadOnly}
          />
        </div>
      </section>
    );
  }
);
