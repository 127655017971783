import { DTO_Component_LineageTree } from "@app/products/property/components/lineage/model";
import { cloneDeep } from "lodash";

const addChild = (
  parent: DTO_Component_LineageTree,
  child: DTO_Component_LineageTree
) => {
  if (parent?.children) {
    parent.children?.push(child);
  } else {
    parent.children = [child];
  }
};

const convertToLineageObject = (lineageArray: DTO_Component_LineageTree[]) => {
  for (let i = 0; i < lineageArray?.length - 1; i++) {
    const lineageAtPositionI = lineageArray[i];
    for (let j = i + 1; j < lineageArray?.length; j++) {
      const lineageAtPositionJ = lineageArray[j];
      if (lineageAtPositionI.Id === lineageAtPositionJ.ParentId) {
        addChild(lineageAtPositionI, lineageAtPositionJ);
      } else if (lineageAtPositionJ.Id === lineageAtPositionI.ParentId) {
        addChild(lineageAtPositionJ, lineageAtPositionI);
      }
    }
  }
  return lineageArray;
};

export const getLineageDataTree = (data: DTO_Component_LineageTree[]) => {
  const cloneData = cloneDeep(data);
  return (
    convertToLineageObject(cloneData)?.filter(
      (lineageItem: DTO_Component_LineageTree) => lineageItem.ParentId === 0
    ) ?? []
  );
};
