import { ICCRoute } from "@common/constants/ICCRoute";

export const psaRoute: ICCRoute = {
  path: "psa",
  name: "PSA",
  children: [
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
    {
      path: "deferred",
      name: "Deferred",
      component: require("./deferred/_index").default,
    },
    {
      path: "exhibition",
      name: "Exhibition",
      component: require("./exhibition/_index").default,
    },
    {
      path: "interested-parties",
      name: "Interested Parties",
      component: require("./interested-parties/_index").default,
    },
    {
      path: "interested-parties-all",
      name: "Interested Parties All",
      component: require("./interested-parties-all/_index").default,
    },
  ],
};
