import { loadViewConfiguresColumns } from "@app/products/property/api";
import { CreateChargeRunButton } from "@app/products/property/charge-and-notice-runs/charge-runs/components/buttons/create-charge-run/_index";
import { ChargeAndNoticeRunsDetailTab } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_PIC_CHARGE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/constant";
import { LastChargeRunButton } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/components/buttons/last-charge-run/_index";
import { colListPICChargeRuns } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/config";
import { CHARGERUNS_PICCHARGERUN_LIST_VIEW_URL } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/constant";
import { VO_PIC_Charge_Run } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/model";
import {
  PICChargeRunsBookmark,
  PICChargeRunsListBookmark,
} from "@app/products/property/charge-and-notice-runs/pic-charge-runs/util";
import { chargeAndNoticeRunsRoute } from "@app/products/property/charge-and-notice-runs/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { APIResponseError } from "@common/apis/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { isNil } from "lodash";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_PIC_Charge_Run>();
export default () => {
  const { state } = useLocation<{
    notification?: IAppNotificationItemAddProps;
  }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [colsConfigured, setColsConfigured] = useState<IColumnFields[]>([]);
  const { pushNotification } = useCCAppNotificationStore();
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={chargeAndNoticeRunsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <CreateChargeRunButton isPICChargeRun />
      </CCNavButton>,
      <LastChargeRunButton />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_PIC_CHARGE_RUNS_ROUTE}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Journal}
        detail={PICChargeRunsBookmark.getBookmarkDetail}
        displayName={PICChargeRunsBookmark.getBookmarkDisplayName}
        listViewDetail={PICChargeRunsListBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          PICChargeRunsListBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <ChargeAndNoticeRunsDetailTab /> },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Charge_Run}
          />
        ),
      },
    ],
  });

  const loadViewConfig = async (
    notification?: IAppNotificationItemAddProps
  ) => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.ChargeRun_PICChargeRun_List,
      colListPICChargeRuns
    );
    if (Array.isArray(response)) {
      setColsConfigured(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
    if (!isNil(notification)) {
      pushNotification(notification);
    }
  };

  useEffectOnce(() => {
    let notificationState;
    if (state?.notification) {
      //This message use in src\app\products\property\charge-and-notice-runs\pic-charge-runs\[id]\components\buttons\delete\_index.tsx
      notificationState = state?.notification;
      window.history.replaceState({}, "");
    }
    loadViewConfig(notificationState);
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <CCProductListView
      dataUrl={CHARGERUNS_PICCHARGERUN_LIST_VIEW_URL}
      columnFields={colsConfigured}
      primaryField={nameOf("PIC_Charge_Run_Id")}
      state={{ sort: [{ field: nameOf("PIC_Charge_Run_Id"), dir: "desc" }] }}
    />
  );
};
