import { Svc_Animals_PoundRegisters } from "@app/products/animals/pound-register/model";

export const AnimalPoundRegisterByNumberBookmark = {
  getBookmarkListViewDisplayName() {
    return "Animal - Pound Register";
  },

  getBookmarkListViewDetail() {
    return "Animal - Pound Register - By Number";
  },

  getBookmarkListViewDetailRecord(selectedRow: Svc_Animals_PoundRegisters) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Animal - By Number ${dynamicDisplayName}`;
  },
};
