import { EditCommentDialog } from "@app/products/property/fire-prevention/components/dialogs/edit-comment/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const EditCommentButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { gridSelectedRows } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();
  const disabledEditButton = useMemo(() => {
    return gridSelectedRows?.length !== 1;
  }, [gridSelectedRows]);
  return (
    <>
      <CCNavButton
        title="Edit Comment"
        onClick={() => setIsShowDialog(true)}
        disabled={disabledEditButton}
      />

      {isShowDialog && (
        <EditCommentDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={(data) => {
            setIsShowDialog(false);
            pushNotification({
              title: `Comment edited successfully`,
              type: "success",
            });
          }}
        />
      )}
    </>
  );
});
