import { BuildingRegistersCertificatesFormElement } from "@app/products/building/registers/certificates/[id]/components/child-screens/general/components/form-element/_index";
import { useBuildingRegistersCertificatesStore } from "@app/products/building/registers/certificates/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const BuildingRegistersCertificatesForm = observer(() => {
  const isNew = useIsNew();
  const {
    createBuildingRegistersCertificates,
    updateBuildingRegistersCertificates,
    setIsLoading,
    certificates,
  } = useBuildingRegistersCertificatesStore();

  const handleSubmit = (data: any) => {
    setIsLoading(true);
    if (isNew) {
      createBuildingRegistersCertificates(data);
    } else {
      updateBuildingRegistersCertificates(data);
    }
    setIsLoading(false);
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={certificates}
        render={(formRenderProps: FormRenderProps) => (
          <BuildingRegistersCertificatesFormElement
            formRenderProps={formRenderProps}
          />
        )}
      />
    </div>
  );
});
