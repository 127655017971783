import {
  ContactMethodPreferred,
  Svc_Contact,
} from "@app/core/contacts/_id/model";
import { getSiteUser } from "@app/products/town-planning/ppr/[id]/components/input-picker/site-user-picker/api";
import { isSuccessResponse } from "@common/apis/util";
import { ContactsSidebar } from "@common/pages/contacts/contacts-sidebar/_index";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";

interface IDDContactsTabProps {
  existedContact?: Svc_Contact;
  isManagePage?: boolean;
}

export const DDContactsTab = observer(
  ({ existedContact, isManagePage = false }: IDDContactsTabProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<ICommonContactItemRender[] | undefined>(
      undefined
    );

    //#region STORE ========/
    const { lastSelectedRow } = useCCProductListViewStore();
    const { dataForms } = useFlexibleFormStore();
    const { pushNotificationPortal } = useNotificationPortalStore();
    //#endregion STORE =====/

    const contactData = useMemo(() => {
      if (existedContact && isManagePage) {
        return [
          {
            ID: existedContact.Contact_Id,
            DisplayName: existedContact.DisplayName,
            Email: existedContact.Email,
            PreferredMethod_ENUM: existedContact.PreferredMethodOfContact_ENUM
              ? ContactMethodPreferred[
                  existedContact.PreferredMethodOfContact_ENUM
                ]
              : "",
            ContactAlert: existedContact.ContactAlert,
            Contact_Id: existedContact.Contact_Id,
            Mobile: existedContact.Mobile,
            PostalAddress: existedContact.PostalAddress?.Formatted_SingleLine,
            PreferredMethod_Name: existedContact.PreferredMethodOfContact,
            WorkPhone: existedContact.WorkPhone,
          } as ICommonContactItemRender,
        ];
      } else {
        return data ?? [];
      }
    }, [existedContact, data, isManagePage]);
    const contactID: number = useMemo(() => {
      if (isManagePage) {
        return dataForms?.GeneralForm?.Contact_ID;
      } else {
        return lastSelectedRow?.Contact_ID;
      }
    }, [dataForms, isManagePage, lastSelectedRow]);

    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        const response = await getSiteUser(contactID ?? 0);
        if (isSuccessResponse(response) && response?.data) {
          const contactData = response?.data;
          setData([
            {
              ID: contactID ?? 0,
              DisplayName: contactData?.DisplayName ?? "",
              Type: contactData?.UserType_Name ?? "",
              Email: contactData?.Email ?? "",
              PreferredMethod_ENUM: contactData?.PreferredMethod_ENUM
                ? ContactMethodPreferred[contactData?.PreferredMethod_ENUM]
                : "",
              ContactAlert: contactData?.ContactAlert ?? "",
              Contact_Id: contactData?.Contact_ID ?? 0,
              Mobile: contactData?.Mobile ?? "",
              PostalAddress:
                contactData?.PostalAddress?.Formatted_SingleLine ?? "",
              PreferredMethod_Name: contactData?.PreferredMethod_Name ?? "",
              WorkPhone: contactData?.WorkPhone ?? "",
            },
          ]);
        } else {
          pushNotificationPortal({
            title: "Load contact failed",
            type: "error",
            autoClose: false,
          });
        }
        setIsLoading(false);
      };
      if (contactID && !existedContact && !isManagePage) {
        fetchData();
      } else {
        setData([]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactID, existedContact, isManagePage]);
    if (isLoading) return <Loading isLoading />;
    return <ContactsSidebar data={contactData} />;
  }
);
