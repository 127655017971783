import { colValue } from "@app/products/property/assessments/[id]/components/child-screens/rating/components/current-valuation/config";
import { DTO_Valuation_Value } from "@app/products/property/assessments/[id]/components/child-screens/rating/model";
import { useAssessmentRatingStore } from "@app/products/property/assessments/[id]/components/child-screens/rating/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { DATE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const nameOfValuationValue = nameOfFactory<DTO_Valuation_Value>();
export const CurrentValuation = observer(() => {
  const { assessment } = useAssessmentRatingStore();

  //Get labels
  const [valuationLabel, dateOfValuationLabel] =
    Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Valuation,
      ECustomColNameProperty.DateOfValuation,
    ]);

  const columnFieldsValuation = useMemo(() => {
    return colValue?.map((col: IColumnFields) => ({
      ...col,
      title:
        col.field === nameOfValuationValue("Value")
          ? valuationLabel
          : col.title,
    }));
  }, [valuationLabel]);

  return (
    <div className="cc-current-valuation cc-field-group">
      <label className="cc-label">Current valuation</label>
      <div className="cc-custom-sub-panel-bar">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCGrid
              className="cc-current-levies-grid"
              data={assessment?.CurrentValuation?.Values || []}
              columnFields={columnFieldsValuation}
              primaryField={nameOfValuationValue("Name")}
            />
          </div>
          <div className="cc-form-cols-3 cc-col-span-1">
            <CCValueField
              label="Effective"
              value={assessment?.CurrentValuation?.EffectiveDate}
              format={DATE_FORMAT.DATE}
            />
            <CCValueField
              label="Issued"
              value={assessment?.CurrentValuation?.IssueDate}
              format={DATE_FORMAT.DATE}
            />
            <CCValueField
              label={dateOfValuationLabel}
              value={assessment?.CurrentValuation?.ValuationDate}
              format={DATE_FORMAT.DATE}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <CCTextArea
              className="cc-form-control"
              value={assessment?.CurrentValuation?.Comment}
              readOnly
              rows={3}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Supplementary {assessment?.CurrentValuation?.Supplementary?.Id}
            </label>
            <CCTextArea
              className="cc-form-control"
              value={assessment?.CurrentValuation?.Supplementary?.Notes}
              readOnly
              rows={3}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
