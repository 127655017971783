import {
  CustomFilterUI,
  ICustomFilterUI,
} from "@components/cc-grid/components/grid-column-menus/column-menu-filter/components/custom-filter-ui/_index";
import React from "react";

export const FilterUI = (props: ICustomFilterUI) => {
  const { hideSecondFilter = true, columnConfig, ...others } = props;

  return (
    <CustomFilterUI
      hideSecondFilter={hideSecondFilter}
      columnConfig={columnConfig}
      {...others}
    />
  );
};
