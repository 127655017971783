import { ICreateJournalDialog } from "@app/products/property/meters/special-reading-list/components/action-bar/dialogs/create-journal/model";

export const mockCreateJournalDialog: ICreateJournalDialog = {
  SelectionOfUsages: 1,
  SelectionOfUsagesList: [
    { Key: 1, Value: "Scheduled and Special Readings" },
    { Key: 2, Value: "Special Readings" },
    { Key: 3, Value: "Usages for specific Assessment" },
  ],
  BillingGroupsToInclude: 1,
  BillingGroupsToIncludeList: [
    { Key: 1, Value: "All Billing Groups" },
    { Key: 2, Value: "Only Billing Group" },
  ],
  OnlyBillingGroups: 2,
  OnlyBillingGroupsList: [
    { Key: 1, Value: "Monthly Billing" },
    { Key: 2, Value: "Standard Periodic Billing" },
  ],
  SearchBy: "Assessment Number",
  SearchValue: null,
  UtilitiesRaiseCharges: 1,
  UtilitiesRaiseChargesList: [{ Key: 1, Value: "Water Usage" }],
  Date: new Date("20 Jan 2022"),
  Name: "<Utility> Usage charges",
  InstalmentPlanList: [{ Key: 1, Value: " Usage 30 Jun 2020" }],
  ApplyRemainingRebateEntitlements: false,
};
