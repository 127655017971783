import { IEnterReadingDialogData } from "@app/products/property/meters/components/dialogs/enter-special-reading/components/dialogs/enter-reading/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const mockReadingOfficer: IKeyValuePacket[] = [
  {
    Key: 1,
    Value: "1, Meter Reader",
  },
  {
    Key: 2,
    Value: "2, Meter Reader",
  },
  {
    Key: 3,
    Value: "3, Meter Reader",
  },
  {
    Key: 4,
    Value: "4, Meter Reader",
  },
  {
    Key: 5,
    Value: "ADMIN, Admin",
  },
  {
    Key: 6,
    Value: "ADMINISTRATOR, System",
  },
  {
    Key: 7,
    Value: "GLINDERMAN, Phill",
  },
  {
    Key: 8,
    Value: "MADSEN, Christine",
  },
  {
    Key: 9,
    Value: "MARTIN, Jody",
  },
  {
    Key: 10,
    Value: "MILNE, Jayne",
  },
  {
    Key: 11,
    Value: "PERSHOUSE, Bev",
  },
  {
    Key: 12,
    Value: "RATES",
  },
  {
    Key: 13,
    Value: "RECFIND, recfind",
  },
];

export const mockReadingStatus: IKeyValuePacket[] = [
  {
    Key: 1,
    Value: "Estimate",
  },
  {
    Key: 2,
    Value: "Faulty Meter",
  },
  {
    Key: 3,
    Value: "Leak on Council Side",
  },
  {
    Key: 4,
    Value: "Leak on Council Side",
  },
  {
    Key: 5,
    Value: "Leak on Owners Side",
  },
  {
    Key: 6,
    Value: "Leak on Owners Side",
  },
  {
    Key: 7,
    Value: "New Meter",
  },
  {
    Key: 8,
    Value: "New Meter",
  },
  {
    Key: 9,
    Value: "Read",
  },
  {
    Key: 10,
    Value: "Read - Broken Glass",
  },
  {
    Key: 11,
    Value: "Read - Meter Vandalised",
  },
  {
    Key: 12,
    Value: "Rechecked - high reading",
  },
  {
    Key: 13,
    Value: "Rechecked - high reading",
  },
  {
    Key: 14,
    Value: "Unable to Access Property",
  },
  {
    Key: 15,
    Value: "Unable to Locate Meter",
  },
  {
    Key: 16,
    Value: "Unable to Read",
  },
];

export const mockReasonForTheSpecialReading: IKeyValuePacket[] = [
  { Key: 1, Value: "Change of Ownership" },
  { Key: 2, Value: "Change of Tenant" },
  { Key: 3, Value: "New Meter" },
  { Key: 4, Value: "Re-connection" },
  { Key: 5, Value: "Request for meter read outside cycle" },
  { Key: 6, Value: "Vacating Tenant" },
];

export const mockEnterReading: IEnterReadingDialogData = {
  ReadingOfficer: {
    Key: 5,
    Value: "ADMIN, Admin",
  },
  ReadingStatus: {
    Key: 9,
    Value: "Read",
  },
  _option: {
    ReadingOfficer: {
      Data: mockReadingOfficer,
    },
    ReadingStatus: {
      Data: mockReadingStatus,
    },
  },
};
