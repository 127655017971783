import { LookupTable } from "@app/products/crms/[id]/model";
import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSPhoneMessagesBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `${CRMS_PRODUCT_NAME} - Phone Messages ${dynamicDisplayName}`;
  },
  getBookmarkDetail() {
    return `${CRMS_PRODUCT_NAME} Phone Message`;
  },
};

export const changeKeyMapRadioGroup = (list: Array<LookupTable>) => {
  return (
    list?.map((item: LookupTable) => {
      return {
        label: item.Description,
        value: item.LookupTable_EnumeratedValue_ENUM,
      };
    }) ?? []
  );
};
