import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { IOptionsDefault } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/infringements-notices/forms/infringement-integration-settings/model";
import { optionsNoticeExportSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/infringements-notices/forms/notice-export-settings/config";
import {
  ILookupItems,
  SettingFieldMoreOptions,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/payment-gateway/forms/payment-gateway-integration-settings/model";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import { filter } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(
  ({ dataSetting, formRenderProps }: IGlobalComponentFormProps) => {
    const { isEditing } = useSettingGlobalManageStore();
    const data = dataSetting.SettingFields;
    const { valueGetter, onChange } = formRenderProps;

    const changeTypeValue = (value: string, lookupItems: ILookupItems[]) =>
      filter(lookupItems, { Id: value })[0];

    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            {data.map((item: SettingField & SettingFieldMoreOptions) => {
              let optionsDefault: IOptionsDefault = {
                showField: false,
                typeField: "",
                rowsField: 5,
                enumFieldName: +item.FieldName,
              };

              const options = optionsNoticeExportSettings(
                optionsDefault,
                formRenderProps
              );
              return (
                options.showField && (
                  <>
                    {item.DataType === "Lookup" && (
                      <div className="cc-field">
                        <label className="cc-label">{item.Title}</label>
                        <Field
                          name={item.FieldName}
                          component={CCDropDownList}
                          data={item?.LookupItems}
                          value={changeTypeValue(
                            valueGetter(item.FieldName),
                            item.LookupItems
                          )}
                          textField="Name"
                          dataItemKey="Id"
                          disabled={!isEditing}
                          onChange={(e: DropDownListChangeEvent) => {
                            onChange(item?.FieldName, {
                              value: e.value.Id,
                            });
                          }}
                        />
                      </div>
                    )}
                    {item?.DataType === "Boolean" && (
                      <div className="cc-field">
                        <label className="cc-label">{item?.Title}</label>
                        <Field
                          name={item?.FieldName}
                          component={CCSwitch}
                          disabled={!isEditing}
                          checked={valueGetter(item?.FieldName)}
                        />
                      </div>
                    )}

                    {item?.DataType === "DateTime" && (
                      <div className="cc-field">
                        <label className="cc-label">{item?.Title}</label>
                        <Field
                          name={item?.FieldName}
                          format={DATETIME_FORMAT.DATETIME_CONTROL}
                          component={CCDateTimePicker}
                          disabled={!isEditing}
                        />
                      </div>
                    )}

                    {item.DataType === "String" && options.typeField === "" && (
                      <div className="cc-field">
                        <label className="cc-label">
                          {item.Title}
                          {item?.IsMandatory && (
                            <CCTooltip type="validator" position="right" />
                          )}
                        </label>
                        <Field
                          name={item.FieldName}
                          component={CCInput}
                          placeholder={item.Title}
                          disabled={!isEditing}
                          validator={
                            item?.IsMandatory ? requiredValidator : undefined
                          }
                        />
                      </div>
                    )}
                    {options.typeField === "numericTextBox" && (
                      <div className="cc-field">
                        <label className="cc-label">
                          {item.Title}
                          {item?.IsMandatory && (
                            <CCTooltip type="validator" position="right" />
                          )}
                        </label>
                        <Field
                          name={item.FieldName}
                          component={CCNumericTextBox}
                          placeholder={item.Title}
                          disabled={!isEditing}
                          validator={
                            item?.IsMandatory ? requiredValidator : undefined
                          }
                        />
                      </div>
                    )}

                    {options.typeField === "textArea" && (
                      <div className="cc-field">
                        <label className="cc-label">
                          {item.Title}
                          {item?.IsMandatory && (
                            <CCTooltip type="validator" position="right" />
                          )}
                          {item?.HelpText && (
                            <CCTooltip
                              type="custom"
                              content=" "
                              position="auto"
                              clickToOpen
                              customTemplate={
                                <>{sanitizeHtml(item.HelpText)}</>
                              }
                            >
                              <i className="fa fa-info-circle ml-1 text-accent" />
                            </CCTooltip>
                          )}
                        </label>
                        <Field
                          name={item.FieldName}
                          component={CCTextArea}
                          placeholder={item.Title}
                          disabled={!isEditing}
                          validator={
                            item?.IsMandatory ? requiredValidator : undefined
                          }
                          rows={options.rowsField}
                        />
                        {item?.Description && (
                          <label className="cc-settings-description">
                            {sanitizeHtml(item?.Description)}
                          </label>
                        )}
                      </div>
                    )}
                  </>
                )
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);
