import { ICCRoute } from "@common/constants/ICCRoute";
export const actionsRoute: ICCRoute = {
  path: "actions",
  name: "Actions",
  children: [
    {
      path: "my-actions",
      name: "My Actions",
      component: require("./my-actions/_index").default,
    },
    {
      path: "all-actions",
      name: "All Actions",
      component: require("./all-actions/_index").default,
    },
    {
      path: "outstanding-actions",
      name: "Outstanding Actions",
      component: require("./outstanding-actions/_index").default,
    },
  ],
};
