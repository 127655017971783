import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { ScheduleSpecialReadingsButton } from "@app/products/property/meters/list/components/action-bar/buttons/schedule-special-readings/_index";
import { metersRoute } from "@app/products/property/meters/route";
import { EditSpecialReadingButton } from "@app/products/property/meters/special-reading-list/components/action-bar/buttons/edit-special-reading/_index";
import { EnterSpecialReadingButton } from "@app/products/property/meters/special-reading-list/components/action-bar/buttons/enter-special-reading/_index";
import { mockAssessmentSpecialReadingList } from "@app/products/property/meters/special-reading-list/mock";
import { MeterSpecialReadingListDetailTab } from "@app/products/property/meters/special-reading-list/[id]/components/reference-sidebar/detail/_index";
import { propertyRoute } from "@app/products/property/route";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import { default as React, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CreateJournalButton } from "./components/action-bar/buttons/create-journal/_index";
import { colAssessmentSpecialReadingList } from "./config";

export default observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={metersRoute.path} />,
    ],
    centerComponents: [
      <CreateJournalButton />,
      <ScheduleSpecialReadingsButton />,
      <EnterSpecialReadingButton />,
      <EditSpecialReadingButton />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      //  Holding now but can use later
      // <ListViewBookmarkIcon url={PROPERTY_METERS_ROUTE} />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <MeterSpecialReadingListDetailTab />,
      },
    ],
  });

  const location = useLocation();
  const state: any = location.state;

  useEffect(() => {
    if (state && state?.notification) {
      pushNotification(state.notification);
    }
  }, [state, pushNotification]);

  return (
    <CCProductListView
      data={mockAssessmentSpecialReadingList}
      columnFields={colAssessmentSpecialReadingList}
      primaryField={"MeterSpecialReadId"}
    />
  );
});
