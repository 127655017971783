import { DTO_Supplementary_Details } from "@app/products/property/supplementary-rates/[id]/components/child-screens/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const toggleLevyCalculations = async (
  supplementaryId: number
): Promise<APIResponse<DTO_Supplementary_Details | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<DTO_Supplementary_Details>(
      `api/property/internal/supplementary/${supplementaryId}/togglelevycalcflag`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
