import { DTO_AssociatedItem_DebtRecoveryExemption } from "@app/products/property/components/associations/components/debt-recovery-exemption/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_DebtRecoveryExemption>();

export const colAssociationsDebtRecoveryExemption: IColumnFields[] = [
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Reason"),
    title: "Reason",
  },
  {
    field: nameOf("ActiveFrom"),
    title: "Active From",
  },
  {
    field: nameOf("ActiveTo"),
    title: "Active To",
  },
  {
    field: nameOf("AssessmentNumber"),
    title: "Assessment Number",
  },
  {
    field: nameOf("AssessmentReference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("CreatedBy"),
    title: "Created By",
  },
  {
    field: nameOf("CreatedOn"),
    title: "Created On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
