export interface IPropertyData {
  Id: number;
  Locality: string;
  Street: string;
  StreetType: string;
  HouseNumber: string;
  PropertyName: string;
  Owner: string;
  OwnerAddress: string;
  Ratepayer: string;
  RatepayerAddress: string;
  LandArea: string;
  LegalDescription: string;
  Clearance: string;
  Outcome: string;
  AssessmentNumber: string;
  ParcelId: number;
  CaseId: number;
}

export interface IPropertyGridData extends Array<IPropertyData> {}

export enum OPTION_SEARCH {
  OWNER = "Owner",
  STREET = "Street",
  LOCALITY = "Locality",
  HOUSE_NO = "HouseNumber",
  PARCEL_ID = "ParcelId",
  RATEPAYER = "Ratepayer",
  RATEPAYER_ADDRESS = "RatepayerAddress",
  ASSESS_NO = "AssessmentNumber",
  OWNER_ADDRESS = "OwnerAddress",
}

export interface IFilterSearchResult {
  owner: string;
  street: string;
  locality: string;
  houseNumber: string;
  parcelId: string;
  ratepayer: string;
  ratepayerAddress: string;
  assessmentNumber: string;
  ownerAddress: string;
}
