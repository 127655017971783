import { eventEmitter } from "@/App";
import { loadViewConfiguresColumns } from "@app/products/property/api";
import { nameOfLov, ViewConfiguration } from "@app/products/property/model";
import {
  deleteSupplementaryAssessmentLevy,
  saveSupplementaryAssessmentLevy,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/assessment-levies/api";
import {
  colAssessmentLevy,
  getSupplementaryLeviesDataUrl,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/assessment-levies/config";
import { FormAssessmentLevyDialog } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/assessment-levies/dialogs/assessment-levy/_index";
import { DTO_Supplementary_AssessmentLevy } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/assessment-levies/dialogs/assessment-levy/model";
import { VO_Supp_Assessment_Levy } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/assessment-levies/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { getDropdownValue, getUUID, nameOfFactory } from "@common/utils/common";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid, IDataReceivedParams } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

const ASSESSMENT_LEVIES_GRID_ID = getUUID();

export const validatorLevies = (value: any): string | undefined => {
  if (value?.AssessmentLevies?.length > 0) {
    return undefined;
  }
  return `At least one levy is required.`;
};
export const AssessmentLeviesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? validatorLevies : undefined}
    />
  );
};

const nameOfAssessmentLevy = nameOfFactory<VO_Supp_Assessment_Levy>();
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
    localNotificationRef,
    options = {
      isReadOnly: false,
      setIsDisabled: () => {},
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const selectedLevy = getFieldValue("LevySelected");

    //Use ref
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    //Use store
    const { assessmentAdjustmentLOVs, supplementaryAssessmentId } =
      useSupplementaryRatesStore();

    //Use state
    const [colFields, setColFields] = useState<IColumnFields[]>(
      colAssessmentLevy ?? []
    );
    const [showConfirmDeletionDialog, setShowConfirmDeletionDialog] =
      useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(false);
    const [assessLevyDialogInitValues, setAssessLevyDialogInitValues] =
      useState<DTO_Supplementary_AssessmentLevy>();
    const [showAssessmentLevyDialog, setShowAssessmentLevyDialog] =
      useState<boolean>(false);
    const [isDeletingLevy, setIsDeletingLevy] = useState<boolean>(false);
    const [isSavingLevy, setIsSavingLevy] = useState<boolean>(false);

    /**
     * Load view configuration for levies grid
     */
    const loadAssessmentLeviesView = async () => {
      setIsLoadingStep(true);
      const response = await loadViewConfiguresColumns(
        ViewConfiguration.AssessmentAdjustment_Levies,
        colAssessmentLevy
      );
      setIsLoadingStep(false);
      if (Array.isArray(response)) {
        let newColumns = response;
        if (!options?.isReadOnly) {
          newColumns = response.map((itemField: IColumnFields) => {
            if (itemField.field !== nameOfAssessmentLevy("Levy_Name")) {
              return itemField;
            }
            return {
              ...itemField,
              handleOnClick: (dataItem: VO_Supp_Assessment_Levy) => {
                const mappingData = {
                  LevyCategoryId: dataItem.Levy_Category_Id,
                  LevyId: dataItem.Levy_Id,
                  ChangeReasonId: dataItem.Change_Reason_Id,
                  NumberOfMinimums: dataItem.AL_Number_of_Minimums,
                  Quantity_Amount: dataItem.AL_Levy_Quantity_Amount,
                  Percentage: dataItem.AL_Percentage, //Percentage of levy
                  BaseAdjustmentRate: dataItem.AL_Base_Adjustment_Rate, //Percentage of amount
                  RateByValueAdjustmentRate:
                    dataItem.AL_RateByValue_Adjustment_Rate, //Percentage of rate by valuation
                  Active_From_Date: dataItem.AL_Active_From_Date,
                  Active_To_Date: dataItem.AL_Active_To_Date,
                  LRCM_Method: dataItem.LRCM_Method,
                  Assessment_Levy_Id: dataItem.Assessment_Levy_Id,
                  NoInterestOnLevy: dataItem.AL_No_Interest_on_Levy,
                };
                setAssessLevyDialogInitValues(
                  mappingData as DTO_Supplementary_AssessmentLevy
                );
                setShowAssessmentLevyDialog(true);
                setIsNew(false);
              },
            };
          });
        }
        setColFields(newColumns);
      } else {
        setLoadFailedStep({
          onReload: () => {
            loadAssessmentLeviesView();
          },
          responseError: {
            status: response.status,
            error: response.error ?? "Load failed",
          },
        });
      }
    };

    const handleOnDataReceived = async (params: IDataReceivedParams) => {
      onChange(nameOf("AssessmentLevies"), {
        value: params.result?.data ?? [],
      });
      options?.setIsDisabled(false);
      return params.result;
    };

    useEffectOnce(() => {
      loadAssessmentLeviesView();
    });

    /**
     * Handle saving levy (add/update)
     * @param data
     */
    const handleSaveLevy = async (data: DTO_Supplementary_AssessmentLevy) => {
      setIsSavingLevy(true);
      //call API
      const response = await saveSupplementaryAssessmentLevy(
        supplementaryAssessmentId,
        data
      );
      const defaultSuccessMessage = `Levy has been ${
        isNew ? "added" : "updated"
      } successfully`;
      const defaultFailedMessage = `${isNew ? "Add" : "Update"} levy failed`;
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        setShowAssessmentLevyDialog(false);
        //Reload levy grid
        eventEmitter.emit(CCGridEventType.RefreshOData, {
          gridIds: [ASSESSMENT_LEVIES_GRID_ID],
        });
        localNotificationRef?.current?.pushNotification({
          title: response.data.SuccessMessage ?? defaultSuccessMessage,
          type: "success",
        });
      } else {
        //Local notification
        notificationRef?.current?.pushNotification({
          autoClose: false,
          title: response.data.ErrorMessage ?? defaultFailedMessage,
          type: "error",
        });
      }
      setIsSavingLevy(false);
    };

    /**
     * Handle grid selection change
     * @param dataItem
     * @param field
     */
    const handleGridSelectionChange = (
      dataItem: VO_Supp_Assessment_Levy[],
      field: string
    ) => {
      let newSelected = dataItem ? dataItem[0] : undefined;
      onChange(nameOf(field), {
        value: newSelected,
      });
    };

    /**
     * Handle deleting assessment levy
     */
    const handleDelete = async () => {
      const selectedLevyID = selectedLevy?.Assessment_Levy_Id;
      setIsDeletingLevy(true);
      //call API
      const response = await deleteSupplementaryAssessmentLevy(
        supplementaryAssessmentId,
        selectedLevyID
      );
      setShowConfirmDeletionDialog(false);
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        //Reload levy grid
        options?.setIsDisabled(true);
        eventEmitter.emit(CCGridEventType.RefreshOData, {
          gridIds: [ASSESSMENT_LEVIES_GRID_ID],
        });
        localNotificationRef?.current?.pushNotification({
          title:
            response.data.SuccessMessage ??
            "Levy has been deleted successfully",
          type: "success",
        });
        handleGridSelectionChange([], "LevySelected");
      } else {
        //Local notification
        notificationRef?.current?.pushNotification({
          autoClose: false,
          title: response?.data?.ErrorMessage ?? `Delete levy failed`,
          type: "error",
        });
      }
      setIsDeletingLevy(false);
    };

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }

    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }

    return (
      <>
        <section className="cc-field-group mb-4">
          <CCLabel title="Land use" />
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Primary</label>
                <Field
                  name={nameOf("PrimaryLandUseId")}
                  data={assessmentAdjustmentLOVs?.LandUse ?? []}
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  value={getDropdownValue(
                    "" + getFieldValue("PrimaryLandUseId"),
                    assessmentAdjustmentLOVs?.LandUse ?? [],
                    nameOfLov("Code")
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange(nameOf("PrimaryLandUseId"), {
                      value: event.target.value?.Code ?? null,
                    });
                  }}
                />
              </div>

              <div className="cc-field">
                <label className="cc-label">Secondary</label>
                <Field
                  name={nameOf("SecondaryLandUseId")}
                  data={assessmentAdjustmentLOVs?.LandUse ?? []}
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  value={getDropdownValue(
                    "" + getFieldValue("SecondaryLandUseId"),
                    assessmentAdjustmentLOVs?.LandUse ?? [],
                    nameOfLov("Code")
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange(nameOf("SecondaryLandUseId"), {
                      value: event.target.value?.Code ?? null,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          {showAssessmentLevyDialog && (
            <FormAssessmentLevyDialog
              isNew={isNew}
              initialValues={assessLevyDialogInitValues}
              onClose={() => setShowAssessmentLevyDialog(false)}
              onSubmit={handleSaveLevy}
              isSubmitting={isSavingLevy}
              notificationRef={notificationRef}
              primaryLandUseId={getFieldValue("PrimaryLandUseId")}
            />
          )}
        </section>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel
              title="Levies"
              isMandatory
              errorMessage={errors?.[nameOf("")]}
            />
            <CCGrid
              gridId={ASSESSMENT_LEVIES_GRID_ID}
              dataUrl={getSupplementaryLeviesDataUrl(supplementaryAssessmentId)}
              columnFields={colFields}
              primaryField={nameOfAssessmentLevy("Assessment_Levy_Id")}
              itemPerPage={10}
              onDataChange={(dataItem: VO_Supp_Assessment_Levy[]) => {
                onChange(nameOf("AssessmentLevies"), {
                  value: [...dataItem],
                });
              }}
              onDataReceived={handleOnDataReceived}
              selectableMode="single"
              onSelectionChange={(dataItem: VO_Supp_Assessment_Levy[]) => {
                handleGridSelectionChange(dataItem, "LevySelected");
              }}
              selectedRows={selectedLevy ? [selectedLevy] : []}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-plus"
                    title="Add Levy"
                    onClick={() => {
                      setShowAssessmentLevyDialog(true);
                      setIsNew(true);
                    }}
                    disabled={options?.isReadOnly}
                  />
                  <Button
                    iconClass="fas fa-minus"
                    title="Remove Levy"
                    disabled={
                      !selectedLevy || selectedLevy?.SAL_Is_Existing_Levy
                    }
                    onClick={() => {
                      setShowConfirmDeletionDialog(true);
                    }}
                  />
                </div>
              }
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>
        {showConfirmDeletionDialog && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={"Are you sure you wish to remove this levy?"}
            onClosePopup={() => {
              setShowConfirmDeletionDialog(false);
            }}
            onAsyncConfirm={handleDelete}
            isLoadingYes={isDeletingLevy}
          />
        )}
      </>
    );
  }
);
