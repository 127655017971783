import { eventEmitter } from "@/App";
import { Attachment } from "@app/core/documents/model";
import { ImportDialog } from "@app/core/import/dialogs/_index";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { postImportActionTypeList } from "@common/pages/settings/lookups/action-types/components/action-bar/buttons/import/api";
import { importDescriptionContent } from "@common/pages/settings/lookups/keywords/_index";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ImportActionTypesButton = observer(() => {
  const { cancelToken } = useCancelRequest();
  const { pushNotification } = useCCAppNotificationStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowAttachmentDialog, setIsShowAttachmentDialog] = useState(false);

  const handleOnSubmit = async (
    data: Attachment,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    setIsLoading(true);
    const response = await postImportActionTypeList(data, cancelToken());
    setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      pushNotification({
        title: "Items imported successfully",
        type: "success",
      });
      setIsShowAttachmentDialog(false);
      eventEmitter.emit(CCGridEventType.RefreshOData);
    } else {
      notificationRef?.current?.resetNotifications();
      notificationRef?.current?.pushNotification({
        title: "Import items failed",
        type: "error",
        autoClose: false,
        description: response.data?.Errors ?? response?.error,
      });
    }
  };

  return (
    <>
      <CCNavButton
        title="Import action types"
        onClick={() => setIsShowAttachmentDialog(true)}
        isLoading={isLoading}
      />
      {isShowAttachmentDialog && (
        <ImportDialog
          onClose={() => setIsShowAttachmentDialog(false)}
          onSubmit={handleOnSubmit}
          isLoadingButton={isLoading}
          primaryImportFieldName="Name"
          description={importDescriptionContent}
        />
      )}
    </>
  );
});
