import { useDebtRecoveryStore } from "@app/products/property/assessments/debt-recovery/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

export const ViewActionButton = observer(() => {
  const { debtRecoveryActionSelectedRow } = useDebtRecoveryStore();

  return (
    <CCNavButton title="View" disabled={isNil(debtRecoveryActionSelectedRow)} />
  );
});
