import { IKeyValuePacket } from "@common/models/keyValuePacket";

export interface IEnterReadingDialog {
  MeterInstallationId?: number;
  ReadingStatus?: IKeyValuePacket | string;
  ReadingOfficer?: IKeyValuePacket | string;
  PreviousReadingDate?: Date | string;
  CurrentReadingDate?: Date | string;
  NumberOfDays?: Date | string;
  PreviousReading?: number;
  CurrentReading?: number;
  Usage?: number;
  Comment?: string;
  UnitOfMeasure?: string;
}
export interface IEnterReadingDialogData extends IEnterReadingDialog {
  _option?: {
    ReadingStatus: {
      Data: IKeyValuePacket[];
    };
    ReadingOfficer: {
      Data: IKeyValuePacket[];
    };
  };
}
export const MAX_READING = 9999;
