import { ICCRoute } from "@common/constants/ICCRoute";
export const psaReferralsRoute: ICCRoute = {
  path: "psa-referrals",
  name: "PSA Referrals",
  children: [
    {
      path: "my-applications",
      name: "My Applications",
      component: require("./my-applications/_index").default,
    },
    // NOTE: Waiting for confirm from JiSha (register is current-register ?)
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
    {
      path: "current-by-officer",
      name: "Current By Officer",
      component: require("./current-by-officer/_index").default,
    },
    {
      path: "current-by-team-leader",
      name: "Current By Delegated Approver",
      component: require("./current-by-team-leader/_index").default,
    },
    {
      path: "hearing-calendar",
      name: "Hearing Calendar",
      component: require("./hearing-calendar/_index").default,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./_id/_index").default,
    },
  ],
};
