import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const collectionsRoute: ICCRoute = {
  path: "collections",
  name: "Collections",
  enum: eMenuAction.Collections_Folder,
  children: [
    //use when have collection manage page
    // {
    //   path: ":id(new|[0-9]+)",
    //   component: require("./[id]/_index").default,
    // },
    {
      path: "list",
      name: "List",
      enum: eMenuAction.Collections_List,
      component: require("./list/_index").default,
    },
  ],
};
