import { DTO_Workflow_JournalAssessmentAdjustCharge } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/model";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postProcessAdjustCharge = async (
  workflowType: WorkflowProcessMode,
  payload: DTO_Workflow_JournalAssessmentAdjustCharge
): Promise<
  APIResponse<
    ResponseMessageWorkflow<DTO_Workflow_JournalAssessmentAdjustCharge>
  >
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/assessmentadjustcharges/process/${workflowType}`,
      payload
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataAdjustCharge =
  | [
      APIResponse<DTO_Journal_LOVs>,
      APIResponse<DTO_Workflow_JournalAssessmentAdjustCharge>
    ]
  | APIResponse<DTO_Journal_LOVs | DTO_Workflow_JournalAssessmentAdjustCharge>
  | undefined;
export const getInitialDataAdjustCharge = async (
  workflowDraftId: number | undefined,
  getNewPayload: RequestJournalAssessmentAdjustChargeObj
): Promise<IGetInitialDataAdjustCharge> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_LOVs>(
        "api/property/internal/journal/lovs"
      ),
      !isNil(workflowDraftId)
        ? getWorkflowAdjustCharge(workflowDraftId)
        : getNewWorkflowAdjustCharge(getNewPayload),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowAdjustCharge = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_JournalAssessmentAdjustCharge>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/assessmentadjustcharges/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export interface RequestJournalAssessmentAdjustChargeObj {
  AssessmentId: number;
  TransactionId: number | null;
  Charge_Ids: number[];
}

export const getNewWorkflowAdjustCharge = async (
  payload: RequestJournalAssessmentAdjustChargeObj
): Promise<APIResponse<DTO_Workflow_JournalAssessmentAdjustCharge>> => {
  try {
    return await CoreAPIService.getClient().post(
      "api/property/internal/workflow/assessmentadjustcharges/new",
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
