import {
  apiCoreGetConditionReference,
  apiCoreGetConditionReferenceLOVs,
  apiCoreSaveConditionReference,
} from "@app/core/components/common/utils";
import {
  ConditionReference,
  Svc_ConditionReference_Lovs,
  Svc_Form_ConditionReference,
} from "@app/core/condition-reference/[id]/model";

import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const getConditionReferenceById = async (
  conditionId: number,
  cancelToken: CancelToken
): Promise<
  APIResponse<IIdentityPacket<Svc_Form_ConditionReference>> | undefined
> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<Svc_Form_ConditionReference>
    >(apiCoreGetConditionReference(conditionId), { cancelToken });
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const getConditionReferenceLovs = async (
  productType: PRODUCT_TYPE_NUMBER,
  cancelToken: CancelToken
): Promise<
  APIResponse<IIdentityPacket<Svc_ConditionReference_Lovs>> | undefined
> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<Svc_ConditionReference_Lovs>
    >(apiCoreGetConditionReferenceLOVs(), {
      params: { productType },
      cancelToken,
    });
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postSaveConditionReference = async (
  condition: ConditionReference,
  cancelToken: CancelToken
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreSaveConditionReference(),
      condition,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
