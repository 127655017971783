import { GeneralLedgerAccountsResponse } from "@app/products/property/schemes/[id]/components/forms/existed/components/action-bar/buttons/edit-gl-account/model";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { colInterestAndInstalmentDetailsGL } from "./config";

interface IProps {
  onClose: () => void;
  onSave?: (data?: any) => void;
  initialForm?: GeneralLedgerAccountsResponse | undefined;
}

export const EditGLAccountDialogs = observer(
  ({ onClose, onSave, initialForm }: IProps) => {
    return (
      <Form
        initialValues={initialForm}
        render={(formRenderProps: FormRenderProps) => {
          const nameOf = nameOfFactory<GeneralLedgerAccountsResponse>();
          const { valueGetter } = formRenderProps;
          return (
            <CCDialog
              titleHeader={"Scheme General Ledger Accounts"}
              onClose={onClose}
              maxWidth="65%"
              maxHeight="70%"
              bodyElement={
                <div className="cc-new-arrangement cc-form">
                  <FormElement
                    onSubmit={formRenderProps.onSubmit}
                    className="cc-field-group"
                  >
                    <CCGrid
                      data={valueGetter(nameOf("GeneralLedgerAccounts"))}
                      columnFields={colInterestAndInstalmentDetailsGL}
                      primaryField={"GLAccountType"}
                      itemPerPage={15}
                      editableMode="cell"
                      onDataChange={(dataItem: any) => {
                        formRenderProps.onChange(
                          nameOf("GeneralLedgerAccounts"),
                          {
                            value: dataItem,
                          }
                        );
                      }}
                    />
                  </FormElement>
                </div>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    onClick={() => {
                      let data = valueGetter(nameOf("GeneralLedgerAccounts"));
                      if (onSave && data) {
                        //onSave(data);
                      }
                      onClose();
                    }}
                    type={"submit"}
                    disabled={!formRenderProps.valid}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
