import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ParcelsAssociationsTab } from "@app/products/property/parcels/[id]/components/reference-sidebar/associations/_index";
import { ParcelDetailTab } from "@app/products/property/parcels/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_PARCEL_ROUTE } from "@app/products/property/parcels/[id]/constant";
import { PROPERTY_PARCEL_REGISTER_ALL_LIST_VIEW_URL } from "@app/products/property/parcels/list/constant";
import { VO_Parcel } from "@app/products/property/parcels/list/model";
import { CommunicationButtonParcelLocality } from "@app/products/property/parcels/locality-street-address/components/button/communication-parcel-locality/_index";
import { ActionBarExportIconParcelLocality } from "@app/products/property/parcels/locality-street-address/components/button/export/_index";
import { LocalityStreetAddressActionBarInfoIcon } from "@app/products/property/parcels/locality-street-address/components/button/info/_index";
import { ListViewBookmarkIconParcelLocality } from "@app/products/property/parcels/locality-street-address/components/button/list-view-bookmark-parcel-locality/_index";
import { MailMergeButtonParcelLocality } from "@app/products/property/parcels/locality-street-address/components/button/mail-merge-parcel-locality/_index";
import { DetailParcel } from "@app/products/property/parcels/locality-street-address/components/detail-parcel/_index";
import { colListLocalityStreetAddress } from "@app/products/property/parcels/locality-street-address/config";
import { localityStreetAddressBookmark } from "@app/products/property/parcels/locality-street-address/util";
import { parcelRoute } from "@app/products/property/parcels/route";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
} from "@app/products/property/parcels/util";
import { propertyRoute } from "@app/products/property/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

const nameOf = nameOfFactory<VO_Parcel>();

export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    centerComponents: [
      <MailMergeButtonParcelLocality
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Parcel}
        recordType={RECORDTYPE.CommunityProperty_Parcel}
      />,
      <CommunicationButtonParcelLocality
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Parcel}
        keyField={"Parcel_Id"}
      />,
    ],
    leftComponents: [
      <PropertyActionBarNavDropdown category={parcelRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIconParcelLocality />,
      <ActionBarFilterIcon />,
      <LocalityStreetAddressActionBarInfoIcon />,
      <ListViewBookmarkIconParcelLocality
        linkUrl={PROPERTY_PARCEL_ROUTE}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        listViewDisplayName={
          localityStreetAddressBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={localityStreetAddressBookmark.getBookmarkListViewDetail}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <ParcelDetailTab />,
      },
      { title: "Map", component: <MapTab /> },
      {
        title: "Contacts",
        component: <PropertyContactTab componentNumber={eComponent.Parcel} />,
      },
      {
        title: "Related",
        component: <ParcelsAssociationsTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Parcel}
          />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colListLocalityStreetAddress}
      primaryField={nameOf("Parcel_Id")}
      dataUrl={PROPERTY_PARCEL_REGISTER_ALL_LIST_VIEW_URL}
      state={{
        group: [
          { field: nameOf("Locality_Name"), dir: "asc" },
          { field: nameOf("Property_Name_Address_Locality"), dir: "asc" },
        ],
      }}
      selectableMode="none"
      detail={DetailParcel}
    />
  );
};
