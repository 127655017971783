import { NOTICES_ROUTE } from "@app/core/notices/[id]/constant";
import { HMNoticeView } from "@app/products/hm/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const nameOfHMNoticeView = nameOfFactory<HMNoticeView>();
export const colHMNoticesGenerator = (isDueStatus: boolean) => {
  if (isDueStatus) {
    return colHMNotices;
  }
  return colHMNotices.filter(
    (col) => col.field !== nameOfHMNoticeView("DueStatus")
  );
};

const colHMNotices: IColumnFields[] = [
  {
    field: nameOfHMNoticeView("ReferenceNumber"),
    title: "Registration Number",
    linkTo: (dataItem: HMNoticeView) => {
      return `${NOTICES_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: nameOfHMNoticeView("DateServed"),
    title: "Served",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMNoticeView("TradingName"),
    title: "Name",
  },
  { field: nameOfHMNoticeView("Location"), title: "Location" },
  {
    field: nameOfHMNoticeView("Type"),
    title: "Type",
  },
  {
    field: nameOfHMNoticeView("NoticeCategory"),
    title: "Notice Category",
  },
  {
    field: nameOfHMNoticeView("Officer"),
    title: "Officer",
  },
  {
    field: nameOfHMNoticeView("DateDue"),
    title: "Compliance Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMNoticeView("DateRevoked"),
    title: "Complied / Revoked",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMNoticeView("DueStatus"),
    title: "Due Status",
  },
];
