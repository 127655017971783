import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IConfirmReverseVoidReadingsDialog {
  onClosePopup: () => void;
  onConfirmYes: () => void;
}
export const ConfirmReverseVoidReadingsDialog = ({
  onClosePopup,
  onConfirmYes,
}: IConfirmReverseVoidReadingsDialog) => {
  return (
    <ConfirmDialog
      width={"20%"}
      height={"auto"}
      title={"Reverse Void Confirmation"}
      message={""}
      subMessage={"Reverse the selected void readings?"}
      onClosePopup={onClosePopup}
      onConfirmYes={onConfirmYes}
    />
  );
};
