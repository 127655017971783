import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DTO_Dashboard } from "@common/pages/home/components/property/model";

export const getPropertyActivityDashboard = async (): Promise<
  APIResponse<DTO_Dashboard | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/dashboard`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
