import { IModifyRecipient } from "@app/core/communication/dialogs/components/form-elememts/to/components/modify-recipient/model";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<IModifyRecipient>();

export const ModifyRecipient = observer(
  ({ setIsShowDialogModifyRecipient, handleModifyRecipient, data }: any) => {
    const handleSubmit = (recipientModify: any) => {
      const newRecipientModify = Object.assign(data, {
        Contact_FullName: recipientModify.DisplayName,
        Email: recipientModify.Email,
        _Mobile: recipientModify.Mobile,
      });
      handleModifyRecipient(newRecipientModify);
    };
    const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
    return (
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          DisplayName: data?.Contact_FullName,
          Email: data?.Email,
          Mobile: data?._Mobile,
        }}
        render={({ onSubmit, modified, onChange }: FormRenderProps) => (
          <CCDialog
            height="auto"
            width="auto"
            unsetMinHeight={true}
            titleHeader={"Modify Recipient"}
            onClose={() => setIsShowDialogModifyRecipient(false)}
            bodyElement={
              <div className="cc-form">
                <div className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Full name</label>
                      <Field
                        name={nameOf("DisplayName")}
                        component={CCInput}
                        placeholder="Full name"
                      />
                    </div>
                    <div className="cc-field">
                      <Field
                        label="Email"
                        name={nameOf("Email")}
                        placeholder="Email"
                        component={CCInputEmail}
                        validator={validateEmail}
                        onChangeForm={onChange}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Mobile</label>
                      <Field
                        name={nameOf("Mobile")}
                        placeholder="Mobile"
                        component={CCInputPhone}
                        validator={validatePhoneNumber}
                        onChangeForm={onChange}
                        skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                        mode={EModePhoneNumber.Mobile}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                <Button
                  className={"cc-dialog-button"}
                  onClick={() => setIsShowDialogModifyRecipient(false)}
                >
                  Cancel
                </Button>

                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={onSubmit}
                  disabled={!modified}
                >
                  OK
                </Button>
              </div>
            }
          />
        )}
      />
    );
  }
);
