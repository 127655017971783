import { KEY_ENTER } from "@components/cc-input/contant";
import { Input, InputProps } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import React from "react";

interface ICCInputProps {
  validationMessage?: string | null;
  visited?: boolean;
}

export const CCInput = (props: ICCInputProps & InputProps) => {
  const { validationMessage, visited, ...others } = props;

  return (
    <>
      <Input
        {...others}
        onKeyPress={(e) => {
          if (e?.charCode === KEY_ENTER) e.preventDefault();
          if (props.onKeyPress) props.onKeyPress(e);
        }}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
