import { Address } from "@app/products/waste-water/[id]/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

interface IViewNearMapButtonProps {
  address?: Address;
}

export const ViewNearMapButton = observer(
  ({ address }: IViewNearMapButtonProps) => {
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const { settings } = useCommonCoreStore();
    const nearMapBaseURL: string =
      settings[ECorporateSettingsField.CorporateSettings_GISNearMaps_BaseURL]
        ?.Value ?? "";
    const viewNearMapUrl: string =
      nearMapBaseURL +
      address?.StreetNo +
      " " +
      address?.StreetName +
      " " +
      address?.Suburb +
      " " +
      address?.State +
      " australia";

    const handleOnClick = () => {
      if (!isNil(address)) {
        clearErrorNotification();
        window.open(`${viewNearMapUrl}`, "_blank");
      } else {
        pushNotification({
          autoClose: false,
          title: "Invalid address object",
          type: "error",
        });
        return;
      }
    };

    return <CCNavButton title="View near map" onClick={handleOnClick} />;
  }
);
