import { isNil } from "lodash";

export const getTotalRelatedAnimalRecordsUrl = (id: number, pid?: string) => {
  const baseAPI = `api/animals/internal/related-animal-total/${id}`;
  return `${baseAPI}?pid=${!isNil(pid) ? pid : "''"}`;
};

export const getRelatedAnimalsUrl = (id?: number, pidNO?: string) => {
  const idParam = isNil(id) ? "" : `currentRegistrationID=${id}`;
  const pidNoParam = `pidNo=${!isNil(pidNO) ? "'" + pidNO + "'" : "''"}`;
  const baseOdataUrl = `/odata/animals/internal/animalsregister`;
  return `${baseOdataUrl}/GetRelatedAnimals(${idParam},${pidNoParam})?$count=true&`;
};

export const convertDateStringFormatDDMMYYYYToDate = (str?: string | null) => {
  if (isNil(str)) return null;
  const onlyDateStr = str.split(" ");
  const dateElements =
    str.indexOf("/") > -1
      ? onlyDateStr[0].split("/")
      : onlyDateStr[0].split("-");
  const result =
    dateElements[2] + "-" + dateElements[1] + "-" + dateElements[0];
  return new Date(result);
};
