import { DTO_AssociatedItem_Sessions } from "@app/products/property/components/associations/components/sessions/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_Sessions>();

export const colAssociationsSessions: IColumnFields[] = [
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("Session_Name"),
    title: "Session Name",
  },
  {
    field: nameOf("Session_Status_Name"),
    title: "Session Status",
  },
  {
    field: nameOf("Session_Created_By"),
    title: "Created By",
  },
  {
    field: nameOf("Session_Created_On"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Session_Updated_By"),
    title: "Updated By",
  },
  {
    field: nameOf("Session_Updated_On"),
    title: "Updated On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Total_Credits"),
    title: "Total Credits",
    format: CURRENCY_FORMAT.CURRENCY1,
  },

  {
    field: nameOf("Total_Debits"),
    title: "Total Debits",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
