import { TypeSubmitActions } from "@common/constants/enumerations";
import {
  ICCHelpDocument
} from "@common/pages/settings/lookups/help-documents/_id/model";
import { produceHelpDocumentSaveTrigger } from "@common/pages/settings/lookups/help-documents/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { useLookupHelpDocument } from "../../../store";
import { LookupHelpDocumentFormElement } from "./components/form-element/_index";

export const LookupHelpDocumentForm = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const { helpDocument, saveHelpDocument, setOnSubmit } = useLookupHelpDocument();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = event;
    const action = event.event?.currentTarget.name as TypeSubmitActions;
    if (!(action in TypeSubmitActions)) return;
    if (!isValid) return;
    if (
      !isModified &&
      [TypeSubmitActions.Save, TypeSubmitActions.New].includes(action)
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    saveHelpDocument(produceHelpDocumentSaveTrigger(values as ICCHelpDocument, helpDocument) as ICCHelpDocument, action);
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, helpDocument]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={helpDocument}
        key={JSON.stringify(helpDocument)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <LookupHelpDocumentFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
