import {
  DTO_Owners,
  DTO_Parcel,
  DTO_RebateType_RatingPeriod,
  DTO_Title,
} from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import { isEmpty } from "lodash";

export const entitlementsFormValidator = (
  values: any,
  options?: any
): string | undefined => {
  const { RebateType_RatingPeriod = [] } = values;
  const { isActro, isLLS, lotLabelName } = options;
  const selectedRebates =
    RebateType_RatingPeriod.filter(
      (rebateType: DTO_RebateType_RatingPeriod) => rebateType.Is_Entitle
    ) ?? [];

  //Validate Rebate Types grid
  if (selectedRebates.length === 0)
    return JSON.stringify({
      RebateTypes: "Must select at least one rebate to be entitled",
    });

  let formError: any = {};
  //Validate Percentage column
  const existInvalidPercentage = selectedRebates.some(
    (rebateType: DTO_RebateType_RatingPeriod) =>
      !rebateType.Percentage || rebateType.Percentage > 100
  );
  if (existInvalidPercentage)
    formError.RebateTypes =
      "Percentage is required and must be between 1 and 100";

  //Validate child grid
  selectedRebates.forEach((rebate: any) => {
    //Validate Lots/Parcels grid
    if (rebate.RT_Is_ParcelBased) {
      //Validate Lots for LLS
      if (isLLS) {
        const existSelectedLot = rebate.Lots?.some(
          (lot: DTO_Title) => lot.Is_Entitle
        );
        if (!existSelectedLot) {
          formError[
            `Lots${rebate.Rebate_Type_Id}`
          ] = `Must select at least one ${lotLabelName}`;
        }
      }

      //Validate Parcels for ACTRO
      if (isActro) {
        const existSelectedParcel = rebate.Parcels?.some(
          (parcel: DTO_Parcel) => parcel.Is_Entitle
        );
        if (!existSelectedParcel) {
          formError[`Parcels${rebate.Rebate_Type_Id}`] =
            "Must select at least one parcel";
        }
      }
    }

    //Validate Owners grid
    if (rebate.Is_ConcessionHeld) {
      const existSelectedOwner = rebate.Owners?.some(
        (owner: DTO_Owners) => owner.Is_Entitle
      );
      if (!existSelectedOwner) {
        formError[`Owners${rebate.Rebate_Type_Id}`] =
          "Must select at least one owner";
      }
    }
  });

  if (isEmpty(formError)) return undefined;
  return JSON.stringify(formError);
};
