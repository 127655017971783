export const mockMakeData = [
  { Key: 1, Value: "Davey-Shepherd" },
  { Key: 2, Value: "Elster" },
  { Key: 3, Value: "Email" },
  { Key: 4, Value: "R M C" },
];
export const mockTypeData = [
  { Key: 1, Value: "Bypass" },
  { Key: 2, Value: "Fire Hydrant" },
  { Key: 3, Value: "Mains" },
];

export const mockUnitOfMeasurementData = [
  { Key: 1, Value: "Corrected Volume" },
  { Key: 2, Value: "Cubic Meter" },
  { Key: 3, Value: "Kilolitre" },
  { Key: 4, Value: "Kilowatt" },
  { Key: 5, Value: "Mega Joule" },
];
export const mockMeterSizeData = [
  { Key: 1, Value: "15KL" },
  { Key: 2, Value: "20KL" },
  { Key: 3, Value: "25KL" },
  { Key: 4, Value: "30KL" },
  { Key: 5, Value: "32KL" },
  { Key: 6, Value: "40KL" },
  { Key: 7, Value: "50KL" },
  { Key: 8, Value: "80KL" },
];
export const mockModifyMeter = {
  Type: { Key: 3, Value: "Mains" },
  UnitOfMeasurement: { Key: 3, Value: "Kilolitre" },
  MeterSize: { Key: 3, Value: "25KL" },
  _option: {
    Make: {
      Data: mockMakeData,
    },
    Type: {
      Data: mockTypeData,
    },
    UnitOfMeasurement: {
      Data: mockUnitOfMeasurementData,
    },
    MeterSize: {
      Data: mockMeterSizeData,
    },
  },
};
