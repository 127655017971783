import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButton } from "@app/core/mail-merge/buttons/_index";
import { bookingsRoute } from "@app/products/event-management/bookings/route";
import { colEventManagementBookingsSubmitted } from "@app/products/event-management/bookings/submitted/config";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/event-management/bookings/submitted/util";
import { EventManagementActionBarNavDropdown } from "@app/products/event-management/components/action-bar/nav-dropdown/_index";
import { NewEventManagementButton } from "@app/products/event-management/components/buttons/new-event-management/_index";
import { EventManagementFieldMapping } from "@app/products/event-management/constant";
import { mockEventManagementsAll } from "@app/products/event-management/mock";
import { eventManagementRoute } from "@app/products/event-management/route";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
} from "@app/products/event-management/util";
import { EventManagementContactsTab } from "@app/products/event-management/[id]/components/reference-sidebar/contacts/_index";
import { EventManagementDetailsTab } from "@app/products/event-management/[id]/components/reference-sidebar/details/_index";
import { EventManagementHistoryTab } from "@app/products/event-management/[id]/components/reference-sidebar/history/_index";
import { EVENT_MANAGEMENT_MANAGE_ROUTE } from "@app/products/event-management/[id]/constant";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

export default () => {
  useCCListViewActionBar({
    title: eventManagementRoute.name,
    leftComponents: [
      <EventManagementActionBarNavDropdown category={bookingsRoute.path} />,
    ],
    centerComponents: [
      <NewEventManagementButton />,
      <MailMergeButton />,
      <CommunicationButtonListView />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={EVENT_MANAGEMENT_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.EventManagementSystem}
        recordType={RECORDTYPE.EMS_EventBooking}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <EventManagementDetailsTab />,
      },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Contacts",
        component: <EventManagementContactsTab />,
      },
      {
        title: "History",
        component: <EventManagementHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colEventManagementBookingsSubmitted}
      data={mockEventManagementsAll ?? []}
      //dataUrl={EventManagementUrl.GET_VIEW_EVENTMANAGEMENT_BOOKINGS_ALL}
      primaryField="ID"
      state={{
        sort: [
          {
            field: EventManagementFieldMapping.EventBooking_ReferenceNo,
            dir: "desc",
          },
        ],
      }}
    />
  );
};
