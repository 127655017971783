import { WorkflowStepApprovalStatus } from "@app/products/property/components/action-bar/property-workflow/model";
import {
  DTO_Secondary_Workflow,
  DTO_WorkflowHeader,
  DTO_Workflow_Approval_Step,
} from "@app/products/property/model";
import { ICON_FORMAT } from "@common/constants/common-format";
import { ICustomFormat } from "@components/cc-grid/model";
import { filter, find, forEach, head, set } from "lodash";

interface ISecondaryWorkflowInitData {
  SecondaryWorkflow: DTO_Secondary_Workflow[];
  SecondaryWorkflowsSelected: number[];
  WorkflowApprovals: DTO_Workflow_Approval_Step[];
  WD_Require_Approval: boolean | null;
}

export const markSelectedSecondaryWorkflowsAsMandatory = (
  workflowData: DTO_Secondary_Workflow[],
  selectedSecondaryWorkflow: DTO_Secondary_Workflow[],
  setItem: boolean = true,
  Workflow_IsMandatory: boolean = true
): DTO_Secondary_Workflow[] => {
  return forEach(workflowData, (item: DTO_Secondary_Workflow) => {
    const isSelected: DTO_Secondary_Workflow | undefined = find(
      selectedSecondaryWorkflow,
      {
        WorkflowType_Int: item.WorkflowType_Int,
      }
    );

    if (isSelected) {
      set(
        setItem ? item : isSelected,
        "Workflow_IsMandatory",
        Workflow_IsMandatory
      );
    }
  });
};

export const getWorkflowInstructions = (
  workflowApprovals: DTO_Workflow_Approval_Step[]
): string | undefined => {
  return head(
    filter(workflowApprovals, {
      ApprovalStatus: WorkflowStepApprovalStatus.Current,
    })
  )?.WorkflowDetails;
};

export const getSuffixTitle = (
  suffixTitle: string | undefined,
  isToBeApprovalMode: boolean,
  workflowApprovals: DTO_Workflow_Approval_Step[]
): string | undefined => {
  return isToBeApprovalMode
    ? find(workflowApprovals, {
        ApprovalStatus: WorkflowStepApprovalStatus.Current,
      })?.WorkflowStepName
    : suffixTitle;
};

export const workflowIsMandatoryFormat = (dataItem: any): ICustomFormat => {
  return dataItem?.Workflow_IsMandatory && dataItem?.Is_Included
    ? {
        defaultFormat: ICON_FORMAT.ICON_PADLOCK,
        customFormat: ICON_FORMAT.ICON_PADLOCK,
      }
    : {
        defaultFormat: ICON_FORMAT.ICON_BOOLEAN,
        customFormat: ICON_FORMAT.ICON_BOOLEAN,
      };
};

export const secondaryWorkflowUtilProcessing = {
  // Process data init for secondary workflow
  processDataInit(initialValues: any) {
    const initSecondaryWorkflow: ISecondaryWorkflowInitData = {
      SecondaryWorkflow:
        initialValues?.WorkflowHeader?.AvailableSecondaryWorkflows,
      SecondaryWorkflowsSelected:
        initialValues?.WorkflowDetail?.SelectedSecondaryWorkflow,
      WorkflowApprovals: initialValues?.WorkflowHeader?.WorkflowApprovals,
      WD_Require_Approval: initialValues?.WorkflowHeader?.WD_Require_Approval,
    };

    return initSecondaryWorkflow;
  },
  // Process secondary workflow data
  processData(
    value: any,
    workflowHeader: DTO_WorkflowHeader,
    workflowDetail: any
  ) {
    workflowDetail.SelectedSecondaryWorkflow =
      value?.SecondaryWorkflowsSelected;
    workflowHeader.AdhocStep = value?.AdhocStep;
    workflowHeader.DeletedAdhocStepId = value?.DeletedAdhocStepId;
    delete workflowDetail.Workflow;
  },
};
