import { IAdditionalContactDialog } from "@app/core/contacts/components/dialogs/additional-contact/model";

export enum LoadingState {
    GET = "get",
    SAVE = "save",
    NONE = "none",
}

export const initialValues: IAdditionalContactDialog = {
    Contact: null,
    RelationshipType: null,
};