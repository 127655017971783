import React from "react";

export const PageTitleMenuRow: React.FC = ({ children }) => {
  return (
    <div
      className="row col-md-12 border-bottom border-top"
      style={{ backgroundColor: "var(--secondary)" }}
    >
      {children}
    </div>
  );
};
