import { EditorTools } from "@progress/kendo-react-editor";

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  ForeColor,
  BackColor,
  CleanFormatting,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  InsertFile,
  SelectAll,
  Print,
  Pdf,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;

export const editorDefaultTools = {
  web: [
    [Bold, Italic, Underline, Strikethrough],
    [Subscript, Superscript],
    ForeColor,
    BackColor,
    [CleanFormatting],
    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
    [Indent, Outdent],
    [OrderedList, UnorderedList],
    FontSize,
    FontName,
    FormatBlock,
    [SelectAll],
    [Undo, Redo],
    [Link, Unlink, InsertImage, ViewHtml],
    [InsertTable, InsertFile],
    [Pdf, Print],
    [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
    [DeleteRow, DeleteColumn, DeleteTable],
    [MergeCells, SplitCell],
  ],
  mobile: [
    [Bold, Italic, Underline],
    ForeColor,
    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
    [OrderedList, UnorderedList],
    [Undo, Redo],
    [Link, Unlink, InsertImage, ViewHtml],
    [Pdf, Print],
  ],
};
