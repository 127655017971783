import { VO_Change_of_Ownership } from "@app/products/property/changes-of-ownership/list/model";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@components/cc-grid/util";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const ChangeOfOwnershipDetailTab = observer(() => {
  const [changeOfOwnershipInfo, setChangeOfOwnershipInfo] = useState<
    VO_Change_of_Ownership | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { lastSelectedRow } = useCCProductListViewStore();

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      if (!lastSelectedRow) return setChangeOfOwnershipInfo(undefined);
      setChangeOfOwnershipInfo(lastSelectedRow);
      setIsLoading(false);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [lastSelectedRow]);

  if (!changeOfOwnershipInfo) return <NoData />;
  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Change of Ownership ID:"}
              value={changeOfOwnershipInfo?.Id + ""}
              primary
            />
          }
        >
          <>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Effective Date:"}
                value={formatDisplayValue(
                  DATE_FORMAT.DATE,
                  changeOfOwnershipInfo?.Effective_Date
                )}
              />
              <ProductReferenceRow
                title={"Property Address:"}
                value={changeOfOwnershipInfo?.Property_Address ?? ""}
              />
              <ProductReferenceRow
                title={"Change user ID:"}
                value={changeOfOwnershipInfo?.Changed_By ?? ""}
              />
              <ProductReferenceRow
                title={"Change Date/Time:"}
                value={formatDisplayValue(
                  DATETIME_FORMAT.DATETIME,
                  changeOfOwnershipInfo?.Changed_On
                )}
              />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
