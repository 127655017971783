import { useSubdivideConsolidateDialogStore } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/store";
import { getDropdownValue } from "@common/utils/common";
import { ICustomEditCell } from "@components/cc-grid/model";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React from "react";

export const OwnerCell = observer(
  ({ value, onChange, dataIndex, dataItem, field }: ICustomEditCell) => {
    const { progenyLOVs } = useSubdivideConsolidateDialogStore();

    return (
      <CCSearchComboBox
        value={getDropdownValue(value, progenyLOVs?.Owners ?? [], "Name")}
        data={progenyLOVs?.Owners ?? []}
        textField="Name"
        dataItemKey="Code"
        onChange={(event: ComboBoxChangeEvent) => {
          if (onChange)
            onChange({
              field: field,
              dataIndex: dataIndex || 0,
              dataItem,
              syntheticEvent: event.syntheticEvent,
              value: event.value?.Name ?? "",
            });
        }}
      />
    );
  }
);
