import { colPotentialDuplicateEntities } from "@app/products/property/contacts-central-names/potential-duplicate-entities/_shares/config";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCNote } from "@components/cc-note/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useEffect, useState } from "react";

export const RemoveExclusionButton = observer(() => {
  const [showDialog, setShowDialog] = useState(false);
  const { gridSelectedRows, isEmptyGridSelectedRow } =
    useCCProductListViewStore();

  const [itemsData, setItemsData] = useState<any[]>([]);

  useEffect(() => {
    setItemsData(
      gridSelectedRows
        .filter((item: any) => item.IsExcluded)
        .map((item: any) => {
          return {
            Name: item.Name,
            NoOfEntities: item.NoOfEntities,
            Detail: item.Detail,
          };
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridSelectedRows]);

  let itemsExcluded = gridSelectedRows.filter((item: any) => !item.IsExcluded);

  return (
    <>
      <CCNavButton
        title="Remove Exclusion"
        onClick={() => setShowDialog(true)}
        disabled={
          isEmptyGridSelectedRow ||
          (gridSelectedRows.filter((item: any) => item.IsExcluded).length ===
            0 &&
            gridSelectedRows.filter((item: any) => !item.IsExcluded).length !==
              0)
        }
      />
      {showDialog && (
        <CCDialog
          onClose={() => {
            setShowDialog(false);
          }}
          titleHeader={"Confirmation"}
          height="auto"
          maxWidth="30%"
          bodyElement={
            <>
              <h6>
                Remove exclusion of selected contacts from the list of potential
                duplicates?
              </h6>
              {itemsData && (
                <CCGrid
                  data={itemsData}
                  columnFields={colPotentialDuplicateEntities.filter(
                    (col: any) => col.field !== "IsExcluded"
                  )}
                  primaryField="Id"
                />
              )}
              {itemsExcluded.length !== 0 && (
                <CCNote
                  message={
                    itemsExcluded.length +
                    ` Selected ${pluralize(
                      "Contact",
                      itemsExcluded.length
                    )} Name and Address will not be processed.`
                  }
                />
              )}
            </>
          }
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              <Button
                className={"cc-dialog-button"}
                onClick={() => {
                  setShowDialog(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className={"cc-dialog-button"}
                themeColor="primary"
                onClick={() => {
                  setShowDialog(false);
                }}
              >
                OK
              </Button>
            </div>
          }
        />
      )}
    </>
  );
});
