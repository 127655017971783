import { getFormattedDate } from "@app/products/ams/api/helpers/date-helper";
import { useAssetManagerStore } from "@app/products/ams/assets/[id]/store";
import { defaultDate } from "@app/products/ams/assets/components/reference-sidebar/detail/constant";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const AssetDetailTab = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { lastSelectedId } = useCCProductListViewStore();
  const assetId: string = id ?? lastSelectedId;
  const {
    getAssetInfo,
    asset,
    isLoading,
    assetLOVs,
    loadAsset,
    responseLoadError,
  } = useAssetManagerStore();
  useEffect(() => {
    if (id) return;
    loadAsset(assetId);
  }, [assetId, loadAsset, id]);
  if (isLoading) return <Loading isLoading />;

  if (!asset || !assetLOVs) return <NoData />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => loadAsset(+id)}
      />
    );

  const assetInfo = getAssetInfo(asset, assetLOVs);
  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Asset number:"}
        value={assetInfo?.AssetNumber}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Asset description:"}
          value={assetInfo?.AssetDescription}
        />
        <ProductReferenceRow
          title={"Category:"}
          value={assetInfo?.AssetCategory}
        />
        <ProductReferenceRow
          title={"Design life:"}
          value={assetInfo?.DesignLife}
        />
        <ProductReferenceRow title={"Status:"} value={assetInfo?.AssetStatus} />
        {getFormattedDate(assetInfo?.WarrantyEndDate) !== defaultDate ? (
          <ProductReferenceRow
            title={"Warranty end date:"}
            value={assetInfo?.AssetStatus}
          />
        ) : null}
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
