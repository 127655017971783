import {
  LocalGovernment,
  LocalGovernmentSubmitActions,
} from "@common/pages/settings/lookups/local-government/model";
import { useLocalGovernmentStore } from "@common/pages/settings/lookups/local-government/store";
import { LocalGovernmentFormElement } from "@common/pages/settings/lookups/local-government/[id]/components/child-screens/general/components/form-element/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const LocalGovernmentForm = observer(() => {
  const { localGovernment, saveLocalGovernment, setOnSubmit } =
    useLocalGovernmentStore();
  const { pushNotification } = useCCAppNotificationStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();
  // Use for integrate API
  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const action = event.event?.currentTarget
      .name as LocalGovernmentSubmitActions;
    const { values, isModified, isValid } = event;
    if (!(action in LocalGovernmentSubmitActions)) return;
    if (!isValid) return;
    if (
      !isModified &&
      [
        LocalGovernmentSubmitActions.Save,
        LocalGovernmentSubmitActions.New,
      ].includes(action)
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    const localGovernmentInfo = values as LocalGovernment;
    saveLocalGovernment(localGovernmentInfo, action);
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, localGovernment]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={localGovernment}
        key={JSON.stringify(localGovernment)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <LocalGovernmentFormElement formRenderProps={formRenderProps} />
          );
        }}
      />
    </div>
  );
});
