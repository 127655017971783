import { apiCoreGetContactsByClassification } from "@app/core/components/common/utils";
import { ContactClassification } from "@app/core/contacts/_id/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Contact, ContactLookahead_JSON } from "@common/models/contact";

export const searchSiteUser = async (
  searchText: string,
  contactClassification?: ContactClassification
): Promise<APIResponse<ContactLookahead_JSON[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<
      ContactLookahead_JSON[] | undefined
    >(apiCoreGetContactsByClassification(), {
      params: {
        searchText,
        contactClassification,
      },
    });
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

//@TODO:Add interface
export const getSiteUser = async (
  contactId: number
): Promise<APIResponse<Contact | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Contact | undefined>(
      `/api/core/internal/getcontact/${contactId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
