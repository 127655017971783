import DOMPurify, { Config } from "dompurify";
import htmlParser from "html-react-parser";

export const sanitizeHtml = (
  html: string
): string | JSX.Element | JSX.Element[] => {
  const sanitizedHtml = DOMPurify.sanitize(html);
  return htmlParser(sanitizedHtml);
};
export const sanitizeWithoutParse = (html: string): string => {
  return DOMPurify.sanitize(html);
};

export const sanitizeHtmlWithConfigs = (
  html: string,
  config: Config
): string | JSX.Element | JSX.Element[] => {
  // Sanitize the HTML with the provided configuration
  const sanitizedContent = DOMPurify.sanitize(html, config);

  // Check if the sanitized content is a string or an HTMLElement
  if (typeof sanitizedContent === "string") {
    // If it's a string, directly pass it to htmlParser
    return htmlParser(sanitizedContent);
  }
  // If it's an HTMLElement, extract its innerHTML
  if (sanitizedContent instanceof HTMLElement) {
    return htmlParser(sanitizedContent.innerHTML);
  }
  // If it's a DocumentFragment, create a temporary div, append the fragment, and then extract its innerHTML
  if (sanitizedContent instanceof DocumentFragment) {
    const tempDiv = document.createElement("div");
    tempDiv.appendChild(sanitizedContent);
    return htmlParser(tempDiv.innerHTML);
  }
  // Otherwise, return an empty string
  return "";
};
