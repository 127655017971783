import { getDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_category/api";
import { settingColumnField } from "@app/products/town-planning/ppr/system-admin/settings/_category/config";
import { MODE_SETTING } from "@app/products/town-planning/ppr/system-admin/settings/_category/model";
import {
  EClientSequenceKey,
  IData,
} from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import { processConfigData } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IColumnFields } from "@components/cc-grid/model";
import { newCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class LocalLawsSettingStore {
  private _responseLoadError?: APIResponseError = undefined;
  private _modeSetting: string = MODE_SETTING.NONE;
  private _isLoading: boolean = false;
  private _dataSetting?: any = undefined;
  private _currentBreadcrumb: any = null;
  private _primaryKeyTable: string = "Title";
  private _columnsFields: IColumnFields[] = [];
  private _configData: IData | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading(): boolean {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  /**
   * get mode for Setting
   */
  get modeSetting() {
    return this._modeSetting;
  }
  setModeSetting = (mode: string) => {
    runInAction(() => {
      this._modeSetting = mode;
    });
  };

  /**
   * get data Setting 2 types: Setting Folder and Setting Item
   */
  get dataSetting() {
    return toJS(this._dataSetting);
  }
  setDataSetting = (data?: any) => {
    runInAction(() => {
      this._dataSetting = data;
    });
  };

  /**
   * get data breadcrumb
   */
  get currentBreadcrumb() {
    return this._currentBreadcrumb;
  }
  setCurrentBreadcrumb = (breadcrumb: any) => {
    runInAction(() => {
      this._currentBreadcrumb = breadcrumb;
    });
  };

  /**
   * get primary key. It's dynamic field
   */
  get primaryKeyTable(): string {
    return this._primaryKeyTable;
  }
  setPrimaryKeyTable = (key: string) => {
    runInAction(() => {
      this._primaryKeyTable = key;
    });
  };

  /**
   * get dynamic column
   */
  get columnFields(): IColumnFields[] {
    return this._columnsFields;
  }
  setColumnField = (columnFields: IColumnFields[]) => {
    runInAction(() => {
      this._columnsFields = columnFields;
    });
  };

  get configData(): IData | undefined {
    return toJS(this._configData);
  }
  setConfigData = (configData?: IData) => {
    runInAction(() => {
      this._configData = configData;
    });
  };

  loadDataSetting = async (url: string) => {
    this.setIsLoading(true);
    const response = await getDataSetting(url);
    newCCProductListViewStore.setRequestUrl(url); // store request URL => keep state of CCProductListView
    let newDataSetting = undefined;
    let errorResponse = undefined;
    if (isSuccessResponse(response)) {
      const data = response?.data;
      let newColumns: IColumnFields[] = [];
      if (isNil(data)) {
        this.setModeSetting(MODE_SETTING.SETTINGS_FOLDER);
        this.setIsLoading(false);
        this.setDataSetting([]);
        return;
      }
      this.setCurrentBreadcrumb(data);
      if (data.hasOwnProperty("SettingsFolders")) {
        if (data.SettingsFolders[0]?.hasOwnProperty("ItemId")) {
          newColumns = settingColumnField(
            data.UseIsActive,
            data.TitleColumnHeader,
            data.DescriptionColumnHeader,
            true
          );
          this.setPrimaryKeyTable("ItemId");
        } else {
          newColumns = settingColumnField(
            data.UseIsActive,
            data.TitleColumnHeader,
            data.DescriptionColumnHeader
          );
          this.setPrimaryKeyTable("Title");
        }
        this.setColumnField(newColumns);
        newDataSetting = data.SettingsFolders;
        this.setModeSetting(MODE_SETTING.SETTINGS_FOLDER);
      } else if (
        data.Mode === MODE_SETTING.SETTINGS_ITEM ||
        data.hasOwnProperty("SettingFields")
      ) {
        if (response.data.hasOwnProperty("SettingFields")) {
          this.setModeSetting(MODE_SETTING.SETTINGS_ITEM);
          const processData = processConfigData(response.data?.SettingFields);
          if (processData) {
            this.setConfigData(processData);
          }
          newDataSetting = response.data;
        }
      } else {
        newDataSetting = data;
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
      this.setModeSetting(MODE_SETTING.NONE);
      this.setCurrentBreadcrumb(undefined);
    }

    this.setResponseLoadError(errorResponse);
    this.setDataSetting(newDataSetting);
    this.setIsLoading(false);
  };

  getConfigDataField = (
    setting: ECorporateSettingsField | string | EClientSequenceKey
  ) => {
    if (!this.configData) return undefined;
    return this.configData[setting.toString()];
  };
}

export const localLawsSettingStoreContext = createContext(
  new LocalLawsSettingStore()
);
export const useLocalLawsSettingStore = () => {
  return useContext(localLawsSettingStoreContext);
};
