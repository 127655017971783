import { DTO_Journal_Summary } from "@app/products/property/journals/session-list/[id]/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Journal_Summary>();

export const colJournalsInPropertySession: IColumnFields[] = [
  {
    field: nameOf("Journal_Number"),
    title: "Journal Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Jnl_Description"),
    title: "Name",
  },
  {
    field: nameOf("Jnl_Debit_Total"),
    title: "Debit Total",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Jnl_Credit_Total"),
    title: "Credit Total",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Session_Number"),
    title: "Session Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Jnl_No_of_Transactions"),
    title: "Number of Transactions",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Journal_Status_Name"),
    title: "Status",
  },
  {
    field: nameOf("Journal_Type_Name"),
    title: "Type",
  },
  {
    field: nameOf("Jnl_DateTime"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Officer_Surname_w_Given_Names"),
    title: "Created By",
  },
  {
    field: nameOf("Authorised_By_Surname_w_Given"),
    title: "Authorised By",
  },
  {
    field: nameOf("Jnl_Authorised_on"),
    title: "Authorised On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Component_Number"),
    title: "Component",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
