import { ICCRoute } from "@common/constants/ICCRoute";

export const categoriesRoute: ICCRoute = {
  path: "categories",
  name: "Categories",
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
