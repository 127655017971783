import { useDebtRecoveryAssessmentStore } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/assessments/store";
import { DTO_Assessment } from "@app/products/property/assessments/debt-recovery/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { colDetail } from "./config";
import "./_index.scss";

const nameOf = nameOfFactory<DTO_Assessment>();
export const DebtRecoveryDetailComponent = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { isLoading, assessment, loadAssessment, resetStore } =
    useDebtRecoveryAssessmentStore();

  useEffect(() => {
    if (lastSelectedRow?.Debt_Recovery_Id)
      loadAssessment(lastSelectedRow.Debt_Recovery_Id);
    return () => {
      resetStore();
    };
  }, [loadAssessment, lastSelectedRow, resetStore]);

  return (
    <CCGrid
      className="cc-contact-debt-recovery-children-grid"
      data={assessment?.DebtRecoveryAssessment ?? []}
      columnFields={colDetail}
      primaryField={nameOf("AssessmentId")}
      isLoading={isLoading}
    />
  );
});
