import {
  action,
  computed,
  configure,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from "mobx";
import React, { createContext } from "react";

configure({ enforceActions: "always" }); // Strict mode: runInAction

class CCSlideActionBarStore {
  private _isActive: boolean = false;

  setIsActive = (status: boolean) => {
    runInAction(() => {
      this._isActive = status;
    });
  };

  constructor() {
    makeObservable<CCSlideActionBarStore, "_isActive">(this, {
      _isActive: observable,
      setIsActive: action,
      isActive: computed,
    });
  }

  get isActive() {
    return toJS(this._isActive);
  }
}

const formStepStoreContext = createContext(new CCSlideActionBarStore());

export const useCCSlideActionBarStore = () => {
  const context = React.useContext(formStepStoreContext);
  if (context === undefined) {
    throw new Error(
      "useCCSlideActionBarStore in not within CCSlideActionBarStore"
    );
  }
  return context;
};
