import {
  AnimalTypeSubmitActions,
  Registration_Status,
} from "@app/products/animals/[id]/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const AnimalNewTagIssueButton = observer(() => {
  const { animalInfo, isWorkflowLoading, isDeleted, onSubmit } =
    useAnimalStore();

  const isVisible = useMemo(() => {
    return animalInfo?.Status_ENUM === Registration_Status.LostTag;
  }, [animalInfo]);

  return isVisible ? (
    <CCNavButton
      title={"New tag issue"}
      name={AnimalTypeSubmitActions.NewTagIssue}
      onClick={onSubmit}
      isLoading={isWorkflowLoading}
      disabled={isWorkflowLoading || isDeleted}
    />
  ) : null;
});
