import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { DTO_Transaction_Item } from "@app/products/property/model";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { loadAssessmentTransactionDetail } from "./api";
import { colAllocation } from "./config";

export const TransactionsDetail = observer((props: GridDetailRowProps) => {
  const dataItem = props.dataItem;
  const [isLoading, setIsLoading] = useState(true);
  const [transactionsData, setTransactionsData] = useState<
    DTO_Transaction_Item[]
  >([]);
  const { assessment } = useAssessmentStore();
  const [gridColumns, setGridColumns] =
    useState<IColumnFields[]>(colAllocation);
  const assessmentId = assessment ? assessment.Assessment.Id : undefined;
  const transactionId = dataItem.TransactionId;

  useEffect(() => {
    if (!assessmentId || !transactionId) return;
    setIsLoading(true);
    loadAssessmentTransactionDetail(assessmentId, transactionId).then(
      (response) => {
        setIsLoading(false);
        if (!response) return;
        const transactionItems = response?.TransactionAllocations
          ? response?.TransactionAllocations.TransactionItem
          : undefined;
        const totalRows: any = response?.TransactionAllocations
          ? response?.TransactionAllocations.Totals
          : {};
        setTransactionsData(transactionItems || []);

        setGridColumns(
          colAllocation.map((col) => ({
            ...col,
            calculateFooterCellValue: () => totalRows[col.field],
          }))
        );
      },
      (error) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  }, [assessmentId, transactionId]);

  return (
    <>
      <label className="cc-label">Transaction allocation</label>
      <CCGrid
        className="cc-transactions-detail-grid"
        data={transactionsData ?? []}
        columnFields={gridColumns}
        primaryField="ChargeBalancesId"
        isLoading={isLoading}
      />
    </>
  );
});
