import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSPayrollAllMyEventsBookmark = {
  getBookmarkListViewDisplayName() {
    return `${CRMS_PRODUCT_NAME} - Payroll`;
  },
  getBookmarkListViewDetail() {
    return `${CRMS_PRODUCT_NAME} - Payroll - All My Events`;
  },
};
