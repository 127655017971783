import { CRMSViews } from "@app/products/crms/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";

export const unresolvedEventsRoute: ICCRoute = {
  path: "unresolved-events",
  name: "Unresolved Events",
  enum: CORPORATE_SETTINGS_FIELD.CUSTOMERSERVICE_Views_UnresolvedEvents,
  children: [
    {
      path: "my-events",
      name: "My Events",
      enum: CRMSViews.Unresolved_MyEvents,
      component: require("./my-events/_index").default,
    },
    {
      path: "my-coordinator-person",
      name: "My Coordinator Person",
      enum: CRMSViews.Unresolved_MyCoordinatorEvents,
      component: require("./my-coordinator-person/_index").default,
    },
    {
      path: "by-action-person",
      name: "By Action Person",
      component: require("./by-action-person/_index").default,
      enum: CRMSViews.Unresolved_ByActionOfficer,
    },
    {
      path: "by-coordinator-person",
      name: "By Coordinator Person",
      component: require("./by-coordinator-person/_index").default,
      enum: CRMSViews.Unresolved_ByCoordinator,
    },
    {
      path: "by-refno",
      name: "By Ref No",
      component: require("./by-refno/_index").default,
      enum: CRMSViews.Unresolved_ByRefNo,
    },
    {
      path: "by-category",
      name: "By Category",
      component: require("./by-category/_index").default,
      enum: CRMSViews.Unresolved_ByCategory,
    },
    {
      path: "by-street",
      name: "By Street",
      component: require("./by-street/_index").default,
      enum: CRMSViews.Unresolved_ByStreetName,
    },
    {
      path: "by-suburb",
      name: "By Suburb",
      component: require("./by-suburb/_index").default,
      enum: CRMSViews.Unresolved_BySuburb,
    },
    {
      path: "by-due-status",
      name: "By Due Status",
      component: require("./by-due-status/_index").default,
      enum: CRMSViews.Unresolved_ByDueStatus,
    },
    {
      path: "by-status",
      name: "By Status",
      component: require("./by-status/_index").default,
      enum: CRMSViews.Unresolved_ByStatus,
    },

    {
      path: "by-org-structure",
      name: "By Org Structure",
      component: require("./by-org-structure/_index").default,
      enum: CRMSViews.Unresolved_ByOrgStructure,
    },
    {
      path: "by-assigned-to-org-structure",
      name: "By Assigned To Org Structure",
      component: require("./by-assigned-to-org-structure/_index").default,
      enum: CRMSViews.Unresolved_ByAssignedToOrgStructure,
    },
    {
      path: "by-recorded-by",
      name: "By Recorded By",
      component: require("./by-recorded-by/_index").default,
      enum: CRMSViews.Unresolved_ByRecordedBy,
    },
    {
      path: "by-work-date",
      name: "By Work Date",
      component: require("./by-work-date/_index").default,
      enum: CRMSViews.Unresolved_ByWorkDate,
    },
    {
      path: "by-special-interest",
      name: "By Special Interest",
      component: require("./by-special-interest/_index").default,
      enum: CRMSViews.Unresolved_BySpecialInterest,
    },
    {
      path: "by-recipient",
      name: "By Recipient",
      component: require("./by-recipient/_index").default,
      enum: CRMSViews.Unresolved_ByRecipient,
    },
    {
      path: "acknowledgement-req",
      name: "Acknowledgement Req.",
      component: require("./acknowledgement-req/_index").default,
      enum: CRMSViews.Unresolved_AcknowledgementRequired,
    },
    {
      path: "resent",
      name: "Resent",
      component: require("./resent/_index").default,
      enum: CRMSViews.Unresolved_Resent,
    },
    {
      path: "drafts",
      name: "Drafts",
      component: require("./drafts/_index").default,
      enum: CRMSViews.Unresolved_Drafts,
    },
    {
      path: "vip",
      name: "VIP",
      component: require("./vip/_index").default,
      enum: CRMSViews.Unresolved_VIP,
    },
    {
      path: "cs-admin",
      name: "CS Admin",
      component: require("./cs-admin/_index").default,
      enum: CRMSViews.Unresolved_CSAdmin,
    },
    {
      path: "by-asset",
      name: "By Asset",
      component: require("./by-asset/_index").default,
      enum: CRMSViews.Unresolved_ByAsset,
    },
    {
      path: "hard-rubbish",
      name: "Hard Rubbish",
      component: require("./hard-rubbish/_index").default,
      enum: CRMSViews.Unresolved_HardRubbish,
    },
    {
      path: "after-hours-service",
      name: "After Hours Service",
      component: require("./after-hours-service/_index").default,
      enum: CRMSViews.Unresolved_AfterHoursService,
    },
    {
      path: "quality-check",
      name: "Quality Check",
      component: require("./quality-check/_index").default,
      enum: CRMSViews.Unresolved_QualityCheck,
    },
    {
      path: "incidents",
      name: "Incidents",
      component: require("./incidents/_index").default,
      enum: CRMSViews.Unresolved_Incident,
    },
    {
      path: "new-events",
      name: "New Events",
      component: require("./new-events/_index").default,
    },
    {
      path: "risk",
      name: "Risk",
      component: require("./risk/_index").default,
      enum: CRMSViews.Unresolved_Risk,
    },
    {
      path: "safety",
      name: "Safety",
      component: require("./safety/_index").default,
      enum: CRMSViews.Unresolved_Safety,
    },
    {
      path: "by-tier-4",
      name: "Tier 4",
      component: require("./by-tier-4/_index").default,
      enum: CRMSViews.Unresolved_ByTier4,
    },
    {
      path: "unread-mail",
      name: "Unread Mail",
      component: require("./unread-mail/_index").default,
      enum: CRMSViews.UnreadMailMessagingEvents,
    },
  ],
};
