import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import { colMetersDetail } from "./config";

const MetersDetail = (props: GridDetailRowProps) => {
  return (
    <div className="cc-form">
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              data={props?.dataItem["Detail"] ?? []}
              columnFields={colMetersDetail}
              primaryField="UsageId"
            />
          </div>
        </div>
      </section>
    </div>
  );
};
export default MetersDetail;
