import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import {
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colAssessmentAccountAssociated: IColumnFields[] = [
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.AssessmentId}`;
    },
  },
  {
    field: "Type",
    title: "Type",
  },

  { field: "BillingGroup", title: "Billing Group" },
  {
    field: "UsageEntitlement",
    title: "Usage Entitlement",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "UsagePercentage",
    title: "Usage Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  { field: "PropertyAddress", title: "Property Address" },
  { field: "Improvements", title: "Improvements" },
  { field: "PrimaryLandUse", title: "Primary Land Use" },
  { field: "AssessmentType", title: "Assessment Type" },
  { field: "Status", title: "Status" },
  {
    field: "AssessmentId",
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
