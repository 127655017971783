import { Attachment, JournalPOI, KeyValuePair } from "@app/core/documents/model";
import {
  FileStreamEntry
} from "@app/core/new-mail-merge/dialogs/model";
import { DTOBaseClass_Standard } from "@app/products/crms/[id]/model";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { DocumentClassification, DOCUMENTSTATUS } from "@common/constants/enumerations";
import { Keyword } from "@common/models/keyword";
import { eBooleanType } from "@common/pages/settings/lookups/documents/_id/components/child-screens/general/components/form-element/model";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export const nameOfCCHelpDocument = nameOfFactory<ICCHelpDocument>();
export interface ICCHelpDocument extends DTOBaseClass_Standard {
  Attachment_ID: number;
  Tag: string;
  Title: string;
  FileEntry: FileStreamEntry;
  TRIMUri: string;
  RecordType: string;
  Legacy_RecordSourceID?: number;
  Legacy_RecordSourceTypeID?: number;
  Category_KWD?: number;
  Category: Keyword;
  DocumentTemplate_ID?: number;
  SortOrder: number;
  JournalPOI_ENUM: JournalPOI;
  ExternalReferenceId?: string;
  BubbleUps: BubbleUpIdentifier[];
  AddToRecords: boolean;
  UseParentFileNumber: boolean;
  FileNumber: string;
  Records_Notes: string;
  Records_ParentRecordId: string;
  CustomFieldList: KeyValuePair<string, string>[];
  AvailableFieldsList: KeyValuePair<string, string>[];
  PreferredAuthor_ID?: number;
  PreferredAuthor_DisplayName: string;
  PreferredAuthor_UserName: string;
  Manager_DisplayName: string;
  Manager_UserName: string;
  Offline_Ref: string;
  FormattedAuthorName: string;
  AssessmentNumber: string;
  Status_ENUM?: DOCUMENTSTATUS;
  Status_Name: string;
  DocumentClassification_ENUM?: DocumentClassification;
  DocumentClassification_Name: string;
  ExternalDocumentType_KWD?: number;
  ExternalDocument?: boolean;
  SaveLocalCopy: boolean;
  SubjectIndex: string;
  FileFolderNumber: string;
  ExternalDocument_ID: string;
  ApplicationType_ENUM?: number;
  ApplicationType_Name: string;
  SaveTriggers: EHelpDocumentUpdateTriggers[];
  _SaveTriggers: EHelpDocumentUpdateTriggers[]
}

export interface ICCHelpDocument_Ext extends ICCHelpDocument {
  _options?: {
    SaveAsFormat?: eBooleanType;
    Availability?: eBooleanType;
    FileUpload?: Attachment[];
    IsDisabledSaveAsFormat?: boolean;
  };
}

export interface ILookupHelpDocumentParentSection {
  notification?: IAppNotificationItemAddProps[];
  parentDocument?: ICCHelpDocument_Ext;
}

export enum EHelpDocumentUpdateTriggers {
  UpdateTitle = 'UpdateTitle',
  UpdatePOI = 'UpdatePOI',
  JournalUpdateFile = 'JournalUpdateFile',
}


