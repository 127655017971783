import { CCDocumentsInput } from "@app/core/documents/components/documents-input/_index";
import { Attachment } from "@app/core/documents/model";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { ApplicationSettingSearchComboBox } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-search-combobox/_index";
import { ApplicationSettingSwitch } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-switch/_index";

import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCLabel } from "@components/cc-label/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const ApplicationMailMergeForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { configData = {} } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();

    //#region  fields
    const fieldElectronicSignature =
      configData[
        ECorporateSettingsField.TP_MailMerge_ElectronicSignature_ID.toString()
      ];
    const fieldOptionElectronicSignature = `option.ValueDisplayDetails_${ECorporateSettingsField.TP_MailMerge_ElectronicSignature_ID}`;
    const attachments = valueGetter(fieldOptionElectronicSignature);

    const handleUpdateSignature = useCallback(
      (attachments: Attachment[]) => {
        onChange(fieldOptionElectronicSignature, {
          value: !attachments.length ? [] : attachments,
        });
        onChange(fieldElectronicSignature?.FieldName, {
          value: !attachments.length ? "" : attachments[0].Attachment_ID,
        });
      },
      [onChange, fieldElectronicSignature, fieldOptionElectronicSignature]
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel
              title={fieldElectronicSignature?.Title ?? ""}
              isMandatory={fieldElectronicSignature.IsMandatory}
            />
            <Field
              name={fieldElectronicSignature?.FieldName}
              value={attachments}
              component={CCDocumentsInput}
              disabled={!isEditing}
              mode="single"
              onChange={handleUpdateSignature}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_MailMerge_CollateInterestedParties
            }
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_MailMerge_CombineIPMailMergeDocsAsOne
            }
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSearchComboBox
            enumParam={
              ECorporateSettingsField.TP_MailMerge_ConditionsMailmergeFormat
            }
          />
        </div>
      </section>
    );
  }
);
