import { getViewConfigurations } from "@app/products/property/api";
import { DTO_Title } from "@app/products/property/assessments/components/form-steps/reactivate-assessment/components/form-elements/associations-parcel/model";
import {
  colFoliosAssessment,
  colPICAmalgamate,
} from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/certificate-details/config";
import {
  eCertificateType,
  eCertificateTypeComponent,
} from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/certificate-details/model";
import { useCertificateDetailStepStore } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/certificate-details/store";
import { certificateDetailValidator } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/certificate-details/util";
import {
  DTO_ChecklistQuestion,
  NewCertificateKeysOfSteps,
} from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/model";
import { useNewCertificateDialogStore } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/store";
import { loadDynamicQuestionList } from "@app/products/property/certificates/[id]/util";
import { getValueName } from "@app/products/property/charge-and-notice-runs/charge-runs/components/form-steps/create-charge-run/components/form-elements/details/util";
import { AddAssessmentLookupDialog } from "@app/products/property/components/dialogs/add-assessment-lookup/_index";
import { getSearchAssessmentLookup } from "@app/products/property/components/dialogs/add-assessment-lookup/api";
import {
  colAssessmentLookup,
  eOptionSearchAssessmentLookup,
} from "@app/products/property/components/dialogs/add-assessment-lookup/config";
import {
  DTO_Assessment,
  fnt_Assessment_LookupResult,
} from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { AddParcelLookupDialog } from "@app/products/property/components/dialogs/add-parcel-lookup/_index";
import { getSearchParcelLookup } from "@app/products/property/components/dialogs/add-parcel-lookup/api";
import {
  colParcelLookup,
  eOptionSearchParcelLookup,
} from "@app/products/property/components/dialogs/add-parcel-lookup/config";
import { DTO_Parcel } from "@app/products/property/components/dialogs/add-parcel-lookup/model";
import { AddPICLookupDialog } from "@app/products/property/components/dialogs/add-pic-lookup/_index";
import { DTO_PIC } from "@app/products/property/components/dialogs/add-pic-lookup/model";
import { AddTitleLookupDialog } from "@app/products/property/components/dialogs/add-title-lookup/_index";
import { getSearchTitleLookup } from "@app/products/property/components/dialogs/add-title-lookup/api";
import { eOptionSearchTitleLookup } from "@app/products/property/components/dialogs/add-title-lookup/config";
import { fnt_Title_LookupResult } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  EListSubmitButton,
  ViewConfiguration,
  nameOfLov,
} from "@app/products/property/model";
import { processCombineData } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { VO_PIC } from "@app/products/property/pic/list/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { Label } from "@common/stores/products/config";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { difference, isEmpty, isEqual, isNil, remove, union } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

const nameOfPIC = nameOfFactory<VO_PIC>();
const nameOfFolio = nameOfFactory<DTO_Title>();
const nameOfHolding = nameOfFactory<DTO_Assessment>();
const nameOfParcel = nameOfFactory<DTO_Parcel>();
export const CertificateDetailFormStep = (props: IFormStepElement) => {
  const {
    options: { isReadOnly = false, isLLS = false },
  } = props;

  const newCertificateDetailValidator = useCallback(
    (value: any) => {
      if (isReadOnly) return undefined;
      return certificateDetailValidator(value, isLLS);
    },
    [isReadOnly, isLLS]
  );

  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={newCertificateDetailValidator}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    localNotificationRef,
    nameOf,
    options = {
      isReadOnly: false,
      isLLS: false,
    },
    setStepsVisible,
  }: IFormStepElement) => {
    const { valueGetter, onChange, onSubmit } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    //store
    const { setDynamicQuestionList, dynamicQuestionList, setTypeChange } =
      useNewCertificateDialogStore();
    const { certificateDetailStepLOVs } = useCertificateDetailStepStore();
    const certificateTypeLOVs =
      certificateDetailStepLOVs?.CertificateType ?? [];

    //Get labels
    const [
      assessmentLabel,
      assessmentsLabel,
      assessmentLowercaseLabel,
      titlesLabel,
      titleLowercaseLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Assessment,
      ECustomColNameProperty.Assessments,
      ECustomColNameProperty.AssessmentLowercase,
      ECustomColNameProperty.Titles,
      ECustomColNameProperty.TitleLowercase,
    ]);

    //get value
    const parcels = getFieldValue("Parcels") ?? [];
    const assessments = getFieldValue("Assessments") ?? [];
    const parcelSelected = getFieldValue("ParcelSelected") ?? [];
    const assessmentSelected = getFieldValue("AssessmentSelected") ?? [];
    const loadingMode = getFieldValue("_option.Loading");
    //state
    const [isShowDialog, setIsShowDialog] = useState<eCertificateType>();
    const [columnsAssessment, setColumnsAssessment] =
      useState<IColumnFields[]>(colAssessmentLookup);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);
    const [columnsParcel, setProcessedParcel] =
      useState<IColumnFields[]>(colParcelLookup);
    const [columnsPIC, setProcessedPIC] =
      useState<IColumnFields[]>(colPICAmalgamate);

    const certificateType = getFieldValue("CertificateType");
    const certificateTypeComponent = getValueName(
      certificateType,
      certificateTypeLOVs ?? [],
      "Component"
    );
    const selectedPIC = getFieldValue("_option.PICSelected") ?? [];
    const pic = getFieldValue("PICs") ?? [];
    const folio = getFieldValue("AssociateTitles") ?? [];
    const folioSelected = getFieldValue("_option.FolioSelected") ?? [];
    const picIds = options?.listIDs ?? [];

    useEffect(() => {
      onChange(nameOf("_option.CertificateTypeComponent"), {
        value: certificateTypeComponent,
      });
      onChange(nameOf("PIC_Ids"), {
        value: picIds,
      });
      setStepsVisible([
        {
          visible:
            !isNil(certificateType) &&
            dynamicQuestionList &&
            dynamicQuestionList.length > 0,
          key: NewCertificateKeysOfSteps.Questionnaire,
          isClearData: false,
        },
      ]);
      // eslint-disable-next-line
    }, [certificateType, pic, picIds, dynamicQuestionList]);

    /**
     * handle add item
     * @param data
     * @param field
     */
    const handleAddItem = async (data: any[], field: string) => {
      //reset notification
      localNotificationRef?.current?.resetNotifications();
      //get previous Parcel and Holding
      const previousParcel =
        parcels?.length && !options?.isLLS ? [...parcels] : [];
      const previousAssessment = assessments?.length ? [...assessments] : [];
      const previousFolio = folio?.length ? [...folio] : [];
      switch (field) {
        case eCertificateTypeComponent.Parcel:
          handleAddParcel(data, previousParcel, previousAssessment);
          break;
        case eCertificateTypeComponent.Assessment:
          if (options?.isLLS) {
            handleAddAssessment(data, previousAssessment, [], previousFolio);
          } else {
            handleAddAssessment(data, previousAssessment, previousParcel);
          }
          break;
        case eCertificateTypeComponent.Lot:
          handleAddFolios(data, previousFolio, previousAssessment);
          break;
      }
    };

    const handleAddParcel = async (
      data: DTO_Parcel[],
      previousParcel: DTO_Parcel[],
      previousAssessment: DTO_Assessment[]
    ) => {
      options?.setIsLoadingInStep(true);
      const listParcelId = data.map((item) => item.Parcel_Id).toString();
      onChange(nameOf("Parcels"), {
        value: processCombineData(previousParcel, data, "Parcel_Id"),
      });
      //Calling api for assessment list
      if (data) {
        onChange(nameOf("_option.Loading"), {
          value: eCertificateType.Assessment,
        });
        const response = await getSearchAssessmentLookup({
          LookupKey: eOptionSearchAssessmentLookup.ParcelId, // 7 - Parcel Id
          LookupValue: listParcelId,
          Statuses: [0], //Fixed now (0: Active)
        });
        onChange(nameOf("_option.Loading"), {
          value: undefined,
        });
        if (isSuccessResponse(response) && response?.data) {
          const resAssessment = response?.data?.Assessments ?? [];
          if (resAssessment?.length) {
            onChange(nameOf("Assessments"), {
              value: processCombineData(
                previousAssessment,
                resAssessment,
                nameOfHolding("Assessment_Id")
              ),
            });
          }
        } else {
          localNotificationRef?.current?.pushNotification({
            title: `Load ${assessmentsLabel} failed`,
            type: "error",
            autoClose: false,
          });
        }
        options?.setIsLoadingInStep(false);
      }
    };

    const handleAddAssessment = async (
      data: fnt_Assessment_LookupResult[],
      previousAssessment: DTO_Assessment[],
      previousParcel?: DTO_Parcel[],
      previousFolio?: DTO_Title[]
    ) => {
      const listAssessmentId = data
        .map((item) => item.Assessment_Id)
        .toString();
      options?.setIsLoadingInStep(true);
      onChange(nameOf("_option.Loading"), {
        value: eCertificateType.Assessment,
      });
      const response = await getSearchAssessmentLookup({
        LookupKey: eOptionSearchAssessmentLookup.AssessmentId,
        LookupValue: listAssessmentId,
        Statuses: [0], //Fixed now (0: Active)
      });
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      if (isSuccessResponse(response) && response?.data) {
        const resAssessment = response?.data?.Assessments ?? [];
        if (resAssessment?.length) {
          const newData = processCombineData(
            previousAssessment,
            resAssessment,
            nameOfHolding("Assessment_Id")
          );
          onChange(nameOf("Assessments"), {
            value: newData,
          });
        }
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${assessmentsLabel} failed`,
          type: "error",
          autoClose: false,
        });
      }
      //Calling api for parcel list
      if (data && !options?.isLLS) {
        onChange(nameOf("_option.Loading"), {
          value: eCertificateType.Parcel,
        });
        const response = await getSearchParcelLookup({
          LookupKey: eOptionSearchParcelLookup.AssessmentId, // 6 - Assessment Id
          LookupValue: listAssessmentId,
          Statuses: [0] /* default Active */,
        });
        onChange(nameOf("_option.Loading"), {
          value: undefined,
        });
        if (isSuccessResponse(response) && response?.data) {
          const resParcel = response?.data?.Parcels ?? [];
          if (resParcel?.length) {
            onChange(nameOf("Parcels"), {
              value: processCombineData(
                previousParcel ?? [],
                resParcel,
                nameOfParcel("Parcel_Id")
              ),
            });
          }
        } else {
          localNotificationRef?.current?.pushNotification({
            title: "Load Parcel failed",
            type: "error",
            autoClose: false,
          });
        }
        onChange(nameOf("_option.Loading"), {
          value: undefined,
        });
      } else {
        onChange(nameOf("_option.Loading"), {
          value: eCertificateType.Lot,
        });
        const responseTitleLookup = await getSearchTitleLookup({
          LookupKey: eOptionSearchTitleLookup.AssessmentId, // 6 - Assessment Id
          LookupValue: listAssessmentId,
          Statuses: [0],
        });
        onChange(nameOf("_option.Loading"), {
          value: undefined,
        });
        if (
          isSuccessResponse(responseTitleLookup) &&
          responseTitleLookup?.data
        ) {
          const resTitle = responseTitleLookup?.data?.Titles ?? [];
          const newFolio =
            resTitle?.map((item) => {
              //calculate maximum Area Percentage can be allocated
              const maxPercentage = 100 - (item?.AssociatedPICAreaTotal ?? 0);
              return {
                ...item,
                AssociatedPICAreaSet: maxPercentage,
              };
            }) ?? [];
          if (resTitle?.length) {
            onChange(nameOf("AssociateTitles"), {
              //merge previous data and new one
              value: processCombineData(
                previousFolio ?? [],
                newFolio,
                nameOfFolio("TitleId")
              ),
            });
          }
        } else {
          localNotificationRef?.current?.pushNotification({
            title: `Load ${titleLowercaseLabel}s failed`,
            type: "error",
            autoClose: false,
          });
        }
      }

      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      options?.setIsLoadingInStep(false);
    };

    /**
     * Load view configuration
     */
    const loadViewConfiguration = async () => {
      onChange(nameOf("_option.Loading"), {
        value: eCertificateType.ViewConfiguration,
      });
      options?.setIsLoadingInStep(true);
      const response = await Promise.all([
        getViewConfigurations(ViewConfiguration.AssessmentSearch),
        getViewConfigurations(ViewConfiguration.ParcelSearch),
        getViewConfigurations(ViewConfiguration.PIC_Lookup),
      ]);
      options?.setIsLoadingInStep(false);
      const [viewAssessment, viewParcel, viewPIC] = response;
      let errorResponse = undefined;
      if (Array.isArray(response)) {
        if (viewAssessment?.data) {
          setColumnsAssessment(
            processDynamicColumns(
              colAssessmentLookup,
              viewAssessment?.data?.ColumnDefinitions?.Columns
            )
          );
        }
        if (viewParcel?.data) {
          setProcessedParcel(
            processDynamicColumns(
              colParcelLookup,
              viewParcel?.data?.ColumnDefinitions?.Columns
            )
          );
        }
        if (viewPIC?.data) {
          const newColumnPIC = processDynamicColumns(
            colPICAmalgamate,
            viewPIC?.data?.ColumnDefinitions?.Columns
          );
          setProcessedPIC(newColumnPIC);
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      setResponseLoadError(errorResponse);
      onChange(nameOf("_option.Loading"), { value: undefined });
    };

    useEffectOnce(() => {
      loadViewConfiguration();
    });

    /**
     * handle delete item
     * @param field
     */
    const handleDeleteItem = (field: string) => {
      if (field === "Assessments") {
        const assessmentId = assessmentSelected?.[0]?.Assessment_Id;
        const newAssessment = assessments.filter(
          (item: DTO_Assessment) => item.Assessment_Id !== assessmentId
        );
        onChange(nameOf("Assessments"), {
          value: newAssessment,
        });
        onChange(nameOf("AssessmentSelected"), {
          value: [],
        });
      } else if (field === "Parcels") {
        const parcelId = parcelSelected?.[0]?.Parcel_Id;
        const newParcel = parcels.filter(
          (item: DTO_Parcel) => parcelId !== item.Parcel_Id
        );
        onChange(nameOf("Parcels"), {
          value: newParcel,
        });
        onChange(nameOf("ParcelSelected"), {
          value: [],
        });
      }
    };

    /**
     * Submit and Reload form
     */
    const submitAndReloadForm = async () => {
      onSubmit({
        currentTarget: {
          id: EListSubmitButton.Save,
          actions: { reloadForm: true },
        },
      } as any);
    };

    /**
     * handle add item (Holding and PIC)
     * @param data
     * @param field
     */
    const handleAddItemPIC = async (data: DTO_PIC[]) => {
      const picIds = data?.map((item) => item.PIC_Id) ?? [];
      const picListId = getFieldValue("PIC_Ids") ?? [];
      const differentListID = difference(picIds, picListId);
      if (differentListID) {
        const concatListPIC = union(picListId, picIds);
        onChange(nameOf("PIC_Ids"), {
          value: concatListPIC,
        });
        await submitAndReloadForm();
      }
    };

    /**
     * handle delete item
     * @param field
     */
    const handleDeleteItemPIC = async () => {
      //get selected pic
      const aPIC: DTO_PIC = selectedPIC?.[0];
      const picId = aPIC?.PIC_Id;
      remove(picIds, (id: number) => id === picId);
      onChange(nameOf("PIC_Ids"), { value: picIds });
      await submitAndReloadForm();
    };

    const handleDataChange = async (
      dataRow: any,
      gridName: string,
      primaryKey: "TitleId" | "Parcel_Id",
      gridData: DTO_Title[] | DTO_Parcel[]
    ) => {
      const id = dataRow?.[primaryKey];
      let newGridData = gridData ? [...gridData] : [];
      newGridData = newGridData?.map((item: any) =>
        item?.[primaryKey] === id ? { ...dataRow } : item
      );
      onChange(nameOf(gridName), { value: newGridData });
    };

    const handleDeleteItemFolio = () => {
      const folioId = folioSelected?.[0]?.TitleId;
      const newFolio = folio.filter(
        (item: DTO_Title) => folioId !== item.TitleId
      );
      onChange(nameOf("AssociateTitles"), {
        value: newFolio,
      });
      onChange(nameOf("_option.FolioSelected"), {
        value: [],
      });
    };

    const handleCertificateTypeChange = async (e: ComboBoxChangeEvent) => {
      const selectedCertificateType = e.value?.Code;
      onChange(nameOf("CertificateType"), { value: selectedCertificateType });
      setTypeChange(selectedCertificateType);
      switch (e.value?.Component) {
        case eCertificateTypeComponent.PIC:
          onChange(nameOf("Assessments"), {
            value: [],
          });
          onChange(nameOf("Parcels"), {
            value: [],
          });
          onChange(nameOf("AssociateTitles"), {
            value: [],
          });
          break;
        case eCertificateTypeComponent.Parcel:
        case eCertificateTypeComponent.Assessment:
          if (!isEmpty(pic)) {
            options?.setIsLoadingInStep(true);
            onChange(nameOf("PIC_Ids"), {
              value: [],
            });
            onChange(nameOf("PICs"), {
              value: [],
            });
            onChange(nameOf("Selected_Primary_PIC_Id"), { value: null });
            onChange(nameOf("_option.PICSelected"), { value: [] });
            submitAndReloadForm();
            options?.setIsLoadingInStep(false);
          }
          break;
      }

      // Load dynamic question list to show/hide Responses step --->
      // Reset dynamic question list
      setDynamicQuestionList([]);

      // Get dynamic question list ID of the selected certificate type
      const dynamicQuestionListId = getValueName(
        selectedCertificateType,
        certificateTypeLOVs ?? [],
        "DynamicQuestionListId"
      );

      // Call API to load dynamic question list by dynamicQuestionListId
      await loadDynamicQuestionList({
        dynamicQuestionListId,
        onTurnOnLoading: () => {
          options?.setIsLoadingInStep(true);
          onChange(nameOf("_option.Loading"), {
            value: eCertificateType.CertificateType,
          });
        },
        onTurnOffLoading: () => {
          options?.setIsLoadingInStep(false);
          onChange(nameOf("_option.Loading"), {
            value: undefined,
          });
        },
        onSuccess: (data: DTO_ChecklistQuestion[]) => {
          setDynamicQuestionList(data);
        },
        onError: (_error: APIResponseError) => {
          localNotificationRef?.current?.pushNotification({
            title: "Load dynamic question list failed.",
            type: "error",
            autoClose: false,
          });
        },
      });
      // Load dynamic question list to handle show/hide Responses step <---
    };

    const handleAddFolios = async (
      data: fnt_Title_LookupResult[],
      previousFolio: DTO_Title[],
      previousHolding: DTO_Assessment[]
    ) => {
      if (data.length === 0) return;
      const titleLookupValue = data
        .map((item: fnt_Title_LookupResult) => item?.Title_Id)
        .toString();
      options?.setIsLoadingInStep(true);
      onChange(nameOf("_option.Loading"), {
        value: eCertificateType.Lot,
      });
      //Calling api Lot
      const responseTitleLookup = await getSearchTitleLookup({
        LookupKey: eOptionSearchTitleLookup.TitleId,
        LookupValue: titleLookupValue,
        Statuses: [0], //default task 12866
      });
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      if (isSuccessResponse(responseTitleLookup) && responseTitleLookup?.data) {
        const resTitles = responseTitleLookup?.data?.Titles ?? [];
        //get current Folio
        if (resTitles.length) {
          const newTitles = resTitles.map((title: DTO_Title) => {
            //calculate maximum Area Percentage can be allocated
            const maxPercentage = 100 - (title?.AssociatedPICAreaTotal ?? 0);
            return {
              ...title,
              AssociatedPICAreaSet: maxPercentage,
            };
          });
          //merge previous data and new one
          const folioData = processCombineData(
            previousFolio,
            newTitles,
            nameOfFolio("TitleId")
          );
          onChange(nameOf("AssociateTitles"), {
            value: folioData,
          });
        }
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${titleLowercaseLabel}s failed`,
          type: "error",
          autoClose: false,
        });
      }
      //Calling api get holding by TitleID (get association of folio)
      onChange(nameOf("_option.Loading"), {
        value: eCertificateType.Assessment,
      });
      const response = await getSearchAssessmentLookup({
        LookupKey: eOptionSearchAssessmentLookup.TitleId, // 8 - Title Id
        LookupValue: titleLookupValue,
        Statuses: [0],
      });
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      if (isSuccessResponse(response) && response?.data) {
        const resAssessment = response?.data?.Assessments ?? [];
        if (resAssessment?.length) {
          resAssessment?.forEach((item: DTO_Assessment) => {
            item.Is_Primary_Assessment = item?.Is_Primary_Assessment ?? false;
          });
          onChange(nameOf("Assessments"), {
            //merge previous data and new one
            value: processCombineData(
              previousHolding,
              resAssessment,
              nameOfHolding("Assessment_Id")
            ),
          });
        }
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${assessmentLowercaseLabel}s failed`,
          type: "error",
          autoClose: false,
        });
      }
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      options?.setIsLoadingInStep(false);
    };

    return (
      <div className="cc-certificate-details">
        <section className="cc-field-group">
          <div className="cc-field">
            <label className="cc-label">
              Certificate type <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("CertificateType")}
              component={CCSearchComboBox}
              validator={!options?.isReadOnly ? validateRequired : undefined}
              data={certificateTypeLOVs ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              value={getDropdownValue(
                certificateType?.toString(),
                certificateTypeLOVs,
                nameOfLov("Code")
              )}
              disabled={options?.isReadOnly}
              onChange={handleCertificateTypeChange}
            />
          </div>
        </section>
        <br />
        {loadingMode ? (
          <Loading isLoading={loadingMode} />
        ) : responseLoadError ? (
          <CCLoadFailed
            responseError={responseLoadError}
            onReload={() => {
              loadViewConfiguration();
            }}
          />
        ) : (
          <section className="cc-field-group">
            {!isEqual(
              certificateTypeComponent,
              eCertificateTypeComponent.PIC
            ) ? (
              <>
                {options?.isLLS ? (
                  <div className="cc-field">
                    <label className="cc-label">
                      {titlesLabel}
                      {isEqual(
                        certificateTypeComponent,
                        eCertificateTypeComponent.Parcel
                      ) && <CCTooltip type="validator" position="right" />}
                      {getFieldValue("_option.Loading") ===
                      eCertificateType.Lot ? (
                        <span className="cc-icon-loading-free">
                          <i className="fas fa-spinner fa-spin" />
                        </span>
                      ) : null}
                    </label>
                    <CCGrid
                      selectableMode="single"
                      readOnly={options?.isReadOnly}
                      data={folio ?? []}
                      columnFields={colFoliosAssessment}
                      primaryField={nameOfFolio("TitleId")}
                      editableMode="cell"
                      onSelectionChange={(dataItems: DTO_Title[]) => {
                        onChange(nameOf("_option.FolioSelected"), {
                          value: dataItems ?? [],
                        });
                      }}
                      onDataRowChange={(dataRow) =>
                        handleDataChange(
                          dataRow,
                          "AssociateTitles",
                          "TitleId",
                          folio
                        )
                      }
                      toolbar={
                        !options?.isReadOnly ? (
                          <div className="cc-grid-tools-bar">
                            <Button
                              iconClass="fas fa-plus"
                              title={`Add ${titleLowercaseLabel}`}
                              onClick={() => {
                                setIsShowDialog(eCertificateType.Lot);
                              }}
                            />
                            <Button
                              iconClass="fas fa-minus"
                              title={`Remove ${titleLowercaseLabel}`}
                              onClick={handleDeleteItemFolio}
                              disabled={folioSelected?.length < 1}
                            />
                          </div>
                        ) : null
                      }
                    />
                  </div>
                ) : (
                  <div className="cc-field">
                    <label className="cc-label">
                      Parcel(s) included in certificate
                      {isEqual(
                        certificateTypeComponent,
                        eCertificateTypeComponent.Parcel
                      ) && <CCTooltip type="validator" position="right" />}
                      {getFieldValue("_option.Loading") ===
                      eCertificateType.Parcel ? (
                        <span className="cc-icon-loading-free">
                          <i className="fas fa-spinner fa-spin" />
                        </span>
                      ) : null}
                    </label>
                    <CCGrid
                      toolbar={
                        <div className="cc-grid-tools-bar">
                          <Button
                            iconClass="fas fa-plus"
                            type="button"
                            title="Add parcel"
                            onClick={() => {
                              setIsShowDialog(eCertificateType.Parcel);
                            }}
                            disabled={options?.isReadOnly}
                          />
                          <Button
                            type="button"
                            iconClass="fas fa-minus"
                            title="Remove parcel"
                            disabled={
                              options?.isReadOnly || parcelSelected?.length <= 0
                            }
                            onClick={() => {
                              handleDeleteItem("Parcels");
                            }}
                          />
                        </div>
                      }
                      selectableMode="single"
                      data={parcels ?? []}
                      primaryField={"Parcel_Id"}
                      columnFields={columnsParcel}
                      selectedRows={parcelSelected ?? []}
                      onSelectionChange={(dataItem: DTO_Parcel[]) => {
                        onChange(nameOf("ParcelSelected"), {
                          value: dataItem ?? [],
                        });
                      }}
                      readOnly={options?.isReadOnly}
                    />
                  </div>
                )}
                <div className="cc-field">
                  <label className="cc-label">
                    {assessmentLabel}(s) included in certificate
                    {isEqual(
                      certificateTypeComponent,
                      eCertificateTypeComponent.Assessment
                    ) && <CCTooltip type="validator" position="right" />}
                    {getFieldValue("_option.Loading") ===
                    eCertificateType.Assessment ? (
                      <span className="cc-icon-loading-free">
                        <i className="fas fa-spinner fa-spin" />
                      </span>
                    ) : null}
                  </label>
                  <CCGrid
                    toolbar={
                      <div className="cc-grid-tools-bar">
                        <Button
                          iconClass="fas fa-plus"
                          type="button"
                          title={`Add ${assessmentLowercaseLabel}`}
                          onClick={() => {
                            setIsShowDialog(eCertificateType.Assessment);
                          }}
                          disabled={options?.isReadOnly}
                        />
                        <Button
                          type="button"
                          iconClass="fas fa-minus"
                          title={`Remove ${assessmentLowercaseLabel}`}
                          disabled={
                            options?.isReadOnly ||
                            assessmentSelected?.length <= 0
                          }
                          onClick={() => {
                            handleDeleteItem("Assessments");
                          }}
                        />
                      </div>
                    }
                    selectableMode="single"
                    data={assessments ?? []}
                    selectedRows={assessmentSelected ?? []}
                    primaryField={nameOfHolding("Assessment_Id")}
                    columnFields={columnsAssessment}
                    onSelectionChange={(
                      dataItem: fnt_Assessment_LookupResult[]
                    ) => {
                      onChange(nameOf("AssessmentSelected"), {
                        value: dataItem ?? [],
                      });
                    }}
                    readOnly={options?.isReadOnly}
                  />
                </div>
              </>
            ) : (
              <div className="cc-field">
                <label className="cc-label">
                  PIC(s) included in certificate
                  {isEqual(
                    certificateTypeComponent,
                    eCertificateTypeComponent.PIC
                  ) && <CCTooltip type="validator" position="right" />}
                </label>
                <div className="cc-field">
                  <CCGrid
                    data={pic ?? []}
                    columnFields={columnsPIC}
                    selectableMode="single"
                    primaryField={nameOfPIC("PIC_Id")}
                    onSelectionChange={(dataItems) => {
                      onChange(nameOf("_option.PICSelected"), {
                        value: dataItems,
                      });
                    }}
                    readOnly={options?.isReadOnly}
                    toolbar={
                      !options?.isReadOnly ? (
                        <div className="cc-grid-tools-bar">
                          <Button
                            iconClass="fas fa-plus"
                            title="Add a PIC"
                            onClick={() => {
                              setIsShowDialog(eCertificateType.PICNew);
                            }}
                          />
                          <Button
                            iconClass="fas fa-minus"
                            title="Remove a PIC"
                            onClick={() =>
                              setIsShowDialog(eCertificateType.PICDelete)
                            }
                            disabled={selectedPIC.length !== 1}
                          />
                        </div>
                      ) : null
                    }
                  />
                </div>
              </div>
            )}
          </section>
        )}
        {isShowDialog === eCertificateType.Lot && (
          <AddTitleLookupDialog
            onClose={() => setIsShowDialog(undefined)}
            handleAddTitle={async (data: fnt_Title_LookupResult[]) => {
              setIsShowDialog(undefined);
              await handleAddItem(data, eCertificateTypeComponent.Lot);
            }}
          />
        )}
        {isShowDialog === eCertificateType.Parcel && (
          <AddParcelLookupDialog
            onClose={() => setIsShowDialog(undefined)}
            handleAddParcel={async (data: DTO_Parcel[]) => {
              await handleAddItem(data, eCertificateTypeComponent.Parcel);
              setIsShowDialog(undefined);
            }}
            isLoadingFinish={loadingMode === eCertificateType.Parcel}
          />
        )}
        {isShowDialog === eCertificateType.Assessment && (
          <AddAssessmentLookupDialog
            onClose={() => setIsShowDialog(undefined)}
            handleAddAssessment={async (
              data: fnt_Assessment_LookupResult[]
            ) => {
              await handleAddItem(data, eCertificateTypeComponent.Assessment);
              setIsShowDialog(undefined);
            }}
            isLoadingFinish={loadingMode === eCertificateType.Assessment}
          />
        )}
        {isShowDialog === eCertificateType.PICNew && (
          <AddPICLookupDialog
            onClose={() => setIsShowDialog(undefined)}
            handleAddPIC={async (data: any) => {
              setIsShowDialog(undefined);
              await handleAddItemPIC(data);
            }}
          />
        )}
        {isShowDialog === eCertificateType.PICDelete && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={"Are you sure you wish to delete the selected PIC(s)?"}
            onClosePopup={() => setIsShowDialog(undefined)}
            onConfirmYes={handleDeleteItemPIC}
          />
        )}
      </div>
    );
  }
);
