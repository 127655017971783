import { NUMBER_FORMAT } from "@common/constants/common-format";
import { Svc_NoticeType } from "@common/models/noticeTypes";
import { SETTINGS_LOOKUPS_NOTICETYPES_ROUTE } from "@common/pages/settings/lookups/notice-types/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_NoticeType>();

export const colLookupsNoticeTypes: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    locked: true,
    linkTo: (dataItem: Svc_NoticeType) =>
      `${SETTINGS_LOOKUPS_NOTICETYPES_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("Classification"),
    title: "Classification",
  },
  {
    field: nameOf("PenaltyUnits"),
    title: "Penalty units",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Description"),
    title: "Section of legislation",
  },
  {
    field: nameOf("Code"),
    title: "Code",
  },
  {
    field: nameOf("SortCode"),
    title: "Sort code",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
