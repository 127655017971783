import { eventEmitter } from "@/App";
import { UITownPlanning } from "@app/products/town-planning/model";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { ReasonDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putPanelReportNotApproved } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/not-approve-panel-report/api";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const NotApprovePanelReportButton = observer(() => {
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();

  const { ppr, onSubmit, pprId, setPPRWithLoading } = usePSARStore();
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const isVisible = useMemo(() => {
    return ppr?.Status_ENUM === Application_Status.PanelReportSentForApproval;
  }, [ppr]);

  const objReason = useMemo(() => {
    const retVal = new ProvideReason();
    retVal.Title_Text = "Reject Panel Report";
    retVal.ReasonLabel_Text = "Comments";
    retVal.Flag_ShowDate = false;
    return retVal;
  }, []);

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.PanelReportNotApproved.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const handleProvideReasonSubmit = (
    value: ProvideReason,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    if (!pprId) return;
    setIsLoading(true);
    putPanelReportNotApproved(pprId, value.Reason ?? "").then((response) => {
      setIsLoading(false);
      if (isSuccessIdentityPacket(response) && response.data?.Application) {
        setIsShowDialog(false);
        setPPRWithLoading(response.data.Application);
        clearNotifications();
        pushNotification({
          type: "success",
          title: "Hearing submission report not approved successfully",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: "Hearing submission report not approved failed",
          type: "error",
          description: response?.data?.Errors ?? response?.error,
        });
      }
    });
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Hearing submission report not approved"
        onClick={onSubmit}
        name={PSARSubmitActions.NotApprovePanelReport}
      />
      {isShowDialog && (
        <ReasonDialog
          onClose={() => setIsShowDialog(false)}
          onSubmit={handleProvideReasonSubmit}
          isLoading={isLoading}
          initialData={objReason}
        />
      )}
    </>
  ) : null;
});
