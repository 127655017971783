import { DTO_Association } from "@app/products/property/assessments/[id]/components/child-screens/associate-assessment/model";
import { useAssociateAssessmentStore } from "@app/products/property/assessments/[id]/components/child-screens/associate-assessment/store";
import { ASSESSMENT_ROUTE } from "@app/products/property/assessments/components/form-steps/modify-assessment/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

interface IAssociateAssessmentAccordion {
  assessmentId: number;
}
const nameOf = nameOfFactory<DTO_Association>();
export const AssociateAssessmentAccordion = observer(
  ({ assessmentId }: IAssociateAssessmentAccordion) => {
    // Store
    const {
      isLoading,
      responseLoadError,
      resetStore,
      associateAssessment,
      colAssociateAssessment,
      loadAssociateAssessment,
    } = useAssociateAssessmentStore();

    const renderNamesColumn = (cols: IColumnFields[]) => {
      return cols.map((col: IColumnFields) => {
        //Do nothing while in readOnly mode
        if (col.field === nameOf("AssessmentNumber")) {
          col.linkTo = (dataItem) => `${ASSESSMENT_ROUTE}/${dataItem?.Id}`;
        }
        return col;
      });
    };

    useEffect(() => {
      loadAssociateAssessment(+assessmentId);
      return () => {
        resetStore();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assessmentId]);

    if (isLoading) {
      return <Loading isLoading={isLoading} />;
    }

    if (responseLoadError) {
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadAssociateAssessment(+assessmentId);
          }}
        />
      );
    }

    return (
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              data={associateAssessment?.AssessmentAssociations ?? []}
              columnFields={renderNamesColumn(colAssociateAssessment ?? [])}
              primaryField={nameOf("Id")}
            />
          </div>
        </div>
      </div>
    );
  }
);
