import { BOOLEAN_FORMAT } from "@common/constants/common-format";
import { SITE_USER_ROUTE } from "@common/pages/profile/constant";
import { Svc_HomepagePageUser } from "@common/pages/settings/homepage/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_HomepagePageUser>();
export const colHomepageUserByViews: IColumnFields[] = [
  {
    title: "First Name",
    field: nameOf("FirstName"),
    locked: true,
    linkTo: (dataItem: Svc_HomepagePageUser) => {
      return `${SITE_USER_ROUTE}/${dataItem.ID}`;
    },
  },
  { title: "Last Name", field: nameOf("LastName") },
  { title: "Login Name", field: nameOf("UserName") },
  { title: "Email", field: nameOf("Email") },
  { title: "Security Templates", field: nameOf("SecurityTemplates") },
  {
    title: "Active",
    field: nameOf("IsActive"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    title: "Locked",
    field: nameOf("IsLocked"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  // todo: implement 'Organisation Structure' later
  { title: "Organisation Structure", field: "Hierarchy" },
  { title: "Homepage View", field: nameOf("HomePageView") },
];
