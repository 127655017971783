export interface Svc_Contact_PickContactNAR {
  ContactID: string;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  DOB: Date | null;
  Address: string;
  TypeName: string;
  Email: string;
  WorkPhone: string;
  Mobile: string;
  HomePhone: string;
  Phone: string;
}
export interface Contact_PickContactNAR_Search {
  ContactID?: string;
  FirstName?: string;
  LastName?: string;
  Address?: string;
  DateOfBirth?: Date | null;
  HomePhone?: string;
}

export enum SUBMIT_TYPE_NAR_CONTACT {
  DIALOG_OK = "DIALOG_OK",
  COPYCONTACTALL = "COPYCONTACTALL",
  COPYCONTACTADDRESS = "COPYCONTACTADDRESS",
}

export interface Svc_Contact_PickContactNAR_Request {
  ContactID: string;
  SubmitType: SUBMIT_TYPE_NAR_CONTACT;
}

export interface ABNChecker_Request {
  IsABNValidation: boolean;
  AuthenticationGuid: string | null;
  SearchString: string | null;
  IncludeHistoricalData: boolean;
}

export interface ABNMetadata {
  IsABN: boolean;
  ABN: string;
  EntityType: string;
  ABNStatus: string;
  OrganisationName: string;
  EffectiveFrom: Date | null;
  EffectiveTo: Date | null;
  StateCode: string;
  PostCode: string;
  GoodsServicesTax: string;
  ErrorMessage: string;
}
