import { getLabel } from "@app/products/property/components/label/config";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.DebtRecoveryId
    ? `- ${selectedRow.DebtRecoveryId}`
    : "";
  return `Property - ${getLabel.assessments()} ${dynamicDisplayName}`;
};

//@TODO Don't have CurrentRatepayer data in grid mock data, recheck when integrated API
export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.DebtRecoveryId,
    selectedRow.CurrentRatepayer,
  ]);
};

export const getBookmarkListViewDetail = () => {
  return `Property - ${getLabel.assessments()} - Debt Recovery - List - All`;
};

export const getBookmarkListViewDisplayName = () => {
  return `Property - ${getLabel.assessments()}`;
};
