import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ViewConfiguration } from "@app/products/property/model";
import {
  colSupplementaryCharges,
  getSupplementaryChargesOdata,
} from "@app/products/property/supplementary-rates/[id]/components/child-screens/charges/config";
import { VO_Supplementary_Levy_Calculation } from "@app/products/property/supplementary-rates/[id]/components/child-screens/charges/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const nameOf = nameOfFactory<VO_Supplementary_Levy_Calculation>();
export const SupplementaryRatesCharges = observer(() => {
  const { supplementaryRatesId } = useSupplementaryRatesStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [processedCols, setProcessedCols] = useState<IColumnFields[]>(
    colSupplementaryCharges
  );

  const loadSupplementaryRatesChargesConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    let response = await loadViewConfiguresColumns(
      ViewConfiguration.SupplementaryRates_Charges,
      colSupplementaryCharges
    );
    setIsLoading(false);
    if (Array.isArray(response)) {
      setProcessedCols(response);
    } else {
      setResponseLoadError(response);
    }
  };

  useEffectOnce(() => {
    loadSupplementaryRatesChargesConfig();
  });

  return (
    <div className="cc-supplementary-rates-charges">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadSupplementaryRatesChargesConfig();
          }}
        />
      ) : (
        <CCGrid
          dataUrl={getSupplementaryChargesOdata(supplementaryRatesId)}
          columnFields={processedCols}
          primaryField={nameOf("Supplementary_Levy_Calc_Id")}
        />
      )}
    </div>
  );
});
