import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { LOCAL_LAWS_MANAGE_ROUTE } from "@app/products/local-laws/[id]/constant";
import { PermitContactsTab } from "@app/products/local-laws/components/sidebar/contacts/_index";
import { PermitDetailsTab } from "@app/products/local-laws/components/sidebar/details/_index";
import { PermitHistoryTab } from "@app/products/local-laws/components/sidebar/history/_index";
import { NewPermitButton } from "@app/products/local-laws/components/toolbar/buttons/new-local-laws/_index";
import { LocalLawsActionBarNavDropdown } from "@app/products/local-laws/components/toolbar/nav-dropdown/_index";
import {
  colLocalLawsFinanceDebtorSummaryAll,
  generateDebtorSummaryColumnsBySetting,
} from "@app/products/local-laws/finance/debtor-summary-all/config";
import {
  PermitDebtorSummaryTypes,
  nameOfPermitFinances,
} from "@app/products/local-laws/finance/model";
import { financeRoute } from "@app/products/local-laws/finance/route";
import {
  getLocalLawsDebtorSummary,
  llFinanceBookmark,
} from "@app/products/local-laws/finance/util";
import { Svc_LLPermit } from "@app/products/local-laws/permits/model";
import { localLawsRoute } from "@app/products/local-laws/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCommonCoreStore } from "@common/stores/core/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<Svc_LLPermit>();
export default observer(() => {
  const { settings } = useCommonCoreStore();

  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.LLPermits_Form_Permit,
    productType: PRODUCT_TYPE_NUMBER.LLPermits,
  });

  const { listViewDisplayURL, recordDisplayURL, listViewDisplayTitle } =
    llFinanceBookmark("Debtor Summary Outstanding");

  useCCListViewActionBar({
    title: localLawsRoute.name,
    leftComponents: [
      <LocalLawsActionBarNavDropdown category={financeRoute.path} />,
    ],
    centerComponents: [
      <NewPermitButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.LLPermits}
        dataSetFilter={MAILMERGEDATASET.LLP_Permit}
        recordType={RECORDTYPE.LLP_Permit}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.LLPermits}
        recordType={RECORDTYPE.LLP_Permit}
        keyField={nameOfPermitFinances("ID")}
        isDisabled={
          !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMUNICATION)
        }
      />,
      <DeleteButton
        recordType={RECORDTYPE.LLP_Permit}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={LOCAL_LAWS_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.LLPermits}
        //TODO don't have exactly record type, temporary use LLP_Permit
        recordType={RECORDTYPE.LLP_Permit}
        detail={listViewDisplayURL}
        displayName={recordDisplayURL}
        listViewDetail={listViewDisplayTitle}
        listViewDisplayName={listViewDisplayURL}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <PermitDetailsTab />,
      },
      {
        title: "Map",
        component: (
          <MapTab
            addressField={[nameOf("SiteAddress"), nameOf("Locality")]}
            zoom={18}
            recordType={RECORDTYPE.LLP_Permit}
            isInManagePage={false}
          />
        ),
      },
      { title: "Contacts", component: <PermitContactsTab /> },
      { title: "History", component: <PermitHistoryTab /> },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        dataUrl={getLocalLawsDebtorSummary(
          PermitDebtorSummaryTypes.DebtorSummaryOutstanding
        )}
        columnFields={generateDebtorSummaryColumnsBySetting({
          settings,
          defaultConfig: colLocalLawsFinanceDebtorSummaryAll,
        })}
        primaryField={nameOfPermitFinances("ID")}
        state={{
          sort: [
            {
              field: nameOfPermitFinances("PermitNumber"),
              dir: "desc",
            },
          ],
        }}
      />
    </LoadingPermissionWrapper>
  );
});
