import { IAttachment } from "@app/core/attachment/components/buttons/add-attachment/model";
import { AddAttachmentDialog } from "@app/core/attachment/components/dialogs/_index";
import {
  eUploadMode,
  IAddAttachmentDialog,
} from "@app/core/attachment/components/dialogs/model";
import { convertToFileStreamEntry } from "@app/core/attachment/components/dialogs/util";
import { ContactClassification } from "@app/core/contacts/_id/model";
import { getDocumentById, saveDocument } from "@app/core/documents/api";
import { Svc_Attachment } from "@app/core/documents/model";
import { getKeywordNameById } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/api";
import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import AvatarDefault from "@assets/img/AvatarDefault.svg";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { APIResponseStatus } from "@common/constants/response-status";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ContactPicker } from "@common/pages/profile/components/input-picker/officer-picker/_index";
import { Svc_ContactDetails } from "@common/pages/profile/components/input-picker/officer-picker/model";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { nameOfFactory } from "@common/utils/common";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import "./_index.scss";
export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export default observer(({ formRenderProps }: ICommonFormProps) => {
  const nameOf = nameOfFactory<Svc_ContactDetails>();
  const { onChange, valueGetter } = formRenderProps;
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const { getConfigDataField } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isLoadingUploadPhoto, setIsLoadingUploadPhoto] = useState(false);
  const [currentProcessingFieldName, setCurrentProcessingFieldName] =
    useState("");

  const fieldOrganisation = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_CouncilDetails
  );

  const organisationValueDisplayDetails = valueGetter(
    `option.ValueDisplayDetails_${ECorporateSettingsField.CorporateSettings_CouncilDetails}`
  )?.[0];

  const logoFields = [
    getConfigDataField(ECorporateSettingsField.CorporateSettings_HomePageLogo),
    getConfigDataField(ECorporateSettingsField.CorporateSettings_HomePageLogo2),
    getConfigDataField(ECorporateSettingsField.CorporateSettings_HeaderBarLogo),
  ];

  const handleOnOrganisation = async (value: Svc_ContactDetails) => {
    onChange(
      ECorporateSettingsField.CorporateSettings_CouncilDetails.toString(),
      {
        value: value.Id?.toString(),
      }
    );

    if (value.BodyType_KWD) {
      const response = await getKeywordNameById(parseInt(value.BodyType_KWD));
      value.DisplayName += ` (${response.data})`;
    }
    onChange(
      `option.ValueDisplayDetails_${ECorporateSettingsField.CorporateSettings_CouncilDetails}`,
      { value: [value] }
    );
  };
  const handleSaveAttachmentPhoto = async (data: IAddAttachmentDialog) => {
    setIsOpenPopup(true);
    setIsLoadingUploadPhoto(true);
    if (data?.UploadMode && data.UploadMode === eUploadMode.UPLOAD_FILE) {
      if (!isNil(data?.File) && isNil(data?.File[0].validationErrors)) {
        const fileEntry = await convertToFileStreamEntry(data?.File[0]);
        const attachments = {
          FileEntry: fileEntry,
          Title: data.Title ?? data?.File[0]?.name,
          Attachment_ID: 0,
          Category_KWD: data?.Category?.Keyword_ID ?? null,
          FileNumber: "",
          TRIMUri: "",
        } as IAttachment;

        const requestBody = {
          ParentID: parseInt(currentProcessingFieldName),
          ParentRecordType: RECORDTYPE.CORE_CorporateSetting,
          AttachmentList: [attachments],
          AddDateTimeToFileName: null,
        } as Svc_Attachment;
        const response = await saveDocument(requestBody);

        if (isSuccessIdentityPacket(response)) {
          const responseDocument = await getDocumentById(
            response.data?.ID,
            true
          );
          if (isSuccessResponse(responseDocument)) {
            onChange(
              `option.ValueDisplayDetails_${currentProcessingFieldName}`,
              {
                value: [responseDocument.data],
              }
            );
            onChange(currentProcessingFieldName, {
              value: responseDocument.data?.Attachment_ID,
            });
            clearNotifications();
            pushNotification({
              title: "Photo uploaded successfully",
              type: "success",
            });
          } else {
            pushNotification({
              autoClose: false,
              title: "Upload photo failed",
              type: "error",
            });
          }
        } else {
          if (response?.status === APIResponseStatus.EXCEEDED_SIZE_LIMIT) {
            pushNotification({
              autoClose: false,
              title: "Exceeded the specified size limit",
              type: "error",
            });
          } else {
            pushNotification({
              autoClose: false,
              title: "Upload photo failed",
              description: response.data?.Errors ?? response.statusText,
              type: "error",
            });
          }
        }
      }
    }
    setIsLoadingUploadPhoto(false);
    setIsOpenPopup(false);
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">
            {fieldOrganisation?.Title ?? ""}
            {fieldOrganisation?.IsMandatory && (
              <CCTooltip type="validator" position="right" />
            )}
            {fieldOrganisation?.HelpText && (
              <CCTooltip
                type="custom"
                content=" "
                position="auto"
                clickToOpen
                customTemplate={<>{sanitizeHtml(fieldOrganisation.HelpText)}</>}
              >
                <i className="fa fa-info-circle ml-1 text-accent" />
              </CCTooltip>
            )}
          </label>
          <Field
            name={fieldOrganisation?.FieldName ?? ""}
            component={ContactPicker}
            disabled={!isEditing}
            value={organisationValueDisplayDetails ?? null}
            grid={{
              selectedRows: valueGetter(
                `option.ValueDisplayDetails_${ECorporateSettingsField.CorporateSettings_CouncilDetails}`
              ),
            }}
            title="Contact Manager"
            classification={ContactClassification.IncorporatedBody}
            onPickOfficer={handleOnOrganisation}
            textField={nameOf("DisplayName")}
          />
        </div>
        <CCSearchComboBoxGlobalSettings
          data={getConfigDataField(
            ECorporateSettingsField.CorporateSettings_AddressDefaultState
          )}
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={getConfigDataField(
            ECorporateSettingsField.CorporateSettings_SystemLabel
          )}
          isEditing={isEditing}
        />
        {logoFields.map((field: SettingField | undefined) => {
          if (field) {
            const fieldDisplayValue = valueGetter(
              `option.ValueDisplayDetails_${field.FieldName}`
            )?.[0] as IAttachment;

            return (
              <div className="cc-field">
                <label className="cc-label">
                  {field?.Title ?? ""}
                  {field?.IsMandatory && (
                    <CCTooltip type="validator" position="right" />
                  )}
                  {field?.HelpText && (
                    <CCTooltip
                      type="custom"
                      content=" "
                      position="auto"
                      clickToOpen
                      customTemplate={<>{sanitizeHtml(field.HelpText)}</>}
                    >
                      <i className="fa fa-info-circle ml-1 text-accent" />
                    </CCTooltip>
                  )}
                </label>
                <div className="cc-image-upload">
                  <div className="cc-image-upload-control">
                    {fieldDisplayValue?.FileEntry?.FileAttachment
                      ? isEditing && (
                          <i
                            className="fa fa-times fa-2x"
                            onClick={() => {
                              formRenderProps.onChange(
                                `option.ValueDisplayDetails_${field.FieldName}`,
                                {
                                  value: null,
                                }
                              );
                              formRenderProps.onChange(field.FieldName, {
                                value: null,
                              });
                            }}
                            title="Remove"
                          />
                        )
                      : isEditing && (
                          <i
                            className="fa fa-upload fa-2x"
                            onClick={() => {
                              setIsOpenPopup(true);
                              setCurrentProcessingFieldName(field.FieldName);
                            }}
                            title="Upload"
                          />
                        )}
                  </div>
                  <img
                    src={
                      fieldDisplayValue
                        ? !fieldDisplayValue?.FileEntry?.FileAttachment?.toString().includes(
                            "blob:"
                          )
                          ? `data:${fieldDisplayValue?.FileEntry?.ContentType};base64,` +
                            fieldDisplayValue.FileEntry?.FileAttachment
                          : fieldDisplayValue.FileEntry?.FileAttachment
                        : AvatarDefault
                    }
                    className="cc-image-preview"
                    alt={field.Title ?? "Home page logo"}
                  />
                </div>
                {isOpenPopup && (
                  <AddAttachmentDialog
                    onClose={() => setIsOpenPopup(false)}
                    onSubmit={handleSaveAttachmentPhoto}
                    isLoadingButton={isLoadingUploadPhoto}
                    isDisableWebLink
                    isAllowMultiple={false}
                    allowedFileExtensions={"jpg|png|jpeg|bmp|gif"}
                  />
                )}
              </div>
            );
          }
          return null;
        })}
      </div>
    </section>
  );
});
