import { PROPERTY_JOURNALS_LISTS_VIEW_URL } from "@app/products/property/journals/list/constant";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import { colDetail } from "./config";

const DetailComponent = (props: GridDetailRowProps) => {
  return (
    <CCGrid
      dataUrl={PROPERTY_JOURNALS_LISTS_VIEW_URL}
      columnFields={colDetail}
      primaryField="Journal_Number"
      state={
        props.dataItem?.Session_Number
          ? {
              filter: {
                logic: "and",
                filters: [
                  {
                    field: "Session_Number",
                    value: props.dataItem?.Session_Number,
                    operator: "eq",
                  },
                ],
              },
            }
          : undefined
      }
    />
  );
};

export default DetailComponent;
