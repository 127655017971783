import { ApplicationCategoryFormElement } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/child-screens/general/components/form-element/_index";
import { ApplicationCategory } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/model";
import { useApplicationCategoriesStore } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const ApplicationCategoryForm = observer(() => {
  const { applicationCategories, saveApplicationCategories } =
    useApplicationCategoriesStore();
  const { pushNotification } = useCCAppNotificationStore();
  // Use for integrate API
  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = event;

    if (!isValid) return;
    if (!isModified)
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    const pprInfo = values as ApplicationCategory;
    saveApplicationCategories(pprInfo);
  };

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={applicationCategories}
        key={JSON.stringify(applicationCategories)}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <ApplicationCategoryFormElement
                  formRenderProps={formRenderProps}
                />
              }
            />
          );
        }}
      />
    </div>
  );
});
