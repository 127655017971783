import { history } from "@/AppRoutes";
import {
  getEventManagementById,
  postEventManagement,
  putEventManagement,
} from "@app/products/event-management/[id]/api";
import { EventManagement } from "@app/products/event-management/[id]/components/child-screens/general/model";
import { EVENT_MANAGEMENT_MANAGE_ROUTE } from "@app/products/event-management/[id]/constant";
import { IEventManagementRegister } from "@app/products/event-management/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class EventManagementStore {
  private _eventManagement?: IEventManagementRegister = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get eventManagement() {
    return this._eventManagement;
  }
  setEventManagement = (
    eventManagement?: IEventManagementRegister | undefined
  ) => {
    runInAction(() => {
      this._eventManagement = eventManagement;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get eventManagementId() {
    return this.eventManagement?.ID;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._eventManagement = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };
  loadEventManagement = async (eventId: number, isNew: boolean = false) => {
    if (isNew) {
      return;
    }
    this.setIsLoading(true);
    const response = await getEventManagementById(eventId);

    let errorResponse = undefined;
    let newEventManagement = undefined;
    if (isSuccessResponse(response)) {
      newEventManagement = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setEventManagement(newEventManagement);

    this.setIsLoading(false);
  };

  createEventManagement = async (data: EventManagement) => {
    this.setIsLoading(true);
    await postEventManagement(data);

    appNotificationStore.pushNotification({
      title: "Event management created successfully",
      type: "success",
      onClose: () => {
        history.push(`${EVENT_MANAGEMENT_MANAGE_ROUTE}/${1}`);
      },
    });

    this.setIsLoading(false);
  };

  updateEventManagement = async (data: EventManagement) => {
    this.setIsLoading(true);
    await putEventManagement(data);
    appNotificationStore.pushNotification({
      title: "Event management updated successfully",
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const eventManagementStoreContext = createContext(new EventManagementStore());
export const useEventManagementStore = () => {
  return useContext(eventManagementStoreContext);
};
