import { getDisplayTextWithDashes } from "@common/utils/common";

export const SystemSettingKeywordBookmark = {
  getBookmarkListViewDisplayName() {
    return "PPR - System Admin";
  },
  getBookmarkListViewDetail() {
    return "PPR - System Admin - Keywords";
  },
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.Keyword_ID
      ? `- ${selectedRow.Keyword_ID}`
      : "";
    return `PPR - Keywords ${dynamicDisplayName}`;
  },
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.Keyword_ID + ", " + selectedRow.Name,
    ]);
  },
};
