import {
  DTO_RebateClaim_Summary,
  VO_Rebate_Transaction,
} from "@app/products/property/assessments/rebates/rebate-claims/[id]/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { NumberCell } from "@components/cc-grid/components/grid-cells/number/_index";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import React from "react";

const nameOfSummary = nameOfFactory<DTO_RebateClaim_Summary>();
const nameOfTransactions = nameOfFactory<VO_Rebate_Transaction>();
export const colClaimSummaryTotals: IColumnFields[] = [
  {
    field: nameOfSummary("Description"),
    title: "Description",
    footerCell: <TextCell />,
  },
  {
    field: nameOfSummary("No_of_Assessments"),
    title: "Number of Assessments",
    format: NUMBER_FORMAT.NUMBER1,
    footerCell: <NumberCell style={formatStyleProminent} />,
  },
  {
    field: nameOfSummary("No_of_Transactions"),
    title: "Number of Transactions",
    format: NUMBER_FORMAT.NUMBER1,
    footerCell: <NumberCell style={formatStyleProminent} />,
  },

  {
    field: nameOfSummary("Rebate_Amount"),
    title: "Total Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfSummary("Original_Rebate_Amount"),
    title: "Rebates Given on Charge Run",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfSummary("Adjustment_Rebate_Amount"),
    title: "Manual Rebate Adjustments",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfSummary("Rates_Amount"),
    title: "Rates Calculated",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
];
export const colRebateTransactionIncludeInClaim: IColumnFields[] = [
  {
    field: nameOfTransactions("Assessment_Number"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfTransactions("Transaction_DateTime"),
    title: "Date/Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfTransactions("Transaction_Code"),
    title: "Transactions Type",
  },
  {
    field: nameOfTransactions("Transaction_Amount"),
    title: "Transaction Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfTransactions("Transaction_Reference"),
    title: "Reference",
  },
  {
    field: nameOfTransactions("Transaction_Description"),
    title: "Description",
  },
  {
    field: nameOfTransactions("Transaction_Notes"),
    title: "Notes",
  },
  {
    field: nameOfTransactions("Journal_Number"),
    title: "Journal Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfTransactions("Session_Number"),
    title: "Session Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfTransactions("Transaction_CreatedBy"),
    title: "User ID",
  },
  {
    field: nameOfTransactions("Transaction_IsProposed"),
    title: "Is Proposed?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfTransactions("Transaction_IsCancelled"),
    title: "Is Cancelled?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfTransactions("Transaction_CreatedOn"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfTransactions("Transaction_Id"),
    title: "Transaction ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

type TTransactionTotalsFieldMapping = {
  [key: string]: string;
  Description: string;
  Adjustment_Rebate_Amount: string;
  No_of_Assessments: string;
  No_of_Transactions: string;
  Original_Rebate_Amount: string;
  Rebate_Amount: string;
  Rates_Amount: string;
};

export const TransactionTotalsFieldMapping: TTransactionTotalsFieldMapping = {
  Description: "Description",
  Adjustment_Rebate_Amount: "Total_Adjustment_Rebate_Amount",
  No_of_Assessments: "Total_No_of_Assessments",
  No_of_Transactions: "Total_No_of_Transactions",
  Original_Rebate_Amount: "Total_Original_Rebate_Amount",
  Rebate_Amount: "Total_Rebate_Amount",
  Rates_Amount: "Total_Rates_Amount",
};
