import { getRelatedAnimalsUrl } from "@app/products/animals/[id]/util";
import { colRelatedAnimalsAccordion } from "@app/products/animals/components/forms/components/form-element/components/related-animals/config";
import { AnimalsRegister } from "@app/products/animals/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IRelatedAnimalsProps {
  id?: number;
  pidNO?: string;
}

const nameOf = nameOfFactory<AnimalsRegister>();
export const RelatedAnimals = ({ id, pidNO }: IRelatedAnimalsProps) => {
  return (
    <CCGrid
      dataUrl={getRelatedAnimalsUrl(id, pidNO)}
      primaryField={nameOf("ID")}
      columnFields={colRelatedAnimalsAccordion}
    />
  );
};
