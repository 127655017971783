import { DTO_Address } from "@app/products/property/assessments/components/form-steps/new-assessment/model";

export const validatorParcelAddress = (value: any): string | undefined => {
  if (value?.length) {
    const isContainPrimary = value?.some((item: DTO_Address) => {
      return item?.IsAddressPrimary;
    });
    if (!isContainPrimary) {
      return "Primary address needs to be set";
    }
  }
  return undefined;
};
