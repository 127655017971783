import { CreateNoticeRunDialog } from "@app/products/property/schemes/notice-runs/components/action-bar/dialogs/create-notice-run/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const CreateNoticeRunButton = observer(() => {
  const [showCreateNoticeRunDialog, setShowCreateNoticeRunDialog] =
    useState(false);
  return (
    <>
      <CCNavButton
        title="Create Notice Run"
        onClick={() => setShowCreateNoticeRunDialog(true)}
      />

      {showCreateNoticeRunDialog && (
        <CreateNoticeRunDialog
          onClose={() => {
            setShowCreateNoticeRunDialog(false);
          }}
          onSubmit={() => {
            setShowCreateNoticeRunDialog(false);
          }}
        />
      )}
    </>
  );
});
