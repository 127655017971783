import {
  CRMS_NOTICES_ROUTE,
  nameOfEventNoticesView,
} from "@app/products/crms/notices/constant";
import { EventNoticesView } from "@app/products/crms/notices/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colByDueStatus: IColumnFields[] = [
  {
    field: nameOfEventNoticesView("ReferenceNumber"),
    title: "Event Number",
    locked: true,
    linkTo: (dataItem: EventNoticesView) =>
      `${CRMS_NOTICES_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOfEventNoticesView("DateServed"),
    title: "Date Served",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfEventNoticesView("Type"),
    title: "Notice Type",
  },
  {
    field: nameOfEventNoticesView("Officer"),
    title: "Officer",
  },
  {
    field: nameOfEventNoticesView("DateDue"),
    title: "Compliance Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfEventNoticesView("DateRevoked"),
    title: "Complied / Revoked",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfEventNoticesView("DueStatus"),
    title: "Status",
  },
];
