import { WastewaterFieldMapping } from "@app/products/waste-water/model";
import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";
export const colWastewaterSystemAdminSystemTypes: IColumnFields[] = [
  {
    field: WastewaterFieldMapping.Manufacturer,
    title: "Manufacturer",
    locked: true,
    linkTo: (dataItem) => {
      return `${WASTE_WATER_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: WastewaterFieldMapping.Name,
    title: "Name",
  },
  {
    field: WastewaterFieldMapping.SystemTypeClassification,
    title: "System Type",
  },
  {
    field: WastewaterFieldMapping.Size,
    title: "Size",
  },
  {
    field: WastewaterFieldMapping.Description,
    title: "Description",
  },
];
