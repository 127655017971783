import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

export const RecordsManagementSettingsForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();

    if (!configData) return <></>;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .CorporateSettings_Fileit_PPRApplicationRootFolder
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .CorporateSettings_Fileit_PSARApplicationRootFolder
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField.CorporateSettings_Fileit_Category
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField.CorporateSettings_Fileit_MaxBufferSize
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .CorporateSettings_Fileit_MaxBufferPoolSize
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .CorporateSettings_Fileit_MaxBufferPoolSize
              ]
            }
            isEditing={isEditing}
          />
        </div>
      </section>
    );
  }
);
