import { eventEmitter } from "@/App";
import { suspendNoticeOfSale } from "@app/products/property/changes-of-ownership/notice-of-sales/components/action-bar/buttons/suspend-notice-of-sale/api";
import { NoticeOfSaleStatus } from "@app/products/property/changes-of-ownership/notice-of-sales/constant";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import {
  ConfirmDialog,
  IConfirmDialogData,
} from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const SuspendNoticeOfSaleButton = observer(() => {
  const { gridSelectedRows, clearSelectedItems } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();

  const [isShowConfirmDialog, setIsShowConfirmDialog] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * Handle suspend notice of sale
   */
  const handleSuspend = async (data: IConfirmDialogData) => {
    const selectedNoticeOfSaleId = gridSelectedRows[0].NoticeofSale_Id;
    setIsLoading(true);
    const response = await suspendNoticeOfSale({
      NoticeOfSale_Id: selectedNoticeOfSaleId,
      Comment: data.Reason,
    });
    setIsLoading(false);
    setIsShowConfirmDialog(false);
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      eventEmitter.emit(CCGridEventType.RefreshOData); //Reload notice of sale grid
      pushNotification({
        title:
          response.data.SuccessMessage ??
          "Notice of sale suspended successfully.",
        type: "success",
      });
      clearSelectedItems();
    } else {
      //Local notification
      pushNotification({
        autoClose: false,
        title: response.data.ErrorMessage ?? `Suspend notice of sale failed.`,
        type: "error",
      });
    }
  };

  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
        privilegesEnum={EPrivilegesWorkflow.Notice_Of_Sale_Suspend}
      >
        <CCNavButton
          title="Suspend notice of sale"
          onClick={() => setIsShowConfirmDialog(true)}
          disabled={
            gridSelectedRows.length !== 1 ||
            gridSelectedRows[0].Status !== NoticeOfSaleStatus.Imported
          }
        />
      </PrivilegeWrapper>
      {isShowConfirmDialog && (
        <ConfirmDialog
          isReasonRequired
          title="Confirm Suspension"
          subMessage="Are you sure you wish to suspend this notice of sale?"
          onClosePopup={() => {
            setIsShowConfirmDialog(false);
          }}
          onAsyncConfirm={handleSuspend}
          isLoadingYes={isLoading}
        />
      )}
    </>
  );
});
