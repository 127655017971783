import { useDebtRecoveryAssessmentStore } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/assessments/store";
import { DTO_Assessment } from "@app/products/property/assessments/debt-recovery/[id]/model";
import { useDebtRecoveryStore } from "@app/products/property/assessments/debt-recovery/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { colDebtRecoveryAssessment } from "./config";

const nameOf = nameOfFactory<DTO_Assessment>();

export const DebtRecoveryAssessments = observer(() => {
  const { isLoading, assessment, loadAssessment, resetStore } =
    useDebtRecoveryAssessmentStore();
  const { debtRecoveryId } = useDebtRecoveryStore();

  useEffect(() => {
    if (debtRecoveryId) loadAssessment(debtRecoveryId);
    return () => {
      resetStore();
    };
  }, [loadAssessment, debtRecoveryId, resetStore]);

  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Assessments associated with debt recovery
            </label>
            <CCGrid
              data={assessment?.DebtRecoveryAssessment ?? []}
              columnFields={colDebtRecoveryAssessment}
              primaryField={nameOf("AssessmentId")}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
