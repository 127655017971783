import {
  loadPropertyAssessmentLevyBalances,
  loadPropertyAssessmentLevyBalanceViews,
} from "@app/products/property/assessments/[id]/components/child-screens/levy-balances/api";
import {
  colByLevyByFinancialArea,
  colByLevyRatingPeriod,
} from "@app/products/property/assessments/[id]/components/child-screens/levy-balances/config";
import {
  AssessmentLevyBalancesViews,
  DTO_Assessment_LevyBalance,
  DTO_Assessment_LevyBalanceView,
  DTO_Assessment_LevyBalanceViews,
} from "@app/products/property/assessments/[id]/components/child-screens/levy-balances/model";
import {
  addDynamicColumnsWithStandardColumns,
  processDynamicColumns,
} from "@app/products/property/assessments/[id]/components/child-screens/levy-balances/util";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class AssessmentLevyBalancesStore {
  private _levyBalances?: DTO_Assessment_LevyBalance = undefined;
  private _columns: IColumnFields[] = colByLevyRatingPeriod;
  private _isLoading: boolean = false;
  private _isDetailLoading: boolean = false;
  private _views?: DTO_Assessment_LevyBalanceViews | undefined = undefined;
  private _view?: DTO_Assessment_LevyBalanceView = undefined;
  private _showFullyPaidLevies: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get columns() {
    return this._columns;
  }
  get showFullyPaidLevies() {
    return this._showFullyPaidLevies;
  }
  get view() {
    return this._view;
  }
  get views() {
    return this._views;
  }
  get levyBalances() {
    return this._levyBalances;
  }
  get isLoading() {
    return this._isLoading;
  }
  get isDetailLoading() {
    return this._isDetailLoading;
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  setColumns = (columns: IColumnFields[]) => {
    runInAction(() => {
      this._columns = columns;
    });
  };
  setLevyBalances = (levyBalances?: DTO_Assessment_LevyBalance) => {
    runInAction(() => {
      this._levyBalances = levyBalances;
    });
  };
  setView = (view?: DTO_Assessment_LevyBalanceView) => {
    runInAction(() => {
      this._view = view;
    });
  };
  setShowFullyPaidLevies = (showFullyPaidLevies: boolean) => {
    runInAction(() => {
      this._showFullyPaidLevies = showFullyPaidLevies;
    });
  };
  setViews = (views?: DTO_Assessment_LevyBalanceViews) => {
    runInAction(() => {
      this._views = views;
    });
  };
  setIsDetailLoading = (isDetailLoading: boolean) => {
    runInAction(() => {
      this._isDetailLoading = isDetailLoading;
    });
  };

  //Action

  loadLevyBalance = async (
    assessmentId: number,
    view: number = 4,
    showFullyPaidLevies: boolean = false
  ) => {
    this.setIsDetailLoading(true);
    let newLevyBalances = await loadPropertyAssessmentLevyBalances(
      assessmentId,
      view,
      showFullyPaidLevies
    );

    if (newLevyBalances) {
      //Add primary field
      newLevyBalances.LevyBalance = newLevyBalances?.LevyBalance
        ? newLevyBalances?.LevyBalance.map((item, index) => {
            if (view === AssessmentLevyBalancesViews.By_FinancialArea) {
              item.Balance = item.ChargeLevies.Totals.Balance;
            }
            return {
              ...item,
              index,
            };
          })
        : [];
      //Add dynamic columns
      const dynamicCols: any = newLevyBalances.DynamicColumns;
      if (Array.isArray(dynamicCols) && dynamicCols.length > 0) {
        const columnsAfterProcessing = processDynamicColumns(dynamicCols);
        const standardColumn = cloneDeep(colByLevyRatingPeriod);
        const levyFinancialArea = cloneDeep(colByLevyByFinancialArea);
        let newColumns;
        if (view === AssessmentLevyBalancesViews.By_FinancialArea) {
          newColumns = addDynamicColumnsWithStandardColumns(
            columnsAfterProcessing,
            levyFinancialArea
          );
        } else {
          newColumns = addDynamicColumnsWithStandardColumns(
            columnsAfterProcessing,
            standardColumn
          );
        }
        const levyBalanceTotals: any = newLevyBalances.Totals;
        if (levyBalanceTotals) {
          newColumns.forEach((item: IColumnFields) => {
            if (Object.keys(levyBalanceTotals).includes(item.field)) {
              item.calculateFooterCellValue = () =>
                levyBalanceTotals[item.field];
            }
          });
          this.setColumns(newColumns);
        }
      }
    }

    this.setIsDetailLoading(false);
    this.setLevyBalances(newLevyBalances);
  };
  loadViews = async () => {
    this.setIsLoading(true);

    const newViews = await loadPropertyAssessmentLevyBalanceViews();
    this.setViews(newViews);
    this.setView(newViews && newViews.Views ? newViews.Views[0] : undefined);

    this.setIsLoading(false);
  };
}

const assessmentLevyBalancesStoreContext = createContext(
  new AssessmentLevyBalancesStore()
);
export const useAssessmentLevyBalancesStore = () => {
  return useContext(assessmentLevyBalancesStoreContext);
};
