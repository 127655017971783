import { history } from "@/AppRoutes";
import { KeyValuePair } from "@app/core/documents/model";
import { PCBACategories } from "@app/products/hm/model";
import { postSavePremisesForm } from "@app/products/hm/premises/[id]/api";
import {
  HM_PREMISES_GET_SLIDER,
  PREMISES_MANAGE_ROUTE,
} from "@app/products/hm/premises/[id]/constant";
import { Premises } from "@app/products/hm/premises/[id]/model";
import { changePremisesGeneralForm } from "@app/products/hm/premises/[id]/util";
import { ErrorMessageFromApi } from "@app/products/local-laws/[id]/components/buttons/save/constant";
import { RECORDTYPE } from "@common/constants/recordtype";
import { PickDebtorNumberDialog } from "@common/dialog/debtor-number/_index";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { RefNumberUsage } from "@common/input-pickers/debtor-number/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface ISavePremisesButtonProps {
  isDisabled?: boolean;
}

export const SavePremisesButton = observer(
  ({ isDisabled }: ISavePremisesButtonProps) => {
    //#region STORE ========/
    const {
      submitFormGetData,
      isLoadingForm,
      setIsLoadingForm,
      setMiddlewareSubmitFormOptions,
    } = useFlexibleFormStore();
    //#endregion STORE =====/

    //#region HOOK ========/
    const { pushNotificationPortal } = useNotificationPortalStore();
    const isNew = useIsNew();
    const { cancelToken } = useCancelRequest();
    const [isShowPickDebtorDialog, setIsShowPickDebtorDialog] = useState(false);
    const [contactId, setContactId] = useState<number>();
    //#endregion HOOK =====/

    //#region METHOD ========/
    const savePremisesButtonSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const premises: Premises = initialData;

            //Save PCAndBACategories
            if (premises._PCAndBACategories) {
              const pcbaCategories = premises._PCAndBACategories.map(
                (pcbaCategory: KeyValuePair<string, string>) =>
                  PCBACategories[
                    pcbaCategory.Key as keyof typeof PCBACategories
                  ]
              );

              premises.PCAndBACategories = pcbaCategories?.reduce(
                (pcbaCategoriesEnum, currentItem) => {
                  return pcbaCategoriesEnum | currentItem;
                },
                PCBACategories.Unspecified
              );
            }

            return postSavePremisesForm(premises, cancelToken());
          },
          handleSuccess: async ({ dataFromApi }) => {
            if (isNew) {
              if (dataFromApi?.data?.ID) {
                const urlRedirect =
                  PREMISES_MANAGE_ROUTE + "/" + dataFromApi.data.ID;
                pushNotificationPortal({
                  title: "Premises saved successfully.",
                  type: "success",
                  route: urlRedirect,
                  callBack() {
                    history.replace(urlRedirect);
                  },
                });
              }
            } else {
              await fetchApiByAlias(HM_PREMISES_GET_SLIDER);
              pushNotificationPortal({
                title: "Premises saved successfully.",
                type: "success",
              });
            }
          },
          handleError: ({ errorFromApi, initialData }) => {
            if (isNew && errorFromApi.error?.[0] === ErrorMessageFromApi) {
              const premises = initialData as Premises;
              setContactId(premises?.Proprietor?.Contact?.Contact_ID);
              setIsShowPickDebtorDialog(true);
            } else {
              pushNotificationPortal({
                title: "Save premises failed.",
                type: "error",
                description: errorFromApi.error,
                autoClose: false,
              });
            }
            changePremisesGeneralForm({
              CCFormOptions: { forceModified: true },
            });
          },
        },
      ],
    });

    //#endregion METHOD =====/
    const handleClickButton = async (_event: any, debtorNumber?: string) => {
      if (debtorNumber) {
        setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      }

      const dataForm = await submitFormGetData("GeneralForm");
      let premises = dataForm?.Premises as Premises;
      if (isNil(premises)) return;

      if (debtorNumber) {
        premises.DebtorNumber = debtorNumber;
      }
      if (isNil(premises.Date_Lodged)) {
        premises.Date_Lodged = new Date();
      }
      setIsLoadingForm(true);
      await savePremisesButtonSlider.fetchApi({
        initialData: premises,
      });
      setIsLoadingForm(false);
    };

    const handlePickDebtor = (value: RefNumberUsage[]) => {
      setIsShowPickDebtorDialog(false);
      handleClickButton(null, value?.[0]?.Sys_DebtorNumber);
    };

    return (
      <>
        <CCNavButton
          title="Save"
          onClick={handleClickButton}
          disabled={isLoadingForm || isDisabled}
        />
        {isShowPickDebtorDialog && (
          <PickDebtorNumberDialog
            onClose={() => {
              setContactId(undefined);
              setIsShowPickDebtorDialog(false);
            }}
            onSubmit={(value: RefNumberUsage[]) => handlePickDebtor(value)}
            contactId={contactId}
            recordId={0}
            recordType={RECORDTYPE.HealthManager_Premises}
          />
        )}
      </>
    );
  }
);
