export const AnimalTypeData = [
  {
    label: "Cat",
    value: true,
  },
  {
    label: "Dog",
    value: false,
  },
];
