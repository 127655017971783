import { DTO_GL_Posting } from "@app/products/property/journals/session-list/[id]/components/child-screens/general-ledger-postings/model";
import { useGeneralLedgerPostingsStore } from "@app/products/property/journals/session-list/[id]/components/child-screens/general-ledger-postings/store";
import { useSessionListStore } from "@app/products/property/journals/session-list/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import "./_index.scss";
const nameOfGLPosting = nameOfFactory<DTO_GL_Posting>();
export const GeneralLedgerPostings = observer(() => {
  const { sessionNumber } = useSessionListStore();
  const {
    isLoading,
    responseLoadError,
    reLoadApiConfig,
    loadGeneralLedgerPostings,
    columnConfigLedger,
    ledgerPostingsData,
  } = useGeneralLedgerPostingsStore();

  useEffect(() => {
    loadGeneralLedgerPostings(sessionNumber);
  }, [loadGeneralLedgerPostings, sessionNumber]);

  //show Loading
  if (isLoading) return <Loading isLoading />;

  //Show load failed and reload
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          reLoadApiConfig(sessionNumber);
        }}
      />
    );

  return (
    <div className="cc-property-journal-panel-items">
      <CCGrid
        data={ledgerPostingsData ?? []}
        columnFields={columnConfigLedger}
        primaryField={nameOfGLPosting("Id")}
      />
    </div>
  );
});
