import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { ICON_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSCentresCentres: IColumnFields[] = [
  {
    field: CRSFieldMapping.CentreName,
    title: "Centre Name",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  { field: CRSFieldMapping.CentreCode, title: "Centre Code" },
  {
    field: CRSFieldMapping.CBC,
    title: "CBC",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.HBC,
    title: "HBC",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.Threeyo,
    title: "3yo",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.ThreeYo_Sessions,
    title: "3yo Sessions",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.Fouryo,
    title: "4yo",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.FourYo_Sessions,
    title: "4yo Sessions",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.CouncilFees,
    title: "Council Fees",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  { field: CRSFieldMapping.CentrePhone, title: "Centre Phone" },
  { field: CRSFieldMapping.Address, title: "Centre Address" },
];
