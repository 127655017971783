import { IAssessmentQuestion } from "@app/products/ams/model/store";
import { ItemTypes } from "@app/products/ams/util/itemTypes";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { useDrag } from "react-dnd";

export interface IQuestionDragItem {
  type: string;
  parentModelName: string;
  parentGroupName: string;
  questionNo: number;
}

interface IProps {
  parentModelName: string;
  parentGroupName: string;
  question: IAssessmentQuestion;
}

const QuestionLayoutElement: React.FC<IProps> = ({
  parentModelName,
  parentGroupName,
  question,
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.EntityFieldLayoutElement,
      parentModelName: parentModelName,
      parentGroupName: parentGroupName,
      questionNo: question.questionNo,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  //const icon = getIcon(fdmr);
  return useObserver(() => (
    <div
      className="form-row col-auto vw-100 border p-1"
      ref={drag}
      style={{ opacity: isDragging ? "0.5" : "1" }}
    >
      <div className="col-9">
        <label className="label">{question.questionDetail}</label>
      </div>
    </div>
  ));
};

export default QuestionLayoutElement;
