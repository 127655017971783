import { processDynamicColumns } from "@app/products/property/util";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import React, { useEffect } from "react";
import { ChargeRunRebateSummaryDetail } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebate-summary/component/_index";
import { colRebateSummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebate-summary/config";
import { useChargeRunRebateSummaryStore } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebate-summary/store";
import { useChargeRunsStore } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { VO_Charge_Run_Rebate_Summary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebate-summary/model";
import { observer } from "mobx-react-lite";

const nameOfRebateSummary = nameOfFactory<VO_Charge_Run_Rebate_Summary>();
export const ChargeRunRebateSummary = observer(() => {
  const { chargeRunsId } = useChargeRunsStore();
  const {
    responseLoadError,
    isLoading,
    viewConfiguration,
    loadChargeRunRebateSummaryConfig,
    resetStore,
  } = useChargeRunRebateSummaryStore();

  useEffect(() => {
    loadChargeRunRebateSummaryConfig();
    return () => {
      resetStore();
    };
  }, [loadChargeRunRebateSummaryConfig, resetStore]);

  if (isLoading) return <Loading isLoading={isLoading} />;

  return responseLoadError ? (
    <CCLoadFailed
      responseError={responseLoadError}
      onReload={() => {
        loadChargeRunRebateSummaryConfig();
      }}
    />
  ) : (
    <div className="cc-charge-run-rebate-summary">
      <CCGrid
        className="cc-charge-run-rebate-summary-grid"
        dataUrl={`/odata/property/internal/chargerunrebateregister/${chargeRunsId}?$count=true&`}
        columnFields={processDynamicColumns(
          colRebateSummary,
          viewConfiguration
        )}
        primaryField={nameOfRebateSummary("Rebate_Type_Id")}
        detail={ChargeRunRebateSummaryDetail}
      />
    </div>
  );
});
