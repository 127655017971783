import { DTO_AssociatedItem_Compliance } from "@app/products/property/components/associations/components/compliance/model";
import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_Compliance>();

export const colAssociationsCompliance: IColumnFields[] = [
  {
    field: nameOf("CreatedOn"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  { field: nameOf("CreatedBy"), title: "Created By" },
  { field: nameOf("Officer"), title: "Officer" },
  {
    field: nameOf("ShowAlertOnAssessment"),
    title: "Show Alert on Assessment",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: nameOf("AlertMessage"), title: "Alert Message" },
  { field: nameOf("SolicitorMessage"), title: "Solicitor Message" },
  {
    field: nameOf("AsAt"),
    title: "As At",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("UpToDueDateTime"),
    title: "Up to Due Date Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "AssessmentBalance",
    title: "Assessment Balance",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "Total",
    title: "Total",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "InitialDebtIncludingCosts",
    title: "Initial Debt Including Costs",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "CostsIncurred",
    title: "Costs Incurred",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "InterestBalance",
    title: "Interest Balance",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "Balance",
    title: "Balance",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "InitialDebt",
    title: "Initial Debt",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "InitialInterestBalance",
    title: "Initial Interest Balance",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },

  {
    field: "InitialBalance",
    title: "Initial Balance",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("DateTo"),
    title: "Date To",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("DateFrom"),
    title: "Date From",
    format: DATETIME_FORMAT.DATETIME,
  },
  { field: nameOf("LastAction"), title: "Last Action" },
  { field: nameOf("Status"), title: "Status" },
  { field: nameOf("Type"), title: "Type" },
  { field: nameOf("Assessments"), title: "Assessments" },
  { field: nameOf("NameAndAddress"), title: "Name and Address" },
  { field: nameOf("Association"), title: "Association" },
  { field: nameOf("Assessments"), title: "Assessments" },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  { field: nameOf("ChangedBy"), title: "Changed By" },
  {
    field: nameOf("ChangedOn"),
    title: "Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
];
