import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { gridDefaultProps } from "@components/cc-grid/config";
import { CCGrid, ICCGridProps } from "@components/cc-grid/_index";
import { PRODUCT_LIST_VIEW_SELECTION_SPEED } from "@components/cc-product-list-view/config";
import {
  AggregateDescriptor,
  GroupDescriptor
} from "@progress/kendo-data-query";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useDebounce } from "react-use";

export const GridDetailAssessment = observer((props: ICCGridProps) => {
  const {
    isShowSlideBar,
    gridData,
    gridColumns,
    gridDataState,
    gridSelectedRows,
    gridExportFormat,
    setGridExportFormat,
    setGridData,
    setGridColumns,
    setGridDataState,
    setGridSelectedRows,
    setGridSelectedIds,
    resetStore,
    setDisableRowField,
    setCanSelectField,
    setGridTotalSelected,
  } = useDetailAssessmentListViewStore();

  const {
    data,
    state,
    columnFields,
    selectableMode = "multiple",
    itemPerPage,
    dataUrl,
    selectedRows,
    disableRowField,
    canSelectField,
    primaryField,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedRowsState, setSelectedRowsState] = useState<any[]>(
    selectedRows ?? []
  );
  const [totalSelectedRows, setTotalSelectedRows] = useState<number>(
    selectedRows?.length ?? 0
  );

  useDebounce(
    () => {
      setGridSelectedRows(selectedRowsState);
      setGridSelectedIds(selectedRowsState.map((item) => item[primaryField]));
    },
    PRODUCT_LIST_VIEW_SELECTION_SPEED,
    [selectedRowsState]
  );
  useDebounce(
    () => {
      setGridTotalSelected(totalSelectedRows);
    },
    PRODUCT_LIST_VIEW_SELECTION_SPEED,
    [totalSelectedRows]
  );

  useEffect(() => {
    setCanSelectField(canSelectField);
  }, [canSelectField, setCanSelectField]);

  useEffect(() => {
    setDisableRowField(disableRowField);
  }, [disableRowField, setDisableRowField]);

  useEffect(() => {
    if (!dataUrl) {
      setGridData(data);
    }
    setIsLoading(false);
  }, [data, dataUrl, setGridData]);

  useEffect(() => {
    if (selectedRows) {
      setGridSelectedRows(selectedRows);
      setGridSelectedIds(selectedRows.map((item) => item[primaryField]));
    }
  }, [primaryField, selectedRows, setGridSelectedIds, setGridSelectedRows]);

  useEffect(() => {
    if (state) {
      const { sort, filter, group, take, skip } = state;
      let newDataState = {
        sort: sort !== undefined ? sort : gridDefaultProps.state?.sort,
        filter: filter !== undefined ? filter : gridDefaultProps.state?.filter,
        group: group !== undefined ? group : gridDefaultProps.state?.group,
        skip: skip !== undefined ? skip : gridDefaultProps.state?.skip,
        take:
          take !== undefined
            ? take
            : itemPerPage || gridDefaultProps.state?.take,
      };

      let groups = newDataState.group;
      if (
        groups &&
        groups?.some((group: GroupDescriptor) => !group?.aggregates)
      ) {
        const aggregates: AggregateDescriptor[] = [
          { field: primaryField, aggregate: "count" },
        ];
        groups.forEach(
          (group: GroupDescriptor) => (group.aggregates = aggregates)
        );
        newDataState.group = groups;
      }
      setGridDataState(newDataState);
    }
  }, [state, itemPerPage, primaryField, setGridDataState]);

  useEffect(() => {
    setGridColumns(columnFields);
  }, [columnFields, setGridColumns]);

  const handleOnDataChange = useCallback(
    (data) => {
      setGridData(data);
    },
    [setGridData]
  );
  const handleOnDataStateChange = useCallback(
    (state) => {
      setGridDataState(state);
    },
    [setGridDataState]
  );
  const handleOnSelectionChange = useCallback(
    (dataItem: any[]) => {
      if (!isShowSlideBar) {
        setGridSelectedRows(dataItem);
        setGridSelectedIds(dataItem.map((item) => item[primaryField]));
      } else {
        //Use Debounce
        setSelectedRowsState(dataItem);
      }
    },
    [isShowSlideBar, setGridSelectedRows, setGridSelectedIds, primaryField]
  );

  const handleOnChangeTotalSelect = useCallback(
    (totalSelectedRows: number) => {
      if (!isShowSlideBar) {
        setGridTotalSelected(totalSelectedRows);
      } else {
        //Use Debounce
        setTotalSelectedRows(totalSelectedRows);
      }
    },
    [isShowSlideBar, setGridTotalSelected]
  );

  useEffect(() => {
    return () => {
      resetStore();
    };
  }, [resetStore]);

  useEffect(() => {
    setGridColumns(columnFields);
  }, [columnFields, setGridColumns]);

  return (
    <CCGrid
      isLoading={isLoading}
      {...props}
      exportFormat={gridExportFormat}
      onExported={setGridExportFormat}
      selectableMode={selectableMode}
      isAutoHiddenPager={false}
      style={{ height: "100%" }}
      pagerType={"input"}
      className="detail-assessment-view-grid"
      data={gridData}
      state={gridDataState}
      columnFields={gridColumns}
      selectedRows={gridSelectedRows}
      onDataChange={handleOnDataChange}
      onDataStateChange={handleOnDataStateChange}
      onSelectionChange={handleOnSelectionChange}
      onTotalSelectedRowChange={handleOnChangeTotalSelect}
    />
  );
});
