import { loadSearchAccount } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/accounts/api";
import {
  colAccounts,
  searchTypeData,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/accounts/config";
import { dropdownRegisterData } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/accounts/mock";
import {
  IAccounts,
  SearchType,
  textFieldMapping,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/accounts/model";
import { validatorAccounts } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/accounts/util";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  DropDownList,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { differenceBy, isEqual } from "lodash";
import React, { useMemo, useRef } from "react";

export const ACCOUNTS_FORM_STEP = "Accounts";

const nameOfAccounts = nameOfFactory<IAccounts>();

export const AccountsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={validatorAccounts}
    />
  );
};

export const FormStepElement = ({
  formRenderProps,
  nameOf,
}: IFormStepElement) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const accountList = getFieldValue("Accounts");
  const accountsSelected = getFieldValue("AccountsSelected");
  const searchTypeValue = getFieldValue("SearchType");

  const handleSearchAccount = (event: ComboBoxFilterChangeEvent) => {
    onChange(nameOf("Account"), { value: null });
    let numLetters = 4;
    if (!searchTypeValue) return;
    if (searchTypeValue.Value === SearchType.AccountId) numLetters = 1;
    if (event.filter.value.length >= numLetters) {
      onChange(nameOf("_option.Account.Loading"), { value: true });
      const registerValue = getFieldValue("Register");
      loadSearchAccount(event.filter, registerValue).then((data) => {
        onChange(nameOf("_option.Account.Data"), { value: data });
        onChange(nameOf("_option.Account.Loading"), { value: false });
      });
    } else {
      onChange(nameOf("_option.Account.Data"), { value: [] });
    }
  };

  const handleSearchAccountResult = (event: ComboBoxChangeEvent) => {
    if (!event.value) return;
    onChange(nameOf("Account"), { value: event.value });

    if (accountList) {
      if (checkExistedRegister(event.value.AccountId, accountList)) {
        notificationRef.current?.pushNotification({
          title: "Register account has already existed",
          type: "warning",
        });
        return;
      }
      let newGirdData = [...accountList];
      newGirdData.unshift({
        Register: event.value?.Register,
        AccountReference: event.value?.AccountReference,
        AccountId: event.value?.AccountId,
        AccountDescription: event.value?.AccountDescription,
        PropertyAddress: event.value?.PropertyAddress,
      });
      onChange(nameOf("Accounts"), { value: newGirdData });
    }
  };

  const checkExistedRegister = (AccountId: number, ListAccounts: any[]) => {
    return ListAccounts.some((item) =>
      isEqual(parseInt(item?.AccountId), AccountId)
    );
  };

  const disabledRemove = useMemo(() => {
    return !accountsSelected || accountsSelected.length < 1;
  }, [accountsSelected]);

  const handleGridSelectionChange = (dataItem: any) => {
    let newSelected = dataItem ? dataItem : undefined;

    onChange(nameOf("AccountsSelected"), {
      value: newSelected,
    });
  };

  const handleRemoveAccounts = () => {
    if (!accountList || !accountsSelected) return;
    const newAccountList = differenceBy(
      accountList,
      accountsSelected,
      "AccountId"
    );

    if (newAccountList?.[0]) {
      handleGridSelectionChange([newAccountList[0]]);
    } else {
      handleGridSelectionChange([]);
    }

    onChange(nameOf("Accounts"), { value: newAccountList });
  };

  return (
    <>
      <CCLocalNotification ref={notificationRef} />
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Register</label>
            <Field
              name={nameOf("Register")}
              data={dropdownRegisterData || []}
              textField="Value"
              dataItemKey="Key"
              component={CCSearchComboBox}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Search by</label>
            <Field
              name={nameOf("SearchType")}
              data={searchTypeData}
              component={DropDownList}
              ataItemKey="Key"
              textField="Value"
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Search account</label>
            <Field
              name={nameOf("Account")}
              filterable
              suggest
              textField={textFieldMapping[searchTypeValue?.Value]}
              data={getFieldValue("_option.Account.Data") ?? []}
              loading={getFieldValue("_option.Account.Loading") ?? false}
              placeholder={`Type ${searchTypeValue?.Value}`}
              component={CCComboBox}
              onFilterChange={handleSearchAccount}
              onChange={handleSearchAccountResult}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Register accounts affected</label>
            <CCGrid
              data={accountList}
              selectedRows={accountsSelected}
              primaryField={nameOfAccounts("AccountId")}
              selectableMode="multiple"
              columnFields={colAccounts}
              onSelectionChange={handleGridSelectionChange}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-minus"
                    title="Remove"
                    disabled={disabledRemove}
                    onClick={handleRemoveAccounts}
                  />
                </div>
              }
            />
          </div>
        </div>
      </section>
    </>
  );
};
