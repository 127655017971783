import { IAttachmentFile } from "@app/core/documents/components/documents-input/model";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { isEmpty, isNil } from "lodash";

export const getProductTypeByMailMergeDataset = (
  type: MAILMERGEDATASET | null | undefined
) => {
  if (!type) return null;

  switch (type) {
    case MAILMERGEDATASET.HealthManager_Premises:
    case MAILMERGEDATASET.HealthManager_Action:
    case MAILMERGEDATASET.HealthManager_Prosecution:
    case MAILMERGEDATASET.HealthManager_Notice:
    case MAILMERGEDATASET.HealthManager_Inspection:
    case MAILMERGEDATASET.HealthManager_Recall:
    case MAILMERGEDATASET.HealthManager_Sample:
    case MAILMERGEDATASET.HealthManager_Fees:
    case MAILMERGEDATASET.HealthManager_ActivityReport:
      return PRODUCT_TYPE_NUMBER.HealthManager;
    case MAILMERGEDATASET.DWCMS_Address_History:
    case MAILMERGEDATASET.DWCMS_Application:
    case MAILMERGEDATASET.DWCMS_Audit:
    case MAILMERGEDATASET.DWCMS_Condition:
    case MAILMERGEDATASET.DWCMS_CriminalHistory:
    case MAILMERGEDATASET.DWCMS_DisciplinaryAction:
    case MAILMERGEDATASET.DWCMS_Division:
    case MAILMERGEDATASET.DWCMS_Employment:
    case MAILMERGEDATASET.DWCMS_Enquiry:
    case MAILMERGEDATASET.DWCMS_Impairment:
    case MAILMERGEDATASET.DWCMS_Insurance:
    case MAILMERGEDATASET.DWCMS_OtherNamesPracticed:
    case MAILMERGEDATASET.DWCMS_PrimaryPlaceOfPractice:
    case MAILMERGEDATASET.DWCMS_ProfessionalCompetence:
    case MAILMERGEDATASET.DWCMS_ProfessionalRegistration:
    case MAILMERGEDATASET.DWCMS_Registration:
    case MAILMERGEDATASET.DWCMS_RegistrationType:
    case MAILMERGEDATASET.DWCMS_ServiceProvider:
    case MAILMERGEDATASET.DWCMS_Worker:
    case MAILMERGEDATASET.DWCMS_Worker_Request:
    case MAILMERGEDATASET.DWCMS_Worker_Undertaking:
      return PRODUCT_TYPE_NUMBER.DWCMS;
    case MAILMERGEDATASET.WasteWater_System:
    case MAILMERGEDATASET.WasteWater_Fees:
    case MAILMERGEDATASET.WasteWater_Inspection:
    case MAILMERGEDATASET.WasteWater_Notice:
    case MAILMERGEDATASET.WasteWater_Prosecution:
    case MAILMERGEDATASET.WasteWater_Sample:
    case MAILMERGEDATASET.WasteWater_Action:
    case MAILMERGEDATASET.WasteWater_System_FurtherInformationRequests:
      return PRODUCT_TYPE_NUMBER.WasteWater;
    case MAILMERGEDATASET.CRS_Registration:
    case MAILMERGEDATASET.CRS_CBCRegistration:
    case MAILMERGEDATASET.CRS_HBCRegistration:
    case MAILMERGEDATASET.CRS_3yoRegistration:
    case MAILMERGEDATASET.CRS_4yoRegistration:
    case MAILMERGEDATASET.CRS_AcknowledgementService:
    case MAILMERGEDATASET.CRS_4yoRegistration_Invoice:
    case MAILMERGEDATASET.CRS_Registration_LateCollection:
    case MAILMERGEDATASET.CRS_CBCRegistrationOffer:
    case MAILMERGEDATASET.CRS_3yoRegistrationOffer:
    case MAILMERGEDATASET.CRS_4yoRegistrationOffer:
      return PRODUCT_TYPE_NUMBER.CRS;
    case MAILMERGEDATASET.DISRES_Permit:
    case MAILMERGEDATASET.DISRES_PropertyException:
    case MAILMERGEDATASET.DISRES_PropertyRestriction:
    case MAILMERGEDATASET.DISRES_ResidentParkingAreaDefinition:
    case MAILMERGEDATASET.DISRES_StaffParkingAreaDefinition:
    case MAILMERGEDATASET.DISRES_PermitLabel:
    case MAILMERGEDATASET.DISRES_Fees:
      return PRODUCT_TYPE_NUMBER.DISRES;
    case MAILMERGEDATASET.CUSTOMERSERVICE_Event:
    case MAILMERGEDATASET.CUSTOMERSERVICE_Event_Actions:
    case MAILMERGEDATASET.CUSTOMERSERVICE_BulletinBoard_Actions:
    case MAILMERGEDATASET.CUSTOMERSERVICE_KBPage:
    case MAILMERGEDATASET.CUSTOMERSERVICE_PhoneMessage:
    case MAILMERGEDATASET.CUSTOMERSERVICE_Event_Inspections:
      return PRODUCT_TYPE_NUMBER.CustomerService;
    case MAILMERGEDATASET.TOWNPLANNING_Application:
    case MAILMERGEDATASET.TOWNPLANNING_BuildingApplication:
    case MAILMERGEDATASET.TOWNPLANNING_Application_Actions:
    case MAILMERGEDATASET.TOWNPLANNING_Application_Advertising:
    case MAILMERGEDATASET.TOWNPLANNING_Application_Appeals:
    case MAILMERGEDATASET.TOWNPLANNING_Application_Complaints:
    case MAILMERGEDATASET.TOWNPLANNING_Application_Contacts:
    case MAILMERGEDATASET.TOWNPLANNING_Application_Fees:
    case MAILMERGEDATASET.TOWNPLANNING_Application_FurtherInformationRequests:
    case MAILMERGEDATASET.TOWNPLANNING_Application_Inspections:
    case MAILMERGEDATASET.TOWNPLANNING_Application_Notices:
    case MAILMERGEDATASET.TOWNPLANNING_Application_Prosecution:
    case MAILMERGEDATASET.TOWNPLANNING_PSA_Applications:
    case MAILMERGEDATASET.TOWNPLANNING_PPR_Applications:
    case MAILMERGEDATASET.TOWNPLANNING_PPR_Actions:
    case MAILMERGEDATASET.TOWNPLANNING_PPR_Appeals:
    case MAILMERGEDATASET.TOWNPLANNING_PPR_FurtherInformationRequests:
    case MAILMERGEDATASET.TOWNPLANNING_PNF_Applications:
    case MAILMERGEDATASET.TOWNPLANNING_PNF_DueDiligence:
    case MAILMERGEDATASET.TOWNPLANNING_PNF_Operation:
    case MAILMERGEDATASET.TOWNPLANNING_PNF_FieldObservation:
    case MAILMERGEDATASET.TOWNPLANNING_PNF_Actions:
      return PRODUCT_TYPE_NUMBER.TownPlanning;
    case MAILMERGEDATASET.BUILDING_Application_FurtherInformationRequests:
      return PRODUCT_TYPE_NUMBER.Building;
    case MAILMERGEDATASET.LLP_Permit:
    case MAILMERGEDATASET.LLP_Application_Fees:
    case MAILMERGEDATASET.LLP_Application_Inspections:
    case MAILMERGEDATASET.LLP_Application_Notices:
    case MAILMERGEDATASET.LLP_Application_Prosecution:
    case MAILMERGEDATASET.LLP_Application_Action:
      return PRODUCT_TYPE_NUMBER.LLPermits;
    case MAILMERGEDATASET.Animals_Registration:
    case MAILMERGEDATASET.Animals_Fees:
    case MAILMERGEDATASET.Animals_Inspections:
    case MAILMERGEDATASET.Animals_Notices:
    case MAILMERGEDATASET.Animals_Prosecution:
    case MAILMERGEDATASET.Animals_Actions:
    case MAILMERGEDATASET.Animals_PoundRegister:
    case MAILMERGEDATASET.Animals_InspectionPlannerInspections:
      return PRODUCT_TYPE_NUMBER.Animals;
    case MAILMERGEDATASET.AMS_Appointment:
      return PRODUCT_TYPE_NUMBER.AppointmentManagementSystem;
    case MAILMERGEDATASET.CSM_Licence:
    case MAILMERGEDATASET.CSM_Fees:
    case MAILMERGEDATASET.CSM_Inspections:
    case MAILMERGEDATASET.CSM_Notice:
    case MAILMERGEDATASET.CSM_Prosecution:
    case MAILMERGEDATASET.CSM_Action:
    case MAILMERGEDATASET.CSM_Samples:
      return PRODUCT_TYPE_NUMBER.CSM;
    case MAILMERGEDATASET.TrainingManager_CourseOutline:
    case MAILMERGEDATASET.TrainingManager_CourseSchedule:
    case MAILMERGEDATASET.TrainingManager_TrainingRequest:
      return PRODUCT_TYPE_NUMBER.TrainingManager;
    case MAILMERGEDATASET.Register_StreetProtection:
      return PRODUCT_TYPE_NUMBER.StreetProtection;
    case MAILMERGEDATASET.INFRINGEMENTS_TICKET:
    case MAILMERGEDATASET.INFRINGEMENTS_Ticket_Appeals:
      return PRODUCT_TYPE_NUMBER.Infringements;
    case MAILMERGEDATASET.CEM_RegisterApplication:
    case MAILMERGEDATASET.CEM_Entry:
      return PRODUCT_TYPE_NUMBER.Cemeteries;
    case MAILMERGEDATASET.LANDMANAGEMENT_LandHoldingApplications:
    case MAILMERGEDATASET.LANDMANAGEMENT_Enquiry:
    case MAILMERGEDATASET.LANDMANAGEMENT_Notification:
    case MAILMERGEDATASET.LANDMANAGEMENT_Certification:
    case MAILMERGEDATASET.LANDMANAGEMENT_FurtherInformationRequests:
      return PRODUCT_TYPE_NUMBER.LandManagement;
    default:
      return null;
  }
};

export const getProductTypeName = (
  type: PRODUCT_TYPE_NUMBER | null | undefined,
  isPNFEnabled: boolean
) => {
  if (!type) return "";

  switch (type) {
    case PRODUCT_TYPE_NUMBER.Core:
      return "Core";
    case PRODUCT_TYPE_NUMBER.Action:
      return "Actions";
    case PRODUCT_TYPE_NUMBER.Contact:
      return "Contacts";
    case PRODUCT_TYPE_NUMBER.HealthManager:
      return "Health Manager";
    case PRODUCT_TYPE_NUMBER.CRS:
      return "CRS";
    case PRODUCT_TYPE_NUMBER.CRSCentreAccess:
      return "CRS Centre Access";
    case PRODUCT_TYPE_NUMBER.WasteWater:
      return "Wastewater";
    case PRODUCT_TYPE_NUMBER.WasteWaterSPI:
      return "WWMS";
    case PRODUCT_TYPE_NUMBER.CSM:
      return "CSL";
    case PRODUCT_TYPE_NUMBER.NSPR:
      return "NSPR";
    case PRODUCT_TYPE_NUMBER.Reports:
      return "Reports";
    case PRODUCT_TYPE_NUMBER.CustomerService:
      return "Customer Service";
    case PRODUCT_TYPE_NUMBER.HomePage:
      return "Home Page";
    case PRODUCT_TYPE_NUMBER.DISRES:
      return "Parking Permits";
    case PRODUCT_TYPE_NUMBER.TownPlanning:
      return isPNFEnabled ? "Private Native Forestry" : "Town Planning";
    case PRODUCT_TYPE_NUMBER.DevApps:
      return "Dev Apps";
    case PRODUCT_TYPE_NUMBER.Building:
      return "Building";
    case PRODUCT_TYPE_NUMBER.LLPermits:
      return "Local Laws";
    case PRODUCT_TYPE_NUMBER.Animals:
      return "Animals";
    case PRODUCT_TYPE_NUMBER.StreetProtection:
      return "Street Protection";
    case PRODUCT_TYPE_NUMBER.MaternalChildHealth:
      return "MCH"; // For Maternal & Child Health project
    case PRODUCT_TYPE_NUMBER.TrainingManager:
      return "Training Manager";
    case PRODUCT_TYPE_NUMBER.CC:
      return "FEATURE_Cold Chain"; //return "Cold Chain";
    case PRODUCT_TYPE_NUMBER.ITSupport:
      return "FEATURE_IT Support"; //return "IT Support";
    case PRODUCT_TYPE_NUMBER.CRMS_HardRubbish:
      return "FEATURE_Hard Rubbish"; //return "Hard Rubbish";
    case PRODUCT_TYPE_NUMBER.CRMS_PhoneMessages:
      return "FEATURE_Phone Messages"; //return "Phone Messages";
    case PRODUCT_TYPE_NUMBER.CRMS_Payroll:
      return "EXTERNAL_Payroll"; //return "Payroll";
    case PRODUCT_TYPE_NUMBER.CRSPortal:
      return "CRS Portal";
    //Product: AMS
    case PRODUCT_TYPE_NUMBER.AppointmentManagementSystem:
      return "Appointment Management";
    //Product: EMS
    case PRODUCT_TYPE_NUMBER.EventManagementSystem:
      return "Event Management";
    case PRODUCT_TYPE_NUMBER.EHealth:
      return "Health 24x7";
    case PRODUCT_TYPE_NUMBER.Project:
      return "Project";
    case PRODUCT_TYPE_NUMBER.Radiation:
      return "Radiation Safety";
    case PRODUCT_TYPE_NUMBER.StandardsAndRegulation:
      return "Human Services Regulator";
    case PRODUCT_TYPE_NUMBER.SSMS:
      return "Speed Sign Management System";
    case PRODUCT_TYPE_NUMBER.DWCMS:
      return "Disability Worker Case Management System";
    case PRODUCT_TYPE_NUMBER.Infringements:
      return "Infringements";
    case PRODUCT_TYPE_NUMBER.FleetBooking:
      return "Fleet Booking";
    case PRODUCT_TYPE_NUMBER.LandManagement:
      return "Land Management";
    case PRODUCT_TYPE_NUMBER.CommunityAsset:
      return "Community Asset";
    case PRODUCT_TYPE_NUMBER.CommunityProperty:
      return "Community Property";
    default:
      type?.toString();
  }
};

export const fileUploadValidator = (files?: IAttachmentFile[] | null) => {
  if (isNil(files) || isEmpty(files)) return "This field is required.";
  const isValid = files?.some((item: IAttachmentFile) => !item?._isFailed);
  if (!isValid) return "File(s) upload invalid.";
  return "";
};
