import { SaveButtonDirectDebitBankAccount } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/components/buttons/save/_index";
import { DirectDebitBankAccountForm } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/components/general/_index";
import { TitleDirectDebitBankAccountManagePage } from "@app/products/direct-debit/system-admin/bank-accounts/constant";
import { DirectDebitBankAccount } from "@app/products/direct-debit/system-admin/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewManageDirectDebitBankAccount = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const directDebitBankAccount =
    dataForms?.GeneralForm as DirectDebitBankAccount;

  return (
    <>
      <FormTitle title={`New ${TitleDirectDebitBankAccountManagePage}`} />
      <CCManagePageActionBar
        leftActions={[<SaveButtonDirectDebitBankAccount />]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {directDebitBankAccount && <DirectDebitBankAccountForm />}
          </div>
        </div>
      </div>
    </>
  );
});
