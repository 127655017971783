export const defaultPerson = {
  Type: 1,
};

export const defaultAddress = {
  Delivery: "Standard",
};

export const defaultAddressLLS = {
  ...defaultAddress,
  Country: "Australia",
};
