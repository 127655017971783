import { eventEmitter } from "@/App";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { useInvoiceStore } from "@app/core/invoice/[id]/store";
import { Fees } from "@app/core/invoice/invoice-item-accordion/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { customLogger } from "@common/utils/logger";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useState } from "react";

interface IDeleteInvoiceItemButtonProps {
  gridSelectedRows: Fees[];
  setGridSelectedRows: React.Dispatch<React.SetStateAction<Fees[]>>;
  recordType?: RECORDTYPE;
  disabled?: boolean;
  visible?: boolean;
}

export const DeleteInvoiceItemButton = observer(
  ({
    gridSelectedRows,
    setGridSelectedRows,
    recordType,
    disabled,
    visible = true,
  }: IDeleteInvoiceItemButtonProps) => {
    const { clearNotifications } = useCCAppNotificationStore();
    const { loadInvoiceById, invoiceID, setIsExpandedInvoiceItems } =
      useInvoiceStore();
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
      useState<boolean>(false);
    const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
      []
    );
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const handleOnSubmit = async (data: IDeleteConfirmation) => {
      if (!recordType) return;
      setIsExpandedInvoiceItems(true);
      setIsDeleting(true);
      const ids: number[] = gridSelectedRows.map((fee: Fees) => fee.ID) ?? [];
      const response = await deleteRefUsageByIdAndRecordType(
        ids,
        recordType,
        data?.Reason
      );
      setIsDeleting(false);
      setNotifications([]);
      customLogger("Core delete, handle delete").info(response?.data);
      if (isSuccessResponse(response)) {
        setGridSelectedRows([]);
        clearNotifications();
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(CCJournalEventType.RefreshData);
        eventEmitter.emit(TabTableEventType.RefreshData);
        setShowConfirmDeleteDialog(false);
        loadInvoiceById(invoiceID ?? 0, false, {
          title: `Record is deleted successfully`,
          type: "success",
        } as IAppNotificationItem);
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: `Delete ${pluralize("record", ids.length)} failed`,
            type: "error",
            description: response.data?.Errors,
          },
        ]);
      }
    };

    if (!visible) return null;

    return (
      <>
        <Button
          type="button"
          iconClass="fas fa-minus"
          title="Remove invoice item"
          disabled={!gridSelectedRows?.length || disabled}
          onClick={() => {
            setShowConfirmDeleteDialog(true);
          }}
        />
        {showConfirmDeleteDialog && (
          <ConfirmDelete
            onClose={() => setShowConfirmDeleteDialog(false)}
            handleSubmit={handleOnSubmit}
            isDeleting={isDeleting}
            header={"Confirm Deletion"}
            notifications={notifications}
            contentDelete={"Fee(s)"}
          />
        )}
      </>
    );
  }
);
