import { DynamicQuestionDialog } from "@app/core/checklist/components/dialog/dynamic-question/_index";
import { postGetDynamicQuestion } from "@app/core/checklist/components/dialog/dynamic-question/api";
import { Svc_DialogDynamicQuestionGetRequest } from "@app/core/checklist/components/dialog/dynamic-question/model";
import { refactorDynamicQuestionData } from "@app/core/checklist/components/dialog/dynamic-question/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IChecklistButtonProps {
  recordSourceID: number;
  checklistID: number;
  checklistName?: string;
  disabled?: boolean;
  isFromAction?: boolean;
  dialogTitle?: string;
}

export const ChecklistButton = observer(
  ({
    disabled,
    recordSourceID,
    checklistID,
    checklistName = "Checklist",
    isFromAction = false,
    dialogTitle,
  }: IChecklistButtonProps) => {
    const [isShowDynamicQuestionDialog, setIsShowDynamicQuestionDialog] =
      useState<boolean>(false);
    const [dynamicQuestionResponse, setDynamicQuestionResponse] =
      useState<object>({});
    const [responseLoadError, setResponseLoadError] =
      useState<APIResponseError>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    if (!checklistID) {
      return null;
    }

    const loadDynamicQuestion = async () => {
      if (checklistID && recordSourceID) {
        const dialogDynamicQuestionGetRequest: Svc_DialogDynamicQuestionGetRequest =
          {
            RecordSourceID: recordSourceID,
            DynamicListID: checklistID.toString(),
            IsFromAction: isFromAction,
          };
        setIsLoading(true);
        const response = await postGetDynamicQuestion(
          dialogDynamicQuestionGetRequest
        );
        setIsLoading(false);
        setResponseLoadError(undefined);
        if (isSuccessResponse(response) && response.data) {
          const newDynamicQuestionData = refactorDynamicQuestionData(
            response.data
          );
          setDynamicQuestionResponse(newDynamicQuestionData);
        } else {
          setResponseLoadError({
            status: response.status,
            error: response.error,
          });
        }
        setIsShowDynamicQuestionDialog(true);
      }
    };

    return (
      <>
        <CCNavButton
          title={checklistName}
          onClick={loadDynamicQuestion}
          disabled={disabled}
        />
        <Loading isLoading={isLoading} isFullScreen />
        {isShowDynamicQuestionDialog && (
          <DynamicQuestionDialog
            onClosePopup={() => {
              setIsShowDynamicQuestionDialog(false);
            }}
            dynamicQuestionResponse={dynamicQuestionResponse}
            responseLoadError={responseLoadError}
            isLoading={isLoading}
            loadDynamicQuestion={loadDynamicQuestion}
            isFromAction={isFromAction}
            dialogTitle={dialogTitle}
          />
        )}
      </>
    );
  }
);
