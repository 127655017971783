import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
} from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useJobQueueDetailsTabStore } from "@common/pages/settings/system-admin/job-queue/reference-sidebar/detail/store";

export const SystemAdminJobQueueDetailTab = observer(() => {
  const {
    isLoading,
    jobQueueDetailsTabData,
    responseLoadError,
    loadJobQueueDetailsTabById,
    resetStore,
  } = useJobQueueDetailsTabStore();
  const { lastSelectedId } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const jobQueueId: string | number = useMemo(() => {
    return id ?? lastSelectedId;
  }, [lastSelectedId, id]);

  useEffect(() => {
    jobQueueId && loadJobQueueDetailsTabById(+jobQueueId);
    return () => resetStore();
  }, [jobQueueId, loadJobQueueDetailsTabById, resetStore]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          jobQueueId && loadJobQueueDetailsTabById(+jobQueueId);
        }}
      />
    );
  }
  if (!jobQueueDetailsTabData) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"ID:"}
              value={jobQueueDetailsTabData?.ID?.toString() ?? ""}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Job Queue By:"}
              value={jobQueueDetailsTabData?.ProcessPooledBy ?? ""}
            />
            <ProductReferenceRow
              title={"Process Type:"}
              value={jobQueueDetailsTabData?.ProcessTypeName ?? ""}
            />
            <ProductReferenceRow
              title={"Date Pooled:"}
              value={formatDisplayValue(
                jobQueueDetailsTabData?.DatePooled,
                DATETIME_FORMAT.DATETIME
              )}
            />
            <ProductReferenceRow
              title={"Flag Processed:"}
              value={formatDisplayValue(
                jobQueueDetailsTabData?.Flag_Processed,
                BOOLEAN_FORMAT.BOOLEAN
              )}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
