import { loadViewConfiguresColumns } from "@app/products/property/api";
import { LeviesSummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/components/levies-summary/_index";
import { colAssessments } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/config";
import { VO_Charge_Run_Assessment_Summary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { usePageType } from "@common/hooks/usePageType";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_Charge_Run_Assessment_Summary>();

export const AssociatedAssessments = observer((data: any) => {
  const { isManagePage } = usePageType();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [colsChargeRunAssessmentConfig, setColsChargeRunAssessmentConfig] =
    useState<IColumnFields[]>([]);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const { id } = useParams<{ id: string }>();
  const chargeRunId = data?.data?.Charge_Run_Id ?? id;
  const dataUrl = useMemo(() => {
    return isManagePage
      ? `odata/property/internal/chargerunassociatedassessmentregister/GetAssociatedAssessment(key=${chargeRunId},assessmentId=${data?.data?.Assessment_Id})?$count=true&`
      : `odata/property/internal/chargerunassessmentregister?key=${chargeRunId}&$count=true&}`;
  }, [isManagePage, chargeRunId, data?.data?.Assessment_Id]);

  //Get label
  const assessmentsLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessments
  );

  const loadChargeRunAssessmentViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Charge_Run_Assessment_Summary,
      colAssessments
    );
    if (Array.isArray(response)) {
      setColsChargeRunAssessmentConfig(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadChargeRunAssessmentViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadChargeRunAssessmentViewConfig();
        }}
      />
    );

  return (
    <div className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          {isManagePage && (
            <label className="cc-label">{`Associated ${assessmentsLabel.toLocaleLowerCase()}`}</label>
          )}
          <CCGrid
            className="cc-charge-run-assessments-grid"
            dataUrl={dataUrl}
            columnFields={colsChargeRunAssessmentConfig}
            primaryField={nameOf("Assessment_Id")}
            detail={LeviesSummary}
            itemPerPage={10}
          />
        </div>
      </div>
    </div>
  );
});
