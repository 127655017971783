import { IAccountsNoticeRun } from "@app/products/property/deferred-duty/notice-runs/[id]/components/child-screens/general/components/form-element/model";
import { IDeferredDutyNoticeRunSummary } from "@app/products/property/deferred-duty/notice-runs/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { ReactElement } from "react";
import { colNoticeRun } from "./config";
import { mockAccountsNoticeRun } from "./mock";

interface IProps {
  formRenderProps: FormRenderProps;
}

export function NoticeRunFormElement({
  formRenderProps,
}: IProps): ReactElement {
  const nameOf = nameOfFactory<IDeferredDutyNoticeRunSummary>();
  const { pushNotification } = useCCAppNotificationStore();
  const nameOfGird = nameOfFactory<IAccountsNoticeRun>();
  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }
    if (!formRenderProps.valid) return;
    pushNotification({
      title: "The form is not modified",
      type: "warning",
    });
  };

  return (
    <FormElement className="cc-e-certificate-request-form-body">
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />
      <section className="cc-field-group">
        <div className="cc-field">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Notice run ID</label>
              <Field
                readOnly
                name={nameOf("NoticeRunID")}
                placeholder={"Notice run ID"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Run</label>
              <Field
                readOnly
                name={nameOf("Run")}
                placeholder={"Run"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Issue date</label>
              <Field
                disabled
                name={nameOf("IssueDate")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Period start</label>
              <Field
                disabled
                name={nameOf("PeriodStart")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Period end</label>
              <Field
                disabled
                name={nameOf("PeriodEnd")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Create by</label>
              <Field
                readOnly
                name={nameOf("CreateBy")}
                placeholder={"Create by"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Create on</label>
              <Field
                disabled
                name={nameOf("CreateOn")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
          </div>
        </div>
        <div className="cc-field">
          <label className="cc-label">Accounts in notice run</label>
          <CCGrid
            className="cc-deferred-duty-notice-run-grid"
            data={mockAccountsNoticeRun}
            columnFields={colNoticeRun}
            primaryField={nameOfGird("NoticeId")}
          />
        </div>
      </section>
    </FormElement>
  );
}
