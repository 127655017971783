import { PPRActionBarNavDropdown } from "@app/products/town-planning/ppr/components/action-bar/nav-dropdown/_index";
import { getApplicationCategoryOdataURL } from "@app/products/town-planning/ppr/psa-referrals/_id/components/category-picker/utils";
import { pprRoute } from "@app/products/town-planning/ppr/route";
import { DeleteApplicationCategory } from "@app/products/town-planning/ppr/system-admin/application-categories/components/action-bar/buttons/delete-application-categories/_index";
import { NewPPRApplicationCategoriesButton } from "@app/products/town-planning/ppr/system-admin/application-categories/components/action-bar/buttons/new-application-categories/_index";
import { colPprSystemAdminApplicationCategories } from "@app/products/town-planning/ppr/system-admin/application-categories/config";
import { PPRApplicationCategory } from "@app/products/town-planning/ppr/system-admin/application-categories/model";
import { ApplicationCategoryDetailTab } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/reference-sidebar/detail/_index";
import { PPRApplicationCategoryHistoryTab } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/reference-sidebar/history/_index";
import { SYSTEM_ADMIN_APPLICATION_CATEGORIES_ROUTE } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/constant";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "./util";

const nameOf = nameOfFactory<PPRApplicationCategory>();

export default () => {
  const [isLoading, setIsLoading] = useState(false);

  useCCListViewActionBar({
    title: pprRoute.name,
    leftComponents: [<PPRActionBarNavDropdown />],
    centerComponents: [
      <NewPPRApplicationCategoriesButton />,
      <DeleteApplicationCategory setIsLoading={setIsLoading} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={SYSTEM_ADMIN_APPLICATION_CATEGORIES_ROUTE}
        productType={PRODUCT_TYPE.TownPlanning}
        recordType={RECORDTYPE.TP_ApplicationCategory}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <ApplicationCategoryDetailTab /> },
      {
        title: "History",
        component: <PPRApplicationCategoryHistoryTab />,
      },
    ],
  });
  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;
  return (
    <>
      <CCProductListView
        columnFields={colPprSystemAdminApplicationCategories}
        dataUrl={getApplicationCategoryOdataURL(RECORDTYPE.TP_PPRApplication)}
        primaryField={nameOf("ID")}
      />
    </>
  );
};
