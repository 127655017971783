import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { ICON_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSApplicationsReports: IColumnFields[] = [
  {
    field: CRSFieldMapping.StandardReport,
    title: "Standard Report",
    format: ICON_FORMAT.ICON_STANDARD_REPORT,
  },
  {
    field: CRSFieldMapping.ReportName,
    title: "Report Name",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  { field: CRSFieldMapping.Category, title: "Category" },
  { field: CRSFieldMapping.CreatedDate, title: "Created Date" },
  { field: CRSFieldMapping.CreatedBy, title: "Owner" },
  { field: CRSFieldMapping.ModifiedDate, title: "Modified Date" },
  { field: CRSFieldMapping.ModifiedBy, title: "Modified By" },
  {
    field: CRSFieldMapping.Recipients,
    title: "Email Recipient(s)",
  },
  { field: CRSFieldMapping.Schedule, title: "Schedule" },
  { field: CRSFieldMapping.RepeatType, title: "Repeat Type" },
  {
    field: CRSFieldMapping.ShareReport,
    title: "Share Report",
    format: ICON_FORMAT.ICON_SHARE_REPORT,
  },
];
