import { setupPickSiteAddress } from "@app/core/location-register/[id]/api";
import {
  LocationRegisterHandlerRequest,
  LocationRegisterMapObj,
  Register,
  Svc_FormAction_LocationRegister,
} from "@app/core/location-register/[id]/model";
import { useLocationRegisterStore } from "@app/core/location-register/[id]/store";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { PropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/_index";
import { RegisterUpdateTriggers } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { isSuccessResponse } from "@common/apis/util";
import { Address_BuildAddress } from "@common/input-pickers/address/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { getFullAddressSingleLine } from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { isHTML } from "@components/cc-input-picker/util";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";

export interface ILocationRegisterFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOfLocationRegisterMapObj = nameOfFactory<LocationRegisterMapObj>();
const nameOf = nameOfFactory<Register>();

const nameOfKeyValuePacket = nameOfFactory<KeyValuePacket>();

const getNameOf = (name: keyof Register) =>
  `${nameOfLocationRegisterMapObj("LocationRegister")}.${nameOf(name)}`;

const propertyTypeValidator = (value: any) => {
  if (value === 0) return "This field is required.";
  return requiredValidator(value);
};

export const LocationRegisterFormElement = observer(
  ({ formRenderProps }: ILocationRegisterFormElementProps) => {
    const { pushNotification, clearNotifications } =
      useCCAppNotificationStore();

    const {
      locationRegisterLovs,
      locationRegisterChangeHandler,
      isFormModified,
      locationRegisterUIControl,
    } = useLocationRegisterStore();

    const { valueGetter, onChange } = formRenderProps;

    const locationRegisterObj = valueGetter(
      nameOfLocationRegisterMapObj("LocationRegister")
    );
    const [isLoadingDialog, setIsLoadingDialog] = useState(false);
    const [initialBuildAddress, setInitialBuildAddress] =
      useState<Address_BuildAddress>();

    const siteAddressValue = getFullAddressSingleLine(
      valueGetter(getNameOf("Address"))
    );

    const propertyTypeDisplay = getDropdownValue(
      valueGetter(getNameOf("RegisterType_ENUM")),
      locationRegisterLovs?.RegisterTypes ?? [],
      "Key"
    );

    const siteAddressValidator = useCallback(() => {
      return requiredValidator(siteAddressValue);
    }, [siteAddressValue]);

    const handleChangePropertyType = (event: ComboBoxChangeEvent) => {
      onChange(getNameOf("RegisterType_ENUM"), {
        value: event.target.value?.Key ?? null,
      });

      onChange(getNameOf("RegisterType_Name"), {
        value: event.target.value?.Value ?? null,
      });
    };

    const updateSaveTriggers = (triggers: RegisterUpdateTriggers) => {
      let saveTriggers: RegisterUpdateTriggers[] =
        valueGetter(getNameOf("_SaveTriggers")) ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(getNameOf("_SaveTriggers"), {
          value: saveTriggers,
        });
      }
    };

    const handleChangeContact = (event: ComboBoxChangeEvent) => {
      const { value } = event;

      const saveTriggers = valueGetter(getNameOf("_SaveTriggers")) ?? [];

      if (!saveTriggers.includes(RegisterUpdateTriggers.UpdateContact)) {
        saveTriggers.push(RegisterUpdateTriggers.UpdateContact);
        onChange(getNameOf("_SaveTriggers"), { value: saveTriggers });
      }

      onChange(`${getNameOf("Contact")}.Contact`, { value: value });

      let params: LocationRegisterHandlerRequest = {
        LocationRegisterFormAction:
          Svc_FormAction_LocationRegister.Form_PickContact,
        Register: locationRegisterObj,
        LocationRegisterArgs: {
          Contact: {
            Contact: value,
          },
        },
        IsFirstTimeLoad: false,
      };

      locationRegisterChangeHandler(params, "Change contact failed.");
    };

    const handleSubmitSiteAddress = (
      buildAddress: Address_BuildAddress | null
    ) => {
      const address = buildAddress?.Address;
      let params: LocationRegisterHandlerRequest = {
        LocationRegisterFormAction:
          Svc_FormAction_LocationRegister.Form_PickAddress,
        Register: locationRegisterObj,
        LocationRegisterArgs: { Address: address },
        IsFirstTimeLoad: false,
      };

      locationRegisterChangeHandler(params, "Change side address failed.");
    };

    const handleChangeType = (event: ComboBoxChangeEvent) => {
      const { value } = event;
      onChange(getNameOf("Type_KWD"), { value: value?.Key ?? null });
    };

    const handleOpenDialog = async () => {
      if (!locationRegisterObj) return;
      setIsLoadingDialog(true);
      clearNotifications();
      const response = await setupPickSiteAddress(locationRegisterObj);
      setIsLoadingDialog(false);

      if (isSuccessResponse(response) && response.data) {
        setInitialBuildAddress(response.data.ReturnObj);
      } else {
        pushNotification({
          autoClose: false,
          title: "Setup property address fail.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };

    useEffect(() => {
      if (isFormModified) {
        onChange(getNameOf("Register_ID"), {
          value: valueGetter(getNameOf("Register_ID")),
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormModified]);

    return (
      <FormElement>
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Property type" isMandatory />
              <Field
                name={getNameOf("RegisterType_ENUM")}
                textField={nameOfKeyValuePacket("Value")}
                dataItemKey={nameOfKeyValuePacket("Key")}
                data={locationRegisterLovs?.RegisterTypes ?? []}
                component={CCSearchComboBox}
                validator={propertyTypeValidator}
                placeholder="Select property type"
                value={propertyTypeDisplay}
                onChange={handleChangePropertyType}
              />
            </div>

            <div className="cc-field">
              <CCLabel title="Type" isMandatory />
              <Field
                name={getNameOf("Type_KWD")}
                textField={nameOfKeyValuePacket("Value")}
                dataItemKey={nameOfKeyValuePacket("Key")}
                data={locationRegisterLovs?.Types ?? []}
                component={CCSearchComboBox}
                validator={requiredValidator}
                placeholder="Select property type"
                onChange={handleChangeType}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <CCLabel
                title={
                  propertyTypeDisplay
                    ? `${propertyTypeDisplay?.Value} name`
                    : "Name"
                }
                isMandatory
              />
              <Field
                name={getNameOf("Description")}
                component={CCTextArea}
                placeholder="Name"
                rows={4}
                validator={requiredValidator}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Site address" isMandatory />
              <Field
                uniqueKey="CRMSAddressLocationPicker"
                name={getNameOf("Address")}
                component={PropertyDetail}
                initialData={initialBuildAddress}
                placeholder={"Select site address"}
                formRenderProps={formRenderProps}
                validator={siteAddressValidator}
                isSearchPropertyAddresses={true}
                onError={(error: any) => {
                  pushNotification({
                    type: "error",
                    title: "Select site address fail",
                    description: error,
                    autoClose: false,
                  });
                }}
                value={siteAddressValue}
                onChangeEventHandler={handleSubmitSiteAddress}
                updateSaveTriggers={() => {
                  updateSaveTriggers(RegisterUpdateTriggers.UpdateAddress);
                }}
                onSubmit={handleSubmitSiteAddress}
                onButtonClick={handleOpenDialog}
                isLoadingDialog={isLoadingDialog}
              />
            </div>

            <div className="cc-field">
              <CCValueField
                value={
                  isHTML(locationRegisterUIControl?.LitOwners?.Value)
                    ? sanitizeHtml(locationRegisterUIControl?.LitOwners?.Value)
                    : locationRegisterUIControl?.LitOwners?.Value
                }
                label="Owner"
              />
            </div>

            <div className="cc-field">
              <CCValueField
                value={
                  isHTML(locationRegisterUIControl?.LitDetails?.Value)
                    ? sanitizeHtml(locationRegisterUIControl?.LitDetails?.Value)
                    : locationRegisterUIControl?.LitDetails?.Value
                }
                label="Details"
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Contact" />
              <Field
                uniqueKey="CRMSContact"
                name={`${getNameOf("Contact")}.Contact`}
                component={ContactPicker}
                onError={(error: any) => {
                  pushNotification({
                    type: "error",
                    title: "Pick contact errors",
                    description: error,
                    autoClose: false,
                  });
                }}
                placeholder="Select contact"
                onChange={handleChangeContact}
              />
            </div>

            <div className="cc-field">
              <CCValueField
                value={
                  isHTML(locationRegisterUIControl?.LitContactAddress?.Value)
                    ? sanitizeHtml(
                        locationRegisterUIControl?.LitContactAddress?.Value
                      )
                    : locationRegisterUIControl?.LitContactAddress?.Value
                }
                label="Address"
              />
            </div>

            <div className="cc-field">
              <CCValueField
                value={
                  isHTML(locationRegisterUIControl?.LitContactContact?.Value)
                    ? sanitizeHtml(
                        locationRegisterUIControl?.LitContactContact?.Value
                      )
                    : locationRegisterUIControl?.LitContactContact?.Value
                }
                label="Contact"
              />
            </div>
          </div>
        </div>
      </FormElement>
    );
  }
);
