import { SCHEMES_NOTICE_RUNS_ROUTE } from "@app/products/property/schemes/notice-runs/[id]/constant";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colSchemesNoticeRun: IColumnFields[] = [
  {
    field: "NoticeRunId",
    title: "Notice Run ID",
    locked: true,
    linkTo: (dataItem) => {
      return `${SCHEMES_NOTICE_RUNS_ROUTE}/${dataItem.NoticeRunId}`;
    },
  },
  {
    field: "NoticeRunName",
    title: "Notice Run Name",
  },
  {
    field: "IssueDate",
    title: "Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "DueDate",
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "NumberofAccounts",
    title: "Number of Accounts",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: "InterestRaised",
    title: "Interest Raised",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "PrincipalBecomingDue",
    title: "Principal Becoming Due",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "AmountDue",
    title: "Amount Due",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "PrincipalBalance",
    title: "Principal Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "PrincipalDue",
    title: "Principal Due",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "InterestBalance",
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "OtherBalances",
    title: "Other Balances",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "BalanceOutstanding",
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "PayoutAmount",
    title: "Payout Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "NoticeRunDate",
    title: "Notice Run Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "CreatedBy",
    title: "Created By",
  },
  {
    field: "NoticePrintedorExportedDate",
    title: "Notice Printed or Exported Date",
    format: DATETIME_FORMAT.DATETIME,
  },
];
