import { DTO_NoticeRun_Summary } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const loadLastNoticeRun = async (): Promise<
  APIResponse<DTO_NoticeRun_Summary | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<DTO_NoticeRun_Summary>(
      `api/property/internal/noticerun/last-noticerun`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
