import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingComplaintsByCategory: IColumnFields[] = [
  {
    field: BuildingFieldMapping.RefNo,
    title: "Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.Description,
    title: "Description",
  },
  {
    field: BuildingFieldMapping.DateTarget,
    title: "Target",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Source,
    title: "Source",
  },
  {
    field: BuildingFieldMapping.Category,
    title: "Category",
  },
  {
    field: BuildingFieldMapping.Location,
    title: "Location",
  },
  {
    field: BuildingFieldMapping.RequestedBy,
    title: "Contact",
  },
  {
    field: BuildingFieldMapping.ContactDetails,
    title: "Contact Number",
  },
  {
    field: BuildingFieldMapping.Status,
    title: "Status",
  },
  {
    field: BuildingFieldMapping.DueStatus,
    title: "Due Status",
  },
  {
    field: BuildingFieldMapping.DateRecorded,
    title: "Recorded",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.RecordedBy,
    title: "Recorded By",
  },
  {
    field: BuildingFieldMapping.OrgStructure,
    title: "Org Structure",
  },
  {
    field: BuildingFieldMapping.ActionOfficer,
    title: "Action Officer",
  },
  {
    field: BuildingFieldMapping.Coordinator,
    title: "Coordinator",
  },
  {
    field: BuildingFieldMapping.DateWork,
    title: "Work Date",
  },
  {
    field: BuildingFieldMapping.FileNumber,
    title: "File Number",
  },
  {
    field: BuildingFieldMapping.EnquiryRefNo,
    title: "Assets Reference Number",
  },
  {
    field: BuildingFieldMapping.Event_ReferenceNoAdditional,
    title: "Additional Reference Number",
  },
  {
    field: BuildingFieldMapping.OnBehalfOf,
    title: "On Behalf Of",
  },
  {
    field: BuildingFieldMapping.Flag_ITSupport,
    title: "IT Support",
  },
  {
    field: BuildingFieldMapping.Flag_Safety,
    title: "Safety",
  },
  {
    field: BuildingFieldMapping.Flag_Risk,
    title: "Risk",
  },
  {
    field: BuildingFieldMapping.Flag_VIP,
    title: "VIP",
  },
];
