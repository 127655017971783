import { DTO_Entity_LOVs } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getContactLovs = async (): Promise<
  APIResponse<DTO_Entity_LOVs>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/entity/lovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
