import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import {
  CommunicationTemplate,
  CommunicationTemplateLOVs,
  Svc_FieldSelections,
  Svc_MailMergeDocuments,
} from "@common/pages/settings/communication/template/_id/model";

export const getCommunicationTemplateById = async (
  communicationTemplateId: number
): Promise<APIResponse<CommunicationTemplate | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<CommunicationTemplate>(
      `api/core/internal/communicationtemplate/${communicationTemplateId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postCommunicationTemplate = async (
  communicationTemplateInfo: CommunicationTemplate
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `api/core/internal/communicationtemplate`,
      communicationTemplateInfo
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCommunicationTemplateLOVs = async (): Promise<
  APIResponse<CommunicationTemplateLOVs | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<CommunicationTemplateLOVs>(
      `api/core/internal/communicationtemplate/lovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getMailMergeDocument = async (
  documentDataset: MAILMERGEDATASET
): Promise<APIResponse<Svc_MailMergeDocuments[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Svc_MailMergeDocuments[]>(
      `api/core/internal/communicationtemplate/mailmergedocuments?documentDataset=${documentDataset}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getFieldSelection = async (
  documentDataset: MAILMERGEDATASET
): Promise<APIResponse<Svc_FieldSelections[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Svc_FieldSelections[]>(
      `api/core/internal/communicationtemplate/fieldselection?documentDataset=${documentDataset}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
