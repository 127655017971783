import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { ReasonDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/_index";
import { putRejectResponse } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/reject-response/api";
import {
  PPREnquirySubmitActions,
  RegisterStatus,
} from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { UITriggerRegister } from "@common/constants/uiTrigger";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const RejectResponseButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const { onSubmit, pprEnquiry, pprEnquiryId, setPPREnquiryWithLoading } =
    usePPREnquiryStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isVisible = useMemo(
    () => pprEnquiry?.Status_ENUM === RegisterStatus.AwaitingApproval,
    [pprEnquiry]
  );

  const objReason = useMemo(() => {
    const retVal = new ProvideReason();
    retVal.Title_Text = "Rejected Enquiry Comments";
    retVal.ReasonLabel_Text = "Comments";
    retVal.Flag_ShowDate = false;
    retVal.Reason = "";
    return retVal;
  }, []);

  useAddUniqueEventEmitter([
    {
      eventType: UITriggerRegister.RejectedResponse.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const handleSubmit = async (
    value: ProvideReason,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    if (!pprEnquiryId) return;
    setIsLoading(true);

    const response = await putRejectResponse(pprEnquiryId, value.Reason);
    setIsShowDialog(false);
    notificationRef.current?.resetNotifications();
    if (isSuccessIdentityPacket(response)) {
      appNotificationStore.clearNotifications();
      setPPREnquiryWithLoading(response.data?.Register);
      appNotificationStore.pushNotification({
        type: "success",
        title: "Response rejected successfully",
      });
      if (
        response.data?.Notifications &&
        response.data?.Notifications.length > 0
      ) {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Reject response",
          type: "warning",
          description: response.data?.Notifications,
        });
      }
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Reject response failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    setIsLoading(false);
  };

  return isVisible ? (
    <>
      <CCNavButton
        title={"Reject response"}
        onClick={onSubmit}
        name={PPREnquirySubmitActions.RejectResponse}
      />
      {isShowDialog && (
        <ReasonDialog
          onClose={() => setIsShowDialog(false)}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          initialData={objReason}
        />
      )}
    </>
  ) : null;
});
