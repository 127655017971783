export enum ModifyAreaOfResponsibilityKeysOfSteps {
  AreaOfResponsibility = "AreaOfResponsibility",
  Comments = "Comments",
  Documents = "Documents",
}
export enum EComponentAreaResponsibility {
  Compliance = 22,
  DebtRecovery = 20,
  Deferments = 21,
}

export enum EFieldAreaResponsibility {
  Compliance = 10008,
  DebtRecovery = 10014,
  Deferments = 10010,
}
