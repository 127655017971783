import { IData } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

export const useDataSetting = (
  configData: IData | undefined,
  enumList: string[]
) => {
  const data: { [key in string]: SettingField | undefined } = {};
  enumList.forEach((item: any) => {
    const settingEnum = ECorporateSettingsField[item];
    data[item] =
      configData && settingEnum ? configData[settingEnum] : undefined;
  });
  return data;
};
