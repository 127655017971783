import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { propertyRoute } from "@app/products/property/route";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { noticeGroupsRoute } from "@app/products/property/notice-groups/route";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { nameOfFactory } from "@common/utils/common";
import { VO_NoticeGroups_Assessment } from "@app/products/property/notice-groups/model";
import { useNoticeGroupsStore } from "@app/products/property/notice-groups/store";
import Loading from "@components/loading/Loading";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { NOTICE_GROUPS_ACT_HOUSING_LIST } from "@app/products/property/notice-groups/constant";
import { observer } from "mobx-react-lite";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_NoticeGroups_Assessment>();
export default observer(() => {
  const {
    columnNoticeGroups,
    isLoading,
    responseLoadError,
    loadConfigs,
    reLoadApiConfig,
    resetStore,
  } = useNoticeGroupsStore();

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={noticeGroupsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      ></CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });

  useEffectOnce(() => {
    loadConfigs();
    return () => resetStore();
  });

  if (isLoading) return <Loading isLoading />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          reLoadApiConfig();
        }}
      />
    );

  return (
    <CCProductListView
      isLoading={isLoading}
      columnFields={columnNoticeGroups}
      dataUrl={NOTICE_GROUPS_ACT_HOUSING_LIST}
      primaryField={nameOf("Assessment_Id")}
    />
  );
});
