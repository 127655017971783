import {
  AmsAssetRegister,
  AmsLookupItem,
  lookupList,
} from "@app/products/ams/assets/[id]/model";
import { AssetLOVs, LOOKUPNAME } from "@app/products/ams/constants/ams.lookups";
import { ILookup, ILookupItem } from "@app/products/ams/model/lookup";

interface AssetLOV {
  [key: string]: ILookupItem[];
}
interface CustomLOV {
  LookupName: string;
  AssetName: keyof AmsAssetRegister;
}
function getObjKeys(obj: any, value: string) {
  return Object.keys(obj).filter((key) => obj[key] === value);
}
export const getLOVFromLookups = (lookups: ILookup[]) => {
  if (!lookups) return;
  // funtion to convert lookups to LOV
  const LOV: AssetLOV = {};
  lookups.forEach((lookup: ILookup) => {
    if (lookupList.includes(lookup.name)) {
      LOV[getObjKeys(LOOKUPNAME, lookup.name)[0]] = [...lookup.items];
    }
  });
  return LOV;
};
export const getLookupItemDescription = (asset: AmsAssetRegister, lov: any) => {
  let description: any = {};
  // function to get description of lov mapping with id in asset
  const lovList: CustomLOV[] = [
    { LookupName: "AssetCategory", AssetName: "AssetCategoryId" },
    { LookupName: "AssetStatus", AssetName: "AssetStatusId" },
    { LookupName: "costCentre", AssetName: "CostCentreId" },
    { LookupName: "department", AssetName: "DepartmentId" },
    { LookupName: "osProduct", AssetName: "OsProductId" },
    { LookupName: "location", AssetName: "LocationId" },
  ];
  lovList.forEach((lovName: CustomLOV) => {
    if (asset[lovName.AssetName as keyof AmsAssetRegister] !== null) {
      const descriptions =
        lov[lovName.LookupName as keyof AssetLOVs].find(
          (item: AmsLookupItem) =>
            item?.id === asset[lovName.AssetName as keyof AmsAssetRegister]
        )?.description ?? "(item missing)";
      description[lovName.AssetName] = descriptions;
    } else {
      description[lovName.AssetName] = "(item missing)";
    }
  });
  return description;
};
