import {
  SHAREPOINT_DOC_LIBRARIES,
  SHAREPOINT_DOC_SET_CONTENT_TYPE,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/share-point-document/constant";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Svc_SharepointDocumentLibrary } from "@common/models/corporateSetting";

export const getSharePointDocumentLibraries = async (
  isSharePointDocumentLibraries: boolean
): Promise<APIResponse<Svc_SharepointDocumentLibrary[]>> => {
  const typeOfData = isSharePointDocumentLibraries
    ? SHAREPOINT_DOC_LIBRARIES
    : SHAREPOINT_DOC_SET_CONTENT_TYPE;
  try {
    return await CoreAPIService.getClient().get(
      `/api/core/internal/settings/${typeOfData}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
