import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCInput } from "@components/cc-input/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field } from "@progress/kendo-react-form";
import { InputProps } from "@progress/kendo-react-inputs";
import React from "react";
export interface IInputGlobalSettingsProps extends InputProps {
  data: SettingField | undefined;
  isEditing: boolean;
  isFieldVisible?: boolean;
  colSpan?: number;
  customTitle?: string;
}

export const InputGlobalSettings = ({
  data,
  isEditing,
  isFieldVisible = true,
  colSpan,
  customTitle,
  ...others
}: IInputGlobalSettingsProps) => {
  return data && isFieldVisible ? (
    <div className={`cc-field${colSpan ? " cc-col-span-" + colSpan : ""}`}>
      <label className="cc-label">
        {customTitle ?? data?.Title ?? ""}
        {data?.IsMandatory && <CCTooltip type="validator" position="right" />}
        {data?.HelpText && (
          <CCTooltip
            type="custom"
            content=" "
            position="auto"
            clickToOpen
            customTemplate={<>{sanitizeHtml(data.HelpText)}</>}
          >
            <i className="fa fa-info-circle ml-1 text-accent" />
          </CCTooltip>
        )}
      </label>
      {data?.Description && (
        <label className="cc-settings-description">
          {sanitizeHtml(data?.Description)}
        </label>
      )}
      <Field
        placeholder={customTitle ?? data?.Title ?? ""}
        name={data?.FieldName}
        component={CCInput}
        disabled={!isEditing}
        validator={data.IsMandatory ? requiredValidator : undefined}
        {...others}
      />
    </div>
  ) : null;
};
