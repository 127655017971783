import { ResetPortalCache } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-portal/components/button/reset-portal-cache/_index";
import { UpdateKeyWords } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-portal/components/button/update-keywords/_index";
import { UpdateSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-portal/components/button/update-settings/_index";
import React from "react";

export const PublicPortalSettingForm = () => {
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <UpdateKeyWords />
        <UpdateSettings />
        <ResetPortalCache />
      </div>
    </section>
  );
};
