import { DTO_ModifyCompliance_LOVs } from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class ModifyComplianceStore {
  private _complianceId?: number | undefined = undefined;
  private _modifyComplianceLOVs?: DTO_ModifyCompliance_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get modifyComplianceLOVs() {
    return this._modifyComplianceLOVs;
  }
  setModifyComplianceLOVs = (
    modifyComplianceLOVs?: DTO_ModifyCompliance_LOVs
  ) => {
    runInAction(() => {
      this._modifyComplianceLOVs = modifyComplianceLOVs;
    });
  };

  get complianceId() {
    return toJS(this._complianceId);
  }
  setComplianceId = (complianceId?: number) => {
    runInAction(() => {
      this._complianceId = complianceId;
    });
  };
}

const modifyComplianceStoreContext = createContext(new ModifyComplianceStore());
export const useModifyComplianceStore = () => {
  return useContext(modifyComplianceStoreContext);
};
