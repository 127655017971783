import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { CancelToken } from "axios";

export const getHMPremisesAddress = async (
  premisesID: number,
  cancelToken: CancelToken
): Promise<APIResponse<string> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<string>(
      `api/hm/internal/premises-address-details/${premisesID}`,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
