import {
  apiCoreGetInspection,
  apiCoreGetInspectionLOVs,
  apiCoreGetLightweightSiteUser,
  apiCoreSaveInspection,
} from "@app/core/components/common/utils";
import { IInspectionLovs, Inspection } from "@app/core/inspections/[id]/model";
import {
  BubbleUpIdentifier,
  SiteUser,
} from "@app/products/waste-water/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const getInspectionById = async (
  inspectionId: number
): Promise<APIResponse<Inspection | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Inspection>(
      apiCoreGetInspection(inspectionId)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getInspectionLOVs = async (
  bubbleUpsParent: BubbleUpIdentifier[] | undefined,
  inspectionType: number
): Promise<APIResponse<IInspectionLovs>> => {
  try {
    return await CoreAPIService.getClient().post<IInspectionLovs>(
      apiCoreGetInspectionLOVs(inspectionType),
      bubbleUpsParent
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSaveInspection = async (
  data: Inspection
): Promise<APIResponse<IdentityPacket>> => {
  try {
    const response = await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreSaveInspection(),
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSiteUser = async (
  id: number
): Promise<APIResponse<SiteUser | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      apiCoreGetLightweightSiteUser(id)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
