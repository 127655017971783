import { WorkflowTypes } from "@app/products/property/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export const ModifyMasterPropertyButton = observer(() => {
  const { gridSelectedRows, isEmptyData } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const { setListDialog } = usePropertyWorkflowStore();

  const isDisabled = useMemo(() => {
    return (
      (!isEmptyData && gridSelectedRows.length !== 1 && !id) ||
      (!id && isEmptyData)
    );
  }, [gridSelectedRows.length, id, isEmptyData]);

  return (
    <CCNavButton
      title="Modify master property"
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Edit_MasterProperty,
            data: {},
          },
        ]);
      }}
      disabled={isDisabled}
    />
  );
});
