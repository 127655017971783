import { DTO_MasterProperty_LOVs } from "@app/products/property/assessments/master-properties/components/form-steps/add-new-master-property/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NewMasterPropertyDialogStore {
  private _masterPropertyLOVs?: DTO_MasterProperty_LOVs = undefined;
  private _isLoadingOnDialog: boolean = false;
  private _isLoadingOnDialogCancel: boolean = false;
  private _isLoadingSendBack: boolean = false;
  private _isLoadingAllocate: boolean = false;
  private _isLoadingReallocate: boolean = false;
  private _isLoadingApprove: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get masterPropertyLOVs() {
    return this._masterPropertyLOVs;
  }
  setMasterPropertyLOVs = (masterPropertyLOVs?: DTO_MasterProperty_LOVs) => {
    runInAction(() => {
      this._masterPropertyLOVs = masterPropertyLOVs;
    });
  };

  get isLoadingOnDialog(): boolean {
    return this._isLoadingOnDialog;
  }
  setIsLoadingOnDialog = (isLoadingOnDialog: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialog = isLoadingOnDialog;
    });
  };

  get isLoadingOnDialogCancel(): boolean {
    return this._isLoadingOnDialogCancel;
  }
  setIsLoadingOnDialogCancel = (isLoadingOnDialogCancel: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialogCancel = isLoadingOnDialogCancel;
    });
  };

  get isLoadingSendBack(): boolean {
    return this._isLoadingSendBack;
  }
  setIsLoadingSendBack = (isLoadingSendBack: boolean) => {
    runInAction(() => {
      this._isLoadingSendBack = isLoadingSendBack;
    });
  };

  get isLoadingAllocate(): boolean {
    return this._isLoadingAllocate;
  }
  setIsLoadingAllocate = (isLoadingAllocate: boolean) => {
    runInAction(() => {
      this._isLoadingAllocate = isLoadingAllocate;
    });
  };

  get isLoadingReallocate(): boolean {
    return this._isLoadingReallocate;
  }
  setIsLoadingReallocate = (isLoadingReallocate: boolean) => {
    runInAction(() => {
      this._isLoadingReallocate = isLoadingReallocate;
    });
  };

  get isLoadingApprove(): boolean {
    return this._isLoadingApprove;
  }
  setIsLoadingApprove = (isLoadingApprove: boolean) => {
    runInAction(() => {
      this._isLoadingApprove = isLoadingApprove;
    });
  };
}

const newMasterPropertyDialogStoreContext = createContext(
  new NewMasterPropertyDialogStore()
);
export const useNewMasterPropertyDialogStore = () => {
  return useContext(newMasterPropertyDialogStoreContext);
};
