import { Coords } from "google-map-react";
import React from "react";
import "./MapMarker.scss";

export interface IMapMarker extends Coords {
  text?: string | undefined;
}
export default function MapMarker({ text }: IMapMarker) {
  return (
    <div className="cc-map-marker">
      <i className="fas fa-map-marker-alt" />
      {text ? <div className="cc-map-marker-label">{text}</div> : null}
    </div>
  );
}
