import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { formatStyleProminent } from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

export const colAllocation: IColumnFields[] = [
  {
    field: "Levy",
    title: "Levy",
  },
  {
    field: "Amount",
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
];

export const colAllocationSpec: IColumnFields[] = [];
