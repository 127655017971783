import { history } from "@/AppRoutes";
import { DTO_DeferredDuty } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/deferred-duty/model";
import { PROPERTY_DEFERRED_DUTY_ROUTE } from "@app/products/property/deferred-duty/[id]/constant";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_DeferredDuty>();
export const colDebtRecoveryDeferredDuty: IColumnFields[] = [
  {
    field: nameOf("AccountId"),
    title: "Account Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
    handleOnClick: (dataItem: any) => {
      history.push(`${PROPERTY_DEFERRED_DUTY_ROUTE}/${dataItem.AssessmentId}`);
    },
  },
  {
    field: nameOf("AccountNumber"),
    title: "Account Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AccountName"),
    title: "Account Name",
  },
  {
    field: nameOf("AssessmentReference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("AssessmentId"),
    title: "Assessment Id",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("DefferedDutyBalance"),
    title: "Deferred Duty Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InitialBalance"),
    title: "Initial Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("BalanceOutstanding"),
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("PayoutAmount"),
    title: "Payout Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InterestRaisedUpto"),
    title: "Interest Raised Upto",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("InstallmentAmount"),
    title: "Installment Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("OverridingInterestRate"),
    title: "Overriding Interest Rate",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("OtherAmount"),
    title: "Other Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
