import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import { IActionCompleteSubmitData } from "@common/pages/actions/components/action-bar/dialog/complete-action/model";

export const putCompleteAction = async (
  id: number,
  reqBody: IActionCompleteSubmitData
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<IdentityPacket>(
      `/api/core/internal/actions/${id}/completed`,
      JSON.stringify(reqBody)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
