export interface DTO_Assessment_Charges {
  AssessmentId: number;
  View: ChargeViews | null;
  RatingPeriodId: number | null;
  ShowCancelledCharges: boolean | null;
  ShowPaidCharges: boolean | null;
  Charges: DTO_Charges;
  ChargeViews: DTO_Assessment_ChargeViews;
}

export interface DTO_Charges {
  Charges: DTO_Charge[];
  Totals: DTO_ChargesTotals;
  GroupByFieldNames: string[];
}

export enum ChargeViews {
  All = 0,
  Grouped_by_Rating_Period = 1,
  Rating_Period = 2,
  From_Rating_Period = 3,
  Created_by_me = 4,
  OldCharges = 7,
  NewCharges = 8,
  Adjustments = 9,
}

export interface DTO_ChargesTotals {
  GrossAmount: number | null;
  RebatesGiven: number | null;
  NettAmount: number | null;
  Balance: number | null;
}

export interface DTO_Assessment_ChargeViews {
  Views: mChargeView[];
}

export interface mChargeView {
  View: ChargeViews;
  Id: number;
  RatingPeriod: mRatingPeriod;
  Name: string;
}

export interface mRatingPeriod {
  Name: string;
  FromDate: string;
  ToDate: string;
  Id: number;
}

export interface DTO_Charge {
  Date: Date | null;
  GrossAmount: number | null;
  RebatesGiven: number | null;
  NettAmount: number;
  Balance: number | null;
  Interest: number | null;
  Name: string;
  Code: string;
  Description: string;
  RatingPeriod: string;
  Id: number;
  ChargeLevies: DTO_Levies;
  ChargeInstalments: DTO_Instalments;
  ChargeBalances: DTO_Balances;
  InstallmentPlanType: string;
}

export interface DTO_Charge_Instalment {
  Charge: string;
  InstalmentNumber: number | null;
  InstalmentAmount: number;
  Balance: number;
  InterestBalance: number | null;
  DueDate: string | null;
  EffectiveDate: string | null;
  InterestStartRaisingOn: string | null;
  InterestEffectiveDate: string | null;
  InterestDateTo: string | null;
  InstalmentProposedAmount: number | null;
  Id: number;
}

export interface DTO_Levies {
  Levies: DTO_Charge_Levy[];
  Totals: DTO_Totals;
}

export interface DTO_Totals {
  GrossAmount: number | null;
  NettAmount: number | null;
  Balance: number | null;
}

export interface DTO_Instalments {
  Instalments: DTO_Charge_Instalment[];
  Totals: DTO_Totals;
}

export interface DTO_Totals {
  InstalmentAmount: number | null;
  Balance: number | null;
  InterestBalance: number | null;
}

export interface DTO_Balances {
  Balances: DTO_Charge_Balance[];
  Totals: DTO_Totals;
}

export interface DTO_Totals {
  Balance: number | null;
  InterestBalance: number | null;
}

export interface DTO_Charge_Levy {
  Id: number;
  Calculation: string;
  TaxAmount: number | null;
  AdjustmentForCappingAmount: number | null;
  ReductionToMaximumAmount: number | null;
  IncrementToMinimumAmount: number | null;
  RateByValuationAmount: number | null;
  RebatesGiven: number | null;
  RebateAmount: number | null;
  Rounding: number | null;
  PercentageOfRatingPeriod: number | null;
  BaseAmount: number | null;
  InterestBalance: number | null;
  Balance: number;
  Levy: string;
  Charge: string;
  GrossAmount: number | null;
  NettAmount: number | null;
}

export interface DTO_Charge_Balance {
  ChargeDate: string | null;
  ChargeName: string;
  InstalmentNumber: number | null;
  DueDate: string | null;
  ChargeLevy: string;
  OriginalAmount: number | null;
  Balance: number;
  InterestBalance: number | null;
  Id: number;
}
