import { CRMSViews } from "@app/products/crms/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";

export const hardRubbishRoute: ICCRoute = {
  path: "hard-rubbish",
  name: "Hard Rubbish",
  enum: CORPORATE_SETTINGS_FIELD.CUSTOMERSERVICE_Views_HardRubbishEvents,
  children: [
    {
      path: "not-collected",
      name: "Not Collected",
      enum: CRMSViews.HardRubbish_NotCollected,
      component: require("./not-collected/_index").default,
    },
    {
      path: "all-collections",
      name: "All Collections",
      enum: CRMSViews.HardRubbish_AllCollections,
      component: require("./all-collections/_index").default,
    },
    {
      path: "all-events",
      name: "All Events",
      enum: CRMSViews.HardRubbish_AllEvents,
      component: require("./all-events/_index").default,
    },
    {
      path: "collection-lookup",
      name: "Collection Lookup",
      enum: CRMSViews.HardRubbish_CollectionLookup,
      component: require("./collection-lookup/_index").default,
    },
  ],
};
