import { columnDebtRecoveryHistory } from "@common/pages/contacts/config";
import { CCGrid } from "@components/cc-grid/_index";
import { NoData } from "@components/no-data/NoData";
import { RadioGroup } from "@progress/kendo-react-inputs";
import React from "react";
import DebtRecoveryDetail from "./components/debt-recovery-detail/_index";
import mockListDebtRecoveryHistory from "./mock";

const DebtRecovery = () => {
  const options = [
    {
      label: "Ratepayer",
      value: "Ratepayer",
    },
    {
      label: "Assessment",
      value: "Assessment",
    },
  ];
  return (
    <div className="cc-contact-debt-recovery cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Debt recovery history for</label>
            <RadioGroup
              data={options}
              layout="horizontal"
              defaultValue="Assessment"
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Debt recovery history</label>
            <CCGrid
              className="cc-contact-debt-recovery-history-grid"
              data={mockListDebtRecoveryHistory || []}
              columnFields={columnDebtRecoveryHistory}
              primaryField="ID"
              detail={DebtRecoveryDetail}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Debt recovery exemptions given </label>
            <NoData vertical />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DebtRecovery;
