import { cancelCertificate } from "@app/products/property/certificates/[id]/components/action-bar/buttons/cancel-certificate/api";
import { useCertificateStore } from "@app/products/property/certificates/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface ICancelCertificateButtonProps {
  disabled: boolean;
  certificateId: number;
}
export const CancelCertificateButton = observer(
  ({ disabled, certificateId }: ICancelCertificateButtonProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const { reLoadCertificate } = useCertificateStore();

    const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCancelCertificate = async () => {
      setIsLoading(true);
      const response = await cancelCertificate(certificateId);
      setIsLoading(false);
      setIsShowConfirmDialog(false);
      if (isSuccessResponse(response) && response.data?.IsSuccess) {
        reLoadCertificate();
        pushNotification({
          title:
            response.data?.SuccessMessage ?? "Cancel certificate successfully.",
          type: "success",
        });
      } else {
        pushNotification({
          autoClose: false,
          title: response.data?.ErrorMessage ?? "Cancel certificate failed.",
          type: "error",
        });
      }
    };

    return (
      <>
        <CCNavButton
          title="Cancel certificate"
          disabled={disabled}
          onClick={() => setIsShowConfirmDialog(true)}
        />
        {isShowConfirmDialog && (
          <ConfirmDialog
            title="Confirm Cancel Certificate"
            subMessage="Are you sure you wish to cancel this certificate?"
            isLoadingYes={isLoading}
            onClosePopup={() => setIsShowConfirmDialog(false)}
            onAsyncConfirm={handleCancelCertificate}
          />
        )}
      </>
    );
  }
);
