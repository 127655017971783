import { eComponent } from "@app/products/property/components/associations/model";
import { getPropertyCollections } from "@app/products/property/components/child-screen/collections/api";
import { colCollectionsProperty } from "@app/products/property/components/child-screen/collections/config";
import {
  DTO_Assessment_Collection,
  DTO_Collection,
} from "@app/products/property/components/child-screen/collections/model";
import { ICCViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class CollectionsStore {
  private _collections?: DTO_Collection[] = undefined;
  private _selectedCollections?: DTO_Collection[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _colCollection: IColumnFields[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get collections() {
    return toJS(this._collections);
  }
  setCollections = (collections: DTO_Collection[] | undefined) => {
    runInAction(() => {
      this._collections = collections;
    });
  };

  get colCollection() {
    return toJS(this._colCollection);
  }
  setColCollection = (columns: IColumnFields[] = []) => {
    runInAction(() => {
      this._colCollection = columns;
    });
  };

  get selectedCollections() {
    return toJS(this._selectedCollections);
  }
  setSelectedCollections = (selectedCollections: DTO_Collection[]) => {
    runInAction(() => {
      this._selectedCollections = selectedCollections;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._collections = undefined;
      this._selectedCollections = [];
      this._isLoading = false;
    });
  };

  loadCollections = async (
    componentNumber: eComponent,
    componentId: number
  ) => {
    if (isNil(componentNumber) && isNil(componentId)) return;
    this.setIsLoading(true);
    const response = await getPropertyCollections(componentNumber, componentId);
    let errorResponse = undefined;
    if (
      Array.isArray(response) &&
      response?.[0]?.data &&
      !response?.[0]?.data?.hasOwnProperty("MessageType") &&
      response?.[1]?.data &&
      !response?.[1]?.data?.hasOwnProperty("MessageType")
    ) {
      const [dataCollection, viewConfig] = response;
      this.setCollections(dataCollection?.data?.Collections ?? []);
      this.setColCollection(
        processDynamicColumns(
          colCollectionsProperty,
          viewConfig?.data?.ColumnDefinitions?.Columns ?? []
        )
      );
    } else {
      if (Array.isArray(response)) {
        let responseError: APIResponse<
          DTO_Assessment_Collection | ICCViewConfiguration | undefined
        > = response?.[0];
        if (!isSuccessResponse(response?.[1]) && response?.[1]) {
          responseError = response?.[1];
        }
        errorResponse = {
          status: responseError?.status,
          error: responseError?.error,
        };
      } else {
        const responseError = response as APIResponse;
        errorResponse = {
          status: responseError.status,
          error: responseError.error,
        };
      }
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  reloadCollections = async (
    componentNumber: eComponent,
    componentId: number
  ) => {
    if (!isNil(componentNumber) && !isNil(componentId))
      await this.loadCollections(componentNumber, componentId);
  };
}
const collectionsStoreContext = createContext(new CollectionsStore());
export const useCollectionsStore = () => {
  return useContext(collectionsStoreContext);
};
