import { VO_Zone } from "@app/products/property/components/dialogs/add-zones-dialog/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Zone>();

export const colSelectZones: IColumnFields[] = [
  {
    field: nameOf("Zoning_Scheme_Name"),
    title: "Scheme",
  },
  {
    field: nameOf("Zone_Name"),
    title: "Name",
  },
  {
    field: nameOf("Zone_Code"),
    title: "Code",
  },
  {
    field: nameOf("Zone_Change_DateTime"),
    title: "Change On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Zone_Change_UserId"),
    title: "Change By",
  },
  {
    field: nameOf("Zone_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
