export const covertToActionTypeSelected = (
  actionTypes: any[],
  getField: string,
  keyField: string
) => {
  if (actionTypes?.length > 0) {
    return actionTypes.map((item) => ({ [keyField]: item[getField] }));
  }
  return undefined;
};
