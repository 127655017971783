import { allEventsRoute } from "@app/products/crms/all-events/route";
import { councillorEventsRoute } from "@app/products/crms/councillor-events/route";
import { externalUserEventsRoute } from "@app/products/crms/external-user-events/route";
import { hardRubbishRoute } from "@app/products/crms/hard-rubbish/route";
import { inspectionsRoute } from "@app/products/crms/inspections/route";
import { itSupportRoute } from "@app/products/crms/it-support/route";
import { knowledgeBaseRoute } from "@app/products/crms/knowledge-base/route";
import { noticesRoute } from "@app/products/crms/notices/route";
import { payRollRoute } from "@app/products/crms/payroll/route";
import { phoneMessagesRoute } from "@app/products/crms/phone-messages/route";
import { serviceStandardsRoute } from "@app/products/crms/service-standards/route";
import { LOOKUP_TABLES_ROUTE } from "@app/products/crms/system-admin/lookup-tables/[id]/constant";
import { systemAdminRoute } from "@app/products/crms/system-admin/route";
import { unresolvedEventsRoute } from "@app/products/crms/unresolved-events/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";

export const crmsRoute: ICCRoute = {
  path: "crms",
  name: "CRMS",
  enum: eProductType.CustomerService,
  children: [
    unresolvedEventsRoute,
    allEventsRoute,
    councillorEventsRoute,
    externalUserEventsRoute,
    hardRubbishRoute,
    phoneMessagesRoute,
    inspectionsRoute,
    noticesRoute,
    itSupportRoute,
    payRollRoute,
    knowledgeBaseRoute,
    serviceStandardsRoute,
    systemAdminRoute,
    {
      path: "event/batch-update",
      component: require("./batch-update-event/_index").default,
    },
    {
      path: "event/:id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
    {
      path: `${LOOKUP_TABLES_ROUTE}/:id(new|[0-9]+)`,
      component: require("./system-admin/lookup-tables/[id]/_index").default,
    },
  ],
};
