import { getJournalSummaryById } from "@app/products/property/journals/[id]/api";
import { DTO_Journal_Summary } from "@app/products/property/journals/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isUndefined } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class JournalStore {
  private _journal?: DTO_Journal_Summary = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get journal() {
    return toJS(this._journal);
  }
  setJournal = (journal?: DTO_Journal_Summary | undefined) => {
    runInAction(() => {
      this._journal = journal;
    });
  };
  get journalId() {
    return this._journal?.Journal_Number;
  }
  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._journal = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadJournal = async (
    journalId: number,
    notification?: IAppNotificationItemAddProps
  ): Promise<boolean> => {
    if (journalId === undefined) return false;
    this.setIsLoading(true);
    const response = await getJournalSummaryById(journalId);
    let errorResponse, newJournal;
    if (isSuccessResponse(response)) {
      newJournal = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setJournal(newJournal);
    this.setIsLoading(false);
    if (notification) {
      appNotificationStore.pushNotification(notification);
    }
    return isUndefined(errorResponse);
  };

  reLoadJournal = async (): Promise<boolean> => {
    if (this.journalId) return await this.loadJournal(this.journalId);
    return false;
  };
}

const journalStoreContext = createContext(new JournalStore());
export const useJournalStore = () => {
  return useContext(journalStoreContext);
};
