//#region FSR
import { HMFieldMapping } from "@app/products/hm/model";
import { DATE_FORMAT } from "@common/constants/common-format";

export const colHMFSRLog = [
  {
    field: HMFieldMapping.DateStarted,
    title: "Date",
    width: 150,
    locked: true,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.Quarter,
    title: "Quarter",
    width: 150,
  },
  {
    field: HMFieldMapping.Result,
    title: "Result",
    width: 150,
  },
  {
    field: HMFieldMapping.LogStatus,
    title: "Log Status",
    width: 150,
  },
  {
    field: HMFieldMapping.TransmissionNumber,
    title: "Transactions Number",
    width: 150,
  },
  {
    field: HMFieldMapping.NoOfParts,
    title: "Parts",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesCount,
    title: "Premises",
    width: 150,
  },
  {
    field: HMFieldMapping.AuditCount,
    title: "Audits",
    width: 150,
  },
  {
    field: HMFieldMapping.InspectionCount,
    title: "Inspections",
    width: 150,
  },
  {
    field: HMFieldMapping.EnforcementCount,
    title: "Notices",
    width: 150,
  },
  {
    field: HMFieldMapping.ProsecutionCount,
    title: "Prosecutions",
    width: 150,
  },
  {
    field: HMFieldMapping.VerdictCount,
    title: "Verdicts",
    width: 150,
  },
  {
    field: HMFieldMapping.ComplaintCount,
    title: "Complaints",
    width: 150,
  },
  {
    field: HMFieldMapping.RecallCount,
    title: "Recalls",
    width: 150,
  },
  {
    field: HMFieldMapping.RunBy,
    title: "Run By",
    width: 150,
  },
];
