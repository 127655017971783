import { DTO_Assessment_Compliance } from "@app/products/property/assessments/[id]/components/child-screens/compliance/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getAssessmentCompliance = async (
  id: number,
  isRatePayer: number
): Promise<APIResponse<DTO_Assessment_Compliance | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Assessment_Compliance>(
      `api/property/internal/assessment/${id}/${isRatePayer}/compliances`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
