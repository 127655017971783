import { DTO_PIC_TabDetails } from "@app/products/property/pic/[id]/components/reference-sidebar/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getPicDetailsTabById = async (
  picId: number
): Promise<APIResponse<DTO_PIC_TabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_PIC_TabDetails>(
      `/api/property/int/pic/${picId}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
