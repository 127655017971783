import { DATETIME_FORMAT } from "@common/constants/common-format";
import { CoreDocument } from "@common/models/CoreDocument";
import { SETTINGS_LOOKUPS_DOCUMENTS_ROUTE } from "@common/pages/settings/lookups/documents/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<CoreDocument>();

export const colLookupsDocuments: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Document Name",
    locked: true,
    linkTo: (dataItem: CoreDocument) =>
      `${SETTINGS_LOOKUPS_DOCUMENTS_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("DocumentUsageName"),
    title: "Document Usage",
  },
  {
    field: nameOf("DatasetName"),
    title: "Data",
  },
  {
    field: nameOf("SaveFormat"),
    title: "Save Format",
  },
  {
    field: nameOf("WhenLoaded"),
    title: "Loaded On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("IsActive"),
    title: "Active",
  },
  {
    field: nameOf("AdditionalInformation"),
    title: "Additional Information",
  },
];
