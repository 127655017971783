import { CurrentLookupValues } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/pick-event-closure/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<CurrentLookupValues>();
export const colPickEvent: IColumnFields[] = [
  {
    field: nameOf("Description"),
    title: "Name",
  },
];
