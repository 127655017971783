import { ePathLevel } from "@common/stores/products/enum";
import { useCommonProductStore } from "@common/stores/products/store";
import { getPathByLevel } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { ItemDropdownTreeNew } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/components/item-drop-down-tree/_index";
import { IDropDownItem } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/model";
import {
  convertMenuToTreeData,
  expandedState,
  getTreeValueByPathLevel,
  processTreeData,
} from "@components/cc-list-view-action-bar/components/nav-dropdown-new/util";
import {
  DropDownList,
  DropDownListChangeEvent,
  DropDownTree,
  DropDownTreeChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./_index.scss";

const nameOfDropDown = nameOfFactory<IDropDownItem>();
const treeConfig = {
  dataItemKey: nameOfDropDown("key"),
  selectField: nameOfDropDown("selected"),
  expandField: nameOfDropDown("expanded"),
  subItemsField: nameOfDropDown("children"),
  textField: nameOfDropDown("name"),
};

interface ICCActionBarNavDropdownNewProps {
  startLever?: ePathLevel;
  disabled?: boolean;
}
export const CCActionBarNavDropdownNew = observer(
  ({
    startLever = ePathLevel.Product,
    disabled,
  }: ICCActionBarNavDropdownNewProps) => {
    const history = useHistory();
    const { allMenu } = useCommonProductStore();
    const { pathname, search } = useLocation();

    const fullPathname = `${pathname}${search}`;

    const [dropDown1Data, setDropDown1Data] = useState<IDropDownItem[]>([]);
    const [dropDown1Value, setDropDown1Value] = useState<IDropDownItem | null>(
      null
    );

    const [isOpenDropDown2, setIsOpenDropDown2] = useState(false);
    const [dropDown2Data, setDropDown2Data] = useState<IDropDownItem[]>([]);
    const [dropDown2Value, setDropDown2Value] = useState<IDropDownItem | null>(
      null
    );
    const [initialDropDown2Expanded, setInitialDropDown2Expanded] = useState<
      string[]
    >([]);
    const [dropDown2Expanded, setDropDown2Expanded] = useState<string[]>([]);

    // Handle Function
    const onDropDown1Change = useCallback(
      (event: DropDownListChangeEvent) => {
        const value = event.value as IDropDownItem;
        setDropDown1Value(value);
        history.push(value.path ?? "/");
      },
      [history]
    );

    const onDropDown2ExpandChange = useCallback(
      (event: any) =>
        setDropDown2Expanded(expandedState(event.item, dropDown2Expanded)),
      [dropDown2Expanded]
    );

    const onDropDown2Change = useCallback(
      (event: DropDownTreeChangeEvent) => {
        const value = event.value as IDropDownItem;
        if (!value.children || !value.children[0]) {
          setDropDown2Value(value);
          history.push(value.path ?? "/");
        }
        onDropDown2ExpandChange({ item: value });
      },
      [onDropDown2ExpandChange, history]
    );

    // Memo data
    const treeDataDropDown2 = useMemo(
      () =>
        processTreeData(dropDown2Data, {
          expanded: dropDown2Expanded,
          value: dropDown2Value,
        }),
      [dropDown2Data, dropDown2Expanded, dropDown2Value]
    );

    //Effect
    useEffect(() => {
      //Convert menu to tree data
      const productMenu = allMenu.find(
        (product) => product.path === getPathByLevel(pathname, startLever)
      );
      const dataConvert = productMenu
        ? convertMenuToTreeData(productMenu, undefined, startLever + 2)
        : undefined;
      if (!dataConvert) return;

      setDropDown1Data(dataConvert.children ?? []);

      //Find Dropdown 1 value
      const valueDropDownFirst =
        dataConvert.children?.find(
          (item) => item.key === getPathByLevel(pathname, startLever + 1)
        ) ?? null;

      setDropDown1Value(valueDropDownFirst);

      if (valueDropDownFirst) {
        //Set Dropdown 2 Data
        let dataDropDownTree = valueDropDownFirst.children;
        setDropDown2Data(dataDropDownTree ?? []);

        //Get Dropdown 2 Value and Expanded keys
        const { value, expanded } = getTreeValueByPathLevel(
          dataDropDownTree ?? [],
          fullPathname,
          startLever + 2
        );
        setDropDown2Value(value ? { ...value, name: value.valueName } : null);
        setInitialDropDown2Expanded(expanded);
        setDropDown2Expanded(expanded);
      }
    }, [allMenu, fullPathname, pathname, startLever]);
    const dropDownTreeWrap = useRef<HTMLDivElement>(null);
    const dropDownListWrap = useRef<HTMLDivElement>(null);
    return (
      <div className="cc-action-bar-nav-dropdowns">
        <div ref={dropDownListWrap} className="cc-drop-down-list-wrap">
          <DropDownList
            data={dropDown1Data}
            textField={nameOfDropDown("name")}
            onChange={onDropDown1Change}
            value={dropDown1Value}
            className="cc-navbar-dropdown"
            popupSettings={{
              popupClass: "cc-drop-down-list-wrap",
              appendTo: dropDownListWrap.current ?? undefined,
            }}
          />
        </div>
        <div ref={dropDownTreeWrap} className="cc-drop-down-tree-wrap">
          <DropDownTree
            {...treeConfig}
            data={treeDataDropDown2 ?? []}
            value={dropDown2Value}
            opened={isOpenDropDown2}
            onOpen={() => {
              setIsOpenDropDown2(true);
              setDropDown2Expanded(initialDropDown2Expanded);
            }}
            onBlur={() => {
              setIsOpenDropDown2(false);
            }}
            item={ItemDropdownTreeNew}
            onChange={onDropDown2Change}
            className="cc-navbar-dropdown-tree"
            onExpandChange={onDropDown2ExpandChange}
            popupSettings={{
              popupClass: "cc-popup-navbar-dropdown-tree",
              appendTo: dropDownTreeWrap.current ?? undefined,
            }}
          />
        </div>
      </div>
    );
  }
);
