import { AuditHistoryDetailTab } from "@app/products/property/audit-history/list/components/reference-sidebar/detail";
import {
  PROPERTY_AUDIT_HISTORY_ROUTE,
  PROPERTY_AUDIT_HISTORY_URL,
} from "@app/products/property/audit-history/list/constant";
import { VO_AuditHistory } from "@app/products/property/audit-history/list/model";
import { useAuditHistoryListStore } from "@app/products/property/audit-history/list/store";
import { AuditHistoryBookmark } from "@app/products/property/audit-history/list/util";
import { auditHistoryRoute } from "@app/products/property/audit-history/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { propertyRoute } from "@app/products/property/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_AuditHistory>();

export default observer(() => {
  //store
  const {
    isLoading,
    responseLoadError,
    reLoadApiConfig,
    loadViewConfigurationAuditList,
    columnConfigAuditList,
  } = useAuditHistoryListStore();

  useEffectOnce(() => {
    loadViewConfigurationAuditList();
  });
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={auditHistoryRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        isDisabledRecordListView
        linkUrl={PROPERTY_AUDIT_HISTORY_ROUTE}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Journal}
        listViewDetail={AuditHistoryBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          AuditHistoryBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <AuditHistoryDetailTab /> },
    ],
  });
  //show Loading
  if (isLoading) return <Loading isLoading isFullScreen />;

  //Show load failed and reload
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          reLoadApiConfig();
        }}
      />
    );
  return (
    <CCProductListView
      columnFields={columnConfigAuditList}
      dataUrl={PROPERTY_AUDIT_HISTORY_URL}
      primaryField={nameOf("Audit_Id")}
    />
  );
});
