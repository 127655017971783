import { SimplePropertyDetailFormElement } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/_index";
import {
  checkEmptySimpleAddressForm,
  checkModifySimpleAddressForm,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/util";
import { Address_BuildAddress } from "@app/products/town-planning/ppr/[id]/model";
import {
  Address,
  AddressClassification,
} from "@app/products/waste-water/[id]/model";
import {
  getDisplayBuildingUnitNumber,
  getDisplayFloorNumber,
  getDisplayHouseNumber,
  getDisplayStreetName,
} from "@common/utils/formatting";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { isEmpty, isEqual } from "lodash";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

export interface IApplicationDescriptionProps {
  initialData: any;
  onClose: () => void;
  onSubmit: (value: any) => void;
  isLoading?: boolean;
  isLoadingValidate?: boolean;
  setIsLoadingValidate?: React.Dispatch<React.SetStateAction<boolean>>;
  titleHeader?: string;
  isCRMS?: boolean;
  isHaveStreetLookup?: boolean;
}
export const SimplePropertyDetail = ({
  initialData,
  onClose,
  onSubmit,
  titleHeader = "Property Details",
  isLoading,
  isLoadingValidate,
  setIsLoadingValidate,
  isCRMS = false,
  isHaveStreetLookup = false,
}: IApplicationDescriptionProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [originalFormData, setOriginalFormData] = useState<Address>();
  useEffectOnce(() => {
    setOriginalFormData(initialData?.Address);
  });

  const handleOnSubmit = (value: any) => {
    delete value._option;
    const buildAddress: Address_BuildAddress = { ...value };
    buildAddress.bAddressChanged = true;

    if (value.Address) {
      if (checkEmptySimpleAddressForm(buildAddress.Address)) {
        buildAddress.Address.VMAS_Verified = null;
      }
      if (buildAddress.Address.VMAS_Verified) {
        if (!isEqual(originalFormData, buildAddress.Address)) {
          if (originalFormData) {
            buildAddress.Address.VMAS_Verified = originalFormData.VMAS_Verified;
          }
        }
      } else {
        if (checkModifySimpleAddressForm(buildAddress.Address)) {
          buildAddress.Address.VMAS_Verified = false;
        }
      }
      buildAddress.Address.AddressClassification_ENUM =
        AddressClassification.Property;
      buildAddress.Address.UnitNo =
        (!isEmpty(
          getDisplayFloorNumber(buildAddress?.Address?.PropertyAssessment)
        )
          ? `${getDisplayFloorNumber(
              buildAddress?.Address?.PropertyAssessment
            )} `
          : "") +
        getDisplayBuildingUnitNumber(buildAddress?.Address?.PropertyAssessment);
      buildAddress.Address.StreetNo = getDisplayHouseNumber(
        buildAddress?.Address?.PropertyAssessment
      );
      buildAddress.Address.StreetName = getDisplayStreetName(
        buildAddress?.Address?.PropertyAssessment
      );
      buildAddress.Address.Mapshare_Magickey = value.Search?.magicKey;
    }

    onSubmit(buildAddress as Address_BuildAddress);
  };

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={initialData}
      key={JSON.stringify(initialData)}
      render={(formRenderProps: FormRenderProps) => {
        const { valid, onSubmit: onFormSubmit, modified } = formRenderProps;

        return (
          <CCDialog
            maxWidth="60%"
            maxHeight="90%"
            titleHeader={titleHeader}
            onClose={onClose}
            bodyElement={
              <>
                <CCLocalNotification ref={notificationRef} />
                {isLoading ? (
                  <Loading isLoading={isLoading} />
                ) : (
                  <SimplePropertyDetailFormElement
                    formRenderProps={formRenderProps}
                    setOriginalFormData={setOriginalFormData}
                    isCRMS={isCRMS}
                    isHaveStreetLookup={isHaveStreetLookup}
                    setIsLoadingValidate={setIsLoadingValidate}
                  />
                )}
              </>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={onFormSubmit}
                  type="submit"
                  iconClass={
                    !valid ||
                    !modified ||
                    formRenderProps.valueGetter("IsSearchLoading") ||
                    isLoadingValidate
                      ? "fas fa-spinner fa-spin"
                      : ""
                  }
                  disabled={
                    !valid ||
                    !modified ||
                    formRenderProps.valueGetter("IsSearchLoading") ||
                    isLoadingValidate
                  }
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
