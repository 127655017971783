import { CORPORATEAUTHORISATIONTYPE } from "@common/constants/enumerations";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { DBRowState } from "@common/models/baseClassStandard";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { SecurityTemplate } from "@common/pages/settings/security/security-templates/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export interface CorporateAuth {
  Sys_EnterpriseKey: string;
  Sys_ModifiedBy: string;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_DBRowState: DBRowState;
  Sys_TransactionDate: Date;
  CorporateAuth_ID: number;
  IsSystemRecord: boolean;
  AuthorisationName: string;
  AuthorisationDescription: string;
  ProductType_ENUM: PRODUCT_TYPE_NUMBER | null;
  ProductType_Name: string;
  CorporateAuthorisationType_ENUM: CORPORATEAUTHORISATIONTYPE | null;
  CorporateAuthorisationType_Name: string;
  SecurityTemplates: SecurityTemplate[];
  Individual_SendNotification: boolean | null;
  SiteUsersCorporateAuthLink: SiteUser_CorporateAuth_Link[];
  CorporateAuthorisation__SendToGeneralEmailOnly: boolean | null;
  CorporateAuthorisation_GeneralEmail: string;
}

export interface ProductTypeWithCorpAuth {
  ProductType: number;
  ProductName: string;
  CorpAuthorisation: IKeyValuePacket[];
}

export interface CorporateAuthorisationsLovs {
  ProductType: ProductTypeWithCorpAuth[];
}

export interface SiteUser_CorporateAuth_Link {
  CorporateAuth_ID: number;
  SiteUser_ID: number;
  SiteUser_DisplayName: string;
  SiteUser_Email: string;
  SiteUser_Username: string;
  SendNotification: boolean | null;
}

export interface Svc_PickSiteUser {
  ID: number;
  Name: string;
  Position: string;
  Email: string;
  Phone: string;
}

export interface ISecurityCorporateAuthNotification {
  notification: IAppNotificationItemAddProps[];
}

export enum CorporateAuthSubmitActions {
  Save = "Save",
  New = "New",
}

export enum CORPORATE_AUTH_DELETE_ITEMS {
  SECURITY_TEMPLATES = 1,
  SITE_USERS = 2,
}

export interface SiteUser_CorporateAuth_Link {
  CorporateAuth_ID: number;
  SiteUser_ID: number;
  SiteUser_DisplayName: string;
  SiteUser_Email: string;
  SiteUser_Username: string;
  SendNotification: boolean | null;
}
