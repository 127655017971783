import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NewTransactionDialogStore {
  private _isLoadingOnDialog: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoadingOnDialog(): boolean {
    return this._isLoadingOnDialog;
  }
  setIsLoadingOnDialog = (isLoadingOnDialog: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialog = isLoadingOnDialog;
    });
  };
}

const newTransactionDialogStoreContext = createContext(
  new NewTransactionDialogStore()
);
export const useNewTransactionDialogStoreContext = () => {
  return useContext(newTransactionDialogStoreContext);
};
