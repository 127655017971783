import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import React from "react";
import mockListContactReference from "./mock";

export const ContactReference = () => {
  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={<ProductReferenceHeading title={"Amounts Owing:"} primary />}
        >
          <ProductReferenceBody>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Overdue:"}
                value={formatDisplayValue(
                  mockListContactReference.Overdue,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />

              <ProductReferenceRow
                title={"Due + overdue:"}
                value={formatDisplayValue(
                  mockListContactReference.DueOverdue,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />
              <ProductReferenceRow
                title={"Due:"}
                value={formatDisplayValue(
                  mockListContactReference.Due,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />

              <ProductReferenceRow
                title={"Assessment Balance Owing:"}
                value={formatDisplayValue(
                  mockListContactReference.AssessmentBalanceOwing,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />
            </ProductReferenceBlock>
          </ProductReferenceBody>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
};
