import { DTO_LOV } from "@common/models/odataResponse";
import { IConfirmation } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirmation/model";
import { getWorkflowSendBackReasons } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/api";
import { DTO_SendBack_LOVs } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/model";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { APIResponseError } from "@common/apis/model";
import { useState } from "react";
import { isSuccessResponse } from "@common/apis/util";
import { useEffectOnce } from "react-use";
import { ConfirmationDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirmation/_index";
import React from "react";

export interface ISendBackDialogProps {
  onClose: () => void;
  onSubmitData: (data: IConfirmation) => void;
  description?: string;
  dataLOVsReason?: DTO_LOV[];
  isLoadingSendData?: boolean;
  notifications?: IAppNotificationItem[];
}

export const SendBackDialog = (props: ISendBackDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sendBackLOVs, setSendBackLOVs] = useState<DTO_SendBack_LOVs>();
  const [responseLoadError, setResponseError] = useState<
    APIResponseError | undefined
  >();

  const loadSendBackDataLOVs = async () => {
    setIsLoading(true);
    const response = await getWorkflowSendBackReasons();
    let errorResponse;
    if (isSuccessResponse(response)) {
      setSendBackLOVs(response.data);
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    setResponseError(errorResponse);
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadSendBackDataLOVs();
  });

  return (
    <ConfirmationDialog
      {...props}
      titleHeader="Confirm Send Back"
      subject="Are you sure you want to send back?"
      customLabelReason="Send Back Reason"
      dataLOVsReason={sendBackLOVs?.ReasonForSendBack ?? []}
      isLoading={isLoading}
      responseLoadError={responseLoadError}
      onReload={() => loadSendBackDataLOVs()}
    />
  );
};
