import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { getDocumentTabDetailsById } from "@common/pages/settings/lookups/documents/_id/components/reference-sidebar/detail/api";
import { DocumentTemplateTabDetails } from "@common/pages/settings/lookups/documents/_id/components/reference-sidebar/detail/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class LookupDocumentDetailsStore {
  private _lookupDocumentDetails?: DocumentTemplateTabDetails = undefined;
  private _responseLoadError?: APIResponseError = undefined;
  private _isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get lookupDocumentDetails() {
    return toJS(this._lookupDocumentDetails);
  }
  setLookupDocumentDetails = (
    lookupDocumentDetails: DocumentTemplateTabDetails | undefined
  ) => {
    runInAction(() => {
      this._lookupDocumentDetails = lookupDocumentDetails;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._lookupDocumentDetails = undefined;
      this._isLoading = false;
    });
  };

  loadLookupDocumentDetails = async (documentId: number) => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    const response = await getDocumentTabDetailsById(documentId);
    if (isSuccessResponse(response)) {
      this.setLookupDocumentDetails(response.data);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };
}
const LookupDocumentDetailsStoreContext = createContext(
  new LookupDocumentDetailsStore()
);
export const useLookupDocumentDetailsStore = () => {
  return useContext(LookupDocumentDetailsStoreContext);
};
