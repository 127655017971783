import { MarkAsCompleteDialog } from "@app/products/property/fire-prevention/current/record-work-complete/components/dialogs/mark-as-complete/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const MarkAsCompleteButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { gridSelectedIds, gridSelectedRows, setGridData, gridData } =
    useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();

  const isDisabled = useMemo(() => {
    if (gridSelectedRows.length === 1) {
      return gridSelectedRows[0].HasBeenCompleted;
    } else {
      return true;
    }
  }, [gridSelectedRows]);

  return (
    <>
      <CCNavButton
        title="Mark as Complete"
        onClick={() => setIsShowDialog(true)}
        disabled={isDisabled}
      />

      {isShowDialog && (
        <MarkAsCompleteDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={(data) => {
            const workOrderId = gridSelectedIds[0];
            const newData = gridData.map((item) => {
              if (item.WorkOrderId === workOrderId)
                item.HasBeenCompleted = true;
              return { ...item };
            });
            setGridData(newData);
            setIsShowDialog(false);
            pushNotification({
              title: `Contract completion date updated successfully`,
              type: "success",
            });
          }}
        />
      )}
    </>
  );
});
