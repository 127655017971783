import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { ApplicationSettingText } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-text-input/_index";
import { SwitchSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/switch/_index";
import { ICommonFormProps } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-application/_index";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Field } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfLookupItem = nameOfFactory<ILookupItem>();

export const PPARSSettingsForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { isEditing } = useSettingPPRManageStore();
    const { getConfigDataField } = useSettingPPRStore();

    //Get setting fields
    const fieldUsePPARS = getConfigDataField(ECorporateSettingsField.TP_PPARS);
    const fieldPlanningScheme = getConfigDataField(
      ECorporateSettingsField.TP_PPARSPlanningScheme
    );
    const fieldRespAuthority = getConfigDataField(
      ECorporateSettingsField.TP_PPARSRespAuth
    );
    const fieldURLforPosting = getConfigDataField(
      ECorporateSettingsField.TP_PPARSPostingURL
    );
    const fieldSchemaURL = getConfigDataField(
      ECorporateSettingsField.TP_PPARSSchemaURL
    );
    const fieldUsername = getConfigDataField(
      ECorporateSettingsField.TP_PPARSUsername
    );
    const fieldPassword = getConfigDataField(
      ECorporateSettingsField.TP_PPARSPassword
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <SwitchSettings
            data={fieldUsePPARS}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          {fieldPlanningScheme && (
            <div className="cc-field">
              <CCLabel
                title={fieldPlanningScheme?.Title ?? ""}
                isMandatory={fieldPlanningScheme.IsMandatory}
              />
              <Field
                name={fieldPlanningScheme?.FieldName}
                component={CCSearchComboBox}
                data={fieldPlanningScheme?.LookupItems}
                textField={nameOfLookupItem("Name")}
                dataItemKey={nameOfLookupItem("Id")}
                disabled={!isEditing}
                isUseDefaultOnchange
                validator={requiredValidator}
              />
            </div>
          )}
          {fieldRespAuthority && (
            <div className="cc-field">
              <CCLabel
                title={fieldRespAuthority?.Title ?? ""}
                isMandatory={fieldRespAuthority.IsMandatory}
              />
              <Field
                name={fieldRespAuthority?.FieldName}
                component={CCSearchComboBox}
                data={fieldRespAuthority?.LookupItems}
                textField={nameOfLookupItem("Name")}
                dataItemKey={nameOfLookupItem("Id")}
                disabled={!isEditing}
                isUseDefaultOnchange
                validator={requiredValidator}
              />
            </div>
          )}
        </div>
        <ApplicationSettingText
          enumParam={ECorporateSettingsField.TP_PPARS_WorkingDirectory}
        />
        <div className="cc-form-cols-3">
          {fieldURLforPosting && (
            <div className="cc-field">
              <CCLabel
                title={fieldURLforPosting?.Title ?? ""}
                isMandatory={fieldURLforPosting.IsMandatory}
              />
              <Field
                name={fieldURLforPosting?.FieldName}
                component={CCInput}
                disabled={!isEditing}
              />
            </div>
          )}
          {fieldSchemaURL && (
            <div className="cc-field">
              <CCLabel
                title={fieldSchemaURL?.Title ?? ""}
                isMandatory={fieldSchemaURL.IsMandatory}
              />
              <Field
                name={fieldSchemaURL?.FieldName}
                component={CCInput}
                disabled={!isEditing}
              />
            </div>
          )}
          {fieldUsername && (
            <div className="cc-field">
              <CCLabel
                title={fieldUsername?.Title ?? ""}
                isMandatory={fieldUsername.IsMandatory}
              />
              <Field
                name={fieldUsername?.FieldName}
                component={CCInput}
                disabled={!isEditing}
              />
            </div>
          )}
          {fieldPassword && (
            <div className="cc-field">
              <CCLabel
                title={fieldPassword?.Title ?? ""}
                isMandatory={fieldPassword.IsMandatory}
              />
              <Field
                name={fieldPassword?.FieldName}
                component={CCInput}
                disabled={!isEditing}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
