import { DynamicQuestionListDetail } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/model";
import { DECIMAL_FORMAT } from "@common/constants/common-format";
import { SortIndexCell } from "@common/pages/settings/lookups/dynamic-lists/_id/components/child-screens/general/components/sort-index/_index";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DynamicQuestionListDetail>();

export const dynamicQuestionListGridColumn = (
  setCheckListQuestionId: (checkListQuestionId?: number | undefined) => void,
  setIsShowCheckListQuestionDialog: (
    isShowCheckListQuestionDialog: boolean
  ) => void,
  isInactive: boolean
): IColumnFields[] => {
  return [
    {
      field: nameOf("Question"),
      title: "Question",
      isGrow: true,
      handleOnClick: isInactive
        ? undefined
        : (dataItem: DynamicQuestionListDetail) => {
            setCheckListQuestionId(dataItem.ChecklistQuestion_ID);
            setIsShowCheckListQuestionDialog(true);
          },
    },
    {
      field: "QuestionType",
      title: "Type",
    },
    {
      field: nameOf("SortIndex"),
      title: "Sort Order",
      editable: true,
      format: DECIMAL_FORMAT.DECIMAL_2_DIGIT,
      editCell: SortIndexCell,
    },
  ];
};
