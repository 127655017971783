import {
  getFromDate,
  getToDate,
} from "@app/products/ams/api/helpers/date-helper";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { DTO_PIC_Swine } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { VO_PIC } from "@app/products/property/pic/list/model";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { isAfter } from "date-fns";
import { isEmpty } from "lodash";

export const getBookmarkDisplayName = (selectedRow: VO_PIC) => {
  const dynamicDisplayName = selectedRow.PIC_Id
    ? `- ${selectedRow.PIC_Id}`
    : "";
  return `Property - PIC ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: VO_PIC) => {
  return getDisplayTextWithDashes([
    selectedRow.PIC_Number,
    selectedRow.PropertyAddress,
    selectedRow.PropertyLocality,
  ]);
};

export const getBookmarkListViewDetail = () => {
  return "Property - PIC - List";
};

export const picDetailsValidator = (value: any): string | undefined => {
  let formError: any = {};

  const gisReferences = value?.GisReferences ?? [];
  const isGISError = gisReferences.some((item: DTO_GIS) => {
    const fromDate = item?.Effective_From
      ? getFromDate(item?.Effective_From)
      : null;
    const toDate = item?.Effective_To ? getToDate(item?.Effective_To) : null;
    return fromDate && toDate && isAfter(fromDate, toDate);
  });
  if (isGISError) {
    formError.GISReferences = `"To Date" must be greater than or equal to "From Date"`;
  }

  const swines = value?.Swines ?? [];
  const isSwineError = swines.some((item: DTO_PIC_Swine) => {
    const fromDate = item?.Effective_From
      ? getFromDate(item?.Effective_From)
      : null;
    const toDate = item?.Effective_To ? getToDate(item?.Effective_To) : null;
    return fromDate && toDate && isAfter(fromDate, toDate);
  });
  if (isSwineError) {
    formError.Swines = `"To Date" must be greater than or equal to "From Date"`;
  }

  if (isEmpty(formError)) return undefined;
  return JSON.stringify(formError);
};
