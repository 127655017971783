export enum AssetsSystem {
  None = 0,
  Custom = 1,
  Confirm = 2,
  RAMM = 3,
  AssetMaster = 4,
  Assetic = 5,
  Reflect = 6,
  Conquest = 7,
}

export enum WorkOrderSystem {
  None = 0,
  CAMSS = 1,
  CommunityAssets = 2,
}
