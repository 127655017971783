import { AddToJournalDialog } from "@app/products/property/journals/not-yet-in-journal/components/action-bar/dialog/add-to-journal/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const AddToJournalButton = observer(() => {
  const [isShowAddToJournalDialog, setIsShowAddToJournalDialog] =
    useState(false);
  const { gridSelectedRows } = useCCProductListViewStore();
  const transactions = gridSelectedRows?.map((row) => {
    return { Id: row?.Transaction_Id };
  });

  return (
    <>
      <CCNavButton
        title="Add to journal"
        onClick={() => setIsShowAddToJournalDialog(true)}
        disabled={gridSelectedRows?.length === 0}
      />
      {isShowAddToJournalDialog && (
        <AddToJournalDialog
          onClose={() => setIsShowAddToJournalDialog(false)}
          transactions={transactions}
        />
      )}
    </>
  );
});
