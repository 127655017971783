import { CONTACT_ROUTE } from "@app/core/contacts/_id/constant";
import { FURTHER_INFO_ROUTE } from "@app/core/further-info/[id]/constant";
import { INSPECTIONS_MANAGE_ROUTE } from "@app/core/inspections/[id]/constant";
import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { CSL_ROUTE } from "@app/products/csl/[id]/constant";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { LocalLawsRoute } from "@app/products/local-laws/[id]/constant";
import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import { TOWN_PLANNING_PSAR_ROUTE } from "@app/products/town-planning/ppr/psa-referrals/_id/constant";
import { SYSTEM_ADMIN_APPLICATION_CATEGORIES_ROUTE } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/constant";
import { SYSTEM_ADMIN_REGION_ROUTE } from "@app/products/town-planning/ppr/system-admin/regions/[id]/constant";
import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ACTIONS_ROUTE } from "@common/pages/actions/[id]/constant";
import { APPEAL_HEARING_ROUTE } from "@common/pages/appeal/_id/appeal-hearing/_id/constant";
import { APPEAL_ROUTE } from "@common/pages/appeal/_id/constant";
import { SETTINGS_COMMUNICATION_QUEUE_ROUTE } from "@common/pages/settings/communication/queue/_id/constant";
import { SETTINGS_COMMUNICATION_TEMPLATE_ROUTE } from "@common/pages/settings/communication/template/_id/constant";
import { SETTINGS_LOOKUPS_ACTION_TYPES_ROUTE } from "@common/pages/settings/lookups/action-types/_id/constant";
import { SETTINGS_LOOKUPS_DOCUMENTS_ROUTE } from "@common/pages/settings/lookups/documents/_id/constant";
import { SETTINGS_LOOKUPS_KEYWORDS_ROUTE } from "@common/pages/settings/lookups/keywords/_id/constant";
import { SETTING_LOCAL_GOVERNMENT_ROUTE } from "@common/pages/settings/lookups/local-government/constants";
import { LOOKUP_REGION_ROUTE } from "@common/pages/settings/lookups/regions/constant";
import { SETTINGS_LOOKUPS_STATE_ROADS_ROUTE } from "@common/pages/settings/lookups/state-roads/_id/constant";
import { SETTINGS_SECURITY_CORPORATE_AUTH_ROUTE } from "@common/pages/settings/security/corporate-authorisations/constant";
import { SETTINGS_SECURITY_ORG_STRUCTURE_ROUTE } from "@common/pages/settings/security/org-structure/_id/constant";
import { SECURITY_TEMPLATE_ROUTE } from "@common/pages/settings/security/security-templates/constant";
import { SITE_USER_ROUTE } from "@common/pages/settings/security/site-users/_id/constant";
export const getPageLinkForRelatedTab = (
  recordType: RECORDTYPE,
  id: number,
  relationShipType?: number,
  isEnablePPR?: boolean
) => {
  switch (recordType) {
    //Core
    case RECORDTYPE.CORE_ActionType:
      return `${SETTINGS_LOOKUPS_ACTION_TYPES_ROUTE}/${id}`;
    case RECORDTYPE.CORE_SecurityTemplate:
      return `${SECURITY_TEMPLATE_ROUTE}/${id}`;
    case RECORDTYPE.CORE_CorporateSetting:
      return `${SETTINGS_SECURITY_CORPORATE_AUTH_ROUTE}/${id}`;
    // TODO: add when have Advertising manage page
    /* case RECORDTYPE.CORE_Advertising:
      return `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${id}`; */
    case RECORDTYPE.CORE_AppealHearing:
      return `${APPEAL_HEARING_ROUTE}/${id}`;
    case RECORDTYPE.CORE_Contact:
      return `${CONTACT_ROUTE}/${id}`;
    case RECORDTYPE.CORE_FurtherInformationRequest:
      return `${FURTHER_INFO_ROUTE}/${id}`;
    case RECORDTYPE.CORE_Appeal:
      return `${APPEAL_ROUTE}/${id}`;
    case RECORDTYPE.CORE_Document:
      return `${SETTINGS_LOOKUPS_DOCUMENTS_ROUTE}/${id}`;
    case RECORDTYPE.CORE_Keyword:
      return `${SETTINGS_LOOKUPS_KEYWORDS_ROUTE}/${id}`;
    case RECORDTYPE.CORE_Action:
      return `${ACTIONS_ROUTE}/${id}`;
    case RECORDTYPE.CORE_CommunicationQueue:
      return `${SETTINGS_COMMUNICATION_QUEUE_ROUTE}/${id}`;
    case RECORDTYPE.CORE_Council:
      return `${SETTING_LOCAL_GOVERNMENT_ROUTE}/${id}`;
    case RECORDTYPE.CORE_CommunicationTemplate:
      return `${SETTINGS_COMMUNICATION_TEMPLATE_ROUTE}/${id}`;
    case RECORDTYPE.CORE_StateRoad:
      return `${SETTINGS_LOOKUPS_STATE_ROADS_ROUTE}/${id}`;
    case RECORDTYPE.CORE_Region:
      return `${LOOKUP_REGION_ROUTE}/${id}`;
    case RECORDTYPE.CORE_OrgStructure:
      return `${SETTINGS_SECURITY_ORG_STRUCTURE_ROUTE}/${id}`;
    case RECORDTYPE.CORE_SiteUser_Credential:
      return `${SITE_USER_ROUTE}/${id}`;
    //HealthManager
    case RECORDTYPE.HealthManager_Premises:
      return `${PREMISES_MANAGE_ROUTE}/${id}`;
    //PPR
    case RECORDTYPE.TP_Application:
      return `${TOWN_PLANNING_ROUTE}/${id}`;
    case RECORDTYPE.TP_ApplicationCategory:
      return `${SYSTEM_ADMIN_APPLICATION_CATEGORIES_ROUTE}/${id}`;
    // TP_ConditionReference
    // TP_ApplicationCondition
    // TP_Application_Contact_MAILMERGEONLY
    // TP_CalculatedFeeSetup
    // TP_Subdivision
    case RECORDTYPE.TP_BuildingApplication:
      return `${BUILDING_MANAGE_ROUTE}/${id}`;
    // TP_ConditionReference
    // TP_ApplicationCondition
    // TP_Application_Contact_MAILMERGEONLY
    // TP_CalculatedFeeSetup
    // TP_Subdivision
    // TP_DevAppsApplication
    // TP_FeeSchedule
    case RECORDTYPE.TP_PSA_Application:
      return `${TOWN_PLANNING_PSAR_ROUTE}/${id}`;
    // TP_PrescribeEvents
    // TP_ExtensionOfTimeActivity
    // TP_SecondaryConsentActivity
    // TP_ObjectionActivity
    // TP_EnquiryActivity
    // TP_OtherDocumentsActivity
    // TP_FurtherInformationActivity
    // TP_EplanningFee
    // TP_PermitType
    // TP_OtherApplication:
    // TP_SPEAR_Application
    // TP_PPRResponsibleAuthority
    case RECORDTYPE.TP_PPRApplication:
      return `${TOWN_PLANNING_PPR_ROUTE}/${id}`;
    case RECORDTYPE.TP_PPRPlanningRegion:
      return `${SYSTEM_ADMIN_REGION_ROUTE}/${id}`;
    // TP_Application_AssessmentDetails
    // TP_PNFApplication
    // TP_PNFDueDiligence
    // TP_PNFOperations
    // TP_PNFTSData
    // TP_PNF_MailMergeCategory
    // TP_PNFFieldObservation
    // TP_PNFDetails
    // TP_PNFAssessement
    // TP_PNFApplication_Lots
    // TP_PNFApplication_LGAs
    // TP_PNFApplication_Regions
    // TP_EPlanning_ApplicationCategory
    // TP_EPlanning_NatureOfWork
    // TP_EPlanning_DocumentChecklist
    // TP_LMApplication
    // TP_LMRegister
    // TP_BUILD_Portal_DocumentChecklist
    case RECORDTYPE.Animals_Registration:
      return `${ANIMALS_ROUTE}/${id}`;
    //TODO: need to check again
    case RECORDTYPE.LLP_Permit:
      return `${LocalLawsRoute.LOCALLAWS_PERMITS_REGISTER}/${id}`;
    case RECORDTYPE.WW_System:
      return `${WASTE_WATER_ROUTE}/${id}`;
    case RECORDTYPE.CSM_Licence:
      return `${CSL_ROUTE}/${id}`;
    case RECORDTYPE.CUSTOMERSERVICE_Event:
      return `${CRMS_ROUTE}/${id}`;
    case RECORDTYPE.CORE_Inspection:
      return `${INSPECTIONS_MANAGE_ROUTE}/${id}`;
    default:
      return undefined;
  }
};
