import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colLevySumary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/components/levy-summary/config";
import { fnt_ChargeRun_LevySummary_AllResult } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/components/levy-summary/model";
import { eLevySummaryTypes } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/model";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { isNil } from "lodash";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface ILevySummaryProps {
  data: any;
  groupTypeId: eLevySummaryTypes;
}
const nameOf = nameOfFactory<fnt_ChargeRun_LevySummary_AllResult>();
export const LevySummary = ({ data, groupTypeId }: ILevySummaryProps) => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedColumns, setProcessedColumns] =
    useState<IColumnFields[]>(colLevySumary);

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Charge_Run_LevySummary,
      colLevySumary
    );
    if (Array.isArray(response)) {
      setProcessedColumns(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );
  if (isLoading) return <Loading isLoading />;

  if (isNil(data.dataItem?.GroupId)) return <NoData />;

  return (
    <div className="cc-grid-charge-run-levy-sumary-detail">
      <CCGrid
        dataUrl={`odata/property/internal/chargerunlevysummarybygroupregister/GetLevySummaryByGroup(key=${id},view=${groupTypeId},groupId=${data.dataItem?.GroupId})?$count=true&`}
        columnFields={processedColumns}
        primaryField={nameOf("Levy_Id")}
        itemPerPage={10}
      />
    </div>
  );
};
