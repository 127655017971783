import { ICCRoute } from "@common/constants/ICCRoute";
import { treeConfig } from "@components/cc-list-view-action-bar/components/nav-dropdown/config";
import { IProductGroup1 } from "@components/cc-list-view-action-bar/components/nav-dropdown/model";
import {
  expandedState,
  getFirstChildTree,
  getPropertyTreeStructure,
  processTreeData,
} from "@components/cc-list-view-action-bar/components/nav-dropdown/util";
import {
  DropDownList,
  DropDownTree,
  DropDownTreeChangeEvent,
  DropDownTreeExpandEvent,
} from "@progress/kendo-react-dropdowns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./_index.scss";
import ItemDropdownTree from "./components/item-drop-down-tree/_index";

interface ICCActionBarNavDropdownProps {
  category: string;
  product: string;
  route: ICCRoute;
}
/**
 * @deprecated Use CCActionBarNavDropdownNew instead
 */
export const CCActionBarNavDropdown = ({
  category,
  product,
  route,
}: ICCActionBarNavDropdownProps) => {
  const location = useLocation<any>();
  const history: any = useHistory();

  const [productGroup1Data, setProductGroup1Data] = useState<IProductGroup1[]>(
    []
  );
  const [productGroup1Value, setProductGroup1Value] =
    useState<IProductGroup1>();
  const [openTreeDropDown, setOpenTreeDropdown] = useState(false);
  const [dropdownTreeState, setDropdownTreeState] = useState<any>();

  const [expandedTree, setExpandedTree] = useState<string[]>([]);
  const [expandedTreeDefault, setExpandedTreeDefault] = useState<string[]>([]);

  const [structureGroup2, setStructureGroup2] = useState<any>({
    tree: null,
    list: [],
  });

  const dataDropdownTree = useMemo(
    () =>
      processTreeData(
        structureGroup2.tree ? structureGroup2.tree : [],
        { expanded: expandedTree, value: dropdownTreeState },
        treeConfig
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expandedTree, dropdownTreeState, structureGroup2.tree]
  );

  const onExpandTreeChange = useCallback(
    (event: DropDownTreeExpandEvent) =>
      setExpandedTree(
        expandedState(event.item, treeConfig.dataItemKey, expandedTree)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expandedTree]
  );

  useEffect(() => {
    if (route.children && route.children[0]) {
      const propertyStructure: any = getPropertyTreeStructure(
        route,
        {
          [treeConfig.displayName]: null,
          [treeConfig.dataItemKey]: null,
        },
        treeConfig
      );

      setProductGroup1Data(
        propertyStructure.tree[treeConfig.subItemsField] &&
          propertyStructure.tree[treeConfig.subItemsField].map((child: any) => {
            return {
              path:
                getFirstChildTree(child, treeConfig)[treeConfig.dataItemKey] ||
                "/",
              key: child[treeConfig.dataItemKey].split("/")[1] || "",
              value: child[treeConfig.displayName].split(" > ")[1] || "",
            };
          })
      );
      const group1Router =
        route.children &&
        route.children.filter((router) => router.path === category)[0];
      if (!group1Router || !group1Router.children) return;
      let newList: any[] = [];
      const newTree = group1Router.children
        .map((router) => {
          const newStructure = getPropertyTreeStructure(
            router,
            {
              [treeConfig.displayName]: null,
              [treeConfig.dataItemKey]: "/" + product + "/" + category,
            },
            treeConfig
          );
          if (!newStructure) return undefined;
          newList = [...newList, ...newStructure.list];
          return newStructure.tree;
        })
        .filter((tree) => tree !== undefined);
      //Expanded all
      /*  setExpandedTree(newList.map((tree) => tree.path));
      setExpandedTreeDefault(
        newList.map((tree) => tree.path)
      ); */
      //Collapse all
      setExpandedTree([]);
      setExpandedTreeDefault([]);
      setStructureGroup2({ list: newList, tree: newTree });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, category]);

  useEffect(() => {
    let path = location.pathname;

    const activeDropdownTreeItem = structureGroup2.list.find(
      (item: any) => item[treeConfig.dataItemKey] === path
    );
    if (activeDropdownTreeItem)
      setDropdownTreeState({
        ...activeDropdownTreeItem,
        value: activeDropdownTreeItem[treeConfig.displayName],
      });
    if (productGroup1Data) {
      const activeDropdownItem = productGroup1Data.find(
        (item: any) => category === item.key
      );
      if (activeDropdownItem) setProductGroup1Value(activeDropdownItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, structureGroup2.list, category, productGroup1Data]);

  const handleProductGroup1Change = (event: any) => {
    history.push("/" + event.value.path);
  };

  const handleChangePageView = useCallback(
    (treeItem: any) => {
      setOpenTreeDropdown(false);
      history.push({
        pathname: treeItem[treeConfig.dataItemKey],
        state: {
          ...location.state,
        },
      });
      setDropdownTreeState({
        ...treeItem,
        value: treeItem[treeConfig.displayName],
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, location.state]
  );

  const handleDropDownTreeChange = (event: DropDownTreeChangeEvent) => {
    const newValue = event.value;
    if (!newValue || !newValue[treeConfig.dataItemKey]) return;
    if (
      newValue[treeConfig.subItemsField] &&
      newValue[treeConfig.subItemsField].length
    ) {
      const event: any = {
        item: newValue,
        lever: [],
      };

      onExpandTreeChange(event);
    } else {
      handleChangePageView(newValue);
    }
  };

  /* -------------------- Expanded Item When Select -------------------- */
  useEffect(() => {
    if (!dropdownTreeState) return;
    let clonePath: string = dropdownTreeState.path;
    let newPathArray: any[] = [];
    while (clonePath.split("/").length > 3) {
      newPathArray.push(clonePath);
      clonePath = clonePath.substring(0, clonePath.lastIndexOf("/"));
    }
    setExpandedTree((expandedTree) =>
      Array.from(new Set([...expandedTree, ...newPathArray]))
    );
    setExpandedTreeDefault(newPathArray);
  }, [dropdownTreeState]);

  return (
    <div className="cc-action-bar-nav-dropdowns">
      <DropDownList
        data={productGroup1Data}
        textField="value"
        onChange={handleProductGroup1Change}
        value={productGroup1Value}
        className="cc-navbar-dropdown"
      />
      <DropDownTree
        data={dataDropdownTree}
        value={dropdownTreeState}
        opened={openTreeDropDown}
        onOpen={() => {
          setOpenTreeDropdown(true);
          setExpandedTree(expandedTreeDefault);
        }}
        onBlur={() => {
          setOpenTreeDropdown(false);
        }}
        item={ItemDropdownTree}
        onChange={handleDropDownTreeChange}
        {...treeConfig}
        className="cc-navbar-dropdown-tree"
        onExpandChange={onExpandTreeChange}
      />
    </div>
  );
};
