import { mockSingleBuildingRegistersCertificates } from "@app/products/building/registers/certificates/[id]/mock";
import { IBuildingRegistersCertificatesFormData } from "@app/products/building/registers/certificates/[id]/model";
import { IBuilding } from "@app/products/building/[id]/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";

export const postBuildingRegistersCertificates = async (
  requestBody: IBuilding
) => {
  await sleep(2000);
};
// };

export const putBuildingRegistersCertificates = async (
  requestBody: IBuilding
) => {
  await sleep(2000);
};

export const getBuildingRegistersCertificatesById = async (
  id?: number
): Promise<APIResponse<IBuildingRegistersCertificatesFormData | undefined>> => {
  await sleep(1000);
  return {
    data: {
      ...mockSingleBuildingRegistersCertificates,
      ID: id,
    } as IBuildingRegistersCertificatesFormData,
    status: APIResponseStatus.SUCCESS,
    statusText: "Ok",
    headers: {},
    config: {},
  };
};
