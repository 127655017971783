import { loadGeneralLedgerAccountsBySchemeId } from "@app/products/property/schemes/[id]/components/forms/existed/components/action-bar/buttons/edit-gl-account/api";
import { GeneralLedgerAccountsResponse } from "@app/products/property/schemes/[id]/components/forms/existed/components/action-bar/buttons/edit-gl-account/model";
import { EditGLAccountDialogs } from "@app/products/property/schemes/[id]/components/forms/existed/components/dialogs/edit-gl-account/_index";
import { ISchemesGL } from "@app/products/property/schemes/[id]/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
interface IModifySchemeProps {
  onSave: (value: ISchemesGL[]) => void;
}
export const EditGLAccountButton = ({ onSave }: IModifySchemeProps) => {
  const params: { id: string } = useParams();
  const schemeId = params.id;
  const [generalLedgerAccounts, setGeneralLedgerAccounts] = useState<
    GeneralLedgerAccountsResponse | undefined
  >();

  return (
    <>
      <CCNavButton
        title="Edit GL account"
        onClick={() => {
          loadGeneralLedgerAccountsBySchemeId(schemeId).then((data) => {
            if (!data) return;
            setGeneralLedgerAccounts(data);
          });
        }}
      />

      {generalLedgerAccounts && (
        <EditGLAccountDialogs
          initialForm={generalLedgerAccounts}
          onClose={() => {
            setGeneralLedgerAccounts(undefined);
          }}
          onSave={(data: ISchemesGL[]) => {
            setGeneralLedgerAccounts(undefined);
            if (!data) return;
            onSave(data);
          }}
        />
      )}
    </>
  );
};
