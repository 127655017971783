import { getPPRDetailsTabById } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/detail/api";
import { PPRApplicationTabDetails } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/detail/model";
import { isSuccessResponse } from "@common/apis/util";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class PPRApplicationDetailsStore {
  private _pprApplicationDetails?: PPRApplicationTabDetails = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get pprApplicationDetails() {
    return toJS(this._pprApplicationDetails);
  }
  setPPRApplicationDetails = (
    pprApplicationDetails: PPRApplicationTabDetails | undefined
  ) => {
    runInAction(() => {
      this._pprApplicationDetails = pprApplicationDetails;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._pprApplicationDetails = undefined;
      this._isLoading = false;
    });
  };

  loadPPRApplicationDetails = async (pprId: number) => {
    this.setIsLoading(true);
    const details = await getPPRDetailsTabById(pprId);
    if (isSuccessResponse(details)) {
      this.setPPRApplicationDetails(details.data);
    } else {
      this.setPPRApplicationDetails(undefined);
    }

    this.setIsLoading(false);
  };
}
const PPRApplicationDetailsStoreContext = createContext(
  new PPRApplicationDetailsStore()
);
export const usePPRApplicationDetailsStore = () => {
  return useContext(PPRApplicationDetailsStoreContext);
};
