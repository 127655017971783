import { VO_Comments } from "@app/core/comments/model";
import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Comments>();
export const colComments: IColumnFields[] = [
  { field: nameOf("Comment"), title: "Description" },
  {
    field: nameOf("Title"),
    title: "Title",
  },
  {
    field: nameOf("RecordedDate"),
    title: "Recorded On",
    format: DATETIME_FORMAT.DATETIME,
  },

  {
    field: nameOf("RecordedBy"),
    title: "Recorded By",
  },
  {
    field: nameOf("ID"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
