import { isBase64 } from "@common/utils/common";
import React from "react";
import "./_index.scss";
export interface ICCLogoProps {
  width?: number | string;
  height?: number | string;
  alt: string;
  className?: string;
  src?: string | undefined;
  onLoad?: () => void;
}

export const CCLogo = (props: ICCLogoProps) => {
  const srcImage = isBase64(props.src ?? "")
    ? `data:image/png;base64,${props.src}`
    : props.src;

  return (
    <div className={`cc-logo ${props.className ?? ""}`}>
      <img
        className="cc-logo-image"
        height={props.height}
        width={props.width}
        src={srcImage}
        alt={props.alt}
        loading="lazy"
        onLoad={props.onLoad}
      />
    </div>
  );
};
