import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSFeesExportedServices: IColumnFields[] = [
  {
    field: CRSFieldMapping.AppNo,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  { field: CRSFieldMapping.ChildName, title: "Child Display Name" },
  { field: CRSFieldMapping.REGISTRATION_SERVICE, title: "Service" },
  {
    field: CRSFieldMapping.YearAttending,
    title: "Year Attending",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.ExportService_DateTime,
    title: "Exported Date",
  },
  {
    field: CRSFieldMapping.DebtorNumber,
    title: "Debtor Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.Parent1Name, title: "Parent1 Name" },
  { field: CRSFieldMapping.PARENT2NAME, title: "Parent2 Name" },
  { field: CRSFieldMapping.PARENT1_CONTACTNO, title: "Contact" },
  { field: CRSFieldMapping.PARENT1_Email, title: "Email" },
];
