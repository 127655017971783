import { VO_AuditHistory } from "@app/products/property/audit-history/list/model";
import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_AuditHistory>();

export const colListAuditHistory: IColumnFields[] = [
  {
    field: nameOf("Audit_DateTime"),
    title: "Date/Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Audit_UserId"),
    title: "User ID",
  },
  {
    field: nameOf("AC_Table"),
    title: "Table",
  },
  {
    field: nameOf("AC_Column"),
    title: "Column",
  },
  { field: nameOf("Audit_BeforeX"), title: "Before" },
  { field: nameOf("Audit_AfterX"), title: "After" },
  {
    field: nameOf("Assess_Reference"),
    title: "Assessment (S-S-B-U)",
  },
  { field: nameOf("Parcel_Reference"), title: "Parcel (S-S-B-U)" },
  { field: nameOf("Audit_Primary_Key_IdX"), title: "Key Column 1" },
  { field: nameOf("Audit_Type"), title: "Type" },
  {
    field: nameOf("Audit_Id"),
    title: "Audit ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
