import { IContactODataItem } from "@app/core/contacts/components/contact-accordion/model";
import { CONTACT_ROUTE } from "@app/core/contacts/_id/constant";

import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IContactODataItem>();

export const colContactAccordion: IColumnFields[] = [
  {
    field: nameOf("DisplayName"),
    title: "Name",
    locked: true,
    linkTo: (dataItem: IContactODataItem) =>
      `${CONTACT_ROUTE}/${dataItem.Contact_Id}`,
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("WorkPhone"),
    title: "Work Phone",
  },
  {
    field: nameOf("HomePhone"),
    title: "Home Phone",
  },
  {
    field: nameOf("Mobile"),
    title: "Mobile",
  },
  {
    field: nameOf("PostalAddress"),
    title: "Postal Address",
  },
  {
    field: nameOf("ContactAlert"),
    title: "Contact Alert",
  },
  {
    field: nameOf("Email"),
    title: "Email",
  },
  {
    field: nameOf("ParentDescription"),
    title: "Parent Description",
  },
];
