import { checkSecondaryMode } from "@app/products/hm/premises/[id]/config";
import { HM_PREMISES_REGISTER_GET_INIT_SLIDER } from "@app/products/hm/premises/[id]/constant";
import {
  Premises,
  Premises_Status,
  SecondaryModeCheckField,
  Svc_Premises,
} from "@app/products/hm/premises/[id]/model";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IHMPremisesRegisterButtonProps {
  isDisabled?: boolean;
}
export const HMPremisesRegisterButton = observer(
  ({ isDisabled = false }: IHMPremisesRegisterButtonProps) => {
    const { settings } = useCommonCoreStore();
    const { dataForms, setMiddlewareSubmitFormOptions, submitFormGetData } =
      useFlexibleFormStore();

    const [isLoadingInit, setIsLoadingInit] = useState(false);

    const premiseObj = dataForms?.GeneralForm as Svc_Premises;
    const premiseData = premiseObj?.Premises as Premises;

    // #region Get Setting Value
    const enableSecondaryMode = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_EnableSecondaryMode]
    );
    const sFieldMapping =
      getStringValueSetting(
        settings[ECorporateSettingsField.HealthManager_TechOneFieldMapping]
      ) ?? "";
    // #endRegion Get Setting Value

    // #region Check Secondary Mode
    const checkSecondaryModeRegisterBtn = checkSecondaryMode(
      enableSecondaryMode,
      sFieldMapping,
      SecondaryModeCheckField.BtnRegister
    );
    // #endRegion Check Secondary Mode

    const handleClickButton = async (_event: any, debtorNumber?: string) => {
      setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      const dataFormSubmit = await submitFormGetData("GeneralForm");
      const premises = dataFormSubmit?.Premises as Premises;
      if (isNil(premises)) return;
      if (debtorNumber) {
        premises.DebtorNumber = debtorNumber;
      }

      setIsLoadingInit(true);
      await fetchApiByAlias(HM_PREMISES_REGISTER_GET_INIT_SLIDER, {
        initialData: premises,
      });
      setIsLoadingInit(false);
    };

    return premiseData?.Status_ENUM === Premises_Status.Prelodgement ? (
      <CCNavButton
        title="Register premises"
        onClick={handleClickButton}
        disabled={isDisabled || checkSecondaryModeRegisterBtn || isLoadingInit}
        isLoading={isLoadingInit}
      />
    ) : null;
  }
);
