import { RaiseChargeDialog } from "@app/products/property/registers/list/components/action-bar/form-steps/raise-charge/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { TYPE_NOTIFICATION } from "@components/cc-notification/comp-notification/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const RaiseChargeButton = observer(() => {
  const [showRaiseChargeDialog, setShowRaiseChargeDialog] =
    useState<boolean>(false);
  const { pushNotifications, isEmptyGridSelectedRow } =
    useCCProductListViewStore();

  return (
    <>
      <CCNavButton
        title="Raise Charge"
        onClick={() => setShowRaiseChargeDialog(true)}
        disabled={isEmptyGridSelectedRow}
      />
      {showRaiseChargeDialog && (
        <RaiseChargeDialog
          onSubmit={() => {
            setShowRaiseChargeDialog(false);
            pushNotifications([
              {
                primaryContent: "Charge raised successfully",
                type: TYPE_NOTIFICATION.SUCCESS,
                isAutoClose: true,
              },
            ]);
          }}
          onClose={() => {
            setShowRaiseChargeDialog(false);
          }}
        />
      )}
    </>
  );
});
