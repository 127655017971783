import { ICCRoute } from "@common/constants/ICCRoute";

export const ePlanningDevelopmentsRoute: ICCRoute = {
  path: "e-planning-developments",
  name: "EPlanning - Developments",
  children: [
    {
      path: "new-applications",
      name: "New Applications",
      component: require("./new-applications/_index").default,
    },
    {
      path: "new-submissions",
      name: "New Submissions",
      component: require("./new-submissions/_index").default,
    },
    {
      path: "new-enquiries",
      name: "New Enquiries",
      component: require("./new-enquiries/_index").default,
    },
    {
      path: "new-secondary-consent",
      name: "New Secondary Consent",
      component: require("./new-secondary-consent/_index").default,
    },
    {
      path: "new-extension-of-time",
      name: "New Extension Of Time",
      component: require("./new-extension-of-time/_index").default,
    },
    {
      path: "new-docs-fi",
      name: "New Docs/FI",
      component: require("./new-docs-fi/_index").default,
    },
    {
      path: "audit-history",
      name: "Audit History",
      component: require("./audit-history/_index").default,
    },
    {
      path: "application-categories",
      name: "Application Categories",
      component: require("./application-categories/_index").default,
    },
    {
      path: "nature-of-work",
      name: "Nature Of Work",
      component: require("./nature-of-work/_index").default,
    },
  ],
};
