import {
  mockBuildingRegistersEnquiryRecordsState,
  mockSingleBuildingRegistersEnquiry,
} from "@app/products/building/registers/enquiries/[id]/mock";
import {
  BuildingRegistersEnquiryRecordsState,
  IBuildingRegistersEnquiryFormData,
} from "@app/products/building/registers/enquiries/[id]/model";
import { IBuilding } from "@app/products/building/[id]/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";

export const postBuildingRegistersEnquiry = async (requestBody: IBuilding) => {
  await sleep(2000);
};

export const putBuildingRegistersEnquiry = async (requestBody: IBuilding) => {
  await sleep(2000);
};

export type IBuildingRegistersEnquiryById =
  | [
      APIResponse<IBuildingRegistersEnquiryFormData>,
      APIResponse<BuildingRegistersEnquiryRecordsState>
    ]
  | APIResponse<IBuildingRegistersEnquiryFormData>
  | undefined;

export const getBuildingRegistersEnquiryById = async (
  id: number
): Promise<IBuildingRegistersEnquiryById | undefined> => {
  await sleep(1000);
  return [
    {
      data: {
        ...mockSingleBuildingRegistersEnquiry,
        ID: id,
      } as IBuildingRegistersEnquiryFormData,
      status: APIResponseStatus.SUCCESS,
      statusText: "Ok",
      headers: {},
      config: {},
    },
    {
      data: {
        ...mockBuildingRegistersEnquiryRecordsState,
        ID: id,
      } as BuildingRegistersEnquiryRecordsState,
      status: APIResponseStatus.SUCCESS,
      statusText: "Ok",
      headers: {},
      config: {},
    },
  ];
};
