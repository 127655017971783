import { searchWastewaterLocality } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/locality/api";
import {
  colSearchLocality,
  searchLocalityConfig,
} from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/locality/config";
import { LocalityResult } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/locality/model";
import { IColumnFields } from "@components/cc-grid/model";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { isEqual, isString } from "lodash";
import { observer } from "mobx-react-lite";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";
interface IWastewaterSearchLocalityProps extends ComboBoxProps {
  onChangeLocality?: (value: LocalityResult | null) => void;
}
export const WastewaterSearchLocality = observer(
  (props: IWastewaterSearchLocalityProps) => {
    const refTimeOut = useRef<NodeJS.Timeout | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<LocalityResult[]>([]);
    const [value, setValue] = useState<string | LocalityResult | null>(null);

    const inputDisplayName = () => {
      if (value) {
        if (!isString(value)) {
          return value.Locality;
        }
        return value;
      }
      return "";
    };

    const handleSearch = (event: ComboBoxFilterChangeEvent) => {
      const searchText = event.filter.value;
      if (searchText.length < searchLocalityConfig.minCharacters) return;

      if (refTimeOut.current) clearTimeout(refTimeOut.current);
      refTimeOut.current = setTimeout(() => {
        setIsLoading(true);

        searchWastewaterLocality(event.filter.value).then((result) => {
          setData(result.data || []);
          setIsLoading(false);
        });
      }, searchLocalityConfig.typeSpeed);
    };

    const handleChange = (event: ComboBoxChangeEvent) => {
      const locality = event.value;
      if (!locality) {
        setValue(null);
        if (props.onChange) props.onChange({ ...event, value: null });
        if (props.onChangeLocality) props.onChangeLocality(null);
        return;
      }
      setValue(locality);
      if (props.onChange) props.onChange({ ...event, value: locality });
      if (props.onChangeLocality) props.onChangeLocality(locality);
    };

    useEffectOnce(() => {
      return () => {
        if (refTimeOut.current) clearTimeout(refTimeOut.current);
      };
    });

    useEffect(() => {
      if (!isEqual(props.value, value)) setValue(props.value);
      // eslint-disable-next-line
    }, [props.value]);

    return (
      <ComboBox
        {...props}
        filterable
        suggest
        data={data}
        loading={isLoading}
        onFilterChange={handleSearch}
        itemRender={ItemRender}
        value={inputDisplayName()}
        header={
          <div className="cc-search-header">
            {colSearchLocality.map((col: IColumnFields) => (
              <div key={col.field} style={{ width: col.width }}>
                {col.title}
              </div>
            ))}
          </div>
        }
        onChange={handleChange}
        popupSettings={{ className: "cc-wastewater-search-address" }}
      />
    );
  }
);
const ItemRender = (
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const { dataItem } = itemProps;
  const itemChildren = (
    <div className="cc-search-item">
      {colSearchLocality.map((col: IColumnFields) => (
        <div key={col.field} style={{ width: col.width }}>
          {dataItem[col.field] ?? ""}
        </div>
      ))}
    </div>
  );
  return React.cloneElement(li, li.props, itemChildren);
};
