import { VO_CollectionType } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/model";
import { AssociateCollection } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/component/dialog/associate-collection";
import { colCollectionsAssociate } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/component/dialog/associate-collection/config";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const COLLECTION_TYPE_GRID_ID = getUUID();

export const CollectionFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const nameOfCollection = nameOfFactory<VO_CollectionType>();
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const collections = valueGetter("SelectedCollection") ?? [];
    const collectionSelected = getFieldValue("LocalSelectedCollection") ?? [];
    const [showAssociateCollection, setShowAssociateCollection] =
      useState(false);
    const handleAssociateCollection = (event: any) => {
      //find the duplicate in event.SelectedCollection and collections array by Collection_Id and remove it
      if (!event.SelectedCollection || event.SelectedCollection.length === 0)
        return;
      const newSelectedCollection = event.SelectedCollection.filter(
        (selectedCollection: VO_CollectionType) =>
          !collections.some(
            (collection: VO_CollectionType) =>
              collection.Collection_Id === selectedCollection.Collection_Id
          )
      );
      onChange("SelectedCollection", {
        value: [...(collections ?? []), ...newSelectedCollection],
      });
      setShowAssociateCollection(false);
    };
    //function that remove selected collection from collections array by SelectedCollection: VO_CollectionType[]
    const removeSelectedCollection = () => {
      if (!collections || collections?.length === 0) return;
      const newCollections = collections.filter(
        (collection: VO_CollectionType) =>
          !collectionSelected.some(
            (selectedCollection: VO_CollectionType) =>
              selectedCollection.Collection_Id === collection.Collection_Id
          )
      );
      onChange("SelectedCollection", { value: [...newCollections] });
    };
    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Collections</label>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCGrid
                  gridId={COLLECTION_TYPE_GRID_ID}
                  data={collections}
                  primaryField={nameOfCollection("Collection_Id")}
                  columnFields={colCollectionsAssociate}
                  selectedRows={collectionSelected}
                  itemPerPage={10}
                  readOnly={options.isReadOnly}
                  selectableMode="multiple"
                  onSelectionChange={(dataItem: VO_CollectionType[]) => {
                    onChange(nameOf("LocalSelectedCollection"), {
                      value: [...dataItem],
                    });
                  }}
                  state={{
                    sort: [
                      {
                        field: nameOfCollection("Collection_Id"),
                        dir: "desc",
                      },
                    ],
                  }}
                  toolbar={
                    <div className="cc-grid-tools-bar">
                      <PrivilegeWrapper
                        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
                        privilegesEnum={EPrivilegesWorkflow.Collections_New}
                      >
                        <Button
                          type="button"
                          iconClass="fas fa-plus"
                          title="Add collections to be associated"
                          onClick={() => setShowAssociateCollection(true)}
                        />
                      </PrivilegeWrapper>
                      <Button
                        type="button"
                        iconClass="fas fa-minus"
                        title="Remove"
                        disabled={collectionSelected?.length < 1}
                        onClick={removeSelectedCollection}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {showAssociateCollection && (
          <AssociateCollection
            onClose={() => setShowAssociateCollection(false)}
            onSubmit={handleAssociateCollection}
          />
        )}
      </section>
    );
  }
);
