import { PPREnquiries } from "@app/products/town-planning/ppr/enquiries/current-register/model";
import { activitiesTownPlanning } from "@common/pages/home/components/townplanning/config";
import {
  IConfigActivitiesTownPlanning,
  IProcessDataPPRDashboardEnquiriesByDueStatus,
  PPRDashboardActivities,
  PPRDashboardEnquiriesByDueStatus,
} from "@common/pages/home/components/townplanning/model";
import { nameOfFactory } from "@common/utils/common";
import { entries, groupBy, isNil, map, sortBy, uniq } from "lodash";
const nameOfActivitiesTownPlanning = nameOfFactory<PPRDashboardActivities>();
const nameOfEnquiries = nameOfFactory<PPREnquiries>();
/**
 * Process Data Activities Town Planning
 * Input: object Activities
 * Output: IConfigActivitiesTownPlanning[]
 * @param data
 * @returns
 */
export const processDataActivitiesTownPlanning = (
  data: any
): IConfigActivitiesTownPlanning[] | [] => {
  const userDisplayname =
    JSON.parse(localStorage.getItem("userData") || "null")?.userDisplayname ??
    "";
  if (isNil(data)) return [];
  //clone data
  const newConfigData = [...activitiesTownPlanning];
  newConfigData.forEach((item) => {
    if (item.name === nameOfActivitiesTownPlanning("TotalMyEnquiries")) {
      item.childData = {
        state: {
          filter: {
            logic: "or",
            filters: [
              {
                field: nameOfEnquiries("Officer"),
                operator: "contains",
                value: userDisplayname,
              },
              {
                field: nameOfEnquiries("TeamLeader"),
                operator: "contains",
                value: userDisplayname,
              },
            ],
          },
        },
      };
    }
  });
  //mapping data from api replace with config data
  const newData = newConfigData.map(
    (itemActivities: IConfigActivitiesTownPlanning) => {
      const name = itemActivities.name;
      const value: number = data[name] ?? 0;
      return {
        ...itemActivities,
        value: value,
      };
    }
  );

  return newData;
};

/**
 * Process Data Enquiries By Due Status
 * Input: PPRDashboardEnquiriesByDueStatus
 * Output: IProcessDataPPRDashboardEnquiriesByDueStatus
 * @param data
 * @returns
 */
export const processDataEnquiriesByDueStatus = (
  data: PPRDashboardEnquiriesByDueStatus[]
): IProcessDataPPRDashboardEnquiriesByDueStatus | undefined => {
  if (!data) return undefined;
  //clone data
  const cloneData: PPRDashboardEnquiriesByDueStatus[] = [...data];
  //sort list order increase by field "Year"
  const sortData = sortBy(cloneData, ["Year"]);

  //get distinct year in list duplicate year
  const uniquesYears = uniq(map(data, "Year"));

  let dataAfterProcess:
    | IProcessDataPPRDashboardEnquiriesByDueStatus
    | undefined = { years: [], listOfData: [] };

  //group list by field Status_Name
  const groupDataByStatusName = groupBy(sortData, "Status_Name");

  const newData = map(entries(groupDataByStatusName), ([status, dataGroup]) => {
    let listCountOfYears = [];
    //check list both list the same size
    if (uniquesYears.length === dataGroup.length) {
      //map return list number of Count
      listCountOfYears = dataGroup.map((itemGroup) => itemGroup?.Count ?? 0);
    } else {
      listCountOfYears = uniquesYears.map((year) => {
        //find all year in uniquesYears which existed in data Group
        const find = dataGroup.find((itemGroup) => itemGroup.Year === year);
        if (find && find?.Count) {
          //If existed get data Count
          return find.Count;
        } else {
          //else get default value is 0
          return 0;
        }
      });
    }
    return {
      name: status,
      data: listCountOfYears,
    };
  });

  if (uniquesYears && newData) {
    dataAfterProcess.years = uniquesYears as number[];
    dataAfterProcess.listOfData = newData;
  }

  return dataAfterProcess;
};
