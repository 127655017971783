import { eventEmitter } from "@/App";
import { history } from "@/AppRoutes";
import {
  getLocationRegisterLovs,
  loadLocationRegisterById,
  locationRegisterHandler,
  saveLocationRegister,
} from "@app/core/location-register/[id]/api";
import { CORE_LOCATION_REGISTER_ROUTE } from "@app/core/location-register/[id]/constant";
import {
  LocationRegisterHandlerRequest,
  LocationRegisterLovs,
  LocationRegisterUIControl,
  Register,
  Svc_FormAction_LocationRegister,
} from "@app/core/location-register/[id]/model";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { IPSARApplicationParentSection } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class LocationRegisterStore {
  private _locationRegister?: Register = undefined;
  private _locationRegisterLovs?: LocationRegisterLovs = undefined;
  private _locationRegisterUIControl?: LocationRegisterUIControl = undefined;
  private _isLoading: boolean = false;
  private _isFormModified: boolean = false;
  private _notification?: IAppNotificationItemAddProps = undefined;
  private _parentSection?: IPSARApplicationParentSection = undefined;

  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get notification() {
    return this._notification;
  }
  setNotification = (
    notification: IAppNotificationItemAddProps | undefined
  ) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: IPSARApplicationParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get locationRegisterID() {
    return toJS(this._locationRegister?.Register_ID);
  }

  get locationRegister() {
    return toJS(this._locationRegister);
  }
  setLocationRegister = (locationRegister: Register) => {
    runInAction(() => {
      this._locationRegister = locationRegister;
    });
  };

  get locationRegisterLovs() {
    return toJS(this._locationRegisterLovs);
  }
  setLocationRegisterLovs = (locationRegisterLovs: LocationRegisterLovs) => {
    runInAction(() => {
      this._locationRegisterLovs = locationRegisterLovs;
    });
  };

  get locationRegisterUIControl() {
    return toJS(this._locationRegisterUIControl);
  }
  setLocationRegisterUIControl = (
    locationRegisterUIControl: LocationRegisterUIControl
  ) => {
    runInAction(() => {
      this._locationRegisterUIControl = locationRegisterUIControl;
    });
  };

  get isFormModified() {
    return this._isFormModified;
  }
  setIsFormModified = (isFormModified: boolean) => {
    runInAction(() => {
      this._isFormModified = isFormModified;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._onSubmit = undefined;
      this._locationRegister = undefined;
      this._isFormModified = false;
      this._parentSection = undefined;
      this._notification = undefined;
      this._locationRegisterUIControl = undefined;
      this._locationRegisterLovs = undefined;
    });
  };

  reloadLocationRegister = async (isNew: boolean) => {
    if (this.locationRegisterID) {
      await this.loadLocationRegister(this.locationRegisterID, isNew);
    }
  };

  loadLocationRegister = async (
    locationRegisterId: number,
    isNew: boolean = false
  ) => {
    this.setIsLoading(true);
    if (isNew) {
      const [response, responseLovs] = await Promise.all([
        loadLocationRegisterById(0),
        getLocationRegisterLovs(),
      ]);
      this.setIsLoading(false);

      if (
        isSuccessResponse(response) &&
        response.data &&
        isSuccessResponse(responseLovs) &&
        responseLovs.data
      ) {
        this.setLocationRegisterLovs(responseLovs.data);

        let params: LocationRegisterHandlerRequest = {
          LocationRegisterFormAction:
            Svc_FormAction_LocationRegister.SystemInitialise,
          Register: response.data,
          LocationRegisterArgs: {},
          IsFirstTimeLoad: true,
        };
        const responseLocationRegisterHandler = await locationRegisterHandler(
          params
        );

        if (
          isSuccessResponse(responseLocationRegisterHandler) &&
          responseLocationRegisterHandler.data?.ReturnObj
        ) {
          this.setLocationRegister(
            responseLocationRegisterHandler.data.ReturnObj.Register
          );

          this.setLocationRegisterUIControl(
            responseLocationRegisterHandler.data.ReturnObj.UIControl
          );
        } else {
          this.setResponseLoadError({
            status: responseLocationRegisterHandler.status,
            error:
              responseLocationRegisterHandler.data?.Errors ??
              responseLocationRegisterHandler.error,
          });
        }
      } else {
        this.setResponseLoadError({
          status: response.status,
          error: response.error || responseLovs.error,
        });
      }
    } else {
      const [response, responseLovs] = await Promise.all([
        loadLocationRegisterById(locationRegisterId),
        getLocationRegisterLovs(),
      ]);

      this.setIsLoading(false);

      if (
        isSuccessResponse(response) &&
        response.data &&
        isSuccessResponse(responseLovs) &&
        responseLovs.data
      ) {
        this.setLocationRegisterLovs(responseLovs.data);

        let params: LocationRegisterHandlerRequest = {
          LocationRegisterFormAction:
            Svc_FormAction_LocationRegister.SystemInitialise,
          Register: response.data,
          LocationRegisterArgs: {},
          IsFirstTimeLoad: true,
        };
        const responseLocationRegisterHandler = await locationRegisterHandler(
          params
        );

        if (
          isSuccessResponse(responseLocationRegisterHandler) &&
          responseLocationRegisterHandler.data?.ReturnObj
        ) {
          this.setLocationRegister(
            responseLocationRegisterHandler.data.ReturnObj.Register
          );

          this.setLocationRegisterUIControl(
            responseLocationRegisterHandler.data.ReturnObj.UIControl
          );
        } else {
          this.setResponseLoadError({
            status: responseLocationRegisterHandler.status,
            error:
              responseLocationRegisterHandler.data?.Errors ??
              responseLocationRegisterHandler.error,
          });
        }

        // Push notification
        if (this.parentSection?.notification) {
          this.parentSection?.notification.forEach(
            (notification: IAppNotificationItemAddProps) => {
              appNotificationStore.pushNotification(notification);
            }
          );
          this.setParentSection({ ...this._parentSection, notification: [] });
        }

        if (this.notification) {
          appNotificationStore.pushNotification(this.notification);
          this.setNotification(undefined);
        }
      } else {
        this.setResponseLoadError({
          status: response.status,
          error: response.error || responseLovs.error,
        });
      }
    }
  };

  saveLocationRegister = async (locationRegister: Register, isNew: boolean) => {
    appNotificationStore.clearNotifications();
    this.setIsLoading(true);
    const response = await saveLocationRegister(locationRegister);
    this.setIsLoading(false);

    if (isSuccessResponse(response) && response.data) {
      if (this.isFormModified) this.setIsFormModified(false);
      if (isNew) {
        history.replace(
          `${CORE_LOCATION_REGISTER_ROUTE}/${response?.data?.ID}`,
          {
            notification: [
              {
                title: "Record successfully saved.",
                type: "success",
              },
            ],
          }
        );
      } else {
        this.reloadLocationRegister(isNew);
        this.setNotification({
          title: "Record successfully saved.",
          type: "success",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(TabTableEventType.RefreshData);
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Record could not be saved.",
        type: "error",
        description: response.data?.Errors ?? response.error,
      });
    }
  };

  locationRegisterChangeHandler = async (
    params: LocationRegisterHandlerRequest,
    errorMsg: string
  ) => {
    this.setIsLoading(true);
    const response = await locationRegisterHandler(params);
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setIsFormModified(true);
      const register = response.data.ReturnObj.Register;

      this.setLocationRegister(register);
      this.setLocationRegisterUIControl(response.data.ReturnObj.UIControl);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: errorMsg,
        type: "error",
        description: response.data?.Errors || response.error,
      });
    }
    this.setIsLoading(false);
  };
}

const locationRegisterStoreContext = createContext(new LocationRegisterStore());
export const useLocationRegisterStore = () => {
  return useContext(locationRegisterStoreContext);
};
