import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const noticesRoute: ICCRoute = {
  path: "notices",
  name: "Notices",
  enum: HealthManagerMenu.Notices,
  children: [
    {
      path: "by-date",
      name: "By Date",
      enum: HealthManagerMenu.NoticesByDate,
      component: require("./by-date/_index").default,
    },
    {
      path: "by-due-status",
      name: "By Due Status",
      enum: HealthManagerMenu.NoticesByDueStatus,
      component: require("./by-due-status/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      enum: HealthManagerMenu.NoticesByOfficer,
      component: require("./by-officer/_index").default,
    },
  ],
};
