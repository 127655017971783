export const mockSupplementary = [
  {
    ID: 451,
    Date: "14-May-2019",
    Name: "Aggregation of Unit and Carspace for 15/16 Unit 8 3 Werambie St Woolwich",
    Reference: "Aggregation of Unit and Carspace for 15/16 Unit 8",
    Notes:
      "Aggregation of Unit and Carspace for 15/16 Unit 8 3 Werambie St Woolwich",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Valuation update (No Raising of charges)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "14-May-2019 16:25",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 452,
    Date: "23-Jun-2019",
    Name: "6 December 2014 Supp Listing",
    Reference: "6 December 2014 Supp Listing",
    Notes: "6 December 2014 Supp Listing",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Subdivisions.Amalgamations and general changes",
    InstalmentPlan: "2014/2015 Instalments (Due 2015/2016 instalments)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "14-May-2019 17:02",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 453,
    Date: "22-May-2019",
    Name: "Garbage & Waste adjustments",
    Reference: "Garbage & Waste adjustments",
    Notes: "Garbage & Waste adjustments",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2014/2015 Instalments (Jun)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "22-May-2019 14:51",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 454,
    Date: "9-Jun-2019",
    Name: "Garbage adjustments",
    Reference: "Garbage adjustments",
    Notes: "Garbage adjustments",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (Aug)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "9-Jun-2019 12:02",
    CreatedBy: "Steven sPAG NOLO",
  },
  {
    ID: 455,
    Date: "10-Jun-2019",
    Name: "Garbage adjustments",
    Notes: "Charges due in 2015/2016 August instalment",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (Aug)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "10-Jun-2019 10:24",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 456,
    Date: "23-Jun-2019",
    Name: "31 January 2015 Supplementary List - Part 1",
    Reference: "31 January 2015 Supplementary List - Part 1",
    Notes: "31 January 2015 Supplementary Listing - Part 1",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Subdivisions,Amalgamations and general changes",
    InstalmentPlan: "2014/2015 Instalments (Due 2015/2016 instalments)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "23-Jun-2019 16:45",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 457,
    Date: "24-Jun-2019",
    Name: "31 January 2015 Supp Listing - Part 2",
    Reference: "31 January 2015 Supp Listing - Part 2",
    Notes: "31 January 2015 Supp Listing - Part 2",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Subdivisions.Amalgamations and general changes",
    InstalmentPlan: "2014/2015 Instalments (Due 2015/2016 instalments)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "24-Jun-2019 16:12",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 458,
    Date: "25-Jun-2019",
    Name: "28 February 2015 Supp Listing",
    Reference: "28 February 2015 Supp Listing",
    Notes: "28 February 2015 Supp Listing",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Subdivisions.Amalgamations and general changes",
    InstalmentPlan: "2015/2016 Instalments (Aug)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "25-Jun-2019 16:37",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 459,
    Date: "26-Jun-2019",
    Name: "28 March 2015 Supp Listing",
    Reference: "28 March 2015 Supp Listing",
    Notes: "28 March 2015 Supp Listing",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Subdivisions.Amalgamations and general changes",
    InstalmentPlan: "2014/2015 Instalments (Due 2015/2016 instalments)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "26-Jun-2019 11:15",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 460,
    Date: "26-Jun-2019",
    Name: "25 April 2015 Supplementary List",
    Reference: "25 April 2015 Supplementary List",
    Notes: "25 April 2015 Supplementary List",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Subdivisions.Amalgamations and general changes",
    InstalmentPlan: "2014/2015 Instalments (Due 2015/2016 instalments)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "26-Jun-2019 16:02",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 461,
    Date: "26-Jun-2019",
    Name: "May 2015 Supp List",
    Reference: "May 2015 Supp List",
    Notes: "May 2015 Supplementary Listing",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Subdivisions.Amalgamations and general changes",
    InstalmentPlan: "2014/2015 Instalments (Due 2015/2016 instalments)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "26-Jun-2019 17:41",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 462,
    Date: "28-Jun-2019",
    Name: "20 June 2015 Supp Listing",
    Reference: "20 June 2015 Supp Listing",
    Notes: "20 June 2015 Supp Listing",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Subdivisions.Amalgamations and general changes",
    InstalmentPlan: "2014/2015 Instalments (Due 2015/2016 instalments)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "28-Jun-2019 15:57",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 463,
    Date: "29-Jun-2019",
    Name: "Add Values to Units at 66 Gladesville Rd Hunters Hill",
    Reference: "Add Values to Units at 66 Gladesville Rd Hunters H",
    Notes: "Add Values to Units at 66 Gladesville Rd Hunters Hill",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Subdivisions.Amalgamations and general changes",
    InstalmentPlan: "2014/2015 Instalments (Due 2015/2016 instalments)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "29-Jun-2019 17:17",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 464,
    Date: "30-Jun-2019",
    Name: "Make values current for Notional (part 1)",
    Reference: "Make values current for Notional (part 1)",
    Notes: "Make values current for Notional (part 1)",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Valuation update (No Raising of charges)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "30-Jun-2019 10:49",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 465,
    Date: "30-Jun-2019",
    Name: "Make values current for Notional (part 2)",
    Reference: "Make values current for Notional (part 2)",
    Notes: "Make values current for Notional (part 2)",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Valuation update (No Raising of charges)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "30-Jun-2019 11:33",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 466,
    Date: "30-Jun-2019",
    Name: "Make values current for Notional (part 3)",
    Reference: "Make values current for Notional (part 3)",
    Notes: "Make values current for Notional (part 3)",
    RatingPeriod: "2015/2016",
    IsComplete: true,
    Type: "Levy Changes (no raising of charges)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "30-Jun-2019 19:27",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 467,
    Date: "10-Jul-2019",
    Name: "Garbage/Waste Charges",
    Reference: "Levy changes in time for 15/16 levy",
    Notes: "Garb/Waste Levy changes for 15/16 levy",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Levy Changes (no raising of charges)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "10-Jul-2019 15:46",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 468,
    Date: "4-Aug-2019",
    Name: "Garbage Adjustments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (Nov, Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "4-Aug-2019 17:06",
    CreatedBy: "Steven sPAG NOLO",
  },
  {
    ID: 469,
    Date: "17-Aug-2019",
    Name: "Garbage adjustments",
    Reference: "Garbage adjustments",
    Notes: "Garbage adjustments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (Aug, Nov, Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "17-Aug-2019 17:25",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 470,
    Date: "16-Sep-2019",
    Name: "40 Mary street Hunters Hill Non-Rateable application",
    Reference: "40 Mary street Hunters Hill Non-Rateable application",
    Notes: "40 Mary street Hunters Hill Non-Rateable application",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (Nov, Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "16-Sep-2019 15:29",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 471,
    Date: "16-Sep-2019",
    Name: "40 Mary street Hunters Hill Non-Ratable Levy code to be added",
    Reference: "40 Mary street Hunters Hill Non-Ratable Levy code",
    Notes: "40 Mary street Hunters Hill Non-Ratable Levy code to be added",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (Nov, Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "16-Sep-2019 15:52",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 472,
    Date: "16-Sep-2019",
    Name: "1A John Street HH Non-rateable application",
    Reference: "1A John Street HH Non-rateable application",
    Notes: "1A John Street HH Non-rateable application",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (Nov. Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "16-Sep-2019 17:58",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 473,
    Date: "17-Sep-19",
    Name: "Garbage & Waste adjustments",
    Reference: "Garbage & Waste adjustments",
    Notes: "Garbage & Waste adjustments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (Nov. Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "17-Sep-2019 15:02",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 474,
    Date: "25-Sep-19",
    Name: "Garbage and Waste adjustments",
    Reference: "Garbage and Waste adjustments",
    Notes: "Garbage and Waste adjustments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (Nov, Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "25-Sep-2019 15:31",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 475,
    Date: "9-Oct-19",
    Name: "Garbage & Waste adjustments",
    Reference: "Garbage & Waste adjustments",
    Notes: "Garbage & Waste adjustments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (Nov, Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "09-Oct-2019 12:53",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 476,
    Date: "15-Oct-19",
    Name: "July 2015 Supp Listing",
    Reference: "July 2015 Supp Listing",
    Notes: "July 2015 Supp Listing",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Subdivisions.Amalgamations and general changes",
    InstalmentPlan: "2015/2016 Instalments (Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "15-Oct-2019 17:21",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 477,
    Date: "22-Oct-19",
    Name: "Aggregation at 157 Victoria Rd",
    Reference: "Doc id 242289",
    Notes: "Aggregation of lots 31 and 43 at 157 Victoria Rd Gladesville",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Valuation Update (No Raising of charges)",
    InstalmentPlan: "2015/2016 Instalments (Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "22-Oct-2019 16:04",
    CreatedBy: "Steven sPAG NOLO",
  },
  {
    ID: 478,
    Date: "26-Oct-19",
    Name: "Garbage Adjustments",
    Reference: "Garbage Adjustments+B45",
    Notes: "Garbage Adjustments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "charge Adjustments+A1:B19",
    InstalmentPlan: "2015/2016 Instalments (Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "26-Oct-2019 11:32",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 479,
    Date: "29-Oct-19",
    Name: "Garbage Adjustments for prior years",
    Reference: "Doc id 240672",
    Notes:
      "Garbage adjustment for prior years - overcharged for additional garbage service",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (Nov, Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "29-Oct-2019 16:29",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 480,
    Date: "6-Nov-19",
    Name: "Garbage Adjustments",
    Reference: "Garbage Adjustments",
    Notes: "Garbage Adjustments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "06-Nov-2019 12:44",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 481,
    Date: "13-NOV-2019",
    Name: "Aggregation at 165 Victoria Rd",
    Reference: "Doc ID 248833",
    Notes:
      "Request for aggregation of Lot 7 & 90 in SP70815 at 165 Victoria Road",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Subdivisions,Amalgamations and general changes",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "13-Nov-2019 16:11",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 482,
    Date: "16-NOV-2019",
    Name: "Termination of strata Plan at 161 Victoria Road",
    Reference: "Doc id 248898",
    Notes:
      "SP82414 was terminated 6/11/2015 and reverted to Lot 10 DP1140169 (assessment 3886406) - doc id 248898. Preparation work to adjust levy codes but not to amend valuation changes (yet) - need to adjust for business overcharge first",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "General Revaluations",
    InstalmentPlan: "2015/2016 Instalments (Aug, Nov, Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "16-Nov-2019 13:04",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 483,
    Date: "17-NOV-2019",
    Name: "Apply Business overcharge levy adjustment",
    Reference: "Doc ID 246049",
    Notes:
      "All Business properties for 2015/2016 were charged Rate in Dollar at 0.136791 but it should have been 0.136418. This supplementary applies an adjustment levy code that will be used to levy the adjustment amount.",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Levy Changes (no raising of charges)",
    InstalmentPlan: "2015/2016 Instalments (Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "17-Nov-2019 12:35",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 484,
    Date: "17-NOV-2019",
    Name: "Cancel valuation for 161 Victoria Rd",
    Reference: "Doc ID 248898",
    Notes:
      "SP82414 was terminated 6/11/2015 and reverted to Lot 10 DP1140169 (assessment 3886406) - doc id 248898. Supplementary to amend valuation changes - levies had already been removed in previous Supp ID 482",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "General Revaluations",
    InstalmentPlan: "2015/2016 Instalments (Jun)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "17-Nov-2019 14:07",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 485,
    Date: "24-NOV-2019",
    Name: "August 2015 Supp List",
    Reference: "Doc ID 241788",
    Notes: "August 2015 Supplementary List",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Subdivisions,Amalgamations and general changes",
    InstalmentPlan: "2015/2016 Instalments (Nov, Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "24-Nov-2019 14:15",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 486,
    Date: "25-NOV-2019",
    Name: "October 2015 Supp List",
    Reference: "Doc ID 246759",
    Notes: "October 2015 Supplementary List",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Subdivisions,Amalgamations and general changes",
    InstalmentPlan: "2015/2016 Instalments (Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "25-Nov-2019 12:01",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 487,
    Date: "26-NOV-2019",
    Name: "November 2015 Supplementary List",
    Reference: "Doc id 249452",
    Notes: "November 2015 Supplementary List",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Subdivisions.Amalgamations and general changes",
    InstalmentPlan: "2015/2016 Instalments (Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "26-Nov-2019 11:02",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 488,
    Date: "27-NOV-2019",
    Name: "November 2015 Supp List - extra",
    Reference: "Doc id 249452",
    Notes:
      "Extra bit missing from Supp 487 - add levy codes onto new assessments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Levy Changes (no raising of charges)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "27-Nov-2019 14:07",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 489,
    Date: "21-Dec-2019",
    Name: "December 2015 Supp List",
    Reference: "Doc ID 251567",
    Notes: "December 2015 Supplementary Valuation Listing",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Subdivisions,Amalgamations and general changes",
    InstalmentPlan: "2015/2016 Instalments (Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "21-Dec-2019 11:20",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 490,
    Date: "7-Jan-2020",
    Name: "Garbage adjustments",
    Reference: "Garbage adjustments",
    Notes: "Garbage adjustments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "7-Jan-2020 10:36",
    CreatedBy: "Steven sPAG NOLO",
  },
  {
    ID: 491,
    Date: "12-Jan-2020",
    Name: "January 2016 Supp List",
    Reference: "Doc id 252728",
    Notes: "January 2016 Supplementary Valuation List",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Subdivisions,Amalgamations and general changes",
    InstalmentPlan: "2015/2016 Instalments (Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "12-Jan-2020 09:57",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 492,
    Date: "12-Jan-2020",
    Name: "Garbage adjustment correction",
    Reference: "Doc ID 252951",
    Notes:
      "Ratepayer called to advise the adjustment was made on the wrong date. Correction to adjust from December instead of November.",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (Feb, May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "12-Jan-2020 11:43",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 493,
    Date: "13-Feb-2020",
    Name: "Garbage & Waste adjustments",
    Reference: "Garb & Waste adjustments",
    Notes: "Garb & Waste adjustments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "13-Feb-2020 14:35",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 494,
    Date: "7-Mar-2020",
    Name: "Garbage & Waste Adjustments",
    Reference: "Garbage & Waste Adjustments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "7-Mar-2020 09:43",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 495,
    Date: "1-Apr-2020",
    Name: "Garbage Adjustments",
    Reference: "Garbage Adjustments",
    Notes: "Garbage Adjustments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "1-Apr-2020 10:34",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 496,
    Date: "6-Apr-2020",
    Name: "Correct levy codes in part of Dec 2015 Supp List",
    Reference: "Doc id 251567",
    Notes:
      "Correct the levy codes and dates for the subdivision of 78A Park Road, which was not done correctly in the original supplementary processing.",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Levy Changes (no raising of charges)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "6-Apr-2020 10:37",
    CreatedBy: "Steven SPAGNOLO",
  },
  {
    ID: 497,
    Date: "20-Apr-2020",
    Name: "Garbage Adjustments",
    Reference: "Garbage Adjustments",
    Notes: "Garbage Adjustments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2015/2016 Instalments (Jun)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "20-Apr-2020 13:22",
    CreatedBy: "Tracey SMITH",
  },
  {
    ID: 498,
    Date: "17-May-2020",
    Name: "Change of Rating Category for 30 Augustine st Hunters Hill",
    Reference: "Change of Rating Category for 30 Augustine st HH",
    Notes: "Change of Rating Category for 30 Augustine st Hunters Hill",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Subdivisions,Amalgamations and general changes",
    InstalmentPlan: "2015/2016 Instalments (May)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "17-May-2020 14:58",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 499,
    Date: "26-May-2020",
    Name: "Garbage & Waste adjustments",
    Reference: "Garbage & Waste adjustments",
    Notes: "Garbage & Waste adjustments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Subdivisions,Amalgamations and general changes",
    InstalmentPlan: "2017/2018 Instalments (Aug 17)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "26-May-2020 16:27",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 500,
    Date: "16-Jun-2020",
    Name: "Garbage & Waste adjustments",
    Reference: "Garbage & Waste adjustments",
    Notes: "Garbage & Waste adjustments",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Charge Adjustments",
    InstalmentPlan: "2017/2018 Instalments (Aug 17)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "16-Jun-2020 16:11",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 501,
    Date: "20-Jun-2020",
    Name: "Non-Ratable to Rateable 1C Massey st Gladesville",
    Reference: "Non-Ratable to Rateable 1C Massey st Gladesville",
    Notes:
      "Non-Ratable to Rateable 1C Massey st Gladesville from 22/4/16 following sale",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Subdivisions,Amalgamations and general changes",
    InstalmentPlan: "2017/2018 Instalments (Aug 17)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "20-Jun-2020 10:11",
    CreatedBy: "Barbara HOGAN",
  },
  {
    ID: 502,
    Date: "20-Jun-2020",
    Name: "February 2016 Supp List",
    Reference: "February 2016 Supp List",
    Notes: "February 2016 Supp List",
    RatingPeriod: "2016/2017",
    IsComplete: true,
    Type: "Subdivisions,Amalgamations and general changes",
    InstalmentPlan: "2017/2018 Instalments (Aug 17)",
    UseExistingInstalmentPlan: false,
    EnableLevyCalculations: true,
    BalanceTransferMethod: "None",
    CreatedOn: "20-Jun-2020 10:59",
    CreatedBy: "Barbara HOGAN",
  },
];
export const mockAuthorised = [
  {
    Authorised: "Open Office",
    Id: 1,
  },
];

export const mockJournalType = [
  {
    Code: "292",
    Name: "Act of Grace - CCMIL",
  },
  {
    Code: "357",
    Name: "Act of Grace - Land Rent",
  },
  {
    Code: "107",
    Name: "Act of Grace - Land Tax",
  },
  {
    Code: "108",
    Name: "Act of Grace - Rates",
  },
  {
    Code: "171",
    Name: "ACTH Payment In",
  },
  {
    Code: "172",
    Name: "ACTHT Payment Out",
  },
  {
    Code: "100",
    Name: "Adj CCMIL Interest",
  },
  {
    Code: "358",
    Name: "Adj Land Rent Interest",
  },
  {
    Code: "106",
    Name: "Adj Land Tax Interest",
  },
  {
    Code: "103",
    Name: "Adj Rates Interest",
  },
  {
    Code: "102",
    Name: "Adjust - Discount",
  },
  {
    Code: "124",
    Name: "Adjust CCMIL Charge",
  },
  {
    Code: "351",
    Name: "Adjust Charge Balances",
  },
  {
    Code: "352",
    Name: "Adjust Land Rent Charge",
  },
  {
    Code: "353",
    Name: "Adjust Land Rent GST",
  },
  {
    Code: "129",
    Name: "Adjust Land Tax Charge",
  },
  {
    Code: "126",
    Name: "Adjust Rates Charge",
  },
  {
    Code: "293",
    Name: "Amend Instalment Balances",
  },
  {
    Code: "30",
    Name: "Arrangement",
  },
  {
    Code: "6",
    Name: "Balance/Consolidation",
  },
  {
    Code: "303",
    Name: "Bulk Payment - ACTH",
  },
  {
    Code: "304",
    Name: "Bulk Payment - CHC",
  },
  {
    Code: "305",
    Name: "Bulk Payment - DHA",
  },
  {
    Code: "302",
    Name: "Bulk Rates Payments",
  },
  {
    Code: "367",
    Name: "CCMIL Manual Charge",
  },
  {
    Code: "47",
    Name: "Centrelink Capped (Manual)",
  },
  {
    Code: "42",
    Name: "Centrelink Capped (Rate Run)",
  },
  {
    Code: "45",
    Name: "Centrelink Non-capped (Manual)",
  },
  {
    Code: "40",
    Name: "Centrelink Non-capped (Rate Run)",
  },
  {
    Code: "7",
    Name: "Change of Instalment Plan",
  },
  {
    Code: "311",
    Name: "CHC Payment In ",
  },
  {
    Code: "312",
    Name: "CHC Payment Out",
  },
  {
    Code: "321",
    Name: "City Centre Improvemnt Levy",
  },
  {
    Code: "116",
    Name: "City Improvemnt Levy",
  },
  {
    Code: "365",
    Name: "Clear Deferred Interest Balances",
  },
  {
    Code: "343",
    Name: "Clear Deferred Rates Balances",
  },
  {
    Code: "61",
    Name: "Compliance - Backdated Interest",
  },
  {
    Code: "60",
    Name: "Compliance - Penalty",
  },
  {
    Code: "117",
    Name: "Credit Interest Rates",
  },
  {
    Code: "299",
    Name: "Credit of Interest CCMIL",
  },
  {
    Code: "359",
    Name: "Credit of Interest Land Rent",
  },
  {
    Code: "118",
    Name: "Credit of Interest Land Tax",
  },
  {
    Code: "300",
    Name: "Credit of Interest Rates",
  },
  {
    Code: "14",
    Name: "Deferment of Amounts Owing",
  },
  {
    Code: "364",
    Name: "Deferment of Charges - Manual",
  },
  {
    Code: "313",
    Name: "DHA Payment In",
  },
  {
    Code: "314",
    Name: "DHA Payment Out",
  },
  {
    Code: "52",
    Name: "Direct Debit Fee",
  },
  {
    Code: "331",
    Name: "Direct Debit Rejection Fee Adj",
  },
  {
    Code: "356",
    Name: "Direct Debits Land Rent Fees",
  },
  {
    Code: "354",
    Name: "Direct Debits Land Rent Payments",
  },
  {
    Code: "355",
    Name: "Direct Debits Land Rent Rejects",
  },
  {
    Code: "284",
    Name: "Direct Debits Land Tax Fees",
  },
  {
    Code: "278",
    Name: "Direct Debits Land Tax Payments",
  },
  {
    Code: "281",
    Name: "Direct Debits Land Tax Rejects",
  },
  {
    Code: "283",
    Name: "Direct Debits Rates Fees",
  },
  {
    Code: "277",
    Name: "Direct Debits Rates Payments",
  },
  {
    Code: "280",
    Name: "Direct Debits Rates Rejects",
  },
  {
    Code: "3",
    Name: "Discount",
  },
  {
    Code: "23",
    Name: "Discount (Manual)",
  },
  {
    Code: "125",
    Name: "Dishonour Cheque Fee",
  },
  {
    Code: "46",
    Name: "Embassy Remissions (Manual)",
  },
  {
    Code: "41",
    Name: "Embassy Remissions (Rate Run)",
  },
  {
    Code: "142",
    Name: "External Jnl - In",
  },
  {
    Code: "143",
    Name: "External Jnl - Out",
  },
  {
    Code: "241",
    Name: "Hardship - Rates",
  },
  {
    Code: "4",
    Name: "Instalment Administration Fee",
  },
  {
    Code: "90",
    Name: "Instalment Fine",
  },
  {
    Code: "2",
    Name: "Interest",
  },
  {
    Code: "22",
    Name: "Interest (Manual)",
  },
  {
    Code: "341",
    Name: "Interest Manual - CCMIL",
  },
  {
    Code: "348",
    Name: "Interest Manual - Land Rent",
  },
  {
    Code: "340",
    Name: "Interest Manual - Land Tax",
  },
  {
    Code: "339",
    Name: "Interest Manual - Rates",
  },
  {
    Code: "174",
    Name: "Internal Jnl - In",
  },
  {
    Code: "175",
    Name: "Internal Jnl - Out",
  },
  {
    Code: "298",
    Name: "Just & Equity - CCMIL",
  },
  {
    Code: "360",
    Name: "Just & Equity - Land Rent",
  },
  {
    Code: "294",
    Name: "Just & Equity - Land Tax",
  },
  {
    Code: "295",
    Name: "Just & Equity - Rates",
  },
  {
    Code: "346",
    Name: "Land Rent Charge",
  },
  {
    Code: "347",
    Name: "Land Rent GST",
  },
  {
    Code: "199",
    Name: "Land Tax",
  },
  {
    Code: "342",
    Name: "Land Tax Manual Charge",
  },
  {
    Code: "200",
    Name: "Land Tax Penalty",
  },
  {
    Code: "186",
    Name: "Ltx Sector 1 Qtr 1",
  },
  {
    Code: "187",
    Name: "Ltx Sector 1 Qtr 2",
  },
  {
    Code: "188",
    Name: "Ltx Sector 1 Qtr 3",
  },
  {
    Code: "189",
    Name: "Ltx Sector 1 Qtr 4",
  },
  {
    Code: "190",
    Name: "Ltx Sector 2 Qtr 1",
  },
  {
    Code: "191",
    Name: "Ltx Sector 2 Qtr 2",
  },
  {
    Code: "192",
    Name: "Ltx Sector 2 Qtr 3",
  },
  {
    Code: "193",
    Name: "Ltx Sector 2 Qtr 4",
  },
  {
    Code: "194",
    Name: "Ltx Sector 3 Qtr 1",
  },
  {
    Code: "195",
    Name: "Ltx Sector 3 Qtr 2",
  },
  {
    Code: "196",
    Name: "Ltx Sector 3 Qtr 3",
  },
  {
    Code: "197",
    Name: "Ltx Sector 3 Qtr 4",
  },
  {
    Code: "288",
    Name: "Manual CCMIL Payments",
  },
  {
    Code: "287",
    Name: "Manual Land Tax Payments",
  },
  {
    Code: "286",
    Name: "Manual Rates Payments",
  },
  {
    Code: "13",
    Name: "Move unalloc credits to Land Tax",
  },
  {
    Code: "0",
    Name: "Overpayment/Credit",
  },
  {
    Code: "20",
    Name: "Payment (Interim Bank)",
  },
  {
    Code: "273",
    Name: "Pension Rebate - Veterans' Affairs Capped (Manual)",
  },
  {
    Code: "272",
    Name: "Pension Rebate - Veterans' Affairs Non-capped (Manual)",
  },
  {
    Code: "276",
    Name: "RAP CCMIL Payments",
  },
  {
    Code: "350",
    Name: "RAP Land Rent Payments",
  },
  {
    Code: "275",
    Name: "RAP Land Tax Payments",
  },
  {
    Code: "306",
    Name: "RAP Rates Payments",
  },
  {
    Code: "366",
    Name: "Rate Manual Charge",
  },
  {
    Code: "1",
    Name: "Rates",
  },
  {
    Code: "221",
    Name: "Rates Sector 1",
  },
  {
    Code: "222",
    Name: "Rates Sector 2",
  },
  {
    Code: "223",
    Name: "Rates Sector 3",
  },
  {
    Code: "11",
    Name: "Reallocation of Credits",
  },
  {
    Code: "16",
    Name: "Reallocation of Supplementary Credits",
  },
  {
    Code: "338",
    Name: "Reb Adj - Centrelink",
  },
  {
    Code: "211",
    Name: "Reb Adj - F Affairs",
  },
  {
    Code: "217",
    Name: "Reb Adj - Vet Affair",
  },
  {
    Code: "9",
    Name: "Refund",
  },
  {
    Code: "238",
    Name: "Refund - CCMIL",
  },
  {
    Code: "349",
    Name: "Refund - Land Rent",
  },
  {
    Code: "240",
    Name: "Refund - Land Tax",
  },
  {
    Code: "307",
    Name: "Refund - Rates",
  },
  {
    Code: "233",
    Name: "Restitution",
  },
  {
    Code: "324",
    Name: "Rev Cent Capped Rebate",
  },
  {
    Code: "325",
    Name: "Rev Cent Non Capped Rebate",
  },
  {
    Code: "326",
    Name: "Rev Embassy Remission",
  },
  {
    Code: "327",
    Name: "Rev Vet Affairs Capped Rebate ",
  },
  {
    Code: "328",
    Name: "Rev Vet Affairs Non Capped Rebate",
  },
  {
    Code: "15",
    Name: "Supplementary Balance Transfer",
  },
  {
    Code: "12",
    Name: "Supplementary Rates",
  },
  {
    Code: "344",
    Name: "Transfer Deferment Balance To Rates",
  },
  {
    Code: "345",
    Name: "Transfer Payment To Deferred Balance Only",
  },
  {
    Code: "261",
    Name: "Unidentified Remit - In",
  },
  {
    Code: "262",
    Name: "Unidentified Remit - Out",
  },
  {
    Code: "308",
    Name: "Unidentified Remit CCMIL",
  },
  {
    Code: "363",
    Name: "Unidentified Remit Land Rent",
  },
  {
    Code: "309",
    Name: "Unidentified Remit Land Tax",
  },
  {
    Code: "310",
    Name: "Unidentified Remit Rates",
  },
  {
    Code: "322",
    Name: "Veterans' Affairs Capped (Manual)",
  },
  {
    Code: "271",
    Name: "Veterans' Affairs Capped (Rate Run)",
  },
  {
    Code: "323",
    Name: "Veterans' Affairs Non-capped (Manual)",
  },
  {
    Code: "270",
    Name: "Veterans' Affairs Non-capped (Rate Run)",
  },
  {
    Code: "17",
    Name: "Waiver",
  },
  {
    Code: "296",
    Name: "Waiver - CCMIL",
  },
  {
    Code: "361",
    Name: "Waiver - Land Rent",
  },
  {
    Code: "265",
    Name: "Waiver - Land Tax",
  },
  {
    Code: "297",
    Name: "Waiver - Rates",
  },
  {
    Code: "8",
    Name: "Write Off",
  },
  {
    Code: "329",
    Name: "Write Off - CCMIL",
  },
  {
    Code: "362",
    Name: "Write Off - Land Rent",
  },
  {
    Code: "269",
    Name: "Write Off - Land Tax",
  },
  {
    Code: "266",
    Name: "Write Off - Rates",
  },
];
