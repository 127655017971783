import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { eProductType } from "@common/stores/products/enum";
import {
  CCMenuNodeDTO,
  ProductListingDTO,
} from "@common/stores/products/model";

export const checkIsActiveCrmsPhoneMessage = (activeProductsMap: {
  [key in eProductType]?: ProductListingDTO;
}) => {
  return (
    activeProductsMap[eProductType.CustomerService]?.Menu?.Nodes?.filter(
      (x: CCMenuNodeDTO) =>
        x.MenuItem_Id ===
        ECorporateSettingsField.CUSTOMERSERVICE_Views_PhoneMessages
    )?.length === 1
  );
};
