import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
import { criteriaAdvancedSearchList } from "./components/form-elements/criteria/config";

export const defaultCriteria = {
  CriteriaList: criteriaAdvancedSearchList,
  Criteria: [{ Criteria: "Assessment", Key: "Assessment" }],
};

export const defaultSearchDetail = {
  Filter: {
    Assessment: {
      logic: "and",
      filters: [
        // { field: "Number", operator: "eq" },
        // { field: "Reference", operator: "contains" },
        // { field: "PropertyAddress", operator: "startswith" },
        {
          field: "Primary Assess Land Use Name",
          operator: "contains",
        },
        {
          logic: "and",
          filters: [
            { field: "Balance", operator: "gte" },
            // { field: "Balance", operator: "lte" },
          ],
        },
      ],
    },
    Name: {},
    Parcel: {},
    Title: {
      logic: "and",
      filters: [
        // {
        //   logic: "and",
        //   filters: [
        //     { field: "Reference Number", operator: "gte" },
        //     { field: "Reference Number", operator: "lte" },
        //   ],
        // },
        // { field: "Plan Type", operator: "contains" },
        // { field: "Plan Number", operator: "eq" },
        { field: "Lot", operator: "eq" },
        { field: "Volume", operator: "startswith" },
        { field: "Folio", operator: "startswith" },
      ],
    },
    "Assessment Transaction": {},
    Arrangement: {},
    Certificate: {},
    "Change Of Ownership": {},
    Charge: {},
    "Debt Recovery": {},
    Entity: {},
    Levy: {},
    "Master Property": {},
    "Rebate Entitlements": {},
    Supplementary: {},
  },
};

export const assessmentColList: IColumnFields[] = [
  { field: "Number", title: "Number", format: NUMBER_FORMAT.NUMBER2 },
  { field: "Reference", title: "Reference" },
  { field: "Property Address", title: "Property Address" },
  {
    field: "Primary Assess Land Use Name",
    title: "Primary Assess Land Use Name",
  },
  { field: "Balance", title: "Balance", format: NUMBER_FORMAT.NUMBER2 },
  {
    field: "1996 Land Value",
    title: "1996 Land Value",
  },
  {
    field: "96 Reval Prop Num",
    title: "96 Reval Prop Num",
  },
  {
    field: "96 Revaluation Date",
    title: "96 Revaluation Date",
  },
  {
    field: "Alert Message",
    title: "Alert Message",
  },
  {
    field: "Area(SQM)",
    title: "Area(SQM)",
  },
  {
    field: "AS Is Assessment Editable",
    title: "AS Is Assessment Editable",
  },
  {
    field: "AS Is Rateable",
    title: "AS Is Rateable",
  },
  {
    field: "AS Reject Payments",
    title: "AS Reject Payments",
  },
  {
    field: "AS Reject Payments Message",
    title: "AS Reject Payments Message",
  },
  {
    field: "Assessment Group",
    title: "Assessment Group",
  },
  {
    field: "Assessment Id",
    title: "Assessment ID",
  },
  {
    field: "Aust Post Check Digit",
    title: "Austpost Check Digit",
  },
  {
    field: "Auto Property Address",
    title: "Auto Property Address",
  },
  {
    field: "Auto Update Legal Desc",
    title: "Auto Update Legal Desc",
  },
  {
    field: "Combined Alert Message",
    title: "Combined Alert Message",
  },
  {
    field: "Compliance Alert Message",
    title: "Compliance Alert Message",
  },
  {
    field: "Compliance Date From",
    title: "Compliance Date From",
  },
  {
    field: "Compliance Date To",
    title: "Compliance Date To",
  },
  {
    field: "Compliance Id",
    title: "Compliance ID",
  },
  {
    field: "Compliance Status",
    title: "Compliance Status",
  },
  {
    field: "Compliance StatusId",
    title: "Compliance Status ID",
  },
  {
    field: "Compliance Type",
    title: "Compliance Type",
  },
  {
    field: "Compliance Type Id",
    title: "Compliance Type ID",
  },
  {
    field: "Conservation Item",
    title: "Conservation Item",
  },
  {
    field: "Contaminated a",
    title: "Contaminated A",
  },
  {
    field: "Contaminated b",
    title: "Contaminated B",
  },
  {
    field: "Contaminated c",
    title: "Contaminated C",
  },
  {
    field: "Contaminated d",
    title: "Contaminated D",
  },
];
