import { CORE_INSPECTION_TYPES_ODATA } from "@app/core/components/common/constant";
import { colTopic } from "@app/products/crms/knowledge-base/[id]/components/forms/components/child-screens/general/components/form-element/components/dialogs/config";
import { notEmptyArrayValidator } from "@common/utils/field-validators";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { Button } from "@progress/kendo-react-buttons";
import { Field } from "@progress/kendo-react-form";
import React, { Fragment } from "react";

export const AddTopicDialog = (props: any) => {
  const topicFields = (fieldsProps: any) => ({
    isMultiSelect: false,
    titleHeader: "PICK TOPIC",
    placeholder: "",
    tableTypes: colTopic,
    url: CORE_INSPECTION_TYPES_ODATA,
    disabled: false,
    fieldName: "Topic",
    value: fieldsProps.valueGetter("Topic"),
    onChange: (event: any) => {
      if (event.target !== null) {
        fieldsProps.onChange("", {
          value: event.target.value,
        });
      } else {
        props.onChange("Topic", {
          value: event.detail.Name,
        });
      }
    },
  });

  return (
    <CCDialog
      maxWidth="55%"
      maxHeight="35%"
      onClose={() => props.onClosePopup()}
      titleHeader={props.titleHeader}
      bodyElement={
        <Fragment>
          <div className="cc-field-group">
            <div className="cc-form-cols-12">
              <div className="cc-field">
                <label className="cc-label">
                  Topic
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={"topicFields"}
                  {...topicFields(props)}
                  component={InputPicker}
                  validator={notEmptyArrayValidator}
                />
              </div>
            </div>
          </div>
        </Fragment>
      }
      footerElement={
        <Fragment>
          <div className={"cc-dialog-footer-actions-right"}>
            <Button
              className={"cc-dialog-button"}
              onClick={() => {
                props.onClosePopup();
              }}
            >
              Cancel
            </Button>
            <Button
              className={"cc-dialog-button"}
              themeColor="primary"
              onClick={() => {
                props.onClosePopup();
                props.onAddTopicPopup(props.valueGetter("Topic"));
              }}
            >
              {"Save & Exit"}
            </Button>
          </div>
        </Fragment>
      }
    />
  );
};
