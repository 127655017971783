import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { usePicStore } from "@app/products/property/pic/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export const ReactivePICButton = observer(() => {
  const { gridSelectedRows, isEmptyData } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const { setListDialog } = usePropertyWorkflowStore();
  const { pic } = usePicStore();

  const isDisabled = useMemo(() => {
    return (
      (!isEmptyData && gridSelectedRows.length !== 1 && !id) ||
      (!id && isEmptyData)
    );
  }, [gridSelectedRows.length, id, isEmptyData]);

  const statusId = useMemo(() => {
    if (!isNil(id)) {
      return pic?.PICStatusId;
    } else {
      return gridSelectedRows?.[0]?.PIC_Status_Id;
    }
  }, [gridSelectedRows, id, pic]);

  return (
    <CCNavButton
      title="Reactivate PIC"
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.PIC_Reactivate,
            data: {},
            props: {
              statusId,
            },
          },
        ]);
      }}
      disabled={isDisabled}
    />
  );
});
