import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { GoogleMapSettings, ISettings } from "@common/models/settings";
import { commonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";

export const getGoogleMapSetting = (
  settings: ISettings | null
): GoogleMapSettings => {
  const commonSettings = commonCoreStore?.settings;

  if (!commonSettings) {
    return settings?.googleMap as GoogleMapSettings;
  }

  const mapKey =
    getStringValueSetting(
      commonSettings[ECorporateSettingsField.CorporateSettings_GoogleMapAPIKey]
    ) ||
    settings?.googleMap?.key ||
    "";

  return {
    ...settings?.googleMap,
    key: mapKey,
  } as GoogleMapSettings;
};
