import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { DATE_FORMAT, ICON_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSCommunicationAllDocuments: IColumnFields[] = [
  {
    field: CRSFieldMapping.DisplayName,
    title: "User",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: CRSFieldMapping.BatchInformation,
    title: "Batch Information",
  },
  {
    field: CRSFieldMapping.DocumentInformation,
    title: "Document Information",
  },
  { field: CRSFieldMapping.ContentType, title: "Content Type" },
  {
    field: CRSFieldMapping.BatchNo,
    title: "Batch Number",
    format: DATE_FORMAT.DATE,
  },
  { field: CRSFieldMapping.SubBatchNo, title: "Sub Batch Number" },
  {
    field: CRSFieldMapping.Printed,
    title: "Printed",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
];
