import { DTO_PIC_Lookup } from "@app/products/property/components/dialogs/add-pic-lookup/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { CancelTokenSource } from "axios";

export const getSearchPicLookup = async (
  searchData: any,
  requestController?: CancelTokenSource
): Promise<APIResponse<DTO_PIC_Lookup>> => {
  try {
    return await CoreAPIService.getClient().post<DTO_PIC_Lookup>(
      `api/property/int/pic/lookup`,
      searchData,
      {
        cancelToken: requestController?.token,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error:
        requestController?.token.reason?.message ??
        errorResponse?.data?.Message,
    };
  }
};
