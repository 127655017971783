import { useAssessmentArrangementStore } from "@app/products/property/assessments/[id]/components/child-screens/arrangement/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { CCGrid } from "@components/cc-grid/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { colLettersSent, colPaymentSchedule } from "./config";

export const Arrangement = observer(() => {
  const { assessmentId } = useAssessmentStore();
  const {
    views,
    view,
    arrangement,
    isLoading,
    isDetailLoading,
    loadArrangement,
    loadViews,
    setView,
  } = useAssessmentArrangementStore();

  useEffect(() => {
    if (assessmentId) {
      loadViews(assessmentId);
    }
  }, [assessmentId, loadViews]);

  useEffect(() => {
    if (assessmentId && view) {
      loadArrangement(assessmentId, view?.Id);
    }
  }, [view, assessmentId, loadArrangement]);

  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <div className="cc-property-arrangement">
      <div className="cc-form">
        <section className="cc-field-group">
          {views.length ? (
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <DropDownList
                  name="CurrentArrangement"
                  className="cc-form-control"
                  textField="ListFormat"
                  dataItemKey="Id"
                  data={views || []}
                  value={view ?? {}}
                  onChange={(event: DropDownListChangeEvent) => {
                    setView(event.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}
          {isDetailLoading ? (
            <Loading isLoading={isDetailLoading} />
          ) : arrangement ? (
            <>
              {arrangement?.CurrentArrangement && (
                <React.Fragment>
                  <div className="cc-form-cols-3">
                    <CCValueField
                      label="Arrangement number"
                      name="ArrangementNumber"
                      value={arrangement?.CurrentArrangement.Number ?? ""}
                      format={NUMBER_FORMAT.NUMBER2}
                    />
                    <CCValueField
                      label="Arrangement date"
                      name="ArrangementDate"
                      value={arrangement?.CurrentArrangement.Date ?? ""}
                      format={DATE_FORMAT.DATE}
                    />
                    <CCValueField
                      label="Initial debt"
                      name="InitialDebt"
                      value={
                        arrangement?.CurrentArrangement.InitialDebtAmount ?? ""
                      }
                      format={CURRENCY_FORMAT.CURRENCY1}
                    />
                    <CCValueField
                      label="Balance"
                      name="Balance"
                      value={arrangement?.CurrentArrangement.Balance ?? ""}
                      format={CURRENCY_FORMAT.CURRENCY1}
                    />
                    <CCValueField
                      label="Overdue"
                      name="Overdue"
                      value={
                        arrangement?.CurrentArrangement.OverdueAmount ?? ""
                      }
                      format={CURRENCY_FORMAT.CURRENCY1}
                    />
                    <CCValueField
                      label="Authorising officer"
                      name="AuthorisingOfficer"
                      value={
                        arrangement?.CurrentArrangement.AuthorisingOfficer ?? ""
                      }
                    />
                    <CCValueField
                      label="Applicant"
                      name="Applicant"
                      value={
                        arrangement?.CurrentArrangement.ApplicantName ?? ""
                      }
                    />
                    <CCValueField
                      label="Status"
                      name="Status"
                      value={arrangement?.CurrentArrangement.Status ?? ""}
                    />
                    <CCValueField
                      label="Interest"
                      name="Interest"
                      value={
                        arrangement?.CurrentArrangement.InterestStatusName ?? ""
                      }
                    />
                  </div>
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">Comments</label>
                      <Input
                        className="cc-form-control"
                        value={
                          arrangement?.CurrentArrangement.Comments
                            ? arrangement?.CurrentArrangement.Comments
                            : "no Comments"
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Payment schedule</label>
                  <CCGrid
                    className="cc-arrangement-grid-data"
                    data={arrangement?.ArrangementInstalment ?? []}
                    columnFields={colPaymentSchedule}
                    primaryField="InstalmentNumber"
                  />
                </div>
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Letters sent</label>
                  <CCGrid
                    className="cc-arrangement-grid-data"
                    data={arrangement?.ArrangementLetter ?? []}
                    columnFields={colLettersSent}
                    primaryField="Id"
                  />
                </div>
              </div>
            </>
          ) : (
            <NoData vertical />
          )}
        </section>
      </div>
    </div>
  );
});
