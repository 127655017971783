import { INTEGRATED_CUSTOM_REPORTS_ROUTE } from "@common/pages/report/integrated-reports/custom-reports/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewReportsButton = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <CCNavButton
        title="Reports"
        onClick={() => {
          history.push(INTEGRATED_CUSTOM_REPORTS_ROUTE);
        }}
      />
    </React.Fragment>
  );
};
