import React, { ReactElement } from "react";
import "./_index.scss";
interface ICCSettingPanel {
  handleDivButton: () => void;
  title: string;
  buttonGroupElement: ReactElement;
  children: ReactElement;
  isExpanded: boolean;
  isBoldCollapse: boolean;
  classNameChildren?: string;
  isInvalid?: boolean;
}

export const CCSettingPanel = ({
  handleDivButton,
  title,
  buttonGroupElement,
  children,
  isExpanded = false,
  isBoldCollapse = false,
  classNameChildren = "",
  isInvalid = false,
}: ICCSettingPanel): ReactElement => {
  return (
    <>
      <div
        className={`cc-setting-button-container ${
          isBoldCollapse ? "cc-setting-bold-collapse" : ""
        } ${isInvalid ? "cc-panel-heading-line-invalid" : ""}`}
        onClick={(e: any) => {
          e.preventDefault();
          if (
            e.target.className.includes("fa-times-circle") ||
            e.target.className.includes("fa-check-circle") ||
            e.target.className.includes("fa-pencil")
          ) {
            return;
          }
          handleDivButton();
        }}
      >
        <h6
          className={`cc-collapse-title ${
            isInvalid ? "cc-panel-title-invalid" : ""
          }`}
        >
          {title ?? ""}
        </h6>
        <div
          className="cc-button-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          {buttonGroupElement}
        </div>
      </div>
      <div className={classNameChildren}>
        <div
          className={`${
            isExpanded ? "cc-setting-expanded" : "cc-setting-collapsed"
          }`}
        >
          {children}
        </div>
      </div>
    </>
  );
};
