import { getContactRelationshipType } from "@app/core/contacts/components/dialogs/additional-contact/api";
import { LoadingState } from "@app/core/contacts/components/dialogs/additional-contact/contants";
import { IAdditionalContactDialog } from "@app/core/contacts/components/dialogs/additional-contact/model";
import { processAdditionalContactOption } from "@app/core/contacts/components/dialogs/additional-contact/util";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { customLogger } from "@common/utils/logger";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

interface AdditionalContactDialogFormProps {
  loadingStatus: LoadingState;
  onClose: () => void;
  formRenderProps: FormRenderProps;
  recordType: RECORDTYPE;
  setLoadingStatus: React.Dispatch<React.SetStateAction<LoadingState>>;
  notificationRef: React.RefObject<ICCLocalNotificationHandle>;
}

const nameOf = nameOfFactory<IAdditionalContactDialog>();
export const AdditionalContactDialogForm = ({
  onClose,
  loadingStatus,
  formRenderProps,
  recordType,
  setLoadingStatus,
  notificationRef,
}: AdditionalContactDialogFormProps) => {
  const [relationshipType, setRelationshipType] = useState<
    IKeyValuePacket[] | undefined
  >();
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const options = useMemo(
    () => processAdditionalContactOption(recordType),
    [recordType]
  );

  const handleLoadRelationshipType = async () => {
    setLoadingStatus(LoadingState.GET);
    let relationshipType = undefined;
    let errorResponse = undefined;
    const response = await getContactRelationshipType(
      options.relationshipFilters
    );
    customLogger("Core Contact, Add contact, getContactRelationshipType").info(
      response?.data
    );
    if (isSuccessResponse(response)) {
      relationshipType = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    formRenderProps.onChange?.("RelationshipType", {
      value: relationshipType?.[0]?.Key,
    });
    setRelationshipType(relationshipType);
    setResponseLoadError(errorResponse);
    setLoadingStatus(LoadingState.NONE);
  };

  useEffectOnce(() => {
    handleLoadRelationshipType();
  });

  return (
    <CCDialog
      maxWidth="35%"
      height="auto"
      disabled={loadingStatus !== LoadingState.NONE}
      titleHeader={"Add Additional Contact"}
      onClose={onClose}
      bodyElement={
        loadingStatus === LoadingState.GET ? (
          <Loading isLoading />
        ) : responseLoadError ? (
          <CCLoadFailed
            responseError={responseLoadError}
            onReload={handleLoadRelationshipType}
          />
        ) : (
          <div className="cc-form">
            <CCLocalNotification ref={notificationRef} />
            <FormElement className="cc-field-group">
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <CCLabel title="Relationship type" isMandatory />
                  <Field
                    name={nameOf("RelationshipType")}
                    component={CCSearchComboBox}
                    validator={requiredValidator}
                    data={relationshipType}
                    textField="Value"
                    dataItemKey="Key"
                    isUseDefaultOnchange
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Contact" isMandatory />
                  <Field
                    name={nameOf("Contact")}
                    component={ContactPicker}
                    isLoadDetail={false}
                    validator={requiredValidator}
                    contactClassificationFilters={options.contactFilters}
                  />
                </div>
              </div>
            </FormElement>
          </div>
        )
      }
      footerElement={
        <div className={"cc-dialog-footer-actions-right"}>
          <Button className={"cc-dialog-button"} onClick={onClose}>
            Close
          </Button>
          <Button
            disabled={!formRenderProps.modified || !formRenderProps.valid}
            themeColor="primary"
            onClick={formRenderProps.onSubmit}
            iconClass={
              loadingStatus === LoadingState.SAVE
                ? "fas fa-spinner fa-spin"
                : ""
            }
            className={"cc-dialog-button"}
          >
            Save
          </Button>
        </div>
      }
    />
  );
};
