import {
  AssessmentModel,
  IAssessmentModel,
} from "@app/products/ams/model/store";
import { action, configure, makeObservable, observable } from "mobx";
import inspectionQuestionCfg from "./inspectionAssessment-data.json";

configure({ enforceActions: "always" }); // Strict mode: runInAction

export default class InspectionAssessmentStore {
  initialising: boolean = true;
  assessmentTemplates: IAssessmentModel[] = [];

  constructor() {
    makeObservable(this, {
      initialising: observable,
      assessmentTemplates: observable,
      loadTemplates: action,
      updateInspectionTemplate: action,
    });
  }

  //@observable editingInspectionTemplate: IAssessmentModel | null = null;

  loadTemplates() {
    this.assessmentTemplates = (
      inspectionQuestionCfg as IAssessmentModel[]
    ).map((a) => new AssessmentModel(a));
    this.initialising = false;
  }

  updateInspectionTemplate(item: IAssessmentModel) {
    this.assessmentTemplates[0] = item; //we only have one at the moment
  }

  /*   @action setEditingTemplate(item: IAssessmentModel) {
    this.editingInspectionTemplate = item;
  } */
}
