import { RegisterAddressPopup } from "@app/products/property/components/register-address-popup/_index";
import { TownPlanningRegisterForm } from "@app/products/town-planning/[id]/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { FormRoute } from "@common/constants/menu";
import { useIsNew } from "@common/hooks/useIsNew";
import { IParentSection } from "@common/pages/contacts/model";
import { nameOfFactory, populateValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { formatNumberByKendo } from "@common/utils/formatting";
import { CCTooltip } from "@components/cc-tooltip/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React, { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";

const nameOf = nameOfFactory<TownPlanningRegisterForm>();

export function TownPlanningParentSection(
  formRenderProps: FormRenderProps
): ReactElement {
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const history = useHistory();

  const getValue = formRenderProps.valueGetter;
  const isNew = useIsNew();

  const navigateToApplicantContactRelationship = () => {
    const parentPage: IParentSection[] = [
      {
        key: 1,
        label: "Application",
        value: "20/0348 40 Lilliput Lane Pakenham VIC 3810",
      },
      { key: 2, label: "Category", value: "Full Application 300 m2" },
      { key: 3, label: "Applicant", value: "Harry Daglas" },
    ];
    const applicant_rid = getValue("Applicant_RID");
    const isNull = !getValue("ApplicantName");
    if (isNull) {
      return history.push(
        `${FormRoute.FORM_CONTACT_REPLATIONSHIP}/${
          isNew ? "new" : applicant_rid
        }`,
        {
          flag_ContactFromNar: true,
          title: "Applicant",
        }
      );
    } else {
      return history.push(
        `${FormRoute.FORM_CONTACT_REPLATIONSHIP}/${applicant_rid}`,
        {
          parentPage,
          flag_ContactFromNar: true,
          title: "Applicant",
        }
      );
    }
  };

  const navigateToContactPreferenceContactRelationship = () => {
    const parentPage: IParentSection[] = [
      {
        key: 1,
        label: "Application",
        value: "20/0348 40 Lilliput Lane Pakenham VIC 3810",
      },
      { key: 2, label: "Category", value: "Full Application 300 m2" },
      { key: 3, label: "Applicant", value: "Harry Daglas" },
    ];
    const preferredContact_rid = getValue("PreferredContact_RID");
    const isNull = !getValue("PreferredName");
    if (isNull) {
      return history.push(
        `${FormRoute.FORM_CONTACT_REPLATIONSHIP}/${
          isNew ? "new" : preferredContact_rid
        }`,
        {
          flag_ContactFromNar: true,
          title: "Contact Primary",
        }
      );
    } else {
      return history.push(
        `${FormRoute.FORM_CONTACT_REPLATIONSHIP}/${preferredContact_rid}`,
        {
          parentPage,
          flag_ContactFromNar: true,
          title: "Contact Primary",
        }
      );
    }
  };

  return (
    <>
      {showAddressPopup && (
        <RegisterAddressPopup onClosePopup={() => setShowAddressPopup(false)} />
      )}
      {/* SECTION 1 */}
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Applicant
              <CCTooltip type="validator" position="right" />
            </label>

            <Field
              onClick={navigateToApplicantContactRelationship}
              name={nameOf("ApplicantName")}
              disabled={true}
              validator={requiredValidator}
              component={InputPicker}
              placeholder={"Applicant"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Postal address</label>
            <div className="cc-label-field">
              {populateValue(
                getValue("Applicant.Contact.PreferredAddress.StreetName")
              )}
            </div>
          </div>
          <div className="cc-field">
            <div className="d-flex">
              <label className="cc-label">Contact</label>
            </div>
            <div className="cc-label-field">
              {populateValue(getValue("Applicant.Contact._Mobile"))}
            </div>
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <div className="d-flex">
              <label className="cc-label">Preferred contact</label>
              <CCTooltip type="validator" position="right" />
            </div>
            <Field
              onClick={navigateToContactPreferenceContactRelationship}
              name={nameOf("PreferredName")}
              disabled={true}
              validator={requiredValidator}
              component={InputPicker}
              placeholder={"Preferred contact"}
            />
          </div>
          <div className="cc-field">
            <div className="d-flex">
              <label className="cc-label">Postal address</label>
            </div>
            <div className="cc-label-field">
              {populateValue(
                getValue("PreferredContact.Contact.PreferredAddress.StreetName")
              )}
            </div>
          </div>
          <div className="cc-field">
            <div className="d-flex">
              <label className="cc-label">Contact</label>
            </div>
            <div className="cc-label-field">
              {populateValue(getValue("PreferredContact.Contact._Mobile"))}
            </div>
          </div>
        </div>
      </div>
      <hr className="cc-divider" />
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Site address
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              onClick={() => setShowAddressPopup(true)}
              name={nameOf("SiteAddressOwner")}
              disabled={true}
              validator={requiredValidator}
              component={InputPicker}
              placeholder={"Site address"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Zone</label>
            <div className="cc-label-field">
              {populateValue(getValue("SiteAddress.PropertyAssessment.Zones"))}
            </div>
          </div>
          <div className="cc-field">
            <label className="cc-label">Postal address</label>
            <div className="cc-label-field">
              {populateValue(
                getValue("Applicant.Contact.PreferredAddress.StreetName")
              )}
            </div>
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Owner</label>
            <div className="cc-label-field">
              {populateValue(
                getValue("SiteAddress.PropertyAssessment.Owners_Name")
              )}
            </div>
          </div>
          <div className="cc-field">
            <label className="cc-label">Details</label>
            <div className="cc-label-field">
              {populateValue(
                getValue("SiteAddress.PropertyAssessment.Legal_Description")
              )}
            </div>
          </div>
          <div className="cc-field">
            <label className="cc-label">Subdivision</label>
            <div className="cc-label-field">
              {populateValue(getValue("Subdivision"))}
            </div>
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Ward</label>
            <div className="cc-label-field">
              {populateValue(getValue("SiteAddress.PropertyAssessment.Ward"))}
            </div>
          </div>
          <div className="cc-field">
            <label className="cc-label">RIN</label>
            <div className="cc-label-field">
              {populateValue(getValue("SiteAddress.PropertyAssessment.RIN"))}
            </div>
          </div>
          <div className="cc-field">
            <label className="cc-label">Land size</label>
            <div className="cc-label-field">
              {formatNumberByKendo(
                getValue("SiteAddress.PropertyAssessment.Assessment_LandSize"),
                NUMBER_FORMAT.NUMBER1
              )}
            </div>
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Lot number</label>
            <div className="cc-label-field">
              {populateValue(getValue("SiteAddress.PropertyAssessment.LotNo"))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
