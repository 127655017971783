import {
  ADDRESS_VALIDATION_FORM_STEP,
  AddressValidationStep,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/_index";
import { AddressValidationType } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/model";
import {
  ADDRESS_FORM_STEP,
  AddressStep,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address/_index";
import {
  DETAIL_FORM_STEP,
  DetailStep,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/detail/_index";
import {
  MAP_FORM_STEP,
  MapStep,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/map/_index";
import {
  PEOPLE_FORM_STEP,
  PeopleStep,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/people/_index";
import {
  SUMMARY_FORM_STEP,
  SummaryStep,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/summary/_index";
import { checkEmptySimpleAddressForm } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/util";
import { Address_BuildAddress } from "@app/products/town-planning/ppr/[id]/model";
import {
  Address,
  AddressClassification,
} from "@app/products/waste-water/[id]/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import {
  getDisplayBuildingUnitNumber,
  getDisplayFloorNumber,
  getDisplayHouseNumber,
  getDisplayStreetName,
} from "@common/utils/formatting";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { isEmpty, isEqual } from "lodash";
import React, { useMemo, useState } from "react";

export interface IApplicationDescriptionProps {
  initialData: any;
  onClose: () => void;
  onSubmit: (value: any) => void;
  isLoading?: boolean;
}
export const ComplexPropertyDetail = (props: IApplicationDescriptionProps) => {
  const { initialData, isLoading, onClose, onSubmit } = props;

  const { settings } = useCommonProductStore();
  const [originalFormData, setOriginalFormData] = useState<Address>();
  const addressValidationSetting = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_AddressValidation]
  );

  const isValidateAddress = useMemo(() => {
    if (
      addressValidationSetting &&
      addressValidationSetting === AddressValidationType.DELWPMapShare
    ) {
      return true;
    } else {
      return false;
    }
  }, [addressValidationSetting]);

  const steps: IStep[] = [
    {
      label: "Address",
      component: AddressStep,
      visible: true,
      key: ADDRESS_FORM_STEP,
    },
    {
      label: "Address validation",
      component: AddressValidationStep,
      visible: isValidateAddress,
      key: ADDRESS_VALIDATION_FORM_STEP,
      options: {
        setOriginalFormData,
      },
    },
    {
      label: "Map",
      component: MapStep,
      visible: true,
      key: MAP_FORM_STEP,
    },
    {
      label: "Detail",
      component: DetailStep,
      visible: true,
      key: DETAIL_FORM_STEP,
    },
    {
      label: "People",
      component: PeopleStep,
      visible: true,
      key: PEOPLE_FORM_STEP,
    },
    {
      label: "Summary",
      component: SummaryStep,
      visible: true,
      key: SUMMARY_FORM_STEP,
    },
  ];
  const handleOnSubmit = (value: any) => {
    delete value._option;

    const buildAddress: Address_BuildAddress = { ...value };
    buildAddress.bAddressChanged = true;

    if (value.Address) {
      if (checkEmptySimpleAddressForm(value.Address)) {
        value.Address.VMAS_Verified = undefined;
      }
      if (!isEqual(originalFormData, value.Address)) {
        value.Address.VMAS_Verified = false;
      }

      buildAddress.Address.AddressClassification_ENUM =
        AddressClassification.Property;
      buildAddress.Address.UnitNo =
        (!isEmpty(
          getDisplayFloorNumber(buildAddress?.Address?.PropertyAssessment)
        )
          ? `${getDisplayFloorNumber(
              buildAddress?.Address?.PropertyAssessment
            )} `
          : "") +
        getDisplayBuildingUnitNumber(buildAddress?.Address?.PropertyAssessment);
      buildAddress.Address.StreetNo = getDisplayHouseNumber(
        buildAddress?.Address?.PropertyAssessment
      );
      buildAddress.Address.StreetName = getDisplayStreetName(
        buildAddress?.Address?.PropertyAssessment
      );
    }
    onSubmit(buildAddress as Address_BuildAddress);
  };
  return (
    <CCFormStep
      onSubmit={handleOnSubmit}
      initialSteps={steps}
      initialValues={initialData}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="60%"
          titleHeader={"Property Details"}
          onClose={onClose}
          bodyElement={
            isLoading ? <Loading isLoading={isLoading} /> : renderProps.children
          }
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              <Button className={"cc-dialog-button"} onClick={onClose}>
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                disabled={renderProps.nextButton.disabled}
                className={"cc-dialog-button"}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
