import { getDisplayTextWithDashes } from "@common/utils/common";

export const SecurityCorporateAuthBookmark = {
  getBookmarkListViewDisplayName() {
    return "Settings - Security";
  },

  getBookmarkListViewDetail() {
    return "Settings - Security - Corporate Authorisations";
  },

  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.CorporateAuthorisationName
      ? `- ${selectedRow.ID}`
      : "";
    return `Settings - Corporate Authorisations ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.ID,
      selectedRow.CorporateAuthorisationName,
    ]);
  },
};
