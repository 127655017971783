import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";

interface INewPPRApplicationButtonProps {
  title?: string;
}

export const NewPPRApplicationButton = ({
  title = "New Application",
}: INewPPRApplicationButtonProps) => {
  return (
    <CCNavButton title={title} linkTo={`${TOWN_PLANNING_PPR_ROUTE}/new`} />
  );
};
