import {
  DTO_Journal_Add_Transaction_LOVs,
  DTO_Workflow_JournalAddTransaction,
  RequestJournalAddTransactionObj,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/model";
import { WorkflowProcessMode } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postProcessAddJournalTransaction = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_JournalAddTransaction
): Promise<APIResponse<any>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/addjournaltransaction/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataJournal =
  | [
      APIResponse<DTO_Journal_Add_Transaction_LOVs>,
      APIResponse<DTO_Workflow_JournalAddTransaction>
    ]
  | APIResponse<
      DTO_Journal_Add_Transaction_LOVs | DTO_Workflow_JournalAddTransaction
    >
  | undefined;
export const getInitialDataJournalTransaction = async (
  workflowDraftId: number | undefined,
  journalNumber: number,
  isNewJournal: boolean = false
): Promise<IGetInitialDataJournal> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_Add_Transaction_LOVs>(
        `api/property/internal/journal/lovs`
      ),
      !isNewJournal && !isNil(workflowDraftId)
        ? getWorkflowAddJournalTransaction(workflowDraftId)
        : getNewWorkflowAddJournalTransaction({
            Journal_Number: journalNumber ?? 0,
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowAddJournalTransaction = async (
  requestObj: RequestJournalAddTransactionObj | object = {}
): Promise<APIResponse<DTO_Workflow_JournalAddTransaction>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/addjournaltransaction/new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowAddJournalTransaction = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_JournalAddTransaction>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/addjournaltransaction/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
