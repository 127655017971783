import { getData } from "@app/products/crms/phone-messages/[id]/api";
import { searchAddressesConfig } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/property-address-picker/config";
import { isSuccessResponse } from "@common/apis/util";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { IColumnFields } from "@components/cc-grid/model";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { FormRenderProps } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { isNil } from "lodash";
import React, { ReactElement, useMemo, useRef, useState } from "react";
import "./_index.scss";

export interface IContactComboBoxSearchProps extends ComboBoxProps {
  visited?: boolean;
  onError: (value: any) => void;
  textField?: string;
  dataItemKey?: string;
  name: string;
  columnFields: IColumnFields[];
  formRenderProps: FormRenderProps;
  dataUrl: string;
  filterField?: string;
  refactorData?: (data: any[]) => any[];
  isExternal?: boolean;
  onChangeHandler?: (event: ComboBoxChangeEvent) => void;
  isForward?: boolean;
}

export const ContactComboBoxSearch = (props: IContactComboBoxSearchProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(undefined);
  const refTimeOut = useRef<NodeJS.Timeout | null>(null);

  const {
    visited,
    validationMessage,
    onError,
    name,
    columnFields,
    dataUrl,
    dataItemKey,
    filterField,
    formRenderProps,
    isExternal = false,
    onChangeHandler,
    value,
    textField,
    disabled = false,
    isForward = false,
  } = props;

  const { onChange: onChangeForm } = formRenderProps;

  const comboBoxValue = useMemo(() => {
    if (isNil(value)) return "";
    if (textField && value.hasOwnProperty(textField)) return value[textField];
    return value;
  }, [value, textField]);

  const getContacts = async (filterSearch?: string) => {
    setIsLoading(true);
    const response = await getData(dataUrl, filterSearch);
    setIsLoading(false);
    if (isSuccessResponse(response) && response.data) {
      setData(isExternal ? response.data : response.data.value);
    } else {
      onError({
        autoClose: false,
        title: "Get data fail.",
        type: "error",
        description: response.error,
      });
    }
  };

  // handle event
  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    const searchText = event.filter.value;
    setData([]);

    if (searchText.length < searchAddressesConfig.minCharacters) return;

    if (refTimeOut.current) clearTimeout(refTimeOut.current);
    refTimeOut.current = setTimeout(() => {
      if (filterField) {
        getContacts(`&$filter=contains(${filterField},'${searchText}')`);
      } else {
        getContacts(searchText);
      }
    }, searchAddressesConfig.typeSpeed);
  };

  const handleOnChange = (event: ComboBoxChangeEvent) => {
    if (dataItemKey) {
      onChangeForm(name, {
        value: event.value?.[dataItemKey],
      });
    }
    if (onChangeHandler) onChangeHandler(event);
  };

  return (
    <>
      <ComboBox
        filterable
        data={data}
        loading={isLoading}
        onFilterChange={handleSearch}
        itemRender={(
          li: ReactElement<HTMLLIElement>,
          itemProps: ListItemProps
        ) => ItemRender(li, itemProps, columnFields)}
        value={comboBoxValue}
        clearButton
        header={
          <div className="cc-search-header">
            {columnFields.map((col: IColumnFields) => (
              <div key={col.field}>{col.title}</div>
            ))}
          </div>
        }
        onChange={handleOnChange}
        popupSettings={{
          className: `${
            isExternal
              ? "cc-contact-search-external"
              : "cc-contact-search-internal"
          } ${isForward ? "cc-forward-internal" : ""}`,
        }}
        disabled={disabled}
      />

      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};

const ItemRender = (
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps,
  columnFields: IColumnFields[]
) => {
  const { dataItem } = itemProps;
  const itemChildren = (
    <div className="cc-search-item">
      {columnFields.map((col: IColumnFields) => (
        <div key={col.field}>{sanitizeHtml(dataItem[col.field] ?? "")}</div>
      ))}
    </div>
  );
  return React.cloneElement(li, li.props, itemChildren);
};
