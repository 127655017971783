import { IAddSubMeterData } from "@app/products/property/meters/[id]/components/action-bar/dialogs/add-master-sub-meter/model";
import { MasterSubEnum } from "@app/products/property/meters/[id]/components/child-screens/installation/model";
import { getUUID } from "@common/utils/common";

export const initialValuesAddMeter: IAddSubMeterData = {
  Id: getUUID(),
  AssociatedFrom: new Date(),
  MasterSub: MasterSubEnum.MasterMeter,
  InstallationMeter: null,
  _option: {
    InstallationMeter: {
      Data: [],
    },
    MasterSub: {
      Data: Object.values(MasterSubEnum).map((e) => ({
        label: e,
        value: e,
      })),
    },
  },
};
