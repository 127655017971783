import { useIsNew } from "@common/hooks/useIsNew";
import { CheckPermissionSettingWrapper } from "@common/pages/settings/components/check-permission-wrapper/_index";
import { ILookupDocumentParentSection as ILookupStateRoadParentSection } from "@common/pages/settings/lookups/documents/_id/model";
import { ExistedLookupStateRoad } from "@common/pages/settings/lookups/state-roads/_id/components/forms/existed/_index";
import { NewLookupStateRoad } from "@common/pages/settings/lookups/state-roads/_id/components/forms/new/_index";
import { useLookupStateRoadStore } from "@common/pages/settings/lookups/state-roads/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageLookupStateRoad = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const stateRoadId = params.id;
  const location = useLocation();
  const { resetStore, loadStateRoad, setParentSection } =
    useLookupStateRoadStore();
  const { resetMenu } = useCCSubActionBarStore();

  const [isHasPermission, setIsHasPermission] = useState<boolean>(false);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  useEffect(() => {
    if (location.state) {
      setParentSection(location.state as ILookupStateRoadParentSection);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  useEffect(() => {
    if (isHasPermission) {
      loadStateRoad(parseInt(stateRoadId), isNew);
    }
  }, [stateRoadId, isNew, loadStateRoad, isHasPermission]);

  return (
    <CheckPermissionSettingWrapper setIsHasPermission={setIsHasPermission}>
      {isNew ? <NewLookupStateRoad /> : <ExistedLookupStateRoad />}
    </CheckPermissionSettingWrapper>
  );
};

export default observer(ManageLookupStateRoad);
