import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { ServiceStandardView } from "@app/products/crms/service-standards/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const CRMSServiceStandardsBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `${CRMS_PRODUCT_NAME} - Service Standard ${dynamicDisplayName}`;
  },
  getBookmarkDetail() {
    return `${CRMS_PRODUCT_NAME}  Service Standard`;
  },
};

const nameOf = nameOfFactory<ServiceStandardView>();

export const columnFieldsCustom = (
  serviceStandardCategoryLevels: number,
  colDefault: IColumnFields[],
  label3: string | null,
  label4: string | null
): IColumnFields[] => {
  const subCategoryIndex = colDefault.findIndex(
    (item) => item.field === nameOf("SubCategory")
  );

  if (subCategoryIndex === -1) return colDefault;

  let newCols: IColumnFields[] = [...colDefault];
  if (serviceStandardCategoryLevels > 2) {
    newCols.splice(subCategoryIndex + 1, 0, {
      field: nameOf("SubCategoryLevel3"),
      title: label3 ?? "Sub Category Level 3",
    });

    if (serviceStandardCategoryLevels > 3) {
      newCols.splice(subCategoryIndex + 2, 0, {
        field: nameOf("SubCategoryLevel4"),
        title: label4 ?? "Sub Category Level 4",
      });
    }
  }
  return newCols;
};
