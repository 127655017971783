import { WastewaterUrl } from "@app/products/waste-water/config";
import { GridSiteUser } from "@app/products/waste-water/model";
import { getWasteWaterLightWeightSiteUser } from "@app/products/waste-water/[id]/api";
import { WastewaterParentSection } from "@app/products/waste-water/[id]/components/child-screen/general/components/form-element/components/parent-section/_index";
import { colSiteUser } from "@app/products/waste-water/[id]/components/child-screen/general/config";
import {
  SiteUser,
  WasteWaterSystemRegisterSummary,
} from "@app/products/waste-water/[id]/model";
import { useWasteWaterStore } from "@app/products/waste-water/[id]/store";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  formatDateByKendo,
  formatDisplayValue,
} from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";

interface IWastewaterFormElementProps {
  formRenderProps: FormRenderProps;
}

const WastewaterFormElement = observer(
  ({ formRenderProps }: IWastewaterFormElementProps) => {
    const { wastewater } = useWasteWaterStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { modified, onSubmit, valid, onChange } = formRenderProps;

    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (modified) {
        onSubmit(event);
        return;
      }

      if (!valid) return;
      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    const nameOf = nameOfFactory<WasteWaterSystemRegisterSummary>();

    return (
      <FormElement>
        {/* Reference by a label in WastewaterActionBar.tsx */}
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />

        <WastewaterParentSection formRenderProps={formRenderProps} />

        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Permit number</label>
              <Field
                autoComplete="off"
                name={nameOf("PermitNumber")}
                placeholder={"Permit number"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Debtor number</label>
              <Field
                autoComplete="off"
                name={nameOf("DebtorNo")}
                placeholder={"Debtor number"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">File number</label>
              <Field
                autoComplete="off"
                name={nameOf("FileNumber")}
                placeholder={"File number"}
                component={CCInput}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">PTI</label>
              <Field
                name={nameOf("Assessment_ApprovalPTIDate")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Issuing officer</label>
              <Field
                name={"Assessment_ApprovalPTIOfficer.DisplayName"}
                titleHeader="Pick Issuing Officer"
                fieldName={"Assessment_ApprovalPTIOfficer.DisplayName"}
                component={InputPicker}
                isMultiSelect={false}
                url={WastewaterUrl.GET_WASTEWATER_ALL_SITEUSER}
                placeholder="Issuing officer"
                readOnly
                tableTypes={colSiteUser}
                onSelect={(selectedData: GridSiteUser[]) => {
                  getWasteWaterLightWeightSiteUser(
                    selectedData[0].ID.toString()
                  ).then((data?: SiteUser) => {
                    if (data) {
                      onChange("Assessment_ApprovalPTIOfficer", {
                        value: data,
                      });
                      onChange("Assessment_ApprovalPTIOfficer_ID", {
                        value: selectedData[0].ID,
                      });
                    }
                  });
                }}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Responsible auth approved</label>
              <Field
                name={nameOf("RespAuthApproved")}
                defaultChecked={
                  wastewater?.RespAuthApproved
                    ? wastewater?.RespAuthApproved
                    : false
                }
                component={CCSwitch}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Approval to use</label>
              <Field
                name={nameOf("Assessment_FinalAssessmentCTUDate")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Pick approval officer</label>
              <Field
                name={"Assessment_FinalAssessmentCTUOfficer.DisplayName"}
                titleHeader="Pick Issuing Officer"
                fieldName={"Assessment_FinalAssessmentCTUOfficer.DisplayName"}
                component={InputPicker}
                isMultiSelect={false}
                url={WastewaterUrl.GET_WASTEWATER_ALL_SITEUSER}
                placeholder="Approval officer"
                readOnly
                tableTypes={colSiteUser}
                onSelect={(selectedData: GridSiteUser[]) => {
                  getWasteWaterLightWeightSiteUser(
                    selectedData[0].ID.toString()
                  ).then((data?: SiteUser) => {
                    if (data) {
                      onChange("Assessment_FinalAssessmentCTUOfficer", {
                        value: data,
                      });
                      onChange("Assessment_FinalAssessmentCTUOfficer_ID", {
                        value: selectedData[0].ID,
                      });
                    }
                  });
                }}
              />
            </div>
            <CCValueField
              label="OS fees"
              value={wastewater?.OSFees}
              format={CURRENCY_FORMAT.CURRENCY1}
            />
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Certificate number</label>
              <Field
                autoComplete="off"
                name={nameOf("ComplianceCertificateNo")}
                placeholder={"Certificate number"}
                component={CCInput}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Approval for BL</label>
              <Field
                name={nameOf("ApproveForBLDate")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">B. license number</label>
              <Field
                autoComplete="off"
                name={nameOf("BLicenceNumber")}
                placeholder={"B. license number"}
                component={CCInput}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Sent to dept</label>
              <Field
                name={nameOf("SentToDOHDate")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Approved by dept</label>
              <Field
                name={nameOf("DOHApprovalDate")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Dept approval number</label>
              <Field
                autoComplete="off"
                name={nameOf("DOHApprovalNumber")}
                placeholder={"Dept approval number"}
                component={CCInput}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Serial number</label>
              <Field
                autoComplete="off"
                name={nameOf("SerialNumber")}
                placeholder={"Serial number"}
                component={CCInput}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCValueField
              label="Last inspection"
              value={
                wastewater?.LastInspection ?? wastewater?.LastInspection
                  ? formatDateByKendo(
                      wastewater?.LastInspection,
                      DATE_FORMAT.DATE
                    )
                  : "Not Yet Inspected"
              }
            />
            <div className="cc-field">
              <label className="cc-label">Frequency</label>
              <Field
                autoComplete="off"
                name={nameOf("InspectionFrequency")}
                placeholder="Frequency"
                spinners={false}
                format={NUMBER_FORMAT.NUMBER1}
                component={NumericTextBox}
              />
            </div>
            <CCValueField
              label="Next inspection"
              value={
                wastewater?.NextInspection
                  ? formatDateByKendo(
                      wastewater?.NextInspection,
                      DATE_FORMAT.DATE
                    )
                  : "Not Yet Inspected"
              }
            />
          </div>
          <div className="cc-form-cols-3">
            <CCValueField
              label="Last service"
              value={
                wastewater?.LastServiced
                  ? formatDisplayValue(
                      wastewater?.LastServiced,
                      DATE_FORMAT.DATE
                    )
                  : "Not Yet Serviced"
              }
            />
            <CCValueField
              label="Last desludge"
              value={
                wastewater?.LastDesludged
                  ? formatDisplayValue(
                      wastewater?.LastDesludged,
                      DATE_FORMAT.DATE
                    )
                  : "Not Yet Desludged"
              }
            />
            <CCValueField
              label="Last sample"
              value={
                wastewater?.LastSampled
                  ? formatDisplayValue(
                      wastewater?.LastSampled,
                      DATE_FORMAT.DATE
                    )
                  : "Not Yet Sampled"
              }
            />
          </div>
          <div className="cc-form-cols-3">
            <CCValueField
              label="Next service"
              value={
                wastewater?.NextService
                  ? formatDisplayValue(
                      wastewater?.NextService,
                      DATE_FORMAT.DATE
                    )
                  : "Not Yet Serviced"
              }
            />
            <CCValueField
              label="Next desludge"
              value={
                wastewater?.NextDesludge
                  ? formatDisplayValue(
                      wastewater?.NextDesludge,
                      DATE_FORMAT.DATE
                    )
                  : "Not Yet Desludged"
              }
            />
            <CCValueField
              label="Next sample"
              value={
                wastewater?.NextSample
                  ? formatDisplayValue(wastewater?.NextSample, DATE_FORMAT.DATE)
                  : "Not Yet Sampled"
              }
            />
          </div>
        </div>
        <hr className="cc-divider" />

        <div className="cc-field-group">
          <div className="cc-form-row">
            <div className="cc-field">
              <label className="cc-label">Comments</label>
              <Field
                name={nameOf("Comments")}
                placeholder="Comments"
                rows={4}
                component={CCTextArea}
              />
            </div>
          </div>
        </div>
      </FormElement>
    );
  }
);

export default WastewaterFormElement;
