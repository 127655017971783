import { ESSENTIAL_SAFETY_MANAGE_ROUTE } from "@app/products/building/registers/essential-safety/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewEssentialSafetyButton = () => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New Essential Safety"
      onClick={() => {
        history.push(`${ESSENTIAL_SAFETY_MANAGE_ROUTE}/new`);
      }}
    />
  );
};
