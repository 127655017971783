import { CRMS_SERVICE_STANDARDS_ROUTE } from "@app/products/crms/service-standards/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

export interface ICloneNewButtonProps {
  isDisabled?: boolean;
}

export const CloneNewButton = observer(
  ({ isDisabled }: ICloneNewButtonProps) => {
    const history = useHistory();
    const { gridSelectedRows, lastSelectedId } = useCCProductListViewStore();

    return (
      <CCNavButton
        title="Clone New"
        disabled={gridSelectedRows.length !== 1 || isDisabled}
        onClick={() => {
          history.push(`${CRMS_SERVICE_STANDARDS_ROUTE}/new`, {
            cloneNew: {
              id: lastSelectedId ?? 0,
              isClone: true,
            },
          });
        }}
      />
    );
  }
);
