import { IExecutionData } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-assessment-interest/components/form-elements/task-schedule/model";

export const actionTaskScheduleData: IExecutionData[] = [
  {
    label: "Run immediately",
    value: true,
  },
  {
    label: "Run at",
    value: false,
  },
];
