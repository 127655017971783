import { AssignDialog } from "@app/products/property/fire-prevention/components/dialogs/assign/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const AssignButton = observer(() => {
  const [showAssignDialog, setShowAssignDialogs] = useState<boolean>(false);
  const { gridSelectedRows } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();

  const isDisabled = useMemo(() => {
    return !(
      gridSelectedRows.length &&
      gridSelectedRows?.every(
        (item) => item.AssignedContractor === "<To be Assigned>"
      )
    );
  }, [gridSelectedRows]);
  return (
    <>
      <CCNavButton
        title="Assign"
        onClick={() => setShowAssignDialogs(true)}
        disabled={isDisabled}
      />

      {showAssignDialog && (
        <AssignDialog
          onClose={() => {
            setShowAssignDialogs(false);
          }}
          onAssign={(data) => {
            pushNotification({
              title: "Contractor assigned successfully",
              type: "success",
            });
            setShowAssignDialogs(false);
          }}
        />
      )}
    </>
  );
});
