import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { AddressValidationType } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IGlobalSettingsProps {
  formRenderProps: FormRenderProps;
}

export default observer(({ formRenderProps }: IGlobalSettingsProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter } = formRenderProps;
  const data = useDataSetting(configData, [
    "CorporateSettings_AddressValidation",
    "CorporateSettings_VMAS2_Feature",
    "CorporateSettings_VMAS2_Username",
    "CorporateSettings_VMAS2_Password",
    "CorporateSettings_VMAS2_UseProxy",
    "CorporateSettings_DataTools_Api_Key",
    "CorporateSettings_DataTools_MaxNumResults",
    "CorporateSettings_DataTools_UseProxy",
    "CorporateSettings_Mapshare_SuggestedAddressURL",
    "CorporateSettings_Mapshare_UserFldAddressURL",
    "CorporateSettings_Mapshare_WebmercatorAddressURL",
    "CorporateSettings_Mapshare_UseProxy",
    "CorporateSettings_Mapshare_ProxyHostAddress",
    "CorporateSettings_Mapshare_ProxyHostPort",
    "CorporateSettings_NSWPOINT_URL_Predictive1",
    "CorporateSettings_NSWPOINT_URL_Predictive2",
    "CorporateSettings_NSWPOINT_URL_Validate",
    "CorporateSettings_NSWPOINT_Access_Key_Predictive",
    "CorporateSettings_NSWPOINT_Access_Key_AllOther",
    "CorporateSettings_NSWPOINT_MaxNumResults",
    "CorporateSettings_NSWPOINT_UseProxy",
    "CorporateSettings_Mapshare_ZoneOverlayAddressURL",
  ]);

  const addressVerificationType = +valueGetter(
    ECorporateSettingsField.CorporateSettings_AddressValidation.toString()
  );
  const useMapshareProxy = +valueGetter(
    ECorporateSettingsField.CorporateSettings_Mapshare_UseProxy.toString()
  );

  const isShowVMAS2 = addressVerificationType === AddressValidationType.VMAS2;
  const isShowDataTools =
    addressVerificationType === AddressValidationType.DataTools;
  const isShowMapshare =
    addressVerificationType === AddressValidationType.DELWPMapShare;
  const isShowNSWPOINT =
    addressVerificationType === AddressValidationType.NSWPOINT;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <CCSearchComboBoxGlobalSettings
          data={data.CorporateSettings_AddressValidation}
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={data.CorporateSettings_PropertyCustomSelectQuery_PropertyIDList}
          isEditing={isEditing}
        />
      </div>
      {isShowVMAS2 && (
        <div className="cc-form-cols-3">
          <InputGlobalSettings
            data={data.CorporateSettings_VMAS2_Feature}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_VMAS2_Username}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_VMAS2_Password}
            isEditing={isEditing}
          />
          <SwitchGlobalSettings
            data={data.CorporateSettings_VMAS2_UseProxy}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
      )}
      {isShowDataTools && (
        <div className="cc-form-cols-3">
          <InputGlobalSettings
            data={data.CorporateSettings_DataTools_Api_Key}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_DataTools_MaxNumResults}
            isEditing={isEditing}
          />
          <SwitchGlobalSettings
            data={data.CorporateSettings_DataTools_UseProxy}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
      )}

      {isShowMapshare && (
        <div className="cc-form-cols-3">
          <InputGlobalSettings
            data={data.CorporateSettings_Mapshare_SuggestedAddressURL}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_Mapshare_UserFldAddressURL}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_Mapshare_WebmercatorAddressURL}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_Mapshare_ZoneOverlayAddressURL}
            isEditing={isEditing}
          />
          <SwitchGlobalSettings
            data={data.CorporateSettings_Mapshare_UseProxy}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          {!!useMapshareProxy && (
            <>
              <InputGlobalSettings
                data={data.CorporateSettings_Mapshare_ProxyHostAddress}
                isEditing={isEditing}
              />
              <InputGlobalSettings
                data={data.CorporateSettings_Mapshare_ProxyHostPort}
                isEditing={isEditing}
              />
            </>
          )}
        </div>
      )}

      {isShowNSWPOINT && (
        <div className="cc-form-cols-3">
          <InputGlobalSettings
            data={data.CorporateSettings_NSWPOINT_URL_Predictive1}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_NSWPOINT_URL_Predictive2}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_NSWPOINT_URL_Validate}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_NSWPOINT_Access_Key_Predictive}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_NSWPOINT_Access_Key_AllOther}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_NSWPOINT_MaxNumResults}
            isEditing={isEditing}
          />
          <SwitchGlobalSettings
            data={data.CorporateSettings_NSWPOINT_UseProxy}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
      )}
    </section>
  );
});
