import { getCurrentAccount } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/accounts/api";
import { colAccountsTransaction } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/accounts/config";
import { IAccountsTransaction } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/accounts/model";
import { validatorAccount } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/accounts/util";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { FieldArray } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ACCOUNTS_FORM_STEP = "Accounts";

const nameOfAccounts = nameOfFactory<IAccountsTransaction>();

export const AccountsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={validatorAccount}
    />
  );
};

export const FormStepElement = ({
  formRenderProps,
  nameOf,
}: IFormStepElement) => {
  const { onChange } = formRenderProps;
  const [data, setData] = useState<IAccountsTransaction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const params: { id: string } = useParams();
  const accountId = params.id;
  useEffectOnce(() => {
    setIsLoading(true);
    getCurrentAccount(parseInt(accountId)).then((data) => {
      onChange(nameOf("Accounts"), { value: data ? [data] : [] });
      if (data) {
        setData([data]);
      }
      setIsLoading(false);
    });
  });
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            Accounts to receive new transaction
          </label>
          <CCGrid
            isLoading={isLoading}
            data={data}
            primaryField={nameOfAccounts("AccountId")}
            columnFields={colAccountsTransaction}
          />
        </div>
      </div>
    </section>
  );
};
