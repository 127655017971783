import {
  categoryData,
  registerData,
} from "@app/products/property/registers/list/components/action-bar/form-steps/create-register/components/account/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";

export const CREATE_REGISTER_ACCOUNT_FORM_STEP = "Account";
export const AccountFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Register</label>
            <Field
              name={nameOf("Register")}
              data={registerData}
              textField="Value"
              dataItemKey="Key"
              component={CCSearchComboBox}
              value={getDropdownValue(
                valueGetter(nameOf("Register")),
                registerData,
                "Key"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Register"), {
                  value: event.target.value?.Key ?? null,
                });
              }}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Account reference</label>
            <Field name={nameOf("AccountReference")} component={CCInput} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Category</label>
            <Field
              name={nameOf("Category")}
              data={categoryData}
              textField="Value"
              dataItemKey="Key"
              component={CCSearchComboBox}
              value={getDropdownValue(
                valueGetter(nameOf("Category")),
                categoryData,
                "Key"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Category"), {
                  value: event.target.value?.Key ?? null,
                });
              }}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Date</label>
            <Field
              name={nameOf("Date")}
              component={CCDatePicker}
              defaultValue={new Date()}
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              name={nameOf("Description")}
              component={CCTextArea}
              rows={3}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Automatically update name and address from linked properties
            </label>
            <Field
              name={nameOf("UpdateNameAndAddress")}
              component={CCSwitch}
              checked={getFieldValue("UpdateNameAndAddress")}
            />
          </div>
        </div>
      </section>
    </>
  );
};
