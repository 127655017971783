import { IAppNo } from "@app/products/town-planning/ppr/enquiries/_id/components/input-picker/app-no-picker/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IAppNo>();

export const colAppNoPicker: IColumnFields[] = [
  { title: "Number", field: nameOf("ApplicationNumber") },
  { title: "Applicant", field: nameOf("Applicant") },
  { title: "Category", field: nameOf("Category") },
  { title: "Type", field: nameOf("Type") },
  { title: "Address validation", field: nameOf("SiteAddress") },
  { title: "Locality", field: nameOf("Locality") },
  { title: "Owner", field: nameOf("Owner") },
  { title: "Legal Description", field: nameOf("LegalDescription") },
  { title: "Days", field: nameOf("StatDays"), format: NUMBER_FORMAT.NUMBER2 },
  { title: "Status", field: nameOf("Status") },
  { title: "File Number", field: nameOf("FileNo") },
  { title: "Reference Number", field: nameOf("RefNo") },
  { title: "Valuation Number", field: nameOf("ValuationNo") },
  { title: "Assess Number", field: nameOf("AssessmentNo") },
];
