import { useIsNew } from "@common/hooks/useIsNew";
import { produceSiteUserSaveTrigger } from "@common/pages/settings/security/site-users/util";
import SiteUserFormElement from "@common/pages/settings/security/site-users/_id/components/child-screens/general/components/_index";
import {
  NewSiteUser,
  SiteUserSubmitActions,
} from "@common/pages/settings/security/site-users/_id/model";
import { useSiteUserStore } from "@common/pages/settings/security/site-users/_id/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const SiteUserForm = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const { siteUser, setOnSubmit, saveSiteUser } = useSiteUserStore();
  const isNew = useIsNew();

  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = async (event: FormSubmitClickEvent) => {
    const action = event.event?.currentTarget.name as SiteUserSubmitActions;
    const { values, isModified, isValid } = event;
    if (
      siteUser?.Contact_SiteUser?.MemberUserName !==
        values?.Contact_SiteUser?.MemberUserName &&
      !isNew
    ) {
      pushNotification({
        autoClose: false,
        type: "error",
        title:
          "Change username button is required to be pressed before saving the record",
      });
      return;
    }
    if (!(action in SiteUserSubmitActions)) return;
    if (!isValid) return;
    if (
      !isModified &&
      [SiteUserSubmitActions.Save, SiteUserSubmitActions.New].includes(action)
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    const pprInfo = produceSiteUserSaveTrigger(values as NewSiteUser, siteUser);
    saveSiteUser(pprInfo, action, isNew);
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, siteUser]);

  // Check if data is existed in Update Mode
  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={siteUser}
        key={JSON.stringify(siteUser)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <SiteUserFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
