export enum PanelID {
  pnlActivities = "pnlActivities",
  pnlContacts = "pnlContacts",
  pnlJournal = "pnlJournal",
  pnlDocuments = "pnlDocuments",
  pnlInspections = "pnlInspections",
  pnlRecords = "pnlRecords",
  pnlRelatedEvents = "pnlRelatedEvents",
  pnlWorkflow = "pnlWorkflow",
  pnlCaseAnimalsWandering = "pnlCaseAnimalsWandering",
}
