import { DTO_DeferredDuty_TransactionTabDetails } from "@app/products/property/journals/not-yet-in-journal/deferred-duty-transactions/components/reference-sidebar/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const loadDeferredDutyTransactionDetailTab = async (
  transactionId: number
): Promise<APIResponse<DTO_DeferredDuty_TransactionTabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<
      DTO_DeferredDuty_TransactionTabDetails | undefined
    >(
      `/api/property/internal/deferredduty/account/transaction/${transactionId}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
