import { getAsseticVersion } from "@app/products/crms/[id]/api";
import { AssetPickerSearchInput } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/asset-picker-search/components/asset-picker-search-input/_index";
import { Asset, Event } from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import logo from "@assets/img/logo.png";
import { isSuccessResponse } from "@common/apis/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { AssetsSystem } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/assets/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import {
	CCLocalNotification,
	ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import MapView from "@components/map/MapView";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
	Field,
	Form,
	FormElement,
	FormRenderProps,
} from "@progress/kendo-react-form";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";

export interface IAssetDetailsDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  primaryImportFieldName?: string;
  isInitiallyLoading?: boolean;
  description?: React.ReactNode;
  initialData: any;
  initialDataSubmit?: any;
  formRenderProps: FormRenderProps;
}

const nameOfEvent = nameOfFactory<Event>();
const nameOfAsset = nameOfFactory<Asset>();

export const AssetDetailsDialog = observer(
  (props: IAssetDetailsDialogProps) => {
    const {
      initialDataSubmit,
      isInitiallyLoading,
      onClose,
      onSubmit,
      initialData,
      formRenderProps: formRenderPropsEvent,
    } = props;
    const { settings } = useCommonCoreStore();
    const { valueGetter: valueGetterEvent } = formRenderPropsEvent;
    const [isLoading, setIsLoading] = useState(false);
    const event = valueGetterEvent("Event");
    const assetsSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_AssetsSystem]
    );

    const { crmsEventLovs } = useCRMSEventStore();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const [dataSubmit, setDataSubmit] = useState<any>(initialDataSubmit);

    const addError = (text?: any) => {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: text,
        type: "error",
      });
    };

    const initialValue = useMemo(() => {
      let data = {};
      const locationAddress = event?.IssueLocation?.LocationAddress;
      const locationDescription = locationAddress?.Location_Description;
      if (initialData?.SiteCode && locationAddress) {
        data = initialData;
      }
      if (locationDescription) {
        data = { ...data, LocationDescription: locationDescription };
        setDataSubmit((prev: any) => {
          return { ...prev, LocationDescription: locationDescription };
        });
      }
      return {
        Asset: data,
      };
    }, [event, initialData]);

    const category = useMemo(
      () =>
        assetsSystem === AssetsSystem.AssetMaster
          ? nameOfAsset("AssetCategoryCode1")
          : nameOfAsset("FeatureType"),
      [assetsSystem]
    );
    const assetID = useMemo(
      () =>
        assetsSystem === AssetsSystem.AssetMaster
          ? nameOfAsset("AssetId")
          : nameOfAsset("FeatureAssetID"),
      [assetsSystem]
    );

    return (
      <Form
        initialValues={initialValue}
        key={JSON.stringify(initialValue)}
        onSubmitClick={() => onSubmit(dataSubmit)}
        render={(formRenderProps: FormRenderProps) => {
          const { valid, valueGetter, onChange } = formRenderProps;

          const siteName = valueGetter(
            `${nameOfEvent("Asset")}.${nameOfAsset("SiteName")}`
          );
          const sideCode = valueGetter(
            `${nameOfEvent("Asset")}.${nameOfAsset("SiteCode")}`
          );

          let isButtonDisabled = !valid || isInitiallyLoading || isLoading;
          if (
            assetsSystem !== AssetsSystem.AssetMaster &&
            (!siteName || !sideCode)
          ) {
            isButtonDisabled = true;
          }
          const isButtonLoading = isInitiallyLoading || isLoading;

          const handlePickSearchChange = async (values: any) => {
            if (values === null) return;
            if (
              values?.SiteCode &&
              (assetsSystem === AssetsSystem.AssetMaster ||
                assetsSystem === AssetsSystem.Custom)
            ) {
              const newData = cloneDeep(values);
              setIsLoading(true);
              const response = await getAsseticVersion(values.SiteCode);
              setIsLoading(false);
              if (isSuccessResponse(response)) {
                Object.assign(newData, response.data);
              }
              setDataSubmit(newData);
              onChange("Asset", { value: newData });
            }
          };

          return (
            <CCDialog
              titleHeader={"Asset Details"}
              height={"auto"}
              maxWidth={"50%"}
              onClose={onClose}
              bodyElement={
                <FormElement className="cc-form relative">
                  <CCLocalNotification ref={notificationRef} />
                  {isInitiallyLoading ? (
                    <LoadingIndicator isLoading={isInitiallyLoading} />
                  ) : (
                    <section>
                      <div className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <CCLabel
                              title="Search"
                              isMandatory={
                                assetsSystem !== AssetsSystem.AssetMaster
                              }
                            />
                            <Field
                              name="Search"
                              component={AssetPickerSearchInput}
                              placeholder="Search"
                              onError={addError}
                              formRenderProps={formRenderProps}
                              onChange={handlePickSearchChange}
                              isLoading={isLoading}
                              setIsLoading={setIsLoading}
                            />
                          </div>
                        </div>
                      </div>

                      <hr className="cc-divider" />
                      <div className="cc-field-group">
                        <div className="cc-form-cols-3">
                          <div className="cc-field">
                            <CCValueField
                              label="Asset name"
                              value={valueGetter(
                                `${nameOfEvent("Asset")}.${nameOfAsset(
                                  "SiteName"
                                )}`
                              )}
                            />
                          </div>

                          <div className="cc-field">
                            <CCValueField
                              label="Asset number"
                              value={valueGetter(
                                `${nameOfEvent("Asset")}.${nameOfAsset(
                                  "SiteCode"
                                )}`
                              )}
                            />
                          </div>

                          <div className="cc-field">
                            <CCLabel title="Location description" />
                            <Field
                              name={`${nameOfEvent("Asset")}.${nameOfAsset(
                                "LocationDescription"
                              )}`}
                              textField="Value"
                              dataItemKey="Key"
                              data={
                                crmsEventLovs?.AssetLocationDescriptions ?? []
                              }
                              component={CCSearchComboBox}
                              placeholder="Select location description"
                              value={getDropdownValue(
                                valueGetter(
                                  `${nameOfEvent("Asset")}.${nameOfAsset(
                                    "LocationDescription"
                                  )}`
                                ),
                                crmsEventLovs?.AssetLocationDescriptions ?? [],
                                "Value"
                              )}
                              onChange={(event: ComboBoxChangeEvent) => {
                                onChange(
                                  `${nameOfEvent("Asset")}.${nameOfAsset(
                                    "LocationDescription"
                                  )}`,
                                  {
                                    value: event.value?.Value,
                                  }
                                );
                                setDataSubmit((prev: any) => {
                                  return {
                                    ...prev,
                                    LocationDescription: event.value?.Value,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="cc-form-cols-3">
                          <div className="cc-field">
                            <CCValueField
                              label="Category"
                              value={sanitizeHtml(
                                `${
                                  valueGetter(
                                    `${nameOfEvent("Asset")}.${category}`
                                  ) ?? "&nbsp;"
                                } `
                              )}
                            />
                          </div>
                          <div className="cc-field">
                            <CCValueField
                              label="Asset ID"
                              value={sanitizeHtml(
                                `${
                                  valueGetter(
                                    `${nameOfEvent("Asset")}.${assetID}`
                                  ) ?? "&nbsp;"
                                } `
                              )}
                            />
                          </div>
                          <div className="cc-field">
                            <CCValueField
                              label="Asset hierarchy"
                              value={sanitizeHtml(
                                `${
                                  valueGetter(
                                    `${nameOfEvent("Asset")}.${nameOfAsset(
                                      "AssetHierarchy"
                                    )}`
                                  ) ?? "&nbsp;"
                                } `
                              )}
                            />
                          </div>
                        </div>

                        <hr className="cc-divider" />

                        <div className="cc-form-cols-3">
                          {/*<div className="cc-field"> // CCS-30 JIRA change Street name to Location name (Check logic later)
                        <CCValueField
                          label="Street name"
                          value={sanitizeHtml(
                            `${
                              valueGetter(
                                `${nameOfEvent("Asset")}.${nameOfAsset(
                                  "StreetName"
                                )}`
                              ) ?? "&nbsp;"
                            } `
                          )}
                        />
                      </div>*/}

                          <div className="cc-field">
                            <CCValueField
                              label="Location name"
                              value={sanitizeHtml(
                                `${
                                  valueGetter(
                                    `${nameOfEvent("Asset")}.${nameOfAsset(
                                      "LocationName"
                                    )}`
                                  ) ?? "&nbsp;"
                                } `
                              )}
                            />
                          </div>
                          <div className="cc-field">
                            <CCValueField
                              label="Suburb"
                              value={sanitizeHtml(
                                `${
                                  valueGetter(
                                    `${nameOfEvent("Asset")}.${nameOfAsset(
                                      "SuburbArea"
                                    )}`
                                  ) ?? "&nbsp;"
                                } `
                              )}
                            />
                          </div>
                          <div className="cc-field">
                            <CCValueField
                              label="Postcode"
                              value={sanitizeHtml(
                                `${
                                  valueGetter(
                                    `${nameOfEvent("Asset")}.${nameOfAsset(
                                      "Postcode"
                                    )}`
                                  ) ?? "&nbsp;"
                                } `
                              )}
                            />
                          </div>
                        </div>

                        {assetsSystem === AssetsSystem.AssetMaster && (
                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <label className="label" onClick={() => {}}>
                                <span className="btn-link">Asset</span>
                              </label>
                            </div>

                            <div className="cc-field"></div>

                            <div className="cc-field">
                              <img className="fit-image" src={logo} alt="" />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="cc-site-address-map">
                        <MapView
                          width="100%"
                          height="150px"
                          address={siteName}
                          zoom={18}
                          onError={(errorMessage: string) =>
                            notificationRef.current?.pushNotification({
                              autoClose: false,
                              title: errorMessage,
                              type: "warning",
                            })
                          }
                        />
                      </div>
                    </section>
                  )}
                </FormElement>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    themeColor="primary"
                    disabled={isButtonDisabled}
                    className={"cc-dialog-button"}
                    type={"submit"}
                    onClick={formRenderProps.onSubmit}
                    iconClass={isButtonLoading ? "fas fa-spinner fa-spin" : ""}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);

const LoadingIndicator = (props: { isLoading?: boolean }) => {
  return props?.isLoading ? (
    <div className="k-loading-mask" style={{ minHeight: 120 }}>
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  ) : null;
};
