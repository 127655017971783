import { history } from "@/AppRoutes";
import { assetLookupsStore } from "@app/products/ams/api/data/lookups/store";
import {
  loadAssetManagerById,
  postAssetManagerById,
  putAssetManagerById,
} from "@app/products/ams/assets/[id]/api";
import { mockDefaultAssetRegister } from "@app/products/ams/assets/[id]/mock";
import {
  AmsAssetRegister,
  AmsAssetRegisterLookup,
  lookupList,
} from "@app/products/ams/assets/[id]/model";
import {
  getLookupItemDescription,
  getLOVFromLookups,
} from "@app/products/ams/assets/[id]/util";
import { AssetLOVs } from "@app/products/ams/constants/ams.lookups";
import { AmsRoute } from "@app/products/ams/constants/ams.route";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class AssetManagerStore {
  private _asset?: AmsAssetRegister = undefined;
  private _assetLOVs: AssetLOVs | null = null;
  private _isLoading = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get asset() {
    return this._asset;
  }
  setAsset = (asset?: AmsAssetRegister) => {
    runInAction(() => {
      this._asset = asset;
    });
  };

  get assetLOVs() {
    return this._assetLOVs;
  }
  setAssetLOVs = (assetLOVs: AssetLOVs) => {
    runInAction(() => {
      this._assetLOVs = assetLOVs;
    });
  };

  clearAsset = () => {
    this._asset = undefined;
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._asset = undefined;
      this._assetLOVs = null;
    });
  };
  loadLovs = async () => {
    this.setIsLoading(true);
    const response = await assetLookupsStore.loadLookups(lookupList);
    let errorResponse = undefined;
    if (response?.length > 0) {
      const lookupLOV = response ? getLOVFromLookups(response) : {};
      this.setAssetLOVs(lookupLOV as any);
    } else {
      errorResponse = true;
    }
    this.setIsLoading(false);
    return errorResponse;
  };
  loadAsset = async (id: number | string) => {
    this.setIsLoading(true);
    const response = await Promise.all([
      loadAssetManagerById(id),
      assetLookupsStore.loadLookups(lookupList),
    ]);
    let errorResponse = undefined;
    if (Array.isArray(response)) {
      if (isSuccessResponse(response[0])) {
        const [assetData, lookupsAsset] = response;
        this.setIsLoading(false);
        const lookupLOV = lookupsAsset ? getLOVFromLookups(lookupsAsset) : {};
        this.setAssetLOVs(lookupLOV as any);
        runInAction(() => {
          const item = assetData.data;
          const newAsset = {
            ...mockDefaultAssetRegister,
            Id: item?.Id,
            AssetNumber: item?.AssetNumber ?? "",
            AssetDescription: item?.AssetDescription,
            AssetStatusId: item?.AssetStatusId,
            AssetCategoryId: item?.AssetCategoryId ?? 0,
            DesignLife: item?.DesignLife,
            WarrantyEndDate: item?.WarrantyEndDate,
            SerialNumber: item?.SerialNumber,
            EquipmentNumber: item?.EquipmentNumber,
            EmployeeCustodian: item?.EmployeeCustodian,
            EmployeeNumber: item?.EmployeeNumber,
            DepartmentId: item?.DepartmentId,
            CostCentreId: item?.CostCentreId,
            LocationId: item?.LocationId,
            OsProductId: item?.OsProductId,
          };
          // old code, not sure if it is needed
          // switch (item.AssetCategory) {
          //   case "Roads":
          //     this.asset.assetCategoryId = 2;
          //     this.asset.divisionLupId = 1;
          //     break;
          //   case "Bridges":
          //     this.asset.assetCategoryId = 1;
          //     this.asset.divisionLupId = 1;
          //     break;
          //   case "Buildings":
          //     this.asset.assetCategoryId = 4;
          //     this.asset.divisionLupId = 4;
          //     break;
          // }
          switch (item?.Status) {
            case "Pending":
              newAsset.AssetStatusId = 1;
              break;
            case "Current":
              newAsset.AssetStatusId = 2;
              break;
            case "Historic":
              newAsset.AssetStatusId = 3;
              break;
          }
          this.setAsset(newAsset);
        });
      }
    } else {
      errorResponse = response as APIResponse;
      errorResponse = {
        status: errorResponse?.status,
        error: errorResponse?.error ?? "Load failed",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };
  editAsset = async (asset: AmsAssetRegister) => {
    this.setIsLoading(true);
    const response = await putAssetManagerById(asset);
    if (isSuccessResponse(response)) {
      this.setIsLoading(false);
      appNotificationStore.pushNotification({
        autoClose: true,
        type: "success",
        title: `Modify asset successfully`,
      });
    } else {
      this.setIsLoading(false);
      appNotificationStore.pushNotification({
        autoClose: false,
        type: "error",
        title: `Modify asset failed`,
        description: response.data?.Errors ?? response.statusText,
      });
    }
  };
  createAsset = async (asset: AmsAssetRegister, isNew = false) => {
    this.setIsLoading(true);
    const response = await postAssetManagerById(asset, isNew);
    if (isSuccessResponse(response)) {
      this.setIsLoading(false);
      history.push(`${AmsRoute.ASSET_MANAGE_PATH}${response.data.Id}`);
      appNotificationStore.pushNotification({
        type: "success",
        title: `Create asset successfully`,
        description: response.data?.Errors ?? response.statusText,
      });
    } else {
      this.setIsLoading(false);
      appNotificationStore.pushNotification({
        autoClose: false,
        type: "error",
        title: `Create asset with id: ${asset.Id} failed`,
        description: response.data?.Errors ?? response.statusText,
      });
    }
  };
  getAssetInfo = (asset?: AmsAssetRegister, lov?: AssetLOVs | null) => {
    if (!asset) return null;
    let dataItemLookup: AmsAssetRegisterLookup = getLookupItemDescription(
      asset,
      lov
    );
    return {
      ...asset,
      AssetCategory: dataItemLookup?.AssetCategoryId,
      AssetStatus: dataItemLookup?.AssetStatusId,
      Location: dataItemLookup?.LocationId,
      DepartmentName: dataItemLookup?.DepartmentId,
      CostCentre: dataItemLookup?.CostCentreId,
      OsProduct: dataItemLookup?.OsProductId,
    };
  };
}
export const assetManagerStore = new AssetManagerStore();
const assetManagerStoreContext = createContext(new AssetManagerStore());
export const useAssetManagerStore = () => {
  return useContext(assetManagerStoreContext);
};
