export const mockType = [
  {
    Key: 0,
    Value: "Deceased Estate",
  },
  {
    Key: 1,
    Value: "Organisation",
  },
  {
    Key: 2,
    Value: "Person",
  },
  {
    Key: 3,
    Value: "Trust",
  },
];

export const mockTitle = [
  { Key: 0, Value: "Col" },
  { Key: 1, Value: "Dame" },
  { Key: 2, Value: "Dr" },
  { Key: 3, Value: "Est Late" },
  { Key: 4, Value: "Master" },
  { Key: 5, Value: "Miss" },
  { Key: 6, Value: "Mr" },
  { Key: 7, Value: "Mrs" },
  { Key: 8, Value: "Ms" },
  { Key: 9, Value: "Prof" },
  { Key: 10, Value: "Rev" },
  { Key: 11, Value: "Sir" },
];
export const mockSurName = [
  { Key: 0, Value: "SMITH" },
  { Key: 1, Value: "B A" },
  { Key: 2, Value: "N" },
  { Key: 3, Value: "J L & M A" },
  { Key: 4, Value: "E A & M A" },
  { Key: 5, Value: "A M & K L" },
  { Key: 6, Value: "E A & M A" },
  { Key: 7, Value: "G L" },
  { Key: 8, Value: "A R & J A" },
  { Key: 9, Value: "P/L" },
  { Key: 10, Value: "P W" },
];
export const mockDistinction = [
  { Key: 0, Value: "" },
  { Key: 1, Value: "AAM" },
  { Key: 2, Value: "AC" },
  { Key: 3, Value: "AD" },
  { Key: 4, Value: "AFSM" },
  { Key: 5, Value: "AK" },
  { Key: 6, Value: "AM" },
  { Key: 7, Value: "ASM" },
  { Key: 8, Value: "BA" },
  { Key: 9, Value: "BBiosc" },
  { Key: 10, Value: "BCom" },
  { Key: 11, Value: "BE" },
  { Key: 12, Value: "BJ" },
  { Key: 13, Value: "BM" },
  { Key: 14, Value: "BSc" },
  { Key: 15, Value: "CMC" },
  { Key: 16, Value: "CSC" },
  { Key: 17, Value: "CSM" },
  { Key: 18, Value: "CV" },
  { Key: 19, Value: "DSC" },
  { Key: 20, Value: "DSM" },
  { Key: 21, Value: "JP" },
  { Key: 22, Value: "LLB" },
  { Key: 23, Value: "m" },
  { Key: 24, Value: "MBBS" },
  { Key: 25, Value: "MG" },
  { Key: 26, Value: "MHA" },
  { Key: 27, Value: "MIEAust" },
  { Key: 28, Value: "MLA" },
  { Key: 29, Value: "MLC" },
  { Key: 30, Value: "MP" },
  { Key: 31, Value: "MPharm" },
  { Key: 32, Value: "Mr" },
  { Key: 33, Value: "NSC" },
  { Key: 34, Value: "OA" },
  { Key: 35, Value: "OAM" },
  { Key: 36, Value: "PhD" },
  { Key: 37, Value: "PSM" },
  { Key: 38, Value: "QC" },
  { Key: 39, Value: "RFD" },
  { Key: 40, Value: "RVM" },
  { Key: 41, Value: "SC" },
  { Key: 42, Value: "SG" },
  { Key: 43, Value: "SJ" },
  { Key: 44, Value: "VC" },
];
export const mockCategory = [
  {
    Key: 0,
    Value: "Church",
  },
  {
    Key: 1,
    Value: "Council",
  },
  {
    Key: 2,
    Value: "Defense Service Homes",
  },
  {
    Key: 3,
    Value: "Embassy",
  },
  {
    Key: 4,
    Value: "Federal Government",
  },
  {
    Key: 5,
    Value: "Non-Profit Organisation",
  },
  {
    Key: 6,
    Value: "Standard",
  },
  {
    Key: 7,
    Value: "State Government",
  },
  {
    Key: 8,
    Value: "University",
  },
];
export const mockDelivery = [
  {
    label: "Standard",
    value: "Standard",
  },
  {
    label: "Special",
    value: "Special",
  },
  {
    label: "International",
    value: "International",
  },
];
export const mockJobDescription = [
  {
    Key: 0,
    Value: "teacher",
  },
];
export const mockPropertyName = [
  {
    Key: 0,
    Value: "Abercrombie ",
  },
  {
    Key: 1,
    Value: "Applewood ",
  },
  {
    Key: 2,
    Value: "Baringa",
  },
  {
    Key: 3,
    Value: "Blackwood",
  },
  {
    Key: 4,
    Value: "Blue Gum Park",
  },
  {
    Key: 5,
    Value: "Bridgewater Park",
  },
  {
    Key: 6,
    Value: "Brookleigh",
  },
  {
    Key: 7,
    Value: "Bunarong",
  },
  {
    Key: 8,
    Value: "Burnbank",
  },
  {
    Key: 9,
    Value: "Carawah",
  },
  {
    Key: 10,
    Value: "Casa Nostra",
  },
  {
    Key: 11,
    Value: "Charick",
  },
  {
    Key: 12,
    Value: "Chestnut Glade",
  },
  {
    Key: 13,
    Value: "Cheverells",
  },
  {
    Key: 14,
    Value: "Chippin Estate",
  },
  {
    Key: 15,
    Value: "Cloverdale",
  },
  {
    Key: 16,
    Value: "Coraness",
  },
  {
    Key: 17,
    Value: "Corrison",
  },
];
export const mockUnitType = [
  {
    Key: 0,
    Value: "Antenna",
  },
  {
    Key: 1,
    Value: "Apartment",
  },
  {
    Key: 2,
    Value: "Auto Teller Machine",
  },
  {
    Key: 3,
    Value: "Barbeque",
  },
  {
    Key: 4,
    Value: "Bathing Box",
  },
  {
    Key: 5,
    Value: "Berth",
  },
  {
    Key: 6,
    Value: "Boat Shed",
  },
  {
    Key: 7,
    Value: "Building",
  },
  {
    Key: 8,
    Value: "Bungalow",
  },
  {
    Key: 9,
    Value: "Cage",
  },
  {
    Key: 10,
    Value: "Carpark",
  },
  {
    Key: 11,
    Value: "Carspace",
  },
  {
    Key: 12,
    Value: "Carwash",
  },
  {
    Key: 13,
    Value: "Club",
  },
  {
    Key: 14,
    Value: "Coolroom ",
  },
  {
    Key: 15,
    Value: "Cottage",
  },
  {
    Key: 16,
    Value: "Courtyard",
  },
  {
    Key: 17,
    Value: "Duplex",
  },
  {
    Key: 18,
    Value: "Factory",
  },
];
export const mockFloorLevelType = [
  {
    Key: 0,
    Value: "Basement",
  },
  {
    Key: 1,
    Value: "Floor",
  },
  {
    Key: 2,
    Value: "Ground Floor",
  },
  {
    Key: 3,
    Value: "Level",
  },
  {
    Key: 4,
    Value: "Lobby",
  },
  {
    Key: 5,
    Value: "Lower Ground Floor ",
  },
  {
    Key: 6,
    Value: "Mezzanine",
  },
  {
    Key: 7,
    Value: "Pavilion ",
  },
  {
    Key: 8,
    Value: "Platform ",
  },
  {
    Key: 9,
    Value: "Podium ",
  },
  {
    Key: 10,
    Value: "Rooftop ",
  },
  {
    Key: 11,
    Value: "Upper Ground Floor",
  },
];
export const mockStreetName = [{ Key: 0, Value: "Aberdeen" }];
export const mockStreetType = [
  {
    Key: 0,
    Value: "Bch",
  },
  {
    Key: 1,
    Value: "Bdge",
  },
  {
    Key: 2,
    Value: "Bdwy",
  },
  {
    Key: 3,
    Value: "Bend",
  },
  {
    Key: 4,
    Value: "Blk",
  },
  {
    Key: 5,
    Value: "Brae",
  },
  {
    Key: 6,
    Value: "Brce",
  },
  {
    Key: 7,
    Value: "Brk",
  },
  {
    Key: 8,
    Value: "Brow",
  },
  {
    Key: 9,
    Value: "Bvd",
  },
  {
    Key: 10,
    Value: "Bypa",
  },
  {
    Key: 11,
    Value: "Caus",
  },
  {
    Key: 12,
    Value: "Cct",
  },
  {
    Key: 13,
    Value: "CDS",
  },
  {
    Key: 14,
    Value: "Ch",
  },
  {
    Key: 15,
    Value: "Cir",
  },
  {
    Key: 16,
    Value: "Cl",
  },
  {
    Key: 17,
    Value: "Clde",
  },
  {
    Key: 18,
    Value: "Cmmn",
  },
  {
    Key: 19,
    Value: "Cnr",
  },
];

export const mockLocality = [
  {
    Key: 0,
    Value: "Aarons Pass, NSW, 2850 ",
  },
  {
    Key: 1,
    Value: "Abba River, WA. 6280 ",
  },
  {
    Key: 2,
    Value: "Abbey, WA, 6280",
  },
  {
    Key: 3,
    Value: "Abbeyard, Vic, 3737 ",
  },
  {
    Key: 4,
    Value: "Abbeywood, Old, 4613 ",
  },
  {
    Key: 5,
    Value: "Abbotsbuiy, NSW, 2176 ",
  },
  {
    Key: 6,
    Value: "Abbotsford, NSW, 2046 ",
  },
  {
    Key: 7,
    Value: "Abbotsford, Old, 4670 ",
  },
  {
    Key: 8,
    Value: "Abbotsford, Vic, 3067 ",
  },
  {
    Key: 9,
    Value: "Abbotshant Tee, 7315 ",
  },
  {
    Key: 10,
    Value: "Abeckett Street, Vic, 8006 ",
  },
  {
    Key: 11,
    Value: "Abets Bay, Tas, 7112 ",
  },
  {
    Key: 12,
    Value: "Abercom, Old, 4627 ",
  },
  {
    Key: 13,
    Value: "Abercrombie River, NSW",
  },
  {
    Key: 14,
    Value: "Abercrombie, NSW, 2795 ",
  },
  {
    Key: 15,
    Value: "Aberdare, NSW, 2325 ",
  },
  {
    Key: 16,
    Value: "Aberdeen, NSW, 2336 ",
  },
  {
    Key: 17,
    Value: "Aberdeen, NSW, 2359 ",
  },
  {
    Key: 18,
    Value: "Aberdeen, Tas, 7310 ",
  },
  {
    Key: 19,
    Value: "Aberfeldie, Vic, 3040",
  },
];
export const mockState = [
  {
    Key: 0,
    Value: "AAT",
  },
  {
    Key: 1,
    Value: "ACT",
  },
  {
    Key: 2,
    Value: "NSW",
  },
  {
    Key: 3,
    Value: "NT",
  },
  {
    Key: 4,
    Value: "Old",
  },
  {
    Key: 5,
    Value: "SA",
  },
  {
    Key: 6,
    Value: "Tas",
  },
  {
    Key: 7,
    Value: "Vic",
  },
  {
    Key: 8,
    Value: "WA",
  },
];
export const mockPostalDeliveryType = [
  {
    Key: 0,
    Value: "c/- GPO Box ",
  },
  {
    Key: 1,
    Value: "C/- PO Box ",
  },
  {
    Key: 2,
    Value: "Care PO",
  },
  {
    Key: 3,
    Value: "Care PO Box ",
  },
  {
    Key: 4,
    Value: "CMA",
  },
  {
    Key: 5,
    Value: "CMB ",
  },
  {
    Key: 6,
    Value: "CPA",
  },
  {
    Key: 7,
    Value: "GPO Box",
  },
  {
    Key: 8,
    Value: "Loc",
  },
  {
    Key: 9,
    Value: "Locked Bag ",
  },
  {
    Key: 10,
    Value: "MS",
  },
  {
    Key: 11,
    Value: "PO Box",
  },
  {
    Key: 12,
    Value: "PO Box ",
  },
  {
    Key: 13,
    Value: "Private Bag ",
  },
  {
    Key: 14,
    Value: "RMB",
  },
  {
    Key: 15,
    Value: "RMS ",
  },
  {
    Key: 16,
    Value: "RSD",
  },
];
export const mockCountry = [
  {
    Key: 0,
    Value: "Afghanistan",
  },
  {
    Key: 1,
    Value: "Albania ",
  },
  {
    Key: 2,
    Value: "Algeria ",
  },
  {
    Key: 3,
    Value: "Andorra ",
  },
  {
    Key: 4,
    Value: "Angola ",
  },
  {
    Key: 5,
    Value: "Antarctica",
  },
  {
    Key: 6,
    Value: "Antigua and Barbuda ",
  },
  {
    Key: 7,
    Value: "Argentina",
  },
  {
    Key: 8,
    Value: "Armenia ",
  },
  {
    Key: 9,
    Value: "Austria ",
  },
  {
    Key: 10,
    Value: "Azerbaijan",
  },
  {
    Key: 11,
    Value: "Bahrain ",
  },
  {
    Key: 12,
    Value: "Bangladesh ",
  },
  {
    Key: 13,
    Value: "Barbados",
  },
];
