import { ENoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/model";

export const listTypeHideTransaction = [
  ENoticeType.AMOUNTS_DUE,
  ENoticeType.FINAL_NOTICES,
  ENoticeType.INSTALMENT_NOTICES,
  ENoticeType.LAND_RENT_ANNUAL_ASSESSMENT_NOTICES,
  ENoticeType.LAND_RENT_ANNUAL_INSTALMENT_NOTICES,
  ENoticeType.LAND_RENT_ARREARS_NOTICES,
  ENoticeType.REMINDER_NOTICES,
  ENoticeType.VALUATION_NOTICES,
];
export const listTypeHideFinancialGr = [ENoticeType.VALUATION_NOTICES];
export const listTypeHideMandatory = [
  ENoticeType.NOTICES_FOR_JOURNALS_OF_ACCOUNT_TRANSACTIONS,
  ENoticeType.REGISTER_ACCOUNT_BALANCES,
];
