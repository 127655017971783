import {
  apiCoreGetFeeTypesById,
  apiCoreGetFeeTypesByProductTypes,
} from "@app/core/components/common/utils";
import {
  ICalculatedFee,
  IFeeTypes as LightWeightFeeTypes,
} from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/add-fee-types/model";
import { FeeType } from "@app/products/town-planning/ppr/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getFeeTypes = async (): Promise<
  APIResponse<LightWeightFeeTypes[] | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      apiCoreGetFeeTypesByProductTypes()
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const getFeeTypesById = async (
  id: number
): Promise<APIResponse<FeeType | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      apiCoreGetFeeTypesById(id)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCalculatedFeeType = async (): Promise<
  APIResponse<ICalculatedFee[] | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      `/api/tp/internal/finance/calculatedfeetype`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
