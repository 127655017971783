import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacket } from "@common/models/identityPacket";
import {
  AppealHearing,
  AppealHearingLOVs,
} from "@common/pages/appeal/_id/appeal-hearing/_id/model";
import { Ancestor } from "@common/pages/appeal/_id/model";

export const getAppealHearing = async (
  id: number
): Promise<APIResponse<AppealHearing | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<AppealHearing>(
      `/api/core/internal/appeals/hearing/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const putAppealHearing = async (
  appealHearing: AppealHearing
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<IdentityPacket>(
      `/api/core/internal/appeals/hearing`,
      appealHearing
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getParentSummary = async (
  parentId: number,
  recordType: RECORDTYPE
): Promise<any> => {
  //TODO: Apply later type of response
  try {
    return await CoreAPIService.getClient().get<Ancestor>(
      `/api/core/internal/core/${parentId}/parentsummary?recordType=${recordType}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAppealHearingLOVS = async (
  productType: PRODUCT_TYPE_NUMBER
): Promise<APIResponse<AppealHearingLOVs | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<AppealHearingLOVs>(
      `/api/core/internal/appeals/hearing/lovs`,
      {
        params: {
          productType,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
