import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ReportsActionBarNavDropdown } from "@common/pages/report/components/action-bar/nav-dropdown/_index";
import { ExportReportButton } from "@common/pages/report/powerbi/components/button/export-report/_index";
import { colTestPowerBICustomReports } from "@common/pages/report/powerbi/list/config";
import { POWER_BI_REPORT_ROUTE } from "@common/pages/report/powerbi/list/constant";
import { IReportPowerBI } from "@common/pages/report/powerbi/list/model";
import { usePowerBIReportsListStore } from "@common/pages/report/powerbi/list/store";
import { ReportBookmark } from "@common/pages/report/powerbi/list/util";
import { powerBIRoute } from "@common/pages/report/powerbi/route";
import { reportsRoute } from "@common/pages/report/route";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<IReportPowerBI>();
export default observer(() => {
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();
  const {
    isLoading,
    loadReports,
    reports,
    workspaceId,
    resetStorePowerBIConfigure,
    responseLoadError,
  } = usePowerBIReportsListStore();

  const handleCheckPermission = async () => {
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.CORE_DASHBOARD_REPORTS,
      1
    );
  };

  useEffectOnce(() => {
    handleCheckPermission();
    loadReports();

    return () => {
      resetStore();
      resetStorePowerBIConfigure();
    };
  });

  useCCListViewActionBar({
    title: reportsRoute.name,
    leftComponents: [
      <ReportsActionBarNavDropdown category={powerBIRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title="New Report"
        externalLinkTo={`https://app.powerbi.com/groups/${workspaceId}`}
        disabled={!hasPermission}
      />,
      // <ImportReportButton disabled={!hasPermission} />,
      <ExportReportButton disabled={!hasPermission} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ListViewBookmarkIcon
        linkUrl={POWER_BI_REPORT_ROUTE}
        productType={PRODUCT_TYPE.Reports}
        recordType={RECORDTYPE.CORE_Report}
        detail={ReportBookmark.getBookmarkDetailReport}
        displayName={ReportBookmark.getBookmarkDisplayNameReport}
        listViewDisplayName={
          ReportBookmark.getBookmarkListViewDisplayNameReport
        }
        listViewDetail={ReportBookmark.getBookmarkListViewDetailReport}
      />,
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadReports();
        }}
      />
    );

  return (
    <CCProductListView
      columnFields={colTestPowerBICustomReports}
      data={hasPermission ? reports : undefined}
      primaryField={nameOf("id")}
      disabled={!hasPermission}
    />
  );
});
