import { ActionsAccordion } from "@app/core/actions/action-accordion/_index";
import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Documents } from "@app/core/documents/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { FurtherInfoForm } from "@app/core/further-info/[id]/components/child-screens/general/_index";
import { FurtherInfoDetailTab } from "@app/core/further-info/[id]/components/reference-sidebar/detail/_index";
import { FurtherInfoHistoryTab } from "@app/core/further-info/[id]/components/reference-sidebar/history/_index";
import {
  FURTHERINFOSTATUS,
  FurtherInfoSubmitActions,
} from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { getFurtherStatusFriendlyName } from "@app/core/further-info/[id]/util";
import { ApproveLetterButton } from "@app/core/further-info/components/buttons/more/approve-letter/_index";
import { SendLetterToRAandApplicantButton } from "@app/core/further-info/components/buttons/more/send-letter-to-RA-Applicant/_index";
import { AdditionalInfoRequiredButton } from "@app/core/further-info/components/buttons/work-flow/additional-info-required/_index";
import { CancelButton } from "@app/core/further-info/components/buttons/work-flow/cancel/_index";
import { InfoAdequateButton } from "@app/core/further-info/components/buttons/work-flow/info-adequate/_index";
import { InfoInadequateButton } from "@app/core/further-info/components/buttons/work-flow/info-inadequate/_index";
import { InfoNotReceivedButton } from "@app/core/further-info/components/buttons/work-flow/info-not-received/_index";
import { InfoReceivedButton } from "@app/core/further-info/components/buttons/work-flow/info-received/_index";
import { ReinstateButton } from "@app/core/further-info/components/buttons/work-flow/reinstate/_index";
import { RejectLetterButton } from "@app/core/further-info/components/buttons/work-flow/reject-letter/_index";
import { SendForApprovalButton } from "@app/core/further-info/components/buttons/work-flow/send-for-approval/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { getFIRequestMailMergeDataSetEnum } from "@app/core/new-mail-merge/dialogs/util";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { ViewDocumentsButton } from "@app/core/view/components/buttons/view-documents/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import { BubbleUpType } from "@app/products/waste-water/[id]/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { mapRecordType, RECORDTYPE } from "@common/constants/recordtype";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import {
  CCNavButton,
  ICCNavButtonProps,
} from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { isEmpty, isNumber } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";

export const ExistedFurtherInfo = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const {
    isLoading,
    responseLoadError,
    onSubmit,
    furtherInfo,
    furtherInfoId,
    loadFurtherInfo,
    isInactive,
  } = useFurtherInfoStore();

  const listPanelBar: ITabProps[] = [
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <ActionsAccordion
          id={furtherInfoId}
          recordType={RECORDTYPE.CORE_FurtherInformationRequest}
        />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments
          id={furtherInfoId}
          recordType={RECORDTYPE.CORE_FurtherInformationRequest}
        />
      ),
    },

    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents
          id={furtherInfoId}
          recordType={RECORDTYPE.CORE_FurtherInformationRequest}
        />
      ),
    },
  ];

  const FINavButton = useCallback(
    (props: ICCNavButtonProps) => {
      const { disabled, ...others } = props;
      return (
        <CCNavButton
          {...others}
          disabled={isInactive ? isInactive : disabled}
        />
      );
    },
    [isInactive]
  );

  const ancestorTypeEnum = useMemo(() => {
    if (isEmpty(furtherInfo?._BubbleUps)) return;
    const ancestorType = furtherInfo?._BubbleUps.find(
      (bubbleUp) => bubbleUp.BubbleUpType_ENUM === BubbleUpType.Ancestor
    )?.SourceIdentifier?._RecordSourceType_ENUM;
    return mapRecordType[ancestorType ?? ""];
  }, [furtherInfo]);

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation
        title={`Further Information Request ${
          isNumber(furtherInfo?.OrignalFI_ID) ? " - Extension" : ""
        }`}
      />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadFurtherInfo(furtherInfoId);
          }}
        />
      ) : (
        <>
          <FormTitle
            title={furtherInfoId ? furtherInfoId.toString() : ""}
            badge={
              getFurtherStatusFriendlyName(
                furtherInfo?.Status_ENUM ?? FURTHERINFOSTATUS.InfoRequested
              ) + (furtherInfo?.DueStatus_Name === "Lapsed" ? " - Lapsed" : "")
            }
          />
          <CCManagePageActionBar
            leftActions={[
              <FINavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <InfoReceivedButton />
                <InfoAdequateButton />
                <InfoInadequateButton />
                <AdditionalInfoRequiredButton />
                <ReinstateButton />
                <InfoNotReceivedButton />
                <SendForApprovalButton />
                <CancelButton />
              </FINavButton>,
              <FINavButton
                title={"Save"}
                onClick={onSubmit}
                name={FurtherInfoSubmitActions.Save}
              />,
              <FINavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={furtherInfoId}
                  recordType={RECORDTYPE.CORE_FurtherInformationRequest}
                  bubbleUps={furtherInfo?._BubbleUps}
                />
                <AddAttachmentButton
                  id={furtherInfoId}
                  recordType={RECORDTYPE.CORE_FurtherInformationRequest}
                  bubbleUps={furtherInfo?._BubbleUps}
                />
                <AddCommentButton
                  id={furtherInfoId}
                  recordType={RECORDTYPE.CORE_FurtherInformationRequest}
                />
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  recordId={furtherInfoId}
                  recordType={RECORDTYPE.CORE_FurtherInformationRequest}
                />
                <AddDocumentButton
                  dataSetFilter={
                    getFIRequestMailMergeDataSetEnum(ancestorTypeEnum) ??
                    MAILMERGEDATASET?.TOWNPLANNING_PPR_FurtherInformationRequests
                  }
                  recordType={RECORDTYPE.CORE_FurtherInformationRequest}
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  id={furtherInfoId}
                  bubbleUps={furtherInfo?._BubbleUps}
                />
              </FINavButton>,
              <FINavButton title={"View"} type="sub">
                <ViewDocumentsButton
                  id={furtherInfoId}
                  recordType={RECORDTYPE.CORE_FurtherInformationRequest}
                />
              </FINavButton>,
              <FINavButton title={"More options"} type="more">
                <ApproveLetterButton />
                <RejectLetterButton />
                <SendLetterToRAandApplicantButton />
              </FINavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {furtherInfo && <FurtherInfoForm />}
                <TabTable
                  initialPanels={listPanelBar}
                  id={furtherInfoId}
                  recordType={RECORDTYPE.CORE_FurtherInformationRequest}
                  disabled={isInactive}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <FurtherInfoDetailTab /> },
                    {
                      title: "History",
                      component: <FurtherInfoHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
