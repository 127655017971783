import { SqlDbType } from "@app/products/property/model";

export interface DTO_Assessment_LevyBalance {
  AssessmentId: number;
  View: AssessmentLevyBalancesViews;
  ShowFullyPaidLevies: boolean | null;
  DynamicColumns: DTO_DynamicColumns[];
  LevyBalanceViews: DTO_Assessment_LevyBalanceViews;
  LevyBalance: DTO_LevyBalance[];
  Totals: DTO_LevyBalanceTotals;
}

export interface DTO_LevyBalance {
  LevyId: number | null;
  FinancialAreaName: string;
  LevyName: string;
  RatingPeriodName: string;
  Balance: number | null;
  Interest: number | null;
  TotalBalanceOwing: number | null;
  ChargeLevies: DTO_Levies;
}

export interface DTO_LevyBalanceTotals {
  Balance: number | null;
  Interest: number | null;
  TotalBalanceOwing: number | null;
}

export interface DTO_Levies {
  Levies: DTO_Charge_Levy[];
  Totals: DTO_Totals;
}

export interface DTO_Totals {
  GrossAmount: number | null;
  RebatesGiven: number | null;
  NettAmount: number | null;
  Balance: number | null;
}

export interface DTO_Charge_Levy {
  Id: number;
  ChargeName: string;
  LevyName: string;
  ChargeDate: string | null;
  RatingPeriodName: string;
  ChargeDescription: string;
  Balance: number;
  InterestBalance: number | null;
  RebatesGiven: number | null;
  Calculation: string;
  GrossAmount: number | null;
  NettAmount: number | null;
}

export enum AssessmentLevyBalancesViews {
  By_FinancialArea = 0,
  By_Levy = 1,
  By_Levy_RatingPeriod = 2,
  By_RatingPeriod_Levy = 3,
}

export interface DTO_DynamicColumns {
  FieldName: string;
  DisplayName: string;
  DataType_ENUM: SqlDbType;
  DataType: string;
  ColumnIndex: number;
}

export interface DTO_Assessment_LevyBalanceViews {
  Views: DTO_Assessment_LevyBalanceView[];
}

export interface DTO_Assessment_LevyBalanceView {
  View: AssessmentLevyBalancesViews;
  Id: number;
  Name: string;
}
