import {
  mockBuildingRegisterEnforcementRecords,
  mockSingleBuildingEnforcement,
} from "@app/products/building/registers/enforcements/[id]/mock";
import {
  IBuildingRecordsState,
  IBuildingRegisterEnforcement,
  IBuildingRegisterEnforcementRequest,
} from "@app/products/building/registers/enforcements/[id]/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";
import { cloneDeep } from "lodash";

export const postBuildingRegisterEnforcement = async (
  requestBody: IBuildingRegisterEnforcementRequest
) => {
  await sleep(2000);
};

export const putBuildingRegisterEnforcement = async (
  requestBody: IBuildingRegisterEnforcementRequest
) => {
  await sleep(2000);
};

export const getBuildingEnforcementRecords = async (
  id: number,
  recordType: number
): Promise<IBuildingRecordsState> => {
  await sleep(1000);
  const response = cloneDeep(mockBuildingRegisterEnforcementRecords);
  return response;
};

export const getBuildingRegisterEnforcementById = async (
  id: number
): Promise<APIResponse<IBuildingRegisterEnforcement | undefined>> => {
  await sleep(1000);
  return {
    data: {
      ...mockSingleBuildingEnforcement,
      ID: id,
    } as IBuildingRegisterEnforcement,
    status: APIResponseStatus.SUCCESS,
    statusText: "Ok",
    headers: {},
    config: {},
  };
};
