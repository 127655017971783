import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const listRoute: ICCRoute = {
  path: "list",
  name: "List",
  enum: eMenuAction.Schemes_List,
  children: [
    {
      path: "all",
      name: "All",
      enum: eMenuAction.Schemes_List_All,
      component: require("./all/_index").default,
    },
    {
      path: "include-inactive",
      name: "Include inactive",
      enum: eMenuAction.Schemes_List_IncludeInactive,
      component: require("./include-inactive/_index").default,
    },
    {
      path: "include-actual-cost-that-have-been-paid-out",
      name: "Include actual cost that have been paid out",
      enum: eMenuAction.Schemes_List_IncludeActualCost,
      component: require("./include-actual-cost-that-have-been-paid-out/_index")
        .default,
    },
    {
      path: "active-only",
      name: "Active only",
      enum: eMenuAction.Schemes_List_OnlyActive,
      component: require("./active-only/_index").default,
    },
  ],
};
