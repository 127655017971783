import {
  DynamicQuestionResponse,
  Svc_DialogDynamicQuestionGetRequest,
  Svc_DialogDynamicQuestionSaveRequest,
} from "@app/core/checklist/components/dialog/dynamic-question/model";
import {
  apiCoreGetDynamicQuestion,
  apiCoreSaveDynamicQuestion,
} from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const postGetDynamicQuestion = async (
  dynamicQuestionGetRequest: Svc_DialogDynamicQuestionGetRequest
): Promise<APIResponse<DynamicQuestionResponse[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<DynamicQuestionResponse[]>(
      apiCoreGetDynamicQuestion(),
      dynamicQuestionGetRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSaveDynamicQuestion = async (
  dynamicQuestionSaveRequest: Svc_DialogDynamicQuestionSaveRequest
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreSaveDynamicQuestion(),
      dynamicQuestionSaveRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
