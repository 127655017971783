import { WastewaterFieldMapping } from "@app/products/waste-water/model";
import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colWastewaterInspectionsByOfficer: IColumnFields[] = [
  {
    field: WastewaterFieldMapping.DateInspected,
    title: "Inspected",
    locked: true,
    linkTo: (dataItem) => {
      return `${WASTE_WATER_ROUTE}/` + dataItem.ID;
    },
    format: DATE_FORMAT.DATE,
  },
  {
    field: WastewaterFieldMapping.ReferenceNumber,
    title: "Number",
  },
  {
    field: WastewaterFieldMapping.ContactName,
    title: "Name",
  },
  {
    field: WastewaterFieldMapping.InspectionType,
    title: "Type",
  },
  {
    field: WastewaterFieldMapping.Officer,
    title: "Officer",
  },
  {
    field: WastewaterFieldMapping.Compliance,
    title: "Compliance",
  },
  {
    field: WastewaterFieldMapping.Reason,
    title: "Reason",
  },
];
