import React from "react";
import { useDrop } from "react-dnd";

interface IProps {
  accept: string;
  handleDrop: (item: any) => void;
}

export const BoxCanvas: React.FC<IProps> = ({ accept, handleDrop, children }) => {
  const [{ isOver }, drop] = useDrop({
    accept: accept,
    drop: (item: any, monitor) => handleDrop(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });
  return (
    <div
      className={`container-fluid vh-100 ${isOver ? "shadow" : ""} `}
      ref={drop}
    >
      {children}
    </div>
  );
};
