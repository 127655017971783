import {
  loadSearch,
  loadSearchResult,
} from "@app/products/property/fire-prevention/components/dialogs/select-property/api";
import {
  colProperty,
  comboboxSearchData,
} from "@app/products/property/fire-prevention/components/dialogs/select-property/config";
import {
  IPropertyData,
  IPropertyGridData,
  OPTION_SEARCH,
} from "@app/products/property/fire-prevention/components/dialogs/select-property/model";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { lowerFirst } from "lodash";
import React, { useEffect, useRef, useState } from "react";
export interface ISelectPropertyDialog {
  onSubmit: (value: any) => void;
  onClose: () => void;
  listPropertyExistedData: IPropertyGridData;
}

const SelectPropertyDialog = ({
  onSubmit,
  onClose,
  listPropertyExistedData,
}: ISelectPropertyDialog) => {
  const [gridData, setGridData] = useState<IPropertyGridData>([]);
  const [listSelectedProperties, setListSelectedProperties] =
    useState<IPropertyGridData>([]);
  const [filter, setFilter] = useState<any>();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  useEffect(() => {
    if (!filter) return;
    loadSearchResult(filter).then((data) => {
      if (!data) return;
      setGridData(data);
    });
  }, [filter]);

  const handleValidateForm: any = (
    event: React.SyntheticEvent<any>,
    formRenderProps: FormRenderProps,
    onEnter: () => void
  ) => {
    if (!formRenderProps.valid) return;
    if (checkExistedProperty(listPropertyExistedData, listSelectedProperties)) {
      notificationRef.current?.pushNotification({
        title: "Property already existed",
        type: "warning",
      });
      return;
    }
    formRenderProps.onSubmit(event);
    onEnter();
    return;
  };

  const handleSearch = (
    event: ComboBoxFilterChangeEvent,
    formRenderProps: FormRenderProps
  ) => {
    const { onChange } = formRenderProps;
    switch (event.target.name) {
      case OPTION_SEARCH.OWNER: {
        if (event.filter.value.length >= 1) {
          onChange("_option.Owner.Loading", { value: true });
          loadSearch(event.filter, OPTION_SEARCH.OWNER).then((data) => {
            onChange("_option.Owner.Data", { value: data });
            onChange("_option.Owner.Loading", { value: false });
          });
        } else {
          onChange("_option.Owner.Data", { value: [] });
        }
        break;
      }

      case OPTION_SEARCH.OWNER_ADDRESS: {
        if (event.filter.value.length >= 1) {
          onChange("_option.OwnerAddress.Loading", { value: true });
          loadSearch(event.filter, OPTION_SEARCH.OWNER_ADDRESS).then((data) => {
            onChange("_option.OwnerAddress.Data", { value: data });
            onChange("_option.OwnerAddress.Loading", { value: false });
          });
        } else {
          onChange("_option.OwnerAddress.Data", { value: [] });
        }
        break;
      }

      case OPTION_SEARCH.ASSESS_NO: {
        if (event.filter.value.length >= 1) {
          onChange("_option.AssessmentNumber.Loading", { value: true });

          loadSearch(event.filter, OPTION_SEARCH.ASSESS_NO).then((data) => {
            onChange("_option.AssessmentNumber.Data", { value: data });
            onChange("_option.AssessmentNumber.Loading", { value: false });
          });
        } else {
          onChange("_option.AssessmentNumber.Data", { value: [] });
        }
        break;
      }

      case OPTION_SEARCH.RATEPAYER_ADDRESS: {
        if (event.filter.value.length >= 1) {
          onChange("_option.RatepayerAddress.Loading", { value: true });
          loadSearch(event.filter, OPTION_SEARCH.RATEPAYER_ADDRESS).then(
            (data) => {
              onChange("_option.RatepayerAddress.Data", { value: data });
              onChange("_option.RatepayerAddress.Loading", { value: false });
            }
          );
        } else {
          onChange("_option.RatepayerAddress.Data", { value: [] });
        }
        break;
      }

      case OPTION_SEARCH.RATEPAYER: {
        if (event.filter.value.length >= 1) {
          onChange("_option.Ratepayer.Loading", { value: true });
          loadSearch(event.filter, OPTION_SEARCH.RATEPAYER).then((data) => {
            onChange("_option.Ratepayer.Data", { value: data });
            onChange("_option.Ratepayer.Loading", { value: false });
          });
        } else {
          onChange("_option.Ratepayer.Data", { value: [] });
        }
        break;
      }

      case OPTION_SEARCH.PARCEL_ID: {
        if (event.filter.value.length >= 1) {
          onChange("_option.ParcelId.Loading", { value: true });
          loadSearch(event.filter, OPTION_SEARCH.PARCEL_ID).then((data) => {
            onChange("_option.ParcelId.Data", { value: data });
            onChange("_option.ParcelId.Loading", { value: false });
          });
        } else {
          onChange("_option.ParcelId.Data", { value: [] });
        }
        break;
      }

      case OPTION_SEARCH.HOUSE_NO: {
        if (event.filter.value.length >= 1) {
          onChange("_option.HouseNumber.Loading", { value: true });
          loadSearch(event.filter, OPTION_SEARCH.HOUSE_NO).then((data) => {
            onChange("_option.HouseNumber.Data", { value: data });
            onChange("_option.HouseNumber.Loading", { value: false });
          });
        } else {
          onChange("_option.HouseNumber.Data", { value: [] });
        }
        break;
      }

      case OPTION_SEARCH.LOCALITY: {
        if (event.filter.value.length >= 1) {
          onChange("_option.Locality.Loading", { value: true });
          loadSearch(event.filter, OPTION_SEARCH.LOCALITY).then((data) => {
            onChange("_option.Locality.Data", { value: data });
            onChange("_option.Locality.Loading", { value: false });
          });
        } else {
          onChange("_option.Locality.Data", { value: [] });
        }
        break;
      }

      case OPTION_SEARCH.STREET: {
        if (event.filter.value.length >= 1) {
          onChange("_option.Street.Loading", { value: true });
          loadSearch(event.filter, OPTION_SEARCH.STREET).then((data) => {
            onChange("_option.Street.Data", { value: data });
            onChange("_option.Street.Loading", { value: false });
          });
        } else {
          onChange("_option.Street.Data", { value: [] });
        }
      }
    }
  };

  const handleSearchResult = (event: ComboBoxChangeEvent) => {
    const { value, name } = event.target;

    const newName = name ? name : "";
    const newValue = value ? value : "";
    setFilter({
      ...filter,
      [lowerFirst(newName)]: newValue,
    });
  };

  const checkExistedProperty = (
    listPropertyExistedData: any,
    listSelectedProperties: any
  ) => {
    if (listPropertyExistedData.length === 0) return false;
    const existedId = listPropertyExistedData.map((item: any) => item.Id);
    return listSelectedProperties.some((item: IPropertyData) =>
      existedId.includes(item.Id)
    );
  };

  const onSendData = () => {
    onSubmit(listSelectedProperties);
  };
  return (
    <div className="cc-new-inspection">
      <Form
        initialValues={comboboxSearchData}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                titleHeader={"Select Property"}
                onClose={onClose}
                maxHeight="70%"
                maxWidth="55%"
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <div className="cc-form-cols-1">
                      <label className="cc-label">Selection criteria</label>
                      <div className="cc-custom-sub-panel-bar">
                        <div className="cc-form-cols-3">
                          <div className="cc-field">
                            <label className="cc-label">House number</label>
                            <Field
                              name="HouseNumber"
                              filterable
                              suggest
                              onFilterChange={(
                                e: ComboBoxFilterChangeEvent
                              ) => {
                                handleSearch(e, formRenderProps);
                              }}
                              data={valueGetter("_option.HouseNumber.Data")}
                              loading={valueGetter(
                                "_option.HouseNumber.Loading"
                              )}
                              placeholder={"Search house number"}
                              component={CCComboBox}
                              onChange={handleSearchResult}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Locality</label>
                            <Field
                              name="Locality"
                              filterable
                              suggest
                              onFilterChange={(
                                e: ComboBoxFilterChangeEvent
                              ) => {
                                handleSearch(e, formRenderProps);
                              }}
                              data={valueGetter("_option.Locality.Data")}
                              loading={valueGetter("_option.Locality.Loading")}
                              placeholder={"Search locality"}
                              component={CCComboBox}
                              onChange={handleSearchResult}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Street</label>
                            <Field
                              name="Street"
                              filterable
                              suggest
                              onFilterChange={(
                                e: ComboBoxFilterChangeEvent
                              ) => {
                                handleSearch(e, formRenderProps);
                              }}
                              data={valueGetter("_option.Street.Data")}
                              loading={valueGetter("_option.Street.Loading")}
                              placeholder={"Search street"}
                              component={CCComboBox}
                              onChange={handleSearchResult}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-3">
                          <div className="cc-field">
                            <label className="cc-label">Owner</label>
                            <Field
                              name="Owner"
                              filterable
                              suggest
                              onFilterChange={(
                                e: ComboBoxFilterChangeEvent
                              ) => {
                                handleSearch(e, formRenderProps);
                              }}
                              data={valueGetter("_option.Owner.Data")}
                              loading={valueGetter("_option.Owner.Loading")}
                              placeholder={"Search owner"}
                              component={CCComboBox}
                              onChange={handleSearchResult}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Owner address</label>
                            <Field
                              name="OwnerAddress"
                              filterable
                              suggest
                              onFilterChange={(
                                e: ComboBoxFilterChangeEvent
                              ) => {
                                handleSearch(e, formRenderProps);
                              }}
                              data={valueGetter("_option.OwnerAddress.Data")}
                              loading={valueGetter(
                                "_option.OwnerAddress.Loading"
                              )}
                              placeholder={"Search owner address"}
                              component={CCComboBox}
                              onChange={handleSearchResult}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Assess number</label>
                            <Field
                              name="AssessmentNumber"
                              filterable
                              suggest
                              onFilterChange={(
                                e: ComboBoxFilterChangeEvent
                              ) => {
                                handleSearch(e, formRenderProps);
                              }}
                              data={valueGetter(
                                "_option.AssessmentNumber.Data"
                              )}
                              loading={valueGetter(
                                "_option.AssessmentNumber.Loading"
                              )}
                              placeholder={"Search assess number"}
                              component={CCComboBox}
                              onChange={handleSearchResult}
                            />
                          </div>
                        </div>

                        <div className="cc-form-cols-3">
                          <div className="cc-field">
                            <label className="cc-label">
                              Ratepayer address
                            </label>
                            <Field
                              name="RatepayerAddress"
                              filterable
                              suggest
                              onFilterChange={(
                                e: ComboBoxFilterChangeEvent
                              ) => {
                                handleSearch(e, formRenderProps);
                              }}
                              data={valueGetter(
                                "_option.RatepayerAddress.Data"
                              )}
                              loading={valueGetter(
                                "_option.RatepayerAddress.Loading"
                              )}
                              placeholder={"Search ratepayer address"}
                              component={CCComboBox}
                              onChange={handleSearchResult}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Ratepayer</label>
                            <Field
                              name="Ratepayer"
                              filterable
                              suggest
                              onFilterChange={(
                                e: ComboBoxFilterChangeEvent
                              ) => {
                                handleSearch(e, formRenderProps);
                              }}
                              data={valueGetter("_option.Ratepayer.Data")}
                              loading={valueGetter("_option.Ratepayer.Loading")}
                              placeholder={"Search ratepayer"}
                              component={CCComboBox}
                              onChange={handleSearchResult}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Parcel ID</label>
                            <Field
                              name="ParcelId"
                              filterable
                              suggest
                              onFilterChange={(
                                e: ComboBoxFilterChangeEvent
                              ) => {
                                handleSearch(e, formRenderProps);
                              }}
                              data={valueGetter("_option.ParcelId.Data")}
                              loading={valueGetter("_option.ParcelId.Loading")}
                              placeholder={"Search parcel id"}
                              component={CCComboBox}
                              onChange={handleSearchResult}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-3">
                          <div className="cc-field">
                            <label className="cc-label">
                              Exclude cleared properties
                            </label>
                            <Field
                              name="ExcludeClearedProperties"
                              component={CCSwitch}
                            />
                          </div>

                          <div className="cc-field">
                            <label className="cc-label">
                              Only include properties with a current case
                            </label>
                            <Field
                              name="OnlyIncludePropertiesWithACurrentCase"
                              component={CCSwitch}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="cc-divider" />
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <CCGrid
                          onSelectionChange={(dataItem) =>
                            setListSelectedProperties(dataItem)
                          }
                          selectableMode="multiple"
                          className="cc-property-grid-data"
                          data={gridData}
                          columnFields={colProperty}
                          primaryField="Id"
                        />
                      </div>
                    </div>
                  </div>
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button
                      type="button"
                      className="cc-dialog-button"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      className="cc-dialog-button"
                      themeColor="primary"
                      disabled={listSelectedProperties.length === 0}
                      onClick={(e) => {
                        handleValidateForm(e, formRenderProps, onSendData);
                      }}
                    >
                      Select Properties
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    </div>
  );
};

export default SelectPropertyDialog;
