import { EventManagementFieldMapping } from "@app/products/event-management/constant";
import { EVENT_MANAGEMENT_MANAGE_ROUTE } from "@app/products/event-management/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colEventManagementGroupBookingsAll: IColumnFields[] = [
  {
    field: EventManagementFieldMapping.EventBooking_ReferenceNo,
    title: "Reference Number",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => {
      return `${EVENT_MANAGEMENT_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: EventManagementFieldMapping.EventBooking_Description,
    title: "Event Description",
    width: 400,
  },
  {
    field: EventManagementFieldMapping.RequestedBy,
    title: "Requested By",
    width: 300,
  },
  {
    field: EventManagementFieldMapping.WaitingOn,
    title: "Waiting On",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: EventManagementFieldMapping.EventBooking_Status,
    title: "Status",
    width: 200,
  },
  {
    field: EventManagementFieldMapping.SubmittedDate,
    title: "Date Submitted",
    width: 300,
    format: DATE_FORMAT.DATE,
  },
  {
    field: EventManagementFieldMapping.ProcessedBy,
    title: "Processed By",
    width: 200,
  },
];
