import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colArrangements: IColumnFields[] = [
  {
    field: "PropertyAddress",
    title: "Property Address",

    width: 300,
    locked: true,
    linkTo: (dataItem) =>
      `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.AssessmentId}`,
  },
  {
    field: "ID",
    title: "Arrangement Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Date",
    title: "Date",

    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "PeriodPaymentAmount",
    title: "Period Payment Amount",

    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "PaymentFrequency",
    title: "Payment Frequency",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "InitDebtAmount",
    title: "Init Debt Amount",

    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Balance",
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "OverdueAmount",
    title: "Overdue Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "NoOfOverdueInstalments",
    title: "Number of Overdue Instalments",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "LastLetterProducedOn",
    title: "Last Letter Produced On",

    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "LastLetterType",
    title: "Last Letter Type",
  },
  {
    field: "Status",
    title: "Status",
  },
  {
    field: "InterestStatus",
    title: "Interest Status",
  },
  {
    field: "ApplicantName",
    title: "Applicant Name",
  },

  {
    field: "Comments",
    title: "Comments",
  },
  {
    field: "AuthorisingOfficer",
    title: "Authorising Officer",
  },
  {
    field: "AssessmentId",
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
