import { CSLUrl } from "@app/products/csl/config";
import {
  CSL,
  CSLRecordsState,
  CSLRegisterRequest,
  CSLResponse,
} from "@app/products/csl/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { globalStoreInstance } from "@common/stores/global/store";
import { mockCSL } from "./mock";

export const loadAllCSL = () => {
  const csl: CSL[] = JSON.parse(JSON.stringify(mockCSL));
  return csl;
};

export type IGetCslById =
  | [APIResponse<CSLResponse>, APIResponse<CSLRecordsState>]
  | APIResponse<CSLResponse>
  | undefined;
export const getCSLById = async (
  id: string,
  recordType: number
): Promise<IGetCslById> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<CSLResponse>(
        `${globalStoreInstance.allSetting.baseAPIUrl}${CSLUrl.GET_CSL.replace(
          "{id}",
          id
        )}`
      ),
      CoreAPIService.getClient().get<CSLRecordsState>(
        `${globalStoreInstance.allSetting.baseAPIUrl}/api/CSM/Internal/relatedtotal/${id}/${recordType}`
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postCSL = async (
  requestBody: CSLRegisterRequest
): Promise<APIResponse> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `${globalStoreInstance.allSetting.baseAPIUrl}${CSLUrl.CREATE_CSL}`,
      requestBody
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putCSL = async (
  requestBody: CSLRegisterRequest
): Promise<APIResponse> => {
  try {
    const response = await CoreAPIService.getClient().put(
      `${globalStoreInstance.allSetting.baseAPIUrl}${CSLUrl.UPDATE_CSL.replace(
        "{id}",
        String(requestBody.Licence_ID)
      )}`,
      requestBody
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
