import { AssetManagerForm } from "@app/products/ams/assets/[id]/child-screen/general/_index";
import { useAssetManagerStore } from "@app/products/ams/assets/[id]/store";
import { AmsRoute } from "@app/products/ams/constants/ams.route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";

export const NewManageAsset = observer(() => {
  const { currentUserInfo } = useGlobalStore();
  const { isLoading, responseLoadError, loadLovs } = useAssetManagerStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: "New Asset",
      LinkUrl: AmsRoute.ASSET_NEW_PATH,
      LinkText: `Asset Manager - Asset Register - New Asset`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
    },
  ];

  const listPanelBar: IListPanelBar[] = [];
  return (
    <Fragment>
      <FormNavigation title="ASSET" />

      {/* Asset Header */}
      <Loading isLoading={isLoading} isFullScreen />
      <>
        {responseLoadError ? (
          <CCLoadFailed
            responseError={responseLoadError}
            onReload={() => loadLovs()}
          />
        ) : (
          <>
            <FormTitle title={"New Asset"} />
            <CCManagePageActionBar
              leftActions={[
                <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              ]}
              rightActions={[
                <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
              ]}
            />
            <div className="cc-manage-container">
              <div className="cc-form cc-manage-left">
                <CCAppNotification />
                <div className="cc-manage-form-body">
                  <CCGeneralPanel component={<AssetManagerForm />} />
                  <CustomPanelBar listPanelBar={listPanelBar} />
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </Fragment>
  );
});
