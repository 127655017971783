import { NewContactButton } from "@app/core/contacts/components/buttons/new-contact/_index";
import { colContactsVIP } from "@app/core/contacts/vip/list/config";
import { CONTACT_VIP_ODATA } from "@app/core/contacts/vip/list/constant";
import { ContactManager_View_VIPContact } from "@app/core/contacts/vip/list/model";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/core/contacts/vip/list/util";
import { ContactDetailTab } from "@app/core/contacts/_id/components/reference-sidebar/detail/_index";
import { ContactHistoryTab } from "@app/core/contacts/_id/components/reference-sidebar/history/_index";
import { CONTACT_ROUTE } from "@app/core/contacts/_id/constant";
import { pprRoute } from "@app/products/town-planning/ppr/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { CCActionBarNavDropdownNew } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/_index";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<ContactManager_View_VIPContact>();
export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(FormActionCheckPermission.CONTACTS, 1);
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: pprRoute.name,
    leftComponents: [<CCActionBarNavDropdownNew />],
    centerComponents: [<NewContactButton disabled={!hasPermission} />],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={CONTACT_ROUTE}
        productType={PRODUCT_TYPE.Contact}
        recordType={RECORDTYPE.CORE_Contact}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        disabled={!hasPermission}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <ContactDetailTab /> },
      {
        title: "History",
        component: <ContactHistoryTab />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colContactsVIP}
      dataUrl={CONTACT_VIP_ODATA}
      primaryField={nameOf("Id")}
      disabled={!hasPermission}
    />
  );
};
