import { getUUID } from "@common/utils/common";
import {
  IAppNotificationItem,
  IAppNotificationItemAddProps,
} from "@components/cc-app-notification/components/notification-item/model";
import { CCAppNotificationContainer } from "@components/cc-app-notification/components/notifications-container/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { observer } from "mobx-react-lite";
import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

/* ====================================CCAppNotification Component =========================================  
  - Using useCCAppNotificationStore() to push notifications
  - Apply for Product List View and Manage Page
*/
export const CCAppNotification = observer(() => {
  const { notifications, removeNotification } = useCCAppNotificationStore();
  return (
    <CCAppNotificationContainer
      removeNotification={removeNotification}
      notifications={notifications}
    />
  );
});

/* ====================================CCLocalNotification Component =========================================  
  - Props:
    + defaultNotifications
  - Action in ref (useRef<ICCLocalNotificationHandle | null>(null))
    + pushNotification
    + updateNotification
    + removeNotification
    + resetNotifications
    + setNotifications
*/
interface ICCLocalNotificationProps {
  defaultNotifications?: IAppNotificationItem[];
}

export interface ICCLocalNotificationHandle {
  pushNotification: (
    notification: IAppNotificationItemAddProps,
    cleanUpNotification?: () => void
  ) => string;
  updateNotification: (notification: IAppNotificationItem) => void;
  removeNotification: (notificationId: string) => void;
  resetNotifications: () => void;
  setNotifications: (notifications: IAppNotificationItem[]) => void;
}

const CCLocalNotificationComp: ForwardRefRenderFunction<
  ICCLocalNotificationHandle,
  ICCLocalNotificationProps
> = ({ defaultNotifications }, ref) => {
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  useImperativeHandle(ref, () => ({
    pushNotification(notification, cleanUpNotification) {
      return handlePushNotification(notification, cleanUpNotification);
    },
    updateNotification(notification) {
      handleUpdateNotification(notification);
    },
    removeNotification(notificationId) {
      handleRemoveNotification(notificationId);
    },
    resetNotifications() {
      setNotifications([]);
    },
    setNotifications(notifications) {
      setNotifications(notifications);
    },
  }));

  const handlePushNotification = (
    newNotification: IAppNotificationItemAddProps,
    cleanUpNotification?: () => void
  ): string => {
    let newNotifications = [...notifications];
    const newNotificationId = getUUID();

    newNotifications.unshift({
      ...newNotification,
      id: newNotificationId,
    });

    setNotifications(newNotifications);
    if (cleanUpNotification) cleanUpNotification();
    return newNotificationId;
  };

  const handleUpdateNotification = (
    notificationUpdate: IAppNotificationItem
  ) => {
    setNotifications((currentNotification) =>
      currentNotification.map((item) =>
        item.id === notificationUpdate.id
          ? { ...item, ...notificationUpdate }
          : item
      )
    );
  };

  const handleRemoveNotification = (notificationId: string) => {
    setNotifications((currentNotification) =>
      currentNotification.filter((item) => item.id !== notificationId)
    );
  };

  useEffect(() => {
    if (defaultNotifications) setNotifications(defaultNotifications);
  }, [defaultNotifications]);

  return (
    <>
      {notifications?.length ? (
        <CCAppNotificationContainer
          removeNotification={handleRemoveNotification}
          notifications={notifications}
          isLocalNotification
        />
      ) : null}
    </>
  );
};
export const CCLocalNotification = forwardRef(CCLocalNotificationComp);
