import { IKeyValuePacket } from "@common/models/keyValuePacket";

export enum SearchRegisterType {
  AccountDescription = "Account Description",
  Address = "Address",
  Name = "Name",
  ReferenceTag = "Reference (Tag)",
  HistoricalReferenceTag = "Historical Reference (Tag)",
}
export enum SearchRegisterField {
  AccountDescription = "_SearchAccountDescription",
  Address = "_SearchAddress",
  Name = "_SearchName",
  ReferenceTag = "_SearchReferenceTag",
  HistoricalReferenceTag = "_SearchHistoricalReferenceTag",
}
export interface IRegisterLookup {
  RegisterSearchBy: IKeyValuePacket[];
  RegisterBy: IKeyValuePacket[];
  RegisterSearchValue: string;
  RegisterLookup: IRegisterAccountSearch[];
  SearchOptions: string[];
  _option?: {
    SearchRegister: {
      Data: IKeyValuePacket[];
      Loading: boolean;
    };
  };
}

export interface IRegisterAccountSearch {
  AccountReference: string;
  AccountId: number;
  PropertyAddress: string;
  AccountDescription: string;
  AccountName: string;
  Category?: string;
  HistoricalReference: number;
}
export const textRegisterFieldMapping: {
  [key: string]: string;
} = {
  [SearchRegisterType.AccountDescription]:
    SearchRegisterField.AccountDescription,
  [SearchRegisterType.Address]: SearchRegisterField.Address,
  [SearchRegisterType.Name]: SearchRegisterField.Name,
  [SearchRegisterType.ReferenceTag]: SearchRegisterField.ReferenceTag,
  [SearchRegisterType.HistoricalReferenceTag]:
    SearchRegisterField.HistoricalReferenceTag,
};
