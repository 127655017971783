import { Ancestor } from "@app/core/further-info/[id]/model";
import { BubbleUpType as BubbleUpTypeNumber } from "@app/core/inspections/[id]/model";
import {
  BubbleUpIdentifier,
  BubbleUpType,
} from "@app/products/waste-water/[id]/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { mapEnum } from "@common/utils/common";
import { differenceInMinutes } from "date-fns";
import { trimStart } from "lodash";

export const renderTitleBanner = (
  inspectionTypeName: string | undefined,
  compliance: string | undefined
) => {
  if (inspectionTypeName && compliance) {
    return `${inspectionTypeName} - ${compliance}`;
  }
  if (inspectionTypeName && !compliance) {
    return `${inspectionTypeName}`;
  }
  if (!inspectionTypeName && compliance) {
    return `Inspection - ${compliance}`;
  }
  return `Inspection`;
};

export const productTypeNumberLogic = (recordType?: RECORDTYPE | null) => {
  switch (recordType) {
    case RECORDTYPE.CUSTOMERSERVICE_Event:
      return PRODUCT_TYPE_NUMBER.CustomerService;
    case RECORDTYPE.DisRes_Permit:
      return PRODUCT_TYPE_NUMBER.DISRES;
    case RECORDTYPE.HealthManager_Premises:
      return PRODUCT_TYPE_NUMBER.HealthManager;
    case RECORDTYPE.WW_System:
      return PRODUCT_TYPE_NUMBER.WasteWater;
    case RECORDTYPE.WW_Installation:
    case RECORDTYPE.WW_Scheme:
    case RECORDTYPE.WW_Product:
      return PRODUCT_TYPE_NUMBER.WasteWaterSPI;
    case RECORDTYPE.CRS_Registration:
    case RECORDTYPE.CRS_FourYOKinder:
    case RECORDTYPE.CRS_HomeBasedCare:
    case RECORDTYPE.CRS_ThreeYOKinder:
    case RECORDTYPE.CRS_CentreBasedCare:
      return PRODUCT_TYPE_NUMBER.CRS;
    case RECORDTYPE.Animals_Registration:
    case RECORDTYPE.Animals_Kennel:
    case RECORDTYPE.Animals_Notices:
    case RECORDTYPE.Animals_PoundRegister:
      return PRODUCT_TYPE_NUMBER.Animals;
    case RECORDTYPE.CORE_Register_StreetProtection:
      return PRODUCT_TYPE_NUMBER.StreetProtection;
    case RECORDTYPE.LLP_Permit:
      return PRODUCT_TYPE_NUMBER.LLPermits;
    case RECORDTYPE.TP_Application:
    case RECORDTYPE.TP_Subdivision:
    case RECORDTYPE.TP_PSA_Application:
    case RECORDTYPE.TP_OtherApplication:
    case RECORDTYPE.TP_PPRApplication:
      return PRODUCT_TYPE_NUMBER.TownPlanning;
    case RECORDTYPE.TP_BuildingApplication:
      return PRODUCT_TYPE_NUMBER.Building;
    case RECORDTYPE.TP_DevAppsApplication:
      return PRODUCT_TYPE_NUMBER.DevApps;
    case RECORDTYPE.CSM_Licence:
      return PRODUCT_TYPE_NUMBER.CSM;
    case RECORDTYPE.NSP_Register:
      return PRODUCT_TYPE_NUMBER.NSPR;
    case RECORDTYPE.EMS_Facility:
    case RECORDTYPE.EMS_FacilityFeature:
    case RECORDTYPE.EMS_EventBooking:
      return PRODUCT_TYPE_NUMBER.EventManagementSystem;
    case RECORDTYPE.RAD_Register_Licence:
    case RECORDTYPE.RAD_Register_Place:
    case RECORDTYPE.RAD_Register_Source:
    case RECORDTYPE.RAD_Register_Accreditation:
      return PRODUCT_TYPE_NUMBER.Radiation;
    case RECORDTYPE.DWCMS_Application:
    case RECORDTYPE.DWCMS_Worker:
    case RECORDTYPE.DWCMS_Registration:
      return PRODUCT_TYPE_NUMBER.DWCMS;
    case RECORDTYPE.SRU_Application:
    case RECORDTYPE.SRU_Organisation:
    case RECORDTYPE.SRU_Registration:
    case RECORDTYPE.SRU_Facility:
    case RECORDTYPE.SRU_RegistrationAdministration:
    case RECORDTYPE.SRU_RegistrationStatement:
    case RECORDTYPE.SRU_Infringement:
    case RECORDTYPE.SRU_SuspendAdmission:
    case RECORDTYPE.SRU_RegistrationSanction:
      return PRODUCT_TYPE_NUMBER.StandardsAndRegulation;
    case RECORDTYPE.INFRINGEMENTS_InfringementTicket:
    case RECORDTYPE.INFRINGEMENTS_InfringementOffence:
    case RECORDTYPE.INFRINGEMENTS_InfringementCategory:
    case RECORDTYPE.INFRINGEMENTS_InfringementAnimals:
      return PRODUCT_TYPE_NUMBER.Infringements;
    case RECORDTYPE.TP_LMApplication:
      return PRODUCT_TYPE_NUMBER.LandManagement;
    case RECORDTYPE.CEM_Entry:
    case RECORDTYPE.CEM_Registration:
      return PRODUCT_TYPE_NUMBER.Cemeteries;
    default:
      return PRODUCT_TYPE_NUMBER.Core;
  }
};

export const getBubbleUpDetailsByType = (
  bubbleUpList: BubbleUpIdentifier[],
  bubbleUpType: BubbleUpTypeNumber
) => {
  return bubbleUpList.find(
    (bubbleUpItem: BubbleUpIdentifier) =>
      mapEnum<BubbleUpType>(
        bubbleUpItem.BubbleUpType_ENUM,
        BubbleUpTypeNumber
      ) === bubbleUpType
  );
};

export const validatorTimeValueWithMin = (
  value: Date | null,
  minTime: Date | null,
  startTimeLabel: string,
  completedTimeLabel: string
) => {
  if (value && minTime) {
    const difference = differenceInMinutes(value, minTime);
    if (difference < 0) {
      return `"${completedTimeLabel}" must be equal or greater than "${startTimeLabel}"`;
    }
  }
  return "";
};
export const validatorTimeValueWithMax = (
  value: Date | null,
  maxTime: Date | null,
  startTimeLabel: string,
  completedTimeLabel: string
) => {
  if (value && maxTime) {
    const difference = differenceInMinutes(maxTime, value);
    if (difference < 0) {
      return `"${startTimeLabel}" must be equal or less than "${completedTimeLabel}"`;
    }
  }
  return "";
};
export const getRefNoParent = (ancestor: Ancestor | undefined) => {
  let refNo = "0";
  if (ancestor) {
    const linkText = ancestor.linktext ?? ancestor.LinkText_Ancestor;
    if (linkText && linkText.indexOf(":") !== -1) {
      refNo = linkText.substring(linkText.indexOf(":") + 1, linkText.length);
    } else {
      refNo = linkText;
    }
  }
  return trimStart(refNo);
};
