import { createRequestsFile } from "@app/products/property/centrelink-customer-confirmation/view-request-file/components/dialogs/create-requests/api";
import { createRequestInitialValues } from "@app/products/property/centrelink-customer-confirmation/view-request-file/components/dialogs/create-requests/config";
import { DTO_Centrelink_BatchRequestObj } from "@app/products/property/centrelink-customer-confirmation/view-request-file/components/dialogs/create-requests/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isNil } from "lodash";
import React, { useRef, useState } from "react";

interface ICreateRequestsDialogProps {
  onClose: () => void;
}

const nameOfCreateRequest = nameOfFactory<DTO_Centrelink_BatchRequestObj>();

export const CreateRequests = ({ onClose }: ICreateRequestsDialogProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  const { pushNotification } = useCCAppNotificationStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (data: DTO_Centrelink_BatchRequestObj) => {
    setIsLoading(true);
    if (!isNil(data)) {
      const response = await createRequestsFile(data);
      if (
        isSuccessResponse(response) &&
        response?.data &&
        response?.data?.IsSuccess
      ) {
        onClose();
        pushNotification({
          title:
            response?.data?.SuccessMessage ??
            "Action placed on a queue successfully, please check the status through notifications.",
          type: "success",
          delay: 5000,
        });
      } else {
        notificationRef.current?.pushNotification({
          title:
            response?.data?.ErrorMessage ??
            "Your Centrelink customer confirmation create requests failed.",
          type: "error",
          autoClose: false,
        });
      }
    }
    setIsLoading(false);
  };
  return (
    <div className="cc-create-requests-dialog">
      <Form
        onSubmitClick={(data: any) => {
          handleSubmit(data?.values);
        }}
        initialValues={createRequestInitialValues}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onSubmit } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                height="auto"
                maxWidth="35%"
                titleHeader={"Create Requests"}
                onClose={onClose}
                bodyElement={
                  <div className="cc-form">
                    <div className="cc-field-group">
                      <CCLocalNotification ref={notificationRef} />
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <CCLabel
                            title="Description of the batch of concession cards to be
                          verified with centrelink"
                          />
                          <Field
                            name={nameOfCreateRequest("BatchDescription")}
                            rows={4}
                            component={CCTextArea}
                            disabled={isLoading}
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel
                            title="Only include concession cards associated with an
                          active assessment rebate"
                          />
                          <Field
                            name={nameOfCreateRequest(
                              "ActiveRebateEntitlementOnly"
                            )}
                            checked={valueGetter("ActiveRebateEntitlementOnly")}
                            component={CCSwitch}
                            disabled={isLoading}
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel title="Only include concession cards not previously confirmed" />
                          <Field
                            name={nameOfCreateRequest("UnverifiedCardsOnly")}
                            checked={valueGetter("UnverifiedCardsOnly")}
                            component={CCSwitch}
                            disabled={isLoading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button className={"cc-dialog-button"} onClick={onClose}>
                      Cancel
                    </Button>

                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      disabled={isLoading}
                      onClick={onSubmit}
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    </div>
  );
};
