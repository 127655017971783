import React from "react";

import { DayOfWeekType } from "@common/pages/settings/system-admin/task-scheduling/model";
import "./_index.scss";

export interface IDataPick {
  value: DayOfWeekType;
  label: string;
}

interface IPickDaysOfWeekProps {
  data: IDataPick[];
  values: DayOfWeekType[];
  onChange: (values: DayOfWeekType[]) => void;
}
export const PickDaysOfWeek = ({
  data,
  values,
  onChange,
}: IPickDaysOfWeekProps) => {
  const handleChangeValue = (value: DayOfWeekType) => {
    let newValues = [...values];
    if (values.includes(value)) {
      newValues = values.filter((item) => item !== value);
    } else {
      newValues.push(value);
    }
    onChange(newValues);
  };

  return (
    <div className="cc-pick-days-week">
      {data?.map((item, index) => (
        <div
          key={`${item}-${index}`}
          className={`cc-pick-days-week__item ${
            values?.includes(item.value) ? "active" : ""
          }`}
          onClick={() => {
            handleChangeValue(item.value);
          }}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};
