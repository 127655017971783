import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { colDocumentTemplate } from "@common/pages/settings/lookups/document-templates/config";
import { Svc_MailMergeDocumentTemplate } from "@common/pages/settings/lookups/document-templates/model";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<Svc_MailMergeDocumentTemplate>();
export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colDocumentTemplate}
      dataUrl={`/odata/core/internal/mailmergedocumenttemplates/GetMailMergeDocumentTemplates?$count=true&`}
      primaryField={nameOf("ID")}
      disabled={!hasPermission}
    />
  );
};
