import { listPSARApprovalDecisions } from "@app/products/town-planning/ppr/components/action-bar/dialogs/approve-decision/config";
import { IApproveDecisionDialogData } from "@app/products/town-planning/ppr/components/action-bar/dialogs/approve-decision/model";
import { getListPPRApprovalDecisions } from "@app/products/town-planning/ppr/components/action-bar/dialogs/approve-decision/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useMemo } from "react";

interface IApproveDecisionDialogProps {
  onClose: () => void;
  onSubmit: (value: IApproveDecisionDialogData) => void;
  isPSA: boolean;
  optCount: boolean;
  isSubmitLoading: boolean;
  notifications?: IAppNotificationItem[];
}

const nameOf = nameOfFactory<IApproveDecisionDialogData>();

export const ApproveDecisionDialog = ({
  onClose,
  onSubmit,
  isPSA,
  optCount,
  isSubmitLoading,
  notifications,
}: IApproveDecisionDialogProps) => {
  const { settings } = useCommonProductStore();
  const isEnablePPR = getBoolValueSetting(
    settings[ECorporateSettingsField.TP_Application_PPR]
  );

  const decisionDropDownList = useMemo(() => {
    if (isEnablePPR && isPSA) {
      return listPSARApprovalDecisions;
    } else {
      return getListPPRApprovalDecisions(optCount);
    }
  }, [isEnablePPR, isPSA, optCount]);

  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    onSubmit(event.values as IApproveDecisionDialogData);
  };

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      render={(formRenderProps: FormRenderProps) => {
        return (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="25%"
              titleHeader={"Approve Application"}
              onClose={onClose}
              disabled={isSubmitLoading}
              bodyElement={
                <div className="cc-form">
                  <CCLocalNotification defaultNotifications={notifications} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Decision
                          <CCTooltip type="validator" />
                        </label>
                        <Field
                          name={nameOf("Decision")}
                          textField={"Value"}
                          dataItemKey="Key"
                          component={CCSearchComboBox}
                          data={decisionDropDownList || []}
                          validator={requiredValidator}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Decision date</label>
                        <Field
                          name={nameOf("DecisionDate")}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE_CONTROL}
                          defaultValue={new Date()}
                        />
                      </div>
                      {isEnablePPR && isPSA && (
                        <div className="cc-field">
                          <label className="cc-label">
                            DoT notified of final decision
                          </label>
                          <Field
                            name={nameOf("DOTNotified")}
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                          />
                        </div>
                      )}
                    </div>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={!formRenderProps.valid}
                    onClick={formRenderProps.onSubmit}
                    iconClass={isSubmitLoading ? "fas fa-spinner fa-spin" : ""}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
