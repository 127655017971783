(() => {
  if ("function" != typeof window.FreshworksWidget) {
    var n = function () {
      n.q.push(arguments);
    };
    n.q = [];
    window.FreshworksWidget = n;
  }
})();
window.HideFreshWidget = () => window.FreshworksWidget("hide");
window.OpenFreshWidget = () => window.FreshworksWidget("open");
