import {
  optionInputPickerApplicationCategory,
  optionInputPickerOfficer,
  optionInputPickerRating,
  optionInputPickerReasonForPermit,
} from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { PPRApplicationCategory } from "@app/products/town-planning/ppr/[id]/components/input-picker/category-picker-search/model";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { ApplicationUpdateTriggers } from "@app/products/town-planning/ppr/[id]/model";
import {
  IPPROfficerAllocation,
  PPROfficerAllocation,
} from "@app/products/town-planning/ppr/components/action-bar/dialogs/allocate-to-officer/model";
import { SiteUser } from "@app/products/waste-water/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { isString } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

interface IAllocateToOfficerDialogProps {
  onClose: () => void;
  onSubmit: (data: PPROfficerAllocation) => void;
  isLoading: boolean;
  initialData: IPPROfficerAllocation;
  notification?: IAppNotificationItemAddProps;
}

export const AllocateToOfficerDialog = observer(
  ({
    onClose,
    onSubmit,
    isLoading,
    initialData,
    notification,
  }: IAllocateToOfficerDialogProps) => {
    const nameOf = nameOfFactory<IPPROfficerAllocation>();

    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const handleOnSubmitClick = async (event: FormSubmitClickEvent) => {
      if (event.event?.currentTarget.name !== "AllocateToOfficerDialog") return;
      const data: IPPROfficerAllocation = { ...event.values };
      const req: PPROfficerAllocation = {
        ReasonForPermit: data?.ReasonForPermit,
        ApplicationCategories: data?._option?.ApplicationCategories,
        Rating_KWD: data?.Rating_KWD,
        Planner_ID: data?.Planner_ID,
        AllocationComments: data?.AllocationComments,
        SaveTriggers: data?.SaveTriggers,
      };
      onSubmit(req);
    };

    useEffect(() => {
      if (!notification) {
        notificationRef.current?.resetNotifications();
      } else {
        notificationRef.current?.pushNotification(notification);
      }
    }, [notification]);

    return (
      <Form
        onSubmitClick={handleOnSubmitClick}
        initialValues={initialData}
        render={(formRenderProps: FormRenderProps) => {
          const { onChange } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                titleHeader={"Officer Assessment"}
                onClose={onClose}
                maxWidth={"45%"}
                height={"auto"}
                disabled={isLoading}
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <section className="cc-field-group">
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">
                            Reason for permit
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            allowCustom
                            name={nameOf("ReasonForPermit")}
                            placeholder="Reason for permit"
                            component={InputPickerSearch}
                            validator={requiredValidator}
                            onChange={(data: any) => {
                              if (isString(data) && data?.trim()?.length) {
                                onChange(nameOf("ReasonForPermit"), {
                                  value: data,
                                });
                                return;
                              }
                              onChange(nameOf("ReasonForPermit"), {
                                value: data?.Name,
                              });
                            }}
                            options={optionInputPickerReasonForPermit}
                          />
                        </div>

                        <div className="cc-field">
                          <label className="cc-label">
                            Application category
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            name="ApplicationCategories"
                            nameDisplayMerge="ApplicationCategory_Name"
                            placeholder="Application categories"
                            component={InputPickerSearch}
                            validator={requiredValidator}
                            onChange={(data: PPRApplicationCategory[]) => {
                              onChange("ApplicationCategories", {
                                value: data,
                              });
                              onChange("SaveTriggers", {
                                value: [
                                  ApplicationUpdateTriggers.UpdateApplicationCategory,
                                ],
                              });
                              onChange("_option.ApplicationCategories", {
                                value: data?.map(
                                  (itemCategory: PPRApplicationCategory) =>
                                    itemCategory.ID
                                ),
                              });
                            }}
                            options={optionInputPickerApplicationCategory}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">Rating</label>
                          <Field
                            name="Rating_Name"
                            placeholder="Rating"
                            component={InputPickerSearch}
                            validator={requiredValidator}
                            onChange={(data: any) => {
                              onChange("Rating_Name", {
                                value: data?.Name,
                              });
                              onChange("Rating_KWD", {
                                value: data?.Keyword_ID,
                              });
                            }}
                            options={optionInputPickerRating}
                          />
                        </div>

                        <div className="cc-field">
                          <label className="cc-label">
                            Planner
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            name="Planner"
                            placeholder="Planner"
                            component={InputPickerSearch}
                            onChange={(data: SiteUser) => {
                              onChange("Planner_ID", {
                                value: data?.Contact_ID,
                              });
                              onChange("Planner", {
                                value: data?.DisplayName,
                              });
                            }}
                            options={optionInputPickerOfficer}
                            validator={requiredValidator}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">Comments</label>
                          <Field
                            name={nameOf("AllocationComments")}
                            component={CCTextArea}
                            rows={3}
                            placeholder={"Comments"}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button className="cc-dialog-button" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      disabled={!formRenderProps.valid}
                      onClick={formRenderProps.onSubmit}
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                      name="AllocateToOfficerDialog"
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
