import {
  BELL_CHARACTER,
  SEPARATOR_CHARACTER,
} from "@common/constants/characters";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { ProductListing } from "@common/pages/settings/security/security-templates/model";
import { IKeyValueFinanceSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/components/common-form-finance/model";
import { filter, indexOf } from "lodash";

export const getIndexField = (
  groupShow: number[],
  position: number | undefined,
  enumFieldName: number
) => {
  let index = indexOf(
    groupShow.filter((v, i, s) => s.indexOf(v) === i),
    enumFieldName
  );
  if (index > 0) {
    index = index * 10;
  }
  if (position) {
    index = position;
  }
  return index;
};

export const getPositionFieldFinance = (
  listShow: ECorporateSettingsField[],
  enumFieldName: number,
  obj?: any
) => {
  let index = indexOf(listShow, enumFieldName);
  if (index > 0) {
    index = index * 10;
  }
  return obj && obj[enumFieldName] ? obj[enumFieldName] : index;
};

export const addBeforeEnumInArrFinance = (
  arrDefault: number[],
  valueAdd: IKeyValueFinanceSettings[]
) => {
  valueAdd.forEach((item) => {
    const index = arrDefault.indexOf(item.key);
    if (Array.isArray(item.value)) {
      arrDefault.splice(index, 0, ...item.value);
    } else {
      arrDefault.splice(index, 0, item.value);
    }
  });
};

export const addAfterEnumInArrFinance = (
  arrDefault: number[],
  valueAdd: IKeyValueFinanceSettings[]
) => {
  valueAdd.forEach((item) => {
    const index = arrDefault.indexOf(item.key);
    if (Array.isArray(item.value)) {
      arrDefault.splice(index + 1, 0, ...item.value);
    } else {
      arrDefault.splice(index + 1, 0, item.value);
    }
  });
};

export const removeEnumInArrFinance = (
  arrDefault: number[],
  valueRemove: number[] | number
) => {
  if (Array.isArray(valueRemove)) {
    valueRemove.forEach((item) => {
      const index = arrDefault.indexOf(item);
      if (index !== -1) {
        arrDefault.splice(index, 1);
      }
    });
  } else {
    const index = arrDefault.indexOf(valueRemove);
    if (index !== -1) {
      arrDefault.splice(index, 1);
    }
  }
};

/* ====  For table render  ==== */
export const getLabel = (
  enumField: string,
  dataEnableProducts: IKeyValuePacket[]
) => {
  let obj = filter(dataEnableProducts, { Key: +enumField });
  return obj[0]?.Value || "No Name";
};

export const getLabelTable = (
  enumField: string,
  dataEnableProducts: ProductListing[]
) => {
  let obj = filter(dataEnableProducts, { ProductType_Int: +enumField });
  return obj[0]?.ProductType_Name || "No Name";
};

export const transformDataFieldsMapping: any = (
  data: string,
  dataEnableProducts: IKeyValuePacket[]
) => {
  const listFields = data.split(SEPARATOR_CHARACTER);
  return listFields.map((item: string) => {
    const dataField = item.split(BELL_CHARACTER);
    let productsActive = filter(dataEnableProducts, { Key: +dataField[0] });
    if (productsActive.length) {
      return dataField;
    }
    return null;
  });
};

export const transformDataFieldsMappingTable: any = (
  data: string,
  dataEnableProducts: ProductListing[]
) => {
  const listFields = data.split(SEPARATOR_CHARACTER);
  const enumListBD = listFields.map(
    (v) => +v.slice(0, v.indexOf(BELL_CHARACTER))
  );
  const dataFields: any = [];
  let allProduct = dataEnableProducts.map((v: any) => v.ProductType_Int);
  allProduct.forEach((item: number) => {
    const index = enumListBD.indexOf(item);
    if (index !== -1) {
      dataFields.push(listFields[index].split(BELL_CHARACTER));
    } else {
      dataFields.push([item]);
    }
  });
  return dataFields;
};

export const convertToDataOrigin = (data: string[]) => {
  return data
    .map((item: any) => item.join(BELL_CHARACTER))
    .join(SEPARATOR_CHARACTER);
};
/* ====  / For table render  ==== */
