import { mockInspectionData } from "@app/products/property/fire-prevention/components/action-bar/buttons/re-inspection/mock";
import { IReInspectionFormData } from "@app/products/property/fire-prevention/components/dialogs/re-inspection/model";
import { sleep } from "@common/utils/common";

export const loadInspectionByCaseId = async (
  id: number | string
): Promise<IReInspectionFormData> => {
  await sleep(500);
  return { ...mockInspectionData, CaseId: id };
};
