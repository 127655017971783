import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { BOOLEAN_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSInfoWorkflow2ndLetterSent: IColumnFields[] = [
  {
    field: CRSFieldMapping.AppNo,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  { field: CRSFieldMapping.ChildName, title: "Child Name" },
  {
    field: CRSFieldMapping.ChildDateOfBirth,
    title: "Date of Birth",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.ChildGender,
    title: "Gender",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: CRSFieldMapping.Parent1Name, title: "Parent Name" },
  { field: CRSFieldMapping.Parent1Relation, title: "Relationship" },
  { field: CRSFieldMapping.LastLetterSent, title: "Last Sent" },
  { field: CRSFieldMapping.PrefMethod, title: "Preference Method" },
];
