import {
  dataSelectedAssessment,
  listOptionHideLevy,
  listOptionIsMakeInactive,
} from "@app/products/property/assessments/list/components/form-steps/bulk-levy-update/components/form-elements/levy-details/config";
import {
  mockDataLevy,
  mockDataLevyCategory,
} from "@app/products/property/assessments/list/components/form-steps/bulk-levy-update/components/form-elements/levy-details/mock";
import { IRadioGroupChangeEventCustom } from "@app/products/property/assessments/list/components/form-steps/bulk-levy-update/components/form-elements/levy-details/model";
import {
  DATE_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCNote } from "@components/cc-note/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const LevyDetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;

    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const selectedAssessment = getFieldValue("ChangeType");
    const handleChangeTypeOnChange = (event: IRadioGroupChangeEventCustom) => {
      const newDescription =
        dataSelectedAssessment.find((item) => item.value === event?.value)
          ?.description ?? "";
      onChange(nameOf("Description"), { value: newDescription });
      onChange(nameOf("ChangeType"), { value: event?.value });
    };

    return (
      <div className="cc-update-levies-dialog cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Levy category in which levies are to be changed?
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("LevyCategory")}
                component={CCSearchComboBox}
                dataItemKey="Code"
                textField="Name"
                data={mockDataLevyCategory ?? []}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                isUseDefaultOnchange
                disabled={options?.isReadOnly}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                For the selected assessments, what change would you like?
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("ChangeType")}
                component={CCRadioGroup}
                data={dataSelectedAssessment}
                onChange={handleChangeTypeOnChange}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                disabled={options?.isReadOnly}
              />
            </div>
            {!listOptionHideLevy.includes(selectedAssessment) && (
              <div className="cc-field">
                <label className="cc-label">
                  Levy
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={nameOf("Levy")}
                  component={CCSearchComboBox}
                  dataItemKey="Code"
                  textField="Name"
                  isUseDefaultOnchange
                  data={mockDataLevy ?? []}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                  disabled={options?.isReadOnly}
                />
              </div>
            )}
          </div>
          <div className="cc-form-cols-2">
            {!listOptionIsMakeInactive.includes(selectedAssessment) && (
              <>
                <div className="cc-field">
                  <label className="cc-label">
                    Percentage of full change (default 100%)
                  </label>
                  <Field
                    name={nameOf("PercentageOfFullCharge")}
                    component={CCNumericTextBox}
                    format={PERCENTAGE_FORMAT.PERCENTAGE}
                    defaultValue={100}
                    max={100}
                    min={0}
                    disabled={options?.isReadOnly}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">
                    Active from date
                    <CCTooltip type="validator" position="right" />
                  </label>
                  <Field
                    name={nameOf("ActiveFromDate")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                    validator={
                      !options?.isReadOnly ? requiredValidator : undefined
                    }
                    disabled={options?.isReadOnly}
                  />
                </div>
              </>
            )}
            {listOptionIsMakeInactive.includes(selectedAssessment) && (
              <div className="cc-field">
                <label className="cc-label">
                  Active to date <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={nameOf("ActiveToDate")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                  disabled={options?.isReadOnly}
                />
              </div>
            )}
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Description of levy change?{" "}
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("Description")}
                component={CCInput}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                placeholder="Description"
                readOnly={options?.isReadOnly}
              />
            </div>
            <CCNote
              message={
                <>
                  <p>
                    Levy charges are placed in a Supplementary where they can be
                    reviewed, amended, deleted or updated.
                  </p>
                  <p>
                    If the Supplementary is set to raise charges, Prorata
                    adjustments are automatically journalised.
                  </p>
                </>
              }
            />
          </div>
        </div>
      </div>
    );
  }
);
