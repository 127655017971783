import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { FirePreventionContactsTab } from "@app/products/property/fire-prevention/hazld-case/[id]/components/reference-sidebar/contacts/_index";
import { FirePreventionDetailTab } from "@app/products/property/fire-prevention/hazld-case/[id]/components/reference-sidebar/detail/_index";
import { colOnHoldCases } from "@app/products/property/fire-prevention/historical/on-hold-cases/config";
import { mockOnHoldCases } from "@app/products/property/fire-prevention/historical/on-hold-cases/mock";
import { firePreventionRoute } from "@app/products/property/fire-prevention/route";
import { propertyRoute } from "@app/products/property/route";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={firePreventionRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      //  Holding now but can use later
      // <ListViewBookmarkIcon
      //   url={FIRE_PREVENTION_ROUTE}
      //   displayName={getBookmarkDisplayName}
      //   detail={getBookmarkDetail}
      // />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <FirePreventionDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Contacts",
        component: <FirePreventionContactsTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_HazardId_Fire_Prevention}
          />
        ),
      },
    ],
  });
  return (
    <CCProductListView
      columnFields={colOnHoldCases}
      data={mockOnHoldCases}
      primaryField="ParcelId"
    />
  );
};
