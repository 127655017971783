import { LotDetails } from "@app/products/property/changes-of-ownership/notice-of-sales/components/detail/components/lot-details/_index";
import { ServiceOfNotice } from "@app/products/property/changes-of-ownership/notice-of-sales/components/detail/components/service-of-notice/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";

export const NoticeOfSaleDetails = (props: GridDetailRowProps) => {
  const listPanelBar: IListPanelBar[] = [
    {
      title: "Service of Notice",
      component: <ServiceOfNotice {...props} />,
    },
    {
      title: "Lot Details",
      component: <LotDetails {...props} />,
    },
  ];

  return <CustomPanelBar listPanelBar={listPanelBar} sort={false} />;
};
