import { IAddress } from "@app/core/addresses/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const nameOfAddress = nameOfFactory<IAddress>();
export const colAddress: IColumnFields[] = [
  {
    field: nameOfAddress("Address"),
    title: "Address",
    locked: true,
  },
  {
    field: nameOfAddress("Locality"),
    title: "Locality",
  },
  {
    field: nameOfAddress("AssessmentNo"),
    title: "Assessment Number",
  },
  {
    field: nameOfAddress("Owner"),
    title: "Owner",
  },
  {
    field: nameOfAddress("LegalDescription"),
    title: "Legal Description",
  },
];
