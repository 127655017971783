import { mockSchemeAccountDetail } from "@app/products/property/schemes/[id]/account/[id]/mock";
import { ISchemeAccountResponse } from "@app/products/property/schemes/[id]/account/[id]/model";
import { mockAccountsInTheScheme } from "@app/products/property/schemes/[id]/components/child-screens/accounts/mock";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";

export const getSchemeAccountById = async (
  id: number
): Promise<APIResponse<ISchemeAccountResponse | undefined>> => {
  const dataResponse = mockAccountsInTheScheme.find(
    (item: any) => item?.SchemeAccountId === id
  );
  if (dataResponse) {
    return {
      data: { ...mockSchemeAccountDetail, AccountId: id },
      status: APIResponseStatus.SUCCESS,
      statusText: "Ok",
      headers: {},
      config: {},
    };
  } else {
    return {
      data: undefined,
      status: APIResponseStatus.NOT_FOUND,
      statusText: "Not Found",
      headers: {},
      config: {},
    };
  }
};
