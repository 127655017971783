import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { POUND_REGISTER_ROUTE } from "@app/products/animals/pound-register/[id]/constant";
import { PanelID } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/case-details/animal-wandering/model";
import {
  CS_Confidentiality,
  CS_IssueLocationType,
  Event,
} from "@app/products/crms/[id]/model";
import {
  checkProductActiveAndFlagCommunication,
  getManageHubURL,
} from "@app/products/crms/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { formatDisplayValue } from "@common/utils/formatting";
import { ActiveProduct } from "@components/layout/model";
import { isEmpty, isNil } from "lodash";

export interface ContentHeaderDefault {
  HeaderSubTitle1: string;
  HeaderSubTitle2: string;
  HeaderSubTitle3: string;
  HeaderSubTitle4: string;
  HeaderSubTitle5: string;
  HeaderSubTitle6: string;
}

export const DisplayContentHeader = (
  panelID: PanelID,
  crmsEvent: Event,
  accessRights?: ACCESSRIGHTS
) => {
  const _Obfuscate =
    accessRights === ACCESSRIGHTS.CanRead &&
    crmsEvent?.Confidentiality_ENUM === CS_Confidentiality.Confidential;

  var HeaderSubTitle1 = "";
  var HeaderSubTitle2 = "";
  var HeaderSubTitle3 = "";
  var HeaderSubTitle4 = "";
  var HeaderSubTitle5 = "";
  var HeaderSubTitle6 = "";

  if (!isEmpty(crmsEvent?.ServiceStandardType_Name))
    HeaderSubTitle1 = crmsEvent.ServiceStandardType_Name;
  else HeaderSubTitle1 = "";
  if (!isNil(crmsEvent?.ServiceStandard))
    HeaderSubTitle3 = crmsEvent.ServiceStandard?.FullName;
  else HeaderSubTitle3 = "Category unknown";

  if (
    !isEmpty(crmsEvent?.IssueLocation?.LocationAddress?.Formatted_SingleLine) &&
    !_Obfuscate
  ) {
    HeaderSubTitle2 = "Location";
    HeaderSubTitle4 =
      crmsEvent.IssueLocation.LocationAddress.Formatted_SingleLine;

    //#region Extra Logic to include Health Premises Trading Name at the start of the Location Address
    if (
      crmsEvent?.IssueLocation?.LocationType_ENUM ===
        CS_IssueLocationType.HealthPremises ||
      crmsEvent?.IssueLocation?.LocationType_ENUM ===
        CS_IssueLocationType.TobaccoPremises
    ) {
      if (!isEmpty(crmsEvent?.Location_LicensedName))
        HeaderSubTitle4 =
          crmsEvent.Location_LicensedName +
          " - " +
          crmsEvent.IssueLocation.LocationAddress.Formatted_SingleLine;
    }
    //#endregion

    //#region Extra Logic to include Location Register Description at the start of the Location Address
    if (
      crmsEvent.IssueLocation.LocationType_ENUM ===
        CS_IssueLocationType.CommunityProperty ||
      crmsEvent.IssueLocation.LocationType_ENUM === CS_IssueLocationType.Reserve
    ) {
      if (!isEmpty(crmsEvent?.Register_Description))
        HeaderSubTitle4 =
          crmsEvent.Register_Description +
          " - " +
          crmsEvent.IssueLocation.LocationAddress.Formatted_SingleLine;
    }
    //#endregion
  } else {
    HeaderSubTitle2 = "";
    HeaderSubTitle4 = "";
  }

  switch (panelID) {
    case PanelID.pnlCaseAnimalsWandering:
      HeaderSubTitle1 = !isNil(crmsEvent?.IncidentDate)
        ? "Incident Date" +
          " - " +
          formatDisplayValue(crmsEvent?.IncidentDate, DATE_FORMAT.DATE)
        : HeaderSubTitle1;
      // HeaderSubTitle2 = HeaderSubTitle2;
      // HeaderSubTitle3 = (HeaderSubTitle3.length > 50 ? HeaderSubTitle3.Substring(0, 50) + "..." : HeaderSubTitle3);
      // HeaderSubTitle3 = HeaderSubTitle3;
      // HeaderSubTitle4 = HeaderSubTitle4;
      HeaderSubTitle5 = "Claimant Name";
      HeaderSubTitle6 = GetClaimantName(crmsEvent);
      break;
  }
  var result = {
    HeaderSubTitle1: HeaderSubTitle1,
    HeaderSubTitle2: HeaderSubTitle2,
    HeaderSubTitle3: HeaderSubTitle3,
    HeaderSubTitle4: HeaderSubTitle4,
    HeaderSubTitle5: HeaderSubTitle5,
    HeaderSubTitle6: HeaderSubTitle6,
  } as ContentHeaderDefault;

  return result;
};

const GetClaimantName = (crmsEvent: Event) => {
  var claimantName = "";

  if (!isEmpty(crmsEvent?.OnBehalfOf_DisplayName))
    claimantName = crmsEvent.OnBehalfOf_DisplayName;

  if (
    !isEmpty(crmsEvent?.RequestedBy_DisplayName) &&
    isEmpty(crmsEvent?.OnBehalfOf_DisplayName)
  )
    claimantName = crmsEvent.RequestedBy_DisplayName;

  return claimantName;
};

export const caseDetailAnimalWanderingLaunchAnimal = (
  animalWanderingAniRegister: number,
  productsActive: ActiveProduct[],
  siteBaseUrl: string
) => {
  if (isNil(animalWanderingAniRegister)) return;

  if (
    !checkProductActiveAndFlagCommunication(
      productsActive,
      PRODUCT_TYPE.Animals
    )
  ) {
    if (siteBaseUrl.length > 0 && !siteBaseUrl.endsWith("/"))
      siteBaseUrl += "/";

    window.open(
      `${siteBaseUrl}${getManageHubURL(
        RECORDTYPE.Animals_Registration,
        animalWanderingAniRegister
      )}`,
      "_blank"
    );
  } else {
    window.open(`${ANIMALS_ROUTE}/${animalWanderingAniRegister}`, "_blank");
  }
};

export const caseDetailAnimalWanderingLaunchPound = (
  poundID: number,
  productsActive: ActiveProduct[],
  siteBaseUrl: string
) => {
  if (isNil(poundID)) return;

  if (
    !checkProductActiveAndFlagCommunication(
      productsActive,
      PRODUCT_TYPE.Animals
    )
  ) {
    if (siteBaseUrl.length > 0 && !siteBaseUrl.endsWith("/"))
      siteBaseUrl += "/";

    window.open(
      `${siteBaseUrl}${getManageHubURL(
        RECORDTYPE.Animals_PoundRegister,
        poundID
      )}`,
      "_blank"
    );
  } else {
    window.open(`${POUND_REGISTER_ROUTE}/${poundID}`, "_blank");
  }
};
