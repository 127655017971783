import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { saveMailMergeDocument } from "@app/core/new-mail-merge/buttons/components/buttons/api";
import { MailMergeSelectDocument } from "@app/core/new-mail-merge/buttons/components/buttons/model";
import { MailMergeDialog } from "@app/core/new-mail-merge/dialogs/_index";
import {
  MAILMERGEDATASET,
  MAILMERGESAVEFORMAT,
  MailMerge_SelectMergeDocument,
  MailMerge_SelectMergeDocument_Ext,
} from "@app/core/new-mail-merge/dialogs/model";
import { setUpMailMergeFromForm } from "@app/core/new-mail-merge/dialogs/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { FormAction } from "@app/products/crms/model";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { DownloadFile } from "@common/constants/downloadfile";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { customLogger } from "@common/utils/logger";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useState } from "react";

interface IAddDocumentButtonProps {
  dataSetFilter: MAILMERGEDATASET;
  recordType: RECORDTYPE;
  productType: PRODUCT_TYPE_NUMBER;
  id?: number;
  bubbleUps?: BubbleUpIdentifier[];
  isDisabled?: boolean;
  formAction?: FormAction;
  buttonLabel?: string;
  handleClickAction?: (
    mailMerge: MailMerge_SelectMergeDocument
  ) => Promise<MailMerge_SelectMergeDocument | undefined>;
}

export const AddDocumentButton = observer(
  ({
    dataSetFilter,
    recordType,
    productType,
    id,
    bubbleUps,
    formAction = FormAction.CORE_TOOLBAR_ADD_DOCUMENT,
    isDisabled = false,
    buttonLabel = "Mail merge",
    handleClickAction,
  }: IAddDocumentButtonProps) => {
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showMailMergeDialog, setShowMailMergeDialog] = useState(false);
    const [isLoadingSubAction, setIsLoadingSubAction] = useState(false);
    const [initialMailMerge, setInitialMailMerge] =
      useState<MailMerge_SelectMergeDocument_Ext>();

    const handleOnClick = async () => {
      let mailMerge = setUpMailMergeFromForm();
      if (handleClickAction) {
        setIsLoadingSubAction(true);
        const result = await handleClickAction(mailMerge);
        setIsLoadingSubAction(false);
        if (isNil(result)) return;
        mailMerge = result;
      }
      mailMerge.Dataset_Filters.push(dataSetFilter);
      setInitialMailMerge({
        ...mailMerge,
        _options: {
          SaveAsPDF: mailMerge.SaveFormat === MAILMERGESAVEFORMAT.Pdf,
        },
      });
      setShowMailMergeDialog(true);
    };

    const handleOnSubmit = async (
      mailMerge: MailMerge_SelectMergeDocument_Ext
    ) => {
      if (id) {
        const requestBody: MailMergeSelectDocument = {
          SelectedRecordIDs: [id],
          Category: mailMerge?.Category_KWD,
          RecordType: recordType,
          SelectedMailMergeDocumentIDs: mailMerge?.SelectedDocumentIDs,
          AppendToRecordSource: mailMerge?.AppendToRecordSource,
          BatchPrint: mailMerge?.BatchPrint,
          SaveAsPdf: mailMerge?._options?.SaveAsPDF ?? false,
          Collate: mailMerge?.Collate,
          BubbleUps: bubbleUps,
        };
        setIsSubmitting(true);
        const response = await saveMailMergeDocument(
          productType,
          requestBody,
          formAction // formAction: use for security template
        );
        customLogger(
          "Core documents, Add Document, saveMailMergeDocument"
        ).info(response?.data);
        setIsSubmitting(false);
        if (isSuccessResponse(response)) {
          clearErrorNotification();
          pushNotification({
            title: `${pluralize(
              "Document",
              mailMerge?.SelectedDocumentIDs?.length
            )} created successfully`,
            type: "success",
          });
          if (
            requestBody?.Collate === true &&
            response.data?.Data != null &&
            response.data?.Data.length > 0
          ) {
            DownloadFile(
              response.data.Data,
              response.data.ContentType,
              response.data.FileName
            );
          }
          eventEmitter.emit(CCGridEventType.RefreshOData);
          eventEmitter.emit(CCJournalEventType.RefreshData);
          eventEmitter.emit(TabTableEventType.RefreshData);
        } else {
          pushNotification({
            autoClose: false,
            title: `Create ${pluralize(
              "document",
              mailMerge?.SelectedDocumentIDs?.length
            )} failed`,
            description: response.error ?? response.statusText,
            type: "error",
          });
        }
      }
      setShowMailMergeDialog(false);
    };

    return (
      <>
        <CCNavButton
          title={buttonLabel}
          onClick={handleOnClick}
          isLoading={isLoadingSubAction}
          disabled={isDisabled}
        />
        {showMailMergeDialog && (
          <MailMergeDialog
            isSubmitting={isSubmitting}
            initialMailMerge={initialMailMerge}
            onClose={() => {
              setShowMailMergeDialog(false);
            }}
            onSubmit={(mailMerge: MailMerge_SelectMergeDocument_Ext) =>
              handleOnSubmit(mailMerge)
            }
            productType={productType}
            isMultiple
          />
        )}
      </>
    );
  }
);
