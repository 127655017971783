import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface ITrimClickOnce {
  formRenderProps: FormRenderProps;
}
export const TrimClickOnce = observer(({ formRenderProps }: ITrimClickOnce) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { getConfigDataField } = useSettingGlobalStore();

  const { valueGetter } = formRenderProps;

  const fieldEnableGoTRIM = getConfigDataField(
    ECorporateSettingsField.TRIMAdmin_EnableTRIMWebLink
  );
  const fieldDefaultClickOnceVersion = getConfigDataField(
    ECorporateSettingsField.TRIMAdmin_DefaultClickOnceVersion
  );
  const fieldTRIMClickOnceURL = getConfigDataField(
    ECorporateSettingsField.TRIMAdmin_ClickOnceURL
  );

  return (
    <>
      {!valueGetter(fieldEnableGoTRIM?.FieldName ?? "") && (
        <>
          {fieldDefaultClickOnceVersion && (
            <div className="cc-form-cols-1">
              <CCSearchComboBoxGlobalSettings
                data={fieldDefaultClickOnceVersion}
                isEditing={isEditing}
              />
            </div>
          )}

          {fieldTRIMClickOnceURL && (
            <div className="cc-form-cols-1">
              <InputGlobalSettings
                data={fieldTRIMClickOnceURL}
                isEditing={isEditing}
              />
            </div>
          )}
        </>
      )}
    </>
  );
});
