import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import {
  getIsIncompleteMode,
  getIsReadOnly,
  getIsShowCancelWorkflowButton,
  getIsToBeApprovalMode,
  getStatusBadge,
} from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/util";
import {
  WorkflowApprovalStatus,
  WorkflowStatus,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { isEmpty } from "lodash";
import { useMemo } from "react";

export interface IStatusValues {
  isSaved: boolean;
  isParked: boolean;
  isIncomplete: boolean;
  isCompleted: boolean;
  isCancelled: boolean;
  isToBeApproved: boolean;
  isApproved: boolean;
  isRejected: boolean;
}

export const usePropertyWorkflow = (workflowDraftData?: VO_Workflow_Draft) => {
  // #region defined variable workflow Status & workflow approval status
  const workflowStatusMode = useMemo(() => {
    const workflowStatus = workflowDraftData?.Workflow_Status;
    const approvalStatus = workflowDraftData?.Workflow_Approval_Status;

    const statusValues = {
      isSaved: workflowStatus === WorkflowStatus.Saved,
      isParked: workflowStatus === WorkflowStatus.Parked,
      isIncomplete: workflowStatus === WorkflowStatus.Incomplete,
      isCompleted: workflowStatus === WorkflowStatus.Completed,
      isCancelled: workflowStatus === WorkflowStatus.Cancelled,
      isToBeApproved:
        approvalStatus === WorkflowApprovalStatus.ToBeApproved ||
        approvalStatus === WorkflowApprovalStatus.AwaitingGisApproval,
      isApproved: approvalStatus === WorkflowApprovalStatus.Approved,
      isRejected: approvalStatus === WorkflowApprovalStatus.Rejected,
    };
    return statusValues;
  }, [
    workflowDraftData?.Workflow_Approval_Status,
    workflowDraftData?.Workflow_Status,
  ]);
  // #endregion

  // #region handle logic to return flag for workflow
  const isShowCancelWorkflowButton = getIsShowCancelWorkflowButton(
    workflowStatusMode,
    workflowDraftData?.Workflow_Status
  );

  const isIncompleteMode = getIsIncompleteMode(workflowStatusMode);

  const statusBadge = getStatusBadge(workflowStatusMode, workflowDraftData);

  const isFromActionList = !isEmpty(workflowDraftData);

  const isReadOnly = getIsReadOnly(workflowStatusMode);

  const isToBeApprovalMode = getIsToBeApprovalMode(workflowStatusMode);
  // #endregion

  return {
    isIncompleteMode,
    isShowCancelWorkflowButton,
    isBeingApprovedMode: workflowStatusMode.isToBeApproved,
    isShowReasonRejection: workflowStatusMode.isRejected,
    isCancelMode: workflowStatusMode.isCancelled,
    isApproved: workflowStatusMode.isApproved,
    isFromActionList,
    isReadOnly,
    isToBeApprovalMode,
    statusBadge,
  };
};
