import { NoData } from "@components/no-data/NoData";
import React from "react";

const ConcessionCards = () => {
  return (
    <div className="cc-contact-concession-card cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Concession cards held</label>
            <NoData vertical />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Verification history for concession cards
            </label>
            <NoData vertical />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConcessionCards;
