import { SecurityTmpFormElement } from "@common/pages/settings/security/security-templates/_id/child-screens/general/components/form-element/_index";
import { useSecurityTemplateStore } from "@common/pages/settings/security/security-templates/_id/store";
import {
  SecurityTemplateSubmitActions,
  SecurityTemplate_Ext,
} from "@common/pages/settings/security/security-templates/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const SecurityTemplateForm = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const { securityTemplate, setIsLoading, saveSecurityTemplate, setOnSubmit } =
    useSecurityTemplateStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleSubmit = async (event: FormSubmitClickEvent) => {
    const actionSubmit = event.event?.currentTarget
      .name as SecurityTemplateSubmitActions;
    const { values, isModified, isValid } = event;
    if (!(actionSubmit in SecurityTemplateSubmitActions)) return;
    if (!isValid) return;
    if (
      !isModified &&
      [
        SecurityTemplateSubmitActions.Save,
        SecurityTemplateSubmitActions.New,
      ].includes(actionSubmit)
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    const data = values as SecurityTemplate_Ext;
    const securityTemplateInfo = {
      ...values,
      ProductType_ENUM:
        data._options?.Product?.ProductType_ENUM ?? values.ProductType_ENUM,
      ProductType_Name:
        data._options?.Product?.ProductType_Name ?? values.ProductType_Name,
    } as SecurityTemplate_Ext;

    if (securityTemplateInfo && securityTemplateInfo._options) {
      delete securityTemplateInfo._options;
    }
    setIsLoading(true);
    saveSecurityTemplate(securityTemplateInfo, actionSubmit);
    setIsLoading(false);
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, securityTemplate]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleSubmit}
        initialValues={securityTemplate}
        key={JSON.stringify(securityTemplate)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <SecurityTmpFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
