import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSApplicationsClassicReports: IColumnFields[] = [
  {
    field: CRSFieldMapping.Name,
    title: "Name",

    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  { field: CRSFieldMapping.Description, title: "Description" },
];
