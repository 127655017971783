import { PROPERTY_METERS_LIST_ROUTE } from "@app/products/property/meters/list/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colMeterReadingDevices: IColumnFields[] = [
  {
    field: "DeviceName",
    title: "Device Name",
    linkTo: (dataItem) => {
      return {
        pathname: `${PROPERTY_METERS_LIST_ROUTE}`,
        state: {
          filter: {
            field: "ReadingDeviceName",
            value: dataItem["DeviceName"],
          },
        },
      };
    },
  },
  { field: "DeviceHostName", title: "Device Host Name" },
  { field: "AssignedReader", title: "Assigned Reader" },
  {
    field: "ActiveMeters",
    title: "Active Meters",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "StandardReadings",
    title: "Standard Readings",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "SpecialReadings",
    title: "Special Readings",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "ReadingsWaitingVerification",
    title: "Reading Waiting Verification",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "ReadingsVoided",
    title: "Readings Voided",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "StandardReadingRequired",
    title: "Standard Reading Required",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "SpecialReadingRequired",
    title: "Special Reading Required",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "ReadingDeviceId",
    title: "Reading Device ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
