import { SupplementaryMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { eComponent } from "@app/products/property/components/associations/model";

export const checkIsNewSupp = (supplementaryMode: SupplementaryMode) => {
  return supplementaryMode === SupplementaryMode.CreateNew;
};

export const checkIsMyExistingSupp = (supplementaryMode: SupplementaryMode) => {
  return supplementaryMode === SupplementaryMode.SelectFromMyList;
};

export const isShowAssessmentGrid = (
  componentNumber: eComponent.Master_Property | eComponent.Assessment
) => {
  //Task 14684: show the selected assessment in a grid on the first page
  return componentNumber === eComponent.Assessment;
};
