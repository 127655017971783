import { AnimalType } from "@app/products/animals/model";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCPercentInput } from "@components/cc-percent-input/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfAnimalType = nameOfFactory<AnimalType>();
const nameOfKeyValuePacket = nameOfFactory<KeyValuePacket>();

interface IFeePartProps {
  feeCalculationLabel: string;
  formRenderProps: FormRenderProps;
}

export const FeePart = observer(
  ({ feeCalculationLabel, formRenderProps }: IFeePartProps) => {
    const { animalTypeLOVs } = useAnimalTypeStore();
    const { valueGetter, onChange } = formRenderProps;

    const handleOnChangeFeeType = (event: ComboBoxChangeEvent) => {
      onChange(nameOfAnimalType("FeeType_ID"), {
        value: event?.target?.value?.Key,
      });
    };

    return (
      <>
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Fee type" isMandatory />
              <Field
                name={nameOfAnimalType("FeeType_ID")}
                component={CCComboBox}
                textField={nameOfKeyValuePacket("Value")}
                dataItemKey={nameOfKeyValuePacket("Key")}
                data={animalTypeLOVs?.FeeType}
                validator={requiredValidator}
                onChange={handleOnChangeFeeType}
                value={getDropdownValue(
                  valueGetter(nameOfAnimalType("FeeType_ID")),
                  animalTypeLOVs?.FeeType ?? [],
                  nameOfKeyValuePacket("Key")
                )}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Standard amount" isMandatory />
              <Field
                name={nameOfAnimalType("Fee_StandardAmount")}
                component={CCCurrencyInput}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Govt amount" isMandatory />
              <Field
                name={nameOfAnimalType("Fee_GvtAmount")}
                component={CCCurrencyInput}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Pensioner discount" isMandatory />
              <Field
                name={nameOfAnimalType("Fee_PensionerDiscount")}
                component={CCPercentInput}
                validator={requiredValidator}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCLabel title={feeCalculationLabel} />
          </div>
          <div className="cc-field-group cc-custom-sub-panel-bar">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Sterilisation" isMandatory />
                <Field
                  name={nameOfAnimalType("Fee_SterilisedDiscount")}
                  component={CCCurrencyInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Over 10 years old" isMandatory />
                <Field
                  name={nameOfAnimalType("Fee_AgeOverTenDiscount")}
                  component={CCCurrencyInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Under 1 year old" isMandatory />
                <Field
                  name={nameOfAnimalType("Fee_AgeUnderOneDiscount")}
                  component={CCCurrencyInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Club member" isMandatory />
                <Field
                  name={nameOfAnimalType("Fee_ClubMemberDiscount")}
                  component={CCCurrencyInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Microchip" isMandatory />
                <Field
                  name={nameOfAnimalType("Fee_MicrochipDiscount")}
                  component={CCCurrencyInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Obedience school" isMandatory />
                <Field
                  name={nameOfAnimalType("Fee_ObedienceSchoolDiscount")}
                  component={CCCurrencyInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Guide dog/assistance" isMandatory />
                <Field
                  name={nameOfAnimalType("Fee_GuideDogDiscount")}
                  component={CCCurrencyInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Customs" isMandatory />
                <Field
                  name={nameOfAnimalType("Fee_CustomsDiscount")}
                  component={CCCurrencyInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Working" isMandatory />
                <Field
                  name={nameOfAnimalType("Fee_FarmDiscount")}
                  component={CCCurrencyInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Organisation" isMandatory />
                <Field
                  name={nameOfAnimalType("Fee_OrganisationDiscount")}
                  component={CCCurrencyInput}
                  validator={requiredValidator}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);
