import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { TypeSubmitActions } from "@common/constants/enumerations";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { LookupHelpDocumentForm } from "@common/pages/settings/lookups/help-documents/_id/components/child-screens/general/_index";
import { useLookupHelpDocument } from "@common/pages/settings/lookups/help-documents/_id/store";
import { isVisibleHelpDocument } from "@common/pages/settings/lookups/help-documents/util";
import { usePermissionStore } from "@common/pages/settings/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCNotFoundPage } from "@components/cc-not-found-page/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const NewLookupHelpDocument = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } = usePermissionStore()
  const { isLoadingPermission, checkPermissions } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_HelpDocument,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });
  const { isLoading, responseLoadError, loadHelpDocument, onSubmit } =
    useLookupHelpDocument();
  const [permissionLoadingFunc, setPermissionLoadingFunc] = useState<boolean>(false);

  const handleCheckPermission = async () => {
    setPermissionLoadingFunc(true)
    await checkPermissionForCurrentMenu(FormActionCheckPermission.GLOBAL_SETTINGS, PRODUCT_TYPE_NUMBER.Core)
    setPermissionLoadingFunc(false)
  }

  useEffectOnce(() => {
    handleCheckPermission()
    return () => {
      resetStore()
    }
  })

  if (!isVisibleHelpDocument()) {
    return <CCNotFoundPage title='No permission' subTitle="" />;
  }

  return (
    <LoadingPermissionWrapper
      isLoadingPermission={isLoadingPermission || permissionLoadingFunc}
    >
      <>
        <Loading isLoading={isLoading} isFullScreen />
        <FormNavigation title='Help Document' />
        {responseLoadError ? (
          <CCLoadFailed
            responseError={responseLoadError}
            onReload={() => loadHelpDocument(parseInt(id), true)}
          />
        ) : (
          <>
            <FormTitle title='New' />
            <CCManagePageActionBar
              leftActions={[
                <CCNavButton
                  title='Save'
                  name={TypeSubmitActions.New}
                  onClick={onSubmit}
                  disabled={
                    !hasPermission || !checkPermissions(FormAction.CORE_ALLOW_SAVE)
                  }
                />,
              ]}
            />
            <div className="cc-manage-container">
              <div className="cc-form cc-manage-left">
                <CCAppNotification />
                <div className="cc-manage-form-body">
                  <LookupHelpDocumentForm />
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </LoadingPermissionWrapper>
  );
});
