import { VO_Assessment } from "@app/products/property/assessments/list/model";
import { colAssessmentPicker } from "@app/products/property/deferred-duty/components/action-bar/dialogs/create-deferred-duty/components/assessment-picker/config";
import { getUrlSearchInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/config";
import { useDebounce } from "@common/hooks/useDebounce";
import { nameOfFactory } from "@common/utils/common";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { ICCInputPickerProps } from "@components/cc-input-picker/_index";
import { isHTML } from "@components/cc-input-picker/util";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { isArray, isNull, isUndefined } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./_index.scss";

export interface IAssessmentPickerProps extends ICCInputPickerProps {
  disabledTyping?: boolean;
}

export const AssessmentPicker = (props: IAssessmentPickerProps) => {
  const nameOf = nameOfFactory<VO_Assessment>();
  const {
    validationMessage,
    visited,
    className,
    dialog = {
      maxWidth: "55%",
      height: "auto",
      titleHeader: "Assessment Picker",
    },
    grid = {
      columnFields: [],
      primaryField: "ID",
      selectableMode: "single",
    },
    value,
    textField,
    textProduce,
    valueField,
    valueProduce,
    onInputChange,
    onChange,
    disabled,
    customDialog,
    onButtonClick,
    isLoading,
    onLoadData,
    showClearButton,
    disabledTyping = false,
    ...others
  } = props;

  const urlOData =
    "/odata/property/internal/assessmentdetailsregister?$count=true&";
  const [urlODataSearch, setUrlODataSearch] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [gridSelectedRows, setGridSelectedRows] = useState<VO_Assessment[]>([]);
  const [showDialog, setShowDialog] = useState(false);
  const debouncedSearch = useDebounce(searchKey, 500);

  const inputValue = useMemo(() => {
    if (isUndefined(value) || isNull(value)) return "";
    if (textProduce) return textProduce(value);
    if (textField && !isArray(value) && textField in value)
      return value[textField];
    return value ?? "";
  }, [value, textField, textProduce]);

  const handleOnChange = useCallback(
    (value: any) => {
      setShowDialog(false);
      if (!onChange) return;
      if (valueProduce) return onChange({ value: valueProduce(value) });

      if (valueField && !isArray(value) && valueField in value)
        return onChange({ value: value[valueField] });

      return onChange({ value });
    },
    [onChange, valueField, valueProduce]
  );

  const handleOnClickButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (onButtonClick) return onButtonClick(event);
    setSearchKey("");
    setShowDialog(true);
    setGridSelectedRows(props.grid?.selectedRows ?? []);
    if (onLoadData) return onLoadData();
  };

  useEffect(() => {
    if (!disabledTyping) return;
    let element = document.getElementsByClassName("assessment-picker-combobox");
    for (let i = 0; i < element.length; i++) {
      element[i].addEventListener("keydown", (e) => {
        e.stopPropagation();
        e.preventDefault();
      });
    }
  }, [disabledTyping]);

  useEffect(() => {
    (async () => {
      if (debouncedSearch) {
        setUrlODataSearch(
          getUrlSearchInputPicker(
            searchKey,
            nameOf("Assessment_Number_Formatted"),
            urlOData
          )
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <>
      <div
        className={`${
          className ?? ""
        } cc-input-picker-new cc-custom-assessment-picker-new ${
          !others.valid ? "cc-input-picker-invalid" : ""
        }`}
      >
        {isHTML(inputValue) ? (
          <div className="cc-input-picker-html k-textbox">
            {sanitizeHtml(inputValue)}
          </div>
        ) : (
          <ComboBox
            className="assessment-picker-combobox"
            disabled={disabled}
            opened={false}
            required={!others.valid}
            value={inputValue}
            onChange={(e: ComboBoxChangeEvent) => {
              e.target.value
                ? handleOnChange([e.target.value])
                : handleOnChange(null);
            }}
            popupSettings={{ className: "cc-assessment-picker-search" }}
          />
        )}
        <Button
          disabled={disabled}
          className="cc-input-picker-button"
          iconClass="fa fa-ellipsis-h"
          onClick={handleOnClickButton}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
      {showDialog &&
        (customDialog ? (
          customDialog(value, () => setShowDialog(false), handleOnChange)
        ) : (
          <div className="cc-grid-selection-dialog">
            <CCDialog
              {...dialog}
              height={670}
              maxHeight="80%"
              onClose={() => setShowDialog(false)}
              bodyElement={
                <div className="cc-grid-search">
                  <div className="cc-grid-search-input">
                    <label className="cc-label">Search</label>
                    <Field
                      name={"searchKey"}
                      component={CCInput}
                      placeholder="Search"
                      onChange={(e: InputChangeEvent) => {
                        setSearchKey(e.value);
                      }}
                    />
                  </div>
                  <div className="cc-grid-search-list">
                    <CCGrid
                      columnFields={colAssessmentPicker}
                      dataUrl={searchKey ? urlODataSearch : urlOData}
                      primaryField={nameOf("Assessment_Id")}
                      selectableMode={grid.selectableMode}
                      selectedRows={gridSelectedRows}
                      onSelectionChange={(dataItems) => {
                        setGridSelectedRows(dataItems);
                      }}
                      state={{
                        sort: [
                          { field: nameOf("Assessment_Number"), dir: "asc" },
                        ],
                      }}
                      itemPerPage={10}
                      isAutoHiddenPager={false}
                    />
                  </div>
                  <br />
                </div>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    className="cc-dialog-button"
                    onClick={() => setShowDialog(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={gridSelectedRows.length === 0}
                    onClick={() => {
                      handleOnChange(
                        grid.selectableMode === "multiple"
                          ? gridSelectedRows
                          : gridSelectedRows[0]
                      );
                    }}
                  >
                    Select
                  </Button>
                </div>
              }
            />
          </div>
        ))}
    </>
  );
};
