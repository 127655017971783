export enum EKeysOfStepsBulkLevyUpdate {
  LevyDetails = "LevyDetails",
  Supplementary = "Supplementary",
  NewSupplementary = "CreateSupplementary",
  Documents = "Documents",
  Comments = "Comments",
}

export const keysOfStepsBulkLevyUpdate = [
  EKeysOfStepsBulkLevyUpdate.LevyDetails,
  EKeysOfStepsBulkLevyUpdate.NewSupplementary,
  EKeysOfStepsBulkLevyUpdate.Supplementary,
];
