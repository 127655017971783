import { IRemoveMeterData } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/remove-meter/model";

export const mockRemoveMeter: IRemoveMeterData = {
  MeterId: 60,
  MeterNumber: "03A236866",
  PropertyAddress: ", Proserpine",
  AccountNames: null,
  DateOfRemoval: new Date(),
  Reading: 2480,
  StatusOfExistingMeter: { Key: 1, Value: "Being Tested" },
  StatusOfExistingMeterList: [
    { Key: 1, Value: "Being Tested" },
    { Key: 2, Value: "Faulty" },
    { Key: 3, Value: "Not in Service" },
    { Key: 4, Value: "Retired" },
  ],
  Reason: null,
  RemovedBy: null,
};
