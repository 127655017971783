import { SecurityOrgStructureForm } from "@common/pages/settings/security/org-structure/_id/components/child-screens/general/_index";
import { SecurityOrgStructureSubmitActions } from "@common/pages/settings/security/org-structure/_id/model";
import { useOrgStructureStore } from "@common/pages/settings/security/org-structure/_id/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const NewSecurityOrgStructure = observer(() => {
  const { id } = useParams<{ id: string }>();

  const { orgUnit, isLoading, responseLoadError, loadOrgUnit, onSubmit } =
    useOrgStructureStore();

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Org Structure"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadOrgUnit(parseInt(id), true)}
        />
      ) : (
        <>
          <FormTitle title="New Org Structure" />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Create top level org unit"}
                name={SecurityOrgStructureSubmitActions.CreateTopLevel}
                onClick={onSubmit}
              />,
              <CCNavButton
                title={"Create ad hoc group"}
                name={SecurityOrgStructureSubmitActions.CreateAdhocGroup}
                onClick={onSubmit}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {orgUnit && <SecurityOrgStructureForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
