import { eventEmitter } from "@/App";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { postHMClosePremises } from "@app/products/hm/premises/[id]/components/buttons/close-premises/api";
import { HMPremisesCloseDialog } from "@app/products/hm/premises/[id]/components/dialogs/close-premises/_index";
import {
  PremisesClosure,
  PremisesClosureWorkflowRequest,
} from "@app/products/hm/premises/[id]/components/dialogs/close-premises/model";
import {
  HM_PREMISES_CLOSE_DIALOG_PLACE_ID,
  HM_PREMISES_GET_SLIDER,
  PREMISES_MANAGE_ROUTE,
} from "@app/products/hm/premises/[id]/constant";
import {
  Premises,
  Premises_Status,
  PremisesRegisterLovs,
  Svc_Premises,
} from "@app/products/hm/premises/[id]/model";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

interface IHMClosePremisesButtonProps {
  isDisabled?: boolean;
}

export const HMClosePremisesButton = observer(
  ({ isDisabled = false }: IHMClosePremisesButtonProps) => {
    const { settings } = useCommonCoreStore();
    const { dataForms, setMiddlewareSubmitFormOptions, submitFormGetData } =
      useFlexibleFormStore();
    const isNew = useIsNew();
    const history = useHistory();
    const { cancelToken } = useCancelRequest();
    const { pushNotificationPortal } = useNotificationPortalStore();
    const [dataDialog, setDataDialog] = useState<PremisesClosure | undefined>();

    const premisesObj = dataForms?.GeneralForm as Svc_Premises;
    const premisesData = premisesObj?.Premises as Premises;
    const premisesLOVs = dataForms?.GeneralFormLovs as PremisesRegisterLovs;

    const notifyEmail =
      getStringValueSetting(
        settings[ECorporateSettingsField.HealthManager_EmailNotifyClosure]
      ) ?? "";

    const objPremisesClosure = () => {
      const retVal = new PremisesClosure();
      retVal.ShowNotification = !isEmpty(notifyEmail);
      retVal.SendNotification = !isEmpty(notifyEmail);
      retVal.Date_Closed = new Date();
      retVal.NotifyText = notifyEmail
        ? "Notify [" + notifyEmail + "] of this closure?"
        : "";
      return retVal;
    };

    const hmClosePremisesSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const premisesCloseValue: PremisesClosureWorkflowRequest =
              initialData;
            return postHMClosePremises(premisesCloseValue, cancelToken());
          },
          handleSuccess: async ({ dataFromApi }) => {
            if (isNew) {
              if (dataFromApi?.data?.ID) {
                const urlRedirect =
                  PREMISES_MANAGE_ROUTE + "/" + dataFromApi.data.ID;

                pushNotificationPortal({
                  title: "Premises closed successfully.",
                  type: "success",
                  route: urlRedirect,
                  callBack() {
                    history.replace(urlRedirect);
                  },
                });
              }
            } else {
              setDataDialog(undefined);
              await fetchApiByAlias(HM_PREMISES_GET_SLIDER);
              eventEmitter.emit(TabTableEventType.RefreshData);
              pushNotificationPortal({
                title: "Premises closed successfully.",
                type: "success",
              });
            }
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "Close premises failed.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
              placeId: HM_PREMISES_CLOSE_DIALOG_PLACE_ID,
            });
          },
        },
      ],
    });

    const handleClickButton = async () => {
      setDataDialog(undefined);

      //Validate form before Open Dialog
      setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      const validateDataFormSubmit = await submitFormGetData("GeneralForm");
      if (!isNil(validateDataFormSubmit)) {
        let newObject = objPremisesClosure();
        setDataDialog(newObject);
      }
    };

    const handleSubmitButton = async (data: PremisesClosure) => {
      setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      const dataFormSubmit = await submitFormGetData("GeneralForm");
      const premises = dataFormSubmit?.Premises as Premises;
      if (isNil(premises)) return;

      let dataRequest: PremisesClosureWorkflowRequest = {
        Premises: premises,
        PremisesClosure: data,
      };
      await hmClosePremisesSlider.fetchApi({
        initialData: dataRequest,
      });
    };

    const isVisible = useMemo(() => {
      //Default True &
      // Status_ENUM == ENUMS_HM.Premises_Status.Prelodgement |
      // Status_ENUM == ENUMS_HM.Premises_Status.Registered
      if (premisesData?.Status_ENUM === Premises_Status.Closed) return false;
      return true;
    }, [premisesData?.Status_ENUM]);

    return isVisible ? (
      <>
        <CCNavButton
          title="Close premises"
          onClick={handleClickButton}
          disabled={isDisabled || hmClosePremisesSlider.isFetching}
          isLoading={hmClosePremisesSlider.isFetching}
        />
        {!isNil(dataDialog) && (
          <HMPremisesCloseDialog
            isLoading={hmClosePremisesSlider.isFetching}
            onClose={() => {
              setDataDialog(undefined);
              cancelToken();
            }}
            onSubmit={handleSubmitButton}
            closureTypeLovs={premisesLOVs?.ClosureType ?? []}
            initData={dataDialog}
          />
        )}
      </>
    ) : null;
  }
);
