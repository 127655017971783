import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colAssessmentRebateSummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/components/rebate-summary/config";
import { VO_Charge_Run_Assessment_Rebate_Summary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/model";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const nameOf = nameOfFactory<VO_Charge_Run_Assessment_Rebate_Summary>();

export const RebateSummary = (data: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [colsChargeRunRebateConfig, setColsChargeRunRebateConfig] = useState<
    IColumnFields[]
  >([]);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const loadChargeRunRebateViewCongig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Charge_Run_Assessment_RebateSummary,
      colAssessmentRebateSummary
    );
    if (Array.isArray(response)) {
      setColsChargeRunRebateConfig(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadChargeRunRebateViewCongig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadChargeRunRebateViewCongig();
        }}
      />
    );

  return (
    <div className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Rebate summary</label>
          <CCGrid
            className="cc-charge-run-rebates-summary-gird"
            dataUrl={`odata/property/internal/chargerunassessmentrebateregister/GetRebateSummary(chargeRunId=${data?.dataItem?.Charge_Run_Id},assessmentId=${data?.dataItem?.Assessment_Id},faid=${data?.dataItem?.Financial_Area_Id},levyId=${data?.dataItem?.Levy_Id})?$count=true&`}
            columnFields={colsChargeRunRebateConfig}
            primaryField={nameOf("Rebate_Type_Id")}
          />
        </div>
      </div>
    </div>
  );
};
