import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import "@app/products/animals/components/forms/components/form-element/_index.scss";
import { AnimalKennelOwnerSection } from "@app/products/animals/kennels/[id]/component/child-screens/general/form-element/components/animal-owner-section/_index";
import { RelatedKennelAnimals } from "@app/products/animals/kennels/[id]/component/child-screens/general/form-element/components/related-animals/_index";
import { KennelWorkFlowElements } from "@app/products/animals/kennels/[id]/component/child-screens/general/form-element/components/workflow-elements/_index";
import {
  AnimalsKennel,
  Svc_AnimalKennel,
} from "@app/products/animals/kennels/[id]/model";
import { useAnimalsKennelStore } from "@app/products/animals/kennels/[id]/store";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { capitalizeFirstLetter, formatOSFees } from "@common/utils/formatting";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export interface IAnimalFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<AnimalsKennel>();
const nameOfMapObj = nameOfFactory<Svc_AnimalKennel>();
const getNameOf = (name: keyof AnimalsKennel) =>
  `${nameOfMapObj("Kennel")}.${nameOf(name)}`;
export const KennelAnimalFormElement = observer(
  ({ formRenderProps }: IAnimalFormElementProps) => {
    const isNew = useIsNew();
    const { settings } = useCommonCoreStore();
    const {
      initData,
      animalKennelId,
      osFee,
      totalRelatedAnimalRecords,
      isSuperAdmin,
      isDeleted,
    } = useAnimalsKennelStore();
    const headerOfDebtorNumber = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_DebtorNumberLabel]
    );

    const listPanelBar: ITabProps[] = !isNew
      ? [
          {
            title: "Related animals",
            badge: totalRelatedAnimalRecords.toString(),
            component: (
              <RelatedKennelAnimals
                id={animalKennelId}
                pidNO={initData?.Address?.PropertyAssessment?.PID_No}
              />
            ),
          },
        ]
      : [];
    const debtorLabel: string =
      capitalizeFirstLetter(headerOfDebtorNumber) ?? "Display debtor number";
    return (
      <FormElement className="cc-animal-kennel-form-element">
        <KennelWorkFlowElements formRenderProps={formRenderProps} />
        <AnimalKennelOwnerSection formRenderProps={formRenderProps} isOwner />
        <hr className="cc-divider" />
        <AnimalKennelOwnerSection formRenderProps={formRenderProps} />
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Description" />
              <Field
                component={CCTextArea}
                name={getNameOf("Description")}
                rows={3}
                disabled={isDeleted}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title={debtorLabel} />
              <Field
                name={getNameOf("Sys_DebtorNumber")}
                component={CCInput}
                placeholder={debtorLabel}
                disabled={!isSuperAdmin || isDeleted}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="File number" />
              <Field
                name={getNameOf("Sys_FileNumber")}
                component={CCInput}
                disabled={isDeleted}
              />
            </div>
            <CCValueField
              label="OS fees"
              value={osFee}
              style={formatOSFees(osFee)}
              format={CURRENCY_FORMAT.CURRENCY1}
            />
          </div>
        </section>
        <hr className="cc-divider" />
        <div className="cc-custom-sub-panel-bar">
          <TabTable
            recordType={RECORDTYPE.Animals_Kennel}
            initialPanels={listPanelBar}
          />
        </div>
      </FormElement>
    );
  }
);
