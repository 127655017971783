import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import {
  DTO_Workflow_MasterPropertyAdjustment,
  RequestAdjustmentMasterPropertyObj,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/master-property-adjustments/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const getMasterPropertyAdjustmentWorkflow = async (
  requestObj: RequestAdjustmentMasterPropertyObj | object = {},
  workflowDraftId: number | undefined
): Promise<APIResponse<DTO_Workflow_MasterPropertyAdjustment>> => {
  try {
    if (isNil(workflowDraftId)) {
      return await CoreAPIService.getClient().post(
        `api/property/internal/workflow/masterpropertyadjustment/new`,
        requestObj
      );
    } else {
      return await CoreAPIService.getClient().get(
        `api/property/internal/workflow/masterpropertyadjustment/${workflowDraftId}`
      );
    }
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessMasterPropertyAdjustmentWorkflow = async (
  mode: WorkflowProcessMode,
  data: DTO_Workflow_MasterPropertyAdjustment
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_MasterPropertyAdjustment>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/process/masterpropertyadjustment/${mode}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
