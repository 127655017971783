import { KeyValuePair } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/documents/model";
import { DTOBaseClass_Standard } from "@app/products/crms/[id]/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { IIdentityPacket } from "@common/models/identityPacket";

export interface Svc_FeeTypeView {
  ID: number;
  Name: string;
  Description: string;
  AccountCode: string;
  CategoryCode: string;
  Flag_CalculatedFee: boolean;
  Flag_GSTApplies: boolean;
  Council_ID?: number;
  DefaultAmount?: number;
  Council: string;
  SystemFilter_ENUMS: string;
  Fee: boolean;
  Refund: boolean;
  Bond: boolean;
  Subsidy: boolean;
  ModulesUsed: string;
  ProductID: string;
}

export interface Svc_FeeTypeLOVs {
  SystemFilters: KeyValuePacket[];
  BondType: KeyValuePair<number, string>[];
  DiscountType: KeyValuePacket[];
  SubsidyTerm: KeyValuePacket[];
}

// #region Fee Types Form
export type TApiFeeType = {
  Lovs: IIdentityPacket<Svc_FeeTypeLOVs>;
  Value: IIdentityPacket<FeeType>;
};
// #endregion

export interface FeeType extends DTOBaseClass_Standard {
  FeeType_ID: number;
  Name: string;
  Description: string;
  Code: string;
  Category: string;
  GSTCode: string;
  OrganisationCode: string;
  Flag_GSTApplies: boolean;
  Fee?: boolean;
  Refund?: boolean;
  Flag_CalculatedFee: boolean;
  Flag_AllowZeroAmount: boolean;
  DefaultAmount_ExGST?: number;
  DefaultAmount_GSTPercentage: number;
  DefaultAmount?: number;
  Council_ID?: number;
  NextEffectiveDate?: Date;
  NextAmount_ExGST?: number;
  NextAmount_GSTPercentage: number;
  NextAmount?: number;
  SystemFilter_ENUMS: string;
  SystemFilter_Names: string;
  BondType: string;
  Bond?: boolean;
  BondType_Id?: number;
  Product_Id: string;
  Subsidy?: boolean;
  Term_ENUM?: SubsidyTerm;
  Term_Name: string;
  StartDate?: Date;
  EndDate?: Date;
  DiscountType_ENUM?: DiscountType;
  DiscountType_Name: string;
}

export interface Svc_PickBondType {
  Code: string;
  Description: string;
  InspectionRequired: boolean;
}

//ENUMS
export enum DiscountType {
  SystemInitialise = 0,
  Percentage = 1,
  Amount = 2,
}

export enum SubsidyTerm {
  SystemInitialise = 0,
  Long = 1,
  Short = 2,
}
