import { RosterType } from "@common/constants/enumerations";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";

export interface Roster extends DTOBaseClass_Standard {
  Roster_ID: number;
  RosterName: string;
  RosterType_ENUM: RosterType;
  RosterType_Name: string;
  RosterKeys: RosterKey[];
  RosterSiteUsers: RosterSiteUser[];
  SaveTriggers: RosterUpdateTriggers[];
  _SaveTriggers: RosterUpdateTriggers[];
}

export interface RosterKey extends DTOBaseClass_Standard {
  RosterKey_ID: number;
  Roster_ID: number;
  Key: string;
  Key_DisplayName: string;
}

export interface RosterSiteUser extends DTOBaseClass_Standard {
  RosterSiteUser_ID: number;
  Roster_ID: number;
  SiteUser_ID: number;
  SiteUser_DisplayName: string;
  LastAllocated: Date | null;
}

export enum RosterUpdateTriggers {
  UpdateSiteUsers,
  UpdateKeys,
}

export interface RosterHandlerRequest {
  RosterFormAction: Svc_FormAction_Roster;
  Roster: Roster;
  EventArgs: any;
  IsFirstTimeLoad: boolean;
}

export enum Svc_FormAction_Roster {
  SystemInitialise,
  PickUsers,
  PickKeys,
}

export interface RosterHandlerResponse {
  Roster: Roster;
}
