import { DTO_MP_Assessment } from "@app/products/property/assessments/master-properties/components/form-steps/add-new-master-property/model";
import { eAssessmentAllocation } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/model";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { RadioButtonProps } from "@progress/kendo-react-inputs";
export const comboBoxSearchData = {
  _option: {
    Assessment: {
      Data: [],
      Loading: false,
    },
  },
};

const nameOf = nameOfFactory<DTO_MP_Assessment>();
export const colJournalAssessment: IColumnFields[] = [
  {
    field: nameOf("Assess_Number"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Assess_Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Ratepayer_Name"),
    title: "Ratepayer",
  },
  {
    field: nameOf("Unit_Type"),
    title: "Unit Type",
  },
  {
    field: nameOf("Unit_Entitlement"),
    title: "Unit Entitlement",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Unit_Entitlement_Percentage"),
    title: "Unit Entitlement Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("Unit_Type_Percentage"),
    title: "Unit Type Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("Valuation_1"),
    title: "Valuation 1",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_2"),
    title: "Valuation 2",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_3"),
    title: "Valuation 3",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_4"),
    title: "Valuation 4",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const allocationRadioGroup: Array<RadioButtonProps> = [
  {
    label: "To a new charge",
    value: eAssessmentAllocation.NEW_CHARGES,
    disabled: false,
  },
  {
    label: "Manually allocate to charge balances",
    value: eAssessmentAllocation.CHARGES_BALANCES,
    disabled: false,
  },
  {
    label: "Allocate credit use allocation sequence",
    value: eAssessmentAllocation.SEQUENCE,
    disabled: false,
  },
];
