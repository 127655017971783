import { mockSingleBuildingRegistersPOPE } from "@app/products/building/registers/pope/[id]/mock";
import { IBuildingRegistersPOPEFormData } from "@app/products/building/registers/pope/[id]/model";
import { IResponseData } from "@common/models/service";

export const loadBuildingRegistersPOPEById = async (
  id?: string
): Promise<IBuildingRegistersPOPEFormData> => {
  const response: IResponseData = JSON.parse(
    JSON.stringify(mockSingleBuildingRegistersPOPE)
  );
  return response.data;
};
