import {
  DTO_Account_Detail,
  DTO_Interest,
  DTO_TRS_Information,
} from "@app/products/property/deferred-duty/components/action-bar/dialogs/create-deferred-duty/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";

export enum EKeysOfStepsModifyDeferredDuty {
  Details = "Detail",
  OtherInfo = "OtherInfo",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfSendStepsModifyDeferredDuty = [
  EKeysOfStepsModifyDeferredDuty.Details,
  EKeysOfStepsModifyDeferredDuty.OtherInfo,
];

export interface DTO_Workflow_ModifyDeferredDuty {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_ModifyDeferredDuty;
}

export interface DTO_WorkflowDetail_ModifyDeferredDuty {
  AccountDetails: DTO_Account_Detail;
  Interest: DTO_Interest;
  TRSInformation: DTO_TRS_Information;
}
