import { getDisplayTextWithDashes } from "@common/utils/common";
import { getLabel } from "@app/products/property/components/label/config";

export const PropertyAssessmentBalancesBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.Assessment_Id
      ? `- ${selectedRow.Assessment_Id}`
      : "";
    return `Property - ${getLabel.assessments()} ${dynamicDisplayName}`;
  },
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.Assessment_Number_Formatted,
      selectedRow.Property_Name_Address_Locality,
    ]);
  },
  getBookmarkListViewDisplayName() {
    return `Property - ${getLabel.assessments()}`;
  },
  getBookmarkListViewDetail() {
    return `Property - ${getLabel.assessments()} - Balances`;
  },
};
