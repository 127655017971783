import { putSendForTLApproval } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-for-tl-approval/api";
import { PPRStoreInstance } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class SendForTLApprovalButtonStore {
  private _isLoading: boolean = false;
  private _isShowCommentDialog: boolean = false;
  private _selectedDocumentIDs: number[] = [];
  private _notifications: IAppNotificationItem[] = [];
  constructor() {
    makeAutoObservable(this);
  }
  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  get notifications() {
    return toJS(this._notifications);
  }
  setNotifications = (notifications: IAppNotificationItem[]) => {
    runInAction(() => {
      this._notifications = notifications;
    });
  };

  get isShowCommentDialog() {
    return toJS(this._isShowCommentDialog);
  }
  setIsShowCommentDialog = (isShowGetDocumentDialog: boolean) => {
    runInAction(() => {
      this._isShowCommentDialog = isShowGetDocumentDialog;
    });
  };

  get selectedDocumentIDs() {
    return toJS(this._selectedDocumentIDs);
  }
  setSelectedDocumentIDs = (selectedDocumentIDs: number[]) => {
    runInAction(() => {
      this._selectedDocumentIDs = selectedDocumentIDs;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._isShowCommentDialog = false;
      this._selectedDocumentIDs = [];
      this._notifications = [];
    });
  };

  handleSendForTLApproval = async (
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>,
    id?: number,
    attachmentIDs?: number[],
    comment?: string
  ) => {
    if (!id || !attachmentIDs) return;
    this.setIsLoading(true);
    const response = await putSendForTLApproval(id, attachmentIDs, comment);
    if (isSuccessIdentityPacket(response)) {
      this.setIsShowCommentDialog(false);
      this.setNotifications([]);
      appNotificationStore.clearNotifications();
      PPRStoreInstance.setPPRWithLoading(response.data.Application);
      appNotificationStore.pushNotification({
        type: "success",
        title: "Send to delegated approver successfully",
      });
      if (
        response.data?.Notifications &&
        response.data?.Notifications.length > 0
      ) {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Send to delegated approver",
          type: "warning",
          description: response.data?.Notifications,
        });
      }
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Send to delegated approver failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsLoading(false);
  };
}
export const sendForTLApprovalButtonStore = new SendForTLApprovalButtonStore();
const sendForTLApprovalButtonContext = createContext(
  sendForTLApprovalButtonStore
);
export const useSendForTLApprovalButtonStore = () => {
  return useContext(sendForTLApprovalButtonContext);
};
