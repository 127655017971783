import { CORE_OFFICERS_ODATA } from "@app/core/components/common/constant";

export const BuildingFieldMapping = {
  ApplicationNumber: "ApplicationNumber",
  PermitNumber: "PermitNumber",
  PermitType: "PermitType",
  Date_PermitIssued: "Date_PermitIssued",
  RefNo: "RefNo",
  AssessmentNo: "AssessmentNo",
  Flag_PrivateCertifier: "Flag_PrivateCertifier",
  IsPrivateCertifier: "IsPrivateCertifier",
  Applicant: "Applicant",
  Builder: "Builder",
  Category: "Category",
  BCA: "BCA",
  UseOfBuilding: "UseOfBuilding",
  ReasonForPermit: "ReasonForPermit",
  SiteAddress: "SiteAddress",
  Locality: "Locality",
  Owner: "Owner",
  Status: "Status",
  ApplicantRequires: "ApplicantRequires",
  Date_Lodged: "Date_Lodged",
  Date_AllInspComplete: "Date_AllInspComplete",
  Date_DueToCommence: "Date_DueToCommence",
  Date_Commenced: "Date_Commenced",
  Date_DueToComplete: "Date_DueToComplete",
  Date_Completed: "Date_Completed",
  Date_CertificateIssued: "Date_CertificateIssued",
  FileNo: "FileNo",
  ValuationNo: "ValuationNo",
  LegalDescription: "LegalDescription",
  BuildingOfficer: "BuildingOfficer",
  Surveyor: "Surveyor",
  SurveyorNumber: "SurveyorNumber",
  StatDays: "StatDays",
  Date_Issued: "Date_Issued",
  Date_ReminderSent: "Date_ReminderSent",
  PayingFee: "PayingFee",
  CarryingOutWork: "CarryingOutWork",
  WorkProposed: "WorkProposed",
  Address: "Address",
  BuildingAppNo: "BuildingAppNo",
  BuildingPermitNo: "BuildingPermitNo",
  Date_BuildingIssued: "Date_BuildingIssued",
  Date_Commencing: "Date_Commencing",
  BondAmount: "BondAmount",
  BondReceived: "BondReceived",
  BondReturned: "BondReturned",
  Date_FirstInspection: "Date_FirstInspection",
  Date_LastInspection: "Date_LastInspection",
  TargetDate: "TargetDate",
  CILFee: "CILFee",
  Flag_CILPaid: "Flag_CILPaid",
  LettersDisplay: "LettersDisplay",
  DateInspected: "DateInspected",
  InspectionType: "InspectionType",
  Officer: "Officer",
  Compliance: "Compliance",
  Reason: "Reason",
  FacilityRegion: "FacilityRegion",
  Description: "Description",
  DateTarget: "DateTarget",
  Source: "Source",
  Location: "Location",
  RequestedBy: "RequestedBy",
  ContactDetails: "ContactDetails",
  DueStatus: "DueStatus",
  DateRecorded: "DateRecorded",
  RecordedBy: "RecordedBy",
  OrgStructure: "OrgStructure",
  ActionOfficer: "ActionOfficer",
  Coordinator: "Coordinator",
  DateWork: "DateWork",
  FileNumber: "FileNumber",
  EnquiryRefNo: "EnquiryRefNo",
  Event_ReferenceNoAdditional: "Event_ReferenceNoAdditional",
  OnBehalfOf: "OnBehalfOf",
  Flag_ITSupport: "Flag_ITSupport",
  Flag_Safety: "Flag_Safety",
  Flag_Risk: "Flag_Risk",
  Flag_VIP: "Flag_VIP",
  DateServed: "DateServed",
  ApplicantName: "ApplicantName",
  Type: "Type",
  Risk: "Risk",
  DateDue: "DateDue",
  DateRevoked: "DateRevoked",
  Register_Number: "Register_Number",
  Contact: "Contact",
  Contact_Address: "Contact_Address",
  Contact_Contact: "Contact_Contact",
  PreferredContact: "PreferredContact",
  PreferredContact_Address: "PreferredContact_Address",
  PreferredContact_Contact: "PreferredContact_Contact",
  Zone: "Zone",
  Overlay: "Overlay",
  ReferenceNumber: "ReferenceNumber",
  Date_Complete: "Date_Complete",
  Date_Construction: "Date_Construction",
  BarrierStandard: "BarrierStandard",
  BarrierType: "BarrierType",
  Date_CertificateOfCompliance: "Date_CertificateOfCompliance",
  Pool_RegisteredDate: "Pool_RegisteredDate",
  Date_Lodgement_CertificateOfBarrierNC:
    "Date_Lodgement_CertificateOfBarrierNC",
  Date_WorkIssued: "Date_WorkIssued",
  Work_CarriedOut: "Work_CarriedOut",
  AmountOS: "AmountOS",
  Flag_RenewalNoticeSent: "Flag_RenewalNoticeSent",
  Outcome: "Outcome",
  Regulation: "Regulation",
  Date_Due: "Date_Due",
  Date_NextInspection: "Date_NextInspection",
  Date_Start: "Date_Start",
  Date_Occurred: "Date_Occurred",
  ContactName: "ContactName",
  CreatedDate: "CreatedDate",
  FeeType: "FeeType",
  AmountIssued: "AmountIssued",
  AmountOutstanding: "AmountOutstanding",
  CostCode: "CostCode",
  AmountPaid: "AmountPaid",
  ReceiptNumber: "ReceiptNumber",
  ReceiptDate: "ReceiptDate",
  ReceiptedBy: "ReceiptedBy",
  Method: "Method",
  InvoiceNumber: "InvoiceNumber",
  DebtorNumber: "DebtorNumber",
  SecurePay_Reference: "SecurePay_Reference",
  Contact2: "Contact2",
  LevelOfEnforcement: "LevelOfEnforcement",
  Number: "Number",
  DateOffence: "DateOffence",
  RegisterType: "RegisterType",
  VicSmart: "VicSmart",
  ePlanningCategory: "ePlanningCategory",
  ePlanningLodgedBy: "ePlanningLodgedBy",
  Comments: "Comments",
  ApplicationCategory_Name: "ApplicationCategory_Name",
  ApplicationCategory_Description: "ApplicationCategory_Description",
  Code: "Code",
  SortIndex: "SortIndex",
  IsPublishToWeb: "IsPublishToWeb",
  PublishName: "PublishName",
  PublishDescription: "PublishDescription",
  Title: "Title",
  ConditionCode: "ConditionCode",
  Condition: "Condition",
  Name: "Name",
  Multiplier: "Multiplier",
  Value: "Value",
  MinimumValue: "MinimumValue",
  TargetDays: "TargetDays",
  Category_1: "Category_1",
  Category_2: "Category_2",
  ObservationSummary: "ObservationSummary",
  ComplianceInstruction: "ComplianceInstruction",
  Details: "Details",
  AppNo: "AppNo",
  Lodged: "Lodged",
  Regulations: "Regulations",
  CouncilConsent: "CouncilConsent",
  OutstandingFees: "OutstandingFees",
  CostOfWorks: "CostOfWorks",
  EnquiryType: "EnquiryType",
  Due: "Due",
  Complete: "Complete",
  ExportedToFinance: "ExportedToFinance",
  BAMSRefNo: "BAMSRefNo",
  BAMS_LodgedDateTime: "BAMS_LodgedDateTime",
  Date_Sent: "Date_Sent",
  PermitNo: "PermitNo",
  PrescribeEvent_KWD_Name: "PrescribeEvent_KWD_Name",
  Surveyor_RegistrationNo: "Surveyor_RegistrationNo",
  Building_StageNumber: "Building_StageNumber",
  CertificateNo: "CertificateNo",
  PersonDisplayName: "PersonDisplayName",
  ProjectID_Name: "ProjectID_Name",
  BAMS_PrescribeEvent_LodgedDateTime: "BAMS_PrescribeEvent_LodgedDateTime",
  ExportToFinanceDate: "ExportToFinanceDate",
};

export const BuildingUrl = {
  // Building CRUD API
  CREATE_BUILDING: "",
  GET_BUILDING: "",
  UPDATE_BUILDING: "",
  DELETE_BUILDING: "",

  // Building Form OData
  GET_FORM_BUILDING: "",

  // Building View OData
  GET_BUILDING_ISSUING_OFFICERS: CORE_OFFICERS_ODATA,
  GET_BUILDING_APPROVAL_OFFICERS: CORE_OFFICERS_ODATA,
};

export const OfficerColumns = [
  { title: "Name", field: "Name" },
  { title: "Position", field: "Position" },
  { title: "Email", field: "Email" },
  { title: "Phone", field: "Phone" },
];

export const BarrierStandardsColumns = [{ title: "Name", field: "Name" }];

export const RegulationColumns = [{ title: "Name", field: "Name" }];

export const BuildingPermitTypeColumns = [
  { title: "Name", field: "Name" },
  { title: "Target Days", field: "Position" },
];

export const BuildingBCAVersionColumns = [
  { title: "Name", field: "Name" },
  { title: "Description", field: "Position" },
];

export const BuildingNatureOfWorkColumns = [
  { title: "Category Name", field: "Name" },
];

export const BuildingUseOfBuildingColumn = [{ title: "Name", field: "Name" }];

export const BuildingBCAClassColumns = [
  { title: "Name", field: "Name" },
  { title: "Description", field: "Position" },
];

export const BuildingOfficerColumns = [
  { title: "Name", field: "Name" },
  { title: "Position", field: "Position" },
  { title: "Email", field: "Email" },
  { title: "Phone", field: "Phone" },
];

export const InspectionsRequiredColumns = [
  { title: "Name", field: "Name" },
  { title: "Group", field: "Group" },
  { title: "Classification", field: "Position" },
  { title: "Description", field: "Position" },
  { title: "Amount", field: "Name" },
];

export const ApplicantColumns = [
  { title: "Number", field: "Name" },
  { title: "Applicant", field: "Name" },
  { title: "Category", field: "SystemType" },
  { title: "Type", field: "Name" },
  { title: "Site Address", field: "Description" },
  { title: "Locality", field: "Description" },
  { title: "Owner", field: "Description" },
  { title: "Legal Description", field: "Description" },
  { title: "Days", field: "Description" },
  { title: "Status", field: "Description" },
  { title: "File Number", field: "Description" },
  { title: "Reference Number", field: "Description" },
  { title: "Valuation Number", field: "Description" },
  { title: "Assess Number", field: "Description" },
];

export class Building {
  // LOAD BY DEFAULT
  id?: string | number = "";
  ID?: string | number = "";
  ApplicationNumber: string = "";
  PermitNumber: string = "";
  PermitType: string = "";
  Date_PermitIssued: string = "";
  RefNo: string = "";
  AssessmentNo: string = "";
  Flag_PrivateCertifier: string = "";
  IsPrivateCertifier: string = "";
  Applicant: string = "";
  Builder: string = "";
  Category: string = "";
  BCA: string = "";
  UseOfBuilding: string = "";
  ReasonForPermit: string = "";
  SiteAddress: string = "";
  Locality: string = "";
  Owner: string = "";
  Status: string = "";
  ApplicantRequires: string = "";
  Date_Lodged: string = "";
  Date_AllInspComplete: string = "";
  Date_DueToCommence: string = "";
  Date_Commenced: string = "";
  Date_DueToComplete: string = "";
  Date_Completed: string = "";
  Date_CertificateIssued: string = "";
  FileNo: string = "";
  ValuationNo: string = "";
  LegalDescription: string = "";
  BuildingOfficer: string = "";
  Surveyor: string = "";
  SurveyorNumber: string = "";
  StatDays: string = "";
  Date_Issued: string = "";
  Date_ReminderSent: string = "";
  PayingFee: string = "";
  CarryingOutWork: string = "";
  WorkProposed: string = "";
  Address: string = "";
  BuildingAppNo: string = "";
  BuildingPermitNo: string = "";
  Date_BuildingIssued: string = "";
  Date_Commencing: string = "";
  BondAmount: string = "";
  BondReceived: string = "";
  BondReturned: string = "";
  Date_FirstInspection: string = "";
  Date_LastInspection: string = "";
  TargetDate: string = "";
  CILFee: string = "";
  Flag_CILPaid: string = "";
  LettersDisplay: string = "";
  DateInspected: string = "";
  InspectionType: string = "";
  Officer: string = "";
  Compliance: string = "";
  Reason: string = "";
  FacilityRegion: string = "";
  Description: string = "";
  DateTarget: string = "";
  Source: string = "";
  Location: string = "";
  RequestedBy: string = "";
  ContactDetails: string = "";
  DueStatus: string = "";
  DateRecorded: string = "";
  RecordedBy: string = "";
  OrgStructure: string = "";
  ActionOfficer: string = "";
  Coordinator: string = "";
  DateWork: string = "";
  FileNumber: string = "";
  EnquiryRefNo: string = "";
  Event_ReferenceNoAdditional: string = "";
  OnBehalfOf: string = "";
  Flag_ITSupport: string = "";
  Flag_Safety: string = "";
  Flag_Risk: string = "";
  Flag_VIP: string = "";
  DateServed: string = "";
  ApplicantName: string = "";
  Type: string = "";
  Risk: string = "";
  DateDue: string = "";
  DateRevoked: string = "";
  Register_Number: string = "";
  Contact: string = "";
  ReferenceNumber: string = "";
  Date_Complete: string = "";
  Date_Construction: string = "";
  BarrierStandard: string = "";
  BarrierType: string = "";
  Date_CertificateOfCompliance: string = "";
  Pool_RegisteredDate: string = "";
  Date_Lodgement_CertificateOfBarrierNC: string = "";
  Date_WorkIssued: string = "";
  Work_CarriedOut: string = "";
  AmountOS: string = "";
  Flag_RenewalNoticeSent: string = "";
  Outcome: string = "";
  Regulation: string = "";
  Date_Due: string = "";
  Date_NextInspection: string = "";
  Date_Start: string = "";
  Date_Occurred: string = "";
  ContactName: string = "";
  CreatedDate: string = "";
  FeeType: string = "";
  AmountIssued: string = "";
  AmountOutstanding: string = "";
  CostCode: string = "";
  AmountPaid: string = "";
  ReceiptNumber: string = "";
  ReceiptDate: string = "";
  ReceiptedBy: string = "";
  Method: string = "";
  InvoiceNumber: string = "";
  DebtorNumber: string = "";
  SecurePay_Reference: string = "";
  Contact2: string = "";
  LevelOfEnforcement: string = "";
  Number: string = "";
  DateOffence: string = "";
  RegisterType: string = "";
  VicSmart: string = "";
  ePlanningCategory: string = "";
  ePlanningLodgedBy: string = "";
  Comments: string = "";
  ApplicationCategory_Name: string = "";
  ApplicationCategory_Description: string = "";
  Code: string = "";
  SortIndex: string = "";
  IsPublishToWeb: string = "";
  PublishName: string = "";
  PublishDescription: string = "";
  Title: string = "";
  ConditionCode: string = "";
  Condition: string = "";
  Name: string = "";
  Multiplier: string = "";
  Value: string = "";
  MinimumValue: string = "";
  TargetDays: string = "";
  Category_1: string = "";
  Category_2: string = "";
  ObservationSummary: string = "";
  ComplianceInstruction: string = "";
}
