import { mockActionsToBeApprovedDetailTab } from "@app/products/property/actions/to-be-approved-list/components/reference-sidebar/detail/mock";
import { IActionsToBeApprovedDetailTab } from "@app/products/property/actions/to-be-approved-list/components/reference-sidebar/detail/model";
import { sleep } from "@common/utils/common";

export const getToBeApprovedRecordDetailsTabById = async (
  recordId: number
): Promise<IActionsToBeApprovedDetailTab | undefined> => {
  await sleep(1000);
  return { Id: recordId, ...mockActionsToBeApprovedDetailTab };
};
