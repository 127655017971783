import { SelectedSecondaryWorkflow } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/transfer-meter/model";
import { EnterSpecialReadingDialog } from "@app/products/property/meters/components/dialogs/enter-special-reading/_index";
import { ISpecialReadingDialogData } from "@app/products/property/meters/components/dialogs/enter-special-reading/model";
import { PROPERTY_METERS_SPECIAL_READING_LIST_ROUTE } from "@app/products/property/meters/special-reading-list/constant";
import { IRegisterMeters } from "@app/products/property/registers/[id]/components/child-screens/meters/model";
import { useRegisterMetersStore } from "@app/products/property/registers/[id]/components/child-screens/meters/store";
import ScheduleSpecialReadingsDialog from "@app/products/property/registers/[id]/components/dialogs/schedule-special-readings/_index";
import { getRegisterSpecialReadingData } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/buttons/enter-special-reading/api";
import { TransferDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/transfer/_index";
import { mockBillingGroup } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/transfer/components/form-elements/transferring/mock";
import {
  IRegisterMeterTransferred,
  IRegisterTransferMeterData,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/transfer/model";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export const TransferButton = observer(() => {
  const [isShowTransferDialog, setIsShowTransferDialog] =
    useState<boolean>(false);
  const [showEnterSpecialReadingDialog, setShowEnterSpecialReading] =
    useState<boolean>(false);
  const [
    showScheduleSpecialReadingsDialog,
    setShowScheduleSpecialReadingsDialog,
  ] = useState<boolean>(false);
  const [specialReadingData, setSpecialReadingData] = useState<
    ISpecialReadingDialogData | undefined
  >();
  const { pushNotification } = useCCAppNotificationStore();
  const { metersSelected, metersData } = useRegisterMetersStore();
  const { register } = useRegisterStore();
  const [transferData, setTransferData] =
    useState<IRegisterTransferMeterData>();
  const history = useHistory();
  const defaultBilling = { Key: 1, Value: "Monthly Billing" };
  const meterTransferred: IRegisterMeterTransferred[] | undefined = [];
  (metersSelected as IRegisterMeters[])?.forEach((item) => {
    if (register) {
      meterTransferred.push({
        AccountReference: register.AccountReference,
        PropertyAddress: register.PropertyAddress,
        MeterId: item.MeterId,
        MeterNumber: item.MeterNumber,
        Change: true,
      });
    }
  });

  const onClickTransfer = () => {
    if (!register) return;
    setTransferData({
      AccountNo: register.AccountReference,
      Description: register.Description,
      AccountName: register.Name,
      DateOfChange: new Date(),
      MeterTransferred: meterTransferred ?? [],
      BillingGroup: defaultBilling,
      _option: {
        BillingGroup: {
          Data: mockBillingGroup,
        },
        SearchAccount: {
          Data: [],
          Loading: false,
        },
      },
    });
    setIsShowTransferDialog(true);
  };

  const handleOnSubmit = (data: any) => {
    setTransferData(undefined);
    setIsShowTransferDialog(false);
    if (!data?.SecondaryWorkflow?.SelectedSecondaryWorkflow) {
      pushNotification({
        title: "Transfer successfully",
        type: "success",
      });
    }
    if (
      data?.SecondaryWorkflow?.SelectedSecondaryWorkflow ===
      SelectedSecondaryWorkflow.EnterSpecialReading
    ) {
      if (!register) return;
      getRegisterSpecialReadingData(register, metersSelected).then(
        (response: ISpecialReadingDialogData) => {
          if (!response) return;
          setSpecialReadingData(response);
          setShowEnterSpecialReading(true);
        }
      );
    } else if (
      data?.SecondaryWorkflow?.SelectedSecondaryWorkflow ===
      SelectedSecondaryWorkflow.ScheduleASpecialReading
    ) {
      setShowScheduleSpecialReadingsDialog(true);
    }
  };

  return (
    <>
      <CCNavButton
        title="Transfer"
        onClick={onClickTransfer}
        disabled={metersSelected.length === 0}
      />

      {isShowTransferDialog && (
        <TransferDialog
          onClose={() => {
            setIsShowTransferDialog(false);
          }}
          onSubmit={handleOnSubmit}
          transferData={transferData}
        />
      )}
      {showEnterSpecialReadingDialog && (
        <EnterSpecialReadingDialog
          onClose={() => {
            setShowEnterSpecialReading(false);
          }}
          onEnterAndExit={() => {
            setShowEnterSpecialReading(false);
            pushNotification({
              title: "Special reading entered successfully",
              type: "success",
            });
          }}
          specialReadingData={specialReadingData}
        />
      )}
      {showScheduleSpecialReadingsDialog && metersData && (
        <ScheduleSpecialReadingsDialog
          onClose={() => setShowScheduleSpecialReadingsDialog(false)}
          onSubmit={() => {
            setShowScheduleSpecialReadingsDialog(false);
            history.push({
              pathname: `${PROPERTY_METERS_SPECIAL_READING_LIST_ROUTE}`,
              state: {
                notification: {
                  title: "Special readings scheduled successfully",
                  type: "success",
                },
              },
            });
          }}
          initialDataDialog={metersData}
        />
      )}
    </>
  );
});
