import { history } from "@/AppRoutes";
import { TOWN_PLANNING_PPR_PERMIT_REFERRALS_REGISTER_ROUTE } from "@app/products/town-planning/ppr/permit-referrals/all/register/contant";
import { getProductTitle } from "@common/constants/product-control";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { PPRDashboardPlanningReferralResults } from "@common/pages/home/components/townplanning/model";
import { useTownPlanningDashboardStore } from "@common/pages/home/components/townplanning/store";
import { nameOfFactory } from "@common/utils/common";
import Loading from "@components/loading/Loading";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<PPRDashboardPlanningReferralResults>();
export const PlanningReferralResults = observer(() => {
  const {
    planningReferralResults,
    queryDateTimeTownPlanningDashboard,
    isLoadingDashboardTownPlanning,
  } = useTownPlanningDashboardStore();

  const PlanningReferralResultsChart = () => {
    return (
      <Chart style={{ height: 250 }}>
        <ChartLegend position="right" orientation="vertical" />
        <ChartSeries>
          <ChartSeriesItem
            type="pie"
            overlay={{
              gradient: "glass",
            }}
            tooltip={{ visible: true }}
            data={planningReferralResults ?? []}
            categoryField={nameOf("ResponseType")}
            field={nameOf("Count")}
          />
        </ChartSeries>
      </Chart>
    );
  };

  return (
    <>
      {isLoadingDashboardTownPlanning ? (
        <Loading isLoading />
      ) : (
        <div
          className="cc-insight-group"
          onClick={() => {
            history.push(TOWN_PLANNING_PPR_PERMIT_REFERRALS_REGISTER_ROUTE);
          }}
        >
          <div className="cc-group-name">
            {getProductTitle(PRODUCT_TYPE.TownPlanning)}
          </div>
          <h4>Planning Referral Results</h4>
          <div className="cc-date-time-description">
            as of {queryDateTimeTownPlanningDashboard ?? ""}
          </div>
          <br />
          <div className="cc-insight-group-container">
            <PlanningReferralResultsChart />
          </div>
        </div>
      )}
    </>
  );
});
