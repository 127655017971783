import { splitCamelCase } from "@common/utils/formatting";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React from "react";

export const ParkingPermitsDetailTab = observer(() => {
  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Permit Number:"}
        value="C16622"
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Status:"}
          value={splitCamelCase("Current")}
          success
        />
        <ProductReferenceRow title={"Permit Type:"} value="Business-focused" />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
