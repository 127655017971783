import { VO_Master_Property } from "@app/products/property/assessments/master-properties/model";
import { getSearchMasterProperty } from "@app/products/property/supplementary-rates/[id]/components/dialogs/add-master-property/api";
import {
  defaultAddMasterProperty,
  getUrlApiMPSearchBy,
  searchByData,
  searchConfig,
} from "@app/products/property/supplementary-rates/[id]/components/dialogs/add-master-property/config";
import {
  eSearchMasterPropertyBy,
  IAddMasterPropertyDataForm,
  ISearchBy,
} from "@app/products/property/supplementary-rates/[id]/components/dialogs/add-master-property/model";
import { handleProcessMasterProperty } from "@app/products/property/supplementary-rates/[id]/components/dialogs/add-master-property/util";
import { isSuccessResponse } from "@common/apis/util";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  DropDownListChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { ReactElement, useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
interface IAddMasterPropertyDialogProps {
  dataUrl: string;
  isLoadingFinish?: boolean;
  notificationRef?: React.MutableRefObject<ICCLocalNotificationHandle | null>;
  handleFinish: (value: any) => void;
  onClose: () => void;
}
const nameOfMP = nameOfFactory<IAddMasterPropertyDataForm>();

const itemRender = (
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const { dataItem } = itemProps;
  const itemChildren = (
    <span>
      {`${dataItem?.Id ?? ""} - ${
        dataItem?.Property_Assessment_Number ?? ""
      } - ${dataItem?.Property_Name_Address_Locality ?? ""}`}
    </span>
  );
  return React.cloneElement(li, li.props, itemChildren);
};

export const AddMasterPropertyDialog = observer(
  ({
    dataUrl,
    handleFinish,
    onClose,
    isLoadingFinish,
    notificationRef,
  }: IAddMasterPropertyDialogProps) => {
    const [filterValue, setFilterValue] = useState<string | null>(null);
    const refTimeOut = useRef<NodeJS.Timeout | null>(null);
    const { cancelToken, cancelRequest } = useCancelRequest();

    const initialData = useMemo(() => {
      const dataForm = { ...defaultAddMasterProperty };
      dataForm._option.SearchBy = {
        Data: searchByData,
        Value: searchByData[0],
      };
      return dataForm;
    }, []);

    useEffectOnce(() => {
      return () => {
        if (refTimeOut.current) clearTimeout(refTimeOut.current);
      };
    });

    return (
      <Form
        onSubmit={handleFinish}
        initialValues={initialData}
        key={JSON.stringify(initialData)}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onChange, valid, modified, onSubmit } =
            formRenderProps;
          const searchByValue: ISearchBy =
            valueGetter("_option.SearchBy.Value") ?? "";
          const masterPropertyValue: VO_Master_Property | null = valueGetter(
            nameOfMP("MasterProperty")
          );

          const handleSearchMasterProperty = (
            event: ComboBoxFilterChangeEvent
          ) => {
            const { filter } = event;
            const searchField = filter?.field as eSearchMasterPropertyBy;
            let filterValue = filter?.value ?? "";
            const searchBy: eSearchMasterPropertyBy =
              searchField ?? eSearchMasterPropertyBy.MasterPropertyNumber;
            //check case decimal
            if (
              [
                eSearchMasterPropertyBy.MasterPropertyNumber,
                eSearchMasterPropertyBy.MasterPropertyValuationNumber,
              ].includes(searchField) &&
              filterValue.endsWith(".")
            )
              filterValue = filterValue + "0";
            setFilterValue(filterValue);
            onChange("_option.MasterProperty.Data", {
              value: [],
            });
            if (!searchByValue) return;
            if (refTimeOut.current) clearTimeout(refTimeOut.current);
            refTimeOut.current = setTimeout(async () => {
              onChange("_option.MasterProperty.Loading", {
                value: true,
              });
              if (filterValue.length > 0) {
                const urlAPI =
                  `${getUrlApiMPSearchBy[searchBy]}`.replace(
                    "{URL_SEARCH_MASTER_PROPERTY}",
                    dataUrl
                  ) + `${filterValue}`;
                cancelRequest();
                const response = await getSearchMasterProperty(
                  urlAPI,
                  cancelToken()
                );
                if (isSuccessResponse(response) && response?.data) {
                  onChange("_option.MasterProperty.Data", {
                    value: response.data?.value,
                  });
                } else {
                  onChange("_option.MasterProperty.Data", {
                    value: [],
                  });
                }
              }
              onChange("_option.MasterProperty.Loading", {
                value: false,
              });
            }, searchConfig.typeSpeed);
          };
          return (
            <CCDialog
              onClose={onClose}
              maxWidth="40%"
              height="auto"
              disabled={isLoadingFinish}
              titleHeader="Add Master Property"
              bodyElement={
                <>
                  <FormElement className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <div className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <CCLabel title="Search by" />
                          <Field
                            name={"_option.SearchBy.Value"}
                            component={CCDropDownList}
                            textField="Value"
                            data={valueGetter("_option.SearchBy.Data") ?? []}
                            onChange={(event: DropDownListChangeEvent) => {
                              onChange("_option.SearchBy.Value", {
                                value: event.target.value,
                              });
                              //reset data remaining fields
                              onChange(nameOfMP("MasterProperty"), {
                                value: null,
                              });
                              setFilterValue(null);
                            }}
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel title={searchByValue?.Value} isMandatory />
                          <Field
                            name={nameOfMP("MasterProperty")}
                            component={CCComboBox}
                            clearButton={false}
                            filterable
                            suggest
                            placeholder={`Type ${
                              searchByValue?.Value?.toLocaleLowerCase() ?? ""
                            }`}
                            textField={
                              searchByValue?.Key ?? searchByData[0].Key
                            }
                            data={
                              valueGetter("_option.MasterProperty.Data") ?? []
                            }
                            loading={valueGetter(
                              "_option.MasterProperty.Loading"
                            )}
                            onFilterChange={handleSearchMasterProperty}
                            validator={requiredValidator}
                            itemRender={itemRender}
                            onChange={(e: ComboBoxChangeEvent) => {
                              let currentValue = handleProcessMasterProperty(e);
                              onChange(nameOfMP("MasterProperty"), {
                                value: currentValue,
                              });
                              setFilterValue(
                                currentValue?.[searchByValue?.Key] ??
                                  searchByData[0].Key
                              );
                            }}
                            filter={filterValue}
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel title="Address" />
                          <Field
                            name={"Property_Name_Address_Locality"}
                            component={CCTextArea}
                            readOnly
                            placeholder="Address"
                            value={
                              masterPropertyValue?.Property_Name_Address_Locality
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </FormElement>
                </>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    themeColor="primary"
                    className={"cc-dialog-button"}
                    type="button"
                    name="btn-submit"
                    disabled={!modified || !valid}
                    onClick={onSubmit}
                    iconClass={isLoadingFinish ? "fas fa-spinner fa-spin" : ""}
                  >
                    Finish
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
