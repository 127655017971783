import { AnimalType, RegistrationFeeRule } from "@app/products/animals/model";
import { AddFeeRuleButton } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/components/buttons/add-rule-button/_index";
import { DeleteRulesButton } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/components/buttons/delete-rule-button/_index";
import {
  getColRegistrationFeeRules,
  nameOfRegistrationFeeRules,
} from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/config";
import {
  RegistrationFeeRuleList,
  RegistrationFeeRulesType,
} from "@app/products/animals/system-admin/animal-types/[id]/model";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import { generateRegistrationFeeRuleData } from "@app/products/animals/system-admin/animal-types/[id]/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface IRegistrationFeeRulesProps {
  animalTypeFormObject: AnimalType;
  formRenderProps: FormRenderProps;
}

const nameOfAnimalType = nameOfFactory<AnimalType>();

export const RegistrationFeeRules = observer(
  ({ animalTypeFormObject, formRenderProps }: IRegistrationFeeRulesProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { setupRegistrationFeeRulesDialog } = useAnimalTypeStore();
    const [gridSelectedRows, setGridSelectedRows] = useState<
      RegistrationFeeRuleList[]
    >([]);

    const feeRulesList = valueGetter(nameOfAnimalType("FeeRulesList"));

    const registrationFeeRulesData = useMemo(() => {
      return generateRegistrationFeeRuleData(
        animalTypeFormObject?.FeeRulesList?.RegistrationFeeRules
      );
    }, [animalTypeFormObject?.FeeRulesList?.RegistrationFeeRules]);

    const submitDeleteHandler = () => {
      let registrationFeeRulesList = cloneDeep(
        animalTypeFormObject.FeeRulesList?.RegistrationFeeRules ?? []
      );
      const rulesEnum = gridSelectedRows.map(
        (item: RegistrationFeeRuleList) => item.RuleType_Enum
      );
      registrationFeeRulesList = registrationFeeRulesList.filter(
        (feeRule: RegistrationFeeRule) =>
          !rulesEnum.includes(feeRule.RuleType_ENUM)
      );
      onChange(nameOfAnimalType("FeeRulesList"), {
        value: {
          ...feeRulesList,
          RegistrationFeeRules: registrationFeeRulesList,
        },
      });
      setGridSelectedRows([]);
    };

    return (
      <>
        <CCLabel
          title={`Registration fee rules${
            registrationFeeRulesData?.length > 0
              ? ` (${registrationFeeRulesData?.length})`
              : ""
          }`}
        />
        <CCGrid
          selectableMode="multiple"
          primaryField={nameOfRegistrationFeeRules("RuleType_Enum")}
          columnFields={getColRegistrationFeeRules(
            setupRegistrationFeeRulesDialog,
            animalTypeFormObject
          )}
          data={registrationFeeRulesData ?? []}
          selectedRows={gridSelectedRows}
          onSelectionChange={(dataItem: RegistrationFeeRuleList[]) => {
            setGridSelectedRows([...dataItem]);
          }}
          state={{
            sort: [
              {
                field: nameOfRegistrationFeeRules("IsPriority"),
                dir: "asc",
              },
              {
                field: nameOfRegistrationFeeRules("SortOrder"),
                dir: "asc",
              },
              {
                field: nameOfRegistrationFeeRules("RuleType_Name"),
                dir: "asc",
              },
            ],
          }}
          toolbar={
            <div className="cc-grid-tools-bar">
              <AddFeeRuleButton
                feeRulesType={RegistrationFeeRulesType.RegistrationFeeRules}
              />
              <DeleteRulesButton
                gridSelectedRows={gridSelectedRows}
                submitDeleteHandler={submitDeleteHandler}
              />
            </div>
          }
        />
      </>
    );
  }
);
