import { getTitleOwners } from "@app/products/property/titles/[id]/components/child-screens/owners/api";
import { DTO_Entity } from "@app/products/property/titles/[id]/components/child-screens/owners/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class TitleOwnersStore {
  private _titleOwners?: DTO_Entity[] = [];
  private _isLoading: boolean = false;
  private _isIncludePastNames: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  get isIncludePastNames() {
    return this._isIncludePastNames;
  }
  setIncludePastNames = (isIncludePastNames: boolean) => {
    runInAction(() => {
      this._isIncludePastNames = isIncludePastNames;
    });
  };
  get titleOwners() {
    return toJS(this._titleOwners);
  }
  setTitleOwners = (titleOwners: DTO_Entity[] | undefined) => {
    runInAction(() => {
      this._titleOwners = titleOwners;
    });
  };

  //Action
  resetStore = () => {
    this.setIncludePastNames(false);
    this.setTitleOwners([]);
    this.setIsLoading(false);
  };
  loadTitleOwners = async (titleId: number, includePastNames: boolean) => {
    this.setIsLoading(true);
    const data = await getTitleOwners(titleId, includePastNames);
    this.setTitleOwners(data?.Entities ?? []);
    this.setIsLoading(false);
  };
}
const titleOwnersStoreContext = createContext(new TitleOwnersStore());
export const useTitleOwnersStore = () => {
  return useContext(titleOwnersStoreContext);
};
