import React, { useState } from "react";
import "./_index.scss";
export interface ISettingPanelBar {
  menuItems: string[];
  activeItem: string;
  setActiveItem: (nameMenu: string) => void;
}

export const SettingPanelBar = (props: ISettingPanelBar) => {
  const [tabSelected, setTabSelected] = useState(0);
  return (
    <>
      <div className="systemsetting-panel row col-lg-12 border-bottom border-top pt-2 pb-2">
        {props.menuItems.map((menuItem: string, indexItem: number) => {
          return (
            <>
              <div
                className={
                  "col-auto pb-2 pt-2 ml-1 " +
                  (tabSelected === indexItem ? "col-border" : "")
                }
              >
                <a
                  onClick={() => {
                    props.setActiveItem(menuItem);
                    setTabSelected(indexItem);
                  }}
                  href="!#"
                >
                  {menuItem}
                </a>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
