import { DTO_Assessment } from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Assessment>();
export const colHoldingAddRebate: IColumnFields[] = [
  {
    field: nameOf("Assess_Number"),
    title: "Holding Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Assess_Property_Address"),
    title: "Address",
  },
  {
    field: nameOf("Assessment_Group_Name"),
    title: "Region",
  },
  {
    field: nameOf("Ratepayer_Name"),
    title: "Occupier",
  },
];
