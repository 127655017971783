import { CustomColumnMenuFilterCell } from "@components/cc-grid/components/grid-column-menus/column-menu-filter/components/custom-column-menu-filter-cell/_index";
import { getItemLogic } from "@components/cc-grid/components/grid-column-menus/column-menu-filter/components/custom-grid-column-menu-filter/config";
import { IColumnFields } from "@components/cc-grid/model";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { GridColumnMenuFilterUIProps } from "@progress/kendo-react-grid";
import { GridFilterOperator } from "@progress/kendo-react-grid/dist/npm/interfaces/GridFilterOperator";
import React from "react";

export interface ILogicBetweenFilter {
  logicValue?: GridFilterOperator;
  logicData: GridFilterOperator[];
  onLogicChange: (e: any) => void;
}
export interface ICustomFilterUI
  extends Omit<
    GridColumnMenuFilterUIProps,
    "logicData" | "logicValue" | "onLogicChange"
  > {
  columnConfig: IColumnFields;
  logicBetweenFilter: ILogicBetweenFilter;
}

export const CustomFilterUI = (props: any) => {
  const {
    firstFilterProps,
    hideSecondFilter = true,
    secondFilterProps,
    columnConfig,
    logicBetweenFilter,
    logicData,
    logicValue,
    onLogicChange,
    hideFirstFilter,
    ...others
  } = props;

  return (
    <div>
      {!hideFirstFilter && (
        <CustomColumnMenuFilterCell
          uniqueKey={"operator-1"}
          {...firstFilterProps}
          columnConfig={columnConfig}
        />
      )}
      {!hideSecondFilter && (
        <>
          {logicBetweenFilter && !hideFirstFilter && (
            <DropDownList
              name={"Between-Filter"}
              data={logicBetweenFilter.logicData ?? []}
              value={getItemLogic(logicBetweenFilter.logicValue)}
              onChange={logicBetweenFilter.onLogicChange}
              className={"k-filter-and"}
              textField="text"
            />
          )}
          <CustomColumnMenuFilterCell
            uniqueKey={"operator-2"}
            {...secondFilterProps}
            columnConfig={columnConfig}
            {...others}
          />
        </>
      )}
    </div>
  );
};
