import { InstallationBillingDetails } from "@app/products/property/meters/[id]/components/child-screens/installation/components/billing-details/_index";
import { InstallationRemoveDetail } from "@app/products/property/meters/[id]/components/child-screens/installation/components/installation-remove-detail/_index";
import { InstallationMaterAndSubMeters } from "@app/products/property/meters/[id]/components/child-screens/installation/components/mater-and-sub-meters/_index";
import { InstallationReadingDetails } from "@app/products/property/meters/[id]/components/child-screens/installation/components/reading-details/_index";
import { useMeterInstallationStore } from "@app/products/property/meters/[id]/components/child-screens/installation/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const MeterInstallation = observer(() => {
  const { isLoading, installation, loadInstallation, setMeterId } =
    useMeterInstallationStore();
  const { id } = useParams<{ id: string }>();
  const [view, setView] = useState<string>(",Proserpine");
  useEffectOnce(() => {
    loadInstallation();
    setMeterId(+id);
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!installation) return <NoData vertical />;
  return (
    <div className="cc-property-meter-installation cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field cc-col-span-2">
          <label className="cc-label">Meter installation</label>
          <DropDownList
            className="cc-form-control"
            data={installation?.ListMeterInstallation || []}
            value={view}
            onChange={(event: DropDownListChangeEvent) => {
              setView(event.value);
            }}
          />
        </div>
        <div className="cc-field-group cc-col-span-3">
          <InstallationReadingDetails data={installation?.ReadingDetail} />
          <InstallationRemoveDetail data={installation?.InstallationDetails} />
          <InstallationMaterAndSubMeters />
          <InstallationBillingDetails />
        </div>
      </div>
    </div>
  );
});
