import { nameOfFactory } from "@common/utils/common";

import { CORE_STATEROADS_ODATA } from "@app/core/components/common/constant";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { StateDeclaredRoad } from "@app/products/town-planning/ppr/[id]/components/input-picker/state-roads-picker-search/model";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfStateRoads = nameOfFactory<StateDeclaredRoad>();
const searchByColumnOfStateRoads = nameOfStateRoads("RoadName");
export const colStateRoadsPicker: IColumnFields[] = [
  { title: "Road Name", field: nameOfStateRoads("RoadName") },
  {
    title: "Road Number",
    field: nameOfStateRoads("RoadNumber"),
  },
  {
    title: "Road ID Number",
    field: nameOfStateRoads("RoadIDNo"),
  },
  {
    title: "Road Class",
    field: nameOfStateRoads("RoadClass"),
  },
  {
    title: "Road Start Number",
    field: nameOfStateRoads("RoadStartNo"),
  },
  {
    title: "Road Start Name",
    field: nameOfStateRoads("RoadStartName"),
  },
  {
    title: "Road Start Type",
    field: nameOfStateRoads("RoadStartType"),
  },
  {
    title: "Road Start Locality",
    field: nameOfStateRoads("RoadStartLocality"),
  },
];

export const optionInputPickerStateRoads: IOptionInputPicker = {
  dialog: {
    titleHeader: "State Roads",
    maxWidth: "60%",
    height: "80%",
  },
  boxSearch: {
    colSearch: searchByColumnOfStateRoads,
    colShowComboboxSearch: [
      {
        title: "Name",
        field: searchByColumnOfStateRoads,
      },
    ],
  },
  grid: {
    columnFields: colStateRoadsPicker,
    primaryField: "ID",
    dataUrl: CORE_STATEROADS_ODATA,
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
  },
};
