import { getViewConfigurations } from "@app/products/property/api";
import { getGeneralLedgerPostings } from "@app/products/property/journals/session-list/[id]/components/child-screens/general-ledger-postings/api";
import { colGeneralLedgerPostings } from "@app/products/property/journals/session-list/[id]/components/child-screens/general-ledger-postings/config";
import {
  DTO_GL_Posting,
  DTO_Session_GL_Postings,
} from "@app/products/property/journals/session-list/[id]/components/child-screens/general-ledger-postings/model";
import {
  ICCViewColumn,
  ICCViewConfiguration,
  ViewConfiguration,
} from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class GeneralLedgerPostingsStore {
  private _columnConfigLedger: IColumnFields[] = [];
  private _ledgerPostingsData: DTO_GL_Posting[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get columnConfigLedger() {
    return this._columnConfigLedger;
  }
  setColumnConfigLedger = (columnConfig: IColumnFields[]) => {
    runInAction(() => {
      this._columnConfigLedger = columnConfig;
    });
  };

  get ledgerPostingsData() {
    return this._ledgerPostingsData;
  }
  setLedgerPostingsData = (ledgerPostingsData: DTO_GL_Posting[]) => {
    runInAction(() => {
      this._ledgerPostingsData = ledgerPostingsData;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  /**
   * Calling api get configuration column
   * process columns
   */
  loadGeneralLedgerPostings = async (sessionNumber?: number) => {
    if (isNil(sessionNumber)) return;
    this.setIsLoading(true);
    let errorResponse = undefined;
    let configColumn: IColumnFields[] = [...colGeneralLedgerPostings];
    const [responseConfiguration, responseData] = await Promise.all([
      getViewConfigurations(ViewConfiguration.Session_GL_Postings),
      getGeneralLedgerPostings(sessionNumber),
    ]);
    if (
      isSuccessResponse(responseConfiguration) &&
      responseConfiguration?.data &&
      isSuccessResponse(responseData) &&
      responseData?.data
    ) {
      const viewConfig: ICCViewColumn[] | undefined =
        responseConfiguration?.data?.ColumnDefinitions?.Columns;
      if (viewConfig?.length) {
        configColumn = processDynamicColumns(configColumn, viewConfig);
      }
      this.setLedgerPostingsData(
        responseData?.data?.GL_Posting?.map((item, index) => {
          return { ...item, Id: index + 1 };
        }) ?? []
      );
    } else {
      let responseError:
        | APIResponse<ICCViewConfiguration | undefined>
        | APIResponse<DTO_Session_GL_Postings | undefined> =
        responseConfiguration;
      if (responseData && !isSuccessResponse(responseData)) {
        responseError = responseData;
      }
      errorResponse = {
        status: responseError.status,
        error: "Load workflow failed",
      };
    }
    this.setColumnConfigLedger(configColumn);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  //restore
  resetStore = () => {
    runInAction(() => {
      this.setColumnConfigLedger([]);
      this.setIsLoading(false);
      this.setResponseLoadError();
    });
  };

  //reload
  reLoadApiConfig = (sessionNumber?: number) => {
    if (isNil(sessionNumber)) return;
    this.loadGeneralLedgerPostings(sessionNumber);
  };
}

const generalLedgerPostingsStoreContext = createContext(
  new GeneralLedgerPostingsStore()
);
export const useGeneralLedgerPostingsStore = () => {
  return useContext(generalLedgerPostingsStoreContext);
};
