import {
  FurtherInformationRequest,
  FURTHERINFOSTATUS,
} from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { optionInputPickerOfficer } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { KeyWordsPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/_index";
import { getSiteUser } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/api";
import { isSuccessResponse } from "@common/apis/util";
import { BOOLEAN_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { mapRecordType, RECORDTYPE } from "@common/constants/recordtype";
import { SiteUser } from "@common/pages/settings/security/site-users/_id/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { ICCInputPickerChangeEvent } from "@components/cc-input-picker/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
  Field,
  FieldProps,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { addDays } from "date-fns";
import { isDate, isNumber } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";
const nameOf = nameOfFactory<FurtherInformationRequest>();

interface IFurtherFormElementProps {
  formRenderProps: FormRenderProps;
}

export const FurtherFormElement = observer(
  ({ formRenderProps }: IFurtherFormElementProps) => {
    const { ancestor, originalFI, furtherInfo, isInactive } =
      useFurtherInfoStore();
    const { valueGetter, onChange } = formRenderProps;

    const { pushNotification } = useCCAppNotificationStore();

    const numberOfDaysGiven = valueGetter(nameOf("NumberOfDaysGiven"));
    const dateRequested = valueGetter(nameOf("DateRequested"));

    const isShowAncestor4 = useMemo(() => {
      if (!ancestor) return false;
      if (
        mapRecordType[
          ancestor?.RecordSourceIdentifier?._RecordSourceType_ENUM ?? ""
        ] !== RECORDTYPE.TP_PPRApplication
      )
        return false;
      if (ancestor.lblParent4 === "") return false;
      return true;
    }, [ancestor]);

    const isTPPSA = useMemo(() => {
      if (!furtherInfo || !furtherInfo?._BubbleUps) return false;
      return furtherInfo?._BubbleUps.some(
        (bubbleUp) =>
          mapRecordType[
            bubbleUp.SourceIdentifier._RecordSourceType_ENUM ?? ""
          ] === RECORDTYPE.TP_PSA_Application
      );
    }, [furtherInfo]);

    const isShowExtensionRequired = useMemo(() => {
      if (!furtherInfo) return false;
      let isShowExtension = furtherInfo.ExtensionRequested ?? false;
      if (!isTPPSA) isShowExtension = false;
      return isShowExtension;
    }, [furtherInfo, isTPPSA]);

    const isShowInfoNotReceived = useMemo(() => {
      return furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.InfoNotReceived;
    }, [furtherInfo]);

    const onChangeNoOfDaysGiven = useCallback(
      (noOfDaysGiven: number | null, dateRequested: Date | null) => {
        onChange(nameOf("NumberOfDaysGiven"), {
          value: noOfDaysGiven,
        });
        if (isNumber(noOfDaysGiven) && isDate(dateRequested)) {
          onChange(nameOf("DateDue"), {
            value: addDays(dateRequested, noOfDaysGiven),
          });
        }
      },
      [onChange]
    );

    const onChangeDateRequested = useCallback(
      (event: DatePickerChangeEvent) => {
        onChange(nameOf("DateRequested"), { value: event.value });
        onChangeNoOfDaysGiven(numberOfDaysGiven, event.value);
      },
      [onChange, onChangeNoOfDaysGiven, numberOfDaysGiven]
    );

    const onChangeNumberOfDaysGiven = useCallback(
      (event: ICCInputPickerChangeEvent) => {
        onChangeNoOfDaysGiven(event.value, dateRequested);
      },
      [onChangeNoOfDaysGiven, dateRequested]
    );

    const onPickOfficer = useCallback(
      async (value: SiteUser | null) => {
        if (!value) {
          onChange(nameOf("Officer"), {
            value: null,
          });
          onChange(nameOf("Officer_ID"), {
            value: null,
          });
          return;
        }
        const response = await getSiteUser(value?.Contact_ID);
        if (isSuccessResponse(response)) {
          if (response.data) {
            onChange(nameOf("Officer"), {
              value: response.data,
            });
            onChange(nameOf("Officer_ID"), {
              value: response.data.Contact_ID,
            });
          }
        } else {
          pushNotification({
            title: "Officer is not found",
            type: "error",
            autoClose: false,
          });
        }
      },
      [onChange, pushNotification]
    );

    const FIField = useCallback(
      (props: FieldProps) => {
        const { disabled, ...others } = props;
        return (
          <Field {...others} disabled={isInactive ? isInactive : disabled} />
        );
      },
      [isInactive]
    );
    return (
      <FormElement>
        {ancestor && (
          <>
            <section className="cc-field-group">
              {ancestor.lblParent1 !== "" && (
                <div className="cc-form-cols-3">
                  {ancestor.litParent1 && (
                    <CCValueField
                      label={ancestor.lblParent1}
                      value={sanitizeHtml(ancestor.litParent1)}
                    />
                  )}
                  {ancestor.litParent2 && (
                    <CCValueField
                      label={ancestor.lblParent2}
                      value={sanitizeHtml(ancestor.litParent2)}
                    />
                  )}
                  {ancestor.litParent3 && (
                    <CCValueField
                      label={ancestor.lblParent3}
                      value={sanitizeHtml(ancestor.litParent3)}
                    />
                  )}
                </div>
              )}
              {isShowAncestor4 && ancestor.litParent4 && (
                <div className="cc-form-cols-1">
                  <CCValueField
                    label={ancestor.lblParent4}
                    value={sanitizeHtml(ancestor.litParent4)}
                  />
                </div>
              )}
            </section>
            <hr className="cc-divider" />
          </>
        )}
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Details <CCTooltip type="validator" position="right" />
              </label>
              <FIField
                name={nameOf("Details")}
                placeholder={"Details"}
                component={CCTextArea}
                validator={requiredValidator}
                rows={3}
              />
            </div>
          </div>
          {/*   Original */}
          {originalFI && (
            <div className="cc-form-cols-3">
              <CCValueField
                label={"Original requested date"}
                value={originalFI.DateRequested}
                format={DATE_FORMAT.DATE}
              />
              <CCValueField
                label={"Original information due"}
                value={originalFI.OriginalDateDue}
                format={DATE_FORMAT.DATE}
              />
              <CCValueField
                label={"Original days"}
                value={originalFI.NumberOfDaysGiven}
              />
            </div>
          )}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Requested <CCTooltip type="validator" position="right" />
              </label>
              <FIField
                name={nameOf("DateRequested")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                validator={requiredValidator}
                onChange={onChangeDateRequested}
              />
            </div>
            <CCValueField
              label={"Information due"}
              value={valueGetter(nameOf("DateDue"))}
              format={DATE_FORMAT.DATE}
            />

            <div className="cc-field">
              <label className="cc-label">Officer</label>
              <FIField
                name={nameOf("Officer")}
                nameDisplay="DisplayName"
                placeholder="Officer"
                component={InputPickerSearch}
                onChange={onPickOfficer}
                options={optionInputPickerOfficer}
              />
            </div>

            {!isTPPSA && (
              <div className="cc-field">
                <label className="cc-label">
                  Number of days <CCTooltip type="validator" position="right" />
                </label>
                <FIField
                  name={nameOf("NumberOfDaysGiven")}
                  component={KeyWordsPicker}
                  keyWordType={KEYWORD_TYPE.Core_FIDays}
                  productType={PRODUCT_TYPE_NUMBER.TownPlanning}
                  placeholder={"Number of days"}
                  validator={requiredValidator}
                  onPickKeyWords={(value: number) => {
                    onChange(nameOf("NumberOfDaysGiven"), {
                      value: value,
                    });
                    onChangeNumberOfDaysGiven({ value });
                  }}
                  onChange={onChangeNumberOfDaysGiven}
                  onError={(error: any) => {
                    pushNotification({
                      title: error,
                      type: "warning",
                    });
                  }}
                  isGetDetail={false}
                />
              </div>
            )}
          </div>
        </section>

        {isShowExtensionRequired && (
          <>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Extension requested</label>
                  <FIField
                    name={"_option.ExtensionRequested"}
                    component={CCSwitch}
                    checked={valueGetter("_option.ExtensionRequested")}
                  />
                </div>
                {valueGetter("_option.ExtensionRequested") && (
                  <div className="cc-field">
                    <label className="cc-label">Date requested</label>
                    <FIField
                      name={nameOf("ExtensionDateRequested")}
                      component={CCDatePicker}
                      format={DATE_FORMAT.DATE_CONTROL}
                    />
                  </div>
                )}
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Number of additional days</label>
                  <FIField
                    name={nameOf("ExtensionNumberOfDaysGiven")}
                    component={CCNumericTextBox}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Decision issued</label>
                  <FIField
                    name={nameOf("ExtensionDateIssued")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                  />
                </div>
              </div>
            </section>
          </>
        )}
        {!isTPPSA && (
          <>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <CCValueField
                  label={"Information received"}
                  value={valueGetter("DateInfoReceived")}
                  format={DATE_FORMAT.DATE}
                />
                <CCValueField
                  label={"Information adequate"}
                  value={valueGetter("InformationAdequate")}
                  format={BOOLEAN_FORMAT.BOOLEAN}
                />
              </div>
            </section>
          </>
        )}
        {isShowInfoNotReceived && (
          <>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <CCValueField
                  label="Information not received"
                  value={valueGetter("DateInfoNotReceived")}
                  format={DATE_FORMAT.DATE}
                />
                <CCValueField
                  label="Comments"
                  value={valueGetter("FI_NotReceivedComments")}
                />
              </div>
            </section>
          </>
        )}
      </FormElement>
    );
  }
);
