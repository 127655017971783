import { ICCRoute } from "@common/constants/ICCRoute";

export const complaintsRoute: ICCRoute = {
  path: "complaints",
  name: "Complaints",
  children: [
    {
      path: "by-refno",
      name: "By RefNo",
      component: require("./by-refno/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      component: require("./by-status/_index").default,
    },
    {
      path: "by-category",
      name: "By Category",
      component: require("./by-category/_index").default,
    },
  ],
};
