import { IFormStepElement } from "@components/cc-form-step/model";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { OfficerAndOfficerRegion } from "@app/products/property/components/fields/officer-and-officer-region/_index";
import { CCInput } from "@components/cc-input/_index";

export const ReplaceCertificateDetailFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {
    isDisabled: false,
  },
}: IFormStepElement) => {
  const { isDisabled } = options;

  return (
    <>
      <section className="cc-field-group">
        {options?.isToBeApprovalMode && (
          <>
            <CCLabel title="Workflow instructions" />
            <Field
              name={nameOf("Instructions")}
              rows={3}
              component={CCTextArea}
              placeholder="Workflow instructions"
              readOnly
            />
          </>
        )}
      </section>
      <OfficerAndOfficerRegion
        formRenderProps={formRenderProps}
        nameOf={nameOf}
        isDisabled={isDisabled}
      />
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Applicant name</label>
            <Field
              name={nameOf("ApplicantName")}
              component={CCInput}
              readOnly
              placeholder="Applicant name"
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Reference</label>
            <Field
              name={nameOf("Reference")}
              readOnly
              component={CCInput}
              placeholder="Reference"
            />
          </div>
        </div>
      </section>
    </>
  );
};
