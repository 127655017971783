import { IKeyValuePacket } from "@common/models/keyValuePacket";

export interface ILookupItems {
  Id: string;
  Name: string;
}

export interface SettingFieldMoreOptions {
  show: boolean;
  LookupItems: IKeyValuePacket;
  hiddenField: boolean;
  HelpText: string;
}

export enum PROVIDER_VALUE {
  None = "0",
  SecurePay = "1",
  BPOINT = "2",
  AdvamDirectPost = "3",
  AdvamIFrame = "4",
  BPointApi = "5",
  PayWay = "6",
}
