import { DTO_RebateClaim_OutputFile } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/action-bar/buttons/produce-claim-file/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getRebateClaimOutputFile = async (
  rebateClaimId: number | string
): Promise<APIResponse<DTO_RebateClaim_OutputFile | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/rebate/${rebateClaimId}/outputfile`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
