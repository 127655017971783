import { CRMSViews } from "@app/products/crms/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";

export const payRollRoute: ICCRoute = {
  path: "payroll",
  name: "Payroll",
  enum: CORPORATE_SETTINGS_FIELD.CUSTOMERSERVICE_Views_PayrollEvents,
  children: [
    {
      path: "my-events",
      name: "My Events",
      enum: CRMSViews.Payroll_AllMyEvents,
      component: require("./my-events/_index").default,
    },
    {
      path: "my-drafts",
      name: "My Drafts",
      enum: CRMSViews.Payroll_MyDrafts,
      component: require("./my-drafts/_index").default,
    },
    {
      path: "all-my-events",
      name: "All My Events",
      enum: CRMSViews.Payroll_AllMyEvents,
      component: require("./all-my-events/_index").default,
    },
    {
      path: "unresolved-events",
      name: "Unresolved Events",
      enum: CRMSViews.Payroll_UnresolvedEvents,
      component: require("./unresolved-events/_index").default,
    },
    {
      path: "all-events",
      name: "All Events",
      enum: CRMSViews.Payroll_AllEvents,
      component: require("./all-events/_index").default,
    },
    {
      path: "drafts",
      name: "Drafts",
      enum: CRMSViews.Payroll_Drafts,
      component: require("./drafts/_index").default,
    },
  ],
};
