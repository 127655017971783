import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";
import { crmsApplicationsRoute } from "./crms-applications/route";
import { financeRoute } from "./finance/route";
import { permitsRoute } from "./permits/route";
import { renewalsRoute } from "./renewals/route";
import { systemAdminRoute } from "./system-admin/route";

export const parkingPermitsRoute: ICCRoute = {
  path: "parking-permits",
  name: "Parking Permits",
  enum: eProductType.DISRES,
  children: [
    permitsRoute,
    renewalsRoute,
    crmsApplicationsRoute,
    financeRoute,
    systemAdminRoute,
    {
      path: ":id(new-disabled|new-parking|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
