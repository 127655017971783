import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colHoldingAddRebate } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/details/config";
import { DTO_Assessment } from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import { OfficerAndOfficerRegion } from "@app/products/property/components/fields/officer-and-officer-region/_index";
import { ViewConfiguration } from "@app/products/property/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfAssessment = nameOfFactory<DTO_Assessment>();
const FormStepElement = ({
  formRenderProps,
  nameOf,
  isLoadingStep,
  setIsLoadingStep = () => {},
  loadFailedStep,
  setLoadFailedStep = () => {},
  options = {
    isLLS: false,
    isReadOnly: false,
    isToBeApprovalMode: false,
    workflowDraftId: 0,
    workflowLOVs: undefined,
  },
}: IFormStepElement) => {
  const { valueGetter } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const [processedColumns, setProcessedColumns] =
    useState<IColumnFields[]>(colHoldingAddRebate);

  /**
   * Load view configuration
   */
  const loadViewConfig = async () => {
    setIsLoadingStep(true);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.AssessmentSearch,
      colHoldingAddRebate
    );
    if (Array.isArray(response)) {
      setProcessedColumns(response);
    } else {
      setLoadFailedStep({
        onReload: () => {
          loadViewConfig();
        },
        responseError: {
          status: response.status,
          error: response.error ?? "Load failed",
        },
      });
    }
    setIsLoadingStep(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoadingStep) {
    return <Loading isLoading={isLoadingStep} />;
  }

  if (loadFailedStep) {
    return (
      <CCLoadFailed
        onReload={loadFailedStep?.onReload}
        responseError={loadFailedStep?.responseError}
      />
    );
  }

  return (
    <>
      <section className="cc-field-group">
        {options?.isToBeApprovalMode && (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Workflow instructions" />
              <Field
                name={nameOf("WorkflowInstructions")}
                rows={3}
                component={CCTextArea}
                placeholder="Workflow instructions"
                readOnly
              />
            </div>
          </div>
        )}
      </section>

      <OfficerAndOfficerRegion
        formRenderProps={formRenderProps}
        nameOf={nameOf}
        isDisabled={options?.isReadOnly || options?.workflowDraftId}
      />

      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Applicant name" isMandatory={options?.isLLS} />
            <Field
              name={nameOf("ApplicantName")}
              placeholder={"Applicant name"}
              component={CCInput}
              validator={
                options?.isLLS && !options.isReadOnly
                  ? requiredValidator
                  : undefined
              }
              readOnly={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Reason for change" isMandatory />
            <Field
              name={nameOf("ReasonId")}
              placeholder="Reason for change"
              component={CCSearchComboBox}
              disabled={options?.isReadOnly}
              data={options?.workflowLOVs?.ReasonForCreate ?? []}
              textField="Name"
              dataItemKey="Code"
              isUseDefaultOnchange
              validator={!options.isReadOnly ? requiredValidator : undefined}
            />
          </div>
        </div>
        <div className="cc-field">
          <CCGrid
            columnFields={processedColumns}
            primaryField={nameOfAssessment("Assessment_Id")}
            data={getFieldValue("Holdings")}
          />
        </div>
      </section>
    </>
  );
};
