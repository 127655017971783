import { CORE_OFFICERS_ODATA } from "@app/core/components/common/constant";
import { IColumnFields } from "@components/cc-grid/model";
import InputPicker from "@components/input-picker/InputPicker";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import React from "react";

const ZoneColumns: IColumnFields[] = [
  { title: "Name", field: "Name" },
  { title: "Description", field: "Position" },
];

const zoneFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Zone",
  isMultiSelect: true,
  placeholder: "Zone",
  tableTypes: ZoneColumns,
  url: CORE_OFFICERS_ODATA,
  fieldName: "Zone",
  value: formRenderProps.valueGetter("Zone")?.join(", "),
  onChange: (event: CustomEvent) => {
    if (event.target !== null) {
      formRenderProps.onChange("Zone", {
        value: event.detail?.value,
      });
    } else {
      formRenderProps.onChange("Zone", {
        value: event.detail.map((cate: any) => cate.Name),
      });
    }
  },
});

const overLayFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Overlay",
  isMultiSelect: true,
  placeholder: "Overlay",
  tableTypes: ZoneColumns,
  url: CORE_OFFICERS_ODATA,
  fieldName: "Overlay",
  value: formRenderProps.valueGetter("Overlay")?.join(", "),
  onChange: (event: CustomEvent) => {
    if (event.target !== null) {
      formRenderProps.onChange("Overlay", {
        value: event.detail?.value,
      });
    } else {
      formRenderProps.onChange("Overlay", {
        value: event.detail.map((cate: any) => cate.Name),
      });
    }
  },
});

export const TabDetails = () => {
  return (
    <Form
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <div className="cc-form">
            <div className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field cc-col-span-2">
                  <label className="cc-label">Assess number</label>
                  <Input placeholder={"Assess number"} />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Assessment ID</label>
                  <Input placeholder={"Assessment ID"} />
                </div>

                <div className="cc-field cc-col-span-2">
                  <label className="cc-label">RIN</label>
                  <Input placeholder={"RIN"} />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Ward</label>
                  <Input placeholder={"Ward"} />
                </div>

                <div className="cc-field cc-col-span-2">
                  <label className="cc-label">Area</label>
                  <Input placeholder={"Area"} />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Parish</label>
                  <Input placeholder={"Parish"} />
                </div>

                <div className="cc-field cc-col-span-2">
                  <label className="cc-label">Crown allotment</label>
                  <Input placeholder={"Crown allotment"} />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Section</label>
                  <Input placeholder={"Section"} />
                </div>

                <div className="cc-field cc-col-span-2">
                  <label className="cc-label">Planning reference number</label>
                  <Input placeholder={"Planning reference number"} />
                </div>
                <div className="cc-field">
                  <label className="cc-label">LP/PS</label>
                  <Input placeholder={"LP/PS"} />
                </div>

                <div className="cc-field cc-col-span-2">
                  <label className="cc-label">Volume</label>
                  <Input placeholder={"Volume"} />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Folio</label>
                  <Input placeholder={"Folio"} />
                </div>

                <div className="cc-field cc-col-span-2">
                  <label className="cc-label">County</label>
                  <Input placeholder={"County"} />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Township</label>
                  <Input placeholder={"Township"} />
                </div>

                <div className="cc-field cc-col-span-2">
                  <label className="cc-label">Valuation number</label>
                  <Input placeholder={"Valuation number"} />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Certificate of title</label>
                  <Input placeholder={"Certificate of title"} />
                </div>

                <div className="cc-field cc-col-span-2">
                  <label className="cc-label">Parcel reference</label>
                  <Input placeholder={"Parcel reference"} />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Parcel ID</label>
                  <NumericTextBox placeholder={"Parcel ID"} />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Land size</label>
                  <NumericTextBox format="n2" placeholder={"Land size"} />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Block</label>
                  <Input placeholder={"Block"} />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Lot</label>
                  <Input placeholder={"Lot"} />
                </div>
              </div>
            </div>
            <div className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Zone</label>
                  <Field
                    component={InputPicker}
                    {...zoneFields(formRenderProps)}
                    name={"Zone"}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Overlay</label>
                  <Field
                    component={InputPicker}
                    {...overLayFields(formRenderProps)}
                    name={"OverLay"}
                  />
                </div>
                <div className="cc-field cc-col-span-3">
                  <label className="cc-label">Legal description</label>
                  <Input placeholder={"Legal description"} />
                </div>
              </div>
            </div>
          </div>
        </FormElement>
      )}
    />
  );
};
