import { CORE_OFFICERS_ODATA } from "@app/core/components/common/constant";

// @TODO: Need to integate later
export const MOCK_URL = "";

export const WastewaterUrl = {
  // Wastewater CRUD API
  CREATE_WASTEWATER: "",
  GET_WASTEWATER: "",
  UPDATE_WASTEWATER: "",
  DELETE_WASTEWATER: "",

  // Wastewater InputPicker ODATA
  GET_WASTEWATER_ISSUING_OFFICERS: CORE_OFFICERS_ODATA,
  GET_WASTEWATER_APPROVAL_OFFICERS: CORE_OFFICERS_ODATA,
  GET_WASTEWATER_RELATED:
    "/odata/wastewater/internal/wastewaterrelated/GetRelated(key={id})?$count=true&",
  GET_WASTEWATER_EVENTS:
    "/odata/wastewater/internal/wastewaterevents/GetEvents(key={id})?$count=true&",
  GET_WASTEWATER_CONDITIONS:
    "/odata/wastewater/internal/wastewaterconditions/GetConditions(key={id})?$count=true&",
  GET_WASTEWATER_SYSTEM_TYPE:
    "/odata/wastewater/internal/wastewatersystemtype?$count=true&",
  GET_WASTEWATER_ALL_SITEUSER:
    "/odata/core/internal/contacts/GetAllSiteUser()?$count=true&",
  GET_WASTEWATER_LIGTHWEIGHT_SITEUSER:
    "/odata/core/internal/contacts/GetLightWeightSiteUser(ID={id})?$count=true&",
  GET_WASTEWATER_VIEW_RELATED:
    "/odata/wastewater/internal/wastewaterviewrelated?$count=true&",
  GET_WASTEWATER_SCHEME_PICK_LIST:
    "/odata/wastewater/internal/wastewaterscheme/GetPickListWasteWaterScheme",

  // Wastewater View OData

  // Mock Wastewater View OData
  GET_VIEW_WASTEWATER_SYSTEMS_REGISTER: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMS_BYMANUFACTURER: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMS_BYMATERIAL: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMS_BYCAPACITY: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMS_BYLOCATION: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMS_BYRISKLEVEL: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMS_BYSTATUS: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMS_BYAUTHORITY: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMS_BYSYSTEMNAME: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMS_BYSYSTEMTYPE: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMS_BYPTINOTISSUED: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMS_ATUNOTISSUED: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMS_ARCHIVE: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMS_DECOMMISSONED: MOCK_URL,
  GET_VIEW_WASTEWATER_SCHEDULE_SERVICING: MOCK_URL,
  GET_VIEW_WASTEWATER_SCHEDULE_DESLUDGING: MOCK_URL,
  GET_VIEW_WASTEWATER_SCHEDULE_SAMPLES: MOCK_URL,
  GET_VIEW_WASTEWATER_SCHEDULE_INSPECTIONS: MOCK_URL,
  GET_VIEW_WASTEWATER_INSPECTIONS_BYDATE: MOCK_URL,
  GET_VIEW_WASTEWATER_INSPECTIONS_BYTYPE: MOCK_URL,
  GET_VIEW_WASTEWATER_INSPECTIONS_BYOFFICER: MOCK_URL,
  GET_VIEW_WASTEWATER_INSPECTIONS_BYMONTH: MOCK_URL,
  GET_VIEW_WASTEWATER_COMPLAINTS_BYREFNO: MOCK_URL,
  GET_VIEW_WASTEWATER_COMPLAINTS_BYCATEGORY: MOCK_URL,
  GET_VIEW_WASTEWATER_COMPLAINTS_BYSTATUS: MOCK_URL,
  GET_VIEW_WASTEWATER_NOTICES_BYDATE: MOCK_URL,
  GET_VIEW_WASTEWATER_NOTICES_BYDUESTATUS: MOCK_URL,
  GET_VIEW_WASTEWATER_FINANCE_OSFEES: MOCK_URL,
  GET_VIEW_WASTEWATER_FINANCE_ALLFEES: MOCK_URL,
  GET_VIEW_WASTEWATER_FINANCE_NOTSENTTOFINANCE: MOCK_URL,
  GET_VIEW_WASTEWATER_FINANCE_SENTTOFINANCE: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMADMIN_SYSTEMTYPES: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMADMIN_SETTINGS: MOCK_URL,
  GET_VIEW_WASTEWATER_SYSTEMADMIN_CONDITIONS: MOCK_URL,

  //WasteWaterSystemRegisterSummary
  GET_WASTEWATER_SUMMARY: "/api/wastewater/internal/registration/{id}/summary",
  GET_WASTEWATER_SUMMARY_LOV:
    "/api/wastewater/internal/registration/summarylovs",
  GET_WASTEWATER_SUMMARY_LITE:
    "/api/wastewater/internal/registration/{id}/detail",
  GET_CONTACTS_SITE_USER: "/api/contact/ext/get-system-type-grid",
  GET_CONTACTS_LIGHTWEIGHT_SITE_USER:
    "/api/core/internal/siteuser/ligthweight-sideuser/{id}",
};

export const OfficerColumns = [
  { title: "Name", field: "Name" },
  { title: "Position", field: "Position" },
  { title: "Email", field: "Email" },
  { title: "Phone", field: "Phone" },
];
