import { history } from "@/AppRoutes";
import { eventHandler, postCRMSEvent } from "@app/products/crms/[id]/api";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import {
  Event,
  EventHandlerRequest,
  EventUpdateTriggers,
  Svc_EventFormAction,
} from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { colGroupsAndUser } from "@app/products/crms/components/dialogs/special-interest/config";
import { validatorManualEntriesEmail } from "@app/products/crms/util";
import { isSuccessResponse } from "@common/apis/util";
import { useIsNew } from "@common/hooks/useIsNew";
import { AccessControl } from "@common/models/accessControl";
import { PickUserGroupOrgButton } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/buttons/pick-user-group-org/_index";
import { IDataUserGroupOrgForm } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/model";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { uniq } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";
export interface ISpecialInterestDialogProps {
  onClosePopup: () => void;
  eventForm: any;
}

export interface IInitialSpecialInterest {
  SpecialInterest_Email_ManualEntries: string;
}

const nameOfAccessControl = nameOfFactory<AccessControl>();

export const CrmsSpecialInterestDialog = observer(
  ({ onClosePopup, eventForm }: ISpecialInterestDialogProps) => {
    const {
      crmsEvent,
      reLoadCRMS,
      setIsShowSpecialInterestDialog,
      setNotification,
    } = useCRMSEventStore();
    const isNew = useIsNew();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const [initialValues, setInitialValues] =
      useState<IInitialSpecialInterest>();
    const [dataGrid, setDataGrid] = useState<AccessControl[]>([]);
    const [isLoadingGrid, setIsLoadingGrid] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<AccessControl[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [saveTrigger, setSaveTrigger] = useState<EventUpdateTriggers>();
    const [eventFormObj, setEventFormObj] = useState<Event>(eventForm);

    const renderManualEntries = (
      eventManualEntries: string,
      serviceStandardManualEntries: string
    ) => {
      if (serviceStandardManualEntries && eventManualEntries) {
        const serviceStandardManualEntriesArr =
          serviceStandardManualEntries.split(";");
        const eventManualEntriesArr = eventManualEntries.split(";");
        return uniq([
          ...serviceStandardManualEntriesArr,
          ...eventManualEntriesArr,
        ]).join(";");
      }

      if (serviceStandardManualEntries) return serviceStandardManualEntries;
      if (eventManualEntries) return eventManualEntries;
      return "";
    };

    const renderSpecialInterestDataGrid = (eventObj: Event) => {
      const svcStdSpecialInterestIDs =
        eventObj?.ServiceStandard?.SpecialInterest?.map(
          (item: AccessControl) => item.EntityID
        );
      const SpecialInterest =
        eventObj?.SpecialInterest?.map((item: AccessControl) => ({
          ...item,
          _FlagIsDisabled: svcStdSpecialInterestIDs?.includes(item.EntityID),
        })) ?? [];

      setDataGrid(SpecialInterest);
    };

    const handleSubmit = async (event: FormSubmitClickEvent) => {
      const data = event.values;
      appNotificationStore.clearNotifications();
      setIsLoading(true);

      //Handle Submit dialog
      const newCrmsEvent = {
        ...eventFormObj,
        SpecialInterest: dataGrid.map((item: any) => {
          delete item._FlagIsDisabled;
          return item;
        }),
        SpecialInterest_Email_ManualEntries: renderManualEntries(
          data?.SpecialInterest_Email_ManualEntries,
          eventFormObj.ServiceStandard?.SpecialInterest_Email_ManualEntries
        ),
        SaveTriggers: saveTrigger
          ? [EventUpdateTriggers.SaveEvent, saveTrigger]
          : [EventUpdateTriggers.SaveEvent],
      };
      const response = await postCRMSEvent(newCrmsEvent);
      setIsLoading(false);
      if (isSuccessResponse(response)) {
        setIsShowSpecialInterestDialog(false);
        if (isNew) {
          history.replace(CRMS_ROUTE + "/" + response?.data?.ID.toString(), {
            notification: [
              {
                title: "Record successfully saved.",
                type: "success",
              },
            ],
          });
        } else {
          reLoadCRMS(false);
          setNotification({
            title: "Record successfully saved.",
            type: "success",
          });
        }
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          type: "error",
          description:
            response.data?.Errors ?? response.statusText ?? "Saved failed.",
        });
      }
    };

    const handleAdd = async (value: IDataUserGroupOrgForm) => {
      setIsLoadingGrid(true);
      const groups = value?.Groups ?? [];
      const orgStructure = value?.OrgStructure ?? [];
      const users = value?.Users ?? [];
      const data = [...groups, ...orgStructure];

      let hierarchiesIds = data.map((item) => item.ID);
      const userIds = users.map((item) => item.ID);

      if (hierarchiesIds.length === 0 && userIds.length === 0) return;

      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickSpecialInterest,
        Event: eventFormObj,
        EventArgs: {
          SiteUsers: userIds ?? [],
          Hierarchies: hierarchiesIds ?? [],
        },
        isFirstTimeLoad: false,
      };
      const response = await eventHandler(params);
      setIsLoadingGrid(false);
      if (isSuccessResponse(response) && response.data) {
        const newEventObj = response.data.ReturnObj?.Event;
        renderSpecialInterestDataGrid(newEventObj);
        setSaveTrigger(EventUpdateTriggers.UpdateAccessControl);
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          type: "error",
          title: "Add failed.",
        });
      }
    };

    const handleRemove = async () => {
      const idsSelected = selectedRows.map(
        (item: AccessControl) => item.EntityID
      );
      if (idsSelected.length > 0) {
        const newDataGrid = dataGrid?.filter(
          (item: AccessControl) => !idsSelected.includes(item.EntityID)
        );
        setEventFormObj({
          ...eventFormObj,
          SpecialInterest: newDataGrid,
        } as Event);
        setDataGrid(newDataGrid);
        setSelectedRows([]);
        setSaveTrigger(EventUpdateTriggers.UpdateAccessControl);
      }
    };

    useEffectOnce(() => {
      if (crmsEvent) renderSpecialInterestDataGrid(crmsEvent);

      setInitialValues({
        SpecialInterest_Email_ManualEntries:
          crmsEvent?.SpecialInterest_Email_ManualEntries?.replaceAll(
            ",",
            ";"
          ) ?? "",
      });
    });

    return (
      <Form
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        onSubmitClick={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { valid, onSubmit } = formRenderProps;
          return (
            <CCDialog
              maxWidth="45%"
              height="auto"
              disabled={isLoading}
              titleHeader="Special Interest"
              onClose={onClosePopup}
              bodyElement={
                <FormElement className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <CCLabel title="Groups & Users" />
                        <CCGrid
                          canNotSelectField="_FlagIsDisabled"
                          data={dataGrid ?? []}
                          selectableMode={"multiple"}
                          columnFields={colGroupsAndUser}
                          isLoading={isLoadingGrid}
                          selectedRows={selectedRows}
                          primaryField={nameOfAccessControl("EntityID")}
                          onSelectionChange={(dataItem: AccessControl[]) => {
                            setSelectedRows(dataItem ?? []);
                          }}
                          state={{
                            sort: [
                              {
                                field: nameOfAccessControl("EntityDisplayName"),
                                dir: "asc",
                              },
                            ],
                          }}
                          toolbar={
                            <div className="cc-grid-tools-bar">
                              <PickUserGroupOrgButton
                                onSubmit={handleAdd}
                                maxHeight="85%"
                              />
                              <Button
                                type="button"
                                iconClass="fas fa-minus"
                                disabled={
                                  dataGrid.length === 0 ||
                                  selectedRows.length === 0
                                }
                                onClick={handleRemove}
                              />
                            </div>
                          }
                        />
                      </div>

                      <div className="cc-field">
                        <CCLabel title="Manual entries" />
                        <Field
                          name={"SpecialInterest_Email_ManualEntries"}
                          component={CCTextArea}
                          placeholder="Manual entries"
                          rows={3}
                          validator={validatorManualEntriesEmail}
                        />
                      </div>
                    </div>
                  </section>
                </FormElement>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    className={"cc-dialog-button"}
                    onClick={() => {
                      onClosePopup();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!valid}
                    themeColor="primary"
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    onClick={onSubmit}
                    className={"cc-dialog-button"}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
