import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const currentRoute: ICCRoute = {
  path: "current",
  name: "Current",
  enum: eMenuAction.Fire_Prevention_List_Current,
  children: [
    {
      path: "hazld-cases",
      name: "HazlD Cases ",
      enum: eMenuAction.Fire_Prevention_List_Current_HazIdCases,
      component: require("./hazld-cases/_index").default,
    },
    {
      path: "inspections",
      name: "Inspections",
      enum: eMenuAction.Fire_Prevention_List_Current_Inspections,
      component: require("./inspections/_index").default,
    },
    {
      path: "properties-to-re-inspect",
      name: "Properties to Re-inspect",
      enum: eMenuAction.Fire_Prevention_List_Current_PropertiesToReinspect,
      component: require("./properties-to-re-inspect/_index").default,
    },
    {
      path: "on-hold-cases",
      name: "On Hold Cases",
      enum: eMenuAction.Fire_Prevention_List_Current_OnHoldCases,
      component: require("./on-hold-cases/_index").default,
    },
    {
      path: "notices",
      name: "Notices",
      enum: eMenuAction.Fire_Prevention_List_Current_Notices,
      component: require("./notices/_index").default,
    },
    {
      path: "infringements",
      name: "Infringements",
      enum: eMenuAction.Fire_Prevention_List_Current_Infringements,
      component: require("./infringements/_index").default,
    },
    {
      path: "work-orders",
      name: "Work Orders",
      enum: eMenuAction.Fire_Prevention_List_Current_WorkOrders,
      component: require("./work-orders/_index").default,
    },
    {
      path: "assign-contractors",
      name: "Assign Contractors",
      enum: eMenuAction.Fire_Prevention_List_Current_AssignContractors,
      component: require("./assign-contractors/_index").default,
    },
    {
      path: "notify-contractors",
      name: "Notify Contractors",
      enum: eMenuAction.Fire_Prevention_List_Current_NotifyContractors,
      component: require("./notify-contractors/_index").default,
    },
    {
      path: "record-work-complete",
      name: "Record Work Complete",
      enum: eMenuAction.Fire_Prevention_List_Current_RecordWorkComplete,
      component: require("./record-work-complete/_index").default,
    },
  ],
};
