import React, { ReactElement } from "react";
import { getValueFieldOptions } from "./util";

export interface ICCValueFieldProps {
  name?: string;
  value?: any;
  label?: string;
  format?: string;
  className?: string;
  style?:
    | ((value?: any) => React.CSSProperties | undefined)
    | React.CSSProperties;
  tooltip?: ReactElement;
}

export const CCValueField: React.FC<ICCValueFieldProps> = ({
  name,
  label = "",
  value = undefined,
  format,
  className = "",
  style,
  tooltip,
  ...others
}) => {
  const options = getValueFieldOptions(format, value);
  let valueStyle: any =
    style && typeof style === "function" ? style(value) : style;

  return (
    <div className={`cc-field ${className}`}>
      <label className="cc-label">
        {label} {tooltip}
      </label>
      <div
        className="cc-label-field"
        style={{ ...options.style, ...valueStyle }}
      >
        {options.formatValue || ""}
      </div>
    </div>
  );
};
