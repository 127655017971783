import { IColumnFields } from "@components/cc-grid/model";
import { CriteriaAdvancedSearch } from "./model";

export const criteriaAdvancedSearchList = Object.values(
  CriteriaAdvancedSearch
).map((criteria: string) => ({
  Criteria: criteria,
  Key: criteria.replaceAll(" ", ""),
}));

export const colCriteriaNewSearch: IColumnFields[] = [
  {
    title: "Type of Search",
    field: "Criteria",
    width: "200",
  },
];
