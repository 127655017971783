import { OfficerAndOfficerRegion } from "@app/products/property/components/fields/officer-and-officer-region/_index";
import { useCreateTitleStore } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/store";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const DetailFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isDisabled: false,
      isToBeApprovalMode: false,
    },
  }: IFormStepElement) => {
    //store
    const { lovsCreateTitle } = useCreateTitleStore();

    return (
      <>
        <section className="cc-field-group">
          {options?.isToBeApprovalMode && (
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <Field
                  name={nameOf("Workflow instructions")}
                  rows={3}
                  component={CCTextArea}
                  placeholder="Workflow instructions"
                  readOnly
                />
              </div>
            </div>
          )}
          <div className="cc-field">
            <OfficerAndOfficerRegion
              formRenderProps={formRenderProps}
              nameOf={nameOf}
              isDisabled={options?.isDisabled}
            />
          </div>
        </section>
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Applicant name" isMandatory />
              <Field
                name={nameOf("ApplicantName")}
                component={CCInput}
                readOnly={options?.isReadOnly}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                placeholder="Applicant name"
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Reason for creation" isMandatory />
              <Field
                name={nameOf("ReasonForCreation")}
                component={CCSearchComboBox}
                data={lovsCreateTitle?.ReasonForCreation ?? []}
                textField="Name"
                dataItemKey="Code"
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                disabled={options?.isReadOnly}
                isUseDefaultOnchange
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Reference" isMandatory />
              <Field
                name={nameOf("Reference")}
                readOnly={options?.isReadOnly}
                component={CCInput}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                placeholder="Reference"
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Status" />
              <Field
                name={nameOf("Status")}
                component={CCSearchComboBox}
                data={convertValueLOVToNumber(
                  lovsCreateTitle?.Title_Status ?? []
                )}
                textField="Name"
                dataItemKey="Code"
                disabled={options?.isReadOnly}
                isUseDefaultOnchange
              />
            </div>
          </div>
        </section>
      </>
    );
  }
);
