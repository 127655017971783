import { AnimalsMenu } from "@app/products/animals/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const viewCrmsEventRoute: ICCRoute = {
  path: "view-crms-event",
  name: "Complaints",
  enum: AnimalsMenu.Complaints,
  children: [
    {
      path: "by-refno",
      name: "By RefNo",
      enum: AnimalsMenu.EventsByRefNo,
      component: require("./by-refno/_index").default,
    },
    {
      path: "by-category",
      name: "By Category",
      enum: AnimalsMenu.AllEventsByCategory,
      component: require("./by-category/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      enum: AnimalsMenu.AllEventsByStatus,
      component: require("./by-status/_index").default,
    },
  ],
};
