import { DTO_AssociatedItem_MasterProperty } from "@app/products/property/components/associations/components/master-property/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_MasterProperty>();

export const colAssociationsMasterProperty: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Address"),
    title: "Address",
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("NumberX"),
    title: "Assessment Number",
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("ValuationNumberX"),
    title: "Valuation Number",
  },
  {
    field: nameOf("EffectiveDate"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("LetterDate"),
    title: "Letter Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Id"),
    title: "Master Property ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
