import {
  DTO_SplitAssessment,
  DTO_Workflow_SplitAssessment,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const getNewSplitAssessmentWorkflow = async (
  input: DTO_SplitAssessment
): Promise<APIResponse<DTO_Workflow_SplitAssessment>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/splitassessment/new`,
      input
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSavedSplitAssessmentWorkflow = async (
  workflowDraftId?: number
): Promise<APIResponse<DTO_Workflow_SplitAssessment>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/splitassessment/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getInitialDataSplitAssessment = async (
  payload: DTO_SplitAssessment,
  workflowDraftId?: number
): Promise<APIResponse<DTO_Workflow_SplitAssessment>> => {
  try {
    return (await !isNil(workflowDraftId))
      ? getSavedSplitAssessmentWorkflow(workflowDraftId)
      : getNewSplitAssessmentWorkflow(payload);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessSplitAssessment = async (
  mode: WorkflowProcessMode,
  data: DTO_Workflow_SplitAssessment
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_SplitAssessment>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/splitassessment/process/${mode}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
