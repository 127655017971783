import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colCRSPortalPortalPayments: IColumnFields[] = [
  {
    field: CRSFieldMapping.ReceiptNumber,
    title: "Receipt Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: CRSFieldMapping.PaidAmount,
    title: "Paid Amount",
  },
  {
    field: CRSFieldMapping.ReceiptDate,
    title: "Receipt Date",
    format: DATE_FORMAT.DATE,
  },
  { field: CRSFieldMapping.AppNo, title: "Application Number" },
  {
    field: CRSFieldMapping.DebtorNo,
    title: "Debtor Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.PaymentInfo,
    title: "Payment Information",
  },
];
