import { Application } from "@app/products/town-planning/ppr/[id]/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import Loading from "@components/loading/Loading";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React, { useState } from "react";

interface ITownPlanningFormProps {
  formRenderProps: FormRenderProps;
  isDisable?: boolean;
}
const nameOf = nameOfFactory<Application>();
export const Decision = ({
  formRenderProps,
  isDisable,
}: ITownPlanningFormProps) => {
  const [isLoading] = useState(false);

  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">Amendment gazetted</label>
          <Field
            name={nameOf("PublishAmendmentDate")}
            component={CCDatePicker}
            format={DATE_FORMAT.DATE_CONTROL}
            disabled={isDisable}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Amendment number</label>
          <Field
            name={"PPRDetails.AmendmentNumber"}
            component={CCNumericTextBox}
            placeholder={"Amendment number"}
            format={NUMBER_FORMAT.NUMBER1}
            disabled={isDisable}
          />
        </div>
      </div>
    </section>
  );
};
