import { UITownPlanning } from "@app/products/town-planning/model";
import {
  Application_Decision_Number,
  IApproveDecisionDialogData,
} from "@app/products/town-planning/ppr/components/action-bar/dialogs/approve-decision/model";
import { ApproveDecisionDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/approve-decision/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putApproveReportAndResponse } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/approve-report-and-response/api";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const ApproveReportAndResponseButton = observer(() => {
  const { ppr, onSubmit, pprId, setPPRWithLoading } = usePPRStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.ApproveReport.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const optCount = useMemo(() => {
    let retVal = true;
    if (
      ppr?.PPRDetails?.ReferralTrigger != null &&
      ppr?.PPRDetails?.ReferralTrigger?.Name === "Section 52"
    )
      retVal = false;
    return retVal;
  }, [ppr]);

  const handleSubmit = async (
    decision: Application_Decision_Number,
    id?: number,
    decisionDate?: Date
  ) => {
    if (!id) return;
    setIsLoading(true);
    const validatedDecisionDate = decisionDate ? decisionDate : new Date();
    const response = await putApproveReportAndResponse(
      id,
      decision,
      validatedDecisionDate
    );
    setIsShowDialog(false);
    if (isSuccessIdentityPacket(response)) {
      setNotifications([]);
      clearNotifications();
      setPPRWithLoading(response.data?.Application);
      pushNotification({
        type: "success",
        title: "Application approved.",
      });
      if (
        response.data?.Notifications &&
        response.data?.Notifications.length > 0
      ) {
        pushNotification({
          autoClose: false,
          title: "Approve report and response",
          type: "warning",
          description: response.data?.Notifications,
        });
      }
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: "Approve report and response failed",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        },
      ]);
    }
    setIsLoading(false);
  };

  const isVisible = useMemo(() => {
    return Application_Status.ReadyForDecision === ppr?.Status_ENUM;
  }, [ppr]);

  return isVisible ? (
    <>
      <CCNavButton
        title="Approve report/response"
        onClick={onSubmit}
        isLoading={isLoading}
        name={PPRSubmitActions.ApproveReportAndResponse}
      />

      {isShowDialog && (
        <ApproveDecisionDialog
          isPSA={ppr?.Flag_IsPSA || false}
          optCount={optCount}
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={(value: IApproveDecisionDialogData) => {
            handleSubmit(
              value.Decision.Key as Application_Decision_Number,
              pprId,
              value.DecisionDate
            );
          }}
          isSubmitLoading={isLoading}
          notifications={notifications}
        />
      )}
    </>
  ) : null;
});
