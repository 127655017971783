import { getDisplayTextWithDashes } from "@common/utils/common";
import { getLabel } from "@app/products/property/components/label/config";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.Assessment_Id
    ? `- ${selectedRow.Assessment_Id}`
    : "";

  return `Property - ${getLabel.assessments()} ${dynamicDisplayName}`;
};

//@TODO Don't have Property_Name_Address_Locality data in grid mock data, recheck when integrated API
export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.Assessment_Number,
    selectedRow.Property_Name_Address_Locality,
  ]);
};
