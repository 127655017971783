import { useAnimalsKennelStore } from "@app/products/animals/kennels/[id]/store";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const AnimalsKennelMapsTab = observer(() => {
  const { animalsKennel } = useAnimalsKennelStore();
  return (
    <MapTab address={animalsKennel?.Address?.Formatted_SingleLine} zoom={18} />
  );
});
