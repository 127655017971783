import { WorkflowTypes } from "@app/products/property/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { useDeferredDutyTransactionStore } from "@app/products/property/deferred-duty/[id]/components/child-screens/transaction/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const TransferDeferredDutyAccountTransactionButton = observer(() => {
  const { listSelectedTransaction } = useDeferredDutyTransactionStore();
  const { setListDialog } = usePropertyWorkflowStore();
  return (
    <CCNavButton
      title="Transfer transaction"
      disabled={listSelectedTransaction.length !== 1}
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Journal_DD_Transfer_Transaction,
            data: {},
          },
        ]);
      }}
    />
  );
});
