import { VO_Land_Use } from "@app/products/property/components/dialogs/add-land-uses-dialog/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Land_Use>();
export const colSelectLandUses: IColumnFields[] = [
  {
    field: nameOf("Land_Use_Name"),
    title: "Name",
  },
  {
    field: nameOf("Land_Use_Code"),
    title: "Code",
  },
  {
    field: nameOf("Land_Use_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
