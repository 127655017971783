import { eventEmitter } from "@/App";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { deleteRebateClaim } from "@app/products/property/assessments/rebates/rebate-claims/components/action-bar/buttons/delete-claim/api";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const DeleteClaimButton = observer(() => {
  const { gridSelectedRows, clearSelectedItems } = useCCProductListViewStore();
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleOnDelete = async (data: IDeleteConfirmation) => {
    const id: number = gridSelectedRows?.[0].Rebate_Claim_Id;
    if (!id) return;
    setIsDeleting(true);
    const comment = {
      ID: 0,
      RecordedDate: null,
      RecordedBy: "",
      Comment: data?.Reason ?? "",
      Title: "",
      BubbleUps: [],
      workFlowDraftID: null,
    };
    const response = await deleteRebateClaim(id, comment);
    setIsDeleting(false);
    clearErrorNotification();
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      //Close confirmation dialog
      setShowConfirmDeleteDialog(false);
      //Refresh data in list view and journal tab
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      clearSelectedItems();
      //Notify
      pushNotification({
        type: "success",
        title:
          response?.data?.SuccessMessage ?? "Rebate claim deleted successfully",
      });
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: "Delete rebate claim failed",
          type: "error",
          description: response.data?.Errors,
        },
      ]);
    }
  };

  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
        privilegesEnum={EPrivilegesWorkflow.Rebate_Delete_Rebate_Claim}
      >
        <CCNavButton
          title="Delete claim"
          disabled={
            gridSelectedRows?.length !== 1 ||
            gridSelectedRows?.[0].RP_Is_Deleted
          }
          isLoading={isDeleting}
          onClick={() => {
            setShowConfirmDeleteDialog(true);
          }}
        />
      </PrivilegeWrapper>
      {showConfirmDeleteDialog && (
        <ConfirmDelete
          onClose={() => setShowConfirmDeleteDialog(false)}
          handleSubmit={handleOnDelete}
          isDeleting={isDeleting}
          header="Confirm Deletion"
          notifications={notifications}
          message="Are you sure you wish to delete the rebate claim?"
        />
      )}
    </>
  );
});
