export const mockNotices = [
  {
    NoticeId: 210002,
    NoticeType: "Fire Prevention Notice",
    CaseId: 21003,
    Owners: "CJ Scolt",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Locality: "West Hobart",
    HouseNumber: "39",
    Street: "Hamiton",
    ParcelId: 107020,
    AssessmentNumber: "1813126",
    InspectionDate: new Date("5-Oct-2018 13:30"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 32,
    NoticeType: "Fire Prevention Notice",
    CaseId: 33,
    Owners: "JA Dunning",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("30-Oct-2018"),
    Locality: "Dynnyrne",
    HouseNumber: "19",
    Street: "Zomay",
    ParcelId: 108750,
    AssessmentNumber: "1842808",
    InspectionDate: new Date("5-Oct-2018 13:12"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 31,
    NoticeType: "Fire Prevention Notice",
    CaseId: 33,
    Owners: "JA Dunning",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Locality: "Dynnyrne",
    HouseNumber: "19",
    Street: "Zomay",
    ParcelId: 108750,
    AssessmentNumber: "1842808",
    InspectionDate: new Date("5-Oct-2018 13:09"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 30,
    NoticeType: "Fire Prevention Notice",
    CaseId: 32,
    Owners: "D A Potter",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Locality: "Dynnyrne",
    HouseNumber: "11",
    Street: "Baden",
    ParcelId: 192915,
    AssessmentNumber: "5652761",
    InspectionDate: new Date("5-Oct-2018 12:52"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 29,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("28-Sep-2018 10:28"),
    SentBy: "wisem",
    MailDate: new Date("28-Sep-2018"),
    CaseId: 31,
    Owners: "D R N Linacre",
    Clearance: "Remove All Undergrowth",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("27-Sep-2018"),
    Locality: "Sandy Bay",
    HouseNumber: "2",
    Street: "Alwyn",
    ParcelId: 169190,
    AssessmentNumber: "5601200",
    InspectionDate: new Date("23-Sep-2018 10:19"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 28,
    NoticeType: "Failure to Comply letter",
    CaseId: 30,
    Owners: "C L Nichols & R Nichols",
    Clearance: "Clear Entire Property",
    Outcome: "Not Cleared. Set Contractor to clear",
    ToBeClearedBy: new Date("27-Sep-2018"),
    Locality: "Lenal Valley",
    HouseNumber: "29",
    Street: "Alwyn",
    ParcelId: 235020,
    AssessmentNumber: "2657831",
    InspectionDate: new Date("27-Sep-2018 14:38"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 27,
    NoticeType: "Fire Prevention Notice",
    CaseId: 30,
    Owners: "C L Nichols & R Nichols",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("27-Sep-2018"),
    Locality: "Lenal Valley",
    HouseNumber: "29",
    Street: "Anelia",
    ParcelId: 235020,
    AssessmentNumber: "2657831",
    InspectionDate: new Date("27-Sep-2018 14:35"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 26,
    NoticeType: "Fire Prevention Notice",
    CaseId: 29,
    Owners: "S W Wood & L Wood",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("27-Sep-2018"),
    Locality: "West Hobart",
    HouseNumber: "4",
    NoticeReference: "26",
    Street: "Alcides",
    ParcelId: 140145,
    AssessmentNumber: "5537392",
    InspectionDate: new Date("27-Sep-2018 14:22"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 25,
    NoticeType: "Fire Prevention Notice",
    CaseId: 28,
    Owners: "M J Zawadzki & Z Zawadzki",
    Clearance: "Clear Entire Property",
    Outcome: "Not Cleared. Set Contractor to clear",
    ToBeClearedBy: new Date("27-Sep-2018"),
    Locality: "Lenal Valley",
    HouseNumber: "25",
    Street: "Alcides",
    ParcelId: 112965,
    AssessmentNumber: "5478388",
    InspectionDate: new Date("27-Sep-2018 14:17"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 24,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("27-Sep-2018 14:15"),
    SentBy: "wisem",
    MailDate: new Date("27-Sep-2018"),
    CaseId: 28,
    Owners: "M J Zawadzki & Z Zawadzki",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("27-Sep-2018"),
    Locality: "Lenal Valley",
    HouseNumber: "25",
    Street: "Alcides",
    ParcelId: 112965,
    AssessmentNumber: "5478388",
    InspectionDate: new Date("27-Sep-2018 14:13"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 23,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("19-Sep-2018 13:21"),
    SentBy: "wisem",
    MailDate: new Date("19-Sep-2018"),
    CaseId: 27,
    Owners: "A Cranenburgh",
    Clearance: "10m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Locality: "South Hobart",
    HouseNumber: "602",
    Street: "Huon",
    ParcelId: 109715,
    AssessmentNumber: "1879213",
    InspectionDate: new Date("19-Sep2018 13:17"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 22,
    NoticeType: "Fire Prevention Notice",
    CaseId: 26,
    Owners: "K F Doran",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("12-Sep-2018"),
    Locality: "Mount Stuart",
    HouseNumber: "2",
    Street: "Abermere",
    ParcelId: 112855,
    AssessmentNumber: "5478142",
    InspectionDate: new Date("12-Sep-2018 14:20"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 21,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("10-Sep-2018 16:05"),
    SentBy: "wisem",
    MailDate: new Date("10-Sep-2018"),
    CaseId: 22,
    Owners: "L Zheng",
    Clearance: "Remove All Undergrowth",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("9-Sep-2018"),
    Locality: "Sandy Bay",
    HouseNumber: "254",
    Street: "Churchill",
    ParcelId: 171300,
    AssessmentNumber: "5605797",
    InspectionDate: new Date("10-Sep-2018 15:16"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 20,
    NoticeType: "Fire Prevention Notice",
    CaseId: 19,
    Owners: "D K McKey",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("4-Aug-2018"),
    SpecificInstructions: "Test...",
    Locality: "West Hobart",
    HouseNumber: "4",
    Street: "Stevents Farm",
    ParcelId: 253925,
    AssessmentNumber: "3283878",
    InspectionDate: new Date("3-Sep-2018 14:34"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 19,
    NoticeType: "Fire Prevention Notice",
    CaseId: 18,
    Owners: "N M Tierner",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Locality: "Lenal Valley",
    HouseNumber: "33",
    Street: "Alcides",
    ParcelId: 112945,
    AssessmentNumber: "1228154",
    InspectionDate: new Date("3-Sep-2018 11:03"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 18,
    NoticeType: "Fire Prevention Notice",
    CaseId: 17,
    Owners: "B G Badger",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Locality: "Glebe",
    HouseNumber: "20",
    Street: "Aberdeen",
    ParcelId: 100135,
    AssessmentNumber: "5478679",
    InspectionDate: new Date("3-Sep-2018 09:07"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 17,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("3-Sep-2018 09:10"),
    SentBy: "wisem",
    MailDate: new Date("3-Sep-2018"),
    CaseId: 16,
    Owners: "M Broadby",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Locality: "Town",
    HouseNumber: "1",
    Street: "Alfred",
    ParcelId: 113095,
    AssessmentNumber: "5599606",
    InspectionDate: new Date("2-Sep-2018 15:58"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 16,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("4-Sep-2018 14:54"),
    SentBy: "wisem",
    MailDate: new Date("4-Sep-2018"),
    CaseId: 15,
    Owners: "R Newton",
    Clearance: "Remove All Undergrowth",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Locality: "Sandy Bay",
    HouseNumber: "4",
    Street: "Adams",
    ParcelId: 168445,
    AssessmentNumber: "5588931",
    InspectionDate: new Date("2-Sep-2018"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 15,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("12-Sep-2018 14:15"),
    SentBy: "wisem",
    MailDate: new Date("12-Sep-2018"),
    CaseId: 14,
    Owners: "C Hawkins",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Locality: "Ridgeway",
    HouseNumber: "3",
    Street: "Ridgeway",
    ParcelId: 163675,
    AssessmentNumber: "3378113",
    InspectionDate: new Date("31-Aug-2018 15:19"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 14,
    NoticeType: "Fire Prevention Notice",
    CaseId: 13,
    Owners: "S W Hennessy & C J E Hennessy  ",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    SpecificInstructions: "Specific instructions",
    Locality: "West Hobart",
    HouseNumber: "9",
    Street: "Stevents Farm",
    ParcelId: 255050,
    AssessmentNumber: "3067614",
    InspectionDate: new Date("30-Aug-2018 00:00"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 13,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("31-Aug-2018 15:37"),
    SentBy: "wisem",
    MailDate: new Date("31-Aug-2018"),
    CaseId: 12,
    Owners: "D M Curtano",
    Clearance: "20m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Sep-2018"),
    SpecificInstructions: "Slash long",
    Locality: "Dynnyrne",
    HouseNumber: "178",
    Street: "Waterworks",
    ParcelId: 252510,
    AssessmentNumber: "3007670",
    InspectionDate: new Date("30-Aug-2018 11:43"),
    InspectorUserId: "watchorn",
    Period: "2018/2019",
  },
  {
    NoticeId: 12,
    NoticeType: "Fire Prevention Notice",
    CaseId: 11,
    Owners: "M J Gray",
    Clearance: "Remove All Undergrowth",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("30-Aug-2018"),
    Locality: "New Town",
    HouseNumber: "57",
    Street: "Montagu",
    ParcelId: 128385,
    InspectionDate: new Date("30-Aug-2018 10:44"),
    InspectorUserId: "graym",
    Period: "2018/2019",
  },
  {
    NoticeId: 11,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("4-Sep-2018 15:37"),
    SentBy: "wisem",
    MailDate: new Date("4-Sep-2018"),
    CaseId: 10,
    Owners: "University Of Tasmania",
    Clearance: "30m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("10-Aug-2018"),
    SpecificInstructions: "overgrown",
    Locality: "Hobart",
    HouseNumber: "12-16",
    Street: "Bathagu",
    ParcelId: 194430,
    AssessmentNumber: "5582644",
    InspectionDate: new Date("30-Aug-2018 10:32"),
    InspectorUserId: "kleineb",
    Period: "2018/2019",
  },
  {
    NoticeId: 9,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("3-Sep-2018 10:27"),
    SentBy: "wisem",
    MailDate: new Date("3-Sep-2018"),
    CaseId: 8,
    Owners: "J M Allen & A Allen",
    Clearance: "10m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("20-Sep-2018"),
    SpecificInstructions: "please clear",
    Locality: "South Hobart",
    HouseNumber: "3",
    Street: "Lawley",
    ParcelId: 160760,
    AssessmentNumber: "5579920",
    InspectionDate: new Date("30-Aug-2018 10:35"),
    InspectorUserId: "mackayi",
    Period: "2018/2019",
  },
  {
    NoticeId: 8,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("3-Sep-2018 13:32"),
    SentBy: "wisem",
    MailDate: new Date("3-Sep-2018"),
    CaseId: 7,
    Owners: "S D Johnson",
    Clearance: "No Hazzard",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("27-Oct-2018"),
    SpecificInstructions: "establish",
    Locality: "Fern Tree",
    HouseNumber: "791",
    Street: "Huon",
    ParcelId: 159455,
    AssessmentNumber: "5579920",
    InspectionDate: new Date("30-Aug-2018 10:13"),
    InspectorUserId: "watchorn",
    Period: "2018/2019",
  },
  {
    NoticeId: 7,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("3-Sep-2018 10:47"),
    SentBy: "wisem",
    MailDate: new Date("3-Sep-2018"),
    CaseId: 7,
    Owners: "S D Johnson",
    Clearance: "30m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("27-Oct-2018"),
    Locality: "Fern Tree",
    HouseNumber: "791",
    Street: "Huon",
    ParcelId: 159455,
    AssessmentNumber: "1854438",
    InspectionDate: new Date("30-Aug-2018 10:27"),
    InspectorUserId: "watchorn",
    Period: "2018/2019",
  },
  {
    NoticeId: 5,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("30-Aug-2018 10:18"),
    SentBy: "hoskir",
    MailDate: new Date("30-Aug-2018"),
    CaseId: 5,
    Owners: "D Reinbach & T Reinbach",
    Clearance: "10m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("10-Aug-2018"),
    Locality: "Glebe",
    HouseNumber: "9",
    Street: "Lilie",
    ParcelId: 109140,
    AssessmentNumber: "5575057",
    InspectionDate: new Date("30-Aug-2018 10:10"),
    InspectorUserId: "hosking",
    Period: "2018/2019",
  },
  {
    NoticeId: 6,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("30-Aug-2018 10:18"),
    SentBy: "hoskir",
    MailDate: new Date("30-Aug-2018"),
    CaseId: 6,
    Owners: "C L Dergess",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("18-Aug-2018"),
    Locality: "Fern Tree",
    HouseNumber: "4",
    Street: "Stephenson",
    ParcelId: 157265,
    InspectionDate: new Date("30-Aug2018 10:10"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 4,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("30-Aug-2018 10:18"),
    SentBy: "hoskir",
    MailDate: new Date("30-Aug-2018"),
    CaseId: 4,
    Owners: "D C Mackie",
    Clearance: "5m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("25-Jul-2018"),
    Locality: "Fern Tree",
    HouseNumber: "14",
    Street: "Grays",
    ParcelId: 157810,
    AssessmentNumber: "5576260",
    InspectionDate: new Date("14-Aug-2018 14:05"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
  {
    NoticeId: 3,
    NoticeType: "Fire Prevention Notice",
    SentOn: new Date("30-Aug-2018 10:18"),
    SentBy: "hoskir",
    MailDate: new Date("30-Aug-2018"),
    CaseId: 3,
    Owners: "G R Pearce & M J Pearce &",
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("27-Oct-2018"),
    SpecificInstructions: "Use a cate",
    Locality: "West Hobart",
    HouseNumber: "7",
    Street: "Stevents Farm",
    ParcelId: 253940,
    AssessmentNumber: "3283907",
    InspectionDate: new Date("10-Aug-2018 13:27"),
    InspectorUserId: "wisem",
    Period: "2018/2019",
  },
];
