import AmsRootStore from "../ams-root-store";
import InspectionAssessmentUi from "./inspections-assessments/store";

export default class DataStore {
  //pointer back to the root store incase we need to pass it down at all to other stores that might need to cross cut and get other data. eg work order might need hit lookups one day.
  rootStore: AmsRootStore;

  inspectionAssessmentUi: InspectionAssessmentUi;

  //once converted add catalogue-store to here

  constructor(rootStore: AmsRootStore) {
    this.rootStore = rootStore;
    this.inspectionAssessmentUi = new InspectionAssessmentUi();
  }
}
