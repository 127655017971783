import { DTO_AssociatedItem_Parcel } from "@app/products/property/components/associations/components/parcel/model";
import { PROPERTY_PARCEL_ROUTE } from "@app/products/property/parcels/[id]/constant";
import {
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_Parcel>();

export const colAssociationsParcel: IColumnFields[] = [
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
    linkTo: (dataItem: DTO_AssociatedItem_Parcel) =>
      `${PROPERTY_PARCEL_ROUTE}/${dataItem.Id}`,
  },
  {
    field: nameOf("ParcelReference"),
    title: "Parcel Reference",
  },
  {
    field: nameOf("PropertyName"),
    title: "Property Name",
  },
  {
    field: nameOf("PropertyName_Address_Locality"),
    title: "Property Address",
  },
  {
    field: nameOf("Factor"),
    title: "Factor",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("FactorPercentage"),
    title: "Factor %",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("CensusDistrict"),
    title: "Census District",
  },
  {
    field: nameOf("ElectoralDivision"),
    title: "Electoral Division",
  },
  {
    field: nameOf("StateElectoralDivision"),
    title: "State Code Electoral Division",
  },
  {
    field: nameOf("FederalElectoralDivision"),
    title: "Federal Electoral Division",
  },
  {
    field: nameOf("LandArea"),
    title: "Land Area",
  },
  {
    field: nameOf("Zones"),
    title: "Zones",
  },
  {
    field: nameOf("LandUses"),
    title: "Land Uses",
  },
  {
    field: nameOf("OwnersNames"),
    title: "Owners Names",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("LocationDescriptor"),
    title: "Location Descriptor",
  },
  {
    field: nameOf("MapNumber"),
    title: "Map Number",
  },
  {
    field: nameOf("MapReference"),
    title: "Map Reference",
  },
  {
    field: nameOf("ExcludedArea"),
    title: "Excluded Area",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("LandCategory"),
    title: "Land Category",
  },
  {
    field: nameOf("Id"),
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
