const ACTIVE = "active";
const IDLE = "idle";
const IDLE_TIMER_STORE = "idleTimer";

const DEFAULT_INITIAL_STATE = ACTIVE;

const DEFAULT_ACTIVITY_EVENTS = [
  "click",
  "mousemove",
  "keydown",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "focus",
];

const DEFAULT_INACTIVITY_EVENTS = ["blur", "visibilitychange"];

const DEFAULT_IGNORED_EVENTS_WHEN_IDLE = ["mousemove"];

let hidden: string, visibilityChangeEvent: string;
if (typeof document.hidden !== "undefined") {
  hidden = "hidden";
  visibilityChangeEvent = "visibilitychange";
} else {
  const prefixes = ["webkit", "moz", "ms"];
  for (let i = 0; i < prefixes.length; i++) {
    const prefix = prefixes[i];
    if (typeof (document as any)[`${prefix}Hidden`] !== "undefined") {
      hidden = `${prefix}Hidden`;
      visibilityChangeEvent = `${prefix}visibilitychange`;
      break;
    }
  }
}

/**
 * Creates an activity detector instance
 *
 * @param  {Object}   options
 * @param  {string[]} options.activityEvents        Events which force a transition to 'active'
 * @param  {string[]} options.inactivityEvents      Events which force a transition to 'idle'
 * @param  {string[]} options.ignoredEventsWhenIdle Events that are ignored in 'idle' state
 * @param  {number}   options.timeToIdle            Inactivity time in ms to transition to 'idle'
 * @param  {string}   options.initialState          One of 'active' or 'idle'
 * @param  {boolean}  options.autoInit
 * @return {Object}   activity detector instance
 */
export const createActivityDetector = ({
  activityEvents = DEFAULT_ACTIVITY_EVENTS,
  inactivityEvents = DEFAULT_INACTIVITY_EVENTS,
  ignoredEventsWhenIdle = DEFAULT_IGNORED_EVENTS_WHEN_IDLE,
  timeToIdle = 30000,
  initialState = DEFAULT_INITIAL_STATE,
  autoInit = true,
} = {}) => {
  const listeners = { [ACTIVE]: [], [IDLE]: [] };
  let state: string;
  let timer: any;
  const DELAY_TIME = 100;

  const setState = (newState: string) => {
    clearInterval(timer);
    if (newState === ACTIVE) {
      setIdleTimer(0);
      timer = setInterval(() => {
        const newTime = getIdleTimer() + DELAY_TIME;
        setIdleTimer(newTime);
        if (newTime >= timeToIdle) setState(IDLE);
      }, DELAY_TIME);
    }
    if (state !== newState) {
      state = newState;
      (listeners as any)[state].forEach((l: any) => l());
    }
  };

  const handleUserActivityEvent = (event: any) => {
    if (state === ACTIVE || ignoredEventsWhenIdle.indexOf(event.type) < 0) {
      setState(ACTIVE);
    }
  };

  const handleUserInactivityEvent = () => {
    setState(IDLE);
  };

  const handleVisibilityChangeEvent = () => {
    setState((document as any)[hidden] ? IDLE : ACTIVE);
  };

  /**
   * Starts the activity detector with the given state.
   * @param {string} firstState 'idle' or 'active'
   */
  const init = (firstState = DEFAULT_INITIAL_STATE) => {
    setState(firstState === ACTIVE ? ACTIVE : IDLE);
    activityEvents.forEach((eventName) =>
      window.addEventListener(eventName, handleUserActivityEvent)
    );

    inactivityEvents
      .filter((eventName) => eventName !== "visibilitychange")
      .forEach((eventName) =>
        window.addEventListener(eventName, handleUserInactivityEvent)
      );

    if (
      inactivityEvents.indexOf("visibilitychange") >= 0 &&
      visibilityChangeEvent
    ) {
      document.addEventListener(
        visibilityChangeEvent,
        handleVisibilityChangeEvent
      );
    }
  };

  /**
   * Register an event listener for the required event
   * @param {string} eventName 'active' or 'idle'
   * @param {Function} listener
   */
  const on = (eventName: string, listener: any) => {
    (listeners as any)[eventName].push(listener);
    const off = () => {
      const index = (listeners as any)[eventName].indexOf(listener);
      if (index >= 0) {
        (listeners as any)[eventName].splice(index, 1);
      }
    };
    return off;
  };

  /**
   * Stops the activity detector and clean the listeners
   */
  const stop = () => {
    listeners[ACTIVE] = [];
    listeners[IDLE] = [];

    clearTimeout(timer);

    activityEvents.forEach((eventName) =>
      window.removeEventListener(eventName, handleUserActivityEvent)
    );

    inactivityEvents.forEach((eventName) =>
      window.removeEventListener(eventName, handleUserInactivityEvent)
    );

    if (visibilityChangeEvent) {
      document.removeEventListener(
        visibilityChangeEvent,
        handleVisibilityChangeEvent
      );
    }
  };

  if (autoInit) {
    init(initialState);
  }

  return { on, stop, init };
};

export const setIdleTimer = (timer: number) => {
  if (timer === undefined) return localStorage.removeItem(IDLE_TIMER_STORE);
  localStorage.setItem(IDLE_TIMER_STORE, timer.toString());
};
export const getIdleTimer = () => {
  const timer: string | null = localStorage.getItem(IDLE_TIMER_STORE);
  if (!timer) return 0;
  return parseInt(timer);
};
