import { IBuildingRegistersCouncilConsentFormData } from "@app/products/building/registers/council-consent/[id]/model";

export const mockSingleBuildingRegistersCouncilConsent: IBuildingRegistersCouncilConsentFormData =
  {
    ID: 1,
    RegisterNumber: 8778,
    Contact: "Daniel M Green",
    Contact_Address: "4245  Paul Wayne Haggerty Road, Youngstown, Ohio",
    Contact_Contact: "danielgreen@hotmail.net",
    Address: "4166  Stoney Lonesome Road, Salt Lake City, Utah",
    Owner: "James K Baker",
    Details: "3288  Dancing Dove Lane, Whitestone, New York",
    Description:
      "I think that I am a responsible and honest boy/girl who wants to do things successfully. I am punctual towards my work and do it before time. I believe that mutual cooperation is a way to success and like to help people whenever they seek my help. I am an average student and like to read books and play chess.",
    Officer: "Andrew Thoma",
    AppNo: 15222,
    FileNumber: 623,
    Lodged: new Date("2020-08-14T18:57:20.898Z"),
    Regulations: "Construction Equipment",
    CouncilConsent: "Minister",
    OutstandingFees: 1565,
    DebtorNumber: "16622",
    CostOfWorks: "Class 3",
    Status: "Active",
    StatDays: 152,
    CreatedDate: new Date(
      "Thur Jan 05 2020 14:09:46 GMT+0700 (Indochina Time)"
    ),
    LodgedDate: new Date("Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)"),
    ClockStart: new Date("Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)"),
  };
