import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadCRS } from "./api";
import { CRSResponse } from "./model";

export const CrsDetailsTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [crsInfo, setCrsInfo] = useState<CRSResponse | undefined>();
  const productListViewStore = useCCProductListViewStore();
  const { lastSelectedRow } = productListViewStore;
  const params: { id: string } = useParams();
  const crsId = params.id;
  useEffect(() => {
    if (lastSelectedRow?.ID || crsId) {
      const ID: any = lastSelectedRow?.ID || crsId;
      setIsLoading(true);
      loadCRS(ID).then((data) => {
        setCrsInfo(data);
        setIsLoading(false);
      });
    }
  }, [lastSelectedRow, crsId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!crsInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Application Number:"}
        value={crsInfo.ApplicationNumber}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Date of Brith:"}
          value={crsInfo.DateOfBrith}
          success
        />
        <ProductReferenceRow
          title={"Lodgement Date:"}
          value={crsInfo.LodgementDate}
        />
        <ProductReferenceRow
          badge
          primary
          value="Registered for 4yo kindergarten."
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
