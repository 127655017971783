import { eventEmitter } from "@/App";
import { MailMergeDialog } from "@app/core/new-mail-merge/dialogs/_index";
import {
  MAILMERGEDATASET,
  MAILMERGESAVEFORMAT,
  MailMerge_SelectMergeDocument,
  MailMerge_SelectMergeDocument_Ext,
} from "@app/core/new-mail-merge/dialogs/model";
import { setUpMailMergeFromView } from "@app/core/new-mail-merge/dialogs/util";
import { postCRMSStandardDocument } from "@app/products/crms/service-standards/[id]/components/forms/components/action-bar/buttons/standard-document/api";
import { isSuccessIdentityPacket } from "@common/apis/util";

import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface IStandardDocumentButtonProps {
  isMultiple?: boolean;
  recordType: RECORDTYPE;
  productType: PRODUCT_TYPE_NUMBER;
  dataSetFilter: MAILMERGEDATASET;
  titleButton?: string;
  isVisible?: boolean;
  isDisabled?: boolean;
  initStandardDocumentData?: Partial<MailMerge_SelectMergeDocument>;
  serviceStandardID?: number;
}

export const StandardDocumentButton = observer(
  ({
    productType,
    dataSetFilter,
    isMultiple = true,
    titleButton = "Add standard document",
    isVisible = true,
    isDisabled = false,
    initStandardDocumentData,
    serviceStandardID,
  }: IStandardDocumentButtonProps) => {
    const { pushNotification } = useCCAppNotificationStore();

    const [showMailMergeDialog, setShowMergeDialog] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [initialStandardDocument, setInitialStandardDocument] =
      useState<MailMerge_SelectMergeDocument_Ext>();

    const handleOnClick = () => {
      const standardDocument = setUpMailMergeFromView();
      standardDocument.Dataset_Filters.push(dataSetFilter);

      setInitialStandardDocument({
        ...standardDocument,
        ...initStandardDocumentData,
        _options: {
          SaveAsPDF: standardDocument.SaveFormat === MAILMERGESAVEFORMAT.Pdf,
        },
      });
      setShowMergeDialog(true);
    };

    const handleOnSubmit = async (
      mailMerge: MailMerge_SelectMergeDocument_Ext
    ) => {
      if (!serviceStandardID) return;

      setIsSubmitting(true);
      const response = await postCRMSStandardDocument(
        serviceStandardID,
        mailMerge.SelectedDocumentIDs
      );
      setIsSubmitting(false);
      setShowMergeDialog(false);
      if (isSuccessIdentityPacket(response)) {
        eventEmitter.emit(CCGridEventType.RefreshOData);
        pushNotification({
          title: "Add standard document successfully.",
          type: "success",
        });
      } else {
        pushNotification({
          autoClose: false,
          title:
            response?.error ??
            response?.data?.Errors ??
            "Add standard document failed.",
          type: "error",
        });
      }
    };

    if (!isVisible) return null;

    return (
      <>
        <CCNavButton
          title={titleButton}
          disabled={isDisabled}
          onClick={handleOnClick}
        />
        {showMailMergeDialog && (
          <MailMergeDialog
            isSubmitting={isSubmitting}
            initialMailMerge={initialStandardDocument}
            onClose={() => {
              setShowMergeDialog(false);
            }}
            onSubmit={(mailMerge: MailMerge_SelectMergeDocument_Ext) =>
              handleOnSubmit(mailMerge)
            }
            productType={productType}
            isMultiple={isMultiple}
          />
        )}
      </>
    );
  }
);
