import { DTO_WorkflowHeader } from "@app/products/property/model";

export const defaultWorkflowHeaderCompleteSupplementary: DTO_WorkflowHeader = {
  WorkflowDraft: {
    Workflow_Draft_Id: 0,
    WD_Workflow_Status: 21,
  },
  WorkflowApprovals: [],
  AvailableSecondaryWorkflows: [],
};
