import {
  IAssessmentGroup,
  IAssessmentQuestion,
  responseCode,
} from "@app/products/ams/model/store";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { useObserver } from "mobx-react-lite";
import React from "react";

interface IProps {
  questionCfg: IAssessmentQuestion;
  parentGroup: IAssessmentGroup;
  isDragging: boolean;
}

interface radioButtonDataItem {
  label: string;
  value: responseCode;
}

const InspectionQuestionEditor: React.FC<IProps> = ({
  questionCfg,
  parentGroup,
  isDragging,
}) => {
  const data: radioButtonDataItem[] = [];
  questionCfg.allowedResponses.forEach((r) => {
    data.push({ label: r, value: r });
  });

  const responses = [
    "Complies",
    "Not Observed",
    "Not Applicable",
    "Serious Non-Compliance",
    "Satisfactory",
    "Major",
    "Minor",
    "Yes",
    "No",
  ];

  return useObserver(() => {
    const firstQuestionNumber = parentGroup.sortedQuestions[0].questionNo;
    return (
      <div style={{ opacity: isDragging ? 0.4 : 1, clear: "both" }}>
        <div
          className={
            questionCfg.questionNo === firstQuestionNumber ? "row" : "row mt-2"
          }
        >
          <div className="col-1">
            <label>Heading</label>
          </div>
          <div className="col-7">
            {/*add change event*/}
            <CCTextArea
              rows={2}
              name="Heading"
              value={questionCfg.questionHeading}
              onChange={(e) => questionCfg.update({ questionHeading: e.value })}
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-1">
            <label>Question</label>
          </div>
          <div className="col-7">
            <CCTextArea
              rows={2}
              className="cc-form-control"
              name="Question"
              value={questionCfg.questionDetail}
              onChange={(e) => questionCfg.update({ questionDetail: e.value })}
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-1">
            <label>Answers</label>
          </div>
          <div className="col-auto">
            <MultiSelect
              name="A1"
              data={responses}
              value={questionCfg.allowedResponses}
              onChange={(e) =>
                questionCfg.update({ allowedResponses: [...e.value] })
              }
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-1">
            <label>Show Comment</label>
          </div>
          <div className="col-auto">
            <CCSwitch
              checked={questionCfg.showComment}
              onChange={(e) => questionCfg.update({ showComment: e.value })}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-1">
            <label>Show Corrected On Site?</label>
          </div>
          <div className="col-auto">
            <CCSwitch
              checked={questionCfg.showYesNoOption}
              onChange={(e) => questionCfg.update({ showYesNoOption: e.value })}
            />
          </div>
        </div>
      </div>
    );
  });
};

export default InspectionQuestionEditor;
