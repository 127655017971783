import { UITownPlanning } from "@app/products/town-planning/model";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putPanelHearingDecision } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/panel-hearing-decision/api";
import { ApplicationDecisionDetails } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/application-decision/model";
import { ApplicationDecisionDialog } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/application-decision/_index";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const PanelHearingDecisionButton = observer(() => {
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();

  const { ppr, onSubmit, pprId, setPPRWithLoading } = usePSARStore();
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  const isVisible = useMemo(() => {
    return ppr?.Status_ENUM === Application_Status.SentReportForPanelHearing;
  }, [ppr]);

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.PanelHearingDecision.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const handleSubmit = (data: ApplicationDecisionDetails) => {
    if (!pprId) return;
    setIsLoading(true);
    putPanelHearingDecision(pprId, data).then((response) => {
      setIsLoading(false);
      if (isSuccessIdentityPacket(response) && response.data?.Application) {
        setIsShowDialog(false);
        setPPRWithLoading(response.data.Application);
        clearNotifications();
        pushNotification({
          type: "success",
          title: "Panel hearing decision successfully",
        });
        if (response.data.Notifications.length > 0) {
          pushNotification({
            type: "warning",
            title: "Notifications",
            description: response.data.Notifications,
          });
        }
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: "Panel hearing decision failed",
            type: "error",
            description: response?.data?.Errors ?? response?.error,
          },
        ]);
      }
    });
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Panel hearing decision"
        onClick={onSubmit}
        name={PSARSubmitActions.PanelHearingDecision}
      />
      {isShowDialog && (
        <ApplicationDecisionDialog
          application={ppr}
          onClose={() => {
            setIsShowDialog(false);
          }}
          notifications={notifications}
          onSubmit={handleSubmit}
          isLoading={isLoading}
        />
      )}
    </>
  ) : null;
});
