import { PPRApplicationCategory } from "@app/products/town-planning/ppr/system-admin/application-categories/model";
import { SYSTEM_ADMIN_APPLICATION_CATEGORIES_ROUTE } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/constant";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<PPRApplicationCategory>();
export const colPprSystemAdminApplicationCategories: IColumnFields[] = [
  {
    field: nameOf("ApplicationCategory_Name"),
    title: "Name",
    locked: true,
    linkTo: (dataItem) =>
      `${SYSTEM_ADMIN_APPLICATION_CATEGORIES_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("ApplicationCategory_Description"),
    title: "Description",
  },
  {
    field: nameOf("LandDivision"),
    title: "Certification",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Code"),
    title: "Code",
  },
  {
    field: nameOf("TargetDays"),
    title: "Target Days",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
