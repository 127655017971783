import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Field } from "@progress/kendo-react-form";
import React from "react";

export const MapFormElement = () => {
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">GIS (latitude)</label>
          <Field
            component={CCNumericTextBox}
            name={"Address.PropertyAssessment.GIS_Latitude"}
            placeholder={"GIS (latitude)"}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">GIS (longitude)</label>
          <Field
            component={CCNumericTextBox}
            name={"Address.PropertyAssessment.GIS_Longitude"}
            placeholder={"GIS (longitude)"}
          />
        </div>
      </div>
    </section>
  );
};
