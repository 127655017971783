import { IDocumentsTemplate } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/ppr/planning-permit-referrals/components/dialogs/select-documents-template/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const nameOfDocumentsTemplate = nameOfFactory<IDocumentsTemplate>();

export const colDocumentsTemplate: IColumnFields[] = [
  {
    field: nameOfDocumentsTemplate("Name"),
    title: "Name",
  },
  {
    field: nameOfDocumentsTemplate("FileName"),
    title: "File Name",
  },
];
