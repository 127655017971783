import { eventEmitter } from "@/App";
import { deleteComment } from "@app/core/comments/api";
import { DeleteCommentDialog } from "@app/core/comments/components/dialogs/delete-comment/_index";
import { ManageComment } from "@app/core/comments/components/dialogs/manage-comment/_index";
import { getComment } from "@app/core/comments/components/dialogs/manage-comment/api";
import { colComments } from "@app/core/comments/config";
import { COMMENT_ACCORDION_GRID_ID } from "@app/core/comments/constant";
import { Comment, VO_Comments } from "@app/core/comments/model";
import { odataGetComments } from "@app/core/components/common/utils";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { useTabTableStore } from "@app/core/tab-table/store";
import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { customLogger } from "@common/utils/logger";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

interface ICommentsProps {
  id?: number | string;
  recordType: RECORDTYPE;
  isHideDeleteButton?: boolean;
  isCallViewconfiguration?: boolean;
}

export const Comments = ({
  id,
  recordType,
  isHideDeleteButton = false,
  isCallViewconfiguration = false,
}: ICommentsProps) => {
  const nameOf = nameOfFactory<VO_Comments>();
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();
  const { isDisabled } = useTabTableStore();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
    useState<boolean>(false);
  const [showManageCommentDialog, setShowManageCommentDialog] =
    useState<boolean>(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
  const [commentInfo, setCommentInfo] = useState<Comment>();
  const [gridSelectedRows, setGridSelectedRows] = useState<VO_Comments[]>([]);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  //#region Logic call Viewconfiguration api
  const [commentCols, setCommentCols] = useState<IColumnFields[]>(colComments);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const loadCommentConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Comment,
      colComments
    );
    customLogger("Core Comments, loadCommentConfig").info(response);
    if (Array.isArray(response)) {
      setCommentCols(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    if (isCallViewconfiguration) {
      loadCommentConfig();
    }
  });
  //#endregion

  const columnFields = useMemo(
    () =>
      commentCols.map((col) => {
        if (col.field === nameOf("Comment")) {
          col.handleOnClick = (dataItem: VO_Comments) => {
            setIsLoadingDialog(true);
            setShowManageCommentDialog(true);
            getComment(dataItem.ID, recordType).then((response) => {
              customLogger("Core Comments, getComment").info(response?.data);
              setIsLoadingDialog(false);
              if (isSuccessResponse(response)) {
                if (response?.data) setCommentInfo(response?.data);
              } else {
                setNotifications([
                  {
                    id: "1",
                    autoClose: false,
                    title: "Comment load failed",
                    type: "error",
                  },
                ]);
              }
            });
          };
        }

        return col;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [commentCols]
  );

  const handleOnDelete = async (data: IDeleteConfirmation) => {
    setIsDeleting(true);
    const ids: number[] = gridSelectedRows?.map((item: VO_Comments) => item.ID);
    const response = await deleteComment(ids[0], recordType, data?.Reason);
    customLogger("Core Comments, deleteComment").info(response?.data);
    setIsDeleting(false);
    if (isSuccessResponse(response)) {
      setGridSelectedRows([]);
      clearErrorNotification();
      pushNotification({
        title: "Comment deleted successfully",
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData, {
        gridIds: [COMMENT_ACCORDION_GRID_ID],
      });
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(TabTableEventType.RefreshData);
    } else {
      pushNotification({
        autoClose: false,
        title: "Delete comment failed",
        description: response.data?.Errors,
        type: "error",
      });
    }
    setShowConfirmDeleteDialog(false);
  };

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadCommentConfig();
        }}
      />
    );
  return (
    <>
      <CCGrid
        toolbar={
          !isHideDeleteButton && (
            <div className="cc-grid-tools-bar">
              <Button
                type="button"
                iconClass="fas fa-minus"
                title="Remove"
                disabled={
                  isDisabled ? isDisabled : gridSelectedRows.length !== 1
                }
                onClick={() => setShowConfirmDeleteDialog(true)}
              />
            </div>
          )
        }
        gridId={COMMENT_ACCORDION_GRID_ID}
        columnFields={columnFields}
        primaryField={nameOf("ID")}
        dataUrl={odataGetComments(recordType, false, id)}
        selectedRows={gridSelectedRows}
        onSelectionChange={(dataItem: VO_Comments[]) => {
          if (dataItem) setGridSelectedRows([...dataItem]);
        }}
        selectableMode="multiple"
        state={{ sort: [{ field: nameOf("ID"), dir: "desc" }] }}
      />

      {showManageCommentDialog && (
        <ManageComment
          isLoading={isLoadingDialog}
          comment={commentInfo}
          notifications={notifications}
          onClose={() => setShowManageCommentDialog(false)}
        />
      )}

      {showConfirmDeleteDialog && (
        <DeleteCommentDialog
          onClose={() => setShowConfirmDeleteDialog(false)}
          onSubmit={handleOnDelete}
          isLoading={isDeleting}
        />
      )}
    </>
  );
};
