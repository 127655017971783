import { WastewaterFieldMapping } from "@app/products/waste-water/model";
import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colWastewaterNoticesByDate: IColumnFields[] = [
  {
    field: WastewaterFieldMapping.DateServed,
    title: "Served",
    locked: true,
    linkTo: (dataItem) => {
      return `${WASTE_WATER_ROUTE}/` + dataItem.ID;
    },
    format: DATE_FORMAT.DATE,
  },
  {
    field: WastewaterFieldMapping.ReferenceNumber,
    title: "Number",
  },
  {
    field: WastewaterFieldMapping.ContactName,
    title: "Name",
  },
  {
    field: WastewaterFieldMapping.Type,
    title: "Type",
  },
  {
    field: WastewaterFieldMapping.Officer,
    title: "Officer",
  },
  {
    field: WastewaterFieldMapping.DateDue,
    title: "Compliance Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: WastewaterFieldMapping.DateRevoked,
    title: "Complied / Revoked",
    format: DATE_FORMAT.DATE,
  },
  {
    field: WastewaterFieldMapping.Noticee,
    title: "Noticee",
  },
];
