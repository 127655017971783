import { ChangeDecisionData } from "@app/products/town-planning/ppr/components/action-bar/dialogs/change-decision/model";
import { ChangeDecisionDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/change-decision/_index";
import { putChangeDecision } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-decision/api";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ChangeDecisionButton = observer(() => {
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();

  const { ppr, pprId, setPPRWithLoading } = usePPRStore();
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (data: ChangeDecisionData) => {
    if (!pprId || data.Decision_ENUM === null) return;
    setIsLoading(true);
    putChangeDecision(pprId, data.Decision_ENUM).then((response) => {
      setIsLoading(false);
      if (isSuccessIdentityPacket(response) && response.data?.Application) {
        setIsShowDialog(false);
        setPPRWithLoading(response.data.Application);
        clearNotifications();
        pushNotification({
          type: "success",
          title: "Change decision successfully",
        });
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: "Change decision failed",
            type: "error",
            description: response?.data?.Errors ?? response?.error,
          },
        ]);
      }
    });
  };

  return (
    <>
      <CCNavButton
        title="Change decision"
        onClick={() => setIsShowDialog(true)}
      />
      {isShowDialog && (
        <ChangeDecisionDialog
          application={ppr}
          onClose={() => {
            setIsShowDialog(false);
          }}
          notifications={notifications}
          onSubmit={handleSubmit}
          isLoading={isLoading}
        />
      )}
    </>
  );
});
