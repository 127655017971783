import { ICCRoute } from "@common/constants/ICCRoute";

export const licencesRoute: ICCRoute = {
  path: "licences",
  name: "Licences",
  children: [
    {
      path: "issued",
      name: "Issued",
      component: require("./issued/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      component: require("./by-type/_index").default,
    },
    {
      path: "new",
      name: "New",
      component: require("./new/_index").default,
    },
    {
      path: "expired",
      name: "Expired",
      component: require("./expired/_index").default,
    },
    {
      path: "non-licensed",
      name: "Non Licensed",
      component: require("./non-licensed/_index").default,
    },
    {
      path: "all-licences",
      name: "All Licences",
      component: require("./all-licences/_index").default,
    },
  ],
};
