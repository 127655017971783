import { DTO_Create_Deferment_LOVs } from "@app/products/property/assessments/deferments/components/action-bar/dialogs/create-deferment/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class CreateDefermentDialogStore {
  private _createDefermentLOVs?: DTO_Create_Deferment_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get createDefermentLOVs() {
    return this._createDefermentLOVs;
  }
  setCreateDefermentLOVs = (createDefermentLOVs?: any) => {
    runInAction(() => {
      this._createDefermentLOVs = createDefermentLOVs;
    });
  };
}

const createDefermentDialogStoreContext = createContext(
  new CreateDefermentDialogStore()
);
export const useCreateDefermentDialogStore = () => {
  return useContext(createDefermentDialogStoreContext);
};
