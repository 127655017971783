import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyCollections } from "@app/products/property/components/child-screen/collections/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { eAccordion } from "@app/products/property/model";
import { CreateSchemeAccountButton } from "@app/products/property/schemes/[id]/components/action-bar/buttons/create-scheme-account/_index";
import { AccountsInTheSchemeFormElement } from "@app/products/property/schemes/[id]/components/child-screens/accounts/_index";
import { GeneralFormElement } from "@app/products/property/schemes/[id]/components/child-screens/general/_index";
import { AddInterestRateButton } from "@app/products/property/schemes/[id]/components/forms/existed/components/action-bar/buttons/add-interest-rate/_index";
import { EditGLAccountButton } from "@app/products/property/schemes/[id]/components/forms/existed/components/action-bar/buttons/edit-gl-account/_index";
import { ImportSchemeAccountsButton } from "@app/products/property/schemes/[id]/components/forms/existed/components/action-bar/buttons/import-scheme-accounts/_index";
import { ModifySchemeButton } from "@app/products/property/schemes/[id]/components/forms/existed/components/action-bar/buttons/modify-scheme/_index";
import { SchemeContactsTab } from "@app/products/property/schemes/[id]/components/reference-sidebar/contact/_index";
import { SchemeDetailTab } from "@app/products/property/schemes/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_SCHEMES_ROUTE } from "@app/products/property/schemes/[id]/constant";
import {
  ISchemeFormGeneral,
  ISchemesGL,
  ISchemesInterestRate,
} from "@app/products/property/schemes/[id]/model";
import { useSchemesStore } from "@app/products/property/schemes/[id]/store";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

const GENERAL_KEY_NAME = "General";

export const ExistedPropertyScheme = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { pushNotification } = useCCAppNotificationStore();
  const {
    responseLoadError,
    loadSchemes,
    schemes,
    schemesId,
    setSchemes,
    isLoading,
  } = useSchemesStore();
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        schemes?.General?.SchemeNumber,
        schemes?.General?.Name,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Property - Schemes - ${schemesId}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: schemesId,
    },
  ];

  const history = useHistory();
  const [isUpdateSchemeAccount, setIsUpdateSchemeAccount] =
    useState<boolean>(false);
  const handleSubmit = async (data: any) => {};

  const getTitle = (): string => {
    const generalData = schemes?.General;
    if (!generalData) return "N/A";
    const schemeNumber = generalData?.SchemeNumber;
    const name = generalData?.Name;

    if (schemeNumber && name) {
      return `${schemeNumber}, ${name}`;
    }

    return schemeNumber || name || "N/A";
  };

  const getNameOf = (name?: keyof ISchemeFormGeneral) => {
    if (name) {
      return `${GENERAL_KEY_NAME}.${name}`;
    }
    return GENERAL_KEY_NAME;
  };

  const getListPanelPar = (
    formRenderProps: FormRenderProps
  ): IPropertyPanelBar[] => {
    return [
      {
        accordionId: eAccordion.Scheme_Accounts_In_This_Scheme,
        title: "Accounts in this Scheme",
        component: (
          <AccountsInTheSchemeFormElement
            formRenderProps={formRenderProps}
            isUpdateSchemeAccount={isUpdateSchemeAccount}
            setIsUpdateSchemeAccount={setIsUpdateSchemeAccount}
          />
        ),
      },
      {
        accordionId: eAccordion.Scheme_Collections,
        title: "Collections",
        component: (
          <PropertyCollections
            componentId={parseInt(id)}
            componentNumber={eComponent.Scheme}
          />
        ),
      },
    ];
  };

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Scheme" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadSchemes(+id, false)}
        />
      ) : (
        <>
          <FormTitle title={getTitle()} badge={"Paid Out"} />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CreateSchemeAccountButton />
                <EditGLAccountButton
                  onSave={(value: ISchemesGL[]) => {
                    if (!schemes?.General) return;
                    setSchemes({
                      ...schemes,
                      General: {
                        ...schemes.General,
                        InterestAndInstalmentDetailsGL: value,
                      },
                    });
                  }}
                />
                <ImportSchemeAccountsButton
                  setIsUpdateSchemeAccount={setIsUpdateSchemeAccount}
                />
                <ModifySchemeButton />
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub"></CCNavButton>,
              <CCNavButton title={"View"} />,
              <CCNavButton title={"Print"} />,
              <CCNavButton title={"More Options"} type="more">
                <AddInterestRateButton
                  initialData={schemes?.General?.SchemesInterestRate || []}
                  onSave={(data: ISchemesInterestRate[]) => {
                    if (!schemes?.General) return;
                    setSchemes({
                      ...schemes,
                      General: {
                        ...schemes.General,
                        SchemesInterestRate: data,
                      },
                    });
                  }}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {schemes && (
                  <Form
                    key={JSON.stringify(schemes)}
                    onSubmit={handleSubmit}
                    initialValues={schemes}
                    render={(formRenderProps: FormRenderProps) => {
                      const validateForm = (
                        event: React.SyntheticEvent<any>
                      ) => {
                        if (formRenderProps.modified) {
                          const schemeId = formRenderProps.valueGetter(
                            getNameOf("SchemeId")
                          );

                          formRenderProps.onSubmit(event);
                          if (schemeId) {
                            formRenderProps.onFormReset();
                            history.push(
                              `${PROPERTY_SCHEMES_ROUTE}/${schemeId}`
                            );
                            pushNotification({
                              title: `Scheme ID ${schemeId} saved successfully`,
                              type: "success",
                            });
                          }
                          return;
                        }

                        if (!formRenderProps.valid) return;

                        pushNotification({
                          title: "The form is not modified",
                          type: "warning",
                        });
                      };
                      return (
                        <FormElement className="cc-property-scheme">
                          {/* Reference by a label in WastewaterActionBar.tsx */}
                          <input
                            hidden
                            type="submit"
                            id="btn-submit"
                            onClick={validateForm}
                          />
                          <CCGeneralPanel
                            component={
                              <GeneralFormElement
                                formRenderProps={formRenderProps}
                              />
                            }
                          />
                          <PropertyPanelBar
                            initialPanels={getListPanelPar(formRenderProps)}
                          />
                        </FormElement>
                      );
                    }}
                  />
                )}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <SchemeDetailTab /> },
                    { title: "Contacts", component: <SchemeContactsTab /> },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
