import { NUMBER_FORMAT } from "@common/constants/common-format";
import { DynamicQuestionList_Light } from "@common/models/dynamicQuestion";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DynamicQuestionList_Light>();

export const colDynamicQuestionPicker: IColumnFields[] = [
  { title: "Question", field: nameOf("DynamicQuestionList_Name") },
  { title: "Checklist Type", field: nameOf("ChecklistType_Name") },
  { title: "Index", field: nameOf("SortIndex"), format: NUMBER_FORMAT.NUMBER2 },
];
