import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { getNamesAndValues } from "@common/pages/settings/security/site-users/util";
import { ClickOnceVersion } from "@common/pages/settings/security/site-users/_id/model";

export const convertClickOneVerEnum = (clickOneVeList?: any[]) => {
  if (!clickOneVeList) return [];
  const newProductList = [...clickOneVeList];
  return newProductList.map((product: IKeyValuePacket) => {
    return {
      Key:
        getNamesAndValues(ClickOnceVersion).find(
          (productEnum) => productEnum.value === product.Key
        )?.name || undefined,
      Value: product.Value,
    };
  });
};
