import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { DTO_Entity } from "@app/products/property/titles/[id]/components/child-screens/owners/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Entity>();

export const colFieldsTitleOwners: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    linkTo: (dataItem: DTO_Entity) =>
      `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem.Id}`,
  },
  {
    field: nameOf("EntityRole"),
    title: "Role",
  },
  { field: nameOf("Address"), title: "Address" },
  {
    field: nameOf("Ownership_Percentage"),
    title: "Ownership Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("Gender"),
    title: "Gender",
  },
  {
    field: nameOf("IsActive"),
    title: "Is Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },

  { field: nameOf("ChangeOfOwnership_Reference"), title: "Change Reference" },
  {
    field: nameOf("ChangeOfOwnership_EffectiveDate"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ChangeOfOwnership_Id"),
    title: "Change of Ownership ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Id"),
    title: "Entity Name/Address ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
