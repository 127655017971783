import { loadCSLSystemAdminCategoriesById } from "@app/products/csl/system-admin/categories/[id]/api";
import { CSLSystemAdminCategoriesForm } from "@app/products/csl/system-admin/categories/[id]/components/child-screens/general/_index";
import { CSLSystemSettingCategoriesForm } from "@app/products/csl/[id]/model";
import { WastewaterRoute } from "@app/products/waste-water/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import { default as React, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export interface IExistManagecCSLSystemAdminCategoriesProps {
  isLoading: boolean;
  setIsLoading: (status: boolean) => void;
  cslSystemAdminCategoriesInfo: CSLSystemSettingCategoriesForm | undefined;
}

export const ExistManageCSLSystemAdminCategories = observer(
  ({ isLoading, setIsLoading }: IExistManagecCSLSystemAdminCategoriesProps) => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { currentUserInfo } = useGlobalStore();
    const [cslSystemAdminCategoriesInfo, setCSLSystemAdminCategoriesInfo] =
      useState<CSLSystemSettingCategoriesForm>();

    const managePageUrl = window.location.pathname;

    const bookmarkList: IBookmarkItem[] = [
      {
        LinkText: `CSL - System Admin - Categories - ${id}`,
        Text: "Licence Category",
        LinkUrl: managePageUrl,
        User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
        ProductType_ENUM: PRODUCT_TYPE.CSM,
        //TODO don't have exactly record type, temporary use CSM_Licence
        RecordType_ENUM: RECORDTYPE.CSM_Licence,
        Record_ID: +id ?? 0,
      },
    ];

    useEffectOnce(() => {
      setIsLoading(true);
      loadCSLSystemAdminCategoriesById().then((data) => {
        if (!data) {
          return history.replace(WastewaterRoute.WASTEWATER_SYSTEMS_REGISTER);
        }
        setCSLSystemAdminCategoriesInfo(data);
        setIsLoading(false);
      });
    });

    return (
      <>
        <Loading isLoading={isLoading} />
        <FormNavigation title="Licence Category" />

        <FormTitle title={"Licence Category"} />

        <CCManagePageActionBar
          leftActions={[
            <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
            <CCNavButton title={"Save & Exit"} htmlFor={"btn-submit"} />,
            <CCNavButton title={"Cancel"} />,
            <CCNavButton title={"Std Condition"} />,
            <CCNavButton title={"View"} />,
          ]}
          rightActions={[<ActionBarBookmarkIcon bookmarkList={bookmarkList} />]}
        />

        <div className="cc-manage-container">
          <div className="cc-manage-left">
            <div className="cc-manage-form-body">
              {cslSystemAdminCategoriesInfo && (
                <CCGeneralPanel
                  component={
                    <CSLSystemAdminCategoriesForm
                      isNew={false}
                      setIsLoading={setIsLoading}
                      formData={cslSystemAdminCategoriesInfo}
                    />
                  }
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
);
