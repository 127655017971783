import { useDebtRecoveryStore } from "@app/products/property/assessments/debt-recovery/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import Loading from "@components/loading/Loading";
import { parseInt } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import ExistedDebtRecovery from "./components/forms/existed/_index";
import NewDebtRecovery from "./components/forms/new/_index";

const ManagePropertyAssessmentDebtRecovery = observer(() => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const debtRecoveryId = params.id;
  const { resetMenu } = useCCSubActionBarStore();
  const { loadDebtRecovery, resetStore, isLoading } = useDebtRecoveryStore();

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });

  useEffect(() => {
    if (isNew) return;
    loadDebtRecovery(parseInt(debtRecoveryId));
  }, [debtRecoveryId, isNew, loadDebtRecovery]);

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  if (isNew) {
    return <NewDebtRecovery />;
  }
  return <ExistedDebtRecovery />;
});

export default ManagePropertyAssessmentDebtRecovery;
