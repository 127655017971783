import React from "react";

interface IProps {
  buttonText: string;
  onClick?: (event: any) => void;
}
export const PageTitleMenuButton: React.FC<IProps> = ({
  buttonText,
  onClick,
}) => {
  return (
    <div className="col-auto pt-2">
      <label className="label btn-link" onClick={onClick}>
        {buttonText}
      </label>
    </div>
  );
};
