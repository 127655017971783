import { PropertyJournalForm } from "@app/products/property/journals/session-list/[id]/components/child-screens/general/_index";
import { useSessionListStore } from "@app/products/property/journals/session-list/[id]/store";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewPropertyJournal = observer(() => {
  const { isLoading } = useSessionListStore();
  return (
    <>
      <Loading isLoading={isLoading} />
      <FormNavigation title="Journal" />
      <FormTitle title="New Journal" />

      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          >
            <CCNavButton title={"Allocate to planner"} />
            <CCNavButton title={"Failure"} />
            <CCNavButton title={"Revision received"} />
            <CCNavButton title={"Withdraw application"} />
          </CCNavButton>,
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

          <CCNavButton title={"Add"} type="sub">
            <AddActionButton />
            <CCNavButton title={"Add comments"} />
            <CCNavButton title={"Add contacts"} />
            <CCNavButton title={"Add documents"} />
            <CCNavButton title={"Add fees"} />
            <CCNavButton title={"Add samples"} />
            <CCNavButton title={"Add inspection"} />
          </CCNavButton>,
          <CCNavButton title={"View"} />,
          <CCNavButton title={"Print"} />,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCGeneralPanel component={<PropertyJournalForm />} />
        </div>
      </div>
    </>
  );
});
