import { ICCRoute } from "@common/constants/ICCRoute";

export const bamsRoute: ICCRoute = {
  path: "bams",
  name: "BAMS",
  children: [
    {
      path: "new-applications",
      name: "New Applications",
      component: require("./new-applications/_index").default,
    },
    {
      path: "apps-sent-to-bams",
      name: "Apps Sent to BAMS",
      component: require("./apps-sent-to-bams/_index").default,
    },
    {
      path: "new-prescribed-events",
      name: "New Prescribed Events",
      component: require("./new-prescribed-events/_index").default,
    },
    {
      path: "pe-sent-to-bams",
      name: "PE Sent to BAMS",
      component: require("./pe-sent-to-bams/_index").default,
    },
  ],
};
