import { Coords } from "google-map-react";

export interface GeoCoderResponse {
  results: GeocoderResult[];
  status: GEOCODE_STATUS_CODES;
  error_message?: string;
}

export interface GeocoderResult {
  address_components: GeocoderAddressComponent[];
  formatted_address: string;
  geometry: GeocoderGeometry;
  place_id: string;
  types: string[];
  partial_match?: boolean;
  plus_code?: PlacePlusCode;
  postcode_localities?: string[];
}

export interface GeocoderAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface GeocoderGeometry {
  location: Coords;
  location_type: GeocoderLocationType;
  viewport: any;
  bounds?: any;
}

export interface PlacePlusCode {
  global_code: string;
  compound_code?: string;
}

export interface IMapMapCenterProps {
  lat: number;
  lng: number;
}

export enum GEOCODE_STATUS_CODES {
  "OK" = "OK",
  "ZERO_RESULTS" = "ZERO_RESULTS",
  "OVER_DAILY_LIMIT" = "OVER_DAILY_LIMIT",
  "OVER_QUERY_LIMIT" = "OVER_QUERY_LIMIT",
  "REQUEST_DENIED" = "REQUEST_DENIED",
  "INVALID_REQUEST" = "INVALID_REQUEST",
  "UNKNOWN_ERROR" = "UNKNOWN_ERROR",
}

export enum GeocoderLocationType {
  "APPROXIMATE" = "APPROXIMATE",
  "GEOMETRIC_CENTER" = "GEOMETRIC_CENTER",
  "RANGE_INTERPOLATED" = "RANGE_INTERPOLATED",
  "ROOFTOP" = "ROOFTOP",
}

export enum MapTypeControlStyle {
  DEFAULT,
  HORIZONTAL_BAR,
  DROPDOWN_MENU,
}
