import { DTO_AssociatedItem_DeferredDuty } from "@app/products/property/components/associations/components/deferred-duty/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_DeferredDuty>();

export const colAssociationsDeferredDuty: IColumnFields[] = [
  {
    field: nameOf("DeferredNumber"),
    title: "Deferred Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("TRSAssessmentNo"),
    title: "TRS Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AmountDeferred"),
    title: "Amount Deferred",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("DeferralBalance"),
    title: "Deferral Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Interest"),
    title: "Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("OtherAmount"),
    title: "Other Amounts",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("PaymentStartDate"),
    title: "Payment Start Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("PaymentCompletion"),
    title: "Payment Completion",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("TRSClientNo"),
    title: "TRS Client number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AccountID"),
    title: "Account ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
