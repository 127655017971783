import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

interface IProps {
  title: string;
}
export const PageTitleBackNav: React.FC<IProps> = ({ title }) => {
  let history = useHistory();
  return (
    <Fragment>
      <h6 className="pl-2 pt-3 pb-2 d-flex align-items-center">
        <i
          className="fa fa-arrow-circle-left ccBackButton mr-2"
          onClick={() => {
            history.goBack();
          }}
        />
        <span style={{ color: "#aaa" }}>{title}</span>
      </h6>
    </Fragment>
  );
};

export default PageTitleBackNav;
