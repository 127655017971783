import { DTO_LOV_Transaction } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/form-steps/new-rebate-claim/model";
import { ICON_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfLOVTransaction = nameOfFactory<DTO_LOV_Transaction>();
export const colTransactionType: IColumnFields[] = [
  {
    field: nameOfLOVTransaction("IsIncluded"),
    title: "Include?",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: nameOfLOVTransaction("Name"),
    title: "Transaction Type",
  },
];
