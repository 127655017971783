import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const RecordsTrimEPlanningForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const { valueGetter } = formRenderProps;

    const showAuthorFormat =
      valueGetter(
        ECorporateSettingsField.TRIMAdmin_TP_PortalEnableApplicantAsAuthor.toString()
      ) === true;
    if (!configData) return <></>;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <SwitchGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .TRIMAdmin_TP_PortalEnableApplicantAsAuthor
              ]
            }
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          {showAuthorFormat && (
            <CCSearchComboBoxGlobalSettings
              data={configData[ECorporateSettingsField.TP_AuthorNameFormatType]}
              isEditing={isEditing}
            />
          )}
        </div>
      </section>
    );
  }
);
