import { useTownPlanningStore } from "@app/products/town-planning/[id]/store";
import { getPPRSummaryById } from "@app/products/town-planning/ppr/[id]/api";
import {
  optionInputPickerApplicationNumber,
  optionInputPickerDoTOffice,
  optionInputPickerOfficer,
  optionInputPickerResponsibleAuthority,
  optionInputPickerTeamLeader,
} from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { pprProduceContactValue } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/util";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { getSiteUser } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/api";
import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { PropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/_index";
import { StateRoadsPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/state-roads-picker-search/_index";
import { optionInputPickerStateRoads } from "@app/products/town-planning/ppr/[id]/components/input-picker/state-roads-picker-search/config";
import { StateDeclaredRoad } from "@app/products/town-planning/ppr/[id]/components/input-picker/state-roads-picker/model";
import {
  Address_BuildAddress,
  Application,
  ContactRelationshipType,
  Council,
  RECORDSSYSTEM,
  StateRoad,
  TRIMSTORAGEMETHOD,
} from "@app/products/town-planning/ppr/[id]/model";
import { requiredAddressValidation } from "@app/products/town-planning/ppr/enquiries/_id/components/child-screens/general/components/form-element/util";
import { IAppNo } from "@app/products/town-planning/ppr/enquiries/_id/components/input-picker/app-no-picker/model";
import {
  ExtRegister,
  RegisterStatus,
  RegisterUpdateTriggers,
} from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import {
  Address,
  BubbleUpType,
  Contact_Relation,
  SiteUser,
} from "@app/products/waste-water/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  formatDisplayValue,
  getFullAddressHTML,
} from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FieldProps,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isArray, isEmpty, isNil, isNull } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface IPPREnquiryFormElementProps {
  formRenderProps: FormRenderProps;
}

export const PPREnquiryFormElement = observer(
  ({ formRenderProps }: IPPREnquiryFormElementProps) => {
    const isNew = useIsNew();
    const nameOf = nameOfFactory<ExtRegister>();
    const { pushNotification } = useCCAppNotificationStore();
    const { loadZoneAndOverlayKeywords, zoneKeywordList, overlayKeywordList } =
      useTownPlanningStore();
    const {
      pprEnquiryMethod,
      pprEnquiryType,
      pprEnquiryOutcome,
      pprEnquiry,
      isInactive,
    } = usePPREnquiryStore();
    const { settings } = useCommonProductStore();
    const { valueGetter, onChange } = formRenderProps;

    //Get settings section
    const recordsSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]?.Value
    );
    const trimStorageMethod = getNumberValueSetting(
      settings[ECorporateSettingsField.TRIMAdmin_EnquiryStorageMethod]
    );
    const setFileNoSetting = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_RecordsSetFileNoWithPropertyNo
      ]
    );
    const propertyNoMask =
      settings[ECorporateSettingsField.Records_PropertyNoMask]?.Value;
    const customLabelForTL =
      settings[ECorporateSettingsField.TP_PPRLabelForTL]?.Value ??
      "Delegated approver";

    useEffectOnce(() => {
      loadZoneAndOverlayKeywords();
    });

    // ------ Handle logic section ------
    // Common
    const updateSaveTriggers = (triggers: RegisterUpdateTriggers) => {
      let saveTriggers: RegisterUpdateTriggers[] =
        valueGetter("_SaveTriggers") ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(nameOf("SaveTriggers"), {
          value: saveTriggers,
        });
      }
    };
    //Applicant Contact
    const applicant: Contact_Relation = valueGetter(nameOf("Contact"));
    const applicantContact = applicant?.Contact;
    const displayApplicantPostalAddress = useMemo(() => {
      return getFullAddressHTML(applicantContact?.PostalAddress);
    }, [applicantContact]);
    const displayApplicantContact = useMemo(() => {
      return pprProduceContactValue(applicantContact);
    }, [applicantContact]);
    const councilName: string = valueGetter("ResponsibleAuthority.CouncilName");
    const handleOnChangeApplicant = (event: ComboBoxChangeEvent) => {
      const value = event.value;
      onChange(nameOf("Contact_RID"), { value: null });
      onChange(nameOf("Contact"), {
        value: {
          ContactRelationshipType_ENUM: ContactRelationshipType.Applicant,
          Contact: value,
        },
      });
      updateSaveTriggers(RegisterUpdateTriggers.UpdateContact);
    };
    // Preferred Contact
    const preferred: Contact_Relation = valueGetter(nameOf("Contact2"));
    const preferredContact = preferred?.Contact;
    const displayPreferredPostalAddress = useMemo(() => {
      return getFullAddressHTML(preferredContact?.PostalAddress);
    }, [preferredContact]);
    const displayPreferredContact = useMemo(() => {
      return pprProduceContactValue(preferredContact);
    }, [preferredContact]);
    const handleOnChangePreferredContact = (event: ComboBoxChangeEvent) => {
      const value = event.value;
      onChange(nameOf("Contact2_RID"), { value: null });
      onChange(nameOf("Contact2"), {
        value: !isNil(event.value)
          ? {
              ContactRelationshipType_ENUM:
                ContactRelationshipType.PrimaryContact,
              Contact: value,
            }
          : null,
      });
      updateSaveTriggers(RegisterUpdateTriggers.UpdateContact2);
    };
    const handleSameAsApplicantClick = () => {
      if (applicant && !isInactive) {
        onChange("Contact2", {
          value: {
            ...applicant,
            ContactRelationshipType_ENUM:
              ContactRelationshipType.PrimaryContact,
            RID: 0,
          },
        });
        updateSaveTriggers(RegisterUpdateTriggers.UpdateContact2);
      }
    };

    //Site Address
    const siteAddress: Address = valueGetter(nameOf("Address"));
    const displaySiteAddress = useMemo(() => {
      if (siteAddress) {
        return getFullAddressHTML(siteAddress);
      } else return "";
    }, [siteAddress]);

    /* ====  SiteUserHandle-Set initial values  ==== */
    const currentApplicationEnquiry: ExtRegister = valueGetter("");
    const siteAddressInitData = useMemo(() => {
      if (!currentApplicationEnquiry) return undefined;
      let result: any = {
        Address: currentApplicationEnquiry?.Address,
        _option: {
          checkComplexAddress: false,
          LocationDescriptor:
            currentApplicationEnquiry?.Address?.PropertyAssessment
              ?.AddressLocationDescriptor,
        },
      };

      if (
        currentApplicationEnquiry?.Address?.PropertyAssessment?.ZoneIDs &&
        zoneKeywordList
      ) {
        result.ZoneList =
          currentApplicationEnquiry?.Address?.PropertyAssessment?.Zones?.split(
            ","
          ).map((item) => {
            return {
              Name: item,
            };
          });

        const zoneIDs =
          currentApplicationEnquiry?.Address?.PropertyAssessment?.ZoneIDs ?? [];
        result.Zone_Display = zoneKeywordList.filter((item) =>
          zoneIDs.includes(item.Keyword_ID)
        );
      }

      if (
        currentApplicationEnquiry?.Address?.PropertyAssessment?.OverlayIDs &&
        overlayKeywordList
      ) {
        result.OverlayList =
          currentApplicationEnquiry?.Address?.PropertyAssessment?.Overlay.split(
            ","
          ).map((item) => {
            return {
              Name: item,
            };
          });

        const overlayIDs =
          currentApplicationEnquiry?.Address?.PropertyAssessment?.OverlayIDs ??
          [];
        result.Overlay_Display = overlayKeywordList.filter((item) =>
          overlayIDs.includes(item.Keyword_ID)
        );
      }
      return result;
      // eslint-disable-next-line
    }, [
      currentApplicationEnquiry,
      siteAddress,
      zoneKeywordList,
      overlayKeywordList,
    ]);
    /* ====  / SiteUserHandle-Set initial values  ==== */

    /* ====  SiteUserHandle-Function is called when clicking "OK" button in Property Details dialog ==== */
    const handleOnChangeSiteAddress = (value: any) => {
      const newAddress: Address_BuildAddress = value;
      onChange(nameOf("Address"), { value: newAddress?.Address });
      if (isArray(value.Zone_Display)) {
        onChange("Address.PropertyAssessment.Zone_Display", {
          value: value.Zone_Display,
        });
      }
      if (isArray(value.Overlay_Display)) {
        onChange("Address.PropertyAssessment.Overlay_Display", {
          value: value.Overlay_Display,
        });
      }
      updateSaveTriggers(RegisterUpdateTriggers.UpdateAddress);
      //override global setting from each system if TRIM
      let setFileNo = setFileNoSetting;
      if (
        recordsSystem &&
        recordsSystem === RECORDSSYSTEM.TRIMContext &&
        trimStorageMethod &&
        trimStorageMethod !== TRIMSTORAGEMETHOD.PROPERTYNUMBER
      ) {
        setFileNo = false;
      }
      if (
        recordsSystem &&
        recordsSystem === RECORDSSYSTEM.TRIMContext &&
        trimStorageMethod &&
        trimStorageMethod === TRIMSTORAGEMETHOD.PROPERTYNUMBER
      ) {
        setFileNo = true;
      }
      if (setFileNo) {
        let propertyNumber = siteAddress?.AssessmentNo;
        if (
          !isEmpty(propertyNoMask) &&
          propertyNoMask.includes("[PROPERTYNUMBER]")
        )
          propertyNumber = propertyNoMask.replace(
            "[PROPERTYNUMBER]",
            propertyNumber
          );
        onChange("FileNo", { value: propertyNumber });
      }
    };
    /* ====  / SiteUserHandle-Function is called when clicking "OK" button in Property Details dialog ==== */

    // Team Leader & Officer
    const handleOnChangeTeamLeader = (value: IOfficer | null) => {
      onChange(nameOf("TeamLeader_ID"), { value: value?.ID });
      if (!value)
        return onChange("TeamLeader", {
          value: null,
        });
      getSiteUser(value.ID).then((response) => {
        if (isSuccessResponse(response)) {
          if (response?.data) {
            onChange("TeamLeader", {
              value: response.data,
            });
          }
        } else {
          pushNotification({
            title: "Officer is not found",
            type: "error",
            autoClose: false,
          });
        }
      });
      if (
        valueGetter(nameOf("Register_ID")) !== 0 &&
        pprEnquiry?.TeamLeader_ID !== valueGetter(nameOf("TeamLeader_ID"))
      ) {
        updateSaveTriggers(RegisterUpdateTriggers.UpdateTeamLeader);
      }
    };
    const handleOnChangeOfficer = (value: SiteUser | null) => {
      onChange(nameOf("Officer_ID"), { value: value?.Contact_ID });
      onChange(nameOf("Officer"), {
        value: value ?? null,
      });

      if (
        valueGetter(nameOf("Register_ID")) !== 0 &&
        pprEnquiry?.Officer_ID !== valueGetter(nameOf("Officer_ID"))
      ) {
        updateSaveTriggers(RegisterUpdateTriggers.UpdateOfficer);
      }
    };

    // App No
    const createPPREnquiryBubbleUps = (
      bubbleUpType: BubbleUpType,
      recordType: RECORDTYPE,
      recordId: number
    ) => {
      return {
        SourceIdentifier: {
          _RecordSourceType_ENUM: recordType,
          _RecordSource_ID: recordId,
          _RecordSource_ColumnName: "",
          _RecordSource_StringID: "",
          _RecordSource_FileNumber: "",
        },
        BubbleUpType_ENUM: bubbleUpType,
        Sys_CreatedDate: new Date(),
        Sys_DeactivationDate: null,
      };
    };
    const handleOnChangeAppNo = (value: IAppNo) => {
      if (!value)
        onChange(nameOf("ReferenceNumber"), {
          value: null,
        });
      if (!value?.ID) return;
      getPPRSummaryById(value.ID).then((response) => {
        if (isSuccessResponse(response)) {
          if (response?.data) {
            const application: Application = response.data;
            if (!isNull(application)) {
              let bubbleUps = valueGetter(nameOf("BubbleUps")) ?? [];
              if (bubbleUps.length > 0) {
                bubbleUps.pop();
                updateSaveTriggers(RegisterUpdateTriggers.UpdateBubbles);
              }
              //Add BubbleUps
              bubbleUps.push(
                createPPREnquiryBubbleUps(
                  BubbleUpType.Ancestor,
                  application.RecordType,
                  application.Application_ID
                )
              );
              bubbleUps.push(
                createPPREnquiryBubbleUps(
                  BubbleUpType.Parent,
                  application.RecordType,
                  application.Application_ID
                )
              );
              onChange(nameOf("BubbleUps"), { value: bubbleUps });
              onChange(nameOf("Address"), { value: application.SiteAddress });
              onChange("Address.Address_ID", { value: 0 });

              if (isNil(valueGetter("Contact.Contact"))) {
                onChange(nameOf("Contact"), { value: application.Applicant });
                onChange("Contact.RID", { value: 0 });
              }

              onChange(nameOf("ReferenceNumber"), {
                value: application.ApplicationNumberDisplay,
              });
              updateSaveTriggers(RegisterUpdateTriggers.UpdateBubbles);
            }
          }
        } else {
          pushNotification({
            title: "Application is not found",
            type: "error",
            autoClose: false,
          });
        }
      });
    };

    // Dont remove this, will be reused
    // const handleOnChangeCategory = (event: ICCInputPickerChangeEvent) => {
    //   const keyword: Keyword = event.value;
    //   onChange("_options.CategoryName", {
    //     value: keyword.Name,
    //   });
    //   if (keyword?.TargetDays && valueGetter(nameOf("Date_Lodged"))) {
    //     const dtLodged = new Date(valueGetter(nameOf("Date_Lodged")));
    //     onChange(nameOf("Date_Due"), {
    //       value: addDays(dtLodged, keyword?.TargetDays),
    //     });
    //   }
    // };

    const EnquiryField = useCallback(
      (props: FieldProps) => {
        const { disabled, ...others } = props;
        return (
          <Field {...others} disabled={isInactive ? isInactive : disabled} />
        );
      },
      [isInactive]
    );

    return (
      <FormElement className="cc-ppr-enquiry-form-element">
        {/* Contact */}
        <section className="cc-field-group">
          {/* Applicant Contact */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Applicant
                <CCTooltip type="validator" position="right" />
              </label>
              <EnquiryField
                name={"Contact.Contact"}
                component={ContactPicker}
                validator={requiredValidator}
                placeholder={"Applicant"}
                onChange={handleOnChangeApplicant}
                onError={(error: any) => {
                  pushNotification({
                    type: "error",
                    title: "Pick contact errors",
                    description: error,
                    autoClose: false,
                  });
                }}
              />
            </div>
            <CCValueField
              label={"Postal address"}
              value={displayApplicantPostalAddress}
            />
            <CCValueField label={"Contact"} value={displayApplicantContact} />
          </div>
          {/*  Preferred Contact */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Preferred contact
                {valueGetter(nameOf("Contact")) && (
                  <CCTooltip
                    content="Set contact same as applicant"
                    type="custom"
                  >
                    <div
                      className="fa fa-chevron-circle-right cc-chevron-circle"
                      onClick={handleSameAsApplicantClick}
                    />
                  </CCTooltip>
                )}
              </label>
              <EnquiryField
                name={"Contact2.Contact"}
                component={ContactPicker}
                placeholder={"Preferred contact"}
                onChange={handleOnChangePreferredContact}
                onError={(error: any) => {
                  pushNotification({
                    type: "error",
                    title: "Pick contact errors",
                    description: error,
                    autoClose: false,
                  });
                }}
              />
            </div>
            <CCValueField
              label={"Postal address"}
              value={displayPreferredPostalAddress}
            />
            <CCValueField label={"Contact"} value={displayPreferredContact} />
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Enquiry method</label>
              <EnquiryField
                name={nameOf("EnquiryMethod_KWD")}
                component={CCSearchComboBox}
                data={pprEnquiryMethod ?? []}
                textField="Name"
                dataItemKey="Keyword_ID"
                isUseDefaultOnchange
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Enquiry type</label>
              <EnquiryField
                name={nameOf("Type_KWD")}
                component={CCSearchComboBox}
                data={pprEnquiryType ?? []}
                textField="Name"
                dataItemKey="Keyword_ID"
                value={getDropdownValue(
                  valueGetter(nameOf("Type_KWD")),
                  pprEnquiryType ?? [],
                  "Keyword_ID"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("Type_KWD"), {
                    value: event.target.value?.Keyword_ID ?? null,
                  });
                }}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        {/* Address */}
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Address validation
                <CCTooltip type="validator" position="right" />
                {!isNil(siteAddress?.VMAS_Verified) && (
                  <div
                    className={`fa fa-home ${
                      siteAddress?.VMAS_Verified ? "text-success" : "text-error"
                    }`}
                  />
                )}
              </label>
              <EnquiryField
                name={nameOf("Address")}
                placeholder={"Address validation"}
                component={PropertyDetail}
                validator={requiredAddressValidation}
                onSubmit={handleOnChangeSiteAddress}
                value={displaySiteAddress}
                initialData={siteAddressInitData}
                formRenderProps={formRenderProps}
                updateSaveTriggers={() => {
                  updateSaveTriggers(RegisterUpdateTriggers.UpdateAddress);
                }}
              />
            </div>
            <CCValueField
              label="Zone"
              value={valueGetter("Address.PropertyAssessment.Zones")}
            />
            <CCValueField
              label="Overlay"
              value={valueGetter("Address.PropertyAssessment.Overlay")}
            />
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Council
                <CCTooltip type="validator" position="right" />
              </label>
              <EnquiryField
                name={nameOf("ResponsibleAuthority")}
                nameDisplay="CouncilName"
                placeholder="Council"
                component={InputPickerSearch}
                validator={requiredValidator}
                onChange={(data: Council) => {
                  onChange("ResponsibleAuthority", {
                    value: data,
                  });
                  onChange("ResponsibleAuthority_ID", {
                    value: data?.Council_ID,
                  });
                }}
                onError={(error: any) => {
                  pushNotification({
                    title: error,
                    type: "warning",
                  });
                }}
                options={optionInputPickerResponsibleAuthority}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Declared arterial</label>
              <EnquiryField
                name={nameOf("VRStreet")}
                placeholder={"Declared arterial"}
                component={StateRoadsPickerSearch}
                //must be required
                uniqueKey={"Enquiry-VRStreet"}
                onChange={(value: StateDeclaredRoad) => {
                  if (value) {
                    onChange("VRStreetID", {
                      value: value.StateRoad_ID ?? value.ID,
                    });
                  } else {
                    onChange("VRStreetID", {
                      value: null,
                    });
                  }
                  onChange("VRStreet", {
                    value: value,
                  });
                }}
                options={optionInputPickerStateRoads}
                textProduce={(value: StateRoad) => {
                  return !isEmpty(value?.RoadName)
                    ? `<span>${value?.RoadName}${
                        value?.RoadIDNo
                          ? `&nbsp<span class='text-error' style='display:inline-block;text-decoration:none;'>${value?.RoadIDNo}`
                          : ""
                      }  </span></span>`
                    : `<span class="text-disabled">Declared arterial</span>`;
                }}
                currentCouncil={councilName}
                onError={() => {
                  pushNotification({
                    title: "State road is not found",
                    type: "warning",
                  });
                }}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                DTP office
                <CCTooltip type="validator" position="right" />
              </label>
              <EnquiryField
                name={nameOf("VROffice")}
                placeholder="DTP office"
                component={InputPickerSearch}
                validator={requiredValidator}
                onChange={(data: CoreKeyword) => {
                  onChange(nameOf("VROffice"), {
                    value: data?.Name,
                  });
                  onChange(nameOf("VR_OfficeID"), {
                    value: data?.Keyword_ID,
                  });
                }}
                options={optionInputPickerDoTOffice}
                onError={(error: any) => {
                  pushNotification({
                    title: error,
                    type: "warning",
                  });
                }}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">
                {customLabelForTL}
                <CCTooltip type="validator" position="right" />
              </label>
              <EnquiryField
                name={"TeamLeader.DisplayName"}
                placeholder={customLabelForTL}
                component={InputPickerSearch}
                validator={requiredValidator}
                onChange={handleOnChangeTeamLeader}
                options={optionInputPickerTeamLeader}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Officer</label>
              <EnquiryField
                name={"Officer.DisplayName"}
                placeholder={"Officer"}
                component={InputPickerSearch}
                onChange={handleOnChangeOfficer}
                options={optionInputPickerOfficer}
                onError={(error: any) => {
                  pushNotification({
                    title: error,
                    type: "error",
                    autoClose: false,
                  });
                }}
              />
            </div>
          </div>
        </section>
        <br />
        <hr className="cc-divider" />
        {/* Date */}
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCValueField
              label="Lodged"
              value={
                !isNew &&
                formatDisplayValue(
                  valueGetter(nameOf("Date_Lodged")),
                  DATE_FORMAT.DATE
                )
              }
            />
            {/* Please dont remove this field, this can be added to the application again when customer request */}
            {/* <div className="cc-field">
              <label className="cc-label">
                Category <CCTooltip type="validator" position="right" />
              </label>
              <EnquiryField
                name={"_options.CategoryName"}
                placeholder={"Category"}
                component={KeyWordsPicker}
                validator={requiredValidator}
                keyWordType={KEYWORD_TYPE.Core_RegisterPlanningEnquiryCategory}
                productType={PRODUCT_TYPE_NUMBER.TownPlanning}
                onPickKeyWords={(value: CoreKeyword) => {
                  onChange("Category_KWD", {
                    value: value.Keyword_ID,
                  });
                }}
                onChange={handleOnChangeCategory}
                onError={(error: any) => {
                  pushNotification({
                    title: error,
                    type: "warning",
                  });
                }}
              />
            </div> */}

            <div className="cc-field">
              <label className="cc-label">File number</label>
              <EnquiryField
                name={nameOf("FileNumber")}
                placeholder={"File number"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Application number</label>
              <EnquiryField
                name={nameOf("ReferenceNumber")}
                placeholder="Application number"
                component={InputPickerSearch}
                onChange={handleOnChangeAppNo}
                options={optionInputPickerApplicationNumber}
              />
            </div>

            <div className="cc-field">
              {valueGetter(nameOf("Category_KWD")) ? (
                <>
                  <label className="cc-label">Due date</label>
                  <EnquiryField
                    name={nameOf("Date_Due")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                  />
                </>
              ) : (
                <CCValueField
                  label="Due date"
                  value={formatDisplayValue(
                    valueGetter(nameOf("Date_Due")),
                    DATE_FORMAT.DATE
                  )}
                />
              )}
            </div>
            <CCValueField
              label="Complete"
              value={formatDisplayValue(
                valueGetter(nameOf("Date_Complete")),
                DATE_FORMAT.DATE
              )}
            />
          </div>
          {!isEmpty(valueGetter(nameOf("Outcome_Description"))) && (
            <div className="cc-form-cols-3">
              <CCValueField
                label={"Planner assessment"}
                value={valueGetter(nameOf("Outcome_Description"))}
              />
              <div className="cc-field">
                <label className="cc-label">Assessment date</label>
                <EnquiryField
                  name={nameOf("Date_Start")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
            </div>
          )}
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Description</label>
              <EnquiryField
                name={nameOf("Description")}
                placeholder={"Description"}
                component={CCTextArea}
                rows={3}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Comments</label>
              <EnquiryField
                name={nameOf("Comment1")}
                placeholder={"Comments"}
                component={CCTextArea}
                rows={3}
              />
            </div>
          </div>
        </section>
        <br />
        <hr className="cc-divider" />
        {/* ClosureDetails */}
        {valueGetter(nameOf("Status_ENUM")) === RegisterStatus.NotForVR && (
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <CCValueField
                label="Closure details"
                value={valueGetter(nameOf("Comment2"))}
              />
              <div className="cc-field">
                <label className="cc-label">Enquiry outcome</label>
                <EnquiryField
                  name={nameOf("Outcome_KWD")}
                  component={CCSearchComboBox}
                  data={pprEnquiryOutcome ?? []}
                  textField="Name"
                  dataItemKey="Keyword_ID"
                  isUseDefaultOnchange
                />
              </div>
            </div>
          </section>
        )}
      </FormElement>
    );
  }
);
