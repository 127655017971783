import { ICCRoute } from "@common/constants/ICCRoute";
export const homepageViewsRoute: ICCRoute = {
  path: "homepage-views",
  name: "Homepage Views",
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
