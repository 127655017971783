import { POOLS_OR_SPAS_MANAGE_ROUTE } from "@app/products/building/pools-or-spas/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewPoolsOrSpasButton = () => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New Pools / Spas"
      onClick={() => {
        history.push(`${POOLS_OR_SPAS_MANAGE_ROUTE}/new`);
      }}
    />
  );
};
