import { IProposedAssessment } from "@app/products/town-planning/ppr/components/action-bar/dialogs/officer-assessment/model";
import { putOfficerAssessment } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/officer-assessment/api";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class OfficerAssessmentButtonStore {
  private _isLoading: boolean = false;
  private _isShowDialog: boolean = false;
  private _officerAssessment: any;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isShowDialog() {
    return toJS(this._isShowDialog);
  }
  setIsShowDialog = (isShowDialog: boolean) => {
    runInAction(() => {
      this._isShowDialog = isShowDialog;
    });
  };

  get officerAssessment() {
    return toJS(this._officerAssessment);
  }
  setOfficerAssessment = (officerAssessment: any) => {
    runInAction(() => {
      this._officerAssessment = officerAssessment;
    });
  };

  updateOfficerAssessment = async (
    id: number,
    officerAssessment: IProposedAssessment
  ) => {
    this.setIsLoading(true);
    let newOfficerAssessment = undefined;
    let errorResponse = undefined;
    const response = await putOfficerAssessment(id, officerAssessment);

    if (isSuccessResponse(response)) {
      newOfficerAssessment = response?.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setOfficerAssessment(newOfficerAssessment);
    this.setIsLoading(false);
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isShowDialog = false;
      this._isLoading = false;
    });
  };
}
export const officerAssessmentButtonStore = new OfficerAssessmentButtonStore();
const officerAssessmentStoreButtonContext = createContext(
  officerAssessmentButtonStore
);
export const useOfficerAssessmentButtonStore = () => {
  return useContext(officerAssessmentStoreButtonContext);
};
