import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<EventView>();
export const colByTier4: IColumnFields[] = [
  {
    field: nameOf("Tier"),
    title: "Tier",
  },
];
