import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum DefermentMethodFieldValue {
  MANUALLY_JOURNALLED_AMOUNTS = "0",
  DEFERRABLE_BALANCES = "1",
  DEFERRABLE_LEVIES_RAISED = "2",
  ONE_OFF_AMOUNT = "3",
  TASMANIAN_DEFERMENT = "4",
}

export enum EKeysOfStepsCreateDeferment {
  Details = "Details",
  Assessment = "Assessment",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfSendSteps = [
  EKeysOfStepsCreateDeferment.Details,
  EKeysOfStepsCreateDeferment.Assessment,
];

export interface DTO_Workflow_CreateDeferment {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_CreateDeferment;
}

export interface DTO_WorkflowDetail_CreateDeferment {
  DefermentsDetails: DTO_Deferement_Detail;
}
export interface DTO_Deferement_Detail {
  Assessment_Deferment_Id: number;
  Assessment_Id: number;
  TypeId?: number;
  MethodId?: number;
  IsApplyFrom?: Date | null;
  IsApplyTo?: Date | null;
  Percentage: number;
  MaximumAmount: number | null;
  OverallMaximumAmount: number | null;
  ApplicationDate: Date | null;
  ApprovalDate: Date | null;
  Notes: string;
  DefermentDate?: Date | null;
  OneOffAmount?: number;
}

export interface DTO_Deferment_LOVs {
  dtoCreate_Deferment_LOVs: DTO_Create_Deferment_LOVs;
}

export interface DTO_Create_Deferment_LOVs {
  Type: DTO_LOV[];
  Method: DTO_LOV[];
}

export interface CreateDefermentRequestObjFromSpatial {
  AssessmentId: number | undefined;
}
