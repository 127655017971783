import {
  IModifyAreaOfResponsibilityDialog,
  ModifyAreaOfResponsibilityDialog,
} from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/_index";
import { useModifyAreaOfResponsibilityStore } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/store";
import Loading from "@components/loading/Loading";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const LoadWorkflowModifyAreaResponsibility = observer(
  (props: IModifyAreaOfResponsibilityDialog) => {
    const { id: certificateId } = useParams<{
      id?: string;
    }>();

    //store
    const { isLoading, workflowInitData, getWorkflowData, resetStore } =
      useModifyAreaOfResponsibilityStore();
    let modifyAreaOfResponsibility = { ...props };
    useEffect(() => {
      getWorkflowData({
        workflowDraftId: props?.dataFromActionList?.Workflow_Draft_Id,
        certificateId: isNil(certificateId) ? undefined : +certificateId,
      });
      return () => resetStore();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    if (isLoading) {
      return <Loading isLoading isFullScreen />;
    } else if (!isNil(workflowInitData))
      return (
        <ModifyAreaOfResponsibilityDialog {...modifyAreaOfResponsibility} />
      );
    return null;
  }
);
