import { SettingField } from "@app/products/property/system-admin/settings/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { TableFields } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/components/common-form-finance/components/table";
import { IOptionsDefaultFinance } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/components/common-form-finance/model";
import {
  ILookupItems,
  SettingFieldMoreOptions,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/payment-gateway/forms/payment-gateway-integration-settings/model";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { filter } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
interface ICommonFormFinance {
  item: SettingField & SettingFieldMoreOptions;
  options: IOptionsDefaultFinance;
  formRenderProps: FormRenderProps;
  optionsTable?: any;
}
export const CommonFormFinance = observer(
  ({ item, options, formRenderProps, optionsTable }: ICommonFormFinance) => {
    const { valueGetter, onChange } = formRenderProps;
    const { isEditing } = useSettingGlobalManageStore();
    const changeTypeValue = (value: string, lookupItems: ILookupItems[]) =>
      filter(lookupItems, { Id: value })[0];
    return (
      <>
        {item.DataType === "Lookup" && (
          <div className="cc-field">
            <label className="cc-label">
              {item?.Title ?? ""}
              {item?.HelpText && (
                <CCTooltip
                  type="custom"
                  content=" "
                  position="auto"
                  clickToOpen
                  customTemplate={<>{sanitizeHtml(item.HelpText)}</>}
                >
                  <i className="fa fa-info-circle ml-1 text-accent" />
                </CCTooltip>
              )}
            </label>
            {item?.Description && (
              <label className="cc-settings-description">
                {sanitizeHtml(item?.Description)}
              </label>
            )}
            <Field
              name={item.FieldName}
              component={CCDropDownList}
              data={item?.LookupItems}
              value={changeTypeValue(
                valueGetter(item.FieldName),
                item.LookupItems
              )}
              textField="Name"
              dataItemKey="Id"
              disabled={options.disabled || !isEditing}
              onChange={(e: DropDownListChangeEvent) => {
                onChange(item?.FieldName, {
                  value: e.value.Id,
                });
              }}
            />
          </div>
        )}
        {item?.DataType === "Boolean" && (
          <div className="cc-field">
            <label className="cc-label">
              {item?.Title ?? ""}
              {item?.HelpText && (
                <CCTooltip
                  type="custom"
                  content=" "
                  position="auto"
                  clickToOpen
                  customTemplate={<>{sanitizeHtml(item.HelpText)}</>}
                >
                  <i className="fa fa-info-circle ml-1 text-accent" />
                </CCTooltip>
              )}
            </label>
            {item?.Description && (
              <label className="cc-settings-description">
                {sanitizeHtml(item?.Description)}
              </label>
            )}
            <Field
              name={item?.FieldName}
              component={CCSwitch}
              disabled={!isEditing}
              checked={valueGetter(item?.FieldName)}
            />
          </div>
        )}

        {item?.DataType === "DateTime" && (
          <div className="cc-field">
            <label className="cc-label">
              {item?.Title ?? ""}
              {item?.HelpText && (
                <CCTooltip
                  type="custom"
                  content=" "
                  position="auto"
                  clickToOpen
                  customTemplate={<>{sanitizeHtml(item.HelpText)}</>}
                >
                  <i className="fa fa-info-circle ml-1 text-accent" />
                </CCTooltip>
              )}
            </label>
            {item?.Description && (
              <label className="cc-settings-description">
                {sanitizeHtml(item?.Description)}
              </label>
            )}
            <Field
              name={item?.FieldName}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              component={CCDateTimePicker}
              disabled={!isEditing}
            />
          </div>
        )}

        {item.DataType === "Int" && options.typeField === "" && (
          <div className="cc-field">
            <label className="cc-label">
              {item?.Title ?? ""}
              {item?.IsMandatory && (
                <CCTooltip type="validator" position="right" />
              )}
              {item?.HelpText && (
                <CCTooltip
                  type="custom"
                  content=" "
                  position="auto"
                  clickToOpen
                  customTemplate={<>{sanitizeHtml(item.HelpText)}</>}
                >
                  <i className="fa fa-info-circle ml-1 text-accent" />
                </CCTooltip>
              )}
            </label>
            {item?.Description && (
              <label className="cc-settings-description">
                {sanitizeHtml(item?.Description)}
              </label>
            )}
            <Field
              name={item.FieldName}
              component={CCNumericTextBox}
              placeholder={item.Title}
              disabled={!isEditing}
              validator={item?.IsMandatory ? requiredValidator : undefined}
            />
          </div>
        )}

        {item.DataType === "String" && options.typeField === "" && (
          <div className="cc-field">
            <label className="cc-label">
              {item?.Title ?? ""}
              {item?.IsMandatory && (
                <CCTooltip type="validator" position="right" />
              )}
              {item?.HelpText && (
                <CCTooltip
                  type="custom"
                  content=" "
                  position="auto"
                  clickToOpen
                  customTemplate={<>{sanitizeHtml(item.HelpText)}</>}
                >
                  <i className="fa fa-info-circle ml-1 text-accent" />
                </CCTooltip>
              )}
            </label>
            {item?.Description && (
              <label className="cc-settings-description">
                {sanitizeHtml(item?.Description)}
              </label>
            )}
            <Field
              name={item.FieldName}
              component={CCInput}
              placeholder={item.Title}
              disabled={!isEditing}
              validator={
                item?.IsMandatory
                  ? requiredValidator
                  : options?.validate
                  ? options?.validate?.checkValidate
                  : undefined
              }
            />
          </div>
        )}
        {item.DataType === "String" && options.typeField === "password" && (
          <div className="cc-field">
            <label className="cc-label">
              {item?.Title ?? ""}
              {item?.IsMandatory && (
                <CCTooltip type="validator" position="right" />
              )}
              {item?.HelpText && (
                <CCTooltip
                  type="custom"
                  content=" "
                  position="auto"
                  clickToOpen
                  customTemplate={<>{sanitizeHtml(item.HelpText)}</>}
                >
                  <i className="fa fa-info-circle ml-1 text-accent" />
                </CCTooltip>
              )}
            </label>
            {item?.Description && (
              <label className="cc-settings-description">
                {sanitizeHtml(item?.Description)}
              </label>
            )}
            <Field
              name={item.FieldName}
              component={CCInput}
              placeholder={item.Title}
              disabled={!isEditing}
              type="password"
              validator={
                item?.IsMandatory
                  ? requiredValidator
                  : options?.validate
                  ? options?.validate?.checkValidate
                  : undefined
              }
            />
          </div>
        )}
        {options.typeField === "numericTextBox" && (
          <div className="cc-field">
            <label className="cc-label">
              {item?.Title ?? ""}
              {item?.IsMandatory && (
                <CCTooltip type="validator" position="right" />
              )}
              {item?.HelpText && (
                <CCTooltip
                  type="custom"
                  content=" "
                  position="auto"
                  clickToOpen
                  customTemplate={<>{sanitizeHtml(item.HelpText)}</>}
                >
                  <i className="fa fa-info-circle ml-1 text-accent" />
                </CCTooltip>
              )}
            </label>
            {item?.Description && (
              <label className="cc-settings-description">
                {sanitizeHtml(item?.Description)}
              </label>
            )}
            <Field
              name={item.FieldName}
              component={CCNumericTextBox}
              placeholder={item.Title}
              disabled={!isEditing}
              validator={item?.IsMandatory ? requiredValidator : undefined}
            />
          </div>
        )}

        {options.typeField === "textArea" && (
          <div className="cc-field">
            <label className="cc-label">
              {item?.Title ?? ""}
              {item?.IsMandatory && (
                <CCTooltip type="validator" position="right" />
              )}
              {item?.HelpText && (
                <CCTooltip
                  type="custom"
                  content=" "
                  position="auto"
                  clickToOpen
                  customTemplate={<>{sanitizeHtml(item.HelpText)}</>}
                >
                  <i className="fa fa-info-circle ml-1 text-accent" />
                </CCTooltip>
              )}
            </label>
            {item?.Description && (
              <label className="cc-settings-description">
                {sanitizeHtml(item?.Description)}
              </label>
            )}
            <Field
              name={item.FieldName}
              component={CCTextArea}
              placeholder={item.Title}
              disabled={!isEditing}
              validator={item?.IsMandatory ? requiredValidator : undefined}
              rows={options.rowsField}
            />
          </div>
        )}

        {options.typeField === "table" && (
          <TableFields
            formRenderProps={formRenderProps}
            optionsTable={optionsTable}
          />
        )}

        {options.typeField === "textOnly" && (
          <div className="cc-field">
            <label className="cc-label">
              {item?.Title ?? ""}
              {item?.IsMandatory && (
                <CCTooltip type="validator" position="right" />
              )}
              {item?.HelpText && (
                <CCTooltip
                  type="custom"
                  content=" "
                  position="auto"
                  clickToOpen
                  customTemplate={<>{sanitizeHtml(item.HelpText)}</>}
                >
                  <i className="fa fa-info-circle ml-1 text-accent" />
                </CCTooltip>
              )}
            </label>
            {item?.Description && (
              <label className="cc-settings-description">
                {sanitizeHtml(item?.Description)}
              </label>
            )}
            <div className="cc-label-value">{item?.Value}</div>
            {item?.Description && (
              <label className="cc-settings-description">
                {sanitizeHtml(item?.Description)}
              </label>
            )}
          </div>
        )}
      </>
    );
  }
);
