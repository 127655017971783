import { cloneDeep } from "lodash";
import {
  ContactRelationshipFormData,
  ContactRelationshipRequest,
  ContactRelationshipResponse,
} from "./model";

export const convertContactResponseToForm = (
  response: ContactRelationshipResponse
): ContactRelationshipFormData => {
  let form = new ContactRelationshipFormData();
  form = cloneDeep(response) as ContactRelationshipFormData;

  form.Position = response.Contact?.Extension.JobTitle || "";
  form.SecondLanguage = response.Contact?.Extension.SecondLanguage || "";
  form.LicenceNo = response.Contact?.Extension.LicenceNo || "";

  return form;
};

export const convertContactFormToRequest = (
  form: ContactRelationshipFormData
): ContactRelationshipRequest => {
  let request = new ContactRelationshipRequest();
  request = cloneDeep(form);

  return request;
};
