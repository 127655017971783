import { useMasterPropertyStore } from "@app/products/property/assessments/master-properties/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import ExistedMasterProperties from "./components/forms/existed/_index";
import { NewMasterProperties } from "./components/forms/new/_index";

function ManageMasterProperties() {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const masterPropertyId = params.id;
  const { resetMenu } = useCCSubActionBarStore();
  const { loadMasterProperty, resetStore, isLoading } =
    useMasterPropertyStore();

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });

  useEffect(() => {
    if (isNew) return;
    loadMasterProperty(parseInt(masterPropertyId));
  }, [masterPropertyId, isNew, loadMasterProperty]);

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;
  if (isNew) {
    return <NewMasterProperties />;
  }
  return <ExistedMasterProperties />;
}

export default observer(ManageMasterProperties);
