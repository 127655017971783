export const mockInspectionData = {
  Property: [
    {
      PropertyAddress: "23 Albuera St, B...",
      LegalDescription: "Lot 1 STR 13963..",
      Owner: "I S Middleton & J ...",
      LandArea: "457 m2",
      AssessNumber: 2214889,
      ParcelId: 231040,
    },
  ],
  CommentsInPreviousCases: [],
  Date: "10-Jan-2022 17:58",
  LastInspection: "7-Jan-2022 17:04",
  Outcome: "No Hazard",
  OutcomeValues: [
    { Code: "1", Name: "Cleared, No Hazard" },
    { Code: "2", Name: "No Hazard" },
    { Code: "3", Name: "Not Cleared, Set Contractor to clear" },
    { Code: "4", Name: "Owners to clear property" },
    { Code: "5", Name: "Site to be inspected" },
  ],
  Clearance: "No Hazard",
  ClearanceValues: [
    { Code: "1", Name: "20m Fire Break" },
    { Code: "2", Name: "30m Fire Break" },
    { Code: "3", Name: "5m Fire Break" },
    { Code: "4", Name: "Case on Hold" },
    { Code: "5", Name: "Clear Entire Property" },
    { Code: "6", Name: "No Hazard" },
    { Code: "7", Name: "Remove All Undergrowth" },
    { Code: "8", Name: "To be assessed" },
    { Code: "9", Name: "Withdrawn" },
  ],
};
