import { DTO_Parcel } from "@app/products/property/components/dialogs/add-parcel-lookup/model";

export const calculationMaxFactorPercentage = (dataRow: DTO_Parcel) => {
  /*
    calculate Association Factor Percentage maximum can be allocated =>
    In Create Assessment the Maximum = 100% - AssociatedAssessmentAreaTotal
    In Modify/Reactivate Assessment the Maximum = AssociatedPICAreaRemaining + AssociatedPICArea
    The AssociatedPICArea will return in the API & it is the stable variable
  */
  return dataRow?.isNewParcel
    ? 100 - (dataRow?.AssociatedAssessmentAreaTotal ?? 0)
    : (dataRow?.AssociatedAssessmentAreaRemaining ?? 0) +
        (dataRow?.AssociationFactorPercentage ?? 0);
};
