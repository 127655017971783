import { NewSchemeAccountDialog } from "@app/products/property/schemes/[id]/components/dialogs/new-scheme-account/_index";
import { useSchemesStore } from "@app/products/property/schemes/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const CreateSchemeAccountButton = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { schemes } = useSchemesStore();
  const { pushNotification } = useCCAppNotificationStore();
  const handleSubmit = (
    values: {
      [name: string]: any;
    },
    event?: React.SyntheticEvent<any>
  ) => {
    setShowDialog(false);
    pushNotification({
      closable: true,
      title: "Scheme accounts created successfully.",
      type: "success",
    });
  };
  return (
    <>
      <CCNavButton
        title="Create scheme account"
        onClick={() => {
          setShowDialog(true);
        }}
      />
      {showDialog && schemes ? (
        <NewSchemeAccountDialog
          schemeInfo={schemes}
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={handleSubmit}
        />
      ) : null}
    </>
  );
});
