import { LocalityResult } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/locality/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const searchLocalityConfig = {
  typeSpeed: 300,
  minCharacters: 3,
};

const nameOf = nameOfFactory<LocalityResult>();

export const colSearchLocality: IColumnFields[] = [
  {
    field: nameOf("Locality"),
    title: "Locality",
    width: 200,
  },
  {
    field: nameOf("PostCode"),
    title: "Post Code",
    width: 150,
  },
  {
    field: nameOf("State"),
    title: "State",
    width: 100,
  },
  {
    field: nameOf("LGA"),
    title: "LGA",
    width: 100,
  },
];
