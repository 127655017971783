/* eslint-disable react-hooks/exhaustive-deps */
import { getViewConfigurations } from "@app/products/property/api";
import { VO_Assessment_w_Detail } from "@app/products/property/assessments/list/model";
import { AddPICLookupDialog } from "@app/products/property/components/dialogs/add-pic-lookup/_index";
import { DTO_PIC } from "@app/products/property/components/dialogs/add-pic-lookup/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  EListSubmitButton,
  ViewConfiguration,
} from "@app/products/property/model";
import {
  colHoldingAmalgamate,
  colPICAmalgamate,
} from "@app/products/property/pic/list/components/action-bar/form-steps/amalgamate-pic/components/form-element/associations/config";
import {
  EAmalgamatePICGridType,
  EDialogStepAssociationAmalgamate,
  EErrorStandardAssociation,
} from "@app/products/property/pic/list/components/action-bar/form-steps/amalgamate-pic/components/form-element/associations/model";
import { validatorAmalgamatePICAssociation } from "@app/products/property/pic/list/components/action-bar/form-steps/amalgamate-pic/components/form-element/associations/util";
import { VO_PIC } from "@app/products/property/pic/list/model";
import { processDynamicColumns } from "@app/products/property/util";
import { BOOLEAN_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { difference, differenceBy, isNil, remove, union } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

export const AssociationAmalgamatePICFormStep = (props: IFormStepElement) => {
  const { options } = props;
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={
        options?.isReadOnly ? undefined : validatorAmalgamatePICAssociation
      }
    />
  );
};

const nameOfPIC = nameOfFactory<VO_PIC>();
const nameOfHolding = nameOfFactory<VO_Assessment_w_Detail>();
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isDifferentType: false,
    },
    setLoadFailedStep,
    setIsLoadingStep,
    isLoadingStep,
    loadFailedStep,
  }: IFormStepElement) => {
    //store
    const { valueGetter, onChange, errors, onSubmit } = formRenderProps;

    //Get labels
    const [assessmentsLabel, assessmentLowercaseLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.Assessments,
        ECustomColNameProperty.AssessmentLowercase,
      ]);

    //get value fields
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const listError = errors[nameOf("")]?.split("_");
    //state
    const [isShowDialog, setIsShowDialog] = useState<
      EDialogStepAssociationAmalgamate | undefined
    >();
    const [columnsPIC, setColumnsPIC] =
      useState<IColumnFields[]>(colPICAmalgamate);
    const [columnsHolding, setColumnsHolding] =
      useState<IColumnFields[]>(colHoldingAmalgamate);

    //check different PIC Type
    const currentPICIds = getFieldValue("PIC_Ids");
    if (
      options?.processNewPicId?.isDifferentType &&
      differenceBy(currentPICIds, options?.processNewPicId?.listIDs)?.length
    ) {
      onChange(nameOf("PIC_Ids"), {
        value: options?.processNewPicId?.listIDs,
      });
      options?.processNewPicId?.setIsDifferentType(false);
    }

    const selectedPIC = getFieldValue("_option.PICSelected") ?? [];
    const selectedHolding = getFieldValue("_option.HoldingSelected") ?? [];
    const pic = getFieldValue("_option.PICs") ?? [];
    const holding = getFieldValue("_option.Primary_Assessments") ?? [];
    const primaryHolding = getFieldValue("Selected_Primary_Assessment_Id");
    const primaryPIC = getFieldValue("Selected_Primary_PIC_Id");

    /**
     * Load view configuration
     * Holding and PIC
     */
    const loadViewConfiguration = async () => {
      setIsLoadingStep(true);
      const response = await Promise.all([
        getViewConfigurations(ViewConfiguration.Assessment_List),
        getViewConfigurations(ViewConfiguration.OData_PIC),
      ]);
      setIsLoadingStep(false);
      const [viewHolding, viewPIC] = response;
      if (
        Array.isArray(response) &&
        !viewHolding?.data?.hasOwnProperty("MessageType") &&
        !viewPIC?.data?.hasOwnProperty("MessageType")
      ) {
        if (viewHolding?.data) {
          const newColumnHolding = processDynamicColumns(
            colHoldingAmalgamate,
            viewHolding?.data?.ColumnDefinitions?.Columns
          );
          newColumnHolding.splice(1, 0, {
            field: "Is_Primary",
            title: "Is Primary Holding",
            format: BOOLEAN_FORMAT.BOOLEAN,
          });
          setColumnsHolding(newColumnHolding);
        }
        if (viewPIC?.data) {
          const newColumnPIC = processDynamicColumns(
            colPICAmalgamate,
            viewPIC?.data?.ColumnDefinitions?.Columns
          );
          newColumnPIC.splice(1, 0, {
            field: "Is_Primary",
            title: "Is Primary PIC",
            format: BOOLEAN_FORMAT.BOOLEAN,
          });
          setColumnsPIC(newColumnPIC);
        }
      } else {
        setLoadFailedStep({
          onReload: () => loadViewConfiguration(),
          responseError: {
            status: APIResponseStatus.INTERNAL_SERVER_ERROR,
            error: "Load view configuration failed",
          },
        });
      }
    };

    /**
     * get initial view configure
     */
    useEffectOnce(() => {
      (async () => {
        await loadViewConfiguration();
      })();
    });

    /**
     * Submit and Reload form
     */
    const submitForm = async () => {
      onSubmit({
        currentTarget: {
          id: EListSubmitButton.Save,
        },
      } as any);
    };

    /**
     * handle add item (Holding and PIC)
     * @param data
     * @param field
     */
    const handleAddItemPIC = async (data: DTO_PIC[]) => {
      const picLookupIds = data?.map((item) => item.PIC_Id) ?? [];
      const picIds = getFieldValue("PIC_Ids") ?? [];
      const differentListID = difference(picLookupIds, picIds);
      if (differentListID) {
        const concatListPIC = union(picIds, picLookupIds);
        onChange(nameOf("PIC_Ids"), {
          value: concatListPIC,
        });
        await submitForm();
      }
    };

    /**
     * handle delete item
     * @param field
     */
    const handleDeleteItemPIC = async () => {
      //get selected pic
      const aPIC: VO_PIC = selectedPIC?.[0];
      const picIds = getFieldValue("PIC_Ids") ?? [];
      const picId = aPIC?.PIC_Id;
      remove(picIds, (id: number) => id === picId);
      onChange(nameOf("PIC_Ids"), { value: picIds });
      submitForm();
    };

    /**
     * disable button primary when having one primary holding
     */
    const isDisabledPrimaryHolding = useMemo(() => {
      const selectHolding: VO_Assessment_w_Detail = selectedHolding?.[0];
      return selectHolding?.Assessment_Id === primaryHolding;
    }, [primaryHolding, selectedHolding]);

    /**
     * disable button primary when having one primary pic
     */
    const isDisabledPrimaryPIC = useMemo(() => {
      const selectPIC: VO_PIC = selectedPIC?.[0];
      return isNil(selectPIC?.PIC_Id) && isNil(primaryPIC)
        ? false
        : selectPIC?.PIC_Id === primaryPIC;
    }, [primaryHolding, selectedPIC]);

    /**
     * handle set primary holding
     */
    const handleSetPrimary = async (mode: EAmalgamatePICGridType) => {
      //Holding
      if (mode === EAmalgamatePICGridType.Holding) {
        //get selected
        const selectHolding: VO_Assessment_w_Detail = selectedHolding?.[0];
        //get current id selected
        const holdingId = selectHolding?.Assessment_Id;
        //set only one holding (selected) and reset False for left holding
        const newHolding = holding?.map((item: VO_Assessment_w_Detail) => {
          return {
            ...item,
            Is_Primary: item?.Assessment_Id === holdingId,
          };
        });
        //update new list Holdings
        onChange(nameOf("_option.Primary_Assessments"), {
          value: newHolding,
        });
        onChange(nameOf("Selected_Primary_Assessment_Id"), {
          value: holdingId,
        });
      } else if (mode === EAmalgamatePICGridType.PIC) {
        //get selected
        const selectPIC: VO_PIC = selectedPIC?.[0];
        //get current id selected
        const picId = selectPIC?.PIC_Id;
        //set only one pic (selected) and reset False for left pic
        const newPIC = pic?.map((item: VO_PIC) => {
          return {
            ...item,
            Is_Primary: item?.PIC_Id === picId,
          };
        });
        //update new list PIC
        onChange(nameOf("_option.PICs"), {
          value: newPIC,
        });
        onChange(nameOf("Selected_Primary_PIC_Id"), { value: picId });
      }
      if (
        mode === EAmalgamatePICGridType.Holding ||
        mode === EAmalgamatePICGridType.PIC
      ) {
        await submitForm();
      }
    };
    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }
    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }
    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Association date" />
              <Field
                name={nameOf("AssociationDate")}
                disabled={options?.isReadOnly}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel
                title="PIC's to be amalgamated"
                isMandatory
                errorMessage={
                  errors?.[nameOf("")]?.length &&
                  listError?.includes(EErrorStandardAssociation.PIC)
                    ? "More than one PIC should be associated and it is mandatory to set the primary PIC."
                    : undefined
                }
              />
              <CCGrid
                data={pic ?? []}
                columnFields={columnsPIC}
                selectableMode="single"
                primaryField={nameOfPIC("PIC_Id")}
                selectedRows={selectedPIC}
                onSelectionChange={(dataItems) => {
                  onChange(nameOf("_option.PICSelected"), {
                    value: dataItems,
                  });
                }}
                readOnly={options?.isReadOnly}
                toolbar={
                  !options?.isReadOnly ? (
                    <div className="cc-grid-tools-bar">
                      <Button
                        themeColor="primary"
                        title="Set as primary"
                        disabled={
                          selectedPIC.length !== 1 || isDisabledPrimaryPIC
                        }
                        onClick={() =>
                          handleSetPrimary(EAmalgamatePICGridType.PIC)
                        }
                      >
                        Set as primary
                      </Button>
                      <Button
                        iconClass="fas fa-plus"
                        title="Add a pic"
                        onClick={() => {
                          setIsShowDialog(
                            EDialogStepAssociationAmalgamate.PICNew
                          );
                        }}
                      />
                      <Button
                        iconClass="fas fa-minus"
                        title="Remove a pic"
                        onClick={() =>
                          setIsShowDialog(
                            EDialogStepAssociationAmalgamate.PICDelete
                          )
                        }
                        disabled={selectedPIC.length !== 1}
                      />
                    </div>
                  ) : null
                }
              />
            </div>
            <div className="cc-field">
              <CCLabel
                title={assessmentsLabel}
                isMandatory
                errorMessage={
                  errors?.[nameOf("")]?.length &&
                  listError?.includes(EErrorStandardAssociation.Holding)
                    ? `The primary ${assessmentLowercaseLabel} needs to be set.`
                    : undefined
                }
              />
              <CCGrid
                data={holding ?? []}
                columnFields={columnsHolding}
                selectableMode="single"
                primaryField={nameOfHolding("Assessment_Id")}
                selectedRows={selectedHolding}
                onSelectionChange={(dataItems) => {
                  onChange(nameOf("_option.HoldingSelected"), {
                    value: dataItems,
                  });
                }}
                readOnly={options?.isReadOnly}
                toolbar={
                  !options?.isReadOnly ? (
                    <div className="cc-grid-tools-bar">
                      <Button
                        themeColor="primary"
                        title="Set as primary"
                        disabled={
                          selectedHolding.length !== 1 ||
                          isDisabledPrimaryHolding
                        }
                        onClick={() =>
                          handleSetPrimary(EAmalgamatePICGridType.Holding)
                        }
                      >
                        Set as primary
                      </Button>
                    </div>
                  ) : null
                }
              />
            </div>
          </div>
        </section>
        {isShowDialog === EDialogStepAssociationAmalgamate.PICNew && (
          <AddPICLookupDialog
            onClose={() => setIsShowDialog(undefined)}
            handleAddPIC={async (data: DTO_PIC[]) => {
              setIsShowDialog(undefined);
              await handleAddItemPIC(data);
            }}
          />
        )}
        {isShowDialog === EDialogStepAssociationAmalgamate.PICDelete && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={`Any associated ${assessmentsLabel} will also be removed.`}
            onClosePopup={() => setIsShowDialog(undefined)}
            onConfirmYes={handleDeleteItemPIC}
          />
        )}
      </>
    );
  }
);
