import { DTO_Transaction_Item } from "@app/products/property/supplementary-rates/[id]/components/child-screens/transaction/model";
import { useSupplementaryRatesTransactionStore } from "@app/products/property/supplementary-rates/[id]/components/child-screens/transaction/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const nameOf = nameOfFactory<DTO_Transaction_Item>();

export const SupplementaryTransactionAllocation = observer(
  ({ dataItem }: GridDetailRowProps) => {
    const { id } = useParams<{ id: string }>();
    const {
      transactionsDetail,
      columnFieldsDetail,
      loadSupplementaryRatesTransactionDetail,
      isLoadingDetail,
    } = useSupplementaryRatesTransactionStore();

    useEffect(() => {
      if (!id) return;
      loadSupplementaryRatesTransactionDetail(
        parseInt(id),
        dataItem.Transaction_Id
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dataItem.Transaction_Id]);

    return (
      <>
        <label className="cc-label">Transaction allocation</label>
        <CCGrid
          data={
            transactionsDetail?.TransactionAllocations.TransactionItem ?? []
          }
          columnFields={columnFieldsDetail}
          primaryField={nameOf("ChargeBalancesId")}
          isLoading={isLoadingDetail}
        />
      </>
    );
  }
);
