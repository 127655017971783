import { DTO_TitleLrsOwner } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { ICON_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_TitleLrsOwner>();
export const titleOwnersCol: IColumnFields[] = [
  {
    field: nameOf("Family_Name"),
    title: "Family Name",
  },
  { field: nameOf("Given_Name"), title: "Given Name" },
  {
    field: nameOf("Corporate_Miscellaneous_Name"),
    title: "Corporate Miscellaneous Name",
  },
  {
    field: nameOf("Address"),
    title: "Address",
  },
  {
    field: nameOf("IsPreviousOwner"),
    title: "Previous LRS Owner",
    format: ICON_FORMAT.ICON_BOOLEAN_WITHOUT_CROSS,
  },
];
