import { history } from "@/AppRoutes";
import {
  getBuildingEssentialSafetyById,
  postBuildingEssentialSafety,
  putBuildingEssentialSafety,
} from "@app/products/building/registers/essential-safety/[id]/api";
import { ESSENTIAL_SAFETY_MANAGE_ROUTE } from "@app/products/building/registers/essential-safety/[id]/constant";
import {
  IBuildingEssentialSafetyRequest,
  IBuildingEssentialSafetyResponse,
} from "@app/products/building/registers/essential-safety/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class BuildingRegistersEssentialSafetyStore {
  private _buildingRegistersEssentialSafety?: IBuildingEssentialSafetyResponse =
    undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get buildingRegistersEssentialSafety() {
    return toJS(this._buildingRegistersEssentialSafety);
  }
  setBuildingRegistersEssentialSafety = (
    buildingRegistersEssentialSafety?: IBuildingEssentialSafetyResponse
  ) => {
    runInAction(() => {
      this._buildingRegistersEssentialSafety = buildingRegistersEssentialSafety;
    });
  };

  get buildingRegistersEssentialSafetyId() {
    return toJS(this.buildingRegistersEssentialSafety?.ID);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._buildingRegistersEssentialSafety = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadBuildingRegistersEssentialSafety = async (
    buildingRegistersEssentialSafetyId: number
  ) => {
    this.setIsLoading(true);
    const response = await getBuildingEssentialSafetyById(
      buildingRegistersEssentialSafetyId
    );
    let errorResponse = undefined;
    let newBuildingRegistersEssentialSafety = undefined;
    if (isSuccessResponse(response)) {
      newBuildingRegistersEssentialSafety = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setBuildingRegistersEssentialSafety(
      newBuildingRegistersEssentialSafety
    );
    this.setIsLoading(false);
  };

  createBuildingEssentialSafety = async (
    data: IBuildingEssentialSafetyRequest
  ) => {
    this.setIsLoading(true);
    await postBuildingEssentialSafety(data);
    appNotificationStore.pushNotification({
      title: "Building essential safety created successfully",
      type: "success",
      onClose: () => {
        history.replace(`${ESSENTIAL_SAFETY_MANAGE_ROUTE}/${data?.ID ?? 1}`);
      },
    });
    this.setIsLoading(false);
  };

  updateBuildingEssentialSafety = async (
    data: IBuildingEssentialSafetyRequest
  ) => {
    this.setIsLoading(true);
    await putBuildingEssentialSafety(data);
    appNotificationStore.pushNotification({
      title: "Building essential safety updated successfully",
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const buildingRegistersEssentialSafetyStoreContext = createContext(
  new BuildingRegistersEssentialSafetyStore()
);
export const useBuildingRegistersEssentialSafetyStore = () => {
  return useContext(buildingRegistersEssentialSafetyStoreContext);
};
