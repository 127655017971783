import { IProposedAssessment } from "@app/products/town-planning/ppr/components/action-bar/dialogs/officer-assessment/model";
import { OfficerAssessmentDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/officer-assessment/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putOfficerAssessment } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/officer-assessment/api";
import { useOfficerAssessmentButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/officer-assessment/store";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const OfficerAssessmentButton = observer(() => {
  const { pprId, ppr, onSubmit, setPPRWithLoading } = usePPRStore();
  const { isShowDialog, setIsShowDialog } = useOfficerAssessmentButtonStore();
  const [isLoading, setIsLoading] = useState(false);

  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();

  const isVisible = useMemo(() => {
    return ppr?.Status_ENUM === Application_Status.AllocatedToPlanner;
  }, [ppr]);

  const handleSubmitOfficerAssessment = async (data: any) => {
    // TODO: Remove dataSubmit later
    const dataSubmit = {
      ...data,
      SaveTriggers: data.SaveTriggers ?? [],
      _option: undefined,
    };

    if (pprId) {
      setIsLoading(true);
      const response = await putOfficerAssessment(pprId, dataSubmit);
      setIsLoading(false);
      setIsShowDialog(false);

      if (isSuccessIdentityPacket(response)) {
        clearErrorNotification();
        setPPRWithLoading(response.data.Application);
        pushNotification({
          title: "Application assessed successfully.",
          type: "success",
        });
      } else {
        pushNotification({
          autoClose: false,
          title: "Assessing application failed.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    }
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Officer assessment"
        onClick={onSubmit}
        name={PPRSubmitActions.OfficerAssessment}
      />
      {isShowDialog && (
        <OfficerAssessmentDialog
          isLoading={isLoading}
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={(data: IProposedAssessment) => {
            handleSubmitOfficerAssessment(data);
          }}
        />
      )}
    </>
  ) : null;
});
