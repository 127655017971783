import { loadTownPlanningContact } from "@app/products/town-planning/[id]/components/reference-sidebar/contact/api";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";
import { ContactsSidebar } from "@common/pages/contacts/contacts-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const TownPlanningContactsTab = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ICommonContactItemRender[] | undefined>(
    undefined
  );
  useEffect(() => {
    setIsLoading(true);
    loadTownPlanningContact().then((data) => {
      setData(data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <Loading isLoading={isLoading} />;

  return <ContactsSidebar data={data || []} />;
});
