import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { getModuleNameByID } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/process-external/forms/components/process-external-action/util";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { FormRenderProps } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import "./_index.scss";

export type IExternalInterfaceForModulesProps = {
  formRenderProps: FormRenderProps;
};

const ExternalInterfaceForModules = ({
  formRenderProps,
}: IExternalInterfaceForModulesProps) => {
  const { valueGetter, onChange, modified } = formRenderProps;
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();

  //Value getter
  const baseURL = configData
    ? valueGetter(configData["ProcessExternalURL"]?.FieldName)
    : "";
  const initExampleURL = configData ? configData["ExampleURL"]?.Value : "";
  const module = configData ? valueGetter(configData["Module"]?.FieldName) : "";
  const source = configData ? valueGetter(configData["Source"]?.FieldName) : "";
  const refNo = configData
    ? valueGetter(configData["ReferenceNo"]?.FieldName)
    : "";
  const assessmentNo = configData
    ? valueGetter(configData["AssessmentNumber"]?.FieldName)
    : "";
  const latitude = configData
    ? valueGetter(configData["Latitude"]?.FieldName)
    : "";
  const longitude = configData
    ? valueGetter(configData["Longitude"]?.FieldName)
    : "";
  const categoryIdentifier = configData
    ? valueGetter(configData["CategoryIdentifier"]?.FieldName)
    : "";
  const narIds = configData ? valueGetter(configData["NARID"]?.FieldName) : "";
  const nameSearch = configData
    ? valueGetter(configData["NameSearch"]?.FieldName)
    : "";

  //States
  const [exampleURL, setExampleURL] = useState<string>("");

  //Handle events
  const handleChangeExampleURL = (value: string, field: string) => {
    onChange(field, { value: value });
    if (!configData) return;

    const moduleId = field === configData["Module"]?.FieldName ? value : module;
    let moduleParam: string = getModuleNameByID(+moduleId);

    let url: string = `${baseURL}Module=${
      moduleParam ? moduleParam : "crms"
    }&source=${source}`;

    const refNoParam =
      field === configData["ReferenceNo"]?.FieldName ? value : refNo;
    if (refNoParam) {
      url = `${url}&refno=${refNoParam}`;
    } else {
      const assessmentNoParam =
        field === configData["AssessmentNumber"]?.FieldName
          ? value
          : assessmentNo;
      if (assessmentNoParam) url = `${url}&id=${assessmentNoParam}`;

      const latitudeParam =
        field === configData["Latitude"]?.FieldName ? value : latitude;
      if (latitudeParam) url = `${url}&latitude=${latitudeParam}`;

      const longitudeParam =
        field === configData["Longitude"]?.FieldName ? value : longitude;
      if (longitudeParam) url = `${url}&longitude=${longitudeParam}`;

      const categoryIdentifierParam =
        field === configData["CategoryIdentifier"]?.FieldName
          ? value
          : categoryIdentifier;
      if (categoryIdentifierParam)
        url = `${url}&category=${categoryIdentifierParam}`;

      const narIdsParam =
        field === configData["NARID"]?.FieldName ? value : narIds;
      if (narIdsParam) url = `${url}&narids=${narIdsParam}`;

      const nameSearchParam =
        field === configData["NameSearch"]?.FieldName ? value : nameSearch;
      if (nameSearchParam) url = `${url}&names=${nameSearchParam}`;
    }

    setExampleURL(url);
  };

  const handleExternalAction = async () => {
    if (isNil(configData)) return;
    if (!isEditing || !modified) return;
    window.open(exampleURL, "_blank");
  };

  return configData ? (
    <section className="cc-field-group">
      <CCValueField
        name={configData["ProcessExternalURL"]?.FieldName ?? ""}
        label={configData["ProcessExternalURL"]?.Title ?? ""}
        value={configData["ProcessExternalURL"]?.Value ?? ""}
      />
      <div className="cc-field">
        <CCLabel title="Parameters" />
      </div>
      <div className="cc-custom-sub-panel-bar cc-field-group">
        <div className="cc-form-cols-3">
          <CCSearchComboBoxGlobalSettings
            data={configData["Module"]}
            isEditing={isEditing}
            onChange={(event: ComboBoxChangeEvent) => {
              handleChangeExampleURL(
                event.value,
                configData["Module"]?.FieldName
              );
            }}
          />
          <CCValueField label="&nbsp;" value="crms" />
        </div>
        <div className="cc-form-cols-3">
          <CCValueField
            name={configData["Source"]?.FieldName ?? ""}
            label={configData["Source"]?.Title ?? ""}
            value={configData["Source"]?.Value ?? ""}
          />
        </div>
        <div className="cc-form-cols-3">
          <InputGlobalSettings
            data={configData["AssessmentNumber"]}
            isEditing={isEditing}
            onChange={(event: InputChangeEvent) => {
              handleChangeExampleURL(
                event.value,
                configData["AssessmentNumber"]?.FieldName
              );
            }}
          />
          {module === PRODUCT_TYPE_NUMBER.CustomerService.toString() && (
            <>
              <InputGlobalSettings
                data={configData["Latitude"]}
                isEditing={isEditing}
                onChange={(event: InputChangeEvent) => {
                  handleChangeExampleURL(
                    event.value,
                    configData["Latitude"]?.FieldName
                  );
                }}
              />
              <InputGlobalSettings
                data={configData["Longitude"]}
                isEditing={isEditing}
                onChange={(event: InputChangeEvent) => {
                  handleChangeExampleURL(
                    event.value,
                    configData["Longitude"]?.FieldName
                  );
                }}
              />
            </>
          )}
        </div>
        {module === PRODUCT_TYPE_NUMBER.CustomerService.toString() && (
          <>
            <div className="cc-form-cols-3">
              <InputGlobalSettings
                data={configData["CategoryIdentifier"]}
                isEditing={isEditing}
                onChange={(event: InputChangeEvent) => {
                  handleChangeExampleURL(
                    event.value,
                    configData["CategoryIdentifier"]?.FieldName
                  );
                }}
              />
              <InputGlobalSettings
                data={configData["NARID"]}
                isEditing={isEditing}
                onChange={(event: InputChangeEvent) => {
                  handleChangeExampleURL(
                    event.value,
                    configData["NARID"]?.FieldName
                  );
                }}
              />
              <InputGlobalSettings
                data={configData["NameSearch"]}
                isEditing={isEditing}
                onChange={(event: InputChangeEvent) => {
                  handleChangeExampleURL(
                    event.value,
                    configData["NameSearch"]?.FieldName
                  );
                }}
              />
            </div>
            <InputGlobalSettings
              data={configData["ReferenceNo"]}
              isEditing={isEditing}
              onChange={(event: InputChangeEvent) => {
                handleChangeExampleURL(
                  event.value,
                  configData["ReferenceNo"]?.FieldName
                );
              }}
            />
          </>
        )}
      </div>
      <div className="cc-field">
        <CCLabel title={configData["ExampleURL"]?.Title ?? ""} />
        <div className="cc-label-field cc-link-field">
          {exampleURL ? exampleURL : initExampleURL}
          {"  "}
          <i
            className={
              !isEditing || !modified
                ? "fas fa-external-link-alt fa-xs cc-ex-interface-modules-launch-disable"
                : "fas fa-external-link-alt fa-xs cc-ex-interface-modules-launch"
            }
            onClick={handleExternalAction}
          />
        </div>
      </div>
    </section>
  ) : null;
};

export default observer(ExternalInterfaceForModules);
