import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useIsNew } from "@common/hooks/useIsNew";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { usePermissionStore } from "@common/pages/settings/store";
import { CCNotFoundPage } from "@components/cc-not-found-page/_index";
import Loading from "@components/loading/Loading";
import React, { ReactNode, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

interface ICheckPermissionWrapperProps {
  formAction?: FormActionCheckPermission;
  setIsHasPermission?: (isAllowed: boolean) => void;
  children: ReactNode;
}
export const CheckPermissionSettingWrapper = ({
  setIsHasPermission,
  children,
  formAction,
}: ICheckPermissionWrapperProps) => {
  const isNew = useIsNew();
  const { checkPermissionForCurrentMenu, resetStore } = usePermissionStore();

  const [isAllowed, setIsAllowed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCheckPermission = async () => {
    setIsLoading(true);
    let hasPermission = false;
    hasPermission = await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      PRODUCT_TYPE_NUMBER.Core
    );
    if (formAction && hasPermission) {
      const isAllowed = await checkPermissionForCurrentMenu(
        formAction,
        PRODUCT_TYPE_NUMBER.Core
      );
      hasPermission = isAllowed;
    }
    setIsAllowed(hasPermission);
    if (setIsHasPermission) {
      setIsHasPermission(hasPermission);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleCheckPermission();
    // eslint-disable-next-line
  }, [isNew]);

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  if (isLoading) {
    return <Loading isFullScreen isLoading />;
  }
  if (!isAllowed) {
    return <CCNotFoundPage />;
  }

  return <>{children}</>;
};
