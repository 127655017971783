import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { ResponsePacket } from "@common/models/identityPacket";

export const reinstateJobQueue = async (
  ids: number[]
): Promise<APIResponse<ResponsePacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put(
      `api/core/internal/processpool/reinstate`,
      ids
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
