import { Ancestor } from "@app/core/further-info/[id]/model";
import { IParentRecallSection } from "@app/core/recall/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { generateParentValueField } from "@common/utils/common";
import { CCValueField } from "@components/cc-value-field/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IRecallParentSectionProps {
  formRenderProps: FormRenderProps;
}
export const RecallParentSection = observer(
  ({ formRenderProps }: IRecallParentSectionProps) => {
    const { valueGetter } = formRenderProps;
    const parentState = valueGetter("CCFormOptions")
      ?.parentState as IParentRecallSection;
    const recordType = parentState?.recordType as RECORDTYPE;

    const ancestor = valueGetter("CCFormOptions")?.parentSummary as Ancestor;

    if (!ancestor) return null;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCValueField
              label={ancestor.lblParent1}
              value={generateParentValueField(ancestor.litParent1, recordType)}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label={ancestor.lblParent2}
              value={generateParentValueField(ancestor.litParent2, recordType)}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label={ancestor.lblParent3}
              value={generateParentValueField(ancestor.litParent3, recordType)}
            />
          </div>
        </div>
      </section>
    );
  }
);
