import {
  CORE_GET_VIEW_SECURITY_TEMPLATES_ODATA,
  CORE_OFFICERS_ODATA,
} from "@app/core/components/common/constant";
import { isSuccessResponse } from "@common/apis/util";
import { useIsNew } from "@common/hooks/useIsNew";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { EditSiteUserDialog } from "@common/pages/settings/security/corporate-authorisations/_id/components/child-screens/general/components/dialogs/edit-site-user/_index";
import {
  colPickSiteUsers,
  colSecurityTemplates,
  colSiteUsers,
} from "@common/pages/settings/security/corporate-authorisations/_id/components/child-screens/general/components/form-element/config";
import {
  CorporateAuth,
  CORPORATE_AUTH_DELETE_ITEMS,
  ProductTypeWithCorpAuth,
  SiteUser_CorporateAuth_Link,
  Svc_PickSiteUser,
} from "@common/pages/settings/security/corporate-authorisations/_id/model";
import { useCorporateAuthStore } from "@common/pages/settings/security/corporate-authorisations/_id/store";
import { Svc_SecurityTemplate } from "@common/pages/settings/security/security-templates/model";
import { getSiteUserById } from "@common/pages/settings/security/site-users/_id/api";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { GridSelectionDialog } from "@components/dialog/dialog-grid-selection/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import "@styles/index.scss";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface ICorporateAuthFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<CorporateAuth>();
const nameOfSecurityTemplate = nameOfFactory<Svc_SecurityTemplate>();
const nameOfSiteUserCorporateAuth =
  nameOfFactory<SiteUser_CorporateAuth_Link>();

export const CorporateAuthFormElement = observer(
  ({ formRenderProps }: ICorporateAuthFormElementProps) => {
    const isNew = useIsNew();
    const { valueGetter, onChange } = formRenderProps;
    const getValue = (name: keyof CorporateAuth) => valueGetter(nameOf(name));
    const [isShowEditSiteUser, setIsShowEditSiteUser] = useState(false);
    const [isShowSecurityTemplateDialog, setIsShowSecurityTemplateDialog] =
      useState(false);
    const [selectedSecurityTemplate, setSelectedSecurityTemplate] = useState<
      Svc_SecurityTemplate[]
    >([]);
    const [securityTemplateGridData, setSecurityTemplateGridData] = useState<
      Svc_SecurityTemplate[]
    >(() => {
      const securityTemplate = getValue("SecurityTemplates");
      if (securityTemplate && securityTemplate.length > 0)
        return securityTemplate;
      return [];
    });
    const [isShowCorpAuthorisations, setIsShowCorpAuthorisations] =
      useState(false);
    const [isShowSiteUsersDialog, setIsShowSiteUsersDialog] = useState(false);
    const [selectedSiteUsers, setSelectedSiteUsers] = useState<
      SiteUser_CorporateAuth_Link[]
    >([]);
    const [siteUsersGridData, setSiteUsersGridData] = useState<
      SiteUser_CorporateAuth_Link[]
    >(() => {
      const corporateAuthLink = getValue("SiteUsersCorporateAuthLink");
      if (corporateAuthLink && corporateAuthLink.length > 0)
        return corporateAuthLink;
      return [];
    });
    const [isLoadingPickSiteUser, setIsLoadingPickSiteUser] = useState(false);

    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
      useState<CORPORATE_AUTH_DELETE_ITEMS | null>(null);

    const productType = getValue("ProductType_ENUM");

    const { corporateAuthLovs } = useCorporateAuthStore();

    const corporateAuthorisationTypeData = useMemo(() => {
      return (
        corporateAuthLovs?.find(
          (item: ProductTypeWithCorpAuth) => item.ProductType === productType
        )?.CorpAuthorisation || []
      );
    }, [corporateAuthLovs, productType]);

    const handleChangeSiteUser = async (
      data: Svc_PickSiteUser[],
      notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
    ) => {
      setIsLoadingPickSiteUser(true);
      let newSiteUsers: SiteUser_CorporateAuth_Link[] = [];
      for (let index = 0; index < data.length; index++) {
        const siteUser = data[index];
        const currentSiteUserIndex = siteUsersGridData.findIndex(
          (currentUser: SiteUser_CorporateAuth_Link) =>
            currentUser.SiteUser_ID === siteUser.ID
        );
        if (currentSiteUserIndex === -1) {
          const response = await getSiteUserById(siteUser.ID);
          if (isSuccessResponse(response) && response?.data) {
            newSiteUsers.push({
              CorporateAuth_ID: getValue("CorporateAuth_ID"),
              SiteUser_ID: response.data?.Contact_SiteUser
                ?.Contact_ID as number,
              SiteUser_DisplayName: response.data?.Contact_SiteUser
                ?.DisplayName as string,
              SiteUser_Email: response.data?.Contact_SiteUser?.Email as string,
              SiteUser_Username: response.data?.Contact_SiteUser
                ?.MemberUserName as string,
              SendNotification: null,
            });
          } else {
            notificationRef.current?.pushNotification({
              title: `Pick Officer failed`,
              type: "warning",
            });
          }
        }
      }

      newSiteUsers = newSiteUsers.concat(siteUsersGridData);
      setSiteUsersGridData(newSiteUsers);
      onChange("SiteUsersCorporateAuthLink", {
        value: newSiteUsers,
      });

      setIsShowSiteUsersDialog(false);
      setIsLoadingPickSiteUser(false);
    };

    const handleChangeSecurityTemplate = (data: Svc_SecurityTemplate[]) => {
      setSecurityTemplateGridData(data);
      onChange("SecurityTemplates", { value: data });
      setIsShowSecurityTemplateDialog(false);
    };

    const handleOnRemoveItems = () => {
      switch (showConfirmDeleteDialog) {
        case CORPORATE_AUTH_DELETE_ITEMS.SECURITY_TEMPLATES:
          const newsecurityTemplate = securityTemplateGridData.filter(
            (item: Svc_SecurityTemplate) =>
              !selectedSecurityTemplate.some(
                (selectedItem: Svc_SecurityTemplate) =>
                  item.SecurityTemplate_ID === selectedItem.SecurityTemplate_ID
              )
          );
          setSecurityTemplateGridData(newsecurityTemplate);
          onChange("SecurityTemplates", {
            value: newsecurityTemplate,
          });
          break;
        case CORPORATE_AUTH_DELETE_ITEMS.SITE_USERS:
          const newSiteUsers = siteUsersGridData.filter(
            (item: SiteUser_CorporateAuth_Link) =>
              !selectedSiteUsers.some(
                (selectedItem: SiteUser_CorporateAuth_Link) =>
                  item.SiteUser_ID === selectedItem.SiteUser_ID
              )
          );
          setSiteUsersGridData(newSiteUsers);
          onChange("SiteUsersCorporateAuthLink", {
            value: newSiteUsers,
          });
          break;
      }
    };

    const handleEditSiteUser = (value: SiteUser_CorporateAuth_Link) => {
      const newSiteUser = siteUsersGridData.map(
        (siteUser: SiteUser_CorporateAuth_Link) => {
          if (siteUser.SiteUser_ID === value.SiteUser_ID) {
            return {
              CorporateAuth_ID: value.CorporateAuth_ID,
              SiteUser_ID: value.SiteUser_ID,
              SiteUser_DisplayName: value.SiteUser_DisplayName,
              SiteUser_Email: value.SiteUser_Email,
              SiteUser_Username: value.SiteUser_Username,
              SendNotification: value.SendNotification,
            };
          }
          return siteUser;
        }
      );
      setSiteUsersGridData(newSiteUser);
      setIsShowEditSiteUser(false);
    };

    const processColSiteUsers = useMemo(() => {
      return colSiteUsers.map((col: IColumnFields) => {
        return {
          ...col,
          handleOnClick: (data: SiteUser_CorporateAuth_Link) => {
            setSelectedSiteUsers([data]);
            setIsShowEditSiteUser(true);
          },
        };
      });
    }, []);

    const isSendToGeneralEmail = getValue(
      "CorporateAuthorisation__SendToGeneralEmailOnly"
    );

    return (
      <>
        <FormElement className="cc-keyword-form-elements">
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Name" isMandatory={true} />
                <Field
                  name={nameOf("AuthorisationName")}
                  component={CCInput}
                  placeholder={"Name"}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field cc-col-span-2">
                <CCLabel title="Description" isMandatory={true} />
                <Field
                  name={nameOf("AuthorisationDescription")}
                  component={CCInput}
                  placeholder={"Description"}
                  validator={requiredValidator}
                  maxLength={100}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Product Type" isMandatory={true} />
                <Field
                  name={nameOf("ProductType_ENUM")}
                  component={CCSearchComboBox}
                  validator={requiredValidator}
                  textField="ProductName"
                  dataItemKey="ProductType"
                  data={corporateAuthLovs || []}
                  value={corporateAuthLovs?.find(
                    (item: ProductTypeWithCorpAuth) => {
                      return item?.ProductType === getValue("ProductType_ENUM");
                    }
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange("ProductType_ENUM", {
                      value: event.value?.ProductType ?? null,
                    });
                    onChange("CorporateAuthorisationType_ENUM", {
                      value: null,
                    });
                    setIsShowCorpAuthorisations(true);
                  }}
                />
              </div>
              {isShowCorpAuthorisations && (
                <div className="cc-field">
                  <CCLabel title="Corp. Authorisation" isMandatory={true} />
                  <Field
                    name={nameOf("CorporateAuthorisationType_ENUM")}
                    component={CCSearchComboBox}
                    validator={requiredValidator}
                    textField="Value"
                    dataItemKey="Key"
                    data={corporateAuthorisationTypeData || []}
                    value={corporateAuthorisationTypeData.find(
                      (item: IKeyValuePacket) => {
                        return (
                          item.Key ===
                          getValue("CorporateAuthorisationType_ENUM")
                        );
                      }
                    )}
                    onChange={(event: ComboBoxChangeEvent) =>
                      onChange("CorporateAuthorisationType_ENUM", {
                        value: event.value?.Key ?? null,
                      })
                    }
                  />
                </div>
              )}
            </div>
          </section>
          <hr className="cc-divider" />
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Send to General Email" />
                <Field
                  name={nameOf(
                    "CorporateAuthorisation__SendToGeneralEmailOnly"
                  )}
                  component={CCSwitch}
                  checked={getValue(
                    "CorporateAuthorisation__SendToGeneralEmailOnly"
                  )}
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title="General Email"
                  isMandatory={isSendToGeneralEmail}
                />
                <Field
                  name={nameOf("CorporateAuthorisation_GeneralEmail")}
                  component={CCInput}
                  validator={
                    isSendToGeneralEmail ? requiredValidator : undefined
                  }
                />
              </div>
            </div>
          </section>
          <hr className="cc-divider" />
          <section className="cc-field-group">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCLabel title="Security Templates" />
                <CCGrid
                  toolbar={
                    <div className="cc-grid-tools-bar">
                      <Button
                        type="button"
                        iconClass="fa fa-plus"
                        onClick={() => {
                          setIsShowSecurityTemplateDialog(true);
                        }}
                        title="Add Security Templates"
                      />
                      <Button
                        type="button"
                        iconClass="fas fa-minus"
                        disabled={selectedSecurityTemplate.length !== 1}
                        onClick={() => {
                          setShowConfirmDeleteDialog(
                            CORPORATE_AUTH_DELETE_ITEMS.SECURITY_TEMPLATES
                          );
                        }}
                        title="Remove Security Templates"
                      />
                    </div>
                  }
                  columnFields={colSecurityTemplates}
                  primaryField={nameOfSecurityTemplate("SecurityTemplate_ID")}
                  selectableMode="single"
                  data={securityTemplateGridData}
                  onSelectionChange={(data: Svc_SecurityTemplate[]) => {
                    setSelectedSecurityTemplate(data);
                  }}
                />
                {isShowSecurityTemplateDialog && (
                  <GridSelectionDialog
                    maxWidth="55%"
                    titleHeader={"Pick Security Template(s)"}
                    selectableMode={"multiple"}
                    dataUrl={`${CORE_GET_VIEW_SECURITY_TEMPLATES_ODATA}?$count=true&`}
                    columnFields={colSecurityTemplates}
                    primaryField={nameOfSecurityTemplate("SecurityTemplate_ID")}
                    setShowDialog={setIsShowSecurityTemplateDialog}
                    onCloseDialog={() => setIsShowSecurityTemplateDialog(false)}
                    onSubmit={(data: Svc_SecurityTemplate[]) =>
                      handleChangeSecurityTemplate(data)
                    }
                  />
                )}
              </div>
              {!isNew && (
                <div className="cc-field">
                  <CCLabel title="Site Users" />
                  <CCGrid
                    toolbar={
                      <div className="cc-grid-tools-bar">
                        <Button
                          type="button"
                          iconClass="fa fa-plus"
                          onClick={() => {
                            setIsShowSiteUsersDialog(true);
                          }}
                          title="Add Site Users"
                        />
                        <Button
                          type="button"
                          iconClass="fas fa-minus"
                          disabled={selectedSiteUsers.length !== 1}
                          onClick={() => {
                            setShowConfirmDeleteDialog(
                              CORPORATE_AUTH_DELETE_ITEMS.SITE_USERS
                            );
                          }}
                          title="Remove Site Users"
                        />
                      </div>
                    }
                    columnFields={processColSiteUsers}
                    primaryField={nameOfSiteUserCorporateAuth("SiteUser_ID")}
                    selectableMode="single"
                    data={siteUsersGridData}
                    onSelectionChange={(
                      data: SiteUser_CorporateAuth_Link[]
                    ) => {
                      setSelectedSiteUsers(data);
                    }}
                  />
                  {isShowSiteUsersDialog && (
                    <GridSelectionDialog
                      maxWidth="55%"
                      titleHeader={"Pick Site Users"}
                      selectableMode={"multiple"}
                      dataUrl={CORE_OFFICERS_ODATA}
                      columnFields={colPickSiteUsers}
                      isLoading={isLoadingPickSiteUser}
                      primaryField="ID"
                      setShowDialog={setIsShowSiteUsersDialog}
                      onCloseDialog={() => setIsShowSiteUsersDialog(false)}
                      onSubmit={(data: Svc_PickSiteUser[], notificationRef) =>
                        handleChangeSiteUser(data, notificationRef)
                      }
                    />
                  )}
                  {isShowEditSiteUser && (
                    <EditSiteUserDialog
                      initialData={selectedSiteUsers[0]}
                      onClose={() => setIsShowEditSiteUser(false)}
                      onSubmit={handleEditSiteUser}
                    />
                  )}
                </div>
              )}
            </div>
          </section>
        </FormElement>
        {showConfirmDeleteDialog && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={"Are you sure you want to delete this item?"}
            onClosePopup={() => {
              setShowConfirmDeleteDialog(null);
            }}
            onConfirmYes={handleOnRemoveItems}
          />
        )}
      </>
    );
  }
);
