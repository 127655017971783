import { APIResponseError } from "@common/apis/model";
import { DTO_ChargeRun_PIC_Summary } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { getPICChargeRunSummaryById } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/api";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class PICChargeRunsStore {
  private _picChargeRuns?: DTO_ChargeRun_PIC_Summary = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get picChargeRun() {
    return toJS(this._picChargeRuns);
  }
  setPICChargeRun = (picChargeRun?: DTO_ChargeRun_PIC_Summary) => {
    runInAction(() => {
      this._picChargeRuns = picChargeRun;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get picChargeRunsId() {
    return toJS(this.picChargeRun?.ChargeRunId);
  }

  //Action

  resetStore = () => {
    runInAction(() => {
      this._picChargeRuns = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadPICChargeRuns = async (
    picChargeRunsId: number,
    notification?: IAppNotificationItemAddProps
  ) => {
    this.setIsLoading(true);
    const response = await getPICChargeRunSummaryById(picChargeRunsId);
    let errorResponse = undefined;
    let newPICChargeRuns = undefined;
    if (isSuccessResponse(response)) {
      newPICChargeRuns = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setPICChargeRun(newPICChargeRuns);
    this.setIsLoading(false);
    if (notification) {
      appNotificationStore.pushNotification(notification);
    }
  };

  reLoadPICChargeRuns = async () => {
    if (this.picChargeRunsId)
      return await this.loadPICChargeRuns(this.picChargeRunsId);
  };
}

const picChargeRunsStoreContext = createContext(new PICChargeRunsStore());
export const usePICChargeRunsStore = () => {
  return useContext(picChargeRunsStoreContext);
};
