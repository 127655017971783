import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { isSuccessResponse } from "@common/apis/util";
import { PROPERTYSYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { getSyncAddressProperty } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/properties/forms/property-data-sync-settings/api";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { TextAreaGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/text-area/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
interface IGlobalSettingsProps {
  formRenderProps: FormRenderProps;
}

export default observer(({ formRenderProps }: IGlobalSettingsProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter } = formRenderProps;
  const data = useDataSetting(configData, [
    "CorporateSettings_PropertySyncQuery",
    "CorporateSettings_PropertyIDColumn",
  ]);
  const ddlPropertiesSystem = +valueGetter(
    ECorporateSettingsField.CorporateSettings_PropertySystem.toString()
  );
  const showPropertiesIDColumn =
    ddlPropertiesSystem in
    [
      PROPERTYSYSTEM.Proclaim,
      PROPERTYSYSTEM.Authority,
      PROPERTYSYSTEM.Lynx,
      PROPERTYSYSTEM.Pathway,
      PROPERTYSYSTEM.PropertyGov,
      PROPERTYSYSTEM.None,
    ];
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isLoadingSyncButton, setIsLoadingSyncButton] = useState(false);
  const { pushNotification } = useCCAppNotificationStore();

  const handleSyncClick = async (e: any) => {
    e.preventDefault();
    setIsLoadingSyncButton(true);
    const response = await getSyncAddressProperty();
    if (isSuccessResponse(response)) {
      pushNotification({
        type: "success",
        title: "Sync property address",
        description: `${response.data.SuccessCount} address synchronised with Property System`,
      });
    } else {
      pushNotification({
        type: "error",
        title: "Sync property address failed",
        autoClose: false,
        description: response.data?.Errors ?? response.error,
      });
    }
    setIsShowDialog(false);
    setIsLoadingSyncButton(false);
  };
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <TextAreaGlobalSettings
          data={data.CorporateSettings_PropertySyncQuery}
          isEditing={isEditing}
          rows={10}
        />

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">&nbsp;</label>
            <Button
              type="button"
              disabled={!isEditing}
              onClick={() => setIsShowDialog(true)}
            >
              {isLoadingSyncButton ? (
                <i className="fas fa-spinner fa-spin" />
              ) : null}
              Sync
            </Button>
          </div>
        </div>
      </div>
      {showPropertiesIDColumn && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings
            data={data.CorporateSettings_PropertyIDColumn}
            isEditing={isEditing}
          />
        </div>
      )}
      {isShowDialog && (
        <ConfirmDialog
          title="Confirm Sync"
          message={
            "This will start sync specified fields of Property, continue?"
          }
          isLoadingYes={isLoadingSyncButton}
          onClosePopup={() => {
            setIsShowDialog(false);
          }}
          onAsyncConfirm={(e) => {
            handleSyncClick(e);
          }}
        />
      )}
    </section>
  );
});
