import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewApplication = () => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New Application"
      onClick={() => {
        history.push(`${CRS_MANAGE_ROUTE}/new`);
      }}
    />
  );
};
