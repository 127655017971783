import { mockNominatedProperty } from "./mock";

export const loadNominatedProperty = async () => {
  return mockNominatedProperty
    ? mockNominatedProperty.map((dataItem: any) => ({
        ...dataItem,
        selected: false,
      }))
    : [];
};
