import { IDataNotification } from "@app/products/property/system-admin/settings/_id/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" }); // Strict mode: runInAction

class PropertySettingManagePageStore {
  private _showNotification: {
    state: boolean;
    props: IAppNotificationItemAddProps | null;
    name?: string;
    data?: any;
  } = {
    state: false,
    props: null,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setShowNotification = (
    state: boolean,
    props: IAppNotificationItemAddProps | null,
    name?: string,
    data?: any
  ) => {
    this._showNotification = { state, props, name, data };
  };
  get showNotification(): IDataNotification {
    return toJS(this._showNotification);
  }

  //Action
  emptyNotification = () => {
    runInAction(() => {
      this.setShowNotification(false, null);
    });
  };
}

const propertySettingManagePageContext = createContext(
  new PropertySettingManagePageStore()
);
export const usePropertySettingManagePageStore = () => {
  return useContext(propertySettingManagePageContext);
};
