import { encryptionHtmlForEditor } from "@app/core/communication/dialogs/components/form-elememts/message-template/components/cc-edit-html-tool/util";
import { HD_SCREEN_WIDTH } from "@common/constants/responsive";
import { editorDefaultTools } from "@components/cc-editor/config";
import { Editor, EditorProps } from "@progress/kendo-react-editor";
import { isString } from "lodash";
import React, { useMemo } from "react";
import { useWindowSize } from "react-use";
import "./_index.scss";

export interface ICCEditorProps extends EditorProps {
  disabled?: boolean;
  className?: string;
}
export const CCEditor = ({
  tools,
  disabled,
  value,
  className,
  ...others
}: ICCEditorProps) => {
  const { width } = useWindowSize();
  const defaultTools = useMemo(
    () =>
      width >= HD_SCREEN_WIDTH
        ? editorDefaultTools.web
        : editorDefaultTools.mobile,

    [width]
  );

  return (
    <div
      className={`cc-editor${disabled ? ` cc-editor-disabled` : ``} ${
        className ? className : ""
      }`}
    >
      <Editor
        {...others}
        tools={tools ? tools : defaultTools}
        value={isString(value) ? encryptionHtmlForEditor(value) : value}
      />
    </div>
  );
};
