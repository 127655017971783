import { getSupplementaryRatesDetailsById } from "@app/products/property/supplementary-rates/[id]/components/child-screens/detail/api";
import { DTO_Supplementary_Details } from "@app/products/property/supplementary-rates/[id]/components/child-screens/detail/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class SupplementaryRatesDetailsStore {
  private _supplementaryRatesDetails?: DTO_Supplementary_Details = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get supplementaryRatesDetails() {
    return toJS(this._supplementaryRatesDetails);
  }
  setSupplementaryRatesDetails = (
    supplementaryRatesDetails: DTO_Supplementary_Details | undefined
  ) => {
    runInAction(() => {
      this._supplementaryRatesDetails = supplementaryRatesDetails;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._supplementaryRatesDetails = undefined;
      this._isLoading = false;
    });
  };

  loadSupplementaryRatesDetails = async (supplementaryId: number) => {
    this.setIsLoading(true);
    const data = await getSupplementaryRatesDetailsById(supplementaryId);
    this.setSupplementaryRatesDetails(data);
    this.setIsLoading(false);
  };
}
const supplementaryRatesDetailsStoreContext = createContext(
  new SupplementaryRatesDetailsStore()
);
export const useSupplementaryRatesDetailsStore = () => {
  return useContext(supplementaryRatesDetailsStoreContext);
};
