import {
  ILookupItem,
  SettingField,
} from "@app/products/town-planning/ppr/system-admin/settings/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field } from "@progress/kendo-react-form";
import React from "react";

export interface IDropdownSettingsProps {
  data: SettingField | undefined;
  isEditing: boolean;
  isFieldVisible?: boolean;
}
const nameOfLookupItem = nameOfFactory<ILookupItem>();

export const SearchComboBoxSettings = ({
  data,
  isEditing,
  isFieldVisible = true,
}: IDropdownSettingsProps) => {
  return data && isFieldVisible ? (
    <div className="cc-field">
      <label className="cc-label">
        {data?.Title ?? ""}
        {data?.IsMandatory && <CCTooltip type="validator" position="right" />}
        {data?.HelpText && (
          <CCTooltip
            type="custom"
            content=" "
            position="auto"
            clickToOpen
            customTemplate={<>{sanitizeHtml(data.HelpText)}</>}
          >
            <i className="fa fa-info-circle ml-1 text-accent" />
          </CCTooltip>
        )}
      </label>
      <Field
        name={data?.FieldName}
        component={CCSearchComboBox}
        data={data?.LookupItems ?? []}
        textField={nameOfLookupItem("Name")}
        dataItemKey={nameOfLookupItem("Id")}
        disabled={!isEditing}
        validator={data.IsMandatory ? requiredValidator : undefined}
        isUseDefaultOnchange
      />
    </div>
  ) : null;
};
