import React from 'react';

interface IProps {}

export const EventTab = (props: IProps) => {
  return (
    <div className={'pt-4'}>
      <b>This is Event Tab !</b>
    </div>
  );
};
