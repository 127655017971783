import { ODATA_GET_CONTACTS } from "@app/core/contacts/components/dialogs/pick-contact/constant";
import { KeyValuePair } from "@app/core/documents/model";
import { renderOptionRequestor } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/config";
import { CS_RequestorType } from "@app/products/crms/[id]/model";
import {
  nameOfOrgUnit,
  optionOrgUnits,
  renderOptionActionOfficer,
} from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/form-element/config";
import { CaseDetails } from "@app/products/crms/service-standards/batch-update/components/form/components/general/components/accordions/case-details/_index";
import { Communications } from "@app/products/crms/service-standards/batch-update/components/form/components/general/components/accordions/communications/_index";
import { CRMSRecord } from "@app/products/crms/service-standards/batch-update/components/form/components/general/components/accordions/records/_index";
import { SpecialInterest } from "@app/products/crms/service-standards/batch-update/components/form/components/general/components/accordions/special-interest/_index";
import { SystemInterface } from "@app/products/crms/service-standards/batch-update/components/form/components/general/components/accordions/system-interface/_index";
import { TargetDays } from "@app/products/crms/service-standards/batch-update/components/form/components/general/components/accordions/target-days/_index";
import { WorkflowSecurity } from "@app/products/crms/service-standards/batch-update/components/form/components/general/components/accordions/workflow-security/_index";
import { options } from "@app/products/crms/service-standards/batch-update/constant";

import {
  ServiceStandardBatchUpdate,
  ServiceStandardBatchUpdateHandlerRequest,
  ServiceStandardBatchUpdateMapObj,
  StateSvcStandardUpdate,
  Svc_FormAction_ServiceStandardBatchUpdate,
} from "@app/products/crms/service-standards/batch-update/model";
import { useSvcStandardBatchUpdateStore } from "@app/products/crms/service-standards/batch-update/store";
import { getRadioOptions } from "@app/products/crms/service-standards/batch-update/util";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { RECORDSSYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { OrgUnit } from "@components/cc-pick-org-units/model";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export interface ICRMSSvcStandardUpdateFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOfSvcStandardBatchUpdateMapObj =
  nameOfFactory<ServiceStandardBatchUpdateMapObj>();
const nameOf = nameOfFactory<ServiceStandardBatchUpdate>();

const getNameOf = (name: keyof ServiceStandardBatchUpdate) => {
  return `${nameOfSvcStandardBatchUpdateMapObj(
    "SvcStandardBatchUpdate"
  )}.${nameOf(name)}`;
};

export const CRMSSvcStandardUpdateFormElement = observer(
  ({ formRenderProps }: ICRMSSvcStandardUpdateFormElementProps) => {
    const { settings } = useCommonCoreStore();
    const { valueGetter, onChange } = formRenderProps;
    const {
      svcStandardBatchUpdateLovs,
      svcStandardBatchUpdateChangeHandler,
      uiControl,
      isExpandedSystemInterface,
      isExpandedSpecialInterest,
      isExpandedWorkflowSecurity,
    } = useSvcStandardBatchUpdateStore();

    const location = useLocation();
    const locationState = location?.state as StateSvcStandardUpdate;
    const gridSelectedIds = locationState?.gridSelectedIds;

    const isDisabled = useMemo(() => {
      return (
        isNil(gridSelectedIds) ||
        (gridSelectedIds && gridSelectedIds?.length < 1)
      );
    }, [gridSelectedIds]);

    const [issueLocationTypesValue, setIssueLocationTypesValue] = useState<
      KeyValuePair<number, string>[] | null
    >([]);

    const recordsSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
    );

    const enableUrgentSetting = getBoolValueSetting(
      settings[ECorporateSettingsField.CUSTOMERSERVICE_Event_EnableUrgent]
    );

    const SvcStandardBatchUpdateMapObj = valueGetter(
      nameOfSvcStandardBatchUpdateMapObj("SvcStandardBatchUpdate")
    );

    const svcStandardTypeOptions = getRadioOptions(
      svcStandardBatchUpdateLovs?.ServiceStandardType
    );
    const confidentialityOptions = getRadioOptions(
      svcStandardBatchUpdateLovs?.Confidentiality
    );
    const onBehalfOfOptions = getRadioOptions(
      svcStandardBatchUpdateLovs?.OnBehalfOf
    );

    const handleChangeIssueLocationType = (data: MultiSelectChangeEvent) => {
      const issuelocationTypeIDs: number[] =
        data?.value?.map((item: KeyValuePair<number, string>) => item.Key) ??
        [];

      onChange(getNameOf("IssueLocationTypes"), {
        value: issuelocationTypeIDs.filter((item: number) => item).join("|"),
      });

      getIssueLocationTypesValue();
    };

    const getIssueLocationTypesValue = () => {
      const ids =
        valueGetter(getNameOf("IssueLocationTypes"))?.split("|") ?? [];

      let data: KeyValuePair<number, string>[] = [];
      if (ids.length > 0) {
        svcStandardBatchUpdateLovs?.IssueLocationTypes?.forEach((item) => {
          if (ids.includes(item.Key?.toString())) {
            data.push(item);
          }
        });
      }
      setIssueLocationTypesValue(data);
    };

    const handleChangeCoordinator = (values: any) => {
      onChange(getNameOf("Coordinator_SiteUser_ID"), {
        value: values?.Id ?? null,
      });
      const params: ServiceStandardBatchUpdateHandlerRequest = {
        ServiceStandardBatchUpdateFormAction:
          Svc_FormAction_ServiceStandardBatchUpdate.PickCoordinator,
        ServiceStandardBatchUpdate: SvcStandardBatchUpdateMapObj,
        EventArgs: {},
        IsFirstTimeLoad: false,
      };
      svcStandardBatchUpdateChangeHandler(params, "Change coordinator failed.");
    };

    const handleChangeActionOfficer = (values: any) => {
      onChange(getNameOf("ActionOfficer_SiteUser_ID"), {
        value: values?.Id ?? null,
      });
      const params: ServiceStandardBatchUpdateHandlerRequest = {
        ServiceStandardBatchUpdateFormAction:
          Svc_FormAction_ServiceStandardBatchUpdate.PickActionOfficer,
        ServiceStandardBatchUpdate: SvcStandardBatchUpdateMapObj,
        EventArgs: {},
        IsFirstTimeLoad: false,
      };
      svcStandardBatchUpdateChangeHandler(
        params,
        "Change action officer failed."
      );
    };

    const handleChangeOrgStructure = (values: OrgUnit) => {
      const params: ServiceStandardBatchUpdateHandlerRequest = {
        ServiceStandardBatchUpdateFormAction:
          Svc_FormAction_ServiceStandardBatchUpdate.PickOrgStructure,
        ServiceStandardBatchUpdate: SvcStandardBatchUpdateMapObj,
        EventArgs: values ? [values?.ID] : [],
        IsFirstTimeLoad: false,
      };
      svcStandardBatchUpdateChangeHandler(
        params,
        "Change org structure failed."
      );
    };

    return (
      <>
        <FormElement>
          <CCGeneralPanel
            component={
              <>
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update coordinator" />
                      <Field
                        name={getNameOf("Update_Coordinator")}
                        checked={valueGetter(getNameOf("Update_Coordinator"))}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_Coordinator")) ? (
                      <div className="cc-field">
                        <CCLabel title="Coordinator" isMandatory />
                        <Field
                          name={getNameOf("Coordinator_SiteUser_ID")}
                          nameDisplay="DisplayName"
                          placeholder="Select coordinator"
                          component={InputPickerSearch}
                          valueMapGrid={valueGetter(
                            getNameOf("Coordinator_SiteUser_ID")
                          )}
                          onChange={handleChangeCoordinator}
                          options={renderOptionRequestor(
                            CS_RequestorType.InternalPerson
                          )}
                          value={uiControl?.LblCoordinator?.Value}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update action officer" />
                      <Field
                        name={getNameOf("Update_ActionOfficer")}
                        checked={valueGetter(getNameOf("Update_ActionOfficer"))}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_ActionOfficer")) ? (
                      <div className="cc-field">
                        <CCLabel title="Action officer" isMandatory />
                        <Field
                          name={getNameOf("ActionOfficer_SiteUser_ID")}
                          nameDisplay="DisplayName"
                          placeholder="Select action officer"
                          component={InputPickerSearch}
                          valueMapGrid={valueGetter(
                            getNameOf("ActionOfficer_SiteUser_ID")
                          )}
                          onChange={handleChangeActionOfficer}
                          validator={requiredValidator}
                          options={renderOptionActionOfficer(
                            `${ODATA_GET_CONTACTS}(classificationFilters='2',hideCouncillors=true)`
                          )}
                          value={uiControl?.LblActionOfficer.Value ?? ""}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update org structure" />
                      <Field
                        name={getNameOf("Update_OrgStructure")}
                        checked={valueGetter(getNameOf("Update_OrgStructure"))}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_OrgStructure")) ? (
                      <div className="cc-field">
                        <CCLabel title="Org structure" isMandatory />
                        <Field
                          name={getNameOf("Org1_Id")}
                          nameDisplay="DisplayName"
                          placeholder="Select org units"
                          component={InputPickerSearch}
                          keyMapGrid={nameOfOrgUnit("Hierarchy")}
                          valueMapGrid={uiControl?.LblOrgStructure?.Value}
                          onChange={handleChangeOrgStructure}
                          options={optionOrgUnits}
                          value={uiControl?.LblOrgStructure?.Value}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>
                </section>

                <hr className="cc-divider" />

                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update send to coordinator" />
                      <Field
                        name={getNameOf("Update_SendToCoordinator")}
                        checked={valueGetter(
                          getNameOf("Update_SendToCoordinator")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_SendToCoordinator")) ? (
                      <div className="cc-field">
                        <CCLabel title="Send to coordinator" isMandatory />
                        <Field
                          name={getNameOf("SendToCoordinator")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel
                        title={`Update send to ${
                          uiControl?.FlSendToActionOfficer?.Value ??
                          "action officer"
                        }`}
                      />
                      <Field
                        name={getNameOf("Update_SendToActionOfficer")}
                        checked={valueGetter(
                          getNameOf("Update_SendToActionOfficer")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_SendToActionOfficer")) ? (
                      <div className="cc-field">
                        <CCLabel
                          title={`Send to ${
                            uiControl?.FlSendToActionOfficer?.Value ??
                            "action officer"
                          }`}
                          isMandatory
                        />
                        <Field
                          name={getNameOf("SendToActionOfficer")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title={`Update automatic allocation`} />
                      <Field
                        name={getNameOf("Update_AutomaticAllocation")}
                        checked={valueGetter(
                          getNameOf("Update_AutomaticAllocation")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_AutomaticAllocation")) ? (
                      <div className="cc-field">
                        <CCLabel title={"Automatic allocation"} isMandatory />
                        <Field
                          name={getNameOf("AutomaticAllocation")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title={`Update allow anonymous`} />
                      <Field
                        name={getNameOf("Update_AllowAnonymous")}
                        checked={valueGetter(
                          getNameOf("Update_AllowAnonymous")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_AllowAnonymous")) ? (
                      <div className="cc-field">
                        <CCLabel title={"Allow Anonymous"} isMandatory />
                        <Field
                          name={getNameOf("AllowAnonymous")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>
                </section>

                <hr className="cc-divider" />

                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update service standard type" />
                      <Field
                        name={getNameOf("Update_ServiceStandardType")}
                        checked={valueGetter(
                          getNameOf("Update_ServiceStandardType")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_ServiceStandardType")) ? (
                      <div className="cc-field">
                        <CCLabel title="Service standard type" isMandatory />
                        <Field
                          name={getNameOf("ServiceStandardType")}
                          component={RadioGroup}
                          data={svcStandardTypeOptions}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title={`Update additional ref no`} />
                      <Field
                        name={getNameOf("Update_AdditionalRefNo")}
                        checked={valueGetter(
                          getNameOf("Update_AdditionalRefNo")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_AdditionalRefNo")) ? (
                      <div className="cc-field">
                        <CCLabel title="Additional ref no" isMandatory />
                        <Field
                          name={getNameOf("AdditionalRefNo")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title={"Update confidentiality"} />
                      <Field
                        name={getNameOf("Update_Confidentiality")}
                        checked={valueGetter(
                          getNameOf("Update_Confidentiality")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_Confidentiality")) ? (
                      <div className="cc-field">
                        <CCLabel title="Confidentiality" isMandatory />
                        <Field
                          name={getNameOf("Confidentiality")}
                          component={RadioGroup}
                          data={confidentialityOptions}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>
                </section>

                <hr className="cc-divider" />

                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update allow recategorise" />
                      <Field
                        name={getNameOf("Update_AllowRecategorise")}
                        checked={valueGetter(
                          getNameOf("Update_AllowRecategorise")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_AllowRecategorise")) ? (
                      <div className="cc-field">
                        <CCLabel title="Allow recategorise" isMandatory />
                        <Field
                          name={getNameOf("AllowRecategorise")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update allow reallocate officer" />
                      <Field
                        name={getNameOf("Update_AllowReallocateOfficer")}
                        checked={valueGetter(
                          getNameOf("Update_AllowReallocateOfficer")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_AllowReallocateOfficer")) ? (
                      <div className="cc-field">
                        <CCLabel title="Allow reallocate officer" isMandatory />
                        <Field
                          name={getNameOf("AllowReallocateOfficer")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  {enableUrgentSetting && (
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel title="Update enable urgent" />
                        <Field
                          name={getNameOf("Update_EnableUrgent")}
                          checked={valueGetter(
                            getNameOf("Update_EnableUrgent")
                          )}
                          component={CCSwitch}
                          disabled={isDisabled}
                        />
                      </div>

                      {valueGetter(getNameOf("Update_EnableUrgent")) ? (
                        <div className="cc-field">
                          <CCLabel title="Enable urgent" isMandatory />
                          <Field
                            name={getNameOf("EnableUrgent")}
                            component={RadioGroup}
                            data={options}
                            layout={"horizontal"}
                            validator={requiredValidator}
                            disabled={isDisabled}
                          />
                        </div>
                      ) : null}
                    </div>
                  )}

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update asset is mandatory" />
                      <Field
                        name={getNameOf("Update_AssetIsMandatory")}
                        checked={valueGetter(
                          getNameOf("Update_AssetIsMandatory")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_AssetIsMandatory")) ? (
                      <div className="cc-field">
                        <CCLabel title="Asset is mandatory" isMandatory />
                        <Field
                          name={getNameOf("AssetIsMandatory")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update allow quick close" />
                      <Field
                        name={getNameOf("Update_QuickClose")}
                        checked={valueGetter(getNameOf("Update_QuickClose"))}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_QuickClose")) ? (
                      <div className="cc-field">
                        <CCLabel title="Allow quick close" />
                        <Field
                          name={getNameOf("QuickClose")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update assessment no is mandatory" />
                      <Field
                        name={getNameOf("Update_AssessmentNoIsMandatory")}
                        checked={valueGetter(
                          getNameOf("Update_AssessmentNoIsMandatory")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(
                      getNameOf("Update_AssessmentNoIsMandatory")
                    ) ? (
                      <div className="cc-field">
                        <CCLabel
                          title="Assessment no is mandatory"
                          isMandatory
                        />
                        <Field
                          name={getNameOf("AssessmentNoIsMandatory")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update on behalf of (For internal requestors only)" />
                      <Field
                        name={getNameOf("Update_OnBehalfOf")}
                        checked={valueGetter(getNameOf("Update_OnBehalfOf"))}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_OnBehalfOf")) ? (
                      <div className="cc-field">
                        <CCLabel title="On behalf of (For internal requestors only)" />
                        <Field
                          name={getNameOf("OnBehalfOf")}
                          component={RadioGroup}
                          data={onBehalfOfOptions}
                          layout={"horizontal"}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update prompt for location" />
                      <Field
                        name={getNameOf("Update_PromptForLocation")}
                        checked={valueGetter(
                          getNameOf("Update_PromptForLocation")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_PromptForLocation")) ? (
                      <div className="cc-field">
                        <CCLabel title="Prompt for location" isMandatory />
                        <Field
                          name={getNameOf("PromptForLocation")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update set me as requestor" />
                      <Field
                        name={getNameOf("Update_SetMeAsRequestor")}
                        checked={valueGetter(
                          getNameOf("Update_SetMeAsRequestor")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_SetMeAsRequestor")) ? (
                      <div className="cc-field">
                        <CCLabel title="Set me as requestor" isMandatory />
                        <Field
                          name={getNameOf("SetMeAsRequestor")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update add attachment" />
                      <Field
                        name={getNameOf("Update_AddAttachment")}
                        checked={valueGetter(getNameOf("Update_AddAttachment"))}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_AddAttachment")) ? (
                      <div className="cc-field">
                        <CCLabel title="Add attachment" isMandatory />
                        <Field
                          name={getNameOf("AddAttachment")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update show map" />
                      <Field
                        name={getNameOf("Update_ShowMap")}
                        checked={valueGetter(getNameOf("Update_ShowMap"))}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_ShowMap")) ? (
                      <div className="cc-field">
                        <CCLabel title="Show map" isMandatory />
                        <Field
                          name={getNameOf("ShowMap")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update lock event" />
                      <Field
                        name={getNameOf("Update_LockEvent")}
                        checked={valueGetter(getNameOf("Update_LockEvent"))}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_LockEvent")) ? (
                      <div className="cc-field">
                        <CCLabel title="Lock event" />
                        <Field
                          name={getNameOf("LockEvent")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>
                </section>

                <hr className="cc-divider" />

                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update use system defaults" />
                      <Field
                        name={getNameOf("Update_IssueLocationSystemDefaults")}
                        checked={valueGetter(
                          getNameOf("Update_IssueLocationSystemDefaults")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(
                      getNameOf("Update_IssueLocationSystemDefaults")
                    ) ? (
                      <div className="cc-field">
                        <CCLabel title="Use system defaults" isMandatory />
                        <Field
                          name={getNameOf("IssueLocationSystemDefaults")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update issue location types" />
                      <Field
                        name={getNameOf("Update_IssueLocationTypes")}
                        checked={valueGetter(
                          getNameOf("Update_IssueLocationTypes")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_IssueLocationTypes")) ? (
                      <div className="cc-field">
                        <CCLabel title="Issue location types" isMandatory />
                        <Field
                          name={getNameOf("IssueLocationTypes")}
                          textField={"Value"}
                          dataItemKey={"Key"}
                          data={
                            svcStandardBatchUpdateLovs?.IssueLocationTypes ?? []
                          }
                          component={CCMultiSelectDropdown}
                          onChange={handleChangeIssueLocationType}
                          value={issueLocationTypesValue}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}
                  </div>
                </section>

                <hr className="cc-divider" />

                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update tier lookup" />
                      <Field
                        name={getNameOf("Update_TierLookup")}
                        checked={valueGetter(getNameOf("Update_TierLookup"))}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>

                    {valueGetter(getNameOf("Update_TierLookup")) ? (
                      <div className="cc-field">
                        <CCLabel title="Enable tier lookup" isMandatory />
                        <Field
                          name={getNameOf("EnableTierLookup")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>
                    ) : null}

                    {valueGetter(getNameOf("EnableTierLookup")) ? (
                      <div className="cc-field">
                        <CCLabel title="Default tier" />
                        <Field
                          name={getNameOf("Tier_ID")}
                          dataItemKey="Key"
                          textField="Value"
                          data={svcStandardBatchUpdateLovs?.Tier ?? []}
                          isUseDefaultOnchange
                          component={CCSearchComboBox}
                          placeholder="Default tier"
                        />
                      </div>
                    ) : null}
                  </div>
                </section>
              </>
            }
          />
          <CustomPanelBar
            expandMode="multiple"
            listPanelBar={[
              {
                title: "System interface",
                component: (
                  <SystemInterface
                    formRenderProps={formRenderProps}
                    isDisabled={isDisabled}
                  />
                ),
                expanded: isExpandedSystemInterface,
              },
              {
                title: "Special interest",
                component: (
                  <SpecialInterest
                    formRenderProps={formRenderProps}
                    isDisabled={isDisabled}
                  />
                ),
                expanded: isExpandedSpecialInterest,
              },
              {
                title: "Target days",
                component: (
                  <TargetDays
                    formRenderProps={formRenderProps}
                    isDisabled={isDisabled}
                  />
                ),
              },
              {
                title: "Workflow/Security",
                component: (
                  <WorkflowSecurity
                    formRenderProps={formRenderProps}
                    isDisabled={isDisabled}
                  />
                ),
                expanded: isExpandedWorkflowSecurity,
              },
              {
                title: "Communications",
                component: (
                  <Communications
                    formRenderProps={formRenderProps}
                    isDisabled={isDisabled}
                  />
                ),
              },
              {
                title: "Records",
                component: (
                  <CRMSRecord
                    formRenderProps={formRenderProps}
                    isDisabled={isDisabled}
                    recordsSystem={recordsSystem}
                  />
                ),
                isVisible: recordsSystem !== RECORDSSYSTEM.None,
              },
              // Show it when implementing in the service standard manage page
              // {
              //   title: "Bulletin board",
              //   component: (
              //     <BulletinBoard
              //       formRenderProps={formRenderProps}
              //       isDisabled={isDisabled}
              //     />
              //   ),
              // },
              {
                title: "Case details",
                component: (
                  <CaseDetails
                    formRenderProps={formRenderProps}
                    isDisabled={isDisabled}
                  />
                ),
              },
              // {
              //   title: "Mobile details",
              //   component: (
              //     <MobileDetails
              //       formRenderProps={formRenderProps}
              //       isDisabled={isDisabled}
              //     />
              //   ),
              // },
            ]}
          />
        </FormElement>
      </>
    );
  }
);
