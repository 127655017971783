import { loadInspectionByCaseId } from "@app/products/property/fire-prevention/components/action-bar/buttons/re-inspection/api";
import { isMultipleRecordsWithTheSameID } from "@app/products/property/fire-prevention/components/action-bar/buttons/re-inspection/util";
import NewInspectionDialog from "@app/products/property/fire-prevention/components/dialogs/new-inspection/_index";
import { IReInspectionFormData } from "@app/products/property/fire-prevention/components/dialogs/re-inspection/model";
import { ReInspectionDialog } from "@app/products/property/fire-prevention/components/dialogs/re-inspection/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
export const ReInspection = observer(() => {
  const { gridSelectedRows } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();
  const [formData, setFormData] = useState<IReInspectionFormData | undefined>();
  const [showNewInspectionDialog, setShowNewInspectionDialog] = useState(false);
  const isDisableButton = useMemo(() => {
    if (gridSelectedRows.length > 1) {
      //enable button when user choose multiple records with the same CaseId
      return isMultipleRecordsWithTheSameID(gridSelectedRows, "CaseId");
    }
    return gridSelectedRows.length === 0 || gridSelectedRows.length > 1;
  }, [gridSelectedRows]);

  return (
    <>
      <CCNavButton
        title="Re-inspection"
        onClick={() => {
          if (isDisableButton) return;
          const caseId = gridSelectedRows[0].CaseId;
          if (!caseId) return;
          loadInspectionByCaseId(caseId).then((data: IReInspectionFormData) => {
            if (!data) return;
            setFormData(data);
          });
        }}
        disabled={isDisableButton}
      />

      {formData && (
        <ReInspectionDialog
          onEnterAndDone={() => {
            pushNotification({
              title: "Inspection updated successfully",
              type: "success",
            });
            setFormData(undefined);
          }}
          oneEnterAndNew={() => {
            pushNotification({
              title: "Inspection updated successfully",
              type: "success",
            });
            setFormData(undefined);
            setTimeout(() => {
              setShowNewInspectionDialog(true);
            }, 500);
          }}
          onClose={() => {
            setFormData(undefined);
          }}
          formData={formData}
        />
      )}

      {showNewInspectionDialog && (
        <NewInspectionDialog
          onEnterAndDone={() => {
            setShowNewInspectionDialog(false);
            pushNotification({
              title: "New inspection created successfully",
              type: "success",
            });
          }}
          onEnterAndNew={() => {
            setShowNewInspectionDialog(false);
            setTimeout(() => {
              setShowNewInspectionDialog(true);
            }, 500);
            pushNotification({
              title: "New inspection created successfully",
              type: "success",
            });
          }}
          onClose={() => {
            setShowNewInspectionDialog(false);
          }}
        />
      )}
    </>
  );
});
