import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const mockOfficerList: IKeyValuePacket[] = [
  {
    Key: 1,
    Value: "Alford Flatley",
  },
  {
    Key: 2,
    Value: "Mariano Johnson",
  },
  {
    Key: 3,
    Value: "Marilou Rempel",
  },
  {
    Key: 4,
    Value: "Tyra Hahn",
  },
];
