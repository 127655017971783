import { eventEmitter } from "@/App";
import { isSuccessResponse } from "@common/apis/util";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { postRecycleBinRestore } from "@common/pages/settings/system-admin/recycle-bin/components/buttons/restore/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useState } from "react";

interface RestoreButtonProps {
  disabled?: boolean;
}
export const RestoreButton = observer(({ disabled }: RestoreButtonProps) => {
  const {
    isEmptyGridSelectedRow,
    gridSelectedRows,
    setGridSelectedRows,
    setGridSelectedIds,
  } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const handleRestore = async () => {
    setIsLoading(true);
    const response = await postRecycleBinRestore(gridSelectedRows);
    setIsLoading(false);
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        setGridSelectedRows([]);
        setGridSelectedIds([]);
        eventEmitter.emit(CCGridEventType.RefreshOData);
        pushNotification({
          title: `Restore ${gridSelectedRows.length} ${pluralize(
            "record",
            gridSelectedRows.length
          )} successfully`,
          type: "success",
        });
      } else {
        pushNotification({
          autoClose: false,
          title: `Restore ${gridSelectedRows.length} ${pluralize(
            "record",
            gridSelectedRows.length
          )} failed`,
          type: "error",
          description: response.data?.Errors,
        });
      }
    } else {
      pushNotification({
        autoClose: false,
        title:
          response?.error ??
          `Restore ${gridSelectedRows.length} ${pluralize(
            "record",
            gridSelectedRows.length
          )} failed`,
        type: "error",
      });
    }
  };
  return (
    <CCNavButton
      isLoading={isLoading}
      disabled={isEmptyGridSelectedRow || disabled}
      title={"Restore"}
      onClick={handleRestore}
    />
  );
});
