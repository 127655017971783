import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(() => {
  const { isEditing } = useSettingGlobalManageStore();
  const { getConfigDataField } = useSettingGlobalStore();

  const fieldObjectiveWebAppURL = getConfigDataField(
    ECorporateSettingsField.Objective_WebAppURL
  );
  const fieldObjectiveContainerPrefix = getConfigDataField(
    ECorporateSettingsField.Objective_ParentContainer_Prefix
  );
  const fieldObjectiveContainerErrorMessage = getConfigDataField(
    ECorporateSettingsField.Objective_ParentContainer_IncorrectMessage
  );
  const fieldObjectiveUnmappableContainer = getConfigDataField(
    ECorporateSettingsField.Objective_UnmappableReturnString
  );

  return (
    <>
      <section className="cc-field-group">
        {fieldObjectiveWebAppURL && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveWebAppURL}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldObjectiveContainerPrefix && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveContainerPrefix}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldObjectiveContainerErrorMessage && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveContainerErrorMessage}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldObjectiveUnmappableContainer && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveUnmappableContainer}
              isEditing={isEditing}
            />
          </div>
        )}
      </section>
    </>
  );
});
