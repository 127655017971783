export enum INFRINGEMENTS_SYSTEM_VALUE {
  None = "0",
  Infringe = "1",
  Custom = "2",
}

export interface IOptionsDefault {
  showField?: boolean;
  typeField?: string;
  rowsField?: number;
  disabled?: boolean | undefined;
  enumFieldName: number;
}
