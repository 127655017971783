import { AnimalTypeSubmitActions } from "@app/products/animals/[id]/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { ElementDisplayStatus } from "@app/products/crms/[id]/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const ViewOrIssuePermitAndKennel = observer(() => {
  const { isLoadingIssueAndView, UIControl, onSubmit } = useAnimalStore();

  const isVisible = useMemo(() => {
    return (
      UIControl?.WfBtnLLPermit?.DisplayStatus === ElementDisplayStatus.Visible
    );
  }, [UIControl]);

  if (isLoadingIssueAndView)
    return <Loading isLoading={isLoadingIssueAndView} isFullScreen />;

  return isVisible ? (
    <CCNavButton
      title={UIControl?.WfBtnLLPermit?.Value ?? "Permit"}
      onClick={onSubmit}
      name={AnimalTypeSubmitActions.IssueAndView}
    />
  ) : null;
});
