export const mockInitialValuesParcelZones = {
  SchemeName: "Hunters Hill LEP2012",
  ZoneSchemeHeading: "Hunters Hill LEP2012",
  ZoneSchemeText: "Hunters Hill LEP2012",
  ZoneName: "R2 Low Density Residential",
  ZoneCode: "R2",
  Header: "R2 Low Density Residential",
  Objectives: `-To provide for the housing needs of the community within a low density residential environment.\n-To enable other land uses that provide facilities or services to meet the day to day needs of residents.`,
  WithoutConsent: "Home occupations; Roads",
  WithConsent:
    "Bed and breakfast accommodation; Boarding houses; Child care centres; Community facilities; Dual occupancies; Dwelling houses; Group homes; Home-based child care; Places of public worship; Respite day care centres; Secondary dwellings",
  WithConcurrence: "",
  Prohibited: "Any development not listed above",
};
