import { ICCRoute } from "@common/constants/ICCRoute";

export const certificationsRoute: ICCRoute = {
  path: "certifications",
  name: "Certifications",
  children: [
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      component: require("./by-status/_index").default,
    },
    {
      path: "by-estate",
      name: "By Estate",
      component: require("./by-estate/_index").default,
    },
    {
      path: "not-certified",
      name: "Not Certified",
      component: require("./not-certified/_index").default,
    },
    {
      path: "cert-and-no-compliance",
      name: "Cert & No Compliance",
      component: require("./cert-and-no-compliance/_index").default,
    },
    {
      path: "compliance",
      name: "Compliance",
      component: require("./compliance/_index").default,
    },
    {
      path: "refused",
      name: "Refused",
      component: require("./refused/_index").default,
    },
    {
      path: "withdrawn",
      name: "Withdrawn",
      component: require("./withdrawn/_index").default,
    },
  ],
};
