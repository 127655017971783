import { DTO_Totals } from "@app/products/property/assessments/[id]/components/child-screens/transactions/model";
import {
  WorkflowApprovalStatus,
  WorkflowStepApprovalStatus,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { DTO_PIC_Transaction } from "@app/products/property/journals/[id]/components/child-screens/items/model";
import { DTO_Contact } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { DTO_LOV } from "@common/models/odataResponse";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields, ICustomFormat } from "@components/cc-grid/model";

export enum eComponent {
  Unknown = "Unknown",
  Assessment = "Assessment",
  Parcel = "Parcel",
  Title = "Title",
  Assessment_Transaction = "Assessment_Transaction",
  Certificate = "Certificate",
  Register_Account = "Register_Account",
  Supplementary = "Supplementary",
  Meter = "Meter",
  Scheme_Account = "Scheme_Account",
  Certificate_Request = "Certificate_Request",
  Charge = "Charge",
  Charge_Balances = "Charge_Balances",
  Notice_Group = "Notice_Group",
  Street_Locality = "Street_Locality",
  GIS_Selection = "GIS_Selection",
  Certificates_Type = "Certificates_Type",
  Active_Certificates = "Active_Certificates",
  Active_Certificates_for_Responsible_Area = "Active_Certificates_for_Responsible_Area",
  Register = "Register",
  Master_Property = "Master_Property",
  Scheme = "Scheme",
  Meter_Reading_Route = "Meter_Reading_Route",
  Meter_Reading_Device = "Meter_Reading_Device",
  Utility = "Utility",
  Scheme_Account_Transaction = "Scheme_Account_Transaction",
  Meter_Installation = "Meter_Installation",
  Register_Account_Transaction = "Register_Account_Transaction",
  Land_Tax_Exemption = "Land_Tax_Exemption",
  ACT_Lease_Transfer = "ACT_Lease_Transfer",
  Deferred_Duty_Account = "Deferred_Duty_Account",
  Deferred_Duty_Scheme = "Deferred_Duty_Scheme",
  Deferred_Duty_Transaction = "Deferred_Duty_Transaction",
  Deferred_Duty_Instalments = "Deferred_Duty_Instalments",
  Entity = "Entity",
  Contact_Name_and_Address = "Contact_Name_and_Address",
  Assessment_Levy_Id = "Assessment_Levy_Id",
  Tasks_Run_Id = "Tasks_Run_Id",
  Tasks_Id = "Tasks_Id",
  Collection = "Collection",
  MenuItem = "MenuItem",
  Journal = "Journal",
  Advanced_Search = "Advanced_Search",
  Report = "Report",
  Home = "Home",
  Charge_Instalment = "Charge_Instalment",
  Charge_Levy = "Charge_Levy",
  Application_Search_No_Results = "Application_Search_No_Results",
  Session = "Session",
  Associated_Name = "Associated_Name",
  Lease = "Lease",
  Debt_Recovery = "Debt_Recovery",
  Assessment_Rebate_Entitlement = "Assessment_Rebate_Entitlement",
  Arrangement = "Arrangement",
  Change_of_Ownership = "Change_of_Ownership",
  Supplementary_Assessment = "Supplementary_Assessment",
  Arrangement_Letter = "Arrangement_Letter",
  Name_and_Address = "Name_and_Address",
  Debt_Recovery_Action = "Debt_Recovery_Action",
  Debt_Recovery_PaymentsReceived = "Debt_Recovery_PaymentsReceived",
  Debt_Recovery_Exemption = "Debt_Recovery_Exemption",
  Mail_Box = "Mail_Box",
  Financial_Summary = "Financial_Summary",
  Rebate_Claim = "Rebate_Claim",
  Concession_Card = "Concession_Card",
  Valuation = "Valuation",
  Address = "Address",
  CommunityCentral_Town_Planning_Application = "CommunityCentral_Town_Planning_Application",
  CommunityCentral_Health_Manager_Premise = "CommunityCentral_Health_Manager_Premise",
  CommunityCentral_Animal_Registration = "CommunityCentral_Animal_Registration",
  CommunityCentral_Building_Application = "CommunityCentral_Building_Application",
  CommunityCentral_Local_Laws_Permit = "CommunityCentral_Local_Laws_Permit",
  CommunityCentral_Events_Management_System_Booking = "CommunityCentral_Events_Management_System_Booking",
  CommunityCentral_Waste_Water_Permit = "CommunityCentral_Waste_Water_Permit",
  HazardId_Fire_Prevention = "HazardId_Fire_Prevention",
  HazardId_Noxious_Weed = "HazardId_Noxious_Weed",
  CommunityFinance_Customer = "CommunityFinance_Customer",
  Charge_Run = "Charge_Run",
  Notice_Run = "Notice_Run",
  CommunityCentral_DevelopmentApplication = "CommunityCentral_DevelopmentApplication",
  CommunityCentral_StreetProtection = "CommunityCentral_StreetProtection",
  CommunityCentral_Other = "CommunityCentral_Other",
  Compliance = "Compliance",
  Communication_Template = "Communication_Template",
  Workflow = "Workflow",
  PIC = "PIC",
}

export enum eVisibility {
  Collapsed = "Collapsed",
  Hidden = "Hidden",
  Visible = "Visible",
}

export enum typeNumber {
  BigInt = 0,
  Int = 8,
  SmallInt = 16,
}

export enum typeDate {
  Date = 31,
}

export enum typeCurrency {
  Decimal = 5,
}

export enum typeText {
  Char = 3,
  NChar = 10,
  NText = 11,
  NVarChar = 12,
  Real = 13,
  Text = 18,
  VarChar = 22,
}

export enum typeDateTime {
  DateTime = 4,
}

export enum SqlDbType {
  BigInt = 0,
  Binary = 1,
  Bit = 2,
  Char = 3,
  DateTime = 4,
  Decimal = 5,
  Float = 6,
  Image = 7,
  Int = 8,
  Money = 9,
  NChar = 10,
  NText = 11,
  NVarChar = 12,
  Real = 13,
  UniqueIdentifier = 14,
  SmallDateTime = 15,
  SmallInt = 16,
  SmallMoney = 17,
  Text = 18,
  Timestamp = 19,
  TinyInt = 20,
  VarBinary = 21,
  VarChar = 22,
  Variant = 23,
  Xml = 25,
  Udt = 29,
  Structured = 30,
  Date = 31,
  Time = 32,
  DateTime2 = 33,
  DateTimeOffset = 34,
}

export enum ePrivilege {
  Maintain_Options = 2,
  Assessments_Maintain = 3,
  Parcels_Titles_Maintain = 4,
  Assessment_Edit_Alert_Message = 5,
  Assessments_Create = 10,
  Parcels_Titles_Create = 11,
  Assessments_Delete = 20,
  Parcels_Titles_Delete = 21,
  Assessments_View_Financials = 30,
  Assessments_Edit_Charge_Details = 31,
  Utilities_View = 32,
  Direct_Debit_View_details = 33,
  Assessments_View_Historical = 34,
  Certificates_View = 35,
  Assessment_Journals_Authorise_own = 38,
  Journals_Administrator = 39,
  Assessment_Journals_Enter = 40,
  Assessment_Journals_Authorise = 41,
  Assessment_Journals_Delete = 42,
  Assessment_Journals_Authorise_on_behalf_of_others = 43,
  Arrangements_Enquiry = 44,
  Arrangements_Enter = 45,
  Arrangements_Cancel = 46,
  Arrangement_Produce_Letters = 47,
  Sessions_Create = 50,
  Sessions_Update = 51,
  Sessions_Rollback = 52,
  Sessions_View = 53,
  Supplementary_Create_Valuation_Changes = 61,
  Supplementary_Maintain_Levy_Codes = 62,
  Certificates_Issue = 63,
  Certificates_Create = 64,
  Certificates_Override_Area_of_Responsibility_completed_flags = 65,
  Constraints_View_All = 70,
  Change_of_Ownerships = 80,
  Change_of_Ownerships_Edit_Rebate_Details = 81,
  Entities_Delete = 84,
  Entities_Maintain = 85,
  Entities_View = 86,
  Entities_View_Attributes = 87,
  Entities_View_Concession_Cards = 88,
  Rebate_Entitlement_Maintain = 90,
  Rebate_Entitlement_Delete = 91,
  Outline_Show_Locality_Street_Address = 101,
  Outline_Show_Change_of_Ownerships = 102,
  Outline_Show_Notice_Groups = 103,
  Outline_Show_Supplementaries = 104,
  Outline_Show_Audit_History = 105,
  Outline_Show_Concession_Card_Holders = 106,
  Outline_Show_Voters = 107,
  Outline_Show_Notice_Production = 108,
  Raise_Interest_on_all_outstanding_balances = 110,
  Raise_Interest_on_individual_Assessments = 111,
  Run_Certificate_Request_Data_Extract_Service = 120,
  Assessment_Trans_Edit_Desc_Notes_when_Jnl_Approved_Cancelled = 140,
  Register_Create_Accounts = 200,
  Register_Maintain_Options = 202,
  Register_Journals_Enter = 240,
  Register_Journals_Authorise = 241,
  Register_Journals_Delete = 242,
  Register_Journals_Authorise_on_behalf_of_others = 243,
  Register_Journals_Authorise_own = 244,
  Meters_Create = 300,
  Meters_Delete = 301,
  Meters_Enter_Readings = 302,
  Meters_Schedule_Special_Reads = 303,
  Meters_Transfer_associated_Assessments = 304,
  Meters_Transfer_associated_Accounts = 305,
  Meters_Remove_Replace_Meters = 306,
  Meters_Associate_Disassociate = 307,
  Meters_Maintain_Master_Sub_Meter_associations = 308,
  Meters_Update_Usages_Create_Journal = 309,
  Meters_Maintain_Options = 310,
  Schemes_Create_Delete_Schemes = 400,
  Schemes_Create_Delete_Notice_Runs = 401,
  Schemes_Maintain_Options = 402,
  Schemes_Journals_Enter = 440,
  Schemes_Journals_Authorise = 441,
  Schemes_Journals_Delete = 442,
  Schemes_Journals_Authorise_on_behalf_of_others = 443,
  Schemes_Journals_Authorise_own = 444,
  Schemes_Maintain_Instalments = 445,
  Debt_Recovery_Enquire = 500,
  Debt_Recovery_Maintain = 501,
  Debt_Recovery_Delete = 502,
  Debt_Recovery_Override_restrictions = 503,
  Compliance_Enquire = 550,
  Compliance_Maintain = 551,
  Compliance_Delete = 552,
  Compliance_Override_restrictions = 553,
  ACT_Land_Tax_Exemption_Enquire = 560,
  ACT_Land_Tax_Exemption_Maintain = 561,
  ACT_Land_Tax_Exemption_Delete = 562,
  Compliance_Maintain_Land_Rent = 563,
  ACT_Land_Rent_Enquire = 570,
  ACT_Land_Rent_Maintain = 571,
  ACT_Land_Rent_Delete = 572,
  ACT_Land_Rent_Balance_Maintain = 573,
  ACT_Lease_Transfer_Enquire = 600,
  ACT_Lease_Transfer_Maintain = 601,
  ACT_Lease_Transfer_Delete = 602,
  Deferred_Duty_Create_Delete_Accounts = 650,
  Deferred_Duty_Create_Delete_Notice_Runs = 651,
  Deferred_Duty_Maintain_Options = 652,
  Deferred_Duty_Journals_Enter = 660,
  Deferred_Duty_Journals_Authorise = 661,
  Deferred_Duty_Journals_Delete = 662,
  Deferred_Duty_Journals_Authorise_on_behalf_of_others = 663,
  Deferred_Duty_Journals_Authorise_own = 664,
  Deferred_Duty_Raise_Interest = 665,
  Deferred_Duty_Raise_Single_Account_Interest = 666,
  Deferred_Duty_Recalculate_Instalments = 667,
  Deferred_Duty_Recalculate_All_Instalments = 668,
  Maintain_Mailboxes = 700,
  View_Group_Tasks = 750,
  View_Own_Tasks = 751,
  Email_Manage = 900,
}

export enum eOutputComponentLineage {
  Assessment = 1,
  Parcel = 2,
  Title = 3,
}

export interface ResponseMessage {
  Next_ID?: number;
  Notification: string;
  SuccessMessage: string;
  ErrorMessage: string;
  IsSuccess: boolean;
  Component_Number?: number;
  Component_Name: string;
  WorkflowStatus?: number;
  Component_ID?: number;
  ID: number;
  Component_Reference: string;
  WorkflowApprovalStatus?: number;
  Gis_Mapping_Launch_Url: string;
  //option added more before
  Errors?: string[] | null;
}
export interface ResponseMessageWorkflow<T> extends ResponseMessage {
  ReturnData: T;
}
export interface RequestObjFromSpatial {
  GISReferences: string[];
  LGA: string;
  PrimaryLandUse: string;
  AssessmentStatus: number;
  RelatableLandArea: string;
  State: string;
  Country: string;
}

export enum WorkflowTypes {
  Default = 0,
  Create_Assessment = 1,
  Change_Of_Ownership = 2,
  Modify_Assessment = 4,
  Create_LandTaxExemption = 5,
  Create_Compliance = 6,
  Modify_Compliance = 7,
  Edit_Change_Of_Ownership = 8,
  Split_Assessment = 10,
  Reactivate_Assessment = 11,
  Associate_Assessment = 12,
  Amalgamate_Assessment = 13,
  Disassociate_Assessment = 14,
  New_Rebate = 15,
  Create_Parcel = 50,
  Modify_Parcel = 51,
  Modify_Parcel_GIS = 52,
  Create_MasterProperty = 53,
  Edit_MasterProperty = 54,
  Associate_Supplementary = 60,
  New_Supplementary = 61,
  Complete_Supplementary = 62,
  AssessmentAdjustment_Supplementary = 63,
  Authorise_Supplementary = 64,
  Incomplete_Supplementary = 65,
  Unauthorise_Supplementary = 66,
  Supplementary_Remove_Assessment = 67,
  Supplementary_Modify = 68,
  Create_DebtRecovery = 70,
  Create_DebtRecoveryExemption = 71,
  Journal_Assessment_Add_Transaction = 80,
  Journal_Add_Transaction = 81,
  Journal_Authorise = 82,
  Journal_Create = 83,
  Journal_Assessment_Reverse_Transaction = 84,
  Journal_Assessment_Raise_Charges = 85,
  Journal_Assessment_Raise_Interest = 86,
  Journal_Assessment_Adjust_Charge = 87,
  Journal_Assessment_Adjust_ChargeBalances = 88,
  Journal_Assessment_Transafer_Transaction = 89,
  Create_DeferredDuty = 90,
  Journal_Add_DDTransaction = 91,
  Modify_DeferredDuty = 92,
  Journal_DD_Reverse_Transaction = 93,
  Journal_DD_Transfer_Transaction = 94,
  Journal_DD_Raise_Interest = 95,
  Journal_DD_Writeoff_Balance = 96,
  Create_Entity = 110,
  Modify_Entity = 111,
  Certificate_Create = 120,
  Certificate_Modify = 121,
  AreaOfResponsibility_Modify = 122,
  Certificate_Replace = 123,
  Create_ChargeRun = 130,
  Update_ChargeRun = 131,
  Create_Deferment = 140,
  Modify_Deferment = 141,
  Create_RebateClaim = 150,
  Update_LossOfEligibilityDate = 151,
  Modify_ChargeDetail = 160,
  NoticeRun_Create = 170,
  PIC_Create = 180,
  PIC_Modify = 181,
  PIC_Reactivate = 182,
  PIC_Amalgamate = 183,
  Journal_PIC_AddTransaction = 184,
  Subdivisions_Consolidations = 190,
  ModifyGISReferenceTitle = 191,
  CreateTitle = 192,
  Modify_Title = 193,
  Change_Of_Ownership_NoticeOfSale = 200,
  Master_Property_Adjustment_Supplementary = 300,
  // TODO: Change enum when have API
  UpdateLeaseTransfer,
  ModifyDebtRecovery,
  CreateSupplementaryWithImportValuation,
  AssessmentLandRentAction,
  BulkLevyUpdate,
}
export enum EPrivilegesWorkflow {
  Associate_Supplementary = 10000,
  Associate_Assessment = 10001,
  Create_Assessment = 10002,
  Disassociate_Assessment = 10003,
  Modify_Assessment = 10004,
  Journal_Assessment_Add_Transaction = 10005,
  Journal_Assessment_Adjust_ChargeBalances = 10006,
  Journal_Assessment_Adjust_Charge = 10007,
  Amalgamate_Assessment = 10008,
  Modify_ChargeDetail = 10009,
  Journal_Assessment_Raise_Charges = 10010,
  Reactivate_Assessment = 10012,
  Journal_Assessment_Reverse_Transaction = 10013,
  Split_Assessment = 10014,
  Certificate_Create = 10015,
  Certificate_Modify = 10016,
  Change_Of_Ownership = 10017,
  Edit_Change_Of_Ownership = 10018,
  Create_ChargeRun = 10019,
  Update_ChargeRun = 10020,
  AreaOfResponsibility_Modify = 10021, // Modify compliance
  Create_Entity = 10022,
  Modify_Entity = 10023,
  Create_DebtRecoveryExemption = 10024,
  Create_DebtRecovery = 10025,
  Create_Deferment = 10026,
  Modify_Deferment = 10027,
  Create_DeferredDuty = 10028,
  Modify_DeferredDuty = 10029,
  Journal_DD_Raise_Interest = 10030,
  Journal_DD_Reverse_Transaction = 10031,
  Journal_DD_Transfer_Transaction = 10032,
  Journal_DD_Writeoff_Balance = 10033,
  Journal_Authorise = 10034,
  Journal_Create = 10035,
  Journal_Add_Transaction = 10036,
  Create_LandTaxExemption = 10037,
  Create_MasterProperty = 10038,
  Edit_MasterProperty = 10039,
  NoticeRun_Create = 10040,
  Export_Notice_Output_Run = 10041, // dialog
  Create_Parcel = 10042,
  Modify_Parcel_GIS = 10043,
  Modify_Parcel = 10044,
  PIC_Reactivate = 10045,
  PIC_Amalgamate = 10046,
  PIC_Create = 10047,
  PIC_Modify = 10048,
  Create_RebateClaim = 10049,
  Update_LossOfEligibilityDate = 10050, //Update_Rebate_Loss_Of_Eligibility
  AssessmentAdjustment_Supplementary = 10051,
  Complete_Supplementary = 10052,
  New_Supplementary = 10053,
  ModifyGISReferenceTitle = 10054,
  Subdivisions_Consolidations = 10055,
  // Add more mappings following the same pattern
  Assessment_Transfer_To_Another_Holding = 10063,
  Assessment_Cancel_Owner_Change = 10056,
  Journal_Remove_Transaction = 10057,
  Journal_Cancel_Transaction = 10058,
  Rebate_Delete_Rebate_Claim = 10059,
  Supplementary_Incomplete = 10060,
  Financial_Summary_Create = 10061,
  Financial_Summary_Modify = 10062,
  New_Rebate = 10064,
  Attachment_Add = 10066,
  Charge_Run_Delete = 10067,
  Notice_Of_Sale_Update = 10068,
  Notice_Of_Sale_Import = 10069,
  Notice_Of_Sale_Cancel = 10070,
  Notice_Of_Sale_Suspend = 10071,
  Notice_Of_Sale_Delete = 10072,
  Rebate_Add = 10073,
  House_Keeping_Queue = 10074,
  Collections_New = 10075,
  Collections_Modify = 10076,
  Comment_Add = 10077,
  Supplementary_Delete = 10078,
  Supplementary_Modify = 10079,
  Journal_Raise_Penalty_Interest = 10080,
  Centrelink_Request_Card_Remove = 10081,
  Master_Property_Adjustment_Supplementary = 10082,
  Centrelink_Request_Create = 10083,
  Centrelink_ProduceRequestFile = 10092,
  Centrelink_Import_Rejection = 10093,
  Centrelink_Import_Response = 10094,
  ModifySupplementary,
  AddSupplementaryMasterProperty,
}
export interface DTO_Component_UDAs {
  ItemData: {
    [key: string]: any;
  };
}

export interface IAttributeData {
  Id: number;
  Attribute: string;
  Value: any;
}
export interface DTO_Workflow_Base {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_Base;
}

export enum WorkflowProcessMode {
  Save = 0,
  Park = 1,
  Finish = 2,
  Cancel = 3,
  Approve = 4,
  Reject = 5,
  SendBack = 6,
  Incomplete = 7,
  Mapping = 8,
  Fetch = 9,
  //TODO: mock
  Reallocate = 10,
}

export const nameOfLov = nameOfFactory<DTO_LOV>();
export enum ViewConfiguration {
  OData_MasterProperties = 0,
  OData_Supplementaries = 1,
  OData_Journal = 2,
  OData_SupplementaryValuations = 3,
  OData_SupplementaryValuationAssessments = 4,
  OData_TransactionsNotInJournal = 5,
  OData_DDTransactionsNotInJournal = 7,
  Journal_Transactions = 8,
  OData_DeferredDuty_NoticeRuns = 9,
  OData_Rebates_RebateClaims = 13,
  OData_Assessment_Notices = 12,
  Sesion_Transactions = 14,
  OData_Rebates_ConcessionCards = 15,
  OData_Certificate_MainMenu_List = 16,
  Assessment_MailBoxList = 17,
  CentrelinkCustomerConfirmation_ViewRequestFile = 20,
  OData_CentrelinkResponse_MatchedRequiresNoFurtherAction = 21,
  OData_AuditHistory = 22,
  ChangeOfOwnership_LeaseTransfers_ToBeProcessed = 23,
  ChangeOfOwnership_LeaseTransfers_ListByImportFile = 24,
  OData_Certificates_By_Type = 25,
  OData_SupplementaryImportValuations = 26,
  NoticeGroups_List = 27,
  OData_Notices = 28,
  OData_NoticeAssessments = 29,
  ChangeOfOwnership_List_ExpanderDocuments = 30,
  OData_PIC = 32,
  Assessment_List = 33,
  Titles_List = 34,
  ChargeRun_PIC = 35,
  ChargeRun_PICChargeRun_List = 36,
  OData_NoticeOfSale = 37,
  Collections_List = 38,
  OData_NoticeOfSale_ServiceOfNoticeAddress = 39,
  OData_NoticeOfSale_Titles = 40,
  OData_NoticeOfSale_Titles_StreetAddress = 41,
  OData_NoticeOfSale_Titles_OwnerDetails = 42,
  OData_NoticeOfSale_Titles_PreviousOwnerDetails = 43,
  OData_Contact_List = 44,
  OData_Assessment_Balances = 46,
  OData_Assessment_Collections = 47,
  OData_Collection_Assessments = 48,
  OData_PICTransactionsNotInJournal = 49,
  OData_AlS_Return_Import_Files = 51,
  OData_CentreLinkResponseExternal_MatchedRequiresNoFurtherAction = 53,
  OData_CentreLinkResponseExternal_MatchedRequiresFurtherAction = 54,
  OData_CentreLinkRejection = 55,
  OData_CentrelinkResponse_MatchedRequiresFurtherAction = 56,
  OData_Workflows_Parked = 101,
  OData_Workflows_SentForApproval = 103,
  OData_Workflows_Cancelled = 104,
  OData_Workflows_Rejected = 105,
  OData_Workflows_Approved = 106,
  OData_Workflows_MyApprovals = 107,
  OData_SecondaryWorkflows = 108,
  OData_RelatedWorkflows = 109,
  MasterProperty_Valuations = 1000,
  MasterProperty_Assessment = 1001,
  Supplementary_Valuations = 1002,
  Supplementary_Transactions = 1003,
  Supplementary_TransactionDetails = 1004,
  Journal_Items_Type_Assessment = 1005,
  Journal_Transactions_Items = 1006,
  Supplementary_Levies = 1007,
  Assessment_Compliances = 1008,
  Assessment_LandTaxExemptions = 1009,
  Supplementary_MasterProperties = 1010,
  DeferredDuty_Transactions = 1011,
  SupplementaryRates_BalanceTransfers = 1012,
  SupplementaryRates_Charges = 1013,
  SupplementaryRates_Rebates = 1014,
  Compliance_Assessments = 1016,
  Comment = 1018,
  Assessment_LandRent = 1019,
  Assessment_LandRentActionDates = 1020,
  Supplementary_Assessments = 1021,
  Assessment_RebateTypeRatingPeriod = 1023,
  Assessment_RebateEntitlement = 1024,
  Assessment_RebateEntitlementEntities = 1025,
  Charge_Run_LevyRaised = 1026,
  ChargeRun_RebateApplied = 1027,
  ChargeRun_RebateSummary = 1028,
  ChargeRun_RebateLevySummary = 1029,
  Journal_SummaryList = 1030,
  Charge_Run_Assessment_Summary = 1031,
  Charge_Run_Assessment_FinancialAreaSummary = 1032,
  Charge_Run_Assessment_LevySummary = 1033,
  Charge_Run_Assessment_RebateSummary = 1034,
  ModifyCompliance_Assessments = 1035,
  Session_Transactions_Items = 1036,
  Assessment_RebateConcessionEntitlement = 1037,
  Session_List_JournalSummary = 1038,
  Supplementary_Valuations_OData = 1040,
  NoticeRuns_List = 1041,
  Session_GL_Postings = 1042,
  Charge_Run_LevySummary_Assesment_Type = 1043,
  Charge_Run_LevySummary_Property_Locality = 1044,
  Charge_Run_LevySummary_Assessment_Status = 1045,
  Charge_Run_LevySummary_FinancialArea = 1046,
  Charge_Run_LevySummary_FinancialGroup = 1047,
  ChangeOfOwnership_LeaseTransfers_Buyers = 1048,
  ChangeOfOwnership_LeaseTransfers_Exceptions = 1049,
  RebateClaim_Transactions = 1050,
  RebateClaim_Summary = 1051,
  Certificate_AreaofResponsibility = 1052,
  Certificate_AreaofBlances = 1053,
  Certificate_Rebates = 1054,
  Supplementary_LevySummary = 1055,
  Supplementary_LevyCalculationSummary = 1056,
  PIC_Folios = 1057,
  Assessment_Rebates = 1058,
  Assessment_Rating_Levies = 1059,
  Assessment_Parcels = 1060,
  Assessment_Valuations = 1061,
  Collections = 1070,
  Assessment_Subdivision_Progeny = 1071,
  Assessment_Subdivision_Parent = 1072,
  PIC_Transactions = 1090,
  Certificate_AdverseAffection = 1091,
  Charge_Run_LevySummary_Region = 1092,
  Journal_Items_Type_PIC = 1094,
  Charge_Run_LevySummary = 1096,
  Entity_Nominated_Properties = 1103,
  Entity_Voting_Entitlements = 1104,
  Supplementary_MasterProperty_Assessments = 1105,
  Associations_Parcels = 2000,
  Associations_DeferredDuty = 2001,
  Associations_Assessment = 2002,
  Associations_Title = 2003,
  Associations_PIC = 2004,
  Associate_Assessment = 2005,
  Associations_ChargeRun = 2006,
  Associations_PICChargeRun = 2007,
  Associations_RebateEntitlement = 2008,
  Contact_Related_AssociatedNames = 2009,
  DeferredDuty_AccountLookup = 3000,
  AssessmentAdjustment_Levies = 3001,
  AssessmentAdjustment_OldCharges = 3002,
  AssessmentAdjustment_NewCharges = 3003,
  AssessmentAdjustment_Adjustments = 3004,
  AssessmentAdjustment_RatingPeriod = 3005,
  AssessmentAdjustment_Valuation = 3006,
  AssessmentSearch = 3007,
  ParcelSearch = 3008,
  TitleSearch = 3009,
  PIC_Lookup = 3010,
  SplitAssessment_Assessment = 3011,
  SplitAssessment_Parcel = 3012,
  SplitAssessment_ParcelUnaffected = 3013,
  TitleLookup = 3014,
  Workflow_Document = 3015,
  ZoneLookup = 3016,
  ConstraintLookup = 3017,
  LandUseLookup = 3018,
  AssociateSupplementary_Assessment = 3019,
  ChangeOfOwnership_NoticeOfSale_Assessment = 3020,
  ChangeOfOwnership_NoticeOfSale_Title = 3021,
  ChangeOfOwnership_NoticeOfSale_PIC = 3022,
  Parcel_Assessments = 3023,
  MasterPropertyAdjustment_Valuation = 3024,
}

// View Column Configure
export interface ICCViewConfiguration {
  ColumnDefinitions: ICCViewColumns;
}

export interface ICCViewColumns {
  Columns: ICCViewColumn[];
}

export interface ICCViewColumn {
  DataColumn: string;
  ColumnName: string;
  IsHidden: boolean;
  IsCurrency?: boolean;
  DecimalPlaces?: number | null;
}

export interface DTO_Transaction_Grouping {
  GroupByFieldNames: string[];
  Transactions: DTO_Transaction[];
  PIC_Transactions: DTO_PIC_Transaction[];
  Totals: DTO_TransactionTotals;
}

export interface DTO_TransactionTotals {
  Amount: number | null;
}

export interface DTO_Journal_Transactions {
  View: eTransactionViews | null;
  JournalNumber: number | null;
  Year: number | null;
  ShowAllocation: boolean;
  ShowCancelledTransactions: boolean;
  TransactionGrouping: DTO_Transaction_Grouping;
  TransactionView: DTO_Journal_TransactionViews;
  TransactionAllocations: DTO_TransactionAllocation;
}

export interface DTO_Transaction {
  JournalNumber: number | null;
  AssessmentId: number | null;
  TransactionType: string;
  DateTime: Date;
  Amount: number;
  ProgressiveBalance: number | null;
  Reference: string;
  Description: string;
  ReceiptNumber: number | null;
  Notes: string;
  TransactionSource: string;
  SourceId: number | null;
  IsProposed: boolean;
  IsCancelled: boolean;
  AutoAllocate: boolean;
  FinancialYear: string;
  CreatedOn: Date | null;
  CreatedBy: string;
  PaymentAllocationNumber: number | null;
  RebateClaimId: number | null;
  AssessmentRebateEntitlementId: number | null;
  AssessmentNumber: number | null;
  AssessmentReference: string;
  AssessmentPropertyAddress: string;
  AssessmentRatePayer: string;
  SessionNumber: number | null;
  SessionName: string;
  TransactionId: number;
}
export interface DTO_Journal_TransactionViews {
  Views: mTransactionView[];
}

export enum eTransactionViews {
  All = 0,
  Grouped_by_Type = 1,
  Grouped_by_Financial_Year = 2,
  Financial_Year = 3,
  From_Financial_Year = 4,
  Created_by_me = 5,
}

export interface mTransactionView {
  View: eTransactionViews | null;
  FinancialYear: mFinancialYear | null;
  Id: number;
  Name: string;
  //unique key add more in UX
  Key: number;
}

export interface ITransactionViewProps extends mTransactionView {
  TempId: string;
}

export interface mFinancialYear {
  Year: number;
  Year2: number;
  FromDate: string;
  ToDate: string;
}

export interface DTO_TransactionAllocation {
  TransactionItem: DTO_Transaction_Item[];
  Totals: DTO_Totals;
}

export interface DTO_Transaction_Item {
  ChargeBalancesId: number;
  Amount: number;
  ChargeDate: Date | null;
  ChargeName: string;
  ChargeCode: string;
  ChargeDescription: string;
  InstalmentNumber: number | null;
  ChargeInstalmentDueDate: Date | null;
  LevyName: string;
  LevyCode: string;
  ArrangementNumber: number | null;
  Id: number;
}

export interface DTO_Transaction {
  JournalNumber: number | null;
  TransactionType: string;
  DateTime: Date;
  Amount: number;
  ProgressiveBalance: number | null;
  Reference: string;
  Description: string;
  ReceiptNumber: number | null;
  Notes: string;
  TransactionSource: string;
  SourceId: number | null;
  IsProposed: boolean;
  IsCancelled: boolean;
  AutoAllocate: boolean;
  FinancialYear: string;
  CreatedOn: Date | null;
  CreatedBy: string;
  PaymentAllocationNumber: number | null;
  RebateClaimId: number | null;
  AssessmentRebateEntitlementId: number | null;
  AssessmentReference: string;
  AssessmentPropertyAddress: string;
  AssessmentRatePayer: string;
  TransactionId: number;
  TransactionAllocation: DTO_TransactionAllocation;
  Id: number;
}

// Workflow button status
export enum EListSubmitButton {
  //Name button cc-[process-workflow-api]-button
  Approve = "cc-approve-button",
  Finish = "cc-finish-step-button",
  Park = "cc-park-button",
  Save = "cc-save-button",
  SendBack = "cc-send-back-button",
  Reallocate = "cc-reallocate-button",
  Reject = "cc-reject-button",
  Close = "cc-close-button",
  Cancel = "cc-cancel-button",
  //Name button cc-[action]-[process-workflow-calling-api]-button
  ConfirmCloseYes = "cc-close-park-button",
  ConfirmCloseNo = "cc-close-cancel-button",
  EventNumber = "cc-event-number-button",
  SaveWorkflow = "cc-save-workflow-button",
  MappingSpatialWorkflow = "cc-mapping-spatial-workflow-button",
  FetchSpatialWorkflow = "cc-fetch-spatial-workflow-button",
  ViewMappingSpatialWorkflow = "cc-view-mapping-spatial-workflow-button",
}

export enum EWorkflowStatus {
  New = "New",
  Save = "Save",
  Incomplete = "Incomplete",
  Park = "Parked",
  Cancelled = "Cancelled",
  Completed = "Completed",
}

export enum EApprovalStatusName {
  Current = "Current",
  Pending = "Pending",
}

export enum ERefreshPageStep {
  Finish = "Finish",
  Approve = "Approve",
}

export interface DTO_WorkflowHeader {
  WorkflowDraft: DTO_Workflow_Draft;
  AvailableSecondaryWorkflows: DTO_Secondary_Workflow[];
  WorkflowApprovals: DTO_Workflow_Approval_Step[];
  // TODO: Add optional to not affect the older functions
  AdhocStep?: DTO_Workflow_Approval_Step;
  OfficerCanApprove?: boolean | null;
  WD_Require_Approval?: boolean | null;
  DeletedAdhocStepId?: number | null;
  Officer?: string | null;
  OfficerAssessmentGroups?: DTO_LOV[] | null;
  RelatedComponents?: mComponentId[] | null;
  WD_Require_SendBack?: boolean;
  SpatialConfig?: DTO_SpatialSettings;
  WD_Gis_Mapping_Launch_Url?: string;
  WD_Gis_View_Launch_Url?: string;
  Flags?: DTO_Form_Flags;
  OfficerIsAdministrator?: boolean;
}

export interface DTO_Form_Flags {
  Requires_Approval?: boolean;
  Requires_Gis_Approval?: boolean;
  Gis_Option: eGISOptions;
  Enable_Interest?: boolean;
  Is_Part_of_Master_Property?: boolean;
  Can_Reject?: boolean;
  Requires_Gis_Update?: boolean;
}

export enum eGISOptions {
  None = 0,
  GIS_Single_Workflow = 1,
  GIS_Approval_Workflow = 2,
}

export interface DTO_SpatialSettings {
  BaseUrl: string;
  APIKeyParameter: string;
  APIKey: string;
  ActionURL: string;
}

export interface mComponentId {
  Component: eComponent;
  Id: number;
}
export interface DTO_Workflow_Draft {
  // TODO: Temporary property use for the older functions
  WD_Data_Json?: string;

  // TODO: Full DTO but need recheck for older functions
  WD_Rejection_Reason_Id?: number | null;
  WD_Rejection_Date?: Date | null;
  WD_Rejection_Officer_Id?: number | null;
  WD_Workflow_Approved_Officer_Id?: number | null;
  WD_Cancellation_Reason_Id?: number | null;
  WD_Cancellation_DateTime?: Date | null;
  WD_Cancellation_Officer_Id?: number | null;
  WD_Workflow_Type?: number | null;
  WD_Workflow_Approval_Status?: WorkflowApprovalStatus;
  WD_Workflow_Status?: number | null;
  WD_Approval_DateTime?: Date | null;
  WD_Completion_DateTime?: Date | null;
  WD_Modify_DateTime?: Date | null;
  WD_Create_DateTime?: Date | null;
  WD_Assessment_Group_Id?: number | null;
  WD_Component_Id?: number | null;
  WD_Component_Number?: number | null;
  WD_Form_Id?: number | null;
  WD_Officer_User_Id?: number | null;
  Workflow_Draft_Id: number;
  WD_SendBack_Reason_Id?: number | null;
  WD_SendBack_Date?: Date | null;
  WD_SendBack_Officer_Id?: number | null;
  WD_Mapping_Status_Message?: string;
  WD_Mapping_Status?: boolean;
  WD_Fetch_Status_Message?: string;
  WD_Fetch_Status?: boolean;
  //Spatial
  WD_GIS_Mapping_Invoked_Date?: Date;
  WD_GIS_Fetched_Date?: Date;
  WD_GIS_Approved?: boolean;
  WD_GIS_Approved_Date?: Date;
  // TODO: Add optional to not affect the older functions
  WD_Cancellation_Additional_Comments?: string;
  WD_Rejection_Additional_Comments?: string;
  WD_SendBack_Additional_Comments?: string;
}

export interface DTO_Form {
  FormId: number;
  WorkflowType: number;
  ComponentNo?: number;
}

export interface DTO_Secondary_Workflow {
  WorkflowType_Name: string;
  WorkflowType_Int: number | null;
  WorkflowType_Enum: WorkflowTypes | null;
  Workflow_Sequence: number | null;
  Workflow_IsMandatory: boolean | null;
  Form_ParentChildId?: number | null;
  Is_Active?: boolean | null;
  Is_Included?: boolean | null;
}

export interface DTO_Workflow_Approval_Step {
  // TODO: Temporary property use for the older functions
  WorkflowStepId?: number;
  WorkflowStatus?: string;
  Date?: string;
  AllocatedDate?: Date;
  CompletedDate?: Date;
  Status?: WorkflowStepApprovalStatus;

  // TODO: Full DTO but need recheck for older functions
  Form_Id: number | null;
  WDA_Approval_Security_Group_Id: number | null;
  AssessmentGroupId: number | null;
  WDA_Create_DateTime: Date | null;
  OfficerId: number | null;
  ApprovalStatus: WorkflowStepApprovalStatus | null;
  WorkflowApprovalId: number | null;
  WorkflowDraftApprovalId: number | null;
  CreatedBy: number | null;

  // TODO: Add optional to not affect the older functions
  ApprovalStatusName?: string;
  SecurityGroup?: string;
  WorkflowStepSequence?: number;
  AssessmentGroup?: string;
  WorkflowDetails?: string;
  WorkflowStepName?: string;
  FunctionalGroup?: string;
}

export enum eAssociatedItemTypes {
  Assessment = 10,
  Assessment_Rebate_Entitlement = 11,
  Assessment_via_Parcel = 12,
  Assessment_via_Account = 13,
  Assessment_via_Meter = 14,
  Parcel = 20,
  Parcel_via_Assessment = 21,
  Parcel_via_Account = 23,
  Parcel_via_Meter = 24,
  Parcel_Address = 25,
  Title = 30,
  Title_via_Assessment = 31,
  Title_via_Parcel = 32,
  Title_via_Account = 33,
  Title_via_Meter = 34,
  Title_Entity_Role = 35,
  Title_Owners = 36,
  Certificate = 40,
  Certificate_via_Assessment = 41,
  Certificate_via_Parcel = 42,
  Debt_Recovery = 50,
  Debt_Recovery_via_Assessment = 51,
  Debt_Recovery_via_Account = 53,
  Debt_Recovery_Exemption = 54,
  Debt_Recovery_Exemption_via_Account = 55,
  Associated_Name = 60,
  Associated_Name_via_Assessment = 61,
  Associated_Name_via_Account = 63,
  Register_Account = 70,
  Register_Account_via_Assessment = 71,
  Register_Account_via_Parcel = 72,
  Scheme_Account = 80,
  Scheme_Account_via_Assessment = 81,
  Scheme_Account_via_Parcel = 82,
  Scheme_Account_via_Account = 83,
  Master_Property = 90,
  Master_Property_via_Account = 91,
  Meter = 100,
  Meter_via_Assessment = 101,
  Meter_via_Account = 103,
  Non_Meter_Account = 106,
  Arrangement = 110,
  Compliance = 120,
  Lease = 130,
  Deferred_Duty = 140,
  Entities = 150,
  CommunityCentral_TownPlanning = 200,
  CommunityCentral_HealthManager_Premise = 201,
  CommunityCentral_Animals = 202,
  CommunityCentral_BuildingApplication = 203,
  CommunityCentral_WasteWaterPermit = 204,
  CommunityCentral_EMS_Booking = 205,
  CommunityCentral_LocalLawsPermit = 206,
  CommunityCentral_DevelopmentApplication = 207,
  CommunityCentral_StreetProtection = 208,
  CommunityCentral_Other = 220,
  HazardId_FirePrevention_Case = 300,
  HazardId_NoxiousWeeds_Case = 301,
  CommunityFinance_Customer = 400,
}

export interface DTO_WorkflowDetail_Base {
  SelectedSecondaryWorkflow: number[];
  SelectedCollection: Collection[];
  Contact: DTO_Contact;
  SpatialErrorMessage: string;
}

export interface Collection {
  Collection_Id: number;
  CollectionTypeName: string;
}

export interface IOptionalViewConfigParams {
  payload?: any;
  dynamicFormat?: (
    viewConfig: ICCViewColumn,
    colConfig: IColumnFields
  ) => ICustomFormat;
}

export interface DTO_Request_Base {
  StatusId?: number | null;
}

export enum eAccordion {
  Assessment_Actions_And_Workflows = 1,
  Assessment_Amounts_Owing = 2,
  Assessment_Annual_Returns = 3,
  Assessment_Arrangement = 4,
  Assessment_Associated_Assessment = 5,
  Assessment_Attributes = 6,
  Assessment_Certificates = 7,
  Assessment_Charges = 8,
  Assessment_Collections = 9,
  Assessment_Comments = 10,
  Assessment_Compliance = 11,
  Assessment_Contacts = 12,
  Assessment_Debt_Recovery = 13,
  Assessment_Deferments = 14,
  Assessment_Documents = 15,
  Assessment_GIS_References = 16,
  Assessment_Interest = 17,
  Assessment_Land_Rent = 18,
  Assessment_Levy_Balances = 19,
  Assessment_Lineage = 20,
  Assessment_Meters = 21,
  Assessment_Notices = 22,
  Assessment_Parcels = 23,
  Assessment_Rate_Breakup = 24,
  Assessment_Rating = 25,
  Assessment_Rebates = 26,
  Assessment_Registers = 27,
  Assessment_Related = 28,
  Assessment_SAP_Transactions = 29,
  Assessment_Transactions = 30,
  Assessment_Transfers = 31,
  Assessment_Valuations = 32,
  Centrelink_Response_Centrelink_Cards_Requiring_Actions = 33,
  Centrelink_Response_Centrelink_Concession_Cards = 34,
  Centrelink_Response_External_Concession_Cards_Requiring_Actions = 35,
  Centrelink_Response_External_Concession_Cards = 36,
  Certificate_Actions_And_Workflows = 37,
  Certificate_Balances = 38,
  Certificate_Collections = 39,
  Certificate_Comments = 40,
  Certificate_Compliance = 41,
  Certificate_Debt_Recovery = 42,
  Certificate_Deferments = 43,
  Certificate_Documents = 44,
  Certificate_Rates_Department = 45,
  Certificate_Related = 46,
  Certificate_Responses = 47,
  Charge_And_Notice_Runs_Notice_Run_Actions_And_Workflows = 48,
  Charge_And_Notice_Runs_Notice_Run_Assessments = 49,
  Charge_And_Notice_Runs_Notice_Run_Comments = 50,
  Charge_And_Notice_Runs_Notice_Run_Documents = 51,
  Charge_And_Notice_Runs_Notice_Run_Notice_Groups = 52,
  Charge_And_Notice_Runs_Notice_Run_Notices = 53,
  Charge_Run_Actions_And_Workflows = 54,
  Charge_Run_Assessments = 55,
  Charge_Run_Comments = 56,
  Charge_Run_Documents = 57,
  Charge_Run_Levies_Raised = 58,
  Charge_Run_Levy_Summary = 59,
  Charge_Run_Rebate_Summary = 60,
  Charge_Run_Rebates_Applied = 61,
  Compliance_Actions_And_Workflows = 62,
  Compliance_Assessment = 63,
  Compliance_Comments = 64,
  Compliance_Documents = 65,
  Compliance_Functionality_Restrictions = 66,
  Compliance_Related = 67,
  Contact_Actions_And_Workflows = 68,
  Contact_Alerts = 69,
  Contact_Associated_Contacts = 70,
  Contact_Comments = 71,
  Contact_Concession_Cards = 72,
  Contact_Documents = 73,
  Contact_Related = 74,
  Contact_SAP_Transactions = 75,
  Contact_Transfers = 76,
  Contact_Voting = 77,
  Debt_Recovery_Actions_And_Workflows = 78,
  Debt_Recovery_Assessments = 79,
  Debt_Recovery_Comments = 80,
  Debt_Recovery_Deferred_Duty = 81,
  Debt_Recovery_Documents = 82,
  Debt_Recovery_Related = 83,
  Deferred_Duty_Actions_And_Workflows = 84,
  Deferred_Duty_Attributes = 85,
  Deferred_Duty_Balances = 86,
  Deferred_Duty_Collections = 87,
  Deferred_Duty_Comments = 88,
  Deferred_Duty_Documents = 89,
  Deferred_Duty_Notice_Run_Documents = 90,
  Deferred_Duty_Notices = 91,
  Deferred_Duty_Related = 92,
  Deferred_Duty_Transactions = 93,
  E_Certificate_Request_Additional_Details = 94,
  E_Certificate_Request_Documents = 95,
  E_Certificate_Request_Request_Response = 96,
  HazlD_Case_Activities = 97,
  HazlD_Case_Inspections = 98,
  HazlD_Case_Memos = 99,
  Journal_Actions_And_Workflows = 100,
  Journal_Comments = 101,
  Journal_Deferred_Duty_Account_Transactions = 102,
  Journal_Documents = 103,
  Journal_Items = 104,
  Journal_Readings = 105,
  Journal_Related = 106,
  Journal_Usages = 107,
  Master_Property_Actions_And_Workflows = 108,
  Master_Property_Assessments = 109,
  Master_Property_Attributes = 110,
  Master_Property_Comments = 111,
  Master_Property_Documents = 112,
  Master_Property_Related = 113,
  Master_Property_Valuations = 114,
  Meter_Collections = 115,
  Meter_Installation = 116,
  Meter_Leakage = 117,
  Meter_Memo = 118,
  Meter_Readings = 119,
  Meter_Transactions = 120,
  Meter_Usage = 121,
  Meter_User_Fields = 122,
  Parcel_Actions_And_Workflows = 123,
  Parcel_Attributes = 124,
  Parcel_Certificates = 125,
  Parcel_Collections = 126,
  Parcel_Comments = 127,
  Parcel_Constraints = 128,
  Parcel_Contacts = 129,
  Parcel_Documents = 130,
  Parcel_GIS_References = 131,
  Parcel_Land_Uses = 132,
  Parcel_Lineage = 133,
  Parcel_Ownership = 134,
  Parcel_Related = 135,
  Parcel_Title = 136,
  Parcel_Zones = 137,
  PIC_Actions_And_Workflows = 138,
  PIC_Attributes = 139,
  PIC_Certificates = 140,
  PIC_Collections = 141,
  PIC_Comments = 142,
  PIC_Documents = 143,
  PIC_Title = 144,
  PIC_GIS_References = 145,
  PIC_Lineage = 146,
  PIC_Related = 147,
  PIC_SAP_Transactions = 148,
  PIC_Transactions = 149,
  PIC_Transfers = 150,
  PIC_Charge_Run_Comments = 151,
  PIC_Charge_Run_Documents = 152,
  PIC_Charge_Run_PIC = 153,
  Register_Memos = 154,
  Register_Meters = 155,
  Register_Names = 156,
  Register_Tag_History = 157,
  Register_Transactions = 158,
  Scheme_Accounts_In_This_Scheme = 159,
  Scheme_Collections = 160,
  Scheme_Account_Attributes = 161,
  Scheme_Account_Balances = 162,
  Scheme_Account_Financial_Summary = 163,
  Scheme_Account_Instalments = 164,
  Scheme_Account_Memos = 165,
  Scheme_Account_Notices = 166,
  Scheme_Account_Transactions = 167,
  Session_List_General_Ledger_Postings = 168,
  Session_List_Transactions = 169,
  Supplementary_Actions_And_Workflows = 170,
  Supplementary_Assessment = 171,
  Supplementary_Balance_Transfers = 172,
  Supplementary_Charges = 173,
  Supplementary_Comments = 174,
  Supplementary_Details = 175,
  Supplementary_Documents = 176,
  Supplementary_Levies = 177,
  Supplementary_Master_Properties = 178,
  Supplementary_Related = 179,
  Supplementary_Transactions = 180,
  Supplementary_Valuations = 181,
  Title_Actions_And_Workflows = 182,
  Title_Associated_Title = 183,
  Title_Attributes = 184,
  Title_Collections = 185,
  Title_Comments = 186,
  Title_Contacts = 187,
  Title_Documents = 188,
  Title_GIS_References = 189,
  Title_Lineage = 190,
  Title_Owners = 191,
  Title_Related = 192,
  Title_Transfers = 193,
  Centrelink_Response_Rejections = 194,
}
