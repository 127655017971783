import { getRemoveMeter } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/remove-meter/api";
import {
  IRemoveMeter,
  IRemoveMeterData,
} from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/remove-meter/model";
import { APIResponseError } from "@common/apis/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { TooltipValidator } from "@components/TooltipValidator/TooltipValidator";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<IRemoveMeter>();

interface IRemoveMeterDialog {
  onClose: () => void;
}
export const RemoveMeterDialog = observer(({ onClose }: IRemoveMeterDialog) => {
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const [initialValues, setInitialValues] = useState<
    IRemoveMeterData | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { pushNotification } = useCCAppNotificationStore();

  const loadInitialValue = () => {
    setIsLoading(true);
    getRemoveMeter().then((data) => {
      setInitialValues(data);
      setResponseLoadError(
        !data
          ? {
              status: APIResponseStatus.NOT_FOUND,
            }
          : undefined
      );
      setIsLoading(false);
    });
  };
  useEffectOnce(() => {
    loadInitialValue();
  });

  const handleSubmit = (data: any) => {
    onClose();
    pushNotification({
      title: "Meter removed successfully",
      type: "success",
    });
  };

  return (
    <Form
      onSubmitClick={(data) => {
        data.values._option = undefined;
        handleSubmit(data);
      }}
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter, onSubmit, valid } = formRenderProps;
        return (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="40%"
              titleHeader={"Remove Meter"}
              onClose={onClose}
              bodyElement={
                <div className="cc-form">
                  {isLoading ? <Loading isLoading={isLoading} /> : null}
                  {responseLoadError && !isLoading ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={loadInitialValue}
                    />
                  ) : null}
                  {initialValues ? (
                    <section className="cc-field-group">
                      <section>
                        <label className="cc-label">
                          Meter being removed/replaced
                        </label>
                        <div className="cc-custom-sub-panel-bar">
                          <section className="cc-field-group">
                            <div className="cc-form-cols-2">
                              <div className="cc-field">
                                <label className="cc-label">Meter ID</label>
                                <Field
                                  name={nameOf("MeterId")}
                                  component={CCInput}
                                  readOnly
                                  placeholder="Meter ID"
                                />
                              </div>

                              <div className="cc-field">
                                <label className="cc-label">Meter number</label>
                                <Field
                                  name={nameOf("MeterNumber")}
                                  component={CCInput}
                                  readOnly
                                  placeholder="Meter number"
                                />
                              </div>

                              <div className="cc-field">
                                <label className="cc-label">
                                  Property address
                                </label>
                                <Field
                                  name={nameOf("PropertyAddress")}
                                  component={CCInput}
                                  readOnly
                                  placeholder="Property address"
                                />
                              </div>

                              <div className="cc-field">
                                <label className="cc-label">
                                  Account names
                                </label>
                                <Field
                                  name={nameOf("AccountNames")}
                                  component={CCInput}
                                  readOnly
                                  placeholder="Account names"
                                />
                              </div>
                            </div>
                          </section>
                        </div>
                      </section>

                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">
                            Date of removal <TooltipValidator />
                          </label>
                          <Field
                            name={nameOf("DateOfRemoval")}
                            format={DATETIME_FORMAT.DATETIME_CONTROL}
                            component={CCDateTimePicker}
                            validator={requiredValidator}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">
                            Reading <TooltipValidator />
                          </label>
                          <Field
                            name={nameOf("Reading")}
                            component={CCInput}
                            placeholder="Reading"
                            validator={requiredValidator}
                          />
                        </div>

                        <div className="cc-field">
                          <label className="cc-label">
                            Status of existing meter
                          </label>
                          <Field
                            name={nameOf("StatusOfExistingMeter")}
                            data={valueGetter(
                              "_option.StatusOfExistingMeter.Data"
                            )}
                            textField="Value"
                            dataItemKey="Key"
                            component={CCSearchComboBox}
                          />
                        </div>

                        <div className="cc-field">
                          <label className="cc-label">Removed by</label>
                          <Field
                            name={nameOf("RemovedBy")}
                            component={CCInput}
                            placeholder="Removed by"
                          />
                        </div>

                        <div className="cc-field cc-col-span-2">
                          <label className="cc-label">Reason</label>
                          <Field
                            name={nameOf("Reason")}
                            component={CCTextArea}
                            rows={3}
                          />
                        </div>
                      </div>
                    </section>
                  ) : null}
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={onSubmit}
                    disabled={!valid || !initialValues}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
});
