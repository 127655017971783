export enum ICommunicationMethod {
  Email = 0,
  SMS = 1,
}
export interface IFieldSelection {
  text: string;
  code: string;
  id: number;
  value: any;
}
export interface ICommunicationTemplate {
  Sys_TimeStamp: string;
  CommunicationTemplate_ID: number;
  CommunicationTemplate_Name: string;
  CommunicationUsage_ENUM: string;
  Dataset_ENUM: string;
  Subject: string;
  SMSBody: string;
  EmailBody: string;
  ShowInCommunicationDialog: boolean;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_StatClockStatus_ENUM: string;
  MailMergeDocument_ID?: number;
}
