import { Keyword } from "@common/models/keyword";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Keyword>();
export const colRestrictedBreedAnimalsSettings: IColumnFields[] = [
  { field: nameOf("Name"), title: "Category", isGrow: true },
];

export const colRestrictedBreedDialog: IColumnFields[] = [
  { field: nameOf("Name"), title: "Name", isGrow: true },
];

export const defaultRestrictedBreedAnimalSettingsStyleGrid = {
  maxHeight: 600,
};
