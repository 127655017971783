import { ExistedPropertyRegister } from "@app/products/property/registers/[id]/components/forms/existed/_index";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { NoData } from "@components/no-data/NoData";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManagePropertyRegister = observer(() => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const registerId = params.id;
  const { resetMenu } = useCCSubActionBarStore();
  const { loadRegister, resetStore } = useRegisterStore();

  useEffect(() => {
    if (isNew) return;
    loadRegister(parseInt(registerId));
  }, [registerId, isNew, loadRegister]);

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });
  if (isNew) {
    return <NoData vertical />;
  }

  return <ExistedPropertyRegister />;
});

export default ManagePropertyRegister;
