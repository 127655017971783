import { IRouteStateForm } from "@common/pages/settings/system-admin/global-settings/_id/main/routeState";

export const modulesRouteStateGlobalSettings: IRouteStateForm[] = [
  {
    name: "Modules_InstalledModules",
    component: require("./forms/installed-modules-settings").default,
  },
  {
    name: "Modules_LaunchExternalGIS",
    component: require("./forms/launch-external-gis").default,
  },
];
