import { DTO_Valuation_Value } from "@app/products/property/assessments/[id]/components/child-screens/rating/model";
import { formatDynamicValuationsForCell } from "@app/products/property/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<DTO_Valuation_Value>();
export const colValue: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Type",
  },
  {
    field: nameOf("Value"),
    title: "Valuation",
    format: formatDynamicValuationsForCell,
    filter: "numeric",
  },
];
