import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { OrgUnitTabDetail } from "@common/pages/settings/security/org-structure/_id/components/reference-sidebar/detail/model";

export const getOrgUnitTabDetailsById = async (
  orgUnitID: number
): Promise<APIResponse<OrgUnitTabDetail | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<OrgUnitTabDetail>(
      `/api/core/internal/orgunit/${orgUnitID}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
