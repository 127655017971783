import { apiCoreDocumentAttachFile } from "@app/core/components/common/utils";
import { Attachment } from "@app/core/documents/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import { RecordCountPacket } from "@common/models/recordCountPacket";

export const postAttachment = async (
  attachments: Attachment
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreDocumentAttachFile(),
      attachments
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const deleteAttachments = async (
  IDs: number[]
): Promise<APIResponse<RecordCountPacket>> => {
  try {
    return await CoreAPIService.getClient().delete<RecordCountPacket>(
      apiCoreDocumentAttachFile(),
      {
        data: JSON.stringify(IDs),
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
