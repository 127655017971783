import { isSuccessResponse } from "@common/apis/util";
import { DownloadFile } from "@common/constants/downloadfile";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { useSelectionListener } from "@components/cc-grid/hooks/useSelectionListener";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { postExportFeeTypeList } from "./api";

const feeTypeContentTypeExport =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

interface IExportFeeTypesButtonProps {
  gridId?: string;
}

export const ExportFeeTypesButton = observer(
  ({ gridId }: IExportFeeTypesButtonProps) => {
    const { cancelToken } = useCancelRequest();
    const { pushNotification } = useCCAppNotificationStore();
    const { loader } = useSelectionListener(gridId);
    const { gridSelectedIds } = useCCProductListViewStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleOnClick = async () => {
      setIsLoading(true);
      let idsExport: number[] =
        gridSelectedIds?.length > 0 ? gridSelectedIds : [];
      if (loader.current.available) {
        idsExport = (
          await loader.current.process({ $select: ["ID"], $flat: true })
        )?.data ?? [];
      }
      if(idsExport?.length){
        const response = await postExportFeeTypeList(idsExport, cancelToken());
        setIsLoading(false);
        if (isSuccessResponse(response) && response?.data) {
          DownloadFile(
            response.data?.FileContent ?? "",
            response.data?.ContentType ?? feeTypeContentTypeExport,
            response.data?.FileName ?? "ExportFeeTypes.xlsx"
          );
        } else {
          pushNotification({
            title: `Export fee types failed.`,
            type: "error",
            autoClose: false,
          });
        }
      }else{
        pushNotification({
          title: `Select all records failed.`,
          type: "error",
          autoClose: false,
        });
      }
    };
    return (
      <CCNavButton
        title="Export selected existing fee types"
        onClick={handleOnClick}
        isLoading={isLoading}
        disabled={gridSelectedIds?.length === 0}
      />
    );
  }
);
