import { getViewConfigurations } from "@app/products/property/api";
import { AddPICLookupDialog } from "@app/products/property/components/dialogs/add-pic-lookup/_index";
import { colPicLookup } from "@app/products/property/components/dialogs/add-pic-lookup/config";
import { DTO_PIC } from "@app/products/property/components/dialogs/add-pic-lookup/model";
import { ViewConfiguration } from "@app/products/property/model";
import { usePICNewTransactionJournalStepStore } from "@app/products/property/pic/list/components/action-bar/form-steps/new-transaction/components/form-elements/pic/store";
import { formPICJournalValidator } from "@app/products/property/pic/list/components/action-bar/form-steps/new-transaction/components/form-elements/pic/util";
import { VO_PIC } from "@app/products/property/pic/list/model";
import { processDynamicColumns } from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { DTO_LOV } from "@common/models/odataResponse";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
const nameOfLovs = nameOfFactory<DTO_LOV>();

export const PICNewTransactionJournalFormStep = (props: IFormStepElement) => {
  const {
    options: { isReadOnly = false },
  } = props;
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!isReadOnly ? formPICJournalValidator : undefined}
    />
  );
};
const nameOfPIC = nameOfFactory<VO_PIC>();
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
    isLoadingStep,
    loadFailedStep,
    setIsLoadingStep,
    setLoadFailedStep,
    currentStepKeyName,
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { newPICJournalStepLOVs } = usePICNewTransactionJournalStepStore();
    //state local
    const [isShowAddPICDialog, setIsShowAddPICDialog] = useState(false);
    const [isShowConfirmDeletion, setIsShowConfirmDeletion] =
      useState<boolean>(false);
    const [columnsPIC, setColumnsPIC] = useState<IColumnFields[]>(colPicLookup);
    //get value
    const currentPIC = getFieldValue("PICs") ?? [];
    const selectedPIC = getFieldValue("_option.PICSelected") ?? [];

    /**
     * Load view configuration
     * Holding and PIC
     */
    const loadViewConfiguration = async () => {
      setIsLoadingStep(true);
      const viewPIC = await getViewConfigurations(ViewConfiguration.PIC_Lookup);
      setIsLoadingStep(false);
      if (
        isSuccessResponse(viewPIC) &&
        viewPIC?.data &&
        !viewPIC?.data?.hasOwnProperty("MessageType")
      ) {
        const newColumnPIC = processDynamicColumns(
          colPicLookup,
          viewPIC?.data?.ColumnDefinitions?.Columns
        );
        setColumnsPIC(newColumnPIC);
      } else {
        setLoadFailedStep({
          onReload: () => loadViewConfiguration(),
          responseError: {
            status: APIResponseStatus.INTERNAL_SERVER_ERROR,
            error: "Load view configuration failed",
          },
        });
      }
    };

    /**
     * get initial view configure
     */
    useEffectOnce(() => {
      (async () => {
        await loadViewConfiguration();
      })();
    });

    /**
     * handle add PIC
     * @param picList
     */
    const handleAddItemPIC = (picList: DTO_PIC[]) => {
      setIsShowAddPICDialog(false);
      const prevList = [...currentPIC];
      let uniqueArr = picList;
      if (prevList.length) {
        let mergedList = [...prevList, ...picList];
        // Create a Set of unique IDs
        let uniqueIds = new Set();
        mergedList?.forEach((obj: VO_PIC) => uniqueIds.add(obj.PIC_Id));
        // Create an array of unique objects
        uniqueArr =
          Array.from(uniqueIds).map((id) =>
            mergedList?.find((obj: DTO_PIC) => obj.PIC_Id === id)
          ) ?? [];
      }
      onChange(nameOf("PICs"), { value: uniqueArr });
    };

    /**
     * Handle delete pic
     */
    const handleDelete = () => {
      const selectedIDs = selectedPIC?.map((pic: DTO_PIC) => pic.PIC_Id);
      const newPIC = currentPIC?.filter(
        (pic: DTO_PIC) => !selectedIDs.includes(pic.PIC_Id)
      );
      onChange(nameOf("PICs"), { value: newPIC });
      onChange(nameOf("_option.PICSelected"), { value: [] });
    };

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }
    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }

    return (
      <>
        <section className="cc-field-group">
          <label className="cc-label">
            PICs
            <CCTooltip type="validator" position="right" />
            {errors?.[currentStepKeyName]?.length ? (
              <Error>{errors?.[currentStepKeyName]}</Error>
            ) : null}
          </label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              <CCGrid
                data={currentPIC ?? []}
                columnFields={columnsPIC}
                selectableMode="multiple"
                primaryField={nameOfPIC("PIC_Id")}
                selectedRows={selectedPIC ?? []}
                onSelectionChange={(dataItems) => {
                  onChange(nameOf("_option.PICSelected"), {
                    value: dataItems,
                  });
                }}
                readOnly={options?.isReadOnly}
                toolbar={
                  !options?.isReadOnly ? (
                    <div className="cc-grid-tools-bar">
                      <Button
                        iconClass="fas fa-plus"
                        title="Add a PIC"
                        onClick={() => {
                          setIsShowAddPICDialog(true);
                        }}
                      />
                      <Button
                        iconClass="fas fa-minus"
                        title="Remove a PIC"
                        onClick={() => setIsShowConfirmDeletion(true)}
                        disabled={selectedPIC.length < 1}
                      />
                    </div>
                  ) : null
                }
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <label className="cc-label">Transaction</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">
                  Type
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={nameOf("Transaction.TransactionTypeId")}
                  component={CCSearchComboBox}
                  data={newPICJournalStepLOVs?.PICTransactionTypes ?? []}
                  textField={nameOfLovs("Name")}
                  dataItemKey={nameOfLovs("Code")}
                  value={getDropdownValue(
                    getFieldValue("Transaction.TransactionTypeId"),
                    newPICJournalStepLOVs?.PICTransactionTypes ?? [],
                    "Code"
                  )}
                  disabled={options.isReadOnly}
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange(nameOf("Transaction.TransactionTypeId"), {
                      value: event.value?.Code,
                    });
                    onChange(nameOf("_option.Transaction.TransactionType"), {
                      value: event.value,
                    });
                  }}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Amount
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={nameOf("Transaction.TransactionAmount")}
                  component={CCCurrencyInput}
                  disabled={options.isReadOnly}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Date</label>
                <Field
                  name={nameOf("Transaction.TransactionDate")}
                  format={DATETIME_FORMAT.DATETIME_CONTROL}
                  component={CCDateTimePicker}
                  disabled={options.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Reference</label>
                <Field
                  name={nameOf("Transaction.Reference")}
                  placeholder={"Reference"}
                  component={CCInput}
                  readOnly={options.isReadOnly}
                />
              </div>
              <div className="cc-col-span-2">
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <label className="cc-label">Description</label>
                    <Field
                      name={nameOf("Transaction.Description")}
                      placeholder={"Description"}
                      component={CCInput}
                      readOnly={options.isReadOnly}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Notes</label>
                    <Field
                      name={nameOf("Transaction.Notes")}
                      placeholder={"Notes"}
                      component={CCTextArea}
                      rows={3}
                      readOnly={options.isReadOnly}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isShowConfirmDeletion && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={
              "Are you sure you wish to disassociate the selected PIC(s) from this journal?"
            }
            onClosePopup={() => {
              setIsShowConfirmDeletion(false);
            }}
            onConfirmYes={handleDelete}
          />
        )}
        {isShowAddPICDialog && (
          <AddPICLookupDialog
            onClose={() => setIsShowAddPICDialog(false)}
            handleAddPIC={handleAddItemPIC}
          />
        )}
      </>
    );
  }
);
