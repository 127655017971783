import { DTO_Workflow_CreateAssessment } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { WorkflowStatus } from "@app/products/property/components/action-bar/property-workflow/model";
import { isNil } from "lodash";

export const checkNumberOnlyValidator = (value: any) => {
  if (isNil(value) || value === "") return;
  const message = "This field only accepts numerical values";
  const regexOnlyNumber = /^\d+$/;

  if (!regexOnlyNumber.test(value)) return message;
  return;
};

export const getOfficerRegionId = (
  workflowDataFromNew: DTO_Workflow_CreateAssessment | undefined
) => {
  const currentWorkflowStatus =
    workflowDataFromNew?.WorkflowHeader?.WorkflowDraft?.WD_Workflow_Status;
  if (
    currentWorkflowStatus === WorkflowStatus.Incomplete ||
    currentWorkflowStatus === WorkflowStatus.Saved
  ) {
    return (
      workflowDataFromNew?.WorkflowDetail?.AssessmentDetail?.Sector ??
      workflowDataFromNew?.WorkflowHeader?.WorkflowDraft
        ?.WD_Assessment_Group_Id ??
      null
    );
  } else {
    return workflowDataFromNew?.WorkflowDetail?.AssessmentDetail?.Sector;
  }
};
