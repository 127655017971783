import { BuildingParentSection } from "@app/products/building/components/forms/building-parent-section/_index";
import { BuildingFieldMapping } from "@app/products/building/model";
import {
  appNoFields,
  officerFields,
} from "@app/products/building/registers/demolitions/[id]/components/child-screens/general/components/form-element/config";
import { IBuildingDemolitionFormData } from "@app/products/building/registers/demolitions/[id]/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { nameOfFactory } from "@common/utils/common";
import { formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";
interface IBuildingRegisterDemolitionFormElementProps {
  formRenderProps: FormRenderProps;
}

export const BuildingRegisterDemolitionFormElement = ({
  formRenderProps,
}: IBuildingRegisterDemolitionFormElementProps) => {
  const isNew = useIsNew();
  const { pushNotification } = useCCAppNotificationStore();
  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }

    if (!formRenderProps.valid) return;

    pushNotification({
      title: "The form is not modified",
      type: "warning",
    });
  };

  const nameOf = nameOfFactory<IBuildingDemolitionFormData>();
  const getValue = formRenderProps.valueGetter;

  return (
    <FormElement>
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />

      <BuildingParentSection formRenderProps={formRenderProps} />
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              rows={8}
              name={nameOf("Description")}
              placeholder="Description"
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Officer</label>
            <Field
              component={InputPicker}
              {...officerFields(formRenderProps)}
              name={BuildingFieldMapping.Officer}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Application number</label>
            <Field
              component={InputPicker}
              {...appNoFields(formRenderProps)}
              name={BuildingFieldMapping.AppNo}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">File number</label>
            <Field
              name={nameOf("FileNumber")}
              placeholder="File number"
              component={CCInput}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <CCValueField
            label="Lodged"
            value={getValue(nameOf("Lodged"))}
            format={DATE_FORMAT.DATE}
          />
          <CCValueField
            label="Decision"
            value={getValue(nameOf("DecisionType"))}
          />
          <CCValueField
            label="Decision date"
            value={getValue(nameOf("DecisionDate"))}
            format={DATE_FORMAT.DATE}
          />
        </div>
        <div className="cc-form-cols-3">
          <CCValueField
            label="OS fees"
            value={getValue(nameOf("OSFees"))}
            style={formatOSFees(getValue(nameOf("OSFees")))}
            format={CURRENCY_FORMAT.CURRENCY1}
          />
          <div className="cc-field">
            <label className="cc-label">Debtor number</label>
            <Field
              name={nameOf("DebtorNumber")}
              placeholder="Debtor number"
              component={CCInput}
            />
          </div>
          {!isNew && (
            <CCValueField
              label="Planner assessment"
              value={getValue(nameOf("PlannerAssessment"))}
            />
          )}
        </div>
      </div>
    </FormElement>
  );
};
