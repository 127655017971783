import { IPopulateCandidateDialog } from "@app/products/property/assessments/compliance/candidates/components/action-bar/dialogs/populate-candidates/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ComplianceCandidateStore {
  private _isLoading: boolean = false;
  private _isOpenPopulateDialog: boolean = false;
  private _populateCandidateData?: IPopulateCandidateDialog = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isOpenPopulateDialog() {
    return this._isOpenPopulateDialog;
  }
  setIsOpenPopulateDialog = (isOpenPopulateDialog: boolean) => {
    runInAction(() => {
      this._isOpenPopulateDialog = isOpenPopulateDialog;
    });
  };

  get populateCandidateData() {
    return this._populateCandidateData;
  }
  setPopulateCandidateData = (
    populateCandidateData?: IPopulateCandidateDialog
  ) => {
    runInAction(() => {
      this._populateCandidateData = populateCandidateData;
    });
  };

  //Action

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._isOpenPopulateDialog = false;
      this._populateCandidateData = undefined;
    });
  };

  loadCandidate = async (
    assessmentNumberFrom?: number | null,
    assessmentNumberTo?: number | null,
    activeCompliance: boolean = false,
    isChangeOfOwnership: boolean = false,
    isNACareOf: boolean = false,
    isPOBox: boolean = false,
    isCommonProperty: boolean = false
  ) => {
    this.setPopulateCandidateData({
      assessmentNumberFrom,
      assessmentNumberTo,
      activeCompliance,
      isChangeOfOwnership,
      isNACareOf,
      isPOBox,
      isCommonProperty,
    });
    this.setIsOpenPopulateDialog(false);
  };
}

const complianceCandidateStoreContext = createContext(
  new ComplianceCandidateStore()
);

export const useComplianceCandidateStore = () => {
  return useContext(complianceCandidateStoreContext);
};
