import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSFeesNotExportedDebtors: IColumnFields[] = [
  {
    field: CRSFieldMapping.DebtorNo,
    title: "Debtor Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.Status, title: "Status" },
  {
    field: CRSFieldMapping.Parent1Name,
    title: "Parent1 Name",
  },
  {
    field: CRSFieldMapping.Child_DisplayName,
    title: "Children Details",
  },
];
