import {
  DTO_NoticeRunTotal,
  DTO_NoticeRun_Summary,
} from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/model";
import { useNoticeRunsStore } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
} from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import { colNotices, colOutputHistory } from "./config";
interface INoticeRunFormElementProps {
  formRenderProps: FormRenderProps;
}
const nameOf = nameOfFactory<DTO_NoticeRun_Summary>();
const nameOfNotices = nameOfFactory<DTO_NoticeRunTotal>();
export const NoticeRunFormElement = observer(
  ({ formRenderProps }: INoticeRunFormElementProps): ReactElement => {
    const { noticeRuns } = useNoticeRunsStore();
    const getValue = formRenderProps.valueGetter;

    //Get label
    const assessmentLowercaseLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.AssessmentLowercase
    );

    return (
      <FormElement>
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Id</label>
              <Field
                readOnly
                name={nameOf("NoticeRunId")}
                placeholder={"Id"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Name</label>
              <Field
                readOnly
                name={nameOf("NoticeName")}
                placeholder={"Name"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Type</label>
              <Field
                readOnly
                name={nameOf("NoticeTypeName")}
                placeholder={"Type"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Rating period</label>
              <Field
                readOnly
                name={nameOf("RatingPeriod")}
                placeholder={"Rating period"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Notice order</label>
              <Field
                readOnly
                name={nameOf("NoticeOrder")}
                placeholder={"Notice order"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Issue date</label>
              <Field
                disabled
                name={nameOf("IssueDate")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Created on</label>
              <Field
                disabled
                name={nameOf("CreatedOn")}
                component={CCDateTimePicker}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Created by</label>
              <Field
                readOnly
                name={nameOf("CreatedBy")}
                placeholder={"Created by"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Notices produced on</label>
              <Field
                disabled
                name={nameOf("ProducedOn")}
                component={CCDateTimePicker}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Notices produced by</label>
              <Field
                readOnly
                name={nameOf("ProduceBy")}
                placeholder={"Notices produced by"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Document template</label>
              <Field
                readOnly
                name={nameOf("DocumentTemplate")}
                placeholder={"Document template"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-field">
            <label className="cc-label">Totals</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Number of notices</label>
                  <Field
                    readOnly
                    name={nameOf("NumberOfNotices")}
                    placeholder={"Number of notice"}
                    component={CCInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Duplicate notices</label>
                  <Field
                    readOnly
                    name={nameOf("NumberOfDuplicateNotices")}
                    placeholder={"Duplicate notice"}
                    component={CCInput}
                    value={0}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">{`Number of ${assessmentLowercaseLabel}s`}</label>
                  <Field
                    readOnly
                    name={nameOf("NumberOfAssessments")}
                    placeholder={`Number of ${assessmentLowercaseLabel}s`}
                    component={CCInput}
                  />
                </div>
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <CCGrid
                    data={getValue("NoticeRunTotal") ?? []}
                    primaryField={nameOfNotices("ID")}
                    columnFields={colNotices}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="cc-field">
            <label className="cc-label">Selection criteria</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCValueField
                    value={getValue(nameOf("MinimumNoticeAmount"))}
                    label="Minium notice amount"
                    format={CURRENCY_FORMAT.CURRENCY1}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">
                    Exclude if notice produced since
                  </label>
                  <Field
                    disabled
                    name={nameOf("ExcludeIfNoticeSince")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">
                    Exclude {assessmentLowercaseLabel}s with transfers after
                  </label>
                  <Field
                    disabled
                    name={nameOf("ExcludeTransfersAfter")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Include balances</label>
                  <Field
                    disabled
                    name={nameOf("IncludeBalances")}
                    component={CCSwitch}
                    checked={getValue(nameOf("IncludeBalances"))}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Include un-grouped notices</label>
                  <Field
                    disabled
                    name={nameOf("IncludeUngroupedNotices")}
                    component={CCSwitch}
                    checked={getValue(nameOf("IncludeUngroupedNotices"))}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">
                    Include if not in charge run
                  </label>
                  <Field
                    disabled
                    name={nameOf("IncludeIfNotInChargeRun")}
                    component={CCSwitch}
                    checked={getValue(nameOf("IncludeIfNotInChargeRun"))}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">
                    Exclude if within discount period
                  </label>
                  <Field
                    disabled
                    name={nameOf("ExcludeIfDiscountPeriod")}
                    component={CCSwitch}
                    checked={getValue(nameOf("ExcludeIfDiscountPeriod"))}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">
                    Exclude {assessmentLowercaseLabel}s with arrangements
                  </label>
                  <Field
                    disabled
                    name={nameOf("ExcludeArrangements")}
                    component={CCSwitch}
                    checked={getValue(nameOf("ExcludeArrangements"))}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">
                    Exclude {assessmentLowercaseLabel}s with rebates
                  </label>
                  <Field
                    disabled
                    name={nameOf("ExcludeAssessmentsWithRebates")}
                    component={CCSwitch}
                    checked={getValue(nameOf("ExcludeAssessmentsWithRebates"))}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">
                    Exclude {assessmentLowercaseLabel}s without rebates
                  </label>
                  <Field
                    disabled
                    name={nameOf("ExcludeAssessmentsWithoutRebates")}
                    component={CCSwitch}
                    checked={getValue(
                      nameOf("ExcludeAssessmentsWithoutRebates")
                    )}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Exclude instalments</label>
                  <Field
                    disabled
                    name={nameOf("ExcludeInstalments")}
                    component={CCSwitch}
                    checked={getValue(nameOf("ExcludeInstalments"))}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Exclude if interest exempt</label>
                  <Field
                    disabled
                    name={nameOf("ExcludeIfInterestExempt")}
                    component={CCSwitch}
                    checked={getValue(nameOf("ExcludeIfInterestExempt"))}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Notice groups</label>
                  <Field
                    readOnly
                    name={nameOf("NoticeGroupsData")}
                    placeholder={"Notice groups"}
                    component={CCTextArea}
                    rows={4}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Financial area</label>
                  <Field
                    readOnly
                    name={nameOf("FinancialAreasData")}
                    placeholder={"Financial area"}
                    component={CCTextArea}
                    rows={4}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Journals</label>
                  <Field
                    readOnly
                    name={nameOf("JournalsData")}
                    placeholder={"Journals"}
                    component={CCTextArea}
                    rows={4}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Supplementaries</label>
                  <Field
                    readOnly
                    name={nameOf("SupplementariesData")}
                    placeholder={"Supplementaries"}
                    component={CCTextArea}
                    rows={4}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Collections</label>
                  <Field
                    readOnly
                    name={nameOf("CollectionsData")}
                    placeholder={"Collections"}
                    component={CCTextArea}
                    rows={4}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Instalments</label>
                  <Field
                    readOnly
                    name={nameOf("InstalmentPlansData")}
                    placeholder={"Instalments"}
                    component={CCTextArea}
                    rows={4}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Output history</label>
              <CCGrid
                data={noticeRuns?.OutputHistory ?? []}
                primaryField={"ID"}
                columnFields={colOutputHistory}
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
