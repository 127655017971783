import { DATETIME_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Field } from "@progress/kendo-react-form";
import React from "react";

export const DateTimePickerSettings = ({
  data,
  isEditing,
  isFieldVisible = true,
  formRenderProps,
  colSpan,
}: any) =>
  data &&
  isFieldVisible && (
    <div className="cc-field">
      <label className="cc-label">
        {data?.Title ?? ""}
        {data?.IsMandatory && <CCTooltip type="validator" position="right" />}
      </label>
      <Field
        name={data?.FieldName}
        component={CCDateTimePicker}
        format={DATETIME_FORMAT.DATETIME_CONTROL}
        disabled={!isEditing}
        value={
          formRenderProps.valueGetter(data?.FieldName) &&
          new Date(formRenderProps.valueGetter(data?.FieldName))
        }
        onChange={(event: DatePickerChangeEvent) => {
          formRenderProps.onChange(data?.FieldName, {
            value: event.target.value,
          });
        }}
        validator={data?.IsMandatory ? requiredValidator : undefined}
      />
    </div>
  );
