import { postSendCommunication } from "@app/core/communication/buttons-list-view/components/buttons/api";
import { CommunicationDialog } from "@app/core/communication/dialogs/_index";
import { recipientTypesCommunicationDialog } from "@app/core/communication/dialogs/config";
import { ICommunication } from "@app/core/communication/dialogs/model";
import { DOCUMENTSTATUS } from "@app/core/documents/model";
import {
  putSendResponseToRAAndApplicant,
  putValidationSendResponseToRAAndApplicant,
} from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-response-to-ra-and-applicant/api";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const SendResponseToRAAndApplicantButton = observer(() => {
  const [showCommunicationDialog, setShowCommunicationDialog] =
    useState<boolean>(false);
  const { clearErrorNotification, pushNotification } =
    useCCAppNotificationStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { ppr, pprId, setPPRWithLoading } = usePPRStore();
  const isVisible = useMemo(() => {
    return ppr?.Status_ENUM === Application_Status.ApprovedByTeamLeader;
  }, [ppr]);

  const handleValidateAndOpenDialog = async () => {
    setIsLoading(true);
    const response = await putValidationSendResponseToRAAndApplicant(pprId);
    if (isSuccessIdentityPacket(response)) {
      setShowCommunicationDialog(true);
    } else {
      clearErrorNotification();
      pushNotification({
        autoClose: false,
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    setIsLoading(false);
  };
  const handleCommunicationSubmit = async (data: ICommunication) => {
    clearErrorNotification();
    const response = await postSendCommunication(
      data,
      RECORDTYPE.TP_PPRApplication // Add recordType based on default recordType of CommunicationDialog
    );
    if (isSuccessIdentityPacket(response)) {
      const resp = await putSendResponseToRAAndApplicant(pprId);
      if (isSuccessIdentityPacket(resp)) {
        setPPRWithLoading(resp.data.Application);
        pushNotification({
          title: "Response to RA and applicant sent successful",
          type: "success",
        });
      } else {
        clearErrorNotification();
        pushNotification({
          autoClose: false,
          title: "Send response to RA and applicant failed",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    } else {
      clearErrorNotification();
      pushNotification({
        autoClose: false,
        title: "Send communication failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    setShowCommunicationDialog(false);
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Send to responsible authority and applicant"
        isLoading={isLoading}
        onClick={() => {
          handleValidateAndOpenDialog();
        }}
      />
      {showCommunicationDialog && (
        <CommunicationDialog
          recipientTypes={recipientTypesCommunicationDialog()}
          onClose={() => setShowCommunicationDialog(false)}
          onSubmit={handleCommunicationSubmit}
          productType={PRODUCT_TYPE_NUMBER.TownPlanning}
          recordID={pprId}
          documentStatus={DOCUMENTSTATUS.Final}
        />
      )}
    </>
  ) : null;
});
