import { eventEmitter } from "@/App";
import { postUpdateConditionSortIndex } from "@app/core/condition/[id]/api";
import { DECIMAL_FORMAT } from "@common/constants/common-format";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { CCGridEventType } from "@components/cc-grid/constant";
import { ICustomEditCell } from "@components/cc-grid/model";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import {
  NumericTextBoxBlurEvent,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { isNull } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";

export const ConditionSortIndexCell = observer(
  ({
    value,
    onChange,
    dataIndex,
    dataItem,
    field,
    format = DECIMAL_FORMAT.DECIMAL_2_DIGIT,
  }: ICustomEditCell) => {
    const { cancelToken } = useCancelRequest();
    const { pushNotificationPortal } = useNotificationPortalStore();

    const [indexValue, setIndexValue] = useState(value);
    const [isPressEnter, setIsPressEnter] = useState(false);
    const valueRef = useRef<number>(
      (() => {
        if (field) {
          return Object.assign({}, dataItem)[field] ?? 0;
        }
        return 0;
      })()
    );

    const keydownEnterHandler = (e: any) => {
      if (e.key === "Enter") {
        setIsPressEnter(true);
      }
    };

    useEffect(() => {
      document
        .getElementById("cc-sort-index")
        ?.addEventListener("keydown", keydownEnterHandler);
      return () => {
        document
          .getElementById("cc-sort-index")
          ?.removeEventListener("keydown", keydownEnterHandler);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateSortIndexSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            let newSortIndex = indexValue;
            if (initialData?.indexValue) {
              newSortIndex = initialData?.indexValue;
            }
            return postUpdateConditionSortIndex(
              dataItem.ID,
              newSortIndex,
              cancelToken()
            );
          },
          handleSuccess: () => {
            eventEmitter.emit(CCGridEventType.RefreshOData);
            valueRef.current = indexValue;
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: errorFromApi.error,
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    useEffect(() => {
      if (field && isPressEnter) {
        updateSortIndexSlider.fetchApi();
        setIsPressEnter(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPressEnter]);

    return (
      <CCNumericTextBox
        id="cc-sort-index"
        value={value}
        format={format}
        onChange={(event: NumericTextBoxChangeEvent) => {
          if (onChange) {
            let newValue = event.value ?? 0;
            setIndexValue(newValue);
            onChange({
              field: field,
              dataIndex: dataIndex || 0,
              dataItem,
              syntheticEvent: event.syntheticEvent,
              value: newValue,
            });
          }
        }}
        onBlur={(event: NumericTextBoxBlurEvent) => {
          if (
            field &&
            !isNull(event.target.value) &&
            valueRef.current.toString() !== event.target.value.toString()
          )
            updateSortIndexSlider.fetchApi({
              initialData: { indexValue: event.target.value },
            });
        }}
      />
    );
  }
);
