import { ILookupItem } from "@app/products/property/system-admin/settings/model";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field } from "@progress/kendo-react-form";
import { RadioButtonProps, RadioGroup } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(
  ({ dataSetting, formRenderProps }: IGlobalComponentFormProps) => {
    const { isEditing } = useSettingGlobalManageStore();
    const { valueGetter } = formRenderProps;
    const data = dataSetting.SettingFields;

    const changeTypeToRadioGroup = (lookupItems: ILookupItem[]) => {
      return lookupItems.map((item: ILookupItem) => {
        return {
          label: item.Name,
          value: item.Id,
          disabled: item.Name === "Unique Number",
        } as RadioButtonProps;
      });
    };

    return (
      <>
        <div className="cc-form-cols-2">
          {/* ==== Infringement Unit Amount ==== */}
          <div className="cc-field">
            <label className="cc-label">{data[0]?.Title}</label>
            <Field
              name={data[0]?.FieldName}
              component={CCCurrencyInput}
              disabled={!isEditing}
            />
          </div>
          {/* ==== / Infringement Unit Amount ==== */}

          {/* ====  Default Complied/Revoked Status Label  ==== */}
          <div className="cc-field">
            <Field
              name={data[2]?.FieldName}
              component={InputGlobalSettings}
              placeholder={data[2]?.Title}
              data={data[2]}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          </div>
          {/* ====  / Default Complied/Revoked Status Label  ==== */}
        </div>

        <div className="cc-form-cols-1">
          {/* ====  Calculate Infringment Unit Amount times Penalty Units  ==== */}
          <div className="cc-field">
            <label className="cc-label">{data[1]?.Title}</label>
            <Field
              name={data[1]?.FieldName}
              component={CCSwitch}
              disabled={!isEditing}
              checked={valueGetter(data[1]?.FieldName)}
            />
          </div>
          {/* ====  / Calculate Infringment Unit Amount times Penalty Units  ==== */}

          {/* ====  Enable Notice list of Statuses  ==== */}
          <div className="cc-field">
            <Field
              name={data[3]?.FieldName}
              component={SwitchGlobalSettings}
              checked={valueGetter(data[3]?.FieldName)}
              data={data[3]}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          </div>
          {/* ====  / Enable Notice list of Statuses  ==== */}

          {/* ====  Enable Infringement Amount to nearest Dollar  ==== */}
          <div className="cc-field">
            <Field
              name={data[4]?.FieldName}
              component={SwitchGlobalSettings}
              checked={valueGetter(data[4]?.FieldName)}
              data={data[4]}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          </div>
          {/* ====  / Enable Infringement Amount to nearest Dollar  ==== */}

          {/* ====  Numbering  ==== */}
          <div className="cc-field">
            <label className="cc-label">
              Numbering
              <CCTooltip
                type="custom"
                content=" "
                position="auto"
                clickToOpen
                customTemplate={<>{sanitizeHtml(data[5].HelpText)}</>}
              >
                <i className="fa fa-info-circle ml-1 text-accent" />
              </CCTooltip>
            </label>
            <Field
              name={data[5]?.FieldName}
              component={RadioGroup}
              data={changeTypeToRadioGroup(data[5].LookupItems)}
              layout="horizontal"
              disabled={!isEditing}
            />
          </div>
          {/* ====  / Numbering  ==== */}
        </div>
      </>
    );
  }
);
