import { ILodgeApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/lodge-application/model";
import { LodgeApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/lodge-application/_index";
import { putEnquiryChangeCompletedDate } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/tools/change-complete-date/api";
import { RegisterStatus } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const PPREnquiryChangedCompleteDateButton = observer(() => {
  const { pprEnquiryId, setPPREnquiryWithLoading, pprEnquiry } =
    usePPREnquiryStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { clearErrorNotification, pushNotification } =
    useCCAppNotificationStore();

  const handleOnSubmit = async (data: ILodgeApplicationDialog) => {
    if (!pprEnquiryId) return;
    setIsLoading(true);
    const response = await putEnquiryChangeCompletedDate(
      pprEnquiryId,
      data?.DateLodged ?? null
    );
    setIsLoading(false);
    setIsShowDialog(false);
    if (isSuccessIdentityPacket(response)) {
      clearErrorNotification();
      setPPREnquiryWithLoading(response?.data?.Register);
      pushNotification({
        type: "success",
        title: "Change complete date successfully",
      });
    } else {
      pushNotification({
        autoClose: false,
        title: "Change complete date failed",
        type: "error",
        description: response.data?.Errors,
      });
    }
  };

  const isVisible = useMemo(() => {
    return (
      pprEnquiry?.Status_ENUM !== RegisterStatus.New &&
      pprEnquiry?.Status_ENUM !== RegisterStatus.NA
    );
  }, [pprEnquiry]);

  return isVisible ? (
    <>
      <CCNavButton
        title="Change complete date"
        isLoading={isLoading}
        onClick={() => {
          setIsShowDialog(true);
        }}
      />
      {isShowDialog && (
        <LodgeApplicationDialog
          isLoading={isLoading}
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleOnSubmit}
          initDate={pprEnquiry?.Date_Complete}
          isCompletedDate
        />
      )}
    </>
  ) : null;
});
