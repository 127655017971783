import { HMInspectionRoute } from "@app/products/hm/model";
import { getProductTitle } from "@common/constants/product-control";
import { PRODUCT_TYPE } from "@common/constants/productType";
import Loading from "@components/loading/Loading";
import React from "react";
import { useHistory } from "react-router-dom";

interface IHealthManagerActiviryProps {
  inspectionThisMonth: number;
  inspectionDueThisWeek: number;
  inspectionFinished: number;
  newRegistrations: number;
}

const HealthManagerActivity = (
  healthManagerActivityData: IHealthManagerActiviryProps
) => {
  const history = useHistory();
  return (
    <div className="cc-activity-group">
      <div className="cc-activity-group-title">
        {getProductTitle(PRODUCT_TYPE.HealthManager)}
      </div>
      {healthManagerActivityData !== null ? (
        <div className="cc-activity-group-container">
          <div
            className="cc-activity-item"
            onClick={(e) => {
              history.push(HMInspectionRoute.HM_INSPECTIONS_BYDATE);
            }}
          >
            <div className="cc-activity-name">INSPECTIONS THIS MONTH</div>
            <div className="cc-activity-number">
              {healthManagerActivityData.inspectionThisMonth}
            </div>
          </div>
          <div
            className="cc-activity-item"
            onClick={(e) => {
              history.push(HMInspectionRoute.HM_INSPECTIONS_BYDATE);
            }}
          >
            <div className="cc-activity-name">INSPECTIONS DUE THIS WEEK</div>
            <div className="cc-activity-number">
              {healthManagerActivityData.inspectionDueThisWeek}
            </div>
          </div>
          <div
            className="cc-activity-item"
            onClick={(e) => {
              history.push(HMInspectionRoute.HM_INSPECTIONS_BYDATE);
            }}
          >
            <div className="cc-activity-name">NEW REGISTRATIONS</div>
            <div className="cc-activity-number">
              {healthManagerActivityData.newRegistrations}
            </div>
          </div>
          <div
            className="cc-activity-item"
            onClick={(e) => {
              history.push(HMInspectionRoute.HM_INSPECTIONS_BYDATE);
            }}
          >
            <div className="cc-activity-name">INSPECTIONS FINISHED</div>
            <div className="cc-activity-number">
              {healthManagerActivityData.inspectionFinished}
            </div>
          </div>
        </div>
      ) : (
        <Loading isLoading />
      )}
    </div>
  );
};

export default HealthManagerActivity;
