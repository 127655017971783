import {
  mockAssessment,
  mockAssociate,
} from "@app/products/property/meters/[id]/components/action-bar/dialogs/associate/mock";
import { SearchField } from "@app/products/property/meters/[id]/components/action-bar/dialogs/associate/model";
import { sleep } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";

export const getAssociate = async (id: number) => {
  await sleep(300);
  return mockAssociate;
};

export const getSearchAssessment = async (filter: FilterDescriptor) => {
  const assessmentList = mockAssessment.map((assessment: any) => {
    return {
      ...assessment,
      [SearchField.AssessmentNumber]:
        assessment.AssessmentNumber + " - " + assessment.PropertyAddress,
      [SearchField.AssessmentId]:
        assessment.AssessmentId + " - " + assessment.PropertyAddress,
    };
  });

  const data = assessmentList.slice();
  await sleep(1000);
  return filterBy(data, filter);
};
