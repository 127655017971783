import { CoreAPIService } from "@/common/apis/coreAPIService";
import {
  PPREnquiryIdentityPacket,
  Register,
} from "@app/products/town-planning/ppr/enquiries/_id/model";
import { APIResponse } from "@common/apis/model";

export const putNotForVR = async (
  id: number,
  register: Register
): Promise<APIResponse<PPREnquiryIdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<PPREnquiryIdentityPacket>(
      `/api/tp/ppr/internal/enquiry/${id}/notforvr`,
      register
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
