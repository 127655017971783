import { EAllEventsListFlag } from "@app/products/crms/all-events/model";
import { FormAction } from "@app/products/crms/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { isNil } from "lodash";

export const crmsAllEventsListViewURL = (
  productType: PRODUCT_TYPE_NUMBER,
  formAction: FormAction,
  flagString?: EAllEventsListFlag
) => {
  let params = "";
  if (!isNil(flagString)) {
    params = `,${flagString}=true`;
  }
  return `odata/crms/internal/events/GetAllEvent(productType=${productType},formAction=${formAction}${params})?$count=true&`;
};
