import { getDisplayTextWithDashes } from "@common/utils/common";

export const HomepageViewsBookmark = {
  getBookmarkListViewDisplayName() {
    return "Settings - Homepage";
  },
  getBookmarkListViewDetail() {
    return "Settings - Homepage - Homepage Views";
  },
};

export const HomepageViewsDetailsBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `${selectedRow.ID}` : "";
    return `Settings - Homepage - Homepage views - ${dynamicDisplayName}`;
  },
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([selectedRow?.ViewHeading]);
  },
};
