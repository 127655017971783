import { getViewConfigurations } from "@app/products/property/api";
import { AddConstraintsDialog } from "@app/products/property/components/dialogs/add-constraints-dialog/_index";
import { postSearchConstraintsLookup } from "@app/products/property/components/dialogs/add-constraints-dialog/api";
import { colSelectConstraint } from "@app/products/property/components/dialogs/add-constraints-dialog/config";
import {
  DTO_Constraint,
  VO_Constraint,
} from "@app/products/property/components/dialogs/add-constraints-dialog/model";
import { ViewConfiguration } from "@app/products/property/model";
import { EParcelLoadingType } from "@app/products/property/parcels/components/form-steps/create-parcel/model";
import { processCombineData } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const ConstraintsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfVOConstraintsGrid = nameOfFactory<VO_Constraint>();
const FormStepElement = observer(
  ({
    formRenderProps,
    localNotificationRef,
    nameOf,
    options = { isReadOnly: false },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const [isShowConfirmDeletion, setIsShowConfirmDeletion] =
      useState<boolean>(false);
    const [isShowSelectConstraintsDialog, setIsShowSelectConstraintsDialog] =
      useState<boolean>(false);

    //get initial data for grid
    const currentConstraints: VO_Constraint[] = getFieldValue("") ?? [];
    const listSelected = getFieldValue("ConstraintSelected") ?? [];

    const loadingMode = getFieldValue("_option.Loading");
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);
    const [constraintsColumns, setConstraintsColumns] =
      useState<IColumnFields[]>(colSelectConstraint);

    const handleDelete = () => {
      const selectedIDs = listSelected.map(
        (item: VO_Constraint) => item.Constraint_Id
      );
      const newConstraints = currentConstraints.filter(
        (record: VO_Constraint) => !selectedIDs.includes(record.Constraint_Id)
      );
      onChange(nameOf(""), { value: newConstraints });
      onChange(nameOf("ConstraintSelected"), { value: [] });
    };

    const handleAddConstraints = async (data: VO_Constraint[]) => {
      const listConstraintsId = data?.map(
        (item: VO_Constraint) => item.Constraint_Id
      );
      onChange(nameOf("_option.Loading"), {
        value: EParcelLoadingType.Data,
      });
      options?.setIsLoadingInStep(true);
      const responseConstraintsLookup = await postSearchConstraintsLookup(
        listConstraintsId ?? []
      );
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      if (
        isSuccessResponse(responseConstraintsLookup) &&
        responseConstraintsLookup?.data
      ) {
        const responseConstraint =
          responseConstraintsLookup?.data?.Constraints ?? [];
        const newData = responseConstraint?.map((item: DTO_Constraint) => item);
        const previousConstraint = currentConstraints?.length
          ? [...currentConstraints]
          : [];
        const constraintData = processCombineData(
          previousConstraint,
          newData,
          "Constraint_Id"
        );
        onChange(nameOf(""), {
          value: constraintData,
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load constraint failed`,
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsLoadingInStep(false);
    };

    const loadViewConfiguration = async () => {
      onChange(nameOf("_option.Loading"), {
        value: EParcelLoadingType.Grid,
      });
      options?.setIsLoadingInStep?.(true);
      const response = await getViewConfigurations(
        ViewConfiguration.ConstraintLookup
      );
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      options?.setIsLoadingInStep?.(false);
      let errorResponse = undefined;
      if (isSuccessResponse(response) && response?.data) {
        setConstraintsColumns(
          processDynamicColumns(
            colSelectConstraint,
            response?.data?.ColumnDefinitions?.Columns
          )
        );
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Load view configuration failed",
        };
      }
      setResponseLoadError(errorResponse);
    };

    useEffectOnce(() => {
      (async () => {
        await loadViewConfiguration();
      })();
    });

    if (loadingMode === EParcelLoadingType.Grid) return <Loading isLoading />;
    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadViewConfiguration();
          }}
        />
      );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              isLoading={loadingMode === EParcelLoadingType.Data}
              data={currentConstraints}
              columnFields={constraintsColumns}
              primaryField={nameOfVOConstraintsGrid("Constraint_Id")}
              readOnly={options?.isReadOnly}
              selectableMode="multiple"
              selectedRows={listSelected}
              onSelectionChange={(dataItem: VO_Constraint[]) => {
                dataItem &&
                  onChange(nameOf("ConstraintSelected"), { value: dataItem });
              }}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-plus"
                    title="Select Constraints"
                    onClick={() => setIsShowSelectConstraintsDialog(true)}
                  />
                  <Button
                    className="cc-edit-field-button"
                    iconClass="fa fa-minus"
                    title="Delete"
                    disabled={!listSelected?.length}
                    onClick={() => {
                      setIsShowConfirmDeletion(true);
                    }}
                  />
                </div>
              }
            />
          </div>
          {isShowSelectConstraintsDialog && (
            <AddConstraintsDialog
              onClose={() => setIsShowSelectConstraintsDialog(false)}
              handleSelectConstraints={(data: VO_Constraint[]) => {
                handleAddConstraints(data);
                setIsShowSelectConstraintsDialog(false);
              }}
            />
          )}
          {isShowConfirmDeletion && (
            <ConfirmDialog
              title={"Confirm Deletion"}
              subMessage={
                "Are you sure you want to delete the selected item(s)?"
              }
              onClosePopup={() => {
                setIsShowConfirmDeletion(false);
              }}
              onConfirmYes={handleDelete}
            />
          )}
        </div>
      </section>
    );
  }
);
