import { ContactClassification } from "@app/core/contacts/_id/model";
import { ContactManagerDialog } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/dialog/contact-manager/_index";
import { ICouncilContact } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/dialog/contact-manager/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  getAllContacts,
  postHomePageRemoveUser,
} from "@common/pages/settings/homepage/homepage-shortcuts/api";
import {
  HomepageType,
  Svc_HomepagePickUserRequest,
} from "@common/pages/settings/homepage/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isArray } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface IRemoveUsersButtonProps {
  homepageType: HomepageType;
}

export const RemoveUsersButton = observer(
  ({ homepageType }: IRemoveUsersButtonProps) => {
    const { gridSelectedIds } = useCCProductListViewStore();
    const [isShowContactManagerDialog, setIsShowContactManagerDialog] =
      useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [unselectItems, setUnselectItems] = useState<number[]>([]);

    const handleRemoveUser = async (data: ICouncilContact[]) => {
      const userIds = isArray(data)
        ? data.map((user: ICouncilContact) => user.Id)
        : [];
      let homepagePickUserRequest: Svc_HomepagePickUserRequest = {
        HomepageIDs: gridSelectedIds,
        SiteUserIDs: userIds,
        HomepageType: homepageType,
      };
      setIsLoading(true);
      // If select all, get all users
      if (isSelectAll) {
        const responseAllUser = await getAllContacts(
          ContactClassification.SiteUser
        );
        if (isSuccessResponse(responseAllUser)) {
          homepagePickUserRequest = {
            ...homepagePickUserRequest,
            SiteUserIDs: responseAllUser.data?.value
              .map((user: ICouncilContact) => user.Id)
              .filter((userId: number) => !unselectItems.includes(userId)),
          };
        } else {
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Get all users failed.",
            type: "error",
            description: responseAllUser.error,
          });
        }

        setIsSelectAll(false);
        setUnselectItems([]);
      }

      const response = await postHomePageRemoveUser(homepagePickUserRequest);
      setIsLoading(false);
      setIsShowContactManagerDialog(false);

      if (isSuccessResponse(response)) {
        appNotificationStore.pushNotification({
          type: "success",
          title: "Remove user successfully.",
        });
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Remove user failed.",
          type: "error",
          description: response.data?.Errors ?? response.error,
        });
      }
    };
    return (
      <>
        <CCNavButton
          title="Remove user"
          onClick={() => {
            setIsShowContactManagerDialog(true);
          }}
          disabled={gridSelectedIds.length <= 0}
        />
        {isShowContactManagerDialog && (
          <ContactManagerDialog
            onClose={() => {
              setIsShowContactManagerDialog(false);
              setIsSelectAll(false);
            }}
            onSubmit={handleRemoveUser}
            classification={ContactClassification.SiteUser}
            title="Contact Manager"
            isSubmitLoading={isLoading}
            isMultiple
            setIsSelectAll={setIsSelectAll}
            setUnselectItems={setUnselectItems}
          />
        )}
      </>
    );
  }
);
