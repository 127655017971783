import {
  defaultSize,
  maxWidthForLaptopAndDesktopDevice,
  maxWidthForTabletAndMobileDevice,
} from "@components/cc-dialog/config";
import { isNumber, isString } from "lodash";

const changeTypeWindowSize = (windowSize: string | number) => {
  return isNumber(windowSize) ? `${windowSize}px` : windowSize;
};

const getPercent = (windowSize: string) => {
  return /(\d+)(%$)/.exec(windowSize);
};

const getNumber = (windowSize: string | number) => {
  return /(\d+)(.+$)/.exec(windowSize + "")?.[0] ?? 0;
};

const getSizeInfo = (
  defaultSize: string,
  optionSize: number | string | null,
  currentSize: number | string,
  fixedSize?: number | string | null
) => {
  if (optionSize && !fixedSize) {
    let smallScreen = maxWidthForTabletAndMobileDevice;
    let largeScreen = maxWidthForLaptopAndDesktopDevice;
    let _sizeResult = changeTypeWindowSize(optionSize);
    const percentageInfo = getPercent(_sizeResult);
    if (percentageInfo) {
      if (isNumber(currentSize)) {
        if (currentSize > largeScreen) {
          _sizeResult = (+percentageInfo[1] * largeScreen) / 100 + "px";
        }
        if (currentSize <= smallScreen) {
          _sizeResult = "100%";
        }
      }
      if (isString(currentSize)) {
        if (parseInt(currentSize) > largeScreen) {
          _sizeResult = (+percentageInfo[1] * largeScreen) / 100 + "px";
        }
        if (parseInt(currentSize) <= smallScreen) {
          _sizeResult = "100%";
        }
      }
    }
    if (+getNumber(currentSize) <= smallScreen) {
      _sizeResult = defaultSize;
    }
    return _sizeResult;
  }
};

export const setMinWidth = (
  width: number | string,
  minWidth: number | string | undefined,
  queryDialogRef: any,
  fixedWidth: number | string | undefined
) => {
  if (minWidth && !fixedWidth) {
    const currentDialog = queryDialogRef.element.querySelector(".k-dialog");
    const _minWidth = getSizeInfo(
      defaultSize.width,
      minWidth,
      width,
      fixedWidth
    );
    currentDialog.style.minWidth = _minWidth;
  }
};

export const setMaxWidth = (
  width: number | string,
  maxWidth: number | string | undefined,
  queryDialogRef: any,
  fixedWidth: number | string | undefined
) => {
  if (maxWidth && !fixedWidth) {
    const currentDialog = queryDialogRef.element.querySelector(".k-dialog");
    const _maxWidth = getSizeInfo(
      defaultSize.maxWidth,
      maxWidth,
      width,
      fixedWidth
    );
    currentDialog.style.maxWidth = _maxWidth;
  }
};

export const setMaxHeight = (
  maxHeight: number | string | undefined,
  queryDialogRef: any
) => {
  if (maxHeight) {
    const currentDialog = queryDialogRef.element.querySelector(".k-dialog");
    currentDialog.style.maxHeight = maxHeight;
  }
};
