import { ConfirmIncompleteReadingDialog } from "@app/products/property/fire-prevention/current/record-work-complete/components/dialogs/incomplete-work-records/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useCallback, useMemo, useState } from "react";

export const IncompleteButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const { gridSelectedIds, gridSelectedRows, setGridData, gridData } =
    useCCProductListViewStore();

  const { pushNotification } = useCCAppNotificationStore();

  const isDisable = useMemo(() => {
    return (
      gridSelectedRows?.length < 1 ||
      !gridSelectedRows.some((data) => data.HasBeenCompleted)
    );
  }, [gridSelectedRows]);

  const handleSubmit = useCallback(() => {
    const newData = gridData.map((item) => {
      if (
        gridSelectedIds.some((id) => item.WorkOrderId === id) &&
        item.HasBeenCompleted
      )
        item.HasBeenCompleted = false;
      return { ...item };
    });
    setGridData(newData);

    pushNotification({
      title: `The selected ${pluralize(
        "record",
        gridSelectedIds.length
      )} were marked as incomplete successfully`,
      type: "success",
    });
    setIsShowDialog(false);
    // eslint-disable-next-line
  }, [gridSelectedIds, gridData]);

  return (
    <>
      <CCNavButton
        title="Incomplete"
        onClick={() => setIsShowDialog(true)}
        disabled={isDisable}
      />

      {isShowDialog && (
        <ConfirmIncompleteReadingDialog
          subMessage={`Do you wish to mark the selected ${pluralize(
            "record",
            gridSelectedRows.length
          )} as Incomplete?`}
          onClosePopup={() => {
            setIsShowDialog(false);
          }}
          onConfirmYes={handleSubmit}
        />
      )}
    </>
  );
});
