import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colParkingPermitsSystemAdminPermitType: IColumnFields[] = [
  {
    field: ParkingPermitsFieldMapping.PermitType_Name,
    title: "Permit Type Name",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${PARKING_PERMITS_ROUTE}/${dataItem.ID}`;
    },
    width: 900,
  },
  {
    field: ParkingPermitsFieldMapping.Sys_DBRowState,
    title: "Status",
    width: 900,
  },
];
