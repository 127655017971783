import { fnt_Assessment_LookupResult } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { DECIMAL_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

/**
 *  1 - Address
 *  2 - Name
 *  3 - Legal Description
 *  4 - Assessment Id
 *  5 - Assessment Number
 *  6 - Assessment (S-S-B-U)
 *  7 - Parcel Id
 *  8 - Title Id
 */
export enum eOptionSearchAssessmentLookup {
  Address = 1,
  Name = 2,
  LegalDescription = 3,
  AssessmentId = 4,
  AssessmentNumber = 5,
  AssessmentReference = 6,
  ParcelId = 7, //only use in background processes, not show in Title Lookup Search By dropdown
  TitleId = 8,
  AssessmentName = 10,
}

export const assessmentSearchByList: DTO_LOV_Number[] = [
  {
    Code: eOptionSearchAssessmentLookup.Address,
    Name: "Address",
  },
  {
    Code: eOptionSearchAssessmentLookup.Name,
    Name: "Name",
  },
  {
    Code: eOptionSearchAssessmentLookup.LegalDescription,
    Name: "Legal Description",
  },
  {
    Code: eOptionSearchAssessmentLookup.AssessmentId,
    Name: "Assessment ID",
  },
  {
    Code: eOptionSearchAssessmentLookup.AssessmentNumber,
    Name: "Assessment Number",
  },
  {
    Code: eOptionSearchAssessmentLookup.AssessmentReference,
    Name: "Assessment (S-S-B-U)",
  },
  {
    Code: eOptionSearchAssessmentLookup.AssessmentName,
    Name: "Holding Name",
  },
];

const nameOf = nameOfFactory<fnt_Assessment_LookupResult>();
export const listConfigNumberTypeAssessment = [4, 5, 7, 8];
export const colAssessmentLookup: IColumnFields[] = [
  {
    field: nameOf("Assess_Number"),
    title: "Assessment Number",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Ratepayer_Name"),
    title: "Ratepayer",
  },
  {
    field: nameOf("Assessment_Status_Name"),
    title: "Status",
  },
  {
    field: nameOf("Assess_Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Assess_Reference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("Assess_Legal_Description"),
    title: "Legal Description",
  },
  {
    field: nameOf("Assessment_Type_Name"),
    title: "Type",
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
