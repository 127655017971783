import { CRMS_ALL_EVENTS_BY_REFNO_URL } from "@common/pages/home/components/crms/constant";
import { CRMSEventClosedDataChartDataItem } from "@common/pages/home/components/crms/model";
import { useCrmsDashboardStore } from "@common/pages/home/components/crms/store";
import { removeItemFromGridStoreURLStored } from "@components/cc-product-list-view/components/hooks/util";
import Loading from "@components/loading/Loading";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  SeriesClickEvent,
} from "@progress/kendo-react-charts";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

export const CRMSEventClosedChart = observer(() => {
  const history = useHistory();
  const {
    isLoadingCrmsEventDashboard,
    crmsDashboardEventClosed,
    crmsEventDashboard,
  } = useCrmsDashboardStore();

  const dataList = useMemo((): CRMSEventClosedDataChartDataItem[] => {
    const dataList = crmsDashboardEventClosed?.data ?? [];

    return dataList?.map((item: CRMSEventClosedDataChartDataItem) => {
      switch (item?.name) {
        case "Closed - Out Time":
          return { ...item, color: "var(--series-f)" };
        case "Closed - In Time":
          return { ...item, color: "var(--series-c)" };
        default:
          return item;
      }
    });
  }, [crmsDashboardEventClosed?.data]);

  const EventClosedChartContainer = () => (
    <Chart
      style={{
        height: 300,
      }}
      onSeriesClick={(event: SeriesClickEvent) => {
        const name = event.series?.name;
        removeItemFromGridStoreURLStored(); //clear session store state to apply the new state of chart
        history.push(CRMS_ALL_EVENTS_BY_REFNO_URL, {
          closedInTimeChartState: {
            isFromDashboard: true,
            name: name,
            closeDate: new Date(event.category),
          },
        });
      }}
    >
      <ChartLegend position="top" orientation="horizontal" />
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          categories={crmsDashboardEventClosed?.groups ?? []}
          startAngle={45}
        />
      </ChartCategoryAxis>
      <ChartSeries>
        {dataList?.map((item, idx) => (
          <ChartSeriesItem
            spacing={0}
            key={idx}
            type="column"
            tooltip={{
              visible: true,
            }}
            data={item.data}
            name={item.name}
            color={item?.color ?? ""}
            border={{ color: item?.color ?? "" }}
          />
        ))}
      </ChartSeries>
    </Chart>
  );

  return (
    <>
      {isLoadingCrmsEventDashboard ? (
        <Loading isLoading={isLoadingCrmsEventDashboard} />
      ) : (
        <div className="cc-insight-group">
          <div className="cc-group-name">EVENTS</div>
          <h4>Closures</h4>
          <div className="cc-date-time-description">
            as of {crmsEventDashboard?.QueryDateTime ?? ""}
          </div>
          <div className="cc-insight-group-container">
            <EventClosedChartContainer />
          </div>
        </div>
      )}
    </>
  );
});
