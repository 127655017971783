import { EPrivilegesWorkflow } from "@app/products/property/model";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IDeleteChargeRunCommonProps {
  disabled?: boolean;
  handleDelete: () => Promise<void>;
  isLoading?: boolean;
  message?: string;
}

export const DeleteChargeRunCommonButton = observer(
  ({
    disabled,
    isLoading,
    message = `Are you sure you want to delete the Charge Run?`,
    handleDelete,
  }: IDeleteChargeRunCommonProps) => {
    const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
    return (
      <>
        <PrivilegeWrapper
          dialogType={ePermissionPrivilegeTypeCheck.Dialog}
          privilegesEnum={EPrivilegesWorkflow.Charge_Run_Delete}
        >
          <CCNavButton
            title="Delete"
            onClick={() => setIsShowConfirmDialog(true)}
            disabled={disabled}
          />
        </PrivilegeWrapper>
        {isShowConfirmDialog && (
          <ConfirmDialog
            title="Confirmation"
            message={message}
            maxWidth={"40%"}
            onClosePopup={() => setIsShowConfirmDialog(false)}
            isLoadingYes={isLoading}
            onAsyncConfirm={async () => {
              await handleDelete();
              setIsShowConfirmDialog(false);
            }}
            btnYesTitle="OK"
          />
        )}
      </>
    );
  }
);
