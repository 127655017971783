import { getBuildingById } from "@app/products/building/registers/council-consent/[id]/components/reference-sidebar/detail/api";
import { IBuildingRegistersCouncilConsentFormData } from "@app/products/building/registers/council-consent/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const CouncilConsentDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [councilConsentInfo, setCouncilConsentInfo] = useState<
    IBuildingRegistersCouncilConsentFormData | undefined
  >();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const councilConsentId: any = lastSelectedRow?.ID ?? params.id;
  useEffect(() => {
    setIsLoading(true);
    getBuildingById(councilConsentId).then((data) => {
      setCouncilConsentInfo(data);
      setIsLoading(false);
    });
  }, [councilConsentId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!councilConsentInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Register Number:"}
        value={councilConsentInfo?.RegisterNumber?.toString() || ""}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Status:"}
          value={councilConsentInfo.Status}
          success
        />
        <ProductReferenceRow
          title={"Stat Days:"}
          value={councilConsentInfo?.StatDays}
          danger
        />
        <ProductReferenceRow
          title={"Created Date:"}
          value={formatDisplayValue(
            councilConsentInfo?.CreatedDate,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Lodged Date:"}
          value={formatDisplayValue(
            councilConsentInfo?.LodgedDate,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Clock Start:"}
          value={formatDisplayValue(
            councilConsentInfo?.ClockStart,
            DATE_FORMAT.DATE
          )}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
