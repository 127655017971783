import { IOrgUnit } from "@app/products/town-planning/ppr/[id]/components/input-picker/pick-org-structure/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfOrgUnit = nameOfFactory<IOrgUnit>();
export const colOrgUnit: IColumnFields[] = [
  { title: "Hierarchy", field: nameOfOrgUnit("Hierarchy") },
  { title: "Org Level", field: nameOfOrgUnit("OrgLevel") },
  { title: "Org Unit Name", field: nameOfOrgUnit("OrgUnit_Name") },
  { title: "Manager", field: nameOfOrgUnit("Manager") },
];
