//#region ROUTE ========/
export const LOCAL_LAWS_MANAGE_ROUTE = "/local-laws";
export const LOCAL_LAWS_SYSTEM_ADMIN_PERMIT_TYPES_ROUTE = `${LOCAL_LAWS_MANAGE_ROUTE}/system-admin/permit-types`;
//#endregion ROUTE =====/

//#region API ========/
export const PERMIT_TYPE_LOVS_API = "api/locallaw/internal/permit-type/lovs";
export const PERMIT_TYPE_BY_ID_API = "api/locallaw/internal/permit-type/[id]";
//#endregion API =====/

export const TitlePermitTypeManagePage = "Permit Type";
