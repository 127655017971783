import {
  AnimalType,
  NonRegistrationFeeRule,
} from "@app/products/animals/model";
import { RegistrationFeeRulesType } from "@app/products/animals/system-admin/animal-types/[id]/model";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { FeeTypesPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/fee-types-picker/_index";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface INonRegistrationFeeRulesDialogProps {
  onClose: () => void;
  initialValues: NonRegistrationFeeRule;
  animalTypeFormObject: AnimalType;
  formRenderProps: FormRenderProps;
}

const nameOfAnimalType = nameOfFactory<AnimalType>();
const nameOfKeyValuePacket = nameOfFactory<KeyValuePacket>();
const nameOfNonRegistrationFeeRule = nameOfFactory<NonRegistrationFeeRule>();

export const NonRegistrationFeeRulesDialog = observer(
  ({
    onClose,
    initialValues,
    animalTypeFormObject,
    formRenderProps,
  }: INonRegistrationFeeRulesDialogProps) => {
    const {
      onChange: onChangeAnimalTypeForm,
      valueGetter: valueGetterAnimalTypeForm,
    } = formRenderProps;
    const {
      feeRuleDialogLOVs,
      setFeeRuleTypeEnum,
      responseLoadErrorDialog,
      setupRegistrationFeeRulesDialog,
      isLoadingDialog,
      feeRuleObj,
    } = useAnimalTypeStore();

    // Handle for submit dialog.
    const handleOnSubmit = (event: FormSubmitClickEvent) => {
      const feeRuleList = valueGetterAnimalTypeForm(
        nameOfAnimalType("FeeRulesList")
      );
      const newFeeRule = event.values as NonRegistrationFeeRule;

      const newNonRegistrationFeeRules =
        animalTypeFormObject.FeeRulesList?.NonRegistrationFeeRules.filter(
          (feeRule: NonRegistrationFeeRule) =>
            feeRule.NonRegistrationFeeRuleType_ENUM !==
            newFeeRule.NonRegistrationFeeRuleType_ENUM
        ) ?? [];

      newNonRegistrationFeeRules.push(newFeeRule as NonRegistrationFeeRule);

      onChangeAnimalTypeForm(nameOfAnimalType("FeeRulesList"), {
        value: {
          ...feeRuleList,
          NonRegistrationFeeRules: newNonRegistrationFeeRules,
        },
      });

      setFeeRuleTypeEnum(RegistrationFeeRulesType.Default);
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmitClick={handleOnSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, valid, onSubmit, onChange } = formRenderProps;

          const feeTypeValue = valueGetter(
            nameOfNonRegistrationFeeRule("FeeType_Name")
          );

          // Handle on field change
          const handleOnChangeRuleType = (event: ComboBoxChangeEvent) => {
            onChange(
              nameOfNonRegistrationFeeRule("NonRegistrationFeeRuleType_ENUM"),
              {
                value: event.value?.Key,
              }
            );
          };

          const handleOnChangeFeeType = (value: any) => {
            onChange(nameOfNonRegistrationFeeRule("FeeType_ID"), {
              value: value.ID,
            });
            onChange(nameOfNonRegistrationFeeRule("FeeType_Name"), {
              value: value.Name,
            });
          };

          return (
            <CCDialog
              maxWidth="25%"
              height={"auto"}
              titleHeader="Non Registration Fee Rule"
              onClose={onClose}
              isLoading={isLoadingDialog}
              bodyElement={
                <>
                  {responseLoadErrorDialog ? (
                    <CCLoadFailed
                      responseError={responseLoadErrorDialog}
                      onReload={() =>
                        setupRegistrationFeeRulesDialog(
                          RegistrationFeeRulesType.NonRegistrationFeeRules,
                          feeRuleObj,
                          true
                        )
                      }
                    />
                  ) : (
                    <FormElement className="cc-form">
                      <div className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <CCLabel title="Rule type" isMandatory />
                            <Field
                              name={nameOfNonRegistrationFeeRule(
                                "NonRegistrationFeeRuleType_ENUM"
                              )}
                              component={CCComboBox}
                              textField={nameOfKeyValuePacket("Value")}
                              dataItemKey={nameOfKeyValuePacket("Key")}
                              data={feeRuleDialogLOVs?.RuleType}
                              onChange={handleOnChangeRuleType}
                              value={getDropdownValue(
                                valueGetter(
                                  nameOfNonRegistrationFeeRule(
                                    "NonRegistrationFeeRuleType_ENUM"
                                  )
                                ),
                                feeRuleDialogLOVs?.RuleType ?? [],
                                nameOfKeyValuePacket("Key")
                              )}
                              validator={requiredValidator}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="Amount" isMandatory />
                            <Field
                              name={nameOfNonRegistrationFeeRule("Amount")}
                              component={CCCurrencyInput}
                              validator={requiredValidator}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="GST Applicable" isMandatory />
                            <Field
                              name={nameOfNonRegistrationFeeRule("AddGST")}
                              component={CCSwitch}
                              checked={valueGetter(
                                nameOfNonRegistrationFeeRule("AddGST")
                              )}
                              validator={requiredValidator}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="Fee type" isMandatory />
                            <Field
                              name={nameOfNonRegistrationFeeRule(
                                "FeeType_Name"
                              )}
                              component={FeeTypesPicker}
                              isGetDetail={false}
                              selectableMode="single"
                              validator={requiredValidator}
                              onPickFeeTypes={handleOnChangeFeeType}
                              value={feeTypeValue}
                              productType={PRODUCT_TYPE_NUMBER.Animals}
                            />
                          </div>
                        </div>
                      </div>
                    </FormElement>
                  )}
                </>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    themeColor="primary"
                    disabled={!valid}
                    onClick={onSubmit}
                    className="cc-dialog-button"
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
