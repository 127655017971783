import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colFIRequests: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.ApplicationNo,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.SiteAddress,
    title: "Site Address",
    width: 300,
  },
  {
    field: TownPlanningFieldMapping.Locality,
    title: "Locality",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.Category,
    title: "Proposal",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.Details,
    title: "Details",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.StatusOnly,
    title: "Status",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.DateRequested,
    title: "Requested",
    width: 250,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.DateDue,
    title: "Due Date",
    width: 250,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.DateInfoReceived,
    title: "Received",
    width: 250,
    format: DATE_FORMAT.DATE,
  },
];
