import { history } from "@/AppRoutes";
import { getRegisterType } from "@app/products/town-planning/ppr/[id]/components/child-screens/related/api";
import { IPPRRelated } from "@app/products/town-planning/ppr/[id]/components/child-screens/related/model";
import { getPageLinkForRelatedTab } from "@app/products/town-planning/ppr/[id]/components/child-screens/related/utils";
import { TOWN_PLANNING_PPR_ENQUIRIES_ROUTE } from "@app/products/town-planning/ppr/enquiries/_id/constant";
import { RegisterType } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IPPRRelated>();

export const colPPRRelated = (isEnablePPR?: boolean): IColumnFields[] => {
  return [
    {
      field: nameOf("Description"),
      title: "Description",
      locked: true,
      linkTo: (dataItem: IPPRRelated) => {
        if (dataItem.RecordType_ID === RECORDTYPE.CORE_Register) {
          return undefined;
        }

        return getPageLinkForRelatedTab(
          dataItem.RecordType_ID,
          dataItem.Record_ID,
          dataItem.Relationship_ID,
          isEnablePPR
        );
      },
      handleOnClick: async (dataItem: IPPRRelated) => {
        if (dataItem.RecordType_ID === RECORDTYPE.CORE_Register) {
          const registerType = await getRegisterType(dataItem.Record_ID);
          if (isSuccessResponse(registerType)) {
            if (registerType.data === RegisterType.PlanningEnquiry) {
              history.push(
                `${TOWN_PLANNING_PPR_ENQUIRIES_ROUTE}/${dataItem.Record_ID}`
              );
            }
          } else {
            appNotificationStore.pushNotification({
              type: "error",
              title: "Load register type failed",
              autoClose: false,
            });
          }
        }
      },
    },
    {
      field: nameOf("Type"),
      title: "Type",
    },
    {
      field: nameOf("Relationship"),
      title: "Relationship",
    },
  ];
};
