import { DTO_PIC_Transaction } from "@app/products/property/pic/[id]/components/child-screens/transactions/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import React from "react";

const nameOf = nameOfFactory<DTO_PIC_Transaction>();
export const colPicTransactions: IColumnFields[] = [
  {
    field: nameOf("TransactionDate"),
    title: "Date Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("TransactionType"),
    title: "Transaction Type",
  },
  {
    field: nameOf("TransactionAmount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell />,
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("Occupier"),
    title: "Occupier",
  },
  {
    field: nameOf("JournalNumber"),
    title: "Journal",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("SessionNumber"),
    title: "Session",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("UserId"),
    title: "User",
  },
  {
    field: nameOf("IsProposed"),
    title: "Is Proposed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("IsCancelled"),
    title: "Is Cancelled",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Source"),
    title: "Source",
  },
  {
    field: nameOf("Source_Id"),
    title: "Source ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("CreatedOn"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Created_By"),
    title: "Created By",
  },
  {
    field: nameOf("PICTransactionId"),
    title: "Transaction ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
