import { mockReasonForTheSpecialReading } from "@app/products/property/meters/components/dialogs/enter-special-reading/components/dialogs/enter-reading/mock";
import {
  IReadingGridData,
  ISpecialReadingDialogData,
} from "@app/products/property/meters/components/dialogs/enter-special-reading/model";

export const mockReadingGridData: IReadingGridData[] = [
  {
    MeterNumber: "17B062894",
    Utility: "Water",
    PreviousReadingDate: new Date("30-Jun-2020 0:00"),
    PreviousReading: 2569,
    UnitOfMeasure: "Kilolitre",
    PercentShare: 100,
    EstimatedUsage: 329,
    WarningUsageThreshold: 658,
    MeterInstallationId: 5567,
  },
];

export const mockSpecialReadingData: ISpecialReadingDialogData = {
  ReasonForTheSpecialReading: { Key: 6, Value: "Vacating Tenant" },
  JournalNumber: undefined,
  Fee: 0,
  TaxIncluded: 0,
  PropertyAddress: "15 Callanna Rd, Roxby Downs",
  AccountName: "Roxby Earthmoving",
  _option: {
    ReasonForTheSpecialReading: {
      Data: mockReasonForTheSpecialReading,
    },
  },
  Reading: mockReadingGridData,
};
