import { useCommonCoreStore } from "@common/stores/core/store";
import {
  Upload,
  UploadFileInfo,
  UploadFileRestrictions,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from "@progress/kendo-react-upload";
import React, { forwardRef, LegacyRef } from "react";
import "./_index.scss";

interface ICCUploadProps {
  validationMessage?: string | null;
  visited?: boolean;
}
interface ICCUploadFileProps {
  accept?: string;
  autoUpload?: boolean;
  batch?: boolean;
  multiple?: boolean;
  files?: UploadFileInfo[] | undefined;
  defaultFiles?: UploadFileInfo[] | undefined;
  withCredentials?: boolean;
  allowedExtensions?: string[] | undefined;
  restrictions?: UploadFileRestrictions | undefined;
  showActionButtons?: boolean;
  onAdd?: (event: UploadOnAddEvent) => void;
  onProgress?: (event: UploadOnProgressEvent) => void;
  onRemove?: (event: UploadOnRemoveEvent) => void;
  onStatusChange?: (event: UploadOnStatusChangeEvent) => void;
  saveUrl?: string;
  removeUrl?: string;
  className?: string;
  disabled?: boolean;
}
const CCUploadFile = forwardRef(
  (
    {
      accept,
      autoUpload,
      batch,
      multiple,
      files,
      defaultFiles = [],
      withCredentials = false,
      restrictions,
      showActionButtons,
      onAdd,
      onProgress,
      onRemove,
      onStatusChange,
      saveUrl,
      removeUrl,
      className,
      disabled,
      validationMessage,
      visited,
      ...others
    }: ICCUploadFileProps & ICCUploadProps,
    ref: LegacyRef<Upload> | undefined
  ) => {
    const { fileSize } = useCommonCoreStore();
    return (
      <Upload
        ref={ref}
        accept={accept}
        autoUpload={autoUpload}
        batch={batch}
        multiple={multiple}
        defaultFiles={!files ? defaultFiles : undefined}
        files={files ? files : undefined}
        withCredentials={withCredentials}
        restrictions={{
          ...restrictions,
          maxFileSize: restrictions?.maxFileSize
            ? restrictions?.maxFileSize
            : fileSize,
        }}
        disabled={disabled}
        onAdd={onAdd}
        onRemove={onRemove}
        onProgress={onProgress}
        onStatusChange={onStatusChange}
        showActionButtons={showActionButtons}
        saveUrl={saveUrl}
        removeUrl={removeUrl}
        className={`${
          visited && validationMessage ? "cc-file-error" : ""
        } ${className}`}
        {...others}
      />
    );
  }
);

export default CCUploadFile;
