import { IKeyValuePacket } from "@common/models/keyValuePacket";

export interface IManualMeterReading {
  MeterNumber: string;
  Id: number;
  PropertyAddress: string;
  AccountName: string;
  ReadingStatus: IKeyValuePacket;
  ReadingOfficer: IKeyValuePacket;
  PreviousReadingDate: Date | undefined;
  PreviousReading: number;
  UnitOfMeasure: string;
  CurrentReadingDate?: Date;
  CurrentReading?: number;
  Comment?: string;
  NumberOfDays?: number;
  Usage?: number;
}
export enum ManualMeterReadingBy {
  MeterNumber = "Meter Number",
  MeterId = "Meter Id",
}

export const MAX_READING = 10000;
