import { IInspectionTypeDocumentSelection } from "@common/pages/settings/lookups/inspection-types/_id/components/documents-selection/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IInspectionTypeDocumentSelection>();
export const colDocumentsSelection: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Documents",
  },
];
