import { mockAssessment } from "@app/products/property/meters/list/components/action-bar/dialogs/schedule-special-readings/mock";
import { sleep } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { SearchField } from "./model";

export const loadSearchAssessment = async (filter: FilterDescriptor) => {
  const assessmentList = mockAssessment.map((assessment: any) => {
    return {
      ...assessment,
      [SearchField.AssessmentNumber]:
        assessment.AssessmentNumber + " - " + assessment.PropertyAddress,
      [SearchField.AssessmentId]:
        assessment.AssessmentId + " - " + assessment.PropertyAddress,
    };
  });

  const data = assessmentList.slice();
  await sleep(1000);
  return filterBy(data, filter);
};
