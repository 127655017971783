import { eventEmitter } from "@/App";
import { postResetRenewalFeeFlag } from "@app/products/animals/renewals/requires-renewal-notice/components/api";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IAnimalResetRenewalFeeFlagButtonProps {
  isVisible?: boolean;
  isDisable?: boolean;
}

export const AnimalResetRenewalFeeFlagButton = observer(
  ({
    isVisible = true,
    isDisable = false,
  }: IAnimalResetRenewalFeeFlagButtonProps) => {
    const { isEmptyGridSelectedRow, gridSelectedIds, setGridSelectedRows } =
      useCCProductListViewStore();
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleOnClick = async () => {
      setIsSubmitting(true);
      const response = await postResetRenewalFeeFlag(gridSelectedIds);
      setIsSubmitting(false);
      if (isSuccessResponse(response)) {
        clearErrorNotification();
        pushNotification({
          type: "success",
          description: "Reset renewal fee flag successfully",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
        setGridSelectedRows([]);
      } else {
        pushNotification({
          type: "error",
          autoClose: false,
          description:
            response.data?.Errors ??
            response.error ??
            "Reset renewal fee flag failed",
        });
      }
    };

    // Set button is invisible
    if (!isVisible) return null;
    // Loading when calling API
    if (isSubmitting) return <Loading isLoading={isSubmitting} isFullScreen />;

    return (
      <CCNavButton
        title="Reset Renewal Fee Flag"
        disabled={isEmptyGridSelectedRow || isDisable}
        onClick={handleOnClick}
      />
    );
  }
);
