import { ContactLovs, Svc_Contact } from "@app/core/contacts/_id/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const getContact = async (
  contactId: number
): Promise<APIResponse<Svc_Contact | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Svc_Contact | undefined>(
      `/api/core/internal/contacts/${contactId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getContactLovs = async (
  isNew: boolean
): Promise<APIResponse<ContactLovs | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<ContactLovs | undefined>(
      `/api/core/internal/getcontactlovs`,
      {
        params: { isNew },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postContact = async (
  contact: Svc_Contact
): Promise<APIResponse<IdentityPacket>> => {
  try {
    if (contact.Contact_Id) {
      return await CoreAPIService.getClient().put<IdentityPacket>(
        `/api/core/internal/contacts/${contact.Contact_Id}`,
        contact
      );
    } else {
      return await CoreAPIService.getClient().post<IdentityPacket>(
        `/api/core/internal/contacts`,
        contact
      );
    }
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
