import { Address } from "@app/products/waste-water/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacket } from "@common/models/identityPacket";

export const postAdditionalAddress = async (
  id: number,
  address: Address,
  recordType: RECORDTYPE
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/core/internal/address/${id}/${recordType}`,
      address
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
