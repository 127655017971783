import { IBuildingDemolitionResponse } from "@app/products/building/registers/demolitions/[id]/model";

export const mockSingleBuildingDemolition: IBuildingDemolitionResponse = {
  ID: 20172,
  Status: "Pending",
  Contact: "Jophy wilson Open Office",
  ContactAddress: "BoundSoft1 4 Tanin Court",
  ContactContact: "+61 470 228 403",
  RegisterNumber: 92164,
  Address: "31 Caroline Avenue",
  Owners: "Wilson K M",
  Details: "L87 LP9628",
  Description: "Test By Jophy",
  Officer: "Jophy Wilson",
  AppNo: "",
  RefNumber: "154784",
  FileNumber: "154784",
  CreatedDate: new Date("10/20/2017"),
  Lodged: new Date("10/27/2017"),
  DecisionType: "Approved",
  DecisionDate: new Date("10/22/2017"),
  OSFees: 10,
  DebtorNumber: "1457844",
  PlannerAssessment: "11111",
};
