import { useReinstateButtonStore } from "@app/core/further-info/components/buttons/work-flow/reinstate/store";
import {
  FURTHERINFOSTATUS,
  FurtherInfoSubmitActions,
} from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const ReinstateButton = observer(() => {
  const { isLoading } = useReinstateButtonStore();
  const { furtherInfo, onSubmit } = useFurtherInfoStore();

  const isVisible = useMemo(
    () => furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.Cancelled,
    [furtherInfo]
  );

  return isVisible ? (
    <CCNavButton
      title={"Reinstate"}
      isLoading={isLoading}
      disabled={isLoading}
      onClick={onSubmit}
      name={FurtherInfoSubmitActions.Reinstate}
    />
  ) : null;
});
