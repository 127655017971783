import { NoticeRunDetail } from "@app/products/property/schemes/notice-runs/[id]/model";

export const mockNoticeRun: NoticeRunDetail = {
  NoticeRunId: 138,
  IssueDate: new Date("28-Nov-2018"),
  DueDate: new Date("12-Feb-2019"),
  PrintedExported: new Date("28-Nov-2018 13:42"),
  CreatedBy: "ClelandS",
  NoticeRunDate: new Date("28-Nov-2018 13:32"),
  NoticeRunName: "Paton Rd Nov 2017",
  NumberofAccounts: 1,
  InterestRaised: 0.0,
  AmountDue: 357.29,
  PrincipalBalance: 357.29,
  PrincipalDue: 7.29,
  InterestBalance: 0.0,
  BalanceOutstanding: 357.29,
  PayoutAmount: 357.29,
  NoticePrintedorExportedDate: new Date("28-Nov-2018 13:42"),
  Filename: "",
};
