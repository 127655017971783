import { CRMSViews } from "@app/products/crms/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";

export const serviceStandardsRoute: ICCRoute = {
  path: "service-standards",
  name: "Service Standards",
  enum: CORPORATE_SETTINGS_FIELD.CUSTOMERSERVICE_Views_ServiceStandard,
  children: [
    {
      path: "by-name",
      name: "By Name",
      enum: CRMSViews.ServiceStandard_ByName,
      component: require("./by-name/_index").default,
    },
    {
      path: "by-action-person",
      name: "By Action Person",
      enum: CRMSViews.ServiceStandard_ByActionOfficer,
      component: require("./by-action-person/_index").default,
    },
    {
      path: "by-category",
      name: "By Category",
      enum: CRMSViews.ServiceStandard_ByCategory,
      component: require("./by-category/_index").default,
    },
    {
      path: "by-coordinator-person",
      name: "By Coordinator Person",
      enum: CRMSViews.ServiceStandard_ByCoordinator,
      component: require("./by-coordinator-person/_index").default,
    },
    {
      path: "by-org-structure",
      name: "By Org Structure",
      enum: CRMSViews.ServiceStandard_ByOrgStructure,
      component: require("./by-org-structure/_index").default,
    },
    {
      path: "deactivated-svc-stds",
      name: "Deactivated Svc Stds",
      enum: CRMSViews.ServiceStandard_DeactivatedServiceStandards,
      component: require("./deactivated-svc-stds/_index").default,
    },
    {
      path: "deactivated-orgs",
      name: "Deactivated Orgs",
      enum: CRMSViews.ServiceStandard_DeactivatedOrgs,
      component: require("./deactivated-orgs/_index").default,
    },
    {
      path: "batch-update",
      component: require("./batch-update/_index").default,
      // The batch update is a special page that has no route enum.
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
