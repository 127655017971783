import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { Journal } from "@app/core/journal/_index";
import { SamplesAccordion } from "@app/core/samples/_index";
import { getBuildingEnforcementRecords } from "@app/products/building/registers/enforcements/[id]/api";
import { BuildingRegisterEnforcementForm } from "@app/products/building/registers/enforcements/[id]/components/child-screens/general/_index";
import { EnforcementContactsTab } from "@app/products/building/registers/enforcements/[id]/components/reference-sidebar/contacts/_index";
import { EnforcementDetailTab } from "@app/products/building/registers/enforcements/[id]/components/reference-sidebar/detail/_index";
import { IBuildingRecordsState } from "@app/products/building/registers/enforcements/[id]/model";
import { useBuildingRegistersEnforcementsStore } from "@app/products/building/registers/enforcements/[id]/store";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ExistManageBuildingRegisterEnforcement = observer(() => {
  const { id } = useParams<{ id: string }>();

  const [buildingRecords, setBuildingRecords] = useState<
    IBuildingRecordsState | undefined
  >(undefined);
  const [showSlideBar, setShowSlideBar] = useState(true);
  const managePageUrl = window.location.pathname;
  const {
    loadEnforcements,
    resetStore,
    isLoading,
    enforcements,
    enforcementId,
    responseLoadError,
  } = useBuildingRegistersEnforcementsStore();

  const { currentUserInfo } = useGlobalStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Building - Registers - Enforcements - ${enforcementId ?? 0}`,
      Text: `${getDisplayTextWithDashes([enforcements?.Contact])}` || "N/A",
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Building,
      RecordType_ENUM: RECORDTYPE.BUILDING,
      Record_ID: enforcementId ? +enforcementId : 0,
    },
  ];

  useEffectOnce(() => {
    getBuildingEnforcementRecords(
      parseInt(id),
      RECORDTYPE.TP_BuildingApplication
    ).then((res) => {
      setBuildingRecords(res);
    });
  });

  useEffectOnce(() => {
    loadEnforcements(parseInt(id));
    return () => {
      resetStore();
    };
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <EnforcementDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <EnforcementContactsTab /> },
      {
        title: "History",
        component: (
          <Journal id={0} recordType={RECORDTYPE.TP_BuildingApplication} />
        ),
      },
    ],
  });

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Actions",
      totalRecord: buildingRecords?.actions,
      component: (
        <Actions
          id={enforcementId}
          recordType={RECORDTYPE.TP_BuildingApplication}
        />
      ),
    },
    {
      title: "Comments",
      totalRecord: buildingRecords?.comments,
      component: (
        <Comments
          id={enforcementId}
          recordType={RECORDTYPE.TP_BuildingApplication}
        />
      ),
    },
    {
      title: "Documents",
      totalRecord: buildingRecords?.documents,
      component: (
        <Documents
          id={enforcementId}
          recordType={RECORDTYPE.TP_BuildingApplication}
        />
      ),
    },
    {
      title: "Fees",
      totalRecord: buildingRecords?.fees,
      component: (
        <Fees
          id={enforcementId}
          recordType={RECORDTYPE.TP_BuildingApplication}
        />
      ),
    },
    {
      title: "Related",
      totalRecord: buildingRecords?.samples,
      component: (
        <SamplesAccordion
          id={enforcementId}
          recordType={RECORDTYPE.TP_BuildingApplication}
        />
      ),
    },
    {
      title: "Events",
      totalRecord: buildingRecords?.samples,
      component: (
        <SamplesAccordion
          id={enforcementId}
          recordType={RECORDTYPE.TP_BuildingApplication}
        />
      ),
    },
  ];

  const formTitle = useMemo(() => {
    const enforcement = enforcements?.Contact;

    return enforcement || "N/A";
  }, [enforcements]);

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Enforcement"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadEnforcements(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={formTitle} badge={enforcements?.Status} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Building officer assessment"} />
                <CCNavButton title={"Permit lapsed"} />
                <CCNavButton title={"Practitioners"} />
                <CCNavButton title={"Withdraw application"} />
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Comment"} />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Document"} />
                <CCNavButton title={"Fee"} />
                <CCNavButton title={"Inspection"} />
                <CCNavButton title={"Sample"} />
              </CCNavButton>,

              <CCNavButton title={"View"} />,
              <CCNavButton title={"Print"} />,
              <CCNavButton title={"More Options"} type="more">
                <CCNavButton title={"Same application"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {enforcements && (
                  <CCGeneralPanel
                    component={<BuildingRegisterEnforcementForm />}
                  />
                )}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
