import {
  BubbleUpIdentifier,
  RecordSourceIdentifier,
} from "@app/products/waste-water/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";

export interface IAction {
  ID: number;
  ActionType_ID: number;
  Description: string;
  Flag_ActionRequired: boolean;
  ActionOfficer_ID: number;
  ActionOfficer: string;
  RecordedBy_ID: number;
  RecordedBy: string;
  // Status_ENUM: ACTIONSTATUS;
  Status: string;
  DateSent: Date | null;
  Parent_ID: number | null;
  ParentType_ENUM: RECORDTYPE | null;
}

export interface IActionType {
  ActionTypes: [
    {
      Key: number;
      Value: string;
    }
  ];
}

export enum ACTIONSTATUS {
  SystemInitialise = "SystemInitialise",
  NEW = "NEW",
  ALLOCATED = "ALLOCATED",
  COMPLETE = "COMPLETE",
  APPROVED = "APPROVED",
  NOTAPPROVED = "NOTAPPROVED",
  AWAITINGAPPROVAL = "AWAITINGAPPROVAL",
  DRAFT = "DRAFT",
  SENT = "SENT",
  READYTOBESENT = "READYTOBESENT",
  NOACTION = "NOACTION",
  PENDINGACTION = "PENDINGACTION",
  SEEKINGPARTIESAGREEMENTS = "SEEKINGPARTIESAGREEMENTS",
  PRECONCILIATIONMEETINGS = "PRECONCILIATIONMEETINGS",
  INPROGRESS = "",
  CLOSED = "INPROGRESS",
  AWAITINGSUBMISSION = "AWAITINGSUBMISSION",
  CONSIDERINGSUBMISSION = "CONSIDERINGSUBMISSION",
  FINAL = "FINAL",
  NOTSTARTED = "NOTSTARTED",
  ASSESSORAPPOINTED = "ASSESSORAPPOINTED",
  ASSESSMENTUNDERWAY = "ASSESSMENTUNDERWAY",
  CONSIDERINGASSESORSREPORT = "CONSIDERINGASSESORSREPORT",
  PENDINGDISCUSSIONWITHWORKER = "PENDINGDISCUSSIONWITHWORKER",
  PENDINGFINALDECISIONCOMPLETED = "PENDINGFINALDECISIONCOMPLETED",
  AWAITINGDELEGATEAPPROVAL = "AWAITINGDELEGATEAPPROVAL",
}

export enum ACTIONWORKFLOW {
  SystemInitialise = 0,
  LAMP_BranchManagerApproval = 1,
  LAMP_LicenceCoordinatorApproval = 2,
  LAMP_DelegateApproval = 3,
  LAMP_NotificationApproval = 4,
  LAMP_ApplicationProcessDelegateApproval = 5,
  PNF_AutoCreatedWorkFlowAction = 6,
  LM_AutoCreatedWorkFlowAction = 7,
}

export enum ASSESSORCONTACTTYPE {
  SystemInitialise = 0,
  PUBLICCONTACT = 1,
  DISABILITYWORKER = 2,
}

export interface IMailMergeIdentifier {
  iD: number;
  sys_EnterpriseKey: string;
  sourceIdentifier: RecordSourceIdentifier;
  bubbleUps: BubbleUpIdentifier[];
  collatedBubbleUps: BubbleUpIdentifier[];
  customFieldList: KeyValuePair<string, string>[];
  availableFieldsList: KeyValuePair<string, string>[];
  assessmentNumber: string;
  records_UserDefinedParentRecordId: string;
  records_AuthorName: string;
  formattedAuthorName: string;
  useParentFileNumber: boolean;
}

interface KeyValuePair<TKey, TValue> {
  Key?: TKey;
  Value?: TValue;
}

export interface Svc_ActionType {
  ID: number;
  Name: string;
  Description: string;
  Flag_HasOutcome: boolean;
  Flag_IsReferral: boolean;
  Flag_IsMeeting: boolean;
  Flag_ExternalOfficer: boolean;
  IsApproval: boolean;
  RestrictActionOfficerSelection: boolean;
  DefaultActionOfficer: string;
  TargetDays: number | null;
  Flag_UserSelection: boolean | null;
  ActionTypeTags_CSV: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Flag_RegulatoryAction: boolean;
}
