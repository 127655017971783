import { IListDialog } from "@app/products/property/components/action-bar/property-workflow/model";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import {
  DTO_Request_Base,
  DTO_WorkflowHeader,
  eVisibility,
} from "@app/products/property/model";

export interface DTO_Workflow_CreateParcel {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_CreateParcel;
}

export interface DTO_WorkflowDetail_CreateParcel {
  ParcelId?: number;
  Details: DTO_CreateParcel_Default;
  ParcelDetails: DTO_CreateParcel_Detail;
  Associations: DTO_CreateParcel_Association;
  Address: DTO_Parcel_Address[];
  SelectLandUses: DTO_Parcel_Land_Use[];
  SelectZones: DTO_Parcel_Zone[];
  SelectConstraints: DTO_ParcelConstraint[];
  //Using for Finish feature => show secondary workflow
  SecondaryWorkflowSelectedList?: IListDialog[];
}

export interface DTO_Parcel_Address {
  Locality: string;
  Address_PFI?: number;
  PropertyName_Address_Locality: string;
  MapReference: string;
  MapNumber: string;
  LocationDescriptor: string;
  SideOfStreet: string;
  StreetAndLocalityId?: number;
  HouseTo: string;
  HouseFrom: string;
  FloorNumberTo: string;
  FloorNumberFrom: string;
  FloorType: string;
  UnitNumberTo: string;
  UnitNumberFrom: string;
  BuildingUnitNumber: string;
  BuildingName: string;
  PropertyName: string;
  IsPrimaryAddress: boolean;
  AddressType: string;
  AddressTypeId: number;
  Id: number;
  PropertyAddress: string;
  postalDeliveryId?: number;
  // Add for handle update
  RowId: string;
}

export interface DTO_Parcel_Land_Use {
  Id: number;
  Name: string;
  Code: string;
  IsActive: boolean;
  ChangedOn?: Date;
  ChangedBy: string;
}

export interface DTO_ParcelConstraint {
  ParcelConstraintId: number;
  Constraint_Id?: number;
  Constraint_Category_Id?: number;
  Constraint_Category_Name: string;
  Constraint_Name: string;
  Constraint_Code: string;
  ChangedOn?: Date;
  ChangedBy: string;
}

export interface DTO_Parcel_Zone {
  Id: number;
  Name: string;
  Code: string;
  ZoningScheme: string;
  ChangedOn?: Date;
  ChangedBy: string;
}

export interface DTO_CreateParcel_Default {
  Reference: string;
  ApplicantName: string;
  ReasonForCreation: string;
  Status: string;
  OfficerRegionVisibility: eVisibility;
}

export interface RequestObj extends DTO_Request_Base {
  ParcelId: number;
}

export interface DTO_CreateParcel_Association {
  AssociationDate: Date;
  AssociateTitles: DTO_CreateParcel_Title[];
  AssociateAssessments: DTO_Assessment[];
}

export interface DTO_CreateParcel_Title {
  LinkedAssessments: DTO_Title_LinkedAssessment[];
  GISReference: string;
  Section: string;
  Parish: string;
  County: string;
  AssociatedPICAreaSet?: number;
  PIC_Title_Id?: number;
  AssociatedPICArea?: number;
  AssociatedPICAreaTotal?: number;
  IsAreaSurveyed: boolean;
  NameAddress: string;
  Frontage?: number;
  Active_From?: Date;
  AssociationFactorPercentage?: number;
  Coverage: string;
  StatusId?: number;
  CadId?: number;
  LLS_Property_ID?: number;
  PIC_ID: string;
  PIC: string;
  Title_Is_Part_of_Lot: boolean;
  GIS_GlobalID: string;
  isAddedToGridAfterSpatial: boolean;
  Is_Entitle?: boolean;
  Association_Type: string;
  Child_Title_Id?: number;
  Active_To?: Date;
  LandArea?: number;
  DivisionDate?: Date;
  Status: string;
  Folio: string;
  Volume: string;
  Lot: string;
  PlanNumber: string;
  PlanType: string;
  ReferenceNumber?: number;
  LegalDescription: string;
  TitleId: number;
  ParcelTitleId?: number;
}

export interface DTO_Title_LinkedAssessment {
  Assessment_Id: number;
  LinkedTitles: number[];
}

export interface DTO_Assessment {
  Official_Address_StringOID?: number;
  LGA: string;
  Active_From?: Date;
  Active_To?: Date;
  RLP_Board_Id?: number;
  RLP_Board_Name: string;
  AssociationFactorPercentage?: number;
  Valuation_1?: number;
  Valuation_2?: number;
  Valuation_3?: number;
  Valuation_4?: number;
  Valuation_5?: number;
  Valuation_6?: number;
  Valuation_7?: number;
  Valuation_8?: number;
  Valuation_9?: number;
  Valuation_10?: number;
  Valuation_11?: number;
  Valuation_12?: number;
  Valuation_13?: number;
  Valuation_14?: number;
  Valuation_15?: number;
  Valuation_16?: number;
  Valuation_17?: number;
  Valuation_18?: number;
  Valuation_19?: number;
  GurasId?: number;
  Region_Name: string;
  Region_Id?: number;
  PIC_Assessment_Id?: number;
  Assessment_Id: number;
  Assess_Number?: number;
  Assess_NumberX: string;
  Assess_Reference: string;
  Assess_Property_Address: string;
  Ratepayer_Name: string;
  Assess_Legal_Description: string;
  Unit_Entitlement?: number;
  Unit_Entitlement_Percentage?: number;
  Unit_Type: string;
  Unit_Type_Percentage?: number;
  Assess_Balance: number;
  Valuation_20?: number;
  Assess_Balance_Outstanding?: number;
  Assessment_Group_Id: number;
  Assessment_Group_Name: string;
  Valuation_Group_Name: string;
  Assessment_Type: number;
  Assessment_Type_Name: string;
  Assessment_Status: number;
  Assessment_Status_Name: string;
  Primary_Assess_Land_Use_Id?: number;
  Primary_Assess_Land_Use_Name: string;
  AS_Is_Assessment_Editable: boolean;
  LinkedTitles: DTO_Assessment_LinkedTitle[];
  Is_Primary_Assessment?: boolean;
  Assess_Being_Postponed: boolean;
}

export interface DTO_Assessment_LinkedTitle {
  Title_Id: number;
  LinkedAssessments: number[];
}

export interface DTO_CreateParcel_Detail {
  Fire_Property_Category_Visibility: eVisibility;
  ElectoralDivisionFederalVisibility: eVisibility;
  ElectoralDivisionStateCodeVisibility: eVisibility;
  ElectoralDivisionLocalVisibility: eVisibility;
  SwimmingPoolFlagVisibility: eVisibility;
  EstateVisibility: eVisibility;
  LandCategoryVisibility: eVisibility;
  GisReferences: DTO_GIS[];
  Fire_Service_District?: number;
  Fire_Service_Classification?: number;
  Fire_Property_Category?: number;
  FederalElectoralDivision?: number;
  StateElectoralDivision?: number;
  LocalElectoralDivision?: number;
  HasSwimmingPool: boolean;
  CensusDistrict: string;
  EstateStage: string;
  Estate?: number;
  AutoUpdateLegalDescription: boolean;
  LegalDescription: string;
  Status: number;
  ParcelReference: string;
  LandCategoryId?: number;
  LandCategory: string;
  LandArea: string;
  Fire_Service_Classification_Visibility: eVisibility;
  Fire_Service_District_Visibility: eVisibility;
}

export enum EParcelGridType {
  Assessment = "Assessment",
  Title = "Title",
}

export enum EParcelLoadingType {
  Grid = "Grid",
  Data = "Data",
  DataTitle = "DataTitle",
  DataAssessment = "DataAssessment",
}

export enum EParcelGridError {
  Assessment = "Assessment",
  Title = "Title",
  TitleLength = "TitleLength",
}
