import { DTO_AssessmentLandRentAction_LOVs } from "@app/products/property/assessments/components/dialogs/assessment-land-rent-action/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class AssessmentLandRentActionDialogStore {
  private _assessmentLandRentActionLOVs?: DTO_AssessmentLandRentAction_LOVs =
    undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get assessmentLandRentActionLOVs() {
    return this._assessmentLandRentActionLOVs;
  }
  setAssessmentLandRentActionLOVs = (assessmentLandRentActionLOVs?: any) => {
    runInAction(() => {
      this._assessmentLandRentActionLOVs = assessmentLandRentActionLOVs;
    });
  };
}

const assessmentLandRentActionDialogStoreContext = createContext(
  new AssessmentLandRentActionDialogStore()
);
export const useAssessmentLandRentActionDialogStore = () => {
  return useContext(assessmentLandRentActionDialogStoreContext);
};
