import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { loadViewConfiguresColumns } from "@app/products/property/api";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { propertyContactCols } from "@app/products/property/contacts-central-names/list/config";
import { VO_Contact } from "@app/products/property/contacts-central-names/list/model";
import { CONTACT_LRS_LOT_OWNERS_URL } from "@app/products/property/contacts-central-names/lrs-lot-owners/constant";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
} from "@app/products/property/contacts-central-names/lrs-lot-owners/util";
import { contactRoute } from "@app/products/property/contacts-central-names/route";
import { getBookmarkListViewDisplayName } from "@app/products/property/contacts-central-names/util";
import { ContactAssociationsTab } from "@app/products/property/contacts-central-names/[id]/components/reference-sidebar/associations/_index";
import { ContactCentralNameDetailTab } from "@app/products/property/contacts-central-names/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_ENTITY_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { APIResponseError } from "@common/apis/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CommunityProperty } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_Contact>();
export default observer(() => {
  const { isLLS } = CommunityProperty.getFlagOfStates();
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedCols, setProcessedCols] =
    useState<IColumnFields[]>(propertyContactCols);

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.OData_Contact_List,
      propertyContactCols
    );
    setIsLoading(false);
    if (Array.isArray(response)) {
      setProcessedCols(response);
    } else {
      setResponseLoadError(response);
    }
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={contactRoute.path} />,
    ],
    centerComponents: [
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Entity}
        recordType={RECORDTYPE.CommunityProperty_Entity}
        isVisible={isLLS}
        initMailMergeData={{
          AppendToRecordSource: false,
          AllowAppendOptionEditing: false,
          AllowSaveFormatOptionEditing: false,
          BatchPrint: true,
        }}
        isDisabled
      />,
      <CommunicationButtonListView
        keyField={nameOf("Entity_Id")}
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Entity}
        isDisabled
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_ENTITY_ROUTE}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Entity}
      />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <ContactCentralNameDetailTab /> },
      {
        title: "Related",
        component: <ContactAssociationsTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Entity}
          />
        ),
      },
    ],
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      dataUrl={CONTACT_LRS_LOT_OWNERS_URL}
      columnFields={processedCols}
      primaryField={nameOf("Entity_Id")}
      state={{
        sort: [{ field: nameOf("ENA_Name"), dir: "asc" }],
      }}
    />
  );
});
