import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" }); // Strict mode: runInAction

class SettingConfirmExitStore {
  private _confirmExit = false;
  private _confirmChildExit: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get confirmExit(): boolean {
    return toJS(this._confirmExit);
  }
  setConfirmExit = (value: boolean) => {
    runInAction(() => {
      this._confirmExit = value;
    });
  };

  get confirmChildExit(): boolean {
    return toJS(this._confirmChildExit);
  }
  setConfirmChildExit = (value: boolean) => {
    runInAction(() => {
      this._confirmChildExit = value;
    });
  };
}

export const settingConfirmExitStore = new SettingConfirmExitStore();
const settingConfirmExitStoreContext = createContext(
  new SettingConfirmExitStore()
);
export const useSettingConfirmExitStore = () => {
  return useContext(settingConfirmExitStoreContext);
};
