import { FINANCE_FEE_MANAGE_ROUTE_NEW } from "@app/core/fees/[id]/constant";
import { IFeeParentSection } from "@app/core/fees/[id]/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

interface IAddFeeWithParentButtonProps {
  id: number;
  recordType: RECORDTYPE;
  productType: PRODUCT_TYPE_NUMBER;
  isDisabled?: boolean;
  titleButton?: string;
}

export const AddFeeWithParentButton = observer(
  ({
    id,
    recordType,
    productType,
    isDisabled = false,
    titleButton = "Invoice item",
  }: IAddFeeWithParentButtonProps) => {
    const history = useHistory();

    return (
      <CCNavButton
        title={titleButton}
        onClick={() => {
          history.push(FINANCE_FEE_MANAGE_ROUTE_NEW, {
            parent: {
              id,
              recordType,
              productType,
            } as IFeeParentSection,
          });
        }}
        disabled={isDisabled}
      />
    );
  }
);
