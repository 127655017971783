import { DTO_ImportValuationTotalAmount } from "@app/products/property/supplementary-rates/import-valuations/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getImportValuationsTotalAmount = async (): Promise<
  APIResponse<DTO_ImportValuationTotalAmount>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/supplementary/import/totalamount`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
