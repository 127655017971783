import { postSaveCondition } from "@app/core/condition/[id]/api";
import {
  CONDITION_MANAGE_ROUTE,
  CONDITION_SLIDER,
} from "@app/core/condition/[id]/constant";
import {
  Condition,
  IParentConditionSection,
} from "@app/core/condition/[id]/model";
import {
  getConditionGeneralForm,
  renderCoreConditionBubbleUps,
} from "@app/core/condition/[id]/util";
import { APIResponse } from "@common/apis/model";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { IdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

interface ISaveConditionButtonProps {
  isDisabled?: boolean;
}
export const SaveConditionButton = observer(
  ({ isDisabled = false }: ISaveConditionButtonProps) => {
    //#region STORE ========/
    const { submitFormGetData, isLoadingForm, setIsLoadingForm } =
      useFlexibleFormStore();
    const isNew = useIsNew();
    //#endregion STORE =====/

    //#region HOOK ========/
    const { pushNotificationPortal } = useNotificationPortalStore();
    const { cancelToken } = useCancelRequest();
    const history = useHistory();
    //#endregion HOOK =====/

    const parentState = getConditionGeneralForm("CCFormOptions")
      ?.parentState as IParentConditionSection;

    const saveConditionSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const condition: Condition = initialData;
            const bubbleUps =
              parentState?.bubbleUps ??
              renderCoreConditionBubbleUps(
                parentState.id,
                parentState.recordType
              );
            if (isNew) {
              condition._BubbleUps = bubbleUps;
            }
            return postSaveCondition(condition, cancelToken());
          },
          handleSuccess: async ({ dataFromApi }) => {
            const response: APIResponse<IdentityPacket> = dataFromApi;
            if (isNew) {
              if (response?.data?.ID) {
                const urlRedirect =
                  CONDITION_MANAGE_ROUTE + `/${response?.data?.ID}`;
                pushNotificationPortal({
                  title: "Condition saved successfully.",
                  type: "success",
                  route: urlRedirect,
                  callBack: () => {
                    history.replace(urlRedirect);
                  },
                });
              }
            } else {
              await fetchApiByAlias(CONDITION_SLIDER);
              pushNotificationPortal({
                title: "Condition saved successfully.",
                type: "success",
              });
            }
            return false;
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "Save condition failed.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleClickButton = async () => {
      const condition: Condition = await submitFormGetData("GeneralForm");

      if (condition) {
        setIsLoadingForm(true);
        await saveConditionSlider.fetchApi({
          initialData: condition,
        });
        setIsLoadingForm(false);
      }
    };

    return (
      <CCNavButton
        title="Save"
        onClick={handleClickButton}
        disabled={isLoadingForm || isDisabled}
      />
    );
  }
);
