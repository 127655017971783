import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { Action } from "@common/pages/actions/model";

export const putSendToActionOfficer = async (
  action: Action,
  id?: number | null,
  recordType?: RECORDTYPE
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/core/internal/actions/sendtoactionofficer`,
      action,
      {
        params: {
          id,
          recordType,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
