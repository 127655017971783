import { ExistedCRMSSystemAminCategory } from "@app/products/crms/system-admin/categories/[id]/components/forms/existed/_index";
import { NewCRMSSystemAminCategory } from "@app/products/crms/system-admin/categories/[id]/components/forms/new/_index";
import { useCRMSSytemAdminCategoryStore } from "@app/products/crms/system-admin/categories/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const ManageCRMSSystemAminCategory = observer(() => {
  const isNew = useIsNew();
  const { loadCategory, resetStore } = useCRMSSytemAdminCategoryStore();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadCategory(parseInt(id), isNew);
    return () => {
      resetStore();
    };
  }, [id, isNew, loadCategory, resetStore]);

  if (isNew) {
    return <NewCRMSSystemAminCategory />;
  }

  return <ExistedCRMSSystemAminCategory />;
});

export default ManageCRMSSystemAminCategory;
