import { mockSpecialReadingData } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/enter-special-reading/mock";
import { DTO_Assessment_Summary } from "@app/products/property/assessments/[id]/model";
import { ISpecialReadingDialogData } from "@app/products/property/meters/components/dialogs/enter-special-reading/model";
import { sleep } from "@common/utils/common";

export const loadAssessmentSpecialReadingData = async (
  assessmentData: DTO_Assessment_Summary | undefined
): Promise<ISpecialReadingDialogData> => {
  await sleep(500);
  return {
    ...mockSpecialReadingData,
    AssessmentNumber: assessmentData?.Assessment.AssessmentNumber,
  };
};
