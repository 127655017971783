import { PPRPlansToComply } from "@app/products/town-planning/ppr/plans-to-comply/register/model";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<PPRPlansToComply>();

export const colPPRPlansToComply: IColumnFields[] = [
  {
    field: nameOf("ApplicationNumber"),
    title: "Number",
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_PPR_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("LodgedDate"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("SiteAddress"),
    title: "Site Address",
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("Postcode"),
    title: "Postcode",
  },
  {
    field: nameOf("Estate"),
    title: "Estate",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("StatDays"),
    title: "Days",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Planner"),
    title: "Planner",
  },
  {
    field: nameOf("TeamLeader"),
    title: "Delegated Approver",
  },
  {
    field: nameOf("VRStreetName"),
    title: "Declared Arterial",
  },
  {
    field: nameOf("ReasonForPermit"),
    title: "Reason for Permit",
  },
  {
    field: nameOf("Decision"),
    title: "Decision",
  },
  {
    field: nameOf("DecisionDate"),
    title: "Decision Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("FinalDecision"),
    title: "Final Decision",
  },
  {
    field: nameOf("FinalDecisionDate"),
    title: "Final Decision Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Applicant"),
    title: "Applicant",
  },
  {
    field: nameOf("ApplicantAddress"),
    title: "Address",
  },
  {
    field: nameOf("FileNo"),
    title: "File Number",
  },
  {
    field: nameOf("AssessmentNo"),
    title: "Assess Number",
  },
  {
    field: nameOf("Ward"),
    title: "Ward",
  },
  {
    field: nameOf("SPEARReference"),
    title: "Spear Reference",
  },
];
