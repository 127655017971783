import { DTO_Workflow_CreateDeferredDuty } from "@app/products/property/deferred-duty/components/action-bar/dialogs/create-deferred-duty/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postNewWorkflowDeferredDuty = async (): Promise<
  APIResponse<DTO_Workflow_CreateDeferredDuty>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/createdeferredduty/new`,
      {}
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewDeferredDuty = async (
  model: WorkflowProcessMode,
  deferredDuty: DTO_Workflow_CreateDeferredDuty | undefined
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_CreateDeferredDuty>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/createdeferredduty/process/${model}`,
      deferredDuty
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getDeferredDutyDraft = async (
  workflowDraftId: number | undefined
): Promise<APIResponse<DTO_Workflow_CreateDeferredDuty>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/createdeferredduty/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataDeferredDuty =
  | APIResponse<DTO_Workflow_CreateDeferredDuty>
  | undefined;

export const getInitialDataDeferredDuty = async (
  workflowDraftId: number | undefined
): Promise<IGetInitialDataDeferredDuty> => {
  try {
    return !isNil(workflowDraftId)
      ? await getDeferredDutyDraft(workflowDraftId)
      : await postNewWorkflowDeferredDuty();
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
