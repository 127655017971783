import { IReadingGridData } from "@app/products/property/meters/components/dialogs/enter-special-reading/model";
import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IReadingGridData>();

export const colSpecialReadingEnterReading: IColumnFields[] = [
  {
    field: nameOf("MeterNumber"),
    title: "Meter Number",
  },
  {
    field: nameOf("Utility"),
    title: "Utility",
  },
  {
    field: nameOf("CurrentReadingDate"),
    title: "Current Reading Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("CurrentReading"),
    title: "Current Reading",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("PreviousReadingDate"),
    title: "Previous Reading Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("PreviousReading"),
    title: "Previous Reading",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Usage"),
    title: "Usage",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("UnitOfMeasure"),
    title: "Unit of Measure",
  },
  {
    field: nameOf("PercentShare"),
    title: "Percentage Share",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("NumberOfDials"),
    title: "Number of Dials",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("EstimatedUsage"),
    title: "Estimated Usage",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("WarningUsageThreshold"),
    title: "Warning Usage Threshold",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Comment"),
    title: "Comment",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("MeterInstallationId"),
    title: "Meter Installation ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
