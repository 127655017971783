import { Svc_PoundRegister } from "@app/products/animals/pound-register/[id]/model";
import { useAnimalPoundRegisterStore } from "@app/products/animals/pound-register/[id]/store";
import { ReasonDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/_index";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { nameOfFactory } from "@common/utils/common";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IAnimalPoundRegisterFormProps {
  formRenderProps: FormRenderProps;
}

const nameOfMapObj = nameOfFactory<Svc_PoundRegister>();

export const PoundRegisterWorkflowElements = observer(
  (props: IAnimalPoundRegisterFormProps) => {
    const { valueGetter } = props.formRenderProps;
    const {
      isLoadingWorkflow,
      isShowWorkflowClosure,
      setIsLoadingWorkflow,
      setIsShowWorkflowClosure,
      handleWorkflowClosure,
    } = useAnimalPoundRegisterStore();

    const objReasonClosure = useMemo(() => {
      const retVal = new ProvideReason();
      retVal.Title_Text = "Closure Details";
      retVal.ReasonLabel_Text = "Outcome";
      retVal.Flag_ShowDate = true;
      retVal.DateLabel_Text = "Closure date";
      retVal.DateLabel_RequiredErrorMessage = "Closure Date must be filled";
      retVal.Flag_DateIsMandatory = true;
      retVal.Flag_ShowKWD = true;
      retVal.Flag_KWDIsMandatory = true;
      retVal.KWDType = KEYWORD_TYPE.Core_Outcome;
      retVal.KWDLabel_Text = "Outcome";
      retVal.KWDLabel_RequiredErrorMessage = "Outcome must be filled";
      retVal.ProdType = PRODUCT_TYPE_NUMBER.Animals;
      retVal.Reason = "";
      retVal.Flag_ShowReasonTextBox = false;
      retVal.DateOfAction = new Date();
      return retVal;
    }, []);

    return (
      <>
        {isShowWorkflowClosure && (
          <ReasonDialog
            onClose={() => setIsShowWorkflowClosure(false)}
            onSubmit={(
              value: ProvideReason,
              notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
            ) =>
              handleWorkflowClosure(
                valueGetter(nameOfMapObj("PoundRegister")),
                value,
                notificationRef
              )
            }
            setIsLoading={setIsLoadingWorkflow}
            isLoading={isLoadingWorkflow}
            initialData={objReasonClosure}
          />
        )}
      </>
    );
  }
);
