import { history } from "@/AppRoutes";
import {
  getBuildingPoolsSpasById,
  postBuildingPoolsSpas,
  putBuildingPoolsSpas,
} from "@app/products/building/pools-or-spas/[id]/api";
import { POOLS_OR_SPAS_MANAGE_ROUTE } from "@app/products/building/pools-or-spas/[id]/constant";
import { IBuildingPoolsSpas } from "@app/products/building/pools-or-spas/[id]/model";
import { IBuilding } from "@app/products/building/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class BuildingPoolOrSpasStore {
  private _poolsOrSpas?: IBuildingPoolsSpas = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get poolsOrSpas() {
    return this._poolsOrSpas;
  }
  setPoolsOrSpas = (poolsOrSpas: IBuildingPoolsSpas | undefined) => {
    runInAction(() => {
      this._poolsOrSpas = poolsOrSpas;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get poolsOrSpasId() {
    return this._poolsOrSpas?.ID;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._poolsOrSpas = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadPoolOrSpas = async (poolsOrSpasId: number) => {
    this.setIsLoading(true);
    const response = await getBuildingPoolsSpasById(poolsOrSpasId);

    let errorResponse, newPoolOrSpas;

    if (isSuccessResponse(response)) {
      newPoolOrSpas = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }

    this.setResponseLoadError(errorResponse);
    this.setPoolsOrSpas(newPoolOrSpas);
    this.setIsLoading(false);
  };

  createBuildingPoolsSpas = async (building: IBuilding) => {
    this.setIsLoading(true);
    await postBuildingPoolsSpas(building);
    appNotificationStore.pushNotification({
      title: "Building pool spas created successfully",
      type: "success",
      onClose: () => {
        history.replace(`${POOLS_OR_SPAS_MANAGE_ROUTE}/${building?.id ?? 1}`);
      },
    });
    this.setIsLoading(false);
  };

  updateBuildingPoolsSpas = async (building: IBuilding) => {
    this.setIsLoading(true);
    await putBuildingPoolsSpas(building);
    appNotificationStore.pushNotification({
      title: "Building pool spas updated successfully",
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const buildingPoolOrSpasStoreContext = createContext(
  new BuildingPoolOrSpasStore()
);
export const useBuildingPoolOrSpasStore = () => {
  return useContext(buildingPoolOrSpasStoreContext);
};
