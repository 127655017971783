import { eventEmitter } from "@/App";
import { eComponent } from "@app/products/property/components/associations/model";
import { ITEMS_ACCORDION_GRID_ID } from "@app/products/property/journals/[id]/components/child-screens/items/constant";
import { useJournalTransactionStore } from "@app/products/property/journals/[id]/components/child-screens/items/store";
import {
  postCancelPICTransaction,
  postCancelTransactionById,
} from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/cancel-transaction/api";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

interface ICancelTransactionButtonProps {
  disabled?: boolean;
  componentNumber?: eComponent;
  nameForTransactionId: string;
}

export const CancelTransactionButton = observer(
  ({
    disabled = false,
    componentNumber,
    nameForTransactionId,
  }: ICancelTransactionButtonProps) => {
    const { selectedTransaction, loadJournalItemsData } =
      useJournalTransactionStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { id } = useParams<{ id: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showCancelTransactionDialog, setShowCancelTransactionDialog] =
      useState(false);
    const handleSubmit = async () => {
      if (
        !isNil(componentNumber) &&
        !isNil(id) &&
        selectedTransaction &&
        !isNil(selectedTransaction?.[`${nameForTransactionId}`])
      ) {
        setIsLoading(true);
        let response = null;
        const journalNumber = parseInt(id);
        const transactionID = selectedTransaction?.[`${nameForTransactionId}`];
        switch (componentNumber) {
          case eComponent.Assessment:
            response = await postCancelTransactionById(
              journalNumber,
              transactionID
            );
            break;
          case eComponent.PIC:
            response = await postCancelPICTransaction(
              journalNumber,
              transactionID
            );
            break;
        }
        setIsLoading(false);
        if (response !== null) {
          if (isSuccessResponse(response) && response.data?.IsSuccess) {
            setShowCancelTransactionDialog(false);
            pushNotification({
              title:
                response.data?.Notification ??
                "Cancel transaction successfully",
              description: response.data?.SuccessMessage ?? "",
              type: "success",
            });
            switch (componentNumber) {
              case eComponent.PIC:
                await loadJournalItemsData(id);
                break;
              case eComponent.Assessment:
              default:
                eventEmitter.emit(CCGridEventType.RefreshOData, {
                  gridIds: [ITEMS_ACCORDION_GRID_ID],
                });
                break;
            }
          } else {
            setShowCancelTransactionDialog(false);
            pushNotification({
              autoClose: false,
              title: response.data?.Notification ?? "Cancel transaction failed",
              description: response.data?.ErrorMessage ?? "",
              type: "error",
            });
          }
        }
      }
      setShowCancelTransactionDialog(false);
    };

    return (
      <>
        <PrivilegeWrapper
          dialogType={ePermissionPrivilegeTypeCheck.Dialog}
          privilegesEnum={EPrivilegesWorkflow.Journal_Cancel_Transaction}
        >
          <CCNavButton
            title="Cancel"
            disabled={disabled}
            onClick={() => setShowCancelTransactionDialog(true)}
          />
        </PrivilegeWrapper>
        {showCancelTransactionDialog && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={"Are you sure you wish to cancel this transaction?"}
            isLoadingYes={isLoading}
            onClosePopup={() => setShowCancelTransactionDialog(false)}
            onAsyncConfirm={handleSubmit}
          />
        )}
      </>
    );
  }
);
