export const mockContacts = [
  {
    ID: "3173054",
    Type: "Complainant",
    DisplayName: "Justin Da Silva",
    WorkPhone: "(640) 254-957",
    Mobile: "1-540-062-1600",
    Email: "tan.tran@openoffice.com.au",
    PostalAddress: "360 Derrimut Street, HOPPERS CROSSING VIC 3029",
    PreferredMethod_ENUM: "0",
    PreferredMethod_Name: "None",
  },
  {
    ID: "3173058",
    Type: "Complaint Handler",
    DisplayName: "Jisha Jose",
    WorkPhone: "(640) 389-657",
    Mobile: "1-640-062-1500",
    Email: "tan.tran@openoffice.com.au",
    PostalAddress: "360 Derrimut Street, HOPPERS CROSSING VIC 3029",
    PreferredMethod_ENUM: "0",
    PreferredMethod_Name: "None",
  },
];
