import { CCGrid } from "@components/cc-grid/_index";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { loadTransactions } from "./api";
import { colTransactions } from "./config";

export const SchemeAccountTransactions = () => {
  const [listData, setListData] = useState<any[]>([]);
  const [showCancelledTransactions, setShowCancelledTransactions] =
    useState<boolean>(true);
  const handleOnShowCancelledTransactionsChange = (
    event: CheckboxChangeEvent
  ) => {
    const { value } = event;
    setShowCancelledTransactions(value);
    loadTransactions(value).then((data) => {
      setListData(data);
    });
  };
  useEffectOnce(() => {
    loadTransactions(showCancelledTransactions).then((data) => {
      setListData(data);
    });
  });
  return (
    <div className="cc-deferred-duty-transactions">
      <div className="cc-grid-control-left">
        <Checkbox
          className="cc-control-item"
          title="Show Cancelled Transactions"
          label="Show cancelled transactions"
          checked={showCancelledTransactions}
          onChange={handleOnShowCancelledTransactionsChange}
        />
      </div>

      <CCGrid
        className="cc-deferred-duty-transactions-grid"
        data={listData ?? []}
        columnFields={colTransactions}
        primaryField="AccountTransactionId"
      />
    </div>
  );
};
