import { ExistedBuildingRegisterEssentialSafety } from "@app/products/building/registers/essential-safety/[id]/components/forms/existed/_index";
import { NewBuildingRegisterEssentialSafety } from "@app/products/building/registers/essential-safety/[id]/components/forms/new/_index";
import { useBuildingRegistersEssentialSafetyStore } from "@app/products/building/registers/essential-safety/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageBuildingRegisterEssentialSafety = observer(() => {
  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();
  const { resetStore, loadBuildingRegistersEssentialSafety } =
    useBuildingRegistersEssentialSafetyStore();

  useEffectOnce(() => {
    return () => resetStore();
  });

  useEffect(() => {
    if (isNew) return;
    loadBuildingRegistersEssentialSafety(+id);
  }, [id, isNew, loadBuildingRegistersEssentialSafety]);

  if (isNew) {
    return <NewBuildingRegisterEssentialSafety />;
  }
  return <ExistedBuildingRegisterEssentialSafety />;
});

export default ManageBuildingRegisterEssentialSafety;
