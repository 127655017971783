import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CollectionFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/_index";
import { useAssessmentCollectionStepStore } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/store";
import { collectionUtilProcessing } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/util";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import {
  getSuffixTitle,
  getWorkflowInstructions,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { officerUtilProcessing } from "@app/products/property/components/fields/officer-and-officer-region/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  Collection,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { PROPERTY_TITLES_ROUTE } from "@app/products/property/titles/[id]/constant";
import {
  getInitialDataTitle,
  postProcessCreateTitle,
} from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/api";
import { AssociatedTitles } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/form-elements/associated-titles/_index";
import { DetailFormStep } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/form-elements/details/_index";
import { ParcelDetailFormStep } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/form-elements/parcel-details/_index";
import { TitleDetailsFormStep } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/form-elements/title-details/_index";
import {
  DTO_Title_LOVs,
  DTO_WorkflowDetail_CreateTitle,
  DTO_Workflow_CreateTitle,
  EKeysOfSteps,
  keysOfSendSteps,
} from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/model";
import { useCreateTitleStore } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/store";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { CommunityProperty, Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { getUUID } from "@common/utils/common";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { head, isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
interface ICreateTitleDialogProps {
  onClose: () => void;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
}

export const CreateTitleDialog = observer((props: ICreateTitleDialogProps) => {
  const {
    onClose,
    prefixTitle,
    suffixTitle,
    dataFromActionList,
    isSaveOnNextStep = false,
  } = props;

  // Store
  const { setDataForCloseDialog, setIsLoadingClose } =
    useConfirmCloseDialogStore();
  const { setDataForCancelDialog } = useConfirmCancelDialogStore();
  const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
  const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
  const { setDataForRejectDialog } = useConfirmRejectDialogStore();
  const { clearSelectedItems } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { setDataForFinishDialog } = useConfirmFinishDialogStore();
  const { currentFormTitle } = useCommonProductStore();
  const { setLOVsCreateTitle, setIsLoadingOnDialog } = useCreateTitleStore();
  const { setAssessmentCollectionStepLOVs } =
    useAssessmentCollectionStepStore();

  const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
  const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
  const [workflowInitData, setWorkflowInitData] =
    useState<DTO_Workflow_CreateTitle>();
  const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
  const [isLoadingInStep, setIsLoadingInStep] = useState<boolean>(false);
  const isFirstSave = useMemo(() => {
    const draftId =
      workflowInitData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id;
    return isNil(draftId) || draftId === 0;
  }, [workflowInitData]);
  const notificationFormStepRef = useRef<ICCFormStepNotificationHandle | null>(
    null
  );

  const history = useHistory();
  const {
    isFromActionList,
    isIncompleteMode,
    isReadOnly,
    isShowCancelWorkflowButton,
    isToBeApprovalMode,
    statusBadge,
  } = usePropertyWorkflow(dataFromActionList);

  // Sites
  const { isLLS } = CommunityProperty.getFlagOfStates();

  //Get labels
  const [titleLabel, titleLabelLowercase] = Label.CommunityProperty.getLabel([
    ECustomColNameProperty.Title,
    ECustomColNameProperty.TitleLowercase,
  ]);

  const initialValue = useMemo(() => {
    const workflowDetail = workflowInitData?.WorkflowDetail;
    const workflowHeader = workflowInitData?.WorkflowHeader;

    const officerInitData =
      officerUtilProcessing.processDataInit(workflowInitData);
    let initDetails: any = {
      ...workflowDetail?.Details,
      Instructions: getWorkflowInstructions(
        workflowHeader?.WorkflowApprovals ?? []
      ),
      ...officerInitData,
    };

    let cloneDetailGIS: DTO_GIS[] = [];
    if (workflowDetail?.TitleDetail?.GIS_References?.length) {
      cloneDetailGIS = workflowDetail?.TitleDetail?.GIS_References?.map(
        (item: DTO_GIS) => ({
          ...item,
          Id: getUUID(),
        })
      );
    }

    let initTitleDetails: any = {
      ...workflowDetail?.TitleDetail,
      GIS_References: cloneDetailGIS,
    };

    let initParcelDetails: any = [...(workflowDetail?.Associate_Parcels ?? [])];
    let initAssociatedTitle: any = [
      ...(workflowDetail?.Associate_Titles ?? []),
    ];

    //---step Secondary Workflow---
    const initSecondaryWorkflow =
      secondaryWorkflowUtilProcessing.processDataInit(workflowInitData);

    return {
      [EKeysOfSteps.Details]: initDetails,
      [EKeysOfSteps.TitleDetail]: initTitleDetails,
      [EKeysOfSteps.ParcelDetails]: initParcelDetails,
      [EKeysOfSteps.AssociatedTitle]: initAssociatedTitle,
      [EKeysOfSteps.Comments]: {},
      [EKeysOfSteps.Documents]: {},
      [EKeysOfSteps.SecondaryWorkflow]: initSecondaryWorkflow,
      [EKeysOfSteps.Collection]:
        collectionUtilProcessing.processDataInit<Collection[]>(
          workflowInitData
        ),
    };
  }, [workflowInitData]);

  const steps: IStep[] = [
    {
      label: "Details",
      component: DetailFormStep,
      visible: true,
      key: EKeysOfSteps.Details,
      initialValues: initialValue.Details,
      options: {
        isReadOnly,
        isDisabled: workflowDraftId,
        isToBeApprovalMode,
      },
    },
    {
      label: `${titleLabel} Details`,
      component: TitleDetailsFormStep,
      visible: true,
      key: EKeysOfSteps.TitleDetail,
      initialValues: initialValue.TitleDetail,
      options: {
        isReadOnly,
        isLLS: isLLS,
        setIsLoadingInStep,
      },
    },
    {
      label: `Parcel Details`,
      component: ParcelDetailFormStep,
      visible: !isLLS,
      key: EKeysOfSteps.ParcelDetails,
      initialValues: initialValue.Associate_Parcels,
      options: {
        isReadOnly,
        setIsLoadingDialog,
      },
    },
    {
      label: `Associated ${titleLabel}`,
      component: AssociatedTitles,
      visible: true,
      key: EKeysOfSteps.AssociatedTitle,
      initialValues: initialValue.Associate_Titles,
      options: {
        isReadOnly,
        setIsLoadingDialog,
      },
    },
    {
      label: "Collections",
      initialValues: initialValue[EKeysOfSteps.Collection],
      component: CollectionFormStep,
      visible: true,
      key: EKeysOfSteps.Collection,
      options: {
        isReadOnly,
      },
    },
    {
      label: "Comments",
      initialValues: initialValue.Comments,
      component: CommentsFormStep,
      visible: true,
      key: EKeysOfSteps.Comments,
      options: {
        isReadOnly,
        workflowDraftId,
        recordType: RECORDTYPE.CommunityProperty_Title,
      },
      customClassName: "cc-comment-step-fixed-height-grid",
    },
    {
      label: "Documents",
      component: DocumentsFormStep,
      initialValues: initialValue.Documents,
      visible: true,
      key: EKeysOfSteps.Documents,
      options: {
        isReadOnly,
        workflowDraftId,
        workflowType: WorkflowTypes.CreateTitle,
      },
    },
    {
      label: "Workflow",
      initialValues: initialValue.SecondaryWorkflow,
      component: WorkflowStepFormStep,
      visible: true,
      key: EKeysOfSteps.SecondaryWorkflow,
      options: {
        isReadOnly,
        isFromActionList,
        dataFromActionList,
      },
    },
  ];

  const handleSubmit = async (data: any, buttonId?: string) => {
    switch (buttonId) {
      case EListSubmitButton.Approve:
        handleApproveProcess(processData(data));
        break;
      case EListSubmitButton.Save:
        await handleSaveAndNext(processData(data), true);
        break;
      case EListSubmitButton.SaveWorkflow:
        await handleSaveAndNext(processData(data), false, true);
        break;
      case EListSubmitButton.Finish:
        handleConfirmFinishProcess(data);
        break;
      case EListSubmitButton.Cancel:
      case EListSubmitButton.ConfirmCloseNo:
        handleCancelButton(processData(data));
        break;
      case EListSubmitButton.Reject:
        handleRejectButton(processData(data));
        break;
      case EListSubmitButton.ConfirmCloseYes:
        handleConfirmRetainProcess(processData(data));
        break;
      case EListSubmitButton.SendBack:
        handleConfirmSendBackProcess(processData(data));
        break;
      case EListSubmitButton.Reallocate:
        handleConfirmReallocateProcess(processData(data));
        break;
      case EListSubmitButton.Park:
      case EListSubmitButton.Close:
        handleParkProcess(processData(data));
        break;
    }
  };

  const handleCloseDialog = (renderProps: ICCFormStepRender) => {
    if (!isFromActionList && !isFirstSave) {
      //Store submit event
      setDataForCloseDialog({
        closeCallback: renderProps.submitButton.onClick,
      });
    } else if (
      isIncompleteMode &&
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
    ) {
      onClose();
    } else if (
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Incomplete &&
      !isFirstSave
    ) {
      const newEvent = {
        currentTarget: { id: EListSubmitButton.Close },
      };
      renderProps.submitButton.onClick(newEvent);
    } else {
      onClose();
    }
  };

  /**
   * Handle cancel process
   */
  const handleCancelButton = (data: any) => {
    if (isFromActionList || !isFirstSave) {
      setDataForCancelDialog({
        cancelAPI: postProcessCreateTitle,
        dataCancel: data,
        defaultSuccessMessage: `The new ${titleLabelLowercase} application was cancelled successfully`,
        defaultErrorMessage: `Create ${titleLabelLowercase} cancel failed`,
      });
    } else {
      onClose();
    }
  };

  /**
   * Handle reject process
   */
  const handleRejectButton = (data: any) => {
    if (isFromActionList || !isFirstSave) {
      setDataForRejectDialog({
        rejectCallback: postProcessCreateTitle,
        dataReject: data,
        defaultSuccessMessage: `Create ${titleLabelLowercase} application was rejected successfully`,
        defaultErrorMessage: `Create ${titleLabelLowercase} reject failed`,
      });
    } else {
      onClose();
    }
  };

  /**
   * handle finish workflow process
   * @param payload
   */
  const handleConfirmFinishProcess = (payload: DTO_Workflow_CreateTitle) => {
    const dataProcessed = processData(payload);
    const finishCallback = function async() {
      return handleFinishProcess(dataProcessed);
    };
    setDataForFinishDialog({
      finishCallback,
      confirmMessage: `The ${titleLabelLowercase} will be created based on the information provided. Are you sure you want to submit?`,
    });
  };

  /**
   * handle confirm send back workflow process
   * @param payload
   */
  const handleConfirmSendBackProcess = (payload: DTO_Workflow_CreateTitle) => {
    setDataForSendBackDialog({
      sendBackCallback: postProcessCreateTitle,
      dataSendBack: payload,
      defaultSuccessMessage: `${titleLabel} approval sent back successfully`,
      defaultErrorMessage: `Send ${titleLabelLowercase} back failed`,
    });
  };

  /**
   * handle confirm reallocate workflow process
   * @param payload
   */
  const handleConfirmReallocateProcess = (
    payload: DTO_Workflow_CreateTitle
  ) => {
    setDataForReallocateDialog({
      reallocateCallback: postProcessCreateTitle,
      dataReallocate: payload,
      defaultSuccessMessage: `${titleLabel} approved successfully`,
      defaultErrorMessage: `Approve ${titleLabelLowercase} failed`,
    });
  };

  const handleNextButton = async (data: any) => {
    const newData = processData(data);
    return await handleSaveAndNext(newData);
  };

  /**
   * common function
   * handle calling api with multiple process
   * @param props
   */
  const handleProcessWorkflow = async (
    props: IProcessWorkflow<DTO_Workflow_CreateTitle>,
    setLoading: () => void
  ) => {
    const { payload, actionSuccess, defaultFailedMessage, modeProcess } = props;
    const response = await postProcessCreateTitle(modeProcess, payload);

    setLoading();
    if (isSuccessResponse(response)) {
      if (response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    } else {
      notificationFormStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
  };

  /**
   * handle park process
   * @param payload
   */
  const handleParkProcess = (payload: DTO_Workflow_CreateTitle) => {
    //set loading button and dialog
    setIsLoadingPark(true);
    setIsLoadingOnDialog(true);

    //props send to process workflow
    const parkProps: IProcessWorkflow<DTO_Workflow_CreateTitle> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        pushNotification({
          title:
            e?.Notification ??
            e?.SuccessMessage ??
            `${titleLabel} parked successfully`,
          type: "success",
        });
      },
      defaultFailedMessage: `Park ${titleLabelLowercase} failed`,
      modeProcess: WorkflowProcessMode.Park,
    };

    const setLoading = () => {
      setIsLoadingPark(false);
      setIsLoadingOnDialog(true);
    };

    //calling api process workflow
    handleProcessWorkflow(parkProps, setLoading);
  };

  /**
   * handle approve process
   */
  const handleApproveProcess = (payload: DTO_Workflow_CreateTitle) => {
    //set loading button
    setIsLoadingApprove(true);

    //props send to process workflow
    const approveProps: IProcessWorkflow<DTO_Workflow_CreateTitle> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        clearSelectedItems();
        eventEmitter.emit(CCGridEventType.RefreshOData);
        pushNotification({
          title:
            e?.Notification ??
            `New ${titleLabelLowercase} approved successfully`,
          type: "success",
        });
      },
      defaultFailedMessage: `Approve new ${titleLabelLowercase} failed`,
      modeProcess: WorkflowProcessMode.Approve,
    };

    const setLoading = () => {
      setIsLoadingApprove(false);
    };
    //calling api process workflow
    handleProcessWorkflow(approveProps, setLoading);
  };

  /**
   * handle finish process
   * @param payload
   */
  const handleFinishProcess = async (payload: DTO_Workflow_CreateTitle) => {
    //props send to process workflow
    const finishProps: IProcessWorkflow<DTO_Workflow_CreateTitle> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        clearSelectedItems();
        eventEmitter.emit(CCGridEventType.RefreshOData);
        const notificationContent: IAppNotificationItemAddProps = {
          title:
            e?.Notification ??
            `New ${titleLabelLowercase} created successfully`,
          type: "success",
        };
        if (
          workflowInitData?.WorkflowHeader?.WorkflowApprovals?.length === 0 &&
          !isNil(e?.Component_ID)
        ) {
          history.push(`${PROPERTY_TITLES_ROUTE}/${e?.Component_ID}`, {
            notification: notificationContent,
          });
        } else {
          pushNotification(notificationContent);
        }
      },
      defaultFailedMessage: `Create new ${titleLabelLowercase} failed`,
      modeProcess: WorkflowProcessMode.Finish,
    };

    const setLoading = () => {};
    //calling api process workflow
    await handleProcessWorkflow(finishProps, setLoading);
  };

  /**
   * handle save parcel
   * @param payload
   * @param isCloseDialog
   * @returns Promise<boolean>
   */
  const handleSaveAndNext = async (
    payload: DTO_Workflow_CreateTitle,
    isCloseDialog: boolean = false,
    isRefreshWorkflowData: boolean = false
  ): Promise<boolean> => {
    setIsLoadingOnNext(true);
    //Calling process Save at next button
    const response = await postProcessCreateTitle(
      WorkflowProcessMode.Save,
      payload
    );

    setIsLoadingOnNext(false);

    //set default notification
    const defaultSuccessMessage = `${titleLabel} saved successfully`;
    const defaultFailedMessage = `The create new ${titleLabelLowercase} process could not be saved`;

    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      if (isCloseDialog) {
        onClose();
        pushNotification({
          title: response?.data?.Notification ?? defaultSuccessMessage,
          type: "success",
        });
      }
      // check is the first saving
      if (isFirstSave) {
        //set current workflowDraft Id
        setWorkflowDraftId(response?.data?.ID || 0);
        // get new data from draftId after first save
        if (officerUtilProcessing.canReloadWorkflowData(isFromActionList)) {
          setWorkflowInitData({
            WorkflowDetail: response?.data?.ReturnData?.WorkflowDetail,
            WorkflowHeader: response?.data?.ReturnData?.WorkflowHeader,
          });
        }
      }
      // TODO: Show notification after reloading the step -> enhance later
      if (isRefreshWorkflowData) {
        getWorkflowData().then(() => {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.Notification ?? defaultSuccessMessage,
              type: "success",
            });
        });
      }
      return true;
    } else {
      const showNotification = () => {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title:
              (isRefreshWorkflowData
                ? head(response?.data?.Errors)
                : response.data?.ErrorMessage) ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      };
      if (isRefreshWorkflowData) {
        getWorkflowData().then(showNotification);
      } else {
        showNotification();
      }
      return false;
    }
  };

  /**
   * handle confirm retain workflow process
   * @param payload
   */
  const handleConfirmRetainProcess = (payload: DTO_Workflow_CreateTitle) => {
    //set loading button and dialog
    setIsLoadingClose(true);

    //props send to process workflow
    const parkProps: IProcessWorkflow<DTO_Workflow_CreateTitle> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        pushNotification({
          title:
            e?.Notification ??
            e?.SuccessMessages ??
            `New ${titleLabelLowercase} was parked successfully.`,
          type: "success",
        });
      },
      defaultFailedMessage: `New ${titleLabelLowercase} could not be parked.`,
      modeProcess: WorkflowProcessMode.Park,
    };

    const setLoading = () => {
      setIsLoadingClose(false);
      setDataForCloseDialog(undefined);
    };

    //calling api process workflow
    handleProcessWorkflow(parkProps, setLoading);
  };

  const titleHeader = useMemo(() => {
    const formId = workflowInitData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id;
    const title =
      currentFormTitle(formId ?? 0) ||
      `Create ${Label.CommunityProperty.getLabel(
        ECustomColNameProperty.Title
      )}`;

    return getTitleWorkflow(
      title,
      prefixTitle,
      getSuffixTitle(
        suffixTitle,
        isToBeApprovalMode,
        workflowInitData?.WorkflowHeader?.WorkflowApprovals ?? []
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prefixTitle,
    suffixTitle,
    isToBeApprovalMode,
    workflowInitData?.WorkflowHeader,
  ]);

  const processData = (data: any) => {
    let workflowDetail: any = {};
    let workflowHeader: any = { ...workflowInitData?.WorkflowHeader };

    //Officer process data
    //field update: WD_Assessment_Group_Id
    const { WD_Assessment_Group_Id: assessmentGroupIdWD } =
      officerUtilProcessing.processData(data, EKeysOfSteps.Details);
    workflowHeader.WorkflowDraft.WD_Assessment_Group_Id = assessmentGroupIdWD;

    const sendSteps = pickBy(data, function (value, key) {
      if (keysOfSendSteps.includes(key as EKeysOfSteps)) {
        return { [key]: value };
      }
    });
    for (const [key, value] of Object.entries(sendSteps)) {
      let dataStep;
      if (
        key === EKeysOfSteps.ParcelDetails ||
        key === EKeysOfSteps.AssociatedTitle ||
        key === EKeysOfSteps.Collection
      ) {
        dataStep = [...(value ?? [])];
      } else {
        dataStep = { ...value };
      }
      if (dataStep && dataStep?._option) {
        delete dataStep._option;
      }
      if (key === EKeysOfSteps.SecondaryWorkflow) {
        secondaryWorkflowUtilProcessing.processData(
          value,
          workflowHeader,
          workflowDetail
        );
      } else {
        workflowDetail[key as keyof DTO_WorkflowDetail_CreateTitle] = dataStep;
      }
    }
    return {
      WorkflowHeader: workflowHeader,
      WorkflowDetail: workflowDetail,
    };
  };

  const getWorkflowData = async () => {
    const id = dataFromActionList?.Workflow_Draft_Id;
    notificationFormStepRef?.current?.setLoadingFormStep(true);
    return await getInitialDataTitle(id).then((response) => {
      if (Array.isArray(response)) {
        const [lovsTitle, workflowData] = response;
        if (
          isSuccessResponse(lovsTitle) &&
          isSuccessResponse(workflowData) &&
          lovsTitle?.data &&
          workflowData?.data
        ) {
          //set Lovs Data Title
          setLOVsCreateTitle(lovsTitle?.data?.dtoTitle_LOVs);
          setAssessmentCollectionStepLOVs({
            Collection_Type: lovsTitle?.data?.dtoTitle_LOVs?.Collection_Type,
          });
          if (workflowData?.data) {
            //set Create Title Init Data
            setWorkflowInitData({
              WorkflowDetail: workflowData.data?.WorkflowDetail,
              WorkflowHeader: workflowData.data?.WorkflowHeader,
            });
            if (workflowData.data?.WorkflowHeader) {
              setWorkflowDraftId(
                workflowData.data?.WorkflowHeader?.WorkflowDraft
                  ?.Workflow_Draft_Id ?? 0
              );
            }
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
        } else {
          let responseError: APIResponse<
            DTO_Title_LOVs | DTO_Workflow_CreateTitle | ResponsePacket
          > = lovsTitle;
          if (!isSuccessResponse(workflowData)) {
            responseError = workflowData;
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    });
  };

  useEffectOnce(() => {
    getWorkflowData();
  });

  return (
    <CCFormStep
      ref={notificationFormStepRef}
      listButtonId={listSubmitButton}
      onSubmit={handleSubmit}
      initialSteps={steps}
      initialValues={initialValue}
      saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
      renderForm={(renderProps: ICCFormStepRender) => {
        return (
          <CCDialog
            maxWidth="65%"
            disabled={
              isLoadingApprove ||
              isLoadingDialog ||
              isLoadingOnNext ||
              isLoadingInStep
            }
            titleHeader={titleHeader}
            onClose={() => handleCloseDialog(renderProps)}
            badge={statusBadge}
            bodyElement={renderProps.children}
            footerElement={
              <>
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={isLoadingPark ? "fas fa-spinner fa-spin" : ""}
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled || isLoadingPark
                      }
                    >
                      Park
                    </Button>
                  )}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={
                        isLoadingOnNext || isLoadingPark || isLoadingApprove
                      }
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}

                  {isToBeApprovalMode &&
                    workflowInitData?.WorkflowHeader?.OfficerCanApprove && (
                      <>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.SendBack}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Send Back
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Reallocate}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Reallocate
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Approve}
                          disabled={
                            isLoadingApprove || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                          iconClass={
                            isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                          }
                        >
                          Approve
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Reject}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {isToBeApprovalMode || isReadOnly ? (
                    !renderProps.isLastStep && (
                      <Button
                        themeColor="primary"
                        id="cc-next-step-button"
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )
                  ) : (
                    <Button
                      themeColor="primary"
                      id={renderProps.nextButton.idButton}
                      disabled={
                        isLoadingOnNext || renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingOnNext
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              </>
            }
          />
        );
      }}
    />
  );
});
