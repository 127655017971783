import { EditGISReferenceEditDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/edit-gis-reference/components/dialogs/edit/_index";
import { IEditGISReferenceEditDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/edit-gis-reference/components/dialogs/edit/models";
import { IEditGISReferenceDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/edit-gis-reference/model";
import { colGISReference } from "@app/products/property/registers/[id]/config";
import { IGISReferenceGrid } from "@app/products/property/registers/[id]/model";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { gisReferenceDataArrayToGrid } from "@app/products/property/registers/[id]/util";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface EditGISReferenceDialogProps {
  onClose: () => void;
  onSubmit: (value: any) => void;
  initialValue: IEditGISReferenceDialog;
}

export const EditGISReferenceDialog = observer(
  ({ onClose, onSubmit, initialValue }: EditGISReferenceDialogProps) => {
    const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
      []
    );
    const { register } = useRegisterStore();

    const nameOf = nameOfFactory<IEditGISReferenceDialog>();

    const nameOfGISReferenceGrid = nameOfFactory<IGISReferenceGrid>();

    const [selectedGISReferenceGrid, setSelectedGISReferenceGrid] =
      useState<IGISReferenceGrid>({});

    const [showEditGISReferenceDialog, setShowEditGISReferenceDialog] =
      useState<boolean>(false);

    const [newColGISReference, setNewColGISReference] =
      useState<IColumnFields[]>();

    const editGISReferenceGrid = (
      reference: string,
      formRenderProps: FormRenderProps
    ) => {
      const { valueGetter, onChange } = formRenderProps;
      if (valueGetter(nameOf("GISReferenceGrid"))) {
        const index: number = valueGetter(
          nameOf("GISReferenceGrid")
        )?.findIndex(
          (data: IGISReferenceGrid) =>
            data.GISReference === selectedGISReferenceGrid.GISReference
        );
        const newGISReference = valueGetter(nameOf("GISReferenceGrid"));
        newGISReference[index] = { GISReference: reference };

        onChange(nameOf("GISReferenceGrid"), { value: newGISReference });
      }
    };

    const checkExistedGISReference = (
      reference: string,
      formRenderProps: FormRenderProps
    ) => {
      const { valueGetter } = formRenderProps;
      if (
        register?.GISReference &&
        gisReferenceDataArrayToGrid(register.GISReference).find(
          (data: IGISReferenceGrid) => data.GISReference === reference
        )
      ) {
        return true;
      } else if (
        valueGetter(nameOf("GISReferenceGrid"))?.find(
          (data: IGISReferenceGrid) => data.GISReference === reference
        )
      ) {
        return true;
      }
      return false;
    };

    useEffectOnce(() => {
      const newColumns = colGISReference.map((itemField: IColumnFields) => {
        if (itemField.field !== "GISReference") return itemField;
        return {
          ...itemField,
          handleOnClick: (dataItem: IGISReferenceGrid) => {
            setSelectedGISReferenceGrid(dataItem);
            setShowEditGISReferenceDialog(true);
          },
        };
      });
      setNewColGISReference(newColumns);
    });

    const handleOnEdit = (
      reference: string,
      formRenderProps: FormRenderProps
    ) => {
      editGISReferenceGrid(reference, formRenderProps);
      setShowEditGISReferenceDialog(false);
    };

    const handleOnSubmit = (
      data: IEditGISReferenceEditDialog,
      formRenderProps: FormRenderProps
    ) => {
      if (data.GISReference) {
        if (checkExistedGISReference(data.GISReference!, formRenderProps)) {
          setNotifications([
            {
              id: "0",
              title: "GIS reference already exists.",
              type: "warning",
            },
          ]);
        } else {
          handleOnEdit(data.GISReference!, formRenderProps);
        }
      }
    };

    // TODO after intergrade API
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValue}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onSubmit } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                width={"25%"}
                height="auto"
                onClose={onClose}
                titleHeader={"Edit GIS Reference"}
                bodyElement={
                  <React.Fragment>
                    {showEditGISReferenceDialog && (
                      <EditGISReferenceEditDialog
                        initialValues={selectedGISReferenceGrid}
                        onClose={() => {
                          setShowEditGISReferenceDialog(false);
                          setNotifications([]);
                        }}
                        onSubmit={(data: IEditGISReferenceEditDialog) => {
                          handleOnSubmit(data, formRenderProps);
                        }}
                        defaultNotifications={notifications}
                      />
                    )}
                    <div className="cc-form">
                      <div className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <label className="cc-label">GIS reference</label>
                          <CCGrid
                            className="cc-gis-reference-grid-data"
                            data={valueGetter(nameOf("GISReferenceGrid")) ?? []}
                            columnFields={newColGISReference ?? []}
                            primaryField={nameOfGISReferenceGrid(
                              "GISReference"
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button className={"cc-dialog-button"} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={onSubmit}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
