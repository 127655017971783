import { ConfirmDeleteLeakageDialog } from "@app/products/property/meters/[id]/components/action-bar/dialogs/confirm-delete-leakage/_index";
import { useMeterLeakageStore } from "@app/products/property/meters/[id]/components/child-screens/leakage/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
export const RemoveLeakageButton = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { selectedLeakage } = useMeterLeakageStore();
  return (
    <>
      <CCNavButton
        title={"Remove"}
        disabled={!(selectedLeakage?.length > 0)}
        onClick={() => {
          setShowDialog(true);
        }}
      />
      {showDialog && (
        <ConfirmDeleteLeakageDialog setShowDialog={setShowDialog} />
      )}
    </>
  );
});
