import { PremiseFormElement } from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/_index";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useFlexibleFormState } from "@common/stores/flexible-form/useFlexibleFormState";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const PremiseForm = observer(() => {
  const { middlewareSubmitForm } = useFlexibleFormStore();
  const { setFormRef, initialDataForm } = useFlexibleFormState();
  const { pushNotificationPortal } = useNotificationPortalStore();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { whenNotModified, whenNotValid } = middlewareSubmitForm(event);
    whenNotValid(() => {
      pushNotificationPortal({
        title: "Please enter all required information.",
        type: "warning",
      });
    });
    whenNotModified(() => {
      pushNotificationPortal({
        title: "The form is not modified.",
        type: "warning",
      });
    });
  };

  return (
    <div className="cc-form">
      <Form
        ref={setFormRef}
        onSubmitClick={handleOnSubmit}
        initialValues={initialDataForm}
        key={JSON.stringify(initialDataForm)}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <PremiseFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
