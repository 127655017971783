import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colInterestedPartiesAll: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.ApplicationNumber,
    title: "Application Number",
    width: 200,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.IPAddress,
    title: "Property Address",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.IPPostalAddress,
    title: "Mailing Address",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.IPName,
    title: "Name",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.IPCompany,
    title: "Company",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Attachment_Date,
    title: "Last Letter Sent",
    width: 200,
    format: DATE_FORMAT.DATE,
    dataType: "date",
  },
  {
    field: TownPlanningFieldMapping.Attachment_Title,
    title: "Last Letter Title",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.SiteAddress,
    title: "Application Address",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Locality,
    title: "Application Locality",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.IPCategory,
    title: "Category",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.IPType,
    title: "Type",
    width: 200,
  },
];
