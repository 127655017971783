import { actionsRoute } from "@app/products/town-planning/ppr/actions/route";
import { enquiriesRoute } from "@app/products/town-planning/ppr/enquiries/route";
import { fiRequestsRoute } from "@app/products/town-planning/ppr/fi-requests/route";
import { permitAppealsRoute } from "@app/products/town-planning/ppr/permit-appeals/route";
import { permitReferralsRoute } from "@app/products/town-planning/ppr/permit-referrals/route";
import { plansToComplyRoute } from "@app/products/town-planning/ppr/plans-to-comply/route";
import { psaReferralsRoute } from "@app/products/town-planning/ppr/psa-referrals/route";
import { systemAdminRoute } from "@app/products/town-planning/ppr/system-admin/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";
export const pprRoute: ICCRoute = {
  path: "town-planning/ppr",
  name: "PPR",
  enum: eProductType.TownPlanning,
  children: [
    enquiriesRoute,
    permitReferralsRoute,
    permitAppealsRoute,
    fiRequestsRoute,
    psaReferralsRoute,
    plansToComplyRoute,
    actionsRoute,
    systemAdminRoute,
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
