import {
  mockParkingPermit,
  mockParkingPermitRecords,
} from "@app/products/parking-permits/mock";
import { sleep } from "@common/utils/common";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { cloneDeep } from "lodash";
import { IParkingPermits, ParkingPermits } from "./model";

export const loadAllParkingPermits = (): IParkingPermits[] => {
  const parkingPermit: IParkingPermits[] = JSON.parse(
    JSON.stringify(mockParkingPermit)
  );
  return parkingPermit;
};

export const postParkingPermits = async (requestBody: ParkingPermits) => {
  await sleep(2000);
};

export const putParkingPermits = async (requestBody: ParkingPermits) => {
  await sleep(2000);
};

export const loadParkingPermitsRecords = async (
  id: number,
  recordType: number
) => {
  try {
    await sleep(2000);
    const response = cloneDeep(mockParkingPermitRecords);
    return response;
  } catch (error) {
    appNotificationStore.pushNotification({
      title: "Related records load failed",
      type: "error",
      autoClose: false,
    });
  }
};
