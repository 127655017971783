import { ICCRoute } from "@common/constants/ICCRoute";

export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  children: [
    {
      path: "permit-type",
      name: "Permit Type",
      component: require("./permit-type/_index").default,
    },
    {
      path: "resident-area",
      name: "Resident Area",
      component: require("./resident-area/_index").default,
    },
    {
      path: "staff-area",
      name: "Staff Area",
      component: require("./staff-area/_index").default,
    },
    {
      path: "property-restrictions",
      name: "Property Restrictions",
      component: require("./property-restrictions/_index").default,
    },
    {
      path: "property-exceptions",
      name: "Property Exceptions",
      component: require("./property-exceptions/_index").default,
    },
    {
      path: "export-data",
      name: "Export Data",
      component: require("./export-data/_index").default,
    },
    {
      path: "inspections",
      name: "Inspections",
      component: require("./inspections/_index").default,
    },
    {
      path: "settings",
      name: "Settings",
      component: require("./settings/_index").default,
    },
  ],
};
