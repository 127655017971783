import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const annualLandStockReturnsRoute: ICCRoute = {
  path: "annual-land-stock-returns",
  name: "Annual Land Stock Return",
  enum: eMenuAction.Annual_Land_Stock_Return_Folder,
  children: [
    {
      path: "import-files",
      name: "Import Files",
      enum: eMenuAction.Inbox,
      component: require("./import-files/_index").default,
    },
    {
      path: "staged-returns-list",
      name: "Staged Returns",
      enum: eMenuAction.Staged_Returns,
      component: require("./staged-returns/_index").default,
    },
    {
      path: "return-list",
      name: "Returns",
      component: require("./returns/_index").default,
      enum: eMenuAction.Returns,
    },
  ],
};
