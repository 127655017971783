import { getUUID } from "@common/utils/common";

export const DocumentStepEventType = {
  RefreshData: getUUID(),
};

export const DocumentCategory = {
  File: "File",
  Weblink: "WebLink",
};
