import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const eCertificateRequestsRoute: ICCRoute = {
  path: "e-certificate-requests",
  name: "eCertificate Requests",
  enum: eMenuAction.Certificate_List_eCertificate_Requests,
  children: [
    {
      path: "cancelled",
      name: "Cancelled",
      enum: eMenuAction.Certificate_List_eCertificate_Requests_Cancelled,
      component: require("./cancelled/_index").default,
    },
    {
      path: "completed",
      name: "Completed",
      enum: eMenuAction.Certificate_List_eCertificate_Requests_Completed,
      component: require("./completed/_index").default,
    },
    {
      path: "in-error",
      name: "In Error",
      enum: eMenuAction.Certificate_List_eCertificate_Requests_InError,
      component: require("./in-error/_index").default,
    },
    {
      path: "in-progress",
      name: "In Progress",
      enum: eMenuAction.Certificate_List_eCertificate_Requests_InProgress,
      component: require("./in-progress/_index").default,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
