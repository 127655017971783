import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { getNoticeTypeById } from "@common/pages/settings/lookups/notice-types/_id/api";
import { NoticeType } from "@common/pages/settings/lookups/notice-types/_id/model";
import { useNoticeTypesStore } from "@common/pages/settings/lookups/notice-types/_id/store";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const LookupNoticeTypesTabDetails = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { noticeType } = useNoticeTypesStore();
  const [isLoading, setIsLoading] = useState(false);
  const [noticeTypeTabDetailsInfo, setNoticeTypeTabDetailsInfo] =
    useState<NoticeType>();
  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();
  const noticeTypeID = noticeType?.NoticeType_ID ?? lastSelectedRow?.ID;

  const loadDetailData = async () => {
    setIsLoading(true);
    const response = await getNoticeTypeById(noticeTypeID);
    if (isSuccessResponse(response)) {
      setNoticeTypeTabDetailsInfo(response?.data?.ReturnObj);
    } else {
      setResponseLoadError({
        status: response?.status ?? APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: response?.error ?? "Load notice type detail failed.",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!noticeTypeID) return;
    loadDetailData();
    // eslint-disable-next-line
  }, [noticeType, noticeTypeID]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => loadDetailData()}
      />
    );
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Name:"}
          value={noticeTypeTabDetailsInfo?.Name}
        />
        <ProductReferenceRow
          title={"Description:"}
          value={noticeTypeTabDetailsInfo?.Description}
        />
        <ProductReferenceRow
          title={"Classification:"}
          value={noticeTypeTabDetailsInfo?.NoticeClassification_Name}
        />
        <ProductReferenceRow
          title={"FSR code:"}
          value={noticeTypeTabDetailsInfo?.FSRCode_Name}
        />
        <ProductReferenceRow
          title={"Code:"}
          value={noticeTypeTabDetailsInfo?.Code}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
