import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButton } from "@app/core/mail-merge/buttons/_index";
import { getAllCRS } from "@app/products/crs/api";
import { NewApplication } from "@app/products/crs/components/action-bar/buttons/new-application/_index";
import { CrsActionBarNavDropdown } from "@app/products/crs/components/action-bar/nav-dropdown/_index";
import { crsRoute } from "@app/products/crs/route";
import {
  getCRSBookmarkDetail,
  getCRSBookmarkDisplayName,
} from "@app/products/crs/util";
import { CrsContactsTab } from "@app/products/crs/[id]/components/reference-sidebar/contacts/_index";
import { CrsDetailsTab } from "@app/products/crs/[id]/components/reference-sidebar/details/_index";
import { CrsHistoryTab } from "@app/products/crs/[id]/components/reference-sidebar/history/_index";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { feesRoute } from "../route";
import { colCRSFees4yoDeposit } from "./config";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "./util";

export default () => {
  useCCListViewActionBar({
    title: crsRoute.name,
    leftComponents: [<CrsActionBarNavDropdown category={feesRoute.path} />],
    centerComponents: [
      <NewApplication />,
      <MailMergeButton />,
      <CommunicationButtonListView />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CRS_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.CRS}
        recordType={RECORDTYPE.CRS_Registration} //@TODO missing RecordType for Fees list recheck later
        detail={getCRSBookmarkDisplayName}
        displayName={getCRSBookmarkDetail}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CrsDetailsTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <CrsContactsTab /> },
      {
        title: "History",
        component: <CrsHistoryTab />,
      },
    ],
  });
  return (
    <CCProductListView
      columnFields={colCRSFees4yoDeposit}
      data={getAllCRS() as any}
      // dataUrl={}
      primaryField="ID"
    />
  );
};
