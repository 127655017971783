import { gridIdDetailAssessmentId } from "@app/products/property/assessments/collections/components/detail-assessment/_index";
import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { ReAllocationOfCreditsButton } from "@app/products/property/assessments/components/action-bar/buttons/re-allocation-of-credits/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const ReAllocationOfCreditsButtonAtCollections = observer(() => {
  const { gridSelectedIds } = useDetailAssessmentListViewStore();
  return (
    <ReAllocationOfCreditsButton
      gridId={gridIdDetailAssessmentId}
      assessmentIds={gridSelectedIds}
    />
  );
});
