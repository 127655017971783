import { TransferDetail } from "@app/products/property/assessments/[id]/components/child-screens/transfer/components/detail/_index";
import { colTransfer } from "@app/products/property/assessments/[id]/components/child-screens/transfer/config";
import { DTO_ChangeOfOwnershipProcessed } from "@app/products/property/assessments/[id]/components/child-screens/transfer/model";
import { usePICTransferStore } from "@app/products/property/pic/[id]/components/child-screens/transfer/store";
import { usePicStore } from "@app/products/property/pic/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";

const nameOf = nameOfFactory<DTO_ChangeOfOwnershipProcessed>();
export const PICTransfer = observer(() => {
  const { picId } = usePicStore();
  const {
    isLoading,
    transfer,
    transferSelected,
    responseLoadError,
    setTransferSelected,
    loadTransfer,
    resetStore,
  } = usePICTransferStore();

  useEffect(() => {
    if (!picId) return;
    loadTransfer(picId);
    return () => {
      resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picId]);

  const handleSelectedTransfer = useCallback(
    (dataItems: DTO_ChangeOfOwnershipProcessed[]) => {
      setTransferSelected(dataItems?.[0]);
    },
    [setTransferSelected]
  );
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          if (!picId) return;
          loadTransfer(picId);
        }}
      />
    );
  return (
    <CCGrid
      isLoading={isLoading}
      className="cc-pic-transfer-grid"
      data={transfer ?? []}
      columnFields={colTransfer}
      primaryField={nameOf("Id")}
      isLimitDetailWidth
      selectableMode="single"
      detail={TransferDetail}
      onSelectionChange={handleSelectedTransfer}
      selectedRows={transferSelected ? [{ ...transferSelected }] : []}
    />
  );
});
