import { NOTICES_ROUTE } from "@app/core/notices/[id]/constant";
import { nameOfLLNoticesView } from "@app/products/local-laws/compliance-notices/constant";
import { Svc_LL_Notice } from "@app/products/local-laws/compliance-notices/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colLocalLawsComplianceNoticesByDueStatus: IColumnFields[] = [
  {
    field: nameOfLLNoticesView("ReferenceNumber"),
    title: "Number",
    locked: true,
    linkTo: (dataItem: Svc_LL_Notice) => `${NOTICES_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOfLLNoticesView("DateServed"),
    title: "Date Served",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfLLNoticesView("Type"),
    title: "Notice Type",
  },
  {
    field: nameOfLLNoticesView("Officer"),
    title: "Officer",
  },
  {
    field: nameOfLLNoticesView("DateDue"),
    title: "Compliance Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfLLNoticesView("DateRevoked"),
    title: "Complied / Revoked",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfLLNoticesView("DueStatus"),
    title: "Status",
  },
];
