import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { DescriptionLabel } from "@components/description-label/DescriptionLabel";
import { SettingButtonEdit } from "@components/setting/setting-button-edit/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import React from "react";
import "./_index.scss";
interface ISettingPercentInputProps {
  name: string;
  value?: number | null;
  label: string;
  disabled?: boolean;
  maxDecimalPlaces?: number;
  onChange: (arg0: NumericTextBoxChangeEvent) => void;
  handleOnEdit?: any;
  handleOnCancel?: any;
  description?: string;
  isMandatory?: boolean;
  isEnabledEditButton?: boolean;
  formRenderProps?: FormRenderProps;
  isLoading?: boolean;
}

export const SettingPercentInput: React.FC<ISettingPercentInputProps> = ({
  name,
  label,
  value = null,
  disabled,
  onChange,
  maxDecimalPlaces = 2,
  description = "",
  isMandatory,
  isEnabledEditButton,
  handleOnCancel = () => {},
  handleOnEdit = () => {},
  formRenderProps = undefined,
  isLoading,
  ...others
}) => {
  const formatOptions = "p" + maxDecimalPlaces;
  return (
    <div className="cc-setting-percent-input">
      <div className="cc-label-flex">
        <DescriptionLabel title={label} description={description} />
        {isMandatory && <CCTooltip type="validator" position="right" />}
      </div>
      <div className={`${isEnabledEditButton ? "cc-field-flex" : ""}`}>
        <div className="cc-numeric-percent">
          <CCNumericTextBox
            name={name}
            value={value ? value / 100 : value}
            disabled={disabled}
            onChange={onChange}
            format={formatOptions}
            {...others}
          />
        </div>
        {isEnabledEditButton ? (
          <SettingButtonEdit
            isEditing={!disabled}
            handleOnEdit={handleOnEdit}
            handleOnCancel={handleOnCancel}
            formRenderProps={formRenderProps}
            isLoading={isLoading}
          />
        ) : null}
      </div>
    </div>
  );
};
