import { getViewConfigurations } from "@app/products/property/api";
import { IFilterSearch } from "@app/products/property/components/dialogs/add-assessment-lookup/model";

import { getSearchParcelLookup } from "@app/products/property/components/dialogs/add-parcel-lookup/api";
import {
  colParcelLookup,
  eOptionSearchParcelLookup,
  parcelSearchByList,
} from "@app/products/property/components/dialogs/add-parcel-lookup/config";
import { DTO_Parcel } from "@app/products/property/components/dialogs/add-parcel-lookup/model";
import { isNumberModeParcelSearch } from "@app/products/property/components/dialogs/add-parcel-lookup/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ComboboxSearchMessage } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/components/combobox-search-api/config";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { useDebounce } from "@common/hooks/useDebounce";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { CancelTokenSource } from "axios";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
interface IAddParcelLookupDialogProps {
  onClose: () => void;
  handleAddParcel: (data: DTO_Parcel[]) => void;
  isLoadingFinish?: boolean;
}
const nameOf = nameOfFactory<DTO_Parcel>();
export const AddParcelLookupDialog = observer(
  ({
    onClose,
    handleAddParcel,
    isLoadingFinish = false,
  }: IAddParcelLookupDialogProps) => {
    //Get labels
    const legalDescriptionLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Legal_Description
    );

    const cancelRequest = useRef<CancelTokenSource>();
    const [filter, setFilter] = useState<IFilterSearch>({
      searchValue: "",
      searchBy: parcelSearchByList[0],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);
    const [processedColumns, setProcessedColumns] =
      useState<IColumnFields[]>(colParcelLookup);
    const [isSearching, setIsSearching] = useState(false);
    const [searchResult, setSearchResult] = useState<DTO_Parcel[]>([]);
    const [selectedParcel, setSelectedParcel] = useState<DTO_Parcel[]>([]);
    const [processedParcelSearchByList, setProcessedParcelSearchByList] =
      useState<DTO_LOV_Number[]>(parcelSearchByList);

    const debouncedSearch = useDebounce(filter?.searchValue ?? "", 500);
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    useEffect(() => {
      (async () => {
        if (
          !isNil(debouncedSearch) &&
          filter &&
          filter?.searchValue?.length >= 1 &&
          !isNil(filter?.searchBy?.Code)
        ) {
          setIsSearching(true);
          const response = await getSearchParcelLookup(
            {
              LookupKey: filter?.searchBy?.Code,
              LookupValue: debouncedSearch,
              Statuses: [0] /* default Active */,
            },
            cancelRequest.current
          );
          setIsSearching(false);
          if (isSuccessResponse(response) && response.data) {
            setSearchResult(response.data?.Parcels ?? []);
          } else if (response.error !== ComboboxSearchMessage) {
            notificationRef.current?.setNotifications([
              {
                title: response.error ?? "Parcel lookup search failed",
                type: "error",
                autoClose: false,
                id: "1",
              },
            ]);
          }
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearch, filter?.searchBy?.Code]);

    const loadConfigurationView = () => {
      setIsLoading(true);
      getViewConfigurations(ViewConfiguration.ParcelSearch).then((response) => {
        if (isSuccessResponse(response)) {
          const viewConfig: ICCViewColumn[] | undefined =
            response?.data?.ColumnDefinitions?.Columns;
          if (!viewConfig || viewConfig?.length === 0) {
            setProcessedColumns([]);
            setIsLoading(false);
            return;
          }
          setProcessedColumns(
            processDynamicColumns(colParcelLookup, viewConfig)
          );
        } else {
          setResponseLoadError({
            status: response.status,
            error: response.error ?? "Load failed",
          });
        }
        setIsLoading(false);
      });
    };

    const processParcelSearchByList = () => {
      const newParcelSearchByList = parcelSearchByList.map(
        (searchByItem: DTO_LOV_Number) => {
          switch (searchByItem.Code) {
            case eOptionSearchParcelLookup.LegalDescription:
              return {
                ...searchByItem,
                Name: legalDescriptionLabel ?? searchByItem.Name,
              };
            default:
              return searchByItem;
          }
        }
      );
      setProcessedParcelSearchByList(newParcelSearchByList);
    };

    useEffectOnce(() => {
      processParcelSearchByList();
      loadConfigurationView();
    });

    return (
      <Form
        onSubmitClick={() => handleAddParcel(selectedParcel)}
        initialValues={{
          ParcelSearchBy: parcelSearchByList[0],
        }}
        render={(formRenderProps: FormRenderProps) => (
          <CCDialog
            titleHeader="Parcel Lookup"
            maxWidth="50%"
            height="auto"
            onClose={onClose}
            disabled={isLoading || isLoadingFinish}
            bodyElement={
              <FormElement className="cc-form">
                <section className="cc-field-group">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Search by" />
                      <Field
                        name={"ParcelSearchBy"}
                        placeholder={"Search by"}
                        component={CCDropDownList}
                        data={processedParcelSearchByList}
                        textField={"Name"}
                        dataItemKey={"Code"}
                        onChange={(event: DropDownListChangeEvent) => {
                          setFilter((pre: IFilterSearch) => {
                            return {
                              searchValue: "",
                              searchBy: event?.value,
                            };
                          });
                          formRenderProps.onChange("ParcelSearchBy", {
                            value: event.value,
                          });
                          formRenderProps.onChange("ParcelSearchValue", {
                            value: "",
                          });
                        }}
                      />
                    </div>
                    <div className="cc-field cc-col-span-2">
                      <label className="cc-label">Search value</label>
                      <Field
                        name={"ParcelSearchValue"}
                        placeholder={"Search value"}
                        component={CCInput}
                        type={
                          isNumberModeParcelSearch(filter?.searchBy?.Code)
                            ? "number"
                            : "string"
                        }
                        onChange={(event: InputChangeEvent) => {
                          setFilter((pre: IFilterSearch) => {
                            return { ...pre, searchValue: event?.value };
                          });
                          formRenderProps.onChange("ParcelSearchValue", {
                            value: event.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-1">
                    {isLoading ? (
                      <Loading isLoading />
                    ) : responseLoadError ? (
                      <CCLoadFailed
                        responseError={responseLoadError}
                        onReload={() => {
                          loadConfigurationView();
                        }}
                      />
                    ) : (
                      <div className="cc-field">
                        <CCGrid
                          columnFields={processedColumns ?? []}
                          primaryField={nameOf("Parcel_Id")}
                          data={searchResult ?? []}
                          isLoading={isSearching}
                          selectableMode="multiple"
                          onSelectionChange={(dataItem: DTO_Parcel[]) => {
                            setSelectedParcel(dataItem);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </section>
              </FormElement>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  iconClass={isLoadingFinish ? "fas fa-spinner fa-spin" : ""}
                  themeColor="primary"
                  onClick={formRenderProps.onSubmit}
                  type="submit"
                  disabled={
                    !formRenderProps.valid ||
                    !formRenderProps.modified ||
                    selectedParcel?.length === 0
                  }
                >
                  OK
                </Button>
              </div>
            }
          />
        )}
      />
    );
  }
);
