import { getLineageAccordion } from "@app/products/property/components/lineage-accordion/api";
import { lineageDefaultCols } from "@app/products/property/components/lineage-accordion/config";
import {
  DTO_Component_Lineage_DataApi,
  DTO_Component_Lineage_DataApiTree,
} from "@app/products/property/components/lineage-accordion/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { isNil } from "lodash";
import React, { useEffect, useMemo, useState } from "react";

interface ILineageAccordionProps {
  apiUrl: string;
  redirectUrl?: string;
  lineageCols?: IColumnFields[];
}
const nameOf = nameOfFactory<DTO_Component_Lineage_DataApiTree>();
export const LineageAccordion = ({
  apiUrl,
  redirectUrl,
  lineageCols = lineageDefaultCols,
}: ILineageAccordionProps) => {
  const [lineageData, setLineageData] =
    useState<DTO_Component_Lineage_DataApi>();
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const newColsLineage = useMemo(() => {
    if (isNil(redirectUrl)) return lineageCols;
    return lineageCols.map((col: IColumnFields) => {
      if (col.field === nameOf("Number")) {
        col.linkTo = (dataItem: DTO_Component_Lineage_DataApiTree) =>
          `${redirectUrl}/${dataItem?.Id}`;
      }
      return col;
    });
  }, [lineageCols, redirectUrl]);

  const getDataLineageAccordion = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await getLineageAccordion(apiUrl);
    if (isSuccessResponse(response) && response?.data) {
      setLineageData(response?.data);
    } else {
      setResponseLoadError({
        status: response?.status,
        error: response?.error ?? "Load lineage data failed",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => await getDataLineageAccordion())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl]);

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          getDataLineageAccordion();
        }}
      />
    );
  }

  return (
    <div className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Parents" />
          <CCGrid
            data={lineageData?.LineageParents ?? []}
            columnFields={newColsLineage ?? []}
            primaryField={nameOf("Id")}
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Children" />
          <CCGrid
            data={lineageData?.LineageChildren ?? []}
            columnFields={newColsLineage ?? []}
            primaryField={nameOf("Id")}
          />
        </div>
      </div>
    </div>
  );
};
