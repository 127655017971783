import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { ChangeStatus } from "@app/products/town-planning/ppr/enquiries/components/action-bar/buttons/batch-complete/model";
import { putBatchStatusEnquiry } from "@app/products/town-planning/ppr/enquiries/components/action-bar/buttons/update-status/api";
import { PPREnquiryUpdateStatusDialog } from "@app/products/town-planning/ppr/enquiries/_id/components/dialogs/change-status/_index";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const PPREnquiryUpdateStatusButton = observer(() => {
  const { gridSelectedIds } = useCCProductListViewStore();
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();

  const [isUpdating, setIsUpdating] = useState(false);
  const [showUpdateStatusDialog, setShowUpdateStatusDialog] = useState(false);

  const handleOnSubmit = async (data?: ChangeStatus) => {
    if (isEmpty(gridSelectedIds) || !data?.RegisterStatus) return;
    setIsUpdating(true);

    const resUpdateStatus = await putBatchStatusEnquiry({
      ...data,
      RegisterIDs: gridSelectedIds,
    });
    setIsUpdating(false);
    setShowUpdateStatusDialog(false);
    if (isSuccessIdentityPacket(resUpdateStatus)) {
      clearErrorNotification();
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      pushNotification({
        type: "success",
        title: "Status changed successfully",
      });
    } else {
      pushNotification({
        title: "Change status failed",
        type: "error",
        autoClose: false,
        description: resUpdateStatus.data?.Errors ?? resUpdateStatus.statusText,
      });
    }
  };

  return (
    <>
      <CCNavButton
        title="Update Status"
        onClick={() => setShowUpdateStatusDialog(true)}
        disabled={isEmpty(gridSelectedIds)}
      />
      {showUpdateStatusDialog && (
        <PPREnquiryUpdateStatusDialog
          isUpdating={isUpdating}
          onClose={() => setShowUpdateStatusDialog(false)}
          onSubmit={handleOnSubmit}
        />
      )}
    </>
  );
});
