import { getParcelConstraints } from "@app/products/property/parcels/[id]/components/child-screens/constraints/api";
import { mockParcelConstraints } from "@app/products/property/parcels/[id]/components/child-screens/constraints/mock";
import { DTO_Parcel_Constraints } from "@app/products/property/parcels/[id]/components/child-screens/constraints/model";
import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

class ParcelsConstraintsStore {
  private _constraints?: DTO_Parcel_Constraints = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get constraints() {
    return this._constraints;
  }
  setConstraints = (constraints?: DTO_Parcel_Constraints) => {
    runInAction(() => {
      this._constraints = constraints;
    });
  };

  //Action

  resetStore = () => {
    runInAction(() => {
      this._constraints = undefined;
      this._isLoading = false;
    });
  };

  loadConstraints = async (id: number) => {
    this.setIsLoading(true);
    const newConstraints = await getParcelConstraints(id);
    // this.setConstraints(newConstraints);
    /* ====  TODO: Write tmp mock for demo  ==== */
    this.setConstraints({
      ...newConstraints,
      Constraints: mockParcelConstraints,
    } as DTO_Parcel_Constraints);
    /* ====  /TODO: Write tmp mock for demo  ==== */
    this.setIsLoading(false);
  };
}

const parcelsConstraintsStoreContext = createContext(
  new ParcelsConstraintsStore()
);
export const useParcelsConstraintsStore = () => {
  return useContext(parcelsConstraintsStoreContext);
};
