import { JournalStatus } from "@app/products/property/journals/model";
import { postReverseJournal } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/reverse-journal/api";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import { useJournalStore } from "@app/products/property/journals/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
interface IReverseJournalProps {
  disabled?: boolean;
}

export const ReverseJournalButton = observer(
  ({ disabled = false }: IReverseJournalProps) => {
    const history = useHistory();
    const { journal } = useJournalStore();
    const { gridSelectedRows } = useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();
    const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
    const [itemIdToBeUpdated, setItemIdToBeUpdated] = useState<
      number | undefined
    >(undefined);
    const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);

    useEffect(() => {
      if (journal) {
        if (journal.Journal_Status === JournalStatus.Authorised) {
          setItemIdToBeUpdated(journal.Journal_Number);
        }
      } else {
        if (
          gridSelectedRows?.length === 1 &&
          gridSelectedRows[0]?.Journal_Status_Id === JournalStatus.Authorised
        ) {
          setItemIdToBeUpdated(gridSelectedRows[0]?.Journal_Number);
        } else {
          setItemIdToBeUpdated(undefined);
        }
      }
    }, [gridSelectedRows, journal]);

    const handleSubmit = async () => {
      if (!itemIdToBeUpdated) return;
      setIsLoadingDialog(true);
      const defaultSuccessMessage = "Reverse journal was successful";
      const defaultErrorMessage = "Reverse journal failed";
      const response = await postReverseJournal(itemIdToBeUpdated);
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        history.push(`${PROPERTY_JOURNALS_ROUTE}/${response?.data?.ID}`);
        pushNotification({
          title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
          type: "success",
        });
      } else {
        pushNotification({
          title: response?.data?.ErrorMessage ?? defaultErrorMessage,
          type: "error",
          autoClose: false,
        });
      }
      setIsLoadingDialog(false);
      setIsShowDialog(false);
    };

    return (
      <>
        <CCNavButton
          title="Reverse"
          disabled={disabled || !itemIdToBeUpdated}
          onClick={() => {
            setIsShowDialog(true);
          }}
        />
        {isShowDialog && (
          <ConfirmDialog
            onClosePopup={() => {
              setIsShowDialog(false);
            }}
            title="Reverse Journal"
            subMessage="Are you sure you wish to reverse this journal?"
            btnNoTitle="Cancel"
            onAsyncConfirm={() => {
              handleSubmit();
            }}
            isLoadingYes={isLoadingDialog}
          />
        )}
      </>
    );
  }
);
