// import region from "@app/products/town-planning/ppr/system-admin/regions/[id]/_index";
import { ICCRoute } from "@common/constants/ICCRoute";
import { actionTypesRoute } from "@common/pages/settings/lookups/action-types/route";
import { documentsRoute } from "@common/pages/settings/lookups/documents/route";
import { dynamicListsRoute } from "@common/pages/settings/lookups/dynamic-lists/route";
import { feeTypeRoute } from "@common/pages/settings/lookups/fee-types/route";
import { inspectionTypeRoute } from "@common/pages/settings/lookups/inspection-types/route";
import { keywordsRoute } from "@common/pages/settings/lookups/keywords/route";
import { localGovernmentRoute } from "@common/pages/settings/lookups/local-government/route";
import { noticeTypesRoute } from "@common/pages/settings/lookups/notice-types/route";
import { publicHolidaysRoute } from "@common/pages/settings/lookups/public-holidays/route";
import { regionsRoute } from "@common/pages/settings/lookups/regions/route";
import { stateRoadsRoute } from "@common/pages/settings/lookups/state-roads/route";
import { helpDocumentRoute } from "@common/pages/settings/lookups/help-documents/route";

export const lookupsRoute: ICCRoute = {
  path: "lookups",
  name: "Lookups",
  children: [
    keywordsRoute,
    documentsRoute,
    {
      path: "document-templates",
      name: "Document Templates",
      component: require("./document-templates/_index").default,
    },
    helpDocumentRoute,
    {
      path: "localities",
      name: "Localities",
      component: require("./localities/_index").default,
    },
    actionTypesRoute,
    feeTypeRoute,
    inspectionTypeRoute,
    {
      path: "sample-types",
      name: "Sample Types",
      component: require("./sample-types/_index").default,
    },
    {
      path: "complaint-types",
      name: "Complaint Types",
      component: require("./complaint-types/_index").default,
    },
    noticeTypesRoute,
    {
      path: "interview-types",
      name: "Interview Types",
      component: require("./interview-types/_index").default,
    },
    publicHolidaysRoute,
    {
      path: "property-alerts",
      name: "Property Alerts",
      component: require("./property-alerts/_index").default,
    },
    localGovernmentRoute,
    regionsRoute,
    {
      path: "work-flows",
      name: "Work Flows",
      component: require("./work-flows/_index").default,
    },
    stateRoadsRoute,
    dynamicListsRoute,
    {
      path: "divisions",
      name: "Divisions",
      component: require("./divisions/_index").default,
    },
  ],
};
