import { DTO_PIC_Summary } from "@app/products/property/pic/[id]/model";
import { DTO_PIC_LOVs } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IGetPICGeneral =
  | [APIResponse<DTO_PIC_Summary>, APIResponse<DTO_PIC_LOVs>]
  | APIResponse<DTO_PIC_Summary | DTO_PIC_LOVs>;
export const getPicSummaryById = async (
  picId: number
): Promise<IGetPICGeneral> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_PIC_Summary>(
        `/api/property/int/pic/${picId}/summary`
      ),
      CoreAPIService.getClient().get<DTO_PIC_LOVs>(`api/property/int/pic/lovs`),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
