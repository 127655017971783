import { DTO_DocumentType } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/model";
import { DTO_Document } from "@app/products/property/components/child-screen/documents/model";
import {
  DATE_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Document>();
const nameOfDocumentType = nameOfFactory<DTO_DocumentType>();

export const colDocuments: IColumnFields[] = [
  {
    field: nameOf("Title"),
    title: "Title",
  },
  {
    field: nameOf("FileName"),
    title: "File Name",
  },
  {
    field: nameOf("DocumentType"),
    title: "Type",
  },
  {
    field: nameOf("FileSize"),
    title: "File Size",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("FileNumber"),
    title: "File Number",
  },
  {
    field: nameOf("CreatedOn"),
    title: "Created On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CreatedBy"),
    title: "Created By",
  },
  {
    field: nameOf("ID"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
export const colDocumentTypes: IColumnFields[] = [
  {
    field: nameOfDocumentType("Document_Type"),
    title: "Name",
  },
  {
    field: nameOfDocumentType("InCluded"),
    title: "Included",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
];
