import { CORE_GET_ACTION_TYPES_ODATA } from "@app/core/components/common/constant";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { BOOLEAN_FORMAT } from "@common/constants/common-format";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import { nameOfFactory } from "@common/utils/common";
import { ICCGridProps } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IRespActionType>();

export const colActionTypePicker: IColumnFields[] = [
  { title: "Name", field: nameOf("Name") },
  { title: "Description", field: nameOf("Description") },
  {
    title: "Has Outcome",
    field: nameOf("Flag_HasOutcome"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];

export const getOptionActionTypePicker = (
  productType: PRODUCT_TYPE_NUMBER,
  grid?: Partial<ICCGridProps>
): IOptionInputPicker => {
  return {
    dialog: {
      titleHeader: "Pick Action Type",
      maxWidth: "60%",
    },
    boxSearch: {
      colSearch: nameOf("Name"),
      colShowComboboxSearch: [
        {
          title: "Name",
          field: nameOf("Name"),
        },
      ],
    },
    grid: {
      columnFields: colActionTypePicker,
      primaryField: nameOf("ID"),
      dataUrl: `${CORE_GET_ACTION_TYPES_ODATA}(productType=["${productType}"],flagUserSelection=true,showRegulatory=true)`,
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: [
          {
            field: nameOf("Name"),
            dir: "asc",
          },
        ],
      },
      ...grid,
    },
  };
};
