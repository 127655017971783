import { IBuildingRegistersSitingApprovalsFormData } from "@app/products/building/registers/siting-approvals/[id]/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { loadBuildingRegistersSitingApprovalsById } from "./api";

export const SitingApprovalsDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [sitingApprovalsInfo, setSitingApprovalsInfo] = useState<
    IBuildingRegistersSitingApprovalsFormData | undefined
  >();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  useEffect(() => {
    if ((lastSelectedRow && lastSelectedRow?.ID) || params.id) {
      const sitingApprovalsId: any = lastSelectedRow?.ID || params.id;
      setIsLoading(true);
      loadBuildingRegistersSitingApprovalsById(sitingApprovalsId).then(
        (data) => {
          setSitingApprovalsInfo(data);
          setIsLoading(false);
        }
      );
    }
  }, [lastSelectedRow, params.id]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!sitingApprovalsInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Register Number:"}
              value={sitingApprovalsInfo?.RegisterNumber?.toString()}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Status:"}
              value={sitingApprovalsInfo?.Status}
              success
            />
            <ProductReferenceRow
              title={"OS Fees:"}
              value={formatDisplayValue(
                sitingApprovalsInfo?.OutstandingFees,
                CURRENCY_FORMAT.CURRENCY1
              )}
              format="currency"
            />

            <ProductReferenceRow
              title={"Lodged Date:"}
              value={formatDisplayValue(
                sitingApprovalsInfo?.LodgedDate,
                DATE_FORMAT.DATE
              )}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
