import { ICCRoute } from "@common/constants/ICCRoute";

export const permitsRoute: ICCRoute = {
  path: "permits",
  name: "Permits",
  children: [
    {
      path: "all-permits",
      name: "All Permits",
      component: require("./all-permits/_index").default,
    },
    {
      path: "disabled-only",
      name: "Disabled Only",
      component: require("./disabled-only/_index").default,
    },
    {
      path: "staff-only",
      name: "Staff Only",
      component: require("./staff-only/_index").default,
    },
    {
      path: "not-issued",
      name: "Not Issued",
      component: require("./not-issued/_index").default,
    },
    {
      path: "not-printed",
      name: "Not Printed",
      component: require("./not-printed/_index").default,
    },
    {
      path: "paid-not-issued",
      name: "Paid Not Issued",
      component: require("./paid-not-issued/_index").default,
    },
  ],
};
