import { ReturnMessage } from "@app/products/property/system-admin/document-template/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postImportRejectionFile = async (
  formData: FormData
): Promise<APIResponse<ReturnMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/centrelink/import/rejection`,
      formData
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postImportResponseFile = async (
  formData: FormData
): Promise<APIResponse<ReturnMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/centrelink/import/response`,
      formData
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
