import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface INoAppealDialogProps {
  onClosePopup: () => void;
  onAsyncConfirm: (e: any) => void;
  isLoadingYes?: boolean;
}
export default function NoAppealDialog({
  onClosePopup,
  onAsyncConfirm,
  isLoadingYes,
}: INoAppealDialogProps) {
  return (
    <ConfirmDialog
      title={"No Appeal Confirmation"}
      message={
        "Are you sure you want to enter No Appeal, this will complete the application, do you want to continue?"
      }
      height={"auto"}
      maxWidth={"25%"}
      isLoadingYes={isLoadingYes}
      onClosePopup={onClosePopup}
      onAsyncConfirm={onAsyncConfirm}
    />
  );
}
