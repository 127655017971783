import { DirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const postDDAuthorisation = async (
  data: DirectDebitAuthorisation,
  cancelToken: CancelToken
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket | undefined>(
      `api/receipting/internal/directdebitauthorisation`,
      data,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
