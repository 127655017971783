import { managePagePropertyWrapper } from "@app/products/property/components/action-bar/property-workflow/component/hoc/manage-page-property-wrapper";
import { ExistedPropertyPIC } from "@app/products/property/pic/[id]/components/forms/existed/_index";
import { usePicStore } from "@app/products/property/pic/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { NewPropertyPIC } from "./components/forms/new/_index";

const ManagePropertyPIC = observer(() => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const picId = params.id;
  const { resetStore, loadPic } = usePicStore();
  const location = useLocation();
  const state: any = location?.state;

  useEffect(() => {
    if (isNew) return;
    let notification = undefined;
    if (state) {
      notification = state?.notification;
      window.history.replaceState({}, "");
    }
    loadPic(parseInt(picId), notification);
    return () => {
      resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picId, isNew, loadPic]);

  if (isNew) {
    return <NewPropertyPIC />;
  }
  return <ExistedPropertyPIC />;
});

export default managePagePropertyWrapper(ManagePropertyPIC);
