import { mockModifyMeter } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/modify-meter/mock";
import { IModifyMeter } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/modify-meter/model";
import { IMeterFormGeneral } from "@app/products/property/meters/[id]/model";
import { sleep } from "@common/utils/common";

export const loadModifyMeter = async (meterInfo: IMeterFormGeneral) => {
  await sleep(1000);
  const dataResponse = { ...meterInfo, ...mockModifyMeter };
  return dataResponse as IModifyMeter;
};
