import { useRegisterTransactionsStore } from "@app/products/property/registers/[id]/components/child-screens/transactions/store";
import { ReverseTransactionDialog } from "@app/products/property/registers/[id]/components/dialogs/form-steps/reverse-transaction/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ReverseTransactionButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const { pushNotification } = useCCAppNotificationStore();
  const { isEmptySelectedTransaction } = useRegisterTransactionsStore();
  return (
    <>
      <CCNavButton
        title="Reverse transaction"
        onClick={() => setIsShowDialog(true)}
        disabled={isEmptySelectedTransaction}
      />

      {isShowDialog && (
        <ReverseTransactionDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={() => {
            pushNotification({
              title: "Transaction reversed successfully",
              type: "success",
            });
            setIsShowDialog(false);
          }}
        />
      )}
    </>
  );
});
