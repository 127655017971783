export const PICChargeRunsBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.Charge_Run_Id
      ? `- ${selectedRow.Charge_Run_Id}`
      : "";
    return `Property - Charge And Notice Run ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    const dynamicDetail =
      "PIC Charge Runs " + (selectedRow.Charge_Run_Id ?? "");
    return `${dynamicDetail}`;
  },
};

export const PICChargeRunsListBookmark = {
  getBookmarkListViewDisplayName() {
    return "Property - Charge And Notice Run";
  },

  getBookmarkListViewDetail() {
    return "Property - Charge And Notice Run - PIC Charge Runs";
  },
};
