import { CRMSViews } from "@app/products/crms/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";

export const itSupportRoute: ICCRoute = {
  path: "it-support",
  name: "IT Support",
  enum: CORPORATE_SETTINGS_FIELD.CUSTOMERSERVICE_Views_ITSupportEvents,
  children: [
    {
      path: "unallocated-events",
      name: "Unallocated Events",
      enum: CRMSViews.ITSupport_UnallocatedEvents,
      component: require("./unallocated-events/_index").default,
    },
    {
      path: "all-events",
      name: "All Events",
      enum: CRMSViews.ITSupport_AllEvents,
      component: require("./all-events/_index").default,
    },
    {
      path: "unresolved-events",
      name: "Unresolved Events",
      enum: CRMSViews.ITSupport_UnresolvedEvents,
      component: require("./unresolved-events/_index").default,
    },
  ],
};
