import { IntegrationModuleItemType } from "@app/products/direct-debit/system-admin/model";
import { SaveButtonDDModuleItemType } from "@app/products/direct-debit/system-admin/module-item-types/[id]/components/buttons/save/_index";
import { DirectDebitModuleItemTypeForm } from "@app/products/direct-debit/system-admin/module-item-types/[id]/components/general/_index";
import { TitleDDModuleItemTypeManagePage } from "@app/products/direct-debit/system-admin/module-item-types/constant";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewManageDDModuleItemType = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const directDebitModuleItemType =
    dataForms?.GeneralForm as IntegrationModuleItemType;

  return (
    <>
      <FormTitle title={`New ${TitleDDModuleItemTypeManagePage}`} />
      <CCManagePageActionBar leftActions={[<SaveButtonDDModuleItemType />]} />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {directDebitModuleItemType && <DirectDebitModuleItemTypeForm />}
          </div>
        </div>
      </div>
    </>
  );
});
