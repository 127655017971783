import { history } from "@/AppRoutes";
import {
  getBuildingRegistersEnquiryById,
  postBuildingStormwater,
  putBuildingStormwater,
} from "@app/products/building/registers/stormwater/[id]/api";
import { STORMWATER_MANAGE_ROUTE } from "@app/products/building/registers/stormwater/[id]/constant";
import {
  BuildingStormwaterRequest,
  BuildingStormwaterResponse,
} from "@app/products/building/registers/stormwater/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class BuildingRegistersStormwaterStore {
  private _buildingRegistersStormwater?: BuildingStormwaterResponse = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get buildingRegistersStormwater() {
    return toJS(this._buildingRegistersStormwater);
  }
  setBuildingRegistersStormwater = (
    buildingRegistersStormwater?: BuildingStormwaterResponse
  ) => {
    runInAction(() => {
      this._buildingRegistersStormwater = buildingRegistersStormwater;
    });
  };

  get buildingRegistersStormwaterId() {
    return toJS(this.buildingRegistersStormwater?.ID);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._buildingRegistersStormwater = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadBuildingRegistersStormwater = async (
    buildingRegistersStormwaterId: number
  ) => {
    this.setIsLoading(true);
    const response = await getBuildingRegistersEnquiryById(
      buildingRegistersStormwaterId
    );
    let errorResponse = undefined;
    let newBuildingRegistersStormwater = undefined;
    if (isSuccessResponse(response)) {
      newBuildingRegistersStormwater = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setBuildingRegistersStormwater(newBuildingRegistersStormwater);
    this.setIsLoading(false);
  };

  createBuildingRegistersStormwater = async (
    buildingRegistersStormwater: BuildingStormwaterRequest
  ) => {
    this.setIsLoading(true);
    await postBuildingStormwater(buildingRegistersStormwater);
    appNotificationStore.pushNotification({
      title: "Building registers stormwater created successfully",
      type: "success",
      onClose: () => {
        history.replace(
          `${STORMWATER_MANAGE_ROUTE}/${buildingRegistersStormwater?.ID ?? 1}`
        );
      },
    });
    this.setIsLoading(false);
  };

  updateBuildingRegistersStormwater = async (
    buildingRegistersStormwater: BuildingStormwaterRequest
  ) => {
    this.setIsLoading(true);
    await putBuildingStormwater(buildingRegistersStormwater);
    appNotificationStore.pushNotification({
      title: "Building registers stormwater updated successfully",
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const buildingRegistersStormwaterStoreContext = createContext(
  new BuildingRegistersStormwaterStore()
);
export const useBuildingRegistersStormwaterStore = () => {
  return useContext(buildingRegistersStormwaterStoreContext);
};
