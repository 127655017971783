import {
  DTO_NoticeRun_LOVs,
  DTO_Workflow_NoticeRunExport,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/notice-run-output/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type INoticeRunsData =
  | [APIResponse<DTO_NoticeRun_LOVs>, APIResponse<DTO_Workflow_NoticeRunExport>]
  | APIResponse<DTO_NoticeRun_LOVs | DTO_Workflow_NoticeRunExport>;

export const getNoticeRunOutput = async (
  noticeRunId: number
): Promise<INoticeRunsData> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_NoticeRun_LOVs>(
        `/api/property/int/noticerun/lovs`
      ),
      CoreAPIService.getClient().post<DTO_Workflow_NoticeRunExport>(
        `/api/property/int/workflow/noticeoutputrun/new`,
        {
          ComponentNumber: 1, //default value
          NoticeRunId: noticeRunId,
        }
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNoticeRunOutput = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_NoticeRunExport
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_NoticeRunExport>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/int/workflow/noticeoutputrun/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
