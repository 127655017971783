import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import React, { useRef, useState } from "react";
import { colFields } from "./config";
import { mockData } from "./mock";
import { IAssessment } from "./model";

interface ISelectAssessmentDialog {
  onClose: () => void;
  setAssessments: React.Dispatch<React.SetStateAction<IAssessment[]>>;
}

export const SelectAssessmentDialog = ({
  onClose,
  setAssessments,
}: ISelectAssessmentDialog) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [selectedAssessments, setSelectedAssessments] = useState<IAssessment[]>(
    []
  );

  const handleSubmit = () => {
    if (selectedAssessments.length === 0) {
      notificationRef.current?.pushNotification({
        closable: false,
        title: "Select at least one row",
        type: "warning",
      });
    } else {
      onClose();
      setAssessments(selectedAssessments);
    }
  };

  return (
    <Form
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement onSubmit={formRenderProps.onSubmit}>
          <CCDialog
            maxWidth="60%"
            maxHeight="45%"
            titleHeader="Select Assessment to Receive Balance"
            onClose={onClose}
            bodyElement={
              <React.Fragment>
                <section className="cc-field-group">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-form-cols-2">
                    <div className="cc-field cc-col-span-2">
                      <Checkbox
                        label={
                          "Show all the remaining Assessments in the Supplementary"
                        }
                      />
                    </div>
                    <div className="cc-field cc-col-span-2">
                      <CCGrid
                        data={mockData}
                        columnFields={colFields}
                        primaryField="AssessmentId"
                        selectableMode="multiple"
                        onSelectionChange={(dataItem: IAssessment[]) => {
                          setSelectedAssessments(dataItem);
                        }}
                      />
                    </div>
                  </div>
                </section>
              </React.Fragment>
            }
            footerElement={
              <React.Fragment>
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    themeColor="primary"
                    type={"submit"}
                    className="cc-dialog-button"
                  >
                    Ok
                  </Button>
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </React.Fragment>
            }
          />
        </FormElement>
      )}
    />
  );
};
