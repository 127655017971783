import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";
import { VO_Officer } from "@common/models/officer";

export enum EKeysOfSteps {
  Compliance = "SelectGeneral",
  Details = "ComplianceDetail",
  Comments = "Comments",
  Documents = "Documents",
}

export const modifyComplianceKeysOfSendSteps = [
  EKeysOfSteps.Compliance,
  EKeysOfSteps.Details,
];

export interface DTO_ModifyCompliance_LOVs_Response {
  dtoModifyComplianceLOVs: DTO_ModifyCompliance_LOVs;
}
export interface DTO_ModifyCompliance_LOVs {
  ReasonForUpdate: DTO_LOV[];
  ComplianceType: DTO_LOV[];
  ComplianceStatus: DTO_LOV[];
}

export interface DTO_Workflow_ModifyCompliance {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_ModifyCompliance;
}

export interface DTO_WorkflowDetail_ModifyCompliance {
  ComplianceId: number;
  SelectGeneral: DTO_ModifyCompliance_Default;
  ComplianceDetail: DTO_ModifyCompliance_Detail;
}

export interface DTO_ModifyCompliance_Default {
  ApplicantName: string;
  ReasonForChange: number;
}

export interface DTO_ModifyCompliance_Detail {
  Interest_From: Date | null;
  Penalty_Percentage: number | null;
  Search_Details: string;
  Rented_From: string;
  Questionaire_Return_Date: Date | null;
  Show_Alert_on_Assess: boolean;
  ModifiedBy: string;
  ModifiedOn: Date | null;
  Is_Active: boolean | null;
  CreatedBy: string;
  Alert_Message: string;
  DateTo: Date | null;
  DateFrom: Date | null;
  Officer_Id: number | null;
  Address_Id: number;
  Compliance_Status: number | null;
  Compliance_Type_Id: number;
  ComplianceId: number;
  CreatedOn: Date | null;
  Search_DetailsX: string;
  ResponsibleOfficer?: VO_Officer;
}

export interface RequestModifyComplianceObj {
  ModifyComplianceId: number | null;
  IsCompliancesActiveOnly: boolean | null;
}
