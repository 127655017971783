import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { useDeferredDutyNoticeRunsStore } from "@app/products/property/deferred-duty/notice-runs/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ExistedNoticeRun } from "./components/forms/existed/_index";
import NewNoticeRun from "./components/forms/new/_index";
import { DeferredDutyNoticeRunDetailTab } from "./components/reference-sidebar/details/_index";

const ManageDeferredDutyNoticeRun = observer(() => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const noticeRequestId = params.id;
  const { loadDeferredDutyNoticeRuns } = useDeferredDutyNoticeRunsStore();

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <DeferredDutyNoticeRunDetailTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Notice_Run_Assessment}
          />
        ),
      },
    ],
  });

  useEffect(() => {
    if (isNew) return;
    loadDeferredDutyNoticeRuns(parseInt(noticeRequestId));
  }, [isNew, noticeRequestId, loadDeferredDutyNoticeRuns]);
  if (isNew) {
    return <NewNoticeRun />;
  }
  return <ExistedNoticeRun />;
});

export default ManageDeferredDutyNoticeRun;
