import { isAfter, isEqual } from "date-fns";
import { isNull } from "lodash";

export const handleValidateForm: any = (data: any) => {
  const LeakageOccurredApproxFrom = data.LeakageOccurredApproxFrom;
  const LeakageOccurredUpTo = data.LeakageOccurredUpTo;

  let errorMessage = {};
  if (isNull(LeakageOccurredApproxFrom)) {
    errorMessage = {
      ...errorMessage,
      LeakageOccurredApproxFrom: " ",
    };
  }
  if (isNull(LeakageOccurredUpTo)) {
    errorMessage = {
      ...errorMessage,
      LeakageOccurredUpTo: " ",
    };
  }
  if (
    !isNull(LeakageOccurredApproxFrom) &&
    !isNull(LeakageOccurredUpTo) &&
    !isAfter(
      new Date(LeakageOccurredUpTo),
      new Date(LeakageOccurredApproxFrom)
    ) &&
    !isEqual(new Date(LeakageOccurredUpTo), new Date(LeakageOccurredApproxFrom))
  ) {
    errorMessage = {
      ...errorMessage,
      LeakageOccurredApproxFrom:
        "Leakage Occurred Approx From must be equal or less than Leakage Occurred Up To",
      LeakageOccurredUpTo:
        "Leakage Occurred Up To must be equal or greater than Leakage Occurred Approx From",
    };
  }

  return errorMessage;
};
