import { CalculateInstalmentDialog } from "@app/products/property/deferred-duty/components/action-bar/dialogs/calculate-instalment/_index";
import { CommunityProperty } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const CalCulateInstalmentButton = observer(() => {
  const { isActro } = CommunityProperty.getFlagOfStates();
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);

  return !isActro ? (
    <>
      <CCNavButton
        title="Calculate instalment"
        onClick={() => setIsShowDialog(true)}
      />
      {isShowDialog && (
        <CalculateInstalmentDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
        />
      )}
    </>
  ) : null;
});
