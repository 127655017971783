import { ICommonFormProps } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-application/_index";
import { EFieldsNumberingChildComponent } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-child-component/model";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import {
  ILookupItem,
  SettingField,
} from "@app/products/town-planning/ppr/system-admin/settings/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type INumberingChildComponentProps = ICommonFormProps & {
  [key in EFieldsNumberingChildComponent]?: SettingField | undefined;
};

const nameOfLookupItem = nameOfFactory<ILookupItem>();

export const NumberingChildComponent = observer(
  (props: INumberingChildComponentProps) => {
    const { isEditing } = useSettingPPRManageStore();

    const {
      fieldNumberFormat,
      fieldPrefix,
      fieldSuffix,
      fieldLength,
      fieldAmendmentSeparator,
      fieldLastNumberUsed,
      fieldSetDebtorNumber,
      fieldDebtorNumberPrefix,
      formRenderProps,
    } = props;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {fieldNumberFormat && (
            <div className="cc-field">
              <CCLabel
                title={fieldNumberFormat?.Title ?? ""}
                isMandatory={fieldNumberFormat.IsMandatory}
              />
              <Field
                name={fieldNumberFormat?.FieldName}
                component={CCSearchComboBox}
                data={fieldNumberFormat?.LookupItems}
                textField={nameOfLookupItem("Name")}
                dataItemKey={nameOfLookupItem("Id")}
                disabled={!isEditing}
                isUseDefaultOnchange
                validator={
                  fieldNumberFormat.IsMandatory ? requiredValidator : undefined
                }
              />
            </div>
          )}
          {fieldPrefix && (
            <div className="cc-field">
              <CCLabel
                title={fieldPrefix?.Title ?? ""}
                isMandatory={fieldPrefix.IsMandatory}
              />
              <Field
                name={fieldPrefix?.FieldName}
                component={CCInput}
                disabled={!isEditing}
                validator={
                  fieldPrefix.IsMandatory ? requiredValidator : undefined
                }
              />
            </div>
          )}
          {fieldSuffix && (
            <div className="cc-field">
              <CCLabel
                title={fieldSuffix?.Title ?? ""}
                isMandatory={fieldSuffix.IsMandatory}
              />
              <Field
                name={fieldSuffix?.FieldName}
                component={CCInput}
                disabled={!isEditing}
                validator={
                  fieldSuffix.IsMandatory ? requiredValidator : undefined
                }
              />
            </div>
          )}
          {fieldLength && (
            <div className="cc-field">
              <CCLabel
                title={fieldLength?.Title ?? ""}
                isMandatory={fieldLength.IsMandatory}
              />
              <Field
                name={fieldLength?.FieldName}
                component={CCNumericTextBox}
                disabled={!isEditing}
                format={NUMBER_FORMAT.NUMBER2}
                validator={
                  fieldLength.IsMandatory ? requiredValidator : undefined
                }
              />
            </div>
          )}
          {fieldAmendmentSeparator && (
            <div className="cc-field">
              <CCLabel
                title={fieldAmendmentSeparator?.Title ?? ""}
                isMandatory={fieldAmendmentSeparator.IsMandatory}
              />
              <Field
                name={fieldAmendmentSeparator?.FieldName}
                component={CCInput}
                disabled={!isEditing}
                validator={
                  fieldAmendmentSeparator.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
          {fieldLastNumberUsed && (
            <div className="cc-field">
              <CCLabel
                title={fieldLastNumberUsed?.Title ?? ""}
                isMandatory={fieldLastNumberUsed.IsMandatory}
              />
              <Field
                name={fieldLastNumberUsed?.FieldName}
                component={CCNumericTextBox}
                disabled={!isEditing}
                format={NUMBER_FORMAT.NUMBER2}
                validator={
                  fieldLastNumberUsed.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
          {fieldSetDebtorNumber && (
            <div className="cc-field">
              <CCLabel title={fieldSetDebtorNumber?.Title ?? ""} />
              <Field
                name={fieldSetDebtorNumber?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={formRenderProps?.valueGetter(
                  fieldSetDebtorNumber?.FieldName
                )}
              />
            </div>
          )}
          {fieldDebtorNumberPrefix && (
            <div className="cc-field">
              <CCLabel
                title={fieldDebtorNumberPrefix?.Title ?? ""}
                isMandatory={fieldDebtorNumberPrefix.IsMandatory}
              />
              <Field
                name={fieldDebtorNumberPrefix?.FieldName}
                component={CCInput}
                disabled={!isEditing}
                validator={
                  fieldDebtorNumberPrefix.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
