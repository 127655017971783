import { TOWN_PLANNING_PPR_ENQUIRIES_ROUTE } from "@app/products/town-planning/ppr/enquiries/_id/constant";
import { IPPREnquiryParentSection } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

interface IPlanningEnquiryButton {
  id?: number;
  recordType: RECORDTYPE;
}

export const PlanningEnquiryButton = ({
  id,
  recordType,
}: IPlanningEnquiryButton) => {
  const { ppr } = usePPRStore();
  const history = useHistory();

  const handleOnClick = () => {
    history.push(`${TOWN_PLANNING_PPR_ENQUIRIES_ROUTE}/new`, {
      pprApplication: ppr,
      recordType: recordType,
      parentID: id,
    } as IPPREnquiryParentSection);
  };

  return <CCNavButton title={"Planning enquiry"} onClick={handleOnClick} />;
};
