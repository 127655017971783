//https://github.com/telerik/kendo-intl/tree/develop/docs
//Custom DateFormat: https://github.com/telerik/kendo-intl/blob/develop/docs/date-formatting/index.md

//TODO: will remove when finish refactor format
export enum COMMON_FORMAT {
  // CONTROL_DATE = "dd/MM/yyyy",
  // CONTROL_DATETIME = "dd/MM/yyyy hh:mm a",
  // DATE = "dd MMM yyyy",
  // DATETIME = "dd MMM yyyy hh:mm a",
  // MONTH_YEAR = "0:y",

  COST_GRID = "{0:c}",
  // DATETIME_GRID = "dd MMM yyyy h:mm a",
  // CURRENCY = "c2",
  // PROPERTY_CURRENCY = "a",
  // PROPERTY_NUMBER = "##,#",
  // PROPERTY_DATETIME = "dd MMM yyyy h:mm a",
  // BOOLEAN = "boolean",
  // NUMBER = "#",
  // PERCENTAGE = "p2",
  // ICON_BOOLEAN = "iconboolean",
  // ICON_STANDARDREPORT = "iconstandardreport",
  // ICON_SHAREREPORT = "iconsharereport",
  PROPERTYGRID_CURRENCY = "{0:a2}",
  PROPERTYGRID_NUMBER = "{0:##,#}",
  PROPERTYGRID_DATE = "dd MMM yyyy",
  PROPERTYGRID_PERCENTAGE = "{0:p2}",
}

// @TODO: merge DATE_FORMAT and DATETIME_FORMAT into one
export enum DATE_FORMAT {
  DATE_CONTROL = "dd/MM/yyyy",
  DATE = "dd MMM yyyy",
  MONTH_YEAR = "MMM y",
  LONG_DATE = "EE, dd MMM, yyyy",
  DAY_MONTH = "dd MMM",
}

export enum DATETIME_FORMAT {
  DATETIME_CONTROL = "dd/MM/yyyy hh:mm a",
  DATETIME = "dd MMM yyyy hh:mm a",
  TIME = "hh:mm a",
  LONG_DATETIME = "EE, dd MMM, yyyy hh:mm a",
}

export enum NUMBER_FORMAT {
  NUMBER = "n",
  NUMBER1 = "##,#",
  NUMBER2 = "#",
}

export enum DECIMAL_FORMAT {
  DECIMAL1 = "#.#########",
  DECIMAL_2_DIGIT = "n2",
  DECIMAL_3_DIGIT = "#.000",
  DECIMAL_4_DIGIT = "#.0000",
  DECIMAL_6_DIGIT = "#.000000",
}

export enum CURRENCY_FORMAT {
  CURRENCY1 = "a",
  CURRENCY2 = "c2",
  CURRENCY4 = "c4",
}

export enum PERCENTAGE_FORMAT {
  PERCENTAGE = "#.00#######\\%",
  PERCENTAGE_1 = "##.##\\%",
  PERCENTAGE_NOT_DECIMAL = "##\\%",
}

export enum ICON_FORMAT {
  ICON_BOOLEAN = "icon_boolean",
  ICON_BOOLEAN_WITHOUT_CROSS = "icon_boolean_without_cross",
  ICON_URGENT = "icon_urgent",
  ICON_CALL_RETURN = "icon_call_return",
  ICON_HAZARD = "icon_hazard",
  ICON_STANDARD_REPORT = "icon_standard_report",
  ICON_SHARE_REPORT = "icon_share_report",
  ICON_PADLOCK = "icon_padlock",
}

export enum BOOLEAN_FORMAT {
  BOOLEAN = "boolean",
}

export enum DATE_FORMAT_SEND_API {
  DATE = "dd-MM-yyyy",
}
