import {
  AnimalsKennel,
  KennelUpdateTriggers,
  Svc_AnimalKennel,
} from "@app/products/animals/kennels/[id]/model";
import { useAnimalsKennelStore } from "@app/products/animals/kennels/[id]/store";
import { ReasonDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/_index";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { DateToFromDialog } from "@common/dialog/date-to-from/_index";
import { DateFromDateTo } from "@common/dialog/date-to-from/model";
import { nameOfFactory } from "@common/utils/common";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { addDays, addYears } from "date-fns";
import { isNull } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface IKennelFormProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<AnimalsKennel>();
const nameOfMapObj = nameOfFactory<Svc_AnimalKennel>();
const getNameOf = (name: keyof AnimalsKennel) =>
  `${nameOfMapObj("Kennel")}.${nameOf(name)}`;

export const KennelWorkFlowElements = observer((props: IKennelFormProps) => {
  const { valueGetter, onChange } = props.formRenderProps;
  const [dataDateToFrom, setDataToFrom] = useState<DateFromDateTo>();
  const [dataDeregister, setDataDeregister] = useState<ProvideReason>();
  const [dataReactivate, setDataReactivate] = useState<ProvideReason>();
  const {
    setIsShowKennelIssueRegistrationDialog,
    isShowKennelIssueRegistrationDialog,
    setIsShowKennelRenewDialog,
    isShowKennelRenewDialog,
    setIsShowKennelDeregisterDialog,
    isShowKennelDeregisterDialog,
    setIsShowKennelReactivateDialog,
    isShowKennelReactivateDialog,
    setIsWorkflowLoading,
    isWorkflowLoading,
    handleWorkFlowIssueRegistration,
    handleWorkFlowRenew,
    handleWorkFlowDeregister,
    handleWorkFlowReactivate,
    isReIssue,
  } = useAnimalsKennelStore();

  const kennelFromObj: AnimalsKennel = valueGetter(nameOfMapObj("Kennel"));

  const objIssueRegistration = () => {
    const retVal = new DateFromDateTo();
    retVal.Title_Text = "Issue Registration";
    retVal.DateLabelFrom_Text = "Effective From";
    retVal.DateLabelFrom_To = "Effective To";

    const dateEffectFrom = valueGetter(getNameOf("Date_EffectiveFrom"))
      ? new Date(valueGetter(getNameOf("Date_EffectiveFrom")))
      : null;

    if (!isNull(dateEffectFrom)) {
      retVal.DateFrom = dateEffectFrom;
    } else {
      retVal.DateFrom = new Date();
    }

    retVal.DateTo = addYears(retVal.DateFrom, 1);
    setDataToFrom(retVal);
  };

  const objRenew = () => {
    const retVal = new DateFromDateTo();
    retVal.Title_Text = "Renew Kennel Registration";
    retVal.DateLabelFrom_Text = "Effective From";
    retVal.DateLabelFrom_To = "Effective To";

    const dateEffectTo = valueGetter(getNameOf("Date_EffectiveTo"))
      ? new Date(valueGetter(getNameOf("Date_EffectiveTo")))
      : null;

    if (!isNull(dateEffectTo)) {
      retVal.DateFrom = addDays(dateEffectTo, 1);
      retVal.DateTo = addYears(dateEffectTo, 1);
    } else {
      retVal.DateFrom = new Date();
      retVal.DateTo = addYears(new Date(), 1);
    }

    if (
      !kennelFromObj?._SaveTriggers?.includes(
        KennelUpdateTriggers.CreateRenewalRegistrationFee
      ) &&
      !kennelFromObj.IsRenewalFeeCreated
    ) {
      const oldSaveTrigger = kennelFromObj._SaveTriggers;
      oldSaveTrigger?.push(KennelUpdateTriggers.CreateRenewalRegistrationFee);
      onChange(getNameOf("_SaveTriggers"), {
        value: oldSaveTrigger ? [...oldSaveTrigger] : [],
      });
    }

    setDataToFrom(retVal);
  };

  const objDeregister = () => {
    const retVal = new ProvideReason();
    retVal.Title_Text = "Deactivate Kennel Registration";
    retVal.ReasonLabel_Text = "Optional Details";
    retVal.Flag_ShowDate = false;

    retVal.Flag_ShowKWD = true;
    retVal.Flag_KWDIsMandatory = false;
    retVal.KWDLabel_RequiredErrorMessage = "Please select a reason";
    retVal.KWDLabel_Text = "Reason";
    retVal.KWDType = KEYWORD_TYPE.Animals_Kennel_DeactivateReason;

    setDataDeregister(retVal);
  };

  const objReactivate = () => {
    const retVal = new ProvideReason();
    retVal.Title_Text = "Reactivate Kennel";
    retVal.ReasonLabel_Text = "Details";
    retVal.Flag_ShowDate = false;
    retVal.Flag_ShowKWD = false;
    retVal.Flag_KWDIsMandatory = false;
    setDataReactivate(retVal);
  };

  const isOpenIssueRegistrationDialog = useMemo(() => {
    setDataToFrom(undefined);
    if (isShowKennelIssueRegistrationDialog) objIssueRegistration();
    return isShowKennelIssueRegistrationDialog;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowKennelIssueRegistrationDialog]);

  const isOpenRenewDialog = useMemo(() => {
    setDataToFrom(undefined);
    if (isShowKennelRenewDialog) objRenew();
    return isShowKennelRenewDialog;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowKennelRenewDialog]);

  const isOpenDeregisterDialog = useMemo(() => {
    setDataDeregister(undefined);
    if (isShowKennelDeregisterDialog) objDeregister();

    return isShowKennelDeregisterDialog;
  }, [isShowKennelDeregisterDialog]);

  const isOpenReactivateDialog = useMemo(() => {
    setDataReactivate(undefined);
    if (isShowKennelReactivateDialog) objReactivate();

    return isShowKennelReactivateDialog;
  }, [isShowKennelReactivateDialog]);

  return (
    <>
      {isOpenIssueRegistrationDialog && (
        <DateToFromDialog
          onClose={() => {
            setIsShowKennelIssueRegistrationDialog(false);
          }}
          onSubmit={(
            value: DateFromDateTo,
            notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
          ) => {
            if (
              !kennelFromObj?._SaveTriggers?.includes(
                KennelUpdateTriggers.CreateNewRegistrationFee
              ) &&
              !isReIssue
            ) {
              const oldSaveTrigger = kennelFromObj._SaveTriggers;
              oldSaveTrigger?.push(
                KennelUpdateTriggers.CreateNewRegistrationFee
              );
              onChange(getNameOf("_SaveTriggers"), {
                value: oldSaveTrigger ? [...oldSaveTrigger] : [],
              });
            }
            handleWorkFlowIssueRegistration(
              kennelFromObj,
              value,
              notificationRef
            );
          }}
          isLoading={isWorkflowLoading}
          data={dataDateToFrom}
        />
      )}
      {isOpenRenewDialog && (
        <DateToFromDialog
          onClose={() => {
            setIsShowKennelRenewDialog(false);
          }}
          onSubmit={(
            value: DateFromDateTo,
            notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
          ) => {
            handleWorkFlowRenew(kennelFromObj, value, notificationRef);
          }}
          isLoading={isWorkflowLoading}
          data={dataDateToFrom}
        />
      )}
      {isOpenDeregisterDialog && (
        <ReasonDialog
          onClose={() => setIsShowKennelDeregisterDialog(false)}
          onSubmit={(
            value: ProvideReason,
            notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
          ) => handleWorkFlowDeregister(kennelFromObj, value, notificationRef)}
          setIsLoading={setIsWorkflowLoading}
          isLoading={isWorkflowLoading}
          initialData={dataDeregister}
        />
      )}
      {isOpenReactivateDialog && (
        <ReasonDialog
          onClose={() => setIsShowKennelReactivateDialog(false)}
          onSubmit={(
            value: ProvideReason,
            notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
          ) => handleWorkFlowReactivate(kennelFromObj, value, notificationRef)}
          setIsLoading={setIsWorkflowLoading}
          isLoading={isWorkflowLoading}
          initialData={dataReactivate}
        />
      )}
    </>
  );
});
