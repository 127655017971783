import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacket } from "@common/models/identityPacket";
import {
  Ancestor,
  IAppeal,
  IAppealLOVs,
  IDisplayDecision,
} from "@common/pages/appeal/_id/model";

export const getAppeal = async (
  id: number
): Promise<APIResponse<IAppeal | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IAppeal>(
      `/api/core/internal/appeals/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getParentSummary = async (
  parentId: number,
  recordType: RECORDTYPE
): Promise<any> => {
  //TODO: Apply later type of response
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<Ancestor>(
        `/api/core/internal/core/${parentId}/parentsummary?recordType=${recordType}`
      ),
      CoreAPIService.getClient().get<IDisplayDecision>(
        `/api/tp/ppr/internal/application/${parentId}/decision`
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putAppeal = async (
  appeal: IAppeal,
  parentID: number | null = null,
  parentRecordType: RECORDTYPE | null = null
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `/api/core/internal/appeals?parentID=${parentID}&parentRecordType=${parentRecordType}`,
      appeal
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAppealLovs = async (): Promise<APIResponse<IAppealLOVs>> => {
  try {
    return await CoreAPIService.getClient().get<IAppealLOVs>(
      `/api/core/internal/appeals/lovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putValidateAppeal = async (
  appeal: IAppeal
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<IdentityPacket>(
      `/api/core/internal/appeals/validate`,
      appeal
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
