import {
  IMailMergeButtonProps,
  MailMergeButton,
} from "@app/core/new-mail-merge/buttons/components/buttons/_index";
import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const MailMergeButtonAssessmentCollections = observer(
  (props: IMailMergeButtonProps) => {
    const { isEmptyGridSelectedRow, gridSelectedRows, gridSelectedIds } =
      useDetailAssessmentListViewStore();

    return (
      <MailMergeButton
        {...props}
        isEmptyGridSelectedRow={isEmptyGridSelectedRow}
        gridSelectedRows={gridSelectedRows}
        selectedRecordIds={gridSelectedIds}
        titleButton="Mail Merge"
      />
    );
  }
);
