import { ViewDocumentsDialog } from "@app/core/view/components/dialogs/view-documents/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface IViewDocumentsButtonProps {
  id?: number;
  recordType: RECORDTYPE;
}

export const ViewDocumentsButton = observer(
  ({ id, recordType }: IViewDocumentsButtonProps) => {
    const [showDocumentsViewDialog, setShowDocumentsViewDialog] =
      useState(false);

    return (
      <>
        <CCNavButton
          title="Documents"
          onClick={() => setShowDocumentsViewDialog(true)}
        />

        {showDocumentsViewDialog && (
          <ViewDocumentsDialog
            id={id}
            recordType={recordType}
            onClose={() => setShowDocumentsViewDialog(false)}
          />
        )}
      </>
    );
  }
);
