import { DTO_Create_Assessment_LOVs } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class DisassociateAssessmentDialogStore {
  private _disAssociateAssessmentLOVs?: DTO_Create_Assessment_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get disAssociateAssessmentLOVs() {
    return this._disAssociateAssessmentLOVs;
  }
  setDisassociateAssessmentLOVs = (
    disAssociateAssessmentLOVs?: DTO_Create_Assessment_LOVs
  ) => {
    runInAction(() => {
      this._disAssociateAssessmentLOVs = disAssociateAssessmentLOVs;
    });
  };
}

const disAssociateAssessmentDialogStoreContext = createContext(
  new DisassociateAssessmentDialogStore()
);
export const useDisassociateAssessmentDialogStore = () => {
  return useContext(disAssociateAssessmentDialogStoreContext);
};
