import {
  DirectDebitAccount,
  DirectDebitAccountLOVs,
  IntegrationModuleItemType,
} from "@app/products/direct-debit/accounts/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { CancelToken } from "axios";

export const getDirectDebitAccountById = async (
  directDebitAccountId: number,
  cancelToken: CancelToken
): Promise<APIResponse<DirectDebitAccount> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<DirectDebitAccount>(
      `api/receipting/internal/directdebitaccount/${directDebitAccountId}`,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postDirectDebitAccount = async (
  directDebitAccount: DirectDebitAccount,
  cancelToken: CancelToken
): Promise<APIResponse<DirectDebitAccount> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<DirectDebitAccount>(
      `api/receipting/internal/directdebitaccount`,
      directDebitAccount,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const getDirectDebitAccountLOVs = async (
  cancelToken: CancelToken
): Promise<APIResponse<DirectDebitAccountLOVs> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<DirectDebitAccountLOVs>(
      `api/receipting/internal/directdebitaccount/lovs`,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const getDDAccountItemTypeLOVs = async (
  cancelToken: CancelToken,
  moduleId: number
): Promise<APIResponse<IntegrationModuleItemType[]> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<IntegrationModuleItemType[]>(
      `api/receipting/internal/integrationmoduleitemtype/getmoduleItemtypeslookup?moduleId=${moduleId}`,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const changeDirectDebitAccountStatus = async (
  directDebitAccount: DirectDebitAccount,
  cancelToken: CancelToken
): Promise<APIResponse<DirectDebitAccount> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<DirectDebitAccount>(
      `api/receipting/internal/directdebitaccount/changeAccountStatus`,
      directDebitAccount,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
