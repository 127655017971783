import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IAdditionalHearing } from "./model";

export const getAdditionalHearing = async (
  id: number
): Promise<APIResponse<IAdditionalHearing[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IAdditionalHearing[]>(
      `api/core/internal/appeals/${id}/hearings`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putAppealsHearings = async (listId?: number[]): Promise<any> => {
  try {
    return await CoreAPIService.getClient().put(
      `/api/core/internal/appeals/hearings/delete`,
      JSON.stringify(listId)
    );
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
