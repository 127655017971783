import { putClearReportLink } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/clear-report-link/api";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ClearReportLinkButton = observer(() => {
  const { reLoadPPR } = usePPRStore();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { clearErrorNotification, pushNotification } =
    useCCAppNotificationStore();

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await putClearReportLink(parseInt(id));
    setIsLoading(false);
    if (isSuccessResponse(response)) {
      reLoadPPR().then(() => {
        clearErrorNotification();
        pushNotification({
          type: "success",
          title: "Clear report link successfully",
        });
      });
    } else {
      clearErrorNotification();
      pushNotification({
        autoClose: false,
        title: "Clear report link failed",
        type: "error",
      });
    }
  };

  return (
    <CCNavButton
      title={"Clear report link"}
      isLoading={isLoading}
      onClick={handleSubmit}
    />
  );
});
