import { isEmpty, isEqual, isNil } from "lodash";

const STORED_URL_NAME = "gridStoreURL";

// Retrieve data from sessionStorage
const getStoredData = (): string | null => {
  return sessionStorage.getItem(STORED_URL_NAME);
};
// Function to check if storeUrl is included in sessionStorage
export const isKeepStore = (storeUrl?: string): boolean => {
  return isEqual(storeUrl, getStoredData());
};

// Function to generate a unique key based on storeUrl and productListViewId
export const generateKeyStored = (
  productListViewId: string,
  storeUrl?: string
): string => {
  // Combine storeUrl and productListViewId to create a unique key
  return `${storeUrl}/${productListViewId}`;
};

// Save/Updated data back to sessionStorage
export const saveDataToLocalStorage = (data: string): void => {
  sessionStorage.setItem(STORED_URL_NAME, data || "");
};

// Function to add/update value in sessionStorage
export const addItemToGridStoreURLStored = (storeUrl?: string): void => {
  if (isNil(storeUrl) || isEmpty(storeUrl)) return;
  saveDataToLocalStorage(storeUrl);
};

// Function to remove item from sessionStorage
export const removeItemFromGridStoreURLStored = (): void => {
  sessionStorage.removeItem(STORED_URL_NAME);
};
