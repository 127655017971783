import { eventEmitter } from "@/App";
import { State } from "@progress/kendo-data-query";
import { useEffect, useRef, useState } from "react";
import { defaultSelectionLoaderEvent } from "../constant";
import { ISelection, SelectTypeEnum } from "../model";
import { getSelectionEmitterId, instantiateODataGreedyLoader } from "../util";

export function useSelectionEmitter(
  oDataURI: string | undefined,
  gridState: State,
  idField: string,
  selection: ISelection,
  selectedRowCount: number,
  gridId?: string
) {
  const trackingSelectionStatus = useRef({
    previous: selection.selectType,
    recent: selection.selectType,
  });
  const [identifier] = useState(() =>
    !gridId ? null : getSelectionEmitterId(gridId)
  );
  const [uri] = useState(oDataURI);

  useEffect(() => {
    if (identifier === null || !uri) return;
    [
      trackingSelectionStatus.current.previous,
      trackingSelectionStatus.current.recent,
    ] = [trackingSelectionStatus.current.recent, selection.selectType];
    if (
      trackingSelectionStatus.current.previous !== SelectTypeEnum.ALL &&
      trackingSelectionStatus.current.recent !== SelectTypeEnum.ALL
    )
      return;
    let eventMessage = defaultSelectionLoaderEvent;
    if (selection.selectType === SelectTypeEnum.ALL) {
      eventMessage = {
        available: true,
        peek: {
          type: selection.selectType,
          count: selectedRowCount,
        },
        process: instantiateODataGreedyLoader(
          uri,
          gridState,
          selection.excludedRowsIds,
          idField
        ),
      };
    }
    eventEmitter.emit(identifier, eventMessage);
  }, [
    uri,
    gridState,
    idField,
    selection.excludedRowsIds,
    selection.selectType,
    selectedRowCount,
    identifier,
  ]);
}
