import { DTO_Assessment } from "@app/products/property/assessments/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { Button } from "@progress/kendo-react-buttons";
import { Popup } from "@progress/kendo-react-popup";
import React, { useRef, useState } from "react";
import "./_index.scss";

interface IValuationsInfoPopup {
  assessmentInfo?: DTO_Assessment;
}
export default function ValuationsInfoPopup({
  assessmentInfo,
}: IValuationsInfoPopup) {
  const anchorRef = useRef(null);
  const popupContentRef = useRef<HTMLDivElement>(null);
  const blurTimeoutRef: { current: NodeJS.Timeout | null } = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const valuationsInfo = assessmentInfo?.CurrentValuation;
  return (
    <>
      <div ref={anchorRef}>
        <Button
          fillMode="flat"
          themeColor="primary"
          className={`cc-property-info-icon ${
            showPopup ? "cc-icon-active" : ""
          }`}
          onClick={(event) => {
            event.preventDefault();
            setShowPopup(!showPopup);
          }}
          iconClass="fa fa-info-circle"
        />
      </div>
      <Popup
        anchor={anchorRef.current}
        show={showPopup}
        anchorAlign={{
          horizontal: "right",
          vertical: "top",
        }}
        popupAlign={{
          horizontal: "right",
          vertical: "bottom",
        }}
        popupClass={"cc-property-info-popup"}
        onOpen={() => {
          if (popupContentRef.current) {
            popupContentRef.current.focus();
          }
        }}
      >
        <div
          ref={popupContentRef}
          onBlur={() => {
            blurTimeoutRef.current && clearTimeout(blurTimeoutRef.current);
            blurTimeoutRef.current = setTimeout(() => {
              setShowPopup(false);
            }, 200);
          }}
          onFocus={() => {
            blurTimeoutRef.current && clearTimeout(blurTimeoutRef.current);
          }}
          tabIndex={0}
          className="cc-popup-content cc-form"
        >
          <div className="cc-field-group">
            <div className="cc-form-cols-2">
              <div className="field">
                <label className="cc-label">Valuation date</label>
                <div className="cc-label-field">
                  {formatDisplayValue(
                    valuationsInfo?.ValuationDate,
                    DATE_FORMAT.DATE
                  ) || "---"}
                </div>
              </div>
              <div className="field">
                <label className="cc-label">Issue date</label>
                <div className="cc-label-field">
                  {formatDisplayValue(
                    valuationsInfo?.IssueDate,
                    DATE_FORMAT.DATE
                  ) || "---"}
                </div>
              </div>
              <div className="field">
                <label className="cc-label">Effective date</label>
                <div className="cc-label-field">
                  {formatDisplayValue(
                    valuationsInfo?.EffectiveDate,
                    DATE_FORMAT.DATE
                  ) || "---"}
                </div>
              </div>
              <div className="field">
                <label className="cc-label">Supplementary</label>
                <div className="cc-label-field">
                  {valuationsInfo?.Supplementary?.IdAndName || "---"}
                </div>
              </div>
              <div className="field">
                <label className="cc-label">Comment</label>
                <div className="cc-label-field">
                  {" "}
                  {valuationsInfo?.Comment || "---"}
                </div>
              </div>

              <div className="field">
                <label className="cc-label">Basis</label>
                <div className="cc-label-field">
                  {valuationsInfo?.Basis || "---"}
                </div>
              </div>
              <div className="field">
                <label className="cc-label">Valuation ID</label>
                <div className="cc-label-field">
                  {valuationsInfo?.Id || "---"}
                </div>
              </div>
              <div className="field">
                <label className="cc-label">Improvements</label>
                <div className="cc-label-field">
                  {assessmentInfo?.Improvements || "---"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
}
