import { ServiceStandardCategory } from "@app/products/crms/service-standards/[id]/model";
import { loadCategoryById } from "@app/products/crms/system-admin/categories/[id]/api";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { useIsNew } from "@common/hooks/useIsNew";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const CRMSSystemAdminCategoryDetailsTab = observer(() => {
  const isNew = useIsNew();
  const { lastSelectedId } = useCCProductListViewStore();
  const params: { id: string } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState<ServiceStandardCategory>();

  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const categoryID = params.id || lastSelectedId;

  const loadCategoryDetailsByID = async (categoryID: number) => {
    setIsLoading(true);
    let errorResponse = undefined;
    const response = await loadCategoryById(categoryID);
    setIsLoading(false);
    if (isSuccessResponse(response)) {
      setCategory(response.data);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }

    setResponseLoadError(errorResponse);
  };

  useEffect(() => {
    if (!isNew && categoryID) loadCategoryDetailsByID(categoryID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryID]);

  if (isLoading) return <Loading isLoading={isLoading} />;

  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          categoryID && loadCategoryDetailsByID(+categoryID);
        }}
      />
    );
  }

  if (!category) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Created date:"}
          value={formatDisplayValue(
            category?.Sys_CreatedDate,
            DATE_FORMAT.DATE
          )}
        />

        <ProductReferenceRow
          title={"Created by:"}
          value={category?.Sys_CreatedBy}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
