import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSCentres4yoCapacityByYear: IColumnFields[] = [
  {
    field: CRSFieldMapping.Year,
    title: "Year",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.CentreName, title: "Centre" },
  { field: CRSFieldMapping.GroupName, title: "Group" },
  {
    field: CRSFieldMapping.Real,
    title: "Real",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: CRSFieldMapping.CentrePhone, title: "Centre Phone" },
  { field: CRSFieldMapping.Forecast, title: "Forecast" },
  { field: CRSFieldMapping.PlacesAvailable, title: "Overall" },
  { field: CRSFieldMapping.Safety, title: "Safety" },
  { field: CRSFieldMapping.Reserved, title: "Reserved" },
  { field: CRSFieldMapping.TotalOffered, title: "Total Offered" },
  {
    field: CRSFieldMapping.TotalPlaced,
    title: "Total Placed",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.TotalPlacedAndWaitListed,
    title: "Total P&W",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.PlacesVacant, title: "Vacant" },
  {
    field: CRSFieldMapping.TotalWaiting,
    title: "Total Wait Listed",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.TotalPlacedOtherAndWaiting,
    title: "Total P Other and W",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.TotalPlacedandPlacedWaitListed,
    title: "Total P and P&W",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.TotalDeferrals,
    title: "Total Deferrals",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.TotalSecondYrEnrolment,
    title: "Total 2 Y Enrol",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.PlacedandPlacedWaitdeferrals,
    title: "P and P&W Deferrals",
  },
  {
    field: CRSFieldMapping.PlacedandPlacedWaitsecondyrenrolment,
    title: "P and P&W 2 Y Enrol",
  },
  {
    field: CRSFieldMapping.offeredDeferrals,
    title: "Offered Deferrals",
  },
  {
    field: CRSFieldMapping.OfferedSecondYrEnrolment,
    title: "Offered 2 Y Enrol",
  },
  {
    field: CRSFieldMapping.WaitListedDeferrals,
    title: "Wait Listed Deferrals",
  },
  {
    field: CRSFieldMapping.WaitListedSecondYrEnrolment,
    title: "Wait Listed 2 Y Enrol",
  },
];
