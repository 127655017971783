import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IConfirmReplaceMeterDialog {
  onClosePopup: () => void;
  onConfirmYes: () => void;
}

export const ConfirmReplaceMeterDialog = ({
  onClosePopup,
  onConfirmYes,
}: IConfirmReplaceMeterDialog) => {
  return (
    <ConfirmDialog
      width={"20%"}
      height={"auto"}
      title={"Warning"}
      message={"Meter Sizes are Different!"}
      subMessage={"Do you wish to update Original size to new Meter?"}
      onClosePopup={onClosePopup}
      onConfirmYes={onConfirmYes}
    />
  );
};
