import { IMeterLastReadingDetails } from "@app/products/property/meters/last-reading-details/model";
import { PROPERTY_METERS_ROUTE } from "@app/products/property/meters/[id]/constant";
import {
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IMeterLastReadingDetails>();
export const colMeterLastReadingDetailsList: IColumnFields[] = [
  {
    field: nameOf("MeterId"),
    title: "Meter ID",
    locked: true,
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => {
      return `${PROPERTY_METERS_ROUTE}/` + dataItem.MeterId;
    },
  },
  {
    field: nameOf("MeterNumber"),
    title: "Meter Number",
  },

  {
    field: nameOf("LastReadingDateTime"),
    title: "Last Reading Date Time",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("LastReading"),
    title: "Last Reading",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("LastReadingComments"),
    title: "Last Reading Comments",
  },
  {
    field: nameOf("LastReadingStatus"),
    title: "Last Reading Status",
  },
  {
    field: nameOf("PreviousReadingDateTime"),
    title: "Previous Reading Date Time",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("PreviousReading"),
    title: "Previous Reading",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("UsageInLastPeriod"),
    title: "Usage in Last Period",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("DaysInLastPeriod"),
    title: "Days in Last Period",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("DailyUsageInLastPeriod"),
    title: "Daily Usage in Last Period",
    format: DECIMAL_FORMAT.DECIMAL_4_DIGIT,
  },
  {
    field: nameOf("LastMeterReader"),
    title: "Last Meter Reader",
  },
  {
    field: nameOf("RouteNumber"),
    title: "Route Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("ReadingSeqInRoute"),
    title: "Reading Seg in Route",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("Location"),
    title: "Location",
  },
  {
    field: nameOf("ReadingInstructions"),
    title: "Reading Instructions",
  },
  {
    field: nameOf("ContactName"),
    title: "Contact Name",
  },
  {
    field: nameOf("ContactPhoneNumber"),
    title: "Contact Phone Number",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Category"),
    title: "Category",
  },
  {
    field: nameOf("MeterType"),
    title: "Meter Type",
  },
  {
    field: nameOf("Size"),
    title: "Size",
    format: DECIMAL_FORMAT.DECIMAL_3_DIGIT,
  },
  {
    field: nameOf("UnitOfMeasure"),
    title: "Unit of Measure",
  },
  {
    field: nameOf("Condition"),
    title: "Condition",
  },
  {
    field: nameOf("InstallationDate"),
    title: "Installation Date",
    format: DATE_FORMAT.DATE,
  },
];
