import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingSystemAdminCalculatedFees: IColumnFields[] = [
  {
    field: BuildingFieldMapping.Name,
    title: "Name",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.Multiplier,
    title: "Multiplier",
  },
  {
    field: BuildingFieldMapping.Value,
    title: "Value",
  },
  {
    field: BuildingFieldMapping.MinimumValue,
    title: "Minimum Value",
  },
];
