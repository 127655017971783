import {
    getAssociate,
    getSearchAssessment,
} from "@app/products/property/meters/[id]/components/action-bar/dialogs/associate/api";
import {
    IAssociate,
    SearchType,
    textFieldMapping,
} from "@app/products/property/meters/[id]/components/action-bar/dialogs/associate/model";
import { APIResponseError } from "@common/apis/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
    ComboBoxChangeEvent,
    ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
    Field,
    Form,
    FormElement,
    FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<IAssociate>();

export interface IAssociateDialogProps {
  onClose: () => void;
}

export const AssociateDialog = observer(
  ({ onClose }: IAssociateDialogProps) => {
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [initialValues, setInitialValues] = useState<
      IAssociate | undefined
    >();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const params: { id: string } = useParams();
    const meterId = params.id;
    const { pushNotification } = useCCAppNotificationStore();

    const loadInitialValue = () => {
      setIsLoading(true);
      setInitialValues(undefined);
      getAssociate(+meterId).then((data) => {
        setInitialValues(data);
        setResponseLoadError(
          !data
            ? {
                status: APIResponseStatus.NOT_FOUND,
              }
            : undefined
        );
        setIsLoading(false);
      });
    };

    useEffectOnce(() => {
      loadInitialValue();
    });

    const handleSubmit = async (data: any) => {
      onClose();
      pushNotification({
        title: "Meter associated with assessment successfully",
        type: "success",
      });
    };

    const searchTypeData = Object.values(SearchType);
    return (
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          ...initialValues,
          _option: {
            AssessmentNumber: {
              Data: [],
              Loading: false,
            },
          },
        }}
        key={JSON.stringify(initialValues)}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onSubmit, valid, onChange } = formRenderProps;
          const searchTypeValue = valueGetter("SearchType");
          const handleSearchAssessment = (event: ComboBoxFilterChangeEvent) => {
            onChange("AssessmentNumber", { value: null });
            if (event.filter.value.length >= 1) {
              onChange("_option.AssessmentNumber.Loading", { value: true });
              getSearchAssessment(event.filter).then((data) => {
                onChange("_option.AssessmentNumber.Data", { value: data });
                onChange("_option.AssessmentNumber.Loading", { value: false });
              });
            } else {
              onChange("_option.AssessmentNumber.Data", { value: [] });
            }
          };

          const handleSearchAssessmentResult = (event: ComboBoxChangeEvent) => {
            if (!event.value) return;

            onChange("AssessmentNumber", { value: event.value });
            onChange("AccountNamesAccountBeingAttached", {
              value: event.value?.AccountName ?? null,
            });
            onChange("PropertyAddressAccountBeingAttached", {
              value: event.value?.PropertyAddress ?? null,
            });
          };

          return (
            <FormElement>
              <CCDialog
                maxWidth="40%"
                height="auto"
                titleHeader="Meter Account Association"
                onClose={onClose}
                bodyElement={
                  <div className="cc-form">
                    {isLoading ? <Loading isLoading={isLoading} /> : null}
                    {responseLoadError && !isLoading ? (
                      <CCLoadFailed
                        responseError={responseLoadError}
                        onReload={loadInitialValue}
                      />
                    ) : null}

                    {initialValues ? (
                      <section className="cc-field-group">
                        <section>
                          <label className="cc-label">
                            Meter being associated
                          </label>
                          <div className="cc-custom-sub-panel-bar">
                            <section className="cc-field-group">
                              <div className="cc-form-cols-2">
                                <div className="cc-field">
                                  <label className="cc-label">Meter ID</label>
                                  <Field
                                    name={nameOf("MeterId")}
                                    component={CCInput}
                                    placeholder="Meter ID"
                                    readOnly
                                  />
                                </div>

                                <div className="cc-field">
                                  <label className="cc-label">
                                    Meter number
                                  </label>
                                  <Field
                                    name={nameOf("MeterNumber")}
                                    component={CCInput}
                                    placeholder="Meter number"
                                    readOnly
                                  />
                                </div>

                                <div className="cc-field cc-col-span-2">
                                  <label className="cc-label">
                                    Property address
                                  </label>
                                  <Field
                                    name={nameOf("PropertyAddress")}
                                    component={CCInput}
                                    placeholder="Property address"
                                    readOnly
                                  />
                                </div>

                                <div className="cc-field cc-col-span-2">
                                  <label className="cc-label">
                                    Account names
                                  </label>
                                  <Field
                                    name={nameOf("AccountNames")}
                                    component={CCInput}
                                    placeholder="Account names"
                                    readOnly
                                  />
                                </div>
                              </div>
                            </section>
                          </div>
                        </section>

                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <label className="cc-label">
                              Date of attachment
                            </label>
                            <Field
                              name={nameOf("DateOfAttachment")}
                              format={DATETIME_FORMAT.DATETIME_CONTROL}
                              component={CCDateTimePicker}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Usage entitlement
                            </label>
                            <Field
                              name={nameOf("UsageEntitlement")}
                              component={CCNumericTextBox}
                              min={1}
                              placeholder="Usage entitlement"
                            />
                          </div>

                          <div className="cc-field">
                            <label className="cc-label">Billing group</label>
                            <Field
                              name={nameOf("BillingGroup")}
                              component={CCDropDownList}
                              data={valueGetter(nameOf("BillingGroupList"))}
                            />
                          </div>
                        </div>

                        <section>
                          <label className="cc-label">
                            Account being attached
                          </label>
                          <div className="cc-custom-sub-panel-bar">
                            <section className="cc-field-group">
                              <div className="cc-form-cols-2">
                                <div className="cc-field">
                                  <label className="cc-label">Search by</label>
                                  <Field
                                    name={nameOf("SearchType")}
                                    data={searchTypeData}
                                    component={CCDropDownList}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Search assessment
                                    <CCTooltip
                                      type="validator"
                                      position="right"
                                    />
                                  </label>
                                  <Field
                                    name={nameOf("AssessmentNumber")}
                                    component={CCComboBox}
                                    placeholder={`Type ${valueGetter(
                                      nameOf("SearchType")
                                    )}`}
                                    textField={
                                      textFieldMapping[searchTypeValue]
                                    }
                                    filterable
                                    suggest
                                    data={
                                      valueGetter(
                                        "_option.AssessmentNumber.Data"
                                      ) ?? []
                                    }
                                    loading={
                                      valueGetter(
                                        "_option.AssessmentNumber.Loading"
                                      ) ?? false
                                    }
                                    onFilterChange={handleSearchAssessment}
                                    onChange={handleSearchAssessmentResult}
                                    validator={requiredValidator}
                                  />
                                </div>

                                <div className="cc-field cc-col-span-2">
                                  <label className="cc-label">
                                    Property address
                                  </label>
                                  <Field
                                    name={nameOf(
                                      "PropertyAddressAccountBeingAttached"
                                    )}
                                    component={CCInput}
                                    placeholder="Property address"
                                  />
                                </div>

                                <div className="cc-field cc-col-span-2">
                                  <label className="cc-label">
                                    Account names
                                  </label>
                                  <Field
                                    name={nameOf(
                                      "AccountNamesAccountBeingAttached"
                                    )}
                                    component={CCInput}
                                    placeholder="Account names"
                                  />
                                </div>
                              </div>
                            </section>
                          </div>
                        </section>
                      </section>
                    ) : null}
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button className={"cc-dialog-button"} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      name="CreateAndNew"
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={onSubmit}
                      disabled={!valid || !initialValues}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
