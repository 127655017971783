import {
  DTO_WorkflowDetail_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfStepsCreateDebtRecoveryExemption {
  DebtRecoveryExemption = "DebtRecoveryExemption",
}

export const keysOfSendStepsCreateDebtRecoveryExemption = [
  EKeysOfStepsCreateDebtRecoveryExemption.DebtRecoveryExemption,
];

export interface DTO_Workflow_CreateDebtRecoveryExemption {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_CreateDebtRecoveryExemption;
}

export interface DTO_WorkflowDetail_CreateDebtRecoveryExemption
  extends DTO_WorkflowDetail_Base {
  DebtRecoveryExemption: DTO_Workflow_Debt_RecoveryExemption;
}

export interface DTO_Workflow_Debt_RecoveryExemption {
  DebtRecoveryExemptionId: number;
  ToApplyFrom: Date | null;
  ToApplyTo: Date | null;
  Assessment: DTO_Assessment;
  ExemptionTypeId: number;
  Reason: string;
}

interface DTO_Assessment {
  AssessmentId: number;
  AssessmentReference: string;
  PropertyNameAddressLocality: string;
  RatePayerId: number | null;
  RatePayerName: string;
  CombinedString: string;
}

export interface RequestObjFromSpatialCreateDebtRecoveryExemption {
  AssessmentId: number;
}
export interface DTO_DebtRecoveryExemption_LOVs {
  dtoCreate_debtRecoveryExemption_LOVs: DTO_Create_DebtRecoveryExemption_LOVs;
}

export interface DTO_Create_DebtRecoveryExemption_LOVs {
  Type: DTO_LOV[];
  Method: DTO_LOV[];
}
