import { MeterStatus } from "@app/products/property/meters/[id]/components/form-steps/installation/components/form-elements/association/model";
import { InstallationDialog } from "@app/products/property/meters/[id]/components/form-steps/installation/_index";
import { useMeterStore } from "@app/products/property/meters/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const InstallationButton = observer(() => {
  const [showInstallation, setShowInstallation] = useState<boolean>(false);
  const { meter } = useMeterStore();
  const isDisable = useMemo(() => {
    if (!meter?.Status) return false;
    return meter.Status !== MeterStatus.NotInService;
  }, [meter]);
  return (
    <>
      <CCNavButton
        title="Installation"
        onClick={() => setShowInstallation(true)}
        disabled={isDisable}
      />

      {showInstallation && (
        <InstallationDialog
          onSubmit={() => {
            setShowInstallation(false);
          }}
          onClose={() => {
            setShowInstallation(false);
          }}
        />
      )}
    </>
  );
});
