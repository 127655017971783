import { odataCoreGetOrgUnit } from "@app/core/components/common/utils";
import { CCPickOrgUnits } from "@components/cc-pick-org-units/_index";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";

interface ICRMSPickKeysOrganisationUnitButtonProps {
  onSubmit: (data: any) => void;
  buttonToolTip?: string;
}
export const CrmsPickKeysOrganisationUnitButton = ({
  onSubmit,
  buttonToolTip = "Pick organisation unit",
}: ICRMSPickKeysOrganisationUnitButtonProps) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  return (
    <>
      <Button
        type="button"
        onClick={() => setIsOpenDialog(true)}
        title={buttonToolTip}
        iconClass="fas fa-plus"
      />
      {isOpenDialog && (
        <CCPickOrgUnits
          dataUrl={odataCoreGetOrgUnit(false, null)}
          setShowDialog={setIsOpenDialog}
          onSubmit={(data) => {
            onSubmit(data);
            setIsOpenDialog(false);
          }}
          onClose={() => setIsOpenDialog(false)}
          isMultiple={true}
        />
      )}
    </>
  );
};
