import { ContactManager_View_DuplicateContact } from "@app/core/contacts/duplicates/list/model";
import { CONTACT_ROUTE } from "@app/core/contacts/_id/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<ContactManager_View_DuplicateContact>();
export const colContactsDuplicates: IColumnFields[] = [
  {
    field: nameOf("Id"),
    title: "Contact ID",
    locked: true,
    linkTo: (dataItem) => `${CONTACT_ROUTE}/${dataItem[nameOf("Id")]}`,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Classification"),
    title: "Type",
  },
  {
    field: nameOf("AddressBook"),
    title: "Address Book",
  },
  {
    field: nameOf("DisplayName"),
    title: "Name",
  },
  {
    field: nameOf("HomePhone"),
    title: "Home Phone",
  },
  {
    field: nameOf("WorkPhone"),
    title: "Work Phone",
  },
  {
    field: nameOf("Mobile"),
    title: "Mobile",
  },
  {
    field: nameOf("Email"),
    title: "Email",
  },
  {
    field: nameOf("PostalAddress"),
    title: "Postal Address",
  },
  {
    field: nameOf("StreetAddress"),
    title: "Street Address",
  },
  {
    field: nameOf("Custom_Tags"),
    title: "Tags",
  },
  {
    field: nameOf("NARNumber"),
    title: "NAR ID",
  },
];
