import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { ContactManagerDialog } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/dialog/contact-manager/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface IChangeVIPButtonProps {
  disabled?: boolean;
  hidden?: boolean;
}

export const ChangeVIPButton = observer(
  ({ disabled = false, hidden = false }: IChangeVIPButtonProps) => {
    const { isShowEventVIP, setEventVIP, setShowEventVIP } =
      useCRMSEventStore();

    if (hidden) {
      return <></>;
    }

    return (
      <>
        <CCNavButton
          disabled={disabled}
          title={"Change VIP"}
          onClick={() => {
            setShowEventVIP(true);
          }}
        />
        {isShowEventVIP && (
          <ContactManagerDialog
            onClose={() => setShowEventVIP(false)}
            onSubmit={(e: any) => {
              setEventVIP(e?.[0]?.DisplayName);
              setShowEventVIP(false);
            }}
            isSubmitLoading={false}
            classification={1}
            title={"Change VIP"}
          />
        )}
      </>
    );
  }
);
