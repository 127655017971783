import { PPRPSAReferral } from "@app/products/town-planning/ppr/psa-referrals/register/model";
import { TOWN_PLANNING_PSAR_ROUTE } from "@app/products/town-planning/ppr/psa-referrals/_id/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<PPRPSAReferral>();
export const colPprPSAReferrals: IColumnFields[] = [
  {
    field: nameOf("ApplicationNumber"),
    title: "Number",
    linkTo: (dataItem) => ({
      pathname: `${TOWN_PLANNING_PSAR_ROUTE}/${dataItem.ID}`,
    }),
    locked: true,
  },
  {
    field: nameOf("LodgedDate"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  { field: nameOf("SiteAddress"), title: "Site Address" },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  { field: nameOf("Postcode"), title: "Postcode" },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("StatDays"),
    title: "Days",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: nameOf("Planner"), title: "Planner" },
  {
    field: nameOf("ReasonForPermit"),
    title: "Reason for Permit",
  },
  {
    field: nameOf("ResponsibleAuthority"),
    title: "Responsible Authority",
  },
  { field: nameOf("VROffice"), title: "Dot Region" },

  { field: nameOf("Applicant"), title: "Applicant" },
  {
    field: nameOf("AmendmentType"),
    title: "Amendment Type",
  },
  {
    field: nameOf("AmendmentReference"),
    title: "Amendment Reference Number",
  },
  { field: nameOf("ApplicantAddress"), title: "Address" },

  { field: nameOf("FileNo"), title: "File Number" },
  {
    field: nameOf("AssessmentNo"),
    title: "Assess Number",
  },
  {
    field: nameOf("Ward"),
    title: "Ward",
  },
  {
    field: nameOf("Gazzetted"),
    title: "Gazzetted",
    format: DATE_FORMAT.DATE,
  },

  {
    field: nameOf("Withdrawn"),
    title: "Withdrawn",
    format: DATE_FORMAT.DATE,
  },

  {
    field: nameOf("SubmissionDueDate"),
    title: "Submission Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("IsReferral"),
    title: "Referral",
  },
  {
    field: nameOf("Day1HearingDate"),
    title: "Day 1 Hearing Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DirectionsHearingDate"),
    title: "Directions Hearing Date",
    format: DATE_FORMAT.DATE,
  },
];
