import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface ITrimFolderVolumeSearch {
  formRenderProps: FormRenderProps;
}
export const TrimFolderVolumeSearch = observer(
  ({ formRenderProps }: ITrimFolderVolumeSearch) => {
    const { isEditing } = useSettingGlobalManageStore();
    const { getConfigDataField } = useSettingGlobalStore();

    const { valueGetter } = formRenderProps;

    const fieldUseHPECMAPI = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_UseServiceAPI
    );
    const fieldEnablePropertyFolderVolumeSearch = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_EnablePropertyFolderVolumeSearch
    );
    const fieldMaximumFolderVolume = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_MaximumFolderVolume
    );
    const fieldCharacterFolderVolumeIdenfier = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_CharacterFolderVolumeIdenfier
    );
    const fieldCallVerifyFunctionUponSave = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_CallVerifyFunctionUponSave
    );
    const fieldSetDocumentsAsFinalised = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_SetDocumentsAsFinalised
    );

    const isShowTrimFolderVolumeSearch = !valueGetter(
      fieldUseHPECMAPI?.FieldName ?? ""
    );
    const isShowVolumeSetting = valueGetter(
      fieldEnablePropertyFolderVolumeSearch?.FieldName ?? ""
    );

    return isShowTrimFolderVolumeSearch ? (
      <>
        <div className="cc-form-cols-3">
          {fieldEnablePropertyFolderVolumeSearch && (
            <SwitchGlobalSettings
              data={fieldEnablePropertyFolderVolumeSearch}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}

          {fieldCallVerifyFunctionUponSave && (
            <SwitchGlobalSettings
              data={fieldCallVerifyFunctionUponSave}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}

          {fieldSetDocumentsAsFinalised && (
            <SwitchGlobalSettings
              data={fieldSetDocumentsAsFinalised}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
        </div>

        {fieldMaximumFolderVolume && isShowVolumeSetting && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldMaximumFolderVolume}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldCharacterFolderVolumeIdenfier && isShowVolumeSetting && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldCharacterFolderVolumeIdenfier}
              isEditing={isEditing}
            />
          </div>
        )}
      </>
    ) : null;
  }
);
