import {
  apiCoreGetValidationPhysicalAddress,
  apiCorePostAddressValidationCandidate,
} from "@app/core/components/common/utils";
import { DTO_Address } from "@app/products/property/assessments/components/form-steps/modify-assessment/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postAddressValidationCandidate = async (
  key: string,
  data?: DTO_Address
): Promise<APIResponse<DTO_Address | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      apiCorePostAddressValidationCandidate(key),
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getValidationPhysicalAddress = async (
  address: DTO_Address
): Promise<APIResponse<DTO_Address | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      apiCoreGetValidationPhysicalAddress(),
      address
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
