import { loadJournalSummaryByIdsAndConfigsColumn } from "@app/products/property/journals/list/components/dialogs/create-session/api";
import { colCreateSession } from "@app/products/property/journals/list/components/dialogs/create-session/config";
import { DTO_Journal_Summary } from "@app/products/property/journals/[id]/model";
import {
  ICCViewColumn,
  ICCViewConfiguration,
} from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class JournalCreateSessionStore {
  private _journals?: DTO_Journal_Summary[] = [];
  private _columnJournalSession: IColumnFields[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get journals() {
    return toJS(this._journals);
  }
  setJournals = (journals?: DTO_Journal_Summary[]) => {
    runInAction(() => {
      this._journals = journals;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get columnJournalSession() {
    return toJS(this._columnJournalSession);
  }
  setColumnJournalSession = (columnConfig: IColumnFields[]) => {
    runInAction(() => {
      this._columnJournalSession = columnConfig;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  loadJournalSummaryByIdsAndConfigsColumn = async (journalIds: number[]) => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    let journals: DTO_Journal_Summary[] = [];
    const response = await loadJournalSummaryByIdsAndConfigsColumn(journalIds);
    let configColumn: IColumnFields[] = [...colCreateSession];
    if (Array.isArray(response)) {
      const [config, dataGrid] = response;
      if (
        isSuccessResponse(response[0]) &&
        isSuccessResponse(response[1]) &&
        dataGrid?.data &&
        config?.data
      ) {
        journals = dataGrid.data ?? [];
        const viewConfig: ICCViewColumn[] | undefined =
          config?.data?.ColumnDefinitions?.Columns;
        if (viewConfig?.length) {
          configColumn = processDynamicColumns(configColumn, viewConfig);
        }
      } else {
        let responseError: APIResponse<
          ICCViewConfiguration | DTO_Journal_Summary[] | undefined
        > = response[0];
        if (!isSuccessResponse(response[1])) {
          responseError = response[1];
        }
        if (responseError) {
          errorResponse = {
            status: responseError.status,
            error: responseError.error,
          };
        }
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }

    this.setJournals(journals);
    this.setColumnJournalSession(configColumn);
    this.setIsLoading(false);
    this.setResponseLoadError(errorResponse);
  };

  //restore
  resetStore = () => {
    runInAction(() => {
      this.setColumnJournalSession([]);
      this.setIsLoading(false);
      this.setResponseLoadError();
      this.setJournals([]);
    });
  };

  //reload
  reLoadApiConfig = (journalIds?: number[]) => {
    if (!isNil(journalIds))
      this.loadJournalSummaryByIdsAndConfigsColumn(journalIds);
  };
}

const journalCreateSessionStoreContext = createContext(
  new JournalCreateSessionStore()
);
export const useJournalCreateSessionStore = () => {
  return useContext(journalCreateSessionStoreContext);
};
