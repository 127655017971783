import {
  CS_LookupTable,
  DBRowState,
  ElementDisplayStatus,
  LookupTable,
  ServiceStandardUpdateTriggers,
} from "@app/products/crms/[id]/model";
import {
  nameOfOrgUnit,
  optionOrgUnits,
} from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/form-element/config";
import { colGroupsOrgUser } from "@app/products/crms/system-admin/lookup-tables/[id]/config";
import {
  LookuptableHandlerRequest,
  LookuptablePickOrgAndSiteuser,
  Svc_FormAction_Lookuptable,
} from "@app/products/crms/system-admin/lookup-tables/[id]/model";
import { useLookupTableStore } from "@app/products/crms/system-admin/lookup-tables/[id]/store";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { useIsNew } from "@common/hooks/useIsNew";
import { PickUserGroupOrgButton } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/buttons/pick-user-group-org/_index";
import { IDataUserGroupOrgForm } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/model";
import { IAccessControl } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/security/forms/security-settings/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCEditor } from "@components/cc-editor/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { OrgUnit } from "@components/cc-pick-org-units/model";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { EditorChangeEvent } from "@progress/kendo-react-editor";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

interface IApplicationCategoryFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<LookupTable>();

export const LookupTablesFormElement = observer(
  ({ formRenderProps }: IApplicationCategoryFormElementProps) => {
    const isNew = useIsNew();
    const { onSubmit, valueGetter, onChange } = formRenderProps;
    const [listSelected, setListSelected] = useState<IAccessControl[]>([]);
    const [isShowConfirmDeleteDialog, setIsShowConfirmDeleteDialog] =
      useState<boolean>(false);
    const {
      lookupTableLovs,
      uiControl,
      lookupTableChangeHandler,
      isFormModified,
      isInactive,
    } = useLookupTableStore();

    const specialInterest = valueGetter(nameOf("SpecialInterest"));
    const lookupTableFormObj = valueGetter("");

    const isShowParentLookup =
      uiControl?.DivParentLookup?.DisplayStatus ===
      ElementDisplayStatus.Visible;
    const isShowSystemValue =
      uiControl?.DdlEnumeratedValue?.DisplayStatus ===
      ElementDisplayStatus.Visible;
    const isShowEventType =
      valueGetter(nameOf("LookupTableType_ENUM")) === CS_LookupTable.Type &&
      !isNew;
    const isShowOrgStructure =
      valueGetter(nameOf("LookupTableType_ENUM")) ===
        CS_LookupTable.TelephoneActivityCategory && !isNew;
    const isShowTier =
      valueGetter(nameOf("LookupTableType_ENUM")) === CS_LookupTable.Tier;

    const handleOnChangeOrgStructure = (data: OrgUnit) => {
      onChange(nameOf("OrgStructure_ID"), { value: data?.ID });
      onChange(nameOf("OrgStructure_Name"), { value: data?.Hierarchy });
      let params: LookuptableHandlerRequest = {
        LookuptableFormAction: Svc_FormAction_Lookuptable.PickOrgStructure,
        Lookuptable: lookupTableFormObj,
        EventArgs: [],
        IsFirstTimeLoad: false,
      };
      if (data) {
        params = {
          ...params,
          EventArgs: [data?.ID],
        };
      }
      lookupTableChangeHandler(params, "Change org structure fail");
    };

    const handleAddNew = (value: IDataUserGroupOrgForm) => {
      const groups = value?.Groups ?? [];
      const orgStructure = value?.OrgStructure ?? [];
      const users = value?.Users ?? [];
      const data = [...groups, ...orgStructure];

      let hierarchiesIds = data.map((item) => item.ID);
      const userIds = users.map((item) => item.ID);

      if (hierarchiesIds.length === 0 && userIds.length === 0) return;

      const pickOrgAndSiteuser: LookuptablePickOrgAndSiteuser = {
        Siteusers: userIds,
        Hierarchies: hierarchiesIds,
      };

      if (pickOrgAndSiteuser) {
        const params: LookuptableHandlerRequest = {
          LookuptableFormAction: Svc_FormAction_Lookuptable.PickSpecialInterest,
          Lookuptable: lookupTableFormObj,
          EventArgs: pickOrgAndSiteuser ?? [],
          IsFirstTimeLoad: false,
        };

        lookupTableChangeHandler(
          params,
          "Select users, groups and org structures was failed"
        );
      }
    };

    const handleDeleteUser = () => {
      const ids = listSelected.map((item: IAccessControl) => item.EntityID);
      if (ids.length > 0) {
        const newList = specialInterest?.filter(
          (item: IAccessControl) => !ids.includes(item.EntityID)
        );

        onChange(nameOf("SpecialInterest"), { value: newList });

        const saveTriggers = valueGetter(nameOf("_SaveTriggers")) ?? [];

        if (
          !saveTriggers.includes(
            ServiceStandardUpdateTriggers.UpdateAccessControl
          )
        ) {
          saveTriggers.push(ServiceStandardUpdateTriggers.UpdateAccessControl);
          onChange(nameOf("_SaveTriggers"), { value: saveTriggers });
        }

        setListSelected([]);
        setIsShowConfirmDeleteDialog(false);
      }
    };

    const lookupTableTypeChangeHandler = (event: ComboBoxChangeEvent) => {
      let params: LookuptableHandlerRequest = {
        LookuptableFormAction: Svc_FormAction_Lookuptable.ChangeLookupType,
        Lookuptable: lookupTableFormObj,
        EventArgs: {},
        IsFirstTimeLoad: false,
      };
      if (event?.value) {
        params = {
          ...params,
          EventArgs: { LookupTableType_ENUM: event.value?.Key },
        };
      }
      lookupTableChangeHandler(params, "Change lookup type fail");
    };

    useEffect(() => {
      if (isFormModified) {
        onChange(nameOf("CustomerService_LookupTable_Id"), {
          value: valueGetter(nameOf("CustomerService_LookupTable_Id")),
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormModified]);

    return (
      <>
        {isShowConfirmDeleteDialog && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={`Are you sure you want to delete ${
              listSelected.length === 1 ? "this item" : "these items"
            }?`}
            onClosePopup={() => {
              setIsShowConfirmDeleteDialog(false);
            }}
            onConfirmYes={handleDeleteUser}
          />
        )}
        <FormElement className="cc-application-category-form-elements">
          <input
            type="submit"
            id="btn-submit"
            hidden
            onClick={onSubmit}
          ></input>
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Lookup type" isMandatory />
                <Field
                  name={nameOf("LookupTableType_ENUM")}
                  component={CCSearchComboBox}
                  textField="Value"
                  dataItemKey="Key"
                  data={lookupTableLovs ?? []}
                  disabled={!isNew}
                  value={getDropdownValue(
                    valueGetter(nameOf("LookupTableType_ENUM")),
                    lookupTableLovs ?? [],
                    "Key"
                  )}
                  onChange={lookupTableTypeChangeHandler}
                  validator={requiredValidator}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              {isShowParentLookup && (
                <div className="cc-field">
                  <CCValueField
                    label="Parent lookup"
                    value={uiControl?.DivParentLookup?.Value}
                  />
                </div>
              )}
              <div className="cc-field">
                <CCLabel title="Lookup value" isMandatory />
                <Field
                  disabled={isInactive}
                  name={nameOf("Description")}
                  component={CCTextArea}
                  validator={requiredValidator}
                  rows={5}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Full description" />
                <Field
                  disabled={isInactive}
                  name={nameOf("FullDescription")}
                  component={CCEditor}
                  contentStyle={{ height: 100 }}
                  value={valueGetter(nameOf("FullDescription"))}
                  onChange={(event: EditorChangeEvent) => {
                    onChange(nameOf("FullDescription"), {
                      value: event.html,
                    });
                  }}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              {isShowSystemValue && (
                <div className="cc-field">
                  <CCLabel title="System value" />
                  <Field
                    disabled={isInactive}
                    name={nameOf("LookupTable_EnumeratedValue_ENUM")}
                    textField="Value"
                    dataItemKey="Key"
                    component={CCSearchComboBox}
                    data={uiControl?.DdlEnumeratedValue?.Dataset ?? []}
                    isUseDefaultOnchange
                  />
                </div>
              )}
              <div className="cc-field">
                <CCLabel title="Publish to web" />
                <Field
                  disabled={isInactive}
                  name={nameOf("Flag_PublishToWeb")}
                  checked={valueGetter(nameOf("Flag_PublishToWeb"))}
                  component={CCSwitch}
                />
              </div>
              {!isNew && (
                <div className="cc-field">
                  <CCLabel title="Active" />
                  <Field
                    disabled={isInactive}
                    name={nameOf("Sys_DBRowState")}
                    checked={valueGetter(nameOf("Sys_DBRowState"))}
                    component={CCSwitch}
                    onChange={(event: SwitchChangeEvent) => {
                      onChange(nameOf("Sys_DBRowState"), {
                        value: event?.value
                          ? DBRowState.Active
                          : DBRowState.Inactive,
                      });
                    }}
                  />
                </div>
              )}
              <div className="cc-field">
                <CCLabel title="Sort index" />
                <Field
                  disabled={isInactive}
                  name={nameOf("SortIndex")}
                  component={CCNumericTextBox}
                />
              </div>
            </div>
            {isShowEventType && (
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Incident type" />
                  <Field
                    disabled={isInactive}
                    name={nameOf("Flag_IsTypeIncident")}
                    checked={valueGetter(nameOf("Flag_IsTypeIncident"))}
                    component={CCSwitch}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Request type" />
                  <Field
                    disabled={isInactive}
                    name={nameOf("Flag_IsTypeRequest")}
                    checked={valueGetter(nameOf("Flag_IsTypeRequest"))}
                    component={CCSwitch}
                  />
                </div>
              </div>
            )}
            {isShowOrgStructure && (
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Org structure" />
                  <Field
                    disabled={isInactive}
                    name={nameOf("OrgStructure_Name")}
                    nameDisplay="DisplayName"
                    placeholder="Select org units"
                    component={InputPickerSearch}
                    keyMapGrid={nameOfOrgUnit("Hierarchy")}
                    valueMapGrid={valueGetter(nameOf("OrgStructure_Name"))}
                    options={optionOrgUnits}
                    onChange={handleOnChangeOrgStructure}
                  />
                </div>
              </div>
            )}
            {isShowTier && (
              <>
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel title="Acknowledgment days" isMandatory />
                    <Field
                      disabled={isInactive}
                      name={nameOf("AcknowledgementDays")}
                      placeholder="Acknowledgment days"
                      validator={requiredValidator}
                      component={CCNumericTextBox}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Target days" isMandatory />
                    <Field
                      disabled={isInactive}
                      name={nameOf("TargetDays")}
                      placeholder="Target days"
                      validator={requiredValidator}
                      component={CCNumericTextBox}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="URL" />
                    <Field
                      disabled={isInactive}
                      name={nameOf("URL")}
                      placeholder="URL"
                      component={CCInput}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-1">
                  <CCLabel title="Groups & users" />
                  <CCGrid
                    columnFields={colGroupsOrgUser}
                    primaryField={"EntityID"}
                    data={valueGetter(nameOf("SpecialInterest"))}
                    selectableMode={"multiple"}
                    selectedRows={listSelected}
                    toolbar={
                      <div className="cc-grid-tools-bar">
                        <PickUserGroupOrgButton
                          onSubmit={handleAddNew}
                          maxHeight="85%"
                        />
                        <Button
                          type="button"
                          iconClass="fas fa-minus"
                          title="Remove"
                          onClick={() => setIsShowConfirmDeleteDialog(true)}
                          disabled={listSelected.length === 0}
                        />
                      </div>
                    }
                    onSelectionChange={(dataItem: IAccessControl[]) => {
                      setListSelected(dataItem ?? []);
                    }}
                    itemPerPage={50}
                    isAutoHiddenPager={false}
                    readOnly={isInactive}
                  />
                </div>
              </>
            )}
          </section>
        </FormElement>
      </>
    );
  }
);
