import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { iconColumnViewConfigAlert } from "@app/products/crms/config";
import { CRMSFieldMapping } from "@app/products/crms/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colNewEvents: IColumnFields[] = [
  {
    field: CRMSFieldMapping.RefNo,
    title: "Reference Number",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => `${CRMS_ROUTE}/${dataItem.ID}`,
  },
  ...iconColumnViewConfigAlert,
  {
    field: CRMSFieldMapping.SSSubCat,
    title: "Category",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Title,
    title: "Title",
    width: 150,
  },
  {
    field: CRMSFieldMapping.DateReceived,
    title: "Received Date",
    width: 150,
  },
  {
    field: CRMSFieldMapping.ActionOfficer,
    title: "Action Officer",
    width: 150,
  },
  {
    field: CRMSFieldMapping.RequestedBy,
    title: "Initiator",
    width: 150,
  },
  {
    field: CRMSFieldMapping.ContactDetails,
    title: "Initiator's Contact Number",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Worker,
    title: "Worker",
    width: 150,
  },
  {
    field: CRMSFieldMapping.WorkerContactDetails,
    title: "Worker's Contact Number",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Priority,
    title: "Priority",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Source,
    title: "Source",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Status,
    title: "Status",
    width: 150,
  },
  {
    field: CRMSFieldMapping.DateTarget,
    title: "Target Date",
    width: 150,
  },
  {
    field: CRMSFieldMapping.DateRecorded,
    title: "Recorded",
    width: 150,

    format: DATE_FORMAT.DATE,
  },
  {
    field: CRMSFieldMapping.RecordedBy,
    title: "Recorded By",
    width: 150,
  },
  {
    field: CRMSFieldMapping.ServiceProvidedBy,
    title: "Service Provided By",
    width: 150,
  },
  {
    field: CRMSFieldMapping.OnBehalfOf,
    title: "Service User",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Description,
    title: "Description",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Workstream_Display,
    title: "Workstream",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Event_ReferenceNoAdditional,
    title: "Portal Reference Number",
    width: 150,
  },
  {
    field: CRMSFieldMapping.OrgStructure,
    title: "Org Structure",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Coordinator,
    title: "Coordinator",
    width: 150,
  },
  {
    field: CRMSFieldMapping.DateWork,
    title: "Work Date",
    width: 150,

    format: DATE_FORMAT.DATE,
  },
  {
    field: CRMSFieldMapping.EnquiryRefNo,
    title: "Assets Reference Number",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRMSFieldMapping.Flag_ITSupport,
    title: "IT Support",
    width: 150,
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];
