import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import {
  loadGuidanceByEventId,
  loadGuidanceByIds,
} from "@app/products/crms/[id]/components/forms/components/reference-sidebar/guidance/api";
import { GuidanceContentIframe } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/guidance/components/guidance-content-iframe/_index";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import {
  KBPage,
  KBPageIDsRequest,
} from "@app/products/crms/knowledge-base/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { forEach, isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import "./_index.scss";

interface CRMSGuidanceTabProps {
  isManagePage?: boolean;
}

export const CRMSGuidanceTab = observer(
  ({ isManagePage = false }: CRMSGuidanceTabProps) => {
    const { crmsEvent } = useCRMSEventStore();
    const { lastSelectedId } = useCCProductListViewStore();
    const [isLoading, setIsLoading] = useState(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [knowledgeBase, setKnowledgeBase] = useState<KBPage[]>([]);
    const [listPanelBar, setListPanelBar] = useState<ITabProps[]>([]);

    const loadKnowledgeBaseByEventID = async (eventID: number) => {
      let errorResponse = undefined;
      setIsLoading(true);
      const response = await loadGuidanceByEventId(eventID);
      setIsLoading(false);
      if (isSuccessResponse(response)) {
        var knowledgeBases = response?.data?.ReturnObj ?? [];
        setKnowledgeBase(knowledgeBases);
        var newListPanelBar: ITabProps[] = [];
        forEach(knowledgeBases, (kb) => {
          newListPanelBar.push({
            title: getTitlePanel(kb.FileNumber, kb.Title),
            component: <GuidanceContentIframe content={kb?.Content_HTML} />,
          });
        });
        setListPanelBar(newListPanelBar);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
      setResponseLoadError(errorResponse);
    };

    const loadKnowledgeBaseByID = async (bulletinBoardIDs: string) => {
      let errorResponse = undefined;
      setIsLoading(true);
      const bodyRequest: KBPageIDsRequest = {
        BulletinBoardIDStr: bulletinBoardIDs,
      };
      const response = await loadGuidanceByIds(bodyRequest);
      setIsLoading(false);
      if (isSuccessResponse(response) && response?.data?.ReturnObj) {
        var knowledgeBases = response?.data?.ReturnObj;
        setKnowledgeBase(knowledgeBases);
        var newListPanelBar: ITabProps[] = [];
        forEach(knowledgeBases, (kb) => {
          newListPanelBar.push({
            title: kb.FileNumber + " - " + kb.Title,
            component: <GuidanceContentIframe content={kb.Content_HTML} />,
          });
        });
        setListPanelBar(newListPanelBar);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
      setResponseLoadError(errorResponse);
    };

    const loadData = async () => {
      if (isManagePage && crmsEvent?.BulletinBoardIDStr) {
        await loadKnowledgeBaseByID(crmsEvent?.BulletinBoardIDStr);
      } else {
        if (lastSelectedId) {
          await loadKnowledgeBaseByEventID(lastSelectedId);
        }
      }
    };

    useEffect(() => {
      loadData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastSelectedId, crmsEvent?.BulletinBoardIDStr]);

    if (isLoading) return <Loading isLoading={isLoading} />;

    if (responseLoadError) {
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            if (lastSelectedId) {
              loadKnowledgeBaseByEventID(lastSelectedId);
            } else if (isManagePage && crmsEvent?.BulletinBoardIDStr) {
              loadKnowledgeBaseByID(crmsEvent?.BulletinBoardIDStr);
            }
          }}
        />
      );
    }

    if (
      !knowledgeBase ||
      knowledgeBase.length === 0 ||
      listPanelBar.length === 0
    )
      return <NoData />;

    return (
      <div className="cc-right-side-bar-guidance">
        <TabTable
          recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
          initialPanels={listPanelBar}
        />
      </div>
    );
  }
);

const getTitlePanel = (
  fileNumber: string | undefined,
  title: string | undefined
) => {
  if (!isEmpty(fileNumber) && !isEmpty(title))
    return fileNumber + " - " + title;
  if (!isEmpty(fileNumber) && isEmpty(title)) return fileNumber;
  if (isEmpty(fileNumber) && !isEmpty(title)) return title;
  return "No name";
};
