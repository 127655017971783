import { KeyValuePacketWithStatus } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket } from "@common/models/identityPacket";

export const setUpCopyNewEvent = async (
  eventID: number,
  sameIssue: boolean
): Promise<
  APIResponse<IIdentityPacket<KeyValuePacketWithStatus[]> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<KeyValuePacketWithStatus[]>
    >(`/api/crms/internal/event/setup-to-new-event`, {
      params: { eventID: eventID, sameIssue: sameIssue },
    });
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
