import { IMeterMemo } from "@app/products/property/meters/[id]/components/child-screens/memos/model";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
export const nameOf = nameOfFactory<IMeterMemo>();
export const colMemos: IColumnFields[] = [
  {
    field: nameOf("MemoId"),
    title: "Memo ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Date"),
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Category"),
    title: "Category",
  },
  { field: nameOf("Memo"), title: "Memo" },
  {
    field: nameOf("ChangedOn"),
    title: "Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("ChangedBy"),
    title: "Changed By",
  },
];
