import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const samplesRoute: ICCRoute = {
  path: "samples",
  name: "Samples",
  enum: HealthManagerMenu.Samples,
  children: [
    {
      path: "by-date",
      name: "By Date",
      enum: HealthManagerMenu.SamplesByDate,
      component: require("./by-date/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      enum: HealthManagerMenu.SamplesByType,
      component: require("./by-type/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      enum: HealthManagerMenu.SamplesByOfficer,
      component: require("./by-officer/_index").default,
    },
    {
      path: "by-month",
      name: "By Month",
      enum: HealthManagerMenu.SamplesByMonth,
      component: require("./by-month/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      enum: HealthManagerMenu.SamplesByStatus,
      component: require("./by-status/_index").default,
    },
    {
      path: "by-survey",
      name: "By Survey",
      enum: HealthManagerMenu.SamplesBySurvey,
      component: require("./by-survey/_index").default,
    },
    {
      path: "surveys",
      name: "Surveys",
      enum: HealthManagerMenu.SampleSurveys,
      component: require("./surveys/_index").default,
    },
    {
      path: "last-sampled",
      name: "Last Sampled",
      enum: HealthManagerMenu.PremisesNotSampled,
      component: require("./last-sampled/_index").default,
    },
  ],
};
