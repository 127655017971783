import { ICCRoute } from "@common/constants/ICCRoute";

export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  children: [
    {
      path: "application-categories",
      name: "Application Categories",
      component: require("./application-categories/_index").default,
    },
    {
      path: "conditions",
      name: "Conditions",
      component: require("./conditions/_index").default,
    },
    {
      path: "clauses",
      name: "Clauses",
      component: require("./clauses/_index").default,
    },
    {
      path: "calculated-fees",
      name: "Calculated Fees",
      component: require("./calculated-fees/_index").default,
    },
    {
      path: "fee-schedule",
      name: "Fee Schedule",
      component: require("./fee-schedule/_index").default,
    },
    {
      path: "reports",
      name: "Reports",
      component: require("./reports/_index").default,
    },
    {
      path: "settings",
      name: "Settings",
      component: require("./settings/_index").default,
    },
  ],
};
