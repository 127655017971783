import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DTO_MasterProperty_Summary } from "./model";

export const getMasterPropertySummaryById = async (
  masterPropertyId: number
): Promise<APIResponse<DTO_MasterProperty_Summary | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_MasterProperty_Summary>(
      `/api/property/internal/MasterProperty/${masterPropertyId}/summary`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
