import { mockBuildingRegisterEasement } from "@app/products/building/registers/easements/[id]/mock";
import { IBuildingRegisterEasementFormData } from "@app/products/building/registers/easements/[id]/model";
import { sleep } from "@common/utils/common";
import { cloneDeep } from "lodash";

export const getBuildingRegisterEasementById = async (
  id?: number | string
): Promise<IBuildingRegisterEasementFormData> => {
  await sleep(1000);
  const response = cloneDeep(mockBuildingRegisterEasement);
  return response;
};
