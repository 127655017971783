import { putNotForVR } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/not-for-vr/api";
import { IEnquiriesSendResponse } from "@app/products/town-planning/ppr/enquiries/_id/components/dialogs/not-for-vr/model";
import { EnquiriesSendResponseDialog } from "@app/products/town-planning/ppr/enquiries/_id/components/dialogs/not-for-vr/_index";
import {
  PPREnquirySubmitActions,
  Register,
  RegisterStatus,
} from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { UITriggerRegister } from "@common/constants/uiTrigger";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const EnquiriesNotForVrButton = observer(() => {
  const { onSubmit, pprEnquiry, pprEnquiryId, setPPREnquiryWithLoading } =
    usePPREnquiryStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [notifications, setNotifications] =
    useState<IAppNotificationItemAddProps>();

  useAddUniqueEventEmitter([
    {
      eventType: UITriggerRegister.NotForVR.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const handleNotForVR = async (data: IEnquiriesSendResponse) => {
    if (!pprEnquiryId || !pprEnquiry) return;
    setIsLoading(true);
    const newPPREnquiry: Register = cloneDeep(pprEnquiry);
    newPPREnquiry.Comment2 = data.ClosureReason;
    newPPREnquiry.Date_Complete = data.DateClosed;
    newPPREnquiry.Outcome_KWD = data.EnquiryOutcome.Keyword_ID;
    newPPREnquiry.Date_Responded = data.DateResponded;

    const response = await putNotForVR(pprEnquiryId, newPPREnquiry);
    setIsShowDialog(false);
    if (isSuccessIdentityPacket(response)) {
      appNotificationStore.clearNotifications();
      setPPREnquiryWithLoading(response.data?.Register);
      appNotificationStore.pushNotification({
        type: "success",
        title: "Enquiry application status updated successfully.",
      });
    } else {
      setNotifications({
        autoClose: false,
        title: "Not for DTP failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    setIsLoading(false);
  };

  const isVisible = useMemo(() => {
    return RegisterStatus.Lodged === pprEnquiry?.Status_ENUM;
  }, [pprEnquiry]);

  return isVisible ? (
    <>
      <CCNavButton
        title={"Not for DTP"}
        onClick={onSubmit}
        name={PPREnquirySubmitActions.NotForVR}
      />
      {isShowDialog && (
        <EnquiriesSendResponseDialog
          onClosePopup={() => setIsShowDialog(false)}
          onSubmit={handleNotForVR}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          notifications={notifications}
        />
      )}
    </>
  ) : null;
});
