import { LocalLawsRoute } from "@app/products/local-laws/[id]/constant";
import { getProductTitle } from "@common/constants/product-control";
import { PRODUCT_TYPE } from "@common/constants/productType";
import Loading from "@components/loading/Loading";
import React from "react";
import { useHistory } from "react-router-dom";

interface ILocalLawsActivityProps {
  permitsThisMonth: number;
  renewalsDueThisWeek: number;
  newRegistrations: number;
}
const LocalLawsActivity = (localLawsActivityData: ILocalLawsActivityProps) => {
  const history = useHistory();

  return (
    <div className="cc-activity-group">
      <div className="cc-activity-group-title">
        {getProductTitle(PRODUCT_TYPE.LLPermits)}
      </div>
      {localLawsActivityData !== null ? (
        <div
          className="cc-activity-group-container"
          onClick={() => {
            history.push(LocalLawsRoute.LOCALLAWS_PERMITS_REGISTER);
          }}
        >
          <div className="cc-activity-item">
            <div className="cc-activity-name">PERMITS THIS MONTH</div>
            <div className="cc-activity-number">
              {localLawsActivityData.permitsThisMonth}
            </div>
          </div>
          <div className="cc-activity-item">
            <div className="cc-activity-name">RENEWALS DUE THIS WEEK</div>
            <div className="cc-activity-number">
              {localLawsActivityData.renewalsDueThisWeek}
            </div>
          </div>
          <div className="cc-activity-item">
            <div className="cc-activity-name">NEW REGISTRATIONS</div>
            <div className="cc-activity-number">
              {localLawsActivityData.newRegistrations}
            </div>
          </div>
        </div>
      ) : (
        <Loading isLoading />
      )}
    </div>
  );
};

export default LocalLawsActivity;
