import { TOWN_PLANNING_PPR_ENQUIRIES_LIST_VIEW_ROUTE } from "@app/products/town-planning/ppr/enquiries/_id/constant";
import { TOWN_PLANNING_PPR_APPEALS_HEARING_CALENDER_LIST_VIEW_URL } from "@app/products/town-planning/ppr/permit-appeals/hearing-calendar/contants";
import {
  TOWN_PLANNING_MY_PPR_PERMIT_REFERRALS_REGISTER_ROUTE,
  TOWN_PLANNING_PPR_PERMIT_REFERRALS_REGISTER_ROUTE,
} from "@app/products/town-planning/ppr/permit-referrals/all/register/contant";
import { PPRApplication } from "@app/products/town-planning/ppr/permit-referrals/all/register/model";
import { TOWN_PLANNING_PPR_PSA_REFERRALS_HEARING_CALENDAR_ROUTE } from "@app/products/town-planning/ppr/psa-referrals/hearing-calendar/constant";
import { TOWN_PLANNING_MY_PPR_PSA_REFERRALS_REGISTER_ROUTE } from "@app/products/town-planning/ppr/psa-referrals/register/constant";
import { ACTIONS_MY_ACTIONS_ROUTE } from "@common/pages/actions/constant";
import {
  IConfigActivitiesTownPlanning,
  PPRDashboardActivities,
} from "@common/pages/home/components/townplanning/model";
import { nameOfFactory } from "@common/utils/common";
import { CalendarViewEnum } from "@components/cc-calendar/model";
const nameOfActivitiesTownPlanning = nameOfFactory<PPRDashboardActivities>();
const nameOf = nameOfFactory<PPRApplication>();

export const activitiesTownPlanning: IConfigActivitiesTownPlanning[] = [
  {
    title: "NEW PERMIT REFERRALS",
    value: 0,
    name: nameOfActivitiesTownPlanning("TotalNewApplication"),
    link: TOWN_PLANNING_PPR_PERMIT_REFERRALS_REGISTER_ROUTE,
    childData: {
      state: {
        filter: {
          logic: "and",
          filters: [
            {
              field: nameOf("Status_Name"),
              operator: "contains",
              value: "New Application",
            },
          ],
        },
      },
    },
  },
  {
    title: "PSA HEARINGS THIS MONTH",
    value: 0,
    name: nameOfActivitiesTownPlanning("TotalPSAHearingThisMonth"),
    link: TOWN_PLANNING_PPR_PSA_REFERRALS_HEARING_CALENDAR_ROUTE,
  },
  {
    title: "APPEAL HEARINGS THIS WEEK",
    value: 0,
    name: nameOfActivitiesTownPlanning("TotalAppealHearingThisWeek"),
    link: TOWN_PLANNING_PPR_APPEALS_HEARING_CALENDER_LIST_VIEW_URL,
    childData: {
      defaultView: CalendarViewEnum.WEEK,
    },
  },
  {
    title: "MY ENQUIRIES",
    value: 0,
    name: nameOfActivitiesTownPlanning("TotalMyEnquiries"),
    link: TOWN_PLANNING_PPR_ENQUIRIES_LIST_VIEW_ROUTE,
  },
  {
    title: "MY PLANNING PERMIT REFERRALS",
    value: 0,
    name: nameOfActivitiesTownPlanning("TotalMyPPRApplication"),
    link: TOWN_PLANNING_MY_PPR_PERMIT_REFERRALS_REGISTER_ROUTE,
  },
  {
    title: "MY PSA REFERRALS",
    value: 0,
    name: nameOfActivitiesTownPlanning("TotalMyPSAApplication"),
    link: TOWN_PLANNING_MY_PPR_PSA_REFERRALS_REGISTER_ROUTE,
  },
  {
    title: "MY ACTIONS",
    value: 0,
    name: nameOfActivitiesTownPlanning("TotalMyActions"),
    link: ACTIONS_MY_ACTIONS_ROUTE,
  },
];
