import { addressSurveyorFields } from "@app/products/building/registers/pope/[id]/components/child-screens/general/components/form-element/config";
import { parentPage } from "@app/products/building/registers/pope/[id]/components/child-screens/general/components/form-element/mock";
import { IBuildingRegistersPOPEFormData } from "@app/products/building/registers/pope/[id]/model";
import { RegisterAddressPopup } from "@app/products/property/components/register-address-popup/_index";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const nameOf = nameOfFactory<IBuildingRegistersPOPEFormData>();

interface IBuildingRegistersPOPEFormElementProps {
  formRenderProps: FormRenderProps;
}

export const BuildingRegistersPOPEFormElement = observer(
  ({ formRenderProps }: IBuildingRegistersPOPEFormElementProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const history = useHistory();
    const [showAddressPopup, setShowAddressPopup] = useState(false);

    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }

      if (!formRenderProps.valid) return;
      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    const getValue = formRenderProps.valueGetter;

    return (
      <FormElement>
        {showAddressPopup && (
          <RegisterAddressPopup
            onClosePopup={() => setShowAddressPopup(false)}
          />
        )}
        {/* Reference by a label in WastewaterActionBar.tsx */}
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />

        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Contact
                <CCTooltip
                  type="validator"
                  position="right"
                  content={formRenderProps.errors.Contact}
                />
              </label>
              <Field
                onClick={() => {
                  history.push(`/managecontactrelationship/${"123"}`, {
                    parentPage,
                    recordType: "11000",
                  });
                }}
                autoComplete="off"
                name={"Contact"}
                placeholder={"Contact"}
                component={InputPicker}
                validator={validateRequired}
              />
            </div>
            <CCValueField
              name={nameOf("Contact_Address")}
              label="Address"
              value={getValue(nameOf("Contact_Address"))}
            />
            <CCValueField
              name={nameOf("Contact_Contact")}
              label="Contact"
              value={getValue(nameOf("Contact_Contact"))}
            />
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCValueField
              name={nameOf("Owner")}
              label="Owner"
              value={getValue(nameOf("Owner"))}
            />

            <CCValueField
              name={nameOf("Details")}
              label="Details"
              value={getValue(nameOf("Details"))}
            />
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Event</label>
              <Field
                name={nameOf("Description")}
                placeholder="Event"
                rows={8}
                value={getValue(nameOf("Description"))}
                component={CCTextArea}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Event start</label>
              <Field
                name={nameOf("EventStart")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Event end</label>
              <Field
                name={nameOf("EventEnd")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">File number</label>
              <Field
                name={nameOf("FileNumber")}
                placeholder={"File number"}
                component={CCInput}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <CCValueField
              name={nameOf("Lodged")}
              label="Lodged"
              value={getValue(nameOf("Lodged"))}
              format={DATE_FORMAT.DATE}
            />
            <CCValueField
              name={nameOf("Issued")}
              label="Issued"
              value={getValue(nameOf("Issued"))}
              format={DATE_FORMAT.DATE}
            />
            <div className="cc-field">
              <label className="cc-label">Event expiry</label>
              <div className="cc-label-field">
                <Field
                  name={nameOf("EventExpiry")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                />
              </div>
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Municipal building surveyor</label>
              <Field
                component={InputPicker}
                {...addressSurveyorFields(formRenderProps)}
                name={nameOf("MunicipalBuildingSurveyor")}
              />
            </div>
            <CCValueField
              name={nameOf("Decision")}
              label="Decision"
              value={getValue(nameOf("Decision"))}
            />
          </div>

          <div className="cc-form-cols-3">
            <CCValueField
              name={nameOf("OutstandingFees")}
              label="OS fees"
              value={getValue(nameOf("OutstandingFees"))}
              format={CURRENCY_FORMAT.CURRENCY1}
              style={formatOSFees(getValue(nameOf("OutstandingFees")))}
            />
            <div className="cc-field">
              <label className="cc-label">Debtor number</label>
              <Field
                name={nameOf("DebtorNumber")}
                placeholder={"Debtor number"}
                component={CCInput}
              />
            </div>
          </div>
        </div>
      </FormElement>
    );
  }
);
