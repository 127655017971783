import { decryptionHtmlForEditor } from "@app/core/communication/dialogs/components/form-elememts/message-template/components/cc-edit-html-tool/util";
import { IMobileAndEmailPreview } from "@app/core/communication/dialogs/model";
import { sanitizeWithoutParse } from "@common/utils/sanitized-parser";
import React from "react";
import "./_index.scss";

export const MobilePreview = ({ SMSContent }: IMobileAndEmailPreview) => {
  const sanitizedHtml = sanitizeWithoutParse(
    decryptionHtmlForEditor(SMSContent ?? "")
  );
  return (
    <div className="cc-smartphone">
      <div className="cc-smartphone-screen">
        <div
          className="cc-sms-content"
          dangerouslySetInnerHTML={{ __html: sanitizedHtml ?? "" }}
        />
      </div>
    </div>
  );
};
