import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCalculatedFees: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.Name,
    title: "Name",
    width: 400,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.Multiplier,
    title: "Multiplier",
    width: 400,
  },
  {
    field: TownPlanningFieldMapping.RateType,
    title: "Rate Type",
    width: 400,
  },
  {
    field: TownPlanningFieldMapping.Value,
    title: "Value",
    width: 400,
  },
  {
    field: TownPlanningFieldMapping.MinimumValue,
    title: "Minimum Value",
    width: 400,
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
