import { DTO_WorkflowHeader } from "@app/products/property/model";

export const defaultWorkflowHeaderLandTaxExempt: DTO_WorkflowHeader = {
  WorkflowDraft: {
    Workflow_Draft_Id: 0,
    WD_Workflow_Status: 3,
    WD_Workflow_Type: 0,
  },
  WorkflowApprovals: [],
  AvailableSecondaryWorkflows: [],
};
