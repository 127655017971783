export const WastewaterFieldMapping = {
  PermitNumber: "PermitNumber",
  Applicant: "Applicant",
  Owner: "Owner",
  Address: "Address",
  Suburb: "Suburb",
  AssessmentNo: "AssessmentNo",
  Status: "Status",
  TypeOfApplication: "TypeOfApplication",
  FileNumber: "FileNumber",
  SystemName: "SystemName",
  SystemType: "SystemType",
  Received: "Received",
  PTI: "PTI",
  CTU: "CTU",
  WaterAuthority: "WaterAuthority",
  Plumber: "Plumber",
  Risk: "Risk",
  CertificateNo: "CertificateNo",
  SerialNo: "SerialNo",
  LastInspection: "LastInspection",
  NextInspection: "NextInspection",
  DateInspected: "DateInspected",
  ReferenceNumber: "ReferenceNumber",
  ContactName: "ContactName",
  InspectionType: "InspectionType",
  Officer: "Officer",
  Compliance: "Compliance",
  Reason: "Reason",
  RefNo: "RefNo",
  Description: "Description",
  DateTarget: "DateTarget",
  Source: "Source",
  Category: "Category",
  Location: "Location",
  RequestedBy: "RequestedBy",
  ContactDetails: "ContactDetails",
  DueStatus: "DueStatus",
  DateRecorded: "DateRecorded",
  RecordedBy: "RecordedBy",
  OrgStructure: "OrgStructure",
  ActionOfficer: "ActionOfficer",
  Coordinator: "Coordinator",
  DateWork: "DateWork",
  EnquiryRefNo: "EnquiryRefNo",
  Event_ReferenceNoAdditional: "Event_ReferenceNoAdditional",
  OnBehalfOf: "OnBehalfOf",
  Flag_ITSupport: "Flag_ITSupport",
  Flag_Safety: "Flag_Safety",
  Flag_Risk: "Flag_Risk",
  Flag_VIP: "Flag_VIP",
  DateServed: "DateServed",
  Type: "Type",
  DateDue: "DateDue",
  DateRevoked: "DateRevoked",
  Noticee: "Noticee",
  CreatedDate: "CreatedDate",
  FeeType: "FeeType",
  AmountIssued: "AmountIssued",
  AmountOutstanding: "AmountOutstanding",
  CostCode: "CostCode",
  InvoiceNumber: "InvoiceNumber",
  DebtorNumber: "DebtorNumber",
  ExportToFinanceDate: "ExportToFinanceDate",
  Manufacturer: "Manufacturer",
  Name: "Name",
  SystemTypeClassification: "SystemTypeClassification",
  Size: "Size",
  SortIndex: "SortIndex",
  Title: "Title",
  Condition: "Condition",
  Material: "Material",
  DateInspectedMonth: "DateInspectedMonth",
};

export enum WastewaterRoute {
  WASTEWATER_SYSTEMS_REGISTER = "/wastewater/systems/register",
  WASTEWATER_SYSTEMADMIN_SETTINGS = "/wastewater/system-admin/system-settings",
}

export interface IWastewater {}
export interface IWasteWaterFormData {
  ID: string;
  Address: string;
  Owner: string;
  DetailsOfResponse: string;
  Applicant: string;
  PostalAddress: string;
  Contact: string;
  AllotmentSize: number;
  DateReceived: string;
  GPSofTank: string;
  OSFees: string;
  DateInspected: string;
  SystemType: {
    SystemType_ID: number;
    Name: string;
    Manufacturer: string;
    Size: number;
  };
  SecondarySystemType: {
    SystemType_ID: number;
    Name: string;
    Manufacturer: string;
    Size: number;
  };
  TertiarySystemType: {
    SystemType_ID: number;
    Name: string;
    Manufacturer: string;
    Size: number;
  };
  WaterAuthority: {
    Keyword_ID: Number;
    Name: string;
  };
  Plumber: {
    RID: number;
    ContactRelationshipType_Name: string;
    DisplayName: string;
    Contact: {
      Contact_ID: number;
      DisplayName: string;
      FirstName: string;
      LastName: string;
    };
  };
  Plumber_RID: number;
  Contractor_RID: number;
  Contractor: {
    RID: number;
    ContactRelationshipType_Name: string;
    DisplayName: string;
    Contact: {
      Contact_ID: number;
      DisplayName: string;
      FirstName: string;
      LastName: string;
    };
  };
  Status: string;
  // Status: {
  //   Keyword: {
  //     Keyword_ID: number;
  //     Name: string;
  //     Description: string;
  //     FullDescription: string;
  //   };
  // };
  Risk: {
    Keyword: {
      Keyword_ID: number;
      Name: string;
      Description: string;
      FullDescription: string;
    };
  };
  PermitNumber: string;
  PermitType: string;
  PermitApplication: string;
  DebtorNo: string;
  FileNumber: string;
  Assessment_ApprovalPTIDate: Date;
  Assessment_ApprovalPTIOfficer: {
    SiteUser: {
      MemberGUID: number;
      MemberUserName: string;
    };
  };
  Assessment_ApprovalPTIOfficer_ID: number;
  OutstandingFees: number;
  EffluentLinesDepth: string;
  TransBedDepth: string;
  DateSent: Date;
  SentToDOHDate: Date;
  Flag_EPAApproved: boolean;
  DOHApprovalNumber: string;
  DOHApprovalDate: string;
  LastInspection: Date;
  NextInspection: Date;
  InspectionFrequency: number;
  LastServiced: Date;
  NextService: Date;
  LastSampled: Date;
  NextSample: Date;
  NextDesludge: Date;
  Comments: string;
}
export class WastewaterResponse {
  ID: string = "";
  PermitNumber: string = "";
  Applicant: string = "";
  Owner: string = "";
  Address: string = "";
  Suburb: string = "";
  AssessmentNo: number = 0;
  Status?: string = "";
  TypeOfApplication: string = "";
  FileNumber: string = "";
  SystemName: string = "";
  SystemType: string = "";
  Received: string = "";
  PTI: string = "";
  CTU: string = "";
  WaterAuthority: string = "";
  Plumber: string = "";
  Risk: string = "";
  CertificateNo: number = 0;
  SerialNo: string = "";
  Details: string = "";
  ApplicantAddress: string = "";
  ApplicantContact: string = "";
  AllotmentSize: number = 0;
  GPS: number = 0;
  Manufacturer: string = "";
  TankSize: number = 0;
  UnitPrimary: string = "";
  PrimarySystemTypeClassification: string = "";
  SecondarySystemType: string = "";
  SecondaryManufacturer: string = "";
  SecondaryTankSize: number = 0;
  UnitSecondary: string = "";
  TertiarySystemType: string = "";
  TertiaryManufacturer: string = "";
  TertiaryTankSize: number = 0;
  UnitTertiary: string = "";
  TertiarySystemTypeClassification: string = "";
  Contractor: string = "";
  RiskLevel: string = "";
  PermitType: string = "";
  ConstructionType: string = "";
  DebtorNo: string = "";
  FileNo: string = "";
  Issued: string = "";
  IssuingOfficer: string = "";
  RespAuthApproved: boolean = false;
  ApprovalToUse: string = "";
  ApprovalOfficer: string = "";
  OutstandingFees: string = "";
  OSFees: string = "";
  ComplainceCertNo: string = "";
  ApprovalForBL: string = "";
  BLicenceNumber: string = "";
  SentToDoH: string = "";
  DoHApproval: string = "";
  DateDoHApproved: string = "";
  DoHApprovalNo: string = "";
  SerialNumber: string = "";
  LastInspection: string = "";
  Frequency: string = "";
  NextInspection: string = "";
  LastService: string = "";
  LastDesludge: string = "";
  LastSample: string = "";
  NextService: string = "";
  NextDesludge: string = "";
  NextSample: string = "";
  Comments: string = "";
  Size: string = "";
  DateInspected: string = "";
  InspectionType: string = "";
  Officer: string = "";
  DateInspectedMonth: string = "";
  DueStatus: string = "";
  Material: string = "";
  InspectionFrequency: number = 0;
  ReferenceNumber: number = 0;
  ContactName: string = "";
  Compliance: string = "";
  Reason: string = "";
  RefNo: number = 0;
  Description: string = "";
  DateTarget: string = "";
  Source: string = "";
  Category: string = "";
  Location: string = "";
  RequestedBy: string = "";
  ContactDetails: string = "";
  DateRecorded: string = "";
  RecordedBy: string = "";
  OrgStructure: string = "";
  ActionOfficer: string = "";
  Coordinator: string = "";
  DateWork: string = "";
  EnquiryRefNo: number = 0;
  Event_ReferenceNoAdditional: number = 0;
  OnBehalfOf: string = "";
  Flag_ITSupport: boolean = false;
  Flag_Safety: boolean = false;
  Flag_Risk: boolean = false;
  Flag_VIP: boolean = true;
  Flag_AuthApproved: boolean = true;
  DateServed: string = "";
  Type: string = "";
  DateDue: string = "";
  DateRevoked: string = "";
  Noticee: string = "";
  CreatedDate: string = "";
  FeeType: string = "";
  AmountIssued: string = "";
  AmountOutstanding: number = 0;
  CostCode: string = "";
  InvoiceNumber: number = 0;
  Name: string = "";
  SystemTypeClassification: string = "";
  SortIndex: number = 0;
  Title: string = "";
  Condition: string = "";
  System_ID: string = "";
}
export class WasteWaterRequest extends WastewaterResponse {}

export class WasteWaterFormData extends WastewaterResponse {}
export class WastewaterRecordsState {
  actions: number = 0;
  comments: number = 0;
  contacts: number = 0;
  documents: number = 0;
  fees: number = 0;
  inspections: number = 0;
  services: number = 0;
  samples: number = 0;
  conditions: number = 0;
  related: number = 0;
  events: number = 0;
}

export interface GridSiteUser {
  ID: number;
  Name: string;
  Position: string;
  Email: string;
  Phone: string;
  Role: string;
  WorkPhone: string;
}

export interface WWSPISchemeView {
  ID: number;
  Name: string;
  Contact: string;
  LGA: string;
  Treatment: string;
  Size: string;
  Address: string;
  Suburb: string;
  NextInspection: Date | null;
}
