import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class SettingPPRManageStore {
  private _isEditing: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isEditing(): boolean {
    return toJS(this._isEditing);
  }
  setIsEditing = (isEditing: boolean) => {
    runInAction(() => {
      this._isEditing = isEditing;
    });
  };
}

export const SettingPPRManageInstance = new SettingPPRManageStore();
const settingPPRManageStoreContext = createContext(new SettingPPRManageStore());
export const useSettingPPRManageStore = () => {
  return useContext(settingPPRManageStoreContext);
};
