import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { useSendHearingSubmissionReportStore } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/send-hearing-submission-report/store";

import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";

import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const PSARSendHearingSubmissionReportButton = observer(() => {
  const { ppr, onSubmit } = usePSARStore();
  const { isLoading } = useSendHearingSubmissionReportStore();

  const isVisible = useMemo(() => {
    return (
      ppr?.Status_ENUM === Application_Status.RequestedToHeardPanel ||
      ppr?.Status_ENUM === Application_Status.PanelReportRefusedByTeamLeader
    );
  }, [ppr]);

  return isVisible ? (
    <>
      <CCNavButton
        title="Send Hearing Submission Report for Approval"
        onClick={onSubmit}
        isLoading={isLoading}
        name={PSARSubmitActions.SendHearingSubmissionReportForApproval}
      />
    </>
  ) : null;
});
