import { odataCoreListMultiTenancy } from "@app/core/components/common/utils";
import { MultiTenancyView } from "@app/core/location-register/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { IOptionInputPicker } from "@components/cc-input-picker-search-multi-select/model";

const nameOfMultiTenancyView = nameOfFactory<MultiTenancyView>();

const multiTenancyViewCols: IColumnFields[] = [
  {
    field: nameOfMultiTenancyView("Contact"),
    title: "Owner",
    locked: true,
  },
  {
    field: nameOfMultiTenancyView("PostalAddress"),
    title: "Postal Address",
  },
  {
    field: nameOfMultiTenancyView("Address"),
    title: "Site Address",
  },
  {
    field: nameOfMultiTenancyView("Locality"),
    title: "Locality",
  },
  {
    field: nameOfMultiTenancyView("Description"),
    title: "Description",
  },
];

export const propertyOwnerOptions: IOptionInputPicker = {
  dialog: {
    titleHeader: "Pick Multi Tenancy",
    maxWidth: "60%",
  },
  boxSearch: {
    colSearch: nameOfMultiTenancyView("Contact"),
    colShowComboboxSearch: [
      { field: nameOfMultiTenancyView("Contact"), title: "Contact" },
    ],
  },
  grid: {
    columnFields: multiTenancyViewCols,
    primaryField: nameOfMultiTenancyView("ID"),
    dataUrl: odataCoreListMultiTenancy(),
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
    state: {
      sort: [
        {
          field: nameOfMultiTenancyView("Contact"),
          dir: "asc",
        },
      ],
    },
  },
};
