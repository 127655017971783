import { getDisplayTextWithDashes } from "@common/utils/common";

export const inspectionTypeListsBookmark = {
  getBookmarkListViewDisplayName() {
    return "Settings - Lookups";
  },
  getBookmarkListViewDetail() {
    return "Settings - Lookups - Inspection Type";
  },
};

export const inspectionTypeListsDetailsBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const inspectionTypeDisplayName = selectedRow.ID
      ? `- ${selectedRow.ID}`
      : "";
    return `Settings - Lookups - Inspection Type ${inspectionTypeDisplayName}`;
  },
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([selectedRow.Name, selectedRow.ID]);
  },
};
