import { pprApplicationCategoryDisplay } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/util";
import { Overlay, Zone } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import {
  ASSESSMENT_FORM_STEP,
  AssessmentFormStep,
} from "@app/products/town-planning/ppr/components/action-bar/dialogs/officer-assessment/components/form-steps/assessment/_index";
import { PROPOSED_ASSESSMENT_FORM_STEP } from "@app/products/town-planning/ppr/components/action-bar/dialogs/officer-assessment/components/form-steps/proposed-assessment/_index";
import {
  ALLOCATION_FORM_STEP,
  AllocationFormStep,
} from "@app/products/town-planning/ppr/components/action-bar/dialogs/permit-completed/components/form-steps/allocation/_index";
import { ProposedAssessmentFormStep } from "@app/products/town-planning/ppr/components/action-bar/dialogs/permit-completed/components/form-steps/proposed-assessment/_index";
import { PPRPermitCompleted } from "@app/products/town-planning/ppr/components/action-bar/dialogs/permit-completed/model";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import React, { useMemo } from "react";

interface IPermitCompletedDialogProps {
  onClose: () => void;
  onSubmit: (data: PPRPermitCompleted) => void;
  isLoading?: boolean;
}

export const PermitCompletedDialog = ({
  onClose,
  onSubmit,
  isLoading = false,
}: IPermitCompletedDialogProps) => {
  const { ppr } = usePPRStore();
  const isVisibleProposedFinal = useMemo(() => {
    return (
      ppr?.Status_ENUM === Application_Status.PermitIssued ||
      ppr?.Status_ENUM === Application_Status.ApplicationComplete
    );
  }, [ppr]);

  const initialValues: PPRPermitCompleted = useMemo(() => {
    let zoneIds: number[] = [];
    let overlayIds: number[] = [];
    let zonesDisplay: string = "";
    let overlaysDisplay: string = "";

    if (
      ppr?.AssessmentPlanner?.AssessmentPlanner_Zones &&
      ppr?.AssessmentPlanner?.AssessmentPlanner_Zones.length > 0
    ) {
      zoneIds = ppr?.AssessmentPlanner?.AssessmentPlanner_Zones?.map(
        (zone: Zone) => zone.Zone_KWD
      );
      zonesDisplay = ppr?.AssessmentPlanner?.AssessmentPlanner_Zones?.reduce(
        (pre, cur) => pre + ", " + cur.Zone_Name,
        ""
      ).slice(1);
    }
    if (
      ppr?.AssessmentPlanner?.AssessmentPlanner_Overlays &&
      ppr?.AssessmentPlanner?.AssessmentPlanner_Overlays.length > 0
    ) {
      overlayIds = ppr?.AssessmentPlanner?.AssessmentPlanner_Overlays?.map(
        (overlay: Overlay) => overlay.Overlay_KWD
      );
      overlaysDisplay =
        ppr?.AssessmentPlanner?.AssessmentPlanner_Overlays.reduce(
          (pre, cur) => pre + ", " + cur.Overlay_Name,
          ""
        ).slice(1);
    }

    return {
      // Step Proposed Assessment
      // Proposed Assessment Fields
      Flag_ProposedApplicationDetailsFilled:
        ppr?.PPRDetails.Flag_ProposedApplicationDetailsFilled,
      TotalNumberOfAdditionalDwellings_Proposed:
        ppr?.PPRDetails.TotalNumberOfAdditionalDwellings_Proposed,
      TotalNumberOfAdditionalBicycleSpaces_Proposed:
        ppr?.PPRDetails.TotalNumberOfAdditionalBicycleSpaces_Proposed,
      TotalNumberOfShowers_Proposed:
        ppr?.PPRDetails.TotalNumberOfShowers_Proposed,
      TotalNumberOfAdditionalCarParks_Proposed:
        ppr?.PPRDetails.TotalNumberOfAdditionalCarParks_Proposed,
      TotalNumberOfAdditionalCrossovers_Proposed:
        ppr?.PPRDetails.TotalNumberOfAdditionalCrossovers_Proposed,
      TotalAdditionalFloorSpace_Commercial_Proposed:
        ppr?.PPRDetails.TotalAdditionalFloorSpace_Commercial_Proposed,
      TotalAdditionalFloorSpace_Retail_Proposed:
        ppr?.PPRDetails.TotalAdditionalFloorSpace_Retail_Proposed,
      TotalAdditionalFloorSpace_Industrial_Proposed:
        ppr?.PPRDetails.TotalAdditionalFloorSpace_Industrial_Proposed,
      TotalAdditionalFloorSpace_Government_Proposed:
        ppr?.PPRDetails.TotalAdditionalFloorSpace_Government_Proposed,
      TotalAdditionalFloorSpace_Education_Proposed:
        ppr?.PPRDetails.TotalAdditionalFloorSpace_Education_Proposed,
      TotalAdditionalFloorSpace_Health_Proposed:
        ppr?.PPRDetails.TotalAdditionalFloorSpace_Health_Proposed,
      TotalAdditionalFloorSpace_Other_Proposed:
        ppr?.PPRDetails.TotalAdditionalFloorSpace_Other_Proposed,
      // Decision Assessment Fields
      TotalNumberOfAdditionalDwellings:
        ppr?.PPRDetails.TotalNumberOfAdditionalDwellings,
      TotalNumberOfAdditionalBicycleSpaces:
        ppr?.PPRDetails.TotalNumberOfAdditionalBicycleSpaces,
      TotalNumberOfShowers: ppr?.PPRDetails.TotalNumberOfShowers,
      TotalNumberOfAdditionalCarParks:
        ppr?.PPRDetails.TotalNumberOfAdditionalCarParks,
      TotalNumberOfAdditionalCrossovers:
        ppr?.PPRDetails.TotalNumberOfAdditionalCrossovers,
      TotalAdditionalFloorSpace_Commercial:
        ppr?.PPRDetails.TotalAdditionalFloorSpace_Commercial,
      TotalAdditionalFloorSpace_Retail:
        ppr?.PPRDetails.TotalAdditionalFloorSpace_Retail,
      TotalAdditionalFloorSpace_Industrial:
        ppr?.PPRDetails.TotalAdditionalFloorSpace_Industrial,
      TotalAdditionalFloorSpace_Government:
        ppr?.PPRDetails.TotalAdditionalFloorSpace_Government,
      TotalAdditionalFloorSpace_Education:
        ppr?.PPRDetails.TotalAdditionalFloorSpace_Education,
      TotalAdditionalFloorSpace_Health:
        ppr?.PPRDetails.TotalAdditionalFloorSpace_Health,
      TotalAdditionalFloorSpace_Other:
        ppr?.PPRDetails.TotalAdditionalFloorSpace_Other,
      // Planning Permit Completion
      Flag_DecisionCompletedByRA: ppr?.PPRDetails.Flag_DecisionCompletedByRA,
      RADecision_ENUM: ppr?.PPRDetails.RADecision_Int,
      Flag_EndorsedPermitSavedInPPR:
        ppr?.PPRDetails.Flag_EndorsedPermitSavedInPPR,
      SaveTriggers: [],
      ReasonForPermit: ppr?.ReasonForPermit,
      Rating_KWD: ppr?.Rating_KWD,
      Rating_Name: ppr?.Rating_Name,
      Planner_ID: ppr?.Planner_ID,
      AllocationComments: ppr?.AllocationComments,
      Zones: zoneIds,
      Overlays: overlayIds,
      ApplicationCategories:
        ppr?.ApplicationCategories?.map(
          (item) => item.ApplicationCategory_ID
        ) ?? [],
      _option: {
        ApplicationCategories: ppr
          ? ppr?.EPlanning_ID && !ppr?.ApplicationCategories_Namelist?.length
            ? ppr?.EPlanning_Categories
            : pprApplicationCategoryDisplay(ppr?.ApplicationCategories)
          : null,
        Planner: ppr?.Planner,
        ZonesDisplay: zonesDisplay,
        OverlaysDisplay: overlaysDisplay,
      },
    };
  }, [ppr]);

  const steps: IStep[] = [
    {
      label: isVisibleProposedFinal
        ? "Application Details – Proposed & Final"
        : "Application Details – Proposed",
      component: ProposedAssessmentFormStep,
      visible: true,
      key: PROPOSED_ASSESSMENT_FORM_STEP,
      options: { isVisibleProposedFinal },
    },
    {
      label: "Allocation details",
      component: AllocationFormStep,
      visible: true,
      key: ALLOCATION_FORM_STEP,
    },
    {
      label: "Assessment",
      component: AssessmentFormStep,
      visible: true,
      key: ASSESSMENT_FORM_STEP,
    },
  ];

  return (
    <CCFormStep
      onSubmit={onSubmit}
      initialSteps={steps}
      initialValues={initialValues}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="65%"
          titleHeader={"Planning Permit Completion Details"}
          onClose={onClose}
          bodyElement={renderProps.children}
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              <Button className={"cc-dialog-button"} onClick={onClose}>
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                disabled={renderProps.nextButton.disabled}
                className={"cc-dialog-button"}
                iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
