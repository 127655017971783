import {
  SECONDARY_WORKFLOW_FORM_STEP,
  SecondaryWorkflowFormStep,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-meter/components/form-elements/secondary-workflow/_index";
import { defaultSecondaryWorkflow } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-meter/components/form-elements/secondary-workflow/config";
import {
  TRANSFERRING_FORM_STEP,
  TransferringStep,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-meter/components/form-elements/transferring/_index";
import {
  ITransferMeter,
  SearchType,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-meter/components/form-elements/transferring/model";
import { isShowParkButton } from "@app/products/property/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";

interface ITransferMeterDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  transferringData: ITransferMeter | undefined;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
}
export const TransferMeterDialog = ({
  onClose,
  onSubmit,
  transferringData,
  isFromActionList = false,
  isIncompleteMode = false,
}: ITransferMeterDialogProps) => {
  const steps: IStep[] = [
    {
      label: "Transferring",
      initialValues: {
        ...transferringData,
        SearchType: SearchType.AssessmentNumber,
      },
      component: TransferringStep,
      visible: true,
      key: TRANSFERRING_FORM_STEP,
    },
    {
      label: "Secondary workflow",
      initialValues: defaultSecondaryWorkflow,
      component: SecondaryWorkflowFormStep,
      visible: true,
      key: SECONDARY_WORKFLOW_FORM_STEP,
    },
  ];
  return (
    <CCFormStep
      onSubmit={onSubmit}
      initialSteps={steps}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="60%"
          titleHeader={"Transfer Meter"}
          onClose={onClose}
          bodyElement={renderProps.children}
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              {isShowParkButton(isFromActionList, isIncompleteMode) && (
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Park
                </Button>
              )}
              <Button className={"cc-dialog-button"} onClick={onClose}>
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                disabled={renderProps.nextButton.disabled}
                className={"cc-dialog-button"}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
