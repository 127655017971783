import { apiCoreContactRoleCore } from "@app/core/components/common/utils";
import { Svc_Contact_ContactRole } from "@app/core/contacts/_id/components/child-screens/contact-roles/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getContactRoleAccordion = async (
  contactId: number | null
): Promise<APIResponse<Svc_Contact_ContactRole[]>> => {
  try {
    return await CoreAPIService.getClient().get<Svc_Contact_ContactRole[]>(
      apiCoreContactRoleCore(contactId)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
