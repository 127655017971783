import { createApplicationListAppear } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/create-application/config";
import { PPREnquirySubmitActions } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const CreateApplicationButton = observer(() => {
  const { pprEnquiry, onSubmit } = usePPREnquiryStore();

  const isVisible = useMemo(() => {
    return !createApplicationListAppear.some(
      (item) => item === pprEnquiry?.Status_ENUM
    );
  }, [pprEnquiry]);

  return isVisible ? (
    <CCNavButton
      title="Create Application"
      onClick={onSubmit}
      name={PPREnquirySubmitActions.CreateApplication}
    />
  ) : null;
});
