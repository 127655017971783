export interface Svc_Animals_Kennel {
  ID: number;
  KennelRegNo: string;
  Address: string;
  Owner: string;
  OwnerContactNo: string;
  PostalAddress: string;
  AdditionalHolder: string;
  AdditionalHolderContactNo: string;
  AdditionalHolderPostalAddress: string;
  DateLodged: Date | null;
  Status: string;
  Status_ENUM: number;
  DateEffectiveFrom: Date | null;
  DateEffectiveTo: Date | null;
  IsRenewalNoticeSent: boolean;
  IsRenewalFeeCreated: boolean;
  DueStatus: string;
  Description: string;
  DebtorNumber: string;
}

export enum AnimalKennelViewType {
  Registration,
  Active,
  Inactive,
  PendingApproval,
  RenewalRequires,
}
