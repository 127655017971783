import { putNoAppeal } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/no-appeal/api";
import { useNoAppealButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/no-appeal/store";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import {
  PPRStoreInstance,
  usePPRStore,
} from "@app/products/town-planning/ppr/[id]/store";
import NoAppealDialog from "@app/products/town-planning/ppr/components/action-bar/dialogs/no-appeal/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const noAppealValidatorList = [
  Application_Status.PermitIssued,
  Application_Status.AmendmentIssued,
  Application_Status.NoticeOfDecision,
  Application_Status.PermitRefused,
  Application_Status.AmendmentRefused,
];
export const NoAppealButton = observer(() => {
  const { isLoading, isShowDialog, setIsShowDialog, setIsLoading } =
    useNoAppealButtonStore();
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();
  const { ppr, onSubmit, pprId } = usePPRStore();

  const handleSubmitNoAppeal = async () => {
    if (pprId) {
      setIsLoading(true);
      const response = await putNoAppeal(pprId);
      setIsShowDialog(false);
      setIsLoading(false);
      if (isSuccessIdentityPacket(response)) {
        clearErrorNotification();
        PPRStoreInstance.setPPRWithLoading(response.data.Application);
        pushNotification({
          type: "success",
          title: "Record saved successfully",
        });
      } else {
        pushNotification({
          autoClose: false,
          title: "Number appeal failed",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    }
  };

  const isVisible = useMemo(() => {
    return noAppealValidatorList.some((item) => item === ppr?.Status_ENUM);
  }, [ppr]);

  return isVisible ? (
    <>
      <CCNavButton
        title="No appeal"
        onClick={onSubmit}
        name={PPRSubmitActions.NoAppeal}
      />
      {isShowDialog && (
        <NoAppealDialog
          onClosePopup={() => {
            setIsShowDialog(false);
          }}
          isLoadingYes={isLoading}
          onAsyncConfirm={handleSubmitNoAppeal}
        />
      )}
    </>
  ) : null;
});
