import { DTO_DeferredDuty_Account_Transaction } from "@app/products/property/deferred-duty/model";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_DeferredDuty_Account_Transaction>();
export const colDeferredDutyTransaction: IColumnFields[] = [
  {
    field: nameOf("JournalNumber"),
    title: "Journal Number",
    locked: true,
    linkTo: (dataItem: DTO_DeferredDuty_Account_Transaction) =>
      `${PROPERTY_JOURNALS_ROUTE}/${dataItem.JournalNumber}`,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("TransactionDate"),
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("TransactionType"),
    title: "Transactions Type",
  },
  {
    field: nameOf("Reference"),
    title: "Reference",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("ReceiptNumber"),
    title: "Receipt Number",
  },
  {
    field: nameOf("TransactionAmount"),
    title: "Transaction Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("DeferredDutyAmount"),
    title: "Deferred Duty Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InterestAmount"),
    title: "Interest Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Notes"),
    title: "Notes",
  },
  {
    field: nameOf("SessionNumber"),
    title: "Session Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("NoticeIssueDate"),
    title: "Notice Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("UserId"),
    title: "User ID",
  },
  {
    field: nameOf("IsProposed"),
    title: "Is Proposed?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("IsCancelled"),
    title: "Is Cancelled?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("CreatedOn"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("AccountTransactionId"),
    title: "Account Transaction ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
