import {
  NewRegistration,
  ParameterTypes,
  SVC_FeeCalculator_ResultStatus,
} from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/dialogs/test-fee/model";

export const getStatusFriendlyName = (
  statusEnum: SVC_FeeCalculator_ResultStatus
) => {
  const statusName: Record<SVC_FeeCalculator_ResultStatus, string> = {
    [SVC_FeeCalculator_ResultStatus.Success]: "Success",
    [SVC_FeeCalculator_ResultStatus.HasError]: "Has Error",
    [SVC_FeeCalculator_ResultStatus.NoMatchingFee]: "No Matching Fee",
    [SVC_FeeCalculator_ResultStatus.Default]: "",
  };
  return statusName[statusEnum] || "";
};

export const getKeyByValue = (
  value: string,
  enumObj: typeof NewRegistration | typeof ParameterTypes
) => {
  const keyOfItem = Object.values(enumObj).indexOf(
    value as unknown as typeof enumObj
  );
  const key = Object.keys(enumObj)[keyOfItem];
  return key;
};

export const covertToStringWithBreakLine = (listString: string[]) => {
  return `${listString.join("\n")}`;
};
