import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { colChangeOfOwnershipListDetails } from "@app/products/property/changes-of-ownership/list/detail/config";
import { VO_ChangeOfOwnership_Documents } from "@app/products/property/changes-of-ownership/list/detail/model";
import { useEffectOnce } from "react-use";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { getViewConfigurations } from "@app/products/property/api";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseError } from "@common/apis/model";
import { processDynamicColumns } from "@app/products/property/util";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";

const nameOf = nameOfFactory<VO_ChangeOfOwnership_Documents>();
export const DetailComponent = ({ dataItem }: GridDetailRowProps) => {
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedColumns, setProcessedColumns] = useState<IColumnFields[]>(
    colChangeOfOwnershipListDetails
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadViewConfiguration = () => {
    setIsLoading(true);
    getViewConfigurations(
      ViewConfiguration.ChangeOfOwnership_List_ExpanderDocuments
    ).then((response) => {
      if (isSuccessResponse(response)) {
        const viewConfig: ICCViewColumn[] | undefined =
          response?.data?.ColumnDefinitions?.Columns;
        if (!viewConfig || viewConfig?.length === 0) {
          setProcessedColumns([]);
          setIsLoading(false);
          return;
        }
        setProcessedColumns(
          processDynamicColumns(colChangeOfOwnershipListDetails, viewConfig)
        );
      } else {
        setResponseLoadError({
          status: response.status,
          error: response.error ?? "Load failed",
        });
      }
      setIsLoading(false);
    });
  };

  useEffectOnce(() => {
    loadViewConfiguration();
  });

  if (isLoading) return <Loading isLoading />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfiguration();
        }}
      />
    );

  return (
    <CCGrid
      dataUrl={`/odata/property/internal/changeofownershipdocumentsregister?&key=${dataItem.Id}&$count=true&`}
      columnFields={processedColumns}
      primaryField={nameOf("Change_of_Ownership_Id")}
    />
  );
};
