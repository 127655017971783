import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

export const DisabledButton = observer(() => {
  const history = useHistory();

  return (
    <CCNavButton
      title="Disabled"
      onClick={() => {
        history.push(`${PARKING_PERMITS_ROUTE}/new-disabled`);
      }}
    />
  );
});
