import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { isNil } from "lodash";

interface IUserOrgProps {
  ID: number;
  Name: string;
}

export const convertOrgUserToOfficerSelected = (users: IKeyValuePacket[]) => {
  if (isNil(users) || users.length === 0) return [];
  return users.map((item) => {
    let newUserSelected: IUserOrgProps = {
      ID: item.Key,
      Name: item.Value,
    };
    return newUserSelected;
  });
};
