import { Address } from "@app/products/waste-water/[id]/model";
import { isEmpty, isNil } from "lodash";

export const checkModifySimpleAddressForm = (value: Address) => {
  if (
    !isEmpty(value.PropertyAssessment?.PropertyName) ||
    !isEmpty(value.PropertyAssessment?.UnitNumber1) ||
    !isEmpty(value.PropertyAssessment?.BuildingName) ||
    !isEmpty(value.PropertyAssessment?.AddressLocationDescriptor) ||
    !isEmpty(value.Location_Description) ||
    !isEmpty(value.PropertyAssessment?.NearestCrossStreet) ||
    !isEmpty(value.NearestCrossStreet) ||
    !isNil(value.PropertyAssessment?.HouseNumber1) ||
    !isEmpty(value.PropertyAssessment?.HouseSuffix1) ||
    !isNil(value.PropertyAssessment?.HouseNumber2) ||
    !isEmpty(value.PropertyAssessment?.HouseSuffix2) ||
    !isEmpty(value.PropertyAssessment?.Street_Name) ||
    !isEmpty(value.PropertyAssessment?.Street_TypeAbbreviation) ||
    !isEmpty(value.StreetNamePart_Type) ||
    !isEmpty(value.PropertyAssessment?.Locality_Name) ||
    !isEmpty(value.PropertyAssessment?.Locality_Postcode) ||
    !isEmpty(value.State)
  ) {
    return true;
  }
  return false;
};

export const checkEmptySimpleAddressForm = (value: Address) => {
  if (
    isEmpty(value.PropertyAssessment?.PropertyName) &&
    isEmpty(value.PropertyAssessment?.UnitNumber1) &&
    isEmpty(value.PropertyAssessment?.BuildingName) &&
    isEmpty(value.PropertyAssessment?.AddressLocationDescriptor) &&
    isEmpty(value.Location_Description) &&
    isEmpty(value.PropertyAssessment?.NearestCrossStreet) &&
    isEmpty(value.NearestCrossStreet) &&
    isNil(value.PropertyAssessment?.HouseNumber1) &&
    isEmpty(value.PropertyAssessment?.HouseSuffix1) &&
    isNil(value.PropertyAssessment?.HouseNumber2) &&
    isEmpty(value.PropertyAssessment?.HouseSuffix2) &&
    isEmpty(value.PropertyAssessment?.Street_Name) &&
    isEmpty(value.PropertyAssessment?.Street_TypeAbbreviation) &&
    isEmpty(value.StreetNamePart_Type) &&
    isEmpty(value.PropertyAssessment?.Locality_Name) &&
    isEmpty(value.PropertyAssessment?.Locality_Postcode) &&
    isEmpty(value.State)
  ) {
    return true;
  }
  return false;
};
