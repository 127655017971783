import { getDisplayTextWithDashes } from "@common/utils/common";

export const CommunicationQueueBookmark = {
  getBookmarkListViewDisplayName() {
    return "Settings - Communication";
  },

  getBookmarkListViewDetail() {
    return "Settings - Communication - Queue";
  },

  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Settings - Communication - Queue ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.ID + ", " + selectedRow.Officer_DisplayName,
    ]);
  },
};
