import { DTO_LandUses } from "@app/products/property/components/dialogs/add-land-uses-dialog/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { CancelTokenSource } from "axios";

export const postSearchLandUseLookup = async (
  searchData: number[],
  requestController?: CancelTokenSource
): Promise<APIResponse<DTO_LandUses>> => {
  try {
    return await CoreAPIService.getClient().post<DTO_LandUses>(
      `api/property/int/landuse/lookup`,
      searchData,
      {
        cancelToken: requestController?.token,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error:
        requestController?.token.reason?.message ??
        errorResponse?.data?.Message,
    };
  }
};
