import { CSLUrl } from "@app/products/csl/config";
import {
  localityUsedFields,
  OfficerColumns,
} from "@app/products/csl/[id]/components/child-screens/general/components/form-element/config";
import { CSLRegisterForm } from "@app/products/csl/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

const nameOf = nameOfFactory<CSLRegisterForm>();

const pestsInvolvedFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Pets Involved",
  readOnly: true,
  placeholder: "Select Pets Involved",
  tableTypes: OfficerColumns,
  url: CSLUrl.GET_CSL_ISSUING_OFFICERS,
  fieldName: "PetsInvolved_Field",
  value: formRenderProps.valueGetter(nameOf("pestsInvolved")),
  onChange: (event: CustomEvent) => {
    formRenderProps.onChange(nameOf("pestsInvolved"), {
      value: event.detail.Name,
    });
  },
});

export const CSLFormBodyDEH = (formRenderProps: FormRenderProps) => {
  const getValue = formRenderProps.valueGetter;

  return (
    <>
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">
            Location/Site used
            <CCTooltip type="validator" position="right" />
          </label>
          <Field
            component={InputPicker}
            {...localityUsedFields(formRenderProps)}
            name={"LocalityUsed"}
            validator={requiredValidator}
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Officers/Locations</label>
          <Field
            rows={5}
            name={nameOf("OfficersLocations")}
            value={getValue(nameOf("OfficersLocations"))}
            component={CCTextArea}
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Drugs possessed</label>
          <Field
            rows={5}
            name={"DrugsPossessed"}
            value={getValue(nameOf("DrugsPossessed"))}
            component={CCTextArea}
          />
        </div>
      </div>

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Max quantity</label>
          <Field name={nameOf("MaxQuantity")} component={CCInput} />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Pests involved</label>
          <Field
            component={InputPicker}
            {...pestsInvolvedFields(formRenderProps)}
            name={"PestsInvolved"}
          />
        </div>
      </div>
    </>
  );
};
