import { IParentSection } from "@app/products/building/[id]/components/child-screens/general/components/form-element/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const parentPage: IParentSection[] = [
  { key: 1, label: "Register number", value: "20211" },
  { key: 2, label: "Type", value: "Pool/Spa" },
  {
    key: 3,
    label: "Description",
    value: "300 Beaconsfield-Emerald GUYS HILL VIC 3807",
  },
];

export const dropdownTypeData: IKeyValuePacket[] = [
  { Key: 1, Value: "Committee" },
  { Key: 2, Value: "Delegate" },
  { Key: 3, Value: "Minister" },
  { Key: 4, Value: "Senior Statutory Planner" },
  { Key: 5, Value: "Tribunal" },
  { Key: 6, Value: "DPC" },
  { Key: 7, Value: "Development Control Unit" },
  { Key: 8, Value: "Sydney Nth Planning Panel(JRPP)" },
  { Key: 9, Value: "Local Planning Panel" },
  { Key: 10, Value: "Land and Environment Court" },
];

export const dropdownStatusData: IKeyValuePacket[] = [
  { Key: 1, Value: "Open" },
  { Key: 2, Value: "Closed" },
  { Key: 3, Value: "Entered" },
  { Key: 4, Value: "Legal Enforcement" },
  { Key: 5, Value: "Decommissioned" },
];
