import { DTO_AssessmentRebateEntitlement } from "@app/products/property/assessments/[id]/components/child-screens/rebates/model";
import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<DTO_AssessmentRebateEntitlement>();
export const colEntitledToRebate: IColumnFields[] = [
  { field: nameOf("RebateType"), title: "Rebate Type" },
  {
    field: nameOf("PercentageOfFullRebate"),
    title: "Percentage of Full Rebate",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("ApplicationDate"),
    title: "Application Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("EffectiveDate"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("LossOfEligibilityDate"),
    title: "Loss of Eligibility Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("EntitledToAlternateRebateCap"),
    title: "Alternate Rebate Cap",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: nameOf("EntitledEntitiesAsString"), title: "Entitled Contacts" },
  {
    field: nameOf("ChangedOn"),
    title: "Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
  { field: nameOf("ChangedBy"), title: "Changed By" },
  {
    field: nameOf("Id"),
    title: "Rebate Entitlement ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
