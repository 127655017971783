import {
  Contact,
  ContactMethodPreferred, PropertyAssessment,
  WasteWaterSystemRegisterSummary
} from "@app/products/waste-water/[id]/model";
import { isNil } from "lodash";

export const isNullOrWhiteSpace = (input: string) => {
  return !input || !input.trim();
};

export const convertFormDataToPutData = (
  data: WasteWaterSystemRegisterSummary
) => {
  const newWaterAuthority =
    data?.WaterAuthority?.Keyword_ID === null
      ? null
      : data?.WaterAuthority?.Keyword_ID;
  const newStatus = data?.Status.Keyword_ID;
  const newRiskLevel =
    data?.RiskLevel?.Keyword_ID === null ? null : data.RiskLevel?.Keyword_ID;
  const newTypeOfApplication = data?.TypeOfApplication.Keyword_ID;
  const newSystemTypeID = data?.SystemType.SystemType_ID;
  const newSecondarySystemID = data?.SecondarySystemType
    ? data?.SecondarySystemType.SystemType_ID
    : null;
  const newTertiarySystemID = data?.TertiarySystemType
    ? data?.TertiarySystemType.SystemType_ID
    : null;
  return {
    ...data,
    WaterAuthority_KWD: newWaterAuthority,
    Status_KWD: newStatus,
    RiskLevel_KWD: newRiskLevel,
    TypeOfApplication_KWD: newTypeOfApplication,
    SystemType_ID: newSystemTypeID,
    SecondarySystemType_ID: newSecondarySystemID,
    TertiarySystemType_ID: newTertiarySystemID,
  };
};

export const getAssessmentDisplay = (
  propertyAssessment?: PropertyAssessment
) => {
  if (!propertyAssessment) return "";
  let AssessmentDisplay = "";

  if (isNullOrWhiteSpace(propertyAssessment?.Legal_Description) === false)
    AssessmentDisplay = propertyAssessment.Legal_Description;

  if (isNullOrWhiteSpace(propertyAssessment.Valuation_Number) === false) {
    if (isNullOrWhiteSpace(AssessmentDisplay))
      AssessmentDisplay = "Val: " + propertyAssessment.Valuation_Number;
    else
      AssessmentDisplay =
        AssessmentDisplay +
        "<BR/>" +
        "Val: " +
        propertyAssessment.Valuation_Number;
  }

  if (isNullOrWhiteSpace(propertyAssessment.PID_No) === false) {
    if (isNullOrWhiteSpace(AssessmentDisplay))
      AssessmentDisplay = "Assess: " + propertyAssessment.PID_No;
    else AssessmentDisplay += `<BR/>Assess: ${propertyAssessment.PID_No}`;
  }
  if (isNullOrWhiteSpace(propertyAssessment.Parcel_Reference) === false) {
    if (isNullOrWhiteSpace(AssessmentDisplay))
      AssessmentDisplay = "Parcel Ref: " + propertyAssessment.Parcel_Reference;
    else
      AssessmentDisplay =
        AssessmentDisplay +
        "<BR/>" +
        "Parcel Ref: " +
        propertyAssessment.Parcel_Reference;
  }

  return AssessmentDisplay;
};

export const getPreferredMethod_Formatted = (contact: Contact) => {
  if (!contact) return "";
  switch (contact.PreferredMethod_ENUM) {
    case ContactMethodPreferred.WorkPhone:
      return contact?._WorkPhone + " (W)";
    case ContactMethodPreferred.HomePhone:
      return contact?._HomePhone + " (H)";
    case ContactMethodPreferred.Mobile:
      return contact?._Mobile;
    case ContactMethodPreferred.Fax:
      return contact?._Fax + " (F)";
    case ContactMethodPreferred.Email:
    case ContactMethodPreferred.EmailAndPost:
      return contact?.Email;
    case ContactMethodPreferred.Post:
      if (contact?.PostalAddress != null)
        return contact?.PostalAddress.FullAddress_Text;
      else return "(Post) ";
    default:
      return "";
  }
};

export const dropdownValidatorObject = (data: any) => {
  if (!data || isNil(data?.Keyword_ID))
    return "The value for dropdown is not valid.";
  else return "";
};
