import {
  DTO_ImportValuationHeader,
  DTO_Supplementary_Import,
} from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/dialogs/import-valuations/model";
import { ReturnMessage } from "@app/products/property/system-admin/document-template/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getImportValuationLOVs = async (): Promise<
  APIResponse<DTO_Supplementary_Import | undefined>
> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Supplementary_Import>(
        `api/property/internal/supplementary/import/lovs`
      );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const updateImportValuationHeader = async (
  payload: DTO_ImportValuationHeader
): Promise<APIResponse<ReturnMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/supplementary/import/updateheader`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const importValuations = async (
  formData: FormData
): Promise<APIResponse<ReturnMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/supplementary/import`,
      formData
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
