import { fnt_Title_LookupResult } from "@app/products/property/components/dialogs/add-title-lookup/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

/**
 *  1 - Legal description
 *  2 - Owner
 *  3 - Volume/Folio
 *  4 - Title Id
 *  5 - Title reference ($Account No (6 Char))
 *  6 - Assessment Id
 */
export enum eOptionSearchTitleLookup {
  LegalDescription = 1,
  Owner = 2,
  Volume_Folio = 3,
  TitleId = 4,
  TitleReference = 5,
  AssessmentId = 6, //only use in background processes, not show in Title Lookup Search By dropdown
}

const nameOf = nameOfFactory<fnt_Title_LookupResult>();
export const listConfigNumberTypeTitle = [4, 5, 6];
export const colTitleLookup: IColumnFields[] = [
  {
    field: nameOf("Title_Reference_Number"),
    title: "$Account No (6 Char)",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Title_Legal_Description"),
    title: "Legal Description",
  },
  {
    field: nameOf("Title_Lot"),
    title: "Lot",
  },
  {
    field: nameOf("Total_PIC_Association"),
    title: "% Area",
    format: PERCENTAGE_FORMAT.PERCENTAGE_1,
  },
  {
    field: nameOf("Plan_Type_Name"),
    title: "Plan Type",
  },
  {
    field: nameOf("Title_Plan_Number"),
    title: "Plan Number",
  },
  {
    field: nameOf("Title_Section"),
    title: "Section",
  },
  {
    field: nameOf("Name_Address"),
    title: "Name Address",
  },
  {
    field: nameOf("Title_Volume"),
    title: "Volume",
  },
  {
    field: nameOf("Title_Folio"),
    title: "Folio",
  },
  {
    field: nameOf("County_Name"),
    title: "County",
  },
  {
    field: nameOf("Parish_Name"),
    title: "Parish",
  },
  {
    field: nameOf("Title_Status_Name"),
    title: "Status",
  },
  {
    field: nameOf("Title_Division_Date"),
    title: "Division Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Title_Frontage"),
    title: "Frontage",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Title_Land_Area"),
    title: "Land Area (m2)",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Title_Is_Area_Surveyed"),
    title: "Is Area Surveyed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Coverage_Name"),
    title: "Coverage",
  },
  {
    field: nameOf("Title_Id"),
    title: "Title Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
