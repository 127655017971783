import { addDays } from "date-fns";

export const validatorDayField = (
  value: any,
  valueGetter: (name: string) => any
): string | undefined => {
  const notificationDate = valueGetter("NotificationDate");
  if (notificationDate) {
    const dateCleared = addDays(notificationDate, 1);
    if (dateCleared <= value) return "Allowed only 1 day in advance";
  }
  return undefined;
};
