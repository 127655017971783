import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import React, { ReactElement, useEffect } from "react";
import "./_index.scss";
export enum TYPE_NOTIFICATION {
  NONE = "None",
  SUCCESS = "Success",
  ERROR = "Error",
  WARNING = "Warning",
  INFORMATION = "Information",
}
export interface INotificationComp {
  primaryContent: string | ReactElement;
  secondaryContents?: string[];
  type: TYPE_NOTIFICATION;
  onClose: () => void;
  isAutoClose?: boolean;
  showCloseButton?: boolean;
}

export const NotificationComp = ({
  primaryContent,
  secondaryContents,
  type = TYPE_NOTIFICATION.NONE,
  isAutoClose = false,
  onClose,
  showCloseButton = true,
}: INotificationComp) => {
  useEffect(() => {
    if (!isAutoClose) return;
    const timeOut = setTimeout(onClose, 5000);
    return () => clearTimeout(timeOut);
  });

  const getIconClass = () => {
    switch (type) {
      case TYPE_NOTIFICATION.SUCCESS:
        return "fas fa-check";
      case TYPE_NOTIFICATION.WARNING:
        return "fas fa-pennant";
      case TYPE_NOTIFICATION.ERROR:
        return "fas fa-exclamation";
      default:
        return "fas fa-info";
    }
  };
  return (
    <div>
      <div className={`cc-notification cc-notification-${type.toLowerCase()}`}>
        <div className="cc-notification-icon">
          <i className={getIconClass()}></i>
        </div>
        <div className="cc-notification-content">
          {secondaryContents && secondaryContents.length > 0 ? (
            <PanelBar expandMode={"single"}>
              <PanelBarItem title={primaryContent} expanded>
                <ul className="cc-notification-secondary-content">
                  {secondaryContents.map((item: string) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </PanelBarItem>
            </PanelBar>
          ) : (
            primaryContent
          )}
        </div>
        {showCloseButton ? (
          <div className="cc-icon-close">
            <i className="fas fa-times" onClick={onClose}></i>
          </div>
        ) : null}
      </div>
    </div>
  );
};
