import { EKeysOfEditEntitySteps } from "@app/products/property/centrelink-customer-confirmation/view-request-file/components/dialogs/edit-entity/model";
import { AssociationsFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/associations/_index";
import { AttributesFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/attributes/_index";
import { ConcessionCardsFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/concession-cards/_index";
import { ContactDetailFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/details/_index";
import { PersonFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/person/_index";
import { PostalAndPhysicalAddressFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/_index";
import { EAddressStepperMode } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/config";
import { VotingFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/voting/_index";
import { defaultPerson } from "@app/products/property/contacts-central-names/list/components/dialogs/update-entity/config";
import { isShowParkButton } from "@app/products/property/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { defaultAssociations, defaultConcessionCards } from "./config";

interface IEntityDialogProps {
  onClose: () => void;
  onSubmit: (entity: any) => void;
  currentEntity?: any;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
}

export const EditEntityDialog = ({
  onClose,
  onSubmit,
  currentEntity,
  isIncompleteMode = false,
  isFromActionList = false,
}: IEntityDialogProps) => {
  const { gridSelectedRows } = useCCProductListViewStore();
  const [dataUpdate, setDataUpdate] = useState<any[]>(gridSelectedRows || []);

  useEffectOnce(() => {
    const listSelected = gridSelectedRows;
    setDataUpdate(listSelected);
  });

  const getInitSteps = () => {
    return dataUpdate?.map((item: any) => {
      return {
        ...item,
        Delivery: "Standard",
        Gender: item?.Gender === "Male" ? "M" : "F",
        Surname: item?.Name?.split(".")[0] ?? "",
        GivenName: item?.Name?.split(".")?.[1]?.trim() ?? "",
      };
    })?.[0];
  };

  const processedEntitiesItem = () => {
    if (currentEntity)
      return {
        ...currentEntity,
      };
    return {
      ...getInitSteps(),
    };
  };

  const steps: IStep[] = [
    {
      label: "Details",
      initialValues: {},
      component: ContactDetailFormStep,
      visible: true,
      key: EKeysOfEditEntitySteps.Details,
      options: {
        contactLOVs: undefined,
        isUpdate: true,
      },
    },
    {
      label: "Person",
      initialValues: { ...defaultPerson, ...processedEntitiesItem() },
      component: PersonFormStep,
      visible: true,
      key: EKeysOfEditEntitySteps.Person,
      //TODO: Update contactLOVs in options when integrate API
      options: {
        contactLOVs: undefined,
      },
    },
    {
      label: "Postal Address",
      initialValues: {},
      component: PostalAndPhysicalAddressFormStep,
      visible: true,
      key: EKeysOfEditEntitySteps.PostalAddress,
      options: {
        contactLOVs: undefined,
        keyOfPersonStep: EKeysOfEditEntitySteps.Person,
        keyOfRelatedAddressStep: EKeysOfEditEntitySteps.PhysicalAddress,
        stepperMode: EAddressStepperMode.Postal,
      },
    },
    {
      label: "Physical Address",
      initialValues: {},
      component: PostalAndPhysicalAddressFormStep,
      visible: true,
      key: EKeysOfEditEntitySteps.PhysicalAddress,
      options: {
        contactLOVs: undefined,
        keyOfPersonStep: EKeysOfEditEntitySteps.Person,
        keyOfRelatedAddressStep: EKeysOfEditEntitySteps.PostalAddress,
        stepperMode: EAddressStepperMode.Physical,
      },
    },
    {
      label: "Attributes",
      initialValues: processedEntitiesItem(),
      component: AttributesFormStep,
      visible: true,
      key: EKeysOfEditEntitySteps.Attributes,
      //TODO: Update contactLOVs in options when integrate API
      options: {
        contactLOVs: undefined,
      },
    },
    {
      label: "Concession cards",
      initialValues: defaultConcessionCards,
      component: ConcessionCardsFormStep,
      visible: true,
      key: EKeysOfEditEntitySteps.ConcessionCards,
    },
    {
      label: "Related",
      initialValues: defaultAssociations,
      component: AssociationsFormStep,
      visible: true,
      key: EKeysOfEditEntitySteps.Associations,
    },
    {
      label: "Voting",
      initialValues: [],
      component: VotingFormStep,
      visible: true,
      key: EKeysOfEditEntitySteps.Voting,
    },
  ];

  return (
    <CCFormStep
      onSubmit={onSubmit}
      initialSteps={steps}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="60%"
          maxHeight="90%"
          titleHeader={"Edit Entity"}
          onClose={onClose}
          bodyElement={renderProps.children}
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              {isShowParkButton(isFromActionList, isIncompleteMode) && (
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Park
                </Button>
              )}
              <Button className={"cc-dialog-button"} onClick={onClose}>
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                disabled={renderProps.nextButton.disabled}
                className={"cc-dialog-button"}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
