export const mockMeterReadingDevicesDetailsTab = {
  DeviceName: "PDA_ALL",
  DeviceHostName: "PDA_ALL",
  AssignedReader: "Meter Reader 1",
  ActiveMeters: 4598,
  StandardReadings: 133,
  SpecialReadings: 0,
  ReadingsWaitingVerification: 133,
  ReadingsVoided: 5,
  StandardReadingRequired: 4594,
  SpecialReadingRequired: 2,
  ReadingDeviceId: 34,
};
