import { colAssessmentAssociate } from "@app/products/property/assessments/components/form-steps/associate-assessment/components/form-elements/associations/config";
import { CCDatePicker } from "@components/cc-date-picker/_index";

import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCValueField } from "@components/cc-value-field/_index";

import { getViewConfigurations } from "@app/products/property/api";
import { DTO_Assessment } from "@app/products/property/assessments/components/form-steps/associate-assessment/model";
import { colAssessmentDisassociate } from "@app/products/property/assessments/components/form-steps/disassociate-assessment/components/form-elements/associations/config";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import { BOOLEAN_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOfAssociate = nameOfFactory<DTO_Assessment>();

export const DisAssociationAssessmentFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    isLoadingStep,
    setIsLoadingStep,
    loadFailedStep,
    setLoadFailedStep,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    // Variable
    const assessmentsAssociate = getFieldValue("Assessments") ?? [];

    // State
    const [columnsDisassociate, setColumnsDisassociate] = useState<
      IColumnFields[]
    >(colAssessmentAssociate);

    //Get labels
    const [assessmentLabel, ratepayerLabel] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Assessment,
      ECustomColNameProperty.Ratepayer,
    ]);

    const loadViewConfig = async () => {
      setIsLoadingStep(true);
      const response = await getViewConfigurations(
        ViewConfiguration.Assessment_List
      );
      setIsLoadingStep(false);
      if (isSuccessResponse(response) && response?.data) {
        const newColumnHolding = processDynamicColumns(
          colAssessmentDisassociate,
          response.data?.ColumnDefinitions?.Columns
        );
        // Handle add new column for view config
        newColumnHolding.splice(1, 0, {
          field: "Is_Primary",
          title: `Is Primary ${assessmentLabel}`,
          format: BOOLEAN_FORMAT.BOOLEAN,
        });
        setColumnsDisassociate(newColumnHolding);
      } else {
        setLoadFailedStep({
          onReload: () => {
            loadViewConfig();
          },
          responseError: {
            status: response.status,
            error: response.error ?? "Load failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      loadViewConfig();
    });

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }

    if (loadFailedStep) {
      return (
        <CCLoadFailed
          responseError={loadFailedStep?.responseError}
          onReload={loadFailedStep?.onReload}
        />
      );
    }

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Disassociation date" />
            <Field
              name={nameOf("DisassociationDate")}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label={ratepayerLabel}
              value={getFieldValue("Ratepayer")}
            />
          </div>
        </div>
        <div className="cc-form-cols-1 cc-associate-assessment">
          <div className="cc-field">
            <label className="cc-label">
              {assessmentLabel}'s to be disassociated
            </label>
            <CCGrid
              data={assessmentsAssociate ?? []}
              columnFields={columnsDisassociate}
              primaryField={nameOfAssociate("Assessment_Id")}
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>
      </section>
    );
  }
);
