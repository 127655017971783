import ResequenceMeterDialog from "@app/products/property/meters/components/dialogs/re-sequence-meter/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const ResequenceMeterButton = () => {
  const [showResequenceMeterDialog, setShowResequenceMeterDialog] =
    useState(false);

  return (
    <>
      <CCNavButton
        title="Re-sequence meter"
        onClick={() => setShowResequenceMeterDialog(true)}
      />
      {showResequenceMeterDialog && (
        <ResequenceMeterDialog
          onClosePopup={() => {
            setShowResequenceMeterDialog(false);
          }}
          onConfirmYes={() => {
            setShowResequenceMeterDialog(false);
          }}
        />
      )}
    </>
  );
};
