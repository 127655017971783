import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colReferral: IColumnFields[] = [
  {
    field: "ID",
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Name",
    title: "Name",
  },
  {
    field: "Description",
    title: "Description",
  },
];
