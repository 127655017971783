import { EAnimalSettingsCategory } from "@app/products/animals/system-admin/settings/_id/main/model";
import { IRouteStateForm } from "@app/products/animals/system-admin/settings/_id/main/routeState";

export const dogSettingsRouteStateAnimalsSettings: IRouteStateForm[] = [
  {
    name: EAnimalSettingsCategory.DogSettings_Details_PureBreed,
    component: require("./forms/dog-settings-details/pure-breed/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_ClubMember,
    component: require("./forms/dog-settings-details/club-member/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_Organisation,
    component: require("./forms/dog-settings-details/organisation/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_Menacing,
    component: require("./forms/dog-settings-details/menacing/_index").default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_HuntingDog,
    component: require("./forms/dog-settings-details/hunting-dog/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_Greyhound,
    component: require("./forms/dog-settings-details/greyhound/_index").default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_GuardDog,
    component: require("./forms/dog-settings-details/guard-dog/_index").default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_DogHomeAdoption,
    component: require("./forms/dog-settings-details/dog-home-adoption/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_ObedienceTrained,
    component: require("./forms/dog-settings-details/obedience-trained/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_CustomsDog,
    component: require("./forms/dog-settings-details/customs-dog/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_SecondaryColour,
    component: require("./forms/dog-settings-details/secondary-colour/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_HealthCareCard,
    component: require("./forms/dog-settings-details/health-care-card/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_Breed,
    component: require("./forms/dog-settings-details/breed/_index").default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_GuideDogAssistance,
    component:
      require("./forms/dog-settings-details/guide-dog-assistance/_index")
        .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_Colour,
    component: require("./forms/dog-settings-details/colour/_index").default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_DOB,
    component: require("./forms/dog-settings-details/dob/_index").default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_AssessmentNumber,
    component: require("./forms/dog-settings-details/assessment-number/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_OwnerPostalAddress,
    component:
      require("./forms/dog-settings-details/owner-postal-address/_index")
        .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_OwnerMobile,
    component: require("./forms/dog-settings-details/owner-mobile/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_AnimalName,
    component: require("./forms/dog-settings-details/animal-name/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_Gender,
    component: require("./forms/dog-settings-details/gender/_index").default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_Dangerous,
    component: require("./forms/dog-settings-details/dangerous/_index").default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_Working,
    component: require("./forms/dog-settings-details/working/_index").default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_SterlisedProof,
    component: require("./forms/dog-settings-details/sterlised-proof/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_ObedienceProof,
    component: require("./forms/dog-settings-details/obedience-proof/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_GuideOrAssistanceDogProof,
    component:
      require("./forms/dog-settings-details/guide-assistance-dog-proof/_index")
        .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_WorkingProof,
    component: require("./forms/dog-settings-details/working-proof/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_CustomsDogProof,
    component: require("./forms/dog-settings-details/customs-dog-proof/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_PureBreedProof,
    component: require("./forms/dog-settings-details/pure-breed-proof/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_GuardDogProof,
    component: require("./forms/dog-settings-details/guard-dog-proof/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_DogHomeAdoptionProof,
    component:
      require("./forms/dog-settings-details/dog-home-adoption-proof/_index")
        .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_HuntingDogProof,
    component: require("./forms/dog-settings-details/hunting-dog-proof/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_GreyhoundProof,
    component: require("./forms/dog-settings-details/greyhound-proof/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_CertifiedAssistance,
    component:
      require("./forms/dog-settings-details/certified-assistance/_index")
        .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_Details_RestrictedBreed,
    component: require("./forms/dog-settings-details/restricted-breed/_index")
      .default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_AnimalClassificationLabel,
    component: require("./forms/animal-classification-label/_index").default,
  },
  {
    name: EAnimalSettingsCategory.DogSettings_RestrictedBreed,
    component: require("./forms/restricted-breed/_index").default,
  },
];
