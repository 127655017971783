import { useIsNew } from "@common/hooks/useIsNew";
import { CheckPermissionSettingWrapper } from "@common/pages/settings/components/check-permission-wrapper/_index";
import { ExistedLookupNoticeType } from "@common/pages/settings/lookups/notice-types/_id/component/form/existed/_index";
import { NewLookupNoticeType } from "@common/pages/settings/lookups/notice-types/_id/component/form/new/_index";
import { IParentNoticeTypeSection } from "@common/pages/settings/lookups/notice-types/_id/model";
import { useNoticeTypesStore } from "@common/pages/settings/lookups/notice-types/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageLookupNoticeType = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const noticeTypeId = parseInt(params.id);
  const { state } = useLocation<{
    parent?: IParentNoticeTypeSection;
  }>();
  const { resetStore, loadNoticeType, setParent } = useNoticeTypesStore();
  const { resetMenu } = useCCSubActionBarStore();

  const [isHasPermission, setIsHasPermission] = useState<boolean>(false);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  useEffect(() => {
    if (state?.parent) {
      setParent(state?.parent);
      window.history.replaceState({}, "");
    }
    if (isHasPermission) loadNoticeType(noticeTypeId, isNew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noticeTypeId, isNew, loadNoticeType, state, isHasPermission]);

  return (
    <CheckPermissionSettingWrapper setIsHasPermission={setIsHasPermission}>
      {isNew ? <NewLookupNoticeType /> : <ExistedLookupNoticeType />}
    </CheckPermissionSettingWrapper>
  );
};

export default observer(ManageLookupNoticeType);
