import { mockFirePreventionSummary } from "@app/products/property/fire-prevention/hazld-case/[id]/mock";
import { IFirePreventionSummary } from "@app/products/property/fire-prevention/hazld-case/[id]/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";

export const getFirePreventionSummaryById = async (
  id: string
): Promise<APIResponse<IFirePreventionSummary | undefined>> => {
  await sleep(1000);
  return {
    data: {
      ...mockFirePreventionSummary,
      FirePreventionNumber: parseInt(id),
      CaseId: parseInt(id),
    },
    status: APIResponseStatus.SUCCESS,
    statusText: "Ok",
    headers: {},
    config: {},
  };
};
