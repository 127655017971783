import { Svc_LL_Notice } from "@app/products/local-laws/compliance-notices/model";

export const LocalLawsComplianceNoticesByDateBookmark = {
  getBookmarkListViewDisplayName() {
    return "Local Laws - Compliance Notices";
  },

  getBookmarkListViewDetail() {
    return "Local Laws - Compliance Notices - By Date";
  },

  getBookmarkListViewDetailRecord(selectedRow: Svc_LL_Notice) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Notices - ${dynamicDisplayName}`;
  },
};
