import { INSPECTIONS_MANAGE_ROUTE } from "@app/core/inspections/[id]/constant";
import { InspectionsView } from "@app/products/animals/inspections/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<InspectionsView>();
export const colLocalLawsInspections: IColumnFields[] = [
  {
    field: nameOf("ReferenceNumber"),
    title: "Number",
    linkTo: (dataItem: InspectionsView) => {
      return `${INSPECTIONS_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: nameOf("DateInspected"),
    title: "Inspected",
    format: DATE_FORMAT.DATE,
  },
  { field: nameOf("ContactName"), title: "Name" },
  { field: nameOf("InspectionType"), title: "Type" },
  { field: nameOf("Officer"), title: "Officer" },
  { field: nameOf("Compliance"), title: "Compliance" },
  { field: nameOf("Reason"), title: "Reason", isGrow: true },
];
