import { AnimalType } from "@app/products/animals/model";
import {
  SVC_AnimalFeeResult,
  Svc_CalculateRegistrationFee,
} from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/dialogs/test-fee/model";
import {
  AnimalsTypeLOVs,
  RegistrationFeeRulesType,
  SVC_FeeCalculator_NonRegistrationFee_RuleType,
  Svc_RegistrationFeeRulesType,
} from "@app/products/animals/system-admin/animal-types/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";

export const getAnimalTypeByID = async (
  animalTypeID: number
): Promise<APIResponse<AnimalType | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<AnimalType>(
      `/api/animals/internal/animals-type/${animalTypeID}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAnimalTypeLOVs = async (): Promise<
  APIResponse<AnimalsTypeLOVs | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<AnimalsTypeLOVs>(
      `/api/animals/internal/animal-type-lovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postAnimalTypeSave = async (
  animalType: AnimalType
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `/api/animals/internal/animals-type`,
      animalType
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSetupRegistrationFeeRulesDialog = async (
  feeRuleType: RegistrationFeeRulesType
): Promise<APIResponse<Svc_RegistrationFeeRulesType | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Svc_RegistrationFeeRulesType>(
      `/api/animals/internal/animal-type/setup-registration-fee-rule/${feeRuleType}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postCalculationNonRegistrationFee = async (
  nonRegistrationRuleTypeEnum: SVC_FeeCalculator_NonRegistrationFee_RuleType,
  animalTypeObj: AnimalType
): Promise<APIResponse<IIdentityPacket<SVC_AnimalFeeResult> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<SVC_AnimalFeeResult>
    >(
      `/api/animals/internal/animal-type/calculate-fee/non-registration/${nonRegistrationRuleTypeEnum}`,
      animalTypeObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postCalculationRegistrationFee = async (
  calculateRegistrationFeeRequest: Svc_CalculateRegistrationFee
): Promise<APIResponse<IIdentityPacket<SVC_AnimalFeeResult> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<SVC_AnimalFeeResult>
    >(
      `/api/animals/internal/animal-type/calculate-fee/registration`,
      calculateRegistrationFeeRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
