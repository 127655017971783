import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class WriteOffBalanceDialogStore {
  private _isLoadingOnDialog: boolean = false;
  private _isLoadingOnDialogCancel: boolean = false;
  private _isLoadingApprove: boolean = false;
  private _writeOffBalanceLOVs?: DTO_Journal_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoadingOnDialogCancel(): boolean {
    return this._isLoadingOnDialogCancel;
  }
  setIsLoadingOnDialogCancel = (isLoadingOnDialogCancel: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialogCancel = isLoadingOnDialogCancel;
    });
  };
  get isLoadingOnDialog(): boolean {
    return this._isLoadingOnDialog;
  }
  setIsLoadingOnDialog = (isLoadingOnDialog: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialog = isLoadingOnDialog;
    });
  };
  get isLoadingApprove(): boolean {
    return this._isLoadingApprove;
  }
  setIsLoadingApprove = (isLoadingApprove: boolean) => {
    runInAction(() => {
      this._isLoadingApprove = isLoadingApprove;
    });
  };

  get writeOffBalanceLOVs() {
    return this._writeOffBalanceLOVs;
  }
  setWriteOffBalanceLOVs = (writeOffBalanceLOVs?: any) => {
    runInAction(() => {
      this._writeOffBalanceLOVs = writeOffBalanceLOVs;
    });
  };
}

const writeOffBalanceDialogStoreContext = createContext(
  new WriteOffBalanceDialogStore()
);
export const useWriteOffBalanceDialogStore = () => {
  return useContext(writeOffBalanceDialogStoreContext);
};
