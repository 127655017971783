import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ISharepointDocument } from "@common/models/document";
import { getStringValueSetting } from "@common/stores/products/util";

const getDocumentLibrary = (
  settings: SettingsMap,
  recordType: RECORDTYPE
): ISharepointDocument | null => {
  switch (recordType) {
    case RECORDTYPE.CUSTOMERSERVICE_Event:
      return {
        documentLibrary: getStringValueSetting(
          settings[
            ECorporateSettingsField
              .CUSTOMERSERVICE_Records_SharePoint_DocumentLibrary
          ]
        ),
      };
    default:
      return null;
  }
};

export const generateSharepointURL = (
  settings: SettingsMap,
  recordType: RECORDTYPE,
  eventFileNumber: string | null | undefined
) => {
  const sharepointSetting = getStringValueSetting(
    settings[ECorporateSettingsField.SharePoint_URL]
  );
  const documentLibrary =
    getDocumentLibrary(settings, recordType)?.documentLibrary ?? "";
  return `${sharepointSetting}${documentLibrary}/Forms/AllItems.aspx?id=${eventFileNumber}`;
};
