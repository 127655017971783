import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colListOfResponses: IColumnFields[] = [
  {
    field: "ResponseSent",
    title: "Response Sent",
  },
  {
    field: "ResponseSentDateTime",
    title: "Response Sent Date Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "OrderStatus",
    title: "Order Status",
  },
  {
    field: "CouncilReference",
    title: "Council Reference",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "CertificateDocType",
    title: "Certificate Doc Type",
  },
  {
    field: "ExceptionDescription",
    title: "Exception Description",
  },
  {
    field: "Id",
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
export const colCertificateRequest: IColumnFields[] = [
  {
    field: "Description",
    title: "Description",
  },
  {
    field: "AdditionalInfo",
    title: "Additional Information",
  },
  {
    field: "Messages",
    title: "Error/Warning/Information",
  },
];
