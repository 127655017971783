import { ANIMALS_KENNEL_MANAGE_PAGE } from "@app/products/animals/kennels/[id]/constant";
import { Svc_Animals_Kennel } from "@app/products/animals/kennels/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<Svc_Animals_Kennel>();

export const colAnimalsKennelsActive: IColumnFields[] = [
  {
    field: nameOf("KennelRegNo"),
    title: "Kennel Number",
    locked: true,
    linkTo: (dataItem: Svc_Animals_Kennel) => {
      return `${ANIMALS_KENNEL_MANAGE_PAGE}/${dataItem.ID}`;
    },
  },
  {
    field: nameOf("Address"),
    title: "Kennel Address",
  },
  { field: nameOf("Owner"), title: "Owner" },
  {
    field: nameOf("OwnerContactNo"),
    title: "Contact Number",
  },
  {
    field: nameOf("PostalAddress"),
    title: "Postal Address",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("AdditionalHolder"),
    title: "Additional Kennel Holder",
  },
  {
    field: nameOf("AdditionalHolderContactNo"),
    title: "Additional Holder Contact Number",
  },
  {
    field: nameOf("AdditionalHolderPostalAddress"),
    title: "Additional Holder Postal Address",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("DateLodged"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateEffectiveFrom"),
    title: "Effective From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateEffectiveTo"),
    title: "Effective To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DebtorNumber"),
    title: "Debtor Number",
  },
];
