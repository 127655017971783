import { getDirectDebitSessionLineByAccountNumber } from "@app/products/direct-debit/accounts/[id]/components/accordion/transfers/api";
import { colDirectDebitSessionLine } from "@app/products/direct-debit/accounts/[id]/components/accordion/transfers/config";
import { DirectDebitSessionLine } from "@app/products/direct-debit/sessions/model";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IDDSessionLineByAccountNumberProps {
  accountNumber: string | undefined;
}

const nameOf = nameOfFactory<DirectDebitSessionLine>();
export const DDSessionLineByAccountNumber = ({
  accountNumber,
}: IDDSessionLineByAccountNumberProps) => {
  const { cancelToken } = useCancelRequest();
  const [transfers, setTransfers] = useState<DirectDebitSessionLine[]>();

  const ddSessionLineByAccountNumberSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: () =>
          getDirectDebitSessionLineByAccountNumber(
            accountNumber,
            cancelToken()
          ),
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<DirectDebitSessionLine[]> = dataFromApi;
          setTransfers(response?.data ?? []);
        },
      },
    ],
  });

  useEffectOnce(() => {
    ddSessionLineByAccountNumberSlider.fetchApi();
  });

  return (
    <CCGrid
      errorComponent={ddSessionLineByAccountNumberSlider.errorComponent}
      isLoading={ddSessionLineByAccountNumberSlider.isFetching}
      columnFields={colDirectDebitSessionLine}
      data={transfers ?? []}
      primaryField={nameOf("Session_ID")}
    />
  );
};
