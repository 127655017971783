import { validateRequired } from "@common/utils/field-validators";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";
import "./_index.scss";

export interface ISaveResultSearchDialog {
  onClose: () => void;
  onSubmit: () => void;
}

const initialName = {
  Name: "Assessments not in valuation group",
};
// TODO
// recordType i keep it for mapping with api
export const SaveResultSearchDialog = ({
  onClose,
  onSubmit,
}: ISaveResultSearchDialog) => {
  return (
    <Form
      initialValues={initialName}
      render={(formRenderProps: FormRenderProps) => (
        <CCDialog
          maxWidth="27%"
          maxHeight="25%"
          titleHeader="Save Confirmation"
          onClose={onClose}
          bodyElement={
            <React.Fragment>
              <h5 className="cc-label cc-label-confirm">
                Are you sure you want to save this search result with?
              </h5>
              <div className="cc-new-arrangement cc-form">
                <FormElement onSubmit={onSubmit} className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">Name</label>
                      <Field
                        name="Name"
                        component={CCInput}
                        validator={validateRequired}
                      />
                    </div>
                  </div>
                </FormElement>
              </div>
            </React.Fragment>
          }
          footerElement={
            <React.Fragment>
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  onClick={() => {
                    onClose();
                  }}
                >
                  NO
                </Button>
                <Button
                  themeColor="primary"
                  type={"submit"}
                  className="cc-dialog-button"
                  onClick={onSubmit}
                >
                  YES
                </Button>
              </div>
            </React.Fragment>
          }
        />
      )}
    />
  );
};
