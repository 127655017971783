import { loadJournalReadings } from "@app/products/property/journals/[id]/components/child-screens/readings/api";
import { colReadings } from "@app/products/property/journals/[id]/components/child-screens/readings/config";
import { CCGrid } from "@components/cc-grid/_index";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const JournalReadings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [journalReadingsData, setJournalReadingsData] = useState<any>();

  useEffectOnce(() => {
    setIsLoading(true);
    loadJournalReadings().then((data) => {
      setJournalReadingsData(data);
      setIsLoading(false);
    });
  });

  return (
    <div className="cc-property-journal-reading">
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCGrid
                isLoading={isLoading}
                className="cc-journal-reading-grid-data"
                data={journalReadingsData}
                columnFields={colReadings}
                primaryField="MeterReadingId"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
