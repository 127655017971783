import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCInput } from "@components/cc-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { mockGender } from "./mock";

export const ATTRIBUTES_FORM_STEP = "Attributes";

export const AttributesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const FormStepElement = observer(
  ({ formRenderProps, nameOf }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Date of birth</label>
              <Field
                autoComplete="off"
                name={nameOf("dateOfBirth")}
                placeholder={"Date of birth"}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Date of death</label>
              <Field
                name={nameOf("dateOfDeath")}
                placeholder={"Date of death"}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Occupation</label>
              <Field
                name={nameOf("occupation")}
                component={CCInput}
                placeholder={"Occupation"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Gender</label>
              <Field
                name={nameOf("gender")}
                textField="Value"
                dataItemKey="Key"
                data={mockGender}
                placeholder={"Gender"}
                value={getDropdownValue(
                  getFieldValue("Gender"),
                  mockGender,
                  "Value"
                )}
                component={CCComboBox}
                allowCustom
              />
            </div>
          </div>

          <div className="cc-field-group">
            <label className="cc-label">Phone numbers</label>
            <div className="cc-form-cols-2 cc-custom-sub-panel-bar">
              <div className="cc-field">
                <label className="cc-label">Private</label>
                <Field
                  name={nameOf("private")}
                  placeholder={"Private"}
                  component={CCInputPhone}
                  validator={validatePhoneNumber}
                  onChangeForm={onChange}
                  skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                  mode={EModePhoneNumber.Private}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Business</label>
                <Field
                  name={nameOf("business")}
                  placeholder={"Business"}
                  component={CCInputPhone}
                  validator={validatePhoneNumber}
                  onChangeForm={onChange}
                  skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                  mode={EModePhoneNumber.Business}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Mobile</label>
                <Field
                  name={nameOf("mobile")}
                  placeholder={"Mobile"}
                  component={CCInputPhone}
                  validator={validatePhoneNumber}
                  onChangeForm={onChange}
                  skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                  mode={EModePhoneNumber.Mobile}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Fax</label>
                <Field
                  name={nameOf("fax")}
                  placeholder={"Fax"}
                  component={CCInputPhone}
                  validator={validatePhoneNumber}
                  onChangeForm={onChange}
                  skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                  mode={EModePhoneNumber.Fax}
                />
              </div>
            </div>
          </div>
          <div className="cc-field-group">
            <label className="cc-label">Electoral commission</label>
            <div className="cc-custom-sub-panel-bar">
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">Person number</label>
                  <Field
                    name={nameOf("personNumber")}
                    component={CCInput}
                    placeholder={"Person number"}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Update date</label>
                  <Field
                    name={nameOf("updateDate")}
                    component={CCInput}
                    placeholder={"Update date"}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Vote registration date</label>
                  <Field
                    name={nameOf("voteRegistrationDate")}
                    component={CCInput}
                    placeholder={"Vote registration date"}
                  />
                </div>

                <div className="cc-field">
                  <label className="cc-label">Silent enrolment</label>
                  <Field
                    name={nameOf("IsSilentEnrolment")}
                    component={CCSwitch}
                    placeholder={"Silent enrolment"}
                    checked={getFieldValue("IsSilentEnrolment")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="cc-field-group">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">
                  Alternate address
                  <CCTooltip
                    type="validator"
                    content="(When different than postal address)"
                  />
                </label>
                <Field
                  name={nameOf("alternateAddress")}
                  rows={3}
                  value={getFieldValue("alternateAddress")}
                  component={CCTextArea}
                  placeholder={"Alternate address"}
                />
              </div>

              <div className="cc-field">
                <label className="cc-label">Also known as names</label>
                <Field
                  name={nameOf("alsoKnownAsNames")}
                  rows={3}
                  value={getFieldValue("alsoKnownAsNames")}
                  component={CCTextArea}
                  placeholder={"Also known as names"}
                />
              </div>
            </div>
          </div>
          <div className="cc-field-group">
            <div className="cc-field">
              <Field
                label="Email address"
                name={nameOf("emailAddress")}
                placeholder={"Email address"}
                component={CCInputEmail}
                validator={validateEmail}
                onChangeForm={onChange}
              />
            </div>
          </div>

          <div className="cc-field-group">
            <div className="cc-field">
              <label className="cc-label">Note</label>
              <Field
                name={nameOf("note")}
                rows={3}
                component={CCTextArea}
                placeholder={"Note"}
              />
            </div>
          </div>

          <div className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCValueField
                  label="Last changed on"
                  value={getFieldValue("ChangedOn")}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Last changed by"
                  value={getFieldValue("ChangedBy")}
                />
              </div>
              <div className="cc-field">
                <CCValueField label="Entity id" value={getFieldValue("ID")} />
              </div>
            </div>
          </div>

          <div className="cc-field-group">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">Applicant number</label>
                <Field
                  name={nameOf("applicantNumber")}
                  component={CCInput}
                  placeholder={"Applicant number"}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
);
