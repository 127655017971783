import {
  appNoFields,
  officerFields,
} from "@app/products/building/registers/enquiries/[id]/components/child-screens/general/components/form-element/config";
import {
  dropdownEnquiryData,
  parentPage,
} from "@app/products/building/registers/enquiries/[id]/mock";
import { IBuildingRegistersEnquiryFormData } from "@app/products/building/registers/enquiries/[id]/model";
import { RegisterAddressPopup } from "@app/products/property/components/register-address-popup/_index";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

interface IBuildingRegistersEnquiryFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<IBuildingRegistersEnquiryFormData>();

export const BuildingRegistersEnquiryFormElement = ({
  formRenderProps,
}: IBuildingRegistersEnquiryFormElementProps) => {
  const history = useHistory();
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const { pushNotification } = useCCAppNotificationStore();
  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }

    if (!formRenderProps.valid) return;

    pushNotification({
      title: "The form is not modified",
      type: "warning",
    });
  };

  const getValue = formRenderProps.valueGetter;

  return (
    <FormElement>
      {showAddressPopup && (
        <RegisterAddressPopup onClosePopup={() => setShowAddressPopup(false)} />
      )}
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />

      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Applicant
              <CCTooltip
                type="validator"
                position="right"
                // content={formRenderProps.errors.Contact} // TODO: pass error, ask again the behavior later
              />
            </label>
            <Field
              onClick={() => {
                history.push(`/managecontactrelationship/${"123"}`, {
                  parentPage,
                  recordType: "11000",
                });
              }}
              autoComplete="off"
              name={nameOf("Contact")}
              className="disabled"
              component={InputPicker}
              placeholder={"Applicant"}
              validator={validateRequired}
            />
          </div>
          <CCValueField
            name={nameOf("Contact_Address")}
            label="Postal address"
            value={getValue(nameOf("Contact_Address"))}
            tooltip={
              <CCTooltip
                type="validator"
                position="right"
                // content={formRenderProps.errors.Contact} // TODO: pass error, ask again the behavior later
              />
            }
          />
          <CCValueField
            name={nameOf("Contact_Contact")}
            label="Contact"
            value={getValue(nameOf("Contact_Contact"))}
          />
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Preferred contact
              <Button
                fillMode="flat"
                className="cc-icon-button cc-icon-label-button"
                onClick={(event) => {
                  event.preventDefault();
                  formRenderProps.onChange(nameOf("PreferredContact"), {
                    value: getValue(nameOf("Contact")),
                  });
                  formRenderProps.onChange(nameOf("PreferredContact_Address"), {
                    value: getValue(nameOf("Contact_Address")),
                  });
                  formRenderProps.onChange(nameOf("PreferredContact_Contact"), {
                    value: getValue(nameOf("Contact_Contact")),
                  });
                }}
                iconClass="fa fa-chevron-circle-down"
              />
            </label>
            <Field
              onClick={() => {
                history.push(`/managecontactrelationship/${"123"}`, {
                  parentPage,
                  recordType: "11000",
                });
              }}
              autoComplete="off"
              name={nameOf("PreferredContact")}
              className="disabled"
              placeholder={"Preferred contact"}
              component={InputPicker}
            />
          </div>
          <CCValueField
            name={nameOf("PreferredContact_Address")}
            label="Postal address"
            value={getValue(nameOf("PreferredContact_Address"))}
          />
          <CCValueField
            name={nameOf("PreferredContact_Contact")}
            label="Contact"
            value={getValue(nameOf("PreferredContact_Contact"))}
          />
        </div>
      </div>
      <hr className="cc-divider" />
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Site address
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              onClick={() => setShowAddressPopup(true)}
              name={nameOf("Address")}
              disabled
              validator={validateRequired}
              component={InputPicker}
              placeholder={"Site address"}
            />
          </div>
          <CCValueField
            name={nameOf("Zone")}
            label="Zone"
            value={getValue(nameOf("Zone"))}
          />
          <CCValueField
            name={nameOf("Overlay")}
            label="Overlay"
            value={getValue(nameOf("Overlay"))}
          />
        </div>
      </div>
      <hr className="cc-divider" />
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              name={nameOf("Description")}
              placeholder="Description"
              rows={8}
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Officer</label>
            <Field
              component={InputPicker}
              {...officerFields(formRenderProps)}
              name={nameOf("Officer")}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Enquiry type
              <CCTooltip type="validator" position="right" />
            </label>

            <Field
              name={nameOf("EnquiryType")}
              dataItemKey="Key"
              textField="Value"
              data={dropdownEnquiryData}
              validator={validateRequired}
              isUseDefaultOnchange
              component={CCSearchComboBox}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Application number</label>
            <Field
              component={InputPicker}
              {...appNoFields(formRenderProps)}
              name={nameOf("AppNo")}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <CCValueField
            name={nameOf("Lodged")}
            label="Lodged"
            value={getValue(nameOf("Lodged"))}
            format={DATE_FORMAT.DATE}
          />
          <CCValueField
            name={nameOf("Due")}
            label="Due"
            value={getValue(nameOf("Due"))}
            format={DATE_FORMAT.DATE}
          />
          <CCValueField
            name={nameOf("Complete")}
            label="Complete"
            value={getValue(nameOf("Complete"))}
            format={DATE_FORMAT.DATE}
          />
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">File number</label>
            <Field
              name={nameOf("FileNumber")}
              placeholder={"File number"}
              component={CCInput}
            />
          </div>
          <CCValueField
            name={nameOf("OutstandingFees")}
            label="OS fees"
            value={getValue(nameOf("OutstandingFees"))}
            format={CURRENCY_FORMAT.CURRENCY1}
            style={formatOSFees(getValue(nameOf("OutstandingFees")))}
          />
          <div className="cc-field">
            <label className="cc-label">Debtor number</label>
            <Field
              name={nameOf("DebtorNumber")}
              component={CCInput}
              placeholder={"Debtor number"}
            />
          </div>
        </div>
      </div>
    </FormElement>
  );
};
