import { ChatSidebar } from "@app/core/chat/ask-ai/reference-sidebar/_index";
import { useAskAIServiceStore } from "@app/core/chat/ask-ai/store";
import { Journal } from "@app/core/journal/_index";
import CRMSDocuments from "@app/products/crms/components/crms-documents/_index";
import CRMSServiceStandard from "@app/products/crms/components/crms-service-standards/_index";
import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { getCRMSKnowledgeBaseRecords } from "@app/products/crms/knowledge-base/[id]/api";
import { CRMSKnowledgeBaseForm } from "@app/products/crms/knowledge-base/[id]/components/forms/components/child-screens/general/_index";
import { CRMSKnowledgeBaseContactTab } from "@app/products/crms/knowledge-base/[id]/components/forms/components/reference-sidebar/contact/_index";
import { CRMSKnowledgeBaseDetailTab } from "@app/products/crms/knowledge-base/[id]/components/forms/components/reference-sidebar/details/_index";
import { CRMSKnowledgeBaseRecordState } from "@app/products/crms/knowledge-base/[id]/model";
import { useCRMSKnowledgeBaseStore } from "@app/products/crms/knowledge-base/[id]/store";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { displayDotByLimit } from "@common/utils/formatting";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ExistedCRMSKnowledgeBase = observer(() => {
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const [crmsKnowledgeBaseRecords, setCRMSKnowledgeBaseRecords] = useState<
    CRMSKnowledgeBaseRecordState | undefined
  >();
  const {
    isLoading,
    crmsKnowledgeBase,
    loadCRMSKnowledgeBase,
    crmsKnowledgeBaseId,
    responseLoadError,
  } = useCRMSKnowledgeBaseStore();
  const { currentUserInfo } = useGlobalStore();
  const { settings } = useCommonCoreStore();
  const cxLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_ProductName]
  );
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        crmsKnowledgeBase?.Description
          ? displayDotByLimit(crmsKnowledgeBase.Description, 15)
          : "Knowledge Base",
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `${CRMS_PRODUCT_NAME} - Knowledge Base - ${
        crmsKnowledgeBaseId ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      //TODO: Update enum later
      ProductType_ENUM: PRODUCT_TYPE.CRMS_HardRubbish,
      RecordType_ENUM: RECORDTYPE.SystemInitialise,
      Record_ID: crmsKnowledgeBaseId ? +crmsKnowledgeBaseId : 0,
    },
  ];
  const listPanelBar: IListPanelBar[] = [
    {
      title: "Documents",
      totalRecord: crmsKnowledgeBaseRecords?.documents,
      component: (
        <CRMSDocuments
          id={crmsKnowledgeBaseId}
          recordType={RECORDTYPE.CUSTOMERSERVICE_KBArticleTopicLink}
        />
      ),
    },
    {
      title: "Service Standards",
      totalRecord: crmsKnowledgeBaseRecords?.standardDocuments,
      component: (
        <CRMSServiceStandard
          id={crmsKnowledgeBaseId}
          recordType={RECORDTYPE.CUSTOMERSERVICE_KBArticleTopicLink}
        />
      ),
    },
  ];

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CRMSKnowledgeBaseDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Contacts",
        component: <CRMSKnowledgeBaseContactTab />,
      },
      {
        title: "History",
        component: (
          <Journal id={13347} recordType={RECORDTYPE.CUSTOMERSERVICE_KBAlert} />
        ),
      },
      {
        title: "Ask AI",
        isVisible: useAskAIServiceStore().isShowAskAI,
        component: (
          <ChatSidebar productId={PRODUCT_TYPE_NUMBER.CustomerService} />
        ),
      },
    ],
  });

  useEffectOnce(() => {
    getCRMSKnowledgeBaseRecords(
      parseInt(id),
      RECORDTYPE.CUSTOMERSERVICE_PhoneMessage
    ).then((res) => {
      setCRMSKnowledgeBaseRecords(res);
    });
  });

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation
        title={`${cxLabel ?? CRMS_PRODUCT_NAME} Knowledge Base`}
      />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCRMSKnowledgeBase(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle
            title={
              crmsKnowledgeBase?.Description
                ? displayDotByLimit(crmsKnowledgeBase.Description, 15)
                : "Knowledge Base"
            }
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Start review"} />
              </CCNavButton>,
              <CCNavButton title={"Send"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"View"} />,
              <CCNavButton title={"Print"} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {crmsKnowledgeBase && <CRMSKnowledgeBaseForm />}
                <br />
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
