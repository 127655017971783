import { loadReplacementMeter } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/replace-meter/api";
import { ConfirmReplaceMeterDialog } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/replace-meter/components/dialogs/confirm-replace-meter-dialog/_index";
import {
    mockListReplacementMeter,
    mockMeterStatus,
} from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/replace-meter/mock";
import { IMeterFormGeneral } from "@app/products/property/meters/[id]/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNote } from "@components/cc-note/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { TooltipValidator } from "@components/TooltipValidator/TooltipValidator";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
    Field,
    Form,
    FormElement,
    FormRenderProps,
    FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useEffect, useState } from "react";
const nameOf = nameOfFactory<IMeterFormGeneral>();
interface IEnterReplaceDialogProps {
  meterInfo: IMeterFormGeneral | undefined;
  onClose: () => void;
  onReplace: (data: IMeterFormGeneral) => void;
}

const ReplaceMeterDialog = ({
  meterInfo,
  onReplace,
  onClose,
}: IEnterReplaceDialogProps) => {
  const [initialValues, setInitialValues] = useState<IMeterFormGeneral>({});
  const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false);
  const [meterReplace, setMeterReplace] = useState<IMeterFormGeneral>({});
  useEffect(() => {
    if (!meterInfo) return;
    setInitialValues(meterInfo);
  }, [meterInfo]);
  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    const { values } = event;

    loadReplacementMeter(values?.ReplacementMeter).then((meterReplaceInfo) => {
      if (!meterReplaceInfo)
        onReplace({ MeterNumber: values?.ReplacementMeter });
      if (meterInfo?.MeterSize !== meterReplaceInfo?.MeterSize) {
        setMeterReplace(meterReplaceInfo);
        setShowWarningDialog(true);
      } else {
        onReplace(meterReplaceInfo);
      }
    });
  };
  const onConfirmYes = () => {
    setShowWarningDialog(false);
    onReplace(meterReplace);
  };
  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter, onChange } = formRenderProps;
        return (
          <>
            <CCDialog
              titleHeader={`Replace Meter ${meterInfo?.MeterNumber}`}
              onClose={onClose}
              maxHeight="66%"
              maxWidth="35%"
              bodyElement={
                <div className="cc-form">
                  <FormElement
                    onSubmit={formRenderProps.onSubmit}
                    className="cc-field-group"
                  >
                    <section>
                      <label className="cc-label">
                        Meter being removed/Replaced
                      </label>
                      <div className="cc-custom-sub-panel-bar cc-estate">
                        <section className="cc-field-group">
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">Meter ID</label>
                              <Field
                                disabled
                                name={nameOf("Id")}
                                component={CCInput}
                                placeholder={"Meter id"}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">Meter number</label>
                              <Field
                                disabled
                                name={nameOf("MeterNumber")}
                                component={CCInput}
                                placeholder={"Meter number"}
                              />
                            </div>
                          </div>
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">
                                Property address
                              </label>
                              <Field
                                disabled
                                name={nameOf("PropertyAddress")}
                                component={CCInput}
                                placeholder={"Property address"}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">Account names</label>
                              <Field
                                disabled
                                name={nameOf("AccountNames")}
                                component={CCInput}
                                placeholder={"Account names"}
                              />
                            </div>
                          </div>
                        </section>
                      </div>
                    </section>
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">
                          Replacement meter <TooltipValidator />
                        </label>
                        <Field
                          name={nameOf("ReplacementMeter")}
                          filterable
                          suggest
                          data={mockListReplacementMeter ?? []}
                          placeholder={"Replacement meter"}
                          component={CCComboBox}
                          validator={requiredValidator}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Date of replacement</label>
                        <Field
                          name={nameOf("DateOfReplacement")}
                          component={CCDateTimePicker}
                          format={DATETIME_FORMAT.DATETIME_CONTROL}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">
                          Status of existing meter <TooltipValidator />
                        </label>
                        <Field
                          name={nameOf("StatusOfExistingMeter")}
                          data={mockMeterStatus ?? []}
                          validator={requiredValidator}
                          textField="Value"
                          dataItemKey="Key"
                          component={CCSearchComboBox}
                          value={getDropdownValue(
                            valueGetter(nameOf("StatusOfExistingMeter")),
                            mockMeterStatus,
                            "Key"
                          )}
                          onChange={(event: ComboBoxChangeEvent) => {
                            onChange(nameOf("StatusOfExistingMeter"), {
                              value: event.target.value?.Key ?? null,
                            });
                          }}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Reading</label>
                        <Field
                          name={nameOf("LastReading")}
                          component={CCNumericTextBox}
                          min={0}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Reason</label>
                        <Field
                          name={nameOf("Reason")}
                          component={CCTextArea}
                          rows={3}
                          placeholder="Reason"
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">Replace by</label>
                        <Field
                          name={nameOf("ReplaceBy")}
                          component={CCInput}
                          placeholder="Replace by"
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Sequence with route</label>
                        <Field
                          name={nameOf("SequenceWithRoute")}
                          component={CCNumericTextBox}
                          min={0}
                        />
                      </div>
                    </div>
                  </FormElement>
                  <CCNote
                    message={
                      "Meter reading details will be transferred to the replacement meter."
                    }
                  />
                </div>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    type="button"
                    className={"cc-dialog-button"}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    type={"submit"}
                    name="OK"
                    themeColor="primary"
                    className={"cc-dialog-button"}
                    onClick={formRenderProps.onSubmit}
                    disabled={!formRenderProps.valid}
                  >
                    OK
                  </Button>
                </div>
              }
            />
            {showWarningDialog && (
              <ConfirmReplaceMeterDialog
                onClosePopup={() => setShowWarningDialog(false)}
                onConfirmYes={onConfirmYes}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default ReplaceMeterDialog;
