import { RejectLetterApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reject-letter-application/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { PPRApplicationIdentityPacket } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { APIResponse } from "@common/apis/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const RejectLetterApplicationButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { ppr, setPPRWithLoading } = usePPRStore();

  const { pushNotification, clearErrorNotification, clearNotifications } =
    useCCAppNotificationStore();

  const isVisible = useMemo(
    () =>
      ppr?.Status_ENUM === Application_Status.TeamLeaderDecisionPending &&
      ppr?.Flag_PlansToComply,
    [ppr]
  );

  return isVisible ? (
    <>
      <CCNavButton
        title="Reject letter"
        onClick={() => setIsShowDialog(true)}
      />
      {isShowDialog && (
        <RejectLetterApplicationDialog
          onSubmit={(response: APIResponse<PPRApplicationIdentityPacket>) => {
            setIsShowDialog(false);
            clearErrorNotification();
            clearNotifications();
            setPPRWithLoading(response.data.Application);
            pushNotification({
              type: "success",
              title: "Letter rejected successfully",
            });
            if (
              response.data?.Notifications &&
              response.data?.Notifications.length > 0
            ) {
              pushNotification({
                type: "warning",
                autoClose: false,
                title: "Reject letter",
                description: response.data.Notifications,
              });
            }
          }}
          onClose={() => setIsShowDialog(false)}
        />
      )}
    </>
  ) : null;
});
