// this function only check if the string is a valid HTML.
// eg: <abxnbcd> => is not a valid HTML => return false
export const isHTML = (value: string) => {
  const el = document.createElement("div");
  el.innerHTML = value;
  for (let c = el.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType === 1) return true;
  }
  return false;
};

export const isHTMLWithAllTag = (str: string) => {
  return /<\/?[a-z][\s\S]*>/i.test(str);
};
