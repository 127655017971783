import { enableAskAI } from "@app/core/chat/ask-ai/api";
import { generateConversationKey } from "@app/core/chat/ask-ai/util";
import { getUUID } from "@common/utils/common";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AskAIService {
  private _isShowAskAI?: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isShowAskAI() {
    return this._isShowAskAI;
  }
  setIsShowAskAI = (isShowAskAI: boolean) => {
    runInAction(() => {
      this._isShowAskAI = isShowAskAI;
    });
  };

  get conversation() {
    const conversationDetails = localStorage.getItem("helpConversation");
    return conversationDetails !== null ? JSON.parse(conversationDetails) : {};
  }
  setInitialShowAskAI = async () => {
    await enableAskAI().then((response: any) => {
      this._isShowAskAI = response?.data === true;
    });
  };

  set conversation(_conversationDetails: { [key: string]: string | null }) {
    localStorage.setItem(
      "helpConversation",
      JSON.stringify(_conversationDetails)
    );
  }

  getConversationId = (productId?: number) => {
    let conversationIds = this.conversation;
    const key = generateConversationKey(productId);
    return conversationIds[key];
  };

  getOrGenerateConversationId = (productId?: number) => {
    let conversationIds = this.conversation;
    const key = generateConversationKey(productId);
    if (conversationIds[key] == null) {
      conversationIds[key] = getUUID();
      this.conversation = conversationIds;
    }
    return conversationIds[key];
  };

  resetConversationId = (productId?: number) => {
    let conversationIds = this.conversation;
    const key = generateConversationKey(productId);
    conversationIds[key] = null;
    this.conversation = conversationIds;
  };
}

export const askAIServiceStore = new AskAIService();
const askAIServiceContext = createContext(askAIServiceStore);
export const useAskAIServiceStore = () => {
  return useContext(askAIServiceContext);
};
