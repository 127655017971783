import {
  EventReschedule,
  RescheduleMapObj,
} from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/details-reschedule/model";
import { CCPickRescheduledReason } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/pick-rescheduled-reason/_index";
import { CS_LookupTable } from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import "./index.scss";

const nameOfRescheduleMapObj = nameOfFactory<RescheduleMapObj>();
const nameOfReschedule = nameOfFactory<EventReschedule>();

export interface IDetailsDialogProps {
  onClosePopup: () => void;
  notifications?: IAppNotificationItemAddProps;
  crmsEventReschedule: RescheduleMapObj;
}

export const RescheduleDialog = observer(
  ({ crmsEventReschedule, onClosePopup }: IDetailsDialogProps) => {
    const {
      setIsShowRescheduleDialog: setIsShowEventDetails,
      notification,
      setNotification,
      rescheduleEventHandler,
    } = useCRMSEventStore();
    const isNew = useIsNew();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const [isShowPickReason, setIsShowPickReason] = useState(false);

    const handleSubmit = async (event: FormSubmitClickEvent) => {
      const { values } = event;
      if (values) {
        rescheduleEventHandler(values as RescheduleMapObj, isNew);
      }
    };

    useEffect(() => {
      if (notification) {
        notificationRef.current?.pushNotification(notification, () =>
          setNotification(undefined)
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification]);

    return (
      crmsEventReschedule && (
        <Form
          initialValues={crmsEventReschedule}
          onSubmitClick={handleSubmit}
          render={(formRenderProps: FormRenderProps) => {
            const { onSubmit, valid, onChange, valueGetter } = formRenderProps;

            return (
              <CCDialog
                maxWidth="55%"
                height="auto"
                titleHeader={"Reschedule"}
                onClose={() => {
                  setIsShowEventDetails(false);
                }}
                bodyElement={
                  <FormElement className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <div className="cc-field-group">
                      <div className="cc-form-cols-3">
                        <div className="cc-field">
                          <label className="cc-label">Reschedule</label>
                          <Field
                            name={`${nameOfRescheduleMapObj(
                              "EventReschedule"
                            )}.${nameOfReschedule("DateRescheduled")}`}
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                          />
                        </div>
                      </div>
                      {valueGetter(
                        `${nameOfRescheduleMapObj(
                          "EventReschedule"
                        )}.${nameOfReschedule("DateRescheduled")}`
                      ) && (
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <div className="cc-label cc-justify-between cc-align-item">
                              <div className="cc-flex">
                                Reason
                                <CCTooltip type="validator" position="right" />
                              </div>
                              <Button
                                type="button"
                                iconClass="fas fa-plus"
                                onClick={() => {
                                  setIsShowPickReason(true);
                                }}
                                title="Rescheduled Reason"
                              />
                            </div>
                            <Field
                              name={`${nameOfRescheduleMapObj(
                                "EventReschedule"
                              )}.${nameOfReschedule("RescheduleReason")}`}
                              placeholder="Rescheduled reason"
                              rows={2}
                              component={CCTextArea}
                              validator={requiredValidator}
                            />
                          </div>

                          {valueGetter(
                            `${nameOfRescheduleMapObj(
                              "EventReschedule"
                            )}.${nameOfReschedule("ShowNotifyCustomer")}`
                          ) && (
                            <div className="cc-field">
                              <label className="cc-label">
                                Alert requestor of reschedule
                              </label>
                              <Field
                                name={"AlertRequestor"}
                                component={CCSwitch}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    {isShowPickReason && (
                      <CCPickRescheduledReason
                        isMultiple={false}
                        dataUrl={`/odata/crms/internal/lookuptable/GetCurrentLookupValues(lookupTableType=${CS_LookupTable.EventRescheduleStandardReason})?$count=true&`}
                        onClose={() => setIsShowPickReason(false)}
                        onSubmit={(data) => {
                          setIsShowPickReason(false);
                          onChange(
                            `${nameOfRescheduleMapObj(
                              "EventReschedule"
                            )}.${nameOfReschedule("RescheduleReason")}`,
                            {
                              value: data.Description,
                            }
                          );
                        }}
                      />
                    )}
                  </FormElement>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button
                      className={"cc-dialog-button"}
                      onClick={() => {
                        onClosePopup();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!valid}
                      themeColor="primary"
                      onClick={onSubmit}
                      className={"cc-dialog-button"}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            );
          }}
        />
      )
    );
  }
);
