import { IMetersReading } from "@app/products/property/meters/[id]/components/child-screens/reading/model";

export const mockMetersReading: IMetersReading[] = [
  {
    ReadingDate: new Date("2-Jul-2011 0:00"),
    MeterReading: 5,
    MeterUnits: "kl",
    BaseUnits: "kl",
    ToBeVerified: false,
    IsVoid: false,
    ReadingStatus: "Read",
    JournalNumber: 1036,
    EnteredBy: "00",
    EnteredOn: new Date("2-Jul-2011 0:00"),
    MeterReadingId: 26808,
  },
  {
    ReadingDate: new Date("30-Jun-2012 0:00"),
    MeterReading: 119,
    Usage: 11,
    MeterUnits: "kl",
    BaseUsage: 11,
    BaseUnits: "kl",
    DaysSinceLastRead: 36,
    AverageDailyUsage: 0.3,
    ToBeVerified: false,
    IsVoid: false,
    ReadingStatus: "Read",
    UsageAmount: 61.3,
    JournalNumber: 1036,
    EnteredBy: "RA",
    EnteredOn: new Date("30-Jun-2012 0:00"),
    MeterReadingId: 26809,
  },
  {
    ReadingDate: new Date("30-Jun-2013 0:00"),
    MeterReading: 460,
    Usage: 341,
    MeterUnits: "kl",
    BaseUsage: 341,
    BaseUnits: "kl",
    DaysSinceLastRead: 365,
    AverageDailyUsage: 0.93,
    ToBeVerified: false,
    IsVoid: false,
    ReadingStatus: "Read",
    UsageAmount: 183.57,
    JournalNumber: 1036,
    EnteredBy: "AR",
    EnteredOn: new Date("30-Jun-2013 0:00"),
    MeterReadingId: 26810,
  },
  {
    ReadingDate: new Date("31-Oct-2013 0:00"),
    MeterReading: 587,
    Usage: 127,
    MeterUnits: "kl",
    BaseUsage: 127,
    BaseUnits: "kl",
    DaysSinceLastRead: 123,
    AverageDailyUsage: 1.03,
    ToBeVerified: false,
    IsVoid: false,
    ReadingStatus: "Read",
    UsageAmount: 68.37,
    JournalNumber: 1036,
    EnteredOn: new Date("31-Oct-2013 0:00"),
    MeterReadingId: 26811,
  },
  {
    ReadingDate: new Date("28-Feb-2014 0:00"),
    MeterReading: 733,
    Usage: 146,
    MeterUnits: "kl",
    BaseUsage: 146,
    BaseUnits: "kl",
    DaysSinceLastRead: 120,
    AverageDailyUsage: 1.22,
    ToBeVerified: false,
    IsVoid: false,
    ReadingStatus: "Read",
    UsageAmount: 78.6,
    JournalNumber: 1036,
    EnteredBy: "AR",
    EnteredOn: new Date("28-Feb-2014 0:00"),
    MeterReadingId: 26812,
  },
  {
    ReadingDate: new Date("30-Jun-2014 0:00"),
    MeterReading: 827,
    Usage: 94,
    MeterUnits: "kl",
    BaseUsage: 94,
    BaseUnits: "kl",
    DaysSinceLastRead: 122,
    AverageDailyUsage: 0.77,
    ToBeVerified: false,
    IsVoid: false,
    ReadingStatus: "Read",
    UsageAmount: 50.6,
    JournalNumber: 1036,
    EnteredBy: "AR",
    EnteredOn: new Date("30-Jun-2014 0:00"),
    MeterReadingId: 26813,
  },
  {
    ReadingDate: new Date("31-Oct-2014 0:00"),
    MeterReading: 948,
    Usage: 121,
    MeterUnits: "kl",
    BaseUsage: 121,
    BaseUnits: "kl",
    DaysSinceLastRead: 123,
    AverageDailyUsage: 0.98,
    ToBeVerified: false,
    IsVoid: false,
    ReadingStatus: "Read",
    UsageAmount: 65.14,
    JournalNumber: 1036,
    EnteredBy: "AR",
    EnteredOn: new Date("31-Oct-2014 0:00"),
    MeterReadingId: 26814,
  },
  {
    ReadingDate: new Date("15-Mar-2015 0:00"),
    MeterReading: 1093,
    Usage: 145,
    MeterUnits: "kl",
    BaseUsage: 145,
    BaseUnits: "kl",
    DaysSinceLastRead: 135,
    AverageDailyUsage: 1.07,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 4",
    ReadingStatus: "Read",
    UsageAmount: 81.85,
    JournalNumber: 1290,
    EnteredBy: "ARC1",
    EnteredOn: new Date("27-Apr-2015 11:11"),
    MeterReadingId: 26815,
  },
  {
    ReadingDate: new Date("5-Jul-2015 12:32"),
    MeterReading: 1169,
    Usage: 76,
    MeterUnits: "kl",
    BaseUsage: 76,
    BaseUnits: "kl",
    DaysSinceLastRead: 112,
    AverageDailyUsage: 0.68,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 2",
    ReadingStatus: "Read",
    UsageAmount: 44.4,
    JournalNumber: 1711,
    EnteredBy: "ARC1",
    EnteredOn: new Date("2-Aug-2015 10:45"),
    MeterReadingId: 26816,
  },
  {
    ReadingDate: new Date("1-Nov-2015 9:43"),
    MeterReading: 1227,
    Usage: 58,
    MeterUnits: "kl",
    BaseUsage: 58,
    BaseUnits: "kl",
    DaysSinceLastRead: 119,
    AverageDailyUsage: 0.49,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 2",
    ReadingStatus: "Read",
    UsageAmount: 33.6,
    JournalNumber: 2436,
    EnteredBy: "BP135",
    EnteredOn: new Date("6-Dec-2015 14:54"),
    MeterReadingId: 26817,
  },
  {
    ReadingDate: new Date("8-Mar-2016 13:22"),
    MeterReading: 1360,
    Usage: 133,
    MeterUnits: "kl",
    BaseUsage: 133,
    BaseUnits: "kl",
    DaysSinceLastRead: 128,
    AverageDailyUsage: 1.04,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 2",
    ReadingStatus: "Read",
    UsageAmount: 80.36,
    JournalNumber: 2950,
    EnteredBy: "BP135",
    EnteredOn: new Date("12-Apr-2016 15:24"),
    MeterReadingId: 39981,
  },
  {
    ReadingDate: new Date("13-Jul-2016 8:12"),
    MeterReading: 1423,
    Usage: 63,
    MeterUnits: "kl",
    BaseUsage: 63,
    BaseUnits: "kl",
    DaysSinceLastRead: 127,
    AverageDailyUsage: 0.5,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 4",
    ReadingStatus: "Read",
    UsageAmount: 40.26,
    JournalNumber: 3580,
    EnteredBy: "AA147",
    EnteredOn: new Date("31-Aug-2016 9:20"),
    MeterReadingId: 45328,
  },
  {
    ReadingDate: new Date("20-Nov-2016 10:18"),
    MeterReading: 1500,
    Usage: 77,
    MeterUnits: "kl",
    BaseUsage: 77,
    BaseUnits: "kl",
    DaysSinceLastRead: 130,
    AverageDailyUsage: 0.59,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 4",
    ReadingStatus: "Read",
    UsageAmount: 49.5,
    JournalNumber: 4322,
    EnteredBy: "BP135",
    EnteredOn: new Date("20-Dec-2016 16:02"),
    MeterReadingId: 49077,
  },
  {
    ReadingDate: new Date("31-Mar-2017 11:05"),
    MeterReading: 1596,
    Usage: 96,
    MeterUnits: "kl",
    BaseUsage: 96,
    BaseUnits: "kl",
    DaysSinceLastRead: 131,
    AverageDailyUsage: 0.73,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 2",
    ReadingStatus: "Read",
    UsageAmount: 62.04,
    JournalNumber: 4887,
    EnteredBy: "BP135",
    EnteredOn: new Date("26-Apr-2017 11:13"),
    MeterReadingId: 54093,
  },
  {
    ReadingDate: new Date("29-Jun-2017 8:51"),
    MeterReading: 1663,
    Usage: 67,
    MeterUnits: "kl",
    BaseUsage: 67,
    BaseUnits: "kl",
    DaysSinceLastRead: 90,
    AverageDailyUsage: 0.74,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 2",
    ReadingStatus: "Read",
    UsageAmount: 43.56,
    JournalNumber: 5280,
    EnteredBy: "BP135",
    EnteredOn: new Date("20-Jul-2017 14:14"),
    MeterReadingId: 58420,
  },
  {
    ReadingDate: new Date("13-Nov-2017 13:28"),
    MeterReading: 1708,
    Usage: 45,
    MeterUnits: "kl",
    BaseUsage: 45,
    BaseUnits: "kl",
    DaysSinceLastRead: 137,
    AverageDailyUsage: 0.33,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 2",
    ReadingStatus: "Read",
    UsageAmount: 31.39,
    JournalNumber: 6080,
    EnteredBy: "BP135",
    EnteredOn: new Date("27-Nov-2017 13:47"),
    MeterReadingId: 62631,
  },
  {
    ReadingDate: new Date("4-Mar-2018 12:24"),
    MeterReading: 1763,
    Usage: 55,
    MeterUnits: "kl",
    BaseUsage: 55,
    BaseUnits: "kl",
    DaysSinceLastRead: 111,
    AverageDailyUsage: 0.5,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 2",
    ReadingStatus: "Read",
    UsageAmount: 38.69,
    JournalNumber: 6736,
    EnteredBy: "BP135",
    EnteredOn: new Date("11-Apr-2018 9:21"),
    MeterReadingId: 66068,
  },
  {
    ReadingDate: new Date("27-Jun-2018 15:34"),
    MeterReading: 1824,
    Usage: 61,
    MeterUnits: "kl",
    BaseUsage: 61,
    BaseUnits: "kl",
    DaysSinceLastRead: 115,
    AverageDailyUsage: 0.53,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 3",
    ReadingStatus: "Read",
    UsageAmount: 43.07,
    JournalNumber: 7224,
    EnteredBy: "BP135",
    EnteredOn: new Date("7-Aug-2018 8:37"),
    MeterReadingId: 70620,
  },
  {
    ReadingDate: new Date("10-Nov-2018 9:09"),
    MeterReading: 1905,
    Usage: 81,
    MeterUnits: "kl",
    BaseUsage: 81,
    BaseUnits: "kl",
    DaysSinceLastRead: 136,
    AverageDailyUsage: 0.6,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 3",
    ReadingStatus: "Read",
    UsageAmount: 60.83,
    JournalNumber: 7891,
    EnteredBy: "BP135",
    EnteredOn: new Date("5-Dec-2018 14:45"),
    MeterReadingId: 76146,
  },
  {
    ReadingDate: new Date("2-Mar-2019 13:48"),
    MeterReading: 1984,
    Usage: 79,
    MeterUnits: "kl",
    BaseUsage: 79,
    BaseUnits: "kl",
    DaysSinceLastRead: 112,
    AverageDailyUsage: 0.71,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 1",
    ReadingStatus: "Read",
    UsageAmount: 59.29,
    JournalNumber: 8416,
    EnteredBy: "BP135",
    EnteredOn: new Date("26-Mar-2019 15:10"),
    MeterReadingId: 80710,
  },
  {
    ReadingDate: new Date("29-Jun-2019 9:51"),
    MeterReading: 2229,
    Usage: 245,
    MeterUnits: "kl",
    BaseUsage: 245,
    BaseUnits: "kl",
    DaysSinceLastRead: 119,
    AverageDailyUsage: 2.06,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 1",
    ReadingStatus: "Rechecked - high reading",
    UsageAmount: 255.31,
    JournalNumber: 8900,
    EnteredBy: "BP135",
    EnteredOn: new Date("17-Jul-2019 13:32"),
    MeterReadingId: 84975,
  },
  {
    ReadingDate: new Date("8-Nov-2019 14:00"),
    MeterReading: 2314,
    Usage: 85,
    MeterUnits: "kl",
    BaseUsage: 85,
    BaseUnits: "kl",
    DaysSinceLastRead: 132,
    AverageDailyUsage: 0.64,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 1",
    ReadingStatus: "Read",
    UsageAmount: 63.91,
    JournalNumber: 9511,
    EnteredBy: "BP135",
    EnteredOn: new Date("7-Dec-2019 11:02"),
    MeterReadingId: 90928,
  },
  {
    ReadingDate: new Date("3-Mar-2020 15:36"),
    MeterReading: 2407,
    Usage: 93,
    MeterUnits: "kl",
    BaseUsage: 93,
    BaseUnits: "kl",
    DaysSinceLastRead: 116,
    AverageDailyUsage: 0.8,
    ToBeVerified: false,
    IsVoid: false,
    Reader: "Meter Reader 1",
    ReadingStatus: "Read",
    UsageAmount: 70.07,
    JournalNumber: 9989,
    EnteredBy: "BP135",
    EnteredOn: new Date("30-Mar-2020 7:25"),
    MeterReadingId: 95809,
  },
  {
    ReadingDate: new Date("7-Jun-2020 7:24"),
    MeterReading: 2480,
    Usage: 73,
    MeterUnits: "kl",
    BaseUsage: 73,
    BaseUnits: "kl",
    DaysSinceLastRead: 96,
    AverageDailyUsage: 0.76,
    ToBeVerified: true,
    IsVoid: false,
    Reader: "Meter Reader 1",
    ReadingStatus: "Read",
    UsageAmount: 54.67,
    JournalNumber: 10350,
    EnteredBy: "BP135",
    EnteredOn: new Date("1-Jul-2020 11:32"),
    MeterReadingId: 99966,
  },
];
