import { colCategoryPicker } from "@app/products/town-planning/ppr/psa-referrals/_id/components/category-picker/config";
import { PSARCouncilContacts } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/model";
import { optionInputPickerTeamLeader } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<PSARCouncilContacts>();
export const colAttachmentRecord: IColumnFields[] = [
  {
    field: nameOf("ContactID"),
    title: "ID",
  },
  {
    field: nameOf("DisplayName"),
    title: "Name",
  },
  {
    field: nameOf("DisplayAddress"),
    title: "Address",
  },
  {
    field: nameOf("DisplayContact"),
    title: "Contact",
  },
];

export const colCouncilDetailGrid: IColumnFields[] = [
  {
    field: nameOf("DisplayName"),
    title: "Council",
    minWidth: 300,
  },
  {
    field: nameOf("DisplayAddress"),
    title: "Address",
    minWidth: 400,
  },
  {
    field: nameOf("DisplayContact"),
    title: "Contact",
    minWidth: 300,
  },
];

/* ====  Option Input Picker Application type  ==== */
export const optionInputPickerApplicationType: IOptionInputPicker = {
  ...optionInputPickerTeamLeader,
  dialog: {
    ...optionInputPickerTeamLeader.dialog,
    titleHeader: "Pick Categories",
  },

  grid: {
    ...optionInputPickerTeamLeader.grid,
    selectableMode: "single",
    columnFields: colCategoryPicker,
    dataUrl:
      "/odata/tp/internal/tpapplicationcategory/GetApplicationcategory(recordType=1310)",
    state: {
      sort: [
        {
          field: "ApplicationCategory_Name",
          dir: "asc",
        },
      ],
    },
  },
  boxSearch: {
    ...optionInputPickerTeamLeader.boxSearch,
    colSearch: "ApplicationCategory_Name",
    colShowComboboxSearch: [
      {
        title: "Category Name",
        field: "ApplicationCategory_Name",
      },
    ],
  },
};
/* ====  / Option Input Picker Application type  ==== */
