import { ExistedApplicationCategory } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/forms/existed/_index";
import { NewApplicationCategory } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/forms/new/_index";
import { IPPRApplicationCategoryParentSection } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/model";
import { useApplicationCategoriesStore } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageApplicationCategory = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const location = useLocation();
  const applicationCategoriesId = parseInt(params.id) || undefined;
  const { resetStore, loadApplicationCategories, setParentSection } =
    useApplicationCategoriesStore();

  useEffect(() => {
    if (location.state) {
      setParentSection(location.state as IPPRApplicationCategoryParentSection);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  useEffect(() => {
    loadApplicationCategories(applicationCategoriesId, isNew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationCategoriesId, isNew, loadApplicationCategories]);

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  if (isNew) {
    return <NewApplicationCategory />;
  }
  return <ExistedApplicationCategory />;
};

export default observer(ManageApplicationCategory);
