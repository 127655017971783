import { postSendVerify } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/authorise/components/form-elements/authorise/components/dialogs/officer-authorisation/api";
import { validateOfficerAuthoriseRequired } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/authorise/components/form-elements/authorise/components/dialogs/officer-authorisation/util";
import { isSuccessResponse } from "@common/apis/util";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { RefObject, useRef, useState } from "react";
import "./_index.scss";

export interface IOfficerAuthoriseDialogProps {
  onClose: () => void;
  localNotificationRef?: RefObject<ICCLocalNotificationHandle | null>;
  onVerifiedOfficer: () => void;
}

export const OfficerAuthorisationDialog = ({
  onClose,
  localNotificationRef,
  onVerifiedOfficer = () => {},
}: IOfficerAuthoriseDialogProps) => {
  const [isLoadingVerify, setIsLoadingVerify] = useState<boolean>(false);
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  const handleVerify = async (data: any) => {
    setIsLoadingVerify(true);
    const response = await postSendVerify(data.values?.Code);
    if (isSuccessResponse(response)) {
      localNotificationRef?.current?.pushNotification({
        title: "Officer authorisation was verified successfully.",
        type: "success",
      });
      //Verify Officer
      onVerifiedOfficer();
      //Close Officer authorise dialog
      onClose();
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: response.data.Errors,
        type: "error",
      });
    }
    setIsLoadingVerify(false);
  };
  return (
    <Form
      onSubmitClick={(data) => handleVerify(data)}
      render={(formRenderProps: FormRenderProps) => {
        const { modified, onSubmit } = formRenderProps;
        return (
          <CCDialog
            maxWidth="27%"
            maxHeight="25%"
            titleHeader="Officer Authorisation"
            onClose={onClose}
            bodyElement={
              <FormElement className="cc-form">
                <CCLocalNotification ref={notificationRef} />
                <div className="cc-field-group cc-officer-authorisation">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">
                        We just sent confirmation code over to&nbsp;
                        <b>your@email.com</b>
                        <CCTooltip type="validator" position="right" />
                      </label>
                      <Field
                        name="Code"
                        placeholder="Enter code"
                        component={CCInput}
                        validator={validateOfficerAuthoriseRequired}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label cc-label-send-another-code">
                        Didn't work?&nbsp;
                        <a href="/">Send me another code.</a>
                      </label>
                    </div>
                  </div>
                </div>
              </FormElement>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  themeColor="primary"
                  type="submit"
                  className="cc-dialog-button"
                  onClick={onSubmit}
                  disabled={!modified}
                  iconClass={isLoadingVerify ? "fas fa-spinner fa-spin" : ""}
                >
                  Verify
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
