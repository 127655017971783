import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Svc_CorporateAuthorisation_TabDetail } from "@common/pages/settings/security/corporate-authorisations/_id/components/reference-sidebar/detail/model";

export const getCorpAuthTabDetailsById = async (
  corpAuthID: number
): Promise<APIResponse<Svc_CorporateAuthorisation_TabDetail>> => {
  try {
    return await CoreAPIService.getClient().get<Svc_CorporateAuthorisation_TabDetail>(
      `/api/core/internal/corporate-authorisation/${corpAuthID}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
