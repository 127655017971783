import { history } from "@/AppRoutes";
import {
  getBuildingRegistersSitingApprovalsById,
  postBuildingRegistersSitingApprovals,
  putBuildingRegistersSitingApprovals,
} from "@app/products/building/registers/siting-approvals/[id]/api";
import { SITING_APPROVALS_MANAGE_ROUTE } from "@app/products/building/registers/siting-approvals/[id]/constant";
import { IBuildingRegistersSitingApprovalsFormData } from "@app/products/building/registers/siting-approvals/[id]/model";
import { IBuilding } from "@app/products/building/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class BuildingRegistersSitingApprovalsStore {
  private _buildingRegistersSitingApprovals?: IBuildingRegistersSitingApprovalsFormData =
    undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  get buildingRegistersSitingApprovals() {
    return toJS(this._buildingRegistersSitingApprovals);
  }
  setBuildingRegistersSitingApprovals = (
    buildingRegistersSitingApprovals?: IBuildingRegistersSitingApprovalsFormData
  ) => {
    runInAction(() => {
      this._buildingRegistersSitingApprovals = buildingRegistersSitingApprovals;
    });
  };

  get buildingRegistersSitingApprovalsId() {
    return toJS(this.buildingRegistersSitingApprovals?.ID);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._buildingRegistersSitingApprovals = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadBuildingRegistersSitingApprovals = async (
    buildingRegistersSitingApprovalsId: number
  ) => {
    this.setIsLoading(true);
    const response = await getBuildingRegistersSitingApprovalsById(
      buildingRegistersSitingApprovalsId
    );
    let errorResponse = undefined;
    let newBuildingRegistersSitingApprovals = undefined;
    if (isSuccessResponse(response)) {
      newBuildingRegistersSitingApprovals = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setBuildingRegistersSitingApprovals(
      newBuildingRegistersSitingApprovals
    );
    this.setIsLoading(false);
  };

  createBuildingRegistersSitingApprovals = async (
    buildingRegistersSitingApprovals: IBuilding
  ) => {
    this.setIsLoading(true);
    await postBuildingRegistersSitingApprovals(
      buildingRegistersSitingApprovals
    );
    appNotificationStore.pushNotification({
      title: "Building registers siting approvals created successfully",
      type: "success",
      onClose: () => {
        history.replace(
          `${SITING_APPROVALS_MANAGE_ROUTE}/${
            buildingRegistersSitingApprovals?.ID ?? 1
          }`
        );
      },
    });
    this.setIsLoading(false);
  };

  updateBuildingRegistersSitingApprovals = async (
    buildingRegistersSitingApprovals: IBuilding
  ) => {
    this.setIsLoading(true);
    await putBuildingRegistersSitingApprovals(buildingRegistersSitingApprovals);
    appNotificationStore.pushNotification({
      title: "Building registers siting approvals updated successfully",
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const buildingRegistersSitingApprovalsStoreContext = createContext(
  new BuildingRegistersSitingApprovalsStore()
);
export const useBuildingRegistersSitingApprovalsStore = () => {
  return useContext(buildingRegistersSitingApprovalsStoreContext);
};
