import { DTO_Component_Lineage_DataApi } from "@app/products/property/components/lineage-accordion/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getLineageAccordion = async (
  apiUrl: string,
): Promise<APIResponse<DTO_Component_Lineage_DataApi | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Component_Lineage_DataApi>(
      apiUrl
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
