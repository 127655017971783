import { Suburb } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
//@ TODO: Handle error when unify the API respone structure with onshore team
export const getSuburbById = async (
  suburbId: number
): Promise<APIResponse<Suburb | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Suburb>(
      `/api/core/internal/suburb/${suburbId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
