export const mockAdditionalDetails = {
  AdditionalParcelDetails: [
    {
      StandardParcelIdentifiers: "8\\LP31967",
      VolumeFolio: "8162/714",
      MapName: "Melway",
      Page: 55,
      Grid: "F2",
    },
  ],
  CrownAllotmentAndMemorial: [{ Lots: 8, PlanNumber: "LP031867" }],
  OtherIdentifiers: [
    {
      Type: "CPN",
      Name: "HOBSONS BAY CITY COUNCIL",
      ReferenceNumber: 9003006500,
    },
  ],
};
