import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colParkingPermitsSystemAdminStaffArea: IColumnFields[] = [
  {
    field: ParkingPermitsFieldMapping.Area,
    title: "Area",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${PARKING_PERMITS_ROUTE}/${dataItem.ID}`;
    },
    width: 500,
  },
  {
    field: ParkingPermitsFieldMapping.Code,
    title: "Code",
    width: 500,
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: ParkingPermitsFieldMapping.Type, title: "Name or Code", width: 500 },
  {
    field: ParkingPermitsFieldMapping.RenewalDate,
    title: "Expiry Date",
    width: 500,
    format: DATE_FORMAT.DATE,
  },
];
