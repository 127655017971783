import { ICCRoute } from "@common/constants/ICCRoute";

export const scheduleRoute: ICCRoute = {
  path: "schedule",
  name: "Schedule",
  children: [
    {
      path: "servicing",
      name: "Servicing",
      component: require("./servicing/_index").default,
    },
    {
      path: "servicing-calendar",
      name: "Servicing - Calendar",
      component: require("./servicing-calendar/_index").default,
    },
    {
      path: "desludging",
      name: "Desludging",
      component: require("./desludging/_index").default,
    },
    {
      path: "desludging-calendar",
      name: "Desludging - Calendar",
      component: require("./desludging-calendar/_index").default,
    },
    {
      path: "samples",
      name: "Samples",
      component: require("./samples/_index").default,
    },
    {
      path: "sample-calendar",
      name: "Sample - Calendar",
      component: require("./sample-calendar/_index").default,
    },
    {
      path: "inspections",
      name: "Inspections",
      component: require("./inspections/_index").default,
    },
    {
      path: "inspections-calendar",
      name: "Inspections - Calendar",
      component: require("./inspections-calendar/_index").default,
    },
  ],
};
