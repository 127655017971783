import {
  colAssessmentByLevy,
  colAssessmentByRatingPeriod,
} from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/components/detail/config";
import { useSupplementaryRatesDetailAssessmentStore } from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/components/detail/store";
import {
  DTO_Supplementary_Assessment_Levy,
  DTO_Supplementary_Assessment_RatingPeriod,
} from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";
export const SupplementaryRateAssessmentDetail: React.FC<GridDetailRowProps> =
  observer(({ dataItem }) => {
    const {
      loadAssessmentsDetail,
      resetStoreDetail,
      supplementaryAssessmentByLevy,
      supplementaryAssessmentByRatingPeriod,
      isLoadingDetail,
    } = useSupplementaryRatesDetailAssessmentStore();

    useEffectOnce(() => {
      if (!dataItem?.ID) return;
      loadAssessmentsDetail(dataItem?.ID);
      return () => {
        resetStoreDetail();
      };
    });

    const nameOfAssessmentByLevy =
      nameOfFactory<DTO_Supplementary_Assessment_Levy>();
    const nameOfAssessmentByRatingPeriod =
      nameOfFactory<DTO_Supplementary_Assessment_RatingPeriod>();

    return (
      <div className="cc-supplementary-assessment-detail cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">By rating period</label>
              <CCGrid
                isLoading={isLoadingDetail}
                data={supplementaryAssessmentByRatingPeriod}
                columnFields={colAssessmentByRatingPeriod}
                primaryField={nameOfAssessmentByRatingPeriod("Id")}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">By levy</label>
              <CCGrid
                isLoading={isLoadingDetail}
                data={supplementaryAssessmentByLevy}
                columnFields={colAssessmentByLevy}
                primaryField={nameOfAssessmentByLevy("Id")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  });
