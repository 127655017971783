import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { DynamicQuestionListDetail } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/model";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { DynamicQuestionList } from "@common/models/dynamicQuestion";
import { TreePacket } from "@common/models/treePacket";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CheckListQuestionDialog } from "@common/pages/settings/lookups/dynamic-lists/_id/components/child-screens/general/components/dialog/dynamic-question-dialog/_index";
import { dynamicQuestionListGridColumn } from "@common/pages/settings/lookups/dynamic-lists/_id/components/child-screens/general/components/form-element/config";
import { useDynamicQuestionListsStore } from "@common/pages/settings/lookups/dynamic-lists/_id/store";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef, useState } from "react";

export interface IDynamicQuestionListsFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<DynamicQuestionList>();
const nameOfCheckListType = nameOfFactory<TreePacket>();
const nameOfDynamicQuestionListDetail =
  nameOfFactory<DynamicQuestionListDetail>();

export const DynamicQuestionListsFormElement = observer(
  ({ formRenderProps }: IDynamicQuestionListsFormElementProps) => {
    const isNew = useIsNew();
    const { settings } = useCommonCoreStore();
    const { valueGetter, onChange } = formRenderProps;
    const {
      dynamicQuestionListsLovs,
      dynamicQuestionLists,
      deleteCheckListQuestion,
      updateCheckListQuestion,
      dynamicQuestionListsId,
      checkListQuestionId,
      setCheckListQuestionId,
      isShowCheckListQuestionDialog,
      setIsShowCheckListQuestionDialog,
      onSubmit,
      setOnUpdateQuestion,
      isInactive,
      isFirstLoadingCheckListQuestionDialog,
    } = useDynamicQuestionListsStore();

    const [gridSelectedRows, setGridSelectedRows] = useState<
      DynamicQuestionListDetail[]
    >([]);

    const defaultItemPerPage: number = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_FlatGridPageSize]
    );

    const updateQuestionRef =
      useRef<
        (
          dataRow: DynamicQuestionListDetail,
          fieldChange: string,
          valueChange?: number
        ) => void | undefined
      >();

    const questionList = useMemo(() => {
      let dynamicQuestionListDetail = cloneDeep(
        dynamicQuestionLists?.DynamicQuestionListDetail
      );
      if (dynamicQuestionListDetail) {
        // sort "Dynamic question list" by Sort Order
        dynamicQuestionListDetail.sort(
          (
            firstItem: DynamicQuestionListDetail,
            secondItem: DynamicQuestionListDetail
          ) => {
            return firstItem.SortIndex - secondItem.SortIndex;
          }
        );
        // refactor "Dynamic question list" data to show friendly name of "Type"
        return dynamicQuestionListDetail.map(
          (dynamicQuestion: DynamicQuestionListDetail) => {
            const questionTypeObj: KeyValuePacket | undefined =
              dynamicQuestionListsLovs?.AnswerType?.find(
                (answerTypeItem: KeyValuePacket) =>
                  answerTypeItem.Key ===
                  dynamicQuestion?.QuestionDetail?.ChecklistAnswerType_ENUM
              );
            return {
              ...dynamicQuestion,
              QuestionType: questionTypeObj?.Value,
            };
          }
        );
      }
    }, [dynamicQuestionLists, dynamicQuestionListsLovs?.AnswerType]);

    const handleRemoveQuestion = () => {
      const dynamicQuestionDetailId =
        gridSelectedRows?.[0]?.DynamicQuestionListDetail_ID;
      const checkListQuestionId = gridSelectedRows?.[0]?.ChecklistQuestion_ID;
      deleteCheckListQuestion(
        dynamicQuestionDetailId,
        checkListQuestionId,
        isNew
      );
    };

    const handleUpdateQuestion = (
      dataRow: DynamicQuestionListDetail,
      fieldChange: string,
      valueChange?: number
    ) => {
      const checklistQuestionId = dataRow?.ChecklistQuestion_ID;
      let updatedQuestion = undefined;
      const dynamicQuestionListDetail = valueGetter(
        nameOf("DynamicQuestionListDetail")
      );

      if (fieldChange === nameOfDynamicQuestionListDetail("SortIndex")) {
        const newDynamicQuestionListDetail = dynamicQuestionListDetail?.map(
          (dynamicQuestionListDetailItem: DynamicQuestionListDetail) => {
            if (
              dynamicQuestionListDetailItem.ChecklistQuestion_ID ===
              checklistQuestionId
            ) {
              updatedQuestion = cloneDeep({
                ...dynamicQuestionListDetailItem,
                SortIndex: valueChange,
              });
              return updatedQuestion;
            }
            return dynamicQuestionListDetailItem;
          }
        );
        onChange(nameOf("DynamicQuestionListDetail"), {
          value: newDynamicQuestionListDetail,
        });

        // Call API to update question
        if (updatedQuestion) updateCheckListQuestion(updatedQuestion, isNew);
      }
    };

    updateQuestionRef.current = handleUpdateQuestion;
    useEffect(() => {
      if (updateQuestionRef.current)
        setOnUpdateQuestion(updateQuestionRef.current);
      //eslint-disable-next-line
    }, [updateQuestionRef.current]);

    return (
      <>
        {isShowCheckListQuestionDialog && dynamicQuestionListsId && (
          <CheckListQuestionDialog
            dynamicQuestionId={dynamicQuestionListsId}
            checkListQuestionId={checkListQuestionId ?? 0}
            onClose={() => {
              setIsShowCheckListQuestionDialog(false);
            }}
          />
        )}
        {isFirstLoadingCheckListQuestionDialog && (
          <Loading
            isLoading={isFirstLoadingCheckListQuestionDialog}
            isFullScreen
          />
        )}
        <FormElement>
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Checklist type" isMandatory />
                <Field
                  name={nameOf("ChecklistType_ENUM")}
                  dataItemKey={nameOfCheckListType("Key")}
                  textField={nameOfCheckListType("Value")}
                  data={dynamicQuestionListsLovs?.ChecklistType ?? []}
                  component={CCSearchComboBox}
                  value={getDropdownValue(
                    valueGetter(nameOf("ChecklistType_ENUM"))?.toString(),
                    dynamicQuestionListsLovs?.ChecklistType ?? [],
                    nameOfCheckListType("Key")
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange(nameOf("ChecklistType_ENUM"), {
                      value: parseInt(event?.value?.Key),
                    });
                  }}
                  validator={requiredValidator}
                  disabled={isInactive}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Dynamic list name" isMandatory />
                <Field
                  name={nameOf("DynamicQuestionList_Name")}
                  component={CCInput}
                  validator={requiredValidator}
                  readOnly={isInactive}
                />
              </div>
            </div>

            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Dynamic question list" />
                <CCGrid
                  readOnly={isInactive}
                  selectableMode="single"
                  editableMode="cell"
                  columnFields={dynamicQuestionListGridColumn(
                    setCheckListQuestionId,
                    setIsShowCheckListQuestionDialog,
                    isInactive
                  )}
                  primaryField={nameOfDynamicQuestionListDetail(
                    "DynamicQuestionListDetail_ID"
                  )}
                  data={questionList ?? []}
                  itemPerPage={defaultItemPerPage}
                  onSelectionChange={(
                    dataItem: DynamicQuestionListDetail[]
                  ) => {
                    setGridSelectedRows([...dataItem]);
                  }}
                  toolbar={
                    <div className="cc-grid-tools-bar">
                      <Button
                        type="button"
                        iconClass="fas fa-plus"
                        title="Add new question"
                        name={ActionSubmitActions.AddCheckListQuestion}
                        onClick={onSubmit}
                        disabled={isInactive}
                      />
                      <Button
                        type="button"
                        iconClass="fas fa-minus"
                        title="Remove question"
                        disabled={gridSelectedRows.length === 0 || isInactive}
                        onClick={() => {
                          handleRemoveQuestion();
                        }}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </section>
        </FormElement>
      </>
    );
  }
);
