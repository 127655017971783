import { getAssessmentCompliance } from "@app/products/property/assessments/[id]/components/child-screens/compliance/api";
import {
  DTO_Assessment_Compliance,
  eComplianceHistoryFor,
} from "@app/products/property/assessments/[id]/components/child-screens/compliance/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class assessmentComplianceStore {
  private _assessmentCompliance?: DTO_Assessment_Compliance = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _isRatePayer: eComplianceHistoryFor =
    eComplianceHistoryFor.RATEPAYER_OWNERS;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get assessmentCompliance() {
    return toJS(this._assessmentCompliance);
  }
  setAssessmentCompliance = (
    assessmentCompliance: DTO_Assessment_Compliance | undefined
  ) => {
    runInAction(() => {
      this._assessmentCompliance = assessmentCompliance;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isRatePayer() {
    return toJS(this._isRatePayer);
  }
  setIsRatePayer = (isRatePayer: eComplianceHistoryFor) => {
    runInAction(() => {
      this._isRatePayer = isRatePayer;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._assessmentCompliance = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._isRatePayer = eComplianceHistoryFor.RATEPAYER_OWNERS;
    });
  };

  loadAssessmentCompliance = async (assessmentId?: number) => {
    if (isNil(assessmentId)) return;

    this.setIsLoading(true);
    let errorResponse;
    const response = await getAssessmentCompliance(
      assessmentId,
      this._isRatePayer
    );
    if (isSuccessResponse(response)) {
      this.setAssessmentCompliance(response?.data);
    } else {
      errorResponse = {
        status: response.status,
        error: response.error ?? "Load association data failed",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };
}
const assessmentComplianceStoreContext = createContext(
  new assessmentComplianceStore()
);
export const useAssessmentComplianceAccordionStore = () => {
  return useContext(assessmentComplianceStoreContext);
};
