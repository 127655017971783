import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import {
  getFieldSelection,
  getMailMergeDocument,
} from "@common/pages/settings/communication/template/_id/api";
import { CommunicationTemplate } from "@common/pages/settings/communication/template/_id/model";
import { useCommunicationTemplateStore } from "@common/pages/settings/communication/template/_id/store";
import { selectionAreaList } from "@common/pages/settings/communication/template/_id/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCCustomEditor } from "@components/cc-custom-editor/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import "@styles/index.scss";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";

interface ICommunicationTemplateFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<CommunicationTemplate>();

export const CommunicationTemplateFormElement = observer(
  ({ formRenderProps }: ICommunicationTemplateFormElementProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const { communicationTemplateLOVs, setCommunicationTemplateLOVs } =
      useCommunicationTemplateStore();
    const { valueGetter, onChange } = formRenderProps;
    const getValue = (name: keyof CommunicationTemplate) =>
      valueGetter(nameOf(name));
    const dataSet = getValue("Dataset_ENUM");
    const isShowCommunication = getValue("ShowInCommunicationDialog");
    const subject = getValue("Subject") ?? "";
    const emailBody = getValue("EmailBody") ?? "";
    const smsBody = getValue("SMSBody") ?? "";
    const fieldSelection = valueGetter("_option.FieldSelection");
    const selectionArea = valueGetter("_option.SelectionArea");

    const loadMailMergeDocumentAndFieldSelection = async () => {
      if (dataSet && dataSet !== 0) {
        const [responseMailMergeDocument, responseFieldSelection] =
          await Promise.all([
            getMailMergeDocument(dataSet),
            getFieldSelection(dataSet),
          ]);
        if (
          isSuccessResponse(responseMailMergeDocument) &&
          isSuccessResponse(responseFieldSelection)
        ) {
          setCommunicationTemplateLOVs({
            ...communicationTemplateLOVs,
            MailMergeDocument: responseMailMergeDocument.data,
            FieldSelections: responseFieldSelection.data,
          });
        } else {
          pushNotification({
            title: "Load LOVs failed",
            autoClose: false,
            type: "error",
          });
        }
      }
    };

    const handleAddField = (event: any) => {
      if (!fieldSelection || !selectionArea) return;
      const replacedField_start = "[";
      const replacedField_end = "]";
      const addText = `${replacedField_start}${fieldSelection.Value}${replacedField_end}`;
      if (selectionArea.Key === "sms_message") {
        onChange("SMSBody", { value: `${smsBody}${addText}` });
      } else if (selectionArea.Key === "email_subject") {
        onChange("Subject", { value: `${subject}${addText}` });
      } else {
        onChange("EmailBody", { value: `${emailBody}${addText}` });
      }
    };

    useEffect(() => {
      loadMailMergeDocumentAndFieldSelection();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSet]);
    return (
      <>
        <FormElement className="cc-communication-template-form-elements">
          <CustomAddAttachmentPortal
            dataSet={dataSet}
            isShowCommunication={isShowCommunication}
          />
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Name" isMandatory={true} />
                <Field
                  name={nameOf("CommunicationTemplate_Name")}
                  component={CCInput}
                  placeholder={"Name"}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Communication usage" isMandatory={true} />
                <Field
                  name={nameOf("CommunicationUsage_ENUM")}
                  component={CCSearchComboBox}
                  validator={requiredValidator}
                  textField={"Value"}
                  dataItemKey={"Key"}
                  data={communicationTemplateLOVs?.CommunicationUsageList}
                  isUseDefaultOnchange
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Get data from" isMandatory={true} />
                <Field
                  name={nameOf("Dataset_ENUM")}
                  component={CCSearchComboBox}
                  validator={requiredValidator}
                  textField={"Value"}
                  dataItemKey={"Key"}
                  data={communicationTemplateLOVs?.Dataset}
                  isUseDefaultOnchange
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Show in dialog" />
                <Field
                  name={nameOf("ShowInCommunicationDialog")}
                  component={CCSwitch}
                  checked={getValue("ShowInCommunicationDialog")}
                />
              </div>
              {getValue("ShowInCommunicationDialog") && dataSet > 0 && (
                <div className="cc-field">
                  <CCLabel title="Mail merge document" />
                  <Field
                    name={nameOf("MailMergeDocument_ID")}
                    component={CCSearchComboBox}
                    textField={"Name"}
                    dataItemKey={"ID"}
                    data={communicationTemplateLOVs?.MailMergeDocument}
                    isUseDefaultOnchange
                  />
                </div>
              )}
            </div>
          </section>
          <hr />
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Field selection" />
                <Field
                  name={"_option.FieldSelection"}
                  component={CCSearchComboBox}
                  textField={"Value"}
                  dataItemKey={"Key"}
                  data={communicationTemplateLOVs?.FieldSelections}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">&nbsp;</label>
                <Field
                  name={"_option.SelectionArea"}
                  component={CCSearchComboBox}
                  textField={"Value"}
                  dataItemKey={"Key"}
                  data={selectionAreaList}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">&nbsp;</label>
                <div>
                  <Button type="button" onClick={handleAddField}>
                    Add Field
                  </Button>
                </div>
              </div>
            </div>
          </section>
          <hr />
          <section className="cc-field-group">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Subject" />
                <Field
                  name={nameOf("Subject")}
                  component={CCInput}
                  placeholder={"Subject"}
                />
              </div>
            </div>
          </section>
          <hr />
          <section className="cc-field-group">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Email / fax content" />
                <CCCustomEditor
                  value={getValue("EmailBody")}
                  onChangeCustomEditor={(value: string) => {
                    onChange("EmailBody", { value: value });
                  }}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="SMS content" />
                <Field name={nameOf("SMSBody")} component={CCTextArea} />
              </div>
            </div>
          </section>
        </FormElement>
      </>
    );
  }
);

// portal component for handle logic show/hidden add attachment
export interface ICustomAddAttachmentPortalProps {
  dataSet?: number;
  isShowCommunication?: boolean;
}

export const CustomAddAttachmentPortal = ({
  dataSet,
  isShowCommunication,
}: ICustomAddAttachmentPortalProps) => {
  const parentRoot: any = document.getElementById("add-attachment-button");
  const params: { id: string } = useParams();
  if (!parentRoot) return null;
  return createPortal(
    dataSet && isShowCommunication ? (
      <AddAttachmentButton
        id={parseInt(params.id)}
        recordType={RECORDTYPE.CORE_CommunicationTemplate}
      />
    ) : null,
    parentRoot
  );
};
