import { eventEmitter } from "@/App";
import { ContactTabEventType } from "@app/core/contacts/components/contact-tab/config";
import { AdditionalContactDialog } from "@app/core/contacts/components/dialogs/additional-contact/_index";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

interface AdditionalContactButtonProps {
  id?: number;
  recordType: RECORDTYPE;
  bubbleUps?: BubbleUpIdentifier[];
  disabled?: boolean;
}
export const AdditionalContactButton = (
  props: AdditionalContactButtonProps
) => {
  const [showDialog, setShowDialog] = useState(false);
  const { pushNotification } = useCCAppNotificationStore();
  return (
    <>
      <CCNavButton
        title={"Contact"}
        onClick={() => {
          setShowDialog(true);
        }}
        disabled={props?.disabled ?? false}
      />
      {showDialog && (
        <AdditionalContactDialog
          {...props}
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={() => {
            setShowDialog(false);
            pushNotification({
              type: "success",
              title: "Additional contact added successfully",
            });
            eventEmitter.emit(CCGridEventType.RefreshOData);
            eventEmitter.emit(ContactTabEventType.RefreshData);
            eventEmitter.emit(CCJournalEventType.RefreshData);
            eventEmitter.emit(TabTableEventType.RefreshData);
          }}
        />
      )}
    </>
  );
};
