import { history } from "@/AppRoutes";
import { SETTINGS_COMMUNICATION_TEMPLATE_ROUTE } from "@common/pages/settings/communication/template/_id/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export interface CloneNewCommunicationTemplateButtonProps {
  disabled?: boolean;
}
export const CloneNewCommunicationTemplateButton = observer(
  ({ disabled }: CloneNewCommunicationTemplateButtonProps) => {
    const { gridSelectedIds } = useCCProductListViewStore();

    const handleCloneNew = () => {
      history.push(`${SETTINGS_COMMUNICATION_TEMPLATE_ROUTE}/new`, {
        parentId: gridSelectedIds[0],
      });
    };

    return (
      <CCNavButton
        title="Clone New"
        onClick={handleCloneNew}
        disabled={gridSelectedIds.length !== 1 || disabled}
      />
    );
  }
);
