import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const mockTransactionType: IKeyValuePacket[] = [
  {
    Key: 1,
    Value: "3Phase Meter Installation",
  },
  {
    Key: 2,
    Value: "3Phase Meter Replacement for Solar",
  },
  {
    Key: 3,
    Value: "Adjustment",
  },
  {
    Key: 4,
    Value: "Cat Charge",
  },
  {
    Key: 5,
    Value: "Direct Debit Fee",
  },
  {
    Key: 6,
    Value: "Direct Debit Payment",
  },
  {
    Key: 7,
    Value: "Deed Debit Reversal",
  },
  {
    Key: 8,
    Value: "Dog Charge",
  },
  {
    Key: 9,
    Value: "Meter Installation",
  },
  {
    Key: 10,
    Value: "Meter Replacement Adjustment Step 1",
  },
  {
    Key: 11,
    Value: "Meter Replacement Adjustment Step 2",
  },
  {
    Key: 12,
    Value: "Meter Replacement Adjustment Step 3",
  },
  {
    Key: 13,
    Value: "Meter Replacement for Solar",
  },
  {
    Key: 14,
    Value: "Meter Tampering Charge",
  },
  {
    Key: 15,
    Value: "Payment",
  },
  {
    Key: 16,
    Value: "Power Account Adjustment",
  },
  {
    Key: 17,
    Value: "Power Account Write-Off (Bad Debt)",
  },
  {
    Key: 18,
    Value: "Power Billing Charges",
  },
  {
    Key: 19,
    Value: "Power Concession Rebate",
  },
];

export const mockCharge = { TransactionDate: new Date() };
