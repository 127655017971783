import { ContactsTab } from "@app/core/contacts/components/contact-tab/_index";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const CRMSEventContactTab = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { crmsEvent } = useCRMSEventStore();
  const { lastSelectedId } = useCCProductListViewStore();
  const eventId = id ? parseInt(id) : lastSelectedId;
  return (
    <ContactsTab
      id={eventId}
      recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
      triggerReload={crmsEvent}
    />
  );
});
