import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const govtReportsRoute: ICCRoute = {
  path: "govt-report",
  name: "Govt. Reports",
  enum: HealthManagerMenu.MAVReports,
  children: [
    {
      path: "tobacco-retailers",
      name: "Tobacco Retailers",
      enum: HealthManagerMenu.PremisesMAVTobaccoRetailers,
      component: require("./tobacco-retailers/_index").default,
    },
    {
      path: "tobacco-inspections",
      name: "Tobacco Inspections",
      enum: HealthManagerMenu.PremisesMAVTobaccoInspections,
      component: require("./tobacco-inspections/_index").default,
    },
    {
      path: "vending-machines",
      name: "Vending Machines",
      enum: HealthManagerMenu.PremisesMAVVendingMachines,
      component: require("./vending-machines/_index").default,
    },
    {
      path: "eat-in-facilities",
      name: "Eat In Facilities",
      enum: HealthManagerMenu.PremisesMAVEatInFacilities,
      component: require("./eat-in-facilities/_index").default,
    },
    {
      path: "outdoor-dining",
      name: "Outdoor Dining",
      enum: HealthManagerMenu.PremisesMAVOutdoorDining,
      component: require("./outdoor-dining/_index").default,
    },
    {
      path: "licenced",
      name: "Licenced",
      enum: HealthManagerMenu.PremisesMAVLicenced,
      component: require("./licenced/_index").default,
    },
  ],
};
