import { eventEmitter } from "@/App";
import { isSuccessResponse } from "@common/apis/util";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { deactivateDocuments } from "@common/pages/settings/lookups/documents/components/action-bar/buttons/deactivate/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface DeactivateDocumentButtonProps {
  disabled?: boolean;
}
export const DeactivateDocumentButton = observer(({disabled} : DeactivateDocumentButtonProps) => {
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();
  const { gridSelectedRows, gridSelectedIds } = useCCProductListViewStore();
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    const response = await deactivateDocuments(gridSelectedIds);
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        clearErrorNotification();
        eventEmitter.emit(CCGridEventType.RefreshOData);
        pushNotification({
          title: "Documents deactivated successfully",
          type: "success",
        });

        if (response.data.Notifications.length > 0) {
          pushNotification({
            autoClose: false,
            title: response.data.Notifications,
            type: "warning",
          });
        }
      } else {
        pushNotification({
          autoClose: false,
          title: response.data?.Errors,
          type: "error",
        });
      }
    } else {
      pushNotification({
        autoClose: false,
        title: response.error,
        type: "error",
      });
    }

    setIsLoading(false);
    setIsShowDialog(false);
  };

  return (
    <>
      <CCNavButton
        title="Deactivate"
        onClick={() => setIsShowDialog(true)}
        disabled={gridSelectedRows?.length === 0 || disabled}
        isLoading={isLoading}
      />
      {isShowDialog && (
        <ConfirmDialog
          title={"Confirm Deactivate"}
          subMessage={
            "This will only deactivate selected active records, are you sure?"
          }
          onClosePopup={() => setIsShowDialog(false)}
          isLoadingYes={isLoading}
          onConfirmYes={onSubmit}
        />
      )}
    </>
  );
});
