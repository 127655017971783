import { CreatePICButton } from "@app/products/property/pic/list/components/action-bar/buttons/new-pic/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const AssessmentCreatePICButton = observer(() => {
  const { gridSelectedIds } = useCCProductListViewStore();
  return (
    <CreatePICButton
      isDisabled={gridSelectedIds?.length < 1}
      assessmentIds={gridSelectedIds}
    />
  );
});
