import { DTO_Notice } from "@app/products/property/deferred-duty/[id]/components/child-screens/notices/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Notice>();
export const colNotices: IColumnFields[] = [
  {
    field: nameOf("NoticeNumber"),
    title: "Notice Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("IssueDate"),
    title: "Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("PeriodStart"),
    title: "Period Start",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("PeriodEnd"),
    title: "Period End",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DeferredDutyBalance"),
    title: "Deferred Duty Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("OpeningBalance"),
    title: "Opening Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    // TODO: Waiting Krishna get back
    field: "PaymentsSinceLastNotice",
    title: "Payments Since Last Notice",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InterestRaised"),
    title: "Interest Raised",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InterestBalance"),
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NameAddress"),
    title: "Name Address",
  },
  {
    field: nameOf("RunDate"),
    title: "Run Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CreatedBy"),
    title: "Created By",
  },
  {
    field: nameOf("NoticeId"),
    title: "Notice ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
