import { ProcessReadingsDialog } from "@app/products/property/meters/readings-to-be-processed/components/action-bar/dialogs/process-readings/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const ProcessReadingsButton = () => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  return (
    <>
      <CCNavButton
        title="Process Readings"
        onClick={() => setIsShowDialog(true)}
      />
      {isShowDialog && (
        <ProcessReadingsDialog
          onClosePopup={() => setIsShowDialog(false)}
          onConfirmYes={() => setIsShowDialog(false)}
        />
      )}
    </>
  );
};
