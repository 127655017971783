import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

export const ConditionReferenceGroupSetting = {
  [PRODUCT_TYPE_NUMBER.HealthManager]:
    ECorporateSettingsField.HealthManager_Views_Conditions_DisplayConditionsInGroup,
  [PRODUCT_TYPE_NUMBER.WasteWater]:
    ECorporateSettingsField.WW_Views_Conditions_DisplayConditionsInGroup,
  [PRODUCT_TYPE_NUMBER.LLPermits]:
    ECorporateSettingsField.LLP_Views_Conditions_DisplayConditionsInGroup,
};
