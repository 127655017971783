import { VO_Supplementary_BalanceTransfer } from "@app/products/property/supplementary-rates/[id]/components/child-screens/balance-transfers/model";
import {
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfBalanceTransfers =
  nameOfFactory<VO_Supplementary_BalanceTransfer>();
export const colBalanceTransfer: IColumnFields[] = [
  {
    field: nameOfBalanceTransfers("From_Assessment_Id"),
    title: "From Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfBalanceTransfers("Entitlement"),
    title: "Entitlement",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfBalanceTransfers("Entitlement_Percentage"),
    title: "Entitlement Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOfBalanceTransfers("To_Assessment_Number"),
    title: "To Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfBalanceTransfers("From_Property_Address"),
    title: "From Property Address",
  },
  {
    field: nameOfBalanceTransfers("From_Assessment_Number"),
    title: "From Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfBalanceTransfers("To_Property_Address"),
    title: "To Property Address",
  },
  {
    field: nameOfBalanceTransfers("To_Assessment_Id"),
    title: "To Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
