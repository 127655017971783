import { BalanceOutStandingSAP } from "@app/products/property/components/grid/balance-out-standing-sap/_index";
import { colSwineBrandSummary } from "@app/products/property/pic/[id]/components/child-screens/general/components/form-element/model";
import { DTO_PIC_Summary } from "@app/products/property/pic/[id]/model";
import { usePicStore } from "@app/products/property/pic/[id]/store";
import { DTO_PIC_Swine } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface Props {
  formRenderProps: FormRenderProps;
}
const nameOfSwine = nameOfFactory<DTO_PIC_Swine>();
export const PropertyPicFormElement = observer(({ formRenderProps }: Props) => {
  const { lovPIC, pic } = usePicStore();
  const nameOf = nameOfFactory<DTO_PIC_Summary>();
  const { valueGetter } = formRenderProps;

  const isShowAgentLicense = useMemo(() => {
    const typePIC = valueGetter("PICType");
    return getDropdownValue(typePIC, lovPIC?.PICTypes ?? [], "Name")?.AgentType;
  }, [lovPIC?.PICTypes, valueGetter]);

  return (
    <FormElement className="cc-property-pic-form-elements">
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="PIC type" />
                <Field
                  name={nameOf("PICType")}
                  placeholder={"Type"}
                  component={CCInput}
                  readOnly
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Property address" />
                <Field
                  name={nameOf("PropertyAddress")}
                  placeholder={"Property address"}
                  component={CCInput}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="cc-field cc-col-span-1">
            <CCLabel title="PIC manager" />
            <Field
              name={nameOf("ManageNameAndAddress")}
              placeholder={"Manager"}
              component={CCTextArea}
              rows={4}
              readOnly
            />
          </div>
          <div className="cc-field">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Trading name" />
                <Field
                  name={nameOf("TradingName")}
                  placeholder={"Trading name"}
                  component={CCInput}
                  readOnly
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Occupier" />
                <Field
                  name={nameOf("Occupier")}
                  placeholder={"Occupier"}
                  component={CCInput}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Land use" />
            <Field
              name={nameOf("PICLandUse")}
              placeholder={"Land use"}
              component={CCInput}
              readOnly
            />
          </div>
          <div className="cc-field">
            <CCLabel title="District" />
            <Field
              name={nameOf("District")}
              placeholder={"District"}
              component={CCInput}
              readOnly
            />
          </div>
          <div className="cc-field">
            <CCLabel title="RLP board" />
            <Field
              name={nameOf("RLPBoard")}
              placeholder={"RLP board"}
              component={CCInput}
              readOnly
            />
          </div>
          <div className="cc-field">
            <CCLabel title="SAP ID(s)" />
            <Field
              name={nameOf("Occupier_SAP_Number")}
              placeholder={"SAP ID(s)"}
              component={CCInput}
              readOnly
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Quarantine status" />
            <Field
              name={nameOf("PICQuarantineStatus")}
              placeholder={"Quarantine status"}
              component={CCInput}
              readOnly
            />
          </div>
          {/* TODO: Recheck when have API */}
          <div className="cc-field">
            <CCLabel title="Residue status" />
            <Field
              name={nameOf("PICResidueStatus")}
              placeholder={"Residue status"}
              component={CCInput}
              readOnly
            />
          </div>
          {isShowAgentLicense ? (
            <div className="cc-field">
              <CCLabel title="Agent license number" />
              <Field
                name={nameOf("AgentLicenseNumber")}
                placeholder={"Agent license number"}
                component={CCInput}
                readOnly
              />
            </div>
          ) : null}
          <div className="cc-field">
            <CCLabel title="Status" />
            <Field
              name={nameOf("PICStatus")}
              placeholder={"Status"}
              component={CCInput}
              readOnly
            />
          </div>
        </div>
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Swine brand" />
              <CCGrid
                data={valueGetter("Swines") ?? []}
                columnFields={colSwineBrandSummary}
                primaryField={nameOfSwine("PIC_Swine_Id")}
              />
            </div>
            <div className="cc-col-span-2">
              <BalanceOutStandingSAP sapId={pic?.Occupier_SAP_Number} />
            </div>
          </div>
        </div>
      </section>
    </FormElement>
  );
});
