import { mockCreateLeakageDialog } from "@app/products/property/meters/[id]/components/action-bar/dialogs/leakage/mock";
import { LeakageDialog } from "@app/products/property/meters/[id]/components/action-bar/dialogs/leakage/_index";
import { IOccurrences } from "@app/products/property/meters/[id]/components/child-screens/leakage/model";
import { useMeterLeakageStore } from "@app/products/property/meters/[id]/components/child-screens/leakage/store";
import { useMeterStore } from "@app/products/property/meters/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const AddLeakageButton = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { addOccurrence, leakage } = useMeterLeakageStore();
  const { meter } = useMeterStore();
  const { pushNotification } = useCCAppNotificationStore();
  const initialDataForm = useMemo(() => {
    if (meter) {
      return { ...meter, ...mockCreateLeakageDialog };
    }
  }, [meter]);

  const newMeterLeakageId = useMemo(() => {
    if (!leakage.Occurrences || leakage.Occurrences.length === 0) {
      return 1;
    } else {
      return (
        Math.max.apply(
          Math,
          leakage.Occurrences.map((item: any) => item.MeterLeakageId)
        ) + 1
      );
    }
  }, [leakage]);

  const handleAddOccurrences = (event: any) => {
    const newOccurrence: IOccurrences = {
      LeakageOccurredApproxFrom: event.LeakageOccurredApproxFrom,
      LeakageOccurredUpTo: event.LeakageOccurredUpTo,
      EstimatedLeakage: event?.EstimatedLeakage,
      DescriptionOfLeak: event?.DescriptionOfLeak,
      EnteredBy: "communitycentral_dev",
      EnteredOn: new Date(),
      MeterLeakageId: newMeterLeakageId,
      IsLeakageTaken: event?.IsLeakageTaken,
    };
    addOccurrence(newOccurrence);
    setShowDialog(false);
    pushNotification({
      title: `Meter leakage added successfully`,
      type: "success",
    });
  };
  return (
    <>
      <CCNavButton title="Add" onClick={() => setShowDialog(true)} />
      {showDialog && (
        <LeakageDialog
          initialData={initialDataForm}
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={(event) => {
            handleAddOccurrences(event);
          }}
        />
      )}
    </>
  );
});
