import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { BOOLEAN_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSFeesSubsidyCards: IColumnFields[] = [
  {
    field: CRSFieldMapping.REgistration_REferenceNo,
    title: "Application Number",
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: CRSFieldMapping.CardExpiryDueStatus,
    title: "Due Status",
  },
  { field: CRSFieldMapping.CHILD_NAME, title: "Child Name" },
  {
    field: CRSFieldMapping.CHILD_BIRTHDATE,
    title: "Date of Birth",
    format: DATE_FORMAT.DATE,
  },
  { field: CRSFieldMapping.CHILD_AGE, title: "Age" },
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Gender",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: CRSFieldMapping.CBCRegistrationStatus,
    title: "CBC Status",
  },
  { field: CRSFieldMapping.CBC_CentreName, title: "CBC Centre" },
  {
    field: CRSFieldMapping.TYORegistrationStatus,
    title: "3yo Status",
  },
  { field: CRSFieldMapping.TYO_YearAttending, title: "3yo Year" },
  { field: CRSFieldMapping.TYO_CentreName, title: "3yo Centre" },
  {
    field: CRSFieldMapping.TYOSecYear_RegistrationStatus,
    title: "3yo 2ye Status",
  },
  {
    field: CRSFieldMapping.TYOSecYear_YearAttending,
    title: "3yo 2ye Year",
  },
  {
    field: CRSFieldMapping.TYOSecYear_CentreName,
    title: "3yo 2ye Centre",
  },
  {
    field: CRSFieldMapping.FYO_RegistrationStatus,
    title: "4yo Status",
  },
  { field: CRSFieldMapping.FYO_YearAttending, title: "4yo Year" },
  { field: CRSFieldMapping.FYO_CentreName, title: "4yo Centre" },
  {
    field: CRSFieldMapping.FYOSecYear_RegistrationStatus,
    title: "4yo 2ye Status",
  },
  {
    field: CRSFieldMapping.FYOSecYear_YearAttending,
    title: "4yo 2ye Year",
  },
  {
    field: CRSFieldMapping.FYOSecYear_CentreName,
    title: "4yo 2ye Centre",
  },
  {
    field: CRSFieldMapping.Registration_CardBelongs,
    title: "Card Belongs To",
  },
  {
    field: CRSFieldMapping.Registration_CardType,
    title: "Card Type",
  },
  {
    field: CRSFieldMapping.Registration_CardNumber,
    title: "Card Number",
  },
  {
    field: CRSFieldMapping.Registration_CardExpiryDate,
    title: "Card Expiry Date",
  },
  {
    field: CRSFieldMapping.Registration_CardExpirtyYear,
    title: "Expiry Year",
  },
  {
    field: CRSFieldMapping.Registration_CardExpiryMonth,
    title: "Expiry Month",
  },
  { field: CRSFieldMapping.PARENT1_NAME, title: "Parent 1 Name" },
  {
    field: CRSFieldMapping.Parent1_PreferredMethod,
    title: "Parent 1 Preference Method",
  },
  {
    field: CRSFieldMapping.Parent1_Email,
    title: "Parent 1 Email",
  },
  {
    field: CRSFieldMapping.Parent1_PostalAddress,
    title: "Parent 1 Address",
  },
  { field: CRSFieldMapping.PARENT2_NAME, title: "Parent 2 Name" },
  {
    field: CRSFieldMapping.Parent2_PreferredMethod,
    title: "Parent 2 Preference Method",
  },
  {
    field: CRSFieldMapping.Parent2_Email,
    title: "Parent 2 Email",
  },
  {
    field: CRSFieldMapping.Parent2_PostalAddress,
    title: "Parent 2 Address",
  },
];
