import {
  DTO_ChangeOfOwnership_LOVs,
  DTO_Role,
  DTO_Workflow_ChangeOfOwnership,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ChangeOfOwnershipDialogStore {
  private _changeOfOwnershipLOVs?: DTO_ChangeOfOwnership_LOVs = undefined;
  private _dynamicRoles?: DTO_Role[] = [];
  private _initWorkflowData?: DTO_Workflow_ChangeOfOwnership =
    {} as DTO_Workflow_ChangeOfOwnership;

  constructor() {
    makeAutoObservable(this);
  }

  get changeOfOwnershipLOVs() {
    return this._changeOfOwnershipLOVs;
  }
  setChangeOfOwnershipLOVs = (
    changeOfOwnershipLOVs?: DTO_ChangeOfOwnership_LOVs
  ) => {
    runInAction(() => {
      this._changeOfOwnershipLOVs = changeOfOwnershipLOVs;
      this._dynamicRoles = changeOfOwnershipLOVs?.Roles;
    });
  };

  get dynamicRoles() {
    return this._dynamicRoles;
  }
  setDynamicRoles = (roles?: DTO_Role[]) => {
    runInAction(() => {
      this._dynamicRoles = roles;
    });
  };

  get initWorkflowData() {
    return this._initWorkflowData;
  }
  setInitWorkflowData = (data: DTO_Workflow_ChangeOfOwnership) => {
    runInAction(() => {
      this._initWorkflowData = data;
    });
  };
}

const changeOfOwnershipDialogStoreContext = createContext(
  new ChangeOfOwnershipDialogStore()
);
export const useChangeOfOwnershipDialogStore = () => {
  return useContext(changeOfOwnershipDialogStoreContext);
};
