import { eventEmitter } from "@/App";
import { useTownPlanningStore } from "@app/products/town-planning/[id]/store";
import {
  getPropertiesAddressList,
  getPropertyAddress,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/api";
import { ComplexPropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/_index";
import {
  getCandidateAddress,
  getOriginalAddress,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/api";
import { MapshareSuggestion } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/model";
import { PropertyDetailDialogEventType } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/constant";
import { SimplePropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/_index";
import {
  AddressValidationDialog,
  IOnAction,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/address-validation";
import { searchAddress } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/property-address-picker/api";
import {
  colSearchAddresses,
  colSearchPropertyAddresses,
  searchAddressesConfig,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/property-address-picker/config";
import { Address } from "@app/products/waste-water/[id]/model";
import { getKeywords } from "@common/apis/coreKeyword";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { isCancelRequest } from "@common/hooks/useCancelRequest";
import { Address_BuildAddress } from "@common/input-pickers/address/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { KeywordLite } from "@common/models/keyword";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import {
  getDisplayBuildingUnitNumber,
  getDisplayFloorNumber,
  getDisplayHouseNumber,
} from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { IColumnFields } from "@components/cc-grid/model";
import { isHTML } from "@components/cc-input-picker/util";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { FormRenderProps } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import axios, { CancelTokenSource } from "axios";
import { cloneDeep, isArray, isEmpty, isNil, omit } from "lodash";
import { observer } from "mobx-react-lite";
import React, {
  MouseEventHandler,
  ReactElement,
  useMemo,
  useRef,
  useState,
} from "react";
import "./_index.scss";

export interface IMapShareInfo {
  mapShareSuggestionSelected: MapshareSuggestion;
  mapShareSearchResult?: MapshareSuggestion[];
}

export interface IApplicationDescriptionProps extends ComboBoxProps {
  initialData: any;
  visited?: boolean;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  disabledButton?: boolean;
  onError?: (value: any) => void;
  isLoadDetail?: boolean;
  textField?: string;
  textProduce?: (value: any) => string | undefined;
  formRenderProps: FormRenderProps;
  notificationRef: any;
  onSubmit: (value: any) => void;
  name: string;
  updateSaveTriggers: () => void;
  onChangeEventHandler?: (buildAddress: Address_BuildAddress | null) => void;
  uniqueKey?: string;
  removeDisplayValue?: () => void;
  restoreDisplayValue?: () => void;
  isSearchPropertyAddresses?: boolean;
  isLoadingDialog?: boolean;
  isShowFullResultSearch?: boolean;
  forceIsUseSimpleAddressDialog?: boolean;
}
export const PropertyDetail = observer(
  (props: IApplicationDescriptionProps) => {
    const {
      initialData,
      className,
      visited,
      validationMessage,
      formRenderProps,
      onButtonClick,
      value,
      textProduce,
      textField,
      onError,
      onChange,
      onFocus,
      onBlur,
      onSubmit,
      name,
      updateSaveTriggers,
      onChangeEventHandler,
      uniqueKey,
      restoreDisplayValue,
      removeDisplayValue,
      isSearchPropertyAddresses,
      isLoadingDialog,
      isShowFullResultSearch,
      forceIsUseSimpleAddressDialog,
      ...others
    } = props;

    const { settings } = useCommonCoreStore();
    const cancelRequest = useRef<CancelTokenSource>();
    const { pushNotification, notifications } = useCCAppNotificationStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingValidate, setIsLoadingValidate] = useState<boolean>(false);
    const [data, setData] = useState<MapshareSuggestion[]>();
    const [showDialog, setShowDialog] = useState(false);
    const { zoneKeywordList, overlayKeywordList } = useTownPlanningStore();

    //Get settings section
    const isUseSimpleAddressDialogTPSetting = getBoolValueSetting(
      settings[ECorporateSettingsField.TP_UseSimpleAddressDialog]
    );
    const isUseSimpleAddressDialog = () => {
      if (forceIsUseSimpleAddressDialog !== undefined)
        return forceIsUseSimpleAddressDialog;
      return isUseSimpleAddressDialogTPSetting;
    };
    const refTimeOut = useRef<NodeJS.Timeout | null>(null);
    const { valueGetter, onChange: onChangeForm } = formRenderProps;
    const address = valueGetter(name);
    const propertyAssessment = valueGetter(`${name}.PropertyAssessment`);
    const assessmentPlanner = valueGetter("AssessmentPlanner");

    const inputData = useMemo(() => {
      return data;
    }, [data]);

    const isStreetTypeEnabled = getBoolValueSetting(
      settings[ECorporateSettingsField.Global_Contact_SplitAddressName]
    );

    const isUseStreetType = useMemo(() => {
      if (
        !isStreetTypeEnabled &&
        !isNil(valueGetter(`${name}.Flag_ForceUse_StreetType`))
      ) {
        return valueGetter(`${name}.Flag_ForceUse_StreetType`);
      } else {
        return isStreetTypeEnabled;
      }
      // eslint-disable-next-line
    }, [isStreetTypeEnabled, valueGetter]);

    const inputDisplayName = useMemo(() => {
      if (isNil(value)) return "";
      if (textProduce) return textProduce(value);
      if (textField && !isArray(value) && textField in value)
        return value[textField];
      return value ?? "";
    }, [value, textField, textProduce]);

    /* ====  SiteUserHandle-Search address (in manage page) ==== */
    const handleSearch = async (event: ComboBoxFilterChangeEvent) => {
      const searchText = event.filter.value;
      if (searchText.length < searchAddressesConfig.minCharacters) return;

      if (refTimeOut.current) clearTimeout(refTimeOut.current);
      refTimeOut.current = setTimeout(async () => {
        cancelRequest.current?.cancel("CancelSearching");
        cancelRequest.current = axios.CancelToken.source();
        setIsLoading(true);
        if (isSearchPropertyAddresses) {
          const loadPropertiesAddressResp = await getPropertiesAddressList(
            searchText,
            cancelRequest.current
          );
          if (isSuccessResponse(loadPropertiesAddressResp)) {
            setData(loadPropertiesAddressResp.data?.value ?? []);
          } else if (!isCancelRequest(loadPropertiesAddressResp?.errorCause)) {
            if (onError) onError(loadPropertiesAddressResp.error);
          }
          setIsLoading(false);
        } else {
          cancelRequest.current?.cancel("CancelSearching");
          cancelRequest.current = axios.CancelToken.source();
          const loadAddressResp = await searchAddress(
            searchText,
            cancelRequest.current
          );
          if (isSuccessResponse(loadAddressResp)) {
            setData(loadAddressResp.data?.suggestions);
          } else if (!isCancelRequest(loadAddressResp?.errorCause)) {
            if (onError) onError(loadAddressResp.error);
          }
          setIsLoading(false);
        }
      }, searchAddressesConfig.typeSpeed);
    };
    /* ====  / SiteUserHandle-Search address (in manage page) ==== */

    const setStreetType = async (address: Address) => {
      const streetTypeAbbreviation =
        address.PropertyAssessment.Street_TypeAbbreviation;
      // const streetName = address.PropertyAssessment.Street_Name;
      const response = await getKeywords(
        KEYWORD_TYPE.Core_Address_StreetType,
        PRODUCT_TYPE_NUMBER.TownPlanning
      );
      if (!isSuccessResponse(response)) {
        pushNotification({
          autoClose: false,
          title: `Street type list load failed`,
          type: "warning",
        });
      } else {
        if (isUseStreetType && response && response.data) {
          const streetTypeIndex = response.data.findIndex(
            (item) =>
              item.Name.toLowerCase() === streetTypeAbbreviation?.toLowerCase()
          );
          if (!isEmpty(streetTypeAbbreviation) && streetTypeIndex !== -1) {
            address = {
              ...address,
              StreetNamePart_Type: response.data[streetTypeIndex]?.Name,
            };
          } else {
            address = { ...address, StreetNamePart_Type: "" };
          }
        }
        // TODO: Re-check this logic later
        // else {
        //   if (!isEmpty(streetTypeAbbreviation)) {
        //     address = {
        //       ...address,
        //       PropertyAssessment: {
        //         ...address.PropertyAssessment,
        //         Street_Name: `${streetName} ${streetTypeAbbreviation}`,
        //       },
        //     };
        //   }
        // }
      }

      return address;
    };

    /* ====  SiteUserHandle-Add values of selected search result to the form (in manage page) ==== */
    const handleAddValueToAddressForm = async (newValue: any) => {
      let newAddress = { ...address };
      let newPropertyAssessment = { ...propertyAssessment };
      if (!newValue) {
        onChangeForm(name, { value: undefined });
        updateSaveTriggers();
        if (onChangeEventHandler) onChangeEventHandler(null);
        return;
      }

      setIsLoading(true);
      if (isSearchPropertyAddresses) {
        getPropertyAddress(newValue?.ID?.toString() ?? "0").then((response) => {
          if (isSuccessResponse(response) && response.data) {
            newAddress = response.data;
            onChangeForm(`${name}.Flag_StreetName_HasParts`, {
              value: isUseStreetType,
            });
            onChangeForm(name, {
              value: newAddress,
            });
            updateSaveTriggers();

            if (onChangeEventHandler)
              onChangeEventHandler({
                Address: newAddress,
                bAddressChanged: true,
              } as Address_BuildAddress);
          } else {
            if (onError) onError(response.error);
          }
          setIsLoading(false);
        });
      } else {
        //Clear unrelated fields
        newPropertyAssessment = {
          ...newPropertyAssessment,
          PropertyName: "",
          BuildingName: "",
          AddressLocationDescriptor: "",
          NearestCrossStreet: "",
          UnitNumber1: null,
          UnitSuffix1: "",
          UnitNumber2: null,
          UnitSuffix2: "",
          UnitAbbreviation: "",
          HouseNumber2: null,
          HouseSuffix2: "",
          FloorNumber1: null,
          FloorSuffix1: "",
          FloorNumber2: null,
          FloorSuffix2: "",
          FloorTypeAbbreviation: "",
        };

        newAddress = {
          ...newAddress,
          NearestCrossStreet: "",
          PropertyName: "",
          Location_Description: "",
          PropertyAssessment: {
            ...newAddress.PropertyAssessment,
            ...newPropertyAssessment,
          },
          StreetNo: getDisplayHouseNumber(newPropertyAssessment),
          UnitNo:
            (!isEmpty(getDisplayFloorNumber(newPropertyAssessment))
              ? `${getDisplayFloorNumber(newPropertyAssessment)} `
              : "") + getDisplayBuildingUnitNumber(newPropertyAssessment),
        };

        //Get candidate address by magicKey
        const responseCandidateAddress = await getCandidateAddress(
          newValue.magicKey
        );
        if (isSuccessResponse(responseCandidateAddress)) {
          const candidateAddress = responseCandidateAddress?.data;
          if (candidateAddress) {
            if (
              candidateAddress.candidates &&
              candidateAddress.candidates.length > 0
            ) {
              newPropertyAssessment = {
                ...newPropertyAssessment,
                GIS_Latitude: candidateAddress.candidates[0].location.x,
                GIS_Longitude: candidateAddress.candidates[0].location.y,
              };

              newAddress = {
                ...newAddress,
                PropertyAssessment: {
                  ...newAddress.PropertyAssessment,
                  ...newPropertyAssessment,
                },
              };

              if (
                candidateAddress.candidates[0].attributes &&
                !isEmpty(candidateAddress.candidates[0].attributes.User_fld)
              ) {
                const responseOriginalAddress = await getOriginalAddress(
                  candidateAddress.candidates[0].attributes.User_fld
                );
                if (isSuccessResponse(responseOriginalAddress)) {
                  const originalAddress = responseOriginalAddress.data;
                  if (originalAddress) {
                    //Fill corresponding fields
                    newAddress = {
                      ...newAddress,
                      UnitNo: originalAddress.UnitNo ?? "",
                      StreetNo: originalAddress.StreetNo ?? "",
                    };

                    newPropertyAssessment = {
                      ...newPropertyAssessment,
                      ...originalAddress.PropertyAssessment,
                      Address_PFI:
                        originalAddress.PropertyAssessment.Address_PFI ?? "",
                      HouseNumber1:
                        originalAddress.PropertyAssessment.HouseNumber1 || null,
                      HouseSuffix1:
                        originalAddress.PropertyAssessment.HouseSuffix1 ?? "",
                      Street_Name:
                        originalAddress.PropertyAssessment.Street_Name ?? "",
                      Street_Suffix:
                        originalAddress.PropertyAssessment.Street_Suffix ?? "",
                      Street_TypeAbbreviation:
                        originalAddress.PropertyAssessment
                          .Street_TypeAbbreviation ?? "",
                      Locality_Name:
                        originalAddress.PropertyAssessment.Locality_Name ?? "",
                      Locality_Postcode:
                        originalAddress.PropertyAssessment.Locality_Postcode ??
                        "",
                      UnitNumber1: originalAddress.UnitNo || null,
                      Zones: originalAddress?.PropertyAssessment?.Zones ?? "",
                      ZoneIDs:
                        originalAddress?.PropertyAssessment?.ZoneIDs ?? [],
                      Overlay:
                        originalAddress?.PropertyAssessment?.Overlay ?? "",
                      OverlayIDs:
                        originalAddress?.PropertyAssessment?.OverlayIDs ?? [],
                    };

                    newAddress = {
                      ...newAddress,
                      Suburb: originalAddress.Suburb ?? "",
                      Postcode: originalAddress.Postcode ?? "",
                      AddressLine1: originalAddress.AddressLine1 ?? "",
                      VMAS_Verified: true,
                      State: originalAddress.State ?? "",
                      StreetNamePart_Name:
                        originalAddress.StreetNamePart_Name ?? "",
                      StreetName: originalAddress.StreetName ?? "",
                      PropertyAssessment: {
                        ...newAddress.PropertyAssessment,
                        ...newPropertyAssessment,
                      },
                      StreetNo: getDisplayHouseNumber(newPropertyAssessment),
                      UnitNo:
                        (!isEmpty(getDisplayFloorNumber(newPropertyAssessment))
                          ? `${getDisplayFloorNumber(newPropertyAssessment)} `
                          : "") +
                        getDisplayBuildingUnitNumber(newPropertyAssessment),
                    };

                    newAddress = await setStreetType(newAddress);

                    onChangeForm(`${name}.Flag_StreetName_HasParts`, {
                      value: isUseStreetType,
                    });
                    onChangeForm(name, {
                      value: newAddress,
                    });

                    //Set AssessmentPlanner_Zones and AssessmentPlanner_Overlays
                    const newAssessmentPlannerZones = zoneKeywordList
                      .filter((zoneKeyword: KeywordLite) =>
                        newAddress?.PropertyAssessment?.ZoneIDs.includes(
                          zoneKeyword.Keyword_ID
                        )
                      )
                      .map((zoneKeyword: KeywordLite) => ({
                        Zone_KWD: zoneKeyword.Keyword_ID,
                        Zone_Name: zoneKeyword.Name,
                      }));

                    const newAssessmentPlannerOverlays = overlayKeywordList
                      .filter((overlayKeyword: KeywordLite) =>
                        newAddress?.PropertyAssessment?.OverlayIDs.includes(
                          overlayKeyword.Keyword_ID
                        )
                      )
                      .map((overlayKeyword: KeywordLite) => ({
                        Overlay_KWD: overlayKeyword.Keyword_ID,
                        Overlay_Name: overlayKeyword.Name,
                      }));

                    onChangeForm("AssessmentPlanner", {
                      value: {
                        ...assessmentPlanner,
                        AssessmentPlanner_Zones: newAssessmentPlannerZones,
                        AssessmentPlanner_Overlays:
                          newAssessmentPlannerOverlays,
                      },
                    });

                    updateSaveTriggers();

                    if (onChangeEventHandler)
                      onChangeEventHandler({
                        Address: newAddress,
                        bAddressChanged: true,
                      } as Address_BuildAddress);
                  }
                } else {
                  if (onError) onError(responseOriginalAddress.error);
                }
              }
            }
          }
        } else {
          if (onError) onError(responseCandidateAddress.error);
        }
        setIsLoading(false);
      }
    };

    const handleOnChange = async (event: ComboBoxChangeEvent) => {
      handleAddValueToAddressForm(event.value);
    };
    /* ====  / SiteUserHandle-Add values of selected search result to the form (in manage page) ==== */

    const [isShowAddressValidationDialog, setIsShowAddressValidationDialog] =
      useState(false);
    const [dataAddress, setDataAddress] = useState<Address>();
    const [formData, setFormData] = useState<any>();
    const [verified, setVerified] = useState<boolean>(false);

    const handleValidateAndSubmit = (value: any) => {
      setFormData(value);
      const initialDataAddressWatch = cloneDeep(initialData?.Address);
      const currentDataAddressWatch = cloneDeep(value?.Address);
      setDataAddress(currentDataAddressWatch);

      /* ====  SiteUserHandle-Watch Change Data Address ==== */

      /* ====  Initial Data Custom  ==== */
      if (initialDataAddressWatch) {
        initialDataAddressWatch.StreetName =
          currentDataAddressWatch?.StreetName.toUpperCase();
        initialDataAddressWatch.Flag_StreetName_HasParts =
          currentDataAddressWatch?.Flag_StreetName_HasParts;
        initialDataAddressWatch.VMAS_Verified =
          currentDataAddressWatch?.VMAS_Verified;
        initialDataAddressWatch.AddressClassification_ENUM =
          currentDataAddressWatch?.AddressClassification_ENUM;
        const initialNewPropertyAssessment = omit(
          initialDataAddressWatch?.PropertyAssessment,
          ["Zones", "Overlay", "OverlayIDs", "ZoneIDs"]
        );
        initialDataAddressWatch.PropertyAssessment =
          initialNewPropertyAssessment;
      }
      /* ====  / Initial Data Custom  ==== */

      /* ====  Current Data Custom  ==== */
      if (currentDataAddressWatch) {
        const currentNewPropertyAssessment = omit(
          currentDataAddressWatch?.PropertyAssessment,
          ["Zones", "Overlay", "OverlayIDs", "ZoneIDs"]
        );
        currentDataAddressWatch.PropertyAssessment =
          currentNewPropertyAssessment;
      }
      /* ====  / Current Data Custom  ==== */

      /* ====  / SiteUserHandle-Watch Change Data Address ==== */

      /* ====  SiteUserHandle-Validate Address  ==== */
      if (verified) {
        setShowDialog(false);
        updateSaveTriggers();
        value.Address.VMAS_Verified = true;
        onSubmit(value);
        setVerified(false);
      } else {
        if (
          JSON.stringify(initialDataAddressWatch) !==
          JSON.stringify(currentDataAddressWatch)
        ) {
          setIsShowAddressValidationDialog(true);
          return;
        } else {
          setShowDialog(false);
          updateSaveTriggers();
          onSubmit(value);
          setVerified(false);
        }
      }
      /* ====  / SiteUserHandle-Validate Address  ==== */
    };

    const handleValidate = async ({ status, dataValidate }: IOnAction) => {
      if (status === "OK") {
        setIsLoadingValidate(true);
        eventEmitter.emit(
          PropertyDetailDialogEventType.updateAddress,
          dataValidate
        );
        setVerified(true);
      } else if (status === "SKIP") {
        updateSaveTriggers();
        formData.Address.VMAS_Verified = false;
        onSubmit(formData);
        setIsShowAddressValidationDialog(false);
        setShowDialog(false);
      } else if (status === "CANCEL") {
        setIsShowAddressValidationDialog(false);
      }
    };

    return (
      <>
        <div
          className={`${className ?? ""} cc-input-picker-new ${
            !others.valid ? "cc-input-picker-invalid" : ""
          }`}
        >
          <div
            style={{
              display: `${!isHTML(inputDisplayName) ? "none" : "block"}`,
            }}
            className={`cc-input-picker-html k-textbox ${
              others.disabled ? "k-state-disabled" : ""
            }`}
            onClick={() => {
              if (removeDisplayValue && uniqueKey && !others.disabled) {
                removeDisplayValue();
                const comboboxElement = document.getElementById(uniqueKey);
                setTimeout(() => {
                  comboboxElement?.focus();
                }, 100);
              }
            }}
          >
            {sanitizeHtml(value)}
          </div>
          <ComboBox
            {...others}
            filterable
            suggest
            style={{
              display: `${isHTML(inputDisplayName) ? "none" : "flex"}`,
            }}
            id={uniqueKey}
            data={inputData}
            loading={isLoading}
            onFilterChange={handleSearch}
            itemRender={(
              li: ReactElement<HTMLLIElement>,
              itemProps: ListItemProps
            ) => ItemRender(li, itemProps, isSearchPropertyAddresses)}
            value={inputDisplayName}
            onBlur={restoreDisplayValue}
            header={
              <div className="cc-search-header">
                {(isSearchPropertyAddresses
                  ? colSearchPropertyAddresses
                  : colSearchAddresses
                ).map((col: IColumnFields) => (
                  <div key={col.field} style={{ width: col.width }}>
                    {col.title}
                  </div>
                ))}
              </div>
            }
            onChange={handleOnChange}
            popupSettings={{
              className: `cc-site-address-search ${
                isShowFullResultSearch ? "cc-search-view-full" : ""
              }`,
            }}
          />
          <Button
            disabled={
              !isNil(props.disabledButton) ? props.disabledButton : isLoading
            }
            className="cc-input-picker-button"
            iconClass="fa fa-ellipsis-h"
            title="Redirect to contact manage page"
            onClick={(event) => {
              event.preventDefault();
              if (onButtonClick) {
                onButtonClick(event);
                if (isNil(notifications) || notifications.length === 0)
                  setShowDialog(true);
              } else {
                setShowDialog(true);
              }
            }}
          />
        </div>
        {visited && validationMessage && <Error>{validationMessage}</Error>}
        {showDialog &&
          (isUseSimpleAddressDialog() ? (
            <>
              <SimplePropertyDetail
                initialData={initialData}
                onClose={() => setShowDialog(false)}
                onSubmit={(value: any) => {
                  handleValidateAndSubmit(value);
                }}
                isLoading={isLoadingDialog}
                isLoadingValidate={isLoadingValidate}
                setIsLoadingValidate={setIsLoadingValidate}
              />
              {isShowAddressValidationDialog && (
                <AddressValidationDialog
                  formData={dataAddress}
                  onAction={handleValidate}
                  onClose={() => setIsShowAddressValidationDialog(false)}
                />
              )}
            </>
          ) : (
            <ComplexPropertyDetail
              initialData={initialData}
              onClose={() => setShowDialog(false)}
              onSubmit={(value: any) => {
                setShowDialog(false);
                updateSaveTriggers();
                onSubmit(value);
              }}
              isLoading={isLoadingDialog}
            />
          ))}
      </>
    );
  }
);

/* ====  SiteUserHandle-Render list of search results (in manage page) ==== */
const ItemRender = (
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps,
  isSearchPropertyAddresses?: boolean
) => {
  const { dataItem } = itemProps;
  const itemChildren = (
    <div className="cc-search-item">
      {(isSearchPropertyAddresses
        ? colSearchPropertyAddresses
        : colSearchAddresses
      ).map((col: IColumnFields) => (
        <div key={col.field} style={{ width: col.width }}>
          {dataItem[col.field] ?? ""}
        </div>
      ))}
    </div>
  );
  return React.cloneElement(li, li.props, itemChildren);
};
/* ====  / SiteUserHandle-Render list of search results (in manage page) ==== */
