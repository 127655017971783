import { IMainMenuItem } from "@components/layout/model";
import { AmsRoute } from "./ams.route";

export const AmsMenu: IMainMenuItem[] = [
  {
    menuName: "ASSETS",
    subItems: [
      { name: "Register", path: AmsRoute.ASSET_REGISTER_PATH },
      // { name: "Category", path: AmsRoute.CATEGORY_MANAGE_PATH },
      // { name: "Inspection Assessment", path: AmsRoute.ASSESSMENT_MANAGE_PATH },
      // { name: "Kanban Board", path: AmsRoute.KANBAN_MANAGE_PATH },
    ],
  },
  {
    menuName: "REPORTS",
    subItems: [
      {
        name: "Computer Asset Report",
        path: AmsRoute.COMPUTER_ASSET_REPORT_PATH,
      },
      {
        name: "Computer Warranty Report",
        path: AmsRoute.COMPUTER_WARRANTY_REPORT_PATH,
      },
    ],
  },
  // {
  //   menuName: "WORK ORDER",
  //   subItems: [
  //     { name: "Work Orders", path: AmsRoute.WORK_ORDER_REGISTER_PATH },
  //   ],
  // },
  // {
  //   menuName: "CONFIGURATION",
  //   subItems: [
  //     {
  //       name: "Work Order Configuration",
  //       path: AmsRoute.ADMIN_WORK_ORDER_CONFIGURATION,
  //     },
  //     {
  //       name: "Asset Management Settings",
  //       path: AmsRoute.ADMIN_SETTINGS,
  //     },
  //   ],
  // },
];
