import { getTownPlanningDetailsTabById } from "@app/products/town-planning/[id]/components/reference-sidebar/details/api";
import { TownPlanningRegisterForm } from "@app/products/town-planning/[id]/model";
import { convertTownPlanningResponseToForm } from "@app/products/town-planning/[id]/util";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue, splitCamelCase } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const TownPlanningDetailsTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const [isLoading, setIsLoading] = useState(true);
  const [townPlanningInfo, setTownPlanningInfo] = useState<
    TownPlanningRegisterForm | undefined
  >();

  const params: { id: string } = useParams();
  const townPlanningId = params.id;

  useEffect(() => {
    if (lastSelectedRow?.ID || townPlanningId) {
      const ID: any = lastSelectedRow?.ID || townPlanningId;
      setIsLoading(true);
      getTownPlanningDetailsTabById(ID).then((data) => {
        if (!data) {
          return;
        }
        const planningForm: TownPlanningRegisterForm =
          convertTownPlanningResponseToForm(data as any);
        setTownPlanningInfo(planningForm);
        setIsLoading(false);
      });
    }
  }, [lastSelectedRow, townPlanningId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!townPlanningInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Application number:"}
        value={townPlanningInfo.Application_ID?.toString()}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Status:"}
          value={splitCamelCase(townPlanningInfo?.Status_ENUM || "")}
          success
        />
        <ProductReferenceRow
          title={"OS Fees:"}
          value={formatDisplayValue(10, CURRENCY_FORMAT.CURRENCY1)}
          format="currency"
        />
        {townPlanningInfo?.Date_Created && !lastSelectedRow?.ID && (
          <ProductReferenceRow
            title={"Created:"}
            value={formatDisplayValue(
              townPlanningInfo?.Date_Created,
              DATE_FORMAT.DATE
            )}
          />
        )}
        {townPlanningInfo?.LodgedDate && (
          <ProductReferenceRow
            title={"Lodged:"}
            value={formatDisplayValue(
              townPlanningInfo?.LodgedDate,
              DATE_FORMAT.DATE
            )}
          />
        )}
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
