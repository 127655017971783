import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { alertDeleteRecord } from "@app/products/direct-debit/constant";
import { getDirectDebitGroupById } from "@app/products/direct-debit/system-admin/groups/[id]/api";
import { ExistManageDirectDebitGroup } from "@app/products/direct-debit/system-admin/groups/[id]/existed/_index";
import { NewManageDirectDebitGroup } from "@app/products/direct-debit/system-admin/groups/[id]/new/_index";
import { checkIsInactiveDirectDebitGroup } from "@app/products/direct-debit/system-admin/groups/[id]/util";
import {
  TitleDirectDebitGroupManagePage,
  manageDirectDebitGroupAliasSlider,
} from "@app/products/direct-debit/system-admin/groups/constant";
import { DirectDebitGroup } from "@app/products/direct-debit/system-admin/model";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { EWatchStatusLoading } from "@common/stores/route/model";
import { routeDataStoreInstance } from "@common/stores/route/store";
import { CCCheckPermissionWrapper } from "@components/cc-check-permission-wrapper/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

const ManageDirectDebitGroup = () => {
  //#region STORE ========/
  const {
    listenNotificationPortal,
    pushNotificationPortal,
    clearNotifications,
  } = useNotificationPortalStore();
  const history = useHistory();
  const { setInitialDataForms, isLoadingForm } = useFlexibleFormStore();
  //#endregion STORE =====/

  //#region HOOK ========/
  const { cancelToken } = useCancelRequest();
  const isNew = useIsNew();
  //#endregion HOOK =====/

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  const groupId = useMemo(() => {
    return isNew ? 0 : +id;
  }, [isNew, id]);
  //#endregion Variable =====/

  const [hasAllowNewPermission, setHasAllowNewPermission] = useState(false);

  const manageDirectDebitGroupSlider = useFlexibleFetchData({
    alias: manageDirectDebitGroupAliasSlider,
    slides: [
      {
        fetch: () => getDirectDebitGroupById(groupId, cancelToken()),
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<DirectDebitGroup> = dataFromApi;
          const ddGroupFormData = response?.data;
          //#region save data to store ========/
          setInitialDataForms({
            GeneralForm: ddGroupFormData,
          });
          //#endregion save data to store =====/

          //#region Notification ========/
          listenNotificationPortal();

          if (checkIsInactiveDirectDebitGroup()) {
            pushNotificationPortal(alertDeleteRecord, true);
          }
          //#endregion Notification =====/
        },
      },
    ],
  });

  useEffect(() => {
    if (!isNew || (isNew && hasAllowNewPermission)) {
      manageDirectDebitGroupSlider.fetchApi();
    }
    return () => clearNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, hasAllowNewPermission]);

  return (
    <>
      <Loading
        isLoading={
          manageDirectDebitGroupSlider.isFetching ||
          isLoadingForm ||
          routeDataStoreInstance.isLoadingFor(
            EWatchStatusLoading.IsLoadingPermission
          )
        }
        isFullScreen
      />
      <FormNavigation title={TitleDirectDebitGroupManagePage} />
      {manageDirectDebitGroupSlider.errorComponent ? (
        manageDirectDebitGroupSlider.errorComponent
      ) : isNew ? (
        <CCCheckPermissionWrapper
          onChangeHasPermission={() => setHasAllowNewPermission(true)}
          permission={{
            formIdentifier: FormIdentifier.DirectDebit_Dialog_Group,
            productType: PRODUCT_TYPE_NUMBER.DirectDebit,
            formAction: [FormAction.CORE_ALLOW_NEW, FormAction.CORE_ALLOW_SAVE],
          }}
        >
          <NewManageDirectDebitGroup />
        </CCCheckPermissionWrapper>
      ) : (
        <ExistManageDirectDebitGroup />
      )}
    </>
  );
};

export default observer(ManageDirectDebitGroup);
