import { ModifyAssessmentCommonButton } from "@app/products/property/assessments/components/action-bar/buttons/modify-assessment/common/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export const ModifyAssessmentButton = observer(() => {
  const { gridSelectedRows, isEmptyData } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();

  const isDisabled = useMemo(() => {
    return (
      (!isEmptyData && gridSelectedRows.length !== 1 && !id) ||
      (!id && isEmptyData)
    );
  }, [gridSelectedRows.length, id, isEmptyData]);

  const assessmentID = useMemo(() => {
    return gridSelectedRows[0]?.Assessment_Id ?? id;
  }, [gridSelectedRows, id]);

  return (
    <ModifyAssessmentCommonButton
      assessmentID={assessmentID}
      statusID={gridSelectedRows[0]?.Assessment_Status}
      isDisabled={isDisabled}
    />
  );
});
