import { eventEmitter } from "@/App";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";
import { AddWorkflowDialog } from "@app/products/property/components/action-bar/dialog/add-workflow/_index";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import {
  DTO_Workflow_Approval_Step,
  WorkflowProcessMode,
} from "@app/products/property/model";

export const ConfirmReallocateDialog = observer(() => {
  const {
    isLoadingReallocate,
    setDataForReallocateDialog,
    dataForReallocateDialog,
    setIsLoadingReallocate,
    resetStore,
  } = useConfirmReallocateDialogStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { removeDialog, listDialog } = usePropertyWorkflowStore();

  const handleConfirmReallocate = async (value: DTO_Workflow_Approval_Step) => {
    if (!dataForReallocateDialog) return;

    setIsLoadingReallocate(true);

    const {
      reallocateCallback,
      dataReallocate,
      defaultSuccessMessage,
      defaultErrorMessage,
    } = dataForReallocateDialog;
    const reallocateData = {
      ...dataReallocate,
      WorkflowHeader: {
        ...dataReallocate?.WorkflowHeader,
        AdhocStep: value,
      },
    };
    const response = await reallocateCallback(
      WorkflowProcessMode.Approve,
      reallocateData
    );

    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      pushNotification({
        title: response.data?.SuccessMessage ?? defaultSuccessMessage,
        type: "success",
      });
    } else {
      pushNotification({
        title: response?.data?.ErrorMessage ?? defaultErrorMessage,
        type: "error",
        autoClose: false,
      });
    }

    setIsLoadingReallocate(false);
    setDataForReallocateDialog(undefined);

    if (listDialog?.[0]?.type) {
      removeDialog(listDialog[0].type);
    }

    if (listDialog?.[0]?.typeList) {
      eventEmitter.emit(CCGridEventType.RefreshOData);
    }
  };

  useEffectOnce(() => {
    return () => resetStore();
  });

  return (
    <>
      {dataForReallocateDialog && (
        <AddWorkflowDialog
          onClose={() => setDataForReallocateDialog(undefined)}
          handleSubmit={handleConfirmReallocate}
          isLoadingOnDialog={isLoadingReallocate}
        />
      )}
    </>
  );
});
