export const mockSchemeDetailTab = {
  SchemeNumber: 32021,
  SchemeName: "Pakenham Industrial Estate Special Charge Scheme",
  SchemeType: "Private Streets Scheme",
  NumberOfAccounts: 48,
  PrincipalBalance: 0,
  BalanceOutstanding: 0,
  AmountDue: 0,
  InterestRate: 0,
  DefaultNumberOfInstalments: 1,
  SchemeInitiatedOn: "20-Nov-2001",
  SchemeDeclaredOn: "3-Dec-2008 ",
  SchemeCommencedOn: "10-Apr-2003",
  ActualSubsidy: 0,
  EstimatedCost: 1633334,
  EstimatedSubsidy: 0,
  EstimatedCostToAssessments: 1633334,
  RaiseInterestOnInterest: false,
  InterestRaisingFrequencyInMonths: 3,
  InstalmentFrequencyInMonths: 3,
  DayToPayInstalment: 30,
  SchemeId: 1,
};
