import { DTO_Workflow_ModifyDeferredDuty } from "@app/products/property/deferred-duty/components/action-bar/dialogs/modify-deferred-duty/model";
import {
  ResponseMessage,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postNewWorkflowModifyDeferredDuty = async (
  accountID?: number
): Promise<APIResponse<DTO_Workflow_ModifyDeferredDuty>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/modifydeferredduty/new`,
      {
        AccountID: accountID,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewModifyDeferredDuty = async (
  mode: WorkflowProcessMode,
  deferredDuty: DTO_Workflow_ModifyDeferredDuty | undefined
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/modifydeferredduty/process/${mode}`,
      deferredDuty
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getModifyDeferredDutyDraft = async (
  workflowDraftId: number | undefined
): Promise<APIResponse<DTO_Workflow_ModifyDeferredDuty>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/modifydeferredduty/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataDeferredDuty =
  | APIResponse<DTO_Workflow_ModifyDeferredDuty>
  | undefined;

export const getInitialDataModifyDeferredDuty = async (
  accountId?: number,
  workflowDraftId?: number
): Promise<IGetInitialDataDeferredDuty> => {
  try {
    return !isNil(workflowDraftId)
      ? await getModifyDeferredDutyDraft(workflowDraftId)
      : await postNewWorkflowModifyDeferredDuty(accountId);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
