import { CSL_ROUTE } from "@app/products/csl/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";

export const colCSLRenewalsDueForExpiry = [
  {
    field: "LicenceNumber",
    title: "Licence Number",
    locked: true,
    linkTo: (dataItem: any) => `${CSL_ROUTE}/${dataItem.ID}`,
  },
  {
    field: "Status",
    title: "Status",
  },
  {
    field: "Applicant",
    title: "Licensee",
  },
  {
    field: "TradingName",
    title: "Trading Name",
  },
  {
    field: "Category",
    title: "Licence Category",
  },
  {
    field: "Suburb",
    title: "Suburb",
  },
  {
    field: "RenewalStatus",
    title: "Renewal Status",
  },
  {
    field: "DateExpires",
    title: "Expires",
    format: DATE_FORMAT.DATE,
  },
];
