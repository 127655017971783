import { IKeyValuePacket } from "@common/models/keyValuePacket";

export enum SearchType {
  AssessmentNumber = "Assessment Number",
  AssessmentID = "Assessment ID",
}
export enum SearchField {
  AssessmentNumber = "AssessmentNumber",
  AssessmentID = "AssessmentID",
}
export interface ITransferMeter {
  AssessmentNumber: number | null | undefined;
  PropertyAddress: string | undefined;
  Ratepayer: string | undefined;
  BillingGroup?: IKeyValuePacket;
  DateOfChange: Date;
  MeterTransferred?: IMeterTransferred[];
  PropertyAddressTransferred?: string | undefined;
  AssessmentIDTransferred?: string | undefined;
  _option?: {
    BillingGroup: {
      Data: IKeyValuePacket[];
    };
    SearchAssessment: {
      Data: IKeyValuePacket[];
      Loading: boolean;
    };
  };
}
export interface IMeterTransferred {
  MeterNumber: string | undefined;
  MeterID: number | null | undefined;
  Change?: boolean;
  AssessmentNumber: number | null;
  PropertyAddress: string;
}
export interface IAccountResponsible {
  AssessmentNumber: number;
  FormattedAddress: string;
  DateOfChange: Date;
  AssessmentID: number;
}
export const textFieldMapping: {
  [key: string]: string;
} = {
  [SearchType.AssessmentNumber]: SearchField.AssessmentNumber,
  [SearchType.AssessmentID]: SearchField.AssessmentID,
};
