import { ICCRoute } from "@common/constants/ICCRoute";
import { appealHearingRoute } from "@common/pages/appeal/_id/appeal-hearing/route";

export const appealRoute: ICCRoute = {
  path: "appeal",
  name: "Appeal",
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./_id/_index").default,
      children: [appealHearingRoute],
    },
  ],
};
