import { AnimalsFieldMapping } from "@app/products/animals/model";
import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colAnimalseAnimalsNewApplications: IColumnFields[] = [
  {
    field: AnimalsFieldMapping.RegistrationNumber,
    title: "Registration Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${ANIMALS_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: AnimalsFieldMapping.TagNo,
    title: "Tag Number",
    width: 150,
  },
  {
    field: AnimalsFieldMapping.MicrochipNo,
    title: "Chip Number",
    width: 150,
  },
  { field: AnimalsFieldMapping.AnimalOwner, title: "Animal Owner", width: 150 },
  {
    field: AnimalsFieldMapping.SiteAddress,
    title: "Registered Address",
    width: 150,
  },
  { field: AnimalsFieldMapping.Locality, title: "Locality", width: 150 },
  { field: AnimalsFieldMapping.Type, title: "Animal Type", width: 150 },
  { field: AnimalsFieldMapping.Breed, title: "Breed", width: 150 },
  { field: AnimalsFieldMapping.AnimalName, title: "Name", width: 150 },
  { field: AnimalsFieldMapping.Colour, title: "Colour", width: 150 },
  { field: AnimalsFieldMapping.Gender, title: "Gender", width: 150 },
  {
    field: AnimalsFieldMapping.Age,
    title: "Age",
    width: 150,
  },
  {
    field: AnimalsFieldMapping.Sterilised,
    title: "Sterilised",
    width: 150,
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: AnimalsFieldMapping.AmountOS,
    title: "Outstanding",
    width: 150,
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: AnimalsFieldMapping.StatusDueStatus,
    title: "Status",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: AnimalsFieldMapping.EffectiveTo,
    title: "Due",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
];
