import PoundRegisterFormElement from "@app/products/animals/pound-register/[id]/components/child-screens/general/form-element/_index";
import {
  PoundRegister,
  PoundRegisterSubmitActions,
} from "@app/products/animals/pound-register/[id]/model";
import { useAnimalPoundRegisterStore } from "@app/products/animals/pound-register/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef } from "react";

export const PoundRegistrationForm = observer(() => {
  const { poundRegisterInfo, isFormModified, savePoundRegister, setOnSubmit } =
    useAnimalPoundRegisterStore();
  const { pushNotification } = useCCAppNotificationStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const action = event.event?.currentTarget
      .name as PoundRegisterSubmitActions;
    const { values, isModified, isValid } = event;
    if (!(action in PoundRegisterSubmitActions)) return;
    if (!isValid) return;
    if (
      !isModified &&
      !isFormModified &&
      [
        PoundRegisterSubmitActions.Save,
        PoundRegisterSubmitActions.New,
      ].includes(action) &&
      ![
        PoundRegisterSubmitActions.LodgeRegistration,
        PoundRegisterSubmitActions.AddRegister,
      ].includes(action)
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    const formValues = values.PoundRegister;
    savePoundRegister(formValues as PoundRegister, action);
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, poundRegisterInfo]);

  const initialValues = useMemo(() => {
    return {
      PoundRegister: poundRegisterInfo,
    };
  }, [poundRegisterInfo]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <PoundRegisterFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
