import { isSuccessResponse } from "@common/apis/util";
import { resetPassword } from "@common/pages/reset-password/api";
import { validateRequired } from "@common/utils/field-validators";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { getter } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";

export const ResetPasswordPage = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification } = useCCAppNotificationStore();

  let history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const enc = params.get("enc");

  /* ====  Validator  ==== */
  const validateConfirmPassword: any = (data: object) => {
    if (getter("NewPassword")(data) === getter("ConfirmPassword")(data)) {
      return {};
    }
    return {
      ConfirmPassword: "Your password and confirmation password do not match.",
    };
  };
  /* ====  / Validator  ==== */

  /* ====  Form Submit  ==== */
  const handleSendRequest = async (data: any) => {
    data.EncodedKey = enc;
    setIsLoading(true);
    // Call Api
    try {
      const response = await resetPassword(data);
      setIsLoading(false);
      if (isSuccessResponse(response)) {
        pushNotification({
          title: response.data?.message ?? "Reset password successfully",
          type: "success",
        });
        setTimeout(function () {
          history.push("/login");
        }, 5000);
      } else {
        pushNotification({
          title: response.data?.Message ?? "Can't reset password",
          type: "error",
          autoClose: false,
        });
      }
    } catch (error) {
      pushNotification({
        title: "Error connecting to server",
        type: "error",
        autoClose: false,
      });
    }
  };
  /* ====  / Form Submit  ==== */

  return (
    <>
      <div className="cc-reset-password-page">
        <div className="cc-reset-password-card">
          <div className="cc-reset-password-header">
            <img
              className="cc-reset-password-logo"
              src="/static/media/logo.daa78c7f.png"
              alt="Ready Community"
            />
            <div className="cc-reset-password-company-name">
              ready community
            </div>
          </div>
          <CCAppNotification />
          <Form
            validator={validateConfirmPassword}
            onSubmit={handleSendRequest}
            render={(fromRenderProps: FormRenderProps) => (
              <FormElement>
                <fieldset className="k-form-fieldset cc-reset-password-form-fieldset">
                  <div className="cc-field">
                    <label className="cc-reset-password-form-label">
                      Temporary password
                    </label>
                    <Field
                      validator={validateRequired}
                      component={CCInput}
                      type="password"
                      placeholder="Temporary password"
                      name="CurrentPassword"
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-reset-password-form-label">
                      New password
                    </label>
                    <Field
                      validator={validateRequired}
                      component={CCInput}
                      type="password"
                      placeholder="New password"
                      name="NewPassword"
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-reset-password-form-label">
                      Confirm new password
                    </label>
                    <Field
                      validator={validateRequired}
                      component={CCInput}
                      type="password"
                      placeholder="Confirm new password"
                      name="ConfirmPassword"
                    />
                  </div>

                  <div className="cc-reset-password-inline-group-ok">
                    <Button
                      className="cc-reset-password-button-ok"
                      type="submit"
                      themeColor="primary"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <i className="fas fa-spinner fa-spin cc-reset-password-button-icon" />
                      ) : null}
                      <span> RESET</span>
                    </Button>
                  </div>
                </fieldset>
              </FormElement>
            )}
          />
        </div>
      </div>
    </>
  );
});
