import { colPotentialDuplicateEntitiesDetail } from "@app/products/property/contacts-central-names/potential-duplicate-entities/_shares/config";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";

const DetailComponent = ({ dataItem }: GridDetailRowProps) => {
  return (
    <>
      <CCGrid
        data={dataItem.Detail ?? []}
        columnFields={colPotentialDuplicateEntitiesDetail}
        primaryField="EntityId"
      />
    </>
  );
};

export default DetailComponent;
