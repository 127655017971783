import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { ReferenceSlideBarPortalAssessmentCollections } from "@app/products/property/assessments/collections/components/reference-slide-bar-portal/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const ActionBarInfoIconAssessmentCollections = observer(() => {
  const { setIsShowSlideBar, isShowSlideBar, isEmptyData } =
    useDetailAssessmentListViewStore();
  const { isEmptyData: isEmptyDataParent } = useCCProductListViewStore();

  return (
    <>
      <CCNavButton
        iconClass="fal fa-info-circle"
        title="Show Information"
        disabled={isEmptyData}
        onClick={() => {
          setIsShowSlideBar(!isShowSlideBar);
        }}
        isActive={isShowSlideBar && !isEmptyData}
      />
      {isEmptyDataParent ? null : (
        <ReferenceSlideBarPortalAssessmentCollections />
      )}
    </>
  );
});
