import { FeeType } from "@app/products/town-planning/ppr/[id]/model";

export const getFeeNextAmount = (feeType?: FeeType) => {
  if (!feeType) return null;
  let retVal = feeType.NextAmount_ExGST
    ? feeType.Flag_GSTApplies
      ? feeType.NextAmount_ExGST +
        (feeType.NextAmount_ExGST * feeType.NextAmount_GSTPercentage) / 100
      : feeType.NextAmount_ExGST
    : null;
  return retVal ? parseFloat(retVal.toFixed(2)) : null;
};
export const getFeeDefaultAmount = (feeType: FeeType) => {
  if (!feeType) return null;
  let retVal = feeType.DefaultAmount_ExGST
    ? feeType.Flag_GSTApplies
      ? feeType.DefaultAmount_ExGST +
        (feeType.DefaultAmount_ExGST * feeType.DefaultAmount_GSTPercentage) /
          100
      : feeType.DefaultAmount_ExGST
    : null;
  return retVal ? parseFloat(retVal.toFixed(2)) : 0;
};
