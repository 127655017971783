import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { VO_Notice_Assessment } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/child-screens/notices/model";
import {
  CURRENCY_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<VO_Notice_Assessment>();
export const colNoticeRunsAssessments: IColumnFields[] = [
  {
    field: nameOf("Assess_Number"),
    title: "Assessment Number",
    format: DECIMAL_FORMAT.DECIMAL1,
    linkTo: (dataItem: VO_Notice_Assessment) =>
      `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem[nameOf("Assessment_Id")]}`,
  },
  {
    field: nameOf("Assess_Reference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Assess_Property_Address"),
    title: "Assessment Property Address",
  },
  {
    field: nameOf("Notice_Name"),
    title: "Notice Name",
  },
  {
    field: nameOf("Locality_Name"),
    title: "Locality Name",
  },
  {
    field: nameOf("NA_Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Interest_Balance"),
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Total_Balance"),
    title: "Total Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Arrears_Balance"),
    title: "Arrears Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Arrears_Interest_Balance"),
    title: "Arrears Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Current_Balance"),
    title: "Current Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Current_Interest_Balance"),
    title: "Current Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Charges_Raised"),
    title: "Charges Raised",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Rebate_Amount"),
    title: "Total Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Nett_Charges_Raised"),
    title: "Amount Due",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Notice_Assessment_Id"),
    title: "Notice Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Notice_Id"),
    title: "Notice ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Notice_Run_Id"),
    title: "Notice Run ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
