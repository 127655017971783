import { colAddressValidation } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/address-validation/config";
import { loadAddressValidationSearch } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/validation-adress-search/api";
import { NSWPointSuggestion } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/validation-adress-search/model";
import { DTO_Address } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement } from "@progress/kendo-react-form";
import { isEmpty } from "lodash";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

export type statusAddressValidation = "OK" | "CANCEL" | "SKIP" | "CLOSE";
export interface IOnAction {
  status: statusAddressValidation;
  dataValidate: NSWPointSuggestion | undefined;
}
export interface IAddressValidationDialogProps {
  address: DTO_Address;
  onAction: ({ status, dataValidate }: IOnAction) => void;
}
const nameOf = nameOfFactory<NSWPointSuggestion>();

export const AddressValidationDialog = ({
  address,
  onAction,
}: IAddressValidationDialogProps) => {
  const [addressSuggestion, setAddressSuggestion] = useState<
    NSWPointSuggestion[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [dataValidate, setDataValidate] = useState<NSWPointSuggestion>();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  useEffectOnce(() => {
    setIsDisabled(true);
    (async () => {
      if (isEmpty(address)) return;
      setIsLoading(true);
      const response = await loadAddressValidationSearch(
        address?.PropertyAddress
      );
      if (isSuccessResponse(response)) {
        response.data && setAddressSuggestion(response?.data?.suggestions);
      } else {
        notificationRef.current?.pushNotification({
          title: "Address validation load failed",
          type: "error",
        });
      }
      setIsLoading(false);
    })();
  });

  const handleSubmit = (status: statusAddressValidation) => {
    onAction({ status, dataValidate });
  };

  return (
    <Form
      render={() => {
        return (
          <FormElement className="cc-form">
            <section className="cc-field-group">
              <CCLocalNotification ref={notificationRef} />
              <CCDialog
                isLoading={isLoading}
                disabled={isLoading}
                maxWidth="550px"
                height="auto"
                titleHeader="Address Validation"
                onClose={() => {
                  handleSubmit("CLOSE");
                }}
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <section className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <CCGrid
                            selectableMode="single"
                            data={addressSuggestion}
                            primaryField={nameOf("address")}
                            columnFields={colAddressValidation}
                            onSelectionChange={(
                              dataItem: NSWPointSuggestion[]
                            ) => {
                              setIsDisabled(dataItem.length < 1);
                              setDataValidate(dataItem?.[0] ?? []);
                            }}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button
                      className={"cc-dialog-button"}
                      onClick={() => {
                        handleSubmit("SKIP");
                      }}
                    >
                      Skip Selection
                    </Button>
                    <Button
                      className={"cc-dialog-button"}
                      onClick={() => {
                        handleSubmit("CANCEL");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={isDisabled}
                      themeColor="primary"
                      onClick={() => {
                        handleSubmit("OK");
                      }}
                      className={"cc-dialog-button"}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
            </section>
          </FormElement>
        );
      }}
    />
  );
};
