import { eventEmitter } from "@/App";
import { colActionsAccordion } from "@app/core/actions/action-accordion/config";
import { IActionsAccordion } from "@app/core/actions/action-accordion/model";
import { CORE_GET_ACTIONS_ODATA } from "@app/core/components/common/constant";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { useTabTableStore } from "@app/core/tab-table/store";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { loadActionTypeConfig } from "@common/pages/actions/util";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IActionsAccordionProps {
  id?: number;
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<IActionsAccordion>();
export const ActionsAccordion = observer(
  ({ id, recordType }: IActionsAccordionProps) => {
    const [gridSelectedRows, setGridSelectedRows] = useState<
      IActionsAccordion[]
    >([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
      useState(false);
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const { isDisabled } = useTabTableStore();
    const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
      []
    );
    const [newColActions, setNewColActions] =
      useState<IColumnFields[]>(colActionsAccordion);
    const [isLoading, setIsLoading] = useState(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();

    const handleConfirmDelete = async (data: IDeleteConfirmation) => {
      setIsDeleting(true);
      const parameterRecordType = gridSelectedRows[0]?.RecordType_ID
        ? gridSelectedRows[0]?.RecordType_ID
        : RECORDTYPE.CORE_Action;
      const response = await deleteRefUsageByIdAndRecordType(
        gridSelectedRows?.map((item: IActionsAccordion) => item.ID),
        parameterRecordType,
        data?.Reason
      );
      setIsDeleting(false);
      if (isSuccessResponse(response)) {
        setShowConfirmDeleteDialog(false);
        clearErrorNotification();
        setGridSelectedRows([]);
        pushNotification({
          title: "Action deleted successfully",
          type: "success",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(CCJournalEventType.RefreshData);
        eventEmitter.emit(TabTableEventType.RefreshData);
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: "Delete action failed",
            type: "error",
            description: response.data?.Errors,
          },
        ]);
      }
    };

    const actionTypeLovs = async () => {
      setIsLoading(true);
      setResponseLoadError(undefined);
      const response = await loadActionTypeConfig(
        colActionsAccordion,
        nameOf("Date")
      );
      if (Array.isArray(response)) {
        setNewColActions(response);
      } else {
        setResponseLoadError(response);
      }
      setIsLoading(false);
    };

    useEffectOnce(() => {
      actionTypeLovs();
    });

    if (isLoading) return <Loading isLoading={isLoading} />;
    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            actionTypeLovs();
          }}
        />
      );

    return (
      <>
        <CCGrid
          toolbar={
            <div className="cc-grid-tools-bar">
              <Button
                type="button"
                iconClass="fas fa-minus"
                title="Remove"
                disabled={
                  isDisabled ? isDisabled : gridSelectedRows.length !== 1
                }
                onClick={() => {
                  setShowConfirmDeleteDialog(true);
                }}
              />
            </div>
          }
          selectableMode="multiple"
          dataUrl={`${CORE_GET_ACTIONS_ODATA}(key=${id},recordType=${recordType})?$count=true&`}
          primaryField={nameOf("ID")}
          state={{
            sort: [{ field: nameOf("Date"), dir: "desc" }],
          }}
          selectedRows={gridSelectedRows}
          onSelectionChange={(dataItem: IActionsAccordion[]) => {
            if (dataItem) setGridSelectedRows([...dataItem]);
          }}
          columnFields={newColActions}
        />
        {showConfirmDeleteDialog && (
          <ConfirmDelete
            onClose={() => {
              setShowConfirmDeleteDialog(false);
            }}
            contentDelete={gridSelectedRows[0]?.Type}
            handleSubmit={handleConfirmDelete}
            isDeleting={isDeleting}
            notifications={notifications}
          />
        )}
      </>
    );
  }
);
