import { CCGrid } from "@components/cc-grid/_index";
import { Input } from "@progress/kendo-react-inputs";
import React from "react";
import {
  colAdditionalParcelDetailsGrid1,
  colAdditionalParcelDetailsGrid2,
  colAdditionalParcelDetailsGrid3,
  colCrownAllotmentAndMemorial,
  colOtherIdentifiers,
} from "./config";
import { mockAdditionalDetails } from "./mock";

const AdditionalDetails = () => {
  return (
    <div className="cc-e-certificate-additional-details">
      <section className="cc-form cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Additional parcel details</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label"> Parcel reference</label>
                <CCGrid
                  data={mockAdditionalDetails.AdditionalParcelDetails}
                  columnFields={colAdditionalParcelDetailsGrid1}
                  primaryField="No"
                />
              </div>
              <div className="cc-field">
                <label className="cc-label"> Associated titles</label>
                <CCGrid
                  data={mockAdditionalDetails.AdditionalParcelDetails}
                  columnFields={colAdditionalParcelDetailsGrid2}
                  primaryField="InstalmentNumber"
                />
              </div>
              <div className="cc-field">
                <label className="cc-label"> Map reference</label>
                <CCGrid
                  data={mockAdditionalDetails.AdditionalParcelDetails}
                  columnFields={colAdditionalParcelDetailsGrid3}
                  primaryField="No"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section className="cc-form cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Crown allotment and memorial</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Memorial</label>
                <Input
                  readOnly
                  className="cc-form-control"
                  name={"Memorial"}
                  placeholder={"Memorial"}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Book</label>
                <Input
                  readOnly
                  className="cc-form-control"
                  name={"Book"}
                  placeholder={"Book"}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label"> Title details</label>
                <CCGrid
                  data={mockAdditionalDetails.CrownAllotmentAndMemorial}
                  columnFields={colCrownAllotmentAndMemorial}
                  primaryField="No"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section className="cc-form cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Other identifiers</label>

          <CCGrid
            data={mockAdditionalDetails.OtherIdentifiers}
            columnFields={colOtherIdentifiers}
            primaryField="InstalmentNumber"
          />
        </div>
      </section>
    </div>
  );
};

export default AdditionalDetails;
