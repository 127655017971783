import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { DTO_LOV } from "@common/models/odataResponse";
import { IColumnFields } from "@components/cc-grid/model";

export const colCost: IColumnFields[] = [
  {
    field: "Costs",
    title: "Costs",
    editable: false,
  },
  {
    field: "Estimated",
    title: "Estimated",
    format: CURRENCY_FORMAT.CURRENCY2,
    editable: true,
    disableEditField: "isDisableEdit",
  },
  {
    field: "Actual",
    title: "Actual",
    format: CURRENCY_FORMAT.CURRENCY2,
    editable: true,
    disableEditField: "isDisableEdit",
  },
];

export const colInterestRateAndPenaltyRate: IColumnFields[] = [
  {
    field: "EffectiveFrom",
    title: "Effective From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "InterestRate",
    title: "Interest Rate",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: "PenaltyRate",
    title: "Penalty Rate",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: "Status",
    title: "Status",
  },
];
export const colInterestAndInstalmentDetailsGL: IColumnFields[] = [
  {
    field: "GLAccountType",
    title: "GL Account Type",
  },
  {
    field: "GLAccReference",
    title: "GL Acc Reference",
  },
  {
    field: "LineItem",
    title: "Line Item",
  },
];

export const mockSchemeType: DTO_LOV[] = [
  {
    Code: "1",
    Name: "Drainage Scheme",
  },
  { Code: "2", Name: "Footpath Scheme" },
  { Code: "3", Name: "Kerb & Channel Scheme" },
  { Code: "4", Name: "Paving Scheme" },
  { Code: "5", Name: "Private Streets Scheme" },
];

export const mockInstalmentType: DTO_LOV[] = [
  {
    Code: "1",
    Name: "Fixed Instalment of varying Principal & Actual Interest Raised",
  },
  {
    Code: "2",
    Name: "Fixed Instalment of varying Principal & Scheduled Interest (excl interest on",
  },
  { Code: "3", Name: "Fixed Principal" },
  { Code: "4", Name: "Fixed Principal • Annual Interest on Fixed Amount" },
];
