import { defermentsRoute } from "@app/products/property/assessments/deferments/route";
import { rebatesRoute } from "@app/products/property/assessments/rebates/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { arrangementsRoute } from "./arrangements/route";
import { complianceRoute } from "./compliance/route";
import { debtRecoveryRoute } from "./debt-recovery/route";
import { financialSummariesRoute } from "./financial-summaries/route";
import { mailboxListRoute } from "./mailbox-list/route";
import { masterPropertiesRoute } from "./master-properties/route";

export const assessmentsRoute: ICCRoute = {
  path: "assessments",
  name: "Assessments",
  enum: eMenuAction.Assessment_Folder,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
    {
      path: "list",
      name: "List",
      enum: eMenuAction.Assessment_List,
      component: require("./list/_index").default,
    },
    {
      path: "active",
      name: "Active",
      enum: eMenuAction.Assessment_Active,
      component: require("./active/_index").default,
    },
    {
      path: "balances",
      name: "Balances",
      component: require("./balances/_index").default,
      enum: eMenuAction.Assessment_Balances,
    },
    {
      path: "collections",
      name: "Collections",
      enum: eMenuAction.Assessment_Collections,
      component: require("./collections/_index").default,
    },
    mailboxListRoute,
    arrangementsRoute,
    defermentsRoute,
    masterPropertiesRoute,
    debtRecoveryRoute,
    financialSummariesRoute,
    rebatesRoute,
    complianceRoute,
  ],
};
