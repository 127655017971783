
import { ContactClassification } from "@app/core/contacts/_id/model";
import { isNil } from "lodash";
/**
 * 4 - Company
 * 5 - Partnership
 * 6 - Incorporated Body
 */
// This list use for show ACN and ABN fields - Task 5736
export const listConditionContactType = [ContactClassification.Company,ContactClassification.IncorporatedBody,ContactClassification.Partnership]

export const isShowOnContactType = (type?: ContactClassification) => {
   if(isNil(type)) return false;
   return listConditionContactType.includes(type);
}