import { Popup, PopupProps } from "@progress/kendo-react-popup";
import { OpenEvent } from "@progress/kendo-react-popup/dist/npm/models/Events";
import React from "react";
import { useWindowSize } from "react-use";

export interface ICCPopupControlProps {
  popup: PopupProps;
  container: HTMLElement | null;
  controlHeight: number;
  controlMargin: number;
}
export const CCPopupControl = ({
  popup,
  container,
  controlHeight,
  controlMargin,
}: ICCPopupControlProps) => {
  const { height } = useWindowSize();

  return (
    <Popup
      {...popup}
      animate={{ openDuration: 0 }}
      onOpen={(event: OpenEvent) => {
        const element = (event.target as any)?._popup;
        if (!element || !container) return;

        const { top, bottom: rectBottom } = container.getBoundingClientRect();
        const bottom = height - rectBottom;
        // top and bottom is distance of element with screen

        let newHeight = undefined;
        let newTop = undefined;

        if (bottom < top && bottom < controlHeight) {
          //Show on top
          newHeight = Math.min(
            controlHeight,
            Math.max(controlMargin, top - controlMargin)
          );
          newTop = top - newHeight;
        } else {
          //Show on bottom
          newHeight = Math.min(
            controlHeight,
            Math.max(controlMargin, bottom - controlMargin)
          );
        }

        //Move popup to new position and set new height
        if (newTop) element.style.top = `${newTop}px`;
        element.style.height = `${newHeight}px`;
      }}
    />
  );
};
