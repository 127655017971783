import { CommunityProperty } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const RaiseSingleSchemeAccountInterestButton = observer(() => {
  const { isLLS, isActro, isGeneral } = CommunityProperty.getFlagOfStates();
  if (isActro || isLLS || isGeneral) return null;
  return <CCNavButton title={"Raise single scheme account interest"} />;
});
