import { DTO_Journal } from "@app/products/property/journals/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";

export interface RequestTransferDDTransactionObj {
  TransactionIds: number[];
}

export interface DTO_Workflow_DDTransferTransaction {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_DDTransferTransaction;
}

export interface DTO_WorkflowDetail_DDTransferTransaction {
  Journal_Number: number | null;
  JournalMode: number;
  TransactionsIds: number[];
  TransactionDate: Date | null;
  CreateJournal: DTO_Journal;
  To_AccountId: number;
  Transactions: DTO_DeferredDuty_Account_Transaction[];
  Accounts: DTO_DeferredDuty_Account[];
}
export interface DTO_DeferredDuty_Account {
  AccountId: number;
  AccountNumber: number | null;
  AccountName: string;
  AssessmentNumber: number | null;
  AssessmentReference: string;
  PropertyAddress: string;
  Description: string;
  LegalDescription: string;
  Status: string;
  DutyBalance: number | null;
  Interest: number | null;
  OtherAmount: number | null;
  DDSTextColour: number | null;
  AssessmentId: number | null;
}
export interface DTO_DeferredDuty_Transaction {
  DeferredDutyTransactions: DTO_Transaction_Grouping;
}

export interface DTO_DeferredDuty_Account_Transaction {
  NoticeIssueDate: Date | null;
  AccountNumber: number | null;
  SourceId: number | null;
  TransactionSourceId: number | null;
  Notes: string;
  ReceiptNumber: string;
  Name: string;
  OtherAmount: number | null;
  CreatedOn: Date | null;
  IsCancelled: boolean;
  IsProposed: boolean;
  UserId: string;
  PropertyAddress: string;
  PayerName: string;
  JournalNumber: number | null;
  InterestAmount: number | null;
  DutyBalance: number | null;
  DeferredDutyAmount: number | null;
  TransactionAmount: number | null;
  Description: string;
  Reference: string;
  TransactionType: string;
  TransactionDate: Date | null;
  AccountId: number | null;
  AccountTransactionId: number;
  SessionNumber: number | null;
}

export interface DTO_DeferredDuty_TransactionTotals {
  TransactionAmount: number | null;
  DeferredDutyAmount: number | null;
  InterestAmount: number | null;
  OtherAmount: number | null;
}

export interface DTO_Transaction_Grouping {
  Transactions: DTO_DeferredDuty_Account_Transaction[];
  Totals: DTO_DeferredDuty_TransactionTotals;
}

export enum TransferDDTransactionKeysOfSteps {
  WhichJournal = "WhichJournal",
  NewJournal = "CreateJournal",
  AccountTo = "AccountTo",
  Transactions = "Transactions",
  Comments = "Comments",
  Documents = "Documents",
}

export const transferDDTransactionKeysOfSendSteps = [
  TransferDDTransactionKeysOfSteps.NewJournal,
  TransferDDTransactionKeysOfSteps.Transactions,
];
