import { CORE_OFFICERS_ODATA } from "@app/core/components/common/constant";
import { Officers } from "@app/core/inspections/[id]/model";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { InspectionClassification } from "@common/constants/enumerations";
import { Svc_InspectionType } from "@common/models/inspectionType";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const OfficerType: IColumnFields[] = [
  { field: "Name", title: "Name" },
  { field: "Position", title: "Position" },
  { field: "Email", title: "Email" },
  { field: "WorkPhone", title: "Phone" },
  { field: "Mobile", title: "Mobile" },
];

const nameOfInspectionType = nameOfFactory<Svc_InspectionType>();
export const renderOptionInspectionsType = (dataURL: string) => {
  const optionInspectionType: IOptionInputPicker = {
    dialog: {
      titleHeader: "Pick Inspection Type",
      maxWidth: "60%",
    },
    boxSearch: {
      colSearch: nameOfInspectionType("Name"),
      colShowComboboxSearch: [
        { field: nameOfInspectionType("Name"), title: "Name" },
      ],
    },
    grid: {
      columnFields: [
        { field: nameOfInspectionType("Name"), title: "Name" },
        {
          field: nameOfInspectionType("Classification"),
          title: "Classification",
        },
        { field: nameOfInspectionType("Description"), title: "Description" },
        { field: nameOfInspectionType("FeeAmount"), title: "Amount" },
      ],
      primaryField: nameOfInspectionType("ID"),
      dataUrl: dataURL,
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: [
          {
            field: nameOfInspectionType("Name"),
            dir: "asc",
          },
        ],
      },
    },
  };

  return optionInspectionType;
};

const nameOfOfficer = nameOfFactory<Officers>();
export const optionOfficer: IOptionInputPicker = {
  dialog: {
    titleHeader: "Pick Officer",
    maxWidth: "60%",
  },
  boxSearch: {
    colSearch: nameOfOfficer("Name"),
    colShowComboboxSearch: [{ field: nameOfOfficer("Name"), title: "Name" }],
  },
  grid: {
    columnFields: [
      { field: nameOfOfficer("Name"), title: "Name" },
      {
        field: nameOfOfficer("Position"),
        title: "Position",
      },
      { field: nameOfOfficer("Email"), title: "Email" },
      { field: nameOfOfficer("WorkPhone"), title: "Phone" },
    ],
    primaryField: nameOfOfficer("ID"),
    dataUrl: CORE_OFFICERS_ODATA,
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
    state: {
      sort: [
        {
          field: nameOfOfficer("Name"),
          dir: "asc",
        },
      ],
    },
  },
};

export const firstConditionEnumArray = [
  InspectionClassification.FIVESTAR_FOODASSESSMENT,
  InspectionClassification.WHITEHORSE_FIVESTAR_FOODASSESSMENT_V2,
  InspectionClassification.FIVESTAR_HEALTHASSESSMENT,
  InspectionClassification.FIVESTAR_FOODSAFETYRATING,
  InspectionClassification.SOUTHAUSTRALIA_FOODSAFETYRATINGSCHEME,
  InspectionClassification.SOUTHAUSTRALIA_FOODSAFETYRATINGSCHEME_V3,
];
export const secondConditionEnumArray = [
  InspectionClassification.FOODHYGIENEINSPECTION,
  InspectionClassification.ACCOMASSESSMENT,
  InspectionClassification.ACCOMASSESSMENTV2,
  InspectionClassification.FOODPREMISES_CHECKLIST,
  InspectionClassification.FOODPREMISES_GLENEIRA,
  InspectionClassification.FOODPREMISES_SURFCOAST,
  InspectionClassification.FOODPREMISES_GLENEIRA_FOLLOWUP_RISKASSESSMENT,
  InspectionClassification.FOODPREMISES_ASSESSMENT_ACTH,
];
