import { colActionTypeReferral } from "@app/products/town-planning/ppr/[id]/components/input-picker/pick-action-type-referral/config";
import { IActionType } from "@app/products/town-planning/ppr/[id]/components/input-picker/pick-action-type-referral/model";
import { nameOfFactory } from "@common/utils/common";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React from "react";

const nameOf = nameOfFactory<IActionType>();

export interface IPickActionTypeReferralPickerProps
  extends ICCInputPickerProps {
  onPickActionTypeReferral: (value: IActionType | null, name: string) => void;
  allowClear: boolean;
  selectMode?: "single" | "multiple" | "none";
}
export const PickActionTypeReferralPicker = (
  props: IPickActionTypeReferralPickerProps
) => {
  const {
    onChange,
    onError,
    onPickActionTypeReferral,
    allowClear,
    selectMode,
    name,
    ...others
  } = props;

  const handleOnChangeActionTypeReferral = (
    event: ICCInputPickerChangeEvent
  ) => {
    const value: IActionType | null = event.value;
    if (onPickActionTypeReferral) onPickActionTypeReferral(value, name ?? "");
  };

  return (
    <CCInputPicker
      {...others}
      dialog={{
        titleHeader: "Pick Referral Type",
        maxWidth: "45%",
      }}
      grid={{
        columnFields: colActionTypeReferral,
        primaryField: nameOf("ID"),
        selectableMode: selectMode,
        dataUrl: `odata/core/internal/actions/GetActionTypesReferrals()?$count=true&`,
        isAutoHiddenPager: false,
        state: { sort: [{ field: nameOf("ID"), dir: "asc" }] },
      }}
      showClearButton={allowClear}
      onChange={handleOnChangeActionTypeReferral}
    />
  );
};
