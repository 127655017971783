import {
  AUTHORISATION_NOTIFICATION_PORTAL_PLACE_ID,
  nameOfDDAuthorisation,
} from "@app/products/direct-debit/authorisations/[id]/components/dialogs/create-authorisation/constant";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { DATE_FORMAT } from "@common/constants/common-format";
import { Contact } from "@common/models/contact";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { isHTML } from "@components/cc-input-picker/util";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

export const DDAuthorisationDetailsStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(({ formRenderProps }: IFormStepElement) => {
  const { onChange, valueGetter } = formRenderProps;
  const { pushNotificationPortal } = useNotificationPortalStore();
  const prevAuthorisedDisplayName = useRef("");

  const handleChangeAuthorisedByName = (event: ComboBoxChangeEvent | null) => {
    const data = event?.value as Contact | null;
    onChange(nameOfDDAuthorisation("_options"), {
      value: {
        contactData: data ?? null,
      },
    });
    onChange(nameOfDDAuthorisation("Authorised_By_Name"), {
      value: data?.DisplayName ?? "",
    });
    onChange(nameOfDDAuthorisation("EMail"), {
      value: data?.Email ?? "",
    });
    onChange(nameOfDDAuthorisation("Phone_No"), {
      value: data?.WorkPhone ?? "",
    });
    onChange(nameOfDDAuthorisation("Mobile_Phone_No"), {
      value: data?.Mobile ?? "",
    });
    onChange(nameOfDDAuthorisation("Contact_ID"), {
      value: data?.Contact_ID ?? "",
    });
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Authorised by name" isMandatory />
          <Field
            uniqueKey="DirectDebitAuthorisationByName"
            name={`${nameOfDDAuthorisation("_options")}.contactData`}
            component={ContactPicker}
            onChange={handleChangeAuthorisedByName}
            onError={(error: any) => {
              pushNotificationPortal({
                type: "error",
                title: "Pick contact errors",
                description: error,
                autoClose: false,
                placeId: AUTHORISATION_NOTIFICATION_PORTAL_PLACE_ID,
              });
            }}
            placeholder="Select authorised by name"
            displayValue={valueGetter(
              nameOfDDAuthorisation("Authorised_By_Name")
            )}
            removeDisplayValue={() => {
              if (valueGetter(nameOfDDAuthorisation("Authorised_By_Name"))) {
                prevAuthorisedDisplayName.current = valueGetter(
                  nameOfDDAuthorisation("Authorised_By_Name")
                );
              }
            }}
            restoreDisplayValue={() => {
              if (
                isHTML(prevAuthorisedDisplayName.current) &&
                valueGetter(nameOfDDAuthorisation("_options")).contactData
              ) {
                onChange(nameOfDDAuthorisation("Authorised_By_Name"), {
                  value: prevAuthorisedDisplayName.current,
                });
              }
            }}
            validator={requiredValidator}
          />
        </div>
      </div>
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <CCValueField
            value={valueGetter(nameOfDDAuthorisation("EMail"))}
            label="Email"
          />
        </div>
        <div className="cc-field">
          <CCValueField
            value={valueGetter(nameOfDDAuthorisation("Phone_No"))}
            label="Phone"
          />
        </div>
        <div className="cc-field">
          <CCValueField
            value={valueGetter(nameOfDDAuthorisation("Mobile_Phone_No"))}
            label="Mobile phone"
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Authorised date" isMandatory />
          <Field
            name={nameOfDDAuthorisation("Authorised_Date")}
            component={CCDatePicker}
            format={DATE_FORMAT.DATE_CONTROL}
            validator={requiredValidator}
          />
        </div>
      </div>
    </section>
  );
});
