import { AddAssessmentType } from "@app/products/property/schemes/[id]/components/dialogs/new-scheme-account/model";

export const mockNewSchemeDialog = {
  AddAssessmentType: AddAssessmentType.AssessmentNumber,
  AsAnAccountOfScheme: {
    No: "4",
    Text: "Drainage",
  },
  AccountNumber: "32052.000100",
  IncrementBy: "000100",
};

export const mockACollectionOfAssessment = [
  {
    Text: "Como Si A Berrys Road special Charge Scheme",
    No: "1",
  },
  {
    Text: "Cornish Road special Charges Scheme",
    No: "2",
  },
  {
    Text: "George and Mary St Bunyip special Charge Scheme",
    No: "3",
  },
  {
    Text: "Guest Rd Special Charge Scheme",
    No: "4",
  },
  {
    Text: "Island Road special Charge Scheme",
    No: "5",
  },
  {
    Text: "Jefferson Rond special Charge Scheme",
    No: "6",
  },
  {
    Text: "McỡrideRd Special Charges Scheme",
    No: "7",
  },
  {
    Text: "Mills Street Special Charge Scheme",
    No: "8",
  },
  {
    Text: "Misccllanoous/Convcrsion schemes",
    No: "9",
  },
  {
    Text: "Moody St KWR Special Charge Scheme",
    No: "10",
  },
  {
    Text: "Old Beaconsfield Rd Special Charge Scheme",
    No: "11",
  },
  {
    Text: "Old Menries Creek Rd special Charge Scheme",
    No: "12",
  },
  {
    Text: "Paton Road Special Charge Scheme",
    No: "13",
  },
  {
    Text: "Peel Street Industrial Area special Charge Scheme",
    No: "14",
  },
  {
    Text: "Ryan Road Special Charge Scheme",
    No: "15",
  },
  {
    Text: "Wadsley Avenue special Charge Scheme",
    No: "16",
  },
  {
    Text: "Wattletreo Rd Special Charge Scheme",
    No: "17",
  },
];
export const mockAssessment = [
  {
    Assessment: "678301 ‑ 14 Princess Maria Pl Hunters Hill",
    Id: 1,
    FormattedAddress: "14 Princess Maria Pl Hunters Hill NSW 2110",
  },
  {
    Assessment: "678300 ‑ 51 Wallace St Hunters Hill",
    Id: 2,
    FormattedAddress: " 51 Wallace St Hunters Hill NSW 2110",
  },
  {
    Assessment: "678302 ‑ 10 Wynnewood Ct Hunters Hill",
    Id: 3,
    FormattedAddress: "10 Wynnewood Ct Hunters Hill NSW 2110",
  },
  {
    Assessment: "678303 ‑ 16 McCartin St Hunters Hill",
    Id: 4,
    FormattedAddress: "16 McCartin St Hunters Hill NSW 2110",
  },
  {
    Assessment: "678304 ‑ 5 Nugent St Hunters Hill",
    Id: 5,
    FormattedAddress: "5 Nugent St Hunters Hill NSW 2110",
  },
];
export const mockActiveAssessmentsInStreet = [
  {
    No: "1",
    Text: "Abbey Lane, South Dudley",
  },
  {
    No: "2",
    Text: "Abbeygate St South Dudley",
  },
  {
    No: "3",
    Text: "Abbott St South Dudley",
  },
  {
    No: "4",
    Text: "A'Beckett Rd, Archies Creek",
  },
  {
    No: "5",
    Text: "A'Beckett Rd, Bass",
  },
  {
    No: "6",
    Text: "Aberdeen Hts, South Dudley",
  },
  {
    No: "7",
    Text: "Aberdeen PI, South Dudley",
  },
  {
    No: "8",
    Text: "Abrehart Rd, South Dudley",
  },
  {
    No: "9",
    Text: "Acacia Cres, Grantville",
  },
  {
    No: "10",
    Text: "Acacia Ct South Dudley",
  },
  {
    No: "11",
    Text: "Acacia Way, South Dudley",
  },
  {
    No: "12",
    Text: "Accord Ave, Silver!eaves",
  },
  {
    No: "13",
    Text: "Aclare Rd, Cowes",
  },
  {
    No: "14",
    Text: "Acorn Lane, South Dudley",
  },
  {
    No: "15",
    Text: "Acre Ct South Dudley",
  },
  {
    No: "16",
    Text: "Adam Ct South Dudley",
  },
];

export const mockAsAnAccountOfScheme = [
  {
    No: "1",
    Text: "Cardinia Drainage",
  },
  {
    No: "2",
    Text: "Cornish Road Special Charge Scheme",
  },
  {
    No: "3",
    Text: "Devon\\Belgrave Ave Special Charge Scheme",
  },
  {
    No: "4",
    Text: "Drainage",
  },
  {
    No: "5",
    Text: "George and Mary Street Bunyip Special Charge Scheme",
  },
  {
    No: "6",
    Text: "Guest Road Special Charge Scheme",
  },
  {
    No: "7",
    Text: "Henry Rd Special Charge Scheme Sewerage",
  },
  {
    No: "8",
    Text: "Island Road KWR Special Charge Scheme",
  },
  {
    No: "9",
    Text: "Jefferson Road Special Charge Scheme",
  },
  {
    No: "10",
    Text: "Majestic Dr Special Charge Scheme",
  },
  {
    No: "11",
    Text: "McBride Road Special Charge Scheme",
  },
  {
    No: "12",
    Text: "Mill St Special Charge Scheme",
  },
  {
    No: "13",
    Text: "Miscellaneous/Conversion Schemes",
  },
  {
    No: "14",
    Text: "Moody Street Koo Wee Rup Special Charge Scheme",
  },
  {
    No: "15",
    Text: "New Scheme",
  },
  {
    No: "16",
    Text: "Old Beaconsfield Road Special Charge Scheme",
  },
  {
    No: "17",
    Text: "Old Menzies Creek Road Special Charge Scheme",
  },
];
