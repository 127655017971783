import { VO_Supplementary_Valuation } from "@app/products/property/supplementary-rates/[id]/components/child-screens/valuations/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Supplementary_Valuation>();
export const colValuation: IColumnFields[] = [
  {
    field: nameOf("Assessment_Number_Formatted"),
    title: "Assessment Number",
  },
  {
    field: nameOf("Assessment_Reference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Rating_Period_Name"),
    title: "Rating Period",
  },
  {
    field: nameOf("New_Valuation_Effective_Date"),
    title: "New Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("New_Valuation_Issue_Date"),
    title: "New Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("New_Date_of_Valuation"),
    title: "New Date of Valuation",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("New_Valuation_Comment"),
    title: "New Comment",
  },
  {
    field: nameOf("New_Valuation_1"),
    title: "New Valuation 1",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_2"),
    title: "New Valuation 2",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_3"),
    title: "New Valuation 3",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_4"),
    title: "New Valuation 4",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_5"),
    title: "New Valuation 5",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_6"),
    title: "New Valuation 6",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_7"),
    title: "New Valuation 7",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_8"),
    title: "New Valuation 8",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_9"),
    title: "New Valuation 9",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_10"),
    title: "New Valuation 10",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_11"),
    title: "New Valuation 11",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_12"),
    title: "New Valuation 12",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_13"),
    title: "New Valuation 13",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_14"),
    title: "New Valuation 14",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_15"),
    title: "New Valuation 15",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_16"),
    title: "New Valuation 16",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_17"),
    title: "New Valuation 17",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_18"),
    title: "New Valuation 18",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_19"),
    title: "New Valuation 19",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_20"),
    title: "New Valuation 20",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Valuation_Basis"),
    title: "New Valuation Basis",
  },
  {
    field: nameOf("Old_Valuation_Effective_Date"),
    title: "Old Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Old_Valuation_Issue_Date"),
    title: "Old Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Old_Date_of_Valuation"),
    title: "Old Date of Valuation",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Old_Valuation_1"),
    title: "Old Valuation 1",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_2"),
    title: "Old Valuation 2",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_3"),
    title: "Old Valuation 3",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_4"),
    title: "Old Valuation 4",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_5"),
    title: "Old Valuation 5",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_6"),
    title: "Old Valuation 6",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_7"),
    title: "Old Valuation 7",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_8"),
    title: "Old Valuation 8",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_9"),
    title: "Old Valuation 9",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_10"),
    title: "Old Valuation 10",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_11"),
    title: "Old Valuation 11",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_12"),
    title: "Old Valuation 12",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_13"),
    title: "Old Valuation 13",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_14"),
    title: "Old Valuation 14",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_15"),
    title: "Old Valuation 15",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_16"),
    title: "Old Valuation 16",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_17"),
    title: "Old Valuation 17",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_18"),
    title: "Old Valuation 18",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_19"),
    title: "Old Valuation 19",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_20"),
    title: "Old Valuation 20",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Old_Valuation_Basis"),
    title: "Old Valuation Basis",
  },
  {
    field: nameOf("Has_Valuations_been_changed"),
    title: "Has Valuations Changed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Difference_Valuation_1"),
    title: "Difference Valuation 1",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_2"),
    title: "Difference Valuation 2",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_3"),
    title: "Difference Valuation 3",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_4"),
    title: "Difference Valuation 4",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_5"),
    title: "Difference Valuation 5",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_6"),
    title: "Difference Valuation 6",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_7"),
    title: "Difference Valuation 7",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_8"),
    title: "Difference Valuation 8",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_9"),
    title: "Difference Valuation 9",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_10"),
    title: "Difference Valuation 10",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_11"),
    title: "Difference Valuation 11",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_12"),
    title: "Difference Valuation 12",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_13"),
    title: "Difference Valuation 13",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_14"),
    title: "Difference Valuation 14",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_15"),
    title: "Difference Valuation 15",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_16"),
    title: "Difference Valuation 16",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_17"),
    title: "Difference Valuation 17",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_18"),
    title: "Difference Valuation 18",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_19"),
    title: "Difference Valuation 19",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Difference_Valuation_20"),
    title: "Difference Valuation 20",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("New_Primary_Assess_Land_Use_Name"),
    title: "Assessment Primary Land Use",
  },
  {
    field: nameOf("New_Secondary_Assess_Land_Use_Name"),
    title: "Assessment Secondary Land Use",
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
