import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { SHAREPOINTSTORAGEMETHOD } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const RecordsSPEnquiryForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const { valueGetter } = formRenderProps;
    const showFormat =
      parseInt(
        valueGetter(
          ECorporateSettingsField.SharePoint_PlanningEnquiry_StorageMethod.toString()
        )
      ) !== SHAREPOINTSTORAGEMETHOD.PROPERTYNUMBER;
    const showSiteCollectionURL = valueGetter(
      ECorporateSettingsField.SharePoint_PlanningEnquiry_UseSiteCollectionUrl.toString()
    );
    if (!configData) return <></>;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <CCSearchComboBoxGlobalSettings
            data={
              configData[
                ECorporateSettingsField.SharePoint_PlanningEnquiry_StorageMethod
              ]
            }
            isEditing={isEditing}
          />
          <SwitchGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .SharePoint_PlanningEnquiry_UseSiteCollectionUrl
              ]
            }
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />

          {showSiteCollectionURL && (
            <InputGlobalSettings
              data={
                configData[
                  ECorporateSettingsField
                    .SharePoint_PlanningEnquiry_SiteCollectionUrl
                ]
              }
              isEditing={isEditing}
            />
          )}
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .SharePoint_PlanningEnquiry_DocumentLibrary
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField.SharePoint_PlanningEnquiry_DocumentSet
              ]
            }
            isEditing={isEditing}
          />
          {showFormat && (
            <>
              <InputGlobalSettings
                data={
                  configData[
                    ECorporateSettingsField
                      .SharePoint_PlanningEnquiry_ParentContainerFormat
                  ]
                }
                isEditing={isEditing}
              />
              <InputGlobalSettings
                data={
                  configData[
                    ECorporateSettingsField
                      .SharePoint_PlanningEnquiry_TitleFormat
                  ]
                }
                isEditing={isEditing}
              />
            </>
          )}
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .SharePoint_PlanningEnquiry_DataSetCustomField
              ]
            }
            isEditing={isEditing}
          />
        </div>
      </section>
    );
  }
);
