import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const dataSerilogSinkType: IKeyValuePacket[] = [
  {
    Key: 0,
    Value: "Database",
  },
  {
    Key: 1,
    Value: "Seq",
  },
];
