import { ICCRoute } from "@common/constants/ICCRoute";
import { integratedReportsRoute } from "@common/pages/report/integrated-reports/route";
import { powerBIRoute } from "@common/pages/report/powerbi/route";

export const ACTIONS_ROUTE = {};

export const reportsRoute: ICCRoute = {
  path: "reports",
  name: "reports",
  children: [integratedReportsRoute, powerBIRoute],
};
