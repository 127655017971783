import { ContactFromNarDialog } from "@common/pages/contacts/contacts-from-nar-dialog/_index";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";
import ContactRelationshipFormElement from "./components/contact-relationship-form-element/_index";

interface IProps {
  isNew: boolean;
  setIsLoading: (status: boolean) => void;
  formData?: any;
}

export const ContactRelationshipForm = ({
  isNew,
  formData: initialValues,
}: IProps) => {
  const history = useHistory();
  const { state } = useLocation<any>();

  useEffectOnce(() => {
    setShowContactFromNar(true);
  });

  const [showContactFromNar, setShowContactFromNar] = useState(
    state?.flag_ContactFromNar
  );

  const handleSubmit = async (data: any) => {
    const previousPage = state?.previousPage;
    if (previousPage) {
      return history.replace(previousPage.url, {
        initialValues: previousPage.state,
      });
    }
    //history.goBack();
  };

  return (
    <>
      {showContactFromNar && (
        <ContactFromNarDialog
          onCloseDialog={() => setShowContactFromNar(false)}
          onSubmitDialog={() => setShowContactFromNar(false)}
        />
      )}
      <div className="cc-form">
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => (
            <ContactRelationshipFormElement
              isNew={isNew}
              formRenderProps={formRenderProps}
            />
          )}
        />
      </div>
    </>
  );
};
