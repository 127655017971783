import { VO_Street_Locality } from "@app/products/property/assessments/components/form-steps/new-assessment/components/street-search/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IODataResponse } from "@common/models/odataResponse";

export const getInitStreetLocality = async (
  defaultStreetId?: number
): Promise<APIResponse<IODataResponse<VO_Street_Locality> | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/odata/property/internal/streetlocalityregister?$count=true&$top=5000&$skip=0${
        defaultStreetId
          ? `&$filter=Street_Locality_Id eq ${defaultStreetId}`
          : ""
      }`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
