import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const listRoute: ICCRoute = {
  path: "list",
  name: "List",
  enum: eMenuAction.Deferments_List,
  children: [
    {
      path: "all",
      name: "All",
      enum: eMenuAction.Deferments_List_All,
      component: require("./all/_index").default,
    },
    {
      path: "active",
      name: "Active",
      enum: eMenuAction.Deferments_List_Active,
      component: require("./active/_index").default,
    },
  ],
};
