import { postDebtRecoveryRestrictionsById } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/button/modify-functionality-restrictions/api";
import { DebtRecoveryFunctionalityRestrictionsDialog } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/dialog/modify-functionality-restrictions/_index";
import { V_Debt_Recovery_Functionality } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/dialog/modify-functionality-restrictions/model";
import { useDebtRecoveryStore } from "@app/products/property/assessments/debt-recovery/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IDebtRecoveryFunctionalityRestrictionsButtonProps {
  debtRecoveryId: number;
}

export const DebtRecoveryFunctionalityRestrictionsButton = observer(
  ({ debtRecoveryId }: IDebtRecoveryFunctionalityRestrictionsButtonProps) => {
    const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { clearErrorNotification, pushNotification } =
      useCCAppNotificationStore();
    const { reLoadDebtRecovery } = useDebtRecoveryStore();

    const handleEditFunctionalityRestrictions = async (
      dataItem: V_Debt_Recovery_Functionality[]
    ) => {
      setIsLoading(true);
      const response = await postDebtRecoveryRestrictionsById(
        debtRecoveryId,
        dataItem
      );
      setIsLoading(false);
      setIsShowDialog(false);
      clearErrorNotification();
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        reLoadDebtRecovery();
        pushNotification({
          type: "success",
          title:
            response?.data?.SuccessMessage ??
            "Function restrictions saved successfully",
        });
      } else {
        pushNotification({
          type: "error",
          title: response?.data?.ErrorMessage ?? "Function restrictions failed",
          autoClose: false,
        });
      }
    };

    return (
      <>
        <CCNavButton
          title={"Modify functionality restrictions"}
          onClick={() => setIsShowDialog(true)}
        />
        {isShowDialog && (
          <DebtRecoveryFunctionalityRestrictionsDialog
            title="Debt Recovery Functionality"
            isLoading={isLoading}
            debtRecoveryId={debtRecoveryId}
            onClose={() => {
              setIsShowDialog(false);
            }}
            onSubmit={handleEditFunctionalityRestrictions}
          />
        )}
      </>
    );
  }
);
