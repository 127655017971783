import { deleteChargeRun } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/buttons/delete/api";
import { DeleteChargeRunCommonButton } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/buttons/delete/components/_index";
import { PROPERTY_CHARGE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/constant";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

interface IDeleteChargeRunProps {
  disabled?: boolean;
}

export const DeleteChargeRunButton = observer(
  ({ disabled }: IDeleteChargeRunProps) => {
    const history = useHistory();
    const { pushNotification } = useCCAppNotificationStore();
    const { id } = useParams<{ id: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDeleteChargeRun = async () => {
      if (!isNil(id)) {
        setIsLoading(true);
        const response = await deleteChargeRun(id);
        setIsLoading(false);
        if (response?.data?.IsSuccess) {
          const notificationContent: IAppNotificationItemAddProps = {
            type: "success",
            title:
              response?.data?.SuccessMessage ??
              "Charge run deleted successfully.",
          };
          history.push(PROPERTY_CHARGE_RUNS_ROUTE, {
            notification: notificationContent,
          });
        } else {
          pushNotification({
            type: "error",
            title:
              response?.data?.ErrorMessage ??
              "Charge run could not be deleted.",
            autoClose: false,
          });
        }
      }
    };

    return (
      <DeleteChargeRunCommonButton
        handleDelete={handleDeleteChargeRun}
        isLoading={isLoading}
        disabled={disabled}
      />
    );
  }
);
