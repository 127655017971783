import { ActionsAccordion } from "@app/core/actions/action-accordion/_index";
import { Addresses } from "@app/core/addresses/_index";
import { AddAdditionalAddressButton } from "@app/core/addresses/components/buttons/add-additional-address/_index";
import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { AdditionalContactButton } from "@app/core/contacts/components/buttons/additional-contact/_index";
import { ContactAccordion } from "@app/core/contacts/components/contact-accordion/_index";
import { Documents } from "@app/core/documents/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { DocumentClassificationNumber } from "@app/core/documents/model";
import { FurtherInformationRequest } from "@app/core/further-info/[id]/model";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { AddRelatedButton } from "@app/core/related/related-button/_index";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { ViewNearMapButton } from "@app/core/view-on-map/components/buttons/view-near-map/_index";
import { ViewVICPlanButton } from "@app/core/view-on-map/components/buttons/view-vic-plan/_index";
import { EPPRProductType } from "@app/core/view-on-map/components/buttons/view-vic-plan/config";
import { ViewDocumentsButton } from "@app/core/view/components/buttons/view-documents/_index";
import { PPRRelated } from "@app/products/town-planning/ppr/[id]/components/child-screens/related/_index";
import { IRelatedParams } from "@app/products/town-planning/ppr/[id]/components/child-screens/related/model";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import { AddFIRequestButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/fi-request/_index";
import { AddMeetingButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/meeting/_index";
import { AddReferralButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/referral/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { PSARChangeApplicationNumberButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/tools/change-application-number/_index";
import { PSARChangeDecisionButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/tools/change-decision/_index";
import { PSARChangedLodgedDateButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/tools/change-lodged-date/_index";
import { PSARChangeStatusButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/tools/change-status/_index";
import { PSARExtendTargetDateButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/tools/extend-target-date/_index";
import { AllocateToOfficerButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/allocate-to-officer/_index";
import { ApplicationCompleteButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/application-complete/_index";
import { ApprovePanelReportButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/approve-panel-report/_index";
import { PSARApproveReportAndResponseButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/approve-report-and-response/_index";
import { AssessmentReportButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/assessment-report/_index";
import { CreateHearingSubmissionButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/create-hearing-submission-report/_index";
import { CreateResponseLetterButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/create-response-letter/_index";
import { FiNotRequiredButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/fi-not-required/_index";
import { LodgeApplicationButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/lodge-application/_index";
import { NotApprovePanelReportButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/not-approve-panel-report/_index";
import { PanelHearingDecisionButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/panel-hearing-decision/_index";
import { ProponentDecisionButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/proponent-decision/_index";
import { ReferralButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/referral/_index";
import { PSARReportAndResponseNotApprovedButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/report-and-response-not-approved/_index";
import { RequestToBeHeardButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/request-to-be-heard/_index";
import { PSARSendHearingSubmissionReportButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/send-hearing-submission-report/_index";
import { SendReportToPanelHearingButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/send-report-to-panel-hearing/_index";
import { SendReptAndRespLetterForApprovalButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/send-rept-and-resp-letter-for-approval/_index";
import { SendResponseToRAAndApplicantButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/send-response-to-ra-and-applicant/_index";
import { WithDrawApplicationButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/withdraw-application/_index";
import { PSARInfoForm } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/_index";
import { PSAContactsTab } from "@app/products/town-planning/ppr/psa-referrals/_id/components/reference-sidebar/contact/_index";
import { PSARDetailTab } from "@app/products/town-planning/ppr/psa-referrals/_id/components/reference-sidebar/detail/_index";
import { PSARHistoryTab } from "@app/products/town-planning/ppr/psa-referrals/_id/components/reference-sidebar/history/_index";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { Action } from "@common/pages/actions/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import {
  CCNavButton,
  ICCNavButtonProps,
} from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedPSARInfo = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const {
    ppr,
    loadPPR,
    isLoading,
    responseLoadError,
    onSubmit,
    pprId,
    hasOSReferral,
    isInactive,
  } = usePSARStore();
  const { id } = useParams<{ id: string }>();

  const { settings } = useCommonCoreStore();
  const defaultFIDays = getNumberValueSetting(
    settings[ECorporateSettingsField.TP_StatDaysFIDays]
  );

  const relatedAccordionParams = useMemo(() => {
    if (pprId) {
      const data: IRelatedParams = {
        RecordType: RECORDTYPE.TP_PSA_Application,
        RecordID: pprId,
        InvoiceNumber: null,
        AssessmentNumberKey: null,
        AddressLine1: null,
        Suburb: null,
        PersonKey: null,
        WasteWaterSchemeKey: null,
        AncestorRecordType: null,
        AncestorRecordId: null,
      };

      if (ppr?.SiteAddress) {
        data.AssessmentNumberKey = ppr.SiteAddress.AssessmentNo;
        data.AddressLine1 = ppr.SiteAddress.Formatted_AddressLine1;
        data.Suburb = ppr.SiteAddress.Suburb;
      }

      if (ppr?.Applicant && ppr.Applicant.Contact) {
        data.PersonKey = ppr.Applicant.Contact.Contact_ID;
      } else if (ppr?.Applicant_SiteUser_ID && ppr.Applicant_SiteUser) {
        data.PersonKey = ppr.Applicant_SiteUser.Contact_ID;
      }

      return data;
    }
    return undefined;
  }, [ppr, pprId]);

  const formNavigationTitle = useMemo(() => {
    let title = "Application";
    if (ppr?.AmendmentReference) title = "Amendment";
    else if (ppr?.PlansToComplyReference) title = "Plans to Comply";
    return title;
  }, [ppr]);

  const formTitleStatus = useMemo(() => {
    let title = "";
    if (!ppr) return title;
    title = ppr?.Status_Name ?? ppr.Status_ENUM;
    switch (ppr?.Status_ENUM) {
      case Application_Status.ReadyToSendToCouncilForAuthorisation:
        title =
          getStringValueSetting(
            settings[
              ECorporateSettingsField
                .TP_PSA_LabelFor_ReadytoSendtocouncilForAuth
            ]
          ) ?? "";
        break;
      case Application_Status.TeamLeaderDecisionPending:
        title = "To be Approved by Delegated approver";
        break;
      case Application_Status.PermitIssued:
        title = "Submission Complete";
        break;
      case Application_Status.ApplicationComplete:
        title = "Closed";
        break;
      case Application_Status.Referral:
        if (!hasOSReferral) {
          title = "Assessed";
        }
        break;
    }

    if (ppr?.DueStatus) title = `${title} - ${ppr?.DueStatus}`;
    return title;
  }, [ppr, hasOSReferral, settings]);

  const listPanelBar: ITabProps[] = [
    {
      title: "Addresses",
      tabType: TabTableType.Addresses,
      component: (
        <Addresses id={pprId} recordType={RECORDTYPE.TP_PSA_Application} />
      ),
    },
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <ActionsAccordion
          id={pprId}
          recordType={RECORDTYPE.TP_PSA_Application}
        />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments id={pprId} recordType={RECORDTYPE.TP_PSA_Application} />
      ),
    },
    {
      title: "Contacts",
      tabType: TabTableType.Contacts,
      component: (
        <ContactAccordion
          id={pprId}
          recordType={RECORDTYPE.TP_PSA_Application}
        />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents id={pprId} recordType={RECORDTYPE.TP_PSA_Application} />
      ),
    },
    {
      title: "Exhibition Documents",
      tabType: TabTableType.Documents_Exhibition,
      component: (
        <Documents
          id={pprId}
          recordType={RECORDTYPE.TP_PSA_Application}
          urlApi={`/odata/tp/ppr/internal/psardocuments/GetPSARDocuments(key=${pprId},recordType=${RECORDTYPE.TP_PSA_Application},documentClassification=${DocumentClassificationNumber.TP_PSAR_ExhibitionDocs})?$count=true&`}
        />
      ),
    },
    {
      title: "Working Documents",
      tabType: TabTableType.Documents_Working,
      component: (
        <Documents
          id={pprId}
          recordType={RECORDTYPE.TP_PSA_Application}
          urlApi={`/odata/tp/ppr/internal/psardocuments/GetPSARDocuments(key=${pprId},recordType=${RECORDTYPE.TP_PSA_Application},documentClassification=${DocumentClassificationNumber.TP_PSAR_WorkingDocs})?$count=true&`}
        />
      ),
    },
    {
      title: "Hearing Documents",
      tabType: TabTableType.Documents_Hearing,
      component: (
        <Documents
          id={pprId}
          recordType={RECORDTYPE.TP_PSA_Application}
          urlApi={`/odata/tp/ppr/internal/psardocuments/GetPSARDocuments(key=${pprId},recordType=${RECORDTYPE.TP_PSA_Application},documentClassification=${DocumentClassificationNumber.TP_PSAR_HearingDocs})?$count=true&`}
        />
      ),
    },
    {
      title: "Related",
      tabType: TabTableType.Related,
      component: <PPRRelated relatedParam={relatedAccordionParams} />,
    },
  ];

  const PSARNavButton = useCallback(
    (props: ICCNavButtonProps) => {
      const { disabled, ...others } = props;
      return (
        <CCNavButton
          {...others}
          disabled={isInactive ? isInactive : disabled}
        />
      );
    },
    [isInactive]
  );

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={formNavigationTitle} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadPPR(parseInt(id), false, true)}
        />
      ) : (
        <>
          <FormTitle
            title={ppr?.ApplicationNumberDisplay}
            badge={formTitleStatus}
          />
          <CCManagePageActionBar
            leftActions={[
              <PSARNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <LodgeApplicationButton />
                <AllocateToOfficerButton />
                <AssessmentReportButton />
                <CreateResponseLetterButton />
                <PSARApproveReportAndResponseButton />
                <PSARReportAndResponseNotApprovedButton />
                <ReferralButton />
                <FiNotRequiredButton />
                <SendResponseToRAAndApplicantButton />
                <ProponentDecisionButton />
                <ApplicationCompleteButton />
                <RequestToBeHeardButton />
                <CreateHearingSubmissionButton />
                <ApprovePanelReportButton />
                <NotApprovePanelReportButton />
                <SendReportToPanelHearingButton />
                <PanelHearingDecisionButton />
                <PSARSendHearingSubmissionReportButton />
                <SendReptAndRespLetterForApprovalButton />
                <WithDrawApplicationButton />
              </PSARNavButton>,
              <PSARNavButton
                title={"Save"}
                onClick={onSubmit}
                name={PSARSubmitActions.Save}
              />,
              <PSARNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PSA_Application}
                />
                <AdditionalContactButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PSA_Application}
                />
                <AddAdditionalAddressButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PSA_Application}
                />
                <AddAttachmentButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PSA_Application}
                />
                <AddCommentButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PSA_Application}
                />
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.TownPlanning}
                  recordId={pprId}
                  recordType={RECORDTYPE.TP_PSA_Application}
                />
                <AddDocumentButton
                  dataSetFilter={MAILMERGEDATASET.TOWNPLANNING_PSA_Applications}
                  recordType={RECORDTYPE.TP_PSA_Application}
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  id={pprId}
                />
                <AddFIRequestButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PSA_Application}
                  data={
                    {
                      DateRequested: new Date(),
                      Officer_ID: ppr?.Planner_ID,
                      Officer: { DisplayName: ppr?.Planner },
                      NumberOfDaysGiven: defaultFIDays,
                    } as FurtherInformationRequest
                  }
                />
                <AddMeetingButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PSA_Application}
                  data={
                    {
                      ActionOfficer_ID: ppr?.Planner_ID,
                      ActionOfficer: { DisplayName: ppr?.Planner },
                    } as Action
                  }
                />
                <AddReferralButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PSA_Application}
                />
                <AddRelatedButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PSA_Application}
                />
              </PSARNavButton>,
              <PSARNavButton title={"View"} type="sub">
                <ViewDocumentsButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PSA_Application}
                />
              </PSARNavButton>,
              <PSARNavButton title={"Map"} type="sub">
                <ViewNearMapButton address={ppr?.SiteAddress} />
                <ViewVICPlanButton pprType={EPPRProductType.PSAR} />
              </PSARNavButton>,
              <PSARNavButton title={"Tools"} type="sub">
                <PSARChangeApplicationNumberButton />
                <PSARChangeDecisionButton />
                <PSARChangedLodgedDateButton />
                <PSARChangeStatusButton />
                <PSARExtendTargetDateButton />
              </PSARNavButton>,
            ]}
            rightActions={[
              <PSARNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {ppr && (
                  <>
                    <PSARInfoForm />
                    <TabTable
                      id={pprId}
                      recordType={RECORDTYPE.TP_PSA_Application}
                      initialPanels={listPanelBar}
                      options={{
                        applicationNumber: ppr.ApplicationNumber,
                        infringeKey: ppr?.ApplicationNumberDisplay,
                        invoiceNumber: relatedAccordionParams?.InvoiceNumber,
                        propertyAssessmentNoKey:
                          relatedAccordionParams?.AssessmentNumberKey,
                        addressLine1: relatedAccordionParams?.AddressLine1,
                        suburb: relatedAccordionParams?.Suburb,
                        personKey: relatedAccordionParams?.PersonKey,
                        schemeKey: relatedAccordionParams?.WasteWaterSchemeKey,
                        refNoKey: relatedAccordionParams?.CsmRefNoKey
                          ? relatedAccordionParams.CsmRefNoKey.toString()
                          : undefined,
                        ancestorRecordId:
                          relatedAccordionParams?.AncestorRecordId,
                        ancestorRecordType:
                          relatedAccordionParams?.AncestorRecordType,
                      }}
                      disabled={isInactive}
                    />
                  </>
                )}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <PSARDetailTab /> },
                    { title: "Contacts", component: <PSAContactsTab /> },
                    {
                      title: "History",
                      component: <PSARHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
