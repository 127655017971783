import { AssessmentPicker } from "@app/products/property/deferred-duty/components/action-bar/dialogs/create-deferred-duty/components/assessment-picker/_index";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

export const ModifyDeferredDutyDetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  nameOf,
  formRenderProps,
  options = { isReadOnly: false, workflowDraftId: 0 },
  isLoadingStep,
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const [isShowPropertyDetails, setIsShowPropertyDetails] =
    useState<boolean>(false);
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const optionAssessmentValueGetter = getFieldValue(
    "_option.AssessmentReference"
  );

  useEffect(() => {
    if (
      optionAssessmentValueGetter &&
      optionAssessmentValueGetter?.[0] &&
      optionAssessmentValueGetter !== ""
    ) {
      onChange(nameOf("AssessmentId"), {
        value: optionAssessmentValueGetter[0]?.Assessment_Id,
      });
      onChange(nameOf("PropertyAddress"), {
        value: optionAssessmentValueGetter[0]?.Property_Address,
      });
      onChange(nameOf("NameAndAddress"), {
        value: optionAssessmentValueGetter[0]?.Owners_Name_Address,
      });
      setIsShowPropertyDetails(true);
    } else {
      setIsShowPropertyDetails(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionAssessmentValueGetter]);

  useEffectOnce(() => {
    if (!isNil(getFieldValue("AssessmentId"))) {
      setIsShowPropertyDetails(true);
    }
  });

  if (isLoadingStep) {
    return <Loading isLoading={isLoadingStep} />;
  }

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Account number</label>
            <Field
              name={nameOf("AccountNumber")}
              placeholder={"Account number"}
              min={0}
              spinners={false}
              component={CCNumericTextBox}
              format={NUMBER_FORMAT.NUMBER2}
              disabled
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              name={nameOf("Description")}
              component={CCTextArea}
              rows={4}
              placeholder={"Description"}
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>

        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Assessment</label>
            <Field
              placeholder="Assessment"
              name={nameOf("AssessmentReference")}
              dataItemKey={"ID"}
              data={getFieldValue("_option.AssessmentReference")}
              component={AssessmentPicker}
              disabled
              disabledTyping
            />
          </div>
          {isShowPropertyDetails && (
            <div className="cc-field">
              <label className="cc-label">Assessment Id</label>
              <Field
                name={nameOf("AssessmentId")}
                component={CCInput}
                placeholder={"Assessment Id"}
                readOnly
              />
            </div>
          )}
        </div>

        {isShowPropertyDetails && (
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Property address</label>
              <Field
                name={nameOf("PropertyAddress")}
                component={CCTextArea}
                rows={4}
                placeholder={"Property address"}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Name and address</label>
              <Field
                name={nameOf("NameAndAddress")}
                component={CCTextArea}
                rows={4}
                placeholder={"Name and address"}
                readOnly
              />
            </div>
          </div>
        )}
      </section>

      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Orginal duty assessed</label>
            <Field
              name={nameOf("OriginalDutyAssessed")}
              placeholder={"Orginal duty assessed"}
              min={0}
              spinners={false}
              component={CCNumericTextBox}
              format={CURRENCY_FORMAT.CURRENCY1}
              disabled
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Duty deferred</label>
            <Field
              name={nameOf("DutyDeferred")}
              placeholder={"Duty deferred"}
              min={0}
              spinners={false}
              component={CCNumericTextBox}
              format={CURRENCY_FORMAT.CURRENCY1}
              disabled
            />
          </div>
        </div>

        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Execution date</label>
            <Field
              name={nameOf("ExecutionDate")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Deferment date</label>
            <Field
              name={nameOf("DefermentDate")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled
            />
          </div>
        </div>

        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Payment commencement</label>
            <Field
              name={nameOf("PaymentCommencement")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Payment completion</label>
            <Field
              name={nameOf("PaymentCompletion")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
      </section>
    </>
  );
};
