import { getDDSessionSendToReceipting } from "@app/products/direct-debit/sessions/[id]/api";
import { DownloadFile } from "@common/constants/downloadfile";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import React from "react";

interface ISendToReceiptingButtonProps {
  isDisabled?: boolean;
}
export const DDSendToReceiptingButton = ({
  isDisabled = false,
}: ISendToReceiptingButtonProps) => {
  const { pushNotificationPortal } = useNotificationPortalStore();
  const { dataForms } = useFlexibleFormStore();
  const { cancelToken } = useCancelRequest();

  const ddSendToReceiptingButtonSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: ({ initialData }) => {
          const sessionNumber: string = initialData;
          return getDDSessionSendToReceipting(sessionNumber, cancelToken());
        },
        handleSuccess: async (dataSuccess) => {
          const data = dataSuccess?.dataFromApi?.data;
          DownloadFile(data?.FileContent, data?.content_type, data?.FileName);
          await fetchApiByAlias("manageDirectDebitSessionSlider");
          pushNotificationPortal({
            title: "Send to receipting successfully.",
            type: "success",
          });
        },
        handleError: ({ errorFromApi }) => {
          pushNotificationPortal({
            title: "Send to receipting failed.",
            type: "error",
            autoClose: false,
            description: errorFromApi.error,
          });
        },
      },
    ],
  });
  const handleClick = () => {
    ddSendToReceiptingButtonSlider.fetchApi({
      initialData: dataForms?.GeneralForm?.Session_Number,
    });
  };

  return (
    <>
      <Loading
        isLoading={ddSendToReceiptingButtonSlider.isFetching}
        isFullScreen
      />
      <CCNavButton
        title="Send To Receipting"
        disabled={isDisabled}
        onClick={handleClick}
      />
    </>
  );
};
