import { useTownPlanningStore } from "@app/products/town-planning/[id]/store";
import { getRegionByPostcodeAndSuburb } from "@app/products/town-planning/ppr/[id]/api";
import {
  optionInputPickerDoTOffice,
  optionInputPickerOfficer,
  optionInputPickerTeamLeader,
} from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import {
  pprApplicationCategoryDisplay,
  pprProduceContactValue,
} from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/util";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { getContact } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/api";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { getSiteUser } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/api";
import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { PropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/_index";
import { AddressValidationType } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/model";
import {
  Address_BuildAddress,
  Application,
  ApplicationAssessmentPlanner,
  ApplicationUpdateTriggers,
  ContactRelationshipType,
  Overlay,
  PROPERTYSYSTEM,
  PSA_ApplicantType,
  RECORDSSYSTEM,
  TRIMSTORAGEMETHOD,
  Zone,
} from "@app/products/town-planning/ppr/[id]/model";
import { requiredAddressValidation } from "@app/products/town-planning/ppr/enquiries/_id/components/child-screens/general/components/form-element/util";
import { getContactList } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/api";
import { Assessment } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/assessment/_index";
import { Decision } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/decision/_index";
import { ContactManagerDialog } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/dialog/contact-manager/_index";
import { ICouncilContact } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/dialog/contact-manager/model";
import { HearingDetail } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/hearing-detail/_index";
import {
  colCouncilDetailGrid,
  optionInputPickerApplicationType,
} from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/config";
import { PSARCouncilContacts } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { PPRApplicationCategory } from "@app/products/town-planning/ppr/system-admin/application-categories/model";
import {
  Address,
  Contact_Relation,
  SiteUser,
} from "@app/products/waste-water/[id]/model";
import { getSettings } from "@common/apis/setting";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import {
  requiredValidator,
  requiredValidatorCheckEmptyArray,
} from "@common/utils/field-validators";
import {
  formatDisplayValue,
  getFullAddressHTML,
} from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { ICCPanelProps } from "@components/cc-panel/_index";
import { CCPanelsBar } from "@components/cc-panels-bar/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  FieldProps,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isArray, isEmpty, isNull, isNumber } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<Application>();
const nameOfGridData = nameOfFactory<PSARCouncilContacts>();

interface IPSARFormElementProps {
  formRenderProps: FormRenderProps;
}

export const PSARFormElement = observer(
  ({ formRenderProps }: IPSARFormElementProps) => {
    const { loadZoneAndOverlayKeywords } = useTownPlanningStore();
    const { applicantTypes, isInactive } = usePSARStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { settings } = useCommonProductStore();
    const { valueGetter, onChange } = formRenderProps;
    const [isLoadingContactDialog, setIsLoadingContactDialog] =
      useState<boolean>(false);
    const [isCouncilSelectionVisible, setIsCouncilSelectionVisible] =
      useState<boolean>(false);
    const [isPSARResponseLetterVisible, setIsPSARResponseLetterVisible] =
      useState<boolean>(false);
    const [
      isSingleProponentDetailsVisible,
      setIsSingleProponentDetailsVisible,
    ] = useState<boolean>(false);
    const [gridSelectedRows, setGridSelectedRows] = useState<
      PSARCouncilContacts[]
    >([]);
    const [showContactManagerDialog, setShowContactManagerDialog] =
      useState<boolean>(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
      useState<boolean>(false);

    const application_ID = valueGetter("Application_ID");
    const siteAddress: Address = valueGetter("SiteAddress");
    const propertiesAutoPopup = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_PropertyAutoPopupDialog
      ]
    );
    const recordsSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]?.Value
    );
    const propertySystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_PropertySystem]
    );
    const addressValidationType = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_AddressValidation]
    );
    const setFileNoSetting = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_RecordsSetFileNoWithPropertyNo
      ]
    );
    const trimStorageMethod = getNumberValueSetting(
      settings[ECorporateSettingsField.TRIMAdmin_TownPlanningStorageMethod]
    );
    const propertyNoMask =
      settings[ECorporateSettingsField.Records_PropertyNoMask]?.Value;
    const listPanelBar: ICCPanelProps[] = [
      {
        title: "Assessment",
        isInvalid:
          formRenderProps.errors["PPRDetails.SubmissionDueDate"] !== "",
        component: (
          <Assessment
            formRenderProps={formRenderProps}
            isDisable={isInactive}
          />
        ),
        isExpanded: true,
        isKeepMounted: true,
      },

      {
        title: "Hearing Details",
        component: (
          <HearingDetail
            formRenderProps={formRenderProps}
            isDisable={isInactive}
          />
        ),
        isKeepMounted: true,
      },
      {
        title: "Decision",
        component: (
          <Decision formRenderProps={formRenderProps} isDisable={isInactive} />
        ),
        isKeepMounted: true,
      },
    ];

    const PSARField = useCallback(
      (props: FieldProps) => {
        const { disabled, ...others } = props;
        return <Field {...others} disabled={isInactive ? isInactive : false} />;
      },
      [isInactive]
    );
    const handleOnPickOfficerForTeamLeader = (value: IOfficer | null) => {
      if (valueGetter("Planner_ID") && valueGetter("Planner_ID") === value?.ID)
        pushNotification({
          title: "Officer cannot be the delegated approver",
          type: "warning",
        });
      else {
        onChange("PPRDetails.TeamLeader_ID", { value: value?.ID });
        if (!value) {
          onChange("PPRDetails.TeamLeader", {
            value: null,
          });
          onChange("PPRDetails.TeamLeader.DisplayName", {
            value: null,
          });
        } else {
          getSiteUser(value.ID).then((response) => {
            if (isSuccessResponse(response)) {
              if (response.data) {
                onChange("PPRDetails.TeamLeader", {
                  value: response.data,
                });
              }
            } else {
              pushNotification({
                title: "Officer is not found",
                type: "error",
                autoClose: false,
              });
            }
          });
        }
      }
    };

    const handleOnPickOfficerForOfficer = (value: SiteUser | null) => {
      if (
        valueGetter("PPRDetails.TeamLeader_ID") &&
        valueGetter("PPRDetails.TeamLeader_ID") === value?.Contact_ID
      )
        pushNotification({
          title: "Officer cannot be the delegated approver",
          type: "warning",
        });
      else {
        onChange("Planner_ID", { value: value?.Contact_ID });
        onChange("Planner", {
          value: value?.DisplayName ?? null,
        });
      }
    };

    const handleOnChangeAppCategory = (
      data: PPRApplicationCategory[] | null
    ) => {
      onChange("ApplicationCategories", {
        value: data,
      });
      onChange("ApplicationCategories_Display", {
        value: pprApplicationCategoryDisplay(
          valueGetter("ApplicationCategories")
        ),
      });
      onChange("ApplicationCategories_Namelist", {
        value: data?.map(
          (item: PPRApplicationCategory) => item.ApplicationCategory_Name
        ),
      });
      onChange("ApplicationCategoriesIDs", {
        value: data?.map((item: PPRApplicationCategory) => item.ID),
      });
      //TODO: RecalculateTargetDate
      updateSaveTriggers(ApplicationUpdateTriggers.UpdateApplicationCategory);
    };

    const updateSaveTriggers = (triggers: ApplicationUpdateTriggers) => {
      let saveTriggers: ApplicationUpdateTriggers[] =
        valueGetter("SaveTriggers") ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange("SaveTriggers", {
          value: saveTriggers,
        });
      }
    };

    useEffectOnce(() => {
      loadZoneAndOverlayKeywords();
      if (isNumber(valueGetter("ApplicantType_Int"))) {
        setIsSingleProponentDetailsVisible(true);
        setIsCouncilSelectionVisible(false);
        setIsPSARResponseLetterVisible(false);
        if (valueGetter("ApplicantType_Int") === PSA_ApplicantType.LGA) {
          setIsSingleProponentDetailsVisible(false);
          setIsCouncilSelectionVisible(true);
          setIsPSARResponseLetterVisible(true);
        }
      }
    });

    const handleOnChangeAmendmentType = async (
      event: DropDownListChangeEvent
    ) => {
      let requestorType = event.target.value.Key as PSA_ApplicantType | null;

      onChange(nameOf("Applicant_SiteUser_ID"), { value: null });
      onChange(nameOf("Applicant_SiteUser"), { value: null });
      onChange(nameOf("Applicant_SiteUser_Description"), { value: null });

      onChange(nameOf("Applicant"), {
        value: null,
      });
      onChange(nameOf("Applicant_RID"), {
        value: null,
      });

      if (requestorType) {
        if (requestorType === PSA_ApplicantType.Council) {
          const councilDetailsID = await getSettings([
            ECorporateSettingsField.CorporateSettings_CouncilDetails,
          ]);
          if (isNumber(councilDetailsID)) {
            const councilContact = await getContact(councilDetailsID);
            if (councilContact) {
              onChange("Applicant.ContactRelationshipType_ENUM", {
                value: ContactRelationshipType.Applicant,
              });
              onChange("Applicant.Contact", {
                value: councilContact,
              });
              onChange("SaveTriggers", {
                value: ApplicationUpdateTriggers.UpdateApplicant,
              });
            }
          } else {
            pushNotification({
              title:
                "You cannot continue until council details been filled in global settings",
              type: "error",
            });
            return;
          }
        } else if (requestorType === PSA_ApplicantType.LGA) {
          setIsSingleProponentDetailsVisible(false);
          setIsCouncilSelectionVisible(true);
          setIsPSARResponseLetterVisible(true);
        } else {
          setIsSingleProponentDetailsVisible(true);
          setIsCouncilSelectionVisible(false);
          setIsPSARResponseLetterVisible(false);
        }

        onChange("ApplicantType_Int", {
          value: event.target.value.Key,
        });
        onChange(nameOf("ApplicantType_ENUM"), {
          value: event.target.value.Key,
        });
      }
    };

    const handleOnChangeApplicant = (event: ComboBoxChangeEvent) => {
      const value = event.value;
      const currentApplicant = valueGetter("Applicant");
      onChange("Applicant_RID", { value: null });
      onChange("Applicant", {
        value: {
          ContactRelationshipType_ENUM:
            currentApplicant?.ContactRelationshipType_ENUM ??
            ContactRelationshipType.Applicant,
          Contact: value,
        },
      });
    };

    const handleOnChangeCouncilApplicant = async (
      selectedCouncilContacts: ICouncilContact[]
    ) => {
      const crrCouncilApplicant =
        (valueGetter("_option.CouncilApplicant") as PSARCouncilContacts[]) ??
        [];

      const newSelectedCouncilContactIDs: string[] = [];
      selectedCouncilContacts.forEach((contact) => {
        if (
          !crrCouncilApplicant.find((item) => item.ContactID === contact.Id)
        ) {
          newSelectedCouncilContactIDs.push(contact.Id.toString());
        }
      });

      if (newSelectedCouncilContactIDs.length > 0) {
        setIsLoadingContactDialog(true);
        const response = await getContactList(
          newSelectedCouncilContactIDs.toString()
        );
        if (isSuccessResponse(response) && response.data) {
          if (response.data.length > 0) {
            onChange("_option.CouncilApplicant", {
              value: crrCouncilApplicant.concat(response.data),
            });
            onChange("Council_ApplicantIDs", {
              value: valueGetter("_option.CouncilApplicant")?.map(
                (contact: PSARCouncilContacts) => contact.ContactID
              ),
            });
            updateSaveTriggers(ApplicationUpdateTriggers.UpdateApplicant);
            setShowContactManagerDialog(false);
          }
        }
        setIsLoadingContactDialog(false);
      } else {
        setShowContactManagerDialog(false);
      }
    };
    const handleOnRemoveCouncilApplicant = (selectedID: number) => {
      let crrCouncilApplicants =
        (valueGetter("_option.CouncilApplicant") as PSARCouncilContacts[]) ??
        [];
      crrCouncilApplicants = crrCouncilApplicants.filter(
        (contact) => contact.ContactID !== selectedID
      );
      onChange("_option.CouncilApplicant", {
        value: crrCouncilApplicants,
      });
      onChange("Council_ApplicantIDs", {
        value: valueGetter("_option.CouncilApplicant")?.map(
          (contact: PSARCouncilContacts) => contact.ContactID
        ),
      });
      updateSaveTriggers(ApplicationUpdateTriggers.UpdateApplicant);
      setShowConfirmDeleteDialog(false);
    };

    const displaySiteAddress = useMemo(() => {
      if (siteAddress) {
        return getFullAddressHTML(siteAddress);
      } else return "";
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteAddress]);

    const currentApplication: Application = valueGetter("");
    const assessmentPlanner: ApplicationAssessmentPlanner =
      valueGetter("AssessmentPlanner");

    /* ====  SiteUserHandle-Set initial values  ==== */
    const siteAddressInitData = useMemo(() => {
      if (!currentApplication) return undefined;
      let result: any = {
        Address: siteAddress,
        _option: {
          checkComplexAddress: false,
          isAddressValidationLoading: false,
          LocationDescriptor:
            siteAddress?.PropertyAssessment?.AddressLocationDescriptor,
        },
      };
      if (siteAddress) {
        //Set zones
        result.ZoneList = assessmentPlanner?.AssessmentPlanner_Zones?.map(
          (zone: Zone): CoreKeyword => {
            return {
              Keyword_ID: zone.Zone_KWD,
              Name: zone.Zone_Name,
              Description: "",
            };
          }
        );
        result.Zone_Display = assessmentPlanner?.AssessmentPlanner_Zones?.map(
          (zone: Zone): CoreKeyword => {
            return {
              Keyword_ID: zone.Zone_KWD,
              Name: zone.Zone_Name,
              Description: "",
            };
          }
        );

        //Set overlays
        result.OverlayList = assessmentPlanner?.AssessmentPlanner_Overlays?.map(
          (overlay: Overlay): CoreKeyword => {
            return {
              Keyword_ID: overlay.Overlay_KWD,
              Name: overlay.Overlay_Name,
              Description: "",
            };
          }
        );

        result.Overlay_Display =
          assessmentPlanner?.AssessmentPlanner_Overlays?.map(
            (overlay: Overlay): CoreKeyword => {
              return {
                Keyword_ID: overlay.Overlay_KWD,
                Name: overlay.Overlay_Name,
                Description: "",
              };
            }
          );
      }

      return result;
    }, [currentApplication, assessmentPlanner, siteAddress]);
    /* ====  / SiteUserHandle-Set initial values  ==== */

    /* ====  SiteUserHandle-Function is called when clicking "OK" button in Property Details dialog ==== */
    const handleSiteAddressChange = (value: any) => {
      const objBuildAddress: Address_BuildAddress | undefined = value;
      if (
        propertiesAutoPopup &&
        propertySystem !== PROPERTYSYSTEM.None &&
        addressValidationType === AddressValidationType.DELWPMapShare
      ) {
        if (
          objBuildAddress?.Address
          //TODO: will check the logic later
          // && !isEmpty(objBuildAddress?.Address?.Mapshare_Magickey)
        ) {
          onChange("SiteAddress", { value: value.Address });
          updateSaveTriggers(ApplicationUpdateTriggers.UpdateSiteAddress);
          //override global setting from each system if TRIM
          let setFileNo = setFileNoSetting;
          if (
            recordsSystem &&
            recordsSystem === RECORDSSYSTEM.TRIMContext &&
            trimStorageMethod &&
            trimStorageMethod !== TRIMSTORAGEMETHOD.PROPERTYNUMBER
          ) {
            setFileNo = false;
          }
          if (
            recordsSystem &&
            recordsSystem === RECORDSSYSTEM.TRIMContext &&
            trimStorageMethod &&
            trimStorageMethod === TRIMSTORAGEMETHOD.PROPERTYNUMBER
          ) {
            setFileNo = true;
          }

          if (setFileNo) {
            let propertyNumber = siteAddress?.AssessmentNo;
            if (
              !isEmpty(propertyNoMask) &&
              propertyNoMask.includes("[PROPERTYNUMBER]")
            )
              propertyNumber = propertyNoMask.replace(
                "[PROPERTYNUMBER]",
                propertyNumber
              );
            onChange("FileNo", { value: propertyNumber });
          }
          if (isArray(value.ZoneList)) {
            const newZone = value.ZoneList?.map((item: CoreKeyword) => {
              return {
                Application_ID: application_ID,
                Zone_KWD: item.Keyword_ID,
                Zone_Name: item.Name,
              };
            });
            onChange("AssessmentPlanner.AssessmentPlanner_Zones", {
              value: newZone,
            });
            updateSaveTriggers(
              ApplicationUpdateTriggers.UpdateAssessmentPlanner
            );
          }

          if (isArray(value.OverlayList)) {
            const newOverlay = value.OverlayList?.map((item: CoreKeyword) => {
              return {
                Application_ID: application_ID,
                Overlay_KWD: item.Keyword_ID,
                Overlay_Name: item.Name,
              };
            });
            onChange("AssessmentPlanner.AssessmentPlanner_Overlays", {
              value: newOverlay,
            });
            updateSaveTriggers(
              ApplicationUpdateTriggers.UpdateAssessmentPlanner
            );
          }
          if (objBuildAddress?.Address && objBuildAddress?.Address?.Suburb) {
            getRegionByPostcodeAndSuburb(
              objBuildAddress.Address.Suburb,
              objBuildAddress.Address.Postcode
            ).then((response) => {
              if (isSuccessResponse(response)) {
                onChange("PPRDetails.VROffice", { value: response.data });
                if (!isNull(response.data)) {
                  onChange("PPRDetails.VR_OfficeID", {
                    value: response.data?.Region_ID,
                  });
                }
              }
            });
          }
        }
      } else {
        onChange("SiteAddress", { value: value.Address });
        updateSaveTriggers(ApplicationUpdateTriggers.UpdateSiteAddress);

        //override global setting from each system if TRIM
        let setFileNo = setFileNoSetting;
        if (
          recordsSystem &&
          recordsSystem === RECORDSSYSTEM.TRIMContext &&
          trimStorageMethod &&
          trimStorageMethod !== TRIMSTORAGEMETHOD.PROPERTYNUMBER
        ) {
          setFileNo = false;
        }
        if (
          recordsSystem &&
          recordsSystem === RECORDSSYSTEM.TRIMContext &&
          trimStorageMethod &&
          trimStorageMethod === TRIMSTORAGEMETHOD.PROPERTYNUMBER
        ) {
          setFileNo = true;
        }

        if (setFileNo) {
          let propertyNumber = siteAddress?.AssessmentNo;
          if (
            !isEmpty(propertyNoMask) &&
            propertyNoMask.includes("[PROPERTYNUMBER]")
          )
            propertyNumber = propertyNoMask.replace(
              "[PROPERTYNUMBER]",
              propertyNumber
            );
          onChange("FileNo", { value: propertyNumber });
        }
        if (isArray(value.ZoneList)) {
          const newZone = value.ZoneList?.map((item: CoreKeyword) => {
            return {
              Application_ID: application_ID,
              Zone_KWD: item.Keyword_ID,
              Zone_Name: item.Name,
            };
          });
          onChange("AssessmentPlanner.AssessmentPlanner_Zones", {
            value: newZone,
          });
          updateSaveTriggers(ApplicationUpdateTriggers.UpdateAssessmentPlanner);
        }

        if (isArray(value.OverlayList)) {
          const newOverlay = value.OverlayList?.map((item: CoreKeyword) => {
            return {
              Application_ID: application_ID,
              Overlay_KWD: item.Keyword_ID,
              Overlay_Name: item.Name,
            };
          });
          onChange("AssessmentPlanner.AssessmentPlanner_Overlays", {
            value: newOverlay,
          });
          updateSaveTriggers(ApplicationUpdateTriggers.UpdateAssessmentPlanner);
        }
        if (objBuildAddress?.Address && objBuildAddress?.Address?.Suburb) {
          getRegionByPostcodeAndSuburb(
            objBuildAddress.Address.Suburb,
            objBuildAddress.Address.Postcode
          ).then((response) => {
            if (isSuccessResponse(response)) {
              onChange("PPRDetails.VROffice", { value: response.data });
              if (!isNull(response.data)) {
                onChange("PPRDetails.VR_OfficeID", {
                  value: response.data?.Region_ID,
                });
              }
            }
          });
        }
      }
    };
    /* ====  / SiteUserHandle-Function is called when clicking "OK" button in Property Details dialog ==== */

    const postalAddress = valueGetter("Applicant.Contact.PostalAddress");
    const getPostalAddress = useMemo(() => {
      return getFullAddressHTML(postalAddress);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postalAddress]);

    const applicant: Contact_Relation = valueGetter("Applicant");
    const applicantContact = applicant?.Contact;
    const displayApplicantContact = useMemo(() => {
      return pprProduceContactValue(applicantContact);
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicantContact]);

    return (
      <FormElement>
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Amendment type <CCTooltip type="validator" position="right" />
              </label>
              <PSARField
                name={"ApplicantType_Int"}
                textField="Value"
                dataItemKey="Key"
                data={applicantTypes}
                component={CCDropDownList}
                validator={requiredValidator}
                value={getDropdownValue(
                  valueGetter("ApplicantType_Int"),
                  applicantTypes,
                  "Key"
                )}
                onChange={handleOnChangeAmendmentType}
              />
            </div>
          </div>
          {isSingleProponentDetailsVisible && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Proponent
                  <CCTooltip type="validator" position="right" />
                </label>
                <PSARField
                  name={"Applicant.Contact"}
                  component={ContactPicker}
                  validator={requiredValidator}
                  onError={(error: any) => {
                    pushNotification({
                      type: "error",
                      title: "Pick contact errors",
                      description: error,
                      autoClose: false,
                    });
                  }}
                  placeholder={"Applicant"}
                  onChange={handleOnChangeApplicant}
                />
              </div>
              <CCValueField label="Postal address" value={getPostalAddress} />
              <CCValueField label="Contact" value={displayApplicantContact} />
            </div>
          )}
          {isPSARResponseLetterVisible && isCouncilSelectionVisible && (
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Proponent</label>
                <CCGrid
                  toolbar={
                    <div className="cc-grid-tools-bar">
                      <Button
                        type="button"
                        iconClass="fa fa-plus"
                        title="Add Response Letter"
                        onClick={() => {
                          setShowContactManagerDialog(true);
                        }}
                      />
                      <Button
                        type="button"
                        iconClass="fas fa-minus"
                        title="Remove"
                        disabled={!(gridSelectedRows?.length === 1)}
                        onClick={() => setShowConfirmDeleteDialog(true)}
                      />
                    </div>
                  }
                  columnFields={colCouncilDetailGrid}
                  primaryField={nameOfGridData("ContactID")}
                  data={valueGetter("_option.CouncilApplicant") ?? []}
                  selectableMode="single"
                  selectedRows={gridSelectedRows}
                  onSelectionChange={(contacts: PSARCouncilContacts[]) => {
                    if (contacts) {
                      setGridSelectedRows([...contacts]);
                    }
                  }}
                />
              </div>
            </div>
          )}

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Proposal <CCTooltip type="validator" position="right" />
              </label>
              <PSARField
                name={nameOf("ReasonForPermit")}
                placeholder={"Proposal"}
                component={CCTextArea}
                validator={requiredValidator}
                rows={3}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Site address <CCTooltip type="validator" position="right" />
                <div
                  className={`fa fa-home ${
                    siteAddress?.VMAS_Verified ? "text-success" : "text-error"
                  }`}
                />
              </label>
              <PSARField
                name={"SiteAddress"}
                placeholder={"Site address"}
                component={PropertyDetail}
                validator={requiredAddressValidation}
                onSubmit={handleSiteAddressChange}
                value={displaySiteAddress}
                initialData={siteAddressInitData}
                formRenderProps={formRenderProps}
                updateSaveTriggers={() =>
                  updateSaveTriggers(
                    ApplicationUpdateTriggers.UpdateSiteAddress
                  )
                }
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Application type
                <CCTooltip type="validator" position="right" />
              </label>
              <PSARField
                name="ApplicationCategories"
                nameDisplayMerge="ApplicationCategory_Name"
                component={InputPickerSearch}
                placeholder="Application type"
                validator={requiredValidatorCheckEmptyArray}
                onChange={handleOnChangeAppCategory}
                options={optionInputPickerApplicationType}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Amendment reference</label>
              <PSARField
                name={"PPRDetails.PSARAmendmentReference"}
                placeholder={"Amendment reference"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Municipality</label>
              <PSARField
                name={"PPRDetails.Municipality"}
                placeholder={"Municipality"}
                component={CCInput}
              />
            </div>
            <CCValueField
              label="Date lodged"
              value={formatDisplayValue(
                valueGetter("LodgedDate"),
                DATE_FORMAT.DATE
              )}
            />
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                DTP office
                <CCTooltip type="validator" position="right" />
              </label>
              <PSARField
                name="PPRDetails.VROfficeName"
                placeholder="DTP office"
                component={InputPickerSearch}
                validator={requiredValidator}
                onChange={(data: CoreKeyword) => {
                  onChange("PPRDetails.VROfficeName", {
                    value: data?.Name,
                  });
                  onChange("PPRDetails.VR_OfficeID", {
                    value: data?.Keyword_ID,
                  });
                }}
                options={optionInputPickerDoTOffice}
                onError={(error: any) => {
                  pushNotification({
                    title: error,
                    type: "warning",
                  });
                }}
              />
            </div>

            <CCValueField label="File number" value={valueGetter("FileNo")} />
            <div className="cc-field">
              <label className="cc-label">Related file number</label>
              <PSARField
                name={"PPRDetails.RelatedFileNumber"}
                component={CCInput}
                placeholder="Related file number"
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Delegated approver
                <CCTooltip type="validator" position="right" />
              </label>
              <PSARField
                name="PPRDetails.TeamLeader.DisplayName"
                placeholder="Delegated approver"
                component={InputPickerSearch}
                validator={requiredValidator}
                onChange={handleOnPickOfficerForTeamLeader}
                options={optionInputPickerTeamLeader}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Officer</label>
              <PSARField
                name={"Planner"}
                placeholder={"Officer"}
                component={InputPickerSearch}
                onChange={handleOnPickOfficerForOfficer}
                options={optionInputPickerOfficer}
                onError={(error: any) => {
                  pushNotification({
                    title: error,
                    type: "error",
                    autoClose: false,
                  });
                }}
              />
            </div>
          </div>
        </section>
        <br />
        <div className="cc-custom-sub-panel-bar">
          <CCPanelsBar initialPanels={listPanelBar} />
        </div>
        {showContactManagerDialog && (
          <ContactManagerDialog
            onClose={() => {
              setShowContactManagerDialog(false);
            }}
            onSubmit={(data: ICouncilContact[]) =>
              handleOnChangeCouncilApplicant(data)
            }
            isSubmitLoading={isLoadingContactDialog}
            isMultiple
          />
        )}
        {showConfirmDeleteDialog && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={"Are you sure you want to delete this item?"}
            onClosePopup={() => {
              setShowConfirmDeleteDialog(false);
            }}
            onConfirmYes={() => {
              handleOnRemoveCouncilApplicant(gridSelectedRows?.[0]?.ContactID);
            }}
          />
        )}
      </FormElement>
    );
  }
);
