import { IAssignContractors } from "@app/products/property/fire-prevention/historical/assign-contractors/model";

export const mockAssignContractors: IAssignContractors[] = [
  {
    WorkOrderId: 9,
    AssignedContractor: "<To be Assigned>",
    Clearance: "Clear Entire Property",
    ToBeClearedBy: new Date("27-Sep-2018"),
    Locality: "Lenah Valley",
    HouseNumber: 25,
    Street: "Alcides",
    Owners: "M J Zawadzki & Z Zawadzki",
    AssessmentNumber: 5478388,
    CreatedOn: new Date("22-Dec-2018 14:17"),
    CreatedBy: "wisem",
    Period: "2018/2019",
    ParcelId: 112965,
    CaseId: 28,
  },
];
