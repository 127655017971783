import { SETTINGS_SECURITY_ORG_STRUCTURE_ROUTE } from "@common/pages/settings/security/org-structure/_id/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

interface NewOrgStructureButtonProps {
  disabled?: boolean;
}

export const NewOrgStructureButton = ({
  disabled,
}: NewOrgStructureButtonProps) => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New"
      onClick={() => {
        history.push(`${SETTINGS_SECURITY_ORG_STRUCTURE_ROUTE}/new`);
      }}
      disabled={disabled}
    />
  );
};
