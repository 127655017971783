import { WorkflowListDetails } from "@app/products/property/actions/components/detail/_index";
import { PROPERTY_ACTIONS_ROUTE } from "@app/products/property/actions/constant";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { actionsRoute } from "@app/products/property/actions/route";
import { ActionToBeApprovedDetailTab } from "@app/products/property/actions/to-be-approved-list/components/reference-sidebar/detail/_index";
import { colToBeApprovedList } from "@app/products/property/actions/to-be-approved-list/config";
import { PROPERTY_ACTION_TO_BE_APPROVE_LIST_VIEW_URL } from "@app/products/property/actions/to-be-approved-list/constant";
import { getBookmarkListViewDetail } from "@app/products/property/actions/to-be-approved-list/util";
import { getBookmarkListViewDisplayName } from "@app/products/property/actions/util";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { TypesActionsWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_Workflow_Draft>();
export default observer(() => {
  const { setListDialog, resetData } = usePropertyWorkflowStore();

  useEffectOnce(() => {
    return () => resetData();
  });

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={actionsRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_ACTIONS_ROUTE}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        isDisabledRecordListView
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <ActionToBeApprovedDetailTab /> },
    ],
  });

  const newColumnToBeApproved = useMemo(() => {
    return colToBeApprovedList.map((column: IColumnFields) => {
      if (column.field === nameOf("Workflow_Draft_Id")) {
        return {
          ...column,
          handleOnClick: (event: VO_Workflow_Draft) => {
            setListDialog([
              {
                type: event?.Workflow_Type_Enum as WorkflowTypes,
                data: event,
              },
            ]);
          },
        };
      }
      return column;
    });
  }, [setListDialog]);

  return (
    <CCProductListView
      columnFields={newColumnToBeApproved}
      dataUrl={PROPERTY_ACTION_TO_BE_APPROVE_LIST_VIEW_URL}
      primaryField={nameOf("Workflow_Draft_Id")}
      detail={({ dataItem }: GridDetailRowProps) => {
        return React.cloneElement(
          <WorkflowListDetails
            workflowDraftId={dataItem?.Workflow_Draft_Id}
            typeList={TypesActionsWorkflow.ToBeApproved}
          />
        );
      }}
    />
  );
});
