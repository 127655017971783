import { KeyValuePair } from "@app/core/new-mail-merge/dialogs/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { CCDocument } from "@common/pages/settings/lookups/documents/_id/model";

// Load LOVs data
export const getUsage = async (
  isColdChain?: boolean
): Promise<APIResponse<KeyValuePair<number, string>[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<KeyValuePair<number, string>[]>(
      `api/core/internal/documenttemplate/usage`,
      {
        params: {
          coldChain: isColdChain,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getMailMergeDataset = async (): Promise<
  APIResponse<KeyValuePair<number, string>[] | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<KeyValuePair<number, string>[]>(
      `api/core/internal/documenttemplate/mailmergedataset`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getDocumentTemplateById = async (
  documentId: number
): Promise<APIResponse<CCDocument | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<CCDocument>(
      `api/core/internal/documenttemplate/${documentId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postDocumentTemplate = async (
  requestBody: CCDocument
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post<CCDocument>(
      `api/core/internal/documenttemplate`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
