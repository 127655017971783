import { ChangeApplicationNumber } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-application-number/model";
import {
  MAX_LENGTH_APPLICATION_NUMBER,
  MAX_LENGTH_REVISION_REFERENCE,
} from "@app/products/town-planning/ppr/components/action-bar/dialogs/change-application-number/const";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IChangeApplicationNumberDialogProps {
  isSubmitting: boolean;
  notifications?: IAppNotificationItem[];
  initialValues?: ChangeApplicationNumber;
  onClose: () => void;
  onSubmit: (data: ChangeApplicationNumber) => void;
}

const nameOf = nameOfFactory<ChangeApplicationNumber>();

export const ChangeApplicationNumberDialog = observer(
  ({
    isSubmitting = false,
    notifications,
    initialValues,
    onClose,
    onSubmit,
  }: IChangeApplicationNumberDialogProps) => {
    return (
      <Form
        initialValues={initialValues}
        onSubmitClick={(event) =>
          onSubmit(event.values as ChangeApplicationNumber)
        }
        render={({ onSubmit, valid, valueGetter }: FormRenderProps) => {
          return (
            <FormElement>
              <CCDialog
                titleHeader={"Application Number"}
                onClose={onClose}
                maxWidth={"25%"}
                height={"auto"}
                disabled={isSubmitting}
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification defaultNotifications={notifications} />
                    <section className="cc-field-group">
                      <div className="cc-field">
                        <label className="cc-label">
                          Number
                          <CCTooltip
                            type="validator"
                            position="right"
                            content="Please enter the application number (numeric)"
                          />
                        </label>
                        <Field
                          name={nameOf("ApplicationNumber")}
                          component={CCNumericTextBox}
                          validator={requiredValidator}
                          format={NUMBER_FORMAT.NUMBER2}
                          min={1}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Year
                          <CCTooltip
                            type="validator"
                            position="right"
                            content="Please enter the application year (numeric)"
                          />
                        </label>
                        <Field
                          name={nameOf("ApplicationYear")}
                          component={CCNumericTextBox}
                          value={valueGetter(
                            nameOf("ApplicationYear") ??
                              new Date().getFullYear()
                          )}
                          validator={requiredValidator}
                          format={NUMBER_FORMAT.NUMBER2}
                          min={1}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Revision reference</label>
                        <Field
                          name={nameOf("RevisionReference")}
                          component={CCInput}
                          maxLength={MAX_LENGTH_REVISION_REFERENCE}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Amendment reference</label>
                        <Field
                          name={nameOf("AmendmentReference")}
                          component={CCNumericTextBox}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Plans to comply reference
                        </label>
                        <Field
                          name={nameOf("PlansToComplyReference")}
                          component={CCNumericTextBox}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Formatted number
                          <CCTooltip
                            type="validator"
                            position="right"
                            content="Please enter the formatted application number"
                          />
                        </label>
                        <Field
                          name={nameOf("FormattedNumber")}
                          component={CCInput}
                          validator={requiredValidator}
                          maxLength={MAX_LENGTH_APPLICATION_NUMBER}
                        />
                      </div>
                    </section>
                  </div>
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button className="cc-dialog-button" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      disabled={!valid}
                      onClick={onSubmit}
                      iconClass={isSubmitting ? "fas fa-spinner fa-spin" : ""}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
