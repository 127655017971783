import { Svc_Animals_Notice } from "@app/products/animals/notices/model";

export const AnimalNoticesByDateBookmark = {
  getBookmarkListViewDisplayName() {
    return "Animals - Notices";
  },

  getBookmarkListViewDetail() {
    return "Animals - Notices - By Date";
  },

  getBookmarkListViewDetailRecord(selectedRow: Svc_Animals_Notice) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Animal - By Date ${dynamicDisplayName}`;
  },
};
