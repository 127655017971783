import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkListViewDisplayName = () => {
  return "Settings - Security";
};

export const getBookmarkListViewDetail = () => {
  return "Settings - Security - Site Users";
};

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `Settings - Security - Site Users ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([selectedRow.ID, selectedRow.DisplayName]);
};
