import {
  DTO_Assessment_NoticeGroups,
  DTO_Assessment_NoticeGroups_LOVs,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/update-notice-group/model";
import { ResponseMessage } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postUpdateNoticeGroup = async (
  payload: DTO_Assessment_NoticeGroups
): Promise<APIResponse<ResponseMessage>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/int/assessment/SaveAssessmentNoticeGroups`,
      payload
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getLovsUpdateNoticeGroup = async (): Promise<
  APIResponse<DTO_Assessment_NoticeGroups_LOVs>
> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `api/property/int/assessment/noticegroups/lovs`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
