import { DTO_Assessment } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Assessment>();

export const colAssessments: IColumnFields[] = [
  {
    field: nameOf("Assess_Number"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: nameOf("Assess_Property_Address"), title: "Property Address" },
  { field: nameOf("Assess_Legal_Description"), title: "Legal Description" },
  { field: nameOf("Ratepayer_Name"), title: "Ratepayer" },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
