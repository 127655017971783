import { JournalMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/model";
import { JournalStatus } from "@app/products/property/journals/model";
import {
  INewJournal,
  IWhichJournal,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/model";

export const defaultWhichJournal: IWhichJournal = {
  JournalMode: JournalMode.CreateNew,
};

export const defaultNewJournal: INewJournal = {
  Status: JournalStatus.TobeAuthorised,
};
