import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";

interface IDeleteRulesButtonProps {
  gridSelectedRows: any[];
  submitDeleteHandler?: () => void;
}

export const DeleteRulesButton = ({
  gridSelectedRows,
  submitDeleteHandler = () => {},
}: IDeleteRulesButtonProps) => {
  const [isShowDialog, setIsShowDialog] = useState(false);

  const handleOnDelete = async () => {
    submitDeleteHandler();
  };

  return (
    <>
      {isShowDialog && (
        <ConfirmDialog
          onClosePopup={() => setIsShowDialog(false)}
          onConfirmNo={() => setIsShowDialog(false)}
          onConfirmYes={handleOnDelete}
          btnNoVisible={false}
          btnCancelVisible
          subMessage={"Are you sure you want to delete this item(s)?"}
          title={"Confirm Deletion"}
        />
      )}

      <Button
        type="button"
        iconClass="fas fa-minus"
        title="Remove"
        disabled={gridSelectedRows.length === 0}
        onClick={() => {
          setIsShowDialog(true);
        }}
      />
    </>
  );
};
