import { allowedExtensionsImage } from "@app/products/property/system-admin/document-template/[id]/components/child-screens/general/config";
import {
  DocumentTemplate_Image,
  FileStreamEntry,
} from "@app/products/property/system-admin/document-template/[id]/model";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import {
  ExternalDropZone,
  Upload,
  UploadOnAddEvent,
} from "@progress/kendo-react-upload";
import { encode } from "base64-arraybuffer";
import React, { LegacyRef, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export interface IImageCardDocumentProps {
  formRenderProps: FormRenderProps;
  index: number;
  handleRemoveImage: (index: number) => void;
  disabled?: boolean;
  itemData: DocumentTemplate_Image;
}

export const ImageCardDocument = ({
  formRenderProps,
  index,
  handleRemoveImage,
  disabled = false,
  itemData,
}: IImageCardDocumentProps) => {
  const { valueGetter, onChange, errors, visited } = formRenderProps;
  const uploadRef = useRef<LegacyRef<Upload> | undefined>(null);
  const attachmentPhoto = valueGetter(
    `DocumentTemplate_Images[${index}].AttachmentPhoto`
  );
  const [isErrorImage, setIsErrorImage] = useState(false);
  useEffectOnce(() => {
    if (itemData?.FileAttachment) {
      onChange(`DocumentTemplate_Images[${index}].AttachmentPhoto`, {
        value: {
          FileName: "default",
          ContentType: "image/jpeg",
          FileAttachment: itemData?.FileAttachment,
        },
      });
    }
  });

  // function to get the image from Upload and turn it into base 64 displayable image
  // with prop UploadOnAddEvent interface
  const handleUploadOnAdd = async (event: UploadOnAddEvent) => {
    if (event.newState.length > 0 && event.newState[0].getRawFile) {
      if (
        event.newState[0]?.validationErrors?.includes("invalidFileExtension")
      ) {
        return setIsErrorImage(true);
      }
      let fileEntry: FileStreamEntry = {};
      fileEntry.FileName = event.newState[0]?.name;
      fileEntry.ContentType = event.newState[0]?.getRawFile().type;
      fileEntry.FileSize = event.newState[0]?.size;
      let reader = new FileReader();
      reader.readAsArrayBuffer(event.newState[0].getRawFile());
      reader.onload = () => {
        if (reader.result) {
          const attachmentBase64 = encode(reader.result as ArrayBuffer);
          fileEntry.FileAttachment = attachmentBase64;

          onChange(`DocumentTemplate_Images[${index}].AttachmentPhoto`, {
            value: fileEntry,
          });
          onChange(`DocumentTemplate_Images[${index}].FileAttachment`, {
            value: attachmentBase64,
          });
        }
      };
    }
  };
  const CustomNote = (
    <span className={`${isErrorImage && "cc-custom-note"}`}>
      Only
      {isErrorImage ? " jpeg, png, gif, svg, jpg extensions " : " image files "}
      are allowed
    </span>
  );
  return (
    <div className="cc-document-group-container" key={index}>
      <Button
        iconClass="fal fa-times"
        className="cc-item-btn-close"
        title="Remove Image Field"
        type="button"
        disabled={disabled}
        onClick={() => {
          handleRemoveImage(index);
        }}
      />
      <section className="cc-document-group">
        <div className="cc-image-col">
          <div className="cc-image-upload-container">
            {!attachmentPhoto && (
              <div>
                <LocalizationProvider language="en-US">
                  <IntlProvider locale="en">
                    <Field
                      name={`DocumentTemplate_Images[${index}].AttachmentPhoto`}
                      component={CCUploadFile}
                      validator={requiredValidator}
                      autoUpload={false}
                      batch={false}
                      disabled={disabled}
                      ref={uploadRef}
                      multiple={false}
                      withCredentials={false}
                      showActionButtons={false}
                      restrictions={{
                        allowedExtensions: allowedExtensionsImage,
                      }}
                      files={[]}
                      onAdd={handleUploadOnAdd}
                      className="cc-button-upload-file"
                    />
                  </IntlProvider>
                </LocalizationProvider>
              </div>
            )}

            {attachmentPhoto ? (
              <div className="cc-image-upload">
                <div className="cc-image-upload-control">
                  <i
                    className="fa fa-times fa-2x"
                    title="Remove"
                    onClick={() => {
                      !disabled &&
                        onChange(
                          `DocumentTemplate_Images[${index}].AttachmentPhoto`,
                          { value: undefined }
                        );
                    }}
                  />
                </div>
                <img
                  src={
                    !attachmentPhoto?.FileAttachment?.toString()?.includes(
                      "blob:"
                    )
                      ? `data:${attachmentPhoto?.ContentType};base64,` +
                        attachmentPhoto?.FileAttachment
                      : attachmentPhoto?.FileAttachment
                  }
                  className="cc-image-review"
                  alt="Attachment Photos"
                />
              </div>
            ) : (
              <ExternalDropZone
                uploadRef={uploadRef}
                className="cc-drop-here"
                disabled={disabled}
                customNote={CustomNote}
                style={{
                  borderColor:
                    visited &&
                    errors?.[
                      `DocumentTemplate_Images[${index}].AttachmentPhoto`
                    ]
                      ? "#b71c1c"
                      : "",
                }}
              />
            )}
          </div>
        </div>
        <div className="cc-field-container">
          <div className="cc-field cc-field-custom">
            <label className="cc-label">Field name</label>
            <Field
              name={`DocumentTemplate_Images[${index}].FieldName`}
              component={CCInput}
              disabled={disabled}
              validator={requiredValidator}
            />
          </div>
          <div className="cc-field cc-field-custom">
            <label className="cc-label">Width</label>
            <Field
              component={CCNumericTextBox}
              name={`DocumentTemplate_Images[${index}].Width`}
              validator={requiredValidator}
              disabled={disabled}
              min={0}
            />
          </div>
          <div className="cc-field cc-field-custom">
            <label className="cc-label">Height</label>
            <Field
              component={CCNumericTextBox}
              name={`DocumentTemplate_Images[${index}].Height`}
              validator={requiredValidator}
              disabled={disabled}
              min={0}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
