import { isEqual } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { CCNotificationItem } from "./components/cc-notification-item/_index";
import { ICCNotification } from "./components/cc-notification-item/model";

export interface INotificationContainerProps {
  initialNotifications?: ICCNotification[];
  newNotifications?: ICCNotification[];
  onSetNotifications?: (notifications: ICCNotification[]) => void;
}
export const CCNotification = ({
  initialNotifications = undefined,
  newNotifications = undefined,
  onSetNotifications,
}: INotificationContainerProps) => {
  const notificationContainerRef = useRef<HTMLDivElement | null>(null);
  const [notifications, setNotifications] = useState<ICCNotification[]>([]);
  const timeOutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    return () => {
      if (timeOutRef.current) clearTimeout(timeOutRef.current);
    };
  }, []);
  const scrollToTop = () => {
    notificationContainerRef.current?.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!initialNotifications) {
      return;
    }
    if (!isEqual(initialNotifications, notifications)) {
      setNotifications(initialNotifications);
      scrollToTop();
    }
    // eslint-disable-next-line
  }, [initialNotifications]);

  useEffect(() => {
    if (!newNotifications) {
      return;
    }
    setNotifications(newNotifications.concat(notifications));
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNotifications]);

  return (
    <div className="cc-manage-notification" ref={notificationContainerRef}>
      {notifications.length
        ? notifications.map((notification, index) => (
            <CCNotificationItem
              key={index}
              showCloseButton={notification.showCloseButton}
              isAutoClose={notification.isAutoClose}
              primaryContent={notification.primaryContent}
              secondaryContents={notification.secondaryContents}
              type={notification.type}
              onClose={() => {
                timeOutRef.current = setTimeout(() => {
                  const newList = notifications.filter((item, currIndex) => {
                    return currIndex !== index;
                  });
                  setNotifications(newList);
                  if (onSetNotifications) onSetNotifications(newList);
                }, 500);
              }}
            />
          ))
        : null}
    </div>
  );
};
