import Actions from "@app/core/actions/_index";
import { Documents } from "@app/core/documents/_index";
import { IAnimalRecordsState } from "@app/products/animals/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import {
  ContactForm,
  IContactFormProps,
} from "@common/pages/contacts/contact-form/_index";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Notifications from "@components/cc-notification/product-detail-notification/_index";
import { ICCPanelProps } from "@components/cc-panel/_index";
import { CCPanelsBar } from "@components/cc-panels-bar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CommentsSection from "./components/comments-section/_index";
import ConcessionCards from "./components/concession-card/_index";
import { ContactReferenceSidebar } from "./components/contact-reference-sidebar/_index";
import DebtRecovery from "./components/debt-recovery/_index";
import PreviousNames from "./components/previous-names/_index";
import PropertyAssociations from "./components/property-associations/_index";
import Voting from "./components/voting/_index";

export interface IExistManageContactProps {
  isLoading: boolean;
}

const ExistManageContact = ({
  isLoading,
  contactInfo,
  setIsLoading,
}: IExistManageContactProps & IContactFormProps) => {
  const { id } = useParams<{ id: string }>();
  const { currentUserInfo } = useGlobalStore();
  const managePageUrl = window.location.pathname;

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: contactInfo?.DisplayName ?? "No Name",
      LinkUrl: managePageUrl,
      LinkText: `Contact - ${id ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: id ? +id : 0,
    },
  ];

  const [showSlideBar, setShowSlideBar] = useState(true);
  const [cotactRecords] = useState<IAnimalRecordsState | undefined>(undefined);

  const listPanelBar: ICCPanelProps[] = [
    {
      title: "Actions",
      badge: cotactRecords?.actions?.toString(),
      component: <Actions id={id} recordType={RECORDTYPE.CORE_Document} />,
    },
    {
      title: "Comments",
      badge: cotactRecords?.comments?.toString(),
      component: (
        <CommentsSection id={id} recordType={RECORDTYPE.CORE_Document} />
      ),
    },
    {
      title: "Contacts Roles",
      badge: cotactRecords?.contacts?.toString(),
      component: <Contacts id={id} recordType={RECORDTYPE.CORE_Document} />,
    },
    {
      title: "Documents",
      badge: cotactRecords?.documents?.toString(),
      component: (
        <Documents id={parseInt(id)} recordType={RECORDTYPE.CORE_Document} />
      ),
    },
    {
      title: "Concession Cards",
      component: <ConcessionCards />,
    },
    {
      title: "Previous Names",
      component: <PreviousNames />,
    },
    {
      title: "Property Associations",
      component: <PropertyAssociations />,
    },
    {
      title: "Voting",
      component: <Voting />,
    },
    {
      title: "Debt Recovery",
      component: <DebtRecovery />,
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Contact"} />
      <FormTitle
        title={contactInfo?.DisplayName ? contactInfo?.DisplayName : "No Name"}
      />

      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          >
            <CCNavButton title={"Issue registration"} />
          </CCNavButton>,
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

          <CCNavButton title={"Add"} type="sub">
            <AddActionButton />
            <CCNavButton title={"Contact"} />
            <CCNavButton title={"Attachment"} />
            <CCNavButton title={"Comment"} />
            <CCNavButton title={"Communication"} />
            <CCNavButton title={"Complaint"} />
            <CCNavButton title={"Document"} />
            <CCNavButton title={"Fee"} />
            <CCNavButton title={"Document"} />
            <CCNavButton title={"Fee"} />
            <CCNavButton title={"Inspection"} />
            <CCNavButton title={"Notice"} />
            <CCNavButton title={"Prosecution"} />
            <CCNavButton title={"Related"} />
          </CCNavButton>,

          <CCNavButton title={"View"} />,
          <CCNavButton title={"Print"} />,
        ]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={showSlideBar}
            onClick={() => {
              setShowSlideBar(!showSlideBar);
            }}
          />,

          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <Notifications />
          <div className="cc-manage-form-body">
            {contactInfo && (
              <CCGeneralPanel
                component={
                  <ContactForm
                    setIsLoading={setIsLoading}
                    contactInfo={contactInfo}
                  />
                }
              />
            )}
            <CCPanelsBar initialPanels={listPanelBar} />
          </div>
        </div>
        {showSlideBar && (
          <div className="cc-manage-right">
            <ContactReferenceSidebar
              data={contactInfo}
              contactReference={{
                Id: id,
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default observer(ExistManageContact);
