import { DIRECT_DEBIT_SESSIONS } from "@app/products/direct-debit/constant";
import { DirectDebitSessionLine } from "@app/products/direct-debit/sessions/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DirectDebitSessionLine>();
export const colDirectDebitSessionLine: IColumnFields[] = [
  {
    field: nameOf("Session_Number"),
    title: "Session Number",
    linkTo: (dataItem: DirectDebitSessionLine) =>
      `${DIRECT_DEBIT_SESSIONS}/${dataItem?.Session_ID}`,
    locked: true,
  },
  {
    field: nameOf("Transfer_Date"),
    title: "Transfer Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Integration_Module_Type_Name"),
    title: "Module Type",
  },
  {
    field: nameOf("Account_Reference"),
    title: "Account Reference",
  },
  {
    field: nameOf("Item_Type"),
    title: "Item Type",
  },
  {
    field: nameOf("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Lodgement_Reference"),
    title: "Lodgement Reference",
  },
  {
    field: nameOf("Is_Rejected"),
    title: "Rejected",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Rejected_Reason"),
    title: "Rejected Reason",
  },
];
