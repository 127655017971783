import { CSL_ROUTE } from "@app/products/csl/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";

export const colCSLInspectionsByDate = [
  {
    field: "LicenceNumber",
    title: "Licence Number",
    locked: true,
    linkTo: (dataItem: any) => `${CSL_ROUTE}/${dataItem.ID}`,
  },
  {
    field: "Applicant",
    title: "Licensee",
  },
  {
    field: "LicenceCategory",
    title: "Licence Category",
  },
  {
    field: "DateInspected",
    title: "Inspected",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "InspectionType",
    title: "Type",
  },
  {
    field: "Officer",
    title: "Officer",
  },
  {
    field: "Compliance",
    title: "Compliance",
  },
  {
    field: "Reason",
    title: "Reason",
  },
  {
    field: "Postcode",
    title: "Postcode",
  },
];
