import {
  transformColumnSearch,
  transformKeySearch,
} from "@app/core/communication/dialogs/components/form-elememts/to/components/add-recipient/util";
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State,
  toODataString,
} from "@progress/kendo-data-query";
import { concat, filter, includes } from "lodash";

export const getUrlSearchInputPicker = (
  searchText: string,
  colSearch: string,
  dataUrl: string
): string => {
  const columnSearch = transformColumnSearch(colSearch);
  const keySearch = transformKeySearch(searchText);
  return `${dataUrl}?$count=true&$filter=contains(${columnSearch},${keySearch})&`;
};

export const getUrlSearchHasStateOption = (
  searchText: string,
  colSearch: string,
  dataUrl: string,
  state: State = {}
): string => {
  let dataFilters = [
    {
      field: `toLower(${colSearch})`,
      operator: "contains",
      value: searchText.toLowerCase(),
    },
  ] as (CompositeFilterDescriptor | FilterDescriptor)[];

  if (state?.filter?.filters) {
    dataFilters = concat(
      dataFilters,
      filter(state.filter.filters, (filter) => !includes(dataFilters, filter))
    );
  }

  const newState = {
    filter: {
      logic: "and",
      filters: dataFilters,
    } as CompositeFilterDescriptor | FilterDescriptor,
  } as State;

  const queryStringOData = `${dataUrl}?$count=true&${toODataString(newState)}&`;

  return queryStringOData;
};
