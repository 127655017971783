import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LookupKeywordForm } from "@common/pages/settings/lookups/keywords/_id/components/child-screens/general/_index";
import { LookupKeywordsTabDetails } from "@common/pages/settings/lookups/keywords/_id/components/reference-sidebar/detail/_index";
import { LookupKeywordsHistoryTab } from "@common/pages/settings/lookups/keywords/_id/components/reference-sidebar/history/_index";
import { LookupKeywordSubmitActions } from "@common/pages/settings/lookups/keywords/_id/model";
import { useKeywordsStore } from "@common/pages/settings/lookups/keywords/_id/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import {
  CCNavButton,
  ICCNavButtonProps,
} from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedLookupKeyword = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const {
    keyword,
    isLoading,
    responseLoadError,
    loadKeyword,
    keywordId,
    isInactive,
    onSubmit,
  } = useKeywordsStore();

  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([keyword?.Keyword_ID, keyword?.Name])}`,
      LinkUrl: managePageUrl,
      LinkText: `Lookups - Keywords - ${keyword?.Keyword_ID ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_Keyword,
      Record_ID: keywordId ?? 0,
    },
  ];

  const KeywordNavButton = useCallback(
    (props: ICCNavButtonProps) => {
      const { disabled, ...others } = props;
      return (
        <CCNavButton
          {...others}
          disabled={isInactive ? isInactive : disabled}
        />
      );
    },
    [isInactive]
  );

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Keyword"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadKeyword(parseInt(id))}
        />
      ) : (
        <div>
          <FormTitle title={keyword?.Name || ""} />
          <CCManagePageActionBar
            leftActions={[
              <KeywordNavButton
                title={"Save"}
                name={LookupKeywordSubmitActions.Save}
                onClick={onSubmit}
              />,
            ]}
            rightActions={[
              <KeywordNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {keyword && <LookupKeywordForm />}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <LookupKeywordsTabDetails />,
                    },
                    {
                      title: "History",
                      component: <LookupKeywordsHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
});
