import { odataGetCoreContactsByClassification } from "@app/core/components/common/utils";
import { ContactClassification } from "@common/constants/enumerations";
import { Svc_ContactDetails } from "@common/pages/profile/components/input-picker/officer-picker/model";
import { nameOfFactory } from "@common/utils/common";
import { IOptionInputPicker } from "@components/cc-input-picker-search-multi-select/model";

export const nameOfOfficer = nameOfFactory<Svc_ContactDetails>();
export const recallOptionOfficer: IOptionInputPicker = {
  dialog: {
    titleHeader: "Pick Officer",
    maxWidth: "60%",
  },
  boxSearch: {
    colSearch: nameOfOfficer("DisplayName"),
    colShowComboboxSearch: [
      { field: nameOfOfficer("DisplayName"), title: "Name" },
    ],
  },
  grid: {
    columnFields: [
      { field: nameOfOfficer("DisplayName"), title: "Name" },
      {
        field: nameOfOfficer("Position"),
        title: "Position",
      },
      { field: nameOfOfficer("Email"), title: "Email" },
      { field: nameOfOfficer("WorkPhone"), title: "Phone" },
    ],
    primaryField: nameOfOfficer("Id"),
    dataUrl: odataGetCoreContactsByClassification(
      ContactClassification.SiteUser
    ),
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
    apiUrlCallBackByID: "api/core/internal/siteuser/lightweight-siteuser/[ID]",
    state: {
      sort: [
        {
          field: nameOfOfficer("DisplayName"),
          dir: "asc",
        },
      ],
    },
  },
};

export const howDataLovs = [
  { Key: "Visit", Value: "Visit" },
  { Key: "Fax", Value: "Fax" },
  { Key: "Email", Value: "Email" },
  { Key: "Phone", Value: "Phone" },
  { Key: "Letter", Value: "Letter" },
];
