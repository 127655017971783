import { eventEmitter } from "@/App";
import { useDebounce } from "@common/hooks/useDebounce";
import { useRouteDataStore } from "@common/stores/route/store";
import { CCGrid, ICCGridProps } from "@components/cc-grid/_index";
import { ExpandedGroupViewsCCGridEventType } from "@components/cc-grid/components/grid-header-collapsed-state-tool/constant";
import { CCInput } from "@components/cc-input/_index";
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import "./_index.scss";

interface IGridSearch extends Omit<ICCGridProps, "primaryField"> {
  grid: {
    primaryField?: string;
    itemPerPage?: number;
    isAutoHiddenPager?: boolean;
  };
  search: {
    hasSearchField?: boolean;
    filterCol?: string;
    sortCol?: string;
  };
  setSelectedItemCount?: (data: any) => void;
  handleSelectionChange: (data: any) => void;
}

export const CRMSGridSearch = observer(
  ({
    grid,
    search = {
      hasSearchField: false,
      filterCol: undefined,
      sortCol: undefined,
    },
    columnFields,
    dataUrl,
    data,
    selectableMode = "single",
    state,
    selectedRows,
    setSelectedItemCount,
    handleSelectionChange,
  }: IGridSearch) => {
    const { isLoadingPage } = useRouteDataStore();
    const filters:
      | (CompositeFilterDescriptor | FilterDescriptor)[]
      | undefined = state?.filter?.filters ?? [];
    const [searchKey, setSearchKey] = useState("");
    const debouncedSearch = useDebounce(searchKey, 500);
    const [stateGrid, setStateGrid] = useState<any>(
      search.sortCol
        ? searchKey.trim() !== ""
          ? {
              ...state,
              sort: [{ field: search.sortCol, dir: "asc" }],
              filter: {
                logic: "and",
                filters: [
                  {
                    field: search.filterCol,
                    operator: "contains",
                    value: searchKey,
                  },
                  ...filters,
                ],
              },
            }
          : {
              ...state,
              sort: [{ field: search.sortCol, dir: "asc" }],
              filter: {
                logic: "or",
                filters: [...filters],
              },
            }
        : { state }
    );

    useEffect(() => {
      if (!isLoadingPage) {
        setTimeout(() => {
          eventEmitter.emit(
            ExpandedGroupViewsCCGridEventType.updateStatus,
            isEmpty(debouncedSearch) ? false : true
          );
        }, 300);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingPage]);

    useEffect(() => {
      setStateGrid({
        ...stateGrid,
        ...(search.sortCol
          ? searchKey.trim() !== ""
            ? {
                ...stateGrid,
                filter: {
                  logic: "and",
                  filters: [
                    {
                      field: search.filterCol,
                      operator: "contains",
                      value: searchKey,
                    },
                    ...filters,
                  ],
                },
              }
            : {
                ...stateGrid,
                filter: {
                  logic: "or",
                  filters: [...filters],
                },
              }
          : stateGrid),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearch]);

    useEffect(() => {
      const newFiltersUpdate = state?.filter?.filters ?? [];
      setStateGrid(
        search.sortCol
          ? searchKey.trim() !== ""
            ? {
                ...state,
                sort: [{ field: search.sortCol, dir: "asc" }],
                filter: {
                  logic: "and",
                  filters: [
                    {
                      field: search.filterCol,
                      operator: "contains",
                      value: searchKey,
                    },
                    ...newFiltersUpdate,
                  ],
                },
              }
            : {
                ...state,
                sort: [{ field: search.sortCol, dir: "asc" }],
                filter: {
                  logic: "and",
                  filters: [...newFiltersUpdate],
                },
              }
          : { state }
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return (
      <div className="cc-grid-search">
        {search.hasSearchField ? (
          <div className="cc-grid-search-input">
            <label className="cc-label">Search</label>
            <Field
              name={"searchKey"}
              component={CCInput}
              placeholder="Search"
              onChange={(e: InputChangeEvent) => {
                setSearchKey(e.value);
              }}
            />
          </div>
        ) : null}
        <span
          id="grid-header-collapsed-state-tool"
          className="cc-expand-collapse-button-group"
        />
        <div className="cc-grid-search-list">
          <CCGrid
            columnFields={columnFields}
            selectableMode={selectableMode}
            data={data}
            dataUrl={dataUrl}
            primaryField={grid.primaryField || "ID"}
            onSelectionChange={handleSelectionChange}
            onTotalSelectedRowChange={setSelectedItemCount}
            onDataStateChange={(state) => setStateGrid(state)}
            state={stateGrid}
            isAutoHiddenPager={grid.isAutoHiddenPager ?? false}
            selectedRows={selectedRows}
            itemPerPage={grid.itemPerPage ?? 10}
          />
        </div>
      </div>
    );
  }
);
