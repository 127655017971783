import { history } from "@/AppRoutes";
import {
  getBuildingDemolitionById,
  postBuildingDemolition,
  putBuildingDemolition,
} from "@app/products/building/registers/demolitions/[id]/api";
import { DEMOLITIONS_MANAGE_ROUTE } from "@app/products/building/registers/demolitions/[id]/constant";
import {
  IBuildingDemolitionFormData,
  IBuildingDemolitionRequest,
} from "@app/products/building/registers/demolitions/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class BuildingRegistersDemolitionsStore {
  private _demolitions?: IBuildingDemolitionFormData = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get demolitions() {
    return this._demolitions;
  }
  setDemolitions = (demolitions: IBuildingDemolitionFormData | undefined) => {
    runInAction(() => {
      this._demolitions = demolitions;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get demolitionId() {
    return this._demolitions?.ID;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._demolitions = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadDemolitions = async (demolitionId: number) => {
    this.setIsLoading(true);
    const response = await getBuildingDemolitionById(demolitionId);
    let errorResponse, newDemolitions;

    if (isSuccessResponse(response)) {
      newDemolitions = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }

    this.setResponseLoadError(errorResponse);
    this.setDemolitions(newDemolitions);
    this.setIsLoading(false);
  };

  createBuildingDemolition = async (building: IBuildingDemolitionRequest) => {
    this.setIsLoading(true);
    await postBuildingDemolition(building);
    appNotificationStore.pushNotification({
      title: "Building demolition created successfully",
      type: "success",
      onClose: () => {
        history.replace(`${DEMOLITIONS_MANAGE_ROUTE}/${building?.ID ?? 1}`);
      },
    });
    this.setIsLoading(false);
  };

  updateBuildingDemolition = async (building: IBuildingDemolitionRequest) => {
    this.setIsLoading(true);
    await putBuildingDemolition(building);
    appNotificationStore.pushNotification({
      title: "Building demolition updated successfully",
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const buildingRegistersDemolitionsStoreContext = createContext(
  new BuildingRegistersDemolitionsStore()
);
export const useBuildingRegistersDemolitionsStore = () => {
  return useContext(buildingRegistersDemolitionsStoreContext);
};
