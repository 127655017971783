import { HMPremiseRoute } from "@app/products/hm/model";
import { getProductTitle } from "@common/constants/product-control";
import { PRODUCT_TYPE } from "@common/constants/productType";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import React from "react";
import { useHistory } from "react-router-dom";

interface IHMFoodSafetyProgramsInsightsProps {
  queryDateTime: any;
}

const HMFoodSafetyProgramsInsight = (
  foodSafetyProgramsInsightData: IHMFoodSafetyProgramsInsightsProps
) => {
  const history = useHistory();

  const pieData = [
    {
      name: "Industrial",
      share: 0.2,
    },
    {
      name: "Commercial",
      share: 0.1,
    },
    {
      name: "Residential",
      share: 0.2,
    },
    {
      name: "High School",
      share: 0.2,
    },
    {
      name: "Others",
      share: 0.3,
    },
  ];
  const FoodSafetyProgramsChart = () => {
    return (
      <React.Fragment>
        <Chart style={{ height: 250 }}>
          <ChartLegend position="right" orientation="vertical" />
          <ChartSeries>
            <ChartSeriesItem
              type="pie"
              overlay={{
                gradient: "glass",
              }}
              tooltip={{ visible: true }}
              data={pieData}
              categoryField="name"
              field="share"
            />
          </ChartSeries>
        </Chart>
      </React.Fragment>
    );
  };

  return (
    <div
      className="cc-insight-group"
      onClick={(e) => {
        history.push(HMPremiseRoute.HM_PREMISES_REGISTER);
      }}
    >
      <div className="cc-group-name">
        {getProductTitle(PRODUCT_TYPE.HealthManager)}
      </div>
      <h4>Food Safety Programs</h4>
      <div className="cc-date-time-description">
        as of {foodSafetyProgramsInsightData.queryDateTime}
      </div>
      <br />
      <div className="row">
        <FoodSafetyProgramsChart />
      </div>
    </div>
  );
};

export default HMFoodSafetyProgramsInsight;
