export const LAND_REST_ODATA = (assessmentId?: number) => {
  if (assessmentId)
    return `odata/property/internal/landrentregister/GetLandRents(assessmentId=${assessmentId})?$count=true&`;
  return `odata/property/internal/landrentregister/GetLandRents()?$count=true&`;
};

export const LAND_RENT_ACTION_DATE_ODATA = (
  assessmentId?: number,
  ratingPeriodId?: number
) => {
  if (assessmentId && !ratingPeriodId)
    return `odata/property/internal/landrentactiondateregister/GetLandRentActionDates(assessmentId=${assessmentId})?$count=true&`;
  if (!assessmentId && ratingPeriodId)
    return `odata/property/internal/landrentactiondateregister/GetLandRentActionDates(ratingPeriodId=${ratingPeriodId})?$count=true&`;
  return `odata/property/internal/landrentactiondateregister/GetLandRentActionDates(assessmentId=${assessmentId}, ratingPeriodId=${ratingPeriodId})?$count=true&`;
};
