import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { getActionDetailTab } from "@common/pages/actions/[id]/components/reference-sidebar/details/api";
import {
  ActionsResponse,
  BubbleupType_ENUM,
  Svc_BubbleupIdentifier,
} from "@common/pages/actions/[id]/components/reference-sidebar/details/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class ActionDetailTabStore {
  private _actionDetailTab?: ActionsResponse = undefined;
  private _actionBubbleUpParent?: Svc_BubbleupIdentifier = undefined;
  private _actionBubbleUpAncestor?: Svc_BubbleupIdentifier = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get actionDetailTab() {
    return toJS(this._actionDetailTab);
  }
  setActionDetailTab = (actionDetailTab: ActionsResponse | undefined) => {
    runInAction(() => {
      this._actionDetailTab = actionDetailTab;
    });
  };

  get actionBubbleUpParent() {
    return toJS(this._actionBubbleUpParent);
  }
  setActionBubbleUpParent = (
    actionBubbleUpParent: Svc_BubbleupIdentifier | undefined
  ) => {
    runInAction(() => {
      this._actionBubbleUpParent = actionBubbleUpParent;
    });
  };

  get actionBubbleUpAncestor() {
    return toJS(this._actionBubbleUpAncestor);
  }
  setActionBubbleUpAncestor = (
    actionBubbleUpAncestor: Svc_BubbleupIdentifier | undefined
  ) => {
    runInAction(() => {
      this._actionBubbleUpAncestor = actionBubbleUpAncestor;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._actionDetailTab = undefined;
      this._isLoading = false;
      this._actionBubbleUpParent = undefined;
      this._actionBubbleUpAncestor = undefined;
      this._responseLoadError = undefined;
    });
  };

  loadActionDetailTab = async (actionId?: string) => {
    this.setIsLoading(true);
    let errorResponse: APIResponseError | undefined = undefined;
    getActionDetailTab(actionId).then((response) => {
      if (Array.isArray(response)) {
        const [detailTabResponse, bubbleupDetailsResponse] = response;
        const detailTabData = detailTabResponse?.data;
        const bubbleupDetailsData = bubbleupDetailsResponse?.data;
        if (
          isSuccessResponse(detailTabResponse) &&
          isSuccessResponse(bubbleupDetailsResponse) &&
          detailTabData
        ) {
          this.setActionDetailTab(detailTabData);

          if (bubbleupDetailsData) {
            //Set parent info
            this.setActionBubbleUpParent(
              getBubbleupDetailsByType(
                bubbleupDetailsData.BubbleList,
                BubbleupType_ENUM.Parent
              )
            );

            //Set ancestor info
            if (!bubbleupDetailsData.AncestorIsSameAsParent) {
              this.setActionBubbleUpAncestor(
                getBubbleupDetailsByType(
                  bubbleupDetailsData.BubbleList,
                  BubbleupType_ENUM.Ancestor
                )
              );
            }
          }
        } else {
          errorResponse = {
            status: APIResponseStatus.INTERNAL_SERVER_ERROR,
            error: "Server error",
          };
        }
        this.setResponseLoadError(errorResponse);
        this.setIsLoading(false);
      }
    });
  };
}
const ActionDetailTabStoreContext = createContext(new ActionDetailTabStore());
export const useActionDetailTabStore = () => {
  return useContext(ActionDetailTabStoreContext);
};

const getBubbleupDetailsByType = (
  bubbleUpList: Svc_BubbleupIdentifier[],
  bubbleupType: BubbleupType_ENUM
) => {
  return bubbleUpList.find(
    (bubbleUpItem: Svc_BubbleupIdentifier) =>
      bubbleUpItem.BubbleupType_ENUM === bubbleupType
  );
};
