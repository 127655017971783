import { history } from "@/AppRoutes";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { APIResponseStatus } from "@common/constants/response-status";
import { SETTINGS_SECURITY_CORPORATE_AUTH_ROUTE } from "@common/pages/settings/security/corporate-authorisations/constant";
import {
  getCorporateAuthById,
  getCorporateAuthLOV,
  postCorporateAuth,
} from "@common/pages/settings/security/corporate-authorisations/_id/api";
import {
  CorporateAuth,
  CorporateAuthSubmitActions,
  ISecurityCorporateAuthNotification,
  ProductTypeWithCorpAuth,
} from "@common/pages/settings/security/corporate-authorisations/_id/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

class CorporateAuthStore {
  private _corporateAuth?: CorporateAuth = undefined;
  private _corporateAuthLovs?: ProductTypeWithCorpAuth[] = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _corporateAuthNotification?: ISecurityCorporateAuthNotification =
    undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get corporateAuth() {
    return toJS(this._corporateAuth);
  }
  setCorporateAuth = (corporateAuth?: CorporateAuth) => {
    runInAction(() => {
      this._corporateAuth = corporateAuth;
    });
  };

  get corporateAuthLovs() {
    return toJS(this._corporateAuthLovs);
  }
  setCorporateAuthLovs = (corporateAuthLovs?: ProductTypeWithCorpAuth[]) => {
    runInAction(() => {
      this._corporateAuthLovs = corporateAuthLovs;
    });
  };

  get corporateAuthNotification() {
    return this._corporateAuthNotification;
  }
  setCorporateAuthNotification = (
    corporateAuthNotification: ISecurityCorporateAuthNotification
  ) => {
    runInAction(() => {
      this._corporateAuthNotification = corporateAuthNotification;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._corporateAuth = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._onSubmit = undefined;
    });
  };

  get corporateAuthId() {
    return toJS(this._corporateAuth?.CorporateAuth_ID);
  }

  reloadCorporateAuth = async (): Promise<boolean> => {
    if (this.corporateAuthId) {
      return await this.loadCorporateAuth(this.corporateAuthId);
    }
    return false;
  };

  loadCorporateAuth = async (
    corporateAuthId: number,
    isNew?: boolean
  ): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    // Load LOVs data
    const lovResponse = await getCorporateAuthLOV();

    if (isSuccessResponse(lovResponse) && lovResponse.data) {
      this.setCorporateAuthLovs(lovResponse.data);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    if (isNew) {
      this.setCorporateAuth({} as CorporateAuth);
    } else {
      let newCorporateAuth = undefined;
      const response = await getCorporateAuthById(corporateAuthId);
      if (isSuccessResponse(response) && response.data) {
        if (response.data.ProductType_ENUM) {
          const productType_Enum = this._corporateAuthLovs?.findIndex(
            (item: ProductTypeWithCorpAuth) => {
              return (
                (item?.ProductType as PRODUCT_TYPE_NUMBER) ===
                response.data?.ProductType_ENUM
              );
            }
          );
          if (productType_Enum === -1) {
            response.data.ProductType_ENUM = null;
          }
        }

        newCorporateAuth = response.data as CorporateAuth;
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      this.setCorporateAuth(newCorporateAuth);
      if (this.corporateAuthNotification?.notification) {
        this.corporateAuthNotification?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setCorporateAuthNotification({
          ...this._corporateAuthNotification,
          notification: [],
        } as ISecurityCorporateAuthNotification);
      }
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  saveCorporateAuth = async (
    corporateAuthInfo: CorporateAuth,
    action: CorporateAuthSubmitActions
  ) => {
    this.setIsLoading(true);
    const response = await postCorporateAuth(corporateAuthInfo);
    this.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      if (this.corporateAuthId)
        await this.loadCorporateAuth(this.corporateAuthId);
      this.runActions(action, response);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Save corporate authorisations failed",
        type: "error",
        description: response.data?.Errors ?? response?.statusText,
      });
    }
  };

  runActions = (action: CorporateAuthSubmitActions, response?: APIResponse) => {
    switch (action) {
      case CorporateAuthSubmitActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Corporate authorisations saved successfully",
          type: "success",
        });
        break;
      case CorporateAuthSubmitActions.New:
        if (isSuccessResponse(response)) {
          history.replace(
            `${SETTINGS_SECURITY_CORPORATE_AUTH_ROUTE}/${response?.data?.ID}`,
            {
              notification: [
                {
                  title: "Corporate authorisations saved successfully",
                  type: "success",
                },
              ],
            }
          );
        } else {
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Save corporate authorisations failed",
            type: "error",
            description: response?.data?.Errors ?? response?.statusText,
          });
        }
        break;
      default:
        break;
    }
  };
}

export const corporateAuthStore = new CorporateAuthStore();
const CorporateAuthStoreContext = createContext(corporateAuthStore);
export const useCorporateAuthStore = () => {
  return useContext(CorporateAuthStoreContext);
};
