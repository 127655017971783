import { TOWN_PLANNING_PPR_ENQUIRIES_ROUTE } from "@app/products/town-planning/ppr/enquiries/_id/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewEnquiryButton = () => {
  const history = useHistory();
  return (
    <CCNavButton
      title="New Enquiry"
      onClick={() => history.push(`${TOWN_PLANNING_PPR_ENQUIRIES_ROUTE}/new`)}
    />
  );
};
