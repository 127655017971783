import { mockScheme } from "@app/products/property/schemes/[id]/components/forms/existed/components/form-step/import-scheme-accounts/components/form-elements/options/mock";

export const defaultOptions = {
  SchemeList: mockScheme,
  SchemeType: "",
  isImportActualAmountOnly: false,
  SchemeUploadFile: [],
};

export const defaultResult = {
  ImportedAccountList: [],
};
