import {
  CreateDefermentRequestObjFromSpatial,
  DTO_Deferment_LOVs,
  DTO_Workflow_CreateDeferment,
} from "@app/products/property/assessments/deferments/components/action-bar/dialogs/create-deferment/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postNewWorkflowCreateDeferment = async (
  requestObjFromSpatial: CreateDefermentRequestObjFromSpatial | undefined
): Promise<APIResponse<DTO_Workflow_CreateDeferment>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/createdeferment/new`,
      requestObjFromSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewCreateDeferment = async (
  mode: WorkflowProcessMode,
  payload: DTO_Workflow_CreateDeferment | undefined
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_CreateDeferment>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/createdeferment/process/${mode}`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCreateDefermentDraft = async (
  workflowDraftId: number | undefined
): Promise<APIResponse<DTO_Workflow_CreateDeferment>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/createdeferment/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataCreateDeferment =
  | [APIResponse<DTO_Deferment_LOVs>, APIResponse<DTO_Workflow_CreateDeferment>]
  | APIResponse<DTO_Deferment_LOVs | DTO_Workflow_CreateDeferment>
  | undefined;
export const getInitialDataCreateDeferment = async (
  workflowDraftId: number | undefined,
  requestObjFromSpatial: CreateDefermentRequestObjFromSpatial | undefined
): Promise<IGetInitialDataCreateDeferment> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Deferment_LOVs>(
        `api/property/internal/workflow/createdeferment/lovs`
      ),
      !isNil(workflowDraftId)
        ? getCreateDefermentDraft(workflowDraftId)
        : postNewWorkflowCreateDeferment(requestObjFromSpatial),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
