import { getComplianceDetailsTabById } from "@app/products/property/assessments/compliance/[id]/components/reference-sidebar/detail/api";
import { DTO_Compliance_TabDetails } from "@app/products/property/assessments/compliance/[id]/components/reference-sidebar/detail/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ComplianceDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [complianceInfo, setComplianceInfo] = useState<
    DTO_Compliance_TabDetails | undefined
  >(undefined);
  const { clearErrorNotification } = useCCAppNotificationStore();
  const { lastSelectedRow } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();

  const handleLoadTabDetail = async () => {
    if (lastSelectedRow?.Compliance_Id || id) {
      const idCompliance = lastSelectedRow?.Compliance_Id ?? id;
      setIsLoading(true);
      let errorResponse = undefined;
      const response = await getComplianceDetailsTabById(
        parseInt(idCompliance + "")
      );
      if (isSuccessResponse(response)) {
        setComplianceInfo(response.data);
        clearErrorNotification();
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
      setResponseLoadError(errorResponse);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleLoadTabDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSelectedRow, id]);
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          handleLoadTabDetail();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!complianceInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Compliance ID:"}
              value={complianceInfo?.ComplianceId + ""}
              primary
            />
          }
        >
          <>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Ratepayer:"}
                value={complianceInfo?.NameAddress}
              />
              <ProductReferenceRow
                title={"Type:"}
                value={complianceInfo?.ComplianceType}
              />
              <ProductReferenceRow
                title={"Status:"}
                value={complianceInfo?.ComplianceStatus}
              />
              <ProductReferenceRow
                title={"Active from:"}
                value={formatDateByKendo(
                  complianceInfo?.ComplianceDateFrom,
                  DATE_FORMAT.DATE
                )}
              />
              <ProductReferenceRow
                title={"Active to:"}
                value={formatDateByKendo(
                  complianceInfo?.ComplianceDateTo,
                  DATE_FORMAT.DATE
                )}
              />
              <ProductReferenceRow
                title={"Assessment reference:"}
                value={complianceInfo?.AssessReference}
              />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
