import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSByTier4Bookmark = {
  getBookmarkListViewDisplayName() {
    return `${CRMS_PRODUCT_NAME} - Unresolved Events`;
  },
  getBookmarkListViewDetail() {
    return `${CRMS_PRODUCT_NAME} - Unresolved Events - By Tier 4`;
  },
};
