import {
  HMNotInspectedView,
  HMPremisesInspectionScheduleView,
} from "@app/products/hm/insp-schedule/model";

export const HMInspScheduleBookmark = {
  getBookmarkDetailDisplayName() {
    return "Health Manager - Inspection Schedule";
  },

  getBookmarkListViewDetail(viewName: string) {
    return `Health Manager - Inspection Schedule - ${viewName}`;
  },

  getBookmarkListViewDetailRecord(
    selectedRow: HMPremisesInspectionScheduleView
  ) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Health Manager - Inspection Schedule ${dynamicDisplayName}`;
  },
};

export const HMInspScheduleNotInpsBookmark = {
  getBookmarkDetailDisplayName() {
    return "Health Manager - Inspection Schedule";
  },

  getBookmarkListViewDetail(viewName: string) {
    return `Health Manager - Inspection Schedule - ${viewName}`;
  },

  getBookmarkListViewDetailRecord(selectedRow: HMNotInspectedView) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Health Manager - Inspection Schedule ${dynamicDisplayName}`;
  },
};
