import React, { useMemo } from "react";

interface IMapSTripTabProps {
  address?: string;
  zoom?: number;
  language?: string;
}
export const MapSTripTab = ({
  address = "M-City Shopping Centre",
  zoom = 14,
  language = "au",
}: IMapSTripTabProps) => {
  const mapURL = useMemo(
    () =>
      `https://maps.google.com/maps?width=100%25&height=100%&hl=${language}&q=${encodeURI(
        address
      )}+(CommunityCentral)&t=&z=${zoom}&ie=UTF8&iwloc=B&output=embed`,
    [address, zoom, language]
  );

  return (
    <div className="cc-ref-map-container">
      <iframe
        title="Google Map Iframe"
        width="100%"
        height="100%"
        src={mapURL}
      />
    </div>
  );
};
