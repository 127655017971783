import { IBuildingRegistersCertificatesFormData } from "@app/products/building/registers/certificates/[id]/model";

export const mockSingleBuildingRegistersCertificates: IBuildingRegistersCertificatesFormData =
  {
    ID: 1,
    RegisterNumber: 8778,
    Contact: "Daniel M Green",
    Contact_Address: "4245  Paul Wayne Haggerty Road, Youngstown, Ohio",
    Contact_Contact: "danielgreen@hotmail.net",
    Address: "3288  Dancing Dove Lane, Whitestone, New York",
    Owner: "Andrew Target",
    Details: "theandrew@gmail.com",
    CertificateDate: new Date("2020-09-26T18:57:20.898Z"),
    Certificate: "156222",
    CertificateType: "Expert",
    Issue: new Date("2021-01-02T18:57:20.898Z"),
    Officer: "Andrew Thoma",
    FileNumber: "623",
    Status: "Active",
    StatDays: 260,
    CreatedDate: new Date(
      "Thur Jan 05 2020 14:09:46 GMT+0700 (Indochina Time)"
    ),
    LodgedDate: new Date("Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)"),
    ClockStart: new Date("Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)"),
  };
