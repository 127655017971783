import { ExistedMeter } from "@app/products/property/meters/[id]/components/forms/existed/_index";
import NewMeter from "@app/products/property/meters/[id]/components/forms/new/_index";
import { useMeterStore } from "@app/products/property/meters/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import React from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

function ManagePropertyMeter() {
  const params: { id: string } = useParams();
  const meterId = params.id;
  const { resetMenu } = useCCSubActionBarStore();
  const { loadMeter, resetStore } = useMeterStore();

  useEffectOnce(() => {
    if (isNew) return;
    loadMeter(parseInt(meterId));
    return () => {
      resetStore();
      resetMenu();
    };
  });

  const isNew = useIsNew();
  if (isNew) {
    return <NewMeter />;
  }

  return <ExistedMeter />;
}

export default ManagePropertyMeter;
