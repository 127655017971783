import {
  DTO_ChangeOfOwnership_NoticeOfSale_LOVs,
  DTO_Workflow_ChangeOfOwnership_NoticeOfSale,
  RequestChangeOfOwnershipObj,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const getChangeOfOwnershipNoSLovs = async (): Promise<
  APIResponse<DTO_ChangeOfOwnership_NoticeOfSale_LOVs>
> => {
  try {
    return await CoreAPIService.getClient().get<DTO_ChangeOfOwnership_NoticeOfSale_LOVs>(
      `/api/property/int/changeofownershipnos/lovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewChangeOfOwnershipNoS = async (
  input: RequestChangeOfOwnershipObj
): Promise<APIResponse<DTO_Workflow_ChangeOfOwnership_NoticeOfSale>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/int/workflow/changeofOwnershipnos/new`,
      input
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSavedChangeOfOwnershipNoS = async (
  workflowDraftId?: number
): Promise<APIResponse<DTO_Workflow_ChangeOfOwnership_NoticeOfSale>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/int/workflow/changeofownershipnos/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataChangeOfOwnershipNoS =
  | [
      APIResponse<DTO_ChangeOfOwnership_NoticeOfSale_LOVs>,
      APIResponse<DTO_Workflow_ChangeOfOwnership_NoticeOfSale>
    ]
  | APIResponse<
      | DTO_ChangeOfOwnership_NoticeOfSale_LOVs
      | DTO_Workflow_ChangeOfOwnership_NoticeOfSale
    >
  | undefined;

export const getInitialDataChangeOfOwnershipNoS = async (
  input: RequestChangeOfOwnershipObj,
  workflowDraftId?: number
): Promise<IGetInitialDataChangeOfOwnershipNoS> => {
  try {
    return await Promise.all([
      getChangeOfOwnershipNoSLovs(),
      !isNil(workflowDraftId)
        ? getSavedChangeOfOwnershipNoS(workflowDraftId)
        : postNewChangeOfOwnershipNoS(input),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessChangeOfOwnershipNoS = async (
  mode: WorkflowProcessMode,
  data: DTO_Workflow_ChangeOfOwnership_NoticeOfSale
): Promise<
  APIResponse<
    ResponseMessageWorkflow<DTO_Workflow_ChangeOfOwnership_NoticeOfSale>
  >
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/int/workflow/changeofownershipnos/process/${mode}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
