import { sleep } from "@common/utils/common";
export const loadDetailBookingById = async (id: number) => {
  await sleep(1000);
  return {
    lodged: new Date("25 Dec 2019"),
    debtorNo: "58627",
    osFees: 1,
    status: "Designer",
  };
};
