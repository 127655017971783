import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import {
  DTO_Supplementary_AssessmentAdjustment_LOVs,
  DTO_Workflow_AssessmentAdjustment,
  RequestAdjustmentAssessmentObj,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const getAssessmentAdjustmentLOVs = async (
  supplementaryId: number,
  assessmentId: number
): Promise<APIResponse<DTO_Supplementary_AssessmentAdjustment_LOVs>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Supplementary_AssessmentAdjustment_LOVs>(
      `api/property/internal/supplementary/assessmentadjustment/lovs/${supplementaryId}/${assessmentId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowAssessmentAdjustment = async (
  requestObj: RequestAdjustmentAssessmentObj | object = {},
  workflowDraftId: number | undefined
): Promise<APIResponse<DTO_Workflow_AssessmentAdjustment>> => {
  try {
    if (isNil(workflowDraftId)) {
      return await CoreAPIService.getClient().post(
        `/api/property/internal/workflow/assessmentadjustment/new`,
        requestObj
      );
    } else {
      return await CoreAPIService.getClient().get(
        `/api/property/internal/workflow/assessmentadjustment/${workflowDraftId}`
      );
    }
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessAssessmentAdjustment = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_AssessmentAdjustment
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_AssessmentAdjustment>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/process/assessmentadjustment/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
