import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const columnArrangementCalculatedSchedule: IColumnFields[] = [
  {
    field: "InstalmentNumber",
    title: "Instalment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "DueDate",
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "InstalmentAmount",
    title: "Instalment Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];

export const comboBoxSearchData = {
  _option: {
    Assessment: {
      Data: [],
      Loading: false,
    },
  },
};
