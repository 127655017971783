import { isShowOnContactType } from "@app/core/contacts/merge-contact/merge-contact-dialog/config";
import { useMergeContactStore } from "@app/core/contacts/merge-contact/store";
import { Address } from "@common/input-pickers/address/model";
import { Contact } from "@common/models/contact";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { nameOfFactory } from "@common/utils/common";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

const nameOf = nameOfFactory<Contact>();
const nameOfAddress = nameOfFactory<Address>();
export const MasterContactForm = observer(() => {
  const {
    mergeContactsLovs,
    masterContact,
    setOnSubmit,
    contacts,
    mergeContacts,
    isLoadingMergeContacts,
    isLoadingMergeAndEditContacts,
  } = useMergeContactStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { values, isValid } = event;
    const actionSubmit = event.event?.currentTarget.name as ActionSubmitActions;
    if (!(actionSubmit in ActionSubmitActions) || !isValid) return;

    if (values && contacts) {
      let mergeContact = {
        SelectedContactIDs: [values as Contact, ...contacts].map(
          (contact: Contact) => contact.Contact_ID
        ),
        MasterContactID: values.Contact_ID.toString(),
        MasterDisplayName: values.DisplayName,
        MasterWorkPhone: values.WorkPhone,
        MasterHomePhone: values.HomePhone,
        MasterMobile: values.Mobile,
        MasterEmail: values.Email,
        MasterPostalAddress: values.PostalAddress?.Address_ID ?? null,
        MasterStreetAddress: values.PreferredAddress?.Address_ID ?? null,
        MasterContactType: values.ContactClassification_ENUM,
        MasterAddressBook: values.AddressBookSection_ENUM,
        SubContacts: contacts.map((contact: any) => ({
          CurrAction: contact._action,
          SubContactID: contact.Contact_ID,
          DisplayName: contact.DisplayName,
        })),
      };
      if (
        mergeContact.MasterContactID &&
        actionSubmit === ActionSubmitActions.MergeContact
      ) {
        mergeContacts(mergeContact, false);
      }
      if (
        mergeContact.MasterContactID &&
        actionSubmit === ActionSubmitActions.MergeContactAndEdit
      ) {
        mergeContacts(mergeContact, true);
      }
    }
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, masterContact]);

  return (
    <Form
      initialValues={masterContact}
      key={JSON.stringify(masterContact)}
      onSubmitClick={handleOnSubmit}
      render={({ onSubmit }: FormRenderProps) => {
        submitRef.current = onSubmit;
        return (
          <FormElement>
            <div className="cc-form">
              <div className="cc-field-group">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel title="Contact ID" />
                    <Field
                      name={nameOf("Contact_ID")}
                      component={CCInput}
                      readOnly
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Display name" />
                    <Field
                      name={nameOf("DisplayName")}
                      component={CCInput}
                      readOnly={
                        isLoadingMergeContacts || isLoadingMergeAndEditContacts
                      }
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Address Book" />
                    <Field
                      name={nameOf("AddressBookSection_ENUM")}
                      component={CCDropDownList}
                      data={mergeContactsLovs?.AddressBook}
                      isKeyValueDropdown
                      disabled={
                        isLoadingMergeContacts || isLoadingMergeAndEditContacts
                      }
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Type" />
                    <Field
                      name={nameOf("ContactClassification_ENUM")}
                      component={CCDropDownList}
                      data={mergeContactsLovs?.ContactType}
                      isKeyValueDropdown
                      disabled={
                        isLoadingMergeContacts || isLoadingMergeAndEditContacts
                      }
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Postal address" />
                    <Field
                      name={nameOf("PostalAddress")}
                      component={CCDropDownList}
                      data={mergeContactsLovs?.PostalAddress}
                      dataItemKey={nameOfAddress("Address_ID")}
                      textField={nameOfAddress("Formatted_SingleLine")}
                      disabled={
                        isLoadingMergeContacts || isLoadingMergeAndEditContacts
                      }
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Street address" />
                    <Field
                      name={nameOf("PreferredAddress")}
                      component={CCDropDownList}
                      data={mergeContactsLovs?.PreferredAddress}
                      dataItemKey={nameOfAddress("Address_ID")}
                      textField={nameOfAddress("Formatted_SingleLine")}
                      disabled={
                        isLoadingMergeContacts || isLoadingMergeAndEditContacts
                      }
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Work phone" />
                    <Field
                      name={nameOf("WorkPhone")}
                      component={CCDropDownList}
                      data={mergeContactsLovs?.WorkPhone}
                      disabled={
                        isLoadingMergeContacts || isLoadingMergeAndEditContacts
                      }
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Home phone" />
                    <Field
                      name={nameOf("HomePhone")}
                      component={CCDropDownList}
                      data={mergeContactsLovs?.HomePhone}
                      disabled={
                        isLoadingMergeContacts || isLoadingMergeAndEditContacts
                      }
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Mobile" />
                    <Field
                      name={nameOf("Mobile")}
                      component={CCDropDownList}
                      data={mergeContactsLovs?.Mobile}
                      disabled={
                        isLoadingMergeContacts || isLoadingMergeAndEditContacts
                      }
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Email address" />
                    <Field
                      name={nameOf("Email")}
                      component={CCDropDownList}
                      data={mergeContactsLovs?.Email}
                      disabled={
                        isLoadingMergeContacts || isLoadingMergeAndEditContacts
                      }
                    />
                  </div>
                  {isShowOnContactType(
                    masterContact?.ContactClassification_ENUM
                  ) ? (
                    <>
                      <div className="cc-field">
                        <CCLabel title="ACN" />
                        <Field
                          name={nameOf("ACN")}
                          component={CCInput}
                          readOnly={
                            isLoadingMergeContacts ||
                            isLoadingMergeAndEditContacts
                          }
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="ABN" />
                        <Field
                          name={nameOf("ABN")}
                          component={CCInput}
                          readOnly={
                            isLoadingMergeContacts ||
                            isLoadingMergeAndEditContacts
                          }
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </FormElement>
        );
      }}
    />
  );
});
