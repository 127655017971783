import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSNoticesByDateBookmark = {
  getBookmarkListViewDisplayName() {
    return `${CRMS_PRODUCT_NAME} - Notices`;
  },
  getBookmarkListViewDetail() {
    return `${CRMS_PRODUCT_NAME} - Notices - By Date`;
  },
};
