import { DTO_Title } from "@app/products/property/parcels/[id]/components/child-screens/titles/model";
import { cloneDeep } from "lodash";

export const convert = (response: DTO_Title) => {
  let result: any = cloneDeep(response);

  if (response.OwnerDetails?.Address) {
    result.OwnerAddress = response.OwnerDetails?.Address;
  }
  if (response.OwnerDetails?.Name) {
    result.OwnerName = response.OwnerDetails?.Name;
  }
  if (response.OwnerDetails?.Locality) {
    result.OwnerLocality = response.OwnerDetails?.Locality;
  }

  return result;
};
