import { AxiosRequestConfig, AxiosResponse } from "axios";

export interface APIResponse<T = any> extends AxiosResponse<T> {
  error?: string | string[];
  errorCause?: unknown;
}
export interface APIResponseError
  extends Pick<APIResponse, "error" | "status" | "errorCause"> {}
// set ExpiredTimeToGetNewToken too long will cause call refresh token too many times
// if the getTokenSilently failed
export const ExpiredTimeToGetNewToken = 300000; // 5 mins

export type THandleCatchResponse = {
  error: any;
  errorCause?: unknown;
  data: any;
  status: number;
  statusText: string;
  headers: any;
  config: AxiosRequestConfig;
  request?: any;
};
