import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const RecallHistoryTab = observer(() => {
  const { lastSelectedId } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const recallId: string | number = id ? id : lastSelectedId;

  const { dataForms } = useFlexibleFormStore();
  const recallFromStore = dataForms?.GeneralForm;

  return (
    <Journal
      id={+recallId}
      triggersReload={recallFromStore}
      recordType={RECORDTYPE.CORE_Recall}
    />
  );
});
