import { IPSARWithdraw } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/withdraw-reason/model";
import { PPRApplicationIdentityPacket } from "@app/products/town-planning/ppr/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const submitWithdrawComment = async (
  withdrawData: IPSARWithdraw
): Promise<APIResponse<PPRApplicationIdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
      `/api/tp/ppr/internal/application/psar/${withdrawData.ApplicationID}/withdraw`,
      withdrawData
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
