import { CONTACT_ROUTE } from "@app/core/contacts/_id/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

interface INewContactProps {
  disabled?: boolean;
}

export const NewContactButton = ({ disabled }: INewContactProps) => {
  const history = useHistory();
  return (
    <CCNavButton
      title={"New"}
      onClick={() => history.push(`${CONTACT_ROUTE}/new`)}
      disabled={disabled}
    />
  );
};
