export enum CalendarViewEnum {
  AGENDA = "agenda",
  TIMELINE = "timeline",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export interface ICalendarData {
  id: number | string;
  start?: string | Date;
  startTimezone?: string;
  end?: string | Date;
  endTimezone?: string;
  isAllDay?: boolean;
  title?: string;
  description?: string;
  recurrenceRule?: string;
  recurrenceId?: string | number;
  recurrenceExceptions?: string;
}

export interface ICalendarFilterColumn {
  text: string;
  value: string;
  color: string;
}

export const currentYear = new Date().getFullYear();

export const parseAdjust = (eventDate: any) => {
  const date = new Date(eventDate);
  date.setFullYear(currentYear);
  return date;
};

export const defaultDisplayDate = new Date();
