import { IFieldDefModelReference } from "@app/products/ams/model/catalogue";
import React from "react";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../util/itemTypes";

interface IProps {
  fdmr: IFieldDefModelReference;
}

export const EntityFieldLayoutElement: React.FC<IProps> = ({ fdmr }) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.EntityFieldLayoutElement,
      fdmr: fdmr,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const getIcon = (fdmr: IFieldDefModelReference): string => {
    switch (fdmr.fieldDef.dataType) {
      case "date":
        return "fa fa-calendar";
      case "string":
        return "fa fa-keyboard";
      case "number":
        //add something for lookup too? faCaretSquareDown ?
        return "fa fa-calculator";
      case "boolean":
        return "fa fa-check-square";
      default:
        return "fa fa-question";
    }
  };

  const icon = getIcon(fdmr);
  return (
    <div
      className="form-row col-auto vw-100 border p-1"
      ref={drag}
      style={{ opacity: isDragging ? "0.5" : "1" }}
    >
      <div className="col-9">
        <label className="label">{fdmr.fieldDef.labelName}</label>
      </div>
      <div className="col float-right">
        {/* aligns right to the label only when i squash, otherwise goes under? make always right */}
        <i className={`${icon} float-right`} />
      </div>
    </div>
  );
};
