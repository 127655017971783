import { colFSPObserved } from "@app/products/hm/inspections/[id]/components/forms/exist/components/fsp-observed/config";
import { IFSPObserved } from "@app/products/hm/inspections/[id]/components/forms/exist/components/fsp-observed/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IFSPObservedProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<IFSPObserved>();
const FSPObserved: React.FC<IFSPObservedProps> = ({ id, recordType }) => {
  return (
    <div>
      <CCGrid
        columnFields={colFSPObserved}
        primaryField={nameOf("ID")}
        dataUrl={undefined}
      />
    </div>
  );
};

export default FSPObserved;
