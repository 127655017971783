import { DTO_Entity_Voter_Details } from "@app/products/property/contacts-central-names/voters-list/components/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const loadVoterDetail = async (
  voterId: number
): Promise<APIResponse<DTO_Entity_Voter_Details>> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Entity_Voter_Details>(
        `api/property/internal/voter/${voterId}/details`
      );

    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
    };
  }
};
