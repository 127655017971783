import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getMailmergeContent = async (
  batchId: string,
  owner: string
): Promise<APIResponse<Blob>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/core/internal/mailmerge/download?batchId=${batchId}&owner=enc=${owner}`,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
