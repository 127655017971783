import { DTO_AssociatedItem_DebtRecovery } from "@app/products/property/components/associations/components/debt-recovery/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_DebtRecovery>();

export const colAssociationsDebtRecovery: IColumnFields[] = [
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("NameAndAddress"),
    title: "Name and Address",
  },
  {
    field: nameOf("Assessments"),
    title: "Assessments",
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("LastAction"),
    title: "Last Action",
  },
  {
    field: nameOf("DateFrom"),
    title: "Date From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateTo"),
    title: "Date To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("InitialBalance"),
    title: "Initial Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InitialInterestBalance"),
    title: "Initial Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InitialDebt"),
    title: "Initial Debt",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InterestBalance"),
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("CostsIncurred"),
    title: "Costs Incurred",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InitialDebtIncludingCosts"),
    title: "Initial Debt Including Costs",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Total"),
    title: "Total",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("AssessmentBalance"),
    title: "Assessment Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("UpToDueDateTime"),
    title: "Up to Due Date Time",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("AsAt"),
    title: "As At",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("SolicitorMessage"),
    title: "Solicitor Message",
  },
  {
    field: nameOf("AlertMessage"),
    title: "Alert Message",
  },
  {
    field: nameOf("ShowAlertOnAssessment"),
    title: "Show Alert on Assessment",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Officer"),
    title: "Officer",
  },
  {
    field: nameOf("CreatedBy"),
    title: "Created By",
  },
  {
    field: nameOf("CreatedOn"),
    title: "Created On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ChangedBy"),
    title: "Changed By",
  },
  {
    field: nameOf("ChangedOn"),
    title: "Changed On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
