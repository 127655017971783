import { DTO_Assessment } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Assessment>();
export const colAssessmentsToBeSplit: IColumnFields[] = [
  {
    field: nameOf("Assessment_Number"),
    title: "Assessment Number",
  },
  {
    field: nameOf("Assessment_Reference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("Property_Address"),
    title: "Address",
  },
  {
    field: nameOf("Assessment_Group"),
    title: "Assessment Group",
  },
  {
    field: nameOf("Ratepayer"),
    title: "Ratepayer",
  },
];
