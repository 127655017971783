import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSCentreBasedCareWaitListbyCentre: IColumnFields[] = [
  {
    field: CRSFieldMapping.AppNo,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: CRSFieldMapping.IsPlacedAndWait,
    title: "P & W",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: CRSFieldMapping.ChildName, title: "Child Name" },
  {
    field: CRSFieldMapping.PrefCommMethod_Name,
    title: "Preference Method",
  },
  {
    field: CRSFieldMapping.ChildAge,
    title: "Age Today",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.Activation, title: "Activation" },
  { field: CRSFieldMapping.Weighting, title: "Weighting" },
  {
    field: CRSFieldMapping.Proximity,
    title: "Proximity",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.RegistrationDate,
    title: "Registered",
    format: DATE_FORMAT.DATE,
  },
  { field: CRSFieldMapping.DateOfBirth, title: "Date of Birth" },
  { field: CRSFieldMapping.Pref1, title: "Preference 1" },
  { field: CRSFieldMapping.Pref2, title: "Preference 2" },
  { field: CRSFieldMapping.Pref3, title: "Preference 3" },
  {
    field: CRSFieldMapping.ChildGender,
    title: "Gender",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: CRSFieldMapping.Suburb, title: "Suburb" },
  { field: CRSFieldMapping.ParentName, title: "Parent Name" },
  { field: CRSFieldMapping.Relationship, title: "Relationship" },
  {
    field: CRSFieldMapping.ContactNumber,
    title: "Contact",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.AllocationPriority,
    title: "Priority",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.PrefAll, title: "All Prefs" },
];
