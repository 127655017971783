import { PPRPSAReferral } from "@app/products/town-planning/ppr/psa-referrals/register/model";
import { ICalendarData } from "@components/cc-calendar/model";
import { setHours } from "date-fns";

//TODO enhance later just fix temporary
export const processHearingCalendarData = (
  rawData: PPRPSAReferral[]
): ICalendarData[] => {
  return rawData.map((data: PPRPSAReferral) => {
    const newData: ICalendarData = {
      id: data?.ID,
      title: data?.CalendarSubject,
      start: new Date(data?.DirectionsHearingDate as Date),
      end: setHours(new Date(data?.HearingDateEnd as Date), 23),
    };
    return newData;
  });
};

export const getBookmarkListViewDisplayName = () => {
  return "PPR - PSA Referrals";
};

export const getBookmarkListViewDetail = () => {
  return "PPR - PSA Referrals - Hearing Calendar";
};
