import { loadImportSchemeAccountsData } from "@app/products/property/schemes/[id]/components/forms/existed/components/form-step/import-scheme-accounts/components/form-elements/results/api";
import { colImportSchemeAccountResult } from "@app/products/property/schemes/[id]/components/forms/existed/components/form-step/import-scheme-accounts/components/form-elements/results/config";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { FieldArray } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const RESULTS_FORM_STEP = "Results";
export const ResultsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  setStepsVisible,
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const [isLoading, setIsLoading] = useState(false);

  useEffectOnce(() => {
    setIsLoading(true);
    loadImportSchemeAccountsData().then((data) => {
      setIsLoading(false);
      onChange(nameOf("ImportedAccountList"), { value: data });
    });
  });

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCGrid
            isLoading={isLoading}
            columnFields={colImportSchemeAccountResult}
            data={getFieldValue("ImportedAccountList")}
            primaryField="ImportId"
          />
        </div>
      </div>
    </section>
  );
};
