import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field } from "@progress/kendo-react-form";
import React from "react";

interface IDatePickerGlobalSettingProps {
  data: SettingField | undefined;
  isEditing: boolean;
  isFieldVisible?: boolean;
  colSpan?: number;
  customTitle?: string;
}

export const DatePickerGlobalSetting = ({
  data,
  isEditing,
  isFieldVisible = true,
  colSpan,
  customTitle,
  ...others
}: IDatePickerGlobalSettingProps) => {
  return data && isFieldVisible ? (
    <div className={`cc-field${colSpan ? " cc-col-span-" + colSpan : ""}`}>
      <label className="cc-label">
        {customTitle ?? data?.Title ?? ""}
        {data?.IsMandatory && <CCTooltip type="validator" position="right" />}
        {data?.HelpText && (
          <CCTooltip
            type="custom"
            content=" "
            position="auto"
            clickToOpen
            customTemplate={<>{sanitizeHtml(data.HelpText)}</>}
          >
            <i className="fa fa-info-circle ml-1 text-accent" />
          </CCTooltip>
        )}
      </label>
      {data?.Description && (
        <label className="cc-settings-description">
          {sanitizeHtml(data?.Description)}
        </label>
      )}

      <Field
        name={data?.FieldName}
        component={CCDatePicker}
        disabled={!isEditing}
        format={DATE_FORMAT.DATE_CONTROL}
        validator={data.IsMandatory ? requiredValidator : undefined}
        {...others}
      />
    </div>
  ) : null;
};
