import { getDisplayTextWithDashes } from "@common/utils/common";

export const CertificateECertificateRequestsBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Property - Certificate - ECertificate Request ${dynamicDisplayName}`;
  },

  //@TODO Don't have Agent_Reference and Name data in grid mock data. Recheck when integrated API
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.Agent_Reference,
      selectedRow.Name,
    ]);
  },
};
