import { deleteApplicationCategories } from "@app/products/town-planning/ppr/system-admin/application-categories/components/action-bar/buttons/delete-application-categories/api";
import { DeleteApplicationCategoryDialog } from "@app/products/town-planning/ppr/system-admin/application-categories/components/action-bar/dialogs/delete-application-categories/_index";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IDeleteApplicationCategoryProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteApplicationCategory = observer(
  ({ setIsLoading }: IDeleteApplicationCategoryProps) => {
    const { gridSelectedRows, gridSelectedIds } = useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();
    const [isShowDialog, setIsShowDialog] = useState(false);

    const onSubmit = async () => {
      setIsLoading(true);
      const response = await deleteApplicationCategories(gridSelectedIds);
      if (isSuccessIdentityPacket(response)) {
        pushNotification({
          title: "Application categories deleted successfully",
          type: "success",
        });
      } else {
        pushNotification({
          autoClose: false,
          title: "Delete application categories failed",
          description: response.data?.Errors ?? response.statusText,
          type: "error",
        });
      }
      setIsShowDialog(false);
      setIsLoading(false);
    };
    return (
      <>
        <CCNavButton
          title="Delete"
          onClick={() => setIsShowDialog(true)}
          disabled={gridSelectedRows?.length === 0}
        />
        {isShowDialog && (
          <DeleteApplicationCategoryDialog
            onClose={() => setIsShowDialog(false)}
            onSubmit={onSubmit}
          />
        )}
      </>
    );
  }
);
