export interface IDisassociateMeter {
  ID?: number | null;
  MeterNumber?: string;
  PropertyAddress?: string;
  AccountNames?: string;
  RemoveOptions?: string;
  DateOfRemoval?: Date | null;
  LastReading?: number | null;
}

export enum RemoveOptions {
  RemoveFromProperty = "RemoveFromProperty",
  DeleteAssociation = "DeleteAssociation",
}
