import {
  DTO_Charges,
  DTO_Deferments,
} from "@app/products/property/assessments/[id]/components/child-screens/deferments/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import React from "react";

const nameOfDeferments = nameOfFactory<DTO_Deferments>();
const nameOfDeferredCharges = nameOfFactory<DTO_Charges>();

export const colDeferments: IColumnFields[] = [
  {
    field: nameOfDeferments("ADActiveFromDate"),
    title: "Active From",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfDeferments("ADActiveToDate"),
    title: "Active To",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfDeferments("DefermentType"),
    title: "Type",
  },
  {
    field: nameOfDeferments("DefermentMethod"),
    title: "Method",
  },
  {
    field: nameOfDeferments("ADMaximumAmount"),
    title: "Maximum Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfDeferments("ADOverallMaximumAmount"),
    title: "Overall Maximum Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfDeferments("ADPercentage"),
    title: "Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOfDeferments("ADApplicationDate"),
    title: "Application Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfDeferments("ADApprovalDate"),
    title: "Approval Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfDeferments("ADNotes"),
    title: "Notes",
  },
  {
    field: nameOfDeferments("ADCreatedBy"),
    title: "Created By",
  },
  {
    field: nameOfDeferments("ADCreatedOn"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfDeferments("ADModifiedBy"),
    title: "Modified By",
  },
  {
    field: nameOfDeferments("ADModifiedOn"),
    title: "Modified On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfDeferments("AssessmentDefermentId"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colDeferredCharges: IColumnFields[] = [
  {
    field: nameOfDeferredCharges("RatingPeriodName"),
    title: "Rating Period",
  },
  {
    field: nameOfDeferredCharges("DateDeferred"),
    title: "Charge Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfDeferredCharges("ChargeName"),
    title: "Charge Name",
    footerCell: <TextCell value="Overall Total" />,
  },
  {
    field: nameOfDeferredCharges("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfDeferredCharges("Interest"),
    title: "Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfDeferredCharges("DateDeferred"),
    title: "Date Deferred",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfDeferredCharges("ChargeId"),
    title: "Charge ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOfDeferredCharges("AssessmentDefermentId"),
    title: "Assessment Deferment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
