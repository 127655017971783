import { eventEmitter } from "@/App";
import { history } from "@/AppRoutes";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { LOCAL_LAWS_PERMIT_ROUTE } from "@app/products/local-laws/[id]/constant";
import {
  Permit,
  Permit_Status,
  Svc_Permit,
  Svc_Permit_Workflow_ProvideReason,
} from "@app/products/local-laws/[id]/model";
import { getPermitGeneralForm } from "@app/products/local-laws/[id]/util";
import { postWithdrawPermit } from "@app/products/local-laws/components/toolbar/buttons/withdraw/api";
import { ReasonDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/_index";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { IIdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
interface IWithdrawPermitButtonProps {
  isDisabled?: boolean;
}

export const WithdrawPermitButton = observer(
  ({ isDisabled }: IWithdrawPermitButtonProps) => {
    //#region STORE ========/
    const {
      dataForms,
      isLoadingForm,
      submitFormGetData,
      setMiddlewareSubmitFormOptions,
      updateFormFields,
    } = useFlexibleFormStore();
    const { cancelToken } = useCancelRequest();
    const { pushNotificationPortal } = useNotificationPortalStore();
    //#endregion STORE =====/

    //#region STATES ========/
    const isNew = useIsNew();
    const [objReasonWithdraw, setObjReasonWithdraw] = useState<ProvideReason>();
    const [isShowWorkflowWithdraw, setIsShowWorkflowWithdraw] =
      useState<boolean>(false);
    //#endregion STATES =====/

    //#region DEFINED ========/
    const isVisible = useMemo(() => {
      const permitData = dataForms?.GeneralForm?.Permit as Permit;
      return (
        permitData?.Status_ENUM === Permit_Status.NewApplication ||
        permitData?.Status_ENUM === Permit_Status.PermitLodged
      );
    }, [dataForms]);
    //#endregion DEFINED =====/

    //#region METHOD ========/
    const objWithdraw = () => {
      const objReason = new ProvideReason();
      objReason.Title_Text = "Withdraw Permit";
      objReason.ReasonLabel_Text = "Reason";
      objReason.Flag_ShowDate = false;

      setObjReasonWithdraw(objReason);
    };

    const isOpenWithdrawWfDialog = useMemo(() => {
      setObjReasonWithdraw(undefined);
      if (isShowWorkflowWithdraw) objWithdraw();

      return isShowWorkflowWithdraw;
    }, [isShowWorkflowWithdraw]);

    const handleOnCloseDialog = () => {
      setIsShowWorkflowWithdraw(false);
      setObjReasonWithdraw(undefined);
    };

    const handleWorkflowWithdraw = async (provideReason: ProvideReason) => {
      setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      const generalForm = await submitFormGetData("GeneralForm");
      let permit: Permit = generalForm?.Permit;

      if (permit) {
        permit.TypeOfDescription = permit.TypeOfDescription ?? "";
        permit.ReferenceNumber = permit.ReferenceNumber ?? "";
        permit.FileNumber = permit.FileNumber ?? "";
        permit.InsurancePolicyNumber = permit.InsurancePolicyNumber ?? "";
        permit.Description = permit.Description ?? "";

        const responseRequest = {
          Permit: permit,
          ProvideReason: provideReason,
        } as Svc_Permit_Workflow_ProvideReason;

        await withdrawPermitButtonSlider.fetchApi({
          initialData: responseRequest,
        });
      }
    };

    const withdrawPermitButtonSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const withdrawPermitRequest: Svc_Permit_Workflow_ProvideReason =
              initialData;
            return postWithdrawPermit(withdrawPermitRequest, cancelToken());
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<IIdentityPacket<Permit>> = dataFromApi;
            if (response?.data?.ReturnObj) {
              const permitFormData = response?.data?.ReturnObj as Permit;
              //#region save data to store ========/
              if (isNew) {
                setIsShowWorkflowWithdraw(false);
                setObjReasonWithdraw(undefined);
                const urlRedirect =
                  LOCAL_LAWS_PERMIT_ROUTE + "/" + permitFormData.Permit_ID;
                pushNotificationPortal({
                  title: "Permit withdraw successfully.",
                  type: "success",
                  route: urlRedirect,
                  callBack() {
                    history.replace(urlRedirect);
                  },
                });
              } else {
                updateFormFields("GeneralForm", {
                  Permit: permitFormData,
                } as Svc_Permit);

                setIsShowWorkflowWithdraw(false);
                setObjReasonWithdraw(undefined);

                eventEmitter.emit(CCGridEventType.RefreshOData);
                eventEmitter.emit(CCJournalEventType.RefreshData);
                eventEmitter.emit(TabTableEventType.RefreshData);

                pushNotificationPortal({
                  title: "Permit withdraw successfully.",
                  type: "success",
                });
              }
            } else {
              pushNotificationPortal({
                placeId: "ReasonDialog",
                title: "Withdrawn permit failed.",
                type: "error",
                autoClose: false,
              });
            }
            return false;
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              placeId: "ReasonDialog",
              title: "Withdrawn permit failed.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleClickButton = async () => {
      const dataForm = getPermitGeneralForm();
      if (!isNil(dataForm?.Permit)) {
        setIsShowWorkflowWithdraw(true);
      }
    };
    //#endregion METHOD =====/

    return (
      <>
        <CCNavButton
          title="Withdraw permit"
          invisible={!isVisible}
          onClick={handleClickButton}
          disabled={isLoadingForm || isDisabled}
        />

        {isOpenWithdrawWfDialog && (
          <ReasonDialog
            onClose={handleOnCloseDialog}
            onSubmit={(value: ProvideReason) => handleWorkflowWithdraw(value)}
            isLoading={withdrawPermitButtonSlider.isFetching}
            initialData={objReasonWithdraw}
          />
        )}
      </>
    );
  }
);
