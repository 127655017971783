import { apiCorePostFeeTypesExport } from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { FileDownloadModel } from "@common/pages/settings/lookups/action-types/components/action-bar/buttons/export/model";
import { CancelToken } from "axios";

export const postExportFeeTypeList = async (
  feeTypeIds: number[],
  cancelToken: CancelToken
): Promise<APIResponse<FileDownloadModel>> => {
  try {
    return await CoreAPIService.getClient().post(
      apiCorePostFeeTypesExport(),
      feeTypeIds,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
