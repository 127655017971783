import { VO_Assess_Rebate_Entitlement_Concession_Card } from "@app/products/property/assessments/rebates/concession-cards/model";
import { VO_Assessment_RebateEntitlementEntity } from "@app/products/property/assessments/rebates/list/components/detail/model";
import { VO_Assessment_RebateEntitlement } from "@app/products/property/assessments/rebates/list/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfConcessionCards =
  nameOfFactory<VO_Assess_Rebate_Entitlement_Concession_Card>();
const nameOfAssessmentRebateEntitlement =
  nameOfFactory<VO_Assessment_RebateEntitlement>();
const nameOfAssessmentRebateEntitlementEntity =
  nameOfFactory<VO_Assessment_RebateEntitlementEntity>();

//All titles will be replaced by column name data from view configuration API
export const colConcessionCards: IColumnFields[] = [
  {
    field: nameOfConcessionCards("Concession_Card_Name"),
    title: "Card Type",
  },
  {
    field: nameOfConcessionCards("Concession_Card_Number"),
    title: "Card Number",
  },
  {
    field: nameOfConcessionCards("Concession_Card_Holder"),
    title: "Card Holder",
  },
  {
    field: nameOfConcessionCards("Card_Holder_Gender"),
    title: "Gender",
  },
  {
    field: nameOfConcessionCards("ECC_Date_Presented"),
    title: "Date Presented",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfConcessionCards("ECC_Confirmation_Date"),
    title: "Confirmation Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfConcessionCards("ARE_Effective_Date"),
    title: "Rebates Effective From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfConcessionCards("Assessment_Rebate_Entitlement"),
    title: "Active Assessment Rebate Entitlements",
  },
  {
    field: nameOfConcessionCards("Active_Concession_Card"),
    title: "Is Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfConcessionCards("ARE_Loss_of_Eligibility_Date"),
    title: "Loss of Entitlement",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfConcessionCards("Concession_Card_Id"),
    title: "Concession Card ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOfConcessionCards("Card_Holder_Id"),
    title: "Entity ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colRebatesDetailConcessionCard: IColumnFields[] = [
  {
    field: nameOfAssessmentRebateEntitlement("Assess_NumberX"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfAssessmentRebateEntitlement("Formatted_Address"),
    title: "Property Address",
  },
  {
    field: nameOfAssessmentRebateEntitlement("Rebate_Type"),
    title: "Rebate Type",
  },
  {
    field: nameOfAssessmentRebateEntitlement("Percentage_Of_Full_Rebate"),
    title: "Percentage of Full Rebate",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOfAssessmentRebateEntitlement("Application_Date"),
    title: "Application Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentRebateEntitlement("Effective_Date"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentRebateEntitlement("ARE_Loss_of_Eligibility_Date"),
    title: "Loss of Eligibility",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentRebateEntitlement("ARE_Entitled_To_AlterRebate_Cap"),
    title: "Alternate Rebate Cap",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfAssessmentRebateEntitlement("Entitled_Entities"),
    title: "Entitled Contacts",
  },
  {
    field: nameOfAssessmentRebateEntitlement("Changed_On"),
    title: "Changed On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentRebateEntitlement("Changed_By"),
    title: "Changed By",
  },
  {
    field: nameOfAssessmentRebateEntitlement("Assess_Rebate_Entitlement_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colRebatesDetailConcessionCardChild: IColumnFields[] = [
  {
    field: nameOfAssessmentRebateEntitlementEntity("Entity_Name"),
    title: "Contact",
  },
  {
    field: nameOfAssessmentRebateEntitlementEntity("Entitled_To_Rebate"),
    title: "Entitled to Rebate",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfAssessmentRebateEntitlementEntity("Concession_Cards"),
    title: "Concession Cards Held",
  },
  {
    field: nameOfAssessmentRebateEntitlementEntity("Entity_Id"),
    title: "Contact ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
