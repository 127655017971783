import { colRegisterSummaryAttribute } from "@app/products/property/registers/[id]/components/child-screens/general/components/form-element/components/attribute/config";
import { IAttribute } from "@app/products/property/registers/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IRegisterSummaryAttributeProps {
  data: IAttribute[];
}
const nameOf = nameOfFactory<IAttribute>();
export const RegisterSummaryAttribute = ({
  data,
}: IRegisterSummaryAttributeProps) => {
  return (
    <div className="cc-register-summary-attribute">
      <CCGrid
        className="cc-register-summary-attribute-grid"
        data={data || []}
        columnFields={colRegisterSummaryAttribute}
        primaryField={nameOf("Id")}
      />
    </div>
  );
};
