import { useDebounce } from "@common/hooks/useDebounce";
import { filterBy } from "@progress/kendo-data-query";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
} from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import { isNil, isNull } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./_index.scss";

interface ICCComboBoxProps {
  validationMessage?: string | null;
  visited?: boolean;
  isUseDefaultOnchange?: boolean;
  isLoading?: boolean;
}

export const CCSearchComboBox = (props: ICCComboBoxProps & ComboBoxProps) => {
  const {
    validationMessage,
    visited,
    data,
    textField,
    onChange,
    isUseDefaultOnchange,
    dataItemKey,
    value,
    isLoading,
    itemRender,
    ...others
  } = props;
  const [searchKey, setSearchKey] = useState("");
  const [processData, setProcessData] = useState<any[] | undefined>(
    data?.slice() ?? []
  );
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearch = useDebounce(searchKey, 200);

  const comboBoxValue = useMemo(() => {
    //TODO:
    //For new version of React Kendo, "value" is the value of form field (binding by "name" prop),
    //the value passed to ComboBox is ignored
    //=> Temporarily fix by checking type of value, will find the right solution later
    if ((typeof value !== "object" || isUseDefaultOnchange) && dataItemKey)
      return data?.find((obj: any) => obj[dataItemKey] === value) ?? null;
    return value;
  }, [value, data, isUseDefaultOnchange, dataItemKey]);

  const handleOnFilterChange = (event: ComboBoxFilterChangeEvent) => {
    const searchText = event.filter.value;
    setSearchKey(searchText);
  };

  const handleOnChange = useCallback(
    (event: ComboBoxChangeEvent) => {
      if (!onChange) return;
      if (isNull(event.value)) {
        setSearchKey("");
        return onChange(event);
      }
      if (isUseDefaultOnchange && dataItemKey)
        return onChange({
          ...event,
          value: event.value[dataItemKey],
        });
      onChange(event);
    },
    [isUseDefaultOnchange, dataItemKey, onChange]
  );

  useEffect(() => {
    (async () => {
      if (!data || data.length === 0) return;
      if (!isNil(debouncedSearch)) {
        setIsSearching(true);
        const resultSearch = filterBy(data?.slice(), {
          field: textField,
          value: debouncedSearch,
          operator: "contains",
        });
        setProcessData(resultSearch);
        setIsSearching(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  useEffect(() => {
    setProcessData(data);
  }, [data]);

  return (
    <>
      <ComboBox
        {...others}
        filterable
        textField={textField}
        dataItemKey={dataItemKey}
        data={processData}
        onFilterChange={handleOnFilterChange}
        loading={isSearching || isLoading}
        onChange={handleOnChange}
        value={comboBoxValue}
        itemRender={itemRender}
        popupSettings={{ className: "cc-search-combo-box-popup" }}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
