import {
  DTO_Entity_Concession_Card,
  EKeysOfNewContactSteps,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { nameOfFactory } from "@common/utils/common";
import { isValidValueWithMask } from "@common/utils/formatting";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { colConcessionCardsHeld } from "./config";

const nameOfConcessionCard = nameOfFactory<DTO_Entity_Concession_Card>();
export const ConcessionCardsFormStep = (props: IFormStepElement) => {
  const newValidator = useCallback(
    (value: any) => {
      if (props?.options?.isReadOnly) return undefined;
      const wrongItem = value?.Concession_Cards.find(
        (concessionCard: DTO_Entity_Concession_Card) =>
          concessionCard.Is_Active &&
          !isValidValueWithMask(
            concessionCard.Card_Number,
            concessionCard.Card_Number_InputMask
          )
      );
      if (wrongItem) return "Some card numbers are in the wrong format.";
      return undefined;
    },
    [props?.options]
  );
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={newValidator}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = { isReadOnly: false },
  }: IFormStepElement) => {
    //get value
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const concessionCard = getFieldValue("Concession_Cards");
    if (options?.onChangeRef) {
      options.onChangeRef.current = { onChange, nameOf };
    }

    /**
     * handle update information selection
     * @param field
     * @param value
     */
    const handleConcessionCardHeldInfoChange = (
      dataRow: DTO_Entity_Concession_Card,
      field: string,
      value: any
    ) => {
      const newConcessionCardHeld = concessionCard?.map(
        (concessionCardHeld: any) => {
          if (concessionCardHeld.Id !== dataRow?.Id) return concessionCardHeld;
          //Clear invalid card number when Is_Active is false
          if (
            field === nameOfConcessionCard("Is_Active") &&
            !value &&
            !isValidValueWithMask(
              concessionCardHeld.Card_Number,
              concessionCardHeld.Card_Number_InputMask
            )
          ) {
            concessionCardHeld[nameOfConcessionCard("Card_Number")] = "";
          }
          concessionCardHeld[field] = value;
          return concessionCardHeld;
        }
      );
      onChange(nameOf("Concession_Cards"), {
        value: newConcessionCardHeld,
      });
    };

    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel
                title="Concession cards held"
                errorMessage={errors[EKeysOfNewContactSteps.ConcessionCards]}
              />
              <CCGrid
                readOnly={options?.isReadOnly}
                data={concessionCard}
                columnFields={colConcessionCardsHeld}
                primaryField={nameOfConcessionCard("Id")}
                selectableMode="single"
                editableMode={!options?.isReadOnly ? "cell" : undefined}
                onDataRowChange={(dataRow, fieldChange, valueChange) => {
                  handleConcessionCardHeldInfoChange(
                    dataRow,
                    fieldChange,
                    valueChange
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
