import { ISecondaryWorkflows } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-meter/components/form-elements/secondary-workflow/model";

export const mockSecondaryWorkflowsData: ISecondaryWorkflows[] = [
  {
    Key: 1,
    Value: "Schedule a Special Readings",
  },
  {
    Key: 2,
    Value: "Enter Special Readings",
  },
];
