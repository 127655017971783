import { EAnimalSettingsCategory } from "@app/products/animals/system-admin/settings/_id/main/model";
import { IRouteStateForm } from "@app/products/animals/system-admin/settings/_id/main/routeState";

export const kennelsRouteStateAnimalsSettings: IRouteStateForm[] = [
  {
    name: EAnimalSettingsCategory.Kennels_Detail,
    component: require("./forms/details/_index").default,
  },
  {
    name: EAnimalSettingsCategory.Kennels_Numbering,
    component: require("./forms/numbering/_index").default,
  },
  {
    name: EAnimalSettingsCategory.Kennels_Fees,
    component: require("./forms/fees/_index").default,
  },
];
