import { VO_MasterPropertyValuation } from "@app/products/property/assessments/master-properties/[id]/components/child-screens/valuations/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_MasterPropertyValuation>();
export const colValuationsByValuations: IColumnFields[] = [
  {
    field: nameOf("Valuation_Effective_Date"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Valuation_Issue_Date"),
    title: "Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Date_of_Valuation"),
    title: "Date Of Valuation",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Valuation_Comment"),
    title: "Comment",
  },
  {
    field: nameOf("Valuation_1"),
    title: "Valuation_1",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_2"),
    title: "Valuation_2",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_3"),
    title: "Valuation_3",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_4"),
    title: "Valuation_4",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_5"),
    title: "Valuation_5",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_6"),
    title: "Valuation_6",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_7"),
    title: "Valuation_7",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_8"),
    title: "Valuation_8",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_9"),
    title: "Valuation_9",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_10"),
    title: "Valuation_10",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_11"),
    title: "Valuation_11",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_12"),
    title: "Valuation_12",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_13"),
    title: "Valuation_13",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_14"),
    title: "Valuation_14",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_15"),
    title: "Valuation_15",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_16"),
    title: "Valuation_16",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_17"),
    title: "Valuation_17",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_18"),
    title: "Valuation_18",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_19"),
    title: "Valuation_19",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_20"),
    title: "Valuation_20",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Supplementary_Id"),
    title: "Supplementary Id",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("SupplementaryReference"),
    title: "Supplementary Reference",
  },
  {
    field: nameOf("Supplementary_Date"),
    title: "Supplementary Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Supplementary_Name"),
    title: "Supplementary Name",
  },
  {
    field: nameOf("Valuation_Id"),
    title: "Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colValuationsByRatingPeriod: IColumnFields[] = [
  {
    field: nameOf("Rating_Period_Name"),
    title: "Rating Period",
  },
  ...colValuationsByValuations,
];
