import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import React from "react";
import { DTO_ChargeInstalment, DTO_PendingInterest } from "./model";

const nameOf = nameOfFactory<DTO_PendingInterest>();
const nameOfChargeInstalment = nameOfFactory<DTO_ChargeInstalment>();

export const colPendingInterest: IColumnFields[] = [
  {
    field: nameOf("EnableInterest"),
    title: "Enable Interest",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("ApplyInterest"),
    title: "Apply Interest",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "CalculatedInterest",
    title: "Calculated Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InterestableBalance"),
    title: "Interestable Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InterestRate"),
    title: "Interest Rate",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("FromInterestDate"),
    title: "From Interest Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ToInterestDate"),
    title: "To Interest Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DaysInterest"),
    title: "Days Interest",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("LevyName"),
    title: "Levy",
  },
  {
    field: nameOf("InstalmentNumber"),
    title: "Instal. Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("EffectiveDate"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DueDate"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("InterestEffectiveDate"),
    title: "Interest Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ChargeName"),
    title: "Charge Name",
  },
  {
    field: nameOf("ChargeDate"),
    title: "Charge Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("GraceDays"),
    title: "Grace Days",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("IsRaiseInterestOnInterest"),
    title: "Interest on Interest",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("MinimumInterestRaised"),
    title: "Minimum Interest Raised",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
export const colInterest: IColumnFields[] = [
  {
    field: nameOfChargeInstalment("Balance"),
    title: "Balance",
    footerCell: <CurrencyCell style={formatStyleProminent} />,
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfChargeInstalment("CalculatedInterest"),
    title: "Calculated Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfChargeInstalment("ChargeDate"),
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfChargeInstalment("Charge"),
    title: "Charge",
  },
  {
    field: nameOfChargeInstalment("InstalmentNumber"),
    title: "Instalment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfChargeInstalment("DueDate"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfChargeInstalment("EffectiveDate"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfChargeInstalment("InterestEffectiveDate"),
    title: "Interest Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfChargeInstalment("InterestDateTo"),
    title: "Raised Up To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfChargeInstalment("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
