export enum RecordsSystemEnum {
  None = "0",
  TRIM = "1",
  infoXpert = "2",
  ECM = "3",
  ContentPlus = "4",
  Objective = "5",
  CMIS = "6",
  SharePoint = "7",
  InternalFile = "8",
  Fileit = "9",
}
