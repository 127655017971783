import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface IAmalgamatePICCommonButtonProps {
  isDisabled?: boolean;
  picIDs: number[];
}
export const AmalgamatePICCommonButton = observer(
  ({ isDisabled = false, picIDs }: IAmalgamatePICCommonButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();
    return (
      <CCNavButton
        title="Amalgamate PIC"
        disabled={isDisabled}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.PIC_Amalgamate,
              data: {},
              props: {
                picIDs,
              },
            },
          ]);
        }}
      />
    );
  }
);
