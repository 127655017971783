import { dogSettingsRouteStateAnimalsSettings } from "@app/products/animals/system-admin/settings/_id/main/settings/dog-settings/routeState";
import { generalRouteStateAnimalsSettings } from "@app/products/animals/system-admin/settings/_id/main/settings/general/routeState";
import { infringementsRouteStateAnimalsSettings } from "@app/products/animals/system-admin/settings/_id/main/settings/infringements/routeState";
import { kennelsRouteStateAnimalsSettings } from "@app/products/animals/system-admin/settings/_id/main/settings/kennels/routeState";
import { numberingRouteStateAnimalsSettings } from "@app/products/animals/system-admin/settings/_id/main/settings/numbering/routeState";
import { poundRegisterRouteStateAnimalsSettings } from "@app/products/animals/system-admin/settings/_id/main/settings/pound-register/routeState";
import { recordsRouteStateAnimalsSettings } from "@app/products/animals/system-admin/settings/_id/main/settings/records/routeState";

export interface IRouteStateForm {
  name: string;
  component: any;
}

export const routeStateAnimalsConfig: IRouteStateForm[] = [
  ...generalRouteStateAnimalsSettings,
  ...numberingRouteStateAnimalsSettings,
  ...poundRegisterRouteStateAnimalsSettings,
  ...infringementsRouteStateAnimalsSettings,
  ...kennelsRouteStateAnimalsSettings,
  ...recordsRouteStateAnimalsSettings,
  ...dogSettingsRouteStateAnimalsSettings,
];
