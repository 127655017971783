import { IKeyValuePacket } from "@common/models/keyValuePacket";

export interface IInstallations {
  MeterInstallation?: string;
  ListMeterInstallation?: string[];
  ReadingDetail?: IReadingDetail;
  InstallationDetails?: IInstallationDetails;
  AssociationMeter?: AssociationMeter[];
  BillingDetails?: IBillingDetails;
}
export interface IInstallationDetails {
  InstallationDate?: Date;
  By?: string;
}
export interface IBillingDetails {
  Category?: IKeyValuePacket;
  ListCategories?: IKeyValuePacket[];
  AssessmentsAssociated?: IAssessmentsAssociated[];
}
export enum MasterSubEnum {
  MasterMeter = "Master Meter",
  SubMeter = "Sub Meter",
}
export interface AssociationMeter {
  MasterSub?: MasterSubEnum;
  MeterNo: string;
  Installed?: Date;
  Removed?: Date;
  AssociatedFrom?: Date;
  AssociatedTo?: Date;
  LastReadOn?: Date;
  CreatedBy?: string;
  CreatedOn?: Date;
  Id: string;
  isDisabledRow?: boolean; //CCGrid disableRowField
}

export interface IAssessmentsAssociated {
  Type?: string;
  ReadingOnRemoval?: number;
  AssessmentNumber?: string;
  MeterNumber?: string;
  BillingGroup?: string;
  UsageEntitlement?: number;
  UsagePercentage?: number;
  PropertyAddress?: string;
  DateAttached?: Date;
  Improvements?: string;
  PrimaryLandUse?: string;
  AssessmentType?: string;
  Status?: string;
  AssessmentId?: number;
  Description?: string;
}

export interface IReadingDetail {
  NextScheduledReadingDate?: Date;
  ReadingFrequency?: number;
  //Route
  RouteName?: IKeyValuePacket;
  ListRouteName?: IKeyValuePacket[];
  SequenceWithinRoute?: number;
  AssignedReadingDevice?: string;
  DeviceAssignedTo?: string;
  //Location
  MeterLocation?: string;
  ReadingInstructions?: IKeyValuePacket;
  SpecialInstructions?: string;
  KeysAreRequired?: boolean;
  GISReference?: string[];
  ListReadingInstructions?: IKeyValuePacket[];
  //Property
  AutoProperty?: boolean;
  PropertyName?: string;
  ListLocality?: IKeyValuePacket[];
  PropertyAddress?: string;
  Locality?: IKeyValuePacket;
  //Contact
  PhoneBeforeReading?: boolean;
  ContactName?: string;
  ContactPhone?: string;
  EmailBeforeReading?: boolean;
  EmailAddress?: string;
}
