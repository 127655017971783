import { DocumentTemplate } from "@app/products/property/system-admin/document-template/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getCloneDocumentTemplate = async (
  id: number
): Promise<APIResponse<DocumentTemplate | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/documenttemplate/Clone/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
