import { useAssessmentRatingStore } from "@app/products/property/assessments/[id]/components/child-screens/rating/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { CommunityProperty, Label } from "@common/stores/products/config";
import { CCInput } from "@components/cc-input/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const RatingAssessment = observer(() => {
  const { assessment } = useAssessmentRatingStore();
  const { isLLS } = CommunityProperty.getFlagOfStates();

  //Get labels
  const assessmentTypeLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.AssessmentType
  );

  return (
    <div className="cc-assessment cc-form-cols-3">
      <div className="cc-field">
        <label className="cc-label">Status</label>
        <CCInput
          className="cc-form-control"
          value={assessment?.Status}
          readOnly
        />
      </div>
      <div className="cc-field">
        <label className="cc-label">
          {isLLS ? "Primary land use" : "Land use"}
        </label>
        <CCInput
          className="cc-form-control"
          value={assessment?.PrimaryLandUse}
          readOnly
        />
      </div>
      {isLLS && (
        <div className="cc-field">
          <label className="cc-label">Secondary land use</label>
          <CCInput
            className="cc-form-control"
            value={assessment?.SecondaryLandUse}
            readOnly
          />
        </div>
      )}
      <div className="cc-field">
        {/* Waiting confirm to split phrase or whole phrase */}
        <label className="cc-label">{assessmentTypeLabel}</label>
        <CCInput
          className="cc-form-control"
          value={assessment?.AssessmentType}
          readOnly
        />
      </div>
    </div>
  );
});
