import { useRosterStore } from "@app/products/crms/system-admin/rosters/[id]/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const CRMSSystemAdminRosterDetailsTab = observer(() => {
  const { roster, isLoadingDetails, loadRosterDetails, responseLoadError } =
    useRosterStore();
  const { lastSelectedId } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const rosterID = params.id || lastSelectedId;

  useEffect(() => {
    if (rosterID) loadRosterDetails(rosterID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rosterID]);

  if (isLoadingDetails) return <Loading isLoading={isLoadingDetails} />;

  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          rosterID && loadRosterDetails(rosterID);
        }}
      />
    );
  }

  if (!roster) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Created date:"}
          value={formatDisplayValue(roster?.Sys_CreatedDate, DATE_FORMAT.DATE)}
        />

        <ProductReferenceRow
          title={"Created by:"}
          value={roster?.Sys_CreatedBy}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
