import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { WorkflowTypes } from "@app/products/property/model";
import { Label } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

interface ISplitAssessmentButtonProps {
  isHidden?: boolean;
}

export const SplitAssessmentButton = observer(
  ({ isHidden }: ISplitAssessmentButtonProps) => {
    const { assessment } = useAssessmentStore();
    const { setListDialog } = usePropertyWorkflowStore();
    const { id } = useParams<{ id: string }>();

    //Get label
    const splitAssessmentLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Split_Assessment
    );

    return !isHidden ? (
      <CCNavButton
        title={splitAssessmentLabel}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Split_Assessment,
              data: {},
              props: {
                assessmentId: +(id ?? 0),
                statusId: assessment?.Assessment?.StatusId ?? null,
              },
            },
          ]);
        }}
      />
    ) : null;
  }
);
