import { DATETIME_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo } from "@common/utils/formatting";
import {
  schedulerDateFormat,
  schedulerMessage,
} from "@components/cc-calendar/config";
import {
  CalendarViewEnum,
  ICalendarData,
  ICalendarFilterColumn,
  defaultDisplayDate,
} from "@components/cc-calendar/model";
import {
  IntlProvider,
  LocalizationProvider,
  load,
  loadMessages,
} from "@progress/kendo-react-intl";
import { Popup } from "@progress/kendo-react-popup";
import {
  AgendaView,
  DayView,
  MonthView,
  Scheduler,
  SchedulerItem,
  SchedulerItemProps,
  SchedulerProps,
  TimelineView,
  WeekView,
} from "@progress/kendo-react-scheduler";
import React from "react";
import { useHistory } from "react-router-dom";

import currencyData from "cldr-core/supplemental/currencyData.json";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import weekData from "cldr-core/supplemental/weekData.json";

import caGregorian from "cldr-dates-full/main/en-GB/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/en-GB/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/en-GB/timeZoneNames.json";
import numbers from "cldr-numbers-full/main/en-GB/numbers.json";
import "./_index.scss";

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  caGregorian,
  dateFields,
  timeZoneNames
);

loadMessages(schedulerMessage, "en-EN");
interface ICCCalendarProps extends SchedulerProps {
  data?: ICalendarData[];
  detailUrl: string;
  filterColumn?: ICalendarFilterColumn[];
  defaultView?: CalendarViewEnum;
}

export const CCCalendar = ({
  data = [],
  filterColumn = [],
  detailUrl,
  defaultView = CalendarViewEnum.MONTH,
  ...other
}: ICCCalendarProps) => {
  return (
    <div className="cc-calendar">
      <div className="cc-event-scheduler-container">
        <LocalizationProvider language="en-EN">
          <IntlProvider locale="en-GB">
            <Scheduler
              height={"100%"}
              style={{ height: "100%", width: "100%" }}
              {...other}
              editable={false}
              data={data}
              defaultDate={defaultDisplayDate}
              // onDataChange={handleDataChange}
              className="cc-event-scheduler"
              item={(itemProps: SchedulerItemProps) => {
                return (
                  <CustomSchedulerItem
                    props={itemProps}
                    detailUrl={detailUrl}
                  />
                );
              }}
              resources={[
                {
                  name: "Applicant",
                  data: [...filterColumn],
                  field: "type",
                  valueField: "value",
                  textField: "text",
                  colorField: "color",
                },
              ]}
              defaultView={defaultView}
            >
              <TimelineView {...schedulerDateFormat.format1} />
              <DayView {...schedulerDateFormat.format2} />
              <WeekView {...schedulerDateFormat.format1} />
              <MonthView />
              <AgendaView {...schedulerDateFormat.format1} />
            </Scheduler>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
};

interface IScheduleItem {
  props: SchedulerItemProps;
  detailUrl: string;
}

const CustomSchedulerItem = ({ props, detailUrl }: IScheduleItem) => {
  const ref = React.useRef<any>(null);
  const [show, setShow] = React.useState(false);
  const history = useHistory();
  const handleMouseOver = React.useCallback(
    (event) => {
      setShow(true);

      if (props.onMouseOver) {
        props.onMouseOver(event);
      }
    },
    [props]
  );

  const handleMouseLeave = React.useCallback(
    (event) => {
      setShow(false);

      if (props.onMouseOut) {
        props.onMouseOut(event);
      }
    },
    [props]
  );

  const navigateToForm = () => {
    history.push(detailUrl + props.uid);
  };

  return (
    <>
      <SchedulerItem
        {...props}
        className={"cc-scheduler-item"}
        ref={ref}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onClick={navigateToForm}
      />
      <Popup
        show={show}
        anchorAlign={{
          horizontal: "left",
          vertical: "bottom",
        }}
        popupAlign={{
          horizontal: "left",
          vertical: "top",
        }}
        id="scheduler-popup"
        anchor={ref.current && ref.current.element}
      >
        <div className="cc-calendar-popup">
          <h6>{props.title}</h6>
          <div>
            <b>Start:</b>
            <span>
              {formatDateByKendo(props.start, DATETIME_FORMAT.DATETIME)}
            </span>
          </div>
          <div>
            <b>End:</b>
            <span>
              {formatDateByKendo(props.end, DATETIME_FORMAT.DATETIME)}
            </span>
          </div>
        </div>
      </Popup>
    </>
  );
};
