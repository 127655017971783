import { getDeferredDutySummaryById } from "@app/products/property/deferred-duty/[id]/api";
import { DTO_DeferredDuty_Account_Detail } from "@app/products/property/deferred-duty/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class DeferredDutyStore {
  private _deferredDuty?: DTO_DeferredDuty_Account_Detail = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };
  get deferredDuty() {
    return this._deferredDuty;
  }
  setDeferredDuty = (deferredDuty?: DTO_DeferredDuty_Account_Detail) => {
    runInAction(() => {
      this._deferredDuty = deferredDuty;
    });
  };
  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get deferredDutyAccountId() {
    return this.deferredDuty?.AccountId;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._deferredDuty = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._onSubmit = undefined;
    });
  };
  loadDeferredDuty = async (deferredDutyId: number) => {
    this.setIsLoading(true);
    const response = await getDeferredDutySummaryById(deferredDutyId);
    let errorResponse = undefined;
    let newDeferredDuty = undefined;
    if (isSuccessResponse(response)) {
      newDeferredDuty = response.data?.DeferredDutyAccountDetail;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setDeferredDuty(newDeferredDuty);
    if (newDeferredDuty && newDeferredDuty?.AlertMessage) {
      appNotificationStore.pushNotification({
        title: newDeferredDuty?.AlertMessage,
        type: "warning",
        autoClose: false,
      });
    }
    this.setIsLoading(false);
  };
  reLoadDeferredDuty = async () => {
    if (this.deferredDutyAccountId)
      await this.loadDeferredDuty(this.deferredDutyAccountId);
  };
}

const deferredDutyStoreContext = createContext(new DeferredDutyStore());
export const useDeferredDutyStore = () => {
  return useContext(deferredDutyStoreContext);
};
