import { ICCRoute } from "@common/constants/ICCRoute";

export const appealsRoute: ICCRoute = {
  path: "appeals",
  name: "Appeals",
  children: [
    {
      path: "all",
      name: "All",
      component: require("./all/_index").default,
    },
    {
      path: "current-by-status",
      name: "Current By Status",
      component: require("./current-by-status/_index").default,
    },
    {
      path: "current-by-month",
      name: "Current By Month",
      component: require("./current-by-month/_index").default,
    },
    {
      path: "hearing-calendar",
      name: "Hearing Calendar",
      component: require("./hearing-calendar/_index").default,
    },
  ],
};
