import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  DIRECT_DEBIT_SESSIONS,
  getDirectDebitSessionOdataByMenuType,
} from "@app/products/direct-debit/constant";
import { DirectDebitMenu } from "@app/products/direct-debit/model";
import { directDebitRoute } from "@app/products/direct-debit/route";
import { SessionsHistoryTab } from "@app/products/direct-debit/sessions/[id]/components/slidebar/history/_index";
import { CreateSessionButton } from "@app/products/direct-debit/sessions/components/buttons/create-session/_index";
import { colDirectDebitSession } from "@app/products/direct-debit/sessions/config";
import { DirectDebitSessionHeader } from "@app/products/direct-debit/sessions/model";
import { ddSessionsBookmark } from "@app/products/direct-debit/sessions/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { nameOfFactory } from "@common/utils/common";
import { CCActionBarNavDropdownNew } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/_index";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<DirectDebitSessionHeader>();

export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.DirectDebit_Dialog_Session,
    productType: PRODUCT_TYPE_NUMBER.DirectDebit,
  });
  const {
    getBookmarkListViewDetailRecord,
    getBookmarkListViewDetail,
    getBookmarkListViewDisplayName,
    getBookmarkRecordDisplayName,
  } = ddSessionsBookmark("By Status");

  useCCListViewActionBar({
    title: directDebitRoute.name,
    leftComponents: [<CCActionBarNavDropdownNew />],
    centerComponents: [
      <CreateSessionButton
        isDisabled={
          !checkPermissions([
            FormAction.CORE_ALLOW_NEW,
            FormAction.CORE_ALLOW_SAVE,
          ])
        }
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={DIRECT_DEBIT_SESSIONS}
        productType={PRODUCT_TYPE.DirectDebit}
        recordType={RECORDTYPE.Receipting_DirectDebitSessionHeader}
        detail={getBookmarkRecordDisplayName}
        displayName={getBookmarkListViewDetailRecord}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "History",
        component: <SessionsHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <>
        <CCProductListView
          columnFields={colDirectDebitSession}
          dataUrl={getDirectDebitSessionOdataByMenuType(
            DirectDebitMenu.SessionsByStatus
          )}
          primaryField={nameOf("Session_ID")}
          state={{
            group: [{ field: nameOf("Status_Type_ENUM"), dir: "asc" }],
            sort: [{ field: nameOf("Session_Number"), dir: "desc" }],
          }}
        />
      </>
    </LoadingPermissionWrapper>
  );
});
