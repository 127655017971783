import { DATE_FORMAT } from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { SelectNominatePropertyDialog } from "./components/dialogs/nominated-property/_index";
import { colNominateProperty, colVotingEntitlementByElection } from "./config";

export const VOTING_FORM_STEP = "Voting";

export const VotingFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const [
    showSelectNominatePropertyDialog,
    setShowSelectNominatePropertyDialog,
  ] = useState(false);
  // const [voting, setVoting] = useState<any>([]);
  const selectedVoting = getFieldValue("SelectedVoting");

  const handleGridSelectionChange = (dataItem: any, field: string) => {
    let newSelected = dataItem ? dataItem[0] : undefined;
    onChange(nameOf(field), {
      value: newSelected,
    });
    if (newSelected && field === "SelectedVoting") {
      onChange(nameOf("EffectiveDate"), {
        value: newSelected.EffectiveDate || null,
      });
      onChange(nameOf("FinishDate"), {
        value: newSelected.FinishDate || null,
      });
    }
  };

  const handleVotingInfoChange = (field: string, value: any) => {
    onChange(nameOf(field), {
      value: value,
    });
    if (selectedVoting) {
      let newVoting = getFieldValue("Voting").map((voting: any) => {
        if (voting.NominatedId !== selectedVoting.NominatedId) return voting;
        voting[field] = value;
        return voting;
      });

      onChange(nameOf("Voting"), { value: newVoting });
    }
  };
  return (
    <div className="cc-form">
      <div className="cc-field-group">
        {selectedVoting ? (
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Effective date</label>
              <Field
                name={nameOf("EffectiveDate")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                onChange={(event: DatePickerChangeEvent) => {
                  handleVotingInfoChange("EffectiveDate", event.value);
                }}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Finish date</label>
              <Field
                name={nameOf("FinishDate")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                onChange={(event: DatePickerChangeEvent) => {
                  handleVotingInfoChange("FinishDate", event.value);
                }}
              />
            </div>
          </div>
        ) : null}
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Nominate property</label>
            <CCGrid
              data={getFieldValue("Voting") || []}
              columnFields={colNominateProperty}
              selectedRows={selectedVoting ? [selectedVoting] : undefined}
              primaryField="NominatedId"
              selectableMode="single"
              onSelectionChange={(dataItem) => {
                handleGridSelectionChange(dataItem, "SelectedVoting");
              }}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-plus"
                    title="Add Nominate Property"
                    onClick={() => {
                      setShowSelectNominatePropertyDialog(true);
                      onChange(nameOf("SelectedVoting"), {
                        value: undefined,
                      });
                    }}
                  />
                  <Button
                    iconClass="fas fa-minus"
                    title="Remove Nominate Property"
                    disabled={!selectedVoting}
                    onClick={() => {
                      onChange(nameOf("Voting"), {
                        value: [],
                      });
                      onChange(nameOf("VotingEntitlementByElection"), {
                        value: [],
                      });
                      onChange(nameOf("SelectedVoting"), {
                        value: undefined,
                      });
                    }}
                  />
                </div>
              }
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Voting entitlement by election</label>
            <CCGrid
              data={getFieldValue("VotingEntitlementByElection") || []}
              columnFields={colVotingEntitlementByElection}
              primaryField="AssessmentId"
            />
          </div>
        </div>
      </div>
      {showSelectNominatePropertyDialog && (
        <SelectNominatePropertyDialog
          onClose={() => {
            setShowSelectNominatePropertyDialog(false);
          }}
          onSubmit={(dataItem) => {
            setShowSelectNominatePropertyDialog(false);
            onChange(nameOf("Voting"), {
              value: dataItem.map((item: any) => ({
                ...item,
                selected: undefined,
              })),
            });
            onChange(nameOf("VotingEntitlementByElection"), {
              value: dataItem[0].VotingEntitlementByElection.map(
                (item: any) => ({
                  ...item,
                  selected: undefined,
                })
              ),
            });
          }}
        />
      )}
    </div>
  );
};
