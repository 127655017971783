import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingSystemAdminNCItems: IColumnFields[] = [
  {
    field: BuildingFieldMapping.Category_1,
    title: "Category 1",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.Category_2,
    title: "Category 2",
  },
  {
    field: BuildingFieldMapping.ObservationSummary,
    title: "Observation Summary",
  },
  {
    field: BuildingFieldMapping.ComplianceInstruction,
    title: "Compliance Instruction",
  },
];
