import { DTO_NoticeRun_LOVs } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NoticeRunStore {
  private _noticeRunOriginLOVs?: DTO_NoticeRun_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get noticeRunOriginLOVs() {
    return toJS(this._noticeRunOriginLOVs);
  }
  setNoticeRunOriginLOVs = (lov?: DTO_NoticeRun_LOVs) => {
    runInAction(() => {
      this._noticeRunOriginLOVs = lov;
    });
  };
}

const noticeRunStoreContext = createContext(new NoticeRunStore());
export const useNoticeRunStore = () => {
  return useContext(noticeRunStoreContext);
};
