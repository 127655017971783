export enum EModePhoneNumber {
  Private = "Private",
  Business = "Business",
  WorkPhone = "WorkPhone",
  Mobile = "Mobile",
  HomePhone = "HomePhone",
  Fax = "Fax",
}

export enum EPhoneNumberFormat {
  SystemInitialise = 0,
  Default = 1,
  Format1 = 2,
}
