import { CoreAPIService } from "@common/apis/coreAPIService";
import { IResponseData } from "@common/models/service";
import { globalStoreInstance } from "@common/stores/global/store";

// TODO: Implement API
export const getContact = async (contact_id: string) => {
  const response: IResponseData = await CoreAPIService.getClient().get(
    `${globalStoreInstance.allSetting.baseAPIUrl}/api/HM/Internal/getcontactrelationship/${contact_id}`
  );
  if (!response) {
    return undefined;
  }
  return response;
};

//TODO
