import { getAnimalsInfo, postAnimal } from "@app/products/animals/[id]/api";
import {
  AnimalsLOVs,
  Registration,
  RegistrationUpdateTriggers,
} from "@app/products/animals/[id]/model";
import { getValueFromSetting } from "@app/products/animals/components/forms/components/form-element/util";
import { AnimalSettingValue } from "@app/products/animals/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { capitalizeFirstLetter } from "@common/utils/formatting";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAnimalsQuickUpdateProps {
  onClose: () => void;
  onSubmit: () => void;
  registrationId: number;
}

const nameOf = nameOfFactory<Registration>();
export const AnimalsQuickUpdateDialog = observer(
  ({ onClose, onSubmit, registrationId }: IAnimalsQuickUpdateProps) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const { settings } = useCommonCoreStore();

    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [initialData, setInitialData] = useState<Registration>();
    const [animalLOVs, setAnimalLOVs] = useState<AnimalsLOVs>();
    const [getSettingValue, loadSettingValue] = useState<AnimalSettingValue>();

    const handleOnSubmitClick = async (event: FormSubmitClickEvent) => {
      const valueSubmit = event.values as Registration;
      valueSubmit.Flag_QuickRegistration = false;
      setIsSubmitting(true);
      const response = await postAnimal(valueSubmit);
      setIsSubmitting(false);
      if (isSuccessIdentityPacket(response)) {
        onSubmit();
      } else {
        const responseError = response as APIResponse;
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: "Update registration failed.",
          type: "error",
          description: responseError.data?.Errors ?? responseError.statusText,
        });
      }
    };

    const loadAnimalsById = async () => {
      let errorResponse = undefined;
      setIsLoading(true);
      const response = await getAnimalsInfo(registrationId);
      if (Array.isArray(response)) {
        const [animalInfo, animalLOVs] = response;
        setAnimalLOVs(animalLOVs.data);
        if (animalInfo?.data?.ReturnObj?.Registration) {
          setInitialData(animalInfo?.data?.ReturnObj?.Registration);
        } else {
          errorResponse = {
            status: APIResponseStatus.INTERNAL_SERVER_ERROR,
            error: "Server error",
          };
        }
      } else {
        const responseError = response as APIResponse;
        errorResponse = {
          status: responseError.status,
          error: responseError.error,
        };
      }
      setIsLoading(false);
      setResponseLoadError(errorResponse);
    };

    useEffectOnce(() => {
      loadAnimalsById();
      loadSettingValue(getValueFromSetting(settings));
    });

    return (
      <Form
        onSubmitClick={handleOnSubmitClick}
        initialValues={initialData}
        key={JSON.stringify(initialData)}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onChange } = formRenderProps;

          const handleAnimalTypeChange = async (event: ComboBoxChangeEvent) => {
            if (event.target.value?.Key) {
              onChange(nameOf("AnimalType_ID"), {
                value: event.target.value.Key,
              });
              const saveTriggers = valueGetter(nameOf("SaveTriggers")) ?? [];
              if (
                !saveTriggers.includes(
                  RegistrationUpdateTriggers.UpdateAnimalType
                )
              ) {
                saveTriggers.push(RegistrationUpdateTriggers.UpdateAnimalType);
                onChange(nameOf("SaveTriggers"), { value: saveTriggers });
              }
            } else {
              onChange(nameOf("AnimalType_ID"), {
                value: null,
              });
            }
          };

          return (
            <FormElement>
              <CCDialog
                height="70%"
                maxWidth="40%"
                titleHeader={"Animals Registration Details"}
                onClose={onClose}
                disabled={isLoading}
                bodyElement={
                  isLoading ? (
                    <Loading isLoading={isLoading} />
                  ) : responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={async () => {
                        await loadAnimalsById();
                      }}
                    />
                  ) : (
                    <div className="cc-form">
                      <CCLocalNotification ref={notificationRef} />
                      <>
                        <section className="cc-field-group">
                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <CCLabel
                                title="Animal name"
                                isMandatory={
                                  getSettingValue?.animalsMandatoryAnimalName
                                }
                              />
                              <Field
                                name={nameOf("AnimalName")}
                                component={CCInput}
                                placeholder={"Animal name"}
                                validator={
                                  getSettingValue?.animalsMandatoryAnimalName
                                    ? requiredValidator
                                    : undefined
                                }
                              />
                            </div>
                            <CCValueField
                              label="Tag number"
                              value={valueGetter(nameOf("TagNumber"))}
                            />
                          </div>
                        </section>
                        <hr className="cc-divider" />
                        <section className="cc-field-group">
                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <CCLabel title="Animal type" isMandatory />
                              <Field
                                name={nameOf("AnimalType_ID")}
                                dataItemKey="Key"
                                textField="Value"
                                data={animalLOVs?.AnimalTypes}
                                value={getDropdownValue(
                                  valueGetter(nameOf("AnimalType_ID")),
                                  animalLOVs?.AnimalTypes
                                )}
                                onChange={handleAnimalTypeChange}
                                component={CCSearchComboBox}
                                validator={requiredValidator}
                              />
                            </div>
                          </div>
                        </section>
                        <hr className="cc-divider" />
                        <section className="cc-field-group">
                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <CCLabel title="Microchip?" isMandatory />
                              <Field
                                name={nameOf("Flag_Microchipped")}
                                defaultChecked={valueGetter(
                                  nameOf("Flag_Microchipped")
                                )}
                                component={CCSwitch}
                              />
                            </div>
                            {valueGetter(nameOf("Flag_Microchipped")) ? (
                              <div className="cc-field">
                                <CCLabel title="Microchip number" isMandatory />
                                <Field
                                  name={nameOf("MicrochipNumber")}
                                  component={CCInput}
                                  validator={requiredValidator}
                                  placeholder={"Microchip number"}
                                />
                              </div>
                            ) : (
                              <div className="cc-field">
                                <CCLabel
                                  title="Proof of exemption"
                                  isMandatory
                                />
                                <Field
                                  name={nameOf("MicrochipProof_KWD")}
                                  textField="Value"
                                  dataItemKey="Key"
                                  data={
                                    animalLOVs?.Animals_MicrochipProofExemption
                                  }
                                  isUseDefaultOnchange
                                  component={CCSearchComboBox}
                                  validator={requiredValidator}
                                />
                              </div>
                            )}
                          </div>

                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <CCLabel title="Pensioner?" isMandatory />
                              <Field
                                name={nameOf("Flag_Pensioner")}
                                defaultChecked={valueGetter(
                                  nameOf("Flag_Pensioner")
                                )}
                                component={CCSwitch}
                              />
                            </div>
                            {valueGetter(nameOf("Flag_Pensioner")) && (
                              <div className="cc-field">
                                <CCLabel title="Pension number" isMandatory />
                                <Field
                                  name={nameOf("PensionNumber")}
                                  component={CCInput}
                                  validator={requiredValidator}
                                  placeholder={"Pension number"}
                                />
                              </div>
                            )}
                            {getSettingValue?.animalsEnablePensionerExpiry && (
                              <div className="cc-field">
                                <CCLabel title="Expiry" />
                                <Field
                                  name={nameOf("Date_PensionerExpiry")}
                                  format={DATE_FORMAT.DATE_CONTROL}
                                  component={CCDatePicker}
                                />
                              </div>
                            )}
                          </div>

                          {getSettingValue?.animalsDisplayHealthCareCard && (
                            <div className="cc-form-cols-3">
                              <div className="cc-field">
                                <CCLabel
                                  title="Health care card?"
                                  isMandatory={
                                    getSettingValue?.animalsMandatoryHealthCareCard
                                  }
                                />
                                <Field
                                  name={nameOf("Flag_HealthCareCard")}
                                  defaultChecked={valueGetter(
                                    nameOf("Flag_HealthCareCard")
                                  )}
                                  component={CCSwitch}
                                  validator={
                                    getSettingValue?.animalsMandatoryHealthCareCard
                                      ? requiredValidator
                                      : undefined
                                  }
                                />
                              </div>
                            </div>
                          )}

                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <CCLabel title="Sterilised?" isMandatory />
                              <Field
                                name={nameOf("Flag_Sterilised")}
                                defaultChecked={valueGetter(
                                  nameOf("Flag_Sterilised")
                                )}
                                component={CCSwitch}
                              />
                            </div>
                            {valueGetter(nameOf("Flag_Sterilised")) && (
                              <div className="cc-field">
                                <CCLabel
                                  title="Type of proof"
                                  isMandatory={
                                    getSettingValue?.animalsMandatorySterlisedTypeOfProof
                                  }
                                />
                                <Field
                                  name={nameOf("SterlisedProof_KWD")}
                                  textField="Value"
                                  dataItemKey="Key"
                                  data={
                                    animalLOVs?.Animals_ProofOfSterilisation
                                  }
                                  isUseDefaultOnchange
                                  component={CCSearchComboBox}
                                  validator={
                                    getSettingValue?.animalsMandatorySterlisedTypeOfProof
                                      ? requiredValidator
                                      : undefined
                                  }
                                />
                              </div>
                            )}
                          </div>

                          {getSettingValue?.animalsDisplayClubMember && (
                            <div className="cc-form-cols-3">
                              <div className="cc-field">
                                <CCLabel
                                  title={
                                    capitalizeFirstLetter(
                                      getSettingValue?.animalsLabelClubMember
                                    ) ?? "Club member?"
                                  }
                                  isMandatory={
                                    getSettingValue?.animalsMandatoryClubMember
                                  }
                                />
                                <Field
                                  name={nameOf("Flag_ClubMember")}
                                  defaultChecked={valueGetter(
                                    nameOf("Flag_ClubMember")
                                  )}
                                  component={CCSwitch}
                                  validator={
                                    getSettingValue?.animalsMandatoryClubMember
                                      ? requiredValidator
                                      : undefined
                                  }
                                />
                              </div>
                              {valueGetter(nameOf("Flag_ClubMember")) && (
                                <div className="cc-field">
                                  <CCLabel title="Member number" isMandatory />
                                  <Field
                                    name={nameOf("ClubNumber")}
                                    component={CCInput}
                                    validator={requiredValidator}
                                    placeholder={"Member number"}
                                  />
                                </div>
                              )}
                            </div>
                          )}

                          {getSettingValue?.animalsDisplayObedienceTrained && (
                            <div className="cc-form-cols-3">
                              {valueGetter("AnimalType.Name") &&
                                valueGetter("AnimalType.Name")
                                  ?.toLowerCase()
                                  ?.includes("dog") && (
                                  <div className="cc-field">
                                    <CCLabel
                                      title="Obedience?"
                                      isMandatory={
                                        getSettingValue?.animalsMandatoryObedienceTrained
                                      }
                                    />
                                    <Field
                                      name={nameOf("Flag_Obedience")}
                                      defaultChecked={valueGetter(
                                        nameOf("Flag_Obedience")
                                      )}
                                      component={CCSwitch}
                                      validator={
                                        getSettingValue?.animalsMandatoryObedienceTrained
                                          ? requiredValidator
                                          : undefined
                                      }
                                    />
                                  </div>
                                )}
                              {valueGetter(nameOf("Flag_Obedience")) && (
                                <div className="cc-field">
                                  <CCLabel
                                    title="Type of proof"
                                    isMandatory={
                                      getSettingValue?.animalsMandatoryObedienceTypeOfProof
                                    }
                                  />
                                  <Field
                                    name={nameOf("ObedienceProof_KWD")}
                                    textField="Value"
                                    dataItemKey="Key"
                                    data={animalLOVs?.Animals_ProofOfObedience}
                                    isUseDefaultOnchange
                                    component={CCSearchComboBox}
                                    validator={
                                      getSettingValue?.animalsMandatoryObedienceTypeOfProof
                                        ? requiredValidator
                                        : undefined
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          )}

                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <CCLabel
                                title={
                                  capitalizeFirstLetter(
                                    getSettingValue?.animalsLabelDangerous
                                  ) ?? "Dangerous?"
                                }
                                isMandatory={
                                  getSettingValue?.animalsMandatoryDangerous
                                }
                              />
                              <Field
                                name={nameOf("Flag_Dangerous")}
                                defaultChecked={valueGetter(
                                  nameOf("Flag_Dangerous")
                                )}
                                component={CCSwitch}
                                validator={
                                  getSettingValue?.animalsMandatoryDangerous
                                    ? requiredValidator
                                    : undefined
                                }
                              />
                            </div>
                          </div>

                          {getSettingValue?.animalsDisplayMenacing && (
                            <div className="cc-form-cols-3">
                              <div className="cc-field">
                                <CCLabel
                                  title={
                                    capitalizeFirstLetter(
                                      getSettingValue?.animalsLabelMenacing
                                    ) ?? "Menacing?"
                                  }
                                  isMandatory={
                                    getSettingValue?.animalsMandatoryMenacing
                                  }
                                />
                                <Field
                                  name={nameOf("Flag_Menacing")}
                                  defaultChecked={valueGetter(
                                    nameOf("Flag_Menacing")
                                  )}
                                  component={CCSwitch}
                                  validator={
                                    getSettingValue?.animalsMandatoryMenacing
                                      ? requiredValidator
                                      : undefined
                                  }
                                />
                              </div>
                            </div>
                          )}

                          {getSettingValue?.animalsDisplayOrganisation && (
                            <div className="cc-form-cols-3">
                              <div className="cc-field">
                                <CCLabel
                                  title={
                                    capitalizeFirstLetter(
                                      getSettingValue?.animalsLabelOrganisation
                                    ) ?? "Organisation?"
                                  }
                                  isMandatory={
                                    getSettingValue?.animalsMandatoryOrganisation
                                  }
                                />
                                <Field
                                  name={nameOf("Flag_Organisation")}
                                  defaultChecked={valueGetter(
                                    nameOf("Flag_Organisation")
                                  )}
                                  component={CCSwitch}
                                  validator={
                                    getSettingValue?.animalsMandatoryOrganisation
                                      ? requiredValidator
                                      : undefined
                                  }
                                />
                              </div>
                            </div>
                          )}

                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <CCLabel
                                title={
                                  capitalizeFirstLetter(
                                    getSettingValue?.animalsLabelGuideDogAssistance
                                  ) ?? "Guide/Assistance dog?"
                                }
                                isMandatory={
                                  getSettingValue?.animalsMandatoryGuideDogAssistance
                                }
                              />
                              <Field
                                name={nameOf("Flag_GuideDog")}
                                defaultChecked={valueGetter(
                                  nameOf("Flag_GuideDog")
                                )}
                                component={CCSwitch}
                                validator={
                                  getSettingValue?.animalsMandatoryGuideDogAssistance
                                    ? requiredValidator
                                    : undefined
                                }
                              />
                            </div>
                            {valueGetter(nameOf("Flag_GuideDog")) && (
                              <div className="cc-field">
                                <CCLabel
                                  title="Type of proof"
                                  isMandatory={
                                    getSettingValue?.animalsMandatoryGuideDogTypeOfProof
                                  }
                                />
                                <Field
                                  name={nameOf("GuideOrAssistanceDogProof_KWD")}
                                  textField="Value"
                                  dataItemKey="Key"
                                  data={
                                    animalLOVs?.Animals_ProofOfSterilisation
                                  }
                                  isUseDefaultOnchange
                                  component={CCSearchComboBox}
                                  validator={
                                    getSettingValue?.animalsMandatoryGuideDogTypeOfProof
                                      ? requiredValidator
                                      : undefined
                                  }
                                />
                              </div>
                            )}
                          </div>

                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <CCLabel
                                title={
                                  capitalizeFirstLetter(
                                    getSettingValue?.animalsLabelWorking
                                  ) ?? "Working?"
                                }
                                isMandatory={
                                  getSettingValue?.animalsMandatoryWorking
                                }
                              />
                              <Field
                                name={nameOf("Flag_Farm")}
                                defaultChecked={valueGetter(
                                  nameOf("Flag_Farm")
                                )}
                                component={CCSwitch}
                                validator={
                                  getSettingValue?.animalsMandatoryWorking
                                    ? requiredValidator
                                    : undefined
                                }
                              />
                            </div>
                            {valueGetter(nameOf("Flag_Farm")) && (
                              <div className="cc-field">
                                <CCLabel
                                  title="Type of proof"
                                  isMandatory={
                                    getSettingValue?.animalsMandatoryWorkingTypeOfProof
                                  }
                                />
                                <Field
                                  name={nameOf("WorkingProof_KWD")}
                                  textField="Value"
                                  dataItemKey="Key"
                                  data={
                                    animalLOVs?.Animals_ProofOfSterilisation
                                  }
                                  isUseDefaultOnchange
                                  component={CCSearchComboBox}
                                  validator={
                                    getSettingValue?.animalsMandatoryWorkingTypeOfProof
                                      ? requiredValidator
                                      : undefined
                                  }
                                />
                              </div>
                            )}
                          </div>

                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <CCLabel
                                title="Customs dog?"
                                isMandatory={
                                  getSettingValue?.animalsMandatoryCustomsDog
                                }
                              />
                              <Field
                                name={nameOf("Flag_Customs")}
                                defaultChecked={valueGetter(
                                  nameOf("Flag_Customs")
                                )}
                                component={CCSwitch}
                                validator={
                                  getSettingValue?.animalsMandatoryCustomsDog
                                    ? requiredValidator
                                    : undefined
                                }
                              />
                            </div>
                            {valueGetter(nameOf("Flag_Customs")) && (
                              <div className="cc-field">
                                <CCLabel
                                  title="Type of proof"
                                  isMandatory={
                                    getSettingValue?.animalsMandatoryCustomsDogTypeOfProof
                                  }
                                />
                                <Field
                                  name={nameOf("CustomsDogProof_KWD")}
                                  textField="Value"
                                  dataItemKey="Key"
                                  data={
                                    animalLOVs?.Animals_ProofOfSterilisation
                                  }
                                  isUseDefaultOnchange
                                  component={CCSearchComboBox}
                                  validator={
                                    getSettingValue?.animalsMandatoryCustomsDogTypeOfProof
                                      ? requiredValidator
                                      : undefined
                                  }
                                />
                              </div>
                            )}
                          </div>

                          {getSettingValue?.animalsMandatoryPurebreed && (
                            <div className="cc-form-cols-3">
                              <div className="cc-field">
                                <CCLabel
                                  title={
                                    capitalizeFirstLetter(
                                      getSettingValue?.animalsLabelPureBreed
                                    ) ?? "Pure breed?"
                                  }
                                  isMandatory={
                                    getSettingValue?.animalsMandatoryPurebreed
                                  }
                                />
                                <Field
                                  name={nameOf("IsPureBreed")}
                                  defaultChecked={valueGetter(
                                    nameOf("IsPureBreed")
                                  )}
                                  component={CCSwitch}
                                  validator={
                                    getSettingValue?.animalsMandatoryPurebreed
                                      ? requiredValidator
                                      : undefined
                                  }
                                />
                              </div>
                              {valueGetter(nameOf("IsPureBreed")) && (
                                <div className="cc-field">
                                  <CCLabel
                                    title="Type of proof"
                                    isMandatory={
                                      getSettingValue?.animalsMandatoryPureBreedTypeOfProof
                                    }
                                  />
                                  <Field
                                    name={nameOf("PureBreedProof_KWD")}
                                    textField="Value"
                                    dataItemKey="Key"
                                    data={
                                      animalLOVs?.Animals_ProofOfSterilisation
                                    }
                                    isUseDefaultOnchange
                                    component={CCSearchComboBox}
                                    validator={
                                      getSettingValue?.animalsMandatoryPureBreedTypeOfProof
                                        ? requiredValidator
                                        : undefined
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          )}

                          {getSettingValue?.animalsDisplayGuardDog && (
                            <div className="cc-form-cols-3">
                              <div className="cc-field">
                                <CCLabel
                                  title="Guard dog?"
                                  isMandatory={
                                    getSettingValue?.animalsMandatoryGuardDog
                                  }
                                />
                                <Field
                                  name={nameOf("IsGuardDog")}
                                  defaultChecked={valueGetter(
                                    nameOf("IsGuardDog")
                                  )}
                                  component={CCSwitch}
                                  validator={
                                    getSettingValue?.animalsMandatoryGuardDog
                                      ? requiredValidator
                                      : undefined
                                  }
                                />
                              </div>
                              {valueGetter(nameOf("IsGuardDog")) && (
                                <div className="cc-field">
                                  <CCLabel
                                    title="Type of proof"
                                    isMandatory={
                                      getSettingValue?.animalsMandatoryGuardDogTypeOfProof
                                    }
                                  />
                                  <Field
                                    name={nameOf("GuardDogProof_KWD")}
                                    textField="Value"
                                    dataItemKey="Key"
                                    data={
                                      animalLOVs?.Animals_ProofOfSterilisation
                                    }
                                    isUseDefaultOnchange
                                    component={CCSearchComboBox}
                                    validator={
                                      getSettingValue?.animalsMandatoryGuardDogTypeOfProof
                                        ? requiredValidator
                                        : undefined
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          )}

                          {getSettingValue?.animalsDisplayDogHomeAdoption && (
                            <div className="cc-form-cols-3">
                              <div className="cc-field">
                                <CCLabel
                                  title="Dog home adoption?"
                                  isMandatory={
                                    getSettingValue?.animalsMandatoryDogHomeAdoption
                                  }
                                />
                                <Field
                                  name={nameOf("IsDogHomeAdoption")}
                                  defaultChecked={valueGetter(
                                    nameOf("IsDogHomeAdoption")
                                  )}
                                  component={CCSwitch}
                                  validator={
                                    getSettingValue?.animalsMandatoryDogHomeAdoption
                                      ? requiredValidator
                                      : undefined
                                  }
                                />
                              </div>
                              {valueGetter(nameOf("IsDogHomeAdoption")) && (
                                <div className="cc-field">
                                  <CCLabel
                                    title="Type of proof"
                                    isMandatory={
                                      getSettingValue?.animalsMandatoryDogHomeAdoptionTypeOfProof
                                    }
                                  />
                                  <Field
                                    name={nameOf("DogHomeAdoptionProof_KWD")}
                                    textField="Value"
                                    dataItemKey="Key"
                                    data={
                                      animalLOVs?.Animals_ProofOfSterilisation
                                    }
                                    isUseDefaultOnchange
                                    component={CCSearchComboBox}
                                    validator={
                                      getSettingValue?.animalsMandatoryDogHomeAdoptionTypeOfProof
                                        ? requiredValidator
                                        : undefined
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          )}

                          {getSettingValue?.animalsDisplayHuntingdog && (
                            <div className="cc-form-cols-3">
                              <div className="cc-field">
                                <CCLabel
                                  title={
                                    capitalizeFirstLetter(
                                      getSettingValue?.animalsLabelHuntingDog
                                    ) ?? "Hunting dog?"
                                  }
                                  isMandatory={
                                    getSettingValue?.animalsMandatoryHuntingdog
                                  }
                                />
                                <Field
                                  name={nameOf("IsHuntingDog")}
                                  defaultChecked={valueGetter(
                                    nameOf("IsHuntingDog")
                                  )}
                                  component={CCSwitch}
                                  validator={
                                    getSettingValue?.animalsMandatoryHuntingdog
                                      ? requiredValidator
                                      : undefined
                                  }
                                />
                              </div>
                              {valueGetter(nameOf("IsHuntingDog")) && (
                                <div className="cc-field">
                                  <CCLabel
                                    title="Type of proof"
                                    isMandatory={
                                      getSettingValue?.animalsMandatoryHuntingDogTypeOfProof
                                    }
                                  />
                                  <Field
                                    name={nameOf("HuntingDogProof_KWD")}
                                    textField="Value"
                                    dataItemKey="Key"
                                    data={
                                      animalLOVs?.Animals_ProofOfSterilisation
                                    }
                                    isUseDefaultOnchange
                                    component={CCSearchComboBox}
                                    validator={
                                      getSettingValue?.animalsMandatoryHuntingDogTypeOfProof
                                        ? requiredValidator
                                        : undefined
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          )}

                          {getSettingValue?.animalsDisplayGreyhound && (
                            <div className="cc-form-cols-3">
                              <div className="cc-field">
                                <CCLabel
                                  title={
                                    capitalizeFirstLetter(
                                      getSettingValue?.animalsLabelGreyhound
                                    ) ?? "Greyhound?"
                                  }
                                  isMandatory={
                                    getSettingValue?.animalsMandatoryGreyhound
                                  }
                                />
                                <Field
                                  name={nameOf("IsGreyhound")}
                                  defaultChecked={valueGetter(
                                    nameOf("IsGreyhound")
                                  )}
                                  component={CCSwitch}
                                  validator={
                                    getSettingValue?.animalsMandatoryGreyhound
                                      ? requiredValidator
                                      : undefined
                                  }
                                />
                              </div>
                              {valueGetter(nameOf("IsGreyhound")) && (
                                <div className="cc-field">
                                  <CCLabel
                                    title="Type of proof"
                                    isMandatory={
                                      getSettingValue?.animalsMandatoryGreyhoundTypeOfProof
                                    }
                                  />
                                  <Field
                                    name={nameOf("GreyhoundProof_KWD")}
                                    textField="Value"
                                    dataItemKey="Key"
                                    data={
                                      animalLOVs?.Animals_ProofOfSterilisation
                                    }
                                    isUseDefaultOnchange
                                    component={CCSearchComboBox}
                                    validator={
                                      getSettingValue?.animalsMandatoryGreyhoundTypeOfProof
                                        ? requiredValidator
                                        : undefined
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          )}

                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <CCLabel title="Breeding cat" />
                              <Field
                                name={nameOf("IsBreedingCat")}
                                defaultChecked={valueGetter(
                                  nameOf("IsBreedingCat")
                                )}
                                component={CCSwitch}
                              />
                            </div>
                          </div>

                          {getSettingValue?.animalsEnableTransfer && (
                            <div className="cc-form-cols-3">
                              <div className="cc-field">
                                <CCLabel title="Transferred?" />
                                <Field
                                  name={nameOf("IsTransferred")}
                                  defaultChecked={valueGetter(
                                    nameOf("IsTransferred")
                                  )}
                                  component={CCSwitch}
                                />
                              </div>
                            </div>
                          )}
                          <br />
                        </section>
                      </>
                    </div>
                  )
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button className={"cc-dialog-button"} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      disabled={
                        !formRenderProps.valid || isLoading || isSubmitting
                      }
                      onClick={formRenderProps.onSubmit}
                      iconClass={isSubmitting ? "fas fa-spinner fa-spin" : ""}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
