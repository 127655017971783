import { IMemoDialogData } from "@app/products/property/meters/[id]/components/action-bar/dialogs/memo/model";

export const mockAddMemo: IMemoDialogData = {
  Category: "General",
  Memo: null,
  MemoId: 1,
  ChangedOn: new Date(),
  ChangedBy: "Derin Davis",
  Date: new Date(),
  _option: {
    Category: {
      Data: [
        { Key: 1, Value: "General" },
        { Key: 2, Value: "Item 1" },
        { Key: 3, Value: "Item 3" },
      ],
    },
  },
};
