import { CoreAPIService } from "@/common/apis/coreAPIService";
import { apiCoreRelatedRecords } from "@app/core/components/common/utils";
import { RelatedRecord } from "@app/core/related/related-dialog/model";
import { APIResponse } from "@common/apis/model";

export const saveRelatedRecords = async (
  data?: RelatedRecord[]
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post(apiCoreRelatedRecords(), data);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
