import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { IGenerateEventsColumnsBySettingProps } from "@app/products/crms/config";
import { CS_ViewMode } from "@app/products/crms/councillor-events/model";
import { RECORDSSYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { AssetsSystem } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/assets/model";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

const nameOf = nameOfFactory<EventView>();

interface IGenerateCouncillorEventViewsBySettingProps
  extends IGenerateEventsColumnsBySettingProps {
  listGroup?: string[];
}
export const generateCouncillorEventViewsBySetting = ({
  settings,
  defaultConfig = [],
  listGroup = [],
}: IGenerateCouncillorEventViewsBySettingProps) => {
  if (!settings) return defaultConfig;

  const councillorViewMode = getNumberValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Event_CouncillorViewMode]
  );
  const isCouncillorMyEventDisplayClosed = getBoolValueSetting(
    settings[
      ECorporateSettingsField.CUSTOMERSERVICE_CouncillorMyEventsDisplayClosed
    ]
  );
  const recordsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
  );
  const assetsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_AssetsSystem]
  );

  let newConfig: IColumnFields[] = cloneDeep(defaultConfig);

  return newConfig.filter((col) => {
    // Show column when this column is grouped
    if (listGroup.includes(col.field)) {
      return true;
    }

    switch (col.field) {
      case nameOf("Ward"):
      case nameOf("Source"):
      case nameOf("Category"):
      case nameOf("RequestedBy"):
      case nameOf("ContactDetails"):
      case nameOf("DueStatus"):
      case nameOf("RecordedBy"):
      case nameOf("OrgStructure"):
      case nameOf("ActionOfficer"):
      case nameOf("Coordinator"):
      case nameOf("DateWork"):
      case nameOf("Event_ReferenceNoAdditional"):
        if (councillorViewMode === CS_ViewMode.Simple) {
          return false;
        }
        return true;
      case nameOf("Status"):
        if (
          councillorViewMode === CS_ViewMode.Simple &&
          !isCouncillorMyEventDisplayClosed
        ) {
          return false;
        }
        return true;
      case nameOf("EnquiryRefNo"):
        if (councillorViewMode === CS_ViewMode.Simple) {
          return false;
        } else if (assetsSystem !== AssetsSystem.None) {
          return true;
        }
        return false;
      case nameOf("FileNumber"):
        if (councillorViewMode === CS_ViewMode.Simple) {
          return false;
        } else if (recordsSystem !== RECORDSSYSTEM.None) {
          return true;
        }
        return false;
      default:
        return true;
    }
  });
};
