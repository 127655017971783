import { eventEmitter } from "@/App";
import { isSuccessResponse } from "@common/apis/util";
import { putMarkToPublish } from "@common/pages/settings/lookups/keywords/components/action-bar/buttons/mark-to-publish/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

interface MarkToPublishKeywordButtonProps {
  disabled?: boolean;
}
export const MarkToPublishKeywordButton = observer(
  ({ disabled }: MarkToPublishKeywordButtonProps) => {
    const { gridSelectedIds } = useCCProductListViewStore();
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();

    const handleOnClick = async () => {
      const resUpdateStatus = await putMarkToPublish(gridSelectedIds);
      if (isSuccessResponse(resUpdateStatus)) {
        clearErrorNotification();
        eventEmitter.emit(CCGridEventType.RefreshOData);
        if (isEmpty(resUpdateStatus?.data?.Notifications)) {
          pushNotification({
            type: "success",
            title: "Marked to publish successfully",
          });
        } else {
          pushNotification({
            type: "warning",
            description: resUpdateStatus?.data?.Notifications,
            autoClose: false,
          });
        }
      } else {
        pushNotification({
          title: "Mark to publish update failed",
          type: "error",
          autoClose: false,
          description:
            resUpdateStatus.data?.Errors ?? resUpdateStatus.statusText,
        });
      }
    };
    return (
      <CCNavButton
        title="Mark to Publish"
        disabled={isEmpty(gridSelectedIds) || disabled}
        onClick={handleOnClick}
      />
    );
  }
);
