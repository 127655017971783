import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const forgotPassword = async (
  email: string
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().put<any>(
      `api/core/internal/forgotpassword`,
      {
        UserName: "",
        EmailAddress: email,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
