import { NEW_SUPPLEMENTARY_FORM_STEP } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/_index";
import { SupplementaryMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { JournalStatus } from "@app/products/property/journals/model";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  RadioGroup,
  RadioGroupChangeEvent,
} from "@progress/kendo-react-inputs";
import React from "react";
import { mockAuthorised, mockJournalType, mockSupplementary } from "./mock";

const dataJournalStatus = [
  {
    label: "Yet to be authorised, proposed transactions",
    value: JournalStatus.TobeAuthorised,
  },
  {
    label: "Incomplete, proposed transactions",
    value: JournalStatus.Incomplete,
  },
  {
    label: "Authorised, active transactions",
    value: JournalStatus.Authorised,
  },
];
const dataWhichSupplementary = [
  {
    label: "None",
    value: SupplementaryMode.None,
  },
  { label: "New supplementary", value: SupplementaryMode.CreateNew },
  {
    label: "Existing supplementary",
    value: SupplementaryMode.SelectFromList,
  },
];

export const NEW_JOURNAL_FORM_STEP = "NewJournal";
export const NewJournalFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  setStepsVisible,
  formRenderProps,
  nameOf,
  options = {
    showStatusField: true,
    showWhichSupplementary: true,
    isReadOnly: false,
  },
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const isShowExistingSupp =
    getFieldValue("WhichSupplementary") === SupplementaryMode.SelectFromList;

  return (
    <section className="cc-field-group">
      <div className="cc-field">
        <label className="cc-label">Journal type</label>
        <Field
          name={nameOf("Journal_Type")}
          component={CCSearchComboBox}
          textField="Name"
          dataItemKey="Code"
          data={mockJournalType}
          isUseDefaultOnchange
          disabled={options.isReadOnly}
        />
      </div>
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <label className="cc-label">Journal number (provisional)</label>
          <Field
            name={nameOf("Number")}
            readOnly
            component={CCInput}
            placeholder="Journal number"
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Reference</label>
          <Field
            name={nameOf("Reference")}
            component={CCInput}
            placeholder="Reference"
            readOnly={options.isReadOnly}
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Description</label>
          <Field
            name={nameOf("Description")}
            placeholder="Description"
            component={CCInput}
            readOnly={options.isReadOnly}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Narration</label>
          <Field
            name={nameOf("Narration")}
            placeholder="Narration"
            rows={4}
            component={CCTextArea}
            readOnly={options.isReadOnly}
          />
        </div>
        {options.showWhichSupplementary ? (
          <div className="cc-field">
            <label className="cc-label">Which supplementary</label>
            <Field
              name={nameOf("WhichSupplementary")}
              component={RadioGroup}
              disabled={options.isReadOnly}
              data={dataWhichSupplementary}
              onChange={(e: RadioGroupChangeEvent) => {
                onChange(nameOf("WhichSupplementary"), {
                  value: e.value,
                });
                if (setStepsVisible) {
                  setStepsVisible([
                    {
                      key: NEW_SUPPLEMENTARY_FORM_STEP,
                      visible: e.value === SupplementaryMode.CreateNew,
                      isClearData: true,
                    },
                  ]);
                }
              }}
            />
          </div>
        ) : null}

        {isShowExistingSupp ? (
          <div className="cc-field">
            <label className="cc-label">Supplementary</label>
            <Field
              name={nameOf("Supplementary")}
              filterable={true}
              suggest={true}
              textField="Name"
              validator={requiredValidator}
              placeholder="Enter supplementary"
              component={CCComboBox}
              disabled={options.isReadOnly}
              data={mockSupplementary}
            />
          </div>
        ) : null}
        {options.showStatusField ? (
          <div className="cc-field">
            <label className="cc-label">Status for journal</label>
            <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
              <Field
                name={nameOf("Status")}
                data={dataJournalStatus}
                component={RadioGroup}
                disabled={options.isReadOnly}
              />
              <div className="cc-field">
                <label className="cc-label">Authorised by</label>
                <Field
                  name={nameOf("AuthorisedBy")}
                  filterable={true}
                  textField="Authorised"
                  data={mockAuthorised}
                  defaultValue={mockAuthorised[0]}
                  placeholder="Enter authorised"
                  component={CCComboBox}
                  disabled={options.isReadOnly}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
};
