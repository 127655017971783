import {
  AnimalType,
  NonRegistrationFeeRule,
  RegistrationFeeAdditionalChargeRule,
  RegistrationFeeRule,
} from "@app/products/animals/model";
import {
  NonRegistrationFeeRuleList,
  RegistrationFeeAdditionalChargeList,
  RegistrationFeeRuleList,
  RegistrationFeeRulesType,
} from "@app/products/animals/system-admin/animal-types/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

// Get column config of registration fee rules
export const nameOfRegistrationFeeRules =
  nameOfFactory<RegistrationFeeRuleList>();

export const getColRegistrationFeeRules = (
  setupRegistrationFeeRulesDialog: (
    feeRuleType: RegistrationFeeRulesType,
    existedFeeRuleItem?: RegistrationFeeRule
  ) => Promise<void>,
  animalTypeFormObject: AnimalType
) => {
  const colRegistrationFeeRules: IColumnFields[] = [
    {
      field: nameOfRegistrationFeeRules("RuleType_Name"),
      title: "Rule",
      handleOnClick: (feeRuleItem: RegistrationFeeRuleList) => {
        if (
          animalTypeFormObject?.FeeRulesList?.RegistrationFeeRules?.length > 0
        ) {
          const existedFeeRuleItem =
            animalTypeFormObject.FeeRulesList.RegistrationFeeRules.find(
              (feeRule: RegistrationFeeRule) =>
                feeRule.RuleType_ENUM === feeRuleItem.RuleType_Enum
            );
          setupRegistrationFeeRulesDialog(
            RegistrationFeeRulesType.RegistrationFeeRules,
            existedFeeRuleItem
          );
        }
      },
    },
    {
      field: nameOfRegistrationFeeRules("SortOrder"),
      title: "Sort",
    },
    {
      field: nameOfRegistrationFeeRules("Description"),
      title: "Description",
      isGrow: true,
    },
  ];
  return colRegistrationFeeRules;
};

// Get column config of registration fee additional charge rules
export const nameOfRegistrationFeeAdditionalChargeList =
  nameOfFactory<RegistrationFeeAdditionalChargeList>();

export const getColRegistrationFeeAdditionalChargeRules = (
  setupRegistrationFeeAdditionalChargeRulesDialog: (
    feeRuleType: RegistrationFeeRulesType,
    existedFeeRuleItem?: RegistrationFeeAdditionalChargeRule
  ) => Promise<void>,
  animalTypeFormObject: AnimalType
) => {
  const colRegistrationFeeAdditionalChargeList: IColumnFields[] = [
    {
      field: nameOfRegistrationFeeAdditionalChargeList("RuleType_Name"),
      title: "Rule",
      handleOnClick: (feeRuleItem: RegistrationFeeAdditionalChargeList) => {
        if (
          animalTypeFormObject?.FeeRulesList
            ?.RegistrationFeeAdditionalChargeRules?.length > 0
        ) {
          const existedFeeRuleItem =
            animalTypeFormObject.FeeRulesList.RegistrationFeeAdditionalChargeRules.find(
              (feeRule: RegistrationFeeAdditionalChargeRule) =>
                feeRule.AdditionalChargeRuleType_ENUM ===
                feeRuleItem.RuleType_Enum
            );
          setupRegistrationFeeAdditionalChargeRulesDialog(
            RegistrationFeeRulesType.RegistrationFeeAdditionalChargeRules,
            existedFeeRuleItem
          );
        }
      },
    },
    {
      field: nameOfRegistrationFeeAdditionalChargeList("Description"),
      title: "Description",
      isGrow: true,
    },
  ];
  return colRegistrationFeeAdditionalChargeList;
};

// Get column config of non registration fee rules
export const nameOfNonRegistrationFeeRuleList =
  nameOfFactory<NonRegistrationFeeRuleList>();

export const getColNonRegistrationFeeRules = (
  setupNonRegistrationFeeRulesDialog: (
    feeRuleType: RegistrationFeeRulesType,
    existedFeeRuleItem?: NonRegistrationFeeRule
  ) => Promise<void>,
  animalTypeFormObject: AnimalType
) => {
  const colNonRegistrationFeeRuleList: IColumnFields[] = [
    {
      field: nameOfNonRegistrationFeeRuleList("RuleType_Name"),
      title: "Rule",
      handleOnClick: (feeRuleItem: NonRegistrationFeeRuleList) => {
        if (
          animalTypeFormObject?.FeeRulesList?.NonRegistrationFeeRules?.length >
          0
        ) {
          const existedFeeRuleItem =
            animalTypeFormObject.FeeRulesList.NonRegistrationFeeRules.find(
              (feeRule: NonRegistrationFeeRule) =>
                feeRule.NonRegistrationFeeRuleType_ENUM ===
                feeRuleItem.RuleType_Enum
            );
          setupNonRegistrationFeeRulesDialog(
            RegistrationFeeRulesType.NonRegistrationFeeRules,
            existedFeeRuleItem
          );
        }
      },
    },
    {
      field: nameOfNonRegistrationFeeRuleList("Description"),
      title: "Description",
      isGrow: true,
    },
  ];
  return colNonRegistrationFeeRuleList;
};
