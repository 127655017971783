import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ImportLeaseTransferButton } from "@app/products/property/changes-of-ownership/lease-transfers/components/action-bar/buttons/import-lease-transfer/_index";
import { UpdateLeaseTransferButton } from "@app/products/property/changes-of-ownership/lease-transfers/components/action-bar/buttons/update-lease-transfers/_index";
import { DetailComponent } from "@app/products/property/changes-of-ownership/lease-transfers/components/detail/_index";
import { ChangeOfOwnershipLeaseTransfersDetailTab } from "@app/products/property/changes-of-ownership/lease-transfers/components/reference-sidebar/detail/_index";
import { colListByImportFile } from "@app/products/property/changes-of-ownership/lease-transfers/list-by-import-file/config";
import { CHANGOFOWNERSHIP_LEASETRANSFERS_LISTBYIMPORTFILE_LIST_VIEW_URL } from "@app/products/property/changes-of-ownership/lease-transfers/list-by-import-file/constant";
import { VO_ACT_Lease_Transfer } from "@app/products/property/changes-of-ownership/lease-transfers/model";
import { changesOfOwnershipRoute } from "@app/products/property/changes-of-ownership/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { LeaseTransfersSuspendButton } from "@app/products/property/changes-of-ownership/lease-transfers/components/action-bar/buttons/suspend/_index";
import { LeaseTransfersCancelButton } from "@app/products/property/changes-of-ownership/lease-transfers/components/action-bar/buttons/cancel/_index";

const nameOf = nameOfFactory<VO_ACT_Lease_Transfer>();
export default () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [colsConfigured, setColsConfigured] = useState<IColumnFields[]>([]);

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={changesOfOwnershipRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <ImportLeaseTransferButton />
        <UpdateLeaseTransferButton />
        <LeaseTransfersSuspendButton />
        <LeaseTransfersCancelButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <ChangeOfOwnershipLeaseTransfersDetailTab />,
      },
    ],
  });

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.ChangeOfOwnership_LeaseTransfers_ListByImportFile,
      colListByImportFile
    );
    if (Array.isArray(response)) {
      setColsConfigured(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <CCProductListView
      columnFields={colsConfigured}
      dataUrl={CHANGOFOWNERSHIP_LEASETRANSFERS_LISTBYIMPORTFILE_LIST_VIEW_URL}
      primaryField={nameOf("ACT_Lease_Transfer_Id")}
      detail={DetailComponent}
    />
  );
};
