import { PoundRegistrationForm } from "@app/products/animals/pound-register/[id]/components/child-screens/general/_index";
import { PoundRegisterSubmitActions } from "@app/products/animals/pound-register/[id]/model";
import { useAnimalPoundRegisterStore } from "@app/products/animals/pound-register/[id]/store";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { IPermissionsWrapperProps } from "@common/hocs/permissions/model";
import { renderWithPermissionsWrapper } from "@common/hocs/permissions/render-with-permission-wrapper";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const NewManageAnimalPoundRegisterComponent = observer(() => {
  const {
    poundRegisterInfo,
    isLoading,
    isLoadingSuperAdmin,
    responseLoadError,
    loadPoundRegisterInfo,
    onSubmit,
  } = useAnimalPoundRegisterStore();
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <FormNavigation title={"Pound Register"} />
      <Loading isLoading={isLoading || isLoadingSuperAdmin} isFullScreen />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadPoundRegisterInfo(parseInt(id), true)}
        />
      ) : (
        <div className="cc-new-pound-register">
          <FormTitle title="New Pound Register" />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                name={PoundRegisterSubmitActions.New}
                onClick={onSubmit}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {poundRegisterInfo && <PoundRegistrationForm />}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

const renderProps: IPermissionsWrapperProps = {
  component: NewManageAnimalPoundRegisterComponent,
  permissions: { productType: PRODUCT_TYPE_NUMBER.Animals },
  useNewPermissionDataCreator: (props: IPermissionsWrapperProps) => {
    return {
      formIdentifier: FormIdentifier.Animals_Form_PoundRegister,
      productType: props.permissions.productType,
      formAction: FormAction.CORE_ALLOW_NEW,
    };
  },
};

export const NewManageAnimalPoundRegister =
  renderWithPermissionsWrapper(renderProps);
