import { CreateSessionDialog } from "@app/products/property/journals/list/components/dialogs/create-session/_index";
import { JournalStatusName } from "@app/products/property/journals/model";
import { DTO_Journal_Summary } from "@app/products/property/journals/[id]/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";

export const CreateSessionButton = observer(() => {
  const { isEmptyGridSelectedRow, gridSelectedRows } =
    useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();
  const [showDialog, setShowDialog] = useState(false);

  const selectedJournalError = useCallback(
    (errorTitle: string) => {
      setShowDialog(false);
      pushNotification({
        title: errorTitle,
        type: "error",
        autoClose: false,
      });
    },
    [pushNotification]
  );

  const validateSelectedJournal = useCallback(() => {
    let errorTitle: string = "";
    const usedJournals: number[] = [];

    for (let item of gridSelectedRows) {
      const selectedItem: DTO_Journal_Summary = item;

      if (selectedItem.Journal_Status_Name !== JournalStatusName.Authorised) {
        selectedJournalError("All journals selected must be approved.");
        return;
      }
      if (!isNil(selectedItem.Session_Number)) {
        usedJournals.push(selectedItem.Journal_Number);
      }
    }

    if (usedJournals.length === 1) {
      errorTitle = `Journal ID ${usedJournals[0]} has been used.`;
    } else if (usedJournals.length > 1) {
      errorTitle = `Journal IDs ${usedJournals
        .sort()
        .join(", ")} have been used.`;
    }

    if (!isEmpty(errorTitle)) {
      selectedJournalError(errorTitle);
    } else {
      setShowDialog(true);
    }
  }, [gridSelectedRows, selectedJournalError]);

  return (
    <>
      <CCNavButton
        disabled={isEmptyGridSelectedRow}
        title="Create Session"
        onClick={validateSelectedJournal}
      />
      {showDialog && (
        <CreateSessionDialog onCloseDialog={() => setShowDialog(false)} />
      )}
    </>
  );
});
