import { history } from "@/AppRoutes";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { LOCAL_LAWS_MANAGE_ROUTE } from "@app/products/local-laws/[id]/constant";
import { LocalLawsActionBarNavDropdown } from "@app/products/local-laws/components/toolbar/nav-dropdown/_index";
import { localLawsRoute } from "@app/products/local-laws/route";
import { LOCAL_LAWS_SYSTEM_ADMIN_PERMIT_TYPES_ROUTE } from "@app/products/local-laws/system-admin/permit-types/[id]/constant";
import { PermitTypeContactsTab } from "@app/products/local-laws/system-admin/permit-types/components/sidebar/contacts/_index";
import { PermitTypeDetailsTab } from "@app/products/local-laws/system-admin/permit-types/components/sidebar/details/_index";
import { PermitTypeHistoryTab } from "@app/products/local-laws/system-admin/permit-types/components/sidebar/history/_index";
import { PERMIT_TYPES_URL } from "@app/products/local-laws/system-admin/permit-types/constant";
import { Svc_LLPermitType } from "@app/products/local-laws/system-admin/permit-types/model";
import { llSystemAdminPermitTypesBookmark } from "@app/products/local-laws/system-admin/permit-types/util";
import { systemAdminRoute } from "@app/products/local-laws/system-admin/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";
import { colLocalLawsSystemAdminPermitTypes } from "./config";

const nameOf = nameOfFactory<Svc_LLPermitType>();
export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.LLPermits_Form_PermitType,
    productType: PRODUCT_TYPE_NUMBER.LLPermits,
  });

  // Implement later - Use for bookmark
  const { listViewDisplayURL, listViewDisplayTitle, recordDisplayURL } =
    llSystemAdminPermitTypesBookmark;

  useCCListViewActionBar({
    title: localLawsRoute.name,
    leftComponents: [
      <LocalLawsActionBarNavDropdown category={systemAdminRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title="New"
        onClick={() => {
          history.push(`${LOCAL_LAWS_SYSTEM_ADMIN_PERMIT_TYPES_ROUTE}/new`);
        }}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
      />,
      <DeleteButton
        recordType={RECORDTYPE.LLP_PermitType}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={LOCAL_LAWS_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.LLPermits}
        recordType={RECORDTYPE.LLP_PermitType}
        detail={listViewDisplayURL}
        displayName={recordDisplayURL}
        listViewDetail={listViewDisplayTitle}
        listViewDisplayName={listViewDisplayURL}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <PermitTypeDetailsTab />,
      },
      {
        title: "Contact",
        component: <PermitTypeContactsTab />,
      },
      {
        title: "History",
        component: <PermitTypeHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        dataUrl={PERMIT_TYPES_URL}
        columnFields={colLocalLawsSystemAdminPermitTypes}
        primaryField={nameOf("ID")}
        state={{
          sort: [{ field: nameOf("Name"), dir: "asc" }],
        }}
      />
    </LoadingPermissionWrapper>
  );
});
