import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { VO_Assessment_DefermentList } from "@app/products/property/assessments/deferments/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Assessment_DefermentList>();
export const colDefermentListActive: IColumnFields[] = [
  {
    field: nameOf("Assessment_Number_Formatted"),
    title: "Assessment (10.3)",
    locked: true,
    linkTo: (dataItem) => ({
      pathname: `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Assessment_Id}`,
      state: { isExpandedDeferment: true },
    }),
  },
  {
    field: nameOf("Assessment_Reference"),
    title: "Assessment (S-S-B-U)",
  },
  {
    field: nameOf("Property_Name_Address_Locality"),
    title: "Property Address",
  },
  {
    field: nameOf("RatePayer"),
    title: "Ratepayer",
  },
  {
    field: nameOf("Active_From_Date"),
    title: "Active From Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Application_Date"),
    title: "Application Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Approval_Date"),
    title: "Approval Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Method_Name"),
    title: "Method",
  },
  {
    field: nameOf("Type_Name"),
    title: "Type",
  },
  {
    field: nameOf("Deferred_Balance"),
    title: "Deferred Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Deferred_Interest_Balance"),
    title: "Deferred Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("TotalDeferred_Balance"),
    title: "Total Deferred Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Maximum_Amount"),
    title: "Annual Maximum Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Overall_Maximum_Amount"),
    title: "Overall Maximum Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Percentage"),
    title: "Percentage Being Deferred",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("ModifyBy"),
    title: "Modified By",
  },
  {
    field: nameOf("ModifyOn"),
    title: "Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Id"),
    title: "Assessment Deferment ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
