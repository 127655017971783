import { Svc_AppealTabViewDetails } from "@app/products/town-planning/ppr/[id]/components/child-screens/appeals/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { APPEAL_ROUTE } from "@common/pages/appeal/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_AppealTabViewDetails>();

export const colPPRAppeals: IColumnFields[] = [
  {
    field: nameOf("ID"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => `${APPEAL_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("DateAppealed"),
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("HearingOfficer"),
    title: "Hearing Officer",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("DueDate"),
    title: "Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
];
