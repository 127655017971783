import { loadModifyMeter } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/modify-meter/api";
import {
    mockMakeData,
    mockMeterSizeData,
    mockTypeData,
    mockUnitOfMeasurementData,
} from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/modify-meter/mock";
import {
    IModifyMeter,
    IModifyMeterData,
} from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/modify-meter/model";
import { IMeterFormGeneral } from "@app/products/property/meters/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
    Field,
    Form,
    FormElement,
    FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IEnterReadingsDialogProps {
  meterInfo: IMeterFormGeneral | undefined;
  onClose: () => void;
}

export const ModifyMeterDialog = observer(
  ({ meterInfo, onClose }: IEnterReadingsDialogProps) => {
    const [initialValues, setInitialValues] = useState<IModifyMeter>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { pushNotification } = useCCAppNotificationStore();

    useEffectOnce(() => {
      if (!meterInfo) return;
      setIsLoading(true);
      loadModifyMeter(meterInfo).then((data) => {
        setInitialValues(data);
        setIsLoading(false);
      });
    });

    const handleOnSubmitClick = () => {
      pushNotification({
        type: "success",
        title: `Meter modified successfully`,
      });
      onClose();
    };

    const nameOf = nameOfFactory<IModifyMeterData>();

    return (
      <Form
        onSubmitClick={handleOnSubmitClick}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <>
              <CCDialog
                titleHeader={`Modify Meter`}
                onClose={onClose}
                height="auto"
                maxWidth="30%"
                bodyElement={
                  isLoading ? (
                    <Loading isLoading />
                  ) : (
                    <div className="cc-form">
                      <FormElement
                        onSubmit={formRenderProps.onSubmit}
                        className="cc-field-group"
                      >
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">Meter number</label>
                            <Field
                              name={nameOf("MeterNumber")}
                              component={CCInput}
                              placeholder={"Meter number"}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Make</label>
                            <Field
                              name={nameOf("Make")}
                              placeholder={"Make"}
                              data={mockMakeData}
                              textField="Value"
                              dataItemKey="Key"
                              component={CCSearchComboBox}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Type</label>
                            <Field
                              name={nameOf("Type")}
                              placeholder={"Type"}
                              data={mockTypeData}
                              textField="Value"
                              dataItemKey="Key"
                              component={CCSearchComboBox}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <label className="cc-label">Purchase date</label>
                            <Field
                              name={nameOf("PurchaseDate")}
                              component={CCDatePicker}
                              format={DATE_FORMAT.DATE_CONTROL}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Number of dials</label>
                            <Field
                              name={nameOf("NumberOfDials")}
                              component={CCNumericTextBox}
                              min={0}
                              placeholder={"Number of dials"}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Meter size</label>
                            <Field
                              name={nameOf("MeterSize")}
                              placeholder={"Meter size"}
                              data={mockMeterSizeData}
                              textField="Value"
                              dataItemKey="Key"
                              component={CCSearchComboBox}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Unit of measurement
                            </label>
                            <Field
                              name={nameOf("UnitOfMeasurement")}
                              placeholder={"Unit of measurement"}
                              data={mockUnitOfMeasurementData}
                              textField="Value"
                              dataItemKey="Key"
                              component={CCSearchComboBox}
                            />
                          </div>
                        </div>
                      </FormElement>
                    </div>
                  )
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button
                      type="button"
                      className={"cc-dialog-button"}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>

                    <Button
                      type={"submit"}
                      name="OK"
                      themeColor="primary"
                      className={"cc-dialog-button"}
                      onClick={formRenderProps.onSubmit}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
            </>
          );
        }}
      />
    );
  }
);
