import {
  CORE_INSPECTION_TYPES_ODATA,
  CORE_OFFICERS_ODATA,
} from "@app/core/components/common/constant";
import {
  InspectionTableType,
  OfficerType,
} from "@app/products/hm/inspections/[id]/components/child-screens/general/components/inspection-form-element/config";
import { useInspectionsStore } from "@app/products/hm/inspections/[id]/store";
import { InspectionRegisterForm } from "@app/products/hm/model";
import { usePremisesStore } from "@app/products/hm/premises/[id]/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import {
  textAreaValidator,
  validateRequired,
} from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import InputPicker, {
  IInputPickerProps,
} from "@components/input-picker/InputPicker";
import { Toast } from "@components/toast";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";
import { InspectionParentSection } from "./components/inspection-parent-section/_index";

export interface IInspectionFormElementProps {
  formRenderProps: FormRenderProps;
}

export const InspectionFormElement = observer(
  ({ formRenderProps }: IInspectionFormElementProps) => {
    const isNew = useIsNew();
    const { valueGetter, onChange } = formRenderProps;
    const nameOf = nameOfFactory<InspectionRegisterForm>();
    const { position, inspectionsLOVs } = useInspectionsStore();
    const { premises } = usePremisesStore();

    const inspectionTypeProps = (
      formRenderProps: FormRenderProps
    ): IInputPickerProps => ({
      id: valueGetter(nameOf("InspectionType_ID")),
      isMultiSelect: false,
      titleHeader: "Pick Inspection Type",
      placeholder: "Inspection Type",
      tableTypes: InspectionTableType,
      url: CORE_INSPECTION_TYPES_ODATA,
      disabled: false,
      fieldName: nameOf("InspectionType"),
      value: valueGetter(nameOf("InspectionType")),
      onChange: (event: any) => {
        let inputText;

        if (event.target !== null) {
          inputText = event.target.value;
          formRenderProps.onChange(nameOf("InspectionType_ID"), {
            value: 0,
          });
          formRenderProps.onChange(nameOf("InspectionType"), {
            value: inputText,
          });
        } else {
          inputText = event.detail.Name;
          formRenderProps.onChange(nameOf("InspectionType_ID"), {
            value: event.detail.ID,
          });
          formRenderProps.onChange(nameOf("InspectionType"), {
            value: inputText,
          });
        }

        // trigger validate function
        formRenderProps.onChange(nameOf("InspectionType"), {
          value: inputText,
        });
      },
      setData: (data: any) => {
        if (data) {
          formRenderProps.onChange(nameOf("InspectionType"), {
            value: data["Name"],
          });
          formRenderProps.onChange(nameOf("InspectionType_ID"), {
            value: data["ID"],
          });
        }
      },
    });

    const officerProps = (
      formRenderProps: FormRenderProps
    ): IInputPickerProps => ({
      titleHeader: "Pick Officer",
      isMultiSelect: false,
      tableTypes: OfficerType,
      url: CORE_OFFICERS_ODATA,
      placeholder: "Officer",
      disabled: false,
      fieldName: nameOf("Officer"),
      value: valueGetter(nameOf("OfficerDisplayName")),
      id: valueGetter(nameOf("Officer_ID")),
      // officer picker
      onChange: (event: any) => {
        if (event.target) {
          formRenderProps.onChange(nameOf("Officer"), {
            value: event.target.value,
          });
          formRenderProps.onChange(nameOf("OfficerDisplayName"), {
            value: event.target.value,
          });
          formRenderProps.onChange(nameOf("Officer_ID"), {
            value: 0,
          });
        } else {
          formRenderProps.onChange(nameOf("Officer"), {
            value: event.detail.Name,
          });
          formRenderProps.onChange(nameOf("OfficerDisplayName"), {
            value: event.detail.Name,
          });
          formRenderProps.onChange(nameOf("Officer_ID"), {
            value: event.detail.ID,
          });
        }
      },
      setData: (data: any) => {
        if (data) {
          formRenderProps.onChange(nameOf("OfficerDisplayName"), {
            value: data["Name"],
          });
          formRenderProps.onChange(nameOf("Officer_ID"), {
            value: data["ID"],
          });
        }
      },
    });

    const officerSecondaryProps = (
      formRenderProps: FormRenderProps
    ): IInputPickerProps => ({
      titleHeader: "Pick Secondary Officer",
      isMultiSelect: false,
      tableTypes: OfficerType,
      url: CORE_OFFICERS_ODATA,
      placeholder: "Officer Secondary",
      disabled: false,
      id: valueGetter(nameOf("SecondaryOfficer_ID")),
      fieldName: nameOf("SecondaryOfficer"),
      value: valueGetter(nameOf("SecondaryOfficerDisplayName")),
      onChange: (event: any) => {
        if (event.target !== null) {
          formRenderProps.onChange(nameOf("SecondaryOfficer"), {
            value: event.target.value,
          });
          // show value
          formRenderProps.onChange(nameOf("SecondaryOfficerDisplayName"), {
            value: event.target?.value,
          });
          formRenderProps.onChange(nameOf("SecondaryOfficer_ID"), {
            value: 0,
          });
        } else {
          formRenderProps.onChange(nameOf("SecondaryOfficer"), {
            value: event.detail.Name,
          });
          formRenderProps.onChange(nameOf("SecondaryOfficerDisplayName"), {
            value: event.detail.Name,
          });
          formRenderProps.onChange(nameOf("SecondaryOfficer_ID"), {
            value: event.detail.ID,
          });
        }
      },
      setData: (data: any) => {
        if (data) {
          formRenderProps.onChange(nameOf("SecondaryOfficerDisplayName"), {
            value: data["Name"],
          });
          formRenderProps.onChange(nameOf("SecondaryOfficer_ID"), {
            value: data["ID"],
          });
        }
      },
    });

    const handleInputChange = (event: any) => {
      const keyList = [
        "RelatedInspection_ID",
        "RelatedAction_ID",
        "RelatedNotice_ID",
        "Position",
      ];
      if (keyList.some((target) => target === event.target.name)) {
        formRenderProps.onChange(nameOf(event.target.name), {
          value: event.target?.value?.Key,
        });
      } else {
        formRenderProps.onChange(nameOf(event.target.name), {
          value: event.target?.value,
        });
      }
      if (event.target.name === nameOf("Reason")) {
        formRenderProps.onChange(nameOf("Reason_ID"), {
          value: event.target?.value?.Key,
        });
      }

      if (event.target.name === nameOf("Compliance")) {
        formRenderProps.onChange(nameOf("Compliance_ID"), {
          value: event.target?.value?.Key,
        });
      }
    };

    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }

      if (!formRenderProps.valid) return;

      Toast.displayToast({
        content: "🚀 The form is not modified !",
        type: "warning",
      });
    };
    return (
      <FormElement onSubmit={formRenderProps.onSubmit}>
        <div>
          <input id="btn-submit" type="submit" hidden onClick={validateForm} />

          <InspectionParentSection
            premiseInfo={premises}
            formRenderProps={formRenderProps}
          />
          <div className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-col-span-2">
                <div className="cc-field">
                  <label className="cc-label">
                    Inspection type
                    <CCTooltip
                      type="validator"
                      content={formRenderProps.errors.inspectionType}
                    />
                  </label>
                  <Field
                    component={InputPicker}
                    {...inspectionTypeProps(formRenderProps)}
                    name={nameOf("InspectionType")}
                    validator={isNew ? validateRequired : undefined}
                  />
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Officer
                  <CCTooltip
                    type="validator"
                    content={formRenderProps.errors.officer}
                  />
                </label>

                <Field
                  component={InputPicker}
                  {...officerProps(formRenderProps)}
                  name={nameOf("Officer")}
                  validator={isNew ? validateRequired : undefined}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Date inspected</label>
                <CCDatePicker
                  value={
                    valueGetter(nameOf("DateInspected"))
                      ? new Date(valueGetter(nameOf("DateInspected")))
                      : new Date()
                  }
                  name={nameOf("DateInspected")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  onChange={(event: any) => {
                    formRenderProps.onChange(nameOf("DateInspected"), {
                      value: event.target.value,
                    });
                    handleInputChange(event);
                  }}
                  valid={valueGetter(nameOf("DateInspected")) !== null}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Time started</label>
                <TimePicker
                  name={nameOf("TimeStarted")}
                  value={
                    valueGetter(nameOf("TimeStarted"))
                      ? new Date(valueGetter(nameOf("TimeStarted")))
                      : new Date()
                  }
                  onChange={(event: any) => {
                    formRenderProps.onChange(nameOf("TimeStarted"), {
                      value: event.target.value,
                    });
                    handleInputChange(event);
                  }}
                  valid={valueGetter(nameOf("TimeStarted")) !== null}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Time completed</label>

                <TimePicker
                  name={nameOf("TimeCompleted")}
                  value={
                    valueGetter(nameOf("TimeCompleted"))
                      ? new Date(valueGetter(nameOf("TimeCompleted")))
                      : new Date()
                  }
                  onChange={(event: any) => {
                    formRenderProps.onChange(nameOf("TimeCompleted"), {
                      value: event.target.value,
                    });
                    handleInputChange(event);
                  }}
                  valid={valueGetter(nameOf("TimeCompleted")) !== null}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Reason
                  {isNew ? (
                    <CCTooltip
                      type="validator"
                      content={formRenderProps.errors.Reason}
                    />
                  ) : null}
                </label>
                <Field
                  validator={isNew ? validateRequired : undefined}
                  name={nameOf("Reason")}
                  textField="Value"
                  dataItemKey="Key"
                  data={inspectionsLOVs?.Reason}
                  component={CCSearchComboBox}
                  value={getDropdownValue(
                    valueGetter(nameOf("Reason")),
                    inspectionsLOVs?.Reason ?? [],
                    "Key"
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange(nameOf("Reason"), {
                      value: event.target.value?.Key ?? null,
                    });
                  }}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Reference number</label>
                <Input
                  autoComplete="off"
                  name={nameOf("ReferenceNumber")}
                  value={valueGetter(nameOf("ReferenceNumber"))}
                  onChange={(event: any) => {
                    formRenderProps.onChange(nameOf("ReferenceNumber"), {
                      value: event.target.value,
                    });
                    handleInputChange(event);
                  }}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Compliance
                  {isNew ? (
                    <CCTooltip
                      type="validator"
                      content={formRenderProps.errors.Compliance}
                    />
                  ) : null}
                </label>
                <Field
                  validator={isNew ? validateRequired : undefined}
                  name={nameOf("Compliance")}
                  textField="Value"
                  dataItemKey="Key"
                  data={inspectionsLOVs?.Compliance}
                  component={CCSearchComboBox}
                  value={getDropdownValue(
                    valueGetter(nameOf("Compliance")),
                    inspectionsLOVs?.Compliance ?? [],
                    "Key"
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange(nameOf("Compliance"), {
                      value: event.target.value?.Key ?? null,
                    });
                  }}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Affect planned schedule</label>
                <CCSwitch
                  name="AffectPlannedSchedule"
                  checked={valueGetter(nameOf("AffectPlannedSchedule"))}
                  onChange={(event: any) => {
                    handleInputChange(event);
                    formRenderProps.onChange(nameOf("AffectPlannedSchedule"), {
                      value: event.target.value,
                    });
                  }}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Secondary officer</label>
                <Field
                  name={nameOf("SecondaryOfficer")}
                  component={InputPicker}
                  {...officerSecondaryProps(formRenderProps)}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Foodsafe score</label>
                <Input
                  autoComplete="off"
                  name={nameOf("FoodSafeScore")}
                  value={valueGetter(nameOf("FoodSafeScore"))}
                  onChange={(event: any) => {
                    formRenderProps.onChange(nameOf("FoodSafeScore"), {
                      value: event.target.value,
                    });
                    handleInputChange(event);
                  }}
                />
              </div>
            </div>
          </div>
          <hr className="cc-divider" />

          <div className="cc-field-group">
            <div className="cc-field">
              <label className="cc-label">Person contact</label>
              {/* <PickSelection {...propPersonContact} /> */}
            </div>
            <div className="cc-field">
              <label className="cc-label">Phone</label>
              <Input disabled name="Phone" />
            </div>
            <div className="cc-field">
              <label className="cc-label">Position</label>
              <Field
                name={nameOf("PersonContactedPosition")}
                textField="Value"
                dataItemKey="Key"
                data={position}
                component={CCSearchComboBox}
                value={getDropdownValue(
                  valueGetter(nameOf("PersonContactedPosition")),
                  position,
                  "Key"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("PersonContactedPosition"), {
                    value: event.target.value?.Key ?? null,
                  });
                }}
              />
            </div>
          </div>
          <hr className="cc-divider" />

          <div className="cc-field-group">
            <div className="cc-field">
              <label className="cc-label">Related inspection</label>
              <Field
                name={nameOf("RelatedInspection_ID")}
                dataItemKey="Key"
                textField="Value"
                data={inspectionsLOVs?.RelatedInspection}
                value={getDropdownValue(
                  valueGetter(nameOf("RelatedInspection_ID")),
                  inspectionsLOVs?.RelatedInspection,
                  "Key"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  formRenderProps.onChange(nameOf("RelatedInspection_ID"), {
                    value: event.target?.value?.Key,
                  });
                  handleInputChange(event);
                }}
                component={CCSearchComboBox}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Related action</label>
              <Field
                name={nameOf("RelatedAction_ID")}
                dataItemKey="Key"
                textField="Value"
                data={inspectionsLOVs?.RelatedActions}
                value={getDropdownValue(
                  valueGetter(nameOf("RelatedAction_ID")),
                  inspectionsLOVs?.RelatedActions,
                  "Value"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  formRenderProps.onChange(nameOf("RelatedAction_ID"), {
                    value: event.target.value,
                  });
                  handleInputChange(event);
                }}
                component={CCSearchComboBox}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Related notice</label>
              <Field
                name={nameOf("RelatedNotice_ID")}
                dataItemKey="Key"
                textField="Value"
                data={inspectionsLOVs?.RelatedNotice}
                value={getDropdownValue(
                  valueGetter(nameOf("RelatedNotice_ID")),
                  inspectionsLOVs?.RelatedNotice,
                  "Value"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  formRenderProps.onChange(nameOf("RelatedNotice_ID"), {
                    value: event.target.value,
                  });
                  handleInputChange(event);
                }}
                component={CCSearchComboBox}
              />
            </div>
          </div>
          <hr className="cc-divider" />

          <div className="cc-field-group">
            <div className="cc-field">
              <label className="cc-label">Description</label>
              <Field
                rows={5}
                name={nameOf("Description")}
                value={valueGetter(nameOf("Description"))}
                onChange={(event: any) => {
                  formRenderProps.onChange(nameOf("Description"), {
                    value: event.target.value,
                  });
                  handleInputChange(event);
                }}
                component={CCTextArea}
                validator={textAreaValidator}
              />
            </div>
          </div>
        </div>
      </FormElement>
    );
  }
);
