import { DTO_ChangeOfOwnershipProcessed } from "@app/products/property/assessments/[id]/components/child-screens/transfer/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_ChangeOfOwnershipProcessed>();
export const colFieldsParcelOwnership: IColumnFields[] = [
  { field: nameOf("Type"), title: "Change Type" },
  { field: nameOf("Officer"), title: "Officer" },
  { field: nameOf("Reference"), title: "Reference" },
  {
    field: nameOf("ContractDate"),
    title: "Contract Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("EffectiveDate"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("SalePrice"),
    title: "Sale Price",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Chattels"),
    title: "Chattels",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("TransferFee"),
    title: "Transfer Fee",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  { field: nameOf("NewNameAndAddressWithId"), title: "New Name and Address" },
  { field: nameOf("OldNameAndAddressWithId"), title: "Old Name and Address" },
  { field: nameOf("Status"), title: "Status" },
  { field: nameOf("Notes"), title: "Notes" },
  { field: nameOf("ChangedBy"), title: "Changed By" },
  {
    field: nameOf("ChangedOn"),
    title: "Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
  { field: nameOf("InstalmentPlan"), title: "Instalment Plan" },
  {
    field: nameOf("Charge_Id"),
    title: "Charge ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("MailboxSubscriptionRequest_Id"),
    title: "Mailbox Subscription Request ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Id"),
    title: "Change of Ownership ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
