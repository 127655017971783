import { IPublicHolidayItem } from "@common/pages/settings/lookups/public-holidays/_id/components/public-holidays-dialog/_index";
import { SettingState } from "@common/pages/settings/lookups/public-holidays/model";

export const publicHolidayItemsList = (
  settingState: SettingState
): IPublicHolidayItem[] => {
  return [
    {
      isHaveState: settingState.isHaveACT,
      label: "ACT",
      fieldNameDescription: "ACT_Description",
      fieldName: "ACT_Holiday",
    },
    {
      isHaveState: settingState.isHaveNSW,
      label: "NSW",
      fieldNameDescription: "NSW_Description",
      fieldName: "NSW_Holiday",
    },
    {
      isHaveState: settingState.isHaveNT,
      label: "NT",
      fieldNameDescription: "NT_Description",
      fieldName: "NT_Holiday",
    },
    {
      isHaveState: settingState.isHaveQLD,
      label: "QLD",
      fieldNameDescription: "QLD_Description",
      fieldName: "QLD_Holiday",
    },
    {
      isHaveState: settingState.isHaveSA,
      label: "SA",
      fieldNameDescription: "SA_Description",
      fieldName: "SA_Holiday",
    },
    {
      isHaveState: settingState.isHaveTAS,
      label: "TAS",
      fieldNameDescription: "TAS_Description",
      fieldName: "TAS_Holiday",
    },
    {
      isHaveState: settingState.isHaveVIC,
      label: "VIC",
      fieldNameDescription: "VIC_Description",
      fieldName: "VIC_Holiday",
    },
    {
      isHaveState: settingState.isHaveWA,
      label: "WA",
      fieldNameDescription: "WA_Description",
      fieldName: "WA_Holiday",
    },
  ];
};
