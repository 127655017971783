import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import "./_index.scss";

interface IAssessmentsAssociationsTabCommonProps {
  id?: string;
}
export const AssessmentsAssociationsTabCommon = observer(
  ({ id }: IAssessmentsAssociationsTabCommonProps) => {
    const params: { id: string } = useParams();
    const assessmentId: string = id ?? params.id;
    return (
      <div className="cc-assessments-associations-tab">
        <PropertyAssociations
          isNoControl
          id={assessmentId}
          ref={useRef<((instance: unknown) => void) | React.MutableRefObject<unknown> | null>(null)}
          componentNumber={eComponent.Assessment}
        />
      </div>
    );
  }
);
