import { ValuationsList } from "@app/products/property/assessments/master-properties/[id]/components/child-screens/valuations/components/valuations-list/_index";
import { useMasterPropertyStore } from "@app/products/property/assessments/master-properties/[id]/store";
import { CCInput } from "@components/cc-input/_index";
import { RadioButton } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

enum ValuationFilterEnum {
  Valuation = 0,
  RatingPeriod = 1,
}

export const MasterPropertiesValuations = observer(() => {
  const [selectedFilterValue, setSelectedFilterValue] = useState(
    ValuationFilterEnum.Valuation
  );
  const { masterProperty } = useMasterPropertyStore();

  const handleChangeFilterValue = (event: any) => {
    setSelectedFilterValue(event.value);
    setValuationData(
      <ValuationsList
        isListByValuation={event.value === ValuationFilterEnum.Valuation}
      />
    );
  };

  const [valuationData, setValuationData] = useState<any>(<ValuationsList />);

  return (
    <div className="cc-property-assessment-master-property-valuations">
      <div className="cc-grid-control-between">
        <div className="cc-grid-control-left">
          <div className="cc-control-item">
            <RadioButton
              name="filterByGroup"
              value={ValuationFilterEnum.Valuation}
              checked={selectedFilterValue === ValuationFilterEnum.Valuation}
              label="By valuation"
              onChange={handleChangeFilterValue}
            />
          </div>
          <div className="cc-control-item">
            <RadioButton
              name="filterByGroup"
              value={ValuationFilterEnum.RatingPeriod}
              checked={selectedFilterValue === ValuationFilterEnum.RatingPeriod}
              label="By rating period"
              onChange={handleChangeFilterValue}
            />
          </div>
        </div>
        <div className="cc-grid-control-right">
          <div className="cc-control-item">
            <label className="cc-label">Valuation number</label>
            <CCInput
              readOnly
              name="SearchValuationNumber"
              value={masterProperty?.MP_Valuation_Number?.toString()}
            />
          </div>
        </div>
      </div>
      <label className="cc-label">Valuations</label>
      {valuationData}
    </div>
  );
});
