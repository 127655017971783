export enum EKeysOfStepsJournalAssessmentRaiseInterest {
  RaiseInterest = "RaiseInterest",
  TaskSchedule = "TaskSchedule",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfSendStepsJournalAssessmentRaiseInterest = [
  EKeysOfStepsJournalAssessmentRaiseInterest.RaiseInterest,
  EKeysOfStepsJournalAssessmentRaiseInterest.TaskSchedule,
];
