import { AnimalTypeForm } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/_index";
import { AnimalTypeDetailsTab } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/reference-sidebar/details/_index";
import { AnimalTypeHistoryTab } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/reference-sidebar/history/_index";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedAnimalType = observer(() => {
  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();
  const managePageUrl = window.location.pathname;
  const [showSlideBar, setShowSlideBar] = useState(true);
  const {
    loadAnimalType,
    animalType,
    responseLoadError,
    isLoading,
    onSubmit,
    animalTypeID,
  } = useAnimalTypeStore();

  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([animalType?.Name])}`,
      LinkUrl: managePageUrl,
      LinkText: `Animal types - ${
        animalType?.AnimalType_ID ? ` - ${animalType?.AnimalType_ID}` : ""
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Animals,
      RecordType_ENUM: RECORDTYPE.Animals_AnimalType,
      Record_ID: animalTypeID ? +animalTypeID : 0,
    },
  ];

  useEffect(() => {
    loadAnimalType(parseInt(id), isNew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew]);

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Animal Type" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadAnimalType(parseInt(id), isNew)}
        />
      ) : (
        <>
          <FormTitle title={animalType?.Name} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
              />,
              <CCNavButton
                title={"Test fees"}
                onClick={onSubmit}
                name={ActionSubmitActions.TestFees}
              />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {animalType && <AnimalTypeForm />}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <AnimalTypeDetailsTab /> },
                    {
                      title: "History",
                      component: <AnimalTypeHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
