import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { JOURNALS_SESSION_LIST_ROUTE } from "@app/products/property/journals/session-list/[id]/constant";
import { PROPERTY_ASSESSMENT_SESSION_LIST_VIEW_URL } from "@app/products/property/journals/session-list/constant";
import { VO_Journal_SessionList } from "@app/products/property/journals/session-list/model";
import { PropertyJournalSessionListBookmark } from "@app/products/property/journals/session-list/util";
import { propertyRoute } from "@app/products/property/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { journalsRoute } from "../route";
import { JournalSectionDetailTab } from "./[id]/components/reference-sidebar/detail/_index";
import { CancelPropertySessionButton } from "./components/action-bar/gl-update/components/buttons/cancel-property-session/_index";
import { UpdateToGeneralLedgerButton } from "./components/action-bar/gl-update/components/buttons/update-to-general-ledger/_index";
import DetailComponent from "./components/detail/_index";
import { colListSession } from "./config";

const nameOf = nameOfFactory<VO_Journal_SessionList>();
export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={journalsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton type="sub-workflow" title={"Workflow"}>
        <UpdateToGeneralLedgerButton />
        <CancelPropertySessionButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={JOURNALS_SESSION_LIST_ROUTE}
        displayName={PropertyJournalSessionListBookmark.getBookmarkDisplayName}
        detail={PropertyJournalSessionListBookmark.getBookmarkDetail}
        listViewDisplayName={
          PropertyJournalSessionListBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={
          PropertyJournalSessionListBookmark.getBookmarkListViewDetail
        }
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Journal}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <JournalSectionDetailTab /> },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Journal}
          />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      dataUrl={PROPERTY_ASSESSMENT_SESSION_LIST_VIEW_URL}
      columnFields={colListSession}
      primaryField={nameOf("Session_Number")}
      detail={DetailComponent}
      selectableMode="multiple"
      state={{ sort: [{ field: nameOf("Session_Number"), dir: "desc" }] }}
    />
  );
};
