import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { relatedAccordionStore } from "@app/core/related/related-accordion/store";
import { saveRelatedRecords } from "@app/core/related/related-button/api";
import { IResultHandleAddRelated } from "@app/core/related/related-button/model";
import { RelatedRecord } from "@app/core/related/related-dialog/model";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class AddRelatedButtonStore {
  private _isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  handleAddRelated = async (
    data: RelatedRecord[]
  ): Promise<IResultHandleAddRelated> => {
    let result: IResultHandleAddRelated;
    this.setIsLoading(true);
    const response = await saveRelatedRecords(data);
    this.setIsLoading(false);
    if (isSuccessResponse(response)) {
      appNotificationStore.clearErrorNotification();
      relatedAccordionStore.loadRelatedRecords();
      appNotificationStore.pushNotification({
        type: "success",
        title: "Record saved successfully",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(TabTableEventType.RefreshData);
      result = { isSuccess: true };
    } else {
      result = {
        isSuccess: false,
        notification: {
          autoClose: false,
          type: "error",
          title: "Add related failed",
          description: response?.data?.Errors ?? response.error,
        },
      };
    }
    return result;
  };
}
export const addRelatedButtonStore = new AddRelatedButtonStore();
const addRelatedButtonStoreContext = createContext(addRelatedButtonStore);
export const useAddRelatedButtonStore = () => {
  return useContext(addRelatedButtonStoreContext);
};
