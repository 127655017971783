import {
  IMeterReading,
  METER_STATUS,
} from "@app/products/property/meters/list/components/action-bar/dialogs/enter-readings/model";

export const mockMeterEnterReading: IMeterReading[] = [
  {
    MeterId: 2926,
    MeterNumber: "C013224",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.ACTIVE,
    PropertyAddress: ",Proserpine",
    ReadingStatus: {
      Key: 9,
      Value: "Read",
    },
    PreviousReadingDate: new Date("14-Jan-2022"),
    PreviousReading: 5000,
  },
  {
    MeterId: 3481,
    MeterNumber: "0000223",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.RETIRED,
    PropertyAddress: "5 Jones Road, Cannonvale",
    ReadingStatus: {
      Key: 10,
      Value: "Read - Broken Glass",
    },
    PreviousReadingDate: new Date("18-Jan-2022"),
    PreviousReading: 4504,
  },
  {
    MeterId: 792,
    MeterNumber: "0000228",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.ACTIVE,
    PropertyAddress: ",Proserpine",
    ReadingStatus: {
      Key: 9,
      Value: "Read",
    },
    PreviousReadingDate: new Date("14-Jan-2022"),
    PreviousReading: 5000,
  },
  {
    MeterId: 3915,
    MeterNumber: "0000232",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.BEING_TESTED,
    PropertyAddress: "5 Jones Road, Cannonvale",
    ReadingStatus: {
      Key: 10,
      Value: "Read - Broken Glass",
    },
    PreviousReadingDate: new Date("18-Jan-2022"),
    PreviousReading: 4504,
  },
  {
    MeterId: 1475,
    MeterNumber: "0000233",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.ACTIVE,
    PropertyAddress: ",Proserpine",
    ReadingStatus: {
      Key: 9,
      Value: "Read",
    },
    PreviousReadingDate: new Date("14-Jan-2022"),
    PreviousReading: 5000,
  },
  {
    MeterId: 3115,
    MeterNumber: "0000236",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.ACTIVE,
    PropertyAddress: "5 Jones Road, Cannonvale",
    ReadingStatus: {
      Key: 10,
      Value: "Read - Broken Glass",
    },
    PreviousReadingDate: new Date("18-Jan-2022"),
    PreviousReading: 4504,
  },
  {
    MeterId: 985,
    MeterNumber: "0000237",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.RETIRED,
    PropertyAddress: ",Proserpine",
    ReadingStatus: {
      Key: 9,
      Value: "Read",
    },
    PreviousReadingDate: new Date("14-Jan-2022"),
    PreviousReading: 5000,
  },
  {
    MeterId: 3308,
    MeterNumber: "0000238",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.ACTIVE,
    PropertyAddress: "5 Jones Road, Cannonvale",
    ReadingStatus: {
      Key: 10,
      Value: "Read - Broken Glass",
    },
    PreviousReadingDate: new Date("18-Jan-2022"),
    PreviousReading: 4504,
  },
  {
    MeterId: 425,
    MeterNumber: "0000240",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.ACTIVE,
    PropertyAddress: "5 Jones Road, Cannonvale",
    ReadingStatus: {
      Key: 10,
      Value: "Read - Broken Glass",
    },
    PreviousReadingDate: new Date("18-Jan-2022"),
    PreviousReading: 4504,
  },
  {
    MeterId: 3632,
    MeterNumber: "0000243",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.RETIRED,
    PropertyAddress: "5 Jones Road, Cannonvale",
    ReadingStatus: {
      Key: 10,
      Value: "Read - Broken Glass",
    },
    PreviousReadingDate: new Date("18-Jan-2022"),
    PreviousReading: 4504,
  },
  {
    MeterId: 562,
    MeterNumber: "0000245",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.RETIRED,
    PropertyAddress: ",Proserpine",
    ReadingStatus: {
      Key: 9,
      Value: "Read",
    },
    PreviousReadingDate: new Date("14-Jan-2022"),
    PreviousReading: 5000,
  },
  {
    MeterId: 2302,
    MeterNumber: "0000250",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.ACTIVE,
    PropertyAddress: ",Proserpine",
    ReadingStatus: {
      Key: 9,
      Value: "Read",
    },
    PreviousReadingDate: new Date("14-Jan-2022"),
    PreviousReading: 5000,
  },
  {
    MeterId: 3077,
    MeterNumber: "0000441",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.ACTIVE,
    PropertyAddress: "5 Jones Road, Cannonvale",
    ReadingStatus: {
      Key: 10,
      Value: "Read - Broken Glass",
    },
    PreviousReadingDate: new Date("18-Jan-2022"),
    PreviousReading: 4504,
  },
  {
    MeterId: 1159,
    MeterNumber: "0000442",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.RETIRED,
    PropertyAddress: ",Proserpine",
    ReadingStatus: {
      Key: 9,
      Value: "Read",
    },
    PreviousReadingDate: new Date("14-Jan-2022"),
    PreviousReading: 5000,
  },
  {
    MeterId: 1677,
    MeterNumber: "0000444",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.RETIRED,
    PropertyAddress: "5 Jones Road, Cannonvale",
    ReadingStatus: {
      Key: 10,
      Value: "Read - Broken Glass",
    },
    PreviousReadingDate: new Date("18-Jan-2022"),
    PreviousReading: 4504,
  },
  {
    MeterId: 1945,
    MeterNumber: "0000445",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.ACTIVE,
    PropertyAddress: ",Proserpine",
    ReadingStatus: {
      Key: 9,
      Value: "Read",
    },
    PreviousReadingDate: new Date("14-Jan-2022"),
    PreviousReading: 5000,
  },
  {
    MeterId: 2184,
    MeterNumber: "0000446",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.ACTIVE,
    PropertyAddress: ",Proserpine",
    ReadingStatus: {
      Key: 9,
      Value: "Read",
    },
    PreviousReadingDate: new Date("14-Jan-2022"),
    PreviousReading: 5000,
  },
  {
    MeterId: 1210,
    MeterNumber: "0000447",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.RETIRED,
    PropertyAddress: "5 Jones Road, Cannonvale",
    ReadingStatus: {
      Key: 10,
      Value: "Read - Broken Glass",
    },
    PreviousReadingDate: new Date("18-Jan-2022"),
    PreviousReading: 4504,
  },
  {
    MeterId: 3645,
    MeterNumber: "0000450",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.RETIRED,
    PropertyAddress: ",Proserpine",
    ReadingStatus: {
      Key: 9,
      Value: "Read",
    },
    PreviousReadingDate: new Date("14-Jan-2022"),
    PreviousReading: 5000,
  },
  {
    MeterId: 2291,
    MeterNumber: "0000451",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.ACTIVE,
    PropertyAddress: ",Proserpine",
    ReadingStatus: {
      Key: 9,
      Value: "Read",
    },
    PreviousReadingDate: new Date("14-Jan-2022"),
    PreviousReading: 5000,
  },
  {
    MeterId: 2947,
    MeterNumber: "0000452",
    UnitOfMeasure: "Kilolitre",
    Status: METER_STATUS.RETIRED,
    PropertyAddress: "5 Jones Road, Cannonvale",
    ReadingStatus: {
      Key: 10,
      Value: "Read - Broken Glass",
    },
    PreviousReadingDate: new Date("18-Jan-2022"),
    PreviousReading: 4504,
  },
];
export const mockReadingStatusData = [
  {
    Key: 1,
    Value: "Estimate",
  },
  {
    Key: 2,
    Value: "Faulty Meter",
  },
  {
    Key: 3,
    Value: "Leak on Council Side",
  },
  {
    Key: 4,
    Value: "Leak on Council Side",
  },
  {
    Key: 5,
    Value: "Leak on Owners Side",
  },
  {
    Key: 6,
    Value: "Leak on Owners Side",
  },
  {
    Key: 7,
    Value: "New Meter",
  },
  {
    Key: 8,
    Value: "New Meter",
  },
  {
    Key: 9,

    Value: "Read",
  },
  {
    Key: 10,
    Value: "Read - Broken Glass",
  },
  {
    Key: 11,
    Value: "Read - Meter Vandalised",
  },
  {
    Key: 12,
    Value: "Rechecked - high reading",
  },
  {
    Key: 13,
    Value: "Rechecked - high reading",
  },
  {
    Key: 14,
    Value: "Unable to Access Property",
  },
  {
    Key: 15,
    Value: "Unable to Locate Meter",
  },
  {
    Key: 16,
    Value: "Unable to Read",
  },
];
export const mockReadingOfficerData = [
  {
    Key: 1,
    Value: "1, Meter Reader",
  },
  {
    Key: 2,
    Value: "2, Meter Reader",
  },
  {
    Key: 3,
    Value: "3, Meter Reader",
  },
  {
    Key: 4,
    Value: "4, Meter Reader",
  },
  {
    Key: 5,
    Value: "ADMIN, Admin",
  },
  {
    Key: 6,
    Value: "ADMINISTRATOR, System",
  },
  {
    Key: 7,
    Value: "GLINDERMAN, Phill",
  },
  {
    Key: 8,
    Value: "MADSEN, Christine",
  },
  {
    Key: 9,
    Value: "MARTIN, Jody",
  },
  {
    Key: 10,
    Value: "MILNE, Jayne",
  },
  {
    Key: 11,
    Value: "PERSHOUSE, Bev",
  },
  {
    Key: 12,
    Value: "RATES",
  },
  {
    Key: 13,
    Value: "RECFIND, recfind",
  },
];
