import {
  AttributeYieldNotificationPortal,
  TypePlaceId,
  YieldNotificationPortalFormStepDialog,
} from "@components/cc-notification-portal/constant";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { notificationsDomList } from "@components/cc-notification-portal/util";
import { groupBy, keys } from "lodash";
import { observer } from "mobx-react-lite";
import React, { ReactPortal, useEffect, useState } from "react";
import { createPortal } from "react-dom";

export const YieldNotificationPortal = ({
  placeId,
}: {
  placeId: TypePlaceId;
}) => {
  return <div {...{ [AttributeYieldNotificationPortal]: placeId }} />;
};

export const CCNotificationPortal = observer(() => {
  const { notificationsCurrentPage } = useNotificationPortalStore();
  const [portals, setPortals] = useState<ReactPortal[] | null>(null);

  useEffect(() => {
    const groupedByPlaceId = groupBy(notificationsCurrentPage, "placeId");
    const newPortals = keys(groupedByPlaceId)
      .map((placeId) => {
        let parentRoot = document.querySelector(
          `[${AttributeYieldNotificationPortal}="${placeId}"]`
        );
        if (parentRoot?.nextElementSibling?.className === "cc-form-step") {
          parentRoot = parentRoot.nextElementSibling.querySelector(
            "." + YieldNotificationPortalFormStepDialog
          );
        }
        const notifications = groupedByPlaceId[placeId];
        if (parentRoot && notifications) {
          return createPortal(notificationsDomList(notifications), parentRoot);
        }
        return null;
      })
      .filter((portal) => portal !== null);

    setPortals(newPortals as ReactPortal[]);
  }, [notificationsCurrentPage]);

  return <>{portals}</>;
});
