import { PROPERTY_METERS_ROUTE } from "@app/products/property/meters/[id]/constant";
import {
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colMeters: IColumnFields[] = [
  {
    field: "MeterNumber",
    title: "Meter Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${PROPERTY_METERS_ROUTE}/` + dataItem.MeterId;
    },
  },
  { field: "Utility", title: "Utility" },

  { field: "ReadingRoute", title: "Reading Route" },
  {
    field: "SequenceInRoute",
    title: "Sequence in Route",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: "Category", title: "Category" },
  {
    field: "UsageEntitlement",
    title: "Usage Entitlement",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "UsagePercentage",
    title: "Usage Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: "LastReading",
    title: "Last Reading",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: "LastRead", title: "Last Read", format: DATE_FORMAT.DATE },
  {
    field: "NumberOfDials",
    title: "Number of Dials",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "NextReadDate",
    title: "Next Read Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "ReadingFrequencyInMonths",
    title: "Reading Frequency (in Months)",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "AverageDailyUsage",
    title: "Average Daily Usage",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: "UnitOfMeasure", title: "Unit of Measure" },
  { field: "MeterLocation", title: "Meter Location" },
  { field: "AssignedReadingDevice", title: "Assigned Reading Device" },
  { field: "AssignedReader", title: "Assigned Reader" },
  {
    field: "InstallationDate",
    title: "Installation Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "MeterSize",
    title: "Meter Size",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: "MeterType", title: "Meter Type" },
  { field: "Status", title: "Status" },
  {
    field: "MeterId",
    title: "Meter ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
