import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { AnimalsDashboard } from "@common/pages/home/components/animals/model";

export const getAnimalsDashboard = async (): Promise<
  APIResponse<AnimalsDashboard | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/animals/internal/dashboard`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
