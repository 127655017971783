import { sanitizeAndConvertLineBreaks } from "@app/products/property/assessments/[id]/util";
import { getParcelDetailsTabById } from "@app/products/property/parcels/[id]/components/reference-sidebar/detail/api";
import { DTO_Parcel_TabDetails } from "@app/products/property/parcels/[id]/components/reference-sidebar/detail/model";
import { isFieldVisible } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";

interface IParcelDetailTabProps {
  id?: string;
}
export const ParcelDetailTab = observer(({ id }: IParcelDetailTabProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [parcelInfo, setParcelInfo] = useState<
    DTO_Parcel_TabDetails | undefined
  >();
  const { lastSelectedId } = useCCProductListViewStore();
  const recordId = id ?? lastSelectedId;

  const loadData = useCallback(() => {
    if (!recordId) {
      return;
    }
    setIsLoading(true);
    getParcelDetailsTabById(parseInt(recordId)).then((response) => {
      let newData: DTO_Parcel_TabDetails;
      let errorResponse = undefined;
      if (isSuccessResponse(response) && response.data) {
        newData = response.data;
        setParcelInfo(newData);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error ?? "Load detail failed",
        };
      }
      setIsLoading(false);
      setResponseLoadError(errorResponse);
    });
  }, [recordId]);

  const isVisibleDataOther = () => {
    return (
      isFieldVisible(parcelInfo?.LandCategoryVisibility) ||
      isFieldVisible(parcelInfo?.SwimmingPoolFlagVisibility) ||
      isFieldVisible(parcelInfo?.FireServices?.PropertyCategoriesVisibility) ||
      isFieldVisible(parcelInfo?.FireServices?.ClassificationsVisibility) ||
      isFieldVisible(parcelInfo?.FireServices?.DistrictsVisibility)
    );
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!parcelInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"ID:"}
              value={parcelInfo?.ParcelId?.toString() ?? ""}
              primary
            />
          }
        >
          <>
            <ProductReferenceBlock>
              {isFieldVisible(parcelInfo?.CensusDistrictsVisibility) && (
                <ProductReferenceRow
                  title={"Census district:"}
                  value={parcelInfo?.CensusDistrict}
                />
              )}
              <ProductReferenceRow
                title={"Legal description:"}
                value={parcelInfo?.LegalDescription}
              />
              <ProductReferenceRow
                title={"Street name locality:"}
                value={parcelInfo?.StreetNameLocality}
              />
              <ProductReferenceRow
                title={"Owners:"}
                value={sanitizeAndConvertLineBreaks(
                  parcelInfo?.Owners_NameAddr
                )}
              />
              <ProductReferenceRow
                title={"Status:"}
                value={parcelInfo?.Status}
                success
              />
              <ProductReferenceRow
                title={"Land area:"}
                value={parcelInfo?.LandArea}
              />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
        <PanelBarItem title={<ProductReferenceHeading title={"Other"} />}>
          <ProductReferenceBlock>
            {isFieldVisible(parcelInfo?.LandCategoryVisibility) && (
              <ProductReferenceRow
                title={"Land category:"}
                value={parcelInfo?.LandCategory}
              />
            )}
            {isFieldVisible(parcelInfo?.SwimmingPoolFlagVisibility) && (
              <ProductReferenceRow
                title={"Has a swimming pool:"}
                value={parcelInfo?.HasSwimmingPool}
              />
            )}
            {isFieldVisible(
              parcelInfo?.FireServices?.PropertyCategoriesVisibility
            ) && (
              <ProductReferenceRow
                title={"Property category:"}
                value={parcelInfo?.FireServices?.PropertyCategory}
              />
            )}
            {isFieldVisible(
              parcelInfo?.FireServices?.ClassificationsVisibility
            ) && (
              <ProductReferenceRow
                title={"Classification:"}
                value={parcelInfo?.FireServices?.Classification}
              />
            )}
            {isFieldVisible(parcelInfo?.FireServices?.DistrictsVisibility) && (
              <ProductReferenceRow
                title={"District:"}
                value={parcelInfo?.FireServices?.District}
              />
            )}
            {!isVisibleDataOther() && <NoData title="Data is not visibility" />}
          </ProductReferenceBlock>
        </PanelBarItem>
        {isFieldVisible(parcelInfo?.ElectoralDivisions?.GroupVisibility) && (
          <PanelBarItem
            title={<ProductReferenceHeading title={"Electoral Division"} />}
          >
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Local:"}
                value={parcelInfo?.ElectoralDivisions?.Local}
              />
              <ProductReferenceRow
                title={"State code:"}
                value={parcelInfo?.ElectoralDivisions?.State}
              />
              <ProductReferenceRow
                title={"Federal:"}
                value={parcelInfo?.ElectoralDivisions?.Federal}
              />
            </ProductReferenceBlock>
          </PanelBarItem>
        )}
      </PanelBar>
    </ProductReferenceBody>
  );
});
