import { DTO_DebtRecovery_LOVs } from "@app/products/property/components/action-bar/dialog/new-debt-recovery/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ModifyDebtRecoveryDialogStore {
  private _isLoadingOnDialog: boolean = false;
  private _isLoadingOnDialogCancel: boolean = false;
  private _debtRecoveryLOVs?: DTO_DebtRecovery_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoadingOnDialogCancel(): boolean {
    return this._isLoadingOnDialogCancel;
  }
  setIsLoadingOnDialogCancel = (isLoadingOnDialogCancel: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialogCancel = isLoadingOnDialogCancel;
    });
  };
  get isLoadingOnDialog(): boolean {
    return this._isLoadingOnDialog;
  }
  setIsLoadingOnDialog = (isLoadingOnDialog: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialog = isLoadingOnDialog;
    });
  };

  get debtRecoveryLOVs() {
    return this._debtRecoveryLOVs;
  }
  setDebtRecoveryLOVs = (debtRecoveryPropertyLOVs?: DTO_DebtRecovery_LOVs) => {
    runInAction(() => {
      this._debtRecoveryLOVs = debtRecoveryPropertyLOVs;
    });
  };
}

const modifyDebtRecoveryDialogStoreContext = createContext(
  new ModifyDebtRecoveryDialogStore()
);
export const useModifyDebtRecoveryDialogStore = () => {
  return useContext(modifyDebtRecoveryDialogStoreContext);
};
