import { getViewConfigurations } from "@app/products/property/api";
import {
  ICCViewConfiguration,
  ViewConfiguration,
} from "@app/products/property/model";
import { DTO_Title_AssociatedTitle } from "@app/products/property/titles/[id]/components/child-screens/associated-titles/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IGetTitleResponse =
  | [
      APIResponse<DTO_Title_AssociatedTitle>,
      APIResponse<ICCViewConfiguration | undefined>
    ]
  | APIResponse<DTO_Title_AssociatedTitle | ICCViewConfiguration>
  | undefined;

export const getTitle = async (titleId: number): Promise<IGetTitleResponse> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Title_AssociatedTitle>(
        `api/property/internal/title/${titleId}/association`
      ),
      getViewConfigurations(ViewConfiguration.Associations_Title),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
