import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const CrsHistoryTab = observer(() => {
  const productListViewStore = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const crsId = params.id;
  const id: any = productListViewStore.lastSelectedRow?.ID || crsId;
  return <Journal id={id} recordType={RECORDTYPE.CRS_Registration} />;
});
