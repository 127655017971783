import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { formatStyleProminent } from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

export const colAmountsOwingAtCurrentDate: IColumnFields[] = [
  {
    field: "Description",
    title: "Description",
    footerCell: <TextCell value="Overall Total" />,
  },
  {
    field: "BalanceStatus",
    title: "Balance Status",
  },
  {
    field: "PrincipalAmount",
    title: "Principal Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("PrincipalAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "InterestRaised",
    title: "Interest Raised",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("InterestRaised", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "OtherCostsAmount",
    title: "Other Costs Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("OtherCostsAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "DueAmounts",
    title: "Due Amounts",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("DueAmounts", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "OverdueAmounts",
    title: "Overdue Amounts",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("OverdueAmounts", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "Pending",
    title: "Pending",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Pending", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "OriginalTotal",
    title: "Original Total",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("OriginalTotal", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
];
