import { getLabel } from "@app/products/property/components/label/config";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.Rebate_Claim_Id
    ? `- ${selectedRow.Rebate_Claim_Id}`
    : "";
  return `Property - ${getLabel.assessments()} ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.RC_Name,
    selectedRow.Rebate_Claim_Id,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return `Property - ${getLabel.assessments()}`;
};

export const getBookmarkListViewDetail = () => {
  return `Property - ${getLabel.assessments()} - Rebates - Rebate Claims`;
};
