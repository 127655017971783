import { useMasterPropertyStore } from "@app/products/property/assessments/master-properties/[id]/store";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import React, { ReactElement } from "react";
import { MasterPropertyFormElement } from "./components/form-element/_index";

export function MasterPropertyForm(): ReactElement {
  const { masterProperty } = useMasterPropertyStore();
  return (
    <div className="cc-form">
      <Form
        initialValues={masterProperty}
        render={(formRenderProps: FormRenderProps) => (
          <MasterPropertyFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
}
