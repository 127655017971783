import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colNotices: IColumnFields[] = [
  {
    field: "NoticeType",
    title: "Notice Type",
  },
  {
    field: "CreatedOn",
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "CreatedBy",
    title: "Created By",
  },
  {
    field: "Reference",
    title: "Reference",
  },
  {
    field: "NoticeId",
    title: "Notice ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
