import { InspectionForm } from "@app/products/hm/inspections/[id]/components/child-screens/general/_index";
import { useInspectionsStore } from "@app/products/hm/inspections/[id]/store";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import React from "react";

export const NewManageInspection = () => {
  const { isLoading } = useInspectionsStore();
  return (
    <>
      <Loading isLoading={isLoading} />
      <FormNavigation title="Inspection" />
      <FormTitle title={"New Inspection"} />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          ></CCNavButton>,
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
          <CCNavButton title={"Add"} type="sub">
            <AddActionButton />
            <CCNavButton title={"Contact"} />
            <CCNavButton title={"Attachment"} />
            <CCNavButton title={"Comment"} />
            <CCNavButton title={"Documents"} />
            <CCNavButton title={"Fees"} />
            <CCNavButton title={"Interview"} />
            <CCNavButton title={"Notice"} />
            <CCNavButton title={"Samples"} />
          </CCNavButton>,
          <CCNavButton title={"View"} />,
          <CCNavButton title={"Print"} />,
          <CCNavButton title={"More Options"} type="more">
            <CCNavButton title={"Add NC item"} />
            <CCNavButton title={"AFSA"} />
            <CCNavButton title={"Add temp recorded"} />
            <CCNavButton title={"Add FSP observed"} />
          </CCNavButton>,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <div className="cc-manage-form-body">
            <InspectionForm />
          </div>
        </div>
      </div>
    </>
  );
};
