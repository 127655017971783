import { WastewaterSearchAddress } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/address/_index";
import { WastewaterSearchLocality } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/locality/_index";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import React, { useState } from "react";

export const TabAddress = () => {
  const [checkComplexAddress, setCheckComplexAddress] = useState(false);
  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Enter an address</label>
            <WastewaterSearchAddress />
          </div>
          <div className="cc-field">
            <label className="cc-label">Property name</label>
            <Input placeholder={"Property name"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Building name</label>
            <Input placeholder={"Building name"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Location description</label>
            <Input placeholder={"Location description"} />
          </div>
        </div>
      </div>

      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <Checkbox
              checked={checkComplexAddress}
              onChange={(event) => setCheckComplexAddress(event.value)}
              label={"Complex address"}
            />
          </div>
        </div>

        {checkComplexAddress ? (
          <>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Unit number start</label>

                <div className="cc-input-group-cols-2">
                  <Input type={"number"} className={"mr-1"} placeholder={"0"} />
                  <Input type={"number"} className={"mr-1"} placeholder={"0"} />
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Unit number end</label>

                <div className="cc-input-group-cols-2">
                  <Input type={"number"} className={"mr-1"} placeholder={"0"} />
                  <Input type={"number"} className={"mr-1"} placeholder={"0"} />
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Unit abbreviation</label>

                <Input placeholder={"Unit abbreviation"} />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">House number start</label>

                <div className="cc-input-group-cols-2">
                  <Input type={"number"} className={"mr-1"} placeholder={"0"} />
                  <Input type={"number"} className={"mr-1"} placeholder={"0"} />
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">House number end</label>

                <div className="cc-input-group-cols-2">
                  <Input type={"number"} className={"mr-1"} placeholder={"0"} />
                  <Input type={"number"} className={"mr-1"} placeholder={"0"} />
                </div>
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Floor number start</label>
                <div className="cc-input-group-cols-2">
                  <Input type={"number"} className={"mr-1"} placeholder={"0"} />
                  <Input type={"number"} className={"mr-1"} placeholder={"0"} />
                </div>
              </div>

              <div className="cc-field">
                <label className="cc-label">Floor number end</label>

                <div className="cc-input-group-cols-2">
                  <Input type={"number"} className={"mr-1"} placeholder={"0"} />
                  <Input type={"number"} className={"mr-1"} placeholder={"0"} />
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Floor abbreviation</label>

                <Input placeholder={"Floor abbreviation"} />
              </div>
            </div>
          </>
        ) : (
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Unit number</label>
              <Input type={"number"} placeholder={"Unit number"} />
            </div>
            <div className="cc-field">
              <label className="cc-label">House number</label>
              <Input
                type={"number"}
                placeholder={"House number"}
                defaultValue={"40"}
              />
            </div>
          </div>
        )}
      </div>

      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Street name</label>

            <Input placeholder={"Street name"} />
          </div>
        </div>
      </div>

      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field cc-col-span-3">
            <label className="cc-label">Locality name</label>
            <WastewaterSearchLocality />
          </div>
          <div className="cc-field">
            <label className="cc-label">Postcode</label>
            <Input placeholder={"Street name"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Locality PFI</label>
            <Input placeholder={"Street name"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Locality code</label>
            <Input placeholder={"Street name"} />
          </div>
          <div className="cc-field cc-col-span-3">
            <label className="cc-label">State</label>
            <Input placeholder={"Street name"} />
          </div>
        </div>
      </div>
    </div>
  );
};
