import {
  ICCOptionsPremisesBatchUpdate,
  IPremisesBatchUpdate,
} from "@app/products/hm/components/batch-update/model";
import { CoreKeyword } from "@common/models/coreKeyword";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";

//#region NAME OF ========/
export const nameOfPremisesBatchUpdateOption = (
  keyItem: keyof ICCOptionsPremisesBatchUpdate["CCFormOptions"]
) => {
  return `${nameOfFactory<ICCOptionsPremisesBatchUpdate>()(
    "CCFormOptions"
  )}.${nameOfFactory<ICCOptionsPremisesBatchUpdate["CCFormOptions"]>()(
    keyItem
  )}`;
};

export const nameOfPremisesBatchUpdate = nameOfFactory<IPremisesBatchUpdate>();
export const nameOfKeyValuePacket = nameOfFactory<IKeyValuePacket>();
export const nameOfCoreKeyword = nameOfFactory<CoreKeyword>();
//#endregion NAME OF =====/
