import { setupRegistrationPickSiteAddress } from "@app/products/animals/[id]/api";
import {
  Registration,
  RegistrationHandlerRequest,
  RegistrationUpdateTriggers,
  Svc_FormAction_Registration,
  Svc_Registration,
} from "@app/products/animals/[id]/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { PropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/_index";
import { isSuccessResponse } from "@common/apis/util";
import { Address_BuildAddress } from "@common/input-pickers/address/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { isHTML } from "@components/cc-input-picker/util";
import { CCLabel } from "@components/cc-label/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./_index.scss";
interface IAnimalParentSectionProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<Registration>();
const nameOfMapObj = nameOfFactory<Svc_Registration>();
const getNameOf = (name: keyof Registration) =>
  `${nameOfMapObj("Registration")}.${nameOf(name)}`;

export const AnimalOwnerSection = observer(
  (props: IAnimalParentSectionProps) => {
    const prevOwnerDisplayName = useRef<string>("");
    const prevAddressDisplayName = useRef<string>("");
    const { valueGetter, onChange } = props.formRenderProps;
    const { animalInfo, UIControl, isDeleted, loadRegistrationHandle } =
      useAnimalStore();
    const { pushNotification, clearNotifications } =
      useCCAppNotificationStore();

    const [initialBuildAddress, setInitialBuildAddress] =
      useState<Address_BuildAddress>();
    const [isLoadingDialog, setIsLoadingDialog] = useState(false);

    const animalRegistrationObj = valueGetter(nameOfMapObj("Registration"));
    const [displayAddress, setDisplayAddress] = useState<string>(
      UIControl?.LitAddressDisplay.Value ?? ""
    );
    const [displayContact, setDisplayContact] = useState<string>(
      UIControl?.LitContact.Value ?? ""
    );
    const contactOwnerValue = valueGetter(`${getNameOf("Contact")}.Contact`);

    const handleOpenDialog = async () => {
      if (!animalRegistrationObj) return;
      setIsLoadingDialog(true);
      clearNotifications();
      const response = await setupRegistrationPickSiteAddress(
        animalRegistrationObj
      );
      setIsLoadingDialog(false);

      if (isSuccessResponse(response) && response.data) {
        setInitialBuildAddress(response.data.ReturnObj);
      } else {
        pushNotification({
          autoClose: false,
          title: "Setup address fail.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };

    const handleSubmitSiteAddress = (
      buildAddress: Address_BuildAddress | null
    ) => {
      const address = buildAddress?.Address;
      let params: RegistrationHandlerRequest = {
        FormAction: Svc_FormAction_Registration.Form_PickAddress,
        Registration: animalRegistrationObj,
        RegistrationArgs: { Address: address },
        IsFirstTimeLoad: false,
      };

      loadRegistrationHandle(params, "Change address failed.");
    };

    const handleChangeContact = (event: ComboBoxChangeEvent) => {
      const { value } = event;

      let animalHolderContactRID = 0;
      if (
        animalRegistrationObj?.Contact_RID &&
        animalRegistrationObj?.Contact?.Contact?.Contact_ID ===
          event?.value?.Contact_ID
      ) {
        animalHolderContactRID = animalRegistrationObj?.Contact_RID;
      }

      let params: RegistrationHandlerRequest = {
        FormAction: Svc_FormAction_Registration.Form_PickContact,
        Registration: animalRegistrationObj,
        RegistrationArgs: {
          Contact: {
            ...animalRegistrationObj?.Contact,
            Contact: value,
            RID: animalHolderContactRID,
          },
        },
        IsFirstTimeLoad: false,
      };

      loadRegistrationHandle(params, "Change contact failed.");
    };

    //* Will Open when handle other modules
    // const handleAnimalOwnerSameAsPropertyOwner = () => {
    //   let params: RegistrationHandlerRequest = {
    //     FormAction:
    //       Svc_FormAction_Registration.Form_AnimalOwnerSameAsPropertyOwner,
    //     Registration: animalRegistrationObj,
    //     RegistrationArgs: {},
    //     IsFirstTimeLoad: false,
    //   };

    //   loadRegistrationHandle(params, "Change address failed.");
    // };

    const updateSaveTriggers = (triggers: RegistrationUpdateTriggers) => {
      let saveTriggers: RegistrationUpdateTriggers[] =
        valueGetter(getNameOf("_SaveTriggers")) ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(getNameOf("_SaveTriggers"), {
          value: saveTriggers,
        });
      }
    };

    const siteAddressValidator = useCallback(() => {
      return requiredValidator(displayAddress);
    }, [displayAddress]);

    const contactOwnerValidator = useCallback(() => {
      return requiredValidator(
        UIControl?.LitContact?.Value
          ? UIControl.LitContact.Value.toString()
          : undefined
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactOwnerValue, UIControl?.LitContact?.Value]);

    const renderContact = () => {
      const contactHomePhone = valueGetter(
        `${getNameOf("Contact")}.Contact.HomePhone`
      );
      const contactWorkPhone = valueGetter(
        `${getNameOf("Contact")}.Contact.WorkPhone`
      );
      const contactMobile = valueGetter(
        `${getNameOf("Contact")}.Contact.Mobile`
      );
      const contactEmail = valueGetter(
        `${getNameOf("Contact")}.Contact.Email`
      )?.trim();

      return (
        <>
          {contactHomePhone && (
            <a href={`tel:${contactHomePhone}`} className="cc-contact">
              {contactHomePhone}(H)
            </a>
          )}
          {contactWorkPhone && (
            <a href={`tel:${contactWorkPhone}`} className="cc-contact">
              {contactWorkPhone}(W)
            </a>
          )}
          {contactMobile && (
            <a href={`tel:${contactMobile}`} className="cc-contact">
              {contactMobile}(M)
            </a>
          )}
          {contactEmail && (
            <a href={`mailto:${contactEmail}`} className="cc-contact">
              {contactEmail}
            </a>
          )}
        </>
      );
    };

    useEffect(() => {
      setDisplayContact(UIControl?.LitContact.Value);
      setDisplayAddress(UIControl?.LitAddressDisplay.Value);
    }, [UIControl]);

    return (
      <section className="cc-field-group">
        {/* ROW 1 */}
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Registered address" isMandatory />
            <Field
              uniqueKey="AddressPicker"
              name={getNameOf("Address")}
              component={PropertyDetail}
              initialData={initialBuildAddress}
              placeholder={"Select site address"}
              formRenderProps={props.formRenderProps}
              validator={siteAddressValidator}
              isSearchPropertyAddresses={true}
              onError={(error: any) => {
                pushNotification({
                  type: "error",
                  title: "Select site address failed.",
                  description: error,
                  autoClose: false,
                });
              }}
              value={displayAddress}
              onChangeEventHandler={handleSubmitSiteAddress}
              updateSaveTriggers={() => {
                updateSaveTriggers(RegistrationUpdateTriggers.UpdateAddress);
              }}
              onSubmit={handleSubmitSiteAddress}
              onButtonClick={handleOpenDialog}
              isLoadingDialog={isLoadingDialog}
              removeDisplayValue={() => {
                if (UIControl) {
                  prevAddressDisplayName.current = displayAddress;
                  setDisplayAddress(
                    animalInfo?.Address?.Formatted_SingleLine ?? ""
                  );
                }
              }}
              restoreDisplayValue={() => {
                if (
                  UIControl &&
                  isHTML(prevAddressDisplayName.current) &&
                  valueGetter(`${getNameOf("Address")}.Formatted_SingleLine`)
                ) {
                  setDisplayAddress(prevAddressDisplayName.current);
                }
              }}
              disabled={isDeleted}
              disabledButton={isDeleted}
            />
          </div>
          <CCValueField
            value={
              isHTML(UIControl?.LitAddress?.Value)
                ? sanitizeHtml(UIControl?.LitAddress?.Value)
                : UIControl?.LitAddress?.Value
            }
            label="Registered address details"
          />
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Animal owner
              <CCTooltip type="validator" position="right" />
              {/* <Button
                fillMode="flat"
                className="cc-animal-owner-button"
                iconClass="fa fa-chevron-circle-right"
                onClick={handleAnimalOwnerSameAsPropertyOwner}
              /> */}
              {/* Will Open when handle other modules */}
            </label>
            <Field
              uniqueKey="AnimalsContactPicker"
              name={`${nameOfMapObj("Registration")}.${nameOf(
                "Contact"
              )}.Contact`}
              component={ContactPicker}
              onError={(error: any) => {
                pushNotification({
                  type: "error",
                  title: "Pick contact errors",
                  description: error,
                  autoClose: false,
                });
              }}
              placeholder="Select contact"
              onChange={handleChangeContact}
              validator={contactOwnerValidator}
              displayValue={displayContact}
              removeDisplayValue={() => {
                if (UIControl) {
                  prevOwnerDisplayName.current = displayContact;
                  setDisplayContact(
                    animalInfo?.Contact?.Contact?.DisplayName ?? ""
                  );
                }
              }}
              restoreDisplayValue={() => {
                if (
                  UIControl &&
                  isHTML(prevOwnerDisplayName.current) &&
                  contactOwnerValue
                ) {
                  setDisplayContact(prevOwnerDisplayName.current);
                }
              }}
              disabled={isDeleted}
              disabledButton={isDeleted}
            />
          </div>
          <CCValueField
            value={
              isHTML(UIControl?.LitContactAddress?.Value)
                ? sanitizeHtml(UIControl?.LitContactAddress?.Value)
                : UIControl?.LitContactAddress?.Value
            }
            label="Postal address"
          />
          <CCValueField label="Contact" value={renderContact()} />
        </div>
      </section>
    );
  }
);
