import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const deleteApplicationCategories = async (
  ids?: any[]
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().delete(
      `/api/tp/internal/applicationcategory`,
      {
        data: ids,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
