import {
  getFullAddressSingleLine,
  getStringDisplayFromArrayObject,
} from "@common/utils/formatting";
import { CCValueField } from "@components/cc-value-field/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import React, { useMemo } from "react";

interface ISummaryFormElement {
  formRenderProps: FormRenderProps;
}

export const SummaryFormElement = ({
  formRenderProps,
}: ISummaryFormElement) => {
  const { valueGetter } = formRenderProps;
  const propertyAssessment = valueGetter("Address.PropertyAssessment");
  const zoneDisplay = getStringDisplayFromArrayObject(
    valueGetter("ZoneList"),
    "Name"
  );
  const overlayDisplay = getStringDisplayFromArrayObject(
    valueGetter("OverlayList"),
    "Name"
  );

  const displayZone = useMemo(() => {
    let displayZone = "";
    if (propertyAssessment?.Zones) {
      displayZone = propertyAssessment?.Zones;
    } else {
      displayZone = zoneDisplay;
    }
    return displayZone;
  }, [propertyAssessment, zoneDisplay]);

  const displayOverlay = useMemo(() => {
    let displayOverlay = "";
    if (propertyAssessment?.Overlay) {
      displayOverlay = propertyAssessment?.Overlay;
    } else {
      displayOverlay = overlayDisplay;
    }
    return displayOverlay;
  }, [overlayDisplay, propertyAssessment]);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">Address</label>
          <CCValueField
            value={getFullAddressSingleLine(valueGetter("Address"))}
          />
        </div>
        {displayZone && (
          <div className="cc-field">
            <label className="cc-label">Zones</label>
            <CCValueField value={displayZone} />
          </div>
        )}
        {displayOverlay && (
          <div className="cc-field">
            <label className="cc-label">Overlays</label>
            <CCValueField value={displayOverlay} />
          </div>
        )}
      </div>
    </section>
  );
};
