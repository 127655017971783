import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import {
  IBookmark,
  IBookmarkItem,
} from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";

export const getBookmarksByUserId = async (
  userId: string
): Promise<APIResponse<IBookmark[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IBookmark[]>(
      `api/core/internal/settings/bookmark`,
      {
        params: {
          userId,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postBookmark = async (
  requestBody: IBookmarkItem[]
): Promise<APIResponse<IBookmark>> => {
  try {
    return await CoreAPIService.getClient().post<IBookmark>(
      `/api/core/internal/settings/bookmark`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
