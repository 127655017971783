import { IBuildingEssentialSafetyResponse } from "@app/products/building/registers/essential-safety/[id]/model";

export const mockSingleBuildingEssentialSafety: IBuildingEssentialSafetyResponse =
  {
    ID: 20172,
    Status: "Open",
    Contact: "Tan Tran Open Office",
    ContactAddress: "BoundSoft1 4 Tanin Court",
    ContactContact: "+61 470 228 403",
    RegisterNumber: 92164,
    Address: "31 Caroline Avenue",
    Owners: "Wilson K M",
    Details: "L87 LP9628",
    Description: "Essential Safety Building",
    Officer: "Derin Davis",
    BCA: 0,
    InspectionFrequency: 10,
    LastInspection: "Not Yet Inspected",
    NextInspection: "Not Yet Inspected",
    Items: "No content",
    AppNo: "",
    RefNumber: 154784,
    FileNumber: 154784,
    CreatedDate: "10/20/2017",
    Lodged: "10/27/2017",
    DecisionType: "Approved",
    DecisionDate: "10/22/2017",
    OSFees: 13.99,
    DebtorNumber: "1457844",
    PlannerAssessment: "11111",
  };
