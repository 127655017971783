import { IdentityPacket } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { LookupTable } from "@app/products/crms/[id]/model";
import {
  LookupTableLovs,
  LookuptableHandlerRequest,
  LookuptableHandlerResponse,
} from "@app/products/crms/system-admin/lookup-tables/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket } from "@common/models/identityPacket";

export const getLookupTableById = async (
  id: number
): Promise<APIResponse<LookupTable>> => {
  try {
    return await CoreAPIService.getClient().get<LookupTable>(
      `/api/crms/internal/system-admin/lookup-table/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getLookupTableLovs = async (): Promise<
  APIResponse<LookupTableLovs>
> => {
  try {
    return await CoreAPIService.getClient().get<LookupTableLovs>(
      "/api/crms/internal/system-admin/lookup-table/lovs"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSaveLookupTable = async (
  lookupTable: LookupTable
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "api/crms/internal/system-admin/lookup-table",
      lookupTable
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postEventHandler = async (
  params: LookuptableHandlerRequest
): Promise<
  APIResponse<IIdentityPacket<LookuptableHandlerResponse> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<LookuptableHandlerResponse>
    >(
      `/api/crms/internal/system-admin/lookup-table/form-event-handler`,
      params
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
