import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { CreateComplianceDocumentButton } from "@app/products/property/assessments/compliance/[id]/components/actionbar/buttons/create-compliance-document/_index";
import { IssueComplianceDocumentButton } from "@app/products/property/assessments/compliance/[id]/components/actionbar/buttons/issue-compliance-document/_index";
import { ModifyComplianceButton } from "@app/products/property/assessments/compliance/[id]/components/actionbar/buttons/modify-compliance/_index";
import { ComplianceAssessment } from "@app/products/property/assessments/compliance/[id]/components/child-screens/assessment/_index";
import ComplianceFunctionalityRestrictions from "@app/products/property/assessments/compliance/[id]/components/child-screens/functionality-restrictions/_index";
import { ComplianceFunctionalityRestrictionsButton } from "@app/products/property/assessments/compliance/[id]/components/child-screens/functionality-restrictions/components/buttons/modify-functionality-restrictions/_index";
import { PropertyComplianceGeneral } from "@app/products/property/assessments/compliance/[id]/components/child-screens/general/_index";
import { ComplianceAssociationsTab } from "@app/products/property/assessments/compliance/[id]/components/reference-sidebar/associations/_index";
import { ComplianceDetailTab } from "@app/products/property/assessments/compliance/[id]/components/reference-sidebar/detail/_index";
import { useComplianceStore } from "@app/products/property/assessments/compliance/[id]/store";
import { LandTaxExemptionButton } from "@app/products/property/assessments/compliance/candidates/components/action-bar/buttons/land-tax-exemption/_index";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { ViewDocumentButton } from "@app/products/property/components/action-bar/button/view-document/_index";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyActions } from "@app/products/property/components/child-screen/actions";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import { eAccordion } from "@app/products/property/model";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { Label } from "@common/stores/products/config";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { eAccordionType } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

const ExistedPropertyCompliance = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const {
    compliance,
    complianceId,
    isLoading,
    responseLoadError,
    loadCompliance,
  } = useComplianceStore();
  const { currentUserInfo } = useGlobalStore();

  //Get label
  const assessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessment
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        compliance?.ComplianceId,
        compliance?.Assessments?.[0]?.RatepayerNameAddress,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Property - ${assessmentLabel} - Compliance - ${
        complianceId ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: complianceId ?? 0,
    },
  ];

  const listPanelBar: IPropertyPanelBar[] = [
    {
      accordionId: eAccordion.Compliance_Actions_And_Workflows,
      title: "Actions and Workflows",
      component: (
        <PropertyActions
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Compliance}
          componentNumber={eComponent.Compliance}
        />
      ),
      accordionType: eAccordionType.Actions_And_Workflows,
    },
    {
      accordionId: eAccordion.Compliance_Assessment,
      title: "Assessment",
      component: <ComplianceAssessment />,
    },
    {
      accordionId: eAccordion.Compliance_Functionality_Restrictions,
      title: "Functionality Restrictions",
      component: <ComplianceFunctionalityRestrictions />,
    },
    {
      accordionId: eAccordion.Compliance_Comments,
      title: "Comments",
      component: (
        <PropertyComments
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Compliance}
        />
      ),
    },
    {
      accordionId: eAccordion.Compliance_Related,
      title: "Related",
      component: (
        <PropertyAssociations
          id={complianceId}
          componentNumber={eComponent.Compliance}
        />
      ),
    },
    {
      accordionId: eAccordion.Compliance_Documents,
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={parseInt(id)}
          componentNumber={eComponent.Compliance}
        />
      ),
    },
  ];
  //regex to get the string before the first /r of compliance?.Compliance?.NameAddress
  const RatepayerOnly =
    compliance?.Compliance?.NameAddress?.match(/^(.*?)\r/)?.[1] ??
    compliance?.Compliance?.NameAddress;

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Compliance" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCompliance(+id)}
        />
      ) : (
        <>
          <FormTitle
            title={`${
              compliance?.ComplianceId ? "ID: " + compliance.ComplianceId : ""
            }${
              compliance?.Compliance?.NameAddress
                ? ", Ratepayer: " + RatepayerOnly
                : ""
            }`}
          />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Compliance"} type="dropdown">
                  <ModifyComplianceButton />
                </CCNavButton>
                <LandTaxExemptionButton />
                <ComplianceFunctionalityRestrictionsButton
                  complianceId={parseInt(id)}
                />
                <CCNavButton title="Documents" type="dropdown">
                  <CreateComplianceDocumentButton />
                  <IssueComplianceDocumentButton />
                  <ViewDocumentButton />
                </CCNavButton>
              </CCNavButton>,

              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Compliance}
                />
                <AddAttachmentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Compliance}
                />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Compliance}
                />
                {/* TODO: will be shown when it has a function */}
                {/* <CCNavButton title={"Contact"} /> */}
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {compliance && (
                  <CCGeneralPanel component={<PropertyComplianceGeneral />} />
                )}
                <PropertyPanelBar
                  initialPanels={listPanelBar}
                  componentId={parseInt(id)}
                  componentNumber={eComponent.Compliance}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <ComplianceDetailTab /> },
                    {
                      title: "Contacts",
                      component: (
                        <PropertyContactTab
                          id={parseInt(id)}
                          componentNumber={eComponent.Compliance}
                        />
                      ),
                    },
                    {
                      title: "Related",
                      component: <ComplianceAssociationsTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={RECORDTYPE.CommunityProperty_Compliance}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});

export default ExistedPropertyCompliance;
