import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingSystemAdminPermitTypes: IColumnFields[] = [
  {
    field: BuildingFieldMapping.Name,
    title: "Name",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.Code,
    title: "Code",
  },
  {
    field: BuildingFieldMapping.TargetDays,
    title: "Target Days",
  },
  {
    field: BuildingFieldMapping.Description,
    title: "Description",
  },
];
