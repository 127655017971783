import { IData } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  ACCESSCONTROLENTITYTYPE,
  ACCESSCONTROLRECORDTYPE,
  ACCESSCONTROLSOURCE,
  ACCESSRIGHTS,
} from "@common/constants/enumerations";
import { PickUserGroupOrg } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/_index";
import { IGroup } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/groups-grid/model";
import { IUsers } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/users-grid/model";
import { IDataUserGroupOrgForm } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/model";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { postSaveAccessControl } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/security/forms/security-settings/api";
import {
  colSupperAdminSettings,
  defaultSupperAdminSettingsStyleGrid,
  recordSourceDefault,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/security/forms/security-settings/config";
import { IAccessControl } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/security/forms/security-settings/model";
import { processAccessControl } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/security/forms/security-settings/util";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { NoData } from "@components/no-data/NoData";
import { Button } from "@progress/kendo-react-buttons";
import { uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<IAccessControl>();
export default observer(
  ({ formRenderProps, setObjectCustomSubmit }: IGlobalComponentFormProps) => {
    //store
    const { getConfigDataField } = useSettingGlobalStore();
    const { isEditing, setIsLoading, setIsEditing } =
      useSettingGlobalManageStore();
    const { pushNotification } = useCCAppNotificationStore();

    //state local
    const [isShowDialogAdd, setIsShowDialogAdd] = useState<boolean>(false);
    const [listSelected, setListSelected] = useState<IAccessControl[]>([]);
    const [isShowConfirmDeleteDialog, setIsShowConfirmDeleteDialog] =
      useState<boolean>(false);

    //props
    const { valueGetter, onChange } = formRenderProps;

    const fieldSuperAdmin = getConfigDataField("SuperAdmins");
    const listSuperAdmin =
      valueGetter(`option.ValueDisplayDetails_${fieldSuperAdmin?.FieldName}`) ??
      [];

    const handleSubmitData = (
      data: any,
      setData: React.Dispatch<React.SetStateAction<IData | undefined>>,
      setOriginData: React.Dispatch<React.SetStateAction<IData | undefined>>
    ) => {
      setIsLoading(true);
      const dataSubmit =
        data?.option[`ValueDisplayDetails_${fieldSuperAdmin?.FieldName}`];
      let dataSend: any = {
        RecordSource: null,
      };
      if (Array.isArray(dataSubmit)) {
        dataSend.RecordSource = recordSourceDefault;
        dataSend.AccessControls = dataSubmit;
      }

      postSaveAccessControl(dataSend).then((response) => {
        if (isSuccessResponse(response)) {
          setData(data);
          setOriginData(data);
          setIsEditing(false);
          setIsLoading(false);
          pushNotification({
            title: `Updated successfully`,
            type: "success",
          });
        } else {
          pushNotification({
            title: `Error occurred`,
            autoClose: false,
            type: "error",
          });
          setIsLoading(false);
        }
      });
    };
    useEffectOnce(() => {
      setObjectCustomSubmit(() => handleSubmitData);
    });
    /**
     * Function handle add new user
     * @param value
     */
    const handleAddNew = (value: IDataUserGroupOrgForm) => {
      let currentUser: any = [];
      const groups = value?.Groups ?? [];
      const orgStructure = value?.OrgStructure ?? [];
      const users = value?.Users ?? [];

      //check existed value was chosen in 3 cases
      if (groups.length || orgStructure.length) {
        currentUser = groups?.length ? groups : orgStructure;
        //process data user
        const newOrgOrGroup: IAccessControl = currentUser?.map(
          (item: IGroup) => {
            const newItem = processAccessControl(
              ACCESSCONTROLRECORDTYPE.SuperAdmin,
              ACCESSCONTROLSOURCE.ManualSelection,
              ACCESSRIGHTS.CanWrite,
              ACCESSCONTROLENTITYTYPE.OrgUnit,
              item.ID,
              item.Hierarchy
            );
            return newItem;
          }
        );
        currentUser = newOrgOrGroup;
      } else if (users.length) {
        //process data user
        const newUser = users?.map((item: IUsers) => {
          const newItem = processAccessControl(
            ACCESSCONTROLRECORDTYPE.SuperAdmin,
            ACCESSCONTROLSOURCE.ManualSelection,
            ACCESSRIGHTS.CanWrite,
            ACCESSCONTROLENTITYTYPE.SiteUser,
            item.ID,
            item.Name
          );
          return newItem;
        });
        currentUser = newUser;
      }

      //check valid and onChange data
      if (currentUser.length) {
        const newListAfterAdding = [...listSuperAdmin, ...currentUser];
        const uniqueList = uniqBy(newListAfterAdding, "EntityID");
        onChange(`option.ValueDisplayDetails_${fieldSuperAdmin?.FieldName}`, {
          value: uniqueList,
        });
      }
      setIsShowDialogAdd(false);
    };

    /**
     * Function handle Delete user
     */
    const handleDeleteUser = () => {
      if (listSelected.length) {
        const currentSelectUserId = listSelected[0]?.EntityID;
        const cloneListSuperAdmin = [...listSuperAdmin];
        const listAfterRemove = cloneListSuperAdmin.filter(
          (item: IAccessControl) => item.EntityID !== currentSelectUserId
        );
        onChange(`option.ValueDisplayDetails_${fieldSuperAdmin?.FieldName}`, {
          value: listAfterRemove,
        });
      }
      setListSelected([]);
      setIsShowConfirmDeleteDialog(false);
    };

    if (!fieldSuperAdmin) return <NoData vertical />;
    return (
      <div className="cc-agent-settings-form">
        <div className="cc-field">
          <label className="cc-label">{fieldSuperAdmin?.Title ?? ""}</label>
          {!isEditing ? (
            <CCGrid
              columnFields={colSupperAdminSettings}
              primaryField={nameOf("EntityID")}
              data={listSuperAdmin}
              style={defaultSupperAdminSettingsStyleGrid}
              itemPerPage={50}
              isAutoHiddenPager={false}
            />
          ) : (
            <CCGrid
              columnFields={colSupperAdminSettings}
              primaryField={nameOf("EntityID")}
              data={listSuperAdmin}
              selectableMode={"single"}
              selectedRows={listSelected}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-plus"
                    type="button"
                    title="Select Users, Groups, and Org Structures"
                    onClick={() => setIsShowDialogAdd(true)}
                    disabled={!isEditing}
                  />
                  <Button
                    type="button"
                    iconClass="fas fa-minus"
                    title="Remove"
                    disabled={!isEditing || listSelected.length !== 1}
                    onClick={() => setIsShowConfirmDeleteDialog(true)}
                  />
                </div>
              }
              onSelectionChange={(dataItem: IAccessControl[]) => {
                let listSelected: IAccessControl[] = [];
                if (dataItem) {
                  listSelected = dataItem;
                }
                setListSelected(listSelected);
              }}
              style={defaultSupperAdminSettingsStyleGrid}
              itemPerPage={50}
              isAutoHiddenPager={false}
            />
          )}
        </div>
        {isShowDialogAdd && (
          <PickUserGroupOrg
            onClose={() => setIsShowDialogAdd(false)}
            handleSubmit={handleAddNew}
          />
        )}
        {isShowConfirmDeleteDialog && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={"Are you sure you want to delete this item?"}
            onClosePopup={() => {
              setIsShowConfirmDeleteDialog(false);
            }}
            onConfirmYes={handleDeleteUser}
          />
        )}
      </div>
    );
  }
);
