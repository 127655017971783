import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Field } from "@progress/kendo-react-form";
import React from "react";

interface IApplicationSettingDropdownProps {
  enumParam: ECorporateSettingsField;
}

export const ApplicationSettingSearchComboBox = (
  props: IApplicationSettingDropdownProps
) => {
  const { configData } = useSettingPPRStore();
  const { isEditing } = useSettingPPRManageStore();
  const nameOfLookupItem = nameOfFactory<ILookupItem>();
  const { enumParam } = props;

  if (!configData) return <></>;
  return (
    configData[enumParam.toString()] && (
      <div className="cc-field">
        <CCLabel
          title={configData[enumParam.toString()].Title ?? ""}
          isMandatory={configData[enumParam.toString()].IsMandatory}
        />
        <Field
          name={configData[enumParam.toString()].FieldName}
          component={CCSearchComboBox}
          data={configData[enumParam.toString()].LookupItems}
          textField={nameOfLookupItem("Name")}
          dataItemKey={nameOfLookupItem("Id")}
          disabled={!isEditing}
          isUseDefaultOnchange
          validator={
            configData[enumParam.toString()].IsMandatory
              ? requiredValidator
              : undefined
          }
        />
      </div>
    )
  );
};
