import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import "./_index.scss";

export const ComplianceAssociationsTab = observer(() => {
  const { lastSelectedId } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const complianceId: string = params.id ?? lastSelectedId;

  return (
    <div className="cc-compliance-associations-tab">
      <PropertyAssociations
        isNoControl
        id={complianceId}
        ref={useRef<any>(null)}
        componentNumber={eComponent.Compliance}
      />
    </div>
  );
});
