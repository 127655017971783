import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colCRSPortalPortalAccounts: IColumnFields[] = [
  {
    field: CRSFieldMapping.ParentName,
    title: "Parent Name",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: CRSFieldMapping.ParentEmail,
    title: "Parent Email",
  },
  {
    field: CRSFieldMapping.ParentContact,
    title: "Parent Contact",
  },
  {
    field: CRSFieldMapping.LastLoginTime,
    title: "Last Login Time",

    format: DATE_FORMAT.DATE,
  },
];
