import { isSuccessResponse } from "@common/apis/util";
import { getAdditionalHearing } from "@common/pages/appeal/_id/components/child-screens/general/components/form-element/components/additonal-hearing/api";
import { IAdditionalHearing } from "@common/pages/appeal/_id/components/child-screens/general/components/form-element/components/additonal-hearing/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AdditionalHearingStore {
  private _isLoading: boolean = false;
  private _additionalHearing?: IAdditionalHearing[] = undefined;
  private _additionalHearingSelected?: IAdditionalHearing[] = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get additionalHearing() {
    return toJS(this._additionalHearing);
  }
  setAdditionalHearing = (additionalHearing?: IAdditionalHearing[]) => {
    runInAction(() => {
      this._additionalHearing = additionalHearing;
    });
  };

  get additionalHearingSelected() {
    return toJS(this._additionalHearingSelected);
  }
  setAdditionalHearingSelected = (
    additionalHearingSelected: IAdditionalHearing[]
  ) => {
    runInAction(() => {
      this._additionalHearingSelected = additionalHearingSelected;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._additionalHearing = undefined;
    });
  };

  loadAdditionalHearing = async (appealId?: number) => {
    if (appealId === undefined) return;
    this.setIsLoading(true);
    let newAdditionalHearing = undefined;

    const response = await getAdditionalHearing(appealId);
    if (isSuccessResponse(response)) {
      const additionalHearing = response.data;
      newAdditionalHearing = additionalHearing?.map(
        (item: IAdditionalHearing) => {
          return { AppealId: appealId, ...item };
        }
      );
    } else {
      appNotificationStore.pushNotification({
        type: "error",
        autoClose: false,
        title: response?.error ?? "Load additional heading failed",
      });
    }

    this.setAdditionalHearing(newAdditionalHearing);
    this.setIsLoading(false);
  };
}
export const additionalHearingStore = new AdditionalHearingStore();
const additionalHearingContext = createContext(additionalHearingStore);
export const useAdditionalHearingStore = () => {
  return useContext(additionalHearingContext);
};
