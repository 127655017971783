import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { Journal } from "@app/core/journal/_index";
import { SamplesAccordion } from "@app/core/samples/_index";
import { BuildingRegisterCouncilConsentForm } from "@app/products/building/registers/council-consent/[id]/components/child-screens/general/_index";
import { CouncilConsentContactsTab } from "@app/products/building/registers/council-consent/[id]/components/reference-sidebar/contacts/_index";
import { CouncilConsentDetailTab } from "@app/products/building/registers/council-consent/[id]/components/reference-sidebar/detail/_index";
import { BuildingRegistersCouncilConsentRecordsState } from "@app/products/building/registers/council-consent/[id]/model";
import { useBuildingRegistersCouncilConsentStore } from "@app/products/building/registers/council-consent/[id]/store";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import { default as React, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ExistedBuildingRegistersCouncilConsent = observer(() => {
  const { id } = useParams<{ id: string }>();

  const [showSlideBar, setShowSlideBar] = useState(true);
  const {
    loadCouncilConsent,
    councilConsent,
    resetStore,
    isLoading,
    responseLoadError,
    councilConsentId,
  } = useBuildingRegistersCouncilConsentStore();

  const managePageUrl = window.location.pathname;

  const { currentUserInfo } = useGlobalStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Building - Registers - Council Consent - ${
        councilConsentId ?? 0
      }`,
      Text:
        `${getDisplayTextWithDashes([
          councilConsent?.RegisterNumber,
          councilConsent?.Contact,
        ])}` || "N/A",
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Building,
      RecordType_ENUM: RECORDTYPE.BUILDING,
      Record_ID: councilConsentId ? +councilConsentId : 0,
    },
  ];

  useEffectOnce(() => {
    loadCouncilConsent(parseInt(id));
    return () => {
      resetStore();
    };
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CouncilConsentDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <CouncilConsentContactsTab /> },
      {
        title: "History",
        component: (
          <Journal id={0} recordType={RECORDTYPE.TP_BuildingApplication} />
        ),
      },
    ],
  });

  const [buildingRegistersCouncilConsentRecords] = useState<
    BuildingRegistersCouncilConsentRecordsState | undefined
  >(undefined);

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Addresses",
      totalRecord: buildingRegistersCouncilConsentRecords?.addresses,
      component: (
        <SamplesAccordion
          id={councilConsentId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Actions",
      totalRecord: buildingRegistersCouncilConsentRecords?.actions,
      component: (
        <Actions id={councilConsentId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Comments",
      totalRecord: buildingRegistersCouncilConsentRecords?.comments,
      component: (
        <Comments id={councilConsentId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Contacts",
      totalRecord: buildingRegistersCouncilConsentRecords?.contacts,
      component: (
        <Contacts id={councilConsentId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Documents",
      totalRecord: buildingRegistersCouncilConsentRecords?.documents,
      component: (
        <Documents
          id={parseInt(councilConsentId + "")}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Fees",
      totalRecord: buildingRegistersCouncilConsentRecords?.fees,
      component: (
        <Fees id={councilConsentId} recordType={RECORDTYPE.BUILDING} />
      ),
    },
    {
      title: "Inspections",
      totalRecord: buildingRegistersCouncilConsentRecords?.samples,
      component: (
        <SamplesAccordion
          id={councilConsentId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Related",
      totalRecord: buildingRegistersCouncilConsentRecords?.samples,
      component: (
        <SamplesAccordion
          id={councilConsentId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
  ];

  const getTitle = () => {
    const registerNumber = councilConsent?.RegisterNumber;
    const contact = councilConsent?.Contact;

    // If these two are not falsy, return both, otherwise return one or default
    if (registerNumber && contact) {
      return `${registerNumber} - ${contact}`;
    }

    return registerNumber?.toString() || contact || "N/A";
  };

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Council Consent" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCouncilConsent(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={getTitle()} badge={councilConsent?.Status} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              ></CCNavButton>,

              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Additional address"} />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Advertising"} />
                <CCNavButton title={"Appeal"} />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Comment"} />
                {/* TODO: enhance later */}
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.Building}
                  recordType={RECORDTYPE.TP_BuildingApplication}
                  recordId={parseInt(id)}
                />
                <CCNavButton title={"Complaint"} />
                <CCNavButton title={"Appeal document"} />
                <CCNavButton title={"Fee"} />
                <CCNavButton title={"Further information request"} />
                <CCNavButton title={"Inspection"} />
                <CCNavButton title={"IP - ad hoc"} />
                <CCNavButton title={"IP - from file"} />
                <CCNavButton title={"IP - from map"} />
                <CCNavButton title={"IP - from properties"} />
                <CCNavButton title={"Notice"} />
                <CCNavButton title={"Prosecution"} />
                <CCNavButton title={"Referral"} />
              </CCNavButton>,

              <CCNavButton title={"View"} type="sub">
                <CCNavButton title={"Documents"} />
                <CCNavButton title={"Journal"} />
                <CCNavButton title={"Project"} />
              </CCNavButton>,
              <CCNavButton title={"Print"} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {councilConsent && (
                  <CCGeneralPanel
                    component={<BuildingRegisterCouncilConsentForm />}
                  />
                )}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
