import { ICCRoute } from "@common/constants/ICCRoute";

export const centresRoute: ICCRoute = {
  path: "centres",
  name: "Centres",
  children: [
    {
      path: "centres",
      name: "Centres",
      component: require("./centres/_index").default,
    },
    {
      path: "hbc-capacity",
      name: "HBC Capacity",
      component: require("./hbc-capacity/_index").default,
    },
    {
      path: "cbc-capacity",
      name: "CBC Capacity",
      component: require("./cbc-capacity/_index").default,
    },
    {
      path: "3yo-capacity-by-year",
      name: "3yo Capacity By Year",
      component: require("./3yo-capacity-by-year/_index").default,
    },
    {
      path: "4yo-capacity-by-year",
      name: "4yo Capacity By Year",
      component: require("./4yo-capacity-by-year/_index").default,
    },
  ],
};
