import { colLocalLawsRenewals } from "@app/products/local-laws/config";
import { PermitRenewals } from "@app/products/local-laws/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<PermitRenewals>();
export const colLocalLawsRenewalsAllByDueStatus: IColumnFields[] = [
  ...colLocalLawsRenewals,
  { field: nameOf("DueStatus"), title: "Due Status" },
];
