import { IColumnFields } from "@components/cc-grid/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import {
  GridColumnMenuProps,
  GridColumnMenuSort,
} from "@progress/kendo-react-grid";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";
import "./_index.scss";

export const ColumnMenuListView = observer((props: GridColumnMenuProps) => {
  const {
    gridColumns,
    gridDataState,
    gridSelectedRows,
    setGridColumns,
    setGridDataState,
    filterBarState,
    setFilterBarState,
  } = useCCProductListViewStore();

  const isLocked = useMemo(() => {
    return gridColumns.find(
      (item: IColumnFields) => item.field === props.column.field
    )?.locked;
  }, [gridColumns, props.column]);

  const handelFilters = useCallback(
    (event) => {
      let newFilterState: CompositeFilterDescriptor = filterBarState
        ? {
            logic: filterBarState.logic,
            filters: [...filterBarState.filters],
          }
        : {
            logic: "and",
            filters: [],
          };
      let filterValue;
      let operator = "eq";
      switch (props.column.filter) {
        case "text":
          filterValue = "";
          operator = "contains";
          break;
        default:
          filterValue = null;
          break;
      }

      const newFilterItem: FilterDescriptor = {
        field: props.column.field,
        operator: operator,
        value: filterValue,
      };
      newFilterState.filters.push(newFilterItem);
      setFilterBarState(newFilterState);
    },

    [filterBarState, setFilterBarState, props.column]
  );

  const handelFilterThisValue = useCallback(
    (event) => {
      let newFilterState: CompositeFilterDescriptor = filterBarState
        ? {
            logic: filterBarState.logic,
            filters: [...filterBarState.filters],
          }
        : {
            logic: "and",
            filters: [],
          };
      let operator = "eq";
      if (props.column.filter === "text") {
        operator = "contains";
      }
      const newFilterItem: CompositeFilterDescriptor = {
        logic: "or",
        filters: gridSelectedRows.map((dataItem: any) => ({
          field: props.column.field,
          operator: operator,
          value: dataItem[props.column.field ?? ""],
        })),
      };
      newFilterState.filters.push(newFilterItem);

      setFilterBarState(newFilterState);
    },
    [
      filterBarState,
      props.column.filter,
      props.column.field,
      gridSelectedRows,
      setFilterBarState,
    ]
  );

  const handelClearFilters = useCallback(
    (event) => {
      setGridDataState({
        ...gridDataState,
        filter: { logic: "and", filters: [] },
      });
    },
    [setGridDataState, gridDataState]
  );

  const handelToggleLookColumn = useCallback(() => {
    setGridColumns(
      gridColumns.map((col: IColumnFields) => ({
        ...col,
        locked: col.field === props.column?.field ? !isLocked : col?.locked,
      }))
    );
  }, [gridColumns, setGridColumns, isLocked, props.column]);

  return (
    <div>
      <GridColumnMenuSort {...props} />

      <div className={"k-columnmenu-item"} onClick={handelFilters}>
        <span className="k-icon k-i-filter"></span>Filter
      </div>

      <div
        onClick={handelFilterThisValue}
        className={
          gridSelectedRows.length
            ? "k-columnmenu-item"
            : "k-columnmenu-item disabled"
        }
      >
        <span className="k-icon k-i-filter"></span>Filter to this value
      </div>

      <div
        onClick={handelClearFilters}
        className={
          gridDataState.filter && gridDataState.filter.filters.length > 0
            ? "k-columnmenu-item"
            : "k-columnmenu-item disabled"
        }
      >
        <span className="k-icon k-i-filter-clear"></span>Clear Filters
      </div>

      <div className={"k-columnmenu-item"} onClick={handelToggleLookColumn}>
        <span
          className={isLocked ? "k-icon k-i-unlock" : "k-icon k-i-lock"}
        ></span>
        {isLocked ? "Unlock Column" : "Lock Column"}
      </div>
    </div>
  );
});
