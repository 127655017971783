import { AssetDetailTab } from "@app/products/ams/assets/components/reference-sidebar/detail/_index";
import { assetRegisterColumns } from "@app/products/ams/assets/register/config";
import { CCV_ASSET_REGISTER } from "@app/products/ams/assets/register/model";
import { AmsRoute } from "@app/products/ams/constants/ams.route";
import { AmsUrl } from "@app/products/ams/constants/ams.url";
import { amsRoute } from "@app/products/ams/route";
import { animalRoute } from "@app/products/animals/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { getDisplayTextWithDashes, nameOfFactory } from "@common/utils/common";
import { CCActionBarNavDropdown } from "@components/cc-list-view-action-bar/components/nav-dropdown/_index";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

const nameOf = nameOfFactory<CCV_ASSET_REGISTER>();

export default (props: any) => {
  useCCListViewActionBar({
    title: animalRoute.name,
    leftComponents: [
      <CCActionBarNavDropdown
        category={"assets"}
        product={amsRoute.path}
        route={amsRoute}
      />,
    ],
    centerComponents: [
      <CCNavButton title="New Asset" linkTo={AmsRoute.ASSET_NEW_PATH} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={"/ams/assets/manage"}
        productType={PRODUCT_TYPE.CommunityAsset}
        recordType={RECORDTYPE.AssetManager_AssetRegister}
        detail={(selectedRow: any) => {
          return getDisplayTextWithDashes([
            selectedRow.AssetNumber,
            selectedRow.AssetDescription,
          ]);
        }}
        displayName={(selectedRow: any) => {
          const dynamicDisplayName = selectedRow.Id
            ? `- ${selectedRow.Id}`
            : "";
          return `Asset - Register ${dynamicDisplayName}`;
        }}
        listViewDisplayName={() => "Asset - Register"}
        listViewDetail={() => "Asset - Register - List"}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [{ title: "Details", component: <AssetDetailTab /> }],
  });

  return (
    <CCProductListView
      {...props}
      columnFields={assetRegisterColumns}
      dataUrl={AmsUrl.GET_VIEW_ASSET_REGISTER_ALL}
      primaryField={nameOf("Id")}
    />
  );
};
