import { ICCRoute } from "@common/constants/ICCRoute";

export const reviewRoute: ICCRoute = {
  path: "review",
  name: "Review",
  children: [
    {
      path: "photo-expiry",
      name: "Photo Expiry",
      component: require("./photo-expiry/_index").default,
    },
    {
      path: "report-due",
      name: "Report Due",
      component: require("./report-due/_index").default,
    },
    {
      path: "ethics-due",
      name: "Ethics Due",
      component: require("./ethics-due/_index").default,
    },
    {
      path: "by-condition-title",
      name: "By Condition Title",
      component: require("./by-condition-title/_index").default,
    },
  ],
};
