import { AddressFormElement } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/address/_index";
import { DetailFormElement } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/detail/_index";
import { MapFormElement } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/map/_index";
import { PropertyAddressPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/property-address-picker/_index";
import { SummaryFormElement } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/summary/_index";
import { Address } from "@app/products/waste-water/[id]/model";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import Loading from "@components/loading/Loading";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";

interface ISimplePropertyDetailFormElementProps {
  formRenderProps: FormRenderProps;
  setOriginalFormData: (value: Address) => void;
  notification?: IAppNotificationItemAddProps;
  isCRMS?: boolean;
  isHaveStreetLookup?: boolean;
  setIsLoadingValidate?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SimplePropertyDetailFormElement = ({
  formRenderProps,
  setOriginalFormData,
  notification,
  isCRMS,
  isHaveStreetLookup = false,
  setIsLoadingValidate,
}: ISimplePropertyDetailFormElementProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!notification) {
      notificationRef?.current?.resetNotifications();
    } else {
      notificationRef?.current?.pushNotification(notification);
    }
  }, [notification]);

  return (
    <FormElement className="cc-property-address-form-elements cc-form">
      <CCLocalNotification ref={notificationRef} />
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          <section className="cc-field-group">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Search</label>
                <Field
                  setIsLoadingValidate={setIsLoadingValidate}
                  formRenderProps={formRenderProps}
                  notificationRef={notificationRef}
                  name="Search"
                  component={PropertyAddressPicker}
                  isCRMS={isCRMS}
                  placeholder="Search"
                  setOriginalFormData={setOriginalFormData}
                  onError={(error: any) => {
                    notificationRef?.current?.pushNotification({
                      type: "error",
                      title: "Pick address errors",
                      description: error,
                      autoClose: false,
                    });
                  }}
                />
              </div>
            </div>
          </section>
          <hr className="cc-divider" />
          <AddressFormElement
            formRenderProps={formRenderProps}
            notificationRef={notificationRef}
            isCRMS={isCRMS}
            isHaveStreetLookup={isHaveStreetLookup}
            setIsLoading={setIsLoading}
          />
          <hr className="cc-divider" />
          <MapFormElement />
          <hr className="cc-divider" />
          <DetailFormElement
            formRenderProps={formRenderProps}
            notificationRef={notificationRef}
          />
          <hr className="cc-divider" />
          <SummaryFormElement formRenderProps={formRenderProps} />
        </>
      )}
    </FormElement>
  );
};
