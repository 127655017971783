import { IAccountsTransaction } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/accounts/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IAccountsTransaction>();

export const colAccountsTransaction: IColumnFields[] = [
  {
    field: nameOf("TagNo"),
    title: "Tag Number",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("AccountId"),
    title: "Account ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
