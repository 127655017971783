import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class TabTableStore {
  private _isDisabled: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isDisabled() {
    return toJS(this._isDisabled);
  }
  setIsDisabled = (isDisabled: boolean) => {
    runInAction(() => {
      this._isDisabled = isDisabled;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isDisabled = false;
    });
  };
}
export const tabTableStore = new TabTableStore();
const tabTableStoreContext = createContext(tabTableStore);
export const useTabTableStore = () => {
  return useContext(tabTableStoreContext);
};
