import { DTO_Component_UDAs } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const loadAttributesUDA = async (
  assessmentId: number
): Promise<DTO_Component_UDAs | undefined> => {
  try {
    const response = await CoreAPIService.getClient().get<DTO_Component_UDAs>(
      `api/property/internal/assessment/${assessmentId}/userdefinedattributes`
    );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
