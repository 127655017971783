import { useDetailParcelListViewStore } from "@app/products/property/parcels/locality-street-address/components/detail-parcel/components/grid-detail-parcel/store";
import { ExportFormat } from "@components/cc-grid/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const ActionBarExportIconParcelLocality = observer(() => {
  const { setGridExportFormat, isEmptyGridSelectedRow } =
    useDetailParcelListViewStore();

  return (
    <CCNavButton
      iconClass="fal fa-download"
      title="Export to Excel"
      disabled={isEmptyGridSelectedRow}
      onClick={() => {
        setGridExportFormat(ExportFormat.EXCEL);
      }}
    />
  );
});
