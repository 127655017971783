import { FIRE_PREVENTION_ROUTE } from "@app/products/property/fire-prevention/hazld-case/[id]/constant";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colPropertiesToReInspect: IColumnFields[] = [
  {
    field: "CaseId",
    title: "Case ID",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => {
      return `${FIRE_PREVENTION_ROUTE}/` + dataItem.CaseId;
    },
    locked: true,
  },
  { field: "Locality", title: "Locality" },
  {
    field: "HouseNumber",
    title: "House Number",
  },
  { field: "Street", title: "Street" },
  {
    field: "PropertyName",
    title: "Property Name",
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  { field: "Owners", title: "Owners" },
  {
    field: "ToBeClearedBy",
    title: "To Be Cleared By",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "AssignedContractor",
    title: "Assigned Contractor",
  },
  {
    field: "ContractorClearedOn",
    title: "Contractor Cleared On",
  },
  {
    field: "LastInspected",
    title: "Last Inspected",
    format: DATETIME_FORMAT.DATETIME,
  },
  { field: "Clearance", title: "Clearance" },
  { field: "OutcomeOfLastInspection", title: "Outcome of Last Inspection" },
  { field: "WorkOrderComment", title: "Work Order Comment" },
  { field: "Period", title: "Period" },
  {
    field: "ParcelId",
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
