import { AnimalType, FeeRulesList } from "@app/products/animals/model";
import { NonRegistrationFeeRules } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/components/non-registration-fee-rules/_index";
import { RegistrationFeeAdditionalChargeRules } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/components/registration-fee-additional-charge-rules/_index";
import { RegistrationFeeRules } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/components/registration-fee-rules/_index";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import { getNameOfAnimalType } from "@app/products/animals/system-admin/animal-types/[id]/util";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCLabel } from "@components/cc-label/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfAnimalType = nameOfFactory<AnimalType>();
const nameOfFeeRulesList = nameOfFactory<FeeRulesList>();
const nameOfKeyValuePacket = nameOfFactory<KeyValuePacket>();

export interface IFeeRuleProps {
  formRenderProps: FormRenderProps;
  animalTypeFormObject: AnimalType;
}

export const FeeRule = observer(
  ({ formRenderProps, animalTypeFormObject }: IFeeRuleProps) => {
    const { animalTypeLOVs } = useAnimalTypeStore();
    const { valueGetter, onChange } = formRenderProps;
    const feeCalculationMethodEnum = getNameOfAnimalType(
      nameOfAnimalType("FeeRulesList"),
      nameOfFeeRulesList("Method_ENUM")
    );
    const feeCalculationMethodName = getNameOfAnimalType(
      nameOfAnimalType("FeeRulesList"),
      nameOfFeeRulesList("Method_Name")
    );

    const handleOnChangeFeeCalculationMethod = (event: ComboBoxChangeEvent) => {
      onChange(feeCalculationMethodEnum, { value: event.value?.Key });
      onChange(feeCalculationMethodName, { value: event.value?.Value });
    };

    return (
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Registration fee calculation method" />
            <Field
              name={feeCalculationMethodEnum}
              component={CCComboBox}
              textField={nameOfKeyValuePacket("Value")}
              dataItemKey={nameOfKeyValuePacket("Key")}
              data={animalTypeLOVs?.FeeCalculationMethod}
              onChange={handleOnChangeFeeCalculationMethod}
              value={getDropdownValue(
                valueGetter(feeCalculationMethodEnum),
                animalTypeLOVs?.FeeCalculationMethod ?? [],
                nameOfKeyValuePacket("Key")
              )}
            />
          </div>
        </div>
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <RegistrationFeeRules
                animalTypeFormObject={animalTypeFormObject}
                formRenderProps={formRenderProps}
              />
            </div>
            <div className="cc-field">
              <RegistrationFeeAdditionalChargeRules
                animalTypeFormObject={animalTypeFormObject}
                formRenderProps={formRenderProps}
              />
            </div>
            <div className="cc-field">
              <NonRegistrationFeeRules
                animalTypeFormObject={animalTypeFormObject}
                formRenderProps={formRenderProps}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
