import {
  hiddenSomeInputsTypes,
  hiddenSwitchTypes,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/detail/config";
import { EKeysOfSteps } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {
    isReadOnly: false,
  },
}: IFormStepElement) => {
  const { valueGetter } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const typeChangeOfOwnership = valueGetter(`${EKeysOfSteps.Type}.Type`);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <div className="cc-field cc-col-span-2">
          <CCLabel title="Reference" />
          <Field
            name={nameOf("Reference")}
            component={CCInput}
            placeholder="Reference"
            readOnly={options?.isReadOnly}
          />
        </div>

        <div className="cc-field">
          <CCLabel title="Effective date" />
          <Field
            name={nameOf("EffectiveDate")}
            format={DATE_FORMAT.DATE_CONTROL}
            component={CCDatePicker}
            disabled={options?.isReadOnly}
          />
        </div>
        {!hiddenSomeInputsTypes.includes(typeChangeOfOwnership) && (
          <>
            <div className="cc-field">
              <CCLabel title="Sale price" />
              <Field
                name={nameOf("SalePrice")}
                component={CCCurrencyInput}
                disabled={options?.isReadOnly}
              />
            </div>

            <div className="cc-field">
              <CCLabel title="Contract date" />
              <Field
                name={nameOf("ContractDate")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
                disabled={options?.isReadOnly}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Chattels" />
              <Field
                name={nameOf("Chattels")}
                component={CCCurrencyInput}
                disabled={options?.isReadOnly}
              />
            </div>
          </>
        )}

        <div className="cc-field cc-col-span-2">
          <CCLabel title="Comment" />
          <Field
            name={nameOf("Comment")}
            component={CCTextArea}
            rows={3}
            placeholder="Comment"
            readOnly={options?.isReadOnly}
          />
        </div>
      </div>

      {!hiddenSwitchTypes.includes(typeChangeOfOwnership) && (
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Retain associated names" />
            <Field
              name={nameOf("RetainAssociatedNames")}
              component={CCSwitch}
              checked={getFieldValue("RetainAssociatedNames")}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Retain lease details" />
            <Field
              name={nameOf("RetainLeaseDetails")}
              component={CCSwitch}
              checked={getFieldValue("RetainLeaseDetails")}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Retain ratepayer details" />
            <Field
              name={nameOf("RetainRatePayerDetails")}
              component={CCSwitch}
              checked={getFieldValue("RetainRatePayerDetails")}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
      )}
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">LTO data</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <CCLabel title="Lease transfer id" />
                    <Field
                      name={"LeaseTransferId"}
                      value="165434"
                      component={CCInput}
                      readOnly
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Document type" />
                    <Field
                      name={"DocumentType"}
                      value="Transfer of Crown Lease"
                      component={CCInput}
                      readOnly
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Document number" />
                    <Field
                      name={"DocumentNumber"}
                      value="3122360"
                      component={CCInput}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="cc-field">
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <CCLabel title="Agreement date" />
                    <Field
                      name={"AgreementDate"}
                      value={new Date()}
                      format={DATE_FORMAT.DATE_CONTROL}
                      component={CCDatePicker}
                      disabled
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Settlement date" />
                    <Field
                      name={"SettlementDate"}
                      value="12/11/2021"
                      format={DATE_FORMAT.DATE_CONTROL}
                      component={CCDatePicker}
                      disabled
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Date of death" />
                    <Field
                      name={"DateOfDeath"}
                      value={new Date()}
                      format={DATE_FORMAT.DATE_CONTROL}
                      component={CCDatePicker}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
