import { history } from "@/AppRoutes";
import {
  getContact,
  getContactLovs,
  postContact,
} from "@app/core/contacts/_id/api";
import { CONTACT_ROUTE } from "@app/core/contacts/_id/constant";
import {
  ContactClassification,
  ContactLovs,
  ContactMethodPreferred,
  ContactSubmitActions,
  IContactParentSection,
  Svc_Contact,
} from "@app/core/contacts/_id/model";
import { getContactAlertFromStringArray } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isBadRequestResponse, isSuccessResponse } from "@common/apis/util";
import { AddressBookSection } from "@common/constants/enumerations";
import { DBRowState } from "@common/models/baseClassStandard";
import { IdentityPacket } from "@common/models/identityPacket";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { customLogger } from "@common/utils/logger";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isUndefined } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class ContactStore {
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _contact?: Svc_Contact = undefined;
  private _lovs?: ContactLovs = undefined;
  private _contactId?: number = undefined;
  private _isInactive: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isInactive() {
    return this._isInactive;
  }

  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get contact() {
    return this._contact;
  }
  setContact = (contact?: Svc_Contact) => {
    runInAction(() => {
      this._contact = contact;
    });
  };

  get lovs() {
    return this._lovs;
  }
  setLovs = (lovs?: ContactLovs) => {
    runInAction(() => {
      this._lovs = lovs;
    });
  };

  get contactId() {
    return this._contactId;
  }
  setContactId = (contactId?: number) => {
    runInAction(() => {
      this._contactId = contactId;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._onSubmit = undefined;
      this._lovs = undefined;
    });
  };

  loadContact = async (
    contactId?: number,
    parentSection?: IContactParentSection,
    isNew?: boolean
  ): Promise<boolean> => {
    this.setIsLoading(true);
    let newContact = undefined;
    let errorResponse = undefined;
    if (!isNew) {
      const response = await getContact(contactId ?? 0);
      customLogger("Core contact, loadContact").info(response?.data);
      if (isSuccessResponse(response)) {
        newContact = response.data;
        if (response.data?.Sys_DBRowState === DBRowState.Inactive) {
          this.setIsInactive(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important note.",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        }
        if (
          newContact?.Contact_Alerts &&
          newContact?.Contact_Alerts?.length > 0
        ) {
          const newNotifications = getContactAlertFromStringArray(
            newContact.Contact_Alerts
          );
          appNotificationStore.setNotifications(newNotifications);
        }
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
    }
    if (isNew !== undefined) {
      let newLovs = undefined;
      const lovResponse = await getContactLovs(isNew);
      customLogger("Core contact, load contact LOVs").info(lovResponse?.data);
      if (isSuccessResponse(lovResponse)) {
        newLovs = lovResponse.data;
      } else {
        errorResponse = {
          status: lovResponse.status,
          error: lovResponse.error,
        };
      }
      this.setLovs(newLovs);
      if (isNew) {
        //Initial Contact List
        newContact = {
          ContactClassification_ENUM: newLovs?.Classifications[0]
            ? ContactClassification[
                newLovs?.Classifications[0]
                  ?.Value as keyof typeof ContactClassification
              ]
            : ContactClassification.SystemInitialise,
          AddressBookSection_Int: AddressBookSection.Global,
          PreferredMethodOfContact_ENUM: ContactMethodPreferred.None,
        };
      }
    }
    const contact = newContact as Svc_Contact | undefined;
    this.setContact(contact);
    this.setContactId(contact?.Contact_Id ?? contactId);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);

    if (parentSection?.notification) {
      parentSection?.notification.forEach(
        (notification: IAppNotificationItemAddProps) => {
          appNotificationStore.pushNotification(notification);
        }
      );
    }
    return isUndefined(errorResponse);
  };

  reloadContact = async (): Promise<boolean> => {
    return await this.loadContact(this.contactId);
  };

  saveContact = async (contact: Svc_Contact, action: ContactSubmitActions) => {
    this.setIsLoading(true);
    const response = await postContact(contact);
    customLogger("Core contact, saveContact").info(response?.data);
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        await this.runActions(action, response.data);
      } else {
        this.setIsLoading(false);
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Contact could not be saved",
          type: "error",
          description: response.data?.Errors,
        });
      }
    } else if (isBadRequestResponse(response)) {
      this.setIsLoading(false);
      appNotificationStore.pushNotification({
        autoClose: false,
        type: "error",
        description: response.data?.Errors,
      });
    } else {
      this.setIsLoading(false);
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.error ?? "Contact could not be saved",
        type: "error",
      });
    }
  };

  runActions = async (action: ContactSubmitActions, data: IdentityPacket) => {
    switch (action) {
      case ContactSubmitActions.Save:
        await this.reloadContact();
        appNotificationStore.pushNotification({
          title: "Contact updated successfully",
          type: "success",
        });
        break;
      case ContactSubmitActions.New:
        appNotificationStore.clearErrorNotification();
        history.replace(`${CONTACT_ROUTE}/${data.ID}`, {
          notification: [
            { title: "Contact created successfully", type: "success" },
          ],
        });
        break;
      default:
        break;
    }
  };
}

export const contactStore = new ContactStore();
const contactStoreContext = createContext(contactStore);
export const useContactStore = () => {
  return useContext(contactStoreContext);
};
