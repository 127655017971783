import { ICCRoute } from "@common/constants/ICCRoute";

export const allApplicationsRoute: ICCRoute = {
  path: "all-applications",
  name: "All Applications",
  children: [
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
    {
      path: "register-search",
      name: "Register Search",
      component: require("./register-search/_index").default,
    },
    {
      path: "open",
      name: "Open",
      component: require("./open/_index").default,
    },
    {
      path: "my-applications",
      name: "My Applications",
      component: require("./my-applications/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      component: require("./by-status/_index").default,
    },
    {
      path: "by-location",
      name: "By Location",
      component: require("./by-location/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      component: require("./by-type/_index").default,
    },
    {
      path: "by-rating",
      name: "By Rating",
      component: require("./by-rating/_index").default,
    },
    {
      path: "by-ward",
      name: "By Ward",
      component: require("./by-ward/_index").default,
    },
    {
      path: "current-by-officer",
      name: "Current By Officer",
      component: require("./current-by-officer/_index").default,
    },
    {
      path: "vicsmart",
      name: "VicSmart",
      component: require("./vicsmart/_index").default,
    },
    {
      path: "no-appeal",
      name: "No Appeal",
      component: require("./no-appeal/_index").default,
    },
    {
      path: "register-state-govt",
      name: "Register State Govt.",
      component: require("./register-state-govt/_index").default,
    },
  ],
};
