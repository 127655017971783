import { IMetersUsage } from "@app/products/property/meters/[id]/components/child-screens/usage/model";
import { DECIMAL_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleAlignRight } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IMetersUsage>();
export const colUsage: IColumnFields[] = [
  {
    field: nameOf("Year"),
    title: "Year",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Jan"),
    title: "Jan",
    format: DECIMAL_FORMAT.DECIMAL1,
    style: formatStyleAlignRight,
  },
  {
    field: nameOf("Feb"),
    title: "Feb",
    format: DECIMAL_FORMAT.DECIMAL1,
    style: formatStyleAlignRight,
  },
  {
    field: nameOf("Mar"),
    title: "Mar",
    format: DECIMAL_FORMAT.DECIMAL1,
    style: formatStyleAlignRight,
  },
  {
    field: nameOf("Apr"),
    title: "Apr",
    format: DECIMAL_FORMAT.DECIMAL1,
    style: formatStyleAlignRight,
  },
  {
    field: nameOf("May"),
    title: "May",
    format: DECIMAL_FORMAT.DECIMAL1,
    style: formatStyleAlignRight,
  },
  {
    field: nameOf("Jun"),
    title: "Jun",
    format: DECIMAL_FORMAT.DECIMAL1,
    style: formatStyleAlignRight,
  },
  {
    field: nameOf("Jul"),
    title: "Jul",
    format: DECIMAL_FORMAT.DECIMAL1,
    style: formatStyleAlignRight,
  },
  {
    field: nameOf("Aug"),
    title: "Aug",
    format: DECIMAL_FORMAT.DECIMAL1,
    style: formatStyleAlignRight,
  },
  {
    field: nameOf("Sep"),
    title: "Sep",
    format: DECIMAL_FORMAT.DECIMAL1,
    style: formatStyleAlignRight,
  },
  {
    field: nameOf("Oct"),
    title: "Oct",
    format: DECIMAL_FORMAT.DECIMAL1,
    style: formatStyleAlignRight,
  },
  {
    field: nameOf("Nov"),
    title: "Nov",
    format: DECIMAL_FORMAT.DECIMAL1,
    style: formatStyleAlignRight,
  },
  {
    field: nameOf("Dec"),
    title: "Dec",
    format: DECIMAL_FORMAT.DECIMAL1,
    style: formatStyleAlignRight,
  },
  {
    field: nameOf("AnnualUsage"),
    title: "Annual Usage",
    format: DECIMAL_FORMAT.DECIMAL1,
    style: formatStyleAlignRight,
  },
];
