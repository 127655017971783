import {
  DTO_Assessment_Compliance_LandTaxExemption,
  DTO_Compliance,
  eComplianceHistoryFor,
} from "@app/products/property/assessments/[id]/components/child-screens/compliance/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfAssessmentComplianceAccordion = nameOfFactory<DTO_Compliance>();
const nameOfAssessmentLandTaxExemptionAccordion =
  nameOfFactory<DTO_Assessment_Compliance_LandTaxExemption>();
export const colAssessmentComplianceAccordion: IColumnFields[] = [
  {
    field: nameOfAssessmentComplianceAccordion("ComplianceId"),
    title: "Compliance ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfAssessmentComplianceAccordion("Name"),
    title: "Name",
  },
  {
    field: nameOfAssessmentComplianceAccordion("Status"),
    title: "Status",
  },
  {
    field: nameOfAssessmentComplianceAccordion("AlertMessage"),
    title: "Alert Message",
  },
  {
    field: nameOfAssessmentComplianceAccordion("Compliance_Date_From"),
    title: "Date From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentComplianceAccordion("Compliance_Date_To"),
    title: "Date To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentComplianceAccordion("Assessment10_3"),
    title: "Assessment 10.3",
  },
  {
    field: nameOfAssessmentComplianceAccordion("Assessment_S_S_B_U"),
    title: "Assessment S-S-B-U",
  },
  {
    field: nameOfAssessmentComplianceAccordion("Assessment_Property_Address"),
    title: "Assessment Property Address",
  },
  {
    field: nameOfAssessmentComplianceAccordion("Assessment_Legal_Description"),
    title: "Assessment Legal Description",
  },
  {
    field: nameOfAssessmentComplianceAccordion("Responsible_Officer"),
    title: "Responsible Officer",
  },
  {
    field: nameOfAssessmentComplianceAccordion("AssessmentId"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
export const colLandTaxExemptions: IColumnFields[] = [
  {
    field: nameOfAssessmentLandTaxExemptionAccordion("Land_Tax_Exemption_Id"),
    title: "Land Tax Exemption ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfAssessmentLandTaxExemptionAccordion("Name"),
    title: "Name",
  },
  {
    field: nameOfAssessmentLandTaxExemptionAccordion("Type"),
    title: "Type",
  },
  {
    field: nameOfAssessmentLandTaxExemptionAccordion("Reason"),
    title: "Reason",
  },
  {
    field: nameOfAssessmentLandTaxExemptionAccordion("Date_From"),
    title: "Date From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentLandTaxExemptionAccordion("Date_To"),
    title: "Date To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentLandTaxExemptionAccordion("Created_By"),
    title: "Created By",
  },
  {
    field: nameOfAssessmentLandTaxExemptionAccordion("Created_On"),
    title: "Created On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentLandTaxExemptionAccordion("Modified_By"),
    title: "Modified By",
  },
  {
    field: nameOfAssessmentLandTaxExemptionAccordion("Modified_On"),
    title: "Modified On",
    format: DATE_FORMAT.DATE,
  },
];

export const optionComplianceFor = [
  {
    label: "Ratepayer/owners",
    value: eComplianceHistoryFor.RATEPAYER_OWNERS,
  },
  {
    label: "Assessment",
    value: eComplianceHistoryFor.ASSESSMENT,
  },
];
