import { BuildingUrl } from "@app/products/building/model";
import { OfficerColumns } from "@app/products/waste-water/config";
import { FormRenderProps } from "@progress/kendo-react-form";

export const officerFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Officer",
  readOnly: true,
  placeholder: "Select Officee",
  tableTypes: OfficerColumns,
  url: BuildingUrl.GET_BUILDING_ISSUING_OFFICERS,
  fieldName: "Officer_Field",
  value: formRenderProps.valueGetter("Officer"),
  onChange: (event: CustomEvent) => {
    formRenderProps.onChange("Officer", {
      value: event.detail.Name,
    });
  },
});
