import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { propertyRoute } from "@app/products/property/route";
import { SupplementaryRatesDetailTab } from "@app/products/property/supplementary-rates/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_SUPPLEMENTARY_RATES_ROUTE } from "@app/products/property/supplementary-rates/[id]/constant";
import { NewSupplementaryButton } from "@app/products/property/supplementary-rates/components/action-bar/buttons/new-supplementary/_index";
import { ImportValuationsButton } from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/buttons/import-valuations/_index";
import { colList } from "@app/products/property/supplementary-rates/list/config";
import { GET_SUPPLEMENTARY_RATES_LIST_CREATED_BY_ME } from "@app/products/property/supplementary-rates/list/constant";
import { VO_Supplementary } from "@app/products/property/supplementary-rates/list/model";
import { supplementaryRatesRoute } from "@app/products/property/supplementary-rates/route";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDisplayName,
} from "@app/products/property/supplementary-rates/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { getBookmarkListViewDetail } from "./util";

export default () => {
  const nameOf = nameOfFactory<VO_Supplementary>();
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={supplementaryRatesRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <NewSupplementaryButton />
        <ImportValuationsButton />
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Supplementary}
        recordType={RECORDTYPE.CommunityProperty_Supplementary}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_SUPPLEMENTARY_RATES_ROUTE}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Supplementary}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <SupplementaryRatesDetailTab /> },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Supplementary}
          />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colList}
      dataUrl={GET_SUPPLEMENTARY_RATES_LIST_CREATED_BY_ME}
      primaryField={nameOf("Supplementary_Id")}
      state={{ sort: [{ field: nameOf("Supplementary_Id"), dir: "desc" }] }}
    />
  );
};
