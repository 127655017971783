import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { IdentityPacket } from "@common/models/identityPacket";
import {
  ProductListing,
  SecurityAction,
  SecurityTemplate,
} from "@common/pages/settings/security/security-templates/model";

export const getSecurityTemplateById = async (
  id: number
): Promise<APIResponse<SecurityTemplate | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<SecurityTemplate>(
      `/api/core/internal/security-templates/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSecurityTemplate = async (
  requestBody: SecurityTemplate
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/core/internal/security-templates`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getProductListing = async (): Promise<
  APIResponse<ProductListing[] | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<ProductListing[]>(
      `api/core/internal/security-templates/product-listing`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSecurityActions = async (
  productType: PRODUCT_TYPE,
  ids: number[]
): Promise<APIResponse<SecurityAction[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<SecurityAction[]>(
      `api/core/internal/security-actions/${productType}`,
      ids
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
