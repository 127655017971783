import {
  LIMIT_DATE_RENEW,
  TIME_PER_DATE,
} from "@app/products/animals/constant";
import { Kennel_Status } from "@app/products/animals/kennels/[id]/model";
import { useAnimalsKennelStore } from "@app/products/animals/kennels/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const KennelRenewButton = observer(() => {
  const {
    animalsKennel,
    isWorkflowLoading,
    isDeleted,
    setIsShowKennelRenewDialog,
  } = useAnimalsKennelStore();

  const isVisible = useMemo(() => {
    return animalsKennel?.Status_ENUM === Kennel_Status.Active;
  }, [animalsKennel]);

  const isDisable = useMemo(() => {
    let isDisable = false;

    if (!isNil(animalsKennel?.Date_EffectiveTo)) {
      let daysToRenewal = Math.round(
        (new Date(animalsKennel.Date_EffectiveTo).getTime() -
          new Date().getTime()) /
          TIME_PER_DATE
      );
      isDisable = daysToRenewal > LIMIT_DATE_RENEW;
    }
    return isDisable;
  }, [animalsKennel]);

  return isVisible ? (
    <CCNavButton
      title={"Renew"}
      onClick={() => {
        setIsShowKennelRenewDialog(true);
      }}
      disabled={isWorkflowLoading || isDisable || isDeleted}
    />
  ) : null;
});
