import { assessmentGISValidator } from "@app/products/property/assessments/components/util";
import {
  DTO_CreateParcel_Title,
  EParcelGridError,
} from "@app/products/property/parcels/components/form-steps/create-parcel/model";
import { nameOfFactory } from "@common/utils/common";

const nameOfTitle = nameOfFactory<DTO_CreateParcel_Title>();
export const validatorRelatedGrid = (value: any): string | undefined => {
  if (!value?.AssociateTitles?.length) {
    return EParcelGridError.TitleLength;
  } else {
    if (
      assessmentGISValidator(
        value?.AssociateTitles ?? [],
        nameOfTitle("Active_From"),
        nameOfTitle("Active_To")
      )
    )
      return EParcelGridError.Title;
  }
  if (
    assessmentGISValidator(
      value?.AssociateAssessments ?? [],
      nameOfTitle("Active_From"),
      nameOfTitle("Active_To")
    )
  )
    return EParcelGridError.Assessment;
  return undefined;
};
