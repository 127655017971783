import { Registration_Status } from "@app/products/animals/[id]/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import ImageDefault from "@assets/img/image-default.svg";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import {
  formatDisplayValue,
  formatOSFees,
  formatStyleRightSideBarImage,
} from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const AnimalDetailsTab = observer(() => {
  const isNew = useIsNew();
  const { lastSelectedId } = useCCProductListViewStore();
  const {
    animalInfo,
    loadDetails,
    isLoadingDetails,
    responseDetailLoadError,
    OSFee,
  } = useAnimalStore();

  const { id } = useParams<{ id: string }>();
  const animalsId: number = id ? parseInt(id) : lastSelectedId;

  useEffect(() => {
    if (!isNew && !id) loadDetails(animalsId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animalsId]);

  if (isLoadingDetails) return <Loading isLoading={isLoadingDetails} />;

  if (responseDetailLoadError) {
    return (
      <CCLoadFailed
        responseError={responseDetailLoadError}
        onReload={() => {
          animalsId && loadDetails(animalsId);
        }}
      />
    );
  }

  if (!animalInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Reference number:"}
        value={formatDisplayValue(
          animalInfo.Registration_Number,
          NUMBER_FORMAT.NUMBER2
        )}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Status:"}
          value={animalInfo?.Status_Name}
          success
        />
        <ProductReferenceRow
          title={"OS fees:"}
          format="currency"
          value={
            OSFee ? formatDisplayValue(OSFee, CURRENCY_FORMAT.CURRENCY1) : "$0"
          }
          style={formatOSFees(OSFee)}
          isPropertyStyle={false}
        />
        <ProductReferenceRow
          title={"Lodged:"}
          value={formatDisplayValue(animalInfo?.Date_Lodged, DATE_FORMAT.DATE)}
        />
        {!!animalInfo?.Date_EffectiveTo && (
          <ProductReferenceRow
            title={"Expires:"}
            value={
              animalInfo?.Status_ENUM !== Registration_Status.Deceased
                ? formatDisplayValue(
                    animalInfo?.Date_EffectiveTo,
                    DATE_FORMAT.DATE
                  )
                : undefined
            }
          />
        )}
        <ProductReferenceRow
          title={"Photo:"}
          image={
            animalInfo.PhotoAttachment?.FileEntry?.FileAttachment
              ? !animalInfo.PhotoAttachment?.FileEntry?.FileAttachment.toString().includes(
                  "blob:"
                )
                ? `data:${animalInfo.PhotoAttachment?.FileEntry?.ContentType};base64,` +
                  animalInfo.PhotoAttachment.FileEntry?.FileAttachment
                : animalInfo.PhotoAttachment.FileEntry?.FileAttachment
              : ImageDefault
          }
          style={formatStyleRightSideBarImage}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
