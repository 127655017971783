import { nameOfFactory } from "@common/utils/common";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import { colPickSuburb } from "@components/cc-pick-suburb/config";
import { Suburb } from "@components/cc-pick-suburb/model";
import React from "react";

interface ICCPickOrgSuburbProps extends ICCInputPickerProps {
  onPickSuburb: (value: Suburb | null) => void;
  selectableMode?: "single" | "multiple" | "none";
  dataUrl: string;
}
const nameOf = nameOfFactory<Suburb>();
export const CCPickSuburb = ({
  onChange,
  onError,
  onPickSuburb,
  selectableMode = "single",
  dataUrl = "/odata/core/internal/suburbs/GetSuburbs()?$count=true&",
  ...others
}: ICCPickOrgSuburbProps) => {
  const handleOnChangeSuburb = (event: ICCInputPickerChangeEvent) => {
    const value: Suburb | null = event.value;
    if (onPickSuburb) onPickSuburb(value);
  };
  return (
    <CCInputPicker
      {...others}
      dialog={{
        titleHeader: "Pick Suburb",
        maxWidth: "75%",
      }}
      grid={{
        columnFields: colPickSuburb,
        primaryField: nameOf("ID"),
        selectableMode: selectableMode,
        dataUrl: dataUrl,
        isAutoHiddenPager: false,
        state: { sort: [{ field: nameOf("Locality"), dir: "asc" }] },
      }}
      onChange={handleOnChangeSuburb}
    />
  );
};
