import { eventEmitter } from "@/App";
import { odataCoreGetSiteUsersByHomepageViewID } from "@app/core/components/common/utils";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { postHomePageRemoveUser } from "@common/pages/settings/homepage/homepage-shortcuts/api";
import { colsSideUsers } from "@common/pages/settings/homepage/homepage-views/[id]/components/child-screens/general/config";
import { useHomePageViewsStore } from "@common/pages/settings/homepage/homepage-views/[id]/store";
import { HomePageView } from "@common/pages/settings/homepage/homepage-views/model";
import {
  HomepageType,
  Svc_HomepagePageUser,
  Svc_HomepagePickUserRequest,
} from "@common/pages/settings/homepage/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FormElement } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

const nameOf = nameOfFactory<HomePageView>();
const nameOfHomepagePageUser = nameOfFactory<Svc_HomepagePageUser>();

export const HomepageViewsFormElement = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { pushNotification } = useCCAppNotificationStore();
  const { homepageLovs, setIsLoading } = useHomePageViewsStore();
  const [gridSelectedRows, setGridSelectedRows] = useState<
    Svc_HomepagePageUser[]
  >([]);

  const handleSideUserDelete = async () => {
    setIsLoading(true);
    const ids: number[] = gridSelectedRows?.map(
      (item: Svc_HomepagePageUser) => item.ID
    );
    const params: Svc_HomepagePickUserRequest = {
      HomepageIDs: [parseInt(id)],
      SiteUserIDs: ids,
      HomepageType: HomepageType.HomepageView,
    };
    const response = await postHomePageRemoveUser(params);
    setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      pushNotification({
        title: "Remove user successfully.",
        description: response.data?.Errors,
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
    } else {
      pushNotification({
        autoClose: false,
        title: "Remove user fail.",
        type: "error",
        description: response.data?.Errors ?? response.error,
      });
    }
  };

  return (
    <FormElement>
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Product type" isMandatory />
            <Field
              name={nameOf("ProductType_ENUM")}
              dataItemKey="ProductType_ENUM"
              textField="ProductType_Name"
              data={homepageLovs ?? []}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={true}
              validator={requiredValidator}
            />
          </div>

          <div className="cc-field">
            <CCLabel title="View heading" isMandatory />
            <Field
              name={nameOf("ViewHeading")}
              placeholder="View heading"
              component={CCInput}
              validator={requiredValidator}
            />
          </div>
        </div>

        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              dataUrl={odataCoreGetSiteUsersByHomepageViewID(id)}
              columnFields={colsSideUsers}
              primaryField={nameOfHomepagePageUser("ID")}
              selectableMode="multiple"
              selectedRows={gridSelectedRows}
              onSelectionChange={(dataItem: Svc_HomepagePageUser[]) => {
                setGridSelectedRows([...dataItem]);
              }}
              state={{
                sort: [
                  { field: nameOfHomepagePageUser("DisplayName"), dir: "asc" },
                ],
                filter: {
                  logic: "and",
                  filters: [
                    {
                      field: nameOfHomepagePageUser("IsActive"),
                      operator: "eq",
                      value: true,
                    },
                  ],
                },
              }}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    type="button"
                    iconClass="fas fa-minus"
                    title="Remove"
                    disabled={gridSelectedRows.length === 0}
                    onClick={handleSideUserDelete}
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>
    </FormElement>
  );
});
