import { ECustomColNameProperty } from "@app/products/property/config";
import { DtoGeneral } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/master-property-adjustments/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const GeneralFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfDtoGeneral = nameOfFactory<DtoGeneral>();
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    //Get label
    const assessmentLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Assessment
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title={`${assessmentLabel} number`} />
            <Field
              name={nameOf(nameOfDtoGeneral("Assessment_Number"))}
              component={CCInput}
              placeholder={`${assessmentLabel} number`}
              readOnly
            />
          </div>
        </div>
        <div className="cc-field">
          <CCLabel title="Formatted address" />
          <Field
            name={nameOf(nameOfDtoGeneral("Address"))}
            component={CCInput}
            placeholder="Formatted address"
            readOnly
          />
        </div>
        <div className="cc-field cc-col-span-2">
          <CCLabel title="Reason for supplementary" />
          <Field
            name={nameOf(nameOfDtoGeneral("Reason"))}
            component={CCTextArea}
            rows={3}
            placeholder="Reason for supplementary"
            readOnly={options?.isReadOnly}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Effective date" />
          <Field
            name={nameOf(nameOfDtoGeneral("EffectiveDate"))}
            format={DATE_FORMAT.DATE_CONTROL}
            value={getFieldValue("EffectiveDate")}
            onChange={(e: DatePickerChangeEvent) => {
              onChange(nameOf("EffectiveDate"), {
                value: e.value ? e.value : new Date(),
              });
            }}
            component={CCDatePicker}
            disabled={options?.isReadOnly}
          />
        </div>
      </section>
    );
  }
);
