import { DirectDebitAccount } from "@app/products/direct-debit/accounts/model";
import { IntegrationModuleType } from "@app/products/direct-debit/model";
import { nameOfFactory } from "@common/utils/common";
import { CCValueField } from "@components/cc-value-field/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IDDAccountDetailsSectionProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}
export interface IDirectDebitAccountManagePage extends DirectDebitAccount {
  _options?: {
    ItemTypesDisplay: string;
  };
}

const nameOf = nameOfFactory<IDirectDebitAccountManagePage>();

export const DDAccountDetailsSection = observer(
  ({ formRenderProps }: IDDAccountDetailsSectionProps) => {
    const { valueGetter } = formRenderProps;
    const getValue = (nameOfField: keyof IDirectDebitAccountManagePage) =>
      valueGetter(nameOf(nameOfField));

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCValueField
              value={getValue("Integration_Module_Type_Name")}
              label="Module type"
            />
          </div>
          <div className="cc-field">
            <CCValueField
              value={getValue("Account_Reference")}
              label="Account reference"
            />
          </div>
          <div className="cc-field">
            <CCValueField
              value={getValue("Integration_Module_Item_Type_Name")}
              label="Item type"
            />
          </div>
        </div>

        {getValue("Integration_Module_Type_ENUM") ===
          IntegrationModuleType.PropertyRegister && (
          <div className="cc-form-cols-3">
            <CCValueField value={getValue("Register_ID")} label="Register ID" />
            <CCValueField
              value={getValue("Register_Account_ID")}
              label="Register account ID"
            />
            <CCValueField
              value={getValue("Property_Address")}
              label="Property address"
            />
          </div>
        )}

        {getValue("Integration_Module_Type_ENUM") ===
          IntegrationModuleType.PropertyAssessment && (
          <div className="cc-form-cols-3">
            <CCValueField
              value={getValue("AssessmentID")}
              label="Assessment ID"
            />
            <CCValueField
              value={getValue("Assessment_Number")}
              label="Assessment number"
            />
            <CCValueField
              value={getValue("Assessment_Reference")}
              label="Assessment reference"
            />
            <CCValueField
              value={getValue("Property_Address")}
              label="Property address"
            />
          </div>
        )}

        {getValue("Integration_Module_Type_ENUM") ===
          IntegrationModuleType.CommunityFinance && (
          <div className="cc-form-cols-3">
            <CCValueField
              value={getValue("Finance_Customer_No")}
              label="Finance customer number"
            />
            <CCValueField
              value={getValue("Finance_Invoice_No")}
              label="Finance invoice number"
            />
          </div>
        )}
      </section>
    );
  }
);
