import { DTO_Certificate_AreaOfResponsibility } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/model";
import { DTO_Certificate_LOVs } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IGetCertificateAreaOfResponsibility =
  | [
      APIResponse<DTO_Certificate_LOVs>,
      APIResponse<DTO_Certificate_AreaOfResponsibility>
    ]
  | APIResponse<DTO_Certificate_AreaOfResponsibility | DTO_Certificate_LOVs>
  | undefined;
export const getCertificateAreaOfResponsibility = async (
  certificateId: number,
  aorId: number
): Promise<IGetCertificateAreaOfResponsibility> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Certificate_LOVs>(
        `api/property/int/certificate/lovs`
      ),
      CoreAPIService.getClient().get<DTO_Certificate_AreaOfResponsibility>(
        `api/property/int/certificate/${certificateId}/areaofresponsibility?area_of_responsible_id=${aorId}`
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
