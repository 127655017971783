import { IIssue } from "@app/core/issues/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IIssue>();
export const colIssues: IColumnFields[] = [
  {
    field: nameOf("AppliesTo"),
    title: "Applies To",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Source"),
    title: "Source",
  },
  {
    field: nameOf("DateCreated"),
    title: "Date Created",
  },
  {
    field: nameOf("DateCompleted"),
    title: "Date Completed",
  },
];
