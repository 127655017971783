import { AppealForm } from "@common/pages/appeal/_id/components/child-screens/general/_index";
import { AppealSubmitActions } from "@common/pages/appeal/_id/model";
import { useAppealStore } from "@common/pages/appeal/_id/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewAppeal = observer(() => {
  const { isLoading, onSubmit, appeal } = useAppealStore();
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Appeal"} />

      <FormTitle title="New Appeal" />

      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          ></CCNavButton>,
          <CCNavButton
            title={"Save"}
            onClick={onSubmit}
            name={AppealSubmitActions.New}
          />,
          <CCNavButton title={"Add"} type="sub"></CCNavButton>,
          <CCNavButton title={"View"} />,
          <CCNavButton title={"More Options"} type="more"></CCNavButton>,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <div className="cc-manage-form-body">{appeal && <AppealForm />}</div>
        </div>
      </div>
    </>
  );
});
