import { ModifyCollectionButton } from "@app/products/property/collections/components/action-bar/buttons/modify-collection/_index";
import { NewCollectionButton } from "@app/products/property/collections/components/action-bar/buttons/new-collection/_index";
import { CollectionDetailTab } from "@app/products/property/collections/components/reference-sidebar/detail/_index";
import { colCollectionList } from "@app/products/property/collections/list/config";
import {
  COLLECTIONS_LIST_ODATA,
  PROPERTY_COLLECTION_ROUTE,
} from "@app/products/property/collections/list/constant";
import { collectionsRoute } from "@app/products/property/collections/route";
import { CollectionBookmark } from "@app/products/property/collections/util";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { propertyRoute } from "@app/products/property/route";
import { APIResponseError } from "@common/apis/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React, { useState } from "react";
import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ViewConfiguration } from "@app/products/property/model";
import { useEffectOnce } from "react-use";
import Loading from "@components/loading/Loading";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { VO_Collection } from "@app/products/property/collections/list/model";

const nameOf = nameOfFactory<VO_Collection>();

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [colsConfigured, setColsConfigured] = useState<IColumnFields[]>([]);

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={collectionsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <NewCollectionButton />
        <ModifyCollectionButton />
        {/* <DeleteCollectionButton /> TODO: #9752: Hide it for now. */}
        {/* [9752]Not Implementing in this phase of development */}
        {/* <ShowCollectionButton /> */}
      </CCNavButton>,
    ],

    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_COLLECTION_ROUTE}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Collection}
        detail={CollectionBookmark.getBookmarkDetail}
        displayName={CollectionBookmark.getBookmarkDisplayName}
        listViewDisplayName={CollectionBookmark.getBookmarkListViewDisplayName}
        listViewDetail={CollectionBookmark.getBookmarkListViewDetail}
        // TODO: Remove when have a new requirement about manage page.
        directToOtherPlace={{
          notManagePage: true,
          urlDirect: (data: any) => "/property/collections/list",
        }}
      />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CollectionDetailTab /> },
    ],
  });

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Collections_List,
      colCollectionList
    );
    if (Array.isArray(response)) {
      setColsConfigured(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    (async () => await loadViewConfig())();
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <CCProductListView
      columnFields={colsConfigured}
      primaryField={nameOf("Collection_Id")}
      dataUrl={COLLECTIONS_LIST_ODATA}
      state={{
        sort: [{ field: nameOf("Collection_Is_Active"), dir: "desc" }],
      }}
    />
  );
};
