import { getTitleDetailById } from "@app/products/property/titles/[id]/api";
import { DTO_Title_Details } from "@app/products/property/titles/[id]/model";
import { getContactAlertFromStringArray } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { ICCNotification } from "@components/cc-notification/components/cc-notification-item/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class TitleStore {
  private _title?: DTO_Title_Details = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _notifications: ICCNotification[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }

  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get title() {
    return toJS(this._title);
  }

  setTitle = (title?: DTO_Title_Details) => {
    runInAction(() => {
      this._title = title;
    });
  };

  get notifications() {
    return toJS(this._notifications);
  }

  setNotifications = (notifications: ICCNotification[]) => {
    runInAction(() => {
      this._notifications = notifications;
    });
  };

  get isLoading() {
    return this._isLoading;
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get titleId() {
    return toJS(this.title?.titleId);
  }

  //Action

  resetStore = () => {
    runInAction(() => {
      this._title = undefined;
      this._isLoading = false;
      this._notifications = [];
    });
  };

  loadTitle = async (
    titleId: number,
    notification?: IAppNotificationItemAddProps
  ) => {
    this.setIsLoading(true);
    const response = await getTitleDetailById(titleId);
    let newTitle = undefined;
    let errorResponse = undefined;
    if (isSuccessResponse(response)) {
      newTitle = response.data;
      if (
        newTitle?.titleSummary?.Contact_Alerts &&
        newTitle?.titleSummary?.Contact_Alerts?.length > 0
      ) {
        const newNotifications = getContactAlertFromStringArray(
          newTitle.titleSummary.Contact_Alerts
        );
        newNotifications.forEach((notification) => {
          appNotificationStore.pushNotification(notification);
        });
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setTitle(newTitle);
    this.setIsLoading(false);
    if (notification) {
      appNotificationStore.pushNotification(notification);
    }
  };
}

const titleStoreContext = createContext(new TitleStore());
export const useTitleStore = () => {
  return useContext(titleStoreContext);
};
