import { useAppealCompleteButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/appeal-complete-application/store";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { VARCHAR_MAX } from "@app/products/town-planning/ppr/components/action-bar/dialogs/appeal-complete-application/constant";
import { IPPRAppealCompleted } from "@app/products/town-planning/ppr/components/action-bar/dialogs/appeal-complete-application/model";
import { useAppealCompleteDialogStore } from "@app/products/town-planning/ppr/components/action-bar/dialogs/appeal-complete-application/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
const nameOfPPRAppeal = nameOfFactory<IPPRAppealCompleted>();
interface IAppealCompleteApplicationDialogProps {
  onClose: () => void;
  onSubmit: (values: IPPRAppealCompleted) => void;
}

export const AppealCompleteApplicationDialog = observer(
  ({ onClose, onSubmit }: IAppealCompleteApplicationDialogProps) => {
    const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
      onSubmit(event.values as IPPRAppealCompleted);
    };
    const {
      isLoadingDialog,
      appeals,
      appealDecisions,
      responseLoadError,
      resetStore,
      loadFormData,
    } = useAppealCompleteDialogStore();
    const { isLoading } = useAppealCompleteButtonStore();
    const [appeal] = useState<IPPRAppealCompleted>(Object);
    const { ppr } = usePPRStore();
    const applicationNumber = ppr?.ApplicationNumberDisplay || "";

    useEffectOnce(() => {
      loadFormData(applicationNumber);
      return () => resetStore();
    });

    return (
      <>
        <Loading isLoading={isLoadingDialog} isFullScreen />
        {responseLoadError ? (
          <CCLoadFailed
            responseError={responseLoadError}
            onReload={() => {
              loadFormData(applicationNumber);
            }}
          />
        ) : (
          <Form
            onSubmitClick={handleOnSubmitClick}
            initialValues={appeal}
            render={(formRenderProps: FormRenderProps) => {
              return (
                <FormElement>
                  <CCDialog
                    titleHeader={"Appeal Complete"}
                    onClose={onClose}
                    maxWidth={"25%"}
                    height={"auto"}
                    disabled={isLoading}
                    bodyElement={
                      <div className="cc-form">
                        <section className="cc-field-group">
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <label className="cc-label">
                                Appeal
                                <CCTooltip
                                  type="validator"
                                  position="right"
                                  content={"Choose an appeal record to save"}
                                />
                              </label>
                              <Field
                                name={nameOfPPRAppeal("Appeal_ID")}
                                textField="Value"
                                dataItemKey="Key"
                                component={CCSearchComboBox}
                                data={appeals}
                                validator={requiredValidator}
                                isUseDefaultOnchange
                              />
                            </div>
                          </div>
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <label className="cc-label">
                                VCAT decision
                                <CCTooltip
                                  type="validator"
                                  position="right"
                                  content={"Choose a decision"}
                                />
                              </label>
                              <Field
                                name={nameOfPPRAppeal("AppealDecision_KWD")}
                                textField="Name"
                                dataItemKey="Keyword_ID"
                                component={CCSearchComboBox}
                                data={appealDecisions}
                                validator={requiredValidator}
                                isUseDefaultOnchange
                              />
                            </div>
                          </div>
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <label className="cc-label">
                                Decision date
                                <CCTooltip
                                  type="validator"
                                  position="right"
                                  content={"Choose decision date"}
                                />
                              </label>
                              <Field
                                name={nameOfPPRAppeal("DecisionDate")}
                                component={CCDatePicker}
                                validator={requiredValidator}
                                format={DATE_FORMAT.DATE_CONTROL}
                              />
                            </div>
                          </div>
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <label className="cc-label">VCAT member</label>
                              <Field
                                name={nameOfPPRAppeal("TribunalMember")}
                                component={CCInput}
                                placeholder="VCAT member"
                              />
                            </div>
                          </div>
                          <br />
                          <label className="cc-label">Decision</label>
                          <div className="cc-custom-sub-panel-bar">
                            <div className="cc-form-cols-1">
                              <div className="cc-field">
                                <label className="cc-label">
                                  Decision status
                                </label>
                                <Field
                                  name={nameOfPPRAppeal("DecisionStatus")}
                                  component={CCInput}
                                  placeholder="Decision status"
                                />
                              </div>
                            </div>
                            <br />
                            <div className="cc-form-cols-1">
                              <div className="cc-field">
                                <label className="cc-label">
                                  Decision/Policy issues
                                </label>
                                <Field
                                  rows={3}
                                  maxLength={VARCHAR_MAX}
                                  name={nameOfPPRAppeal("DecisionPolicyIssues")}
                                  component={CCTextArea}
                                  placeholder="Decision/Policy issues"
                                />
                              </div>
                            </div>
                            <br />
                            <div className="cc-form-cols-1">
                              <div className="cc-field">
                                <label className="cc-label">Key issues</label>
                                <Field
                                  rows={3}
                                  maxLength={VARCHAR_MAX}
                                  name={nameOfPPRAppeal("KeyIssues")}
                                  component={CCTextArea}
                                  placeholder="Key issues"
                                />
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    }
                    footerElement={
                      <div className="cc-dialog-footer-actions-right">
                        <Button className="cc-dialog-button" onClick={onClose}>
                          Cancel
                        </Button>
                        <Button
                          className="cc-dialog-button"
                          themeColor="primary"
                          disabled={!formRenderProps.valid}
                          onClick={formRenderProps.onSubmit}
                          iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                        >
                          Save
                        </Button>
                      </div>
                    }
                  />
                </FormElement>
              );
            }}
          />
        )}
      </>
    );
  }
);
