import { Button } from "@progress/kendo-react-buttons";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";
import "./_index.scss";
interface ISettingButtonEditProps {
  isEditing: boolean | undefined;
  handleOnCancel: any;
  handleOnEdit: any;
  formRenderProps?: FormRenderProps;
  isLoading?: boolean;
}

export const SettingButtonEdit: React.FC<ISettingButtonEditProps> = ({
  isEditing,
  handleOnEdit,
  handleOnCancel,
  formRenderProps,
  isLoading,
}) => {
  return (
    <div className="cc-setting-button-edit">
      {!isEditing && !isLoading ? (
        <Button
          className="cc-manage-button"
          onClick={(e: any) => {
            e.preventDefault();
            handleOnEdit();
          }}
          iconClass="fa fa-pencil"
          fillMode="flat"
          title="Edit"
        />
      ) : (
        <>
          <Button
            className={`${
              !formRenderProps?.valid || !formRenderProps?.modified
                ? "cc-sub-button-disabled"
                : "cc-save-button"
            }`}
            disabled={
              isLoading || !formRenderProps?.valid || !formRenderProps?.modified
                ? true
                : false
            }
            onClick={(e: any) => {
              formRenderProps?.onSubmit(e);
              formRenderProps?.onFormReset();
            }}
            iconClass={
              isLoading ? "fas fa-spinner fa-spin" : "fa fa-check-circle"
            }
            fillMode="flat"
            title="Save"
          />
          <Button
            className="cc-cancel-button"
            onClick={(e) => {
              handleOnCancel(e);
            }}
            disabled={isLoading}
            iconClass="fa fa-times-circle"
            fillMode="flat"
            title="Cancel"
          />
        </>
      )}
    </div>
  );
};
