import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import {
  DTO_Title_LOVs,
  DTO_Workflow_CreateTitle,
  RequestInputObj,
} from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postProcessCreateTitle = async (
  workflowType: WorkflowProcessMode,
  titleData: DTO_Workflow_CreateTitle
): Promise<APIResponse<ResponseMessageWorkflow<DTO_Workflow_CreateTitle>>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/createtitle/process/${workflowType}`,
      titleData
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowCreateTitle = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_CreateTitle>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/createtitle/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataTitle =
  | [APIResponse<DTO_Title_LOVs>, APIResponse<DTO_Workflow_CreateTitle>]
  | APIResponse<DTO_Title_LOVs | DTO_Workflow_CreateTitle>
  | undefined;
export const getInitialDataTitle = async (
  workflowDraftId: number | undefined
): Promise<IGetInitialDataTitle> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Title_LOVs>(
        `api/property/internal/workflow/title/lovs`
      ),
      !isNil(workflowDraftId)
        ? getWorkflowCreateTitle(workflowDraftId)
        : postNewWorkflowCreateTitle(),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewWorkflowCreateTitle = async (
  requestObjSpatial: RequestInputObj | object = {}
): Promise<APIResponse<DTO_Workflow_CreateTitle>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/createtitle/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
