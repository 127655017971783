import {
  ILookupItem,
  SettingField,
} from "@app/products/town-planning/ppr/system-admin/settings/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ComboBoxProps } from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import React from "react";

export interface IComboBoxGlobalSettingsProps
  extends Omit<ComboBoxProps, "data"> {
  data: SettingField | undefined;
  isEditing: boolean;
  isFieldVisible?: boolean;
}
const nameOfLookupItem = nameOfFactory<ILookupItem>();

export const ComboBoxGlobalSettings = ({
  data,
  isEditing,
  isFieldVisible = true,
  disabled,
  ...others
}: IComboBoxGlobalSettingsProps) => {
  return data && isFieldVisible ? (
    <div className="cc-field">
      <label className="cc-label">
        {data?.Title ?? ""}
        {data?.IsMandatory && <CCTooltip type="validator" position="right" />}
        {data?.HelpText && (
          <CCTooltip
            type="custom"
            content=" "
            position="auto"
            clickToOpen
            customTemplate={<>{sanitizeHtml(data.HelpText)}</>}
          >
            <i className="fa fa-info-circle ml-1 text-accent" />
          </CCTooltip>
        )}
      </label>
      {data?.Description && (
        <label className="cc-settings-description">
          {sanitizeHtml(data?.Description)}
        </label>
      )}
      <Field
        {...others}
        name={data?.FieldName}
        component={CCComboBox}
        data={data?.LookupItems ?? []}
        textField={nameOfLookupItem("Name")}
        dataItemKey={nameOfLookupItem("Id")}
        disabled={disabled || !isEditing}
        validator={data.IsMandatory ? requiredValidator : undefined}
      />
    </div>
  ) : null;
};
