import { DirectDebitMenu } from "@app/products/direct-debit/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const ddAuthorisationsRoute: ICCRoute = {
  path: "authorisations",
  name: "Authorisations",
  enum: DirectDebitMenu.Authorisations,
  children: [
    {
      path: "by-authorisation",
      name: "By Authorisation",
      enum: DirectDebitMenu.AuthorisationsByAuthorisation,
      component: require("./by-authorisation/_index").default,
    },
    {
      path: "by-contact",
      name: "By Contact",
      enum: DirectDebitMenu.AuthorisationsByContact,
      component: require("./by-contact/_index").default,
    },
    {
      path: "vip",
      name: "VIP",
      enum: DirectDebitMenu.AuthorisationsVIP,
      component: require("./vip/_index").default,
    },
  ],
};
