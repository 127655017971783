import { DTO_LOV } from "@common/models/odataResponse";
import { filter, first } from "lodash";

export const getNameFromLovs = (
  lovs: DTO_LOV[] | undefined,
  fieldValue: string
): string => {
  return (
    first(filter(lovs ?? [], (item: any) => item.Code === fieldValue))?.Name ??
    ""
  );
};
