import { putRejectLetter } from "@app/core/further-info/components/buttons/work-flow/reject-letter/api";
import { furtherInfoStore } from "@app/core/further-info/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class RejectLetterButtonStore {
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  rejectLetterFIRequest = async (id: number) => {
    this.setIsLoading(true);
    const response = await putRejectLetter(id);
    if (isSuccessIdentityPacket(response)) {
      appNotificationStore.clearErrorNotification();
      furtherInfoStore.reLoadFurtherInfo().then(() => {
        appNotificationStore.pushNotification({
          type: "success",
          title: "Reject letter successfully",
        });
      });
      if (
        response.data?.Notifications &&
        response.data?.Notifications.length > 0
      ) {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Reject letter further information request",
          type: "warning",
          description: response.data?.Notifications,
        });
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Reject letter further information request failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsLoading(false);
  };
}
export const rejectLetterButtonStore = new RejectLetterButtonStore();
const rejectLetterButtonContext = createContext(rejectLetterButtonStore);
export const useRejectLetterButtonStore = () => {
  return useContext(rejectLetterButtonContext);
};
