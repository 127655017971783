export interface DTO_Journal_RaisePenaltyInterest {
  InterestFromDate?: Date;
  InterestToDate?: Date;
  InstalmentPlanId?: number;
}

export enum eLoadingRaisePenalty {
  Finish = "Finish",
  GetData = "GetData",
  None = "None",
}
