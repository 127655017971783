import { HMFieldMapping } from "@app/products/hm/model";
import { DATE_FORMAT } from "@common/constants/common-format";

export const colHMGovtReportsTobaccoInspections = [
  {
    field: HMFieldMapping.TradingName,
    title: "Trading Name",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.Address,
    title: "Address",
    width: 150,
  },
  {
    field: HMFieldMapping.Suburb,
    title: "Suburb",
    width: 150,
  },
  {
    field: HMFieldMapping.DateInspected,
    title: "Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.Contacted,
    title: "Contact Person",
    width: 150,
  },
  {
    field: HMFieldMapping.Phone,
    title: "Phone",
    width: 150,
  },
  {
    field: HMFieldMapping.InspectionType,
    title: "Inspection Type",
    width: 150,
  },
  {
    field: HMFieldMapping.Compliance,
    title: "Compliance",
    width: 150,
  },
  {
    field: HMFieldMapping.Licenced,
    title: "Licenced",
    width: 150,
  },
  {
    field: HMFieldMapping.EatIn,
    title: "Eat In",
    width: 150,
  },
  {
    field: HMFieldMapping.VendingMachine,
    title: "Vending",
    width: 150,
  },
  {
    field: HMFieldMapping.Gaming,
    title: "Gaming",
    width: 150,
  },
  {
    field: HMFieldMapping.Retail,
    title: "Retail",
    width: 150,
  },
  {
    field: HMFieldMapping.Workplace,
    title: "Workplace",
    width: 150,
  },
];
