import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colAuditHistory: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.RecordedDate,
    title: "Recorded Date",
    width: 350,
    format: DATE_FORMAT.DATE,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.RecordedBy,
    title: "Recorded By",
    width: 350,
  },
  {
    field: TownPlanningFieldMapping.ParentRefNo,
    title: "Reference Number",
    width: 350,
  },
  {
    field: TownPlanningFieldMapping.ParentDesc,
    title: "Record",
    width: 350,
  },
  {
    field: TownPlanningFieldMapping.Title,
    title: "Title",
    width: 350,
  },
  {
    field: TownPlanningFieldMapping.Description,
    title: "Description",
    width: 350,
  },
];
