import { VO_PIC_Transaction_NotIn_Journal } from "@app/products/property/journals/not-yet-in-journal/pic-transactions/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_PIC_Transaction_NotIn_Journal>();
export const colListJournalPICTransaction: IColumnFields[] = [
  {
    field: nameOf("PIC_Number"),
    title: "PIC Number",
  },
  {
    field: nameOf("Transaction_Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("PIC_TT_Name"),
    title: "Transaction Type",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("Is_Proposed"),
    title: "Proposed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Is_Cancelled"),
    title: "Cancelled",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("PIC_Transaction_Id"),
    title: "Transaction ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("PIC_Id"),
    title: "PIC ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
