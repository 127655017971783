import { loadMasterPropertyDetailsTabById } from "@app/products/property/assessments/master-properties/[id]/components/reference-sidebar/detail/api";
import { DTO_MasterProperty_TabDetails } from "@app/products/property/assessments/master-properties/[id]/components/reference-sidebar/detail/model";
import { isSuccessResponse } from "@common/apis/util";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const MasterPropertyDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [masterPropertyInfo, setMasterPropertyInfo] = useState<
    DTO_MasterProperty_TabDetails | undefined
  >();

  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const masterPropertyId: string =
    lastSelectedRow && lastSelectedRow?.Id ? lastSelectedRow?.Id : params.id;

  useEffect(() => {
    if (masterPropertyId) {
      setIsLoading(true);
      loadMasterPropertyDetailsTabById(parseInt(masterPropertyId)).then(
        (resp) => {
          if (isSuccessResponse(resp)) {
            setMasterPropertyInfo(resp.data);
          }
          setIsLoading(false);
        }
      );
    }
  }, [masterPropertyId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!masterPropertyInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Master Property Id:"}
              value={masterPropertyId + ""}
              primary
            />
          }
        >
          <>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Type:"}
                value={masterPropertyInfo?.Master_Property_Type_Name}
              />
              <ProductReferenceRow
                title={"Description:"}
                value={masterPropertyInfo?.MP_Description}
              />
              <ProductReferenceRow
                title={"Address:"}
                value={masterPropertyInfo?.MP_Property_Address}
              />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
