import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import {
  DTO_Component_Accordion,
  eAccordionType,
} from "@components/custom-panelbar/model";

export const postAccordionsCount = async (
  componentNumber: number | undefined,
  componentId: number | undefined,
  accordionType: eAccordionType[]
): Promise<APIResponse<DTO_Component_Accordion>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/accordion-count/${componentNumber}/${componentId}`,
      accordionType
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
