import { eventEmitter } from "@/App";
import { postFinanceInvoiceExport } from "@app/core/invoice/components/buttons/export-to-finance/api";
import { ProductType } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { isSuccessResponse } from "@common/apis/util";

import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IFinanceExportInvoiceProps {
  isAllRecords?: boolean;
  isDisabled?: boolean;
  productType: ProductType;
}

export const FinanceExportInvoice = observer(
  ({
    isDisabled = false,
    isAllRecords = false,
    productType,
  }: IFinanceExportInvoiceProps) => {
    const { isEmptyGridSelectedRow, gridSelectedIds, clearSelectedItems } =
      useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleOnClick = async () => {
      setIsSubmitting(true);
      const response = await postFinanceInvoiceExport(
        isAllRecords ? [] : gridSelectedIds,
        isAllRecords,
        productType
      );
      setIsSubmitting(false);

      if (isSuccessResponse(response)) {
        clearSelectedItems();
        eventEmitter.emit(CCGridEventType.RefreshOData);
      } else {
        pushNotification({
          autoClose: false,
          type: "error",
          title: "Finance export failed.",
          description: response.data?.Errors ?? response.error,
        });
      }
    };

    // loading when call API
    if (isSubmitting) return <Loading isLoading={isSubmitting} isFullScreen />;

    return (
      <CCNavButton
        title={
          isAllRecords ? "Export all to finance" : "Export selected to finance"
        }
        disabled={(isEmptyGridSelectedRow && !isAllRecords) || isDisabled}
        onClick={handleOnClick}
      />
    );
  }
);
