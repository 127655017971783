import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Journal } from "@app/core/journal/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { loadAllParkingPermits } from "@app/products/parking-permits/api";
import { DisabledSameAddrButton } from "@app/products/parking-permits/components/action-bar/buttons/disabled-same-addr/_index";
import { DisabledButton } from "@app/products/parking-permits/components/action-bar/buttons/disabled/_index";
import { ParkingSameAddrButton } from "@app/products/parking-permits/components/action-bar/buttons/parking-same-addr/_index";
import { ParkingButton } from "@app/products/parking-permits/components/action-bar/buttons/parking/_index";
import { ParkingPermitsActionBarNavDropdown } from "@app/products/parking-permits/components/action-bar/nav-dropdown/_index";
import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import { parkingPermitsRoute } from "@app/products/parking-permits/route";
import { ParkingPermitsSystemAdminExportDataBookmark } from "@app/products/parking-permits/system-admin/export-data/util";
import { ParkingPermitsBookmark } from "@app/products/parking-permits/util";
import { ParkingPermitsContactTab } from "@app/products/parking-permits/[id]/components/reference-sidebar/contact/_index";
import { ParkingPermitsDetailTab } from "@app/products/parking-permits/[id]/components/reference-sidebar/details/_index";
import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { systemAdminRoute } from "../route";
import { colParkingPermitsSystemAdminExportData } from "./config";

export default () => {
  useCCListViewActionBar({
    title: parkingPermitsRoute.name,
    leftComponents: [
      <ParkingPermitsActionBarNavDropdown category={systemAdminRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton title="New" type="sub">
        <DisabledButton />
        <DisabledSameAddrButton />
        <ParkingButton />
        <ParkingSameAddrButton />
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.DISRES}
        recordType={RECORDTYPE.DisRes_Permit}
        dataSetFilter={MAILMERGEDATASET.DISRES_Permit}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.DISRES}
        recordType={RECORDTYPE.DisRes_Permit}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PARKING_PERMITS_ROUTE}
        productType={PRODUCT_TYPE.DISRES}
        //TODO don't have exactly record type, temporary use LLP_Permit
        recordType={RECORDTYPE.DisRes_Permit}
        detail={ParkingPermitsBookmark.getBookmarkDetail}
        displayName={ParkingPermitsBookmark.getBookmarkDisplayName}
        listViewDetail={
          ParkingPermitsSystemAdminExportDataBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          ParkingPermitsSystemAdminExportDataBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <ParkingPermitsDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Contacts",
        component: <ParkingPermitsContactTab />,
      },

      {
        title: "History",
        component: (
          <Journal id={3235079} recordType={RECORDTYPE.DisRes_Permit} />
        ),
      },
    ],
  });
  return (
    <CCProductListView
      columnFields={colParkingPermitsSystemAdminExportData}
      data={loadAllParkingPermits() as []}
      state={{
        sort: [
          { field: ParkingPermitsFieldMapping.PermitType_Name, dir: "asc" },
        ],
      }}
      // dataUrl={ParkingPermitsUrl.GET_VIEW_PARKINGPERMITS_SYSTEMADMIN_EXPORTDATA}
      primaryField="ID"
    />
  );
};
