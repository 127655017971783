import { DTO_MasterProperty_AssessmentRange } from "@app/products/property/assessments/master-properties/components/form-steps/add-new-master-property/components/form-elements/associations/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getMasterPropertyAssessments = async (
  asessmentRangeData: DTO_MasterProperty_AssessmentRange
): Promise<APIResponse<DTO_MasterProperty_AssessmentRange | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/assessment/range?asessmentNoFrom=${
        asessmentRangeData?.AsessmentNumberFrom
      }&asessmentNoTo=${
        asessmentRangeData?.AsessmentNumberTo ??
        asessmentRangeData?.AsessmentNumberFrom
      }`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
