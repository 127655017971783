export class IBuildingDemolitionResponse {
  ID?: number = 0;
  RegisterNumber?: number = 0;
  Status?: string = "Pending";
  Contact?: string = "";
  ContactAddress?: string = "";
  ContactContact?: string = "";

  Address?: string = "";
  Owners?: string = "";
  Details?: string = "";
  Description?: string = "";
  Officer?: string = "";
  AppNo?: string = "";
  RefNumber?: string = "";
  FileNumber?: string = "";
  Lodged?: Date | string = "";
  DecisionType?: string = "";
  CreatedDate?: Date | string = "";
  DecisionDate?: Date | string = "";
  OSFees?: number = 0;
  DebtorNumber?: string = "";
  PlannerAssessment?: string = "";
}
export class IBuildingDemolitionRequest extends IBuildingDemolitionResponse {}
export class IBuildingDemolitionFormData extends IBuildingDemolitionResponse {}

export class IBuildingDemolitionRecordState {
  addresses: number = 0;
  actions: number = 0;
  comments: number = 0;
  contacts: number = 0;
  documents: number = 0;
  fees: number = 0;
  inspections: number = 0;
  related: number = 0;
}
