import { IAccountResponsible } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-meter/components/form-elements/transferring/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const mockBillingGroup: IKeyValuePacket[] = [
  {
    Key: 1,
    Value: "Monthly Billing",
  },
  {
    Key: 2,
    Value: "Standard Periodic Billing",
  },
];
export const mockAccountResponsible: IAccountResponsible[] = [
  {
    AssessmentNumber: 106200000,
    FormattedAddress: "10 Hermitage Drive, Airlie Beach",
    AssessmentID: 2979,
    DateOfChange: new Date("16-May-2015"),
  },
  {
    AssessmentNumber: 106200000.0001,
    FormattedAddress: "Multiple Payments in Arrears 2009/10",
    AssessmentID: 9019,
    DateOfChange: new Date("11-May-2014"),
  },
  {
    AssessmentNumber: 106200000.0002,
    FormattedAddress: "2885 Benor Rd, Archies Creek",
    AssessmentID: 67,
    DateOfChange: new Date("6-Jan-2014"),
  },
];
