import { useRegisterMetersStore } from "@app/products/property/registers/[id]/components/child-screens/meters/store";
import { mockDisassociateMeterDialog } from "@app/products/property/registers/[id]/components/dialogs/disassociate-meter/mock";
import { IInitialDisassociateMeter } from "@app/products/property/registers/[id]/components/dialogs/disassociate-meter/model";
import { DisassociateMeterDialog } from "@app/products/property/registers/[id]/components/dialogs/disassociate-meter/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const DisassociateMeterButton = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { metersSelected } = useRegisterMetersStore();
  const { pushNotification } = useCCAppNotificationStore();

  const initialDataForm = useMemo(() => {
    return {
      ...metersSelected[0],
      ...mockDisassociateMeterDialog,
    } as IInitialDisassociateMeter;
  }, [metersSelected]);

  return (
    <>
      <CCNavButton
        title="Disassociate meter"
        onClick={() => setShowDialog(true)}
        disabled={metersSelected.length !== 1}
      />
      {showDialog && (
        <DisassociateMeterDialog
          initialData={initialDataForm}
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={() => {
            setShowDialog(false);
            pushNotification({
              title: `Meter was disassociated from account successfully`,
              type: "success",
            });
          }}
        />
      )}
    </>
  );
});
