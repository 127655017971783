import { MasterPropertyValuation } from "@app/products/property/assessments/master-properties/[id]/components/child-screens/valuations/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getMasterPropertiesValuations = async (
  id?: number,
  isListByValuation?: boolean
): Promise<APIResponse<MasterPropertyValuation | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/masterproperty/${id}/valuations`,
      {
        params: {
          byRatingPeriod: !isListByValuation,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
