import React, { Fragment, ReactNode, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { PanelBarItem } from "@progress/kendo-react-layout";
import { ItemTypes } from "../util/itemTypes";

export interface ItemTracker {
  name: string;
  ordinalPosition: number;
}

interface IAcceptType {
  itemType: string,
  handleDrop: (groupTarget: ItemTracker, item: any) => void;  //not sure i cannot make this 'any' unless i start using generics?
}

interface IProps {
  currentItem: ItemTracker;
  title?: ReactNode;
  expanded?: boolean;
  moveItem?: (dragItemIdx: number, hoverItemIdx: number) => void;
  acceptItems?: IAcceptType[];
}

export const GroupPanelWrapper: React.FC<IProps> = ({
  currentItem,
  title,
  expanded,
  moveItem,
  acceptItems,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const allAccept = [ItemTypes.EntityGroupLayoutElement, ...acceptItems!.map((r) => r.itemType)];

  const [, drop] = useDrop({
    accept: allAccept,
    drop(item: any, monitor) {
      if(item.type !== ItemTypes.EntityGroupLayoutElement) {
        const acceptor = acceptItems?.find( (r) => r.itemType === item.type);
        if(acceptor) {
          acceptor.handleDrop(item, currentItem);
        }

      }
    },
    hover(draggingItem: any, monitor) {
      if (!ref.current || draggingItem.type !== ItemTypes.EntityGroupLayoutElement) {
        return;
      }

      const dragIndex = draggingItem.ordinalPosition;
      const hoverIndex = currentItem.ordinalPosition;

      if (dragIndex === hoverIndex) {
        return;
      }

      if (null !== ref.current) {
        const hoveredRect = ref.current.getBoundingClientRect();
        const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
        const mousePosition = monitor.getClientOffset();

        if (null !== mousePosition) {
          const hoverClientY = mousePosition.y - hoveredRect.top;

          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
          }

          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
          }

          if (!!moveItem) {
            moveItem(dragIndex, hoverIndex);
            draggingItem.ordinalPosition = hoverIndex;
          }
        }
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.EntityGroupLayoutElement, ...currentItem },
    
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  //still not sure why this is in here, but i need it.
  drag(drop(ref));

  return (
    <Fragment>
      <div ref={ref}>
        <PanelBarItem
          expanded={expanded}
          title={title}
          style={{ opacity: isDragging ? 0.4 : 1 }}
        >
          {children}
        </PanelBarItem>
      </div>
    </Fragment>
  );
};

GroupPanelWrapper.defaultProps = {
  expanded: false,
  acceptItems: []
};
