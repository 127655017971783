import { ISecondaryWorkflows } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-meter/components/form-elements/secondary-workflow/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { mockSecondaryWorkflowsData } from "./mock";
const nameOf = nameOfFactory<ISecondaryWorkflows>();
export const colSecondaryWorkflow: IColumnFields[] = [
  {
    field: nameOf("Value"),
    title: "Workflow Type",
  },
];

export const defaultSecondaryWorkflow = {
  _option: {
    Data: {
      SecondaryWorkflows: [...mockSecondaryWorkflowsData],
    },
  },
};
