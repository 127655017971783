import { postLLPIssueFeeBatch } from "@app/products/local-laws/renewals/requires-renewal-fee/components/api";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface ILLPIssueRenewalFeesSubButtonProps {
  isAllRecords?: boolean;
  titleButton?: string;
  isVisible?: boolean;
  isDisabled?: boolean;
}

export const LLPIssueRenewalFeesSubButton = observer(
  ({
    isVisible = true,
    isDisabled = false,
    titleButton = "All records",
    isAllRecords = true,
  }: ILLPIssueRenewalFeesSubButtonProps) => {
    const {
      isEmptyGridSelectedRow,
      gridSelectedIds,
      setGridSelectedRows,
      isEmptyData,
    } = useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleOnClick = async () => {
      setIsSubmitting(true);
      const response = await postLLPIssueFeeBatch(
        isAllRecords ? [] : gridSelectedIds,
        isAllRecords
      );
      setIsSubmitting(false);

      if (isSuccessResponse(response)) {
        pushNotification({
          title:
            "Action batch update has been queued to run. You will be emailed on completion.",
          type: "info",
        });
        setGridSelectedRows([]);
      } else {
        pushNotification({
          autoClose: false,
          type: "error",
          description:
            response.data?.Errors ??
            response.error ??
            "The issue renewal fee failed",
        });
      }
    };

    // set button is invisible
    if (!isVisible) return null;

    return (
      <CCNavButton
        title={titleButton}
        disabled={
          (isEmptyGridSelectedRow && !isAllRecords) || isDisabled || isEmptyData
        }
        onClick={handleOnClick}
        isLoading={isSubmitting}
      />
    );
  }
);
