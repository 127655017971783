import { loadMeterReadingRoutesDetailTabById } from "@app/products/property/meters/reading-routes/[id]/components/reference-sidebar/detail/api";
import { IReferenceSideBarDetails } from "@app/products/property/meters/reading-routes/[id]/components/reference-sidebar/detail/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const MeterReadingRouteDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [meterReadingRouteInfo, setMeterReadingRouteInfo] =
    useState<IReferenceSideBarDetails>();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const readingRouteId = params.id || lastSelectedRow.ReadingRouteId;
  useEffect(() => {
    setIsLoading(true);
    if (!readingRouteId) {
      setIsLoading(false);
      return;
    }
    loadMeterReadingRoutesDetailTabById(readingRouteId).then((data) => {
      if (!data) return;
      setMeterReadingRouteInfo(data);
      setIsLoading(false);
    });
  }, [readingRouteId]);

  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Meter Reading Route Number:"}
          value={meterReadingRouteInfo?.MeterReadingRouteNumber}
        />
        <ProductReferenceRow
          title={"Route Name:"}
          value={meterReadingRouteInfo?.RouteName}
        />
        <ProductReferenceRow
          title={"Assigned Reading Device:"}
          value={meterReadingRouteInfo?.AssignedReadingDevice}
        />
        <ProductReferenceRow
          title={"Assigned Device Host Name:"}
          value={meterReadingRouteInfo?.AssignedDeviceHostName}
        />
        <ProductReferenceRow
          title={"Assigned Reader:"}
          value={meterReadingRouteInfo?.AssignedReader}
        />
        <ProductReferenceRow
          title={"Active Meters:"}
          value={meterReadingRouteInfo?.ActiveMeters}
        />
        <ProductReferenceRow
          title={"Meters with Standard Readings:"}
          value={meterReadingRouteInfo?.MetersWithStandardReadings}
        />
        <ProductReferenceRow
          title={"Meters with Special Readings:"}
          value={meterReadingRouteInfo?.MetersWithSpecialReadings}
        />
        <ProductReferenceRow
          title={"Reading waiting Verification:"}
          value={meterReadingRouteInfo?.ReadingsWaitingVerification}
        />
        <ProductReferenceRow
          title={"Standard Reading Required:"}
          value={meterReadingRouteInfo?.StandardReadingRequired}
        />
        <ProductReferenceRow
          title={"Special Reading Required:"}
          value={meterReadingRouteInfo?.SpecialReadingRequired}
        />
        <ProductReferenceRow
          title={"Reading Route Id:"}
          value={meterReadingRouteInfo?.ReadingRouteId}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
