import { colFeeTypesPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/fee-types-picker/config";
import {
  getFeeTypes,
  getFeeTypesById,
} from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/add-fee-types/api";
import { IFeeTypes } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/add-fee-types/model";
import { isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { nameOfFactory } from "@common/utils/common";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React, { useState } from "react";

const nameOf = nameOfFactory<IFeeTypes>();

export interface IFeeTypesPicker extends ICCInputPickerProps {
  onPickFeeTypes: (value: any) => void;
  isGetDetail?: boolean;
  selectableMode?: "single" | "multiple" | "none";
  productType?: PRODUCT_TYPE_NUMBER;
}
export const FeeTypesPicker = (props: IFeeTypesPicker) => {
  const {
    onPickFeeTypes,
    onChange,
    onError,
    isGetDetail = true,
    selectableMode,
    textField,
    textProduce,
    valueField,
    valueProduce,
    productType,
    ...other
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [feeTypeListData, setFeeTypeListData] = useState<IFeeTypes[]>([]);

  const handleOnLoadData = () => {
    setIsLoading(true);
    getFeeTypes(productType).then((response) => {
      setIsLoading(false);
      if (isSuccessResponse(response)) {
        if (response?.data) {
          setFeeTypeListData(response.data);
        } else {
          const error = response?.error ?? "Fee Type list load failed";
          if (onError) onError(error);
        }
      }
    });
  };

  const handleOnChange = (event: ICCInputPickerChangeEvent) => {
    const value: IFeeTypes = event.value;
    if (onPickFeeTypes) onPickFeeTypes(value);
    if (isGetDetail) {
      getFeeTypesById(value.ID).then((response) => {
        if (isSuccessResponse(response) && response.data) {
          if (onChange) onChange({ value: response.data });
        } else {
          const error = response?.error ?? "Fee Type is not found";
          if (onError) onError(error);
        }
      });
    }
  };

  return (
    <CCInputPicker
      {...other}
      isLoading={isLoading}
      dialog={{
        titleHeader: "Pick Fee Type",
        maxWidth: "50%",
      }}
      grid={{
        columnFields: colFeeTypesPicker,
        primaryField: nameOf("ID"),
        selectableMode: selectableMode,
        data: feeTypeListData,
        isAutoHiddenPager: false,
      }}
      onLoadData={handleOnLoadData}
      onChange={handleOnChange}
      valueField={valueField}
      valueProduce={valueProduce}
      textField={textField}
      textProduce={textProduce}
    />
  );
};
