import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DTO_Component_ChangeOfOwnership } from "./model";

export const getAssessmentTransfer = async (
  assessmentId: number
): Promise<APIResponse<DTO_Component_ChangeOfOwnership | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Component_ChangeOfOwnership>(
      `/api/property/internal/assessment/${assessmentId}/changeofownership`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
