import { SiteUser_CorporateAuth_Link } from "@common/pages/settings/security/corporate-authorisations/_id/model";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useRef } from "react";

interface IProvideReasonDialogProps {
  onClose: () => void;
  onSubmit: (
    value: SiteUser_CorporateAuth_Link,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => void;
  initialData?: SiteUser_CorporateAuth_Link;
}

const nameOf = nameOfFactory<SiteUser_CorporateAuth_Link>();

export const EditSiteUserDialog = ({
  onClose,
  onSubmit,
  initialData,
}: IProvideReasonDialogProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    onSubmit(event.values as SiteUser_CorporateAuth_Link, notificationRef);
  };

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      initialValues={initialData}
      render={(formRenderProps: FormRenderProps) => {
        return (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="25%"
              titleHeader="Edit Site User"
              onClose={onClose}
              bodyElement={
                <div className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <CCLabel title="Name" />
                        <Field
                          name={nameOf("SiteUser_DisplayName")}
                          component={CCInput}
                          placeholder={"Name"}
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Send Notification" />
                        <Field
                          name={nameOf("SendNotification")}
                          component={CCSwitch}
                          checked={formRenderProps.valueGetter(
                            "SendNotification"
                          )}
                        />
                      </div>
                      <div className="cc-field"></div>
                    </div>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={!formRenderProps.valid}
                    onClick={formRenderProps.onSubmit}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
