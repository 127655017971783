import { useIsNew } from "@common/hooks/useIsNew";
import { DynamicQuestionList } from "@common/models/dynamicQuestion";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { DynamicQuestionListsFormElement } from "@common/pages/settings/lookups/dynamic-lists/_id/components/child-screens/general/components/form-element/_index";
import { useDynamicQuestionListsStore } from "@common/pages/settings/lookups/dynamic-lists/_id/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useEffect, useRef } from "react";

export const DynamicQuestionListsForm = observer(() => {
  const isNew = useIsNew();
  const submitRef = useRef<(event: SyntheticEvent<any>) => void | undefined>();
  const { dynamicQuestionLists, setOnSubmit, submitAction } =
    useDynamicQuestionListsStore();
  const { pushNotification } = useCCAppNotificationStore();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = event;
    const actionSubmit = event.event?.currentTarget.name as ActionSubmitActions;
    if (!(actionSubmit in ActionSubmitActions)) return;

    if (!isValid) return;
    if (!isModified && [ActionSubmitActions.Save].includes(actionSubmit))
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });

    switch (actionSubmit) {
      case ActionSubmitActions.Save:
        submitAction(
          values as DynamicQuestionList,
          ActionSubmitActions.Save,
          isNew
        );
        break;
      case ActionSubmitActions.AddCheckListQuestion:
        submitAction(
          values as DynamicQuestionList,
          ActionSubmitActions.AddCheckListQuestion,
          isNew,
          isModified
        );
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={dynamicQuestionLists}
        key={JSON.stringify(dynamicQuestionLists)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <DynamicQuestionListsFormElement
                  formRenderProps={formRenderProps}
                />
              }
            />
          );
        }}
      />
    </div>
  );
});
