import { isEqual } from "lodash";
import { useEffect, useRef } from "react";
import { useEffectOnce } from "react-use";
import { IReferenceComponents, useReferenceSideBarStore } from "../store";

export interface IReferenceSideBar {
  referenceComponents?: IReferenceComponents[];
}

export const useReferenceSideBar = ({
  referenceComponents = [],
}: IReferenceSideBar) => {
  const { setReferenceComponents, resetDefault } = useReferenceSideBarStore();
  const refComponents = useRef<IReferenceComponents[]>([]);
  useEffect(() => {
    const oldProps = refComponents?.current?.map(
      (item) => item?.component?.props ?? null
    );
    const newProps = referenceComponents?.map(
      (item) => item?.component?.props ?? null
    );
    if (!isEqual(oldProps, newProps)) {
      setReferenceComponents(referenceComponents);
      refComponents.current = referenceComponents;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceComponents]);

  useEffectOnce(() => {
    return () => {
      resetDefault();
    };
  });
};
