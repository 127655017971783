import { mockDisassociateMeterDialog } from "@app/products/property/meters/[id]/components/action-bar/dialogs/disassociate-meter/mock";
import { DisassociateMeterDialog } from "@app/products/property/meters/[id]/components/action-bar/dialogs/disassociate-meter/_index";
import { useMeterInstallationStore } from "@app/products/property/meters/[id]/components/child-screens/installation/store";
import { useMeterStore } from "@app/products/property/meters/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
export const DisassociateMeterButton = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const {
    setSelectedAssessmentsAssociated,
    selectedAssessmentsAssociated,
    disassociateMeter,
  } = useMeterInstallationStore();
  const { meter } = useMeterStore();
  const { pushNotification } = useCCAppNotificationStore();

  const initialDataForm = useMemo(() => {
    return { ...meter, ...mockDisassociateMeterDialog };
  }, [meter]);

  return (
    <>
      <CCNavButton
        title="Disassociate meter"
        onClick={() => setShowDialog(true)}
        disabled={
          selectedAssessmentsAssociated &&
          selectedAssessmentsAssociated.length > 0
            ? false
            : true
        }
      />
      {showDialog && (
        <DisassociateMeterDialog
          initialData={initialDataForm}
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={() => {
            disassociateMeter();
            setShowDialog(false);
            pushNotification({
              title: `Meter dissociated successfully`,
              type: "success",
            });
            setSelectedAssessmentsAssociated(undefined);
          }}
        />
      )}
    </>
  );
});
