import { PopulateCandidatesLandRentDialog } from "@app/products/property/assessments/compliance/candidates-land-rent/components/action-bar/dialogs/populate-candidates-land-rent/_index";
import { useComplianceCandidateLandStore } from "@app/products/property/assessments/compliance/candidates-land-rent/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const PopulateCandidatesLandRentButton = observer(() => {
  const { isOpenCandidateLandDialog, setIsOpenCandidateLandDialog } =
    useComplianceCandidateLandStore();

  return (
    <>
      <CCNavButton
        title="Populate Candidates"
        onClick={() => setIsOpenCandidateLandDialog(true)}
      />
      {isOpenCandidateLandDialog && (
        <PopulateCandidatesLandRentDialog
          onClose={() => setIsOpenCandidateLandDialog(false)}
        />
      )}
    </>
  );
});
