import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { SwitchSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/switch/_index";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};
const enumList: string[] = [
  "TP_Application_OAs",
  "TP_Application_OAsLabel",
  "TP_Application_ShowOAs",
  "TP_Application_OA_EnableNoOfLots",
];
export const OAsApplicationForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();

    const data = useMemo(() => {
      const data: any = {};
      enumList.forEach((item: any) => {
        const settingEnum = ECorporateSettingsField[item];
        data[item] =
          configData && settingEnum ? configData[settingEnum] : undefined;
      });
      return data;
    }, [configData]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          {data.TP_Application_OAs && (
            <div className="cc-field">
              <label className="cc-label">
                {data.TP_Application_OAs?.Title ?? ""}
                {data.TP_Application_OAs?.IsMandatory && (
                  <CCTooltip type="validator" position="right" />
                )}
              </label>
              <Field
                name={data.TP_Application_OAs?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={formRenderProps?.valueGetter(
                  data.TP_Application_OAs?.FieldName
                )}
              />
            </div>
          )}
          {formRenderProps?.valueGetter(data.TP_Application_OAs?.FieldName) && (
            <>
              {data.TP_Application_OAsLabel && (
                <div className="cc-field">
                  <label className="cc-label">
                    {data.TP_Application_OAsLabel?.Title ?? ""}
                    {data.TP_Application_OAsLabel?.IsMandatory && (
                      <CCTooltip type="validator" position="right" />
                    )}
                  </label>
                  <Field
                    name={data.TP_Application_OAsLabel?.FieldName}
                    component={CCInput}
                    disabled={!isEditing}
                    validator={requiredValidator}
                  />
                </div>
              )}
              {data.TP_Application_ShowOAs && (
                <div className="cc-field">
                  <label className="cc-label">
                    {data.TP_Application_ShowOAs?.Title ?? ""}
                    {data.TP_Application_ShowOAs?.IsMandatory && (
                      <CCTooltip type="validator" position="right" />
                    )}
                  </label>
                  <Field
                    name={data.TP_Application_ShowOAs?.FieldName}
                    component={CCSwitch}
                    disabled={!isEditing}
                    checked={formRenderProps?.valueGetter(
                      data.TP_Application_ShowOAs?.FieldName
                    )}
                  />
                </div>
              )}
              <SwitchSettings
                data={data.TP_Application_OA_EnableNoOfLots}
                isEditing={isEditing}
                formRenderProps={formRenderProps}
              />
            </>
          )}
        </div>
      </section>
    );
  }
);
