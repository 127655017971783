import { applicationsRoute } from "@app/products/building/applications/route";
import { bamsRoute } from "@app/products/building/bams/route";
import { complaintsRoute } from "@app/products/building/complaints/route";
import { eBuildingRoute } from "@app/products/building/ebuilding/route";
import { financeRoute } from "@app/products/building/finance/route";
import { inspScheduleRoute } from "@app/products/building/insp-schedule/route";
import { inspectionsRoute } from "@app/products/building/inspections/route";
import { nonComplianceRoute } from "@app/products/building/non-compliance/route";
import { noticesRoute } from "@app/products/building/notices/route";
import { poolsOrSpasRenewalsRoute } from "@app/products/building/pools-or-spas-renewals/route";
import { poolsOrSpasRoute } from "@app/products/building/pools-or-spas/route";
import { registersRoute } from "@app/products/building/registers/route";
import { systemAdminRoute } from "@app/products/building/system-admin/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";

export const buildingRoute: ICCRoute = {
  path: "building",
  name: "Building",
  enum: eProductType.Building,
  children: [
    applicationsRoute,
    inspectionsRoute,
    inspScheduleRoute,
    complaintsRoute,
    noticesRoute,
    poolsOrSpasRoute,
    poolsOrSpasRenewalsRoute,
    registersRoute,
    financeRoute,
    bamsRoute,
    nonComplianceRoute,
    eBuildingRoute,
    systemAdminRoute,
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
