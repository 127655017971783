import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { IWorkflowIdCellProps } from "@app/products/property/components/cells/workflow-id-cell/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { NumberCell } from "@components/cc-grid/components/grid-cells/number/_index";
import { isNil } from "lodash";
import React from "react";

export const WorkflowIdCell = ({
  props,
  workflowType,
}: IWorkflowIdCellProps) => {
  const { setListDialog } = usePropertyWorkflowStore();
  const {
    Workflow_Id: Workflow_Draft_Id,
    Workflow_Status,
    Workflow_Status_Name,
    Workflow_Approval_Status,
  } = props?.dataItem ?? {};

  const handleOpenWorkflow = () => {
    setListDialog([
      {
        type: workflowType,
        data: {
          Workflow_Draft_Id,
          Workflow_Status,
          Workflow_Status_Name,
          Workflow_Approval_Status,
        },
      },
    ]);
  };

  return (
    <NumberCell
      value={Workflow_Draft_Id}
      format={NUMBER_FORMAT.NUMBER2}
      handleOnClick={!isNil(Workflow_Draft_Id) ? handleOpenWorkflow : undefined}
    />
  );
};
