import { KeyValuePair } from "@app/core/documents/model";
import { KEYWORD_TYPE } from "@common/constants/keywordType";

export const optionPlusKeywordsTitle = (keywordType: KEYWORD_TYPE) => {
  switch (keywordType) {
    case KEYWORD_TYPE.HealthManager_Foods:
      return "Select Foods";
    case KEYWORD_TYPE.HealthManager_ScopeOfService:
      return "Select Scope Of Service";
    case KEYWORD_TYPE.HealthManager_SpecificInfrastructure:
      return "Select Specific Infrastructure";
  }
  return "Select Keywords";
};

export const cbxTobCategories: KeyValuePair<string, string>[] = [
  { Key: "Retail", Value: "Retail" },
  { Key: "Workplace", Value: "Workplace" },
  { Key: "Café/Restaurant", Value: "Café/Restaurant" },
  { Key: "Hotel", Value: "Hotel" },
  { Key: "Public Areas", Value: "Public Areas" },
];
