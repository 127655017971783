import { ISettingLookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { SelectionGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/selection/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useShowHideLogicPropertiesSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/properties/hooks/useShowHideLogicPropertiesSettings";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { nameOfFactory } from "@common/utils/common";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IGlobalSettingsProps {
  formRenderProps: FormRenderProps;
}
const nameOfISettingLookupItem = nameOfFactory<ISettingLookupItem>();

export const GlobalSettingPropertyDialog = observer(
  ({ formRenderProps }: IGlobalSettingsProps) => {
    const { isEditing } = useSettingGlobalManageStore();
    const { configData } = useSettingGlobalStore();
    const data = useDataSetting(configData, [
      "CorporateSettings_PropertyAutoPopupDialog",
      "CorporateSettings_PropertyDialog_OnPropertySelectionReturnToPropertyDialog",
      "CorporateSettings_PropertyDialog_NearestCrossStreet",
      "CorporateSettings_PropertyDialog_BuildingName",
      "CorporateSettings_PropertyDialog_ForceAddressTab",
      "CorporateSettings_PropertyDialog_OwnerStreetNumber",
      "CorporateSettings_EnableZoneOverlayInternalLookup",
      "CorporateSettings_EnableAutoCompleteSearchForStreetName",
      "CorporateSettings_PropertyDialog_OwnerBusinessNumbers",
    ]);
    const {
      showPropertiesAutoPopup,
      showPropertySelectionReturnToPropertyDialog,
    } = useShowHideLogicPropertiesSettings(formRenderProps);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {showPropertiesAutoPopup && (
            <SwitchGlobalSettings
              data={data.CorporateSettings_PropertyAutoPopupDialog}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
          {showPropertySelectionReturnToPropertyDialog && (
            <SwitchGlobalSettings
              data={
                data.CorporateSettings_PropertyDialog_OnPropertySelectionReturnToPropertyDialog
              }
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
          <SwitchGlobalSettings
            data={data.CorporateSettings_PropertyDialog_NearestCrossStreet}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchGlobalSettings
            data={data.CorporateSettings_PropertyDialog_BuildingName}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchGlobalSettings
            data={data.CorporateSettings_PropertyDialog_ForceAddressTab}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          {data.CorporateSettings_PropertyDialog_OwnerBusinessNumbers && (
            <SelectionGlobalSettings
              field={data.CorporateSettings_PropertyDialog_OwnerBusinessNumbers}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
              isMultiple={true}
              separator={","}
              itemKey={nameOfISettingLookupItem("Name")}
            />
          )}
          <SwitchGlobalSettings
            data={data.CorporateSettings_PropertyDialog_OwnerStreetNumber}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchGlobalSettings
            data={data.CorporateSettings_EnableZoneOverlayInternalLookup}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchGlobalSettings
            data={data.CorporateSettings_EnableAutoCompleteSearchForStreetName}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
      </section>
    );
  }
);
