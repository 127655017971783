import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const metersRoute: ICCRoute = {
  path: "meters",
  name: "Meters",
  enum: eMenuAction.Meter_Generate_Menu_Items_for_each_Utility,
  children: [
    {
      path: "list",
      name: "List",
      enum: eMenuAction.Meter_List,
      component: require("./list/_index").default,
    },
    {
      path: "reading-devices",
      name: "Reading Devices",
      enum: eMenuAction.Meter_Reading_Devices,
      component: require("./reading-devices/_index").default,
    },
    {
      path: "reading-routes",
      name: "Reading Routes",
      enum: eMenuAction.Meter_Reading_Routes,
      component: require("./reading-routes/_index").default,
    },
    {
      path: "processes",
      name: "Processes",
      enum: eMenuAction.Meter_Generate_Menu_Items_for_Processes, //Error
      component: require("./processes/_index").default,
    },
    {
      path: "special-reading-list",
      name: "Special Reading List",
      enum: eMenuAction.Meter_Special_Reads,
      component: require("./special-reading-list/_index").default,
    },
    {
      path: "last-reading-details",
      name: "Last Reading Details",
      enum: eMenuAction.Meter_Last_Reading_Details,
      component: require("./last-reading-details/_index").default,
    },
    {
      path: "readings-to-be-processed",
      name: "Readings to be Processed",
      enum: eMenuAction.Meter_Readings_To_Be_Processed,
      component: require("./readings-to-be-processed/_index").default,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
