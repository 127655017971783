import { globalStoreInstance } from "@common/stores/global/store";
import { createActivityDetector } from "@common/utils/activity-detector";
import { useEffect, useState } from "react";
export const useIdle = (isStart: boolean) => {
  const [isIdle, setIsIdle] = useState(false);
  useEffect(() => {
    if (isStart) {
      const activityDetector = createActivityDetector(
        optionActivityDetector(globalStoreInstance) as any
      ) as any;
      activityDetector.on("idle", () => setIsIdle(true));
      activityDetector.on("active", () => setIsIdle(false));
      return () => activityDetector.stop();
    } else {
      setIsIdle(false);
    }
  }, [isStart]);
  return isIdle;
};

export enum CONFIG_DEFAULT_IDLE {
  idleTime = 600000,
  countDownTime = 60000,
}

const optionActivityDetector = (globalStoreInstance: any) => {
  return {
    timeToIdle: globalStoreInstance.allSetting?.activityDetector.idleTime,
    inactivityEvents: [],
    activityEvents: [
      "click",
      "mousemove",
      "keydown",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "focus",
    ],
    ignoredEventsWhenIdle: [],
  };
};
