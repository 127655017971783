import { listSearchOptions } from "@app/products/property/components/fields/search-name/config";
import { CONTACTSEARCHFILTER } from "@app/products/property/components/fields/search-name/model";
import { nameOfLov } from "@app/products/property/model";
import { getDropdownValue } from "@common/utils/common";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import React, { useEffect } from "react";

export interface ISearchOptions {
  formRenderProps: FormRenderProps;
  nameOf: (name: string) => string;
  isDisable: boolean;
}
//[15566] - Name search filter - UX:
// - Search options:  Change to dropdown and default is STARTSWITH
export const SearchOptions = ({
  formRenderProps,
  nameOf,
  isDisable,
}: ISearchOptions) => {
  const { onChange, valueGetter } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const searchOptionValue = getFieldValue("SearchOptions");
  useEffect(() => {
    // default is STARTSWITH if not set before
    if (isNil(searchOptionValue)) {
      onChange(nameOf("SearchOptions"), {
        value: CONTACTSEARCHFILTER.STARTSWITH,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOptionValue]);

  return (
    <div className="cc-field">
      <label className="cc-label">Search options</label>
      <Field
        name={nameOf("SearchOptions")}
        textField={nameOfLov("Name")}
        dataItemKey={nameOfLov("Code")}
        data={listSearchOptions ?? []}
        component={CCDropDownList}
        disabled={isDisable}
        value={getDropdownValue(
          "" + searchOptionValue,
          listSearchOptions,
          nameOfLov("Code")
        )}
        onChange={(event: ComboBoxChangeEvent) => {
          onChange(nameOf("SearchOptions"), {
            value: event.value?.Code ?? null,
          });
        }}
      />
    </div>
  );
};
