import {
  ACCESSCONTROLENTITYTYPE,
  ACCESSCONTROLRECORDTYPE,
  ACCESSCONTROLSOURCE,
  ACCESSRIGHTS,
} from "@common/constants/enumerations";
import { recordSourceDefault } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/security/forms/security-settings/config";
import { IAccessControl } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/security/forms/security-settings/model";

export const getNameWithEnum = (listEnum: any, value: number): string => {
  if (!listEnum) return "";
  const indexOfEnum = Object.values(listEnum).indexOf(value);
  let name = "";
  if (indexOfEnum !== -1) {
    name = Object.keys(listEnum)[indexOfEnum].toString();
  }
  return name;
};

export const processAccessControl = (
  acRecordTypeEnum: ACCESSCONTROLRECORDTYPE,
  accessSourceEnum: ACCESSCONTROLSOURCE,
  accessRightsEnum: ACCESSRIGHTS,
  entityTypeEnum: ACCESSCONTROLENTITYTYPE,
  entityId: number,
  entityName: string
): IAccessControl => {
  let infoUser: any = {};
  if (entityTypeEnum === ACCESSCONTROLENTITYTYPE.OrgUnit) {
    infoUser.OrgUnit_ID = entityId;
    infoUser.OrgUnit_Hierarchy = entityName;
  } else if (entityTypeEnum === ACCESSCONTROLENTITYTYPE.SiteUser) {
    infoUser.Contact_ID = entityId;
    infoUser.Contact_DisplayName = entityName;
  }

  return {
    ACRecordType_Name: getNameWithEnum(
      ACCESSCONTROLRECORDTYPE,
      acRecordTypeEnum
    ),
    RecordSource: recordSourceDefault,
    ACSource_Name: getNameWithEnum(ACCESSCONTROLSOURCE, accessSourceEnum),
    AccessRights_Name: getNameWithEnum(ACCESSRIGHTS, accessRightsEnum),
    EntityTypes_Name: getNameWithEnum(ACCESSCONTROLENTITYTYPE, entityTypeEnum),
    EntityDisplayName: entityName,
    EntityID: entityId,
    ACRecordType_ENUM: acRecordTypeEnum,
    ACSource_ENUM: accessSourceEnum,
    AccessRights_ENUM: accessRightsEnum,
    EntityType_ENUM: entityTypeEnum,
    ...infoUser,
  };
};
