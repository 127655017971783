import { getDisplayTextWithDashes } from "@common/utils/common";
import { mockEventManagementCalender } from "@app/products/event-management/mock";

const currentYear = new Date().getFullYear();
export const displayDate = new Date(Date.UTC(currentYear, 11, 25));

const parseAdjust = (eventDate: any) => {
  const date = new Date(eventDate);
  date.setFullYear(currentYear);
  return date;
};

export const sampleCalendarData = mockEventManagementCalender.map(
  (dataItem) => ({
    id: dataItem.TaskID,
    start: parseAdjust(dataItem.Start),
    startTimezone: dataItem.StartTimezone,
    end: parseAdjust(dataItem.End),
    endTimezone: dataItem.EndTimeZone,
    isAllDay: dataItem.isAllDay,
    title: dataItem.Title,
    description: dataItem.Description,
    recurrenceRule: dataItem.RecurrenceRule,
    recurrenceId: dataItem.RecurrenceID,
    recurrenceExceptions: dataItem.RecurrenceException,
    type: dataItem.type,
    roomId: dataItem.RoomID,
    ownerID: dataItem.OwnerID,
    personId: dataItem.OwnerID,
  })
);

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `Event Management ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.DebtorNumber,
    selectedRow.MasterFacility_Name,
  ]);
};
