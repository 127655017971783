import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

export interface ICCFooterCellProps {
  footerCell: GridFooterCellProps;
  columnField: IColumnFields;
  gridData: any;
}

export const CCFooterCell = ({
  footerCell,
  columnField,
  gridData,
}: ICCFooterCellProps) => {
  let value =
    columnField.footerCell?.props && columnField.footerCell.props?.value;
  if (typeof columnField.calculateFooterCellValue === "function") {
    value = columnField.calculateFooterCellValue(footerCell, gridData);
  }
  return React.cloneElement(columnField.footerCell ?? <></>, {
    ...footerCell,
    ...columnField.footerCell?.props,
    value,
  });
};
