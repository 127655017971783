import { IUsers } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/users-grid/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const nameOfUsers = nameOfFactory<IUsers>();

export const colUsers: IColumnFields[] = [
  {
    field: nameOfUsers("Name"),
    title: "Name",
  },
  {
    field: nameOfUsers("Position"),
    title: "Position",
  },
  {
    field: nameOfUsers("Role"),
    title: "Role",
  },
  {
    field: nameOfUsers("Email"),
    title: "Email",
  },
  {
    field: nameOfUsers("WorkPhone"),
    title: "Work Phone",
  },
  {
    field: nameOfUsers("HomePhone"),
    title: "Home Phone",
  },
  {
    field: nameOfUsers("Mobile"),
    title: "Mobile",
  },
];
