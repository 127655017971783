import { ICCRoute } from "@common/constants/ICCRoute";
export const inspectionTypeRoute: ICCRoute = {
  path: "inspection-types",
  name: "Inspection Types",
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./_id/_index").default,
    },
  ],
};
