import { eventEmitter } from "@/App";

import { postIssueRenewalNoticeMarkAsSent } from "@app/products/animals/renewals/requires-renewal-notice/components/api";
import { isSuccessResponse } from "@common/apis/util";

import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IAnimalMarkAsSentButtonProps {
  titleButton?: string;
  isVisible?: boolean;
  isDisabled?: boolean;
}

export const AnimalMarkAsSentButton = observer(
  ({
    titleButton = "Mark As Sent",
    isVisible = true,
    isDisabled = false,
  }: IAnimalMarkAsSentButtonProps) => {
    const { isEmptyGridSelectedRow, gridSelectedIds, setGridSelectedRows } =
      useCCProductListViewStore();
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleOnClick = async () => {
      setIsSubmitting(true);
      const response = await postIssueRenewalNoticeMarkAsSent(gridSelectedIds);
      setIsSubmitting(false);

      if (isSuccessResponse(response)) {
        clearErrorNotification();
        pushNotification({
          type: "success",
          description: "Issue renewal notice and mark as sent successfully",
        });
        // refresh list view
        eventEmitter.emit(CCGridEventType.RefreshOData);
        setGridSelectedRows([]);
      } else {
        pushNotification({
          autoClose: false,
          type: "error",
          description:
            response.data?.Errors ??
            response.error ??
            "Issue renewal notice and mark as sent failed",
        });
      }
    };

    // set button is invisible
    if (!isVisible) return null;
    // loading when call API
    if (isSubmitting) return <Loading isLoading={isSubmitting} isFullScreen />;

    return (
      <CCNavButton
        title={titleButton}
        disabled={isEmptyGridSelectedRow || isDisabled}
        onClick={handleOnClick}
      />
    );
  }
);
