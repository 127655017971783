export interface Svc_ConditionReference {
  ID: number;
  Title: string;
  Group: string;
  Condition: string;
  Clause: string;
  SortIndex: number;
  ReferenceType_ENUM: number | null;
  AdditionalInfo: string;
  RequirementType_ENUM: number | null;
  RequirementTypeName: string;
  ValidationExpression: string;
  DocumentName: string;
  IsMutualRecognition: boolean | null;
  StatusName: string;
}

export enum StatusColumnGroupSystemAdminConditions {
  False = 0,
  True = 1,
}
