import {
  getPropertyAssessmentAllCharges,
  getPropertyAssessmentChargeViews,
} from "@app/products/property/assessments/[id]/components/child-screens/charges/api";
import { colCharges } from "@app/products/property/assessments/[id]/components/child-screens/charges/config";
import {
  ChargeViews,
  DTO_Charge,
  mChargeView,
} from "@app/products/property/assessments/[id]/components/child-screens/charges/model";
import { assessmentTransactionsStoreInstance } from "@app/products/property/assessments/[id]/components/child-screens/transactions/store";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { IColumnFields } from "@components/cc-grid/model";
import { State } from "@progress/kendo-data-query";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AssessmentChargesStore {
  private _isLoading: boolean = false;
  private _isGridLoading: boolean = false;
  private _chargesViewData: mChargeView[] = [];
  private _chargesView?: mChargeView = undefined;
  private _showCancelled: boolean = false;
  private _showPaidCharges: boolean = false;
  private _chargesData: DTO_Charge[] = [];
  private _newColumnCharges: IColumnFields[] = colCharges;
  private _gridDataState?: State = undefined;
  private _chargesSelected: DTO_Charge[] = [];
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isGridLoading() {
    return this._isGridLoading;
  }
  setIsGridLoading = (isGridLoading: boolean) => {
    runInAction(() => {
      this._isGridLoading = isGridLoading;
    });
  };

  get chargesData() {
    return this._chargesData;
  }
  setChargesData = (data: DTO_Charge[]) => {
    runInAction(() => {
      this._chargesData = data;
    });
  };

  get newColumnCharges() {
    return this._newColumnCharges;
  }
  setNewColumnCharges = (columnFields: IColumnFields[]) => {
    runInAction(() => {
      this._newColumnCharges = columnFields;
    });
  };

  get chargesView() {
    return this._chargesView;
  }
  setChargesView = (view?: mChargeView) => {
    runInAction(() => {
      this._chargesView = view;
    });
  };

  get chargesViewsData() {
    return this._chargesViewData;
  }
  setChargesViewsData = (views: mChargeView[]) => {
    runInAction(() => {
      this._chargesViewData = views;
    });
  };

  get showCancelled() {
    return this._showCancelled;
  }
  setShowCancelled = (showCancelled: boolean) => {
    runInAction(() => {
      this._showCancelled = showCancelled;
    });
  };

  get showPaidCharges() {
    return this._showPaidCharges;
  }
  setShowPaidCharges = (showPaidCharges: boolean) => {
    runInAction(() => {
      this._showPaidCharges = showPaidCharges;
    });
  };

  get gridDataState() {
    return this._gridDataState;
  }
  setGridDataState = (gridDataState?: State) => {
    runInAction(() => {
      this._gridDataState = gridDataState;
    });
  };

  get chargesSelected() {
    return this._chargesSelected;
  }
  setChargesSelected = (gridSelectedRows: DTO_Charge[]) => {
    runInAction(() => {
      this._chargesSelected = gridSelectedRows;
      assessmentTransactionsStoreInstance.setGridSelectedRows([]);
    });
  };

  get responseLoadError() {
    return this._responseLoadError;
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  loadViews = async () => {
    let errorResponse;
    this.setIsLoading(true);
    let viewData: mChargeView[] = [];
    let view: mChargeView | undefined;
    const newViews = await getPropertyAssessmentChargeViews();
    if (isSuccessResponse(newViews)) {
      viewData = newViews.data?.Views ?? [];
      view = newViews.data?.Views[0];
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setChargesViewsData(viewData);
    this.setChargesView(view);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  loadCharges = async (
    assessmentId: number,
    view: ChargeViews = ChargeViews.All,
    ratingPeriodId?: number,
    showCancelledCharges: boolean = false,
    showPaidCharges: boolean = false,
    loadDetails: boolean = false,
    isClearSelected: boolean = true
  ) => {
    if (isClearSelected) this.setChargesSelected([]);
    this.setIsGridLoading(true);
    const newCharges = await getPropertyAssessmentAllCharges(
      assessmentId,
      view,
      ratingPeriodId,
      showCancelledCharges,
      showPaidCharges,
      loadDetails
    );
    const data = newCharges?.data;
    this.setChargesData(
      data
        ? data?.Charges?.Charges?.map((item: DTO_Charge) => ({
            ...item,
            assessmentId,
          }))
        : []
    );
    if (data && data?.Charges) {
      const chargesTotals: any = data?.Charges?.Totals;
      this.setNewColumnCharges(
        this.newColumnCharges.map((item) => ({
          ...item,
          calculateFooterCellValue: () => chargesTotals[item.field],
        }))
      );
      const groupField = data?.Charges?.GroupByFieldNames;
      if (groupField) {
        this.setGridDataState({
          group: groupField?.map((field) => ({ field: field, dir: "asc" })),
        });
      } else {
        this.setGridDataState(undefined);
      }
    }
    this.setIsGridLoading(false);
  };

  reloadChargesExistedFilter = (assessmentId?: number) => {
    if (assessmentId && this.chargesView) {
      this.loadCharges(
        assessmentId,
        this.chargesView?.Id,
        this.chargesView?.RatingPeriod?.Id,
        this.showCancelled,
        this.showPaidCharges,
        false,
        false
      );
    }
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._isGridLoading = false;
      this._chargesViewData = [];
      this._chargesView = undefined;
      this._showCancelled = false;
      this._showPaidCharges = false;
      this._chargesData = [];
      this._newColumnCharges = colCharges;
      this._gridDataState = undefined;
      this._chargesSelected = [];
      this._responseLoadError = undefined;
    });
  };
}
export const assessmentChargesStoreInstance = new AssessmentChargesStore();
const assessmentChargesStoreContext = createContext(
  assessmentChargesStoreInstance
);
export const useAssessmentChargesStore = () => {
  return useContext(assessmentChargesStoreContext);
};
