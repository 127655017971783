import {
  FURTHERINFOSTATUS,
  FurtherInfoSubmitActions,
} from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { useCancelButtonStore } from "@app/core/further-info/components/buttons/work-flow/cancel/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const CancelValidatorList = [
  FURTHERINFOSTATUS.InfoRequestedDraft,
  FURTHERINFOSTATUS.InfoRequested,
  FURTHERINFOSTATUS.ExtensionDeclined,
  FURTHERINFOSTATUS.ExtensionRequested,
  FURTHERINFOSTATUS.ExtensionGranted,
  FURTHERINFOSTATUS.InfoReceivedAwaitingAssessment,
  FURTHERINFOSTATUS.InfoWaitingForApproval,
  FURTHERINFOSTATUS.InfoApproved,
  FURTHERINFOSTATUS.InfoRejected,
];
export const CancelButton = observer(() => {
  const { isLoading } = useCancelButtonStore();
  const { furtherInfo, onSubmit } = useFurtherInfoStore();

  const isVisible = useMemo(() => {
    return CancelValidatorList.some(
      (item) => item === furtherInfo?.Status_ENUM
    );
  }, [furtherInfo]);

  return isVisible ? (
    <CCNavButton
      title={"Cancel"}
      isLoading={isLoading}
      disabled={isLoading}
      onClick={onSubmit}
      name={FurtherInfoSubmitActions.Cancel}
    />
  ) : null;
});
