import { NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colReInspectionProperty: IColumnFields[] = [
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "LegalDescription",
    title: "Legal Description",
  },
  {
    field: "Owner",
    title: "Owner",
  },
  {
    field: "LandArea",
    title: "Land Area",
  },
  {
    field: "AssessNumber",
    title: "Assess Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "ParcelId",
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colReInspectionCommentsInPreviousCases: IColumnFields[] = [
  {
    field: "ParcelId",
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "CaseId",
    title: "Case ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const ACCEPT_FORMAT = [
  ".xlsx",
  ".csv",
  ".xlsm",
  ".xltx",
  ".xls",
  ".jpg",
  ".jpeg",
  ".jfif",
  ".pjpeg",
  ".pjp",
  ".png",
  ".svg",
  ".doc",
  ".docx",
  ".odt",
  ".txt",
];

export const MAX_FILE_SIZE = 5000000;

export const comboBoxSearchData = {
  _option: {
    CaseId: {
      Data: [],
      Loading: false,
    },
  },
};
