import { eventEmitter } from "@/App";
import { isSuccessResponse } from "@common/apis/util";
import {
  postJobsScheduling,
  postUpdateJobsScheduling,
} from "@common/pages/settings/system-admin/task-scheduling/api";
import { JobsSchedulingDialog } from "@common/pages/settings/system-admin/task-scheduling/components/dialogs/new-or-update/_index";
import {
  ScheduleJobType,
  ScheduleJob_New,
} from "@common/pages/settings/system-admin/task-scheduling/model";
import {
  convertCronFormat,
  cronFormatString,
} from "@common/pages/settings/system-admin/task-scheduling/util";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { clone } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";

interface IJobsSchedulingButtonProps {
  isNew?: boolean;
  disabled?: boolean;
}

export const JobsSchedulingButton = observer(
  ({ isNew = true, disabled = false }: IJobsSchedulingButtonProps) => {
    const { gridSelectedIds, gridSelectedRows, clearSelectedItems } =
      useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const [isShowDialog, setIsShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const isDisabled = useMemo(() => {
      return disabled || (isNew ? false : gridSelectedIds.length !== 1);
    }, [gridSelectedIds.length, isNew, disabled]);

    const handleSubmit = async (data: ScheduleJob_New) => {
      let newData = clone(data);
      if (
        newData?.JobType === ScheduleJobType.Weekly &&
        !newData?.DayOfWeek?.length
      ) {
        notificationRef.current?.pushNotification({
          title: "Please select at least one day of the week.",
          type: "warning",
        });
        return;
      }

      let result = convertCronFormat(newData);
      const cronFormat = cronFormatString(result);

      setIsLoading(true);
      if (!newData?.IsExpire) {
        newData.JobScheduleExpireDateTime = null;
      }
      if (!newData?.IsRepeatJob) {
        newData.RepeatJobTime = null;
        newData.Duration = null;
      }
      if (newData?.JobType !== ScheduleJobType.Weekly) {
        newData.DayOfWeek = [];
      }

      let response = undefined;
      const dataSubmit = {
        ...newData,
        CronValue: cronFormat,
      };

      if (isNew) {
        response = await postJobsScheduling(dataSubmit);
      } else {
        if (gridSelectedRows.length !== 1) {
          return;
        }
        response = await postUpdateJobsScheduling({
          Jobkey: gridSelectedRows[0].JobName,
          Group: gridSelectedRows[0].GroupName,
          Job: dataSubmit,
        });
      }

      setIsLoading(false);
      if (isSuccessResponse(response) && response?.data) {
        setIsShowDialog(false);
        clearSelectedItems();
        eventEmitter.emit(CCGridEventType.RefreshOData);
        pushNotification({
          autoClose: true,
          title: "The job successfully saved.",
          type: "success",
        });
      } else {
        notificationRef.current?.pushNotification({
          title: response.error ?? "The job could not be saved.",
          description: response.error ?? response.data?.Errors,
          type: "error",
          autoClose: false,
        });
      }
    };

    return (
      <>
        <CCNavButton
          title={isNew ? "New" : "Edit"}
          onClick={() => {
            setIsShowDialog(true);
          }}
          disabled={isDisabled}
        />
        {isShowDialog && (
          <JobsSchedulingDialog
            onClosePopup={() => setIsShowDialog(false)}
            onSubmit={handleSubmit}
            isNew={isNew}
            notificationRef={notificationRef}
            isLoading={isLoading}
          />
        )}
      </>
    );
  }
);
