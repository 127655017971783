import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCommunicationQueueStore } from "@common/pages/settings/communication/queue/_id/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const CommunicationQueueHistoryTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { communicationQueue, communicationQueueId } =
    useCommunicationQueueStore();
  const communicationQueueID = communicationQueueId ?? lastSelectedRow?.ID;
  return (
    <Journal
      id={communicationQueueID}
      triggersReload={communicationQueue}
      recordType={RECORDTYPE.CORE_CommunicationQueue}
    />
  );
});
