import { SaveConditionButton } from "@app/core/condition-reference/[id]/components/button/save";
import { ConditionReferenceForm } from "@app/core/condition-reference/[id]/components/general/_index";
import { TitleConditionReferenceManagePage } from "@app/core/condition-reference/[id]/constant";
import { Svc_Form_ConditionReference } from "@app/core/condition-reference/[id]/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewManageConditionReference = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const formCondition = dataForms?.GeneralForm as Svc_Form_ConditionReference;

  return (
    <>
      <FormTitle title={`New ${TitleConditionReferenceManagePage}`} />
      <CCManagePageActionBar leftActions={[<SaveConditionButton />]} />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {formCondition && <ConditionReferenceForm />}
          </div>
        </div>
      </div>
    </>
  );
});
