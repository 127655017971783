import { PropertyChargeAndNoticeRunsFormElement } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/general/components/form-element/_index";
import { useChargeRunsStore } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { Label } from "@common/stores/products/config";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const PropertyChargeAndNoticeRunsForm = observer(() => {
  const { chargeRun } = useChargeRunsStore();

  //Get label
  const assessmentGroupsLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.AssessmentGroups
  );

  return (
    <div className="cc-form">
      <Form
        // onSubmit={handleSubmit}
        initialValues={chargeRun}
        render={(formRenderProps: FormRenderProps) => (
          <PropertyChargeAndNoticeRunsFormElement
            formRenderProps={formRenderProps}
            options={{
              assessmentGroupLabel: assessmentGroupsLabel,
            }}
          />
        )}
      />
    </div>
  );
});
