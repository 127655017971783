import { PROPERTY_METERS_LIST_ROUTE } from "@app/products/property/meters/list/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colMeterReadingRoutes: IColumnFields[] = [
  {
    field: "RouteName",
    title: "Route Name",
    linkTo: (dataItem) => {
      return {
        pathname: `${PROPERTY_METERS_LIST_ROUTE}`,
        state: {
          filter: {
            field: "RouteName",
            value: dataItem["RouteName"],
          },
        },
      };
    },
  },
  {
    field: "MeterReadingRouteNumber",
    title: "Meter Reading Route Number",
    format: NUMBER_FORMAT.NUMBER2,
  },

  { field: "AssignedReadingDevice", title: "Assigned Reading Device" },
  { field: "AssignedDeviceHostName", title: "Assigned Device Host Name" },
  { field: "AssignedReader", title: "Assigned Reader" },
  {
    field: "ActiveMeters",
    title: "Active Meters",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "MetersWithStandardReadings",
    title: "Meters With Standard Readings",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "MetersWithSpecialReadings",
    title: "Meters With Special Readings",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "ReadingsWaitingVerification",
    title: "Reading Waiting Verification",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "StandardReadingRequired",
    title: "Standard Reading Required",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "SpecialReadingRequired",
    title: "Special Reading Required",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "ReadingRouteId",
    title: "Reading Route ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
