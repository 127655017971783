import { ExistedPropertyChargeAndNoticeRuns } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/forms/existed/_index";
import { NewPropertyChargeAndNoticeRuns } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/forms/new/_index";
import { useChargeRunsStore } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

function ManageChargeAndNoticeRuns() {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const chargeRunId = params.id;
  const { loadChargeRuns, resetStore } = useChargeRunsStore();
  const location = useLocation();
  const state: any = location?.state;

  useEffect(() => {
    if (isNew) return;
    let notification = undefined;
    if (state) {
      notification = state?.notification;
      window.history.replaceState({}, "");
    }
    loadChargeRuns(parseInt(chargeRunId), notification);
    return () => {
      resetStore();
    };
  }, [state, isNew, chargeRunId, loadChargeRuns, resetStore]);

  if (isNew) {
    return <NewPropertyChargeAndNoticeRuns />;
  }

  return <ExistedPropertyChargeAndNoticeRuns />;
}

export default observer(ManageChargeAndNoticeRuns);
