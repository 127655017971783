import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { CCPanel } from "@components/cc-panel/_index";
import React, { useState } from "react";

interface IAccordionRelatedProps {
  idContact?: number;
}
export const AccordionRelated = ({ idContact }: IAccordionRelatedProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className="cc-custom-sub-panel-bar">
      <CCPanel
        title="Related"
        component={
          <PropertyAssociations
            narID={idContact}
            id={idContact}
            componentNumber={eComponent.CORE_Contact}
          />
        }
        onToggle={() => {
          setIsExpanded(!isExpanded);
        }}
        isKeepMounted
        isExpanded={isExpanded}
      />
    </div>
  );
};
