import { CreateDefermentCommonButton } from "@app/products/property/assessments/deferments/components/action-bar/buttons/create-deferment/common/_index";
import React from "react";
import { useParams } from "react-router-dom";

export const CreateDefermentAssessmentButton = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <CreateDefermentCommonButton recordID={+id} isRedirectManagePage={false} />
  );
};
