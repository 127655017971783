import { mockRegisterTransactions } from "@app/products/property/registers/[id]/components/child-screens/transactions/mock";
import { IRegisterTransactions } from "@app/products/property/registers/[id]/components/child-screens/transactions/model";
import { sleep } from "@common/utils/common";

export const getRegisterTransactions = async (): Promise<
  IRegisterTransactions[] | undefined
> => {
  try {
    await sleep(1000);
    return mockRegisterTransactions;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
