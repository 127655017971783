import { isNil } from "lodash";

export const processDataLovInAmalgamate = (data?: {
  [key: number]: string;
}) => {
  if (isNil(data)) return [];
  const lov = [];
  for (const code in data) {
    const name = data[code];
    lov.push({ Code: parseInt(code), Name: name });
  }
  return lov;
};

export const getActualValueOption = (data: any[], value?: number) => {
  if (isNil(value)) return undefined;
  if (value === 0) {
    const existedItem = data?.find((item) => item.Code === 0);
    if (isNil(existedItem)) return undefined;
  }
  return value;
};
