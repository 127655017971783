import {
  ServiceStandardBatchUpdate,
  ServiceStandardBatchUpdateMapObj,
} from "@app/products/crms/service-standards/batch-update/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";

export const getRadioOptions = (options?: IKeyValuePacket[]) => {
  if (!options) return [];

  return options?.map((item: IKeyValuePacket) => {
    return {
      label: item.Value,
      value: item.Key,
    };
  });
};

const nameOfSvcStandardBatchUpdateMapObj =
  nameOfFactory<ServiceStandardBatchUpdateMapObj>();
const nameOfSvcStandardBatchUpdate =
  nameOfFactory<ServiceStandardBatchUpdate>();

export const getNameOfSvcStandardBatchUpdate = (
  name: keyof ServiceStandardBatchUpdate
) => {
  return `${nameOfSvcStandardBatchUpdateMapObj(
    "SvcStandardBatchUpdate"
  )}.${nameOfSvcStandardBatchUpdate(name)}`;
};
