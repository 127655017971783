import {
  DTO_NominatedProperty,
  DTO_VotingEntitlement_By_Election,
} from "@app/products/property/contacts-central-names/[id]/components/child-screens/voting/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfNominatedProperty = nameOfFactory<DTO_NominatedProperty>();
export const nominatePropertyCol: IColumnFields[] = [
  {
    field: nameOfNominatedProperty("Effective_Date"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfNominatedProperty("Finish_Date"),
    title: "Finish Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfNominatedProperty("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfNominatedProperty("Assess_Number"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfNominatedProperty("Property_Name_Address_Locality"),
    title: "Property Address",
  },
  {
    field: nameOfNominatedProperty("Nominated_Property_Id"),
    title: "Nominated Property ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

const nameOfVotingEntitlement =
  nameOfFactory<DTO_VotingEntitlement_By_Election>();
export const votingEntitlementCol: IColumnFields[] = [
  { field: nameOfVotingEntitlement("Election"), title: "Election" },
  {
    field: nameOfVotingEntitlement("Can_Vote"),
    title: "Could Vote?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: nameOfVotingEntitlement("Role_Name"), title: "Role" },
  {
    field: nameOfVotingEntitlement("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfVotingEntitlement("Assess_Number"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfVotingEntitlement("Property_Name_Address_Locality"),
    title: "Property Address",
  },
];
