import { isBoolean, isDate, isNil, isNumber, isString } from "lodash";

export const toStringType = (value: any): string | null | undefined => {
  if (isNil(value) || isString(value)) return value;
  return value.toString();
};
export const toNumberType = (value: any): number | null | undefined => {
  if (isNil(value) || isNumber(value)) return value;
  if (isString(value)) return parseFloat(value);
  return null;
};

export const toDateType = (value: any): Date | null | undefined => {
  if (isNil(value) || isDate(value)) return value;
  if (isString(value) || isNumber(value)) return new Date(value);
  return null;
};
export const toBooleanType = (value: any): boolean | null | undefined => {
  if (isBoolean(value) || isNil(value)) return value;
  if (isNumber(value)) return Boolean(value);
  if (isString(value)) return value === true.toString();
  return null;
};
