import React from "react";

export const TabSummary = () => {
  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <div className="cc-tab-summary-field">
              <strong>PID No.</strong>
              <p>5614</p>
            </div>
            <div className="cc-tab-summary-field">
              <strong>RIN</strong>
              <p>9039</p>
            </div>
            <div className="cc-tab-summary-field">
              <strong>Ward</strong>
              <p>Glenelg Shire</p>
            </div>
            <div className="cc-tab-summary-field">
              <strong>Address</strong>
              <p>40 Levetts Road, Bolwarra WA 3305</p>
            </div>
            <div className="cc-tab-summary-field">
              <strong>Legal Desc</strong>
              <p>Lot 1 LP120183</p>
            </div>
            <div className="cc-tab-summary-field">
              <strong>Owners</strong>
              <p>
                A W & R A Jones <br /> 40 Levetts Rd <br /> Bolwarra, Vic 3305
              </p>
            </div>
            <div className="cc-tab-summary-field">
              <strong>Ratepayers</strong>
              <p>
                A W & R A Jones 40 <br /> Levetts Rd <br /> Bolwarra, Vic 3305
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
