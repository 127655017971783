import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { FormAction } from "@app/products/crms/model";
import { isNil } from "lodash";

export const CRMSInspectionsBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `${CRMS_PRODUCT_NAME} - Inspections ${dynamicDisplayName}`;
  },
  getBookmarkDetail() {
    return `${CRMS_PRODUCT_NAME} Inspections`;
  },
};

export const crmsInspectionsListViewURL = (formAction: FormAction) => {
  return `odata/crms/internal/events/GetEventInspections(formAction=${formAction})?$count=true&`;
};

export const crmsServiceStandardListViewURL = (
  formAction: FormAction,
  flagString?: "flagDeactivatedOrgStructure" | "isInactiveSS"
) => {
  let params = "";
  if (!isNil(flagString)) {
    params = `,${flagString}=true`;
  }
  return `odata/crms/internal/servicestandard/GetServiceStandard(formAction=${formAction}${params})?$count=true&`;
};
