import { batchRecallInputValidators as validators } from "@app/core/recall/components/dialogs/batch-recall/util";
import {
  BATCH_RECALL_SEND_OPTIONS,
  BATCH_RECALL_STEP_KEYS as keys,
} from "@app/core/recall/constant";
import { OfficerPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/_index";
import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import {
  requiredValidator,
  validatorDateValueWithMinMax,
} from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Chip } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
import { cloneDeep } from "lodash";
import React, { useCallback, useRef } from "react";
import { useDeepCompareEffect } from "react-use";
import "./_index.scss";
import { RecallAttachmentField } from "./components/attachment-input/_index";
import { renderRecallAttachmentItem } from "./components/attachment-input/util";

export const FormRecallStep = (props: IFormStepElement) => (
  <FieldArray name={props.nameOf()} {...props} component={FormRecall} />
);

const FormRecall = (props: IFormStepElement) => {
  //#region props, states & refs
  const { nameOf, formRenderProps, setStepsVisible, options } = props;
  const { onChange, valueGetter } = formRenderProps;
  const date = useRef(new Date());
  //#endregion

  //#region event handlers
  const handleChangeOfficer = (officer: IOfficer | null) => {
    // including removing the selection of officer
    onChange(nameOf("Officer_ID"), { value: officer?.ID });
    onChange("UIControl.Officer_Name", { value: officer?.Name });
  };

  const handleSwitchingSendOption = (event: RadioGroupChangeEvent) => {
    onChange("UIControl.SendOption", { value: event.value });
    setStepsVisible([
      {
        key: keys.CommunicationRecipient,
        visible: event.value === "communication",
      },
      {
        key: keys.CommunicationMessageTemplate,
        visible: event.value === "communication",
      },
      {
        key: keys.CommunicationMailMergeTemplate,
        visible: event.value === "communication",
      },
      {
        key: keys.CommunicationAttachment,
        visible: event.value === "communication",
      },
      {
        key: keys.CommunicationWorkflow,
        visible: event.value === "communication" && false,
      },
      {
        key: keys.MailMergeTemplate,
        visible: event.value === "mailmerge",
      },
      {
        key: keys.MailMergeOptions,
        visible: event.value === "mailmerge",
      },
      {
        key: keys.MailMergeWorkflow,
        visible: event.value === "mailmerge" && false, // this step is currently not implemented
      },
    ]);
  };
  //#endregion

  //#region Validators
  const validateRecallDate = useCallback((value: any) => {
    return (
      requiredValidator(value) ||
      validatorDateValueWithMinMax(value, null, date.current)
    );
  }, []);
  //#region

  //#region computed
  const isDisabled = options?.disabled;
  //#endregion

  //#region effects
  useDeepCompareEffect(
    function populateAttachmentIds() {
      const attachments = cloneDeep(valueGetter("UIControl.AttachmentFiles"));
      const recallAttachmentIds = options?.recallAttachmentIds ?? {};
      for (let pos = 0; pos < attachments.length; pos++) {
        for (const recallId in recallAttachmentIds) {
          if (recallAttachmentIds?.[recallId]?.[pos]) {
            attachments[pos].parentMapping =
              attachments[pos].parentMapping ?? {};
            attachments[pos].parentMapping[recallId] =
              recallAttachmentIds[recallId][pos];
          }
        }
      }
      onChange("UIControl.AttachmentFiles", {
        value: renderRecallAttachmentItem(
          attachments,
          ({ text }: { text: string }) => (
            <span>
              {text}{" "}
              <Chip
                size={"small"}
                rounded={"full"}
                themeColor={"success"}
                children="new"
              />
            </span>
          )
        ),
      });
      onChange(nameOf("ListOfAttachments"), { value: [] });
    },
    [options?.recallAttachmentIds]
  );
  //#endregion

  return (
    <section className="cc-form-recall-detail">
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Recall date" isMandatory />
            <Field
              name={nameOf("Date_Recall")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              max={date.current}
              validator={validateRecallDate}
              disabled={isDisabled}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Product type" />
            <Field
              name={nameOf("ProductType")}
              component={CCInput}
              disabled={isDisabled}
              validator={isDisabled ? undefined : validators.stringMaxLength150}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Product name" />
            <Field
              name={nameOf("ProductName")}
              component={CCInput}
              disabled={isDisabled}
              validator={isDisabled ? undefined : validators.stringMaxLength150}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Recall number" />
            <Field
              name={nameOf("Number")}
              component={CCInput}
              disabled={isDisabled}
              validator={isDisabled ? undefined : validators.stringMaxLength50}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Batch number" />
            <Field
              name={nameOf("BatchNo")}
              component={CCInput}
              disabled={isDisabled}
              validator={isDisabled ? undefined : validators.stringMaxLength150}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Country of origin" />
            <Field
              name={nameOf("CountryOfOrigin")}
              component={CCInput}
              disabled={isDisabled}
              validator={isDisabled ? undefined : validators.stringMaxLength150}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Marking date" />
            <Field
              name={nameOf("DateMarking")}
              component={CCInput}
              disabled={isDisabled}
              validator={isDisabled ? undefined : validators.stringMaxLength150}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Product size" />
            <Field
              name={nameOf("ProductSize")}
              component={CCInput}
              disabled={isDisabled}
              validator={isDisabled ? undefined : validators.stringMaxLength150}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Packaging" />
            <Field
              name={nameOf("ProductPackaging")}
              component={CCInput}
              disabled={isDisabled}
              validator={isDisabled ? undefined : validators.stringMaxLength150}
            />
          </div>
          <div className="cc-field cc-col-span-3">
            <CCLabel title="Description" />
            <Field
              name={nameOf("Details")}
              component={CCTextArea}
              rows={1}
              readOnly={isDisabled}
            />
          </div>
          <div className="cc-field cc-col-span-3">
            <RecallAttachmentField
              files={valueGetter("UIControl.AttachmentFiles")}
              updateFileData={(value: any) =>
                onChange(nameOf("ListOfAttachments"), { value })
              }
              updateFileUI={(value: any) =>
                onChange("UIControl.AttachmentFiles", { value })
              }
              updateAttachmentRemoval={(value: any) =>
                onChange("UIControl.AttachmentRemoval", { value })
              }
              attachmentRemoval={valueGetter("UIControl.AttachmentRemoval")}
              removalWarning={options?.attachmentRemovalWarning}
              disabled={isDisabled}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Officer" />
            <Field
              name={"UIControl.Officer_Name"}
              component={OfficerPicker}
              title={"Pick Officer"}
              showClearButton
              onPickOfficer={handleChangeOfficer}
              disabled={isDisabled}
            />
          </div>
          <div className="cc-field cc-col-span-3">
            <CCLabel title="Send" />
            <Field
              name="UIControl.SendOption"
              component={CCRadioGroup}
              data={BATCH_RECALL_SEND_OPTIONS}
              layout="horizontal"
              onChange={handleSwitchingSendOption}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
