import { CSLFormBodyDEH } from "@app/products/csl/[id]/components/child-screens/general/components/form-element/components/deh/_index";
import { CSLFormBodyR25 } from "@app/products/csl/[id]/components/child-screens/general/components/form-element/components/r25/_index";
import { CSLFormBodySDL } from "@app/products/csl/[id]/components/child-screens/general/components/form-element/components/sdl/_index";
import { CSLFormBodySIDL } from "@app/products/csl/[id]/components/child-screens/general/components/form-element/components/sidl/_index";
import {
  approvingOfficerFields,
  assessingOfficerFields,
  licenceCategoryFields,
  localityUsedFields,
  parentPage,
} from "@app/products/csl/[id]/components/child-screens/general/components/form-element/config";
import { CSLRegisterForm, LicenceType } from "@app/products/csl/[id]/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { FormRoute } from "@common/constants/menu";
import { useIsNew } from "@common/hooks/useIsNew";
import {
  checkUrlExist,
  getWebAndEmail,
  nameOfFactory,
  populateValue,
} from "@common/utils/common";
import {
  requiredValidator,
  textAreaValidator,
} from "@common/utils/field-validators";
import { formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";
const nameOf = nameOfFactory<CSLRegisterForm>();

interface IProps {
  formRenderProps: FormRenderProps;
}

export const CSLFormElement = observer(({ formRenderProps }: IProps) => {
  const { modified, valid, onSubmit, valueGetter } = formRenderProps;
  const history = useHistory();
  const isNew = useIsNew();
  const { pushNotification } = useCCAppNotificationStore();
  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (modified) {
      onSubmit(event);
      return;
    }

    if (!valid) return;
    pushNotification({
      title: "The form is not modified",
      type: "warning",
    });
  };
  return (
    <FormElement>
      {/* Reference by a label in CSLActionBar.tsx */}
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />

      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-col-span-2">
            <div className="cc-field">
              <label className="cc-label">
                Licence category
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                component={InputPicker}
                {...licenceCategoryFields(formRenderProps)}
                name={nameOf("LicenceCategory")}
              />
            </div>
          </div>
          {!isNew && (
            <div className="cc-field">
              <Checkbox
                label={"Do not renew"}
                defaultChecked={valueGetter(nameOf("Flag_DoNotRenew"))}
              />
            </div>
          )}
        </div>
      </div>
      <hr className="cc-divider" />

      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Licensee
              <CCTooltip type="validator" position="right" />
            </label>

            <Field
              onClick={() => {
                history.push(
                  `${FormRoute.FORM_CONTACT_REPLATIONSHIP}/${
                    valueGetter(nameOf("Applicant_RID")) || 0
                  }`,
                  {
                    parentPage,
                    recordType: "11000",
                  }
                );
              }}
              // disabled={true}
              autoComplete="off"
              name={nameOf("licensee")}
              className="disabled"
              component={InputPicker}
              validator={requiredValidator}
              placeholder={"Licensee"}
            />
          </div>

          <CCValueField
            label="Address"
            value={valueGetter(nameOf("address"))}
          />
          <CCValueField
            label="Postal address"
            value={valueGetter(nameOf("postalAddress"))}
          />
        </div>
        <div className="cc-form-cols-1">
          <CCValueField
            label="Trading name"
            value={valueGetter(nameOf("tradingName"))}
          />
        </div>
        <div className="cc-form-cols-3">
          <CCValueField label="Phone" value={valueGetter(nameOf("phone"))} />
          <CCValueField label="Mobile" value={valueGetter(nameOf("mobile"))} />

          {/* TODO: Update alter because. I don't know logic here*/}
          <div className="cc-field">
            <label className="cc-label">Email/Web</label>
            <div className="cc-label-field">
              {
                getWebAndEmail(populateValue(valueGetter(nameOf("emailOrWeb"))))
                  .email
              }
              {checkUrlExist(
                `http://${
                  getWebAndEmail(
                    populateValue(valueGetter(nameOf("emailOrWeb")))
                  ).website
                }`
              ) ? (
                <a
                  href={`http://${
                    getWebAndEmail(
                      populateValue(valueGetter(nameOf("emailOrWeb")))
                    ).website
                  }`}
                >
                  {
                    getWebAndEmail(
                      populateValue(valueGetter(nameOf("emailOrWeb")))
                    ).website
                  }
                </a>
              ) : (
                populateValue(valueGetter(nameOf("emailOrWeb")))
              )}
            </div>
          </div>
        </div>
      </div>
      <hr className="cc-divider" />

      {!isNew && (
        <>
          <div className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Contact
                  <CCTooltip type="validator" position="right" />
                </label>

                <Field
                  onClick={() => {
                    history.push(
                      `${FormRoute.FORM_CONTACT_REPLATIONSHIP}/${
                        valueGetter(nameOf("Business_RID")) || 0
                      }`,
                      {
                        parentPage,
                        recordType: "11000",
                      }
                    );
                  }}
                  autoComplete="off"
                  name={nameOf("contact")}
                  placeholder="Contact"
                  className="disabled"
                  component={InputPicker}
                  validator={requiredValidator}
                />
              </div>
              <CCValueField
                label="Position"
                value={valueGetter(nameOf("contactPosition"))}
              />
            </div>
            <div className="cc-form-cols-3">
              <CCValueField
                label="Phone"
                value={valueGetter(nameOf("contactPhone"))}
              />
              <CCValueField
                label="Mobile"
                value={valueGetter(nameOf("contactMobile"))}
              />
              <CCValueField
                label="Email"
                value={valueGetter(nameOf("contactEmail"))}
              />
            </div>
          </div>
          <hr className="cc-divider" />
        </>
      )}
      {!isNew && (
        <>
          <div className="cc-field-group">
            {valueGetter(nameOf("LicenceCategory_ID")) ===
              LicenceType.R25_REGULATION && (
              <CSLFormBodyR25 {...formRenderProps} />
            )}
            {valueGetter(nameOf("LicenceCategory_ID")) ===
              LicenceType.DEH_REGULATION_SODIUM && (
              <CSLFormBodyDEH {...formRenderProps} />
            )}
            {valueGetter(nameOf("LicenceCategory_ID")) ===
              LicenceType.SDL_DRUG_LICENCE && (
              <CSLFormBodySDL {...formRenderProps} />
            )}
            {valueGetter(nameOf("LicenceCategory_ID")) ===
              LicenceType.SIDL_IMPREST_DRUG_LICENCE && (
              <CSLFormBodySIDL {...formRenderProps} />
            )}
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  Location/Site used
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  component={InputPicker}
                  {...localityUsedFields(formRenderProps)}
                  name={"LocalityUsed"}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Drugs possessed</label>
                <Field
                  name={nameOf("DrugsPossessed")}
                  rows={5}
                  value={valueGetter(nameOf("DrugsPossessed"))}
                  component={CCTextArea}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  Purpose of
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={nameOf("PurposeOf")}
                  rows={5}
                  value={valueGetter(nameOf("PurposeOf"))}
                  component={CCTextArea}
                  validator={textAreaValidator}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  Storage
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={nameOf("StorageArrangements")}
                  rows={5}
                  value={valueGetter(nameOf("StorageArrangements"))}
                  component={CCTextArea}
                  validator={textAreaValidator}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  Supervised by
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={nameOf("SupervisedBy")}
                  rows={5}
                  value={valueGetter(nameOf("SupervisedBy"))}
                  component={CCTextArea}
                  validator={textAreaValidator}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  Persons application to order
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={nameOf("PersonsApprovedToAccess")}
                  rows={5}
                  value={valueGetter(nameOf("PersonsApprovedToAccess"))}
                  component={CCTextArea}
                  validator={textAreaValidator}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  Persons application to access
                  <CCTooltip type="validator" position="right" />
                </label>

                <Field
                  name={nameOf("PersonsApprovedToOrder")}
                  rows={5}
                  value={valueGetter(nameOf("PersonsApprovedToOrder"))}
                  component={CCTextArea}
                  validator={textAreaValidator}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Misc 1</label>
                <Field
                  name={nameOf("Misc1")}
                  rows={5}
                  value={valueGetter(nameOf("Misc1"))}
                  component={CCTextArea}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Misc 2</label>
                <Field
                  name={nameOf("Misc2")}
                  rows={5}
                  value={valueGetter(nameOf("Misc2"))}
                  component={CCTextArea}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Misc 3</label>
                <Field
                  name={nameOf("Misc3")}
                  rows={5}
                  value={valueGetter(nameOf("Misc3"))}
                  component={CCTextArea}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  Theft and loss policy required
                </label>
                <Field
                  name={nameOf("Flag_TheftLossPolicyRequired")}
                  defaultChecked={valueGetter(
                    nameOf("Flag_TheftLossPolicyRequired")
                  )}
                  component={CCSwitch}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  Code of practice for transport and storage of drugs required
                </label>
                <Field
                  name={nameOf("Flag_TransportCodeRequired")}
                  defaultChecked={valueGetter(
                    nameOf("Flag_TransportCodeRequired")
                  )}
                  component={CCSwitch}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Report required</label>
                <Field
                  name={nameOf("Flag_VetDrugUseReportRequired")}
                  defaultChecked={valueGetter(
                    nameOf("Flag_VetDrugUseReportRequired")
                  )}
                  component={CCSwitch}
                />
              </div>
              {valueGetter(nameOf("Flag_VetDrugUseReportRequired")) && (
                <div className="cc-field">
                  <label className="cc-label">Report due</label>
                  <Field
                    name={nameOf("Date_VetDrugUseReportRequired")}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                  />
                </div>
              )}
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Payment by journal required</label>
                <Field
                  name={nameOf("Flag_PaymentJournalRequired")}
                  defaultChecked={valueGetter(
                    nameOf("Flag_PaymentJournalRequired")
                  )}
                  component={CCSwitch}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  Licensee is part of country health network
                </label>
                <Field
                  name={nameOf("Flag_CountryHealthNetwork")}
                  defaultChecked={valueGetter(
                    nameOf("Flag_CountryHealthNetwork")
                  )}
                  component={CCSwitch}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Wholesale report</label>
                <Field
                  name={nameOf("Flag_WholesaleReport")}
                  defaultChecked={valueGetter(nameOf("Flag_WholesaleReport"))}
                  component={CCSwitch}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Ethics committee approval</label>
                <Field
                  name={nameOf("Flag_EthicsCommitteeApproval")}
                  defaultChecked={valueGetter(
                    nameOf("Flag_EthicsCommitteeApproval")
                  )}
                  component={CCSwitch}
                />
              </div>
              {valueGetter(nameOf("Flag_EthicsCommitteeApproval")) && (
                <div className="cc-field">
                  <label className="cc-label">Expires</label>
                  <Field
                    name={nameOf("DateIssued")}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                  />
                </div>
              )}
            </div>
          </div>
          <hr className="cc-divider" />
        </>
      )}
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Assessing officer</label>
            <Field
              component={InputPicker}
              {...assessingOfficerFields(formRenderProps)}
              name={"AssessingOfficer"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Approving officer</label>
            <Field
              component={InputPicker}
              {...approvingOfficerFields(formRenderProps)}
              name={"ApprovingOfficer"}
            />
          </div>
          <CCValueField
            label="OS fees"
            value={valueGetter("OSFees") || 0}
            style={formatOSFees(valueGetter("OSFees"))}
            format={CURRENCY_FORMAT.CURRENCY1}
          />
        </div>
        <div className="cc-form-cols-3">
          <CCValueField label="Lodged" value={valueGetter(nameOf("lodged"))} />
          <CCValueField
            label="Approved"
            value={valueGetter(nameOf("approved"))}
          />
          <div className="cc-field">
            <label className="cc-label">Signed form</label>
            <Field
              name={nameOf("Flag_SignedDeclaration")}
              defaultChecked={true}
              value={valueGetter(nameOf("Flag_SignedDeclaration"))}
              component={CCSwitch}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <CCValueField label="Issued" value={valueGetter(nameOf("issued"))} />
          <div className="cc-field">
            <label className="cc-label">Expires</label>
            <Field
              name={nameOf("DateExpires")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
            />
          </div>
          <CCValueField
            label="BPAY reference"
            value={valueGetter("BPAYReference")}
          />
        </div>
      </div>
    </FormElement>
  );
});
