import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

export const colTransactions: IColumnFields[] = [
  {
    field: "DateTime",
    title: "Date/Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "TransType",
    title: "Transactions Type",
  },
  {
    field: "Reference",
    title: "Reference",
  },
  {
    field: "Description",
    title: "Description",
    footerCell: <TextCell value="Overall Total" />,
  },
  {
    field: "ReceiptNumber",
    title: "Receipt Number",
  },
  {
    field: "TransactionAmount",
    title: "Transaction Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("TransactionAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "ProgressiveTotal",
    title: "Progressive Total",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "PrincipalAmount",
    title: "Principal Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("PrincipalAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "CurrentAmountDue",
    title: "Current Amount Due",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("CurrentAmountDue", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "PrincipalBalance",
    title: "Principal Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "InterestBalance",
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "TotalBalanceOwing",
    title: "Total Balance Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "CurrentBalanceOwing",
    title: "Current Balance Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "JournalNumber",
    title: "Journal Number",
  },
  {
    field: "SessionNumber",
    title: "Session Number",
  },
  {
    field: "NoticeIssueDate",
    title: "Notice Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "NoticeDueDate",
    title: "Notice Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "UserId",
    title: "User ID",
  },
  {
    field: "IsProposed",
    title: "Is Proposed?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "IsCancelled",
    title: "Is Cancelled?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "SuppressOnNotice",
    title: "Suppress on Notice",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "CreatedOn",
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "AccountTransactionId",
    title: "Account Transaction ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
