import { DirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IdentityPacket } from "@common/models/identityPacket";

export const postSaveDirectDebitAuthorisation = async (
  directDebitAuthorisations: DirectDebitAuthorisation
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "api/receipting/internal/directdebitauthorisation",
      directDebitAuthorisations
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
