import { odataGetDebtorNumbers } from "@app/core/components/common/utils";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import {
  IOptionDebtorNumber,
  RefNumberUsage,
} from "@common/input-pickers/debtor-number/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<RefNumberUsage>();
export const colPickDebtorNumber: IColumnFields[] = [
  {
    field: nameOf("Sys_DebtorNumber"),
    title: "Debtor number",
  },
  {
    field: nameOf("Description"),
    title: "Name",
  },
  {
    field: nameOf("RecordType_Name"),
    title: "Record type",
  },
];

export const optionDebtorNumber = ({
  contactId,
  recordId,
  recordType,
}: IOptionDebtorNumber): IOptionInputPicker => {
  return {
    dialog: {
      titleHeader: "Pick Debtor Number",
      maxWidth: "50%",
    },
    boxSearch: {
      colSearch: "Sys_DebtorNumber",
      colShowComboboxSearch: [
        {
          field: "Sys_DebtorNumber",
          title: "Debtor Number",
          isGrow: true,
        },
      ],
    },
    grid: {
      columnFields: colPickDebtorNumber,
      primaryField: nameOf("Record_Id"),
      dataUrl: odataGetDebtorNumbers(contactId, recordId, recordType),
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
    },
  };
};
