import { DTO_Title_Details } from "@app/products/property/titles/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const getTitleDetailsTabById = async (
  titleId: number
): Promise<DTO_Title_Details | undefined> => {
  try {
    const response = await CoreAPIService.getClient().get<DTO_Title_Details>(
      `/api/property/internal/title/${titleId}/details`
    );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
