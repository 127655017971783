import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import { DTO_AssessmentCharge_Levy } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/model";
import { colLevies } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/components/steps/adjust-charges/components/detail/config";

interface IChargeDetailsProps extends GridDetailRowProps {
  onDataChange?: any;
  readOnly?: boolean;
}

const nameOf = nameOfFactory<DTO_AssessmentCharge_Levy>();
export const ChargesDetails = (props: IChargeDetailsProps) => {
  return (
    <CCGrid
      editableMode="cell"
      data={props?.dataItem?.ChargeLevies?.Levies ?? []}
      columnFields={colLevies}
      primaryField={nameOf("Charge_Balances_Id")}
      onDataChange={props?.onDataChange}
      readOnly={props?.readOnly}
    />
  );
};
