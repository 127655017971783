import { useBuildingRegistersStormwaterStore } from "@app/products/building/registers/stormwater/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistedBuildingRegisterStormwater } from "./components/forms/existed/_index";
import { NewBuildingRegisterStormwater } from "./components/forms/new/_index";

const ManageBuildingRegisterStormwater = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { resetStore, loadBuildingRegistersStormwater } =
    useBuildingRegistersStormwaterStore();

  useEffectOnce(() => {
    return () => resetStore();
  });
  useEffect(() => {
    if (isNew) return;
    loadBuildingRegistersStormwater(+id);
  }, [id, isNew, loadBuildingRegistersStormwater]);

  if (isNew) {
    return <NewBuildingRegisterStormwater />;
  }
  return <ExistedBuildingRegisterStormwater />;
});

export default ManageBuildingRegisterStormwater;
