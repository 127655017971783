import { ExportFormat, gridDefaultProps } from "@components/cc-grid/config";
import { IColumnFields } from "@components/cc-grid/model";
import { getFirstRowInGroup } from "@components/cc-grid/util";
import { ICCNotification } from "@components/cc-notification/components/cc-notification-item/model";
import { PRODUCT_LIST_VIEW_TAKE } from "@components/cc-product-list-view/config";
import {
  CompositeFilterDescriptor,
  process,
  State,
} from "@progress/kendo-data-query";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" }); // Strict mode: runInAction

export default class DetailParcelListViewStore {
  /* =========================== observable =================*/
  private _notifications: ICCNotification[] = [];
  private _isShowFilterBar: boolean = false;
  private _isShowSlideBar: boolean = false;
  private _gridSelectedIds: any[] = [];
  private _gridTotalSelected?: number;

  private _filterBarState?: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };
  private _disableRowField: string = gridDefaultProps.disableRowField;
  private _canSelectField: string = gridDefaultProps.canSelectField;
  private _gridData?: any[] = [];
  private _gridDataState: State = {
    take: PRODUCT_LIST_VIEW_TAKE,
    skip: 0,
    filter: { logic: "and", filters: [] },
    sort: [],
    group: [],
  };
  private _gridColumns: IColumnFields[] = [];
  private _gridSelectedRows: any = [];
  private _gridExportFormat: ExportFormat = ExportFormat.NONE;

  /* =========================== Actions =====================*/
  pushNotifications = (notifications: ICCNotification[]) => {
    runInAction(() => {
      this._notifications = notifications.concat(this._notifications);
    });
  };

  setNotifications = (notifications: ICCNotification[]) => {
    runInAction(() => {
      this._notifications = notifications;
    });
  };
  setIsShowFilterBar = (isShowFilterBar: boolean) => {
    runInAction(() => {
      this._isShowFilterBar = isShowFilterBar;
    });
  };

  setIsShowSlideBar = (isShowSlideBar: boolean) => {
    runInAction(() => {
      this._isShowSlideBar = isShowSlideBar;
      if (isShowSlideBar && !this.lastSelectedRow && this.gridData) {
        const processed = process(
          this.gridData.filter((item) =>
            item[this._disableRowField] ? item[this._canSelectField] : true
          ),
          {
            ...this.gridDataState,
            skip: 0,
          }
        ).data;

        const firstRow = getFirstRowInGroup(
          processed,
          this.gridDataState?.group
        );
        if (firstRow) this._gridSelectedRows = [firstRow];
      }
    });
  };
  setFilterBarState = (filter?: CompositeFilterDescriptor) => {
    runInAction(() => {
      this._filterBarState = filter;
      if (!this._isShowFilterBar) {
        this._isShowFilterBar = true;
      }
    });
  };
  setGridData = (gridData?: any[]) => {
    runInAction(() => {
      this._gridData = gridData || [];
    });
  };
  setCanSelectField = (canSelectField?: string) => {
    runInAction(() => {
      this._canSelectField = canSelectField || gridDefaultProps.canSelectField;
    });
  };
  setDisableRowField = (disableRowField?: string) => {
    runInAction(() => {
      this._disableRowField =
        disableRowField || gridDefaultProps.disableRowField;
    });
  };
  setGridDataState = (gridDataState: State) => {
    runInAction(() => {
      this._gridDataState = gridDataState;
      this._filterBarState = gridDataState.filter;
    });
  };
  setGridColumns = (gridColumns: IColumnFields[]) => {
    runInAction(() => {
      this._gridColumns = gridColumns;
    });
  };
  setGridSelectedIds = (gridSelectedIds: any[]) => {
    runInAction(() => {
      this._gridSelectedIds = [...gridSelectedIds];
    });
  };
  setGridSelectedRows = (gridSelectedRows: any[]) => {
    runInAction(() => {
      this._gridSelectedRows = [...gridSelectedRows];
      if (gridSelectedRows.length === 0) {
        this._isShowSlideBar = false;
      }
    });
  };
  setGridTotalSelected = (gridTotalSelected: number) => {
    runInAction(() => {
      this._gridTotalSelected = gridTotalSelected;
    });
  };

  setGridExportFormat = (format: ExportFormat) => {
    runInAction(() => {
      this._gridExportFormat = format;
    });
  };
  resetStore = () => {
    runInAction(() => {
      this._notifications = [];
      this._isShowFilterBar = false;
      this._isShowSlideBar = false;

      this._filterBarState = {
        logic: "and",
        filters: [],
      };

      this._gridData = [];
      this._gridDataState = {
        take: PRODUCT_LIST_VIEW_TAKE,
        skip: 0,
        filter: { logic: "and", filters: [] },
        sort: [],
        group: [],
      };
      this._gridColumns = [];
      this._gridSelectedRows = [];
      this._gridSelectedIds = [];
      this._gridExportFormat = ExportFormat.NONE;
    });
  };

  resetGridDataState = () => {
    runInAction(() => {
      this._gridDataState = {
        take: PRODUCT_LIST_VIEW_TAKE,
        skip: 0,
        filter: { logic: "and", filters: [] },
        sort: [],
        group: [],
      };
    });
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  /* =========================== computed ===================*/
  get notifications(): ICCNotification[] {
    return toJS(this._notifications);
  }

  get isShowFilterBar(): boolean {
    return toJS(this._isShowFilterBar);
  }
  get isShowSlideBar(): boolean {
    return toJS(this._isShowSlideBar);
  }
  get filterBarState(): CompositeFilterDescriptor | undefined {
    return toJS(this._filterBarState);
  }
  get canSelectField(): string {
    return this._canSelectField;
  }
  get disableRowField(): string {
    return this._disableRowField;
  }
  get gridData(): any[] {
    return this._gridData ? toJS(this._gridData) : [];
  }
  get isEmptyData(): boolean {
    return !this._gridData || toJS(this._gridData).length === 0;
  }

  get gridDataState(): State {
    return toJS(this._gridDataState);
  }

  get gridColumns(): IColumnFields[] {
    return toJS(this._gridColumns);
  }
  get gridSelectedIds(): any[] {
    return toJS(this._gridSelectedIds) || [];
  }
  get gridSelectedRows(): any[] {
    return toJS(this._gridSelectedRows) || [];
  }
  get gridTotalSelected(): number {
    return toJS(this._gridTotalSelected) || 0;
  }
  get isEmptyGridSelectedRow(): boolean {
    return toJS(this._gridSelectedRows.length) === 0;
  }

  get lastSelectedRow(): any {
    return toJS(this._gridSelectedRows[this._gridSelectedRows.length - 1]);
  }

  get gridExportFormat(): ExportFormat {
    return toJS(this._gridExportFormat);
  }
}

export const detailParcelListViewStore = new DetailParcelListViewStore();
const detailParcelListViewStoreContext = createContext(
  new DetailParcelListViewStore()
);
export const useDetailParcelListViewStore = () => {
  return useContext(detailParcelListViewStoreContext);
};
