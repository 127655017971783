import { DirectDebitBankAccount } from "@app/products/direct-debit/system-admin/model";
import { stringLengthValidator } from "@app/products/direct-debit/system-admin/util";
import { nameOfFactory } from "@common/utils/common";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Field } from "@progress/kendo-react-form";
import React from "react";

interface IDDBankAccountGeneralSectionProps {
  isInactive?: boolean;
}

const nameOf = nameOfFactory<DirectDebitBankAccount>();

const descriptionValidator = (value: any) =>
  stringLengthValidator(value, 1, 50);

export const DDBankAccountGeneralSection = ({
  isInactive,
}: IDDBankAccountGeneralSectionProps) => {
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="Description" isMandatory />
          <Field
            name={nameOf("Description")}
            component={CCInput}
            placeholder="Description"
            disabled={isInactive}
            validator={descriptionValidator}
          />
        </div>
      </div>
    </section>
  );
};
