import {
  CommunicationButton,
  ICommunicationButton,
} from "@app/core/communication/buttons-list-view/components/buttons/_index";
import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const CommunicationButtonAssessmentCollections = observer(
  (props: ICommunicationButton) => {
    const { gridSelectedRows } = useDetailAssessmentListViewStore();

    return (
      <CommunicationButton {...props} gridSelectedRows={gridSelectedRows} />
    );
  }
);
