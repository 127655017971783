import { eventEmitter } from "@/App";
import { isSuccessResponse } from "@common/apis/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  getPublicHolidayById,
  postSavePublicHoliday,
} from "@common/pages/settings/lookups/public-holidays/_id/api";
import { publicHolidayItemsList } from "@common/pages/settings/lookups/public-holidays/_id/components/public-holidays-dialog/config";
import {
  LookupDatesDTO,
  SettingState,
} from "@common/pages/settings/lookups/public-holidays/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef, useState } from "react";

export interface IPublicHolidaysDialogProps {
  onCloseDialog: () => void;
  publicHolidayID: number;
  setShowSlideBar?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IPublicHolidayItem {
  isHaveState: boolean;
  label: string;
  fieldNameDescription: keyof LookupDatesDTO;
  fieldName: keyof LookupDatesDTO;
}

export const PublicHolidaysDialog = observer(
  ({
    onCloseDialog,
    publicHolidayID,
    setShowSlideBar,
  }: IPublicHolidaysDialogProps) => {
    const { settings } = useCommonCoreStore();
    const settingState: SettingState = useMemo(() => {
      return {
        isHaveACT: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_ACTHOLIDAYS]
        ),
        isHaveNSW: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_NSWHOLIDAYS]
        ),
        isHaveVIC: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_VICHOLIDAYS]
        ),
        isHaveSA: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_SAHOLIDAYS]
        ),
        isHaveTAS: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_TASHOLIDAYS]
        ),
        isHaveWA: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_WAHOLIDAYS]
        ),
        isHaveNT: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_NTHOLIDAYS]
        ),
        isHaveQLD: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_QLDHOLIDAYS]
        ),
      };
    }, [settings]);
    const [initData, setInitData] = useState<LookupDatesDTO>();
    const [loading, setIsLoading] = useState<boolean>(false);
    const [loadingSave, setIsLoadingSave] = useState<boolean>(false);
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const handleSubmit = async (event: FormSubmitClickEvent) => {
      setShowSlideBar?.(false);
      setIsLoadingSave(true);
      const responseSaveData = await postSavePublicHoliday(
        event.values as LookupDatesDTO
      );
      setIsLoadingSave(false);

      if (isSuccessResponse(responseSaveData)) {
        eventEmitter.emit(CCGridEventType.RefreshOData);
        onCloseDialog();
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          type: "error",
          description: responseSaveData.error ?? "Save public holiday failed.",
        });
      }
    };

    const loadInitData = async () => {
      setIsLoading(true);
      const responseInitData = await getPublicHolidayById(publicHolidayID);
      setIsLoading(false);

      if (isSuccessResponse(responseInitData) && responseInitData?.data) {
        setInitData(responseInitData.data);
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          type: "error",
          description: responseInitData.error ?? "Get public holiday failed.",
        });
      }
    };

    const renderPublicHolidayItem = (
      onChange: (
        name: string,
        options: {
          value: any;
        }
      ) => void
    ) => {
      const publicHolidayItems = publicHolidayItemsList(settingState);

      return publicHolidayItems
        .map((publicHolidayItem: IPublicHolidayItem) => {
          if (!publicHolidayItem.isHaveState) return undefined;
          return (
            <div className="cc-field" key={publicHolidayItem.fieldName}>
              <CCLabel title={publicHolidayItem.label} />
              <Field
                name={publicHolidayItem.fieldNameDescription}
                component={CCInput}
                onChange={(event: InputChangeEvent) => {
                  onChange(publicHolidayItem.fieldNameDescription, {
                    value: event.value,
                  });
                  onChange(publicHolidayItem.fieldName, { value: true });
                }}
                disabled={loadingSave}
              />
            </div>
          );
        })
        .filter((publicHolidayItem) => publicHolidayItem);
    };

    useEffect(() => {
      loadInitData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicHolidayID]);

    return (
      <Form
        initialValues={initData}
        key={JSON.stringify(initData)}
        onSubmitClick={(event: FormSubmitClickEvent) => handleSubmit(event)}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit, onChange } = formRenderProps;
          return (
            <CCDialog
              maxWidth="35%"
              height="auto"
              titleHeader="Public Holiday"
              onClose={() => {
                onCloseDialog();
              }}
              isLoading={loading}
              bodyElement={
                <FormElement className="cc-form">
                  <div className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <section className="cc-field-group">
                      <div className="cc-form-cols-1">
                        {renderPublicHolidayItem(onChange)}
                      </div>
                    </section>
                  </div>
                </FormElement>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    className={"cc-dialog-button"}
                    onClick={() => {
                      onCloseDialog();
                    }}
                    disabled={loadingSave}
                  >
                    Cancel
                  </Button>
                  <Button
                    themeColor="primary"
                    onClick={onSubmit}
                    className={"cc-dialog-button"}
                    iconClass={loadingSave ? "fas fa-spinner fa-spin" : ""}
                    disabled={loadingSave}
                  >
                    Update
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
