import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const RecordsViewsHistoryTab = observer(() => {
  const { lastSelectedId } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const recordId: number = id ? parseInt(id) : lastSelectedId;

  return (
    <Journal
      id={recordId}
      triggersReload={recordId}
      recordType={RECORDTYPE.CORE_Attachment}
    />
  );
});
