import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { eComponent } from "@app/products/property/model";
import { SettingFolder } from "@app/products/property/system-admin/settings/model";

export enum eVisibility {
  Collapsed = "Collapsed",
  Hidden = "Hidden",
  Visible = "Visible",
}

export enum eFlags {
  "Entitled to Rebate(s)" = 1,
  "Has Direct Debit Authority" = 2,
  "Has Arrangement" = 3,
  "In Debt Recovery" = 4,
  "Exempt from Debt Recovery" = 5,
  "Under Compliance review" = 6,
  "Exempt from Land Tax" = 7,
  "Pending Lease Transfer" = 8,
  "Actively Deferring Balances" = 9,
  "Has Active Deferred Duty" = 10,
  "Pay in Full" = 11,
  "Single Farm Enterprise Exemption Granted" = 12,
  //"Has Direct Debit Authority" = 30,
  "Has Direct Debit Authority on Register Account" = 32,
  "Has Task Associated" = 40,
}

export interface DTO_Assessment_Summary {
  GisReferenceVisibility: eVisibility;
  GisReferences: DTO_GIS[];
  StatusAndGroups: DTO_Assessment_StatusAndGroups;
  LandInformation: DTO_Assessment_LandInformation;
  CheckDigits: DTO_Assessment_CheckDigit[];
  AlertMessages: DTO_Assessment_Alert[];
  Flags: DTO_Assessment_Flag[];
  FinancialAreaBalances: DTO_Assessment_FinancialAreaBalances;
  OwnersNoticeGroupVisibility: eVisibility;
  CanViewAssessmentFinancials: boolean;
  NameAndAddressGroupName: string;
  Assessment: DTO_Assessment;
  AssessmentId: number;
  OwnersGroupBoxVisibility: eVisibility;
  Settings: SettingFolder;
  Addresses: DTO_Address[];
  BannerStatus: string;
}

export interface DTO_Address {
  AddressId: number;
  AddressTypeName: string;
  propertyAddress: string;
  isAddressPrimary: boolean;
  PropertyName: string;
  Locality: string;
  LocationDescriptor: string;
  MapNumber: string;
  isRuralHouseNumber: boolean;
  MapReference: string;
  AddressPFI: number | null;
}

export interface DTO_Assessment {
  LesseeIn: Date | null;
  Ratepayer_NoticeGroup: string;
  Owner_NoticeGroup: string;
  State: string;
  Country: string;
  LGA: string;
  PrimaryLandUse: string;
  SecondaryLandUse: string;
  CalculatedLandArea: string;
  GISLandArea: string;
  GISLandAreaUnit: string;
  ActualLandArea: string;
  ActualLandAreaUnit: string;
  PropertyName: string;
  CreationDate: Date | null;
  LeaseCommencementDate: Date | null;
  LandTaxLevy: string;
  SAPHoldingId: string;
  StateVisibility: eVisibility;
  CountryVisibility: eVisibility;
  SecondaryLandUseVisibility: eVisibility;
  CalculatedLandAreaVisibility: eVisibility;
  GLSLandAreaVisibility: eVisibility;
  ActualLandAreaVisibility: eVisibility;
  LGAVisibility: eVisibility;
  LeaseInDateVisibility: eVisibility;
  LandTaxLevyVisibility: eVisibility;
  UnitEntitlementVisibility: eVisibility;
  UnitTypeEntitlementVisibility: eVisibility;
  LeaseCommencementVisibility: eVisibility;
  ValuationNumberVisibility: eVisibility;
  SAPHoldingIdVisibility: eVisibility;
  MasterPropertyId: number | null;
  AssessmentGroupId: number;
  UnitEntitlementAsString: string;
  UnitEntitlementPercentage: number | null;
  Id: number;
  PropertyName_Address_Locality: string;
  Legal_Description: string;
  Improvements: string;
  LandAreaFormatted: string;
  Ratepayer: string;
  Owners: string;
  Status: string;
  Type: string;
  StatusId: number;
  Group: string;
  LandUse: string;
  FireServicesLandStatus: string;
  AssessmentNumber: number | null;
  AssessmentNumberX: string;
  AssessmentReference: string;
  ValuationNumber: number | null;
  ValuationNumberX: string;
  PropertyLocationDescriptor: string;
  BPayReference: string;
  CBAReference: string;
  AustPostReference: string;
  DebtorReference: string;
  LandTaxReference: string;
  Category: string;
  HoldingLandArea: string;
  FSPLLandClassification: string;
  Differential: string;
  BalancesOutstandingListCaption: string;
  UnitEntitlement: number | null;
  CurrentValuation: DTO_Valuation;
  Contact_Alerts: string[];
  AssessmentEffectiveTo?: Date;
  AssessmentEffectiveFrom?: Date;
  QuarantineStatus: string;
  GISViewUrl: string;
  Property_Address_Locality: string;
}

export interface DTO_Assessment_Flag {
  Id: number;
  Name: string;
}

export interface DTO_Assessment_Alert {
  Message: string;
  Type: string;
  Date: Date | null;
  Component: eComponent | null;
  Id: number | null;
}

export interface DTO_Assessment_CheckDigit {
  Id: number | null;
  Name: string;
  CheckDigit: number | null;
}

export interface DTO_Assessment_FinancialAreaBalances {
  Balances: DTO_Assessment_FinancialAreaBalance[];
  Totals: DTO_FinancialAreaBalance_Totals;
}

export interface DTO_FinancialAreaBalance_Totals {
  Overdue: number | null;
  Due: number | null;
  OverdueDue: number | null;
  Balance: number | null;
  Total: number | null;
  Pending: number | null;
}

export interface DTO_Assessment_LandInformation {}

export interface DTO_Assessment_StatusAndGroups {}

export interface DTO_Valuation {
  Total: number | null;
  Values: DTO_Valuation_Value[];
  ItemDescription: string;
  RestartAveraging: boolean | null;
  Supplementary: DTO_Supplementary;
  LandTaxPercentage: number | null;
  Basis: string;
  Id: number | null;
  SecondaryLandUse: string;
  SubMarketGroupName: string;
  SubMarketGroup: string;
  Comment: string;
  EffectiveDate: Date;
  IssueDate: Date | null;
  ValuationDate: Date | null;
  RatingPeriod: string;
  PrimaryLandUse: string;
}

export interface DTO_Supplementary {
  Id: number | null;
  Name: string;
  IdAndName: string;
  Date: Date | null;
  Reference: string;
  Notes: string;
}
export interface DTO_Valuation_Value {
  Name: string;
  Code: string;
  FieldName: string;
  Value: number;
  Value_Formatted: string;
  IsCurrency: boolean | null;
  DecimalPlaces: number | null;
  DisplayOrder: number | null;
}

export interface DTO_Assessment_FinancialAreaBalance {
  FinancialArea: string;
  IsOnArrangements: boolean;
  PendingDiscount: number | null;
  PostponedInterest: number | null;
  PostponedBalance: number | null;
  ArrangementBalance: number | null;
  Total: number;
  InterestBalance: number | null;
  Balance: number | null;
  Deferred: number | null;
  IsOnInstalments: boolean;
  Pending: number | null;
  OverdueDue: number | null;
  Overdue: number | null;
  Due: number;
  Overpayment: number | null;
  OriginalPostponedAmount: number | null;
  OriginalAmount: number | null;
  Postponed: number | null;
}

export interface DTO_Balance_OutStanding {
  DocumentID: string;
  ExternalReferenceID: string;
  DocumentType: string;
  LLSInvoiceType: string;
  ABCClassificationID: string;
  DocumentDate: Date;
  DueDate: Date;
  NotDueAmount: number;
  OverDueAmount: number;
}
