import { CRMS_KB_BASE_ROUTE } from "@app/products/crms/knowledge-base/[id]/constant";
import { CRMSFieldMapping } from "@app/products/crms/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colAlerts: IColumnFields[] = [
  {
    field: CRMSFieldMapping.Title,
    title: "Title",
    width: 150,
    locked: true,
    linkTo: (dataItem) => `${CRMS_KB_BASE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: CRMSFieldMapping.Message,
    title: "Detail",
    width: 150,
  },
  {
    field: CRMSFieldMapping.AlwaysDisplay,
    title: "Always Display",
    width: 150,
  },
  {
    field: CRMSFieldMapping.LinkArticle,
    title: "Article",
    width: 150,
  },
  {
    field: CRMSFieldMapping.AlertType,
    title: "Alert Type",
    width: 150,
  },
  {
    field: CRMSFieldMapping.StartDate,
    title: "Start Date",
    width: 150,
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: CRMSFieldMapping.EndDate,
    title: "End Date",
    width: 150,
    format: DATETIME_FORMAT.DATETIME,
  },
];
