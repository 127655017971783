import {
  WHICH_JOURNAL_FORM_STEP,
  WhichJournalFormStep,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/_index";
import {
  NEW_JOURNAL_FORM_STEP,
  NewJournalFormStep,
} from "@app/products/property/journals/components/form-elements/new-journal/_index";
import { IAccountsTransaction } from "@app/products/property/registers/[id]/components/dialogs/form-steps/raise-charge/components/form-elements/accounts/model";
import {
  CHARGE_FORM_STEP,
  ChargeFormStep,
} from "@app/products/property/registers/[id]/components/dialogs/form-steps/raise-charge/components/form-elements/charge/_index";
import { mockCharge } from "@app/products/property/registers/[id]/components/dialogs/form-steps/raise-charge/components/form-elements/charge/mock";
import {
  defaultNewJournal,
  defaultWhichJournal,
} from "@app/products/property/registers/[id]/components/dialogs/form-steps/raise-charge/config";
import { mockNewJournal } from "@app/products/property/registers/[id]/components/dialogs/form-steps/raise-charge/mock";
import { INewJournal } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/model";
import {
  ACCOUNTS_FORM_STEP,
  AccountsFormStep,
} from "@app/products/property/registers/list/components/action-bar/form-steps/raise-charge/components/form-elements/accounts/_index";
import { convertToAccountTransactions } from "@app/products/property/registers/list/components/action-bar/form-steps/raise-charge/components/form-elements/accounts/util";
import { isShowParkButton } from "@app/products/property/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React from "react";

interface IRaiseChargeDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
}
export const RaiseChargeDialog = observer(
  ({
    onClose,
    onSubmit,
    isIncompleteMode = false,
    isFromActionList = false,
  }: IRaiseChargeDialogProps) => {
    const defaultNewJournalStep: INewJournal = {
      ...defaultNewJournal,
      ...mockNewJournal,
    };
    const { gridSelectedRows } = useCCProductListViewStore();
    let newListAccount: IAccountsTransaction[] = [];
    gridSelectedRows.map((item) => {
      newListAccount.push(convertToAccountTransactions(item));
      return newListAccount;
    });

    const steps: IStep[] = [
      {
        label: "Journal",
        initialValues: defaultWhichJournal,
        component: WhichJournalFormStep,
        visible: true,
        key: WHICH_JOURNAL_FORM_STEP,
      },
      {
        label: "New journal",
        initialValues: defaultNewJournalStep,
        component: NewJournalFormStep,
        visible: true,
        key: NEW_JOURNAL_FORM_STEP,
        options: {
          showWhichSupplementary: false,
          showStatusField: true,
        },
      },
      {
        label: "Accounts",
        component: AccountsFormStep,
        initialValues: {
          Accounts: newListAccount,
        },
        visible: true,
        key: ACCOUNTS_FORM_STEP,
      },
      {
        label: "Charge",
        component: ChargeFormStep,
        initialValues: { ...mockCharge },
        visible: true,
        key: CHARGE_FORM_STEP,
      },
    ];

    return (
      <CCFormStep
        onSubmit={onSubmit}
        initialSteps={steps}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            maxHeight="70%"
            titleHeader="Raise Charge"
            onClose={onClose}
            bodyElement={renderProps.children}
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                {isShowParkButton(isFromActionList, isIncompleteMode) && (
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Park
                  </Button>
                )}
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Cancel
                </Button>
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                <Button
                  themeColor="primary"
                  id="cc-next-step-button"
                  disabled={renderProps.nextButton.disabled}
                  className={"cc-dialog-button"}
                  onClick={renderProps.nextButton.onClick}
                >
                  {renderProps.nextButton.label}
                </Button>
              </div>
            }
          />
        )}
      />
    );
  }
);
