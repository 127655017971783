import {
  getConditionReferenceById,
  getConditionReferenceLovs,
} from "@app/core/condition-reference/[id]/api";
import {
  CONDITION_REFERENCE_SLIDER,
  TitleConditionReferenceManagePage,
} from "@app/core/condition-reference/[id]/constant";
import { ExistManageConditionReference } from "@app/core/condition-reference/[id]/existed/_index";
import {
  Svc_ConditionReference_Lovs,
  Svc_Form_ConditionReference,
} from "@app/core/condition-reference/[id]/model";
import { NewManageConditionReference } from "@app/core/condition-reference/[id]/new/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { IIdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { routeDataStoreInstance } from "@common/stores/route/store";
import {
  CCCheckPermissionWrapper,
  TPermissionCheck,
} from "@components/cc-check-permission-wrapper/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageConditionReference = () => {
  //#region STORE ========/
  const history = useHistory();
  const { setInitialDataForms, isLoadingForm } = useFlexibleFormStore();

  const { state } = useLocation<{
    parent?: {
      productType: PRODUCT_TYPE_NUMBER;
    };
  }>();
  //#endregion STORE =====/

  //#region STATE ========/
  const [hasPermission, setHasPermission] = React.useState<boolean>(false);
  //#endregion STATE =====/

  //#region HOOK ========/
  const { cancelToken } = useCancelRequest();
  const isNew = useIsNew();
  const { pushNotificationPortal, clearNotifications } =
    useNotificationPortalStore();
  //#endregion HOOK =====/

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  const conditionReferenceId = useMemo(() => {
    return isNew ? "0" : id;
  }, [isNew, id]);

  let productType: PRODUCT_TYPE_NUMBER | null;
  //#endregion Variable =====/

  useEffectOnce(() => {
    return () => {
      clearNotifications();
    };
  });

  const manageConditionReferenceSlider = useFlexibleFetchData({
    alias: CONDITION_REFERENCE_SLIDER,
    slides: [
      {
        fetch: () => {
          return getConditionReferenceById(
            +conditionReferenceId,
            cancelToken()
          );
        },
        handleError: ({ errorFromApi }) => {
          pushNotificationPortal({
            title: "The condition reference could not be loaded.",
            type: "error",
            description: errorFromApi.error,
            autoClose: false,
          });
        },
        extractParamForNextAPI: ({ dataFromApi }) => {
          const response: APIResponse<
            IIdentityPacket<Svc_Form_ConditionReference>
          > = dataFromApi;
          return response?.data?.ReturnObj;
        },
      },
      {
        fetch: ({ dataFromExtractParam }) => {
          const formConditionReference: Svc_Form_ConditionReference =
            dataFromExtractParam;

          if (isNew) {
            productType = state?.parent?.productType ?? null;
          } else {
            productType =
              formConditionReference?.ConditionReference?.ProductType_ENUM;
          }
          return getConditionReferenceLovs(
            productType ?? PRODUCT_TYPE_NUMBER.SystemInitialise,
            cancelToken()
          );
        },
        handleSuccess: ({ dataFromApi, dataFromExtractParam }) => {
          const conditionReferenceFormData: Svc_Form_ConditionReference =
            dataFromExtractParam;
          const response: APIResponse<
            IIdentityPacket<Svc_ConditionReference_Lovs>
          > = dataFromApi;
          const conditionReferenceLovs = response?.data?.ReturnObj;

          setInitialDataForms({
            GeneralForm: {
              ...conditionReferenceFormData,
            },
            GeneralFormLovs: conditionReferenceLovs,
            ParentSection: {
              productType,
            },
          });
        },
      },
    ],
  });

  useEffect(() => {
    if (isNew && !hasPermission) return;
    manageConditionReferenceSlider.fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, hasPermission]);

  const checkPermissionForPermit: TPermissionCheck = {
    formIdentifier: FormIdentifier.Core_Form_ConditionReference,
    productType: PRODUCT_TYPE_NUMBER.Core,
    formAction: FormAction.CORE_ALLOW_NEW,
  };

  return (
    <>
      <Loading
        isLoading={
          manageConditionReferenceSlider.isFetching ||
          routeDataStoreInstance.isLoadingPage ||
          isLoadingForm
        }
        isFullScreen
      />
      <FormNavigation title={TitleConditionReferenceManagePage} />
      {manageConditionReferenceSlider.errorComponent ? (
        manageConditionReferenceSlider.errorComponent
      ) : isNew ? (
        <CCCheckPermissionWrapper
          permission={checkPermissionForPermit}
          onChangeHasPermission={() => setHasPermission(true)}
        >
          <NewManageConditionReference />
        </CCCheckPermissionWrapper>
      ) : (
        <ExistManageConditionReference />
      )}
    </>
  );
};

export default observer(ManageConditionReference);
