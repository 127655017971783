import { ComplianceStatus } from "@app/products/property/assessments/compliance/component/dialog/create-request-status/model";
import { CreateRequestDialog } from "@app/products/property/assessments/compliance/component/dialog/create-request-status/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IInitiateReviewButtonProps {
  buttonName?: string;
}
export const InitiateReviewButton = observer(
  ({ buttonName = "Initiate Review" }: IInitiateReviewButtonProps) => {
    const [showInitiateReviewDialog, setShowInitiateReviewDialog] =
      useState(false);
    const { isEmptyGridSelectedRow } = useCCProductListViewStore();

    return (
      <>
        <CCNavButton
          title={buttonName}
          onClick={() => setShowInitiateReviewDialog(true)}
          disabled={isEmptyGridSelectedRow}
        />
        {showInitiateReviewDialog && (
          <CreateRequestDialog
            onClose={() => {
              setShowInitiateReviewDialog(false);
            }}
            requestStatus={ComplianceStatus.Under_Review}
          />
        )}
      </>
    );
  }
);
