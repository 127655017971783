import { FeesFormElement } from "@app/core/fees/[id]/components/child-screens/general/components/form-element/_index";
import { Fee } from "@app/core/fees/[id]/model";
import { useFeesStore } from "@app/core/fees/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef } from "react";

export const FeesForm = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const isNew = useIsNew();
  const { setOnSubmit, financeFee, isFormModified, saveFinanceFee } =
    useFeesStore();

  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = (submitEvent: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = submitEvent;
    const actionSubmit = submitEvent.event?.currentTarget
      .name as ActionSubmitActions;

    if (!(actionSubmit in ActionSubmitActions)) return;

    if (!isValid) return;
    if (
      !isModified &&
      [ActionSubmitActions.Save].includes(actionSubmit) &&
      !isFormModified
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    saveFinanceFee(values as Fee, isNew);
  };

  const initialValues = useMemo(() => {
    return financeFee?.FinanceFee;
  }, [financeFee]);

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, financeFee]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={<FeesFormElement formRenderProps={formRenderProps} />}
            />
          );
        }}
      />
    </div>
  );
});
