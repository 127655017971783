import { DIRECT_DEBIT_BANK_ACCOUNTS } from "@app/products/direct-debit/constant";
import {
  getDirectDebitBankAccountById,
  postSaveDirectDebitBankAccount,
} from "@app/products/direct-debit/system-admin/bank-accounts/[id]/api";
import { checkIsInactiveDirectDebitBankAccount } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/util";
import { DirectDebitBankAccount } from "@app/products/direct-debit/system-admin/model";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { IdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { trim } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

export const SaveButtonDirectDebitBankAccount = observer(
  ({ disabled }: { disabled?: boolean }) => {
    //#region STORE ========/
    const {
      submitFormGetData,
      isLoadingForm,
      setIsLoadingForm,
      setInitialDataForms,
    } = useFlexibleFormStore();
    //#endregion STORE =====/

    //#region HOOK ========/
    const { pushNotificationPortal } = useNotificationPortalStore();
    const isNew = useIsNew();
    const history = useHistory();
    const { cancelToken } = useCancelRequest();
    //#endregion HOOK =====/

    const saveDDBankAccountSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            let directDebitBankAccount: DirectDebitBankAccount = initialData;

            // Ignore a value with only whitespace
            // Reason: Create session will check these values (security name & no)
            const securityName =
              directDebitBankAccount.Direct_Debit_Security_Name;
            const securityNumber =
              directDebitBankAccount.Direct_Debit_Security_No;

            if (trim(securityName).length === 0) {
              directDebitBankAccount.Direct_Debit_Security_Name = "";
            }
            if (trim(securityNumber).length === 0) {
              directDebitBankAccount.Direct_Debit_Security_No = "";
            }
            return postSaveDirectDebitBankAccount(directDebitBankAccount);
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<IdentityPacket | undefined> =
              dataFromApi;
            if (isNew) {
              if (response?.data?.ID) {
                const urlRedirect =
                  DIRECT_DEBIT_BANK_ACCOUNTS + "/" + response.data.ID;
                pushNotificationPortal({
                  title: "Direct debit bank account saved successfully.",
                  type: "success",
                  route: urlRedirect,
                  callBack() {
                    history.replace(urlRedirect);
                  },
                });
              } else {
                pushNotificationPortal({
                  title: "Direct debit bank account could not be saved.",
                  type: "error",
                  autoClose: false,
                });
              }
              return false;
            }
          },
          extractParamForNextAPI: ({ dataFromApi }) => {
            const response: APIResponse<IdentityPacket> = dataFromApi;
            return response?.data?.ID;
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "Direct debit bank account could not be saved.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },

        {
          fetch: ({ dataFromExtractParam }) => {
            const id: number = dataFromExtractParam;
            return getDirectDebitBankAccountById(id, cancelToken());
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<DirectDebitBankAccount | undefined> =
              dataFromApi;
            const ddBankAccountFormData = response?.data;
            //#region save data to store ========/
            setInitialDataForms({
              GeneralForm: ddBankAccountFormData,
            });
            //#endregion save data to store =====/
            pushNotificationPortal({
              title: "Direct debit bank account saved successfully.",
              type: "success",
            });
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "Saved direct debit bank account failed.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleClickButton = async () => {
      const directDebitBankAccount: DirectDebitBankAccount =
        await submitFormGetData("GeneralForm");
      if (directDebitBankAccount) {
        setIsLoadingForm(true);
        await saveDDBankAccountSlider.fetchApi({
          initialData: directDebitBankAccount,
        });
        setIsLoadingForm(false);
      }
    };

    return (
      <CCNavButton
        title="Save"
        onClick={handleClickButton}
        disabled={
          disabled || isLoadingForm || checkIsInactiveDirectDebitBankAccount()
        }
      />
    );
  }
);
