import { DDCreateSessionDialog } from "@app/products/direct-debit/sessions/components/dialogs/create-session/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

interface ICreateSessionButtonProps {
  isDisabled?: boolean;
}
export const CreateSessionButton = ({
  isDisabled = false,
}: ICreateSessionButtonProps) => {
  const [isShowDialog, setIsShowDialog] = useState(false);

  return (
    <>
      <CCNavButton
        title="New"
        disabled={isDisabled}
        onClick={() => setIsShowDialog(true)}
      />
      {isShowDialog ? (
        <DDCreateSessionDialog onClose={() => setIsShowDialog(false)} />
      ) : null}
    </>
  );
};
