import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import {
  ISwitchCustomeFieldProps,
  SwitchValueOptions,
} from "@app/products/animals/system-admin/settings/_id/main/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

export interface IAnimalRenderSwitchProps {
  switchList: ISwitchCustomeFieldProps[];
  optionsValue: SwitchValueOptions;
  formRenderProps: FormRenderProps;
}

export const AnimalRenderSwitch = observer(
  ({ switchList, optionsValue, formRenderProps }: IAnimalRenderSwitchProps) => {
    const { getConfigDataField } = useAnimalsSettingStore();
    const { isEditing } = useAnimalsSettingManageStore();
    const { valueGetter, onChange } = formRenderProps;
    if (!switchList.length) return null;

    return (
      <>
        {switchList.map((item: ISwitchCustomeFieldProps) => {
          if (item?.isVisible === false) return null;

          return (
            <SwitchGlobalSettings
              data={getConfigDataField(item.eSettingsField)}
              isEditing={
                !isNil(item?.isEditing)
                  ? item.isEditing && isEditing
                  : isEditing
              }
              formRenderProps={formRenderProps}
              customChecked={
                valueGetter(`${item.eSettingsField}`) === optionsValue.TRUE
              }
              customOnChange={(event: SwitchChangeEvent) => {
                onChange(`${item.eSettingsField}`, {
                  value: event?.value ? optionsValue.TRUE : optionsValue.FALSE,
                });
              }}
            />
          );
        })}
      </>
    );
  }
);
