import { DTO_COO_Type } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { useChangeOfOwnershipNoSDialogStore } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/store";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { colTypeChangeOfOwnershipList } from "./config";

const validator = (values: any) => {
  if (!values?.Type) {
    return "Please selected one type!";
  }
  return undefined;
};
const nameOfTypeLovs = nameOfFactory<DTO_COO_Type>();
export const TypeNoticeOfSaleFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? validator : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { changeOfOwnershipNoSLOVs } = useChangeOfOwnershipNoSDialogStore();

    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const typeValue = getFieldValue("Type");

    const typeList = useMemo(() => {
      return changeOfOwnershipNoSLOVs?.ChangeOfOwnershipTypes ?? [];
    }, [changeOfOwnershipNoSLOVs]);

    const selectedType = useMemo(() => {
      return (
        typeList?.filter(
          (type: DTO_COO_Type) => type.Change_of_Ownership_Type === typeValue
        ) ?? []
      );
    }, [typeValue, typeList]);

    const handleGridSelectionChange = (dataItem: DTO_COO_Type[]) => {
      const newSelected = dataItem?.[0];
      onChange(nameOf("Type"), {
        value: newSelected?.Change_of_Ownership_Type ?? null,
      });
      onChange(nameOf("_option.Type"), {
        value: newSelected ?? {},
      });
    };

    return (
      <section className="cc-field-group cc-change-of-ownership-type-step">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              data={typeList}
              selectedRows={selectedType}
              columnFields={colTypeChangeOfOwnershipList}
              primaryField={nameOfTypeLovs("Change_of_Ownership_Type")}
              readOnly={options?.isReadOnly}
              selectableMode="single"
              isLimitDetailWidth
              onSelectionChange={(dataItem) => {
                handleGridSelectionChange(dataItem);
              }}
            />
          </div>
        </div>
      </section>
    );
  }
);
