import { annualLandStockReturnsRoute } from "@app/products/property/annual-land-stock-returns/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { propertyRoute } from "@app/products/property/route";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { NoData } from "@components/no-data/NoData";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(() => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown
        category={annualLandStockReturnsRoute.path}
      />,
    ],
    centerComponents: [],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });
  return <NoData />;
});
