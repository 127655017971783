import {
  apiCoreCheckIsSuperAdmin,
  apiCoreRegisteredAddress,
} from "@app/core/components/common/utils";
import {} from "@app/products/hm/model";
import {
  PremiseHandlerRequest,
  Premises,
  PremisesHandlerResponse,
  PremisesRegisterLovs,
  Svc_Premises,
} from "@app/products/hm/premises/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import {
  Address_BuildAddress,
  SetupAddress,
} from "@common/input-pickers/address/model";
import { IdentityPacket, IIdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

// #region HEALTH MANAGER
// #region HM - Premise - LOVs
export const getHMPremiseRegisterLOVs = async (
  cancelToken?: CancelToken
): Promise<APIResponse<IIdentityPacket<PremisesRegisterLovs>> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<PremisesRegisterLovs>
    >("api/hm/internal/premises/lovs", { cancelToken });
  } catch (error) {
    return handleCatchResponse(error);
  }
};
// #endRegion HM - Premise - LOVs

// #region HM - Premise - Get Premise By ID
export const getHMPremiseById = async (
  premiseId: number,
  cancelToken?: CancelToken
): Promise<APIResponse<IIdentityPacket<Svc_Premises>> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<IIdentityPacket<Svc_Premises>>(
      `api/hm/internal/premises/${premiseId}`,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
// #endRegion HM - Premise - Get Premise By ID

// #region HM - Premise - Event Handler
export const postHMPremisesEventHandler = async (
  options: PremiseHandlerRequest,
  cancelToken?: CancelToken
): Promise<
  APIResponse<IIdentityPacket<PremisesHandlerResponse>> | undefined
> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<PremisesHandlerResponse>
    >(`api/hm/internal/premise/form-handler`, options, { cancelToken });
  } catch (error) {
    return handleCatchResponse(error);
  }
};
// #endRegion HM - Premise - Event Handler

export const setupPremisesPickSiteAddress = async (
  setupAddress: SetupAddress
): Promise<APIResponse<IIdentityPacket<Address_BuildAddress> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<Address_BuildAddress>
    >(apiCoreRegisteredAddress(), setupAddress);
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postSavePremisesForm = async (
  premises: Premises,
  cancelToken?: CancelToken
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<PremisesHandlerResponse>
    >(`api/hm/internal/premises`, premises, { cancelToken });
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const checkIsSuperAdmin = async (
  userID: number,
  cancelToken: CancelToken
): Promise<APIResponse<boolean> | APIResponseError> => {
  try {
    return await CoreAPIService.getClient().post<APIResponse<boolean>>(
      apiCoreCheckIsSuperAdmin(userID),
      {
        cancelToken,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

// #endRegion HEALTH MANAGER
