import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IGroup as IGroups } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/groups-grid/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IGroups>();
export const colGroups: IColumnFields[] = [
  {
    field: nameOf("Hierarchy"),
    title: "Hierarchy",
  },
  {
    field: nameOf("OrgLevel"),
    title: "Org Level",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("OrgUnit_Name"),
    title: "Org Unit Name",
  },
  {
    field: nameOf("Manager"),
    title: "Manager",
  },
];
