import { CRMS_KB_BASE_ROUTE } from "@app/products/crms/knowledge-base/[id]/constant";
import { CRMSFieldMapping } from "@app/products/crms/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colNoServiceStandard: IColumnFields[] = [
  {
    field: CRMSFieldMapping.Title,
    title: "Title",
    width: 150,
    locked: true,
    linkTo: (dataItem) => `${CRMS_KB_BASE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: CRMSFieldMapping.Contact,
    title: "Contact",
    width: 150,
  },
  {
    field: CRMSFieldMapping.PhoneNo,
    title: "Phone",
    width: 150,
  },
  {
    field: CRMSFieldMapping.MobileNo,
    title: "Mobile",
    width: 150,
  },
  { field: CRMSFieldMapping.FaxNo, title: "Fax", width: 150, dataType: "text" },
  {
    field: CRMSFieldMapping.Manager,
    title: "Manager",
    width: 150,
  },
  {
    field: CRMSFieldMapping.Reviewer,
    title: "Reviewer",
    width: 150,
  },
  {
    field: CRMSFieldMapping.DateRevoked,
    title: "Complied / Revoked",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRMSFieldMapping.NextReviewDate,
    title: "Next Review",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRMSFieldMapping.Released,
    title: "Released",
    width: 150,
  },
  {
    field: CRMSFieldMapping.External,
    title: "External",
    width: 150,
  },
];
