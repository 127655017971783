import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { ICustomEditCell } from "@components/cc-grid/model";
import React from "react";

export const CurrencyInputCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
}: ICustomEditCell) => {
  return (
    <CCCurrencyInput
      value={value}
      onChange={(event) => {
        if (onChange) {
          onChange({
            field: field,
            dataIndex: dataIndex || 0,
            dataItem,
            syntheticEvent: event.syntheticEvent,
            value: event.value,
          });
        }
      }}
    />
  );
};
