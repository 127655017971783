import { usePowerBIReportStore } from "@common/pages/report/powerbi/[id]/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useWindowSize } from "react-use";
import "./_index.scss";

const MAX_SCREEN_WIDTH = 1180;

export const ExistManagePowerBIReport = observer(() => {
  const {
    isLoading,
    report,
    tokenEmbed,
    responseLoadError,
    reloadReportWithoutState,
  } = usePowerBIReportStore();
  const { width } = useWindowSize();
  const params: { id: string } = useParams();
  const reportId = params.id;
  const layoutTypeStatus = useMemo(() => {
    if (width <= MAX_SCREEN_WIDTH) {
      return models.LayoutType.MobilePortrait;
    }
    return models.LayoutType.Master;
  }, [width]);

  const [resetEmbedReport, setResetEmbedReport] = useState<boolean>(false);

  useEffect(() => {
    setResetEmbedReport(true);
    setTimeout(() => {
      setResetEmbedReport(false);
    }, 1000);
  }, [layoutTypeStatus]);

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation
        title={"Report " + report?.name.replace(/([A-Z])/g, " $1")}
      />
      {responseLoadError && report ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => reloadReportWithoutState(reportId)}
        />
      ) : (
        <>
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              {!resetEmbedReport && (
                <PowerBIEmbed
                  cssClassName={"cc-report-class"}
                  embedConfig={{
                    type: "report",
                    id: report?.id,
                    embedUrl: report?.embedUrl,
                    accessToken: tokenEmbed,
                    tokenType: models.TokenType.Embed,
                    settings: {
                      layoutType: layoutTypeStatus,
                      panes: {
                        filters: {
                          expanded: false,
                          visible: false,
                        },
                      },
                      background: models.BackgroundType.Transparent,
                    },
                  }}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
});
