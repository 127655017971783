import { postSendCommunication } from "@app/core/communication/buttons-list-view/components/buttons/api";
import { CommunicationDialog } from "@app/core/communication/dialogs/_index";
import { recipientTypesCommunicationDialog } from "@app/core/communication/dialogs/config";
import { ICommunication } from "@app/core/communication/dialogs/model";
import { DOCUMENTSTATUS } from "@app/core/documents/model";
import { UITownPlanning } from "@app/products/town-planning/model";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putSendReportToPanelHearing } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/send-report-to-panel-hearing/api";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const SendReportToPanelHearingButton = observer(() => {
  const { clearNotifications, pushNotification } = useCCAppNotificationStore();
  const { ppr, onSubmit, pprId, setPPRWithLoading } = usePSARStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const isVisible = useMemo(() => {
    return (
      ppr?.Status_ENUM === Application_Status.PanelReportApprovedByTeamLeader
    );
  }, [ppr]);

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.SendReportToPanelHearing.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const handleCommunicationSubmit = async (data: ICommunication) => {
    clearNotifications();
    setIsLoading(true);
    const response = await postSendCommunication(
      data,
      RECORDTYPE.TP_PSA_Application
    );
    if (isSuccessIdentityPacket(response)) {
      const respSendReport = await putSendReportToPanelHearing(pprId);
      if (
        isSuccessIdentityPacket(respSendReport) &&
        respSendReport.data?.Application
      ) {
        setPPRWithLoading(respSendReport.data.Application);
        pushNotification({
          title: "Report to panel hearing sent successfully",
          type: "success",
        });
      } else {
        pushNotification({
          autoClose: false,
          title: "Send report to panel hearing failed",
          type: "error",
          description: respSendReport.data?.Errors ?? respSendReport.statusText,
        });
      }
    } else {
      pushNotification({
        autoClose: false,
        title: "Send communication failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }

    setIsShowDialog(false);
    setIsLoading(false);
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Send report to panel hearing"
        isLoading={isLoading}
        onClick={onSubmit}
        name={PSARSubmitActions.SentToPanelHearing}
      />
      {isShowDialog && (
        <CommunicationDialog
          onClose={() => setIsShowDialog(false)}
          onSubmit={handleCommunicationSubmit}
          recordID={pprId}
          recipientTypes={recipientTypesCommunicationDialog(
            RECORDTYPE.TP_PSA_Application
          )}
          productType={PRODUCT_TYPE_NUMBER.TownPlanning}
          recordType={RECORDTYPE.TP_PSA_Application}
          documentStatus={DOCUMENTSTATUS.Final}
        />
      )}
    </>
  ) : null;
});
