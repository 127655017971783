import { Attachment } from "@app/core/documents/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { ResponsePacket } from "@common/models/identityPacket";

export const importStateRoads = async (
  attachment: Attachment
): Promise<APIResponse<ResponsePacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/core/internal/stateroad/import`,
      JSON.stringify(attachment)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
