import { PROPERTY_METERS_ROUTE } from "@app/products/property/meters/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colMeterReadingToBeProcessed: IColumnFields[] = [
  {
    field: "MeterId",
    title: "Meter ID",
    locked: true,
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => {
      return `${PROPERTY_METERS_ROUTE}/` + dataItem.MeterId;
    },
  },
  {
    field: "MeterNo",
    title: "Meter Number",
  },
  {
    field: "MeterReadingStatusName",
    title: "Meter Reading Status Name",
  },

  { field: "Reading", title: "Reading", format: NUMBER_FORMAT.NUMBER2 },
  {
    field: "ReadingDateTime",
    title: "Reading Date Time",
    format: DATE_FORMAT.DATE,
  },
  { field: "Comment", title: "Comment" },
  { field: "MeterMatchingComments", title: "Meter Matching Comments" },
];
