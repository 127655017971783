import { getMultipleSelectValue } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/util";
import { BalanceOutStandingSAP } from "@app/products/property/components/grid/balance-out-standing-sap/_index";
import { colSAPCustomerNumber } from "@app/products/property/contacts-central-names/[id]/components/child-screens/general/components/further-details/config";
import { SAP } from "@app/products/property/contacts-central-names/[id]/components/child-screens/general/components/further-details/model";
import { useContactsCentralNamesStore } from "@app/products/property/contacts-central-names/[id]/store";
import { EHasCommunityGroup } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/attributes/model";
import { MODE_CONTACT } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { nameOfLov } from "@app/products/property/model";
import { isFieldVisible } from "@app/products/property/util";
import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
} from "@common/constants/common-format";
import { CommunityProperty } from "@common/stores/products/config";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const nameOf = nameOfFactory<SAP>();
export const EntitiesFurtherDetails = observer(() => {
  const { contact, attribute, lovs } = useContactsCentralNamesStore();
  const { isLLS, isActro } = CommunityProperty.getFlagOfStates();
  const formatCulturalIdentity = getMultipleSelectValue(
    contact?.Entity?.Community_Group_Ids ?? [],
    lovs?.CommunityGroup ?? [],
    nameOfLov("Code")
  )
    .map((item: any) => item["Name"])
    .join(", ");
  const listSAPNumber = useMemo(() => {
    const sapNumber = attribute?.SAP_Numbers;
    if (sapNumber?.length) {
      return sapNumber.map((item) => item.SAP_Number).toString();
    }
    return undefined;
  }, [attribute]);

  const isShowPersonFields = contact?.Entity?.Type === MODE_CONTACT.PERSON;
  return (
    <div className="cc-property-entities-attributes cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCValueField label={"Contact ID"} value={attribute?.Id} />
            <CCValueField
              label={"Applicant number"}
              value={attribute?.StandardApplicantNumber}
            />
            {(isFieldVisible(attribute?.GenderVisibility) ||
              isShowPersonFields) && (
              <CCValueField label={"Gender"} value={attribute?.Gender} />
            )}
            {isFieldVisible(attribute?.DistinctionVisibility) && (
              <CCValueField
                label={"Distinction"}
                value={attribute?.Distinction}
              />
            )}
            <CCValueField
              label={"Category"}
              value={
                getDropdownValue(
                  contact?.Entity.Category_Id ?? 0,
                  lovs?.Category ?? [],
                  "Code"
                )?.Name ?? ""
              }
            />
            {isShowPersonFields && (
              <CCValueField
                label={"Occupation"}
                value={attribute?.Occupation}
              />
            )}
            {!isLLS && isFieldVisible(attribute?.DateOfBirthVisibility) && (
              <>
                <CCValueField
                  label={"Date of birth"}
                  value={attribute?.DateOfBirth}
                  format={DATE_FORMAT.DATE}
                />
                <CCValueField
                  label={"Date of death"}
                  value={attribute?.DateOfDeath}
                  format={DATE_FORMAT.DATE}
                />
              </>
            )}
            {isShowPersonFields && (
              <>
                <CCValueField
                  label={"Identify as Aboriginal/Torres Strait Islander"}
                  value={contact?.Entity?.Aboriginal}
                />
                <CCValueField
                  label={"Identifies with a Community Group"}
                  value={contact?.Entity?.HasCommunityGroup}
                />
                {contact?.Entity?.HasCommunityGroup ===
                EHasCommunityGroup[EHasCommunityGroup.Yes] ? (
                  <CCValueField
                    label={"Community Group"}
                    value={formatCulturalIdentity}
                  />
                ) : null}
              </>
            )}
            <CCValueField
              label={"Last changed"}
              value={attribute?.ChangedOn}
              format={DATETIME_FORMAT.DATETIME}
            />
            <CCValueField label={"Changed by"} value={attribute?.ChangedBy} />
            <CCValueField
              label={"Is obsolete"}
              value={contact?.Entity?.Is_Obsolete}
              format={BOOLEAN_FORMAT.BOOLEAN}
            />
          </div>
        </div>
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            {isLLS && (
              <div className="cc-field">
                <label className="cc-label">SAP customer numbers</label>
                <CCGrid
                  data={attribute?.SAP_Numbers ?? []}
                  columnFields={colSAPCustomerNumber}
                  primaryField={nameOf("Entity_SAP_Id")}
                />
              </div>
            )}
            <div className="cc-col-span-2">
              <BalanceOutStandingSAP sapId={listSAPNumber} />
            </div>
          </div>
        </div>
        <div className="cc-field-group">
          <div className="cc-field">
            <label className="cc-label">Notes</label>
            <CCTextArea
              className="cc-form-control"
              name={"Notes"}
              rows={4}
              value={attribute?.Notes}
              placeholder="Notes"
              readOnly
            />
          </div>
          {isFieldVisible(attribute?.AlternateAddressVisibility) && (
            <div className="cc-field-group">
              <div className="cc-field">
                <label className="cc-label">Alternate address</label>
                <CCTextArea
                  className="cc-form-control"
                  name={"PhysicalAddress"}
                  rows={4}
                  value={attribute?.PhysicalAddress}
                  placeholder="Alternate address"
                  readOnly
                />
              </div>
            </div>
          )}
        </div>
        {!isActro && !isLLS ? (
          <div className="cc-field-group">
            <label className="cc-label">Electoral commission</label>
            <div className="cc-custom-sub-panel-bar">
              <div className="cc-field-group">
                <div className="cc-form-cols-3">
                  <CCValueField
                    label={"Person number"}
                    value={attribute?.ElectoralCommissionPersonNumber}
                  />
                  <CCValueField
                    label={"Update date"}
                    value={attribute?.ElectoralCommissionUpdateDate}
                    format={DATE_FORMAT.DATE}
                  />
                  <CCValueField
                    label={"Voter registration date"}
                    value={attribute?.VoterRegistrationDate}
                    format={DATE_FORMAT.DATE}
                  />
                  <CCValueField
                    label={"Silent enrolment"}
                    value={attribute?.IsSilentEnrolment}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
});
