import React from 'react';

interface IProps {}

export const ServiceStandardTab= (props: IProps) => {
  return (
    <div className={'pt-4'}>
      <b>This is Service Standard Tab !</b>
    </div>
  );
};
