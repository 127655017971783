import { AcknowledgementTab } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/components/form-element/components/tabs/acknowledgement/_index";
import { AssetTab } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/components/form-element/components/tabs/asset/_index";
import { EscalationTab } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/components/form-element/components/tabs/escalation/_index";
import { EventTab } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/components/form-element/components/tabs/event/_index";
import { GeneralTab } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/components/form-element/components/tabs/general/_index";
import { MailMessagesTab } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/components/form-element/components/tabs/mail-message/_index";
import { NumberingTab } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/components/form-element/components/tabs/numbering/_index";
import { PhoneMessagesTab } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/components/form-element/components/tabs/phone-message/_index";
import { SecurityTab } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/components/form-element/components/tabs/security/_index";
import { ServiceStandardTab } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/components/form-element/components/tabs/service-standard/_index";
import { ViewTab } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/components/form-element/components/tabs/view/_index";
import { Toast } from "@components/toast";
import { FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { default as React } from "react";
export interface ICRMSFormElementProps {
  formRenderProps: FormRenderProps;
  activeItem: string;
}

export const CRMSSystemSettingFormElement = ({
  formRenderProps,
  activeItem,
}: ICRMSFormElementProps) => {
  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }
    if (!formRenderProps.valid) return;
    Toast.displayToast({
      content: "🚀 The form is not modified !",
      type: "warning",
    });
  };

  return (
    <>
      <FormElement onSubmit={formRenderProps.onSubmit}>
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        {activeItem.includes("GENERAL") && (
          <div className={"col-lg-12"}>
            <GeneralTab formRenderProps={formRenderProps} />
          </div>
        )}
        {activeItem.includes("EVENT") && <EventTab />}
        {activeItem.includes("NUMBERING") && <NumberingTab />}
        {activeItem.includes("SERVICE STANDARD") && <ServiceStandardTab />}
        {activeItem.includes("ACKNOWLEDGEMENT") && <AcknowledgementTab />}
        {activeItem.includes("ESCALATIONS") && <EscalationTab />}
        {activeItem.includes("SECURITY") && <SecurityTab />}
        {activeItem.includes("VIEWS") && <ViewTab />}
        {activeItem.includes("PHONE MESSAGES") && <PhoneMessagesTab />}
        {activeItem.includes("MAIL MESSAGING") && <MailMessagesTab />}
        {activeItem.includes("ASSETS") && <AssetTab />}
      </FormElement>
    </>
  );
};
