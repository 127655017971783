import { Journal } from "@app/core/journal/_index";
import { useLocationRegisterStore } from "@app/core/location-register/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const LocationRegisterHistoryTab = observer(() => {
  const { lastSelectedId } = useCCProductListViewStore();
  const { locationRegister } = useLocationRegisterStore();
  const { id } = useParams<{ id: string }>();
  const locationRegisterId: number = id ? parseInt(id) : lastSelectedId;
  return (
    <Journal
      id={locationRegisterId}
      triggersReload={locationRegister}
      recordType={RECORDTYPE.CORE_Register}
    />
  );
});
