import { IBuildingDemolitionFormData } from "@app/products/building/registers/demolitions/[id]/model";
import { RegisterAddressPopup } from "@app/products/property/components/register-address-popup/_index";
import { IParentSection } from "@common/pages/contacts/model";
import { nameOfFactory } from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

interface Props {
  formRenderProps: FormRenderProps;
}

export const BuildingParentSection = ({ formRenderProps }: Props) => {
  const [showAddressPopup, setShowAddressPopup] = useState(false);

  const history = useHistory();
  const nameOf = nameOfFactory<IBuildingDemolitionFormData>();
  const getValue = formRenderProps.valueGetter;

  const parentPage: IParentSection[] = [];

  return (
    <>
      {showAddressPopup && (
        <RegisterAddressPopup onClosePopup={() => setShowAddressPopup(false)} />
      )}
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Contact
              <CCTooltip
                type="validator"
                position="right"
                content={formRenderProps.errors.Contact}
              />
            </label>
            <Field
              onClick={() => {
                history.push(`/managecontactrelationship/${"123"}`, {
                  parentPage,
                  recordType: "11000",
                });
              }}
              autoComplete="off"
              name={nameOf("Contact")}
              placeholder="Contact"
              readOnly
              component={InputPicker}
              validator={validateRequired}
            />
          </div>
          <CCValueField
            label="Address"
            value={getValue(nameOf("ContactAddress"))}
          />
          <CCValueField
            label="Contact"
            value={getValue(nameOf("ContactContact"))}
          />
        </div>
      </div>
      <hr className="cc-divider" />
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Site address
              <CCTooltip
                type="validator"
                position="right"
                content={formRenderProps.errors.Address}
              />
            </label>

            <Field
              onClick={() => setShowAddressPopup(true)}
              name={nameOf("Address")}
              validator={validateRequired}
              component={InputPicker}
              placeholder={"Site address"}
              readOnly
              className="disabled"
            />
          </div>
          <CCValueField label="Owner" value={getValue(nameOf("Owners"))} />
          <CCValueField label="Details" value={getValue(nameOf("Details"))} />
        </div>
      </div>
      <hr className="cc-divider" />
    </>
  );
};
