export const mockSchemes = [
  { Name: "Como St, Berrys Rd, Ferres Rd and Part off", ID: 1 },
  { Name: "Cornish Road Special Charge Scheme", ID: 2 },
  { Name: "Devon\\Belqrave Ave Special Charge Scheme", ID: 3 },
  { Name: "Drainage", ID: 4 },
  { Name: "George and Mary Street Bunyip Special", ID: 5 },
  { Name: "Guest Road Special Charge Scheme", ID: 6 },
  { Name: "Henry Rd Special Charge Scheme Sewerage", ID: 7 },
  { Name: "Island Road KWR Special Charge Scheme", ID: 8 },
  { Name: "Jefferson Road Special Charge Scheme", ID: 9 },
  { Name: "Majestic Dr Special Charge Scheme", ID: 10 },
  { Name: "McBride Road Special Charge Scheme", ID: 11 },
  { Name: "Mill St Special Charge Scheme", ID: 12 },
  { Name: "Miscellaneous/Conversion Schemes", ID: 13 },
  { Name: "Moody Street Koo Wee Rup Special Charge ", ID: 14 },
  { Name: "New Scheme", ID: 15 },
  { Name: "Old Beaconsfield Road Special Charge Scheme", ID: 16 },
  { Name: "Old Menzies Creek Road Special Charge Scheme", ID: 17 },
  { Name: "Pakenham Industrial Estate Special Charge Scheme", ID: 18 },
  { Name: "Paton Road Special Charge Scheme", ID: 19 },
  { Name: "Peet Street Industrial Area Special Charge Scheme", ID: 20 },
  { Name: "Ryan Road Special Charge Scheme", ID: 21 },
  { Name: "Wadsley Avenue Special Charge Scheme", ID: 22 },
  { Name: "Wattletree Road Special Charge Scheme", ID: 23 },
  { Name: "Westlands Rd Special Charge Scheme", ID: 24 },
  { Name: "Wheeler Road Maryknoll Special Charge Scheme", ID: 25 },
];
