import { IAssessmentJournalTransactionStepsLOVs } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NewAssessmentJournalStepStore {
  private _newAssessmentJournalStepLOVs?: IAssessmentJournalTransactionStepsLOVs =
    undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get newAssessmentJournalStepLOVs() {
    return this._newAssessmentJournalStepLOVs;
  }
  setNewAssessmentJournalStepLOVs = (
    newAssessmentJournalStepLOVs?: IAssessmentJournalTransactionStepsLOVs
  ) => {
    runInAction(() => {
      this._newAssessmentJournalStepLOVs = newAssessmentJournalStepLOVs;
    });
  };
}

const newAssessmentJournalStepStoreContext = createContext(
  new NewAssessmentJournalStepStore()
);
export const useNewAssessmentJournalStepStore = () => {
  return useContext(newAssessmentJournalStepStoreContext);
};
