export interface IAssociate {
  MeterId?: number | null;
  MeterNumber?: string | null;
  PropertyAddress?: string | null;
  AccountNames?: string | null;
  DateOfAttachment?: Date | null;
  UsageEntitlement?: string | null;
  BillingGroup?: string | null;
  BillingGroupList?: string[] | null;
  AssessmentNumber?: number | null;
  PropertyAddressAccountBeingAttached?: string | null;
  AccountNamesAccountBeingAttached?: string | null;
  SearchType?: string | null;
}

export enum SearchType {
  AssessmentNumber = "Assessment Number",
  AssessmentId = "Assessment Id",
}
export enum SearchField {
  AssessmentNumber = "_searchByAssessmentNumber",
  AssessmentId = "_searchByAssessmentId",
}

export const textFieldMapping: {
  [key: string]: string;
} = {
  [SearchType.AssessmentNumber]: SearchField.AssessmentNumber,
  [SearchType.AssessmentId]: SearchField.AssessmentId,
};
