import { ContactManager_View_VIPContact } from "@app/core/contacts/vip/list/model";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (
  selectedRow: ContactManager_View_VIPContact
) => {
  const dynamicDisplayName = selectedRow.Id ? `- ${selectedRow.Id}` : "";
  return `Contact - VIP ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (
  selectedRow: ContactManager_View_VIPContact
) => {
  return getDisplayTextWithDashes([
    selectedRow.Classification,
    selectedRow.DisplayName,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return "Contact - VIP";
};

export const getBookmarkListViewDetail = () => {
  return "Contact - VIP - List";
};
