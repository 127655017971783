import { loadViewConfiguresColumns } from "@app/products/property/api";
import { INVALID_WORKFLOW_DRAFT_ID } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { accountValidator } from "@app/products/property/deferred-duty/[id]/components/forms/existed/components/form-steps/transfer-transaction/components/form-elements/to-account/util";
import { AddDeferredDutyDialog } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/components/add-deferred-duty-account/_index";
import { DTO_DeferredDuty_Account } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/components/add-deferred-duty-account/model";
import { colJournalDeferredDuty } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/config";
import { DTO_Assessment } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { ViewConfiguration } from "@app/products/property/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const ToAccountDDTransferFormStep = (props: IFormStepElement) => {
  const {
    options: { isReadOnly = false },
  } = props;
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!isReadOnly ? accountValidator : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    setIsLoadingStep,
    localNotificationRef,
    options = {
      isReadOnly: false,
      workflowDraftId: INVALID_WORKFLOW_DRAFT_ID,
    },
  }: IFormStepElement) => {
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { valueGetter, onChange, errors } = formRenderProps;
    const currentAccountData = getFieldValue("Accounts") ?? [];
    //state
    const [showAddDeferredDuty, setShowAddDeferredDuty] = useState(false);
    const [isShowConfirmDeletion, setIsShowConfirmDeletion] =
      useState<boolean>(false);
    const [processColumn, setProcessColumn] = useState(colJournalDeferredDuty);
    /**
     * handle Add deferred duty
     * @param deferredDuty
     */
    const handleAddDeferredDuty = (
      deferredDuty: DTO_DeferredDuty_Account[]
    ) => {
      const newItem = deferredDuty[0];
      setShowAddDeferredDuty(false);
      onChange(nameOf("Accounts"), { value: [newItem] });
    };

    const loadConfigurationColumn = async () => {
      if (setIsLoadingStep) {
        setIsLoadingStep(true);
        const response = await loadViewConfiguresColumns(
          ViewConfiguration.DeferredDuty_AccountLookup,
          colJournalDeferredDuty
        );
        if (Array.isArray(response)) {
          setProcessColumn(response);
        } else {
          localNotificationRef?.current?.pushNotification({
            type: "error",
            autoClose: true,
            title: response.error,
          });
        }
        setIsLoadingStep(false);
      }
    };

    /**
     * handle Delete deferred duty
     */
    const handleDelete = () => {
      onChange(nameOf("Accounts"), { value: [] });
    };

    useEffectOnce(() => {
      loadConfigurationColumn();
    });
    return (
      <>
        <section className="cc-field-group">
          <label className="cc-label">
            Deferred duty account
            <CCTooltip type="validator" position="right" />
          </label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              <CCGrid
                data={currentAccountData}
                columnFields={processColumn}
                primaryField={"AccountId"}
                readOnly={options?.isReadOnly}
                onDataChange={(dataItem: DTO_Assessment[]) => {
                  onChange(nameOf("Accounts"), { value: dataItem });
                }}
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      className="cc-edit-field-button"
                      iconClass="fa fa-plus"
                      title="Add deferred duty"
                      disabled={
                        options?.isReadOnly || isNil(options?.workflowDraftId)
                      }
                      onClick={() => {
                        setShowAddDeferredDuty(true);
                      }}
                    />
                    <Button
                      iconClass="fas fa-minus"
                      title="Delete deferred duty"
                      disabled={
                        options?.isReadOnly || currentAccountData?.length !== 1
                      }
                      onClick={() => {
                        setIsShowConfirmDeletion(true);
                      }}
                    />
                  </div>
                }
              />
            </div>
          </div>
          {errors?.AccountTo?.length ? (
            <Error>{errors?.AccountTo}</Error>
          ) : null}
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Transaction date</label>
              <Field
                name={nameOf("TransactionDate")}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
                disabled={options?.isReadOnly}
                component={CCDateTimePicker}
              />
            </div>
          </div>
        </section>
        {isShowConfirmDeletion && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={
              "Are you sure you wish to delete the selected account from this deferred duty?"
            }
            onClosePopup={() => {
              setIsShowConfirmDeletion(false);
            }}
            onConfirmYes={handleDelete}
          />
        )}
        {showAddDeferredDuty && (
          <AddDeferredDutyDialog
            onClose={() => {
              setShowAddDeferredDuty(false);
            }}
            handleAddAccount={handleAddDeferredDuty}
            defaultMessage="No account have been selected that are not already associated with the journal."
          />
        )}
      </>
    );
  }
);
