import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { ResponseMessageCheckPermission } from "@common/pages/settings/model";

export const getCheckPermission = async (
  formAction: FormActionCheckPermission,
  productType: number
): Promise<APIResponse<ResponseMessageCheckPermission>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/core/internal/security/checkpermission?formAction=${formAction}&productType=${productType}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
