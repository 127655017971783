import { ResponseMessage } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postIssueDocument = async (
  documentId: number | string
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/document/${documentId}/issue`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
