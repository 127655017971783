import { NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colFields: IColumnFields[] = [
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "LegalDescription",
    title: "Legal Description",
  },
  {
    field: "PropertyAddress",
    title: "Propery Address",
  },
  {
    field: "LegalDescription",
    title: "Legal Description",
  },
  {
    field: "Owners",
    title: "Owners",
  },
  {
    field: "Reason",
    title: "Reason",
  },
  {
    field: "AssessmentId",
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
