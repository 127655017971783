import {
  ATTACHMENT_STEP,
  AttachmentsStep,
} from "@app/core/communication/dialogs/components/form-elememts/attachment/_index";
import {
  MESSAGE_TEMPLATE_STEP,
  MessageTemplateStep,
} from "@app/core/communication/dialogs/components/form-elememts/message-template/_index";
import { decryptionHtmlForEditor } from "@app/core/communication/dialogs/components/form-elememts/message-template/components/cc-edit-html-tool/util";
import {
  MAIL_MERGE_TEMPLATE_FORM_STEP,
  TemplateFormStep,
} from "@app/core/communication/dialogs/components/form-elememts/template/_index";
import {
  COMMUNICATION_TO_STEP,
  CommunicationToStep,
} from "@app/core/communication/dialogs/components/form-elememts/to/_index";
import { useToStepStore } from "@app/core/communication/dialogs/components/form-elememts/to/store";
import {
  WORKFLOW_STEP,
  WorkflowStep,
} from "@app/core/communication/dialogs/components/form-elememts/work-flow/_index";
import {
  defaultAttachmentStep,
  defaultMessageTemplateStep,
  defaultTemplateStep,
  defaultToStep,
} from "@app/core/communication/dialogs/config";
import { ICommunication } from "@app/core/communication/dialogs/model";
import { DOCUMENTSTATUS } from "@app/core/documents/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";
import "./_index.scss";

interface ICommunicationDialogProps {
  onClose: () => void;
  onSubmit: (data: ICommunication) => void;
  productType?: PRODUCT_TYPE_NUMBER;
  recordType?: RECORDTYPE;
  recordID?: number;
  recordIDs?: number[];
  recordIdAttachment?: number;
  recordTypeAttachment?: RECORDTYPE;
  documentStatus?: DOCUMENTSTATUS;
  recipientTypes?: string | null;
}

export const CommunicationDialog = ({
  onClose,
  onSubmit,
  productType = PRODUCT_TYPE_NUMBER.TownPlanning,
  recordType = RECORDTYPE.TP_PPRApplication,
  recordTypeAttachment,
  recordIdAttachment,
  recordID,
  recordIDs,
  documentStatus,
  recipientTypes,
}: ICommunicationDialogProps) => {
  const { resetStore } = useToStepStore();
  const { settings } = useCommonCoreStore();
  const isShowWorkflow = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableWorkflow]
  );

  const steps: IStep[] = [
    {
      label: "To",
      initialValues: defaultToStep,
      component: CommunicationToStep,
      visible: true,
      key: COMMUNICATION_TO_STEP,
      options: {
        RecordId: recordID,
        RecordIds: recordIDs,
        ProductType: productType,
        RecordType: recordType,
        RecipientTypes: recipientTypes,
      },
      customClassName: "cc-communication-form-full-h",
    },
    {
      label: "Message/Template",
      initialValues: defaultMessageTemplateStep,
      component: MessageTemplateStep,
      visible: true,
      key: MESSAGE_TEMPLATE_STEP,
    },
    {
      label: "Mail merge",
      initialValues: defaultTemplateStep,
      component: TemplateFormStep,
      visible: true,
      key: MAIL_MERGE_TEMPLATE_FORM_STEP,
      options: {
        ProductType: productType,
      },
    },
    {
      label: "Attachments",
      component: AttachmentsStep,
      initialValues: defaultAttachmentStep,
      visible: true,
      key: ATTACHMENT_STEP,
      options: {
        RecordId: recordID,
        RecordIds: recordIDs,
        RecordIdAttachment: recordIdAttachment || recordID,
        RecordTypeAttachment: recordTypeAttachment || recordType,
        DocumentStatus: documentStatus,
      },
    },
    {
      label: "Workflow",
      component: WorkflowStep,
      visible: isShowWorkflow,
      key: WORKFLOW_STEP,
    },
  ];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleCommunicationSubmit = (data: any) => {
    //Update checkbox for recipient selected
    let recipientsSelected = data.CommunicationTo.SelectedToRows.map(
      (item: any) => {
        item.SendToEmail = data.CommunicationTo.UseEmail;
        item.SendToSMS = data.CommunicationTo.UseSMS;
        return item;
      }
    );

    const communication: ICommunication = Object.assign(
      data.CommunicationTo.Communication ?? {},
      {
        SelectedActionType_ID: data.Workflow?.ActionTypes?.Key ?? 0,
        Recipients: recipientsSelected,
        EmailSubject:
          data.MessageTemplate.SubjectContent.replaceAll("\r", "").replaceAll(
            "\n",
            ""
          ) ?? "",
        EmailContent:
          decryptionHtmlForEditor(data.MessageTemplate.EmailContent) ?? "",
        SMSContent: data.MessageTemplate.SMSContent ?? "",
        SendByUsingPreferredMethod: data.CommunicationTo.UsePreferred,
        SendToEmail: data.CommunicationTo.UseEmail,
        SendToSMS: data.CommunicationTo.UseSMS,
        SendCopyToMe: data.MessageTemplate.IncludeMe,
        SendCopyToMeBCC: data.MessageTemplate.SendCopyToMeBCC,
        SenderEmail: data.SendFrom?.Value,
        Records_UserDefinedParentRecordId: "",
        PreferredAuthor_DisplayName: "",
        AccessToken: "",
        ListOfAttachments: data.Attachment.ListOfAttachments,
        ListOfTemplateAttachments: data.Attachment.ListOfTemplateAttachments,
        ListOfAttachmentsFromCurrent:
          data.Attachment.ListOfAttachmentsFromCurrent,
        SelectedMailMergeDocumentID: data.Template.SelectedMailMergeDocumentID,
      }
    );
    if (onSubmit) {
      setIsLoading(true);
      onSubmit(communication);
    }
  };

  return (
    <CCFormStep
      onSubmit={handleCommunicationSubmit}
      initialSteps={steps}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="80%"
          maxHeight="85%"
          titleHeader={"Communication"}
          onClose={onClose}
          disabled={isLoading}
          bodyElement={renderProps.children}
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              <Button
                className={"cc-dialog-button"}
                onClick={() => {
                  resetStore();
                  onClose();
                }}
              >
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                disabled={renderProps.nextButton.disabled}
                className={"cc-dialog-button"}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.nextButton.label !== "Finish"
                  ? renderProps.nextButton.label
                  : "Send"}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
