import { getDeferredDutyNoticeRuns } from "@app/products/property/deferred-duty/notice-runs/[id]/api";
import { IDeferredDutyNoticeRunSummary } from "@app/products/property/deferred-duty/notice-runs/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class DeferredDutyNoticeRunsStore {
  private _deferredDutyNoticeRuns?: IDeferredDutyNoticeRunSummary = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get deferredDutyNoticeRuns() {
    return toJS(this._deferredDutyNoticeRuns);
  }

  setDeferredDutyNoticeRuns = (
    deferredDutyNoticeRuns?: IDeferredDutyNoticeRunSummary
  ) => {
    runInAction(() => {
      this._deferredDutyNoticeRuns = deferredDutyNoticeRuns;
    });
  };

  get isLoading() {
    return this._isLoading;
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get deferredDutyNoticeRunsId() {
    return toJS(this.deferredDutyNoticeRuns?.NoticeRunID);
  }
  //Action

  resetStore = () => {
    runInAction(() => {
      this._deferredDutyNoticeRuns = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadDeferredDutyNoticeRuns = async (deferredDutyNoticeRunsId: number) => {
    this.setIsLoading(true);
    const response = await getDeferredDutyNoticeRuns(deferredDutyNoticeRunsId);
    let errorResponse = undefined;
    let newDeferredDutyNoticeRuns = undefined;
    if (isSuccessResponse(response)) {
      newDeferredDutyNoticeRuns = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setDeferredDutyNoticeRuns(newDeferredDutyNoticeRuns);
    this.setIsLoading(false);
  };
}

const deferredDutyNoticeRunsStoreContext = createContext(
  new DeferredDutyNoticeRunsStore()
);
export const useDeferredDutyNoticeRunsStore = () => {
  return useContext(deferredDutyNoticeRunsStoreContext);
};
