import { DTO_DebtRecoveryExemption_LOVs } from "@app/products/property/assessments/debt-recovery/candidates/components/action-bar/dialogs/create-debt-recovery-exemption/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class CreateDebtRecoveryExemptionDialogStore {
  private _createDebtRecoveryExemptionLOVs?: DTO_DebtRecoveryExemption_LOVs =
    undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get createDebtRecoveryExemptionLOVs() {
    return this._createDebtRecoveryExemptionLOVs;
  }
  setCreateDebtRecoveryExemptionLOVs = (
    createDebtRecoveryExemptionLOVs?: DTO_DebtRecoveryExemption_LOVs
  ) => {
    runInAction(() => {
      this._createDebtRecoveryExemptionLOVs = createDebtRecoveryExemptionLOVs;
    });
  };
}

const createDebtRecoveryExemptionDialogStoreContext = createContext(
  new CreateDebtRecoveryExemptionDialogStore()
);
export const useCreateDebtRecoveryExemptionDialogStore = () => {
  return useContext(createDebtRecoveryExemptionDialogStoreContext);
};
