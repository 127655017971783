import { PSAR_PlannerDecision_Number } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/approve-decision/model";
import { PSAR_PlannerDecision } from "@app/products/town-planning/ppr/[id]/model";

export const plannerDecisionNumberMap: {
  [key in PSAR_PlannerDecision]?: PSAR_PlannerDecision_Number;
} = {
  [PSAR_PlannerDecision.Unspecified]: 0,
  [PSAR_PlannerDecision.Support_Submission]: 1,
  [PSAR_PlannerDecision.Support_Submission_Amendments_Sought]: 2,
  [PSAR_PlannerDecision.Dont_support_Submission]: 3,
};
