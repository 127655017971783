import { DTO_Transaction_Item } from "@app/products/property/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import React from "react";

const nameOf = nameOfFactory<DTO_Transaction_Item>();

export const colItemDetail: IColumnFields[] = [
  {
    field: nameOf("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell />,
  },
  {
    field: nameOf("ChargeDate"),
    title: "Charge Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ChargeName"),
    title: "Charge Name",
  },
  {
    field: nameOf("ChargeDescription"),
    title: "Charge Description",
  },
  {
    field: nameOf("InstalmentNumber"),
    title: "Instalment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("ChargeInstalmentDueDate"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("LevyName"),
    title: "Levy",
  },
  {
    field: nameOf("LevyCode"),
    title: "Code",
  },
  {
    field: nameOf("ChargeCode"),
    title: "Charge Code",
  },
  {
    field: nameOf("Id"),
    title: "Transaction ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("ChargeBalancesId"),
    title: "Charge Balances ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
