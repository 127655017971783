import {
  Application_Status,
  Application_Status_Int,
} from "@app/products/town-planning/ppr/constant";
import {
  Application_Decision,
  Application_Decision_Int,
} from "@app/products/town-planning/ppr/[id]/model";

export const convertTypeApplicationStatus = (
  statusEnum?: Application_Status
) => {
  if (!statusEnum) return;
  return Application_Status_Int[statusEnum];
};

export const convertTypeApplicationDecision = (
  decision?: Application_Decision | null
) => {
  if (!decision) return;
  return Application_Decision_Int[decision];
};
