import {
  DTO_Workflow_Amalgamate_Assessment,
  RequestAmalgamateAssessmentObj,
} from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/model";
import { DTO_Assessment_LOVs } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postProcessAmalgamateAssessment = async (
  workflowType: WorkflowProcessMode,
  amalgamateData: DTO_Workflow_Amalgamate_Assessment
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_Amalgamate_Assessment>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/AmalgamateAssessment/process/${workflowType}`,
      amalgamateData
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewWorkflowAmalgamateAssessment = async (
  requestObjSpatial: RequestAmalgamateAssessmentObj
): Promise<APIResponse<DTO_Workflow_Amalgamate_Assessment>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/AmalgamateAssessment/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowAmalgamateAssessment = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_Amalgamate_Assessment>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/AmalgamateAssessment/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataAssessment =
  | [
      APIResponse<DTO_Assessment_LOVs>,
      APIResponse<DTO_Workflow_Amalgamate_Assessment>
    ]
  | APIResponse<DTO_Assessment_LOVs | DTO_Workflow_Amalgamate_Assessment>
  | undefined;

export const getInitialDataAmalgamateAssessment = async (
  workflowType: WorkflowTypes,
  requestObjSpatial: RequestAmalgamateAssessmentObj,
  workflowDraftId?: number | undefined
): Promise<IGetInitialDataAssessment> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Assessment_LOVs>(
        `/api/property/internal/workflow/createassessment/lovs?workflowType=${workflowType}`
      ),
      !isNil(workflowDraftId)
        ? getWorkflowAmalgamateAssessment(workflowDraftId)
        : postNewWorkflowAmalgamateAssessment(requestObjSpatial),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
