//TODO: For the new main menu, we can use this name from Server
export const getDisplayCategoryName = (managePageUrl?: string) => {
  if (!managePageUrl) return "New Bookmark";
  const [, productUrl] = managePageUrl.split("/");
  if (!productUrl) return "New Bookmark";
  switch (productUrl) {
    case "property":
      return "Property";
    case "animals":
      return "Animals";
    case "ams":
      return "Asset";
    case "town-planning":
      return "Town Planning";
    case "local-laws":
      return "Local Laws";
    case "waste-water":
      return "Wastewater";
    case "actions":
      return "Actions";
    case "event-management":
      return "Event Management";
    case "building":
      return "Building";
    case "crms":
      return "Crms";
    case "csl":
      return "CSL";
    case "crs":
      return "CRS";
    case "settings":
      return "Settings";
    case "hm":
      return "Health Manager";
    case "parking-permits":
      return "Parking Permits";
    default:
      return "New Bookmark";
  }
};
