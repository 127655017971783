import { colAssociationsDebtRecoveryExemption } from "@app/products/property/components/associations/components/debt-recovery-exemption/config";
import { DTO_AssociatedItem_DebtRecoveryExemption } from "@app/products/property/components/associations/components/debt-recovery-exemption/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsDebtRecoveryExemptionProps {
  associationsDebtRecoveryExemptionInfo:
    | DTO_AssociatedItem_DebtRecoveryExemption[]
    | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_DebtRecoveryExemption>();

export const AssociationsDebtRecoveryExemption = ({
  associationsDebtRecoveryExemptionInfo,
}: IAssociationsDebtRecoveryExemptionProps) => {
  return (
    <CCGrid
      className="cc-debt-recovery-exemption-grid"
      data={associationsDebtRecoveryExemptionInfo || []}
      columnFields={colAssociationsDebtRecoveryExemption}
      primaryField={nameOf("Id")}
    />
  );
};
