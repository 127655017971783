import { IMeterScheduleSpecialReadings } from "@app/products/property/meters/list/components/action-bar/dialogs/schedule-special-readings/model";

export const mockAssessment: IMeterScheduleSpecialReadings[] = [
  {
    AssessmentNumber: 3400000.0005,
    PropertyAddress: ", ",
    AccountName: "C K SIDHU & K S GILL",
    AssessmentId: 12345,
    Meters: [
      {
        Utility: "Water Usage",
        MeterNumber: "05W888617",
        ScheduleRead: true,
        BillingGroup: "Standard Periodic Billing",
        MeterInstallationId: 4342,
      },
    ],
  },
];

export const reasonSpecialReadingData = [
  { Key: 1, Value: "Change of Ownership" },
  { Key: 2, Value: "Change of Tenant" },
  { Key: 3, Value: "New Meter" },
  { Key: 4, Value: "Re-connection" },
  { Key: 5, Value: "Request for meter read outside cycle" },
];

export const readingDeviceData = [
  { Key: 1, Value: "PDA_ALL" },
  { Key: 2, Value: "PDA1" },
  { Key: 3, Value: "PDA2" },
  { Key: 4, Value: "PDA3" },
  { Key: 5, Value: "PDA4" },
  { Key: 6, Value: "PDA5" },
  { Key: 7, Value: "Windows CE Hand Held Device" },
];
