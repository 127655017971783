import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colFields: IColumnFields[] = [
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  {
    field: "Percentage",
    title: "Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: "TransferAmount",
    title: "Transfer Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "PropertyAddress",
    title: "Propery Address",
  },
  {
    field: "LegalDescription",
    title: "Legal Description",
  },
  {
    field: "Ratepayer",
    title: "Ratepayer",
  },
  {
    field: "Reason",
    title: "Reason",
  },
  {
    field: "AssessmentId",
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const radio = [
  {
    label: "No",
    value: false,
  },
  {
    label: "Yes",
    value: true,
  },
];
