import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { DTO_C_Assessment } from "@app/products/property/assessments/compliance/[id]/components/child-screens/assessment/model";

const nameOf = nameOfFactory<DTO_C_Assessment>();
export const colComplianceAssessment: IColumnFields[] = [
  {
    field: nameOf("Assessment_SSBU"),
    title: "Assessment (S-S-B-U)",
    linkTo: (item: DTO_C_Assessment) => {
      return PROPERTY_ASSESSMENT_ROUTE + "/" + item?.Assessment_Id;
    },
  },
  {
    field: nameOf("Assessment_Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Ratepayer_Name"),
    title: "Current Ratepayer",
  },
  {
    field: nameOf("Last_Compliance_Date"),
    title: "Last Compliance Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
