import { ICCRoute } from "@common/constants/ICCRoute";
export const enquiriesRoute: ICCRoute = {
  path: "enquiries",
  name: "Enquiries",
  children: [
    {
      path: "current-by-officer",
      name: "Current By Officer",
      component: require("./current-by-officer/_index").default,
    },
    {
      path: "current-register",
      name: "Current Register",
      component: require("./current-register/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      component: require("./by-status/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      component: require("./by-type/_index").default,
    },
    {
      path: "by-team-leader",
      name: "By Delegated approver",
      component: require("./by-team-leader/_index").default,
    },
    {
      path: "by-municipality",
      name: "By Municipality",
      component: require("./by-municipality/_index").default,
    },
    {
      path: "by-location",
      name: "By Location",
      component: require("./by-location/_index").default,
    },
    {
      path: "by-due-status",
      name: "By Due Status",
      component: require("./by-due-status/_index").default,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./_id/_index").default,
    },
  ],
};
