import { CredentialsDialog } from "@common/pages/settings/security/site-users/_id/components/dialogs/credentials/_index";
import { UserCredentials } from "@common/pages/settings/security/site-users/_id/components/dialogs/credentials/model";
import {
  CCInputPicker,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React from "react";

interface ICredentialsPickerProps extends ICCInputPickerProps {
  isDomainMandatory?: boolean;
  initialValue?: UserCredentials;
}

export const CredentialsPicker = (props: ICredentialsPickerProps) => {
  const { onError, isDomainMandatory, initialValue, ...others } = props;
  return (
    <CCInputPicker
      {...others}
      customDialog={(value: UserCredentials, onClose, onSubmit) => (
        <CredentialsDialog
          onClose={onClose}
          onSubmit={onSubmit}
          isDomainMandatory={isDomainMandatory}
          initialValue={initialValue}
        />
      )}
    />
  );
};
