import { IssueActionDialog } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/dialog/issue-action/_index";
import { useDebtRecoveryStore } from "@app/products/property/assessments/debt-recovery/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const IssueActionButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { debtRecoveryActionSelectedRow } = useDebtRecoveryStore();

  return (
    <>
      <CCNavButton
        title="Issue"
        disabled={isNil(debtRecoveryActionSelectedRow)}
        onClick={() => setIsShowDialog(true)}
      />
      {isShowDialog && (
        <IssueActionDialog onCloseDialog={() => setIsShowDialog(false)} />
      )}
    </>
  );
});
