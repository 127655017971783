import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";

export interface IProvideReasonDialogData {
  Comment?: string;
}

export class ProvideReason {
  Title_Text: string = "";
  Flag_HighlightTitle: boolean = false;
  WindowTitle_Text: string = "";
  ReasonLabel_Text: string = "";
  ReasonLabel_RequiredErrorMessage: string = "";
  Flag_ShowDate: boolean = false;
  Flag_DateIsMandatory: boolean = false;
  Flag_TextIsMandatory: boolean = false;
  DateLabel_Text: string = "";
  DateLabel_RequiredErrorMessage: string = "";
  Flag_ShowKWD: boolean = false;
  Flag_KWDIsMandatory: boolean = false;
  KWDLabel_Text: string = "";
  KWDLabel_RequiredErrorMessage: string = "";
  IsReadOnly_KWD: boolean = false;
  KWDType: KEYWORD_TYPE | null = null;
  ProdType: PRODUCT_TYPE_NUMBER | null = null;
  Reason: string = "";
  DateOfAction: Date | null = null;
  Reason_KWD: number | null = null;
  Flag_ShowReasonTextBox: boolean = false;

  constructor() {
    this.ReasonLabel_Text = "Reason";
    this.ReasonLabel_RequiredErrorMessage = "Provide a Reason";

    this.Flag_ShowDate = true;
    this.Flag_DateIsMandatory = false;
    this.Flag_TextIsMandatory = false;
    this.Flag_ShowKWD = false;
    this.Flag_KWDIsMandatory = false;
    this.DateLabel_Text = "Date of Action";
    this.DateLabel_RequiredErrorMessage = "Provide Date of Action";
    this.ProdType = PRODUCT_TYPE_NUMBER.Core;
    this.Flag_ShowReasonTextBox = true;
    this.IsReadOnly_KWD = false;
  }
}

export const ReasonDialogPlaceID = "ReasonDialog";
