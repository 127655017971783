import { IListDialog } from "@app/products/property/components/action-bar/property-workflow/model";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import {
  DTO_Request_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfSteps {
  TitleDetail = "TitleDetail",
  Details = "Details",
  Comments = "Comments",
  Documents = "Documents",
  ParcelDetails = "Associate_Parcels",
  AssociatedTitle = "Associate_Titles",
  SecondaryWorkflow = "SecondaryWorkflow",
  Collection = "SelectedCollection",
}

export const keysOfSendSteps = [
  EKeysOfSteps.Details,
  EKeysOfSteps.TitleDetail,
  EKeysOfSteps.ParcelDetails,
  EKeysOfSteps.AssociatedTitle,
  EKeysOfSteps.Collection,
  EKeysOfSteps.SecondaryWorkflow,
];

export interface DTO_Workflow_ModifyTitle {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_ModifyTitle;
}

export interface DTO_WorkflowDetail_ModifyTitle {
  Details: DTO_Default_Title_Page;
  TitleDetail: DTO_Title_Detail;
  Associate_Parcels: DTO_Parcel[];
  Associate_Titles: DTO_Title[];
  //Using for Finish feature => show secondary workflow
  SecondaryWorkflowSelectedList?: IListDialog[];
}

export interface DTO_Parcel {
  AssociationFactorPercentage?: number;
  Is_Entitle?: boolean;
  OwnerName: string;
  Status: string;
  LandUse: string;
  Zones: string;
  LandArea: string;
  FederalElectoralDivision: string;
  StateCodeElectoralDivision: string;
  ElectoralDivision: string;
  CensusDistrict: string;
  LegalDescription: string;
  Locality: string;
  ParcelReference: string;
  PropertyAddress: string;
  Parcel_Id: number;
  Active_From?: Date;
  Active_To?: Date;
}

export interface DTO_Title {
  LinkedAssessments: DTO_Title_LinkedAssessment[];
  GISReference: string;
  Section: string;
  Parish: string;
  County: string;
  AssociatedPICAreaSet?: number;
  PIC_Title_Id?: number;
  AssociatedPICArea?: number;
  AssociatedPICAreaTotal?: number;
  IsAreaSurveyed: boolean;
  NameAddress: string;
  Frontage?: number;
  Active_From?: Date;
  AssociationFactorPercentage?: number;
  Coverage: string;
  StatusId?: number;
  CadId?: number;
  LLS_Property_ID?: number;
  PIC_ID: string;
  PIC: string;
  Title_Is_Part_of_Lot: boolean;
  GIS_GlobalID: string;
  isAddedToGridAfterSpatial: boolean;
  Is_Entitle?: boolean;
  Association_Type?: string;
  Child_Title_Id?: number;
  Active_To?: Date;
  LandArea?: number;
  Status: string;
  TitleId: number;
  Folio: string;
  Volume: string;
  Lot: string;
  PlanNumber: string;
  PlanType: string;
  ReferenceNumber?: number;
  LegalDescription: string;
  DivisionDate?: Date;
  RowId?: string;
}

export interface DTO_Title_LinkedAssessment {
  Assessment_Id: number;
  LinkedTitles: number[];
}

export interface DTO_Default_Title_Page {
  Reference: string;
  ApplicantName: string;
  ReasonForCreation: string;
  Status: string;
}

export interface DTO_Title_Detail {
  Land_Area?: number;
  Land_Area_Unit_Code: string;
  Excluded_Area?: number;
  Excluded_Area_Unit_Code: string;
  Is_Area_Surveyed: boolean;
  Division_Date?: Date;
  Parish_Id?: number;
  Tenure_Id?: number;
  Title_System: string;
  Frontage?: number;
  P_Number: string;
  Crown_Allotment: string;
  Crown_Status?: string;
  Further_Description: string;
  Plan_Registration_Date?: Date;
  Title_Piece: string;
  Title_Subdivision: string;
  Title_Block: string;
  Coverage_Id: number;
  Folio: string;
  Volume: string;
  Is_Part_Of_Lot: boolean;
  Title_Id: number;
  Reference_Number?: number;
  Status: number;
  Legal_Description: string;
  LGA_Id?: number;
  Plan_Type?: number;
  Plan_Number: string;
  Land_Type: string;
  Township_Id?: number;
  County_Id?: number;
  State_Code: string;
  Country_Id?: number;
  Region_Id?: number;
  DRC_Id?: number;
  Dealing_Number: string;
  Book_Number: string;
  Section: string;
  Title_Type_Id: number;
  Lot: string;
  Title_Portion: string;
  GIS_References: DTO_GIS[];
}

export interface DTO_Create_Title_LOVs {
  Tenure: DTO_LOV[];
  Parish: DTO_LOV[];
  Area_Unit: DTO_LOV[];
  Lot_Type: DTO_LOV[];
  Differential_Rate_Classification: DTO_LOV[];
  Region: DTO_LOV[];
  Country: DTO_LOV[];
  State: DTO_LOV[];
  County: DTO_LOV[];
  Township: DTO_LOV[];
  Plan_Type: DTO_LOV[];
  LGA: DTO_LOV[];
  ReasonForCreation: DTO_LOV[];
  Coverage: DTO_LOV[];
  Title_Association_Type: DTO_LOV[];
  Collection_Type: DTO_LOV[];
  Title_Status: DTO_LOV[];
  GISType: DTO_LOV[];
}

export interface RequestInputObj extends DTO_Request_Base {
  Title_Id: number;
}
