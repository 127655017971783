import { BOOLEAN_FORMAT } from "@common/constants/common-format";
import { Svc_HomepagePageUser } from "@common/pages/settings/homepage/model";
import { SITE_USER_ROUTE } from "@common/pages/settings/security/site-users/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_HomepagePageUser>();

export const colUserByShortcuts: IColumnFields[] = [
  {
    title: "Login Name",
    field: nameOf("UserName"),
    linkTo: (dataItem: Svc_HomepagePageUser) => {
      return `${SITE_USER_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    title: "First Name",
    field: nameOf("FirstName"),
  },
  { title: "Last Name", field: nameOf("LastName") },
  { title: "Email", field: nameOf("Email") },
  { title: "Security Templates", field: nameOf("SecurityTemplates") },
  // TODO: implement later
  { title: "Organisation Structure", field: "Hierarchy" },
  {
    title: "Active",
    field: nameOf("IsActive"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    title: "Locked",
    field: nameOf("IsLocked"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    title: "Homepage Function",
    field: nameOf("HomePageFunction"),
  },
];
