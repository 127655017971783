import { history } from "@/AppRoutes";
import { CSL_SYSTEM_ADMIN_CONDITIONS_ROUTE } from "@app/products/csl/system-admin/conditions/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";

export const NewCSLConditionsButton = () => {
  return (
    <>
      <CCNavButton
        title="New CSL Conditions"
        onClick={() => history.push(`${CSL_SYSTEM_ADMIN_CONDITIONS_ROUTE}/new`)}
      />
    </>
  );
};
