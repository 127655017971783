import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface IRecordsObjective {
  formRenderProps: FormRenderProps;
}

export const RecordsObjective = observer(
  ({ formRenderProps }: IRecordsObjective) => {
    const { isEditing } = useSettingGlobalManageStore();
    const { getConfigDataField } = useSettingGlobalStore();

    const fieldObjectiveAttachmentTitleFormat = getConfigDataField(
      ECorporateSettingsField.Objective_AttachmentTitleFormat
    );
    const fieldObjectiveAuthentication = getConfigDataField(
      ECorporateSettingsField.Objective_Authentication
    );
    const fieldObjectiveCredentials = getConfigDataField(
      ECorporateSettingsField.Objective_Credentials
    );
    const fieldObjectiveWebServiceBaseURL = getConfigDataField(
      ECorporateSettingsField.Objective_WebServiceBaseURL
    );
    const fieldObjectiveWorkingDirectory = getConfigDataField(
      ECorporateSettingsField.Objective_WorkingDirectory
    );
    const fieldObjectiveIgnoreInvalidSSLCertificates = getConfigDataField(
      ECorporateSettingsField.Objective_IgnoreInvalidSSLCertificates
    );
    const fieldObjectiveSaveCommunications = getConfigDataField(
      ECorporateSettingsField.Objective_SaveCommunications
    );
    const fieldObjectiveVerboseLog = getConfigDataField(
      ECorporateSettingsField.Objective_VerboseLog
    );

    return (
      <>
        {fieldObjectiveAttachmentTitleFormat && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveAttachmentTitleFormat}
              isEditing={isEditing}
            />
          </div>
        )}

        <div className="cc-form-cols-3">
          {fieldObjectiveAuthentication &&
            fieldObjectiveAuthentication?.FieldName && (
              <div>
                <CCSearchComboBoxGlobalSettings
                  data={fieldObjectiveAuthentication}
                  isEditing={isEditing}
                />
              </div>
            )}

          {fieldObjectiveCredentials &&
            fieldObjectiveCredentials?.FieldName && (
              <div>
                <CCSearchComboBoxGlobalSettings
                  data={fieldObjectiveCredentials}
                  isEditing={isEditing}
                />
              </div>
            )}
        </div>

        {fieldObjectiveWebServiceBaseURL && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveWebServiceBaseURL}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldObjectiveWorkingDirectory && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveWorkingDirectory}
              isEditing={isEditing}
            />
          </div>
        )}

        <div className="cc-form-cols-3">
          {fieldObjectiveIgnoreInvalidSSLCertificates && (
            <SwitchGlobalSettings
              data={fieldObjectiveIgnoreInvalidSSLCertificates}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}

          {fieldObjectiveSaveCommunications && (
            <SwitchGlobalSettings
              data={fieldObjectiveSaveCommunications}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}

          {fieldObjectiveVerboseLog && (
            <SwitchGlobalSettings
              data={fieldObjectiveVerboseLog}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
        </div>
      </>
    );
  }
);
