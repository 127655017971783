import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Svc_SharepointDocumentLibrary } from "@common/models/corporateSetting";
import { IdentityPacket } from "@common/models/identityPacket";

export const getSharePointDocumentLibrary = async (
  siteCollectionURL: string
): Promise<
  APIResponse<
    IdentityPacket | Svc_SharepointDocumentLibrary[] | any | undefined
  >
> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/core/internal/settings/records/sharepoint/get-document-libraries?siteCollectionURL='${siteCollectionURL}'&accessToken=''`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
