import { IImage } from "@app/core/documents/model";
import { isBase64 } from "@common/utils/common";
import { ReactImageGalleryItem } from "react-image-gallery";

export const convertToImageInfo = (data: IImage) => {
  return {
    original:
      data?.ImageFile && isBase64(data?.ImageFile)
        ? `data:image/png;base64,${data?.ImageFile}`
        : data?.ImageFile,
    thumbnail:
      data?.ImageFile && isBase64(data?.ImageFile)
        ? `data:image/png;base64,${data?.ImageFile}`
        : data?.ImageFile,
    originalTitle: data?.Title,
    thumbnailTitle: data?.Title,
  } as ReactImageGalleryItem;
};
