import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { FieldArray } from "@progress/kendo-react-form";
import React from "react";
import { colAssociations } from "./config";

export const ASSOCIATIONS_FORM_STEP = "Associations";
export const AssociationsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { valueGetter } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));

  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              data={getFieldValue("Associations") || []}
              columnFields={colAssociations}
              primaryField="Id"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
