import { getFromDate } from "@app/products/ams/api/helpers/date-helper";
import { isNil } from "lodash";

export const validatorDateDialog = (
  values: any,
  valueGetter: (name: string) => any
) => {
  const edit = valueGetter("Editing");
  let validateErros = {};
  if (
    !isNil(valueGetter("Effective_To")) &&
    !isNil(valueGetter("Effective_To"))
  ) {
    const toDateValue: Date = getFromDate(valueGetter("Effective_To"));
    const fromDateValue: Date = getFromDate(valueGetter("Effective_From"));
    if (fromDateValue > toDateValue) {
      let errorMessage = "";
      errorMessage =
        edit === "Effective_From"
          ? `"From Date" must be less than or equal to "To Date"`
          : `"To Date" must be greater than or equal to "From Date"`;
      validateErros = {
        [edit]: errorMessage,
      };
    }
    return validateErros;
  }
  return undefined;
};
