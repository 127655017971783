import { JournalStatus } from "@app/products/property/journals/model";
import { postJournalStatus } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/complete-journal/api";
import { useJournalStore } from "@app/products/property/journals/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { eventEmitter } from "@/App";
import { CCGridEventType } from "@components/cc-grid/constant";
import { usePageType } from "@common/hooks/usePageType";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";

interface ICompleteIncompleteJournalProps {
  disabled?: boolean;
  isCompleteButton?: boolean;
}

export const CompleteIncompleteJournalButton = observer(
  ({
    disabled = false,
    isCompleteButton = false,
  }: ICompleteIncompleteJournalProps) => {
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { pushNotification } = useCCAppNotificationStore();
    const { reLoadJournal, journalId } = useJournalStore();
    const { gridSelectedRows, setGridSelectedRows } =
      useCCProductListViewStore();
    const { isManagePage } = usePageType();

    const handleUpdateJournalStatus = async () => {
      setIsLoading(true);
      const response = await postJournalStatus(
        isCompleteButton
          ? JournalStatus.TobeAuthorised
          : JournalStatus.Incomplete,
        isManagePage ? journalId : gridSelectedRows?.[0]?.Journal_Number
      );
      setIsLoading(false);
      setShowDialog(false);
      const defaultSuccessMessage = isCompleteButton
        ? "Journal has been completed successfully"
        : "Journal status was changed to incomplete";
      const defaultFailedMessage = isCompleteButton
        ? "Complete journal failed"
        : "Journal status could not be changed to incomplete";
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (!isManagePage) {
          eventEmitter.emit(CCGridEventType.RefreshOData);
          setGridSelectedRows([]);
          pushNotification({
            type: "success",
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
          });
          return;
        }

        reLoadJournal().then(() => {
          pushNotification({
            type: "success",
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
          });
        });
      } else {
        pushNotification({
          type: "error",
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          autoClose: false,
        });
      }
    };

    return (
      <>
        <CCNavButton
          title={isCompleteButton ? "Complete" : "Incomplete"}
          disabled={disabled}
          onClick={() => setShowDialog(true)}
          isLoading={isLoading}
        />
        {showDialog && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={`Are you sure you want to ${
              isCompleteButton
                ? "complete"
                : "change the status to incomplete for"
            } this journal?`}
            onClosePopup={() => setShowDialog(false)}
            onAsyncConfirm={handleUpdateJournalStatus}
            isLoadingYes={isLoading}
          />
        )}
      </>
    );
  }
);
