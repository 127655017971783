import { DTO_LOV } from "@common/models/odataResponse";

export const mockFinancialGroup: DTO_LOV[] = [
  {
    Code: "2",
    Name: "General rates",
  },
  {
    Code: "3",
    Name: "Land tax",
  },
  {
    Code: "4",
    Name: "FESL",
  },
  {
    Code: "100",
    Name: "CCMIL",
  },
  {
    Code: "101",
    Name: "Safer children",
  },
];
