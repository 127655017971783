import { colAssociationsSchemeAccount } from "@app/products/property/components/associations/components/scheme-account/config";
import { DTO_AssociatedItem_SchemeAccount } from "@app/products/property/components/associations/components/scheme-account/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsSchemeAccountProps {
  associationsSchemeAccountInfo: DTO_AssociatedItem_SchemeAccount[] | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_SchemeAccount>();

export const AssociationsSchemeAccount = ({
  associationsSchemeAccountInfo,
}: IAssociationsSchemeAccountProps) => {
  return (
    <CCGrid
      className="cc-scheme-account-grid"
      data={associationsSchemeAccountInfo || []}
      columnFields={colAssociationsSchemeAccount}
      primaryField={nameOf("Id")}
    />
  );
};
