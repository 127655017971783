import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import {
  VO_Candidate_LandRent,
  VO_Candidate_LandRent_Without_AnnualReview,
} from "@app/products/property/assessments/compliance/candidates-land-rent/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOfWithReview = nameOfFactory<VO_Candidate_LandRent>();
const nameOfWithoutReview =
  nameOfFactory<VO_Candidate_LandRent_Without_AnnualReview>();

export const complianceCandidateLandRentFirstRadioColList: IColumnFields[] = [
  {
    field: nameOfWithReview("Assess_Reference"),
    title: "Assessment (S-S-B-U)",
    linkTo: (dataItem) => ({
      pathname: `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Assessment_Id}`,
    }),
  },
  //TODOS change the NA_Name to Ratepayer when Vijay give back
  { field: nameOfWithReview("NA_Name"), title: "Ratepayer" },
  {
    field: nameOfWithReview("NA_Address_Locality"),
    title: "Address",
  },
  {
    field: nameOfWithReview("Property_Name_Address_Locality"),
    title: "Property Address",
  },
  {
    field: nameOfWithReview("Last_Change_of_Ownership_Date"),
    title: "Last Change of Ownership",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfWithReview("Last_Compliance_Date"),
    title: "Last Compliance Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfWithReview("Name_Address_Id"),
    title: "Name Address ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOfWithReview("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const complianceCandidateLandRentSecondRadioColList: IColumnFields[] = [
  {
    field: nameOfWithoutReview("Assess_Reference"),
    title: "Assessment (S-S-B-U)",
  },
  { field: nameOfWithoutReview("NA_Name"), title: "Ratepayer" },
  {
    field: nameOfWithoutReview("NA_Address_Locality"),
    title: "Address",
  },
  {
    field: nameOfWithoutReview("Property_Name_Address_Locality"),
    title: "Property Address",
  },
  {
    field: nameOfWithoutReview("Last_Compliance_Date"),
    title: "Last Compliance Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfWithoutReview("Name_Address_Id"),
    title: "Name Address ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOfWithoutReview("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
