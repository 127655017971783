export const ApplicationCategoriesFrom: any = {
  ApplicationCategory_Name: "",
  ApplicationCategory_Description: "",
  SortIndex: 0,
  TownPlanning: false,
  Building: false,
  LandDivision: false,
  Code: "",
  TargetDays: null,
  isPSA: false,
  IsOA: false,
  IsPNF: false,
  IsPublishToWeb: false,
  PublishName: "",
  PublishDescription: "",
  IsPPR: false,
  WorkFlow_ID: null,
  WorkFlowAutoActionTypes: [],
  ApplicationCategoryFeeType_ID: null,
  ApplicationCategoryFeeType: null,
  ApplicationCategoryFee_Amount: null,
  InheritApplicationFeeType: false,
  ApplicationCategoryCalculatedFee_ID: null,
  ChecklistQuestion_ID: null,
  ApplicationCategoryFees: [],
  DevAppsStatClockStart_ENUM: null,
  DevAppsStatClockStop_ENUM: null,
  IsLandManagement: false,
  IsCertification: false,
  IsNotification: false,
  ApplicationAssessment: [],
  Sys_CreatedDate: new Date(),
};
