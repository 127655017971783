import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colParkingPermitsPermitsNotPrinted: IColumnFields[] = [
  {
    field: ParkingPermitsFieldMapping.PermitType_Name,
    title: "Permit Type",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${PARKING_PERMITS_ROUTE}/${dataItem.ID}`;
    },
    width: 350,
  },
  {
    field: ParkingPermitsFieldMapping.PermitNumber,
    title: "Permit Number",
    width: 350,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.PermitHolder_DisplayName,
    title: "Holder",
    width: 350,
  },
  {
    field: ParkingPermitsFieldMapping.IssuedDate,
    title: "Issue Date",
    width: 350,

    format: DATE_FORMAT.DATE,
  },
  {
    field: ParkingPermitsFieldMapping.PrintStatus,
    title: "Already Printed?",
    width: 350,
  },
];
