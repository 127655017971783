import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const RequestFIButton = observer(() => {
  const { ppr, onSubmit } = usePPRStore();
  const { settings } = useCommonProductStore();
  const applicationValidation = getBoolValueSetting(
    settings[ECorporateSettingsField.TP_ApplicationValidation]
  );
  const isVisible = useMemo(() => {
    const status = ppr?.Status_ENUM;
    if (!status) return false;
    if (
      [
        Application_Status.AssessedByPlanner,
        Application_Status.PreliminaryAssessment,
      ].includes(status) &&
      !applicationValidation
    )
      return true;
    if (status === Application_Status.Validated) return true;
  }, [ppr, applicationValidation]);

  return isVisible ? (
    <CCNavButton
      title="Request further information"
      onClick={onSubmit}
      name={PPRSubmitActions.RequestFI}
    />
  ) : null;
});
