import {
  FURTHERINFOSTATUS,
  FurtherInfoSubmitActions,
} from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { checkIsPPR } from "@app/core/further-info/[id]/util";
import { useInfoNotReceivedStore } from "@app/core/further-info/components/buttons/work-flow/info-not-received/store";
import { InfoNotReceivedDialog } from "@app/core/further-info/components/dialogs/info-not-received/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

const infoNotReceivedValidatorList = [
  FURTHERINFOSTATUS.InfoRequestedDraft,
  FURTHERINFOSTATUS.InfoRequested,
];
export const InfoNotReceivedButton = observer(() => {
  const { furtherInfo, onSubmit, reLoadFurtherInfo } = useFurtherInfoStore();
  const { isShowDialog, setIsShowDialog } = useInfoNotReceivedStore();
  const { clearErrorNotification } = useCCAppNotificationStore();

  let history = useHistory();

  const handleAddInfoNotReceived = () => {
    setIsShowDialog(false);
    clearErrorNotification();
    try {
      history.goBack();
    } catch {
      reLoadFurtherInfo();
    }
  };
  const isVisible = useMemo(() => {
    return (
      infoNotReceivedValidatorList.some(
        (item) => item === furtherInfo?.Status_ENUM
      ) && checkIsPPR(furtherInfo)
    );
  }, [furtherInfo]);

  return isVisible ? (
    <>
      <CCNavButton
        title={"Further Information not received"}
        onClick={onSubmit}
        name={FurtherInfoSubmitActions.InfoNotReceived}
      />
      {isShowDialog && (
        <InfoNotReceivedDialog
          onClosePopup={() => setIsShowDialog(false)}
          onSubmit={handleAddInfoNotReceived}
        />
      )}
    </>
  ) : null;
});
