import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { ICCInputFilterProps } from "@components/cc-product-list-view/components/list-view-filter-bar/model";
import {
  DatePickerChangeEvent,
  DateTimePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import React from "react";

export const CCDateFilter = (props: ICCInputFilterProps) => {
  const { filter, onFilterChange, item } = props;

  const onChange = (
    event: DatePickerChangeEvent | DateTimePickerChangeEvent
  ) => {
    onFilterChange.call(undefined, {
      nextFilter: { ...filter, value: event.value },
    });
  };

  if (Object.values(DATE_FORMAT).includes(item.format)) {
    return (
      <CCDatePicker
        format={DATE_FORMAT.DATE_CONTROL}
        value={filter.value}
        onChange={onChange}
      />
    );
  }

  return (
    <CCDateTimePicker
      format={DATETIME_FORMAT.DATETIME_CONTROL}
      onChange={onChange}
      value={filter.value}
    />
  );
};
