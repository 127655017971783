import { eventEmitter } from "@/App";
import { loadViewConfiguresColumns } from "@app/products/property/api";
import {
  deleteAssessmentCompliance,
  postAddAssessmentCompliance,
} from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/components/form-elements/compliance/api";
import { colModifyComplianceAssessmentRegister } from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/components/form-elements/compliance/config";
import { ASSESSMENTS_FOR_MODIFY_COMPLIANCE_REGISTER_URL } from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/components/form-elements/compliance/constant";
import {
  VO_Assessment_for_ModifyCompliance,
  V_Assessment,
} from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/components/form-elements/compliance/model";
import { useModifyComplianceStore } from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/store";
import { AddAssessmentDialog } from "@app/products/property/components/action-bar/dialog/add-assessment/_index";
import { ViewConfiguration, nameOfLov } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { getDropdownValue, getUUID, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { IColumnFields } from "@components/cc-grid/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

const ASSESSMENTS_FOR_COMPLIANCE_GRID_ID = getUUID();

export const ComplianceFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfModifyComplianceAssessment = nameOfFactory<V_Assessment>();
const FormStepElement = observer(
  ({
    nameOf,
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
    formRenderProps,
    localNotificationRef,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const selectedAssessment = getFieldValue("AssessmentSelected");

    //Use store
    const { modifyComplianceLOVs } = useModifyComplianceStore();
    const { complianceId } = useModifyComplianceStore();

    //Use state
    const [complianceAssessmentCols, setComplianceAssessmentCols] = useState<
      IColumnFields[]
    >(colModifyComplianceAssessmentRegister);
    const [showConfirmDeletionDialog, setShowConfirmDeletionDialog] =
      useState<boolean>(false);
    const [showAddAssessmentDialog, setShowAddAssessmentDialog] =
      useState<boolean>(false);
    const [isAddingAssessment, setIsAddingAssessment] =
      useState<boolean>(false);
    const [isDeletingAssessment, setIsDeletingAssessment] =
      useState<boolean>(false);

    const loadComplianceAssessmentsView = async () => {
      setIsLoadingStep(true);
      const response = await loadViewConfiguresColumns(
        ViewConfiguration.ModifyCompliance_Assessments,
        complianceAssessmentCols
      );
      setIsLoadingStep(false);
      if (Array.isArray(response)) {
        setComplianceAssessmentCols(response);
      } else {
        setLoadFailedStep({
          onReload: () => {
            loadComplianceAssessmentsView();
          },
          responseError: {
            status: response.status,
            error: response.error ?? "Load failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      loadComplianceAssessmentsView();
    });

    /**
     * handle add assessment (Finish button)
     * @param assessment
     * @returns
     */
    const handleAddAssessment = async (
      assessment: VO_Assessment_for_ModifyCompliance
    ) => {
      if (!assessment.Assessment_Id || !complianceId) return;

      setIsAddingAssessment(true);
      //call API
      const response = await postAddAssessmentCompliance(
        complianceId,
        assessment.Assessment_Id
      );

      if (
        isSuccessResponse(response) &&
        response &&
        response?.data?.IsSuccess
      ) {
        setShowAddAssessmentDialog(false);
        //Reload assessments grid
        eventEmitter.emit(CCGridEventType.RefreshOData, {
          gridIds: [ASSESSMENTS_FOR_COMPLIANCE_GRID_ID],
        });
        localNotificationRef?.current?.pushNotification({
          title:
            response.data.SuccessMessage ??
            response.data.Notification ??
            "Assessment added successfully",
          type: "success",
        });
      } else {
        //Local notification
        notificationRef?.current?.pushNotification({
          autoClose: false,
          title: response.data.ErrorMessage ?? `Add assessment failed`,
          type: "error",
        });
      }
      setIsAddingAssessment(false);
    };

    /**
     * handle delete assessment
     */
    const handleDelete = async () => {
      const selectedAssessmentID = selectedAssessment.Assessment_Id;

      if (!selectedAssessmentID || !complianceId) return;
      setIsDeletingAssessment(true);
      //call API
      const response = await deleteAssessmentCompliance(
        complianceId,
        selectedAssessmentID
      );

      if (
        isSuccessResponse(response) &&
        response &&
        response?.data?.IsSuccess
      ) {
        setShowConfirmDeletionDialog(false);
        //Reload assessments grid
        eventEmitter.emit(CCGridEventType.RefreshOData, {
          gridIds: [ASSESSMENTS_FOR_COMPLIANCE_GRID_ID],
        });
        localNotificationRef?.current?.pushNotification({
          title:
            response.data.SuccessMessage ??
            response.data.Notification ??
            "Assessment deleted successfully",
          type: "success",
        });
        handleGridSelectionChange([], "AssessmentSelected");
      } else {
        //Local notification
        notificationRef?.current?.pushNotification({
          autoClose: false,
          title: response.data.ErrorMessage ?? `Delete assessment failed`,
          type: "error",
        });
      }
      setIsDeletingAssessment(false);
    };

    const handleGridSelectionChange = (dataItem: any, field: string) => {
      let newSelected = dataItem ? dataItem[0] : undefined;
      onChange(nameOf(field), {
        value: newSelected,
      });
    };

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }

    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }

    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Applicant name" />
              <Field
                name={nameOf("ApplicantName")}
                placeholder={"Applicant name"}
                component={CCInput}
                readOnly={options?.isReadOnly}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Reason for change" isMandatory />
              <Field
                name={nameOf("ReasonForChange")}
                component={CCSearchComboBox}
                disabled={options?.isReadOnly}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                data={modifyComplianceLOVs?.ReasonForUpdate ?? []}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                value={getDropdownValue(
                  "" + getFieldValue("ReasonForChange"),
                  modifyComplianceLOVs?.ReasonForUpdate ?? [],
                  "Code"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("ReasonForChange"), {
                    value: event.target.value?.Code ?? null,
                  });
                }}
              />
            </div>
          </div>
          <div className="cc-field">
            <CCLabel title="Assessments on which compliance is for" />
            <CCGrid
              gridId={ASSESSMENTS_FOR_COMPLIANCE_GRID_ID}
              dataUrl={`/odata/property/internal/modifycomplianceassessmentregister/${complianceId}?$count=true&`}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    type="button"
                    iconClass="fas fa-plus"
                    title="Add new assessment"
                    onClick={() => {
                      setShowAddAssessmentDialog(true);
                    }}
                  />
                  <Button
                    type="button"
                    iconClass="fas fa-minus"
                    title="Un-associate Assessment"
                    disabled={!selectedAssessment}
                    onClick={() => {
                      setShowConfirmDeletionDialog(true);
                    }}
                  />
                </div>
              }
              selectableMode="single"
              onSelectionChange={(
                dataItem: VO_Assessment_for_ModifyCompliance[]
              ) => {
                handleGridSelectionChange(dataItem, "AssessmentSelected");
              }}
              selectedRows={selectedAssessment ? [selectedAssessment] : []}
              columnFields={complianceAssessmentCols}
              primaryField={nameOfModifyComplianceAssessment("Assessment_Id")}
              readOnly={options?.isReadOnly}
            />
          </div>
        </section>
        {showAddAssessmentDialog && (
          <AddAssessmentDialog
            dataUrl={ASSESSMENTS_FOR_MODIFY_COMPLIANCE_REGISTER_URL}
            onClose={() => {
              setShowAddAssessmentDialog(false);
            }}
            handleFinish={handleAddAssessment}
            notificationRef={notificationRef}
            isLoadingFinish={isAddingAssessment}
          />
        )}
        {showConfirmDeletionDialog && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={
              "Are you sure you wish to un-associate the selected assessment from this compliance?"
            }
            onClosePopup={() => {
              setShowConfirmDeletionDialog(false);
            }}
            onAsyncConfirm={handleDelete}
            isLoadingYes={isDeletingAssessment}
          />
        )}
      </>
    );
  }
);
