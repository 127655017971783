import { getDisplayTextWithDashes } from "@common/utils/common";

export const CSLBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `CSL ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return (
      getDisplayTextWithDashes([
        selectedRow?.ID,
        selectedRow?.LicenceCategory,
      ]) || "N/A"
    );
  },
};
