import {
  apiCoreDeleteCheckListQuestion,
  apiCoreDeleteDynamicQuestionLists,
  apiCoreGetCheckListQuestion,
  apiCoreGetCheckListQuestionLovs,
  apiCoreGetDynamicQuestionListsByID,
  apiCoreGetDynamicQuestionListsLovs,
  apiCoreSaveCheckListQuestion,
  apiCoreSaveDynamicQuestionLists,
  apiCoreUpdateCheckListQuestion,
} from "@app/core/components/common/utils";
import {
  ChecklistQuestion,
  DynamicQuestionListDetail,
} from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DynamicQuestionList } from "@common/models/dynamicQuestion";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";
import {
  Svc_CheckListQuestionLovs,
  Svc_DynamicQuestionLovs,
  Svc_ManageQuestionRequest,
} from "@common/pages/settings/lookups/dynamic-lists/model";

export const postDynamicQuestionListsByID = async (
  dynamicQuestionListsId: number,
  manageQuestionRequest?: Svc_ManageQuestionRequest
): Promise<APIResponse<DynamicQuestionList> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<DynamicQuestionList>(
      apiCoreGetDynamicQuestionListsByID(dynamicQuestionListsId),
      manageQuestionRequest ?? {
        KeywordTypeId: null,
        ProductTypeId: null,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getDynamicQuestionListsLovs = async (): Promise<
  APIResponse<IIdentityPacket<Svc_DynamicQuestionLovs> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<Svc_DynamicQuestionLovs>
    >(apiCoreGetDynamicQuestionListsLovs());
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCheckListQuestionLovs = async (
  dynamicQuestionId: number,
  checkListQuestionId: number
): Promise<
  APIResponse<IIdentityPacket<Svc_CheckListQuestionLovs> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<Svc_CheckListQuestionLovs>
    >(apiCoreGetCheckListQuestionLovs(dynamicQuestionId, checkListQuestionId));
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSaveDynamicQuestionLists = async (
  dynamicQuestionLists: DynamicQuestionList
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreSaveDynamicQuestionLists(),
      dynamicQuestionLists
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postDeleteDynamicQuestionLists = async (
  listIds: number[]
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreDeleteDynamicQuestionLists(),
      listIds
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getDeleteCheckListQuestion = async (
  dynamicQuestionDetailId: number,
  checkListQuestionId: number
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<IdentityPacket>(
      apiCoreDeleteCheckListQuestion(),
      {
        params: {
          detailsId: dynamicQuestionDetailId,
          questionId: checkListQuestionId,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postUpdateCheckListQuestion = async (
  dynamicQuestionListDetail: DynamicQuestionListDetail
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreUpdateCheckListQuestion(),
      dynamicQuestionListDetail
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postCheckListQuestionById = async (
  checkListQuestionId: number,
  manageQuestionRequest?: Svc_ManageQuestionRequest
): Promise<APIResponse<ChecklistQuestion> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<ChecklistQuestion>(
      apiCoreGetCheckListQuestion(checkListQuestionId),
      manageQuestionRequest ?? {
        KeywordTypeId: null,
        ProductTypeId: null,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSaveCheckListQuestion = async (
  checkListQuestion: ChecklistQuestion
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreSaveCheckListQuestion(),
      checkListQuestion
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
