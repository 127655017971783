import { Application_Decision_Int } from "@app/products/town-planning/ppr/[id]/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const lstPPRApprovalDecisions: IKeyValuePacket[] = [
  { Key: Application_Decision_Int.OBJECTION, Value: "Objection" },
  {
    Key: Application_Decision_Int.NOOBJECTIONSUBJECTTOCONDITIONS,
    Value: "No Objection Subject To Conditions",
  },
  { Key: Application_Decision_Int.NOOBJECTION, Value: "No Objection" },
];
