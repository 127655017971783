import { CRMSViews } from "@app/products/crms/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";

export const externalUserEventsRoute: ICCRoute = {
  path: "external-user-events",
  name: "External User Events",
  enum: CORPORATE_SETTINGS_FIELD.CUSTOMERSERVICE_Views_ExternalUserEvents,
  children: [
    {
      path: "unresolved-by-refno",
      name: "Unresolved By RefNo",
      enum: CRMSViews.ExternalUser_UnresolvedByRefNo,
      component: require("./unresolved-by-refno/_index").default,
    },
    {
      path: "unresolved-by-action-person",
      name: "Unresolved By Action Person",
      enum: CRMSViews.ExternalUser_UnresolvedByActionOfficer,
      component: require("./unresolved-by-action-person/_index").default,
    },
    {
      path: "all-by-refno",
      name: "All By RefNo",
      enum: CRMSViews.ExternalUser_AllByRefNo,
      component: require("./all-by-refno/_index").default,
    },
    {
      path: "all-by-action-person",
      name: "All By Action Person",
      enum: CRMSViews.ExternalUser_AllByActionOfficer,
      component: require("./all-by-action-person/_index").default,
    },
  ],
};
