import { IKeyValuePacket } from "@common/models/keyValuePacket";

export interface IRegisterMeterTransferred {
  MeterNumber?: string | undefined;
  MeterId?: number;
  Change?: boolean;
  AccountReference?: string | undefined;
  PropertyAddress?: string | undefined;
}

export interface IRegisterTransferMeterData {
  AccountNo?: string | undefined;
  Description?: string;
  AccountName?: string;
  BillingGroup?: IKeyValuePacket;
  DateOfChange: Date;
  MeterTransferred?: IRegisterMeterTransferred[];
  PropertyAddressTransferred?: string | undefined;
  _option?: {
    BillingGroup: {
      Data: IKeyValuePacket[];
    };
    SearchAccount: {
      Data: IKeyValuePacket[];
      Loading: boolean;
    };
  };
}

export enum SearchType {
  AccountNo = "Account Number",
}
export enum SearchField {
  AccountNo = "AccountReference",
}
export const textFieldMapping: {
  [key: string]: string;
} = {
  [SearchType.AccountNo]: SearchField.AccountNo,
};
