import { DTO_Deferement_Detail } from "@app/products/property/assessments/deferments/components/action-bar/dialogs/create-deferment/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";

export enum EKeysOfStepsModifyDeferment {
  Details = "Details",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfSendStepsModifyDeferment = [
  EKeysOfStepsModifyDeferment.Details,
];

export interface DTO_Workflow_ModifyDeferment {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_ModifyDeferment;
}

export interface DTO_WorkflowDetail_ModifyDeferment {
  DefermentsDetails: DTO_Deferement_Detail;
}

export interface ModifyDefermentRequestObjFromSpatial {
  AssessmentDefermentId: number;
}
