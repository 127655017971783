import {
  CommunicationContact,
  SVC_SelectedAttachment,
} from "@app/core/communication/dialogs/model";
import { Attachment, MAILMERGEDOCUMENTUSAGE } from "@app/core/documents/model";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import {
  DocumentTemplate_Image,
  MailMergeIdentifier,
} from "@app/core/new-mail-merge/dialogs/model";
import { DataTable } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/referral/model";
import { ContactRelationshipType } from "@common/constants/enumerations";
import { ActionType } from "@common/models/actionType";

export enum ProductType {
  SystemInitialise = 0,
  Core = 1,
  Contact = 2,
  Action = 3,
  HealthManager = 4,
  CRS = 5,
  WasteWater = 6,
  Reports = 7,
  MailMerge = 8,
  Dashboards = 9,
  CustomerService = 10,
  HomePage = 11,
  Complaints = 12,
  Notices = 13,
  Streatrader_DONOTUSE = 14,
  SRS_DONOTUSE = 15,
  EPlanning = 16,
  WasteWaterSPI = 17,
  ELF = 18,
  DevApps = 19,
  MaternalChildHealth = 20,
  Building = 21,
  TownPlanning = 22,
  LLPermits = 23,
  Animals = 24,
  CRSPortal = 25,
  FSR = 26,
  CSM = 27,
  EHealth = 28,
  StreetProtection = 29,
  AppointmentManagementSystem = 30,
  EventManagementSystem = 31,
  PortalCore = 32,
  AppointmentBookingSystem = 33,
  EventBookingSystem = 34,
  FoodAct = 35,
  NSPR = 36,
  Radiation = 37,
  StandardsAndRegulation = 38,
  DWCMS = 39,
  FleetBooking = 40,
  CommunityAsset = 41,
  CommunityProperty = 42,
  LandManagement = 43,
  FakeProduct = 98,
  Agent = 99,
  ThirdParty = 100,
  DISRES = 250,
  CM_DONOTUSE = 251,
  CC = 252,
  PAM = 253,
  ITSupport = 254,
  CRMS_HardRubbish = 255,
  CRMS_PhoneMessages = 256,
  CRMS_Payroll = 257,
  CRSCentreAccess = 258,
  TrainingManager = 259,
  TelephoneActivity = 260,
  Asset = 261,
  Property = 262,
  Payroll = 263,
  Receipting = 264,
  HumanResource = 265,
  GIS = 266,
  Infringement = 267,
  Project = 268,
  CouncilOnline = 269,
  SSMS = 270,
  Records = 271,
  OtherSystems = 272,
  Hazard = 273,
  Cemeteries = 274,
  OpenOfficeCRM = 275,
  Infringements = 276,
  DirectDebit = 277,
}

export interface Communication {
  SelectedRecords_Details: MailMergeIdentifier[];
  DocumentUsage_Filters: MAILMERGEDOCUMENTUSAGE[];
  Dataset_Filters: MAILMERGEDATASET[];
  SendCommunicationTo_ContactRelationshipType: ContactRelationshipType[];
  SendCommunicationContacts: CommunicationContact[];
  IsAttachmentFromCurrentAvailable: boolean;
  SelectedDocument: Document;
  dtSelectedRecordsDetails: DataTable;
  ApendToRecords: boolean;
  SenderEmail: string;
  DefaultTemplate: number;
  ForwardPIRForInformation: boolean;
  SendToEmail: boolean | null;
  SendToFax: boolean | null;
  SendToSMS: boolean | null;
  SendByUsingPreferredMethod: boolean | null;
  ShowEmail: boolean;
  ShowFax: boolean;
  ShowSMS: boolean;
  ShowPreferredMethod: boolean;
  ShowDocumentTemplateAttachment: boolean;
  ShowAttachmentFromCurrent: boolean;
  ShowRecordsFromRecordSystem: boolean;
  ShowMultipleAttachments: boolean;
  QueueSystem_SendToCommunicationQueue: boolean | null;
  NoCommunication_Ignore: boolean | null;
  PassedActionType_ID: number | null;
  PassedActionType: ActionType;
  PassedNoOfDays_DueDate: number | null;
  SelectedActionType_ID: number;
  SelectedActionType: ActionType;
  Recipients: CommunicationContact[];
  Recipients_EmailCC_String: string;
  Recipients_EmailBCC_String: string;
  EmailSubject: string;
  EmailContent: string;
  SMSContent: string;
  ListOfAttachments: Attachment[];
  ListOfTemplateAttachments: number[];
  ListOfSelectedAttachmentIDsFromCurrent: Attachment[];
  ListOfTRIMAttachments: Attachment[];
  Recipients_EmailQty: number;
  Recipients_FaxQty: number;
  Recipients_SMSQty: number;
  Recipients_PrintQty: number;
  Communication_ProductType: ProductType;
  IsCalledFromView: boolean;
  PreferredAuthor_ID: number | null;
  PreferredAuthor_DisplayName: string;
  PreferredAuthor_UserName: string;
  Manager_DisplayName: string;
  Manager_UserName: string;
  Records_Notes: string;
  Records_AllowUserDefinedParentRecordId: boolean;
  Records_UserDefinedParentRecordId: string;
  Records_RecordType: string;
  AdditionalDocs: Attachment[];
  ShowFinalDocuments: boolean;
  IsRenewalNoticeCommunication: boolean;
  SaveCommunicationAttachment: boolean | null;
  SelectedRows: number[];
  SelectedContactRIDs: number[];
  SendUsingBCC: boolean;
  SendCopyToMe: boolean;
  SendCopyToEmail: string;
  SendCopyToMeBCC: boolean;
  SendCopyToEmailBCC: string;
  AccessToken: string;
  SelectedMailMergeDocumentID: number | null;
  ListOfAttachmentsFromCurrent: SVC_SelectedAttachment[];
  ListOfDocumentTemplateImages: DocumentTemplate_Image[];
}

export interface EventBatchAcknowledge {
  SelectedRows: number[];
  DateAcknowledged: Date;
  AcknowledgedMethod_ID: number;
  Show_MailMerge: boolean;
  MailMergeDocument_ID: number | null;
  CommunicationSetup: Communication;
}

export interface EventBatchAcknowledgeResponse {
  MergeResult: string;
  ContentType: string;
  FileNameNoSpaces: string;
}
