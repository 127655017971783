import UnAssociateDialog from "@app/products/property/registers/[id]/components/dialogs/un-associate/_index";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const UnAssociateButton = observer(() => {
  const [showUnAssociateDialog, setShowUnAssociateDialog] = useState(false);
  const { isEmptySelectedAssociateParcel } = useRegisterStore();
  const { pushNotification } = useCCAppNotificationStore();
  return (
    <>
      <CCNavButton
        title="Un-associate"
        disabled={isEmptySelectedAssociateParcel}
        onClick={() => setShowUnAssociateDialog(true)}
      />
      {showUnAssociateDialog && (
        <UnAssociateDialog
          onClosePopup={() => {
            setShowUnAssociateDialog(false);
            pushNotification({
              title: "Parcel un-associated successfully",
              type: "success",
            });
          }}
          onConfirmYes={() => {
            setShowUnAssociateDialog(false);
          }}
        />
      )}
    </>
  );
});
