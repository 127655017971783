import { SECURITY_TEMPLATE_ROUTE } from "@common/pages/settings/security/security-templates/constant";
import { SecurityTemplate } from "@common/pages/settings/security/security-templates/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<SecurityTemplate>();
export const colSecurityTemplates: IColumnFields[] = [
  {
    field: nameOf("ProductType_Name"),
    title: "Product",
    locked: true,
    linkTo: (dataItem: SecurityTemplate) =>
      `${SECURITY_TEMPLATE_ROUTE}/${dataItem.SecurityTemplate_ID}`,
  },
  {
    field: nameOf("TemplateName"),
    title: "Name",
  },
  {
    field: nameOf("TemplateDescription"),
    title: "Description",
  },
];
