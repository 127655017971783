import { getViewConfigurations } from "@app/products/property/assessments/master-properties/api";
import { DTO_AssociatedItem_Parcel } from "@app/products/property/components/associations/components/parcel/model";
import { colAssociationsParcel } from "@app/products/property/components/associations/components/parcel/standard-parcel/config";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IAssociationsParcelProps {
  associationsParcelInfo: DTO_AssociatedItem_Parcel[] | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_Parcel>();

export const AssociationsParcel = ({
  associationsParcelInfo,
}: IAssociationsParcelProps) => {
  const [viewconfiguration, setViewconfiguration] = useState<ICCViewColumn[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffectOnce(() => {
    getViewConfigurations(ViewConfiguration.Associations_Parcels).then(
      (response) => {
        if (isSuccessResponse(response) && response?.data) {
          setViewconfiguration(response.data.ColumnDefinitions?.Columns);
        }
        setIsLoading(false);
      }
    );
  });

  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <CCGrid
      className="cc-parcel-grid"
      data={associationsParcelInfo || []}
      columnFields={processDynamicColumns(
        colAssociationsParcel,
        viewconfiguration
      )}
      primaryField={nameOf("Id")}
    />
  );
};
