import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { deletePSARRecords } from "@app/products/town-planning/ppr/psa-referrals/components/action-bar/button/delete/api";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const PSARDeleteButton = observer(() => {
  const { gridSelectedIds, gridSelectedRows, setGridSelectedRows } =
    useCCProductListViewStore();
  const [isShowDeleteConfirm, setIsShowDeleteConfirm] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();

  const handleSubmit = async () => {
    setIsDeleting(true);
    const ids: number[] = gridSelectedIds.filter((number) => number != null);
    const response = await deletePSARRecords(ids);
    setIsDeleting(false);
    setIsShowDeleteConfirm(false);
    if (isSuccessResponse(response)) {
      setGridSelectedRows([]);
      clearNotifications();
      pushNotification({
        title: "Deleted successfully",
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
    } else {
      pushNotification({
        autoClose: false,
        title: "Deleted failed",
        description: response.data.Errors ?? response.statusText,
        type: "error",
      });
    }
  };
  return (
    <>
      <CCNavButton
        title="Delete"
        disabled={gridSelectedRows.length < 1}
        isLoading={isDeleting}
        onClick={() => {
          setIsShowDeleteConfirm(true);
        }}
      />
      {isShowDeleteConfirm && (
        <ConfirmDialog
          title={"Confirm Deletion"}
          subMessage={"Are you sure you want to delete this item?"}
          onClosePopup={() => {
            setIsShowDeleteConfirm(false);
          }}
          onConfirmYes={handleSubmit}
        />
      )}
    </>
  );
});
