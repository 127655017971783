import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingNoticesByDate: IColumnFields[] = [
  {
    field: BuildingFieldMapping.DateServed,
    title: "Served",
    format: DATE_FORMAT.DATE,
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.ApplicantName,
    title: "Applicant",
  },
  {
    field: BuildingFieldMapping.SiteAddress,
    title: "Site Address",
  },
  {
    field: BuildingFieldMapping.AssessmentNo,
    title: "Assess Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: BuildingFieldMapping.Type,
    title: "Type",
  },
  {
    field: BuildingFieldMapping.Status,
    title: "Status",
  },
  {
    field: BuildingFieldMapping.Risk,
    title: "Risk",
  },
  {
    field: BuildingFieldMapping.Officer,
    title: "Officer",
  },
  {
    field: BuildingFieldMapping.DateDue,
    title: "Compliance Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.DateRevoked,
    title: "Complied / Revoked",
  },
];
