import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<EventView>();

export const colByOrgStructure: IColumnFields[] = [
  {
    field: nameOf("Org1"),
    title: "Org 1",
  },
  {
    field: nameOf("Org2"),
    title: "Org 2",
  },
  {
    field: nameOf("Org3"),
    title: "Org 3",
  },
];
