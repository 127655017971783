import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import React from "react";
import { mockNoticeRunSidebar } from "../mock";

export const NoticeRunDetailReference = () => {
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Notice Run ID:"}
          value={mockNoticeRunSidebar.NoticeRunID}
        />
        <ProductReferenceRow
          title={"Issue Date:"}
          value={formatDisplayValue(
            mockNoticeRunSidebar.IssueDate,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Due Date:"}
          value={formatDisplayValue(
            mockNoticeRunSidebar.DueDate,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Printed or Exported on:"}
          value={formatDisplayValue(
            mockNoticeRunSidebar.PrintedExported,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Create By:"}
          value={mockNoticeRunSidebar.CreateBy}
        />
        <ProductReferenceRow
          title={"Create On:"}
          value={formatDisplayValue(
            mockNoticeRunSidebar.CreateOn,
            DATETIME_FORMAT.DATETIME
          )}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
};
