import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { ChangeInstalmentButton } from "@app/products/property/assessments/components/action-bar/buttons/change-instalment/_index";
import { HousekeepingButton } from "@app/products/property/assessments/components/action-bar/buttons/housekeeping/_index";
import { ReAllocationOfCreditsButton } from "@app/products/property/assessments/components/action-bar/buttons/re-allocation-of-credits/_index";
import { MasterPropertyAssociationsTab } from "@app/products/property/assessments/master-properties/[id]/components/reference-sidebar/associations/_index";
import { MasterPropertyDetailTab } from "@app/products/property/assessments/master-properties/[id]/components/reference-sidebar/detail/_index";
import { ASSESSMENT_MASTER_PROPERTIES_ROUTE } from "@app/products/property/assessments/master-properties/[id]/constant";
import { getViewConfigurations } from "@app/products/property/assessments/master-properties/api";
import { AddToSupplementaryButton } from "@app/products/property/assessments/master-properties/components/actionbar/buttons/add-to-supplementary/_index";
import { ModifyMasterPropertyButton } from "@app/products/property/assessments/master-properties/components/actionbar/buttons/modify-master-property/_index";
import { NewMasterPropertyButton } from "@app/products/property/assessments/master-properties/components/actionbar/buttons/new-master-property/_index";
import { colMasterProperties } from "@app/products/property/assessments/master-properties/config";
import { GET_VIEW_PROPERTY_ASSESSMENT_MASTER_PROPERTY } from "@app/products/property/assessments/master-properties/constant";
import { VO_Master_Property } from "@app/products/property/assessments/master-properties/model";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/property/assessments/master-properties/util";
import { assessmentsRoute } from "@app/products/property/assessments/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const gridId = getUUID();
const nameOf = nameOfFactory<VO_Master_Property>();
export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={assessmentsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <CCNavButton title={"Master property"} type="dropdown">
          <ModifyMasterPropertyButton />
          <NewMasterPropertyButton />
        </CCNavButton>
        <CCNavButton title={"Supplementary"} type="dropdown">
          <AddToSupplementaryButton
            componentNumber={eComponent.Master_Property}
          />
        </CCNavButton>
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Assessment}
        recordType={RECORDTYPE.CommunityProperty_Master_Property}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Master_Property}
        keyField={"Id"}
      />,
      <CCNavButton title={"Tools"} type="more">
        <HousekeepingButton />
        <ReAllocationOfCreditsButton gridId={gridId} />
        <ChangeInstalmentButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={ASSESSMENT_MASTER_PROPERTIES_ROUTE}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <MasterPropertyDetailTab /> },
      {
        title: "Map",
        component: (
          <MapTab addressField={nameOf("Property_Name_Address_Locality")} />
        ),
      },
      {
        title: "Related",
        component: <MasterPropertyAssociationsTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Master_Property}
          />
        ),
      },
    ],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedMasterPropertiesCol, setProcessedMasterPropertiesCol] =
    useState<IColumnFields[]>(colMasterProperties);

  const loadData = () => {
    setIsLoading(true);
    getViewConfigurations(ViewConfiguration.OData_MasterProperties).then(
      (response) => {
        if (isSuccessResponse(response)) {
          const viewConfig: ICCViewColumn[] | undefined =
            response?.data?.ColumnDefinitions?.Columns;
          if (!viewConfig || viewConfig?.length === 0) {
            setProcessedMasterPropertiesCol([]);
            setIsLoading(false);
            return;
          }
          setProcessedMasterPropertiesCol(
            processDynamicColumns(colMasterProperties, viewConfig)
          );
        } else {
          setResponseLoadError({
            status: response.status,
            error: response.error ?? "Load failed",
          });
        }
        setIsLoading(false);
      }
    );
  };

  useEffectOnce(() => {
    loadData();
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      gridId={gridId}
      dataUrl={GET_VIEW_PROPERTY_ASSESSMENT_MASTER_PROPERTY}
      columnFields={processedMasterPropertiesCol}
      primaryField={nameOf("Id")}
    />
  );
};
