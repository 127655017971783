import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { DATE_FORMAT } from "@common/constants/common-format";
import { FINANCEMETHOD, FINANCESYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { SystemFilter } from "@common/pages/settings/lookups/components/system-filter/_index";
import { PickTagsForFeeDialog } from "@common/pages/settings/lookups/fee-types/_id/components/general/pick-tags-for-fee/_index";
import { renderOptionBondTypePicker } from "@common/pages/settings/lookups/fee-types/config";
import {
  FeeType,
  SubsidyTerm,
  Svc_FeeTypeLOVs,
  Svc_PickBondType,
} from "@common/pages/settings/lookups/fee-types/model";
import { checkIsInactiveFeeType } from "@common/pages/settings/lookups/fee-types/util";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import {
  requiredValidator,
  validatorDateValueWithMinMax,
} from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCPercentInput } from "@components/cc-percent-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
export interface IFeeTypeFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<FeeType>();

export const FeeTypeFormElement = observer(
  ({ formRenderProps }: IFeeTypeFormElementProps) => {
    const { dataForms } = useFlexibleFormStore();
    const { settings } = useCommonCoreStore();

    const { valueGetter, onChange, errors } = formRenderProps;

    // #region state
    const [isShowPickTags, setIsShowPickTags] = useState(false);
    const [initialProductTags, setInitialProductTags] = useState("");
    // #endregion state

    // #region  Get Lovs
    const feeTypeLovs = dataForms?.GeneralFormLovs as Svc_FeeTypeLOVs;
    // #endregion Get Lovs

    // #region setting value
    const futureFeeIsEnabled = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableFutureFees]
    );
    const showGST = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableGST]
    );
    const ledgerCodeMandatory = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_LedgeCodeMandatory]
    );
    const subsidyIsEnabled = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_Enable_Subsidies]
    );
    const subsidyLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_Subsidies_Label]
    );
    const bondLookup = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_BondLookup]
    );
    const financeSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_FinanceSystem]
    );
    const financeMethod =
      getNumberValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_FinanceMethod]
      ) ?? FINANCEMETHOD.None;
    const enableUpToGlassFinance = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance]
    );
    const checkUpToGlassEnabled =
      enableUpToGlassFinance && financeMethod === FINANCEMETHOD.Direct;
    // #endregion

    // #region Get field value
    const subsidyValue = valueGetter(nameOf("Subsidy")) ?? false;
    const termEnumValue = valueGetter(nameOf("Term_ENUM"));
    // #endregion

    // #region Show/hide/disable and config label fields
    let isDeletedRecord = checkIsInactiveFeeType();
    let divGSTVisible = useMemo(() => {
      if (!showGST) return false;
      if (subsidyIsEnabled && subsidyValue === true) {
        return false;
      }
      return true;
    }, [showGST, subsidyIsEnabled, subsidyValue]);

    let isHideSubsidyEnableAndChecked = useMemo(() => {
      if (subsidyIsEnabled && subsidyValue === true) {
        return false;
      }
      return true;
    }, [subsidyIsEnabled, subsidyValue]);

    let isFlagGSTApplies = useMemo(() => {
      return valueGetter(nameOf("Flag_GSTApplies")) ?? false;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueGetter(nameOf("Flag_GSTApplies"))]);

    let fieldDefaultAmountLabel = useMemo(() => {
      if (isFlagGSTApplies) {
        return "Amount Ex GST";
      }
      return "Amount";
    }, [isFlagGSTApplies]);

    let fieldNextAmountLabel = useMemo(() => {
      if (isFlagGSTApplies) {
        return "Next amount Ex GST";
      }
      return "Next amount";
    }, [isFlagGSTApplies]);

    let isRequiredCostCode = useMemo(() => {
      if (ledgerCodeMandatory) {
        return true;
      }
      if (financeSystem === FINANCESYSTEM.CommunityFinance) return true;
      return false;
    }, [ledgerCodeMandatory, financeSystem]);

    let costCodeLabel = useMemo(() => {
      if (financeSystem === FINANCESYSTEM.MYOB) {
        return "Item code";
      }
      return "Ledger code";
    }, [financeSystem]);

    let categoryLabel = useMemo(() => {
      if (
        financeSystem === FINANCESYSTEM.ComputronDebtors ||
        financeSystem === FINANCESYSTEM.ComputronPathway
      ) {
        return "Transaction code";
      }
      return "Category code";
    }, [financeSystem]);

    let GSTCodeLabel = useMemo(() => {
      if (financeSystem === FINANCESYSTEM.ComputronDebtors) {
        return "Sales territory";
      } else if (financeSystem === FINANCESYSTEM.ComputronPathway) {
        return "Cost centre";
      }
      return "GST code";
    }, [financeSystem]);

    let trFutureFeesVisible = useMemo(() => {
      if (futureFeeIsEnabled) {
        if (subsidyValue === true && termEnumValue === SubsidyTerm.Short) {
          return false;
        }
        return true;
      }
      return false;
    }, [futureFeeIsEnabled, subsidyValue, termEnumValue]);

    let divCommonFutureFeeValueVisible = useMemo(() => {
      if (
        futureFeeIsEnabled &&
        subsidyValue === true &&
        termEnumValue === SubsidyTerm.Long
      ) {
        return false;
      }
      return true;
    }, [subsidyValue, termEnumValue, futureFeeIsEnabled]);
    // #endregion Show/hide/disable fields

    // #region Functions
    const maxDateValidatorStartDate = useCallback(
      (value: Date) =>
        validatorDateValueWithMinMax(
          value,
          null,
          valueGetter(nameOf("EndDate"))
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(nameOf("StartDate")), valueGetter(nameOf("EndDate"))]
    );
    const minDateValidatorEndDate = useCallback(
      (value: Date) =>
        validatorDateValueWithMinMax(
          value,
          valueGetter(nameOf("StartDate")),
          null
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(nameOf("StartDate")), valueGetter(nameOf("EndDate"))]
    );
    // #endregion functions

    useEffectOnce(() => {
      setInitialProductTags(valueGetter(nameOf("SystemFilter_ENUMS")) ?? "");
    });

    return (
      <>
        {/* <Loading isLoading={isLoading} isFullScreen /> */}
        <FormElement id="cc-form-manage-page-general">
          {/* SESSION 1: General 1 */}
          <section className="cc-field-group">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Name" isMandatory />
                <Field
                  name={nameOf("Name")}
                  component={CCInput}
                  placeholder="Name"
                  validator={requiredValidator}
                  disabled={isDeletedRecord}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <div className="cc-label cc-justify-between cc-align-item">
                  <div className="cc-flex">Description</div>
                  <Button
                    type="button"
                    iconClass="fas fa-plus"
                    onClick={() => {
                      setIsShowPickTags(true);
                    }}
                    disabled={isDeletedRecord}
                    title="Pick Fee Description Tags"
                  />
                </div>
                <Field
                  name={nameOf("Description")}
                  component={CCTextArea}
                  placeholder="Description"
                  disabled={isDeletedRecord}
                  rows={3}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              {divGSTVisible && (
                <div className="cc-field">
                  <CCLabel title="GST applies?" isMandatory />
                  <Field
                    name={nameOf("Flag_GSTApplies")}
                    checked={valueGetter(nameOf("Flag_GSTApplies")) ?? false}
                    component={CCSwitch}
                    validator={requiredValidator}
                    disabled={isDeletedRecord}
                  />
                </div>
              )}
              {isHideSubsidyEnableAndChecked && (
                <>
                  <div className="cc-field">
                    <CCLabel
                      title={fieldDefaultAmountLabel}
                      isMandatory={isFlagGSTApplies}
                    />
                    <Field
                      name={nameOf("DefaultAmount_ExGST")}
                      component={CCCurrencyInput}
                      validator={
                        isFlagGSTApplies ? requiredValidator : undefined
                      }
                      disabled={isDeletedRecord}
                    />
                  </div>
                  {isFlagGSTApplies && (
                    <div className="cc-field">
                      <CCLabel title="GST" isMandatory={isFlagGSTApplies} />
                      <Field
                        name={nameOf("DefaultAmount_GSTPercentage")}
                        min={0}
                        max={100}
                        step={0.01}
                        component={CCPercentInput}
                        disabled={isDeletedRecord}
                        validator={
                          isFlagGSTApplies ? requiredValidator : undefined
                        }
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </section>

          {/* SESSION 2 : General 2*/}
          <hr className="cc-divider" />
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              {isHideSubsidyEnableAndChecked && (
                <>
                  <div className="cc-field">
                    <CCLabel
                      title={costCodeLabel}
                      isMandatory={isRequiredCostCode}
                    />
                    <Field
                      name={nameOf("Code")}
                      component={CCInput}
                      placeholder="Name"
                      validator={
                        isRequiredCostCode ? requiredValidator : undefined
                      }
                      disabled={isDeletedRecord}
                    />
                  </div>
                  {!checkUpToGlassEnabled && (
                    <div className="cc-field">
                      <CCLabel title={categoryLabel} />
                      <Field
                        name={nameOf("Category")}
                        component={CCInput}
                        placeholder="Category"
                        disabled={isDeletedRecord}
                      />
                    </div>
                  )}
                  <div className="cc-field">
                    <CCLabel title={GSTCodeLabel} />
                    <Field
                      name={nameOf("GSTCode")}
                      component={CCInput}
                      placeholder="GST code"
                      disabled={isDeletedRecord}
                    />
                  </div>
                  {!checkUpToGlassEnabled && (
                    <div className="cc-field">
                      <CCLabel title="Organisation code" />
                      <Field
                        name={nameOf("OrganisationCode")}
                        component={CCInput}
                        placeholder="Organisation code"
                        disabled={isDeletedRecord}
                      />
                    </div>
                  )}
                </>
              )}

              <div className="cc-field">
                <CCLabel title="Product ID" />
                <Field
                  name={nameOf("Product_Id")}
                  component={CCInput}
                  placeholder="Product ID"
                  disabled={isDeletedRecord}
                />
              </div>
            </div>
            <div className="cc-field">
              <CCLabel title="Use for" />
              <div className="cc-field-group cc-custom-sub-panel-bar">
                <div className="cc-form-cols-4">
                  <div className="cc-field">
                    <CCLabel title="Fee" />
                    <Field
                      name={nameOf("Fee")}
                      checked={valueGetter(nameOf("Fee")) ?? false}
                      component={CCSwitch}
                      validator={requiredValidator}
                      disabled={isDeletedRecord || subsidyValue}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Refund" />
                    <Field
                      name={nameOf("Refund")}
                      checked={valueGetter(nameOf("Refund")) ?? false}
                      component={CCSwitch}
                      validator={requiredValidator}
                      disabled={isDeletedRecord || subsidyValue}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Bond" />
                    <Field
                      name={nameOf("Bond")}
                      checked={valueGetter(nameOf("Bond")) ?? false}
                      component={CCSwitch}
                      validator={requiredValidator}
                      disabled={isDeletedRecord || subsidyValue}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title={subsidyLabel ?? "Subsidy"} />
                    <Field
                      name={nameOf("Subsidy")}
                      checked={subsidyValue ?? false}
                      component={CCSwitch}
                      validator={requiredValidator}
                      disabled={isDeletedRecord}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* SESSION 3: External Bond Type */}
          {valueGetter(nameOf("Bond")) && bondLookup && (
            <>
              <hr className="cc-divider" />
              <section className="cc-field-group">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel title="External bond type" isMandatory />
                    <Field
                      name={nameOf("BondType_Id")}
                      textField="Value"
                      dataItemKey="Key"
                      data={feeTypeLovs?.BondType ?? []}
                      component={CCSearchComboBox}
                      validator={requiredValidator}
                      value={getDropdownValue(
                        valueGetter(nameOf("BondType_Id")),
                        feeTypeLovs?.BondType ?? [],
                        "Key"
                      )}
                      onChange={(event: ComboBoxChangeEvent) => {
                        onChange(nameOf("BondType_Id"), {
                          value: event.value?.Key,
                        });
                        onChange(nameOf("BondType"), {
                          value: event.value?.Value,
                        });
                      }}
                      disabled={isDeletedRecord}
                    />
                  </div>
                </div>
              </section>
            </>
          )}

          {/* SESSION 4: Bond Type */}
          {valueGetter(nameOf("Bond")) && !bondLookup && (
            <>
              <hr className="cc-divider" />
              <section className="cc-field-group">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel title="Bond type" isMandatory />
                    <Field
                      name={nameOf("BondType")}
                      placeholder="Select bond type"
                      component={InputPickerSearch}
                      onChange={(data: Svc_PickBondType) => {
                        onChange(nameOf("BondType"), {
                          value: data?.Code,
                        });
                      }}
                      validator={requiredValidator}
                      options={renderOptionBondTypePicker()}
                      value={valueGetter(nameOf("BondType")) || ""}
                      disabled={isDeletedRecord}
                    />
                  </div>
                </div>
              </section>
            </>
          )}

          {/* SESSION 5: Subsidy Details */}
          {!isHideSubsidyEnableAndChecked && (
            <>
              <hr className="cc-divider" />
              <section className="cc-field-group">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel title="Discount type" />
                    <Field
                      name={nameOf("DiscountType_ENUM")}
                      textField="Value"
                      dataItemKey="Key"
                      data={feeTypeLovs?.DiscountType ?? []}
                      component={CCSearchComboBox}
                      value={getDropdownValue(
                        valueGetter(nameOf("DiscountType_ENUM")),
                        feeTypeLovs?.DiscountType ?? [],
                        "Key"
                      )}
                      onChange={(event: ComboBoxChangeEvent) => {
                        onChange(nameOf("DiscountType_ENUM"), {
                          value: event.value?.Key,
                        });
                        onChange(nameOf("DiscountType_Name"), {
                          value: event.value?.Value,
                        });
                      }}
                      disabled={isDeletedRecord}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Value" />
                    <Field
                      name={nameOf("DefaultAmount_ExGST")}
                      component={CCNumericTextBox}
                      placeholder="Subsidy compute value"
                      disabled={isDeletedRecord}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Term" />
                    <Field
                      name={nameOf("Term_ENUM")}
                      textField="Value"
                      dataItemKey="Key"
                      data={feeTypeLovs?.SubsidyTerm ?? []}
                      component={CCSearchComboBox}
                      value={getDropdownValue(
                        valueGetter(nameOf("Term_ENUM")),
                        feeTypeLovs?.SubsidyTerm ?? [],
                        "Key"
                      )}
                      onChange={(event: ComboBoxChangeEvent) => {
                        onChange(nameOf("Term_ENUM"), {
                          value: event.value?.Key,
                        });
                        onChange(nameOf("Term_Name"), {
                          value: event.value?.Value,
                        });
                      }}
                      disabled={isDeletedRecord}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel title="Start date" />
                    <Field
                      name={nameOf("StartDate")}
                      component={CCDatePicker}
                      validator={maxDateValidatorStartDate}
                      format={DATE_FORMAT.DATE_CONTROL}
                      visited={errors[nameOf("StartDate")]}
                      disabled={isDeletedRecord}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="End date" />
                    <Field
                      name={nameOf("EndDate")}
                      component={CCDatePicker}
                      min={valueGetter(nameOf("StartDate"))}
                      format={DATE_FORMAT.DATE_CONTROL}
                      validator={minDateValidatorEndDate}
                      visited={errors[nameOf("EndDate")]}
                      disabled={isDeletedRecord}
                    />
                  </div>
                </div>
              </section>
            </>
          )}

          <hr className="cc-divider" />
          {/* SESSION 6: Module */}
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <SystemFilter
                formRenderProps={formRenderProps}
                readOnly={isDeletedRecord}
              />
            </div>
          </section>

          {/* SESSION 7: Calculated Fee Details */}
          {isHideSubsidyEnableAndChecked && (
            <>
              <hr className="cc-divider" />
              <section className="cc-field-group">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel
                      title="Allow calculated fees with this type?"
                      isMandatory
                    />
                    <Field
                      name={nameOf("Flag_CalculatedFee")}
                      checked={
                        valueGetter(nameOf("Flag_CalculatedFee")) ?? false
                      }
                      component={CCSwitch}
                      validator={requiredValidator}
                      disabled={isDeletedRecord}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Allow zero amount?" isMandatory />
                    <Field
                      name={nameOf("Flag_AllowZeroAmount")}
                      checked={
                        valueGetter(nameOf("Flag_AllowZeroAmount")) ?? false
                      }
                      component={CCSwitch}
                      validator={requiredValidator}
                      disabled={isDeletedRecord}
                    />
                  </div>
                </div>
              </section>
            </>
          )}

          {/* SESSION 8: Future Fees */}
          {trFutureFeesVisible && (
            <>
              <hr className="cc-divider" />
              <section className="cc-field-group">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel title="Next effective date" />
                    <Field
                      name={nameOf("NextEffectiveDate")}
                      component={CCDatePicker}
                      format={DATE_FORMAT.DATE_CONTROL}
                      disabled={isDeletedRecord}
                    />
                  </div>
                </div>
                {divCommonFutureFeeValueVisible && (
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title={fieldNextAmountLabel} />
                      <Field
                        name={nameOf("NextAmount_ExGST")}
                        component={CCNumericTextBox}
                        placeholder={fieldNextAmountLabel}
                        disabled={isDeletedRecord}
                      />
                    </div>
                    {valueGetter(nameOf("Flag_GSTApplies")) && (
                      <div className="cc-field">
                        <CCLabel title={"Next GST"} />
                        <Field
                          name={nameOf("NextAmount_GSTPercentage")}
                          min={0}
                          max={100}
                          step={0.01}
                          component={CCPercentInput}
                          disabled={isDeletedRecord}
                        />
                      </div>
                    )}
                  </div>
                )}
              </section>
            </>
          )}
        </FormElement>

        {isShowPickTags && (
          <PickTagsForFeeDialog
            productTags={initialProductTags}
            onSubmit={(data) => {
              if (!data) return;
              const concatenatedString = " [" + data.join("] [") + "]";
              onChange(nameOf("Description"), {
                value: valueGetter(nameOf("Description")) + concatenatedString,
              });
              setIsShowPickTags(false);
            }}
            onCloseDialog={() => setIsShowPickTags(false)}
          />
        )}
      </>
    );
  }
);
