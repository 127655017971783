import { eComponent } from "@app/products/property/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import {
  DTO_ApplicationSearch_Item,
  mappingURLPattern,
} from "@common/pages/search/model";
import { get, includes, replace, toString } from "lodash";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";

export const getURLPage = (dataItem: DTO_ApplicationSearch_Item) => {
  const pattern = mappingURLPattern[dataItem.Component ?? ""];
  if (dataItem.Id) {
    return pattern.replace("{id}", dataItem.Id.toString());
  }
  return pattern;
};

export const searchTextValidator: any = (value: string) => {
  return value?.trim()?.length > 2
    ? undefined
    : "Please enter at least 3 characters";
};

export const mappingRecordSourceTypeEnum = (
  productTypeEnum: PRODUCT_TYPE_NUMBER,
  recordSourceTypeEnum: number,
  recordSourceId?: number | null
): string | undefined => {
  if (productTypeEnum === PRODUCT_TYPE_NUMBER.CommunityProperty) {
    let enumType: eComponent | undefined = undefined;
    switch (recordSourceTypeEnum) {
      case RECORDTYPE.CommunityProperty_Unknown:
        enumType = eComponent.Unknown;
        break;
      case RECORDTYPE.CommunityProperty_Assessment:
        enumType = eComponent.Assessment;
        break;
      case RECORDTYPE.CommunityProperty_Parcel:
        enumType = eComponent.Parcel;
        break;
      case RECORDTYPE.CommunityProperty_Title:
        enumType = eComponent.Title;
        break;
      case RECORDTYPE.CommunityProperty_Assessment_Transaction:
        enumType = eComponent.Assessment_Transaction;
        break;
      case RECORDTYPE.CommunityProperty_Certificate:
        enumType = eComponent.Certificate;
        break;
      case RECORDTYPE.CommunityProperty_Register_Account:
        enumType = eComponent.Register_Account;
        break;
      case RECORDTYPE.CommunityProperty_Supplementary:
        enumType = eComponent.Supplementary;
        break;
      case RECORDTYPE.CommunityProperty_Meter:
        enumType = eComponent.Meter;
        break;
      case RECORDTYPE.CommunityProperty_Scheme_Account:
        enumType = eComponent.Scheme_Account;
        break;
      case RECORDTYPE.CommunityProperty_Certificate_Request:
        enumType = eComponent.Certificate_Request;
        break;
      case RECORDTYPE.CommunityProperty_Charge:
        enumType = eComponent.Charge;
        break;
      case RECORDTYPE.CommunityProperty_Charge_Balances:
        enumType = eComponent.Charge_Balances;
        break;
      case RECORDTYPE.CommunityProperty_Notice_Group:
        enumType = eComponent.Notice_Group;
        break;
      case RECORDTYPE.CommunityProperty_Street_Locality:
        enumType = eComponent.Street_Locality;
        break;
      case RECORDTYPE.CommunityProperty_GIS_Selection:
        enumType = eComponent.GIS_Selection;
        break;
      case RECORDTYPE.CommunityProperty_Certificates_Type:
        enumType = eComponent.Certificates_Type;
        break;
      case RECORDTYPE.CommunityProperty_Active_Certificates:
        enumType = eComponent.Active_Certificates;
        break;
      case RECORDTYPE.CommunityProperty_Active_Certificates_for_Responsible_Area:
        enumType = eComponent.Active_Certificates_for_Responsible_Area;
        break;
      case RECORDTYPE.CommunityProperty_Register:
        enumType = eComponent.Register;
        break;
      case RECORDTYPE.CommunityProperty_Master_Property:
        enumType = eComponent.Master_Property;
        break;
      case RECORDTYPE.CommunityProperty_Scheme:
        enumType = eComponent.Scheme;
        break;
      case RECORDTYPE.CommunityProperty_Meter_Reading_Route:
        enumType = eComponent.Meter_Reading_Route;
        break;
      case RECORDTYPE.CommunityProperty_Meter_Reading_Device:
        enumType = eComponent.Meter_Reading_Device;
        break;
      case RECORDTYPE.CommunityProperty_Utility:
        enumType = eComponent.Utility;
        break;
      case RECORDTYPE.CommunityProperty_Scheme_Account_Transaction:
        enumType = eComponent.Scheme_Account_Transaction;
        break;
      case RECORDTYPE.CommunityProperty_Meter_Installation:
        enumType = eComponent.Meter_Installation;
        break;
      case RECORDTYPE.CommunityProperty_Register_Account_Transaction:
        enumType = eComponent.Register_Account_Transaction;
        break;
      case RECORDTYPE.CommunityProperty_Land_Tax_Exemption:
        enumType = eComponent.Land_Tax_Exemption;
        break;
      case RECORDTYPE.CommunityProperty_ACT_Lease_Transfer:
        enumType = eComponent.ACT_Lease_Transfer;
        break;
      case RECORDTYPE.CommunityProperty_Deferred_Duty_Account:
        enumType = eComponent.Deferred_Duty_Account;
        break;
      case RECORDTYPE.CommunityProperty_Deferred_Duty_Scheme:
        enumType = eComponent.Deferred_Duty_Scheme;
        break;
      case RECORDTYPE.CommunityProperty_Deferred_Duty_Transaction:
        enumType = eComponent.Deferred_Duty_Transaction;
        break;
      case RECORDTYPE.CommunityProperty_Deferred_Duty_Instalments:
        enumType = eComponent.Deferred_Duty_Instalments;
        break;
      case RECORDTYPE.CommunityProperty_Entity:
        enumType = eComponent.Entity;
        break;
      case RECORDTYPE.CommunityProperty_Entity_Name_and_Address:
        enumType = eComponent.Contact_Name_and_Address;
        break;
      case RECORDTYPE.CommunityProperty_Assessment_Levy_Id:
        enumType = eComponent.Assessment_Levy_Id;
        break;
      case RECORDTYPE.CommunityProperty_Tasks_Run_Id:
        enumType = eComponent.Tasks_Run_Id;
        break;
      case RECORDTYPE.CommunityProperty_Tasks_Id:
        enumType = eComponent.Tasks_Id;
        break;
      case RECORDTYPE.CommunityProperty_Collection:
        enumType = eComponent.Collection;
        break;
      case RECORDTYPE.CommunityProperty_MenuItem:
        enumType = eComponent.MenuItem;
        break;
      case RECORDTYPE.CommunityProperty_Journal:
        enumType = eComponent.Journal;
        break;
      case RECORDTYPE.CommunityProperty_Advanced_Search:
        enumType = eComponent.Advanced_Search;
        break;
      case RECORDTYPE.CommunityProperty_Report:
        enumType = eComponent.Report;
        break;
      case RECORDTYPE.CommunityProperty_Home:
        enumType = eComponent.Home;
        break;
      case RECORDTYPE.CommunityProperty_Charge_Instalment:
        enumType = eComponent.Charge_Instalment;
        break;
      case RECORDTYPE.CommunityProperty_Charge_Levy:
        enumType = eComponent.Charge_Levy;
        break;
      case RECORDTYPE.CommunityProperty_Application_Search_No_Results:
        enumType = eComponent.Application_Search_No_Results;
        break;
      case RECORDTYPE.CommunityProperty_Session:
        enumType = eComponent.Session;
        break;
      case RECORDTYPE.CommunityProperty_Associated_Name:
        enumType = eComponent.Associated_Name;
        break;
      case RECORDTYPE.CommunityProperty_Lease:
        enumType = eComponent.Lease;
        break;
      case RECORDTYPE.CommunityProperty_Debt_Recovery:
        enumType = eComponent.Debt_Recovery;
        break;
      case RECORDTYPE.CommunityProperty_Assessment_Rebate_Entitlement:
        enumType = eComponent.Assessment_Rebate_Entitlement;
        break;
      case RECORDTYPE.CommunityProperty_Arrangement:
        enumType = eComponent.Arrangement;
        break;
      case RECORDTYPE.CommunityProperty_Change_of_Ownership:
        enumType = eComponent.Change_of_Ownership;
        break;
      case RECORDTYPE.CommunityProperty_Supplementary_Assessment:
        enumType = eComponent.Supplementary_Assessment;
        break;
      case RECORDTYPE.CommunityProperty_Arrangement_Letter:
        enumType = eComponent.Arrangement_Letter;
        break;
      case RECORDTYPE.CommunityProperty_Name_and_Address:
        enumType = eComponent.Name_and_Address;
        break;
      case RECORDTYPE.CommunityProperty_Debt_Recovery_Action:
        enumType = eComponent.Debt_Recovery_Action;
        break;
      case RECORDTYPE.CommunityProperty_Debt_Recovery_PaymentsReceived:
        enumType = eComponent.Debt_Recovery_PaymentsReceived;
        break;
      case RECORDTYPE.CommunityProperty_Debt_Recovery_Exemption:
        enumType = eComponent.Debt_Recovery_Exemption;
        break;
      case RECORDTYPE.CommunityProperty_Mail_Box:
        enumType = eComponent.Mail_Box;
        break;
      case RECORDTYPE.CommunityProperty_Financial_Summary:
        enumType = eComponent.Financial_Summary;
        break;
      case RECORDTYPE.CommunityProperty_Rebate_Claim:
        enumType = eComponent.Rebate_Claim;
        break;
      case RECORDTYPE.CommunityProperty_Concession_Card:
        enumType = eComponent.Concession_Card;
        break;
      case RECORDTYPE.CommunityProperty_Valuation:
        enumType = eComponent.Valuation;
        break;
      case RECORDTYPE.CommunityProperty_Address:
        enumType = eComponent.Address;
        break;
      case RECORDTYPE.CommunityProperty_CommunityCentral_Town_Planning_Application:
        enumType = eComponent.CommunityCentral_Town_Planning_Application;
        break;
      case RECORDTYPE.CommunityProperty_CommunityCentral_Health_Manager_Premise:
        enumType = eComponent.CommunityCentral_Health_Manager_Premise;
        break;
      case RECORDTYPE.CommunityProperty_CommunityCentral_Animal_Registration:
        enumType = eComponent.CommunityCentral_Animal_Registration;
        break;
      case RECORDTYPE.CommunityProperty_CommunityCentral_Building_Application:
        enumType = eComponent.CommunityCentral_Building_Application;
        break;
      case RECORDTYPE.CommunityProperty_CommunityCentral_Local_Laws_Permit:
        enumType = eComponent.CommunityCentral_Local_Laws_Permit;
        break;
      case RECORDTYPE.CommunityProperty_CommunityCentral_Events_Management_System_Booking:
        enumType = eComponent.CommunityCentral_Events_Management_System_Booking;
        break;
      case RECORDTYPE.CommunityProperty_CommunityCentral_Waste_Water_Permit:
        enumType = eComponent.CommunityCentral_Waste_Water_Permit;
        break;
      case RECORDTYPE.CommunityProperty_HazardId_Fire_Prevention:
        enumType = eComponent.HazardId_Fire_Prevention;
        break;
      case RECORDTYPE.CommunityProperty_HazardId_Noxious_Weed:
        enumType = eComponent.HazardId_Noxious_Weed;
        break;
      case RECORDTYPE.CommunityProperty_CommunityFinance_Customer:
        enumType = eComponent.CommunityFinance_Customer;
        break;
      case RECORDTYPE.CommunityProperty_Charge_Run:
        enumType = eComponent.Charge_Run;
        break;
      case RECORDTYPE.CommunityProperty_Notice_Run_Assessment:
        enumType = eComponent.Notice_Run;
        break;
      case RECORDTYPE.CommunityProperty_CommunityCentral_DevelopmentApplication:
        enumType = eComponent.CommunityCentral_DevelopmentApplication;
        break;
      case RECORDTYPE.CommunityProperty_CommunityCentral_StreetProtection:
        enumType = eComponent.CommunityCentral_StreetProtection;
        break;
      case RECORDTYPE.CommunityProperty_CommunityCentral_Other:
        enumType = eComponent.CommunityCentral_Other;
        break;
      case RECORDTYPE.CommunityProperty_Compliance:
        enumType = eComponent.Compliance;
        break;
      case RECORDTYPE.CommunityProperty_CommunicationTemplate:
        enumType = eComponent.Communication_Template;
        break;
      case RECORDTYPE.CommunityProperty_Workflow:
        enumType = eComponent.Workflow;
        break;
      case RECORDTYPE.CommunityProperty_InterestRun_Assessment:
        enumType = eComponent.Assessment;
        break;
      case RECORDTYPE.CommunityProperty_InterestRun_DeferredDuty:
        enumType = eComponent.Deferred_Duty_Account;
        break;
      case RECORDTYPE.CommunityProperty_PIC:
        enumType = eComponent.PIC;
        break;
    }
    if (enumType) return generateURLWithIdParameter(enumType, recordSourceId);
  }
};

const generateURLWithIdParameter = (
  eComponent: eComponent,
  id?: number | null
): string | undefined => {
  const urlPattern = get(mappingURLPattern, eComponent);

  return includes(urlPattern, "{id}") && id
    ? replace(urlPattern, "{id}", toString(id))
    : undefined;
};
