import { colRegisterTransactions } from "@app/products/property/registers/[id]/components/child-screens/transactions/config";
import { useRegisterTransactionsStore } from "@app/products/property/registers/[id]/components/child-screens/transactions/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { Checkbox } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";
import { IRegisterTransactions } from "./model";

const nameOf = nameOfFactory<IRegisterTransactions>();

export const RegisterTransactions = observer(() => {
  const {
    loadRegisterTransactions,
    registerTransactions,
    isLoading,
    resetStore,
    setSelectedRegisterTransactions,
  } = useRegisterTransactionsStore();

  useEffectOnce(() => {
    loadRegisterTransactions();
    return () => {
      resetStore();
    };
  });

  return (
    <div className="cc-register-transactions">
      <div className="cc-grid-control-left">
        <Checkbox
          className="cc-control-item"
          title="Show Cancelled Transactions"
          label="Show cancelled transactions"
        />
      </div>
      <CCGrid
        isLoading={isLoading}
        className="cc-register-transactions-grid"
        data={registerTransactions || []}
        columnFields={colRegisterTransactions}
        primaryField={nameOf("TransactionId")}
        selectableMode={"multiple"}
        onSelectionChange={setSelectedRegisterTransactions}
      />
    </div>
  );
});
