import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<EventView>();

export const colByWorkDate: IColumnFields[] = [
  { field: nameOf("DateWork"), title: "Work Date", format: DATE_FORMAT.DATE },
  {
    field: nameOf("Org2"),
    title: "Org 2",
  },
  {
    field: nameOf("Org3"),
    title: "Org 3",
  },
];
