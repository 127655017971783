import { DDCreateAccountDialog } from "@app/products/direct-debit/accounts/components/dialogs/create-account";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

interface ICreateAccountButtonProps {
  isDisabled?: boolean;
}
export const CreateAccountButton = ({
  isDisabled = false,
}: ICreateAccountButtonProps) => {
  const [isShowDialog, setIsShowDialog] = useState(false);

  return (
    <>
      <CCNavButton
        title="New"
        disabled={isDisabled}
        onClick={() => setIsShowDialog(true)}
      />
      {isShowDialog ? (
        <DDCreateAccountDialog onClose={() => setIsShowDialog(false)} />
      ) : null}
    </>
  );
};
