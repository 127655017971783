import { VO_Journal_SessionList } from "@app/products/property/journals/session-list/model";
import { JOURNALS_SESSION_LIST_ROUTE } from "@app/products/property/journals/session-list/[id]/constant";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Journal_SessionList>();

export const colListSession: IColumnFields[] = [
  {
    field: nameOf("Session_Number"),
    title: "Session",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) =>
      `${JOURNALS_SESSION_LIST_ROUTE}/${dataItem.Session_Number}`,
  },
  {
    field: nameOf("Session_Name"),
    title: "Name",
  },
  {
    field: nameOf("Session_Status_Name"),
    title: "Status",
  },
  {
    field: nameOf("No_of_Transactions"),
    title: "Number of Transaction",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Total_Debits"),
    title: "Total Debits",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Total_Credits"),
    title: "Total Credits",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("JournalNumbers"),
    title: "Journals Included",
  },
  {
    field: nameOf("Session_Created_On"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Session_Created_By"),
    title: "Created By",
  },
  {
    field: nameOf("Session_Updated_On"),
    title: "Updated On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Session_Updated_By"),
    title: "Updated By",
  },
  {
    field: nameOf("Session_Finesse_Session_Number"),
    title: "Finesse Session Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
];

export enum SESSION_STATUS_NAME_CAN_PROCESS {
  ToBeUpdated = "To be Updated",
  Updated = "Updated",
}
