import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colAssessmentSpecialReadingList: IColumnFields[] = [
  {
    field: "ScheduledReadDate",
    title: "Scheduled Read Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "DateRead",
    title: "Date Read",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "SpecialReadType",
    title: "Special Read Type",
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "Description",
    title: "Description",
  },
  {
    field: "Name",
    title: "Name",
  },
  {
    field: "Comments",
    title: "Comments",
  },
  {
    field: "AssignedReadingDevice",
    title: "Assigned Reading Device",
  },
  {
    field: "ReadingOfficer",
    title: "Reading Officer",
  },
  {
    field: "FeeAmount",
    title: "Fee Amount",
  },
  {
    field: "JournalNumber",
    title: "Journal Number",
  },
  {
    field: "CreatedOn",
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "CreatedBy",
    title: "Created By",
  },
  {
    field: "AssessmentId",
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "MeterSpecialReadId",
    title: "Meter Special Read ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
