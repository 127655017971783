import { ICCRoute } from "@common/constants/ICCRoute";
export const communicationQueueRoute: ICCRoute = {
  path: "queue",
  name: "Queue",
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./_id/_index").default,
    },
  ],
};
