import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import { IColumnFields } from "@components/cc-grid/model";

/**
 * Task ID: 13891
 * Corresponding list with enum.
 */
export enum Svc_LLPermitsViewType {
  Register,
  New,
  Lodged,
  Refused,
  Expired,
  Deactivated,
  Withdrawn,
  Insurance,
}

/**
 * DTO for the Permits views
 */
export interface Svc_LLPermit {
  ID: number;
  PermitNumber: string;
  PermitType_ID: string;
  Applicant: string;
  SiteAddress: string;
  Locality: string;
  Owner: string;
  Type: string;
  StatusDueStatus: string;
  Status_ENUM: number;
  Status: string;
  DueStatus: string;
  InsuranceDueStatus: string;
  LodgedDate: Date | null;
  EffectiveFrom: Date | null;
  EffectiveTo: Date | null;
  InsuranceCompany: string;
  InsurancePolicyNumber: string;
  InsuranceDateExpires: Date | null;
  Officer: string;
  FileNo: string;
  RefNo: string;
  UsePermitEventName: string;
  RegistrationNumber: string;
  TimeFrom: Date | null;
  TimeTo: Date | null;
  Area: string;
  AssessmentNo: string;
  DebtorNo: string;
  Drescription: string;
}

/**
 * Using this to show/hide the columns based on the settings
 */
export interface IGenerateLLPColumnsBySettingProps {
  defaultConfig: IColumnFields[];
  settings?: SettingsMap;
}
