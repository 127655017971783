import { IAttributeLov } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-run-attributes/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NoticeRunAttributeStepStore {
  private _attributeLOVs?: IAttributeLov = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get attributeLOVs() {
    return toJS(this._attributeLOVs);
  }
  setAttributeLOVs = (attributeLOVs?: IAttributeLov) => {
    runInAction(() => {
      this._attributeLOVs = attributeLOVs;
    });
  };
}

const noticeRunAttributeStepStoreContext = createContext(
  new NoticeRunAttributeStepStore()
);
export const useNoticeRunAttributeStepStore = () => {
  return useContext(noticeRunAttributeStepStoreContext);
};
