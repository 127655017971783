import { mockNewMeter } from "@app/products/property/meters/list/components/action-bar/dialogs/new-meter/mock";
import { sleep } from "@common/utils/common";

export const loadNewMeter = async () => {
  await sleep(300);
  return mockNewMeter;
};

export const loadToCheckNewMeter = async () => {
  await sleep(300);
  return "126";
};
