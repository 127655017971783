import React from "react";
import "./_index.scss";
export interface ICCNote {
  label?: string | JSX.Element;
  message: string | JSX.Element;
}
export const CCNote = ({ label = "Note:", message }: ICCNote) => {
  return (
    <div className={"cc-note"}>
      <div className={"cc-note-label"}> {label} </div>
      <div className={"cc-note-message"}> {message} </div>
    </div>
  );
};
