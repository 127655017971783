import {
  apiCoreGetInspectionType,
  apiCoreGetInspectionTypeLovs,
} from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { TApiInspectionType } from "@common/pages/settings/lookups/inspection-types/_id/model";
import { CancelToken } from "axios";

export const getInspectionTypeById = async (
  inspectionTypeId: number,
  cancelToken: CancelToken
): Promise<APIResponse<TApiInspectionType["Value"]> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<TApiInspectionType["Value"]>(
      apiCoreGetInspectionType(inspectionTypeId),
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const getInspectionTypeLOVs = async (
  cancelToken: CancelToken
): Promise<APIResponse<TApiInspectionType["Lovs"]> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<TApiInspectionType["Lovs"]>(
      apiCoreGetInspectionTypeLovs(),
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
