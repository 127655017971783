import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";
export interface DTO_Workflow_CreateDebtRecovery {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_CreateDebtRecovery;
}

export interface DTO_WorkflowDetail_CreateDebtRecovery {
  DebtRecovery: DTO_Workflow_Debt_Recovery;
}

export interface DTO_Workflow_Debt_Recovery {
  DebtRecoveryDate: Date;
  Assessment: DTO_Assessment;
  DueDateUpTo: Date;
  DebtRecoveryTypeId: number;
  SolicitorReference: string;
  ResponsibleOfficer: string;
  IncludeOtherAssessment: boolean;
}

export interface DTO_Assessment {
  AssessmentId: number;
  AssessmentReference: string;
  PropertyNameAddressLocality: string;
  RatePayerId: number | null;
  RatePayerName: string;
  CombinedString: string;
}

export interface DTO_DebtRecovery_LOVs {
  dtoDebtRecovery_LOVs: DTO_Create_DebtRecovery_LOVs;
}

export interface DTO_Create_DebtRecovery_LOVs {
  DebtRecoveryType: DTO_LOV[];
  DebtRecoveryStatus: DTO_LOV[];
  ReasonForChange?: DTO_LOV[];
  Financial?: DTO_LOV[];
}

export enum EKeysOfSteps {
  Details = "DebtRecovery",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfSendSteps = [EKeysOfSteps.Details];
