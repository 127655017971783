import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  INFRINGEMENTS_SYSTEM_VALUE,
  IOptionsDefault,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/infringements-notices/forms/infringement-integration-settings/model";
import { FormRenderProps } from "@progress/kendo-react-form";

export const optionsInfringementIntegrationSettings = (
  options: IOptionsDefault,
  formRenderProps: FormRenderProps
) => {
  const { valueGetter } = formRenderProps;
  // Infringements System:
  switch (
    valueGetter(
      ECorporateSettingsField.CorporateSettings_InfringementSystem.toString()
    )
  ) {
    case INFRINGEMENTS_SYSTEM_VALUE.None:
      options.showField = false;
      break;
    case INFRINGEMENTS_SYSTEM_VALUE.Infringe:
      options.showField = [
        ECorporateSettingsField.CorporateSettings_InfringementConnectionString, // Connection String
        ECorporateSettingsField.CorporateSettings_InfringementCustomQueryOSBalance, // Outstanding Balance Custom Query
      ].includes(options.enumFieldName);
      if (
        ECorporateSettingsField.CorporateSettings_InfringementCustomQueryOSBalance ===
        options.enumFieldName
      )
        options.typeField = "textArea";
      break;
    case INFRINGEMENTS_SYSTEM_VALUE.Custom:
      options.showField = true; // Show all
      if (
        [
          ECorporateSettingsField.CorporateSettings_InfringementCustomQueryOSBalance,
          ECorporateSettingsField.CorporateSettings_InfringementSystemImportCustomQuery,
        ].includes(options.enumFieldName)
      )
        options.typeField = "textArea";
      break;
  }

  if (
    [
      ECorporateSettingsField.CorporateSettings_InfringementSystem, // Infringements System
    ].includes(options.enumFieldName)
  ) {
    options.showField = true;
  }
  return options;
};
