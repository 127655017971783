import { loadCSLSystemAdminConditonsById } from "@app/products/csl/system-admin/conditions/[id]/api";
import { CSLSystemAdminConditionsForm } from "@app/products/csl/system-admin/conditions/[id]/components/child-screens/general/_index";
import { CSLSystemAdminConditionsFormData } from "@app/products/csl/[id]/model";
import { WastewaterRoute } from "@app/products/waste-water/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { splitCamelCase } from "@common/utils/formatting";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export interface IExistManagecCSLSystemAdminConditionsProps {
  isLoading: boolean;
  setIsLoading: (status: boolean) => void;
  cslSystemAdminConditionsInfo: CSLSystemAdminConditionsFormData | undefined;
}

const ExistManageCSLSystemAdminConditions = ({
  isLoading,
  setIsLoading,
}: IExistManagecCSLSystemAdminConditionsProps) => {
  const history = useHistory();
  const [cslSystemAdminConditionsInfo, setCSLSystemAdminConditionsInfo] =
    useState<CSLSystemAdminConditionsFormData>();
  const { id } = useParams<{ id: string }>();
  const { currentUserInfo } = useGlobalStore();
  const managePageUrl = window.location.pathname;

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `CSL - System Admin - Conditions - ${id}`,
      Text: "Conditions Reference",
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CSM,
      //TODO don't have exactly record type, temporary use CSM_Licence
      RecordType_ENUM: RECORDTYPE.CSM_Licence,
      Record_ID: +id ?? 0,
    },
  ];

  useEffectOnce(() => {
    setIsLoading(true);
    loadCSLSystemAdminConditonsById().then((data) => {
      if (!data) {
        return history.replace(WastewaterRoute.WASTEWATER_SYSTEMS_REGISTER);
      }
      setCSLSystemAdminConditionsInfo(data);
      setIsLoading(false);
    });
  });

  return (
    <>
      <Loading isLoading={isLoading} />
      <FormNavigation title="Conditions Reference" />
      <FormTitle
        title={"Condition Reference"}
        badge={splitCamelCase(cslSystemAdminConditionsInfo?.Condition || "")}
      />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
          <CCNavButton title={"Cancel"} />,
        ]}
        rightActions={[<ActionBarBookmarkIcon bookmarkList={bookmarkList} />]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <div className="cc-manage-form-body">
            {cslSystemAdminConditionsInfo && (
              <CCGeneralPanel
                component={
                  <CSLSystemAdminConditionsForm
                    isNew={false}
                    setIsLoading={setIsLoading}
                    formData={cslSystemAdminConditionsInfo}
                  />
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default observer(ExistManageCSLSystemAdminConditions);
