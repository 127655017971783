import { odataCoreGetContactListNARWithDetailSearch } from "@app/core/components/common/utils";
import { generateColNARContactGrid } from "@app/core/contacts/components/dialogs/contacts-from-nar/config";
import {
  Contact_PickContactNAR_Search,
  SUBMIT_TYPE_NAR_CONTACT,
  Svc_Contact_PickContactNAR,
  Svc_Contact_PickContactNAR_Request,
} from "@app/core/contacts/components/dialogs/contacts-from-nar/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { NARAPISYSTEM, NARSYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import "./_index.scss";

const nameOf = nameOfFactory<Svc_Contact_PickContactNAR>();
const nameOfSearch = nameOfFactory<Contact_PickContactNAR_Search>();

export interface IContactFromNarDialogProps {
  onCloseDialog: () => void;
  onSubmitNARContact: (
    narContactRequest: Svc_Contact_PickContactNAR_Request
  ) => void;
}

export const ContactFromNarDialog = observer(
  ({ onCloseDialog, onSubmitNARContact }: IContactFromNarDialogProps) => {
    const { settings } = useCommonCoreStore();

    const narSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_NARSystem]
    );
    const detailSearchEnabled = getBoolValueSetting(
      settings[ECorporateSettingsField.NAR_DetailSearchEnabled]
    );
    const narApiSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_NARAPISystem]
    );
    const useApi = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_NARAPI]
    );
    const IsCopyContactEnabled = getBoolValueSetting(
      settings[ECorporateSettingsField.Global_ContactManager_AllowCopyContact]
    );

    const [selectedRows, setSelectedRows] = useState<
      Svc_Contact_PickContactNAR[]
    >([]);

    const [searchOdataUrl, setSearchOdataUrl] = useState("");
    // TODO: Implement later
    // const [searchText, setSearchText] = useState("");

    const showDOBFilter = useMemo(() => {
      if (narSystem === NARSYSTEM.CustomDynamic) {
        return true;
      } else {
        return getBoolValueSetting(
          settings[
            ECorporateSettingsField.CorporateSettings_NAR_Custom_DOBFilter
          ]
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [narSystem]);

    const showNARNumberFilter = useMemo(() => {
      if (narSystem === NARSYSTEM.CustomDynamic) {
        return true;
      } else {
        return getBoolValueSetting(
          settings[
            ECorporateSettingsField.CorporateSettings_NAR_Custom_NARNumberFilter
          ]
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [narSystem]);

    const showDetailSearchEnabled = useMemo(() => {
      if (
        narSystem === NARSYSTEM.CustomDynamic ||
        (useApi && narApiSystem !== NARAPISYSTEM.None)
      ) {
        return true;
      } else {
        return detailSearchEnabled;
      }
    }, [narSystem, narApiSystem, useApi, detailSearchEnabled]);

    // TODO: Implement later
    // const handleSearchChange = (event: InputChangeEvent) => {
    //   setSearchText(event.value);
    // };

    const handleSubmit = (event: FormSubmitClickEvent) => {
      setSelectedRows([]);
      setSearchOdataUrl(
        odataCoreGetContactListNARWithDetailSearch(event.values)
      );
    };

    const handlePickContact = () => {
      if (selectedRows.length > 0)
        onSubmitNARContact({
          ContactID: selectedRows[0].ContactID,
          SubmitType: SUBMIT_TYPE_NAR_CONTACT.DIALOG_OK,
        });
    };

    const handleCopyContact = () => {
      if (selectedRows.length > 0)
        onSubmitNARContact({
          ContactID: selectedRows[0].ContactID,
          SubmitType: SUBMIT_TYPE_NAR_CONTACT.COPYCONTACTALL,
        });
    };

    // TODO: Implement later
    // useEffect(() => {
    //   const timeOutId = setTimeout(() => {
    //     setSearchOdataUrl(odataGetContactListNARWithDefaultSearch(searchText));
    //   }, 300);
    //   return () => {
    //     clearTimeout(timeOutId);
    //   };
    // }, [searchText]);

    return (
      <Form
        onSubmitClick={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit } = formRenderProps;
          return (
            <CCDialog
              maxWidth="65%"
              height="auto"
              titleHeader={"Pick Contact From NAR"}
              onClose={onCloseDialog}
              bodyElement={
                <FormElement>
                  <div className="cc-form cc-contact-from-nar-dialog">
                    <div className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <CCLabel title="Search" />
                          {/* TODO: Implement later */}
                          {/* {!showDetailSearchEnabled && (
                          <CCInput onChange={handleSearchChange} />
                        )} */}
                        </div>
                      </div>
                    </div>

                    {showDetailSearchEnabled && (
                      <div className="cc-field-group">
                        <div className="cc-nar-contact-form-row">
                          {showNARNumberFilter && (
                            <div className="cc-field">
                              <CCLabel title="NAR number" />
                              <Field
                                name={nameOfSearch("ContactID")}
                                component={CCInput}
                              />
                            </div>
                          )}
                          <div className="cc-field">
                            <CCLabel title="First name" />
                            <Field
                              name={nameOfSearch("FirstName")}
                              component={CCInput}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="Last name / organisation" />
                            <Field
                              name={nameOfSearch("LastName")}
                              component={CCInput}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="Address" />
                            <Field
                              name={nameOfSearch("Address")}
                              component={CCInput}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="Phone" />
                            <Field
                              name={nameOfSearch("HomePhone")}
                              component={CCInput}
                            />
                          </div>
                          {showDOBFilter && (
                            <div className="cc-field">
                              <CCLabel title="Date of birth" />
                              <Field
                                name={nameOfSearch("DateOfBirth")}
                                component={CCDatePicker}
                                format={DATE_FORMAT.DATE_CONTROL}
                              />
                            </div>
                          )}
                          <div className="cc-contact-field-submit">
                            <Button
                              themeColor="primary"
                              onClick={onSubmit}
                              type="button"
                            >
                              Find
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}

                    <CCGrid
                      className="cc-grid-contact-nar"
                      columnFields={generateColNARContactGrid(
                        narSystem,
                        detailSearchEnabled,
                        useApi,
                        showDOBFilter
                      )}
                      selectableMode="single"
                      primaryField={nameOf("ContactID")}
                      dataUrl={searchOdataUrl}
                      selectedRows={selectedRows}
                      onSelectionChange={(
                        dataItem: Svc_Contact_PickContactNAR[]
                      ) => {
                        setSelectedRows(dataItem);
                      }}
                    />
                  </div>
                </FormElement>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onCloseDialog}>
                    Cancel
                  </Button>
                  {IsCopyContactEnabled && (
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      onClick={handleCopyContact}
                      disabled={selectedRows.length === 0}
                    >
                      Copy contact
                    </Button>
                  )}
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    onClick={handlePickContact}
                    disabled={selectedRows.length === 0}
                  >
                    Select
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
