import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import {
  Field,
  FieldValidatorType,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";

export interface ISwitchSettings {
  data: SettingField | undefined;
  isEditing: boolean;
  isFieldVisible?: boolean;
  formRenderProps: FormRenderProps | undefined;
  validator?: FieldValidatorType | FieldValidatorType[];
}

export const SwitchSettings = ({
  data,
  isEditing,
  isFieldVisible = true,
  formRenderProps,
  validator,
}: ISwitchSettings) => {
  return data && isFieldVisible ? (
    <div className="cc-field">
      <label className="cc-label">
        {data?.Title ?? ""}
        {data?.IsMandatory && <CCTooltip type="validator" position="right" />}
        {data?.HelpText && (
          <CCTooltip
            type="custom"
            content=" "
            position="auto"
            clickToOpen
            customTemplate={<>{sanitizeHtml(data.HelpText)}</>}
          >
            <i className="fa fa-info-circle ml-1 text-accent" />
          </CCTooltip>
        )}
      </label>
      <Field
        name={data?.FieldName}
        component={CCSwitch}
        disabled={!isEditing}
        checked={formRenderProps?.valueGetter(data?.FieldName)}
        validator={validator}
      />
    </div>
  ) : null;
};
