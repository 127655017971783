import { history } from "@/AppRoutes";
import { getComment } from "@app/core/comments/components/dialogs/manage-comment/api";
import { ManageComment } from "@app/core/comments/components/dialogs/manage-comment/_index";
import { Comment } from "@app/core/comments/model";
import { KeyValuePair } from "@app/core/documents/model";
import { getKeywords } from "@common/apis/coreKeyword";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import {
  colSearchProperty,
  colSearchResults,
} from "@common/pages/search/components/search-results/config";
import { defaultSearchFilter } from "@common/pages/search/config";
import { searchTextValidator } from "@common/pages/search/util";
import { useGlobalStore } from "@common/stores/global/store";
import { nameOfFactory } from "@common/utils/common";
import { getPageLinkByRecordType } from "@common/utils/getPageLinkByRecordType";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { PRODUCT_LIST_VIEW_TAKE } from "@components/cc-product-list-view/config";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { getParentAttachment } from "@components/layout/cc-header/components/app-search/components/item-search-result/api";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-use";
import { getSearchHomePage, getSearchProperty } from "./api";
import {
  DTO_ApplicationSearch_Item,
  eApplicationSearchItemType,
  IListingProps,
  ISearchForm,
} from "./model";
import "./_index.scss";

const nameOfForm = nameOfFactory<ISearchForm>();
const nameOfSearchHome = nameOfFactory<IListingProps>();
const nameOfSearchProperty = nameOfFactory<DTO_ApplicationSearch_Item>();

export const Search = observer(() => {
  const { productsActive } = useGlobalStore();
  const location = useLocation();
  const [isGridLoading, setIsGridLoading] = useState(false);
  const [showManageCommentDialog, setShowManageCommentDialog] =
    useState<boolean>(false);
  const [isLoadingComment, setIsLoadingComment] = useState<boolean>(false);
  const [commentInfo, setCommentInfo] = useState<Comment>();
  const [searchMode, setSearchMode] = useState<"home" | "property">("home");
  const [dataDropdown, setDataDropdown] = useState<KeyValuePair<any, any>[]>(
    []
  );
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const [gridData, setGridData] = useState<
    IListingProps[] | DTO_ApplicationSearch_Item[]
  >();

  const processColSearchResults = useMemo(
    () =>
      colSearchResults.map((col) => {
        if (col.field === nameOfSearchHome("ID")) {
          // Only for record type comment
          col.handleOnClick = (dataItem: IListingProps) => {
            if (
              dataItem?.RecordType_ENUM &&
              dataItem?.ID &&
              dataItem?.RecordType_ENUM === RECORDTYPE.CORE_Comment
            ) {
              setIsLoadingComment(true);
              setShowManageCommentDialog(true);
              getComment(dataItem?.ID ?? 0, dataItem.RecordType_ENUM).then(
                (response) => {
                  setIsLoadingComment(false);
                  if (isSuccessResponse(response) && dataItem.ID) {
                    if (response?.data) setCommentInfo(response?.data);
                  } else {
                    setNotifications([
                      {
                        id: "1",
                        autoClose: false,
                        title: "Comment load failed",
                        type: "error",
                      },
                    ]);
                  }
                }
              );
            }
            if (
              dataItem?.RecordType_ENUM &&
              dataItem?.ID &&
              (dataItem?.RecordType_ENUM === RECORDTYPE.CORE_Attachment ||
                dataItem?.RecordType_ENUM === RECORDTYPE.CORE_WebLink)
            ) {
              getParentAttachment(dataItem.ID, dataItem.RecordType_ENUM).then(
                (response) => {
                  if (isSuccessResponse(response) && response?.data) {
                    const linkManagePage = getPageLinkByRecordType(
                      response.data.RecordType_ENUM,
                      response.data.RecordID
                    );
                    if (linkManagePage) history.push(linkManagePage);
                  }
                }
              );
            }
          };
          // for all remaining case
          col.linkTo = (dataItem: IListingProps) => {
            if (
              dataItem?.RecordType_ENUM &&
              dataItem?.ID &&
              dataItem?.RecordType_ENUM !== RECORDTYPE.CORE_Comment
            ) {
              return getPageLinkByRecordType(
                dataItem.RecordType_ENUM,
                dataItem.ID
              );
            }
            return undefined;
          };
        }

        return col;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const searchData: ISearchForm = location?.state?.state;
  const initialFormData = {
    FilterGroup: searchData?.FilterGroup ?? defaultSearchFilter,
    SearchText: searchData?.SearchText ?? "",
    MaximumResult: 0,
  };

  const handleSearch = (values: any) => {
    const data = values as ISearchForm;
    setIsGridLoading(true);
    if (data.FilterGroup?.Key === PRODUCT_TYPE.CommunityProperty) {
      setSearchMode("property");
      getSearchProperty(data.SearchText ?? "").then((data) => {
        if (data) {
          //Check No_Matches_Found item
          if (
            data.Items.length === 1 &&
            data.Items[0].SearchItemType ===
              eApplicationSearchItemType.No_Matches_Found
          ) {
            setGridData([]);
          } else {
            setGridData(data.Items);
          }
        }
        setIsGridLoading(false);
      });
    } else {
      setSearchMode("home");
      getSearchHomePage({ ...data, FilterGroup: data.FilterGroup?.Value }).then(
        (response) => {
          const searchResults = response?.Listing || [];
          setGridData(searchResults);
          setIsGridLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    let newData: KeyValuePair<any, any>[] = [defaultSearchFilter];
    const propertyProduct = productsActive.find(
      (product) => product.ProductType_ENUM === PRODUCT_TYPE.CommunityProperty
    );
    if (propertyProduct) {
      newData.push({
        Key: propertyProduct.ProductType_ENUM,
        Value: propertyProduct.ProductType_Name,
      });
    }
    getKeywords(
      KEYWORD_TYPE.Core_HomePage_ResultsFilter,
      PRODUCT_TYPE_NUMBER.Core
    ).then((response) => {
      if (isSuccessResponse(response)) {
        const keyWords =
          response.data?.map((data) => ({
            Key: data.Keyword_ID,
            Value: data.Name,
          })) ?? [];
        newData = [...newData, ...keyWords];
      }
      setDataDropdown(newData);
    });
    //Call search first time
    handleSearch(initialFormData);
    // eslint-disable-next-line
  }, [productsActive]);

  return (
    <>
      <FormNavigation title="Search" />
      <div className="cc-search-page">
        <div className="cc-search-form">
          <Form
            initialValues={initialFormData}
            onSubmitClick={(event: FormSubmitClickEvent) => {
              if (!event.isValid) return;
              handleSearch(event.values);
            }}
            render={({
              valid,
              onSubmit,
              onChange,
              valueGetter,
            }: FormRenderProps) => {
              const handleOnChangFilter = (event: ComboBoxChangeEvent) => {
                onChange(nameOfForm("FilterGroup"), {
                  value: event?.value ?? null,
                });
                const currentFormValue = valueGetter("");
                handleSearch({
                  ...currentFormValue,
                  [nameOfForm("FilterGroup")]: event?.value ?? null,
                });
              };
              return (
                <FormElement vertical="true">
                  <div className="cc-form">
                    <div className="cc-field-group">
                      <div className="cc-form-cols-4">
                        <div className="cc-field">
                          <label className="cc-label">Search text</label>
                          <div className="cc-search-filter">
                            <div className="cc-search-filter-input">
                              <Field
                                name={nameOfForm("SearchText")}
                                component={CCInput}
                                validator={searchTextValidator}
                              />
                            </div>
                            <Button
                              className="cc-icon-button"
                              iconClass="fal fa-search"
                              onClick={onSubmit}
                              disabled={!valid}
                            />
                          </div>
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Search filter</label>
                          <Field
                            name={nameOfForm("FilterGroup")}
                            component={CCSearchComboBox}
                            data={dataDropdown}
                            textField="Value"
                            dataItemKey="Key"
                            onChange={handleOnChangFilter}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </FormElement>
              );
            }}
          />
        </div>
        <div className="cc-search-result">
          <div className="cc-search-result-title">
            <label className="cc-label">Search results</label>
          </div>
          <div className="cc-search-result-body">
            <CCGrid
              isLoading={isGridLoading}
              itemPerPage={PRODUCT_LIST_VIEW_TAKE}
              groupDragMode={"fixed"}
              pagerType={"input"}
              isAutoHiddenPager={false}
              style={{ height: "100%" }}
              data={isGridLoading ? [] : gridData}
              columnFields={
                searchMode === "home"
                  ? processColSearchResults
                  : colSearchProperty
              }
              primaryField={
                searchMode === "home"
                  ? nameOfSearchHome("ID")
                  : nameOfSearchProperty("Id")
              }
            />
          </div>
        </div>
      </div>
      {showManageCommentDialog && (
        <ManageComment
          isLoading={isLoadingComment}
          comment={commentInfo}
          notifications={notifications}
          onClose={() => setShowManageCommentDialog(false)}
        />
      )}
    </>
  );
});
