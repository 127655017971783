import { useIsNew } from "@common/hooks/useIsNew";
import { CheckPermissionSettingWrapper } from "@common/pages/settings/components/check-permission-wrapper/_index";
import { ExistedLookupActionType } from "@common/pages/settings/lookups/action-types/_id/components/forms/existed/_index";
import { NewLookupActionType } from "@common/pages/settings/lookups/action-types/_id/components/forms/new/_index";
import { ILookupActionTypeNotification } from "@common/pages/settings/lookups/action-types/_id/model";
import { useActionTypesStore } from "@common/pages/settings/lookups/action-types/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageLookupActionTypes = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const location = useLocation();
  const actionTypeId = parseInt(params.id);
  const { resetStore, loadActionType, setActionTypeNotification } =
    useActionTypesStore();
  const { resetMenu } = useCCSubActionBarStore();

  const [isHasPermission, setIsHasPermission] = useState<boolean>(false);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  useEffect(() => {
    if (location.state) {
      setActionTypeNotification(
        location.state as ILookupActionTypeNotification
      );
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line
  }, [isNew]);

  useEffect(() => {
    if (isHasPermission) {
      loadActionType(actionTypeId, isNew);
    }
  }, [actionTypeId, isNew, loadActionType, isHasPermission]);

  return (
    <CheckPermissionSettingWrapper setIsHasPermission={setIsHasPermission}>
      {isNew ? <NewLookupActionType /> : <ExistedLookupActionType />}
    </CheckPermissionSettingWrapper>
  );
};

export default observer(ManageLookupActionTypes);
