import { OfficerAndOfficerRegion } from "@app/products/property/components/fields/officer-and-officer-region/_index";
import { nameOfLov } from "@app/products/property/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";

export const GeneralFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {
    isReadOnly: false,
    workflowLOVs: undefined,
    workflowDraftId: 0,
  },
}: IFormStepElement) => {
  return (
    <>
      <OfficerAndOfficerRegion
        formRenderProps={formRenderProps}
        nameOf={nameOf}
        isDisabled={options?.isReadOnly || options?.workflowDraftId}
      />
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Applicant name" />
            <Field
              name={nameOf("Applicant")}
              placeholder="Applicant name"
              component={CCInput}
              readOnly={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Reason for change" isMandatory />
            <Field
              name={nameOf("Reason_Id")}
              component={CCSearchComboBox}
              data={options?.workflowLOVs?.Change_Reason ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              isUseDefaultOnchange
              disabled={options?.isReadOnly}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
            />
          </div>

          <div className="cc-field">
            <CCLabel title="Reference number" />
            <Field
              name={nameOf("Reference")}
              placeholder="Reference number"
              component={CCInput}
              readOnly={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Split date" />
            <Field
              name={nameOf("Association_Date")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
      </section>
    </>
  );
};
