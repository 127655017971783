import { ManagePageEventType } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useRefreshData/config";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { getUUID } from "@common/utils/common";
import React, { ComponentType, useState } from "react";

export const managePagePropertyWrapper = (
  WrappedComponent: ComponentType<any>
) => {
  return (props: ComponentType<any>) => {
    const [keyRender, setKeyRender] = useState(getUUID);
    useAddUniqueEventEmitter([
      {
        eventType: ManagePageEventType.RefreshData,
        listener: async (callBack: Function) => {
          setKeyRender(getUUID());
          if (callBack) await callBack();
        },
      },
    ]);
    return (
      <React.Fragment key={keyRender}>
        <WrappedComponent {...props} />
      </React.Fragment>
    );
  };
};
