import { colDocuments } from "@app/core/documents/config";
import { DOCUMENT_STATUS_NUNBER, IDocuments } from "@app/core/documents/model";
import { getDocumentsByIdAndProductType } from "@app/products/town-planning/ppr/components/action-bar/dialogs/send-for-approval/api";
import {
  ISendForApproval,
  ISendForApprovalParentData,
} from "@app/products/town-planning/ppr/components/action-bar/dialogs/send-for-approval/model";
import { sendForApprovalValidation } from "@app/products/town-planning/ppr/components/action-bar/dialogs/send-for-approval/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { nameOfFactory } from "@common/utils/common";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export interface ISendForApprovalDialogProps {
  onClose: () => void;
  onSubmit: (value: ISendForApproval) => void;
  parentData: ISendForApprovalParentData;
  isSubmitLoading?: boolean;
  notifications?: IAppNotificationItem[];
}

const nameOfGrid = nameOfFactory<IDocuments>();
const nameOf = nameOfFactory<ISendForApproval>();

export const SendForApprovalDialog = ({
  onClose,
  onSubmit,
  isSubmitLoading = false,
  parentData,
  notifications,
}: ISendForApprovalDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [documentsData, setDocumentsData] = useState<IDocuments[]>([]);
  const [errorResponse, setErrorResponse] = useState<APIResponseError>();
  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    onSubmit({
      SelectedDocumentIDs: event.values?.SelectedDocumentIDs.map(
        (document: IDocuments) => document.ID
      ),
    } as ISendForApproval);
  };

  const loadDocument = async () => {
    setIsLoading(true);
    if (!parentData.Id) return;
    const response = await getDocumentsByIdAndProductType(
      parentData.Id,
      parentData.recordType,
      DOCUMENT_STATUS_NUNBER.Draft
    );
    let errorResponse = undefined;
    if (isSuccessResponse(response) && response.data) {
      setDocumentsData(response.data);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    setErrorResponse(errorResponse);
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadDocument();
  });

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      validator={sendForApprovalValidation}
      render={(formRenderProps: FormRenderProps) => {
        const { onChange, modified, valid, onSubmit } = formRenderProps;

        return (
          <FormElement>
            <CCDialog
              maxWidth="70%"
              height="auto"
              titleHeader={"View Attachments"}
              onClose={onClose}
              disabled={isLoading}
              bodyElement={
                <div className="cc-form">
                  <CCLocalNotification defaultNotifications={notifications} />
                  {errorResponse ? (
                    <CCLoadFailed
                      responseError={errorResponse}
                      onReload={() => loadDocument()}
                    />
                  ) : (
                    <CCGrid
                      isLoading={isLoading}
                      selectableMode="multiple"
                      columnFields={colDocuments}
                      primaryField={nameOfGrid("ID")}
                      data={documentsData}
                      isAutoHiddenPager={false}
                      onSelectionChange={(selectedRows: IDocuments[]) => {
                        onChange(nameOf("SelectedDocumentIDs"), {
                          value: selectedRows,
                        });
                      }}
                    />
                  )}
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    className={"cc-dialog-button"}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!modified || !valid}
                    iconClass={isSubmitLoading ? "fas fa-spinner fa-spin" : ""}
                    themeColor="primary"
                    className={"cc-dialog-button"}
                    onClick={onSubmit}
                  >
                    Select
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
