import {
  DTO_AssessmentCharge,
  DTO_AssessmentChargeBalances,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/model";
import { DTO_ChargeInstallments } from "./../../../model";

export const getChargeBalancesData = (listData: any) => {
  return listData
    ?.flatMap(
      (assessmentCharge: DTO_AssessmentCharge) =>
        assessmentCharge?.AssessmentChargeBalances
    )
    ?.filter(
      (assessmentChargeBalance: DTO_AssessmentChargeBalances) =>
        // Get data row is edited
        assessmentChargeBalance?.CB_AmendBalanceBy !== 0 ||
        assessmentChargeBalance?.CB_AmendInterestBy !== 0 ||
        assessmentChargeBalance?.CB_AmendedBalance !== 0 ||
        assessmentChargeBalance?.CB_AmendedInterest !== 0
    );
};

export const getChargeInstalmentData = (listData: any) => {
  return listData
    ?.flatMap(
      (assessmentCharge: DTO_AssessmentCharge) =>
        assessmentCharge?.Charge_Installments
    )
    ?.filter((chargeInstallments: DTO_ChargeInstallments) => {
      return chargeInstallments?.Charge_Instalment_Id !== null;
    });
};
