import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";

export const infringementsRoute: ICCRoute = {
  path: "infringements",
  name: "Infringements",
  enum: eProductType.Infringements,
  children: [
  ],
};
