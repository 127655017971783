import { WorkflowTypes } from "@app/products/property/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewCertificateButton = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();
  return (
    <>
      <CCNavButton
        title="New certificate"
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Certificate_Create,
              data: {},
            },
          ]);
        }}
      />
    </>
  );
});
