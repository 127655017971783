import { ServiceStandardView } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/details/model";
import { ServiceStandardPickListPacket } from "@app/products/crms/service-standards/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { isNil } from "lodash";

const nameOf = nameOfFactory<ServiceStandardView>();

export const processFilter = (
  articleID: number | undefined,
  categoryFilter: ServiceStandardPickListPacket | undefined
) => {
  const newFilter = [];
  if (!isNil(categoryFilter?.Flag_CRMSChoice))
    newFilter.push({
      field: nameOf("DisplayAsCRMSChoice"),
      operator: "eq",
      value: categoryFilter?.Flag_CRMSChoice ? "Yes" : "No",
    });

  if (!isNil(categoryFilter?.Flag_ITSupport))
    newFilter.push({
      field: nameOf("Flag_ITSupport"),
      operator: "eq",
      value: categoryFilter?.Flag_ITSupport ? "Yes" : "No",
    });

  if (!isNil(categoryFilter?.Flag_HardRubbish)) {
    newFilter.push({
      field: nameOf("Flag_HardRubbish"),
      operator: "eq",
      value: categoryFilter?.Flag_HardRubbish ? "Yes" : "No",
    });

    if (!isNil(categoryFilter?.Flag_HardRubbishPicker)) {
      newFilter.push({
        field: nameOf("Flag_HardRubbishPicker"),
        operator: "eq",
        value: categoryFilter?.Flag_HardRubbishPicker ? "Yes" : "No",
      });
    }
  }

  if (!isNil(categoryFilter?.Flag_Payroll))
    newFilter.push({
      field: nameOf("Flag_Payroll"),
      operator: "eq",
      value: categoryFilter?.Flag_Payroll ? "Yes" : "No",
    });

  if (!isNil(categoryFilter?.IsAnimal))
    newFilter.push({
      field: nameOf("IsAnimal"),
      operator: "eq",
      value: categoryFilter?.IsAnimal ? "Yes" : "No",
    });

  if (!isNil(categoryFilter?.IsKennels))
    newFilter.push({
      field: nameOf("IsKennels"),
      operator: "eq",
      value: categoryFilter?.IsKennels ? "Yes" : "No",
    });

  if (!isNil(categoryFilter?.IsHealth))
    newFilter.push({
      field: nameOf("IsHealth"),
      operator: "eq",
      value: categoryFilter?.IsHealth ? "Yes" : "No",
    });

  if (!isNil(categoryFilter?.HasWasteWater))
    newFilter.push({
      field: nameOf("HasWasteWater"),
      operator: "eq",
      value: categoryFilter?.HasWasteWater ? "Yes" : "No",
    });

  if (!isNil(categoryFilter?.IsLocalLaws))
    newFilter.push({
      field: nameOf("IsLocalLaws"),
      operator: "eq",
      value: categoryFilter?.IsLocalLaws ? "Yes" : "No",
    });

  if (!isNil(categoryFilter?.IsInfringement))
    newFilter.push({
      field: nameOf("IsInfringement"),
      operator: "eq",
      value: categoryFilter?.IsInfringement ? "Yes" : "No",
    });

  if (!isNil(categoryFilter?.HasControlledSubstances))
    newFilter.push({
      field: nameOf("HasControlledSubstance"),
      operator: "eq",
      value: categoryFilter?.HasControlledSubstances ? "Yes" : "No",
    });

  if (!isNil(categoryFilter?.IsParkingPermit))
    newFilter.push({
      field: nameOf("IsParkingPermit"),
      operator: "eq",
      value: categoryFilter?.IsParkingPermit ? "Yes" : "No",
    });

  if (!isNil(categoryFilter?.IsBuilding))
    newFilter.push({
      field: nameOf("IsBuilding"),
      operator: "eq",
      value: categoryFilter?.IsBuilding ? "Yes" : "No",
    });

  if (!isNil(categoryFilter?.IsTownPlanning))
    newFilter.push({
      field: nameOf("IsTownPlanning"),
      operator: "eq",
      value: categoryFilter?.IsTownPlanning ? "Yes" : "No",
    });

  if (!isNil(categoryFilter?.IsLandMangement))
    newFilter.push({
      field: nameOf("IsLandMangement"),
      operator: "eq",
      value: categoryFilter?.IsLandMangement ? "Yes" : "No",
    });

  if (!isNil(categoryFilter?.Flag_System))
    newFilter.push({
      field: nameOf("Flag_System"),
      operator: "eq",
      value: categoryFilter?.Flag_System ? "Yes" : "No",
    });

  if (articleID)
    newFilter.push({
      field: nameOf("Flag_HardRubbishPicker"),
      operator: "eq",
      value: categoryFilter?.Flag_HardRubbishPicker ? "Yes" : "No",
    });
  return newFilter;
};
