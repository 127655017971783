import { loadViewConfiguresColumns } from "@app/products/property/api";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ViewConfiguration, WorkflowTypes } from "@app/products/property/model";
import { SupplementaryRateAssessmentDetail } from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/components/detail/_index";
import { colSupplementaryRatesAssessment } from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/config";
import { VO_Supplementary_Assessment } from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/model";
import { getSupplementaryRatesDetailsTabById } from "@app/products/property/supplementary-rates/[id]/components/reference-sidebar/detail/api";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { Label } from "@common/stores/products/config";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ASSESSMENT_ACCORDION_GRID_ID = getUUID();
const nameOf = nameOfFactory<VO_Supplementary_Assessment>();
export const SupplementaryRatesAssessment = observer(() => {
  const { id } = useParams<{ id: string }>();

  const { setListDialog } = usePropertyWorkflowStore();
  const {
    assessmentsSelected,
    setAssessmentsSelected,
    setSupplementaryAssessments,
  } = useSupplementaryRatesStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [processedCols, setProcessedCols] = useState<IColumnFields[]>(
    colSupplementaryRatesAssessment
  );

  //Get label
  const assessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessment
  );

  const loadAssessmentConfigAndSuppDetails = async () => {
    setIsLoading(true);
    let errorResponse = undefined;
    const response = await Promise.all([
      loadViewConfiguresColumns(
        ViewConfiguration.Supplementary_Assessments,
        colSupplementaryRatesAssessment
      ),
      getSupplementaryRatesDetailsTabById(+id),
    ]);
    setIsLoading(false);

    if (Array.isArray(response)) {
      const [responseAssessmentConfig, responseSuppDetails] = response;
      const suppDetailsData = responseSuppDetails?.data;
      if (
        Array.isArray(responseAssessmentConfig) &&
        isSuccessResponse(responseSuppDetails)
      ) {
        setProcessedCols(responseAssessmentConfig);
        setSupplementaryAssessments(suppDetailsData);
      } else {
        let responseError = responseAssessmentConfig as APIResponseError;
        if (!isSuccessResponse(responseSuppDetails)) {
          responseError = responseSuppDetails;
        }
        errorResponse = {
          status: responseError.status,
          error: responseError.error ?? "Load failed",
        };
      }
    } else {
      errorResponse = response as APIResponse;
    }
    setResponseLoadError(errorResponse);
  };

  useEffectOnce(() => {
    loadAssessmentConfigAndSuppDetails();
  });

  //show Loading
  if (isLoading) return <Loading isLoading />;

  //Show load failed and reload
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadAssessmentConfigAndSuppDetails();
        }}
      />
    );
  return (
    <div className="cc-supplementary-rates-assessment">
      <CCGrid
        gridId={ASSESSMENT_ACCORDION_GRID_ID}
        onSelectionChange={(dataItem: VO_Supplementary_Assessment[]) => {
          setAssessmentsSelected(dataItem ? [...dataItem] : []);
        }}
        selectedRows={assessmentsSelected}
        selectableMode="multiple"
        dataUrl={`/odata/property/internal/supplementaryratesassessmentsregister/${id}?$count=true&`}
        columnFields={processedCols}
        primaryField={nameOf("ID")}
        detail={SupplementaryRateAssessmentDetail}
        toolbar={
          <div className="cc-grid-tools-bar">
            <Button
              className="cc-edit-field-button"
              iconClass="fa fa-pencil"
              title={`${assessmentLabel} Adjustments`}
              disabled={assessmentsSelected?.length !== 1}
              onClick={() => {
                setListDialog([
                  {
                    type: WorkflowTypes.AssessmentAdjustment_Supplementary,
                    data: {},
                  },
                ]);
              }}
            />
          </div>
        }
      />
    </div>
  );
});
