import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { DTO_AssociatedItem_Assessment } from "@app/products/property/components/associations/components/assessment/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_Assessment>();

export const colAssociationsAssessment: IColumnFields[] = [
  {
    field: nameOf("AssessmentNumber"),
    title: "Assessment Number",
    linkTo: (dataItem: DTO_AssociatedItem_Assessment) =>
      `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Id}`,
  },
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("PropertyName"),
    title: "Holding Name",
  },
  { field: nameOf("AssessmentReference"), title: "Assessment Reference" },
  {
    field: nameOf("PropertyName_Address_Locality"),
    title: "Property Name Address Locality",
  },
  { field: nameOf("RatepayerName"), title: "Ratepayer Name" },
  { field: nameOf("LegalDescription"), title: "Legal Description" },
  {
    field: nameOf("UnitEntitlement"),
    title: "Unit Entitlement",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("UnitEntitlementPercentage"),
    title: "Unit Entitlement Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("LevyUnitType"),
    title: "Levy Unit Type",
  },
  {
    field: nameOf("UnitTypeEntitlementPercent"),
    title: "Unit Type Entitlement Percent",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("MasterProperty"),
    title: "Master Property",
  },
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InterestBalance"),
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("BalanceOutstanding"),
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("DeferredBalance"),
    title: "Deferred Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("DeferredInterest"),
    title: "Deferred Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("PendingDiscount"),
    title: "Pending Discount",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("BeingPostponed"),
    title: "Being Postponed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("PostponedBalance"),
    title: "Postponed Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("PostponedInterestBalance"),
    title: "Postponed Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  { field: nameOf("AssessmentGroup"), title: "Assessment Group" },
  { field: nameOf("ValuationGroup"), title: "Valuation Group" },
  { field: nameOf("Type"), title: "Type" },
  { field: nameOf("Status"), title: "Status" },
  { field: nameOf("ValuationNumber"), title: "Valuation Number" },
  { field: nameOf("Improvements"), title: "Improvements" },
  { field: nameOf("LandUses"), title: "Land Uses" },
  { field: nameOf("FireLevyLandStatus"), title: "Fire Levy Land Status" },
  {
    field: nameOf("AlertMessage"),
    title: "Alert Message",
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
