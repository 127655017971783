export const mockNoticeRunTable = [
  {
    NameAddress: "N E & J M ZahraPO Box 306 VIC 3782",
    PrincipalBalance: 357.29,
    AmountDue: 357.29,
    OpeningBalance: 357.29,
    OpeningAmountDue: 357.29,
    CurrentAmountDue: 0.0,
    PrincipalArrears: 357.29,
    ArrearsAmountDue: 357.29,
    PrincipalDue: 357.29,
    InterestBalance: 0.0,
    OtherBalances: 0.0,
    BalanceOutstanding: 357.29,
    PayoutAmount: 357.29,
    AccountReference: 32042.0004,
    NoticeNumber: 30,
    Scheme: "Paton Road Special Charge Scheme",
    PropertyAddress: "7 Paton Rd, Emerald",
    AssessmentNumber: 2673200100,
    SchemeAccountId: 679,
    NoticeId: 15125,
  },
];
