import { useSessionListStore } from "@app/products/property/journals/session-list/[id]/store";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { PropertyJournalSessionList } from "./components/form-element/_index";

export const PropertyJournalForm = observer(() => {
  const { sessionList } = useSessionListStore();
  return (
    <div className="cc-form">
      <Form
        // onSubmit={handleSubmit}
        initialValues={sessionList}
        render={(formRenderProps: FormRenderProps) => (
          <PropertyJournalSessionList formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
