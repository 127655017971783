import { ParcelZoneDialog } from "@app/products/property/parcels/[id]/components/child-screens/zones/components/dialogs";
import { colParcelZones } from "@app/products/property/parcels/[id]/components/child-screens/zones/config";
import { DTO_ParcelZone } from "@app/products/property/parcels/[id]/components/child-screens/zones/model";
import { useParcelZonesStore } from "@app/products/property/parcels/[id]/components/child-screens/zones/store";
import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

export const ParcelZones = observer(() => {
  const nameOf = nameOfFactory<DTO_ParcelZone>();
  const { parcelId } = useParcelsStore();
  const { isLoading, loadParcelZones, resetStore, parcelZones } =
    useParcelZonesStore();

  useEffectOnce(() => {
    if (!parcelId) {
      return;
    }
    loadParcelZones(parcelId);
    return () => {
      resetStore();
    };
  });

  return (
    <div className="cc-parcel-zones">
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Parcel zones</label>
              <CCGrid
                isLoading={isLoading}
                className="cc-parcel-zones-grid"
                data={parcelZones?.Zones ?? []}
                columnFields={colParcelZones}
                primaryField={nameOf("Id")}
              />
            </div>
          </div>
        </div>
      </div>
      <ParcelZoneDialog />
    </div>
  );
});
