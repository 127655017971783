import { RegisterAddressPopup } from "@app/products/property/components/register-address-popup/_index";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { ICommonContact } from "@common/pages/contacts/contact-relationship/model";
import { IParentSection } from "@common/pages/contacts/model";
import {
  createDefaultDropdownItem,
  getDropdownValue,
  nameOfFactory,
} from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { Toast } from "@components/toast";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { ContactNameSearch } from "./components/contact-name-search/_index";

const nameOf = nameOfFactory<ICommonContact>();

interface IProps {
  isNew: boolean;
  formRenderProps: FormRenderProps;
}

const ContactRelationshipFormElement = ({ formRenderProps }: IProps) => {
  const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }
    if (!formRenderProps.valid) return;

    Toast.displayToast({
      content: "🚀 The form is not modified !",
      type: "warning",
    });
  };
  const isNew = useIsNew();
  const getValue = formRenderProps.valueGetter;
  const location: any = useLocation();
  const parentSection = location.state?.parentPage;
  const [isOpenAddressPopup, setIsOpenAddressPopup] = useState(false);
  const [showNameSearch, setShowNameSearch] = useState<boolean>(true);

  const salutations = [
    { Key: "1", Value: "Dr" },
    { Key: "2", Value: "Miss" },
    { Key: "3", Value: "Mr" },
    { Key: "4", Value: "Mrs" },
    { Key: "5", Value: "Ms" },
  ];
  const contactTypes = [
    { Key: "1", Value: "Person" },
    { Key: "2", Value: "Company" },
    { Key: "3", Value: "Partnership" },
  ];
  const preferredMethods = [
    { Key: "1", Value: "None" },
    { Key: "2", Value: "Work Phone" },
    { Key: "3", Value: "Home Phone" },
    { Key: "4", Value: "Mobile" },
    { Key: "5", Value: "Email" },
    { Key: "6", Value: "TTY" },
  ];

  const handleNameSearchSelected = (data: any) => {
    setShowNameSearch(false);
    const nameParts = data.DisplayName.split(" ") || [];
    formRenderProps.onChange(nameOf("FirstName"), {
      value: nameParts.slice(0, 1),
    });
    formRenderProps.onChange(nameOf("LastName"), {
      value: nameParts.slice(1).join(" "),
    });
    formRenderProps.onChange(nameOf("DisplayName"), {
      value: data.DisplayName,
    });
    formRenderProps.onChange(nameOf("_HomePhone"), {
      value: data.Phone,
    });
    formRenderProps.onChange(nameOf("Email"), {
      value: data.Email,
    });
  };

  return (
    <FormElement>
      {isOpenAddressPopup && (
        <RegisterAddressPopup
          onClosePopup={() => setIsOpenAddressPopup(false)}
        />
      )}
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />

      <div className="cc-contact-relationship-form-element">
        <section className="cc-field-group">
          {!isNew && (
            <div className="cc-form-cols-3">
              {Array.isArray(parentSection) &&
                parentSection.map((element: IParentSection) => {
                  return (
                    <div className="cc-field" key={element.key}>
                      <label className="c-label">{element.label}</label>

                      <Field
                        disabled={!isNew}
                        autoComplete="off"
                        name={"TagNo"}
                        defaultValue={element.value}
                        component={CCInput}
                      />
                    </div>
                  );
                })}
            </div>
          )}
          {showNameSearch && (
            <ContactNameSearch onSelected={handleNameSearchSelected} />
          )}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Contact type</label>

              <Field
                name={nameOf("ContactClassification_ENUM")}
                textField="Value"
                dataItemKey="Key"
                data={contactTypes}
                component={CCSearchComboBox}
                value={getDropdownValue(
                  getValue(nameOf("ContactClassification_ENUM")),
                  contactTypes,
                  "Key"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  formRenderProps.onChange(
                    nameOf("ContactClassification_ENUM"),
                    {
                      value: event.target.value?.Key ?? null,
                    }
                  );
                }}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Salutation</label>

              <Field
                name={nameOf("Salutation")}
                textField="Value"
                dataItemKey="Key"
                defaultItem={createDefaultDropdownItem("Salutation")}
                data={salutations}
                component={CCSearchComboBox}
                value={getDropdownValue(
                  getValue(nameOf("Salutation")),
                  salutations,
                  "Key"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  formRenderProps.onChange(nameOf("Salutation"), {
                    value: event.target.value?.Key ?? null,
                  });
                }}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                First name <CCTooltip type="validator" position="right" />
              </label>

              <Field
                name={nameOf("FirstName")}
                placeholder={"First Name"}
                component={CCInput}
                validator={validateRequired}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Last name</label>

              <Field
                name={nameOf("LastName")}
                placeholder={"Last Name"}
                component={CCInput}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Organisation</label>

              <Field
                name={nameOf("Organisation")}
                placeholder={"Organisation"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Position</label>

              <Field
                name={"Extension.JobTitle"}
                placeholder={"Position"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">ABN</label>

              <Field
                name={nameOf("ABN")}
                placeholder={"ABN"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">
                Display as (name)
                <CCTooltip type="validator" position="right" />
              </label>

              <Field
                name={nameOf("DisplayName")}
                placeholder={"Display As (Name)"}
                component={CCInput}
                validator={validateRequired}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Preferred method</label>

              <Field
                name={"PreferredMethod_ENUM"}
                textField="Value"
                dataItemKey="Key"
                data={preferredMethods}
                component={CCSearchComboBox}
                isUseDefaultOnchange
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Mobile</label>
              <Field
                name={nameOf("_Mobile")}
                placeholder={"Mobile"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={formRenderProps.onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Mobile}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Home phone</label>
              <Field
                name={nameOf("_HomePhone")}
                placeholder={"Home Phone"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={formRenderProps.onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.HomePhone}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Work phone</label>

              <Field
                name={nameOf("_WorkPhone")}
                placeholder={"Work Phone"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={formRenderProps.onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.WorkPhone}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <Field
                label="Email"
                name={nameOf("Email")}
                placeholder={"Email"}
                type="email"
                component={CCInputEmail}
                validator={validateEmail}
                onChangeForm={formRenderProps.onChange}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Fax</label>
              <Field
                name={nameOf("_Fax")}
                placeholder={"Fax"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={formRenderProps.onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Fax}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-3">
              <label className="cc-label">Website</label>

              <Field
                name={nameOf("Website")}
                placeholder={"Website"}
                component={CCInput}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Date of birth</label>

              <Field
                name={"DateOfBirth"}
                placeholder={"Date of Birth"}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Second language</label>

              <Field
                name={"Extension.SecondLanguage"}
                placeholder={"Second Language"}
                component={CCInput}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">Contact alert</label>

              <Field
                name={"ContactAlert"}
                placeholder={"Contact Alert"}
                component={CCInput}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">Registration number</label>

              <Field
                name={"LicenceNo"}
                placeholder={"Registration Number"}
                component={CCInput}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />

        {!isNew && (
          <>
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">FSS certified?</label>
                  <Field
                    name={"FSSCertificate"}
                    defaultChecked={getValue("FSSCertificate")}
                    component={CCSwitch}
                  />
                </div>

                <div className="cc-field">
                  <label className="cc-label">Certificate expiry date</label>

                  <Field
                    name={"FSSCertificateExpiryDate"}
                    placeholder={"Certificate Expiry Date"}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">FSS certificate number</label>

                  <Field
                    name={"FSSCertificateNo"}
                    placeholder={"FSS Certificate Number"}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />
          </>
        )}

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Postal address</label>

              <Field
                onClick={() => {
                  setIsOpenAddressPopup(true);
                }}
                autoComplete="off"
                name={"PostalAddress"}
                placeholder={"Postal Address"}
                className="disabled"
                component={InputPicker}
              />
            </div>
            {/* <div className="col-8">33 Wellington Road, MULGRAVE VIC 3170</div> */}
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Street address</label>

              <Field
                onClick={() => {
                  setIsOpenAddressPopup(true);
                }}
                autoComplete="off"
                name={"StreetAddress"}
                placeholder={"Street Address"}
                className="disabled"
                component={InputPicker}
              />
            </div>
            {/* <div className="col-8">33 Wellington Road, MULGRAVE VIC 3170</div> */}
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Alternate address</label>

              <Field
                onClick={() => {
                  setIsOpenAddressPopup(true);
                }}
                autoComplete="off"
                name={"AlternateAddress"}
                placeholder={"Alternate Address"}
                className="disabled"
                component={InputPicker}
              />
            </div>
            {/* <div className="col-8">33 Wellington Road, MULGRAVE VIC 3170</div> */}
          </div>
        </section>
      </div>
    </FormElement>
  );
};

export default observer(ContactRelationshipFormElement);
