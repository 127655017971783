import { SETTINGS_LOOKUPS_KEYWORDS_ROUTE } from "@common/pages/settings/lookups/keywords/_id/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

interface NewKeywordButtonProps {
  disabled?: boolean;
}
export const NewKeywordButton = ({ disabled }: NewKeywordButtonProps) => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New"
      onClick={() => {
        history.push(`${SETTINGS_LOOKUPS_KEYWORDS_ROUTE}/new`);
      }}
      disabled={disabled}
    />
  );
};
