import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSSafetyBookmark = {
  getBookmarkListViewDisplayName() {
    return `${CRMS_PRODUCT_NAME} - Unresolved Events`;
  },
  getBookmarkListViewDetail() {
    return `${CRMS_PRODUCT_NAME} - Unresolved Events - Safety`;
  },
};
