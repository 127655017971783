import { useChargeRunsStore } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { formatStyleProminent } from "@common/utils/formatting";
import { CCValueField } from "@components/cc-value-field/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const ChargeRunsTotal = observer(() => {
  const { chargeRun } = useChargeRunsStore();
  //Get label
  const assessmentLowercaseLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.AssessmentLowercase
  );
  return (
    <div className="cc-property-charge-and-notice-runs-charge-runs-totals">
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCValueField
              label={`Number of ${assessmentLowercaseLabel}s`}
              value={chargeRun?.NumberOfAssessments}
              format={NUMBER_FORMAT.NUMBER2}
              style={formatStyleProminent}
            />
            <CCValueField
              label="Number of levies"
              value={chargeRun?.NumberOfLevies}
              format={NUMBER_FORMAT.NUMBER2}
              style={formatStyleProminent}
            />
            <CCValueField
              label="Gross levy amount"
              value={chargeRun?.TotalGrossLevyAmount}
              format={CURRENCY_FORMAT.CURRENCY1}
              style={formatStyleProminent}
            />
            <CCValueField
              label="Rebates"
              value={chargeRun?.TotalRebateAmount}
              format={CURRENCY_FORMAT.CURRENCY1}
              style={formatStyleProminent}
            />
            <CCValueField
              label="Roundings"
              value={chargeRun?.TotalRoundingAmount}
              format={CURRENCY_FORMAT.CURRENCY1}
              style={formatStyleProminent}
            />
            <CCValueField
              label="Nett levy amount"
              value={chargeRun?.TotalNettLevyAmount}
              format={CURRENCY_FORMAT.CURRENCY1}
              style={formatStyleProminent}
            />
            <CCValueField
              label="Discount amount"
              value={chargeRun?.TotalDiscountAmount1}
              format={CURRENCY_FORMAT.CURRENCY1}
              style={formatStyleProminent}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
