import { observer } from "mobx-react-lite";
import { Form } from "@progress/kendo-react-form";
import React from "react";
import { PropertyChargeAndNoticeRunsFormElement } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/components/child-screens/general/components/form-element/_index";
import { usePICChargeRunsStore } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/store";

export const PropertyChargeAndNoticeRunsForm = observer(() => {
  const { picChargeRun } = usePICChargeRunsStore();

  return (
    <div className="cc-form">
      <Form
        initialValues={picChargeRun}
        render={() => <PropertyChargeAndNoticeRunsFormElement />}
      />
    </div>
  );
});
