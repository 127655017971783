import { DTO_MasterProperty_TabDetails } from "@app/products/property/assessments/master-properties/[id]/components/reference-sidebar/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const loadMasterPropertyDetailsTabById = async (
  masterPropertyId?: number
): Promise<APIResponse<DTO_MasterProperty_TabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_MasterProperty_TabDetails>(
      `api/property/internal/masterproperty/${masterPropertyId}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
