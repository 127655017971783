import {
  colActions,
  colFeeTypes,
} from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/child-screens/general/components/form-element/config";
import { IFeeTypesGrid } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/child-screens/general/components/form-element/model";
import { processFeeTypeGridData } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/child-screens/general/components/form-element/util";
import { AddFeeTypesDialog } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/add-fee-types/_index";
import { ILightWeightActionType } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/automatic-action/model";
import { AutomaticActionDialog } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/automatic-action/_index";
import { ApplicationCategory } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/model";
import { useApplicationCategoriesStore } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/store";
import { ApplicationCategoryFee } from "@app/products/town-planning/ppr/[id]/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldProps,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isArray, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";

interface IApplicationCategoryFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<ApplicationCategory>();
const nameOfFeeTypes = nameOfFactory<IFeeTypesGrid>();
const nameOfLightWeightActionType = nameOfFactory<ILightWeightActionType>();

export const ApplicationCategoryFormElement = observer(
  ({ formRenderProps }: IApplicationCategoryFormElementProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { settings } = useCommonProductStore();
    const applicationCategoryFees = valueGetter("ApplicationCategoryFees");
    const workFlowAutoActionTypes = valueGetter("WorkFlowAutoActionTypes");
    const { isInactive } = useApplicationCategoriesStore();
    //@TODO will use if OnShore team change requirement
    // const { pushNotification } = useCCAppNotificationStore();
    // const applicationCategoryId = valueGetter("ApplicationCategory_ID");
    const [isLoadingActionType, setIsLoadingActionType] = useState(false);
    const [isLoadingFeeType, setIsLoadingFeeType] = useState(false);
    const [isShowAddFeeTypeDialog, setIsShowAddFeeTypeDialog] = useState(false);
    const [isShowAutomaticActionDialog, setIsShowAutomaticActionDialog] =
      useState(false);
    const [isShowConfirmDeleteDialog, setIsShowConfirmDeleteDialog] =
      useState(false);
    const [gridDataFeeType, setGridDataFeeType] = useState<IFeeTypesGrid[]>(
      () => {
        if (isArray(applicationCategoryFees)) {
          return processFeeTypeGridData(applicationCategoryFees);
        }
        return [];
      }
    );
    const [gridDataActionType, setGridDataActionType] = useState<
      ILightWeightActionType[]
    >(workFlowAutoActionTypes);
    const [selectedFeeType, setSelectedFeeType] = useState<IFeeTypesGrid[]>([]);
    const [selectedAutomaticAction, setSelectedAutomaticAction] = useState<
      ILightWeightActionType[]
    >([]);
    const flagTownPlanning = valueGetter("TownPlanning") ?? false;
    const flagCertification = valueGetter("IsCertification") ?? false;
    const flagOA = valueGetter("IsOA") ?? false;
    const flagPSA = valueGetter("isPSA") ?? false;
    const flagPPR = valueGetter("IsPPR") ?? false;

    const isEnablePPR = getBoolValueSetting(
      settings[ECorporateSettingsField.TP_Application_PPR]
    );
    const isPSAEnable = getBoolValueSetting(
      settings[ECorporateSettingsField.TP_Application_PSA]
    );
    const isOAEnable = getBoolValueSetting(
      settings[ECorporateSettingsField.TP_Application_OAs]
    );
    const isTargetDaysEnabled = getBoolValueSetting(
      settings[ECorporateSettingsField.TP_StatTargetDaysEnabled]
    );
    const isReadyForDecisionWorkflowEnable = getBoolValueSetting(
      settings[
        ECorporateSettingsField.TP_EnableReadyforDecision_Approval_Workflow
      ]
    );

    const handleSubmitAddFeeType = (value: any) => {
      setIsLoadingActionType(true);
      let newApplicationCategoryFee: ApplicationCategoryFee = {
        CoreLinkId: null,
        Index: 0,
        ApplicationCategoryFeeType_ID: null,
        ApplicationCategoryFeeType_Name: "",
        ApplicationCategoryCalculatedFee_ID: null,
        ApplicationCategoryCalculatedFee_Name: "",
        ApplicationCategoryFee_Amount: null,
        InheritApplicationFeeType: false,
      };
      newApplicationCategoryFee.ApplicationCategoryFeeType_ID =
        value.FeeType.ID;
      newApplicationCategoryFee.ApplicationCategoryFeeType_Name =
        value.FeeType.Name;
      if (value.CalculatedFeeType) {
        newApplicationCategoryFee.ApplicationCategoryFee_Amount = null;
        newApplicationCategoryFee.InheritApplicationFeeType = null;
        newApplicationCategoryFee.ApplicationCategoryCalculatedFee_ID =
          value.CalculatedFeeType.ID;
        newApplicationCategoryFee.ApplicationCategoryCalculatedFee_Name =
          value.CalculatedFeeType.Name;
      } else {
        if (!isNil(value.FeeAmount))
          newApplicationCategoryFee.ApplicationCategoryFee_Amount =
            value.FeeAmount;

        newApplicationCategoryFee.InheritApplicationFeeType = value.InheritFee;
        newApplicationCategoryFee.ApplicationCategoryCalculatedFee_ID = null;
        newApplicationCategoryFee.Index = isArray(applicationCategoryFees)
          ? applicationCategoryFees.length + 1
          : 0;
      }
      if (isArray(applicationCategoryFees)) {
        applicationCategoryFees.push(newApplicationCategoryFee);
        onChange("ApplicationCategoryFees", { value: applicationCategoryFees });
        setGridDataFeeType(processFeeTypeGridData(applicationCategoryFees));
      }
      setIsShowAddFeeTypeDialog(false);
      setIsLoadingActionType(false);
    };

    const handleDelete = async () => {
      const newGridData = workFlowAutoActionTypes.filter(
        (item: ILightWeightActionType) =>
          !selectedAutomaticAction.some(
            (selectedItem: ILightWeightActionType) =>
              item.ID === selectedItem.ID
          )
      );
      setGridDataActionType(newGridData);
      onChange("WorkFlowAutoActionTypes", {
        value: newGridData,
      });
    };

    //set default data for all flag if they aren't exist
    useEffectOnce(() => {
      const flagArray = [
        "TownPlanning",
        "IsCertification",
        "IsOA",
        "isPSA",
        "IsPPR",
      ];
      flagArray.forEach((item) => {
        if (isNil(valueGetter(item))) onChange(item, { value: false });
      });
    });

    const ApplicationCategoryField = useCallback(
      (props: FieldProps) => {
        const { disabled, ...others } = props;
        return (
          <Field {...others} disabled={isInactive ? isInactive : disabled} />
        );
      },
      [isInactive]
    );

    return (
      <FormElement className="cc-application-category-form-elements">
        <input
          type="submit"
          id="btn-submit"
          hidden
          onClick={formRenderProps.onSubmit}
        ></input>
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Name
                <CCTooltip type="validator" position="right" />
              </label>
              <ApplicationCategoryField
                name={nameOf("ApplicationCategory_Name")}
                component={CCInput}
                validator={requiredValidator}
                placeholder={"Name"}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Description</label>
              <ApplicationCategoryField
                name={nameOf("ApplicationCategory_Description")}
                component={CCTextArea}
                rows={3}
                placeholder={"Description"}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            {!isEnablePPR && (
              <div className="cc-field">
                <label className="cc-label">Town planning</label>
                <ApplicationCategoryField
                  name={nameOf("TownPlanning")}
                  component={CCSwitch}
                  checked={flagTownPlanning}
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">Certification</label>
              <ApplicationCategoryField
                name={nameOf("IsCertification")}
                component={CCSwitch}
                checked={flagCertification}
              />
            </div>
            {isPSAEnable && (
              <div className="cc-field">
                <label className="cc-label">
                  {isEnablePPR ? "PSAR" : "PSA"}
                </label>
                <ApplicationCategoryField
                  name={nameOf("isPSA")}
                  component={CCSwitch}
                  checked={flagPSA}
                />
              </div>
            )}
            {isOAEnable && !isEnablePPR && (
              <div className="cc-field">
                <label className="cc-label">OA's</label>
                <ApplicationCategoryField
                  name={nameOf("IsOA")}
                  component={CCSwitch}
                  checked={flagOA}
                />
              </div>
            )}
            {/* missing LOVs API for common, add it later */}
            {flagOA && (
              <div className="cc-field">
                <label className="cc-label">Work flow</label>
                <ApplicationCategoryField
                  name={"WorkFlow"}
                  component={CCSearchComboBox}
                  data={[]}
                />
              </div>
            )}
            {isEnablePPR && (
              <div className="cc-field">
                <label className="cc-label">PPR</label>
                <ApplicationCategoryField
                  name={nameOf("IsPPR")}
                  component={CCSwitch}
                  checked={flagPPR}
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">Code</label>
              <ApplicationCategoryField
                name={nameOf("Code")}
                component={CCInput}
                placeholder={"Code"}
              />
            </div>

            {isTargetDaysEnabled && (
              <div className="cc-field">
                <label className="cc-label">Stat target days</label>
                <ApplicationCategoryField
                  name={nameOf("TargetDays")}
                  component={CCNumericTextBox}
                  format={NUMBER_FORMAT.NUMBER2}
                  placeholder={"Stat target days"}
                />
              </div>
            )}
          </div>
        </section>
        {!isEnablePPR && (
          <>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Fee types</label>

                  <CCGrid
                    isLoading={isLoadingFeeType}
                    columnFields={colFeeTypes}
                    data={gridDataFeeType}
                    primaryField={nameOfFeeTypes("ID")}
                    selectableMode={"multiple"}
                    onSelectionChange={(dataItem: any) =>
                      setSelectedFeeType(dataItem)
                    }
                    toolbar={
                      <div className="cc-grid-tools-bar">
                        <Button
                          iconClass="fas fa-plus"
                          title="Add Fee Type"
                          onClick={() => {
                            setIsShowAddFeeTypeDialog(true);
                          }}
                          type="button"
                        />
                        <Button
                          iconClass="fas fa-minus"
                          disabled={!(selectedFeeType.length > 0) || isInactive}
                          title="Remove Fee Type"
                          onClick={() => {
                            //@TODO will use if OnShore team change requirement
                            // setIsLoadingFeeType(true);
                            // putCoreLinksStatus(
                            //   applicationCategoryId,
                            //   // eslint-disable-next-line array-callback-return
                            //   selectedFeeType.map((fee: any) => {
                            //     if (!isNil(fee.ApplicationCategoryFeeType_ID))
                            //       return fee.ApplicationCategoryFeeType_ID;
                            //   }),
                            //   RECORDTYPE.TP_ApplicationCategory,
                            //   LinkType.TP_ApplicationCategoryFees
                            // ).then((response) => {
                            //   if (isSuccessResponse(response)) {
                            //     const newGridData = gridDataFeeType.filter(
                            //       (item: IFeeTypesGrid) =>
                            //         !selectedFeeType.some(
                            //           (selectedItem: IFeeTypesGrid) =>
                            //             item.ID === selectedItem.ID
                            //         )
                            //     );
                            //     setGridDataFeeType(newGridData);
                            //   } else {
                            //     pushNotification({
                            //       autoClose: false,
                            //       title:
                            //         response?.error ??
                            //         "Fee types delete failed",
                            //       type: "error",
                            //     });
                            //   }
                            //   setIsLoadingFeeType(false);
                            // });
                            const newGridData = gridDataFeeType.filter(
                              (item: IFeeTypesGrid) =>
                                !selectedFeeType.some(
                                  (selectedItem: IFeeTypesGrid) =>
                                    item.ID === selectedItem.ID
                                )
                            );
                            setGridDataFeeType(newGridData);
                            onChange("ApplicationCategoryFees", {
                              value: newGridData,
                            });
                          }}
                          type="button"
                        />
                      </div>
                    }
                  />
                  {isShowAddFeeTypeDialog && (
                    <AddFeeTypesDialog
                      onClose={() => setIsShowAddFeeTypeDialog(false)}
                      onSubmit={handleSubmitAddFeeType}
                      initialData={applicationCategoryFees}
                    />
                  )}
                </div>
              </div>
            </section>
          </>
        )}
        {isReadyForDecisionWorkflowEnable && (
          <>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Actions</label>
                  <CCGrid
                    isLoading={isLoadingActionType}
                    columnFields={colActions}
                    data={gridDataActionType}
                    primaryField={nameOfLightWeightActionType("ID")}
                    selectableMode={"multiple"}
                    onSelectionChange={(dataItem: any) =>
                      setSelectedAutomaticAction(dataItem)
                    }
                    toolbar={
                      <div className="cc-grid-tools-bar">
                        <Button
                          iconClass="fas fa-plus"
                          title="Add Automatic Action"
                          onClick={() => {
                            setIsShowAutomaticActionDialog(true);
                          }}
                          type="button"
                        />
                        <Button
                          iconClass="fas fa-minus"
                          disabled={
                            !(selectedAutomaticAction.length > 0) || isInactive
                          }
                          title="Remove Automatic Action"
                          onClick={() => {
                            setIsShowConfirmDeleteDialog(true);
                            //@TODO will use if OnShore team change requirement
                            // setIsLoadingActionType(true);
                            // putCoreLinksStatus(
                            //   applicationCategoryId,
                            //   selectedAutomaticAction.map(
                            //     (action) => action.ID
                            //   ),
                            //   RECORDTYPE.TP_ApplicationCategory,
                            //   LinkType.TP_Application_Category_AutoAction
                            // ).then((response) => {
                            //   if (isSuccessResponse(response)) {
                            //     const newGridData =
                            //       workFlowAutoActionTypes.filter(
                            //         (item: ILightWeightActionType) =>
                            //           !selectedAutomaticAction.some(
                            //             (
                            //               selectedItem: ILightWeightActionType
                            //             ) => item.ID === selectedItem.ID
                            //           )
                            //       );
                            //     setGridDataActionType(newGridData);
                            //     onChange("WorkFlowAutoActionTypes", {
                            //       value: newGridData,
                            //     });
                            //   } else {
                            //     pushNotification({
                            //       autoClose: false,
                            //       title:
                            //         response?.error ??
                            //         "Action types delete failed",
                            //       type: "error",
                            //     });
                            //   }
                            //   setIsLoadingActionType(false);
                            // });
                          }}
                          type="button"
                        />
                      </div>
                    }
                  />
                  {isShowAutomaticActionDialog && (
                    <AutomaticActionDialog
                      initialData={workFlowAutoActionTypes}
                      onClose={() => setIsShowAutomaticActionDialog(false)}
                      onSubmit={(value: ILightWeightActionType[]) => {
                        setIsLoadingFeeType(true);
                        setGridDataActionType(value);
                        onChange("WorkFlowAutoActionTypes", { value: value });
                        setIsShowAutomaticActionDialog(false);
                        setIsLoadingFeeType(false);
                      }}
                      selectableMode="multiple"
                    />
                  )}
                  {isShowConfirmDeleteDialog && (
                    <ConfirmDialog
                      title="Confirm Delete"
                      subMessage="Are you sure you want to delete this item?"
                      onClosePopup={() => {
                        setIsShowConfirmDeleteDialog(false);
                      }}
                      onConfirmYes={handleDelete}
                    />
                  )}
                </div>
              </div>
            </section>
          </>
        )}
      </FormElement>
    );
  }
);
