import { DTO_Workflow_Supplementary_AddMasterProperty } from "@app/products/property/assessments/master-properties/model";
import { ResponseMessageWorkflow } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { CancelToken } from "axios";

export const getSearchMasterProperty = async (
  url: string,
  cancelToken?: CancelToken
): Promise<APIResponse<any | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<any>(`${url}`, {
      cancelToken,
    });
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postAddMasterProperty = async (
  payload: DTO_Workflow_Supplementary_AddMasterProperty
): Promise<
  APIResponse<
    ResponseMessageWorkflow<DTO_Workflow_Supplementary_AddMasterProperty>
  >
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      "/api/property/internal/workflow/supplementaryMasterProperty/process/2",
      payload
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
