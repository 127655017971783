import { colActionTypePickerDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/add-meeting/config";
import { isSuccessResponse } from "@common/apis/util";
import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import { getMeetingActionTypes } from "@common/pages/actions/api";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IAddMeetingDialogProps {
  onClose: () => void;
  onSubmit: (actionType: IRespActionType) => void;
  isLoading?: boolean;
}

const nameOf = nameOfFactory<IRespActionType>();
export const AddMeetingDialog = observer(
  ({ onClose, onSubmit, isLoading }: IAddMeetingDialogProps) => {
    const [gridSelectedRows, setGridSelectedRows] = useState<IRespActionType[]>(
      []
    );
    const [actionTypeList, setActionTypeList] = useState<IRespActionType[]>();

    const handleOnSubmitClick = () => {
      onSubmit(gridSelectedRows[0]);
    };

    useEffectOnce(() => {
      getMeetingActionTypes().then((response) => {
        if (isSuccessResponse(response)) {
          if (response?.data) {
            setActionTypeList(response.data);
          }
        }
      });
    });

    return (
      <CCDialog
        titleHeader={"Pick Meeting Type"}
        onClose={onClose}
        maxWidth={"45%"}
        height={"auto"}
        disabled={isLoading}
        bodyElement={
          <CCGrid
            columnFields={colActionTypePickerDialog}
            selectedRows={gridSelectedRows}
            primaryField={nameOf("ID")}
            selectableMode="single"
            onSelectionChange={(dataItem: IRespActionType[]) => {
              if (dataItem) setGridSelectedRows([...dataItem]);
            }}
            data={actionTypeList ?? []}
          />
        }
        footerElement={
          <div className="cc-dialog-footer-actions-right">
            <Button className="cc-dialog-button" onClick={onClose}>
              Cancel
            </Button>
            <Button
              className="cc-dialog-button"
              themeColor="primary"
              disabled={gridSelectedRows.length === 0}
              onClick={handleOnSubmitClick}
              iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
              name="AllocateToOfficerDialog"
            >
              Save
            </Button>
          </div>
        }
      />
    );
  }
);
