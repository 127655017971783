import React from "react";
import { Redirect, Switch } from "react-router-dom";

interface IProps {
  children: any;
}

export function ProductRouterSwitch(props: IProps) {
  return (
    <Switch>
      {props.children}
      <Redirect from="*" to="/home" />
    </Switch>
  );
}
