import { ICCRoute } from "@common/constants/ICCRoute";

export const inspScheduleRoute: ICCRoute = {
  path: "insp-schedule",
  name: "Insp Schedule",
  children: [
    {
      path: "my-schedule",
      name: "My Schedule",
      component: require("./my-schedule/_index").default,
    },
    {
      path: "calendar",
      name: "Calendar",
      component: require("./calendar/_index").default,
    },
  ],
};
