import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { SamplesAccordion } from "@app/core/samples/_index";
import { CRSForm } from "@app/products/crs/[id]/components/child-screens/general/_index";
import { CrsContactsTab } from "@app/products/crs/[id]/components/reference-sidebar/contacts/_index";
import { CrsDetailsTab } from "@app/products/crs/[id]/components/reference-sidebar/details/_index";
import { CrsHistoryTab } from "@app/products/crs/[id]/components/reference-sidebar/history/_index";
import { useCRSStore } from "@app/products/crs/[id]/store";
import { CRSRecordsState } from "@app/products/crs/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { displayDotByLimit } from "@common/utils/formatting";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import { default as React, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedCRS = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const { isLoading, crs, crsId, responseLoadError, loadCRS } = useCRSStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        crs?.ChildDisplayName,
        "Main Application",
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `CRS - ${crsId ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CRS,
      RecordType_ENUM: RECORDTYPE.CRS_FYOGroup,
      Record_ID: crsId ?? 0,
    },
  ];

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CrsDetailsTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <CrsContactsTab /> },
      {
        title: "History",
        component: <CrsHistoryTab />,
      },
    ],
  });

  const [crsRecords] = useState<CRSRecordsState | undefined>(undefined);

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Actions",
      totalRecord: crsRecords?.actions,
      component: <Actions id={id} recordType={RECORDTYPE.CRS_Registration} />,
    },
    {
      title: "Comments",
      totalRecord: crsRecords?.comments,
      component: <Comments id={id} recordType={RECORDTYPE.CRS_Registration} />,
    },
    {
      title: "Contacts",
      totalRecord: crsRecords?.contacts,
      component: <Contacts id={id} recordType={RECORDTYPE.CRS_Registration} />,
    },
    {
      title: "Documents",
      totalRecord: crsRecords?.documents,
      component: (
        <Documents id={parseInt(id)} recordType={RECORDTYPE.CRS_Registration} />
      ),
    },
    {
      title: "Fees",
      totalRecord: crsRecords?.fees,
      component: <Fees id={id} recordType={RECORDTYPE.CRS_Registration} />,
    },
    {
      title: "Siblings",
      totalRecord: crsRecords?.siblings,
      component: (
        <SamplesAccordion id={id} recordType={RECORDTYPE.CRS_Registration} />
      ),
    },
    {
      title: "Centre Notes",
      totalRecord: crsRecords?.centrenotes,
      component: (
        <SamplesAccordion id={id} recordType={RECORDTYPE.CRS_Registration} />
      ),
    },
    {
      title: "Family Notes",
      totalRecord: crsRecords?.familynotes,
      component: (
        <SamplesAccordion id={id} recordType={RECORDTYPE.CRS_Registration} />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Application"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCRS(id)}
        />
      ) : (
        <>
          <FormTitle
            title={
              crs?.ChildDisplayName
                ? displayDotByLimit(crs.ChildDisplayName, 15)
                : "Main Application"
            }
            badge={crs?.Status}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Save & exit"} htmlFor={"btn-submit"} />,

              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Comment"} />
                <CCNavButton title={"Communication"} />
                <CCNavButton title={"Document"} />
              </CCNavButton>,

              <CCNavButton title={"View"} type="sub">
                <CCNavButton title={"Documents"} />
                <CCNavButton title={"Journal"} />
                <CCNavButton title={"Project"} />
              </CCNavButton>,
              <CCNavButton title={"Remove duplicate application"} />,
              <CCNavButton title={"Siblings"} type="sub">
                <CCNavButton title={"Create new siblings"} />
                <CCNavButton title={"Link additional siblings"} />
                <CCNavButton title={"Unlink siblings"} />
              </CCNavButton>,
              <CCNavButton title={"Publish"} type="sub">
                <CCNavButton title={"Documents"} />
              </CCNavButton>,
              <CCNavButton title={"Home"} />,
              <CCNavButton title={"More Options"} type="more">
                <CCNavButton title={"Centre based care"} />
                <CCNavButton title={"3yo kindergarten"} />
                <CCNavButton title={"4yo kindergarten"} />
                <CCNavButton title={"Information updated"} />
                <CCNavButton title={"Archive"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {crs && (
                  <CCGeneralPanel component={<CRSForm />}></CCGeneralPanel>
                )}
                <div>
                  <CustomPanelBar listPanelBar={listPanelBar} />
                </div>
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
