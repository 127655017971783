import { postEditAction } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/dialog/edit-action/api";
import { DebtRecoveryAction } from "@app/products/property/assessments/debt-recovery/[id]/model";
import { useDebtRecoveryStore } from "@app/products/property/assessments/debt-recovery/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";

interface IEditActionDialogProps {
  onCloseDialog: () => void;
}

const nameOf = nameOfFactory<DebtRecoveryAction>();

export const EditActionDialog = observer(
  ({ onCloseDialog }: IEditActionDialogProps) => {
    const {
      debtRecoveryActionSelectedRow,
      loadDebtRecovery,
      setDebtRecoveryActionSelectedRow,
    } = useDebtRecoveryStore();

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const { id } = useParams<{ id: string }>();

    const initialValues = {
      Type: debtRecoveryActionSelectedRow?.Type,
      ActionsDateAndTime: debtRecoveryActionSelectedRow?.ActionsDateAndTime,
      Description: debtRecoveryActionSelectedRow?.Description,
    };

    const handleSubmit = async (values: any) => {
      setIsLoadingSubmit(true);
      const response = await postEditAction();

      if (isSuccessResponse(response)) {
        if (id) {
          loadDebtRecovery(+id, {
            title: "Edit debt recovery action was completed successfully.",
            type: "success",
          });
          setDebtRecoveryActionSelectedRow(undefined);
          onCloseDialog();
        }
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title:
            response?.data?.ErrorMessage ??
            "Edit debt recovery action could not be completed.",
          type: "error",
        });
      }
      setIsLoadingSubmit(false);
    };

    return (
      <Form
        onSubmitClick={handleSubmit}
        initialValues={initialValues}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCDialog
              titleHeader={`Edit Debt Recovery Action ${
                debtRecoveryActionSelectedRow?.DebtRecoveryActionId ?? ""
              }`}
              onClose={onCloseDialog}
              maxHeight="380px"
              maxWidth="30%"
              bodyElement={
                <FormElement className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-field-group">
                    <div className="cc-field">
                      <CCLabel title="Action date" isMandatory />
                      <Field
                        name={nameOf("ActionsDateAndTime")}
                        placeholder={"Action date"}
                        component={CCDateTimePicker}
                        validator={requiredValidator}
                        format={DATETIME_FORMAT.DATETIME_CONTROL}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Type" isMandatory />
                      <Field
                        name={nameOf("Type")}
                        component={CCSearchComboBox}
                        data={[]}
                        textField="Name"
                        dataItemKey="Code"
                        isUseDefaultOnchange
                        validator={requiredValidator}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Description" />
                      <Field
                        rows={3}
                        name={nameOf("Description")}
                        placeholder="Description"
                        component={CCTextArea}
                      />
                    </div>
                  </div>
                </FormElement>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    themeColor="primary"
                    iconClass={isLoadingSubmit ? "fas fa-spinner fa-spin" : ""}
                    disabled={
                      isLoadingSubmit ||
                      !formRenderProps?.valid ||
                      !formRenderProps.modified
                    }
                    className={"cc-dialog-button"}
                    onClick={formRenderProps.onSubmit}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
