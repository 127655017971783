import { ActionsAccordion } from "@app/core/actions/action-accordion/_index";
import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { AdditionalContactButton } from "@app/core/contacts/components/buttons/additional-contact/_index";
import { ContactAccordion } from "@app/core/contacts/components/contact-accordion/_index";
import { Documents } from "@app/core/documents/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { AddFeeWithParentButton } from "@app/core/fees/fee-button/_index";
import { InvoicesAccordion } from "@app/core/invoice/invoice-accordion/_index";
import { AddInvoiceWithParentButton } from "@app/core/invoice/invoice-button/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { AddNoticeWithParentButton } from "@app/core/notices/notices-button/_index";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { FinanceTransactions } from "@app/core/transaction/finance-transaction-accordion/_index";
import { isVisibleFinanceTransaction } from "@app/core/transaction/finance-transaction-accordion/util";
import { PoundAddRegistrationButton } from "@app/products/animals/pound-register/[id]/components/action-bar/add-registration/_index";
import { PoundCloseRegistrationButton } from "@app/products/animals/pound-register/[id]/components/action-bar/close-registration/_index";
import { PoundLodgeRegistrationButton } from "@app/products/animals/pound-register/[id]/components/action-bar/lodge-registration/_index";
import { PoundRegistrationForm } from "@app/products/animals/pound-register/[id]/components/child-screens/general/_index";
import { PoundRegisterDetailsTab } from "@app/products/animals/pound-register/[id]/components/reference-sidebar/details/_index";
import { PoundRegisterHistoryTab } from "@app/products/animals/pound-register/[id]/components/reference-sidebar/history/_index";
import {
  PoundRegisterSubmitActions,
  PoundRegister_Status,
} from "@app/products/animals/pound-register/[id]/model";
import { useAnimalPoundRegisterStore } from "@app/products/animals/pound-register/[id]/store";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistManageAnimalPoundRegister = observer(() => {
  const {
    isLoading,
    isLoadingSuperAdmin,
    poundRegisterInfo,
    responseLoadError,
    loadPoundRegisterInfo,
    poundRegisterId,
    onSubmit,
    isDeleted,
  } = useAnimalPoundRegisterStore();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { currentUserInfo } = useGlobalStore();
  const { settings } = useCommonCoreStore();
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Animals_Form_PoundRegister,
    productType: PRODUCT_TYPE_NUMBER.Animals,
  });
  const isShowFinanceTransactionAccordion = useMemo(() => {
    //Condition to show finance transaction:
    //1. isVisibleFinanceTransaction = true
    //2. Fee/Invoice is enabled (This Form is always enabled Invoice)
    return isVisibleFinanceTransaction(settings);
  }, [settings]);

  const isEnableMultiLineInvoice = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText:
        "Animal Pound Register" +
        (poundRegisterInfo?.RegisterNo
          ? ` - ${poundRegisterInfo.RegisterNo}`
          : ""),
      Text: `${getDisplayTextWithDashes([
        poundRegisterInfo?.RegisterNo
          ? poundRegisterInfo?.RegisterNo
          : "No Name",
      ])}`,
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Animals,
      RecordType_ENUM: RECORDTYPE.Animals_PoundRegister,
      Record_ID: poundRegisterId ?? 0,
    },
  ];

  const getTitle = useMemo(() => {
    return `${
      poundRegisterInfo?.RegisterNo
        ? "Ref No: " + poundRegisterInfo?.RegisterNo
        : "No Name"
    }`;
  }, [poundRegisterInfo]);

  const listPanelBar: ITabProps[] = [
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <ActionsAccordion
          id={poundRegisterId}
          recordType={RECORDTYPE.Animals_PoundRegister}
        />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments
          id={poundRegisterId}
          recordType={RECORDTYPE.Animals_PoundRegister}
        />
      ),
    },
    {
      title: "Contacts",
      tabType: TabTableType.Contacts,
      component: (
        <ContactAccordion
          id={poundRegisterId}
          recordType={RECORDTYPE.Animals_PoundRegister}
        />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents
          id={poundRegisterId}
          recordType={RECORDTYPE.Animals_PoundRegister}
        />
      ),
    },
    {
      title: "Transactions",
      tabType: TabTableType.FinanceTransaction,
      isVisible: isShowFinanceTransactionAccordion,
      component: (
        <FinanceTransactions
          id={poundRegisterId}
          recordType={RECORDTYPE.Animals_PoundRegister}
        />
      ),
    },
    {
      title: "Invoices",
      tabType: TabTableType.Invoices,
      isVisible: isEnableMultiLineInvoice,
      component: (
        <InvoicesAccordion
          id={poundRegisterId}
          recordType={RECORDTYPE.Animals_PoundRegister}
        />
      ),
    },
  ];

  const badgeTitles = () => {
    const badgeTitles: string[] = [];
    if (poundRegisterInfo?.Status_Name)
      badgeTitles.push(poundRegisterInfo?.Status_Name);
    if (poundRegisterInfo?.ImpoundedBy_Name)
      badgeTitles.push(poundRegisterInfo?.ImpoundedBy_Name);
    if (
      poundRegisterInfo?.Outcome_Name &&
      poundRegisterInfo?.Outcome_KWD &&
      poundRegisterInfo.Status_ENUM === PoundRegister_Status.Closed
    )
      badgeTitles.push(poundRegisterInfo.Outcome_Name);
    return badgeTitles;
  };

  return (
    <>
      <Loading
        isLoading={isLoading || isLoadingSuperAdmin || isLoadingPermission}
        isFullScreen
      />
      <FormNavigation title={"Pound Register"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadPoundRegisterInfo(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={getTitle} badge={badgeTitles()} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <PoundLodgeRegistrationButton />
                <PoundCloseRegistrationButton />
                <PoundAddRegistrationButton />
              </CCNavButton>,
              <CCNavButton
                title={"Save"}
                name={PoundRegisterSubmitActions.Save}
                onClick={onSubmit}
                disabled={
                  isDeleted ||
                  !checkPermissions([
                    FormAction.CORE_ALLOW_SAVE,
                    FormAction.CORE_ALLOW_EDIT,
                  ])
                }
              />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={poundRegisterId}
                  recordType={RECORDTYPE.Animals_PoundRegister}
                  bubbleUps={poundRegisterInfo?.BubbleUp}
                  disabled={
                    isDeleted ||
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ACTION)
                  }
                />
                <AdditionalContactButton
                  id={poundRegisterId}
                  recordType={RECORDTYPE.Animals_PoundRegister}
                  disabled={
                    isDeleted ||
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_CONTACT)
                  }
                />
                <AddAttachmentButton
                  id={poundRegisterId}
                  recordType={RECORDTYPE.Animals_PoundRegister}
                  disabled={
                    isDeleted ||
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ATTACHMENT)
                  }
                />
                <AddCommentButton
                  id={poundRegisterId}
                  recordType={RECORDTYPE.Animals_PoundRegister}
                  isDisabled={
                    isDeleted ||
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMENT)
                  }
                />
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.Animals}
                  recordType={RECORDTYPE.Animals_PoundRegister}
                  recordId={poundRegisterId}
                  isDisabled={isDeleted}
                />
                <AddDocumentButton
                  dataSetFilter={MAILMERGEDATASET.Animals_PoundRegister}
                  recordType={RECORDTYPE.Animals_PoundRegister}
                  productType={PRODUCT_TYPE_NUMBER.Animals}
                  id={poundRegisterId}
                  isDisabled={
                    isDeleted ||
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_DOCUMENT)
                  }
                />
                {isEnableMultiLineInvoice ? (
                  <AddInvoiceWithParentButton
                    id={poundRegisterId}
                    recordType={RECORDTYPE.Animals_PoundRegister}
                    bubbleUps={poundRegisterInfo?.BubbleUp}
                    productType={PRODUCT_TYPE_NUMBER.Animals}
                    isDisabled={isDeleted}
                  />
                ) : (
                  <AddFeeWithParentButton
                    id={parseInt(id)}
                    recordType={RECORDTYPE.Animals_PoundRegister}
                    productType={PRODUCT_TYPE_NUMBER.Animals}
                    titleButton="Fee"
                    isDisabled={
                      isDeleted ||
                      !checkPermissions(FormAction.CORE_TOOLBAR_ADD_FEE)
                    }
                  />
                )}
                <AddNoticeWithParentButton
                  id={poundRegisterId}
                  recordType={RECORDTYPE.Animals_PoundRegister}
                  bubbleUps={poundRegisterInfo?.BubbleUp}
                  productType={PRODUCT_TYPE_NUMBER.Animals}
                  isDisabled={
                    isDeleted ||
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_NOTICE)
                  }
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {poundRegisterInfo && <PoundRegistrationForm />}
                <TabTable
                  id={poundRegisterId}
                  recordType={RECORDTYPE.Animals_PoundRegister}
                  initialPanels={listPanelBar}
                />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <PoundRegisterDetailsTab />,
                    },
                    {
                      title: "History",
                      component: <PoundRegisterHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
