import { action, observable, computed, makeObservable } from "mobx";

export type responseCode =
  | "Complies"
  | "Not Observed"
  | "Not Applicable"
  | "Serious Non-Compliance"
  | "Satisfactory"
  | "Major"
  | "Minor"
  | "Yes"
  | "No";
/*
enum ResponseCode {
    Complies,
    NotComplies,
    NotApplicable,
    SeriousNotComplies,
    Satisfactory,
    MostlyAccurate,
    Missing
}
*/

//this doesnt have to be exact, im just mocking up the idea
export interface IAssessmentQuestion {
  questionNo: number;
  questionHeading?: string; //dont show heading if not set
  questionDetail: string; //this always exists
  allowedResponses: responseCode[];
  responseRequiresComment: responseCode[];
  showComment: boolean;
  showYesNoOption: boolean;
  update(values: Partial<IAssessmentQuestion>) : void;
}

export interface IAssessmentGroup {
  groupHeading: string;
  ordinalPosition: number; //you could use the index itself in the array, but no guarantee of this?
  questions: IAssessmentQuestion[];
  readonly sortedQuestions: IAssessmentQuestion[];
  readonly nextQuestionNo: number;
}

export interface IAssessmentModel {
  assessmentHeading: string;
  assessmentGroups: IAssessmentGroup[];
  moveGroup(dragOrdinalPos: number, hoverOrdinalPos: number) : void;
  moveQuestion(groupContainer: string, dragOrdinalPos: number, hoverOrdinalPos: number) : void;
  addQuestion(groupContainer:string, question:IAssessmentQuestion) : void;
  addGroup(group: IAssessmentGroup) : void;
  readonly sortedGroups: IAssessmentGroup[];
  readonly nextGroupOrdinal: number;
}

export class AssessmentQuestion implements IAssessmentQuestion {
  questionNo: number;
  questionHeading?: string | undefined;
  questionDetail: string;
  allowedResponses: responseCode[];
  responseRequiresComment: responseCode[];
  showComment: boolean;
  showYesNoOption: boolean;

  constructor(assessmentQuestion: IAssessmentQuestion) {
    makeObservable(this, {
      questionNo: observable,
      questionHeading: observable,
      questionDetail: observable,
      allowedResponses: observable,
      responseRequiresComment: observable,
      showComment: observable,
      showYesNoOption: observable,
      update: action
    });

    this.questionNo = assessmentQuestion.questionNo;
    this.questionHeading = assessmentQuestion.questionHeading;
    this.questionDetail = assessmentQuestion.questionDetail;
    this.allowedResponses = [...assessmentQuestion.allowedResponses];
    this.responseRequiresComment = [...assessmentQuestion.responseRequiresComment];
    this.showComment = assessmentQuestion.showComment;
    this.showYesNoOption = assessmentQuestion.showYesNoOption;
  }
  
  update(values: Partial<IAssessmentQuestion>): void {
    Object.assign(this, values);
  }


}

export class AssessmentGroup implements IAssessmentGroup {
  groupHeading: string;
  ordinalPosition: number;
  questions: IAssessmentQuestion[];

  constructor(assessmentGroup: IAssessmentGroup) {
    makeObservable(this, {
      groupHeading: observable,
      ordinalPosition: observable,
      questions: observable,
      sortedQuestions: computed,
      nextQuestionNo: computed
    });

    this.groupHeading = assessmentGroup.groupHeading;
    this.ordinalPosition = assessmentGroup.ordinalPosition;
    this.questions = assessmentGroup.questions.map(q => new AssessmentQuestion(q));
  }

  get sortedQuestions() {
    return this.questions.slice().sort((a,b)=> a.questionNo - b.questionNo);
  }

  get nextQuestionNo() {
    return (this.questions.length === 0 ? 1 : this.sortedQuestions[this.sortedQuestions.length - 1].questionNo + 1);
  }

}

export class AssessmentModel implements IAssessmentModel {
  assessmentHeading: string;
  assessmentGroups: IAssessmentGroup[];

  constructor(assessmentModel: IAssessmentModel) {
    makeObservable(this, {
      assessmentHeading: observable,
      assessmentGroups: observable,
      sortedGroups: computed,
      nextGroupOrdinal: computed,
      moveGroup: action,
      moveQuestion: action,
      addQuestion: action,
      addGroup: action
    });

    this.assessmentHeading = assessmentModel.assessmentHeading;
    this.assessmentGroups = assessmentModel.assessmentGroups.map(g => new AssessmentGroup(g));
  }

  get sortedGroups() {
    return this.assessmentGroups.slice().sort((a,b) => a.ordinalPosition - b.ordinalPosition);
  }

  get nextGroupOrdinal() {
    return (this.sortedGroups.length === 0 ? 10 : this.sortedGroups[this.sortedGroups.length - 1].ordinalPosition + 10);
  }

  moveGroup = (dragOrdinalPos: number, hoverOrdinalPos: number) => {
    const dragGrpItem = this.assessmentGroups.filter((r) => r.ordinalPosition === dragOrdinalPos)[0];
    const hoverGrpItem = this.assessmentGroups.filter((r) => r.ordinalPosition === hoverOrdinalPos)[0];
    const newItems = this.assessmentGroups.filter(
      (r) => r.ordinalPosition !== dragOrdinalPos && r.ordinalPosition !== hoverOrdinalPos,
    );
    const concatted = newItems.concat([
      new AssessmentGroup({ ...dragGrpItem, ordinalPosition: hoverOrdinalPos }),
      new AssessmentGroup({ ...hoverGrpItem, ordinalPosition: dragOrdinalPos }),
    ]);

    this.assessmentGroups = [...concatted];
    //inspectionAssessmentStore.updateInspectionTemplate({ ...inspCfg, assessmentGroups: concatted });
  };

  moveQuestion = (groupContainer: string, dragOrdinalPos: number, hoverOrdinalPos: number) => {
    //const inspCfg = inspectionAssessmentStore.editingInspectionTemplate!;
    const grpContainer = this.assessmentGroups.filter((r) => r.groupHeading === groupContainer)[0];
    const dragItem = grpContainer.questions.filter((r) => r.questionNo === dragOrdinalPos)[0];
    const hoverItem = grpContainer.questions.filter((r) => r.questionNo === hoverOrdinalPos)[0];

    const newQuestions = grpContainer.questions.filter(
      (r) => r.questionNo !== dragOrdinalPos && r.questionNo !== hoverOrdinalPos,
    );
    const concatQuest = newQuestions.concat([
      new AssessmentQuestion({ ...dragItem, questionNo: hoverOrdinalPos }),
      new AssessmentQuestion({ ...hoverItem, questionNo: dragOrdinalPos }),
    ]);

    const newGroups = this.assessmentGroups.filter((r) => r.groupHeading !== groupContainer);
    const concatGrps = newGroups.concat([new AssessmentGroup({ ...grpContainer, questions: concatQuest })]);
    this.assessmentGroups = [...concatGrps];
    //inspectionAssessmentStore.updateInspectionTemplate({ ...inspCfg, assessmentGroups: concatGrps });    
  };
  
  addQuestion(groupContainer:string, copyFromQuestion:IAssessmentQuestion) {
    const grpContainer = this.assessmentGroups.filter((r) => r.groupHeading === groupContainer)[0];
    const targetQuest = new AssessmentQuestion({...copyFromQuestion, questionNo: grpContainer.nextQuestionNo });
    grpContainer.questions.push(targetQuest);
  }

  addGroup(group: IAssessmentGroup) {
    const newGroup = new AssessmentGroup({...group, ordinalPosition: this.nextGroupOrdinal });
    this.assessmentGroups.push(newGroup);
  }

}