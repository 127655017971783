import {
  eSearchTitleBy,
  IDataSearchTitle,
  ISearchTitleBy,
  VO_Assessment_Subdivision_Title,
} from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/details/model";
import { DECIMAL_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Assessment_Subdivision_Title>();
export const colAssessmentParentTitles: IColumnFields[] = [
  {
    field: nameOf("Title_Legal_Description"),
    title: "Legal Description",
  },
  {
    field: nameOf("Title_Lot"),
    title: "Lot",
  },
  {
    field: nameOf("Title_Plan_Type"),
    title: "Plan Type",
  },
  {
    field: nameOf("Title_Plan_Number"),
    title: "Plan Number",
  },
  {
    field: nameOf("Title_Land_Area"),
    title: "Land Area (m²)",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Title_Type"),
    title: "Lot Type",
  },
  {
    field: nameOf("Title_Owners_Names"),
    title: "Owners",
  },
  {
    field: nameOf("Title_Id"),
    title: "Lot ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const searchTitleByData: ISearchTitleBy[] = [
  {
    Key: eSearchTitleBy.ParcelReference,
    Value: "Parcel Reference",
  },
  {
    Key: eSearchTitleBy.ParcelId,
    Value: "Parcel ID",
  },
];

export const defaultSearchTitle: IDataSearchTitle = {
  SearchBy: {
    Data: searchTitleByData,
    Value: searchTitleByData[0],
  },
  SearchValue: {
    Data: [],
    Loading: false,
  },
};

export const searchTitleConfig = {
  typeSpeed: 500,
  numberLetterSearch: 1,
};

export const regCheckIntegerNumber = /^[0-9]*$/g;
export const regCheckFormatReference = /(-|)[0-9-]+-([0-9]+|)$|^(-|)[0-9]+$/g;

export const getSearchTitleOptionRegex = {
  [eSearchTitleBy.ParcelReference]: regCheckFormatReference,
  [eSearchTitleBy.ParcelId]: regCheckIntegerNumber,
};

export const getTitleSearchKey = {
  [eSearchTitleBy.ParcelReference]: "pref",
  [eSearchTitleBy.ParcelId]: "pid",
  TitleId: "ids", // Only used in the background process, not visible in 'Search by' dropdown
};
