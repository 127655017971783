import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { getStateRoadTabDetailsById } from "@common/pages/settings/lookups/state-roads/_id/components/reference-sidebar/detail/api";
import { StateRoadTabDetails } from "@common/pages/settings/lookups/state-roads/_id/components/reference-sidebar/detail/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class LookupStateRoadTabDetailsStore {
  private _lookupStateRoadDetails?: StateRoadTabDetails = undefined;
  private _responseLoadError?: APIResponseError = undefined;
  private _isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get lookupStateRoadDetails() {
    return toJS(this._lookupStateRoadDetails);
  }
  setLookupStateRoadDetails = (
    lookupStateRoadDetails: StateRoadTabDetails | undefined
  ) => {
    runInAction(() => {
      this._lookupStateRoadDetails = lookupStateRoadDetails;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._lookupStateRoadDetails = undefined;
      this._isLoading = false;
    });
  };

  loadLookupStateRoadDetails = async (id: number) => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    const response = await getStateRoadTabDetailsById(id);
    if (isSuccessResponse(response)) {
      this.setLookupStateRoadDetails(response.data);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };
}
const LookupStateRoadDetailsStoreContext = createContext(
  new LookupStateRoadTabDetailsStore()
);
export const useLookupStateRoadDetailsStore = () => {
  return useContext(LookupStateRoadDetailsStoreContext);
};
