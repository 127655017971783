import { DirectDebitGroup } from "@app/products/direct-debit/system-admin/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { CancelToken } from "axios";

export const getDirectDebitGroupById = async (
  directDebitGroupId: number,
  cancelToken: CancelToken
): Promise<APIResponse<DirectDebitGroup> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<DirectDebitGroup>(
      `api/receipting/internal/directdebitgroup/${directDebitGroupId}`,
      {
        cancelToken,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
