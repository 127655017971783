import { CCDialog } from "@components/cc-dialog/_index";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import React, { Fragment, useState } from "react";
import "./_index.scss";
import { PickAddressDialog } from "./componets/pick-address-dialog/_index";
import { TabAddress } from "./componets/tab-address/_index";
import { TabDetails } from "./componets/tab-details/_index";
import { TabMap } from "./componets/tab-map/_index";
import { TabPeople } from "./componets/tab-people/_index";
import { TabSummary } from "./componets/tab-summary/_index";
export const RegisterAddressPopup = (props: any) => {
  const [tabSelected, setTabSelected] = useState(0);

  const handleSelect = (e: any) => {
    setTabSelected(e.selected);
  };
  const [isShowPickAddressDialog, setIsShowPickAddressDialog] = useState(true);
  const [isShowPickStreetDialog, setIsShowPickStreetDialog] = useState(false);
  return (
    <div className="cc-register-address-popup">
      <CCDialog
        onClose={() => props.onClosePopup()}
        titleHeader={"Property Details"}
        bodyElement={
          <Fragment>
            <TabStrip selected={tabSelected} onSelect={handleSelect}>
              <TabStripTab title={"Address"}>
                <TabAddress />
              </TabStripTab>
              <TabStripTab title={"Map"}>
                <TabMap />
              </TabStripTab>
              <TabStripTab title={"Details"}>
                <TabDetails />
              </TabStripTab>
              <TabStripTab title={"People"}>
                <TabPeople />
              </TabStripTab>
              <TabStripTab title={"Summary"}>
                <TabSummary />
              </TabStripTab>
            </TabStrip>
            {isShowPickAddressDialog && (
              <PickAddressDialog
                onCloseDialog={() => setIsShowPickAddressDialog(false)}
                onSubmitDialog={() => setIsShowPickAddressDialog(false)}
                titleHeader={"Pick Address"}
                isMultiSelect={false}
              />
            )}
            {isShowPickStreetDialog && (
              <PickAddressDialog
                onCloseDialog={() => setIsShowPickStreetDialog(false)}
                onSubmitDialog={() => setIsShowPickStreetDialog(false)}
                titleHeader={"Pick Street"}
                isMultiSelect={true}
              />
            )}
          </Fragment>
        }
        footerElement={
          <div className="cc-dialog-footer-actions-right">
            {/* <Button
              className={'pt-1 ml-2'}
              themeColor="primary"

              onClick={() => {
                props.onClosePopup();
              }}
            >
              {'Select from Properties'} 
              <i className={'fa fa-caret-down'} style={{marginLeft:5}}></i>
            </Button> */}
            <Button
              className="cc-dialog-button"
              onClick={() => {
                props.onClosePopup();
              }}
            >
              Cancel
            </Button>
            <DropDownButton
              className="cc-dialog-dropdown-actions"
              text=" Select from Properties"
              themeColor="primary"
              items={[
                {
                  text: " Select Street",
                  render: (props: any) => (
                    <div
                      className="cc-dialog-button"
                      onClick={() => {
                        setIsShowPickStreetDialog(true);
                      }}
                    >
                      Select Street
                    </div>
                  ),
                },
                {
                  text: "Select Property",
                  render: (props: any) => (
                    <div
                      className="cc-dialog-button"
                      onClick={() => {
                        setIsShowPickAddressDialog(true);
                      }}
                    >
                      Select Property
                    </div>
                  ),
                },
              ]}
            />
            <Button
              className="cc-dialog-button"
              themeColor="primary"
              onClick={() => {
                props.onClosePopup();
              }}
            >
              Select From Map
            </Button>

            <Button
              className="cc-dialog-button"
              themeColor="primary"
              onClick={() => {
                props.onClosePopup();
              }}
            >
              Save & Exit
            </Button>
          </div>
        }
      />
    </div>
  );
};
