import { useNoticeRunGroupStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/store";
import { getMultipleSelectValue } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/util";
import { useNoticeRunNoticeTypeStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-type/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { isFieldVisible } from "@app/products/property/util";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const GroupsStatusesAndTypesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    //store
    const { groupLOVs } = useNoticeRunGroupStepStore();
    const { dataSelectedNoticeType } = useNoticeRunNoticeTypeStepStore();
    const { onChange, valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const assessmentGroupsLovs = useMemo(
      () => groupLOVs?.AssessmentGroups ?? [],
      [groupLOVs?.AssessmentGroups]
    );

    const assessmentStatusLovs = useMemo(
      () => groupLOVs?.AssessmentStatus ?? [],
      [groupLOVs?.AssessmentStatus]
    );

    const assessmentTypesLovs = useMemo(
      () => groupLOVs?.AssessmentTypes ?? [],
      [groupLOVs?.AssessmentTypes]
    );

    const noticeGroupsLovs = useMemo(
      () => groupLOVs?.NoticeGroups ?? [],
      [groupLOVs?.NoticeGroups]
    );

    //check flag show or hide fields Assessment Type and Status
    const isShowAssessmentTypeAndStatus = useMemo(
      () => !isFieldVisible(dataSelectedNoticeType?.ChargeRunVisibility),
      [dataSelectedNoticeType?.ChargeRunVisibility]
    );

    //Get label
    const assessmentLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Assessment
    );

    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">{`${assessmentLabel} groups`}</label>
              <Field
                name={nameOf("AssessmentGroups")}
                component={CCMultiSelectDropdown}
                data={assessmentGroupsLovs}
                textField="Name"
                dataItemKey="Code"
                onChange={(event: MultiSelectChangeEvent) => {
                  let value = event?.value;
                  if (value?.length) {
                    value = value.map((item: DTO_LOV_Number) => item.Code);
                  }
                  onChange(nameOf("AssessmentGroups"), {
                    value: value,
                  });
                }}
                value={getMultipleSelectValue(
                  getFieldValue("AssessmentGroups"),
                  assessmentGroupsLovs,
                  "Code"
                )}
                disabled={options?.isReadOnly}
              />
            </div>
            {isShowAssessmentTypeAndStatus && (
              <>
                <div className="cc-field">
                  <label className="cc-label">{`${assessmentLabel} statuses`}</label>
                  <Field
                    name={nameOf("AssessmentStatuses")}
                    component={CCMultiSelectDropdown}
                    data={assessmentStatusLovs ?? []}
                    textField="Name"
                    dataItemKey="Code"
                    onChange={(event: MultiSelectChangeEvent) => {
                      let value = event?.value;
                      if (value?.length) {
                        value = value.map((item: DTO_LOV_Number) => item.Code);
                      }
                      onChange(nameOf("AssessmentStatuses"), {
                        value: value,
                      });
                    }}
                    value={getMultipleSelectValue(
                      getFieldValue("AssessmentStatuses"),
                      assessmentStatusLovs,
                      "Code"
                    )}
                    disabled={options?.isReadOnly}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">{`${assessmentLabel} types`}</label>
                  <Field
                    name={nameOf("AssessmentTypes")}
                    component={CCMultiSelectDropdown}
                    data={assessmentTypesLovs ?? []}
                    onChange={(event: MultiSelectChangeEvent) => {
                      let value = event?.value;
                      if (value?.length) {
                        value = value.map((item: DTO_LOV_Number) => item.Code);
                      }
                      onChange(nameOf("AssessmentTypes"), {
                        value: value,
                      });
                    }}
                    value={getMultipleSelectValue(
                      getFieldValue("AssessmentTypes"),
                      assessmentTypesLovs ?? [],
                      "Code"
                    )}
                    textField="Name"
                    dataItemKey="Code"
                    disabled={options?.isReadOnly}
                  />
                </div>
              </>
            )}
            <div className="cc-form-cols-1">
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">Include un-grouped notices</label>
                  <Field
                    name={nameOf("IncludeUnGroupedNotices")}
                    checked={getFieldValue("IncludeUnGroupedNotices")}
                    component={CCSwitch}
                    disabled={options?.isReadOnly}
                  />
                </div>
              </div>
            </div>
            <div className="cc-field">
              <label className="cc-label">Notice groups</label>
              <Field
                name={nameOf("NoticeGroups")}
                component={CCMultiSelectDropdown}
                data={noticeGroupsLovs ?? []}
                onChange={(event: MultiSelectChangeEvent) => {
                  let value = event?.value;
                  if (value?.length) {
                    value = value.map((item: DTO_LOV_Number) => item.Code);
                  }
                  onChange(nameOf("NoticeGroups"), {
                    value: value,
                  });
                }}
                value={getMultipleSelectValue(
                  getFieldValue("NoticeGroups"),
                  noticeGroupsLovs,
                  "Code"
                )}
                textField="Name"
                dataItemKey="Code"
                disabled={options?.isReadOnly}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
