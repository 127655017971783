import { loadRegisterDetailsTabById } from "@app/products/property/registers/[id]/components/reference-sidebar/detail/api";
import { IRegistersSummary } from "@app/products/property/registers/[id]/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const PropertyRegisterDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [registerInfo, setRegisterInfo] = useState<
    IRegistersSummary | undefined
  >();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const registerId: any = params.id || lastSelectedRow?.Id;
  useEffect(() => {
    if (registerId) {
      setIsLoading(true);
      loadRegisterDetailsTabById(parseInt(registerId)).then((data) => {
        setRegisterInfo(data);
        setIsLoading(false);
      });
    }
  }, [registerId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!registerInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Account Id:"}
              value={registerInfo?.Id + ""}
              primary
            />
          }
        >
          <>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Account Reference:"}
                value={registerInfo?.AccountReference}
              />
              <ProductReferenceRow
                title={"Description:"}
                value={registerInfo?.Description}
              />
              <ProductReferenceRow
                title={"Address:"}
                value={registerInfo?.PropertyAddress}
              />
              <ProductReferenceRow title={"Name:"} value={registerInfo?.Name} />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
