import { useModifyComplianceStore } from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/store";
import { ResponsibleOfficerSearch } from "@app/products/property/assessments/compliance/component/dialog/create-request-status/components/responsible-officer/_index";
import { ComplianceStatus } from "@app/products/property/assessments/compliance/component/dialog/create-request-status/model";
import { INVALID_WORKFLOW_DRAFT_ID } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { nameOfLov } from "@app/products/property/model";
import {
  DATE_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      workflowDraftId: INVALID_WORKFLOW_DRAFT_ID,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const complianceStatus = +getFieldValue("Compliance_Status");
    const isActiveToDateRequired = useMemo(() => {
      return (
        complianceStatus === ComplianceStatus.Completed_Is_In_Compliance ||
        complianceStatus === ComplianceStatus.Completed_Not_In_Compliance ||
        complianceStatus === ComplianceStatus.No_Review_Required
      );
    }, [complianceStatus]);

    const validateActiveTo = useCallback(
      (value: any) => {
        if (options?.isReadOnly) return undefined;
        return isActiveToDateRequired ? requiredValidator(value) : undefined;
      },
      [options?.isReadOnly, isActiveToDateRequired]
    );

    //Use store
    const { modifyComplianceLOVs } = useModifyComplianceStore();

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Compliance ID" />
            <Field
              name={nameOf("ComplianceId")}
              placeholder={"Compliance ID"}
              component={CCInput}
              readOnly
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Status" />
            <Field
              name={nameOf("Compliance_Status")}
              component={CCSearchComboBox}
              disabled={options?.isReadOnly}
              textField="Name"
              dataItemKey="Code"
              data={modifyComplianceLOVs?.ComplianceStatus ?? []}
              value={getDropdownValue(
                "" + getFieldValue("Compliance_Status"),
                modifyComplianceLOVs?.ComplianceStatus ?? [],
                "Code"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Compliance_Status"), {
                  value: event.target.value?.Code ?? null,
                });
              }}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Active from" />
            <Field
              name={nameOf("DateFrom")}
              component={CCDatePicker}
              value={
                getFieldValue("DateFrom") && new Date(getFieldValue("DateFrom"))
              }
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Active to" isMandatory={isActiveToDateRequired} />
            <Field
              name={nameOf("DateTo")}
              component={CCDatePicker}
              value={
                getFieldValue("DateTo") && new Date(getFieldValue("DateTo"))
              }
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
              validator={validateActiveTo}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Type" />
            <Field
              name={nameOf("Compliance_Type_Id")}
              component={CCSearchComboBox}
              disabled={options?.isReadOnly}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              data={modifyComplianceLOVs?.ComplianceType ?? []}
              value={getDropdownValue(
                "" + getFieldValue("Compliance_Type_Id"),
                modifyComplianceLOVs?.ComplianceType ?? [],
                "Code"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Compliance_Type_Id"), {
                  value: event.target.value?.Code ?? null,
                });
              }}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Responsible" />
            <Field
              name={nameOf("ResponsibleOfficer")}
              component={ResponsibleOfficerSearch}
              textField={"FullName"}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("ResponsibleOfficer"), {
                  value: event.value,
                });
                onChange(nameOf("Officer_Id"), {
                  value: event.value?.Id,
                });
              }}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Questionnaire return date" />
            <Field
              name={nameOf("Questionaire_Return_Date")}
              component={CCDatePicker}
              value={
                getFieldValue("Questionaire_Return_Date") &&
                new Date(getFieldValue("Questionaire_Return_Date"))
              }
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Rented from" />
            <Field
              name={nameOf("Rented_From")}
              placeholder={"Rented from"}
              component={CCInput}
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Interest from" />
            <Field
              name={nameOf("Interest_From")}
              component={CCDatePicker}
              value={
                getFieldValue("Interest_From") &&
                new Date(getFieldValue("Interest_From"))
              }
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Penalty" />
            <Field
              name={nameOf("Penalty_Percentage")}
              placeholder={"Penalty"}
              component={CCNumericTextBox}
              format={PERCENTAGE_FORMAT.PERCENTAGE}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-field">
          <CCLabel title="Search details" />
          <Field
            name={nameOf("Search_Details")}
            placeholder={"Search details"}
            component={CCTextArea}
            readOnly={options?.isReadOnly}
          />
        </div>
      </section>
    );
  }
);
