import { getNameOfSvcStandardBatchUpdate } from "@app/products/crms/service-standards/batch-update/util";
import { RECORDSSYSTEM } from "@common/constants/enumerations";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { NoData } from "@components/no-data/NoData";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export interface ICRMSRecordProps {
  formRenderProps: FormRenderProps;
  recordsSystem: RECORDSSYSTEM;
  isDisabled?: boolean;
}

export const CRMSRecord = ({
  formRenderProps,
  recordsSystem,
  isDisabled,
}: ICRMSRecordProps) => {
  const { valueGetter } = formRenderProps;

  // TODO: Remove it when implement another record system
  if (recordsSystem !== RECORDSSYSTEM.SharePoint) return <NoData />;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="Update records category" />
          <Field
            name={getNameOfSvcStandardBatchUpdate(
              "Update_Records_CategoryCode"
            )}
            checked={valueGetter(
              getNameOfSvcStandardBatchUpdate("Update_Records_CategoryCode")
            )}
            component={CCSwitch}
            disabled={isDisabled}
          />
        </div>
        {valueGetter(
          getNameOfSvcStandardBatchUpdate("Update_Records_CategoryCode")
        ) ? (
          <div className="cc-field">
            <CCLabel title="Records category" isMandatory />
            <Field
              name={getNameOfSvcStandardBatchUpdate("Records_CategoryCode")}
              component={CCInput}
              readOnly={isDisabled}
              validator={requiredValidator}
            />
          </div>
        ) : null}
      </div>
    </section>
  );
};
