import { loadCRMSContact } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/contact/api";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";
import { ContactsSidebar } from "@common/pages/contacts/contacts-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const CRMSKnowledgeBaseContactTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ICommonContactItemRender[] | any>();
  useEffectOnce(() => {
    setIsLoading(true);
    loadCRMSContact().then((data) => {
      setData(data);
      setIsLoading(false);
    });
  });

  if (isLoading) return <Loading isLoading={isLoading} />;

  return <ContactsSidebar data={data || []} />;
});
