import { getViewConfigurations } from "@app/products/property/api";
import { colListAuditHistory } from "@app/products/property/audit-history/list/config";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class AuditHistoryListStore {
  private _columnConfigAuditList: IColumnFields[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get columnConfigAuditList() {
    return this._columnConfigAuditList;
  }
  setColumnConfigAuditList = (columnConfig: IColumnFields[]) => {
    runInAction(() => {
      this._columnConfigAuditList = columnConfig;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  /**
   * Calling api get configuration column
   * process columns
   */
  loadViewConfigurationAuditList = async () => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    let configColumn: IColumnFields[] = [...colListAuditHistory];
    const response = await getViewConfigurations(
      ViewConfiguration.OData_AuditHistory
    );
    if (isSuccessResponse(response)) {
      const viewConfig: ICCViewColumn[] | undefined =
        response?.data?.ColumnDefinitions?.Columns;
      if (viewConfig?.length) {
        configColumn = processDynamicColumns(configColumn, viewConfig);
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setColumnConfigAuditList(configColumn);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  //restore
  resetStore = () => {
    runInAction(() => {
      this.setColumnConfigAuditList([]);
      this.setIsLoading(false);
      this.setResponseLoadError();
    });
  };

  //reload
  reLoadApiConfig = () => {
    this.loadViewConfigurationAuditList();
  };
}

const auditHistoryListStoreContext = createContext(new AuditHistoryListStore());
export const useAuditHistoryListStore = () => {
  return useContext(auditHistoryListStoreContext);
};
