import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import {
  getInspectionComplianceInputPickerOption,
  getOfficerInputPickerOption,
  inspectionTypePickerOption,
} from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { Svc_InspectionType } from "@common/models/inspectionType";
import { KeywordLite } from "@common/models/keyword";
import { getOptionActionTypePicker } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/config";
import { Svc_ActionType } from "@common/pages/actions/[id]/model";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  const nameOfActionType = nameOfFactory<Svc_ActionType>();
  const nameOfInspectionType = nameOfFactory<Svc_InspectionType>();
  const nameOfCoreKeyword = nameOfFactory<KeywordLite>();
  const nameOfOfficer = nameOfFactory<IOfficer>();
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter, onChange } = formRenderProps;
  const data = useDataSetting(configData, [
    "CorporateSettings_GroupInspectionTypes",
    "CorporateSettings_NCItemsPending",
    "CorporateSettings_SetNCNumberReadOnly",
    "Inspection_CompliedLabel",
    "Inspection_MinorNonCompliedLabel",
    "Inspection_NonCompliedLabel",
    "Inspection_CriticalNonCompliedLabel",
    "SimpleInspection_CompliedLabel",
    "CorporateSettings_HasInspectionSuperWF",
    "CorporateSettings_InspectionSuperLabel",
    "CorporateSettings_EnableInspectionAutoActions",
    "CorporateSettings_EnableInspectionTRIMRefNo",
    "CorporateSettings_InspectionSuperList",
    "Inspection_FollowUpActionType",
    "CorporateSettings_InspectionType",
    "CorporateSettings_InspectionResultApplyCompliance",
  ]);
  const hasInspectionSuperWF = valueGetter(
    ECorporateSettingsField.CorporateSettings_HasInspectionSuperWF.toString()
  );
  const txtInspectionSuperWF_Label = valueGetter(
    ECorporateSettingsField.CorporateSettings_InspectionSuperLabel.toString()
  );
  const hasInspectionSuperWFLabel = useRef(
    data.CorporateSettings_HasInspectionSuperWF?.Title
  );

  const inspectionSuperWFLabel = useRef(
    data.CorporateSettings_InspectionSuperLabel?.Title
  );

  const inspectionSuperList = useRef(
    data.CorporateSettings_InspectionSuperList?.Title
  );

  const selectedInspectionTypeRows = valueGetter(
    `option.ValueDisplayDetails_${data.CorporateSettings_InspectionType?.FieldName}`
  );
  const selectedInspectionComplianceRows = valueGetter(
    `option.ValueDisplayDetails_${data.CorporateSettings_InspectionResultApplyCompliance?.FieldName}`
  );

  useEffect(
    () => {
      if (
        data.CorporateSettings_HasInspectionSuperWF &&
        data.CorporateSettings_InspectionSuperLabel &&
        data.CorporateSettings_InspectionSuperList
      ) {
        if (hasInspectionSuperWF) {
          if (txtInspectionSuperWF_Label !== "") {
            onChange(
              `option.ValueDisplayDetails_${data.CorporateSettings_HasInspectionSuperWF?.FieldName}_title`,
              { value: txtInspectionSuperWF_Label + " workflow" }
            );

            onChange(
              `option.ValueDisplayDetails_${data.CorporateSettings_InspectionSuperLabel?.FieldName}_title`,
              { value: txtInspectionSuperWF_Label + " label" }
            );

            onChange(
              `option.ValueDisplayDetails_${data.CorporateSettings_InspectionSuperList?.FieldName}_title`,
              { value: txtInspectionSuperWF_Label + "'s list" }
            );
          }
        } else {
          onChange(
            ECorporateSettingsField.CorporateSettings_InspectionSuperLabel.toString(),
            { value: "" }
          );
          if (
            hasInspectionSuperWFLabel.current &&
            inspectionSuperWFLabel.current &&
            inspectionSuperList.current
          ) {
            onChange(
              `option.ValueDisplayDetails_${data.CorporateSettings_HasInspectionSuperWF?.FieldName}_title`,
              { value: hasInspectionSuperWFLabel.current }
            );

            onChange(
              `option.ValueDisplayDetails_${data.CorporateSettings_InspectionSuperLabel?.FieldName}_title`,
              { value: inspectionSuperWFLabel.current }
            );

            onChange(
              `option.ValueDisplayDetails_${data.CorporateSettings_InspectionSuperList?.FieldName}_title`,
              { value: inspectionSuperList.current }
            );
          }
        }
      }
    },
    // eslint-disable-next-line
    [hasInspectionSuperWF, txtInspectionSuperWF_Label]
  );

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <SwitchGlobalSettings
            data={data.CorporateSettings_GroupInspectionTypes}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchGlobalSettings
            data={data.CorporateSettings_NCItemsPending}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchGlobalSettings
            data={data.CorporateSettings_SetNCNumberReadOnly}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <CCSearchComboBoxGlobalSettings
            data={data.Inspection_CompliedLabel}
            isEditing={isEditing}
          />
          <CCSearchComboBoxGlobalSettings
            data={data.Inspection_MinorNonCompliedLabel}
            isEditing={isEditing}
          />
          <CCSearchComboBoxGlobalSettings
            data={data.Inspection_NonCompliedLabel}
            isEditing={isEditing}
          />
          <CCSearchComboBoxGlobalSettings
            data={data.Inspection_CriticalNonCompliedLabel}
            isEditing={isEditing}
          />
          <CCSearchComboBoxGlobalSettings
            data={data.Inspection_CriticalNonCompliedLabel}
            isEditing={isEditing}
          />
          <CCSearchComboBoxGlobalSettings
            data={data.SimpleInspection_CompliedLabel}
            isEditing={isEditing}
          />
          {data.Inspection_FollowUpActionType && (
            <div className="cc-field">
              <label className="cc-label">
                {data.Inspection_FollowUpActionType.Title}{" "}
                {data.Inspection_FollowUpActionType.IsMandatory && (
                  <CCTooltip type="validator" position="right" />
                )}
              </label>
              <Field
                name={`option.ValueDisplayDetails_${data.Inspection_FollowUpActionType.FieldName}`}
                component={InputPickerSearch}
                disabled={!isEditing}
                validator={
                  data.Inspection_FollowUpActionType.IsMandatory
                    ? requiredValidator
                    : undefined
                }
                onChange={(value: Svc_ActionType[] | null) => {
                  if (data.Inspection_FollowUpActionType) {
                    onChange(data.Inspection_FollowUpActionType.FieldName, {
                      value: value?.[0].ID,
                    });
                    onChange(
                      `option.ValueDisplayDetails_${data.Inspection_FollowUpActionType.FieldName}`,
                      {
                        value,
                      }
                    );
                  }
                }}
                nameDisplayMerge={nameOfActionType("Name")}
                options={getOptionActionTypePicker(PRODUCT_TYPE_NUMBER.Core, {
                  selectedRows: valueGetter(
                    `option.ValueDisplayDetails_${data.Inspection_FollowUpActionType?.FieldName}`
                  ),
                })}
                showClearButton={false}
              />
            </div>
          )}
          <SwitchGlobalSettings
            data={data.CorporateSettings_HasInspectionSuperWF}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
            customTitle={valueGetter(
              `option.ValueDisplayDetails_${data.CorporateSettings_HasInspectionSuperWF?.FieldName}_title`
            )}
          />
          {hasInspectionSuperWF && (
            <InputGlobalSettings
              data={data.CorporateSettings_InspectionSuperLabel}
              customTitle={valueGetter(
                `option.ValueDisplayDetails_${data.CorporateSettings_InspectionSuperLabel?.FieldName}_title`
              )}
              isEditing={isEditing}
            />
          )}
          {data.CorporateSettings_InspectionSuperList && (
            <div className="cc-field">
              <label className="cc-label">
                {valueGetter(
                  `option.ValueDisplayDetails_${data.CorporateSettings_InspectionSuperList?.FieldName}_title`
                ) ?? data.CorporateSettings_InspectionSuperList.Title}
                {data.CorporateSettings_InspectionSuperList.IsMandatory && (
                  <CCTooltip type="validator" position="right" />
                )}
              </label>
              <Field
                name={`option.ValueDisplayDetails_${data.CorporateSettings_InspectionSuperList.FieldName}`}
                component={InputPickerSearch}
                disabled={!isEditing}
                validator={
                  data.CorporateSettings_InspectionSuperList.IsMandatory
                    ? requiredValidator
                    : undefined
                }
                onChange={(value: IOfficer[]) => {
                  if (data.CorporateSettings_InspectionSuperList) {
                    onChange(
                      data.CorporateSettings_InspectionSuperList.FieldName,
                      {
                        value: value?.map((x) => x.ID).toString(),
                      }
                    );
                    onChange(
                      `option.ValueDisplayDetails_${data.CorporateSettings_InspectionSuperList.FieldName}`,
                      { value: value }
                    );
                  }
                }}
                nameDisplayMerge={nameOfOfficer("Name")}
                options={getOfficerInputPickerOption({
                  selectableMode: "multiple",
                  selectedRows: valueGetter(
                    `option.ValueDisplayDetails_${data.CorporateSettings_InspectionSuperList?.FieldName}`
                  ),
                })}
                showClearButton={false}
              />
            </div>
          )}

          {data.CorporateSettings_InspectionType && (
            <div className="cc-field">
              <label className="cc-label">
                {data.CorporateSettings_InspectionType.Title}{" "}
                {data.CorporateSettings_InspectionType.IsMandatory && (
                  <CCTooltip type="validator" position="right" />
                )}
              </label>
              <Field
                name={`option.ValueDisplayDetails_${data.CorporateSettings_InspectionType.FieldName}`}
                component={InputPickerSearch}
                disabled={!isEditing}
                validator={
                  data.CorporateSettings_InspectionType.IsMandatory
                    ? requiredValidator
                    : undefined
                }
                onChange={(value: Svc_InspectionType[] | null) => {
                  if (value && data.CorporateSettings_InspectionType) {
                    onChange(data.CorporateSettings_InspectionType.FieldName, {
                      value: value?.[0].ID,
                    });
                    onChange(
                      `option.ValueDisplayDetails_${data.CorporateSettings_InspectionType.FieldName}`,
                      {
                        value,
                      }
                    );
                  }
                }}
                nameDisplayMerge={nameOfInspectionType("Name")}
                options={inspectionTypePickerOption(PRODUCT_TYPE_NUMBER.Core, {
                  selectedRows: selectedInspectionTypeRows,
                })}
                showClearButton={false}
              />
            </div>
          )}

          {data.CorporateSettings_InspectionResultApplyCompliance && (
            <div className="cc-field">
              <label className="cc-label">
                {data.CorporateSettings_InspectionResultApplyCompliance.Title}{" "}
                {data.CorporateSettings_InspectionResultApplyCompliance
                  .IsMandatory && (
                  <CCTooltip type="validator" position="right" />
                )}
              </label>
              <Field
                name={`option.ValueDisplayDetails_${data.CorporateSettings_InspectionResultApplyCompliance.FieldName}`}
                component={InputPickerSearch}
                disabled={!isEditing}
                validator={
                  data.CorporateSettings_InspectionResultApplyCompliance
                    .IsMandatory
                    ? requiredValidator
                    : undefined
                }
                onChange={(value: KeywordLite[] | null) => {
                  if (data.CorporateSettings_InspectionResultApplyCompliance) {
                    onChange(
                      data.CorporateSettings_InspectionResultApplyCompliance
                        .FieldName,
                      {
                        value: value?.map((item) => item.Keyword_ID).toString(),
                      }
                    );
                    onChange(
                      `option.ValueDisplayDetails_${data.CorporateSettings_InspectionResultApplyCompliance.FieldName}`,
                      {
                        value,
                      }
                    );
                  }
                }}
                nameDisplayMerge={nameOfCoreKeyword("Name")}
                options={getInspectionComplianceInputPickerOption(undefined, {
                  selectableMode: "multiple",
                  selectedRows: selectedInspectionComplianceRows,
                })}
                showClearButton={false}
              />
            </div>
          )}
          <SwitchGlobalSettings
            data={data.CorporateSettings_EnableInspectionAutoActions}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchGlobalSettings
            data={data.CorporateSettings_EnableInspectionTRIMRefNo}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
      </section>
    </>
  );
});
