import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colCRSPortal3yoRegistrations: IColumnFields[] = [
  {
    field: CRSFieldMapping.Status,
    title: "Status",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: CRSFieldMapping.PortalApplicationType,
    title: "Application Type",
  },
  { field: CRSFieldMapping.YearAttending, title: "Year" },
  {
    field: CRSFieldMapping.DisplayName,
    title: "Parent / Guardian",
  },
  { field: CRSFieldMapping.ChildName, title: "Child Name" },
  {
    field: CRSFieldMapping.DateLodged,
    title: "Lodged Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.Centre_Name,
    title: "Centre Name",
  },
  { field: CRSFieldMapping.Group_Name, title: "Group Name" },
  {
    field: CRSFieldMapping.ReferenceNumber,
    title: "Reference Number",
  },
  { field: CRSFieldMapping.Mobile, title: "Mobile" },
  { field: CRSFieldMapping.HomePhone, title: "Home Phone" },
  { field: CRSFieldMapping.WorkPhone, title: "Work Phone" },
  { field: CRSFieldMapping.Email, title: "Email" },
  { field: CRSFieldMapping.Address, title: "Address" },
];
