import { ContactManager_View_ContactWithAlert } from "@app/core/contacts/alert/list/model";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (
  selectedRow: ContactManager_View_ContactWithAlert
) => {
  const dynamicDisplayName = selectedRow.Id ? `- ${selectedRow.Id}` : "";
  return `Contact - Contact Alerts ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (
  selectedRow: ContactManager_View_ContactWithAlert
) => {
  return getDisplayTextWithDashes([
    selectedRow.Classification,
    selectedRow.DisplayName,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return "Contact - Contact Alerts";
};

export const getBookmarkListViewDetail = () => {
  return "Contact - Contact Alerts - List";
};
