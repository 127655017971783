export const TOWN_PLANNING_PPR_MY_ACTION_VIEW_URL =
  "/odata/core/internal/actions/Register(myRecs=true)?$count=true&";
export const TOWN_PLANNING_PPR_ALL_ACTION_VIEW_URL =
  "/odata/core/internal/actions/Register?$count=true&";
export const TOWN_PLANNING_PPR_OUTSTANDING_ACTION_VIEW_URL =
  "/odata/core/internal/actions/Register(openRecsOnly=true)?$count=true&";
export const TOWN_PLANNING_PPR_MY_ACTION_WITH_OUTSTANDING_ACTION_VIEW_URL =
  "/odata/core/internal/actions/Register(myRecs=true,openRecsOnly=true)?$count=true&";
export const TOWN_PLANNING_PPR_MY_ACTION_OVERDUE_VIEW_URL =
  "/odata/core/internal/actions/Register(myRecs=true)?$count=true&";
