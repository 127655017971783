import { DTO_Document } from "@app/products/property/components/grid/document-issued-grid/model";
import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Document>();
export const colDocumentsIssued: IColumnFields[] = [
  {
    field: nameOf("Created_On"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Created_Officer_Name"),
    title: "Created By",
  },
  {
    field: nameOf("DocumentType"),
    title: "Document Type",
  },
  {
    field: nameOf("Issued_On"),
    title: "Issued On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Issued_Officer_Name"),
    title: "Issued By",
  },
  {
    field: nameOf("Component_Document_Id"),
    title: "Document ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
