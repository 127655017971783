import { DeleteButton } from "@app/core/delete/buttons/_index";
import { CrmsActionBarNavDropdown } from "@app/products/crms/components/action-bar/nav-dropdown/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { crmsRoute } from "@app/products/crms/route";
import {
  CRMS_SYSTEM_ADMIN_ROSTER_ROUTE,
  CRMS_SYSTEM_ADMIN_ROSTER_URL,
} from "@app/products/crms/system-admin/constant";
import { RosterView } from "@app/products/crms/system-admin/model";
import { NewRosterButton } from "@app/products/crms/system-admin/rosters/components/action-bar/buttons/new-roster/_index";
import { CRMSSystemAdminRosterDetailsTab } from "@app/products/crms/system-admin/rosters/components/reference-sidebar/details/_index";
import { CRMSSystemAdminRosterHistoryTab } from "@app/products/crms/system-admin/rosters/components/reference-sidebar/history/_index";
import { colRosters } from "@app/products/crms/system-admin/rosters/config";
import { CRMSSystemAdminRosterBookmark } from "@app/products/crms/system-admin/rosters/util";
import { systemAdminRoute } from "@app/products/crms/system-admin/route";
import { CRMSSystemAdminBookmark } from "@app/products/crms/system-admin/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<RosterView>();
const gridId = getUUID();

export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_Roster,
    productType: PRODUCT_TYPE_NUMBER.CustomerService,
  });

  useCCListViewActionBar({
    title: crmsRoute.name,
    leftComponents: [
      <CrmsActionBarNavDropdown category={systemAdminRoute.path} />,
    ],
    centerComponents: [
      <NewRosterButton
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
      />,
      <DeleteButton
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
        recordType={RECORDTYPE.CORE_Roster}
        refreshGridIds={[gridId]}
        gridId={gridId}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CRMS_SYSTEM_ADMIN_ROSTER_ROUTE}
        productType={PRODUCT_TYPE.CustomerService}
        recordType={RECORDTYPE.CORE_Roster}
        detail={CRMSSystemAdminBookmark.getBookmarkDetail}
        displayName={CRMSSystemAdminBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          CRMSSystemAdminRosterBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={CRMSSystemAdminRosterBookmark.getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CRMSSystemAdminRosterDetailsTab /> },
      {
        title: "History",
        component: <CRMSSystemAdminRosterHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        gridId={gridId}
        columnFields={colRosters}
        primaryField={"ID"}
        dataUrl={CRMS_SYSTEM_ADMIN_ROSTER_URL}
        state={{ sort: [{ field: nameOf("ID"), dir: "desc" }] }}
      />
    </LoadingPermissionWrapper>
  );
});
