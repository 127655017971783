import { VO_NoticeOfSale_Service_Notice_Address } from "@app/products/property/changes-of-ownership/notice-of-sales/components/detail/components/service-of-notice/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_NoticeOfSale_Service_Notice_Address>();
export const serviceOfNoticeColumns: IColumnFields[] = [
  {
    field: nameOf("Contact_Name"),
    title: "Contact Name",
  },
  {
    field: nameOf("Property_Name"),
    title: "Property Name",
  },
  {
    field: nameOf("Code_Acquiring_Dealing_Type"),
    title: "Code of Acquiring Dealing Type",
  },
  {
    field: nameOf("ASON_Unit_Number"),
    title: "Unit Number",
  },
  {
    field: nameOf("ASON_Unit_Suffix"),
    title: "Unit Suffix",
  },
  {
    field: nameOf("ASON_Address_Line1"),
    title: "Address Line 1",
  },
  {
    field: nameOf("ASON_Address_Line2"),
    title: "Address Line 2",
  },
  {
    field: nameOf("ASON_Address_Line3"),
    title: "Address Line 3",
  },
  {
    field: nameOf("ASON_Address_Line4"),
    title: "Address Line 4",
  },
  {
    field: nameOf("ASON_House_Number1"),
    title: "House Number 1",
  },
  {
    field: nameOf("ASON_House_Prefix1"),
    title: "House Prefix 1",
  },
  {
    field: nameOf("ASON_House_Suffix1"),
    title: "House Suffix 1",
  },
  {
    field: nameOf("ASON_House_Number2"),
    title: "House Number 2",
  },
  {
    field: nameOf("ASON_House_Prefix2"),
    title: "House Prefix 2",
  },
  {
    field: nameOf("ASON_House_Suffix2"),
    title: "House Suffix 2",
  },
  {
    field: nameOf("ASON_Floor_Number"),
    title: "Floor Number",
  },
  {
    field: nameOf("ASON_Floor_Type"),
    title: "Floor Type",
  },
  {
    field: nameOf("ASON_Floor_Suffix"),
    title: "Floor Suffix",
  },
  {
    field: nameOf("ASON_Location_Descriptor"),
    title: "Location Descriptor",
  },
  {
    field: nameOf("ASON_Street_Name1"),
    title: "Street Name",
  },
  {
    field: nameOf("ASON_Street_Type1"),
    title: "Street Type",
  },
  {
    field: nameOf("ASON_Street_Suffix1"),
    title: "Street Suffix",
  },
  {
    field: nameOf("ASON_Suburb"),
    title: "Suburb",
  },
  {
    field: nameOf("ASON_Postcode"),
    title: "Postcode",
  },
  {
    field: nameOf("ASON_State"),
    title: "State",
  },
  {
    field: nameOf("ASON_Country"),
    title: "Country",
  },
];
