import {
  DTO_Assessment_FinancialAreaBalance,
  DTO_Balance_OutStanding,
  DTO_Valuation_Value,
} from "@app/products/property/assessments/[id]/model";
import { formatDynamicValuationsForCell } from "@app/products/property/util";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleProminent } from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOfFinancialAreaBalance =
  nameOfFactory<DTO_Assessment_FinancialAreaBalance>();
const nameOfValuationValue = nameOfFactory<DTO_Valuation_Value>();
export const colValuations: IColumnFields[] = [
  {
    field: nameOfValuationValue("Name"),
    title: "Type",
  },
  {
    field: nameOfValuationValue("Value"),
    title: "Valuation",
    format: formatDynamicValuationsForCell,
    filter: "numeric",
  },
];
export const colBalanceOutstanding: IColumnFields[] = [
  {
    field: nameOfFinancialAreaBalance("FinancialArea"),
    title: "Financial Area",
  },
  {
    field: nameOfFinancialAreaBalance("Overdue"),
    title: "Overdue",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfFinancialAreaBalance("Due"),
    title: "Due",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfFinancialAreaBalance("OverdueDue"),
    title: "Overdue + Due",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfFinancialAreaBalance("Pending"),
    title: "Pending",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfFinancialAreaBalance("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfFinancialAreaBalance("Total"),
    title: "Total",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
];
const nameOfFinancialAreaBalanceSAP = nameOfFactory<DTO_Balance_OutStanding>();
export const colBalanceOutstandingSAP: IColumnFields[] = [
  {
    field: nameOfFinancialAreaBalanceSAP("DocumentID"),
    title: "Document ID",
  },
  {
    field: nameOfFinancialAreaBalanceSAP("DocumentDate"),
    title: "Document Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfFinancialAreaBalanceSAP("DueDate"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfFinancialAreaBalanceSAP("NotDueAmount"),
    title: "Not Due Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("NotDueAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfFinancialAreaBalanceSAP("OverDueAmount"),
    title: "Over Due Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("OverDueAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfFinancialAreaBalanceSAP("ExternalReferenceID"),
    title: "External Reference ID",
  },
  {
    field: nameOfFinancialAreaBalanceSAP("DocumentType"),
    title: "Document Type",
  },
  {
    field: nameOfFinancialAreaBalanceSAP("LLSInvoiceType"),
    title: "LLS Invoice Type",
  },
  {
    field: nameOfFinancialAreaBalanceSAP("ABCClassificationID"),
    title: "ABC Classification ID",
  },
];
