import { history } from "@/AppRoutes";
import { generateGetContactURL } from "@app/core/contacts/components/dialogs/pick-contact/util";
import { postForwardOn } from "@app/products/crms/phone-messages/[id]/api";
import { ContactComboBoxSearch } from "@app/products/crms/phone-messages/[id]/components/forms/components/child-screens/general/components/contact-combobox-search/_index";
import { colInternalContact } from "@app/products/crms/phone-messages/[id]/config";
import {
    ForwardOnRequest,
    PhoneMessage,
} from "@app/products/crms/phone-messages/[id]/model";
import { useCRMSPhoneMessageStore } from "@app/products/crms/phone-messages/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { ContactClassification } from "@common/constants/enumerations";
import { Svc_ContactDetails } from "@common/pages/profile/components/input-picker/officer-picker/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
    CCLocalNotification,
    ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
    Field,
    Form,
    FormElement,
    FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";

export interface IForwardOnDialogProps {
  onClosePopup: () => void;
  PhoneMessage: PhoneMessage;
}

export interface IForwardOnForm {
  Recipient: any;
  CommentMessage: string;
}

const nameOf = nameOfFactory<IForwardOnForm>();
const nameOfInternalContact = nameOfFactory<Svc_ContactDetails>();

export const ForwardOnDialog = observer(
  ({ onClosePopup, PhoneMessage }: IForwardOnDialogProps) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { setIsShowForwardOnDialog } = useCRMSPhoneMessageStore();

    const handleSubmit = async (event: FormSubmitClickEvent) => {
      const forwardOnRequest = {
        RecipientId: event?.values?.Recipient?.Id,
        CommentMessage: event?.values?.CommentMessage,
        PhoneMessage: PhoneMessage,
      } as ForwardOnRequest;

      setIsLoading(true);
      const response = await postForwardOn(forwardOnRequest);
      setIsLoading(false);

      if (isSuccessResponse(response) && response.data) {
        setIsShowForwardOnDialog(false);
        history.goBack();
      } else {
        notificationRef?.current?.pushNotification({
          autoClose: false,
          type: "error",
          description:
            response.data?.Errors ||
            response.error ||
            "Forward on phone message fail.",
        });
      }
    };

    return (
      <Form
        onSubmitClick={(event: FormSubmitClickEvent) => handleSubmit(event)}
        render={(formRenderProps) => {
          const { valueGetter, onChange, valid, onSubmit } = formRenderProps;
          const recipientChangeHandler = (event: ComboBoxChangeEvent) => {
            if (event.value)
              onChange(nameOf("Recipient"), { value: event.value });
          };
          return (
            <FormElement>
              <CCDialog
                maxWidth="35%"
                maxHeight="37%"
                titleHeader="Message Forward"
                onClose={() => onClosePopup()}
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <section className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <CCLabel title="Pick recipient" isMandatory />
                          <Field
                            name={nameOf("Recipient")}
                            value={valueGetter(nameOf("Recipient")) ?? ""}
                            component={ContactComboBoxSearch}
                            dataUrl={`${generateGetContactURL({
                              classificationFilters: `'${ContactClassification.SiteUser}'`,
                            })}?$count=true`}
                            columnFields={colInternalContact}
                            textField={nameOfInternalContact("DisplayName")}
                            dataItemKey={nameOfInternalContact("Id")}
                            filterField={nameOfInternalContact("DisplayName")}
                            onError={notificationRef?.current?.pushNotification}
                            formRenderProps={formRenderProps}
                            onChangeHandler={recipientChangeHandler}
                            isExternal={false}
                            validator={requiredValidator}
                            disabled={isLoading}
                            isForward={true}
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel title="Comment" />
                          <Field
                            name={nameOf("CommentMessage")}
                            placeholder="Comment"
                            rows={5}
                            value={valueGetter(nameOf("CommentMessage"))}
                            component={CCTextArea}
                            readOnly={isLoading}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button
                      className={"cc-dialog-button"}
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                      themeColor="primary"
                      disabled={!valid || isLoading}
                      onClick={onSubmit}
                    >
                      Forward message
                    </Button>
                    <Button
                      className={"cc-dialog-button"}
                      onClick={() => {
                        onClosePopup();
                      }}
                      disabled={isLoading}
                    >
                      Cancel
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
