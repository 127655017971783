import {
  DTO_ModifyCompliance_LOVs_Response,
  DTO_Workflow_ModifyCompliance,
  RequestModifyComplianceObj,
} from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { sleep } from "@common/utils/common";
import { isNil } from "lodash";

export const getModifyComplianceLOVs = async (
  workflowType: WorkflowTypes
): Promise<APIResponse<DTO_ModifyCompliance_LOVs_Response>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_ModifyCompliance_LOVs_Response>(
      `/api/property/internal/workflow/modifycompliance/lovs?workflowType=${workflowType}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowModifyCompliance = async (
  requestObj: RequestModifyComplianceObj | object = {}
): Promise<APIResponse<DTO_Workflow_ModifyCompliance>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/modifycompliance/new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSavedWorkflowModifyCompliance = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_ModifyCompliance>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/modifycompliance/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataModifyCompliance =
  | [
      APIResponse<DTO_ModifyCompliance_LOVs_Response>,
      APIResponse<DTO_Workflow_ModifyCompliance>
    ]
  | APIResponse<
      DTO_ModifyCompliance_LOVs_Response | DTO_Workflow_ModifyCompliance
    >
  | undefined;
export const getInitialDataModifyCompliance = async (
  workflowType: WorkflowTypes,
  complianceId: number,
  workflowDraftId: number | undefined,
  isNewCompliance: boolean = false
): Promise<IGetInitialDataModifyCompliance> => {
  try {
    return await Promise.all([
      getModifyComplianceLOVs(workflowType),
      !isNewCompliance && !isNil(workflowDraftId)
        ? getSavedWorkflowModifyCompliance(workflowDraftId)
        : getNewWorkflowModifyCompliance({
            ComplianceId: complianceId.toString(),
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessModifyCompliance = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_ModifyCompliance
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_ModifyCompliance>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/process/modifycompliance/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postRejectModifyCompliance = async (data: any) => {
  await sleep(2000);
  // mock for success and fail cases
  if (data?.Reason === "false") {
    return { status: 500, data: { IsSuccess: false } };
  } else {
    return { status: 200, data: { IsSuccess: true } };
  }
};
