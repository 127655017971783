export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.NoticeRunId
    ? `- ${selectedRow.NoticeRunId}`
    : "";

  return `Property - Schemes ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  const dynamicDetail =
    "Schemes " +
    (selectedRow.NoticeRunId ? `- ${selectedRow.NoticeRunId}` : "");

  return `${dynamicDetail}`;
};

export const getBookmarkListViewDetail = () => {
  return "Property - Schemes - Notice Runs";
};
