import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export const RaiseChargeButton = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();
  const { gridSelectedRows } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();

  const isDisabled = useMemo(() => {
    return !(id || gridSelectedRows.length === 1);
  }, [gridSelectedRows.length, id]);

  const assessmentID = useMemo(() => {
    return gridSelectedRows[0]?.Assessment_Id ?? +(id ?? 0);
  }, [gridSelectedRows, id]);

  return (
    <CCNavButton
      title="Raise charge"
      disabled={isDisabled}
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Journal_Assessment_Raise_Charges,
            data: {},
            props: {
              assessmentID,
            },
          },
        ]);
      }}
    />
  );
});
