import { TOWN_PLANNING_PPR_ENQUIRIES_ROUTE } from "@app/products/town-planning/ppr/enquiries/_id/constant";
import { RegisterType } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { PPRApplication } from "@app/products/town-planning/ppr/permit-referrals/all/register/model";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import { RECORDTYPE } from "@common/constants/recordtype";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `PPR - Register ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.ApplicationNumber,
    selectedRow.SiteAddress + ", " + selectedRow.Locality,
  ]);
};

export const handlePermitReferralCurrentLinkTo = (dataItem: PPRApplication) => {
  if (
    dataItem.RecordType_ENUM === RECORDTYPE.CORE_Register &&
    dataItem.RegisterType_ENUM === RegisterType.PlanningEnquiry
  ) {
    return `${TOWN_PLANNING_PPR_ENQUIRIES_ROUTE}/${dataItem.ID}`;
  } else {
    return `${TOWN_PLANNING_PPR_ROUTE}/${dataItem.ID}`;
  }
};
