/* eslint-disable react-hooks/exhaustive-deps */
import { PremisesType } from "@app/products/hm/model";
import {
  getNameOfPremises,
  nameOfSvcPremises,
} from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/_index";
import { getLeaseeDisplay } from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/leasee-section/util";
import { HM_PREMISES_HANDLER_SLIDER } from "@app/products/hm/premises/[id]/constant";
import {
  IHMHandlerEventInitialData,
  PremiseHandlerRequest,
  Premises,
  PremisesRegisterLovs,
  PremisesUIControl,
  Svc_FormAction_Premise,
} from "@app/products/hm/premises/[id]/model";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { nameOfFactory } from "@common/utils/common";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { isHTML } from "@components/cc-input-picker/util";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef } from "react";

export interface ILeaseeSectionProps {
  formRenderProps: FormRenderProps;
  isInactive?: boolean;
}

const nameOfPremisesType = nameOfFactory<PremisesType>();
const nameOfKeyValuePacket = nameOfFactory<IKeyValuePacket>();
export const LeaseeSection = observer(
  ({ formRenderProps, isInactive = false }: ILeaseeSectionProps) => {
    const { dataForms, setInitialDataForms } = useFlexibleFormStore();
    const { pushNotificationPortal } = useNotificationPortalStore();
    const { valueGetter } = formRenderProps;

    const premisesObj = valueGetter(nameOfSvcPremises("Premises")) as Premises;
    const premisesGeneralFormLovs =
      dataForms?.GeneralFormLovs as PremisesRegisterLovs;
    const premisesGeneralUIControl =
      dataForms?.GeneralUIControl as PremisesUIControl;

    const prevLeaseeDisplayName = useRef<string>("");
    const prevSubcontractorDisplayName = useRef<string>("");

    // #region SHOW/HIDE VARIABLES
    const trHealthLicensingVisible =
      valueGetter(
        `${getNameOfPremises(
          "PremisesType"
        )}.PremisesClassification.HealthLicensing`
      ) ?? false;

    const divShowBedsVisible =
      valueGetter(
        `${getNameOfPremises("PremisesType")}.${nameOfPremisesType(
          "Flag_NoOfBeds"
        )}`
      ) ?? false;

    const divShowVehiclesVisible =
      valueGetter(
        `${getNameOfPremises("PremisesType")}.${nameOfPremisesType(
          "Flag_NoOfVehicles"
        )}`
      ) ?? false;
    // #endregion SHOW/HIDE VARIABLES

    // #region Functions
    const getInitialPremisesHandlerRequest = (
      formAction: Svc_FormAction_Premise
    ) => {
      return {
        FormAction: formAction,
        Premises: premisesObj,
        Args: {},
        IsFirstTimeLoad: false,
        UIControl: premisesGeneralUIControl,
        PremiseLOVs: premisesGeneralFormLovs,
      } as PremiseHandlerRequest;
    };

    // #region Leasee
    const handleOnChangeLeasee = (event: ComboBoxChangeEvent | null) => {
      const contact = event?.value;
      let premisesHandlerRequest: PremiseHandlerRequest =
        getInitialPremisesHandlerRequest(
          Svc_FormAction_Premise.Form_PickLeasee
        );
      if (contact) {
        premisesHandlerRequest.Args = {
          Leasee: {
            Contact: contact,
          },
        };
      }
      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: "Select leasee failed.",
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const removeDisplayValueLeasee = () => {
      if (premisesGeneralUIControl) {
        prevLeaseeDisplayName.current =
          premisesGeneralUIControl?.LitLeasee?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...premisesGeneralUIControl,
            LitLeasee: {
              ...premisesGeneralUIControl?.LitLeasee,
              Value: premisesObj?.Leasee?.Contact?.DisplayName,
            },
          },
        });
      }
    };
    const restoreDisplayValueLeasee = () => {
      if (
        premisesGeneralUIControl &&
        isHTML(prevLeaseeDisplayName.current) &&
        valueGetter(getNameOfPremises("Leasee"))?.Contact
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...premisesGeneralUIControl,
            LitLeasee: {
              ...premisesGeneralUIControl?.LitLeasee,
              Value: prevLeaseeDisplayName.current,
            },
          },
        });
      }
    };
    const leaseeDisplay = useMemo(() => {
      return getLeaseeDisplay(
        valueGetter(getNameOfPremises("Leasee"))?.Contact
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueGetter(getNameOfPremises("Leasee"))]);
    // #endregion Leasee

    // #region Subcontractor
    const handleOnChangeSubcontractor = (event: ComboBoxChangeEvent | null) => {
      const contact = event?.value;
      let premisesHandlerRequest: PremiseHandlerRequest =
        getInitialPremisesHandlerRequest(
          Svc_FormAction_Premise.Form_PickSubcontractor
        );
      if (contact) {
        premisesHandlerRequest.Args = {
          Subcontractor: {
            Contact: contact,
          },
        };
      }
      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: "Select subcontractor failed.",
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const removeDisplayValueSubcontractor = () => {
      if (premisesGeneralUIControl) {
        prevSubcontractorDisplayName.current =
          premisesGeneralUIControl?.LitSubContractor?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...premisesGeneralUIControl,
            LitSubContractor: {
              ...premisesGeneralUIControl?.LitSubContractor,
              Value: premisesObj?.Subcontractor?.Contact?.DisplayName,
            },
          },
        });
      }
    };
    const restoreDisplayValueSubcontractor = () => {
      if (
        premisesGeneralUIControl &&
        isHTML(prevSubcontractorDisplayName.current) &&
        valueGetter(getNameOfPremises("Subcontractor"))?.Contact
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...premisesGeneralUIControl,
            LitSubContractor: {
              ...premisesGeneralUIControl?.LitSubContractor,
              Value: prevSubcontractorDisplayName.current,
            },
          },
        });
      }
    };
    const subcontractorDisplay = useMemo(() => {
      return getLeaseeDisplay(
        valueGetter(getNameOfPremises("Subcontractor"))?.Contact
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueGetter(getNameOfPremises("Subcontractor"))]);
    //#endregion Subcontractor

    // #endregion Functions

    return (
      trHealthLicensingVisible && (
        <>
          <hr className="cc-divider" />
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Leasee" />
                <Field
                  uniqueKey="HMPremisesLeaseePicker"
                  name={`${getNameOfPremises("Leasee")}.Contact`}
                  component={ContactPicker}
                  onError={(error: any) => {
                    pushNotificationPortal({
                      type: "error",
                      title: "Pick leasee failed.",
                      description: error,
                      autoClose: false,
                    });
                  }}
                  displayValue={
                    premisesGeneralUIControl?.LitLeasee?.Value ?? ""
                  }
                  placeholder="Select leasee"
                  onChange={handleOnChangeLeasee}
                  removeDisplayValue={removeDisplayValueLeasee}
                  restoreDisplayValue={restoreDisplayValueLeasee}
                  disabled={isInactive}
                  disabledButton={isInactive}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Leasee details"
                  value={
                    isHTML(leaseeDisplay)
                      ? sanitizeHtml(leaseeDisplay)
                      : leaseeDisplay
                  }
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Subcontractor" />
                <Field
                  uniqueKey="HMPremisesSubcontractorPicker"
                  name={`${getNameOfPremises("Subcontractor")}.Contact`}
                  component={ContactPicker}
                  onError={(error: any) => {
                    pushNotificationPortal({
                      type: "error",
                      title: "Pick subcontractor failed.",
                      description: error,
                      autoClose: false,
                    });
                  }}
                  displayValue={
                    premisesGeneralUIControl?.LitSubContractor?.Value ?? ""
                  }
                  placeholder="Select subcontractor"
                  onChange={handleOnChangeSubcontractor}
                  removeDisplayValue={removeDisplayValueSubcontractor}
                  restoreDisplayValue={restoreDisplayValueSubcontractor}
                  disabled={isInactive}
                  disabledButton={isInactive}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Subcontractor details"
                  value={
                    isHTML(subcontractorDisplay)
                      ? sanitizeHtml(subcontractorDisplay)
                      : subcontractorDisplay
                  }
                />
              </div>
              <div className="cc-field">
                <CCLabel title="SubStatus" />
                <Field
                  name={getNameOfPremises("SubStatus_KWD")}
                  component={CCSearchComboBox}
                  data={premisesGeneralFormLovs?.SubStatus ?? []}
                  textField={nameOfKeyValuePacket("Value")}
                  dataItemKey={nameOfKeyValuePacket("Key")}
                  isUseDefaultOnchange
                  disabled={isInactive}
                />
              </div>
            </div>

            <div className="cc-form-cols-3">
              {divShowBedsVisible && (
                <div className="cc-field">
                  <CCLabel title="Number of beds" />
                  <Field
                    name={getNameOfPremises("NoOfBeds")}
                    component={CCNumericTextBox}
                    min={0}
                    disabled={isInactive}
                  />
                </div>
              )}
              {divShowVehiclesVisible && (
                <div className="cc-field">
                  <CCLabel title="Number of vehicles" />
                  <Field
                    name={getNameOfPremises("NoOfVehicles")}
                    component={CCNumericTextBox}
                    min={0}
                    disabled={isInactive}
                  />
                </div>
              )}
            </div>
          </section>
        </>
      )
    );
  }
);
