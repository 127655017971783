import { IBuildingRegistersSitingApprovalsFormData } from "@app/products/building/registers/siting-approvals/[id]/model";

export const mockSingleBuildingRegistersSitingApprovalsJSON: IBuildingRegistersSitingApprovalsFormData =
  {
    ID: 1,
    RegisterNumber: "8778",
    Contact: "Daniel M Green",
    Contact_Address: "4245  Paul Wayne Haggerty Road, Youngstown, Ohio",
    Contact_Contact: "danielgreen@hotmail.net",
    PreferredContact: "James K Baker",
    PreferredContact_Address:
      "4166  Stoney Lonesome Road, Salt Lake City, Utah",
    PreferredContact_Contact: "robert.hardwell@gmail.com",
    Address: "3288  Dancing Dove Lane, Whitestone, New York",
    Owner: "Andrew Target",
    Details: "theandrew@gmail.com",
    Zone: "Arizona",
    Overlay: "Something like that ",
    Description:
      "I think that I am a responsible and honest boy/girl who wants to do things successfully. I am punctual towards my work and do it before time. I believe that mutual cooperation is a way to success and like to help people whenever they seek my help. I am an average student and like to read books and play chess.",
    Officer: "Andrew Thoma",
    EnquiryType: "Council",
    AppNo: "15222",
    Lodged: "2020-08-14T18:57:20.898Z",
    Due: "2020-09-26T18:57:20.898Z",
    Complete: "2021-01-02T18:57:20.898Z",
    EventStart: "2021-01-02T18:57:20.898Z",
    EventEnd: "2021-01-02T18:57:20.898Z",
    Issued: "2021-01-02T18:57:20.898Z",
    Expires: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    FileNumber: "623",
    OutstandingFees: 1565,
    DebtorNumber: 16622,
    Status: "Active",
    StatDays: 260,
    CreatedDate: "Thur Jan 05 2020 14:09:46 GMT+0700 (Indochina Time)",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
  };
