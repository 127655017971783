import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { NewPPREnquiryButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/new-enquiry/_index";
import { PPRActionBarNavDropdown } from "@app/products/town-planning/ppr/components/action-bar/nav-dropdown/_index";
import { PPREnquiryBatchCompleteButton } from "@app/products/town-planning/ppr/enquiries/components/action-bar/buttons/batch-complete/_index";
import { PPREnquiryDeleteButton } from "@app/products/town-planning/ppr/enquiries/components/action-bar/buttons/delete/_index";
import { PPREnquiryUpdateStatusButton } from "@app/products/town-planning/ppr/enquiries/components/action-bar/buttons/update-status/_index";
import { colPPREnquiriesCurrentRegister } from "@app/products/town-planning/ppr/enquiries/current-register/config";
import { TOWN_PLANNING_PPR_ENQUIRIES_CURRENT_REGISTER_LIST_VIEW_URL } from "@app/products/town-planning/ppr/enquiries/current-register/contants";
import { PPREnquiries } from "@app/products/town-planning/ppr/enquiries/current-register/model";
import { PPREnquiryContactsTab } from "@app/products/town-planning/ppr/enquiries/_id/components/reference-sidebar/contact/_index";
import { PPREnquiryDetailTab } from "@app/products/town-planning/ppr/enquiries/_id/components/reference-sidebar/detail/_index";
import { PPREnquiryHistoryTab } from "@app/products/town-planning/ppr/enquiries/_id/components/reference-sidebar/history/_index";
import { PPREnquiryViewOnMapTab } from "@app/products/town-planning/ppr/enquiries/_id/components/reference-sidebar/view-on-map/_index";
import { TOWN_PLANNING_PPR_ENQUIRIES_ROUTE } from "@app/products/town-planning/ppr/enquiries/_id/constant";
import { pprRoute } from "@app/products/town-planning/ppr/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { getBookmarkDetail, getBookmarkDisplayName } from "../util";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "./util";

const nameOf = nameOfFactory<PPREnquiries>();

export default () => {
  useCCListViewActionBar({
    title: pprRoute.name,
    leftComponents: [<PPRActionBarNavDropdown />],
    centerComponents: [
      <NewPPREnquiryButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.Core}
        dataSetFilter={MAILMERGEDATASET.Register_PlanningEnquiry}
        recordType={RECORDTYPE.CORE_Register}
      />,
      <PPREnquiryDeleteButton />,
      <PPREnquiryUpdateStatusButton />,
      <PPREnquiryBatchCompleteButton />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={TOWN_PLANNING_PPR_ENQUIRIES_ROUTE}
        productType={PRODUCT_TYPE.TownPlanning}
        recordType={RECORDTYPE.TP_PPRApplication}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <PPREnquiryDetailTab /> },
      {
        title: "Map",
        component: <PPREnquiryViewOnMapTab />,
      },
      {
        title: "Contacts",
        component: <PPREnquiryContactsTab />,
      },
      {
        title: "History",
        component: <PPREnquiryHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colPPREnquiriesCurrentRegister}
      primaryField={nameOf("ID")}
      state={{
        group: [{ field: nameOf("VROffice"), dir: "asc" }],
      }}
      dataUrl={TOWN_PLANNING_PPR_ENQUIRIES_CURRENT_REGISTER_LIST_VIEW_URL}
      itemPerPage={100}
      pageSizes={[5, 10, 20, 50, 100, 200, "All"]}
    />
  );
};
