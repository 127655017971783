import { loadEnterReading } from "@app/products/property/meters/components/dialogs/enter-special-reading/api";
import { EnterReadingDialog } from "@app/products/property/meters/components/dialogs/enter-special-reading/components/dialogs/enter-reading/_index";
import { IEnterReadingDialogData } from "@app/products/property/meters/components/dialogs/enter-special-reading/components/dialogs/enter-reading/model";
import { colSpecialReadingEnterReading } from "@app/products/property/meters/components/dialogs/enter-special-reading/config";
import {
  IReadingGridData,
  ISpecialReadingDialogData,
} from "@app/products/property/meters/components/dialogs/enter-special-reading/model";
import { PROPERTY_REGISTERS_ROUTE } from "@app/products/property/registers/[id]/constant";
import { nameOfFactory } from "@common/utils/common";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface ISpecialReadingDialogProps {
  title?: string;
  onClose: () => void;
  onEnterAndExit: () => void;
  specialReadingData?: ISpecialReadingDialogData;
}

export const EnterSpecialReadingDialog = ({
  title = "Enter Special Reading",
  specialReadingData,
  onClose,
  onEnterAndExit,
}: ISpecialReadingDialogProps) => {
  const [showEnterReadingDialog, setShowEnterReadingDialog] = useState(false);
  const [colReading, setColReading] = useState<IColumnFields[]>([]);
  const [listSelectedReading, setListSelectedReading] =
    useState<IEnterReadingDialogData>({});
  const nameOf = nameOfFactory<ISpecialReadingDialogData>();
  const nameOfReadingGrid = nameOfFactory<IReadingGridData>();
  const location = useLocation();
  const path = /(.+)(\/\d+)/gi.exec(location.pathname) || [];

  useEffectOnce(() => {
    const newColumns = colSpecialReadingEnterReading.map(
      (itemField: IColumnFields) => {
        if (itemField.field !== nameOfReadingGrid("MeterNumber"))
          return itemField;
        return {
          ...itemField,
          handleOnClick: (dataItem: any) => {
            loadEnterReading(dataItem).then((data: any) => {
              if (!data) return;
              setListSelectedReading(data);
              setShowEnterReadingDialog(true);
            });
          },
        };
      }
    );
    if (path[1] === PROPERTY_REGISTERS_ROUTE) {
      setColReading(newColumns.filter((item) => item.field !== "Status"));
    } else {
      setColReading(newColumns.filter((item) => item.field !== "Utility"));
    }
  });

  return (
    <Form
      onSubmit={onEnterAndExit}
      initialValues={specialReadingData}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter, onSubmit, valid, modified, onChange } =
          formRenderProps;

        const onChangeEnterReading = (data: IReadingGridData) => {
          if (data) {
            const modifiedGrid: IReadingGridData[] = valueGetter("Reading").map(
              (item: IReadingGridData) => {
                if (item.MeterInstallationId !== data.MeterInstallationId)
                  return item;
                return {
                  ...item,
                  Comment: data.Comment,
                  Usage: data.Usage,
                  CurrentReading: data.CurrentReading,
                  CurrentReadingDate: data.CurrentReadingDate,
                };
              }
            );
            onChange("Reading", { value: modifiedGrid });
            onChange("_option.Reading.IsModified", { value: true });
          }
        };
        return (
          <FormElement>
            <CCDialog
              maxWidth="50%"
              maxHeight="80%"
              titleHeader={title}
              onClose={onClose}
              bodyElement={
                <div className="cc-special-reading-dialog cc-form">
                  {showEnterReadingDialog && (
                    <EnterReadingDialog
                      onClose={() => {
                        setShowEnterReadingDialog(false);
                      }}
                      onSubmit={(e) => {
                        onChangeEnterReading(e);
                        setShowEnterReadingDialog(false);
                      }}
                      readingValue={listSelectedReading!}
                    />
                  )}
                  <div className="cc-field-group">
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">Special read ID</label>
                        <Field
                          name={nameOf("MeterSpecialReadId")}
                          component={CCInput}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Account with special reading
                        </label>
                        <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">
                                Account reference
                              </label>
                              <Field
                                name={nameOf("AccountReference")}
                                component={CCInput}
                                readOnly
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Assessment number
                              </label>
                              <Field
                                name={nameOf("AssessmentNumber")}
                                component={CCInput}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">
                                Property address
                              </label>
                              <Field
                                name={nameOf("PropertyAddress")}
                                component={CCInput}
                                readOnly
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">Account name</label>
                              <Field
                                name={nameOf("AccountName")}
                                component={CCInput}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Reason for the special reading
                        </label>
                        <Field
                          name={nameOf("ReasonForTheSpecialReading")}
                          data={
                            valueGetter(
                              "_option.ReasonForTheSpecialReading.Data"
                            ) || []
                          }
                          textField="Value"
                          dataItemKey="Key"
                          component={CCSearchComboBox}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Comments</label>
                        <Field
                          name={nameOf("Comments")}
                          component={CCTextArea}
                          rows={3}
                          placeholder="Comments"
                        />
                      </div>
                    </div>

                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <label className="cc-label">Fee</label>
                        <Field
                          name={nameOf("Fee")}
                          component={CCCurrencyInput}
                          min={0}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Tax included</label>
                        <Field
                          name={nameOf("TaxIncluded")}
                          component={CCCurrencyInput}
                          min={0}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Journal number</label>
                        <Field
                          name={nameOf("JournalNumber")}
                          component={CCInput}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Readings
                          <CCTooltip type="validator" position="right" />
                        </label>

                        <CCGrid
                          className="cc-reading-grid"
                          data={valueGetter("Reading") || []}
                          columnFields={colReading}
                          primaryField={nameOfReadingGrid(
                            "MeterInstallationId"
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      !valueGetter("_option.Reading.IsModified") ||
                      !valid ||
                      !modified
                    }
                    name="EnterAndExit"
                    themeColor="primary"
                    className={"cc-dialog-button"}
                    type="button"
                    onClick={onSubmit}
                  >
                    Enter & Exit
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
