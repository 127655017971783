export const registerData = [
  { Key: 1, Value: "Cemetery" },
  { Key: 2, Value: "Item1" },
  { Key: 3, Value: "Item2" },
  { Key: 4, Value: "Item3" },
];

export const categoryData = [
  { Key: 1, Value: "Item1" },
  { Key: 2, Value: "Item2" },
  { Key: 3, Value: "Item3" },
];
