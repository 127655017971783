import { Comments } from "@app/core/comments/model";
import {
  PhoneMessage,
  SendToActionOfficerRequest,
} from "@app/products/crms/phone-messages/[id]/model";
import { useCRMSPhoneMessageStore } from "@app/products/crms/phone-messages/[id]/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

interface ICommentDialogProps {
  onClose: () => void;
  phoneMessage: PhoneMessage;
}
const nameOf = nameOfFactory<Comments>();

export const AddCommentPhoneMessageDialog = observer(
  ({ onClose, phoneMessage }: ICommentDialogProps) => {
    const {
      isLoadingSendToActionOfficer,
      notification,
      setNotification,
      sendToActionOfficerHandler,
    } = useCRMSPhoneMessageStore();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const { settings } = useCommonCoreStore();
    const actionOfficerLabel = getStringValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_Common_ActionOfficerLabel
      ]
    );
    const { id } = useParams<{ id: string }>();

    const initValue = { Title: "", Comment: "" };

    useEffect(() => {
      if (notification) {
        notificationRef.current?.pushNotification(notification, () =>
          setNotification(undefined)
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification]);

    return (
      <Form
        onSubmitClick={(submitEvent: FormSubmitClickEvent) => {
          sendToActionOfficerHandler(
            {
              CommentMessage: submitEvent?.values?.Comment ?? "",
              PhoneMessage: phoneMessage,
            } as SendToActionOfficerRequest,
            id
          );
        }}
        initialValues={initValue}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit } = formRenderProps;
          return (
            <CCDialog
              maxWidth="33%"
              height="auto"
              disabled={isLoadingSendToActionOfficer}
              titleHeader={
                actionOfficerLabel
                  ? `${actionOfficerLabel} Comment`
                  : "Action Officer Comment"
              }
              onClose={onClose}
              bodyElement={
                <FormElement className="cc-form cc-comment-dialog">
                  <section className="cc-field-group">
                    <CCLocalNotification ref={notificationRef} />
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <CCLabel title="Description" />
                        <Field
                          name={nameOf("Comment")}
                          component={CCTextArea}
                          placeholder="Description"
                          rows={4}
                        />
                      </div>
                    </div>
                  </section>
                </FormElement>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    themeColor="primary"
                    iconClass={
                      isLoadingSendToActionOfficer
                        ? "fas fa-spinner fa-spin"
                        : ""
                    }
                    disabled={
                      !formRenderProps?.valid || isLoadingSendToActionOfficer
                    }
                    className={"cc-dialog-button"}
                    onClick={onSubmit}
                  >
                    {`Send to ${actionOfficerLabel ?? "action officer"}`}
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
