import { IParcels } from "@app/products/property/registers/list/components/action-bar/form-steps/create-register/components/parcels/model";

export const mockParcel: IParcels[] = [
  {
    Address: "29 Byelenbrook Place, North Wonthaggi",
    Name: "",
    LegalDescription: "Lot 275 DP9217",
    ParcelId: 1,
    ParcelReference: 1003922,
  },
  {
    Address: "Shop 1/52 Byelenbrook Place Wonthaggi",
    Name: "",
    LegalDescription: "Lot 276 DP9217",
    ParcelId: 2,
    ParcelReference: 1003923,
  },
  {
    Address: "270 Abrehart St, Archies Creek",
    Name: "J A KOREVAAR",
    LegalDescription: "LOT 52 DP 13389",
    ParcelId: 3,
    ParcelReference: 1003924,
  },
  {
    Address: "27 Aberdeen St, Archies Creek",
    Name: "D J & K M BROWN",
    LegalDescription: "Lot 12 DP9217",
    ParcelId: 4,
    ParcelReference: 1003925,
  },
  {
    Address: "390 Aberdeen St, Archies Creek",
    Name: "D Page",
    LegalDescription: "Lot 16 DP9217",
    ParcelId: 5,
    ParcelReference: 1003926,
  },
];
