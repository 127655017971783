import { useAssessmentMeterStore } from "@app/products/property/assessments/[id]/components/child-screens/meters/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import ScheduleSpecialReadingsDialog from "@app/products/property/meters/list/components/action-bar/dialogs/schedule-special-readings/_index";
import { PROPERTY_METERS_SPECIAL_READING_LIST_ROUTE } from "@app/products/property/meters/special-reading-list/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ScheduleSpecialReadingsButton = observer(() => {
  const [showDialog, setShowDialog] = useState(false);
  const history = useHistory();
  const { assessment } = useAssessmentStore();
  const { id } = useParams<{ id: string }>();
  const { meter, loadMeter } = useAssessmentMeterStore();

  useEffectOnce(() => {
    if (!meter) {
      loadMeter();
    }
  });

  const initialDataDialog = useMemo(() => {
    if (!id) {
      return {};
    } else {
      return {
        PropertyAddress: assessment?.Assessment?.PropertyName_Address_Locality,
        Assessment: {
          AssessmentNumber: assessment?.Assessment.AssessmentNumber,
          AssessmentId: id,
          _searchByAssessmentNumber: `${assessment?.Assessment.AssessmentNumber} - ${assessment?.Assessment?.PropertyName_Address_Locality}`,
          _searchByAssessmentId: `${id} - ${assessment?.Assessment?.PropertyName_Address_Locality}`,
        },
        Meters: meter,
      };
    }
  }, [id, assessment, meter]);
  return (
    <>
      <CCNavButton
        title="Schedule special readings"
        onClick={() => setShowDialog(true)}
      />
      {showDialog && meter && (
        <ScheduleSpecialReadingsDialog
          onClose={() => setShowDialog(false)}
          onSubmit={() => {
            setShowDialog(false);
            history.push({
              pathname: `${PROPERTY_METERS_SPECIAL_READING_LIST_ROUTE}`,
              state: {
                notification: {
                  title: "Schedule special readings successfully",
                  type: "success",
                },
              },
            });
          }}
          initialDataDialog={initialDataDialog}
        />
      )}
    </>
  );
});
