import { ICCRoute } from "@common/constants/ICCRoute";
export const regionsRoute: ICCRoute = {
  path: "regions",
  name: "Regions",
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component:
        require("../../../../../app/products/town-planning/ppr/system-admin/regions/[id]/_index")
          .default,
    },
  ],
};
