import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSExternalUnresolvedByActionPersonBookmark = {
  getBookmarkListViewDisplayName() {
    return `${CRMS_PRODUCT_NAME} - External User Events`;
  },
  getBookmarkListViewDetail() {
    return `${CRMS_PRODUCT_NAME} - External User Events - Unresolved By Action Person`;
  },
};
