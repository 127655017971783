export const EventManagementFieldMapping = {
  EventBooking_ReferenceNo: "EventBooking_ReferenceNo",
  EventBooking_Description: "EventBooking_Description",
  EventBookingStart: "EventBookingStart",
  Facility_Name: "Facility_Name",
  RequestedBy: "RequestedBy",
  WaitingOn: "WaitingOn",
  EventBooking_Status: "EventBooking_Status",
  SubmittedDate: "SubmittedDate",
  ProcessedBy: "ProcessedBy",
  CRMSEventReferenceNumber: "CRMSEventReferenceNumber",
  ReferenceNumber: "ReferenceNumber",
  Description: "Description",
  CreatedDate: "CreatedDate",
  FeeType: "FeeType",
  AmountIssued: "AmountIssued",
  AmountOutstanding: "AmountOutstanding",
  CostCode: "CostCode",
  InvoiceNumber: "InvoiceNumber",
  DebtorNumber: "DebtorNumber",
  SecurePay_Reference: "SecurePay_Reference",
  AmountPaid: "AmountPaid",
  ReceiptNumber: "ReceiptNumber",
  ReceiptDate: "ReceiptDate",
  ReceiptedBy: "ReceiptedBy",
  Method: "Method",
  SentToFinanceDate: "SentToFinanceDate",
  SentToFinanceBatch: "SentToFinanceBatch",
  Facility_Status: "Facility_Status",
  MasterFacility_Name: "MasterFacility_Name",
  Capacity: "Capacity",
  Alcohol: "Alcohol",
  Catering: "Catering",
  AudioVisual: "AudioVisual",
  ChildFriendly: "ChildFriendly",
  EventType_Name: "EventType_Name",
  EventType_Description: "EventType_Description",
  Classification: "Classification",
  Level: "Level",
  Group: "Group",
  FeatureName: "FeatureName",
  DataType: "DataType",
  FeatureNameFacility: "FeatureNameFacility",
  FeatureNameEventBooking: "FeatureNameEventBooking",
  Status: "Status",
  LastName: "LastName",
  FirstName: "FirstName",
  OrganisationName: "OrganisationName",
  Mobile: "Mobile",
  HomePhone: "HomePhone",
  WorkPhone: "WorkPhone",
  Email: "Email",
  OSFees: "OSFees",
  FileNumber: "FileNumber",
  Name: "Name",
  Position: "Position",
  Phone: "Phone",
  RequestedBy_ContactDetails: "RequestedBy_ContactDetails",
  RequestedBy_Picker_Field: "RequestedBy_Picker_Field",
  BookingContact: "BookingContact",
  MasterFacility: "MasterFacility",
  EventTypes: "EventTypes",
  RequestedByName: "RequestedByName",
};
