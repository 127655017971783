import { IMailMergeSelection } from "@app/core/mail-merge/form-steps/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";

//TODO: to be removed, please use saveMailMergeDocument in new-mail-merge
/**
 * @deprecated
 */
export const saveMailMergeDocument = async (
  data: IMailMergeSelection,
  ProductType?: PRODUCT_TYPE_NUMBER
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/core/int/mailmerge/savemailmergedocument/${ProductType}`,
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
