import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const mailboxListRoute: ICCRoute = {
  path: "mailbox-list",
  name: "Mailbox List",
  enum: eMenuAction.Mailbox_List,
  children: [
    {
      path: "all",
      name: "All",
      enum: eMenuAction.Mailbox_List_All,
      component: require("./all/_index").default,
    },
  ],
};
