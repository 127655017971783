export const mockHazldCases = [
  {
    Locality: "Battery Point",
    HouseNumber: "23",
    Street: "Albuera\r\n",
    AssessmentNumber: "2214889",
    Owners: "I S Middleton & J M Middleton",
    InspectionDate: new Date("7-Jan-2021 17:04"),
    Clearance: "10m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("16-Jan-2022"),
    Date: new Date("07-Jan-2022"),
    Period: "2021/2022",
    ParcelId: 231040,
    CaseId: 210004,
  },
  {
    Locality: "Dynnyme",
    HouseNumber: "178",
    Street: "Wateworks",
    AssessmentNumber: "3067614",
    Owners: "D M Curtano",
    InspectionDate: new Date("30-Aug-2018 10:43"),
    Clearance: "20m Fee Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Sep-2018"),
    Date: new Date("30-Aug-2018"),
    Period: "2018/2019",
    ParcelId: 252510,
    CaseId: 12,
  },
  {
    Locality: "Dynnyme",
    HouseNumber: "19",
    Street: "Zomay",
    AssessmentNumber: "1842808",
    Owners: "J A Dunning",
    InspectionDate: new Date("5-Oct-2018 13:12"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("30-Oct-2018"),
    Date: new Date("05-Oct-2018"),
    Period: "2018/2019",
    ParcelId: 108750,
    CaseId: 33,
  },
  {
    Locality: "Dynnyme",
    HouseNumber: "11",
    Street: "Zomay",
    AssessmentNumber: "5682761",
    Owners: "D A Potter",
    InspectionDate: new Date("5-Oct-2018 12:52"),
    Clearance: "Clear Entire Property",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Date: new Date("05-Oct-2018"),
    Period: "2018/2019",
    ParcelId: 192915,
    CaseId: 32,
  },
  {
    Locality: "Fern Tree",
    HouseNumber: "14",
    Street: "Grays",
    AssessmentNumber: "5576260",
    Owners: "D C Mackie",
    InspectionDate: new Date("14-Aug-2018 14:09"),
    Clearance: "5m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("27-Oct-2018"),
    Date: new Date("14-Aug-2018"),
    Period: "2018/2019",
    ParcelId: 157810,
    CaseId: 4,
  },
  {
    Locality: "Fern Tree",
    HouseNumber: "791",
    Street: "Huon",
    AssessmentNumber: "5679920",
    Owners: "S D Johnson",
    InspectionDate: new Date("30-Aug-2018 10:33"),
    Clearance: "No Hazard",
    Outcome: "Cleared, No Hazard",
    Date: new Date("30-Aug-2018"),
    Period: "2018/2019",
    ParcelId: 159455,
    CaseId: 7,
  },
  {
    Locality: "Fern Tree",
    HouseNumber: "4",
    Street: "Stephenson",
    AssessmentNumber: "5575057",
    Owners: "C L Dergess",
    InspectionDate: new Date("3-Sep-2018 10:43"),
    Clearance: "No Hazard",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Date: new Date("30-Aug-2018"),
    Period: "2018/2019",
    ParcelId: 157265,
    CaseId: 6,
  },
  {
    Locality: "Glebe",
    HouseNumber: "20",
    Street: "Aberdeen",
    AssessmentNumber: "1228154",
    Owners: "B G Badger",
    InspectionDate: new Date("3-Sep-2018 13:12"),
    Clearance: "Clear Entire Property",
    Outcome: "No Hazard",
    Date: new Date("3-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 100135,
    CaseId: 17,
  },
  {
    Locality: "Glebe",
    HouseNumber: "1",
    Street: "Aberdeen",
    AssessmentNumber: "2572897",
    Owners: "J M Baniord",
    InspectionDate: new Date("21-Sep-2018 12:52"),
    Clearance: "No Hazard",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("20-Aug-2018"),
    Date: new Date("10-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 193120,
    CaseId: 24,
  },
  {
    Locality: "Glebe",
    HouseNumber: "9",
    Street: "Lilie",
    AssessmentNumber: "1854438",
    Owners: "D Rerbach & T Rernbach",
    InspectionDate: new Date("30-Aug-2018 14:09"),
    Clearance: "10m Fỉre Break",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("5-Sep-2018"),
    Date: new Date("30-Aug-2018"),
    Period: "2018/2019",
    ParcelId: 109140,
    CaseId: 5,
  },
  {
    Locality: "Hobart",
    HouseNumber: "12-16",
    Street: "Bathurst",
    Owners: "University Of Tasmaria",
    InspectionDate: new Date("30-Aug-2018 10:33"),
    Clearance: "30m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Date: new Date("30-Aug-2018"),
    Period: "2018/2019",
    ParcelId: 194430,
    CaseId: 10,
  },
  {
    Locality: "Lenah Valley",
    HouseNumber: "33",
    Street: "Alcides",
    AssessmentNumber: "5478337",
    Owners: "N M Tierney",
    InspectionDate: new Date("3-Sep-2018 10:51"),
    Clearance: "Clear Entire Property",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("27-Sep-2018"),
    Date: new Date("03-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 112945,
    CaseId: 18,
  },
  {
    Locality: "Lenah Valley",
    HouseNumber: "25",
    Street: "Alcides",
    AssessmentNumber: "5478388",
    Owners: "M J Zawadzkj ỈC z Zawadzki",
    InspectionDate: new Date("27-Sep-2018 9:07"),
    Clearance: "Clear Entire Property",
    Outcome: "Cleared by Contractor, No Hazard",
    Date: new Date("27-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 112965,
    CaseId: 28,
  },
  {
    Locality: "Lenah Valley",
    HouseNumber: "29",
    Street: "Alwyn",
    AssessmentNumber: "2657831",
    Owners: "C L Nichols & R Nchols",
    InspectionDate: new Date("27-Sep-2018 11:52"),
    Clearance: "No Hazard",
    Outcome: "No Hazard",
    Date: new Date("27-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 235020,
    CaseId: 30,
  },
  {
    Locality: "Lenah Valley",
    HouseNumber: "6",
    Street: "Yaizu",
    AssessmentNumber: "2025359",
    Owners: "A M Domelly",
    InspectionDate: new Date("5-Oct-2018 11:52"),
    Clearance: "No Hazard",
    Outcome: "No Hazard",
    Date: new Date("05-Oct-2018"),
    Period: "2018/2019",
    ParcelId: 112830,
    CaseId: 210002,
  },
  {
    Locality: "Mount Stuart",
    HouseNumber: "2",
    Street: "Abermere",
    AssessmentNumber: "5478142",
    Owners: "K F Doran",
    InspectionDate: new Date("12-Sep-2018 14:24"),
    Clearance: "No Hazard",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Date: new Date("12-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 112855,
    CaseId: 26,
  },
  {
    Locality: "New Town",
    HouseNumber: "1",
    Street: "Alfred",
    AssessmentNumber: "5478679",
    Owners: "M Broadby",
    InspectionDate: new Date("3-Sep-2018 8:58"),
    Clearance: "Clear Entire Property",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("5-Sep-2018"),
    Date: new Date("03-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 113095,
    CaseId: 16,
  },
  {
    Locality: "New Town",
    HouseNumber: "57",
    Street: "Montagu",
    AssessmentNumber: "3007670",
    Owners: "M J Gray",
    InspectionDate: new Date("30-Aug-2018 10:51"),
    Clearance: "Remove All Undergrowth",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Date: new Date("30-Aug-2018"),
    Period: "2018/2019",
    ParcelId: 128385,
    CaseId: 11,
  },
  {
    Locality: "Ridgeway",
    HouseNumber: "3",
    Street: "Ridgeway",
    AssessmentNumber: "5588931",
    Owners: "C Hawkins",
    InspectionDate: new Date("31-Aug-2018 15:19"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("25-Sep-2018"),
    Date: new Date("31-Aug-2018"),
    Period: "2018/2019",
    ParcelId: 163675,
    CaseId: 14,
  },
  {
    Locality: "Sandy Bay",
    HouseNumber: "12",
    Street: "Ada",
    AssessmentNumber: "2298074",
    Owners: "A M M Baker",
    InspectionDate: new Date("4-Sep-2018 14:10"),
    Clearance: "To be assessed",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("5-Sep-2018"),
    Date: new Date("04-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 233770,
    CaseId: 21,
  },
  {
    Locality: "Sandy Bay",
    HouseNumber: "6",
    Street: "Ada",
    AssessmentNumber: "5682581",
    Owners: "A Grillas",
    InspectionDate: new Date("4- Sep 2018 8:25"),
    Clearance: "Clear Entire Property",
    Outcome: "Site to be inspected",
    ToBeClearedBy: new Date("5-Sep-2018"),
    Date: new Date("04-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 206565,
    CaseId: 20,
  },
  {
    Locality: "Sandy Bay",
    HouseNumber: "2",
    Street: "Adams",
    AssessmentNumber: "5599593",
    Owners: "S J Hansen-Lohrey & C F Hansen-Lohrey",
    InspectionDate: new Date("5-Sep-2018 16:36"),
    Clearance: "Remove All Undergrowth",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Date: new Date("03-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 168440,
    CaseId: 23,
  },
  {
    Locality: "Sandy Bay",
    HouseNumber: "4",
    Street: "Adams",
    AssessmentNumber: "5699606",
    Owners: "R Newton",
    InspectionDate: new Date("2-Sep-2018 9:44"),
    Clearance: "Remove All Undergrowth",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("27-Sep-2018"),
    Date: new Date("02-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 168445,
    CaseId: 15,
  },
  {
    Locality: "Sandy Bay",
    HouseNumber: "2",
    Street: "Baden",
    AssessmentNumber: "5601200",
    Owners: "D R N Lnacre",
    InspectionDate: new Date("23-Sep-2018 10:19"),
    Clearance: "Remove All Undergrowth",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("25-Sep-2018"),
    Date: new Date("28-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 169190,
    CaseId: 31,
  },
  {
    Locality: "Sandy Bay",
    HouseNumber: "254",
    Street: "Churchil",
    AssessmentNumber: "5605797",
    Owners: "L Zheng",
    Date: new Date("05-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 171300,
    CaseId: 22,
  },
  {
    Locality: "Sandy Bay",
    HouseNumber: "4",
    Street: "Dervrentwater",
    AssessmentNumber: "3211524",
    Owners: "T Redmond & H K Lorenzen",
    InspectionDate: new Date("10-Sep-2018 17:11"),
    Clearance: "No Hazard",
    Outcome: "No Hazard",
    Date: new Date("30-Aug-2018"),
    Period: "2018/2019",
    ParcelId: 252540,
    CaseId: 9,
  },
  {
    Locality: "South Hobart",
    HouseNumber: "10",
    Street: "Adelaide",
    AssessmentNumber: "5563339",
    Owners: "A J Foster",
    InspectionDate: new Date("12-Sep-2018 10:39"),
    Clearance: "10m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Date: new Date("12-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 151985,
    CaseId: 25,
  },
  {
    Locality: "South Hobart",
    HouseNumber: "602",
    Street: "Huon",
    AssessmentNumber: "1879213",
    Owners: "A Cranenburgh",
    InspectionDate: new Date("19-Sep-2018 13:17"),
    Clearance: "10m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("20-Sep-2018"),
    Date: new Date("19-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 109715,
    CaseId: 27,
  },
  {
    Locality: "South Hobart",
    HouseNumber: "3",
    Street: "Lawley",
    AssessmentNumber: "5582644",
    Owners: "J M Allen & A Allen",
    InspectionDate: new Date("30-Aug-2018 10:35"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("27-Sep-2018"),
    Date: new Date("30-Aug-2018"),
    Period: "2018/2019",
    ParcelId: 160760,
    CaseId: 8,
  },
  {
    Locality: "West Hobart",
    HouseNumber: "4",
    Street: "Amelia",
    AssessmentNumber: "5637392",
    Owners: "S W Wood & L Wood",
    InspectionDate: new Date("27-Sep-2018 14:22"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Oct-2018"),
    Date: new Date("27-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 140145,
    CaseId: 29,
  },
  {
    Locality: "West Hobart",
    HouseNumber: "39",
    Street: "Hamilton",
    AssessmentNumber: "1813126",
    Owners: "C J Scott",
    InspectionDate: new Date("5-Oct-2018 13:30"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("4-Aug-2018"),
    Date: new Date("05-Oct-2018"),
    Period: "2018/2019",
    ParcelId: 107020,
    CaseId: 210003,
  },
  {
    Locality: "West Hobart",
    HouseNumber: "4",
    Street: "Stevens Farm",
    AssessmentNumber: "3283878",
    Owners: "D K McKay",
    InspectionDate: new Date("3-Sep-2018 14:34"),
    Clearance: "No Hazard",
    Outcome: "Owners to clear property",
    Date: new Date("02-Sep-2018"),
    Period: "2018/2019",
    ParcelId: 253925,
    CaseId: 19,
  },
  {
    Locality: "West Hobart",
    HouseNumber: "7",
    Street: "Stevens Farm",
    AssessmentNumber: "3283907",
    Owners: "G R Pearce & M J Pearce & M S Wtse & S M Wise",
    InspectionDate: new Date("20-Aug-2018 14:50"),
    Clearance: "No Hazard",
    Outcome: "Cleared, No Hazard",
    Date: new Date("10-Aug-2018"),
    Period: "2018/2019",
    ParcelId: 253940,
    CaseId: 3,
  },
  {
    Locality: "West Hobart",
    HouseNumber: "9",
    Street: "Stevens Farm",
    AssessmentNumber: "3378113",
    Owners: "S W Hennessy & C J E Hennessy",
    InspectionDate: new Date("19-Sep-2018 13:23"),
    Clearance: "Clear Entire Property",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("19-Sep-2018"),
    Date: new Date("31-Aug-2018"),
    Period: "2018/2019",
    ParcelId: 255050,
    CaseId: 13,
  },
  {
    Locality: "Battery Point",
    HouseNumber: "4-6",
    Street: "Albuera\r\n",
    Owners: "R J McIntyre & P A Mclntyre & Sphalanx Pty Ltd",
    InspectionDate: new Date("7-Jan-2021 17:04"),
    Clearance: "20m Fee Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("16-Jan-2022"),
    Date: new Date("07-Jan-2022"),
    Period: "2017/2018",
    ParcelId: 152320,
    CaseId: 2,
  },
  {
    Locality: "Dynnyme",
    HouseNumber: "3",
    Street: "Allambee",
    AssessmentNumber: "5653828",
    Owners: "P F Battaglene",
    InspectionDate: new Date("30-Aug-2018 10:43"),
    Clearance: "20m Fee Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("13-Sep-2018"),
    Date: new Date("30-Aug-2018"),
    Period: "2017/2018",
    ParcelId: 193345,
    CaseId: 1,
  },
  {
    Locality: "Dynnyme",
    HouseNumber: "6",
    Street: "Adelaide",
    AssessmentNumber: "5563312",
    Owners: "R A Nicholson & C R Studd",
    InspectionDate: new Date("5-Oct-2018 13:12"),
    Clearance: "10m Fee Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("30-Oct-2018"),
    Date: new Date("05-Oct-2018"),
    Period: "2017/2018",
    ParcelId: 151975,
    CaseId: 10002,
  },
];
