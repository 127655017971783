import {
  AnimalType,
  SVC_FeeCalculator_RegistrationFee_MatchMethod,
} from "@app/products/animals/model";
import {
  feeRuleTypeData,
  feeRulesTypeData,
  newRegistrationData,
  parametersData,
} from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/dialogs/test-fee/config";
import {
  NewRegistration,
  ParameterTypes,
  SVC_AnimalFeeParams,
  SVC_FeeCalculator_ResultStatus,
  Svc_CalculateRegistrationFee,
  TestFees,
} from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/dialogs/test-fee/model";
import {
  covertToStringWithBreakLine,
  getKeyByValue,
  getStatusFriendlyName,
} from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/dialogs/test-fee/util";
import { getRegistrationFeeCalculationMethodFriendlyName } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/util";
import { RegistrationFeeRulesType } from "@app/products/animals/system-admin/animal-types/[id]/model";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCCheckboxGroup } from "@components/cc-checkbox-group/_index";
import { ICCCheckboxGroupItem } from "@components/cc-checkbox-group/model";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import {
  RadioGroupChangeEvent,
  TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import { cloneDeep, isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import "./_index.scss";

interface ITestFeesDialogProps {
  onClose: () => void;
  animalTypeFormObject: AnimalType;
}

const nameOfKeyValuePacket = nameOfFactory<KeyValuePacket>();
const nameOfTestFees = nameOfFactory<TestFees>();

export const TestFeesDialog = observer(
  ({ onClose, animalTypeFormObject }: ITestFeesDialogProps) => {
    const {
      testFees,
      calculationNonRegistrationFeeRule,
      calculationRegistrationFeeRule,
      isLoadingCalculationFee,
      setTestFees,
    } = useAnimalTypeStore();
    const [feeRuleType, setFeeRuleType] = useState(
      RegistrationFeeRulesType.RegistrationFeeRules
    );
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const testFeesDialogTitle = useMemo(() => {
      let title = "Test Fees";
      const methodEnum = animalTypeFormObject.FeeRulesList.Method_ENUM;

      if (
        methodEnum !== SVC_FeeCalculator_RegistrationFee_MatchMethod.Default
      ) {
        const methodName =
          getRegistrationFeeCalculationMethodFriendlyName(methodEnum);
        title += `${!isEmpty(methodName) ? ` - ${methodName}` : ""}`;
      }
      return title;
    }, [animalTypeFormObject.FeeRulesList.Method_ENUM]);

    // Handle for submit dialog.
    const handleOnSubmit = (event: FormSubmitClickEvent) => {
      const formData: TestFees = cloneDeep(event.values) as TestFees;

      if (feeRuleType === RegistrationFeeRulesType.RegistrationFeeRules) {
        // Generate object Parameters
        const parameters = formData.Parameters?.reduce(
          (parametersObj, parameterItem: ICCCheckboxGroupItem) => {
            return {
              ...parametersObj,
              [getKeyByValue(parameterItem.label, ParameterTypes)]: true,
            };
          },
          {}
        );

        const newRegistration = formData.NewRegistration?.reduce(
          (newRegistrationObj, newRegistrationItem: ICCCheckboxGroupItem) => {
            return {
              ...newRegistrationObj,
              [getKeyByValue(newRegistrationItem.label, NewRegistration)]: true,
            };
          },
          {}
        );

        const calculateRegistrationFeeRequest: Svc_CalculateRegistrationFee = {
          FeeParams: {
            ...parameters,
            ...newRegistration,
          } as SVC_AnimalFeeParams,
          ObjAnimalType: animalTypeFormObject,
          AnimalDOB: formData.AnimalDOB,
          CalculationDate: formData.CalculationDate,
        };

        calculationRegistrationFeeRule(
          calculateRegistrationFeeRequest,
          notificationRef
        );
      } else if (
        feeRuleType === RegistrationFeeRulesType.NonRegistrationFeeRules
      ) {
        calculationNonRegistrationFeeRule(
          formData.NonRegistrationFeeRuleType,
          animalTypeFormObject,
          notificationRef
        );
      }
    };

    return (
      <Form
        initialValues={{ CalculationDate: new Date() }}
        onSubmitClick={handleOnSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, valid, onSubmit, onChange } = formRenderProps;
          // Handle on change field
          const handleOnChangeFeeRuleType = (event: RadioGroupChangeEvent) => {
            setFeeRuleType(event.value);
            onChange(nameOfTestFees("FeeRulesType"), { value: event.value });
            setTestFees({
              ...testFees,
              CalculationSteps: [],
              FeeType_Name: "",
              TotalAmount: null,
              Status_ENUM: SVC_FeeCalculator_ResultStatus.Default,
            } as TestFees);
          };

          const handleOnChangeNonRegistrationFeeRuleType = (
            event: ComboBoxChangeEvent
          ) => {
            onChange(nameOfTestFees("NonRegistrationFeeRuleType"), {
              value: event.value.Key,
            });
          };

          const handleOnChangeNewRegistration = (
            value: ICCCheckboxGroupItem[]
          ) => {
            onChange(nameOfTestFees("NewRegistration"), { value });
          };

          const handleOnChangeParameters = (value: ICCCheckboxGroupItem[]) => {
            onChange(nameOfTestFees("Parameters"), { value });
          };

          const handleOnChangeCalculationSteps = (
            event: TextAreaChangeEvent
          ) => {
            setTestFees({
              ...testFees,
              CalculationSteps: [event.value],
            } as TestFees);
          };

          return (
            <CCDialog
              maxWidth="45%"
              height={"auto"}
              titleHeader={testFeesDialogTitle}
              onClose={onClose}
              bodyElement={
                <FormElement className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <CCLabel title="Rule type" />
                        <Field
                          name={nameOfTestFees("FeeRulesType")}
                          layout={"horizontal"}
                          component={CCRadioGroup}
                          value={feeRuleType}
                          onChange={handleOnChangeFeeRuleType}
                          data={feeRulesTypeData}
                          disabled={isLoadingCalculationFee}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="cc-divider" />
                  {feeRuleType ===
                    RegistrationFeeRulesType.RegistrationFeeRules && (
                    <div className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <CCLabel title="Parameters" />
                          <Field
                            name={nameOfTestFees("Parameters")}
                            layout={"horizontal"}
                            component={CCCheckboxGroup}
                            value={valueGetter("Parameters")}
                            onDataChange={handleOnChangeParameters}
                            className="cc-checkbox-parameters"
                            data={parametersData}
                            disabled={isLoadingCalculationFee}
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel title="New registration" />
                          <Field
                            name={nameOfTestFees("NewRegistration")}
                            layout={"horizontal"}
                            component={CCCheckboxGroup}
                            className="cc-checkbox-parameters"
                            value={valueGetter("NewRegistration")}
                            onDataChange={handleOnChangeNewRegistration}
                            data={newRegistrationData}
                            disabled={isLoadingCalculationFee}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-3">
                        <div className="cc-field">
                          <CCLabel title="Animal date of birth" />
                          <Field
                            name={nameOfTestFees("AnimalDOB")}
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                            disabled={isLoadingCalculationFee}
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel title="Calculation date" isMandatory />
                          <Field
                            name={nameOfTestFees("CalculationDate")}
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                            disabled={isLoadingCalculationFee}
                            validator={requiredValidator}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-4">
                        <Button
                          iconClass={
                            isLoadingCalculationFee
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                          className="cc-dialog-button"
                          disabled={!valid || isLoadingCalculationFee}
                          onClick={onSubmit}
                        >
                          Calculate fee
                        </Button>
                      </div>
                    </div>
                  )}
                  {feeRuleType ===
                    RegistrationFeeRulesType.NonRegistrationFeeRules && (
                    <div className="cc-field-group">
                      <div className="cc-form-cols-3">
                        <div className="cc-field">
                          <CCLabel title="Fee type" isMandatory />
                          <Field
                            name={nameOfTestFees("NonRegistrationFeeRuleType")}
                            component={CCComboBox}
                            textField={nameOfKeyValuePacket("Value")}
                            dataItemKey={nameOfKeyValuePacket("Key")}
                            data={feeRuleTypeData}
                            onChange={handleOnChangeNonRegistrationFeeRuleType}
                            value={getDropdownValue(
                              valueGetter("NonRegistrationFeeRuleType"),
                              feeRuleTypeData ?? [],
                              nameOfKeyValuePacket("Key")
                            )}
                            validator={requiredValidator}
                            disabled={isLoadingCalculationFee}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-4">
                        <Button
                          iconClass={
                            isLoadingCalculationFee
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                          className="cc-dialog-button"
                          onClick={onSubmit}
                          disabled={!valid || isLoadingCalculationFee}
                        >
                          Calculate fee
                        </Button>
                      </div>
                    </div>
                  )}
                  <hr className="cc-divider" />
                  <div className="cc-field-group">
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCValueField
                          name={nameOfTestFees("FeeType_Name")}
                          label="Fee type"
                          value={testFees?.FeeType_Name}
                        />
                      </div>
                      <div className="cc-field">
                        <CCValueField
                          name={nameOfTestFees("TotalAmount")}
                          label="Fee amount"
                          format={CURRENCY_FORMAT.CURRENCY1}
                          value={testFees?.TotalAmount}
                        />
                      </div>
                      <div className="cc-field">
                        <CCValueField
                          name={nameOfTestFees("Status_ENUM")}
                          label="Message"
                          value={getStatusFriendlyName(
                            testFees?.Status_ENUM ?? 0
                          )}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <CCLabel title="Calculation steps" />
                        <Field
                          name={nameOfTestFees("CalculationSteps")}
                          value={covertToStringWithBreakLine(
                            testFees?.CalculationSteps ?? []
                          )}
                          onChange={handleOnChangeCalculationSteps}
                          component={CCTextArea}
                          rows={5}
                          disabled={isLoadingCalculationFee}
                        />
                      </div>
                    </div>
                  </div>
                </FormElement>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    className="cc-dialog-button"
                    onClick={onClose}
                    disabled={isLoadingCalculationFee}
                  >
                    Cancel
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
