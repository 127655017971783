import { CORE_GET_TRIM_CONTAINERS_ODATA } from "@app/core/components/common/constant";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { Svc_SharepointContentType } from "@common/models/corporateSetting";
import { nameOfFactory } from "@common/utils/common";
import { ICCGridProps } from "@components/cc-grid/_index";

export const getTrimContainerODataURL = (
  searchText: string = "",
  showNone: boolean = true,
  electronicOnly: boolean = false
) =>
  `${CORE_GET_TRIM_CONTAINERS_ODATA}(searchText='${searchText}',showNone=${showNone},electronicOnly=${electronicOnly})?$count=true&`;

export const getSharepointContentTypeInputPickerOption = (
  option?: Partial<IOptionInputPicker>,
  grid?: Partial<ICCGridProps>,
  siteCollectionURL: string = ""
): IOptionInputPicker => {
  const nameOfSharepointContentType =
    nameOfFactory<Svc_SharepointContentType>();
  return {
    dialog: {
      titleHeader: option?.dialog?.titleHeader ?? "SharePoint Content Types",
      maxWidth: option?.dialog?.maxWidth ?? "60%",
    },
    boxSearch: option?.boxSearch ?? {
      colSearch: nameOfSharepointContentType("Title"),
      colShowComboboxSearch: [
        {
          title: "Name",
          field: nameOfSharepointContentType("Title"),
        },
      ],
    },
    grid: {
      columnFields: [
        { title: "ID", field: nameOfSharepointContentType("ID") },
        {
          title: "Name",
          field: nameOfSharepointContentType("Title"),
        },
      ],
      primaryField: nameOfSharepointContentType("ID"),
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: [{ field: nameOfSharepointContentType("Title"), dir: "asc" }],
      },
      ...grid,
    },
  };
};
