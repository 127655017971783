import { mockRegistersList } from "@app/products/property/registers/list/mock";
import { SearchField } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/transfer/model";
import { sleep } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";

export const loadSearchAccount = async (filter: FilterDescriptor) => {
  const accountList = mockRegistersList.map((account: any) => {
    return {
      ...account,
      [SearchField.AccountNo]: account.AccountReference + " - " + account.Name,
    };
  });

  const data = accountList.slice();
  await sleep(1000);
  return filterBy(data, filter);
};
