import { loadMeterReadingDevicesDetailTabById } from "@app/products/property/meters/reading-devices/[id]/components/reference-sidebar/detail/api";
import { IReferenceSideBarDetails } from "@app/products/property/meters/reading-devices/[id]/components/reference-sidebar/detail/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const MeterReadingDevicesDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [meterReadingDeviceInfo, setMeterReadingDeviceInfo] =
    useState<IReferenceSideBarDetails>();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const readingDeviceId = params.id || lastSelectedRow.ReadingDeviceId;

  useEffect(() => {
    setIsLoading(true);
    if (!readingDeviceId) {
      setIsLoading(false);
      return;
    }
    loadMeterReadingDevicesDetailTabById(readingDeviceId).then((data) => {
      if (!data) return;
      setMeterReadingDeviceInfo(data);
      setIsLoading(false);
    });
  }, [readingDeviceId]);

  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Device Name:"}
          value={meterReadingDeviceInfo?.DeviceName}
        />
        <ProductReferenceRow
          title={"Device Host Name:"}
          value={meterReadingDeviceInfo?.DeviceName}
        />
        <ProductReferenceRow
          title={"Assigned Reader:"}
          value={meterReadingDeviceInfo?.AssignedReader}
        />
        <ProductReferenceRow
          title={"Active Meters:"}
          value={meterReadingDeviceInfo?.ActiveMeters}
        />
        <ProductReferenceRow
          title={"Standard Readings:"}
          value={meterReadingDeviceInfo?.StandardReadings}
        />
        <ProductReferenceRow
          title={"Special Readings:"}
          value={meterReadingDeviceInfo?.SpecialReadings}
        />
        <ProductReferenceRow
          title={"Reading waiting Verification:"}
          value={meterReadingDeviceInfo?.ReadingsWaitingVerification}
        />
        <ProductReferenceRow
          title={"Readings Voided:"}
          value={meterReadingDeviceInfo?.ReadingsVoided}
        />
        <ProductReferenceRow
          title={"Standard Reading Required:"}
          value={meterReadingDeviceInfo?.StandardReadingRequired}
        />
        <ProductReferenceRow
          title={"Special Reading Required:"}
          value={meterReadingDeviceInfo?.SpecialReadingRequired}
        />
        <ProductReferenceRow
          title={"Reading Device ID:"}
          value={meterReadingDeviceInfo?.ReadingDeviceId}
        />
        <ProductReferenceRow
          image={require("@/assets/img/MeterReadingDevices.png")}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
