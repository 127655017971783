import { mockParkingPermitForm } from "@app/products/parking-permits/[id]/mock";
import { IParkingPermitsForm } from "@app/products/parking-permits/[id]/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";

export const getParkingPermitsById = async (
  id: number
): Promise<APIResponse<IParkingPermitsForm | undefined>> => {
  await sleep(1000);
  return {
    data: {
      ...mockParkingPermitForm,
      ID: id,
    } as IParkingPermitsForm,
    status: APIResponseStatus.SUCCESS,
    statusText: "Ok",
    headers: {},
    config: {},
  };
};
