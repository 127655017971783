import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { StateRoad } from "@common/pages/settings/lookups/state-roads/_id/model";

export const getStateRoadById = async (
  id: number
): Promise<APIResponse<StateRoad | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<StateRoad>(
      `api/core/internal/stateroad/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postStateRoad = async (
  requestBody: StateRoad
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post<StateRoad>(
      `api/core/internal/stateroad`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
