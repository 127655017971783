import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { VO_Compliance_Candidates } from "@app/products/property/assessments/compliance/candidates/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Compliance_Candidates>();
export const complianceCandidateColList: IColumnFields[] = [
  {
    field: nameOf("Assess_Reference"),
    title: "Assessment (S-S-B-U)",
    locked: true,
    linkTo: (dataItem: VO_Compliance_Candidates) => ({
      pathname: `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Assessment_Id}`,
      state: { isExpandedRating: true },
    }),
  },
  { field: nameOf("NA_Name"), title: "Ratepayer" },
  { field: nameOf("NA_Address_Locality"), title: "Address" },
  {
    field: nameOf("Property_Name_Address_Locality"),
    title: "Property Address",
  },
  {
    field: nameOf("Last_Change_of_Ownership_Date"),
    title: "Last Change of Ownership",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Last_Compliance_Date"),
    title: "Last Compliance Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Land_Tax_Exemption_Id"),
    title: "Land Tax Exemption ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Name_Address_Id"),
    title: "Name Address ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
