import { Application } from "@app/products/town-planning/ppr/[id]/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class LodgeApplicationStore {
  private _isLoading: boolean = false;
  private _isShowDialog: boolean = false;
  private _newApplication?: Application = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isShowDialog() {
    return toJS(this._isShowDialog);
  }
  setIsShowDialog = (isShowDialog: boolean) => {
    runInAction(() => {
      this._isShowDialog = isShowDialog;
    });
  };

  get newApplication() {
    return toJS(this._newApplication);
  }
  setNewApplication = (newApplication?: Application) => {
    runInAction(() => {
      this._newApplication = newApplication;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isShowDialog = false;
      this._isLoading = false;
    });
  };
}
export const lodgeApplicationStore = new LodgeApplicationStore();
const lodgeApplicationStoreContext = createContext(lodgeApplicationStore);
export const useLodgeApplicationStore = () => {
  return useContext(lodgeApplicationStoreContext);
};
