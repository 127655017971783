import { getInitialModifyAreaOfResponsibility } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/api";
import { DTO_Step_Details_LOVs } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/components/element-steps/_step-details/model";
import {
  DTO_Workflow_AreaOfResponsibilityModify,
  IModifyAreaOfResponsibilityInfo,
  IWorkflowDataAreaOfResponsibilityModify,
} from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/model";
import {
  DTO_Certificate_LOVs,
  DTO_LOV_UDAL,
} from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/model";

import { DTO_WorkflowHeader } from "@app/products/property/model";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ResponsePacket } from "@common/models/identityPacket";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { cloneDeep, isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ModifyAreaOfResponsibilityStore {
  private _workflowInitData?: DTO_Workflow_AreaOfResponsibilityModify =
    undefined;
  private _dialogInfo?: IModifyAreaOfResponsibilityInfo = undefined;
  private _isLoading?: boolean = false;
  private _workflowDraftId: number = 0;
  private _workflowHeader: DTO_WorkflowHeader = {
    WorkflowDraft: { Workflow_Draft_Id: 0 },
    AvailableSecondaryWorkflows: [],
    WorkflowApprovals: [],
  };
  private _stepDetailStepLOVs?: DTO_Step_Details_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get workflowDraftId() {
    return toJS(this._workflowDraftId);
  }
  setWorkflowDraftId = (id: number) => {
    runInAction(() => {
      this._workflowDraftId = id;
    });
  };

  get workflowHeader() {
    return toJS(this._workflowHeader);
  }
  setWorkflowHeader = (data: DTO_WorkflowHeader) => {
    runInAction(() => {
      this._workflowHeader = data;
    });
  };

  get workflowInitData() {
    return toJS(this._workflowInitData);
  }
  private setWorkflowInitData = (
    data?: DTO_Workflow_AreaOfResponsibilityModify
  ) => {
    runInAction(() => {
      this._workflowInitData = data;
    });
  };

  get dialogInfo() {
    return toJS(this._dialogInfo);
  }
  private setDialogInfo = (data?: IModifyAreaOfResponsibilityInfo) => {
    runInAction(() => {
      this._dialogInfo = data;
    });
  };

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get stepDetailStepLOVs() {
    return toJS(this._stepDetailStepLOVs);
  }
  setStepDetailStepLOVs = (data?: DTO_Step_Details_LOVs) => {
    runInAction(() => {
      this._stepDetailStepLOVs = data;
    });
  };

  //Action
  getWorkflowData = async (props: IWorkflowDataAreaOfResponsibilityModify) => {
    appNotificationStore.clearErrorNotification();
    this.setIsLoading(true);
    const response = await getInitialModifyAreaOfResponsibility(
      props?.workflowDraftId,
      props?.isFromActionList,
      isNil(props?.certificateId) ? undefined : +props?.certificateId,
      props?.aorId
    );
    this.setIsLoading(false);
    if (Array.isArray(response)) {
      const [lovsCertificate, workflowData] = response;
      if (
        isSuccessResponse(lovsCertificate) &&
        isSuccessResponse(workflowData) &&
        lovsCertificate?.data &&
        workflowData?.data
      ) {
        //process group by UDA_Attribute_Id UDA LOV
        let udaDataLovs: any = cloneDeep(lovsCertificate?.data?.UDAL);
        udaDataLovs = udaDataLovs?.reduce((group: any, item: DTO_LOV_UDAL) => {
          const { UDA_Attribute_Id } = item;
          group[UDA_Attribute_Id] = group[UDA_Attribute_Id] || [];
          group[UDA_Attribute_Id].push(item);
          return group;
        }, {});
        const dataUDA =
          workflowData?.data?.WorkflowDetail?.AreaOfResponsibility?.UDAL ?? [];
        const configField = dataUDA?.[0];
        // set Lovs Data
        this.setStepDetailStepLOVs({
          UDAL: configField,
          UDALOvs: udaDataLovs,
        });
        if (workflowData.data?.WorkflowHeader) {
          this.setWorkflowHeader(workflowData.data?.WorkflowHeader);
          this.setWorkflowDraftId(
            workflowData.data?.WorkflowHeader?.WorkflowDraft
              ?.Workflow_Draft_Id ?? 0
          );
        }
        this.setDialogInfo({
          titleDialog:
            workflowData?.data?.WorkflowDetail?.AreaOfResponsibility
              ?.AOR_Name ?? "",
          udaId:
            workflowData?.data?.WorkflowDetail?.AreaOfResponsibility
              ?.UDA_Attribute_Id,
        });
        this.setWorkflowInitData(workflowData?.data);
      } else {
        let responseError: APIResponse<
          | ResponsePacket
          | DTO_Certificate_LOVs
          | DTO_Workflow_AreaOfResponsibilityModify
        > = response[0];
        if (!isSuccessResponse(response[1])) {
          responseError = response[1];
        }
        appNotificationStore.pushNotification({
          type: "error",
          autoClose: false,
          title:
            (responseError.data as ResponsePacket)?.Errors ??
            "Load workflow failed",
        });
        this.resetStore();
      }
    } else {
      appNotificationStore.pushNotification({
        type: "error",
        autoClose: false,
        title: "Load workflow failedssss",
      });
    }
  };

  resetStore = () => {
    this._workflowInitData = undefined;
    this._dialogInfo = undefined;
    this._isLoading = false;
    this._workflowDraftId = 0;
    this._workflowHeader = {
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    };
  };
}

const modifyAreaOfResponsibilityStoreContext = createContext(
  new ModifyAreaOfResponsibilityStore()
);
export const useModifyAreaOfResponsibilityStore = () => {
  return useContext(modifyAreaOfResponsibilityStoreContext);
};
