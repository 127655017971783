import { ICCRoute } from "@common/constants/ICCRoute";

export const recordsRoute: ICCRoute = {
  path: "records",
  name: "Records",
  children: [
    {
      path: "documents",
      name: "Documents",
      component: require("./documents/_index").default,
    },
    {
      path: "documents-no-file-number",
      name: "Documents - No File Number",
      component: require("./documents-no-file-number/_index").default,
    },
  ],
};
