import {
  IAlternateAddress,
  IProprietor,
  IPurchaser,
} from "@app/products/property/certificates/e-certificate-requests/[id]/components/child-screens/general/components/form-element/model";

export const mockProprietor: IProprietor[] = [
  { ProprietorNames: "Bomm, Marina Eugena" },
];
export const mockPurchaser: IPurchaser[] = [
  { PurchaserNames: "Cooney, Adam Nicolas" },
];
export const mockAlternateAddresses: IAlternateAddress[] = [];
