import { ChangeViewDialog } from "@app/products/property/advanced_search/[id]/components/action-bar/dialogs/change-view/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const ChangeViewButton = () => {
  const [showNewSearchDialog, setShowNewSearchDialog] =
    useState<boolean>(false);
  return (
    <>
      <CCNavButton
        title="Change View"
        onClick={() => {
          setShowNewSearchDialog(true);
        }}
      />
      {showNewSearchDialog && (
        <ChangeViewDialog
          onClose={() => {
            setShowNewSearchDialog(false);
          }}
        />
      )}
    </>
  );
};
