import {
  IPropertyHistoryTabCommonProps,
  PropertyHistoryTabCommon,
} from "@app/products/property/components/reference-sidebar/property-history/common/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

interface IPropertyHistoryProps extends IPropertyHistoryTabCommonProps {
  /**
   * This field is used to retrieve an ID when the ID you want to use difference from primaryField in CCProductListview.
   * If not provided, it will default to obtaining the primaryField in CCProductListview.
   * Rule: the ID in listview and manage page must be the same
   */
  primaryField?: string;
}

export const PropertyHistoryTab = observer((props: IPropertyHistoryProps) => {
  const { lastSelectedId, lastSelectedRow } = useCCProductListViewStore();
  const { primaryField } = props;
  const recordID: string | number = primaryField
    ? lastSelectedRow?.[primaryField]
    : lastSelectedId;
  return <PropertyHistoryTabCommon {...props} id={recordID?.toString()} />;
});
