import {
  eOptionsAssessment,
  IPopulateCandidateLandRentDataDialog,
} from "@app/products/property/assessments/compliance/candidates-land-rent/model";
import { useComplianceCandidateLandStore } from "@app/products/property/assessments/compliance/candidates-land-rent/store";
import { formatAssessmentNumberInMasked } from "@common/utils/formatting";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCMaskedTextBox } from "@components/cc-masked-textbox/_index";
import { CCNote } from "@components/cc-note/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IPopulateCandidatesLandRentDialog {
  onClose: () => void;
}

export const PopulateCandidatesLandRentDialog = observer(
  ({ onClose }: IPopulateCandidatesLandRentDialog) => {
    const { loadCandidateLand, populateCandidateLandData } =
      useComplianceCandidateLandStore();
    const initialValues = useMemo(() => {
      return populateCandidateLandData
        ? cloneDeep(populateCandidateLandData)
        : { Assessment: eOptionsAssessment.ASSESSMENT_WITH_REVIEW };
    }, [populateCandidateLandData]);
    const dataOptionsAssessment = [
      {
        label:
          "Assessment where postal address is different from property address",
        value: eOptionsAssessment.ASSESSMENT_WITH_REVIEW,
      },
      {
        label: "Assessment where annual review form has not been submitted",
        value: eOptionsAssessment.ASSESSMENT_WITHOUT_REVIEW,
      },
    ];
    const handleSubmit = (values: IPopulateCandidateLandRentDataDialog) => {
      loadCandidateLand(
        values?.FromAssessment,
        values?.ToAssessment,
        values?.Assessment
      );
    };
    return (
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { modified, valid, valueGetter, onChange } = formRenderProps;
          const handleOnChange = (event: any, assessmentNameField: string) => {
            const rawDataOnlyNumber =
              event.target.rawValue?.replace(/\D/g, "") ?? "";
            const newValue = formatAssessmentNumberInMasked(rawDataOnlyNumber);
            if (valueGetter(assessmentNameField) !== newValue) {
              onChange(assessmentNameField, {
                value: newValue,
              });
            }
          };
          return (
            <CCDialog
              titleHeader={"Populate Compliance Candidates (Land Rent)"}
              onClose={onClose}
              maxHeight="42%"
              maxWidth="40%"
              bodyElement={
                <div className="cc-populate-candidates-land-rent-dialog cc-form">
                  <FormElement className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <section className="cc-field-group cc-populate-candidates-assessments">
                        <label className="cc-label">
                          Assessments
                          <CCTooltip
                            type={"info"}
                            position="auto"
                            content={"S-S-B-U format (e.g. 123-456-7890-123)"}
                          />
                        </label>
                        <div className="cc-custom-sub-panel-bar">
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">From</label>
                              <Field
                                name="FromAssessment"
                                component={CCMaskedTextBox}
                                mask="000-000-0000-000"
                                onChange={(event: any) =>
                                  handleOnChange(event, "FromAssessment")
                                }
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">To</label>
                              <Field
                                name="ToAssessment"
                                component={CCMaskedTextBox}
                                mask="000-000-0000-000"
                                onChange={(event: any) =>
                                  handleOnChange(event, "ToAssessment")
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="cc-field-group ">
                        <div className="cc-custom-sub-panel-bar">
                          <div className="cc-field">
                            <Field
                              name={"Assessment"}
                              data={dataOptionsAssessment}
                              component={RadioGroup}
                            />
                          </div>
                        </div>
                      </section>
                      <CCNote
                        message={
                          <>
                            <span>
                              Identity Assessments with a Residential land rent
                              calculated based on discounted rate.
                            </span>
                            <br />
                            <span>
                              Enter an assessment range if required and select
                              Populate Candidates to display results.
                            </span>
                          </>
                        }
                      />
                    </div>
                  </FormElement>
                </div>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={!modified || !valid}
                    onClick={formRenderProps.onSubmit}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
