import { odataCoreGetBondTypes } from "@app/core/components/common/utils";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { CURRENCY_FORMAT, ICON_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IGenerateColFieldBySetting } from "@common/models/settings";
import { FEE_TYPE_ROUTE } from "@common/pages/settings/lookups/fee-types/constant";
import {
  Svc_FeeTypeView,
  Svc_PickBondType,
} from "@common/pages/settings/lookups/fee-types/model";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep, isEmpty, isNil } from "lodash";

const nameOf = nameOfFactory<Svc_FeeTypeView>();
export const feeTypeGridColumn: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    linkTo: (dataItem: Svc_FeeTypeView) => {
      return `${FEE_TYPE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("DefaultAmount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Flag_GSTApplies"),
    title: "GST",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: nameOf("AccountCode"),
    title: "Ledger Code",
  },
  {
    field: nameOf("CategoryCode"),
    title: "Category Code",
  },
  {
    field: nameOf("ProductID"),
    title: "Product ID",
  },
  {
    field: nameOf("Fee"),
    title: "Fee",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: nameOf("Refund"),
    title: "Refund",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: nameOf("Bond"),
    title: "Bond",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: nameOf("Subsidy"),
    title: "Subsidy",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: nameOf("ModulesUsed"),
    title: "Modules Used",
  },
];

export const generateColFieldBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateColFieldBySetting): IColumnFields[] => {
  if (!settings) return defaultConfig;
  const subsidyIsEnabled = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_Enable_Subsidies]
  );
  const subsidyLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_Subsidies_Label]
  );
  let subsidyLabelSetting =
    subsidyIsEnabled && !isNil(subsidyLabel) && !isEmpty(subsidyLabel)
      ? subsidyLabel
      : "Subsidy";
  let newConfig: IColumnFields[] = cloneDeep(defaultConfig);
  newConfig.forEach((col) => {
    switch (col.field) {
      case nameOf("Subsidy"):
        return (col.title = subsidyLabelSetting);
    }
  });
  return newConfig;
};

const nameOfPickBond = nameOfFactory<Svc_PickBondType>();
export const renderOptionBondTypePicker = () => {
  const optionRequestor: IOptionInputPicker = {
    dialog: {
      titleHeader: "Manage Bond Type",
      maxWidth: "60%",
    },
    boxSearch: {
      colSearch: nameOfPickBond("Code"),
      colShowComboboxSearch: [
        {
          title: "Code",
          field: nameOfPickBond("Code"),
        },
      ],
    },
    grid: {
      columnFields: [
        { title: "Code", field: nameOfPickBond("Code") },
        { title: "Description", field: nameOfPickBond("Description") },
        {
          title: "Inspection Required",
          field: nameOfPickBond("InspectionRequired"),
          format: ICON_FORMAT.ICON_BOOLEAN,
        },
      ],
      primaryField: nameOfPickBond("Code"),
      dataUrl: odataCoreGetBondTypes(),
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
    },
  };

  return optionRequestor;
};
