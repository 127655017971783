import {
  IBuildingRecordsState,
  IBuildingRegisterEnforcement,
} from "@app/products/building/registers/enforcements/[id]/model";

export const mockSingleBuildingEnforcement: IBuildingRegisterEnforcement = {
  ID: 1,
  Contact: "Tran Tan",
  Applicant_Address: "4245  Paul Wayne Haggerty Road, Youngstown, Ohio",
  PreferredContact_Contact: "danielgreen@hotmail.net",
  SiteAddress: "everylikebook.com",
  SiteAddress_Owner: "Andrew Thomas",
  SiteAddress_Details: "bookstore@everylikebook.com",
  Type: "",
  Description:
    "We are looking for a Software Developer to build and implement functional programs. You will work with other Developers and Product Managers throughout the software development life cycle.",
  Officer: "Andrew Thomas",
  RefNo: 215672,
  Date_Lodged: new Date("Wed Jan 13 2021 13:24:55"),
  DateDue: new Date("Wed Jan 13 2021 13:24:55"),
  Date_Completed: new Date("Wed Jan 13 2021 13:24:55"),
  AssessmentNo: 656,
  OutstandingFees: 5600,
  DebtorNumber: 121245,

  AssessNo: "434718",
  PermitNo: "68553",
  Status: "In-Progress",
  PermitType: "Construction Equipment",
  Applicant: "Daniel M Green",
  CreatedDate: new Date("Thur Jan 05 2020 14:09:46 GMT+0700 (Indochina Time)"),
  LodgedDate: new Date("Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)"),
  ClockStart: new Date("Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)"),
};

export const mockBuildingRegisterEnforcementRecords: IBuildingRecordsState = {
  actions: 0,
  comments: 0,
  contacts: 0,
  documents: 0,
  fees: 0,
  inspections: 0,
  samples: 0,
};
