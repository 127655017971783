import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { useContactsCentralNamesStore } from "@app/products/property/contacts-central-names/[id]/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import "./_index.scss";

export const ContactAssociationsTab = observer(() => {
  const { lastSelectedId } = useCCProductListViewStore();
  const { contact } = useContactsCentralNamesStore();

  const params: { id: string } = useParams();
  const contactId = params.id ?? lastSelectedId;
  return (
    <div className="cc-contact-associations-tab">
      <PropertyAssociations
        isNoControl
        id={contactId}
        narID={contact?.Entity?.External_Reference_Id ?? 0}
        componentNumber={eComponent.Entity}
      />
    </div>
  );
});
