import { DTO_AssociatedItem_Assessment } from "@app/products/property/components/associations/components/assessment/model";
import { DTO_Entity_NominatedProperty } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import {
  DTO_Entity_Detail,
  DTO_Entity_VotingEntitlement,
  EKeysOfUpdateContactSteps,
} from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { SelectNominatePropertyDialog } from "./components/dialogs/nominated-property/_index";
import { colNominateProperty, colVotingEntitlementByElection } from "./config";

export const VotingFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const nameOfEntityDetail = nameOfFactory<DTO_Entity_Detail>();
const nameOfNominatedProperty = nameOfFactory<DTO_Entity_NominatedProperty>();
const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = { isReadOnly: false },
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const [
    showSelectNominatePropertyDialog,
    setShowSelectNominatePropertyDialog,
  ] = useState(false);
  const selectedVoting = getFieldValue("_option.SelectedVoting");

  const handleGridSelectionChange = (
    dataItem: DTO_Entity_VotingEntitlement[]
  ) => {
    let newSelected = dataItem ? { ...dataItem?.[0] } : undefined;
    onChange(nameOf("_option.SelectedVoting"), {
      value: newSelected,
    });
  };

  const handleVotingInfoChange = (field: string, value: any) => {
    if (selectedVoting) {
      let newVoting =
        getFieldValue("Nominated_Properties")?.map((voting: any) => {
          if (voting.Assessment_Id !== selectedVoting.Assessment_Id)
            return voting;
          voting[field] = value;
          return voting;
        }) ?? [];
      onChange(nameOf("Nominated_Properties"), { value: newVoting });
    }
  };
  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Nominate property" />
            <CCGrid
              data={getFieldValue("Nominated_Properties") || []}
              selectedRows={selectedVoting ? [selectedVoting] : undefined}
              columnFields={colNominateProperty}
              primaryField={nameOfNominatedProperty("Assessment_Id")}
              selectableMode={!options?.isReadOnly ? "single" : undefined}
              onSelectionChange={(dataItem) => {
                !options?.isReadOnly && handleGridSelectionChange(dataItem);
              }}
              editableMode={!options?.isReadOnly ? "cell" : undefined}
              onDataRowChange={(_dataRow, fieldChange, valueChange) => {
                handleVotingInfoChange(fieldChange, valueChange);
              }}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-plus"
                    title="Add Nominate Property"
                    onClick={() => {
                      setShowSelectNominatePropertyDialog(true);
                      onChange(nameOf("_option.SelectedVoting"), {
                        value: undefined,
                      });
                    }}
                    disabled={options?.isReadOnly}
                  />
                  <Button
                    iconClass="fas fa-minus"
                    title="Remove Nominate Property"
                    disabled={!selectedVoting || options?.isReadOnly}
                    onClick={() => {
                      onChange(nameOf("Nominated_Properties"), {
                        value: [],
                      });
                      onChange(nameOf("Voting_Entitlements"), {
                        value: [],
                      });
                      onChange(nameOf("_option.SelectedVoting"), {
                        value: undefined,
                      });
                    }}
                  />
                </div>
              }
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Voting entitlement by election" />
            <CCGrid
              data={getFieldValue("Voting_Entitlements") || []}
              columnFields={colVotingEntitlementByElection}
              primaryField="AssessmentId"
            />
          </div>
        </div>
      </div>
      {showSelectNominatePropertyDialog && (
        <SelectNominatePropertyDialog
          entityId={valueGetter(
            `${EKeysOfUpdateContactSteps.Person}.${nameOfEntityDetail("Id")}`
          )}
          onClose={() => {
            setShowSelectNominatePropertyDialog(false);
          }}
          onSubmit={(dataItem) => {
            setShowSelectNominatePropertyDialog(false);
            onChange(nameOf("Nominated_Properties"), {
              value: dataItem.map((item: DTO_AssociatedItem_Assessment) => ({
                Assessment_Id: item.Id,
                Assessment_Number: item.AssessmentNumber,
                Property_Address: item.PropertyName_Address_Locality,
                Role: item.Association,
                Effective_Date: new Date(),
                Nominated_Id: 0,
              })),
            });
            onChange(nameOf("Voting_Entitlements"), {
              //TODO: Implement in the future(#14906)
              value: [],
            });
          }}
        />
      )}
    </div>
  );
};
