import { CRMS_KB_BASE_ROUTE } from "@app/products/crms/knowledge-base/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewArticleButton = () => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New Article"
      onClick={() => {
        history.push(`${CRMS_KB_BASE_ROUTE}/new`);
      }}
    />
  );
};
