import { ISettingManagerForm } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { PPRSettingManageFormActionBar } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/action-bar/_index";
import { ExistedPPRSettingManage } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/existed/_index";
import { PPRSettingHistoryTab } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/reference-sidebar/history/_index";
import { usePhoneOrTablet } from "@common/hooks/usePhoneOrTablet";
import { useCCListViewActionBarStore } from "@components/cc-list-view-action-bar/store";
import { useReferenceSideBarStore } from "@components/cc-reference-sidebar/store";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const PPRSettingManage = observer((props: ISettingManagerForm) => {
  const actionBarStore = useCCListViewActionBarStore();
  const referenceSideBarStore = useReferenceSideBarStore();

  const history = useHistory();
  const [isShowSlideBar, setIsShowSlideBar] = useState(true);
  usePhoneOrTablet(() => setIsShowSlideBar(false));
  actionBarStore.resetDefault();

  const handleShowSlideBar = () => {
    setIsShowSlideBar(!isShowSlideBar);
  };

  useEffect(() => {
    referenceSideBarStore.setReferenceComponents([
      {
        title: "History",
        component: <PPRSettingHistoryTab />,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state]);

  return (
    <>
      <PPRSettingManageFormActionBar
        isShowSlideBar={isShowSlideBar}
        onClick={handleShowSlideBar}
      />
      <div className="cc-manage-container">
        <>
          <div className="cc-manage-left">
            <div className="cc-manage-form-body">
              <ExistedPPRSettingManage />
            </div>
          </div>
          {isShowSlideBar && (
            <div className="cc-manage-right">
              <ReferenceSideBar />
            </div>
          )}
        </>
      </div>
    </>
  );
});

export default PPRSettingManage;
