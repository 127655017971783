import { getDisplayTextWithDashes } from "@common/utils/common";
import { getter } from "@progress/kendo-data-query";

export const handleMeterReadingValidateForm: any = (data: any) => {
  const previousReadingDateGetter: any = getter("PreviousReadingDate");
  const currentReadingDateGetter: any = getter("CurrentReadingDate");
  const currentReadingGetter: any = getter("CurrentReading");
  const currentDay: any = new Date();

  const previousReadingDate = previousReadingDateGetter(data);
  const currentReadingDate = currentReadingDateGetter(data);
  const currentReading = currentReadingGetter(data);

  let errorMessage = {};

  if (!currentReadingDate) {
    errorMessage = {
      ...errorMessage,
      CurrentReadingDate: "Current Reading Date is incorrect.",
    };
  } else if (currentReadingDate <= previousReadingDate) {
    errorMessage = {
      ...errorMessage,
      CurrentReadingDate:
        "Current Reading Date must be greater than Previous Reading Date",
    };
  } else if (currentReadingDate > currentDay) {
    errorMessage = {
      ...errorMessage,
      CurrentReadingDate:
        "Current Reading Date must be equal or less than current date ",
    };
  }

  if (typeof currentReading !== "number" || currentReading < 0) {
    errorMessage = {
      ...errorMessage,
      CurrentReading: "Current Reading is incorrect",
    };
  }
  return errorMessage;
};

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.MeterId
    ? `- ${selectedRow.ComplianceId}`
    : "";

  return `Property - Meters ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.MeterNumber,
    selectedRow.PropertyAddress,
  ]);
};
