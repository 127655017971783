export interface IAccountNames {
  Role: string;
  Name: string;
  Address: string;
  EntityNameAddrId: number | null;
}

export enum NameRole {
  RESERVATION_CONTACT = "Reservation Contact",
  RESERVED_FOR = "Reserved for",
  NEXT_OF_KIN = "Next of Kin",
  DECEASED = "Deceased",
}
