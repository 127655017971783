import { getPPREnquiryDetailsTabById } from "@app/products/town-planning/ppr/enquiries/_id/components/reference-sidebar/detail/api";
import { RegisterDetailTab } from "@app/products/town-planning/ppr/enquiries/_id/components/reference-sidebar/detail/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { formatDateByKendo } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { getUUID } from "@common/utils/common";

export const EnquiryDetailTabEventType = {
  RefreshData: getUUID(),
};

export const PPREnquiryDetailTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const [isLoading, setIsLoading] = useState(true);
  const [detailTabData, setDetailTabData] = useState<
    RegisterDetailTab | undefined
  >();
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);

  const params: { id: string } = useParams();
  const townPlanningId = lastSelectedRow?.ID || params.id;

  const loadDetailTab = async () => {
    if (townPlanningId) {
      let errorResponse: APIResponseError | undefined = undefined;
      setIsLoading(true);
      const response = await getPPREnquiryDetailsTabById(townPlanningId);
      if (isSuccessResponse(response)) {
        setDetailTabData(response.data);
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      setResponseLoadError(errorResponse);
      setIsLoading(false);
    }
  };

  useAddUniqueEventEmitter([
    {
      eventType: EnquiryDetailTabEventType.RefreshData,
      listener: () => loadDetailTab(),
    },
  ]);

  useEffect(() => {
    loadDetailTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [townPlanningId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!detailTabData) return <NoData />;

  return (
    <>
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadDetailTab()}
        />
      ) : (
        <ProductReferenceBody>
          <ProductReferenceBlock>
            <ProductReferenceRow title={"ID:"} value={detailTabData.ID} />
            <ProductReferenceRow
              title={"Status:"}
              value={detailTabData.Status}
            />
            <ProductReferenceRow
              title={"Council:"}
              value={detailTabData?.ResponsibleAuthority}
            />
            <ProductReferenceRow
              title={"Created by:"}
              value={detailTabData.CreatedBy}
            />
            <ProductReferenceRow
              title={"Created:"}
              value={formatDateByKendo(
                detailTabData.CreatedDate,
                DATE_FORMAT.DATE
              )}
            />
            <ProductReferenceBlock flex>
              <ProductReferenceRow
                badgeSmall
                value={`${detailTabData.GrossDays} gross days `}
                success
              />
            </ProductReferenceBlock>
          </ProductReferenceBlock>
        </ProductReferenceBody>
      )}
    </>
  );
});
