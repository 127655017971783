import { DTO_ChangeOfOwnershipProcessed } from "@app/products/property/assessments/[id]/components/child-screens/transfer/model";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { scrollToView } from "@common/utils/animation";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { observer } from "mobx-react-lite";
import React, { RefObject, useCallback, useEffect } from "react";
import { TransferDetail } from "./components/detail/_index";
import { colTransfer } from "./config";
import { useAssessmentTransferStore } from "./store";

interface ITransferProps {
  transferRef?: RefObject<any>;
}
const nameOf = nameOfFactory<DTO_ChangeOfOwnershipProcessed>();
export const Transfer = observer(({ transferRef }: ITransferProps) => {
  const { assessmentId } = useAssessmentStore();
  const {
    isLoading,
    transfer,
    transferSelected,
    responseLoadError,
    setTransferSelected,
    loadTransfer,
    resetStore,
  } = useAssessmentTransferStore();

  useEffect(() => {
    scrollToView(transferRef);
  }, [isLoading, transferRef]);

  useEffect(() => {
    if (!assessmentId) return;
    loadTransfer(assessmentId);

    return () => {
      resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentId]);

  const handleSelectedTransfer = useCallback(
    (dataItems: any[]) => {
      setTransferSelected(dataItems[0]);
    },
    [setTransferSelected]
  );
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          if (!assessmentId) return;
          loadTransfer(assessmentId);
        }}
      />
    );
  return (
    <div className="cc-assessment-transfer" ref={transferRef}>
      <CCGrid
        isLoading={isLoading}
        className="cc-assessment-transfer-grid"
        data={transfer ?? []}
        columnFields={colTransfer}
        primaryField={nameOf("Id")}
        isLimitDetailWidth
        selectableMode="single"
        detail={TransferDetail}
        onSelectionChange={handleSelectedTransfer}
        selectedRows={transferSelected ? [{ ...transferSelected }] : []}
      />
    </div>
  );
});
