import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colByStatus: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.DateRecorded,
    title: "Recorded",
    width: 200,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.ComplaintNo,
    title: "Complaint Number",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.ApplicantName,
    title: "Applicant",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.SiteAddress,
    title: "Site Address",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.DateReceived,
    title: "Received",
    width: 200,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.DateOccured,
    title: "Occurred",
    width: 200,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.Source,
    title: "Source",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Category,
    title: "Category",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.SubCategory,
    title: "Sub Category",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Officer,
    title: "Officer",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.ComplaintStatus,
    title: "Status",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.DateTarget,
    title: "Target",
    width: 200,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.DateClosed,
    title: "Closed",
    width: 200,
    format: DATE_FORMAT.DATE,
  },
];
