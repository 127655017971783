import { postSendCommunication } from "@app/core/communication/buttons-list-view/components/buttons/api";
import { CommunicationDialog } from "@app/core/communication/dialogs/_index";
import { recipientTypesCommunicationDialog } from "@app/core/communication/dialogs/config";
import { ICommunication } from "@app/core/communication/dialogs/model";
import { DOCUMENTSTATUS } from "@app/core/documents/model";
import { UITownPlanning } from "@app/products/town-planning/model";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putSendResponseToRAAndApplicant } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/send-response-to-ra-and-applicant/api";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const SendResponseToRAAndApplicantButton = observer(() => {
  const [showCommunicationDialog, setShowCommunicationDialog] =
    useState<boolean>(false);
  const { clearNotifications, pushNotification } = useCCAppNotificationStore();
  const { ppr, pprId, onSubmit, setPPR, isLoading } = usePSARStore();
  const isVisible = useMemo(() => {
    return ppr?.Status_ENUM === Application_Status.ApprovedByTeamLeader;
  }, [ppr]);

  useAddUniqueEventEmitter([
    {
      eventType:
        UITownPlanning.SendApprovedResponseReportLetterToRAAndApplicant.toString(),
      listener: () => {
        setShowCommunicationDialog(true);
      },
    },
  ]);

  const handleCommunicationSubmit = async (data: ICommunication) => {
    clearNotifications();
    const response = await postSendCommunication(
      data,
      RECORDTYPE.TP_PSA_Application
    );
    if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
      const respSendToProponent = await putSendResponseToRAAndApplicant(pprId);
      setShowCommunicationDialog(false);
      if (
        isSuccessIdentityPacket(respSendToProponent) &&
        respSendToProponent.data?.Application
      ) {
        setPPR(respSendToProponent.data.Application);
        pushNotification({
          type: "success",
          title: "Send response to proponent successfully",
        });
      } else {
        pushNotification({
          autoClose: false,
          title: "Send response to proponent failed",
          type: "error",
          description:
            respSendToProponent.data?.Errors ?? respSendToProponent.statusText,
        });
      }
    } else {
      setShowCommunicationDialog(false);
      pushNotification({
        autoClose: false,
        title: "Send communication failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Send response to proponent"
        onClick={onSubmit}
        disabled={isLoading}
        isLoading={isLoading}
        name={
          PSARSubmitActions.SendApprovedResponseReportLetterToRAAndApplicant
        }
      />
      {showCommunicationDialog && (
        <CommunicationDialog
          onClose={() => setShowCommunicationDialog(false)}
          onSubmit={handleCommunicationSubmit}
          productType={PRODUCT_TYPE_NUMBER.TownPlanning}
          recordID={pprId}
          recipientTypes={recipientTypesCommunicationDialog(
            RECORDTYPE.TP_PSA_Application
          )}
          recordType={RECORDTYPE.TP_PSA_Application}
          documentStatus={DOCUMENTSTATUS.Final}
        />
      )}
    </>
  ) : null;
});
