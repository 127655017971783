import { ReturnMessage } from "@app/products/property/system-admin/document-template/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const putChangeStatusDocument = async (
  id: number,
  isActive: boolean
): Promise<APIResponse<ReturnMessage | undefined>> => {
  try {
    return await CoreAPIService.getClient().put(
      `/api/property/internal/documenttemplate/changeStatus/${id}?isActive=${isActive}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
