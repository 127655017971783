export interface DTO_Entity_AddressDetail {
  dtoStreetAndLocality: DTO_Entity_StreetAndLocality;
}

export interface DTO_Entity_StreetAndLocality {
  StreetName: string;
  StreetType: string;
  Locality: string;
  State: string;
  PostCode: string;
}
export interface VO_JobDescription {
  Job_Description: string;
}

export interface VO_StreetName {
  Street: string;
}

export interface VO_Entity_Locality {
  Locality_Code: string;
  Locality_Name: string;
  Postcode_Id: number;
}

export enum unitAddressContact {
  FLAT = "Flat",
  UNIT = "Unit",
  APARTMENT = "Apartment",
}
