import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SaveButtonFeeTypes } from "@common/pages/settings/lookups/fee-types/_id/components/buttons/save/_index";
import { FeeTypeForm } from "@common/pages/settings/lookups/fee-types/_id/components/general/_index";
import { FeeTypesDetailsTab } from "@common/pages/settings/lookups/fee-types/components/action-bar/side-bar/details/_index";
import { FeeTypeHistoryTab } from "@common/pages/settings/lookups/fee-types/components/action-bar/side-bar/history/_index";
import { TitleFeeTypeManagePage } from "@common/pages/settings/lookups/fee-types/constant";
import { FeeType } from "@common/pages/settings/lookups/fee-types/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const ExistManageFeeType = observer(() => {
  // #region STORE
  const { currentUserInfo } = useGlobalStore();
  const { dataForms } = useFlexibleFormStore();
  const feeType = dataForms?.GeneralForm as FeeType;
  // #endregion STORE

  //#region HOOK
  const location = useLocation();
  //#endregion HOOK

  // #region STATE
  const [showSlideBar, setShowSlideBar] = useState(true);
  // #endregion STATE

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  //#endregion Variable =====/

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Fee type - ${id ?? 0}`,
      Text: `${getDisplayTextWithDashes([feeType?.Name])}`,
      LinkUrl: location.pathname,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_FeeType,
      Record_ID: +id ?? 0,
    },
  ];

  return (
    <>
      <FormTitle title={`${TitleFeeTypeManagePage} - ${feeType?.Name}`} />
      <CCManagePageActionBar
        leftActions={[<SaveButtonFeeTypes />]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={showSlideBar}
            onClick={() => {
              setShowSlideBar(!showSlideBar);
            }}
          />,
          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {feeType && <FeeTypeForm />}
          </div>
        </div>

        {showSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                {
                  title: "Details",
                  component: <FeeTypesDetailsTab />,
                },
                {
                  title: "History",
                  component: <FeeTypeHistoryTab />,
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
});
