import { InspectionType } from "@app/core/inspections/[id]/model";
import { APIResponse } from "@common/apis/model";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useIsNew } from "@common/hooks/useIsNew";
import { IdentityPacket } from "@common/models/identityPacket";
import { postSaveInspectionTypes } from "@common/pages/settings/lookups/inspection-types/_id/components/buttons/save/api";
import { checkIsInactiveInspectionType } from "@common/pages/settings/lookups/inspection-types/_id/components/buttons/save/util";
import { INSPECTION_TYPE_SLIDER } from "@common/pages/settings/lookups/inspection-types/_id/constant";
import { SETTING_CORE_INSPECTIONTYPE } from "@common/pages/settings/lookups/inspection-types/constant";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

export const SaveButtonInspectionTypes = observer(() => {
  //#region STORE ========/
  const { submitFormGetData, isLoadingForm, setIsLoadingForm } =
    useFlexibleFormStore();
  //#endregion STORE =====/

  //#region HOOK ========/
  const { pushNotificationPortal } = useNotificationPortalStore();
  const isNew = useIsNew();
  const history = useHistory();
  //#endregion HOOK =====/

  const slider = useFlexibleFetchData({
    slides: [
      {
        fetch: ({ initialData }) => {
          const inspectionType: InspectionType = initialData;
          return postSaveInspectionTypes(inspectionType);
        },
        handleSuccess: async ({ dataFromApi }) => {
          const response: APIResponse<IdentityPacket | undefined> = dataFromApi;
          if (isNew) {
            if (response?.data?.ID) {
              const urlRedirect =
                SETTING_CORE_INSPECTIONTYPE + "/" + response.data.ID;
              pushNotificationPortal({
                title: "Inspection type saved successfully.",
                type: "success",
                route: urlRedirect,
                callBack() {
                  history.replace(urlRedirect);
                },
              });
            } else {
              pushNotificationPortal({
                title: "Not found new inspection type id.",
                type: "error",
                autoClose: false,
              });
            }
          } else {
            await fetchApiByAlias(INSPECTION_TYPE_SLIDER);
            pushNotificationPortal({
              title: "Inspection type saved successfully.",
              type: "success",
            });
          }
          return false;
        },
        handleError: ({ errorFromApi, initialData }) => {
          pushNotificationPortal({
            title: initialData?.errorMsg,
            type: "error",
            description: errorFromApi.error,
            autoClose: false,
          });
        },
      },
    ],
  });

  const handleClickButton = async () => {
    const inspectionType: InspectionType = await submitFormGetData(
      "GeneralForm"
    );

    if (inspectionType) {
      inspectionType.Flag_IsAllowFollowUp =
        inspectionType?.Flag_IsAllowFollowUp ?? false;

      setIsLoadingForm(true);
      await slider.fetchApi({
        initialData: inspectionType,
      });
      setIsLoadingForm(false);
    }
  };

  return (
    <CCNavButton
      title="Save"
      onClick={handleClickButton}
      disabled={isLoadingForm || checkIsInactiveInspectionType()}
    />
  );
});
