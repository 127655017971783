import { DTO_AssessmentModifyChargeDetail_LOVs } from "@app/products/property/assessments/components/dialogs/modify-charge-details/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class AssessmentModifyChargeDetailDialogStore {
  private _assessmentModifyChargeDetailLOVs?: DTO_AssessmentModifyChargeDetail_LOVs =
    undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get assessmentModifyChargeDetailLOVs() {
    return this._assessmentModifyChargeDetailLOVs;
  }
  setAssessmentModifyChargeDetailLOVs = (
    assessmentModifyChargeDetailLOVs?: any
  ) => {
    runInAction(() => {
      this._assessmentModifyChargeDetailLOVs = assessmentModifyChargeDetailLOVs;
    });
  };
}

const assessmentModifyChargeDetailDialogStoreContext = createContext(
  new AssessmentModifyChargeDetailDialogStore()
);
export const useAssessmentModifyChargeDetailDialogStore = () => {
  return useContext(assessmentModifyChargeDetailDialogStoreContext);
};
