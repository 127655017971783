import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const ActionsHistoryTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { action } = useActionStore();
  const params: { id: string } = useParams();
  const actionId: number = params.id
    ? parseInt(params.id)
    : lastSelectedRow?.ID;
  return (
    <Journal
      id={actionId}
      triggersReload={action}
      recordType={RECORDTYPE.CORE_Action}
    />
  );
});
