import { MasterContact } from "@app/core/contacts/merge-contact/merge-contact-dialog/components/master-contact/_index";
import { UnMasterContact } from "@app/core/contacts/merge-contact/merge-contact-dialog/components/un-master-contact/_index";
import { useMergeContactStore } from "@app/core/contacts/merge-contact/store";
import { Contact } from "@common/models/contact";
import { ActionSubmitActions } from "@common/pages/actions/model";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";
import { NoteEntityMerge } from "./components/note-contact-merge/_index";

export interface IMergeContactDialogProps {
  onClosePopup: () => void;
  listIdSelected: number[];
  title: string;
}

export const MergeContactDialog = observer(
  ({
    listIdSelected: contactIds,
    title,
    onClosePopup,
  }: IMergeContactDialogProps) => {
    const {
      responseLoadError,
      setupMergeContacts,
      isLoading,
      contacts,
      onSubmit,
      isLoadingMergeContacts,
      isLoadingMergeAndEditContacts,
      notification,
      masterContact,
    } = useMergeContactStore();
    const { gridSelectedIds } = useCCProductListViewStore();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    useEffectOnce(() => {
      setupMergeContacts(contactIds);
    });

    useEffect(() => {
      if (notification) notificationRef.current?.pushNotification(notification);
    }, [notification]);

    return (
      <div className="cc-entity-merge-dialog-wrap">
        <CCDialog
          maxHeight="80%"
          maxWidth="80%"
          titleHeader={title}
          onClose={onClosePopup}
          bodyElement={
            <div className="cc-entity-merge-dialog-content">
              <CCLocalNotification ref={notificationRef} />
              <h6>{`Contacts Selected for Merge - ${contactIds.length}`}</h6>
              <NoteEntityMerge />
              {isLoading ? (
                <Loading isLoading />
              ) : responseLoadError ? (
                <CCLoadFailed
                  responseError={responseLoadError}
                  onReload={() => setupMergeContacts(gridSelectedIds)}
                />
              ) : (
                <>
                  {masterContact && <MasterContact />}
                  {contacts?.map((contact: Contact) => {
                    return (
                      <UnMasterContact
                        key={contact.Contact_ID}
                        contact={contact}
                      />
                    );
                  })}
                </>
              )}
            </div>
          }
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              <Button
                className={"cc-dialog-button"}
                onClick={onClosePopup}
                disabled={
                  isLoadingMergeContacts || isLoadingMergeAndEditContacts
                }
              >
                Cancel
              </Button>
              <Button
                className="cc-dialog-button"
                iconClass={
                  isLoadingMergeContacts ? `fas fa-spinner fa-spin` : ""
                }
                themeColor="primary"
                onClick={onSubmit}
                name={ActionSubmitActions.MergeContact}
                disabled={
                  isLoadingMergeContacts || isLoadingMergeAndEditContacts
                }
              >
                Merge
              </Button>

              <Button
                className="cc-dialog-button"
                iconClass={
                  isLoadingMergeAndEditContacts ? `fas fa-spinner fa-spin` : ""
                }
                themeColor="primary"
                onClick={onSubmit}
                name={ActionSubmitActions.MergeContactAndEdit}
                disabled={
                  isLoadingMergeContacts || isLoadingMergeAndEditContacts
                }
              >
                Merge and edit
              </Button>
            </div>
          }
        />
      </div>
    );
  }
);
