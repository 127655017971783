import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { VO_Collection } from "@app/products/property/collections/list/model";

const nameOf = nameOfFactory<VO_Collection>();

export const colCollectionList: IColumnFields[] = [
  {
    field: nameOf("Collection_Name"),
    title: "Collection Name",
    locked: true,
  },
  {
    field: nameOf("Collection_Type_Name"),
    title: "Collection Type",
  },
  { field: nameOf("Collection_Created_By"), title: "Created By" },
  {
    field: nameOf("Collection_Is_Active"),
    title: "Is Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Collection_Id"),
    title: "Collection Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
