import { TypeNotice } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/model";
import { DTO_LOV_NoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const typeNoticeList = Object.values(TypeNotice).map(
  (type: string, index: number) => ({ Type: type, No: index })
);
const nameOfNoticeType = nameOfFactory<DTO_LOV_NoticeType>();
export const colNoticeType: IColumnFields[] = [
  {
    title: "Type of Notice",
    field: nameOfNoticeType("Name"),
  },
];
