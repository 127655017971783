//#region SET-GET GeneralForm ========/

import { InspectionType } from "@app/core/inspections/[id]/model";
import { flexibleFormStoreInstance } from "@common/stores/flexible-form/store";

type TInspectionType = InspectionType;
export const changeInspectionTypeGeneralForm = (
  nameOfField: Partial<TInspectionType>
) => flexibleFormStoreInstance.updateFormFields("GeneralForm", nameOfField);

export const getInspectionTypeGeneralForm = (
  nameOfField?: keyof TInspectionType | Array<keyof TInspectionType>
) => flexibleFormStoreInstance.getFormFields("GeneralForm", nameOfField);
//#endregion SET-GET GeneralForm =====/
