import { IPicCertificate } from "@app/products/property/pic/[id]/components/child-screens/certificates/model";
import { usePicCertificateStore } from "@app/products/property/pic/[id]/components/child-screens/certificates/store";
import { usePicStore } from "@app/products/property/pic/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { colPicCertificates } from "./config";

export const PicCertificates = observer(() => {
  const { isLoading, certificate, loadPicCertificate, resetStore } =
    usePicCertificateStore();
  const { picId } = usePicStore();
  const nameOf = nameOfFactory<IPicCertificate>();
  useEffect(() => {
    if (picId) loadPicCertificate(picId);
    return () => {
      resetStore();
    };
  }, [picId, loadPicCertificate, resetStore]);

  return (
    <div className="cc-pic-certificates-gird">
      <CCGrid
        isLoading={isLoading}
        data={certificate ?? []}
        columnFields={colPicCertificates}
        primaryField={nameOf("Id")}
      />
    </div>
  );
});
