import { IRegisterAccountSearch } from "@app/products/property/registers/[id]/components/dialogs/form-steps/transfer-to-another-account/components/form-elements/to-account/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const mockRegisterAccountGrid: IRegisterAccountSearch[] = [
  {
    AccountReference: "83511",
    AccountId: 51684,
    PropertyAddress: "64 Ascot St South",
    AccountDescription: "Brahma James CLARE’S German Shepherd",
    Category: "Standard Fee/Microchipped",
    AccountName: "Ben Borzini",
    HistoricalReference: 12355,
  },
  {
    AccountReference: "83522",
    AccountId: 835,
    PropertyAddress: "94 Amanda, Archies Creek",
    Category: "Standard Fee/Microchipped",
    AccountDescription: "James CLARE’S German Shepherd",
    AccountName: "L J GILES 14 Silver St Lysterfield VIC 3156",
    HistoricalReference: 55452,
  },
];
export const mockSearchRegister = [
  { Key: 1, Value: "Account Description" },
  { Key: 2, Value: "Address" },
  { Key: 3, Value: "Name" },
  { Key: 4, Value: "Reference (Tag)" },
  { Key: 5, Value: "Historical Reference (Tag)" },
];
export const mockRegisterDropdown = [{ Key: 1, Value: "Dog" }];
export const listRegisterSearchOptions: IKeyValuePacket[] = [
  { Key: 1, Value: "Keyword" },
  { Key: 2, Value: "Soundex" },
  { Key: 3, Value: "Only Active Entities" },
];
