import {
  EExecutionOption,
  executionData,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/execution/config";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
import React from "react";

export const ExecutionFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = { isReadOnly: false },
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const handleExecutionOption = (event: RadioGroupChangeEvent) => {
    const value = event?.value;
    onChange(nameOf("_option.ExecutionOption"), { value: value });
    if (value === EExecutionOption.RunImmediately) {
      onChange(nameOf("RunImmediately"), { value: true });
    } else if (value === EExecutionOption.ScheduleToRunAt) {
      onChange(nameOf("RunImmediately"), { value: false });
    }
  };
  return (
    <div className="cc-form">
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <Field
              name={nameOf("_option.ExecutionOption")}
              component={CCRadioGroup}
              data={executionData}
              disabled={options?.isReadOnly}
              onChange={handleExecutionOption}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          {getFieldValue("_option.ExecutionOption") ===
          EExecutionOption.ScheduleToRunAt ? (
            <div className="cc-field">
              <label className="cc-label">&nbsp;</label>
              <Field
                name={nameOf("RunAt")}
                component={CCDateTimePicker}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
                disabled={options?.isReadOnly}
              />
            </div>
          ) : null}
          <div className="cc-field">
            <Field
              label="Email address"
              name={nameOf("EmailRecipients")}
              readOnly={options?.isReadOnly}
              component={CCInputEmail}
              validator={!options?.isReadOnly ? validateEmail : undefined}
              onChangeForm={onChange}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
