import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colParkingPermitsSystemAdminPropertyExceptions: IColumnFields[] = [
  {
    field: ParkingPermitsFieldMapping.FullAddress,
    title: "Property",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${PARKING_PERMITS_ROUTE}/${dataItem.ID}`;
    },
    width: 200,
  },
  {
    field: ParkingPermitsFieldMapping.AssessmentNo,
    title: "Property ID Number",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.MaximumPermits,
    title: "Maximum Permits",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.NoOfFreePermits,
    title: "Number Free",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.NoOfCost1,
    title: "Number at Cost 1",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: ParkingPermitsFieldMapping.Cost1, title: "Cost 1", width: 200 },
  {
    field: ParkingPermitsFieldMapping.NoOfCost2,
    title: "Number at Cost 2",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: ParkingPermitsFieldMapping.Cost2, title: "Cost 2", width: 200 },
  {
    field: ParkingPermitsFieldMapping.NoOfCost3,
    title: "Number at Cost 3",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.Cost3,
    title: "Cost 3",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
