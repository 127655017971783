import { ReactElement } from "react";
export enum eAccordionType {
  Actions_And_Workflows = 1,
}
export interface IListPanelBar {
  title: string | ReactElement;
  rightTitle?: ReactElement;
  totalRecord?: number;
  component: ReactElement;
  isShowedTotalRecord?: boolean;
  expanded?: boolean;
  handleOnClick?: any;
  isVisible?: boolean | null;
  id?: string;
}

export interface DTO_Component_Accordion {
  Accordion_Count: DictionaryAccordionType;
}

export type DictionaryAccordionType = {
  [key in string]?: number;
};
