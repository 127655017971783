import { getCSLById } from "@app/products/csl/[id]/components/reference-sidebar/api";
import { CSLRegisterForm, CSLResponse } from "@app/products/csl/[id]/model";
import { convertCSLResponseToForm } from "@app/products/csl/[id]/util";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";
import { ContactsSidebar } from "@common/pages/contacts/contacts-sidebar/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import { default as React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const CSLContactTab = observer(() => {
  const [contactInfo, setContactInfo] = useState<ICommonContactItemRender[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { lastSelectedRow } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const cslId = id || lastSelectedRow.ID;
  useEffect(() => {
    setIsLoading(true);
    getCSLById(cslId).then((data?: CSLResponse) => {
      if (data) {
        const newData: CSLRegisterForm | undefined =
          convertCSLResponseToForm(data);
        if (!newData) return;
        let contactInfo: ICommonContactItemRender[] = [];
        if (newData.Applicant) {
          contactInfo.push({
            ID: String(newData.Applicant?.Contact.Contact_ID),
            Type: "Licensee",
            DisplayName: newData.Applicant?.Contact.DisplayName,
            Email: newData.Applicant?.Contact.Email,
            WorkPhone: newData.Applicant?.Contact._WorkPhone,
            Mobile: newData.Applicant?.Contact._HomePhone,
          });
        }

        if (newData.Business) {
          contactInfo.push({
            ID: String(newData.Business?.Contact.Contact_ID),
            Type: "Primary Contact",
            DisplayName: newData.Business?.Contact.DisplayName,
            Email: newData.Business?.Contact.Email,
            WorkPhone: newData.Business?.Contact._WorkPhone,
            Mobile: newData.Business?.Contact._HomePhone,
          });
        }

        setContactInfo([...contactInfo]);
      }
      setIsLoading(false);
    });
  }, [cslId]);

  if (isLoading) return <Loading isLoading={isLoading} />;

  return <ContactsSidebar data={contactInfo as ICommonContactItemRender[]} />;
});
