import { TownPlanningActionBarNavDropdown } from "@app/products/town-planning/components/action-bar/nav-dropdown/_index";
import { townPlanningRoute } from "@app/products/town-planning/route";
import { systemAdminRoute } from "@app/products/town-planning/system-admin/route";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { NoData } from "@components/no-data/NoData";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer((props: any) => {
  useCCListViewActionBar({
    title: townPlanningRoute.name,
    leftComponents: [
      <TownPlanningActionBarNavDropdown category={systemAdminRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
    ],
  });

  return (
    <div className="cc-system-admin-setting">
      <NoData />
    </div>
  );
});
