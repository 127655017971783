import { history } from "@/AppRoutes";
import { FURTHER_INFO_ROUTE } from "@app/core/further-info/[id]/constant";
import { IParentFISection } from "@app/core/further-info/[id]/model";
import { putRequestFI } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/request-fi/api";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { IdentityPacket } from "@common/models/identityPacket";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class RequestFIButtonStore {
  constructor() {
    makeAutoObservable(this);
  }
  //Action
  requestFI = async (applicationId: number, data: IdentityPacket) => {
    const response = await putRequestFI(applicationId);
    if (isSuccessIdentityPacket(response)) {
      history.replace(`${FURTHER_INFO_ROUTE}/${data.ID}`, {
        parent: {
          notification: [
            {
              title: "Further information requested successfully.",
              type: "success",
            },
          ],
        } as IParentFISection,
      });
      return true;
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Further information could not be requested.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
      return false;
    }
  };
}
export const requestFIButtonStore = new RequestFIButtonStore();
const requestFIButtonContext = createContext(requestFIButtonStore);
export const useRequestFIButtonStore = () => {
  return useContext(requestFIButtonContext);
};
