import { Switch, SwitchProps } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import React from "react";

interface ICCSwitchProps {
  validationMessage?: string | null;
  visited?: boolean;
}

export const CCSwitch = (props: ICCSwitchProps & SwitchProps) => {
  const { validationMessage, visited, ...others } = props;

  return (
    <>
      <Switch offLabel="" onLabel="" {...others} size="small" />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
