import { DTO_Workflow_ChargeRunUpdate } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/buttons/update/model";
import { ResponseMessageWorkflow } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postUpdateChargeRun = async (
  chargeRunId: DTO_Workflow_ChargeRunUpdate
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_ChargeRunUpdate>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/chargerun/update`,
      chargeRunId
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
