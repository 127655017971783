import {
  DTO_SubdivisionsConsolidations,
  DTO_SubdivisionsConsolidations_LOVs_Response,
  DTO_Workflow_SubdivisionsConsolidations,
} from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const getSubdivisionsConsolidationsLovs = async (): Promise<
  APIResponse<DTO_SubdivisionsConsolidations_LOVs_Response>
> => {
  try {
    return await CoreAPIService.getClient().get<DTO_SubdivisionsConsolidations_LOVs_Response>(
      `/api/property/internal/subdivisionsconsolidations/lovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowSubdivisionsConsolidations = async (
  input: DTO_SubdivisionsConsolidations
): Promise<APIResponse<DTO_Workflow_SubdivisionsConsolidations>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/subdivisionsconsolidations/new`,
      input
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSavedWorkflowSubdivisionsConsolidations = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_SubdivisionsConsolidations>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/subdivisionsconsolidations/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataSubdivisionsConsolidations =
  | [
      APIResponse<DTO_SubdivisionsConsolidations_LOVs_Response>,
      APIResponse<DTO_Workflow_SubdivisionsConsolidations>
    ]
  | APIResponse<
      | DTO_SubdivisionsConsolidations_LOVs_Response
      | DTO_Workflow_SubdivisionsConsolidations
    >
  | undefined;
export const getInitialDataSubdivisionsConsolidations = async (
  payload: DTO_SubdivisionsConsolidations,
  workflowDraftId: number | undefined
): Promise<IGetInitialDataSubdivisionsConsolidations> => {
  try {
    return await Promise.all([
      getSubdivisionsConsolidationsLovs(),
      !isNil(workflowDraftId)
        ? getSavedWorkflowSubdivisionsConsolidations(workflowDraftId)
        : getNewWorkflowSubdivisionsConsolidations(payload),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessSubdivisionsConsolidations = async (
  mode: WorkflowProcessMode,
  data: DTO_Workflow_SubdivisionsConsolidations
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_SubdivisionsConsolidations>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/subdivisionsconsolidations/process/${mode}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
