import { ICCRoute } from "@common/constants/ICCRoute";

export const allRoute: ICCRoute = {
  path: "all",
  name: "All",
  children: [
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
    {
      path: "register-search",
      name: "Register - Search",
      component: require("./register-search/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      component: require("./by-status/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      component: require("./by-type/_index").default,
    },
    {
      path: "by-team-leader",
      name: "By Delegated Approver",
      component: require("./by-team-leader/_index").default,
    },
    {
      path: "by-municipality",
      name: "By Municipality",
      component: require("./by-municipality/_index").default,
    },
    {
      path: "by-location",
      name: "By Location",
      component: require("./by-location/_index").default,
    },
    {
      path: "ref-auth-register",
      name: "Reference Auth Register",
      component: require("./ref-auth-register/_index").default,
    },
  ],
};
