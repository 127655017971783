import { ITransactionPICStepsLOVs } from "@app/products/property/pic/list/components/action-bar/form-steps/new-transaction/components/form-elements/pic/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class PICNewTransactionJournalStepStore {
  private _newPICJournalStepLOVs?: ITransactionPICStepsLOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get newPICJournalStepLOVs() {
    return toJS(this._newPICJournalStepLOVs);
  }
  setNewPICJournalStepLOVs = (
    newPICJournalStepLOVs?: ITransactionPICStepsLOVs
  ) => {
    runInAction(() => {
      this._newPICJournalStepLOVs = newPICJournalStepLOVs;
    });
  };
}

const picNewTransactionJournalStepStoreContext = createContext(
  new PICNewTransactionJournalStepStore()
);
export const usePICNewTransactionJournalStepStore = () => {
  return useContext(picNewTransactionJournalStepStoreContext);
};
