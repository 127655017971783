import { IMeterSearch } from "@components/cc-search-meter/model";

export const mockMeterData: IMeterSearch[] = [
  {
    MeterId: 1,
    MeterNumber: 1234,
    PropertyAddress: "Wandella Avenue/04 Durham St",
    AccountName: "C K SIDHU & K S GILL",
    AccountId: 4,
  },
  {
    MeterId: 2,
    MeterNumber: 6789,
    PropertyAddress:
      "9999 Victoria Rd Adjacent No 869 lorem Victoria Rd Adjacent No 869 lorem Victoria Rd Adjacent No 869 lorem",
    AccountName: "A P Lawrence",
    AccountId: 9,
  },
];
