import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewTownPlanningButton = () => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New Town Planning"
      onClick={() => {
        history.push(`${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/new`);
      }}
    />
  );
};
