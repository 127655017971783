const getContentFilter = (stringFilter: string) =>
  stringFilter.replace(/filter\b=|&/gi, "") ?? "";

export const transformUrlFilterForContains = (url: string) => {
  const regex = /filter\b=(.*?&|.+)/gi;
  const listFilter = url.match(regex) ?? [];
  if (listFilter.length === 2) {
    let filter1 = "";
    let filter2 = "";
    listFilter.forEach((item, i) => {
      i === 0
        ? (filter1 = getContentFilter(item))
        : (filter2 = getContentFilter(item).replace(
            /((?:contains\b|indexof\b)\()((?!^\s+).+?)(,)('(?!^\s+).*?')(\))/gi,
            `$1toLower($2)$3toLower($4)$5`
          ));
    });

    let count = 0;
    return url.replace(/\$filter\b=([^&]*&|.+)/gi, () => {
      count++;
      if (count === 2) return "";
      return `$filter=(${filter1} and ${filter2})&`;
    });
  }
  return url.replace(
    /((?:contains\b|indexof\b)\()((?!^\s+).+?)(,)('(?!^\s+).*?')(\))/gi,
    `$1toLower($2)$3toLower($4)$5`
  );
};
