import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { Svc_CUSTRequestsAccordion } from "@app/products/crms/components/event-accordion/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_CUSTRequestsAccordion>();
export const colCUSTRequestsAccordion: IColumnFields[] = [
  {
    field: nameOf("RefNo"),
    title: "Reference Number",
    locked: true,
    linkTo: (dataItem) => `${CRMS_ROUTE}/${dataItem?.ID}`,
  },
  {
    field: nameOf("ActionOfficer"),
    title: "Action Officer",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("FileNumber"),
    title: "File Number",
  },
  {
    field: nameOf("DateRecorded"),
    title: "Recorded",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateTarget"),
    title: "Target",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
];
