import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ProfileForm } from "@common/pages/profile/components/form/_index";
import { useProfileStore } from "@common/pages/profile/store";
import { SiteUserTabDetails } from "@common/pages/settings/security/site-users/_id/components/reference-sidebar/detail/_index";
import { SiteUserHistoryTab } from "@common/pages/settings/security/site-users/_id/components/reference-sidebar/history/_index";
import { SiteUserSubmitActions } from "@common/pages/settings/security/site-users/_id/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

const ManageProfile = () => {
  const { currentUserInfo } = useGlobalStore();
  const siteUserId = currentUserInfo?.UserPKID || "";
  const {
    onSubmit,
    isLoading,
    siteUser,
    resetStore,
    onChange,
    isOnLeave,
    setIsOnLeave,
    responseLoadError,
    loadSiteUser,
  } = useProfileStore();
  const { resetMenu } = useCCSubActionBarStore();
  const { settings } = useCommonCoreStore();
  const [showSlideBar, setShowSlideBar] = useState(true);

  const staffOnLeave = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_StaffOnLeave]
  );

  const isShowOnLeave = useMemo(() => {
    return !isOnLeave && staffOnLeave;
  }, [isOnLeave, staffOnLeave]);

  const leftPageActionBar = useMemo(() => {
    const retVal = [
      <CCNavButton
        title={"Save"}
        onClick={onSubmit}
        name={SiteUserSubmitActions.Save}
      />,
    ];
    if (isShowOnLeave) {
      retVal.push(
        <CCNavButton
          title={"On Leave"}
          onClick={() => {
            setIsOnLeave(true);
            if (onChange) {
              onChange("Contact_SiteUser.Flag_OnLeave", { value: true });
            }
          }}
        />
      );
    }
    return retVal;
  }, [isShowOnLeave, onChange, onSubmit, setIsOnLeave]);

  useEffect(() => {
    loadSiteUser(parseInt(siteUserId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteUserId]);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"My Profile"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadSiteUser(parseInt(siteUserId))}
        />
      ) : (
        <>
          <FormTitle title={siteUser?.Contact_SiteUser?.DisplayName} />

          <CCManagePageActionBar
            leftActions={leftPageActionBar}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {siteUser && <ProfileForm />}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: (
                        <SiteUserTabDetails id={parseInt(siteUserId)} />
                      ),
                    },
                    {
                      title: "History",
                      component: (
                        <SiteUserHistoryTab id={parseInt(siteUserId)} />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default observer(ManageProfile);
