import { CoreAPIService } from "@/common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const getApplicationDecisions = async (): Promise<
  APIResponse<IKeyValuePacket[]>
> => {
  try {
    return await CoreAPIService.getClient().get<IKeyValuePacket[]>(
      `/api/tp/ppr/internal/application/psar/applicationdecisions`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
