import { ICCRoute } from "@common/constants/ICCRoute";

export const infoWorkflowRoute: ICCRoute = {
  path: "info-workflow",
  name: "Information Workflow",
  children: [
    {
      path: "acknowledgement",
      name: "Acknowledgement",
      component: require("./acknowledgement/_index").default,
    },
    {
      path: "ready-to-send",
      name: "Ready To Send",
      component: require("./ready-to-send/_index").default,
    },
    {
      path: "1st-letter-sent",
      name: "1st Letter Sent",
      component: require("./1st-letter-sent/_index").default,
    },
    {
      path: "2nd-letter-sent",
      name: "2nd Letter Sent",
      component: require("./2nd-letter-sent/_index").default,
    },
  ],
};
