import { ContactManager_View_ChangeQueueContact } from "@app/core/contacts/change-queue/list/model";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (
  selectedRow: ContactManager_View_ChangeQueueContact
) => {
  const dynamicDisplayName = selectedRow.Id ? `- ${selectedRow.Id}` : "";
  return `Contact - Change Queue ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (
  selectedRow: ContactManager_View_ChangeQueueContact
) => {
  return getDisplayTextWithDashes([
    selectedRow.Classification,
    selectedRow.DisplayName,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return "Contact - Change Queue";
};

export const getBookmarkListViewDetail = () => {
  return "Contact - Change Queue - List";
};
