import { getDisplayTextWithDashes } from "@common/utils/common";

export const BuildingRegistersCertificatesBookmark = {
  getBookmarkListViewDisplayName() {
    return "Building - Registers";
  },
  getBookmarkListViewDetail() {
    return "Building - Registers - Certificates";
  },
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Building - Registers - Certificates ${dynamicDisplayName}`;
  },
  getBookmarkDetail(selectedRow: any) {
    return (
      getDisplayTextWithDashes([
        selectedRow?.Register_Number,
        selectedRow?.Contact,
      ]) || "N/A"
    );
  },
};
