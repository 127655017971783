import { AssessmentLevyBalancesViews } from "@app/products/property/assessments/[id]/components/child-screens/levy-balances/model";
import { useAssessmentLevyBalancesStore } from "@app/products/property/assessments/[id]/components/child-screens/levy-balances/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { CCGrid } from "@components/cc-grid/_index";
import Loading from "@components/loading/Loading";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import "./_index.scss";
import ChargeLevies from "./components/grids/charge-levies/_index";

export const LevyBalances = observer(() => {
  const {
    isLoading,
    isDetailLoading,
    columns,
    levyBalances,
    showFullyPaidLevies,
    views,
    view,
    setView,
    setShowFullyPaidLevies,
    loadViews,
    loadLevyBalance,
  } = useAssessmentLevyBalancesStore();
  const { assessmentId } = useAssessmentStore();

  useEffect(() => {
    loadViews();
  }, [loadViews]);

  useEffect(() => {
    if (!assessmentId || !view) return;
    loadLevyBalance(assessmentId, view.Id, showFullyPaidLevies);
  }, [view, assessmentId, showFullyPaidLevies, loadLevyBalance]);

  const handleOnViewChange = (event: DropDownListChangeEvent) => {
    setView(event.target.value);
  };

  const handleOnShowFullyPaidLevies = (event: CheckboxChangeEvent) => {
    setShowFullyPaidLevies(event.value);
  };

  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <div className="cc-assessment-levy-balance">
      <div className="cc-actions">
        <DropDownList
          className="cc-form-control"
          data={views ? views.Views : []}
          textField="Name"
          dataItemKey="Id"
          value={view}
          onChange={handleOnViewChange}
          disabled={isDetailLoading}
        />
        <Checkbox
          label="Show fully paid levies"
          className="cc-checkbox"
          checked={showFullyPaidLevies}
          onChange={handleOnShowFullyPaidLevies}
          disabled={isDetailLoading}
        />
      </div>
      <div className="cc-assessment-levy-balance-grid-container">
        <CCGrid
          isLoading={isDetailLoading}
          data={levyBalances?.LevyBalance ?? []}
          columnFields={columns || []}
          primaryField="index"
          detail={
            view?.Id === AssessmentLevyBalancesViews.By_FinancialArea
              ? null
              : ChargeLevies
          }
        />
      </div>
    </div>
  );
});
