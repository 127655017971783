import { KeyValuePair } from "@app/core/documents/model";
import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE } from "@app/products/property/assessments/compliance/[id]/constant";
import { ASSESSMENTS_DEBT_RECOVERY_ROUTE } from "@app/products/property/assessments/debt-recovery/[id]/constant";
import { ASSESSMENT_MASTER_PROPERTIES_ROUTE } from "@app/products/property/assessments/master-properties/[id]/constant";
import { ASSESSMENTS_PENSIONER_OR_REBATES_REBATE_CLAIMS_ROUTE } from "@app/products/property/assessments/rebates/rebate-claims/[id]/constant";
import { PROPERTY_CERTIFICATE_ROUTE } from "@app/products/property/certificates/[id]/constant";
import { CERTIFICATE_E_CERTIFICATE_REQUEST_ROUTE } from "@app/products/property/certificates/e-certificate-requests/[id]/constant";
import { PROPERTY_CHARGE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/constant";
import { PROPERTY_NOTICE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/constant";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { PROPERTY_DEFERRED_DUTY_ROUTE } from "@app/products/property/deferred-duty/[id]/constant";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import { JOURNALS_SESSION_LIST_ROUTE } from "@app/products/property/journals/session-list/[id]/constant";
import { PROPERTY_METERS_ROUTE } from "@app/products/property/meters/[id]/constant";
import { eComponent } from "@app/products/property/model";
import { PROPERTY_PARCEL_ROUTE } from "@app/products/property/parcels/[id]/constant";
import { PROPERTY_PIC_ROUTE } from "@app/products/property/pic/[id]/constant";
import { PROPERTY_REGISTERS_ROUTE } from "@app/products/property/registers/[id]/constant";
import { PROPERTY_SCHEMES_ROUTE } from "@app/products/property/schemes/[id]/constant";
import { PROPERTY_SUPPLEMENTARY_RATES_ROUTE } from "@app/products/property/supplementary-rates/[id]/constant";
import { PROPERTY_TITLES_ROUTE } from "@app/products/property/titles/[id]/constant";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SETTINGS_COMMUNICATION_TEMPLATE_ROUTE } from "@common/pages/settings/communication/template/_id/constant";

export interface ISearchFilter {
  SearchText: string;
  MaximumResult?: number;
  FilterGroup: string;
}
export interface ISearchForm {
  FilterGroup: KeyValuePair<any, any>;
  SearchText: string;
  MaximumResult?: number;
}

export interface IListingProps {
  ID?: number;
  RecordType_ENUM?: RECORDTYPE;
  RecordType_Name?: string;
  Description?: string;
  RowNumber?: number;
  Status?: string;
}

export interface IResponseProps {
  Listing?: IListingProps[];
  Total?: number;
}

export interface DTO_ApplicationSearch {
  SearchText: string;
  Items: DTO_ApplicationSearch_Item[];
  StartOn: string | null;
  SearchElapsedTime: string | null;
  ExceptionMessage: string;
}

export interface DTO_ApplicationSearch_Item {
  Id: number | null;
  Component: eComponent;
  ComponentLabel: string;
  SearchItemType: eApplicationSearchItemType;
  PopupText: string;
  AccountNumber: string;
  AccountReference: string;
  Name: string;
  Address: string;
  Description: string;
  Balance: number | null;
  Attributes: DTO_ApplicationSearch_ToolTipItem[];
}

export interface DTO_ApplicationSearch_ToolTipItem {
  Name: string;
  Value: any;
}

export enum eFilter {
  All_Results = "All Results",
  Property = "Property",
  Community_Asset = "Community Asset",
  Event_Management = "Event Management",
  Appointment_Management = "Appointment Management",
  CSL = "CSL",
  Animals = "Animals",
  Local_Laws = "Local Laws",
  NSPR = "NSPR",
  Town_Planning = "Town Planning",
  Building = "Building",
  Customer_Service = "Customer Service",
  Dev_Apps = "Dev Apps",
  Wastewater = "Wastewater",
  WWMS = "WWMS",
  CRS = "CRS",
  Health_Manager = "Health Manager",
  Street_Protection = "Street Protection",
  CRS_Centre_Access = "CRS Centre Access",
  Parking_Permits = "Parking Permits",
  Training_Manager = "Training Manager",
  Actions = "Actions",
  Contacts = "Contacts",
  Reports = "Reports",
  Asset = "Asset",
  Cemeteries = "Cemeteries",
  Cold_Chain = "Cold Chain",
  Complaints = "Complaints",
  Disability_Worker_Case_Management_System = "Disability Worker Case Management System",
  EventBookingSystem = "EventBookingSystem",
  FSR = "FSR",
  GIS = "GIS",
  Hard_Rubbish = "Hard Rubbish",
  Home_Page = "Home Page",
  HumanResource = "HumanResource",
  Human_Services_Regulator = "Human Services Regulator",
  Infringements = "Infringements",
  IT_Support = "IT Support",
  MailMerge = "MailMerge",
  Notices = "Notices",
  OtherSystems = "OtherSystems",
  Payroll = "Payroll",
  Phone_Messages = "Phone Messages",
  Project = "Project",
  Radiation_Safety = "Radiation Safety",
  Receipting = "Receipting",
  Speed_Sign_Management_System = "Speed Sign Management System",
  TelephoneActivity = "TelephoneActivity",
}
export const mappingURLPattern: { [key: string]: string } = {
  [eComponent.Unknown]: "",
  [eComponent.Assessment]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Parcel]: `${PROPERTY_PARCEL_ROUTE}/{id}`,
  [eComponent.Title]: `${PROPERTY_TITLES_ROUTE}/{id}`,
  [eComponent.Assessment_Transaction]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Certificate]: `${PROPERTY_CERTIFICATE_ROUTE}/{id}`,
  [eComponent.Register_Account]: `${PROPERTY_REGISTERS_ROUTE}/{id}`,
  [eComponent.Supplementary]: `${PROPERTY_SUPPLEMENTARY_RATES_ROUTE}/{id}`,
  [eComponent.Meter]: `${PROPERTY_METERS_ROUTE}/{id}`,
  [eComponent.Scheme_Account]: "/",
  [eComponent.Certificate_Request]: `${CERTIFICATE_E_CERTIFICATE_REQUEST_ROUTE}/{id}`,
  [eComponent.Charge]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Charge_Balances]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Notice_Group]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Street_Locality]: `${PROPERTY_PARCEL_ROUTE}/{id}`,
  [eComponent.GIS_Selection]: "PROPERTY_PARCEL_ROUTE/PROPERTY_TITLES_ROUTE",
  [eComponent.Certificates_Type]: `${PROPERTY_CERTIFICATE_ROUTE}/{id}`,
  [eComponent.Active_Certificates]: `${PROPERTY_CERTIFICATE_ROUTE}/{id}`,
  [eComponent.Active_Certificates_for_Responsible_Area]:
    "/property/certificate/list",
  [eComponent.Register]: "/property/registers/list",
  [eComponent.Master_Property]: `${ASSESSMENT_MASTER_PROPERTIES_ROUTE}/{id}`,
  [eComponent.Scheme]: `${PROPERTY_SCHEMES_ROUTE}/{id}`,
  [eComponent.Meter_Reading_Route]: "/property/meters/meter-reading-routes",
  [eComponent.Meter_Reading_Device]: "/property/meters/meter-reading-devices",
  [eComponent.Utility]: "/property/meters/meter-reading-routes",
  [eComponent.Scheme_Account_Transaction]: "/",
  [eComponent.Meter_Installation]: `${PROPERTY_METERS_ROUTE}/{id}`,
  [eComponent.Register_Account_Transaction]: `${PROPERTY_REGISTERS_ROUTE}/{id}`,
  [eComponent.Land_Tax_Exemption]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.ACT_Lease_Transfer]: "/",
  [eComponent.Deferred_Duty_Account]: `${PROPERTY_DEFERRED_DUTY_ROUTE}/{id}`,
  [eComponent.Deferred_Duty_Scheme]: "/",
  [eComponent.Deferred_Duty_Transaction]: `${PROPERTY_DEFERRED_DUTY_ROUTE}/{id}`,
  [eComponent.Deferred_Duty_Instalments]: `${PROPERTY_DEFERRED_DUTY_ROUTE}/{id}`,
  [eComponent.Entity]: `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/{id}`,
  [eComponent.Contact_Name_and_Address]: `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/{id}`,
  [eComponent.Assessment_Levy_Id]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Tasks_Run_Id]: "/",
  [eComponent.Tasks_Id]: "/",
  [eComponent.Collection]: "/",
  [eComponent.MenuItem]: "/",
  [eComponent.Journal]: `${PROPERTY_JOURNALS_ROUTE}/{id}`,
  [eComponent.Advanced_Search]: "/property/advanced-search/searches",
  [eComponent.Report]: "/",
  [eComponent.Home]: "/home",
  [eComponent.Charge_Instalment]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Charge_Levy]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Application_Search_No_Results]: "/",
  [eComponent.Session]: `${JOURNALS_SESSION_LIST_ROUTE}/{id}`,
  [eComponent.Associated_Name]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Lease]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Debt_Recovery]: `${ASSESSMENTS_DEBT_RECOVERY_ROUTE}/{id}`,
  [eComponent.Assessment_Rebate_Entitlement]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Arrangement]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Change_of_Ownership]: "/",
  [eComponent.Supplementary_Assessment]: `${PROPERTY_SUPPLEMENTARY_RATES_ROUTE}/{id}`,
  [eComponent.Arrangement_Letter]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Name_and_Address]: "/",
  [eComponent.Debt_Recovery_Action]: `${ASSESSMENTS_DEBT_RECOVERY_ROUTE}/{id}`,
  [eComponent.Debt_Recovery_PaymentsReceived]:
    "/property/assessments/debt-recovery/list/all",
  [eComponent.Debt_Recovery_Exemption]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Mail_Box]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Financial_Summary]: "/property/assessments/financial-summaries",
  [eComponent.Rebate_Claim]: `${ASSESSMENTS_PENSIONER_OR_REBATES_REBATE_CLAIMS_ROUTE}/{id}`,
  [eComponent.Concession_Card]: `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/{id}`,
  [eComponent.Valuation]: `${PROPERTY_ASSESSMENT_ROUTE}/{id}`,
  [eComponent.Address]: `${PROPERTY_PARCEL_ROUTE}/{id}`,
  [eComponent.CommunityCentral_Town_Planning_Application]: "/",
  [eComponent.CommunityCentral_Health_Manager_Premise]: "/",
  [eComponent.CommunityCentral_Animal_Registration]: "/",
  [eComponent.CommunityCentral_Building_Application]: "/",
  [eComponent.CommunityCentral_Local_Laws_Permit]: "/",
  [eComponent.CommunityCentral_Events_Management_System_Booking]: "/",
  [eComponent.CommunityCentral_Waste_Water_Permit]: "/",
  [eComponent.HazardId_Fire_Prevention]: "/",
  [eComponent.HazardId_Noxious_Weed]: "/",
  [eComponent.CommunityFinance_Customer]: "/",
  [eComponent.Charge_Run]: `${PROPERTY_CHARGE_RUNS_ROUTE}/{id}`,
  [eComponent.Notice_Run]: `${PROPERTY_NOTICE_RUNS_ROUTE}/{id}`,
  [eComponent.CommunityCentral_DevelopmentApplication]: "/",
  [eComponent.CommunityCentral_StreetProtection]: "/",
  [eComponent.CommunityCentral_Other]: "/",
  [eComponent.Compliance]: `${PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE}/{id}`,
  [eComponent.PIC]: `${PROPERTY_PIC_ROUTE}/{id}`,
  [eComponent.Communication_Template]: `${SETTINGS_COMMUNICATION_TEMPLATE_ROUTE}/{id}`,
  [eComponent.Workflow]: "",
};

export enum eApplicationSearchItemType {
  No_Matches_Found = "No_Matches_Found",
  Favourite = "Favourite",
  Recently_Accessed = "Recently_Accessed",
  Active_Journal = "Active_Journal",
  Active_Session = "Active_Session",
  Active_Supplementary = "Active_Supplementary",
  Match_on_Assessment_Number = "Match_on_Assessment_Number",
  Match_on_Assessment_Reference = "Match_on_Assessment_Reference",
  Match_on_Property_Address = "Match_on_Property_Address",
  Match_on_Ratepayer_Name = "Match_on_Ratepayer_Name",
  Match_on_Owners_Name = "Match_on_Owners_Name",
  Match_on_Assessment_Id = "Match_on_Assessment_Id",
  Match_on_Entity_Name = "Match_on_Entity_Name",
  Match_on_Entity_Address = "Match_on_Entity_Address",
  Match_on_Entity_Phone_Number = "Match_on_Entity_Phone_Number",
  Match_on_Parcel_Address = "Match_on_Parcel_Address",
  Match_on_Parcel_Id = "Match_on_Parcel_Id",
  Match_on_Parcel_Reference = "Match_on_Parcel_Reference",
  Collection = "Collection",
  Report = "Report",
  Menu_Item = "Menu_Item",
  Journal_Entry_Assessment = "Journal_Entry_Assessment",
  Journal_Entry_Scheme_Account = "Journal_Entry_Scheme_Account",
  Journal_Entry_Deferred_Duty_Account = "Journal_Entry_Deferred_Duty_Account",
  Journal_Entry_Register_Account = "Journal_Entry_Register_Account",
  Property_Search = "Property_Search",
  Match_on_Title_Id = "Match_on_Title_Id",
}

export const mappingDataShow: Partial<Record<eComponent, string>> = {
  [eComponent.Unknown]: "",
  [eComponent.Assessment]: "Address",
  [eComponent.Parcel]: "Address",
  [eComponent.Title]: "Name",
  [eComponent.Assessment_Transaction]: "Name",
  [eComponent.Certificate]: "Name",
  [eComponent.Register_Account]: "Name",
  [eComponent.Supplementary]: "Name",
  [eComponent.Meter]: "Name",
  [eComponent.Scheme_Account]: "Name",
  [eComponent.Certificate_Request]: "Name",
  [eComponent.Charge]: "Name",
  [eComponent.Charge_Balances]: "Name",
  [eComponent.Notice_Group]: "Name",
  [eComponent.Street_Locality]: "Name",
  [eComponent.GIS_Selection]: "Name",
  [eComponent.Certificates_Type]: "Name",
  [eComponent.Active_Certificates]: "Name",
  [eComponent.Active_Certificates_for_Responsible_Area]: "Name",
  [eComponent.Register]: "Name",
  [eComponent.Master_Property]: "Name",
  [eComponent.Scheme]: "Name",
  [eComponent.Meter_Reading_Route]: "Name",
  [eComponent.Meter_Reading_Device]: "Name",
  [eComponent.Utility]: "Name",
  [eComponent.Scheme_Account_Transaction]: "Name",
  [eComponent.Meter_Installation]: "Name",
  [eComponent.Register_Account_Transaction]: "Name",
  [eComponent.Land_Tax_Exemption]: "Name",
  [eComponent.ACT_Lease_Transfer]: "Name",
  [eComponent.Deferred_Duty_Account]: "Name",
  [eComponent.Deferred_Duty_Scheme]: "Name",
  [eComponent.Deferred_Duty_Transaction]: "Name",
  [eComponent.Deferred_Duty_Instalments]: "Name",
  [eComponent.Entity]: "Name",
  [eComponent.Contact_Name_and_Address]: "Name",
  [eComponent.Assessment_Levy_Id]: "Name",
  [eComponent.Tasks_Run_Id]: "Name",
  [eComponent.Tasks_Id]: "Name",
  [eComponent.Collection]: "Name",
  [eComponent.MenuItem]: "Name",
  [eComponent.Journal]: "Name",
  [eComponent.Advanced_Search]: "Name",
  [eComponent.Report]: "Attributes.Group",
  [eComponent.Home]: "Name",
  [eComponent.Charge_Instalment]: "Name",
  [eComponent.Charge_Levy]: "Name",
  [eComponent.Application_Search_No_Results]: "Name",
  [eComponent.Session]: "Name",
  [eComponent.Associated_Name]: "Name",
  [eComponent.Lease]: "Name",
  [eComponent.Debt_Recovery]: "Name",
  [eComponent.Assessment_Rebate_Entitlement]: "Name",
  [eComponent.Arrangement]: "Name",
  [eComponent.Change_of_Ownership]: "Name",
  [eComponent.Supplementary_Assessment]: "Name",
  [eComponent.Arrangement_Letter]: "Name",
  [eComponent.Name_and_Address]: "Name",
  [eComponent.Debt_Recovery_Action]: "Name",
  [eComponent.Debt_Recovery_PaymentsReceived]: "Name",
  [eComponent.Debt_Recovery_Exemption]: "Name",
  [eComponent.Mail_Box]: "Name",
  [eComponent.Financial_Summary]: "Name",
  [eComponent.Rebate_Claim]: "Name",
  [eComponent.Concession_Card]: "Name",
  [eComponent.Valuation]: "Name",
  [eComponent.Address]: "Name",
  [eComponent.CommunityCentral_Town_Planning_Application]: "Name",
  [eComponent.CommunityCentral_Health_Manager_Premise]: "Name",
  [eComponent.CommunityCentral_Animal_Registration]: "Name",
  [eComponent.CommunityCentral_Building_Application]: "Name",
  [eComponent.CommunityCentral_Local_Laws_Permit]: "Name",
  [eComponent.CommunityCentral_Events_Management_System_Booking]: "Name",
  [eComponent.CommunityCentral_Waste_Water_Permit]: "Name",
  [eComponent.HazardId_Fire_Prevention]: "Name",
  [eComponent.HazardId_Noxious_Weed]: "Name",
  [eComponent.CommunityFinance_Customer]: "Name",
  [eComponent.Charge_Run]: "Name",
  [eComponent.Notice_Run]: "Name",
  [eComponent.CommunityCentral_DevelopmentApplication]: "Name",
  [eComponent.CommunityCentral_StreetProtection]: "Name",
  [eComponent.CommunityCentral_Other]: "Name",
  [eComponent.Compliance]: "Name",
};
