import {
  WHICH_JOURNAL_FORM_STEP,
  WhichJournalFormStep,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/_index";
import {
  NEW_JOURNAL_FORM_STEP,
  NewJournalFormStep,
} from "@app/products/property/journals/components/form-elements/new-journal/_index";
import {
  TO_ACCOUNT_FORM_STEP,
  ToAccountFormStep,
} from "@app/products/property/registers/[id]/components/dialogs/form-steps/transfer-to-another-account/components/form-elements/to-account/_index";
import {
  TRANSACTION_FORM_STEP,
  TransactionFormStep,
} from "@app/products/property/registers/[id]/components/dialogs/form-steps/transfer-to-another-account/components/form-elements/transaction/_index";
import { isShowParkButton } from "@app/products/property/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import {
  defaultNewJournal,
  defaultToAccount,
  defaultTransaction,
  defaultWhichJournal,
} from "./config";
interface ITransferToAnotherAccountDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
}
const steps: IStep[] = [
  {
    label: "Journal",
    initialValues: defaultWhichJournal,
    component: WhichJournalFormStep,
    visible: true,
    key: WHICH_JOURNAL_FORM_STEP,
  },
  {
    label: "New journal",
    initialValues: defaultNewJournal,
    component: NewJournalFormStep,
    visible: true,
    key: NEW_JOURNAL_FORM_STEP,
    options: {
      showWhichSupplementary: false,
      showStatusField: true,
    },
  },
  {
    label: "To account",
    initialValues: defaultToAccount,
    component: ToAccountFormStep,
    visible: true,
    key: TO_ACCOUNT_FORM_STEP,
  },
  {
    label: "Transaction",
    component: TransactionFormStep,
    visible: true,
    initialValues: defaultTransaction,
    key: TRANSACTION_FORM_STEP,
  },
];
export const TransferToAnotherAccountDialog = ({
  onClose,
  onSubmit,
  isIncompleteMode = false,
  isFromActionList = false,
}: ITransferToAnotherAccountDialogProps) => {
  return (
    <CCFormStep
      onSubmit={onSubmit}
      initialSteps={steps}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="60%"
          maxHeight="75%"
          titleHeader={"Transfer Register Account Transactions"}
          onClose={onClose}
          bodyElement={renderProps.children}
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              {isShowParkButton(isFromActionList, isIncompleteMode) && (
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Park
                </Button>
              )}
              <Button className={"cc-dialog-button"} onClick={onClose}>
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                disabled={renderProps.nextButton.disabled}
                className={"cc-dialog-button"}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
