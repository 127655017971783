import { AmsAssetRegister } from "@app/products/ams/assets/[id]/model";
import { AmsUrl } from "@app/products/ams/constants/ams.url";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const loadAssetManagerById = async (
  id: number | string
): Promise<APIResponse<AmsAssetRegister | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<AmsAssetRegister>(
      `${AmsUrl.ASSET_REGISTER_ITEM}${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const putAssetManagerById = async (
  payload: AmsAssetRegister
): Promise<APIResponse<any | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<AmsAssetRegister>(
      `${AmsUrl.ASSET_REGISTER_ITEM}${payload.Id}`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const postAssetManagerById = async (
  payload: AmsAssetRegister,
  isNew: boolean
): Promise<APIResponse<any | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<AmsAssetRegister>(
      `${AmsUrl.ASSET_REGISTER_ITEM}${isNew ? 0 : payload.Id}`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
