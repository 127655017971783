import { colAssessmentsDebtRecovery } from "@app/products/property/components/action-bar/dialog/modify-debt-recovery/form-element/details/config";
import { DTO_Assessments_DebtRecovery } from "@app/products/property/components/action-bar/dialog/modify-debt-recovery/model";
import { useModifyDebtRecoveryDialogStore } from "@app/products/property/components/action-bar/dialog/modify-debt-recovery/store";
import { nameOfLov } from "@app/products/property/model";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const DebtRecoveryFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    nameOf,
    formRenderProps,
    options = { isReadOnly: false, workflowDraftId: 0 },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const assessmentListData =
      getFieldValue(nameOf("AssessmentListSelected")) ?? [];
    const { debtRecoveryLOVs } = useModifyDebtRecoveryDialogStore();
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
      useState<boolean>(false);

    const handleOnDelete = () => {
      //@TODO: waiting api data grid
      onChange(nameOf("AssessmentListSelected"), { value: [] });
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Application name</label>
            <Field
              name={nameOf("AppliacationName")}
              component={CCInput}
              placeholder={"Application name"}
              readOnly={options?.isReadOnly}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Reason for change</label>
            <Field
              name={nameOf("ReasonForChange")}
              component={CCSearchComboBox}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              disabled={options?.isReadOnly}
              data={
                debtRecoveryLOVs?.dtoDebtRecovery_LOVs?.ReasonForChange ?? []
              }
              isUseDefaultOnchange
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Assessments on which Debt Recovery is for
            </label>
            <CCGrid
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    type="button"
                    iconClass="fas fa-plus"
                    title="Add"
                    onClick={() => {}}
                  />
                  <Button
                    type="button"
                    iconClass="fas fa-minus"
                    title="Remove"
                    onClick={() => setShowConfirmDeleteDialog(true)}
                    disabled={isEmpty(assessmentListData)}
                  />
                </div>
              }
              readOnly={options?.isReadOnly}
              data={[]}
              columnFields={colAssessmentsDebtRecovery}
              primaryField={nameOf("Assessment_ID")}
              selectableMode="multiple"
              onSelectionChange={(dataItem: DTO_Assessments_DebtRecovery[]) => {
                if (dataItem)
                  onChange(nameOf("AssessmentListSelected"), {
                    value: [...dataItem],
                  });
              }}
              selectedRows={assessmentListData}
            />
          </div>
        </div>

        {showConfirmDeleteDialog && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={"Are you sure you want to delete?"}
            onClosePopup={() => setShowConfirmDeleteDialog(false)}
            onAsyncConfirm={handleOnDelete}
          />
        )}
      </section>
    );
  }
);
