import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { EWatchStatusLoading } from "@common/stores/route/model";
import { routeDataStoreInstance } from "@common/stores/route/store";
import { IDataReceivedParams } from "@components/cc-grid/_index";
import { loadOData } from "@components/cc-grid/components/grid-loader/api";
import { transformUrlFilterForContains } from "@components/cc-grid/components/grid-loader/util";
import { CCGridCancelMessage } from "@components/cc-grid/config";
import { CCGridEventType } from "@components/cc-grid/constant";
import { TPositionLoad } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { State, toODataString } from "@progress/kendo-data-query";
import axios, { CancelTokenSource } from "axios";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { useDeepCompareEffect } from "react-use";

export interface ICCGridLoaderProps {
  positionLoad: TPositionLoad;
  dataUrl: string;
  gridDataState: State;
  onDataReceived: (params: IDataReceivedParams) => Promise<void>;
  gridId?: string;
  isUseCancelRequest?: boolean;
  isNotCallDataGrid?: boolean;
}

export const CCGridLoader = memo(
  ({
    positionLoad,
    dataUrl,
    gridDataState,
    onDataReceived,
    gridId,
    isUseCancelRequest,
    isNotCallDataGrid,
  }: ICCGridLoaderProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [responseError, setResponseError] = useState<
      APIResponseError | undefined
    >();
    const lastedRequestURL = useRef<string>("");
    const cancelRequest = useRef<CancelTokenSource>();
    const requestNewData = useCallback(
      (requestURL: string) => {
        if (isNotCallDataGrid) return;
        if (isUseCancelRequest) {
          if (
            lastedRequestURL?.current &&
            lastedRequestURL?.current !== requestURL
          ) {
            cancelRequest.current?.cancel(CCGridCancelMessage);
          }
          cancelRequest.current = axios.CancelToken.source();
        }
        lastedRequestURL.current = requestURL;
        setLoading(true);
        routeDataStoreInstance.setLoadingList(
          EWatchStatusLoading.IsLoadingGrid,
          true
        );
        loadOData(requestURL, cancelRequest.current).then(
          async (response: APIResponse) => {
            routeDataStoreInstance.setLoadingList(
              EWatchStatusLoading.IsLoadingGrid,
              false
            );
            if (
              isSuccessResponse(response) ||
              response.error === CCGridCancelMessage
            ) {
              setResponseError(undefined);
            } else {
              setResponseError({
                status: response.status,
                error: response.error,
              });
            }
            if (response.error !== CCGridCancelMessage) {
              await onDataReceived({
                result: isSuccessResponse(response)
                  ? {
                      data: response.data.value,
                      total: response.data["@odata.count"],
                    }
                  : undefined,
                state: gridDataState,
              });
              setLoading(false);
            }
          }
        );
      },

      [gridDataState, isUseCancelRequest, isNotCallDataGrid, onDataReceived]
    );

    const newRequestURL = useMemo(() => {
      const url = transformUrlFilterForContains(
        `${dataUrl}${toODataString(gridDataState, {
          utcDates: true,
        })}`
      );
      return url.replace(/&$/, "");
    }, [dataUrl, gridDataState]);

    useAddUniqueEventEmitter([
      {
        eventType: CCGridEventType.RefreshOData,
        listener: (event: any) => {
          if (!event?.gridIds) return requestNewData(newRequestURL); //Reload all grid
          if (Array.isArray(event?.gridIds) && event.gridIds.includes(gridId))
            requestNewData(newRequestURL); // Reload grid with list gridIds
        },
      },
    ]);

    useDeepCompareEffect(() => {
      if (lastedRequestURL.current !== newRequestURL) {
        requestNewData(newRequestURL);
      }
    }, [dataUrl, gridDataState, requestNewData]);

    const gridContent = positionLoad()?.gridContent;

    useEffect(() => {
      if (!gridContent) return;
      if (responseError) {
        gridContent.classList.add("cc-grid-has-error");
      } else {
        gridContent.classList.remove("cc-grid-has-error");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseError]);

    return loading ? (
      <GridLoadingPanel positionLoad={positionLoad} />
    ) : responseError ? (
      <GridLoadFailedPanel
        positionLoad={positionLoad}
        responseError={responseError}
        onReload={() => requestNewData(lastedRequestURL.current)}
      />
    ) : null;
  }
);

interface ILoadingPanelProps {
  positionLoad: TPositionLoad;
}
export const GridLoadingPanel = ({ positionLoad }: ILoadingPanelProps) => {
  const gridContent = positionLoad()?.gridContent;
  const gridContainer =
    gridContent?.parentElement?.closest(".k-grid-container");
  const existedLoading = gridContainer?.querySelector(".k-loading-mask");

  useEffect(() => {
    if (existedLoading) gridContainer?.classList.add("cc-grid-is-loading");
    return () => gridContainer?.classList.remove("cc-grid-is-loading");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existedLoading]);

  const position = {
    top: gridContent?.scrollTop ?? 0 + "px",
    left: gridContent?.scrollLeft ?? 0 + "px",
  };
  const loadingPanel = (
    <div
      className="k-loading-mask"
      style={
        positionLoad().isGridDetail
          ? {
              ...position,
              width: positionLoad().width,
            }
          : position
      }
    >
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  );

  return gridContent
    ? ReactDOM.createPortal(loadingPanel, gridContent)
    : loadingPanel;
};

interface ILoadFailedPanelProps {
  positionLoad: TPositionLoad;
  onReload?: () => void;
  responseError?: APIResponseError;
}
const GridLoadFailedPanel = ({
  positionLoad,
  onReload,
  responseError: response,
}: ILoadFailedPanelProps) => {
  const errorComponent = (
    <CCLoadFailed
      style={{ width: positionLoad().width }}
      onReload={onReload}
      responseError={response}
    />
  );

  const gridContent = positionLoad()?.gridContent;
  return gridContent
    ? ReactDOM.createPortal(errorComponent, gridContent)
    : errorComponent;
};

interface ICCGridLoadFailed {
  positionLoad: TPositionLoad;
  errorComponent?: JSX.Element | null;
  setGridData: React.Dispatch<React.SetStateAction<any[]>>;
}
export const CCGridLoadFailed = memo(
  ({ positionLoad, errorComponent, setGridData }: ICCGridLoadFailed) => {
    const gridContent = positionLoad()?.gridContent;
    useEffect(() => {
      if (!gridContent) return;
      if (errorComponent) {
        gridContent.classList.add("cc-grid-has-error");
      } else {
        gridContent.classList.remove("cc-grid-has-error");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorComponent]);

    if (errorComponent && gridContent) {
      setGridData([]);
      return ReactDOM.createPortal(errorComponent, gridContent);
    } else {
      return null;
    }
  }
);
