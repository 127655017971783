import { NOTICES_ROUTE } from "@app/core/notices/[id]/constant";
import { Svc_Animals_Notice } from "@app/products/animals/notices/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_Animals_Notice>();

export const colAnimalsNoticesByDueStatus: IColumnFields[] = [
  {
    field: nameOf("Type"),
    title: "Type",
    locked: true,
    linkTo: (dataItem: Svc_Animals_Notice) => {
      return `${NOTICES_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: nameOf("DateServed"),
    title: "Served",
    format: DATE_FORMAT.DATE,
  },
  { field: nameOf("ReferenceNumber"), title: "Number" },
  { field: nameOf("ContactName"), title: "Name" },
  { field: nameOf("Officer"), title: "Officer" },
  {
    field: nameOf("DateDue"),
    title: "Compliance Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateRevoked"),
    title: "Complied / Revoked",
    format: DATE_FORMAT.DATE,
  },
  { field: nameOf("DueStatus"), title: "Due Status" },
];
