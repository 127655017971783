import { DTO_Component_ChangeOfOwnership } from "@app/products/property/assessments/[id]/components/child-screens/transfer/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const getParcelOwnership = async (
  parcelsId: number
): Promise<DTO_Component_ChangeOfOwnership | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Component_ChangeOfOwnership>(
        `api/property/internal/parcel/${parcelsId}/changeofownership`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
