import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";
import { ImportLeaseTransferDialog } from "@app/products/property/changes-of-ownership/lease-transfers/components/action-bar/import-lease-transfer/components/form-elements/_index";

export const ImportLeaseTransferButton = () => {
  const [showImportLeaseTransferDialog, setShowImportLeaseTransferDialog] =
    useState<boolean>(false);

  return (
    <>
      <CCNavButton
        title="Import lease transfer"
        onClick={() => setShowImportLeaseTransferDialog(true)}
      />
      {showImportLeaseTransferDialog && (
        <ImportLeaseTransferDialog
          onClose={() => {
            setShowImportLeaseTransferDialog(false);
          }}
        />
      )}
    </>
  );
};
