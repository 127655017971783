import { CORE_LOCATION_REGISTER_ROUTE } from "@app/core/location-register/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

interface INewLocationRegisterButtonProps {
  isDisabled?: boolean;
}

export const NewLocationRegisterButton = observer(
  ({ isDisabled }: INewLocationRegisterButtonProps) => {
    const history = useHistory();
    return (
      <CCNavButton
        title="New"
        disabled={isDisabled}
        onClick={() => history.push(`${CORE_LOCATION_REGISTER_ROUTE}/new`)}
      />
    );
  }
);
