import { nameOfFactory } from "@common/utils/common";
import { VO_PIC_Charge_Run } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/model";
import { IColumnFields } from "@components/cc-grid/model";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { PROPERTY_PIC_CHARGE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/constant";

const nameOf = nameOfFactory<VO_PIC_Charge_Run>();
export const colListPICChargeRuns: IColumnFields[] = [
  {
    field: nameOf("PIC_Charge_Run_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
    locked: true,
    linkTo: (dataItem) =>
      `${PROPERTY_PIC_CHARGE_RUNS_ROUTE}/${dataItem.PIC_Charge_Run_Id}`,
  },
  {
    field: nameOf("CRT_Name"),
    title: "Type",
  },
  {
    field: nameOf("RP_Name"),
    title: "Rating Period",
  },
  {
    field: nameOf("IP_Name"),
    title: "Instalment Plan",
  },
  {
    field: nameOf("CR_Charge_Date"),
    title: "Charge Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CR_Name"),
    title: "Name",
  },
  {
    field: nameOf("CR_Code"),
    title: "Code",
  },
  {
    field: nameOf("CR_Description"),
    title: "Description",
  },
  {
    field: nameOf("CR_Created_By_UserId"),
    title: "Created By",
  },
  {
    field: nameOf("CR_Created_On_DateTime"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("CR_Updated_By_UserId"),
    title: "Updated By",
  },
  {
    field: nameOf("CR_Updated_On_DateTime"),
    title: "Updated On",
    format: DATETIME_FORMAT.DATETIME,
  },
];
