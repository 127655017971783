import { eventEmitter } from "@/App";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { WebLink } from "@app/core/documents/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { DocumentCategory } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/constant";
import { eComponent } from "@app/products/property/components/associations/model";
import {
  downloadAssessmentDocument,
  putDeactivateAttachment,
  putDeactivateWeblink,
} from "@app/products/property/components/child-screen/documents/api";
import { PropertyDocumentEventType } from "@app/products/property/components/child-screen/documents/constant";
import { DTO_Document } from "@app/products/property/components/child-screen/documents/model";
import { useDocumentStore } from "@app/products/property/components/child-screen/documents/store";
import { isSuccessResponse } from "@common/apis/util";
import { DownloadFile } from "@common/constants/downloadfile";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";

interface IPropertyDocumentsProps {
  componentNumber: eComponent;
  componentId: number;
}
const nameOf = nameOfFactory<DTO_Document>();
export const PropertyDocuments = observer(
  ({ componentNumber, componentId }: IPropertyDocumentsProps) => {
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const [isDeleting, setIsDeleting] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
      useState<boolean>(false);
    const {
      resetStore,
      colDocument,
      isLoading,
      loadDocuments,
      responseLoadError,
      selectedDocuments,
      setSelectedDocuments,
      documents,
    } = useDocumentStore();

    useEffect(() => {
      (async () => {
        if (!isNil(componentNumber) && !isNil(componentId)) {
          await loadDocuments(componentNumber, componentId);
        }
      })();
      return () => {
        resetStore();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentId, componentNumber]);

    useAddUniqueEventEmitter([
      {
        eventType: PropertyDocumentEventType.RefreshData,
        listener: () => {
          (async () => {
            if (!isNil(componentNumber) && !isNil(componentId)) {
              await loadDocuments(componentNumber, componentId);
            }
          })();
        },
      },
    ]);

    const columnFields = useMemo(
      () =>
        colDocument.map((col) => {
          if (col.field === nameOf("Title")) {
            col.handleOnClick = async (dataItem: DTO_Document) => {
              const response = await downloadAssessmentDocument(dataItem.ID);
              DownloadFile(
                response?.data ?? "",
                dataItem?.ContentType ?? "",
                dataItem?.FileName ?? "document"
              );
              if (isSuccessResponse(response)) {
                clearErrorNotification();
                pushNotification({
                  title: "Document downloaded successfully",
                  type: "success",
                });
              } else {
                clearErrorNotification();
                pushNotification({
                  autoClose: false,
                  description: response.data.Errors ?? response.statusText,
                  type: "error",
                });
              }
            };
          }
          return col;
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [colDocument]
    );

    const handleDeleteFile = async (id: number, reason?: string) => {
      setIsDeleting(true);
      const response = await putDeactivateAttachment(id, reason);
      setIsDeleting(false);
      if (isSuccessResponse(response)) {
        if (response.data?.DeactivateAttachmentResponse?.IsSuccess) {
          setSelectedDocuments([]);
          clearErrorNotification();
          pushNotification({
            title: "Document deleted successfully",
            type: "success",
          });
          //Refresh document accordion after updated successfully
          eventEmitter.emit(PropertyDocumentEventType.RefreshData);
          // Refresh History
          eventEmitter.emit(CCJournalEventType.RefreshData);
        } else {
          pushNotification({
            autoClose: false,
            description:
              response.data?.DeactivateAttachmentResponse?.SuccessMessage ??
              response.statusText ??
              "Delete document failed",
            type: "error",
          });
        }
      } else {
        clearErrorNotification();
        pushNotification({
          autoClose: false,
          description: response.data.ErrorMessage ?? response.statusText,
          type: "error",
        });
      }
    };

    const handleDeleteWeblink = async (id: number, reason?: string) => {
      setIsDeleting(true);
      const payload = { Weblink_Delete_Reason: reason } as WebLink;
      const response = await putDeactivateWeblink(id, payload);
      setIsDeleting(false);
      if (
        isSuccessResponse(response) &&
        response.data?.ErrorStatus === IdentityPacketErrorStatus.Success
      ) {
        setSelectedDocuments([]);
        clearErrorNotification();
        pushNotification({
          title: "Document deleted successfully.",
          type: "success",
        });
        //Refresh document accordion after updated successfully
        eventEmitter.emit(PropertyDocumentEventType.RefreshData);
        // Refresh History
        eventEmitter.emit(CCJournalEventType.RefreshData);
      } else {
        clearErrorNotification();
        pushNotification({
          autoClose: false,
          title: response.data?.Errors ?? "Delete document failed.",
          type: "error",
        });
      }
    };

    const isWeblink =
      selectedDocuments?.[0]?.Category === DocumentCategory.Weblink;

    const handleOnDelete = async (data: IDeleteConfirmation) => {
      if (!selectedDocuments?.length) return;
      const id: number | undefined = selectedDocuments?.[0]?.ID;
      const reason = data?.Reason;
      if (!isNil(id) && reason) {
        await (isWeblink
          ? handleDeleteWeblink(id, reason)
          : handleDeleteFile(id, reason));
      }
      setShowConfirmDeleteDialog(false);
    };

    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadDocuments(componentNumber, componentId);
          }}
        />
      );

    return (
      <div className="cc-ppr-documents">
        <CCGrid
          isLoading={isLoading}
          toolbar={
            <div className="cc-grid-tools-bar">
              <Button
                type="button"
                iconClass="fas fa-minus"
                title="Remove"
                disabled={!selectedDocuments?.length}
                onClick={() => setShowConfirmDeleteDialog(true)}
              />
            </div>
          }
          data={documents}
          selectableMode="single"
          primaryField={nameOf("ID")}
          selectedRows={selectedDocuments}
          onSelectionChange={(dataItem: DTO_Document[]) => {
            setSelectedDocuments(dataItem ? [...dataItem] : []);
          }}
          columnFields={columnFields}
          state={{
            sort: [{ field: nameOf("CreatedOn"), dir: "desc" }],
          }}
        />
        {showConfirmDeleteDialog && (
          <ConfirmDialog
            title="Confirm Deletion"
            message={`Are you sure you want to delete the ${
              isWeblink ? "weblink" : "attachment"
            }?`}
            subMessage="If yes, enter the reason below (your comment will be visible in the History):"
            isLoadingYes={isDeleting}
            onClosePopup={() => setShowConfirmDeleteDialog(false)}
            onAsyncConfirm={handleOnDelete}
            isReasonRequired
          />
        )}
      </div>
    );
  }
);
