import {
  DTO_Entity_LOVs,
  DTO_Workflow_CreateContact,
  RequestObjFromSpatial,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const getWorkflowNewContact = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_CreateContact>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/createentity/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowContact = async (
  requestObjSpatial: RequestObjFromSpatial | object = {}
): Promise<APIResponse<DTO_Workflow_CreateContact>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/createentity/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessNewContact = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_CreateContact
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_CreateContact>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/createentity/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataContact =
  | [APIResponse<DTO_Entity_LOVs>, APIResponse<DTO_Workflow_CreateContact>]
  | APIResponse<DTO_Entity_LOVs>
  | APIResponse<DTO_Workflow_CreateContact>
  | undefined;
export const getInitialDataContact = async (
  workflowType: WorkflowTypes,
  workflowDraftId: number | undefined,
  isNewContact: boolean = false
): Promise<IGetInitialDataContact> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Entity_LOVs>(
        `/api/property/internal/workflow/entity/lovs?workflowType=${workflowType}`
      ),
      !isNewContact && !isNil(workflowDraftId)
        ? getWorkflowNewContact(workflowDraftId)
        : getNewWorkflowContact(),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
