import { getRebateClaimSummaryByIdAndViewConfigs } from "@app/products/property/assessments/rebates/rebate-claims/[id]/api";
import { DTO_RebateClaim_Summary } from "@app/products/property/assessments/rebates/rebate-claims/[id]/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import { ICCNotification } from "@components/cc-notification/components/cc-notification-item/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class RebateClaimStore {
  private _rebateClaim?: DTO_RebateClaim_Summary = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _notifications: ICCNotification[] = [];
  private _columnSummary: IColumnFields[] = [];
  private _columnTransactions: IColumnFields[] = [];
  private _selectedTransactions: any[] = []; //TODO: Currently using mock data, DTO will be defined later
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };
  get rebateClaim() {
    return this._rebateClaim;
  }
  setRebateClaim = (rebateClaim?: DTO_RebateClaim_Summary) => {
    runInAction(() => {
      this._rebateClaim = rebateClaim;
    });
  };
  get columnSummary() {
    return this._columnSummary;
  }
  setColumnSummary = (columnSummary: IColumnFields[]) => {
    runInAction(() => {
      this._columnSummary = columnSummary;
    });
  };
  get columnTransactions() {
    return this._columnTransactions;
  }
  setColumnTransactions = (columnTransactions: IColumnFields[]) => {
    runInAction(() => {
      this._columnTransactions = columnTransactions;
    });
  };
  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  get notifications() {
    return this._notifications;
  }
  setNotifications = (notifications: ICCNotification[]) => {
    runInAction(() => {
      this._notifications = notifications;
    });
  };
  get rebateClaimId() {
    return this.rebateClaim?.Id;
  }

  //TODO: Currently using mock data, DTO will be defined later
  get selectedTransactions() {
    return this._selectedTransactions;
  }
  setSelectedTransactions = (selectedTransactions: any[]) => {
    runInAction(() => {
      this._selectedTransactions = selectedTransactions;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._rebateClaim = undefined;
      this._isLoading = false;
      this._notifications = [];
      this._responseLoadError = undefined;
      this._selectedTransactions = [];
    });
  };
  loadRebateClaim = async (rebateClaimId: number) => {
    this.setIsLoading(true);
    const response = await getRebateClaimSummaryByIdAndViewConfigs(
      rebateClaimId
    );
    this.setIsLoading(false);
    let errorResponse = undefined;
    if (Array.isArray(response)) {
      const [
        responseTransactionsViewConfig,
        responseSummaryViewConfig,
        responseSummary,
      ] = response;
      const dataSummary = responseSummary?.data;
      if (
        Array.isArray(responseTransactionsViewConfig) &&
        Array.isArray(responseSummaryViewConfig) &&
        dataSummary
      ) {
        this.setRebateClaim({
          ...dataSummary,
          Id: dataSummary.RebateTransactionSummary?.Rebate_Claim_Id,
          RebateClaim_Name:
            dataSummary.RebateTransactionSummary?.Rebate_Claim_Name,
        });
        this.setColumnSummary(responseSummaryViewConfig);
        this.setColumnTransactions(responseTransactionsViewConfig);
      } else {
        errorResponse = responseTransactionsViewConfig as APIResponseError;
        switch (true) {
          case !Array.isArray(responseSummaryViewConfig):
            errorResponse = responseSummaryViewConfig as APIResponseError;
            break;
          case !isSuccessResponse(responseSummary):
            errorResponse = responseSummary;
            break;
        }
      }
    } else {
      errorResponse = response as APIResponse;
    }
    if (errorResponse) {
      errorResponse = {
        ...errorResponse,
        error: errorResponse.error ?? "Load failed",
      };
    }
    this.setResponseLoadError(errorResponse);
  };
}

const rebateClaimStoreContext = createContext(new RebateClaimStore());
export const useRebateClaimStore = () => {
  return useContext(rebateClaimStoreContext);
};
