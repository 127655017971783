import { ILovDetailPIC } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/pic-details/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ReactivatePICDetailStepStore {
  private _lovPICDetail?: ILovDetailPIC = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get lovPICDetail() {
    return toJS(this._lovPICDetail);
  }
  setLovPICDetail = (lov: ILovDetailPIC) => {
    runInAction(() => {
      this._lovPICDetail = lov;
    });
  };
}

const reactivatePICDetailStepStoreContext = createContext(
  new ReactivatePICDetailStepStore()
);
export const useReactivatePICDetailStepStoreContext = () => {
  return useContext(reactivatePICDetailStepStoreContext);
};
