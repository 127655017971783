import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { iconAnimalsColumnDangerousConfig } from "@app/products/animals/config";
import { AnimalsRegister } from "@app/products/animals/model";
import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
} from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";
const nameOf = nameOfFactory<AnimalsRegister>();

export const colAnimalsRenewalsAllByDueStatus: IColumnFields[] = [
  {
    field: nameOf("RegistrationNumber"),
    title: "Registration Number",
    locked: true,
    linkTo: (dataItem: AnimalsRegister) => {
      return `${ANIMALS_ROUTE}/` + dataItem.ID;
    },
  },
  ...iconAnimalsColumnDangerousConfig,
  {
    field: nameOf("TagNo"),
    title: "Tag Number",
  },
  { field: nameOf("AnimalOwner"), title: "Animal Owner" },
  { field: nameOf("AnimalOwnerEmail"), title: "Email" },
  {
    field: nameOf("SiteAddress"),
    title: "Registered Address",
  },
  { field: nameOf("Locality"), title: "Locality" },
  { field: nameOf("Type"), title: "Animal Type" },
  { field: nameOf("Breed"), title: "Breed" },
  { field: nameOf("AnimalName"), title: "Name" },
  { field: nameOf("Colour"), title: "Colour" },
  { field: nameOf("Gender"), title: "Gender" },
  {
    field: nameOf("Age"),
    title: "Age",
  },
  {
    field: nameOf("MicrochipNo"),
    title: "Chip Number",
  },
  {
    field: nameOf("Sterilised"),
    title: "Sterilised",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("AmountOS"),
    title: "Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("StatusDueStatus"),
    title: "Status",
  },
  {
    field: nameOf("EffectiveTo"),
    title: "Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DueStatus"),
    title: "Due Status",
  },
];

export const generateColAnimalsRenewals = (
  defaultColumnFields: IColumnFields[],
  settings: SettingsMap
) => {
  if (!settings) return defaultColumnFields;
  const isAnimalsShowAnimalType = getBoolValueSetting(
    settings[ECorporateSettingsField.Animals_DisplayAnimalType]
  );
  const isAnimalsDisplayCustomerBalance = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance]
  );

  let newColumnFields: IColumnFields[] = cloneDeep(defaultColumnFields);
  return newColumnFields.filter((col) => {
    switch (col.field) {
      case nameOf("Type"):
        return isAnimalsShowAnimalType;
      case nameOf("AmountOS"):
        return !isAnimalsDisplayCustomerBalance;
      case nameOf("CustomerBalance"):
        return isAnimalsDisplayCustomerBalance;
      default:
        return true;
    }
  });
};
