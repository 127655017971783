import { searchResultDataMock } from "@app/products/property/advanced_search/[id]/mock";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import React from "react";

const renderProductReferenceRow = (filters: any) => {
  return filters?.map((filter: any) => {
    if (!filter.logic) {
      return <ProductReferenceRow title={filter.field} value={filter.value} />;
    }
    return renderProductReferenceRow(filter.filters);
  });
};
export const SearchDetailTab = () => {
  const { Filter } = searchResultDataMock;

  const renderPanelBarItem = () => {
    return Object.keys(Filter).map((item) => {
      return (
        <PanelBarItem title={<ProductReferenceHeading title={item} />}>
          <ProductReferenceBlock>
            {Filter[item]?.filters ? (
              renderProductReferenceRow(Filter[item].filters)
            ) : (
              <></>
            )}
          </ProductReferenceBlock>
        </PanelBarItem>
      );
    });
  };

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"multiple"}>
        {renderPanelBarItem()}
        {/* <PanelBarItem
          title={<ProductReferenceHeading title={"Assessment Numbers"} />}
        >
          <ProductReferenceBlock>
            <ProductReferenceRow title={"Assessment Number:"} value="avb" />
            <ProductReferenceRow title={"Assessment Reference:"} value="abc" />
          </ProductReferenceBlock>
        </PanelBarItem> */}
      </PanelBar>
    </ProductReferenceBody>
  );
};
