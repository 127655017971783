import { getChangeOfOwnershipLovs } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/api";
import { DTO_ChangeOfOwnership_LOVs } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { eComponent } from "@app/products/property/components/associations/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  DTO_PIC_LOVs,
  DTO_Workflow_PICCreate,
  RequestPICCreateObj,
} from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postNewPIC = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_PICCreate
): Promise<APIResponse<ResponseMessageWorkflow<DTO_Workflow_PICCreate>>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/int/workflow/pic/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataNewPIC =
  | [
      APIResponse<DTO_PIC_LOVs>,
      APIResponse<DTO_Workflow_PICCreate>,
      APIResponse<DTO_ChangeOfOwnership_LOVs>
    ]
  | APIResponse<
      DTO_PIC_LOVs | DTO_Workflow_PICCreate | DTO_ChangeOfOwnership_LOVs
    >;

export const getInitialDataNewPIC = async (
  workflowType: WorkflowTypes,
  workflowDraftId: number | undefined,
  assessmentIds: number[] = [],
  titleIds: number[] = []
): Promise<IGetInitialDataNewPIC> => {
  try {
    return await Promise.all([
      getLovPIC(workflowType),
      !isNil(workflowDraftId)
        ? getWorkflowNewPIC(workflowDraftId)
        : getNewWorkflowNewPIC({
            AssessmentIds: assessmentIds,
            TitleIds: titleIds,
          }),
      getChangeOfOwnershipLovs(eComponent.PIC),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowNewPIC = async (
  object: RequestPICCreateObj
): Promise<APIResponse<DTO_Workflow_PICCreate>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/workflow/pic/new`,
      object
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getLovPIC = async (
  workflowType: WorkflowTypes
): Promise<APIResponse<DTO_PIC_LOVs>> => {
  try {
    return CoreAPIService.getClient().get<DTO_PIC_LOVs>(
      `api/property/int/pic/lovs?workflowType=${workflowType}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowNewPIC = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_PICCreate>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/int/workflow/pic/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
