import { DATE_FORMAT } from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";

export const TYPES_FORM_STEP = "Types";

export const TypesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
export const FormStepElement = ({
  formRenderProps,
  nameOf,
}: IFormStepElement) => {
  return (
    <>
      <section className="cc-field-group">
        <label className="cc-label">Change description</label>
        <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Reference</label>
              <Field
                name={nameOf("Reference")}
                component={CCInput}
                placeholder="Reference"
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Effective date</label>
              <Field
                name={nameOf("EffectiveDate")}
                component={CCDatePicker}
                defaultValue={new Date()}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Comment</label>
              <Field
                name={nameOf("Comment")}
                rows={4}
                component={CCTextArea}
                placeholder="Comment"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
