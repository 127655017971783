import { ContactManager_Contact_TabDetail } from "@app/core/contacts/_id/components/reference-sidebar/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getContactDetail = async (
  contactId: number
): Promise<APIResponse<ContactManager_Contact_TabDetail | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<
      ContactManager_Contact_TabDetail | undefined
    >(`/api/core/internal/contact/${contactId}/tabdetails`);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
