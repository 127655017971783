import { mockMeterReadingRouteDetailsTab } from "@app/products/property/meters/reading-routes/[id]/components/reference-sidebar/detail/mock";
import { IReferenceSideBarDetails } from "@app/products/property/meters/reading-routes/[id]/components/reference-sidebar/detail/model";
import { sleep } from "@common/utils/common";

export const loadMeterReadingRoutesDetailTabById = async (
  id: string
): Promise<IReferenceSideBarDetails> => {
  await sleep(1000);
  return mockMeterReadingRouteDetailsTab;
};
