import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import React from "react";

export interface IPropertyPicContactsTabProps {
  id?: number | null;
}

export const PropertyPicContactsTab = ({
  id,
}: IPropertyPicContactsTabProps) => {
  return <PropertyContactTab id={id} componentNumber={eComponent.PIC} />;
};
