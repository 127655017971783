import { mockFirePreventionDetailsTab } from "@app/products/property/fire-prevention/hazld-case/[id]/components/reference-sidebar/detail/mock";
import { sleep } from "@common/utils/common";

export const getFirePreventionDetailsTabById = async (id: string) => {
  await sleep(1000);
  return {
    ...mockFirePreventionDetailsTab,
    CaseId: id,
  };
};
