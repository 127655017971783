import { eventEmitter } from "@/App";
import { COMMENT_ACCORDION_GRID_ID } from "@app/core/comments/constant";
import { CCJournalEventType } from "@app/core/journal/util";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import {
  isDisabledApproveButton,
  isHiddenRejectButton,
} from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/util";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WhichJournalFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/_index";
import { JournalMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/model";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import { secondaryWorkflowUtilProcessing } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { PropertyDocumentEventType } from "@app/products/property/components/child-screen/documents/constant";
import { DTO_PIC } from "@app/products/property/components/dialogs/add-pic-lookup/model";
import { useJournalTransactionStore } from "@app/products/property/journals/[id]/components/child-screens/items/store";
import { DTO_Journal_Add_Transaction_LOVs } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/model";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import { NewJournalFormStep } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/_index";
import { useNewJournalStepStore } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/store";
import { JournalStatus } from "@app/products/property/journals/model";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  getInitialDataPICNewTransaction,
  postProcessNewPICTransaction,
} from "@app/products/property/pic/list/components/action-bar/form-steps/new-transaction/api";
import { PICNewTransactionJournalFormStep } from "@app/products/property/pic/list/components/action-bar/form-steps/new-transaction/components/form-elements/pic/_index";
import { usePICNewTransactionJournalStepStore } from "@app/products/property/pic/list/components/action-bar/form-steps/new-transaction/components/form-elements/pic/store";
import {
  DTO_Workflow_JournalPICAddTransaction,
  EKeysOfStepsPICNewTransaction,
  ELabelPICNewTransaction,
  TransactionAddFrom,
  keysOfStepsPICNewTransaction,
} from "@app/products/property/pic/list/components/action-bar/form-steps/new-transaction/model";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface INewTransactionPICDialog {
  onClose: () => void;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  listPICId?: number[];
  journalNumber?: number;
}
export const NewTransactionPICDialog = observer(
  ({
    onClose,
    isSaveOnNextStep = false,
    suffixTitle,
    prefixTitle,
    dataFromActionList,
    listPICId,
    journalNumber,
  }: INewTransactionPICDialog) => {
    //#region <Store>
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setNewJournalStepLOVs } = useNewJournalStepStore();
    const { setNewPICJournalStepLOVs } = usePICNewTransactionJournalStepStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { currentFormTitle } = useCommonProductStore();
    const { reloadJournalItemsData } = useJournalTransactionStore();
    //#endregion

    //region <State>
    const history = useHistory();
    const localNotificationRef = useRef<ICCLocalNotificationHandle | null>(
      null
    );
    const [workflowInitData, setWorkflowInitData] =
      useState<DTO_Workflow_JournalPICAddTransaction>();
    const [isLoadingProcess, setIsLoadingProcess] = useState<
      WorkflowProcessMode | undefined
    >();
    const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);
    const isFirstSave = useMemo(() => {
      const draftId =
        workflowInitData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id;
      return isNil(draftId) || draftId === 0;
    }, [workflowInitData]);

    const {
      isFromActionList,
      isIncompleteMode,
      isReadOnly,
      isShowCancelWorkflowButton,
      isToBeApprovalMode,
      statusBadge,
    } = usePropertyWorkflow(dataFromActionList);
    //#endregion

    //#region <Workflow name>
    const workflowName = useMemo(() => {
      const formId =
        workflowInitData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id;
      return currentFormTitle(formId ?? 0) ?? "New Transaction";
    }, [
      currentFormTitle,
      workflowInitData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id,
    ]);
    //#endregion

    //#region <Title header>
    const titleHeader = useMemo(() => {
      return getTitleWorkflow(workflowName, prefixTitle, suffixTitle);
    }, [prefixTitle, suffixTitle, workflowName]);
    //#endregion

    //#region <Initial value>
    const initialValue = useMemo((): any => {
      //step Journal
      const journalNumber = workflowInitData?.WorkflowDetail?.JournalNumber;
      let initWhichJournal: any = {
        JournalMode: workflowInitData?.WorkflowDetail?.JournalMode ?? 0,
        JournalSelected: journalNumber
          ? [{ Journal_Number: journalNumber }]
          : [],
      };
      //step New journal
      let initNewJournal: any = {
        ...workflowInitData?.WorkflowDetail?.CreateJournal,
      };
      // step PIC
      let initPICTransaction: any = {
        ...workflowInitData?.WorkflowDetail?.PIC,
      };
      const isOpenFromJournal =
        workflowInitData?.WorkflowDetail?.NavigatedFrom ===
        TransactionAddFrom.JournalManage;
      if (isOpenFromJournal) {
        formStepRef?.current?.setStepsVisible([
          {
            key: EKeysOfStepsPICNewTransaction.WhichJournal,
            visible: false,
          },
          {
            key: EKeysOfStepsPICNewTransaction.NewJournal,
            visible: false,
          },
        ]);
      } else {
        formStepRef?.current?.setStepsVisible([
          {
            key: EKeysOfStepsPICNewTransaction.WhichJournal,
            visible: true,
          },
          {
            key: EKeysOfStepsPICNewTransaction.NewJournal,
            visible:
              workflowInitData?.WorkflowDetail.JournalMode ===
              JournalMode.CreateNew,
          },
        ]);
      }

      //---step Secondary Workflow---
      const initialWorkflow =
        secondaryWorkflowUtilProcessing.processDataInit(workflowInitData);

      return {
        [EKeysOfStepsPICNewTransaction.WhichJournal]: initWhichJournal,
        [EKeysOfStepsPICNewTransaction.NewJournal]: initNewJournal,
        [EKeysOfStepsPICNewTransaction.PIC]: initPICTransaction,
        [EKeysOfStepsPICNewTransaction.Documents]: {},
        [EKeysOfStepsPICNewTransaction.Comments]: {},
        [EKeysOfStepsPICNewTransaction.Workflow]: initialWorkflow,
      };
    }, [workflowInitData]);
    //#endregion

    //#region <Steps>
    const steps: IStep[] = [
      {
        label: ELabelPICNewTransaction.WhichJournal,
        component: WhichJournalFormStep,
        visible: true,
        key: EKeysOfStepsPICNewTransaction.WhichJournal,
        options: {
          //hidden showBringUpField for now
          showBringUpField: false,
          isReadOnly,
          journalStatus: JournalStatus.Incomplete,
          newJournalKey: EKeysOfStepsPICNewTransaction.NewJournal,
          journalTypeNumber:
            workflowInitData?.WorkflowDetail?.CreateJournal?.Journal_Type,
        },
        initialValues: initialValue[EKeysOfStepsPICNewTransaction.WhichJournal],
      },
      {
        label: ELabelPICNewTransaction.NewJournal,
        component: NewJournalFormStep,
        visible: true,
        key: EKeysOfStepsPICNewTransaction.NewJournal,
        options: {
          showStatusField: false,
          isReadOnly,
          isDisableJournalType: true,
        },
        initialValues: initialValue[EKeysOfStepsPICNewTransaction.NewJournal],
      },
      {
        label: ELabelPICNewTransaction.PIC,
        component: PICNewTransactionJournalFormStep,
        visible: true,
        key: EKeysOfStepsPICNewTransaction.PIC,
        options: {
          isReadOnly,
        },
        initialValues: initialValue[EKeysOfStepsPICNewTransaction.PIC],
      },
      {
        label: ELabelPICNewTransaction.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsPICNewTransaction.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId:
            workflowInitData?.WorkflowHeader?.WorkflowDraft
              ?.Workflow_Draft_Id ?? 0,
          recordType: RECORDTYPE.CommunityProperty_PIC,
        },
        initialValues: initialValue[EKeysOfStepsPICNewTransaction.Comments],
      },
      {
        label: ELabelPICNewTransaction.Documents,
        component: DocumentsFormStep,
        visible: true,
        key: EKeysOfStepsPICNewTransaction.Documents,
        options: {
          isReadOnly,
          workflowDraftId:
            workflowInitData?.WorkflowHeader?.WorkflowDraft
              ?.Workflow_Draft_Id ?? 0,
          workflowType: WorkflowTypes.Journal_PIC_AddTransaction,
        },
        initialValues: initialValue[EKeysOfStepsPICNewTransaction.Documents],
      },
      {
        label: ELabelPICNewTransaction.Workflow,
        initialValues: initialValue[EKeysOfStepsPICNewTransaction.Workflow],
        component: WorkflowStepFormStep,
        visible: true,
        key: EKeysOfStepsPICNewTransaction.Workflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];
    //endregion

    //#region <handleSubmit>
    const handleSubmit = async (data: any, buttonId?: string) => {
      //reset notification before go next calling
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.resetNotifications();
      switch (buttonId) {
        case EListSubmitButton.Approve:
          handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.SaveWorkflow:
          await handleSaveAndNext(processData(data));
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinish(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleParkProcess(
            processData(data),
            EListSubmitButton.ConfirmCloseYes
          );
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };
    //#endregion

    //#region <Handle Approve process>
    /**
     * @param payload
     * @param mode
     */
    const handleApproveProcess = async (
      payload: DTO_Workflow_JournalPICAddTransaction
    ) => {
      //set loading button and dialog
      setIsLoadingProcess(WorkflowProcessMode.Approve);

      //props send to process workflow
      const approveProps: INewProcessWorkflow<DTO_Workflow_JournalPICAddTransaction> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.Notification ??
                e?.SuccessMessage ??
                `New transaction was approved successfully.`,
              type: "success",
            });
            isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
          },
          setLoading: () => {
            setIsLoadingProcess(undefined);
          },
          defaultFailedMessage: "New transaction could not be approved.",
          modeProcess: WorkflowProcessMode.Approve,
        };
      //calling api process workflow
      await handleProcessWorkflow(approveProps);
    };
    //#endregion

    /**
     * Handle reject process
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessNewPICTransaction,
          dataReject: data,
          defaultSuccessMessage: `New transaction was rejected successfully.`,
          defaultErrorMessage: `New transaction could not be rejected.`,
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_JournalPICAddTransaction
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessNewPICTransaction,
        dataSendBack: payload,
        defaultSuccessMessage: `New transaction was sent back successfully.`,
        defaultErrorMessage: `New transaction could not be sent back.`,
      });
    };

    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_JournalPICAddTransaction
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessNewPICTransaction,
        dataReallocate: payload,
        defaultSuccessMessage: `New transaction was reallocated successfully.`,
        defaultErrorMessage: `New transaction could not be reallocated.`,
      });
    };

    //#region <Process data>
    const processData = (data: any) => {
      let workflowDetail: any = {};
      const workflowHeader =
        workflowInitData?.WorkflowHeader as DTO_WorkflowHeader;
      const newData = { ...data };
      const sendSteps = pickBy(newData, (value, key) => {
        if (
          keysOfStepsPICNewTransaction.includes(
            key as EKeysOfStepsPICNewTransaction
          )
        ) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        const dataStep = { ...value };
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        workflowDetail[key] = dataStep;
        if (key === EKeysOfStepsPICNewTransaction.Workflow) {
          secondaryWorkflowUtilProcessing.processData(
            value,
            workflowHeader,
            workflowDetail
          );
        }
      }
      //step Journal
      if (
        workflowInitData?.WorkflowDetail?.NavigatedFrom ===
        TransactionAddFrom.JournalManage
      ) {
        //Assign 1 for mode existed journal
        workflowDetail.JournalMode = 1;
      } else {
        workflowDetail.JournalMode =
          data?.[EKeysOfStepsPICNewTransaction.WhichJournal]?.JournalMode;
      }
      const journalNumberData =
        data?.[EKeysOfStepsPICNewTransaction.WhichJournal]?.JournalSelected?.[0]
          ?.Journal_Number;
      workflowDetail.JournalNumber = journalNumberData ?? 0;
      workflowDetail[EKeysOfStepsPICNewTransaction.NewJournal].JournalNumber =
        journalNumberData ?? 0;
      //process data PIC
      const listPICId = data?.[EKeysOfStepsPICNewTransaction.PIC]?.PICs?.map(
        (pic: DTO_PIC) => pic?.PIC_Id
      );
      workflowDetail.PIC.PIC_Ids = listPICId;
      const newWorkflowDetail = {
        ...workflowInitData?.WorkflowDetail,
        ...workflowDetail,
      };
      return {
        WorkflowHeader: workflowInitData?.WorkflowHeader,
        WorkflowDetail: newWorkflowDetail,
      } as DTO_Workflow_JournalPICAddTransaction;
    };
    //#endregion

    //#region <Handle process workflow>
    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: INewProcessWorkflow<DTO_Workflow_JournalPICAddTransaction>
    ) => {
      const {
        actionSuccess,
        defaultFailedMessage,
        setLoading,
        modeProcess,
        payload,
      } = props;
      const response = await postProcessNewPICTransaction(modeProcess, payload);
      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          if (props?.actionFail) props?.actionFail(response);
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        if (props?.actionFail) props?.actionFail(response);
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      if (props?.actionClose) props.actionClose();
    };
    //#endregion

    //#region <Handle park process>
    /**
     * @param payload
     * @param mode
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_JournalPICAddTransaction,
      mode?: EListSubmitButton
    ) => {
      //set loading button and dialog
      setIsLoadingProcess(WorkflowProcessMode.Park);
      mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
      const actionCloseRetainDialog = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog();
      };
      //props send to process workflow
      const parkProps: INewProcessWorkflow<DTO_Workflow_JournalPICAddTransaction> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.Notification ??
                e?.SuccessMessage ??
                `New transaction was parked successfully.`,
              type: "success",
            });
            isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
          },
          setLoading: () => {
            setIsLoadingProcess(undefined);
          },
          actionClose: () => {
            mode === EListSubmitButton.ConfirmCloseYes &&
              actionCloseRetainDialog();
          },
          defaultFailedMessage: `New transaction could not be parked.`,
          modeProcess: WorkflowProcessMode.Park,
        };

      //calling api process workflow
      await handleProcessWorkflow(parkProps);
    };
    //#endregion

    //#region <Handle cancel button>
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessNewPICTransaction,
          dataCancel: data,
          defaultSuccessMessage: `New transaction was cancelled successfully.`,
          defaultErrorMessage: `New transaction could not be cancelled.`,
        });
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <Handle next button>
    /**
     * @param data
     * @param step
     * @param keyStep
     * @returns
     */
    const handleNextButton = async (
      data: DTO_Workflow_JournalPICAddTransaction
    ) => {
      const newData = { ...data };
      const processPayload = processData(newData);
      //send data to call api save
      return handleSaveAndNext(processPayload);
    };
    //#endregion

    //#region <Handle save and next>
    const handleSaveAndNext = async (
      payload: DTO_Workflow_JournalPICAddTransaction
    ): Promise<boolean> => {
      //check condition use for Save button
      setIsLoadingProcess(WorkflowProcessMode.Save);
      //Calling process Save at next button
      const response = await postProcessNewPICTransaction(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingProcess(undefined);
      //set default notification
      const defaultFailedMessage = "New transaction could not be saved.";
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        setWorkflowInitData(response?.data?.ReturnData);
        return true;
      } else {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      return false;
    };
    //#endregion

    //#region <Handle finish process>
    /**
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_JournalPICAddTransaction
    ) => {
      //set loading button
      setIsLoadingProcess(WorkflowProcessMode.Finish);
      //props send to process workflow
      const finishProps: INewProcessWorkflow<DTO_Workflow_JournalPICAddTransaction> =
        {
          payload: payload,
          actionSuccess: async (e) => {
            const notificationContent: IAppNotificationItemAddProps = {
              title:
                e?.Notification ??
                e?.SuccessMessage ??
                "New transaction was created successfully.",
              type: "success",
            };
            onClose();

            const isJournalManagePage =
              history.location.pathname ===
              `${PROPERTY_JOURNALS_ROUTE}/${journalNumber}`;
            if (!isJournalManagePage && !isNil(e?.Component_ID)) {
              history.push(`${PROPERTY_JOURNALS_ROUTE}/${e.Component_ID}`, {
                notification: notificationContent,
              });
            } else {
              if (isJournalManagePage) {
                // Refresh History
                eventEmitter.emit(CCJournalEventType.RefreshData);
                // Refresh Items grid
                reloadJournalItemsData(journalNumber);
                // Refresh Document grid
                eventEmitter.emit(PropertyDocumentEventType.RefreshData);
                // Refresh Comment grid
                eventEmitter.emit(CCGridEventType.RefreshOData, {
                  gridIds: [COMMENT_ACCORDION_GRID_ID],
                });
              }
              pushNotification(notificationContent);
            }
          },
          setLoading: () => {
            setIsLoadingProcess(undefined);
          },
          defaultFailedMessage: "New transaction could not be created.",
          modeProcess: WorkflowProcessMode.Finish,
        };
      //calling api process workflow
      await handleProcessWorkflow(finishProps);
    };
    //#endregion

    //#region <Handle confirm finish>
    const handleConfirmFinish = (
      payload: DTO_Workflow_JournalPICAddTransaction
    ) => {
      const dataProcessed = processData(payload);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage:
          "The PIC transactions will be created based on the information provided. Are you sure you want to submit?",
      });
    };
    //#endregion

    //#region <Handle close dialog>
    /**
     * @param renderProps
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <Get workflow data>
    /**
     * load initValue for FormStep
     * call once time
     */
    const getWorkflowData = async () => {
      const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
      formStepRef?.current?.setLoadingFormStep(true);
      const response = await getInitialDataPICNewTransaction(
        workflowDraftId,
        journalNumber,
        listPICId
      );
      formStepRef?.current?.setLoadingFormStep(false);
      if (Array.isArray(response)) {
        const [lovsJournal, workflowData] = response;
        if (
          isSuccessResponse(lovsJournal) &&
          isSuccessResponse(workflowData) &&
          lovsJournal?.data &&
          workflowData?.data
        ) {
          setNewJournalStepLOVs({
            JournalTypes: convertValueLOVToNumber(
              lovsJournal?.data?.JournalTypes ?? [],
              "Code"
            ),
          });
          setNewPICJournalStepLOVs({
            PICTransactionTypes: convertValueLOVToNumber(
              lovsJournal?.data?.PICTransactionTypes ?? [],
              "Code"
            ),
          });
          setWorkflowInitData(workflowData?.data);
        } else {
          let responseError: APIResponse<
            | DTO_Journal_Add_Transaction_LOVs
            | DTO_Workflow_JournalPICAddTransaction
            | ResponsePacket
          > = response[0];
          if (!isSuccessResponse(response[1])) {
            responseError = response[1];
          }
          formStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        formStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    };
    //#endregion

    //#region <Use Effect calling initial data>
    useEffectOnce(() => {
      getWorkflowData();
    });
    //#endregion
    return (
      <CCFormStep
        ref={formStepRef}
        onSubmit={handleSubmit}
        listButtonId={listSubmitButton}
        initialSteps={steps}
        initialValues={initialValue}
        localNotificationRef={localNotificationRef?.current ?? null}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            titleHeader={titleHeader}
            onClose={() => handleCloseDialog(renderProps)}
            badge={statusBadge}
            bodyElement={renderProps.children}
            disabled={isLoadingProcess !== undefined}
            footerElement={
              <>
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Park
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled ||
                        isLoadingProcess === WorkflowProcessMode.Park
                      }
                    >
                      Park
                    </Button>
                  )}
                  {/* Cancel button */}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={!isNil(isLoadingProcess)}
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}
                  {isToBeApprovalMode &&
                    workflowInitData?.WorkflowHeader?.OfficerCanApprove && (
                      <>
                        {/* Send Back button */}
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.SendBack}
                          disabled={
                            isLoadingProcess === WorkflowProcessMode.SendBack ||
                            renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingProcess === WorkflowProcessMode.SendBack
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                          onClick={renderProps.submitButton.onClick}
                        >
                          Send Back
                        </Button>
                        {/* Reallocate button */}
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Reallocate}
                          disabled={
                            isLoadingProcess ===
                              WorkflowProcessMode.Reallocate ||
                            renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingProcess === WorkflowProcessMode.Reallocate
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                          onClick={renderProps.submitButton.onClick}
                        >
                          Reallocate
                        </Button>
                        {/* Approve button */}
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Approve}
                          disabled={
                            isLoadingProcess === WorkflowProcessMode.Approve ||
                            renderProps.nextButton.disabled ||
                            isDisabledApproveButton(
                              workflowInitData?.WorkflowHeader
                            )
                          }
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                          iconClass={
                            isLoadingProcess === WorkflowProcessMode.Approve
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                        >
                          Approve
                        </Button>
                        {/* Reject button */}
                        {isHiddenRejectButton(
                          workflowInitData?.WorkflowHeader
                        ) ? null : (
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reject}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reject
                          </Button>
                        )}
                      </>
                    )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {(isToBeApprovalMode || isReadOnly) &&
                  renderProps.isLastStep ? null : (
                    <Button
                      themeColor="primary"
                      id={
                        !renderProps.isLastStep &&
                        (isToBeApprovalMode || isReadOnly)
                          ? "cc-next-step-button"
                          : renderProps.nextButton.idButton
                      }
                      disabled={
                        isLoadingProcess === WorkflowProcessMode.Save ||
                        renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Save
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingProcess === WorkflowProcessMode.Save
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              </>
            }
          />
        )}
      />
    );
  }
);
