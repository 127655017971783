import { useComplianceStore } from "@app/products/property/assessments/compliance/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import ExistedPropertyCompliance from "./components/forms/existed/_index";
import { NewPropertyCompliance } from "./components/forms/new/_index";

const ManagePropertyCompliance = observer(() => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const complianceId = params.id;
  const { resetMenu } = useCCSubActionBarStore();
  const { loadCompliance, resetStore, isLoading } = useComplianceStore();

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });

  useEffect(() => {
    if (isNew) return;
    loadCompliance(parseInt(complianceId));
  }, [complianceId, isNew, loadCompliance]);

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  if (isNew) {
    return <NewPropertyCompliance />;
  }
  return <ExistedPropertyCompliance />;
});

export default ManagePropertyCompliance;
