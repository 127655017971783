import {
  ServiceStandardBatchUpdate,
  ServiceStandardBatchUpdateHandlerRequest,
  ServiceStandardBatchUpdateHandlerResponse,
  ServiceStandardBatchUpdateLovs,
} from "@app/products/crms/service-standards/batch-update/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";

export const serviceStandardBatchUpdateNew = async (): Promise<
  APIResponse<IIdentityPacket<ServiceStandardBatchUpdate> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<ServiceStandardBatchUpdate>
    >("api/crms/internal/svc-stda-batch-update/new");
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const serviceStandardBatchUpdateLovs = async (): Promise<
  APIResponse<ServiceStandardBatchUpdateLovs | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<ServiceStandardBatchUpdateLovs>(
      "api/crms/internal/svc-stda-batch-update/lovs"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const svcStandardBatchUpdateHandler = async (
  params: ServiceStandardBatchUpdateHandlerRequest
): Promise<
  APIResponse<
    IIdentityPacket<ServiceStandardBatchUpdateHandlerResponse> | undefined
  >
> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<ServiceStandardBatchUpdateHandlerResponse>
    >(`api/crms/internal/svc-stda-batch-update/form-event-handler`, params);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSvcStandardbatchUpdate = async (
  serviceStandard: ServiceStandardBatchUpdate
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "api/crms/internal/svc-stda-batch-update",
      serviceStandard
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
