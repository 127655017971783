import { useNoticeRunAssessmentStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/assessment-selection-criteria/store";
import { useNoticeRunGroupStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/store";
import { EInstalmentPlanOption } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/instalments/config";
import {
  listTypeHideFinancialGr,
  listTypeHideMandatory,
  listTypeHideTransaction,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-run-attributes/config";
import { ILoadNoticeRunLOVs } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-run-attributes/model";
import { useNoticeRunAttributeStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-run-attributes/store";
import { useNoticeRunNoticeTypeStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-type/store";
import { getLovNoticeRun } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/api";
import {
  DTO_NoticeRun_LOVs,
  EKeysOfStepsNewNoticeRun,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/model";
import { useNoticeRunStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/store";
import { isShowWithNoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/utils";
import { nameOfLov } from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isFieldVisible,
} from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isNil, isUndefined } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useEffectOnce } from "react-use";

export const NoticeRunAttributesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    localNotificationRef,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    //Get values
    const chargeRunId = getFieldValue("Charge_Run_Id");
    const financialGroupId = getFieldValue("Financial_Group_Id");

    //store
    const { dataSelectedNoticeType } = useNoticeRunNoticeTypeStepStore();
    const { noticeRunOriginLOVs } = useNoticeRunStore();
    const { attributeLOVs, setAttributeLOVs } =
      useNoticeRunAttributeStepStore();
    const { assessmentLOVs, setAssessmentLOVs } =
      useNoticeRunAssessmentStepStore();
    const { groupLOVs, setGroupLOVs } = useNoticeRunGroupStepStore();

    //Lovs data
    const chargeRunLov = useMemo(() => {
      return attributeLOVs?.ChargeRun ?? [];
    }, [attributeLOVs?.ChargeRun]);

    const ratingPeriodLov = useMemo(() => {
      return attributeLOVs?.RatingPeriods ?? [];
    }, [attributeLOVs?.RatingPeriods]);

    const financialGroupLov = useMemo(() => {
      return attributeLOVs?.FinancialGroups ?? [];
    }, [attributeLOVs?.FinancialGroups]);

    const isShowChargeRun = useMemo(() => {
      return isFieldVisible(dataSelectedNoticeType?.ChargeRunVisibility);
    }, [dataSelectedNoticeType?.ChargeRunVisibility]);

    const handleChargeRunChange = (
      noticeRunLovs: DTO_NoticeRun_LOVs | undefined
    ) => {
      setGroupLOVs({
        ...groupLOVs,
        AssessmentGroups: convertValueLOVToNumber(
          noticeRunLovs?.AssessmentGroups ?? [],
          "Code"
        ),
      });

      let financialGroup =
        noticeRunLovs?.FinancialGroups?.length === 1
          ? +noticeRunLovs?.FinancialGroups?.[0].Code
          : undefined;
      onChange(nameOf("Financial_Group_Id"), {
        value: financialGroup,
      });
      onChange(nameOf("Rating_Period_Id"), {
        value: noticeRunLovs?.RatingPeriodId,
      });
    };

    const handleFinancialGroupChange = (
      noticeRunLovs: DTO_NoticeRun_LOVs | undefined
    ) => {
      setAssessmentLOVs({
        ...assessmentLOVs,
        InstalmentPlans: convertValueLOVToNumber(
          noticeRunLovs?.InstalmentPlans ?? [],
          "Code"
        ),
      });
    };

    const isMandatoryField = useMemo(() => {
      return !isShowWithNoticeType(listTypeHideMandatory, valueGetter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadLOVsWithParams = async ({
      params,
      loadingFields = [],
      handleDataChange,
    }: ILoadNoticeRunLOVs) => {
      if (isUndefined(params)) {
        handleDataChange(noticeRunOriginLOVs);
        return;
      }

      options?.setIsDisabledDialog(true);
      loadingFields.forEach((field: string) =>
        onChange(field, { value: true })
      );
      const response = await getLovNoticeRun(params);
      options?.setIsDisabledDialog(false);
      loadingFields.forEach((field: string) =>
        onChange(field, { value: false })
      );
      if (isSuccessResponse(response) && response?.data) {
        const lovData = response.data;
        setAttributeLOVs({
          ChargeRun: convertValueLOVToNumber(lovData.ChargeRun ?? [], "Code"),
          FinancialGroups: convertValueLOVToNumber(
            lovData.FinancialGroups ?? [],
            "Code"
          ),
          RatingPeriods: convertValueLOVToNumber(
            lovData.RatingPeriods ?? [],
            "Code"
          ),
          InstalmentPlans: convertValueLOVToNumber(
            lovData.InstalmentPlans ?? [],
            "Code"
          ),
        });
        handleDataChange(lovData);

        const instalmentPlanId = lovData?.InstalmentPlanId;
        if (!isNil(instalmentPlanId)) {
          // Set default 'Selection by instalment plan' to 'Select instalment plans'
          onChange(
            `${EKeysOfStepsNewNoticeRun.Instalment}._option.SelectionByInstalmentPlan`,
            {
              value: EInstalmentPlanOption.SelectInstalment,
            }
          );

          // Set flag to disable 'Instalment plan' and 'Selection by instalment plan' fields
          onChange(
            `${EKeysOfStepsNewNoticeRun.Instalment}._option.IsDefaultInstalmentPlanUsed`,
            {
              value: true,
            }
          );

          // Set default instalment plan
          onChange(`${EKeysOfStepsNewNoticeRun.Instalment}.InstalmentPlans`, {
            value: [instalmentPlanId],
          });

          // Set Assessment.Instalment_Plan_Id to pass to the API
          onChange(
            `${EKeysOfStepsNewNoticeRun.Assessment}.Instalment_Plan_Id`,
            {
              value: instalmentPlanId,
            }
          );
        }
      } else {
        localNotificationRef?.current?.pushNotification({
          title: "Load LOVs failed.",
          type: "error",
          autoClose: false,
        });
      }
    };

    useEffectOnce(() => {
      let params = undefined;
      if (!isNil(chargeRunId) || !isNil(financialGroupId)) {
        params = {
          chargeRunId,
          financialGroupId,
        };
      }
      loadLOVsWithParams({
        params,
        loadingFields: [
          nameOf("_option.Loading_ChargeRun"),
          nameOf("_option.Loading_FinancialGroup"),
        ],
        handleDataChange: (data?: DTO_NoticeRun_LOVs) => {
          isShowChargeRun && handleChargeRunChange(data);
          handleFinancialGroupChange(data);
        },
      });
    });

    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Issue date</label>
              <Field
                name={nameOf("IssueDate")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
                disabled={options?.isReadOnly}
              />
            </div>
            {!isShowWithNoticeType(listTypeHideTransaction, valueGetter) && (
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">
                    Set transaction date to issue date
                  </label>
                  <Field
                    name={nameOf("SetTransactionDateToIssueDate")}
                    checked={getFieldValue("SetTransactionDateToIssueDate")}
                    component={CCSwitch}
                    disabled={options?.isReadOnly}
                  />
                </div>
              </div>
            )}
            {isShowChargeRun ? (
              <div className="cc-field">
                <label className="cc-label">
                  Charge run
                  <CCTooltip type="validator" position="right" />
                  {getFieldValue("_option.Loading_ChargeRun") ? (
                    <span>
                      <i className="fas fa-spinner fa-spin" />
                    </span>
                  ) : null}
                </label>
                <Field
                  name={nameOf("Charge_Run_Id")}
                  component={CCSearchComboBox}
                  data={chargeRunLov ?? []}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  onChange={(event: ComboBoxChangeEvent) => {
                    const value = event?.value?.Code;
                    onChange(nameOf("Charge_Run_Id"), { value: value });
                    loadLOVsWithParams({
                      params:
                        isNil(value) && isNil(financialGroupId)
                          ? undefined
                          : {
                              chargeRunId: value,
                              financialGroupId,
                            },
                      loadingFields: [nameOf("_option.Loading_ChargeRun")],
                      handleDataChange: (data?: DTO_NoticeRun_LOVs) => {
                        handleChargeRunChange(data);
                      },
                    });
                  }}
                  disabled={options?.isReadOnly}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                />
              </div>
            ) : null}
            {!isShowWithNoticeType(listTypeHideFinancialGr, valueGetter) && (
              <div className="cc-field">
                <label className="cc-label">
                  Financial group
                  <CCTooltip type="validator" position="right" />
                  {getFieldValue("_option.Loading_FinancialGroup") ? (
                    <span>
                      <i className="fas fa-spinner fa-spin" />
                    </span>
                  ) : null}
                </label>
                <Field
                  name={nameOf("Financial_Group_Id")}
                  component={CCSearchComboBox}
                  data={financialGroupLov ?? []}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  disabled={options?.isReadOnly}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                  onChange={(event: ComboBoxChangeEvent) => {
                    const value = event?.value?.Code;
                    onChange(nameOf("Financial_Group_Id"), {
                      value: value,
                    });
                    loadLOVsWithParams({
                      params:
                        isNil(chargeRunId) && isNil(value)
                          ? undefined
                          : {
                              chargeRunId,
                              financialGroupId: value,
                            },
                      loadingFields: [nameOf("_option.Loading_FinancialGroup")],
                      handleDataChange: (data?: DTO_NoticeRun_LOVs) => {
                        handleFinancialGroupChange(data);
                      },
                    });
                  }}
                />
              </div>
            )}
            <div className="cc-field">
              <CCLabel title="Rating period" isMandatory={isMandatoryField} />
              <Field
                name={nameOf("Rating_Period_Id")}
                component={CCSearchComboBox}
                data={ratingPeriodLov ?? []}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                isUseDefaultOnchange
                disabled={options?.isReadOnly || isShowChargeRun}
                validator={
                  !options?.isReadOnly && isMandatoryField
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Name
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                name={nameOf("Name")}
                component={CCInput}
                placeholder="Name"
                readOnly={options?.isReadOnly}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
