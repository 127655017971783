import {
  apiCoreGetFinanceInvoice,
  apiCoreSaveFinanceInvoice,
} from "@app/core/components/common/utils";
import {
  Invoice,
  ParentParams,
  Svc_FinanceInvoice,
} from "@app/core/invoice/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";

export const postInvoiceById = async (
  invoiceId: number,
  parentParams: ParentParams | {} = {}
): Promise<APIResponse<IIdentityPacket<Svc_FinanceInvoice> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<Svc_FinanceInvoice>
    >(apiCoreGetFinanceInvoice(invoiceId), parentParams);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSaveInvoice = async (
  data: Invoice
): Promise<APIResponse<IdentityPacket>> => {
  try {
    const response = await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreSaveFinanceInvoice(),
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
