import { mockAccountResponsible } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-meter/components/form-elements/transferring/mock";
import { sleep } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { SearchField } from "./model";

export const loadSearchAssessment = async (filter: FilterDescriptor) => {
  const assessmentList = mockAccountResponsible.map((assessment: any) => {
    return {
      ...assessment,
      [SearchField.AssessmentNumber]:
        assessment.AssessmentNumber + " - " + assessment.FormattedAddress,
      [SearchField.AssessmentID]:
        assessment.AssessmentID + " - " + assessment.FormattedAddress,
      AssessmentIDTransferred: assessment.AssessmentID,
    };
  });

  const data = assessmentList.slice();
  await sleep(1000);
  return filterBy(data, filter);
};
