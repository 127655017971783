import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const appealedValidatorList = [
  Application_Status.PermitIssued,
  Application_Status.AmendmentIssued,
  Application_Status.NoticeOfDecision,
  Application_Status.PermitRefused,
  Application_Status.AmendmentRefused,
  Application_Status.ApplicationComplete,
  Application_Status.Appealed,
];
export const AppealedButton = observer(() => {
  const { ppr, onSubmit } = usePPRStore();

  const isVisible = useMemo(() => {
    return (
      appealedValidatorList.some((item) => item === ppr?.Status_ENUM) ||
      ppr?.Status_ENUM === Application_Status.Appealed
    );
  }, [ppr]);

  return isVisible ? (
    <CCNavButton
      title="Appealed"
      onClick={onSubmit}
      name={PPRSubmitActions.Appealed}
    />
  ) : null;
});
