import { AnimalRenderSwitch } from "@app/products/animals/system-admin/settings/_id/components/switch/_index";
import { switchOptionsValueNumberString } from "@app/products/animals/system-admin/settings/_id/main/config";
import { ICommonFormProps } from "@app/products/animals/system-admin/settings/_id/main/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { SettingDynamicGridFields } from "@components/setting/setting-dynamic-grid-fields/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { isEditing } = useAnimalsSettingManageStore();

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <AnimalRenderSwitch
          switchList={[
            {
              eSettingsField:
                ECorporateSettingsField.Animals_Mandatory_OwnerMobile,
            },
          ]}
          formRenderProps={formRenderProps}
          optionsValue={switchOptionsValueNumberString}
        />
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <SettingDynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
