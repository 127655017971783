import { ProductType } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { ChecklistAnswerType } from "@common/constants/enumerations";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";

export interface DynamicQuestionResponse extends DTOBaseClass_Standard {
  DynamicQuestionResponse_ID: number;
  RecordSource_ID: number;
  ProductType_ENUM: ProductType;
  ProductType_Name: string;
  Question: string;
  NumberOfAnswersField: number | null;
  AnswerField: string;
  ChecklistAnswerType_ENUM: ChecklistAnswerType;
  ChecklistTypeAnswerType_Name: string;
  AnswerResponse: string;
  SortIndex: number;
  ChecklistQuestion_ID: number | null;
  NumberOfComments: number | null;
  CommentLabels: string;
  Comments: string;
  QuestionListName: string;
}

export interface Svc_DialogDynamicQuestionSaveRequest {
  Responses: DynamicQuestionResponse[];
  IsFromAction: boolean;
}

export interface Svc_DialogDynamicQuestionGetRequest {
  RecordSourceID: number;
  DynamicListID: string;
  IsFromAction: boolean;
}

export enum dynamicQuestionFieldName {
  TextBox = "_textbox_",
  Label = "_label_",
  DatePicker = "_date_",
  RadioButton = "_radio_",
  CheckBox = "_checkbox_",
}
