import { odataCoreGetConditionReferences } from "@app/core/components/common/utils";
import {
  conditionReferenceColumns,
  conditionReferenceColumnsWithoutGroup,
  nameOfConditionReference,
  YieldNotificationPortal_CONDITION_REFERENCE_DIALOG,
} from "@app/core/condition/components/dialogs/list-condition-references/config";
import { ConditionReferenceGroupSetting } from "@app/core/condition/components/dialogs/list-condition-references/constant";
import { Svc_ConditionReference } from "@app/core/condition/components/dialogs/list-condition-references/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { GridSelectionDialog } from "@components/dialog/dialog-grid-selection/_index";
import { State } from "@progress/kendo-data-query";
import { Form } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const ListConditionReferenceDialog = observer(
  ({
    productType,
    isLoading = false,
    onSubmit,
    onClosePopup,
  }: IDialogProps) => {
    const { settings } = useCommonCoreStore();

    // Check setting to group the conditions data
    const showInGroup = useMemo(
      () =>
        getBoolValueSetting(
          settings[ConditionReferenceGroupSetting[productType]]
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [productType]
    );
    const initialGridState: State = useMemo(
      () => ({
        group: showInGroup
          ? [
              {
                field: nameOfConditionReference("Group"),
                dir: "asc",
              },
            ]
          : undefined,
      }),
      [showInGroup]
    );

    const handleSubmit = (
      conditions: Svc_ConditionReference[],
      notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
    ) => {
      if (typeof onSubmit === "function") {
        onSubmit(conditions, notificationRef);
      }
    };

    return (
      <Form
        render={() => (
          <GridSelectionDialog
            selectableMode="multiple"
            maxWidth="60%"
            height={720}
            titleHeader="Conditions"
            dataUrl={odataCoreGetConditionReferences(productType)}
            columnFields={
              showInGroup
                ? conditionReferenceColumns
                : conditionReferenceColumnsWithoutGroup
            }
            debouncedSearch
            hasSearchField
            groupDragMode="auto"
            state={initialGridState}
            filterCol={nameOfConditionReference("Title")}
            isLoading={isLoading}
            onCloseDialog={onClosePopup}
            setShowDialog={onClosePopup}
            onSubmit={handleSubmit}
            {...{
              yieldNotificationPortal:
                YieldNotificationPortal_CONDITION_REFERENCE_DIALOG,
            }}
          />
        )}
      />
    );
  }
);

interface IDialogProps {
  productType: keyof typeof ConditionReferenceGroupSetting;
  isLoading?: boolean;
  onSubmit: (
    conditions: Svc_ConditionReference[],
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => Promise<void> | void;
  onClosePopup: () => void;
}
