import { getViewConfigurations } from "@app/products/property/api";
import { AddParcelLookupDialog } from "@app/products/property/components/dialogs/add-parcel-lookup/_index";
import { DTO_Parcel } from "@app/products/property/components/dialogs/add-parcel-lookup/model";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processCombineData } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { colParcels } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/form-elements/parcel-details/config";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOfParcel = nameOfFactory<DTO_Parcel>();
export const ParcelDetailFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      setIsLoadingDialog: () => {},
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    // Variable for Parcel
    const parcels = getFieldValue("") ?? [];
    const parcelSelected = getFieldValue("_option.ParcelSelected") ?? [];
    const [processedColumns, setProcessedColumns] =
      useState<IColumnFields[]>(colParcels);
    const isLoading = getFieldValue("_option.Loading") ?? false;

    // State
    const [isShowDialog, setIsShowDialog] = useState<boolean>();
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);

    // Handle logic for parcel grid
    /**
     * handle add item for (parcel)
     * @param data
     */
    const handleAddItem = async (data: DTO_Parcel[]) => {
      const previousParcel = parcels?.length ? [...parcels] : [];
      const newParcels = processCombineData(
        previousParcel,
        data,
        nameOfParcel("Parcel_Id")
      );
      onChange(nameOf(""), {
        value: newParcels,
      });
    };

    const loadViewConfiguration = async () => {
      options?.setIsLoadingDialog(true);
      onChange(nameOf("_option.Loading"), { value: true });
      getViewConfigurations(ViewConfiguration.ParcelSearch).then((response) => {
        if (isSuccessResponse(response)) {
          const viewConfig: ICCViewColumn[] | undefined =
            response?.data?.ColumnDefinitions?.Columns;
          if (!viewConfig || viewConfig?.length === 0) {
            setProcessedColumns([]);
            options?.setIsLoadingDialog(false);
            onChange(nameOf("_option.Loading"), { value: false });
            return;
          }
          setProcessedColumns(processDynamicColumns(colParcels, viewConfig));
        } else {
          setResponseLoadError({
            status: response.status,
            error: response.error ?? "Load failed",
          });
        }
        options?.setIsLoadingDialog(false);
        onChange(nameOf("_option.Loading"), { value: false });
      });
    };

    /**
     * handle delete item
     */
    const handleDeleteItem = () => {
      const parcelId = parcelSelected?.[0]?.Parcel_Id;
      const newParcel = parcels.filter(
        (item: DTO_Parcel) => parcelId !== item.Parcel_Id
      );
      onChange(nameOf(""), {
        value: newParcel,
      });
      onChange(nameOf("_option.ParcelSelected"), {
        value: [],
      });
    };

    useEffectOnce(() => {
      (async () => {
        await loadViewConfiguration();
      })();
    });

    return (
      <section className="cc-field-group">
        <CCLabel title="Parcels" />
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : responseLoadError ? (
          <CCLoadFailed
            responseError={responseLoadError}
            onReload={() => {
              loadViewConfiguration();
            }}
          />
        ) : (
          <CCGrid
            toolbar={
              !options?.isReadOnly ? (
                <div className="cc-grid-tools-bar">
                  <Button
                    type="button"
                    iconClass="fas fa-plus"
                    title="Add"
                    onClick={() => {
                      setIsShowDialog(true);
                    }}
                  />
                  <Button
                    type="button"
                    iconClass="fas fa-minus"
                    title="Remove"
                    onClick={() => {
                      handleDeleteItem();
                    }}
                    disabled={parcelSelected?.length !== 1}
                  />
                </div>
              ) : null
            }
            selectedRows={parcelSelected ?? []}
            readOnly={options?.isReadOnly}
            primaryField={nameOfParcel("Parcel_Id")}
            columnFields={processedColumns ?? []}
            data={parcels ?? []}
            selectableMode={!options?.isReadOnly ? "single" : undefined}
            onSelectionChange={(dataItem: any[]) => {
              onChange(nameOf("_option.ParcelSelected"), {
                value: dataItem ?? [],
              });
            }}
          />
        )}
        {isShowDialog && (
          <AddParcelLookupDialog
            onClose={() => setIsShowDialog(false)}
            handleAddParcel={async (data: DTO_Parcel[]) => {
              await handleAddItem(data);
              setIsShowDialog(false);
            }}
          />
        )}
      </section>
    );
  }
);
