import { PPRApplicationIdentityPacket } from "@app/products/town-planning/ppr/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const putSendReptAndRespLetterForApprovalValidate = async (
  id: number
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<IdentityPacket>(
      `/api/tp/ppr/internal/application/${id}/validate`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putSendReptAndRespLetterForApproval = async (
  id: number,
  comments: string = ""
): Promise<APIResponse<PPRApplicationIdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
      `/api/tp/ppr/internal/application/${id}/requireapproval`,
      JSON.stringify(comments)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
