import { CONTEXT_PINNER } from "./config";
import { ISubItemPin } from "./model";

export const getUrlKey = (pathname: string) => {
  let paths = pathname.split("/");
  paths.pop();
  return paths.reduce(
    (pathname: string, path: string) => pathname + path + "/",
    ""
  );
};
export const getPinSubAction = () => {
  return JSON.parse(
    localStorage.getItem(CONTEXT_PINNER.STORAGE_SUB_MENU_PIN) || "[]"
  );
};

export const getMenuNameByUrlKey = (urlKey: string) => {
  const pins = getPinSubAction();
  const findPin = pins.find((pin: ISubItemPin) => pin.urlKey === urlKey);
  return findPin ? findPin.menuName : undefined;
};

export const setPinSubAction = (urlKey: string, menuName: string) => {
  let newPins = getPinSubAction();
  const oldPinIndex = newPins.findIndex(
    (pin: ISubItemPin) => pin.urlKey === urlKey
  );
  if (!menuName) return;
  if (oldPinIndex !== -1) {
    newPins[oldPinIndex] = { urlKey, menuName };
  } else {
    newPins.push({ urlKey, menuName });
  }

  localStorage.setItem(
    CONTEXT_PINNER.STORAGE_SUB_MENU_PIN,
    JSON.stringify(newPins)
  );
};

export const removePinSubAction = (urlKey: string) => {
  let newPins = getPinSubAction();
  newPins = newPins.filter((pin: ISubItemPin) => pin.urlKey !== urlKey);
  localStorage.setItem(
    CONTEXT_PINNER.STORAGE_SUB_MENU_PIN,
    JSON.stringify(newPins)
  );
};
