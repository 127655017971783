import { history } from "@/AppRoutes";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { AnimalActionBarNavDropdown } from "@app/products/animals/components/action-bar/nav-dropdown/_index";
import { animalRoute } from "@app/products/animals/route";
import { AnimalTypeDetailsTab } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/reference-sidebar/details/_index";
import { AnimalTypeHistoryTab } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/reference-sidebar/history/_index";
import { ANIMAL_TYPE_ROUTE } from "@app/products/animals/system-admin/animal-types/[id]/constant";
import { ANIMAL_TYPES_ODATA } from "@app/products/animals/system-admin/animal-types/constant";
import { Svc_Animals_TypeView } from "@app/products/animals/system-admin/animal-types/model";
import { AnimalSystemAdminAnimalTypesBookmark } from "@app/products/animals/system-admin/animal-types/util";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { systemAdminRoute } from "../route";
import { colAnimalsSystemAdminAnimalTypes } from "./config";

const nameOf = nameOfFactory<Svc_Animals_TypeView>();

export default () => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Animals_Form_AnimalType,
    productType: PRODUCT_TYPE_NUMBER.Animals,
  });
  useCCListViewActionBar({
    title: animalRoute.name,
    leftComponents: [
      <AnimalActionBarNavDropdown category={systemAdminRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title="New"
        onClick={() => {
          history.push(`${ANIMAL_TYPE_ROUTE}/new`);
        }}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
      />,
      <DeleteButton
        recordType={RECORDTYPE.Animals_AnimalType}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={ANIMALS_ROUTE}
        productType={PRODUCT_TYPE.Animals}
        recordType={RECORDTYPE.Animals_AnimalType}
        detail={
          AnimalSystemAdminAnimalTypesBookmark.getBookmarkListViewDisplayName
        }
        displayName={
          AnimalSystemAdminAnimalTypesBookmark.getBookmarkListViewDetailsRecord
        }
        listViewDetail={
          AnimalSystemAdminAnimalTypesBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          AnimalSystemAdminAnimalTypesBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <AnimalTypeDetailsTab /> },
      {
        title: "History",
        component: <AnimalTypeHistoryTab />,
      },
    ],
  });
  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={colAnimalsSystemAdminAnimalTypes}
        dataUrl={ANIMAL_TYPES_ODATA}
        primaryField={nameOf("ID")}
      />
    </LoadingPermissionWrapper>
  );
};
