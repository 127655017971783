import { IAssignContractor } from "@app/products/property/fire-prevention/components/dialogs/assign/model";

export const mockAssignContractor: IAssignContractor[] = [
  {
    Contractor: "Ben Godfrey",
    PreferredWorkRegion: "Hobart",
    ContractorId: 100,
  },
  {
    Contractor: "Mark Steven",
    ContractorId: 102,
  },
  {
    Contractor: "Nick Paine",
    PreferredWorkRegion: "Hobart",
    ContractorId: 101,
  },
];
