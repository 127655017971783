import { CERTIFICATE_E_CERTIFICATE_REQUEST_ROUTE } from "@app/products/property/certificates/e-certificate-requests/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
export const colECertificateRequests: IColumnFields[] = [
  {
    field: "CertificateNumber",
    title: "Certificate Number",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) =>
      `${CERTIFICATE_E_CERTIFICATE_REQUEST_ROUTE}/${dataItem.ID}`,
  },
  {
    field: "ApplicationReference",
    title: "Application Reference",
  },
  {
    field: "Source",
    title: "Source",
  },
  {
    field: "MessageType",
    title: "Message Type",
  },
  {
    field: "CertificateRequested",
    title: "Certificate Requested",
  },
  {
    field: "DateRequestRegistered",
    title: "Date Request Registered",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "OrderStatus",
    title: "Order Status",
  },
  {
    field: "ExceptionDescription",
    title: "Exception Description",
  },
  {
    field: "StreetName",
    title: "Street Name",
  },
  {
    field: "Suburb",
    title: "Suburb",
  },
  {
    field: "Postcode",
    title: "Postcode",
  },
  {
    field: "ApplicantName",
    title: "Applicant Name",
  },
  {
    field: "ApplicantAddress",
    title: "Applicant Address",
  },
  {
    field: "ID",
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
