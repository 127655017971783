import { CorporateAuthForm } from "@common/pages/settings/security/corporate-authorisations/_id/components/child-screens/general/_index";
import { CorporateAuthSubmitActions } from "@common/pages/settings/security/corporate-authorisations/_id/model";
import { useCorporateAuthStore } from "@common/pages/settings/security/corporate-authorisations/_id/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const NewSecurityCorporateAuth = observer(() => {
  const { id } = useParams<{ id: string }>();

  const {
    isLoading,
    responseLoadError,
    loadCorporateAuth,
    onSubmit,
    corporateAuth,
  } = useCorporateAuthStore();

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Corporate Authorisation"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCorporateAuth(parseInt(id), true)}
        />
      ) : (
        <>
          <FormTitle title="New Corporate Authorisation" />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                name={CorporateAuthSubmitActions.New}
                onClick={onSubmit}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {corporateAuth && <CorporateAuthForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
