import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";
import { DTO_Assessment_Interest } from "./model";

export interface ILoadPropertyAssessmentAllInterestProps {
  assessmentId: number;
  onlyShowWhenInterestApplies?: boolean;
  fromInterestDate: string | null;
  toDueDate: string | null;
}

export const loadPropertyAssessmentAllInterest = async ({
  assessmentId,
  onlyShowWhenInterestApplies,
  fromInterestDate,
  toDueDate,
}: ILoadPropertyAssessmentAllInterestProps) => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Assessment_Interest>(
        `/api/property/internal/assessment/${assessmentId}/interests`,
        {
          params: {
            onlyShowWhenInterestApplies,
            fromInterestDate,
            toDueDate,
          },
        }
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
