import { ServiceStandardUpdateTriggers } from "@app/products/crms/[id]/model";
import { colGroupsOrgUser } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/special-interest/config";
import {
  ServiceStandard,
  ServiceStandardHandlerRequest,
  ServiceStandardMapObj,
  Svc_FormAction,
} from "@app/products/crms/service-standards/[id]/model";
import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { validatorManualEntriesEmail } from "@app/products/crms/util";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import { PickUserGroupOrgButton } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/buttons/pick-user-group-org/_index";
import { IDataUserGroupOrgForm } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/model";
import { IAccessControl } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/security/forms/security-settings/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const nameOf = nameOfFactory<ServiceStandard>();
const nameOfServiceStandardMapObj = nameOfFactory<ServiceStandardMapObj>();

export interface ISpecialInterestProps {
  formRenderProps: FormRenderProps;
}

export const SpecialInterest = observer(
  ({ formRenderProps }: ISpecialInterestProps) => {
    const { valueGetter, onChange } = formRenderProps;

    const {
      serviceStandardLovs,
      serviceStandardChangeHandler,
      setIsExpandedSpecialInterestAccordion,
      accessRight,
    } = useCRMSServiceStandardStore();

    const [listSelected, setListSelected] = useState<IAccessControl[]>([]);
    const [isShowConfirmDeleteDialog, setIsShowConfirmDeleteDialog] =
      useState<boolean>(false);

    const serviceStandardFormObj = valueGetter(
      nameOfServiceStandardMapObj("ServiceStandard")
    );

    const specialInterest = valueGetter(
      `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
        "SpecialInterest"
      )}`
    );

    const isDisabled = accessRight === ACCESSRIGHTS.CanRead;

    const handleAddNew = (value: IDataUserGroupOrgForm) => {
      const groups = value?.Groups ?? [];
      const orgStructure = value?.OrgStructure ?? [];
      const users = value?.Users ?? [];
      const data = [...groups, ...orgStructure];

      let hierarchiesIds = data.map((item) => item.ID);
      const userIds = users.map((item) => item.ID);

      if (hierarchiesIds.length === 0 && userIds.length === 0) return;

      const params: ServiceStandardHandlerRequest = {
        ServiceStandardFormAction: Svc_FormAction.PickSpecialInterest,
        ServiceStandard: serviceStandardFormObj,
        EventArgs: {
          SiteUsers: userIds ?? [],
          Hierarchies: hierarchiesIds ?? [],
        },
        IsFirstTimeLoad: false,
      };

      serviceStandardChangeHandler(
        params,
        "Select users, groups and org structures was failed"
      );

      setIsExpandedSpecialInterestAccordion(true);
    };

    const handleDeleteUser = () => {
      const ids = listSelected.map((item: IAccessControl) => item.EntityID);
      if (ids.length > 0) {
        const newList = specialInterest?.filter(
          (item: IAccessControl) => !ids.includes(item.EntityID)
        );

        onChange(
          `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
            "SpecialInterest"
          )}`,
          { value: newList }
        );

        const saveTriggers =
          valueGetter(
            `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "_SaveTriggers"
            )}`
          ) ?? [];

        if (
          !saveTriggers.includes(
            ServiceStandardUpdateTriggers.UpdateAccessControl
          )
        ) {
          saveTriggers.push(ServiceStandardUpdateTriggers.UpdateAccessControl);
          onChange(
            `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "_SaveTriggers"
            )}`,
            { value: saveTriggers }
          );
        }
        setListSelected([]);
        setIsShowConfirmDeleteDialog(false);
      }
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-col-span-3">
            <div className="cc-field">
              <label className="cc-label">Groups & users</label>
              <CCGrid
                columnFields={colGroupsOrgUser}
                primaryField={"EntityID"}
                data={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "SpecialInterest"
                  )}`
                )}
                selectableMode={"multiple"}
                selectedRows={listSelected}
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <PickUserGroupOrgButton
                      onSubmit={handleAddNew}
                      maxHeight="85%"
                    />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      title="Remove"
                      onClick={() => setIsShowConfirmDeleteDialog(true)}
                      disabled={listSelected.length === 0}
                    />
                  </div>
                }
                onSelectionChange={(dataItem: IAccessControl[]) => {
                  setListSelected(dataItem ?? []);
                }}
                itemPerPage={50}
                isAutoHiddenPager={false}
                readOnly={isDisabled}
              />
            </div>
          </div>
        </div>

        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Manual entries" />
            <Field
              disabled={isDisabled}
              name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "SpecialInterest_Email_ManualEntries"
              )}`}
              placeholder="Manual entries"
              disable={true}
              rows={6}
              component={CCTextArea}
              validator={validatorManualEntriesEmail}
            />
          </div>
        </div>

        <div className="cc-form-cols-3">
          <CCLabel title="Internal requestors" />
        </div>
        <div className="cc-field-group cc-custom-sub-panel-bar">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Email org manager" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("Flag_SpecialInterest_Email_RequestorOrgManager")}`}
                checked={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "Flag_SpecialInterest_Email_RequestorOrgManager"
                  )}`
                )}
                component={CCSwitch}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Org level" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf(
                  "SpecialInterest_Email_RequestorOrgManagerLevel_ENUM"
                )}`}
                textField="Value"
                dataItemKey="Key"
                data={
                  serviceStandardLovs?.SpecialInterest_Email_OrgManager_OrgLevel ??
                  []
                }
                component={CCSearchComboBox}
                value={getDropdownValue(
                  valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "SpecialInterest_Email_RequestorOrgManagerLevel_ENUM"
                    )}`
                  ),
                  serviceStandardLovs?.SpecialInterest_Email_OrgManager_OrgLevel ??
                    [],
                  "Key"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "SpecialInterest_Email_RequestorOrgManagerLevel_ENUM"
                    )}`,
                    {
                      value: event.target.value?.Key ?? null,
                    }
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="cc-form-cols-3">
          <CCLabel title="Send email when:" />
        </div>
        <div className="cc-field-group cc-custom-sub-panel-bar">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Created" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("SpecialInterest_Email_WhenCreated")}`}
                checked={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "SpecialInterest_Email_WhenCreated"
                  )}`
                )}
                component={CCSwitch}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Recategorised" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("SpecialInterest_Email_WhenRecategorised")}`}
                checked={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "SpecialInterest_Email_WhenRecategorised"
                  )}`
                )}
                component={CCSwitch}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Rescheduled" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("SpecialInterest_Email_WhenRescheduled")}`}
                checked={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "SpecialInterest_Email_WhenRescheduled"
                  )}`
                )}
                component={CCSwitch}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Overdue" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("SpecialInterest_Email_WhenOverdue")}`}
                checked={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "SpecialInterest_Email_WhenOverdue"
                  )}`
                )}
                component={CCSwitch}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Closed" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("SpecialInterest_Email_WhenClosed")}`}
                checked={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "SpecialInterest_Email_WhenClosed"
                  )}`
                )}
                component={CCSwitch}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Sent for action" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("SpecialInterest_Email_WhenSentForAction")}`}
                checked={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "SpecialInterest_Email_WhenSentForAction"
                  )}`
                )}
                component={CCSwitch}
              />
            </div>
          </div>
        </div>
        {isShowConfirmDeleteDialog && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={`Are you sure you want to delete ${
              listSelected.length === 1 ? "this item" : "these items"
            }?`}
            onClosePopup={() => {
              setIsShowConfirmDeleteDialog(false);
            }}
            onConfirmYes={handleDeleteUser}
          />
        )}
      </section>
    );
  }
);
