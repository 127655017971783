import { IReferenceSideBarDetails } from "@app/products/property/meters/[id]/components/reference-sidebar/detail/model";
import { mockMeterFormGeneral } from "@app/products/property/meters/[id]/mock";
import { sleep } from "@common/utils/common";

export const loadMeterDetailTabById = async (
  id: string
): Promise<IReferenceSideBarDetails> => {
  const dataResponse = mockMeterFormGeneral;
  await sleep(1000);
  if (+id === 9999)
    return { ...dataResponse, Status: "Not in service", MeterId: +id };
  return { ...dataResponse, MeterId: +id };
};
