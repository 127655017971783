import {
  Permit,
  Svc_Permit_Workflow_DateFromDateTo,
} from "@app/products/local-laws/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IIdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const postIssuePermit = async (
  lodgePermitRequest: Svc_Permit_Workflow_DateFromDateTo,
  cancelToken: CancelToken
): Promise<APIResponse<IIdentityPacket<Permit> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/locallaw/internal/permit-register/issue`,
      lodgePermitRequest,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
