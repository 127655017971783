export const mockNoticeRun = [
  {
    NoticeRunId: 138,
    NoticeRunName: "Paton Rd Nov 2017",
    IssueDate: new Date("28-NOV-2018"),
    DueDate: new Date("12-Feb-2019"),
    NumberofAccounts: 1,
    InterestRaised: 0.0,
    AmountDue: 357.29,
    PrincipalBalance: 357.29,
    PrincipalDue: 7.29,
    InterestBalance: 0.0,
    OtherBalances: 0.0,
    BalanceOutstanding: 357.29,
    PayoutAmount: 357.29,
    NoticeRunDate: new Date("28-NOV-2018 13:32"),
    CreatedBy: "Clelands",
    NoticePrintedorExportedDate: new Date("28-NOV-2018 13:42"),
  },
  {
    NoticeRunId: 137,
    NoticeRunName: "July 2017",
    IssueDate: new Date("28-Jun-2018"),
    DueDate: new Date("12-Sep-2018"),
    NumberofAccounts: 138,
    InterestRaised: 17732.97,
    PrincipalBecomingDue: 55942.45,
    AmountDue: 1081216.19,
    PrincipalBalance: 1019659.47,
    PrincipalDue: 1019659.47,
    InterestBalance: 61556.72,
    OtherBalances: 0.0,
    BalanceOutstanding: 1081216.19,
    PayoutAmount: 1081216.19,
    NoticeRunDate: new Date("28-Jun-2018 11:06"),
    CreatedBy: "HodgesR",
    NoticePrintedorExportedDate: new Date("28-Jun-2018 14:54"),
  },
  {
    NoticeRunId: 135,
    NoticeRunName: "October 2016",
    IssueDate: new Date("7-NOV-2017"),
    DueDate: new Date("22-Jan-2018"),
    NumberofAccounts: 149,
    InterestRaised: 0.0,
    AmountDue: 1196169.9,
    PrincipalBalance: 1094831.81,
    PrincipalDue: 1151381.83,
    InterestBalance: 44788.07,
    OtherBalances: 0.0,
    BalanceOutstanding: 1139619.88,
    PayoutAmount: 1139619.88,
    NoticeRunDate: new Date("7-NOV-2017 10:01"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 134,
    NoticeRunName: "April 2016",
    IssueDate: new Date("7-NOV-2017"),
    DueDate: new Date("22-Jan-2018"),
    NumberofAccounts: 149,
    InterestRaised: -2786.92,
    AmountDue: 1196169.9,
    PrincipalBalance: 1094831.81,
    PrincipalDue: 1151381.83,
    InterestBalance: 44788.07,
    OtherBalances: 0.0,
    BalanceOutstanding: 1139619.88,
    PayoutAmount: 1139619.88,
    NoticeRunDate: new Date("7-NOV-2017 9:57"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 133,
    NoticeRunName: "April 2016",
    IssueDate: new Date("29-Jul-2017"),
    DueDate: new Date("13-Oct-2017"),
    NumberofAccounts: 167,
    InterestRaised: 15861.64,
    PrincipalBecomingDue: 104532.8,
    AmountDue: 1439524.6,
    PrincipalBalance: 1329702.82,
    PrincipalDue: 1386252.84,
    InterestBalance: 53271.76,
    OtherBalances: 0.0,
    BalanceOutstanding: 1382974.58,
    PayoutAmount: 1382974.58,
    NoticeRunDate: new Date("29-Jul-2017 8:50"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 130,
    NoticeRunName: "April 2016",
    IssueDate: new Date("9-May-2017"),
    DueDate: new Date("24-Jul-2017"),
    NumberofAccounts: 1,
    InterestRaised: 0.0,
    AmountDue: 8041.56,
    PrincipalBalance: 8041.56,
    PrincipalDue: 8041.56,
    InterestBalance: 0.0,
    OtherBalances: 0.0,
    BalanceOutstanding: 8041.56,
    PayoutAmount: 8041.56,
    NoticeRunDate: new Date("9-May-2017 10:14"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 129,
    NoticeRunName: "Feb 2016",
    IssueDate: new Date("3-May-2017"),
    DueDate: new Date("18-Jul-2017"),
    NumberofAccounts: 53,
    InterestRaised: 0.0,
    PrincipalBecomingDue: 133000.0,
    AmountDue: 366996.08,
    PrincipalBalance: 366996.08,
    PrincipalDue: 366996.08,
    InterestBalance: 0.0,
    OtherBalances: 0.0,
    BalanceOutstanding: 366996.08,
    PayoutAmount: 366996.08,
    NoticeRunDate: new Date("3-May-2017 16:53"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 128,
    NoticeRunName: "Feb 2016",
    IssueDate: new Date("15-Mar-2017"),
    DueDate: new Date("30-May-2017"),
    NumberofAccounts: 28,
    InterestRaised: 0.0,
    PrincipalBecomingDue: 239304.83,
    AmountDue: 239304.83,
    PrincipalBalance: 239304.83,
    PrincipalDue: 239304.83,
    InterestBalance: 0.0,
    OtherBalances: 0.0,
    BalanceOutstanding: 239304.83,
    PayoutAmount: 239304.83,
    NoticeRunDate: new Date("15-Mar-2017 11:41"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 126,
    NoticeRunName: "Feb 2016",
    IssueDate: new Date("9-Mar-2017"),
    DueDate: new Date("24-Apr-2017"),
    NumberofAccounts: 15,
    InterestRaised: 0.0,
    AmountDue: 240091.12,
    PrincipalBalance: 240091.12,
    PrincipalDue: 240091.12,
    InterestBalance: 0.0,
    OtherBalances: 0.0,
    BalanceOutstanding: 240091.12,
    PayoutAmount: 240091.12,
    NoticeRunDate: new Date("9-Mar-2017 14:36"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 125,
    NoticeRunName: "Feb 2016",
    IssueDate: new Date("9-Mar-2017"),
    DueDate: new Date("24-Apr-2017"),
    NumberofAccounts: 15,
    InterestRaised: 0.0,
    PrincipalBecomingDue: -26410.08,
    AmountDue: 240091.12,
    PrincipalBalance: 240091.12,
    PrincipalDue: 240091.12,
    InterestBalance: 0.0,
    OtherBalances: 0.0,
    BalanceOutstanding: 240091.12,
    PayoutAmount: 240091.12,
    NoticeRunDate: new Date("9-Mar-2017 14:34"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 124,
    NoticeRunName: "Feb 2016",
    IssueDate: new Date("4-Feb-2017"),
    DueDate: new Date("21-Mar-2017"),
    NumberofAccounts: 141,
    InterestRaised: 0.0,
    AmountDue: 1161429.9,
    PrincipalBalance: 1066549.78,
    PrincipalDue: 1123099.8,
    InterestBalance: 38330.1,
    OtherBalances: 0.0,
    BalanceOutstanding: 1104879.88,
    PayoutAmount: 1104879.88,
    NoticeRunDate: new Date("4-Feb-2017 15:37"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 123,
    NoticeRunName: "July 2015",
    IssueDate: new Date("3-Aug-2016"),
    DueDate: new Date("18-Sep-2016"),
    NumberofAccounts: 146,
    InterestRaised: 24446.46,
    PrincipalBecomingDue: 383510.0,
    AmountDue: 1202163.12,
    PrincipalBalance: 1105026.86,
    PrincipalDue: 1161576.88,
    InterestBalance: 40586.24,
    OtherBalances: 0.0,
    BalanceOutstanding: 1145613.1,
    PayoutAmount: 1145613.1,
    NoticeRunDate: new Date("3-Aug-2016 16:44"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 122,
    NoticeRunName: "July 2015",
    IssueDate: new Date("3-Aug-2016"),
    DueDate: new Date("18-Sep-2016"),
    NumberofAccounts: 146,
    InterestRaised: 0.0,
    AmountDue: 1202163.12,
    PrincipalBalance: 1105026.86,
    PrincipalDue: 1161576.88,
    InterestBalance: 40586.24,
    OtherBalances: 0.0,
    BalanceOutstanding: 1145613.1,
    PayoutAmount: 1145613.1,
    NoticeRunDate: new Date("3-Aug-2016 16:36"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 121,
    NoticeRunName: "January 2015",
    IssueDate: new Date("27-Apr-2016"),
    DueDate: new Date("12-Jun-2016"),
    NumberofAccounts: 137,
    InterestRaised: 0.0,
    AmountDue: 855097.75,
    PrincipalBalance: 781434.8,
    PrincipalDue: 837984.82,
    InterestBalance: 17112.93,
    OtherBalances: 0.0,
    BalanceOutstanding: 798547.73,
    PayoutAmount: 798547.73,
    NoticeRunDate: new Date("27-Apr-2016 8:53"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 120,
    NoticeRunName: "January 2015",
    IssueDate: new Date("31-Jan-2016"),
    DueDate: new Date("18-Mar-2016"),
    NumberofAccounts: 140,
    InterestRaised: 0.0,
    AmountDue: 876089.92,
    PrincipalBalance: 802426.97,
    PrincipalDue: 858976.99,
    InterestBalance: 17112.93,
    OtherBalances: 0.0,
    BalanceOutstanding: 819539.9,
    PayoutAmount: 819539.9,
    NoticeRunDate: new Date("31-Jan-2016 14:15"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 119,
    NoticeRunName: "January 2015",
    IssueDate: new Date("31-Jan-2016"),
    DueDate: new Date("18-Mar-2016"),
    NumberofAccounts: 140,
    InterestRaised: 0.0,
    AmountDue: 876089.92,
    PrincipalBalance: 802426.97,
    PrincipalDue: 858976.99,
    InterestBalance: 17112.93,
    OtherBalances: 0.0,
    BalanceOutstanding: 819539.9,
    PayoutAmount: 819539.9,
    NoticeRunDate: new Date("31-Jan-2016 13:49"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 118,
    NoticeRunName: "April Notices",
    IssueDate: new Date("10-NOV-2015"),
    DueDate: new Date("26-Dec-2015"),
    NumberofAccounts: 145,
    InterestRaised: 0.0,
    AmountDue: 903243.85,
    PrincipalBalance: 829379.44,
    PrincipalDue: 885929.46,
    InterestBalance: 17314.39,
    OtherBalances: 0.0,
    BalanceOutstanding: 846693.83,
    PayoutAmount: 846693.83,
    NoticeRunDate: new Date("10-Nov-2015 16:07"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 117,
    NoticeRunName: "April Notices",
    IssueDate: new Date("10-NOV-2015"),
    DueDate: new Date("26-Dec-2015"),
    NumberofAccounts: 37,
    InterestRaised: 0.0,
    AmountDue: 170756.34,
    PrincipalBalance: 169563.17,
    PrincipalDue: 19563.17,
    InterestBalance: 1193.17,
    OtherBalances: 0.0,
    BalanceOutstanding: 170756.34,
    PayoutAmount: 170756.34,
    NoticeRunDate: new Date("10-Nov-2015 16:06"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 116,
    NoticeRunName: "April Notices",
    IssueDate: new Date("21-Jul-2015"),
    DueDate: new Date("5-Sep-2015"),
    NumberofAccounts: 68,
    InterestRaised: 17721.62,
    AmountDue: 324440.22,
    PrincipalBalance: 305009.81,
    PrincipalDue: 305009.81,
    InterestBalance: 19430.41,
    OtherBalances: 0.0,
    BalanceOutstanding: 324440.22,
    PayoutAmount: 324440.22,
    NoticeRunDate: new Date("21-Jul-2015 9:31"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 115,
    NoticeRunName: "April Notices",
    IssueDate: new Date("21-Jul-2015"),
    DueDate: new Date("5-Sep-2015"),
    NumberofAccounts: 153,
    InterestRaised: 0.0,
    AmountDue: 942451.28,
    PrincipalBalance: 866470.85,
    PrincipalDue: 923020.87,
    InterestBalance: 19430.41,
    OtherBalances: 0.0,
    BalanceOutstanding: 885901.26,
    PayoutAmount: 885901.26,
    NoticeRunDate: new Date("21-Jul-2015 9:28"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 114,
    NoticeRunName: "April Notices",
    IssueDate: new Date("12-May-2015"),
    DueDate: new Date("27-Jun-2015"),
    NumberofAccounts: 164,
    InterestRaised: 0.0,
    PrincipalBecomingDue: -56550.02,
    AmountDue: 951752.5,
    PrincipalBalance: 893041.06,
    PrincipalDue: 949591.08,
    InterestBalance: 2161.42,
    OtherBalances: 0.0,
    BalanceOutstanding: 895202.48,
    PayoutAmount: 895202.48,
    NoticeRunDate: new Date("12-May-2015 8:34"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 113,
    NoticeRunName: "January Notices",
    IssueDate: new Date("28-Jan-2015"),
    DueDate: new Date("15-Mar-2015"),
    NumberofAccounts: 196,
    InterestRaised: 0.0,
    PrincipalBecomingDue: -32979.77,
    AmountDue: 1072036.01,
    PrincipalBalance: 1069874.59,
    PrincipalDue: 1069874.59,
    InterestBalance: 2161.42,
    OtherBalances: 0.0,
    BalanceOutstanding: 1072036.01,
    PayoutAmount: 1072036.01,
    NoticeRunDate: new Date("28-Jan-2015 15:52"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 112,
    NoticeRunName: "Majestic Drive",
    IssueDate: new Date("20-NOV-2014"),
    DueDate: new Date("31-Jan-2015"),
    NumberofAccounts: 3,
    InterestRaised: 0.0,
    AmountDue: 6803.21,
    PrincipalBalance: 6803.21,
    PrincipalDue: 6803.21,
    InterestBalance: 0.0,
    OtherBalances: 0.0,
    BalanceOutstanding: 6803.21,
    PayoutAmount: 6803.21,
    NoticeRunDate: new Date("20-NOV-2014 9:04"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 111,
    NoticeRunName: "Majestic Drive",
    IssueDate: new Date("20-NOV-2014"),
    DueDate: new Date("31-Jan-2015"),
    NumberofAccounts: 3,
    InterestRaised: 0.0,
    AmountDue: 6803.21,
    PrincipalBalance: 6803.21,
    PrincipalDue: 6803.21,
    InterestBalance: 0.0,
    OtherBalances: 0.0,
    BalanceOutstanding: 6803.21,
    PayoutAmount: 6803.21,
    NoticeRunDate: new Date("20-NOV-2014 8:53"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 110,
    NoticeRunName: "George and Mary St Special Charge Scheme",
    IssueDate: new Date("13-NOV-2014"),
    DueDate: new Date("4-Jan-2015"),
    NumberofAccounts: 13,
    InterestRaised: 0.0,
    AmountDue: 47148.98,
    PrincipalBalance: 44987.56,
    PrincipalDue: 44987.56,
    InterestBalance: 2161.42,
    OtherBalances: 0.0,
    BalanceOutstanding: 47148.98,
    PayoutAmount: 47148.98,
    NoticeRunDate: new Date("13-NOV-2014 9:00"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 109,
    NoticeRunName: "George and Mary St Special Charge Scheme",
    IssueDate: new Date("12-NOV-2014"),
    DueDate: new Date("3-Jan-2015"),
    NumberofAccounts: 30,
    InterestRaised: 0.0,
    PrincipalBecomingDue: -14688.01,
    AmountDue: 214311.99,
    PrincipalBalance: 214311.99,
    PrincipalDue: 214311.99,
    InterestBalance: 0.0,
    OtherBalances: 0.0,
    BalanceOutstanding: 214311.99,
    PayoutAmount: 214311.99,
    NoticeRunDate: new Date("12-NOV-2014 9:00"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 108,
    NoticeRunName: "Como St Berrys Rd Ferres Rd",
    IssueDate: new Date("12-NOV-2014"),
    DueDate: new Date("3-Jan-2015"),
    NumberofAccounts: 30,
    InterestRaised: 0.0,
    AmountDue: 214311.99,
    PrincipalBalance: 214311.99,
    PrincipalDue: 214311.99,
    InterestBalance: 0.0,
    OtherBalances: 0.0,
    BalanceOutstanding: 214311.99,
    PayoutAmount: 214311.99,
    NoticeRunDate: new Date("12-NOV-2014 8:39"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 107,
    NoticeRunName: "Como St Berrys Rd Ferres Rd",
    IssueDate: new Date("6-NOV-2014"),
    DueDate: new Date("28-Dec-2014"),
    NumberofAccounts: 207,
    InterestRaised: 0.0,
    AmountDue: 1198589.99,
    PrincipalBalance: 1196428.57,
    PrincipalDue: 1196428.57,
    InterestBalance: 2161.42,
    OtherBalances: 0.0,
    BalanceOutstanding: 1198589.99,
    PayoutAmount: 1198589.99,
    NoticeRunDate: new Date("6-NOV-2014 11:55"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 106,
    NoticeRunName: "Como St Berrys Rd Ferres Rd",
    IssueDate: new Date("5-Aug-2014"),
    DueDate: new Date("26-Sep-2014"),
    NumberofAccounts: 25,
    InterestRaised: 0.0,
    AmountDue: 185890.18,
    PrincipalBalance: 185890.18,
    PrincipalDue: 15890.18,
    InterestBalance: 0.0,
    OtherBalances: 0.0,
    BalanceOutstanding: 185890.18,
    PayoutAmount: 185890.18,
    NoticeRunDate: new Date("5-Aug-2014 15:17"),
    CreatedBy: "HodgesR",
  },
  {
    NoticeRunId: 105,
    IssueDate: new Date("23-Jul-2014"),
    DueDate: new Date("13-Sep-2014"),
    NumberofAccounts: 220,
    InterestRaised: 0.0,
    AmountDue: 1346906.8,
    PrincipalBalance: 1344220.73,
    PrincipalDue: 1344220.73,
    InterestBalance: 2686.07,
    OtherBalances: 0.0,
    BalanceOutstanding: 1346906.8,
    PayoutAmount: 1346906.8,
    NoticeRunDate: new Date("23-Jul-2014 12:36"),
    CreatedBy: "HodgesR",
  },
];
