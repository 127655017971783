import { mockDataBillingGroup } from "@app/products/property/meters/[id]/components/action-bar/dialogs/edit-meter-association/mock";
import {
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IEditMeterAssociationDialogProps {
  onClose: () => void;
  onSave?: (value: any) => void;
  initialForm?: any;
}

export const EditMeterAssociationDialog = observer(
  ({ onClose, onSave, initialForm }: IEditMeterAssociationDialogProps) => {
    return (
      <Form
        initialValues={initialForm}
        onSubmit={onSave}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onChange } = formRenderProps;
          return (
            <CCDialog
              titleHeader={"Edit Association with Property"}
              onClose={onClose}
              maxWidth="40%"
              height={"auto"}
              bodyElement={
                <div className="cc-edit-meter-association cc-form">
                  <FormElement
                    onSubmit={formRenderProps.onSubmit}
                    className="cc-field-group"
                  >
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">Meter ID</label>
                        <Field name="MeterId" readOnly component={CCInput} />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Meter number</label>
                        <Field
                          name="MeterNumber"
                          readOnly
                          component={CCInput}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Property address</label>
                        <Field
                          name="PropertyAddress"
                          readOnly
                          component={CCInput}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Account names</label>
                        <Field
                          name="AccountNames"
                          readOnly
                          component={CCInput}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">Billing group</label>
                        <Field
                          name="BillingGroup"
                          data={mockDataBillingGroup}
                          placeholder="Billing group"
                          textField="Value"
                          dataItemKey="Key"
                          component={CCSearchComboBox}
                          value={getDropdownValue(
                            valueGetter("BillingGroup"),
                            mockDataBillingGroup,
                            "Key"
                          )}
                          onChange={(event: ComboBoxChangeEvent) => {
                            onChange("BillingGroup", {
                              value: event.target.value?.Key ?? null,
                            });
                          }}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Usage entitlement</label>
                        <Field
                          name="UsageEntitlement"
                          component={CCNumericTextBox}
                          placeholder="Usage entitlement"
                          min={0}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Usage percentage</label>
                        <Field
                          name="UsagePercentage"
                          component={CCNumericTextBox}
                          placeholder="Usage percentage"
                          disabled
                          format={PERCENTAGE_FORMAT.PERCENTAGE}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Date attached</label>
                        <Field
                          name="DateAttached"
                          format={DATE_FORMAT.DATE_CONTROL}
                          component={CCDatePicker}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Date removed</label>
                        <Field
                          name="DateRemoved"
                          format={DATE_FORMAT.DATE_CONTROL}
                          component={CCDatePicker}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Reading on removal</label>
                        <Field
                          name="ReadingOnRemoval"
                          format={NUMBER_FORMAT.NUMBER2}
                          component={CCNumericTextBox}
                          placeholder="Reading on removal"
                          min={0}
                        />
                      </div>
                    </div>
                  </FormElement>
                </div>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    onClick={formRenderProps.onSubmit}
                    type={"submit"}
                    disabled={!formRenderProps.modified}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
