import { ChecklistQuestion } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/model";

import { useDynamicQuestionListsStore } from "@common/pages/settings/lookups/dynamic-lists/_id/store";
import { COMMENT_LABEL_TEMP_NAME } from "@common/pages/settings/lookups/dynamic-lists/constant";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import Loading from "@components/loading/Loading";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import { default as React, useMemo } from "react";

export const COMMENT_FORM_STEP = "CommentStep";
export const CommentStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const nameOf = nameOfFactory<ChecklistQuestion>();
const FormStepElement = observer(
  ({
    formRenderProps,
    options = {
      isDisabled: false,
    },
  }: IFormStepElement) => {
    const { valueGetter } = formRenderProps;
    const { isFirstLoadingCheckListQuestionDialog } =
      useDynamicQuestionListsStore();

    const numberOfComments = valueGetter(nameOf("NumberOfComments"));

    if (isFirstLoadingCheckListQuestionDialog) {
      return <Loading isLoading={isFirstLoadingCheckListQuestionDialog} />;
    }

    const listCommentLabels = useMemo(() => {
      const listCommentLabelItems = [];
      if (numberOfComments > 0) {
        for (let index = 0; index < numberOfComments; index++) {
          listCommentLabelItems.push(
            <div className="cc-field" key={`comment_labels_${index}`}>
              <CCLabel title={`${String(index + 1).padStart(2, "0")}.`} />
              <Field
                name={`${COMMENT_FORM_STEP}.${COMMENT_LABEL_TEMP_NAME}${index}`}
                component={CCInput}
                readOnly={options?.isDisabled}
              />
            </div>
          );
        }
      }
      return listCommentLabelItems;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberOfComments]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Number of comment" />
            <Field
              name={nameOf("NumberOfComments")}
              component={CCNumericTextBox}
              disabled={options?.isDisabled}
              min={0}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Comment labels" />
          </div>
        </div>
        <div className="cc-form-cols-2">{listCommentLabels}</div>
      </section>
    );
  }
);
