import { DTO_LOV } from "@common/models/odataResponse";

export enum LOOKUPNAME {
  SystemYesNo = "System YesNo",
  AssetCategory = "System Category Lookup",
  AssetStatus = "System AR Status",
  AssetManager = "System AR Asset Manager",
  Organisation = "System AR Controlling Organisation",
  Project = "System AR Project",
  Company = "System Company Lookup",
  AssetComplexity = "System AR Complexity",
  Supplier = "System AR Supplier",
  PrimaryServiceName = "System AR Primary Service Name",
  department = "Global-Department",
  costCentre = "Global-Cost Centre",
  location = "Global-Office Location",
  osProduct = "IT-OS Product",
}
export type AssetLOVs = {
  [key in keyof typeof LOOKUPNAME]: DTO_LOV[];
};
