import { IColumnFields } from "@components/cc-grid/model";

export const colAttributes: IColumnFields[] = [
  {
    field: "Name",
    title: "Name",
    width: 120,
  },
  {
    field: "Value",
    title: "Value",
    width: 240,
  },
];
