import { Permit, Svc_Permit } from "@app/products/local-laws/[id]/model";
import { formatPermitBalance } from "@app/products/local-laws/[id]/util";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { FINANCEMETHOD } from "@common/constants/enumerations";
import { RECORDTYPE } from "@common/constants/recordtype";
import { RefNumberUsage } from "@common/input-pickers/debtor-number/model";
import { DebtorNumberPicker } from "@common/input-pickers/debtor-number/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { capitalizeFirstLetter, formatOSFees } from "@common/utils/formatting";
import { ICCInputPickerChangeEvent } from "@components/cc-input-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IDebtorSectionProps {
  formRenderProps: FormRenderProps;
  isInactive: boolean
}

const nameOfSvcPermit = nameOfFactory<Svc_Permit>();
const nameOfPermit = nameOfFactory<Permit>();
const getNameOf = (permitPropsName: keyof Permit) => {
  return `${nameOfSvcPermit("Permit")}.${nameOfPermit(permitPropsName)}`;
};
const nameOfDebtorNumber = nameOfFactory<RefNumberUsage>();

export const PermitDebtorSection = observer(
  ({ formRenderProps, isInactive }: IDebtorSectionProps) => {
    //#region STORE ========/
    const { dataForms } = useFlexibleFormStore();
    const osFee = dataForms?.GeneralForm?.OSFee;
    const permitBalance = dataForms?.GeneralForm?.PermitBalance;
    const isSuperAdmin = dataForms?.IsSuperAdmin;
    const { settings } = useCommonCoreStore();
    const { pushNotificationPortal } = useNotificationPortalStore();
    //#endregion STORE =====/

    const { valueGetter, onChange } = formRenderProps;
    const permitObj = valueGetter(nameOfSvcPermit("Permit"));

    //#region HANDLER ========/
    const handleOnChangeDebtorNumberValue = (value: RefNumberUsage) => {
      onChange(getNameOf("DebtorNumber"), {
        value: value?.Sys_DebtorNumber ?? "",
      });
      onChange(`option.DebtorNumber`, {
        value: [value],
      });
    };
    //#endregion HANDLER =====/

    //#region UI ========/
    const debtorNumberLabelSetting = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_DebtorNumberLabel]
    );
    const debtorNumberLabel = capitalizeFirstLetter(debtorNumberLabelSetting);

    const autoCreateDebtorNumber = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_FinanceAllocateDebtorNumber
      ]
    );

    const isMandatoryDebtorNumber = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_DebtorNumberMandatory]
    );

    const isEnableDebtorUserSelection = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_EnableDebtorUserSelection
      ]
    );

    const osFeesLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_OSFeeLabel]
    );

    const isEnableMultiLineInvoice = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
    );
    const isEnableUpToGlassFinance = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance]
    );
    const financeMethod =
      getNumberValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_FinanceMethod]
      ) ?? FINANCEMETHOD.None;

    const isShowPermitBalance =
      isEnableUpToGlassFinance &&
      financeMethod === FINANCEMETHOD.Direct &&
      isEnableMultiLineInvoice;

    //Debtor picker
    const documentDebtorNumbers: RefNumberUsage[] = valueGetter(
      `option.DebtorNumber`
    ) ?? [
      {
        Record_Id: null,
        Sys_DebtorNumber: valueGetter(getNameOf("DebtorNumber")),
      },
    ];

    const isHasPermitHolder = !!permitObj.Contact;
    const onDebtorNumberButtonClick = () => {
      pushNotificationPortal({
        title: "Please select Permit holder before selecting Debtor number.",
        type: "warning",
      });
    };

    //#endregion UI =====/

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel
              title={debtorNumberLabel ?? "Debtor number"}
              isMandatory={isMandatoryDebtorNumber}
            />
            {isEnableDebtorUserSelection ? (
              <Field
                name={getNameOf("DebtorNumber")}
                value={documentDebtorNumbers[0]}
                placeholder={"Debtor number"}
                component={DebtorNumberPicker}
                disabled={(!isSuperAdmin && autoCreateDebtorNumber) || isInactive}
                onlyDisableInput={!isInactive}
                isSharePointDocumentLibraries
                selectableMode="single"
                onPickHandle={handleOnChangeDebtorNumberValue}
                onInputChange={(event: ICCInputPickerChangeEvent) => {
                  onChange(getNameOf("DebtorNumber"), {
                    value: event?.value ?? "",
                  });
                  onChange(`option.DebtorNumber`, {
                    value: [
                      {
                        Record_Id: null,
                        Sys_DebtorNumber: event.value,
                      },
                    ],
                  });
                }}
                validator={
                  isMandatoryDebtorNumber ? requiredValidator : undefined
                }
                contactId={permitObj.Contact?.Contact?.Contact_ID}
                recordId={permitObj?.Permit_ID}
                recordType={RECORDTYPE.LLP_Permit}
                textField={nameOfDebtorNumber("Sys_DebtorNumber")}
                textProduce={(data: RefNumberUsage) => data.Sys_DebtorNumber}
                onButtonClick={
                  isHasPermitHolder ? undefined : onDebtorNumberButtonClick
                }
              />
            ) : (
              <Field
                name={getNameOf("DebtorNumber")}
                component={CCInput}
                placeholder={"Debtor number"}
                disabled={(!isSuperAdmin && autoCreateDebtorNumber) || isInactive}
                validator={
                  isMandatoryDebtorNumber ? requiredValidator : undefined
                }
              />
            )}
          </div>
          <div className="cc-field">
            <CCValueField
              name={nameOfSvcPermit("OSFee")}
              label={osFeesLabel ?? "OS fees"}
              value={osFee}
              style={formatOSFees(osFee)}
              format={CURRENCY_FORMAT.CURRENCY1}
            />
          </div>
          {isShowPermitBalance && (
            <div className="cc-field">
              <CCValueField
                name={nameOfSvcPermit("PermitBalance")}
                label="Permit balance"
                style={formatPermitBalance(permitBalance)}
                value={permitBalance}
                format={CURRENCY_FORMAT.CURRENCY1}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
