import { EKeysOfStepsNewNoticeRun, ENoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/model"

const getNoticeTypeId = (valueGetter: any) => {
  return valueGetter(EKeysOfStepsNewNoticeRun.NoticeType)?.Notice_Type_Id
}


export const isShowWithNoticeType = (listTypeAllow: ENoticeType[], valueGetter: any) => {
  return listTypeAllow.includes(getNoticeTypeId(valueGetter))
}
