import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { SamplesAccordion } from "@app/core/samples/_index";
import { CSLForm } from "@app/products/csl/[id]/components/child-screens/general/_index";
import { CSLContactTab } from "@app/products/csl/[id]/components/reference-sidebar/contact/_index";
import { CSLDetailTab } from "@app/products/csl/[id]/components/reference-sidebar/detail/_index";
import { CSLHistoryTab } from "@app/products/csl/[id]/components/reference-sidebar/history/_index";
import { useCslStore } from "@app/products/csl/[id]/store";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import { default as React, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedCSL = observer(() => {
  // TODO: The id variable is only use in case reloading.
  const { id } = useParams<{ id: string }>();
  const { isLoading, cslRecords, csl, loadCsl, responseLoadError, cslId } =
    useCslStore();

  const [showSlideBar, setShowSlideBar] = useState(true);
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `CSL - ${cslId ?? 0}`,
      Text:
        `${getDisplayTextWithDashes([csl?.Licence_ID, csl?.licensee])}` ||
        "N/A",
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CSM,
      //TODO don't have exactly record type, temporary use CSM_Licence
      RecordType_ENUM: RECORDTYPE.CSM_Licence,
      Record_ID: cslId ?? 0,
    },
  ];

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CSLDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <CSLContactTab /> },
      {
        title: "History",
        component: <CSLHistoryTab />,
      },
    ],
  });

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Actions",
      totalRecord: cslRecords?.actions,
      component: <Actions id={cslId} recordType={RECORDTYPE.CSM_Licence} />,
    },
    {
      title: "Comments",
      totalRecord: cslRecords?.comments,
      component: <Comments id={cslId} recordType={RECORDTYPE.CSM_Licence} />,
    },
    {
      title: "Contacts",
      totalRecord: cslRecords?.contacts,
      component: <Contacts id={cslId} recordType={RECORDTYPE.CSM_Licence} />,
    },
    {
      title: "Documents",
      totalRecord: cslRecords?.documents,
      component: <Documents id={cslId} recordType={RECORDTYPE.CSM_Licence} />,
    },
    {
      title: "Fees",
      totalRecord: cslRecords?.fees,
      component: <Fees id={cslId} recordType={RECORDTYPE.CSM_Licence} />,
    },
    {
      title: "Inspections",
      totalRecord: cslRecords?.samples,
      component: (
        <SamplesAccordion id={cslId} recordType={RECORDTYPE.CSM_Licence} />
      ),
    },
    {
      title: "Services",
      totalRecord: cslRecords?.samples,
      component: (
        <SamplesAccordion id={cslId} recordType={RECORDTYPE.CSM_Licence} />
      ),
    },
    {
      title: "Samples",
      totalRecord: cslRecords?.samples,
      component: (
        <SamplesAccordion id={cslId} recordType={RECORDTYPE.CSM_Licence} />
      ),
    },
    {
      title: "Conditions",
      totalRecord: cslRecords?.samples,
      component: (
        <SamplesAccordion id={cslId} recordType={RECORDTYPE.CSM_Licence} />
      ),
    },
    {
      title: "Related",
      totalRecord: cslRecords?.samples,
      component: (
        <SamplesAccordion id={cslId} recordType={RECORDTYPE.CSM_Licence} />
      ),
    },
    {
      title: "Events",
      totalRecord: cslRecords?.samples,
      component: (
        <SamplesAccordion id={cslId} recordType={RECORDTYPE.CSM_Licence} />
      ),
    },
  ];
  const getTitle = useMemo(() => {
    const licenceNumber = csl?.Licence_ID;
    const applicant = csl?.licensee;

    if (licenceNumber && applicant) {
      return `${licenceNumber} - ${applicant}`;
    }

    return (licenceNumber && String(licenceNumber)) || applicant || "N/A";
  }, [csl]);

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Licence" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCsl(id, RECORDTYPE.CSM_Licence)}
        />
      ) : (
        <>
          <FormTitle title={getTitle} badge={csl?.Status_ENUM} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Renew"} />
                <CCNavButton title={"Cancel"} />
                <CCNavButton title={"Revoke"} />
                <CCNavButton title={"Non licensed"} />
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Comment"} />
                {/* TODO: enhance later */}
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.CSM}
                  recordType={RECORDTYPE.CSM_Licence}
                  recordId={parseInt(id)}
                />
                <CCNavButton title={"Complaint"} />
                <CCNavButton title={"Document"} />
                <CCNavButton title={"Fee"} />
                <CCNavButton title={"Inspection"} />
                <CCNavButton title={"Related"} />
              </CCNavButton>,

              <CCNavButton title={"View"} type="sub">
                <CCNavButton title={"Documents"} />
                <CCNavButton title={"Journal"} />
                <CCNavButton title={"Project"} />
              </CCNavButton>,
              <CCNavButton title={"Print"} />,
              <CCNavButton title={"More Options"} type="more">
                <CCNavButton title={"Check fee amount"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {csl && <CCGeneralPanel component={<CSLForm />} />}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
