import { getViewConfigurations } from "@app/products/property/api";
import { colSelectConstraint } from "@app/products/property/components/dialogs/add-constraints-dialog/config";
import { PROPERTY_CONSTRAINT_REGISTER_ODATA } from "@app/products/property/components/dialogs/add-constraints-dialog/constant";
import { VO_Constraint } from "@app/products/property/components/dialogs/add-constraints-dialog/model";
import { ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAddConstraintsDialogProps {
  onClose: () => void;
  isLoadingFinish?: boolean;
  handleSelectConstraints: (data: VO_Constraint[]) => void;
}

const nameOf = nameOfFactory<VO_Constraint>();
export const AddConstraintsDialog = ({
  onClose,
  isLoadingFinish = false,
  handleSelectConstraints,
}: IAddConstraintsDialogProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [selectedConstraints, setSelectedConstraints] = useState<
    VO_Constraint[]
  >([]);
  const [constraintsColumns, setConstraintsColumns] =
    useState<IColumnFields[]>(colSelectConstraint);
  const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);

  const loadViewConfiguration = async () => {
    setIsLoadingDialog(true);
    const response = await getViewConfigurations(
      ViewConfiguration.ConstraintLookup
    );
    setIsLoadingDialog(false);
    let errorResponse = undefined;
    if (isSuccessResponse(response) && response?.data) {
      setConstraintsColumns(
        processDynamicColumns(
          colSelectConstraint,
          response?.data?.ColumnDefinitions?.Columns
        )
      );
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Load view configuration failed",
      };
    }
    setResponseLoadError(errorResponse);
  };

  useEffectOnce(() => {
    loadViewConfiguration();
  });

  return (
    <Form
      onSubmitClick={() => {
        handleSelectConstraints(selectedConstraints ?? []);
      }}
      render={(formRenderProps: FormRenderProps) => {
        return (
          <CCDialog
            titleHeader="Select Constraints"
            maxWidth="50%"
            height="auto"
            onClose={onClose}
            bodyElement={
              <FormElement className="cc-form">
                <section className="cc-field-group">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-form-cols-1">
                    {isLoadingDialog ? (
                      <Loading isLoading={isLoadingDialog} />
                    ) : responseLoadError ? (
                      <CCLoadFailed
                        responseError={responseLoadError}
                        onReload={() => {
                          loadViewConfiguration();
                        }}
                      />
                    ) : (
                      <div className="cc-form-cols-1">
                        <CCGrid
                          columnFields={constraintsColumns}
                          primaryField={nameOf("Constraint_Id")}
                          dataUrl={PROPERTY_CONSTRAINT_REGISTER_ODATA}
                          selectableMode="multiple"
                          onSelectionChange={(dataItem: VO_Constraint[]) => {
                            setSelectedConstraints(dataItem);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </section>
              </FormElement>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  iconClass={isLoadingFinish ? "fas fa-spinner fa-spin" : ""}
                  themeColor="primary"
                  onClick={formRenderProps.onSubmit}
                  type="submit"
                  disabled={selectedConstraints?.length === 0}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
