import { PSARCouncilContacts } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getContactList = async (
  ids: string
): Promise<APIResponse<PSARCouncilContacts[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<PSARCouncilContacts[]>(
      `api/core/internal/contacts/councilcontactlist`,
      {
        params: {
          ids: ids,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
