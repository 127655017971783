import { history } from "@/AppRoutes";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import {
  Application,
  IPPRApplicationParentSection,
} from "@app/products/town-planning/ppr/[id]/model";
import { PPRStoreInstance } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
import { putPlansToComply } from "./api";
configure({ enforceActions: "always" });

class PlansToComplyButtonStore {
  private _isLoading: boolean = false;
  private _isPPRWorkflow: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isPPRWorkflow() {
    return toJS(this._isPPRWorkflow);
  }
  setIsPPRWorkflow = (isPPRWorkflow: boolean) => {
    runInAction(() => {
      this._isPPRWorkflow = isPPRWorkflow;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  putPlansToComply = async (
    application: Application,
    isPPRWorkflow?: boolean
  ) => {
    this.setIsLoading(true);
    const response = await putPlansToComply(application, isPPRWorkflow);
    PPRStoreInstance.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      history.replace(`${TOWN_PLANNING_PPR_ROUTE}/${response.data?.ID}`, {
        notification: [
          {
            title: "Application Plans to comply successfully",
            type: "success",
          },
        ],
      } as IPPRApplicationParentSection);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Application plans to comply failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsLoading(false);
  };
}
export const plansToComplyButtonStore = new PlansToComplyButtonStore();
const plansToComplyButtonContext = createContext(plansToComplyButtonStore);
export const usePlansToComplyButtonStore = () => {
  return useContext(plansToComplyButtonContext);
};
