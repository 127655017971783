import { PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE } from "@app/products/property/assessments/compliance/[id]/constant";
import { VO_Compliance_W_Assessment } from "@app/products/property/assessments/compliance/[id]/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<VO_Compliance_W_Assessment>();
export const includeInactiveComplianceColList: IColumnFields[] = [
  {
    field: nameOf("Compliance_Id"),
    title: "Compliance ID",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) =>
      `${PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE}/${dataItem.Compliance_Id}`,
  },
  { field: nameOf("Name"), title: "Name", width: 250 },
  { field: "Type", title: "Type" },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Alert_Message"),
    title: "Alert Message",

    width: 150,
  },
  {
    field: nameOf("From_Date"),
    title: "Date From",

    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("To_Date"),
    title: "Date To",

    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Responsible_Officer"),
    title: "Responsible Officer",
  },
];
