import { IPropertyData } from "@app/products/property/fire-prevention/components/dialogs/select-property/model";

export const mockPropertyData: IPropertyData[] = [
  {
    Id: 1,
    Locality: "Hobart",
    Street: "Berea",
    StreetType: "Street",
    HouseNumber: "4",
    PropertyName: "",
    Owner: "G Q Dyer & K Nowicka",
    OwnerAddress: "1/4 Berea St",
    Ratepayer: "G Q Dyer & K",
    RatepayerAddress: "1/4 Berea St",
    LandArea: "",
    LegalDescription: "STR 129193",
    Clearance: "",
    Outcome: "",
    AssessmentNumber: "1825610",
    ParcelId: 107330,
    CaseId: 1,
  },
  {
    Id: 2,
    Locality: "Hobart",
    Street: "Berea",
    StreetType: "Street",
    HouseNumber: "12",
    PropertyName: "",
    Owner: "P G Allen & K A Moore",
    OwnerAddress: "U 22/37 Lpim",
    Ratepayer: "P G Allen & K",
    RatepayerAddress: "U 22/37 Lpim",
    LandArea: "111 m2",
    LegalDescription: "LTO 20-19N9",
    Clearance: "",
    Outcome: "",
    AssessmentNumber: "5655321",
    ParcelId: 194080,
    CaseId: 1,
  },
  {
    Id: 3,
    Locality: "Hobart",
    Street: "Berea",
    StreetType: "Street",
    HouseNumber: "10",
    PropertyName: "",
    Owner: "U Kennedy",
    OwnerAddress: "10 Bereas St",
    Ratepayer: "U Kennedy",
    RatepayerAddress: "10 Bereas St",
    LandArea: "124 m2",
    LegalDescription: "LTO 20-19N9",
    Clearance: "",
    Outcome: "",
    AssessmentNumber: "5655348",
    ParcelId: 107330,
    CaseId: 1,
  },
  {
    Id: 4,
    Locality: "Hobart",
    Street: "Berea",
    StreetType: "Street",
    HouseNumber: "4",
    PropertyName: "",
    Owner: "D C Tapin",
    OwnerAddress: "2/4 Berea St",
    Ratepayer: "D C Tapin",
    RatepayerAddress: "2/4 Berea St",
    LandArea: "",
    LegalDescription: "STR 129193",
    Clearance: "",
    Outcome: "",
    AssessmentNumber: "1825629",
    ParcelId: 107335,
    CaseId: 1,
  },
  {
    Id: 5,
    Locality: "Hobart",
    Street: "Berea",
    StreetType: "Street",
    HouseNumber: "8",
    PropertyName: "",
    Owner: "M G Branfield & L Branfield",
    OwnerAddress: "24 Montgomery Ave",
    Ratepayer: "M G Branfield",
    RatepayerAddress: "24 Montgomery Ave",
    LandArea: "126 m2",
    LegalDescription: "LTO 20-19N9",
    Clearance: "",
    Outcome: "",
    AssessmentNumber: "5655356",
    ParcelId: 194095,
    CaseId: 1,
  },
  {
    Id: 6,
    Locality: "Hobart",
    Street: "Berea",
    StreetType: "Street",
    HouseNumber: "6",
    PropertyName: "",
    Owner: "G B Lum",
    OwnerAddress: "287 Maccquarie St",
    Ratepayer: "G B Lum",
    RatepayerAddress: "287 Maccquarie St",
    LandArea: "",
    LegalDescription: "LTO SP1255",
    Clearance: "",
    Outcome: "",
    AssessmentNumber: "5655364",
    ParcelId: 194095,
    CaseId: 1,
  },
  {
    Id: 7,
    Locality: "Battery Point",
    Street: "Crelin",
    StreetType: "Street",
    HouseNumber: "3A",
    PropertyName: "",
    Owner: "Australian Wholesale Timbers Pty Ltd",
    OwnerAddress: "PO Box 258",
    Ratepayer: "",
    RatepayerAddress: "",
    LandArea: "",
    LegalDescription: "STR 129193",
    Clearance: "",
    Outcome: "",
    AssessmentNumber: "",
    ParcelId: 1,
    CaseId: 1,
  },
  {
    Id: 8,
    Locality: "Battery Point",
    Street: "Crelin",
    StreetType: "Street",
    HouseNumber: "3",
    PropertyName: "",
    Owner: "R G Ely & M J Ely",
    OwnerAddress: "16 Capel St",
    Ratepayer: "R G Ely & M J Ely",
    RatepayerAddress: "16 Capel St",
    LandArea: "",
    LegalDescription: "",
    Clearance: "",
    Outcome: "",
    AssessmentNumber: "",
    ParcelId: 1,
    CaseId: 1,
  },
  {
    Id: 9,
    Locality: "Battery Point",
    Street: "Crelin",
    StreetType: "Street",
    HouseNumber: "3A",
    PropertyName: "",
    Owner: "M J Demangone & K V Demangone",
    OwnerAddress: "PO Box 178",
    Ratepayer: "M J Demangone & K V Demangone",
    RatepayerAddress: "PO Box 178",
    LandArea: "",
    LegalDescription: "",
    Clearance: "",
    Outcome: "",
    AssessmentNumber: "",
    ParcelId: 1,
    CaseId: 1,
  },
  {
    Id: 10,
    Locality: "Battery Point",
    Street: "Crelin",
    StreetType: "Street",
    HouseNumber: "3A",
    PropertyName: "",
    Owner: "R C Smith & S A Smith",
    OwnerAddress: "PO Box 903",
    Ratepayer: "R C Smith & S A Smith",
    RatepayerAddress: "PO Box 903",
    LandArea: "",
    LegalDescription: "",
    Clearance: "",
    Outcome: "",
    AssessmentNumber: "",
    ParcelId: 1,
    CaseId: 1,
  },
];
