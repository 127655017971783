import { eventEmitter } from "@/App";
import { clearValuations } from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/buttons/clear-valuations/api";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ClearValuationsButton = observer(() => {
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const { gridData } = useCCProductListViewStore();

  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleClearValuations = async () => {
    setIsLoading(true);
    const response = await clearValuations();
    setIsLoading(false);
    setShowConfirmationDialog(false);
    clearNotifications();
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      eventEmitter.emit(CCGridEventType.RefreshOData);
      pushNotification({
        title:
          response?.data?.SuccessMessage ??
          "Cleared valuation(s) successfully.",
        type: "success",
      });
    } else {
      pushNotification({
        title: response?.data?.ErrorMessage ?? "Clear valuation(s) failed.",
        type: "error",
        autoClose: false,
      });
    }
  };

  return (
    <>
      <CCNavButton
        title="Clear valuations"
        onClick={() => setShowConfirmationDialog(true)}
        disabled={!gridData?.length}
      />
      {showConfirmationDialog && (
        <ConfirmDialog
          title="Confirmation"
          subMessage="Do you wish to clear the imported valuations?"
          onClosePopup={() => setShowConfirmationDialog(false)}
          isLoadingYes={isLoading}
          onAsyncConfirm={handleClearValuations}
        />
      )}
    </>
  );
});
