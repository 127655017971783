import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { ICustomEditCell } from "@components/cc-grid/model";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";

export const CurrencyInputCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
}: ICustomEditCell) => {
  const [data, setData] = useState<any>();
  const [isPressEnter, setIsPressEnter] = useState(false);

  const keydownEnterHandler = (e: any) => {
    if (e.key === "Enter") {
      setIsPressEnter(true);
    }
  };

  useEffect(() => {
    document
      .getElementById("cc-currency-input")
      ?.addEventListener("keydown", keydownEnterHandler);

    return () => {
      document
        .getElementById("cc-currency-input")
        ?.removeEventListener("keydown", keydownEnterHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CCCurrencyInput
      id="cc-currency-input"
      value={value}
      onChange={(event: NumericTextBoxChangeEvent) => {
        setData({
          field: field,
          dataIndex: dataIndex || 0,
          dataItem,
          syntheticEvent: event.syntheticEvent,
          value: event.value,
        });
        if (onChange && isPressEnter) {
          onChange(data);
        }
      }}
      onBlur={() => {
        if (field && onChange) {
          onChange(data);
        }
      }}
    />
  );
};
