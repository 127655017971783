import { isSuccessResponse } from "@common/apis/util";
import { ICCRoute } from "@common/constants/ICCRoute";
import { commonProductStore } from "@common/stores/products/store";
import { getSettingsCorePPR } from "@common/stores/products/town-planning/api";
import { TOWN_PLANNING_CONFIGURATION_MODE } from "@common/stores/products/town-planning/enum";
import { isNull } from "lodash";
import { configure, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class CommonTownPlanningStore {
  private _isLoading: boolean = false;
  private _townPlanningMode: TOWN_PLANNING_CONFIGURATION_MODE =
    TOWN_PLANNING_CONFIGURATION_MODE.LOCAL_GOV;
  private _townPlanningRoute?: ICCRoute = undefined;
  private _townPlanningRouteMap?: {
    [key in TOWN_PLANNING_CONFIGURATION_MODE]?: ICCRoute;
  } = undefined;

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get townPlanningMode() {
    return this._townPlanningMode;
  }
  setTownPlanningMode = (
    townPlanningMode: TOWN_PLANNING_CONFIGURATION_MODE
  ) => {
    runInAction(() => {
      this._townPlanningMode = townPlanningMode;
    });
  };

  get townPlanningRoute() {
    return this._townPlanningRoute;
  }
  setTownPlanningRoute = (townPlanningRoute?: ICCRoute) => {
    runInAction(() => {
      this._townPlanningRoute = townPlanningRoute;
    });
  };

  get townPlanningRouteMap() {
    return this._townPlanningRouteMap;
  }
  setTownPlanningRouteMap = (townPlanningRouteMap?: {
    [key in TOWN_PLANNING_CONFIGURATION_MODE]?: ICCRoute;
  }) => {
    runInAction(() => {
      this._townPlanningRouteMap = townPlanningRouteMap;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._townPlanningMode = TOWN_PLANNING_CONFIGURATION_MODE.LOCAL_GOV;
      this._townPlanningRoute = undefined;
      this._townPlanningRouteMap = undefined;
    });
  };

  loadSettingCorePPR = async () => {
    this.setIsLoading(true);
    const response = await getSettingsCorePPR();

    let townPlanningMode: TOWN_PLANNING_CONFIGURATION_MODE =
      this.townPlanningMode;
    let townPlanningRoute = this.townPlanningRouteMap
      ? this.townPlanningRouteMap[townPlanningMode]
      : undefined;
    if (isSuccessResponse(response) && !isNull(response.data)) {
      townPlanningMode = response.data
        ?.Value as TOWN_PLANNING_CONFIGURATION_MODE;

      townPlanningRoute = this.townPlanningRouteMap
        ? this.townPlanningRouteMap[townPlanningMode]
        : undefined;

      this.setTownPlanningMode(townPlanningMode);
      this.setTownPlanningRoute(townPlanningRoute);
      commonProductStore.setResponseLoadError(undefined);
      this.setIsLoading(false);
      return {
        mode: townPlanningMode,
        route: townPlanningRoute,
      };
    }

    commonProductStore.setResponseLoadError({
      status: response.status,
      error: response.error,
    });
    this.setIsLoading(false);
    return undefined;
  };
}

export const commonTownPlanningStore = new CommonTownPlanningStore();
const commonTownPlanningStoreContext = createContext(commonTownPlanningStore);
export const useCommonTownPlanningStore = () => {
  return useContext(commonTownPlanningStoreContext);
};
