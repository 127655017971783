import { sortByField } from "@common/utils/common";
import { IListPanelBar } from "@components/custom-panelbar/model";
import {
  PanelBar,
  PanelBarExpandMode,
  PanelBarItem,
  PanelBarSelectEventArguments,
} from "@progress/kendo-react-layout";
import React from "react";
import "./CustomPanelBar.scss";

export interface ICustomPanelBarProps {
  listPanelBar: IListPanelBar[];
  isShowedTotalRecord?: boolean;
  sort?: boolean;
  expandMode?: PanelBarExpandMode;
  handleOnSelect?: (event: PanelBarSelectEventArguments) => void;
}

export const CustomPanelBar = ({
  listPanelBar,
  isShowedTotalRecord = false,
  sort = true,
  expandMode = "single",
  handleOnSelect,
}: ICustomPanelBarProps) => {
  const listSortedPanelBar = sort
    ? sortByField(listPanelBar, "title")
    : listPanelBar;
  return (
    <PanelBar
      expandMode={expandMode}
      className="cc-custom-panel-bar"
      onSelect={handleOnSelect}
    >
      {listSortedPanelBar.map((item, index) => {
        if (item?.isVisible === false) {
          return null;
        }
        return (
          <PanelBarItem
            expanded={item.expanded}
            key={index}
            title={
              <div className="cc-collapse-header">
                <div className="cc-title-right" id={item.id ?? ""}>
                  <h6>
                    {item.title}&nbsp;
                    {/*
                    //TODO: will remove when confirmed
                     {isShowedTotalRecord ? (
                      <span>( {item.totalRecord || 0} )</span>
                    ) : (
                      ""
                    )} */}
                    {isShowedTotalRecord && (
                      <div className="cc-panel-header-total-record">
                        {item?.totalRecord ?? 0}
                      </div>
                    )}
                  </h6>
                  {item.rightTitle && item.rightTitle}
                </div>
                <hr />
              </div>
            }
          >
            <div className="cc-custom-panel-body">{item.component}</div>
          </PanelBarItem>
        );
      })}
    </PanelBar>
  );
};
