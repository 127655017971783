import { CCPanel, ICCPanelProps } from "@components/cc-panel/_index";
import React, { useState } from "react";
import "./_index.scss";

interface ICCGeneralPanel extends ICCPanelProps {}
export const CCGeneralPanel = ({
  isExpanded: isExpandedProp = true,
  title = "General",
  isKeepMounted = true,
  className,
  isInvalid,
  isSubPanel,
  ...others
}: ICCGeneralPanel) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedProp);
  return (
    <CCPanel
      {...others}
      title={title}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded(!isExpanded)}
      className={`${className ? `${className} ` : ``} cc-general-panel ${
        isSubPanel ? `cc-sub-panel` : ``
      }`}
      isKeepMounted={isKeepMounted}
      isInvalid={isInvalid}
    />
  );
};
