import { nameOfFactory } from "@common/utils/common";
import { VO_Charge_Run_PIC } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/components/child-screens/pic/model";
import { CCGrid } from "@components/cc-grid/_index";
import { colChargeRunPIC } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/components/child-screens/pic/config";
import React, { useState } from "react";
import { getViewConfigurations } from "@app/products/property/api";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { useEffectOnce } from "react-use";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { useParams } from "react-router-dom";

const nameOfPIC = nameOfFactory<VO_Charge_Run_PIC>();
export const ChargeRunPIC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [processColChargeRunPIC, setProcessColChargeRunPIC] =
    useState<IColumnFields[]>(colChargeRunPIC);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const { id } = useParams<{ id: string }>();

  const loadData = async () => {
    setIsLoading(true);
    const response = await getViewConfigurations(
      ViewConfiguration.ChargeRun_PIC
    );
    if (isSuccessResponse(response)) {
      const viewConfig: ICCViewColumn[] | undefined =
        response?.data?.ColumnDefinitions?.Columns;
      if (!viewConfig || viewConfig?.length === 0) {
        setProcessColChargeRunPIC([]);
        setIsLoading(false);
        return;
      }
      setProcessColChargeRunPIC(
        processDynamicColumns(colChargeRunPIC, viewConfig)
      );
    } else {
      setResponseLoadError({
        status: response.status,
        error: response.error ?? "Load failed",
      });
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadData();
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <CCGrid
      dataUrl={`/odata/property/internal/chargerunpicregister?key=${id}&$count=true&`}
      columnFields={processColChargeRunPIC}
      primaryField={nameOfPIC("PIC_Charge_Run_Id")}
    />
  );
};
