import { colRecordTypesPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/records-picker/config";
import { IRecordsTypes } from "@app/products/town-planning/ppr/[id]/components/input-picker/records-picker/model";
import { nameOfFactory } from "@common/utils/common";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React, { useState } from "react";

const nameOf = nameOfFactory<IRecordsTypes>();

export interface IRecordsTypesPicker extends ICCInputPickerProps {
  onPickTypes: (value: any) => void;
  recordsTypeListData: any[];
  selectableMode?: "single" | "multiple" | "none";
}
export const RecordsTypesPicker = (props: IRecordsTypesPicker) => {
  const {
    onPickTypes,
    recordsTypeListData,
    onChange,
    onError,
    selectableMode,
    textField,
    textProduce,
    valueField,
    valueProduce,
    ...other
  } = props;
  const [listData, setListData] = useState<IRecordsTypes[]>([]);
  const handleOnChange = (event: ICCInputPickerChangeEvent) => {
    const value: IRecordsTypes = event.value;
    if (onPickTypes) onPickTypes(value);
  };

  const handleOnLoadData = () => {
    setListData(recordsTypeListData);
  };

  return (
    <CCInputPicker
      {...other}
      dialog={{
        titleHeader: "Pick Record Type",
        maxWidth: "50%",
      }}
      grid={{
        columnFields: colRecordTypesPicker,
        primaryField: nameOf("ID"),
        selectableMode: selectableMode,
        data: listData,
        isAutoHiddenPager: false,
      }}
      onLoadData={handleOnLoadData}
      onChange={handleOnChange}
      valueField={valueField}
      valueProduce={valueProduce}
      textField={textField}
      textProduce={textProduce}
    />
  );
};
