import { validate } from "@app/products/hm/inspections/[id]/components/child-screens/general/components/inspection-form-element/util";
import { useInspectionsStore } from "@app/products/hm/inspections/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { InspectionFormElement } from "./components/inspection-form-element/_index";

export const InspectionForm = observer(() => {
  const { inspections, loadPosition, createInspections, updateInspections } =
    useInspectionsStore();
  const isNew = useIsNew();
  const handleSubmitForm = (data: any) => {
    if (isNew) {
      createInspections(data);
    } else {
      updateInspections(data);
    }
  };

  //#region STATES
  useEffect(() => {
    loadPosition(4);
  }, [loadPosition]);

  //#region PROPERTIES
  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmitForm}
        validator={() => validate(inspections)}
        initialValues={inspections}
        render={(formRenderProps: FormRenderProps) => (
          <InspectionFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
