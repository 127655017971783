import { ANIMALS_INSPECTIONS_VIEW_URL } from "@app/products/animals/inspections/constant";
import { InspectionsView } from "@app/products/animals/inspections/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { formatDateByKendo } from "@common/utils/formatting";

export const getInspectionsByAreaView = (isByArea: boolean) => {
  return `${ANIMALS_INSPECTIONS_VIEW_URL}(isByArea=${isByArea})?$count=true&`;
};

export const AnimalInspectionsBookmark = {
  getBookmarkDisplayName(selectedRow: InspectionsView) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Animals - Inspections ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: InspectionsView) {
    return getDisplayTextWithDashes([
      selectedRow?.InspectionType ?? "",
      formatDateByKendo(selectedRow?.DateInspected, DATE_FORMAT.DATE) ?? "",
    ]);
  },
};
