import { NotificationType } from "@common/constants/notificationtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { ContactRegisterForm } from "@common/pages/contacts/manage-contact/model";
import { useGlobalStore } from "@common/stores/global/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import ExistManageContact from "./components/exist-manage-contact/_index";
import { NewManageContact } from "./components/new-manage-contact/_index";
import "./_index.scss";

export const ManageContact = observer(() => {
  const {
    setIsNotify,
    setNotificationType,
    // setNotificationsList,
  } = useGlobalStore();

  const isNew = useIsNew();
  const history = useHistory<any>();

  const [isLoading, setIsLoading] = useState(false);

  const [contactInfo, setContactInfo] = useState<
    ContactRegisterForm | undefined
  >(undefined);

  useEffect(() => {
    const { state } = history.location;

    if (state?.status === "success") {
      setIsNotify(true);
      setNotificationType(NotificationType.Success);

      setTimeout(() => {
        setIsNotify(false);
      }, 3000);

      history.replace(window.location.pathname); // remove history states
    }

    return () => {
      setIsNotify(false);
    };
  }, [history, setIsNotify, setNotificationType]);

  //@Todo UnComment after api working

  /*   useEffectOnce(() => {
    if (isNew) return;

    loadContactById(id).then((data: ContactResponse | undefined) => {
      if (!data) {
        // return history.replace(AnimalsRoute.ANIMALS_REGISTER_ACTIVE);
        return;
      }
      const value: ContactRegisterForm =
        convertContactResponseToContactForm(data);
      setContactInfo(value);
    });
  }); */

  //@Todo remove after api working
  useEffectOnce(() => {
    const mockContactInfo: ContactRegisterForm = {
      DisplayName: "Derin Davis",
      FirstName: "Derin",
      LastName: "Davis",
      ContactClassification_Name: "",
      PreferredMethod_Name: "",
      WorkPhone: "06273628163",
      HomePhone: "0999999999",

      Fax: "",
      Mobile: "0999999999",
      NARNumber: "",
      NARUpdated: "",
      Flag_SendSMSAlerts: false,
      Custom_Tags: "",
      Email: "derin.davis@openoffice.com.au",
      Website: "",
      ABN: "",
      ACN: "",
      VIP: false,
      VIPTypeID: "",
      VIPType: "",
      VIPRelationshipOwnerID: 0,
      VIPRelationshipOwner: "",
      ContactRoles_Count: 0,
      PreferredAddress: "",
      Organisation: "",
      Preferred_ContactAddressType_KWD: 0,
      Preferred_ContactAddressType_Name: "",
      PostalAddress: "",
      Postal_ContactAddressType_KWD: 0,
      Postal_ContactAddressType_Name: "",
      EntityId: 8590, //Mock
      ApplicationNumber: undefined, //Mock
      Gender: "female", //Mock
      DateOfBirth: new Date(), //Mock
      DateOfDeath: new Date(), //Mock
      PrivateAddress: "Moorabbin, VIC 3189", //Mock
      WorkAddress: "377A Springvale Rd Springvale Vic 3171", //Mock
      MoreAddress:
        "Home at Strathewen burnt in Bushfire, being rebuilt: 50 Chadds Chreek Rd Strathewen Vic 3099", //Mock
      Distinction: "", //Mock
      Occupation: "", //Mock
      LastChanged: new Date(), //Mock
      ChangedBy: "", //Mock
      IsObsolete: false, //Mock
      Notes: "", //Mock
      PersonNumber: 0, //Mock
      SilentEnrolment: false, //Mock
      VoterRegistration: new Date(), //Mock
      UpdateDate: new Date(), //Mock
    };
    setContactInfo(mockContactInfo);
  });

  if (isNew) {
    return (
      <NewManageContact
        setIsLoading={setIsLoading}
        contactInfo={new ContactRegisterForm()}
      />
    );
  }

  return (
    <ExistManageContact
      setIsLoading={setIsLoading}
      isLoading={isLoading}
      contactInfo={contactInfo}
    />
  );
});
