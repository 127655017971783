import { DATE_FORMAT } from "@common/constants/common-format";

export const schedulerMessage = {
  scheduler: {
    allDay: "All day",
  },
};
export const schedulerDateFormat = {
  format1: {
    selectedDateFormat: `{0:${DATE_FORMAT.DATE}} - {1:${DATE_FORMAT.DATE}}`,
    selectedShortDateFormat: `{0:${DATE_FORMAT.DATE}} - {1:${DATE_FORMAT.DATE}}`,
  },
  format2: {
    selectedDateFormat: `{0:${DATE_FORMAT.LONG_DATE}}`,
    selectedShortDateFormat: `{0:${DATE_FORMAT.LONG_DATE}}`,
  },
};
