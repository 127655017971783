import { Documents } from "@app/core/documents/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { CommunicationTemplateForm } from "@common/pages/settings/communication/template/_id/components/child-screens/general/_index";
import { CommunicationTemplateTabDetails } from "@common/pages/settings/communication/template/_id/components/reference-sidebar/detail/_index";
import { CommunicationTemplateHistoryTab } from "@common/pages/settings/communication/template/_id/components/reference-sidebar/history/_index";
import { useCommunicationTemplateStore } from "@common/pages/settings/communication/template/_id/store";
import { SecurityOrgStructureSubmitActions } from "@common/pages/settings/security/org-structure/_id/model";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedCommunicationTemplate = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();

  const {
    isLoading,
    responseLoadError,
    loadCommunicationTemplate,
    onSubmit,
    communicationTemplate,
    communicationTemplateId,
  } = useCommunicationTemplateStore();

  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        communicationTemplate?.CommunicationTemplate_ID,
        communicationTemplate?.CommunicationTemplate_Name,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Settings - Communication - Template - ${
        communicationTemplate?.CommunicationTemplate_ID ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_CommunicationTemplate,
      Record_ID: communicationTemplateId ?? 0,
    },
  ];

  const listPanelBar: ITabProps[] = [
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents
          id={communicationTemplateId}
          recordType={RECORDTYPE.CORE_CommunicationTemplate}
        />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Communication Template"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCommunicationTemplate(parseInt(id), isNew)}
        />
      ) : (
        <>
          <FormTitle
            title={communicationTemplate?.CommunicationTemplate_Name || ""}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                name={SecurityOrgStructureSubmitActions.Save}
                onClick={onSubmit}
              />,
              // use for create portal
              <div id="add-attachment-button"></div>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {communicationTemplate && (
                  <>
                    <CommunicationTemplateForm />
                    <TabTable
                      id={communicationTemplateId}
                      recordType={RECORDTYPE.CORE_CommunicationTemplate}
                      initialPanels={listPanelBar}
                    />
                  </>
                )}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <CommunicationTemplateTabDetails />,
                    },
                    {
                      title: "History",
                      component: <CommunicationTemplateHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
