import {
  DTO_Assessment_Transactions,
  DTO_Assessment_TransactionViews,
} from "@app/products/property/assessments/[id]/components/child-screens/transactions/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";

export const getAssessmentTransactionsViews = async (): Promise<
  APIResponse<DTO_Assessment_TransactionViews | undefined>
> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Assessment_TransactionViews>(
        `/api/property/internal/assessmenttransactionviews`
      );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAssessmentTransactions = async (
  assessmentId: number,
  year: number,
  view: number,
  showCancelledTransactions: boolean,
  loadAllocation: boolean = false
): Promise<DTO_Assessment_Transactions | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Assessment_Transactions>(
        `/api/property/internal/assessment/${assessmentId}/transactions`,
        {
          params: {
            year,
            view,
            loadAllocation,
            showCancelledTransactions,
          },
        }
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
