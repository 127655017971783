import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkListViewDisplayName = () => {
  return "Settings - Lookups";
};

export const getBookmarkListViewDetail = () => {
  return "Settings - Lookups - Documents";
};

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `Settings - Documents ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([selectedRow.ID + ", " + selectedRow.Name]);
};
