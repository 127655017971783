import { PrivateRoute } from "@/AppRoutes";
import { ProductRouterSwitch } from "@/ProductRouterSwitch";
import { contactRoute } from "@app/core/contacts/route";
import {
  convertRouteDynamic,
  IConvertRouteDynamic,
} from "@common/utils/convertRouteDynamic";
import React from "react";

export function ContactRoute(props: any) {
  return (
    <ProductRouterSwitch>
      {contactRoute.children &&
        convertRouteDynamic(contactRoute.children).map(
          (route: IConvertRouteDynamic, index: number): JSX.Element => {
            return (
              route.component && (
                <PrivateRoute
                  exact
                  key={index}
                  path={`${props.match.url}/${route.path}`}
                  component={route.component}
                />
              )
            );
          }
        )}
    </ProductRouterSwitch>
  );
}
