import { Event, EventMapObj } from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCLabel } from "@components/cc-label/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const ATTACHMENT_STEP = "AttachmentStep";

const nameOfEventMapObj = nameOfFactory<EventMapObj>();
const nameOfEvent = nameOfFactory<Event>();

export const AttachmentStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(({ formRenderProps }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;

  const { isLoadingSubmitSimpleEvent } = useCRMSEventStore();

  const { settings } = useCommonCoreStore();

  const filterTypesStr: string =
    settings[ECorporateSettingsField.CorporateSettings_FileExtension]?.Value;

  const isEnableAttachmentSizeRestriction = getBoolValueSetting(
    settings[ECorporateSettingsField.Global_EnableAttachmentSizeRestriction]
  );

  const attachmentSizeRestrictionSize = getNumberValueSetting(
    settings[ECorporateSettingsField.Global_AttachmentSizeRestrictionSize]
  );

  const allowedExtensions = useMemo(() => {
    if (!isEmpty(filterTypesStr)) {
      return filterTypesStr
        .toUpperCase()
        .split("|")
        .map((item) => item.replace(".", "").replace("*", ""));
    }
  }, [filterTypesStr]);

  const maxFileSize = useMemo(() => {
    if (isEnableAttachmentSizeRestriction && attachmentSizeRestrictionSize) {
      return attachmentSizeRestrictionSize;
    }
  }, [attachmentSizeRestrictionSize, isEnableAttachmentSizeRestriction]);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Add Attachment(s) File" />
          <Field
            name={`${nameOfEventMapObj("Event")}.${nameOfEvent("Attachment")}`}
            component={CCUploadFile}
            autoUpload={false}
            batch={false}
            multiple
            defaultFiles={[]}
            withCredentials={false}
            files={valueGetter(
              `${nameOfEventMapObj("Event")}.${nameOfEvent("Attachment")}`
            )}
            disabled={isLoadingSubmitSimpleEvent}
            restrictions={{
              allowedExtensions: allowedExtensions,
              maxFileSize: maxFileSize,
            }}
            onAdd={(event: UploadOnAddEvent) => {
              onChange(
                `${nameOfEventMapObj("Event")}.${nameOfEvent("Attachment")}`,
                {
                  value: event.newState,
                }
              );
            }}
            onRemove={(event: UploadOnRemoveEvent) => {
              onChange(
                `${nameOfEventMapObj("Event")}.${nameOfEvent("Attachment")}`,
                {
                  value: event.newState,
                }
              );
            }}
            showActionButtons={false}
          />
        </div>
      </div>
    </section>
  );
});
