import { colLocalLawsPermitsBy } from "@app/products/local-laws/permits/config";
import { Svc_LLPermit } from "@app/products/local-laws/permits/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_LLPermit>();
export const colLocalLawsPermitsByArea: IColumnFields[] = [
  ...colLocalLawsPermitsBy,
  { field: nameOf("Area"), title: "Area" },
];
