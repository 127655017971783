import { IAccountNames } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/names/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IAccountNames>();

export const colAccountNames: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Role"),
    title: "Role",
  },
  {
    field: nameOf("Address"),
    title: "Address",
  },
  {
    field: nameOf("EntityNameAddrId"),
    title: "Entity Name/Addr ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const fieldName = {
  Names: "Names",
  NameSelected: "NamesSelected",
  SearchName: "SearchName",
  Role: "Role",
  Name: "Name",
  Address: "Address",
  FormattedNameAndAddress: "FormattedNameAndAddress",
  PrimaryKey: "EntityNameAddrId",
};

export const listSearchOptions: IKeyValuePacket[] = [
  { Key: 1, Value: "Keyword" },
  { Key: 2, Value: "Soundex" },
  { Key: 3, Value: "Only Active Entities" },
  { Key: 4, Value: "Include Superseded" },
  { Key: 5, Value: "Include Obsolete" },
];
