import { eventEmitter } from "@/App";
import { postKennelIssueFeeBatch } from "@app/products/animals/kennels-renewals/components/button/issue-renewal-fees/api";
import { isSuccessResponse } from "@common/apis/util";

import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface IKennelIssueRenewalFeesSubButtonProps {
  isAllRecords?: boolean;
  isDisabled?: boolean;
}

export const KennelIssueRenewalFeesSubButton = observer(
  ({
    isDisabled = false,
    isAllRecords = false,
  }: IKennelIssueRenewalFeesSubButtonProps) => {
    const { isEmptyGridSelectedRow, gridSelectedIds, clearSelectedItems } =
      useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleOnClick = async () => {
      setIsSubmitting(true);
      const response = await postKennelIssueFeeBatch(
        isAllRecords ? [] : gridSelectedIds,
        isAllRecords
      );
      setIsSubmitting(false);

      if (isSuccessResponse(response)) {
        clearSelectedItems();
        eventEmitter.emit(CCGridEventType.RefreshOData);
      } else {
        pushNotification({
          autoClose: false,
          type: "error",
          title: "Issue renewal fee failed.",
          description: response.data?.Errors ?? response.error,
        });
      }
    };

    // loading when call API
    if (isSubmitting) return <Loading isLoading={isSubmitting} isFullScreen />;

    return (
      <CCNavButton
        title={isAllRecords ? "All records" : "Selected records"}
        disabled={(isEmptyGridSelectedRow && !isAllRecords) || isDisabled}
        onClick={handleOnClick}
      />
    );
  }
);
