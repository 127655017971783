import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export default ({ formRenderProps }: ICommonFormProps) => {
  const { configData } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();

  const { valueGetter } = formRenderProps;
  const fieldExternalDocument = valueGetter(
    `${ECorporateSettingsField.CorporateSettings_CouncilDetails}`
  );
  if (!configData) return <></>;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField.CorporateSettings_HideDocumentCategory
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField.CorporateSettings_EnableManageDocuments
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField.CorporateSettings_EnableExternalDocument
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
        {fieldExternalDocument && (
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField.CorporateSettings_ExternalDocumentLabel
              ]
            }
            isEditing={isEditing}
          />
        )}
      </div>
    </section>
  );
};
