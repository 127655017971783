import { IDataForRejectDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ConfirmRejectDialogStore {
  private _dataForRejectDialog?: IDataForRejectDialog = undefined;
  private _isLoadingReject: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get dataForRejectDialog(): IDataForRejectDialog | undefined {
    return this._dataForRejectDialog;
  }
  setDataForRejectDialog = (dataForRejectDialog?: IDataForRejectDialog) => {
    runInAction(() => {
      this._dataForRejectDialog = dataForRejectDialog;
    });
  };

  get isLoadingReject(): boolean {
    return this._isLoadingReject;
  }
  setIsLoadingReject = (isLoadingReject: boolean) => {
    runInAction(() => {
      this._isLoadingReject = isLoadingReject;
    });
  };
  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoadingReject = false;
      this._dataForRejectDialog = undefined;
    });
  };
}

const confirmRejectDialogStoreContext = createContext(
  new ConfirmRejectDialogStore()
);
export const useConfirmRejectDialogStore = () => {
  return useContext(confirmRejectDialogStoreContext);
};
