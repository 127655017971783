import {
  getChargeBalancesData,
  getChargeInstalmentData,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/components/form-elements/charge-balance-adjustments/util";
import {
  colChargeBalances,
  colInstalmentSummary,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/components/form-elements/charges/config";
import {
  DTO_AssessmentCharge,
  DTO_AssessmentChargeBalances,
  DTO_ChargeInstallments,
  EKeysOfStepsAdjustChargeBalances,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/model";
import { nameOfFactory } from "@common/utils/common";
import { ccCurrency } from "@common/utils/currency";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { FieldArray } from "@progress/kendo-react-form";
import { get, sumBy } from "lodash";
import React, { useCallback, useMemo } from "react";

const nameOfAssessmentChargeBalances =
  nameOfFactory<DTO_AssessmentChargeBalances>();
const nameOfChargeInstallments = nameOfFactory<DTO_ChargeInstallments>();

export const ChargeBalanceAdjustmentsFormStep = (props: IFormStepElement) => {
  const isValid = useMemo(() => {
    // Get Charge Balances data list
    const assessmentChargeBalancesData = getChargeBalancesData(
      props.formRenderProps.valueGetter(
        EKeysOfStepsAdjustChargeBalances.AssessmentCharges
      )
    );

    const chargeInstalmentData = getChargeInstalmentData(
      props.formRenderProps.valueGetter(
        EKeysOfStepsAdjustChargeBalances.AssessmentCharges
      )
    );

    const chargeBalancesGridValue = sumBy(
      assessmentChargeBalancesData,
      function (chargeData) {
        const getValueChargeBalances = (
          field: keyof DTO_AssessmentChargeBalances
        ) => ccCurrency(get(chargeData, field, 0));

        const amendedBalance = getValueChargeBalances("CB_AmendedBalance");
        const amendedInterest = getValueChargeBalances("CB_AmendedInterest");
        const currentBalance = getValueChargeBalances("CB_Balance");
        const currentInterest = getValueChargeBalances("CB_Interest");

        //In the Charge Balances table -> Sum of Current Balance + Current Interest = Sum of Amended Balance + Amended Interest
        return currentBalance
          .add(currentInterest)
          .subtract(amendedBalance.add(amendedInterest)).value;
      }
    );
    const chargeInstalmentGridValue = sumBy(
      chargeInstalmentData,
      function (instalmentData) {
        const getValueInstalment = (field: keyof DTO_ChargeInstallments) =>
          ccCurrency(get(instalmentData, field, 0));

        const newBalance = getValueInstalment("New_Balance");
        const newInterest = getValueInstalment("New_Interest");
        const currentBalance = getValueInstalment("Current_Balance");
        const currentInterest = getValueInstalment("Current_Interest");
        //In the Instalment Summary table → Sum of Current Balance + Current Interest = Sum of New Balance + New Interest
        return currentBalance
          .add(currentInterest)
          .subtract(newBalance.add(newInterest)).value;
      }
    );

    // total Amend Balance by + total Amend Interest by === 0 => allow next step, otherwise not allow
    return (
      ccCurrency(chargeBalancesGridValue).value === 0 &&
      ccCurrency(chargeInstalmentGridValue).value === 0
    );
  }, [props.formRenderProps]);

  const amendBalanceValidator = useCallback(() => {
    return isValid ? "" : "Invalid step";
  }, [isValid]);

  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={
        !props?.options?.isReadOnly ? amendBalanceValidator : undefined
      }
    />
  );
};

const FormStepElement = ({ formRenderProps }: IFormStepElement) => {
  const { valueGetter } = formRenderProps;
  const assessmentChargeBalancesData = getChargeBalancesData(
    valueGetter(EKeysOfStepsAdjustChargeBalances.AssessmentCharges)
  );
  const chargeInstallmentsData = valueGetter(
    EKeysOfStepsAdjustChargeBalances.AssessmentCharges
  )
    ?.filter((assessmentCharge: DTO_AssessmentCharge) =>
      assessmentCharge?.AssessmentChargeBalances?.some(
        (assessmentChargeBalance: DTO_AssessmentChargeBalances) =>
          assessmentChargeBalancesData?.includes(assessmentChargeBalance)
      )
    )
    ?.flatMap(
      (assessmentCharge: DTO_AssessmentCharge) =>
        assessmentCharge?.Charge_Installments
    );

  return (
    <section className="cc-field-group">
      <div className="cc-field">
        <CCLabel
          title="Charge Balances"
          informationTooltip="Total Amended Balance and Amended Interest must be equal $0.00"
        />
        <CCGrid
          data={assessmentChargeBalancesData}
          columnFields={colChargeBalances}
          primaryField={nameOfAssessmentChargeBalances("Charge_Balances_Id")}
          state={{
            sort: [
              {
                field: nameOfAssessmentChargeBalances("CI_Instalment_Number"),
                dir: "asc",
              },
            ],
          }}
          readOnly
        />
      </div>
      <div className="cc-field">
        <label className="cc-label">Instalment Summary</label>
        <CCGrid
          data={chargeInstallmentsData}
          columnFields={colInstalmentSummary}
          primaryField={nameOfChargeInstallments("Installment_No")}
          state={{
            sort: [
              { field: nameOfChargeInstallments("Installment_No"), dir: "asc" },
            ],
          }}
          readOnly
        />
      </div>
    </section>
  );
};
