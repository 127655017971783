import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { Svc_SecurityTemplate } from "@common/pages/settings/security/security-templates/model";
import { getSecurityTemplateTabDetailsById } from "@common/pages/settings/security/security-templates/_id/components/reference-sidebar/detail/api";
import { useSecurityTemplateStore } from "@common/pages/settings/security/security-templates/_id/store";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const SecurityTemplateTabDetails = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { securityTemplate } = useSecurityTemplateStore();
  const [isLoading, setIsLoading] = useState(false);
  const [securityTemplateTabDetailsInfo, setSecurityTemplateTabDetailsInfo] =
    useState<Svc_SecurityTemplate>();
  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();
  const securityTemplateId =
    securityTemplate?.SecurityTemplate_ID ??
    lastSelectedRow?.SecurityTemplate_ID;

  const loadDetailData = async () => {
    setIsLoading(true);
    const response = await getSecurityTemplateTabDetailsById(
      securityTemplateId
    );
    if (isSuccessResponse(response)) {
      setSecurityTemplateTabDetailsInfo(response.data);
    } else {
      setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!securityTemplateId) return;
    loadDetailData();
    // eslint-disable-next-line
  }, [securityTemplate, securityTemplateId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => loadDetailData()}
      />
    );
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Name:"}
          value={securityTemplateTabDetailsInfo?.TemplateName}
        />
        <ProductReferenceRow
          title={"Description:"}
          value={securityTemplateTabDetailsInfo?.TemplateDescription}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
