import { CustomGridColumnMenuFilter } from "@components/cc-grid/components/grid-column-menus/column-menu-filter/components/custom-grid-column-menu-filter/_index";
import { FilterUI } from "@components/cc-grid/components/grid-column-menus/column-menu-filter/components/filter-ui/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridColumnMenuProps } from "@progress/kendo-react-grid";
import React from "react";
export interface IColumnMenuFilterProps {
  columnMenuProps: GridColumnMenuProps;
  isLocked?: boolean;
  handelToggleLookColumn: () => void;
  columnConfig: IColumnFields;
}

export const ColumnMenuFilter = ({
  columnMenuProps,
  isLocked,
  handelToggleLookColumn,
  columnConfig,
}: IColumnMenuFilterProps) => {
  const { column } = columnMenuProps;
  const { filter } = column;

  const hideSecondFilter = filter !== "date";

  return (
    <div>
      <div className={"k-columnmenu-item"} onClick={handelToggleLookColumn}>
        <span
          className={isLocked ? "k-icon k-i-unlock" : "k-icon k-i-lock"}
        ></span>
        {isLocked ? "Unlock Column" : "Lock Column"}
      </div>
      <CustomGridColumnMenuFilter
        {...columnMenuProps}
        hideSecondFilter={hideSecondFilter}
        columnConfig={columnConfig}
        expanded
        filterUI={FilterUI}
      />
    </div>
  );
};
