import { APIResponse } from "@common/apis/model";
import { DTO_PIC_Transactions } from "@app/products/property/pic/[id]/components/child-screens/transactions/model";
import { CoreAPIService } from "@common/apis/coreAPIService";

export const getPicTransactions = async (
  picId: number
): Promise<APIResponse<DTO_PIC_Transactions | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_PIC_Transactions>(
      `/api/property/int/pic/${picId}/transactions`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
