import { ISchemeAccountResponse } from "@app/products/property/schemes/[id]/account/[id]/model";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCPercentInput } from "@components/cc-percent-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { ReactElement } from "react";

interface IProps {
  formRenderProps: FormRenderProps;
}

export function SchemeAccountFormElement({
  formRenderProps,
}: IProps): ReactElement {
  const nameOf = nameOfFactory<ISchemeAccountResponse>();
  const { pushNotification } = useCCAppNotificationStore();
  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }
    if (!formRenderProps.valid) return;
    pushNotification({
      title: "The form is not modified",
      type: "warning",
    });
  };

  return (
    <FormElement className="cc-e-certificate-request-form-body">
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />
      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Account</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Description</label>
                <Field
                  readOnly
                  name={nameOf("Description")}
                  placeholder={"Description"}
                  component={CCTextArea}
                  rows={3}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Comment</label>
                <Field
                  readOnly
                  name={nameOf("Comment")}
                  placeholder={"Comment"}
                  component={CCTextArea}
                  rows={3}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Check digit(s)</label>
                <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">CBA</label>
                      <Field
                        readOnly
                        name={nameOf("CBA")}
                        placeholder={"CBA"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Bpay</label>
                      <Field
                        readOnly
                        name={nameOf("BPay")}
                        placeholder={"Bpay"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Aust post</label>
                      <Field
                        readOnly
                        name={nameOf("AustPost")}
                        placeholder={"Austpost"}
                        component={CCInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Assessment's share of costs</label>
                <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Estimated costs</label>
                      <Field
                        disabled
                        name={nameOf("EstimatedCosts")}
                        component={CCCurrencyInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Estimated percentage</label>
                      <Field
                        disabled
                        name={nameOf("EstimatedPercentage")}
                        component={CCPercentInput}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Actual costs</label>
                      <Field
                        disabled
                        name={nameOf("ActualCosts")}
                        component={CCCurrencyInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Actual percentage</label>
                      <Field
                        disabled
                        name={nameOf("ActualPercentage")}
                        component={CCPercentInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Calculation</label>
                <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Size</label>
                      <Field
                        readOnly
                        name={nameOf("Size")}
                        placeholder={"Size"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Coefficient</label>
                      <Field
                        readOnly
                        name={nameOf("Coefficient")}
                        placeholder={"Coefficient"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Number of units</label>
                      <Field
                        readOnly
                        name={nameOf("NoofUnits")}
                        placeholder={"Number of units"}
                        component={CCInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Property</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Number</label>
                <Field
                  readOnly
                  name={nameOf("Number")}
                  placeholder={"Number"}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Assessment ID</label>
                <Field
                  readOnly
                  name={nameOf("AssessmentId")}
                  placeholder={"Assessment tid"}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Parcel ID</label>
                <Field
                  readOnly
                  name={nameOf("ParcelId")}
                  placeholder={"Parcel id"}
                  component={CCInput}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Property address</label>
                <Field
                  readOnly
                  name={nameOf("PropertyAddress")}
                  placeholder={"Property address"}
                  component={CCTextArea}
                  rows={3}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Legal description</label>
                <Field
                  readOnly
                  name={nameOf("LegalDescription")}
                  placeholder={"Legal description"}
                  component={CCTextArea}
                  rows={3}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Name and address</label>
                <Field
                  readOnly
                  name={nameOf("NameAndAddress")}
                  placeholder={"Name and address"}
                  component={CCTextArea}
                  rows={3}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Notice group</label>
                <Field
                  readOnly
                  name={nameOf("NoticeGroup")}
                  placeholder={"Notice group"}
                  component={CCInput}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Notices</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Hold notices until</label>
                <Field
                  readOnly
                  name={nameOf("HoldNoticesUntil")}
                  placeholder={"Hold notices until"}
                  component={CCInput}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Notice message</label>
                <Field
                  readOnly
                  name={nameOf("NoticeMessage")}
                  placeholder={"Notice message"}
                  component={CCTextArea}
                  rows={3}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Scheme</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Scheme number</label>
                <Field
                  readOnly
                  name={nameOf("SchemeNumber")}
                  placeholder={"Scheme number"}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Scheme ID</label>
                <Field
                  readOnly
                  name={nameOf("SchemeId")}
                  placeholder={"Scheme id"}
                  component={CCInput}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Initiated</label>
                <Field
                  disabled
                  name={nameOf("Initiated")}
                  placeholder={"Initiated"}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Declared</label>
                <Field
                  disabled
                  name={nameOf("Declared")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Commenced</label>
                <Field
                  disabled
                  name={nameOf("Commenced")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Estimated cost</label>
                <Field
                  disabled
                  name={nameOf("EstimatedCost")}
                  component={CCCurrencyInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Estimated subsidy</label>
                <Field
                  disabled
                  name={nameOf("EstimatedSubsidy")}
                  component={CCCurrencyInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Estimated cost to assessments
                </label>
                <Field
                  disabled
                  name={nameOf("EstimatedCostToAssessments")}
                  component={CCCurrencyInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Actual cost</label>
                <Field
                  disabled
                  name={nameOf("ActualCost")}
                  component={CCCurrencyInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Actual subsidy</label>
                <Field
                  disabled
                  name={nameOf("ActualSubsidy")}
                  component={CCCurrencyInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Actual cost to assessments</label>
                <Field
                  disabled
                  name={nameOf("ActualCostToAssessments")}
                  component={CCCurrencyInput}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Comments</label>
                <Field
                  readOnly
                  name={nameOf("Comments")}
                  placeholder={"Comments"}
                  component={CCTextArea}
                  rows={3}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Last changed</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">On</label>
                <Field
                  disabled
                  name={nameOf("LastChangedOn")}
                  placeholder={"Last changed on"}
                  component={CCDatePicker}
                  format={DATETIME_FORMAT.DATETIME_CONTROL}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">By</label>
                <Field
                  readOnly
                  name={nameOf("LastChangedBy")}
                  placeholder={"Last changed by"}
                  component={CCInput}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </FormElement>
  );
}
