import { isSuccessResponse } from "@common/apis/util";
import { getAnimalsDashboard } from "@common/pages/home/components/animals/api";
import { AnimalsDashboard } from "@common/pages/home/components/animals/model";

import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class AnimalsDashboardStore {
  private _isLoadingAnimalsDashboard: boolean = false;
  private _animalDashboard?: AnimalsDashboard = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoadingAnimalsDashboard() {
    return this._isLoadingAnimalsDashboard;
  }
  setIsLoadingAnimalsDashboard = (isLoadingAnimalsDashboard: boolean) => {
    runInAction(() => {
      this._isLoadingAnimalsDashboard = isLoadingAnimalsDashboard;
    });
  };

  get animalDashboard() {
    return toJS(this._animalDashboard);
  }
  setAnimalDashboard = (animalDashboard?: AnimalsDashboard) => {
    runInAction(() => {
      this._animalDashboard = animalDashboard;
    });
  };

  loadAnimalsDashboard = async () => {
    this.setIsLoadingAnimalsDashboard(true);
    const response = await getAnimalsDashboard();
    this.setIsLoadingAnimalsDashboard(false);

    if (isSuccessResponse(response) && response?.data) {
      this.setAnimalDashboard(response.data);
    } else {
      appNotificationStore.pushNotification({
        title: response?.error ?? "Load animals dashboard failed",
        type: "error",
        autoClose: false,
      });
    }
  };
}

export const animalsDashboardStore = new AnimalsDashboardStore();
const animalsDashboardContext = createContext(animalsDashboardStore);
export const useAnimalsDashboardStore = () => {
  return useContext(animalsDashboardContext);
};
