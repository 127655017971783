import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { GroupsGrid } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/groups-grid/_index";
import { colGroups } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/groups-grid/config";
import { IGroup } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/groups-grid/model";
import { OrgStructureGrid } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/org-structure-grid/_index";
import { UsersGrid } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/users-grid/_index";
import { colUsers } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/users-grid/config";
import { IUsers } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/users-grid/model";
import { initialValuesForm } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/config";
import {
  ConfigUserGroupOrg,
  IDataUserGroupOrgForm,
} from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/model";

import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import React, { useState } from "react";
export const nameOfUsers = nameOfFactory<IUsers>();
export const nameOfGroups = nameOfFactory<IGroup>();
interface IPickUserGroupOrgProps {
  handleSubmit: (value: IDataUserGroupOrgForm) => void;
  onClose: () => void;
  maxHeight?: string;
}

export const PickUserGroupOrg = ({
  maxHeight,
  handleSubmit,
  onClose,
}: IPickUserGroupOrgProps) => {
  const [selectedUsers, setSelectedUser] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedOrgStructure, setSelectedOrgStructure] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const [userState, setUserState] = useState<State>();
  const [orgState, setOrgState] = useState<State>();
  const [groupState, setGroupState] = useState<State>();

  return (
    <div className="cc-form">
      <Form
        initialValues={initialValuesForm}
        onSubmit={(values) => handleSubmit(values as IDataUserGroupOrgForm)}
        render={({ onSubmit, onChange }: FormRenderProps) => {
          if (
            selectedUsers.length ||
            selectedGroups.length ||
            selectedOrgStructure.length
          ) {
            setValidForm(true);
          } else {
            setValidForm(false);
          }

          const handleChangeUsers = (data: any) => {
            if (data.length) {
              setSelectedUser(data);
              onChange(ConfigUserGroupOrg.Users, { value: data });
            } else {
              setSelectedUser([]);
              onChange(ConfigUserGroupOrg.Users, { value: [] });
            }
          };
          const handleChangeGroups = (data: any) => {
            if (data.length) {
              setSelectedGroups(data);
              onChange(ConfigUserGroupOrg.Groups, { value: data });
            } else {
              onChange(ConfigUserGroupOrg.Groups, { value: [] });
              setSelectedGroups([]);
            }
          };
          const handleChangeOrgStructure = (data: any) => {
            if (data.length) {
              setSelectedOrgStructure(data);
              onChange(ConfigUserGroupOrg.OrgStructure, { value: data });
            } else {
              setSelectedOrgStructure([]);
              onChange(ConfigUserGroupOrg.OrgStructure, { value: [] });
            }
          };

          const listPanelBar: ITabProps[] = [
            {
              title: "Users",
              isExpanded: true,
              isKeepMounted: true,
              component: (
                <>
                  <UsersGrid
                    handleSelectionChange={handleChangeUsers}
                    columnFields={colUsers}
                    primaryField={nameOfUsers("ID")}
                    selectableMode="multiple"
                    selectedRows={selectedUsers ?? []}
                    onDataStateChange={(state: State) => {
                      setUserState(state);
                    }}
                    state={userState}
                  />
                </>
              ),
            },
            {
              title: "Groups",
              isKeepMounted: true,
              component: (
                <GroupsGrid
                  handleSelectionChange={handleChangeGroups}
                  columnFields={colGroups}
                  primaryField={nameOfGroups("ID")}
                  selectableMode="multiple"
                  selectedRows={selectedGroups ?? []}
                  onDataStateChange={(state: State) => {
                    setGroupState(state);
                  }}
                  state={groupState}
                />
              ),
            },
            {
              title: "Org Structure",
              isKeepMounted: true,
              component: (
                <OrgStructureGrid
                  handleSelectionChange={handleChangeOrgStructure}
                  columnFields={colGroups}
                  primaryField={nameOfGroups("ID")}
                  selectableMode="multiple"
                  selectedRows={selectedOrgStructure ?? []}
                  onDataStateChange={(state: State) => {
                    setOrgState(state);
                  }}
                  state={orgState}
                />
              ),
            },
          ];
          return (
            <CCDialog
              height="auto"
              maxHeight={maxHeight}
              width="50%"
              unsetMinHeight={true}
              titleHeader={"Select Users, Groups and Org Structures"}
              onClose={onClose}
              bodyElement={
                <TabTable
                  recordType={RECORDTYPE.CORE_Report}
                  initialPanels={listPanelBar}
                />
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={onSubmit}
                    disabled={!validForm}
                  >
                    Add
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    </div>
  );
};
