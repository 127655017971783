import { IColumnFields } from "@components/cc-grid/model";

export const colMasterPropertyAttributes: IColumnFields[] = [
  {
    field: "Attribute",
    title: "Attribute",
  },
  {
    field: "Value",
    title: "Value",
  },
];
