import { CCDialog } from "@components/cc-dialog/_index";
import { Button } from "@progress/kendo-react-buttons";
import { GridColumn as Column, Grid } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import React from "react";
import "./_index.scss";

export const PickAddressDialog = (props: any) => {
  return (
    <CCDialog
      maxWidth="50%"
      maxHeight="60%"
      titleHeader={props.titleHeader}
      onClose={props.onCloseDialog}
      bodyElement={
        <div className="cc-pick-address-dialog">
          <div className="cc-pick-address-form-row">
            <div className="cc-pick-address-field">
              <label className="cc-label">Search</label>
              <Input className="cc-form-control" />
            </div>

            <div className="cc-pick-address-field-submit">
              <Button themeColor="primary">Find</Button>
            </div>
          </div>

          <Grid
            className="cc-grid-view"
            sortable={true}
            pageable={true}
            resizable
            selectedField="selected"
            filterOperators={{
              text: [
                {
                  text: "grid.filterContainsOperator",
                  operator: "contains",
                },
              ],
              numeric: [{ text: "grid.filterEqOperator", operator: "eq" }],
              date: [
                { text: "grid.filterAfterOperator", operator: "gt" },
                { text: "grid.filterBeforeOperator", operator: "lt" },
              ],
            }}
          >
            <Column
              className="cc-grid-col"
              field="Street"
              title="Street"
              width={200}
            />
            <Column
              className="cc-grid-col"
              field="Locality"
              title="Locality"
              width={200}
            />
            <Column
              className="cc-grid-col"
              field="Postcode"
              title="Postcode"
              width={100}
            />
            <Column
              className="cc-grid-col"
              field="State"
              title="State"
              width={100}
            />
            <Column
              className="cc-grid-col"
              field="Ward"
              title="Ward"
              width={100}
            />
          </Grid>
        </div>
      }
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={props.onCloseDialog}>
            Cancel
          </Button>
          <Button className="cc-dialog-button" themeColor="primary">
            {props.isMultiSelect ? `Multi Select` : "Select"}
          </Button>
        </div>
      }
    />
  );
};
