import { mockInstallations } from "@app/products/property/meters/[id]/components/child-screens/installation/mock";
import {
  AssociationMeter,
  IInstallations,
} from "@app/products/property/meters/[id]/components/child-screens/installation/model";
import { getUUID, sleep } from "@common/utils/common";

export const loadMeterInstallation = async () => {
  const dataResponse = mockInstallations;
  await sleep(1000);
  return dataResponse as IInstallations;
};

export const addAssociationMeterAPI = async (
  currentAssociationMeter: AssociationMeter[],
  newMeterAssociation: AssociationMeter
) => {
  let newAssociationMeter = [...currentAssociationMeter];
  newMeterAssociation.Id = getUUID();
  newAssociationMeter.unshift(newMeterAssociation);
  await sleep(1000);
  return newAssociationMeter;
};

export const removeAssociationMeterAPI = async (
  currentAssociationMeter: AssociationMeter[],
  selectedAssociationMeter: AssociationMeter[]
) => {
  let newAssociationMeter = [...currentAssociationMeter];
  selectedAssociationMeter.forEach((item) => {
    const dataIndex = newAssociationMeter.findIndex(
      (meter) => meter.Id === item.Id
    );
    if (dataIndex !== -1) {
      newAssociationMeter[dataIndex] = {
        ...newAssociationMeter[dataIndex],
        AssociatedTo: item.AssociatedTo,
        isDisabledRow: true,
      };
    }
  });

  await sleep(1000);
  return newAssociationMeter;
};
