import { localityUsedFields } from "@app/products/csl/[id]/components/child-screens/general/components/form-element/config";
import { CSLRegisterForm } from "@app/products/csl/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";
export const CSLFormBodySIDL = (formRenderProps: FormRenderProps) => {
  const getValue = formRenderProps.valueGetter;
  const nameOf = nameOfFactory<CSLRegisterForm>();

  return (
    <>
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">
            Location/Site used
            <CCTooltip type="validator" position="right" />
          </label>
          <Field
            component={InputPicker}
            {...localityUsedFields(formRenderProps)}
            name={"LocalityUsed"}
            validator={requiredValidator}
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Drugs possessed</label>
          <Field
            rows={5}
            name={"DrugsPossessed"}
            value={getValue(nameOf("DrugsPossessed"))}
            component={CCTextArea}
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            Purpose of
            <CCTooltip type="validator" position="right" />
          </label>
          <Field
            rows={5}
            name={nameOf("PurposeOf")}
            value={getValue(nameOf("PurposeOf"))}
            component={CCTextArea}
          />
        </div>
      </div>
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">
            Pharmacy
            <CCTooltip type="validator" position="right" />
          </label>
          <Field
            component={InputPicker}
            {...localityUsedFields(formRenderProps)}
            name={"LocalityUsed"}
            validator={requiredValidator}
          />
        </div>
      </div>

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            Person application to order
            <CCTooltip type="validator" position="right" />
          </label>
          <Field
            rows={5}
            name={nameOf("PersonsApprovedToOrder")}
            value={getValue(nameOf("PersonsApprovedToOrder"))}
            component={CCTextArea}
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            Persons application to access
            <CCTooltip type="validator" position="right" />
          </label>
          <Field
            rows={5}
            name={nameOf("PersonsApprovedToAccess")}
            value={getValue(nameOf("PersonsApprovedToAccess"))}
            component={CCTextArea}
          />
        </div>
      </div>
    </>
  );
};
