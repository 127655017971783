import { ICCRoute } from "@common/constants/ICCRoute";

export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  children: [
    {
      path: "system-types",
      name: "System Types",
      component: require("./system-types/_index").default,
    },
    {
      path: "settings",
      name: "Settings",
      component: require("./settings/_index").default,
    },
    {
      path: "conditions",
      name: "Conditions",
      component: require("./conditions/_index").default,
    },
  ],
};
