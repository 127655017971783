import React from "react";
import "./ProductReferenceBody.scss";
export const ProductReferenceBody = (props: any) => {
  return <div className="cc-ref-body">{props.children}</div>;
};

export const ProductReferenceBlock = (props: any) => {
  return (
    <div className={`cc-ref-block ${props.flex ? " cc-ref-block-flex" : ""}`}>
      {props.children}
    </div>
  );
};
