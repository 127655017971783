import { AnimalsMenu } from "@app/products/animals/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const financeRoute: ICCRoute = {
  path: "finance",
  name: "Finance",
  enum: AnimalsMenu.Finance,
  children: [
    // Implement later
    // {
    //   path: "os-fees",
    //   name: "OS Fees",
    //   enum: AnimalsMenu.FeesOutstanding,
    //   component: require("./os-fees/_index").default,
    // },
    // {
    //   path: "fees-paid",
    //   name: "Fees Paid",
    //   enum: AnimalsMenu.FeesPaid,
    //   component: require("./fees-paid/_index").default,
    // },
    {
      path: "fees-all",
      name: "Fees All",
      enum: AnimalsMenu.FeesAll,
      component: require("./fees-all/_index").default,
    },
    {
      path: "all-debtor-summary",
      name: "All Debtor Summary",
      enum: AnimalsMenu.DebtorSummaryAll,
      component: require("./all-debtor-summary/_index").default,
    },
    // Implement later
    // {
    //   path: "not-sent-to-finance",
    //   name: "Not Sent To Finance",
    //   enum: AnimalsMenu.FeesNotSentToFinance,
    //   component: require("./not-sent-to-finance/_index").default,
    // },
    // {
    //   path: "sent-to-finance",
    //   name: "OS Debtor Summary",
    //   enum: AnimalsMenu.FeesSentToFinance,
    //   component: require("./sent-to-finance/_index").default,
    // },
    {
      path: "os-debtor-summary",
      name: "OS Debtor Summary",
      enum: AnimalsMenu.DebtorSummaryOutstanding,
      component: require("./os-debtor-summary/_index").default,
    },
    {
      path: "invoices-not-sent-to-finance",
      name: "Invoices Not Sent To Finance",
      enum: AnimalsMenu.InvoicesNotSentToFinance,
      component: require("./invoices-not-sent-to-finance/_index").default,
    },
    {
      path: "invoices-sent-to-finance",
      name: "Invoices Sent To Finance",
      enum: AnimalsMenu.InvoicesSentToFinance,
      component: require("./invoices-sent-to-finance/_index").default,
    },
  ],
};
