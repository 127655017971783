import { isSuccessResponse } from "@common/apis/util";
import { getCheckPermission } from "@common/pages/settings/api";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" }); // Strict mode: runInAction
class PermissionStore {
  private _hasPermission: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  get hasPermission() {
    return toJS(this._hasPermission);
  }
  setHasPermission = (hasPermission: boolean) => {
    runInAction(() => {
      this._hasPermission = hasPermission;
    });
  };

  checkPermissionForCurrentMenu = async (
    formAction: FormActionCheckPermission,
    //TODO: Need to have enum
    productType: number
  ) => {
    const response = await getCheckPermission(formAction, productType);
    if (
      isSuccessResponse(response) &&
      response?.data?.ErrorStatus === "Success"
    ) {
      return true;
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title:
          response?.data?.Errors?.[0] ??
          "An error has occurred — please try again. If the error persists, notify your system administrator.",
        type: "error",
      });
      this._hasPermission = false;
      return false;
    }
  };

  //Actions
  resetStore = () => {
    this._hasPermission = true;
  };
}
const permissionStoreContext = createContext(new PermissionStore());
export const usePermissionStore = () => {
  return useContext(permissionStoreContext);
};
