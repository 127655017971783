import { DTOBaseClass_Standard } from "@app/products/crms/[id]/model";
import { APIResponse } from "@common/apis/model";
import { ContactRelationshipType } from "@common/constants/enumerations";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const HMFieldMapping = {
  TradingName: "TradingName",
  RegNo: "RegNo",
  LocationDesc: "LocationDesc",
  StreetNo: "StreetNo",
  AddressLine1: "AddressLine1",
  Street: "Street",
  Suburb: "Suburb",
  Area: "Area",
  Premise_Home_Jurisdiction: "Premise_Home_Jurisdiction",
  PremisesClassification: "PremisesClassification",
  PremisesType: "PremisesType",
  Risk: "Risk",
  Status: "Status",
  Officer: "Officer",
  AmountOS: "AmountOS",
  RefNo: "RefNo",
  FileNumber: "FileNumber",
  DebtorNo: "DebtorNo",
  AssessmentNo: "AssessmentNo",
  Flag_MultiTenancy: "Flag_MultiTenancy",
  Flag_DeptOfEducation: "Flag_DeptOfEducation",
  HomeBusiness: "HomeBusiness",
  LastInspection: "LastInspection",
  LastCompliance: "LastCompliance",
  PreviousCategory: "PreviousCategory",
  PostalAddress: "PostalAddress",
  PostalAddress2: "PostalAddress2",
  Proprietor: "Proprietor",
  Mobile: "Mobile",
  Phone: "Phone",
  ProprietorABN: "ProprietorABN",
  ProprietorACN: "ProprietorACN",
  NoEmployees: "NoEmployees",
  PrimaryContact: "PrimaryContact",
  FSPContact: "FSPContact",
  FSP: "FSP",
  DateLodged: "DateLodged",
  DateRegistrationExpires: "DateRegistrationExpires",
  Fax: "Fax",
  Address: "AddressLine1",
  RenewalApproved: "RenewalApproved",
  Consent: "Consent",
  DateConsentGiven: "DateConsentGiven",
  Rating: "Rating",
  Score: "Score",
  SecondaryOfficer: "SecondaryOfficer",
  RatingInspectionDate: "RatingInspectionDate",
  PCBA_BodyPiercing: "PCBA_BodyPiercing",
  PCBA_BodyTreatments: "PCBA_BodyTreatments",
  PCBA_ColonicIrrigation: "PCBA_ColonicIrrigation",
  PCBA_CosmeticTattooing: "PCBA_CosmeticTattooing",
  PCBA_CuticleCutting: "PCBA_CuticleCutting",
  PCBA_Dermabrasion: "PCBA_Dermabrasion",
  PCBA_Accupuncture: "PCBA_Accupuncture",
  PCBA_EarPiercing: "PCBA_EarPiercing",
  PCBA_Electrolysis: "PCBA_Electrolysis",
  PCBA_EyebrowEyelashTinting: "PCBA_EyebrowEyelashTinting",
  PCBA_EyelashExtensions: "PCBA_EyelashExtensions",
  PCBA_Facials: "PCBA_Facials",
  PCBA_FacialsMakeup: "PCBA_FacialsMakeup",
  PCBA_Footscraping: "PCBA_Footscraping",
  PCBA_Footspa: "PCBA_Footspa",
  PCBA_Hairdressing: "PCBA_Hairdressing",
  PCBA_HairExtensions: "PCBA_HairExtensions",
  PCBA_HennaTattoos: "PCBA_HennaTattoos",
  PCBA_LaserTreatment: "PCBA_LaserTreatment",
  PCBA_Makeup: "PCBA_Makeup",
  PCBA_ManicurePedicure: "PCBA_ManicurePedicure",
  PCBA_NailTreatment: "PCBA_NailTreatment",
  PCBA_SprayTan: "PCBA_SprayTan",
  PCBA_Tattooing: "PCBA_Tattooing",
  PCBA_TemporaryMakeUp: "PCBA_TemporaryMakeUp",
  PCBA_Threading: "PCBA_Threading",
  PCBA_Waxing: "PCBA_Waxing",
  PCBA_Wigs: "PCBA_Wigs",
  PCBA_Other: "PCBA_Other",
  PCBA_PRP: "PCBA_PRP",
  ProprietorAddress: "ProprietorAddress",
  NoOfOccupants: "NoOfOccupants",
  NoOfBeds: "NoOfBeds",
  NoOfUnits: "NoOfUnits",
  NoPools: "NoPools",
  NoTowers: "NoTowers",
  NoSystems: "NoSystems",
  FSPTemplate: "FSPTemplate",
  EventName: "EventName",
  PeriodCommence: "PeriodCommence",
  PeriodConclude: "PeriodConclude",
  Expires: "Expires",
  VendorType: "VendorType",
  Vehicle1_Make: "Vehicle1_Make",
  Vehicle1_RegNo: "Vehicle1_RegNo",
  Vehicle1_Colour: "Vehicle1_Colour",
  Vehicle1_FoodToBeCarried: "Vehicle1_FoodToBeCarried",
  StreatraderRef: "StreatraderRef",
  Date_Closed: "Date_Closed",
  DateConditionsDue: "DateConditionsDue",
  DateConditionsComplied: "DateConditionsComplied",
  AlertText: "AlertText",
  CreatedDate: "CreatedDate",
  TOB_Retail: "TOB_Retail",
  TOB_Workplace: "TOB_Workplace",
  TOB_Restaurant: "TOB_Restaurant",
  TOB_Hotel: "TOB_Hotel",
  TOB_PublicAreas: "TOB_PublicAreas",
  Tobacco: "Tobacco",
  ECigaretteProducts: "ECigaretteProducts",
  ShishaProducts: "ShishaProducts",
  NoofPOS: "NoofPOS",
  NoofOtherPOS: "NoofOtherPOS",
  Flag_TobaccoRetail: "Flag_TobaccoRetail",
  Flag_RenewalApproved: "Flag_RenewalApproved",
  Flag_RenewalFeeCreated: "Flag_RenewalFeeCreated",
  Flag_RenewalNoticeSent: "Flag_RenewalNoticeSent",
  Flag_PenaltyFeeCreated: "Flag_PenaltyFeeCreated",
  RenewalStatus: "RenewalStatus",
  PreferredMethod: "PreferredMethod",
  LastPaidBy: "LastPaidBy",
  Flag_RenewalSentToPortal: "Flag_RenewalSentToPortal",
  Flag_RenewalSubmittedFromPortal: "Flag_RenewalSubmittedFromPortal",
  NextInspection: "NextInspection",
  DateInspected: "DateInspected",
  InspectionType: "InspectionType",
  Reason: "Reason",
  Compliance: "Compliance",
  ReferenceNumber: "ReferenceNumber",
  RiskClassification: "RiskClassification",
  NCItemLocation: "NCItemLocation",
  NCItemDescription: "NCItemDescription",
  NCItemRisk: "NCItemRisk",
  NCItemDue: "NCItemDue",
  Description: "Description",
  DateTarget: "DateTarget",
  Source: "Source",
  Category: "Category",
  Location: "Location",
  RequestedBy: "RequestedBy",
  ContactDetails: "ContactDetails",
  DueStatus: "DueStatus",
  DateRecorded: "DateRecorded",
  RecordedBy: "RecordedBy",
  OrgStructure: "OrgStructure",
  ActionOfficer: "ActionOfficer",
  Coordinator: "Coordinator",
  DateWork: "DateWork",
  EnquiryRefNo: "EnquiryRefNo",
  Event_ReferenceNoAdditional: "Event_ReferenceNoAdditional",
  OnBehalfOf: "OnBehalfOf",
  Flag_ITSupport: "Flag_ITSupport",
  Flag_Safety: "Flag_Safety",
  Flag_Risk: "Flag_Risk",
  Flag_VIP: "Flag_VIP",
  DateServed: "DateServed",
  Type: "Type",
  NoticeCategory: "NoticeCategory",
  DateDue: "DateDue",
  DateRevoked: "DateRevoked",
  DateSampled: "DateSampled",
  SampleNumber: "SampleNumber",
  SampleType: "SampleType",
  Survey: "Survey",
  ProductName: "ProductName",
  Title: "Title",
  Number: "Number",
  EnforcementAgency: "EnforcementAgency",
  Outcome: "Outcome",
  Date_Start: "Date_Start",
  Date_Completetion: "Date_Completetion",
  LastSampled: "LastSampled",
  Date_Recall: "Date_Recall",
  ProductType: "ProductType",
  FeeType: "FeeType",
  AmountIssued: "AmountIssued",
  AmountOutstanding: "AmountOutstanding",
  CostCode: "CostCode",
  InvoiceNumber: "InvoiceNumber",
  DebtorNumber: "DebtorNumber",
  SecurePay_Reference: "SecurePay_Reference",
  AmountPaid: "AmountPaid",
  ReceiptNumber: "ReceiptNumber",
  ReceiptDate: "ReceiptDate",
  ReceiptedBy: "ReceiptedBy",
  Method: "Method",
  SentToFinanceDate: "SentToFinanceDate",
  SentToFinanceBatch: "SentToFinanceBatch",
  DateStarted: "DateStarted",
  Quarter: "Quarter",
  Result: "Result",
  LogStatus: "LogStatus",
  TransmissionNumber: "TransmissionNumber",
  NoOfParts: "NoOfParts",
  PremisesCount: "PremisesCount",
  AuditCount: "AuditCount",
  InspectionCount: "InspectionCount",
  EnforcementCount: "EnforcementCount",
  ProsecutionCount: "ProsecutionCount",
  VerdictCount: "VerdictCount",
  ComplaintCount: "ComplaintCount",
  RecallCount: "RecallCount",
  RunBy: "RunBy",
  Name: "Name",
  Flag_FSR: "Flag_FSR",
  FSR_BusinessClass_Name: "FSR_BusinessClass_Name",
  FSR_BusinessClass_ENUM: "FSR_BusinessClass_ENUM",
  FSR_PremisesType_Name: "FSR_PremisesType_Name",
  FSR_PremisesType_ENUM: "FSR_PremisesType_ENUM",
  Classification: "Classification",
  Group: "Group",
  FSRType: "FSRType",
  FSRCode: "FSRCode",
  FeeAmount: "FeeAmount",
  PenaltyUnits: "PenaltyUnits",
  SubCategory: "SubCategory",
  KeywordType_Name: "KeywordType_Name",
  IsActive: "IsActive",
  SortIndex: "SortIndex",
  Code: "Code",
  Contacted: "Contacted",
  Licenced: "Licenced",
  EatIn: "EatIn",
  VendingMachine: "VendingMachine",
  Gaming: "Gaming",
  Retail: "Retail",
  Workplace: "Workplace",
  NonGaming: "NonGaming",
  RenewalMethod_Name: "RenewalMethod_Name",
  InspectionFrequency: "InspectionFrequency",
  RegistrationFee_Amount: "RegistrationFee_Amount",
  RenewalFee_Amount: "RenewalFee_Amount",
  TransferFee_Amount: "TransferFee_Amount",
  Flag_NoOfEmployees: "Flag_NoOfEmployees",
  Flag_NoOfUnits: "Flag_NoOfUnits",
  Flag_NoOfBeds: "Flag_NoOfBeds",
  Flag_TemporaryPremises: "Flag_TemporaryPremises",
  Flag_FoodVehicle: "Flag_FoodVehicle",
  Flag_FoodPremises: "Flag_FoodPremises",
  Flag_TobaccoPremises: "Flag_TobaccoPremises",
  Is_DrinkingWater: "Is_DrinkingWater",
  LowerLimit: "LowerLimit",
  UpperLimit: "UpperLimit",
  Identifier: "Identifier",
  Category_1: "Category_1",
  Category_2: "Category_2",
  Category_3: "Category_3",
  Category_4: "Category_4",
  Category_5: "Category_5",
  Category_6: "Category_6",
  ObservationSummary: "ObservationSummary",
  ComplianceInstruction: "ComplianceInstruction",
  AFSAQuestion: "AFSAQuestion",
  FoodActSection: "FoodActSection",
  FSANZClause: "FSANZClause",
  FoodSafetyRatingQuestion_ID: "FoodSafetyRatingQuestion_ID",
  Comment: "Comment",
  Question_IDS: "Question_IDS",
  DeletedOn: "DeletedOn",
  DeletedBy: "DeletedBy",
  // TODO hand added
  PropertyName: "PropertyName",
  DateInspectedMonth: "DateInspectedMonth",
  ClosureType: "ClosureType",
  Proprietor_RID: "Proprietor_RID",
  PremisesType_ID: "PremisesType_ID",
  BPayReference: "BPayReference",
  Flag_Streatrader: "Flag_Streatrader",
  Flag_FastChoice: "Flag_FastChoice",
  DisplayName: "DisplayName",
  HealthArea_KWD: "HealthArea_KWD",
  FSPTemplate_KWD: "FSPTemplate_KWD",
  Email: "Email",
};

export class InspectionRespone {
  ID: number = 0;
  InspectionType_ID: number = 0;
  InspectionType: string = "";
  Officer?: Officer;
  SecondaryOfficer_ID: number = 0;
  SecondaryOfficer?: Officer;
  Officer_ID: number = 0;
  Compliance: string = "";
  Reason: string = "";
  Compliance_ID: number = 0;
  Reason_ID: number = 0;
  DateInspected: string = "";
  TimeStarted: string = "";
  TimeCompleted: string = "";
  AssessmentRating: number = 0;
  ReferenceNumber: string = "";
  AffectPlannedSchedule?: boolean;
  parent?: Parent;
  FoodSafeScore: number = 0;
  Description: string = "";
  RelatedInspection_ID: number = 0;
  RelatedAction_ID: number = 0;
  RelatedNotice_ID: number = 0;
  Position: number = 0;
}
export interface Officer {
  _ProductRole: any[];
  _LoginProviders: any[];
  _Products: any[];
  _CorporateAuths: any[];
  _SecurityTemplates: any[];
  _Special_SecurityTemplates: any[];
  _OrgUnits: any[];
  _SecurityOrgUnits: any[];
  _HomePageFunctions: any[];
  _CorpAuth_HomePageFunctions: any[];
  _HomePageViews: any[];
  _CorpAuth_HomePageViews: any[];
  Sys_TimeStamp: string;
  _WorkPhone: string;
  _HomePhone: string;
  _Fax: string;
  _Mobile: string;
  _ExtensionType: string[];
  _SaveTriggers: any[];
  MemberUserName: string;
  MemberGUID: string;
  EnterpriseRole_ENUM: string;
  PositionName: string;
  Flag_CRMS_StandardForm: boolean;
  Flag_CRMS_Escalation_ActionOfficer_OutstandingNotifications: boolean;
  ClickOnceVersion: string;
  bResetPasswordOnFirstLogon: boolean;
  EnterpriseKey_Set: string;
  EnterpriseRole_Set: string;
  DocMgmt_Username: string;
  DocMgmt_Password: string;
  Contact_ID: number;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  ContactClassification_ENUM: string;
  PreferredMethod_ENUM: string;
  Flag_SendSMSAlerts: boolean;
  Email: string;
  ContactRoles_Count: number;
  AddressBook: AddressBook;
  AddressBookFlag_ENUM: string;
  CopiedFrom_Contact_ID: number;
  SynchDate: Date;
  Extension: Extension;
  IsContactCopied: boolean;
  ABNValid: boolean;
  ACNValid: boolean;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_StatClockStatus_ENUM: string;
  _PreferredMethod_Formatted?: string;
  Flag_Duplicate?: boolean;
}
export interface AddressBook {
  Sys_TimeStamp: string;
  _ExtensionType: any[];
  Sys_EnterpriseKey: string;
  Sys_TransactionDate: Date;
  AddressBook_ID: number;
  AddressBook_Name: string;
  AddressBookSection_ENUM: string;
  ProductType_ENUM: string;
  IsSystemRecord: boolean;
}
export interface Extension {}
export interface Parent {
  _SaveTriggers: any[];
  Sys_TimeStamp: string;
  Premises_ID: number;
  RegistrationNumber: string;
  ReferenceNumber: string;
  BPayReference: string;
  FileNumber: string;
  DebtorNumber: string;
  StreatraderRef: string;
  TradingName: string;
  Comments: string;
  Conditions: string;
  Foods: string;
  Alert: string;
  BarcodeReference: string;
  Migration_ID: string;
  ClosureReason: string;
  Date_Lodged: Date;
  Date_RegistrationFrom: Date;
  Date_RegistrationTo: Date;
  Date_Closed: Date;
  DW_Source_KWDs: any[];
  DW_Treatment_KWDs: any[];
  Status_ENUM: string;
  WasteWater_ENUM: string;
  Flag_Conditional: boolean;
  Flag_RequiresCertificate: boolean;
  Flag_RenewalFeeCreated: boolean;
  Flag_PenaltyFeeCreated: boolean;
  Flag_RenewalApproved: boolean;
  Flag_Streatrader: boolean;
  Flag_PendingTransfer: boolean;
  Flag_DebtorExported: boolean;
  Flag_EatInFacility: boolean;
  Flag_OutdoorDining: boolean;
  Flag_TobaccoRetail: boolean;
  Flag_ECigaretteProducts: boolean;
  Flag_ShishaProducts: boolean;
  Flag_VendingMachine: boolean;
  Flag_LiquorLicence: boolean;
  Flag_PrivateWaterSupply: boolean;
  Flag_BingoCentre: boolean;
  Flag_LicencedNonGaming: boolean;
  Flag_GamingVenue: boolean;
  Flag_ConsentToDisclose: boolean;
  Flag_RenewalNoticeSent: boolean;
  Flag_DeptOfEducation: boolean;
  Flag_HomeBusiness: boolean;
  Stall_Overall_EventName: string;
  Stall1_Location: string;
  Stall2_Location: string;
  Stall3_Location: string;
  Stall4_Location: string;
  Stall5_Location: string;
  Stall6_Location: string;
  Stall7_Location: string;
  Stall8_Location: string;
  Stall9_Location: string;
  Stall10_Location: string;
  Stall11_Location: string;
  Stall12_Location: string;
  Vehicle_Description: string;
  Vehicle_1_Make: string;
  Vehicle_1_RegNo: string;
  Vehicle_1_Colour: string;
  Vehicle_1_FoodToBeCarried: string;
  Vehicle_2_Make: string;
  Vehicle_2_RegNo: string;
  Vehicle_2_Colour: string;
  Vehicle_2_FoodToBeCarried: string;
  Vehicle_3_Make: string;
  Vehicle_3_RegNo: string;
  Vehicle_3_Colour: string;
  Vehicle_3_FoodToBeCarried: string;
  Pool_Description: string;
  Pool_TurnOver: string;
  Pool_Siting: string;
  Pool_DisinfectantDosing: string;
  Pool_WaterTreatment: string;
  Pool_Filtration: string;
  Pool_WaterDisposal: string;
  Pool_Flag_CyanuricAcid: boolean;
  Pool_Flag_SharedCirculation: boolean;
  Pool_Flag_Contracted: boolean;
  Pool_Volume: number;
  TOB_Categories: string;
  PCAndBACategories: string;
  PCAndBA: string;
  VM_AdvertisingLocation: string;
  VM_MobileVanRegNo: string;
  ArchiveBoxNumber: string;
  TradingHours: string;
  PremisesType: PremisesType;
  PremisesType_ID: number;
  Proprietor_Light: Light;
  Proprietor_RID: number;
  PrimaryContact_Light: Light;
  PrimaryContact_RID: number;
  SiteAddress: SiteAddress;
  SiteAddress_ID: number;
  Flag_ViewInPortal: boolean;
  Flag_RenewalSentToPortal: boolean;
  Flag_RenewalSubmittedFromPortal: boolean;
  Flag_MultiTenancy: boolean;
  AD_ProofProvidedFeeExmp: string;
  AD_ASICExtractProvided: string;
  Finance_DebtorExported_ENUM: string;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy: string;
  Sys_ModifiedBy_ID: number;
  Sys_ReferenceNumber: string;
  Sys_StatClockStatus_ENUM: string;
}
export interface SiteAddress {
  _BubbleUps: BubbleUp[];
  Sys_TimeStamp: string;
  Address_ID: number;
  AddressClassification_ENUM: string;
  ContactAddressType_Name: string;
  PropertyName: string;
  UnitNo: string;
  StreetNo: string;
  StreetName: string;
  Suburb: string;
  State: string;
  Postcode: string;
  LGA: string;
  AreaCode: string;
  Region: string;
  Location_Description: string;
  Map_Reference: string;
  AssessmentNo: string;
  PoBoxNo: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  ComplexAddress: boolean;
  Flag_StreetName_HasParts: boolean;
  StreetNamePart_Name: string;
  StreetNamePart_Type: string;
  PropertyAssessment_ID: number;
  PropertyAssessment: PropertyAssessment;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_StatClockStatus_ENUM: string;
}
export interface PropertyAssessment {
  PropertyAssessment_ID: number;
  Flag_PrimaryAddress: boolean;
  UnitSuffix1: string;
  HouseNumber1: number;
  Flag_RuralHouseNumber: boolean;
  Flag_OutsideProperty: boolean;
  Locality_Name: string;
  Locality_Postcode: string;
  Street_Name: string;
  Street_TypeAbbreviation: string;
  PID_No: string;
  Ward: string;
  Area: string;
  Parish: string;
  CrownAllotment: string;
  Section: string;
  LotNo: string;
  PlanRef: string;
  Volume: string;
  Folio: string;
  County: string;
  Legal_Description: string;
  CertOfTitle: string;
  Valuation_Number: string;
  Owners_Name: string;
  Owners_Attention_Of: string;
  Owners_Care_Of: string;
  Owners_Address: string;
  Owners_Locality: string;
  Owners_State: string;
  Owners_Postcode: string;
  Owners_Phone: string;
  RatePayers_Name: string;
  RatePayers_Attention_Of: string;
  RatePayers_Care_Of: string;
  RatePayers_Address: string;
  RatePayers_Locality: string;
  RatePayers_State: string;
  RatePayers_Postcode: string;
  RatePayers_Phone: string;
  RatePayers_Email: string;
  RatePayers_Mobile: string;
  RatePayers_Fax: string;
}
export interface BubbleUp {
  PKID: number;
  SourceIdentifier: SourceIdentifier;
  BubbleUpType_ENUM: string;
  Sys_CreatedDate: Date;
}
export interface SourceIdentifier {
  _RecordSource_ID: number;
  _RecordSourceType_ENUM: string;
  _RecordSource_ColumnName: string;
  _RecordSource_StringID: string;
  _RecordSource_FileNumber: string;
}
export interface Light {
  Sys_TimeStamp: string;
  RID: number;
  ContactRelationshipType_ENUM: string;
  Contact_ID: number;
  DisplayName: string;
  Email: string;
  ABN: string;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_StatClockStatus_ENUM: string;
}
export interface PremisesType extends DTOBaseClass_Standard {
  PremisesType_ID: number;
  Name: string;
  Description: string;
  Category: string;
  Records_Classification: string;
  Records_CategoryCode: string;
  PreTreatmentTypes: string;
  InspectionFrequency: number | null;
  Flag_HealthArea: boolean;
  Flag_ScheduledInspections: boolean;
  Flag_RiskClassification: boolean;
  Flag_Fees: boolean;
  Flag_FSP: boolean;
  Flag_Inspections: boolean;
  Flag_Samples: boolean;
  Flag_Notices: boolean;
  Flag_Prosecutions: boolean;
  Flag_Smoking: boolean;
  Flag_Conditions: boolean;
  Flag_TemporaryPremises: boolean;
  Flag_FoodVehicle: boolean;
  Flag_SwimmingPool: boolean;
  Flag_PrivateWaterSupply: boolean;
  Flag_WasteWater: boolean;
  Flag_PCBA: boolean;
  Flag_LiquorLicence: boolean;
  Flag_EatInFacility: boolean;
  Flag_OutdoorDining: boolean | null;
  Flag_TobaccoRetail: boolean;
  Flag_GamingVenue: boolean;
  Flag_BingoCentre: boolean;
  Flag_LicencedNonGaming: boolean;
  Flag_NoOfEmployees: boolean;
  Flag_NoOfSites: boolean;
  Flag_NoOfBeds: boolean;
  Flag_NoOfRooms: boolean;
  Flag_NoOfUnits: boolean;
  Flag_NoOfTables: boolean;
  Flag_FSR: boolean | null;
  Flag_CoolingTower: boolean | null;
  Flag_InfectionControl: boolean | null;
  Flag_HealthCare: boolean | null;
  Flag_ViewInPortal: boolean;
  Flag_BedFeesFixed: boolean | null;
  Flag_NoOfFoodhandlers: boolean | null;
  Flag_AdministrativeFees: boolean | null;
  Flag_EnableFSS: boolean | null;
  Flag_DisplayProprietorAddress: boolean | null;
  Flag_NoOfVehicles: boolean | null;
  Flag_NoOfChairs: boolean | null;
  Flag_DisplayAdditionalDetails: boolean | null;
  Flag_EnableTransfer: boolean | null;
  PremisesClassification_ID: number | null;
  RiskClassification_ID: number | null;
  PremisesClassification: PremisesClassification;
  RiskClassification: RiskClassification;
  RegistrationFeeType_ID: number | null;
  RenewalFeeType_ID: number | null;
  TransferFeeType_ID: number | null;
  PenaltyFeeType_ID: number | null;
  ApplicationFeeType_ID: number | null;
  AdministrativeFeeType_ID: number | null;
  HasInheritedRegistrationFeeType: boolean | null;
  HasInheritedRenewalFeeType: boolean | null;
  HasInheritedTransferFeeType: boolean | null;
  HasInheritedPenaltyFeeType: boolean | null;
  HasInheritedApplicationFeeType: boolean | null;
  HasInheritedAdministrativeFeeType: boolean | null;
  ProRata_ENUM: ProRataMethod | null;
  ProRata_Name: string;
  RegistrationFee_Amount: number | null;
  RenewalFee_Amount: number | null;
  TransferFee_Amount: number | null;
  PenaltyFee_Amount: number | null;
  ApplicationFee_Amount: number | null;
  AdministrativeFee_Amount: number | null;
  EmployeeFee_Amount: number | null;
  SiteLongTermFee_Amount: number | null;
  SiteShortTermFee_Amount: number | null;
  BedFee_Amount: number | null;
  RoomFee_Amount: number | null;
  UnitFee_Amount: number | null;
  TableFee_Amount: number | null;
  EmployeeFee_MaxAmount: number | null;
  SiteLongTermFee_MaxAmount: number | null;
  SiteShortTermFee_MaxAmount: number | null;
  BedFee_MaxAmount: number | null;
  RoomFee_MaxAmount: number | null;
  UnitFee_MaxAmount: number | null;
  TableFee_MaxAmount: number | null;
  EmployeeFee_NotCharged: number | null;
  SiteLongTermFee_NotCharged: number | null;
  SiteShortTermFee_NotCharged: number | null;
  BedFee_NotCharged: number | null;
  RoomFee_NotCharged: number | null;
  UnitFee_NotCharged: number | null;
  TableFee_NotCharged: number | null;
  NoOfBedsLimit: number | null;
  BedFee_Fixed_LT_Limit: number | null;
  BedFee_Fixed_GT_Limit: number | null;
  RenewalMethod_ENUM: RenewalMethod;
  RenewalMethod_Name: string;
  RenewalMethod_Date: Date | null;
  RenewalMethod_AnniversaryOptions: RenewalAnniversaryOption[];
  FSR_BusinessClass_ENUM: FSR_BUSINESSCLASSIFICATION | null;
  FSR_BusinessClass_Name: string;
  FSR_PremisesType_ENUM: FSR_PREMISESTYPE | null;
  FSR_PremisesType_Name: string;
  Numbering_Prefix: string;
  Numbering_Suffix: string;
  Records_SubjectIndex: string;
}
export interface PremisesClassification extends DTOBaseClass_Standard {
  PremisesClassification_ID: number;
  Name: string;
  Description: string;
  Records_Classification: string;
  Flag_FoodPremises: boolean;
  Flag_TobaccoPremises: boolean;
  Is_DrinkingWater: boolean | null;
  HealthLicensing: boolean | null;
}

export interface RiskClassification extends DTOBaseClass_Standard {
  RiskClassification_ID: number;
  Name: string;
  Description: string;
  UpperLimit: number | null;
  LowerLimit: number | null;
  Flag_StarRatedProgram: boolean;
}
export interface PremiseResponse {
  Proprietor_RID: string;
  Premises_ID: number;
  TradingName: string;
  Flag_MultiTenancy: boolean;
  Flag_DeptOfEducation: boolean;
  Status_ENUM: string;
  RegistrationNumber: string;
  ReferenceNumber: string;
  DebtorNumber: string;
  FileNumber: string;
  BPayReference: string;
  Flag_Streatrader: boolean;
  Flag_FastChoice: boolean;
  FSPTemplate_KWD: number;
  PremisesType_ID: number;
  Sys_TimeStamp: [];
  Proprietor: {
    Contact: {
      DisplayName: string;
    };
  };
  PrimaryContact: {
    Contact: {
      DisplayName: string;
      PreferredMethod_ENUM: string;
      PreferredMethod_Name: string;
    };
  };
  SiteAddress: {
    StreetNo: string;
    StreetName: string;
    Suburb: string;
    State: string;
    Postcode: string;
  };
  PostalAddress: {
    StreetNo: string;
    StreetName: string;
    Suburb: string;
    State: string;
    Postcode: string;
  };
  Officer_ID: number;
  Officer: {
    HealthArea_KWD: number;
    DisplayName: string;
    HealthArea_Name: string;
    DateInspected: Date;
    TimeStarted: Date;
    TimeCompleted: Date;
  };
  PremisesType: {
    PremisesType_ID: number;
    Name: string;
  };
}
export class PremiseRegisterForm {
  Proprietor_RID: string = "";
  Premises_ID: number = 0;
  TradingName: string = "";
  Flag_MultiTenancy: boolean = false;
  Flag_DeptOfEducation: boolean = false;
  Status_ENUM: string = "";
  RegistrationNumber: string = "";
  ReferenceNumber: string = "";
  DebtorNumber: string = "";
  FileNumber: string = "";
  BPayReference: string = "";
  Flag_Streatrader: boolean = false;
  Flag_FastChoice: boolean = false;
  FSPTemplate_KWD: number = 0;
  PremisesType_ID: number = 0;
  Sys_TimeStamp: [] = [];
  Officer_ID: number = 0;
  ProprietorContactDisplayName: string = "";
  PrimaryContactContactDisplayName: string = "";
  PrimaryContactContactPreferredMethod_ENUM: string = "";
  PrimaryContactContactPreferredMethod_Name: string = "";
  SiteAddressStreetNo: string = "";
  SiteAddressStreetName: string = "";
  SiteAddressSuburb: string = "";
  SiteAddressState: string = "";
  SiteAddressPostcode: string = "";
  PostalAddressStreetNo: string = "";
  PostalAddressStreetName: string = "";
  PostalAddressSuburb: string = "";
  PostalAddressState: string = "";
  PostalAddressPostcode: string = "";
  OfficerHealthArea_KWD: number = 0;
  OfficerDisplayName: string = "";
  OfficerHealthArea_Name: string = "";
  PremisesTypePremisesType_ID: number = 0;
  PremisesTypeName: string = "";
  DateInspected?: Date;
  TimeStarted?: Date;
  TimeCompleted?: Date;
}
export interface PremiseLOVs {
  PremiseTypes: [
    {
      Key: number;
      Value: string;
    }
  ];
  HealthAreas: [
    {
      Key: number;
      Value: string;
    }
  ];
  FSPTemplates: [
    {
      Key: number;
      Value: string;
    }
  ];
}

export interface ITotalRecords {
  actions: number;
  comments: number;
  contacts: number;
  samples: number;
  fees: number;
  documents: number;
  inspections: number;
  events: number;
  related: number;
}
export class InspectionRegisterForm extends InspectionRespone {
  TradingName: string = "";
  Address: string = "";
  Type: string = "";
  OfficerDisplayName?: string = "";
  SecondaryOfficerDisplayName?: string = "";
  PersonContactedDisplayName?: string = "";
  RelatedInspection?: string = "";
  Description: string = "";
  RelatedAction: string = "";
  RelatedNotice: string = "";
  PersonContactedPhone: string = "";
  PersonContactedPosition: string = "";
  Position: number = 0;
  DateInspected: string = "";
  TimeStarted: string = "";
  Premises_ID: string = "";
  TimeCompleted: string = "";
  //TODO
  parent?: any = {
    Premises_ID: "",
    TradingName: "",
    RegistrationNumber: "",
    ReferenceNumber: "",
  };
}

export interface IInspectionLovs {
  Reason: Array<IKeyValuePacket>;
  RelatedInspection: Array<IKeyValuePacket>;
  RelatedActions: Array<IKeyValuePacket>;
  RelatedNotice: Array<IKeyValuePacket>;
  Compliance: Array<IKeyValuePacket>;
  Complied: number;
}

export class IInspectionRecordsState {
  actions: number = 0;
  comments: number = 0;
  contacts: number = 0;
  documents: number = 0;
  inspections: number = 0;
  fees: number = 0;
  samples: number = 0;
  ncitems: number = 0;
  temperature: number = 0;
  fspobserved: number = 0;
  related: number = 0;
  issues: number = 0;
}

export enum HMRoute {
  HM_RENEWALS_ALLBYDUESTATUS = "/hm/renewals/allbyduestatus",
  HM_RENEWALS_ALLBYTYPE = "/hm/renewals/allbytype",
  HM_RENEWALS_REQUIRESRENEWALFEE = "/hm/renewals/requiresrenewalfee",
  HM_RENEWALS_REQUIRESNOTICE = "/hm/renewals/requiresnotice",
  HM_RENEWALS_NOTICESENT = "/hm/renewals/noticesent",
  HM_RENEWALS_NOTICERECEIVED = "/hm/renewals/noticereceived",
  HM_RENEWALS_FEESOUTSTANDING = "/hm/renewals/feesoutstanding",
  HM_RENEWALS_REQUIRESCERTIFICATEPAID = "/hm/renewals/requirescertificatepaid",
  HM_RENEWALS_REQUIRESCERTIFICATE = "/hm/renewals/requirescertificate",
  HM_INSPSCHEDULE_MYSCHEDULE = "/hm/inspschedule/myschedule",
  HM_INSPSCHEDULE_CALENDAR = "/hm/inspschedule/calendar",
  HM_INSPSCHEDULE_ALLBYOFFICER = "/hm/inspschedule/allbyofficer",
  HM_INSPSCHEDULE_NOTINSPECTED = "/hm/inspschedule/notinspected",
  HM_INSPSCHEDULE_INSPECTIONSDUE = "/hm/inspschedule/inspectionsdue",
  HM_INSPSCHEDULE_AUDITSCHEDULE = "/hm/inspschedule/auditschedule",
  HM_COMPLAINTS_BYREFNO = "/hm/complaints/byrefno",
  HM_COMPLAINTS_BYCATEGORY = "/hm/complaints/bycategory",
  HM_COMPLAINTS_BYSTATUS = "/hm/complaints/bystatus",
  HM_NOTICES_BYDATE = "/hm/notices/bydate",
  HM_NOTICES_BYDUESTATUS = "/hm/notices/byduestatus",
  HM_NOTICES_BYOFFICER = "/hm/notices/byofficer",
  HM_SAMPLES_BYDATE = "/hm/samples/bydate",
  HM_SAMPLES_BYTYPE = "/hm/samples/bytype",
  HM_SAMPLES_BYOFFICER = "/hm/samples/byofficer",
  HM_SAMPLES_BYMONTH = "/hm/samples/bymonth",
  HM_SAMPLES_BYSTATUS = "/hm/samples/bystatus",
  HM_SAMPLES_BYSURVEY = "/hm/samples/bysurvey",
  HM_SAMPLES_SURVEYS = "/hm/samples/surveys",
  HM_SAMPLES_LASTSAMPLED = "/hm/samples/lastsampled",
  HM_RECALLS_BYTRADINGNAME = "/hm/recalls/bytradingname",
  HM_RECALLS_BYMONTH = "/hm/recalls/bymonth",
  HM_RECALLS_BYNUMBER = "/hm/recalls/bynumber",
  HM_FINANCE_OSFEES = "/hm/finance/osfees",
  HM_FINANCE_PAIDFEES = "/hm/finance/paidfees",
  HM_FINANCE_FEESALL = "/hm/finance/feesall",
  HM_FINANCE_NOTSENTTOFINANCE = "/hm/finance/notsenttofinance",
  HM_FINANCE_SENTTOFINANCE = "/hm/finance/senttofinance",
  HM_FSR_LOG = "/hm/fsr/log",
  HM_FSR_PREMISESREPORT = "/hm/fsr/premisesreport",
  HM_FSR_PREMISESTYPES = "/hm/fsr/premisestypes",
  HM_FSR_INSPECTIONSTYPES = "/hm/fsr/inspectionstypes",
  HM_FSR_NOTICETYPES = "/hm/fsr/noticetypes",
  HM_FSR_COMPLAINTTYPES = "/hm/fsr/complainttypes",
  HM_FSR_KEYWORDS = "/hm/fsr/keywords",
  HM_GOVTREPORTS_TOBACCORETAILERS = "/hm/govtreports/tobaccoretailers",
  HM_GOVTREPORTS_TOBACCOINSPECTIONS = "/hm/govtreports/tobaccoinspections",
  HM_GOVTREPORTS_VENDINGMACHINES = "/hm/govtreports/vendingmachines",
  HM_GOVTREPORTS_EATINFACILITIES = "/hm/govtreports/eatinfacilities",
  HM_GOVTREPORTS_OUTDOORDINING = "/hm/govtreports/outdoordining",
  HM_GOVTREPORTS_LICENCED = "/hm/govtreports/licenced",
  HM_SYSTEMADMIN_PREMISESTYPES = "/hm/systemadmin/premisestypes",
  HM_SYSTEMADMIN_CLASSIFICATIONS = "/hm/systemadmin/classifications",
  HM_SYSTEMADMIN_RISKS = "/hm/systemadmin/risks",
  HM_SYSTEMADMIN_NCITEMS = "/hm/systemadmin/ncitems",
  HM_SYSTEMADMIN_INSPCOMMENTS = "/hm/systemadmin/inspcomments",
  HM_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/healthmanager/register",
  HM_RECYCLEBIN_PREMISES = "/hm/recyclebin/premises",
  HM_RECYCLEBIN_PREMISESTYPES = "/hm/recyclebin/premisestypes",
  HM_RECYCLEBIN_FEES = "/hm/recyclebin/fees",
  HM_RECYCLEBIN_INSPECTIONS = "/hm/recyclebin/inspections",
  HM_RECYCLEBIN_SAMPLES = "/hm/recyclebin/samples",
  HM_DETAIL = "/hm/managepremise",
}

export enum HMPremiseRoute {
  HM_PREMISES_REGISTER = "/hm/premises/register",
  HM_PREMISES_BYTYPE = "/hm/premises/bytype",
  HM_PREMISES_BYSUBURB = "/hm/premises/bysuburb",
  HM_PREMISES_BYAREA = "/hm/premises/byarea",
  HM_PREMISES_BYRISK = "/hm/premises/byrisk",
  HM_PREMISES_BYOFFICER = "/hm/premises/byofficer",
  HM_PREMISES_BYFSP = "/hm/premises/byfsp",
  HM_PREMISES_BYPROPERTY = "/hm/premises/byproperty",
  HM_PREMISES_BYSTARRATING = "/hm/premises/bystarrating",
  HM_PREMISES_BYCATEGORY = "/hm/premises/bycategory",
  HM_PREMISES_BYPROPRIETOR = "/hm/premises/byproprietor",
  HM_PREMISES_PCBA = "/hm/premises/pcba",
  HM_PREMISES_PRESCRIBEDACCOM = "/hm/premises/prescribedaccom",
  HM_PREMISES_POOLSSPAS = "/hm/premises/poolsspas",
  HM_PREMISES_COOLINGTOWERS = "/hm/premises/coolingtowers",
  HM_PREMISES_DRINKINGWATER = "/hm/premises/drinkingwater",
  HM_PREMISES_FASTCHOICES = "/hm/premises/fastchoices",
  HM_PREMISES_FOODSTALLS = "/hm/premises/foodstalls",
  HM_PREMISES_FOODVEHICLES = "/hm/premises/foodvehicles",
  HM_PREMISES_OSFEES = "/hm/premises/osfees",
  HM_PREMISES_NONRENEWABLE = "/hm/premises/nonrenewable",
  HM_PREMISES_STREATRADER = "/hm/premises/streatrader",
  HM_PREMISES_PRELODGEMENTS = "/hm/premises/prelodgements",
  HM_PREMISES_CLOSURES = "/hm/premises/closures",
  HM_PREMISES_PENDINGTRANSFERS = "/hm/premises/pendingtransfers",
  HM_PREMISES_DUECONDITIONS = "/hm/premises/dueconditions",
  HM_PREMISES_COMPCONDITIONS = "/hm/premises/compconditions",
  HM_PREMISES_ALERTS = "/hm/premises/alerts",
}

export enum HMTobaccoRoute {
  HM_TOBACCO_REGISTER = "/hm/tobacco/register",
  HM_TOBACCO_BYTYPE = "/hm/tobacco/bytype",
  HM_TOBACCO_BYSUBURB = "/hm/tobacco/bysuburb",
  HM_TOBACCO_BYAREA = "/hm/tobacco/byarea",
  HM_TOBACCO_BYOFFICER = "/hm/tobacco/byofficer",
  HM_TOBACCO_PRELODGEMENTS = "/hm/tobacco/prelodgements",
  HM_TOBACCO_CLOSURES = "/hm/tobacco/closures",
  HM_TOBACCO_PENDINGTRANSFERS = "/hm/tobacco/pendingtransfers",
}

export enum HMInspectionRoute {
  HM_INSPECTIONS_BYDATE = "/hm/inspections/bydate",
  HM_INSPECTIONS_BYTYPE = "/hm/inspections/bytype",
  HM_INSPECTIONS_BYOFFICER = "/hm/inspections/byofficer",
  HM_INSPECTIONS_BYMONTH = "/hm/inspections/bymonth",
  HM_INSPECTIONS_BYAREA = "/hm/inspections/byarea",
  HM_INSPECTIONS_ASSESSMENTS = "/hm/inspections/assessments",
  HM_INSPECTIONS_AUDITS = "/hm/inspections/audits",
  HM_INSPECTIONS_OSNCITEMS = "/hm/inspections/osncitems",
}

export enum HMToolsRoute {
  HM_BATCH_UPDATE = "/hm/batch-update",
}

export type IGetHMPremiseById =
  | [APIResponse<PremiseResponse>, APIResponse<PremiseLOVs>]
  | APIResponse<PremiseResponse>
  | undefined;

export type IGetHMInspectionById =
  | [APIResponse<InspectionRespone>, APIResponse<IInspectionLovs>]
  | APIResponse<InspectionRespone>
  | undefined;

export enum PCBACategories {
  Unspecified = 0,
  BodyPiercing = 1 << 0,
  BodyTreatments = 1 << 1,
  ColonicIrrigation = 1 << 3,
  CosmeticTattooing = 1 << 4,
  CuticleCutting = 1 << 2,
  Dermabrasion = 1 << 5,
  DryNeedlingAccupuncture = 1 << 6,
  EarPiercing = 1 << 7,
  Electrolysis = 1 << 8,
  EyebrowEyelashTinting = 1 << 9,
  EyelashExtensions = 1 << 10,
  Facials = 1 << 11,
  FacialsMakeUp = 1 << 12,
  Footscraping = 1 << 13,
  Footspa = 1 << 14,
  Hairdressing = 1 << 15,
  HairExtensions = 1 << 16,
  HennaTattoos = 1 << 17,
  LaserTreatment = 1 << 18,
  MakeUp = 1 << 19,
  ManicurePedicure = 1 << 20,
  NailTreatment = 1 << 21,
  SprayTan = 1 << 22,
  Tattooing = 1 << 23,
  TemporaryMakeUp = 1 << 24,
  Threading = 1 << 25,
  Waxing = 1 << 26,
  Wigs = 1 << 27,
  Other = 1 << 28,
  PRP = 1 << 29,
  Microblading = 1 << 30,
}
export enum WasteWater {
  Unspecified = 0,
  Sewer = 1,
  Septic = 2,
}
export enum VehicleVendorType {
  Unspecified = 0,
  Mobile = 1,
  Stationed = 2,
}
export enum RenewalWorkflowStatus {
  FirstLetterSent = 1,
  SecondLetterSent = 2,
  FinalLetterSent = 3,
  NoLetterSent = 4,
  RenewalFormReceived = 5,
}
export interface Contact_RelationLightWeight extends DTOBaseClass_Standard {
  RID: number;
  ContactRelationshipType_ENUM: ContactRelationshipType;
  ContactRelationshipType_Name: string;
  Contact_ID: number;
  DisplayName: string;
  Email: string;
  ABN: string;
  PreferredMethod_Formatted: string;
}

export enum ProRataMethod {
  Unspecified = 0,
  None = 1,
  Monthly = 2,
  Quarterly = 3,
  Biannually = 4,
}
export enum RenewalMethod {
  Unspecified = 0,
  None = 1,
  SameDate = 2,
  Individual = 3,
  Anniversary = 4,
}
export enum RenewalAnniversaryOption {
  Unspecified = 0,
  OneYear = 1,
  TwoYear = 2,
  ThreeYear = 3,
  FourYear = 4,
  FiveYear = 5,
  TenYear = 10,
}
export enum FSR_BUSINESSCLASSIFICATION {
  CLASS_1 = 200,
  CLASS_2 = 201,
  CLASS_3 = 202,
  CLASS_4 = 203,
}
export enum FSR_PREMISESTYPE {
  COMMUNITY_EVENTS = 100,
  MANUFACTURER_POTENTIALLY_HAZARDS_FOODS = 101,
  MANUFACTURER_LOW_RISK_FOODS = 102,
  BAKERY_RETAILER = 103,
  CONVENIENCE_STORES = 104,
  DELICATESSEN = 105,
  GREEN_GROCERY = 106,
  HOME_BASED_RETAILER = 107,
  LOW_RISK_PACKAGED_FOOD_RETAILER = 108,
  NUTS_HERBS_SPICES_RETAIL = 109,
  PASTA_RETAIL = 110,
  SUPERMARKET = 111,
  TEMPORARY_FOOD_PREMISES_MARKET_DISPLAY_EXHIBITION_STALL = 112,
  VENDING_MACHINE = 113,
  ACCOMODATION_GETAWAY = 114,
  AGED_CARE_FACILITIES = 115,
  BAR_PUB = 116,
  CANTEEN_CAMPS = 117,
  CAFE_RESTAURANT = 118,
  CATERING = 119,
  CHILD_CARE = 120,
  CLUB = 121,
  COFFEE_AND_DESSERT_OUTLET = 122,
  DELIVERY_MEAL_ORGANISATION = 123,
  JUICE_BAR = 124,
  HOSPITAL = 125,
  MOBILE_FOOD_PREMISES = 126,
  RECEPTION_CENTRE = 127,
  RESIDENTIAL_CARE = 128,
  TAKE_AWAY_FOODS_FAST_FOOD_OUTLET_KIOSK = 129,
  WAREHOUSE_DISTRIBUTORS_WHOLESALERS_AND_IMPORTERS = 130,
}

export interface HMNoticeView {
  ID: number;
  DateServed: Date;
  Type: string;
  DateDue: Date | null;
  Officer: string;
  DateRevoked: Date | null;
  TradingName: string;
  ReferenceNumber: string;
  DueStatus: string;
  CreatedDate: Date;
  CreatedBy: string;
  NoticeCategory: string;
  Location: string;
}