import { DTO_Assessment_Deferments } from "@app/products/property/assessments/[id]/components/child-screens/deferments/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getAssessmentDeferments = async (
  assessmentId: number
): Promise<APIResponse<DTO_Assessment_Deferments | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Assessment_Deferments>(
      `/api/property/internal/assessment/${assessmentId}/deferments`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
