import { ILightWeightActionType } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/automatic-action/model";
import { BOOLEAN_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<ILightWeightActionType>();
export const colAutomaticAction: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("Flag_HasOutcome"),
    title: "Has Outcome",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];
