import { IReadingGridData } from "@app/products/property/meters/components/dialogs/enter-special-reading/model";
import { IRegisterMeters } from "@app/products/property/registers/[id]/components/child-screens/meters/model";
import { cloneDeep } from "lodash";

export const convertToReadingGridData = (response: IRegisterMeters) => {
  let result: IReadingGridData = cloneDeep(response);
  if (response?.LastRead) {
    result.PreviousReadingDate = response?.LastRead;
  }
  if (response?.LastReading) {
    result.PreviousReading = response?.LastReading;
  }

  return result;
};
