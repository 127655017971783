import { Comments } from "@app/core/comments/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import React from "react";

interface IPropertyCommentsProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

export const PropertyComments = (props: IPropertyCommentsProps) => {
  return <Comments {...props} isHideDeleteButton isCallViewconfiguration />;
};
