import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { eComponent } from "@app/products/property/components/associations/model";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

interface IAddToSupplementaryCommonButtonProps {
  componentNumber: eComponent.Master_Property | eComponent.Assessment;
  statusId?: number;
  isDisabled?: boolean;
  ids?: number[];
}

export const AddToSupplementaryCommonButton = observer(
  ({
    componentNumber,
    statusId,
    isDisabled,
    ids,
  }: IAddToSupplementaryCommonButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();
    const { id } = useParams<{ id: string }>();

    const recordIDs: number[] = useMemo(() => {
      if (ids?.length) {
        return ids;
      }
      return id ? [+id] : [];
    }, [id, ids]);

    return (
      <CCNavButton
        title={"Associate supplementary"}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Associate_Supplementary,
              data: {},
              props: {
                componentNumber,
                componentIds: recordIDs,
                statusId: statusId ?? null,
              },
            },
          ]);
        }}
        disabled={isDisabled}
      />
    );
  }
);
