export enum FormRoute {
  FORM_ROUTE = "/manageregistration",
  //CRMS
  FORM_CRMS_SERVICESTANDARD = "/manageservicestandard",
  FORM_CRMS_PHONEMESSAGE = "/managephonemessage",
  FORM_CRMS_KBARTICLE = "/managekbarticle",
  //HM
  FORM_HM_PREMISE = "/managepremise",
  FORM_HM_INSPECTION = "/manageinspection",
  //BUILDING
  FORM_BUILDING_REGISTERS_COUNCILCONSENT = "/managebuildingregisterscouncilconsent",
  FORM_BUILDING_REGISTER_DEMOLITION = "/managedemolition",
  FORM_BUILDING_ESSENTIAL_SAFETY = "/manageessentialsafety",
  FORM_BUILDING_REGISTERS_POPE = "/managebuildingregisterspope",
  FORM_BUILDING_REGISTERS_ENQUIRY = "/managebuildingregistersenquiry",
  FORM_BUILDING_REGISTERS_CERTIFICATES = "/managebuildingregisterscertificates",
  FORM_BUILDING_REGISTERS_SITINGAPPROVALS = "/managebuildingregisterssitingapprovals",
  FORM_BUILDING_REGISTER_ENFORCEMENT = "/managerenforcement",
  FORM_BUILDING_REGISTER_STORMWATER = "/managestormwater",
  FORM_BUILDING_REGISTER_EASEMENT = "/manageeasements",

  FORM_BUILDING_POOLSSPAS = "/managebuildingregisterspoolsspas",
  //CSL
  FORM_CSL_SYSTEM_ADMIN_CATEGORIES = "/managelicencecategories",
  FORM_CSL_SYSTEM_ADMIN_CONDITION = "/manageconditionsreference",
  //
  FORM_ACTIONS_COMPLAINT = "/manageactionscomplaint",

  //CONTACT REPLATIONSHIP
  FORM_CONTACT_REPLATIONSHIP = "/managecontactrelationship",

  FORM_PROPERTY_CERTIFICATE = "/managecertificate",
  FORM_PROPERTY_ASSESSMENT = "/manageproperty",
  FORM_PROPERTY_ASSESSMENT_MASTERPROPERTIES = "/masterproperties",
  FORM_PROPERTY_SUPPLEMENTARY_RATE = "/managesupplementaryrate",
  FORM_PROPERTY_JOURNAL = "/managejournal",
  FORM_PROPERTY_PARCEL = "/manageparcel",
  FORM_PROPERTY_TITLE = "/managetitle",
  FORM_PROPERTY_ASSESSMENTS_REBATES_CLAIMS = "/rebatesclaims",
}

export enum GeneralRoute {
  ANIMALS_PATH = "/animals",
  APPOINTMENT_PATH = "/appointment",
  ASSETMANAGER_PATH = "/asset-manager",
  BUILDING_PATH = "/building",
  CRMS_PATH = "/crms",
  CSL_PATH = "/csl",
  EVENTMANAGEMENT_PATH = "/event-management",
  LOCALLAWS_PATH = "/local-laws",
  PARKING_PERMITS_PATH = "/parking-permits",

  PROPERTY_PATH = "/property",
  PROPERTY_ASSESSMENT_PATH = "/property/assessments",
  PROPERTY_PARCEL_PATH = "/property/parcel",
  PROPERTY_TITLE_PATH = "/property/titles",
  PROPERTY_JOURNAL_PATH = "/property/journals",
  PROPERTY_SUPPLEMENTARY_RATES_PATH = "/property/supplementaryrates",
  PROPERTY_CERTIFICATES_PATH = "/property/certificates",

  STREATRADER_PATH = "/streatrader",
  TOWNPLANNING_PATH = "/town-planning",
  HEALTH_MANAGER = "/hm",

  TOBACCO_REGISTER_PATH = "/tobacco-register",
  WORKORDER = "/workorder",
  ASSET_REGISTER_PATH = "/asset-register",
  MANAGE_CATEGORY_PATH = "/manage-category",
  MANAGE_ASSESSMENT_PATH = "/manage-assessment",
  MANAGE_KANBAN_PATH = "/manage-kanban",
  WORK_ORDER_PATH = "/work-order",
  AMS_PATH = "/ams",

  ACTIONS_PATH = "/actions",
  CONTACTS_PATH = "/contacts",
  HELP_PATH = "/help",
  REPORT_PATH = "/reports",
  SETTINGS_PATH = "/settings",
  WASTEWATER_PATH = "/waste-water",
  CRS_PATH = "/crs",
  SITEUSER_PATH = "/site-user",
  DASHBOARD_SEARCH = "/api/core/internal/dashboard/search",
}
