export const mockNotices = [
  {
    NoticeId: 210003,
    NoticeType: "Fire Prevention Notice",
    CreatedOn: "7-Jan-2022 17:05",
    CreatedBy: "rhosking",
    Reference: "test",
  },
  {
    NoticeId: 210004,
    NoticeType: "Fire Prevention Notice 2",
    CreatedOn: "8-Jan-2022 17:05",
    CreatedBy: "rhosking",
  },
];
