import { DTO_Entity } from "@app/products/property/parcels/[id]/components/child-screens/entities/model";
import { useParcelEntitiesStore } from "@app/products/property/parcels/[id]/components/child-screens/entities/store";
import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useEffectOnce } from "react-use";
import EntitiesDetails from "./components/detail/_index";
import { colParcelAssociatedEntities } from "./config";

const nameOf = nameOfFactory<DTO_Entity>();
export const ParcelEntities = observer(() => {
  const {
    isLoading,
    parcelEntities,
    loadParcelEntities,
    includePastNames,
    setIncludePastNames,
    resetStore,
  } = useParcelEntitiesStore();
  const { parcelId } = useParcelsStore();

  useEffect(() => {
    if (!parcelId) return;
    loadParcelEntities(parcelId, includePastNames);
  }, [loadParcelEntities, parcelId, includePastNames]);

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  return (
    <div className="cc-parcel-entities">
      <div className="cc-grid-control-between">
        <div className="cc-grid-control-left">
          <label className="cc-label">Associated</label>
        </div>
        <div className="cc-grid-control-right">
          <Checkbox
            title="Include previous contacts"
            label="Include previous contacts"
            checked={includePastNames}
            disabled={isLoading}
            onChange={(e: CheckboxChangeEvent) => {
              setIncludePastNames(e.value);
            }}
          />
        </div>
      </div>
      <CCGrid
        isLoading={isLoading}
        className="cc-entities-grid"
        data={parcelEntities ?? []}
        columnFields={colParcelAssociatedEntities}
        primaryField={nameOf("Id")}
        detail={EntitiesDetails}
      />
    </div>
  );
});
