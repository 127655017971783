import { useAssessmentChargesStore } from "@app/products/property/assessments/[id]/components/child-screens/charges/store";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const ModifyChargeDetailsButton = observer(() => {
  const { chargesSelected } = useAssessmentChargesStore();
  const { setListDialog } = usePropertyWorkflowStore();
  return (
    <CCNavButton
      title="Modify charge details"
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Modify_ChargeDetail,
            data: {},
            props: {
              isRedirectManagePage: false,
            },
          },
        ]);
      }}
      disabled={chargesSelected?.length !== 1}
    />
  );
});
