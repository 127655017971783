import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { ExpandedGroupViewsCCGridEventType } from "@components/cc-grid/components/grid-header-collapsed-state-tool/constant";
import React from "react";
import { createPortal } from "react-dom";
import "./_index.scss";

interface IGridHeaderCollapsedStateToolProps {
  setExpandedGroupViews: (status: boolean) => void;
}
export const GridHeaderCollapsedStateTool = ({
  setExpandedGroupViews,
}: IGridHeaderCollapsedStateToolProps) => {
  const parentRoot: Element | null = document.querySelector(
    "#grid-header-collapsed-state-tool"
  );

  useAddUniqueEventEmitter([
    {
      eventType: ExpandedGroupViewsCCGridEventType.updateStatus,
      listener: (status: boolean) => {
        setExpandedGroupViews(status);
      },
    },
  ]);

  const ControlButton = () => {
    return (
      <div className="cc-grid-control-button">
        <div className="cc-wrap-icon">
          <div
            className="cc-expand-icon"
            onClick={() => {
              setExpandedGroupViews(true);
            }}
          >
            <div className="cc-icon-custom cc-float-left" />
            <i className="fal fa-plus-square"></i>
            <div className="cc-icon-custom cc-float-right" />
          </div>
        </div>
        <div className="cc-wrap-icon">
          <div
            className="cc-collapse-icon"
            onClick={() => {
              setExpandedGroupViews(false);
            }}
          >
            <div className="cc-icon-custom cc-float-left" />
            <i className="fal fa-minus-square"></i>
            <div className="cc-icon-custom cc-float-right" />
          </div>
        </div>
      </div>
    );
  };

  if (parentRoot) return createPortal(<ControlButton />, parentRoot);
  return null;
};
