import { ViewName } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/oas/oas-views/components/view-name/_index";
import {
  dataFormSetting,
  enumListView,
} from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/oas/oas-views/util";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

export const OAsViewsForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { isEditing } = useSettingPPRManageStore();

    return (
      <>
        <ViewName
          {...dataFormSetting(enumListView(1))}
          view={1}
          titleGroup="Application Number Settings"
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />

        <ViewName
          {...dataFormSetting(enumListView(2))}
          view={2}
          titleGroup="Application Number Settings"
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />

        <ViewName
          {...dataFormSetting(enumListView(3))}
          view={3}
          titleGroup="Application Number Settings"
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
      </>
    );
  }
);
