import { DTO_WorkflowHeader } from "@app/products/property/model";

export interface DTO_WorkflowDetail_JournalDDRaiseInterest {
  DDAccountId?: number;
  PreviousInterestTo: Date | null;
  CalculateInterestTo: Date | null;
  MarketInterestRate?: number;
  PremiumInterestRate?: number;
  TaskType?: string;
  TaskName?: string;
  EmailRecipients?: string;
  RunImmediately?: boolean;
  RunAt: Date | null;
  Journal_Number?: number;
}

export enum EKeysOfStepsRaiseDeferredDutyInterest {
  Details = "Detail",
  TaskSchedule = "TaskSchedule",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfSendStepsRaiseDeferredDutyInterest = [
  EKeysOfStepsRaiseDeferredDutyInterest.Details,
];

export interface DTO_Workflow_RaiseDeferredDutyInterest {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_JournalDDRaiseInterest;
}
