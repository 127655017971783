import { DTO_ChargeBalance } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/model";
import { CurrencyInputCell } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/allocations-to-charge-balances/components/currency-input-cell/_index";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_ChargeBalance>();
export const ChargeBalancesName = {
  AdjustedAmount: nameOf("Adjusted_Amount"),
  AdjustInterest: nameOf("Adjusted_Interest"),
};

export const colAllocationToChargeBalances: IColumnFields[] = [
  {
    field: nameOf("Adjusted_Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    editCell: CurrencyInputCell,
    editable: true,
  },
  {
    field: nameOf("Adjusted_Interest"),
    title: "Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
    editCell: CurrencyInputCell,
    editable: true,
  },
  {
    field: nameOf("CB_Original_Amount"),
    title: "Original Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("CB_Balance_Amount"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("CB_Interest"),
    title: "Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("CA_Charge_Date"),
    title: "Charge Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CI_Instalment_Number"),
    title: "Instal. Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Levy_Name"),
    title: "Levy",
  },
  {
    field: nameOf("CI_Due_Date"),
    title: "Date Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CA_Name"),
    title: "Charge Name",
  },
  {
    field: nameOf("CA_Code"),
    title: "Charge Code",
  },
  {
    field: nameOf("Charge_Balances_Id"),
    title: "Charge Balances ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
