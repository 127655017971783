import { history } from "@/AppRoutes";
import {
  getBuildingRegistersCertificatesById,
  postBuildingRegistersCertificates,
  putBuildingRegistersCertificates,
} from "@app/products/building/registers/certificates/[id]/api";
import { CERTIFICATES_MANAGE_ROUTE } from "@app/products/building/registers/certificates/[id]/constant";
import { IBuildingRegistersCertificatesFormData } from "@app/products/building/registers/certificates/[id]/model";
import { IBuilding } from "@app/products/building/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class BuildingRegistersCertificatesStore {
  private _certificates?: IBuildingRegistersCertificatesFormData = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get certificates() {
    return this._certificates;
  }
  setCertificates = (
    certificates: IBuildingRegistersCertificatesFormData | undefined
  ) => {
    runInAction(() => {
      this._certificates = certificates;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get certificateId() {
    return this._certificates?.ID;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._certificates = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadCertificates = async (certificateId: number) => {
    this.setIsLoading(true);
    const response = await getBuildingRegistersCertificatesById(certificateId);
    let errorResponse, newCertificates;

    if (isSuccessResponse(response)) {
      newCertificates = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }

    this.setResponseLoadError(errorResponse);
    this.setCertificates(newCertificates);
    this.setIsLoading(false);
  };

  createBuildingRegistersCertificates = async (building: IBuilding) => {
    this.setIsLoading(true);
    await postBuildingRegistersCertificates(building);
    appNotificationStore.pushNotification({
      title: "Building register certificate created successfully",
      type: "success",
      onClose: () => {
        history.replace(`${CERTIFICATES_MANAGE_ROUTE}/${building?.id ?? 1}`);
      },
    });
    this.setIsLoading(false);
  };

  updateBuildingRegistersCertificates = async (building: IBuilding) => {
    this.setIsLoading(true);
    await putBuildingRegistersCertificates(building);
    appNotificationStore.pushNotification({
      title: "Building register certificate updated successfully",
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const buildingRegistersCertificatesStoreContext = createContext(
  new BuildingRegistersCertificatesStore()
);
export const useBuildingRegistersCertificatesStore = () => {
  return useContext(buildingRegistersCertificatesStoreContext);
};
