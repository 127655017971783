import { COMMUNICATION_API_ROUTE } from "@app/core/communication/dialogs/constant";
import { apiCoreattachments } from "@app/core/components/common/utils";
import { DOCUMENTSTATUS, IDocuments } from "@app/core/documents/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { globalStoreInstance } from "@common/stores/global/store";

// all api of this file is still on Radium PR
export const getAttachFiles = async (templateId: string) => {
  return await CoreAPIService.getClient().get(
    `${globalStoreInstance.allSetting.baseAPIUrl}${COMMUNICATION_API_ROUTE}/getcurrentattachment/${templateId}`
  );
};

export const getDocumentsByIdAndRecordType = async (
  id: number,
  recordType: RECORDTYPE,
  documentStatus: DOCUMENTSTATUS
): Promise<APIResponse<IDocuments[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IDocuments[]>(
      apiCoreattachments(id),
      {
        params: {
          recordType: recordType,
          status: documentStatus,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
