import { DOCUMENT_TEMPLATE_ROUTE } from "@app/products/property/system-admin/document-template/constant";
import { VO_DocumentTemplate } from "@app/products/property/system-admin/document-template/list/model";
import { BOOLEAN_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<VO_DocumentTemplate>();
export const colDocumentTemplate: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Document Name",
    locked: true,
    linkTo: (dataItem) => `${DOCUMENT_TEMPLATE_ROUTE}/${dataItem.Id}`,
  },
  {
    field: nameOf("Dataset_Name"),
    title: "Data",
  },
  {
    field: nameOf("SaveFormat_Name"),
    title: "Save Format",
  },
  {
    field: nameOf("CreatedDate"),
    title: "Loaded On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("IsActive"),
    title: "Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("AdditionalInformation"),
    title: "Additional Information",
  },
];
