import { DTO_Workflow_JournalAssessmentTransferTransaction } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-transaction/model";
import {
  DTO_Journal_LOVs,
  RequestJournalCreateObj,
} from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { WorkflowProcessMode } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postProcessTransferTransaction = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_JournalAssessmentTransferTransaction
): Promise<APIResponse<any>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/transferassessmenttransaction/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataJournal =
  | [
      APIResponse<DTO_Journal_LOVs>,
      APIResponse<DTO_Workflow_JournalAssessmentTransferTransaction>
    ]
  | APIResponse<
      DTO_Journal_LOVs | DTO_Workflow_JournalAssessmentTransferTransaction
    >
  | undefined;
export const getInitialDataTransferTransaction = async (
  workflowDraftId: number | undefined = 0,
  isFromActionList: boolean = false,
  transactionIds?: number[] | undefined
): Promise<IGetInitialDataJournal> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_LOVs>(
        `api/property/internal/journal/lovs`
      ),
      isFromActionList
        ? getWorkflowTransferTransaction(workflowDraftId)
        : getNewWorkflowTransferTransaction({
            TransactionIds: transactionIds,
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowTransferTransaction = async (
  requestObj: RequestJournalCreateObj | object = {}
): Promise<APIResponse<DTO_Workflow_JournalAssessmentTransferTransaction>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/transferassessmenttransaction/new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowTransferTransaction = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_JournalAssessmentTransferTransaction>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/transferassessmenttransaction/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
