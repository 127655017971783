import { loadRecommendDeferredDutyNoticeRun } from "@app/products/property/deferred-duty/notice-runs/components/dialogs/new-deferred-duty-notice-run/api";
import { INewDeferredDutyNoticeRunFormProps } from "@app/products/property/deferred-duty/notice-runs/components/dialogs/new-deferred-duty-notice-run/model";
import { DeferredDutyNoticeRunDialog } from "@app/products/property/deferred-duty/notice-runs/components/dialogs/new-deferred-duty-notice-run/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const NewDeferredDutyNoticeRunButton = () => {
  const [noticeRunInfo, setNoticeRunInfo] = useState<
    INewDeferredDutyNoticeRunFormProps | undefined
  >(undefined);
  return (
    <>
      <CCNavButton
        title="New"
        onClick={() => {
          loadRecommendDeferredDutyNoticeRun().then((response: any) => {
            setNoticeRunInfo(response);
          });
        }}
      />

      {noticeRunInfo && (
        <DeferredDutyNoticeRunDialog
          onClose={() => {
            setNoticeRunInfo(undefined);
          }}
          onOkButton={() => {
            setNoticeRunInfo(undefined);
          }}
          noticeRunInfo={noticeRunInfo}
        />
      )}
    </>
  );
};
