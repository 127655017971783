import { CRSFormElement } from "@app/products/crs/[id]/components/child-screens/general/components/form-element/_index";
import { useCRSStore } from "@app/products/crs/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const CRSForm = observer(() => {
  const isNew = useIsNew();
  const { createCRS, updateCRS, crs } = useCRSStore();

  const handleSubmit = async (data: any) => {
    if (isNew) {
      createCRS(data);
    } else {
      updateCRS(data);
    }
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={crs}
        render={(formRenderProps: FormRenderProps) => (
          <CRSFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
