import { parkingPermitsRoute } from "@app/products/parking-permits/route";
import { CCActionBarNavDropdown } from "@components/cc-list-view-action-bar/components/nav-dropdown/_index";
import React from "react";

interface IParkingPermitsActionBarNavDropdownProps {
  category: string;
}

export const ParkingPermitsActionBarNavDropdown = ({
  category,
}: IParkingPermitsActionBarNavDropdownProps) => {
  return (
    <CCActionBarNavDropdown
      category={category}
      product={parkingPermitsRoute.path}
      route={parkingPermitsRoute}
    />
  );
};
