import { IGridSummary } from "@app/products/property/assessments/financial-summaries/[id]/model";

export const mockGridSummary: IGridSummary[] = [
  {
    Name: "Adhac Charges",
    Trans: 1,
    Charges: 1,
    Amount: 555,
    Interest: 0,
    Total: 555,
    Id: 1,
  },
  {
    Name: "Maree recept test",
    Trans: 1,
    Charges: 1,
    Amount: 555,
    Interest: 0,
    Total: 555,
    Id: 2,
  },
  {
    Name: "Maree Supp",
    Trans: 2,
    Charges: 2,
    Amount: 770,
    Interest: 0,
    Total: 770,
    Id: 3,
  },
  {
    Name: "Payments taken at Town Hall - Location Session 3018",
    Trans: 2,
    Charges: 2,
    Amount: -20,
    Interest: 0,
    Total: -20,
    Id: 4,
  },
  {
    Name: "Payments taken at Town Hall - Location Session 3019",
    Trans: 1,
    Charges: 1,
    Amount: -750,
    Interest: 0,
    Total: -750,
    Id: 5,
  },
  {
    Name: "Payments taken at Town Hall - Location Session 3020",
    Trans: 1,
    Charges: 1,
    Amount: -555,
    Interest: 0,
    Total: -555,
    Id: 6,
  },
  {
    Name: "Special Comm Fac RES",
    Trans: 1,
    Charges: 1,
    Amount: 20.52,
    Interest: 0,
    Total: 20.52,
    Id: 7,
  },
  {
    Name: "testing 680213",
    Trans: 1,
    Charges: 1,
    Amount: 34.69,
    Interest: 0,
    Total: 34.39,
    Id: 8,
  },
];
