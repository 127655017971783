import { eventEmitter } from "@/App";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ReportsActionBarNavDropdown } from "@common/pages/report/components/action-bar/nav-dropdown/_index";
import { ChangeOwnerButton } from "@common/pages/report/integrated-reports/component/buttons/change-owner/_index";
import { DeleteReportButton } from "@common/pages/report/integrated-reports/component/buttons/delete-report/_index";
import { ExportXmlButton } from "@common/pages/report/integrated-reports/component/buttons/export-xml/_index";
import { ImportButton } from "@common/pages/report/integrated-reports/component/buttons/import/_index";
import { RefreshAllSharingButton } from "@common/pages/report/integrated-reports/component/buttons/refresh-all-sharing/_index";
import { ShareReportIcon } from "@common/pages/report/integrated-reports/component/buttons/share-report/_index";
import { colIntegratedCustomReports } from "@common/pages/report/integrated-reports/custom-reports/config";
import {
  INTEGRATED_CUSTOM_REPORTS_ROUTE,
  INTEGRATED_CUSTOM_REPORTS_ROUTE_ODATA,
} from "@common/pages/report/integrated-reports/custom-reports/constant";
import { IntegratedReport } from "@common/pages/report/integrated-reports/custom-reports/model";
import {
  getBookmarkDetailReport,
  getBookmarkDisplayNameReport,
  getBookmarkListViewDetailReport,
  getBookmarkListViewDisplayNameReport,
} from "@common/pages/report/integrated-reports/custom-reports/util";
import { integratedReportsRoute } from "@common/pages/report/integrated-reports/route";
import { reportsRoute } from "@common/pages/report/route";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { usePermissionStore } from "@common/pages/settings/store";
import { commonCoreStore } from "@common/stores/core/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGridEventType } from "@components/cc-grid/constant";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<IntegratedReport>();

export default observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.CORE_DASHBOARD_REPORTS,
      1
    );
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: reportsRoute.name,
    leftComponents: [
      <ReportsActionBarNavDropdown category={integratedReportsRoute.path} />,
    ],

    centerComponents: [
      <CCNavButton
        title="New Instant Report"
        externalLinkTo={`${commonCoreStore.siteBaseUrl}Secure/Content/Core/IntegratedReports/InstantReport.aspx`}
        disabled={!hasPermission}
      />,
      <CCNavButton
        title="New Report"
        externalLinkTo={`${commonCoreStore.siteBaseUrl}Secure/Content/Core/IntegratedReports/ReportDesigner.aspx`}
        disabled={!hasPermission}
      />,
      <CCNavButton
        title="New Dashboard"
        externalLinkTo={`${commonCoreStore.siteBaseUrl}Secure/Content/Core/IntegratedReports/Dash.aspx#?new`}
        disabled={!hasPermission}
      />,
      <CCNavButton
        title="Refresh Report List"
        onClick={() => eventEmitter.emit(CCGridEventType.RefreshOData)}
        disabled={!hasPermission}
      />,
      <DeleteReportButton disabled={!hasPermission} />,
      <CCNavButton title="Tools" type="more" disabled={!hasPermission}>
        <ChangeOwnerButton />
        <ImportButton />
        <ExportXmlButton />
        <RefreshAllSharingButton />
        <CCNavButton title="Refresh data cache" />
      </CCNavButton>,
    ],
    rightComponents: [
      <ShareReportIcon disabled={!hasPermission} />,
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={INTEGRATED_CUSTOM_REPORTS_ROUTE}
        productType={PRODUCT_TYPE.Reports}
        recordType={RECORDTYPE.CORE_Report}
        detail={getBookmarkDetailReport}
        displayName={getBookmarkDisplayNameReport}
        listViewDisplayName={getBookmarkListViewDisplayNameReport}
        listViewDetail={getBookmarkListViewDetailReport}
        isDisabledRecordListView={!hasPermission}
      />,
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colIntegratedCustomReports}
      dataUrl={INTEGRATED_CUSTOM_REPORTS_ROUTE_ODATA}
      primaryField={nameOf("ID")}
      disabled={!hasPermission}
    />
  );
});
