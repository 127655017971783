import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { ReinstateJobQueueButton } from "@common/pages/settings/system-admin/job-queue-failures/components/action-bar/buttons/reinstate/_index";
import { SYSTEMADMIN_JOBQUEUEFAILURES_LIST_VIEW_URL } from "@common/pages/settings/system-admin/job-queue-failures/constant";
import { ProcessPool } from "@common/pages/settings/system-admin/job-queue/model";
import { SystemAdminJobQueueDetailTab } from "@common/pages/settings/system-admin/job-queue/reference-sidebar/detail/_index";
import { nameOfFactory } from "@common/utils/common";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colJobQueueFailures } from "@common/pages/settings/system-admin/job-queue-failures/config";

const nameOf = nameOfFactory<ProcessPool>();
export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    const responseCheckGlobalSettings = await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );

    if (responseCheckGlobalSettings) {
      await checkPermissionForCurrentMenu(
        FormActionCheckPermission.SETTINGS_MENU,
        1
      );
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [<ReinstateJobQueueButton />],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      //<ActionBarBookmarkIcon />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <SystemAdminJobQueueDetailTab />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      dataUrl={SYSTEMADMIN_JOBQUEUEFAILURES_LIST_VIEW_URL}
      columnFields={colJobQueueFailures}
      primaryField={nameOf("ID")}
      disabled={!hasPermission}
    />
  );
};
