import { CCPopupControl } from "@components/cc-popup-control/_index";
import {
  DATE_TIME_PICKER_HEIGHT,
  DATE_TIME_PICKER_MARGIN,
} from "@components/cc-time-picker/config";
import { TimePicker, TimePickerProps } from "@progress/kendo-react-dateinputs";
import { Error } from "@progress/kendo-react-labels";
import { isDate, isString } from "lodash";
import React, { useCallback, useMemo, useRef } from "react";
import "./_index.scss";
interface ICCTimePickerProps {
  validationMessage?: string | null;
  visited?: boolean;
}

export const CCTimePicker = (props: ICCTimePickerProps & TimePickerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { validationMessage, visited, value, ...others } = props;

  const dateTimeValue = useMemo(() => {
    if (value && isString(value)) {
      return new Date(value);
    }
    if (isDate(value)) return value;
    return null;
  }, [value]);

  const renderPopup = useCallback(
    (props: any) => {
      return (
        <CCPopupControl
          popup={props}
          container={ref.current}
          controlHeight={DATE_TIME_PICKER_HEIGHT}
          controlMargin={DATE_TIME_PICKER_MARGIN}
        />
      );
    },
    [ref]
  );

  return (
    <div ref={ref} className="cc-date-time-picker">
      <TimePicker {...others} value={dateTimeValue} popup={renderPopup} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
