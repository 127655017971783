import { SharePointDocumentPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/share-point-document/_index";
import { Svc_SharepointDocumentLibrary } from "@common/models/corporateSetting";

import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { SharePointVerifyButton } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/components/button/share-point-test";
import { SharePointCredentialsEnum } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/share-point/model";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCCustomEditor } from "@components/cc-custom-editor/_index";
import { ICCInputPickerChangeEvent } from "@components/cc-input-picker/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface IRecordsSharePoint {
  formRenderProps: FormRenderProps;
}

const nameOfSharePointDocument = nameOfFactory<Svc_SharepointDocumentLibrary>();
export const RecordsSharePoint = observer(
  ({ formRenderProps }: IRecordsSharePoint) => {
    const { isEditing } = useSettingGlobalManageStore();
    const { getConfigDataField } = useSettingGlobalStore();

    const { valueGetter, onChange } = formRenderProps;

    const fieldSharePointURL = getConfigDataField(
      ECorporateSettingsField.SharePoint_URL
    );
    const fieldSharePointCredentials = getConfigDataField(
      ECorporateSettingsField.SharePoint_Credentials
    );
    const fieldSharePointEnableImpersonation = getConfigDataField(
      ECorporateSettingsField.SharePoint_Impersonation
    );
    const fieldSharePointAuthType = getConfigDataField(
      ECorporateSettingsField.SharePoint_AuthType
    );
    const fieldSharePointDocumentLibrary = getConfigDataField(
      ECorporateSettingsField.SharePoint_DocumentLibrary
    );
    const fieldSharePointDocumentSet = getConfigDataField(
      ECorporateSettingsField.SharePoint_DocumentSet
    );
    const fieldSharePointTitleFormat = getConfigDataField(
      ECorporateSettingsField.SharePoint_TitleFormat
    );
    const fieldSharePointShowSuggestionDialog = getConfigDataField(
      ECorporateSettingsField.SharePoint_ShowSuggestionDailog
    );
    const fieldSharePointSuggestion = getConfigDataField(
      ECorporateSettingsField.SharePoint_SuggestionDailogText
    );
    const fieldSharePointSaveCommunications = getConfigDataField(
      ECorporateSettingsField.SharePoint_SaveCommunications
    );

    const documentLibraryValueDisplayDetails: Svc_SharepointDocumentLibrary[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.SharePoint_DocumentLibrary}`
      ) ?? [{ ID: null, Title: fieldSharePointDocumentLibrary?.Value }];
    const documentSetValueDisplayDetails: Svc_SharepointDocumentLibrary[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.SharePoint_DocumentSet}`
      ) ?? [{ ID: null, Title: fieldSharePointDocumentSet?.Value }];

    const handleOnChangeDocumentLibraryValue = (
      type: Svc_SharepointDocumentLibrary
    ) => {
      onChange(fieldSharePointDocumentLibrary?.FieldName ?? "", {
        value: type.Title.toString(),
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.SharePoint_DocumentLibrary}`,
        {
          value: [type],
        }
      );
    };

    const handleOnChangeDocumentSetValue = (
      type: Svc_SharepointDocumentLibrary
    ) => {
      onChange(fieldSharePointDocumentSet?.FieldName ?? "", {
        value: type.Title.toString(),
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.SharePoint_DocumentSet}`,
        {
          value: [type],
        }
      );
    };

    return (
      <>
        {fieldSharePointURL && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldSharePointURL}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldSharePointCredentials && (
          <div className="cc-form-cols-1">
            <CCSearchComboBoxGlobalSettings
              data={fieldSharePointCredentials}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldSharePointEnableImpersonation && (
          <div className="cc-form-cols-1">
            <SwitchGlobalSettings
              data={fieldSharePointEnableImpersonation}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          </div>
        )}

        {fieldSharePointAuthType &&
          (valueGetter(fieldSharePointCredentials?.FieldName ?? "") ===
            SharePointCredentialsEnum.NetworkCredentialsSiteUserRecords ||
            valueGetter(fieldSharePointCredentials?.FieldName ?? "") ===
              SharePointCredentialsEnum.NetworkCredentialsProxy) && (
            <div className="cc-form-cols-1">
              <InputGlobalSettings
                data={fieldSharePointAuthType}
                isEditing={isEditing}
              />
            </div>
          )}

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title={"SharePoint Test Connection"} />
            <SharePointVerifyButton isEditing={isEditing} />
          </div>
        </div>

        {/* Add Picker Dialog Later */}
        {fieldSharePointDocumentLibrary && (
          <div className="cc-field">
            <CCLabel
              title={fieldSharePointDocumentLibrary?.Title ?? ""}
              isMandatory={fieldSharePointDocumentLibrary.IsMandatory}
            />
            {fieldSharePointDocumentLibrary?.Description ? (
              <label className="text-error">
                {sanitizeHtml(
                  fieldSharePointDocumentLibrary?.Description ?? ""
                )}
              </label>
            ) : null}
            <Field
              name={fieldSharePointDocumentLibrary?.FieldName ?? ""}
              placeholder={fieldSharePointDocumentLibrary?.Title ?? ""}
              value={documentLibraryValueDisplayDetails[0]}
              component={SharePointDocumentPicker}
              disabled={!isEditing}
              isSharePointDocumentLibraries
              selectableMode="single"
              onPickTypes={handleOnChangeDocumentLibraryValue}
              textField={nameOfSharePointDocument("Title")}
              textProduce={(data: Svc_SharepointDocumentLibrary) => data.Title}
              onInputChange={(event: ICCInputPickerChangeEvent) => {
                onChange(fieldSharePointDocumentLibrary?.FieldName ?? "", {
                  value: event.value,
                });
                onChange(
                  `option.ValueDisplayDetails_${ECorporateSettingsField.SharePoint_DocumentLibrary}`,
                  {
                    value: [
                      {
                        ID: null,
                        Title: event.value,
                      },
                    ],
                  }
                );
              }}
              validator={
                fieldSharePointDocumentLibrary.IsMandatory
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
        )}

        {fieldSharePointDocumentSet && (
          <div className="cc-field">
            <CCLabel
              title={fieldSharePointDocumentSet?.Title ?? ""}
              isMandatory={fieldSharePointDocumentSet.IsMandatory}
            />
            {fieldSharePointDocumentSet?.Description ? (
              <label className="text-error">
                {sanitizeHtml(fieldSharePointDocumentSet?.Description ?? "")}
              </label>
            ) : null}
            <Field
              name={fieldSharePointDocumentSet?.FieldName ?? ""}
              placeholder={fieldSharePointDocumentSet?.Title ?? ""}
              value={documentSetValueDisplayDetails[0] ?? null}
              component={SharePointDocumentPicker}
              disabled={!isEditing}
              selectableMode="single"
              onPickTypes={handleOnChangeDocumentSetValue}
              textField={nameOfSharePointDocument("Title")}
              textProduce={(data: Svc_SharepointDocumentLibrary) => data.Title}
              validator={
                fieldSharePointDocumentSet.IsMandatory
                  ? requiredValidator
                  : undefined
              }
              onInputChange={(event: ICCInputPickerChangeEvent) => {
                onChange(fieldSharePointDocumentSet?.FieldName ?? "", {
                  value: event.value,
                });
                onChange(
                  `option.ValueDisplayDetails_${ECorporateSettingsField.SharePoint_DocumentSet}`,
                  {
                    value: [
                      {
                        ID: null,
                        Title: event.value,
                      },
                    ],
                  }
                );
              }}
            />
          </div>
        )}

        {fieldSharePointTitleFormat && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldSharePointTitleFormat}
              isEditing={isEditing}
            />
          </div>
        )}

        <div className="cc-form-cols-3">
          {fieldSharePointShowSuggestionDialog && (
            <SwitchGlobalSettings
              data={fieldSharePointShowSuggestionDialog}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}

          {fieldSharePointSaveCommunications && (
            <SwitchGlobalSettings
              data={fieldSharePointSaveCommunications}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
        </div>

        {fieldSharePointSuggestion && (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title={fieldSharePointSuggestion?.Title ?? ""} />
              {/* Add Suggestion Text*/}
              <CCCustomEditor
                value={valueGetter(fieldSharePointSuggestion?.FieldName ?? "")}
                onChangeCustomEditor={(value: string) => {
                  onChange(fieldSharePointSuggestion?.FieldName, {
                    value: value,
                  });
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
);
