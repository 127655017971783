import { DTO_COO_Type } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_COO_Type>();
export const colTypeChangeOfOwnershipList: IColumnFields[] = [
  {
    title: "Type of Change",
    field: nameOf("COOT_Name"),
  },
];
