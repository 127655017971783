import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { CancelTokenSource } from "axios";

export const loadOData = async (
  pathName: string,
  requestController?: CancelTokenSource
): Promise<APIResponse> => {
  try {
    return await CoreAPIService.getClient().get(pathName, {
      cancelToken: requestController?.token,
    });
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error:
        requestController?.token.reason?.message ??
        errorResponse?.data?.Message,
    };
  }
};
