import { WastewaterFieldMapping } from "@app/products/waste-water/model";
import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colWastewaterSystemsByAuthority: IColumnFields[] = [
  {
    field: WastewaterFieldMapping.PermitNumber,
    title: "Permit Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${WASTE_WATER_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: WastewaterFieldMapping.Applicant,
    title: "Applicant",
  },
  {
    field: WastewaterFieldMapping.Owner,
    title: "Owner",
  },
  {
    field: WastewaterFieldMapping.Address,
    title: "Address",
  },
  {
    field: WastewaterFieldMapping.Suburb,
    title: "Suburb",
  },
  {
    field: WastewaterFieldMapping.AssessmentNo,
    title: "Assessment Number",

    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: WastewaterFieldMapping.Status,
    title: "Status",
  },
  {
    field: WastewaterFieldMapping.TypeOfApplication,
    title: "Permit Type",
  },
  {
    field: WastewaterFieldMapping.FileNumber,
    title: "File Number",

    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: WastewaterFieldMapping.SystemType,
    title: "System Type",
  },
  {
    field: WastewaterFieldMapping.Received,
    title: "Received",

    format: DATE_FORMAT.DATE,
  },
  {
    field: WastewaterFieldMapping.PTI,
    title: "PTI",

    format: DATE_FORMAT.DATE,
  },
  {
    field: WastewaterFieldMapping.CTU,
    title: "ATU",
  },
  {
    field: WastewaterFieldMapping.WaterAuthority,
    title: "Authority",
  },
  {
    field: WastewaterFieldMapping.Plumber,
    title: "Plumber",
  },
  {
    field: WastewaterFieldMapping.CertificateNo,
    title: "Certificate Number",

    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: WastewaterFieldMapping.SerialNo,
    title: "Serial Number",
  },
];
