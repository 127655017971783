import { DBRowState } from "@app/products/crms/[id]/model";
import {
  FeeType,
  Svc_FeeTypeView,
} from "@common/pages/settings/lookups/fee-types/model";
import { flexibleFormStoreInstance } from "@common/stores/flexible-form/store";

export const LookupsFeeTypeBookmark = {
  getBookmarkListViewDisplayName() {
    return "Settings - Lookups";
  },

  getBookmarkListViewDetail(viewName: string) {
    return `Settings - Lookups - ${viewName}`;
  },
  getBookmarkListViewDetailRecordGroup(selectedRow: Svc_FeeTypeView) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Settings - Lookups - Fee Types  ${dynamicDisplayName}`;
  },
};

export const checkIsInactiveFeeType = (): boolean => {
  const data = flexibleFormStoreInstance?.dataForms?.GeneralForm as FeeType;
  return (data?.Sys_DBRowState as DBRowState) === DBRowState.Inactive;
};
