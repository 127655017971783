import { ContactClassification } from "@app/core/contacts/_id/model";
import { generateGetContactURL } from "@app/core/contacts/components/dialogs/pick-contact/util";
import { ICrmsUsersPicker } from "@app/products/crms/components/dialogs/pick-users-groups/model";
import { nameOfFactory } from "@common/utils/common";
import { GridSearch } from "@components/cc-grid-search/_index";
import { ICCGridProps } from "@components/cc-grid/_index";
import { State } from "@progress/kendo-data-query";
import React, { useState } from "react";

interface IUsersGridProps extends ICCGridProps {
  handleSelectionChange: (e: ICrmsUsersPicker[]) => void;
}
export const nameOfUsers = nameOfFactory<ICrmsUsersPicker>();
export const CrmsUsersGrid = (props: IUsersGridProps) => {
  const {
    handleSelectionChange,
    selectedRows,
    columnFields,
    selectableMode,
    primaryField,
  } = props;

  const [stateData, setStateData] = useState<State>({
    sort: [{ field: nameOfUsers("DisplayName"), dir: "asc" }],
  });

  return (
    <div className="cc-grid-selection-dialog">
      <GridSearch
        grid={{
          primaryField: primaryField,
        }}
        search={{
          hasSearchField: true,
          filterCol: nameOfUsers("DisplayName"),
          sortCol: nameOfUsers("DisplayName"),
        }}
        columnFields={columnFields}
        selectableMode={selectableMode}
        selectedRows={selectedRows}
        dataUrl={`${generateGetContactURL({
          classificationFilters: `'${ContactClassification.SiteUser}'`,
          hideCouncillors: "true",
        })}?$count=true&`}
        handleSelectionChange={handleSelectionChange}
        onDataStateChange={(state: State) => {
          setStateData(state);
        }}
        state={stateData}
      />
    </div>
  );
};
