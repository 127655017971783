import { eventEmitter } from "@/App";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { CCJournalEventType } from "@app/core/journal/util";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useState } from "react";

interface DeleteKeywordButtonProps {
  disabled?: boolean;
}
export const DeleteKeywordButton = observer(
  ({ disabled }: DeleteKeywordButtonProps) => {
    const { gridSelectedRows, gridSelectedIds } = useCCProductListViewStore();
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const [isShowDialog, setIsShowDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
      []
    );

    const handleOnDelete = async (data: IDeleteConfirmation) => {
      setIsDeleting(true);
      const response = await deleteRefUsageByIdAndRecordType(
        gridSelectedIds,
        RECORDTYPE.CORE_Keyword,
        data?.Reason
      );
      setIsDeleting(false);
      if (isSuccessResponse(response)) {
        clearErrorNotification();
        setIsShowDialog(false);

        pushNotification({
          title: `${pluralize(
            "Keyword",
            gridSelectedIds.length
          )} deleted successfully`,
          type: "success",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(CCJournalEventType.RefreshData);
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: `Delete ${pluralize(
              "keyword",
              gridSelectedIds.length
            )} failed`,
            type: "error",
            description: response.data?.Errors,
          },
        ]);
      }
    };
    return (
      <>
        <CCNavButton
          title="Delete"
          onClick={() => setIsShowDialog(true)}
          disabled={!gridSelectedRows?.length || disabled}
        />
        {isShowDialog && (
          <ConfirmDelete
            onClose={() => setIsShowDialog(false)}
            handleSubmit={handleOnDelete}
            isDeleting={isDeleting}
            contentDelete={"Keywords"}
            header={"Confirm Delete"}
            notifications={notifications}
          />
        )}
      </>
    );
  }
);
