import { ICCRoute } from "@common/constants/ICCRoute";
import { isVisibleHelpDocument } from "@common/pages/settings/lookups/help-documents/util";

export const helpDocumentRoute: ICCRoute = {
  path: "help-document",
  name: "Help Documents",
  component: require("./_index").default,
  isVisible: () => isVisibleHelpDocument(),
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./_id/_index").default,
    },
  ],
};
