import { odataCoreGetKeywordsByType } from "@app/core/components/common/utils";
import { colRestrictedBreedDialog } from "@app/products/animals/system-admin/settings/_id/main/settings/dog-settings/forms/restricted-breed/config";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { Keyword } from "@common/models/keyword";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";

interface IPickRestrictedBreedDialogProps {
  handleSubmit: (value: Keyword[]) => void;
  onClose: () => void;
  maxHeight?: string;
}

const nameOf = nameOfFactory<Keyword>();

export const PickRestrictedBreedDialog = ({
  onClose,
  maxHeight,
  handleSubmit,
}: IPickRestrictedBreedDialogProps) => {
  const [gridSelectedRows, setGridSelectedRows] = useState<Keyword[] | []>([]);

  return (
    <CCDialog
      height="auto"
      maxHeight={maxHeight}
      width="50%"
      unsetMinHeight={true}
      titleHeader={"Dog Breed"}
      onClose={onClose}
      bodyElement={
        <CCGrid
          style={{ height: "100%" }}
          selectableMode="multiple"
          isAutoHiddenPager={false}
          itemPerPage={10}
          dataUrl={`${odataCoreGetKeywordsByType(
            KEYWORD_TYPE.Animals_DogBreeds,
            PRODUCT_TYPE_NUMBER.Animals
          )}?$count=true&`}
          primaryField={nameOf("Keyword_ID")}
          selectedRows={gridSelectedRows}
          onSelectionChange={(dataItem: Keyword[]) => {
            setGridSelectedRows([...dataItem]);
          }}
          columnFields={colRestrictedBreedDialog}
          state={{
            sort: [{ field: nameOf("Name"), dir: "asc" }],
          }}
        />
      }
      footerElement={
        <div className={"cc-dialog-footer-actions-right"}>
          <Button className={"cc-dialog-button"} onClick={onClose}>
            Cancel
          </Button>
          <Button
            className={"cc-dialog-button"}
            themeColor="primary"
            onClick={() => handleSubmit(gridSelectedRows)}
            disabled={!gridSelectedRows?.length}
          >
            Add
          </Button>
        </div>
      }
    />
  );
};
