import { useDebounce } from "@common/hooks/useDebounce";
import { colLPickTagsForFee } from "@common/pages/settings/lookups/fee-types/_id/components/general/pick-tags-for-fee/config";
import { getListTagByProductEnum } from "@common/pages/settings/lookups/fee-types/_id/components/general/pick-tags-for-fee/utils";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export interface IPickTagsForFeeProps {
  productTags: string; //SystemFilter_ENUMS
  onSubmit: (data: string[]) => void;
  onCloseDialog: () => void;
}

export interface IPickTags {
  ProductTypeName: string;
  Key: string;
  Value: string;
}

const nameOf = nameOfFactory<IPickTags>();
const searchingPropKey = nameOf("Value");
export const PickTagsForFeeDialog = ({
  productTags,
  onSubmit,
  onCloseDialog,
}: IPickTagsForFeeProps) => {
  const [searchKey, setSearchKey] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [gridSelectedRows, setGridSelectedRows] = useState<IPickTags[]>([]);
  const [gridData, setGridData] = useState<IPickTags[]>([]);
  const [gridDefaultData, setGridDefaultData] = useState<IPickTags[]>([]);

  const debouncedSearch = useDebounce(searchKey, 500);

  const handleOnSubmit = (event: IPickTags[]) => {
    onSubmit(event.map((item) => item.Value));
  };

  const handleOnLocalSearch = async (searchText: string) => {
    setGridData(() => {
      if (searchText !== "" && searchingPropKey) {
        return gridData.filter((x) =>
          x?.[searchingPropKey].toLowerCase().includes(searchText.toLowerCase())
        );
      }
      return gridDefaultData;
    });
  };

  useEffect(() => {
    (async () => {
      setIsSearching(true);
      await handleOnLocalSearch(debouncedSearch);
      setIsSearching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  useEffectOnce(() => {
    if (!isEmpty(productTags)) {
      var productEnums = productTags
        ?.split("|")
        ?.filter((e) => e !== "")
        ?.map((x) => parseInt(x));
      setGridData(getListTagByProductEnum(productEnums));
      setGridDefaultData(getListTagByProductEnum(productEnums));
    }
  });

  return (
    <CCDialog
      titleHeader={"Pick Fee Description Tags"}
      dialogWrapperClassName="cc-fee-tags-picker-search"
      maxWidth="45%"
      maxHeight="70%"
      onClose={onCloseDialog}
      bodyElement={
        <div className="cc-search-result">
          <div className="cc-search-result-title">
            <CCLabel title="Search" />
            <Field
              name={"searchKey"}
              component={CCInput}
              placeholder="Search"
              onChange={(e: InputChangeEvent) => {
                setSearchKey(e.value);
              }}
            />
          </div>
          <div className="cc-search-result-body">
            <CCGrid
              columnFields={colLPickTagsForFee}
              primaryField={nameOf("Key")}
              selectableMode={"multiple"}
              itemPerPage={5}
              data={gridData}
              onSelectionChange={(dataItems) => {
                setGridSelectedRows(dataItems);
              }}
              selectedRows={gridSelectedRows}
              isLoading={isSearching}
              isAutoHiddenPager={false}
            />
          </div>
        </div>
      }
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Button
            className="cc-dialog-button"
            themeColor="primary"
            disabled={gridSelectedRows.length === 0}
            onClick={() => handleOnSubmit(gridSelectedRows)}
          >
            Select
          </Button>
        </div>
      }
    />
  );
};
