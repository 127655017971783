import { ActivateButton } from "@app/products/property/system-admin/document-template/[id]/components/child-screens/general/components/buttons/active-document/_index";
import { DocumentTemplateForm } from "@app/products/property/system-admin/document-template/[id]/components/child-screens/general/_index";
import { useDocumentTemplateStore } from "@app/products/property/system-admin/document-template/[id]/store";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const ExistedDocumentTemplate = observer(() => {
  const { id } = useParams<{ id: string }>();
  const {
    isLoading,
    isLoadingSave,
    documentTemplate,
    responseLoadError,
    loadDocumentTemplate,
    isDeletedRecord,
  } = useDocumentTemplateStore();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        documentTemplate?.DocumentTemplate_ID,
        documentTemplate?.Name,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Document Template - ${
        documentTemplate?.DocumentTemplate_ID ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_CommunityCentral_Other,
      Record_ID: +id ?? 0,
    },
  ];
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Document Template"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadDocumentTemplate(+id);
          }}
        />
      ) : (
        <>
          <FormTitle
            title={
              documentTemplate ? documentTemplate.Name : "Document Template"
            }
          />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                htmlFor={"btn-submit"}
                disabled={isDeletedRecord}
                isLoading={isLoadingSave}
              />,
              <ActivateButton />,
            ]}
            rightActions={[
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                <DocumentTemplateForm />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
