/* eslint-disable jsx-a11y/anchor-is-valid */
import { ConfirmExit } from "@app/products/property/system-admin/settings/_id/components/dialogs/confirm-exit/_index";
import { useSettingConfirmExitStore } from "@app/products/property/system-admin/settings/_id/components/dialogs/confirm-exit/store";
import { useSettingNewButtonStore } from "@app/products/property/system-admin/settings/components/action-bar/buttons/new/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";
export interface SettingParentFolders {
  Name: string;
  Title: string;
}

const Breadcrumb = observer(({ breadcrumb: data, isSettingFolder }: any) => {
  let parentFolders: SettingParentFolders[] = data?.ParentFolders;
  const [isShowDialog, setIsShowDialog] = useState(false);
  const history = useHistory();
  const { confirmExit, setConfirmExit } = useSettingConfirmExitStore();
  const { setIsNew } = useSettingNewButtonStore();

  return (
    <div className="cc-breadcrumb">
      <div>
        <button
          className="k-button k-button-icon cc-back-button"
          style={{ backgroundColor: "#68b8cf" }}
          onClick={() => {
            if (confirmExit) {
              setIsShowDialog(true);
            } else {
              history.goBack();
              setIsNew(false);
            }
          }}
        >
          <span role="presentation" className="fal fa-arrow-left"></span>
        </button>
      </div>
      <div className="cc-nav-breadcrumb">
        <nav className="cc-nav-breadcrumb" aria-label="Breadcrumb">
          <ol className="cc-breadcrumb-items">
            {isSettingFolder ? (
              parentFolders &&
              parentFolders?.map((item: any, index: number) => {
                return parentFolders.length > 3 &&
                  parentFolders.length - (index + 2) > 1 ? (
                  index === 1 ? (
                    <li
                      className={`cc-breadcrumb-item ${
                        index === parentFolders.length - 1 && "active"
                      }`}
                      key={index}
                      onClick={() => {
                        let url: any;
                        let temp = parentFolders
                          .filter((item: any, index: number) => index <= 1)
                          .map((elm: SettingParentFolders) => elm.Name);
                        url = temp.join("/");
                        index !== parentFolders.length - 1 &&
                          history.push(history.location.pathname, {
                            requestUrl: `${data.ProductType_Enum}/${url}`,
                          });
                      }}
                    >
                      <a
                        href=""
                        onClick={(event: any) => {
                          event.preventDefault();
                        }}
                      >
                        ...
                      </a>
                    </li>
                  ) : (
                    ""
                  )
                ) : (
                  <li
                    className={`cc-breadcrumb-item ${
                      index === parentFolders.length - 1 && "active"
                    }`}
                    key={index}
                    onClick={() => {
                      let url: any;
                      let indexItem = parentFolders.findIndex(
                        (ele: SettingParentFolders) => ele.Name === item.Name
                      );
                      let temp = parentFolders
                        .filter(
                          (item: any, index: number) => index <= indexItem
                        )
                        .map((elm: SettingParentFolders) => elm.Name);
                      url = temp.join("/");
                      index !== parentFolders.length - 1 &&
                        history.push(history.location.pathname, {
                          requestUrl: `/${data.ProductType_Enum}/${url}`,
                        });
                    }}
                  >
                    {index === parentFolders.length - 1 ? (
                      item.Title
                    ) : (
                      <a
                        href=""
                        onClick={(event: any) => {
                          event.preventDefault();
                        }}
                      >
                        {item.Title}
                      </a>
                    )}
                  </li>
                );
              })
            ) : (
              <li className="cc-breadcrumb-item active">
                {parentFolders &&
                  parentFolders.slice(-1)[0]?.Title?.toUpperCase()}
              </li>
            )}
          </ol>
        </nav>
      </div>
      {isShowDialog && (
        <ConfirmExit
          onClose={() => {
            setIsShowDialog(false);
          }}
          onClick={() => {
            history.goBack();
            setConfirmExit(false);
          }}
        />
      )}
    </div>
  );
});

export default Breadcrumb;
