import { AmsAssetRegister } from "@app/products/ams/assets/[id]/model";

export const mockDefaultAssetRegister: AmsAssetRegister = {
  Id: 1,
  AssetNumber: "",
  AssetDescription: "",
  AssetCategoryId: 0,
  SerialNumber: "",
  EquipmentNumber: "",
  AssetStatusId: 2,
  DesignLife: 0,
  WarrantyEndDate: null,
  EmployeeCustodian: "",
  EmployeeNumber: "",
  DepartmentId: null,
  LocationId: null,
  CostCentreId: null,
  OsProductId: null,
  Comments: "",
  CatalogueId: 1,
  Status: "",
};
