import { PhoneMessageStatus } from "@app/products/crms/phone-messages/[id]/model";
import { useCRMSPhoneMessageStore } from "@app/products/crms/phone-messages/[id]/store";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue, splitCamelCase } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const CRMSPhoneMessageDetailsTab = observer(() => {
  const {
    crmsPhoneMessageDetails,
    isLoadingDetails,
    loadCRMSPhoneMessageDetails,
    resetDetailsCrmsPhoneMessage,
    responseLoadError,
  } = useCRMSPhoneMessageStore();

  const { lastSelectedId } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const phoneMessageId = params.id || lastSelectedId;

  useEffect(() => {
    if (phoneMessageId) {
      loadCRMSPhoneMessageDetails(phoneMessageId);
    }
    return () => {
      resetDetailsCrmsPhoneMessage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneMessageId]);

  if (isLoadingDetails) return <Loading isLoading={isLoadingDetails} />;

  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          phoneMessageId && loadCRMSPhoneMessageDetails(phoneMessageId);
        }}
      />
    );
  }

  if (!crmsPhoneMessageDetails) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Reference Number:"}
        value={crmsPhoneMessageDetails?.Event_ReferenceNo + ""}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Status:"}
          value={splitCamelCase(
            PhoneMessageStatus[crmsPhoneMessageDetails?.Status_ENUM] || ""
          )}
        />
        <ProductReferenceRow
          title={"Date Forwarded:"}
          value={formatDisplayValue(
            crmsPhoneMessageDetails?.DateForwarded,
            DATETIME_FORMAT.DATETIME
          )}
        />
        <ProductReferenceRow
          title={"Date Sent:"}
          value={formatDisplayValue(
            crmsPhoneMessageDetails?.DateSent,
            DATETIME_FORMAT.DATETIME
          )}
        />
        {crmsPhoneMessageDetails?.Status_ENUM ===
          PhoneMessageStatus.Completed && (
          <ProductReferenceRow
            title={"Completed:"}
            value={formatDisplayValue(
              crmsPhoneMessageDetails?.DateCompleted,
              DATE_FORMAT.DATE
            )}
          />
        )}
        <ProductReferenceRow
          title={"Date Target:"}
          value={formatDisplayValue(
            crmsPhoneMessageDetails?.DateTarget,
            DATE_FORMAT.DATE
          )}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
