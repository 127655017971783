export const mockSchemeAccountNotices = [
  {
    NoticeNumber: "1",
    IssueDate: new Date("29-Jul-17"),
    DueDate: new Date("13-Oct-2017"),
    PrincipalBalance: 12041.74,
    AmountDue: 12041.74,
    OpeningBalance: 0.0,
    OpeningAmountDue: 0.0,
    PrincipalBecomingDue: 12041.74,
    CurrentAmountDue: 12041.74,
    PrincipalDue: 12041.74,
    InterestBalance: 0.0,
    BalanceOutstanding: 12041.74,
    PayoutAmount: 12041.74,
    NameAddress: "1 Binnak Dr VIC 3813",
    RunDate: new Date("29-Jul-17"),
    CreatedBy: "HodgesR",
    AccountNoticeId: "14546",
  },
  {
    NoticeNumber: "2",
    IssueDate: new Date("7-Nov-17"),
    DueDate: new Date("22-Jan-2018"),
    PrincipalBalance: 12041.74,
    AmountDue: 12041.74,
    OpeningBalance: 12041.74,
    OpeningAmountDue: 12041.74,
    CurrentAmountDue: 0.0,
    PrincipalArrears: 12041.74,
    ArrearsAmountDue: 12041.74,
    PrincipalDue: 12041.74,
    InterestBalance: 0.0,
    BalanceOutstanding: 12041.74,
    PayoutAmount: 12041.74,
    NameAddress: "1 Binnak Dr VIC 3813",
    RunDate: new Date("7-Nov-17"),
    CreatedBy: "HodgesR",
    AccountNoticeId: "14695",
  },
  {
    NoticeNumber: "3",
    IssueDate: new Date("7-Nov-17"),
    DueDate: new Date("22-Jan-2018"),
    PrincipalBalance: 12041.74,
    AmountDue: 12041.74,
    OpeningBalance: 12041.74,
    OpeningAmountDue: 12041.74,
    CurrentAmountDue: 0.0,
    PrincipalArrears: 12041.74,
    ArrearsAmountDue: 12041.74,
    PrincipalDue: 12041.74,
    InterestBalance: 0.0,
    BalanceOutstanding: 12041.74,
    PayoutAmount: 12041.74,
    NameAddress: "1 Binnak Dr VIC 3813",
    RunDate: new Date("7-Nov-17"),
    CreatedBy: "HodgesR",
    AccountNoticeId: "14844",
  },
  {
    NoticeNumber: "4",
    IssueDate: new Date("28-Jun-2018"),
    DueDate: new Date("12-Sep-2018"),
    PrincipalBalance: 12041.74,
    AmountDue: 12041.74,
    OpeningBalance: 12041.74,
    OpeningAmountDue: 12041.74,
    CurrentAmountDue: 0.0,
    PrincipalArrears: 12041.74,
    ArrearsAmountDue: 12041.74,
    PrincipalDue: 12041.74,
    InterestBalance: 0.0,
    BalanceOutstanding: 12041.74,
    PayoutAmount: 12041.74,
    NameAddress: "1 Binnak Dr VIC 3813",
    RunDate: new Date("28-Jun-2018"),
    CreatedBy: "HodgesR",
    AccountNoticeId: "15120",
  },
];
