import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { NARAPISYSTEM } from "@common/constants/enumerations";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const APIIntegrationSettingsForm = ({
  formRenderProps,
}: ICommonFormProps) => {
  const { valueGetter } = formRenderProps;
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const data = useDataSetting(configData, [
    "CorporateSettings_NARAPISystem",
    "CorporateSettings_NARAPIURL",
    "CorporateSettings_NARAPIUserName",
    "CorporateSettings_NARAPIPassword",
    "CorporateSettings_NARBearerKey",
  ]);

  const narAPISystem = valueGetter(
    data.CorporateSettings_NARAPISystem?.FieldName as string
  );

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <CCSearchComboBoxGlobalSettings
          data={data.CorporateSettings_NARAPISystem}
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={data.CorporateSettings_NARAPIURL}
          isEditing={isEditing}
        />
      </div>
      {narAPISystem === NARAPISYSTEM.PortAdelaide.toString() ? (
        <InputGlobalSettings
          data={data.CorporateSettings_NARBearerKey}
          isEditing={isEditing}
        />
      ) : (
        <div className="cc-form-cols-2">
          <InputGlobalSettings
            data={data.CorporateSettings_NARAPIUserName}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_NARAPIPassword}
            isEditing={isEditing}
          />
        </div>
      )}
    </section>
  );
};

export default observer(APIIntegrationSettingsForm);
