import { colApplicantTypesPicker } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/psa/planning-scheme-amendment-referral/components/applicant-types-picker/config";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";
import { ICCGridProps } from "@components/cc-grid/_index";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React from "react";

const nameOf = nameOfFactory<IKeyValuePacket>();
export interface IApplicantTypesPickerProps extends ICCInputPickerProps {
  onPickApplicantTypes: (applicantTypes: IKeyValuePacket[]) => void;
  dataList: IKeyValuePacket[];
  grid?: ICCGridProps;
}

export const ApplicantTypesPicker = (props: IApplicantTypesPickerProps) => {
  const {
    onChange,
    onError,
    onPickApplicantTypes,
    valueField,
    valueProduce,
    textField,
    textProduce,
    dataList = [],
    grid,
    ...others
  } = props;

  const handleOnChange = (event: ICCInputPickerChangeEvent) => {
    const applicantTypes: IKeyValuePacket[] = event.value;
    if (onPickApplicantTypes) onPickApplicantTypes(applicantTypes);
  };

  return (
    <CCInputPicker
      {...others}
      dialog={{
        titleHeader: "PSA Applicant Types",
        maxWidth: "60%",
      }}
      grid={{
        ...grid,
        columnFields: colApplicantTypesPicker,
        primaryField: nameOf("Key"),
        selectableMode: "multiple",
        data: dataList,
        isAutoHiddenPager: false,
      }}
      onChange={handleOnChange}
      valueField={valueField}
      valueProduce={valueProduce}
      textField={textField}
      textProduce={textProduce}
    />
  );
};
