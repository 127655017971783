import { NoData } from "@components/no-data/NoData";
import React from "react";

const PreviousNames = () => {
  return (
    <div className="cc-contact-previous-names cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Previous names and addresses</label>
            <NoData vertical />
          </div>
          <div className="cc-field">
            <label className="cc-label">Audit history</label>
            <NoData vertical />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousNames;
