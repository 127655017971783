export const mockSchemeAccountAttributesUDA = {
  Attributes: [
    { Attribute: "area", Value: 854.2, ID: 1 },
    { Attribute: "Cert_NationBultdingAct", Value: 56, ID: 2 },
    {
      Attribute: "Cert_SiteComp_AffRent",
      Value: "25 May 2021 12:00AM",
      ID: 3,
    },
    {
      Attribute: "Cert_SiteCompinfrast",
      Value: "26 Mar 2021 12:00AM",
      ID: 4,
    },
    {
      Attribute: "Cert_SiteComp_SenHous",
      Value: "26 Mar 2021 12:00AM",
      ID: 5,
    },
    { Attribute: "Cert_SiteVerification", Value: 46, ID: 6 },
    { Attribute: "Cncl_Road_Wide_Res", Value: 45, ID: 7 },
    { Attribute: "Heritage_Schedule5_CSV", Value: "", ID: 8 },
  ],
};
