import { simpleDate } from "@app/products/ams/api/helpers/date-helper";
import {
  IWorkOrder,
  IWorkOrderTask,
  IWorkOrderTaskValues,
  IWorkOrderValues,
} from "@app/products/ams/model/workOrder";
import authHeader from "@common/apis/auth-header";
import { APIResponseStatus } from "@common/constants/response-status";
import { globalStoreInstance } from "@common/stores/global/store";
import { Toast } from "@components/toast";
import axios from "axios";
import { action, configure, makeObservable, observable } from "mobx";
import defaultWorkOrder, {
  createWorkOrderAsset,
  createWorkOrderResource,
  createWorkOrderTask,
  getCreateWorkOrderObject,
  isOverdue,
} from "./workorder-helper";
configure({ enforceActions: "always" }); // Strict mode: runInAction

export default class WorkOrderStore {
  isLoadingInitial = false;
  workOrderRegistry = new Map<string, IWorkOrder>();
  workOrder: IWorkOrderValues = defaultWorkOrder();
  selectedWorkOrder: IWorkOrder | null = null;
  isSubmitting = false;
  newWorkOrderId = 0;
  gridData: any = null;

  loadGrid = () => {};

  setNewWorkOrderId = (value: number) => {
    this.newWorkOrderId = value;
  };

  constructor() {
    makeObservable(this, {
      isLoadingInitial: observable,
      workOrderRegistry: observable,
      workOrder: observable,
      selectedWorkOrder: observable,
      isSubmitting: observable,
      newWorkOrderId: observable,
      gridData: observable,
      loadGrid: action,
      setNewWorkOrderId: action,
      loadFakeWorkOrders: action,
      loadWorkOrders: action,
      setSelectedWorkOrder: action,
      setTaskExpanded: action,
      loadWorkOrder: action,
      createWorkOrder: action,
      getNewWorkOrderNumber: action,
      saveNewWorkOrder: action,
      saveExistingWorkOrder: action,
    });
  }

  loadFakeWorkOrders() {
    let workOrder: IWorkOrderValues;
    let task: IWorkOrderTask;

    workOrder = getCreateWorkOrderObject(
      1,
      "WO000001",
      "Medium Pothole on North St",
      1,
      2,
      3,
      1,
      3,
      1,
      2,
      "Level 2 signage required before repair.",
      new Date("15/9/2020"),
      null,
      new Date("18/9/2020"),
      new Date("22/9/2020")
    );
    workOrder.Assets.push(createWorkOrderAsset(1, 1, "RD02", "North St", ""));
    task = createWorkOrderTask(1, 1, "Pothole Repair", 1, 360, 2);
    task.Resources?.push(
      createWorkOrderResource(
        1,
        1,
        1,
        "Joe Blogs",
        "Roadworks Contractor",
        2,
        90,
        180,
        null
      )
    );
    task.Resources?.push(
      createWorkOrderResource(
        2,
        2,
        2,
        "Grader 2",
        "South Depot Plant Grader",
        2,
        90,
        180,
        null
      )
    );
    workOrder.Tasks?.push(task);
    this.workOrderRegistry.set(workOrder.WorkOrderId.toString(), workOrder);

    workOrder = getCreateWorkOrderObject(
      2,
      "WO000002",
      "Storm damage to curb",
      2,
      1,
      2,
      1,
      3,
      2,
      2,
      "Submersible pump required for drainage",
      simpleDate(new Date(2020, 0, 1)),
      null,
      new Date(2020, 9, 31),
      null
    );
    workOrder.Assets.push(createWorkOrderAsset(1, 2, "RD0002", "North St", ""));
    task = createWorkOrderTask(1, 1, "Replace Kerb section", 2, 360, 2);
    task.Resources?.push(
      createWorkOrderResource(
        1,
        1,
        1,
        "Joe Blogs",
        "Roadworks Contractor",
        2,
        90,
        180,
        2
      )
    );
    task.Resources?.push(
      createWorkOrderResource(
        2,
        2,
        2,
        "Concreter",
        "South Depot Concreter",
        2,
        90,
        180,
        6
      )
    );
    workOrder.Tasks?.push(task);
    task = createWorkOrderTask(2, 2, "Road patch next to kerb", 1, 360, 2);
    task.Resources?.push(
      createWorkOrderResource(
        3,
        1,
        1,
        "Jane Blogs",
        "Roadworks Contractor",
        2,
        90,
        180,
        2
      )
    );
    task.Resources?.push(
      createWorkOrderResource(
        4,
        2,
        2,
        "Small Asphelter",
        "South Depot Small Asphelter",
        2,
        90,
        180,
        1
      )
    );
    workOrder.Tasks?.push(task);
    this.workOrderRegistry.set(workOrder.WorkOrderId.toString(), workOrder);

    workOrder = getCreateWorkOrderObject(
      3,
      "WO000003",
      "Maintenance Program - Sched B",
      1,
      3,
      2,
      1,
      3,
      1,
      2,
      "Refer to Sched B manual for latest procedure",
      new Date(2020, 8, 15),
      null,
      new Date(2020, 8, 18),
      new Date(2020, 8, 22)
    );
    workOrder.Assets.push(
      createWorkOrderAsset(
        1,
        2,
        "RD0002",
        "North St",
        "!1m14!1m8!1m3!1d6675.561560729553!2d145.04462725749897!3d-37.84986326037516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf79911f79ad48f5b!2sOpen%20Office!5e0!3m2!1sen!2sus!4v1604529586596!5m2!1sen!2sus"
      )
    );
    task = createWorkOrderTask(1, 1, "Annual Maintenance", 3, 180, 2);
    task.Resources?.push(
      createWorkOrderResource(
        1,
        1,
        1,
        "Joe Blogs",
        "Roadworks Contractor",
        2,
        90,
        180,
        5
      )
    );
    workOrder.Tasks?.push(task);
    this.workOrderRegistry.set(workOrder.WorkOrderId.toString(), workOrder);

    workOrder = getCreateWorkOrderObject(
      4,
      "WO000004",
      "Replace outer supports",
      1,
      2,
      2,
      1,
      3,
      1,
      2,
      "",
      new Date("15/9/2020"),
      null,
      new Date("18/9/2020"),
      new Date("22/9/2020")
    );
    workOrder.Assets.push(createWorkOrderAsset(1, 2, "RD0002", "North St", ""));
    task = createWorkOrderTask(1, 1, "Support Replacement", 2, 180, 2);
    task.Resources?.push(
      createWorkOrderResource(
        1,
        1,
        1,
        "Joe Blogs",
        "Roadworks Contractor",
        2,
        90,
        180,
        4
      )
    );
    workOrder.Tasks?.push(task);
    this.workOrderRegistry.set(workOrder.WorkOrderId.toString(), workOrder);

    this.isLoadingInitial = false;
  }

  loadWorkOrders() {
    //todo - You never want to laod ALL the work orders in the system. Change to add criteria.
    this.loadFakeWorkOrders();
    this.isLoadingInitial = false;
  }

  setSelectedWorkOrder(id: number) {
    let workOrder = this.workOrderRegistry.get(id.toString());
    if (workOrder) {
      this.selectedWorkOrder = workOrder;
    }
  }

  setTaskExpanded(taskId: number, isExpand: boolean) {
    let task: IWorkOrderTaskValues = this.workOrder.Tasks?.find(
      (x) => x.TaskId === taskId
    )!;
    task.expanded = isExpand;
  }

  loadWorkOrder = async (id: string) => {
    this.isLoadingInitial = true;
    let workOrder = this.workOrderRegistry.get(id);
    if (workOrder) {
      this.workOrder = workOrder;
      this.workOrder.isOverdue = isOverdue(workOrder);
      this.isLoadingInitial = false;
    } else {
      //Load WorkOrder from database
      try {
        const response = await axios.get(
          `${globalStoreInstance.allSetting.oDataUrl}/odata/ams/internal/workorder(${id})`,
          {
            headers: authHeader(),
          }
        );
        if (
          !response ||
          response.status !== APIResponseStatus.SUCCESS ||
          !response.data
        ) {
          console.warn(`loadWorkOrder with id: ${id} fail`);
          Toast.displayToast({
            content: "🚀 Load Work Order Failed!",
            type: "error",
          });
          this.setNewWorkOrderId(0);
        }
      } finally {
        this.isLoadingInitial = false;
      }
    }
  };

  createWorkOrder() {
    this.workOrder = defaultWorkOrder();
    this.setNewWorkOrderId(0);
  }

  getWorkOrder = (id: string) => {
    return this.workOrderRegistry.get(id);
  };

  getNewWorkOrderId() {
    let nextId = 0;
    if (this.workOrderRegistry.size > -1) {
      this.workOrderRegistry.forEach((item: IWorkOrder) => {
        if (item.WorkOrderId > nextId) {
          nextId = item.WorkOrderId + 1;
        }
      });
    }
    return nextId;
  }

  getNewWorkOrderNumber(id: number) {
    //temp function to create WO Number
    let workOrderNmber = "WO" + this.pad(id, 6);
    return workOrderNmber;
  }

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  saveNewWorkOrder(workOrder: IWorkOrder) {
    this.isSubmitting = true;
    this.setNewWorkOrderId(workOrder.WorkOrderId);
    this.workOrderRegistry.set(workOrder.WorkOrderId.toString(), workOrder);
    //todo
    this.isSubmitting = false;
  }

  saveExistingWorkOrder(workOrder: IWorkOrder) {
    this.isSubmitting = true;
    //todo
    this.isSubmitting = false;
  }
}
