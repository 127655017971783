import { useInfoAdequateButtonStore } from "@app/core/further-info/components/buttons/work-flow/info-adequate/store";
import {
  FURTHERINFOSTATUS,
  FurtherInfoSubmitActions,
} from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { checkIsPPR } from "@app/core/further-info/[id]/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const InfoAdequateButton = observer(() => {
  const { onSubmit, furtherInfo } = useFurtherInfoStore();
  const { isLoading } = useInfoAdequateButtonStore();
  const isVisible = useMemo(() => {
    if (
      (furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.ExtensionDeclined ||
        furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.ExtensionRequested ||
        furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.ExtensionGranted ||
        furtherInfo?.Status_ENUM ===
          FURTHERINFOSTATUS.InfoReceivedAwaitingAssessment ||
        furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.InfoRejected) &&
      furtherInfo?.DateInfoReceived
    )
      return true;
    if (
      furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.ExtensionDeclined &&
      checkIsPPR(furtherInfo)
    )
      return true;
    if (
      furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.InfoWaitingForApproval ||
      furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.InfoApproved
    )
      return true;
    return false;
  }, [furtherInfo]);

  return isVisible ? (
    <CCNavButton
      title={"Information adequate"}
      isLoading={isLoading}
      onClick={onSubmit}
      name={FurtherInfoSubmitActions.InfoAdequate}
    />
  ) : null;
});
