import { ICCRoute } from "@common/constants/ICCRoute";
import { allActionsRoute } from "@common/pages/actions/all-actions/route";
import { myActionsRoute } from "@common/pages/actions/my-actions/route";
import { outStandingActionsRoute } from "@common/pages/actions/outstanding-actions/route";

export const actionsRoute: ICCRoute = {
  path: "actions",
  name: "actions",
  children: [
    myActionsRoute,
    outStandingActionsRoute,
    allActionsRoute,
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
