import { DTO_ChangeOfOwnership_DocumentTypes } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/model";
import { DTO_Documents } from "@app/products/property/components/child-screen/documents/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { CancelToken } from "axios";

export const getChangeOfOwnershipDocumentTypes = async (
  changeOfOwnershipTypeId: string | number,
  cancelToken: CancelToken
): Promise<APIResponse<DTO_ChangeOfOwnership_DocumentTypes>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/int/changeofownership/documenttypes`,
      {
        change_Of_Ownership_Type_Id: changeOfOwnershipTypeId,
        cancelToken,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getDocumentsList = async (
  documentsId: string | number,
  cancelToken: CancelToken
): Promise<APIResponse<DTO_Documents>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/int/workflow/documents?id=${documentsId}`,
      {
        cancelToken,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
