import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSCentreBasedCareOffers: IColumnFields[] = [
  {
    field: CRSFieldMapping.Registration_ReferenceNo,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.ActivationDate,
    title: "Activation Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.AgeAtActivation,
    title: "Age",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.IsPlacedAndWait,
    title: "Charged Deposit",
    format: DATE_FORMAT.DATE,
  },
  { field: CRSFieldMapping.IsPlacedAndWait, title: "Offer Sent" },
  {
    field: CRSFieldMapping.OfferLetterSentDate,
    title: "Offer Sent Date",
    format: DATE_FORMAT.DATE,
  },
  { field: CRSFieldMapping.ChildName, title: "Child Name" },
  { field: CRSFieldMapping.PrefMethod, title: "Preference Method" },
  { field: CRSFieldMapping.Parent_HasPortal, title: "Portal Acct" },
  { field: CRSFieldMapping.Offered, title: "Centre Offered" },
  { field: CRSFieldMapping.GroupOffer, title: "Group Offered" },
  {
    field: CRSFieldMapping.PrefNumber,
    title: "Preference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.DateOffered,
    title: "Date Offered",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.ChildDateOfBirth,
    title: "Date of Birth",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.Deferred,
    title: "Deferred",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.SecondYearEntrolment,
    title: "2nd Yr Enrol",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.ChildGender,
    title: "Gender",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: CRSFieldMapping.Suburb, title: "Suburb" },
  { field: CRSFieldMapping.ParentName, title: "Parent Name" },
  {
    field: CRSFieldMapping.Phone,
    title: "Phone",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.Mobile, title: "Mobile" },
  { field: CRSFieldMapping.EmailAddress, title: "Email" },
  {
    field: CRSFieldMapping.Weight,
    title: "Weight",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
