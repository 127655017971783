import { useCentreLinkResponseBatchStore } from "@app/products/property/centrelink-customer-confirmation/view-responses/store";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import React from "react";

export const CentreLinkResponseBatchDetailTab = () => {
  const {
    loadResponseBatchDetails,
    responseBatchDetails,
    isLoading,
    responseLoadError,
  } = useCentreLinkResponseBatchStore();

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadResponseBatchDetails();
        }}
      />
    );
  }
  if (!responseBatchDetails) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode="single">
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title="CentreLink Response Batch ID:"
              value={responseBatchDetails?.ResponseBatchId?.toString() ?? ""}
              primary
            />
          }
        >
          <>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title="Request file date:"
                value={formatDateByKendo(
                  responseBatchDetails?.RequestFileDate,
                  DATETIME_FORMAT.DATETIME_CONTROL
                )}
              />
              <ProductReferenceRow
                title="Number of centrelink responses:"
                value={responseBatchDetails?.NumberOfCentreLinkResponses}
              />
              <ProductReferenceRow
                title="Responses import date:"
                value={formatDateByKendo(
                  responseBatchDetails?.ResponsesImportDate,
                  DATETIME_FORMAT.DATETIME_CONTROL
                )}
              />
              <ProductReferenceRow
                title="Number of external responses:"
                value={responseBatchDetails?.NumberOfExternalResponses}
              />
              <ProductReferenceRow
                title="Rejections import date:"
                value={formatDateByKendo(
                  responseBatchDetails?.RejectionsImportDate,
                  DATETIME_FORMAT.DATETIME_CONTROL
                )}
              />
              <ProductReferenceRow
                title="Number of rejections:"
                value={responseBatchDetails?.NumberOfRejections}
              />

              <ProductReferenceRow
                title="Response file imported by:"
                value={responseBatchDetails?.ImportedByResponse}
              />

              <ProductReferenceRow
                title="Rejection file imported by:"
                value={responseBatchDetails?.ImportedByRejection}
              />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
};
