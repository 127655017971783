import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";
import { colRebate, colRebateOwner } from "./config";

export const RebatesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = { isReadOnly: false },
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const selectedRebate = getFieldValue("_option.SelectedRebate");
  const rebatesValue = getFieldValue("Rebates");

  const handleGridSelectionChange = (dataItem: any, field: string) => {
    if (options?.isReadOnly) return; //Do nothing while in readOnly mode
    let newSelected = dataItem ? dataItem[0] : undefined;
    onChange(nameOf(field), {
      value: newSelected,
    });
  };
  const handleRebateInfoChange = (field: string, value: any) => {
    if (selectedRebate) {
      let newRebates = rebatesValue.map((rebate: any) => {
        if (
          rebate.Rebate_Entitlement_Id !== selectedRebate.Rebate_Entitlement_Id
        )
          return rebate;
        rebate[field] = value;
        return rebate;
      });
      let newSelectedRebate = newRebates.find(
        (item: any) =>
          item?.Rebate_Entitlement_Id === selectedRebate?.Rebate_Entitlement_Id
      );
      onChange(nameOf("Rebates"), { value: newRebates });
      onChange(nameOf("_option.SelectedRebate"), { value: newSelectedRebate });
    }
  };

  return (
    <section className="cc-field-group cc-change-of-ownership-type-step">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Rebate entitlement details</label>
          <CCGrid
            className="cc-rebate-change-of-ownership"
            data={rebatesValue || []}
            columnFields={colRebate}
            selectedRows={selectedRebate ? [selectedRebate] : undefined}
            onSelectionChange={(dataItem) => {
              handleGridSelectionChange(dataItem, "_option.SelectedRebate");
            }}
            primaryField="Rebate_Entitlement_Id"
            readOnly={options?.isReadOnly}
            selectableMode="single"
            editableMode="cell"
            onDataRowChange={(dataRow, fieldChange, valueChange) => {
              handleRebateInfoChange(fieldChange, valueChange);
            }}
            toolbar={
              <div className="cc-grid-tools-bar">
                <Button
                  iconClass="fas fa-check text-success"
                  title="Activate Rebate"
                  disabled={selectedRebate?.is_InActive ?? true}
                  onClick={() => {
                    handleRebateInfoChange("is_InActive", true);
                  }}
                />
                <Button
                  iconClass="fas fa-times text-danger"
                  title="Inactivate Rebate"
                  disabled={!selectedRebate?.is_InActive ?? true}
                  onClick={() => {
                    handleRebateInfoChange("is_InActive", false);
                  }}
                />
              </div>
            }
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">
            Owners associated with selected rebate entitlements
          </label>
          <CCGrid
            className="cc-rebate-owner-change-of-ownership"
            data={
              getFieldValue("Owners")?.filter(
                (item: any) =>
                  item?.Rebate_Entitlement_Id ===
                  selectedRebate?.Rebate_Entitlement_Id
              ) || []
            }
            columnFields={colRebateOwner}
            primaryField="Entity_Id"
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Calculate rebate adjustment</label>
          <Field
            disabled
            name={"CalculateRebate"}
            checked={getFieldValue("Calculate_Rebate_Adjustment")}
            component={CCSwitch}
          />
        </div>
      </div>
    </section>
  );
};
