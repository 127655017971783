import { HMFieldMapping } from "@app/products/hm/model";

export const colHMComplaintsByRefNo = [
  {
    field: HMFieldMapping.RefNo,
    title: "Reference Number",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.Description,
    title: "Description",
    width: 150,
  },
  {
    field: HMFieldMapping.DateTarget,
    title: "Target",
    width: 150,
  },
  {
    field: HMFieldMapping.Source,
    title: "Source",
    width: 150,
  },
  {
    field: HMFieldMapping.Category,
    title: "Category",
    width: 150,
  },
  {
    field: HMFieldMapping.Location,
    title: "Location",
    width: 150,
  },
  {
    field: HMFieldMapping.RequestedBy,
    title: "Contact",
    width: 150,
  },
  {
    field: HMFieldMapping.ContactDetails,
    title: "Contact Number",
    width: 150,
  },
  {
    field: HMFieldMapping.Status,
    title: "Status",
    width: 150,
  },
  {
    field: HMFieldMapping.DueStatus,
    title: "Due Status",
    width: 150,
  },
  {
    field: HMFieldMapping.DateRecorded,
    title: "Recorded",
    width: 150,
  },
  {
    field: HMFieldMapping.RecordedBy,
    title: "Recorded By",
    width: 150,
  },
  {
    field: HMFieldMapping.OrgStructure,
    title: "Org Structure",
    width: 150,
  },
  {
    field: HMFieldMapping.ActionOfficer,
    title: "Action Officer",
    width: 150,
  },
  {
    field: HMFieldMapping.Coordinator,
    title: "Coordinator",
    width: 150,
  },
  {
    field: HMFieldMapping.DateWork,
    title: "Work Date",
    width: 150,
  },
  {
    field: HMFieldMapping.FileNumber,
    title: "File Number",
    width: 150,
  },
  {
    field: HMFieldMapping.EnquiryRefNo,
    title: "Assets Reference Number",
    width: 150,
  },
  {
    field: HMFieldMapping.Event_ReferenceNoAdditional,
    title: "Additional Reference Number",
    width: 150,
  },
  {
    field: HMFieldMapping.OnBehalfOf,
    title: "On Behalf Of",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_ITSupport,
    title: "IT Support",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_Safety,
    title: "Safety",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_Risk,
    title: "Risk",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_VIP,
    title: "VIP",
    width: 150,
  },
];
