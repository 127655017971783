import { MailMergeDialog } from "@app/core/new-mail-merge/dialogs/_index";
import {
  MAILMERGEDATASET,
  MAILMERGESAVEFORMAT,
  MailMerge_SelectMergeDocument_Ext,
} from "@app/core/new-mail-merge/dialogs/model";
import { setUpMailMergeFromForm } from "@app/core/new-mail-merge/dialogs/util";

import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React, { useState } from "react";

export interface IMailMergePickerProps extends ICCInputPickerProps {
  isMultiple?: boolean;
  recordType: RECORDTYPE;
  productType: PRODUCT_TYPE_NUMBER;
  dataSetFilter: MAILMERGEDATASET;
  isVisible?: boolean;
  isDisabled?: boolean;
  onChangeForm?: (mailMerge: MailMerge_SelectMergeDocument_Ext) => {};
}

export const MailMergePicker = ({
  productType,
  recordType,
  dataSetFilter,
  isMultiple = true,
  isVisible = true,
  isDisabled = false,
  onChangeForm,
  ...others
}: IMailMergePickerProps) => {
  const [initialMailMerge, setInitialMailMerge] =
    useState<MailMerge_SelectMergeDocument_Ext>();

  const handleOnClick = () => {
    const mailMerge = setUpMailMergeFromForm();
    mailMerge.Dataset_Filters.push(dataSetFilter);
    setInitialMailMerge({
      ...mailMerge,
      _options: {
        SaveAsPDF: mailMerge.SaveFormat === MAILMERGESAVEFORMAT.Pdf,
      },
    });
  };

  const handleOnSubmit = async (
    mailMerge: MailMerge_SelectMergeDocument_Ext
  ) => {
    onChangeForm?.(mailMerge);
  };

  if (!isVisible) return null;
  return (
    <CCInputPicker
      {...others}
      onChange={(mailMerge: ICCInputPickerChangeEvent) => {
        handleOnSubmit(mailMerge?.value);
      }}
      onLoadData={handleOnClick}
      customDialog={(value, onClose, onSubmit) => (
        <MailMergeDialog
          initialMailMerge={initialMailMerge}
          onClose={onClose}
          onSubmit={onSubmit}
          productType={productType}
          isMultiple={isMultiple}
        />
      )}
    />
  );
};
