import { Svc_RecordDetails } from "@app/products/town-planning/ppr/permit-referrals/components/action-bar/buttons/delete/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const deletePPRRecords = async (
  recordDetails: Svc_RecordDetails[]
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().delete(
      `/api/tp/ppr/internal/application/delete`,
      {
        data: recordDetails,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
