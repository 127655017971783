import { CRMS_SERVICE_STANDARDS_ROUTE } from "@app/products/crms/service-standards/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export interface INewButtonProps {
  isDisabled?: boolean;
}

export const NewButton = ({ isDisabled }: INewButtonProps) => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New"
      disabled={isDisabled}
      onClick={() => {
        history.push(`${CRMS_SERVICE_STANDARDS_ROUTE}/new`);
      }}
    />
  );
};
