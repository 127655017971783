import { useAssessmentCertificateStore } from "@app/products/property/assessments/[id]/components/child-screens/certificates/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { colCertificates } from "./config";

export const Certificates = observer(() => {
  const { isLoading, certificate, loadCertificate } =
    useAssessmentCertificateStore();
  const { assessmentId } = useAssessmentStore();

  useEffect(() => {
    if (assessmentId) loadCertificate(assessmentId);
  }, [assessmentId, loadCertificate]);

  return (
    <div className="cc-property-panel-certificates">
      <CCGrid
        isLoading={isLoading}
        data={certificate?.Certificates ?? []}
        columnFields={colCertificates}
        primaryField="Id"
      />
    </div>
  );
});
