import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";
import { mockNoticeRun } from "./mock";
import { IDeferredDutyNoticeRunSummary } from "./model";

export const getDeferredDutyNoticeRuns = async (
  id: number
): Promise<APIResponse<IDeferredDutyNoticeRunSummary | undefined>> => {
  await sleep(1000);
  return {
    data: mockNoticeRun as IDeferredDutyNoticeRunSummary,
    status: APIResponseStatus.SUCCESS,
    statusText: "Ok",
    headers: {},
    config: {},
  };
};
