import { DATE_FORMAT } from "@common/constants/common-format";
import { useAppealHearingDetailTabStore } from "@common/pages/appeal/_id/appeal-hearing/_id/components/child-screens/general/reference-sidebar/detail/store";
import { useAppealHearingStore } from "@common/pages/appeal/_id/appeal-hearing/_id/store";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

export const AppealHearingDetailTab = observer(() => {
  const { loadAppealHearingDetailTab, appealHearingDetailTab, isLoading } =
    useAppealHearingDetailTabStore();
  const { lastSelectedRow } = useCCProductListViewStore();
  const { appealHearing } = useAppealHearingStore();

  const appealHearingId: number =
    lastSelectedRow?.ID ?? appealHearing?.AppealHearing_ID;

  useEffect(() => {
    if (appealHearingId) {
      loadAppealHearingDetailTab(appealHearingId);
    }
    //eslint-disable-next-line
  }, [appealHearingId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!appealHearingDetailTab) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Appeal Id:"}
              value={appealHearingDetailTab?.Appeal_ID?.toString()}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Hearing Id:"}
              value={appealHearingDetailTab?.Hearing_ID?.toString()}
              primary
            />
            <ProductReferenceRow
              title={"Appeal Number:"}
              value={appealHearingDetailTab?.AppealNo}
              success
            />
          </ProductReferenceBlock>
        </PanelBarItem>
        {/* Date information panel bar */}
        <PanelBarItem
          title={
            <ProductReferenceHeading title={"Date Information:"} primary />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Created Date:"}
              value={formatDisplayValue(
                appealHearingDetailTab?.CreatedDate,
                DATE_FORMAT.DATE
              )}
            />
            <ProductReferenceRow
              title={"Date Appealed:"}
              value={formatDisplayValue(
                appealHearingDetailTab?.DateAppealed,
                DATE_FORMAT.DATE
              )}
            />
            <ProductReferenceRow
              title={"Date Hearing:"}
              value={formatDisplayValue(
                appealHearingDetailTab?.HearingDate,
                DATE_FORMAT.DATE
              )}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
