import { SettingPPRStoreInstance } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { EClientSequenceKey } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

export const dataFormSetting = (enumList: string[]) => {
  const data: any = {};
  enumList.forEach((item: any) => {
    const settingEnum =
      ECorporateSettingsField[item] || EClientSequenceKey[item];
    data[item] =
      SettingPPRStoreInstance.configData && settingEnum
        ? SettingPPRStoreInstance.configData[settingEnum]
        : undefined;
  });
  return data;
};

export const enumListView = (num: number) => {
  return [
    `TP_Application_OAs_ViewLabel${num}`, // View Name
    `TP_Application_OAs_AppCategoryCSV${num}`, // Application category
    `TP_NumberFormat_OAs_AppCategoryGRP${num}`, // Number Format *
    `TP_NumberPrefix_OAs_AppCategoryGRP${num}`, // Prefix
    `TP_NumberSuffix_OAs_AppCategoryGRP${num}`, // Suffix
    `TP_NumberLength_OAs_AppCategoryGRP${num}`, // Length
    `TP_OAs_AppCategoryGRP${num}`, // Last Number Used
    `TP_Set_DebtorNumber_As_OANumber_AppCategoryGRP${num}`, // Set Debtor Number To OA Number
    `TP_DebtorNumber_Prefix_OA_AppCategoryGRP${num}`, // Debtor Number Prefix
  ];
};
