import { EClientSequenceKey } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import { CC_SETTINGS_SEQCONTROL } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

export const eAnimalsRegistration = `${CC_SETTINGS_SEQCONTROL}${EClientSequenceKey.Animals_Registration}`;
export const eAnimalsKennel = `${CC_SETTINGS_SEQCONTROL}${EClientSequenceKey.Animals_Kennel}`;
export const eAnimalsPoundRegister = `${CC_SETTINGS_SEQCONTROL}${EClientSequenceKey.Animals_PoundRegister}`;

/**
 * Setting fields implemented in the form
 * Use to get grid ''setting field'' list to render dynamic
 */

//======General=========
//1. Genernal
export const GE_GeneralCorporateSetting = [
  ECorporateSettingsField.Animals_Landarea,
  ECorporateSettingsField.Animals_RestrictDateRangeStart,
  ECorporateSettingsField.Animals_RestrictDateRangeEnd,
  ECorporateSettingsField.Animals_ProfilePhoto_Attachment_Prefix,
  ECorporateSettingsField.Animals_MicrochipNumberMaxLength,
  ECorporateSettingsField.Animals_InvoiceBasedOnOwner,
];
//2. Registration
export const GE_RegistrationCorporateSetting = [
  ECorporateSettingsField.Animals_AutoIssue,
  ECorporateSettingsField.Animals_RemoveOSFees,
  ECorporateSettingsField.Animals_UseOpenOfficeFeeCalculator,
  ECorporateSettingsField.Animals_FinancialYearEndDate,
  ECorporateSettingsField.Animals_EnableProRata,
  ECorporateSettingsField.Animals_EnableQuickEntry,
  ECorporateSettingsField.Animals_EnableRegenerateFee,
  ECorporateSettingsField.Animals_EnableDiscount,
  ECorporateSettingsField.Animals_Discount_Name,
  ECorporateSettingsField.Animals_EnablePenaltyFees,
  ECorporateSettingsField.Animals_EnableTransfer,
  ECorporateSettingsField.Animals_DesexingCatsMandatory,
  ECorporateSettingsField.Animals_DefaultSterlizationTypeOfProof,
  ECorporateSettingsField.Animals_DefaultObedienceTypeOfProof,
  ECorporateSettingsField.Animals_EnableDuplicateTagNumber,
  ECorporateSettingsField.Animals_EnableDuplicateTagNumberOnActiveRegistrationsOnly,
  ECorporateSettingsField.Animals_EnableDuplicateMicrochip,
  ECorporateSettingsField.Animals_EnableDuplicateMicrochipOnActiveRegistrationsOnly,
  ECorporateSettingsField.Animals_EnablePensionerExpiry,
  ECorporateSettingsField.Animals_EnableDelegate,
  ECorporateSettingsField.Animals_EnableQuickRegister,
  ECorporateSettingsField.Animals_AssessmentExceptionList,
  ECorporateSettingsField.Animals_EnableDOGExemption,
  ECorporateSettingsField.Animals_RegistrationExpiryDateOption,
  ECorporateSettingsField.Animals_RegistrationExpiryCalcEffectiveDate,
  ECorporateSettingsField.Animals_EnableDocumentOrRecordsTab,
  ECorporateSettingsField.Animals_RegistrationExpiryDate,
];
// 3. Renewals
export const GE_RenewalsCorporateSetting = [
  ECorporateSettingsField.Animals_RenewalDate,
  ECorporateSettingsField.Animals_RenewalMethod,
  ECorporateSettingsField.Animals_EnableQuickEntryBatchRenew,
  ECorporateSettingsField.Animals_DefaultCurrentTagNoToNewTagNo,
  ECorporateSettingsField.Animals_EnableBatchRenewRecieptDialogBox,
  ECorporateSettingsField.Animals_CreateFeeOnRenewal,
  // Adding enum Animals_TagNoPlusYears to don't show in dynamic grid
  // Visible the field has been commented in the API - hide in UI
  ECorporateSettingsField.Animals_TagNoPlusYears,
];

// 4. Views
export const GE_ViewsCorporateSetting = [
  ECorporateSettingsField.Animals_ShowOSFees,
  ECorporateSettingsField.Animals_ShowAge,
  ECorporateSettingsField.Animals_ViewCatsByBreed,
  ECorporateSettingsField.Animals_ViewOtherByBreed,
  ECorporateSettingsField.Animals_ViewAnimalsByType,
  ECorporateSettingsField.Animals_ViewDogRegister,
  ECorporateSettingsField.Animals_ViewCatRegister,
  ECorporateSettingsField.Animals_ViewCatBreedRegister,
  ECorporateSettingsField.Animals_DisplayAnimalType,
  ECorporateSettingsField.Animals_AnimalsDefaultView,
];

// 5. General InspectionPlanner
export const GE_InspectionPlannerCorporateSetting = [
  ECorporateSettingsField.Animals_EnableInspectionPlanner,
  ECorporateSettingsField.Animals_EmailDueInspectionPlanners,
  ECorporateSettingsField.Animals_ActualDueDaysInspectionPlanner,
  ECorporateSettingsField.Animals_EnableCalendarSubscription,
];

// 6. Mail Merge
export const GE_MailMergePlannerCorporateSetting = [
  ECorporateSettingsField.Animals_MailMerge_ElectronicSignature_ID,
];

//=====Numbering========

// 1. Details
export const NU_DetailsCorporateSetting = [
  ECorporateSettingsField.Animals_NumberFormat,
  ECorporateSettingsField.Animals_NumberPrefix,
  ECorporateSettingsField.Animals_NumberSuffix,
  ECorporateSettingsField.Animals_NumberLength,
  ECorporateSettingsField.Animals_DefaultDebtorNo,
  ECorporateSettingsField.Animals_DebtorNumberPrefix,
  eAnimalsRegistration,
];

// ====Pound Register========
// 1. Detail
export const PO_DetailCorporateSetting = [
  ECorporateSettingsField.Animals_EnablePoundRegister,
];

// 2. Numbering
export const PO_NumberingCorporateSetting = [
  ECorporateSettingsField.Animals_PoundNumberFormat,
  ECorporateSettingsField.Animals_PoundNumberPrefix,
  ECorporateSettingsField.Animals_PoundNumberSuffix,
  ECorporateSettingsField.Animals_PoundNumberLength,
  eAnimalsPoundRegister,
];

// =====Infringements=======
// 1. Infringements Details
export const IN_DetailsCorporateSetting = [
  ECorporateSettingsField.Animals_InfringementCategoryID,
];

// =====Kennels=======
// 1. Detail
export const KN_DetailCorporateSetting = [
  ECorporateSettingsField.Animals_EnableKennels,
];
// 2. Numbering
export const KN_NumberingCorporateSetting = [
  ECorporateSettingsField.Animals_KennelNumberFormat,
  ECorporateSettingsField.Animals_KennelNumberPrefix,
  ECorporateSettingsField.Animals_KennelNumberSuffix,
  ECorporateSettingsField.Animals_KennelNumberLength,
  eAnimalsKennel,
  ECorporateSettingsField.Animals_KennelDefaultDebtorNo,
  ECorporateSettingsField.Animals_KennelDebtorNumberPrefix,
];
// 3. Fees
export const KN_FeesCorporateSetting = [
  ECorporateSettingsField.Animals_KennelNewRegistrationFeeType,
  ECorporateSettingsField.Animals_KennelRenewalFeeType,
];

// =====Records=======
// 1. Attachment Title Settings
export const RE_AttachmentTitleSettingsCorporateSetting = [
  ECorporateSettingsField.Animals_Attachment_TitleFormat,
  ECorporateSettingsField.Kennels_Attachment_TitleFormat,
];

// 2. Animal Settings
export const RE_AnimalSettingsCorporateSetting = [
  ECorporateSettingsField.SharePoint_Animals_StorageMethod,
  ECorporateSettingsField.SharePoint_Animals_UseSiteCollectionUrl,
  ECorporateSettingsField.SharePoint_Animals_SiteCollectionUrl,
  ECorporateSettingsField.SharePoint_Animals_ParentContainerFormat,
  ECorporateSettingsField.SharePoint_Animals_TitleFormat,
  ECorporateSettingsField.SharePoint_Animals_DataSetCustomField,

  // add these enums to hide in dynamic grid - implement later
  ECorporateSettingsField.TRIMAdmin_AnimalsStorageMethod,
  ECorporateSettingsField.TRIMAdmin_AnimalsPrimaryContainerName,
  ECorporateSettingsField.TRIMAdmin_AnimalsClassification,
  ECorporateSettingsField.TRIMAdmin_AnimalsPrimaryRecordType,
  ECorporateSettingsField.TRIMAdmin_AnimalsTitleFormat,
  ECorporateSettingsField.TRIMAdmin_AnimalsNumberMethod,
];

// 3. Kennels Settings
export const RE_KennelsSettingsCorporateSetting = [
  ECorporateSettingsField.SharePoint_Kennels_StorageMethod,
  ECorporateSettingsField.SharePoint_Kennels_UseSiteCollectionUrl,
  ECorporateSettingsField.SharePoint_Kennels_ParentContainerFormat,
  ECorporateSettingsField.SharePoint_Kennels_TitleFormat,
  ECorporateSettingsField.SharePoint_Kennels_DataSetCustomField,
  ECorporateSettingsField.SharePoint_Kennels_SiteCollectionUrl,

  // add these enums to hide in dynamic grid - implement later
  ECorporateSettingsField.TRIMAdmin_KennelsStorageMethod,
  ECorporateSettingsField.TRIMAdmin_KennelsPrimaryContainerName,
  ECorporateSettingsField.TRIMAdmin_KennelsClassification,
  ECorporateSettingsField.TRIMAdmin_KennelsPrimaryRecordType,
  ECorporateSettingsField.TRIMAdmin_KennelsTitleFormat,
  ECorporateSettingsField.TRIMAdmin_KennelsNumberMethod,
];

// =======Dog Settings=======
// 1. Dog Settings Details
// Pure Breed
export const DO_DogSettingsDetailsPureBreedCorporateSetting = [
  ECorporateSettingsField.Animals_Display_Purebreed,
  ECorporateSettingsField.Animals_Mandatory_Purebreed,
  ECorporateSettingsField.Animals_LabelPureBreed,
  ECorporateSettingsField.Animals_EnablePortal_Purebreed,
  ECorporateSettingsField.Animals_RenewalLocked_Purebreed,
];

// Pure Breed
export const DO_DogSettingsDetailsClubMemberCorporateSetting = [
  ECorporateSettingsField.Animals_Display_ClubMember,
  ECorporateSettingsField.Animals_Mandatory_ClubMember,
  ECorporateSettingsField.Animals_LabelClubMember,
  ECorporateSettingsField.Animals_EnablePortal_ClubMember,
  ECorporateSettingsField.Animals_RenewalLocked_ClubMember,
];

// Organisation
export const DO_DogSettingsDetailsOrganisationCorporateSetting = [
  ECorporateSettingsField.Animals_Display_Organisation,
  ECorporateSettingsField.Animals_Mandatory_Organisation,
  ECorporateSettingsField.Animals_LabelOrganisation,
  ECorporateSettingsField.Animals_EnablePortal_Organisation,
  ECorporateSettingsField.Animals_RenewalLocked_Organisation,
];

// Menacing
export const DO_DogSettingsDetailsMenacingCorporateSetting = [
  ECorporateSettingsField.Animals_Display_Menacing,
  ECorporateSettingsField.Animals_Mandatory_Menacing,
  ECorporateSettingsField.Animals_LabelMenacing,
  ECorporateSettingsField.Animals_EnablePortal_Menacing,
  ECorporateSettingsField.Animals_RenewalLocked_Menacing,
];

// Hunting Dog
export const DO_DogSettingsDetailsHuntingDogCorporateSetting = [
  ECorporateSettingsField.Animals_Display_Huntingdog,
  ECorporateSettingsField.Animals_Mandatory_Huntingdog,
  ECorporateSettingsField.Animals_LabelHuntingDog,
  ECorporateSettingsField.Animals_EnablePortal_HuntingDog,
  ECorporateSettingsField.Animals_RenewalLocked_HuntingDog,
];

//Greyhound
export const DO_DogSettingsDetailsGreyhoundCorporateSetting = [
  ECorporateSettingsField.Animals_Display_Greyhound,
  ECorporateSettingsField.Animals_Mandatory_Greyhound,
  ECorporateSettingsField.Animals_LabelGreyhound,
  ECorporateSettingsField.Animals_EnablePortal_Greyhound,
  ECorporateSettingsField.Animals_RenewalLocked_Greyhound,
];

//Guard Dog
export const DO_DogSettingsDetailsGuardDogCorporateSetting = [
  ECorporateSettingsField.Animals_Display_GuardDog,
  ECorporateSettingsField.Animals_Mandatory_GuardDog,
  ECorporateSettingsField.Animals_EnablePortal_GuardDog,
  ECorporateSettingsField.Animals_RenewalLocked_GuardDog,
];

// Dog Home Adoption
export const DO_DogSettingsDetailsDogHomeAdoptionCorporateSetting = [
  ECorporateSettingsField.Animals_Display_DogHomeAdoption,
  ECorporateSettingsField.Animals_Mandatory_DogHomeAdoption,
  ECorporateSettingsField.Animals_EnablePortal_DogHomeAdoption,
  ECorporateSettingsField.Animals_RenewalLocked_DogHomeAdoption,
];

// Obedience Trained
export const DO_DogSettingsDetailsObedienceTrainedCorporateSetting = [
  ECorporateSettingsField.Animals_Display_ObedienceTrained,
  ECorporateSettingsField.Animals_Mandatory_ObedienceTrained,
  ECorporateSettingsField.Animals_EnablePortal_ObedienceTrained,
  ECorporateSettingsField.Animals_RenewalLocked_ObedienceTrained,
];

// Customs Dog
export const DO_DogSettingsDetailsCustomsDogCorporateSetting = [
  ECorporateSettingsField.Animals_Display_CustomsDog,
  ECorporateSettingsField.Animals_Mandatory_CustomsDog,
];

//Secondary Colour
export const DO_DogSettingsDetailsSecondaryColourCorporateSetting = [
  ECorporateSettingsField.Animals_Display_SecondaryColour,
  ECorporateSettingsField.Animals_Mandatory_SecondaryColour,
];

// Health Care Card
export const DO_DogSettingsDetailsHealthCareCardCorporateSetting = [
  ECorporateSettingsField.Animals_Display_HealthCareCard,
  ECorporateSettingsField.Animals_Mandatory_HealthCareCard,
];

// Breed
export const DO_DogSettingsDetailsBreedCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_Breed,
  ECorporateSettingsField.Animals_LabelBreed,
];

// Guide Dog Assistance
export const DO_DogSettingsDetailsGuideDogAssistanceCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_GuideDogAssistance,
  ECorporateSettingsField.Animals_LabelGuideDogAssistance,
];

// Colour
export const DO_DogSettingsDetailsColourCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_Colour,
  ECorporateSettingsField.Animals_LabelColour,
];

// DOB
export const DO_DogSettingsDetailsDOBCorporateSetting = [
  ECorporateSettingsField.Animals_LabelDOB,
];

// Assessment Number
export const DO_DogSettingsDetailsAssessmentNumberCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_AssessmentNumber,
];

// Owner Postal Address
export const DO_DogSettingsDetailsOwnerPostalAddressCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_OwnerPostaladdress,
];

//Owner Mobile
export const DO_DogSettingsDetailsOwnerMobileCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_OwnerMobile,
];

// Animal Name
export const DO_DogSettingsDetailsAnimalNameCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_AnimalName,
];

// Gender
export const DO_DogSettingsDetailsGenderCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_Gender,
];

// Dangerous
export const DO_DogSettingsDetailsDangerousCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_Dangerous,
  ECorporateSettingsField.Animals_LabelDangerous,
];

// Working
export const DO_DogSettingsDetailsWorkingCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_Working,
  ECorporateSettingsField.Animals_LabelWorking,
];

// Sterlised Proof
export const DO_DogSettingsDetailsSterlisedProofCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_SterlisedTypeOfProof,
];

// Obedience Proof
export const DO_DogSettingsDetailsObedienceProofCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_ObedienceTypeOfProof,
];

//Guide/Assistance Dog Proof
export const DO_DogSettingsDetailsGuideAssistanceDogProofCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_GuideDogTypeOfProof,
];

//Working Proof
export const DO_DogSettingsDetailsWorkingProofCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_WorkingTypeOfProof,
];

//Customs Dog Proof
export const DO_DogSettingsDetailsCustomsDogProofCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_CustomsDogTypeOfProof,
];

// Pure Breed Proof
export const DO_DogSettingsDetailsPureBreedProofCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_PureBreedTypeOfProof,
];

// Guard Dog Proof
export const DO_DogSettingsDetailsGuardDogProofCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_GuardDogTypeOfProof,
];

// Dog Home Adoption Proof
export const DO_DogSettingsDetailsDogHomeAdoptionProofCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_DogHomeAdoptionTypeOfProof,
];

// Hunting Dog Proof
export const DO_DogSettingsDetailsHuntingDogProofCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_HuntingDogTypeOfProof,
];

//Greyhound Proof
export const DO_DogSettingsDetailsGreyhoundProofCorporateSetting = [
  ECorporateSettingsField.Animals_Mandatory_GreyhoundTypeOfProof,
];

// Certified Assistance
export const DO_DogSettingsDetailsCertifiedAssistanceCorporateSetting = [
  ECorporateSettingsField.Animals_Display_CertifiedAssistance,
  ECorporateSettingsField.Animals_Mandatory_CertifiedAssistance,
  ECorporateSettingsField.Animals_EnablePortal_CertifiedAssistance,
  ECorporateSettingsField.Animals_RenewalLocked_CertifiedAssistance,
];

// Restricted Breed
export const DO_DogSettingsDetailsRestrictedBreedCorporateSetting = [
  ECorporateSettingsField.Animals_Display_RestrictedBreed,
  ECorporateSettingsField.Animals_Mandatory_RestrictedBreed,
  ECorporateSettingsField.Animals_EnablePortal_RestrictedBreed,
  ECorporateSettingsField.Animals_RenewalLocked_RestrictedBreed,
];

// 2. Animal Classification Label
export const DO_AnimalClassificationLabelCorporateSetting = [
  ECorporateSettingsField.Animals_ClassificationLabelPensioner_Sterilised,
  ECorporateSettingsField.Animals_ClassificationLabelPensioner_NotSterilised,
  ECorporateSettingsField.Animals_ClassificationLabelNotPensioner_Sterilised,
  ECorporateSettingsField.Animals_ClassificationLabelNotPensioner_NotSterilised,
];

// 3. Restricted Breed
export const DO_RestrictedBreedCorporateSetting = [
  ECorporateSettingsField.Animals_RestrictedBreeds,
];
