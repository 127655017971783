import { certificatesRoute } from "@app/products/building/registers/certificates/route";
import { councilConsentRoute } from "@app/products/building/registers/council-consent/route";
import { demolitionsRoute } from "@app/products/building/registers/demolitions/route";
import { easementsRoute } from "@app/products/building/registers/easements/route";
import { enforcementsRoute } from "@app/products/building/registers/enforcements/route";
import { enquiriesRoute } from "@app/products/building/registers/enquiries/route";
import { essentialSafetyRoute } from "@app/products/building/registers/essential-safety/route";
import { popeRoute } from "@app/products/building/registers/pope/route";
import { sitingApprovalsRoute } from "@app/products/building/registers/siting-approvals/route";
import { stormwaterRoute } from "@app/products/building/registers/stormwater/route";
import { ICCRoute } from "@common/constants/ICCRoute";

export const registersRoute: ICCRoute = {
  path: "registers",
  name: "Registers",
  children: [
    councilConsentRoute,
    demolitionsRoute,
    essentialSafetyRoute,
    popeRoute,
    enquiriesRoute,
    certificatesRoute,
    sitingApprovalsRoute,
    enforcementsRoute,
    stormwaterRoute,
    easementsRoute,
  ],
};
