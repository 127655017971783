import { nameOfFactory } from "@common/utils/common";

export const nameOfDateExtended = nameOfFactory<BuildDialog_DateExtended>();

export class BuildDialog_DateExtended {
  SelectedDate: Date | null = null;
  DateCaption: string = "";
  WindowTitle: string = "";
  HasComment: boolean = false;
  MandatoryComment: boolean = false;
  CommentCaption: string = "";
  Comment: string = "";

  constructor() {
    this.SelectedDate = null;
    this.DateCaption = "Date";
    this.WindowTitle = "Enter the Date";
    this.HasComment = false;
    this.MandatoryComment = false;
    this.CommentCaption = "Please enter a Comment";
    this.Comment = "";
  }
}
