import { searchColumnType } from "@app/core/communication/dialogs/components/form-elememts/to/components/add-recipient/model";
import {
  transformColumnSearch,
  transformKeySearch,
} from "@app/core/communication/dialogs/components/form-elememts/to/components/add-recipient/util";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { AnyRecord } from "dns";

const colDisplayName = transformColumnSearch("DisplayName");
const colUserName = transformColumnSearch("UserName");
const colEmail = transformColumnSearch("Email");
const colMobile = transformColumnSearch("Mobile");

export const searchSiteUser = async (
  searchText: string,
  searchColumn: searchColumnType
): Promise<APIResponse<any | undefined>> => {
  const searchTextTransform = transformKeySearch(searchText);
  const url =
    searchColumn === searchColumnType.All
      ? `/odata/core/internal/contacts/GetViewSiteUser?$count=true&$top=50&$skip=0&$filter=(contains(${colDisplayName},${searchTextTransform}) or contains(${colUserName},${searchTextTransform}) or contains(${colEmail},${searchTextTransform}))`
      : `/odata/core/internal/contacts/GetViewSiteUser?$count=true&$top=50&$skip=0&$filter=contains(${colDisplayName},${searchTextTransform})`;

  try {
    return await CoreAPIService.getClient().get<AnyRecord | undefined>(url);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const searchContacts = async (
  searchText: string,
  searchColumn: searchColumnType
): Promise<APIResponse<any | undefined>> => {
  const searchTextTransform = transformKeySearch(searchText);
  const url =
    searchColumn === searchColumnType.All
      ? `/odata/core/internal/contacts/GetActiveContacts(filterAlphabets='')?$count=true&$top=50&$skip=0&$filter=(contains(${colDisplayName},${searchTextTransform}) or contains(${colEmail},${searchTextTransform}) or contains(${colMobile},${searchTextTransform}))`
      : `/odata/core/internal/contacts/GetActiveContacts(filterAlphabets='')?$count=true&$top=50&$skip=0&$filter=contains(${colDisplayName},${searchTextTransform})`;
  try {
    return await CoreAPIService.getClient().get<AnyRecord | undefined>(url);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
