import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { VO_Assessment } from "@app/products/property/assessments/list/model";
import {
  IPropertyHistoryTabCommonProps,
  PropertyHistoryTabCommon,
} from "@app/products/property/components/reference-sidebar/property-history/common/_index";
import { nameOfFactory } from "@common/utils/common";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const nameOf = nameOfFactory<VO_Assessment>();
export const PropertyAssessmentCollectionsHistoryTab = observer(
  (props: IPropertyHistoryTabCommonProps) => {
    const { lastSelectedRow } = useDetailAssessmentListViewStore();

    const assessmentID = useMemo(() => {
      return lastSelectedRow?.[nameOf("Assessment_Id")];
    }, [lastSelectedRow]);

    return <PropertyHistoryTabCommon id={assessmentID.toString()} {...props} />;
  }
);
