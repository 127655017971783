import { sleep } from "@common/utils/common";
import { IActionsMyApprovalsDetailTab } from "@app/products/property/actions/my-approvals-list/components/reference-sidebar/detail/model";
import { mockActionsMyApprovalsDetailTab } from "@app/products/property/actions/my-approvals-list/components/reference-sidebar/detail/mock";

// TODO: Copy from Send for Approved list => Not using right now can skip when reviewing
export const getMyApprovalsRecordDetailsTabById = async (
  recordId: number
): Promise<IActionsMyApprovalsDetailTab | undefined> => {
  await sleep(1000);
  return { Id: recordId, ...mockActionsMyApprovalsDetailTab };
};
