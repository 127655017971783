import { NewArrangementDialog } from "@app/products/property/assessments/arrangements/components/dialogs/new-arrangements/_index";
import { CommunityProperty } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const NewArrangementButton = observer(() => {
  const [showNewArrangementDialog, setShowNewArrangementDialog] =
    useState(false);
  const { gridSelectedRows } = useCCProductListViewStore();

  /**
   * Task D-5702
   * Hide "New arrangement" for ACTRO
   */
  const { isActro } = CommunityProperty.getFlagOfStates();

  return (
    <>
      {isActro ? undefined : (
        <CCNavButton
          title="New arrangement"
          disabled={gridSelectedRows.length > 1}
          onClick={() => setShowNewArrangementDialog(true)}
        />
      )}
      {showNewArrangementDialog && (
        <NewArrangementDialog
          assessmentInfo={gridSelectedRows[0]}
          onClose={() => setShowNewArrangementDialog(false)}
        />
      )}
    </>
  );
});
