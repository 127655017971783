import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { PPRApplication } from "@app/products/town-planning/ppr/permit-referrals/all/register/model";
import { deletePPRRecords } from "@app/products/town-planning/ppr/permit-referrals/components/action-bar/buttons/delete/api";
import { Svc_RecordDetails } from "@app/products/town-planning/ppr/permit-referrals/components/action-bar/buttons/delete/model";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const DeletePPRButton = observer(() => {
  const { gridSelectedRows, setGridSelectedRows } = useCCProductListViewStore();
  const [isShowDeleteConfirm, setIsShowDeleteConfirm] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();

  const handleSubmit = async () => {
    setIsDeleting(true);
    const recordDetails: Svc_RecordDetails[] = gridSelectedRows.map(
      (selectedRow: PPRApplication) => {
        return {
          ID: selectedRow.ID,
          RecordType_ENUM: selectedRow.RecordType_ENUM,
        };
      }
    );
    const response = await deletePPRRecords(recordDetails);
    setIsDeleting(false);
    setIsShowDeleteConfirm(false);
    if (isSuccessResponse(response)) {
      setGridSelectedRows([]);
      clearNotifications();
      pushNotification({
        title: "Deleted successfully",
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(TabTableEventType.RefreshData);
    } else {
      pushNotification({
        autoClose: false,
        title: "Delete failed",
        description: response.data.Errors ?? response.statusText,
        type: "error",
      });
    }
  };

  return (
    <>
      <CCNavButton
        title="Delete"
        disabled={gridSelectedRows.length < 1}
        isLoading={isDeleting}
        onClick={() => {
          setIsShowDeleteConfirm(true);
        }}
      />
      {isShowDeleteConfirm && (
        <ConfirmDialog
          title={"Confirm Deletion"}
          subMessage={"Are you sure you want to delete this item?"}
          onClosePopup={() => {
            setIsShowDeleteConfirm(false);
          }}
          onConfirmYes={handleSubmit}
        />
      )}
    </>
  );
});
