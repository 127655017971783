import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SendCommunicationButton } from "@common/pages/settings/communication/components/buttons/send-communication/_index";
import { SETTINGS_COMMUNICATION_QUEUE_LIST_VIEW_URL } from "@common/pages/settings/communication/queue/constant";
import { Svc_GetView_CommunicationQueueList } from "@common/pages/settings/communication/queue/model";
import { CommunicationQueueBookmark } from "@common/pages/settings/communication/queue/util";
import { CommunicationQueueTabDetails } from "@common/pages/settings/communication/queue/_id/components/reference-sidebar/detail/_index";
import { CommunicationQueueHistoryTab } from "@common/pages/settings/communication/queue/_id/components/reference-sidebar/history/_index";
import { SETTINGS_COMMUNICATION_QUEUE_ROUTE } from "@common/pages/settings/communication/queue/_id/constant";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colCommunicationQueue } from "./config";

const nameOf = nameOfFactory<Svc_GetView_CommunicationQueueList>();

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    const responseCheckGlobalSettings = await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );

    if (responseCheckGlobalSettings) {
      await checkPermissionForCurrentMenu(
        FormActionCheckPermission.COMMUNICATION_MENU,
        1
      );
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [<SendCommunicationButton />],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={SETTINGS_COMMUNICATION_QUEUE_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_CommunicationQueue}
        detail={CommunicationQueueBookmark.getBookmarkDetail}
        displayName={CommunicationQueueBookmark.getBookmarkDisplayName}
        listViewDetail={CommunicationQueueBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          CommunicationQueueBookmark.getBookmarkListViewDisplayName
        }
        isDisabledRecordListView={!hasPermission}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CommunicationQueueTabDetails /> },
      {
        title: "History",
        component: <CommunicationQueueHistoryTab />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colCommunicationQueue}
      primaryField={nameOf("ID")}
      dataUrl={SETTINGS_COMMUNICATION_QUEUE_LIST_VIEW_URL}
      state={{
        sort: [{ field: nameOf("DateQueued"), dir: "desc" }],
      }}
      disabled={!hasPermission}
    />
  );
};
