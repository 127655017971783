import {
  RosterKey,
  RosterSiteUser,
} from "@app/products/crms/system-admin/rosters/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const nameOfUsers = nameOfFactory<RosterSiteUser>();
export const colRosterUsers: IColumnFields[] = [
  {
    field: nameOfUsers("SiteUser_DisplayName"),
    title: "Name",
    minWidth: 700,
  },
];

export const nameOfKeys = nameOfFactory<RosterKey>();
export const colRosterKeys: IColumnFields[] = [
  {
    field: nameOfKeys("Key_DisplayName"),
    title: "Description",
    minWidth: 700,
  },
];
