import { AnimalPoundRegisterSettingValue } from "@app/products/animals/pound-register/[id]/model";
import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";

export const getPoundRegisterValueFromSetting = (settings: SettingsMap) => {
  return {
    corporateSettingsRecordsSystem: getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
    ),
    corporateSettingsGIS: getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_GIS]
    ),
    corporateSettingsPropertyEXE: getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_PropertyEXE]
    ),
    infoXpertURL: getStringValueSetting(
      settings[ECorporateSettingsField.infoXpert_URL]
    ),
    ecmAdminPropertyLaunchUrl: getStringValueSetting(
      settings[ECorporateSettingsField.ecmAdmin_PropertyLaunchUrl]
    ),
    trimAdminAnimalsStorageMethod: getNumberValueSetting(
      settings[ECorporateSettingsField.TRIMAdmin_AnimalsStorageMethod]
    ),
    infoXpertAnimalsStorageMethod: getNumberValueSetting(
      settings[ECorporateSettingsField.infoXpert_AnimalsStorageMethod]
    ),
    corporateSettingsEnableMultiLineInvoice: getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
    ),
    corporateSettingsDebtorNumberLabel: getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_DebtorNumberLabel]
    ),
    corporateSettingsEnableDebtorUserSelection: getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_EnableDebtorUserSelection
      ]
    ),
    corporateSettingsFinanceAllocateDebtorNumber: getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_FinanceAllocateDebtorNumber
      ]
    ),
    corporateSettingsDebtorNumberMandatory: getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_DebtorNumberMandatory]
    ),
  } as AnimalPoundRegisterSettingValue;
};
