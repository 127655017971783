import { INVALID_WORKFLOW_DRAFT_ID } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { DTO_MP_Assessment } from "@app/products/property/assessments/master-properties/components/form-steps/add-new-master-property/model";
import { AddAssessmentLookupDialog } from "@app/products/property/components/dialogs/add-assessment-lookup/_index";
import { getSearchAssessmentLookup } from "@app/products/property/components/dialogs/add-assessment-lookup/api";
import { eOptionSearchAssessmentLookup } from "@app/products/property/components/dialogs/add-assessment-lookup/config";
import {
  DTO_Assessment,
  eLoadingAssessment,
  fnt_Assessment_LookupResult,
} from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  allocationRadioGroupAssessmentJournal,
  colJournalAssessment,
  transactionTypeClassificationMode,
  transactionTypeClassificationModeNotManually,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/config";
import { eAssessmentAllocation } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/model";
import { useAssessmentAddTransactionJournalStepStore } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/store";
import {
  amountValidator,
  checkClassificationExisted,
  formValidatorAssess,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/util";
import { EKeysOfStepsAddTransaction } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/model";
import { processCombineData } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { isSuccessResponse } from "@common/apis/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { DTO_LOV } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNote } from "@components/cc-note/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  NumericTextBoxChangeEvent,
  RadioButtonProps,
  RadioGroupChangeEvent,
} from "@progress/kendo-react-inputs";
import { cloneDeep, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const nameOfLovs = nameOfFactory<DTO_LOV>();
const nameOfAssessment = nameOfFactory<DTO_MP_Assessment>();

export const AssessmentAddTransactionJournalFormStep = (
  props: IFormStepElement
) => {
  const newValidator = useCallback(
    (value: any) => {
      if (props?.options?.isReadOnly) return undefined;
      return formValidatorAssess(
        value,
        props?.options?.assessmentLowercaseLabel ?? "assessment"
      );
    },
    [props?.options]
  );
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={newValidator}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    setStepsVisible,
    resetAllStepsAfter,
    localNotificationRef,
    options = {
      isReadOnly: false,
      workflowDraftId: INVALID_WORKFLOW_DRAFT_ID,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    //set onChange for parent form
    if (options?.onChangeRef) {
      options.onChangeRef.current = { onChange, nameOf, valueGetter };
    }
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    //store
    const { newAssessmentJournalStepLOVs } =
      useAssessmentAddTransactionJournalStepStore();
    const [processedCols, setProcessedCols] =
      useState<IColumnFields[]>(colJournalAssessment);
    //state local
    const [showAddAssessmentRangeDialog, setShowAddAssessmentRangeDialog] =
      useState(false);
    const [isShowConfirmDeletion, setIsShowConfirmDeletion] =
      useState<boolean>(false);
    const [allocationOptions, setAllocationOptions] = useState<
      RadioButtonProps[]
    >(allocationRadioGroupAssessmentJournal);

    //Get labels
    const [
      assessmentLabel,
      assessmentLowercaseLabel,
      assessmentNumberLabel,
      assessmentIDLabel,
      ratepayerLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Assessment,
      ECustomColNameProperty.AssessmentLowercase,
      ECustomColNameProperty.AssessmentNumber,
      ECustomColNameProperty.AssessmentID,
      ECustomColNameProperty.Ratepayer,
    ]);

    //get value
    const currentAssessments = getFieldValue("Assessments") ?? [];
    const amount = getFieldValue("Amount");
    const selectedAssessments =
      getFieldValue("_option.AssessmentSelected") ?? [];
    const transactionTypeClassification = getFieldValue(
      "_option.Transaction_Type.Classifications"
    );
    const currentAllocationMode = getFieldValue("AllocationMode");

    /**
     * condition to show RebateEntitlement
     */
    const isShowRebateEntitlement = useMemo(() => {
      return (
        checkClassificationExisted(
          transactionTypeClassification,
          transactionTypeClassificationMode
        ) || newAssessmentJournalStepLOVs?.IsRebateTypeTransaction
      );
    }, [newAssessmentJournalStepLOVs, transactionTypeClassification]);

    const validateAmount = useCallback(
      (value) => {
        return amountValidator(value, currentAssessments?.length);
      },
      [currentAssessments?.length]
    );

    //lovs transaction type
    const transactionListType = useMemo(() => {
      return newAssessmentJournalStepLOVs?.TransactionTypes ?? [];
    }, [newAssessmentJournalStepLOVs]);

    const paymentAllocationLovs =
      newAssessmentJournalStepLOVs?.PaymentAllocations ?? [];

    /**
     * handleChangeStep
     * check visible or hide step base on condition
     * @param assessmentAllocation
     */
    const handleChangeStep = (assessmentAllocation: eAssessmentAllocation) => {
      let newSteps = resetAllStepsAfter(
        EKeysOfStepsAddTransaction.AssessmentJournal
      );
      if (setStepsVisible) {
        switch (assessmentAllocation) {
          case eAssessmentAllocation.CHARGES_BALANCES:
            setStepsVisible(
              [
                {
                  key: EKeysOfStepsAddTransaction.AllocationChargeBalances,
                  visible: true,
                  isClearData: true,
                },
                {
                  key: EKeysOfStepsAddTransaction.NewChargeDetail,
                  visible: false,
                  isClearData: true,
                },
              ],
              newSteps
            );
            break;
          case eAssessmentAllocation.SEQUENCE:
            setStepsVisible(
              [
                {
                  key: EKeysOfStepsAddTransaction.AllocationChargeBalances,
                  visible: false,
                  isClearData: true,
                },
                {
                  key: EKeysOfStepsAddTransaction.NewChargeDetail,
                  visible: false,
                  isClearData: true,
                },
              ],
              newSteps
            );
            break;
          default:
            setStepsVisible(
              [
                {
                  key: EKeysOfStepsAddTransaction.NewChargeDetail,
                  visible: true,
                  isClearData: true,
                },
                {
                  key: EKeysOfStepsAddTransaction.AllocationChargeBalances,
                  visible: false,
                  isClearData: true,
                },
              ],
              newSteps
            );
        }
      }
    };

    /**
     * handle add Assessment
     * @param assessmentList
     */
    const handleAddAssessments = async (
      assessmentList: fnt_Assessment_LookupResult[]
    ) => {
      setShowAddAssessmentRangeDialog(false);
      const listAssessmentId = assessmentList
        .map((item) => item.Assessment_Id)
        .toString();
      options?.setIsLoadingInStep(true);
      onChange(nameOf("_option.Loading"), {
        value: eLoadingAssessment,
      });
      const response = await getSearchAssessmentLookup({
        LookupKey: eOptionSearchAssessmentLookup.AssessmentId,
        LookupValue: listAssessmentId,
        Statuses: [0], //Fixed now (0: Active)
      });
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      if (isSuccessResponse(response) && response?.data) {
        const prevList = [...currentAssessments];
        const resAssessment = response?.data?.Assessments ?? [];
        if (resAssessment?.length) {
          const newData = processCombineData(
            prevList,
            resAssessment,
            "Assessment_Id"
          );
          updateAllocationOptions(
            newData,
            amount,
            transactionTypeClassification
          );
          onChange(nameOf("Assessments"), {
            value: newData,
          });
        }
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${assessmentLabel} failed`,
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsLoadingInStep(false);
    };

    /**
     * Handle delete assessment
     */
    const handleDelete = () => {
      const selectedIDs = selectedAssessments?.map(
        (assessment: DTO_Assessment) => assessment.Assessment_Id
      );
      const newAssessments = currentAssessments?.filter(
        (assessment: DTO_Assessment) =>
          !selectedIDs.includes(assessment.Assessment_Id)
      );
      updateAllocationOptions(
        newAssessments,
        amount,
        transactionTypeClassification
      );
      onChange(nameOf("Assessments"), { value: newAssessments });
      onChange(nameOf("_option.AssessmentSelected"), { value: [] });
    };

    /**
     * Check disabled Minus Button
     */
    const isDisabledMinusButton = () => {
      if (!selectedAssessments) return false;
      return selectedAssessments?.length > 0;
    };

    /**
     * Update allocation options based on the provided assessments, amount, and classification
     * @param assessments Assessments to be associated with the journal
     * @param amount Transaction amount
     * @param classification Transaction type
     */
    const updateAllocationOptions = (
      assessments: DTO_Assessment[],
      amount?: number,
      classification?: string
    ) => {
      const numberOfAssessments = assessments?.length;
      if (!numberOfAssessments || isNil(amount)) return;
      let allocationOption = cloneDeep(allocationRadioGroupAssessmentJournal);

      allocationOption = allocationOption?.map((item: RadioButtonProps) => {
        // Disable SEQUENCE option if amount is 0 or positive
        // Disable NEW_CHARGES option if amount is 0
        if (amount >= 0) {
          if (
            item.value === eAssessmentAllocation.SEQUENCE ||
            (amount === 0 && item.value === eAssessmentAllocation.NEW_CHARGES)
          ) {
            return { ...item, disabled: true };
          }
        }

        // Disable CHARGES_BALANCES option if amount negative and classification mode is 5, 25 or 13
        else if (
          numberOfAssessments === 1 &&
          !isNil(classification) &&
          checkClassificationExisted(
            classification,
            transactionTypeClassificationModeNotManually
          )
        ) {
          if (item.value === eAssessmentAllocation.CHARGES_BALANCES) {
            return { ...item, disabled: true };
          }
        }

        // Disable CHARGES_BALANCES option if number of assessments greater than 1
        if (
          numberOfAssessments > 1 &&
          item.value === eAssessmentAllocation.CHARGES_BALANCES
        ) {
          return { ...item, disabled: true };
        }
        return item;
      });

      // Update allocation options
      setAllocationOptions(allocationOption);

      // Reset allocation mode if the current selected option is disabled
      const isResetAllocationNeeded = allocationOption.some(
        (item: RadioButtonProps) =>
          item.value === currentAllocationMode && item.disabled
      );
      isResetAllocationNeeded && resetAllocationMode(allocationOption);
    };

    /**
     * Reset allocation mode
     * @param allocationOptions Allocation options
     */
    const resetAllocationMode = (allocationOptions: RadioButtonProps[]) => {
      // Find the first enabled option and set it as the default mode
      const firstEnabledOption = allocationOptions.find(
        (item: RadioButtonProps) => !item.disabled
      );
      const defaultMode = firstEnabledOption?.value;

      // If there is no enabled option, keep the current mode
      if (!isNil(defaultMode)) {
        onChange(nameOf("AllocationMode"), {
          value: defaultMode,
        });
        // Reset payment allocation number if the mode is not SEQUENCE
        if (defaultMode !== eAssessmentAllocation.SEQUENCE) {
          onChange(nameOf("PaymentAllocationNo"), {
            value: null,
          });
        }
        // Change the step based on the default mode
        handleChangeStep(defaultMode);
      }
    };

    const handleGridSelectionChange = (dataItem: any, field: string) => {
      if (dataItem) {
        onChange(nameOf(field), {
          value: dataItem,
        });
      }
    };

    useEffectOnce(() => {
      //set initial disable option when assessments greater than 1
      if (currentAssessments.length > 1) {
        setAllocationOptions(
          allocationRadioGroupAssessmentJournal?.map(
            (item: RadioButtonProps) => {
              if (item.value === eAssessmentAllocation.CHARGES_BALANCES) {
                return { ...item, disabled: true };
              }
              return item;
            }
          )
        );
      }
      //Update column headers dynamically
      const newCols = colJournalAssessment.map((column: IColumnFields) => {
        switch (column.field) {
          case nameOfAssessment("Assess_Number"):
            return {
              ...column,
              title: assessmentNumberLabel,
            };
          case nameOfAssessment("Assessment_Id"):
            return {
              ...column,
              title: assessmentIDLabel,
            };
          case nameOfAssessment("Ratepayer_Name"):
            return {
              ...column,
              title: ratepayerLabel,
            };
          default:
            return column;
        }
      });
      setProcessedCols(newCols);
    });

    useEffectOnce(() => {
      updateAllocationOptions(
        currentAssessments,
        amount,
        transactionTypeClassification
      );
    });

    return (
      <>
        <section className="cc-field-group cc-assessment-journal">
          <CCLabel
            title={`${assessmentLabel}(s)`}
            isMandatory
            errorMessage={errors?.[nameOf("")] ? errors[nameOf("")] : undefined}
            isLoading={getFieldValue("_option.Loading") === eLoadingAssessment}
          />
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              <CCGrid
                data={getFieldValue("Assessments") || []}
                columnFields={processedCols}
                primaryField={"Assessment_Id"}
                editableMode={"cell"}
                selectableMode={"multiple"}
                readOnly={options?.isReadOnly}
                onSelectionChange={(dataItem: any) => {
                  if (options?.isReadOnly) return;
                  handleGridSelectionChange(
                    dataItem,
                    "_option.AssessmentSelected"
                  );
                }}
                selectedRows={
                  selectedAssessments ? [...selectedAssessments] : undefined
                }
                onDataChange={(dataItem: DTO_Assessment[]) => {
                  onChange(nameOf("Assessments"), { value: dataItem });
                }}
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      className="cc-edit-field-button"
                      iconClass="fa fa-plus"
                      title={`Associate ${assessmentLabel}(s)`}
                      disabled={
                        options?.isReadOnly || isNil(options?.workflowDraftId)
                      }
                      onClick={() => {
                        setShowAddAssessmentRangeDialog(true);
                      }}
                    />
                    <Button
                      iconClass="fas fa-minus"
                      title={`Un-associate ${assessmentLabel}(s)`}
                      disabled={options?.isReadOnly || !isDisabledMinusButton()}
                      onClick={() => {
                        setIsShowConfirmDeletion(true);
                      }}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <label className="cc-label">Transaction</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">
                  Type
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={nameOf("Transaction_Type")}
                  component={CCSearchComboBox}
                  data={transactionListType}
                  textField={nameOfLovs("Name")}
                  dataItemKey={nameOfLovs("Code")}
                  value={getDropdownValue(
                    getFieldValue("Transaction_Type"),
                    transactionListType,
                    "Code"
                  )}
                  disabled={
                    options.isReadOnly || currentAssessments?.length <= 0
                  }
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange(nameOf("Transaction_Type"), {
                      value: event.value?.Code,
                    });
                    onChange(nameOf("_option.Transaction_Type"), {
                      value: event.value,
                    });
                    updateAllocationOptions(
                      currentAssessments,
                      amount,
                      event.value?.Classifications
                    );
                    //reset Levy
                    onChange(
                      `${EKeysOfStepsAddTransaction.NewChargeDetail}.LevyId`,
                      {
                        value: undefined,
                      }
                    );
                    onChange(
                      `${EKeysOfStepsAddTransaction.NewChargeDetail}._option.Levy`,
                      {
                        value: undefined,
                      }
                    );
                    //reset RatingPeriod
                    onChange(
                      `${EKeysOfStepsAddTransaction.NewChargeDetail}.RatingPeriod`,
                      {
                        value: undefined,
                      }
                    );
                    onChange(
                      `${EKeysOfStepsAddTransaction.NewChargeDetail}._option.RatingPeriod`,
                      {
                        value: undefined,
                      }
                    );
                  }}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Amount
                  <CCTooltip type="validator" position="right" />
                  <CCTooltip
                    type="custom"
                    position="auto"
                    content=" "
                    customTemplate={
                      <div>
                        &#8226; Enter with sign. eg. Payments are negative
                        <br />
                        &#8226; If multiple {assessmentLowercaseLabel}s are
                        associated the amount cannot be 0
                      </div>
                    }
                  >
                    <i className="fa fa-info-circle ml-1 text-accent" />
                  </CCTooltip>
                </label>
                <Field
                  name={nameOf("Amount")}
                  component={CCCurrencyInput}
                  disabled={
                    options.isReadOnly || currentAssessments?.length <= 0
                  }
                  validator={!options?.isReadOnly ? validateAmount : undefined}
                  onChange={(event: NumericTextBoxChangeEvent) => {
                    onChange(nameOf("Amount"), { value: event?.value });
                    updateAllocationOptions(
                      currentAssessments,
                      event?.value ?? undefined,
                      transactionTypeClassification
                    );
                    onChange(
                      `${EKeysOfStepsAddTransaction.NewChargeDetail}.Amount`,
                      { value: event?.value }
                    );
                  }}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Date</label>
                <Field
                  name={nameOf("TransactionDateTime")}
                  format={DATETIME_FORMAT.DATETIME_CONTROL}
                  component={CCDateTimePicker}
                  disabled={options.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Reference</label>
                <Field
                  name={nameOf("TransactionReference")}
                  placeholder={"Reference"}
                  component={CCInput}
                  readOnly={options.isReadOnly}
                />
              </div>
              <div className="cc-col-span-2">
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <label className="cc-label">Description</label>
                    <Field
                      name={nameOf("TransactionDescription")}
                      placeholder={"Description"}
                      component={CCInput}
                      readOnly={options.isReadOnly}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Notes</label>
                    <Field
                      name={nameOf("TransactionNotes")}
                      placeholder={"Notes"}
                      component={CCTextArea}
                      rows={3}
                      readOnly={options.isReadOnly}
                    />
                  </div>
                  {isShowRebateEntitlement && (
                    <div className="cc-field">
                      <label className="cc-label">Rebate entitlement</label>
                      <Field
                        name={nameOf("AssessmentRebateEntitlementId")}
                        component={CCSearchComboBox}
                        data={
                          newAssessmentJournalStepLOVs?.AssessRebateEntitlements
                        }
                        textField={nameOfLovs("Name")}
                        dataItemKey={nameOfLovs("Code")}
                        value={getDropdownValue(
                          getFieldValue("AssessmentRebateEntitlementId"),
                          newAssessmentJournalStepLOVs?.AssessRebateEntitlements ??
                            [],
                          "Code"
                        )}
                        disabled={options.isReadOnly}
                        onChange={(event: ComboBoxChangeEvent) => {
                          let valueRebate = event?.value?.Code;
                          if (!isShowRebateEntitlement) valueRebate = null;
                          onChange(nameOf("AssessmentRebateEntitlementId"), {
                            value: valueRebate,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <label className="cc-label">Allocation</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <Field
                  name={nameOf("AllocationMode")}
                  component={CCRadioGroup}
                  data={allocationOptions}
                  onChange={(e: RadioGroupChangeEvent) => {
                    onChange(nameOf("AllocationMode"), {
                      value: e.value,
                    });
                    if (e.value !== eAssessmentAllocation.SEQUENCE) {
                      onChange(nameOf("PaymentAllocationNo"), {
                        value: null,
                      });
                    }
                    handleChangeStep(e.value);
                  }}
                  disabled={
                    options.isReadOnly || currentAssessments?.length <= 0
                  }
                />
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <div className="cc-control-item">
                    {currentAllocationMode ===
                      eAssessmentAllocation.SEQUENCE && (
                      <>
                        <label className="cc-label">
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("PaymentAllocationNo")}
                          component={CCSearchComboBox}
                          disabled={
                            currentAllocationMode !==
                            eAssessmentAllocation.SEQUENCE
                          }
                          data={paymentAllocationLovs}
                          textField={nameOfLovs("Name")}
                          dataItemKey={nameOfLovs("Code")}
                          isUseDefaultOnchange
                          validator={
                            currentAllocationMode ===
                              eAssessmentAllocation.SEQUENCE &&
                            !options.isReadOnly
                              ? requiredValidator
                              : undefined
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CCNote
            message={
              <>
                <p>
                  <b>To a new charge</b>
                  &nbsp;is disabled if amount is equal to $0.
                </p>
                <p>
                  <b>Manually allocate to charge balances</b>
                  &nbsp;is disabled if amount is negative and classification
                  type is Payment (5) or Payment (Journalled) (25) or Payment
                  (Interim Bank) (13).
                </p>
                <p>
                  <b>Allocate credit use allocation sequence</b>
                  &nbsp;is disabled if amount is equal to $0 or is positive.
                </p>
              </>
            }
          />
          <br />
        </section>
        {isShowConfirmDeletion && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={`Are you sure you wish to un-associate the selected ${assessmentLowercaseLabel}(s) from this journal?`}
            onClosePopup={() => {
              setIsShowConfirmDeletion(false);
            }}
            onConfirmYes={handleDelete}
          />
        )}
        {showAddAssessmentRangeDialog && (
          <AddAssessmentLookupDialog
            onClose={() => setShowAddAssessmentRangeDialog(false)}
            handleAddAssessment={handleAddAssessments}
          />
        )}
      </>
    );
  }
);
