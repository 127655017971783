import { IAttribute } from "@app/products/property/registers/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<IAttribute>();
export const colRegisterSummaryAttribute: IColumnFields[] = [
  {
    field: nameOf("Attribute"),
    title: "Attribute",
  },
  {
    field: nameOf("Value"),
    title: "Value",
  },
];
