export const TownPlanningBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.Application_ID
      ? `- ${selectedRow.ID}`
      : "";
    return `Town Planning ${dynamicDisplayName}`;
  },
  getBookmarkDetail(selectedRow: any) {
    return selectedRow?.SiteAddress || "N/A";
  },
};
