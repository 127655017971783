import { KeyValuePair } from "@app/core/documents/model";
import {
  howDataLovs,
  recallOptionOfficer,
} from "@app/core/recall/[id]/components/general/form-element/components/officer-section/config";
import { nameOfRecall } from "@app/core/recall/[id]/util";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { DATE_FORMAT } from "@common/constants/common-format";
import { SiteUser } from "@common/pages/settings/security/site-users/_id/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IRecallSectionProps {
  isInactive: boolean;
  formRenderProps: FormRenderProps;
}
const nameOfOfficer = nameOfFactory<SiteUser>();

export const RecallOfficerSection = observer(
  ({ isInactive, formRenderProps }: IRecallSectionProps) => {
    const { valueGetter, onChange } = formRenderProps;

    const alertedRetail = valueGetter(nameOfRecall("Flag_AlertedRetail"));
    const randomChecks = valueGetter(
      nameOfRecall("Flag_ConductedRandomChecks")
    );
    const seizedProducts = valueGetter(nameOfRecall("Flag_SeizedProducts"));
    const officer = valueGetter(nameOfRecall("Officer"));
    const officerId = valueGetter(nameOfRecall("Officer_ID"));

    // Officer Field
    const officerDisplay = useMemo(() => {
      let litOfficer = "";
      if (officer) {
        litOfficer = officer.DisplayName;
      } else {
        litOfficer = "";
      }
      return litOfficer;
    }, [officer]);

    const pickOfficerHandler = (officer: SiteUser) => {
      if (officer) {
        onChange(nameOfRecall("Officer"), { value: officer });
        onChange(nameOfRecall("Officer_ID"), { value: officer.Contact_ID });
      } else {
        onChange(nameOfRecall("Officer"), { value: undefined });
        onChange(nameOfRecall("Officer_ID"), { value: undefined });
      }
    };

    // How Field
    const handleChangeHow = (data: MultiSelectChangeEvent) => {
      const howIds: string[] =
        data?.value?.map((item: KeyValuePair<string, string>) => item.Key) ??
        [];

      onChange(nameOfRecall("HowAlerted"), {
        value: howIds?.filter((item: string) => item).join("|"),
      });
    };

    const howAlerted = valueGetter(nameOfRecall("HowAlerted"));
    const howValue = useMemo(() => {
      const ids = howAlerted?.split("|") ?? [];

      let howList: KeyValuePair<string, string>[] = [];
      if (ids.length > 0) {
        howDataLovs?.forEach((item: KeyValuePair<string, string>) => {
          if (ids.includes(item.Key?.toString())) {
            howList.push(item);
          }
        });
      }
      return howList;
    }, [howAlerted]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Officer" isMandatory />
            <Field
              component={InputPickerSearch}
              valueMapGrid={officerId}
              name={nameOfRecall("Officer")}
              nameDisplay={nameOfOfficer("DisplayName")}
              value={officerDisplay}
              options={recallOptionOfficer}
              onChange={pickOfficerHandler}
              placeholder="Select officer"
              disabled={isInactive}
              validator={requiredValidator}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Alerted retail prop" isMandatory />
            <Field
              name={nameOfRecall("Flag_AlertedRetail")}
              component={CCSwitch}
              checked={alertedRetail}
              disabled={isInactive}
              validator={requiredValidator}
            />
          </div>
          {alertedRetail && (
            <div className="cc-field">
              <CCLabel title="How" />
              <Field
                name={nameOfRecall("HowAlerted")}
                component={CCMultiSelectDropdown}
                disabled={isInactive}
                textField={"Value"}
                dataItemKey={"Key"}
                data={howDataLovs}
                onChange={handleChangeHow}
                value={howValue}
              />
            </div>
          )}
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Random checks" isMandatory />
            <Field
              name={nameOfRecall("Flag_ConductedRandomChecks")}
              component={CCSwitch}
              checked={randomChecks}
              disabled={isInactive}
              validator={requiredValidator}
            />
          </div>
          {randomChecks && (
            <div className="cc-field">
              <CCLabel title="Date checked" isMandatory />
              <Field
                name={nameOfRecall("Date_Checked")}
                component={CCDatePicker}
                disabled={isInactive}
                validator={requiredValidator}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
          )}
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Seized products" isMandatory />
            <Field
              name={nameOfRecall("Flag_SeizedProducts")}
              component={CCSwitch}
              checked={seizedProducts}
              disabled={isInactive}
              validator={requiredValidator}
            />
          </div>
          {seizedProducts && (
            <div className="cc-field">
              <CCLabel title="Date seized" isMandatory />
              <Field
                name={nameOfRecall("Date_Seized")}
                component={CCDatePicker}
                disabled={isInactive}
                validator={requiredValidator}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
