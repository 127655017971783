import { eventEmitter } from "@/App";
import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { CommunicationButton } from "@app/core/communication/buttons-list-view/components/buttons/_index";
import { Alerts } from "@app/core/contacts/components/accordions/alerts/_index";
import { useAlertsAccordionStore } from "@app/core/contacts/components/accordions/alerts/store";
import { AlertsButton } from "@app/core/contacts/components/buttons/alert/_index";
import { MailMergeButtonManagePage } from "@app/core/new-mail-merge/buttons/mail-merge-manage-page/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { ChangeOfOwnerShipButton } from "@app/products/property/assessments/components/action-bar/buttons/change-of-ownership/_index";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyActions } from "@app/products/property/components/child-screen/actions";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import { SAPTransaction } from "@app/products/property/components/child-screen/sap-transactions/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { AssociatedContacts } from "@app/products/property/contacts-central-names/[id]/components/child-screens/associated-contacts/_index";
import { ContactConcessionCard } from "@app/products/property/contacts-central-names/[id]/components/child-screens/concession-cards/_index";
import { GeneralContactForm } from "@app/products/property/contacts-central-names/[id]/components/child-screens/general/_index";
import { ContactTransfer } from "@app/products/property/contacts-central-names/[id]/components/child-screens/transfer/_index";
import { Voting } from "@app/products/property/contacts-central-names/[id]/components/child-screens/voting/_index";
import { UpdateContactManagePageButton } from "@app/products/property/contacts-central-names/[id]/components/forms/existed/components/action-bar/buttons/update-contact/_index";
import { ContactAssociationsTab } from "@app/products/property/contacts-central-names/[id]/components/reference-sidebar/associations/_index";
import { ContactCentralNameDetailTab } from "@app/products/property/contacts-central-names/[id]/components/reference-sidebar/detail/_index";
import { ContactCentralNameDetailTabEventType } from "@app/products/property/contacts-central-names/[id]/components/reference-sidebar/detail/util";
import { useContactsCentralNamesStore } from "@app/products/property/contacts-central-names/[id]/store";
import { eAccordion } from "@app/products/property/model";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { CommunityProperty } from "@common/stores/products/config";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { eAccordionType } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

const ExistedPropertyEntity = observer(() => {
  const { alertSelectedRows } = useAlertsAccordionStore();
  const { isLLS, isGeneral } = CommunityProperty.getFlagOfStates();
  const {
    isLoading,
    contact,
    responseLoadError,
    loadContact,
    contactId,
    attribute,
  } = useContactsCentralNamesStore();
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        contact?.ContactId,
        contact?.Entity?.NameAddress_Formatted ?? "",
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Property - Contact - ${contactId}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Entity,
      Record_ID: contactId,
    },
  ];

  const listSAPNumber = useMemo(() => {
    const sapNumber = attribute?.SAP_Numbers;
    if (sapNumber?.length) {
      return sapNumber.map((item) => item.SAP_Number).toString();
    }
    return undefined;
  }, [attribute]);

  const isLockedContact = contact?.Entity?.Is_Entity_locked;

  const listPanelBar: IPropertyPanelBar[] = [
    {
      accordionId: eAccordion.Contact_Actions_And_Workflows,
      title: "Actions and Workflows",
      component: (
        <PropertyActions
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Entity}
          componentNumber={eComponent.Entity}
        />
      ),
      accordionType: eAccordionType.Actions_And_Workflows,
    },
    {
      accordionId: eAccordion.Contact_Related,
      title: "Related",
      component: (
        <PropertyAssociations
          id={parseInt(id)}
          narID={contact?.Entity?.External_Reference_Id ?? 0}
          componentNumber={eComponent.Entity}
        />
      ),
    },
    // Requirement: https://dev.azure.com/openofficeau/Radium/_workitems/edit/12802/
    // {
    //   title: "Audit",
    //   component: <ContactAudit />,
    // },
    {
      accordionId: eAccordion.Contact_Comments,
      title: "Comments",
      component: (
        <PropertyComments
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Entity}
        />
      ),
    },
    {
      accordionId: eAccordion.Contact_Concession_Cards,
      title: "Concession Cards",
      component: <ContactConcessionCard />,
      isVisible: !isLLS,
    },
    {
      accordionId: eAccordion.Contact_Documents,
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={parseInt(id)}
          componentNumber={eComponent.Entity}
        />
      ),
      isVisible: !isLLS,
    },
    {
      accordionId: eAccordion.Contact_Transfers,
      title: "Transfers",
      component: <ContactTransfer />,
    },
    {
      accordionId: eAccordion.Contact_Associated_Contacts,
      title: "Associated Contacts",
      component: <AssociatedContacts />,
    },
    {
      accordionId: eAccordion.Contact_SAP_Transactions,
      title: "SAP Transactions",
      component: <SAPTransaction sapId={listSAPNumber} />,
      isVisible: isLLS,
    },
    {
      accordionId: eAccordion.Contact_Alerts,
      title: "Alerts",
      component: (
        <Alerts
          recordID={contact?.Entity?.External_Reference_Id ?? null}
          recordType={RECORDTYPE.CORE_Contact}
        />
      ),
    },
    {
      accordionId: eAccordion.Contact_Voting,
      title: "Voting",
      component: <Voting />,
      isVisible: isGeneral,
    },
  ];

  const reloadContact = async () => {
    eventEmitter.emit(ContactCentralNameDetailTabEventType.RefreshData);
    await loadContact(parseInt(id));
  };

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Contact" badge={contact?.BannerStatus} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadContact(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={contact?.Entity?.Name ?? ""} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
                disabled={isLockedContact}
              >
                {/* TODO: implement later */}
                {/* <CCNavButton title={"PIC"} type="dropdown">
                  <AmalgamatePICManagePageContactButton />
                </CCNavButton> */}
                <ChangeOfOwnerShipButton
                  componentNumber={eComponent.Entity}
                  disabled={!contact?.Entity?.Has_Assessment_Association}
                />
                <UpdateContactManagePageButton
                  isDisabled={contact?.Entity?.Is_Obsolete}
                />
                <CCNavButton title="Alerts" type="dropdown">
                  <AlertsButton
                    recordType={RECORDTYPE.CORE_Contact}
                    onReload={reloadContact}
                    contactId={contact?.Entity?.External_Reference_Id ?? null}
                  />
                  <AlertsButton
                    isModifyButton
                    isDisabled={alertSelectedRows?.length !== 1}
                    recordType={RECORDTYPE.CORE_Contact}
                    onReload={reloadContact}
                    contactId={contact?.Entity?.External_Reference_Id ?? null}
                  />
                </CCNavButton>
              </CCNavButton>,
              <CCNavButton title={"Add"} type="sub" disabled={isLockedContact}>
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Entity}
                />
                {/* TODO: will be shown when it has a function */}
                {/* <CCNavButton title={"Add item"} /> */}
                <AddAttachmentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Entity}
                  isVisible={!isLLS}
                />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Entity}
                />
                <CommunicationButton
                  productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
                  recordType={RECORDTYPE.CommunityProperty_Entity}
                  recordId={parseInt(id)}
                />
                <MailMergeButtonManagePage
                  productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
                  dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Entity}
                  recordType={RECORDTYPE.CommunityProperty_Entity}
                  isEmptyGridSelectedRow={!parseInt(id)}
                  selectedRecordIds={[parseInt(id)]}
                  isVisible={isLLS}
                  initMailMergeData={{
                    AppendToRecordSource: false,
                    AllowAppendOptionEditing: false,
                    AllowSaveFormatOptionEditing: false,
                    BatchPrint: true,
                  }}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {contact && (
                  <CCGeneralPanel component={<GeneralContactForm />} />
                )}
                <PropertyPanelBar
                  initialPanels={listPanelBar}
                  componentId={parseInt(id)}
                  componentNumber={eComponent.Entity}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <ContactCentralNameDetailTab />,
                    },
                    {
                      title: "Related",
                      component: <ContactAssociationsTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={RECORDTYPE.CommunityProperty_Entity}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});

export default ExistedPropertyEntity;
