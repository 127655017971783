import {
  getFormattedDate,
  simpleDate,
} from "@app/products/ams/api/helpers/date-helper";
import {
  IDateInfoField,
  IWorkOrder,
  IWorkOrderAsset,
  IWorkOrderResource,
  IWorkOrderTask,
  IWorkOrderValues,
} from "@app/products/ams/model/workOrder";

export const getCreateWorkOrderObject = (
  id: number,
  workOrderNumber: string,
  description: string,
  statusId: number,
  priorityId: number | null,
  projectId: number | null,
  divisionId: number | null,
  actioningOfficerId: number | null,
  maintenanceTypeId: number | null,
  budgetId: number | null,
  specialInstructions: string,
  scheduledStartDate: Date | null,
  expectedStartDate: Date | null,
  actualStartDate: Date | null,
  actualFinishDate: Date | null
): IWorkOrderValues => {
  let newDate = simpleDate(new Date());
  let workOrder: IWorkOrderValues = {
    WorkOrderId: id,
    WorkOrderNumber: workOrderNumber,
    Description: description,
    StatusDate: newDate,
    CreatedDate: newDate,
    ScheduledStartDate: scheduledStartDate,
    ScheduledFinishDate: null,
    ExpectedStartDate: expectedStartDate,
    ExpectedFinishDate: null,
    ActualStartDate: actualStartDate,
    ActualFinishDate: actualFinishDate,
    StatusId: statusId,
    PriorityId: priorityId,
    ProjectId: projectId,
    DivisionId: divisionId,
    ActioningOfficerId: actioningOfficerId,
    MaintenanceTypeId: maintenanceTypeId,
    BudgetId: budgetId,
    Contact: null,
    SpecialInstructions: specialInstructions,
    Assets: [],
    Tasks: [],
    isOverdue: false,
  };
  return workOrder;
};

export default function defaultWorkOrder(): IWorkOrderValues {
  return initIWorkOrder();
}

export function initIWorkOrder(): IWorkOrderValues {
  let workOrder: IWorkOrderValues;
  workOrder = getCreateWorkOrderObject(
    0,
    "",
    "",
    1,
    null,
    null,
    null,
    null,
    null,
    null,
    "",
    null,
    null,
    null,
    null
  );
  return workOrder;
}

export const createWorkOrderAsset = (
  workOrderAssetId: number,
  id: number,
  assetNumber: string,
  description: string,
  location: string
) => {
  let asset: IWorkOrderAsset = {
    WorkOrderAssetId: workOrderAssetId,
    AssetId: id,
    AssetNumber: assetNumber,
    AssetDescription: description,
    Location: location,
  };
  return asset;
};

export const createWorkOrderTask = (
  workOrderTaskId: number,
  taskiId: number,
  description: string,
  taskTypeId: number,
  cost: number | null,
  budgetId: number | null
) => {
  let task: IWorkOrderTask = {
    WorkOrderTaskId: workOrderTaskId,
    TaskId: taskiId,
    TaskTypeId: taskTypeId,
    TaskStartDate: null,
    TaskEndDate: null,
    Description: description,
    BudgetId: budgetId,
    Cost: cost,
    Duration: null,
    Assets: [],
    Resources: [],
  };
  return task;
};

export const createWorkOrderResource = (
  workOrderResourceId: number,
  resourceId: number,
  resourceTypeId: number,
  name: string,
  description: string,
  quantity: number | null,
  rate: number | null,
  cost: number | null,
  supplierId: number | null
) => {
  let resource: IWorkOrderResource = {
    WorkOrderResourceId: workOrderResourceId,
    ResourceId: resourceId,
    ResourceTypeId: resourceTypeId,
    Name: name,
    Description: description,
    Quantity: quantity,
    Rate: rate,
    Cost: cost,
    SupplierId: supplierId,
  };
  return resource;
};

export const getWorkOrderStartDate = (
  workOrder: IWorkOrderValues
): IDateInfoField => {
  //Get the most important start date for a work order.
  if (workOrder.ActualStartDate)
    return {
      Name: "Actual Start Date",
      Date: getFormattedDate(workOrder.ActualStartDate),
    };
  if (workOrder.ExpectedStartDate)
    return {
      Name: "Expected Start Date",
      Date: getFormattedDate(workOrder.ExpectedStartDate),
    };
  if (workOrder.ScheduledStartDate)
    return {
      Name: "Scheduled Start Date",
      Date: getFormattedDate(workOrder.ScheduledStartDate),
    };
  if (workOrder.CreatedDate)
    return {
      Name: "Scheduled Start Date",
      Date: getFormattedDate(workOrder.CreatedDate),
    };
  return { Name: "Start Date", Date: "N/A" };
};

export const getWorkOrderFinishDate = (
  workOrder: IWorkOrderValues
): IDateInfoField => {
  //Get the most important finish date for a work order.
  if (workOrder.ActualFinishDate)
    return {
      Name: "Actual Finish Date",
      Date: getFormattedDate(workOrder.ActualFinishDate),
    };
  if (workOrder.ExpectedFinishDate)
    return {
      Name: "Expected Finish Date",
      Date: getFormattedDate(workOrder.ExpectedFinishDate),
    };
  if (workOrder.ScheduledFinishDate)
    return {
      Name: "Scheduled Finish Date",
      Date: getFormattedDate(workOrder.ScheduledFinishDate),
    };
  //return { Name:'', Date: getFormattedDate(date)};
  return { Name: "Finish Date", Date: "N/A" };
};

export const getPriorityColour = (priorityId: number): string => {
  switch (priorityId) {
    case 1:
      return "font-weight-bolder text-danger";
    case 2:
      return "font-weight-bolder text-warning";
    case 3:
      return "font-weight-bolder text-success";
    case 4:
      return "font-weight-bolder";
  }
  return "font-weight-bolder";
};

// //Get date without time
// export function simpleDate(date: Date) : Date {
//   let inDate : Date;
//   if (Object.prototype.toString.call(date) === '[object Date]') {
//     inDate = date;
//   } else { //date might be passed in as a string...like...whaaaaat? So create a date to use the date functions.
//     inDate = new Date(date);
//   }
//   //Note: Month is zero based! so 1/1/2020 = 1-Feb-2020
//   let outDate = new Date(inDate.getFullYear(), inDate.getMonth(), inDate.getDate(),0,0,0,0);
//   return outDate;
// }

// function getFormattedDate(date : Date) {
//   let inDate : Date;
//   if (Object.prototype.toString.call(date) === '[object Date]') {
//     inDate = date;
//   } else { //date might be passed in as a string...like...whaaaaat? So create a date to use the date functions.
//     inDate = new Date(date);
//   }
//   var year = inDate.getFullYear();

//   var month = (1 + inDate.getMonth()).toString();
//   month = month.length > 1 ? month : '0' + month;

//   var day = inDate.getDate().toString();
//   day = day.length > 1 ? day : '0' + day;

//   return day + '/' + month + '/' + year;
// }

export function isOverdue(workOrder: IWorkOrderValues): boolean {
  let isOverdue = false;
  if (workOrder.ActualFinishDate) return isOverdue;

  let today = simpleDate(new Date());
  let startDate = new Date("1/1/1111");
  if (workOrder.ExpectedStartDate)
    startDate = simpleDate(workOrder.ExpectedStartDate);
  if (workOrder.ScheduledStartDate)
    startDate = simpleDate(workOrder.ScheduledStartDate);
  if (today > startDate) isOverdue = true;
  return isOverdue;
}

export function toWorkOrderValues(workOrder: IWorkOrder): IWorkOrderValues {
  return {
    WorkOrderId: workOrder.WorkOrderId,
    WorkOrderNumber: workOrder.WorkOrderNumber,
    Description: workOrder.Description,
    StatusId: workOrder.StatusId,
    CreatedDate: workOrder.CreatedDate,
    StatusDate: workOrder.StatusDate,
    PriorityId: workOrder.PriorityId,
    ProjectId: workOrder.ProjectId,
    DivisionId: workOrder.DivisionId,
    ActioningOfficerId: workOrder.ActioningOfficerId,
    MaintenanceTypeId: workOrder.MaintenanceTypeId,
    Contact: workOrder.Contact,
    BudgetId: workOrder.BudgetId,
    ScheduledStartDate: workOrder.ScheduledStartDate,
    ScheduledFinishDate: workOrder.ScheduledFinishDate,
    ExpectedStartDate: workOrder.ExpectedStartDate,
    ExpectedFinishDate: workOrder.ExpectedFinishDate,
    ActualStartDate: workOrder.ActualStartDate,
    ActualFinishDate: workOrder.ActualFinishDate,
    SpecialInstructions: workOrder.SpecialInstructions,
    Assets: workOrder.Assets,
    Tasks: workOrder.Tasks,
  };
}
