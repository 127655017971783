import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { eFilter } from "@common/pages/search/model";
import { IPickTags } from "@common/pages/settings/lookups/fee-types/_id/components/general/pick-tags-for-fee/_index";
import {
  AnimalRecord_Tags,
  BuildingRecord_Tags,
  EventRecord_Tags,
  HMPremisesRecord_Tags,
  InfringeRecord_Tags,
  LLRecord_Tags,
  TPRecord_Tags,
  WWRecord_Tags,
} from "@common/pages/settings/lookups/fee-types/_id/components/general/pick-tags-for-fee/model";
import { forEach } from "lodash";

export const getListTagByProductEnum = (
  productTypeEnums: PRODUCT_TYPE_NUMBER[]
) => {
  const tags: IPickTags[] = [];
  forEach(productTypeEnums, (productType) => {
    switch (productType) {
      case PRODUCT_TYPE_NUMBER.Animals:
        tags.push(
          ...[
            {
              ProductTypeName: eFilter.Animals,
              Key:
                eFilter.Animals +
                AnimalRecord_Tags[AnimalRecord_Tags.REGISTRATIONNUMBER],
              Value: AnimalRecord_Tags[AnimalRecord_Tags.REGISTRATIONNUMBER],
            },
            {
              ProductTypeName: eFilter.Animals,
              Key:
                eFilter.Animals +
                AnimalRecord_Tags[AnimalRecord_Tags.TAGNUMBER],
              Value: AnimalRecord_Tags[AnimalRecord_Tags.TAGNUMBER],
            },
            {
              ProductTypeName: eFilter.Animals,
              Key: eFilter.Animals + AnimalRecord_Tags[AnimalRecord_Tags.BREED],
              Value: AnimalRecord_Tags[AnimalRecord_Tags.BREED],
            },
            {
              ProductTypeName: eFilter.Animals,
              Key:
                eFilter.Animals +
                AnimalRecord_Tags[AnimalRecord_Tags.ANIMALNAME],
              Value: AnimalRecord_Tags[AnimalRecord_Tags.ANIMALNAME],
            },
            {
              ProductTypeName: eFilter.Animals,
              Key:
                eFilter.Animals +
                AnimalRecord_Tags[AnimalRecord_Tags.OWNERNAME],
              Value: AnimalRecord_Tags[AnimalRecord_Tags.OWNERNAME],
            },
            {
              ProductTypeName: eFilter.Animals,
              Key:
                eFilter.Animals +
                AnimalRecord_Tags[AnimalRecord_Tags.REGISTEREDADDRESS],
              Value: AnimalRecord_Tags[AnimalRecord_Tags.REGISTEREDADDRESS],
            },
          ]
        );
        break;
      case PRODUCT_TYPE_NUMBER.Building:
        tags.push(
          ...[
            {
              ProductTypeName: eFilter.Building,
              Key:
                eFilter.Building +
                BuildingRecord_Tags[BuildingRecord_Tags.REGISTRATIONNUMBER],
              Value:
                BuildingRecord_Tags[BuildingRecord_Tags.REGISTRATIONNUMBER],
            },
            {
              ProductTypeName: eFilter.Building,
              Key:
                eFilter.Building +
                BuildingRecord_Tags[BuildingRecord_Tags.TRADININGNAME],
              Value: BuildingRecord_Tags[BuildingRecord_Tags.TRADININGNAME],
            },
            {
              ProductTypeName: eFilter.Building,
              Key:
                eFilter.Building +
                BuildingRecord_Tags[BuildingRecord_Tags.PREMISESTYPE],
              Value: BuildingRecord_Tags[BuildingRecord_Tags.PREMISESTYPE],
            },
            {
              ProductTypeName: eFilter.Building,
              Key:
                eFilter.Building +
                BuildingRecord_Tags[BuildingRecord_Tags.PROPRIETOR],
              Value: BuildingRecord_Tags[BuildingRecord_Tags.PROPRIETOR],
            },
            {
              ProductTypeName: eFilter.Building,
              Key:
                eFilter.Building +
                BuildingRecord_Tags[BuildingRecord_Tags.PROPRIETORADDRESS],
              Value: BuildingRecord_Tags[BuildingRecord_Tags.PROPRIETORADDRESS],
            },
            {
              ProductTypeName: eFilter.Building,
              Key:
                eFilter.Building +
                BuildingRecord_Tags[BuildingRecord_Tags.REGISTEREDADDRESS],
              Value: BuildingRecord_Tags[BuildingRecord_Tags.REGISTEREDADDRESS],
            },
          ]
        );
        break;
      case PRODUCT_TYPE_NUMBER.Cemeteries:
        tags.push(
          ...[
            {
              ProductTypeName: eFilter.Cemeteries,
              Key:
                eFilter.Cemeteries +
                LLRecord_Tags[LLRecord_Tags.REGISTRATIONNUMBER],
              Value: LLRecord_Tags[LLRecord_Tags.REGISTRATIONNUMBER],
            },
            {
              ProductTypeName: eFilter.Cemeteries,
              Key:
                eFilter.Cemeteries + LLRecord_Tags[LLRecord_Tags.TRADININGNAME],
              Value: LLRecord_Tags[LLRecord_Tags.TRADININGNAME],
            },
            {
              ProductTypeName: eFilter.Cemeteries,
              Key:
                eFilter.Cemeteries + LLRecord_Tags[LLRecord_Tags.PREMISESTYPE],
              Value: LLRecord_Tags[LLRecord_Tags.PREMISESTYPE],
            },
            {
              ProductTypeName: eFilter.Cemeteries,
              Key: eFilter.Cemeteries + LLRecord_Tags[LLRecord_Tags.PROPRIETOR],
              Value: LLRecord_Tags[LLRecord_Tags.PROPRIETOR],
            },
            {
              ProductTypeName: eFilter.Cemeteries,
              Key:
                eFilter.Cemeteries +
                LLRecord_Tags[LLRecord_Tags.PROPRIETORADDRESS],
              Value: LLRecord_Tags[LLRecord_Tags.PROPRIETORADDRESS],
            },
          ]
        );
        break;
      case PRODUCT_TYPE_NUMBER.EventManagementSystem:
        tags.push(
          ...[
            {
              ProductTypeName: eFilter.Event_Management,
              Key:
                eFilter.Event_Management +
                EventRecord_Tags[EventRecord_Tags.REGISTRATIONNUMBER],
              Value: EventRecord_Tags[EventRecord_Tags.REGISTRATIONNUMBER],
            },
            {
              ProductTypeName: eFilter.Event_Management,
              Key:
                eFilter.Event_Management +
                EventRecord_Tags[EventRecord_Tags.TRADININGNAME],
              Value: EventRecord_Tags[EventRecord_Tags.TRADININGNAME],
            },
            {
              ProductTypeName: eFilter.Event_Management,
              Key:
                eFilter.Event_Management +
                EventRecord_Tags[EventRecord_Tags.PREMISESTYPE],
              Value: EventRecord_Tags[EventRecord_Tags.PREMISESTYPE],
            },
            {
              ProductTypeName: eFilter.Event_Management,
              Key:
                eFilter.Event_Management +
                EventRecord_Tags[EventRecord_Tags.PROPRIETOR],
              Value: EventRecord_Tags[EventRecord_Tags.PROPRIETOR],
            },
            {
              ProductTypeName: eFilter.Event_Management,
              Key:
                eFilter.Event_Management +
                EventRecord_Tags[EventRecord_Tags.PROPRIETORADDRESS],
              Value: EventRecord_Tags[EventRecord_Tags.PROPRIETORADDRESS],
            },
            {
              ProductTypeName: eFilter.Event_Management,
              Key:
                eFilter.Event_Management +
                EventRecord_Tags[EventRecord_Tags.MASTERFACILITY],
              Value: EventRecord_Tags[EventRecord_Tags.MASTERFACILITY],
            },
          ]
        );
        break;
      case PRODUCT_TYPE_NUMBER.HealthManager:
        tags.push(
          ...[
            {
              ProductTypeName: eFilter.Health_Manager,
              Key:
                eFilter.Health_Manager +
                HMPremisesRecord_Tags[HMPremisesRecord_Tags.REGISTRATIONNUMBER],
              Value:
                HMPremisesRecord_Tags[HMPremisesRecord_Tags.REGISTRATIONNUMBER],
            },
            {
              ProductTypeName: eFilter.Health_Manager,
              Key:
                eFilter.Health_Manager +
                HMPremisesRecord_Tags[HMPremisesRecord_Tags.TRADININGNAME],
              Value: HMPremisesRecord_Tags[HMPremisesRecord_Tags.TRADININGNAME],
            },
            {
              ProductTypeName: eFilter.Health_Manager,
              Key:
                eFilter.Health_Manager +
                HMPremisesRecord_Tags[HMPremisesRecord_Tags.PREMISESTYPE],
              Value: HMPremisesRecord_Tags[HMPremisesRecord_Tags.PREMISESTYPE],
            },
            {
              ProductTypeName: eFilter.Health_Manager,
              Key:
                eFilter.Health_Manager +
                HMPremisesRecord_Tags[HMPremisesRecord_Tags.PROPRIETOR],
              Value: HMPremisesRecord_Tags[HMPremisesRecord_Tags.PROPRIETOR],
            },
            {
              ProductTypeName: eFilter.Health_Manager,
              Key:
                eFilter.Health_Manager +
                HMPremisesRecord_Tags[HMPremisesRecord_Tags.PROPRIETORADDRESS],
              Value:
                HMPremisesRecord_Tags[HMPremisesRecord_Tags.PROPRIETORADDRESS],
            },
            {
              ProductTypeName: eFilter.Health_Manager,
              Key:
                eFilter.Health_Manager +
                HMPremisesRecord_Tags[HMPremisesRecord_Tags.REGISTEREDADDRESS],
              Value:
                HMPremisesRecord_Tags[HMPremisesRecord_Tags.REGISTEREDADDRESS],
            },
          ]
        );
        break;
      case PRODUCT_TYPE_NUMBER.Infringement:
        tags.push(
          ...[
            {
              ProductTypeName: eFilter.Infringements,
              Key:
                eFilter.Infringements +
                InfringeRecord_Tags[InfringeRecord_Tags.REGISTRATIONNUMBER],
              Value:
                InfringeRecord_Tags[InfringeRecord_Tags.REGISTRATIONNUMBER],
            },
            {
              ProductTypeName: eFilter.Infringements,
              Key:
                eFilter.Infringements +
                InfringeRecord_Tags[InfringeRecord_Tags.INFRINGEMENTNUMBER],
              Value:
                InfringeRecord_Tags[InfringeRecord_Tags.INFRINGEMENTNUMBER],
            },
            {
              ProductTypeName: eFilter.Infringements,
              Key:
                eFilter.Infringements +
                InfringeRecord_Tags[InfringeRecord_Tags.INFRINGEDATE],
              Value: InfringeRecord_Tags[InfringeRecord_Tags.INFRINGEDATE],
            },
            {
              ProductTypeName: eFilter.Infringements,
              Key:
                eFilter.Infringements +
                InfringeRecord_Tags[InfringeRecord_Tags.OFFENDER],
              Value: InfringeRecord_Tags[InfringeRecord_Tags.OFFENDER],
            },
            {
              ProductTypeName: eFilter.Infringements,
              Key:
                eFilter.Infringements +
                InfringeRecord_Tags[InfringeRecord_Tags.OFFENDERADDRESS],
              Value: InfringeRecord_Tags[InfringeRecord_Tags.OFFENDERADDRESS],
            },
            {
              ProductTypeName: eFilter.Infringements,
              Key:
                eFilter.Infringements +
                InfringeRecord_Tags[InfringeRecord_Tags.INFRNGEMENTTYPE],
              Value: InfringeRecord_Tags[InfringeRecord_Tags.INFRNGEMENTTYPE],
            },
            {
              ProductTypeName: eFilter.Infringements,
              Key:
                eFilter.Infringements +
                InfringeRecord_Tags[InfringeRecord_Tags.INFRNGEMENTCATEGORY],
              Value:
                InfringeRecord_Tags[InfringeRecord_Tags.INFRNGEMENTCATEGORY],
            },
            {
              ProductTypeName: eFilter.Infringements,
              Key:
                eFilter.Infringements +
                InfringeRecord_Tags[InfringeRecord_Tags.INFRINGEMNETSECTION],
              Value:
                InfringeRecord_Tags[InfringeRecord_Tags.INFRINGEMNETSECTION],
            },
          ]
        );
        break;
      case PRODUCT_TYPE_NUMBER.LLPermits:
        tags.push(
          ...[
            {
              ProductTypeName: eFilter.Local_Laws,
              Key:
                eFilter.Local_Laws +
                LLRecord_Tags[LLRecord_Tags.REGISTRATIONNUMBER],
              Value: LLRecord_Tags[LLRecord_Tags.REGISTRATIONNUMBER],
            },
            {
              ProductTypeName: eFilter.Local_Laws,
              Key:
                eFilter.Local_Laws + LLRecord_Tags[LLRecord_Tags.TRADININGNAME],
              Value: LLRecord_Tags[LLRecord_Tags.TRADININGNAME],
            },
            {
              ProductTypeName: eFilter.Local_Laws,
              Key:
                eFilter.Local_Laws + LLRecord_Tags[LLRecord_Tags.PREMISESTYPE],
              Value: LLRecord_Tags[LLRecord_Tags.PREMISESTYPE],
            },
            {
              ProductTypeName: eFilter.Local_Laws,
              Key: eFilter.Local_Laws + LLRecord_Tags[LLRecord_Tags.PROPRIETOR],
              Value: LLRecord_Tags[LLRecord_Tags.PROPRIETOR],
            },
            {
              ProductTypeName: eFilter.Local_Laws,
              Key:
                eFilter.Local_Laws +
                LLRecord_Tags[LLRecord_Tags.PROPRIETORADDRESS],
              Value: LLRecord_Tags[LLRecord_Tags.PROPRIETORADDRESS],
            },
          ]
        );
        break;
      case PRODUCT_TYPE_NUMBER.TownPlanning:
        tags.push(
          ...[
            {
              ProductTypeName: eFilter.Town_Planning,
              Key:
                eFilter.Town_Planning +
                TPRecord_Tags[TPRecord_Tags.REGISTRATIONNUMBER],
              Value: TPRecord_Tags[TPRecord_Tags.REGISTRATIONNUMBER],
            },
            {
              ProductTypeName: eFilter.Town_Planning,
              Key:
                eFilter.Town_Planning +
                TPRecord_Tags[TPRecord_Tags.TRADININGNAME],
              Value: TPRecord_Tags[TPRecord_Tags.TRADININGNAME],
            },
            {
              ProductTypeName: eFilter.Town_Planning,
              Key:
                eFilter.Town_Planning +
                TPRecord_Tags[TPRecord_Tags.PREMISESTYPE],
              Value: TPRecord_Tags[TPRecord_Tags.PREMISESTYPE],
            },
            {
              ProductTypeName: eFilter.Town_Planning,
              Key:
                eFilter.Town_Planning + TPRecord_Tags[TPRecord_Tags.PROPRIETOR],
              Value: TPRecord_Tags[TPRecord_Tags.PROPRIETOR],
            },
            {
              ProductTypeName: eFilter.Town_Planning,
              Key:
                eFilter.Town_Planning +
                TPRecord_Tags[TPRecord_Tags.PROPRIETORADDRESS],
              Value: TPRecord_Tags[TPRecord_Tags.PROPRIETORADDRESS],
            },
            {
              ProductTypeName: eFilter.Town_Planning,
              Key:
                eFilter.Town_Planning +
                TPRecord_Tags[TPRecord_Tags.REGISTEREDADDRESS],
              Value: TPRecord_Tags[TPRecord_Tags.REGISTEREDADDRESS],
            },
          ]
        );
        break;
      case PRODUCT_TYPE_NUMBER.WasteWater:
        tags.push(
          ...[
            {
              ProductTypeName: eFilter.Wastewater,
              Key:
                eFilter.Wastewater +
                WWRecord_Tags[WWRecord_Tags.REGISTRATIONNUMBER],
              Value: WWRecord_Tags[WWRecord_Tags.REGISTRATIONNUMBER],
            },
            {
              ProductTypeName: eFilter.Wastewater,
              Key:
                eFilter.Wastewater + WWRecord_Tags[WWRecord_Tags.APPLICANTNAME],
              Value: WWRecord_Tags[WWRecord_Tags.APPLICANTNAME],
            },
            {
              ProductTypeName: eFilter.Wastewater,
              Key: eFilter.Wastewater + WWRecord_Tags[WWRecord_Tags.SYSTEMTYPE],
              Value: WWRecord_Tags[WWRecord_Tags.SYSTEMTYPE],
            },
            {
              ProductTypeName: eFilter.Wastewater,
              Key:
                eFilter.Wastewater +
                WWRecord_Tags[WWRecord_Tags.REGISTEREDADDRESS],
              Value: WWRecord_Tags[WWRecord_Tags.REGISTEREDADDRESS],
            },
          ]
        );
        break;
    }
  });
  return tags;
};
