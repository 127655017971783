import { ICCRoute } from "@common/constants/ICCRoute";

export const bookingsRoute: ICCRoute = {
  path: "bookings",
  name: "Bookings",
  children: [
    {
      path: "all",
      name: "All",
      component: require("./all/_index").default,
    },
    {
      path: "all-calendar",
      name: "All (Calendar)",
      component: require("./all-calendar/_index").default,
    },
    {
      path: "by-organisation",
      name: "By Organisation",
      component: require("./by-organisation/_index").default,
    },
    {
      path: "future",
      name: "Future",
      component: require("./future/_index").default,
    },
    {
      path: "submitted",
      name: "Submitted",
      component: require("./submitted/_index").default,
    },
    {
      path: "not-yet-approved",
      name: "Not Yet Approved",
      component: require("./not-yet-approved/_index").default,
    },
    {
      path: "cancelled",
      name: "Cancelled",
      component: require("./cancelled/_index").default,
    },
    {
      path: "draft",
      name: "Draft",
      component: require("./draft/_index").default,
    },
  ],
};
