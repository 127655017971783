import { colIssues } from "@app/core/issues/config";
import { IIssue } from "@app/core/issues/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IIssuesProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<IIssue>();
const Issues: React.FC<IIssuesProps> = ({ id, recordType }) => {
  return (
    <CCGrid
      columnFields={colIssues}
      primaryField={nameOf("ID")}
      dataUrl={undefined}
    />
  );
};

export default Issues;
