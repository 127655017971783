import { Collection } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { VO_Assessment_w_Detail } from "@app/products/property/assessments/list/model";
import { IListDialog } from "@app/products/property/components/action-bar/property-workflow/model";
import {
  DTO_Request_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";
import { VO_Title } from "@app/products/property/titles/list/model";
import { DTO_LOV } from "@common/models/odataResponse";

export interface DTO_Workflow_Amalgamate_Assessment {
  WorkflowDetail: DTO_WorkflowDetail_Amalgamate_Assessment;
  WorkflowHeader: DTO_WorkflowHeader;
}

export interface DTO_WorkflowDetail_Amalgamate_Assessment {
  Details: DTO_Assessment_AmalgamateDetails;
  Associations: DTO_Assessment_AmalgamateAssociations;
  PhysicalAddress: DTO_Assessment_AmalgamatePhysicalAddress;
  AssessmentDetails: DTO_Assessment_AmalgamateAssessmentDetails;
  LOVs: DTO_Amalgamate_AssessmentLOVs;
  SelectedCollection: Collection[];
  //Using for Finish feature => show secondary workflow
  SecondaryWorkflowSelectedList?: IListDialog[];
}

export enum EKeysOfSteps {
  Details = "Details",
  Association = "Associations",
  PhysicalAddress = "PhysicalAddress",
  AssessmentDetail = "AssessmentDetails",
  Collection = "SelectedCollection",
  Comments = "Comments",
  Documents = "Documents",
  SecondaryWorkflow = "SecondaryWorkflow",
}

export const keysOfSendSteps = [
  EKeysOfSteps.Details,
  EKeysOfSteps.PhysicalAddress,
  EKeysOfSteps.AssessmentDetail,
  EKeysOfSteps.Association,
  EKeysOfSteps.Collection,
];

export interface RequestAmalgamateObj extends DTO_Request_Base {
  Assessment_Id: number;
}

export interface RequestAmalgamateAssessmentObj {
  Assessments: RequestAmalgamateObj[];
  Assessment_Ids: number[];
}

export interface DTO_Assessment_AmalgamateDetails {
  Reference: string;
  ApplicantName: string;
  ReasonId?: number;
}

export interface DTO_Assessment_AmalgamateAssociations {
  AssociationDate: Date;
  Assessment_Ids: number[];
  Primary_Assessment_Id?: number;
  Assessments: VO_Assessment_w_Detail[];
  AssessmentTitles: V_Assessment_Title_Link[];
  Title_Ids: number[];
  Titles: VO_Title[];
}

export interface DTO_Assessment_AmalgamatePhysicalAddress {
  Address_Ids: number[];
  Primary_Address_Id?: number;
  Addresses: DTO_Address[];
}

export interface DTO_Address {
  Locality: string;
  ParcelId: number;
  PostalDeliveryId: number | null;
  MapReference: string;
  MapNumber: string;
  StreetAndLocalityId: number | null;
  SideOfStreet: string;
  LocationDescriptor: string;
  FormattedAddress: string;
  Country: string;
  State: string;
  HouseTo: string;
  HouseFrom: string;
  AddressPFI: number;
  IsOutsideProperty: boolean;
  IsRuralHouseNumber: boolean;
  FloorNumberTo: string;
  FloorNumberFrom: string;
  FloorType: string;
  UnitNumberTo: string;
  UnitNumberFrom: string;
  BuildingUnitNumber: string;
  BuildingName: string;
  PropertyName: string;
  IsAddressPrimary: boolean;
  PropertyAddress: string;
  AddressTypeName: string;
  AddressTypeId: number;
  AddressId: number;
  StreetName: string;
  PostCode: number;
  StreetAndLocalityName: string;
  IsAddressValid: boolean;

  // Gen mock id
  RowId: string;
}

export interface DTO_Amalgamate_AssessmentLOVs {
  ReasonForCreation: DTO_LOV[];
}

export interface V_Assessment_Title_Link {
  Assessment_Id: number;
  Parcel_Id: number;
  Title_Id: number;
}

export interface DTO_Assessment_AmalgamateAssessmentDetails {
  Actual_Land_Area_Unit_Name: string;
  Actual_Land_Area_Unit_Id?: number;
  Actual_Land_Area?: number;
  Default_Secondary_Land_Use_Id?: number;
  Selected_Secondary_Land_Use_Id?: number;
  Secondary_Land_Uses: { [key: number]: string };
  Default_Primary_Land_Use_Id?: number;
  Selected_Primary_Land_Use_Id?: number;
  Primary_Land_Uses: { [key: number]: string };
  Default_LGA_Id: string;
  Selected_LGA: string;
  LGAs: string[];
  Default_Differential_Rate_Classification_Id?: number;
  Selected_Differential_Rate_Classification_Id?: number;
  Differential_Rate_Classifications: { [key: number]: string };
  Default_Assessment_Group_Id?: number;
  Selected_Assessment_Group_Id?: number;
  Assessment_Groups: { [key: number]: string };
  Default_Assessment_Type_Id?: number;
  Selected_Assessment_Type_Id?: number;
  Assessment_Types: { [key: number]: string };
  Assessment_Gis_Interface: VO_Assessment_GIS[];
  Gis_Interface_Ids: number[];
  SAP_Id: string;
  Status_Id: number;
  AssessmentPropertyName: string;
}

export interface VO_Assessment_GIS {
  Assessment_Id: number;
  GIS_Interface_Id: number;
  GIS_Reference: string;
  AG_Effective_From: Date | null;
  AG_Effective_To: Date | null;
  GIS_Type_Id: number | null;
  GIS_Type_Name: string;
  AG_Is_Active: boolean | null;
  Status_Id: number | null;
  // mock id for edit
  Id: string;
  // Added this field to load dropdown value
  GISTypeObj: DTO_LOV;
}

export const DIFFTYPEERROR =
  "Holdings from different Holding Types were detected. Amalgamate Holding can only be done on Holdings with the same Holding Type.";
