import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";

export interface IData {
  [key: string]: SettingField;
}

export interface IProcessDataFormFirst {
  dataConfig: IData;
  dataInitialForm: IData;
}

export enum EClientSequenceKey {
  PKSequence = 0,
  CRS_Registration = 1,
  CRS_Question = 2,
  CRS_Siblings = 3,
  CRS_FeeBatchID = 4,
  CRS_DebtorNumber = 5,
  CRS_ReceiptNumber = 6,
  HealthManager_Premises = 50,
  Core_Quote = 151,
  Core_Invoice = 152,
  Core_Receipt = 153,
  Core_DebtorNumber = 154,
  Core_ServiceStandard = 155,
  Core_Event = 156,
  Core_Appointment = 157,
  Core_Complaints = 158,
  Core_InternalFileSystem = 160,
  Core_Investigation = 161,
  CUSTOMERSERVICE_SameIssue = 201,
  Telephone_Activity = 202,
  CUST_Stats = 203,
  DISRES_Permit = 251,
  DISRES_PermitIntegration_ID = 252,
  TP_Application = 301,
  TP_ApplicationBuilding = 302,
  TP_Subdivision = 303,
  TP_Enquiry = 304,
  TP_ApplicationBuilding_Permit = 305,
  BUILD_ES = 306,
  TP_NC = 307,
  BUILD_BuildingEnquiry = 308,
  BUILD_PoolsSpa = 309,
  BUILD_Stormwater = 310,
  BUILD_Easement = 311,
  BUILD_Enforcement = 312,
  BUILD_SitingApproval = 313,
  BUILD_CouncilConsent = 314,
  BUILD_Demolition = 315,
  BUILD_POPE = 316,
  TP_SC = 317,
  TP_PSA = 318,
  TP_OAs = 319,
  TP_EOT = 320,
  TP_ApplicationBuilding_Plumbing = 321,
  TP_ApplicationBuilding_Plumbing_Permit = 322,
  TP_ApplicationBuilding_Certificate = 323,
  BUILD_BAMS = 324,
  BUILD_PrescribeEvents = 325,
  TP_OAs_AppCategoryGRP1 = 326,
  TP_OAs_AppCategoryGRP2 = 327,
  TP_OAs_AppCategoryGRP3 = 328,
  TP_PNFDueDiligence = 329,
  TP_PNFOperation = 330,
  TP_PNFApproval = 331,
  TP_CDC_Application = 332,
  BUILD_NC = 333,
  BUILD_Certificate = 334,
  MCH_Registration = 351,
  LLP_PermitType = 451,
  LLP_Permit = 452,
  Animals_Registration = 501,
  Animals_AnimalType = 502,
  Animals_PoundRegister = 503,
  Animals_Kennel = 504,
  WW_System = 551,
  WW_SystemType = 552,
  WW_Scheme = 553,
  WW_Installation = 554,
  WW_Product = 555,
  CSM_Licence = 601,
  CSM_Licence_Renew = 602,
  CSM_Poisons_PestObservation = 603,
  CSM_Poisons_Indemnity = 604,
  StreetProtection_Permit = 651,
  EMS_EventBooking = 701,
  EMS_GroupEventBooking = 702,
  NSP_Register = 751,
  Project = 801,
  RAD_Register_Licence = 901,
  RAD_Register_Accreditation = 902,
  RAD_Register_Source = 903,
  RAD_Register_Place = 904,
  RAD_Authorisation = 905,
  RAD_Acquisition = 906,
  RAD_Register_MgmtLicence = 907,
  RAD_Register_Tester = 908,
  RAD_Register_Assessor = 909,
  RAD_MgmtLicenceIncident = 910,
  RAD_MgmtLicenceResearchProject = 911,
  RAD_Source_Licence_Renew = 912,
  RAD_DisposalPathway = 913,
  RAD_Application = 914,
  HM_Premises_Renew = 951,
  SSMS_ChangeRequest = 1001,
  SSMS_MOA = 1002,
  SSMS_Repository = 1003,
  DWCMS_Application = 1101,
  DWCMS_Worker = 1102,
  DWCMS_Registration = 1103,
  DWCMS_ProhibitionOrder = 1104,
  DWCMS_RequestInfo = 1105,
  Infringement_Ticket = 1151,
  Infringement_PaymentPlan = 1152,
  Infringement_Court = 1153,
  CEM_Entry = 1201,
  FLEET_EventBooking = 1301,
  FLEET_GroupEventBooking = 1302,
  LM_LandHolding = 1401,
  LM_ApprovalNumber = 1402,
  LM_Register = 1403,
  LM_Appraisal = 1403,
  LM_DueDiligence = 1404,
}
