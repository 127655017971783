import { CoreAPIService } from "@/common/apis/coreAPIService";
import { PPRApplicationIdentityPacket } from "@app/products/town-planning/ppr/[id]/model";
import { APIResponse } from "@common/apis/model";

export const putReferral = async (
  id: number,
  actionTypeIDs: number[]
): Promise<APIResponse<PPRApplicationIdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
      `api/tp/ppr/internal/application/psar/${id}/referral`,
      JSON.stringify(actionTypeIDs)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
