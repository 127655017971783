import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IntegratedReport } from "@common/pages/report/integrated-reports/custom-reports/model";
import { commonCoreStore } from "@common/stores/core/store";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IntegratedReport>();

export const colIntegratedCustomReports: IColumnFields[] = [
  {
    field: nameOf("ReportName"),
    title: "Report Name",
    href: (dataItem: IntegratedReport) => {
      return {
        href: `${commonCoreStore.siteBaseUrl}Secure/Content/Core/IntegratedReports/ReportViewer.aspx?rn=${dataItem.InternalName}`,
        target: "_blank",
      };
    },
  },

  {
    field: nameOf("Category"),
    title: "Category",
  },

  {
    field: nameOf("CreatedDate"),
    title: "Created Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CreatedBy"),
    title: "Owner",
  },
  {
    field: nameOf("ModifiedDate"),
    title: "Modified Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ModifiedBy"),
    title: "Modified By",
  },
  {
    field: nameOf("EmailRecipients"),
    title: "Email Recipient(s)",
  },
  {
    field: nameOf("Schedule"),
    title: "Schedule",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "RepeatType",
    title: "Repeat Type",
  },
  {
    field: nameOf("ID"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
