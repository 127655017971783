import { DTO_Charge } from "@app/products/property/assessments/[id]/components/child-screens/charges/model";
import { useAssessmentChargesStore } from "@app/products/property/assessments/[id]/components/child-screens/charges/store";
import { useAssessmentTransactionStore } from "@app/products/property/assessments/[id]/components/child-screens/transactions/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const AdjustChargeButton = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();
  const { gridSelectedRows: transactionsSelected } =
    useAssessmentTransactionStore();
  const { chargesSelected } = useAssessmentChargesStore();
  const { assessmentId } = useAssessmentStore();

  const transactionSelectedId =
    transactionsSelected?.[0]?.TransactionId ?? null;
  const chargesSelectedIds = useMemo(() => {
    return chargesSelected?.map((charge: DTO_Charge) => charge.Id) ?? [];
  }, [chargesSelected]);

  const isDisabledAdjustChargeButton = useMemo(() => {
    return (
      (isNil(transactionSelectedId) || transactionsSelected?.length !== 1) &&
      !chargesSelectedIds?.length
    );
  }, [transactionSelectedId, chargesSelectedIds, transactionsSelected]);

  return (
    <CCNavButton
      disabled={isDisabledAdjustChargeButton}
      title="Adjust charge"
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Journal_Assessment_Adjust_Charge,
            data: {},
            props: {
              adjustChargeGetNewObj: {
                AssessmentId: assessmentId,
                TransactionId: transactionSelectedId,
                Charge_Ids: chargesSelectedIds,
              },
            },
          },
        ]);
      }}
    />
  );
});
