import { ConfirmReadingDialog } from "@app/products/property/meters/components/dialogs/confirm-reading/_index";
import {
  IDisassociateMeter,
  IInitialDisassociateMeter,
  RemoveOptions,
} from "@app/products/property/registers/[id]/components/dialogs/disassociate-meter/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { handleValidateDisassociateMeter } from "./util";

const nameOf = nameOfFactory<IDisassociateMeter>();
interface ICreateNoticeRunDialog {
  onClose: () => void;
  onSubmit: (event: any) => void;
  initialData: IInitialDisassociateMeter;
}

export const DisassociateMeterDialog = observer(
  ({ onClose, onSubmit, initialData }: ICreateNoticeRunDialog) => {
    const lastReading = initialData.LastReading;

    const [confirmDialog, setConfirmDialog] = useState<any | undefined>(
      undefined
    );
    const getConfirm = async () => {
      return await new Promise((res) => {
        setConfirmDialog({ actionCallback: res });
      });
    };

    const handleSubmit = (event: any) => {
      if (lastReading && event.values.LastReading < lastReading) {
        getConfirm().then((data) => {
          setConfirmDialog(undefined);
          if (data) {
            onSubmit(event.values);
          }
        });
      } else {
        onSubmit(event.values);
      }
    };

    return (
      <>
        <Form
          onSubmitClick={handleSubmit}
          initialValues={initialData}
          validator={handleValidateDisassociateMeter}
          render={(formRenderProps: FormRenderProps) => {
            const { valueGetter, valid } = formRenderProps;

            return (
              <FormElement>
                <CCDialog
                  height="auto"
                  maxWidth="35%"
                  titleHeader={"Meter Removal from Account"}
                  onClose={onClose}
                  bodyElement={
                    <>
                      <div className="cc-form cc-disassociate-meter-dialog">
                        <section className="cc-field-group">
                          <section>
                            <label className="cc-label">Meter</label>
                            <div className="cc-custom-sub-panel-bar">
                              <div className="cc-form-cols-2">
                                <div className="cc-field">
                                  <label className="cc-label">Meter ID</label>
                                  <Field
                                    name={nameOf("ID")}
                                    component={CCInput}
                                    readOnly
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Meter number
                                  </label>
                                  <Field
                                    name={nameOf("MeterNumber")}
                                    component={Input}
                                    readOnly
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Property address
                                  </label>
                                  <Field
                                    name={nameOf("PropertyAddress")}
                                    component={Input}
                                    readOnly
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Account names
                                  </label>
                                  <Field
                                    name={nameOf("AccountNames")}
                                    component={Input}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </section>
                          <section>
                            <div className="cc-form-cols-1">
                              <div className="cc-field">
                                <div className="cc-control-group">
                                  <Field
                                    name={nameOf("RemoveOptions")}
                                    component={RadioButton}
                                    label="Meter has been removed from the account"
                                    value={RemoveOptions.RemoveFromProperty}
                                    checked={
                                      valueGetter("RemoveOptions") ===
                                      RemoveOptions.RemoveFromProperty
                                    }
                                  />
                                </div>
                              </div>
                              <div className="cc-form-cols-2">
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Date of removal
                                    <CCTooltip type="validator" />
                                  </label>
                                  <Field
                                    name={nameOf("DateOfRemoval")}
                                    component={CCDatePicker}
                                    format={DATE_FORMAT.DATE_CONTROL}
                                    max={new Date()}
                                    value={valueGetter("DateOfRemoval")}
                                    disabled={
                                      valueGetter("RemoveOptions") ===
                                      RemoveOptions.DeleteAssociation
                                    }
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Meter reading on removal
                                  </label>
                                  <Field
                                    name={nameOf("LastReading")}
                                    component={CCNumericTextBox}
                                    format={NUMBER_FORMAT.NUMBER2}
                                    value={valueGetter("LastReading")}
                                    min={0}
                                    disabled={
                                      valueGetter("RemoveOptions") ===
                                      RemoveOptions.DeleteAssociation
                                    }
                                  />
                                </div>
                              </div>
                              <div className="cc-field">
                                <div className="cc-control-group">
                                  <Field
                                    name={nameOf("RemoveOptions")}
                                    component={RadioButton}
                                    value={RemoveOptions.DeleteAssociation}
                                    label="Delete the association of this meter with
                                    the account"
                                    checked={
                                      valueGetter("RemoveOptions") ===
                                      RemoveOptions.DeleteAssociation
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </section>
                        </section>
                      </div>
                      {confirmDialog && (
                        <ConfirmReadingDialog
                          onClosePopup={() => {
                            confirmDialog.actionCallback(false);
                          }}
                          onConfirmYes={() => {
                            confirmDialog.actionCallback(true);
                          }}
                        />
                      )}
                    </>
                  }
                  footerElement={
                    <div className={"cc-dialog-footer-actions-right"}>
                      <Button className={"cc-dialog-button"} onClick={onClose}>
                        Cancel
                      </Button>

                      <Button
                        className={"cc-dialog-button"}
                        disabled={!valid}
                        themeColor="primary"
                        onClick={onSubmit}
                      >
                        OK
                      </Button>
                    </div>
                  }
                />
              </FormElement>
            );
          }}
        />
      </>
    );
  }
);
