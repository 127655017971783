import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colCRSHomeBasedCarePlacements: IColumnFields[] = [
  {
    field: CRSFieldMapping.Registration_ReferenceNo,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.ActivationDate,
    title: "Activation Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.AgeAtActivation,
    title: "Age",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.ChildName, title: "Child Name" },
  { field: CRSFieldMapping.PrefMethod, title: "Preference Method" },
  {
    field: CRSFieldMapping.Placed,
    title: "Centre Placed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: CRSFieldMapping.InGroup,
    title: "In Group",
  },
  { field: CRSFieldMapping.GroupPlaced, title: "Group Placed" },
  {
    field: CRSFieldMapping.DatePlaced,
    title: "Date Placed",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.DaysReq,
    title: "Days Req",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.DaysAlloc,
    title: "Days Alloc",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.LodgementDate,
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.RegistrationDate,
    title: "Registered",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.ChildDateOfBirth,
    title: "Date of Birth",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.ChildGender,
    title: "Gender",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: CRSFieldMapping.ParentName, title: "Parent Name" },
  { field: CRSFieldMapping.Relationship, title: "Relationship" },
  { field: CRSFieldMapping.Phone, title: "Contact" },
  { field: CRSFieldMapping.Suburb, title: "Suburb" },
  { field: CRSFieldMapping.Email, title: "Email" },
];
