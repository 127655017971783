import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const IssueDocumentsButton = observer(() => {
  const { isEmptyGridSelectedRow } = useCCProductListViewStore();

  return (
    <CCNavButton title="Issue Documents" disabled={isEmptyGridSelectedRow} />
  );
});
