import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AllocateToOfficerStore {
  private _isLoading: boolean = false;
  private _isShowDialog: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isShowDialog() {
    return toJS(this._isShowDialog);
  }
  setIsShowDialog = (isShowDialog: boolean) => {
    runInAction(() => {
      this._isShowDialog = isShowDialog;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isShowDialog = false;
      this._isLoading = false;
    });
  };
}
export const allocateToOfficerStore = new AllocateToOfficerStore();
const allocateToOfficerStoreContext = createContext(allocateToOfficerStore);
export const useAllocateToOfficerStore = () => {
  return useContext(allocateToOfficerStoreContext);
};
