import { DTO_Component_Lineage_DataApiTree } from "@app/products/property/components/lineage-accordion/model";
import { PROPERTY_TITLES_ROUTE } from "@app/products/property/titles/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Component_Lineage_DataApiTree>();

export const lotLineageCols: IColumnFields[] = [
  {
    field: nameOf("LegalDescription"),
    title: "Legal description",
    linkTo: (dataItem: DTO_Component_Lineage_DataApiTree) =>
      `${PROPERTY_TITLES_ROUTE}/${dataItem?.Id}`,
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
