import { EGenderAnimal } from "@app/products/animals/config";
import { POUND_REGISTER_ROUTE } from "@app/products/animals/pound-register/[id]/constant";
import { Svc_Animals_PoundRegisters } from "@app/products/animals/pound-register/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_Animals_PoundRegisters>();

export const colAnimalsPoundRegisterByStatus: IColumnFields[] = [
  {
    field: nameOf("RegisterNo"),
    title: "Pound Number",
    locked: true,
    linkTo: (dataItem: Svc_Animals_PoundRegisters) => {
      return `${POUND_REGISTER_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: nameOf("AnimalsRegNo"),
    title: "Animal Number",
  },
  {
    field: nameOf("CRMSEventNo"),
    title: "Request Number",
  },
  {
    field: nameOf("MicrochipNumber"),
    title: "Microchip Number",
  },
  {
    field: nameOf("DateEntered"),
    title: "Entered",
    format: DATE_FORMAT.DATE,
  },
  { field: nameOf("ImpoundedBy"), title: "Impounded By" },
  {
    field: nameOf("PickUpLocation"),
    title: "Pick Up Location",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  { field: nameOf("Outcome"), title: "Outcome" },
  {
    field: nameOf("DateClosure"),
    title: "Closure",
    format: DATE_FORMAT.DATE,
  },
  { field: nameOf("AnimalType"), title: "Animal Type" },
  { field: nameOf("Breed"), title: "Breed" },
  { field: nameOf("Colour"), title: "Colour" },
  {
    field: nameOf("AmountOS"),
    title: "Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  { field: nameOf("Gender"), title: "Gender", filterByEnum: EGenderAnimal },
  {
    field: nameOf("Age"),
    title: "Age",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
