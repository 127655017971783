import { ReactElement } from "react";

export enum TYPE_NOTIFICATION {
  NONE = "None",
  SUCCESS = "Success",
  ERROR = "Error",
  WARNING = "Warning",
  INFORMATION = "Information",
}
export interface ICCNotificationItemProps {
  primaryContent: string | ReactElement;
  secondaryContents?: string[];
  type: TYPE_NOTIFICATION;
  onClose: () => void;
  isAutoClose?: boolean;
  showCloseButton?: boolean;
}

export interface ICCNotification {
  primaryContent: string | ReactElement;
  secondaryContents?: string[];
  type: TYPE_NOTIFICATION;
  isAutoClose?: boolean;
  showCloseButton?: boolean;
}
