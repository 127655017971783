import { loadSchemeDetailTabById } from "@app/products/property/schemes/[id]/components/reference-sidebar/detail/api";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const SchemeDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [schemeInfo, setSchemeInfo] = useState<any>({});
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const schemeId = params.id || lastSelectedRow.SchemeId;
  useEffect(() => {
    setIsLoading(true);
    if (!schemeId) {
      setIsLoading(false);
      return;
    }
    loadSchemeDetailTabById(schemeId).then((data) => {
      if (!data) return;
      setSchemeInfo(data);
      setIsLoading(false);
    });
  }, [schemeId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Scheme ID:"}
          value={schemeInfo?.SchemeId}
        />
        <ProductReferenceRow
          title={"Scheme Type:"}
          value={schemeInfo?.SchemeType}
        />
        <ProductReferenceRow
          title={"Number of Accounts:"}
          value={schemeInfo?.NumberOfAccounts}
        />

        {schemeInfo.EstimatedCost !== undefined &&
          schemeInfo.EstimatedCost !== null && (
            <ProductReferenceRow
              title={"Estimate Cost:"}
              value={formatDisplayValue(
                schemeInfo.EstimatedCost,
                CURRENCY_FORMAT.CURRENCY1
              )}
              format="currency"
            />
          )}

        {schemeInfo.EstimatedSubsidy !== undefined &&
          schemeInfo.EstimatedSubsidy !== null && (
            <ProductReferenceRow
              title={"Estimated Subsidy:"}
              value={formatDisplayValue(
                schemeInfo.EstimatedSubsidy,
                CURRENCY_FORMAT.CURRENCY1
              )}
              format="currency"
            />
          )}

        {schemeInfo.EstimatedCostToAssessments !== undefined &&
          schemeInfo.EstimatedCostToAssessments !== null && (
            <ProductReferenceRow
              title={"Estimated Cost To Assessments:"}
              value={formatDisplayValue(
                schemeInfo.EstimatedCostToAssessments,
                CURRENCY_FORMAT.CURRENCY1
              )}
              format="currency"
            />
          )}

        {schemeInfo.ActualCost !== undefined &&
          schemeInfo.ActualCost !== null && (
            <ProductReferenceRow
              title={"Actual Cost:"}
              value={formatDisplayValue(
                schemeInfo.ActualCost,
                CURRENCY_FORMAT.CURRENCY1
              )}
              format="currency"
            />
          )}

        {schemeInfo.ActualCostToAssessments !== undefined &&
          schemeInfo.ActualCostToAssessments !== null && (
            <ProductReferenceRow
              title={"Actual Cost To Assessments:"}
              value={formatDisplayValue(
                schemeInfo.ActualCostToAssessments,
                CURRENCY_FORMAT.CURRENCY1
              )}
              format="currency"
            />
          )}
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
