import { ActionSubmitActions } from "@common/pages/actions/model";
import { HomepageShortcutsFormElement } from "@common/pages/settings/homepage/homepage-shortcuts/[id]/components/child-screens/general/components/form-element/_index";
import { useHomePageShortcutsStore } from "@common/pages/settings/homepage/homepage-shortcuts/[id]/store";
import { HomePageFunction } from "@common/pages/settings/homepage/homepage-shortcuts/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useEffect, useRef } from "react";

export const HomePageShortcutsForm = observer(() => {
  const submitRef = useRef<(event: SyntheticEvent<any>) => void | undefined>();
  const { homepageShortcuts, setOnSubmit, submitAction } =
    useHomePageShortcutsStore();
  const { pushNotification } = useCCAppNotificationStore();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = event;
    const actionSubmit = event.event?.currentTarget.name as ActionSubmitActions;
    if (!(actionSubmit in ActionSubmitActions)) return;

    if (!isValid) return;
    if (!isModified && [ActionSubmitActions.Save].includes(actionSubmit))
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });

    submitAction(values as HomePageFunction, ActionSubmitActions.Save);
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={homepageShortcuts}
        key={JSON.stringify(homepageShortcuts)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={<HomepageShortcutsFormElement />}
            />
          );
        }}
      />
    </div>
  );
});
