import { V_Debt_Recovery_Functionality } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/dialog/modify-functionality-restrictions/model";
import { ResponseMessage } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getDebtRecoveryRestrictionsById = async (
  debtRecoveryId: number
): Promise<APIResponse<V_Debt_Recovery_Functionality[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<
      V_Debt_Recovery_Functionality[]
    >(`api/property/internal/debtrecovery/functionalities/${debtRecoveryId}`);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postDebtRecoveryRestrictionsById = async (
  debtRecoveryId: number,
  functionalities: V_Debt_Recovery_Functionality[]
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post<ResponseMessage>(
      `api/property/internal/debtrecovery/${debtRecoveryId}/functionalities`,
      JSON.stringify(functionalities)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
