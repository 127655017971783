import { ParkingPermitsFormElement } from "@app/products/parking-permits/[id]/components/child-screens/general/components/form-element/_index";
import { useParkingPermitsStore } from "@app/products/parking-permits/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const ParkingPermitsForm = observer(() => {
  const isNew = useIsNew();
  const { parkingPermits, createParkingPermits, updateParkingPermits } =
    useParkingPermitsStore();

  const handleSubmit = (data: any) => {
    if (isNew) {
      createParkingPermits(data);
    } else {
      updateParkingPermits(data);
    }
  };

  // Check if data is existed in Update Mode
  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={parkingPermits}
        render={(formRenderProps: FormRenderProps) => (
          <ParkingPermitsFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
