import { apiCoreattachments } from "@app/core/components/common/utils";
import { DOCUMENT_STATUS_NUNBER, IDocuments } from "@app/core/documents/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";

export const getDocumentsByIdAndProductType = async (
  id: number,
  recordType: RECORDTYPE,
  documentStatus: DOCUMENT_STATUS_NUNBER
): Promise<APIResponse<IDocuments[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IDocuments[]>(
      apiCoreattachments(id),
      {
        params: {
          recordType: recordType,
          status: documentStatus,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
