import { LOVTransactionType } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/components/form-elements/charge/model";
import { NumericTextBoxProps } from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import React, { memo } from "react";

export function blurDOMInput(identifier: string) {
  const input: HTMLInputElement | null = document.querySelector(
    `input${identifier}`
  );
  if (!isNil(input)) {
    input.blur();
  }
}

export function to2DecimalNumber(value: number | string): number {
  let _value = +value;
  if (isNaN(_value)) {
    return 0;
  }
  return Number(_value.toFixed(2));
}

export function getDefaultTransactionType(
  transactionTypeId: number,
  isOnlyChargeTransactions: boolean,
  transactionTypes: LOVTransactionType | undefined,
  chargeTransactionTypes: LOVTransactionType | undefined,
  financialGroupId: number
) {
  // * For conciseness, OCTT will be used on behalf of phrase Only-Charge-Transaction-Type,
  // * and TT will be used on behalf of Transaction-Type
  const defaultTT = transactionTypeId;
  const showOCTT = isOnlyChargeTransactions;
  const groupedTransactionTypes = transactionTypes?.[financialGroupId] || [];
  const groupedChargeTransactionTypes =
    chargeTransactionTypes?.[financialGroupId] || [];
  const firstTT = groupedTransactionTypes[0]?.Code ?? null;
  const firstOCTT = groupedChargeTransactionTypes[0]?.Code ?? null;
  if (isNil(defaultTT)) {
    return showOCTT ? firstOCTT : firstTT;
  }
  const doesOCTTInclude = groupedChargeTransactionTypes.some(
    (txType) => defaultTT === txType.Code
  );
  const doesTTInclude = groupedTransactionTypes.some(
    (txType) => defaultTT === txType.Code
  );
  if (showOCTT) {
    return doesOCTTInclude ? defaultTT : firstOCTT;
  }
  return doesTTInclude ? defaultTT : firstTT;
}

export function withSuffixedLoading(
  Component: (props: NumericTextBoxProps) => JSX.Element,
  isLoading: boolean
) {
  const loadingAddon = () => {
    return isLoading ? (
      <span className="k-i-loading k-icon k-input-loading-icon"></span>
    ) : null;
  };
  return memo(function render(props: any) {
    return <Component {...props} suffix={loadingAddon} />;
  });
}
