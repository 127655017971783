import { KeyValuePair } from "@app/core/documents/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { NoticeClassification } from "@common/constants/enumerations";
import { FSR_NOTICETYPE } from "@common/constants/fsrCode";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export interface NoticeType extends DTOBaseClass_Standard {
  NoticeType_ID: number;
  Name: string;
  Description: string;
  Code: string;
  NoticeClassification_ENUM?: NoticeClassification;
  NoticeClassification_Name: string;
  PenaltyUnits?: number;
  FSRCode_ENUM?: FSR_NOTICETYPE;
  FSRCode_Name: string;
  SystemFilter_ENUMS: string;
  SystemFilter_Names: string;
  SortCode?: number;
  Flag_ExposeToAPI?: boolean;
  Flag_MandatoryComplianceDue?: boolean;
}

export interface NoticesTypeLovs {
  NoticeClassification: KeyValuePair<number, string>[];
  FSRCode: KeyValuePacket[];
  SystemFilter: KeyValuePacket[];
}

export interface IParentNoticeTypeSection {
  id: number;
  recordType: RECORDTYPE;
  data?: NoticeType;
  notification?: IAppNotificationItemAddProps[];
}

export enum LookupNoticeTypeSubmitActions {
  Save = "Save",
  New = "New",
}