import { DTO_Supplementary_Details } from "@app/products/property/supplementary-rates/[id]/components/child-screens/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const getSupplementaryRatesDetailsById = async (
  supplementaryId: number
): Promise<DTO_Supplementary_Details | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Supplementary_Details>(
        `/api/property/internal/supplementary/${supplementaryId}/detail`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
