export const mockRecordWorkComplete = [
  {
    WorkOrderId: 1,
    HasBeenCompleted: true,
    NotifiedOn: new Date("07-Jan-2022"),
    ClearedOn: new Date("15-Sep-2018"),
    Contractor: "Ben Godfrey",
    ContractorNotifiedOn: new Date("14-Aug-2018 14:14"),
    AssessmentNumber: "5576260",
    PropertyAddress: "14 Grays Rd, Fern Tree",
    Owners: "I S Middleton & J M Middleton",
    Clearance: "10m Fire Break",
    Period: "2018/2019",
    Comment: "Normal Clear",
    ParcelId: 157810,
    ToBeClearedBy: new Date("14-Aug-2018"),
    CaseId: 4,
  },
  {
    WorkOrderId: 2,
    HasBeenCompleted: true,
    NotifiedOn: new Date("07-Jan-2022"),
    ClearedOn: new Date("18-Sep-2018"),
    Contractor: "Ben Godfrey",
    ContractorNotifiedOn: new Date("30-Aug-2018 10:23"),
    AssessmentNumber: "1854438",
    PropertyAddress: "9 Lilie St, Glebe",
    Owners: "D M Curtano",
    Clearance: "20m Fee Break",
    Period: "2018/2019",
    ParcelId: 109140,
    ToBeClearedBy: new Date("30-Aug-2018"),
    CaseId: 5,
  },
  {
    WorkOrderId: 3,
    HasBeenCompleted: true,
    NotifiedOn: new Date("07-Jan-2022"),
    ClearedOn: new Date("2-Sep-2018"),
    Contractor: "Ben Godfrey",
    ContractorNotifiedOn: new Date("12-Sep-2018 8:20"),
    AssessmentNumber: "3007670",
    PropertyAddress: "57 Montagu St, New Town",
    Owners: "J A Dunning",
    Clearance: "Clear Entire Property",
    Period: "2018/2019",
    ParcelId: 128385,
    ToBeClearedBy: new Date("12-Sep-2018"),
    CaseId: 11,
  },
  {
    WorkOrderId: 5,
    HasBeenCompleted: true,
    NotifiedOn: new Date("10-Sep-2018"),
    ClearedOn: new Date("12-Sep-2018"),
    Contractor: "Mark Steven",
    ContractorNotifiedOn: new Date("12-Sep-2018 11:38"),
    AssessmentNumber: "3378113",
    PropertyAddress: "9 Setvens Farm Dr, West Hobart",
    Owners: "D A Potter",
    Clearance: "Clear Entire Property",
    Period: "2018/2019",
    ParcelId: 255050,
    ToBeClearedBy: new Date("14-Aug-2018"),
    CaseId: 13,
  },
  {
    WorkOrderId: 8,
    HasBeenCompleted: false,
    NotifiedOn: new Date("8-Sep-2018"),
    ClearedOn: new Date("7-Sep-2018"),
    Contractor: "Mark Steven",
    AssessmentNumber: "3378113",
    PropertyAddress: "9 Setvens Farm Dr, West Hobart",
    Owners: "D C Mackie",
    Clearance: "5m Fire Break",
    Period: "2018/2019",
    ParcelId: 255050,
    ToBeClearedBy: new Date("12-Sep-2018"),
    CaseId: 13,
  },
  {
    WorkOrderId: 4,
    HasBeenCompleted: false,
    Contractor: "Nick Paine",
    NotifiedOn: new Date("2-Sep-2018"),
    ClearedOn: new Date("1-Sep-2018"),
    ContractorNotifiedOn: new Date("30-Aug-2018 10:54"),
    PropertyAddress: "12-16 Bathurst St, Hobart",
    Owners: "S D Johnson",
    Clearance: "No Hazard",
    Period: "2018/2019",
    ParcelId: 194430,
    ToBeClearedBy: new Date("14-Jan-2018"),
    CaseId: 10,
  },
  {
    WorkOrderId: 6,
    HasBeenCompleted: false,
    Contractor: "Nick Paine",
    NotifiedOn: new Date("5-Sep-2018"),
    ClearedOn: new Date("6-Sep-2018"),
    AssessmentNumber: "5682581",
    PropertyAddress: "6 Ada Cres, Sandy Bay",
    Owners: "C L Dergess",
    Clearance: "No Hazard",
    Period: "2018/2019",
    ParcelId: 206565,
    ToBeClearedBy: new Date("14-Aug-2018"),
    CaseId: 20,
  },
  {
    WorkOrderId: 7,
    HasBeenCompleted: false,
    NotifiedOn: new Date("2-Sep-2018"),
    ClearedOn: new Date("5-Sep-2018"),
    Contractor: "Nick Paine",
    AssessmentNumber: "5682581",
    PropertyAddress: "254 Churchill Ave, Sandy Bay",
    Owners: "B G Badger",
    Clearance: "Clear Entire Property",
    Period: "2018/2019",
    ParcelId: 171300,
    ToBeClearedBy: new Date("13-Aug-2018"),
    CaseId: 22,
  },
];
