import { IEditGISReferenceDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/edit-gis-reference/model";
import { EditGISReferenceDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/edit-gis-reference/_index";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { gisReferenceDataGridToArray } from "@app/products/property/registers/[id]/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { Fragment, useState } from "react";

export const EditGISReferenceButton = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const {
    selectedGISReference,
    editGISReference,
    isEmptySelectedGISReference,
  } = useRegisterStore();
  const { pushNotification } = useCCAppNotificationStore();

  return (
    <Fragment>
      <CCNavButton
        title={"Edit"}
        disabled={isEmptySelectedGISReference}
        onClick={() => {
          setShowDialog(true);
        }}
      />
      {showDialog && (
        <EditGISReferenceDialog
          initialValue={{ GISReferenceGrid: selectedGISReference }}
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={(data: IEditGISReferenceDialog) => {
            editGISReference(
              gisReferenceDataGridToArray(selectedGISReference),
              gisReferenceDataGridToArray(data.GISReferenceGrid)
            );
            setShowDialog(false);
            pushNotification({
              title: "GIS reference edited successfully.",
              type: "success",
            });
          }}
        />
      )}
    </Fragment>
  );
});
