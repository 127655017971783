import { colParcelCertificates } from "@app/products/property/parcels/[id]/components/child-screens/certificates/config";
import { DTO_Certificate } from "@app/products/property/parcels/[id]/components/child-screens/certificates/model";
import { useParcelCertificateStore } from "@app/products/property/parcels/[id]/components/child-screens/certificates/store";
import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

const nameOf = nameOfFactory<DTO_Certificate>();
export const ParcelCertificates = observer(() => {
  const { isLoading, certificate, loadParcelCertificate, resetStore } =
    useParcelCertificateStore();
  const { parcelId } = useParcelsStore();

  useEffect(() => {
    if (parcelId) loadParcelCertificate(parcelId);
    return () => resetStore();
    // eslint-disable-next-line
  }, [parcelId, loadParcelCertificate]);

  return (
    <div className="cc-parcel-certificates">
      <CCGrid
        isLoading={isLoading}
        data={certificate?.Certificates ?? []}
        columnFields={colParcelCertificates}
        primaryField={nameOf("Id")}
      />
    </div>
  );
});
