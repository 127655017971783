import { Svc_Animals_TypeView } from "@app/products/animals/system-admin/animal-types/model";

export const AnimalSystemAdminAnimalTypesBookmark = {
  getBookmarkListViewDisplayName() {
    return "Animal - System Admin";
  },

  getBookmarkListViewDetail() {
    return "Animal - System Admin - Animal Types";
  },

  getBookmarkListViewDetailsRecord(selectedRow: Svc_Animals_TypeView) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Animal - Animal Types ${dynamicDisplayName}`;
  },
};
