import { ECustomColNameProperty } from "@app/products/property/config";
import {
  colAssessmentSummary,
  colValuationSummary,
} from "@app/products/property/supplementary-rates/[id]/components/child-screens/general/config";
import {
  DTO_Supplementary_AssessmentSummary,
  DTO_Supplementary_LevyCalculationSummary,
  DTO_Supplementary_LevySummary,
  DTO_Supplementary_ValuationSummary,
} from "@app/products/property/supplementary-rates/[id]/components/child-screens/general/model";
import { useSupplementaryRatesGeneralStore } from "@app/products/property/supplementary-rates/[id]/components/child-screens/general/store";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleProminent } from "@common/utils/formatting";
import { CCGrid } from "@components/cc-grid/_index";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { NumberCell } from "@components/cc-grid/components/grid-cells/number/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
const nameOfAssessmentSummary =
  nameOfFactory<DTO_Supplementary_AssessmentSummary>();
const nameOfValuationSummary =
  nameOfFactory<DTO_Supplementary_ValuationSummary>();
const nameOfLevySummary = nameOfFactory<DTO_Supplementary_LevySummary>();
const nameOfLevyAdjustments =
  nameOfFactory<DTO_Supplementary_LevyCalculationSummary>();

export const SupplementaryRatesGeneral = observer(() => {
  const { supplementaryRatesId } = useSupplementaryRatesStore();

  //Get label
  const assessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessment
  );

  const {
    loadSupplementaryRates,
    processedColLevySummary,
    processedColLevyAdjustments,
    resetStore,
    isLoading,
    supplementaryRates,
  } = useSupplementaryRatesGeneralStore();
  useEffectOnce(() => {
    if (!supplementaryRatesId) return;
    loadSupplementaryRates(supplementaryRatesId);
    return () => {
      resetStore();
    };
  });
  const [colLevyAdj, setColLevyAdj] = useState<IColumnFields[]>([]);
  const [colLevySum, setColLevySum] = useState<IColumnFields[]>([]);
  useEffect(() => {
    let tempColLevyAdj = processedColLevyAdjustments;
    let tempColLevySum = processedColLevySummary;
    //TODO: enhance later
    tempColLevyAdj.forEach((item: IColumnFields, index: number) => {
      switch (item.field) {
        case "NumberOfLevies":
          tempColLevyAdj[index].footerCell = (
            <NumberCell
              style={formatStyleProminent}
              value={supplementaryRates?.Total_LA_NumberOfLevies}
            />
          );
          break;
        case "Base_Amount":
          tempColLevyAdj[index].footerCell = (
            <CurrencyCell
              style={formatStyleProminent}
              value={supplementaryRates?.Total_LA_Base_Amount}
            />
          );
          break;
        case "Rate_by_Valuation":
          tempColLevyAdj[index].footerCell = (
            <CurrencyCell
              style={formatStyleProminent}
              value={supplementaryRates?.Total_LA_Rate_by_Valuation}
            />
          );
          break;
        case "Increment_to_Minimum":
          tempColLevyAdj[index].footerCell = (
            <CurrencyCell
              style={formatStyleProminent}
              value={supplementaryRates?.Total_LA_Increment_to_Minimum}
            />
          );
          break;
        case "Reduction_to_Maximum":
          tempColLevyAdj[index].footerCell = (
            <CurrencyCell
              style={formatStyleProminent}
              value={supplementaryRates?.Total_LA_Reduction_to_Maximum}
            />
          );
          break;
        case "Adjustment_for_Capping":
          tempColLevyAdj[index].footerCell = (
            <CurrencyCell
              style={formatStyleProminent}
              value={supplementaryRates?.Total_LA_Adjustment_for_Capping}
            />
          );
          break;
        case "Tax_Amount":
          tempColLevyAdj[index].footerCell = (
            <CurrencyCell
              style={formatStyleProminent}
              value={supplementaryRates?.Total_LA_Tax_Amount}
            />
          );
          break;
        case "Gross_Amount":
          tempColLevyAdj[index].footerCell = (
            <CurrencyCell
              style={formatStyleProminent}
              value={supplementaryRates?.Total_LA_Gross_Amount}
            />
          );
          break;
        case "Rebate_Amount":
          tempColLevyAdj[index].footerCell = (
            <CurrencyCell
              style={formatStyleProminent}
              value={supplementaryRates?.Total_LA_Rebate_Amount}
            />
          );
          break;
        case "Nett_Amount":
          tempColLevyAdj[index].footerCell = (
            <CurrencyCell
              style={formatStyleProminent}
              value={supplementaryRates?.Total_LA_Nett_Amount}
            />
          );
          break;
      }
    });
    tempColLevySum.forEach((item: IColumnFields, index: number) => {
      switch (item.field) {
        case "NettAmount":
          tempColLevySum[index].footerCell = (
            <CurrencyCell
              style={formatStyleProminent}
              value={supplementaryRates?.Total_LS_NettAdjustments as number}
            />
          );
          break;
      }
    });
    setColLevyAdj(tempColLevyAdj);
    setColLevySum(tempColLevySum);
  }, [
    supplementaryRates,
    processedColLevySummary,
    processedColLevyAdjustments,
  ]);

  return (
    <div className="cc-form">
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">{assessmentLabel} summary</label>

            <CCGrid
              data={supplementaryRates?.AssessmentSummary ?? []}
              primaryField={nameOfAssessmentSummary("NumberOfAssessments")}
              columnFields={colAssessmentSummary}
              isLoading={isLoading}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Valuation summary</label>

            <CCGrid
              data={supplementaryRates?.ValuationSummary ?? []}
              primaryField={nameOfValuationSummary("ValuationType")}
              isLoading={isLoading}
              columnFields={colValuationSummary}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Levy summary</label>

            <CCGrid
              data={supplementaryRates?.LevySummary ?? []}
              primaryField={nameOfLevySummary("Levy_Code")}
              isLoading={isLoading}
              columnFields={colLevySum}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Levy adjustments</label>
            <CCGrid
              data={supplementaryRates?.LevyCalculationSummary ?? []}
              primaryField={nameOfLevyAdjustments("Levy_Name")}
              isLoading={isLoading}
              columnFields={colLevyAdj}
            />
          </div>
        </div>
      </section>
    </div>
  );
});
