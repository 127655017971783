import {
  HMInspectionView,
  HealthManagerInspectionViewType,
} from "@app/products/hm/inspections/model";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `HM - Inspections ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([selectedRow.ID, selectedRow.InspectionType]);
};

export const HMInspectionBookmark = {
  getListViewTitle() {
    return "Health Manager - Inspections";
  },
  getRecordTitle() {
    return "Health Manager - Inspection";
  },
  getListViewName(viewName: string) {
    return `Health Manager - Inspections - ${viewName}`;
  },
  getListViewDetailRecord(selectedRow: HMInspectionView) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Health Manager - Inspection ${dynamicDisplayName}`;
  },
};

export const hmInspectionOdataByViewType = (
  viewType?: HealthManagerInspectionViewType
) => {
  let params = "";
  if (viewType) {
    params = `viewType=${viewType}`;
  }
  return `odata/hm/internal/hminspection/GetViewHMInspection(${params})?$count=true&`;
};
