import { useAssessmentAttributesUDAStore } from "@app/products/property/assessments/[id]/components/child-screens/attributes-uda/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { IAttributeData } from "@app/products/property/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { colAttributesUDA } from "./config";

const nameOf = nameOfFactory<IAttributeData>();
export const AssessmentAttributesUDA = observer(() => {
  const { assessmentId } = useAssessmentStore();
  const { isLoading, attributesUDA, loadAttributesUDA } =
    useAssessmentAttributesUDAStore();

  useEffect(() => {
    if (assessmentId) loadAttributesUDA(assessmentId);
  }, [assessmentId, loadAttributesUDA]);

  return (
    <div className="cc-assessment-attributes-uda">
      <div className="cc-grid-control-between">
        <div className="cc-grid-control-left">
          <label className="cc-label">User defined attributes</label>
        </div>
      </div>
      <CCGrid
        isLoading={isLoading}
        className="cc-attributes-uda-grid"
        data={attributesUDA || []}
        columnFields={colAttributesUDA}
        primaryField={nameOf("Id")}
        itemPerPage={10}
      />
    </div>
  );
});
