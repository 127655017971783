import { listConfigNumberTypeTitle } from "@app/products/property/components/dialogs/add-title-lookup/config";
import { DTO_TitleLookupObj } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { isNil } from "lodash";

export const isNumberModeTitleSearch = (value?: number): boolean => {
  if (isNil(value)) return false;
  return listConfigNumberTypeTitle.includes(value);
};

export const generateTitleLookupOdataUrl = (searchData: DTO_TitleLookupObj) => {
  const { LookupKey, LookupValue, Statuses } = searchData;
  return `odata/property/internal/titlelookupregister?lookupKey=${LookupKey}&lookupValue=${LookupValue}${
    Statuses.length > 0 ? `&status=${Statuses.toString()}` : "0" // default task 12866
  }&$count=true&`;
};
