import { getComplianceAssessment } from "@app/products/property/assessments/compliance/[id]/components/child-screens/assessment/api";
import { DTO_C_Assessment } from "@app/products/property/assessments/compliance/[id]/components/child-screens/assessment/model";
import { useComplianceAssessmentStore } from "@app/products/property/assessments/compliance/[id]/components/child-screens/assessment/store";
import { useComplianceStore } from "@app/products/property/assessments/compliance/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

const nameOf = nameOfFactory<DTO_C_Assessment>();
export const ComplianceAssessment = observer(() => {
  const {
    complianceAssessment,
    loadComplianceAssessment,
    viewConfiguration,
    responseLoadError,
    isLoading,
    setSelectedAssessment,
    resetStore,
  } = useComplianceAssessmentStore();
  const { complianceId } = useComplianceStore();

  useEffect(() => {
    complianceId && loadComplianceAssessment(complianceId);
    return () => {
      resetStore();
    };
  }, [complianceId, loadComplianceAssessment, resetStore]);

  return responseLoadError ? (
    <CCLoadFailed
      responseError={responseLoadError}
      onReload={() => {
        complianceId && getComplianceAssessment(complianceId);
      }}
    />
  ) : (
    <div className="cc-assessment-compliance-assessment">
      <CCGrid
        isLoading={isLoading}
        className="cc-assessment-compliance-grid"
        data={complianceAssessment?.Assessments ?? []}
        columnFields={viewConfiguration}
        primaryField={nameOf("Assessment_Id")}
        selectableMode="single"
        onSelectionChange={(dataItem: DTO_C_Assessment[]) => {
          if (dataItem) setSelectedAssessment(dataItem[0]);
        }}
      />
    </div>
  );
});
