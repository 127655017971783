import {
  CRMSEventClosedDataChart,
  EventsDashboardUnresolvedEventsStatus,
} from "@common/pages/home/components/crms/model";
import { entries, groupBy, map, uniq } from "lodash";

export const customCrmsEventClosedData = (
  data: EventsDashboardUnresolvedEventsStatus[]
) => {
  if (!data) return undefined;

  //get distinct Group in list duplicate Group
  const uniquesGroup = uniq(map(data, "Group"));
  //group list by field Label
  const groupDataByLabel = groupBy(data, "Label");

  const newData = map(entries(groupDataByLabel), ([status, dataGroup]) => {
    let listCountOfGroups = [];

    if (uniquesGroup.length === dataGroup.length) {
      listCountOfGroups = dataGroup.map(
        (itemGroup: EventsDashboardUnresolvedEventsStatus) =>
          itemGroup?.Value ?? 0
      );
    } else {
      listCountOfGroups = uniquesGroup.map((group) => {
        const find: any = dataGroup.find(
          (itemGroup: EventsDashboardUnresolvedEventsStatus) =>
            itemGroup?.Group === group
        );
        return find?.Value ?? 0;
      });
    }
    return {
      name: status,
      data: listCountOfGroups,
    };
  });

  let dataChart: CRMSEventClosedDataChart | undefined = {
    groups: [],
    data: [],
  };

  if (uniquesGroup && newData) {
    dataChart.groups = uniquesGroup;
    dataChart.data = newData;
  }

  return dataChart;
};
