import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";
import { DTO_Assessment_Arrangements } from "./model";

export const loadAssessmentArrangementList = async (
  assessmentId: number
): Promise<DTO_Assessment_Arrangements | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Assessment_Arrangements>(
        `api/property/internal/assessment/${assessmentId}/arrangementlist`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const loadAssessmentArrangementInfo = async (
  assessmentId: number,
  arrangementId: number
): Promise<any | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Assessment_Arrangements>(
        `api/property/internal/assessment/${assessmentId}/arrangements/${arrangementId}`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
