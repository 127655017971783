import {
  AnimalTypeSubmitActions,
  Registration,
} from "@app/products/animals/[id]/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef } from "react";
import AnimalFormElement from "./components/form-element/_index";

export const AnimalForm = observer(() => {
  const { animalInfo, isFormModified, saveAnimalRegistration, setOnSubmit } =
    useAnimalStore();
  const { pushNotification } = useCCAppNotificationStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const action = event.event?.currentTarget.name as AnimalTypeSubmitActions;
    const { values, isModified, isValid } = event;
    if (!(action in AnimalTypeSubmitActions)) return;
    if (!isValid && action !== AnimalTypeSubmitActions.SameDetails) return;
    if (
      !isModified &&
      !isFormModified &&
      [AnimalTypeSubmitActions.Save, AnimalTypeSubmitActions.New].includes(
        action
      ) &&
      ![
        AnimalTypeSubmitActions.SameDetails,
        AnimalTypeSubmitActions.RegenerateFee,
        AnimalTypeSubmitActions.NewTagIssue,
        AnimalTypeSubmitActions.IssueAndView,
      ].includes(action)
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    const animalInfo = values.Registration;
    if (isNil(animalInfo.Contact?.Contact)) {
      delete animalInfo.Contact;
      saveAnimalRegistration(
        { ...animalInfo, Contact_RID: null } as Registration,
        action
      );
    } else {
      saveAnimalRegistration(animalInfo as Registration, action, isModified);
    }
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, animalInfo]);

  const initialValues = useMemo(() => {
    return {
      Registration: animalInfo,
    };
  }, [animalInfo]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <AnimalFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
