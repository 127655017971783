import { RecallUploadFileInfo } from "@app/core/recall/model";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import { cloneDeep } from "lodash";

export const renderRecallAttachmentItem = (
  files: UploadFileInfo[],
  markingElement: React.FunctionComponent<{ text: string }>
): RecallUploadFileInfo[] => {
  return cloneDeep(files).map((file) => {
    const uiFile: RecallUploadFileInfo = file as RecallUploadFileInfo,
      { name: _omitted, ...rest } = uiFile;
    const originalFileName = uiFile.originalFileName ?? uiFile.name;
    const name =
      uiFile.parentMapping === undefined
        ? markingElement({ text: originalFileName })
        : originalFileName;
    return {
      ...rest,
      name,
      originalFileName,
    } as RecallUploadFileInfo;
  });
};
