import {
  calculateValueAdjustment,
  calculateValueNewBalance,
  validatorBalanceAdjustment,
} from "@app/products/property/deferred-duty/components/action-bar/form-steps/write-off-balances/components/form-elements/balance-adjustment/util";
import { useWriteOffBalanceDialogStore } from "@app/products/property/deferred-duty/components/action-bar/form-steps/write-off-balances/store";
import { ICalculateValueBalance } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/balance-adjustment/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
} from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

export const BalanceAdjustmentFormStep = (props: IFormStepElement) => {
  const {
    options: { isReadOnly = false },
  } = props;
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!isReadOnly ? validatorBalanceAdjustment : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    nameOf,
    formRenderProps,
    options = { isReadOnly: false, accounts: [] },
  }: IFormStepElement) => {
    const { writeOffBalanceLOVs } = useWriteOffBalanceDialogStore();
    const { onChange, valueGetter, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    useEffectOnce(() => {
      onChange(nameOf("CurrentDeferredDutyAmount"), {
        value: options.accounts?.DutyBalance ?? 0,
      });
      onChange(nameOf("CurrentInterestAmount"), {
        value: options.accounts?.Interest ?? 0,
      });
      onChange(nameOf("CurrentOtherAmount"), {
        value: options.accounts?.OtherAmount ?? 0,
      });
    });

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Transaction date</label>
            <Field
              name={nameOf("TransactionDate")}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              disabled={options.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Transaction type
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("TransactionTypeId")}
              placeholder="Transaction type"
              textField="Name"
              dataItemKey="Code"
              data={writeOffBalanceLOVs?.DDTransactionTypes ?? []}
              component={CCSearchComboBox}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              value={getDropdownValue(
                valueGetter(nameOf("TransactionTypeId"))?.toString(),
                writeOffBalanceLOVs?.DDTransactionTypes ?? [],
                "Code"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("TransactionTypeId"), {
                  value: event.target.value?.Code ?? null,
                });
              }}
              disabled={options.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Reference
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("Reference")}
              component={CCInput}
              placeholder="Reference"
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              readOnly={options.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              name={nameOf("Description")}
              placeholder="Description"
              rows={3}
              component={CCTextArea}
              readOnly={options.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Notes</label>
            <Field
              name={nameOf("Notes")}
              placeholder="Notes"
              rows={3}
              component={CCTextArea}
              readOnly={options.isReadOnly}
            />
          </div>
        </div>

        <section>
          <label className="cc-label">Deferred duty balance</label>
          {errors?.BalanceAdjustment?.length ? (
            <>
              <Error>{errors?.BalanceAdjustment}</Error>
              <br />
            </>
          ) : null}
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Current balance</label>
                  <Field
                    name={nameOf("CurrentDeferredDutyAmount")}
                    component={CCCurrencyInput}
                    format={CURRENCY_FORMAT.CURRENCY1}
                    customColorNegativeNumber
                    disabled
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Adjustment</label>
                  <Field
                    name={nameOf("DeferredDutyAdjustment")}
                    component={CCCurrencyInput}
                    format={CURRENCY_FORMAT.CURRENCY1}
                    customColorNegativeNumber
                    onChange={(event: NumericTextBoxChangeEvent) => {
                      const propsCalculateValueNewBalance: ICalculateValueBalance =
                        {
                          existValue: getFieldValue(
                            "CurrentDeferredDutyAmount"
                          ),
                          value: event.value,
                        };
                      onChange(nameOf("DeferredDutyAmount"), {
                        value: calculateValueNewBalance(
                          propsCalculateValueNewBalance
                        ),
                      });
                      onChange(nameOf("DeferredDutyAdjustment"), {
                        value: event.value,
                      });
                    }}
                    value={
                      getFieldValue("DeferredDutyAdjustment")
                        ? getFieldValue("DeferredDutyAdjustment")
                        : getFieldValue("DeferredDutyAmount")
                        ? getFieldValue("DeferredDutyAmount") -
                          getFieldValue("CurrentDeferredDutyAmount")
                        : undefined
                    }
                    disabled={options.isReadOnly}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">New balance</label>
                  <Field
                    name={nameOf("DeferredDutyAmount")}
                    component={CCCurrencyInput}
                    format={CURRENCY_FORMAT.CURRENCY1}
                    customColorNegativeNumber
                    value={getFieldValue("DeferredDutyAmount")}
                    onChange={(event: NumericTextBoxChangeEvent) => {
                      const propsCalculateValueAdjustment: ICalculateValueBalance =
                        {
                          existValue: getFieldValue(
                            "CurrentDeferredDutyAmount"
                          ),
                          value: event.value,
                        };
                      onChange(nameOf("DeferredDutyAdjustment"), {
                        value: calculateValueAdjustment(
                          propsCalculateValueAdjustment
                        ),
                      });
                      onChange(nameOf("DeferredDutyAmount"), {
                        value: event.value,
                      });
                    }}
                    disabled={options.isReadOnly}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <label className="cc-label">Interest</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Current balance</label>
                  <Field
                    name={nameOf("CurrentInterestAmount")}
                    component={CCCurrencyInput}
                    format={CURRENCY_FORMAT.CURRENCY1}
                    customColorNegativeNumber
                    disabled
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Adjustment</label>
                  <Field
                    name={nameOf("InterestAdjustment")}
                    component={CCCurrencyInput}
                    format={CURRENCY_FORMAT.CURRENCY1}
                    customColorNegativeNumber
                    onChange={(event: NumericTextBoxChangeEvent) => {
                      const propsCalculateValueNewBalance: ICalculateValueBalance =
                        {
                          existValue: getFieldValue("CurrentInterestAmount"),
                          value: event.value,
                        };
                      onChange(nameOf("InterestAmount"), {
                        value: calculateValueNewBalance(
                          propsCalculateValueNewBalance
                        ),
                      });
                      onChange(nameOf("InterestAdjustment"), {
                        value: event.value,
                      });
                    }}
                    value={
                      getFieldValue("InterestAdjustment")
                        ? getFieldValue("InterestAdjustment")
                        : getFieldValue("InterestAmount")
                        ? getFieldValue("InterestAmount") -
                          getFieldValue("CurrentInterestAmount")
                        : undefined
                    }
                    disabled={options.isReadOnly}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">New balance</label>
                  <Field
                    name={nameOf("InterestAmount")}
                    component={CCCurrencyInput}
                    format={CURRENCY_FORMAT.CURRENCY1}
                    customColorNegativeNumber
                    value={getFieldValue("InterestAmount")}
                    onChange={(event: NumericTextBoxChangeEvent) => {
                      const propsCalculateValueAdjustment: ICalculateValueBalance =
                        {
                          existValue: getFieldValue("CurrentInterestAmount"),
                          value: event.value,
                        };
                      onChange(nameOf("InterestAdjustment"), {
                        value: calculateValueAdjustment(
                          propsCalculateValueAdjustment
                        ),
                      });
                      onChange(nameOf("InterestAmount"), {
                        value: event.value,
                      });
                    }}
                    disabled={options.isReadOnly}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <label className="cc-label">Other costs</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Current balance</label>
                  <Field
                    name={nameOf("CurrentOtherAmount")}
                    component={CCCurrencyInput}
                    format={CURRENCY_FORMAT.CURRENCY1}
                    customColorNegativeNumber
                    disabled
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Adjustment</label>
                  <Field
                    name={nameOf("Adjustment")}
                    component={CCCurrencyInput}
                    format={CURRENCY_FORMAT.CURRENCY1}
                    customColorNegativeNumber
                    onChange={(event: NumericTextBoxChangeEvent) => {
                      const propsCalculateValueNewBalance: ICalculateValueBalance =
                        {
                          existValue: getFieldValue("CurrentOtherAmount"),
                          value: event.value,
                        };
                      onChange(nameOf("OtherAmount"), {
                        value: calculateValueNewBalance(
                          propsCalculateValueNewBalance
                        ),
                      });
                      onChange(nameOf("Adjustment"), { value: event.value });
                    }}
                    value={
                      getFieldValue("Adjustment")
                        ? getFieldValue("Adjustment")
                        : getFieldValue("OtherAmount")
                        ? getFieldValue("OtherAmount") -
                          getFieldValue("CurrentOtherAmount")
                        : undefined
                    }
                    disabled={options.isReadOnly}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">New balance</label>
                  <Field
                    name={nameOf("OtherAmount")}
                    component={CCCurrencyInput}
                    format={CURRENCY_FORMAT.CURRENCY1}
                    customColorNegativeNumber
                    onChange={(event: NumericTextBoxChangeEvent) => {
                      const propsCalculateValueAdjustment: ICalculateValueBalance =
                        {
                          existValue: getFieldValue("CurrentOtherAmount"),
                          value: event.value,
                        };
                      onChange(nameOf("Adjustment"), {
                        value: calculateValueAdjustment(
                          propsCalculateValueAdjustment
                        ),
                      });
                      onChange(nameOf("OtherAmount"), { value: event.value });
                    }}
                    disabled={options.isReadOnly}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
);
