export const CRSFieldMapping = {
  Registration_ReferenceNo: "Registration_ReferenceNo",
  Status: "Status",
  ChildName: "ChildName",
  ChildDateOfBirth: "ChildDateOfBirth",
  ChildAge: "ChildAge",
  ChildGender: "ChildGender",
  Parent1Name: "Parent1Name",
  Parent1Relation: "Parent1Relation",
  ParentGuardian1_PreferredMethod: "ParentGuardian1_PreferredMethod",
  Phone: "Phone",
  PrefMethod: "PrefMethod",
  Email: "Email",
  Portal: "Portal",
  CBCRegistrationStatus: "CBCRegistrationStatus",
  HBCRegistrationStatus: "HBCRegistrationStatus",
  TYORegistrationStatus: "TYORegistrationStatus",
  TYOSecondYrRegStatus: "TYOSecondYrRegStatus",
  FYORegistrationStatus: "FYORegistrationStatus",
  FYOSecondYrRegStatus: "FYOSecondYrRegStatus",
  DebtorNo: "DebtorNo",
  Parent2Name: "Parent2Name",
  Parent2Relation: "Parent2Relation",
  ApplicationStatus: "ApplicationStatus",
  ChildDisplayName: "ChildDisplayName",
  DateofBirth: "DateofBirth",
  Gender: "Gender",
  ChildCRN: "ChildCRN",
  AddNeeds: "AddNeeds",
  HasSiblings: "HasSiblings",
  Parent1DisplayName: "Parent1DisplayName",
  Parent1Language: "Parent1Language",
  Parent1CRN: "Parent1CRN",
  Parent2DisplayName: "Parent2DisplayName",
  Parent2Language: "Parent2Language",
  Parent2CRN: "Parent2CRN",
  RegisteredCBC: "RegisteredCBC",
  StatusCBC: "StatusCBC",
  RegisteredHBC: "RegisteredHBC",
  StatusHBC: "StatusHBC",
  Registered3yo: "Registered3yo",
  Status3yo: "Status3yo",
  Status3yoScndYr: "Status3yoScndYr",
  Registered4yo: "Registered4yo",
  Status4yo: "Status4yo",
  Status4yoScndYr: "Status4yoScndYr",
  Category: "Category",
  ReportName: "ReportName",
  CreatedDate: "CreatedDate",
  CreatedBy: "CreatedBy",
  ModifiedDate: "ModifiedDate",
  ModifiedBy: "ModifiedBy",
  Recipients: "Recipients",
  Schedule: "Schedule",
  RepeatType: "RepeatType",
  Name: "Name",
  Description: "Description",
  AppNo: "AppNo",
  LastLetterSent: "LastLetterSent",
  Paren2Name: "Paren2Name",
  YearAttending: "YearAttending",
  ActivationDate: "ActivationDate",
  AgeAtActivation: "AgeAtActivation",
  Reg_Status: "Reg_Status",
  GroupsSelected: "GroupsSelected",
  Weighting: "Weighting",
  RegistrationDate: "RegistrationDate",
  Deferred: "Deferred",
  SecondYearEntrolment: "SecondYearEntrolment",
  FirstPref: "FirstPref",
  SecondPref: "SecondPref",
  ThirdPref: "ThirdPref",
  FourPref: "FourPref",
  FifthPref: "FifthPref",
  SixthPref: "SixthPref",
  SeventhPref: "SeventhPref",
  EighthPref: "EighthPref",
  NinethPref: "NinethPref",
  TenthPref: "TenthPref",
  EleventhPref: "EleventhPref",
  TwelfthPref: "TwelfthPref",
  ThirteenthPref: "ThirteenthPref",
  FourteenthPref: "FourteenthPref",
  FifteenthPref: "FifteenthPref",
  Age_int: "Age_int",
  Reg_Weight: "Reg_Weight",
  FirstPreference: "FirstPreference",
  LodgementDate: "LodgementDate",
  Suburb: "Suburb",
  ParentName: "ParentName",
  Relationship: "Relationship",
  Offered: "Offered",
  Placed: "Placed",
  DaysReq: "DaysReq",
  DaysAlloc: "DaysAlloc",
  IsPlacedAndWait: "IsPlacedAndWait",
  PrefCommMethod_Name: "PrefCommMethod_Name",
  Activation: "Activation",
  Proximity: "Proximity",
  DateOfBirth: "DateOfBirth",
  Pref1: "Pref1",
  Pref2: "Pref2",
  Pref3: "Pref3",
  ContactNumber: "ContactNumber",
  AllocationPriority: "AllocationPriority",
  PrefAll: "PrefAll",
  OfferLetterSentDate: "OfferLetterSentDate",
  Parent_HasPortal: "Parent_HasPortal",
  GroupOffer: "GroupOffer",
  PrefNumber: "PrefNumber",
  DateOffered: "DateOffered",
  Mobile: "Mobile",
  EmailAddress: "EmailAddress",
  Weight: "Weight",
  Comments: "Comments",
  InGroup: "InGroup",
  GroupPlaced: "GroupPlaced",
  DatePlaced: "DatePlaced",
  CancelledDate: "CancelledDate",
  AttendingThisYear: "AttendingThisYear",
  AttendingNextYear: "AttendingNextYear",
  GroupSelected: "GroupSelected",
  IsDeferred: "IsDeferred",
  IsSecondYr: "IsSecondYr",
  CentrePlaced: "CentrePlaced",
  CentreName: "CentreName",
  CentreCode: "CentreCode",
  CBC: "CBC",
  HBC: "HBC",
  Threeyo: "Threeyo",
  ThreeYo_Sessions: "ThreeYo_Sessions",
  Fouryo: "Fouryo",
  FourYo_Sessions: "FourYo_Sessions",
  CouncilFees: "CouncilFees",
  CentrePhone: "CentrePhone",
  Address: "Address",
  Year: "Year",
  GroupName: "GroupName",
  WeekDays_Name: "WeekDays_Name",
  PlacesAvailable: "PlacesAvailable",
  Safety: "Safety",
  Reserved: "Reserved",
  PlacesFilled: "PlacesFilled",
  PlacesVacant: "PlacesVacant",
  Real: "Real",
  Forecast: "Forecast",
  TotalOffered: "TotalOffered",
  TotalPlaced: "TotalPlaced",
  TotalPlacedAndWaitListed: "TotalPlacedAndWaitListed",
  Vacant: "Vacant",
  TotalWaiting: "TotalWaiting",
  TotalPlacedOtherAndWaiting: "TotalPlacedOtherAndWaiting",
  TotalPlacedandPlacedWaitListed: "TotalPlacedandPlacedWaitListed",
  TotalDeferrals: "TotalDeferrals",
  TotalSecondYrEnrolment: "TotalSecondYrEnrolment",
  PlacedandPlacedWaitdeferrals: "PlacedandPlacedWaitdeferrals",
  PlacedandPlacedWaitsecondyrenrolment: "PlacedandPlacedWaitsecondyrenrolment",
  offeredDeferrals: "offeredDeferrals",
  OfferedSecondYrEnrolment: "OfferedSecondYrEnrolment",
  WaitListedDeferrals: "WaitListedDeferrals",
  WaitListedSecondYrEnrolment: "WaitListedSecondYrEnrolment",
  Registration_RefenceNo: "Registration_RefenceNo",
  Child_DisplayName: "Child_DisplayName",
  Deposit_Amount: "Deposit_Amount",
  Deposit_Balance: "Deposit_Balance",
  DebtorNumber: "DebtorNumber",
  ServiceStatus_Name: "ServiceStatus_Name",
  Parent1Phone: "Parent1Phone",
  Parent1Address: "Parent1Address",
  PreferredMethod: "PreferredMethod",
  REGISTRATION_AppNo: "REGISTRATION_AppNo",
  CHILD_NAME: "CHILD_NAME",
  PARENT1_DEBTORNO: "PARENT1_DEBTORNO",
  PARENT1_NAMENUMBER: "PARENT1_NAMENUMBER",
  REGISTRATION_STATUS: "REGISTRATION_STATUS",
  CHILD_GENDER: "CHILD_GENDER",
  CHILD_BIRTHDATE: "CHILD_BIRTHDATE",
  CHILD_AGE: "CHILD_AGE",
  ALLOCATION_YEARATTENDING: "ALLOCATION_YEARATTENDING",
  ALLOCATION_PLACEDAT: "ALLOCATION_PLACEDAT",
  ALLOCATION_PREFERENCE: "ALLOCATION_PREFERENCE",
  PARENT1_NAME: "PARENT1_NAME",
  PARENT1_CONTACTNO: "PARENT1_CONTACTNO",
  InvoiceNumber: "InvoiceNumber",
  InvoiceSent: "InvoiceSent",
  CurrentTerm_Name: "CurrentTerm_Name",
  Parent1HomePhone: "Parent1HomePhone",
  Deposit_Reason: "Deposit_Reason",
  Term1_Reason: "Term1_Reason",
  Term2_Reason: "Term2_Reason",
  Term3_Reason: "Term3_Reason",
  Term4_Reason: "Term4_Reason",
  Registration_DebtorNumber: "Registration_DebtorNumber",
  Service: "Service",
  Term: "Term",
  ReadyForExport: "ReadyForExport",
  FeeType: "FeeType",
  Fee_Description: "Fee_Description",
  FeeType_Code: "FeeType_Code",
  Amount: "Amount",
  AmountPaid: "AmountPaid",
  InvoicedDate: "InvoicedDate",
  BPayReference: "BPayReference",
  Sys_CreatedDate: "Sys_CreatedDate",
  ExportedToFinanceDate: "ExportedToFinanceDate",
  Registration_ID: "Registration_ID",
  Registration_DebtorNo: "Registration_DebtorNo",
  Parent1_Name: "Parent1_Name",
  Amnt_TotalOwing: "Amnt_TotalOwing",
  Amnt_Credit: "Amnt_Credit",
  Amnt_CurrNotYetDue: "Amnt_CurrNotYetDue",
  Amnt_Overdue: "Amnt_Overdue",
  Amnt_OverduePlus30: "Amnt_OverduePlus30",
  Amnt_OverduePlus60: "Amnt_OverduePlus60",
  Amnt_OverduePlus90: "Amnt_OverduePlus90",
  Amnt_OverduePlus120: "Amnt_OverduePlus120",
  Parent1_PreferredMthd: "Parent1_PreferredMthd",
  Registration_SiblingName: "Registration_SiblingName",
  Parent1_ContactNo: "Parent1_ContactNo",
  Parent1_Address: "Parent1_Address",
  Parent1_Email: "Parent1_Email",
  Registration_AppNo: "Registration_AppNo",
  PaymentPlan: "PaymentPlan",
  REGISTRATION_SERVICE: "REGISTRATION_SERVICE",
  PARENT2NAME: "PARENT2NAME",
  PARENT1_Email: "PARENT1_Email",
  ExportService_DateTime: "ExportService_DateTime",
  REGISTRATION_PAYMENTPLAN: "REGISTRATION_PAYMENTPLAN",
  Last_Payment_Date: "Last_Payment_Date",
  tyo_Year_Attending: "tyo_Year_Attending",
  fyo_Year_Attending: "fyo_Year_Attending",
  PARENT2_NAME: "PARENT2_NAME",
  Preferred_Contact_Method: "Preferred_Contact_Method",
  REgistration_REferenceNo: "REgistration_REferenceNo",
  CardExpiryDueStatus: "CardExpiryDueStatus",
  CBC_CentreName: "CBC_CentreName",
  TYO_YearAttending: "TYO_YearAttending",
  TYO_CentreName: "TYO_CentreName",
  TYOSecYear_RegistrationStatus: "TYOSecYear_RegistrationStatus",
  TYOSecYear_YearAttending: "TYOSecYear_YearAttending",
  TYOSecYear_CentreName: "TYOSecYear_CentreName",
  FYO_RegistrationStatus: "FYO_RegistrationStatus",
  FYO_YearAttending: "FYO_YearAttending",
  FYO_CentreName: "FYO_CentreName",
  FYOSecYear_RegistrationStatus: "FYOSecYear_RegistrationStatus",
  FYOSecYear_YearAttending: "FYOSecYear_YearAttending",
  FYOSecYear_CentreName: "FYOSecYear_CentreName",
  Registration_CardBelongs: "Registration_CardBelongs",
  Registration_CardType: "Registration_CardType",
  Registration_CardNumber: "Registration_CardNumber",
  Registration_CardExpiryDate: "Registration_CardExpiryDate",
  Registration_CardExpirtyYear: "Registration_CardExpirtyYear",
  Registration_CardExpiryMonth: "Registration_CardExpiryMonth",
  Parent1_PreferredMethod: "Parent1_PreferredMethod",
  Parent1_PostalAddress: "Parent1_PostalAddress",
  Parent2_PreferredMethod: "Parent2_PreferredMethod",
  Parent2_Email: "Parent2_Email",
  Parent2_PostalAddress: "Parent2_PostalAddress",
  AllocationYear: "AllocationYear",
  AllocationDate: "AllocationDate",
  Officer: "Officer",
  ModeString: "ModeString",
  RoundNumber: "RoundNumber",
  Service_Name: "Service_Name",
  Method_Name: "Method_Name",
  PlaceHolderGrp_Included: "PlaceHolderGrp_Included",
  DisplayName: "DisplayName",
  BatchInformation: "BatchInformation",
  DocumentInformation: "DocumentInformation",
  ContentType: "ContentType",
  BatchNo: "BatchNo",
  SubBatchNo: "SubBatchNo",
  Printed: "Printed",
  Child_FirstName: "Child_FirstName",
  Child_LastName: "Child_LastName",
  RecordedDate: "RecordedDate",
  ActionType: "ActionType",
  ActionOfficer: "ActionOfficer",
  ActionDescription: "ActionDescription",
  DateTarget: "DateTarget",
  Status_Name: "Status_Name",
  RecordedBy: "RecordedBy",
  Comment: "Comment",
  Title: "Title",
  FileName: "FileName",
  ParentGuardian1_FirstName: "ParentGuardian1_FirstName",
  ParentGuardian1_LastName: "ParentGuardian1_LastName",
  FeeTypeDescription: "FeeTypeDescription",
  CostCode: "CostCode",
  QuestionNumber: "QuestionNumber",
  Cat_Order: "Cat_Order",
  CategoryName: "CategoryName",
  SortOrder: "SortOrder",
  Question: "Question",
  Weight_CBC: "Weight_CBC",
  Weight_HBC: "Weight_HBC",
  Weight_3yo: "Weight_3yo",
  Weight_4yo: "Weight_4yo",
  Document_Usage_Name: "Document_Usage_Name",
  Dataset_Name: "Dataset_Name",
  SaveFormat: "SaveFormat",
  WhenLoaded: "WhenLoaded",
  IsActive: "IsActive",
  AdditionalInformation: "AdditionalInformation",
  WeightingPriority_Name: "WeightingPriority_Name",
  WeightingPriority_Score: "WeightingPriority_Score",
  Child_Name: "Child_Name",
  Child_DateOfBirth: "Child_DateOfBirth",
  Child_Age: "Child_Age",
  ParentGuardian1_HomePhone: "ParentGuardian1_HomePhone",

  CurrentTerm_NoInvoiceRsn: "CurrentTerm_NoInvoiceRsn",
  ChildrenDetails: "ChildrenDetails",
  DateLodged_DATETIME: "DateLodged_DATETIME",
  ApplicationType: "ApplicationType",
  PrimaryCaregiver_DisplayName: "PrimaryCaregiver_DisplayName",
  Portal_Application_REFID: "Portal_Application_REFID",
  Portal_ReferenceNo: "Portal_ReferenceNo",
  DateSigned_DATETIME: "DateSigned_DATETIME",
  ApplicationFeePayment_TransactionStatus:
    "ApplicationFeePayment_TransactionStatus",
  PrimaryCaregiver_Email: "PrimaryCaregiver_Email",
  PrimaryCaregiver_Contact: "PrimaryCaregiver_Contact",
  PortalApplicationType: "PortalApplicationType",
  DateLodged: "DateLodged",
  ReferenceNumber: "ReferenceNumber",
  HomePhone: "HomePhone",
  WorkPhone: "WorkPhone",
  Centre_Name: "Centre_Name",
  Group_Name: "Group_Name",
  Response: "Response",
  ReceiptNumber: "ReceiptNumber",
  PaidAmount: "PaidAmount",
  ReceiptDate: "ReceiptDate",
  PaymentInfo: "PaymentInfo",
  ParentEmail: "ParentEmail",
  ParentContact: "ParentContact",
  LastLoginTime: "LastLoginTime",

  StandardReport: "StandardReport",
  ShareReport: "ShareReport",
};

export enum CRSRoute {
  CRS_APPLICATIONS_LODGED = "/crs/applications/lodged",
  CRS_APPLICATIONS_DRAFT = "/crs/applications/draft",
  CRS_APPLICATIONS_ARCHIVED = "/crs/applications/archived",
  CRS_APPLICATIONS_ALLAPPLICATIONS = "/crs/applications/allapplications",
  CRS_APPLICATIONS_SUBMITTED = "/crs/applications/submitted",
  CRS_APPLICATIONS_NOSERVICES = "/crs/applications/noservices",
  CRS_APPLICATIONS_SEARCH = "/crs/applications/search",
  CRS_APPLICATIONS_REPORTS = "/crs/applications/reports",
  CRS_APPLICATIONS_CLASSICREPORTS = "/crs/applications/classicreports",
  CRS_INFOWORKFLOW_ACKNOWLEDGEMENT = "/crs/infoworkflow/acknowledgement",
  CRS_INFOWORKFLOW_READYTOSEND = "/crs/infoworkflow/readytosend",
  CRS_INFOWORKFLOW_1STLETTERSENT = "/crs/infoworkflow/1stlettersent",
  CRS_INFOWORKFLOW_2NDLETTERSENT = "/crs/infoworkflow/2ndlettersent",
  CRS_CENTREBASEDCARE_ALLBYPREFERENCES = "/crs/centrebasedcare/allbypreferences",
  CRS_CENTREBASEDCARE_ALLBYWEIGHTING = "/crs/centrebasedcare/allbyweighting",
  CRS_CENTREBASEDCARE_ALLBYSTATUS = "/crs/centrebasedcare/allbystatus",
  CRS_CENTREBASEDCARE_WAITLISTBYCENTRE = "/crs/centrebasedcare/waitlistbycentre",
  CRS_CENTREBASEDCARE_OFFERS = "/crs/centrebasedcare/offers",
  CRS_CENTREBASEDCARE_PLACEMENTS = "/crs/centrebasedcare/placements",
  CRS_CENTREBASEDCARE_CANCELLATIONS = "/crs/centrebasedcare/cancellations",
  CRS_HOMEBASEDCARE_ALLBYWEIGHTING = "/crs/homebasedcare/allbyweighting",
  CRS_HOMEBASEDCARE_ALLBYSTATUS = "/crs/homebasedcare/allbystatus",
  CRS_HOMEBASEDCARE_PLACEMENTS = "/crs/homebasedcare/placements",
  CRS_HOMEBASEDCARE_CANCELLATIONS = "/crs/homebasedcare/cancellations",
  CRS_3YOKINDER_ALLBYPREFERENCES = "/crs/3yokinder/allbypreferences",
  CRS_3YOKINDER_ALLBYWEIGHTING = "/crs/3yokinder/allbyweighting",
  CRS_3YOKINDER_ALLBYSTATUS = "/crs/3yokinder/allbystatus",
  CRS_3YOKINDER_WAITLISTBYCENTRE = "/crs/3yokinder/waitlistbycentre",
  CRS_3YOKINDER_WAITLISTBYGROUP = "/crs/3yokinder/waitlistbygroup",
  CRS_3YOKINDER_OFFERS = "/crs/3yokinder/offers",
  CRS_3YOKINDER_PLACEMENTS = "/crs/3yokinder/placements",
  CRS_3YOKINDER_DEFERRALS = "/crs/3yokinder/deferrals",
  CRS_3YOKINDER_2NDYEARENROLMENTS = "/crs/3yokinder/2ndyearenrolments",
  CRS_3YOKINDER_CANCELLATIONS = "/crs/3yokinder/cancellations",
  CRS_4YOKINDER_ALLBYPREFERENCES = "/crs/4yokinder/allbypreferences",
  CRS_4YOKINDER_ALLBYWEIGHTING = "/crs/4yokinder/allbyweighting",
  CRS_4YOKINDER_ALLBYSTATUS = "/crs/4yokinder/allbystatus",
  CRS_4YOKINDER_WAITLISTBYCENTRE = "/crs/4yokinder/waitlistbycentre",
  CRS_4YOKINDER_WAITLISTBYGROUP = "/crs/4yokinder/waitlistbygroup",
  CRS_4YOKINDER_OFFERS = "/crs/4yokinder/offers",
  CRS_4YOKINDER_PLACEMENTS = "/crs/4yokinder/placements",
  CRS_4YOKINDER_DEFERRALS = "/crs/4yokinder/deferrals",
  CRS_4YOKINDER_2NDYEARENROLMENTS = "/crs/4yokinder/2ndyearenrolments",
  CRS_4YOKINDER_CANCELLATIONS = "/crs/4yokinder/cancellations",
  CRS_CENTRES_CENTRES = "/crs/centres/centres",
  CRS_CENTRES_HBCCAPACITY = "/crs/centres/hbccapacity",
  CRS_CENTRES_CBCCAPACITY = "/crs/centres/cbccapacity",
  CRS_CENTRES_3YOCAPACITYBYYEAR = "/crs/centres/3yocapacitybyyear",
  CRS_CENTRES_4YOCAPACITYBYYEAR = "/crs/centres/4yocapacitybyyear",
  CRS_FEES_4YODEPOSIT = "/crs/fees/4yodeposit",
  CRS_FEES_4YOFEES = "/crs/fees/4yofees",
  CRS_FEES_4YOINVOICES = "/crs/fees/4yoinvoices",
  CRS_FEES_4YOZEROFEES = "/crs/fees/4yozerofees",
  CRS_FEES_PLACEMENT4YOZEROFEES = "/crs/fees/placement4yozerofees",
  CRS_FEES_TERM14YOZEROFEES = "/crs/fees/term14yozerofees",
  CRS_FEES_TERM24YOZEROFEES = "/crs/fees/term24yozerofees",
  CRS_FEES_TERM34YOZEROFEES = "/crs/fees/term34yozerofees",
  CRS_FEES_TERM44YOZEROFEES = "/crs/fees/term44yozerofees",
  CRS_FEES_3YOFEES = "/crs/fees/3yofees",
  CRS_FEES_3YOINVOICES = "/crs/fees/3yoinvoices",
  CRS_FEES_NOTEXPORTEDFEES = "/crs/fees/notexportedfees",
  CRS_FEES_EXPORTEDFEES = "/crs/fees/exportedfees",
  CRS_FEES_NOTEXPORTEDDEBTORS = "/crs/fees/notexporteddebtors",
  CRS_FEES_EXPORTEDDEBTORS = "/crs/fees/exporteddebtors",
  CRS_FEES_OSFEESBYDEBTOR = "/crs/fees/osfeesbydebtor",
  CRS_FEES_OSFEESBYREGISTRATION = "/crs/fees/osfeesbyregistration",
  CRS_FEES_NOTEXPORTEDSERVICES = "/crs/fees/notexportedservices",
  CRS_FEES_EXPORTEDSERVICES = "/crs/fees/exportedservices",
  CRS_FEES_PAYMENTPLANS = "/crs/fees/paymentplans",
  CRS_FEES_SUBSIDYCARDS = "/crs/fees/subsidycards",
  CRS_ALLOCATIONS_ALLOCATIONS = "/crs/allocations/allocations",
  CRS_ALLOCATIONS_ROUNDSBYYEAR = "/crs/allocations/roundsbyyear",
  CRS_ALLOCATIONS_ADJUSTMENTSBYYEAR = "/crs/allocations/adjustmentsbyyear",
  CRS_ALLOCATIONS_ROUNDSBYSERVICE = "/crs/allocations/roundsbyservice",
  CRS_ALLOCATIONS_ADJUSTMENTSBYSERVICE = "/crs/allocations/adjustmentsbyservice",
  CRS_COMMUNICATION_MYUNPRINTEDDOCS = "/crs/communication/myunprinteddocs",
  CRS_COMMUNICATION_ALLMYDOCUMENTS = "/crs/communication/allmydocuments",
  CRS_COMMUNICATION_ALLDOCUMENTS = "/crs/communication/alldocuments",
  CRS_EXPORTS_ACTIONS = "/crs/exports/actions",
  CRS_EXPORTS_COMMENTS = "/crs/exports/comments",
  CRS_EXPORTS_DOCUMENTS = "/crs/exports/documents",
  CRS_EXPORTS_FEES = "/crs/exports/fees",

  CRS_PORTAL_NEWAPPLICATIONS = "/crs/portal/newapplications",
  CRS_PORTAL_WAITINGFORPAYMENT = "/crs/portal/waitingforpayment",
  CRS_PORTAL_MAINAPPCHANGES = "/crs/portal/mainappchanges",
  CRS_PORTAL_CBCREGISTRATIONS = "/crs/portal/cbcregistrations",
  CRS_PORTAL_HBCREGISTRATIONS = "/crs/portal/hbcregistrations",
  CRS_PORTAL_3YOREGISTRATIONS = "/crs/portal/3yoregistrations",
  CRS_PORTAL_4YOREGISTRATIONS = "/crs/portal/4yoregistrations",
  CRS_PORTAL_CBCREGREQUESTS = "/crs/portal/cbcregrequests",
  CRS_PORTAL_HBCREGREQUESTS = "/crs/portal/hbcregrequests",
  CRS_PORTAL_3YOREGREQUESTS = "/crs/portal/3yoregrequests",
  CRS_PORTAL_4YOREGREQUESTS = "/crs/portal/4yoregrequests",
  CRS_PORTAL_CBCOFFERRESPONSES = "/crs/portal/cbcofferresponses",
  CRS_PORTAL_HBCOFFERRESPONSES = "/crs/portal/hbcofferresponses",
  CRS_PORTAL_3YOOFFERRESPONSES = "/crs/portal/3yoofferresponses",
  CRS_PORTAL_4YOOFFERRESPONSES = "/crs/portal/4yoofferresponses",
  CRS_PORTAL_PROFILECHANGES = "/crs/portal/profilechanges",
  CRS_PORTAL_PORTALPAYMENTS = "/crs/portal/portalpayments",
  CRS_PORTAL_PORTALACCOUNTS = "/crs/portal/portalaccounts",

  CRS_PRIORITYOFACCESS_QUESTIONS = "/crs/priorityofaccess/questions",
  CRS_PRIORITYOFACCESS_GROUPS = "/crs/priorityofaccess/groups",
  CRS_SYSTEMADMIN_BULKARCHIVE = "/crs/systemadmin/bulkarchive",

  CRS_SYSTEMADMIN_FEESETUP = "/crs/systemadmin/feesetup",
  CRS_SYSTEMADMIN_HBCCOMPATSCORE = "/crs/systemadmin/hbccompat.score",
  CRS_SYSTEMADMIN_LEGACYREGISTRATIONS = "/crs/systemadmin/legacyregistrations",
  CRS_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/crs/lodged",
  CRS_USER_CHANGEPASSWORD = "/crs/user/changepassword",
  CRS_DETAIL = "/crs/manageregistration",
}

//Return other page
export enum CRSSystemAdminRouteToOtherPage {
  CRS_SYSTEMADMIN_DOCUMENTS = "/crs/systemadmin/documents",
  CRS_SYSTEMADMIN_KEYWORDS = "/crs/systemadmin/keywords",
  CRS_SYSTEMADMIN_LOCALITIES = "/crs/systemadmin/localities",
}
//Return CRS SystemAdmin form
export enum CRSFormRoute {
  CRS_SYSTEMADMIN_CHARACTERISTICS = "/crs/systemadmin/managecharacteristics",
  CRS_USER_CHANGEPASSWORD = "/crs/user/managechangepassword",
}

export class CRSResponse {
  ID: number = 0;
  Registration_ReferenceNo: number = 0;
  Status: string = "";
  ChildName: string = "";
  ChildDateOfBirth: string = "";
  ChildAge: number = 0;
  ChildGender: boolean = false;
  Parent1Name: string = "";
  Parent1Relation: string = "";
  ParentGuardian1_PreferredMethod: string = "";
  Phone: string = "";
  PrefMethod: string = "";
  Email: string = "";
  Portal: string = "";
  CBCRegistrationStatus: string = "";
  HBCRegistrationStatus: string = "";
  TYORegistrationStatus: string = "";
  TYOSecondYrRegStatus: string = "";
  FYORegistrationStatus: string = "";
  FYOSecondYrRegStatus: string = "";
  DebtorNo: number = 0;
  Parent2Name: string = "";
  Parent2Relation: string = "";
  ApplicationStatus: string = "";
  ChildDisplayName: string = "";
  DateofBirth: string = "";
  Gender: string = "";
  ChildCRN: number = 0;
  AddNeeds: string = "";
  HasSiblings: string = "";
  Parent1DisplayName: string = "";
  Parent1Language: string = "";
  Parent1CRN: number = 0;
  Parent2DisplayName: string = "";
  Parent2Language: string = "";
  Parent2CRN: string = "";
  RegisteredCBC: number = 0;
  StatusCBC: string = "";
  RegisteredHBC: number = 0;
  StatusHBC: string = "";
  Registered3yo: number = 0;
  Status3yo: string = "";
  Status3yoScndYr: string = "";
  Registered4yo: string = "";
  Status4yo: number = 0;
  Status4yoScndYr: number = 0;
  Category: string = "";
  ReportName: string = "";
  CreatedDate: string = "";
  CreatedBy: string = "";
  ModifiedDate: string = "";
  ModifiedBy: string = "";
  Recipients: string = "";
  Schedule: string = "";
  RepeatType: string = "";
  Name: string = "";
  Description: string = "";
  AppNo: number = 0;
  LastLetterSent: string = "";
  Paren2Name: string = "";
  IsPlacedAndWait: boolean = false;
  PrefCommMethod_Name: string = "";
  Activation: string = "";
  ActivationDate: string = "";
  Weighting: number = 0;
  Proximity: number = 0;
  RegistrationDate: string = "";
  DateOfBirth: string = "";
  Pref1: string = "";
  Pref2: string = "";
  Pref3: string = "";
  Suburb: string = "";
  ParentName: string = "";
  Relationship: string = "";
  ContactNumber: number = 0;
  AllocationPriority: number = 0;
  PrefAll: string = "";
  YearAttending: string = "";
  AgeAtActivation: number = 0;
  OfferLetterSentDate: string = "";
  Parent_HasPortal: boolean = false;
  Offered: string = "";
  GroupOffer: string = "";
  PrefNumber: number = 0;
  DateOffered: string = "";
  LodgementDate: string = "";
  Deferred: string = "";
  SecondYearEntrolment: boolean = false;
  Mobile: string = "";
  EmailAddress: string = "";
  Weight: number = 0;
  Comments: string = "";
  Placed: boolean = false;
  InGroup: string = "";
  GroupPlaced: string = "";
  DatePlaced: string = "";
  DaysReq: string = "";
  DaysAlloc: string = "";
  Reg_Weight: number = 0;
  FirstPreference: string = "";
  Reg_Status: string = "";
  CancelledDate: string = "";
  Age_int: number = 0;
  GroupsSelected: string = "";
  FirstPref: string = "";
  AttendingThisYear: boolean = false;
  AttendingNextYear: boolean = false;
  GroupSelected: string = "";
  IsDeferred: boolean = false;
  IsSecondYr: boolean = false;
  CentrePlaced: string = "";
  CentreName: string = "";
  CentreCode: string = "";
  CBC: boolean = false;
  HBC: boolean = false;
  Threeyo: boolean = false;
  ThreeYo_Sessions: boolean = false;
  Fouryo: boolean = false;
  FourYo_Sessions: boolean = false;
  CouncilFees: boolean = false;
  CentrePhone: string = "";
  Address: string = "";
  Year: Date = new Date();
  GroupName: string = "";
  WeekDays_Name: string = "";
  PlacesAvailable: string = "";
  Safety: string = "";
  Reserved: string = "";
  PlacesFilled: string = "";
  PlacesVacant: string = "";
  Real: boolean = false;
  Forecast: string = "";
  TotalOffered: number = 0;
  TotalPlaced: number = 0;
  TotalPlacedAndWaitListed: string = "";
  Vacant: string = "";
  TotalWaiting: number = 0;
  TotalPlacedOtherAndWaiting: number = 0;
  TotalPlacedandPlacedWaitListed: number = 0;
  TotalDeferrals: number = 0;
  TotalSecondYrEnrolment: number = 0;
  PlacedandPlacedWaitdeferrals: string = "";
  PlacedandPlacedWaitsecondyrenrolment: string = "";
  offeredDeferrals: string = "";
  OfferedSecondYrEnrolment: string = "";
  WaitListedDeferrals: string = "";
  WaitListedSecondYrEnrolment: string = "";
  Registration_RefenceNo: string = "";
  Child_DisplayName: string = "";
  Deposit_Amount: string = "";
  Deposit_Balance: string = "";
  DebtorNumber: number = 0;
  ServiceStatus_Name: string = "";
  Parent1Phone: string = "";
  Parent1Address: string = "";
  PreferredMethod: string = "";
  REGISTRATION_AppNo: string = "";
  CHILD_NAME: string = "";
  PARENT1_DEBTORNO: number = 0;
  PARENT1_NAMENUMBER: number = 0;
  REGISTRATION_STATUS: string = "";
  CHILD_GENDER: string = "";
  CHILD_BIRTHDATE: string = "";
  CHILD_AGE: number = 0;
  ALLOCATION_YEARATTENDING: string = "";
  ALLOCATION_PLACEDAT: string = "";
  ALLOCATION_PREFERENCE: string = "";
  PARENT1_NAME: string = "";
  PARENT1_CONTACTNO: number = 0;
  InvoiceNumber: number = 0;
  InvoiceSent: boolean = false;
  CurrentTerm_Name: string = "";
  Parent1HomePhone: string = "";
  Deposit_Reason: string = "";
  Term1_Reason: string = "";
  Term2_Reason: string = "";
  Term3_Reason: string = "";
  Term4_Reason: string = "";
  Registration_DebtorNumber: number = 0;
  Service: string = "";
  Term: string = "";
  ReadyForExport: boolean = false;
  FeeType: string = "";
  Fee_Description: string = "";
  FeeType_Code: string = "";
  Amount: number = 0;
  AmountPaid: number = 0;
  InvoicedDate: string = "";
  BPayReference: string = "";
  Sys_CreatedDate: string = "";
  ExportedToFinanceDate: string = "";
  Registration_ID: number = 0;
  Registration_DebtorNo: number = 0;
  Parent1_Name: string = "";
  Amnt_TotalOwing: number = 0;
  Amnt_Credit: number = 0;
  Amnt_CurrNotYetDue: number = 0;
  Amnt_Overdue: number = 0;
  Amnt_OverduePlus30: number = 0;
  Amnt_OverduePlus60: number = 0;
  Amnt_OverduePlus90: number = 0;
  Amnt_OverduePlus120: number = 0;
  Parent1_PreferredMthd: number = 0;
  Registration_SiblingName: string = "";
  Parent1_ContactNo: number = 0;
  Parent1_Address: string = "";
  Parent1_Email: string = "";
  Registration_AppNo: number = 0;
  PaymentPlan: string = "";
  REGISTRATION_SERVICE: string = "";
  PARENT2NAME: string = "";
  PARENT1_Email: string = "";
  ExportService_DateTime: string = "";
  REGISTRATION_PAYMENTPLAN: string = "";
  Last_Payment_Date: string = "";
  tyo_Year_Attending: string = "";
  fyo_Year_Attending: string = "";
  PARENT2_NAME: string = "";
  Preferred_Contact_Method: string = "";
  REgistration_REferenceNo: number = 0;
  CardExpiryDueStatus: string = "";
  CBC_CentreName: string = "";
  TYO_YearAttending: string = "";
  TYO_CentreName: string = "";
  TYOSecYear_RegistrationStatus: string = "";
  TYOSecYear_YearAttending: string = "";
  TYOSecYear_CentreName: string = "";
  FYO_RegistrationStatus: string = "";
  FYO_YearAttending: string = "";
  FYO_CentreName: string = "";
  FYOSecYear_RegistrationStatus: string = "";
  FYOSecYear_YearAttending: string = "";
  FYOSecYear_CentreName: string = "";
  Registration_CardBelongs: string = "";
  Registration_CardType: string = "";
  Registration_CardNumber: string = "";
  Registration_CardExpiryDate: string = "";
  Registration_CardExpirtyYear: string = "";
  Registration_CardExpiryMonth: string = "";
  Parent1_PreferredMethod: string = "";
  Parent1_PostalAddress: string = "";
  Parent2_PreferredMethod: string = "";
  Parent2_Email: string = "";
  Parent2_PostalAddress: string = "";
  AllocationYear: string = "";
  AllocationDate: string = "";
  Officer: string = "";
  ModeString: string = "";
  RoundNumber: number = 0;
  Service_Name: string = "";
  Method_Name: string = "";
  PlaceHolderGrp_Included: string = "";
  DisplayName: string = "";
  BatchInformation: string = "";
  DocumentInformation: string = "";
  ContentType: string = "";
  BatchNo: string = "";
  SubBatchNo: number = 0;
  Printed: boolean = false;
  Child_FirstName: string = "";
  Child_LastName: string = "";
  RecordedDate: string = "";
  ActionType: string = "";
  ActionOfficer: string = "";
  ActionDescription: string = "";
  DateTarget: string = "";
  Status_Name: string = "";
  RecordedBy: string = "";
  Comment: string = "";
  Title: string = "";
  FileName: string = "";
  ParentGuardian1_FirstName: string = "";
  ParentGuardian1_LastName: string = "";
  FeeTypeDescription: string = "";
  CostCode: string = "";
  QuestionNumber: number = 0;
  Cat_Order: number = 0;
  CategoryName: string = "";
  SortOrder: number = 0;
  Question: string = "";
  Weight_CBC: number = 0;
  Weight_HBC: number = 0;
  Weight_3yo: number = 0;
  Weight_4yo: number = 0;
  Document_Usage_Name: string = "";
  Dataset_Name: string = "";
  SaveFormat: string = "";
  WhenLoaded: string = "";
  IsActive: string = "";
  AdditionalInformation: string = "";
  WeightingPriority_Name: string = "";
  WeightingPriority_Score: string = "";
  //Form
  ProofofBirthAttached: string = "";
  CulturalBackground: string = "";
  FileNumber: number = 0;
  CountryofBirth: string = "";

  Parent1CountryofOrigin: string = "";
  Parent1InterpreterRequired: string = "";
  Parent1_Registration_CardType: string = "";
  Parent1ProofofGuardianship: string = "";

  Parent2CountryofOrigin: string = "";
  Parent2InterpreterRequired: string = "";
  Parent2_Registration_CardType: string = "";
  Parent2ProofofGuardianship: string = "";
  Parent2_ContactNo: number = 0;
  PARENT2_NAMENUMBER: number = 0;
  Parent2Phone: string = "";
  Parent2HomePhone: string = "";
  ParentGuardian2_PreferredMethod: string = "";
  Parent2Address: string = "";

  EC1: boolean = true;
  EC1Contactable: boolean = false;
  EC1Name: string = "";
  EC1RelationtoChild: string = "";
  EC1ContactDetails: string = "";
  EC1CouncilNotes: string = "";
  EC2: boolean = false;
  EC2Contactable: boolean = false;
  EC2Name: string = "";
  EC2RelationtoChild: string = "";
  EC2ContactDetails: string = "";
  EC2CouncilNotes: string = "";

  IsAboriginalTSI: boolean = true;
  IsInterpreter: boolean = false;
  IsHealthCareCard: boolean = false;
  IsChildCurrentlyEnrolledinMunicipality: boolean = false;
  IsResident: boolean = false;
  IsSingleParent: boolean = false;
  IsRespiteforParent: boolean = false;
  IsDisabilityinfamily: boolean = false;
  IsSpecialNeedsIndicated: boolean = false;
  IsSpecialNeedAssessed: boolean = false;
  IsSiblingscurrentlyenrolledinMunicipality: boolean = false;
  IsAtRiskIndicated: boolean = false;
  IsAtRiskAssessed: boolean = false;
  IsAssessedasSociallyIsolated: boolean = false;
  IsHighSupportNeedsIndicated: boolean = true;
  IsHighSupportNeedsAssessed: boolean = false;
  IsReferralAgency: boolean = false;
  IsCaredByFamilyMember: boolean = false;
  IsMultipleBirth: boolean = false;
  IsMaternalChildHealthCheck: boolean = true;
  IsEnrolmentPeriod: boolean = false;
  IsParentworkingstudying: boolean = false;
  IsWorkingStudyinginmunicipality: boolean = false;
  IsParentseekingwork: boolean = false;
  IsSkipPOAValidation: boolean = false;
  FRegisteredCBC: number = 0;
  FRegisteredHBC: number = 0;
  FRegistered3yo: number = 0;
  FRegistered4yo: number = 0;

  AllowCentreAccess: boolean = false;
  ExternalRef: string = "";
}
export class CRSRequest extends CRSResponse {}

export class CRSFormData extends CRSResponse {}

export class CRSRecordsState {
  actions: number = 0;
  comments: number = 0;
  contacts: number = 0;
  documents: number = 0;
  fees: number = 0;
  siblings: number = 0;
  centrenotes: number = 0;
  familynotes: number = 0;
}
