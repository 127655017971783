import { ResponseMessage } from "@app/products/property/model";
import { DTO_DeleteSupplementary } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/supplementary-rates/delete-suplementary/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const putDeleteSupplementary = async (
  supplementaryId: number | string,
  payload?: DTO_DeleteSupplementary
): Promise<APIResponse<ResponseMessage | any>> => {
  try {
    return await CoreAPIService.getClient().put(
      `api/property/internal/supplementary/${supplementaryId}`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
