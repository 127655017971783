import { CCComboboxWithNewOption } from "@components/cc-combobox-with-new-option/_index";
import { searchCategoryConfig } from "@components/cc-combox-search-lov/config";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
} from "@progress/kendo-react-dropdowns";
import { isNil } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
interface ICCComboBoxProps extends ComboBoxProps {
  name: string;
  onChange: (values: any) => void;
  dataLov: any[];
  onChangeNewButton?: (searchKey?: string) => void;
  isShowNewFooter?: boolean;
  valueField: string | number;
  isUseDefaultOnchange?: boolean;
}
export const CCComboxSearchLov = (props: ICCComboBoxProps) => {
  const {
    name,
    onChange,
    dataLov,
    dataItemKey,
    textField,
    onChangeNewButton,
    isShowNewFooter = false,
    valueField,
    isUseDefaultOnchange,
    ...others
  } = props;

  const [data, setData] = useState<any[]>([...dataLov]);
  const [searchKey, setSearchKey] = useState("");

  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    if (event.filter?.value?.length === 0) setData(dataLov);
    setSearchKey(event.filter?.value ?? "");
    if (event.filter?.value?.length < searchCategoryConfig.minCharacters)
      return;

    const filterData = dataLov?.filter((item) =>
      item?.[textField ?? ""]
        .toLocaleLowerCase()
        .includes(event.filter.value.toLocaleLowerCase())
    );

    setData(() => {
      return filterData.length > 0 ? filterData : [];
    });
  };

  const handleOnChange = useCallback(
    (event: ComboBoxChangeEvent) => {
      if (!onChange) return;
      if (isUseDefaultOnchange && dataItemKey)
        return onChange({
          ...event,
          value: event.value[dataItemKey],
        });
      onChange(event?.target?.value);
    },
    [isUseDefaultOnchange, dataItemKey, onChange]
  );

  const comboBoxValue = useMemo(() => {
    if (!isNil(valueField) && dataItemKey)
      return (
        data?.find((obj: any) => obj?.[dataItemKey] === valueField) ?? null
      );
    return valueField;
  }, [valueField, dataItemKey, data]);

  useEffect(() => {
    setData(dataLov);
  }, [dataLov]);

  return (
    <div className="cc-field cc-combo-box-with-new">
      <CCComboboxWithNewOption
        {...others}
        name={name}
        dataItemKey={dataItemKey}
        textField={textField}
        filterable
        suggest
        onFilterChange={handleSearch}
        data={data}
        onChange={handleOnChange}
        value={comboBoxValue}
        isShowNewFooter={isShowNewFooter}
        searchKey={searchKey}
        onChangeNewButton={onChangeNewButton}
      />
    </div>
  );
};
