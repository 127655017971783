import { eventEmitter } from "@/App";
import { EventSubscription } from "fbemitter";
import { useRef } from "react";
import { useDeepCompareEffect } from "react-use";

export interface IAddUniqueEventEmitProps {
  eventType: string;
  listener: Function;
}

export interface IEventSubscription extends EventSubscription {
  eventType: string;
  key: number;
}

export const useAddUniqueEventEmitter = (
  eventList: IAddUniqueEventEmitProps[]
) => {
  const eventEmitterList = useRef<IEventSubscription[]>([]);

  useDeepCompareEffect(() => {
    eventList.forEach((event: IAddUniqueEventEmitProps) => {
      const removeEventIndex = eventEmitterList.current.findIndex(
        (eventEmitter) => eventEmitter?.eventType
      );
      if (removeEventIndex !== -1) {
        eventEmitterList.current[removeEventIndex].remove();
      }
      eventEmitterList.current.push(
        eventEmitter.addListener(
          event.eventType,
          event.listener
        ) as IEventSubscription
      );
    });
    return () => {
      eventEmitterList.current.forEach((event: IEventSubscription) => {
        event.remove();
      });
    };
  }, [eventList]);
};
