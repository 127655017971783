import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Svc_TrimContainer } from "@common/models/corporateSetting";
import { IdentityPacket } from "@common/models/identityPacket";

export const getRecordTrimContainer = async (
  searchText: string,
  appendNoneOption?: boolean,
  electronicOnly?: boolean
): Promise<
  APIResponse<IdentityPacket | Svc_TrimContainer[] | any | undefined>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/core/internal/settings/records/trim/get-container?appendNoneOption=${appendNoneOption}&electronicOnly=${electronicOnly}`,
      JSON.stringify(searchText)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
