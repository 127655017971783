import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { TextAreaGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/text-area/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

const SiteSelection = () => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const data = useDataSetting(configData, [
    "CorporateSettings_Assets_Custom_SiteSelectionQuery",
    "CorporateSettings_Assets_Custom_SiteReturnQuery",
  ]);

  return (
    <section className="cc-field-group">
      <TextAreaGlobalSettings
        data={data.CorporateSettings_Assets_Custom_SiteSelectionQuery}
        isEditing={isEditing}
        rows={10}
      />
      <TextAreaGlobalSettings
        data={data.CorporateSettings_Assets_Custom_SiteReturnQuery}
        isEditing={isEditing}
        rows={10}
      />
    </section>
  );
};

export default observer(SiteSelection);
