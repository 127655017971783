import React, { Fragment, useState, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import Window from "./Window";
import { ItemTypes } from "./itemTypes";
import { KanbanItem, StatusDef } from "./models";

interface IProps {
  item: KanbanItem;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  statusDef: StatusDef;
}

//const Item = ({ item, index, moveItem, status }) => {
const Item: React.FC<IProps> = ({ item, index, moveItem, statusDef }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: ItemTypes.Card,
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      if (null !== ref.current) {
        const hoveredRect = ref.current.getBoundingClientRect();
        const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
        const mousePosition = monitor.getClientOffset();
        if (null !== mousePosition) {
          const hoverClientY = mousePosition.y - hoveredRect.top;

          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
          }

          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
          }
          moveItem(dragIndex, hoverIndex);
          item.index = hoverIndex;
        }
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.Card, ...item, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [show, setShow] = useState(false);
  const onOpen = () => setShow(true);
  const onClose = () => setShow(false); 

  drag(drop(ref));

  return (
    <Fragment>
      <div
        ref={ref}
        style={{ opacity: isDragging ? 0 : 1 }}
        className={"card-item"}
        onClick={onOpen}
      >
        <div
          className={"color-bar"}
          style={{ backgroundColor: statusDef.color }}
        />
        <p className={"card-item-title"}>{item.content}</p>
        <p className={"card-item-status"}>{item.icon}</p>
      </div>
      <Window item={item} onClose={onClose} show={show} />

    </Fragment>
  );
};

export default Item;
