export const mockFirePreventionActivities = [
  {
    TypeId: 42,
    ActivityType: "Notice Reference Change",
    ActivityDateTime: "7-Jan-2022 22:15",
    NoticeId: 210003,
    Comments:
      "Change of Reference for Notice Reference : test - added to Notice 210003 ’",
    UserId: "rhosking",
    ActivityId: 210004,
  },
];
