import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";

export const groupFieldSettingGlobal = (objectArray: any[], property: any) => {
  return objectArray.reduce((acc: any, obj: any) => {
    let key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

export const getLinkNavigate = (enumProduct: string): string => {
  switch (enumProduct) {
    case `${PRODUCT_TYPE_NUMBER.TownPlanning}`:
      return "/town-planning/ppr/system-admin/settings";
    case `${PRODUCT_TYPE_NUMBER.CRS}`:
      return "/crs/system-admin/settings";
    case `${PRODUCT_TYPE_NUMBER.WasteWater}`:
      return "/waste-water/system-admin/settings";
    case `${PRODUCT_TYPE_NUMBER.Building}`:
      return "/building/system-admin/settings";
    case `${PRODUCT_TYPE_NUMBER.Animals}`:
      return "/animals/system-admin/settings";
    case `${PRODUCT_TYPE_NUMBER.LLPermits}`:
      return "/local-laws/system-admin/settings";
    case `${PRODUCT_TYPE_NUMBER.CommunityProperty}`:
      return "/property/system-admin/settings/assessment";
    case `${PRODUCT_TYPE_NUMBER.DISRES}`:
      return "/parking-permits/system-admin/settings";
    case `${PRODUCT_TYPE_NUMBER.HealthManager}`:
      return "/hm/system-admin/settings";
    case `${PRODUCT_TYPE_NUMBER.CRMS_HardRubbish}`: // Wrong ENUM
      return "/crms/system-admin/settings";
    default:
      return "";
  }
};
