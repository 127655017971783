import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" }); // Strict mode: runInAction

class SettingNewButtonStore {
  private _isNew = false;
  private _urlNewForm: string = "";
  private _isExistAfterCreated: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isNew(): boolean {
    return toJS(this._isNew);
  }
  setIsNew = (isNew: boolean) => {
    runInAction(() => {
      this._isNew = isNew;
    });
  };

  get urlNewForm(): string {
    return toJS(this._urlNewForm);
  }
  setUrlNewForm = (url: string) => {
    runInAction(() => {
      this._urlNewForm = url;
    });
  };

  get isExistAfterCreated(): boolean {
    return toJS(this._isExistAfterCreated);
  }
  setIsExistAfterCreated = (isExist: boolean) => {
    this._isExistAfterCreated = isExist;
  };
}

export const settingNewButtonStore = new SettingNewButtonStore();
const SettingNewButtonStoreContext = createContext(new SettingNewButtonStore());
export const useSettingNewButtonStore = () => {
  return useContext(SettingNewButtonStoreContext);
};
