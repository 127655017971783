import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { VO_Charge_Run_PIC } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/components/child-screens/pic/model";

const nameOfPIC = nameOfFactory<VO_Charge_Run_PIC>();
export const colChargeRunPIC: IColumnFields[] = [
  {
    field: nameOfPIC("PIC_Number"),
    title: "PIC Number",
  },
  {
    field: nameOfPIC("PropertyAddress"),
    title: "Address",
  },
  {
    field: nameOfPIC("PrimaryHoldingOccupier"),
    title: "Primary Holding Occupier",
  },
  {
    field: nameOfPIC("Charge_Run_Fee"),
    title: "Total Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfPIC("PIC_Id"),
    title: "PIC ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
