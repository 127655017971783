import { NewDebtRecoveryCommonButton } from "@app/products/property/components/action-bar/button/new-debt-recovery/common/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export const NewDebtRecoveryButton = observer(() => {
  const { gridSelectedRows, isEmptyData, lastSelectedId } =
    useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();

  const isDisabled = useMemo(() => {
    return (
      (!isEmptyData && gridSelectedRows.length !== 1 && !id) ||
      (!id && isEmptyData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridSelectedRows.length, id, isEmptyData]);

  const recordID = useMemo(() => {
    return lastSelectedId ?? id;
  }, [id, lastSelectedId]);
  return <NewDebtRecoveryCommonButton isDisabled={isDisabled} id={recordID} />;
});
