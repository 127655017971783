import { ElementDisplayStatus } from "@app/products/crms/[id]/model";

export interface KeyValuePacket {
  Key: number;
  Value: string;
}

export interface KeyValuePacketWithStatus extends KeyValuePacket {
  DisplayStatus: ElementDisplayStatus;
  Checked: boolean;
}

export enum CS_RequestSection {
  Default = 0,
  SameCategory = 1,
  SameDescription = 2,
  SameLocation = 3,
  SameOnBehalfOf = 4,
  SamePerson = 5,
  AllocateCoordinator = 6,
  AllocateActionOfficer = 7,
}

export interface NewEventCloneParams {
  id?: number;
  isClone: boolean;
  detailsToCopy: CS_RequestSection[];
  sameIssue: boolean;
}
