import { useNoticesStore } from "@app/core/notices/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { generateParentValueField } from "@common/utils/common";
import { CCValueField } from "@components/cc-value-field/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

interface INoticeParentSectionProps {
  recordType: RECORDTYPE | undefined;
}

export const NoticeParentSection = observer(
  ({ recordType }: INoticeParentSectionProps) => {
    const { ancestor } = useNoticesStore();
    if (!ancestor || isNil(ancestor?.lblParent1) || ancestor?.lblParent1 === "")
      return null;
    return (
      <>
        <section className="cc-field-group">
          {!isNil(ancestor?.lblParentHeader) &&
          ancestor?.lblParentHeader !== "" ? (
            <div className="cc-form-cols-1">
              <CCValueField value={ancestor.lblParentHeader} />
            </div>
          ) : null}

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCValueField
                label={ancestor.lblParent1}
                value={generateParentValueField(
                  ancestor.litParent1,
                  recordType
                )}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label={ancestor.lblParent2}
                value={generateParentValueField(
                  ancestor.litParent2,
                  recordType
                )}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label={ancestor.lblParent3}
                value={generateParentValueField(
                  ancestor.litParent3,
                  recordType
                )}
              />
            </div>
            {ancestor?.lblParent4 ? (
              <div className="cc-field">
                <CCValueField
                  label={ancestor.lblParent4}
                  value={generateParentValueField(
                    ancestor.litParent4,
                    recordType
                  )}
                />
              </div>
            ) : null}
          </div>
        </section>
        <hr className="cc-divider" />
      </>
    );
  }
);
