import {
  DTO_Workflow_JournalAssessmentAdjustChargeBalances,
  RequestJournalAssessmentAdjustChargeBalancesObj,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/model";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IGetInitialDataAdjustChargeBalances =
  | [
      APIResponse<DTO_Journal_LOVs>,
      APIResponse<DTO_Workflow_JournalAssessmentAdjustChargeBalances>
    ]
  | APIResponse<
      DTO_Journal_LOVs | DTO_Workflow_JournalAssessmentAdjustChargeBalances
    >
  | undefined;

export const getInitialDataAdjustChargeBalances = async (
  id: number,
  isFromActionList: boolean = false,
  chargeData: number[],
  requestObjSpatial:
    | RequestJournalAssessmentAdjustChargeBalancesObj
    | object = {
    AssessmentId: id,
    Charge_Ids: chargeData,
  }
): Promise<IGetInitialDataAdjustChargeBalances> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_LOVs>(
        `/api/property/internal/journal/lovs`
      ),
      isFromActionList
        ? getWorkflowAdjustChargeBalances(id)
        : getNewWorkflowAdjustChargeBalances(requestObjSpatial),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowAdjustChargeBalances = async (
  requestObjSpatial:
    | RequestJournalAssessmentAdjustChargeBalancesObj
    | object = {}
): Promise<APIResponse<DTO_Workflow_JournalAssessmentAdjustChargeBalances>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/assessmentadjustchargebalances/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowAdjustChargeBalances = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_JournalAssessmentAdjustChargeBalances>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/assessmentadjustchargebalances/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessWorkflowAdjustChargeBalances = async (
  modeProcess: WorkflowProcessMode,
  dataAdjustChargeBalances: DTO_Workflow_JournalAssessmentAdjustChargeBalances
): Promise<
  APIResponse<
    ResponseMessageWorkflow<DTO_Workflow_JournalAssessmentAdjustChargeBalances>
  >
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/assessmentadjustchargebalances/process/${modeProcess}`,
      dataAdjustChargeBalances
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
