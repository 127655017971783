import { colPickEvent } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/pick-rescheduled-reason/config";
import { CurrentLookupValues } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/pick-rescheduled-reason/model";
import { nameOfFactory } from "@common/utils/common";
import { GridSelectionDialog } from "@components/dialog/dialog-grid-selection/_index";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

interface ICPickRescheduledReasonProps {
  onSubmit?: (data: any) => void;
  onClose?: (isShowDialog: boolean) => void;
  setShowDialog?: (isShowDialog: boolean) => void;
  dataUrl: string;
  isMultiple?: boolean;
  selectedRows?: any[];
}
const nameOf = nameOfFactory<CurrentLookupValues>();
export const CCPickRescheduledReason = ({
  onSubmit = () => {},
  onClose = () => {},
  setShowDialog = () => {},
  isMultiple = true,
  dataUrl,
  selectedRows,
}: ICPickRescheduledReasonProps) => {
  return (
    <Form
      render={(formRenderProps: FormRenderProps) => {
        return (
          <GridSelectionDialog
            maxWidth="55%"
            titleHeader={"Pick Event Reschedule Standard Reason"}
            selectableMode={isMultiple ? "multiple" : "single"}
            dataUrl={dataUrl}
            columnFields={colPickEvent}
            primaryField={nameOf("ID")}
            {...formRenderProps}
            setShowDialog={setShowDialog}
            onCloseDialog={() => onClose(false)}
            onSubmit={(data: CurrentLookupValues[]) => {
              onSubmit(data[0]);
            }}
            selectedRows={selectedRows}
          />
        );
      }}
    />
  );
};
