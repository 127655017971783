import { isSuccessResponse } from "@common/apis/util";
import { includes, isArray } from "lodash";

export const errorPromiseAllResponse = (apiResult: any[]) => {
  return apiResult.find((v) => !isSuccessResponse(v));
};

export const isSkipSlides = (
  currentSlide: number,
  skipSlide: number | number[]
) => {
  return isArray(skipSlide)
    ? includes(skipSlide, currentSlide)
    : currentSlide === skipSlide;
};
