import { IReference } from "@app/products/property/fire-prevention/components/dialogs/reference/model";
import { ReferenceDialog } from "@app/products/property/fire-prevention/components/dialogs/reference/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";
interface IReferenceButton {
  noticeGridSelectData: IReference[] | undefined;
}
export const ReferenceButton = ({ noticeGridSelectData }: IReferenceButton) => {
  const [showReferenceDialog, setShowReferenceDialog] = useState(false);
  const handleConfirmReference = (data: IReference) => {
    setShowReferenceDialog(false);
  };
  const { pushNotification } = useCCAppNotificationStore();
  const handleOnClickReference = () => {
    if (noticeGridSelectData && noticeGridSelectData[0]?.Reference) {
      pushNotification({
        title: "This notice already had the reference",
        type: "warning",
      });
      return;
    }
    setShowReferenceDialog(true);
  };
  const isDisableReferenceButton = () => {
    if (!noticeGridSelectData) return true;
    return noticeGridSelectData?.length !== 1;
  };
  return (
    <React.Fragment>
      <CCNavButton
        title="Notice reference"
        disabled={isDisableReferenceButton()}
        onClick={handleOnClickReference}
      />
      {showReferenceDialog && (
        <ReferenceDialog
          onCloseDialog={() => setShowReferenceDialog(false)}
          onConfirmReference={(data: IReference) =>
            handleConfirmReference(data)
          }
          noticeGridSelectData={noticeGridSelectData}
        />
      )}
    </React.Fragment>
  );
};
