import { DBRowState } from "@app/products/crms/[id]/model";
import {
  getPermitTypeById,
  getPermitTypeLOVs,
} from "@app/products/local-laws/system-admin/permit-types/[id]/api";
import { TitlePermitTypeManagePage } from "@app/products/local-laws/system-admin/permit-types/[id]/constant";
import {
  PermitType,
  PermitTypeLOVs,
  PermitTypeUiControl,
} from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { IIdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { routeDataStoreInstance } from "@common/stores/route/store";
import { mapEnum } from "@common/utils/common";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistManagePermitType } from "./existed/_index";
import { NewManagePermitType } from "./new/_index";

const ManagePermitType = () => {
  //#region STORE ========/
  const history = useHistory();
  const { setInitialDataForms, isLoadingForm } = useFlexibleFormStore();
  //#endregion STORE =====/

  //#region HOOK ========/
  const { cancelToken } = useCancelRequest();
  const { pushNotificationPortal, clearNotifications } =
    useNotificationPortalStore();
  const isNew = useIsNew();
  //#endregion HOOK =====/

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  const permitTypeId = isNew ? "0" : id;
  //#endregion Variable =====/

  useEffectOnce(() => {
    return () => {
      clearNotifications();
    };
  });

  const managePermitTypeSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: () => {
          const _cancelToken = cancelToken();
          return [
            getPermitTypeById(+permitTypeId, _cancelToken),
            getPermitTypeLOVs(_cancelToken),
          ];
        },
        handleSuccess: ({ dataFromApi }) => {
          const response = dataFromApi as [
            APIResponse<IIdentityPacket<PermitType>>,
            APIResponse<PermitTypeLOVs>
          ];
          const permitType = response?.[0]?.data?.ReturnObj;
          const permitTypeLOVs = response?.[1]?.data;
          let isDeleted = false;
          if (
            mapEnum(permitType.Sys_DBRowState, DBRowState) ===
              DBRowState.Inactive &&
            +permitTypeId !== 0
          ) {
            isDeleted = true;
            pushNotificationPortal({
              title: "Important Note",
              type: "info",
              description:
                "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
              autoClose: false,
            });
          }
          setInitialDataForms({
            GeneralForm: permitType,
            GeneralFormLovs: permitTypeLOVs,
            GeneralUIControl: { isDeleted } as PermitTypeUiControl,
          });
        },
      },
    ],
  });

  useEffect(() => {
    managePermitTypeSlider.fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  return (
    <>
      <Loading
        isLoading={
          managePermitTypeSlider.isFetching ||
          routeDataStoreInstance.isLoadingPage ||
          isLoadingForm
        }
        isFullScreen
      />
      <FormNavigation title={TitlePermitTypeManagePage} />
      {managePermitTypeSlider.errorComponent ? (
        managePermitTypeSlider.errorComponent
      ) : isNew ? (
        <NewManagePermitType />
      ) : (
        <ExistManagePermitType />
      )}
    </>
  );
};

export default observer(ManagePermitType);
