import { history } from "@/AppRoutes";
import {
  getCRMSServiceStandardSummaryById,
  getServiceStandardLovs,
  getSetupPickServiceStandard,
  postCRMSServiceStandard,
  postSetupPickActionOfficer,
  serviceStandardHandler,
} from "@app/products/crms/service-standards/[id]/api";
import { CRMS_SERVICE_STANDARDS_ROUTE } from "@app/products/crms/service-standards/[id]/constant";
import {
  NewServiceStandardCloneParams,
  NewServiceStandardFromSubCategoryParams,
  ServiceStandard,
  ServiceStandardHandlerRequest,
  ServiceStandardLOVs,
  ServiceStandardPickListPacket,
  ServiceStandardUIControl,
  Svc_FormAction,
} from "@app/products/crms/service-standards/[id]/model";
import { generateContactManagerDataURL } from "@app/products/crms/util";
import { IPSARApplicationParentSection } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class CRMSServiceStandardStore {
  private _crmsServiceStandard?: ServiceStandard = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _serviceStandardLovs?: ServiceStandardLOVs = undefined;
  private _isFormModified: boolean = false;
  private _notification?: IAppNotificationItemAddProps = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _uiControl?: ServiceStandardUIControl = undefined;
  private _contactManagerDataURL?: string = undefined;
  private _isExpandedTargetDays?: boolean = false;
  private _isLoadingDetails?: boolean = false;

  private _isExpandedActionAccordion?: boolean = false;
  private _isExpandedSpecialInterestAccordion?: boolean = false;
  private _isExpandedSecurityAccordion?: boolean = false;
  private _serviceStandardDataFilter?: ServiceStandardPickListPacket =
    undefined;
  private _isExpandInternetInformationDetails?: boolean = false;
  private _parentSection?: IPSARApplicationParentSection = undefined;
  private _accessRight?: ACCESSRIGHTS = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get crmsServiceStandard() {
    return this._crmsServiceStandard;
  }
  setCRMSServiceStandard = (
    crmsServiceStandard: ServiceStandard | undefined
  ) => {
    runInAction(() => {
      this._crmsServiceStandard = crmsServiceStandard;
    });
  };

  get isExpandedActionAccordion() {
    return this._isExpandedActionAccordion;
  }
  setIsExpandedActionAccordion = (isExpandedActionAccordion: boolean) => {
    runInAction(() => {
      this._isExpandedActionAccordion = isExpandedActionAccordion;
    });
  };

  get isExpandedSpecialInterestAccordion() {
    return this._isExpandedSpecialInterestAccordion;
  }
  setIsExpandedSpecialInterestAccordion = (
    isExpandedSpecialInterestAccordion: boolean
  ) => {
    runInAction(() => {
      this._isExpandedSpecialInterestAccordion =
        isExpandedSpecialInterestAccordion;
    });
  };

  get isExpandedSecurityAccordion() {
    return this._isExpandedSecurityAccordion;
  }
  setIsExpandedSecurityAccordion = (isExpandedSecurityAccordion: boolean) => {
    runInAction(() => {
      this._isExpandedSecurityAccordion = isExpandedSecurityAccordion;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingDetails() {
    return this._isLoadingDetails;
  }
  setIsLoadingDetails = (isLoadingDetails: boolean) => {
    runInAction(() => {
      this._isLoadingDetails = isLoadingDetails;
    });
  };

  get crmsServiceStandardId() {
    return this._crmsServiceStandard?.ServiceStandard_Id;
  }

  get serviceStandardLovs() {
    return toJS(this._serviceStandardLovs);
  }
  setServiceStandardLovs = (serviceStandardLovs?: ServiceStandardLOVs) => {
    runInAction(() => {
      this._serviceStandardLovs = serviceStandardLovs;
    });
  };

  get contactManagerDataURL() {
    return this._contactManagerDataURL;
  }
  setContactManagerDataURL = (contactManagerDataURL?: string) => {
    runInAction(() => {
      this._contactManagerDataURL = contactManagerDataURL;
    });
  };

  get isExpandedTargetDays() {
    return this._isExpandedTargetDays;
  }
  setIsExpandedTargetDays = (isExpandedTargetDays: boolean) => {
    runInAction(() => {
      this._isExpandedTargetDays = isExpandedTargetDays;
    });
  };

  get serviceStandardDataFilter() {
    return this._serviceStandardDataFilter;
  }
  setServiceStandardDataFilter = (
    serviceStandardDataFilter?: ServiceStandardPickListPacket
  ) => {
    runInAction(() => {
      this._serviceStandardDataFilter = serviceStandardDataFilter;
    });
  };

  get isExpandInternetInformationDetails() {
    return this._isExpandInternetInformationDetails;
  }
  setIsExpandInternetInformationDetails = (
    isExpandInternetInformationDetails?: boolean
  ) => {
    runInAction(() => {
      this._isExpandInternetInformationDetails =
        isExpandInternetInformationDetails;
    });
  };

  get isFormModified() {
    return this._isFormModified;
  }
  setIsFormModified = (isFormModified: boolean) => {
    runInAction(() => {
      this._isFormModified = isFormModified;
    });
  };

  reLoadServiceStandard = async (isNew: boolean) => {
    if (this.crmsServiceStandardId) {
      await this.loadCRMSServiceStandard(this.crmsServiceStandardId, isNew);
    }
  };

  get notification() {
    return this._notification;
  }
  setNotification = (
    notification: IAppNotificationItemAddProps | undefined
  ) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get uiControl() {
    return this._uiControl;
  }
  setUIControl = (uiControl?: ServiceStandardUIControl) => {
    runInAction(() => {
      this._uiControl = uiControl;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: IPSARApplicationParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get accessRight() {
    return this._accessRight;
  }
  setAccessRight = (accessRight: ACCESSRIGHTS) => {
    runInAction(() => {
      this._accessRight = accessRight;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._crmsServiceStandard = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._serviceStandardLovs = undefined;
      this._onSubmit = undefined;
      this._uiControl = undefined;
      this._contactManagerDataURL = undefined;
      this._isExpandedActionAccordion = false;
      this._isExpandedSpecialInterestAccordion = false;
      this._isExpandedSecurityAccordion = false;
      this._isExpandedTargetDays = false;
      this._isLoadingDetails = false;
      this._notification = undefined;
      this._parentSection = undefined;
      this._accessRight = undefined;
    });
  };

  loadCRMSServiceStandard = async (
    crmsServiceStandardId: number,
    isNew: boolean = false,
    serviceStandardCloneParams?: NewServiceStandardCloneParams,
    dataFromNewSubCategory?: NewServiceStandardFromSubCategoryParams
  ) => {
    this.setIsLoading(true);
    this.setIsLoadingDetails(true);
    let errorResponse = undefined;
    let serviceStandard = undefined;
    let uiControl = undefined;
    let serviceStandardLovs = undefined;
    let contactManagerDataURL = undefined;
    let serviceStandardDataFilter = undefined;
    let accessRight = undefined;

    if (!isNew) {
      const [response, responseLovs] = await Promise.all([
        getCRMSServiceStandardSummaryById(crmsServiceStandardId),
        getServiceStandardLovs(),
      ]);

      if (
        isSuccessResponse(response) &&
        response.data?.ReturnObj &&
        isSuccessResponse(responseLovs) &&
        responseLovs?.data
      ) {
        serviceStandardLovs = responseLovs.data;
        // Call event handler at the first time
        const params: ServiceStandardHandlerRequest = {
          ServiceStandardFormAction: Svc_FormAction.SystemInitialise,
          ServiceStandard: response.data?.ReturnObj,
          EventArgs: {},
          IsFirstTimeLoad: true,
        };

        const [
          responseServiceStandardHandler,
          responseSetupPickActionOfficer,
          responseSetupPickServiceStandard,
        ] = await Promise.all([
          serviceStandardHandler(params),
          postSetupPickActionOfficer(response.data?.ReturnObj),
          getSetupPickServiceStandard(),
        ]);
        if (
          isSuccessResponse(responseSetupPickActionOfficer) &&
          isSuccessResponse(responseServiceStandardHandler) &&
          isSuccessResponse(responseSetupPickServiceStandard) &&
          responseServiceStandardHandler.data?.ReturnObj &&
          responseSetupPickActionOfficer?.data &&
          responseSetupPickServiceStandard?.data
        ) {
          serviceStandard =
            responseServiceStandardHandler.data.ReturnObj.ServiceStandard;
          uiControl = responseServiceStandardHandler.data.ReturnObj.UIControl;
          contactManagerDataURL = generateContactManagerDataURL(
            responseSetupPickActionOfficer?.data._OrgStructureFilter,
            true
          );
          serviceStandardDataFilter = responseSetupPickServiceStandard?.data;
          accessRight =
            responseServiceStandardHandler.data.ReturnObj.UIControl
              ?.AccessRights?.Value;
        } else {
          errorResponse = {
            status: responseServiceStandardHandler.status,
            error:
              responseServiceStandardHandler.data?.Errors ??
              responseServiceStandardHandler.error,
          };
        }
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }

      // Push notification
      if (this.notification) {
        appNotificationStore.pushNotification(this.notification);
        this.setNotification(undefined);
      }
      if (this.parentSection?.notification) {
        this.parentSection?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setParentSection({ ...this._parentSection, notification: [] });
      }
    } else {
      const [response, responseLovs] = await Promise.all(
        serviceStandardCloneParams && serviceStandardCloneParams.id
          ? [
              getCRMSServiceStandardSummaryById(
                serviceStandardCloneParams.id,
                serviceStandardCloneParams?.isClone
              ),
              getServiceStandardLovs(),
            ]
          : [getCRMSServiceStandardSummaryById(0), getServiceStandardLovs()]
      );

      if (
        isSuccessResponse(response) &&
        response?.data?.ReturnObj &&
        isSuccessResponse(responseLovs) &&
        responseLovs?.data
      ) {
        serviceStandardLovs = responseLovs.data;
        // Call event handler at the first time
        let params: ServiceStandardHandlerRequest = {
          ServiceStandardFormAction: Svc_FormAction.SystemInitialise,
          ServiceStandard: response.data?.ReturnObj,
          EventArgs: {},
          IsFirstTimeLoad: true,
        };
        // Call event handler to change category when Category_Id available
        if (dataFromNewSubCategory?.ServiceStandardCategory_Id) {
          params = {
            ...params,
            ServiceStandardFormAction: Svc_FormAction.ChangeCategory,
            EventArgs: {
              ServiceStandardCategory_Id:
                dataFromNewSubCategory?.ServiceStandardCategory_Id,
            },
          };
        }

        const [
          responseServiceStandardHandler,
          responseSetupPickActionOfficer,
          responseSetupPickServiceStandard,
        ] = await Promise.all([
          serviceStandardHandler(params),
          postSetupPickActionOfficer(response.data?.ReturnObj),
          getSetupPickServiceStandard(),
        ]);
        if (
          isSuccessResponse(responseSetupPickActionOfficer) &&
          isSuccessResponse(responseServiceStandardHandler) &&
          isSuccessResponse(responseSetupPickServiceStandard) &&
          responseServiceStandardHandler.data?.ReturnObj &&
          responseSetupPickActionOfficer?.data &&
          responseSetupPickServiceStandard?.data
        ) {
          serviceStandard =
            responseServiceStandardHandler.data.ReturnObj.ServiceStandard;

          // Call event handler to change subcategory when SubCategory_Id available
          if (dataFromNewSubCategory?.ServiceStandardSubCategory_Id) {
            let subParams: ServiceStandardHandlerRequest = {
              ServiceStandardFormAction: Svc_FormAction.ChangeSubCategory,
              ServiceStandard: serviceStandard,
              EventArgs: {
                ServiceStandardSubCategory_Id:
                  dataFromNewSubCategory?.ServiceStandardSubCategory_Id,
              },
              IsFirstTimeLoad: false,
            };

            const responseChangeSubCategoryHandler =
              await serviceStandardHandler(subParams);
            if (
              isSuccessResponse(responseChangeSubCategoryHandler) &&
              responseChangeSubCategoryHandler.data?.ReturnObj
            ) {
              serviceStandard =
                responseChangeSubCategoryHandler.data.ReturnObj
                  ?.ServiceStandard;
            }
          }

          uiControl = responseServiceStandardHandler.data.ReturnObj.UIControl;
          contactManagerDataURL = generateContactManagerDataURL(
            responseSetupPickActionOfficer?.data._OrgStructureFilter,
            true
          );
          serviceStandardDataFilter = responseSetupPickServiceStandard?.data;
        } else {
          errorResponse = {
            status: responseServiceStandardHandler.status,
            error:
              responseServiceStandardHandler.data?.Errors ??
              responseServiceStandardHandler.error,
          };
        }
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
    }
    this.setUIControl(uiControl);
    this.setCRMSServiceStandard(serviceStandard);
    this.setServiceStandardLovs(serviceStandardLovs);
    this.setContactManagerDataURL(contactManagerDataURL);
    this.setServiceStandardDataFilter(serviceStandardDataFilter);
    this.setResponseLoadError(errorResponse);
    this.setIsLoadingDetails(false);
    this.setAccessRight(accessRight);
    this.setIsLoading(false);
  };

  submitAction = async (
    serviceStandard: ServiceStandard,
    isNew: boolean = false,
    action: ActionSubmitActions
  ) => {
    if (action === ActionSubmitActions.Save) {
      this.setIsExpandedActionAccordion(false);
      this.setIsExpandedSpecialInterestAccordion(false);
      this.setIsExpandedSecurityAccordion(false);

      this.setIsLoading(true);
      const response = await postCRMSServiceStandard(serviceStandard);
      this.setIsLoading(false);
      if (isSuccessResponse(response)) {
        if (this.isFormModified) this.setIsFormModified(false);

        if (isNew) {
          history.replace(
            `${CRMS_SERVICE_STANDARDS_ROUTE}/${response?.data?.ID}`,
            {
              notification: [
                {
                  title: "Record successfully saved.",
                  type: "success",
                },
              ],
            }
          );
        } else {
          this.reLoadServiceStandard(isNew);
          this.setNotification({
            title: "Record successfully saved.",
            type: "success",
          });
        }
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Service standard could not be saved.",
          type: "error",
          description: response.data?.Errors,
        });
      }
    }
  };

  serviceStandardChangeHandler = async (
    params: ServiceStandardHandlerRequest,
    errorMsg: string,
    isOrgStructureChange: boolean = false
  ) => {
    this.setIsLoading(true);
    const response = await serviceStandardHandler(params);

    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setIsFormModified(true);
      this.setCRMSServiceStandard(response.data.ReturnObj?.ServiceStandard);
      this.setUIControl(response.data.ReturnObj.UIControl);

      if (isOrgStructureChange) {
        const responseSetupPickActionOfficer = await postSetupPickActionOfficer(
          response.data?.ReturnObj?.ServiceStandard
        );
        if (
          isSuccessResponse(responseSetupPickActionOfficer) &&
          responseSetupPickActionOfficer?.data
        ) {
          const contactManagerDataURL = generateContactManagerDataURL(
            responseSetupPickActionOfficer?.data._OrgStructureFilter,
            true
          );
          this.setContactManagerDataURL(contactManagerDataURL);
        } else {
          appNotificationStore.pushNotification({
            autoClose: false,
            title: errorMsg,
            type: "error",
            description: responseSetupPickActionOfficer.error,
          });
        }
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: errorMsg,
        type: "error",
        description: response.data?.Errors || response.error,
      });
    }
    this.setIsLoading(false);
  };

  loadCRMSServiceStandardDetails = async (serviceStandardId: number) => {
    this.setResponseLoadError(undefined);
    this.setIsLoadingDetails(true);
    const response = await getCRMSServiceStandardSummaryById(serviceStandardId);
    this.setIsLoadingDetails(false);
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setCRMSServiceStandard(response.data.ReturnObj);
    } else {
      this.setResponseLoadError({
        status: response.status,
        error: response.data?.Errors ?? response.error,
      });
    }
  };
}

const crmsServiceStandardStoreContext = createContext(
  new CRMSServiceStandardStore()
);
export const useCRMSServiceStandardStore = () => {
  return useContext(crmsServiceStandardStoreContext);
};
