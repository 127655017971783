import { mockSchemeDetailTab } from "@app/products/property/schemes/[id]/components/reference-sidebar/detail/mock";
import { sleep } from "@common/utils/common";

export const loadSchemeDetailTabById = async (id: string): Promise<any> => {
  await sleep(1000);
  return {
    ...mockSchemeDetailTab,
    SchemeId: id,
  };
};
