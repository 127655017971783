import { CRMS_SYSTEM_ADMIN_CATEGORY_ROUTE } from "@app/products/crms/system-admin/categories/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewCategoryButton = () => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New category"
      onClick={() => {
        history.push(`${CRMS_SYSTEM_ADMIN_CATEGORY_ROUTE}/new`);
      }}
    />
  );
};
