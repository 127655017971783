import { CRMS_PHONE_MESSAGE_ROUTE } from "@app/products/crms/phone-messages/[id]/constant";
import { MessageHTMLCell } from "@app/products/crms/phone-messages/components/message-html-cell/_index";
import { PhoneMessageView } from "@app/products/crms/phone-messages/open-messages/models";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<PhoneMessageView>();

export const colAllMessages: IColumnFields[] = [
  {
    field: nameOf("RefNo"),
    title: "Reference Number",
    locked: true,
    linkTo: (dataItem) => `${CRMS_PHONE_MESSAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("Message"),
    title: "Description",
    cell: MessageHTMLCell,
  },
  {
    field: nameOf("Contact"),
    title: "Contact",
  },
  {
    field: nameOf("DateSent"),
    title: "Date Sent",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("DateTarget"),
    title: "Date Target",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Sender"),
    title: "Sender",
  },
  {
    field: nameOf("DateForwarded"),
    title: "Date Forwarded",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateCompleted"),
    title: "Date Completed",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Recipient"),
    title: "Recipient",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("ContactType"),
    title: "Contact Type",
  },
  {
    field: nameOf("PreferredMethod"),
    title: "Preferred Contact",
  },
  {
    field: nameOf("WorkPhone"),
    title: "Work Phone",
  },
  {
    field: nameOf("MobileNo"),
    title: "Mobile",
  },
  {
    field: nameOf("HomePhone"),
    title: "Home Phone",
  },
  {
    field: nameOf("Email"),
    title: "Email",
  },
];
