import { mockSingleBuildingPoolsSpas } from "@app/products/building/pools-or-spas/[id]/mock";
import { IBuildingPoolsSpas } from "@app/products/building/pools-or-spas/[id]/model";
import { IBuilding } from "@app/products/building/[id]/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";

export const getBuildingPoolsSpasById = async (
  id?: number
): Promise<APIResponse<IBuildingPoolsSpas | undefined>> => {
  await sleep(1000);
  return {
    data: {
      ...mockSingleBuildingPoolsSpas,
      ID: id,
    } as IBuildingPoolsSpas,
    status: APIResponseStatus.SUCCESS,
    statusText: "Ok",
    headers: {},
    config: {},
  };
};

export const postBuildingPoolsSpas = async (requestBody: IBuilding) => {
  sleep(2000);
};

export const putBuildingPoolsSpas = async (requestBody: IBuilding) => {
  sleep(2000);
};
