import { ASSESSMENT_MASTER_PROPERTIES_ROUTE } from "@app/products/property/assessments/master-properties/[id]/constant";
import { VO_Supplementary_MasterProperty } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Supplementary_MasterProperty>();
export const colSupplementMasterProperty: IColumnFields[] = [
  {
    field: nameOf("Master_Property_Id"),
    title: "Master Property ID",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem: VO_Supplementary_MasterProperty) =>
      `${ASSESSMENT_MASTER_PROPERTIES_ROUTE}/${dataItem.Master_Property_Id}`,
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Assessment_Number_Formatted"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Property_Name"),
    title: "Property Name",
  },
  {
    field: nameOf("Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Locality_Name"),
    title: "Property Locality",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("No_of_Assessments"),
    title: "Number of Assessments",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Valuation_Number_Formatted"),
    title: "Valuation Number",
  },
  {
    field: nameOf("Date_of_Valuation"),
    title: "Date Of Valuation",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Issue_Date"),
    title: "Valuation Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Effective_Date"),
    title: "Valuation Effect Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Comment"),
    title: "Comments",
  },
  {
    field: nameOf("Valuation_1"),
    title: "Valuation_1",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_2"),
    title: "Valuation_2",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_3"),
    title: "Valuation_3",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_4"),
    title: "Valuation_4",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_5"),
    title: "Valuation_5",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_6"),
    title: "Valuation_6",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_7"),
    title: "Valuation_7",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
  {
    field: nameOf("Valuation_8"),
    title: "Valuation_8",
    format: NUMBER_FORMAT.NUMBER,
    filter: "numeric",
  },
];
