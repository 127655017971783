import { colSupplementaryRatesAssessment } from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/config";
import { VO_Supplementary_Assessment } from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/model";
import { DTO_Supplementary_Details } from "@app/products/property/supplementary-rates/[id]/components/child-screens/detail/model";
import { DTO_Supplementary_AssessmentAdjustment_LOVs } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/model";
import { getSupplementaryRatesDetailsTabById } from "@app/products/property/supplementary-rates/[id]/components/reference-sidebar/detail/api";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class SupplementaryRatesStore {
  private _supplementary_assessment?: DTO_Supplementary_Details = undefined;
  private _assessmentsSelected: VO_Supplementary_Assessment[] = [];
  private _isLoading: boolean = false;
  private _gridColumns: IColumnFields[] = colSupplementaryRatesAssessment;
  private _responseLoadError?: APIResponseError = undefined;
  private _assessmentAdjustmentLOVs?: DTO_Supplementary_AssessmentAdjustment_LOVs =
    undefined;
  private _supplementaryRatesId: number = 0;
  private _assessmentId: number = 0;
  private _supplementaryAssessmentId: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get supplementaryAssessments() {
    return this._supplementary_assessment;
  }
  setSupplementaryAssessments = (
    supplementary_assessments?: DTO_Supplementary_Details
  ) => {
    runInAction(() => {
      this._supplementary_assessment = supplementary_assessments;
      this.setSupplementaryRatesId(
        supplementary_assessments?.SupplementaryId ?? 0
      );
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get supplementaryRatesId() {
    return this._supplementaryRatesId;
  }
  setSupplementaryRatesId = (supplementaryRatesId: number) => {
    runInAction(() => {
      this._supplementaryRatesId = supplementaryRatesId;
    });
  };

  get assessmentId() {
    return this._assessmentId;
  }
  setAssessmentId = (assessmentId: number) => {
    runInAction(() => {
      this._assessmentId = assessmentId;
    });
  };

  get supplementaryAssessmentId() {
    return this._supplementaryAssessmentId;
  }
  setSupplementaryAssessmentId = (supplementaryAssessmentId: number) => {
    runInAction(() => {
      this._supplementaryAssessmentId = supplementaryAssessmentId;
    });
  };

  get gridColumns() {
    return this._gridColumns;
  }
  setGridColumns = (gridColumns: IColumnFields[]) => {
    runInAction(() => {
      this._gridColumns = gridColumns;
    });
  };

  get assessmentsSelected() {
    return toJS(this._assessmentsSelected);
  }
  setAssessmentsSelected = (
    assessmentsSelected: VO_Supplementary_Assessment[]
  ) => {
    runInAction(() => {
      this._assessmentsSelected = assessmentsSelected;
      this.setAssessmentId(assessmentsSelected[0]?.Assessment_Id);
      this.setSupplementaryAssessmentId(assessmentsSelected[0]?.ID);
    });
  };

  get assessmentAdjustmentLOVs() {
    return this._assessmentAdjustmentLOVs;
  }
  setAssessmentAdjustmentLOVs = (
    assessmentAdjustmentLOVs?: DTO_Supplementary_AssessmentAdjustment_LOVs
  ) => {
    runInAction(() => {
      this._assessmentAdjustmentLOVs = assessmentAdjustmentLOVs;
    });
  };

  get isEmptyAssessmentSelected() {
    return this._assessmentsSelected.length === 0;
  }

  //Action
  resetSupplementaryRatesStore = () => {
    runInAction(() => {
      this._supplementary_assessment = undefined;
      this._assessmentsSelected = [];
      this._isLoading = false;
      this._gridColumns = colSupplementaryRatesAssessment;
      this._responseLoadError = undefined;
    });
  };

  loadSupplementaryRates = async (supplementaryId: number) => {
    this.setIsLoading(true);
    const response = await getSupplementaryRatesDetailsTabById(supplementaryId);
    let errorResponse = undefined;
    let newSupplementaryAssessments = undefined;
    if (isSuccessResponse(response)) {
      newSupplementaryAssessments = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setSupplementaryAssessments(newSupplementaryAssessments);
    this.setIsLoading(false);
  };

  reLoadSupplementaryRates = async (): Promise<void> => {
    if (this.supplementaryRatesId) {
      await this.loadSupplementaryRates(this.supplementaryRatesId);
    }
  };
}
const supplementaryRatesStoreContext = createContext(
  new SupplementaryRatesStore()
);
export const useSupplementaryRatesStore = () => {
  return useContext(supplementaryRatesStoreContext);
};
