import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { DBRowState } from "@common/models/baseClassStandard";
import { eDBRowState } from "@common/stores/products/enum";
import {
  CCFormConfigDTO,
  CCLabelNamesDTO,
  CCMenuDTO,
  CCOrganisations,
} from "@common/stores/products/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export interface Svc_SecurityTemplate {
  SecurityTemplate_ID: number;
  TemplateName: string;
  TemplateDescription: string;
  ProductType_ENUM: PRODUCT_TYPE | null;
  ProductType_Name: string;
}

export interface SecurityTemplate {
  SecurityTemplate_ID: number;
  Sys_EnterpriseKey: string;
  Sys_DBRowState: DBRowState;
  Sys_TimeStamp: string;
  Sys_TransactionDate: string;
  TemplateName: string;
  TemplateDescription: string;
  ProductType_ENUM: PRODUCT_TYPE;
  CustomProductTypeName: string;
  ProductType_Name?: string;
  IsSystemRecord: boolean;
  DeniedActions: SecurityAction[];
}

export interface SecurityTemplate_Ext extends SecurityTemplate {
  _options?: {
    Product: ProductListing;
  };
}
export interface SecurityAction {
  ProductType_ENUM: PRODUCT_TYPE_NUMBER;
  ProductType_Name: string;
  FormIdentifier_ENUM: string;
  FormIdentifier_Name: string;
  bAllowAction: boolean;
  FormAction_ENUM: string;
  FormAction_Name: string;
  SecurityKey: string;
}

export interface ProductListing {
  Sys_ModifiedBy: string;
  Sys_ModifiedBy_ID: number;
  Sys_ModifiedDate: string;
  Sys_DBRowState: eDBRowState;
  Sys_EnterpriseKey: string;
  Sys_TimeStamp: string;
  Sys_TransactionDate: string;
  ClientListing_ID: number;
  ProductType_ENUM: PRODUCT_TYPE;
  ProductType_Int: number;
  CustomProductName: string;
  ProductType_Name: string;
  Theme_LKP: number;
  LicenceKey: string;
  Menu_Width: number | null;
  Logo: string;
  FileSize: number;
  Filename: string;
  ContentType: string;
  Menu_Sort_Order: number | null;
  Product_Label: string;
  Flag_CommunityCentral: boolean | null;
  Product_Acronym: string;
  Menu?: CCMenuDTO;
  LabelNames: CCLabelNamesDTO;
  FormConfig: CCFormConfigDTO;
  Organisations: CCOrganisations;
}

export enum SecurityTemplateSubmitActions {
  Save = "Save",
  New = "New",
}

export interface ISecurityActionGridProps {
  isGridToolBar?: boolean;
}

export interface ISecurityTemplateNotificationSection {
  notification: IAppNotificationItemAddProps[];
}
