import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { convertDateStringFormatDDMMYYYYToDate } from "@app/products/animals/[id]/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

export interface INewAnimalButtonProps {
  isDisabled?: boolean;
  buttonTitle?: string;
}
export const NewAnimalButton = observer(
  ({ isDisabled = false, buttonTitle }: INewAnimalButtonProps) => {
    const history = useHistory();
    const { settings } = useCommonCoreStore();
    const { pushNotification } = useCCAppNotificationStore();

    const handleOnClick = () => {
      const animalsRestrictDateRangeStartSettingValue = getStringValueSetting(
        settings[ECorporateSettingsField.Animals_RestrictDateRangeStart]
      );
      const animalsRestrictDateRangeStart: Date | null =
        convertDateStringFormatDDMMYYYYToDate(
          animalsRestrictDateRangeStartSettingValue
        );

      const animalsRestrictDateRangeEndSettingValue = getStringValueSetting(
        settings[ECorporateSettingsField.Animals_RestrictDateRangeEnd]
      );
      const animalsRestrictDateRangeEnd: Date | null =
        convertDateStringFormatDDMMYYYYToDate(
          animalsRestrictDateRangeEndSettingValue
        );
      if (
        animalsRestrictDateRangeStart != null &&
        animalsRestrictDateRangeEnd != null &&
        new Date() >= animalsRestrictDateRangeStart &&
        new Date() <= animalsRestrictDateRangeEnd
      ) {
        pushNotification({
          autoClose: false,
          title: `Animal Registration restrict between ${formatDisplayValue(
            animalsRestrictDateRangeStart,
            DATE_FORMAT.DATE
          )} and ${formatDisplayValue(
            animalsRestrictDateRangeEnd,
            DATE_FORMAT.DATE
          )}`,
          type: "error",
        });
      } else {
        history.push(`${ANIMALS_ROUTE}/new`);
      }
    };

    return (
      <CCNavButton
        title={buttonTitle ?? "New"}
        disabled={isDisabled}
        onClick={handleOnClick}
      />
    );
  }
);
