import { postReallocationOfCredits } from "@app/products/property/assessments/components/action-bar/buttons/re-allocation-of-credits/api";
import AssessmentReAllocationDialog from "@app/products/property/assessments/components/dialogs/re-allocation/_index";
import { isSuccessResponse } from "@common/apis/util";
import { CommunityProperty } from "@common/stores/products/config";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { useSelectionListener } from "@components/cc-grid/hooks/useSelectionListener";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { chain } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface IReAllocationOfCreditsButtonProps {
  gridId?: string;
  assessmentIds?: any[];
  keyField?: string;
}

export const ReAllocationOfCreditsButton = observer(
  (props: IReAllocationOfCreditsButtonProps) => {
    const { isLLS } = CommunityProperty.getFlagOfStates();
    const { gridSelectedRows } = useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();
    const [showReAllocationDialog, setShowReAllocationDialog] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { loader } = useSelectionListener(props?.gridId);
    const primaryKey = props?.keyField ?? "Assessment_Id";

    const handlePostHouseKeeping = async () => {
      setIsLoading(true);
      const assessmentIds =
        props.assessmentIds ||
        gridSelectedRows.map((item) => item?.[primaryKey]) ||
        [];

      let newAssessmentIds: number | string[] = assessmentIds;
      if (loader.current.available) {
        newAssessmentIds = (
          await loader.current.process({
            $select: [primaryKey],
            $flat: true,
          })
        ).data;
      }
      const uniqueIds = chain(newAssessmentIds)
        .compact() // Removes falsey values (null, undefined, etc.)
        .uniq() // Removes duplicates
        .value(); // Extracts the final result

      const response = await postReallocationOfCredits({
        AssessmentsList: uniqueIds.toString(),
      });
      setIsLoading(false);
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        pushNotification({
          title:
            response.data?.SuccessMessage ??
            "A Credit Reallocation task is created successfully",
          type: "success",
        });
      } else {
        pushNotification({
          autoClose: false,
          title:
            response.data?.ErrorMessage ??
            "A Credit Reallocation task is already being processed or is on the job queue.",
          type: "error",
        });
      }
      setShowReAllocationDialog(false);
    };

    return (
      <>
        <CCNavButton
          title="Reallocation credits"
          onClick={() => setShowReAllocationDialog(true)}
          invisible={isLLS}
        />

        {showReAllocationDialog && (
          <AssessmentReAllocationDialog
            onClosePopup={() => {
              setShowReAllocationDialog(false);
            }}
            onAsyncConfirm={() => {
              handlePostHouseKeeping();
            }}
            isLoadingYes={isLoading}
          />
        )}
      </>
    );
  }
);
