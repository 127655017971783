import { TownPlanningFormElement } from "@app/products/town-planning/[id]/components/child-screens/general/components/form-element/_index";
import { TownPlanningRegisterRequest } from "@app/products/town-planning/[id]/model";
import { useTownPlanningStore } from "@app/products/town-planning/[id]/store";
import { convertTownPlanningFormToRegisterRequest } from "@app/products/town-planning/[id]/util";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";


export const TownPlanningForm = () => {
  const isNew = useIsNew();
  const { townPlanning, createTownPlanning, updateTownPlanning } =
    useTownPlanningStore();
  const handleSubmit = async (data: any) => {
    const request: TownPlanningRegisterRequest =
      convertTownPlanningFormToRegisterRequest(data);
    if (isNew) {
      createTownPlanning(request);
    } else {
      updateTownPlanning(request);
    }
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={townPlanning}
        render={(formRenderProps: FormRenderProps) => (
          <TownPlanningFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
};
