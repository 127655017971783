import { ApplicationCategoryForm } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/child-screens/general/_index";
import { ApplicationCategoryDetailTab } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/reference-sidebar/detail/_index";
import { PPRApplicationCategoryHistoryTab } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/reference-sidebar/history/_index";
import { useApplicationCategoriesStore } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/store";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import {
  CCNavButton,
  ICCNavButtonProps,
} from "@components/cc-nav-button/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import "./_index.scss";

export const ExistedApplicationCategory = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const {
    applicationCategories,
    isLoading,
    responseLoadError,
    loadApplicationCategories,
    applicationCategoriesId,
    isInactive,
  } = useApplicationCategoriesStore();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        applicationCategories?.ApplicationCategory_ID,
        applicationCategories?.ApplicationCategory_Name,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `PPR - Application Categories - ${
        applicationCategories?.ApplicationCategory_ID ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.TownPlanning,
      RecordType_ENUM: RECORDTYPE.TP_PPRApplication,
      Record_ID: applicationCategoriesId ?? 0,
    },
  ];

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <ApplicationCategoryDetailTab /> },
      {
        title: "History",
        component: <PPRApplicationCategoryHistoryTab />,
      },
    ],
  });

  const ApplicationCategoryNavButton = useCallback(
    (props: ICCNavButtonProps) => {
      const { disabled, ...others } = props;
      return (
        <CCNavButton
          {...others}
          disabled={isInactive ? isInactive : disabled}
        />
      );
    },
    [isInactive]
  );

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Application Category"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadApplicationCategories(parseInt(id))}
        />
      ) : (
        <div className={"cc-application-category-form"}>
          <FormTitle
            title={applicationCategories?.ApplicationCategory_Name || ""}
          />
          <CCManagePageActionBar
            leftActions={[
              <ApplicationCategoryNavButton
                title={"Save"}
                htmlFor={"btn-submit"}
              />,
            ]}
            rightActions={[
              <ApplicationCategoryNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {applicationCategories && <ApplicationCategoryForm />}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
});
