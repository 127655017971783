import { CreateSuppWithImportValDialog } from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/dialogs/create-supplementary/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const CreateSupplementaryWithImportValuationButton = () => {
  const [
    isShowCreateSuppWithImportValDialog,
    setIsShowCreateSuppWithImportValDialog,
  ] = useState<boolean>(false);

  return (
    <>
      <CCNavButton
        title="Create supplementary"
        onClick={() => {
          setIsShowCreateSuppWithImportValDialog(true);
        }}
      />
      {isShowCreateSuppWithImportValDialog && (
        <CreateSuppWithImportValDialog
          onClose={() => {
            setIsShowCreateSuppWithImportValDialog(false);
          }}
        />
      )}
    </>
  );
};
