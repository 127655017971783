import { mockSpecialReadingListDetailsTab } from "@app/products/property/meters/special-reading-list/[id]/components/reference-sidebar/detail/mock";
import { IReferenceSideBarDetails } from "@app/products/property/meters/special-reading-list/[id]/components/reference-sidebar/detail/model";
import { sleep } from "@common/utils/common";

export const loadMeterSpecialReadingListDetailTabById = async (
  id: string
): Promise<IReferenceSideBarDetails> => {
  await sleep(1000);
  return mockSpecialReadingListDetailsTab;
};
