import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const concessionCardsRoute: ICCRoute = {
  path: "concession-cards",
  name: "Concession Cards",
  enum: eMenuAction.Rebates_ConcessionCards,
  children: [
    {
      path: "all",
      name: "All",
      enum: eMenuAction.Rebates_ConcessionCards_All,
      component: require("./all/_index").default,
    },
    {
      path: "only-active-concession-cards",
      name: "Only Active Concession Cards",
      enum: eMenuAction.Rebates_ConcessionCards_Active,
      component: require("./only-active-concession-cards/_index").default,
    },
    {
      path: "only-with-active-rebate-entitlement",
      name: "Only With Active Rebate Entitlement",
      enum: eMenuAction.Rebates_ConcessionCards_WithActiveEntitlements,
      component: require("./only-with-active-rebate-entitlement/_index")
        .default,
    },
  ],
};
