import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import {
  getInitialDataAssessmentTransaction,
  postProcessNewAssessmentTransaction,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/new-transaction/api";
import {
  DTO_Workflow_Journal__AddAssessmentTransaction,
  EKeysOfStepsNewTransaction,
  keysOfStepsNewTransaction,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/new-transaction/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { NewSupplementaryFormStep } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/_index";
import { useNewSupplementaryDialogStore } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/store";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WhichJournalFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/_index";
import { JournalMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/model";
import { SupplementaryMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import { AllocationToChargeBalancesAddTransactionFormStep } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/allocations-to-charge-balances/_index";
import { loadAssessmentChargeBalanceDetail } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/allocations-to-charge-balances/api";
import { AssessmentAddTransactionJournalFormStep } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/_index";
import {
  DTO_LOV_Transaction,
  eAssessmentAllocation,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/model";
import { useAssessmentAddTransactionJournalStepStore } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/store";
import { NewChargeDetailsAddTransactionFormStep } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/new-charge-details/_index";
import {
  DTO_LOV_Levy,
  DTO_LOV_RatingPeriod,
  INewChargeDetailsTransactionStepsLOVs,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/new-charge-details/model";
import { useNewChargeDetailsAddTransactionStepStore } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/new-charge-details/store";
import {
  DTO_ChargeBalance,
  DTO_Journal_Add_Transaction_LOVs,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/model";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import { NewJournalFormStep } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/_index";
import { useNewJournalStepStore } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/store";
import { TransactionAllocationMode } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { JournalStatus } from "@app/products/property/journals/model";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep, isNil, pickBy, uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface INewAssessmentTransactionDialog {
  onClose: () => void;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  assessmentIDs?: number[];
}
export const NewAssessmentTransactionDialog = observer(
  ({
    onClose,
    isSaveOnNextStep = false,
    suffixTitle,
    prefixTitle,
    dataFromActionList,
    assessmentIDs = [],
  }: INewAssessmentTransactionDialog) => {
    //#region <Store>
    const { currentFormTitle } = useCommonProductStore();
    const { setNewAssessmentJournalStepLOVs, newAssessmentJournalStepLOVs } =
      useAssessmentAddTransactionJournalStepStore();
    const { setNewJournalStepLOVs, newJournalStepLOVs } =
      useNewJournalStepStore();
    const { setCreateSupplementaryLOVs } = useNewSupplementaryDialogStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const {
      setOriginNewChargeDetailsStepLOVs,
      setNewChargeDetailsStepLOVs,
      originNewChargeDetailsStepLOVs,
      dataLOvsGroupByFinancialGroup,
    } = useNewChargeDetailsAddTransactionStepStore();
    const {
      isIncompleteMode,
      isFromActionList,
      isReadOnly,
      statusBadge,
      isShowCancelWorkflowButton,
      isToBeApprovalMode,
    } = usePropertyWorkflow(dataFromActionList);
    //#endregion

    //region <State and variables>
    const history = useHistory();
    const localNotificationRef = useRef<ICCLocalNotificationHandle | null>(
      null
    );
    const [workflowInitData, setWorkflowInitData] =
      useState<DTO_Workflow_Journal__AddAssessmentTransaction>();
    const [isLoadingProcess, setIsLoadingProcess] = useState<
      WorkflowProcessMode | undefined
    >();
    const [isLoadingInStep, setIsLoadingInStep] = useState<boolean>(false);
    const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [previousAssessmentID, setPreviousAssessmentID] = useState<number>();
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const onChangeRef = useRef<any>();

    //Get labels
    const [assessmentLabel, assessmentLowercaseLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.Assessment,
        ECustomColNameProperty.AssessmentLowercase,
      ]);

    //#endregion

    //#region <Title header>
    const workflowName = useMemo(() => {
      const formId =
        workflowInitData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id;
      return (
        currentFormTitle(formId ?? 0) || `Add ${assessmentLabel} Transaction`
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflowInitData]);

    const titleHeader = useMemo(() => {
      return getTitleWorkflow(workflowName, prefixTitle, suffixTitle);
    }, [prefixTitle, suffixTitle, workflowName]);
    //#endregion

    //#region <Initial value>
    const initialValue = useMemo((): any => {
      //step Journal
      const journalNumber =
        workflowInitData?.WorkflowDetail?.AssessmentTransaction?.Journal_Number;
      let initWhichJournal: any = {
        JournalMode: workflowInitData?.WorkflowDetail?.JournalMode ?? 0,
        JournalSelected: journalNumber
          ? [{ Journal_Number: journalNumber }]
          : [],
      };
      //step New journal
      let initNewJournal: any = {
        ...workflowInitData?.WorkflowDetail?.CreateNewJournal,
        Journal_Type_Classification: newJournalStepLOVs?.JournalTypes
          ? newJournalStepLOVs.JournalTypes?.find(
              (journalType) =>
                journalType?.Code ===
                workflowInitData?.WorkflowDetail?.CreateNewJournal?.Journal_Type
            )?.Classifications ?? 0
          : 0,
        SupplementaryMode: workflowInitData?.WorkflowDetail?.SupplementaryMode,
      };
      const supplementaryId =
        workflowInitData?.WorkflowDetail?.CreateNewJournal?.Supplementary_Id;
      if (supplementaryId) {
        initNewJournal._options = {
          SupplementarySelected: [
            {
              Supplementary_Id: supplementaryId,
            },
          ],
        };
      }
      //step New supplementary
      let initNewSupplementary: any = {
        ...workflowInitData?.WorkflowDetail?.NewSupplementary,
      };
      //step Assessment
      const transactionType =
        workflowInitData?.WorkflowDetail?.AssessmentTransaction
          ?.Transaction_Type || undefined;
      let optionTransactionType;
      if (!isNil(transactionType)) {
        optionTransactionType =
          newAssessmentJournalStepLOVs?.TransactionTypes?.find(
            (item: DTO_LOV_Transaction) => item?.Code === transactionType
          );
      }

      const assessments = workflowInitData?.WorkflowDetail?.Assessments ?? [];
      if (assessments?.length === 1) {
        setPreviousAssessmentID(assessments[0]?.Assessment_Id);
      }
      let initAssessmentTransaction: any = {
        ...workflowInitData?.WorkflowDetail?.AssessmentTransaction,
        Assessments: assessments,
        Transaction_Type: transactionType,
        TransactionDateTime:
          workflowInitData?.WorkflowDetail?.AssessmentTransaction
            ?.TransactionDateTime ?? new Date(),
        _option: { Transaction_Type: optionTransactionType },
      };
      const levyId =
        workflowInitData?.WorkflowDetail?.NewCharge?.LevyId || undefined;
      const ratingPeriod =
        workflowInitData?.WorkflowDetail?.NewCharge?.RatingPeriod;
      let optionLevyId;
      let optionRatingPeriod;
      if (levyId) {
        optionLevyId = originNewChargeDetailsStepLOVs?.Levy?.find(
          (item) => item?.Code === levyId
        );
      }
      if (ratingPeriod) {
        optionRatingPeriod =
          originNewChargeDetailsStepLOVs?.RatingPeriods?.find(
            (item) => item?.Code === ratingPeriod
          );
      }
      //step New charge details
      let initNewChargeDetail: any = {
        ...workflowInitData?.WorkflowDetail?.NewCharge,
        ChargeDate:
          workflowInitData?.WorkflowDetail?.NewCharge?.ChargeDate ?? new Date(),
        RatingPeriod: ratingPeriod,
        LevyId: levyId,
        Amount:
          workflowInitData?.WorkflowDetail?.NewCharge?.Amount ??
          workflowInitData?.WorkflowDetail?.AssessmentTransaction?.Amount,
        _option: { LevyId: optionLevyId, RatingPeriod: optionRatingPeriod },
      };
      const manualChargeBalances =
        workflowInitData?.WorkflowDetail?.AllocationChargeBalance
          ?.ManualChargeBalances ?? [];
      let chargeBalancesIDs: number[] = [];
      if (manualChargeBalances?.length) {
        chargeBalancesIDs = manualChargeBalances.map(
          (item) => item.Charge_Balances_Id
        );
      }
      //step Allocation charge balance
      let initAllocationChargeBalances: any = {
        ManualChargeBalances:
          workflowInitData?.WorkflowDetail?.AllocationChargeBalance
            ?.ManualChargeBalances ?? [],
        ChargeBalance:
          workflowInitData?.WorkflowDetail?.AllocationChargeBalance
            ?.ChargeBalance ?? [],
        _option: { ChargeBalancesIDs: chargeBalancesIDs },
      };

      formStepRef?.current?.setStepsVisible([
        {
          key: EKeysOfStepsNewTransaction.NewJournal,
          visible:
            workflowInitData?.WorkflowDetail.JournalMode ===
            JournalMode.CreateNew,
          isClearData: true,
        },
        {
          key: EKeysOfStepsNewTransaction.NewSupplementary,
          visible:
            workflowInitData?.WorkflowDetail.SupplementaryMode ===
            SupplementaryMode.CreateNew,
          isClearData: true,
        },
        {
          key: EKeysOfStepsNewTransaction.AllocationChargeBalances,
          visible:
            workflowInitData?.WorkflowDetail?.AssessmentTransaction
              ?.AllocationMode === TransactionAllocationMode.Manual,
          isClearData: true,
        },
        {
          key: EKeysOfStepsNewTransaction.NewChargeDetail,
          visible:
            workflowInitData?.WorkflowDetail?.AssessmentTransaction
              ?.AllocationMode === TransactionAllocationMode.NewCharge,
          isClearData: true,
        },
      ]);
      return {
        [EKeysOfStepsNewTransaction.WhichJournal]: initWhichJournal,
        [EKeysOfStepsNewTransaction.NewJournal]: initNewJournal,
        [EKeysOfStepsNewTransaction.NewSupplementary]: initNewSupplementary,
        [EKeysOfStepsNewTransaction.AssessmentJournal]:
          initAssessmentTransaction,
        [EKeysOfStepsNewTransaction.NewChargeDetail]: initNewChargeDetail,
        [EKeysOfStepsNewTransaction.AllocationChargeBalances]:
          initAllocationChargeBalances,
        [EKeysOfStepsNewTransaction.Documents]: {},
        [EKeysOfStepsNewTransaction.Comments]: {},
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newJournalStepLOVs, workflowInitData]);
    //#endregion

    //#region <Steps>
    const steps: IStep[] = [
      {
        label: "Journal",
        component: WhichJournalFormStep,
        visible: true,
        key: EKeysOfStepsNewTransaction.WhichJournal,
        options: {
          //hidden showBringUpField for now
          showBringUpField: false,
          isReadOnly,
          journalStatus: JournalStatus.Incomplete,
          journalTypeNumber:
            workflowInitData?.WorkflowDetail?.CreateNewJournal?.Journal_Type,
        },
        initialValues: initialValue[EKeysOfStepsNewTransaction.WhichJournal],
      },
      {
        label: "New journal",
        component: NewJournalFormStep,
        visible: true,
        key: EKeysOfStepsNewTransaction.NewJournal,
        options: {
          showStatusField: false,
          isReadOnly,
          showWhichSupplementary: true,
          supplementaryStepKey: EKeysOfStepsNewTransaction.NewSupplementary,
          isDisableJournalType: true,
        },
        initialValues: initialValue[EKeysOfStepsNewTransaction.NewJournal],
      },
      {
        label: "New supplementary",
        component: NewSupplementaryFormStep,
        visible: false,
        key: EKeysOfStepsNewTransaction.NewSupplementary,
        options: {
          isReadOnly,
          workflowDraftId,
        },
        initialValues:
          initialValue[EKeysOfStepsNewTransaction.NewSupplementary],
      },
      {
        label: assessmentLabel,
        component: AssessmentAddTransactionJournalFormStep,
        visible: true,
        key: EKeysOfStepsNewTransaction.AssessmentJournal,
        options: {
          isReadOnly,
          workflowDraftId,
          onChangeRef,
          setIsLoadingInStep,
          assessmentLowercaseLabel,
        },
        initialValues:
          initialValue[EKeysOfStepsNewTransaction.AssessmentJournal],
      },
      {
        label: "New charge details",
        component: NewChargeDetailsAddTransactionFormStep,
        visible: true,
        key: EKeysOfStepsNewTransaction.NewChargeDetail,
        options: {
          isReadOnly,
          workflowDraftId,
        },
        initialValues: initialValue[EKeysOfStepsNewTransaction.NewChargeDetail],
      },
      {
        label: "Allocation to charge balances",
        component: AllocationToChargeBalancesAddTransactionFormStep,
        visible: false,
        key: EKeysOfStepsNewTransaction.AllocationChargeBalances,
        options: {
          isReadOnly,
          workflowDraftId,
        },
        initialValues:
          initialValue[EKeysOfStepsNewTransaction.AllocationChargeBalances],
      },
      {
        label: "Comments",
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsNewTransaction.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Journal,
        },
        initialValues: initialValue[EKeysOfStepsNewTransaction.Comments],
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        visible: true,
        key: EKeysOfStepsNewTransaction.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Journal_Assessment_Add_Transaction,
        },
        initialValues: initialValue[EKeysOfStepsNewTransaction.Documents],
      },
    ];
    //endregion

    //#region <handleSubmit>
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          handleConfirmFinish(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleParkProcess(
            processData(data),
            EListSubmitButton.ConfirmCloseYes
          );
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };
    //#endregion

    //#region <Process data>
    const processData = (data: any) => {
      let workflowDetail: any = {};
      const newData = { ...data };
      const sendSteps = pickBy(newData, (value, key) => {
        if (
          keysOfStepsNewTransaction.includes(key as EKeysOfStepsNewTransaction)
        ) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        const dataStep = { ...value };
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        workflowDetail[key] = dataStep;
      }
      //step Journal
      workflowDetail.JournalMode =
        data?.[EKeysOfStepsNewTransaction.WhichJournal]?.JournalMode;
      workflowDetail.Journal_Number =
        data?.[
          EKeysOfStepsNewTransaction.WhichJournal
        ]?.JournalSelected?.[0]?.Journal_Number;
      workflowDetail.SupplementaryMode =
        data[EKeysOfStepsNewTransaction.NewJournal]?.SupplementaryMode;
      //process data Assessment
      const assessment =
        data?.[EKeysOfStepsNewTransaction.AssessmentJournal]?.Assessments;
      workflowDetail.Assessments = assessment?.length ? assessment : [];
      //process data Allocation
      const chargeBalanceList = cloneDeep(
        data?.[EKeysOfStepsNewTransaction.AllocationChargeBalances]
          ?.ChargeBalance
      );
      let manualChargeBalance =
        data?.[EKeysOfStepsNewTransaction.AllocationChargeBalances]
          ?.ManualChargeBalances ?? [];
      const listChargeBalanceIdChanges =
        data?.[EKeysOfStepsNewTransaction.AllocationChargeBalances]?._option
          ?.ChargeBalancesIDs ?? [];
      if (listChargeBalanceIdChanges?.length) {
        const chargeBalanceListChange = chargeBalanceList.filter(
          (item: DTO_ChargeBalance) =>
            listChargeBalanceIdChanges.includes(item.Charge_Balances_Id)
        );
        manualChargeBalance = chargeBalanceListChange?.map(
          (chargeBalance: DTO_ChargeBalance) => {
            return {
              Charge_Balances_Id: chargeBalance?.Charge_Balances_Id,
              BalanceAmount: chargeBalance?.Adjusted_Amount ?? null,
              Interest: chargeBalance?.Adjusted_Interest ?? null,
            };
          }
        );
      }
      workflowDetail.AllocationChargeBalance = {
        ChargeBalance: chargeBalanceList,
        ManualChargeBalances: manualChargeBalance,
      };
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };
    //#endregion

    //#region <Handle process workflow>
    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: INewProcessWorkflow<DTO_Workflow_Journal__AddAssessmentTransaction>
    ) => {
      const {
        actionSuccess,
        defaultFailedMessage,
        setLoading,
        modeProcess,
        payload,
      } = props;
      const response = await postProcessNewAssessmentTransaction(
        modeProcess,
        payload
      );
      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          if (props?.actionFail) props?.actionFail(response);
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        if (props?.actionFail) props?.actionFail(response);
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      if (props?.actionClose) props.actionClose();
    };
    //#endregion

    //#region <Handle park process>
    /**
     * @param payload
     * @param mode
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_Journal__AddAssessmentTransaction,
      mode?: EListSubmitButton
    ) => {
      //set loading button and dialog
      setIsLoadingProcess(WorkflowProcessMode.Park);
      mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
      const actionCloseRetainDialog = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog();
      };
      //props send to process workflow
      const parkProps: INewProcessWorkflow<DTO_Workflow_Journal__AddAssessmentTransaction> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.Notification ??
                `Add ${assessmentLowercaseLabel} transaction was parked successfully.`,
              type: "success",
            });
            isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
          },
          setLoading: () => {
            setIsLoadingProcess(undefined);
          },
          actionClose: () => {
            mode === EListSubmitButton.ConfirmCloseYes &&
              actionCloseRetainDialog();
          },
          defaultFailedMessage: `Add ${assessmentLowercaseLabel} transaction could not be parked.`,
          modeProcess: WorkflowProcessMode.Park,
        };

      //calling api process workflow
      await handleProcessWorkflow(parkProps);
    };
    //#endregion

    //#region <Handle cancel button>
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessNewAssessmentTransaction,
          dataCancel: data,
          defaultSuccessMessage: `Add ${assessmentLowercaseLabel} transaction was cancelled successfully.`,
          defaultErrorMessage: `Add ${assessmentLowercaseLabel} transaction could not be cancelled.`,
        });
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <Handle next button>
    /**
     * @param data
     * @param step
     * @param keyStep
     * @returns
     */
    const handleNextButton = async (
      data: DTO_Workflow_Journal__AddAssessmentTransaction
    ) => {
      const newData = { ...data };
      const processPayload = processData(newData);
      //send data to call api save
      return handleSaveAndNext(processPayload, newData);
    };
    //#endregion

    //#region <process Data after succeed previous step (Assessment step) >
    const processDataLovsNewChargeDetail = async (
      payload: DTO_Workflow_Journal__AddAssessmentTransaction,
      data: any
    ) => {
      if (!originNewChargeDetailsStepLOVs) return;
      const dataOriginLovsNewChargeDetail: INewChargeDetailsTransactionStepsLOVs =
        {
          ...originNewChargeDetailsStepLOVs,
        };
      //allocation mode
      const allocationMode =
        payload?.WorkflowDetail?.AssessmentTransaction?.AllocationMode;
      if (allocationMode === eAssessmentAllocation.NEW_CHARGES) {
        //clone data lovs
        const newRatingPeriod = cloneDeep(
          dataLOvsGroupByFinancialGroup?.RatingPeriods
        );
        const newLevy = cloneDeep(dataLOvsGroupByFinancialGroup?.Levy);
        //transactionType
        const transactionType =
          payload?.WorkflowDetail?.AssessmentTransaction?.Transaction_Type;
        //financial group in transaction
        let financialGroups =
          data?.AssessmentTransaction?._option?.Transaction_Type
            ?.Financial_Groups;
        if (isNil(financialGroups)) {
          financialGroups =
            newAssessmentJournalStepLOVs?.TransactionTypes?.find(
              (item: DTO_LOV_Transaction) => item?.Code === transactionType
            )?.Financial_Groups;
        }
        if (financialGroups?.length) {
          //filter data by financial group
          let ratingPeriodLovs: DTO_LOV_RatingPeriod[] = [];
          let levyLovs: DTO_LOV_Levy[] = [];
          financialGroups?.forEach((group: number) => {
            let ratingPeriodByGroup: DTO_LOV_RatingPeriod[] = [];
            if (newRatingPeriod) {
              ratingPeriodByGroup = newRatingPeriod[group] ?? [];
            }
            ratingPeriodLovs = [...ratingPeriodLovs, ...ratingPeriodByGroup];
            ratingPeriodLovs = uniqBy(ratingPeriodLovs, "Code");

            let levyByGroup: DTO_LOV_Levy[] = [];
            if (newLevy) {
              levyByGroup = newLevy[group] ?? [];
            }
            levyLovs = [...levyLovs, ...levyByGroup];
            levyLovs = uniqBy(levyLovs, "Code");
          });
          //set new lovs
          setNewChargeDetailsStepLOVs({
            ...dataOriginLovsNewChargeDetail,
            RatingPeriods: ratingPeriodLovs,
            Levy: levyLovs,
          });
        } else {
          //get current value
          const valueRatingPeriod =
            data?.[`${EKeysOfStepsNewTransaction.NewChargeDetail}`]
              ?.RatingPeriod;
          const valueLevy =
            data?.[`${EKeysOfStepsNewTransaction.NewChargeDetail}`]?.LevyId;

          if (!isNil(valueRatingPeriod) && isNil(valueLevy)) {
            const financialGroup =
              data?.[`${EKeysOfStepsNewTransaction.NewChargeDetail}`]?._option
                ?.RatingPeriod?.Financial_Groups ?? [];
            if (financialGroup?.length) {
              let listLevy: DTO_LOV_Levy[] = [];
              financialGroups?.forEach((group: number) => {
                let levyByGroup: DTO_LOV_Levy[] = [];
                if (newLevy) {
                  levyByGroup = newLevy[group] ?? [];
                }
                listLevy = [...listLevy, ...levyByGroup];
                listLevy = uniqBy(listLevy, "Code");
              });
              setNewChargeDetailsStepLOVs({
                ...dataOriginLovsNewChargeDetail,
                Levy: listLevy,
              });
            }
          } else if (isNil(valueRatingPeriod) && !isNil(valueLevy)) {
            const financialGroupId =
              data?.[`${EKeysOfStepsNewTransaction.NewChargeDetail}`]?._option
                ?.Levy?.Financial_Group_Id;
            if (financialGroupId) {
              let listRatingPeriod: DTO_LOV_RatingPeriod[] = [];
              financialGroups?.forEach((group: number) => {
                let ratingByGroup: DTO_LOV_RatingPeriod[] = [];
                if (newRatingPeriod) {
                  ratingByGroup = newRatingPeriod[group] ?? [];
                }
                listRatingPeriod = [...listRatingPeriod, ...ratingByGroup];
                listRatingPeriod = uniqBy(listRatingPeriod, "Code");
              });
              setNewChargeDetailsStepLOVs({
                ...dataOriginLovsNewChargeDetail,
                RatingPeriods: listRatingPeriod,
              });
            }
          } else {
            setNewChargeDetailsStepLOVs(dataOriginLovsNewChargeDetail);
          }
        }
      } else if (allocationMode === eAssessmentAllocation.CHARGES_BALANCES) {
        const newAssessmentId =
          data?.AssessmentTransaction?.Assessments?.[0]?.Assessment_Id;
        if (previousAssessmentID !== newAssessmentId)
          setPreviousAssessmentID(newAssessmentId);
        const chargeBalanceData =
          data?.AllocationChargeBalance?.ChargeBalance ?? [];
        //check condition to load api list charge balance
        if (
          previousAssessmentID !== newAssessmentId ||
          chargeBalanceData?.length === 0
        ) {
          await loadAssessmentChargeBalanceDetail(newAssessmentId).then(
            (response) => {
              if (isSuccessResponse(response) && response.data) {
                const chargeBalanceItems = response.data?.ChargeBalances
                  ? response.data?.ChargeBalances
                  : [];
                if (onChangeRef?.current) {
                  const { onChange } = onChangeRef.current;
                  onChange(
                    `${EKeysOfStepsNewTransaction.AllocationChargeBalances}.ChargeBalance`,
                    {
                      value: chargeBalanceItems,
                    }
                  );
                }
              } else {
                localNotificationRef?.current?.pushNotification({
                  title: `${assessmentLabel} charge balance load failed`,
                  type: "error",
                });
              }
            }
          );
        }
      }
    };
    //#endregion

    //#region <Handle save and next>
    const handleSaveAndNext = async (
      payload: DTO_Workflow_Journal__AddAssessmentTransaction,
      data: any
    ): Promise<boolean> => {
      //check condition use for Save button
      setIsLoadingProcess(WorkflowProcessMode.Save);
      //Calling process Save at next button
      const response = await postProcessNewAssessmentTransaction(
        WorkflowProcessMode.Save,
        payload
      );
      await processDataLovsNewChargeDetail(payload, data);
      setIsLoadingProcess(undefined);
      //set default notification
      const defaultSuccessMessage = `Add ${assessmentLowercaseLabel} transaction was saved successfully.`;
      const defaultFailedMessage = `Add ${assessmentLowercaseLabel} transaction could not be saved.`;
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (!isSaveOnNextStep) {
          onClose();
          pushNotification({
            title: response?.data?.Notification ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          // check is the first saving
          if (isFirstSave) {
            setIsFirstSave(false);
            //set current workflowDraft Id
            setWorkflowDraftId(response?.data?.ID ?? 0);
            // set payload to send
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID,
              },
            });
          }
        }
        return true;
      } else {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      return false;
    };
    //#endregion

    //#region <Handle finish process>
    /**
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_Journal__AddAssessmentTransaction
    ) => {
      //set loading button
      setIsLoadingProcess(WorkflowProcessMode.Finish);
      //props send to process workflow
      const finishProps: INewProcessWorkflow<DTO_Workflow_Journal__AddAssessmentTransaction> =
        {
          payload: payload,
          actionSuccess: (e) => {
            const notificationContent: IAppNotificationItemAddProps = {
              title:
                e?.Notification ??
                `Add ${assessmentLowercaseLabel} transaction was created successfully.`,
              type: "success",
            };
            onClose();
            if (!isNil(e?.Component_ID)) {
              history.push(`${PROPERTY_JOURNALS_ROUTE}/${e?.Component_ID}`, {
                notification: notificationContent,
              });
            } else {
              pushNotification(notificationContent);
            }
          },
          setLoading: () => {
            setIsLoadingProcess(undefined);
          },
          defaultFailedMessage: `Add ${assessmentLowercaseLabel} transaction could not be created.`,
          modeProcess: WorkflowProcessMode.Finish,
        };
      //calling api process workflow
      await handleProcessWorkflow(finishProps);
    };
    //#endregion

    //#region <Handle confirm finish>
    const handleConfirmFinish = (
      payload: DTO_Workflow_Journal__AddAssessmentTransaction
    ) => {
      const dataProcessed = processData(payload);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage: `The ${assessmentLowercaseLabel} transactions will be created based on the information provided. Are you sure you want to submit?`,
      });
    };
    //#endregion

    //#region <Handle close dialog>
    /**
     * @param renderProps
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <Get workflow data>
    /**
     * load initValue for FormStep
     * call once time
     */
    const getWorkflowData = async () => {
      const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
      formStepRef?.current?.setLoadingFormStep(true);
      const response = await getInitialDataAssessmentTransaction(
        workflowDraftId,
        isFromActionList,
        isFromActionList ? undefined : assessmentIDs
      );
      if (Array.isArray(response)) {
        const [lovsJournal, workflowData] = response;
        if (
          isSuccessResponse(lovsJournal) &&
          isSuccessResponse(workflowData) &&
          lovsJournal?.data &&
          workflowData?.data
        ) {
          //set Lovs Data Assessment
          let createSupplementaryLOVs: any = {
            SupplementaryType: lovsJournal?.data?.SupplementaryType ?? [],
            InstallmentType: lovsJournal?.data?.InstalmentPlans,
            AssessmentType: lovsJournal?.data?.AssessmentType,
            ValuationTypeTobeUsed: lovsJournal?.data?.ValuationTypeTobeUsed,
          };
          const newChargeDetail = {
            RatingPeriods: convertValueLOVToNumber(
              lovsJournal?.data?.RatingPeriods ?? [],
              "Code"
            ),
            InstalmentPlans: convertValueLOVToNumber(
              lovsJournal?.data?.InstalmentPlans ?? [],
              "Code"
            ),
            Levy: convertValueLOVToNumber(
              lovsJournal?.data?.Levy ?? [],
              "Code"
            ),
          };
          setNewChargeDetailsStepLOVs(newChargeDetail);
          setOriginNewChargeDetailsStepLOVs(newChargeDetail);
          setNewJournalStepLOVs({
            JournalTypes: convertValueLOVToNumber(
              lovsJournal?.data?.JournalTypes ?? [],
              "Code"
            ),
            Officer: convertValueLOVToNumber(
              lovsJournal?.data?.Officer ?? [],
              "Code"
            ),
          });
          setNewAssessmentJournalStepLOVs({
            TransactionTypes: convertValueLOVToNumber(
              lovsJournal?.data?.TransactionTypes ?? [],
              "Code"
            ),
            PaymentAllocations: convertValueLOVToNumber(
              lovsJournal?.data?.PaymentAllocations ?? [],
              "Code"
            ),
            IsRebateTypeTransaction:
              lovsJournal?.data?.IsRebateTypeTransaction ?? false,
            AssessRebateEntitlements: convertValueLOVToNumber(
              lovsJournal?.data?.AssessRebateEntitlements ?? [],
              "Code"
            ),
          });
          setCreateSupplementaryLOVs(createSupplementaryLOVs);
          setWorkflowInitData(workflowData?.data);
          if (workflowData.data?.WorkflowHeader) {
            setWorkflowHeader(workflowData.data?.WorkflowHeader);
            setWorkflowDraftId(
              workflowData.data?.WorkflowHeader?.WorkflowDraft
                ?.Workflow_Draft_Id ?? 0
            );
          }
          formStepRef?.current?.setLoadingFormStep(false);
        } else {
          let responseError: APIResponse<
            | DTO_Journal_Add_Transaction_LOVs
            | DTO_Workflow_Journal__AddAssessmentTransaction
            | ResponsePacket
          > = response[0];
          if (!isSuccessResponse(response[1])) {
            responseError = response[1];
          }
          formStepRef?.current?.setLoadingFormStep(false);
          formStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        formStepRef?.current?.setLoadingFormStep(false);
        formStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    };
    //#endregion

    //#region <Use Effect calling initial data>
    useEffectOnce(() => {
      getWorkflowData();
    });
    //#endregion
    return (
      <CCFormStep
        ref={formStepRef}
        onSubmit={handleSubmit}
        listButtonId={listSubmitButton}
        initialSteps={steps}
        initialValues={initialValue}
        localNotificationRef={localNotificationRef?.current ?? null}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            titleHeader={titleHeader}
            onClose={() => handleCloseDialog(renderProps)}
            badge={statusBadge}
            bodyElement={renderProps.children}
            disabled={isLoadingProcess !== undefined || isLoadingInStep}
            footerElement={
              <>
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Park
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled ||
                        isLoadingProcess === WorkflowProcessMode.Park
                      }
                    >
                      Park
                    </Button>
                  )}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={!isNil(isLoadingProcess)}
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {(isToBeApprovalMode || isReadOnly) &&
                  renderProps.isLastStep ? null : (
                    <Button
                      themeColor="primary"
                      id={
                        !renderProps.isLastStep &&
                        (isToBeApprovalMode || isReadOnly)
                          ? "cc-next-step-button"
                          : renderProps.nextButton.idButton
                      }
                      disabled={
                        isLoadingProcess === WorkflowProcessMode.Save ||
                        renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Save
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingProcess === WorkflowProcessMode.Save
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              </>
            }
          />
        )}
      />
    );
  }
);
