import { useAssessmentTransactionStore } from "@app/products/property/assessments/[id]/components/child-screens/transactions/store";
import { WorkflowTypes } from "@app/products/property/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const ReverseTransactionButton = observer(() => {
  const { gridSelectedRows } = useAssessmentTransactionStore();
  const { setListDialog } = usePropertyWorkflowStore();
  return (
    <>
      <CCNavButton
        title="Reverse transaction"
        disabled={gridSelectedRows.length !== 1}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Journal_Assessment_Reverse_Transaction,
              data: {},
            },
          ]);
        }}
      />
    </>
  );
});
