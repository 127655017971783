import { UITownPlanning } from "@app/products/town-planning/model";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putApplicationComplete } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/application-complete/api";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNull } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const ApplicationCompleteButton = observer(() => {
  const { ppr, onSubmit, pprId, setPPRWithLoading } = usePSARStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.Complete.toString(),
      listener: () => {
        applicationComplete();
      },
    },
  ]);

  const applicationComplete = async () => {
    if (!pprId) return;
    setIsLoading(true);
    const response = await putApplicationComplete(pprId);

    if (isSuccessIdentityPacket(response) && response.data?.Application) {
      appNotificationStore.clearNotifications();
      setPPRWithLoading(response.data.Application);
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Application completed successfully",
        type: "success",
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Application complete failed",
        description: response.data?.Errors ?? response.statusText,
        type: "error",
      });
    }
    setIsLoading(false);
  };

  const isVisible = useMemo(() => {
    return (
      ppr?.Status_ENUM === Application_Status.PermitIssued &&
      (isNull(ppr?.PPRDetails) || ppr?.PPRDetails?.ProponentDecisionType_ENUM)
    );
  }, [ppr]);

  return isVisible ? (
    <>
      <CCNavButton
        title="Application complete"
        isLoading={isLoading}
        disabled={isLoading}
        onClick={onSubmit}
        name={PSARSubmitActions.ApplicationComplete}
      />
    </>
  ) : null;
});
