import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { AssessmentDetailTab } from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE } from "@app/products/property/assessments/compliance/[id]/constant";
import { PopulateCandidatesLandRentButton } from "@app/products/property/assessments/compliance/candidates-land-rent/components/action-bar/buttons/populate-candidates/_index";
import {
  complianceCandidateLandRentFirstRadioColList,
  complianceCandidateLandRentSecondRadioColList,
} from "@app/products/property/assessments/compliance/candidates-land-rent/config";
import { PROPERTY_COMPLIANCE_CANDIDATE_LAND_RENT_WITH_REVIEW } from "@app/products/property/assessments/compliance/candidates-land-rent/constant";
import {
  VO_Candidate_LandRent,
  eOptionsAssessment,
} from "@app/products/property/assessments/compliance/candidates-land-rent/model";
import { useComplianceCandidateLandStore } from "@app/products/property/assessments/compliance/candidates-land-rent/store";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/property/assessments/compliance/candidates-land-rent/util";
import { InitiateReviewButton } from "@app/products/property/assessments/compliance/component/buttons/initiate-review/_index";
import { NoReviewRequiredButton } from "@app/products/property/assessments/compliance/component/buttons/no-review-required/_index";
import { assessmentsRoute } from "@app/products/property/assessments/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { propertyRoute } from "@app/products/property/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { State } from "@progress/kendo-data-query";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_Candidate_LandRent>();
export default observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentGridDataState, setCurrentGridDataState] = useState<State>({
    group: [{ field: nameOf("NA_Name"), dir: "asc" }],
  });
  const { populateCandidateLandData, resetStore } =
    useComplianceCandidateLandStore();
  const { setIsShowFilterBar, isEmptyData } = useCCProductListViewStore();

  const [dataUrl, setDataUrl] = useState<string>(
    PROPERTY_COMPLIANCE_CANDIDATE_LAND_RENT_WITH_REVIEW
  );
  const [columnsList, setColumnsList] = useState<IColumnFields[]>(
    complianceCandidateLandRentFirstRadioColList
  );
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <AssessmentDetailTab /> },
      {
        title: "Map",
        component: <MapTab addressField="Property_Name_Address_Locality" />,
      },
      {
        title: "Contacts",
        component: (
          <PropertyContactTab componentNumber={eComponent.Assessment} />
        ),
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            //Hyperlink open Assessment -> Use Assessment
            recordType={RECORDTYPE.CommunityProperty_Assessment}
          />
        ),
      },
    ],
  });

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={assessmentsRoute.path} />,
    ],
    centerComponents: [
      <PopulateCandidatesLandRentButton />,
      <InitiateReviewButton />,
      <NoReviewRequiredButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Compliance}
        recordType={RECORDTYPE.CommunityProperty_Compliance}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Compliance}
        keyField={"Assessment_Id"}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        productType={PRODUCT_TYPE.CommunityProperty}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  useEffect(() => {
    if (populateCandidateLandData && !isEmptyData) {
      setIsLoading(true);
      const newFilter = [];
      setIsShowFilterBar(false);
      if (populateCandidateLandData.FromAssessment) {
        newFilter.push({
          field: nameOf("Assess_Reference"),
          value: populateCandidateLandData.FromAssessment,
          operator: "gte",
        });
      }
      if (populateCandidateLandData.ToAssessment) {
        newFilter.push({
          field: nameOf("Assess_Reference"),
          value: populateCandidateLandData.ToAssessment,
          operator: "lte",
        });
      }
      if (newFilter?.length > 0) {
        setIsShowFilterBar(true);
        setCurrentGridDataState({
          ...currentGridDataState,
          filter: currentGridDataState?.filter
            ? {
                ...currentGridDataState.filter,
                filters: newFilter,
                logic: "and",
              }
            : {
                filters: newFilter,
                logic: "and",
              },
        });
      } else if ((currentGridDataState.filter?.filters?.length ?? 0) > 0) {
        setCurrentGridDataState({
          ...currentGridDataState,
          filter: currentGridDataState?.filter
            ? { ...currentGridDataState.filter, logic: "and", filters: [] }
            : { logic: "and", filters: [] },
        });
      }
      if (
        populateCandidateLandData.Assessment ===
        eOptionsAssessment.ASSESSMENT_WITH_REVIEW
      ) {
        if (
          populateCandidateLandData.FromAssessment ||
          populateCandidateLandData.ToAssessment
        ) {
          setColumnsList(complianceCandidateLandRentFirstRadioColList);
          setDataUrl(
            `/odata/property/internal/compliancecandidatelandrentregister?$count=true&`
          );
        }
      } else if (
        populateCandidateLandData.Assessment ===
        eOptionsAssessment.ASSESSMENT_WITHOUT_REVIEW
      ) {
        setColumnsList(complianceCandidateLandRentSecondRadioColList);
        setDataUrl(
          `/odata/property/internal/compliancecandidatelandrentwithoutreviewregister?$count=true&`
        );
      }
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [populateCandidateLandData]);

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;
  return (
    <CCProductListView
      dataUrl={dataUrl}
      columnFields={columnsList}
      primaryField={nameOf("Assessment_Id")}
      state={currentGridDataState}
      onDataStateChange={(state: State) => setCurrentGridDataState(state)}
    />
  );
});
