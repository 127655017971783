import { PPRActionBarNavDropdown } from "@app/products/town-planning/ppr/components/action-bar/nav-dropdown/_index";
import { PPRAppeal } from "@app/products/town-planning/ppr/permit-appeals/all/model";
import { AppealDeleteButton } from "@app/products/town-planning/ppr/permit-appeals/components/action-bar/buttons/delete/_index";
import { colPPRAppealsCurrentByMonth } from "@app/products/town-planning/ppr/permit-appeals/current-by-month/config";
import { TOWN_PLANNING_PPR_APPEALS_CURRENT_BY_MONTH_LIST_VIEW_URL } from "@app/products/town-planning/ppr/permit-appeals/current-by-month/contants";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/town-planning/ppr/permit-appeals/current-by-month/util";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
} from "@app/products/town-planning/ppr/permit-appeals/util";
import { pprRoute } from "@app/products/town-planning/ppr/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AppealContactsTab } from "@common/pages/appeal/_id/components/reference-sidebar/contact/_index";
import { AppealDetailTab } from "@common/pages/appeal/_id/components/reference-sidebar/detail/_index";
import { AppealHistoryTab } from "@common/pages/appeal/_id/components/reference-sidebar/history/_index";
import { APPEAL_ROUTE } from "@common/pages/appeal/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

const nameOf = nameOfFactory<PPRAppeal>();

export default () => {
  useCCListViewActionBar({
    title: pprRoute.name,
    leftComponents: [<PPRActionBarNavDropdown />],
    centerComponents: [<AppealDeleteButton />],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={APPEAL_ROUTE}
        productType={PRODUCT_TYPE.TownPlanning}
        recordType={RECORDTYPE.TP_PPRApplication}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <AppealDetailTab />,
      },
      {
        title: "Contacts",
        component: <AppealContactsTab />,
      },
      {
        title: "History",
        component: <AppealHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colPPRAppealsCurrentByMonth}
      primaryField={nameOf("ID")}
      dataUrl={TOWN_PLANNING_PPR_APPEALS_CURRENT_BY_MONTH_LIST_VIEW_URL}
      state={{
        group: [
          { field: nameOf("DateAppealedMonth"), dir: "asc" },
          { field: nameOf("Status"), dir: "asc" },
        ],
        sort: [{ field: nameOf("DateAppealed"), dir: "desc" }],
      }}
    />
  );
};
