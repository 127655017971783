import { IData } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import { isSuccessResponse } from "@common/apis/util";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { IResultCustomSubmit } from "@common/pages/settings/system-admin/global-settings/_id/main/model";
import { updateProductListing } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/modules/forms/installed-modules-settings/api";
import { ViewModule } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/modules/forms/installed-modules-settings/components/view-module";
import { transformDataGlobalSettingsModule } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/modules/forms/installed-modules-settings/config";
import {
  ContentGroupFlagCommunicationContains,
  ContentGroupProductLabelContains,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/modules/forms/installed-modules-settings/constant";
import { groupFieldSettingGlobal } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/modules/forms/installed-modules-settings/util";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { cloneDeep, filter, sortBy } from "lodash";
import React from "react";
import { useEffectOnce } from "react-use";

export default ({
  setObjectCustomSubmit,
  formRenderProps,
  dataSetting,
}: IGlobalComponentFormProps) => {
  const { SettingFields } = dataSetting;
  const { setIsEditing, setIsLoading } = useSettingGlobalManageStore();

  useEffectOnce(() => {
    const submit = async (
      data: any,
      setData: React.Dispatch<React.SetStateAction<IData | undefined>>,
      setOriginData: React.Dispatch<React.SetStateAction<IData | undefined>>
    ): Promise<void | IResultCustomSubmit | false> => {
      setIsLoading(true);

      /* ====  Data send server  ==== */
      const dataSubmitCustom: any = [];
      cloneDeep(formRender).forEach((item: any) => {
        const itemCustom: any = {};
        item.forEach((field: any) => {
          if (field.Type === "Input") {
            if (
              field.contentGroup?.includes(ContentGroupProductLabelContains)
            ) {
              //contentGroup: "21|Input-Module label-21", 21: ProductType_ENUM, Switch-Community central: constant
              itemCustom["ClientListing_ID"] = field?.EditGroupId;
              itemCustom["ProductType_ENUM"] = parseInt(field?.group) ?? null; // ENUM in contentGroup
              itemCustom["Product_Label"] = data[field?.FieldName];
            }
          } else if (field.Type === "Switch") {
            if (
              field.contentGroup?.includes(
                ContentGroupFlagCommunicationContains
              )
            ) {
              //contentGroup : "21|Switch-Community central-21", 21: ProductType_ENUM, Switch-Community central: constant
              itemCustom["Flag_CommunityCentral"] = data[field?.FieldName];
            }
          }
        });
        if (itemCustom["ProductType_ENUM"]) dataSubmitCustom.push(itemCustom);
      });

      try {
        const response = await updateProductListing(dataSubmitCustom);
        if (isSuccessResponse(response)) {
          setData(data);
          setOriginData(data);
          setIsEditing(false);
          setIsLoading(false);
          return Promise.resolve({
            continueCallSaveBasic: true,
          });
        } else {
          return Promise.resolve(false);
        }
      } catch (e) {
        return Promise.resolve(false);
      }
      /* ====  / Data send server  ==== */
    };

    setObjectCustomSubmit(() => submit);
  });

  let settingFieldsCustom = transformDataGlobalSettingsModule(SettingFields);
  let settingFieldsCustomGroup = groupFieldSettingGlobal(
    settingFieldsCustom,
    "group"
  );

  const settingsType1: any[] = [];
  const settingsType2: any[] = [];
  const settingsType3: any[] = [];

  const settingFieldsCustomGroupArrKey = Object.keys(settingFieldsCustomGroup);
  settingFieldsCustomGroupArrKey.forEach((keyName, index) => {
    const item = settingFieldsCustomGroup[keyName] ?? null;
    if (item) {
      let title = "";
      const itemTitle = filter(item, (o) => o.Type === "Title");
      if (itemTitle.length) {
        title = itemTitle[0].Title;
      }

      const itemHasLink = filter(item, (o) => o.Type === "Link");
      if (itemHasLink.length) {
        const itemHasDropdown = filter(item, (o) => o.DataType === "Lookup");
        if (itemHasDropdown.length) {
          settingsType2.push({
            title,
            item,
          });
        } else {
          settingsType1.push({
            title,
            item,
          });
        }
      } else {
        settingsType3.push({
          title,
          item,
        });
      }
    }
  });

  const formRenderSort: any[] = [
    ...sortBy(settingsType1, "title", "asc"),
    ...sortBy(settingsType2, "title", "asc"),
    ...sortBy(settingsType3, "title", "asc"),
  ];

  const formRender = formRenderSort.map((v) => v.item);
  return formRender.map((item) => {
    return <ViewModule formRenderProps={formRenderProps} data={item} />;
  });
};
