import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import {
  BubbleUpIdentifier,
  Contact_Relation,
  SiteUser,
} from "@app/products/waste-water/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard"; // Change name to dtoBase
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export enum APPEALSTATUS {
  SystemInitialise = "SystemInitialise", //0
  AwaitingAppeal = "AwaitingAppeal", //1
  Lodged = "Lodged", //2
  AwaitingHearing = "AwaitingHearing", //3
  AwaitingApproval = "AwaitingApproval", //4
  TeamLeaderApproved = "TeamLeaderApproved", //5
  TeamLeaderRejected = "TeamLeaderRejected", //6
  Completed = "Completed", //7
  AwaitingOrder = "AwaitingOrder", //8
}

export enum SOGDECISION {
  SystemInitialise = 0,
  Submitted = 1,
  NotSubmitted = 2,
}

export enum SOGDECISIONSTRING {
  SystemInitialise = "SystemInitialise",
  Submitted = "Submitted",
  NotSubmitted = "NotSubmitted",
}
//---------------------------------New
export interface Ancestor {
  AncestorIsSameAsParent: boolean;
  lblParent1: string;
  lblParent2: string;
  lblParent3: string;
  lblParent4: string;
  lblParent5: string;
  lblParent6: string;
  litParent1: string;
  litParent2: string;
  litParent3: string;
  litParent4: string;
  litParent5: string;
  litParent6: string;
  linktext: string;
  lblParentHeader: string;
  lblParentDescription: string;
  litParentDescription: string;
  RecordSourceIdentifier: RecordSourceIdentifier;
  lblAncestor1: string;
  lblAncestor2: string;
  lblAncestor3: string;
  lblAncestor4: string;
  litAncestor1: string;
  litAncestor2: string;
  litAncestor3: string;
  litAncestor4: string;
  LinkText_Ancestor: string;
  RecordSourceIdentifier_Ancestor: RecordSourceIdentifier;
  IsSensitiveApplication: boolean;
  AncestorHasRequiredInspectionType: IKeyValuePacket[];
}

export interface RecordSourceIdentifier {
  _RecordSource_ID: number;
  _RecordSourceType_ENUM: RECORDTYPE | null;
  _RecordSourceType_Name: string;
  _RecordSource_ColumnName: string;
  _RecordSource_StringID: string;
  _RecordSource_FileNumber: string;
}

export type AppealParentActions = PPRSubmitActions.Appealed;
export interface IParentAppealSection {
  id: number;
  recordType: RECORDTYPE;
  data?: IAppeal;
  parentAction?: AppealParentActions;
}

export interface IAppeal extends DTOBaseClass_Standard {
  Appeal_ID: number;
  AppealNo: string;
  DateAppealed: Date;
  Grounds_KWD: number;
  Position_KWD: number | null;
  PositionDate: Date | null;
  Appellant: Contact_Relation;
  Appellant_RID: number | null;
  HearingType_KWD: number | null;
  HearingDuration: string;
  HearingDate: Date | null;
  HearingRepresentationOfficer: SiteUser;
  HearingRepresentationOfficer_ID: number | null;
  HearingWitness: Contact_Relation;
  HearingWitness_RID: number | null;
  HearingAppellantRepresentation: Contact_Relation;
  HearingAppellantRepresentation_RID: number | null;
  CouncilRepresentation: Contact_Relation;
  CouncilRepresentation_RID: number | null;
  ApplicantRepresentation: Contact_Relation;
  ApplicantRepresentation_RID: number | null;
  HearingCost: number | null;
  AppealDecision_KWD: number | null;
  AppealDecision_Name: string;
  RepresentationName: string;
  RepresentationContactDetails: string;
  DecisionDate: Date | null;
  TribunalMember: string;
  DecisionStatus: string;
  DecisionPolicyIssues: string;
  KeyIssues: string;
  Appellant_Details: string;
  AppellantContact_Details: string;
  AppellantContact_Details2: string;
  Witness_Deatils: string;
  AppRepresentation_Details: string;
  Status: string;
  WorkFlowStatus_ENUM: APPEALSTATUS | null;
  WorkFlowStatus_Name: string;
  SOGDueDate: Date | null;
  SOGDecision_ENUM: SOGDECISION | null;
  SOGDecision_Name: string;
  Flag_DotAttendingHearing: boolean | null;
  PracticeHearingDuration: string;
  PracticeHearingDate: Date | null;
  PracticeHearingRepresentationOfficer: SiteUser;
  PracticeHearingRepresentationOfficer_ID: number | null;
  Flag_PracticeHearingDotAttending: boolean | null;
  CCDuration: string;
  CCDateTime: Date | null;
  CCRepresentationOfficer: SiteUser;
  CCRepresentationOfficer_ID: number | null;
  Flag_CCDotAttending: boolean | null;
  CostOfWorks: number | null;
  ApplicantDetails: string;
  _BubbleUps: BubbleUpIdentifier[];
  SaveTriggers: AppealUpdateTriggers[];
}

export enum AppealUpdateTriggers {
  UpdateAppellant,
  UpdateHearingRepresentationOfficer,
  UpdateHearingWitness,
  UpdateHearingAppellantRepresentation,
  UpdateCouncilRepresentation,
  UpdateApplicantRepresentation,
  UpdatePracticeHearingRepresentationOfficer,
  UpdateCCOfficer,
}

export enum AppealSubmitActions {
  Save = "Save",
  New = "New",
  SendLetterToVcatApplicant = "SendLetterToVcatApplicant",
  AdditionalHearing = "AdditionalHearing",
  AppealSendForApproval = "AppealSendForApproval",
  ApproveLetter = "ApproveLetter",
  RejectLetter = "RejectLetter",
}

export const appealSubmitWithValidateActions: AppealSubmitActions[] = [
  AppealSubmitActions.SendLetterToVcatApplicant,
  AppealSubmitActions.AppealSendForApproval,
];

export interface IAppealLOVs {
  HearingType: IKeyValuePacket[];
  AppealDecision: IKeyValuePacket[];
  HearingDuration: IKeyValuePacket[];
  CCDuration: IKeyValuePacket[];
  PracticeHearingDuration: IKeyValuePacket[];
  SOGDecision: IKeyValuePacket[];
  Grounds: IKeyValuePacket[];
}

export interface IDisplayDecision {
  DecisionENUM: number | null;
  DecisionDate: string | null;
  DecisionName: string;
  DecisionValue: string;
}

export interface IContact {
  Contact_ID: number;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  ContactClassification_Name: string;
  PreferredMethod_Name: string;
  PreferredMethod_Formatted: string;
  WorkPhone: string;
  HomePhone: string;
  Fax: string;
  Mobile: string;
  NARNumber: string;
  NARUpdated: boolean | null;
  Flag_SendSMSAlerts: boolean;
  Custom_Tags: string;
  Officer_Ticket_Counter: number;
  Email: string;
  Website: string;
  ABN: string;
  ACN: string;
  VIP: boolean | null;
  VIPTypeID: number | null;
  VIPType: string;
  VIPRelationshipOwnerID: number | null;
  VIPRelationshipOwner: string;
  ContactRoles_Count: number;
  Organisation: string;
  Preferred_ContactAddressType_KWD: number | null;
  Preferred_ContactAddressType_Name: string;
  Postal_ContactAddressType_KWD: number | null;
  Postal_ContactAddressType_Name: string;
  Alternate_ContactAddressType_KWD: number | null;
  Alternate_ContactAddressType_Name: string;
  AddressBook_ID: number | null;
  CopiedFrom_Contact_ID: number;
  DisplayName_Format1: string;
  DisplayName_Format2: string;
  DisplayName_WithSalutation: string;
  DisplayName_PlusPhoneNumbers: string;
  FullContactDetails: string;
  FullContactDetailsWithBR: string;
  DisplayName_CM: string;
  SynchDate: string;
  Contact_CopiedFrom_PortalUser_MemberGUID: string | null;
  Contact_CopiedFrom_PortalUser_MemberUserName: string;
  ContactAlert: string;
  MergedContact_ID: number | null;
  Flag_Duplicate: boolean | null;
  IsAdministrativeAccount: boolean | null;
  ContactComments: string;
  Qualifications: string;
  IsContactCopied: boolean;
  IDCardNumber: string;
  UserType_Name: string;
  Photo_Attachment_ID: number | null;
  Sign_Attachment_ID: number | null;
  AuthorisedActs_KWDs: string;
  Qualification_KWD: number | null;
  Qualification_Name: string;
  Date_IoA: string | null;
  Has_IoA_Location: boolean | null;
  Date_DeliveryLGIS: string | null;
  Signed_IoA_Attachment_ID: number | null;
  Date_CardOrginalIssue: string | null;
  Date_CardProduced: string | null;
  Date_CardReceivedSigned: string | null;
  Date_CardReIssue: string | null;
  Date_CardReIssueReceivedSigned: string | null;
  AQN: string;
  PortalStatus: string;
  ABNValid: boolean;
  ACNValid: boolean;
  GoldenRecord: boolean;
}
