import { loadViewConfiguresColumns } from "@app/products/property/api";
import { getMasterPropertiesValuations } from "@app/products/property/assessments/master-properties/[id]/components/child-screens/valuations/api";
import {
  colValuationsByRatingPeriod,
  colValuationsByValuations,
} from "@app/products/property/assessments/master-properties/[id]/components/child-screens/valuations/config";
import { VO_MasterPropertyValuation } from "@app/products/property/assessments/master-properties/[id]/components/child-screens/valuations/model";
import { useMasterPropertyStore } from "@app/products/property/assessments/master-properties/[id]/store";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

interface MasterPropertyValuationProps {
  isListByValuation?: boolean;
}

const nameOf = nameOfFactory<VO_MasterPropertyValuation>();
export const ValuationsList = observer(
  ({ isListByValuation = true }: MasterPropertyValuationProps) => {
    const { masterPropertyId } = useMasterPropertyStore();
    const [valuationsData, setValuationsData] = useState<
      VO_MasterPropertyValuation[]
    >([]);
    const colValuationFields = isListByValuation
      ? colValuationsByValuations
      : colValuationsByRatingPeriod;
    const [isGridLoading, setIsGridLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);
    const [processedColumns, setProcessedColumns] =
      useState<IColumnFields[]>(colValuationFields);

    useEffect(() => {
      if (masterPropertyId) {
        setIsGridLoading(true);
        getMasterPropertiesValuations(masterPropertyId, isListByValuation).then(
          (response) => {
            setIsGridLoading(false);
            if (response?.data?.Valuations) {
              setValuationsData(response.data.Valuations);
            }
          }
        );
      }
    }, [masterPropertyId, isListByValuation]);

    const loadViewConfig = async () => {
      setIsLoading(true);
      setResponseLoadError(undefined);

      const response = await loadViewConfiguresColumns(
        ViewConfiguration.MasterProperty_Valuations,
        colValuationFields
      );
      if (Array.isArray(response)) {
        setProcessedColumns(response);
      } else {
        setResponseLoadError(response);
      }
      setIsLoading(false);
    };

    useEffectOnce(() => {
      loadViewConfig();
    });

    if (isLoading) return <Loading isLoading />;
    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadViewConfig();
          }}
        />
      );

    return (
      <CCGrid
        className="cc-property-valuations-grid"
        data={valuationsData}
        columnFields={processedColumns}
        primaryField={nameOf("Valuation_Id")}
        isLoading={isGridLoading}
        state={{
          sort: [{ field: nameOf("Valuation_Effective_Date"), dir: "desc" }],
        }}
      />
    );
  }
);
