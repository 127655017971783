import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { PPRCommunicationButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/communication/_index";
import { NewPPRApplicationButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/new-application/_index";
import { PPRUpdateStatusButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/update-status/_index";
import { PPRActionBarNavDropdown } from "@app/products/town-planning/ppr/components/action-bar/nav-dropdown/_index";
import { DeletePPRButton } from "@app/products/town-planning/ppr/permit-referrals/components/action-bar/buttons/delete/_index";
import { colPPRPlansToComply } from "@app/products/town-planning/ppr/plans-to-comply/register/config";
import { TOWN_PLANNING_PPR_PLAN_TO_COMPLY_LIST_VIEW_URL } from "@app/products/town-planning/ppr/plans-to-comply/register/contants";
import { PPRPlansToComply } from "@app/products/town-planning/ppr/plans-to-comply/register/model";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/town-planning/ppr/plans-to-comply/register/util";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
} from "@app/products/town-planning/ppr/plans-to-comply/util";
import { pprRoute } from "@app/products/town-planning/ppr/route";
import { PPRContactsTab } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/contact/_index";
import { PPRDetailTab } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/detail/_index";
import { PPRHistoryTab } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/history/_index";
import { PPRViewOnMapTab } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/view-on-map/_index";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

const nameOf = nameOfFactory<PPRPlansToComply>();

export default () => {
  useCCListViewActionBar({
    title: pprRoute.name,
    leftComponents: [<PPRActionBarNavDropdown />],
    centerComponents: [
      <NewPPRApplicationButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.TownPlanning}
        dataSetFilter={MAILMERGEDATASET.TOWNPLANNING_PPR_Applications}
        recordType={RECORDTYPE.TP_PPRApplication}
      />,
      <PPRCommunicationButton />,
      <DeletePPRButton />,
      <PPRUpdateStatusButton isPlansToComply />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={TOWN_PLANNING_PPR_ROUTE}
        productType={PRODUCT_TYPE.TownPlanning}
        recordType={RECORDTYPE.TP_PPRApplication}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <PPRDetailTab /> },
      { title: "Map", component: <PPRViewOnMapTab /> },
      {
        title: "Contacts",
        component: <PPRContactsTab />,
      },
      {
        title: "History",
        component: <PPRHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colPPRPlansToComply}
      primaryField={nameOf("ID")}
      dataUrl={TOWN_PLANNING_PPR_PLAN_TO_COMPLY_LIST_VIEW_URL}
      state={{
        sort: [
          { field: nameOf("ApplicationYear"), dir: "desc" },
          { field: nameOf("ApplicationNumberSort"), dir: "desc" },
        ],
      }}
    />
  );
};
