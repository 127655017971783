import { PropertyRoute } from "@app/products/property/property.route";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { useHistory } from "react-router-dom";
import { colProduceLetter } from "./config";
import { mockProduceLetter } from "./mock";

interface IAssessmentProduceLetterDialog {
  onClose: () => void;
}

const ArrangementProduceLetterDialog = (
  props: IAssessmentProduceLetterDialog
) => {
  const history = useHistory();
  return (
    <CCDialog
      titleHeader={"Produce Arrangement Letter"}
      onClose={props.onClose}
      maxWidth="54%"
      maxHeight="43%"
      bodyElement={
        <>
          <CCGrid
            className="cc-attributes-uda-grid"
            data={mockProduceLetter}
            columnFields={colProduceLetter}
            primaryField="Condition"
            selectableMode="multiple"
            editableMode="row"
          />
        </>
      }
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            className="cc-dialog-button"
            themeColor="primary"
            onClick={() => {
              history.push(PropertyRoute.PROPERTY_ASSESSMENTS_ARRANGEMENTS);
              props.onClose();
            }}
          >
            OK
          </Button>
        </div>
      }
    />
  );
};
export default ArrangementProduceLetterDialog;
