import { LLPermitsMenu } from "@app/products/local-laws/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const feeSetupRoute: ICCRoute = {
  path: "fee-setup",
  name: "Fee Setup",
  enum: LLPermitsMenu.FeeSetup,
  children: [
    {
      path: "by-permit-type",
      name: "By Permit Type",
      enum: LLPermitsMenu.FeeSetupByPermitType,
      component: require("./by-region/_index").default,
    },
    {
      path: "by-region",
      name: "By Region",
      enum: LLPermitsMenu.FeeSetupByRegion,
      component: require("./by-region/_index").default,
    },
    {
      path: "by-fee-type",
      name: "By Fee Type",
      enum: LLPermitsMenu.FeeSetupByFeeType,
      component: require("./by-region/_index").default,
    },
  ],
};
