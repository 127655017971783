import { getNextTransferDate } from "@app/products/direct-debit/accounts/components/dialogs/create-account/steps/payment-details/util";
import {
  DirectDebitAccount,
  DirectDebitAccountLOVs,
} from "@app/products/direct-debit/accounts/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import {
  requiredValidator,
  validatePositiveCurrency,
  validatorDateValueWithMinMax,
} from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isAfter, isBefore } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";

const nameOf = nameOfFactory<DirectDebitAccount>();
export const DDPaymentDetailsStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const isEndDateCheckBefore = (startDate: Date, endDate: Date) => {
  if (isBefore(endDate, startDate)) {
    return "Date input must be after Start date";
  }
};

const isStartDateCheckAfter = (startDate: Date, endDate: Date) => {
  if (isAfter(startDate, endDate)) {
    return "Date input must be before End date";
  }
};

const FormStepElement = observer(({ formRenderProps }: IFormStepElement) => {
  const { valueGetter, onChange, errors } = formRenderProps;

  const { dataForms } = useFlexibleFormStore();
  const ddAccountsLOVs = useMemo(() => {
    return dataForms?.DialogFormFirstLOVs as DirectDebitAccountLOVs;
  }, [dataForms?.DialogFormFirstLOVs]);

  const getValue = (nameOfField: keyof DirectDebitAccount) =>
    valueGetter(nameOf(nameOfField));
  const onChangeField = (nameOfField: keyof DirectDebitAccount, value: any) => {
    onChange(nameOf(nameOfField as keyof DirectDebitAccount), {
      value: value,
    });
  };

  const handlePaymentFrequencyChange = (event: ComboBoxChangeEvent) => {
    onChangeField("Payment_Frequency_ENUM", event.target.value?.Key);
    onChangeField("Payment_Frequency_Name", event.target.value?.Value);
    const startDate = getValue("Start_Date");
    const paymentFrequency = event.target.value?.Key;
    if (startDate && paymentFrequency) {
      const calculatedDate = getNextTransferDate(
        new Date(startDate),
        paymentFrequency
      );
      onChangeField("Next_Transfer_Date", calculatedDate);
    }
  };

  const handleStartDateChange = (event: DatePickerChangeEvent) => {
    if (!event.value) {
      onChangeField("Start_Date", undefined);
      return;
    }
    onChangeField("Start_Date", new Date(event?.value));
    const paymentFrequency = getValue("Payment_Frequency_ENUM");
    if (paymentFrequency) {
      const calculatedDate = getNextTransferDate(
        new Date(event.value),
        paymentFrequency
      );
      onChangeField("Next_Transfer_Date", calculatedDate);
    }
  };

  const handleEndDateChange = (event: DatePickerChangeEvent) => {
    onChangeField("Next_Transfer_Date", undefined);
    if (!event.value) {
      onChangeField("End_Date", undefined);
      return;
    }
    onChangeField("End_Date", new Date(event?.value));
  };

  const maxDateValidatorStartDate = useCallback(
    (value: Date) =>
      requiredValidator(value) ||
      validatorDateValueWithMinMax(value, new Date(), null) ||
      isStartDateCheckAfter(value, getValue("End_Date")),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getValue("Start_Date")]
  );
  const maxDateValidatorEndDate = useCallback(
    (value: Date) =>
      validatorDateValueWithMinMax(value, new Date(), null) ||
      isEndDateCheckBefore(getValue("Start_Date"), value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getValue("End_Date")]
  );

  const maxDateValidatorNextTransferDate = useCallback(
    (value: Date) =>
      validatorDateValueWithMinMax(
        value,
        getValue("Start_Date") ?? new Date(),
        getValue("End_Date")
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getValue("End_Date"), getValue("Start_Date")]
  );

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <CCLabel title="Start date" isMandatory />
          <Field
            name={nameOf("Start_Date")}
            component={CCDatePicker}
            validator={maxDateValidatorStartDate}
            min={new Date()}
            format={DATE_FORMAT.DATE_CONTROL}
            onChange={handleStartDateChange}
            visited={errors[nameOf("Start_Date")]}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="End date" />
          <Field
            name={nameOf("End_Date")}
            component={CCDatePicker}
            min={getValue("Start_Date")}
            format={DATE_FORMAT.DATE_CONTROL}
            validator={maxDateValidatorEndDate}
            onChange={handleEndDateChange}
            visited={errors[nameOf("End_Date")]}
          />
        </div>
      </div>
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <CCLabel title="Payment frequency" isMandatory />
          <Field
            name={nameOf("Payment_Frequency_ENUM")}
            component={CCSearchComboBox}
            nameDisplay={nameOf("Payment_Frequency_Name")}
            textField={"Value"}
            dataItemKey={"Key"}
            value={getDropdownValue(
              getValue("Payment_Frequency_ENUM"),
              ddAccountsLOVs?.PaymentFrequencies ?? [],
              "Key"
            )}
            validator={requiredValidator}
            data={ddAccountsLOVs?.PaymentFrequencies ?? []}
            onChange={handlePaymentFrequencyChange}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Next transfer date" />
          <Field
            name={nameOf("Next_Transfer_Date")}
            component={CCDatePicker}
            format={DATE_FORMAT.DATE_CONTROL}
            min={getValue("Start_Date") ?? new Date()}
            max={getValue("End_Date") ?? undefined}
            validator={maxDateValidatorNextTransferDate}
            disabled={!getValue("Payment_Frequency_ENUM")}
            visited={errors[nameOf("Next_Transfer_Date")]}
          />
        </div>
      </div>
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <CCLabel title="Amount" />
          <Field
            name={nameOf("Amount")}
            component={CCCurrencyInput}
            validator={validatePositiveCurrency}
            format={CURRENCY_FORMAT.CURRENCY2}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Stop when balance 0" />
          <Field
            name={nameOf("Stop_When_Balance_Zero")}
            component={CCSwitch}
            checked={getValue("Stop_When_Balance_Zero")}
          />
        </div>
      </div>
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <CCLabel title="Balance only" />
          <Field
            name={nameOf("Balance_Only")}
            component={CCSwitch}
            checked={getValue("Balance_Only")}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Instalment only" />
          <Field
            name={nameOf("Instalment_Only")}
            component={CCSwitch}
            checked={getValue("Instalment_Only")}
          />
        </div>
      </div>
    </section>
  );
});
