import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { EventDashboard } from "@common/pages/home/components/crms/model";

export const getCrmsEventDashboard = async (): Promise<
  APIResponse<EventDashboard | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/crms/internal/event/dashboard`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
