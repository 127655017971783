import { IColumnFields } from "@components/cc-grid/model";

export const colInterestAndInstalmentDetailsGL: IColumnFields[] = [
  {
    field: "GLAccountType",
    title: "General Ledger Account Type",
  },
  {
    field: "GLAccReference",
    title: "GL Account Reference",
    editable: true,
  },
  {
    field: "LineItem",
    title: "GL Line Item",
    editable: true,
  },
  {
    field: "DefaultGLAccReference",
    title: "Default Account Reference for GL Account Type",
  },
  {
    field: "DefaultLineItem",
    title: "Default Line Item for GL Account Type",
  },
];
