import authHeader from "@common/apis/auth-header";
import { APIResponse } from "@common/apis/model";
import { IBearerClaims } from "@common/models/bearerTokenClaims";
import { ResponseLogin } from "@common/models/responseLogin";
import { getCookie } from "@common/utils/common";
import axios, { AxiosResponse } from "axios";
const login = async (
  username: string,
  password: string
): Promise<AxiosResponse<ResponseLogin>> => {
  const data = new URLSearchParams();
  data.append("username", username);
  data.append("password", password);
  return await axios.post("/api/authentication/login", data);
};
const TIMEOUT_REQUEST = 10000;
export const getTokenByRefreshToken = async (): Promise<
  APIResponse<ResponseLogin>
> => {
  try {
    const cancel = axios.CancelToken.source();
    const id = setTimeout(() => cancel.cancel(), TIMEOUT_REQUEST);
    const response = (await axios.get(`/api/authentication/token`, {
      headers: {
        headers: authHeader(),
      },
      cancelToken: cancel.token,
    })) as APIResponse<ResponseLogin>;
    clearTimeout(id);
    return response;
  } catch (error) {
    return error as any;
  }
};

const logout = () => {
  localStorage.removeItem("userData");
};

const isLogged = () => {
  if (
    !getCurrentUser() ||
    !getCookie("OpenOffice.LoginCookie") ||
    !getCookie("AccessToken")
  ) {
    localStorage.clear();
    return false;
  }
  return true;
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("userData") || "null");
};

const getTokenSilently = async (): Promise<APIResponse<ResponseLogin>> => {
  return await getTokenByRefreshToken();
};

const getToken = (): string | null =>
  JSON.parse(localStorage.getItem("userData") || "null")?.token;

const getTokenExpireTime = (token: string): number => {
  const tokenClaims = decodeBearerToken(token);
  return new Date((tokenClaims.exp ?? 0) * 1000).getTime();
};

const storeUserData = (result: ResponseLogin): void => {
  if (result && result.access_token && result.access_token !== "") {
    const userData = {
      token: result.access_token,
      username: decodeBearerToken(result.access_token).sub,
      userDisplayname: "",
      userID: 0,
    };
    localStorage.setItem("userData", JSON.stringify(userData));
  }
};

const decodeBearerToken = (token: string): IBearerClaims => {
  var base64Payload = token.split(".")[1];
  var payloadBuffer = Buffer.from(base64Payload, "base64");
  return JSON.parse(payloadBuffer.toString());
};

export default {
  login,
  logout,
  isLogged,
  getCurrentUser,
  decodeBearerToken,
  getTokenSilently,
  getToken,
  getTokenExpireTime,
  storeUserData,
};
