import { BuildingUrl, OfficerColumns } from "@app/products/building/model";
import { IBuildingRegistersCertificatesFormData } from "@app/products/building/registers/certificates/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { FormRenderProps } from "@progress/kendo-react-form";
const nameOf = nameOfFactory<IBuildingRegistersCertificatesFormData>();

export const addressFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Address",
  readOnly: true,
  placeholder: "Site address",
  tableTypes: OfficerColumns,
  url: BuildingUrl.GET_BUILDING_ISSUING_OFFICERS,
  fieldName: "Address_Field",
  value: formRenderProps.valueGetter(nameOf("Address")),
  onChange: (event: CustomEvent) => {
    formRenderProps.onChange(nameOf("Address"), {
      value: event.detail.Position,
    });

    formRenderProps.onChange(nameOf("Owner"), {
      value: event.detail.Name,
    });

    formRenderProps.onChange(nameOf("Details"), {
      value: event.detail.Position,
    });
  },
});

export const officerFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Issued",
  readOnly: true,
  placeholder: "Issued",
  tableTypes: OfficerColumns,
  url: BuildingUrl.GET_BUILDING_ISSUING_OFFICERS,
  fieldName: "Issued_Field",
  value: formRenderProps.valueGetter(nameOf("Officer")),
  onChange: (event: CustomEvent) => {
    formRenderProps.onChange(nameOf("Officer"), {
      value: event.detail.Name,
    });
  },
});
