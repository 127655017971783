import currency from "currency.js";

// Interface for configuration options for more flexibility
export interface ICCCurrencyConfig {
  precision?: number; // Decimal precision (default is 2)
}

export interface ICCCurrencyType extends currency {
  compare: (number: currency | string | number) => boolean;
}

export const CCCurrencyConfig: ICCCurrencyConfig = {
  precision: 2,
};

export const ccCurrency = (
  amount: number | string,
  option?: ICCCurrencyConfig
): ICCCurrencyType => {
  const defaultConfig = option ? option : CCCurrencyConfig;
  const currentCurrency = currency(amount, defaultConfig);
  // Extend the currency object by adding a compare method
  return Object.assign(Object.create(currentCurrency), {
    compare: (value: currency | string | number): boolean => {
      if (typeof value === "object" && "value" in value) {
        return currentCurrency.value === value.value;
      }
      return currentCurrency.value === currency(value).value;
    },
  });
};
