import {
  mockAccountsInTheScheme,
  mockImportSchemeAccounts,
} from "@app/products/property/schemes/[id]/components/child-screens/accounts/mock";
import { IAccountInTheScheme } from "@app/products/property/schemes/[id]/components/child-screens/accounts/model";
import { sleep } from "@common/utils/common";

export const loadAccountsInScheme = async (
  id: string
): Promise<IAccountInTheScheme[]> => {
  await sleep(1000);
  return mockAccountsInTheScheme;
};

export const loadImportSchemeAccounts =
  async (): Promise<IAccountInTheScheme> => {
    await sleep(1000);
    return mockImportSchemeAccounts;
  };
