import { usePicStore } from "@app/products/property/pic/[id]/store";
import { AmalgamatePICCommonButton } from "@app/products/property/pic/list/components/action-bar/buttons/amalgamate-pic/components/buttons/amalgamate-pic-common/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

export const AmalgamatePICManagePagePICButton = observer(() => {
  const { picId } = usePicStore();
  return <AmalgamatePICCommonButton picIDs={isNil(picId) ? [] : [picId]} />;
});
