import { AccessControl } from "@common/models/accessControl";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const nameOfUsers = nameOfFactory<AccessControl>();
export const colGroupsAndUser: IColumnFields[] = [
  {
    field: nameOfUsers("EntityDisplayName"),
    title: "Name",
    width: 550,
  },
];
