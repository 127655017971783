import { ICCRoute } from "@common/constants/ICCRoute";

export const demolitionsRoute: ICCRoute = {
  path: "demolitions",
  name: "Demolitions",
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
