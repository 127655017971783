export const defaultPerson = {
  Delivery: "Standard",
};
export const defaultAttributes = {};
export const defaultConcessionCards = {
  ConcessionCardsHeld: [
    {
      ConcessionCard: "CentreLink - Pensioner Concession Card",
      IsActive: false,
      Id: 0,
    },
    {
      ConcessionCard: "Veterans' Affairs - Pensioner Concession Card",
      IsActive: false,
      Id: 1,
    },
    {
      ConcessionCard: "War Widow/ers'Transport Concession Card",
      IsActive: false,
      Id: 2,
    },
    {
      ConcessionCard: "Veterans' Affairs - Repatriation Health Card",
      IsActive: false,
      Id: 3,
    },
    {
      ConcessionCard: "Veterans' Affairs - Commonwealth Seniors Health Card",
      IsActive: false,
      Id: 4,
    },
  ],
};
export const defaultVoting = {};
export const defaultAssociations = {};
