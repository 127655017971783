import { searchCategoryNameOnly } from "@app/products/town-planning/ppr/[id]/components/input-picker/category-picker/api";
import { colCategoryPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/category-picker/config";
import { getApplicationCategoryOdataURL } from "@app/products/town-planning/ppr/psa-referrals/_id/components/category-picker/utils";
import { PPRApplicationCategory } from "@app/products/town-planning/ppr/system-admin/application-categories/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useDebounce } from "@common/hooks/useDebounce";
import { nameOfFactory } from "@common/utils/common";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { ICCInputPickerProps } from "@components/cc-input-picker/_index";
import { isHTML } from "@components/cc-input-picker/util";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { isArray, isNull, isUndefined } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export interface ICCCategoryPicker extends ICCInputPickerProps {
  recordType: RECORDTYPE;
}

export const CategoryPicker = (props: ICCCategoryPicker) => {
  const nameOf = nameOfFactory<PPRApplicationCategory>();

  const {
    validationMessage,
    visited,
    className,
    dialog = {
      maxWidth: "60%",
      height: "auto",
      titleHeader: "Pick Categories",
    },
    grid = {
      columnFields: [],
      primaryField: "ID",
      selectableMode: "multiple",
    },
    value,
    textField,
    textProduce,
    valueField,
    valueProduce,
    onInputChange,
    onChange,
    readOnly = true,
    disabled,
    customDialog,
    onButtonClick,
    isLoading,
    onLoadData,
    showClearButton,
    recordType,
    ...others
  } = props;

  const [searchKey, setSearchKey] = useState("");
  const [resultsSearch, setResultsSearch] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearch = useDebounce(searchKey, 500);

  const [showDialog, setShowDialog] = useState(false);
  const [gridSelectedRows, setGridSelectedRows] = useState<any[]>(
    props.grid?.selectedRows ?? []
  );

  const inputValue = useMemo(() => {
    if (isUndefined(value) || isNull(value)) return "";
    if (textProduce) return textProduce(value);
    if (textField && !isArray(value) && textField in value)
      return value[textField];
    return value ?? "";
  }, [value, textField, textProduce]);

  const handleOnChange = useCallback(
    (value: any) => {
      setShowDialog(false);
      if (!onChange) return;
      if (valueProduce) return onChange({ value: valueProduce(value) });

      if (valueField && !isArray(value) && valueField in value)
        return onChange({ value: value[valueField] });

      return onChange({ value });
    },
    [onChange, valueField, valueProduce]
  );

  const handleOnClickButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (onButtonClick) return onButtonClick(event);
    setSearchKey("");
    setShowDialog(true);
    setGridSelectedRows(props.grid?.selectedRows ?? []);
    if (onLoadData) return onLoadData();
  };

  useEffect(() => {
    const handleSearchCategory = async () => {
      if (debouncedSearch) {
        setIsSearching(true);
        const response = await searchCategoryNameOnly(
          debouncedSearch,
          recordType
        );
        setResultsSearch(response.data.value || []);
        setIsSearching(false);
      }
    };
    handleSearchCategory();
  }, [debouncedSearch, recordType]);

  return (
    <>
      <div
        className={`${className ?? ""} cc-input-picker-new ${
          !others.valid ? "cc-input-picker-invalid" : ""
        }`}
      >
        {isHTML(inputValue) ? (
          <div className="cc-input-picker-html k-textbox">
            {sanitizeHtml(inputValue)}
          </div>
        ) : (
          <CCInput
            {...others}
            value={inputValue}
            onChange={onInputChange}
            className="cc-input-picker-field"
            readOnly={readOnly}
            disabled={disabled}
          />
        )}
        {showClearButton && (
          <Button
            disabled={disabled}
            className="cc-input-picker-button cc-clear-button"
            icon="close"
            onClick={(event: any) => {
              event.preventDefault();
              handleOnChange(null);
            }}
          />
        )}
        <Button
          disabled={disabled}
          className="cc-input-picker-button"
          iconClass="fa fa-ellipsis-h"
          onClick={handleOnClickButton}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
      {showDialog &&
        (customDialog ? (
          customDialog(value, () => setShowDialog(false), handleOnChange)
        ) : (
          <CCDialog
            {...dialog}
            height="auto"
            onClose={() => setShowDialog(false)}
            bodyElement={
              <div className="cc-field-group cc-add-recipient-dialog">
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <label className="cc-label">Search</label>
                    <Field
                      name={"searchKey"}
                      component={CCInput}
                      placeholder="Search"
                      onChange={(e: InputChangeEvent) => {
                        setSearchKey(e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-1">
                  <CCGrid
                    {...grid}
                    columnFields={colCategoryPicker}
                    primaryField={grid.primaryField}
                    data={searchKey ? resultsSearch : undefined}
                    dataUrl={
                      searchKey
                        ? undefined
                        : getApplicationCategoryOdataURL(recordType)
                    }
                    selectableMode={grid.selectableMode}
                    isLoading={isSearching}
                    isAutoHiddenPager={false}
                    itemPerPage={10}
                    selectedRows={gridSelectedRows}
                    onSelectionChange={(dataItems) => {
                      setGridSelectedRows(dataItems);
                    }}
                    state={{
                      sort: [
                        {
                          field: nameOf("ApplicationCategory_Name"),
                          dir: "asc",
                        },
                      ],
                    }}
                  />
                </div>
                <br />
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  onClick={() => setShowDialog(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  disabled={gridSelectedRows.length === 0}
                  onClick={() => {
                    handleOnChange(
                      grid.selectableMode === "multiple"
                        ? gridSelectedRows
                        : gridSelectedRows[0]
                    );
                  }}
                >
                  Select
                </Button>
              </div>
            }
          />
        ))}
    </>
  );
};
