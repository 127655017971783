import { eComponent } from "@app/products/property/components/associations/model";
import { ResponseMessage } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const createDocument = async (
  component_number: eComponent,
  component_id: number | undefined,
  documentTypeId: string
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/document/${component_number}/${component_id}/save`,
      documentTypeId
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
