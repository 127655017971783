import { ADVANCED_SEARCH_SEARCHES_ROUTE } from "@app/products/property/advanced_search/searches/constant";
import { BOOLEAN_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const footprintsColList: IColumnFields[] = [
  {
    field: "Name",
    title: "Name",
    width: 350,
    locked: true,
    linkTo: (dataItem) => `${ADVANCED_SEARCH_SEARCHES_ROUTE}/1`,
  },
  { field: "CreatedOn", title: "Created On", format: DATE_FORMAT.DATE },
  { field: "Component", title: "Component" },
  {
    field: "Type",
    title: "Type",
    width: 150,
  },
  {
    field: "IsActive",
    title: "Is Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "Officer",
    title: "Officer",
    width: 150,
  },
  {
    field: "SetApplicationRole",
    title: "Set Application Role",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "ListView",
    title: "List View",
    width: 250,
  },
];
