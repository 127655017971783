import { createStore } from "@app/products/ams/api/helpers/create-store";
import { StoreProvider } from "@app/products/ams/api/helpers/store-context";
import ErrorFallback from "@components/cc-error-fallback/_index";
import "@fortawesome/fontawesome-pro/css/all.min.css";
import "@fortawesome/fontawesome-pro/css/light.min.css";
import "@fortawesome/fontawesome-pro/css/solid.min.css";
import "@styles/index.scss";
import "@styles/responsive.scss";
import "mobx-react-lite/batchingForReactDom";
import React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
require("./externalFunctions.js");

// really this would be at the absolute root of all the stores, ive just done it in ams to demonstrate

const rootStore = createStore();

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <StoreProvider value={rootStore}>
      <App />
    </StoreProvider>
  </ErrorBoundary>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
