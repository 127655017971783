import { DTO_Address } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { TextEditCustomCell } from "@app/products/property/components/grid/components/cc-text-edit-cell/_index";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfAddress = nameOfFactory<DTO_Address>();

export const colPhysicalAddress: IColumnFields[] = [
  {
    field: nameOfAddress("PropertyName"),
    title: "Property Name",
    width: 200,
    editable: true,
    editCell: TextEditCustomCell,
  },
  {
    field: nameOfAddress("PropertyAddress"),
    title: "Property Address",
  },
  { field: nameOfAddress("AddressTypeName"), title: "Address Type" },
  {
    field: nameOfAddress("IsAddressPrimary"),
    title: "Is Primary",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: nameOfAddress("Locality"), title: "Locality" },
  { field: nameOfAddress("LocationDescriptor"), title: "Location Descriptor" },
  {
    field: nameOfAddress("AddressPFI"),
    title: "Address PFI",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfAddress("IsAddressValid"),
    title: "Is Validated",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];
