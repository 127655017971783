import { eventEmitter } from "@/App";
import { colAddress, nameOfAddress } from "@app/core/addresses/config";
import { IAddress } from "@app/core/addresses/model";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { useTabTableStore } from "@app/core/tab-table/store";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";

interface IAddressProps {
  id?: number;
  recordType: RECORDTYPE;
}

export const Addresses = ({ id, recordType }: IAddressProps) => {
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();
  const { isDisabled } = useTabTableStore();
  const [gridSelectedRows, setGridSelectedRows] = useState<IAddress[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
    useState<boolean>(false);

  const handleOnDelete = async (data: IDeleteConfirmation) => {
    setIsDeleting(true);
    const ids: number[] = gridSelectedRows?.map((item: IAddress) => item?.ID);
    const response = await deleteRefUsageByIdAndRecordType(
      ids,
      RECORDTYPE.CORE_Address,
      data?.Reason
    );
    setIsDeleting(false);
    if (isSuccessResponse(response)) {
      setGridSelectedRows([]);
      clearErrorNotification();
      setShowConfirmDeleteDialog(false);
      pushNotification({
        title: "Address deleted successfully",
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(TabTableEventType.RefreshData);
    } else {
      pushNotification({
        autoClose: false,
        title: "Delete address failed",
        description: response.data?.Errors,
        type: "error",
      });
    }
  };

  return (
    <>
      <CCGrid
        toolbar={
          <div className="cc-grid-tools-bar">
            <Button
              type="button"
              iconClass="fas fa-minus"
              title="Remove"
              disabled={isDisabled ? isDisabled : gridSelectedRows.length !== 1}
              onClick={() => setShowConfirmDeleteDialog(true)}
            />
          </div>
        }
        selectableMode="multiple"
        dataUrl={`/odata/core/internal/addresses/GetAddresses(key=${id},recordType=${recordType})?$count=true&`}
        primaryField={nameOfAddress("ID")}
        selectedRows={gridSelectedRows}
        onSelectionChange={(dataItem: IAddress[]) => {
          setGridSelectedRows([...dataItem]);
        }}
        columnFields={colAddress}
        state={{ sort: [{ field: nameOfAddress("ID"), dir: "desc" }] }}
      />

      {showConfirmDeleteDialog && (
        <ConfirmDelete
          onClose={() => setShowConfirmDeleteDialog(false)}
          handleSubmit={handleOnDelete}
          isDeleting={isDeleting}
          contentDelete={"Address"}
          header={"Confirm Deletion"}
        />
      )}
    </>
  );
};
