import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

export const colTransactionAllocation: IColumnFields[] = [
  {
    field: "Amount",
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "DueDate",
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "RatingPeriod",
    title: "Rating Period",
  },
  {
    field: "ChangeName",
    title: "Change Name",
    footerCell: <TextCell value="Overall Total" />,
  },
  {
    field: "ChangeCode",
    title: "Change Code",
  },
  {
    field: "ChangeDate",
    title: "Change Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "LevyName",
    title: "Levy Name",
  },
  {
    field: "LevyCode",
    title: "Levy Code",
  },
  {
    field: "ChargeBalancesId",
    title: "Charge Balances ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
