import { UITownPlanning } from "@app/products/town-planning/model";
import {
  IPPROfficerAllocation,
  PPROfficerAllocation,
} from "@app/products/town-planning/ppr/components/action-bar/dialogs/allocate-to-officer/model";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putOfficerAllocate } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/allocate-to-officer/api";
import { AllocateToOfficerDialog } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/allocate-to-officer/_index";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const AllocateToOfficerButton = observer(() => {
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const { ppr, setPPRWithLoading, onSubmit, pprId } = usePSARStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [notification, setNotification] =
    useState<IAppNotificationItemAddProps>();

  const isVisible = useMemo(() => {
    return ppr?.Status_ENUM === Application_Status.Lodged;
  }, [ppr]);

  const initialData: IPPROfficerAllocation = useMemo(() => {
    return {
      ReasonForPermit: ppr?.ReasonForPermit,
      ApplicationCategories: ppr?.ApplicationCategories,
      Rating_KWD: ppr?.Rating_KWD,
      Planner: ppr?.Planner,
      Planner_ID: ppr?.Planner_ID,
      AllocationComments: ppr?.AllocationComments,
      SaveTriggers: [],
    };
  }, [ppr]);

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.AllocateToPlanner.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const handleSubmit = async (data: PPROfficerAllocation) => {
    setIsLoading(true);
    if (!pprId) return;
    const response = await putOfficerAllocate(pprId, data);
    setNotification(undefined);
    if (isSuccessIdentityPacket(response) && response.data?.Application) {
      setIsShowDialog(false);
      clearNotifications();
      setPPRWithLoading(response.data.Application);
      pushNotification({
        type: "success",
        title: "Officer allocated successfully",
      });
      if (
        response.data?.Notifications &&
        response.data?.Notifications.length > 0
      ) {
        pushNotification({
          autoClose: false,
          title: "Allocate to officer",
          type: "warning",
          description: response.data?.Notifications,
        });
      }
    } else {
      setNotification({
        title: "The allocate to officer workflow could not be completed.",
        type: "error",
        autoClose: false,
        description: response.data?.Errors ?? response?.error,
      });
    }
    setIsLoading(false);
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Allocate to planner" // TODO: Change name this folder and other folder later
        onClick={onSubmit}
        name={PSARSubmitActions.AllocateToOfficer}
      />

      {isShowDialog && (
        <AllocateToOfficerDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleSubmit}
          notification={notification}
          isLoading={isLoading}
          initialData={initialData}
        />
      )}
    </>
  ) : null;
});
