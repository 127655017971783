import { DTO_DebtRecovery_Assessment } from "@app/products/property/assessments/debt-recovery/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const loadDebtRecoveryAssessmentData = async (
  debtRecoveryId: number
): Promise<DTO_DebtRecovery_Assessment | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_DebtRecovery_Assessment>(
        `api/property/internal/debtrecovery/${debtRecoveryId}/assessment`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    return undefined;
  }
};
