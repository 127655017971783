import { EventManagementFieldMapping } from "@app/products/event-management/constant";
import { EVENT_MANAGEMENT_MANAGE_ROUTE } from "@app/products/event-management/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colEventManagementAdminEventTypes: IColumnFields[] = [
  {
    field: EventManagementFieldMapping.EventType_Name,
    title: "Name",
    width: 400,
    locked: true,
    linkTo: (dataItem) => {
      return `${EVENT_MANAGEMENT_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: EventManagementFieldMapping.EventType_Description,
    title: "Description",
    width: 500,
  },
  {
    field: EventManagementFieldMapping.Classification,
    title: "Classification",
    width: 300,
  },
  { field: EventManagementFieldMapping.Level, title: "Level", width: 300 },
  { field: EventManagementFieldMapping.Group, title: "Group", width: 300 },
];
