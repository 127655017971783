import {
  getDisplayBuildingUnitNumber,
  getDisplayFloorNumber,
  getDisplayHouseNumber,
  getDisplayStreetName,
  getFullAddressSingleLine,
  getOwnersDisplay,
  getRatePayersDisplay,
  getStringDisplayFromArrayObject,
} from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCValueField } from "@components/cc-value-field/_index";
import { FieldArray } from "@progress/kendo-react-form";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import { useEffectOnce } from "react-use";

export const SUMMARY_FORM_STEP = "Summary";
export const SummaryStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  setStepsVisible,
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const propertyAssessment = valueGetter("Address.PropertyAssessment");
  const assessmentID = valueGetter("Address.PropertyAssessment.Assessment_ID");
  const rin = valueGetter("Address.PropertyAssessment.RIN");
  const ward = valueGetter("Address.PropertyAssessment.Ward");
  const legalDesc = valueGetter("Address.PropertyAssessment.Legal_Description");
  const owner = getOwnersDisplay(propertyAssessment);
  const ratepayer = getRatePayersDisplay(propertyAssessment);
  const zoneDisplay = getStringDisplayFromArrayObject(
    valueGetter("ZoneList"),
    "Name"
  );
  const overlayDisplay = getStringDisplayFromArrayObject(
    valueGetter("OverlayList"),
    "Name"
  );

  const displayZone = useMemo(() => {
    let displayZone = "";
    if (isEmpty(zoneDisplay) && propertyAssessment?.Zones) {
      displayZone = propertyAssessment?.Zones;
    } else {
      displayZone = zoneDisplay;
    }
    return displayZone;
  }, [propertyAssessment, zoneDisplay]);

  const displayOverlay = useMemo(() => {
    let displayOverlay = "";
    if (isEmpty(overlayDisplay) && propertyAssessment?.Overlay) {
      displayOverlay = propertyAssessment?.Overlay;
    } else {
      displayOverlay = overlayDisplay;
    }
    return displayOverlay;
  }, [overlayDisplay, propertyAssessment]);

  useEffectOnce(() => {
    const newUnitNo = !isEmpty(getDisplayFloorNumber(propertyAssessment))
      ? `${getDisplayFloorNumber(propertyAssessment)} `
      : "" + getDisplayBuildingUnitNumber(propertyAssessment);

    onChange("Address.StreetNo", {
      value: getDisplayHouseNumber(valueGetter("Address.PropertyAssessment")),
    });
    onChange("Address.UnitNo", {
      value: newUnitNo,
    });
    onChange("Address.StreetName", {
      value: getDisplayStreetName(propertyAssessment),
    });
  });

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">PID number</label>
          <CCValueField
            value={valueGetter("Address.PropertyAssessment.PID_No")}
          />
        </div>
        {assessmentID && (
          <div className="cc-field">
            <label className="cc-label">Assessment ID</label>
            <CCValueField value={assessmentID} />
          </div>
        )}
        {!isEmpty(rin) && (
          <div className="cc-field">
            <label className="cc-label">RIN</label>
            <CCValueField value={rin} />
          </div>
        )}
        {!isEmpty(ward) && (
          <div className="cc-field">
            <label className="cc-label">Ward</label>
            <CCValueField value={ward} />
          </div>
        )}
        <div className="cc-field">
          <label className="cc-label">Address</label>
          <CCValueField
            value={getFullAddressSingleLine(valueGetter("Address"))}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Legal description</label>
          <CCValueField value={legalDesc} />
        </div>
        {owner && (
          <div className="cc-field">
            <label className="cc-label">Owners</label>
            <CCValueField value={sanitizeHtml(owner)} />
          </div>
        )}
        {ratepayer && (
          <div className="cc-field">
            <label className="cc-label">Ratepayers</label>
            <CCValueField value={sanitizeHtml(ratepayer)} />
          </div>
        )}
        {displayZone && (
          <div className="cc-field">
            <label className="cc-label">Zones</label>
            <CCValueField value={displayZone} />
          </div>
        )}

        {displayOverlay && (
          <div className="cc-field">
            <label className="cc-label">Overlays</label>
            <CCValueField value={displayOverlay} />
          </div>
        )}
      </div>
    </section>
  );
};
