import { IUndoAppealCompleteDialogData } from "@app/products/town-planning/ppr/components/action-bar/dialogs/undo-appeal-complete/model";
import { UndoAppealCompleteDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/undo-appeal-complete/_index";
import { putUndoAppealComplete } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/undo-appeal-complete/api";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const UndoAppealCompleteButton = observer(() => {
  const { reLoadPPR } = usePPRStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const { clearNotifications, pushNotification } = useCCAppNotificationStore();
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  const handleSubmit = async (appealID?: number) => {
    if (appealID) {
      setIsLoading(true);
      setNotifications([]);
      const response = await putUndoAppealComplete(appealID);
      if (isSuccessResponse(response)) {
        reLoadPPR().then(() => {
          setIsShowDialog(false);
          clearNotifications();
          pushNotification({
            type: "success",
            title: "Undo appeal complete successfully",
          });
        });
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: "Undo appeal complete failed",
            type: "error",
            description: response.data?.Errors ?? response.statusText,
          },
        ]);
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <CCNavButton
        title={"Undo appeal complete"}
        isLoading={isLoading}
        onClick={() => setIsShowDialog(true)}
      />
      {isShowDialog && (
        <UndoAppealCompleteDialog
          isLoading={isLoading}
          onSubmit={(data: IUndoAppealCompleteDialogData) => {
            handleSubmit(data.SelectedAppeal?.Key);
          }}
          onClose={() => setIsShowDialog(false)}
          notifications={notifications}
        />
      )}
    </>
  );
});
