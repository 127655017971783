import { VO_Assessment_GIS } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { DTO_LOV } from "@common/models/odataResponse";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { dateRangeValidator } from "@common/utils/form-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { isEqual } from "lodash";
import React, { useCallback, useRef } from "react";

interface GISReferenceAmalgamateAssessmentDialogProps {
  onClose: () => void;
  onSubmit: (value: VO_Assessment_GIS) => void;
  gisReferenceData: VO_Assessment_GIS[];
  gisTypes: DTO_LOV[];
}

const nameOf = nameOfFactory<VO_Assessment_GIS>();
export const GISReferenceAmalgamateAssessmentDialog = ({
  onClose,
  onSubmit,
  gisReferenceData,
  gisTypes,
}: GISReferenceAmalgamateAssessmentDialogProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const formValidator = useCallback((value) => {
    return dateRangeValidator(
      value,
      nameOf("AG_Effective_From"),
      nameOf("AG_Effective_To")
    );
  }, []);

  const handleOnSubmitClick = async (event: FormSubmitClickEvent) => {
    const newGISReference = event.values as VO_Assessment_GIS;
    //Check duplicates
    if (
      newGISReference?.GIS_Reference &&
      !gisReferenceData?.some((item: VO_Assessment_GIS) =>
        isEqual(item?.GIS_Reference, newGISReference.GIS_Reference)
      )
    ) {
      return onSubmit(newGISReference);
    }
    notificationRef.current?.pushNotification({
      title: `GIS Reference ${newGISReference.GIS_Reference} already exist`,
      type: "warning",
    });
  };

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      validator={formValidator}
      render={(formRenderProps: FormRenderProps) => {
        const { valid, onSubmit, valueGetter } = formRenderProps;
        return (
          <FormElement>
            <CCDialog
              maxWidth="25%"
              height="auto"
              onClose={onClose}
              titleHeader="Add GIS Reference"
              bodyElement={
                <div className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <CCLabel title="GIS reference" isMandatory />
                        <Field
                          name={nameOf("GIS_Reference")}
                          component={CCInput}
                          maxLength={50}
                          placeholder="GIS reference"
                          validator={requiredValidator}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Type" isMandatory />
                        <Field
                          name={nameOf("GISTypeObj")}
                          component={CCDropDownList}
                          textField={"Name"}
                          dataItemKey={"Code"}
                          data={gisTypes}
                          validator={requiredValidator}
                          onChange={(event: DropDownListChangeEvent) => {
                            formRenderProps.onChange(nameOf("GISTypeObj"), {
                              value: event.value,
                            });
                            formRenderProps.onChange(nameOf("GIS_Type_Id"), {
                              value: event.value.Code,
                            });
                            formRenderProps.onChange(nameOf("GIS_Type_Name"), {
                              value: event.value.Name,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <CCLabel title="From date" />
                        <Field
                          name={nameOf("AG_Effective_From")}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE_CONTROL}
                          max={
                            valueGetter(nameOf("AG_Effective_To"))
                              ? new Date(valueGetter(nameOf("AG_Effective_To")))
                              : undefined
                          }
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="To date" />
                        <Field
                          name={nameOf("AG_Effective_To")}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE_CONTROL}
                          min={
                            valueGetter(nameOf("AG_Effective_From"))
                              ? new Date(
                                  valueGetter(nameOf("AG_Effective_From"))
                                )
                              : undefined
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className={"cc-dialog-button"}
                    disabled={!valid}
                    themeColor="primary"
                    onClick={onSubmit}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
