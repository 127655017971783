import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";

export const mockRegisterContacts: ICommonContactItemRender[] = [
  {
    ID: "0",
    DisplayName: "L & s Carevic",
    Type: "Owner",
    Email: "lsarevic@openoffice.com.au",
    Mobile: "1-540-062-1600",
    WorkPhone: "(640) 389-657",
  },
  {
    ID: "1",
    DisplayName: '"Merrileugh" Toorak Rd',
    Type: "Owner",
    Email: "merrileughtoorakrd@openoffice.com.au",
    Mobile: "1-540-062-2154",
    WorkPhone: "(640) 786-657",
  },
  {
    ID: "2",
    DisplayName: "BEACONSFIELD, NSW 2015",
    Type: "Owner",
    Email: "beaconsfieadnsw2015@openoffice.com.au",
    Mobile: "1-545-062-9133",
    WorkPhone: "(640) 137-657",
  },
];
