import { InputSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/input/_index";
import { SearchComboBoxSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/search-combobox/_index";
import { SwitchSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/switch/_index";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { contactAllowNameValidator } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/contacts/forms/general-settings/util";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const ContactGeneralSettingsForm = ({
  formRenderProps,
}: ICommonFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const data = useDataSetting(configData, [
    "Global_DefaultPostalAddress",
    "CorporateSettings_ContactAddressOrder",
    "CorporateSettings_PreferredAddressLabel",
    "CorporateSettings_AlternateAddressLabel",
    "CorporateSettings_PreferredMethodLabel",
    "Global_AlternateAsBillingAddress",
    "Global_ContactManager_AllowFirstNameOnly",
    "Global_ContactManager_AllowLastNameOnly",
    "Global_ContactManager_AllowCopyContact",
    "Global_ContactManager_ShowContactRolesWarning",
    "Global_ContactManager_HighlightContactAlert",
    "Global_ContactManager_EnableContactAlertColumn",
    "Global_ContactManager_EnablePostalAddressColumn",
    "Global_ContactManager_ShowGenderPronoun",
    "Global_ContactManager_ShowGenderPronounWithDisplayName",
    "Global_ContactManager_ShowContactExtension",
    "Global_ContactsTabTable_ShowHomePhone",
    "Global_ContactManager_AddSalutationToDisplayName",
    "Global_ContactManager_ConatctDetailMandatory",
    "EnableVIP",
    "CorporateSettings_EnableFeesAndGarbage",
    "CorporateSettings_NameSearchOnlyReturnPerson",
    "CorporateSettings_NameSearchIncludeOrganisationDisplay",
    "CorporateSettings_InheritParentContacts",
    "CorporateSettings_EnableMergeDuplicateContacts",
    "CorporateSettings_ProsecutionsContactPreferredDisplayAddress",
  ]);

  const isAllowFirstName = formRenderProps.valueGetter(
    data.Global_ContactManager_AllowFirstNameOnly?.FieldName as string
  );
  const isAllowLastName = formRenderProps.valueGetter(
    data.Global_ContactManager_AllowLastNameOnly?.FieldName as string
  );

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <SwitchSettings
          data={data.Global_DefaultPostalAddress}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SearchComboBoxSettings
          data={data.CorporateSettings_ContactAddressOrder}
          isEditing={isEditing}
        />
        <InputSettings
          data={data.CorporateSettings_PreferredAddressLabel}
          isEditing={isEditing}
        />
        <InputSettings
          data={data.CorporateSettings_AlternateAddressLabel}
          isEditing={isEditing}
        />
        <InputSettings
          data={data.CorporateSettings_PreferredMethodLabel}
          isEditing={isEditing}
        />
        <SwitchSettings
          data={data.Global_AlternateAsBillingAddress}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.Global_ContactManager_AllowFirstNameOnly}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
          validator={() =>
            contactAllowNameValidator(isAllowFirstName, isAllowLastName)
          }
        />
        <SwitchSettings
          data={data.Global_ContactManager_AllowLastNameOnly}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
          validator={() =>
            contactAllowNameValidator(isAllowFirstName, isAllowLastName)
          }
        />
        <SwitchSettings
          data={data.Global_ContactManager_AllowCopyContact}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.Global_ContactManager_ShowContactRolesWarning}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.Global_ContactManager_HighlightContactAlert}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.Global_ContactManager_EnableContactAlertColumn}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.Global_ContactManager_EnablePostalAddressColumn}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.Global_ContactManager_ShowGenderPronoun}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.Global_ContactManager_ShowGenderPronounWithDisplayName}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.Global_ContactManager_ShowContactExtension}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.Global_ContactsTabTable_ShowHomePhone}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.Global_ContactManager_AddSalutationToDisplayName}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.Global_ContactManager_ConatctDetailMandatory}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.EnableVIP}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.CorporateSettings_EnableFeesAndGarbage}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.CorporateSettings_NameSearchOnlyReturnPerson}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.CorporateSettings_NameSearchIncludeOrganisationDisplay}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.CorporateSettings_InheritParentContacts}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SwitchSettings
          data={data.CorporateSettings_EnableMergeDuplicateContacts}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <SearchComboBoxSettings
          data={
            data.CorporateSettings_ProsecutionsContactPreferredDisplayAddress
          }
          isEditing={isEditing}
        />
      </div>
    </section>
  );
};

export default observer(ContactGeneralSettingsForm);
