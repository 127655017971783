import { IMetersReading } from "@app/products/property/meters/[id]/components/child-screens/reading/model";
import { useMeterReadingsStore } from "@app/products/property/meters/[id]/components/child-screens/reading/store";
import { ConfirmReverseVoidReadingsDialog } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/reverse-void-readings/components/dialogs/confirm-reverse-void-readings/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const ReverseVoidButton = observer(() => {
  const [showConfirmDialog, setShowConfirmDialogs] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const { selectedReadings, reverseVoid } = useMeterReadingsStore();

  useEffect(() => {
    setIsDisabled(
      !selectedReadings?.some((item: IMetersReading) => item.IsVoid === true)
    );
  }, [selectedReadings]);

  return (
    <>
      <CCNavButton
        title="Reverse void"
        onClick={() => setShowConfirmDialogs(true)}
        disabled={isDisabled}
      />

      {showConfirmDialog && (
        <ConfirmReverseVoidReadingsDialog
          onClosePopup={() => {
            setShowConfirmDialogs(false);
          }}
          onConfirmYes={() => {
            reverseVoid();
            setShowConfirmDialogs(false);
          }}
        />
      )}
    </>
  );
});
