import { AnimalsRegister } from "@app/products/animals/model";

import { postIssueFeeBatch } from "@app/products/animals/renewals/requires-renewal-fee/components/api";
import { isSuccessResponse } from "@common/apis/util";

import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export interface IIssueRenewalFeesSubButtonProps {
  isAllRecords?: boolean;
  titleButton?: string;
  isVisible?: boolean;
  isDisabled?: boolean;
}

export const IssueRenewalFeesSubButton = observer(
  ({
    isVisible = true,
    isDisabled = false,
    titleButton = "All records",
    isAllRecords = true,
  }: IIssueRenewalFeesSubButtonProps) => {
    const {
      isEmptyGridSelectedRow,
      gridSelectedIds,
      setGridSelectedRows,
      gridData,
    } = useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const allIDs = useMemo(
      () => gridData.map((item: AnimalsRegister) => item.ID),
      [gridData]
    );

    const handleOnClick = async () => {
      setIsSubmitting(true);
      const response = await postIssueFeeBatch(
        isAllRecords ? allIDs : gridSelectedIds,
        isAllRecords
      );
      setIsSubmitting(false);

      if (isSuccessResponse(response)) {
        setGridSelectedRows([]);
      } else {
        pushNotification({
          autoClose: false,
          type: "error",
          description:
            response.data?.Errors ?? response.error ?? "The update failed",
        });
      }
    };

    // set button is invisible
    if (!isVisible) return null;
    // loading when call API
    if (isSubmitting) return <Loading isLoading={isSubmitting} isFullScreen />;

    return (
      <CCNavButton
        title={titleButton}
        disabled={(isEmptyGridSelectedRow && !isAllRecords) || isDisabled}
        onClick={handleOnClick}
      />
    );
  }
);
