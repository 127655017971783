import { isShowParkButton } from "@app/products/property/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  CRITERIA_FORM_STEP,
  CriteriaFormStep,
} from "./components/form-elements/criteria/_index";
import {
  RESULTS_FORM_STEP,
  ResultsFormStep,
} from "./components/form-elements/results/_index";
import {
  SEARCH_DETAILS_FORM_STEP,
  SearchDetailsFormStep,
} from "./components/form-elements/search-details/_index";
import { defaultCriteria, defaultSearchDetail } from "./config";

interface INewSearchDialog {
  onClose: () => void;
  onSubmit: (data: any) => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
}
const defaultTitle = "Advanced Search";

const steps: IStep[] = [
  {
    label: "Criteria",
    initialValues: defaultCriteria,
    component: CriteriaFormStep,
    visible: true,
    key: CRITERIA_FORM_STEP,
  },
  {
    label: "Search details",
    initialValues: defaultSearchDetail,
    component: SearchDetailsFormStep,
    visible: true,
    key: SEARCH_DETAILS_FORM_STEP,
  },
  {
    label: "Results",
    // initialValues: defaultProperties,
    component: ResultsFormStep,
    visible: true,
    key: RESULTS_FORM_STEP,
  },
];

export const NewSearchDialog = observer(
  ({
    onClose,
    onSubmit,
    isFromActionList = false,
    isIncompleteMode = false,
  }: INewSearchDialog) => {
    return (
      <CCFormStep
        onSubmit={onSubmit}
        initialSteps={steps}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="55%"
            maxHeight="70%"
            titleHeader={defaultTitle}
            onClose={onClose}
            bodyElement={renderProps.children}
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                {isShowParkButton(isFromActionList, isIncompleteMode) && (
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Park
                  </Button>
                )}
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Cancel
                </Button>
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                <Button
                  themeColor="primary"
                  id="cc-next-step-button"
                  disabled={renderProps.nextButton.disabled}
                  className={"cc-dialog-button"}
                  onClick={renderProps.nextButton.onClick}
                >
                  {renderProps.nextButton.label}
                </Button>
              </div>
            }
          />
        )}
      />
    );
  }
);
