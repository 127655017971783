import { DTO_Parcel_Titles } from "@app/products/property/parcels/[id]/components/child-screens/titles/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const getParcelTitles = async (
  parcelId: number
): Promise<DTO_Parcel_Titles | undefined> => {
  try {
    const response = await CoreAPIService.getClient().get<DTO_Parcel_Titles>(
      `api/property/internal/parcel/${parcelId}/titles`
    );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
