import { mockRegisterAccountsAffected } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/accounts/mock";
import { SearchField } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/accounts/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { sleep } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";

export const loadSearchAccount = async (
  filter: FilterDescriptor,
  register: IKeyValuePacket
) => {
  let accountList = mockRegisterAccountsAffected.map((account: any) => {
    return {
      ...account,
      [SearchField.AccountReference]: account.AccountReference,
      [SearchField.AccountId]: account.AccountId,
    };
  });

  if (register) {
    accountList = accountList.filter(
      (account) => account.Register === register.Value
    );
  }

  const data = accountList.slice();
  await sleep(1000);
  return filterBy(data, filter);
};
