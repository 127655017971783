import { getViewConfigurations } from "@app/products/property/api";
import { colRebatesDetailConcessionCardChild } from "@app/products/property/assessments/rebates/concession-cards/config";
import { VO_Assessment_RebateEntitlementEntity } from "@app/products/property/assessments/rebates/list/components/detail/model";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_Assessment_RebateEntitlementEntity>();

export const ConcessionCardsChild = ({ dataItem }: GridDetailRowProps) => {
  //State
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedColumns, setProcessedColumns] = useState<IColumnFields[]>(
    colRebatesDetailConcessionCardChild
  );

  const loadData = () => {
    setIsLoading(true);
    getViewConfigurations(
      ViewConfiguration.Assessment_RebateEntitlementEntities
    ).then((response) => {
      if (isSuccessResponse(response)) {
        const viewConfig: ICCViewColumn[] | undefined =
          response?.data?.ColumnDefinitions?.Columns;
        if (!viewConfig || viewConfig?.length === 0) {
          setProcessedColumns([]);
          setIsLoading(false);
          return;
        }
        setProcessedColumns(
          processDynamicColumns(colRebatesDetailConcessionCardChild, viewConfig)
        );
      } else {
        setResponseLoadError({
          status: response.status,
          error: response.error ?? "Load failed",
        });
      }
      setIsLoading(false);
    });
  };

  useEffectOnce(() => {
    loadData();
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <div className="cc-rebates-list-detail">
      <div className="cc-grid-control-between">
        <label className="cc-label">Associated contacts</label>
      </div>
      <CCGrid
        className="cc-associated-entities-grid"
        dataUrl={`/odata/property/internal/rebateentitlemententitiesregister/GetRebateEntitlementEntities?assessmentRebateEntitlementId=${dataItem.Assess_Rebate_Entitlement_Id}&$count=true&`}
        columnFields={processedColumns}
        primaryField={nameOf("Entity_Id")}
      />
    </div>
  );
};
