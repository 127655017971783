import { VO_Supplementary_Levy_Calculation } from "@app/products/property/supplementary-rates/[id]/components/child-screens/charges/model";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<VO_Supplementary_Levy_Calculation>();
export const colValuationDetail: IColumnFields[] = [
  {
    field: nameOf("Levy_Category"),
    title: "Levy Category",
  },
  {
    field: nameOf("Levy_Name"),
    title: "Levy Name",
  },
  {
    field: nameOf("Rating_Period"),
    title: "Rating Period",
  },
  {
    field: nameOf("SLC_ProRata_Diff_Base_Amount"),
    title: "Pro-Rata Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("SLC_ProRata_Diff_Base_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("SLC_ProRata_Diff_Gross_Amount"),
    title: "Pro-Rata Gross Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("SLC_ProRata_Diff_Gross_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("SLC_ProRata_Diff_Nett_Amount"),
    title: "Pro-Rata Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("SLC_ProRata_Diff_Nett_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("New_Percentage"),
    title: "New % of Rating Period",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("Old_Percentage"),
    title: "Old % of Rating Period",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("SLC_Diff_Gross_Amount"),
    title: "Difference Gross Amount",

    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("SLC_Diff_Gross_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("SLC_Diff_Nett_Amount"),
    title: "Difference Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("SLC_Diff_Nett_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("New_Valuation"),
    title: "New Valuation",

    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("New_Valuation"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("New_Base_Amount"),
    title: "New Base Amount",

    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("New_Base_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("New_RateByValue_Amount"),
    title: "New Rate by Valuation Amount",

    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("New_RateByValue_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("New_Gross_Amount"),
    title: "New Gross Amount",

    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("New_Gross_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("New_Nett_Amount"),
    title: "New Nett Amount",

    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("New_Nett_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Old_Valuation"),
    title: "Old Valuation",

    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("Old_Valuation"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Old_Base_Amount"),
    title: "Old Base Amount",

    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("Old_Base_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Old_RateByValue_Amount"),
    title: "Old Rate by Valuation Amount",

    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("Old_RateByValue_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Old_Gross_Amount"),
    title: "Old Gross Amount",

    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("Old_Gross_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Old_Nett_Amount"),
    title: "Old Nett Amount",

    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("Old_Nett_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Levy_Rate"),
    title: "Levy Rate",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Levy_Minimum_Amount"),
    title: "Levy Minimum Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Supplementary_Levy_Calc_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
