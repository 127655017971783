import { HistoryStackHelper } from "@components/cc-form/navigation/components/history-stack/util";
import { Action, Location } from "history";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
// Define the type for the context value

const HistoryStackContext = createContext<Location[]>([]);

interface IHistoryStackProviderProps {
  children: ReactNode;
}

export const HistoryStackProvider: React.FC<IHistoryStackProviderProps> = ({
  children,
}) => {
  const history = useHistory();
  const initialHistoryStack = HistoryStackHelper.getHistoryStackFromSession([
    history.location,
  ]);
  const [historyStack, setHistoryStack] =
    useState<Location[]>(initialHistoryStack);

  useEffect(() => {
    const unListenHistory = history.listen(
      (location: Location, action: Action) => {
        setHistoryStack((prevStack) => {
          let updatedStack;
          switch (action) {
            case "POP":
              updatedStack = prevStack.slice(0, prevStack.length - 1);
              break;
            case "PUSH":
              updatedStack = [...prevStack, location];
              break;
            case "REPLACE":
              updatedStack = [
                ...prevStack.slice(0, prevStack.length - 1),
                location,
              ];
              break;
            default:
              updatedStack = prevStack;
          }
          // Save the updated stack to sessionStorage
          HistoryStackHelper.setHistoryStackInSession(updatedStack);
          return updatedStack;
        });
      }
    );

    return () => {
      unListenHistory();
    };
  }, [history]);

  return (
    <HistoryStackContext.Provider value={historyStack}>
      {children}
    </HistoryStackContext.Provider>
  );
};

// Custom hook to use the HistoryStackContext
export const useHistoryStack = () => useContext(HistoryStackContext);
