import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colNoticeRun: IColumnFields[] = [
  {
    field: "Assessment",
    title: "Assessment (S-S-B-U)",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Assessment_Id}`;
    },
  },
  {
    field: "AccountNumber",
    title: "Account Number",
  },
  {
    field: "OwnerNameAddress",
    title: "Owner Name Address",
  },
  {
    field: "PrincipalBalance",
    title: "Principal Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "OpeningBalance",
    title: "Opening Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "PaymentsSinceLastNotice",
    title: "Payment Since Last Notice",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "InterestRaised",
    title: "Interest Raised",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "OtherCosts",
    title: "Other Costs",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "InterestBalance",
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "OtherBalance",
    title: "Other Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "BalanceOutstanding",
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "NoticeForNumber",
    title: "Notice for A/C Number",
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "DDSAccountId",
    title: "DDS Account ID",
    style: formatStyleUnRelevant,
  },
  {
    field: "NoticeId",
    title: "Notice ID",
    style: formatStyleUnRelevant,
  },
];
