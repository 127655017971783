import { useIsNew } from "@common/hooks/useIsNew";
import { DBRowState } from "@common/models/baseClassStandard";
import { useAppealHearingStore } from "@common/pages/appeal/_id/appeal-hearing/_id/store";
import { AppealSubmitActions } from "@common/pages/appeal/_id/model";
import { useAppealStore } from "@common/pages/appeal/_id/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const AdditionalHearingButton = observer(() => {
  const isNew = useIsNew();
  const { isLoading } = useAppealHearingStore();
  const { onSubmit, appeal } = useAppealStore();
  const isVisible = useMemo(() => {
    return (
      !isNew &&
      !appeal?.DecisionDate &&
      appeal?.Sys_DBRowState !== DBRowState.Archive
    );
  }, [appeal, isNew]);

  return isVisible ? (
    <CCNavButton
      title={"Additional Hearing"}
      isLoading={isLoading}
      disabled={isLoading}
      onClick={onSubmit}
      name={AppealSubmitActions.AdditionalHearing}
    />
  ) : null;
});
