import { PhoneMessageBatchClose } from "@app/products/crms/phone-messages/components/action-bar/buttons/close-events/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const postPhoneMessageBatchClose = async (
  phoneMessageBatchClose: PhoneMessageBatchClose
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `api/crms/internal/phone-message/batch-close`,
      phoneMessageBatchClose
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
