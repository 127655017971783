import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { EventAnimalsLookup } from "@app/products/crms/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<EventAnimalsLookup>();
export const colAnimalsLookup: IColumnFields[] = [
  {
    field: nameOf("Registration_Number"),
    title: "Registration Number",
    handleOnClick: (dataItem: EventAnimalsLookup) => {
      window.open(`${ANIMALS_ROUTE}/${dataItem.Registration_ID}`, "_blank");
    },
  },
  {
    field: nameOf("AnimalOwner"),
    title: "Animal Owner",
  },
  {
    field: nameOf("AnimalName"),
    title: "Name",
  },
  {
    field: nameOf("Breed"),
    title: "Breed",
  },
  {
    field: nameOf("TagNo"),
    title: "Tag Number",
  },
];
