import { ExistedCRMSSystemAminSubCategory } from "@app/products/crms/system-admin/categories/[id]/subcategory/[id]/existed/_index";
import { NewCRMSSystemAminSubCategory } from "@app/products/crms/system-admin/categories/[id]/subcategory/[id]/new/_index";
import { useCRMSSytemAdminSubCategoryStore } from "@app/products/crms/system-admin/categories/[id]/subcategory/[id]/store";
import { useIsNewSubcategory } from "@app/products/crms/system-admin/categories/[id]/subcategory/hooks/useIsNewSubcategory";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const ManageCRMSSystemAminSubCategory = observer(() => {
  const { subCategoryId } = useParams<{ subCategoryId: string }>();

  const isNew = useIsNewSubcategory();

  const { loadSubCategory, resetStore } = useCRMSSytemAdminSubCategoryStore();

  useEffect(() => {
    loadSubCategory(parseInt(subCategoryId), isNew);
    return () => {
      resetStore();
    };
  }, [subCategoryId, isNew, loadSubCategory, resetStore]);

  if (isNew) {
    return <NewCRMSSystemAminSubCategory />;
  }

  return <ExistedCRMSSystemAminSubCategory />;
});

export default ManageCRMSSystemAminSubCategory;
