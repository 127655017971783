import { wasteWaterRoute } from "@app/products/waste-water/route";
import { CCActionBarNavDropdown } from "@components/cc-list-view-action-bar/components/nav-dropdown/_index";
import React from "react";

interface IWasteWaterActionBarNavDropdownProps {
  category: string;
}

export const WasteWaterActionBarNavDropdown = ({
  category,
}: IWasteWaterActionBarNavDropdownProps) => {
  return (
    <CCActionBarNavDropdown
      category={category}
      product={wasteWaterRoute.path}
      route={wasteWaterRoute}
    />
  );
};
