import { AccordionRelated } from "@app/core/contacts/merge-contact/merge-contact-dialog/components/accordion-related/_index";
import { ContactTitle } from "@app/core/contacts/merge-contact/merge-contact-dialog/components/contact-title/_index";
import { UnMasterContactForm } from "@app/core/contacts/merge-contact/merge-contact-dialog/components/un-master-contact/components/form/_index";
import { useMergeContactStore } from "@app/core/contacts/merge-contact/store";
import { Contact } from "@common/models/contact";
import { CCPanel } from "@components/cc-panel/_index";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import "./_index.scss";
interface IUnMasterContactProps {
  contact: Contact;
}

export const UnMasterContact = observer(
  ({ contact }: IUnMasterContactProps) => {
    const {
      setMasterContact,
      setContacts,
      contacts,
      masterContact,
      isLoadingMergeContacts,
      isLoadingMergeAndEditContacts,
    } = useMergeContactStore();
    const [isExpanded, setIsExpanded] = useState(true);
    const title = useMemo(() => {
      return (
        <ContactTitle
          title={`${contact?.ContactClassification_Name ?? ""} - ${
            contact.DisplayName ? contact.DisplayName + " -" : ""
          } Used ${contact.ContactRoles_Count ?? 0} times`}
          isGolden={contact?.GoldenRecord}
        />
      );
    }, [contact]);

    const handleSetMaster = (event: any) => {
      event.stopPropagation();
      if (contacts && masterContact) {
        let newContacts = cloneDeep(contacts)?.filter((contactItem) => {
          return contactItem.Contact_ID !== contact.Contact_ID;
        });
        const masterContactClone = cloneDeep(masterContact);
        setContacts([masterContactClone, ...newContacts]);
        setMasterContact(cloneDeep(contact));
      }
    };
    return (
      <div className="cc-contact-panel">
        <CCPanel
          title={title}
          component={<UnMasterContactForm contact={contact} />}
          onToggle={() => {
            setIsExpanded(!isExpanded);
          }}
          isKeepMounted
          isExpanded={isExpanded}
          subTitle={
            <Button
              className="cc-nav-button"
              onClick={handleSetMaster}
              disabled={isLoadingMergeContacts || isLoadingMergeAndEditContacts}
            >
              Set Master
            </Button>
          }
        />
        <AccordionRelated idContact={contact.Contact_ID} />
      </div>
    );
  }
);
