import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colImportSchemeAccountResult: IColumnFields[] = [
  {
    field: "ImportId",
    title: "Import ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "AccountNumber",
    title: "Account Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "ImportedAssessmentNumber",
    title: "Imported Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "AssessmentId",
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "PropertyNameAddressLocality",
    title: "Property Name Address Locality",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "LegalDescription",
    title: "Legal Description",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Description",
    title: "Description",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Comment",
    title: "Comment",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "EstimatedShareOfCosts",
    title: "Estimated Share of Costs",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "ActualShareOfCosts",
    title: "Actual Share of Costs",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Coefficient",
    title: "Coefficient",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Size",
    title: "Size",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "NoOfUnits",
    title: "Number of Units",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Errors",
    title: "Errors",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
