import { SettingParentFolders } from "@app/products/property/system-admin/settings/components/breadcrumb/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

export type SettingsMap = {
  [key in ECorporateSettingsField]?: SettingField;
};
export interface ICategoryConfig {
  endPoint: string;
  product: string;
  name: string;
}

export enum SettingsDataType {
  String = "String",
  Int = "Int",
  Boolean = "Boolean",
  Date = "Date",
  Decimal = "Decimal",
  Lookup = "Lookup",
  List = "List",
  Label = "Label",
  Money = "Money",
  DateTime = "DateTime",
  Percent = "Percent",
  Button = "Button",
}
export interface SettingField {
  FieldName: string;
  Title: string;
  EditGroupId?: number;
  DisplayOrder: number;
  DataType: SettingsDataType;
  MaxLength?: number;
  IsMandatory?: boolean;
  IsVisible?: boolean;
  IsReadOnly?: boolean;
  Value?: any;
  KeyValueBlob?: string;
  Description?: string;
  MaxDecimalPlaces?: number;
  IsBigField?: boolean;
  IsReadOnlyOnEdit?: boolean;
  LookupName?: string;
  LookupItems?: any[];
  ListItems: SettingFolder;
  CorporateSettingsField_ENUM: ECorporateSettingsField;
  CorporateSettingsField_Int: ECorporateSettingsField;
  ValueDisplayDetails?: any[];
  HelpText?: string;
}

export interface SettingFolder {
  Name: string;
  Title: string;
  Description: string;
  ItemId: string;
  ProductType_Enum: string;
  LinkField?: string;
  SettingCategoryName: string;
  Mode: string;
  IsEditable: boolean;
  IsChildFolder?: boolean;
  IsActive?: boolean;
  IsNew?: boolean;
  IsSelected?: boolean;
  IsEnabled?: boolean;
  CanClone: boolean;
  CanDelete: boolean;
  UseIsActive: boolean;
  GridFields: SettingGridField[];
  EditGroups: SettingEditGroup[];
  SettingFields: SettingField[];
  ParentFolders: SettingParentFolders[];
  SettingsFolders: SettingFolder[];
  TitleColumnHeader: string;
  DescriptionColumnHeader: string;
}

interface SettingGridField {
  Name: string;
  Title: string;
  DataType: string;
  MaxLength?: number;
  Value: boolean | number | string;
}
export interface IGroupSettingField {
  groupId: number;
  name: string;
  settingFields: SettingField[];
  isEditing: boolean;
  status?: any;
}

export interface IListOption {
  CategoryName: string;
  ProductName: string;
  SettingName: string;
  LookupName: string;
}

export interface IListOptionValue {
  Id: string;
  Name: string;
  Checked?: boolean;
  IsActive?: boolean;
}

export interface ICollapseState {
  [key: number]: boolean;
}

export interface IDataListLookup {
  [key: string]: IListOptionValue[];
}

export interface ILookupItem {
  Id: string;
  Name: string;
}

export interface ISettingManagerForm {
  data: SettingFolder;
  isNew: boolean;
  lastID?: any;
}
export interface SettingParentFolder {
  Name: string;
  Title: string;
}
export interface ISubRootSetting {
  Name: string;
  Title: string;
  Description: string;
  ItemId: string;
  ProductType_Enum: string;
  SettingCategoryName: string;
  Mode: string;
  IsEditable: boolean;
  UseIsActive: boolean;
  EditGroups: SettingEditGroup[];
  ParentFolders: SettingParentFolder[];
  SettingFields: SettingField[];
}

export interface IListOptions {
  ProductName: string;
  CategoryName: string;
  SettingName: string;
  LookupName: string;
}
export interface SettingEditGroup {
  Name: string;
  GroupId: number;
}

export interface ISettingLookupItem {
  Id: string;
  Name: string;
  IsActive?: boolean;
}

export enum ETownPlanningSettingsCategory {
  //Numbering
  Numbering = "Numbering",
  Numbering_Application = "Numbering_Application",
  Numbering_Subdivision = "Numbering_Subdivision",
  Numbering_Enquiry = "Numbering_Enquiry",
  Numbering_NonCompliance = "Numbering_NonCompliance",
  Numbering_SecondaryConsent = "Numbering_SecondaryConsent",
  Numbering_ExtensionOfTime = "Numbering_ExtensionOfTime",
  Numbering_PSA = "Numbering_PSA",
  Numbering_OAs = "Numbering_OAs",
  Numbering_ComplyDevelopmentCertificate = "Numbering_ComplyDevelopmentCertificate",

  //Application
  Application = "Application",
  Application_Registers = "Application_Registers",
  Application_General = "Application_General",
  Application_MandatoryFields = "Application_MandatoryFields",
  Application_Workflow = "Application_Workflow",
  Application_PlanningOnlineMap = "Application_PlanningOnlineMap",
  Application_LandscapeInspectionsPermitAudits = "Application_LandscapeInspectionsPermitAudits",
  Application_Advertising = "Application_Advertising",
  Application_MailMerge = "Application_MailMerge",
  Application_CustomLabels = "Application_CustomLabels",
  Application_EPlanning = "Application_EPlanning",
  Application_Communication = "Application_Communication",

  //Registers
  Registers = "Registers",
  Registers_NonCompliance = "Registers_NonCompliance",
  Registers_General = "Registers_General",
  Registers_ExtensionOfTime = "Registers_ExtensionOfTime",
  Registers_SecondaryConsent = "Registers_SecondaryConsent",
  Registers_PlanningEnquiry = "Registers_PlanningEnquiry",
  Registers_Workflow = "Registers_Workflow",

  //Assessment
  Assessment = "Assessment",
  Assessment_General1 = "Assessment_General1",
  Assessment_General2 = "Assessment_General2",
  Assessment_ExtendedSettings = "Assessment_ExtendedSettings",
  Assessment_PrelimAssessment = "Assessment_PrelimAssessment",
  Assessment_Checklist = "Assessment_Checklist",

  //StatDays
  StatDays = "StatDays",
  StatDays_Settings = "StatDays_Settings",

  //Public_Portal
  PublicPortal = "PublicPortal",
  PublicPortal_TownPlanningSettings = "PublicPortal_TownPlanningSettings",
  PublicPortal_PortalSettings = "PublicPortal_PortalSettings",
  PublicPortal_PortalApplicationSettings = "PublicPortal_PortalApplicationSettings",
  PublicPortal_LiveMergeTemplates = "PublicPortal_LiveMergeTemplates",
  PublicPortal_DocumentSettings = "PublicPortal_DocumentSettings",
  PublicPortal_PortalSubmissionSettings = "PublicPortal_PortalSubmissionSettings",

  //PublicRegister
  PublicRegister = "PublicRegister",
  PublicRegister_RegisterSettings = "PublicRegister_RegisterSettings",
  PublicRegister_ExportRegisterSettings = "PublicRegister_ExportRegisterSettings",
  PublicRegister_RegisterStyleSettings = "PublicRegister_RegisterStyleSettings",

  //PPARS
  PPARS = "PPARS",
  PPARS_Settings = "PPARS_Settings",

  //PSA - PSAR
  PSA = "PSA",
  PSAR = "PSAR",
  PSA_PlanningSchemeAmendment = "PSA_PlanningSchemeAmendment",
  PSA_FieldSettings = "PSA_FieldSettings",
  PSA_CustomLabels = "PSA_CustomLabels",

  //OA's
  OAs = "OAs",
  OAs_Applications = "OAs_Applications",
  OAs_Views = "OAs_Views",

  //Certification
  Certification = "Certification",
  Certification_Certification = "Certification_Certification",
  Certification_CustomLabel = "Certification_CustomLabel",

  //SPEAR
  SPEAR = "SPEAR",
  SPEAR_Settings = "SPEAR_Settings",

  //PPR
  PPR = "PPR",
  PPR_PlanningPermitReferrals = "PPR_PlanningPermitReferrals",
  PPR_CustomLabel = "PPR_CustomLabel",

  //PublicPortalExternal
  EPlanningPortal = "EPlanningPortal",
  EPlanningPortal_NSWEPlanningPortal = "EPlanningPortal_NSWEPlanningPortal",

  //Records
  Records = "Records",
  Records_AttachmentTitleFormats = "Records_AttachmentTitleFormats",
  Records_RecordsManagementSettings = "Records_RecordsManagementSettings",
  Records_Application = "Records_Application",
  Records_TrimApplication = "Records_TrimApplication",
  Records_TrimAmendment = "Records_TrimAmendment",
  Records_TrimSubdivision = "Records_TrimSubdivision",
  Records_TrimEnquiry = "Records_TrimEnquiry",
  Records_TrimNonCompliance = "Records_TrimNonCompliance",
  Records_TrimSecondaryConsent = "Records_TrimSecondaryConsent",
  Records_TrimExtensionOfTime = "Records_TrimExtensionOfTime",
  Records_TrimPlanToComply = "Records_TrimPlanToComply",
  Records_TrimPSA = "Records_TrimPSA",
  Records_TrimOtherApplications = "Records_TrimOtherApplications",
  Records_TrimCustomFields = "Records_TrimCustomFields",
  Records_TrimEPlanning = "Records_TrimEPlanning",
  Records_InfoExpertApplication = "Records_InfoExpertApplication",
  Records_InfoExpertSubdivision = "Records_InfoExpertSubdivision",
  Records_InfoExpertEnquiry = "Records_InfoExpertEnquiry",
  Records_InfoExpertNonCompliance = "Records_InfoExpertNonCompliance",
  Records_InfoExpertSecondaryConsent = "Records_InfoExpertSecondaryConsent",
  Records_InfoExpertEOT = "Records_InfoExpertEOT",
  Records_InfoExpertOA = "Records_InfoExpertOA",
  Records_InfoExpertAmendments = "Records_InfoExpertAmendments",
  Records_ECM = "Records_ECM",
  Records_FileITApplication = "Records_FileITApplication",
  Records_FileITAmendment = "Records_FileITAmendment",
  Records_FileITPlansToComply = "Records_FileITPlanToComply",
  Records_FileITPlanningEnquiry = "Records_FileITPlanningEnquiry",
  Records_SPContainerParts = "Records_SPContainerParts",
  Records_SPApplication = "Records_SPApplication",
  Records_SPSubDivision = "Records_SPSubDivision",
  Records_SPEnquiry = "Records_SPEnquiry",
  Records_SPNonCompliance = "Records_SPNonCompliance",
  Records_SPSecondaryConsent = "Records_SPSecondaryConsent",
  Records_SPExtensionOfTime = "Records_SPExtensionOfTime",
  Records_SPPlansToComply = "Records_SPPlansToComply",
  Records_CustomRecordsField = "Records_CustomRecordsField",
  Records_Amendment = "Records_Amendment",
  Records_PlanToComply = "Records_PlanToComply",
  Records_PlanningEnquiry = "Records_PlanningEnquiry",
}

export const CC_SETTINGS_SEQCONTROL = "SYSENUM_";
