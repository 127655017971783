import { DTO_Association } from "@app/products/property/assessments/[id]/components/child-screens/associate-assessment/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<DTO_Association>();

export const associateAssessmentCol: IColumnFields[] = [
  {
    field: nameOf("AssessmentNumber"),
    title: "Assessment Number",
  },
  {
    field: nameOf("Association"),
    title: "Association",
  },
  { field: nameOf("AssessmentReference"), title: "Assessment Reference" },
  {
    field: nameOf("PropertyName_Address_Locality"),
    title: "Property Name Address Locality",
  },
  { field: nameOf("RatepayerName"), title: "Ratepayer Name" },
  { field: nameOf("LegalDescription"), title: "Legal Description" },
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("BalanceOutstanding"),
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("BeingPostponed"),
    title: "Being Postponed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: nameOf("AssessmentGroup"), title: "Assessment Group" },
  { field: nameOf("ValuationGroup"), title: "Valuation Group" },
  { field: nameOf("Type"), title: "Type" },
  { field: nameOf("Status"), title: "Status" },
  { field: nameOf("LandUses"), title: "Land Uses" },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
