import { EWatchStatusLoading } from "@common/stores/route/model";
import { useRouteDataStore } from "@common/stores/route/store";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { ReactElement, useEffect } from "react";
import { useEffectOnce } from "react-use";

interface ILoadingPermissionWrapperProps {
  isLoadingPermission: boolean;
  children: ReactElement;
}

export const LoadingPermissionWrapper = observer(
  ({ isLoadingPermission, children }: ILoadingPermissionWrapperProps) => {
    const { isApiCallDone, setWatchStatusApiCallDone } = useRouteDataStore();
    const { listenNotificationPortal } = useNotificationPortalStore();

    useEffectOnce(() => {
      setWatchStatusApiCallDone([
        EWatchStatusLoading.IsLoadingGrid,
        EWatchStatusLoading.IsLoadingPermission,
      ]);
    });
    useEffect(() => {
      // Show notifications after all of the APIs are called done
      isApiCallDone() && listenNotificationPortal();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isApiCallDone()]);

    return (
      <>
        <Loading isFullScreen isLoading={isLoadingPermission} />
        {children}
      </>
    );
  }
);
