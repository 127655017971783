import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";

export const NewIncidentButton = () => {
  return (
    <React.Fragment>
      <CCNavButton
        title="New Incident"
        // onClick={() => {
        //   history.push(`${PREMISES_ROUTE.MANAGE_ROUTE}/new`);
        // }}
      />
    </React.Fragment>
  );
};
