import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getAssetVersionHelptext = async (): Promise<
  APIResponse<string | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<string | undefined>(
      "/api/core/internal/settings/assetic-version/helptext"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAssetVersion = async (
  username: string,
  password: string,
  url: string
): Promise<APIResponse<string | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<string | undefined>(
      "/api/core/internal/settings/assetic-version",
      {
        params: {
          username,
          password,
          url,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
