import { MailMerge_SelectMergeDocument } from "@app/core/new-mail-merge/dialogs/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";
import "./_index.scss";

export const MAIL_MERGE_OPTIONS_FORM_STEP = "Options";

export const OptionsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOf = nameOfFactory<MailMerge_SelectMergeDocument>();

const FormStepElement = ({ formRenderProps }: IFormStepElement) => {
  const { valueGetter } = formRenderProps;
  const { settings } = useCommonCoreStore();

  const enableExternalDocument = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableExternalDocument]
  );

  const publishEnabled = false; //TODO: Load AppSettings["OpenOffice.EnablePortalPublish"]

  return (
    <div className="cc-mail-merge-selection-container">
      <div className="cc-field-group">
        <div className="cc-form-cols-4 cc-switch-options-step">
          {valueGetter(nameOf("ShowAppendToRecordSourceOption")) && (
            <div className="cc-field">
              <label className="cc-label">Attach to each record</label>
              <Field
                name={nameOf("AppendToRecordSource")}
                checked={valueGetter(nameOf("AppendToRecordSource"))}
                disabled={!valueGetter(nameOf("AllowAppendOptionEditing"))}
                component={CCSwitch}
              />
            </div>
          )}
          {valueGetter(nameOf("ShowCollateOption")) && (
            <div className="cc-field">
              <label className="cc-label">Open document after merge</label>
              <Field
                name={nameOf("Collate")}
                checked={valueGetter(nameOf("Collate"))}
                disabled={!valueGetter(nameOf("AllowCollateOptionEdititng"))}
                component={CCSwitch}
              />
            </div>
          )}
          {valueGetter(nameOf("ShowBatchPrintOption")) && (
            <div className="cc-field">
              <label className="cc-label">Provide copy for printing</label>
              <Field
                name={nameOf("BatchPrint")}
                checked={valueGetter(nameOf("BatchPrint"))}
                disabled={!valueGetter(nameOf("AllowBatchPrintOptionEditing"))}
                component={CCSwitch}
              />
            </div>
          )}
          {valueGetter(nameOf("ShowSaveFormatOption")) && (
            <div className="cc-field">
              <label className="cc-label">Save as PDF</label>
              <Field
                name={"_options.SaveAsPDF"}
                checked={valueGetter("_options.SaveAsPDF")}
                disabled={!valueGetter(nameOf("AllowSaveFormatOptionEditing"))}
                component={CCSwitch}
              />
            </div>
          )}
          {valueGetter(nameOf("ShowPublishDocumentOption")) &&
            publishEnabled && (
              <div className="cc-field">
                <label className="cc-label">Publish document</label>
                <Field
                  name={nameOf("PublishDocument")}
                  checked={valueGetter(nameOf("PublishDocument"))}
                  disabled={
                    !valueGetter(nameOf("AllowPublishDocumentOptionEditing"))
                  }
                  component={CCSwitch}
                />
              </div>
            )}
          {valueGetter(nameOf("ShowUsePreferredMethod")) && (
            <div className="cc-field">
              <label className="cc-label">Use preferred method</label>
              <Field
                name={nameOf("UsePreferredMethod")}
                checked={valueGetter(nameOf("UsePreferredMethod"))}
                disabled={!valueGetter(nameOf("AllowUsePreferredMethod"))}
                component={CCSwitch}
              />
            </div>
          )}
          {valueGetter(nameOf("ShowExternalDocumentOption")) &&
            enableExternalDocument && (
              <div className="cc-field">
                <label className="cc-label">External document</label>
                <Field
                  name={nameOf("ExternalDocument")}
                  checked={valueGetter(nameOf("ExternalDocument"))}
                  disabled={
                    !valueGetter(nameOf("AllowExternalDocumentOptionEditing"))
                  }
                  component={CCSwitch}
                />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
