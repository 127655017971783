import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";

interface IAnimalsSettingManageFormActionBarProps {
  isShowSlideBar: boolean;
  onClick: () => void;
}
export const AnimalsSettingManageFormActionBar = ({
  isShowSlideBar = false,
  onClick,
}: IAnimalsSettingManageFormActionBarProps) => {
  return (
    <CCManagePageActionBar
      rightActions={[
        <CCNavButton
          title={"Slide Bar"}
          iconClass="fal fa-info-circle"
          isActive={isShowSlideBar}
          onClick={onClick}
        />,
      ]}
    />
  );
};
