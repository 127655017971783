import { IFirePreventionWorkOrder } from "@app/products/property/fire-prevention/current/work-orders/model";

export const mockWorkOrders: IFirePreventionWorkOrder[] = [
  {
    WorkOrderId: 9,
    AssignedContractor: "<To be Assigned>",
    Clearance: "Clear Entire Property",
    ToBeClearedBy: new Date("27-Sep-2018"),
    Locality: "Lenah Valley",
    Street: "Alcides",
    HouseNumber: "25",
    Owners: "M J Zawadzki && Z Zawadzki",
    CreatedOn: new Date("27-Sep-2018 14:17"),
    CreatedBy: "wisem",
    Period: "2021/2022",
    AssessmentNumber: 5478388,
    ParcelId: 112965,
    CaseId: 28,
  },
  {
    WorkOrderId: 8,
    AssignedContractor: "Mark Steven",
    Clearance: "Clear Entire Property",
    ToBeClearedBy: new Date("19-Sep-2018"),
    Locality: "West Hobart",
    Street: "Stevens Farm",
    HouseNumber: "9",
    Owners: "S W Hennessy & C J Hennessy",
    CreatedOn: new Date("19-Sep-2018 13:24"),
    CreatedBy: "wisem",
    Period: "2021/2022",
    AssessmentNumber: 3378113,
    ParcelId: 255050,
    CaseId: 13,
  },
  {
    WorkOrderId: 7,
    AssignedContractor: "Nick Paine",
    Clearance: "Remove All Undergrowth",
    ToBeClearedBy: new Date("25-Sep-2018"),
    Locality: "Sandy Bay",
    Street: "Churchill",
    HouseNumber: "254",
    Owners: "L Zheng",
    CreatedOn: new Date("10-Sep-2018 17:17"),
    CreatedBy: "wisem",
    Period: "2021/2022",
    AssessmentNumber: 5605797,
    ParcelId: 171300,
    CaseId: 22,
  },
];
