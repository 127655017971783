export const myActivityAddressDisplayModelData = [
  {
    label: "Application site address",
    value: true,
  },
  {
    label: "My preferred address",
    value: false,
  },
];
