import { ElementDisplayStatus } from "@app/products/crms/[id]/model";
import { KeyValuePacketWithStatus } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import React, { Fragment } from "react";
import "./_index.scss";

export interface ICloneNewEventDialog {
  onClosePopup: () => void;
  initData?: any;
  handleSubmit?: ((event: FormSubmitClickEvent) => void) | undefined;
  optionList?: KeyValuePacketWithStatus[];
  optionListEnable?: KeyValuePacketWithStatus[];
}

export const CloneNewEventDialog = ({
  onClosePopup,
  handleSubmit = () => {},
  initData = {},
  optionList,
  optionListEnable,
}: ICloneNewEventDialog) => {
  return (
    <Form
      initialValues={initData}
      key={JSON.stringify(initData)}
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => {
        const { onSubmit, onChange, valueGetter } = formRenderProps;
        const changeSelectAllHandler = (value: boolean) => {
          optionListEnable?.forEach((option) => {
            onChange(option.Key.toString(), { value: value });
          });
        };
        return (
          <FormElement>
            <CCDialog
              dialogWrapperClassName="cc-clone-new-event-dialog"
              maxWidth="35%"
              onClose={() => onClosePopup()}
              titleHeader="Select the item you wish to copy to the new event"
              bodyElement={
                <div className="cc-form">
                  <div className="cc-field-group">
                    <div className="cc-form-cols-3">
                      {optionList &&
                        optionList.map((item: KeyValuePacketWithStatus) => (
                          <div className="cc-field">
                            <CCLabel title={item.Value} />
                            <Field
                              name={item.Key.toString()}
                              component={CCSwitch}
                              checked={valueGetter(item.Key.toString())}
                              onChange={(e: SwitchChangeEvent) => {
                                onChange(item.Key.toString(), {
                                  value: e.value,
                                });
                              }}
                              disabled={
                                item.DisplayStatus ===
                                ElementDisplayStatus.Disable
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              }
              footerElement={
                <Fragment>
                  <div className="cc-dialog-footer-actions-right">
                    <Button
                      className={"cc-dialog-button"}
                      type="button"
                      onClick={() => {
                        changeSelectAllHandler(false);
                      }}
                    >
                      Deselect
                    </Button>
                    <Button
                      className="cc-dialog-button"
                      type="button"
                      themeColor="primary"
                      onClick={() => {
                        changeSelectAllHandler(true);
                      }}
                    >
                      Select All
                    </Button>
                    <Button
                      className="cc-dialog-button"
                      type="button"
                      onClick={() => {
                        onClosePopup();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      type="submit"
                      onClick={onSubmit}
                    >
                      OK
                    </Button>
                  </div>
                </Fragment>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
