import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSSystemAdminDocuments: IColumnFields[] = [
  {
    field: CRSFieldMapping.Name,
    title: "Document Name",

    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: CRSFieldMapping.Document_Usage_Name,
    title: "Document Usage",
  },
  { field: CRSFieldMapping.Dataset_Name, title: "Data" },
  { field: CRSFieldMapping.SaveFormat, title: "Save Format" },
  { field: CRSFieldMapping.WhenLoaded, title: "Loaded On" },
  { field: CRSFieldMapping.IsActive, title: "Active?" },
  {
    field: CRSFieldMapping.AdditionalInformation,
    title: "Additional Information",
  },
];
