import { buildingRoute } from "@app/products/building/route";
import { CCActionBarNavDropdown } from "@components/cc-list-view-action-bar/components/nav-dropdown/_index";
import React from "react";

interface IBuildingActionBarNavDropdownProps {
  category: string;
}

export const BuildingActionBarNavDropdown = ({
  category,
}: IBuildingActionBarNavDropdownProps) => {
  return (
    <CCActionBarNavDropdown
      category={category}
      product={buildingRoute.path}
      route={buildingRoute}
    />
  );
};
