import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SecurityOrgStructureForm } from "@common/pages/settings/security/org-structure/_id/components/child-screens/general/_index";
import { SecurityOrgStructureTabDetails } from "@common/pages/settings/security/org-structure/_id/components/reference-sidebar/detail/_index";
import { SecurityOrgStructureHistoryTab } from "@common/pages/settings/security/org-structure/_id/components/reference-sidebar/history/_index";
import { SecurityOrgStructureSubmitActions } from "@common/pages/settings/security/org-structure/_id/model";
import { useOrgStructureStore } from "@common/pages/settings/security/org-structure/_id/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedSecurityOrgStructure = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const {
    orgUnit,
    isLoading,
    responseLoadError,
    loadOrgUnit,
    orgUnitId,
    onSubmit,
  } = useOrgStructureStore();

  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        orgUnit?.OrgUnit_ID,
        orgUnit?.OrgUnit_Name,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Security - Org Structure - ${orgUnit?.OrgUnit_ID ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_OrgUnit,
      Record_ID: orgUnitId ?? 0,
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Org Structure"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadOrgUnit(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={orgUnit?.OrgUnit_Name || ""} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                name={SecurityOrgStructureSubmitActions.Save}
                onClick={onSubmit}
              />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {orgUnit && <SecurityOrgStructureForm />}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <SecurityOrgStructureTabDetails />,
                    },
                    {
                      title: "History",
                      component: <SecurityOrgStructureHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
