import { BuildingFieldMapping } from "@app/products/building/model";
import { CERTIFICATES_MANAGE_ROUTE } from "@app/products/building/registers/certificates/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingRegistersCertificates: IColumnFields[] = [
  {
    field: BuildingFieldMapping.Register_Number,
    title: "Number",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${CERTIFICATES_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.Date_Lodged,
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Address,
    title: "Address",
  },
  {
    field: BuildingFieldMapping.Locality,
    title: "Locality",
  },
  {
    field: BuildingFieldMapping.LegalDescription,
    title: "Legal Description",
  },
  {
    field: BuildingFieldMapping.Contact,
    title: "Contact",
  },
  {
    field: BuildingFieldMapping.Description,
    title: "Description",
  },
];
