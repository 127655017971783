import { FIRequestTabDetails } from "@app/core/further-info/[id]/components/reference-sidebar/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Svc_BubbleUpDetails } from "@common/pages/actions/[id]/components/reference-sidebar/details/model";

export const getFurtherInfoDetailsTabById = async (
  id: number
): Promise<APIResponse<FIRequestTabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<FIRequestTabDetails>(
      `/api/core/internal/firequest/${id}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getFIRequestBubbleupDetails = async (
  id: number
): Promise<APIResponse<Svc_BubbleUpDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/core/internal/firequest/${id}/bubbleup`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetFIRequestDetailTabResponse =
  | [
      APIResponse<FIRequestTabDetails | undefined>,
      APIResponse<Svc_BubbleUpDetails | undefined>
    ]
  | APIResponse<FIRequestTabDetails | Svc_BubbleUpDetails>
  | undefined;

export const getFIRequestDetailTab = async (
  id: number
): Promise<IGetFIRequestDetailTabResponse> => {
  try {
    return await Promise.all([
      getFurtherInfoDetailsTabById(id),
      getFIRequestBubbleupDetails(id),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
