import { LOCAL_LAWS_SYSTEM_ADMIN_PERMIT_TYPES_ROUTE } from "@app/products/local-laws/system-admin/permit-types/[id]/constant";
import { Svc_LLPermitType } from "@app/products/local-laws/system-admin/permit-types/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_LLPermitType>();
export const colLocalLawsSystemAdminPermitTypes: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    linkTo: (dataItem) => ({
      pathname: `${LOCAL_LAWS_SYSTEM_ADMIN_PERMIT_TYPES_ROUTE}/` + dataItem.ID,
    }),
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
];
