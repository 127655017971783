import { SaveRecallButton } from "@app/core/recall/[id]/components/button/save";
import { RecallForm } from "@app/core/recall/[id]/components/general/_index";
import { TitleRecallManagePage } from "@app/core/recall/[id]/constant";
import { Recall } from "@app/core/recall/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewManageRecall = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const recall = dataForms?.GeneralForm as Recall;

  return (
    <>
      <FormTitle title={`New ${TitleRecallManagePage}`} />
      <CCManagePageActionBar leftActions={[<SaveRecallButton />]} />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">{recall && <RecallForm />}</div>
        </div>
      </div>
    </>
  );
});
