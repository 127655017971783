import { saveAs } from "@progress/kendo-file-saver";

export const DownloadFile = (
  data: string,
  contentType: string,
  fileName: string
) => {
  const dataURI = `data:${contentType};base64,${data}`;
  saveAs(dataURI, fileName);
};
