import { history } from "@/AppRoutes";
import { CSL_SYSTEM_ADMIN_CATEGORIES_ROUTE } from "@app/products/csl/system-admin/categories/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
export const NewCSLCategoriesButton = () => {
  return (
    <>
      <CCNavButton
        title="New CSL Categories"
        onClick={() => history.push(`${CSL_SYSTEM_ADMIN_CATEGORIES_ROUTE}/new`)}
      />
    </>
  );
};
