import { useComplianceDocumentsStore } from "@app/products/property/assessments/compliance/actions-and-letters/all-documents/all/store";
import { IPopulateDocumentsDialog } from "@app/products/property/assessments/compliance/actions-and-letters/components/action-bar/dialog/populate-documents/model";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
    Field,
    Form,
    FormElement,
    FormRenderProps,
    FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import "./_index.scss";

const nameOf = nameOfFactory<IPopulateDocumentsDialog>();

interface IDocumentIssuedDialog {
  onClose: () => void;
}

export const DocumentIssuedDialog = observer(
  ({ onClose }: IDocumentIssuedDialog) => {
    const { populateDocumentsData, loadDocuments } =
      useComplianceDocumentsStore();

    const initialValues = useMemo(() => {
      return populateDocumentsData ? cloneDeep(populateDocumentsData) : {};
    }, [populateDocumentsData]);

    const handleSubmit = (values: IPopulateDocumentsDialog) => {
      loadDocuments(values?.IssuedFrom, values?.IssuedTo);
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmitClick={(event: FormSubmitClickEvent) =>
          handleSubmit(event.values)
        }
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter } = formRenderProps;
          return (
            <CCDialog
              titleHeader={"Compliance Documents Selection Criteria"}
              onClose={onClose}
              maxHeight="25%"
              maxWidth="40%"
              bodyElement={
                <div className="cc-form">
                  <FormElement className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <section className="cc-field-group">
                        <label className="cc-label">Issued date</label>
                        <div className="cc-custom-sub-panel-bar">
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">From</label>
                              <Field
                                name={nameOf("IssuedFrom")}
                                component={CCDatePicker}
                                max={
                                  !valueGetter("IssuedTo")
                                    ? new Date()
                                    : new Date(valueGetter("IssuedTo"))
                                }
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">To</label>
                              <Field
                                name={nameOf("IssuedTo")}
                                component={CCDatePicker}
                                min={
                                  !valueGetter("IssuedFrom")
                                    ? undefined
                                    : new Date(valueGetter("IssuedFrom"))
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </FormElement>
                </div>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={
                      !formRenderProps.modified || !formRenderProps.valid
                    }
                    onClick={formRenderProps.onSubmit}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
