import { LOCATION_CACHE, REMEMBER_ACCOUNT } from "@common/pages/login/constant";
import { ILogin } from "@common/pages/login/model";
import { aesDecrypt, aesEncrypt } from "@common/utils/cryptography";

export const getSessionRedirectURL = () => {
  const locationCache = sessionStorage.getItem(LOCATION_CACHE);
  if (!locationCache) return;
  return JSON.parse(locationCache);
};

export const setSessionRedirectURL = () => {
  if (!getSessionRedirectURL())
    sessionStorage.setItem(
      LOCATION_CACHE,
      JSON.stringify(
        `${window.location.pathname}${window.location.search}${window.location.hash}`
      )
    );
};

export const removeSessionRedirectURL = () => {
  sessionStorage.removeItem(LOCATION_CACHE);
};

export const getRememberAccount = (): ILogin | undefined => {
  const locationCache = localStorage.getItem(REMEMBER_ACCOUNT);
  if (!locationCache) return;
  return JSON.parse(aesDecrypt(locationCache));
};

export const setRememberAccount = (
  data: Omit<ILogin, "password"> | undefined
) => {
  if (!data) return localStorage.removeItem(REMEMBER_ACCOUNT);
  localStorage.setItem(REMEMBER_ACCOUNT, aesEncrypt(JSON.stringify(data)));
};
