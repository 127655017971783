import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colParkingPermitsSystemAdminResidentArea: IColumnFields[] = [
  {
    field: ParkingPermitsFieldMapping.ResidentAreaCode,
    title: "Area",
    width: 150,
    locked: true,
    linkTo: (dataItem: any) => {
      return `${PARKING_PERMITS_ROUTE}/${dataItem.ID}`;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.PermitRenewalDate,
    title: "Expiry Date",
    width: 150,

    format: DATE_FORMAT.DATE,
  },
  {
    field: ParkingPermitsFieldMapping.MaximumPermits,
    title: "Maximum Permits",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.NoOfFreePermits,
    title: "Number Free",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.NoOfCost1,
    title: "Number at Cost 1",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.Cost1,
    title: "Cost 1",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.NoOfCost2,
    title: "Number at Cost 2",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: ParkingPermitsFieldMapping.Cost2, title: "Cost 2", width: 150 },
  {
    field: ParkingPermitsFieldMapping.NoOfCost3,
    title: "Number at Cost 3",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.Cost3,
    title: "Cost 3",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.AllowableSuburbs,
    title: "Suburbs",
    width: 150,
  },
  {
    field: ParkingPermitsFieldMapping.AreaBoundaries,
    title: "Boundaries",
    width: 150,
  },
];
