import { AnimalsApiUrl } from "@app/products/animals/config";
import { AnimalsRegister } from "@app/products/animals/model";
import { animalsLookupList } from "@app/products/crms/components/dialogs/pick-animals-lookup/config";
import { getUrlSearchInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/config";
import { useDebounce } from "@common/hooks/useDebounce";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import React, { useState } from "react";

const nameOf = nameOfFactory<AnimalsRegister>();
export interface PickAnimalsLookupProps {
  onCloseDialog: () => void;
  onSubmit: (data: AnimalsRegister[]) => void;
  selectedAnimalsLookup?: AnimalsRegister[];
}

export const PickAnimalsLookupDialog = ({
  onCloseDialog,
  onSubmit,
  selectedAnimalsLookup,
}: PickAnimalsLookupProps) => {
  const [searchKey, setSearchKey] = useState("");
  const [gridSelectedRows, setGridSelectedRows] = useState<AnimalsRegister[]>(
    selectedAnimalsLookup ?? []
  );

  const debouncedSearch = useDebounce(searchKey, 500);

  return (
    <CCDialog
      titleHeader="Animals"
      dialogWrapperClassName="cc-officer-picker-search"
      maxWidth="60%"
      maxHeight="80%"
      onClose={onCloseDialog}
      bodyElement={
        <div className="cc-search-result">
          <div className="cc-search-result-title">
            <CCLabel title="Search" />
            <Field
              name={"searchKey"}
              component={CCInput}
              placeholder="Search"
              onChange={(e: InputChangeEvent) => {
                setSearchKey(e.value);
              }}
            />
          </div>
          <div className="cc-search-result-body">
            <CCGrid
              columnFields={animalsLookupList}
              primaryField={nameOf("ID")}
              selectableMode={"multiple"}
              itemPerPage={15}
              dataUrl={
                AnimalsApiUrl.GET_VIEW_ANIMALS_REGISTER_ALL
                  ? debouncedSearch
                    ? getUrlSearchInputPicker(
                        debouncedSearch,
                        nameOf("RegistrationNumber"),
                        AnimalsApiUrl.GET_VIEW_ANIMALS_REGISTER_ALL
                      )
                    : AnimalsApiUrl.GET_VIEW_ANIMALS_REGISTER_ALL
                  : undefined
              }
              onSelectionChange={(dataItems) => {
                setGridSelectedRows(dataItems);
              }}
              selectedRows={gridSelectedRows}
            />
          </div>
        </div>
      }
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Button
            className="cc-dialog-button"
            themeColor="primary"
            disabled={gridSelectedRows.length === 0}
            onClick={() => onSubmit(gridSelectedRows)}
          >
            Select
          </Button>
        </div>
      }
    />
  );
};
