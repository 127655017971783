import { eventEmitter } from "@/App";
import { Attachment } from "@app/core/documents/model";
import { ImportDialog } from "@app/core/import/dialogs/_index";
import { isSuccessResponse } from "@common/apis/util";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { postImportFeeTypeList } from "@common/pages/settings/lookups/fee-types/api";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const importDescriptionContent = (
  <>
    <div>Instructions for import : Fee type</div>
    <ul className="cc-note-import">
      <li>
        The import file must be a Excel Workbook File (xlsx) with the following.
      </li>
      <li>
        FeeType_ID, Name, Description, Code, Category, GST Code, Council_ID,
        DefaultAmount_ExGST, Next Effective Date, NextAmount_ExGST,
        Flag_GSTApplies, Flag_CalculatedFee, DefaultAmount_GSTPercentage,
        NextAmount_GSTPercentage, SystemFilter_ENUMS, SystemFilter_Names,
        Sys_DBRowState, Fee, Refund, Flag_AllowZeroAmount, Organisation Code,
        Bond, BondType.
      </li>
      <li>The first row must contain the column headers.</li>
      <li>'Save' button will import all rows in the file.</li>
    </ul>
  </>
);

interface IFeeTypesImportButtonProps {
  gridId?: string;
}

export const FeeTypesImportButton = observer(
  ({ gridId }: IFeeTypesImportButtonProps) => {
    const { cancelToken } = useCancelRequest();
    const { pushNotification } = useCCAppNotificationStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowAttachmentDialog, setIsShowAttachmentDialog] = useState(false);

    const handleOnSubmit = async (
      data: Attachment,
      notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
    ) => {
      setIsLoading(true);
      const response = await postImportFeeTypeList(data, cancelToken());
      setIsLoading(false);
      if (isSuccessResponse(response)) {
        setIsShowAttachmentDialog(false);
        eventEmitter.emit(CCGridEventType.RefreshOData, {
          gridIds: gridId ? [gridId] : [],
        });
        pushNotification({
          title: "Items imported successfully",
          type: "success",
        });
      } else {
        notificationRef?.current?.resetNotifications();
        notificationRef?.current?.pushNotification({
          title: "Import items failed",
          type: "error",
          autoClose: false,
          description: response.data?.Errors ?? response?.error,
        });
      }
    };

    return (
      <>
        <CCNavButton
          title="Import new fee types"
          onClick={() => setIsShowAttachmentDialog(true)}
          isLoading={isLoading}
        />
        {isShowAttachmentDialog && (
          <ImportDialog
            onClose={() => setIsShowAttachmentDialog(false)}
            onSubmit={handleOnSubmit}
            isLoadingButton={isLoading}
            primaryImportFieldName="FeeType_ID"
            description={importDescriptionContent}
          />
        )}
      </>
    );
  }
);
