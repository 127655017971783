import { useAssessmentTransferStore } from "@app/products/property/assessments/[id]/components/child-screens/transfer/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { cancelChangeOfOwnership } from "@app/products/property/changes-of-ownership/list/components/dialogs/cancel_owner_change/api";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNote } from "@components/cc-note/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const CancelOwnerChange = observer(
  ({ onClose, changeOfOwnershipID }: any) => {
    const { assessmentId } = useAssessmentStore();
    const { reLoadTransfer } = useAssessmentTransferStore();
    const { pushNotification } = useCCAppNotificationStore();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
      setIsLoading(true);
      const response = await cancelChangeOfOwnership(changeOfOwnershipID);
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        reLoadTransfer(assessmentId);
        pushNotification({
          title:
            response?.data?.SuccessMessage ??
            "Change of ownership was cancelled successfully.",
          type: "success",
        });
      } else {
        pushNotification({
          title:
            response?.data?.ErrorMessage ??
            "Change of ownership could not be cancelled.",
          type: "error",
          autoClose: false,
        });
      }
      setIsLoading(false);
      onClose();
    };
    return (
      <>
        <ConfirmDialog
          width={"35%"}
          height={"auto"}
          title={"Confirm Cancellation"}
          message={
            "Are you sure you wish to cancel the selected Change of Ownership entries?"
          }
          subMessage={
            <>
              <p>
                If this is the last Change of Ownership change for an Assessment
                then the Assessment will revert to its previous name and
                addresses. If there is a corresponding Transfer Fee that is in a
                Journal yet to be authorised, the Transfer Fee will be
                cancelled, otherwise the transfer fee will need to be manually
                reversed.
              </p>
              <CCNote
                message={`Once cancelled the Change of Ownership cannot be uncancelled`}
              />
            </>
          }
          onClosePopup={() => {
            onClose();
          }}
          onAsyncConfirm={() => {
            handleSubmit();
          }}
          isLoadingYes={isLoading}
        />
      </>
    );
  }
);
