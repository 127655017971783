import { reopenEvent } from "@app/products/crms/[id]/api";
import { Event } from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { CRMSCommentDialog } from "@app/products/crms/components/dialogs/comment/_index";
import { isSuccessResponse } from "@common/apis/util";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { FormSubmitClickEvent } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

interface IReopenProps {
  disabled?: boolean;
  isHidden?: boolean;
}

export const ReopenButton = observer(
  ({ disabled = false, isHidden = false }: IReopenProps) => {
    const { id } = useParams<{ id: string }>();
    const isNew = useIsNew();

    const { pushNotification } = useCCAppNotificationStore();

    const { crmsEvent, setIsLoading, loadCRMSEvent, setNotification } =
      useCRMSEventStore();
    const [isShowDialog, setIsShowDialog] = useState(false);

    if (isHidden) {
      return null;
    }

    const handleIReopenEvent = async (value: string) => {
      if (!crmsEvent) return;
      setIsLoading(true);
      const data: Event = { ...crmsEvent, ReopenReason: value };

      const response = await reopenEvent(data);
      setIsLoading(false);
      if (isSuccessResponse(response)) {
        setNotification({
          title: "Event successfully re-opened.",
          type: "success",
        });
        loadCRMSEvent(parseInt(id), isNew);
      } else {
        pushNotification({
          autoClose: false,
          title: "Event could not be re-openned.",
          description: response.data?.Errors,
          type: "error",
        });
      }
    };

    return (
      <>
        <CCNavButton
          disabled={disabled}
          title={"Re-open event"}
          onClick={() => setIsShowDialog(true)}
        />

        {isShowDialog && (
          <CRMSCommentDialog
            dialogTitle="Re-open event"
            fieldTitle="Re-open reason (to be inserted in the journal)"
            nameOfField={"ReasonEvent"}
            onClosePopup={() => {
              setIsShowDialog(false);
            }}
            handleSubmit={(event: FormSubmitClickEvent) => {
              handleIReopenEvent(event?.values?.ReasonEvent);
              setIsShowDialog(false);
            }}
            placeholderString="Re-open reason"
          />
        )}
      </>
    );
  }
);
