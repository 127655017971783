export const CSLSystemAdminConditionsBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `CSL - System Admin - Conditions ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return "Conditions Reference";
  },
  getBookmarkListViewDisplayName() {
    return "CSL - System Admin";
  },

  getBookmarkListViewDetail() {
    return "CSL - System Admin - Conditions";
  },
};
