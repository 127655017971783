import { VO_Assessment } from "@app/products/property/assessments/list/model";

import { nameOfFactory } from "@common/utils/common";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";

const nameOf = nameOfFactory<VO_Assessment>();

export enum eAssessmentActiveFilterValue {
  NonRateable = "Non-Rateable",
  Active = "Active",
}

export const assessmentActiveListFilterState: CompositeFilterDescriptor = {
  filters: [
    {
      field: nameOf("Assessment_Status_Name"),
      operator: "eq",
      value: eAssessmentActiveFilterValue.NonRateable,
    },
    {
      field: nameOf("Assessment_Status_Name"),
      operator: "eq",
      value: eAssessmentActiveFilterValue.Active,
    },
  ],
  logic: "or",
};
