import { useCommentDialogStore } from "@app/core/comments/components/dialogs/add-comment/store";
import { Comments } from "@app/core/comments/model";
import { nameOfLov } from "@app/products/property/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import {
  requiredStringValidator,
  requiredValidator,
} from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isUndefined } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

interface ICommentDialogProps {
  onClose: () => void;
  onSubmit: (data: Comments) => void;
  recordType: RECORDTYPE;
  notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>;
  isEdit?: boolean;
  initialValue?: Comments;
  isLoadingSubmit: boolean;
  isUseDropDownForTitle?: boolean;
}
const nameOf = nameOfFactory<Comments>();

export const AddCommentDialog = observer(
  ({
    onClose,
    onSubmit,
    recordType,
    notificationRef,
    initialValue,
    isEdit = false,
    isLoadingSubmit = false,
    isUseDropDownForTitle = false,
  }: ICommentDialogProps) => {
    const {
      titleLovs,
      isLoadingOnDialog,
      responseLoadError,
      loadTitleLovs,
      resetStore,
    } = useCommentDialogStore();

    useEffectOnce(() => {
      if (isUseDropDownForTitle) {
        loadTitleLovs(recordType);
      }
      return () => {
        resetStore();
      };
    });

    const defaultInitValue = {
      Title:
        getDropdownValue(initialValue?.Title ?? "", titleLovs, "Name")?.Code ??
        "",
      Comment: initialValue?.Comment ?? "",
    };
    const emptyInitValue = { Title: "", Comment: "" };

    return (
      <Form
        onSubmitClick={({ values }) => {
          onSubmit(values as Comments);
        }}
        initialValues={
          isEdit && isUseDropDownForTitle ? defaultInitValue : emptyInitValue
        }
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onChange } = formRenderProps;
          return (
            <CCDialog
              maxWidth="33%"
              height="auto"
              disabled={isLoadingOnDialog || isLoadingSubmit}
              titleHeader={isEdit ? "Edit Comment" : "Add Comment"}
              onClose={onClose}
              bodyElement={
                isLoadingOnDialog ? (
                  <Loading isLoading={isLoadingOnDialog} />
                ) : responseLoadError ? (
                  <CCLoadFailed
                    responseError={responseLoadError}
                    onReload={() => {
                      loadTitleLovs(recordType);
                    }}
                  />
                ) : (
                  <FormElement className="cc-form cc-comment-dialog">
                    <section className="cc-field-group">
                      <CCLocalNotification ref={notificationRef} />
                      <div className="cc-form-cols-1">
                        {isUseDropDownForTitle && titleLovs.length !== 0 && (
                          <div className="cc-field">
                            <CCLabel title="Title" isMandatory />
                            <Field
                              name={nameOf("Title")}
                              placeholder={"Title"}
                              component={CCSearchComboBox}
                              data={titleLovs}
                              validator={requiredValidator}
                              textField={nameOfLov("Name")}
                              dataItemKey={nameOfLov("Code")}
                              value={getDropdownValue(
                                valueGetter(nameOf("Title")),
                                titleLovs,
                                nameOfLov("Name")
                              )}
                              onChange={(event: ComboBoxChangeEvent) => {
                                onChange(nameOf("Title"), {
                                  value: event.value?.Name,
                                });
                              }}
                            />
                          </div>
                        )}
                        {!isUseDropDownForTitle && (
                          <div className="cc-field">
                            <CCLabel title="Title" isMandatory />
                            <Field
                              name={nameOf("Title")}
                              placeholder={"Title"}
                              component={CCInput}
                              validator={requiredStringValidator}
                            />
                          </div>
                        )}
                        <div className="cc-field">
                          <CCLabel title="Description" />
                          <Field
                            name={nameOf("Comment")}
                            component={CCTextArea}
                            placeholder="Description"
                            rows={4}
                          />
                        </div>
                      </div>
                    </section>
                  </FormElement>
                )
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    themeColor="primary"
                    iconClass={isLoadingSubmit ? "fas fa-spinner fa-spin" : ""}
                    disabled={
                      isLoadingSubmit ||
                      !formRenderProps?.valid ||
                      !isUndefined(responseLoadError)
                    }
                    className={"cc-dialog-button"}
                    onClick={formRenderProps.onSubmit}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
