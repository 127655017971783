import { getLevyDetail } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/components/form-elements/charge/api";
import {
  DTO_Journal_LevyDetails,
  IChargesStepsLOVs,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/components/form-elements/charge/model";
import { useChargeStepStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/components/form-elements/charge/store";
import {
  blurDOMInput,
  getDefaultTransactionType,
  to2DecimalNumber,
  withSuffixedLoading,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/components/form-elements/charge/util";
import {
  DTO_LOV_Levy,
  DTO_LOV_TransactionConverted,
} from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
} from "@common/constants/common-format";
import { DTO_LOV } from "@common/models/odataResponse";
import { getDropdownValue } from "@common/utils/common";
import {
  nonZeroNumberValidator,
  requiredValidator,
} from "@common/utils/field-validators";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  NumericTextBoxChangeEvent,
  SwitchChangeEvent,
} from "@progress/kendo-react-inputs";
import axios, { Canceler } from "axios";
import { isInteger, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { MutableRefObject, useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export const CHARGES_FORM_STEP = "Charge";
const CancelToken = axios.CancelToken;

export const ChargeFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    localNotificationRef,
    options = {
      isReadOnly: false,
    },
    setIsLoadingStep,
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const { chargesStepLOVs, setChargesStepLOVs } = useChargeStepStore();
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const [isLevyLoading, setIsLevyLoading] = useState(false);
    const [isNettAmountLoading, setIsNettAmountLoading] = useState(false);
    const [isDisableQuantity, setIsDisableQuantity] = useState(true);
    const [allowManualEditOnAmount, setAllowManualEditOnAmount] =
      useState(false);
    const [financialGroupId, setFinancialGroupId] = useState<
      string | undefined
    >();
    const levyDetailCanceler: MutableRefObject<Canceler | null> = useRef(null);
    const nettAmountCanceler: MutableRefObject<Canceler | null> = useRef(null);

    const AmountInput = useMemo(
      () => withSuffixedLoading(CCCurrencyInput, isLevyLoading),
      [isLevyLoading]
    );
    const NettAmountInput = useMemo(
      () => withSuffixedLoading(CCCurrencyInput, isNettAmountLoading),
      [isNettAmountLoading]
    );

    // Assuming LevyId=0 is equivalent to non-existing Levy. This was inferred by
    // seeing new raise charge always initiated with LevyId=0 while such Levy doesn't appear in the LOVs
    const isSelectedLevy = !!getFieldValue("LevyId");
    const levyAmount = getFieldValue("LevyAmount") ?? 0;
    const quantity = getFieldValue("Quantity") ?? 0;

    const fetchNettAmount = async () => {
      nettAmountCanceler.current?.();
      setIsNettAmountLoading(true);
      setIsLoadingStep(true);
      const chargeAmount = getFieldValue("LevyAmount")
        ? to2DecimalNumber(
            getFieldValue("LevyAmount") * getFieldValue("Quantity")
          )
        : getFieldValue("Amount");
      getLevyDetail(
        getFieldValue("LevyId"),
        getFieldValue("RatingPeriod"),
        getFieldValue("ProrataChargeOfRatingPeriod"),
        getFieldValue("NetAmountFrom"),
        chargeAmount,
        {
          token: new CancelToken(function executor(canceler) {
            nettAmountCanceler.current = canceler;
          }),
        }
      ).then((response: APIResponse<DTO_Journal_LevyDetails>) => {
        if (axios.isCancel(response.errorCause)) {
          return;
        }
        if (isSuccessResponse(response) && response.data) {
          onChange(nameOf("NetAmount"), {
            value: response.data.NetAmount,
          });
        } else {
          localNotificationRef?.current?.pushNotification({
            title: response?.error ?? "Failed on getting Nett Amount",
            type: "error",
            autoClose: false,
          });
        }
        setIsLoadingStep(false);
        setIsNettAmountLoading(false);
      });
    };

    const onLevyChangedCallback = (
      response: APIResponse<DTO_Journal_LevyDetails>,
      config?: { preserveUnboundChargeAmount: boolean }
    ) => {
      const nextLevyAmount = response.data?.LevyRates?.Amount ?? 0;
      onChange("NewCharge", {
        value: {
          ...valueGetter("NewCharge"),
          Description: response.data?.LevyRates?.LevyName ?? "",
          LevyAmount: nextLevyAmount,
        },
      });
      if (nextLevyAmount !== 0) {
        let nextQuantity = quantity;
        (function recoverQuantity() {
          // For old workflows that haven't been approved / rejected / cancelled, it's potentially missing LevyAmount and Quantity
          // on getting reopened after parking / finishing. This small function attempts to recover the Quantity.
          const factor = getFieldValue("Amount") / nextLevyAmount;
          if (nextQuantity === 0 && isInteger(factor)) {
            nextQuantity = factor;
          }
        })();
        nextQuantity = Math.max(nextQuantity, 1);
        onChange(nameOf("Quantity"), {
          value: nextQuantity,
        });
        onChange(nameOf("Amount"), {
          value: to2DecimalNumber(nextLevyAmount * nextQuantity),
        });
        setIsDisableQuantity(false);
        setAllowManualEditOnAmount(false);
      } else {
        onChange(nameOf("Quantity"), {
          value: 0,
        });
        if (!config?.preserveUnboundChargeAmount) {
          onChange(nameOf("Amount"), {
            value: 0,
          });
        }
        setIsDisableQuantity(true);
        setAllowManualEditOnAmount(true);
      }
      setChargesStepLOVs({
        ...chargesStepLOVs,
        InstallmentPlan: convertValueLOVToNumber<DTO_LOV>(
          response.data.InstallmentPlan ?? [],
          "Code"
        ),
      } as IChargesStepsLOVs);
    };

    const seekLevyCalculation = async (args: {
      amount: number;
      successCallback: (data: APIResponse<DTO_Journal_LevyDetails>) => void;
      errorCallback?: (asError: APIResponse<DTO_Journal_LevyDetails>) => void;
    }) => {
      const { amount, successCallback, errorCallback } = args;
      setIsLevyLoading(true);
      setIsLoadingStep(true);
      levyDetailCanceler.current?.();
      const response = await getLevyDetail(
        getFieldValue("LevyId"),
        getFieldValue("RatingPeriod"),
        getFieldValue("ProrataChargeOfRatingPeriod"),
        getFieldValue("NetAmountFrom"),
        amount,
        {
          token: new CancelToken(function executor(canceler) {
            levyDetailCanceler.current = canceler;
          }),
        }
      );
      if (axios.isCancel(response.errorCause)) {
        return undefined;
      }
      let levyData;
      if (isSuccessResponse(response) && response.data) {
        successCallback(response);
        levyData = response.data;
      } else {
        (typeof errorCallback === "function"
          ? errorCallback
          : function defaultErrorCallback(
              errorResponse: APIResponse<DTO_Journal_LevyDetails>
            ) {
              localNotificationRef?.current?.pushNotification({
                title: errorResponse?.error ?? "Levy detail get failed",
                type: "error",
                autoClose: false,
              });
            })(response);
      }
      setIsLevyLoading(false);
      setIsLoadingStep(false);
      return levyData;
    };

    const handleLevyIdChange = async (event: ComboBoxChangeEvent) => {
      const financialGroupId = event.value?.Financial_Group_Id;
      setFinancialGroupId(financialGroupId);
      onChange(nameOf("LevyId"), {
        value: event.value?.Code ?? null,
      });
      if (isNil(event.value?.Code)) {
        blurDOMInput("#new-charge-amount");
        onChange("NewCharge", {
          value: {
            ...valueGetter("NewCharge"),
            Description: "",
            LevyAmount: 0,
            Quantity: 0,
            Amount: 0,
            NetAmount: 0,
          },
        });
        // #region empty-the-combo-boxes
        // separately set empty value to combo-boxes to trigger validation
        onChange(nameOf("RatingPeriod"), {
          value: null,
        });
        onChange(nameOf("TransactionType"), {
          value: null,
        });
        // #endregion
        setChargesStepLOVs({
          ...chargesStepLOVs,
          InstallmentPlan: [],
        } as IChargesStepsLOVs);
        setIsDisableQuantity(true);
        setAllowManualEditOnAmount(false);
        return;
      }
      if (
        !chargesStepLOVs?.RatingPeriods?.[financialGroupId]?.find(
          (item) => item.Code === getFieldValue("RatingPeriod")
        )
      ) {
        onChange(nameOf("RatingPeriod"), {
          value:
            chargesStepLOVs?.RatingPeriods?.[financialGroupId]?.[0]?.Code ??
            null,
        });
      }
      onChange(nameOf("TransactionType"), {
        value: getDefaultTransactionType(
          event.value.Transaction_Type_Id,
          getFieldValue("ShowOnlyChargeTransactionType"),
          chargesStepLOVs?.TransactionTypes,
          chargesStepLOVs?.TransactionTypesOnlyCharge,
          financialGroupId
        ),
      });
      await seekLevyCalculation({
        amount: getFieldValue("Amount"),
        successCallback: onLevyChangedCallback,
      }).then((data: DTO_Journal_LevyDetails | undefined) => {
        if (getFieldValue("ProrataChargeOfRatingPeriod") && !isNil(data)) {
          fetchNettAmount();
        }
      });
    };

    const handleRatingPeriodChange = async (event: ComboBoxChangeEvent) => {
      onChange(nameOf("RatingPeriod"), {
        value: event.value?.Code ?? null,
      });
      if (isNil(event.value?.Code)) {
        blurDOMInput("#new-charge-amount");
        onChange("NewCharge", {
          value: {
            ...valueGetter("NewCharge"),
            LevyAmount: 0,
            Quantity: 0,
            Amount: 0,
            NetAmount: 0,
          },
        });
        setIsDisableQuantity(true);
        setAllowManualEditOnAmount(false);
        return;
      }
      await seekLevyCalculation({
        amount: getFieldValue("Amount"),
        successCallback: onLevyChangedCallback,
      }).then((data: DTO_Journal_LevyDetails | undefined) => {
        if (getFieldValue("ProrataChargeOfRatingPeriod") && !isNil(data)) {
          fetchNettAmount();
        }
      });
    };

    const handleChargeProrateChange = (event: SwitchChangeEvent) => {
      const value = event.value;
      onChange(nameOf("ProrataChargeOfRatingPeriod"), {
        value,
      });
      if (!value) {
        nettAmountCanceler.current?.();
        return;
      }
      fetchNettAmount();
    };

    const handleAmountChange = (event: NumericTextBoxChangeEvent) => {
      const value = event.value ?? 0;
      onChange(nameOf("Amount"), {
        value,
      });
      if (!getFieldValue("ProrataChargeOfRatingPeriod")) {
        return;
      }
      fetchNettAmount();
    };

    const handleQuantityChange = (event: NumericTextBoxChangeEvent) => {
      const value = event.value ?? 0;
      onChange(nameOf("Quantity"), {
        value,
      });
      onChange(nameOf("Amount"), {
        value: to2DecimalNumber(value * getFieldValue("LevyAmount")),
      });
      if (!getFieldValue("ProrataChargeOfRatingPeriod")) {
        return;
      }
      fetchNettAmount();
    };

    const handleChargeNettFromChange = (event: DatePickerChangeEvent) => {
      const value = event.value;
      onChange(nameOf("NetAmountFrom"), {
        value,
      });
      if (!getFieldValue("ProrataChargeOfRatingPeriod")) {
        return;
      }
      fetchNettAmount();
    };

    const handleFilterTransactionTypes = (event: SwitchChangeEvent) => {
      onChange(nameOf("ShowOnlyChargeTransactionType"), {
        value: event.value,
      });
      const currentLevy = chargesStepLOVs?.Levy.find(
        (levy: DTO_LOV_Levy) => levy.Code === getFieldValue("LevyId")
      );
      if (isNil(currentLevy) || currentLevy.Financial_Group_Id === null) {
        return;
      }
      const currentTransactionType = getFieldValue("TransactionType");
      if (isNil(currentTransactionType)) {
        return;
      }
      const include = (
        chargesStepLOVs?.TransactionTypesOnlyCharge?.[
          currentLevy.Financial_Group_Id
        ] || []
      ).some(
        (transactionType: DTO_LOV_TransactionConverted) =>
          currentTransactionType === transactionType.Code
      );
      if (event.value === true && !include) {
        onChange(nameOf("TransactionType"), {
          value: null,
        });
      }
    };

    useEffectOnce(() => {
      if (isSelectedLevy) {
        const selectedFinancialGroupId = chargesStepLOVs?.Levy?.find(
          (item) => item.Code === getFieldValue("LevyId")
        )?.Financial_Group_Id;
        selectedFinancialGroupId &&
          setFinancialGroupId(selectedFinancialGroupId.toString());
      }
      if (isSelectedLevy && !options?.isReadOnly) {
        let chargeAmount = to2DecimalNumber(levyAmount * quantity);
        seekLevyCalculation({
          amount: chargeAmount,
          successCallback: (response) =>
            onLevyChangedCallback(response, {
              preserveUnboundChargeAmount: true,
            }),
        }).then((data: DTO_Journal_LevyDetails | undefined) => {
          if (getFieldValue("ProrataChargeOfRatingPeriod") && !isNil(data)) {
            fetchNettAmount();
          }
        });
      }
      return () => {
        levyDetailCanceler.current?.();
        nettAmountCanceler.current?.();
      };
    });

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Levy" isMandatory />
            <Field
              name={nameOf("LevyId")}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              textField="Name"
              dataItemKey="Code"
              component={CCSearchComboBox}
              disabled={options?.isReadOnly}
              isLoading={isLevyLoading}
              data={chargesStepLOVs?.Levy ?? []}
              value={getDropdownValue(
                getFieldValue("LevyId"),
                chargesStepLOVs?.Levy ?? [],
                "Code"
              )}
              onChange={handleLevyIdChange}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Charge date" isMandatory />
            <Field
              name={nameOf("ChargeDate")}
              component={CCDateTimePicker}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Rating period" isMandatory />
            <Field
              name={nameOf("RatingPeriod")}
              disabled={options?.isReadOnly || !isSelectedLevy}
              data={
                financialGroupId
                  ? chargesStepLOVs?.RatingPeriods?.[financialGroupId] ?? []
                  : []
              }
              textField="Name"
              dataItemKey="Code"
              component={CCSearchComboBox}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              value={getDropdownValue(
                getFieldValue("RatingPeriod"),
                financialGroupId
                  ? chargesStepLOVs?.RatingPeriods?.[financialGroupId] ?? []
                  : [],
                "Code"
              )}
              onChange={handleRatingPeriodChange}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Description" />
            <Field
              name={nameOf("Description")}
              placeholder="Description"
              rows={4}
              component={CCTextArea}
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Code" />
            <Field
              name={nameOf("Code")}
              placeholder="Code"
              component={CCInput}
              readOnly={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Quantity" />
            <div className="cc-custom-input-group">
              <Field
                name={nameOf("Quantity")}
                component={CCNumericTextBox}
                disabled={options?.isReadOnly || isDisableQuantity}
                min={0}
                onChange={handleQuantityChange}
              />
              <div
                className={`cc-input-group-postfix cc-postfix-quantity-amount${
                  isDisableQuantity ? "-disabled" : ""
                }`}
              >
                @ {formatDisplayValue(levyAmount, CURRENCY_FORMAT.CURRENCY1)}
              </div>
            </div>
          </div>

          <div className="cc-field">
            <CCLabel title="Amount" isMandatory />
            <Field
              id="new-charge-amount"
              name={nameOf("Amount")}
              component={AmountInput}
              onChange={handleAmountChange}
              validator={
                !options?.isReadOnly ? nonZeroNumberValidator : undefined
              }
              disabled={options?.isReadOnly || !allowManualEditOnAmount}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Prorata charge for portion of rating period" />
            <Field
              name={nameOf("ProrataChargeOfRatingPeriod")}
              checked={getFieldValue("ProrataChargeOfRatingPeriod")}
              component={CCSwitch}
              disabled={options?.isReadOnly}
              onChange={handleChargeProrateChange}
            />
          </div>
        </div>

        {getFieldValue("ProrataChargeOfRatingPeriod") ? (
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Nett amount" />
              <Field
                name={nameOf("NetAmount")}
                component={NettAmountInput}
                disabled
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Nett amount from" />
              <Field
                name={nameOf("NetAmountFrom")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                onChange={handleChargeNettFromChange}
                disabled={options?.isReadOnly}
              />
            </div>
          </div>
        ) : null}
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Instalment plan" />
            <Field
              name={nameOf("InstalmentPlanId")}
              textField="Name"
              dataItemKey="Code"
              component={CCSearchComboBox}
              isUseDefaultOnchange
              data={chargesStepLOVs?.InstallmentPlan ?? []}
              disabled={options?.isReadOnly || !isSelectedLevy}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Apply any remaining rebate entitlements to charge" />
            <Field
              name={nameOf("ApplyRemainingEntitlements")}
              component={CCSwitch}
              disabled={options?.isReadOnly}
              checked={getFieldValue("ApplyRemainingEntitlements")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Transaction type" isMandatory />
            <Field
              name={nameOf("TransactionType")}
              disabled={options?.isReadOnly || !isSelectedLevy}
              data={
                financialGroupId
                  ? getFieldValue("ShowOnlyChargeTransactionType")
                    ? chargesStepLOVs?.TransactionTypesOnlyCharge?.[
                        financialGroupId
                      ] ?? []
                    : chargesStepLOVs?.TransactionTypes?.[financialGroupId] ??
                      []
                  : []
              }
              textField="Name"
              dataItemKey="Code"
              component={CCSearchComboBox}
              isUseDefaultOnchange
              validator={!options?.isReadOnly ? requiredValidator : undefined}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Transaction date" isMandatory />
            <Field
              name={nameOf("TransactionDate")}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              component={CCDateTimePicker}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              disabled={options?.isReadOnly}
            />
          </div>

          <div className="cc-field">
            <CCLabel title="Show only charge transaction types" />
            <Field
              name={nameOf("ShowOnlyChargeTransactionType")}
              component={CCSwitch}
              disabled={options?.isReadOnly}
              checked={getFieldValue("ShowOnlyChargeTransactionType")}
              onChange={handleFilterTransactionTypes}
            />
          </div>
        </div>
      </section>
    );
  }
);
