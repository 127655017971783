import { ServiceStandard } from "@app/products/crms/service-standards/[id]/model";

export const generateServiceStandardFormTitle = (
  serviceStandard: ServiceStandard
) => {
  const titleItems: string[] = [];
  if (serviceStandard.Category?.Name) {
    titleItems.push(serviceStandard.Category?.Name);
  }
  if (serviceStandard.SubCategory?.Name) {
    titleItems.push(serviceStandard.SubCategory?.Name);
  }
  if (serviceStandard.SubCategoryLevel3) {
    titleItems.push(serviceStandard.SubCategoryLevel3);
  }
  if (serviceStandard.SubCategoryLevel4) {
    titleItems.push(serviceStandard.SubCategoryLevel4);
  }
  if (titleItems.length > 0) {
    return `${titleItems?.[0]} ... ${titleItems?.[titleItems.length - 1]}`;
  }
  return "Service Standard";
};
