import { SaveButtonDirectDebitGroup } from "@app/products/direct-debit/system-admin/groups/[id]/components/buttons/save/_index";
import { DirectDebitGroupForm } from "@app/products/direct-debit/system-admin/groups/[id]/components/general/_index";
import { TitleDirectDebitGroupManagePage } from "@app/products/direct-debit/system-admin/groups/constant";
import { DirectDebitGroup } from "@app/products/direct-debit/system-admin/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewManageDirectDebitGroup = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const directDebitGroup = dataForms?.GeneralForm as DirectDebitGroup;

  return (
    <>
      <FormTitle title={`New ${TitleDirectDebitGroupManagePage}`} />
      <CCManagePageActionBar leftActions={[<SaveButtonDirectDebitGroup />]} />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {directDebitGroup && <DirectDebitGroupForm />}
          </div>
        </div>
      </div>
    </>
  );
});
