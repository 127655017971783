import { DTO_DeferredDuty_Transaction } from "@app/products/property/deferred-duty/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getJournalDeferredDutyAccountTransactions = async (
  id?: number
): Promise<APIResponse<DTO_DeferredDuty_Transaction | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/journal/${id}/ddtransactions`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
