import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { ComplianceDetailTab } from "@app/products/property/assessments/compliance/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE } from "@app/products/property/assessments/compliance/[id]/constant";
import { VO_Compliance_W_Assessment } from "@app/products/property/assessments/compliance/[id]/model";
import { PROPERTY_COMPLIANCE_ALL_WITHOUT_ASSESSMENTS } from "@app/products/property/assessments/compliance/all-without-assessments/constant";
import { assessmentsRoute } from "@app/products/property/assessments/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { propertyRoute } from "@app/products/property/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { ComplianceAssociationsTab } from "../[id]/components/reference-sidebar/associations/_index";
import { includeInactiveComplianceColList } from "./config";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "./util";
const nameOf = nameOfFactory<VO_Compliance_W_Assessment>();
export default () => {
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <ComplianceDetailTab /> },
      { title: "Map", component: <MapTab addressField="Property_Address" /> },
      {
        title: "Contacts",
        component: (
          <PropertyContactTab componentNumber={eComponent.Compliance} />
        ),
      },
      { title: "Related", component: <ComplianceAssociationsTab /> },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Compliance}
          />
        ),
      },
    ],
  });
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={assessmentsRoute.path} />,
    ],
    centerComponents: [
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Compliance}
        recordType={RECORDTYPE.CommunityProperty_Compliance}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Compliance}
        keyField={"Compliance_Id"}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        productType={PRODUCT_TYPE.CommunityProperty}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  return (
    <CCProductListView
      dataUrl={PROPERTY_COMPLIANCE_ALL_WITHOUT_ASSESSMENTS}
      columnFields={includeInactiveComplianceColList}
      primaryField={nameOf("Compliance_Id")}
    />
  );
};
