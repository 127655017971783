import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { VO_Charge_Run_Rebate_Summary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebate-summary/model";

const nameOfRebateSummary = nameOfFactory<VO_Charge_Run_Rebate_Summary>();
export const colRebateSummary: IColumnFields[] = [
  {
    field: nameOfRebateSummary("RT_Name"),
    title: "Type",
  },
  {
    field: nameOfRebateSummary("No_of_Rebates"),
    title: "Number Of",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfRebateSummary("Total_Rebates"),
    title: "Total",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
