import { colUsers } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/users-grid/config";
import { REPORT_SHARE_REPORT_GET_OFFICER_CONTACTS_URL } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/users-grid/constant";
import { IUsers } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/users-grid/model";
import { nameOfFactory } from "@common/utils/common";
import { GridSearch } from "@components/cc-grid-search/_index";
import { ICCGridProps } from "@components/cc-grid/_index";
import { State } from "@progress/kendo-data-query";
import React, { useEffect, useState } from "react";

interface IUsersGridProps extends ICCGridProps {
  handleSelectionChange: (e: IUsers[]) => void;
}
export const nameOfUsers = nameOfFactory<IUsers>();
export const UsersGrid = (props: IUsersGridProps) => {
  const { state = {} } = props;
  const { handleSelectionChange } = props;
  const [stateData, setStateData] = useState<State>({
    ...state,
    sort: [{ field: nameOfUsers("Name"), dir: "asc" }],
  });

  useEffect(() => {
    setStateData({
      ...state,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.state]);

  return (
    <div className="cc-grid-selection-dialog">
      <GridSearch
        {...props}
        state={stateData}
        grid={{
          primaryField: nameOfUsers("ID"),
        }}
        search={{
          hasSearchField: true,
          filterCol: nameOfUsers("Name"),
          sortCol: nameOfUsers("Name"),
        }}
        columnFields={colUsers}
        selectableMode={"multiple"}
        dataUrl={REPORT_SHARE_REPORT_GET_OFFICER_CONTACTS_URL}
        handleSelectionChange={handleSelectionChange}
      />
    </div>
  );
};
