import { IOfficerAllocation } from "@app/products/property/assessments/components/form-steps/new-assessment/dialog/officer-allocation/model";
import { OfficerAllocationDialog } from "@app/products/property/assessments/components/form-steps/new-assessment/dialog/officer-allocation/_index";
import { loadListOfficer } from "@app/products/property/assessments/components/form-steps/new-assessment/dialog/officer-reallocate/api";
import { useNewAssessmentDialogStore } from "@app/products/property/assessments/components/form-steps/new-assessment/store";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IOfficerReallocateProps {
  onClose: () => void;
  onSubmit: (values: IOfficerAllocation) => void;
}

export const OfficerReallocateDialog = observer(
  ({ onClose, onSubmit }: IOfficerReallocateProps) => {
    const [listOfficer, setListOfficer] = useState<IKeyValuePacket[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { isLoadingReallocate } = useNewAssessmentDialogStore();
    useEffectOnce(() => {
      setIsLoading(true);
      loadListOfficer().then((data: IKeyValuePacket[]) => {
        setListOfficer(data);
        setIsLoading(false);
      });
    });
    return (
      <OfficerAllocationDialog
        titleDialog={"Officer Reallocation"}
        onClose={onClose}
        isShowWarning={false}
        onSubmitData={onSubmit}
        isLoadingAllocate={isLoadingReallocate}
        isLoadingOfficer={isLoading}
        dataOfficer={listOfficer}
      />
    );
  }
);
