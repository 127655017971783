import { ChangeDecisionData } from "@app/products/town-planning/ppr/components/action-bar/dialogs/change-decision/model";
import { ChangeDecisionDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/change-decision/_index";
import { putPSARChangeDecision } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/tools/change-decision/api";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const PSARChangeDecisionButton = observer(() => {
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const { ppr, pprId, setPPRWithLoading } = usePSARStore();
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  const handleOnClick = () => {
    if (!isNil(ppr?.Decision_ENUM)) {
      setIsShowDialog(true);
    } else {
      pushNotification({
        autoClose: false,
        type: "error",
        title: "Decision required",
      });
    }
  };

  const handleSubmit = (data: ChangeDecisionData) => {
    if (!pprId || data.Decision_ENUM === null) return;
    setIsSubmitting(true);
    putPSARChangeDecision(pprId, data.Decision_ENUM).then((response) => {
      setIsSubmitting(false);
      if (isSuccessIdentityPacket(response) && response.data?.Application) {
        setIsShowDialog(false);
        setPPRWithLoading(response.data.Application);
        clearNotifications();
        pushNotification({
          type: "success",
          title: "Change decision successfully",
        });
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: "Change decision failed",
            type: "error",
            description: response?.data?.Errors ?? response?.error,
          },
        ]);
      }
    });
  };

  return (
    <>
      <CCNavButton title="Change decision" onClick={handleOnClick} />
      {isShowDialog && (
        <ChangeDecisionDialog
          application={ppr}
          onClose={() => {
            setIsShowDialog(false);
          }}
          notifications={notifications}
          onSubmit={handleSubmit}
          isLoading={isSubmitting}
        />
      )}
    </>
  );
});
