import {
  getNewWorkflowCreateAssessment,
  getWorkflowCreateAssessment,
} from "@app/products/property/api";
import {
  DTO_Assessment_LOVs,
  DTO_Workflow_CreateAssessment,
} from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postProcessCreateAssessment = async (
  workflowType: WorkflowProcessMode,
  holdingData: DTO_Workflow_CreateAssessment
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_CreateAssessment>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/createassessment/process/${workflowType}`,
      holdingData
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataAssessment =
  | [
      APIResponse<DTO_Assessment_LOVs>,
      APIResponse<DTO_Workflow_CreateAssessment>
    ]
  | APIResponse<DTO_Assessment_LOVs | DTO_Workflow_CreateAssessment>
  | undefined;
export const getInitialDataAssessment = async (
  workflowType: WorkflowTypes,
  workflowDraftId: number | undefined,
  isNewAssessment: boolean = false
): Promise<IGetInitialDataAssessment> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Assessment_LOVs>(
        `/api/property/internal/workflow/createassessment/lovs?workflowType=${workflowType}`
      ),
      !isNewAssessment && !isNil(workflowDraftId)
        ? getWorkflowCreateAssessment(workflowDraftId)
        : getNewWorkflowCreateAssessment(),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getRLPBoardLOV = async (
  assessmentGroupId: number,
  workflowType: WorkflowTypes
): Promise<APIResponse<DTO_Assessment_LOVs>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/createassessment/lovs?workflowType=${workflowType}&AssessmentGroupId=${assessmentGroupId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
