import { ExistManageKennel } from "@app/products/animals/kennels/[id]/component/forms/existed/_index";
import { NewManageKennel } from "@app/products/animals/kennels/[id]/component/forms/new/_index";
import { IParentKennelSection } from "@app/products/animals/kennels/[id]/model";
import { useAnimalsKennelStore } from "@app/products/animals/kennels/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useGlobalStore } from "@common/stores/global/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export default observer(() => {
  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();
  const { currentUserInfo } = useGlobalStore();
  const { resetMenu } = useCCSubActionBarStore();
  const { loadAnimalsKennelById, resetStore, setParent, checkIsSuperAdmin } =
    useAnimalsKennelStore();

  const { state } = useLocation<{
    parent?: IParentKennelSection;
  }>();

  useEffect(() => {
    if (state?.parent) {
      setParent(state?.parent);
      window.history.replaceState({}, "");
    }

    loadAnimalsKennelById(+id, isNew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew, loadAnimalsKennelById]);

  useEffect(() => {
    if (currentUserInfo?.UserPKID)
      checkIsSuperAdmin(parseInt(currentUserInfo.UserPKID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserInfo?.UserPKID]);

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });

  if (isNew) {
    return <NewManageKennel />;
  }
  return <ExistManageKennel />;
});
