import {
  DTO_Workflow_JournalDDReverseTransaction,
  RequestReverseDDTransactionObj,
} from "@app/products/property/deferred-duty/[id]/components/forms/existed/components/form-steps/reverse-transaction/model";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { WorkflowProcessMode } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postProcessDeferredDutyReverseTransaction = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_JournalDDReverseTransaction
): Promise<APIResponse<any>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/deferreddutytransactionreverse/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDeferredDutyReverseTransaction =
  | [
      APIResponse<DTO_Journal_LOVs>,
      APIResponse<DTO_Workflow_JournalDDReverseTransaction>
    ]
  | APIResponse<DTO_Journal_LOVs | DTO_Workflow_JournalDDReverseTransaction>
  | undefined;

export const getInitialDeferredDutyReverseTransaction = async (
  workflowDraftId: number | undefined = 0,
  isFromActionList: boolean = false,
  transactionIds?: number[] | undefined
): Promise<IGetInitialDeferredDutyReverseTransaction> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_LOVs>(
        `api/property/internal/journal/lovs`
      ),
      isFromActionList
        ? getWorkflowDeferredDutyReverseTransaction(workflowDraftId)
        : getNewWorkflowDeferredDutyReverseTransaction({
            TransactionIds: transactionIds,
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowDeferredDutyReverseTransaction = async (
  requestObj: RequestReverseDDTransactionObj | object = {}
): Promise<APIResponse<DTO_Workflow_JournalDDReverseTransaction>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/deferreddutytransactionreverse/new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowDeferredDutyReverseTransaction = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_JournalDDReverseTransaction>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/deferreddutytransactionreverse/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
