import { LLPermitsMenu } from "@app/products/local-laws/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const llsPermitsRoute: ICCRoute = {
  path: "lls-permits",
  name: "Permits",
  enum: LLPermitsMenu.LLSPermits,
  children: [
    {
      path: "register",
      name: "Register",
      enum: LLPermitsMenu.LLSPermitsRegister,
      component: require("./register/_index").default,
    },
    {
      path: "new-applications",
      name: "New Applications",
      enum: LLPermitsMenu.LLSPermitsRegisterNewApplications,
      component: require("./register/_index").default,
    },
    {
      path: "active",
      name: "Active",
      enum: LLPermitsMenu.LLSPermitsRegisterActive,
      component: require("./register/_index").default,
    },
    {
      path: "rejected-on-hold",
      name: "Rejected / On Hold",
      enum: LLPermitsMenu.LLSPermitsRegisterRejectedOrOnhold,
      component: require("./register/_index").default,
    },
    {
      path: "cancelled",
      name: "Cancelled",
      enum: LLPermitsMenu.LLSPermitsRegisterCancelled,
      component: require("./register/_index").default,
    },
    {
      path: "revoked",
      name: "Revoked",
      enum: LLPermitsMenu.LLSPermitsRegisterRevoked,
      component: require("./register/_index").default,
    },
    {
      path: "expired",
      name: "Expired",
      enum: LLPermitsMenu.LLSPermitsRegisterExpired,
      component: require("./register/_index").default,
    },
    {
      path: "withdrawn",
      name: "Withdrawn",
      enum: LLPermitsMenu.LLSPermitsRegisterWithdrawn,
      component: require("./register/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      enum: LLPermitsMenu.LLSPermitsRegisterByType,
      component: require("./register/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      enum: LLPermitsMenu.LLSPermitsRegisterByStatus,
      component: require("./register/_index").default,
    },
    {
      path: "by-region",
      name: "By Region",
      enum: LLPermitsMenu.LLSPermitsRegisterByRegion,
      component: require("./register/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      enum: LLPermitsMenu.LLSPermitsRegisterByOfficer,
      component: require("./register/_index").default,
    },
    {
      path: "by-ao-approver",
      name: "By AO Approver",
      enum: LLPermitsMenu.LLSPermitsRegisterByAOApprover,
      component: require("./register/_index").default,
    },
    {
      path: "by-bio-approver",
      name: "By BIO Approver",
      enum: LLPermitsMenu.LLSPermitsRegisterByBIOApprover,
      component: require("./register/_index").default,
    },
    {
      path: "invoicing",
      name: "Invoicing",
      enum: LLPermitsMenu.LLSPermitsRegisterInvoicing,
      component: require("./register/_index").default,
    },
    {
      path: "due-for-annual-invoice",
      name: "Due For Annual Invoice",
      enum: LLPermitsMenu.LLSPermitsRegisterDueForInvoice,
      component: require("./register/_index").default,
    },
    {
      path: "annual-invoiced",
      name: "Annual Invoiced",
      enum: LLPermitsMenu.LLSPermitsRegisterAnnualInvoiced,
      component: require("./register/_index").default,
    },
  ],
};
