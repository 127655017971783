import { IRegisterMeters } from "@app/products/property/registers/[id]/components/child-screens/meters/model";
import {
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IRegisterMeters>();

export const colRegisterMeters: IColumnFields[] = [
  {
    field: nameOf("Utility"),
    title: "Utility",
  },
  {
    field: nameOf("MeterNumber"),
    title: "Meter Number",
  },
  {
    field: nameOf("ReadingRoute"),
    title: "Reading Route",
  },
  {
    field: nameOf("SequenceInRoute"),
    title: "Sequence in Route",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Category"),
    title: "Category",
  },
  {
    field: nameOf("UsageEntitlement"),
    title: "Usage Entitlement",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("UsagePercentage"),
    title: "Usage Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("LastReading"),
    title: "Last Reading",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("LastRead"),
    title: "Last Read",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateAttached"),
    title: "Date Attached",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateRemoved"),
    title: "Date Removed",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ReadingOnRemoval"),
    title: "Reading on Removal",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("NumberOfDials"),
    title: "Number of Dials",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("NextReadDate"),
    title: "Next Read Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ReadingFrequency"),
    title: "Reading Frequency (in Months)",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AverageDaily"),
    title: "Average Daily Usage",
    format: DECIMAL_FORMAT.DECIMAL_4_DIGIT,
  },

  {
    field: nameOf("UnitOfMeasure"),
    title: "Unit of Measure",
  },
  {
    field: nameOf("MeterLocation"),
    title: "Meter Location",
  },
  {
    field: nameOf("InstallationDate"),
    title: "Installation Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("MeterSize"),
    title: "Meter Size",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("MeterId"),
    title: "Meter ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
