import { IFSPObserved } from "@app/products/hm/inspections/[id]/components/forms/exist/components/fsp-observed/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IFSPObserved>();
export const colFSPObserved: IColumnFields[] = [
  {
    field: nameOf("Recorded"),
    title: "Recorded",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Item"),
    title: "Item",
  },
  {
    field: nameOf("DateFrom"),
    title: "Date From",
  },
  {
    field: nameOf("DateTo"),
    title: "Date To",
  },
  {
    field: nameOf("Comments"),
    title: "Comments",
  },
];
