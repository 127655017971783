import { history } from "@/AppRoutes";
import { IMeterMemo } from "@app/products/property/meters/[id]/components/child-screens/memos/model";
import {
  getWasteWaterInfo,
  getWasteWaterSummaryById,
  getWasteWaterSummaryLov,
  postWastewater,
  putWastewater,
} from "@app/products/waste-water/[id]/api";
import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import {
  WasteWaterSummaryLOVs,
  WasteWaterSystemRegisterSummary,
} from "@app/products/waste-water/[id]/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
export const nameOf = nameOfFactory<IMeterMemo>();
class WasteWaterStore {
  private _wastewater?: WasteWaterSystemRegisterSummary = undefined;
  private _isLoading: boolean = false;
  private _wastewaterLov?: WasteWaterSummaryLOVs = undefined;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get wastewater() {
    return this._wastewater;
  }
  setWastewater = (wastewater?: WasteWaterSystemRegisterSummary) => {
    runInAction(() => {
      this._wastewater = wastewater;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get wastewaterLov() {
    return this._wastewaterLov;
  }
  setWastewaterLov = (wastewaterLov?: WasteWaterSummaryLOVs) => {
    runInAction(() => {
      this._wastewaterLov = wastewaterLov;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get wasteWaterId() {
    return toJS(this.wastewater?.System_ID);
  }

  //Action
  resetStore = () => {
    this.setWastewater(undefined);
    this.setWastewaterLov(undefined);
    this.setIsLoading(false);
    this.setResponseLoadError(undefined);
  };

  loadWasteWaterInfo = async (id: string, isNew: boolean) => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    if (isNew) {
      const response = await getWasteWaterSummaryLov();
      if (isSuccessResponse(response)) {
        this.setWastewaterLov(response.data);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
    } else {
      const responseWWInfo = await getWasteWaterInfo(id);
      if (Array.isArray(responseWWInfo)) {
        const [wwInfo, wwLOVs] = responseWWInfo;
        this.setWastewater(wwInfo.data);
        this.setWastewaterLov(wwLOVs.data);
      } else {
        const responseError = responseWWInfo as APIResponse;
        errorResponse = {
          status: responseError.status,
          error: responseError.error,
        };
      }
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  loadWastewaterSummary = async (id: string) => {
    this.setIsLoading(true);
    const responseSummary = await getWasteWaterSummaryById(id);

    let errorResponse = undefined;
    if (isSuccessResponse(responseSummary)) {
      this.setWastewater(responseSummary.data);
      errorResponse = undefined;
    } else {
      errorResponse = {
        status: responseSummary.status,
        error: responseSummary.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  loadWastewaterSummaryLov = async () => {
    this.setIsLoading(true);
    const responseSummaryLov = await getWasteWaterSummaryLov();

    let errorResponse = undefined;
    if (isSuccessResponse(responseSummaryLov)) {
      this.setWastewaterLov(responseSummaryLov.data);
      errorResponse = undefined;
    } else {
      errorResponse = {
        status: responseSummaryLov.status,
        error: responseSummaryLov.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  createWasteWater = async (data: WasteWaterSystemRegisterSummary) => {
    this.setIsLoading(true);
    const response = await postWastewater(data);
    if (isSuccessResponse(response)) {
      appNotificationStore.pushNotification({
        title: "Waste water created successfully",
        type: "success",
        onClose: () => {
          history.push(`${WASTE_WATER_ROUTE}/${response?.data.System_ID ?? 1}`);
        },
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.error ?? "Waste water create failed",
        type: "error",
      });
    }
    this.setIsLoading(false);
  };

  updateWasteWater = async (data: WasteWaterSystemRegisterSummary) => {
    this.setIsLoading(true);
    const response = await putWastewater(data);
    if (isSuccessResponse(response)) {
      appNotificationStore.pushNotification({
        title: "Waste water updated successfully",
        type: "success",
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.error ?? "Waste water update failed",
        type: "error",
      });
    }
    this.setIsLoading(false);
  };
}

const WasteWaterStoreContext = createContext(new WasteWaterStore());
export const useWasteWaterStore = () => {
  return useContext(WasteWaterStoreContext);
};
