import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { VO_JournalList } from "@app/products/property/journals/list/model";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";

const nameOf = nameOfFactory<VO_JournalList>();
export const colChargeRunJournals: IColumnFields[] = [
  {
    field: nameOf("Journal_Number"),
    title: "Journal Number",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) =>
      `${PROPERTY_JOURNALS_ROUTE}/${dataItem.Journal_Number}`,
  },
  {
    field: nameOf("Reference"),
    title: "Reference",
  },
  {
    field: nameOf("Description"),
    title: "Name",
  },
  {
    field: nameOf("Notes"),
    title: "Notes",
  },
  {
    field: nameOf("Debit_Total"),
    title: "Debit Total",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Credit_Total"),
    title: "Credit Total",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Session_Number"),
    title: "Session Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("No_of_Transactions"),
    title: "Number of Transactions",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Journal_Status_Name"),
    title: "Status",
  },
  {
    field: nameOf("Journal_Type_Name"),
    title: "Type",
  },
  {
    field: nameOf("Created_On"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Created_By"),
    title: "Created By",
  },

  {
    field: nameOf("Authorised_By"),
    title: "Authorised By",
  },
  {
    field: nameOf("Authorised_On"),
    title: "Authorised On",
    format: DATETIME_FORMAT.DATETIME,
  },
];
