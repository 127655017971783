import { history } from "@/AppRoutes";
import {
  getBuildingRegisterEasementById,
  postBuildingRegisterEasement,
  putBuildingRegisterEasement,
} from "@app/products/building/registers/easements/[id]/api";
import { EASEMENTS_MANAGE_ROUTE } from "@app/products/building/registers/easements/[id]/constant";
import {
  IBuildingRegisterEasementFormData,
  IBuildingRegisterEasementRequest,
} from "@app/products/building/registers/easements/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class BuildingRegistersEasementsStore {
  private _easements?: IBuildingRegisterEasementFormData = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get easements() {
    return this._easements;
  }
  setEasements = (easements: IBuildingRegisterEasementFormData | undefined) => {
    runInAction(() => {
      this._easements = easements;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get easementId() {
    return this._easements?.ID;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._easements = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadEasements = async (easementId: number) => {
    this.setIsLoading(true);
    const response = await getBuildingRegisterEasementById(easementId);
    let errorResponse, newEasements;

    if (isSuccessResponse(response)) {
      newEasements = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }

    this.setResponseLoadError(errorResponse);
    this.setEasements(newEasements);
    this.setIsLoading(false);
  };

  createBuildingRegistersEasement = async (
    data: IBuildingRegisterEasementRequest
  ) => {
    this.setIsLoading(true);
    await postBuildingRegisterEasement(data);
    appNotificationStore.pushNotification({
      title: "Building registers easement created successfully",
      type: "success",
      onClose: () => {
        history.replace(`${EASEMENTS_MANAGE_ROUTE}/${data?.ID ?? 1}`);
      },
    });
    this.setIsLoading(false);
  };

  updateBuildingRegistersEasement = async (
    data: IBuildingRegisterEasementRequest
  ) => {
    this.setIsLoading(true);
    await putBuildingRegisterEasement(data);
    appNotificationStore.pushNotification({
      title: "Building registers easement updated successfully",
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const buildingRegistersEasementsStoreContext = createContext(
  new BuildingRegistersEasementsStore()
);
export const useBuildingRegistersEasementsStore = () => {
  return useContext(buildingRegistersEasementsStoreContext);
};
