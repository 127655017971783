import { DTO_CentrelinkResponseBatchDetail } from "@app/products/property/centrelink-customer-confirmation/view-responses/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getResponseBatchDetails = async (): Promise<
  APIResponse<DTO_CentrelinkResponseBatchDetail>
> => {
  try {
    return await CoreAPIService.getClient().get(
      "api/property/int/centrelinkresponses/detail"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
