import { DTO_Role } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ContactRoleCellStore {
  private _dropdownList: DTO_Role[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get dropdownList() {
    return this._dropdownList;
  }
  setDropdownList = (dropdownList: DTO_Role[]) => {
    runInAction(() => {
      this._dropdownList = dropdownList;
    });
  };
}

const contactRoleCellStoreContext = createContext(new ContactRoleCellStore());
export const useContactRoleCellStore = () => {
  return useContext(contactRoleCellStoreContext);
};
