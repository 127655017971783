import authHeader from "@common/apis/auth-header";
import { APIResponse } from "@common/apis/model";
import axios from "axios";

export const getWorkspaceId = async (
  ): Promise<APIResponse<any>> => {
    try {
      return await axios.get(
        `/api/powerBIAuthentication/get-workspace-id`,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      const errorResponse: APIResponse = error as any;
      return {
        ...errorResponse,
        error: errorResponse.data?.Message,
      };
    }
  };

export const getPowerBIReportList = async (
): Promise<APIResponse<any>> => {
  try {
    return await axios.get(
      `/api/powerBIAuthentication/get-reports-in-group`,
      {
        headers: authHeader(),
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getPowerBIReport = async (
  reportId: string
): Promise<APIResponse<any>> => {
  try {
    return await axios.get(
      `/api/powerBIAuthentication/get-report-in-group/${reportId}`,
      {
        headers: authHeader(),
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const generateEmbeddedReportToken = async (
  idReportEmbed: string,
): Promise<APIResponse<any>> => {
  try {
    return await axios.get(
      `/api/powerBIAuthentication/generate-embed-token/${idReportEmbed}`,
      {
        headers: authHeader(),
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const exportReportPB = async (
  reportId: string
): Promise<APIResponse<any>> => {
  try {
    return await axios.get(
      `/api/powerBIAuthentication/export-report-in-group/${reportId}`,
      {
        headers: authHeader(),
        responseType: "arraybuffer",
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

//#region Import Power BI Report not yet
//#endregion
