import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const getRelatedAppealComplete = async (
  id: number
): Promise<APIResponse<IKeyValuePacket[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IKeyValuePacket[]>(
      `api/tp/ppr/internal/application/${id}/related-appeal-complete`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
