import { putApproveResponse } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/approve-response/api";
import { pprEnquiryInstance } from "@app/products/town-planning/ppr/enquiries/_id/store";

import { isSuccessIdentityPacket } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class ApproveResponseButtonStore {
  private _isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  approveResponse = async (id?: number) => {
    this.setIsLoading(true);
    if (!id) return;
    const response = await putApproveResponse(id);
    if (isSuccessIdentityPacket(response)) {
      appNotificationStore.clearNotifications();
      pprEnquiryInstance.setPPREnquiryWithLoading(response.data?.Register);
      appNotificationStore.pushNotification({
        type: "success",
        title: "Response approved successfully",
      });
      if (
        response.data?.Notifications &&
        response.data?.Notifications.length > 0
      ) {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Approve response",
          type: "warning",
          description: response.data?.Notifications,
        });
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Response approved failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsLoading(false);
  };
}

export const approveResponseButtonStore = new ApproveResponseButtonStore();
const ApproveResponseButtonContext = createContext(approveResponseButtonStore);
export const useApproveResponseButtonStore = () => {
  return useContext(ApproveResponseButtonContext);
};
