import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { LIMIT_RECORD_BY_API } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/bookmark/constant";
import { getDisplayCategoryName } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/util";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

export interface IBookmarkIconProps {
  detail?: (selectedRow: any) => string;
  linkUrl?: string;
  isDisabledRecordListView?: boolean;
  displayName?: (selectedRow: any) => string;
  recordType: RECORDTYPE;
  productType: PRODUCT_TYPE;
  directToOtherPlace?: IDirectToOtherList;
  listViewDetail?: () => string;
  listViewDisplayName?: () => string;
  gridSelectedIds?: any[];
  isEmptyGridSelectedRow?: boolean;
  gridSelectedRows?: any[];
  gridTotalSelected?: number;
  bookmarkId?: number;
  disabled?: boolean;
  primaryField?: string;
}
interface IDirectToOtherList {
  notManagePage: boolean;
  urlDirect: (data: any) => string;
}

export const BookmarkIcon = observer(
  ({
    detail,
    linkUrl,
    displayName,
    recordType,
    productType,
    listViewDetail,
    listViewDisplayName,
    isDisabledRecordListView = false,
    directToOtherPlace,
    gridSelectedIds = [],
    isEmptyGridSelectedRow = true,
    gridSelectedRows = [],
    gridTotalSelected = 0,
    bookmarkId,
    disabled,
    primaryField,
  }: IBookmarkIconProps) => {
    const { currentUserInfo } = useGlobalStore();
    const pageNameDefault = getDisplayCategoryName(linkUrl);

    const { pathname } = useLocation();
    const bookmarkList = useMemo(() => {
      if (!isNil(currentUserInfo)) {
        if (isEmptyGridSelectedRow) {
          return [
            {
              Text: listViewDetail ? listViewDetail() : pageNameDefault,
              LinkUrl: pathname,
              LinkText: listViewDisplayName
                ? listViewDisplayName()
                : pageNameDefault,
              User_ID: +currentUserInfo.UserPKID,
              ProductType_ENUM: productType,
              RecordType_ENUM: recordType,
            },
          ];
          //TODO: Enhance later (coding temporary for some special cases)
        } else if (directToOtherPlace && directToOtherPlace?.notManagePage) {
          const { urlDirect } = directToOtherPlace;
          return gridSelectedIds.map((id: string, index: number) => {
            id = primaryField
              ? gridSelectedRows[index]?.[primaryField]
              : bookmarkId?.toString() ?? id;

            return {
              Text:
                detail && gridSelectedRows[index]
                  ? detail(gridSelectedRows[index])
                  : pageNameDefault,
              LinkUrl: urlDirect(id),
              LinkText:
                displayName && gridSelectedRows[index]
                  ? displayName(gridSelectedRows[index])
                  : `${pageNameDefault} - ${id}`,
              User_ID: +currentUserInfo.UserPKID,
              ProductType_ENUM: productType,
              RecordType_ENUM: recordType,
              Record_ID: +id,
            };
          });
        } else {
          return gridSelectedIds.map((id: string, index: number) => {
            id = primaryField
              ? gridSelectedRows[index]?.[primaryField]
              : bookmarkId?.toString() ?? id;

            return {
              Text:
                detail && gridSelectedRows[index]
                  ? detail(gridSelectedRows[index])
                  : pageNameDefault,
              LinkUrl: `${linkUrl}/${id}`,
              LinkText:
                displayName && gridSelectedRows[index]
                  ? displayName(gridSelectedRows[index])
                  : `${pageNameDefault} - ${id}`,
              User_ID: +currentUserInfo.UserPKID,
              ProductType_ENUM: productType,
              RecordType_ENUM: recordType,
              Record_ID: +id,
            };
          });
        }
      }
      return [];
    }, [
      currentUserInfo,
      isEmptyGridSelectedRow,
      listViewDetail,
      pageNameDefault,
      pathname,
      listViewDisplayName,
      productType,
      recordType,
      gridSelectedIds,
      detail,
      gridSelectedRows,
      linkUrl,
      displayName,
      directToOtherPlace,
      bookmarkId,
      primaryField,
    ]);

    const isDisabledButtonBookmark = useMemo(() => {
      if (isDisabledRecordListView) {
        //This condition check in list view if having any record button will be disabled
        return gridTotalSelected > 0;
      }
      return gridTotalSelected > LIMIT_RECORD_BY_API;
    }, [gridTotalSelected, isDisabledRecordListView]);

    return (
      <ActionBarBookmarkIcon
        bookmarkList={bookmarkList}
        bookmarkOptions={isEmptyGridSelectedRow ? "page" : "record"}
        disabled={isDisabledButtonBookmark || disabled}
      />
    );
  }
);
