import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { NARSYSTEM } from "@common/constants/enumerations";
import { ComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/combo-box/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { TextAreaGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/text-area/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

const LocationContactsSearch = ({ formRenderProps }: ICommonFormProps) => {
  const { valueGetter } = formRenderProps;
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const data = useDataSetting(configData, [
    "CorporateSettings_NARSystem",
    "CorporateSettings_NAR_Custom_LocationContactsSearch",
    "CorporateSettings_NARCustomDynamic_LocationContactsSearch",
    "CorporateSettings_NAR_Custom_ListContactsAtAddress_WHERE_Clause",
    "CorporateSettings_NARCustomDynamic_ListContactsAtLocation_SelectionQuery",
  ]);

  const narSystem = valueGetter(
    data.CorporateSettings_NARSystem?.FieldName as string
  );

  const enableSearchCustom = valueGetter(
    data.CorporateSettings_NAR_Custom_LocationContactsSearch
      ?.FieldName as string
  );

  const enableSearchCustomDynamic = valueGetter(
    data.CorporateSettings_NARCustomDynamic_LocationContactsSearch
      ?.FieldName as string
  );

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        {narSystem === NARSYSTEM.Custom.toString() && (
          <SwitchGlobalSettings
            data={data.CorporateSettings_NAR_Custom_LocationContactsSearch}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        )}
        {narSystem === NARSYSTEM.CustomDynamic.toString() && (
          <SwitchGlobalSettings
            data={
              data.CorporateSettings_NARCustomDynamic_LocationContactsSearch
            }
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        )}
      </div>

      {narSystem === NARSYSTEM.Custom.toString() && enableSearchCustom && (
        <>
          <ComboBoxGlobalSettings
            data={configData && configData["AvailableParameters"]}
            isEditing={isEditing}
          />
          <TextAreaGlobalSettings
            data={
              data.CorporateSettings_NAR_Custom_ListContactsAtAddress_WHERE_Clause
            }
            isEditing={isEditing}
            rows={10}
          />
        </>
      )}

      {narSystem === NARSYSTEM.CustomDynamic.toString() &&
        enableSearchCustomDynamic && (
          <>
            <ComboBoxGlobalSettings
              data={configData && configData["AvailableParameters"]}
              isEditing={isEditing}
            />
            <TextAreaGlobalSettings
              data={
                data.CorporateSettings_NARCustomDynamic_ListContactsAtLocation_SelectionQuery
              }
              isEditing={isEditing}
              rows={10}
            />
          </>
        )}
    </section>
  );
};

export default observer(LocationContactsSearch);
