import { CORE_GET_LOCAL_GOVERNMENTS_ODATA } from "@app/core/components/common/constant";
import { colAppNoPicker } from "@app/products/town-planning/ppr/enquiries/_id/components/input-picker/app-no-picker/config";
import { colCategoryPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/category-picker-search/config";
import { colCouncilPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/council-picker/config";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { colKeyWordsPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/config";
import { colOfficerPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/config";
import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { Application_Decision } from "@app/products/town-planning/ppr/[id]/model";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { Svc_InfoExpertRecordType } from "@common/models/corporateSetting";
import { Svc_InspectionType } from "@common/models/inspectionType";
import { KeywordLite } from "@common/models/keyword";
import { nameOfFactory } from "@common/utils/common";
import { ICCGridProps } from "@components/cc-grid/_index";

export const pprDecisionDateMap: {
  [key in Application_Decision]?: string;
} = {
  [Application_Decision.NOOBJECTION]:
    "Does not object to the granting of the permit.",
  [Application_Decision.OBJECTION]:
    "Objects to the granting of the permit on any specified ground.",
  [Application_Decision.NOOBJECTIONSUBJECTTOCONDITIONS]:
    "Does not object if the permit is subject to conditions specified by the referral authority.",
};

/* ====  Option Input Picker Team Leader  ==== */
const nameOfTeamLeader = nameOfFactory<IOfficer>();
const nameOfInspectionType = nameOfFactory<Svc_InspectionType>();
const nameOfCoreKeyword = nameOfFactory<KeywordLite>();
const nameOfInfoXpertRecordType = nameOfFactory<Svc_InfoExpertRecordType>();

export const optionInputPickerTeamLeader: IOptionInputPicker = {
  dialog: {
    titleHeader: "Select Delegated Approver",
    maxWidth: "60%",
  },
  boxSearch: {
    colSearch: nameOfTeamLeader("Name"),
    colShowComboboxSearch: [
      {
        title: "Name",
        field: nameOfTeamLeader("Name"),
      },
    ],
  },
  grid: {
    columnFields: [
      { title: "Name", field: nameOfTeamLeader("Name") },
      { title: "Position", field: nameOfTeamLeader("Position") },
      { title: "Email", field: nameOfTeamLeader("Email") },
      { title: "Work Phone", field: nameOfTeamLeader("WorkPhone") },
      { title: "Mobile", field: nameOfTeamLeader("Mobile") },
    ],
    primaryField: "ID",
    dataUrl: "/odata/core/internal/officers",
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
    state: {
      sort: [
        {
          field: nameOfTeamLeader("Name"),
          dir: "asc",
        },
      ],
    },
  },
};
/* ====  / Option Input Team Leader  ==== */

/* ====  Option Input Picker Officer  ==== */
export const optionInputPickerOfficer: IOptionInputPicker = {
  ...optionInputPickerTeamLeader,
  dialog: {
    ...optionInputPickerTeamLeader.dialog,
    titleHeader: "Pick Officer",
  },
  grid: {
    ...optionInputPickerTeamLeader.grid,
    columnFields: colOfficerPicker,
    apiUrlCallBackByID: "api/core/internal/siteuser/lightweight-siteuser/[ID]",
  },
};
/* ====  / Option Input Picker Officer  ==== */

/* ====  Option Input Picker Rating  ==== */
export const optionInputPickerRating: IOptionInputPicker = {
  ...optionInputPickerTeamLeader,
  dialog: {
    ...optionInputPickerTeamLeader.dialog,
    titleHeader: "Rating",
    maxWidth: "30%",
  },
  grid: {
    ...optionInputPickerTeamLeader.grid,
    primaryField: "Keyword_ID",
    columnFields: colKeyWordsPicker,
    dataUrl:
      "/odata/core/internal/cckeyword/GetKeywordsByType(keywordType=7001,productType=22)",
    apiUrlCallBackByID: "/api/core/internal/keywordbyid/[ID]",
  },
};
/* ====  / Option Input Picker Rating  ==== */

/* ====  Option Input Closure reason  ==== */
export const optionInputPickerClosureReason: IOptionInputPicker = {
  ...optionInputPickerTeamLeader,
  dialog: {
    ...optionInputPickerTeamLeader.dialog,
    titleHeader: "Closure reason",
    maxWidth: "30%",
  },
  grid: {
    ...optionInputPickerTeamLeader.grid,
    primaryField: "Keyword_ID",
    columnFields: colKeyWordsPicker,
    dataUrl:
      "/odata/core/internal/cckeyword/GetKeywordsByType(keywordType=7038,productType=22)",
    apiUrlCallBackByID: "/api/core/internal/keywordbyid/[ID]",
  },
};
/* ====  / Option Input Closure reason  ==== */

/* ====  Option Input Picker Responsible authority  ==== */
export const optionInputPickerResponsibleAuthority: IOptionInputPicker = {
  ...optionInputPickerTeamLeader,
  dialog: {
    ...optionInputPickerTeamLeader.dialog,
    titleHeader: "Pick Council",
  },

  grid: {
    ...optionInputPickerTeamLeader.grid,
    columnFields: colCouncilPicker,
    dataUrl: CORE_GET_LOCAL_GOVERNMENTS_ODATA,
    apiUrlCallBackByID: "/api/core/internal/council/[ID]",
  },
};
/* ====  / Option Input Picker Responsible authority  ==== */

/* ====  Option Input Picker DoT office  ==== */
export const optionInputPickerDoTOffice: IOptionInputPicker = {
  ...optionInputPickerTeamLeader,
  dialog: {
    ...optionInputPickerTeamLeader.dialog,
    titleHeader: "Region",
    maxWidth: "30%",
  },
  grid: {
    ...optionInputPickerTeamLeader.grid,
    primaryField: "Keyword_ID",
    columnFields: colKeyWordsPicker,
    dataUrl:
      "/odata/core/internal/cckeyword/GetKeywordsByType(keywordType=4,productType=22)",
    apiUrlCallBackByID: "/api/core/internal/keywordbyid/[ID]",
  },
};
/* ====  / Option Input Picker DoT office  ==== */

/* ====  Option Input Picker Referral trigger  ==== */
export const optionInputPickerReferralTrigger: IOptionInputPicker = {
  ...optionInputPickerTeamLeader,
  dialog: {
    ...optionInputPickerTeamLeader.dialog,
    titleHeader: "Referral Trigger",
    maxWidth: "30%",
  },
  grid: {
    ...optionInputPickerTeamLeader.grid,
    primaryField: "Keyword_ID",
    columnFields: colKeyWordsPicker,
    dataUrl:
      "/odata/core/internal/cckeyword/GetKeywordsByType(keywordType=94,productType=22)",
    apiUrlCallBackByID: "/api/core/internal/keywordbyid/[ID]",
  },
};

/* ====  / Option Input Picker Referral trigger  ==== */

/* ====  Option Input Picker Application number  ==== */
export const optionInputPickerApplicationNumber: IOptionInputPicker = {
  ...optionInputPickerTeamLeader,
  dialog: {
    ...optionInputPickerTeamLeader.dialog,
    titleHeader: "Pick Application",
  },
  grid: {
    ...optionInputPickerTeamLeader.grid,
    columnFields: colAppNoPicker,
    dataUrl:
      "/odata/tp/ppr/internal/pprapplication/Register(openRecsOnly=false,myRecs=false)",
    state: {
      sort: [
        {
          field: "ID",
          dir: "desc",
        },
      ],
    },
  },
  boxSearch: {
    ...optionInputPickerTeamLeader.boxSearch,
    colSearch: "ApplicationNumber",
    colShowComboboxSearch: [
      {
        title: "ApplicationNumber",
        field: "ApplicationNumber",
      },
    ],
  },
};

/* ====  / Option Input Picker Application number  ==== */

/* ====  Option Input Picker Reason for permit  ==== */
export const optionInputPickerReasonForPermit: IOptionInputPicker = {
  ...optionInputPickerTeamLeader,
  dialog: {
    ...optionInputPickerTeamLeader.dialog,
    titleHeader: "Reason for Permit",
    maxWidth: "30%",
  },
  grid: {
    ...optionInputPickerTeamLeader.grid,
    primaryField: "Keyword_ID",
    columnFields: colKeyWordsPicker,
    dataUrl:
      "/odata/core/internal/cckeyword/GetKeywordsByType(keywordType=7029,productType=22)",
    apiUrlCallBackByID: "/api/core/internal/keywordbyid/[ID]",
  },
};
/* ====  / Option Input Picker Reason for permit  ==== */

/* ====  Option Input Picker Application category  ==== */
export const optionInputPickerApplicationCategory: IOptionInputPicker = {
  ...optionInputPickerTeamLeader,
  dialog: {
    ...optionInputPickerTeamLeader.dialog,
    titleHeader: "Pick Categories",
  },

  grid: {
    ...optionInputPickerTeamLeader.grid,
    columnFields: colCategoryPicker,
    selectableMode: "multiple",
    dataUrl:
      "/odata/tp/internal/tpapplicationcategory/GetApplicationcategory(recordType=1300)",
    state: {
      sort: [
        {
          field: "ApplicationCategory_Name",
          dir: "asc",
        },
      ],
    },
  },
  boxSearch: {
    ...optionInputPickerTeamLeader.boxSearch,
    colSearch: "ApplicationCategory_Name",
    colShowComboboxSearch: [
      {
        title: "Category Name",
        field: "ApplicationCategory_Name",
      },
    ],
  },
};
/* ====  / Option Input Picker Application category  ==== */

/* ====  Option Input Picker Application category manage page  ==== */
export const optionInputPickerApplicationCategoryManagePage: IOptionInputPicker =
  {
    ...optionInputPickerApplicationCategory,
    grid: {
      ...optionInputPickerApplicationCategory.grid,
      dataUrl:
        "/odata/tp/internal/tpapplicationcategory/GetApplicationcategory(recordType=1399)",
    },
  };

export const inspectionTypePickerOption = (
  productType: PRODUCT_TYPE_NUMBER,
  grid?: Partial<ICCGridProps>
): IOptionInputPicker => {
  return {
    dialog: {
      titleHeader: "Pick Inspection Type",
      maxWidth: "60%",
    },
    boxSearch: {
      colSearch: nameOfInspectionType("Name"),
      colShowComboboxSearch: [
        {
          title: "Name",
          field: nameOfInspectionType("Name"),
        },
      ],
    },
    grid: {
      columnFields: [
        { title: "Name", field: nameOfInspectionType("Name") },
        { title: "Group", field: nameOfInspectionType("Group") },
        {
          title: "Classification",
          field: nameOfInspectionType("Classification"),
        },
        { title: "Description", field: nameOfInspectionType("Description") },
        { title: "Amount", field: nameOfInspectionType("FeeAmount") },
        {
          title: "Mandatory Inspection Header",
          field: nameOfInspectionType("MandatoryInspectionHeader"),
        },
      ],
      primaryField: nameOfInspectionType("ID"),
      dataUrl: `odata/core/internal/inspectiontypes/GetInspectionTypesByProductType(productType=${productType},selectedIDs=[${grid?.selectedRows
        ?.map((item) => item.ID)
        .toString()}])?$count=true&`,
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        group: [
          {
            field: nameOfInspectionType("MandatoryInspectionHeader"),
            dir: "asc",
          },
        ],
      },
      ...grid,
    },
  };
};
export const getInspectionComplianceInputPickerOption = (
  option?: Partial<IOptionInputPicker>,
  grid?: Partial<ICCGridProps>
): IOptionInputPicker => {
  return {
    dialog: {
      titleHeader: option?.dialog?.titleHeader ?? "Inspection Compliance",
      maxWidth: option?.dialog?.maxWidth ?? "60%",
    },
    boxSearch: option?.boxSearch ?? {
      colSearch: nameOfCoreKeyword("Name"),
      colShowComboboxSearch: [
        {
          title: "Name",
          field: nameOfCoreKeyword("Name"),
        },
      ],
    },
    grid: {
      columnFields: [
        { title: "Name", field: nameOfCoreKeyword("Name") },
        { title: "Description", field: nameOfCoreKeyword("Description") },
      ],
      primaryField: nameOfCoreKeyword("Keyword_ID"),
      dataUrl: `odata/core/internal/cckeyword/GetKeywordsByType(keywordType=${KEYWORD_TYPE.Core_InspectionCompliance})?$count=true&`,
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: [{ field: nameOfCoreKeyword("Name"), dir: "asc" }],
      },
      ...grid,
    },
  };
};
export const getInfoExpertRecordTypeInputPickerOption = (
  option?: Partial<IOptionInputPicker>,
  grid?: Partial<ICCGridProps>
): IOptionInputPicker => {
  return {
    dialog: {
      titleHeader: option?.dialog?.titleHeader ?? "infoXpert Record Types",
      maxWidth: option?.dialog?.maxWidth ?? "60%",
    },
    boxSearch: option?.boxSearch ?? {
      colSearch: nameOfInfoXpertRecordType("Name"),
      colShowComboboxSearch: [
        {
          title: "Name",
          field: nameOfInfoXpertRecordType("Name"),
        },
      ],
    },
    grid: {
      columnFields: [
        { title: "Name", field: nameOfInfoXpertRecordType("Name") },
        {
          title: "Description",
          field: nameOfInfoXpertRecordType("Description"),
        },
      ],
      primaryField: nameOfInfoXpertRecordType("ID"),
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: [{ field: nameOfInfoXpertRecordType("Name"), dir: "asc" }],
      },
      ...grid,
    },
  };
};

export const getOfficerInputPickerOption = (
  grid?: Partial<ICCGridProps>
): IOptionInputPicker => {
  return {
    ...optionInputPickerTeamLeader,
    dialog: {
      ...optionInputPickerTeamLeader.dialog,
      titleHeader: "Pick Officer",
    },
    grid: {
      ...optionInputPickerTeamLeader.grid,
      columnFields: colOfficerPicker,
      ...grid,
    },
  };
};

/* ====  Option Input Picker Application number  ==== */
export const optionInputPickerAddress: IOptionInputPicker = {
  ...optionInputPickerTeamLeader,
  grid: {
    ...optionInputPickerTeamLeader.grid,
    dataUrl: undefined,
    dataUrlSearch: `odata/core/internal/addresses/GetAddressList(searchText='[searchText]')`,
    primaryField: "PlaceId",
    apiUrlCallBackByID: "/api/core/internal/address/getaddress/[ID]",
  },
  boxSearch: {
    ...optionInputPickerTeamLeader.boxSearch,
    colShowComboboxSearch: [
      {
        title: "Description",
        field: "Description",
      },
    ],
  },
};

/* ====  / Option Input Picker Application number  ==== */
