import { SYSTEM_ADMIN_APPLICATION_CATEGORIES_ROUTE } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewPPRApplicationCategoriesButton = () => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New"
      onClick={() => {
        history.push(`${SYSTEM_ADMIN_APPLICATION_CATEGORIES_ROUTE}/new`);
      }}
    />
  );
};
