import { ExistedCRMSPhoneMessage } from "@app/products/crms/phone-messages/[id]/components/forms/existed/_index";
import { useCRMSPhoneMessageStore } from "@app/products/crms/phone-messages/[id]/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const ManageCRMSPhoneMessage = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { resetStore, loadCRMSPhoneMessage } = useCRMSPhoneMessageStore();

  useEffect(() => {
    loadCRMSPhoneMessage(parseInt(id), false);
    return () => {
      resetStore(true);
    };
  }, [loadCRMSPhoneMessage, id, resetStore]);

  return <ExistedCRMSPhoneMessage />;
});

export default ManageCRMSPhoneMessage;
