import { mockSingleBuildingRegistersCouncilConsent } from "@app/products/building/registers/council-consent/[id]/mock";
import { IBuildingRegistersCouncilConsentFormData } from "@app/products/building/registers/council-consent/[id]/model";
import { IBuilding } from "@app/products/building/[id]/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";
export const postBuildingRegistersCouncilConsent = async (
  requestBody: IBuilding
) => {
  await sleep(2000);
};

export const putBuildingRegistersCouncilConsent = async (
  requestBody: IBuilding
) => {
  await sleep(2000);
};

export const getBuildingRegistersCouncilConsentById = async (
  id?: number
): Promise<
  APIResponse<IBuildingRegistersCouncilConsentFormData | undefined>
> => {
  await sleep(1000);
  return {
    data: {
      ...mockSingleBuildingRegistersCouncilConsent,
      ID: id,
    } as IBuildingRegistersCouncilConsentFormData,
    status: APIResponseStatus.SUCCESS,
    statusText: "Ok",
    headers: {},
    config: {},
  };
};
