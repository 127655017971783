import { ResponseMessage } from "@app/products/property/model";
import { Valuation } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/valuations/dialogs/new-valuation/model";
import { DTO_Supplementary_Assessment_Valuation } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/valuations/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getAssessmentAdjustmentSupplementaryValuations = async (
  supplementaryId?: number,
  assessmentId?: number
): Promise<APIResponse<DTO_Supplementary_Assessment_Valuation[]>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/supplementary/assessmentadjustment/valuation/${supplementaryId}/${assessmentId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const createValuation = async (
  supplementaryId: number,
  assessmentId: number,
  payload: Valuation
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/supplementary/assessessmentvaluation/${supplementaryId}/${assessmentId}`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const updateValuation = async (
  valuationId: number,
  payload: DTO_Supplementary_Assessment_Valuation
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().put(
      `api/property/internal/supplementary/valuation/${valuationId}`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const deleteValuation = async (
  valuationId: number
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().delete(
      `api/property/internal/supplementary/assessessmentvaluation/${valuationId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
