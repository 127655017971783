import { IGroupsLov } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NoticeRunGroupStepStore {
  private _groupLOVs?: IGroupsLov = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get groupLOVs() {
    return toJS(this._groupLOVs);
  }
  setGroupLOVs = (groupLOVs?: IGroupsLov) => {
    runInAction(() => {
      this._groupLOVs = groupLOVs;
    });
  };
}

const noticeRunGroupStepStoreContext = createContext(
  new NoticeRunGroupStepStore()
);
export const useNoticeRunGroupStepStore = () => {
  return useContext(noticeRunGroupStepStoreContext);
};
