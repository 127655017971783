import { HMFieldMapping } from "@app/products/hm/model";
import { DATE_FORMAT } from "@common/constants/common-format";

export const colHMRenewalsRequiresNotice = [
  {
    field: HMFieldMapping.TradingName,
    title: "Trading Name",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.RegNo,
    title: "Registration Number",
    width: 150,
  },
  {
    field: HMFieldMapping.Address,
    title: "Address",
    width: 150,
  },
  {
    field: HMFieldMapping.Area,
    title: "Area",
    width: 150,
  },
  {
    field: HMFieldMapping.Phone,
    title: "Phone",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesClassification,
    title: "Classification",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesType,
    title: "Type",
    width: 150,
  },
  {
    field: HMFieldMapping.Risk,
    title: "Risk",
    width: 150,
  },
  {
    field: HMFieldMapping.Officer,
    title: "Officer",
    width: 150,
  },
  {
    field: HMFieldMapping.RenewalStatus,
    title: "Due Status",
    width: 150,
  },
  {
    field: HMFieldMapping.AmountOS,
    title: "Outstanding",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_RenewalApproved,
    title: "Renewal Approved",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_RenewalFeeCreated,
    title: "Fee Created",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_PenaltyFeeCreated,
    title: "Penalty Created",
    width: 150,
  },
  {
    field: HMFieldMapping.RefNo,
    title: "Reference Number",
    width: 150,
  },
  {
    field: HMFieldMapping.FileNumber,
    title: "File Number",
    width: 150,
  },
  {
    field: HMFieldMapping.DebtorNo,
    title: "Debtor Number",
    width: 150,
  },
  {
    field: HMFieldMapping.AssessmentNo,
    title: "Assessment Number",
    width: 150,
  },
  {
    field: HMFieldMapping.Proprietor,
    title: "Proprietor",
    width: 150,
  },
  {
    field: HMFieldMapping.PreferredMethod,
    title: "Preferred Method",
    width: 150,
  },
  {
    field: HMFieldMapping.PostalAddress,
    title: "Postal Address",
    width: 150,
  },
  {
    field: HMFieldMapping.PostalAddress2,
    title: "Postal Address 2",
    width: 150,
  },
  {
    field: HMFieldMapping.NoEmployees,
    title: "Emp",
    width: 150,
  },
  {
    field: HMFieldMapping.PrimaryContact,
    title: "Contact",
    width: 150,
  },
  {
    field: HMFieldMapping.FSPContact,
    title: "FSS Contact",
    width: 150,
  },
  {
    field: HMFieldMapping.DateLodged,
    title: "Lodged",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.DateRegistrationExpires,
    title: "Due Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.Fax,
    title: "Fax",
    width: 150,
  },
  {
    field: HMFieldMapping.AmountOS,
    title: "Outstanding",
    width: 150,
  },
];
