import { DIRECT_DEBIT_GROUPS } from "@app/products/direct-debit/constant";
import { getDirectDebitGroupById } from "@app/products/direct-debit/system-admin/groups/[id]/api";
import { postSaveDirectDebitGroup } from "@app/products/direct-debit/system-admin/groups/[id]/components/buttons/save/api";
import { checkIsInactiveDirectDebitGroup } from "@app/products/direct-debit/system-admin/groups/[id]/util";
import { DirectDebitGroup } from "@app/products/direct-debit/system-admin/model";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { IdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

export const SaveButtonDirectDebitGroup = observer(
  ({ disabled }: { disabled?: boolean }) => {
    //#region STORE ========/
    const {
      submitFormGetData,
      isLoadingForm,
      setIsLoadingForm,
      setInitialDataForms,
    } = useFlexibleFormStore();
    //#endregion STORE =====/

    //#region HOOK ========/
    const { pushNotificationPortal } = useNotificationPortalStore();
    const isNew = useIsNew();
    const history = useHistory();
    const { cancelToken } = useCancelRequest();
    //#endregion HOOK =====/

    const saveDirectDebitGroupSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const directDebitGroup: DirectDebitGroup = initialData;
            return postSaveDirectDebitGroup(directDebitGroup);
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<IdentityPacket | undefined> =
              dataFromApi;
            if (isNew) {
              if (response?.data?.ID) {
                const urlRedirect =
                  DIRECT_DEBIT_GROUPS + "/" + response.data.ID;
                pushNotificationPortal({
                  title: "Direct debit group saved successfully.",
                  type: "success",
                  route: urlRedirect,
                  callBack() {
                    history.replace(urlRedirect);
                  },
                });
              } else {
                pushNotificationPortal({
                  title: "Not found new direct debit group Id.",
                  type: "error",
                  autoClose: false,
                });
              }
              return false;
            }
          },
          extractParamForNextAPI: ({ dataFromApi }) => {
            const response: APIResponse<IdentityPacket> = dataFromApi;
            return response?.data?.ID;
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "Direct debit group could not be saved.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
        {
          fetch: ({ dataFromExtractParam }) => {
            const id: number = dataFromExtractParam;
            return getDirectDebitGroupById(id, cancelToken());
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<DirectDebitGroup> = dataFromApi;
            const ddGroupFormData = response?.data;
            //#region save data to store ========/
            setInitialDataForms({
              GeneralForm: ddGroupFormData,
            });
            //#endregion save data to store =====/
            pushNotificationPortal({
              title: "Direct debit group saved successfully.",
              type: "success",
            });
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "Direct debit group could not be saved.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleClickButton = async () => {
      const directDebitGroup: DirectDebitGroup = await submitFormGetData(
        "GeneralForm"
      );

      if (directDebitGroup) {
        setIsLoadingForm(true);
        await saveDirectDebitGroupSlider.fetchApi({
          initialData: directDebitGroup,
        });
        setIsLoadingForm(false);
      }
    };

    return (
      <CCNavButton
        title="Save"
        onClick={handleClickButton}
        disabled={
          disabled || isLoadingForm || checkIsInactiveDirectDebitGroup()
        }
      />
    );
  }
);
