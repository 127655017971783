import {
  CommunicationButton,
  ICommunicationButton,
} from "@app/core/communication/buttons-list-view/components/buttons/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const CommunicationButtonListView = observer(
  (props: ICommunicationButton) => {
    const { gridSelectedRows } = useCCProductListViewStore();
    return (
      <CommunicationButton {...props} gridSelectedRows={gridSelectedRows} />
    );
  }
);
