import { ICCRoute } from "@common/constants/ICCRoute";

export const contactGoldenContactsRoute: ICCRoute = {
  path: "golden-contacts",
  name: "Golden Contacts",
  children: [
    {
      path: "list",
      name: "List",
      component: require("./list/_index").default,
    },
  ],
};
