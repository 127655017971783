import { odataCoreGetFeeTypes } from "@app/core/components/common/utils";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { postCheckIsSuperAdmin } from "@app/core/notices/[id]/api";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { IGenerateColFieldBySetting } from "@common/models/settings";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { ExportFeeTypesButton } from "@common/pages/settings/lookups/fee-types/components/action-bar/buttons/export/_index";
import { FeeTypesImportButton } from "@common/pages/settings/lookups/fee-types/components/action-bar/buttons/import/_index";
import { FeeTypesDetailsTab } from "@common/pages/settings/lookups/fee-types/components/action-bar/side-bar/details/_index";
import { FeeTypeHistoryTab } from "@common/pages/settings/lookups/fee-types/components/action-bar/side-bar/history/_index";
import {
  feeTypeGridColumn,
  generateColFieldBySetting,
} from "@common/pages/settings/lookups/fee-types/config";
import { FEE_TYPE_ROUTE } from "@common/pages/settings/lookups/fee-types/constant";
import { Svc_FeeTypeView } from "@common/pages/settings/lookups/fee-types/model";
import { LookupsFeeTypeBookmark } from "@common/pages/settings/lookups/fee-types/util";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<Svc_FeeTypeView>();
const gridId = getUUID();
export default () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const { isLoadingPermission, checkPermissions } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_FeeType,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();
  const { settings } = useCommonCoreStore();
  const history = useHistory();
  const { currentUserInfo } = useGlobalStore();
  const { pushNotificationPortal } = useNotificationPortalStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );
    setIsLoading(false);
  };

  const checkIsSuperAdmin = async (userID: number) => {
    const response = await postCheckIsSuperAdmin(userID);
    if (isSuccessResponse(response)) {
      setIsSuperAdmin(response.data ?? false);
    } else {
      pushNotificationPortal({
        title: "Check permission tools button failed.",
        type: "error",
        autoClose: false,
        description: response?.error,
      });
    }
  };

  useEffectOnce(() => {
    handleCheckPermission();
    if (currentUserInfo?.UserPKID) {
      checkIsSuperAdmin(parseInt(currentUserInfo.UserPKID));
    }

    return () => {
      resetStore();
    };
  });

  const ToolsButton = () => {
    return isSuperAdmin ? (
      <CCNavButton title="Tools" type="more">
        <FeeTypesImportButton gridId={gridId} />
        <ExportFeeTypesButton gridId={gridId} />
      </CCNavButton>
    ) : (
      <></>
    );
  };

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title="New"
        onClick={() => {
          history.push(`${FEE_TYPE_ROUTE}/new`);
        }}
        disabled={
          !hasPermission || !checkPermissions(FormAction.CORE_ALLOW_DELETE)
        }
      />,
      <DeleteButton
        recordType={RECORDTYPE.CORE_FeeType}
        disabled={
          !hasPermission || !checkPermissions(FormAction.CORE_ALLOW_DELETE)
        }
        refreshGridIds={[gridId]}
      />,
      <ToolsButton />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={FEE_TYPE_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_FeeType}
        detail={LookupsFeeTypeBookmark.getBookmarkListViewDisplayName}
        displayName={
          LookupsFeeTypeBookmark.getBookmarkListViewDetailRecordGroup
        }
        listViewDetail={() =>
          LookupsFeeTypeBookmark.getBookmarkListViewDetail("Fee Types")
        }
        listViewDisplayName={
          LookupsFeeTypeBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <FeeTypesDetailsTab />,
      },
      {
        title: "History",
        component: <FeeTypeHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper
      isLoadingPermission={isLoadingPermission || isLoading}
    >
      <CCProductListView
        columnFields={generateColFieldBySetting({
          settings,
          defaultConfig: feeTypeGridColumn,
        } as IGenerateColFieldBySetting)}
        primaryField={nameOf("ID")}
        dataUrl={odataCoreGetFeeTypes()}
        state={{
          sort: [{ field: nameOf("Name"), dir: "asc" }],
        }}
        gridId={gridId}
      />
    </LoadingPermissionWrapper>
  );
};
