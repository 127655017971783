import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSAllocationsRoundsByYear: IColumnFields[] = [
  {
    field: CRSFieldMapping.AllocationYear,
    title: "Year",

    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: CRSFieldMapping.AllocationDate,
    title: "Allocation Date",

    format: DATE_FORMAT.DATE,
  },
  { field: CRSFieldMapping.ModeString, title: "Mode" },
  {
    field: CRSFieldMapping.RoundNumber,
    title: "Round",

    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.Service_Name, title: "Service Name" },
  { field: CRSFieldMapping.Method_Name, title: "Method" },

  { field: CRSFieldMapping.Name, title: "Name" },
  { field: CRSFieldMapping.Description, title: "Description" },
];
