import { LOCAL_LAWS_PERMIT_ROUTE } from "@app/products/local-laws/[id]/constant";
import { Svc_LLPermit } from "@app/products/local-laws/permits/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_LLPermit>();
export const colLocalLawsPermitsBy: IColumnFields[] = [
  {
    field: nameOf("PermitNumber"),
    title: "Permit Number",
    locked: true,
    linkTo: (dataItem: Svc_LLPermit) => ({
      pathname: `${LOCAL_LAWS_PERMIT_ROUTE}/` + dataItem.ID,
    }),
  },
  {
    field: nameOf("Applicant"),
    title: "Permit Holder",
  },
  { field: nameOf("SiteAddress"), title: "Address" },
  { field: nameOf("Locality"), title: "Locality" },
  { field: nameOf("Type"), title: "Permit Type" },
  { field: nameOf("StatusDueStatus"), title: "Status" },
  { field: nameOf("Officer"), title: "Officer" },
  {
    field: nameOf("EffectiveFrom"),
    title: "Effective From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("EffectiveTo"),
    title: "Effective To",
    format: DATE_FORMAT.DATE,
  },
];
