import { getActionTypes } from "@app/core/communication/dialogs/components/form-elememts/work-flow/api";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IResponseData } from "@common/models/service";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
export const WORKFLOW_STEP = "Workflow";
export const WorkflowStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ nameOf, formRenderProps }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const [isLoading, setIsLoading] = useState(false);
  const { settings } = useCommonCoreStore();
  const actionTypeDefaultKey = getNumberValueSetting(
    settings[ECorporateSettingsField.Global_Communication_ActionType]
  );

  useEffectOnce(() => {
    // Load Action Types
    if (!getFieldValue("ActionTypes")) {
      setIsLoading(true);
      getActionTypes(RECORDTYPE.CORE_CommunicationTemplate).then(
        (response: IResponseData) => {
          if (isSuccessResponse(response)) {
            // @TODO: Show Toast
          }
          onChange(nameOf("ActionTypesData"), { value: response.data });
          onChange(nameOf("ActionTypes"), {
            value:
              response.data?.filter(
                (item: IKeyValuePacket) => item.Key === actionTypeDefaultKey
              )[0] || {},
          });
          setIsLoading(false);
        }
      );
    }

    return () => {
      setIsLoading(false);
    };
  });
  return (
    <section>
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Action type</label>
            <Field
              name={nameOf("ActionTypes")}
              component={CCSearchComboBox}
              loading={isLoading}
              className="cc-form-control"
              data={getFieldValue("ActionTypesData") ?? []}
              textField="Value"
              dataItemKey="Key"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
