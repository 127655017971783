import { Invoice } from "@app/core/invoice/[id]/model";
import { CCValueField } from "@components/cc-value-field/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export const InvoicePaymentReference = ({
  valueGetter,
  nameOf,
}: InvoicePaymentReferenceProps) => {
  return (
    <div className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCValueField
            label="BPay reference"
            name={nameOf("BPayReference")}
            value={valueGetter(nameOf("BPayReference"))}
          />
        </div>
        <div className="cc-field">
          <CCValueField
            label="BillPay reference"
            name={nameOf("BillPayReference")}
            value={valueGetter(nameOf("BillPayReference"))}
          />
        </div>
        <div className="cc-field">
          <CCValueField
            label="Bpay iCRN"
            name={nameOf("BPAY_iCRN")}
            value={valueGetter(nameOf("BPAY_iCRN"))}
          />
        </div>
        <div className="cc-field">
          <CCValueField
            label="Keyline reference"
            name={nameOf("KeyLineReference")}
            value={valueGetter(nameOf("KeyLineReference"))}
          />
        </div>
        <div className="cc-field">
          <CCValueField
            label="OCR reference"
            name={nameOf("OCRReference")}
            value={valueGetter(nameOf("OCRReference"))}
          />
        </div>
      </div>
    </div>
  );
};

type InvoicePaymentReferenceProps = {
  valueGetter: FormRenderProps["valueGetter"];
  nameOf: (name: keyof Invoice) => keyof Invoice;
};
