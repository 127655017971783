import { complaintsRoute } from "@app/products/local-laws/complaints/route";
import { complianceNoticesRoute } from "@app/products/local-laws/compliance-notices/route";
import { crmsApplicationsRoute } from "@app/products/local-laws/crms-applications/route";
import { feeSetupRoute } from "@app/products/local-laws/fee-setup/route";
import { financeRoute } from "@app/products/local-laws/finance/route";
import { inspectionsRoute } from "@app/products/local-laws/inspections/route";
import { llsPermitsRoute } from "@app/products/local-laws/lls-permits/route";
import { permitsRoute } from "@app/products/local-laws/permits/route";
import { renewalsRoute } from "@app/products/local-laws/renewals/route";
import { systemAdminRoute } from "@app/products/local-laws/system-admin/route";
import { llsTendersRoute } from "@app/products/local-laws/tenders/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";

export const localLawsRoute: ICCRoute = {
  path: "local-laws",
  name: "Local Laws",
  enum: eProductType.LLPermits,
  children: [
    permitsRoute,
    renewalsRoute,
    inspectionsRoute,
    complaintsRoute,
    complianceNoticesRoute,
    crmsApplicationsRoute,
    financeRoute,
    systemAdminRoute,
    llsPermitsRoute,
    llsTendersRoute,
    feeSetupRoute,
    {
      path: "permits/:id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
