import { loadAssessmentMeter } from "@app/products/property/assessments/[id]/components/child-screens/meters/api";
import { IAssessmentMeters } from "@app/products/property/assessments/[id]/components/child-screens/meters/model";
import { IMeterFormGeneral } from "@app/products/property/meters/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
export const nameOf = nameOfFactory<IAssessmentMeters>();

class AssessmentMeterStore {
  private _meter?: IAssessmentMeters[] = undefined;
  private _isLoading: boolean = false;
  private _meterSelected: IMeterFormGeneral[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  get meter() {
    return this._meter;
  }

  get meterSelected() {
    return this._meterSelected;
  }

  get isLoading() {
    return this._isLoading;
  }

  setMeter = (meter: IAssessmentMeters[]) => {
    runInAction(() => {
      this._meter = meter;
    });
  };

  setMeterSelected = (meterSelected: IMeterFormGeneral[]) => {
    runInAction(() => {
      this._meterSelected = meterSelected;
    });
  };

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  // @TODO Remove when integrated API
  loadMeter = async () => {
    this.setIsLoading(true);
    const newMeter = await loadAssessmentMeter();
    this.setMeter(newMeter);
    this.setIsLoading(false);
  };
}
const assessmentMeterStoreContext = createContext(new AssessmentMeterStore());
export const useAssessmentMeterStore = () => {
  return useContext(assessmentMeterStoreContext);
};
