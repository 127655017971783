import { getPicCertificate } from "@app/products/property/pic/[id]/components/child-screens/certificates/api";
import { IPicCertificate } from "@app/products/property/pic/[id]/components/child-screens/certificates/model";
import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

class PicCertificateStore {
  private _certificate?: IPicCertificate[] = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get certificate() {
    return this._certificate;
  }
  setCertificate = (certificate?: IPicCertificate[]) => {
    runInAction(() => {
      this._certificate = certificate;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._certificate = undefined;
      this._isLoading = false;
    });
  };

  loadPicCertificate = async (id: number) => {
    this.setIsLoading(true);
    const certificate = await getPicCertificate(id);
    this.setCertificate(certificate);
    this.setIsLoading(false);
  };
}

const picCertificateStoreContext = createContext(new PicCertificateStore());
export const usePicCertificateStore = () => {
  return useContext(picCertificateStoreContext);
};
