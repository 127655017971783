import { AmsRoute } from "@app/products/ams/constants/ams.route";
import { IWorkOrderAsset } from "@app/products/ams/model/workOrder";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

export interface IProps {
  assets: IWorkOrderAsset[];
  title?: string;
}

export const AssetListFragment: React.FC<IProps> = ({ assets }) => {
  let location = useLocation();
  const manageasseturl: string = AmsRoute.ASSET_MANAGE_PATH;
  const assetsRowRender = (trElement: any, dataItem: any) => {
    const trProps = {
      ...trElement.props,
      onContextMenu: (e: any) => {
        e.preventDefault();
        //handleContextMenuOpen(e, dataItem.dataItem);
      },
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  return (
    <Fragment>
      <div>
        {assets.length > 0 ? (
          <div>
            <Grid resizable={true} data={assets} rowRender={assetsRowRender}>
              <GridColumn
                field="AssetNumber"
                title="Asset Number"
                width={100}
                cell={(props: any) => {
                  return (
                    <td>
                      <Link
                        key={props.dataItem.WorkOrderId}
                        to={{
                          pathname: `${manageasseturl}${props.dataItem.AssetId}`,
                          state: { background: location },
                        }}
                      >
                        <div
                          className="label btn-link text-overflow-ellipsis"
                          color="var(--accent) !important"
                        >
                          {props.dataItem[props.field]}
                        </div>
                      </Link>
                    </td>
                  );
                }}
              />
              <GridColumn field="AssetDescription" title="Description" />
            </Grid>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

AssetListFragment.defaultProps = {
  title: "Assets",
};

export default AssetListFragment;
