import { PPRApplicationIdentityPacket } from "@app/products/town-planning/ppr/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const putPASRChangeLodgeDate = async (
  id: number,
  lodgedDate: Date
): Promise<APIResponse<PPRApplicationIdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
      `api/tp/ppr/internal/application/psar/${id}/changelodgeddate`,
      JSON.stringify(lodgedDate)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
