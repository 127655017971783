import { InvoiceForm } from "@app/core/invoice/[id]/components/child-screens/general/_index";
import { useInvoiceStore } from "@app/core/invoice/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewManageInvoice = observer(() => {
  const isNew = useIsNew();
  const {
    isLoading,
    isLoadingParent,
    onSubmit,
    invoice,
    responseLoadError,
    loadInvoiceById,
  } = useInvoiceStore();

  return (
    <>
      <Loading isLoading={isLoading || isLoadingParent} isFullScreen />
      <FormNavigation title="Invoice" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadInvoiceById(0, isNew);
          }}
        />
      ) : (
        <>
          <FormTitle title={"New Invoice"} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
              />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {invoice && <InvoiceForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
