import { HOME_PAGE_VIEW_URL } from "@common/pages/settings/homepage/homepage-views/constant";
import { Svc_HomepageViews } from "@common/pages/settings/homepage/homepage-views/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_HomepageViews>();

export const colHomepageViews: IColumnFields[] = [
  {
    title: "Product Type",
    field: nameOf("ProductType_Name"),
    locked: true,
    linkTo: (dataItem: Svc_HomepageViews) => {
      return `${HOME_PAGE_VIEW_URL}/${dataItem.ID}`;
    },
  },
  { title: "View Heading", field: nameOf("ViewHeading"), width: 500 },
];
