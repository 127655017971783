import { NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colAttachment: IColumnFields[] = [
  {
    field: "DocumentType",
    title: "Document Type",
  },
  {
    field: "Description",
    title: "Description",
  },
  {
    field: "FileCreatedOn",
    title: "File Created On",
  },
  {
    field: "FileAddedBy",
    title: "File Added By",
  },
  {
    field: "FileAddedOn",
    title: "File Added On",
  },
  {
    field: "OriginalFile",
    title: "Original File",
  },
  {
    field: "DocumentUri",
    title: "Document URI",
  },
  {
    field: "DocumentId",
    title: "Document ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
