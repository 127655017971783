import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useSecurityTemplateStore } from "@common/pages/settings/security/security-templates/_id/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const SecurityTemplateTabHistory = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { securityTemplate } = useSecurityTemplateStore();
  const securityTemplateId: number =
    securityTemplate?.SecurityTemplate_ID ??
    lastSelectedRow?.SecurityTemplate_ID;
  return (
    <Journal
      id={securityTemplateId}
      triggersReload={securityTemplate}
      recordType={RECORDTYPE.CORE_SecurityTemplate}
    />
  );
});
