import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { allDocumentsRoute } from "./all-documents/route";
import { documentsYetToBeIssuedRoute } from "./documents-yet-to-be-issued/route";

export const actionsAndLettersRoute: ICCRoute = {
  path: "actions-and-letters",
  name: "Actions and Letters",
  enum: eMenuAction.Compliance_ActionsAndLetters,
  children: [allDocumentsRoute, documentsYetToBeIssuedRoute],
};
