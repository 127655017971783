import { apiCoreSettingOptions } from "@app/core/components/common/utils";
import { SettingFolder } from "@app/products/property/system-admin/settings/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const loadSettings = async (): Promise<
  APIResponse<SettingFolder | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<SettingFolder>(
      apiCoreSettingOptions()
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
