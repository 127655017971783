import {
  ASSOCIATION_LOCATION_FORM_STEP,
  AssociationFormStep,
} from "@app/products/property/meters/[id]/components/form-steps/installation/components/form-elements/association/_index";
import { SearchType } from "@app/products/property/meters/[id]/components/form-steps/installation/components/form-elements/association/model";
import {
  METER_LOCATION_FORM_STEP,
  MeterLocationFormStep,
} from "@app/products/property/meters/[id]/components/form-steps/installation/components/form-elements/meter-location/_index";
import { isShowParkButton } from "@app/products/property/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";

interface IInstallationDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
}
export const InstallationDialog = ({
  onClose,
  onSubmit,
  isIncompleteMode = false,
  isFromActionList = false,
}: IInstallationDialogProps) => {
  const steps: IStep[] = [
    {
      label: "Meter location",
      initialValues: { MeterLocation: [] },
      component: MeterLocationFormStep,
      visible: true,
      key: METER_LOCATION_FORM_STEP,
      options: {
        showBringUpField: true,
      },
    },
    {
      label: "Association",
      initialValues: {
        Association: [],
        MeterId: 5879,
        SearchType: SearchType.AssessmentNumber,
      },
      component: AssociationFormStep,
      visible: true,
      key: ASSOCIATION_LOCATION_FORM_STEP,
      options: {
        showStatusField: false,
      },
    },
  ];
  return (
    <CCFormStep
      onSubmit={onSubmit}
      initialSteps={steps}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="50%"
          maxHeight="78%"
          titleHeader={"Installation"}
          onClose={onClose}
          bodyElement={renderProps.children}
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              {isShowParkButton(isFromActionList, isIncompleteMode) && (
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Park
                </Button>
              )}
              <Button className={"cc-dialog-button"} onClick={onClose}>
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                disabled={renderProps.nextButton.disabled}
                className={"cc-dialog-button"}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
