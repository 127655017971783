import { LLPermitsMenu } from "@app/products/local-laws/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const renewalsRoute: ICCRoute = {
  path: "renewals",
  name: "Renewals",
  enum: LLPermitsMenu.Renewals,
  children: [
    {
      path: "all-by-due-status",
      name: "All By Due Status",
      enum: LLPermitsMenu.PermitsByRenewal,
      component: require("./all-by-due-status/_index").default,
    },
    {
      path: "requires-renewal-fee",
      name: "Requires Renewal Fee",
      enum: LLPermitsMenu.RenewalsReqFee,
      component: require("./requires-renewal-fee/_index").default,
    },
    {
      path: "requires-renewal-notice",
      name: "Requires Renewal Notice",
      enum: LLPermitsMenu.RenewalsReqNotice,
      component: require("./requires-renewal-notice/_index").default,
    },
    {
      path: "requires-renewal",
      name: "Requires Renewal",
      enum: LLPermitsMenu.RenewalsReqRenewal,
      component: require("./requires-renewal/_index").default,
    },
  ],
};
