import { ContactsTab } from "@app/core/contacts/components/contact-tab/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useAppealStore } from "@common/pages/appeal/_id/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const AppealContactsTab = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { appeal } = useAppealStore();
  const { lastSelectedRow } = useCCProductListViewStore();
  const appealId = id ? parseInt(id) : lastSelectedRow?.ID;

  return (
    <ContactsTab
      id={appealId}
      recordType={RECORDTYPE.CORE_Appeal}
      triggerReload={appeal}
    />
  );
});
