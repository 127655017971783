//ONLY FOR TAGS ENUMS

export enum RegisterRecord_Tags {
  REGISTRATIONNUMBER = 0,
  APPLICANTNAME = 1,
  REGISTEREDADDRESS = 2,
}

export enum AnimalRecord_Tags {
  REGISTRATIONNUMBER = 0,
  TAGNUMBER = 1,
  BREED = 2,
  ANIMALNAME = 3,
  OWNERNAME = 4,
  REGISTEREDADDRESS = 5,
}

export enum WWRecord_Tags {
  REGISTRATIONNUMBER = 0,
  APPLICANTNAME = 1,
  SYSTEMTYPE = 2,
  REGISTEREDADDRESS = 3,
}

export enum HMPremisesRecord_Tags {
  REGISTRATIONNUMBER = 0,
  TRADININGNAME = 1,
  PREMISESTYPE = 2,
  PROPRIETOR = 3,
  PROPRIETORADDRESS = 4,
  REGISTEREDADDRESS = 5,
}

export enum TPRecord_Tags {
  REGISTRATIONNUMBER = 0,
  TRADININGNAME = 1,
  PREMISESTYPE = 2,
  PROPRIETOR = 3,
  PROPRIETORADDRESS = 4,
  REGISTEREDADDRESS = 5,
}

export enum BuildingRecord_Tags {
  REGISTRATIONNUMBER = 0,
  TRADININGNAME = 1,
  PREMISESTYPE = 2,
  PROPRIETOR = 3,
  PROPRIETORADDRESS = 4,
  REGISTEREDADDRESS = 5,
}

export enum CemetriesRecord_Tags {
  REGISTRATIONNUMBER = 0,
  TRADININGNAME = 1,
  PREMISESTYPE = 2,
  PROPRIETOR = 3,
  PROPRIETORADDRESS = 4,
}

export enum LLRecord_Tags {
  REGISTRATIONNUMBER = 0,
  TRADININGNAME = 1,
  PREMISESTYPE = 2,
  PROPRIETOR = 3,
  PROPRIETORADDRESS = 4,
}

export enum EventRecord_Tags {
  REGISTRATIONNUMBER = 0,
  TRADININGNAME = 1,
  PREMISESTYPE = 2,
  PROPRIETOR = 3,
  PROPRIETORADDRESS = 4,
  MASTERFACILITY = 5,
}

export enum InfringeRecord_Tags {
  REGISTRATIONNUMBER = 0,
  INFRINGEMENTNUMBER = 1,
  INFRINGEDATE = 2,
  OFFENDER = 3,
  OFFENDERADDRESS = 4,
  INFRNGEMENTTYPE = 5,
  INFRNGEMENTCATEGORY = 6,
  INFRINGEMNETSECTION = 7,
}
