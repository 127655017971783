import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { MailMergeButtonManagePage } from "@app/core/new-mail-merge/buttons/mail-merge-manage-page/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyActions } from "@app/products/property/components/child-screen/actions";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { JournalDeferredDutyAccountTransactions } from "@app/products/property/journals/[id]/components/child-screens/deferred-duty-account-transactions/_index";
import { PropertyJournalForm } from "@app/products/property/journals/[id]/components/child-screens/general/_index";
import { JournalItems } from "@app/products/property/journals/[id]/components/child-screens/items/_index";
import { useJournalTransactionStore } from "@app/products/property/journals/[id]/components/child-screens/items/store";
import { getItemsInfoBaseOnComponentNumber } from "@app/products/property/journals/[id]/components/child-screens/items/util";
import { JournalReadings } from "@app/products/property/journals/[id]/components/child-screens/readings/_index";
import { JournalUsages } from "@app/products/property/journals/[id]/components/child-screens/usages/_index";
import { AddTransactionButton } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/add-transaction/_index";
import { AuthoriseJournalButton } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/authorise-journal/_index";
import { CancelJournalButton } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/cancel-journal/_index";
import { CancelTransactionButton } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/cancel-transaction/_index";
import { CompleteIncompleteJournalButton } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/complete-journal/_index";
import { DeauthoriseJournalButton } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/deauthorise-journal/_index";
import { ModifyTransactionButton } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/modify-transaction/_index";
import { RemoveTransactionButton } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/remove-transaction/_index";
import { ReverseJournalButton } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/reverse-journal/_index";
import { JournalDetailTab } from "@app/products/property/journals/[id]/components/reference-sidebar/detail/_index";
import { JOURNAL_TYPE } from "@app/products/property/journals/[id]/model";
import { useJournalStore } from "@app/products/property/journals/[id]/store";
import { eJournalType } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/model";
import {
  JournalStatus,
  JournalStatusName,
} from "@app/products/property/journals/model";
import { eAccordion } from "@app/products/property/model";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { eAccordionType } from "@components/custom-panelbar/model";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const ExistedPropertyJournal = observer(() => {
  const { journal, isLoading, responseLoadError, loadJournal } =
    useJournalStore();
  const { selectedTransaction } = useJournalTransactionStore();

  const [showSlideBar, setShowSlideBar] = useState(true);

  const location = useLocation<any>();
  const isExpandedUsage = !!location.state?.isExpandedUsage;
  const { currentUserInfo } = useGlobalStore();
  const scrollToViewRef = useRef<any>(null);
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const getBookmarkDetail = useMemo(() => {
    const dynamicDetail = "Journal " + (journal?.Journal_Number ?? "");
    return `${dynamicDetail}`;
  }, [journal]);

  const [showIncompleteJournalDialog, setShowIncompleteJournalDialog] =
    useState(false);
  const nameForTransactionId = getItemsInfoBaseOnComponentNumber(
    journal?.Component_Number
  )?.nameForTransactionId;

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: getBookmarkDetail,
      LinkUrl: managePageUrl,
      LinkText: `Property - Journals - ${id ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Journal,
      Record_ID: parseInt(id) ?? 0,
    },
  ];

  const listPanelBar: IPropertyPanelBar[] = [
    {
      accordionId: eAccordion.Journal_Actions_And_Workflows,
      title: "Actions and Workflows",
      component: (
        <PropertyActions
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Journal}
          componentNumber={eComponent.Journal}
        />
      ),
      accordionType: eAccordionType.Actions_And_Workflows,
    },
    {
      accordionId: eAccordion.Journal_Related,
      title: "Related",
      component: (
        <PropertyAssociations
          id={parseInt(id)}
          componentNumber={eComponent.Journal}
        />
      ),
    },
    // Requirement: https://dev.azure.com/openofficeau/Radium/_workitems/edit/12802/
    // {
    //   title: "Audit",
    //   component: (
    //     <PropertyAudit id={parseInt(id)} componentNumber={eComponent.Journal} />
    //   ),
    // },
    {
      accordionId: eAccordion.Journal_Comments,
      title: "Comments",
      component: (
        <PropertyComments
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Journal}
        />
      ),
    },
    {
      accordionId: eAccordion.Journal_Items,
      title: "Items",
      component: <JournalItems componentNumber={journal?.Component_Number} />,
    },
    {
      accordionId: eAccordion.Journal_Readings,
      title: "Readings",
      isVisible: journal?.Journal_Type_Name === JOURNAL_TYPE.METER_USAGES,
      component: <JournalReadings />,
    },
    {
      accordionId: eAccordion.Journal_Usages,
      title: "Usages",
      isVisible: journal?.Journal_Type_Name === JOURNAL_TYPE.METER_USAGES,
      component: (
        <JournalUsages
          usageRef={isExpandedUsage ? scrollToViewRef : undefined}
        />
      ),
      isExpanded: isExpandedUsage,
    },
    {
      accordionId: eAccordion.Journal_Documents,
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={parseInt(id)}
          componentNumber={eComponent.Journal}
        />
      ),
    },
    {
      accordionId: eAccordion.Journal_Deferred_Duty_Account_Transactions,
      title: "Deferred Duty Account Transactions",
      isVisible:
        journal?.Journal_Type_Name === JOURNAL_TYPE.DeferredDutyAccountJournal,
      component: <JournalDeferredDutyAccountTransactions />,
    },
  ];
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Journal" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadJournal(+id)}
        />
      ) : (
        <>
          <FormTitle
            title={
              (journal?.Journal_Number ?? "") +
              (!isNil(journal?.Journal_Type_Name)
                ? ` - ${journal?.Journal_Type_Name}`
                : "")
            }
            badge={journal?.Journal_Status_Name ?? ""}
          />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Journal"} type="dropdown">
                  {/* Complete button */}
                  <CompleteIncompleteJournalButton
                    //Logic: enabled if journal status is "Incomplete"
                    disabled={
                      journal?.Journal_Status_Name !==
                      JournalStatusName.Incomplete
                    }
                    isCompleteButton
                  />
                  {/* Incomplete button */}
                  <CompleteIncompleteJournalButton
                    //Logic: enabled if journal status is "To be Authorised"
                    disabled={
                      journal?.Journal_Status_Name !==
                      JournalStatusName.ToBeAuthorised
                    }
                  />
                  <AuthoriseJournalButton
                    //Logic: enabled if journal status is "Incomplete" or "To be Authorised"
                    disabled={
                      journal?.Journal_Status_Name !==
                        JournalStatusName.Incomplete &&
                      journal?.Journal_Status_Name !==
                        JournalStatusName.ToBeAuthorised
                    }
                  />
                  <DeauthoriseJournalButton
                    //Logic: enabled if journal status is "Authorised" and no Session has been created
                    disabled={
                      journal?.Journal_Status_Name !==
                        JournalStatusName.Authorised ||
                      !isNil(journal?.Session_Number)
                    }
                  />
                  <ReverseJournalButton
                    //Logic: enabled if journal status is "Authorised"
                    disabled={
                      journal?.Journal_Status_Name !==
                      JournalStatusName.Authorised
                    }
                  />
                  <CancelJournalButton
                    //Logic: enabled if journal status is "To be Authorised"
                    disabled={
                      journal?.Journal_Status_Name !==
                      JournalStatusName.ToBeAuthorised
                    }
                  />
                </CCNavButton>
                <CCNavButton title={"Transaction"} type="dropdown">
                  <AddTransactionButton
                    //Logic: enabled if journal status is "Incomplete"
                    disabled={
                      journal?.Journal_Status !== JournalStatus.Incomplete
                    }
                  />
                  {journal?.Journal_Type === eJournalType["PIC Journal"] ? (
                    <></>
                  ) : (
                    <ModifyTransactionButton
                      //Logic: enabled if transaction selected and journal status is "Incomplete"
                      disabled={
                        journal?.Journal_Status !== JournalStatus.Incomplete ||
                        !selectedTransaction?.[`${nameForTransactionId}`]
                      }
                      nameForTransactionId={nameForTransactionId}
                    />
                  )}
                  <RemoveTransactionButton
                    //Logic: enabled if transaction selected, journal status is "Incomplete"
                    //and there is no transaction with a higher transaction ID
                    //TODO: will check if transaction has the highest ID by API - waiting for API
                    disabled={
                      journal?.Journal_Status !== JournalStatus.Incomplete ||
                      !selectedTransaction?.[`${nameForTransactionId}`]
                    }
                    componentNumber={journal?.Component_Number}
                    nameForTransactionId={nameForTransactionId}
                  />
                  <CancelTransactionButton
                    //Logic: enabled if transaction selected, journal status is "Incomplete"
                    disabled={
                      journal?.Journal_Status !== JournalStatus.Incomplete ||
                      !selectedTransaction?.[`${nameForTransactionId}`] ||
                      selectedTransaction?.IsCancelled
                    }
                    componentNumber={journal?.Component_Number}
                    nameForTransactionId={nameForTransactionId}
                  />
                </CCNavButton>
              </CCNavButton>,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Journal}
                />
                <AddAttachmentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Journal}
                />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Journal}
                />
                <MailMergeButtonManagePage
                  productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
                  dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Journal}
                  recordType={RECORDTYPE.CommunityProperty_Journal}
                  isEmptyGridSelectedRow={!parseInt(id)}
                  selectedRecordIds={[parseInt(id)]}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {journal && (
                  <CCGeneralPanel component={<PropertyJournalForm />} />
                )}
                <PropertyPanelBar
                  initialPanels={listPanelBar}
                  componentId={parseInt(id)}
                  componentNumber={eComponent.Journal}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <JournalDetailTab /> },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={RECORDTYPE.CommunityProperty_Journal}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
          {/* TODO: Will move this dialog to button component when integrating API */}
          {showIncompleteJournalDialog && (
            <ConfirmDialog
              title="Confirmation"
              subMessage="Are you sure you want to change the status to incomplete for this journal?"
              onClosePopup={() => setShowIncompleteJournalDialog(false)}
              onAsyncConfirm={() => setShowIncompleteJournalDialog(false)}
              isLoadingYes={isLoading}
            />
          )}
        </>
      )}
    </>
  );
});
