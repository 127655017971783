import { mockMetersReading } from "@app/products/property/meters/[id]/components/child-screens/reading/mock";
import { IMetersReading } from "@app/products/property/meters/[id]/components/child-screens/reading/model";
import { sleep } from "@common/utils/common";

export const loadMetersReading = async () => {
  await sleep(1000);
  return mockMetersReading;
};

const checkExistedReading = (
  MeterReadingId: number | null | undefined,
  ListMeterReadings: IMetersReading[] | null | undefined
) => {
  return ListMeterReadings?.some(
    (item) => item?.MeterReadingId === MeterReadingId
  );
};

export const voidReadings = async (
  readings: IMetersReading[],
  selectedReadings: IMetersReading[]
) => {
  await sleep(1000);
  return readings.map((item: IMetersReading) => {
    if (checkExistedReading(item.MeterReadingId, selectedReadings)) {
      return { ...item, IsVoid: true };
    }
    return item;
  });
};

export const reverseVoidReadings = async (
  readings: IMetersReading[],
  selectedReadings: IMetersReading[]
) => {
  await sleep(1000);
  return readings.map((item: IMetersReading) => {
    if (checkExistedReading(item.MeterReadingId, selectedReadings)) {
      return { ...item, IsVoid: false };
    }
    return item;
  });
};

export const verifyReadings = async (
  readings: IMetersReading[],
  selectedReadings: IMetersReading[]
) => {
  await sleep(1000);
  return readings.map((item: IMetersReading) => {
    if (checkExistedReading(item.MeterReadingId, selectedReadings)) {
      return { ...item, ToBeVerified: false };
    }
    return item;
  });
};

export const unVerifyReadings = async (
  readings: IMetersReading[],
  selectedReadings: IMetersReading[]
) => {
  await sleep(1000);
  return readings.map((item: IMetersReading) => {
    if (checkExistedReading(item.MeterReadingId, selectedReadings)) {
      return { ...item, ToBeVerified: true };
    }
    return item;
  });
};
