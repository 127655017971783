import { loadViewConfiguresColumns } from "@app/products/property/api";
import { SelectRebatesToBeEntitledDetail } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/rebate_entitlements/components/detail/_index";
import {
  colLotRebate,
  colParcelRebate,
} from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/rebate_entitlements/components/detail/config";
import { entitlementsFormValidator } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/rebate_entitlements/components/util";
import { colRebateToBeEntitled } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/rebate_entitlements/config";
import {
  DTO_Owners,
  DTO_RebateType_RatingPeriod,
} from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import { ViewConfiguration } from "@app/products/property/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";

const nameOfRebateType = nameOfFactory<DTO_RebateType_RatingPeriod>();
export const RebateEntitlementsFormStep = (props: IFormStepElement) => {
  const { options } = props;
  const formValidator = useCallback(
    (values) => {
      return entitlementsFormValidator(values, options);
    },
    [options]
  );

  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!options.isReadOnly ? formValidator : undefined}
    />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  isLoadingStep,
  setIsLoadingStep = () => {},
  loadFailedStep,
  setLoadFailedStep = () => {},
  options = {
    isReadOnly: false,
    isLLS: false,
    isActro: false,
    isWA: false,
    lotLabelName: "lot",
  },
}: IFormStepElement) => {
  const { valueGetter, onChange, errors } = formRenderProps;
  const formErrors = errors?.[nameOf("")]
    ? JSON.parse(errors?.[nameOf("")])
    : undefined;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const rebateTypesData = getFieldValue("RebateType_RatingPeriod");

  const [processedRebateTypesColumns, setProcessedRebateTypesColumns] =
    useState<IColumnFields[]>(colRebateToBeEntitled);
  const [processedParcelColumns, setProcessedParcelColumns] =
    useState<IColumnFields[]>(colParcelRebate);
  const [processedLotColumns, setProcessedLotColumns] =
    useState<IColumnFields[]>(colLotRebate);

  /**
   * Load view configuration
   */
  const loadViewConfig = async () => {
    setIsLoadingStep(true);
    const responseRebateTypes = await loadViewConfiguresColumns(
      ViewConfiguration.Assessment_RebateTypeRatingPeriod,
      colRebateToBeEntitled
    );
    if (Array.isArray(responseRebateTypes)) {
      setProcessedRebateTypesColumns(responseRebateTypes);
      if (options?.isActro) {
        const responseParcels = await loadViewConfiguresColumns(
          ViewConfiguration.ParcelSearch,
          colParcelRebate
        );
        setIsLoadingStep(false);
        if (Array.isArray(responseParcels)) {
          setProcessedParcelColumns(responseParcels);
        } else {
          setLoadFailedStep({
            onReload: () => {
              loadViewConfig();
            },
            responseError: {
              status: responseParcels.status,
              error: responseParcels.error ?? "Load failed",
            },
          });
        }
      } else if (options?.isLLS) {
        const responseLots = await loadViewConfiguresColumns(
          ViewConfiguration.TitleSearch,
          colLotRebate
        );
        setIsLoadingStep(false);
        if (Array.isArray(responseLots)) {
          setProcessedLotColumns(responseLots);
        } else {
          setLoadFailedStep({
            onReload: () => {
              loadViewConfig();
            },
            responseError: {
              status: responseLots.status,
              error: responseLots.error ?? "Load failed",
            },
          });
        }
      } else {
        setIsLoadingStep(false);
      }
    } else {
      setLoadFailedStep({
        onReload: () => {
          loadViewConfig();
        },
        responseError: {
          status: responseRebateTypes.status,
          error: responseRebateTypes.error ?? "Load failed",
        },
      });
    }
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoadingStep) {
    return <Loading isLoading={isLoadingStep} />;
  }

  if (loadFailedStep) {
    return (
      <CCLoadFailed
        onReload={loadFailedStep?.onReload}
        responseError={loadFailedStep?.responseError}
      />
    );
  }

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <CCLabel title="Rebate application received" isMandatory />
          <Field
            name={nameOf("ApplicationDate")}
            component={CCDatePicker}
            disabled={options?.isReadOnly}
            format={DATE_FORMAT.DATE_CONTROL}
            validator={!options.isReadOnly ? requiredValidator : undefined}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Date rebates to be entitled from" isMandatory />
          <Field
            name={nameOf("EffectiveDate")}
            component={CCDatePicker}
            disabled={options?.isReadOnly}
            format={DATE_FORMAT.DATE_CONTROL}
            validator={!options.isReadOnly ? requiredValidator : undefined}
          />
        </div>
      </div>

      {!options?.isLLS && (
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Entitled to alternative rebate CAP" />
            <Field
              name={nameOf("EntitledToAlternateRebateCap")}
              component={CCSwitch}
              checked={getFieldValue("EntitledToAlternateRebateCap")}
              disabled={options.isReadOnly}
            />
          </div>
        </div>
      )}

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel
            title="Select rebates to be entitled"
            isMandatory
            customTooltip={
              <CCTooltip
                type="custom"
                position="auto"
                content=" "
                customTemplate={
                  <div>
                    &#8226; Select at least one rebate to be entitled
                    <br />
                    &#8226; If rebate is entitled, percentage is required and
                    must be between 1 and 100
                  </div>
                }
              >
                <i className="fa fa-info-circle ml-1 text-accent" />
              </CCTooltip>
            }
            errorMessage={formErrors?.RebateTypes}
          />
          <CCGrid
            data={rebateTypesData}
            primaryField={nameOfRebateType("Rebate_Type_Id")}
            columnFields={processedRebateTypesColumns}
            readOnly={options?.isReadOnly}
            detail={(props: GridDetailRowProps) => (
              <SelectRebatesToBeEntitledDetail
                errors={formErrors}
                data={rebateTypesData.find(
                  (item: DTO_RebateType_RatingPeriod) =>
                    item.Rebate_Type_Id === props.dataItem.Rebate_Type_Id
                )}
                handleDataChange={(dataItem, fieldName: string) => {
                  const index = rebateTypesData.findIndex(
                    (item: DTO_RebateType_RatingPeriod) =>
                      item.Rebate_Type_Id === props.dataItem.Rebate_Type_Id
                  );

                  if (index !== -1) {
                    // Create a new array with the updated element
                    const newRebateTypes = [...rebateTypesData];
                    newRebateTypes[index] = {
                      ...newRebateTypes[index],
                      [fieldName]: dataItem,
                    };

                    //Set rebate percentage based on selected owners
                    if (fieldName === "Owners") {
                      const percentage = dataItem.reduce(
                        (
                          totalOwnershipPercentage: number,
                          currentOwner: DTO_Owners
                        ) => {
                          const { Is_Entitle, Ownership_Percentage } =
                            currentOwner;
                          if (Is_Entitle) {
                            totalOwnershipPercentage +=
                              Ownership_Percentage ?? 0;
                          }
                          return totalOwnershipPercentage;
                        },
                        0
                      );
                      newRebateTypes[index].Percentage = percentage;
                    }

                    onChange(nameOf("RebateType_RatingPeriod"), {
                      value: newRebateTypes,
                    });
                  }

                  // If the ID is not found, return the original array
                  return;
                }}
                gridDetailRowProps={props}
                options={{
                  isReadOnly: options?.isReadOnly,
                  isLLS: options?.isLLS,
                  isActro: options?.isActro,
                  isWA: options?.isWA,
                  lotLabelName: options?.lotLabelName,
                  parcelColumns: processedParcelColumns,
                  lotColumns: processedLotColumns,
                }}
              />
            )}
            editableMode="cell"
            hideExpanderButton={(dataItem: DTO_RebateType_RatingPeriod) => {
              return !dataItem?.Is_Entitle;
            }}
            onDataChange={(dataItem: DTO_RebateType_RatingPeriod[]) => {
              onChange(nameOf("RebateType_RatingPeriod"), { value: dataItem });
            }}
          />
        </div>
      </div>
    </section>
  );
};
