import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { InputSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/input/_index";
import { SwitchSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/switch/_index";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

export const AssessmentGeneral1Form = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const { settings } = useCommonCoreStore();

    const addressDefaultState = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_AddressDefaultState]
    );

    const data = useDataSetting(configData, [
      "TP_Assessment_Form_AssessmentTab_Label",
      "TP_DynamicAssessment_Form",
      "TP_DynamicAssessment_Form_Tab1",
      "TP_DynamicAssessment_Form_Tab1_LabelAndOrder",
      "TP_DynamicAssessment_Form_Tab2",
      "TP_DynamicAssessment_Form_Tab2_LabelAndOrder",
      "TP_DynamicAssessment_Form_Tab3",
      "TP_DynamicAssessment_Form_Tab3_LabelAndOrder",
      "TP_DynamicAssessment_Form_Tab4",
      "TP_DynamicAssessment_Form_Tab4_LabelAndOrder",
      "TP_DynamicAssessment_Form_Tab5",
      "TP_DynamicAssessment_Form_Tab5_LabelAndOrder",
      "TP_DynamicAssessment_Form_Tab6",
      "TP_DynamicAssessment_Form_Tab6_LabelAndOrder",
      "TP_DynamicAssessment_Form_Tab7",
      "TP_DynamicAssessment_Form_Tab7_LabelAndOrder",
      "TP_DynamicAssessment_Form_Tab8",
      "TP_DynamicAssessment_Form_Tab8_LabelAndOrder",
      "TP_DynamicAssessment_Form_Tab9",
      "TP_DynamicAssessment_Form_Tab9_LabelAndOrder",
    ]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <SwitchSettings
            data={data.TP_DynamicAssessment_Form}
            isEditing={addressDefaultState === "NSW" ? false : isEditing}
            formRenderProps={formRenderProps}
          />
          <InputSettings
            data={data.TP_Assessment_Form_AssessmentTab_Label}
            isEditing={isEditing}
            colSpan={2}
          />
        </div>

        {data.TP_DynamicAssessment_Form &&
          formRenderProps?.valueGetter(
            data.TP_DynamicAssessment_Form.FieldName
          ) && (
            <>
              <div className="cc-form-cols-3">
                <SwitchSettings
                  data={data.TP_DynamicAssessment_Form_Tab1}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />

                <InputSettings
                  data={data.TP_DynamicAssessment_Form_Tab1_LabelAndOrder}
                  isEditing={isEditing}
                  colSpan={2}
                />
              </div>
              <div className="cc-form-cols-3">
                <SwitchSettings
                  data={data.TP_DynamicAssessment_Form_Tab2}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />

                <InputSettings
                  data={data.TP_DynamicAssessment_Form_Tab2_LabelAndOrder}
                  isEditing={isEditing}
                  colSpan={2}
                />
              </div>

              <div className="cc-form-cols-3">
                <SwitchSettings
                  data={data.TP_DynamicAssessment_Form_Tab3}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />

                <InputSettings
                  data={data.TP_DynamicAssessment_Form_Tab3_LabelAndOrder}
                  isEditing={isEditing}
                  colSpan={2}
                />
              </div>
              <div className="cc-form-cols-3">
                <SwitchSettings
                  data={data.TP_DynamicAssessment_Form_Tab4}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />

                <InputSettings
                  data={data.TP_DynamicAssessment_Form_Tab4_LabelAndOrder}
                  isEditing={isEditing}
                  colSpan={2}
                />
              </div>
              <div className="cc-form-cols-3">
                <SwitchSettings
                  data={data.TP_DynamicAssessment_Form_Tab5}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />

                <InputSettings
                  data={data.TP_DynamicAssessment_Form_Tab5_LabelAndOrder}
                  isEditing={isEditing}
                  colSpan={2}
                />
              </div>
              <div className="cc-form-cols-3">
                <SwitchSettings
                  data={data.TP_DynamicAssessment_Form_Tab6}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />

                <InputSettings
                  data={data.TP_DynamicAssessment_Form_Tab6_LabelAndOrder}
                  isEditing={isEditing}
                  colSpan={2}
                />
              </div>
              <div className="cc-form-cols-3">
                <SwitchSettings
                  data={data.TP_DynamicAssessment_Form_Tab7}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />

                <InputSettings
                  data={data.TP_DynamicAssessment_Form_Tab7_LabelAndOrder}
                  isEditing={isEditing}
                  colSpan={2}
                />
              </div>
              <div className="cc-form-cols-3">
                <SwitchSettings
                  data={data.TP_DynamicAssessment_Form_Tab8}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />

                <InputSettings
                  data={data.TP_DynamicAssessment_Form_Tab8_LabelAndOrder}
                  isEditing={isEditing}
                  colSpan={2}
                />
              </div>
              <div className="cc-form-cols-3">
                <SwitchSettings
                  data={data.TP_DynamicAssessment_Form_Tab9}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />

                <InputSettings
                  data={data.TP_DynamicAssessment_Form_Tab9_LabelAndOrder}
                  isEditing={isEditing}
                  colSpan={2}
                />
              </div>
            </>
          )}
      </section>
    );
  }
);
