import { DTO_Certificate_RatesDepartment } from "@app/products/property/certificates/[id]/components/child-screens/rates-department/model";
import { DTO_Certificate_LOVs } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IGetCertificateRateDepartment =
  | [
      APIResponse<DTO_Certificate_LOVs>,
      APIResponse<DTO_Certificate_RatesDepartment>
    ]
  | APIResponse<DTO_Certificate_RatesDepartment | DTO_Certificate_LOVs>
  | undefined;
export const getCertificateRateDepartment = async (
  certificateId: number
): Promise<IGetCertificateRateDepartment> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Certificate_LOVs>(
        `api/property/int/certificate/lovs`
      ),
      CoreAPIService.getClient().get<DTO_Certificate_RatesDepartment>(
        `api/property/int/certificate/${certificateId}/ratesdepartment`
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
