import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { GlobalSettingNumeric } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/forms/components/numeric-input/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export default ({ formRenderProps }: ICommonFormProps) => {
  const { configData } = useSettingGlobalStore();

  if (!configData) return <></>;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <GlobalSettingNumeric
          enumParam={
            ECorporateSettingsField.CorporateSettings_MenuGroup_MinimumChildItems
          }
        />
        <GlobalSettingNumeric
          enumParam={ECorporateSettingsField.CorporateSettings_MenuGroup_Height}
        />
      </div>
    </section>
  );
};
