import { AssessmentDetailTab } from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/_index";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { metersRoute } from "@app/products/property/meters/route";
import { propertyRoute } from "@app/products/property/route";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { NoData } from "@components/no-data/NoData";
import React from "react";

export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={metersRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <AssessmentDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts" },
      {
        title: "History",
        component: (
          <PropertyHistoryTab recordType={RECORDTYPE.CommunityProperty_Meter} />
        ),
      },
    ],
  });

  return <NoData />;
};
