import { getDirectDebitAuthorisationAccounts } from "@app/products/direct-debit/authorisations/[id]/components/accordion/accounts/api";
import { colDirectDebitAuthorisationAccounts } from "@app/products/direct-debit/authorisations/[id]/components/accordion/accounts/config";
import { REFRESH_AUTHORISATION_ACCOUNTS_ACCORDION } from "@app/products/direct-debit/authorisations/[id]/constant";
import { DirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/model";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<DirectDebitAuthorisation>();

export const DDAuthorisationAccount = observer(() => {
  const { cancelToken } = useCancelRequest();
  const [accounts, setAccounts] = useState<any[]>([]);
  const { dataForms } = useFlexibleFormStore();
  const authorisation = dataForms.GeneralForm as DirectDebitAuthorisation;

  useAddUniqueEventEmitter([
    {
      eventType: REFRESH_AUTHORISATION_ACCOUNTS_ACCORDION,
      listener: () => {
        slider.fetchApi();
      },
    },
  ]);

  const slider = useFlexibleFetchData({
    slides: [
      {
        fetch: () =>
          getDirectDebitAuthorisationAccounts(
            authorisation.Authorisation_Number,
            cancelToken()
          ),
        handleSuccess: ({ dataFromApi }) => {
          setAccounts(dataFromApi?.data ?? []);
        },
      },
    ],
  });

  useEffectOnce(() => {
    slider.fetchApi();
  });

  return (
    <CCGrid
      errorComponent={slider.errorComponent}
      isLoading={slider.isFetching}
      columnFields={colDirectDebitAuthorisationAccounts}
      data={accounts ?? []}
      primaryField={nameOf("Account_Reference")}
    />
  );
});
