import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import { switchOptionsValueNumberString } from "@app/products/animals/system-admin/settings/_id/main/config";
import {
  ICommonFormProps,
  ISwitchCustomeFieldProps,
} from "@app/products/animals/system-admin/settings/_id/main/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/combo-box/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { CCLabel } from "@components/cc-label/_index";
import { SettingDynamicGridFields } from "@components/setting/setting-dynamic-grid-fields/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfLookupItem = nameOfFactory<ILookupItem>();

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { getConfigDataField } = useAnimalsSettingStore();
  const { isEditing } = useAnimalsSettingManageStore();

  const { onChange, valueGetter } = formRenderProps;

  const showRegisterFields: ISwitchCustomeFieldProps[] = [
    {
      eSettingsField: ECorporateSettingsField.Animals_ShowOSFees,
      isEditing: isEditing,
    },
    {
      eSettingsField: ECorporateSettingsField.Animals_ShowAge,
      isEditing: isEditing,
    },
  ];

  const animalsDefaultViewData = getConfigDataField(
    ECorporateSettingsField.Animals_AnimalsDefaultView
  );

  const animalViewsFields = [
    ECorporateSettingsField.Animals_ViewCatsByBreed,
    ECorporateSettingsField.Animals_ViewOtherByBreed,
    ECorporateSettingsField.Animals_ViewAnimalsByType,
    ECorporateSettingsField.Animals_ViewDogRegister,
    ECorporateSettingsField.Animals_ViewCatRegister,
    ECorporateSettingsField.Animals_ViewCatBreedRegister,
  ];

  const eDisplayAnimalType = ECorporateSettingsField.Animals_DisplayAnimalType;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        {showRegisterFields.map((item: ISwitchCustomeFieldProps) => (
          <SwitchGlobalSettings
            data={getConfigDataField(item.eSettingsField)}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
            customChecked={
              valueGetter(`${item.eSettingsField}`) ===
              switchOptionsValueNumberString.TRUE
            }
            customOnChange={(event: SwitchChangeEvent) => {
              onChange(`${item.eSettingsField}`, {
                value: event?.value
                  ? switchOptionsValueNumberString.TRUE
                  : switchOptionsValueNumberString.FALSE,
              });
            }}
          />
        ))}
      </div>

      <div className="cc-form-cols-3">
        <CCLabel title="Animal views" />
      </div>

      <div className="cc-field-group cc-custom-sub-panel-bar">
        <div className="cc-form-cols-3">
          {animalViewsFields.map((eField: ECorporateSettingsField) => (
            <SwitchGlobalSettings
              data={getConfigDataField(eField)}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          ))}
        </div>
      </div>

      <div className="cc-form-cols-3">
        <SwitchGlobalSettings
          data={getConfigDataField(eDisplayAnimalType)}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
          customChecked={
            valueGetter(`${eDisplayAnimalType}`) ===
            switchOptionsValueNumberString.TRUE
          }
          customOnChange={(event: SwitchChangeEvent) => {
            onChange(`${eDisplayAnimalType}`, {
              value: event?.value
                ? switchOptionsValueNumberString.TRUE
                : switchOptionsValueNumberString.FALSE,
            });
          }}
        />

        <ComboBoxGlobalSettings
          data={animalsDefaultViewData}
          isEditing={isEditing}
          onChange={(e: ComboBoxChangeEvent) => {
            onChange(`${ECorporateSettingsField.Animals_AnimalsDefaultView}`, {
              value: e.value?.Id ?? null,
            });
          }}
          value={getDropdownValue(
            valueGetter(
              `${ECorporateSettingsField.Animals_AnimalsDefaultView}`
            ),
            animalsDefaultViewData?.LookupItems ?? [],
            nameOfLookupItem("Id")
          )}
        />
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <SettingDynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
