import {
  DTO_Parcel,
  DTO_Title,
} from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import {
  DTO_TransactionTotals,
  DTO_Transaction_Item,
  eComponent,
} from "@app/products/property/model";

export enum eTransactionSources {
  Unknown = 0,
  CashLink = 1,
  Based_on_Transaction_Id = 2,
  Trf_From_Transaction_Id = 3,
  Charge_being_Written_Off = 4,
  Charge_Item_being_Written_Off = 5,
  Charge_Raised_by_Journal = 6,
  Supplementary_Charge_Raised = 7,
  Charge_Balances_Adjustment = 8,
  CashLink_Agency_Payment = 9,
  Arrangement = 10,
  CashLink_Discount = 11,
  CashLink_Agency_Payment_Discount = 12,
  CashLink_Rebate = 13,
  Deferment_of_Amounts_Owing = 15,
  Charge_Run_Levy = 20,
  Charge_Run_Postponed = 21,
  Charge_Run_Rebate = 22,
  Supplementary_Levy = 25,
  Supplementary_Postponed = 26,
  Supplementary_Rebate = 27,
  Supplementary_Balance_Transfer = 28,
  Utility = 30,
  Change_of_Ownership = 31,
  Compliance_Backdated_Interest = 35,
  Compliance_Penalty = 36,
  CashLink_Interest = 40,
  Journal_Interest = 41,
  Penalty_Interest = 42,
  Rebate_Adjustment = 50,
  Rebate_on_Charge_Raised = 51,
  Credit_Reallocation = 60,
  Reallocation_due_to_change_of_Instalment_Plan = 61,
  WA_Instalment_Administration_Fee = 62,
  WA_Transfer_Scheme_Balances_to_Assessment = 63,
  WA_Agency_Payment_for_Scheme = 64,
  WA_Reversal_of_Instalment_Admin_Fee = 65,
  Transfer_unallocated_credits_to_Land_Tax = 66,
  Credit_Reallocation_of_Deferred_Charges = 67,
  Credit_Reallocation_of_Supplementaries = 68,
  Based_on_Account_Transaction_Id = 70,
  Based_on_Scheme_Account_Transaction_Id = 80,
  Change_of_Cost_on_Scheme_Account = 81,
  Raise_Scheme_Interest = 82,
  Raise_Scheme_Instalment = 83,
  Raise_Instalment_Penalty_SA = 90,
  Conversion_to_Property = 99,
  Charge_raised_on_Insert_of_Account = 100,
  Charge_raised_on_New_Account_dialog = 101,
  Charge_raised_by_CashLink = 102,
  Automatic_Periodic_Charge = 103,
  Reversal_Payment_from_CashLink_Direct_Debits = 104,
  Debt_Recovery_Cost = 110,
  New_Deferred_Duty_Amount = 120,
  Raise_Deferred_Duty_Interest = 121,
  Raise_Deferred_Duty_Instalment = 122,
  Change_Deferred_Duty_Amount = 123,
  Based_on_DDS_Account_Transaction_Id = 124,
  Procure_Refund = 130,
  Meter_Usage_Journal = 140,
  Receipting_Payment = 150,
  Receipting_Rebate = 151,
  Receipting_Discount = 152,
}

export interface DTO_Assessment_Rebates {
  FormType: number;
  Component: eComponent;
  AssociatedLots: DTO_Title[];
  AssociatedParcels: DTO_Parcel[];
  AssociatedEntities: DTO_AssessmentRebateEntitlementEntity[];
  ConcessionCards: DTO_ConcessionCard[];
  RebateAppliedOnAssessment: DTO_Transaction[];
  EntitledToRebate: DTO_AssessmentRebateEntitlement[];
  ShowHistoricalEntitlements: boolean;
  Total: DTO_TransactionTotals;
  AssessmentId: number;
}

export interface DTO_TransactionAllocation {
  TransactionItem: DTO_Transaction_Item[];
  Totals: DTO_Totals;
}

export interface DTO_Totals {
  Amount: number | null;
}

export interface DTO_AssessmentRebateEntitlement {
  Id: number;
  RebateType: string;
  PercentageOfFullRebate: number;
  ApplicationDate: Date | null;
  EffectiveDate: Date | null;
  EntitledToAlternateRebateCap: boolean;
  ChangedOn: Date | null;
  ChangedBy: string;
  IsActive: boolean;
  EntitledEntitiesAsString: string;
  AssessmentNumber: string;
  PropertyAddress: string;
  LossOfEligibilityDate: Date | null;
  AssociatedEntities: DTO_AssessmentRebateEntitlementEntity[];
}

export interface DTO_AssessmentRebateEntitlementEntity {
  Id: number;
  EntitledEntitiesAsString: string;
  IsEntitledToRebate: boolean;
  ConcessionCardHeld: string;
}

export interface DTO_ConcessionCard {
  Id: string;
  CardType: string;
  CardNumber: string;
  CardHolder: string;
  DateOfBirth: string | null;
  Gender: string;
  DatePresented: string | null;
  ConfirmationDate: string | null;
  AssessmentRebateEntitlements: DTO_AssessmentRebateEntitlement[];
  AssessmentRebateEntitlementsAsString: string;
  RebatesEffectiveFrom: string | null;
  IsActive: boolean;
  ConcessionCardId: number;
}

export interface DTO_Entity {
  id: number;
  EntityId: number;
  DateOfBirth: string | null;
  DateOfBirthAsString: string;
  ConcessionCardHeld: string;
  NameAddress: DTO_EntityNameAddress;
  Name: string;
}

export interface DTO_EntityNameAddress {
  Id: number;
  Name: string;
}

export interface DTO_Assessment {
  Id: number;
  NumberFormatted: string;
  NumberFormattedTrimmed: string;
  PropertyName: string;
  PropertyAddress: string;
  PropertyName_Address_Locality: string;
  Ratepayer: string;
  AssessmentReference: string;
}

export interface DTO_Transaction {
  AssessmentPropertyAddress: string;
  AssessmentReference: string;
  CreatedBy: string;
  AssessmentRebateEntitlementId: number | null;
  RebateClaimId: number | null;
  CreatedOn: string | null;
  FinancialYear: string;
  PaymentAllocationNumber: number | null;
  AutoAllocate: boolean;
  IsCancelled: boolean;
  IsProposed: boolean;
  SourceId: number | null;
  TransactionSource: eTransactionSources | null;
  Notes: string;
  ReceiptNumber: number | null;
  Description: string;
  Reference: string;
  ProgressiveBalance: number | null;
  Amount: number;
  DateTime: string;
  TransactionType: string;
  TransactionId: number;
  JournalNumber: number | null;
  AssessmentRatePayer: string;
  TransactionAllocation: DTO_TransactionAllocation;
  Id: number;
}
