import { history } from "@/AppRoutes";
import { colHMPremisesAlert } from "@app/products/hm/config";
import { HMPremisesView } from "@app/products/hm/premises/model";
import { nameOfHMPremisesView } from "@app/products/hm/premises/util";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
} from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IGenerateColFieldBySetting } from "@common/models/settings";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { convertToTitleCase } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep, isEmpty, isNil } from "lodash";
import { PREMISES_MANAGE_ROUTE } from "../[id]/constant";

export const colHMPremisesByOfficer = [
  {
    field: nameOfHMPremisesView("RegNo"),
    title: "Registration Number",
    locked: true,
    handleOnClick: (rowValue: HMPremisesView) => {
      // Confirmed with Zach and Rebecca in the catch-up meeting 03/10/2024
      // If the RegNo is empty or null, click on the blank cell to navigate
      if (isEmpty(rowValue?.RegNo) || isNil(rowValue?.RegNo)) {
        history.push(`${PREMISES_MANAGE_ROUTE}/${rowValue.ID}`);
      }
    },
    linkTo: (dataItem: HMPremisesView) => {
      return `${PREMISES_MANAGE_ROUTE}/${dataItem?.ID}`;
    },
  },
  ...colHMPremisesAlert,
  {
    field: nameOfHMPremisesView("TradingName"),
    title: "Trading Name",
  },
  {
    field: nameOfHMPremisesView("Address"),
    title: "Address",
  },
  {
    field: nameOfHMPremisesView("Area"),
    title: "Area",
  },
  {
    field: nameOfHMPremisesView("PremisesClassification"),
    title: "Classification",
  },
  {
    field: nameOfHMPremisesView("PremisesType"),
    title: "Type",
  },
  {
    field: nameOfHMPremisesView("Risk"),
    title: "Risk",
  },
  {
    field: nameOfHMPremisesView("Phone"),
    title: "Phone",
  },
  {
    field: nameOfHMPremisesView("Proprietor"),
    title: "Proprietor",
  },
  {
    field: nameOfHMPremisesView("PrimaryContact"),
    title: "Contact",
  },
  {
    field: nameOfHMPremisesView("Email"),
    title: "Email",
  },
  {
    field: nameOfHMPremisesView("DateLodged"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMPremisesView("DateRegistrationExpires"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMPremisesView("AmountOS"),
    title: "Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfHMPremisesView("RenewalApproved"),
    title: "Renewal Approved",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];

export const generateHMPremisesByOfficerColumnsBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateColFieldBySetting) => {
  if (!settings) return defaultConfig;

  const columnRegNoModifier = (column: IColumnFields) => {
    const registrationNoLabel = getStringValueSetting(
      settings[ECorporateSettingsField.HealthManager_RegistrationNoLabel]
    );
    const title = registrationNoLabel
      ? convertToTitleCase(registrationNoLabel)
      : column.title;
    return { ...column, title };
  };
  const columnMapper = (column: IColumnFields) => {
    switch (column.field) {
      case nameOfHMPremisesView("RegNo"):
        return columnRegNoModifier(column);
      default:
        return column;
    }
  };
  const columnFilterAmountOS = (_column: IColumnFields) => {
    return (
      getBoolValueSetting(
        settings[ECorporateSettingsField.HealthManager_ShowLastInspOSFees]
      ) ?? true
    );
  };
  const columnFilterRenewalApproved = (_column: IColumnFields) => {
    return getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_UseRenewalApprovals]
    );
  };
  const columnFilter = (column: IColumnFields) => {
    switch (column.field) {
      case nameOfHMPremisesView("AmountOS"):
        return columnFilterAmountOS(column);
      case nameOfHMPremisesView("RenewalApproved"):
        return columnFilterRenewalApproved(column);
      default:
        return true;
    }
  };
  return cloneDeep(defaultConfig.filter(columnFilter)).map(columnMapper);
};
