import { CORE_OFFICERS_ODATA } from "@app/core/components/common/constant";

export const CSLUrl = {
  // CSL CRUD API
  CREATE_CSL: "",
  GET_CSL: "/api/CSM/Internal/Licence/{id}",
  UPDATE_CSL: "/api/CSM/Internal/Licence/{id}",
  DELETE_CSL: "",

  // CSL Form OData
  GET_LICENCE_LOVS: "/api/CSM/Internal/licencelovs",

  // CSL View OData
  GET_CSL_ISSUING_OFFICERS: CORE_OFFICERS_ODATA,
  GET_CSL_APPROVAL_OFFICERS: CORE_OFFICERS_ODATA,
  //CSL Register Easements
  GET_CLS_REGISTER_EASEMENTS_APPLITION: CORE_OFFICERS_ODATA,
  GET_VIEW_CSL_LICENCES_ISSUED:
    "/odata/csm/internal/licenceregister/GetViewLicencesIssued?$count=true&",
  GET_VIEW_CSL_LICENCES_NEW:
    "/odata/csm/internal/licenceregister/GetViewLicencesNew?$count=true&",

  // Mock CSL View OData
  GET_VIEW_CSL_LICENCES_BYTYPE: "",
  GET_VIEW_CSL_LICENCES_EXPIRED: "",
  GET_VIEW_CSL_LICENCES_NONLICENSED: "",
  GET_VIEW_CSL_LICENCES_ALLLICENCES: "",
  GET_VIEW_CSL_RENEWALS_REQUIRESRENEWALFEE: "",
  GET_VIEW_CSL_RENEWALS_REQUIRESRENEWALNOTICE: "",
  GET_VIEW_CSL_RENEWALS_RENEWALNOTICESENT: "",
  GET_VIEW_CSL_RENEWALS_REQUIRESAPPROVAL: "",
  GET_VIEW_CSL_RENEWALS_REQUIRESCERTIFICATE: "",
  GET_VIEW_CSL_RENEWALS_DUEFOREXPIRY: "",
  GET_VIEW_CSL_REVIEW_PHOTOEXPIRY: "",
  GET_VIEW_CSL_REVIEW_REPORTDUE: "",
  GET_VIEW_CSL_REVIEW_ETHICSDUE: "",
  GET_VIEW_CSL_REVIEW_BYCONDITIONTITLE: "",
  GET_VIEW_CSL_FEES_OSFEES: "",
  GET_VIEW_CSL_FEES_ALLFEES: "",
  GET_VIEW_CSL_INSPECTIONS_BYDATE: "",
  GET_VIEW_CSL_INSPECTIONS_BYPOSTCODE: "",
  GET_VIEW_CSL_INSPECTIONS_BYTYPE: "",
  GET_VIEW_CSL_INSPECTIONS_BYOFFICER: "",
  GET_VIEW_CSL_INSPECTIONS_BYMONTH: "",
  GET_VIEW_CSL_SYSTEMADMIN_CATEGORIES: "",
  GET_VIEW_CSL_SYSTEMADMIN_CONDITIONS: "",
};
