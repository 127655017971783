import { IReAssign } from "@app/products/property/fire-prevention/components/dialogs/re-assign/model";

export const mockReAssign: IReAssign[] = [
  {
    Contractor: "Ben Godfrey",
    PreferredWortRegion: "Hobart",
    NumberOfOustandingWorks: null,
    NumberOfCompletedWorks: null,
    LastAssignedWork: null,
    LastCompletedWork: null,
    ContractorId: 100,
  },
  {
    Contractor: "Mark Steven",
    PreferredWortRegion: null,
    NumberOfOustandingWorks: 1,
    NumberOfCompletedWorks: 0,
    LastAssignedWork: null,
    LastCompletedWork: null,
    ContractorId: 102,
  },
  {
    Contractor: "Nick Paine",
    PreferredWortRegion: "Hobart",
    NumberOfOustandingWorks: null,
    NumberOfCompletedWorks: null,
    LastAssignedWork: null,
    LastCompletedWork: null,
    ContractorId: 101,
  },
];
