import { AppSearchOption } from "@components/layout/cc-header/components/app-search/model";

export const appSearchConfig = {
  typeSpeed: 500,
  minCharacters: 3,
  defaultSearchOption: AppSearchOption.View,
  maximumResults: 10,
  maximumLenghtURL: 1800,
  maximumColumnFilted: 10,
  searchOptionData: [
    AppSearchOption.View,
    AppSearchOption.Module,
    AppSearchOption.All,
  ],
};
