import { sleep } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { mockParcel } from "./mock";
import { SearchField } from "./model";

export const loadSearchParcel = async (filter: FilterDescriptor) => {
  const parcelList = mockParcel.map((parcel: any) => {
    return {
      ...parcel,
      [SearchField.Address]: parcel.Address,
      [SearchField.Name]: parcel.Name + " - " + parcel.Address,
      [SearchField.LegalDescription]:
        parcel.LegalDescription + " - " + parcel.Address,
      [SearchField.ParcelId]: parcel.ParcelId + " - " + parcel.Address,
      [SearchField.ParcelReference]:
        parcel.ParcelReference + " - " + parcel.Address,
    };
  });

  await sleep(1000);
  return filterBy([...parcelList], filter);
};
