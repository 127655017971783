import { IMainMenuItem } from "@components/layout/model";
export const ContactsMenu: IMainMenuItem[] = [
  {
    menuName: "Contacts",
    subItems: [
      {
        name: "All",
        path: "/all",
      },
      { name: "ABC", path: "/abc" },
      { name: "DEF", path: "/def" },
      {
        name: "GHI",
        path: "/ghi",
      },
      { name: "JKL", path: "/jkl" },
      { name: "MNO", path: "/mno" },
      { name: "PQRS", path: "/pqrs" },
      { name: "TUV", path: "/tuv" },
      { name: "WXYZ", path: "/wxyz" },
      { name: "Contact Alerts", path: "/contactalerts" },
      { name: "Duplicates", path: "/duplicates" },
      { name: "Deleted", path: "/deleted" },
      { name: "Change Queue", path: "/changequeue" },
    ],
  },
];
