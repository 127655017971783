import { getResponsesQuestions } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/responses/api";
import { ILoadDynamicQuestionListParams } from "@app/products/property/certificates/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { isNil } from "lodash";

/**
 * Loads a dynamic question list based on the provided ID.
 *
 * @param {ILoadDynamicQuestionListParams} params - The parameters for loading the dynamic question list.
 *
 * @typedef {Object} ILoadDynamicQuestionListParams
 * @property {string} dynamicQuestionListId - The ID of the dynamic question list to load.
 * @property {() => void} onTurnOnLoading - Callback function to turn on loading state.
 * @property {() => void} onTurnOffLoading - Callback function to turn off loading state.
 * @property {(data: DTO_ChecklistQuestion[]) => void} onSuccess - Callback function to handle successful API response. Receives the list of questions as an argument.
 * @property {(error: any) => void} onError - Callback function to handle API errors. Receives the error object as an argument.
 *
 * @returns {Promise<void>} - A promise that resolves when the operation is complete.
 */
export const loadDynamicQuestionList = async (
  params: ILoadDynamicQuestionListParams
): Promise<void> => {
  const {
    dynamicQuestionListId,
    onTurnOnLoading,
    onTurnOffLoading,
    onSuccess,
    onError,
  } = params;
  if (isNil(dynamicQuestionListId)) return;

  if (typeof onTurnOnLoading === "function") onTurnOnLoading();
  const response = await getResponsesQuestions(dynamicQuestionListId);
  if (typeof onTurnOffLoading === "function") onTurnOffLoading();
  if (isSuccessResponse(response) && response?.data) {
    onSuccess(response?.data?.Questions ?? []);
  } else {
    onError(response.error);
  }
};
