import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { Documents } from "@app/core/documents/_index";
import { WastewaterRecordsState } from "@app/products/waste-water/model";
import { FormRoute } from "@common/constants/menu";
import { RECORDTYPE } from "@common/constants/recordtype";
import { getContactRelationshipById } from "@common/pages/contacts/api";
import { ContactRelationshipForm } from "@common/pages/contacts/components/contact-relationship-form/_index";
import {
  ContactRelationshipFormData,
  ContactRelationshipResponse,
} from "@common/pages/contacts/contact-relationship/model";
import { convertContactResponseToForm } from "@common/pages/contacts/contact-relationship/utils";
import { ContactFromNarDialog } from "@common/pages/contacts/contacts-from-nar-dialog/_index";
import Contacts from "@common/pages/contacts/_index";
import { splitCamelCase } from "@common/utils/formatting";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ICCPanelProps } from "@components/cc-panel/_index";
import { CCPanelsBar } from "@components/cc-panels-bar/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IProps {
  isLoading: boolean;
  setIsLoading: (status: boolean) => void;
  contactRelationshipInfo?: ContactRelationshipFormData;
}

export const ExistedContactRelationship = ({
  isLoading,
  setIsLoading,
}: IProps) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [contactRelationshipInfo, setContactRelationshipInfo] =
    useState<ContactRelationshipFormData>();

  useEffectOnce(() => {
    setIsLoading(true);
    getContactRelationshipById(id).then(
      (response: ContactRelationshipResponse) => {
        if (response) {
          setContactRelationshipInfo(convertContactResponseToForm(response));
        } else {
          history.replace(FormRoute.FORM_CONTACT_REPLATIONSHIP + "/new");
        }
        setIsLoading(false);
      }
    );
  });

  const [wastewaterRecords] = useState<WastewaterRecordsState | undefined>(
    undefined
  );

  const listPanelBar: ICCPanelProps[] = [
    {
      title: "Actions",
      badge: wastewaterRecords?.actions?.toString(),
      component: <Actions id={id} recordType={RECORDTYPE.WW_System} />,
    },
    {
      title: "Comments",
      badge: wastewaterRecords?.comments?.toString(),
      component: <Comments id={id} recordType={RECORDTYPE.WW_System} />,
    },
    {
      title: "Contacts Roles",
      badge: wastewaterRecords?.contacts?.toString(),
      component: <Contacts id={id} recordType={RECORDTYPE.WW_System} />,
    },
    {
      title: "Documents",
      badge: wastewaterRecords?.documents?.toString(),
      component: (
        <Documents id={parseInt(id)} recordType={RECORDTYPE.WW_System} />
      ),
    },
  ];

  const location: any = useLocation();
  const title = location.state?.title;
  const getContactBadge = (): string => {
    const contactType = splitCamelCase(
      title || contactRelationshipInfo?.ContactRelationshipType_ENUM || ""
    );
    const contactID = `ContactID ${id}`;

    if (contactType && id) {
      return contactType + " - " + contactID;
    } else {
      return contactType || contactID || "N/A";
    }
  };
  const [isShowNavigation, setIsShowNavigation] = useState<boolean>(false);
  const [showContactFromNar, setShowContactFromNar] = useState(false);
  const NavigationMoreOptions = () => {
    if (!isShowNavigation) {
      return (
        <>
          <label
            className="cc-label-button"
            onClick={() => {
              setIsShowNavigation(true);
            }}
          >
            More Options
          </label>
        </>
      );
    }
    return (
      <>
        <label className="cc-label-button">Pick Another Contact</label>
        <label
          className="cc-label-button"
          onClick={() => setShowContactFromNar(true)}
        >
          Pick From NAR
        </label>
        <Button
          fillMode="flat"
          className="cc-icon-button"
          onClick={() => {
            setIsShowNavigation(false);
          }}
          iconClass="fal fa-times"
        />
      </>
    );
  };
  return (
    <>
      {showContactFromNar && (
        <ContactFromNarDialog
          onCloseDialog={() => setShowContactFromNar(false)}
          onSubmitDialog={() => setShowContactFromNar(false)}
        />
      )}
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Contact Relationship"} />
      <FormTitle title={"Contact Relationship"} badge={getContactBadge()} />
      <div className="cc-product-action-bar">
        <div className="cc-action-bar-row">
          <label className="cc-label-button" htmlFor={"btn-submit"}>
            Save
          </label>
          <div className="cc-navigation-more">
            <NavigationMoreOptions />
          </div>
        </div>
      </div>
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <div className="cc-manage-form-body">
            {contactRelationshipInfo && (
              <CCGeneralPanel
                component={
                  <ContactRelationshipForm
                    isNew={false}
                    setIsLoading={setIsLoading}
                    formData={contactRelationshipInfo.Contact}
                  />
                }
              />
            )}
            <CCPanelsBar initialPanels={listPanelBar} />
          </div>
        </div>
      </div>
    </>
  );
};
