import { useCommonCoreStore } from "@common/stores/core/store";
import { CCLogo } from "@components/cc-logo/_index";
import Loading from "@components/loading/Loading";
import React, { useEffect, useState } from "react";
import "./_index.scss";
const Greeting = (props: any) => {
  const [greetingText, setGreetingText] = useState("Good Morning");
  const [displayName, setDisplayName] = useState(props.displayName);
  const { headerBarLogo, homePageLogo1, homePageLogo2 } = useCommonCoreStore();

  useEffect(() => {
    const time = new Date().getHours();
    setDisplayName(props.displayName);
    if (time >= 5 && time < 12) {
      setGreetingText("Good morning");
    } else if (time >= 12 && time < 17) {
      setGreetingText("Good afternoon");
    } else {
      setGreetingText("Good evening");
    }
  }, [props]);

  return (
    <div className="cc-greeting-section">
      <div className="cc-brand-container">
        {headerBarLogo && (
          <CCLogo
            className="cc-logo-header-bar"
            alt="Company Logo"
            src={headerBarLogo}
          />
        )}
        {homePageLogo1 && (
          <CCLogo
            className="cc-logo-home-page-1"
            alt="Company Logo"
            src={homePageLogo1}
          />
        )}
        {homePageLogo2 && (
          <CCLogo
            className="cc-logo-home-page-2"
            alt="Company Logo"
            src={homePageLogo2}
          />
        )}
      </div>
      <div className="cc-greeting-text">
        {/* {displayName !== "" && <i className="fas fa-sun"></i>} // TODO: will add again */}
        <h2>
          {greetingText},{" "}
          {displayName !== "" ? displayName : <Loading isLoading />}
        </h2>
      </div>
    </div>
  );
};
export default Greeting;
