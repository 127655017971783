import { DTO_LOV } from "@common/models/odataResponse";

interface IOfficerInitData {
  WD_Assessment_Group_Id: number;
  Officer: string;
  OfficerAssessmentGroups: DTO_LOV[];
}

interface IOfficerProcessData {
  WD_Assessment_Group_Id: number;
}

export const officerUtilProcessing = {
  //Process data init for officer
  processDataInit(initialValues: any) {
    const initOfficerRegion: IOfficerInitData = {
      WD_Assessment_Group_Id:
        initialValues?.WorkflowHeader?.WorkflowDraft?.WD_Assessment_Group_Id ??
        initialValues?.WorkflowHeader?.OfficerAssessmentGroups?.[0]?.Code ??
        null,
      Officer: initialValues?.WorkflowHeader?.Officer ?? "",
      OfficerAssessmentGroups:
        initialValues?.WorkflowHeader?.OfficerAssessmentGroups ?? [],
    };

    return initOfficerRegion;
  },
  //Process officer data
  processData(data: any, stepKey: string): IOfficerProcessData {
    return {
      WD_Assessment_Group_Id: +data?.[stepKey]?.WD_Assessment_Group_Id,
    };
  },
  /* Check the first time reload data */
  canReloadWorkflowData(isFromActionList: boolean): boolean {
    return !isFromActionList;
  },
};
