import { FeesForm } from "@app/core/fees/[id]/components/child-screens/general/_index";
import { useFeesStore } from "@app/core/fees/[id]/store";
import { generateInvoiceItemTitle } from "@app/core/invoice/[id]/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewManageFees = observer(() => {
  const {
    isLoading,
    onSubmit,
    responseLoadError,
    loadFinanceFees,
    financeFee,
    parents,
  } = useFeesStore();
  const { settings } = useCommonCoreStore();

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation
        title={generateInvoiceItemTitle(settings, RECORDTYPE.CORE_Invoice)}
      />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadFinanceFees(0, parents?.id?.toString())}
        />
      ) : (
        <>
          <FormTitle
            title={`New ${generateInvoiceItemTitle(
              settings,
              RECORDTYPE.CORE_Invoice,
              true
            )}`}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
                // Check disabled case - when no having parent
              />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {financeFee && <FeesForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
