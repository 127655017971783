import { DTO_AmountsOwing_Group } from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/model";
import { formatStyleProminent } from "@common/utils/formatting";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import React, { useMemo } from "react";
import { colCharges, colFieldBalance } from "./config";
import { getCurrentLevelNameFormat } from "./util";

interface IAmountOwingGridProps {
  currentGroup: DTO_AmountsOwing_Group;
}
export default function AmountOwingGrid({
  currentGroup,
}: IAmountOwingGridProps) {
  const columnFields = useMemo<IColumnFields[]>(() => {
    let colField: IColumnFields[] = [];
    if (currentGroup.Charges) {
      colField = colCharges;
    } else {
      colField = colFieldBalance;
      colField[0].title = currentGroup.ChildLevelCaption;
      colField[0].format = getCurrentLevelNameFormat(currentGroup.ChildLevel);
    }
    const footerData: any = currentGroup.Totals;
    colField = colField.map((field: IColumnFields) => {
      let fieldName = field.field;
      if (currentGroup.Charges && fieldName === "TotalBalance") {
        fieldName = "Total";
      } else {
        fieldName = fieldName.replace("_", "");
      }

      const footerValue = footerData[fieldName];
      return {
        ...field,
        footerCell:
          footerValue !== undefined ? (
            <CurrencyCell style={formatStyleProminent} value={footerValue} />
          ) : undefined,
      };
    });

    return colField;
  }, [currentGroup]);

  const gridData = useMemo(() => {
    if (currentGroup.Charges) return currentGroup.Charges;
    if (currentGroup.ChildGroups) {
      return currentGroup.ChildGroups.map(
        (group: DTO_AmountsOwing_Group, index: number) => ({
          ...group,
          _Balance: group.Totals.Balance,
          _InterestBalance: group.Totals.InterestBalance,
          _Total: group.Totals.Total,
          _No: index,
        })
      );
    }
    return [];
  }, [currentGroup]);

  return (
    <>
      {!currentGroup.IsTopLevel ? (
        <label className="cc-label">{currentGroup.ChildLevelCaption}</label>
      ) : null}
      <CCGrid
        className="cc-amount-owing-grid"
        data={gridData || []}
        columnFields={columnFields}
        primaryField={currentGroup.Charges ? "Id" : "_No"}
        detail={
          !currentGroup.IsLastLevel
            ? ({ dataItem }: any) => {
                return <AmountOwingGrid currentGroup={dataItem} />;
              }
            : undefined
        }
      />
    </>
  );
}
