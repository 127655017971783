export enum EDialogStepAssociation {
  Holding = 1,
  Folio = 2,
  DeleteHolding = 3,
  DeleteFolio = 4,
}

export enum EPICGridType {
  Holding = 1,
  Folio = 2,
  ViewConfiguration = 3,
}

export enum EMessageDelete {
  Holding = "Removing a Holding will remove the associated Lots in the Lots grid",
  Folio = "Removing a Lot will remove the associated Holdings in the Holdings grid",
}
