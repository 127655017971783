import { ManageFinancialSummaries } from "@app/products/property/assessments/financial-summaries/components/manage-dialog/_index";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const NewFinancialSummaryButton = () => {
  const [showNewFinancialSummaryDialog, setShowNewFinancialSummaryDialog] =
    useState(false);

  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
        privilegesEnum={EPrivilegesWorkflow.Financial_Summary_Create}
      >
        <CCNavButton
          title="New financial summary"
          onClick={() => setShowNewFinancialSummaryDialog(true)}
        />
      </PrivilegeWrapper>
      {showNewFinancialSummaryDialog && (
        <ManageFinancialSummaries
          onClose={() => {
            setShowNewFinancialSummaryDialog(false);
          }}
          isNew
        />
      )}
    </>
  );
};
