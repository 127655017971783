import React, { ReactNode, forwardRef, useMemo } from "react";
import "./_index.scss";
export interface ICCResultProps {
  status?: "info" | "error" | "success" | "warning";
  title?: ReactNode;
  subTitle?: ReactNode;
  extra?: ReactNode;
  className?: string;
  style?: object;
}
export const CCResult = forwardRef(
  (
    {
      status = "warning",
      title = "Warning message",
      subTitle,
      extra,
      className = "",
      style,
    }: ICCResultProps,
    ref: React.LegacyRef<HTMLDivElement> | undefined
  ) => {
    const iconClass = useMemo(() => {
      switch (status) {
        case "info":
          return "fas fa-exclamation-circle";
        case "error":
          return "fas fa-times-circle";
        case "success":
          return "fas fa-check-circle";
        case "warning":
          return "fas fa-exclamation-triangle";
        default:
          return "fas times-circle";
      }
    }, [status]);
    return (
      <div
        ref={ref}
        className={`cc-result cc-result-${status} ${className}`}
        style={style}
      >
        <div className="cc-result-icon">
          <i className={iconClass} />
        </div>
        {title ? <h2>{title}</h2> : null}
        {subTitle ? (
          Array.isArray(subTitle) ? (
            subTitle.map((content, key) => <h6 key={key}>{content}</h6>)
          ) : (
            <h6>{subTitle}</h6>
          )
        ) : null}
        {extra}
      </div>
    );
  }
);
