import { NoticeFormElement } from "@app/core/notices/[id]/components/child-screens/general/components/form-element/_index";
import { Notice } from "@app/core/notices/[id]/model";
import { useNoticesStore } from "@app/core/notices/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const NoticeForm = observer(() => {
  const { notices, setOnSubmit, saveNotices } = useNoticesStore();
  const { pushNotification } = useCCAppNotificationStore();
  const isNew = useIsNew();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();
  const handleOnSubmit = (submitEvent: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = submitEvent;
    const actionSubmit = submitEvent.event?.currentTarget
      ?.name as ActionSubmitActions;
    if (!(actionSubmit in ActionSubmitActions)) return;

    if (!isValid) return;
    if (!isModified && [ActionSubmitActions.Save].includes(actionSubmit))
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });

    saveNotices(
      { ...values, Description: values?.Description ?? "" } as Notice,
      isNew
    );
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, notices]);

  return (
    <div className="cc-form">
      <Form
        initialValues={notices}
        onSubmitClick={handleOnSubmit}
        key={JSON.stringify(notices)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <NoticeFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
