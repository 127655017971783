import { ICCRoute } from "@common/constants/ICCRoute";

export const subCategoryManageRoute: ICCRoute = {
  path: "subcategory",
  name: "Subcategory",
  children: [
    {
      path: ":subCategoryId(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
