import { DirectDebitSessionHeader } from "@app/products/direct-debit/sessions/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const getDirectDebitSessionBySessionID = async (
  session_Id: number,
  cancelToken?: CancelToken
): Promise<APIResponse<DirectDebitSessionHeader> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<DirectDebitSessionHeader>(
      `api/receipting/internal/directdebitsession/${session_Id}`,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const getDDSessionExportBankFile = async (
  sessionNumber: string,
  cancelToken: CancelToken
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<IdentityPacket>(
      `api/receipting/internal/directdebitsession/${sessionNumber}/exportbankfile`,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const getDDSessionSendToReceipting = async (
  sessionNumber: string,
  cancelToken: CancelToken
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<IdentityPacket>(
      `api/receipting/internal/directdebitsession/${sessionNumber}/exportagencyimportfile`,
      {
        cancelToken,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postDDSessionChangeStatus = async (
  sessionHeader: DirectDebitSessionHeader,
  cancelToken: CancelToken
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `api/receipting/internal/DirectDebitSessionHeader/changeSessionStatus`,
      sessionHeader,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
