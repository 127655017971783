import { useDeferredDutyStore } from "@app/products/property/deferred-duty/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { DeferredDutyGeneralFormElement } from "./form-element/_index";

export const PropertyDeferredDutyGeneral = observer(() => {
  const { deferredDuty, setOnSubmit, onSubmit } = useDeferredDutyStore();
  const { pushNotification } = useCCAppNotificationStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = ({
    isModified,
    isValid,
    event,
  }: FormSubmitClickEvent) => {
    if (isModified) {
      if (event && onSubmit) onSubmit(event);
      return;
    }
    if (!isValid) return;
    pushNotification({
      title: "The form is not modified",
      type: "warning",
    });
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, deferredDuty]);

  return (
    <div className="cc-form">
      <Form
        initialValues={deferredDuty}
        onSubmitClick={handleOnSubmit}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <DeferredDutyGeneralFormElement formRenderProps={formRenderProps} />
          );
        }}
      />
    </div>
  );
});
