import {
  AnimalType,
  RegistrationFeeAdditionalChargeRule,
} from "@app/products/animals/model";
import { RegistrationFeeRulesType } from "@app/products/animals/system-admin/animal-types/[id]/model";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import { checkAdditionalChargeRuleAdjustmentAllowPercent } from "@app/products/animals/system-admin/animal-types/[id]/util";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCPercentInput } from "@components/cc-percent-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IRegistrationFeeAdditionalChargeRulesDialogProps {
  onClose: () => void;
  initialValues: RegistrationFeeAdditionalChargeRule;
  animalTypeFormObject: AnimalType;
  formRenderProps: FormRenderProps;
}

const nameOfAnimalType = nameOfFactory<AnimalType>();
const nameOfKeyValuePacket = nameOfFactory<KeyValuePacket>();
const nameOfRegistrationAdditionalChargeFeeRule =
  nameOfFactory<RegistrationFeeAdditionalChargeRule>();

export const RegistrationFeeAdditionalChargeRulesDialog = observer(
  ({
    onClose,
    initialValues,
    animalTypeFormObject,
    formRenderProps,
  }: IRegistrationFeeAdditionalChargeRulesDialogProps) => {
    const {
      onChange: onChangeAnimalTypeForm,
      valueGetter: valueGetterAnimalTypeForm,
    } = formRenderProps;
    const {
      feeRuleDialogLOVs,
      setFeeRuleTypeEnum,
      responseLoadErrorDialog,
      setupRegistrationFeeRulesDialog,
      isLoadingDialog,
      feeRuleObj,
    } = useAnimalTypeStore();

    // Handle for submit dialog.
    const handleOnSubmit = (event: FormSubmitClickEvent) => {
      const feeRuleList = valueGetterAnimalTypeForm(
        nameOfAnimalType("FeeRulesList")
      );
      const newFeeRule = event.values as RegistrationFeeAdditionalChargeRule;

      const newAdditionalChargeRules =
        animalTypeFormObject.FeeRulesList?.RegistrationFeeAdditionalChargeRules.filter(
          (feeRule: RegistrationFeeAdditionalChargeRule) =>
            feeRule.AdditionalChargeRuleType_ENUM !==
            newFeeRule.AdditionalChargeRuleType_ENUM
        ) ?? [];

      newAdditionalChargeRules.push(
        newFeeRule as RegistrationFeeAdditionalChargeRule
      );

      onChangeAnimalTypeForm(nameOfAnimalType("FeeRulesList"), {
        value: {
          ...feeRuleList,
          RegistrationFeeAdditionalChargeRules: newAdditionalChargeRules,
        },
      });

      setFeeRuleTypeEnum(RegistrationFeeRulesType.Default);
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmitClick={handleOnSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, valid, onSubmit, onChange } = formRenderProps;

          const adjustmentTypeEnum = valueGetter(
            nameOfRegistrationAdditionalChargeFeeRule("AdjustmentType_ENUM")
          );

          const isAdjustmentPercent =
            checkAdditionalChargeRuleAdjustmentAllowPercent(adjustmentTypeEnum);

          // Handle on field change
          const handleOnChangeCombobox = (
            event: ComboBoxChangeEvent,
            fieldName: keyof RegistrationFeeAdditionalChargeRule
          ) => {
            onChange(fieldName, {
              value: event.value?.Key ?? undefined,
            });
          };

          return (
            <CCDialog
              maxWidth="25%"
              height={"auto"}
              titleHeader="Registration Fee Additional Charge Rule"
              onClose={onClose}
              isLoading={isLoadingDialog}
              bodyElement={
                <>
                  {responseLoadErrorDialog ? (
                    <CCLoadFailed
                      responseError={responseLoadErrorDialog}
                      onReload={() =>
                        setupRegistrationFeeRulesDialog(
                          RegistrationFeeRulesType.RegistrationFeeAdditionalChargeRules,
                          feeRuleObj,
                          true
                        )
                      }
                    />
                  ) : (
                    <FormElement className="cc-form">
                      <div className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <CCLabel title="Rule type" isMandatory />
                            <Field
                              name={nameOfRegistrationAdditionalChargeFeeRule(
                                "AdditionalChargeRuleType_ENUM"
                              )}
                              component={CCComboBox}
                              textField={nameOfKeyValuePacket("Value")}
                              dataItemKey={nameOfKeyValuePacket("Key")}
                              data={feeRuleDialogLOVs?.RuleType}
                              onChange={(event: ComboBoxChangeEvent) => {
                                handleOnChangeCombobox(
                                  event,
                                  nameOfRegistrationAdditionalChargeFeeRule(
                                    "AdditionalChargeRuleType_ENUM"
                                  )
                                );
                              }}
                              value={getDropdownValue(
                                valueGetter(
                                  nameOfRegistrationAdditionalChargeFeeRule(
                                    "AdditionalChargeRuleType_ENUM"
                                  )
                                ),
                                feeRuleDialogLOVs?.RuleType ?? [],
                                nameOfKeyValuePacket("Key")
                              )}
                              validator={requiredValidator}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="Adjustment type" isMandatory />
                            <Field
                              name={nameOfRegistrationAdditionalChargeFeeRule(
                                "AdjustmentType_ENUM"
                              )}
                              component={CCComboBox}
                              textField={nameOfKeyValuePacket("Value")}
                              dataItemKey={nameOfKeyValuePacket("Key")}
                              data={feeRuleDialogLOVs?.AdjustmentType1}
                              onChange={(event: ComboBoxChangeEvent) => {
                                handleOnChangeCombobox(
                                  event,
                                  nameOfRegistrationAdditionalChargeFeeRule(
                                    "AdjustmentType_ENUM"
                                  )
                                );
                              }}
                              value={getDropdownValue(
                                valueGetter(
                                  nameOfRegistrationAdditionalChargeFeeRule(
                                    "AdjustmentType_ENUM"
                                  )
                                ),
                                feeRuleDialogLOVs?.AdjustmentType1 ?? [],
                                nameOfKeyValuePacket("Key")
                              )}
                              validator={requiredValidator}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="Amount" isMandatory />
                            <Field
                              name={nameOfRegistrationAdditionalChargeFeeRule(
                                "Amount"
                              )}
                              component={
                                isAdjustmentPercent
                                  ? CCPercentInput
                                  : CCCurrencyInput
                              }
                              validator={requiredValidator}
                            />
                          </div>
                        </div>
                      </div>
                    </FormElement>
                  )}
                </>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    themeColor="primary"
                    disabled={!valid}
                    onClick={onSubmit}
                    className="cc-dialog-button"
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
