import { DTO_AssociatedItem_Title } from "@app/products/property/components/associations/components/title/model";
import { PROPERTY_TITLES_ROUTE } from "@app/products/property/titles/[id]/constant";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_Title>();
export const colContactAssociationTitle: IColumnFields[] = [
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
    linkTo: (dataItem: DTO_AssociatedItem_Title) =>
      `${PROPERTY_TITLES_ROUTE}/${dataItem.Id}`,
  },
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("ReferenceNumber"),
    title: "Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Lot"),
    title: "Lot",
  },
  {
    field: nameOf("PlanType"),
    title: "Plan Type",
  },
  {
    field: nameOf("PlanNumber"),
    title: "Plan Number",
  },
  {
    field: nameOf("County"),
    title: "County",
  },
  {
    field: nameOf("Parish"),
    title: "Parish",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("LandAreaAsString"),
    title: "Land Area",
  },
  {
    field: nameOf("IsAreaSurveyed"),
    title: "Is Area Surveyed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Coverage"),
    title: "Coverage",
  },
  {
    field: nameOf("OwnersName"),
    title: "Owners",
  },
  {
    field: nameOf("OwnersAddress"),
    title: "Address",
  },
  {
    field: nameOf("OwnersLocality"),
    title: "Locality",
  },
  {
    field: nameOf("Id"),
    title: "Title ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
