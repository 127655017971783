import { useCreateDebtRecoveryExemptionDialogStore } from "@app/products/property/assessments/debt-recovery/candidates/components/action-bar/dialogs/create-debt-recovery-exemption/store";
import { nameOfLov } from "@app/products/property/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import {
  requiredValidator,
  validatorDateValueWithMinMax,
} from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";

export const DebtRecoveryExemptionFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { createDebtRecoveryExemptionLOVs } =
      useCreateDebtRecoveryExemptionDialogStore();
    const { valueGetter } = formRenderProps;

    const maxDateValidator = useCallback(
      (value: Date) =>
        validatorDateValueWithMinMax(
          value,
          valueGetter(nameOf("ToApplyFrom")),
          null
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(nameOf("ToApplyFrom"))]
    );

    return (
      <section className="cc-field-group cc-form-cols-1">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="To apply from" isMandatory />
            <Field
              name={nameOf("ToApplyFrom")}
              component={CCDatePicker}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="To" />
            <Field
              name={nameOf("ToApplyTo")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              min={new Date(valueGetter(nameOf("ToApplyFrom")))}
              validator={maxDateValidator}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Type" isMandatory />
            <Field
              name={nameOf("ExemptionTypeId")}
              component={CCSearchComboBox}
              data={convertValueLOVToNumber(
                createDebtRecoveryExemptionLOVs
                  ?.dtoCreate_debtRecoveryExemption_LOVs?.Type ?? []
              )}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              isUseDefaultOnchange
              disabled={options?.isReadOnly}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Reason" isMandatory />
            <Field
              name={nameOf("Reason")}
              component={CCTextArea}
              placeholder="Reason"
              rows={3}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>
      </section>
    );
  }
);
