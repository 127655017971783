import { useMergeContactStore } from "@app/core/contacts/merge-contact/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
interface IFindDuplicatesButtonProps {
  disabled?: boolean;
}

export const FindDuplicatesButton = observer(
  ({ disabled = false }: IFindDuplicatesButtonProps) => {
    const { findDuplicateContact, isLoadingFindDuplicateContact } =
      useMergeContactStore();
    return (
      <CCNavButton
        title="Find duplicates"
        onClick={() => {
          findDuplicateContact();
        }}
        disabled={disabled || isLoadingFindDuplicateContact}
        isLoading={isLoadingFindDuplicateContact}
      />
    );
  }
);
