import { ECustomColNameProperty } from "@app/products/property/config";
import {
  colAssociatedParcels,
  colAssociatedTitles,
} from "@app/products/property/titles/[id]/components/child-screens/general/config";
import {
  DTO_Parcel,
  DTO_Title,
  DTO_TitleAssociation,
} from "@app/products/property/titles/[id]/model";
import { useTitleStore } from "@app/products/property/titles/[id]/store";
import { isFieldVisible } from "@app/products/property/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { CommunityProperty, Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface Props {
  formRenderProps: FormRenderProps;
}

export const PropertyTitleFormElement = observer(
  ({ formRenderProps }: Props) => {
    const { valueGetter } = formRenderProps;
    const { title } = useTitleStore();
    const { isLLS } = CommunityProperty.getFlagOfStates();

    const nameOfAssociatedParcels = nameOfFactory<DTO_Parcel>();
    const nameOf = nameOfFactory<DTO_Title>();
    const nameOfAssociatedTitles = nameOfFactory<DTO_TitleAssociation>();
    const submitForm = (event: React.SyntheticEvent<any>) => {
      formRenderProps.onSubmit(event);
    };

    //Get labels
    const [
      titleLabel,
      titleLowercaseLabel,
      titleIDLabel,
      legalDescriptionLabel,
      assessmentGroupLabel,
      categoryLabel,
      titleSystemLabel,
      titleStatusLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Title,
      ECustomColNameProperty.TitleLowercase,
      ECustomColNameProperty.Title_ID,
      ECustomColNameProperty.Legal_Description,
      ECustomColNameProperty.AssessmentGroup,
      ECustomColNameProperty.Category,
      ECustomColNameProperty.Title_System,
      ECustomColNameProperty.Title_Status,
    ]);

    return (
      <FormElement className="cc-property-parcel-form-elements">
        {/* Reference by a label in WastewaterActionBar.tsx */}
        <input hidden id="btn-submit" onClick={submitForm} />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">{titleIDLabel}</label>
              <Field
                name={nameOf("Id")}
                placeholder={titleIDLabel}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              {/* Waiting confirm to split phrase or whole phrase */}
              <label className="cc-label">{titleLabel} reference</label>
              <Field
                name={nameOf("ReferenceNumber")}
                placeholder={`${titleLabel} reference`}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">{legalDescriptionLabel}</label>
              <Field
                name={nameOf("LegalDescription")}
                placeholder={legalDescriptionLabel}
                component={CCInput}
                readOnly
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Owners</label>
              <Field
                name={nameOf("TitleOwnersNameAndAddress")}
                placeholder="Owners"
                rows={4}
                component={CCTextArea}
                readOnly
              />
            </div>
            <div className="cc-field cc-col-span-2">
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">Plan type</label>
                  <Field
                    name={nameOf("PlanType")}
                    placeholder={"Plan type"}
                    component={CCInput}
                    readOnly
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Plan number</label>
                  <Field
                    name={nameOf("PlanNumber")}
                    placeholder={"Plan number"}
                    component={CCInput}
                    readOnly
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Township</label>
                  <Field
                    name={nameOf("Township")}
                    placeholder={"Township"}
                    component={CCInput}
                    readOnly
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">County</label>
                  <Field
                    name={nameOf("County")}
                    placeholder={"County"}
                    component={CCInput}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
          {isLLS && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">State</label>
                <Field
                  name={nameOf("State")}
                  placeholder={"State"}
                  component={CCInput}
                  readOnly
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Country</label>
                <Field
                  name={nameOf("Country")}
                  placeholder={"Country"}
                  component={CCInput}
                  readOnly
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">LGA</label>
                <Field
                  name={nameOf("LGA")}
                  placeholder={"LGA"}
                  component={CCInput}
                  readOnly
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">{assessmentGroupLabel}</label>
                <Field
                  name={nameOf("Assessment_Group")}
                  placeholder={assessmentGroupLabel}
                  component={CCInput}
                  readOnly
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">{categoryLabel}</label>
                <Field
                  name={nameOf("Differential_Category")}
                  placeholder={categoryLabel}
                  component={CCInput}
                  readOnly
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Land type</label>
                <Field
                  name={nameOf("Land_Type")}
                  placeholder={"Land type"}
                  component={CCInput}
                  readOnly
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Dealing number</label>
                <Field
                  name={nameOf("Dealing_Number")}
                  placeholder={"Dealing number"}
                  component={CCInput}
                  readOnly
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Book number</label>
                <Field
                  name={nameOf("Book_Number")}
                  placeholder={"Book number"}
                  component={CCInput}
                  readOnly
                />
              </div>
            </div>
          )}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              {/* Waiting api add label for Sentence case */}
              <label className="cc-label">{titleLabel} type</label>
              <Field
                name={nameOf("Type")}
                placeholder={`${titleLabel} type`}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Lot</label>
              <Field
                name={nameOf("Lot")}
                placeholder={"Lot"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Is part lot</label>
              <Field
                name={nameOf("IsPartOfLot")}
                component={CCSwitch}
                checked={valueGetter(nameOf("IsPartOfLot"))}
                disabled
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Volume</label>
              <Field
                name={nameOf("Volume")}
                placeholder={"Volume"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Folio</label>
              <Field
                name={nameOf("Folio")}
                placeholder={"Folio"}
                component={CCInput}
                readOnly
              />
            </div>
            {isLLS && (
              <div className="cc-field">
                <label className="cc-label">{titleSystemLabel}</label>
                <Field
                  name={nameOf("Title_System")}
                  placeholder={`${titleSystemLabel}`}
                  component={CCInput}
                  readOnly
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">{titleStatusLabel}</label>
              <Field
                name={nameOf("Status")}
                placeholder={`${titleStatusLabel}`}
                component={CCInput}
                readOnly
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Land area</label>
              <Field
                name={nameOf("LandAreaAsString")}
                placeholder={"Land area"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Excluded area</label>
              <Field
                name={nameOf("ExcludedAreaAsString")}
                placeholder={"Excluded area"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Is area surveyed</label>
              <Field
                name={nameOf("IsAreaSurveyed")}
                checked={valueGetter(nameOf("IsAreaSurveyed"))}
                component={CCSwitch}
                disabled
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Division date</label>
              <Field
                name={nameOf("DivisionDate")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
                disabled
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Parish</label>
              <Field
                name={nameOf("Parish")}
                placeholder={"Parish"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Tenure</label>
              <Field
                name={nameOf("Tenure")}
                placeholder={"Tenure"}
                component={CCInput}
                readOnly
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Frontage</label>
              <Field
                name={nameOf("Frontage")}
                placeholder={"Frontage"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Coverage</label>
              <Field
                name={nameOf("Coverage")}
                placeholder={"Coverage"}
                component={CCInput}
                readOnly
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Crown allotment</label>
              <Field
                name={nameOf("CrownAllotment")}
                placeholder={"Crown allotment"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Crown status</label>
              <Field
                name={nameOf("CrownStatus")}
                placeholder={"Crown status"}
                component={CCInput}
                readOnly
              />
            </div>
            {!isLLS && (
              <div className="cc-field">
                <label className="cc-label">Block</label>
                <Field
                  name={nameOf("Block")}
                  placeholder={"Block"}
                  component={CCInput}
                  readOnly
                />
              </div>
            )}
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Further description</label>
              <Field
                name={nameOf("FurtherDescription")}
                placeholder="Further description"
                rows={4}
                component={CCTextArea}
                readOnly
              />
            </div>
            <div className="cc-field cc-col-span-2">
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">P number</label>
                  <Field
                    name={nameOf("Page")}
                    placeholder={"Page number"}
                    component={CCInput}
                    readOnly
                  />
                </div>
                {!isLLS && (
                  <>
                    <div className="cc-field">
                      <label className="cc-label">Portion</label>
                      <Field
                        name={nameOf("Portion")}
                        placeholder={"Portion"}
                        component={CCInput}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Subdivision</label>
                      <Field
                        name={nameOf("Subdivision")}
                        placeholder={"Subdivision"}
                        component={CCInput}
                        readOnly
                      />
                    </div>
                  </>
                )}

                <div className="cc-field">
                  <label className="cc-label">Section</label>
                  <Field
                    name={nameOf("Section")}
                    placeholder={"Section"}
                    component={CCInput}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              {isFieldVisible(
                title?.AssociatedParcel.AssociatedParcelVisibility
              ) && (
                <>
                  <label className="cc-label">Related parcels</label>
                  <CCGrid
                    columnFields={colAssociatedParcels}
                    data={title?.AssociatedParcel?.AssociatedParcel ?? []}
                    primaryField={nameOfAssociatedParcels("Parcel_Id")}
                  />
                </>
              )}
            </div>
            <div className="cc-field">
              {isFieldVisible(
                title?.TitleAssociation?.AssociatedTitlesVisibility
              ) && (
                <>
                  <label className="cc-label">
                    Associated {titleLowercaseLabel}s
                  </label>
                  <CCGrid
                    columnFields={colAssociatedTitles}
                    data={title?.TitleAssociation?.TitleAssociations ?? []}
                    primaryField={nameOfAssociatedTitles("titleId")}
                  />
                </>
              )}
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
