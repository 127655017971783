import { switchOptionsValueNumberString } from "@app/products/animals/system-admin/settings/_id/main/config";
import {
  ISwitchCustomFieldProps,
  LLRenderSwitch,
} from "@app/products/local-laws/system-admin/settings/_id/components/switch/_index";
import { ICommonFormProps } from "@app/products/local-laws/system-admin/settings/_id/main/model";
import { useLocalLawsSettingManageStore } from "@app/products/local-laws/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { SettingDynamicGridFields } from "@components/setting/setting-dynamic-grid-fields/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { isEditing } = useLocalLawsSettingManageStore();

  const eExcludeInactive = ECorporateSettingsField.LLP_ExcludeInactive;
  const eUsePermitEventName = ECorporateSettingsField.LLP_UsePermitEventName;
  const eRaiseFeeOnLodgement = ECorporateSettingsField.LLP_RaiseFeeOnLodgement;
  const eShowPermitEffectiveDates =
    ECorporateSettingsField.LLP_ShowPermitEffectiveDates;
  const eAllowMultipleAddresses =
    ECorporateSettingsField.LLP_AllowMultipleAddresses;
  const eShowRefuseButton = ECorporateSettingsField.LLP_ShowRefuseButton;
  const eDisplayConditionsInGroup =
    ECorporateSettingsField.LLP_Views_Conditions_DisplayConditionsInGroup;
  const eShowApproveButton = ECorporateSettingsField.LLP_ShowApproveButton;

  const switchList: ISwitchCustomFieldProps[] = [
    {
      eSettingsField: eExcludeInactive,
    },
    {
      eSettingsField: eUsePermitEventName,
    },
    {
      eSettingsField: eRaiseFeeOnLodgement,
    },
    {
      eSettingsField: eShowPermitEffectiveDates,
    },
    {
      eSettingsField: eAllowMultipleAddresses,
    },
    {
      eSettingsField: eShowRefuseButton,
    },
    {
      eSettingsField: eDisplayConditionsInGroup,
    },
    {
      eSettingsField: eShowApproveButton,
    },
  ];
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <LLRenderSwitch
          switchList={switchList}
          formRenderProps={formRenderProps}
          optionsValue={switchOptionsValueNumberString}
        />
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <SettingDynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
