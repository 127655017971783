import {
  IPaymentOption,
  IRateNotice,
} from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/components/panels/rate-notice/model";

export const mockPaymentOptions: IPaymentOption[] = [
  {
    Id: 1,
    PaymentOption: "Option 1 - Full Payment",
    Amount: 1014.25,
  },
  {
    Id: 2,
    PaymentOption: "Option 2 - Instalment",
    Amount: 252.25,
    Amount2: 254.0,
    Amount3: 254.0,
    Amount4: 254.0,
    CostOfOption: 0.0,
  },
];

export const mockRateNotice: IRateNotice = {
  Arrears: 0,
  LeviesRaised: 1655.51,
  Rebates: -641.26,
  NettRates: 1014.25,
  PaymentOptions: mockPaymentOptions,
};
