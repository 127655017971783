import { ContactContact } from "@app/products/animals/model";
import { optionInputPickerOfficer } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { ContactRelationshipType } from "@app/products/town-planning/ppr/[id]/model";
import {
  ContactMethodPreferred,
  SiteUser,
} from "@app/products/waste-water/[id]/model";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { IAppealed } from "@common/pages/appeal/_id/appeal-hearing/_id/components/child-screens/general/components/form-element/model";
import {
  AppealHearing,
  AppealHearingUpdateTriggers,
} from "@common/pages/appeal/_id/appeal-hearing/_id/model";
import { useAppealHearingStore } from "@common/pages/appeal/_id/appeal-hearing/_id/store";
import { IAppeal } from "@common/pages/appeal/_id/model";
import { nameOfFactory } from "@common/utils/common";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IAppealHearingFormElementProps {
  formRenderProps: FormRenderProps;
}

export const AppealHearingFormElement = observer(
  ({ formRenderProps }: IAppealHearingFormElementProps) => {
    const isNew = useIsNew();
    const { pushNotification } = useCCAppNotificationStore();
    const { ancestor, lovs, appeal } = useAppealHearingStore();
    const nameOfHearing = nameOfFactory<AppealHearing>();
    const nameOf = nameOfFactory<IAppealed>();
    const { valueGetter, onSubmit, onChange } = formRenderProps;
    const appealValueGetter: IAppeal = isNew
      ? appeal
      : valueGetter(nameOfHearing("Appeal"));

    const valueWitness = useMemo(() => {
      const witnessValue = valueGetter(nameOfHearing("HearingWitness"));
      let witnessContact = witnessValue?.Contact?.DisplayName ?? "";
      if (
        witnessValue?.Contact?.PreferredMethod_ENUM !==
        ContactMethodPreferred.None
      ) {
        witnessContact = witnessValue?.Contact?._PreferredMethod_Formatted;
      } else {
        witnessContact = "No Preferred Contact";
      }
      return {
        witnessContact: witnessContact,
      };
    }, [nameOfHearing, valueGetter]);
    const valueCouncil = useMemo(() => {
      const councilValue = valueGetter(nameOfHearing("CouncilRepresentation"));
      let councilContact = councilValue?.Contact?.DisplayName ?? "";
      let councilAddress = "";
      if (
        councilValue?.Contact?.PreferredMethod_ENUM !==
        ContactMethodPreferred.None
      ) {
        councilContact = councilValue?.Contact?._PreferredMethod_Formatted;
      } else {
        councilContact = "No Preferred Contact";
      }
      if (councilValue?.Contact?.PostalAddress) {
        councilAddress =
          councilValue?.Contact.PostalAddress?.Formatted_SingleLine;
      }
      return {
        witnessContact: councilContact,
        councilAddress: councilAddress,
      };
    }, [valueGetter, nameOfHearing]);

    const onPickOfficer = (data: SiteUser) => {
      onChange("HearingRepresentationOfficer.DisplayName", {
        value: data?.DisplayName ?? null,
      });
      onChange(nameOfHearing("HearingRepresentationOfficer_ID"), {
        value: data?.Contact_ID ?? null,
      });
    };

    const handleOnChangeWitness = (event: ComboBoxChangeEvent) => {
      const value = event.value;
      if (!value) {
        onChange("HearingWitness_RID", { value: 0 });
        onChange("_SaveTriggers", {
          value: [AppealHearingUpdateTriggers.UpdateHearingWitness],
        });
        onChange("HearingWitness", {
          value: null,
        });
      } else {
        const currentApplication = valueGetter("HearingWitness");
        onChange("HearingWitness_RID", { value: null });
        onChange("_SaveTriggers", {
          value: [AppealHearingUpdateTriggers.UpdateHearingWitness],
        });
        onChange("HearingWitness", {
          value: {
            ContactRelationshipType_ENUM:
              currentApplication?.ContactRelationshipType_ENUM ??
              ContactRelationshipType.Applicant,
            Contact: value,
          },
        });
      }
    };
    const handleOnChangeCouncil = (event: ComboBoxChangeEvent) => {
      const value = event.value;
      if (!value) {
        onChange("CouncilRepresentation_RID", { value: 0 });
        onChange("_SaveTriggers", {
          value: [AppealHearingUpdateTriggers.UpdateCouncilRepresentation],
        });
        onChange("CouncilRepresentation", {
          value: null,
        });
      } else {
        const currentApplication = valueGetter("CouncilRepresentation");
        onChange("_SaveTriggers", {
          value: [AppealHearingUpdateTriggers.UpdateCouncilRepresentation],
        });
        onChange("CouncilRepresentation_RID", { value: null });
        onChange("CouncilRepresentation", {
          value: {
            ContactRelationshipType_ENUM:
              currentApplication?.ContactRelationshipType_ENUM ??
              ContactRelationshipType.Applicant,
            Contact: value,
          },
        });
      }
    };
    const handleTextProduce = (value: ContactContact) => {
      if (value?.Organisation) {
        return value?.DisplayName + "  -  " + value?.Organisation;
      } else {
        return value?.DisplayName;
      }
    };
    return (
      <FormElement onSubmit={onSubmit}>
        <div className="cc-form">
          {ancestor && (
            <>
              <section className="cc-field-group">
                {ancestor?.lblParent1 !== "" && (
                  <div className="cc-form-cols-3">
                    {ancestor?.litParent1 && (
                      <CCValueField
                        label={ancestor.lblParent1}
                        value={sanitizeHtml(ancestor.litParent1)}
                      />
                    )}
                    {ancestor?.litParent2 && (
                      <CCValueField
                        label={ancestor.lblParent2}
                        value={sanitizeHtml(ancestor.litParent2)}
                      />
                    )}
                    {ancestor?.litParent3 && (
                      <CCValueField
                        label={ancestor.lblParent3}
                        value={sanitizeHtml(ancestor.litParent3)}
                      />
                    )}
                    <CCValueField
                      name={nameOf("AppealNo")}
                      label="Appeal number"
                      value={appealValueGetter?.AppealNo}
                    />
                    <CCValueField
                      name={nameOf("DateAppealed")}
                      label="Date appealed"
                      value={appealValueGetter?.DateAppealed}
                      format={DATE_FORMAT.DATE}
                    />
                  </div>
                )}
              </section>
              <hr className="cc-divider" />
            </>
          )}
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Hearing type</label>
                <Field
                  name={nameOfHearing("HearingType_KWD")}
                  component={CCSearchComboBox}
                  data={lovs?.HearingType ?? []}
                  textField="Value"
                  dataItemKey="Key"
                  isUseDefaultOnchange
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Hearing duration</label>
                <Field
                  name={nameOfHearing("HearingDuration")}
                  component={CCDropDownList} // TODO: Need to confirm before change to CCSearchComboBox
                  data={lovs?.HearingDuration ?? []}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Hearing date & time</label>
                <Field
                  name={nameOfHearing("HearingDate")}
                  format={DATETIME_FORMAT.DATETIME_CONTROL}
                  component={CCDateTimePicker}
                />
              </div>

              <div className="cc-field">
                <label className="cc-label">Officer</label>
                <Field
                  name={"HearingRepresentationOfficer.DisplayName"}
                  component={InputPickerSearch}
                  placeholder={"Officer"}
                  onChange={onPickOfficer}
                  options={optionInputPickerOfficer}
                  onError={(error: any) => {
                    pushNotification({
                      title: error,
                      type: "error",
                      autoClose: false,
                    });
                  }}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Witness</label>
                <Field
                  name={"HearingWitness.Contact"}
                  component={ContactPicker}
                  onError={(error: any) => {
                    pushNotification({
                      type: "error",
                      title: "Pick Contact Errors",
                      description: error,
                      autoClose: false,
                    });
                  }}
                  placeholder={"Witness"}
                  onChange={handleOnChangeWitness}
                  textProduce={handleTextProduce}
                />
              </div>
              <CCValueField
                name={"HearingWitness"}
                label="Contact"
                value={valueWitness.witnessContact}
              />
              <div className="cc-field">
                <label className="cc-label">Council representation</label>
                <Field
                  name={"CouncilRepresentation.Contact"}
                  component={ContactPicker}
                  onError={(error: any) => {
                    pushNotification({
                      type: "error",
                      title: "Pick Contact Errors",
                      description: error,
                      autoClose: false,
                    });
                  }}
                  placeholder={"Council Representation"}
                  onChange={handleOnChangeCouncil}
                  textProduce={handleTextProduce}
                />
              </div>
              <CCValueField
                label="Postal address"
                value={valueCouncil.councilAddress}
              />
              <CCValueField
                label="Contact"
                value={valueCouncil.witnessContact}
              />
            </div>
          </section>
        </div>
      </FormElement>
    );
  }
);
