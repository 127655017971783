import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class LandTaxExemptionButtonStore {
  private _isShowDialog: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isShowDialog() {
    return this._isShowDialog;
  }
  setIsShowDialog = (isShow: boolean) => {
    runInAction(() => {
      this._isShowDialog = isShow;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._isShowDialog = false;
    });
  };
}

const landTaxExemptionButtonStoreContext = createContext(
  new LandTaxExemptionButtonStore()
);

export const useLandTaxExemptionButtonStore = () => {
  return useContext(landTaxExemptionButtonStoreContext);
};
