import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  UNRESOLVED_EVENTS_BY_ORG_STRUCTURE_URL,
  UNRESOLVED_EVENTS_BY_STATUS_URL,
} from "@common/pages/home/components/crms/constant";
import { CRMSEventClosedChart } from "@common/pages/home/components/crms/insights/components/closed-in-time-chart/_index";
import { CrmsPieChart } from "@common/pages/home/components/crms/insights/components/pie-chart/_index";
import { useCrmsDashboardStore } from "@common/pages/home/components/crms/store";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const CrmsInsights = observer(() => {
  const { settings } = useCommonCoreStore();

  const { crmsEventDashboard, isLoadingCrmsEventDashboard } =
    useCrmsDashboardStore();

  const labelOrg1 = getStringValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_LabelOrg1]
  );

  return (
    <>
      {isLoadingCrmsEventDashboard ? (
        <Loading isLoading />
      ) : (
        <>
          <CrmsPieChart
            type="column"
            tooltip={{ visible: true }}
            data={crmsEventDashboard?.Insights?.UnresolvedByStatus}
            name="Unresolved by Status"
            groupName="EVENTS"
            queryDateTime={crmsEventDashboard?.QueryDateTime}
            route={UNRESOLVED_EVENTS_BY_STATUS_URL}
          />

          <CrmsPieChart
            type="column"
            tooltip={{ visible: true }}
            data={crmsEventDashboard?.Insights?.UnresolvedByOrg1}
            name={`Unresolved by ${labelOrg1 ?? "Org 1"}`}
            groupName="EVENTS"
            queryDateTime={crmsEventDashboard?.QueryDateTime}
            route={UNRESOLVED_EVENTS_BY_ORG_STRUCTURE_URL}
          />

          <CRMSEventClosedChart />
        </>
      )}
    </>
  );
});
