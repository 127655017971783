import { history } from "@/AppRoutes";
import { putAmendPermit } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/amend-permit/api";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import { Application } from "@app/products/town-planning/ppr/[id]/model";
import { PPRStoreInstance } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AmendPermitButtonStore {
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  putAmendPermit = async (application: Application) => {
    this.setIsLoading(true);
    const response = await putAmendPermit(application);
    PPRStoreInstance.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      history.replace(`${TOWN_PLANNING_PPR_ROUTE}/${response.data?.ID}`, {
        notification: [
          {
            title: "Application Amendment successfully",
            type: "success",
          },
        ],
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Application amendment failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsLoading(false);
  };
}

export const amendPermitButtonStore = new AmendPermitButtonStore();
const amendPermitButtonContext = createContext(amendPermitButtonStore);
export const useAmendPermitButtonStore = () => {
  return useContext(amendPermitButtonContext);
};
