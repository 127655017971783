import { ECertificateRequestFormElement } from "@app/products/property/certificates/e-certificate-requests/[id]/components/child-screens/general/components/form-element/_index";
import { useECertificateRequestStore } from "@app/products/property/certificates/e-certificate-requests/[id]/store";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";

export const ECertificateRequestForm = observer((): ReactElement => {
  const { eCertificateRequest } = useECertificateRequestStore();
  return (
    <div className="cc-form">
      <Form
        initialValues={eCertificateRequest}
        render={(formRenderProps: FormRenderProps) => (
          <ECertificateRequestFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
