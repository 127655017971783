import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { ICommonFormProps } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-application/_index";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Field } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const CustomLabelForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { isEditing } = useSettingPPRManageStore();
    const { getConfigDataField } = useSettingPPRStore();

    //Get setting fields
    const fieldCertificationsMenuLabel = getConfigDataField(
      ECorporateSettingsField.TP_Menu_Label_Certifications
    );
    const fieldPeggingStatementRecLabel = getConfigDataField(
      ECorporateSettingsField.TP_PeggingStatementRecLabel
    );
    const fieldSubdivisionPlannerLabel = getConfigDataField(
      ECorporateSettingsField.TP_SubdivisionPlannerLabel
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {fieldCertificationsMenuLabel && (
            <div className="cc-field">
              <CCLabel
                title={fieldCertificationsMenuLabel?.Title ?? ""}
                isMandatory={fieldCertificationsMenuLabel.IsMandatory}
              />
              <Field
                name={fieldCertificationsMenuLabel?.FieldName}
                component={CCInput}
                disabled={!isEditing}
                validator={
                  fieldCertificationsMenuLabel.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
          {fieldPeggingStatementRecLabel && (
            <div className="cc-field">
              <CCLabel
                title={fieldPeggingStatementRecLabel?.Title ?? ""}
                isMandatory={fieldPeggingStatementRecLabel.IsMandatory}
              />
              <Field
                name={fieldPeggingStatementRecLabel?.FieldName}
                component={CCInput}
                disabled={!isEditing}
                validator={
                  fieldPeggingStatementRecLabel.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
          {fieldSubdivisionPlannerLabel && (
            <div className="cc-field">
              <CCLabel
                title={fieldSubdivisionPlannerLabel?.Title ?? ""}
                isMandatory={fieldSubdivisionPlannerLabel.IsMandatory}
              />
              <Field
                name={fieldSubdivisionPlannerLabel?.FieldName}
                component={CCInput}
                disabled={!isEditing}
                validator={
                  fieldSubdivisionPlannerLabel.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
