import { IInterestRateDialogData } from "@app/products/property/schemes/[id]/components/forms/existed/components/dialogs/add-interest-rate/components/dialogs/interest-rate/model";
import { ISchemesInterestRate } from "@app/products/property/schemes/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { getter } from "@progress/kendo-data-query";

export const sortByEffectiveForm = (data: ISchemesInterestRate[]) => {
  return data.sort(
    (a, b) => +new Date(a.EffectiveFrom) - +new Date(b.EffectiveFrom)
  );
};

export const getMaxEffectiveFrom = (data: ISchemesInterestRate[]) => {
  return data.reduce((a, b) => {
    return new Date(a.EffectiveFrom) > new Date(b.EffectiveFrom) ? a : b;
  }).EffectiveFrom;
};

export const handleValidateForm: any = (
  formData: any,
  data: IInterestRateDialogData
) => {
  const effectiveFromGetter: any = getter("EffectiveFrom");
  const effectiveFrom = effectiveFromGetter(formData);

  let errorMessage = {};
  if (!effectiveFrom) {
    errorMessage = {
      VALIDATION_SUMMARY: "Effective From is incorrect.",
    };
  }

  if (data.IsNew && effectiveFrom <= data.MaxEffectiveFrom) {
    errorMessage = {
      ...errorMessage,
      EffectiveFrom: `Effective From must be greater than ${formatDisplayValue(
        data.MaxEffectiveFrom,
        DATE_FORMAT.DATE
      )}`,
    };
  } else {
    if (
      data.InterestRateData.find(
        (el: ISchemesInterestRate) =>
          el.EffectiveFrom?.toString() === effectiveFrom?.toString() &&
          el.EffectiveFrom?.toString() !==
            data.InterestRate?.EffectiveFrom.toString()
      )
    ) {
      errorMessage = {
        ...errorMessage,
        EffectiveFrom: `Effective From is duplicated`,
      };
    }
    if (effectiveFrom <= data.EffectiveFromInUse) {
      errorMessage = {
        ...errorMessage,
        EffectiveFrom: `Effective From must be greater than ${formatDisplayValue(
          data.EffectiveFromInUse,
          DATE_FORMAT.DATE
        )}`,
      };
    }
  }

  return errorMessage;
};
