import { TabTable } from "@app/core/tab-table/_index";
import {
  AnimalType,
  NonRegistrationFeeRule,
  RegistrationFeeAdditionalChargeRule,
  RegistrationFeeRule,
} from "@app/products/animals/model";
import { TestFeesDialog } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/dialogs/test-fee/_index";
import { FeeCalculation } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/_index";
import { NonRegistrationFeeRulesDialog } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/components/dialogs/non-registration-fee-rules-dialog/_index";
import { RegistrationFeeAdditionalChargeRulesDialog } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/components/dialogs/registration-fee-additional-charge-rules-dialog/_index";
import { RegistrationFeeRulesDialog } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/components/dialogs/registration-fee-rules-dialog/_index";
import { AnimalTypeData } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/form-element/config";
import { Options } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/options-accordion/_index";
import { RegistrationFeeRulesType } from "@app/products/animals/system-admin/animal-types/[id]/model";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCPercentInput } from "@components/cc-percent-input/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  RadioGroupChangeEvent,
  SwitchChangeEvent,
} from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IAnimalTypeFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOfAnimalType = nameOfFactory<AnimalType>();
const nameOfKeyValuePacket = nameOfFactory<KeyValuePacket>();

export const AnimalTypeFormElement = observer(
  ({ formRenderProps }: IAnimalTypeFormElementProps) => {
    const { settings } = useCommonCoreStore();
    const { onChange, valueGetter } = formRenderProps;
    const {
      animalTypeLOVs,
      initValueFeeRuleDialog,
      feeRuleTypeEnum,
      setFeeRuleTypeEnum,
      isShowTestFeeDialog,
      setIsShowTestFeeDialog,
      setTestFees,
    } = useAnimalTypeStore();

    const initFlagLifeTimeTag = valueGetter(
      nameOfAnimalType("Flag_LifeTimeTag")
    );
    const [isShowRenewalPeriod, setShowRenewalPeriod] =
      useState(initFlagLifeTimeTag);

    const initFlagDiscount = valueGetter(nameOfAnimalType("Flag_Discount"));
    const [isShowCaptionDiscount, setIsShowCaptionDiscount] =
      useState(initFlagDiscount);

    const initAnimalTypeValue = valueGetter(
      nameOfAnimalType("Flag_AnimalType")
    );
    const [isShowCatOption, setIsShowCatOption] = useState(initAnimalTypeValue);

    const [feeCalculationLabel, setFeeCalculationLabel] =
      useState("Deductions");
    const animalTypeFormObj = valueGetter("");
    const isUseOOFeeCalculator = getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_UseOpenOfficeFeeCalculator]
    );
    const isShowSpecialDiscount = getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_EnableDiscount]
    );
    const discountLabel = getStringValueSetting(
      settings[ECorporateSettingsField.Animals_Discount_Name]
    );

    // field on change handler
    const handleOnChangeAnimalType = (event: RadioGroupChangeEvent) => {
      onChange(nameOfAnimalType("Flag_AnimalType"), { value: event.value });
      setIsShowCatOption(event.value);
    };

    const handleOnChangeFeeCalculationType = (event: ComboBoxChangeEvent) => {
      onChange(nameOfAnimalType("FeeCalculationType_ID"), {
        value: event?.target?.value?.Key,
      });
      setFeeCalculationLabel(event?.target?.value.Value);
    };

    const handleOnChangeRenewalPeriod = (event: ComboBoxChangeEvent) => {
      onChange(nameOfAnimalType("RenewalPeriod_ID"), {
        value: event?.target?.value?.Key,
      });
    };

    const handleOnChangePenalityFeeType = (event: ComboBoxChangeEvent) => {
      onChange(nameOfAnimalType("PenalityFeeType_ID"), {
        value: event?.target?.value?.Key,
      });
    };

    return (
      <>
        {feeRuleTypeEnum === RegistrationFeeRulesType.RegistrationFeeRules && (
          <RegistrationFeeRulesDialog
            initialValues={initValueFeeRuleDialog as RegistrationFeeRule}
            onClose={() => {
              setFeeRuleTypeEnum(RegistrationFeeRulesType.Default);
            }}
            animalTypeFormObject={animalTypeFormObj}
            formRenderProps={formRenderProps}
          />
        )}
        {feeRuleTypeEnum ===
          RegistrationFeeRulesType.RegistrationFeeAdditionalChargeRules && (
          <RegistrationFeeAdditionalChargeRulesDialog
            initialValues={
              initValueFeeRuleDialog as RegistrationFeeAdditionalChargeRule
            }
            onClose={() => {
              setFeeRuleTypeEnum(RegistrationFeeRulesType.Default);
            }}
            animalTypeFormObject={animalTypeFormObj}
            formRenderProps={formRenderProps}
          />
        )}
        {feeRuleTypeEnum ===
          RegistrationFeeRulesType.NonRegistrationFeeRules && (
          <NonRegistrationFeeRulesDialog
            initialValues={initValueFeeRuleDialog as NonRegistrationFeeRule}
            onClose={() => {
              setFeeRuleTypeEnum(RegistrationFeeRulesType.Default);
            }}
            animalTypeFormObject={animalTypeFormObj}
            formRenderProps={formRenderProps}
          />
        )}
        {isShowTestFeeDialog && (
          <TestFeesDialog
            onClose={() => {
              setIsShowTestFeeDialog(false);
              setTestFees(undefined);
            }}
            animalTypeFormObject={animalTypeFormObj}
          />
        )}
        <FormElement>
          <div className="cc-field-group">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Name" isMandatory />
                <Field
                  name={nameOfAnimalType("Name")}
                  placeholder="Name"
                  component={CCInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Description" />
                <Field
                  name={nameOfAnimalType("Description")}
                  placeholder="Description"
                  component={CCTextArea}
                  rows={4}
                />
              </div>
            </div>
            {!isUseOOFeeCalculator && (
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Fee calculation type" isMandatory />
                  <Field
                    name={nameOfAnimalType("FeeCalculationType_ID")}
                    component={CCComboBox}
                    textField={nameOfKeyValuePacket("Value")}
                    dataItemKey={nameOfKeyValuePacket("Key")}
                    data={animalTypeLOVs?.FeeCalculationType}
                    onChange={handleOnChangeFeeCalculationType}
                    value={getDropdownValue(
                      valueGetter(nameOfAnimalType("FeeCalculationType_ID")),
                      animalTypeLOVs?.FeeCalculationType ?? [],
                      nameOfKeyValuePacket("Key")
                    )}
                    validator={requiredValidator}
                  />
                </div>
              </div>
            )}
          </div>
          <hr className="cc-divider" />
          <div className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Max number" isMandatory />
                <Field
                  name={nameOfAnimalType("MaxNoOfAnimals")}
                  component={CCNumericTextBox}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Max number (large)" isMandatory />
                <Field
                  name={nameOfAnimalType("MaxNoOfAnimals_Large")}
                  component={CCNumericTextBox}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Minimum amount" isMandatory />
                <Field
                  name={nameOfAnimalType("Fee_MinAmount")}
                  component={CCCurrencyInput}
                  validator={requiredValidator}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Health care card discount" isMandatory />
                <Field
                  name={nameOfAnimalType("Fee_HealthCareCardDiscount")}
                  component={CCPercentInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Life time tag?" isMandatory />
                <Field
                  name={nameOfAnimalType("Flag_LifeTimeTag")}
                  component={CCSwitch}
                  validator={requiredValidator}
                  checked={valueGetter(nameOfAnimalType("Flag_LifeTimeTag"))}
                  onChange={(event: SwitchChangeEvent) => {
                    onChange(nameOfAnimalType("Flag_LifeTimeTag"), {
                      value: event.value,
                    });
                    setShowRenewalPeriod(event.value);
                  }}
                />
              </div>
              {!isShowRenewalPeriod && (
                <div className="cc-field">
                  <CCLabel title="Renewal period" isMandatory />
                  <Field
                    name={nameOfAnimalType("RenewalPeriod_ID")}
                    textField={nameOfKeyValuePacket("Value")}
                    dataItemKey={nameOfKeyValuePacket("Key")}
                    component={CCComboBox}
                    data={animalTypeLOVs?.RenewalPeriod}
                    validator={requiredValidator}
                    onChange={handleOnChangeRenewalPeriod}
                    value={getDropdownValue(
                      valueGetter(nameOfAnimalType("RenewalPeriod_ID")),
                      animalTypeLOVs?.RenewalPeriod ?? [],
                      nameOfKeyValuePacket("Key")
                    )}
                  />
                </div>
              )}
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Transfer fee" isMandatory />
                <Field
                  name={nameOfAnimalType("Fee_TransferAmount")}
                  component={CCCurrencyInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Replacement tag fee" isMandatory />
                <Field
                  name={nameOfAnimalType("Fee_ReplacementTagAmount")}
                  component={CCCurrencyInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Penalty fee type" />
                <Field
                  name={nameOfAnimalType("PenalityFeeType_ID")}
                  component={CCComboBox}
                  textField={nameOfKeyValuePacket("Value")}
                  dataItemKey={nameOfKeyValuePacket("Key")}
                  data={animalTypeLOVs?.PenalityFeeType}
                  onChange={handleOnChangePenalityFeeType}
                  value={getDropdownValue(
                    valueGetter(nameOfAnimalType("PenalityFeeType_ID")),
                    animalTypeLOVs?.PenalityFeeType ?? [],
                    nameOfKeyValuePacket("Key")
                  )}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel
                  title="Enable pro Rata for new registrations?"
                  isMandatory
                />
                <Field
                  name={nameOfAnimalType("Flag_EnableProrata")}
                  component={CCSwitch}
                  checked={valueGetter(nameOfAnimalType("Flag_EnableProrata"))}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Cat/Dog?" isMandatory />
                <Field
                  name={nameOfAnimalType("Flag_AnimalType")}
                  component={CCRadioGroup}
                  layout={"horizontal"}
                  data={AnimalTypeData}
                  validator={requiredValidator}
                  onChange={handleOnChangeAnimalType}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              {isShowSpecialDiscount && (
                <>
                  <div className="cc-field">
                    <CCLabel title={discountLabel ?? "Discount"} />
                    <Field
                      name={nameOfAnimalType("Flag_Discount")}
                      component={CCSwitch}
                      checked={valueGetter(nameOfAnimalType("Flag_Discount"))}
                      onChange={(event: SwitchChangeEvent) => {
                        onChange(nameOfAnimalType("Flag_Discount"), {
                          value: event.value,
                        });
                        setIsShowCaptionDiscount(event.value);
                      }}
                    />
                  </div>
                  {isShowCaptionDiscount && (
                    <div className="cc-field">
                      <CCLabel
                        title={
                          discountLabel ? `${discountLabel} (%)` : "Caption"
                        }
                        isMandatory
                      />
                      <Field
                        name={nameOfAnimalType("Fee_SpecialDiscountPercent")}
                        component={CCPercentInput}
                        validator={requiredValidator}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <br />
          <div className="cc-custom-sub-panel-bar">
            <TabTable
              recordType={RECORDTYPE.Animals_AnimalType}
              initialPanels={[
                {
                  title: "Fee Calculation",
                  isKeepMounted: true,
                  component: (
                    <FeeCalculation
                      animalTypeFormObject={animalTypeFormObj}
                      feeCalculationLabel={feeCalculationLabel}
                      formRenderProps={formRenderProps}
                    />
                  ),
                },
                {
                  title: "Options",
                  isKeepMounted: true,
                  component: (
                    <Options
                      animalTypeFormObject={animalTypeFormObj}
                      formRenderProps={formRenderProps}
                      isShowCatOption={isShowCatOption}
                    />
                  ),
                },
              ]}
            />
          </div>
        </FormElement>
      </>
    );
  }
);
