import { APIResponse } from "@common/apis/model";
import {
  BOOLEAN_FORMAT,
  COMMON_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { ICCGridProps } from "@components/cc-grid/_index";
import { IColumnMenuFilterProps } from "@components/cc-grid/components/grid-column-menus/column-menu-filter/_index";
import { State } from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridColumnMenuProps,
  GridColumnProps,
  GridFilterOperators,
  GridFooterCellProps,
} from "@progress/kendo-react-grid";
import { CancelTokenSource } from "axios";
import { OptionalOptions } from "calculate-size";
import * as H from "history";
import React, { AnchorHTMLAttributes, ComponentType } from "react";

export interface IGridConfig {
  paging: {
    buttonCount: number;
    info: boolean;
    pageSizes: number[];
    previousNext: boolean;
  };
  filterOperators?: GridFilterOperators;
  fontSetting: OptionalOptions;
  size: {
    grid: {
      paddingX: number;
      detailPaddingX: number;
    };
    columns: {
      minWidth: number;
      maxWidth: number;
      paddingX: number;
      minResizable: number;
    };
    components: {
      dateCellWidth: number;
      dateTimeCellWidth: number;
      iconBooleanCellWidth: number;
      iconShareCellWidth: number;
      checkBoxWidth: number;
      filterButtonWidth: number;
    };
  };
}

export interface ICCGridDefaultProps extends ICCGridProps {
  data: [];
  state: State;
  editField: string;
  selectedField: string;
  expandField: string;
  groupField: string;
  disableRowField: string;
  canSelectField: string;
  canNotSelectField: string;
  pageSizes: (number | string)[];
}

export enum SelectTypeEnum {
  PAGE = "PAGE",
  ALL = "ALL",
}
export interface ISelection {
  selectType: SelectTypeEnum;
  selectedRowsIds: any[];
  excludedRowsIds: any[];
  ignoreSelectIds: any[];
}
export interface IEditOptions {
  fields: string[];
  inEditRowIds: any[];
}

// @TODO: Change to IColumnField instead of IColumnFields
export type ColumnLinkTo = (
  dataItem: any
) =>
  | H.LocationDescriptor<H.LocationState>
  | ((
      location: H.Location<H.LocationState>
    ) => H.LocationDescriptor<H.LocationState>)
  | undefined;

export type TLinkTo =
  | H.LocationDescriptor<H.LocationState>
  | ((
      location: H.Location<H.LocationState>
    ) => H.LocationDescriptor<H.LocationState>)
  | undefined;

export interface ICustomColumMenu {
  columnMenuProps: GridColumnMenuProps;
  isLocked?: boolean;
  handelToggleLookColumn: () => void;
}

export interface IListFieldNameCustomEditCell {
  fromField: string;
  toField: string;
}

export interface IExtraConditionCustomEditor {
  listFieldName: IListFieldNameCustomEditCell;
}

export interface IDynamicStyle {
  width: number;
}
export interface ICustomEditCell {
  field?: string;
  value?: any;
  format?:
    | DATE_FORMAT
    | CURRENCY_FORMAT
    | NUMBER_FORMAT
    | BOOLEAN_FORMAT
    | ICON_FORMAT
    | PERCENTAGE_FORMAT
    | COMMON_FORMAT
    | DATETIME_FORMAT
    | DECIMAL_FORMAT;
  dataItem?: any;
  dataIndex?: number;
  onChange?: (event: {
    dataItem: any;
    dataIndex: number;
    syntheticEvent: React.SyntheticEvent<any>;
    field?: string;
    value?: any;
  }) => void;
  extraConditions?: IExtraConditionCustomEditor;
}

export interface ICustomFormat {
  defaultFormat?:
    | DATE_FORMAT
    | CURRENCY_FORMAT
    | NUMBER_FORMAT
    | BOOLEAN_FORMAT
    | ICON_FORMAT
    | PERCENTAGE_FORMAT
    | COMMON_FORMAT
    | DATETIME_FORMAT
    | DECIMAL_FORMAT;
  customFormat?: string;
}

export interface IColumnFields
  extends Omit<
    GridColumnProps,
    "footerCell" | "columnMenu" | "cell" | "format"
  > {
  field: string;
  title: string;
  width?: number | string;
  format?:
    | DATE_FORMAT
    | CURRENCY_FORMAT
    | NUMBER_FORMAT
    | BOOLEAN_FORMAT
    | ICON_FORMAT
    | PERCENTAGE_FORMAT
    | COMMON_FORMAT
    | DATETIME_FORMAT
    | DECIMAL_FORMAT
    | ((dataItem: any) => ICustomFormat)
    | ICustomFormat;
  decimalPlaces?: number;
  lookup?: string;
  type?: string;
  ignoreCase?: boolean;
  primaryKey?: string;
  dataType?: string;
  locked?: boolean;
  footerCell?: React.ReactElement;
  calculateFooterCellValue?: (
    props: GridFooterCellProps,
    gridData: any
  ) => number | string | null;
  cell?: ComponentType<GridCellProps>;
  handleOnClick?: (e: any) => void;
  linkTo?: ColumnLinkTo;
  style?: (value?: any) => React.CSSProperties | undefined;
  disableFieldName?: string;
  disableEditField?: string | ((dataItem: any) => boolean);
  columnMenu?: null | ComponentType<IColumnMenuFilterProps>;
  editCell?: ComponentType<ICustomEditCell>;
  /**
   * Return anchor element in grid column
   * Example href: (dataItem) => "http://34.129.181.226/OpenOfficeRadium/Login.aspx"
   */
  href?: (dataItem: any) => AnchorHTMLAttributes<any> | string | undefined;
  isGrow?: boolean;
  minWidth?: number;
  orderIndex?: number;
  ignoreGlobalFilter?: boolean;
  iconHeaderCell?: React.ReactElement;
  id?: string;
  filterByEnum?: any;
  // Use gridDataState to get a custom style (Ex: get dynamic column width)
  dynamicStyle?: (value: State) => IDynamicStyle;
}

export type TPositionLoad = () => {
  width: number | string;
  gridContent: Element | null | undefined;
  isGridDetail: boolean;
};

export type ODataGreedyLoaderQuery = {
  $select?: string[];
  $flat?: boolean;
  $grouped?: boolean;
};

export type ODataGreedyLoaderResponse = {
  successful: boolean;
  data: any[];
  error?: APIResponse["error"];
};

export type SelectionLoaderEvent = {
  available: boolean;
  peek: {
    type: SelectTypeEnum | undefined;
    count: number;
  };
  process: (
    query?: ODataGreedyLoaderQuery,
    cancelToken?: CancelTokenSource
  ) => Promise<ODataGreedyLoaderResponse>;
};
