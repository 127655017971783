import { mockRegisterTagHistory } from "@app/products/property/registers/[id]/components/child-screens/tag-history/mock";
import { ITagsHistory } from "@app/products/property/registers/[id]/components/child-screens/tag-history/model";
import { sleep } from "@common/utils/common";

export const loadRegisterTagsHistory = async (): Promise<
  ITagsHistory | undefined
> => {
  await sleep(1000);
  return mockRegisterTagHistory;
};
