import { ExistedCRMSKnowledgeBase } from "@app/products/crms/knowledge-base/[id]/components/forms/existed/_index";
import { NewCRMSKnowledgeBase } from "@app/products/crms/knowledge-base/[id]/components/forms/new/_index";
import { useCRMSKnowledgeBaseStore } from "@app/products/crms/knowledge-base/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const ManageCRMSKnowledgeBase = () => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { resetStore, loadCRMSKnowledgeBase } = useCRMSKnowledgeBaseStore();

  useEffectOnce(() => {
    loadCRMSKnowledgeBase(parseInt(id), isNew);
    return () => {
      resetStore();
    };
  });

  if (isNew) {
    return <NewCRMSKnowledgeBase />;
  }
  return <ExistedCRMSKnowledgeBase />;
};

export default observer(ManageCRMSKnowledgeBase);
