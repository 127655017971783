import { colValuations } from "@app/products/property/assessments/master-properties/[id]/components/child-screens/general/components/form-element/config";
import {
  DTO_MasterProperty_Summary,
  Master_Property_Valuation,
} from "@app/products/property/assessments/master-properties/[id]/model";
import { useMasterPropertyStore } from "@app/products/property/assessments/master-properties/[id]/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { TYPE_NOTIFICATION } from "@components/cc-notification/comp-notification/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { ReactElement } from "react";

interface IMasterPropertySummaryProps {
  formRenderProps: FormRenderProps;
}

export function MasterPropertyFormElement({
  formRenderProps,
}: IMasterPropertySummaryProps): ReactElement {
  const nameOf = nameOfFactory<DTO_MasterProperty_Summary>();
  const nameOfValuations = nameOfFactory<Master_Property_Valuation>();
  const getValue = formRenderProps.valueGetter;
  const { setNotifications } = useMasterPropertyStore();
  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }
    if (!formRenderProps.valid) return;
    setNotifications([
      {
        primaryContent: "The form is not modified",
        isAutoClose: true,
        type: TYPE_NOTIFICATION.WARNING,
      },
    ]);
  };

  return (
    <FormElement className="cc-property-assessment-form-body">
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Master property ID</label>
            <Field
              readOnly
              name={nameOf("Master_Property_Id")}
              placeholder={"Master property id"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Master property type</label>
            <Field
              readOnly
              name={nameOf("Master_Property_Type_Name")}
              placeholder={"Master property type"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Master property number</label>
            <Field
              readOnly
              name={nameOf("MP_Assess_Number")}
              placeholder={"Master property number"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Property name</label>
            <Field
              readOnly
              name={nameOf("MP_Property_Name")}
              placeholder={"Property name"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Property address</label>
            <Field
              readOnly
              name={nameOf("MP_Property_Address")}
              placeholder={"Property address"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Locality</label>
            <Field
              readOnly
              name={nameOf("Locality_Name")}
              placeholder={"Locality"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              readOnly
              name={nameOf("MP_Description")}
              placeholder={"Description"}
              component={CCInput}
            />
          </div>

          <div className="cc-field">
            <CCValueField
              label="Effective date"
              value={getValue(nameOf("MP_Effective_Date"))}
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label="Letters sent on"
              value={getValue(nameOf("MP_Letter_Date"))}
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Valuation</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCValueField
                    label="Effective date"
                    value={getValue(nameOf("Valuation_Effective_Date"))}
                    format={DATE_FORMAT.DATE_CONTROL}
                  />
                </div>
                <div className="cc-field">
                  <CCValueField
                    label="Issue date"
                    value={getValue(nameOf("Valuation_Issue_Date"))}
                    format={DATE_FORMAT.DATE_CONTROL}
                  />
                </div>
                <div className="cc-field">
                  <CCValueField
                    label="Date of valuation"
                    value={getValue(nameOf("Date_of_Valuation"))}
                    format={DATE_FORMAT.DATE_CONTROL}
                  />
                </div>
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <div className="cc-form-span-1">
                    <label className="cc-label">Valuations</label>
                    <CCGrid
                      className="cc-anonymous-requestor-grid"
                      data={getValue(nameOf("Valuations"))}
                      columnFields={colValuations}
                      primaryField={nameOfValuations("Id")}
                    />
                  </div>
                </div>
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">
                    Last changed on supplementary
                  </label>
                  <div className="cc-custom-sub-panel-bar">
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <label className="cc-label">Number</label>
                        <Field
                          readOnly
                          name={nameOf("Supplementary_Id")}
                          placeholder={"Number"}
                          component={CCInput}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Name</label>
                        <Field
                          readOnly
                          name={nameOf("Supplementary_Name")}
                          placeholder={"Name"}
                          component={CCInput}
                        />
                      </div>
                      <div className="cc-field">
                        <CCValueField
                          label="Supplementary date"
                          value={getValue(nameOf("Supplementary_Date"))}
                          format={DATE_FORMAT.DATE_CONTROL}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </FormElement>
  );
}
