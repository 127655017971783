import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingApplicationsStreetProtection: IColumnFields[] = [
  {
    field: BuildingFieldMapping.PermitNumber,
    title: "Permit Number",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.Date_Issued,
    title: "Issued",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Date_ReminderSent,
    title: "Reminder Sent",
  },
  {
    field: BuildingFieldMapping.PayingFee,
    title: "Paying Fee",
  },
  {
    field: BuildingFieldMapping.CarryingOutWork,
    title: "Carrying Out Work",
  },
  {
    field: BuildingFieldMapping.WorkProposed,
    title: "Work Proposed",
  },
  {
    field: BuildingFieldMapping.Address,
    title: "Address",
  },
  {
    field: BuildingFieldMapping.Locality,
    title: "Locality",
  },
  {
    field: BuildingFieldMapping.AssessmentNo,
    title: "Assessment Number",
  },
  {
    field: BuildingFieldMapping.BuildingAppNo,
    title: "Building Application",
  },
  {
    field: BuildingFieldMapping.BuildingPermitNo,
    title: "Building Number",
  },
  {
    field: BuildingFieldMapping.Date_BuildingIssued,
    title: "Building Issued",
  },
  {
    field: BuildingFieldMapping.Date_Commencing,
    title: "Commencing",
  },
  {
    field: BuildingFieldMapping.BondAmount,
    title: "Bond",
  },
  {
    field: BuildingFieldMapping.BondReceived,
    title: "Bond Received",
  },
  {
    field: BuildingFieldMapping.BondReturned,
    title: "Bond Returned",
  },
  {
    field: BuildingFieldMapping.Date_FirstInspection,
    title: "First Inspection",
  },
  {
    field: BuildingFieldMapping.Date_LastInspection,
    title: "Last Inspection",
  },
];
