import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import {
  IMapTabProps,
  MapTab,
} from "@components/cc-reference-sidebar/components/Map/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const AssessmentCollectionsMapTab = observer((props: IMapTabProps) => {
  const { lastSelectedRow } = useDetailAssessmentListViewStore();

  const searchAddress = useMemo(() => {
    return lastSelectedRow?.["Property_Address"];
  }, [lastSelectedRow]);

  const propsMapTab = {
    address: searchAddress,
  };

  return <MapTab {...propsMapTab} />;
});
