import {
  IParkingPermits,
  IParkingPermitsRecordsState,
} from "@app/products/parking-permits/model";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";

export const mockParkingPermit: IParkingPermits[] = [
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 48135,
    PermitType_Name: "Planner",
    PermitHolder_DisplayName: "Luna",
    StreetName: "648 Effertz Via",
    Suburb: "Luisa_Goldner58",
    Address_Search: "Suite 762",
    Permit_Status: "Grocery",
    Permit_SubStatus: "ex do",
    IssuedDate: "Sun Nov 14 2021 11:23:04 GMT+0700 (Indochina Time)",
    ExpiryDate: "Sun Mar 21 2021 08:41:06 GMT+0700 (Indochina Time)",
    PermitNumber: 35786,
    RegNo: 17040,
    PermitArea: "Optimization",
    ZoneCode: 45484,
    ZoneName: "Hettingerstad",
    PermitCategory_Name: "Senior Accounts Executive",
    StaffArea: "Reggie Buckridge",
    StaffOrg: "Maurine Welch",
    FeesOutstanding: "168.00",
    PrintStatus: "Land",
    Status: "New Jersey",
    Permit_DisplayStatus: "Books",
    PermitHolder_Email: "Claude.Jacobs@gmail.com",
    ReferenceNumber: 72429,
    PermitHolder: "Cruz Aufderhar",
    CreatedDate: "Sat Jun 20 2020 18:38:57 GMT+0700 (Indochina Time)",
    FeeType: "Producer",
    AmountIssued: "7.00",
    AmountOutstanding: 245,
    CostCode: 2801,
    InvoiceNumber: 79394,
    SecurePay_Reference: 47464,
    AmountPaid: "58631",
    ReceiptNumber: 14650,
    ReceiptDate: "Mon May 11 2020 16:38:57 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Maida Okuneva",
    Method: "Regional",
    SentToFinanceDate: "Sat Jul 04 2020 04:26:30 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 57422,
    Sys_DBRowState: "Intelligent",
    ResidentAreaCode: 6782,
    PermitRenewalDate: "Mon Dec 14 2020 03:56:34 GMT+0700 (Indochina Time)",
    MaximumPermits: 33059,
    NoOfFreePermits: 23023,
    NoOfCost1: 93719,
    Cost1: 40185,
    NoOfCost2: 51575,
    Cost2: 17276,
    NoOfCost3: 65850,
    Cost3: 97159,
    AllowableSuburbs: "Port Velda",
    AreaBoundaries: "Weberport",
    Area: "Cassinside",
    Code: 84875,
    Type: "Tactics",
    RenewalDate: "Fri Feb 28 2020 19:51:25 GMT+0700 (Indochina Time)",
    FullAddress: "Port Ahmed",
    AssessmentNo: 20022,
    Reason: "Architect",
    Sys_CreatedBy: "Carson Swaniawski",
    PermitSubType: "Consultant",
    PropertyType: "Representative",
    Org: "collaborative",
    Street: "92222 Sophie Via",
    RenewalStatus: "B2C",
    LetterSent: "Idaho",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 18547,
    PermitType_Name: "Consultant",
    PermitHolder_DisplayName: "Ray",
    StreetName: "398 Ziemann Turnpike",
    Suburb: "Melba_Will",
    Address_Search: "Suite 587",
    Permit_Status: "backing up",
    Permit_SubStatus: "eiusmod cupidatat",
    IssuedDate: "Wed Nov 24 2021 11:42:36 GMT+0700 (Indochina Time)",
    ExpiryDate: "Mon Oct 25 2021 01:31:18 GMT+0700 (Indochina Time)",
    PermitNumber: 98051,
    RegNo: 23774,
    PermitArea: "Group",
    ZoneCode: 86629,
    ZoneName: "Welchborough",
    PermitCategory_Name: "Corporate Brand Orchestrator",
    StaffArea: "Darren Grant",
    StaffOrg: "Anya Braun",
    FeesOutstanding: "989.00",
    PrintStatus: "payment",
    Status: "Massachusetts",
    Permit_DisplayStatus: "Washington",
    PermitHolder_Email: "Manuela_McCullough@hotmail.com",
    ReferenceNumber: 95722,
    PermitHolder: "Dedrick Treutel",
    CreatedDate: "Wed Jan 29 2020 03:12:00 GMT+0700 (Indochina Time)",
    FeeType: "Executive",
    AmountIssued: "954.00",
    AmountOutstanding: 157,
    CostCode: 93800,
    InvoiceNumber: 5231,
    SecurePay_Reference: 524,
    AmountPaid: "96806",
    ReceiptNumber: 46454,
    ReceiptDate: "Mon Dec 14 2020 05:09:13 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Myriam Altenwerth",
    Method: "Dynamic",
    SentToFinanceDate: "Mon Oct 05 2020 00:15:19 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 3065,
    Sys_DBRowState: "Points",
    ResidentAreaCode: 94205,
    PermitRenewalDate: "Fri Apr 03 2020 16:07:50 GMT+0700 (Indochina Time)",
    MaximumPermits: 3667,
    NoOfFreePermits: 86434,
    NoOfCost1: 73312,
    Cost1: 21321,
    NoOfCost2: 35514,
    Cost2: 23246,
    NoOfCost3: 68448,
    Cost3: 81221,
    AllowableSuburbs: "Port Johathantown",
    AreaBoundaries: "New Jayden",
    Area: "South Lucienne",
    Code: 29197,
    Type: "backing up",
    RenewalDate: "Tue Dec 15 2020 00:29:36 GMT+0700 (Indochina Time)",
    FullAddress: "Port Hillaryview",
    AssessmentNo: 96850,
    Reason: "mint green",
    Sys_CreatedBy: "Caleb Jacobson",
    PermitSubType: "Director",
    PropertyType: "Designer",
    Org: "transmitting",
    Street: "921 Rau Field",
    RenewalStatus: "Auto Loan Account",
    LetterSent: "Swaziland",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 22370,
    PermitType_Name: "Analyst",
    PermitHolder_DisplayName: "Rosalind",
    StreetName: "2249 Rylee Trafficway",
    Suburb: "Letha_Stamm45",
    Address_Search: "Suite 222",
    Permit_Status: "uniform",
    Permit_SubStatus: "sit in Ut esse",
    IssuedDate: "Mon Sep 20 2021 17:49:42 GMT+0700 (Indochina Time)",
    ExpiryDate: "Wed Feb 24 2021 04:21:05 GMT+0700 (Indochina Time)",
    PermitNumber: 89875,
    RegNo: 32257,
    PermitArea: "Group",
    ZoneCode: 10734,
    ZoneName: "Port Frankie",
    PermitCategory_Name: "Future Assurance Orchestrator",
    StaffArea: "Ashley Connelly",
    StaffOrg: "Tabitha Wiegand",
    FeesOutstanding: "409.00",
    PrintStatus: "Avon",
    Status: "content",
    Permit_DisplayStatus: "Bike",
    PermitHolder_Email: "Pearline_Eichmann@yahoo.com",
    ReferenceNumber: 89239,
    PermitHolder: "Miss Garfield Rohan",
    CreatedDate: "Tue Jul 28 2020 04:08:32 GMT+0700 (Indochina Time)",
    FeeType: "Architect",
    AmountIssued: "160.00",
    AmountOutstanding: 60,
    CostCode: 98299,
    InvoiceNumber: 26261,
    SecurePay_Reference: 7429,
    AmountPaid: "42279",
    ReceiptNumber: 67771,
    ReceiptDate: "Thu Jan 09 2020 04:06:17 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Lance Deckow",
    Method: "Internal",
    SentToFinanceDate: "Thu Nov 26 2020 01:09:24 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 98401,
    Sys_DBRowState: "matrix",
    ResidentAreaCode: 91261,
    PermitRenewalDate: "Mon Apr 13 2020 03:24:59 GMT+0700 (Indochina Time)",
    MaximumPermits: 43612,
    NoOfFreePermits: 36000,
    NoOfCost1: 32692,
    Cost1: 3087,
    NoOfCost2: 47058,
    Cost2: 79164,
    NoOfCost3: 34481,
    Cost3: 95376,
    AllowableSuburbs: "South Kailyn",
    AreaBoundaries: "East Demariotown",
    Area: "Port Ryanmouth",
    Code: 83382,
    Type: "Frozen",
    RenewalDate: "Thu Jun 11 2020 01:35:16 GMT+0700 (Indochina Time)",
    FullAddress: "Lake Darronmouth",
    AssessmentNo: 8188,
    Reason: "Cedi",
    Sys_CreatedBy: "Marcelle Daugherty",
    PermitSubType: "Specialist",
    PropertyType: "Specialist",
    Org: "Sausages",
    Street: "187 Feeney Mission",
    RenewalStatus: "Borders",
    LetterSent: "discrete",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 29410,
    PermitType_Name: "Specialist",
    PermitHolder_DisplayName: "Abbey",
    StreetName: "13900 Edwin Loop",
    Suburb: "Norene.Rodriguez",
    Address_Search: "Suite 609",
    Permit_Status: "Soft",
    Permit_SubStatus: "amet voluptate culpa",
    IssuedDate: "Tue May 11 2021 08:33:44 GMT+0700 (Indochina Time)",
    ExpiryDate: "Mon May 31 2021 12:19:07 GMT+0700 (Indochina Time)",
    PermitNumber: 96420,
    RegNo: 99205,
    PermitArea: "Metrics",
    ZoneCode: 71588,
    ZoneName: "Port Norene",
    PermitCategory_Name: "Central Group Liason",
    StaffArea: "Miss Kari Denesik",
    StaffOrg: "Bertram Ratke",
    FeesOutstanding: "571.00",
    PrintStatus: "hack",
    Status: "Executive",
    Permit_DisplayStatus: "Shoes",
    PermitHolder_Email: "Cydney.Terry87@hotmail.com",
    ReferenceNumber: 65768,
    PermitHolder: "Mr. Estrella Ebert",
    CreatedDate: "Tue Jun 02 2020 23:33:57 GMT+0700 (Indochina Time)",
    FeeType: "Coordinator",
    AmountIssued: "27.00",
    AmountOutstanding: 459,
    CostCode: 48076,
    InvoiceNumber: 53790,
    SecurePay_Reference: 93108,
    AmountPaid: "47475",
    ReceiptNumber: 87564,
    ReceiptDate: "Mon May 11 2020 21:33:14 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Ludie Berge",
    Method: "National",
    SentToFinanceDate: "Tue Apr 07 2020 22:14:45 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 13991,
    Sys_DBRowState: "Belgium",
    ResidentAreaCode: 90463,
    PermitRenewalDate: "Thu Dec 10 2020 22:28:17 GMT+0700 (Indochina Time)",
    MaximumPermits: 40972,
    NoOfFreePermits: 10016,
    NoOfCost1: 40387,
    Cost1: 56527,
    NoOfCost2: 38032,
    Cost2: 93787,
    NoOfCost3: 42244,
    Cost3: 79219,
    AllowableSuburbs: "Gerdaport",
    AreaBoundaries: "North Elmer",
    Area: "West Lucio",
    Code: 75943,
    Type: "multi-byte",
    RenewalDate: "Thu Mar 12 2020 08:32:26 GMT+0700 (Indochina Time)",
    FullAddress: "West Anjalifurt",
    AssessmentNo: 14022,
    Reason: "SDD",
    Sys_CreatedBy: "Elisha Treutel",
    PermitSubType: "Planner",
    PropertyType: "Assistant",
    Org: "SMS",
    Street: "27508 Will Parks",
    RenewalStatus: "Soft",
    LetterSent: "Swedish Krona Islands Berkshire",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 88022,
    PermitType_Name: "Manager",
    PermitHolder_DisplayName: "Dariana",
    StreetName: "0024 Schaden Well",
    Suburb: "Flavio.Leannon",
    Address_Search: "Suite 126",
    Permit_Status: "task-force",
    Permit_SubStatus: "adipisicing cupidatat",
    IssuedDate: "Mon Jul 12 2021 08:13:29 GMT+0700 (Indochina Time)",
    ExpiryDate: "Sat Aug 07 2021 08:37:15 GMT+0700 (Indochina Time)",
    PermitNumber: 75435,
    RegNo: 72602,
    PermitArea: "Data",
    ZoneCode: 75110,
    ZoneName: "Eunaland",
    PermitCategory_Name: "Legacy Operations Planner",
    StaffArea: "Mia Torphy",
    StaffOrg: "Neha Howell",
    FeesOutstanding: "332.00",
    PrintStatus: "mobile",
    Status: "Buckinghamshire",
    Permit_DisplayStatus: "holistic",
    PermitHolder_Email: "Rowland.Bogisich@yahoo.com",
    ReferenceNumber: 12416,
    PermitHolder: "Jamey Littel",
    CreatedDate: "Mon May 18 2020 04:54:06 GMT+0700 (Indochina Time)",
    FeeType: "Liason",
    AmountIssued: "693.00",
    AmountOutstanding: 574,
    CostCode: 3488,
    InvoiceNumber: 78680,
    SecurePay_Reference: 54787,
    AmountPaid: "42317",
    ReceiptNumber: 99506,
    ReceiptDate: "Wed Sep 30 2020 19:40:02 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Miss Marianna Rau",
    Method: "International",
    SentToFinanceDate: "Wed Apr 15 2020 14:51:21 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 50431,
    Sys_DBRowState: "migration",
    ResidentAreaCode: 36331,
    PermitRenewalDate: "Tue Mar 31 2020 01:58:39 GMT+0700 (Indochina Time)",
    MaximumPermits: 8492,
    NoOfFreePermits: 68183,
    NoOfCost1: 20072,
    Cost1: 7361,
    NoOfCost2: 87374,
    Cost2: 42782,
    NoOfCost3: 33999,
    Cost3: 58606,
    AllowableSuburbs: "East Ayanaborough",
    AreaBoundaries: "Nikolausberg",
    Area: "South Leanna",
    Code: 6373,
    Type: "application",
    RenewalDate: "Thu Oct 29 2020 20:25:11 GMT+0700 (Indochina Time)",
    FullAddress: "North Mona",
    AssessmentNo: 52018,
    Reason: "definition",
    Sys_CreatedBy: "Jeramy Kshlerin",
    PermitSubType: "Coordinator",
    PropertyType: "Engineer",
    Org: "initiatives",
    Street: "220 Hertha Isle",
    RenewalStatus: "Mouse",
    LetterSent: "Accountability e-commerce",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 46522,
    PermitType_Name: "Director",
    PermitHolder_DisplayName: "Juwan",
    StreetName: "65524 Shields Crossroad",
    Suburb: "Madison.Mueller",
    Address_Search: "Suite 796",
    Permit_Status: "Gorgeous",
    Permit_SubStatus: "sit laboris occaecat commodo",
    IssuedDate: "Sun Apr 04 2021 11:25:51 GMT+0700 (Indochina Time)",
    ExpiryDate: "Thu Dec 31 2020 18:25:01 GMT+0700 (Indochina Time)",
    PermitNumber: 63293,
    RegNo: 1857,
    PermitArea: "Metrics",
    ZoneCode: 70292,
    ZoneName: "Francescabury",
    PermitCategory_Name: "Central Brand Director",
    StaffArea: "Lillian Douglas",
    StaffOrg: "Wilber Herzog",
    FeesOutstanding: "429.00",
    PrintStatus: "Investment Account",
    Status: "neural",
    Permit_DisplayStatus: "experiences",
    PermitHolder_Email: "Ken_Ledner@hotmail.com",
    ReferenceNumber: 67105,
    PermitHolder: "Rosamond Blick",
    CreatedDate: "Tue Dec 08 2020 06:52:46 GMT+0700 (Indochina Time)",
    FeeType: "Orchestrator",
    AmountIssued: "776.00",
    AmountOutstanding: 802,
    CostCode: 84803,
    InvoiceNumber: 72911,
    SecurePay_Reference: 65140,
    AmountPaid: "19159",
    ReceiptNumber: 90463,
    ReceiptDate: "Tue Aug 04 2020 02:17:48 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Dr. Willie Medhurst",
    Method: "Regional",
    SentToFinanceDate: "Sun Jun 14 2020 02:54:42 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 38107,
    Sys_DBRowState: "Coordinator",
    ResidentAreaCode: 83667,
    PermitRenewalDate: "Thu Apr 16 2020 22:51:37 GMT+0700 (Indochina Time)",
    MaximumPermits: 46502,
    NoOfFreePermits: 10470,
    NoOfCost1: 97262,
    Cost1: 43170,
    NoOfCost2: 16656,
    Cost2: 59007,
    NoOfCost3: 57636,
    Cost3: 86943,
    AllowableSuburbs: "Marisafort",
    AreaBoundaries: "Leslyborough",
    Area: "Earnestineton",
    Code: 4713,
    Type: "Home Loan Account",
    RenewalDate: "Fri Oct 23 2020 08:10:24 GMT+0700 (Indochina Time)",
    FullAddress: "Ebertmouth",
    AssessmentNo: 41274,
    Reason: "Fully-configurable",
    Sys_CreatedBy: "Katharina Christiansen DVM",
    PermitSubType: "Strategist",
    PropertyType: "Representative",
    Org: "human-resource",
    Street: "5424 Polly Causeway",
    RenewalStatus: "Lao People's Democratic Republic",
    LetterSent: "Small Concrete",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 94705,
    PermitType_Name: "Administrator",
    PermitHolder_DisplayName: "Josue",
    StreetName: "6254 Bailey Loop",
    Suburb: "Jerome.Jaskolski",
    Address_Search: "Suite 316",
    Permit_Status: "Concrete",
    Permit_SubStatus: "nisi",
    IssuedDate: "Fri Jul 02 2021 12:26:59 GMT+0700 (Indochina Time)",
    ExpiryDate: "Mon Mar 01 2021 05:03:15 GMT+0700 (Indochina Time)",
    PermitNumber: 11500,
    RegNo: 47594,
    PermitArea: "Optimization",
    ZoneCode: 72088,
    ZoneName: "East Jacinthe",
    PermitCategory_Name: "District Integration Associate",
    StaffArea: "Bonnie Huels",
    StaffOrg: "Ada Langosh",
    FeesOutstanding: "541.00",
    PrintStatus: "upward-trending",
    Status: "Dominican Republic",
    Permit_DisplayStatus: "Tools",
    PermitHolder_Email: "Theresa_Collier36@yahoo.com",
    ReferenceNumber: 34800,
    PermitHolder: "Bernice Daniel",
    CreatedDate: "Mon Dec 14 2020 00:00:08 GMT+0700 (Indochina Time)",
    FeeType: "Architect",
    AmountIssued: "42.00",
    AmountOutstanding: 486,
    CostCode: 96101,
    InvoiceNumber: 57679,
    SecurePay_Reference: 37840,
    AmountPaid: "77557",
    ReceiptNumber: 92080,
    ReceiptDate: "Fri Sep 25 2020 21:37:18 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Otto Hyatt",
    Method: "Future",
    SentToFinanceDate: "Fri Nov 20 2020 17:29:21 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 80505,
    Sys_DBRowState: "vortals",
    ResidentAreaCode: 23227,
    PermitRenewalDate: "Thu Aug 27 2020 17:54:41 GMT+0700 (Indochina Time)",
    MaximumPermits: 83770,
    NoOfFreePermits: 64377,
    NoOfCost1: 97351,
    Cost1: 85798,
    NoOfCost2: 67879,
    Cost2: 55109,
    NoOfCost3: 40007,
    Cost3: 32061,
    AllowableSuburbs: "East Diannaburgh",
    AreaBoundaries: "East Karsonmouth",
    Area: "Schadenside",
    Code: 23164,
    Type: "Hat",
    RenewalDate: "Fri May 15 2020 00:36:51 GMT+0700 (Indochina Time)",
    FullAddress: "Uptonborough",
    AssessmentNo: 38405,
    Reason: "capacitor",
    Sys_CreatedBy: "Isabel Schroeder",
    PermitSubType: "Liason",
    PropertyType: "Technician",
    Org: "Egyptian Pound",
    Street: "7466 Pedro Land",
    RenewalStatus: "functionalities",
    LetterSent: "cross-platform IB",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 66392,
    PermitType_Name: "Agent",
    PermitHolder_DisplayName: "Eliza",
    StreetName: "05170 Erdman Stravenue",
    Suburb: "Braden.Krajcik",
    Address_Search: "Apt. 202",
    Permit_Status: "Awesome Rubber Ball",
    Permit_SubStatus: "cillum aliqua Excepteur",
    IssuedDate: "Mon Mar 22 2021 17:31:37 GMT+0700 (Indochina Time)",
    ExpiryDate: "Tue Aug 31 2021 13:56:11 GMT+0700 (Indochina Time)",
    PermitNumber: 98456,
    RegNo: 22233,
    PermitArea: "Markets",
    ZoneCode: 80820,
    ZoneName: "Iketown",
    PermitCategory_Name: "Chief Implementation Administrator",
    StaffArea: "Kassandra Schimmel",
    StaffOrg: "Felton Spencer",
    FeesOutstanding: "730.00",
    PrintStatus: "Corporate",
    Status: "withdrawal",
    Permit_DisplayStatus: "Incredible",
    PermitHolder_Email: "Tianna50@gmail.com",
    ReferenceNumber: 22068,
    PermitHolder: "Betsy Upton",
    CreatedDate: "Fri Jun 26 2020 23:16:32 GMT+0700 (Indochina Time)",
    FeeType: "Technician",
    AmountIssued: "713.00",
    AmountOutstanding: 60,
    CostCode: 21195,
    InvoiceNumber: 8868,
    SecurePay_Reference: 93047,
    AmountPaid: "84616",
    ReceiptNumber: 43541,
    ReceiptDate: "Sat Jul 11 2020 02:33:59 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Mozell Crona",
    Method: "Lead",
    SentToFinanceDate: "Sat Jul 04 2020 10:35:41 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 55168,
    Sys_DBRowState: "Regional",
    ResidentAreaCode: 86510,
    PermitRenewalDate: "Fri Jun 19 2020 11:36:59 GMT+0700 (Indochina Time)",
    MaximumPermits: 36684,
    NoOfFreePermits: 65214,
    NoOfCost1: 77055,
    Cost1: 66076,
    NoOfCost2: 38804,
    Cost2: 20666,
    NoOfCost3: 59963,
    Cost3: 91980,
    AllowableSuburbs: "Lelaland",
    AreaBoundaries: "Lake Dortha",
    Area: "Gleasonfort",
    Code: 70591,
    Type: "RAM",
    RenewalDate: "Mon Jun 08 2020 15:28:17 GMT+0700 (Indochina Time)",
    FullAddress: "Konopelskifort",
    AssessmentNo: 18451,
    Reason: "zero administration",
    Sys_CreatedBy: "Delfina Kulas",
    PermitSubType: "Designer",
    PropertyType: "Administrator",
    Org: "Fantastic",
    Street: "545 Kirlin Freeway",
    RenewalStatus: "Agent",
    LetterSent: "Delaware",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 60252,
    PermitType_Name: "Agent",
    PermitHolder_DisplayName: "Krista",
    StreetName: "6483 Haag Keys",
    Suburb: "Josh_Hand15",
    Address_Search: "Suite 955",
    Permit_Status: "Awesome Soft Chicken",
    Permit_SubStatus: "anim",
    IssuedDate: "Sun Mar 07 2021 11:59:16 GMT+0700 (Indochina Time)",
    ExpiryDate: "Sat Nov 20 2021 18:39:08 GMT+0700 (Indochina Time)",
    PermitNumber: 21234,
    RegNo: 83252,
    PermitArea: "Intranet",
    ZoneCode: 66750,
    ZoneName: "Charleneland",
    PermitCategory_Name: "District Group Planner",
    StaffArea: "Abdiel Abbott",
    StaffOrg: "Miss Irwin Johns",
    FeesOutstanding: "59.00",
    PrintStatus: "Investment Account",
    Status: "Response",
    Permit_DisplayStatus: "Burundi Franc",
    PermitHolder_Email: "Mariam63@gmail.com",
    ReferenceNumber: 4713,
    PermitHolder: "Melany Graham",
    CreatedDate: "Sat Jul 25 2020 02:33:11 GMT+0700 (Indochina Time)",
    FeeType: "Analyst",
    AmountIssued: "870.00",
    AmountOutstanding: 242,
    CostCode: 25703,
    InvoiceNumber: 30856,
    SecurePay_Reference: 77563,
    AmountPaid: "87093",
    ReceiptNumber: 84204,
    ReceiptDate: "Mon Jan 27 2020 11:47:59 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Cora Luettgen",
    Method: "International",
    SentToFinanceDate: "Fri Mar 06 2020 04:19:23 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 37595,
    Sys_DBRowState: "Awesome",
    ResidentAreaCode: 87770,
    PermitRenewalDate: "Sat Oct 03 2020 14:10:02 GMT+0700 (Indochina Time)",
    MaximumPermits: 30908,
    NoOfFreePermits: 28683,
    NoOfCost1: 50123,
    Cost1: 96096,
    NoOfCost2: 98069,
    Cost2: 97315,
    NoOfCost3: 87664,
    Cost3: 59322,
    AllowableSuburbs: "North Darlene",
    AreaBoundaries: "North Brendanmouth",
    Area: "Port Sofia",
    Code: 83192,
    Type: "Fresh",
    RenewalDate: "Sat Jun 13 2020 09:07:11 GMT+0700 (Indochina Time)",
    FullAddress: "Carlishire",
    AssessmentNo: 88173,
    Reason: "Licensed Fresh Chicken",
    Sys_CreatedBy: "Audie Wilderman",
    PermitSubType: "Engineer",
    PropertyType: "Analyst",
    Org: "District",
    Street: "70762 Erdman Ferry",
    RenewalStatus: "user-facing",
    LetterSent: "portals",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 66304,
    PermitType_Name: "Executive",
    PermitHolder_DisplayName: "Duncan",
    StreetName: "6444 Grant Shoal",
    Suburb: "Wilfrid_Moore20",
    Address_Search: "Apt. 726",
    Permit_Status: "Generic Rubber Salad",
    Permit_SubStatus: "eu eiusmod",
    IssuedDate: "Wed Oct 06 2021 16:59:37 GMT+0700 (Indochina Time)",
    ExpiryDate: "Fri Dec 03 2021 07:49:36 GMT+0700 (Indochina Time)",
    PermitNumber: 1005,
    RegNo: 87294,
    PermitArea: "Research",
    ZoneCode: 62433,
    ZoneName: "Krishaven",
    PermitCategory_Name: "Investor Implementation Manager",
    StaffArea: "Valerie Bosco",
    StaffOrg: "Josie Murazik II",
    FeesOutstanding: "404.00",
    PrintStatus: "distributed",
    Status: "green",
    Permit_DisplayStatus: "Tasty Plastic Shoes",
    PermitHolder_Email: "Yazmin1@gmail.com",
    ReferenceNumber: 24323,
    PermitHolder: "Ephraim Wilderman",
    CreatedDate: "Tue Sep 22 2020 01:27:01 GMT+0700 (Indochina Time)",
    FeeType: "Agent",
    AmountIssued: "731.00",
    AmountOutstanding: 699,
    CostCode: 92368,
    InvoiceNumber: 37558,
    SecurePay_Reference: 49753,
    AmountPaid: "7966",
    ReceiptNumber: 8641,
    ReceiptDate: "Sat Mar 21 2020 00:49:10 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Alicia Dicki IV",
    Method: "Regional",
    SentToFinanceDate: "Fri Aug 21 2020 21:47:32 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 89741,
    Sys_DBRowState: "Brazil",
    ResidentAreaCode: 91868,
    PermitRenewalDate: "Tue Oct 27 2020 12:09:34 GMT+0700 (Indochina Time)",
    MaximumPermits: 66642,
    NoOfFreePermits: 25759,
    NoOfCost1: 34423,
    Cost1: 14970,
    NoOfCost2: 25992,
    Cost2: 65815,
    NoOfCost3: 19562,
    Cost3: 92977,
    AllowableSuburbs: "Donnafort",
    AreaBoundaries: "Amanishire",
    Area: "Schowalterchester",
    Code: 8911,
    Type: "Legacy",
    RenewalDate: "Thu Nov 19 2020 21:16:46 GMT+0700 (Indochina Time)",
    FullAddress: "Einofort",
    AssessmentNo: 29098,
    Reason: "XML",
    Sys_CreatedBy: "Trent Ullrich",
    PermitSubType: "Administrator",
    PropertyType: "Coordinator",
    Org: "internet solution",
    Street: "279 Winnifred Estate",
    RenewalStatus: "groupware",
    LetterSent: "embrace Fantastic Soft Chips Syrian Pound",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 60779,
    PermitType_Name: "Planner",
    PermitHolder_DisplayName: "Retta",
    StreetName: "8343 Kunze Key",
    Suburb: "Gennaro52",
    Address_Search: "Apt. 471",
    Permit_Status: "Toys",
    Permit_SubStatus: "Duis amet est culpa",
    IssuedDate: "Mon Aug 23 2021 12:05:23 GMT+0700 (Indochina Time)",
    ExpiryDate: "Wed Sep 08 2021 23:11:04 GMT+0700 (Indochina Time)",
    PermitNumber: 72460,
    RegNo: 27498,
    PermitArea: "Tactics",
    ZoneCode: 25601,
    ZoneName: "Kilbackmouth",
    PermitCategory_Name: "Human Program Orchestrator",
    StaffArea: "Ms. Aileen Flatley",
    StaffOrg: "Tyrique Green",
    FeesOutstanding: "668.00",
    PrintStatus: "Buckinghamshire",
    Status: "compressing",
    Permit_DisplayStatus: "Strategist",
    PermitHolder_Email: "Presley_Wilderman38@hotmail.com",
    ReferenceNumber: 84120,
    PermitHolder: "Dr. Yvette Kuphal",
    CreatedDate: "Sun Mar 08 2020 15:07:40 GMT+0700 (Indochina Time)",
    FeeType: "Developer",
    AmountIssued: "290.00",
    AmountOutstanding: 696,
    CostCode: 66298,
    InvoiceNumber: 89222,
    SecurePay_Reference: 29114,
    AmountPaid: "36511",
    ReceiptNumber: 6610,
    ReceiptDate: "Sun Mar 22 2020 21:48:22 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Twila Moen",
    Method: "Product",
    SentToFinanceDate: "Thu Dec 24 2020 07:30:19 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 7414,
    Sys_DBRowState: "Maryland",
    ResidentAreaCode: 45744,
    PermitRenewalDate: "Tue Oct 20 2020 22:04:58 GMT+0700 (Indochina Time)",
    MaximumPermits: 82627,
    NoOfFreePermits: 17052,
    NoOfCost1: 76604,
    Cost1: 71097,
    NoOfCost2: 19425,
    Cost2: 79596,
    NoOfCost3: 1835,
    Cost3: 67288,
    AllowableSuburbs: "West Janick",
    AreaBoundaries: "Port Bellefurt",
    Area: "Framichester",
    Code: 64123,
    Type: "architectures",
    RenewalDate: "Fri Oct 23 2020 13:55:21 GMT+0700 (Indochina Time)",
    FullAddress: "Ikechester",
    AssessmentNo: 61961,
    Reason: "input",
    Sys_CreatedBy: "Alessandro Lindgren IV",
    PermitSubType: "Supervisor",
    PropertyType: "Orchestrator",
    Org: "empower",
    Street: "36014 Oberbrunner Landing",
    RenewalStatus: "Cambridgeshire",
    LetterSent: "firewall task-force",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 95944,
    PermitType_Name: "Specialist",
    PermitHolder_DisplayName: "Octavia",
    StreetName: "84777 King Inlet",
    Suburb: "Pascale.Rippin",
    Address_Search: "Apt. 515",
    Permit_Status: "calculating",
    Permit_SubStatus: "elit nulla sed",
    IssuedDate: "Fri Nov 26 2021 11:48:24 GMT+0700 (Indochina Time)",
    ExpiryDate: "Thu Apr 01 2021 17:59:57 GMT+0700 (Indochina Time)",
    PermitNumber: 73892,
    RegNo: 34689,
    PermitArea: "Data",
    ZoneCode: 22146,
    ZoneName: "Port Abnerland",
    PermitCategory_Name: "Central Data Agent",
    StaffArea: "Elinor Strosin",
    StaffOrg: "Alexzander Daniel",
    FeesOutstanding: "692.00",
    PrintStatus: "Intelligent Soft Ball",
    Status: "Coordinator",
    Permit_DisplayStatus: "Savings Account",
    PermitHolder_Email: "Christa19@hotmail.com",
    ReferenceNumber: 57111,
    PermitHolder: "Raymundo Beatty IV",
    CreatedDate: "Tue Mar 03 2020 14:31:21 GMT+0700 (Indochina Time)",
    FeeType: "Executive",
    AmountIssued: "903.00",
    AmountOutstanding: 848,
    CostCode: 75084,
    InvoiceNumber: 97158,
    SecurePay_Reference: 20498,
    AmountPaid: "33237",
    ReceiptNumber: 57717,
    ReceiptDate: "Sat Jul 04 2020 08:02:56 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Mr. Emmalee Nolan",
    Method: "Corporate",
    SentToFinanceDate: "Tue Jul 14 2020 05:57:00 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 45620,
    Sys_DBRowState: "deliverables",
    ResidentAreaCode: 32182,
    PermitRenewalDate: "Mon Mar 02 2020 19:58:42 GMT+0700 (Indochina Time)",
    MaximumPermits: 69028,
    NoOfFreePermits: 72288,
    NoOfCost1: 65412,
    Cost1: 21753,
    NoOfCost2: 19782,
    Cost2: 80837,
    NoOfCost3: 81979,
    Cost3: 66317,
    AllowableSuburbs: "New Adeleland",
    AreaBoundaries: "Denesiktown",
    Area: "Reingerburgh",
    Code: 83492,
    Type: "Senior",
    RenewalDate: "Wed Mar 04 2020 09:14:14 GMT+0700 (Indochina Time)",
    FullAddress: "West Berta",
    AssessmentNo: 61927,
    Reason: "cutting-edge",
    Sys_CreatedBy: "Hillary Conn",
    PermitSubType: "Analyst",
    PropertyType: "Consultant",
    Org: "Avon",
    Street: "091 Cary Ports",
    RenewalStatus: "withdrawal",
    LetterSent: "Rustic",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 39393,
    PermitType_Name: "Specialist",
    PermitHolder_DisplayName: "Hollis",
    StreetName: "19486 Maegan Turnpike",
    Suburb: "Jany15",
    Address_Search: "Apt. 228",
    Permit_Status: "Configuration",
    Permit_SubStatus: "in Excepteur deserunt ipsum velit",
    IssuedDate: "Thu Jul 29 2021 20:33:40 GMT+0700 (Indochina Time)",
    ExpiryDate: "Tue Mar 30 2021 21:55:14 GMT+0700 (Indochina Time)",
    PermitNumber: 39317,
    RegNo: 42270,
    PermitArea: "Mobility",
    ZoneCode: 79644,
    ZoneName: "Marianstad",
    PermitCategory_Name: "Future Infrastructure Representative",
    StaffArea: "Bridie Rutherford DVM",
    StaffOrg: "Wade Hodkiewicz",
    FeesOutstanding: "632.00",
    PrintStatus: "green",
    Status: "Credit Card Account",
    Permit_DisplayStatus: "deposit",
    PermitHolder_Email: "Colby.Littel@hotmail.com",
    ReferenceNumber: 76902,
    PermitHolder: "Yazmin Gibson",
    CreatedDate: "Sat Aug 22 2020 11:30:08 GMT+0700 (Indochina Time)",
    FeeType: "Designer",
    AmountIssued: "971.00",
    AmountOutstanding: 60,
    CostCode: 20806,
    InvoiceNumber: 76732,
    SecurePay_Reference: 93541,
    AmountPaid: "56486",
    ReceiptNumber: 72015,
    ReceiptDate: "Fri Feb 28 2020 20:30:12 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Kyle Kozey",
    Method: "Chief",
    SentToFinanceDate: "Fri May 15 2020 11:04:45 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 1797,
    Sys_DBRowState: "Practical",
    ResidentAreaCode: 75561,
    PermitRenewalDate: "Sun Mar 22 2020 12:38:33 GMT+0700 (Indochina Time)",
    MaximumPermits: 22367,
    NoOfFreePermits: 39089,
    NoOfCost1: 88972,
    Cost1: 50373,
    NoOfCost2: 74114,
    Cost2: 39222,
    NoOfCost3: 95749,
    Cost3: 20907,
    AllowableSuburbs: "New Bella",
    AreaBoundaries: "Kulasbury",
    Area: "New Devonte",
    Code: 21039,
    Type: "Arizona",
    RenewalDate: "Fri Sep 04 2020 04:13:30 GMT+0700 (Indochina Time)",
    FullAddress: "Bayermouth",
    AssessmentNo: 87631,
    Reason: "generating",
    Sys_CreatedBy: "Joanne Vandervort",
    PermitSubType: "Orchestrator",
    PropertyType: "Developer",
    Org: "Avon",
    Street: "572 Conroy Brooks",
    RenewalStatus: "bus",
    LetterSent: "Gabon Industrial Savings Account",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 81595,
    PermitType_Name: "Technician",
    PermitHolder_DisplayName: "Lionel",
    StreetName: "3341 Schoen Village",
    Suburb: "Tressie7",
    Address_Search: "Suite 844",
    Permit_Status: "XML",
    Permit_SubStatus: "qui quis mollit id",
    IssuedDate: "Mon Apr 05 2021 08:37:49 GMT+0700 (Indochina Time)",
    ExpiryDate: "Sat Feb 06 2021 12:08:01 GMT+0700 (Indochina Time)",
    PermitNumber: 67124,
    RegNo: 64638,
    PermitArea: "Security",
    ZoneCode: 85308,
    ZoneName: "Port Ruthieport",
    PermitCategory_Name: "Regional Security Coordinator",
    StaffArea: "Miss Osborne Funk",
    StaffOrg: "Alize Bergstrom",
    FeesOutstanding: "929.00",
    PrintStatus: "Refined",
    Status: "JSON",
    Permit_DisplayStatus: "panel",
    PermitHolder_Email: "Krystel.Lockman45@yahoo.com",
    ReferenceNumber: 40756,
    PermitHolder: "Monique Breitenberg",
    CreatedDate: "Mon Sep 07 2020 00:15:08 GMT+0700 (Indochina Time)",
    FeeType: "Facilitator",
    AmountIssued: "768.00",
    AmountOutstanding: 885,
    CostCode: 64160,
    InvoiceNumber: 10352,
    SecurePay_Reference: 7653,
    AmountPaid: "33097",
    ReceiptNumber: 79114,
    ReceiptDate: "Sun Feb 23 2020 05:27:11 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Dr. Ernestina Baumbach",
    Method: "Product",
    SentToFinanceDate: "Wed Jan 01 2020 12:27:32 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 963,
    Sys_DBRowState: "program",
    ResidentAreaCode: 51737,
    PermitRenewalDate: "Fri Dec 04 2020 11:09:58 GMT+0700 (Indochina Time)",
    MaximumPermits: 34841,
    NoOfFreePermits: 19932,
    NoOfCost1: 64617,
    Cost1: 62107,
    NoOfCost2: 96563,
    Cost2: 82003,
    NoOfCost3: 27371,
    Cost3: 90159,
    AllowableSuburbs: "New Beryl",
    AreaBoundaries: "Hoseastad",
    Area: "Norbertoborough",
    Code: 4509,
    Type: "human-resource",
    RenewalDate: "Sun Jul 12 2020 14:41:26 GMT+0700 (Indochina Time)",
    FullAddress: "Newtonside",
    AssessmentNo: 20929,
    Reason: "program",
    Sys_CreatedBy: "Gregoria Anderson",
    PermitSubType: "Liason",
    PropertyType: "Liason",
    Org: "deposit",
    Street: "81837 Morissette Islands",
    RenewalStatus: "Kroon",
    LetterSent: "protocol",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 10978,
    PermitType_Name: "Engineer",
    PermitHolder_DisplayName: "Ulises",
    StreetName: "2391 Jayce Glens",
    Suburb: "Marion_Gorczany",
    Address_Search: "Apt. 623",
    Permit_Status: "Data",
    Permit_SubStatus: "voluptate aliquip",
    IssuedDate: "Wed Apr 07 2021 13:28:54 GMT+0700 (Indochina Time)",
    ExpiryDate: "Fri Mar 12 2021 12:51:01 GMT+0700 (Indochina Time)",
    PermitNumber: 78248,
    RegNo: 56793,
    PermitArea: "Configuration",
    ZoneCode: 48376,
    ZoneName: "Lake Kelsietown",
    PermitCategory_Name: "Forward Accountability Planner",
    StaffArea: "Ms. Dorothea MacGyver",
    StaffOrg: "John Jacobs PhD",
    FeesOutstanding: "258.00",
    PrintStatus: "Product",
    Status: "Oregon",
    Permit_DisplayStatus: "invoice",
    PermitHolder_Email: "Jaylin_Waters8@yahoo.com",
    ReferenceNumber: 73945,
    PermitHolder: "Ladarius Stoltenberg Jr.",
    CreatedDate: "Sat Nov 14 2020 02:00:09 GMT+0700 (Indochina Time)",
    FeeType: "Agent",
    AmountIssued: "2.00",
    AmountOutstanding: 733,
    CostCode: 35811,
    InvoiceNumber: 48297,
    SecurePay_Reference: 93841,
    AmountPaid: "12244",
    ReceiptNumber: 77966,
    ReceiptDate: "Tue Dec 31 2019 02:45:08 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Sheila Runte",
    Method: "Dynamic",
    SentToFinanceDate: "Thu May 14 2020 00:01:39 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 46232,
    Sys_DBRowState: "Solutions",
    ResidentAreaCode: 23595,
    PermitRenewalDate: "Thu Jun 11 2020 14:53:54 GMT+0700 (Indochina Time)",
    MaximumPermits: 36921,
    NoOfFreePermits: 34459,
    NoOfCost1: 29089,
    Cost1: 32639,
    NoOfCost2: 37848,
    Cost2: 75727,
    NoOfCost3: 16918,
    Cost3: 88441,
    AllowableSuburbs: "Hyattberg",
    AreaBoundaries: "Lake Novellatown",
    Area: "South Lillian",
    Code: 98464,
    Type: "North Carolina",
    RenewalDate: "Mon Jan 20 2020 05:13:54 GMT+0700 (Indochina Time)",
    FullAddress: "North Easterport",
    AssessmentNo: 13250,
    Reason: "lavender",
    Sys_CreatedBy: "Angelica Morar",
    PermitSubType: "Officer",
    PropertyType: "Representative",
    Org: "Cambridgeshire",
    Street: "0365 Fern Courts",
    RenewalStatus: "intuitive",
    LetterSent: "Lead Vista Incredible",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 14085,
    PermitType_Name: "Executive",
    PermitHolder_DisplayName: "Timothy",
    StreetName: "24781 Conn Extensions",
    Suburb: "Marcus37",
    Address_Search: "Suite 200",
    Permit_Status: "Optimization",
    Permit_SubStatus: "reprehenderit in eu non",
    IssuedDate: "Mon Jul 26 2021 04:40:47 GMT+0700 (Indochina Time)",
    ExpiryDate: "Sun Nov 21 2021 04:49:26 GMT+0700 (Indochina Time)",
    PermitNumber: 92468,
    RegNo: 72234,
    PermitArea: "Markets",
    ZoneCode: 33663,
    ZoneName: "South Maeve",
    PermitCategory_Name: "Dynamic Markets Planner",
    StaffArea: "Brooke Barrows",
    StaffOrg: "Courtney Koch",
    FeesOutstanding: "749.00",
    PrintStatus: "index",
    Status: "Industrial",
    Permit_DisplayStatus: "Practical Concrete Shirt",
    PermitHolder_Email: "Lenny17@gmail.com",
    ReferenceNumber: 61006,
    PermitHolder: "Rachel Kris",
    CreatedDate: "Tue May 05 2020 16:54:07 GMT+0700 (Indochina Time)",
    FeeType: "Liason",
    AmountIssued: "261.00",
    AmountOutstanding: 846,
    CostCode: 74578,
    InvoiceNumber: 28573,
    SecurePay_Reference: 81088,
    AmountPaid: "29352",
    ReceiptNumber: 3766,
    ReceiptDate: "Thu Nov 05 2020 05:00:47 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Jazlyn Herzog",
    Method: "Lead",
    SentToFinanceDate: "Mon Oct 19 2020 10:13:10 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 34245,
    Sys_DBRowState: "port",
    ResidentAreaCode: 71656,
    PermitRenewalDate: "Thu Mar 05 2020 13:52:15 GMT+0700 (Indochina Time)",
    MaximumPermits: 57199,
    NoOfFreePermits: 62135,
    NoOfCost1: 36260,
    Cost1: 94322,
    NoOfCost2: 75044,
    Cost2: 79825,
    NoOfCost3: 85756,
    Cost3: 46685,
    AllowableSuburbs: "South Fletcherchester",
    AreaBoundaries: "North Jaime",
    Area: "Wunschport",
    Code: 56059,
    Type: "GB",
    RenewalDate: "Tue Aug 04 2020 08:05:44 GMT+0700 (Indochina Time)",
    FullAddress: "Xavierside",
    AssessmentNo: 22392,
    Reason: "Awesome Fresh Chips",
    Sys_CreatedBy: "Werner Cummerata",
    PermitSubType: "Associate",
    PropertyType: "Designer",
    Org: "teal",
    Street: "3264 Schumm Estate",
    RenewalStatus: "bluetooth",
    LetterSent: "Manor Accounts Inlet",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 93886,
    PermitType_Name: "Engineer",
    PermitHolder_DisplayName: "Joseph",
    StreetName: "526 Mayer Ford",
    Suburb: "Peter_Pouros9",
    Address_Search: "Apt. 405",
    Permit_Status: "withdrawal",
    Permit_SubStatus: "occaecat qui eiusmod veniam sit",
    IssuedDate: "Mon Sep 06 2021 07:32:24 GMT+0700 (Indochina Time)",
    ExpiryDate: "Tue Aug 31 2021 08:46:07 GMT+0700 (Indochina Time)",
    PermitNumber: 78222,
    RegNo: 40825,
    PermitArea: "Accountability",
    ZoneCode: 82534,
    ZoneName: "Pagacmouth",
    PermitCategory_Name: "Internal Marketing Orchestrator",
    StaffArea: "Marian Orn",
    StaffOrg: "Alicia Harber Sr.",
    FeesOutstanding: "235.00",
    PrintStatus: "Fantastic Frozen Salad",
    Status: "invoice",
    Permit_DisplayStatus: "24 hour",
    PermitHolder_Email: "Eloise_Barrows@hotmail.com",
    ReferenceNumber: 38671,
    PermitHolder: "Orin Nicolas",
    CreatedDate: "Fri Apr 10 2020 15:01:03 GMT+0700 (Indochina Time)",
    FeeType: "Manager",
    AmountIssued: "647.00",
    AmountOutstanding: 523,
    CostCode: 73659,
    InvoiceNumber: 150,
    SecurePay_Reference: 18545,
    AmountPaid: "16032",
    ReceiptNumber: 70714,
    ReceiptDate: "Wed Jul 29 2020 11:56:09 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Robyn Herman",
    Method: "Customer",
    SentToFinanceDate: "Tue Mar 10 2020 10:54:21 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 16672,
    Sys_DBRowState: "Extended",
    ResidentAreaCode: 3295,
    PermitRenewalDate: "Wed Aug 05 2020 17:54:02 GMT+0700 (Indochina Time)",
    MaximumPermits: 15976,
    NoOfFreePermits: 69434,
    NoOfCost1: 82099,
    Cost1: 23048,
    NoOfCost2: 19212,
    Cost2: 8505,
    NoOfCost3: 49637,
    Cost3: 88400,
    AllowableSuburbs: "Mrazmouth",
    AreaBoundaries: "North Isabellborough",
    Area: "West Janiya",
    Code: 13242,
    Type: "Gorgeous",
    RenewalDate: "Mon Jan 27 2020 16:43:03 GMT+0700 (Indochina Time)",
    FullAddress: "Port Christelle",
    AssessmentNo: 43668,
    Reason: "Cambridgeshire",
    Sys_CreatedBy: "Macy Shanahan",
    PermitSubType: "Analyst",
    PropertyType: "Strategist",
    Org: "transparent",
    Street: "595 Hand Grove",
    RenewalStatus: "Garden",
    LetterSent: "driver world-class Incredible Steel Sausages",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 49075,
    PermitType_Name: "Manager",
    PermitHolder_DisplayName: "Ozella",
    StreetName: "153 Elsa Dam",
    Suburb: "Wava_Stehr11",
    Address_Search: "Apt. 524",
    Permit_Status: "European Unit of Account 9(E.U.A.-9)",
    Permit_SubStatus: "dolor ipsum occaecat",
    IssuedDate: "Mon Jun 07 2021 10:15:20 GMT+0700 (Indochina Time)",
    ExpiryDate: "Mon Dec 13 2021 14:58:14 GMT+0700 (Indochina Time)",
    PermitNumber: 71620,
    RegNo: 87374,
    PermitArea: "Solutions",
    ZoneCode: 75021,
    ZoneName: "Port Meghan",
    PermitCategory_Name: "Dynamic Metrics Executive",
    StaffArea: "Iva Walsh",
    StaffOrg: "Jake Carroll",
    FeesOutstanding: "660.00",
    PrintStatus: "Sports",
    Status: "magenta",
    Permit_DisplayStatus: "Factors",
    PermitHolder_Email: "Roosevelt.Rutherford21@gmail.com",
    ReferenceNumber: 28881,
    PermitHolder: "Jules Collins",
    CreatedDate: "Tue Aug 18 2020 05:45:27 GMT+0700 (Indochina Time)",
    FeeType: "Representative",
    AmountIssued: "218.00",
    AmountOutstanding: 343,
    CostCode: 39170,
    InvoiceNumber: 50817,
    SecurePay_Reference: 59480,
    AmountPaid: "55622",
    ReceiptNumber: 28545,
    ReceiptDate: "Tue Apr 14 2020 00:43:05 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Ryder Schoen MD",
    Method: "Central",
    SentToFinanceDate: "Mon Oct 19 2020 13:02:38 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 67274,
    Sys_DBRowState: "Money Market Account",
    ResidentAreaCode: 56516,
    PermitRenewalDate: "Fri Oct 09 2020 23:32:36 GMT+0700 (Indochina Time)",
    MaximumPermits: 21155,
    NoOfFreePermits: 94958,
    NoOfCost1: 79186,
    Cost1: 62733,
    NoOfCost2: 32496,
    Cost2: 93531,
    NoOfCost3: 95210,
    Cost3: 42057,
    AllowableSuburbs: "East Leannbury",
    AreaBoundaries: "Lake Ralphmouth",
    Area: "Orlandmouth",
    Code: 22199,
    Type: "client-driven",
    RenewalDate: "Sat May 16 2020 10:59:29 GMT+0700 (Indochina Time)",
    FullAddress: "Busterview",
    AssessmentNo: 30911,
    Reason: "Kyat",
    Sys_CreatedBy: "Deangelo Howell",
    PermitSubType: "Producer",
    PropertyType: "Producer",
    Org: "Ferry",
    Street: "68929 Kulas Knolls",
    RenewalStatus: "Tuna",
    LetterSent: "Hills web-enabled Borders",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 79158,
    PermitType_Name: "Developer",
    PermitHolder_DisplayName: "Ola",
    StreetName: "7592 Nathaniel Pike",
    Suburb: "Kaylin_Pouros",
    Address_Search: "Apt. 551",
    Permit_Status: "Engineer",
    Permit_SubStatus: "incididunt dolore adipisicing",
    IssuedDate: "Sun Jul 18 2021 13:28:16 GMT+0700 (Indochina Time)",
    ExpiryDate: "Fri Apr 30 2021 08:23:45 GMT+0700 (Indochina Time)",
    PermitNumber: 90580,
    RegNo: 11394,
    PermitArea: "Configuration",
    ZoneCode: 10533,
    ZoneName: "Cassinchester",
    PermitCategory_Name: "Human Assurance Orchestrator",
    StaffArea: "Sydnee Kuhlman PhD",
    StaffOrg: "Kaley McLaughlin",
    FeesOutstanding: "441.00",
    PrintStatus: "Intranet",
    Status: "Buckinghamshire",
    Permit_DisplayStatus: "benchmark",
    PermitHolder_Email: "Theresia.Metz@hotmail.com",
    ReferenceNumber: 57099,
    PermitHolder: "Trevion Dickens",
    CreatedDate: "Thu Oct 01 2020 01:08:50 GMT+0700 (Indochina Time)",
    FeeType: "Analyst",
    AmountIssued: "259.00",
    AmountOutstanding: 644,
    CostCode: 2765,
    InvoiceNumber: 39114,
    SecurePay_Reference: 23469,
    AmountPaid: "8569",
    ReceiptNumber: 32641,
    ReceiptDate: "Sun Aug 02 2020 14:58:48 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Bette Abernathy",
    Method: "Investor",
    SentToFinanceDate: "Sun Apr 26 2020 02:12:35 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 96725,
    Sys_DBRowState: "withdrawal",
    ResidentAreaCode: 66836,
    PermitRenewalDate: "Sun Dec 13 2020 22:14:23 GMT+0700 (Indochina Time)",
    MaximumPermits: 58404,
    NoOfFreePermits: 94906,
    NoOfCost1: 20374,
    Cost1: 64671,
    NoOfCost2: 55638,
    Cost2: 50293,
    NoOfCost3: 83056,
    Cost3: 29153,
    AllowableSuburbs: "East Dallas",
    AreaBoundaries: "Johnsport",
    Area: "East Zander",
    Code: 6333,
    Type: "Djibouti",
    RenewalDate: "Sat Nov 14 2020 12:13:01 GMT+0700 (Indochina Time)",
    FullAddress: "Jovannyport",
    AssessmentNo: 19844,
    Reason: "Frozen",
    Sys_CreatedBy: "Ralph Funk II",
    PermitSubType: "Administrator",
    PropertyType: "Representative",
    Org: "bandwidth",
    Street: "90506 Bosco Gateway",
    RenewalStatus: "Unbranded Frozen Bike",
    LetterSent: "Clothing Money Market Account Metrics",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 87983,
    PermitType_Name: "Associate",
    PermitHolder_DisplayName: "Candice",
    StreetName: "3471 Victor Circle",
    Suburb: "Geoffrey97",
    Address_Search: "Apt. 859",
    Permit_Status: "calculating",
    Permit_SubStatus: "irure",
    IssuedDate: "Thu Oct 07 2021 14:52:18 GMT+0700 (Indochina Time)",
    ExpiryDate: "Sun Jun 20 2021 07:11:20 GMT+0700 (Indochina Time)",
    PermitNumber: 73497,
    RegNo: 60160,
    PermitArea: "Optimization",
    ZoneCode: 12192,
    ZoneName: "Lake Leathafurt",
    PermitCategory_Name: "Investor Assurance Coordinator",
    StaffArea: "Mertie Borer",
    StaffOrg: "Lewis Lueilwitz",
    FeesOutstanding: "860.00",
    PrintStatus: "Synchronised",
    Status: "Horizontal",
    Permit_DisplayStatus: "olive",
    PermitHolder_Email: "Shakira36@gmail.com",
    ReferenceNumber: 52837,
    PermitHolder: "Orpha Stokes",
    CreatedDate: "Sun Jun 21 2020 04:45:32 GMT+0700 (Indochina Time)",
    FeeType: "Associate",
    AmountIssued: "262.00",
    AmountOutstanding: 177,
    CostCode: 26889,
    InvoiceNumber: 5645,
    SecurePay_Reference: 9629,
    AmountPaid: "24038",
    ReceiptNumber: 53008,
    ReceiptDate: "Wed Apr 22 2020 16:33:10 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Elyssa Wisoky",
    Method: "Forward",
    SentToFinanceDate: "Sun Mar 29 2020 15:14:59 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 77578,
    Sys_DBRowState: "cross-platform",
    ResidentAreaCode: 24913,
    PermitRenewalDate: "Mon Mar 16 2020 14:16:32 GMT+0700 (Indochina Time)",
    MaximumPermits: 72091,
    NoOfFreePermits: 10624,
    NoOfCost1: 49597,
    Cost1: 49133,
    NoOfCost2: 16873,
    Cost2: 67718,
    NoOfCost3: 3169,
    Cost3: 30756,
    AllowableSuburbs: "Markston",
    AreaBoundaries: "North Noemy",
    Area: "East Haydenshire",
    Code: 16104,
    Type: "Future",
    RenewalDate: "Wed Jun 17 2020 12:03:00 GMT+0700 (Indochina Time)",
    FullAddress: "Swiftmouth",
    AssessmentNo: 97270,
    Reason: "Bacon",
    Sys_CreatedBy: "Elisa Johnson",
    PermitSubType: "Facilitator",
    PropertyType: "Producer",
    Org: "Gold",
    Street: "88661 Reynolds Drives",
    RenewalStatus: "bluetooth",
    LetterSent: "interface",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 76557,
    PermitType_Name: "Manager",
    PermitHolder_DisplayName: "Erica",
    StreetName: "03194 Schmeler Trace",
    Suburb: "Summer.Schoen",
    Address_Search: "Suite 779",
    Permit_Status: "Fresh",
    Permit_SubStatus: "veniam non sunt aute ipsum",
    IssuedDate: "Sat Oct 30 2021 12:49:35 GMT+0700 (Indochina Time)",
    ExpiryDate: "Wed Jun 09 2021 01:11:27 GMT+0700 (Indochina Time)",
    PermitNumber: 97005,
    RegNo: 72197,
    PermitArea: "Applications",
    ZoneCode: 95917,
    ZoneName: "Port Kristastad",
    PermitCategory_Name: "Customer Interactions Agent",
    StaffArea: "Shayna O'Kon",
    StaffOrg: "Rosendo Rutherford",
    FeesOutstanding: "385.00",
    PrintStatus: "Developer",
    Status: "navigate",
    Permit_DisplayStatus: "Usability",
    PermitHolder_Email: "Sadye.McKenzie95@yahoo.com",
    ReferenceNumber: 52281,
    PermitHolder: "Harvey Pollich",
    CreatedDate: "Sat Aug 29 2020 10:41:54 GMT+0700 (Indochina Time)",
    FeeType: "Designer",
    AmountIssued: "287.00",
    AmountOutstanding: 214,
    CostCode: 37601,
    InvoiceNumber: 14724,
    SecurePay_Reference: 91260,
    AmountPaid: "90053",
    ReceiptNumber: 575,
    ReceiptDate: "Fri Jul 31 2020 05:48:38 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Marcellus Welch",
    Method: "Chief",
    SentToFinanceDate: "Tue Feb 04 2020 10:41:05 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 19108,
    Sys_DBRowState: "Refined Granite Computer",
    ResidentAreaCode: 54158,
    PermitRenewalDate: "Fri Apr 17 2020 07:43:49 GMT+0700 (Indochina Time)",
    MaximumPermits: 62658,
    NoOfFreePermits: 96838,
    NoOfCost1: 94431,
    Cost1: 15348,
    NoOfCost2: 43459,
    Cost2: 16192,
    NoOfCost3: 98267,
    Cost3: 14005,
    AllowableSuburbs: "Port Ezequiel",
    AreaBoundaries: "East Carolyn",
    Area: "East Alessandroville",
    Code: 67268,
    Type: "Azerbaijan",
    RenewalDate: "Sun Oct 04 2020 08:15:44 GMT+0700 (Indochina Time)",
    FullAddress: "South Cecelia",
    AssessmentNo: 57884,
    Reason: "port",
    Sys_CreatedBy: "Kara Stokes",
    PermitSubType: "Consultant",
    PropertyType: "Administrator",
    Org: "Lari",
    Street: "173 Melyna Forks",
    RenewalStatus: "benchmark",
    LetterSent: "invoice",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 63652,
    PermitType_Name: "Architect",
    PermitHolder_DisplayName: "Katharina",
    StreetName: "128 Olson Curve",
    Suburb: "Orion_OReilly",
    Address_Search: "Suite 333",
    Permit_Status: "maximize",
    Permit_SubStatus: "dolore ipsum quis",
    IssuedDate: "Tue Aug 17 2021 05:32:39 GMT+0700 (Indochina Time)",
    ExpiryDate: "Sun Oct 17 2021 06:10:59 GMT+0700 (Indochina Time)",
    PermitNumber: 38224,
    RegNo: 52238,
    PermitArea: "Usability",
    ZoneCode: 67910,
    ZoneName: "Forestville",
    PermitCategory_Name: "Investor Implementation Associate",
    StaffArea: "Tony Hirthe",
    StaffOrg: "Mr. Wilhelmine Kiehn",
    FeesOutstanding: "261.00",
    PrintStatus: "mesh",
    Status: "navigating",
    Permit_DisplayStatus: "Checking Account",
    PermitHolder_Email: "Felton_Witting@yahoo.com",
    ReferenceNumber: 44956,
    PermitHolder: "Dayana Smith",
    CreatedDate: "Fri Nov 20 2020 04:55:15 GMT+0700 (Indochina Time)",
    FeeType: "Administrator",
    AmountIssued: "39.00",
    AmountOutstanding: 348,
    CostCode: 45158,
    InvoiceNumber: 91890,
    SecurePay_Reference: 90192,
    AmountPaid: "86541",
    ReceiptNumber: 16682,
    ReceiptDate: "Thu Jul 16 2020 14:39:37 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Scotty Kuhic",
    Method: "Internal",
    SentToFinanceDate: "Mon Nov 30 2020 06:35:38 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 58107,
    Sys_DBRowState: "context-sensitive",
    ResidentAreaCode: 36296,
    PermitRenewalDate: "Sat Aug 08 2020 04:58:15 GMT+0700 (Indochina Time)",
    MaximumPermits: 15149,
    NoOfFreePermits: 40291,
    NoOfCost1: 30388,
    Cost1: 72173,
    NoOfCost2: 1125,
    Cost2: 67870,
    NoOfCost3: 19314,
    Cost3: 24619,
    AllowableSuburbs: "Lake Demarcusfurt",
    AreaBoundaries: "South Kanetown",
    Area: "North Camillashire",
    Code: 28547,
    Type: "Internal",
    RenewalDate: "Mon Oct 05 2020 12:48:25 GMT+0700 (Indochina Time)",
    FullAddress: "Thompsonton",
    AssessmentNo: 17098,
    Reason: "Home Loan Account",
    Sys_CreatedBy: "Nola Deckow",
    PermitSubType: "Architect",
    PropertyType: "Technician",
    Org: "SQL",
    Street: "4193 Gabe Camp",
    RenewalStatus: "backing up",
    LetterSent: "Cambridgeshire SSL",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 35931,
    PermitType_Name: "Coordinator",
    PermitHolder_DisplayName: "Minerva",
    StreetName: "54644 Rolfson Rue",
    Suburb: "Heidi_Dicki68",
    Address_Search: "Apt. 641",
    Permit_Status: "Fantastic",
    Permit_SubStatus: "aute",
    IssuedDate: "Sat Apr 17 2021 06:56:59 GMT+0700 (Indochina Time)",
    ExpiryDate: "Wed Dec 30 2020 21:09:01 GMT+0700 (Indochina Time)",
    PermitNumber: 91660,
    RegNo: 71972,
    PermitArea: "Mobility",
    ZoneCode: 35785,
    ZoneName: "Dickiton",
    PermitCategory_Name: "Chief Group Supervisor",
    StaffArea: "Jaden Collier",
    StaffOrg: "Marcellus Lueilwitz",
    FeesOutstanding: "361.00",
    PrintStatus: "morph",
    Status: "Small",
    Permit_DisplayStatus: "RAM",
    PermitHolder_Email: "Samson91@gmail.com",
    ReferenceNumber: 15254,
    PermitHolder: "Nannie Hoppe",
    CreatedDate: "Wed Aug 19 2020 03:10:44 GMT+0700 (Indochina Time)",
    FeeType: "Designer",
    AmountIssued: "121.00",
    AmountOutstanding: 497,
    CostCode: 92682,
    InvoiceNumber: 11669,
    SecurePay_Reference: 76590,
    AmountPaid: "33310",
    ReceiptNumber: 44350,
    ReceiptDate: "Sun Mar 08 2020 03:22:27 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Caterina McGlynn",
    Method: "Senior",
    SentToFinanceDate: "Thu Feb 27 2020 22:03:13 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 96688,
    Sys_DBRowState: "synergy",
    ResidentAreaCode: 91887,
    PermitRenewalDate: "Tue Apr 14 2020 15:52:08 GMT+0700 (Indochina Time)",
    MaximumPermits: 62964,
    NoOfFreePermits: 49372,
    NoOfCost1: 20663,
    Cost1: 78740,
    NoOfCost2: 8391,
    Cost2: 10399,
    NoOfCost3: 80739,
    Cost3: 63646,
    AllowableSuburbs: "West Oswaldo",
    AreaBoundaries: "Nikitaview",
    Area: "East Gordon",
    Code: 31313,
    Type: "Arizona",
    RenewalDate: "Sun Nov 01 2020 12:19:51 GMT+0700 (Indochina Time)",
    FullAddress: "Port Carter",
    AssessmentNo: 9281,
    Reason: "Configurable",
    Sys_CreatedBy: "Kasey Collins",
    PermitSubType: "Director",
    PropertyType: "Administrator",
    Org: "Mobility",
    Street: "766 Cronin Circle",
    RenewalStatus: "Hong Kong Dollar",
    LetterSent: "cross-platform Security",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 31326,
    PermitType_Name: "Analyst",
    PermitHolder_DisplayName: "Brycen",
    StreetName: "015 MacGyver Courts",
    Suburb: "Ruthe_Hammes64",
    Address_Search: "Apt. 319",
    Permit_Status: "Fresh",
    Permit_SubStatus: "irure",
    IssuedDate: "Wed May 12 2021 13:45:31 GMT+0700 (Indochina Time)",
    ExpiryDate: "Fri Jul 16 2021 16:59:37 GMT+0700 (Indochina Time)",
    PermitNumber: 55685,
    RegNo: 35588,
    PermitArea: "Metrics",
    ZoneCode: 83802,
    ZoneName: "Port Luigiton",
    PermitCategory_Name: "Forward Implementation Developer",
    StaffArea: "Ms. Matt Green",
    StaffOrg: "Isaac Bruen",
    FeesOutstanding: "335.00",
    PrintStatus: "Holy See (Vatican City State)",
    Status: "payment",
    Permit_DisplayStatus: "Buckinghamshire",
    PermitHolder_Email: "Malvina57@yahoo.com",
    ReferenceNumber: 14027,
    PermitHolder: "Troy Gislason",
    CreatedDate: "Thu May 14 2020 18:34:16 GMT+0700 (Indochina Time)",
    FeeType: "Representative",
    AmountIssued: "421.00",
    AmountOutstanding: 945,
    CostCode: 85265,
    InvoiceNumber: 66038,
    SecurePay_Reference: 9797,
    AmountPaid: "39940",
    ReceiptNumber: 7538,
    ReceiptDate: "Mon Mar 23 2020 09:45:38 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Marcelino Okuneva",
    Method: "Dynamic",
    SentToFinanceDate: "Wed Jul 08 2020 02:31:52 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 49393,
    Sys_DBRowState: "Keyboard",
    ResidentAreaCode: 88526,
    PermitRenewalDate: "Sun Oct 04 2020 07:48:52 GMT+0700 (Indochina Time)",
    MaximumPermits: 73673,
    NoOfFreePermits: 77711,
    NoOfCost1: 73470,
    Cost1: 69734,
    NoOfCost2: 29062,
    Cost2: 7124,
    NoOfCost3: 65907,
    Cost3: 72256,
    AllowableSuburbs: "Ernamouth",
    AreaBoundaries: "Crooksmouth",
    Area: "Christiansenport",
    Code: 42183,
    Type: "logistical",
    RenewalDate: "Tue Feb 25 2020 00:25:07 GMT+0700 (Indochina Time)",
    FullAddress: "Lake Connorshire",
    AssessmentNo: 58672,
    Reason: "global",
    Sys_CreatedBy: "Favian Fisher",
    PermitSubType: "Assistant",
    PropertyType: "Designer",
    Org: "discrete",
    Street: "51024 Hills Common",
    RenewalStatus: "Chair",
    LetterSent: "Cambridgeshire",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 66258,
    PermitType_Name: "Orchestrator",
    PermitHolder_DisplayName: "Efrain",
    StreetName: "4137 Towne Island",
    Suburb: "Melody.Carroll",
    Address_Search: "Suite 721",
    Permit_Status: "transmitter",
    Permit_SubStatus: "officia cillum ut eu elit",
    IssuedDate: "Thu Sep 02 2021 22:25:06 GMT+0700 (Indochina Time)",
    ExpiryDate: "Sat Aug 28 2021 22:48:53 GMT+0700 (Indochina Time)",
    PermitNumber: 57106,
    RegNo: 21751,
    PermitArea: "Metrics",
    ZoneCode: 3516,
    ZoneName: "South Verniceshire",
    PermitCategory_Name: "Direct Response Specialist",
    StaffArea: "Jordane Haley",
    StaffOrg: "Terry Jacobson",
    FeesOutstanding: "936.00",
    PrintStatus: "GB",
    Status: "Identity",
    Permit_DisplayStatus: "Dalasi",
    PermitHolder_Email: "Yasmin.Satterfield@yahoo.com",
    ReferenceNumber: 20624,
    PermitHolder: "Mrs. Jensen Kerluke",
    CreatedDate: "Mon Aug 03 2020 15:16:48 GMT+0700 (Indochina Time)",
    FeeType: "Planner",
    AmountIssued: "538.00",
    AmountOutstanding: 469,
    CostCode: 5192,
    InvoiceNumber: 49860,
    SecurePay_Reference: 6236,
    AmountPaid: "15316",
    ReceiptNumber: 88674,
    ReceiptDate: "Fri Jan 31 2020 19:46:07 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Mrs. Lilian Rowe",
    Method: "Dynamic",
    SentToFinanceDate: "Mon Jun 29 2020 05:27:54 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 80914,
    Sys_DBRowState: "definition",
    ResidentAreaCode: 52777,
    PermitRenewalDate: "Fri Jun 05 2020 08:16:36 GMT+0700 (Indochina Time)",
    MaximumPermits: 55302,
    NoOfFreePermits: 83738,
    NoOfCost1: 36610,
    Cost1: 30501,
    NoOfCost2: 73074,
    Cost2: 88241,
    NoOfCost3: 4483,
    Cost3: 48287,
    AllowableSuburbs: "Blickburgh",
    AreaBoundaries: "North Colten",
    Area: "Stehrfurt",
    Code: 65354,
    Type: "Seychelles",
    RenewalDate: "Sun Sep 13 2020 14:43:21 GMT+0700 (Indochina Time)",
    FullAddress: "Sandrabury",
    AssessmentNo: 41258,
    Reason: "Designer",
    Sys_CreatedBy: "Dameon Murray PhD",
    PermitSubType: "Supervisor",
    PropertyType: "Associate",
    Org: "Bypass",
    Street: "52375 Schuppe Meadow",
    RenewalStatus: "repurpose",
    LetterSent: "Personal Loan Account",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 36478,
    PermitType_Name: "Administrator",
    PermitHolder_DisplayName: "Carolina",
    StreetName: "428 Cruickshank Trace",
    Suburb: "Nyah47",
    Address_Search: "Suite 206",
    Permit_Status: "South Carolina",
    Permit_SubStatus: "aute in fugiat eu reprehenderit",
    IssuedDate: "Sat Nov 06 2021 19:35:18 GMT+0700 (Indochina Time)",
    ExpiryDate: "Fri Nov 12 2021 00:45:42 GMT+0700 (Indochina Time)",
    PermitNumber: 10787,
    RegNo: 6212,
    PermitArea: "Assurance",
    ZoneCode: 51881,
    ZoneName: "South Jess",
    PermitCategory_Name: "Human Functionality Designer",
    StaffArea: "Orpha Dickinson",
    StaffOrg: "Keanu Langworth II",
    FeesOutstanding: "349.00",
    PrintStatus: "strategize",
    Status: "users",
    Permit_DisplayStatus: "deposit",
    PermitHolder_Email: "Michel_Hackett98@hotmail.com",
    ReferenceNumber: 87392,
    PermitHolder: "Asha Renner",
    CreatedDate: "Tue Jul 14 2020 03:01:12 GMT+0700 (Indochina Time)",
    FeeType: "Facilitator",
    AmountIssued: "556.00",
    AmountOutstanding: 891,
    CostCode: 22371,
    InvoiceNumber: 2433,
    SecurePay_Reference: 75575,
    AmountPaid: "43626",
    ReceiptNumber: 86258,
    ReceiptDate: "Wed Jan 15 2020 08:55:08 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Conner Hermann",
    Method: "Customer",
    SentToFinanceDate: "Fri Jul 17 2020 13:49:54 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 69495,
    Sys_DBRowState: "Central",
    ResidentAreaCode: 14717,
    PermitRenewalDate: "Tue Mar 03 2020 23:35:27 GMT+0700 (Indochina Time)",
    MaximumPermits: 54023,
    NoOfFreePermits: 8210,
    NoOfCost1: 89399,
    Cost1: 27833,
    NoOfCost2: 63895,
    Cost2: 54758,
    NoOfCost3: 34149,
    Cost3: 8216,
    AllowableSuburbs: "North Ervinview",
    AreaBoundaries: "Starkchester",
    Area: "Port Bill",
    Code: 16000,
    Type: "North Korean Won",
    RenewalDate: "Wed Sep 16 2020 22:08:12 GMT+0700 (Indochina Time)",
    FullAddress: "Port Haroldmouth",
    AssessmentNo: 2150,
    Reason: "Computers",
    Sys_CreatedBy: "Payton Schumm",
    PermitSubType: "Orchestrator",
    PropertyType: "Engineer",
    Org: "open-source",
    Street: "7463 Larson Mews",
    RenewalStatus: "Borders",
    LetterSent: "Cotton Fresh",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 85440,
    PermitType_Name: "Director",
    PermitHolder_DisplayName: "Larry",
    StreetName: "1346 Pearlie Plaza",
    Suburb: "Gardner.Pouros",
    Address_Search: "Suite 702",
    Permit_Status: "pixel",
    Permit_SubStatus: "esse ut minim",
    IssuedDate: "Fri Nov 05 2021 22:17:39 GMT+0700 (Indochina Time)",
    ExpiryDate: "Sun Mar 21 2021 01:32:28 GMT+0700 (Indochina Time)",
    PermitNumber: 24855,
    RegNo: 54201,
    PermitArea: "Interactions",
    ZoneCode: 76704,
    ZoneName: "Schmittton",
    PermitCategory_Name: "Central Division Representative",
    StaffArea: "Piper Stamm",
    StaffOrg: "Nicolas Lynch",
    FeesOutstanding: "971.00",
    PrintStatus: "Chicken",
    Status: "synthesize",
    Permit_DisplayStatus: "deliver",
    PermitHolder_Email: "Cory.Gorczany21@gmail.com",
    ReferenceNumber: 88586,
    PermitHolder: "Nedra Cormier",
    CreatedDate: "Tue Mar 31 2020 07:24:50 GMT+0700 (Indochina Time)",
    FeeType: "Manager",
    AmountIssued: "602.00",
    AmountOutstanding: 775,
    CostCode: 13388,
    InvoiceNumber: 69370,
    SecurePay_Reference: 2530,
    AmountPaid: "79280",
    ReceiptNumber: 99841,
    ReceiptDate: "Mon Mar 16 2020 07:03:17 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Brown Bogisich",
    Method: "Senior",
    SentToFinanceDate: "Sat Jun 20 2020 18:12:27 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 7868,
    Sys_DBRowState: "Ports",
    ResidentAreaCode: 32907,
    PermitRenewalDate: "Wed Jul 01 2020 04:30:48 GMT+0700 (Indochina Time)",
    MaximumPermits: 68279,
    NoOfFreePermits: 68769,
    NoOfCost1: 51477,
    Cost1: 38199,
    NoOfCost2: 82634,
    Cost2: 91030,
    NoOfCost3: 19217,
    Cost3: 33816,
    AllowableSuburbs: "Nicolasshire",
    AreaBoundaries: "Feeneymouth",
    Area: "Emilieside",
    Code: 11251,
    Type: "digital",
    RenewalDate: "Fri Mar 20 2020 17:03:12 GMT+0700 (Indochina Time)",
    FullAddress: "Mathiasmouth",
    AssessmentNo: 41540,
    Reason: "Salad",
    Sys_CreatedBy: "Arturo Altenwerth",
    PermitSubType: "Producer",
    PropertyType: "Planner",
    Org: "Checking Account",
    Street: "021 Volkman Stravenue",
    RenewalStatus: "Principal",
    LetterSent: "complexity Garden Dynamic",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 23081,
    PermitType_Name: "Supervisor",
    PermitHolder_DisplayName: "Halle",
    StreetName: "265 Simonis Oval",
    Suburb: "Alvena.Block",
    Address_Search: "Apt. 039",
    Permit_Status: "Advanced",
    Permit_SubStatus: "ullamco",
    IssuedDate: "Fri Apr 30 2021 04:35:12 GMT+0700 (Indochina Time)",
    ExpiryDate: "Wed Nov 17 2021 11:42:49 GMT+0700 (Indochina Time)",
    PermitNumber: 14689,
    RegNo: 18010,
    PermitArea: "Solutions",
    ZoneCode: 69292,
    ZoneName: "East Elliot",
    PermitCategory_Name: "Future Markets Assistant",
    StaffArea: "Dayton McGlynn",
    StaffOrg: "Velda Lind",
    FeesOutstanding: "205.00",
    PrintStatus: "feed",
    Status: "JBOD",
    Permit_DisplayStatus: "Credit Card Account",
    PermitHolder_Email: "Kasey.Wintheiser59@yahoo.com",
    ReferenceNumber: 75585,
    PermitHolder: "General Jacobi",
    CreatedDate: "Thu Sep 17 2020 22:27:54 GMT+0700 (Indochina Time)",
    FeeType: "Liason",
    AmountIssued: "288.00",
    AmountOutstanding: 659,
    CostCode: 94383,
    InvoiceNumber: 8069,
    SecurePay_Reference: 12814,
    AmountPaid: "20994",
    ReceiptNumber: 85276,
    ReceiptDate: "Mon May 11 2020 09:25:55 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Sadye Klein",
    Method: "Central",
    SentToFinanceDate: "Sat Sep 19 2020 11:52:19 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 88626,
    Sys_DBRowState: "Future",
    ResidentAreaCode: 81589,
    PermitRenewalDate: "Fri Sep 18 2020 18:17:42 GMT+0700 (Indochina Time)",
    MaximumPermits: 59370,
    NoOfFreePermits: 45624,
    NoOfCost1: 25752,
    Cost1: 34733,
    NoOfCost2: 5218,
    Cost2: 64847,
    NoOfCost3: 4796,
    Cost3: 26731,
    AllowableSuburbs: "Webermouth",
    AreaBoundaries: "North Jerad",
    Area: "Nolanville",
    Code: 12130,
    Type: "Checking Account",
    RenewalDate: "Mon Jul 06 2020 18:34:52 GMT+0700 (Indochina Time)",
    FullAddress: "North Roel",
    AssessmentNo: 88378,
    Reason: "SCSI",
    Sys_CreatedBy: "Alex Carroll",
    PermitSubType: "Facilitator",
    PropertyType: "Assistant",
    Org: "infomediaries",
    Street: "4326 Carol Place",
    RenewalStatus: "Movies",
    LetterSent: "exuding Czech Republic",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 60129,
    PermitType_Name: "Technician",
    PermitHolder_DisplayName: "Joy",
    StreetName: "023 Hillard View",
    Suburb: "Ruthie_Leuschke45",
    Address_Search: "Apt. 372",
    Permit_Status: "Alabama",
    Permit_SubStatus: "occaecat laborum",
    IssuedDate: "Sat Jun 12 2021 18:29:15 GMT+0700 (Indochina Time)",
    ExpiryDate: "Mon Feb 01 2021 14:57:21 GMT+0700 (Indochina Time)",
    PermitNumber: 44062,
    RegNo: 90549,
    PermitArea: "Directives",
    ZoneCode: 71035,
    ZoneName: "Port Jerrodberg",
    PermitCategory_Name: "Forward Group Liason",
    StaffArea: "Sylvia Hane",
    StaffOrg: "Kaelyn Mills",
    FeesOutstanding: "946.00",
    PrintStatus: "alarm",
    Status: "envisioneer",
    Permit_DisplayStatus: "Producer",
    PermitHolder_Email: "Deshaun97@gmail.com",
    ReferenceNumber: 93268,
    PermitHolder: "Vincenza Bosco",
    CreatedDate: "Thu Feb 06 2020 19:36:19 GMT+0700 (Indochina Time)",
    FeeType: "Associate",
    AmountIssued: "366.00",
    AmountOutstanding: 498,
    CostCode: 77629,
    InvoiceNumber: 37128,
    SecurePay_Reference: 22999,
    AmountPaid: "14959",
    ReceiptNumber: 79084,
    ReceiptDate: "Fri Aug 14 2020 15:48:37 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Mrs. Gonzalo Pouros",
    Method: "Product",
    SentToFinanceDate: "Mon May 11 2020 02:40:07 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 50566,
    Sys_DBRowState: "Baht",
    ResidentAreaCode: 20703,
    PermitRenewalDate: "Fri May 08 2020 21:06:47 GMT+0700 (Indochina Time)",
    MaximumPermits: 79416,
    NoOfFreePermits: 29609,
    NoOfCost1: 62575,
    Cost1: 50555,
    NoOfCost2: 69726,
    Cost2: 79995,
    NoOfCost3: 51383,
    Cost3: 11354,
    AllowableSuburbs: "Riceport",
    AreaBoundaries: "East Maidaberg",
    Area: "Yoshikoton",
    Code: 48564,
    Type: "SDD",
    RenewalDate: "Fri Oct 23 2020 05:14:29 GMT+0700 (Indochina Time)",
    FullAddress: "West Mable",
    AssessmentNo: 14065,
    Reason: "Car",
    Sys_CreatedBy: "Edgar Feil PhD",
    PermitSubType: "Engineer",
    PropertyType: "Executive",
    Org: "SMS",
    Street: "818 Huel Underpass",
    RenewalStatus: "invoice",
    LetterSent: "Moroccan Dirham",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 25338,
    PermitType_Name: "Planner",
    PermitHolder_DisplayName: "Eleanora",
    StreetName: "11507 Stacey Lane",
    Suburb: "Harold61",
    Address_Search: "Apt. 728",
    Permit_Status: "Agent",
    Permit_SubStatus: "eu nisi Excepteur fugiat",
    IssuedDate: "Fri Aug 13 2021 06:51:04 GMT+0700 (Indochina Time)",
    ExpiryDate: "Wed May 12 2021 17:15:27 GMT+0700 (Indochina Time)",
    PermitNumber: 85718,
    RegNo: 51963,
    PermitArea: "Directives",
    ZoneCode: 65528,
    ZoneName: "Kesslerburgh",
    PermitCategory_Name: "Chief Intranet Executive",
    StaffArea: "Dillan Tremblay",
    StaffOrg: "Dashawn Shields MD",
    FeesOutstanding: "257.00",
    PrintStatus: "supply-chains",
    Status: "Licensed Frozen Hat",
    Permit_DisplayStatus: "web services",
    PermitHolder_Email: "Ed12@gmail.com",
    ReferenceNumber: 80081,
    PermitHolder: "Mr. Gregoria Schumm",
    CreatedDate: "Fri Oct 30 2020 15:51:10 GMT+0700 (Indochina Time)",
    FeeType: "Technician",
    AmountIssued: "728.00",
    AmountOutstanding: 61,
    CostCode: 88457,
    InvoiceNumber: 872,
    SecurePay_Reference: 43829,
    AmountPaid: "96913",
    ReceiptNumber: 83772,
    ReceiptDate: "Mon Jan 06 2020 07:42:04 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Gage Lindgren",
    Method: "International",
    SentToFinanceDate: "Thu Jul 09 2020 04:31:15 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 97737,
    Sys_DBRowState: "Gorgeous Wooden Chair",
    ResidentAreaCode: 55724,
    PermitRenewalDate: "Mon Nov 30 2020 23:18:20 GMT+0700 (Indochina Time)",
    MaximumPermits: 65567,
    NoOfFreePermits: 56952,
    NoOfCost1: 29693,
    Cost1: 38145,
    NoOfCost2: 91728,
    Cost2: 59194,
    NoOfCost3: 11050,
    Cost3: 14572,
    AllowableSuburbs: "Valentinestad",
    AreaBoundaries: "Port Nya",
    Area: "North Ariel",
    Code: 12051,
    Type: "microchip",
    RenewalDate: "Fri May 01 2020 01:44:30 GMT+0700 (Indochina Time)",
    FullAddress: "Port Sheridan",
    AssessmentNo: 69240,
    Reason: "Personal Loan Account",
    Sys_CreatedBy: "Darron Hamill",
    PermitSubType: "Director",
    PropertyType: "Technician",
    Org: "Investor",
    Street: "286 Lockman Radial",
    RenewalStatus: "whiteboard",
    LetterSent: "quantify circuit",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 27319,
    PermitType_Name: "Planner",
    PermitHolder_DisplayName: "Juwan",
    StreetName: "2045 Renner Greens",
    Suburb: "Donna.Treutel88",
    Address_Search: "Suite 193",
    Permit_Status: "digital",
    Permit_SubStatus: "laborum ut quis",
    IssuedDate: "Mon Feb 08 2021 01:39:54 GMT+0700 (Indochina Time)",
    ExpiryDate: "Wed Jun 09 2021 02:14:47 GMT+0700 (Indochina Time)",
    PermitNumber: 46310,
    RegNo: 47518,
    PermitArea: "Applications",
    ZoneCode: 7050,
    ZoneName: "North Bradfordhaven",
    PermitCategory_Name: "Investor Communications Agent",
    StaffArea: "Jackeline Sporer",
    StaffOrg: "Rosalia Heller",
    FeesOutstanding: "511.00",
    PrintStatus: "Avon",
    Status: "Station",
    Permit_DisplayStatus: "reboot",
    PermitHolder_Email: "Mariah_Hagenes@gmail.com",
    ReferenceNumber: 52776,
    PermitHolder: "Hayden Bradtke",
    CreatedDate: "Tue May 26 2020 21:19:26 GMT+0700 (Indochina Time)",
    FeeType: "Agent",
    AmountIssued: "143.00",
    AmountOutstanding: 248,
    CostCode: 22076,
    InvoiceNumber: 18895,
    SecurePay_Reference: 46327,
    AmountPaid: "74548",
    ReceiptNumber: 93674,
    ReceiptDate: "Sun Oct 04 2020 10:37:44 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Bernice Walker",
    Method: "Legacy",
    SentToFinanceDate: "Wed Apr 29 2020 17:56:39 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 10870,
    Sys_DBRowState: "Oman",
    ResidentAreaCode: 72963,
    PermitRenewalDate: "Sun May 31 2020 16:51:54 GMT+0700 (Indochina Time)",
    MaximumPermits: 36095,
    NoOfFreePermits: 48999,
    NoOfCost1: 11449,
    Cost1: 20442,
    NoOfCost2: 88794,
    Cost2: 12065,
    NoOfCost3: 5812,
    Cost3: 24436,
    AllowableSuburbs: "South Geovanni",
    AreaBoundaries: "Leopoldberg",
    Area: "East Ella",
    Code: 10132,
    Type: "United Kingdom",
    RenewalDate: "Thu Oct 08 2020 17:14:10 GMT+0700 (Indochina Time)",
    FullAddress: "South Ida",
    AssessmentNo: 6521,
    Reason: "Legacy",
    Sys_CreatedBy: "Kimberly Dibbert",
    PermitSubType: "Coordinator",
    PropertyType: "Supervisor",
    Org: "interface",
    Street: "8227 Cassidy Square",
    RenewalStatus: "gold",
    LetterSent: "Universal Kwacha",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 82123,
    PermitType_Name: "Executive",
    PermitHolder_DisplayName: "Jovan",
    StreetName: "94700 Kenya Hill",
    Suburb: "Erica21",
    Address_Search: "Suite 340",
    Permit_Status: "Saint Helena",
    Permit_SubStatus: "id nulla esse consequat",
    IssuedDate: "Sat Nov 06 2021 09:30:14 GMT+0700 (Indochina Time)",
    ExpiryDate: "Wed Sep 01 2021 07:27:27 GMT+0700 (Indochina Time)",
    PermitNumber: 15659,
    RegNo: 96058,
    PermitArea: "Usability",
    ZoneCode: 66024,
    ZoneName: "Port Judd",
    PermitCategory_Name: "Product Quality Strategist",
    StaffArea: "Jeremie Hegmann",
    StaffOrg: "Camylle Prosacco",
    FeesOutstanding: "419.00",
    PrintStatus: "system",
    Status: "firewall",
    Permit_DisplayStatus: "Awesome Soft Salad",
    PermitHolder_Email: "Noah11@gmail.com",
    ReferenceNumber: 10077,
    PermitHolder: "Jessie Gutkowski",
    CreatedDate: "Sun Jun 28 2020 19:44:10 GMT+0700 (Indochina Time)",
    FeeType: "Planner",
    AmountIssued: "379.00",
    AmountOutstanding: 314,
    CostCode: 19459,
    InvoiceNumber: 77382,
    SecurePay_Reference: 27005,
    AmountPaid: "36",
    ReceiptNumber: 75784,
    ReceiptDate: "Fri Dec 25 2020 12:08:22 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Antwon Harber",
    Method: "Lead",
    SentToFinanceDate: "Sun Mar 29 2020 14:53:30 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 56133,
    Sys_DBRowState: "Cape Verde",
    ResidentAreaCode: 46391,
    PermitRenewalDate: "Sun Aug 23 2020 22:51:51 GMT+0700 (Indochina Time)",
    MaximumPermits: 48109,
    NoOfFreePermits: 85903,
    NoOfCost1: 7617,
    Cost1: 72239,
    NoOfCost2: 76545,
    Cost2: 11291,
    NoOfCost3: 49884,
    Cost3: 43477,
    AllowableSuburbs: "Lessieport",
    AreaBoundaries: "Port Edahaven",
    Area: "Benedictchester",
    Code: 51327,
    Type: "Generic Frozen Gloves",
    RenewalDate: "Mon Nov 23 2020 12:25:11 GMT+0700 (Indochina Time)",
    FullAddress: "South Kayley",
    AssessmentNo: 92618,
    Reason: "Cambridgeshire",
    Sys_CreatedBy: "Elyse McClure",
    PermitSubType: "Producer",
    PropertyType: "Representative",
    Org: "Paradigm",
    Street: "317 Brianne Cliffs",
    RenewalStatus: "FTP",
    LetterSent: "Function-based cultivate",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 61823,
    PermitType_Name: "Director",
    PermitHolder_DisplayName: "Sean",
    StreetName: "477 Conroy Ridge",
    Suburb: "Ruthe.Yundt27",
    Address_Search: "Apt. 561",
    Permit_Status: "e-enable",
    Permit_SubStatus: "pariatur Ut",
    IssuedDate: "Sun Jul 04 2021 13:39:13 GMT+0700 (Indochina Time)",
    ExpiryDate: "Tue May 18 2021 01:01:10 GMT+0700 (Indochina Time)",
    PermitNumber: 8610,
    RegNo: 41295,
    PermitArea: "Program",
    ZoneCode: 90647,
    ZoneName: "Velvaview",
    PermitCategory_Name: "Customer Tactics Assistant",
    StaffArea: "Ethyl Raynor",
    StaffOrg: "Aurore Koelpin",
    FeesOutstanding: "884.00",
    PrintStatus: "demand-driven",
    Status: "analyzer",
    Permit_DisplayStatus: "one-to-one",
    PermitHolder_Email: "Ted_Hettinger86@hotmail.com",
    ReferenceNumber: 6870,
    PermitHolder: "Elsie Mraz",
    CreatedDate: "Sat Jan 25 2020 04:44:53 GMT+0700 (Indochina Time)",
    FeeType: "Strategist",
    AmountIssued: "429.00",
    AmountOutstanding: 707,
    CostCode: 79819,
    InvoiceNumber: 19222,
    SecurePay_Reference: 31263,
    AmountPaid: "66802",
    ReceiptNumber: 17506,
    ReceiptDate: "Fri May 22 2020 12:54:11 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Tavares Emmerich",
    Method: "Global",
    SentToFinanceDate: "Tue Sep 15 2020 20:34:53 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 47364,
    Sys_DBRowState: "Trafficway",
    ResidentAreaCode: 40937,
    PermitRenewalDate: "Sun Jun 07 2020 15:08:39 GMT+0700 (Indochina Time)",
    MaximumPermits: 71569,
    NoOfFreePermits: 18367,
    NoOfCost1: 16758,
    Cost1: 74510,
    NoOfCost2: 22815,
    Cost2: 18873,
    NoOfCost3: 95776,
    Cost3: 65415,
    AllowableSuburbs: "North Myrtice",
    AreaBoundaries: "Pfefferborough",
    Area: "North Westleyshire",
    Code: 81970,
    Type: "B2C",
    RenewalDate: "Mon Feb 17 2020 10:48:32 GMT+0700 (Indochina Time)",
    FullAddress: "New Viva",
    AssessmentNo: 6890,
    Reason: "Latvia",
    Sys_CreatedBy: "Mr. Callie Emard",
    PermitSubType: "Assistant",
    PropertyType: "Engineer",
    Org: "Checking Account",
    Street: "024 Fernando Ridges",
    RenewalStatus: "Data",
    LetterSent: "Prairie Borders",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 86409,
    PermitType_Name: "Technician",
    PermitHolder_DisplayName: "Olin",
    StreetName: "6136 Kuvalis Road",
    Suburb: "Norberto40",
    Address_Search: "Apt. 619",
    Permit_Status: "Egyptian Pound",
    Permit_SubStatus: "dolor nulla Excepteur",
    IssuedDate: "Mon Aug 30 2021 08:43:43 GMT+0700 (Indochina Time)",
    ExpiryDate: "Fri May 14 2021 07:01:26 GMT+0700 (Indochina Time)",
    PermitNumber: 77066,
    RegNo: 65034,
    PermitArea: "Interactions",
    ZoneCode: 76860,
    ZoneName: "Paucekville",
    PermitCategory_Name: "Principal Applications Administrator",
    StaffArea: "Dayne Kertzmann",
    StaffOrg: "Mose Brown",
    FeesOutstanding: "122.00",
    PrintStatus: "calculating",
    Status: "generating",
    Permit_DisplayStatus: "Marketing",
    PermitHolder_Email: "Obie41@hotmail.com",
    ReferenceNumber: 83573,
    PermitHolder: "Ida Becker",
    CreatedDate: "Mon Mar 16 2020 20:07:59 GMT+0700 (Indochina Time)",
    FeeType: "Consultant",
    AmountIssued: "362.00",
    AmountOutstanding: 313,
    CostCode: 38647,
    InvoiceNumber: 2496,
    SecurePay_Reference: 73648,
    AmountPaid: "13984",
    ReceiptNumber: 62898,
    ReceiptDate: "Sat Feb 01 2020 10:03:50 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Vinnie Rohan V",
    Method: "Corporate",
    SentToFinanceDate: "Fri Nov 06 2020 16:56:29 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 95460,
    Sys_DBRowState: "Grocery",
    ResidentAreaCode: 68931,
    PermitRenewalDate: "Thu Dec 03 2020 01:22:16 GMT+0700 (Indochina Time)",
    MaximumPermits: 63295,
    NoOfFreePermits: 23615,
    NoOfCost1: 73608,
    Cost1: 8002,
    NoOfCost2: 22640,
    Cost2: 63802,
    NoOfCost3: 8999,
    Cost3: 35251,
    AllowableSuburbs: "East Giuseppe",
    AreaBoundaries: "New Jenifer",
    Area: "Lucianotown",
    Code: 2786,
    Type: "parsing",
    RenewalDate: "Mon Dec 14 2020 23:31:31 GMT+0700 (Indochina Time)",
    FullAddress: "Stoltenbergburgh",
    AssessmentNo: 67458,
    Reason: "connecting",
    Sys_CreatedBy: "Simeon Gerlach",
    PermitSubType: "Representative",
    PropertyType: "Associate",
    Org: "evolve",
    Street: "03986 Devin Hollow",
    RenewalStatus: "bypassing",
    LetterSent: "override functionalities",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 75106,
    PermitType_Name: "Coordinator",
    PermitHolder_DisplayName: "Shayne",
    StreetName: "6324 Schimmel Islands",
    Suburb: "Dina_Runolfsdottir2",
    Address_Search: "Apt. 500",
    Permit_Status: "Costa Rican Colon",
    Permit_SubStatus: "mollit occaecat ad",
    IssuedDate: "Mon Aug 09 2021 20:02:57 GMT+0700 (Indochina Time)",
    ExpiryDate: "Tue Jun 15 2021 05:02:36 GMT+0700 (Indochina Time)",
    PermitNumber: 74941,
    RegNo: 19338,
    PermitArea: "Branding",
    ZoneCode: 97582,
    ZoneName: "Lake Cindy",
    PermitCategory_Name: "Direct Security Executive",
    StaffArea: "Janelle Goodwin II",
    StaffOrg: "Logan Ritchie",
    FeesOutstanding: "619.00",
    PrintStatus: "navigating",
    Status: "Practical",
    Permit_DisplayStatus: "metrics",
    PermitHolder_Email: "Benny_Roob80@gmail.com",
    ReferenceNumber: 34302,
    PermitHolder: "Lonie McLaughlin Sr.",
    CreatedDate: "Mon Mar 09 2020 21:48:22 GMT+0700 (Indochina Time)",
    FeeType: "Coordinator",
    AmountIssued: "318.00",
    AmountOutstanding: 306,
    CostCode: 73635,
    InvoiceNumber: 17442,
    SecurePay_Reference: 70324,
    AmountPaid: "39931",
    ReceiptNumber: 57759,
    ReceiptDate: "Sun May 17 2020 12:27:15 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Beth Goldner",
    Method: "Senior",
    SentToFinanceDate: "Fri Aug 14 2020 09:05:29 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 87753,
    Sys_DBRowState: "Sudanese Pound",
    ResidentAreaCode: 73924,
    PermitRenewalDate: "Fri Sep 25 2020 09:34:35 GMT+0700 (Indochina Time)",
    MaximumPermits: 67996,
    NoOfFreePermits: 95381,
    NoOfCost1: 99337,
    Cost1: 4405,
    NoOfCost2: 63763,
    Cost2: 57238,
    NoOfCost3: 96868,
    Cost3: 12654,
    AllowableSuburbs: "Douglasfurt",
    AreaBoundaries: "North Katharinaview",
    Area: "Boganport",
    Code: 14204,
    Type: "Solutions",
    RenewalDate: "Thu Mar 12 2020 03:25:45 GMT+0700 (Indochina Time)",
    FullAddress: "Ashleymouth",
    AssessmentNo: 69501,
    Reason: "Mouse",
    Sys_CreatedBy: "Jaden Halvorson",
    PermitSubType: "Planner",
    PropertyType: "Director",
    Org: "withdrawal",
    Street: "3578 Hammes Forge",
    RenewalStatus: "bypassing",
    LetterSent: "Borders",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 32307,
    PermitType_Name: "Analyst",
    PermitHolder_DisplayName: "Mekhi",
    StreetName: "0206 Labadie Loop",
    Suburb: "Maude44",
    Address_Search: "Suite 506",
    Permit_Status: "ROI",
    Permit_SubStatus: "sint minim nulla",
    IssuedDate: "Wed Feb 24 2021 11:04:46 GMT+0700 (Indochina Time)",
    ExpiryDate: "Sun Feb 28 2021 23:09:49 GMT+0700 (Indochina Time)",
    PermitNumber: 37164,
    RegNo: 69475,
    PermitArea: "Accountability",
    ZoneCode: 32861,
    ZoneName: "South Adriana",
    PermitCategory_Name: "Direct Usability Consultant",
    StaffArea: "Zackary Pfeffer",
    StaffOrg: "Ethan McGlynn",
    FeesOutstanding: "501.00",
    PrintStatus: "Streets",
    Status: "West Virginia",
    Permit_DisplayStatus: "Health",
    PermitHolder_Email: "Allan.Pagac89@yahoo.com",
    ReferenceNumber: 67403,
    PermitHolder: "Mrs. Tatyana Durgan",
    CreatedDate: "Mon Nov 02 2020 22:44:09 GMT+0700 (Indochina Time)",
    FeeType: "Consultant",
    AmountIssued: "125.00",
    AmountOutstanding: 348,
    CostCode: 65504,
    InvoiceNumber: 96988,
    SecurePay_Reference: 73711,
    AmountPaid: "88164",
    ReceiptNumber: 91335,
    ReceiptDate: "Fri Jul 24 2020 08:54:41 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Willa Daniel",
    Method: "Principal",
    SentToFinanceDate: "Fri Aug 28 2020 07:58:49 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 55952,
    Sys_DBRowState: "customized",
    ResidentAreaCode: 38413,
    PermitRenewalDate: "Wed Mar 18 2020 02:57:08 GMT+0700 (Indochina Time)",
    MaximumPermits: 8992,
    NoOfFreePermits: 64917,
    NoOfCost1: 49910,
    Cost1: 8597,
    NoOfCost2: 53200,
    Cost2: 79953,
    NoOfCost3: 24993,
    Cost3: 99672,
    AllowableSuburbs: "Port Monserrate",
    AreaBoundaries: "Kadechester",
    Area: "New Joannestad",
    Code: 39701,
    Type: "AGP",
    RenewalDate: "Wed Apr 15 2020 14:46:35 GMT+0700 (Indochina Time)",
    FullAddress: "South Jackeline",
    AssessmentNo: 73210,
    Reason: "groupware",
    Sys_CreatedBy: "Roselyn Mills",
    PermitSubType: "Specialist",
    PropertyType: "Engineer",
    Org: "seamless",
    Street: "32033 Julius Village",
    RenewalStatus: "visionary",
    LetterSent: "online Corporate Tuna",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 58447,
    PermitType_Name: "Director",
    PermitHolder_DisplayName: "Fatima",
    StreetName: "52765 Molly Mill",
    Suburb: "Gabrielle_Rohan16",
    Address_Search: "Apt. 626",
    Permit_Status: "Configuration",
    Permit_SubStatus: "sit cupidatat est tempor dolor",
    IssuedDate: "Sat Jul 17 2021 00:12:38 GMT+0700 (Indochina Time)",
    ExpiryDate: "Mon May 10 2021 02:36:30 GMT+0700 (Indochina Time)",
    PermitNumber: 33536,
    RegNo: 47871,
    PermitArea: "Usability",
    ZoneCode: 36311,
    ZoneName: "Cartermouth",
    PermitCategory_Name: "Central Configuration Developer",
    StaffArea: "Miss Karina Klocko",
    StaffOrg: "Mrs. Mollie Dooley",
    FeesOutstanding: "121.00",
    PrintStatus: "Fresh",
    Status: "Identity",
    Permit_DisplayStatus: "Road",
    PermitHolder_Email: "Nelson67@gmail.com",
    ReferenceNumber: 26431,
    PermitHolder: "Sibyl Hartmann",
    CreatedDate: "Tue Feb 11 2020 15:24:31 GMT+0700 (Indochina Time)",
    FeeType: "Liason",
    AmountIssued: "89.00",
    AmountOutstanding: 981,
    CostCode: 39696,
    InvoiceNumber: 20419,
    SecurePay_Reference: 8981,
    AmountPaid: "3600",
    ReceiptNumber: 77419,
    ReceiptDate: "Sat Dec 12 2020 06:33:48 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Jade Rohan I",
    Method: "Customer",
    SentToFinanceDate: "Mon Feb 10 2020 03:18:33 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 60060,
    Sys_DBRowState: "Implementation",
    ResidentAreaCode: 64634,
    PermitRenewalDate: "Sat Sep 26 2020 00:27:10 GMT+0700 (Indochina Time)",
    MaximumPermits: 96190,
    NoOfFreePermits: 22382,
    NoOfCost1: 89490,
    Cost1: 82238,
    NoOfCost2: 85235,
    Cost2: 3409,
    NoOfCost3: 72415,
    Cost3: 66784,
    AllowableSuburbs: "Merrittshire",
    AreaBoundaries: "East Roxanne",
    Area: "South Joesph",
    Code: 87690,
    Type: "e-services",
    RenewalDate: "Sat May 02 2020 03:31:48 GMT+0700 (Indochina Time)",
    FullAddress: "Port Arely",
    AssessmentNo: 66573,
    Reason: "Rubber",
    Sys_CreatedBy: "Erin Mohr",
    PermitSubType: "Designer",
    PropertyType: "Agent",
    Org: "Gorgeous Soft Shirt",
    Street: "55610 Deckow Ramp",
    RenewalStatus: "Niue",
    LetterSent: "indexing Congolese Franc",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 67146,
    PermitType_Name: "Developer",
    PermitHolder_DisplayName: "Jayce",
    StreetName: "597 Joseph Via",
    Suburb: "Rory_Toy54",
    Address_Search: "Suite 538",
    Permit_Status: "eyeballs",
    Permit_SubStatus: "proident voluptate labore quis",
    IssuedDate: "Wed Feb 10 2021 07:11:16 GMT+0700 (Indochina Time)",
    ExpiryDate: "Sat Jan 30 2021 08:44:57 GMT+0700 (Indochina Time)",
    PermitNumber: 11461,
    RegNo: 21045,
    PermitArea: "Program",
    ZoneCode: 53166,
    ZoneName: "Purdychester",
    PermitCategory_Name: "Senior Group Strategist",
    StaffArea: "Skyla Kertzmann MD",
    StaffOrg: "Mrs. Taurean Little",
    FeesOutstanding: "167.00",
    PrintStatus: "archive",
    Status: "infrastructures",
    Permit_DisplayStatus: "bottom-line",
    PermitHolder_Email: "Hyman.Gulgowski70@gmail.com",
    ReferenceNumber: 45764,
    PermitHolder: "Andres Rutherford IV",
    CreatedDate: "Thu Sep 10 2020 00:17:48 GMT+0700 (Indochina Time)",
    FeeType: "Designer",
    AmountIssued: "6.00",
    AmountOutstanding: 642,
    CostCode: 66362,
    InvoiceNumber: 19482,
    SecurePay_Reference: 49628,
    AmountPaid: "48493",
    ReceiptNumber: 46054,
    ReceiptDate: "Tue Jan 28 2020 00:10:49 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Trever Boehm",
    Method: "Legacy",
    SentToFinanceDate: "Fri Sep 11 2020 10:09:08 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 91453,
    Sys_DBRowState: "zero administration",
    ResidentAreaCode: 43400,
    PermitRenewalDate: "Sat Oct 03 2020 05:07:18 GMT+0700 (Indochina Time)",
    MaximumPermits: 95787,
    NoOfFreePermits: 66432,
    NoOfCost1: 72009,
    Cost1: 99115,
    NoOfCost2: 99478,
    Cost2: 47900,
    NoOfCost3: 87849,
    Cost3: 52883,
    AllowableSuburbs: "Erikport",
    AreaBoundaries: "Corwinmouth",
    Area: "West Roxanne",
    Code: 59030,
    Type: "end-to-end",
    RenewalDate: "Sat Jun 20 2020 05:52:48 GMT+0700 (Indochina Time)",
    FullAddress: "West Zakarybury",
    AssessmentNo: 45424,
    Reason: "Refined Granite Shoes",
    Sys_CreatedBy: "Vincenza Marvin Jr.",
    PermitSubType: "Manager",
    PropertyType: "Director",
    Org: "bypass",
    Street: "1311 Aileen Springs",
    RenewalStatus: "Small Rubber Bike",
    LetterSent: "Solutions Personal Loan Account",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 50176,
    PermitType_Name: "Planner",
    PermitHolder_DisplayName: "Demario",
    StreetName: "5348 Reyes Land",
    Suburb: "Mellie.Boyle2",
    Address_Search: "Apt. 814",
    Permit_Status: "Small Metal Ball",
    Permit_SubStatus: "quis",
    IssuedDate: "Sun Sep 05 2021 12:09:14 GMT+0700 (Indochina Time)",
    ExpiryDate: "Tue Dec 14 2021 09:40:08 GMT+0700 (Indochina Time)",
    PermitNumber: 98254,
    RegNo: 45131,
    PermitArea: "Mobility",
    ZoneCode: 80597,
    ZoneName: "Hickleberg",
    PermitCategory_Name: "Human Optimization Engineer",
    StaffArea: "Amira Orn",
    StaffOrg: "Emory Nicolas",
    FeesOutstanding: "21.00",
    PrintStatus: "recontextualize",
    Status: "copy",
    Permit_DisplayStatus: "Shoes",
    PermitHolder_Email: "Amani.Luettgen24@gmail.com",
    ReferenceNumber: 72980,
    PermitHolder: "Madyson Littel",
    CreatedDate: "Thu Feb 20 2020 04:06:29 GMT+0700 (Indochina Time)",
    FeeType: "Liason",
    AmountIssued: "273.00",
    AmountOutstanding: 438,
    CostCode: 13078,
    InvoiceNumber: 76338,
    SecurePay_Reference: 13583,
    AmountPaid: "59684",
    ReceiptNumber: 93333,
    ReceiptDate: "Sun Apr 26 2020 08:00:14 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Shaylee Torphy",
    Method: "Corporate",
    SentToFinanceDate: "Tue Jan 21 2020 12:41:53 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 83928,
    Sys_DBRowState: "paradigms",
    ResidentAreaCode: 23347,
    PermitRenewalDate: "Sun Feb 09 2020 07:30:25 GMT+0700 (Indochina Time)",
    MaximumPermits: 20187,
    NoOfFreePermits: 13288,
    NoOfCost1: 72397,
    Cost1: 24130,
    NoOfCost2: 35190,
    Cost2: 82750,
    NoOfCost3: 69166,
    Cost3: 11002,
    AllowableSuburbs: "West Ressieport",
    AreaBoundaries: "New Nelsonshire",
    Area: "Harveytown",
    Code: 33634,
    Type: "gold",
    RenewalDate: "Thu Dec 10 2020 20:40:21 GMT+0700 (Indochina Time)",
    FullAddress: "Charitymouth",
    AssessmentNo: 45459,
    Reason: "online",
    Sys_CreatedBy: "Allene Kirlin",
    PermitSubType: "Agent",
    PropertyType: "Manager",
    Org: "capacitor",
    Street: "56883 Padberg Pike",
    RenewalStatus: "Nevada",
    LetterSent: "monitor viral deposit",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 11527,
    PermitType_Name: "Technician",
    PermitHolder_DisplayName: "Odell",
    StreetName: "82386 Rutherford Row",
    Suburb: "Alta.Bogisich97",
    Address_Search: "Apt. 052",
    Permit_Status: "Personal Loan Account",
    Permit_SubStatus: "quis commodo",
    IssuedDate: "Wed Dec 30 2020 12:27:49 GMT+0700 (Indochina Time)",
    ExpiryDate: "Wed Jun 30 2021 21:30:19 GMT+0700 (Indochina Time)",
    PermitNumber: 36741,
    RegNo: 33376,
    PermitArea: "Accountability",
    ZoneCode: 77724,
    ZoneName: "Friedaberg",
    PermitCategory_Name: "District Directives Orchestrator",
    StaffArea: "Clara Jerde DVM",
    StaffOrg: "Trudie Cole",
    FeesOutstanding: "777.00",
    PrintStatus: "Technician",
    Status: "dynamic",
    Permit_DisplayStatus: "XML",
    PermitHolder_Email: "Stephen14@gmail.com",
    ReferenceNumber: 46596,
    PermitHolder: "Kaycee Gerlach III",
    CreatedDate: "Sat Jun 13 2020 09:12:16 GMT+0700 (Indochina Time)",
    FeeType: "Supervisor",
    AmountIssued: "16.00",
    AmountOutstanding: 246,
    CostCode: 53907,
    InvoiceNumber: 83407,
    SecurePay_Reference: 79489,
    AmountPaid: "61685",
    ReceiptNumber: 98419,
    ReceiptDate: "Sun Jul 12 2020 23:57:27 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Mr. Gordon Schulist",
    Method: "Forward",
    SentToFinanceDate: "Sat Nov 21 2020 20:26:36 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 32786,
    Sys_DBRowState: "Concrete",
    ResidentAreaCode: 31406,
    PermitRenewalDate: "Wed Jun 17 2020 22:50:18 GMT+0700 (Indochina Time)",
    MaximumPermits: 86926,
    NoOfFreePermits: 190,
    NoOfCost1: 35642,
    Cost1: 25915,
    NoOfCost2: 23170,
    Cost2: 88615,
    NoOfCost3: 17003,
    Cost3: 96638,
    AllowableSuburbs: "Port Tamiaborough",
    AreaBoundaries: "New Tyreltown",
    Area: "New Tony",
    Code: 29722,
    Type: "Exclusive",
    RenewalDate: "Sat Sep 05 2020 23:27:22 GMT+0700 (Indochina Time)",
    FullAddress: "Briaton",
    AssessmentNo: 58288,
    Reason: "overriding",
    Sys_CreatedBy: "Eloy Murphy",
    PermitSubType: "Architect",
    PropertyType: "Representative",
    Org: "auxiliary",
    Street: "070 Marietta Estate",
    RenewalStatus: "Decentralized",
    LetterSent: "programming",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 52886,
    PermitType_Name: "Specialist",
    PermitHolder_DisplayName: "Rosamond",
    StreetName: "32540 Harvey Terrace",
    Suburb: "Rolando.Schneider",
    Address_Search: "Suite 256",
    Permit_Status: "SAS",
    Permit_SubStatus: "irure consequat velit eu",
    IssuedDate: "Sat Sep 11 2021 05:53:38 GMT+0700 (Indochina Time)",
    ExpiryDate: "Fri Dec 10 2021 22:39:31 GMT+0700 (Indochina Time)",
    PermitNumber: 78116,
    RegNo: 90548,
    PermitArea: "Division",
    ZoneCode: 15463,
    ZoneName: "New Emmybury",
    PermitCategory_Name: "Central Assurance Specialist",
    StaffArea: "Mrs. Paolo Bartell",
    StaffOrg: "Miss Frances Douglas",
    FeesOutstanding: "202.00",
    PrintStatus: "online",
    Status: "encryption",
    Permit_DisplayStatus: "teal",
    PermitHolder_Email: "Warren_Ortiz@gmail.com",
    ReferenceNumber: 84299,
    PermitHolder: "Jamaal Donnelly",
    CreatedDate: "Mon Jan 20 2020 02:30:39 GMT+0700 (Indochina Time)",
    FeeType: "Developer",
    AmountIssued: "168.00",
    AmountOutstanding: 885,
    CostCode: 610,
    InvoiceNumber: 72417,
    SecurePay_Reference: 19298,
    AmountPaid: "82353",
    ReceiptNumber: 76073,
    ReceiptDate: "Fri Nov 20 2020 19:34:10 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Cleve Kassulke",
    Method: "Internal",
    SentToFinanceDate: "Sun Jan 19 2020 11:00:09 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 25038,
    Sys_DBRowState: "Pennsylvania",
    ResidentAreaCode: 47212,
    PermitRenewalDate: "Tue Nov 10 2020 19:42:13 GMT+0700 (Indochina Time)",
    MaximumPermits: 22539,
    NoOfFreePermits: 20238,
    NoOfCost1: 8287,
    Cost1: 21464,
    NoOfCost2: 59607,
    Cost2: 23993,
    NoOfCost3: 31396,
    Cost3: 62825,
    AllowableSuburbs: "Missourishire",
    AreaBoundaries: "West Gavinberg",
    Area: "Lake Baronburgh",
    Code: 61348,
    Type: "Vanuatu",
    RenewalDate: "Tue Oct 06 2020 03:26:58 GMT+0700 (Indochina Time)",
    FullAddress: "Quitzonhaven",
    AssessmentNo: 80940,
    Reason: "Team-oriented",
    Sys_CreatedBy: "Judge Hessel",
    PermitSubType: "Specialist",
    PropertyType: "Associate",
    Org: "contextually-based",
    Street: "2288 Gislason Course",
    RenewalStatus: "Slovenia",
    LetterSent: "infrastructures Administrator",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 65617,
    PermitType_Name: "Representative",
    PermitHolder_DisplayName: "Katrina",
    StreetName: "198 Denesik Way",
    Suburb: "Drew_Langworth84",
    Address_Search: "Suite 933",
    Permit_Status: "Libyan Arab Jamahiriya",
    Permit_SubStatus: "ut consectetur deserunt aliquip exercitation",
    IssuedDate: "Fri Oct 22 2021 16:32:44 GMT+0700 (Indochina Time)",
    ExpiryDate: "Fri Jan 01 2021 00:24:30 GMT+0700 (Indochina Time)",
    PermitNumber: 44679,
    RegNo: 68294,
    PermitArea: "Factors",
    ZoneCode: 73919,
    ZoneName: "East Queenbury",
    PermitCategory_Name: "Lead Communications Analyst",
    StaffArea: "Althea Feest",
    StaffOrg: "Herminio Brakus III",
    FeesOutstanding: "800.00",
    PrintStatus: "azure",
    Status: "Borders",
    Permit_DisplayStatus: "JBOD",
    PermitHolder_Email: "Nayeli_Treutel@yahoo.com",
    ReferenceNumber: 15387,
    PermitHolder: "Lavada Lakin",
    CreatedDate: "Thu Mar 12 2020 06:11:01 GMT+0700 (Indochina Time)",
    FeeType: "Representative",
    AmountIssued: "86.00",
    AmountOutstanding: 515,
    CostCode: 80099,
    InvoiceNumber: 49415,
    SecurePay_Reference: 92039,
    AmountPaid: "13517",
    ReceiptNumber: 41589,
    ReceiptDate: "Mon Jun 01 2020 23:32:37 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Lilliana Ruecker",
    Method: "Senior",
    SentToFinanceDate: "Sun Oct 25 2020 22:26:52 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 75801,
    Sys_DBRowState: "Investment Account",
    ResidentAreaCode: 46150,
    PermitRenewalDate: "Sat Dec 12 2020 00:52:58 GMT+0700 (Indochina Time)",
    MaximumPermits: 60122,
    NoOfFreePermits: 32023,
    NoOfCost1: 945,
    Cost1: 32736,
    NoOfCost2: 5109,
    Cost2: 87653,
    NoOfCost3: 15924,
    Cost3: 46904,
    AllowableSuburbs: "Lake Marilouview",
    AreaBoundaries: "Port Annamarieland",
    Area: "Jonesberg",
    Code: 59940,
    Type: "Granite",
    RenewalDate: "Tue Feb 11 2020 03:32:56 GMT+0700 (Indochina Time)",
    FullAddress: "Wolffland",
    AssessmentNo: 81748,
    Reason: "Lead",
    Sys_CreatedBy: "Sonia Douglas",
    PermitSubType: "Administrator",
    PropertyType: "Associate",
    Org: "HTTP",
    Street: "616 Hermann Place",
    RenewalStatus: "Checking Account",
    LetterSent: "Sleek Frozen Table Universal navigating",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 78713,
    PermitType_Name: "Representative",
    PermitHolder_DisplayName: "Darren",
    StreetName: "57519 Senger Flat",
    Suburb: "Sedrick53",
    Address_Search: "Suite 003",
    Permit_Status: "invoice",
    Permit_SubStatus: "consectetur ipsum qui Excepteur sed",
    IssuedDate: "Fri Dec 10 2021 18:22:56 GMT+0700 (Indochina Time)",
    ExpiryDate: "Wed Feb 03 2021 18:23:12 GMT+0700 (Indochina Time)",
    PermitNumber: 74547,
    RegNo: 20904,
    PermitArea: "Integration",
    ZoneCode: 8119,
    ZoneName: "North Cadeland",
    PermitCategory_Name: "Regional Response Supervisor",
    StaffArea: "Kathleen Kilback",
    StaffOrg: "Magnolia Stehr",
    FeesOutstanding: "429.00",
    PrintStatus: "hack",
    Status: "Beauty",
    Permit_DisplayStatus: "leverage",
    PermitHolder_Email: "Madyson_Kunde65@gmail.com",
    ReferenceNumber: 88323,
    PermitHolder: "Maribel Medhurst DVM",
    CreatedDate: "Sun Jul 05 2020 12:33:50 GMT+0700 (Indochina Time)",
    FeeType: "Director",
    AmountIssued: "707.00",
    AmountOutstanding: 49,
    CostCode: 16556,
    InvoiceNumber: 97903,
    SecurePay_Reference: 40433,
    AmountPaid: "95375",
    ReceiptNumber: 38107,
    ReceiptDate: "Mon Apr 27 2020 07:58:29 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Brando Erdman",
    Method: "Senior",
    SentToFinanceDate: "Fri Nov 06 2020 04:31:59 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 52602,
    Sys_DBRowState: "Gloves",
    ResidentAreaCode: 21790,
    PermitRenewalDate: "Sun Apr 12 2020 14:36:37 GMT+0700 (Indochina Time)",
    MaximumPermits: 47404,
    NoOfFreePermits: 21161,
    NoOfCost1: 72012,
    Cost1: 85176,
    NoOfCost2: 29458,
    Cost2: 34788,
    NoOfCost3: 63441,
    Cost3: 32921,
    AllowableSuburbs: "New Stephanieton",
    AreaBoundaries: "Boyleberg",
    Area: "Waelchitown",
    Code: 72630,
    Type: "1080p",
    RenewalDate: "Sun Feb 16 2020 12:02:28 GMT+0700 (Indochina Time)",
    FullAddress: "Lake Ashleighstad",
    AssessmentNo: 29810,
    Reason: "Rand Namibia Dollar",
    Sys_CreatedBy: "Evelyn Hoeger",
    PermitSubType: "Associate",
    PropertyType: "Associate",
    Org: "Pre-emptive",
    Street: "1598 Wolff Mills",
    RenewalStatus: "Kids",
    LetterSent: "port programming Zloty",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 80000,
    PermitType_Name: "Developer",
    PermitHolder_DisplayName: "Sofia",
    StreetName: "7625 Connor Estates",
    Suburb: "Alec64",
    Address_Search: "Apt. 409",
    Permit_Status: "Pants",
    Permit_SubStatus: "adipisicing velit",
    IssuedDate: "Wed Dec 22 2021 12:36:25 GMT+0700 (Indochina Time)",
    ExpiryDate: "Sat Apr 24 2021 06:54:54 GMT+0700 (Indochina Time)",
    PermitNumber: 39714,
    RegNo: 82763,
    PermitArea: "Research",
    ZoneCode: 50225,
    ZoneName: "South Vincenzabury",
    PermitCategory_Name: "Future Accounts Technician",
    StaffArea: "Rosalyn Kerluke",
    StaffOrg: "Carlos Botsford",
    FeesOutstanding: "958.00",
    PrintStatus: "Home",
    Status: "payment",
    Permit_DisplayStatus: "SQL",
    PermitHolder_Email: "Ruth.Ullrich26@yahoo.com",
    ReferenceNumber: 39962,
    PermitHolder: "Colleen Bauch",
    CreatedDate: "Sun Oct 04 2020 01:47:04 GMT+0700 (Indochina Time)",
    FeeType: "Facilitator",
    AmountIssued: "690.00",
    AmountOutstanding: 576,
    CostCode: 39356,
    InvoiceNumber: 29176,
    SecurePay_Reference: 94735,
    AmountPaid: "13258",
    ReceiptNumber: 9976,
    ReceiptDate: "Fri May 08 2020 02:53:55 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Dewayne Wisozk",
    Method: "Dynamic",
    SentToFinanceDate: "Tue Nov 17 2020 22:58:08 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 97014,
    Sys_DBRowState: "internet solution",
    ResidentAreaCode: 42663,
    PermitRenewalDate: "Wed Dec 09 2020 18:13:54 GMT+0700 (Indochina Time)",
    MaximumPermits: 76302,
    NoOfFreePermits: 97521,
    NoOfCost1: 25602,
    Cost1: 80099,
    NoOfCost2: 82745,
    Cost2: 74761,
    NoOfCost3: 78245,
    Cost3: 23277,
    AllowableSuburbs: "North Daveton",
    AreaBoundaries: "West Donnaland",
    Area: "McDermottview",
    Code: 13821,
    Type: "bandwidth",
    RenewalDate: "Mon Nov 30 2020 03:54:08 GMT+0700 (Indochina Time)",
    FullAddress: "Rosschester",
    AssessmentNo: 71226,
    Reason: "installation",
    Sys_CreatedBy: "Chanel Block",
    PermitSubType: "Specialist",
    PropertyType: "Engineer",
    Org: "Oregon",
    Street: "109 Eichmann Estates",
    RenewalStatus: "Bedfordshire",
    LetterSent: "Liason Jewelery payment",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 97232,
    PermitType_Name: "Designer",
    PermitHolder_DisplayName: "Taurean",
    StreetName: "7256 Avery Fields",
    Suburb: "Blake_Gulgowski52",
    Address_Search: "Suite 942",
    Permit_Status: "Principal",
    Permit_SubStatus: "nostrud dolor aliqua id",
    IssuedDate: "Fri Oct 29 2021 06:22:25 GMT+0700 (Indochina Time)",
    ExpiryDate: "Thu Oct 28 2021 20:28:19 GMT+0700 (Indochina Time)",
    PermitNumber: 87873,
    RegNo: 96401,
    PermitArea: "Assurance",
    ZoneCode: 39215,
    ZoneName: "Beatriceborough",
    PermitCategory_Name: "Customer Usability Strategist",
    StaffArea: "Kyla Mayer",
    StaffOrg: "Stephanie Klein",
    FeesOutstanding: "8.00",
    PrintStatus: "back up",
    Status: "Sleek Frozen Fish",
    Permit_DisplayStatus: "teal",
    PermitHolder_Email: "Brad_Prohaska59@hotmail.com",
    ReferenceNumber: 99183,
    PermitHolder: "Ms. Katelynn Emmerich",
    CreatedDate: "Fri Oct 23 2020 04:23:11 GMT+0700 (Indochina Time)",
    FeeType: "Liason",
    AmountIssued: "356.00",
    AmountOutstanding: 515,
    CostCode: 93900,
    InvoiceNumber: 5164,
    SecurePay_Reference: 5707,
    AmountPaid: "22650",
    ReceiptNumber: 35162,
    ReceiptDate: "Thu Mar 26 2020 03:18:43 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Kody Bogisich",
    Method: "Central",
    SentToFinanceDate: "Sat Feb 08 2020 01:48:30 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 63333,
    Sys_DBRowState: "supply-chains",
    ResidentAreaCode: 98627,
    PermitRenewalDate: "Mon Nov 02 2020 14:07:18 GMT+0700 (Indochina Time)",
    MaximumPermits: 70088,
    NoOfFreePermits: 47149,
    NoOfCost1: 80288,
    Cost1: 89426,
    NoOfCost2: 55025,
    Cost2: 13732,
    NoOfCost3: 6694,
    Cost3: 19248,
    AllowableSuburbs: "Dickensport",
    AreaBoundaries: "Willaland",
    Area: "Strackeside",
    Code: 68107,
    Type: "24/365",
    RenewalDate: "Sat Jun 13 2020 14:09:37 GMT+0700 (Indochina Time)",
    FullAddress: "Port Verdieport",
    AssessmentNo: 18065,
    Reason: "Plastic",
    Sys_CreatedBy: "Judy Witting II",
    PermitSubType: "Producer",
    PropertyType: "Representative",
    Org: "Developer",
    Street: "73972 Jabari Run",
    RenewalStatus: "Concrete",
    LetterSent: "Shoes",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 54134,
    PermitType_Name: "Strategist",
    PermitHolder_DisplayName: "Ottis",
    StreetName: "159 Gutkowski Ridge",
    Suburb: "Eulalia23",
    Address_Search: "Apt. 791",
    Permit_Status: "Dynamic",
    Permit_SubStatus: "nisi",
    IssuedDate: "Wed Mar 17 2021 22:36:01 GMT+0700 (Indochina Time)",
    ExpiryDate: "Thu Jul 22 2021 21:11:28 GMT+0700 (Indochina Time)",
    PermitNumber: 88219,
    RegNo: 63591,
    PermitArea: "Directives",
    ZoneCode: 60383,
    ZoneName: "Demetriston",
    PermitCategory_Name: "Product Accounts Director",
    StaffArea: "Citlalli Koch",
    StaffOrg: "Josefa Schowalter",
    FeesOutstanding: "803.00",
    PrintStatus: "Concrete",
    Status: "Bedfordshire",
    Permit_DisplayStatus: "Concrete",
    PermitHolder_Email: "Margarete93@yahoo.com",
    ReferenceNumber: 64025,
    PermitHolder: "Miss Nash Feest",
    CreatedDate: "Fri Dec 25 2020 21:10:22 GMT+0700 (Indochina Time)",
    FeeType: "Officer",
    AmountIssued: "730.00",
    AmountOutstanding: 475,
    CostCode: 15834,
    InvoiceNumber: 80303,
    SecurePay_Reference: 81887,
    AmountPaid: "55078",
    ReceiptNumber: 12097,
    ReceiptDate: "Wed Sep 02 2020 22:22:09 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Deanna O'Connell",
    Method: "Direct",
    SentToFinanceDate: "Wed Feb 12 2020 09:37:52 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 56363,
    Sys_DBRowState: "Avon",
    ResidentAreaCode: 51473,
    PermitRenewalDate: "Sun Aug 23 2020 13:32:00 GMT+0700 (Indochina Time)",
    MaximumPermits: 39550,
    NoOfFreePermits: 32530,
    NoOfCost1: 26803,
    Cost1: 6412,
    NoOfCost2: 67809,
    Cost2: 98329,
    NoOfCost3: 89768,
    Cost3: 58192,
    AllowableSuburbs: "Brendantown",
    AreaBoundaries: "North Camryn",
    Area: "Britneyborough",
    Code: 12517,
    Type: "service-desk",
    RenewalDate: "Thu Jan 30 2020 15:59:56 GMT+0700 (Indochina Time)",
    FullAddress: "Port Edwardoville",
    AssessmentNo: 45331,
    Reason: "Ergonomic Fresh Car",
    Sys_CreatedBy: "Jadyn Farrell",
    PermitSubType: "Officer",
    PropertyType: "Facilitator",
    Org: "Tuna",
    Street: "3332 Myriam Ville",
    RenewalStatus: "invoice",
    LetterSent: "withdrawal Steel Arizona",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 99076,
    PermitType_Name: "Planner",
    PermitHolder_DisplayName: "Athena",
    StreetName: "2894 Reichert Drives",
    Suburb: "Bridget.Kshlerin",
    Address_Search: "Suite 086",
    Permit_Status: "Bedfordshire",
    Permit_SubStatus: "labore",
    IssuedDate: "Sun Dec 05 2021 16:50:20 GMT+0700 (Indochina Time)",
    ExpiryDate: "Fri Sep 24 2021 05:51:23 GMT+0700 (Indochina Time)",
    PermitNumber: 46139,
    RegNo: 61899,
    PermitArea: "Web",
    ZoneCode: 59948,
    ZoneName: "Dakotabury",
    PermitCategory_Name: "Legacy Functionality Representative",
    StaffArea: "Miss Osbaldo Koch",
    StaffOrg: "Jana Kris",
    FeesOutstanding: "29.00",
    PrintStatus: "Identity",
    Status: "payment",
    Permit_DisplayStatus: "Iranian Rial",
    PermitHolder_Email: "Cleo.Kirlin@gmail.com",
    ReferenceNumber: 61325,
    PermitHolder: "Leora Baumbach",
    CreatedDate: "Sun Jul 05 2020 06:34:46 GMT+0700 (Indochina Time)",
    FeeType: "Agent",
    AmountIssued: "303.00",
    AmountOutstanding: 46,
    CostCode: 74179,
    InvoiceNumber: 585,
    SecurePay_Reference: 23782,
    AmountPaid: "51453",
    ReceiptNumber: 3240,
    ReceiptDate: "Fri May 22 2020 12:09:39 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Mr. America Skiles",
    Method: "Senior",
    SentToFinanceDate: "Sat Feb 15 2020 04:37:14 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 571,
    Sys_DBRowState: "cross-platform",
    ResidentAreaCode: 49716,
    PermitRenewalDate: "Fri Jul 03 2020 05:31:30 GMT+0700 (Indochina Time)",
    MaximumPermits: 20859,
    NoOfFreePermits: 62144,
    NoOfCost1: 362,
    Cost1: 20162,
    NoOfCost2: 22316,
    Cost2: 49125,
    NoOfCost3: 10579,
    Cost3: 65119,
    AllowableSuburbs: "Port Marianna",
    AreaBoundaries: "New Nicholas",
    Area: "West Ubaldochester",
    Code: 92505,
    Type: "Bike",
    RenewalDate: "Fri Jan 17 2020 17:20:49 GMT+0700 (Indochina Time)",
    FullAddress: "Bryceborough",
    AssessmentNo: 7980,
    Reason: "Illinois",
    Sys_CreatedBy: "Stefan Hoeger",
    PermitSubType: "Specialist",
    PropertyType: "Supervisor",
    Org: "index",
    Street: "096 Green Inlet",
    RenewalStatus: "Mexico",
    LetterSent: "scale Greens",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 55563,
    PermitType_Name: "Coordinator",
    PermitHolder_DisplayName: "Alfonso",
    StreetName: "145 Bechtelar Courts",
    Suburb: "Alyson93",
    Address_Search: "Apt. 880",
    Permit_Status: "auxiliary",
    Permit_SubStatus: "nostrud elit",
    IssuedDate: "Thu Aug 19 2021 19:57:39 GMT+0700 (Indochina Time)",
    ExpiryDate: "Thu May 20 2021 08:28:48 GMT+0700 (Indochina Time)",
    PermitNumber: 65579,
    RegNo: 12098,
    PermitArea: "Integration",
    ZoneCode: 4284,
    ZoneName: "Bellaland",
    PermitCategory_Name: "District Branding Assistant",
    StaffArea: "Jakob Kerluke",
    StaffOrg: "Miss Mya Dickens",
    FeesOutstanding: "784.00",
    PrintStatus: "tertiary",
    Status: "wireless",
    Permit_DisplayStatus: "Checking Account",
    PermitHolder_Email: "Easter_Bednar57@gmail.com",
    ReferenceNumber: 9448,
    PermitHolder: "Doyle Kuphal",
    CreatedDate: "Fri Apr 17 2020 12:11:04 GMT+0700 (Indochina Time)",
    FeeType: "Analyst",
    AmountIssued: "754.00",
    AmountOutstanding: 8,
    CostCode: 15270,
    InvoiceNumber: 59826,
    SecurePay_Reference: 19604,
    AmountPaid: "21156",
    ReceiptNumber: 83440,
    ReceiptDate: "Wed Dec 23 2020 18:37:41 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Linnie Greenfelder",
    Method: "Forward",
    SentToFinanceDate: "Sat Nov 07 2020 16:34:57 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 47298,
    Sys_DBRowState: "Savings Account",
    ResidentAreaCode: 98547,
    PermitRenewalDate: "Tue Nov 03 2020 20:01:14 GMT+0700 (Indochina Time)",
    MaximumPermits: 11771,
    NoOfFreePermits: 5648,
    NoOfCost1: 62975,
    Cost1: 40458,
    NoOfCost2: 75903,
    Cost2: 3635,
    NoOfCost3: 2791,
    Cost3: 17518,
    AllowableSuburbs: "West Goldaland",
    AreaBoundaries: "Greenholtchester",
    Area: "Wunschborough",
    Code: 45968,
    Type: "digital",
    RenewalDate: "Tue Nov 10 2020 04:09:49 GMT+0700 (Indochina Time)",
    FullAddress: "Lake Lelahmouth",
    AssessmentNo: 72331,
    Reason: "intermediate",
    Sys_CreatedBy: "Adriel Rohan Jr.",
    PermitSubType: "Producer",
    PropertyType: "Liason",
    Org: "iterate",
    Street: "8848 Wendy Keys",
    RenewalStatus: "Personal Loan Account",
    LetterSent: "array Refined lime",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 78608,
    PermitType_Name: "Producer",
    PermitHolder_DisplayName: "Norval",
    StreetName: "25452 Ward Orchard",
    Suburb: "Henri.Fahey",
    Address_Search: "Suite 127",
    Permit_Status: "Trail",
    Permit_SubStatus: "Lorem ut adipisicing Excepteur tempor",
    IssuedDate: "Fri Jan 01 2021 21:40:29 GMT+0700 (Indochina Time)",
    ExpiryDate: "Sun Jun 06 2021 15:12:03 GMT+0700 (Indochina Time)",
    PermitNumber: 30260,
    RegNo: 89379,
    PermitArea: "Accounts",
    ZoneCode: 43391,
    ZoneName: "Spinkaton",
    PermitCategory_Name: "National Division Architect",
    StaffArea: "Cindy Kozey",
    StaffOrg: "Narciso Ledner",
    FeesOutstanding: "243.00",
    PrintStatus: "programming",
    Status: "seize",
    Permit_DisplayStatus: "end-to-end",
    PermitHolder_Email: "Dayana.Emard@hotmail.com",
    ReferenceNumber: 20205,
    PermitHolder: "Loren Romaguera",
    CreatedDate: "Mon Sep 14 2020 22:48:12 GMT+0700 (Indochina Time)",
    FeeType: "Officer",
    AmountIssued: "614.00",
    AmountOutstanding: 843,
    CostCode: 46005,
    InvoiceNumber: 40603,
    SecurePay_Reference: 31330,
    AmountPaid: "39647",
    ReceiptNumber: 42664,
    ReceiptDate: "Fri Dec 27 2019 12:11:15 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Hunter Labadie",
    Method: "Legacy",
    SentToFinanceDate: "Wed Feb 19 2020 15:42:20 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 35352,
    Sys_DBRowState: "mindshare",
    ResidentAreaCode: 45897,
    PermitRenewalDate: "Fri May 15 2020 01:59:04 GMT+0700 (Indochina Time)",
    MaximumPermits: 73651,
    NoOfFreePermits: 40581,
    NoOfCost1: 29505,
    Cost1: 72765,
    NoOfCost2: 27455,
    Cost2: 71421,
    NoOfCost3: 96384,
    Cost3: 25598,
    AllowableSuburbs: "East Asha",
    AreaBoundaries: "Lake Dario",
    Area: "Port Kevonland",
    Code: 5363,
    Type: "quantify",
    RenewalDate: "Tue Apr 07 2020 20:30:39 GMT+0700 (Indochina Time)",
    FullAddress: "Robertschester",
    AssessmentNo: 47425,
    Reason: "incremental",
    Sys_CreatedBy: "Judge Muller",
    PermitSubType: "Liason",
    PropertyType: "Associate",
    Org: "TCP",
    Street: "6209 Erin Ferry",
    RenewalStatus: "Kentucky",
    LetterSent: "South Dakota solid state",
  },
  {
    PermitNo: "C16622",
    PermitType: "Business-focused",
    DebtorNumber: 64276,
    FileNumber: 14590,
    OSFees: 60008,
    Status_ENUM: "Current",
    ContactPermitHolder: "(955) 852-3448 x4663",
    ContactPerson: "Madelyn Cremin",
    ContactNumPerson: "1-765-486-4663",
    PropertyNumber: 44739,
    PostalAddress: "548 Gibson Overpass",
    PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
    IsSameAsPermitAddress: true,
    IsPropertyAddress: false,
    OnBehalfOf: true,
    IssueDate: "2017-06-30",
    IssueTime: "2017-06-30T23:00:00.782Z",
    ExpiryTime: "2017-06-30T23:00:00.782Z",
    ID: 20809,
    PermitType_Name: "Analyst",
    PermitHolder_DisplayName: "Gino",
    StreetName: "6863 Oma Trail",
    Suburb: "Finn30",
    Address_Search: "Suite 628",
    Permit_Status: "Sleek",
    Permit_SubStatus: "nisi ex laborum",
    IssuedDate: "Mon Mar 22 2021 21:22:18 GMT+0700 (Indochina Time)",
    ExpiryDate: "Mon May 31 2021 08:09:14 GMT+0700 (Indochina Time)",
    PermitNumber: 56432,
    RegNo: 54318,
    PermitArea: "Intranet",
    ZoneCode: 69459,
    ZoneName: "Lake Eldredland",
    PermitCategory_Name: "Forward Identity Manager",
    StaffArea: "Freeman Kozey",
    StaffOrg: "Zetta Barton III",
    FeesOutstanding: "662.00",
    PrintStatus: "Kentucky",
    Status: "visionary",
    Permit_DisplayStatus: "Mandatory",
    PermitHolder_Email: "Oda_Parker82@yahoo.com",
    ReferenceNumber: 19838,
    PermitHolder: "Lincoln Windler",
    CreatedDate: "Thu Feb 27 2020 12:56:21 GMT+0700 (Indochina Time)",
    FeeType: "Developer",
    AmountIssued: "26.00",
    AmountOutstanding: 117,
    CostCode: 11301,
    InvoiceNumber: 24227,
    SecurePay_Reference: 60837,
    AmountPaid: "18234",
    ReceiptNumber: 33998,
    ReceiptDate: "Fri Jul 03 2020 01:18:18 GMT+0700 (Indochina Time)",
    ReceiptedBy: "Jayson Stanton",
    Method: "Chief",
    SentToFinanceDate: "Thu May 21 2020 07:50:09 GMT+0700 (Indochina Time)",
    SentToFinanceBatch: 95991,
    Sys_DBRowState: "productize",
    ResidentAreaCode: 14049,
    PermitRenewalDate: "Sun Jul 26 2020 18:36:56 GMT+0700 (Indochina Time)",
    MaximumPermits: 99886,
    NoOfFreePermits: 19288,
    NoOfCost1: 28923,
    Cost1: 65486,
    NoOfCost2: 61949,
    Cost2: 20098,
    NoOfCost3: 65249,
    Cost3: 5392,
    AllowableSuburbs: "New Carmella",
    AreaBoundaries: "North Avery",
    Area: "Hyattport",
    Code: 70009,
    Type: "bus",
    RenewalDate: "Mon Feb 24 2020 14:46:35 GMT+0700 (Indochina Time)",
    FullAddress: "Chrisberg",
    AssessmentNo: 89546,
    Reason: "Bedfordshire",
    Sys_CreatedBy: "Rogers Reilly",
    PermitSubType: "Producer",
    PropertyType: "Coordinator",
    Org: "Concrete",
    Street: "11217 Zemlak Isle",
    RenewalStatus: "content",
    LetterSent: "national orchid Auto Loan Account",
  },
];

export const mockParkingPermitRecords: IParkingPermitsRecordsState = {
  actions: 0,
  comments: 0,
  documents: 0,
  fees: 0,
  inspections: 0,
  samples: 0,
};

export const mockParkingPermitContactTab: ICommonContactItemRender[] = [
  {
    ID: "C16622",
    Type: "Permits Holder",
    DisplayName: "Cruz Aufderhar",
    WorkPhone: "",
    Mobile: "(955) 852-3448 x4663",
    PostalAddress: "360 Derrimut Street, HOPPERS CROSSING VIC 3029",
    PreferredMethod_ENUM: "0",
    PreferredMethod_Name: "None",
  },
];
