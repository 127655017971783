import { odataCoreGetDynamicQuestionListView } from "@app/core/components/common/utils";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { DynamicListsHistoryTab } from "@common/pages/settings/lookups/dynamic-lists/_id/components/reference-sidebar/history/_index";
import { DeleteDynamicQuestionButton } from "@common/pages/settings/lookups/dynamic-lists/components/action-bar/buttons/delete-dynamic-question/_index";
import { NewDynamicQuestionListsButton } from "@common/pages/settings/lookups/dynamic-lists/components/action-bar/buttons/new-dynamic-question/_index";
import { dynamicListGrigColumn } from "@common/pages/settings/lookups/dynamic-lists/config";
import { DYNAMIC_LISTS_ROUTE } from "@common/pages/settings/lookups/dynamic-lists/constant";
import { Svc_DynamicQuestionView } from "@common/pages/settings/lookups/dynamic-lists/model";
import {
  dynamicListsBookmark,
  dynamicListsDetailsBookmark,
} from "@common/pages/settings/lookups/dynamic-lists/util";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<Svc_DynamicQuestionView>();

export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_ManageDynamicQuestionList,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [
      <NewDynamicQuestionListsButton
        disabled={
          !hasPermission || !checkPermissions(FormAction.CORE_ALLOW_NEW)
        }
      />,
      <DeleteDynamicQuestionButton
        disabled={
          !hasPermission || !checkPermissions(FormAction.CORE_ALLOW_DELETE)
        }
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={DYNAMIC_LISTS_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.Core_Bookmark}
        detail={dynamicListsDetailsBookmark.getBookmarkDetail}
        displayName={dynamicListsDetailsBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          dynamicListsBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={dynamicListsBookmark.getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "History",
        component: <DynamicListsHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper
      isLoadingPermission={isLoadingPermission || isLoading}
    >
      <CCProductListView
        columnFields={dynamicListGrigColumn}
        primaryField={nameOf("ID")}
        dataUrl={odataCoreGetDynamicQuestionListView()}
        disabled={!hasPermission}
      />
    </LoadingPermissionWrapper>
  );
});
