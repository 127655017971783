import { AnimalsMenu } from "@app/products/animals/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const inspectionsRoute: ICCRoute = {
  path: "inspections",
  name: "Inspections",
  enum: AnimalsMenu.Inspections,
  children: [
    {
      path: "by-date",
      name: "By Date",
      enum: AnimalsMenu.InspectionsByDate,
      component: require("./by-date/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      enum: AnimalsMenu.InspectionsByType,
      component: require("./by-type/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      enum: AnimalsMenu.InspectionsByOfficer,
      component: require("./by-officer/_index").default,
    },
    {
      path: "by-month",
      name: "By Month",
      enum: AnimalsMenu.InspectionsByMonth,
      component: require("./by-month/_index").default,
    },
    {
      path: "by-area",
      name: "By Area",
      enum: AnimalsMenu.InspectionsByArea,
      component: require("./by-area/_index").default,
    },
  ],
};
