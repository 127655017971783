import {
  DTO_Workflow_EditChangeOfOwnership,
  RequestEditChangeOfOwnershipObj,
} from "@app/products/property/changes-of-ownership/components/form-steps/edit-change-of-ownership/model";

import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postProcessEditChangeOfOwnership = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_EditChangeOfOwnership
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_EditChangeOfOwnership>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/int/workflow/editchangeofOwnership/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getInitialEditChangeOfOwnership = async (
  workflowDraftId: number | undefined = 0,
  isFromActionList: boolean = false,
  changeOfOwnershipId: number | null = null
): Promise<APIResponse<DTO_Workflow_EditChangeOfOwnership>> => {
  try {
    if (isFromActionList) {
      return await getWorkflowEditChangeOfOwnership(workflowDraftId);
    } else {
      return await getNewWorkflowEditChangeOfOwnership({
        Change_Of_Ownership_Id: changeOfOwnershipId,
      });
    }
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowEditChangeOfOwnership = async (
  payload: RequestEditChangeOfOwnershipObj
): Promise<APIResponse<DTO_Workflow_EditChangeOfOwnership>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/workflow/editchangeofOwnership/new`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowEditChangeOfOwnership = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_EditChangeOfOwnership>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/int/workflow/editchangeofOwnership/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
