import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";
export const RecordsECM = observer(() => {
  const { isEditing } = useSettingGlobalManageStore();
  const { getConfigDataField } = useSettingGlobalStore();

  const fieldECMConnectionString = getConfigDataField(
    ECorporateSettingsField.ecmAdmin_LoginConnectionString
  );
  const fieldECMuserName = getConfigDataField(
    ECorporateSettingsField.ecmAdmin_Username
  );
  const fieldECMPassword = getConfigDataField(
    ECorporateSettingsField.ecmAdmin_Password
  );
  const fieldECMSQLQuery = getConfigDataField(
    ECorporateSettingsField.ecmAdmin_LoginSQL
  );
  const fieldECMDASName = getConfigDataField(
    ECorporateSettingsField.ecmAdmin_DASServerName
  );
  const fieldECMPropertyLaunchPath = getConfigDataField(
    ECorporateSettingsField.ecmAdmin_PropertyLaunchUrl
  );
  const fieldECMLaunchPath = getConfigDataField(
    ECorporateSettingsField.ecmAdmin_LaunchUrl
  );
  const fieldECMWebServerWorkPath = getConfigDataField(
    ECorporateSettingsField.ecmAdmin_WebServerWorkPath
  );
  const fieldECMPropertyCriteriaName = getConfigDataField(
    ECorporateSettingsField.ecmAdmin_CriteriaProperty
  );

  return (
    <>
      {fieldECMConnectionString && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings
            data={fieldECMConnectionString}
            isEditing={isEditing}
          />
        </div>
      )}

      {fieldECMuserName && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings data={fieldECMuserName} isEditing={isEditing} />
        </div>
      )}

      {fieldECMPassword && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings data={fieldECMPassword} isEditing={isEditing} />
        </div>
      )}

      {fieldECMSQLQuery && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings data={fieldECMSQLQuery} isEditing={isEditing} />
        </div>
      )}

      {fieldECMDASName && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings data={fieldECMDASName} isEditing={isEditing} />
        </div>
      )}

      {fieldECMPropertyLaunchPath && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings
            data={fieldECMPropertyLaunchPath}
            isEditing={isEditing}
          />
        </div>
      )}

      {fieldECMLaunchPath && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings
            data={fieldECMLaunchPath}
            isEditing={isEditing}
          />
        </div>
      )}

      {fieldECMWebServerWorkPath && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings
            data={fieldECMWebServerWorkPath}
            isEditing={isEditing}
          />
        </div>
      )}

      {fieldECMPropertyCriteriaName && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings
            data={fieldECMPropertyCriteriaName}
            isEditing={isEditing}
          />
        </div>
      )}
    </>
  );
});
