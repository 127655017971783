import { Application_Status } from "@app/products/town-planning/ppr/constant";

export const psarApplicationForm = {
  Status_ENUM: Application_Status.NewApplication,
  Sys_CreatedDate: new Date(),
  Date_Created: new Date(),
  Flag_EnforcementIssue: false,
  Flag_PreApplicationMeeting: false,
  Flag_PrivateSurveyor: false,
  Flag_PlanningPrivateSurveyor: false,
  Flag_LandDivision: false,
  Flag_PlansToComply: false,
  Flag_IsPSA: true,
  Flag_IsOA: false,
  BuildingDetails: {
    Building_StageNumber: "0",
    Building_TotalFloorArea: 0,
    Building_BCA: [],
    ReportingAuthorities: [],
    Building_BCAVersion: [],
    Building_SpecificWork: [],
    Building_BuildingWorks_Display: [],
    Building_UseOfBuildings: [],
  },
  AssessmentPlanner: { AssessmentPlanner_Images: [] },
  StatDays: {
    StatDays: 0,
    GrossDays: 0,
    AppealDays: 0,
    TargetDays: 0,
    StartDateDescription: "",
    EndDateDescription: "",
    StartDate: null,
    TargetDate: null,
  },
  PPRDetails: {},
  AdditionalAddresses: [],
  BuildingWorks: {
    Nature: {
      IsPNF: false,
      IsLandManagement: false,
      WorkFlowAutoActionTypes: [],
      ApplicationCategoryFees: [],
      ApplicationAssessment: [],
    },
    Use: {},
    Class: {},
  },
  ApplicationCategories: [],
  PrescribeEvents: { Sent_Counter: 0 },
  IsAmendment: false,
  Flag_IsStateGovtApp: false,
};
