import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { Svc_InfoExpertContainer } from "@common/models/corporateSetting";
import { nameOfFactory } from "@common/utils/common";
import { ICCGridProps } from "@components/cc-grid/_index";

export const getInfoExpertContainerInputPickerOption = (
  option?: Partial<IOptionInputPicker>,
  grid?: Partial<ICCGridProps>
): IOptionInputPicker => {
  const nameOfInfoXpertContainer = nameOfFactory<Svc_InfoExpertContainer>();

  return {
    dialog: {
      titleHeader: option?.dialog?.titleHeader ?? "infoXpert Folders",
      maxWidth: option?.dialog?.maxWidth ?? "60%",
    },
    boxSearch: option?.boxSearch ?? {
      colSearch: nameOfInfoXpertContainer("Name"),
      colShowComboboxSearch: [
        {
          title: "Name",
          field: nameOfInfoXpertContainer("Name"),
        },
      ],
    },
    grid: {
      columnFields: [
        { title: "Name", field: nameOfInfoXpertContainer("Name") },
        {
          title: "Folder Path",
          field: nameOfInfoXpertContainer("Description"),
        },
      ],
      primaryField: nameOfInfoXpertContainer("ID"),
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: [{ field: nameOfInfoXpertContainer("Name"), dir: "asc" }],
      },
      ...grid,
    },
  };
};
