import { FIRE_PREVENTION_ROUTE } from "@app/products/property/fire-prevention/hazld-case/[id]/constant";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colInfringements: IColumnFields[] = [
  {
    field: "CaseId",
    title: "Case ID",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => {
      return `${FIRE_PREVENTION_ROUTE}/` + dataItem.CaseId;
    },
    locked: true,
  },
  {
    field: "InfringementId",
    title: "Infringement ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: "InfringementType", title: "Infringement Type" },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  { field: "PropertyAddress", title: "Property Address" },
  { field: "NameAndAddress", title: "Name and Address" },
  { field: "Clearance", title: "Clearance" },
  {
    field: "NoticeSentOn",
    title: "Notice Sent On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "ToBeClearedBy",
    title: "To Be Cleared By",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "CreatedOn",
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "CreatedBy",
    title: "Created By",
  },
  {
    field: "Period",
    title: "Period",
  },
  {
    field: "ParcelId",
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "NoticeId",
    title: "Notice ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
