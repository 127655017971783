import { NewAssessmentTransactionCommonButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/new-transaction/common/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export const NewAssessmentTransactionButton = observer(() => {
  const { gridSelectedRows } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const listAssessmentID = useMemo(() => {
    return gridSelectedRows?.map((item) => item.Assessment_Id) ?? [];
  }, [gridSelectedRows]);

  return (
    <NewAssessmentTransactionCommonButton
      isDisabled={id ? false : gridSelectedRows.length < 1}
      assessmentIDs={id ? [+id] : listAssessmentID}
    />
  );
});
