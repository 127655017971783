import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
import { colNotices } from "./config";
import { mockNotices } from "./mock";
interface INoticesGrid {
  setNoticeGridSelectData: (data: any) => void;
}
export const NoticesGrid = ({ setNoticeGridSelectData }: INoticesGrid) => {
  return (
    <>
      <label className="cc-control-item cc-label">Notices</label>
      <CCGrid
        data={mockNotices}
        columnFields={colNotices}
        onSelectionChange={(data: any) => setNoticeGridSelectData(data)}
        selectableMode={"single"}
        primaryField="NoticeId"
      />
    </>
  );
};
