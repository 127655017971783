import { getJournalSessionListDetailTab } from "@app/products/property/journals/session-list/[id]/components/reference-sidebar/detail/api";
import { DTO_JournalSession_TabDetails } from "@app/products/property/journals/session-list/[id]/components/reference-sidebar/detail/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class JournalSessionDetailsStore {
  private _journalSessionDetails?: DTO_JournalSession_TabDetails = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get journalSessionNumber() {
    return this._journalSessionDetails?.SessionNumber;
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get journalSessionDetails() {
    return toJS(this._journalSessionDetails);
  }
  setJournalSessionDetails = (
    journalSessionDetails: DTO_JournalSession_TabDetails | undefined
  ) => {
    runInAction(() => {
      this._journalSessionDetails = journalSessionDetails;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._journalSessionDetails = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadJournalSessionDetails = async (id: number) => {
    this.setIsLoading(true);
    const response = await getJournalSessionListDetailTab(id);

    let errorResponse = undefined;
    let newJournalSessionDetails = undefined;
    if (isSuccessResponse(response)) {
      newJournalSessionDetails = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setJournalSessionDetails(newJournalSessionDetails);
    this.setIsLoading(false);
  };

  reLoadJournalSessionDetails = async () => {
    if (this.journalSessionNumber) {
      await this.loadJournalSessionDetails(this.journalSessionNumber);
    }
  };
}

const JournalSessionDetailsStoreContext = createContext(
  new JournalSessionDetailsStore()
);
export const useJournalSessionDetailsStore = () => {
  return useContext(JournalSessionDetailsStoreContext);
};
