import { EAnimalSettingsCategory } from "@app/products/animals/system-admin/settings/_id/main/model";
import { IRouteStateForm } from "@app/products/animals/system-admin/settings/_id/main/routeState";

export const recordsRouteStateAnimalsSettings: IRouteStateForm[] = [
  {
    name: EAnimalSettingsCategory.Records_AttachmentTitleSettings,
    component: require("./forms/attachment-title-settings/_index").default,
  },
  {
    name: EAnimalSettingsCategory.Records_AnimalSettings,
    component: require("./forms/animal-settings/_index").default,
  },
  {
    name: EAnimalSettingsCategory.Records_KennelsSettings,
    component: require("./forms/kennels-settings/_index").default,
  },
];
