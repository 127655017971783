import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { PPRDashboard } from "@common/pages/home/components/townplanning/model";

export const getPPRDashboard = async (): Promise<APIResponse<PPRDashboard>> => {
  try {
    return await CoreAPIService.getClient().get<PPRDashboard>(
      `/api/tp/ppr/internal/dashboard`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
