import {
  PermitDebtorSummaryTypes,
  PermitFees,
} from "@app/products/local-laws/finance/model";

export const llFinanceBookmark = (viewName: string) => {
  return {
    listViewDisplayURL() {
      return "Local Laws - Finance";
    },

    listViewDisplayTitle() {
      return `Local Laws - Finance - ${viewName}`;
    },

    recordDisplayURL(selectedRow: PermitFees) {
      const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
      return `Local Laws - ${viewName} ${dynamicDisplayName}`;
    },
  };
};

export const getLocalLawsDebtorSummary = (
  viewEnum: PermitDebtorSummaryTypes
) => {
  return `odata/locallaw/internal/llfinances/GetLLPermitsFinancesView(viewEnum=${viewEnum})?$count=true&`;
};
