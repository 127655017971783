import { Journal } from "@app/core/journal/_index";
import { useApplicationCategoriesStore } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const PPRApplicationCategoryHistoryTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { applicationCategories } = useApplicationCategoriesStore();
  const params: { id: string } = useParams();
  const pprId: number =
    lastSelectedRow && lastSelectedRow?.ID
      ? lastSelectedRow?.ID
      : parseInt(params.id);
  return (
    <Journal
      id={pprId}
      triggersReload={applicationCategories}
      recordType={RECORDTYPE.TP_ApplicationCategory}
    />
  );
});
