import { ICCNotification } from "@components/cc-notification/components/cc-notification-item/model";
import {
  action,
  computed,
  configure,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from "mobx";
import React, { createContext } from "react";
configure({ enforceActions: "always" });

class PropertySchemeNewButtonStore {
  private _notification: ICCNotification[] = [];

  setNotification = (notification: ICCNotification[]) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  resetNotification = () => {
    runInAction(() => {
      this._notification = [];
    });
  };

  constructor() {
    makeObservable<PropertySchemeNewButtonStore, "_notification">(this, {
      _notification: observable,
      setNotification: action,
      resetNotification: action,
      notification: computed,
    });
  }

  get notification(): ICCNotification[] {
    return toJS(this._notification);
  }
}

const propertySchemeNewButtonStore = createContext(
  new PropertySchemeNewButtonStore()
);

export const usePropertySchemeNewButtonStore = () => {
  const context = React.useContext(propertySchemeNewButtonStore);
  if (context === undefined) {
    throw new Error(
      "usePropertySchemeNewButtonStore in not within PropertySchemeNewButtonStore"
    );
  }
  return context;
};
