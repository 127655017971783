import { ITemperature } from "@app/core/temperature/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<ITemperature>();
export const colTemperature: IColumnFields[] = [
  {
    field: nameOf("Recorded"),
    title: "Recorded",
  },
  {
    field: nameOf("Appliance"),
    title: "Appliance",
  },
  {
    field: nameOf("Temperature"),
    title: "Temperature",
  },
  {
    field: nameOf("Notes"),
    title: "Notes",
  },
];
