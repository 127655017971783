import { IGISReferenceGrid } from "@app/products/property/registers/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IGISReferenceGrid>();

export const colGISReference: IColumnFields[] = [
  {
    field: nameOf("GISReference"),
    title: "GIS Reference",
  },
];
