import { DTO_AssociatedItem_Generic } from "@app/products/property/components/associations/components/entity-name-and-address/model";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_Generic>();

export const colAssociationsEntityNameAndAddress: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    linkTo: (dataItem: DTO_AssociatedItem_Generic) =>
      `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem.Entity_Id}`,
  },
  { field: nameOf("AssociationType"), title: "Association Type" },
  { field: nameOf("Association"), title: "Association" },
  {
    field: nameOf("Role_or_AssociationType"),
    title: "Role or Association Type",
  },
  { field: nameOf("Component"), title: "Component" },
  { field: nameOf("Item"), title: "Item" },
  { field: nameOf("ComponentId"), title: "Component ID" },
  { field: nameOf("Address"), title: "Address" },
  {
    field: nameOf("Id"),
    title: "Contact Name & Address ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Entity_Id"),
    title: "Contact ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
