import { loadViewConfiguresColumns } from "@app/products/property/api";
import { getAssociateAssessment } from "@app/products/property/assessments/[id]/components/child-screens/associate-assessment/api";
import { associateAssessmentCol } from "@app/products/property/assessments/[id]/components/child-screens/associate-assessment/config";
import { DTO_Assessment_AssociatedAssessment } from "@app/products/property/assessments/[id]/components/child-screens/associate-assessment/model";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class AssociateAssessmentStore {
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _associateAssessment?: DTO_Assessment_AssociatedAssessment =
    undefined;
  private _colAssociateAssessment?: IColumnFields[] = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get associateAssessment() {
    return this._associateAssessment;
  }

  setAssociateAssessment = (
    associateAssessment?: DTO_Assessment_AssociatedAssessment
  ) => {
    runInAction(() => {
      this._associateAssessment = associateAssessment;
    });
  };

  get colAssociateAssessment() {
    return this._colAssociateAssessment;
  }

  setColAssociateAssessment = (colAssociateAssessment?: IColumnFields[]) => {
    runInAction(() => {
      this._colAssociateAssessment = colAssociateAssessment;
    });
  };

  get isLoading() {
    return toJS(this._isLoading);
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._associateAssessment = undefined;
      this._colAssociateAssessment = undefined;
    });
  };

  loadAssociateAssessment = async (assessmentId: number) => {
    this.setIsLoading(true);
    this.setResponseLoadError(undefined);

    const [associateData, associateCol] = await Promise.all([
      getAssociateAssessment(assessmentId || 0),
      loadViewConfiguresColumns(
        ViewConfiguration.Associations_Assessment,
        associateAssessmentCol
      ),
    ]);
    if (isSuccessResponse(associateData) && Array.isArray(associateCol)) {
      this.setAssociateAssessment(associateData?.data);
      this.setColAssociateAssessment(associateCol);
    } else {
      this.setResponseLoadError({
        status: associateData.status,
        error: associateData.error ?? "Load associate data failed",
      });
    }
    this.setIsLoading(false);
  };
}

const associateAssessmentStoreContext = createContext(
  new AssociateAssessmentStore()
);

export const useAssociateAssessmentStore = () => {
  return useContext(associateAssessmentStoreContext);
};
