import {
  ANIMAL_DANGEROUS_DOGS_URL,
  ANIMAL_REGISTRATIONS_URL,
} from "@common/pages/home/components/animals/constant";
import { useAnimalsDashboardStore } from "@common/pages/home/components/animals/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

const AnimalsActivity = observer(() => {
  const history = useHistory();
  const { animalDashboard, isLoadingAnimalsDashboard } =
    useAnimalsDashboardStore();

  if (isLoadingAnimalsDashboard) return <Loading isLoading />;

  return (
    <div className="cc-activity-group">
      <div className="cc-activity-group-title">ANIMALS</div>
      {animalDashboard?.Activities ? (
        <div className="cc-activity-group-container">
          <div
            className="cc-activity-item"
            onClick={() => {
              history.push(ANIMAL_REGISTRATIONS_URL);
            }}
          >
            <div className="cc-activity-name">REGISTRATIONS</div>
            <div className="cc-activity-number">
              {animalDashboard.Activities?.TotalAnimalsRegistration}
            </div>
          </div>
          <div
            className="cc-activity-item"
            onClick={() => {
              history.push(ANIMAL_DANGEROUS_DOGS_URL);
            }}
          >
            <div className="cc-activity-name">DANGEROUS DOGS</div>
            <div className="cc-activity-number">
              {animalDashboard.Activities?.TotalDangerousDogs}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
});

export default AnimalsActivity;
