import { ICCRoute } from "@common/constants/ICCRoute";
export const applicationCategoriesRoute: ICCRoute = {
  path: "application-categories",
  name: "Application Categories",
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
