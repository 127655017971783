import { useInspScheduleCalendarStore } from "@app/products/building/insp-schedule/calendar/store";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import React, { useState } from "react";
import "./_index.scss";

export const CalendarDropdown = () => {
  const { setBuildingCalendarFilter }: any = useInspScheduleCalendarStore();
  const [selectedBuildingCalendarFilter, setSelectedBuildingCalendarFilter] =
    useState({
      key: "everyone",
      title: "Everyone",
    });

  const onBuildingCalenderFilterChange = (event: any) => {
    const cloneData: any = event.target.value;
    setSelectedBuildingCalendarFilter(cloneData);
    setBuildingCalendarFilter(event.target.value);
  };
  const buildingCalendarFilterData = [
    {
      key: "everyone",
      title: "Everyone",
    },
    {
      key: "messi",
      title: "Leo Messi",
    },
    {
      key: "alexander",
      title: "Alexander Ryan",
    },
    {
      key: "abby",
      title: "Abby Dickson",
    },
    {
      key: "agata",
      title: "Agata Snelling",
    },
  ];
  return (
    <div className="cc-action-bar-nav-dropdown">
      <DropDownList
        data={buildingCalendarFilterData}
        textField="title"
        dataItemKey="key"
        defaultValue={selectedBuildingCalendarFilter}
        className="cc-navbar-dropdown"
        onChange={onBuildingCalenderFilterChange}
      />
    </div>
  );
};
