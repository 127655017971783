import { getDisplayTextWithDashes } from "@common/utils/common";

export const BuildingPoolsOrSpasBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Building - Pools Or Spas ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return (
      getDisplayTextWithDashes([
        selectedRow?.Register_Number,
        selectedRow?.Contact,
      ]) || "N/A"
    );
  },
};
