import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { renderTrimNewComponents } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/config";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import {
  TRIMNUMBERINGMETHOD,
  TRIMSTORAGEMETHOD,
} from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export const RecordsTrimApplicationForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const { valueGetter, onChange } = formRenderProps;
    const showNewTrim =
      parseInt(
        valueGetter(
          ECorporateSettingsField.TRIMAdmin_TownPlanningStorageMethod.toString()
        )
      ) === TRIMSTORAGEMETHOD.CREATE;

    const showTrimNumberPrefix =
      parseInt(
        valueGetter(
          ECorporateSettingsField.TRIMAdmin_TownPlanningNumberMethod.toString()
        )
      ) === TRIMNUMBERINGMETHOD.SYSTEM;

    const showDisplayAllRecords =
      valueGetter(
        ECorporateSettingsField.TRIMAdmin_EnableSubFolderSystem.toString()
      ) === true;

    if (!configData) return <></>;
    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <CCSearchComboBoxGlobalSettings
            data={
              configData[
                ECorporateSettingsField.TRIMAdmin_TownPlanningStorageMethod
              ]
            }
            isEditing={isEditing}
          />
          {showNewTrim && (
            <>
              {renderTrimNewComponents(
                formRenderProps,
                isEditing,
                configData[
                  ECorporateSettingsField
                    .TRIMAdmin_TownPlanningPrimaryContainerName
                ],
                configData[
                  ECorporateSettingsField.TRIMAdmin_TownPlanningClassification
                ],
                configData[
                  ECorporateSettingsField
                    .TRIMAdmin_TownPlanningPrimaryRecordType
                ]
              )}
              <InputGlobalSettings
                data={
                  configData[
                    ECorporateSettingsField.TRIMAdmin_TownPlanningTitleFormat
                  ]
                }
                isEditing={isEditing}
              />
              <CCSearchComboBoxGlobalSettings
                data={
                  configData[
                    ECorporateSettingsField.TRIMAdmin_TownPlanningNumberMethod
                  ]
                }
                isEditing={isEditing}
              />
              {showTrimNumberPrefix && (
                <>
                  <InputGlobalSettings
                    data={
                      configData[
                        ECorporateSettingsField
                          .TRIMAdmin_TownPlanningNumberPrefix
                      ]
                    }
                    isEditing={isEditing}
                  />
                  <CCSearchComboBoxGlobalSettings
                    data={
                      configData[
                        ECorporateSettingsField
                          .TRIMAdmin_TownPlanningSystemNumberingMethod
                      ]
                    }
                    isEditing={isEditing}
                  />
                </>
              )}
              <SwitchGlobalSettings
                data={
                  configData[
                    ECorporateSettingsField.TRIMAdmin_TP_AppUseSubdivFileNo
                  ]
                }
                isEditing={isEditing}
                formRenderProps={formRenderProps}
              />
              <SwitchGlobalSettings
                data={
                  configData[
                    ECorporateSettingsField.TRIMAdmin_EnableSubFolderSystem
                  ]
                }
                isEditing={isEditing}
                formRenderProps={formRenderProps}
                onChange={(e: SwitchChangeEvent) => {
                  onChange(
                    ECorporateSettingsField.TRIMAdmin_EnableSubFolderSystem.toString(),
                    { value: e.value }
                  );
                  if (e.value) {
                    onChange(
                      ECorporateSettingsField.TRIMAdmin_TP_SecConsentInheritApplicationFileNumber.toString(),
                      { value: e.value }
                    );
                    onChange(
                      ECorporateSettingsField.TRIMAdmin_TP_EOTInheritApplicationFileNumber.toString(),
                      { value: e.value }
                    );
                    onChange(
                      ECorporateSettingsField.TRIMAdmin_TP_PlansToComplyInheritApplicationFileNumber.toString(),
                      { value: e.value }
                    );
                  }
                }}
              />
              {showDisplayAllRecords && (
                <SwitchGlobalSettings
                  data={
                    configData[
                      ECorporateSettingsField
                        .TRIMAdmin_DisplayAllRecordsSubFolderSystem
                    ]
                  }
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />
              )}
            </>
          )}
        </div>
      </section>
    );
  }
);
