import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { isValidElement } from "react";
import { CCDialog } from "../cc-dialog/_index";
import "./ConfirmDialog.scss";

export const MAX_LENGTH_REASON = 255;
export interface IConfirmDialogData {
  Reason: string;
}
const nameOf = nameOfFactory<IConfirmDialogData>();

export interface IConfirmDialog {
  title: string;
  message?: string | JSX.Element;
  subMessage?: string | JSX.Element;
  height?: string | number;
  width?: string | number;
  maxHeight?: string | number;
  btnYesTitle?: string;
  btnNoTitle?: string;
  maxWidth?: string | number;
  isLoadingYes?: boolean;
  isLoadingNo?: boolean;
  btnCancelVisible?: boolean;
  onClosePopup: () => void;
  onConfirmYes?: (data?: any) => void;
  onAsyncConfirm?: (e?: any) => void;
  onConfirmNo?: () => void;
  btnYesId?: string;
  btnNoVisible?: boolean;
  unsetMinHeight?: boolean;
  isReasonRequired?: boolean;
  maxLength?: number;
}

export const ConfirmDialog = ({
  message,
  subMessage,
  title,
  width,
  height,
  maxWidth,
  maxHeight,
  onClosePopup,
  onConfirmYes,
  onAsyncConfirm,
  onConfirmNo,
  isLoadingYes,
  isLoadingNo,
  btnYesTitle,
  btnNoTitle,
  btnCancelVisible = true,
  btnYesId,
  btnNoVisible = true,
  unsetMinHeight = true,
  isReasonRequired = false,
  maxLength = MAX_LENGTH_REASON,
}: IConfirmDialog) => {
  const handleSubmit = (event: FormSubmitClickEvent) => {
    const values: IConfirmDialogData = event.values as IConfirmDialogData;
    if (onAsyncConfirm) {
      onAsyncConfirm(values);
    } else {
      onConfirmYes && onConfirmYes(values);
      onClosePopup();
    }
  };

  const renderMessage = () => {
    if (message) {
      return isValidElement(message) ? (
        message
      ) : (
        <h5 className="cc-label">{message}</h5>
      );
    }
    return null;
  };

  const renderSubMessage = () => {
    if (subMessage) {
      return isValidElement(message) ? (
        subMessage
      ) : (
        <span className="cc-dialog-sub-message">{subMessage}</span>
      );
    }
    return null;
  };

  return (
    <Form
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter, valid, onSubmit } = formRenderProps;
        return (
          <div className="cc-confirm-dialog">
            <CCDialog
              unsetMinHeight={unsetMinHeight}
              maxWidth={isReasonRequired ? "40%" : maxWidth}
              maxHeight={maxHeight}
              width={isReasonRequired ? undefined : width}
              height={height}
              disabled={isLoadingYes || isLoadingNo}
              titleHeader={title}
              onClose={onClosePopup}
              bodyElement={
                <FormElement className="cc-form">
                  <div className="cc-confirm-dialog-body">
                    {renderMessage()}
                    {renderSubMessage()}
                    {isReasonRequired && (
                      <div className="cc-label-field">
                        <CCLabel title="Reason" isMandatory />
                        <Field
                          name={nameOf("Reason")}
                          rows={5}
                          value={valueGetter(nameOf("Reason"))}
                          component={CCTextArea}
                          validator={requiredValidator}
                          maxLength={maxLength}
                        />
                      </div>
                    )}
                  </div>
                </FormElement>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  {onConfirmNo && btnCancelVisible ? (
                    <Button
                      className="cc-dialog-button"
                      onClick={() => onClosePopup()}
                    >
                      Cancel
                    </Button>
                  ) : null}
                  {btnNoVisible && (
                    <Button
                      className="cc-dialog-button"
                      iconClass={isLoadingNo ? "fas fa-spinner fa-spin" : ""}
                      onClick={() =>
                        onConfirmNo ? onConfirmNo() : onClosePopup()
                      }
                    >
                      {btnNoTitle ?? "No"}
                    </Button>
                  )}
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={!valid}
                    id={btnYesId}
                    iconClass={isLoadingYes ? "fas fa-spinner fa-spin" : ""}
                    onClick={onSubmit}
                  >
                    {btnYesTitle ?? "Yes"}
                  </Button>
                </div>
              }
            />
          </div>
        );
      }}
    />
  );
};

ConfirmDialog.defaultProps = {
  width: "auto",
  height: "auto",
  maxWidth: "50%",
  maxHeight: "auto",
};
