import { isSuccessResponse } from "@common/apis/util";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { activateDocument } from "@common/pages/settings/lookups/documents/_id/components/buttons/activate/api";
import { useLookupDocumentStore } from "@common/pages/settings/lookups/documents/_id/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ActivateDocumentButton = observer(() => {
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const { documentId, reloadLookupDocument, isInactive } =
    useLookupDocumentStore();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOnClick = async () => {
    if (!documentId) return;
    setIsSubmitting(true);
    const response = await activateDocument(documentId);
    setIsSubmitting(false);
    if (isSuccessResponse(response)) {
      if (response?.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        reloadLookupDocument().then(() => {
          clearNotifications();
          pushNotification({
            title: "Document activated successfully",
            type: "success",
          });
        });
        if (response?.data?.Notifications?.length > 0) {
          pushNotification({
            autoClose: false,
            title: response.data?.Notifications,
            type: "warning",
          });
        }
      } else {
        pushNotification({
          autoClose: false,
          title: response.data?.Errors,
          type: "error",
        });
      }
    } else {
      pushNotification({
        autoClose: false,
        title: response.error,
        type: "error",
      });
    }
  };

  return isInactive ? (
    <>
      <CCNavButton
        title="Activate"
        onClick={handleOnClick}
        isLoading={isSubmitting}
      />
    </>
  ) : null;
});
