import { colProperty } from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/general/config";
import {
  IFirePreventionSummary,
  IProperty,
} from "@app/products/property/fire-prevention/hazld-case/[id]/model";
import { useFirePreventionStore } from "@app/products/property/fire-prevention/hazld-case/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<IFirePreventionSummary>();
const nameOfProperty = nameOfFactory<IProperty>();

export const FirePreventionManageGeneral = observer(() => {
  const { firePrevention } = useFirePreventionStore();
  return (
    <div className="cc-form">
      <Form
        initialValues={firePrevention}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Case ID</label>
                  <Field
                    name={nameOf("CaseId")}
                    component={CCInput}
                    placeholder="Case id"
                  />
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Owner(s)</label>
                  <Field
                    name={nameOf("OwnerS")}
                    component={CCTextArea}
                    rows={3}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Ratepayer/Postal address</label>
                  <Field
                    name={nameOf("RatepayerPostalAddress")}
                    component={CCTextArea}
                    rows={3}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Comments</label>
                  <Field
                    name={nameOf("Comments")}
                    component={CCTextArea}
                    rows={3}
                  />
                </div>
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Property</label>
                  <CCGrid
                    data={firePrevention?.Property ?? []}
                    columnFields={colProperty}
                    primaryField={nameOfProperty("ParcelId")}
                  />
                </div>
              </div>
            </section>
            <br />
          </FormElement>
        )}
      />
    </div>
  );
});
