import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { Journal } from "@app/core/journal/_index";
import { SamplesAccordion } from "@app/core/samples/_index";
import { TownPlanningForm } from "@app/products/town-planning/[id]/components/child-screens/general/_index";
import { TownPlanningContactsTab } from "@app/products/town-planning/[id]/components/reference-sidebar/contact/_index";
import { TownPlanningDetailsTab } from "@app/products/town-planning/[id]/components/reference-sidebar/details/_index";
import { TownPlanningRecordsState } from "@app/products/town-planning/[id]/model";
import { useTownPlanningStore } from "@app/products/town-planning/[id]/store";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { splitCamelCase } from "@common/utils/formatting";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedTownPlanning = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [townPlanningRecords] = useState<TownPlanningRecordsState | undefined>(
    undefined
  );
  const [showSlideBar, setShowSlideBar] = useState(true);
  const managePageUrl = window.location.pathname;
  const {
    townPlanningId,
    isLoading,
    townPlanning,
    responseLoadError,
    loadTownPlanning,
  } = useTownPlanningStore();
  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text:
        townPlanning?.SiteAddress.PropertyAssessment.Owners_Address.trim() ||
        "N/A",
      LinkUrl: managePageUrl,
      LinkText: `Town Planning - ${townPlanningId ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.TownPlanning,
      RecordType_ENUM: RECORDTYPE.TP_Application,
      Record_ID: townPlanningId ?? 0,
    },
  ];
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <TownPlanningDetailsTab /> },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Contacts",
        component: <TownPlanningContactsTab />,
      },
      {
        title: "History",
        component: (
          <Journal id={13347} recordType={RECORDTYPE.TP_Application} />
        ),
      },
    ],
  });

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Addresses",
      totalRecord: townPlanningRecords?.samples,
      component: (
        <SamplesAccordion id={id} recordType={RECORDTYPE.TP_Application} />
      ),
    },
    {
      title: "Actions",
      totalRecord: townPlanningRecords?.actions,
      component: <Actions id={id} recordType={RECORDTYPE.TP_Application} />,
    },
    {
      title: "Comments",
      totalRecord: townPlanningRecords?.comments,
      component: <Comments id={id} recordType={RECORDTYPE.TP_Application} />,
    },
    {
      title: "Contacts",
      totalRecord: townPlanningRecords?.contacts,
      component: <Contacts id={id} recordType={RECORDTYPE.TP_Application} />,
    },
    {
      title: "Documents",
      totalRecord: townPlanningRecords?.documents,
      component: (
        <Documents id={parseInt(id)} recordType={RECORDTYPE.TP_Application} />
      ),
    },
    {
      title: "Fees",
      totalRecord: townPlanningRecords?.fees,
      component: <Fees id={id} recordType={RECORDTYPE.TP_Application} />,
    },
    {
      title: "Inspections",
      totalRecord: townPlanningRecords?.samples,
      component: (
        <SamplesAccordion id={id} recordType={RECORDTYPE.TP_Application} />
      ),
    },
    {
      title: "Registers",
      totalRecord: townPlanningRecords?.samples,
      component: (
        <SamplesAccordion id={id} recordType={RECORDTYPE.TP_Application} />
      ),
    },
    {
      title: "Related",
      totalRecord: townPlanningRecords?.samples,
      component: (
        <SamplesAccordion id={id} recordType={RECORDTYPE.TP_Application} />
      ),
    },
    {
      title: "Events",
      totalRecord: townPlanningRecords?.samples,
      component: (
        <SamplesAccordion id={id} recordType={RECORDTYPE.TP_Application} />
      ),
    },
    {
      title: "Conditions/Restrictions",
      totalRecord: townPlanningRecords?.samples,
      component: (
        <SamplesAccordion id={id} recordType={RECORDTYPE.TP_Application} />
      ),
    },
    {
      title: "Clauses",
      totalRecord: townPlanningRecords?.samples,
      component: (
        <SamplesAccordion id={id} recordType={RECORDTYPE.TP_Application} />
      ),
    },
  ];

  const getTitle = (): string => {
    const ownerAddress =
      townPlanning?.SiteAddress.PropertyAssessment.Owners_Address.trim();
    if (ownerAddress === undefined) return "";

    return ownerAddress || "N/A";
  };

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Town Planning"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadTownPlanning(id)}
        />
      ) : (
        <>
          <FormTitle
            title={getTitle()}
            badge={splitCamelCase(townPlanning?.Status_ENUM || "")}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button border-right"
              >
                <CCNavButton title={"Amend permit"} />
                <CCNavButton title={"Appealed"} />
                <CCNavButton title={"Audit"} />
                <CCNavButton title={"Number appeal"} />
                <CCNavButton title={"Plans to comply"} />
                <CCNavButton title={"Secondary consent"} />
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Additional address"} />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Advertising"} />
                <CCNavButton title={"Appeal"} />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Comment"} />
                {/* TODO: enhance later */}
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.TownPlanning}
                  recordType={
                    RECORDTYPE.CommunityProperty_CommunityCentral_Town_Planning_Application
                  }
                  recordId={parseInt(id)}
                />
                <CCNavButton title={"Complaint"} />
                <CCNavButton title={"Condition - ad hoc"} />
                <CCNavButton title={"Condition - std"} />
                <CCNavButton title={"Fee - ad hoc"} />
                <CCNavButton title={"Fee - calculated"} />
                <CCNavButton title={"Fee - multiple"} />
                <CCNavButton title={"Further information request"} />
                <CCNavButton title={"IP - ad hoc"} />
                <CCNavButton title={"IP - from file"} />
                <CCNavButton title={"IP - from map"} />
                <CCNavButton title={"IP - from properties"} />
                <CCNavButton title={"Meeting"} />
                <CCNavButton title={"Notice"} />
                <CCNavButton title={"Referral"} />
                <CCNavButton title={"Related"} />
              </CCNavButton>,
              <CCNavButton title={"View"} />,
              <CCNavButton title={"Print"} />,
              <CCNavButton title={"More Options"} type="more">
                <CCNavButton title={"Application details"} />
                <CCNavButton title={"Certification"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {townPlanning && (
                  <CCGeneralPanel component={<TownPlanningForm />} />
                )}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
