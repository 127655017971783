import { InspectionTypeFee } from "@app/core/inspections/[id]/model";
import { FormElementField } from "@common/pages/settings/lookups/inspection-types/_id/components/additional-fees-dialog/components/form-element/_index";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useMemo, useRef, useState } from "react";

export interface IAdditionalFeesDialogProps {
  onClose: () => void;
  onSubmit: (data: InspectionTypeFee) => void;
}

export const AdditionalFeesDialog = ({
  onClose,
  onSubmit,
}: IAdditionalFeesDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  const initialValues = useMemo(() => {
    return {
      Index: 0,
      InspectionTypeFeeType_ID: null,
      InspectionTypeFeeType_Name: "",
      InspectionTypeFee_Amount: null,
      InheritInspectionTypeFeeType: false,
    } as InspectionTypeFee;
  }, []);

  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    onSubmit(event.values as InspectionTypeFee);
    onClose();
  };
  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      render={(formRenderProps: FormRenderProps) => {
        const { valid, onSubmit } = formRenderProps;
        return (
          <CCDialog
            maxWidth="40%"
            height={"auto"}
            disabled={isLoading}
            titleHeader="Add fee type"
            onClose={onClose}
            bodyElement={
              <FormElement className="cc-form">
                <CCLocalNotification ref={notificationRef} />
                <FormElementField
                  formRenderProps={formRenderProps}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              </FormElement>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  themeColor="primary"
                  disabled={!valid}
                  onClick={onSubmit}
                  className="cc-dialog-button"
                >
                  Save
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
