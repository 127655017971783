import { mockBuilding } from "@app/products/building/mock";
import { mockSingleBuilding } from "@app/products/building/[id]/mock";
import {
  BuildingLOVs,
  IBuilding,
  IBuildingRegister,
} from "@app/products/building/[id]/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { IResponseData } from "@common/models/service";
import { sleep } from "@common/utils/common";
import { appNotificationStore } from "@components/cc-app-notification/store";

export const getBuildingLovs = async (): Promise<
  APIResponse<BuildingLOVs | undefined>
> => {
  // const response: IResponseData = await getAsync(LocalLawsUrl.GET_BUILDING_LOVS);

  await sleep(1000);
  return {
    data: {
      ...mockSingleBuilding,
    } as BuildingLOVs,
    status: APIResponseStatus.SUCCESS,
    statusText: "Ok",
    headers: {},
    config: {},
  };
};

export const getBuildingById = async (
  id: number
): Promise<APIResponse<IBuildingRegister | undefined>> => {
  await sleep(1000);
  return {
    data: {
      ...mockSingleBuilding,
      ID: id,
    } as IBuildingRegister,
    status: APIResponseStatus.SUCCESS,
    statusText: "Ok",
    headers: {},
    config: {},
  };
};

export const getBuildingRecords = async (id: number, recordType: number) => {
  try {
    const response: IResponseData = JSON.parse(JSON.stringify(mockBuilding));
    return response.data;
  } catch (error) {
    appNotificationStore.pushNotification({
      autoClose: false,
      title: "Related records load failed",
      type: "error",
    });
  }
};

export const postBuilding = async (requestBody: IBuilding) => {
  await sleep(2000);
};
export const putBuilding = async (requestBody: IBuilding) => {
  await sleep(2000);
};
