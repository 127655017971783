import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { NumericTextboxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/numeric-textbox/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export default ({ formRenderProps }: ICommonFormProps) => {
  const { configData } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();
  const { valueGetter } = formRenderProps;
  const fieldRunProcessPool = valueGetter(
    ECorporateSettingsField.CorporateSettings_RunAsProcessPool.toString()
  );

  if (!configData) return <></>;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField.CorporateSettings_RunAsProcessPool
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
      </div>
      {fieldRunProcessPool && (
        <>
          <div className="cc-form-cols-2">
            <NumericTextboxGlobalSettings
              data={
                configData[ECorporateSettingsField.Global_ProcessPoolMinSize]
              }
              isEditing={isEditing}
            />
          </div>
          <div className="cc-form-cols-2">
            <NumericTextboxGlobalSettings
              data={
                configData[
                  ECorporateSettingsField
                    .CorporateSettings_JobQueueAgentStailTime
                ]
              }
              isEditing={isEditing}
            />
          </div>
        </>
      )}
    </section>
  );
};
