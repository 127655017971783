import { V_Compliance_Functionality } from "@app/products/property/assessments/compliance/[id]/components/child-screens/functionality-restrictions/components/dialog/modify-functionality-restrictions/model";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { isBefore } from "date-fns";

export const functionalitiesValid = (
  functionalities: V_Compliance_Functionality[]
): IAppNotificationItem[] => {
  for (let i = 0; i < functionalities.length; i++) {
    const item = functionalities[i];

    if (item?.CF_Restrict_From && item?.CF_Restrict_To) {
      const from = new Date(item.CF_Restrict_From);
      const to = new Date(item.CF_Restrict_To);
      from.setHours(0, 0, 0, 0);
      to.setHours(0, 0, 0, 0);
      if (isBefore(to, from)) {
        return [
          {
            id: "0",
            title: `Functionality ${item.Functionality_Name} - "Restrict To" must be equal or greater than "Restrict From"`,
            type: "error",
            autoClose: false,
          },
        ];
      }
    }
  }
  return [];
};
