import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { VO_Assessment } from "@app/products/property/assessments/list/model";
import { AssessmentDetailTabCommon } from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/common/_index";
import { nameOfFactory } from "@common/utils/common";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const nameOf = nameOfFactory<VO_Assessment>();
export const AssessmentCollectionsDetailTab = observer(() => {
  const { lastSelectedRow } = useDetailAssessmentListViewStore();

  const assessmentID = useMemo(() => {
    return lastSelectedRow?.[nameOf("Assessment_Id")];
  }, [lastSelectedRow]);
  return <AssessmentDetailTabCommon id={assessmentID} />;
});
