import { history } from "@/AppRoutes";
import { ICategoryConfig } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ICON_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const settingFieldMapping = {
  Title: "Title",
  Description: "Description",
};

export const defaultPPRCategoryConfig: ICategoryConfig = {
  name: "settings",
  endPoint: "/TownPlanning/Settings",
  product: "TownPlanning",
};

export const settingListIsActiveHasItemID = (
  title: string = "Title",
  description: string = "Description"
): IColumnFields[] => {
  return [
    {
      field: settingFieldMapping.Title,
      title: title,
      locked: true,
      disableFieldName: "IsEditable",
      linkTo: (dataItem) => {
        return {
          pathname: history.location.pathname,
          state: {
            requestUrl: `/${dataItem.ProductType_Enum}/${dataItem.SettingCategoryName}/${dataItem.Name}/${dataItem.ItemId}`,
            product: dataItem.ProductType_Enum,
            category: dataItem.SettingCategoryName,
          },
        };
      },
    },
    {
      field: settingFieldMapping.Description,
      title: description,
    },
    {
      field: "IsActive",
      title: "Is Active",
      format: ICON_FORMAT.ICON_BOOLEAN,
    },
  ];
};

export const settingListHasItemID = (
  title: string = "Title",
  description: string = "Description"
): IColumnFields[] => {
  return [
    {
      field: settingFieldMapping.Title,
      title: title,
      locked: true,
      disableFieldName: "IsEditable",
      linkTo: (dataItem) => {
        return {
          pathname: history.location.pathname,
          state: {
            requestUrl: `/${dataItem.ProductType_Enum}/${dataItem.SettingCategoryName}/${dataItem.Name}/${dataItem.ItemId}`,
            product: dataItem.ProductType_Enum,
            category: dataItem.SettingCategoryName,
          },
        };
      },
    },
    {
      field: settingFieldMapping.Description,
      title: description,
    },
  ];
};

export const settingList = (
  title: string = "Title",
  description: string = "Description"
): IColumnFields[] => {
  return [
    {
      field: settingFieldMapping.Title,
      title: title,
      disableFieldName: "IsEditable",
      locked: true,
      linkTo: (dataItem) => {
        const isCategoryList = dataItem.SettingCategoryName === "Settings";
        return {
          pathname: history.location.pathname,
          state: {
            requestUrl: isCategoryList
              ? `/${dataItem.ProductType_Enum}/${dataItem.Name}`
              : `/${dataItem.ProductType_Enum}/${dataItem.SettingCategoryName}/${dataItem.Name}`,
            product: dataItem.ProductType_Enum,
            category: isCategoryList
              ? dataItem.Name
              : dataItem.SettingCategoryName,
          },
        };
      },
    },
    {
      field: settingFieldMapping.Description,
      title: description,
    },
  ];
};

export const settingListIsActive = (
  title: string = "Title",
  description: string = "Description"
): IColumnFields[] => {
  return [
    {
      field: settingFieldMapping.Title,
      title: title,
      disableFieldName: "IsEditable",
      locked: true,
      linkTo: (dataItem) => {
        return {
          pathname: history.location.pathname,
          state: {
            requestUrl: `/${dataItem.ProductType_Enum}/${dataItem.SettingCategoryName}/${dataItem.Name}`,
            product: dataItem.ProductType_Enum,
            category: dataItem.SettingCategoryName,
          },
        };
      },
    },
    {
      field: settingFieldMapping.Description,
      title: description,
    },
    {
      field: "IsActive",
      title: "Is Active",
      format: ICON_FORMAT.ICON_BOOLEAN,
    },
  ];
};

export const settingColumnField = (
  isActive: boolean,
  title: string,
  description: string,
  isItemId: boolean = false
): IColumnFields[] => {
  if (isActive) {
    if (isItemId) {
      return settingListIsActiveHasItemID(title, description);
    } else {
      return settingListIsActive(title, description);
    }
  } else {
    if (isItemId) {
      return settingListHasItemID(title, description);
    } else {
      return settingList(title, description);
    }
  }
};
