import { SecurityTemplateSubmitActions } from "@common/pages/settings/security/security-templates/model";
import { SecurityTemplateForm } from "@common/pages/settings/security/security-templates/_id/child-screens/general/_index";
import { useSecurityTemplateStore } from "@common/pages/settings/security/security-templates/_id/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewSecurityTemplate = observer(() => {
  const { isLoading, onSubmit, responseLoadError, reloadSecurityTemplate } =
    useSecurityTemplateStore();
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Action"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => reloadSecurityTemplate()}
        />
      ) : (
        <>
          <FormTitle title={"Security Template"} />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={SecurityTemplateSubmitActions.New}
              />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                <SecurityTemplateForm />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
