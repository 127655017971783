import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { DisAssociateAssessmentCommonButton } from "@app/products/property/assessments/components/action-bar/buttons/disassociate-assessment/common/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export const DisAssociateAssessmentButtonAtCollections = observer(() => {
  const { gridSelectedRows, isEmptyData } = useDetailAssessmentListViewStore();
  const { id } = useParams<{ id: string }>();

  const isDisabled = useMemo(() => {
    return (
      (!isEmptyData && gridSelectedRows.length !== 1 && !id) ||
      (!id && isEmptyData)
    );
  }, [gridSelectedRows.length, id, isEmptyData]);

  const assessmentID = useMemo(() => {
    return gridSelectedRows[0]?.Assessment_Id ?? id;
  }, [gridSelectedRows, id]);

  return (
    <DisAssociateAssessmentCommonButton
      assessmentID={assessmentID}
      statusID={gridSelectedRows[0]?.Assessment_Status}
      isDisabled={isDisabled}
    />
  );
});
