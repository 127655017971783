import { Comment } from "@app/core/comments/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import Loading from "@components/loading/Loading";
import React from "react";

interface IManageCommentProps {
  comment?: Comment;
  onClose: () => void;
  isLoading?: boolean;
  notifications?: IAppNotificationItem[];
}

export const ManageComment = ({
  comment,
  onClose,
  isLoading = false,
  notifications,
}: IManageCommentProps) => {
  return (
    <CCDialog
      titleHeader={"Comment"}
      height={"auto"}
      maxWidth={"40%"}
      onClose={onClose}
      bodyElement={
        isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <>
            <CCLocalNotification defaultNotifications={notifications} />
            <div className="cc-form">
              <div className="cc-field-group">
                <div className="cc-form-cols-1">
                  <CCValueField label="Title" value={comment?.Title} />
                </div>
                <div className="cc-form-cols-1">
                  <CCValueField
                    label="Description"
                    value={comment?.Description}
                  />
                </div>
                <div className="cc-form-cols-2">
                  <CCValueField
                    label="Recorded on"
                    value={formatDisplayValue(
                      comment?.Sys_CreatedDate,
                      DATETIME_FORMAT.DATETIME
                    )}
                  />
                  <CCValueField
                    label="Recorded by"
                    value={comment?.Sys_CreatedBy}
                  />
                </div>
              </div>
            </div>
          </>
        )
      }
    />
  );
};
