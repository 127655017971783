import { history } from "@/AppRoutes";
import {
  getBuildingById,
  getBuildingLovs,
  postBuilding,
  putBuilding,
} from "@app/products/building/[id]/api";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import {
  BuildingLOVs,
  IBuilding,
  IBuildingRegister,
} from "@app/products/building/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class BuildingStore {
  private _building?: IBuildingRegister = undefined;
  private _buildingLovs?: BuildingLOVs = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get building() {
    return this._building;
  }
  setBuilding = (building: IBuildingRegister | undefined) => {
    runInAction(() => {
      this._building = building;
    });
  };

  get buildingLovs() {
    return this._buildingLovs;
  }
  setBuildingLovs = (buildingLovs: BuildingLOVs | undefined) => {
    runInAction(() => {
      this._buildingLovs = buildingLovs;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get buildingId() {
    return this._building?.ID;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._building = undefined;
      this._buildingLovs = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadBuilding = async (buildingId: number, isNew: boolean = false) => {
    this.setIsLoading(true);
    const responseBuildingLovs = await getBuildingLovs();
    let errorResponse, newBuilding, newBuildingLovs;

    if (isSuccessResponse(responseBuildingLovs)) {
      newBuildingLovs = responseBuildingLovs.data;
    } else {
      errorResponse = {
        status: responseBuildingLovs.status,
        error: responseBuildingLovs.error,
      };
    }

    if (!isNew) {
      const response = await getBuildingById(buildingId);
      if (isSuccessResponse(response)) {
        newBuilding = response.data;
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
      this.setBuilding(newBuilding);
    }

    this.setResponseLoadError(errorResponse);
    this.setBuildingLovs(newBuildingLovs);
    this.setIsLoading(false);
  };

  createBuilding = async (building: IBuilding) => {
    this.setIsLoading(true);
    await postBuilding(building);
    appNotificationStore.pushNotification({
      title: "Building created successfully",
      type: "success",
      onClose: () => {
        history.replace(`${BUILDING_MANAGE_ROUTE}/${building?.ID ?? 1}`);
      },
    });
    this.setIsLoading(false);
  };

  updateBuilding = async (building: IBuilding) => {
    this.setIsLoading(true);
    await putBuilding(building);
    appNotificationStore.pushNotification({
      title: "Building updated successfully",
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const buildingStoreContext = createContext(new BuildingStore());
export const useBuildingStore = () => {
  return useContext(buildingStoreContext);
};
