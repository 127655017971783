export const convertJSONToXML = (obj: any) => {
  let xmlTag = "";
  Object.keys(obj).forEach((item: string) => {
    let content = obj[item];
    if (item === "Xml") {
      content = content
        .replace(/</g, `&lt;`)
        .replace(/>/g, `&gt;`)
        .replace(/&amp;/g, `&amp;amp;`);
    }
    xmlTag += `<${item}>${content}</${item}>\n`;
  });
  return `<Report>${xmlTag}</Report>\n`;
};

export const convertArrJSONToXML = (arrObj: any) => {
  let xml = "";
  arrObj.forEach((obj: any) => {
    xml += convertJSONToXML(obj);
  });
  return `<Reports>
    ${xml}
  </Reports>`;
};
