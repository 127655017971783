import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface ILeaseTransfersSuspendDialogProps {
  onClose: () => void;
}

export const LeaseTransfersSuspendDialog = observer(
  ({ onClose }: ILeaseTransfersSuspendDialogProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const [isShowConfirmDialog, setIsShowConfirmDialog] =
      useState<boolean>(false);

    const handleConfirmFinishProcess = () => {
      onClose();
      pushNotification({
        title: "Suspend lease transfer was completed successfully.",
        type: "success",
      });
    };

    return (
      <Form
        render={(formRenderProps: FormRenderProps) => {
          return (
            <>
              <CCDialog
                maxWidth="35%"
                height={"auto"}
                titleHeader={"Suspend Lease Transfers"}
                onClose={onClose}
                bodyElement={
                  <FormElement className="cc-form">
                    <div className="cc-field-group">
                      <div className="cc-field">
                        <label className="cc-label">
                          Reason for suspending the lease transfer(s)
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <div className="cc-label-field">
                          <Field
                            name={"Reason"}
                            rows={5}
                            component={CCTextArea}
                            validator={requiredValidator}
                          />
                        </div>
                      </div>
                    </div>
                  </FormElement>
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      onClick={() => setIsShowConfirmDialog(true)}
                      disabled={!formRenderProps.valid}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
              {isShowConfirmDialog && (
                <ConfirmDialog
                  title="Confirmation"
                  subMessage={"Are you sure you want to submit?"}
                  onClosePopup={() => setIsShowConfirmDialog(false)}
                  onAsyncConfirm={() => {
                    return handleConfirmFinishProcess();
                  }}
                />
              )}
            </>
          );
        }}
      />
    );
  }
);
