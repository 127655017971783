import { IAssessmentJournalTransactionStepsLOVs } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class DeferredJournalStepStore {
  private _deferredJournalStepLOVs?: IAssessmentJournalTransactionStepsLOVs =
    undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get deferredJournalStepLOVs() {
    return this._deferredJournalStepLOVs;
  }
  setDeferredJournalStepLOVs = (
    deferredJournalStepLOVs?: IAssessmentJournalTransactionStepsLOVs
  ) => {
    runInAction(() => {
      this._deferredJournalStepLOVs = deferredJournalStepLOVs;
    });
  };
}

const deferredJournalStepStoreContext = createContext(
  new DeferredJournalStepStore()
);
export const useDeferredJournalStepStore = () => {
  return useContext(deferredJournalStepStoreContext);
};
