import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";
import {
  DTO_Assessment_LevyBalance,
  DTO_Assessment_LevyBalanceViews,
} from "./model";

export const loadPropertyAssessmentLevyBalances = async (
  assessmentId: number,
  view: number = 4,
  showFullyPaidLevies: boolean = false
): Promise<DTO_Assessment_LevyBalance | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Assessment_LevyBalance>(
        `/api/property/internal/assessment/${assessmentId}/levyBalance`,
        {
          params: {
            view,
            showFullyPaidLevies,
          },
        }
      );

    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const loadPropertyAssessmentLevyBalanceViews = async (): Promise<
  DTO_Assessment_LevyBalanceViews | undefined
> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Assessment_LevyBalanceViews>(
        `api/property/internal/assessment/levybalanceviews`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
