export const mockNominatedProperty = [
  {
    AssessmentId: 47298,
    AssessmentNumber: 5000020571,
    PropertyAddress: "23 Viewgrand Dr, Pakenham",
    Role: "Aus Citz, Applicant Voter",
    EffectiveDate: "19-Oct-2021",
    NominatedId: 1,
    VotingEntitlementByElection: [
      {
        Election: "Cardinia Shire Election Oct 2020 - 07/09/2020",
        CouldVote: true,
        Role: "Aus Citz, Applicant Voter",
        AssessmentId: 47298,
        AssessmentNumber: 5000020571,
        PropertyAddress: "23 Viewgrand Dr, Pakenham",
      },
    ],
  },
  {
    AssessmentId: 47299,
    AssessmentNumber: 5000020572,
    PropertyAddress: "24 Viewgrand Dr, Pakenham",
    Role: "Aus Citz, Applicant Voter",
    EffectiveDate: "19-Oct-2021",
    NominatedId: 2,
    VotingEntitlementByElection: [
      {
        Election: "Cardinia Shire Election Oct 2020 - 07/09/2020",
        CouldVote: true,
        Role: "Aus Citz, Applicant Voter",
        AssessmentId: 47299,
        AssessmentNumber: 5000020572,
        PropertyAddress: "24 Viewgrand Dr, Pakenham",
      },
    ],
  },
  {
    AssessmentId: 47300,
    AssessmentNumber: 5000020573,
    PropertyAddress: "25 Viewgrand Dr, Pakenham",
    Role: "Aus Citz, Applicant Voter",
    EffectiveDate: "19-Oct-2021",
    NominatedId: 3,
    VotingEntitlementByElection: [
      {
        Election: "Cardinia Shire Election Oct 2020 - 07/09/2020",
        CouldVote: true,
        Role: "Aus Citz, Applicant Voter",
        AssessmentId: 47300,
        AssessmentNumber: 5000020573,
        PropertyAddress: "25 Viewgrand Dr, Pakenham",
      },
    ],
  },
];
