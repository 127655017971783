import { CORE_INSPECTION_TYPES_ODATA } from "@app/core/components/common/constant";
import { generateGetContactURL } from "@app/core/contacts/components/dialogs/pick-contact/util";
import {
  ILocationType,
  LocationRegisterView,
  PremisesView,
  Svc_ContactDetails,
} from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/model";
import {
  CRMSEventRegister,
  CS_IssueLocationType,
  CS_RequestorType,
} from "@app/products/crms/[id]/model";
import { CrmsUrlApi } from "@app/products/crms/config";
import { HMUrl } from "@app/products/hm/config";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { ICouncilContact } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/dialog/contact-manager/model";
import { WastewaterUrl } from "@app/products/waste-water/config";
import { WWSPISchemeView } from "@app/products/waste-water/model";
import { ContactClassification } from "@common/constants/enumerations";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { SortDescriptor } from "@progress/kendo-data-query";
import { FormRenderProps } from "@progress/kendo-react-form";

const nameOf = nameOfFactory<CRMSEventRegister>();
export const colContactManager: IColumnFields[] = [
  { title: "Name", field: "Name", width: 150 },
  { title: "Position", field: "Position", width: 100 },
  {
    title: "Org Structure",
    field: "ORGStructure",
    width: 100,
  },
  { title: "Email", field: "Email", width: 100 },
  { title: "Work Phone", field: "WorkPhone", width: 100 },
  { title: "Home Phone", field: "HomePhone", width: 100 },
  { title: "Mobile", field: "Mobile", width: 100 },
];

export const colLocation: IColumnFields[] = [
  { title: "Name", field: "Name" },
  { title: "Type", field: "Type" },
  { title: "Address", field: "Address" },
];
export const locationType: Array<ILocationType> = [
  {
    label: "No location",
    value: "NoLocation",
  },
  { label: "Location", value: "Location" },
  {
    label: "Same as requestor",
    value: "SameasRequestor",
  },
  {
    label: "Community property",
    value: "CommunityProperty",
  },
  {
    label: "Council property",
    value: "CouncilProperty",
  },
  {
    label: "Health",
    value: "Health",
  },
  {
    label: "Non property",
    value: "NonProperty",
  },
  {
    label: "Poisons",
    value: "Poisons",
  },
  {
    label: "Radiation place",
    value: "RadiationPlace",
  },
  {
    label: "Reserve",
    value: "Reserve",
  },
  {
    label: "Tobacco",
    value: "Tobacco",
  },
];

export const requestorFields = (props: FormRenderProps) => ({
  Id: "0",
  isMultiSelect: false,
  titleHeader: "Contact Manager",
  placeholder: "Pick Requestor",
  tableTypes: colContactManager,
  url: CORE_INSPECTION_TYPES_ODATA,
  fieldName: nameOf("Requestor"),
  value: props.valueGetter(nameOf("Requestor")),
  onChange: (event: any) => {
    if (event.target !== null) {
      props.onChange(nameOf("Requestor"), {
        value: event.target.value,
      });
    } else {
      props.onChange(nameOf("Requestor"), {
        value: event.detail.Name,
      });
      props.onChange("Position", {
        value: "IT Officer",
      });
      props.onChange(nameOf("Address"), {
        value: "Human Services > Child and Family Services",
      });
      props.onChange(nameOf("Contact"), {
        value: "03 5220 7176 (W)",
      });
    }
  },
});

export const locationFields = (props: FormRenderProps) => ({
  Id: "2",
  isMultiSelect: false,
  titleHeader: "Contact Manager",
  placeholder: "Location",
  tableTypes: colLocation,
  url: CORE_INSPECTION_TYPES_ODATA,
  disabled: true,
  fieldName: nameOf("Location"),
  value: props.valueGetter(nameOf("Location")),
  onChange: (event: any) => {
    if (event.target !== null) {
      props.onChange(nameOf("Location"), {
        value: event.target.value,
      });
    } else {
      props.onChange(nameOf("Location"), {
        value: event.detail.Name,
      });
      props.onChange(nameOf("LocationDetails"), {
        value: "Details Assess:315224 Parcel: 23712",
      });
    }
  },
});

// render option for requestor type InternalPerson, Councillor, ExecutiveManagementTeam
const nameOfContact = nameOfFactory<ICouncilContact>();
const nameOfRequestor = nameOfFactory<Svc_ContactDetails>();
export const renderOptionRequestor = (requestorType: CS_RequestorType) => {
  let dataUrl = undefined;
  let title = "";

  switch (requestorType) {
    case CS_RequestorType.InternalPerson:
      dataUrl = generateGetContactURL({
        classificationFilters: `'${ContactClassification.SiteUser}'`,
        hideCouncillors: "true",
      });
      title = "Contact Manager";
      break;
    case CS_RequestorType.Councillor:
      dataUrl = generateGetContactURL({
        classificationFilters: `'${ContactClassification.SiteUser}'`,
        showOnlyCouncillors: "true",
      });
      title = "Contact Manager";
      break;
    case CS_RequestorType.ExecutiveManagementTeam:
      dataUrl = "/odata/core/internal/contacts/GetCurrentEMTContacts";
      title = "Pick Contact";
      break;
    default:
      break;
  }

  const optionRequestor: IOptionInputPicker = {
    dialog: {
      titleHeader: title,
      maxWidth: "60%",
    },
    boxSearch: {
      colSearch: nameOfRequestor("DisplayName"),
      colShowComboboxSearch: [
        {
          title: "Name",
          field: nameOfRequestor("DisplayName"),
        },
      ],
    },
    grid: {
      columnFields: [
        { title: "Name", field: nameOfRequestor("DisplayName") },
        { title: "Position", field: nameOfRequestor("Position") },
        { title: "Org Structure", field: nameOfRequestor("OrgStructure") },
        { title: "Email", field: nameOfRequestor("Email") },
        { title: "Work Phone", field: nameOfRequestor("WorkPhone") },
        { title: "Home Phone", field: nameOfRequestor("HomePhone") },
        { title: "Mobile", field: nameOfRequestor("Mobile") },
      ],
      primaryField: nameOfRequestor("Id"),
      dataUrl: dataUrl,
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: [
          {
            field: nameOfRequestor("DisplayName"),
            dir: "asc",
          },
        ],
      },
    },
  };

  return optionRequestor;
};

export const optionOnBehalfOF: IOptionInputPicker = {
  dialog: {
    titleHeader: "On behalf of",
    maxWidth: "60%",
  },
  boxSearch: {
    colSearch: nameOfContact("DisplayName"),
    colShowComboboxSearch: [
      {
        title: "Name",
        field: nameOfContact("DisplayName"),
      },
    ],
  },
  grid: {
    columnFields: [
      { title: "Name", field: nameOfContact("DisplayName") },
      { title: "Position", field: nameOfContact("Position") },
      { title: "Email", field: nameOfContact("Email") },
      { title: "Work Phone", field: nameOfContact("WorkPhone") },
      { title: "Mobile", field: nameOfContact("Mobile") },
    ],
    primaryField: nameOfContact("Id"),
    dataUrl:
      "odata/core/internal/contacts/GetContactsByClassification(classification=1)",
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
    state: {
      sort: [
        {
          field: nameOfContact("DisplayName"),
          dir: "asc",
        },
      ],
    },
  },
};

// Render option for issue location HealthPremises, TobaccoPremises, Reserve/Park, Community property, WasteWaterScheme
const nameOfIssueLocationPremises = nameOfFactory<PremisesView>();
const nameOfIssueLocationRegister = nameOfFactory<LocationRegisterView>();
const nameOfWasteWaterScheme = nameOfFactory<WWSPISchemeView>();
export const renderOptionIssueLocation = (
  issueLocationType: CS_IssueLocationType
) => {
  let dataUrl = undefined;
  let title = "";
  let columnFields: IColumnFields[] = [];
  let colSearch = "";
  let colShowComboboxSearch: IColumnFields[] = [];
  let primaryField: string = "";
  let sort: SortDescriptor[] = [];

  // Issue location premise
  const premisesColumnFields = [
    {
      title: "Trading Name",
      field: nameOfIssueLocationPremises("TradingName"),
    },
    { title: "Reg No", field: nameOfIssueLocationPremises("RegNo") },
    { title: "Address", field: nameOfIssueLocationPremises("Address") },
    { title: "Suburb", field: nameOfIssueLocationPremises("Suburb") },
    { title: "Type", field: nameOfIssueLocationPremises("PremisesType") },
    { title: "Risk", field: nameOfIssueLocationPremises("Risk") },
    { title: "Status", field: nameOfIssueLocationPremises("Status") },
  ];

  const premisesColSearch = nameOfIssueLocationPremises("TradingName");
  const premisesColShowComboboxSearch: IColumnFields[] = [
    {
      title: "Trading Name",
      field: nameOfIssueLocationPremises("TradingName"),
    },
  ];
  const premisesPrimaryField = nameOfIssueLocationPremises("ID");
  const premisesSort: SortDescriptor[] = [
    {
      field: nameOfIssueLocationPremises("TradingName"),
      dir: "asc",
    },
  ];

  // Issue location register
  const registerColumnFields = [
    {
      title: "Name",
      field: nameOfIssueLocationRegister("Name"),
    },
    { title: "Type", field: nameOfIssueLocationRegister("Type") },
    { title: "Address", field: nameOfIssueLocationRegister("Address") },
  ];
  const registerColSearch = nameOfIssueLocationRegister("Name");
  const registerColShowComboboxSearch: IColumnFields[] = [
    {
      title: "Name",
      field: nameOfIssueLocationRegister("Name"),
    },
  ];
  const registerPrimaryField = nameOfIssueLocationRegister("ID");
  const registerSort: SortDescriptor[] = [
    {
      field: nameOfIssueLocationRegister("Name"),
      dir: "asc",
    },
  ];

  // Issue location waste water scheme
  const wasteWaterSchemeColumnFields = [
    {
      title: "Name",
      field: nameOfWasteWaterScheme("Name"),
    },
    { title: "LGA", field: nameOfWasteWaterScheme("LGA") },
    { title: "Contact", field: nameOfWasteWaterScheme("Contact") },
  ];
  const wasteWaterSchemeColSearch = nameOfWasteWaterScheme("Name");
  const wasteWaterSchemeColShowComboboxSearch: IColumnFields[] = [
    {
      title: "Name",
      field: nameOfWasteWaterScheme("Name"),
    },
  ];
  const wasteWaterSchemePrimaryField = nameOfWasteWaterScheme("ID");
  const wasteWaterSchemeSort: SortDescriptor[] = [
    {
      field: nameOfWasteWaterScheme("Name"),
      dir: "asc",
    },
  ];

  // Generate config
  switch (issueLocationType) {
    case CS_IssueLocationType.HealthPremises:
      dataUrl = HMUrl.GET_VIEW_HM_FOOD_PICK_LIST;
      title = "Health Premises";
      columnFields = premisesColumnFields;
      colSearch = premisesColSearch;
      colShowComboboxSearch = premisesColShowComboboxSearch;
      primaryField = premisesPrimaryField;
      sort = premisesSort;
      break;
    case CS_IssueLocationType.TobaccoPremises:
      dataUrl = HMUrl.GET_VIEW_HM_TOBACCO_PICK_LIST;
      title = "Tobacco Premises";
      columnFields = premisesColumnFields;
      colSearch = premisesColSearch;
      colShowComboboxSearch = premisesColShowComboboxSearch;
      primaryField = premisesPrimaryField;
      sort = premisesSort;
      break;
    case CS_IssueLocationType.Reserve:
      dataUrl = CrmsUrlApi.GET_VIEW_CRMS_ISSUE_LOCATION_RESERVE;
      title = "Pick Reserve/Park";
      columnFields = registerColumnFields;
      colSearch = registerColSearch;
      colShowComboboxSearch = registerColShowComboboxSearch;
      primaryField = registerPrimaryField;
      sort = registerSort;
      break;
    case CS_IssueLocationType.CommunityProperty:
      dataUrl = CrmsUrlApi.GET_VIEW_CRMS_ISSUE_LOCATION_COMMUNITY_PROPERTY;
      title = "Pick Community Property";
      columnFields = registerColumnFields;
      colSearch = registerColSearch;
      colShowComboboxSearch = registerColShowComboboxSearch;
      primaryField = registerPrimaryField;
      sort = registerSort;
      break;
    case CS_IssueLocationType.WWMSScheme:
      dataUrl = WastewaterUrl.GET_WASTEWATER_SCHEME_PICK_LIST;
      title = "Schemes";
      columnFields = wasteWaterSchemeColumnFields;
      colSearch = wasteWaterSchemeColSearch;
      colShowComboboxSearch = wasteWaterSchemeColShowComboboxSearch;
      primaryField = wasteWaterSchemePrimaryField;
      sort = wasteWaterSchemeSort;
      break;
    default:
      break;
  }

  const optionIssueLocation: IOptionInputPicker = {
    dialog: {
      titleHeader: title,
      maxWidth: "60%",
    },
    boxSearch: {
      colSearch: colSearch,
      colShowComboboxSearch: colShowComboboxSearch,
    },
    grid: {
      columnFields: columnFields,
      primaryField: primaryField,
      dataUrl: dataUrl,
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: sort,
      },
    },
  };

  return optionIssueLocation;
};
