import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";
import { DTO_NoticeRun_TabDetails } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/reference-sidebar/detail/model";

export const getNoticeRunDetailsTabById = async (
  noticeRunId: number
): Promise<DTO_NoticeRun_TabDetails | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_NoticeRun_TabDetails>(
        `api/property/int/noticerun/${noticeRunId}/tabdetails`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
