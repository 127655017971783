import {
  ElementDisplayStatus,
  ServiceStandardUpdateTriggers,
} from "@app/products/crms/[id]/model";
import { colGroupsOrgUser } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/special-interest/config";
import {
  ServiceStandard,
  ServiceStandardHandlerRequest,
  ServiceStandardMapObj,
  Svc_FormAction,
} from "@app/products/crms/service-standards/[id]/model";
import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import { AccessControl } from "@common/models/accessControl";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { PickUserGroupOrgButton } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/buttons/pick-user-group-org/_index";
import { IDataUserGroupOrgForm } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface ISecurityProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<ServiceStandard>();
const nameOfServiceStandardMapObj = nameOfFactory<ServiceStandardMapObj>();

const getIds = (
  listDeleteSelected: AccessControl[],
  listData: AccessControl[]
) => {
  const ids = listDeleteSelected.map((item: AccessControl) => item.EntityID);
  const newList = listData.filter(
    (item: AccessControl) => !ids.includes(item.EntityID)
  );

  return newList;
};

export const Security = observer(({ formRenderProps }: ISecurityProps) => {
  const { onChange, valueGetter } = formRenderProps;
  const {
    serviceStandardLovs,
    uiControl,
    serviceStandardChangeHandler,
    setIsExpandedSecurityAccordion,
    accessRight,
  } = useCRMSServiceStandardStore();

  const { settings } = useCommonCoreStore();

  const [stdReaderslistSelected, setStdReaderslistSelected] = useState<
    AccessControl[]
  >([]);
  const [stdWriterslistSelected, setStdWriterslistSelected] = useState<
    AccessControl[]
  >([]);

  const [stdEventReaderslistSelected, setStdEventReaderslistSelected] =
    useState<AccessControl[]>([]);
  const [stdEventWriterslistSelected, setStdEventWriterslistSelected] =
    useState<AccessControl[]>([]);

  const [
    isShowConfirmDeleteStdReadersDialog,
    setIsShowConfirmDeleteStdReadersDialog,
  ] = useState<boolean>(false);

  const [
    isShowConfirmDeleteStdWritersDialog,
    setIsShowConfirmDeleteStdWritersDialog,
  ] = useState<boolean>(false);

  const [
    isShowConfirmDeleteEventReadersDialog,
    setIsShowConfirmDeleteEventReadersDialog,
  ] = useState<boolean>(false);

  const [
    isShowConfirmDeleteEventWritersDialog,
    setIsShowConfirmDeleteEventWritersDialog,
  ] = useState<boolean>(false);

  const isConfidentialEnableAddAttachment = getBoolValueSetting(
    settings[
      ECorporateSettingsField
        .CUSTOMERSERVICE_Security_ConfidentialEnableAddAttachment
    ]
  );

  const [isDeleteMultiple, setIsDeleteMultiple] = useState(false);

  const isDisabled = accessRight === ACCESSRIGHTS.CanRead;

  const serviceStandardFormObj = valueGetter(
    nameOfServiceStandardMapObj("ServiceStandard")
  );

  const stdReaders = valueGetter(
    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf("Readers")}`
  );

  const stdWriters = valueGetter(
    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf("Writers")}`
  );

  const stdEventReaders = valueGetter(
    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
      "EventReaders"
    )}`
  );

  const stdEventWriters = valueGetter(
    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
      "EventWriters"
    )}`
  );

  const paramsDelete = (svcFormAction: Svc_FormAction) => {
    return {
      ServiceStandardFormAction: svcFormAction,
      ServiceStandard: serviceStandardFormObj,
      EventArgs: {
        Siteusers: [],
        Hierarchies: [],
      },
      IsFirstTimeLoad: false,
    };
  };

  const handleAddNew = (
    value: IDataUserGroupOrgForm,
    svcFormAction: Svc_FormAction
  ) => {
    const groups = value?.Groups ?? [];
    const orgStructure = value?.OrgStructure ?? [];
    const users = value?.Users ?? [];
    const data = [...groups, ...orgStructure];

    let hierarchiesIds = data.map((item) => item.ID);
    const userIds = users.map((item) => item.ID);

    if (hierarchiesIds.length === 0 && userIds.length === 0) return;

    const params: ServiceStandardHandlerRequest = {
      ServiceStandardFormAction: svcFormAction,
      ServiceStandard: serviceStandardFormObj,
      EventArgs: {
        SiteUsers: userIds ?? [],
        Hierarchies: hierarchiesIds ?? [],
      },
      IsFirstTimeLoad: false,
    };

    serviceStandardChangeHandler(
      params,
      "Select users, groups and org structures was failed"
    );

    setIsExpandedSecurityAccordion(true);
  };

  const onCloseConfirmDeleteDialog = () => {
    setIsShowConfirmDeleteStdReadersDialog(false);
    setIsShowConfirmDeleteStdWritersDialog(false);
    setIsShowConfirmDeleteEventReadersDialog(false);
    setIsShowConfirmDeleteEventWritersDialog(false);
  };

  const handleConfirmDelete = () => {
    switch (true) {
      case isShowConfirmDeleteStdReadersDialog:
        handleDeleteStdReaders();
        break;
      case isShowConfirmDeleteStdWritersDialog:
        handleDeleteStdWriters();
        break;
      case isShowConfirmDeleteEventReadersDialog:
        handleDeleteStdEventReaders();
        break;
      case isShowConfirmDeleteEventWritersDialog:
        handleDeleteStdEventWriters();
        break;
      default:
        break;
    }
  };

  const addSaveTriggers = () => {
    const saveTriggers =
      valueGetter(
        `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
          "_SaveTriggers"
        )}`
      ) ?? [];

    if (
      !saveTriggers.includes(ServiceStandardUpdateTriggers.UpdateAccessControl)
    ) {
      saveTriggers.push(ServiceStandardUpdateTriggers.UpdateAccessControl);
      onChange(
        `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
          "_SaveTriggers"
        )}`,
        { value: saveTriggers }
      );
    }
  };

  const handleDeleteStdReaders = () => {
    const newList = getIds(stdReaderslistSelected, stdReaders);
    onChange(
      `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf("Readers")}`,
      { value: newList }
    );
    addSaveTriggers();

    const params: ServiceStandardHandlerRequest = paramsDelete(
      Svc_FormAction.PickReader
    );
    serviceStandardChangeHandler(
      params,
      "Delete service standard reader was failed."
    );
    setStdReaderslistSelected([]);
    setIsExpandedSecurityAccordion(true);
  };

  const handleDeleteStdWriters = () => {
    const newList = getIds(stdWriterslistSelected, stdWriters);
    onChange(
      `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf("Writers")}`,
      { value: newList }
    );
    addSaveTriggers();

    const params: ServiceStandardHandlerRequest = paramsDelete(
      Svc_FormAction.PickWriter
    );

    serviceStandardChangeHandler(
      params,
      "Delete service standard writer was failed."
    );
    setStdWriterslistSelected([]);
    setIsExpandedSecurityAccordion(true);
  };

  const handleDeleteStdEventReaders = () => {
    const newList = getIds(stdEventReaderslistSelected, stdEventReaders);
    onChange(
      `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
        "EventReaders"
      )}`,
      { value: newList }
    );
    addSaveTriggers();

    const params: ServiceStandardHandlerRequest = paramsDelete(
      Svc_FormAction.PickEventReader
    );
    serviceStandardChangeHandler(params, "Delete event reader was failed.");
    setStdEventReaderslistSelected([]);
    setIsExpandedSecurityAccordion(true);
  };

  const handleDeleteStdEventWriters = () => {
    const newList = getIds(stdEventWriterslistSelected, stdEventWriters);

    onChange(
      `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
        "EventWriters"
      )}`,
      { value: newList }
    );
    addSaveTriggers();

    const params: ServiceStandardHandlerRequest = paramsDelete(
      Svc_FormAction.PickEventWriter
    );
    serviceStandardChangeHandler(params, "Delete event writer was failed.");
    setStdEventWriterslistSelected([]);
    setIsExpandedSecurityAccordion(true);
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="Confidentiality" isMandatory />
          <Field
            name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "Confidentiality_ENUM"
            )}`}
            textField="Value"
            dataItemKey="Key"
            data={serviceStandardLovs?.Confidentiality}
            component={CCSearchComboBox}
            value={getDropdownValue(
              valueGetter(
                `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                  "Confidentiality_ENUM"
                )}`
              ),
              serviceStandardLovs?.Confidentiality,
              "Key"
            )}
            onChange={(event: ComboBoxChangeEvent) => {
              onChange(
                `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                  "Confidentiality_ENUM"
                )}`,
                {
                  value: event.target.value?.Key ?? null,
                }
              );
            }}
            disabled={
              uiControl?.DdlConfidentiality?.DisplayStatus ===
                ElementDisplayStatus.Disable || isDisabled
            }
            validator={requiredValidator}
          />
        </div>
      </div>
      <div className="cc-form-cols-3">
        {isConfidentialEnableAddAttachment ? (
          <div className="cc-field">
            <CCLabel title="Confidential - enable add attachment" />
            <Field
              disabled={isDisabled}
              name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "ConfidentialEnableAddAttachment"
              )}`}
              component={CCSwitch}
              checked={valueGetter(
                `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                  "ConfidentialEnableAddAttachment"
                )}`
              )}
            />
          </div>
        ) : (
          <CCValueField
            label="Confidential - enable add attachment"
            value={"No"}
          />
        )}
      </div>

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Service standard readers" />
          <CCGrid
            readOnly={isDisabled}
            columnFields={colGroupsOrgUser}
            primaryField={"EntityID"}
            data={valueGetter(
              `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "Readers"
              )}`
            )}
            selectableMode={"multiple"}
            selectedRows={stdReaderslistSelected}
            toolbar={
              <div className="cc-grid-tools-bar">
                <PickUserGroupOrgButton
                  onSubmit={(values: IDataUserGroupOrgForm) =>
                    handleAddNew(values, Svc_FormAction.PickReader)
                  }
                  maxHeight="85%"
                />
                <Button
                  type="button"
                  iconClass="fas fa-minus"
                  title="Remove"
                  onClick={() => {
                    setIsShowConfirmDeleteStdReadersDialog(true);
                    setIsDeleteMultiple(stdReaderslistSelected.length > 1);
                  }}
                  disabled={stdReaderslistSelected.length === 0}
                />
              </div>
            }
            onSelectionChange={(dataItem: AccessControl[]) => {
              setStdReaderslistSelected(dataItem ?? []);
            }}
            itemPerPage={50}
            isAutoHiddenPager={false}
          />
        </div>
      </div>

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Service standard writers" />
          <CCGrid
            readOnly={isDisabled}
            columnFields={colGroupsOrgUser}
            primaryField={"EntityID"}
            data={valueGetter(
              `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "Writers"
              )}`
            )}
            selectableMode={"multiple"}
            selectedRows={stdWriterslistSelected}
            toolbar={
              <div className="cc-grid-tools-bar">
                <PickUserGroupOrgButton
                  onSubmit={(values: IDataUserGroupOrgForm) =>
                    handleAddNew(values, Svc_FormAction.PickWriter)
                  }
                  maxHeight="85%"
                />
                <Button
                  type="button"
                  iconClass="fas fa-minus"
                  title="Remove"
                  onClick={() => {
                    setIsShowConfirmDeleteStdWritersDialog(true);
                    setIsDeleteMultiple(stdWriterslistSelected.length > 1);
                  }}
                  disabled={stdWriterslistSelected.length === 0}
                />
              </div>
            }
            onSelectionChange={(dataItem: AccessControl[]) => {
              setStdWriterslistSelected(dataItem ?? []);
            }}
            itemPerPage={50}
            isAutoHiddenPager={false}
          />
        </div>
      </div>

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Event readers" />
          <CCGrid
            readOnly={isDisabled}
            columnFields={colGroupsOrgUser}
            primaryField={"EntityID"}
            data={valueGetter(
              `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "EventReaders"
              )}`
            )}
            selectableMode={"multiple"}
            selectedRows={stdEventReaderslistSelected}
            toolbar={
              <div className="cc-grid-tools-bar">
                <PickUserGroupOrgButton
                  onSubmit={(values: IDataUserGroupOrgForm) =>
                    handleAddNew(values, Svc_FormAction.PickEventReader)
                  }
                  maxHeight="85%"
                />
                <Button
                  type="button"
                  iconClass="fas fa-minus"
                  title="Remove"
                  onClick={() => {
                    setIsShowConfirmDeleteEventReadersDialog(true);
                    setIsDeleteMultiple(stdEventReaderslistSelected.length > 1);
                  }}
                  disabled={stdEventReaderslistSelected.length === 0}
                />
              </div>
            }
            onSelectionChange={(dataItem: AccessControl[]) => {
              setStdEventReaderslistSelected(dataItem ?? []);
            }}
            itemPerPage={50}
            isAutoHiddenPager={false}
          />
        </div>
      </div>

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Event writers" />
          <CCGrid
            readOnly={isDisabled}
            columnFields={colGroupsOrgUser}
            primaryField={"EntityID"}
            data={valueGetter(
              `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "EventWriters"
              )}`
            )}
            selectableMode={"multiple"}
            selectedRows={stdEventWriterslistSelected}
            toolbar={
              <div className="cc-grid-tools-bar">
                <PickUserGroupOrgButton
                  onSubmit={(values: IDataUserGroupOrgForm) =>
                    handleAddNew(values, Svc_FormAction.PickEventWriter)
                  }
                  maxHeight="85%"
                />
                <Button
                  type="button"
                  iconClass="fas fa-minus"
                  title="Remove"
                  onClick={() => {
                    setIsShowConfirmDeleteEventWritersDialog(true);
                    setIsDeleteMultiple(stdEventWriterslistSelected.length > 1);
                  }}
                  disabled={stdEventWriterslistSelected.length === 0}
                />
              </div>
            }
            onSelectionChange={(dataItem: AccessControl[]) => {
              setStdEventWriterslistSelected(dataItem ?? []);
            }}
            itemPerPage={50}
            isAutoHiddenPager={false}
          />
        </div>
      </div>

      {(isShowConfirmDeleteStdReadersDialog ||
        isShowConfirmDeleteStdWritersDialog ||
        isShowConfirmDeleteEventReadersDialog ||
        isShowConfirmDeleteEventWritersDialog) && (
        <ConfirmDialog
          title={"Confirm Deletion"}
          subMessage={`Are you sure you want to delete ${
            isDeleteMultiple ? "these items" : "this item"
          }?`}
          onClosePopup={onCloseConfirmDeleteDialog}
          onConfirmYes={handleConfirmDelete}
        />
      )}
    </section>
  );
});
