import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { potentialDuplicateEntitiesRoute } from "./potential-duplicate-entities/route";

export const contactRoute: ICCRoute = {
  path: "contacts",
  name: "Contacts",
  enum: eMenuAction.Entities_Folder,
  children: [
    {
      path: "list",
      name: "List",
      enum: eMenuAction.Entities_List,
      component: require("./list/_index").default,
    },
    {
      path: "lrs-lot-owners",
      name: "LRS Lot Owners",
      enum: eMenuAction.Entities_LRSOwner,
      component: require("./lrs-lot-owners/_index").default,
    },
    potentialDuplicateEntitiesRoute,
    {
      path: "voters-list",
      name: "Voters List",
      enum: eMenuAction.Entities_VoterList,
      component: require("./voters-list/_index").default,
    },
  ],
};

export const contactManagePageRoute: ICCRoute = {
  path: "contact",
  name: "Contact",
  enum: eMenuAction.Entities_Folder,
  isVisible: false,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};

export const propertyContactRoute: ICCRoute[] = [
  contactRoute,
  contactManagePageRoute,
];
