import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { commonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { EPhoneNumberFormat } from "@components/cc-input-phone/model";

export const useIsEnableValidatePhoneNumber = () => {
  const settings = commonCoreStore.settings;
  return (
    getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_PhoneNumberFormat]
    ) === EPhoneNumberFormat.Format1
  );
};
