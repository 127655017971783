import {
  DTO_Create_Supplementary_LOVs_Response,
  DTO_Workflow_Supplementary_Associate,
  RequestAddSupplementaryObj,
} from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const getWorkflowAddToSupplementary = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_Supplementary_Associate>> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/addsupplementary/${workflowDraftId}`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowAddToSupplementary = async (
  payload: RequestAddSupplementaryObj
): Promise<APIResponse<DTO_Workflow_Supplementary_Associate>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/addsupplementary/new`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataAddToSupplementary =
  | [
      APIResponse<DTO_Create_Supplementary_LOVs_Response>,
      APIResponse<DTO_Workflow_Supplementary_Associate>
    ]
  | APIResponse<
      | DTO_Create_Supplementary_LOVs_Response
      | DTO_Workflow_Supplementary_Associate
    >;

export const getInitialDataAddToSupplementary = async (
  payload: RequestAddSupplementaryObj,
  workflowDraftId?: number
): Promise<IGetInitialDataAddToSupplementary> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Create_Supplementary_LOVs_Response>(
        `/api/property/internal/Supplementary/lovs`
      ),
      !isNil(workflowDraftId)
        ? getWorkflowAddToSupplementary(workflowDraftId)
        : getNewWorkflowAddToSupplementary(payload),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewSupplementary = async (
  mode: WorkflowProcessMode,
  newSupplementary?: DTO_Workflow_Supplementary_Associate
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_Supplementary_Associate>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/addsupplementary/process/${mode}`,
      newSupplementary
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
