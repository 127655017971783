import { Svc_ConditionReference } from "@app/products/local-laws/system-admin/conditions/model";

export const llSystemAdminConditionsBookmark = {
  listViewDisplayURL() {
    return "Local Laws - System Admin";
  },

  listViewDisplayTitle() {
    return "Local Laws - System Admin - Conditions";
  },

  recordDisplayURL(selectedRow: Svc_ConditionReference) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Local Laws - Conditions ${dynamicDisplayName}`;
  },
};
