import { PROPERTY_REGISTERS_ROUTE } from "@app/products/property/registers/[id]/constant";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
export const colList: IColumnFields[] = [
  {
    field: "AccountReference",
    title: "Account Reference",
    locked: true,
    linkTo: (dataItem) => {
      return `${PROPERTY_REGISTERS_ROUTE}/${dataItem.Id}`;
    },
  },
  {
    field: "Register",
    title: "Register",
  },
  {
    field: "Description",
    title: "Description",
  },
  { field: "Date", title: "Date", format: DATE_FORMAT.DATE },
  {
    field: "PropertyName",
    title: "Property Name",
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "PropertyLocality",
    title: "Property Locality",
  },
  {
    field: "Name",
    title: "Name",
  },
  {
    field: "Balance",
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  { field: "Status", title: "Status" },
  { field: "CreatedOn", title: "Created On", format: DATE_FORMAT.DATE },
  {
    field: "Id",
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
