import { DIRECT_DEBIT_SESSIONS } from "@app/products/direct-debit/constant";
import { DirectDebitStatusFilter } from "@app/products/direct-debit/model";
import { DirectDebitSessionHeader } from "@app/products/direct-debit/sessions/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DirectDebitSessionHeader>();

export const colDirectDebitSession: IColumnFields[] = [
  {
    field: nameOf("Session_Number"),
    title: "Session Number",
    locked: true,
    linkTo: (dataItem: DirectDebitSessionHeader) => {
      return `${DIRECT_DEBIT_SESSIONS}/` + dataItem.Session_ID;
    },
  },
  {
    field: nameOf("Bank_Account_Description"),
    title: "Bank Account",
  },
  {
    field: nameOf("Session_Creation_Date"),
    title: "Creation Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Transfer_Date"),
    title: "Transfer Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Status_Type_ENUM"),
    title: "Status",
    filterByEnum: DirectDebitStatusFilter,
  },
  {
    field: nameOf("No_Of_Transfers"),
    title: "Number Of Transfers",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("No_Of_Rejections"),
    title: "Number Of Rejections",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Total_Amount"),
    title: "Total Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Rejected_Amount"),
    title: "Rejected Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Session_ID"),
    title: "Session ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
