import {
  EventBatchUpdate,
  EventBatchUpdateLOV,
  EventBatchUpdateRequest,
} from "@app/products/crms/batch-update-event/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";

export const getBatchUpdateEventLovs = async (): Promise<
  APIResponse<EventBatchUpdateLOV | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<EventBatchUpdateLOV>(
      "api/crms/internal/event/batch-update-lovs"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const batchUpdateEventHandler = async (
  params: EventBatchUpdateRequest
): Promise<APIResponse<IIdentityPacket<EventBatchUpdate> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<EventBatchUpdate>
    >(`api/crms/internal/event/batch-update-handler`, params);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postBatchUpdateEvent = async (
  eventBatchUpdate: EventBatchUpdate
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "api/crms/internal/event/event-batch-update",
      eventBatchUpdate
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
