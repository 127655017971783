import { usePhoneOrTablet } from "@common/hooks/usePhoneOrTablet";
import { useCommonCoreStore } from "@common/stores/core/store";
import { CCLogo } from "@components/cc-logo/_index";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { useCCSlideActionBarStore } from "./store";
import "./_index.scss";

export interface ICCSlideActionBarProps {
  children?: ReactElement | ReactElement[];
}

export const CCSlideActionBar = observer(
  ({ children }: ICCSlideActionBarProps) => {
    const { isActive, setIsActive } = useCCSlideActionBarStore();
    const { headerBarLogo } = useCommonCoreStore();

    usePhoneOrTablet(() => setIsActive(false));

    return (
      <>
        <div
          className={`cc-slide-action-bar ${
            isActive ? "cc-slide-action-bar-show" : ""
          }`}
        >
          <div className="cc-header">
            <Link to="/" className="cc-link-transparent">
              {headerBarLogo && (
                <CCLogo
                  alt="Ready Tech"
                  className="cc-logo-link-transparent"
                  src={headerBarLogo}
                />
              )}
              <h4>ready community</h4>
            </Link>
          </div>
          <div className="cc-body">{children}</div>
        </div>
        <div
          className={`cc-slide-backdrop ${
            isActive ? "cc-slide-backdrop-show" : ""
          }`}
          onClick={() => {
            setIsActive(false);
          }}
        />
      </>
    );
  }
);
