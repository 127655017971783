import { useRegisterMetersStore } from "@app/products/property/registers/[id]/components/child-screens/meters/store";
import { ManualReadingsDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/manual-readings/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ManualReadingsButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { metersSelected } = useRegisterMetersStore();
  const { pushNotification } = useCCAppNotificationStore();
  const meterInfo = metersSelected[0];
  return (
    <>
      <CCNavButton
        title="Manual readings"
        onClick={() => {
          setIsShowDialog(true);
        }}
        disabled={metersSelected.length !== 1}
      />

      {isShowDialog && (
        <ManualReadingsDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          meterInfo={meterInfo}
          onSubmitManualReadings={() => {
            setIsShowDialog(false);
            pushNotification({
              title: `Manual readings entered successfully`,
              type: "success",
            });
          }}
        />
      )}
    </>
  );
});
