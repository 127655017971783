export const selectionAreaList = [
  {
    Key: "email_subject",
    Value: "Email Subject",
  },
  {
    Key: "email_body",
    Value: "Email Body",
  },
  {
    Key: "sms_message",
    Value: "SMS Message",
  },
];
