export const APIResponseStatus = {
  NETWORK_ERROR: 0,
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UN_AUTHORIZE: 401,
  NOT_FOUND: 404,
  TIME_OUT: 408,
  INTERNAL_SERVER_ERROR: 500,
  EXCEEDED_SIZE_LIMIT: 413,
  NON_AUTHORITATIVE: 203,
  FORBIDDEN: 403,
  CANCELLED: -1,
};
