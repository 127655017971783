import { DTO_LOV_Number } from "@common/models/odataResponse";

export const deferredDutySearchByList: DTO_LOV_Number[] = [
  {
    Code: 1,
    Name: "Property Address",
  },
  {
    Code: 2,
    Name: "Account Description",
  },
  {
    Code: 3,
    Name: "Assessment Owner/Ratepayer",
  },
  {
    Code: 4,
    Name: "Assessment Id",
  },
  {
    Code: 5,
    Name: "DDA Account Id",
  },
  {
    Code: 6,
    Name: "Account Number",
  },
];
