import { nameOfFactory } from "@common/utils/common";
import { colAddContactOfficer } from "@components/cc-contact-manager/config";
import { IOfficers } from "@components/cc-contact-manager/model";
import { GridSelectionEnhanceDialog } from "@components/dialog/dialog-grid-selection-enhance/_index";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

interface ICCContactManagerProps {
  onSubmit?: (data: any) => void;
  onClose?: (isShowDialog: boolean) => void;
  setShowDialog?: (isShowDialog: boolean) => void;
  dataUrl: string;
  isMultiple?: boolean;
  isLoading?: boolean;
  isCloseDialogAfterSubmit?: boolean;
}
const nameOf = nameOfFactory<IOfficers>();
export const CCContactManager = ({
  onSubmit = () => {},
  onClose = () => {},
  setShowDialog = () => {},
  isMultiple = true,
  dataUrl,
  isCloseDialogAfterSubmit,
  isLoading,
}: ICCContactManagerProps) => {
  return (
    <Form
      render={(formRenderProps: FormRenderProps) => {
        return (
          <GridSelectionEnhanceDialog
            dialog={{
              titleHeader: "Contact Manager",
              maxWidth: "55%",
              isLoading: isLoading,
              isCloseDialogAfterSubmit: isCloseDialogAfterSubmit,
            }}
            grid={{
              primaryField: nameOf("ID"),
            }}
            search={{
              hasSearchField: true,
              filterCol: nameOf("Name"),
            }}
            selectableMode={isMultiple ? "multiple" : "single"}
            dataUrl={dataUrl}
            columnFields={colAddContactOfficer}
            {...formRenderProps}
            setShowDialog={setShowDialog}
            onCloseDialog={() => onClose(false)}
            onSubmit={(data: IOfficers[]) => onSubmit(data)}
          />
        );
      }}
    />
  );
};
