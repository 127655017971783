import { PPRApplication } from "@app/products/town-planning/ppr/permit-referrals/all/register/model";
import { handlePermitReferralsAllLinkTo } from "@app/products/town-planning/ppr/permit-referrals/all/util";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<PPRApplication>();
export const colPprAllApplicationsByLocation: IColumnFields[] = [
  {
    field: nameOf("ApplicationNumber"),
    title: "Number",
    locked: true,
    linkTo: handlePermitReferralsAllLinkTo,
  },
  {
    field: nameOf("LodgedDate"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("RefTrigger"),
    title: "Referral Trigger",
  },
  {
    field: nameOf("SiteAddress"),
    title: "Site Address",
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("ResponsibleAuthority"),
    title: "Responsible Authority",
  },
  {
    field: nameOf("Planner"),
    title: "Planner",
  },
  {
    field: nameOf("TeamLeader"),
    title: "Delegated Approver",
  },
  {
    field: nameOf("Status_Name"),
    title: "Status",
  },
  {
    field: nameOf("DotOffice"),
    title: "DoT Region",
  },
  {
    field: nameOf("CouncilReferenceNo"),
    title: "Council Reference Number",
  },
  {
    field: nameOf("ResponseDue"),
    title: "Response Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("StatDays"),
    title: "Stat Days",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Rating"),
    title: "Rating",
  },
  {
    field: nameOf("Applicant"),
    title: "Applicant",
  },
  {
    field: nameOf("Postcode"),
    title: "Post Code",
  },
  {
    field: nameOf("ResponsibleAuthorityOfficer"),
    title: "Officer",
  },
  {
    field: nameOf("PlanningScheme"),
    title: "Planning Scheme",
  },
  {
    field: nameOf("DecisionDate"),
    title: "Decision Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Decision"),
    title: "Decision",
  },
  {
    field: nameOf("ApplicationType"),
    title: "Application Category",
  },
  {
    field: nameOf("FileNo"),
    title: "File Number",
  },
  {
    field: nameOf("Estate"),
    title: "Estate",
  },
  {
    field: nameOf("SpearRef"),
    title: "Spear Reference",
  },
  {
    field: nameOf("DeclaredArterial"),
    title: "Declared Arterial",
  },
];
