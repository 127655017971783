import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

export const colBalances: IColumnFields[] = [
  {
    field: "ChargeDate",
    title: "Charge Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "ChargeName",
    title: "Charge Name",
  },
  {
    field: "InstalmentNumber",
    title: "Instalment Number",
  },
  {
    field: "Levy",
    title: "Levy",
  },
  {
    field: "Balance",
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Balance", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "Interest",
    title: "Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Interest", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "ChargeBalanceId",
    title: "Charge Balance ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
export const colInstalments: IColumnFields[] = [
  {
    field: "InstalmentNumber",
    title: "Instalment Number",
  },
  {
    field: "InstalmentAmount",
    title: "Instalment Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Balance",
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "DueDate",
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "EffectiveDate",
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "InterestEffectiveDate",
    title: "Interest Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "RaisedUpTo",
    title: "Raised Up To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "Id",
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
export const colLevies: IColumnFields[] = [
  {
    field: "Levy",
    title: "Levy",
  },
  {
    field: "GrossAmount",
    title: "Gross Amount",

    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "NettAmount",
    title: "Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Balance",
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    // calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
    //   totalColumnField("Balance", gridData),
    // footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "Calculation",
    title: "Calculation",
  },
  {
    field: "Id",
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
