import { GridHeaderCellProps } from "@progress/kendo-react-grid";
import React, { ReactElement } from "react";
import "./_index.scss";
interface IGridHeaderCell extends Omit<GridHeaderCellProps, "children"> {
  children?: React.ReactNode;
  showFilter?: boolean;
  iconHeader?: ReactElement;
  id?: string;
}

export default function GridHeaderCell(props: IGridHeaderCell) {
  const { onClick, title, showFilter, children, iconHeader, id } = props;
  return (
    <span
      className={`cc-grid-header-cell ${!iconHeader ? "padding-right" : ""}`}
      onClick={onClick}
    >
      <span className="cc-grid-header-cell-title" id={id ?? ""}>
        {iconHeader ?? title}
      </span>
      {showFilter ? <span className="k-icon k-i-filter" /> : null}
      {children}
    </span>
  );
}
