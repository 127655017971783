import { ChargesDetails } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/components/steps/adjust-charges/components/detail/_index";
import { DTO_AssessmentCharge } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { FieldArray } from "@progress/kendo-react-form";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { cloneDeep, head, sumBy } from "lodash";
import React from "react";
import {
  ADJUST_CHARGE_VALIDATOR_MESSAGE,
  colAdjustCharges,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/components/steps/adjust-charges/config";

const formValidator = (values: DTO_AssessmentCharge[]) => {
  if (head(values)?.Adjustment_Amount) return;
  return ADJUST_CHARGE_VALIDATOR_MESSAGE;
};

export const AdjustChargesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? formValidator : undefined}
    />
  );
};

const nameOfAssessmentCharge = nameOfFactory<DTO_AssessmentCharge>();
const FormStepElement = ({
  formRenderProps: { valueGetter, onChange },
  options = {
    isReadOnly: false,
    assessmentId: 0,
    isLoadingOnNext: false,
  },
  nameOf,
}: IFormStepElement) => {
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const assessmentCharges = getFieldValue(""); //get data of step.

  const handleDataDetailChange = (levy: any[]) => {
    const adjustmentAmount = sumBy(levy, "Adjustment_Amount");
    const newAssessmentCharges: DTO_AssessmentCharge[] =
      cloneDeep(assessmentCharges);
    onChange(nameOf(""), {
      value:
        [
          {
            ...newAssessmentCharges?.[0],
            Adjustment_Amount: adjustmentAmount,
            ChargeLevies: {
              ...newAssessmentCharges?.[0]?.ChargeLevies,
              Levies: levy,
            },
          },
        ] ?? [],
    });
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            Charges being adjusted
            <CCTooltip
              type="validator"
              content={ADJUST_CHARGE_VALIDATOR_MESSAGE}
            />
          </label>
          <CCGrid
            data={assessmentCharges || []}
            columnFields={colAdjustCharges}
            primaryField={nameOfAssessmentCharge("Charge_Id")}
            readOnly={options.isReadOnly || options.isLoadingOnNext}
            detail={(props: GridDetailRowProps) => {
              return (
                <ChargesDetails
                  {...props}
                  onDataChange={handleDataDetailChange}
                  readOnly={options.isReadOnly || options.isLoadingOnNext}
                />
              );
            }}
          />
        </div>
      </div>
    </section>
  );
};
