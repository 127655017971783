import { DTO_Component_Entities } from "@app/products/property/parcels/[id]/components/child-screens/entities/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const getPropertyParcelEntities = async (
  parcelsId: number,
  includeInactive: boolean = false
): Promise<DTO_Component_Entities | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Component_Entities>(
        `api/property/internal/parcel/${parcelsId}/${includeInactive}/entities`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
