import { useAssessmentAmountOwingStore } from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import AmountOwingGrid from "./components/amount-owing-grid/_index";
import "./_index.scss";

export const DetailsAmountsOwing = observer(() => {
  const {
    amountOwing,
    isLoadingDetailPanel,
    loadAmountOwing,
    view,
    isShowPaidCharges,
    setIsShowPaidCharges,
    setView,
  } = useAssessmentAmountOwingStore();
  const { assessmentId } = useAssessmentStore();

  if (!amountOwing) return <NoData vertical />;

  /* ===========Effects ==============*/
  useEffect(() => {
    if (!assessmentId || !view) return;
    loadAmountOwing(assessmentId, view.Id, isShowPaidCharges);
  }, [assessmentId, view, isShowPaidCharges, loadAmountOwing]);
  return (
    <div className="cc-details-amounts-owing">
      <div className="cc-actions">
        <DropDownList
          className="cc-form-control"
          data={amountOwing.AmountsOwingViews.Views}
          value={view}
          textField="Name"
          dataItemKey="Id"
          onChange={(e: DropDownListChangeEvent) => {
            setView(e.target.value);
          }}
          disabled={isLoadingDetailPanel}
        />
        <Checkbox
          label={"Pending amounts"}
          disabled={isLoadingDetailPanel}
          className="cc-checkbox"
        />
        <Checkbox
          label={"Paid charges"}
          disabled={isLoadingDetailPanel}
          checked={isShowPaidCharges}
          onChange={(e: CheckboxChangeEvent) => {
            setIsShowPaidCharges(e.value);
          }}
        />
      </div>

      <div className="cc-details-amounts-grid-container">
        {isLoadingDetailPanel ? (
          <Loading isLoading={isLoadingDetailPanel} />
        ) : amountOwing && amountOwing.AmountsOwing ? (
          <AmountOwingGrid currentGroup={amountOwing.AmountsOwing} />
        ) : (
          <NoData vertical />
        )}
      </div>
    </div>
  );
});
