import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { ApplicationSettingText } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-text-input/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

export const ApplicationPlanningOnlineMapForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();

    if (!configData) return <></>;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_PlanningMapButtonLabel}
          />
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_PlanningMapUrl}
          />
        </div>
      </section>
    );
  }
);
