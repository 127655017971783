import { TransactionTotalsFieldMapping } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/child-screens/general/components/form-element/config";
import {
  DTO_RebateClaim_Summary,
  DTO_RebateClaim_TransactionTotals,
  VO_Rebate_Transaction,
} from "@app/products/property/assessments/rebates/rebate-claims/[id]/model";
import { useRebateClaimStore } from "@app/products/property/assessments/rebates/rebate-claims/[id]/store";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { NumberCell } from "@components/cc-grid/components/grid-cells/number/_index";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { cloneDeep, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { ReactElement, useEffect, useState } from "react";
import { REBATE_TRANSACTION_GRID_ID } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/child-screens/general/components/form-element/constant";

interface IProps {
  formRenderProps: FormRenderProps;
}

const nameOfSummary = nameOfFactory<DTO_RebateClaim_Summary>();
const nameOfTransactions = nameOfFactory<VO_Rebate_Transaction>();
export const RebateClaimFormElement = observer(
  ({ formRenderProps }: IProps): ReactElement => {
    const {
      rebateClaim,
      columnSummary,
      columnTransactions,
      selectedTransactions,
      setSelectedTransactions,
    } = useRebateClaimStore();
    const [colClaimSummary, setColClaimSummary] = useState<IColumnFields[]>([]);

    useEffect(() => {
      if (!columnSummary.length) return;
      const tempCol = cloneDeep(columnSummary);
      if (rebateClaim?.RebateTransactionSummary?.Summary?.length) {
        tempCol.forEach((item: IColumnFields) => {
          const totalsFieldName = TransactionTotalsFieldMapping[
            item.field
          ] as keyof DTO_RebateClaim_TransactionTotals;
          if (
            Object.keys(
              rebateClaim?.RebateTransactionSummary?.Totals as Object
            ).includes(totalsFieldName)
          ) {
            if (
              !isNil(
                rebateClaim?.RebateTransactionSummary?.Totals?.[totalsFieldName]
              )
            ) {
              const format: any = item.format;
              switch (true) {
                case Object.values(NUMBER_FORMAT).includes(format):
                  item.footerCell = (
                    <NumberCell
                      value={
                        rebateClaim?.RebateTransactionSummary?.Totals?.[
                          totalsFieldName
                        ] as number
                      }
                    />
                  );
                  break;
                case Object.values(CURRENCY_FORMAT).includes(format):
                  item.footerCell = (
                    <CurrencyCell
                      value={
                        rebateClaim?.RebateTransactionSummary?.Totals?.[
                          totalsFieldName
                        ] as number
                      }
                    />
                  );
                  break;
                default:
                  item.footerCell = (
                    <TextCell
                      value={
                        rebateClaim?.RebateTransactionSummary?.Totals?.[
                          totalsFieldName
                        ] as string
                      }
                    />
                  );
                  break;
              }
            }
          }
        });
      }
      setColClaimSummary(tempCol);
    }, [rebateClaim, columnSummary]);

    return (
      <FormElement>
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Claim summary totals</label>
              <CCGrid
                data={rebateClaim?.RebateTransactionSummary?.Summary ?? []}
                columnFields={colClaimSummary}
                primaryField={nameOfSummary("Id")}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Rebate transaction included in claim
              </label>
              <CCGrid
                gridId={REBATE_TRANSACTION_GRID_ID}
                dataUrl={`odata/property/internal/rebatetransactionsregister/${rebateClaim?.Id}?$count=true&`}
                columnFields={columnTransactions}
                primaryField={nameOfTransactions("Transaction_Id")}
                selectableMode="multiple"
                selectedRows={selectedTransactions}
                onSelectionChange={(dataItem) => {
                  setSelectedTransactions(dataItem ? [...dataItem] : []);
                }}
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
