import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { SwitchSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/switch/_index";
import { myActivityAddressDisplayModelData } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-portal/portal-submission-settings/config";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { KeyWordsPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/_index";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

const nameOfCoreKeyword = nameOfFactory<CoreKeyword>();
export const PublicPortalPortalSubmissionSettingForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { isEditing } = useSettingPPRManageStore();
    const { valueGetter, onChange } = formRenderProps;
    const { getConfigDataField } = useSettingPPRStore();

    const fieldRestrictObjections = getConfigDataField(
      ECorporateSettingsField.TP_PortalRestrictObjections
    );
    const fieldEnableSubmissionType = getConfigDataField(
      ECorporateSettingsField.TP_PortalSubmissionType
    );
    const fieldEnableConditionalSupporter = getConfigDataField(
      ECorporateSettingsField.TP_PortalEnableConditionalSupporter
    );
    const fieldObjector = getConfigDataField(
      ECorporateSettingsField.TP_PortalObjector
    );
    const fieldSupporter = getConfigDataField(
      ECorporateSettingsField.TP_PortalSupporter
    );
    const fieldMyActivityAddressDisplay = getConfigDataField(
      ECorporateSettingsField.TP_ObjectionActivityAddressDisplay
    );

    const objectorValueDisplayDetails: CoreKeyword[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_PortalObjector}`
      ) ?? [];
    const supporterValueDisplayDetails: CoreKeyword[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_PortalSupporter}`
      ) ?? [];

    const handleOnChangeObjector = (coreKeyword: CoreKeyword) => {
      onChange(fieldObjector?.FieldName ?? "", {
        value: coreKeyword.Keyword_ID.toString(),
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_PortalObjector}`,
        {
          value: [coreKeyword],
        }
      );
    };
    const handleOnChangeSupporter = (coreKeyword: CoreKeyword) => {
      onChange(fieldSupporter?.FieldName ?? "", {
        value: coreKeyword.Keyword_ID.toString(),
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_PortalSupporter}`,
        {
          value: [coreKeyword],
        }
      );
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <SwitchSettings
            data={fieldRestrictObjections}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          {fieldEnableSubmissionType && (
            <div className="cc-field">
              <CCLabel
                title={fieldEnableSubmissionType?.Title ?? ""}
                isMandatory={fieldEnableSubmissionType.IsMandatory}
              />
              <Field
                name={fieldEnableSubmissionType?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={formRenderProps?.valueGetter(
                  fieldEnableSubmissionType?.FieldName
                )}
              />
            </div>
          )}
        </div>
        <div className="cc-form-cols-1">
          {fieldEnableConditionalSupporter && (
            <div className="cc-field">
              <CCLabel
                title={fieldEnableConditionalSupporter?.Title ?? ""}
                isMandatory={fieldEnableConditionalSupporter.IsMandatory}
              />
              <Field
                name={fieldEnableConditionalSupporter?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={formRenderProps?.valueGetter(
                  fieldEnableConditionalSupporter?.FieldName
                )}
              />
            </div>
          )}
          {fieldObjector &&
            valueGetter(
              ECorporateSettingsField.TP_PortalSubmissionType.toString()
            ) && (
              <div className="cc-field">
                <CCLabel
                  title={fieldObjector?.Title ?? ""}
                  isMandatory={fieldObjector.IsMandatory}
                />
                <Field
                  name={fieldObjector?.FieldName ?? ""}
                  placeholder={fieldObjector?.Title ?? ""}
                  value={objectorValueDisplayDetails[0] ?? null}
                  component={KeyWordsPicker}
                  keyWordType={KEYWORD_TYPE.Core_ContactRole}
                  productType={PRODUCT_TYPE_NUMBER.TownPlanning}
                  onPickKeyWords={handleOnChangeObjector}
                  isGetDetail={false}
                  selectableMode={"single"}
                  disabled={!isEditing}
                  textField={nameOfCoreKeyword("Name")}
                  textProduce={(data: CoreKeyword) => data.Name}
                  validator={
                    fieldObjector.IsMandatory ? requiredValidator : undefined
                  }
                />
              </div>
            )}
          {fieldSupporter &&
            valueGetter(
              ECorporateSettingsField.TP_PortalSubmissionType.toString()
            ) && (
              <div className="cc-field">
                <CCLabel
                  title={fieldSupporter?.Title ?? ""}
                  isMandatory={fieldSupporter.IsMandatory}
                />
                <Field
                  name={fieldSupporter?.FieldName ?? ""}
                  placeholder={fieldSupporter?.Title ?? ""}
                  value={supporterValueDisplayDetails[0] ?? null}
                  component={KeyWordsPicker}
                  keyWordType={KEYWORD_TYPE.Core_ContactRole}
                  productType={PRODUCT_TYPE_NUMBER.TownPlanning}
                  onPickKeyWords={handleOnChangeSupporter}
                  isGetDetail={false}
                  selectableMode={"single"}
                  disabled={!isEditing}
                  textField={nameOfCoreKeyword("Name")}
                  textProduce={(data: CoreKeyword) => data.Name}
                  validator={
                    fieldSupporter.IsMandatory ? requiredValidator : undefined
                  }
                />
              </div>
            )}
          {fieldMyActivityAddressDisplay && (
            <div className="cc-field">
              <CCLabel
                title={fieldMyActivityAddressDisplay?.Title ?? ""}
                isMandatory={fieldMyActivityAddressDisplay.IsMandatory}
              />
              <Field
                name={fieldMyActivityAddressDisplay?.FieldName}
                component={RadioGroup}
                layout={"horizontal"}
                data={myActivityAddressDisplayModelData}
                disabled={!isEditing}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
