import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { VO_Notice_Assessment_NoticeGroup } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/model";

const nameOf = nameOfFactory<VO_Notice_Assessment_NoticeGroup>();
export const colNoticeGroupsDetail: IColumnFields[] = [
  {
    field: nameOf("Assess_Number"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Assess_Reference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("Assess_Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("NA_Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Total_Balance"),
    title: "Total Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Current_Balance"),
    title: "Current Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
