import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { ResponsePacket } from "@common/models/identityPacket";

export const postCRMSStandardDocument = async (
  serviceStandardID: number,
  documentIDs: number[]
): Promise<APIResponse<ResponsePacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<ResponsePacket>(
      `api/crms/internal/service-standard/add-ss-document/${serviceStandardID}`,
      documentIDs
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
