import { NewAddressDialog } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/_index";
import {
  AddressValidationDialog,
  IOnAction,
} from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/address-validation/_index";
import {
  getValidationPhysicalAddress,
  postAddressValidationCandidate,
} from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/api";
import {
  EStatusValidation,
  IEditAddAddressLOVs,
} from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/models";
import { EAddressValidation } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/validation-adress-search/model";
import { validatorPhysicalAddress } from "@app/products/property/assessments/components/form-steps/modify-assessment/util";
import { colPhysicalAddress } from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/physical-address-grid/config";
import { getAddressTypeName } from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/physical-address-grid/util";
import {
  DTO_Address,
  EKeysOfSteps,
} from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { isSuccessResponse } from "@common/apis/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { commonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { getUUID, nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { cloneDeep, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useRef, useState } from "react";

export const PHYSICAL_FORM_STEP = EKeysOfSteps.PhysicalAddress;
export const PhysicalAddressGridFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={
        !props?.options?.isReadOnly
          ? props?.options?.isLLS && props?.options?.isFromNewAssessment
            ? undefined
            : validatorPhysicalAddress
          : undefined
      }
    />
  );
};

const nameOfAddress = nameOfFactory<DTO_Address>();

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isLLS: false,
      isActro: false,
      isFromNewAssessment: false,
      setIsLoadingDialog: () => {},
      assessmentLOVs: {},
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const [newPhysicalAddressData, setNewPhysicalAddressData] = useState<any>();
    const [dataValidationDialog, setDataValidationDialog] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [updatePhysicalAddressData, setUpdatePhysicalAddressData] =
      useState<any>();
    const address = getFieldValue("") ?? [];
    const addressSelected = getFieldValue("AddressSelected") ?? [];
    let initNewAddressDialog = {
      Country: valueGetter(`${EKeysOfSteps.AssessmentDetail}.Country`),
      State: valueGetter(`${EKeysOfSteps.AssessmentDetail}.State`),
    };
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const initEditAddAddressLOVs: IEditAddAddressLOVs = useMemo(() => {
      return {
        Address_Side_of_Street: options?.assessmentLOVs?.Address_Side_of_Street,
        Address_State: options?.assessmentLOVs?.Address_State,
        Address_FloorType: options?.assessmentLOVs?.Address_FloorType,
        Address_UnitType: options?.assessmentLOVs?.Address_UnitType,
        Address_Type: options?.assessmentLOVs?.Address_Type,
        Country: options?.assessmentLOVs?.Country,
      };
    }, [options?.assessmentLOVs]);

    const settings = commonCoreStore.settings;
    const isShowSearchAddressValidation = useMemo(() => {
      return (
        getNumberValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_AddressValidation]
        ) !== EAddressValidation.None
      );
    }, [settings]);

    const handleValidate = async (dataAction: IOnAction, addressData: any) => {
      const { status, dataValidate } = dataAction;
      const { data, isUpdate } = addressData;
      let addressTypeName = null;
      if (!isNil(data?.AddressTypeId)) {
        addressTypeName = getAddressTypeName(
          data?.AddressTypeId + "",
          options?.assessmentLOVs?.Address_Type ?? []
        );
      }
      if (status === EStatusValidation.OK) {
        setDataValidationDialog(undefined);
        setIsLoading(true);
        options?.setIsLoadingDialog(true);
        if (!isNil(dataValidate)) {
          const response = await postAddressValidationCandidate(
            dataValidate?.id,
            { ...data, AddressTypeName: addressTypeName }
          );
          if (isSuccessResponse(response) && response?.data) {
            const responseValidation = await getValidationPhysicalAddress(
              response?.data
            );
            if (
              isSuccessResponse(responseValidation) &&
              responseValidation?.data
            ) {
              if (isUpdate) {
                handleUpdateItem({
                  ...responseValidation?.data,
                  RowId: data?.RowId,
                  AddressTypeName: addressTypeName,
                });
              } else {
                handleAddItem({
                  ...responseValidation?.data,
                  AddressTypeName: addressTypeName,
                });
              }
            } else {
              notificationRef.current?.pushNotification({
                title: "Get Address validation failed",
                type: "error",
              });
            }
          } else {
            notificationRef.current?.pushNotification({
              title: "Get Address validation failed",
              type: "error",
            });
          }
        }
      } else if (status === EStatusValidation.SKIP) {
        setDataValidationDialog(undefined);
        const newData = {
          ...data,
          IsAddressValid: false,
          AddressTypeName: addressTypeName,
        };
        if (isUpdate) {
          handleUpdateItem(newData);
        } else {
          handleAddItem(newData);
        }
      } else if (
        status === EStatusValidation.CANCEL ||
        EStatusValidation.ClOSE
      ) {
        setDataValidationDialog(undefined);
        if (isUpdate) {
          setUpdatePhysicalAddressData(data);
        } else {
          setNewPhysicalAddressData(data);
        }
      }
      setIsLoading(false);
      options?.setIsLoadingDialog(false);
    };

    const handleProcessData = (data: DTO_Address, isUpdate: boolean) => {
      if (isNil(data)) return;
      if (isShowSearchAddressValidation) {
        setDataValidationDialog({ data, isUpdate });
      } else {
        if (updatePhysicalAddressData) {
          handleUpdateItem(data);
        }
        if (newPhysicalAddressData) {
          handleAddItem(data);
        }
      }
    };

    /**
     * handle add item
     * @param data
     */
    const handleAddItem = (data: DTO_Address) => {
      const newItemId = getUUID();
      const newData = {
        ...data,
        RowId: newItemId,
      };
      const previousAddress = address?.length
        ? [...address, newData]
        : [newData];
      // If new data have isAddressPrimary=true => reset all of isAddressPrimary
      //  Only one isAddressPrimary = true
      if (data?.IsAddressPrimary && previousAddress?.length > 0) {
        handleSetPrimaryKey(newItemId, previousAddress, false);
        return;
      }

      onChange(nameOf(""), {
        value: previousAddress,
      });
    };

    /**
     * handle add item
     * @param data
     */
    const handleUpdateItem = (data: DTO_Address) => {
      const newAddress =
        address?.map((item: DTO_Address) => {
          return item?.RowId === data?.RowId ? { ...item, ...data } : item;
        }) ?? [];

      if (data?.IsAddressPrimary && newAddress?.length > 0) {
        handleSetPrimaryKey(data?.RowId, newAddress, false);
        return;
      }
      onChange(nameOf(""), {
        value: newAddress,
      });
    };

    /**
     * handle delete item
     */
    const handleDeleteItem = () => {
      const addressId = addressSelected?.[0]?.RowId;
      const newAddress = address.filter(
        (item: DTO_Address) => addressId !== item.RowId
      );
      onChange(nameOf(""), {
        value: newAddress,
      });
      onChange(nameOf("AddressSelected"), {
        value: [],
      });
    };

    /**
     * handle set Primary key
     * @param id
     * @param addressList
     * @param isResetSelected
     */
    const handleSetPrimaryKey = (
      id: number | string,
      addressList: DTO_Address[],
      isResetSelected: boolean = true
    ) => {
      if (!id || !addressList.length) return;
      const newAddress = addressList.map((item: DTO_Address) => ({
        ...item,
        IsAddressPrimary: id === item.RowId,
      }));
      onChange(nameOf(""), {
        value: newAddress ?? [],
      });
      if (isResetSelected) {
        onChange(nameOf("AddressSelected"), {
          value: [],
        });
      }
    };

    const renderNamesColumn = useMemo(() => {
      let newCols = cloneDeep(colPhysicalAddress);
      // Do nothing while in readOnly mode
      if (options?.isReadOnly) return newCols;
      //Update handle function for PropertyAddress field
      newCols = newCols.map((col: IColumnFields) => {
        // Add handle OnClick action for field PropertyAddress
        if (col.field === nameOfAddress("PropertyAddress")) {
          col.handleOnClick = (dataItem: DTO_Address) => {
            setUpdatePhysicalAddressData(dataItem);
          };
        }
        return col;
      });
      if (isShowSearchAddressValidation) {
        return newCols;
      } else {
        return newCols?.filter((column: IColumnFields) => {
          return column.field !== nameOfAddress("IsAddressValid");
        });
      }
    }, [isShowSearchAddressValidation, options?.isReadOnly]);

    const renderShowValidator = useCallback(() => {
      if (options?.isActro && options?.isFromNewAssessment) {
        return <CCTooltip type="validator" position="right" />;
      } else if (options?.isLLS && !options?.isFromNewAssessment) {
        return <CCTooltip type="validator" position="right" />;
      }
      return null; // Return null if no tooltip is to be shown
    }, [options?.isActro, options?.isFromNewAssessment, options?.isLLS]);

    return (
      <section className="cc-field-group">
        <CCLocalNotification ref={notificationRef} />
        <label className="cc-label">
          Physical address
          {renderShowValidator()}
          <CCTooltip
            type="custom"
            position="auto"
            content=" "
            customTemplate={
              <div>
                • Please add at least one address
                <br />• At least one address must be set as primary
              </div>
            }
          >
            <i className="fa fa-info-circle ml-1 text-accent" />
          </CCTooltip>
          {errors?.[nameOf("")] ? <Error>{errors[nameOf("")]}</Error> : null}
        </label>
        <div className="cc-form-cols-1">
          <CCGrid
            toolbar={
              <div className="cc-grid-tools-bar">
                <Button
                  type="button"
                  disabled={
                    isLoading ||
                    options?.isReadOnly ||
                    address.length <= 0 ||
                    (addressSelected?.length === 1 &&
                      addressSelected?.[0]?.IsAddressPrimary) ||
                    !addressSelected?.length
                  }
                  themeColor="primary"
                  onClick={() =>
                    handleSetPrimaryKey(addressSelected?.[0]?.RowId, address)
                  }
                >
                  Set as primary
                </Button>
                <Button
                  iconClass="fas fa-plus"
                  type="button"
                  onClick={() => {
                    setNewPhysicalAddressData(initNewAddressDialog);
                  }}
                  disabled={options?.isReadOnly || isLoading}
                />
                <Button
                  type="button"
                  iconClass="fas fa-minus"
                  disabled={
                    isLoading ||
                    options?.isReadOnly ||
                    address.length <= 0 ||
                    !addressSelected?.length
                  }
                  onClick={() => {
                    handleDeleteItem();
                  }}
                />
              </div>
            }
            className="cc-address-for-parcel-grid"
            columnFields={renderNamesColumn}
            data={address ?? []}
            primaryField={nameOfAddress("RowId")}
            readOnly={options?.isReadOnly}
            selectableMode="single"
            selectedRows={addressSelected ?? []}
            onSelectionChange={(dataItem: any[]) => {
              onChange(nameOf("AddressSelected"), {
                value: dataItem ?? [],
              });
            }}
            editableMode={!options?.isReadOnly ? "cell" : undefined}
            isLoading={isLoading}
          />
          {newPhysicalAddressData && (
            <NewAddressDialog
              onClose={() => setNewPhysicalAddressData(undefined)}
              handleAddAddress={(data: DTO_Address) => {
                handleProcessData(data, false);
                setNewPhysicalAddressData(undefined);
              }}
              subId={valueGetter(`${EKeysOfSteps.AssessmentDetail}.Suburb`)}
              initialValue={newPhysicalAddressData}
              assessmentLOVs={initEditAddAddressLOVs}
            />
          )}
          {updatePhysicalAddressData ? (
            <NewAddressDialog
              onClose={() => setUpdatePhysicalAddressData(undefined)}
              handleUpdateAddress={(data: DTO_Address) => {
                handleProcessData(data, true);
                setUpdatePhysicalAddressData(undefined);
              }}
              subId={valueGetter(`${EKeysOfSteps.AssessmentDetail}.Suburb`)}
              dataItem={updatePhysicalAddressData}
              assessmentLOVs={initEditAddAddressLOVs}
            />
          ) : null}
        </div>
        {!isNil(dataValidationDialog) ? (
          <AddressValidationDialog
            address={dataValidationDialog?.data}
            onAction={(dataAction) =>
              handleValidate(dataAction, dataValidationDialog)
            }
          />
        ) : null}
      </section>
    );
  }
);
