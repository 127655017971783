import { ExistedPSARInfo } from "@app/products/town-planning/ppr/psa-referrals/_id/components/forms/existed/_index";
import { NewPSARInfo } from "@app/products/town-planning/ppr/psa-referrals/_id/components/forms/new/_index";
import { IPSARApplicationParentSection } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManagePSARInfo = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const psarInfoId = params.id;
  const { resetStore, loadPPR, setParentSection } = usePSARStore();
  const { resetMenu } = useCCSubActionBarStore();
  const location = useLocation();
  useEffect(() => {
    loadPPR(parseInt(psarInfoId), isNew, true);
  }, [psarInfoId, isNew, loadPPR]);

  useEffect(() => {
    if (location.state) {
      setParentSection(location.state as IPSARApplicationParentSection);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });

  if (isNew) {
    return <NewPSARInfo />;
  }
  return <ExistedPSARInfo />;
};

export default observer(ManagePSARInfo);
