import { ICCRoute } from "@common/constants/ICCRoute";

export const centreBasedCareRoute: ICCRoute = {
  path: "centre-based-care",
  name: "Centre Based Care",
  children: [
    {
      path: "all-by-preferences",
      name: "All By Preferences",
      component: require("./all-by-preferences/_index").default,
    },
    {
      path: "all-by-weighting",
      name: "All By Weighting",
      component: require("./all-by-weighting/_index").default,
    },
    {
      path: "all-by-status",
      name: "All By Status",
      component: require("./all-by-status/_index").default,
    },
    {
      path: "wait-list-by-centre",
      name: "Wait List by Centre",
      component: require("./wait-list-by-centre/_index").default,
    },
    {
      path: "offers",
      name: "Offers",
      component: require("./offers/_index").default,
    },
    {
      path: "placements",
      name: "Placements",
      component: require("./placements/_index").default,
    },
    {
      path: "cancellations",
      name: "Cancellations",
      component: require("./cancellations/_index").default,
    },
  ],
};
