import { ChangeApplicationNumber } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-application-number/model";
import { PPRIdentityPacket } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/fi-not-required/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const putChangeApplicationNumber = async (
  id: number,
  reqBody: ChangeApplicationNumber
): Promise<APIResponse<PPRIdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<PPRIdentityPacket>(
      `api/tp/ppr/internal/application/${id}/applicationnumber`,
      reqBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
