import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colNoticeRun: IColumnFields[] = [
  {
    field: "NameAddress",
    title: "Name Address",
  },
  {
    field: "PrincipalBalance",
    title: "Principal Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "AmountDue",
    title: "Amount Due",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "OpeningBalance",
    title: "Opening Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "OpeningAmountDue",
    title: "Opening Amount Due",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "CurrentAmountDue",
    title: "Current Amount Due",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "PrincipalArrears",
    title: "Principal Arrears",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "ArrearsAmountDue",
    title: "Arrears Amount Due",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "PrincipalDue",
    title: "Principal Due",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "InterestBalance",
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "OtherBalances",
    title: "Other Balances",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "BalanceOutstanding",
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "PayoutAmount",
    title: "Payout Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "AccountReference",
    title: "Account Reference",
  },
  {
    field: "NoticeNumber",
    title: "Notice for A/C Number",
  },
  {
    field: "Scheme",
    title: "Scheme",
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  {
    field: "SchemeAccountId",
    title: "Scheme Account ID",
    style: formatStyleUnRelevant,
  },
  {
    field: "NoticeId",
    title: "Notice ID",
    style: formatStyleUnRelevant,
  },
];
