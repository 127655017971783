import {
  apiCoreGetHomepageViewsById,
  apiCoreSaveHomepageViews,
} from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import { HomePageView } from "@common/pages/settings/homepage/homepage-views/model";

export const getHomepageViewById = async (
  homepageId: number
): Promise<APIResponse<HomePageView | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<HomePageView>(
      apiCoreGetHomepageViewsById(homepageId)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSaveHomepageViews = async (
  homepageView: HomePageView
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreSaveHomepageViews(),
      homepageView
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
