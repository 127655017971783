import {
  DTO_Owners,
  DTO_Parcel,
  DTO_Title,
} from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import { IconEditCell } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/components/icon-edit-cell/_index";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfParcel = nameOfFactory<DTO_Parcel>();
export const colParcelRebate: IColumnFields[] = [
  {
    field: nameOfParcel("Is_Entitle"),
    title: "To be Levied?",
    format: ICON_FORMAT.ICON_BOOLEAN_WITHOUT_CROSS,
    editable: true,
    editCell: IconEditCell,
  },
  {
    field: nameOfParcel("ParcelReference"),
    title: "Parcel (S-S-B-U)",
  },
  {
    field: nameOfParcel("PropertyAddress"),
    title: "Address",
  },
  {
    field: nameOfParcel("Locality"),
    title: "Locality",
  },
  {
    field: nameOfParcel("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOfParcel("CensusDistrict"),
    title: "Census District",
  },
  {
    field: nameOfParcel("ElectoralDivision"),
    title: "Electoral Division",
  },
  {
    field: nameOfParcel("StateCodeElectoralDivision"),
    title: "State Electoral Division",
  },
  {
    field: nameOfParcel("FederalElectoralDivision"),
    title: "Federal Electoral Division",
  },
  {
    field: nameOfParcel("LandArea"),
    title: "Land Area",
  },
  {
    field: nameOfParcel("Zones"),
    title: "Zones",
  },
  {
    field: nameOfParcel("LandUse"),
    title: "Land Use",
  },
  {
    field: nameOfParcel("OwnerName"),
    title: "Owners",
  },
  {
    field: nameOfParcel("Status"),
    title: "Status",
  },
  {
    field: nameOfParcel("Parcel_Id"),
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

const nameOfTitle = nameOfFactory<DTO_Title>();
export const colLotRebate: IColumnFields[] = [
  {
    field: nameOfTitle("Is_Entitle"),
    title: "To be Levied?",
    format: ICON_FORMAT.ICON_BOOLEAN_WITHOUT_CROSS,
    editable: true,
    editCell: IconEditCell,
  },
  {
    field: nameOfTitle("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOfTitle("Status"),
    title: "Status",
  },
  {
    field: nameOfTitle("Lot"),
    title: "Lot",
  },
  {
    field: nameOfTitle("AssociationFactorPercentage"),
    title: "Factor %",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfTitle("PlanType"),
    title: "Plan Type",
  },
  {
    field: nameOfTitle("PlanNumber"),
    title: "Plan Number",
  },
  {
    field: nameOfTitle("Section"),
    title: "Section",
  },
  {
    field: nameOfTitle("NameAddress"),
    title: "Name Address",
  },
  {
    field: nameOfTitle("Volume"),
    title: "Volume",
  },
  {
    field: nameOfTitle("Folio"),
    title: "Folio",
  },
  {
    field: nameOfTitle("County"),
    title: "County",
  },
  {
    field: nameOfTitle("Parish"),
    title: "Parish",
  },
  {
    field: nameOfTitle("DivisionDate"),
    title: "Division Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfTitle("Frontage"),
    title: "Frontage",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOfTitle("LandArea"),
    title: "Land Area (m2)",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfTitle("IsAreaSurveyed"),
    title: "Is Area Surveyed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfTitle("Coverage"),
    title: "Coverage",
  },
  {
    field: nameOfTitle("TitleId"),
    title: "Lot ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

const nameOfOwner = nameOfFactory<DTO_Owners>();
export const colOwnerEntitledRebate: IColumnFields[] = [
  {
    field: nameOfOwner("Is_Entitle"),
    title: "Entitle?",
    format: ICON_FORMAT.ICON_BOOLEAN_WITHOUT_CROSS,
    editable: true,
    editCell: IconEditCell,
  },
  {
    field: nameOfOwner("Name"),
    title: "Owner/Life Tenant",
  },
  {
    field: nameOfOwner("Ownership_Percentage"),
    title: "Ownership Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
];
