import { IJournalProps, Journal } from "@app/core/journal/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export interface IPropertyHistoryTabCommonProps
  extends Omit<IJournalProps, "id"> {
  id?: string;
}

export const PropertyHistoryTabCommon = observer(
  (props: IPropertyHistoryTabCommonProps) => {
    const { id, ...others } = props;
    const params: { id: string } = useParams();
    const manangePageId = params.id;
    const recordID: string = id ?? manangePageId ?? 0;
    return <Journal id={recordID} {...others} />;
  }
);
