import { useRegisterMetersStore } from "@app/products/property/registers/[id]/components/child-screens/meters/store";
import ScheduleSpecialReadingsDialog from "@app/products/property/registers/[id]/components/dialogs/schedule-special-readings/_index";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ScheduleSpecialReadingsButton = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { register, loadRegister } = useRegisterStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { metersSelected } = useRegisterMetersStore();

  useEffectOnce(() => {
    if (!id) {
      loadRegister(parseInt(id));
    }
  });

  const initialDataDialog = useMemo(() => {
    if (!id) return;
    return {
      PropertyAddress: register?.PropertyAddress ?? "",
      AccountName: register?.AccountName,
      Assessment: {
        AccountReference: register?.AccountReference,
        AssessmentNumber: "3400000.0061",
        _searchByAssessmentNumber: `3400000.0061 - ${
          register?.PropertyAddress ?? ""
        }`,
        _searchByAccountReference: `${register?.AccountReference} - ${
          register?.AccountName ?? ""
        }`,
      },
      Register: register,
      Meters: [...toJS(metersSelected)],
    };
  }, [id, register, metersSelected]);

  return (
    <>
      <CCNavButton
        title="Schedule special readings"
        onClick={() => setShowDialog(true)}
        disabled={!metersSelected.length}
      />
      {showDialog && register && (
        <ScheduleSpecialReadingsDialog
          onClose={() => setShowDialog(false)}
          onSubmit={() => {
            pushNotification({
              title: "Schedule special readings successfully",
              type: "success",
            });

            setShowDialog(false);
          }}
          initialDataDialog={initialDataDialog}
        />
      )}
    </>
  );
});
