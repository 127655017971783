import { colContactAssociationParcel } from "@app/products/property/components/associations/components/parcel/contact-parcel/config";
import { DTO_AssociatedItem_Parcel } from "@app/products/property/components/associations/components/parcel/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
interface IContactParcelProps {
  data: DTO_AssociatedItem_Parcel[];
}

const nameOf = nameOfFactory<DTO_AssociatedItem_Parcel>();
export const ContactAssociationParcel = observer(
  ({ data }: IContactParcelProps) => {
    const [processedCol, setProcessedCol] = useState<IColumnFields[]>(
      colContactAssociationParcel
    );

    //Get labels
    const [legalDescriptionLabel, parcelReferenceLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.Legal_Description,
        ECustomColNameProperty.ParcelReference,
      ]);

    useEffectOnce(() => {
      const newCols = colContactAssociationParcel.map(
        (column: IColumnFields) => {
          switch (column.field) {
            case nameOf("LegalDescription"):
              return {
                ...column,
                title: legalDescriptionLabel ?? column.title,
                id: `cc-grid-cell-${ECustomColNameProperty.Legal_Description}`,
              };
            case nameOf("ParcelReference"):
              return {
                ...column,
                title: parcelReferenceLabel ?? column.title,
                id: `cc-grid-cell-${ECustomColNameProperty.ParcelReference}`,
              };
            default:
              return column;
          }
        }
      );
      setProcessedCol(newCols);
    });

    return (
      <CCGrid
        data={data}
        columnFields={processedCol}
        primaryField={nameOf("Id")}
      />
    );
  }
);
