import {
  DTO_RebateClaim_LOVs,
  DTO_Workflow_CreateRebateClaim,
  RequestObjCreateRebateClaim,
} from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/form-steps/new-rebate-claim/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const getCreateRebateClaimLOVs = async (): Promise<
  APIResponse<DTO_RebateClaim_LOVs>
> => {
  try {
    return await CoreAPIService.getClient().get<DTO_RebateClaim_LOVs>(
      `/api/property/internal/workflow/createdrebateclaim/lovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowCreateRebateClaim = async (
  requestObj: RequestObjCreateRebateClaim | object = {}
): Promise<APIResponse<DTO_Workflow_CreateRebateClaim>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/createrebateclaim/new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSavedWorkflowCreateRebateClaim = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_CreateRebateClaim>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/createrebateclaim/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataCreateRebateClaim =
  | [
      APIResponse<DTO_RebateClaim_LOVs>,
      APIResponse<DTO_Workflow_CreateRebateClaim>
    ]
  | APIResponse<DTO_RebateClaim_LOVs | DTO_Workflow_CreateRebateClaim>
  | undefined;
export const getInitialDataCreateRebateClaim = async (
  workflowDraftId: number | undefined
): Promise<IGetInitialDataCreateRebateClaim> => {
  try {
    return await Promise.all([
      getCreateRebateClaimLOVs(),
      !isNil(workflowDraftId)
        ? getSavedWorkflowCreateRebateClaim(workflowDraftId)
        : getNewWorkflowCreateRebateClaim({}),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessCreateRebateClaim = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_CreateRebateClaim
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_CreateRebateClaim>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/createrebateclaim/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
