import { ICCRoute } from "@common/constants/ICCRoute";

export const renewalsRoute: ICCRoute = {
  path: "renewals",
  name: "Renewals",
  children: [
    {
      path: "requires-renewal-fee",
      name: "Requires Renewal Fee",
      component: require("./requires-renewal-fee/_index").default,
    },
    {
      path: "requires-renewal-notice",
      name: "Requires Renewal Notice",
      component: require("./requires-renewal-notice/_index").default,
    },
    {
      path: "renewal-notice-sent",
      name: "Renewal Notice Sent",
      component: require("./renewal-notice-sent/_index").default,
    },
    {
      path: "requires-approval",
      name: "Requires Approval",
      component: require("./requires-approval/_index").default,
    },
    {
      path: "requires-certificate",
      name: "Requires Certificate",
      component: require("./requires-certificate/_index").default,
    },
    {
      path: "due-for-expiry",
      name: "Due For Expiry",
      component: require("./due-for-expiry/_index").default,
    },
  ],
};
