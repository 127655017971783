import { PropertyRoute } from "@app/products/property/property.route";
import { getProductTitle } from "@common/constants/product-control";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { usePropertyDashboardStore } from "@common/pages/home/components/property/store";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import { isArray } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";

export const PropertyPieChart = observer((props: any) => {
  const { queryDateTime } = usePropertyDashboardStore();
  const history = useHistory();
  const transFormData = (arrObj: Array<any>) => {
    if (!arrObj || !isArray(arrObj)) return [];
    return arrObj.map((item) => {
      return {
        name: item.Label,
        share: item.Value,
      };
    });
  };

  const PropertyTypeChart = () => {
    return (
      <div id="cc-property-type-chart">
        <div className="cc-chart-container">
          <Chart style={{ height: 250, overflow: "auto" }}>
            <ChartSeries>
              <ChartSeriesItem
                type="pie"
                overlay={{
                  gradient: "glass",
                }}
                tooltip={{ visible: true }}
                data={transFormData(props.data)}
                categoryField="name"
                field="share"
                visible={true}
              />
            </ChartSeries>
            <ChartLegend
              position="right"
              orientation="horizontal"
              width={100}
              height={5000}
              visible={false}
            />
          </Chart>
        </div>
        <div
          className="cc-chart-description"
          style={props?.data?.length > 13 ? { overflow: "auto" } : {}}
        >
          <Chart
            style={
              props?.data?.length > 13
                ? { height: 250, width: 2750 }
                : { height: 250 }
            }
          >
            <ChartSeries>
              <ChartSeriesItem
                type="pie"
                overlay={{
                  gradient: "glass",
                }}
                tooltip={{ visible: true }}
                data={transFormData(props?.data)}
                categoryField="name"
                field="share"
                visible={false}
              />
            </ChartSeries>
            <ChartLegend
              position="left"
              orientation="vertical"
              visible={true}
            />
          </Chart>
        </div>
      </div>
    );
  };

  return (
    <div
      className="cc-insight-group"
      onClick={(e) => {
        history.push(PropertyRoute.PROPERTY_ASSESSMENTS_LIST);
      }}
    >
      <div className="cc-group-name">
        {getProductTitle(PRODUCT_TYPE.CommunityProperty)}
      </div>
      <h4>{props.name}</h4>
      <div className="cc-date-time-description">
        as of {queryDateTime ?? ""}
      </div>
      <div className="cc-insight-group-container">
        <PropertyTypeChart />
      </div>
    </div>
  );
});
