export interface IBadgeDialog {
  content: string;
  type?: EBadgeDialogType;
}

export enum EBadgeDialogType {
  NORMAL = "normal",
  SUCCESS = "success",
  ERROR = "error",
}
