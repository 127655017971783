import { history } from "@/AppRoutes";
import { getDataSetting } from "@app/products/property/system-admin/settings/_category/api";
import { settingColumnField } from "@app/products/property/system-admin/settings/_category/config";
import { MODE_SETTING } from "@app/products/property/system-admin/settings/_category/model";
import {
  settingGridFieldColumn,
  settingGridFieldValue,
  settingSortingColumns,
} from "@app/products/property/system-admin/settings/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { IColumnFields } from "@components/cc-grid/model";
import { newCCProductListViewStore } from "@components/cc-product-list-view/store";
import { SortDescriptor } from "@progress/kendo-data-query";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class SettingPropertyStore {
  private _dataSetting?: any = undefined;
  private _isLoading: boolean = false;
  private _modeSetting: string = MODE_SETTING.NONE;
  private _primaryKeyTable: string = "Title";
  private _columnsFields: IColumnFields[] = [];
  private _sortDescriptor: SortDescriptor[] = [];
  private _responseLoadError?: APIResponseError = undefined;
  private _urlNewForm: string = "";
  private _currentBreadcrumb: any = null;
  private _isEditable: boolean = false;
  private _canClone: boolean = false;
  private _isClone: boolean = false;
  private _isDelete: boolean = false;
  private _isNew = false;
  private _isExistAfterCreated: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * check isDelete in action-bar list view
   */
  get isDelete(): boolean {
    return toJS(this._isDelete);
  }
  /**
   * set isDelete
   * @param isDelete
   */
  setIsDelete = (isDelete: boolean) => {
    runInAction(() => {
      this._isDelete = isDelete;
    });
  };

  /**
   * responseLoadError
   */
  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  /**
   * get mode for Setting
   */
  get modeSetting() {
    return this._modeSetting;
  }
  setModeSetting = (mode: string) => {
    runInAction(() => {
      this._modeSetting = mode;
    });
  };

  /**
   * check can clone
   */
  get canClone(): boolean {
    return toJS(this._canClone);
  }
  setCanClone = (canClone: boolean) => {
    runInAction(() => {
      this._canClone = canClone;
    });
  };

  /**
   * check isClone
   */
  get isClone(): boolean {
    return toJS(this._isClone);
  }
  setIsClone = (isClone: boolean) => {
    runInAction(() => {
      this._isClone = isClone;
    });
  };

  /**
   * get data breadcrumb
   */
  get currentBreadcrumb() {
    return this._currentBreadcrumb;
  }
  setCurrentBreadcrumb = (breadcrumb: any) => {
    runInAction(() => {
      this._currentBreadcrumb = breadcrumb;
    });
  };

  /**
   * get data Setting 2 types: Setting Folder and Setting Item
   */
  get dataSetting() {
    return toJS(this._dataSetting);
  }
  setDataSetting = (data?: any) => {
    runInAction(() => {
      this._dataSetting = data;
    });
  };

  /**
   * check is Loading
   */
  get isLoading(): boolean {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  /**
   * get primary key. It's dynamic field
   */
  get primaryKeyTable(): string {
    return this._primaryKeyTable;
  }
  setPrimaryKeyTable = (key: string) => {
    runInAction(() => {
      this._primaryKeyTable = key;
    });
  };

  /**
   * get dynamic column
   */
  get columnFields(): IColumnFields[] {
    return this._columnsFields;
  }
  setColumnField = (columnFields: IColumnFields[]) => {
    runInAction(() => {
      this._columnsFields = columnFields;
    });
  };

  /**
   * get dynamic sort descriptor
   */
  get sortDescriptor(): SortDescriptor[] {
    return this._sortDescriptor;
  }
  setSortDescriptor = (sortDescriptor: SortDescriptor[]) => {
    runInAction(() => {
      this._sortDescriptor = sortDescriptor;
    });
  };

  /**
   * get urlNewForm to create new in manage page
   */
  get urlNewForm(): string {
    return toJS(this._urlNewForm);
  }
  setUrlNewForm = (url: string) => {
    runInAction(() => {
      this._urlNewForm = url;
    });
  };

  /**
   * check isEditable
   */
  get isEditable(): boolean {
    return toJS(this._isEditable);
  }
  setIsEditable = (isEditable: boolean) => {
    runInAction(() => {
      this._isEditable = isEditable;
    });
  };

  /**
   * check is New
   */
  get isNew(): boolean {
    return toJS(this._isNew);
  }
  setIsNew = (isNew: boolean) => {
    runInAction(() => {
      this._isNew = isNew;
    });
  };

  /**
   * check Exist After Created
   */
  get isExistAfterCreated(): boolean {
    return toJS(this._isExistAfterCreated);
  }
  setIsExistAfterCreated = (isExist: boolean) => {
    this._isExistAfterCreated = isExist;
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._dataSetting = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  /**
   * load data setting
   * check mode setting
   * set some properties
   * @param requestUrl
   * @returns
   */
  loadDataSetting = async (
    requestUrl: string,
    notificationSuccess?: string
  ) => {
    const { location } = history;
    const state: any = location?.state;
    this.setIsLoading(true);
    const response = await getDataSetting(requestUrl);
    newCCProductListViewStore.setRequestUrl(requestUrl); // store request URL => keep state of CCProductListView
    let newDataSetting = undefined;
    let errorResponse = undefined;
    if (isSuccessResponse(response)) {
      let newColumns: IColumnFields[] = [];
      if (response.data === null || response === null) {
        this.setModeSetting(MODE_SETTING.SETTINGS_FOLDER);
        this.setIsLoading(false);
        return;
      }
      if (
        response.data.Mode === "SettingsFolder" ||
        response.data.Mode === "ListField"
      ) {
        if (
          response.data.hasOwnProperty("GridFields") &&
          response.data.hasOwnProperty("SettingsFolders")
        ) {
          const dataGrid = settingGridFieldValue(response.data.SettingsFolders);
          newColumns = settingGridFieldColumn(
            response.data.GridFields,
            response.data.LinkField,
            true
          );
          this.setColumnField(newColumns);
          this.setSortDescriptor(
            settingSortingColumns(response.data.GridFields)
          );
          newDataSetting = dataGrid;
          this.setPrimaryKeyTable("ItemId");
          requestUrl = requestUrl + `/-1`;
          this.setUrlNewForm(requestUrl);
        } else if (response.data.hasOwnProperty("SettingsFolders")) {
          const title: string = response.data.TitleColumnHeader;
          const description: string = response.data.DescriptionColumnHeader;
          if (response.data.SettingsFolders[0].hasOwnProperty("ItemId")) {
            newColumns = settingColumnField(
              response.data.UseIsActive,
              title,
              description,
              true
            );
            this.setColumnField(newColumns);
            this.setPrimaryKeyTable("ItemId");
            requestUrl = requestUrl + `/-1`;
            this.setUrlNewForm(requestUrl);
          } else {
            newColumns = settingColumnField(
              response.data.UseIsActive,
              title,
              description,
              false
            );
            this.setColumnField(newColumns);
            this.setPrimaryKeyTable("Title");
          }
          newDataSetting = response.data.SettingsFolders;
        }
        this.setModeSetting(MODE_SETTING.SETTINGS_FOLDER);
        if (notificationSuccess) {
          appNotificationStore.pushNotification({
            title: notificationSuccess,
            type: "success",
          });
        }
      } else if (response.data.Mode === "SettingsItem") {
        if (response.data.hasOwnProperty("SettingFields")) {
          this.setModeSetting(MODE_SETTING.SETTINGS_ITEM);
          if (response.data.hasOwnProperty("IsNew")) {
            if (!response.data.IsNew && state?.isNew) {
              const urlBack = history.location.pathname.split("/new")[0];
              history.push(urlBack);
            }
            if (location.pathname.includes("new")) {
              requestUrl = this.urlNewForm;
            }
          }
          newDataSetting = response.data;
        }
      } else {
        newDataSetting = response.data;
      }
      if (response.data) {
        if (!response.data.SettingsFolders) {
          requestUrl = requestUrl + `/-1`;
          this.setUrlNewForm(requestUrl);
        }
        this.setCurrentBreadcrumb(response.data);
        this.setIsEditable(response.data.IsEditable);
        this.setCanClone(response.data.CanClone ?? false);
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
      this.setModeSetting(MODE_SETTING.NONE);
      this.setCurrentBreadcrumb(undefined);
    }
    this.setResponseLoadError(errorResponse);
    this.setDataSetting(newDataSetting);
    this.setIsLoading(false);
  };
}

const settingPropertyStoreContext = createContext(new SettingPropertyStore());
export const useSettingPropertyStore = () => {
  return useContext(settingPropertyStoreContext);
};
