import { Contact_Relation } from "@app/core/inspections/[id]/model";
import { AnimalType } from "@app/products/animals/model";
import { DTOBaseClass_Standard } from "@app/products/crms/[id]/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { Gender } from "@common/constants/enumerations";
import { Address } from "@common/input-pickers/address/model";
import { SiteUser } from "@common/pages/settings/security/site-users/_id/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export interface PoundRegister extends DTOBaseClass_Standard {
  Register_ID: number;
  RegisterNo: string;
  RegisterSeqId: number;
  DateEntered: Date;
  ImpoundedBy_ENUM: PoundRegister_ImpoundedBy;
  ImpoundedBy_Name: string;
  ImpoundedByCouncilOfficer_ID?: number;
  ImpoundedByCouncilOfficer: SiteUser;
  ImpoundedByExternal_RID?: number;
  ImpoundedByExternal: Contact_Relation;
  ImpoundedByContact_ID?: number;
  CRMSEventNo: string;
  CRMSEvent_ID?: number;
  ImpoundDetails: string;
  PickUpLocation_ID?: number;
  PickUpLocation: Address;
  AnimalObtainedFrom: string;
  Status_ENUM: PoundRegister_Status;
  Status_Name: string;
  DateClosure?: Date;
  AnimalType_ID?: number;
  AnimalType: AnimalType;
  Gender_ENUM?: Gender;
  Gender_Name: string;
  AnimalName: string;
  Age?: number;
  Breed_KWD?: number;
  CrossBreed_KWD?: number;
  Colour_KWD?: number;
  SecondaryColour_KWD?: number;
  Flag_Registered: boolean;
  AnimalsRegistration_ID?: number;
  AnimalsRegistration_No: string;
  Flag_Dangerous?: boolean;
  Flag_Menacing?: boolean;
  TagNumber: string;
  Flag_Microchipped?: boolean;
  MicrochipNumber: string;
  Size_KWD?: number;
  Condition_KWD?: number;
  Disposition_KWD?: number;
  Owner_RID?: number;
  Owner: Contact_Relation;
  AlternativeContact_RID?: number;
  AlternativeContact: Contact_Relation;
  Judgement_KWD?: number;
  InfringementNo: string;
  Outcome_KWD?: number;
  Outcome_Name: string;
  FurtherComments: string;
  FileNumber: string;
  SaveTriggers: PoundRegisterUpdateTriggers[];
  BubbleUp: BubbleUpIdentifier[];
}

export interface Svc_PoundRegister {
  PoundRegister: PoundRegister;
  InfringementOSBalance?: number;
  OSFee?: number;
}

export interface Svc_PoundRegisterLOVs {
  ImpoundedBy: KeyValuePacket[];
  AnimalType: KeyValuePacket[];
  DogBreeds: KeyValuePacket[];
  CatBreeds: KeyValuePacket[];
  OtherBreeds: KeyValuePacket[];
  Gender: KeyValuePacket[];
  Colour: KeyValuePacket[];
  Size: KeyValuePacket[];
  Condition: KeyValuePacket[];
  Disposition: KeyValuePacket[];
  Dangerous: KeyValuePacket[];
  Menacing: KeyValuePacket[];
  Judgement: KeyValuePacket[];
}

export interface PoundRegisterHandlerRequest {
  FormAction: Svc_FormAction_PoundRegister;
  PoundRegister: PoundRegister;
  PoundRegisterArgs: any;
}

export interface PoundRegisterHandlerResponse {
  PoundRegister: PoundRegister;
}

// Interface
export interface AnimalPoundRegisterSettingValue {
  corporateSettingsRecordsSystem: number | undefined;
  corporateSettingsGIS: number | undefined;
  corporateSettingsPropertyEXE: string;
  infoXpertURL: string;
  ecmAdminPropertyLaunchUrl: string;
  trimAdminAnimalsStorageMethod: number | undefined;
  infoXpertAnimalsStorageMethod: number | undefined;
  corporateSettingsEnableMultiLineInvoice: boolean;
  corporateSettingsDebtorNumberLabel: string;
  corporateSettingsEnableDebtorUserSelection: boolean;
  corporateSettingsFinanceAllocateDebtorNumber: boolean;
  corporateSettingsDebtorNumberMandatory: boolean;
}

export interface CRMSEventPicker {
  ID: number;
  RefNo: string;
  ActionOfficer: string;
  Description: string;
  Record: Date | undefined;
  Category: string;
  Location: string;
  Contact: string;
  Target: Date | undefined;
}

export interface Svc_PoundRegister_Workflow_ProvideReason {
  PoundRegister: PoundRegister;
  ProvideReason: ProvideReason;
}

export interface IParentPoundRegisterSection {
  notification?: IAppNotificationItemAddProps[];
  newAnimalsId?: number;
}

//ENUM
export enum PoundRegister_ImpoundedBy {
  Unspecified = 0,
  Council = 1,
  Police = 2,
  Other = 3,
  Unknown = 4,
}

export enum PoundRegister_Status {
  Unspecified = 0,
  NewRegistration = 1,
  WithRanger = 2,
  Impounded = 3,
  Closed = 4,
}

export enum PoundRegisterUpdateTriggers {
  UpdateOwner,
  ClearOwner,
  UpdateAlternativeContact,
  ClearAlternativeContact,
  UpdatePickUpLocation,
  ClearPickUpLocation,
  UpdateAnimalType,
  UpdateImpoundedByExternal,
  ClearImpoundedByExternal,
}

export enum Svc_FormAction_PoundRegister {
  SystemInitialise,
  Form_PickExternalContact,
  Form_PickAddress,
  Form_PickAnimal,
  Form_PickOwner,
  Form_PickAlternativeContact,
}

export enum PoundRegisterSubmitActions {
  Save = "Save",
  New = "New",
  LodgeRegistration = "LodgeRegistration",
  AddRegister = "AddRegister",
}
