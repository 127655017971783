import { IWithdrawCommentDialogData } from "@app/products/town-planning/ppr/components/action-bar/dialogs/withdraw-comment/model";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React from "react";

interface IWithdrawCommentDialogProps {
  onClose: () => void;
  onSubmit: (data: IWithdrawCommentDialogData) => void;
  isLoading?: boolean;
}

const nameOf = nameOfFactory<IWithdrawCommentDialogData>();

export const WithdrawCommentDialog = ({
  onClose,
  onSubmit,
  isLoading,
}: IWithdrawCommentDialogProps) => {
  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    onSubmit(event.values as IWithdrawCommentDialogData);
  };
  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      render={(formRenderProps: FormRenderProps) => {
        return (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="25%"
              titleHeader={"Withdraw Application"}
              onClose={onClose}
              disabled={isLoading}
              bodyElement={
                <div className="cc-form">
                  <div className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Reason</label>
                        <Field
                          name={nameOf("Comment")}
                          component={CCTextArea}
                          rows={3}
                          placeholder="Reason"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    onClick={formRenderProps.onSubmit}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
