import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { isSuccessResponse } from "@common/apis/util";
import { getCrmsEventDashboard } from "@common/pages/home/components/crms/api";
import {
  CRMSEventClosedDataChart,
  EventDashboard,
} from "@common/pages/home/components/crms/model";
import { customCrmsEventClosedData } from "@common/pages/home/components/crms/util";

import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class CrmsDashboardStore {
  private _isLoadingCrmsEventDashboard: boolean = false;
  private _crmsEventDashboard?: EventDashboard = undefined;
  private _crmsDashboardEventClosed?: CRMSEventClosedDataChart = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoadingCrmsEventDashboard() {
    return this._isLoadingCrmsEventDashboard;
  }
  setIsLoadingCrmsEventDashboard = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingCrmsEventDashboard = isLoading;
    });
  };

  get crmsEventDashboard() {
    return toJS(this._crmsEventDashboard);
  }
  setCrmsEventDashboard = (eventDashboard?: EventDashboard) => {
    runInAction(() => {
      this._crmsEventDashboard = eventDashboard;
    });
  };

  get crmsDashboardEventClosed() {
    return toJS(this._crmsDashboardEventClosed);
  }
  setCrmsDashboardEventClosed = (
    crmsDashboardEventClosed?: CRMSEventClosedDataChart
  ) => {
    runInAction(() => {
      this._crmsDashboardEventClosed = crmsDashboardEventClosed;
    });
  };

  loadCrmsEventDashboard = async () => {
    this.setIsLoadingCrmsEventDashboard(true);
    const response = await getCrmsEventDashboard();

    if (isSuccessResponse(response) && response?.data) {
      this.setCrmsEventDashboard(response.data);
      const customData = customCrmsEventClosedData(
        response?.data?.Insights?.EventClosed
      );
      if (customData) {
        this.setCrmsDashboardEventClosed(customData);
      }
    } else {
      appNotificationStore.pushNotification({
        title:
          response?.error ?? `Load ${CRMS_PRODUCT_NAME} event dashboard failed`,
        type: "error",
        autoClose: false,
      });
    }

    this.setIsLoadingCrmsEventDashboard(false);
  };
}

export const crmsDashboardStore = new CrmsDashboardStore();
const crmsDashboardContext = createContext(crmsDashboardStore);
export const useCrmsDashboardStore = () => {
  return useContext(crmsDashboardContext);
};
