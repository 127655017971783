import { PROPERTY_SCHEMES_ROUTE } from "@app/products/property/schemes/[id]/constant";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { NumberCell } from "@components/cc-grid/components/grid-cells/number/_index";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

export const colSchemeList: IColumnFields[] = [
  {
    field: "SchemeNumber",
    title: "Scheme Number",
    locked: true,
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => `${PROPERTY_SCHEMES_ROUTE}/${dataItem.SchemeId}`,
  },
  {
    field: "SchemeName",
    title: "Scheme Name",
  },
  { field: "SchemeType", title: "Scheme Type" },
  {
    field: "NumberOfAccounts",
    title: "Number of Accounts",
    format: NUMBER_FORMAT.NUMBER2,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("NumberOfAccounts", gridData),
    footerCell: <NumberCell value={885} />,
  },
  {
    field: "PrincipalBalance",
    title: "Principal Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("PrincipalBalance", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "InterestBalance",
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("InterestBalance", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "OtherBalances",
    title: "Other Balances",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("OtherBalances", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "BalanceOutstanding",
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("BalanceOutstanding", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "AmountDue",
    title: "Amount Due",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("AmountDue", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "InterestRate",
    title: "Interest Rate",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: "NextInterestDate",
    title: "Next Interest Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "NextInstalmentDate",
    title: "Next Instalment Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "DefaultNumberOfInstalments",
    title: "Default Number of Instalments",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "SchemeInitiatedOn",
    title: "Scheme Initiated On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "SchemeDeclaredOn",
    title: "Scheme Declared On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "SchemeCommencedOn",
    title: "Scheme Commenced On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "ActualCost",
    title: "Actual Cost",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("ActualCost", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "ActualSubsidy",
    title: "Actual Subsidy",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("ActualSubsidy", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "ActualCostToAssessments",
    title: "Actual Cost to Assessments",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("ActualCostToAssessments", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "EstimatedCost",
    title: "Estimated Cost",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("EstimatedCost", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "EstimatedSubsidy",
    title: "Estimated Subsidy",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("EstimatedSubsidy", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "EstimatedCostToAssessments",
    title: "Estimated Cost to Assessments",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("EstimatedCostToAssessments", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "RaiseInterestOnInterest",
    title: "Raise Interest on Interest",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "InterestRaisingFrequencyInMonths",
    title: "Interest Raising Frequency (in Months)",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "InstalmentFrequencyInMonths",
    title: "Instalment Frequency (in Months)",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "DayToPayInstalment",
    title: "Days to Pay Instalment",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Comment",
    title: "Comment",
    footerCell: <TextCell value="Overall Total" />,
  },
  {
    field: "SchemeId",
    title: "Scheme ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
