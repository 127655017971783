import {
  apiGetPublicHolidayById,
  apiSavePublicHoliday,
} from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import { LookupDatesDTO } from "@common/pages/settings/lookups/public-holidays/model";

export const getPublicHolidayById = async (
  publicHolidayId: number
): Promise<APIResponse<LookupDatesDTO | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<LookupDatesDTO>(
      apiGetPublicHolidayById(publicHolidayId)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSavePublicHoliday = async (
  lookupDates: LookupDatesDTO
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiSavePublicHoliday(),
      lookupDates
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
