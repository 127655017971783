import { SecurityOrgStructureFormElement } from "@common/pages/settings/security/org-structure/_id/components/child-screens/general/components/form-element/_index";
import {
  OrgUnit,
  SecurityOrgStructureSubmitActions,
} from "@common/pages/settings/security/org-structure/_id/model";
import { useOrgStructureStore } from "@common/pages/settings/security/org-structure/_id/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const SecurityOrgStructureForm = observer(() => {
  const { orgUnit, saveOrgUnit, setOnSubmit } = useOrgStructureStore();
  const { pushNotification } = useCCAppNotificationStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const action = event.event?.currentTarget
      .name as SecurityOrgStructureSubmitActions;
    const { values, isModified, isValid } = event;
    if (!(action in SecurityOrgStructureSubmitActions)) return;
    if (!isValid) return;
    if (
      !isModified &&
      [
        SecurityOrgStructureSubmitActions.Save,
        SecurityOrgStructureSubmitActions.CreateTopLevel,
        SecurityOrgStructureSubmitActions.CreateAdhocGroup,
        SecurityOrgStructureSubmitActions.AddThisOrgUnit,
      ].includes(action)
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    let orgUnitInfo = values as OrgUnit;
    if (action === SecurityOrgStructureSubmitActions.CreateTopLevel)
      orgUnitInfo.AdhocGroup = false;
    else if (action === SecurityOrgStructureSubmitActions.CreateAdhocGroup)
      orgUnitInfo.AdhocGroup = true;

    saveOrgUnit(orgUnitInfo, action);
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, orgUnit]);
  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={orgUnit}
        key={JSON.stringify(orgUnit)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <SecurityOrgStructureFormElement
                  formRenderProps={formRenderProps}
                  handleOnSubmit={handleOnSubmit}
                />
              }
            />
          );
        }}
      />
    </div>
  );
});
