import { CoreAPIService } from "@common/apis/coreAPIService";
import { IResponseData } from "@common/models/service";
import { globalStoreInstance } from "@common/stores/global/store";
// const GET_CONTACT = '/api/contact/ext/getcontact';
const GET_CONTACT_RELATIONSHIP =
  "/api/core/internal/getcontactrelationship/{proprietor_RID}";
const SEARCH_CONTACT = "/api/core/internal/getcontacts/{searchText}";

export const getContactRelationshipById = async (proprietor_RID: string) => {
  const response: IResponseData = await CoreAPIService.getClient().get(
    `${
      globalStoreInstance.allSetting.baseAPIUrl
    }${GET_CONTACT_RELATIONSHIP.replace("{proprietor_RID}", proprietor_RID)}`
  );

  if (!response) {
    console.warn("Load contact information failed", response);
    return undefined;
  }

  return response.data;
};

export const searchContact = async (keyword: string) => {
  const response: IResponseData = await CoreAPIService.getClient().get(
    `${globalStoreInstance.allSetting.baseAPIUrl}${SEARCH_CONTACT.replace(
      "{searchText}",
      keyword
    )}`
  );

  if (!response) {
    return undefined;
  }
  return response.data;
};
