import {
  DTO_Workflow_DDTransferTransaction,
  RequestTransferDDTransactionObj,
} from "@app/products/property/deferred-duty/[id]/components/forms/existed/components/form-steps/transfer-transaction/model";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { WorkflowProcessMode } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postProcessTransferDDTransaction = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_DDTransferTransaction
): Promise<APIResponse<any>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/deferreddutytransactiontransfer/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataJournal =
  | [
      APIResponse<DTO_Journal_LOVs>,
      APIResponse<DTO_Workflow_DDTransferTransaction>
    ]
  | APIResponse<DTO_Journal_LOVs | DTO_Workflow_DDTransferTransaction>
  | undefined;
export const getInitialDataTransferDDTransaction = async (
  workflowDraftId: number | undefined = 0,
  isFromActionList: boolean = false,
  transactionIds?: number[] | undefined
): Promise<IGetInitialDataJournal> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_LOVs>(
        `api/property/internal/journal/lovs`
      ),
      isFromActionList
        ? getWorkflowTransferDDTransaction(workflowDraftId)
        : getNewWorkflowTransferDDTransaction({
            TransactionIds: transactionIds,
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowTransferDDTransaction = async (
  requestObj: RequestTransferDDTransactionObj | object = {}
): Promise<APIResponse<DTO_Workflow_DDTransferTransaction>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/deferreddutytransactiontransfer/new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowTransferDDTransaction = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_DDTransferTransaction>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/deferreddutytransactiontransfer/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
