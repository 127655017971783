import { eventEmitter } from "@/App";
import { DTO_Journal_Summary } from "@app/products/property/journals/[id]/model";
import { postJournalSummaryByIds } from "@app/products/property/journals/list/components/dialogs/create-session/api";
import { DTO_Session } from "@app/products/property/journals/list/components/dialogs/create-session/model";
import { useJournalCreateSessionStore } from "@app/products/property/journals/list/components/dialogs/create-session/store";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory, sleep } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { CCSwitch } from "@components/cc-switch/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface ICreateSessionDialog {
  onCloseDialog: () => void;
}

const nameOfJournals = nameOfFactory<DTO_Journal_Summary>();
const nameOfSession = nameOfFactory<DTO_Session>();

export const CreateSessionDialog = observer(
  ({ onCloseDialog }: ICreateSessionDialog) => {
    const { gridSelectedIds, clearSelectedItems } = useCCProductListViewStore();
    const {
      journals,
      isLoading,
      responseLoadError,
      reLoadApiConfig,
      loadJournalSummaryByIdsAndConfigsColumn,
      columnJournalSession,
    } = useJournalCreateSessionStore();
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const [selectedItems, setSelectedItems] = useState<DTO_Journal_Summary[]>(
      []
    );
    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
    const [disableConsolidateSwitchButton, setDisableConsolidateSwitchButton] =
      useState<boolean>(true);
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const handleOnSubmitClick = async (event: FormSubmitClickEvent) => {
      setIsLoadingSubmit(true);
      await sleep(2000);
      let selectedJournalNumber: string = "";
      selectedItems.forEach(
        (item) => (selectedJournalNumber += "," + item?.Journal_Number)
      );
      const submitData = {
        Session_Number: 0,
        Session_Name: event?.values?.Session_Name,
        JournalNumbers: selectedJournalNumber.slice(1),
        ComputronSourceCode: null,
        ConsolidateGLPostings: event?.values?.ConsolidateGLPostings ?? false,
      } as DTO_Session;
      const response = await postJournalSummaryByIds(submitData);
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        onCloseDialog();
        clearErrorNotification();
        pushNotification({
          title: "Created new journal session successfully",
          type: "success",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
        clearSelectedItems();
        setSelectedItems([]);
      } else {
        notificationRef.current?.pushNotification({
          title:
            response?.data?.ErrorMessage ?? `New journal session create failed`,
          type: "error",
          autoClose: false,
        });
      }
      setIsLoadingSubmit(false);
    };

    useEffectOnce(() => {
      loadJournalSummaryByIdsAndConfigsColumn(gridSelectedIds);
    });

    return (
      <Form
        onSubmitClick={handleOnSubmitClick}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onChange } = formRenderProps;

          const handleGetDataSelected = (event: DTO_Journal_Summary[]) => {
            setSelectedItems(event);
            if (event?.length > 0) {
              setDisableConsolidateSwitchButton(false);
            } else {
              onChange(nameOfSession("ComputronSourceCode"), {
                value: false,
              });
              setDisableConsolidateSwitchButton(true);
            }
          };

          return (
            <CCDialog
              titleHeader={"Create a New Session from Approved Journals"}
              onClose={onCloseDialog}
              maxWidth="70%"
              height="auto"
              bodyElement={
                <>
                  {isLoading ? (
                    <Loading isLoading />
                  ) : responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={() => {
                        reLoadApiConfig(gridSelectedIds);
                      }}
                    />
                  ) : (
                    <FormElement className="cc-form">
                      <CCLocalNotification ref={notificationRef} />
                      <section className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <CCLabel title="Name of the session" isMandatory />
                            <Field
                              name={nameOfSession("Session_Name")}
                              placeholder="Name of the session"
                              component={CCInput}
                              validator={requiredValidator}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Select the approved journals to be included in
                              this session
                            </label>
                            <CCGrid
                              data={journals}
                              columnFields={columnJournalSession}
                              primaryField={nameOfJournals("Journal_Number")}
                              selectableMode="multiple"
                              selectedRows={selectedItems ?? []}
                              onSelectionChange={handleGetDataSelected}
                            />
                          </div>
                        </div>

                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">
                              Consolidate general ledger postings
                            </label>
                            <Field
                              name={nameOfSession("ConsolidateGLPostings")}
                              checked={
                                valueGetter(
                                  nameOfSession("ConsolidateGLPostings")
                                ) ?? false
                              }
                              component={CCSwitch}
                              disabled={disableConsolidateSwitchButton}
                            />
                          </div>
                        </div>
                      </section>
                    </FormElement>
                  )}
                </>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    className="cc-dialog-button"
                    disabled={isLoadingSubmit}
                    onClick={onCloseDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    disabled={
                      !formRenderProps.valid ||
                      isLoadingSubmit ||
                      selectedItems.length === 0
                    }
                    themeColor="primary"
                    iconClass={isLoadingSubmit ? "fas fa-spinner fa-spin" : ""}
                    onClick={formRenderProps.onSubmit}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
