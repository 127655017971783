import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { TextAreaGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/text-area/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useShowHideLogicPropertiesSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/properties/hooks/useShowHideLogicPropertiesSettings";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IGlobalSettingsProps {
  formRenderProps: FormRenderProps;
}

export default observer(({ formRenderProps }: IGlobalSettingsProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter } = formRenderProps;
  const data = useDataSetting(configData, [
    "CorporateSettings_PropertySystem",
    "CorporateSettings_PropertiesDefaultPropertyReturnAssessmentKey",
    "CorporateSettings_PropertiesAssessmentNumberColumnName",
    "CorporateSettings_PropertiesAssessmentIDColumnName",
    "CorporateSettings_PropertiesAssessmentNoLabel",
    "CorporateSettings_PropertiesTitleIDColumnName",
    "CorporateSettings_PropertiesConnectionMethod",
    "CorporateSettings_PropertySystemConnectionString",
    "CorporateSettings_PropertyEXE",
    "CorporateSettings_PropertyURL",
    "CorporateSettings_PropertyCustomSelectQuery",
    "CorporateSettings_PropertyCustomReturnQuery",
    "CorporateSettings_PropertyCustomSelectQuery_Street",
    "CorporateSettings_AssessmentNoReturnQuery",
    "CorporateSettings_CILPropertyCustomSelectQuery",
    "CorporateSettings_PropertyOwnerSelect",
    "CorporateSettings_PropertyOwnerSelectQuery",
  ]);
  const fetchPropertyOwnerDetail = valueGetter(
    ECorporateSettingsField.CorporateSettings_PropertyOwnerSelect.toString()
  );

  const {
    showConnectionMethod,
    showPropertyCustom,
    showPropertiesEXE,
    showPropertiesConnectionString,
  } = useShowHideLogicPropertiesSettings(formRenderProps);
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <CCSearchComboBoxGlobalSettings
          data={data.CorporateSettings_PropertySystem}
          isEditing={isEditing}
        />
        {showConnectionMethod && (
          <CCSearchComboBoxGlobalSettings
            data={data.CorporateSettings_PropertiesConnectionMethod}
            isEditing={isEditing}
          />
        )}
        <CCSearchComboBoxGlobalSettings
          data={
            data.CorporateSettings_PropertiesDefaultPropertyReturnAssessmentKey
          }
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={data.CorporateSettings_PropertiesAssessmentNumberColumnName}
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={data.CorporateSettings_PropertiesAssessmentIDColumnName}
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={data.CorporateSettings_PropertiesAssessmentNoLabel}
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={data.CorporateSettings_PropertiesTitleIDColumnName}
          isEditing={isEditing}
        />

        {showPropertiesConnectionString && (
          <InputGlobalSettings
            data={data.CorporateSettings_PropertySystemConnectionString}
            isEditing={isEditing}
          />
        )}
        {showPropertiesEXE && (
          <InputGlobalSettings
            data={data.CorporateSettings_PropertyEXE}
            isEditing={isEditing}
          />
        )}
        <InputGlobalSettings
          data={data.CorporateSettings_PropertyURL}
          isEditing={isEditing}
        />
      </div>
      {showPropertyCustom && (
        <>
          <div className="cc-form-cols-1">
            <TextAreaGlobalSettings
              data={data.CorporateSettings_PropertyCustomSelectQuery}
              isEditing={isEditing}
              rows={10}
            />
          </div>
          <div className="cc-form-cols-1">
            <TextAreaGlobalSettings
              data={data.CorporateSettings_PropertyCustomReturnQuery}
              isEditing={isEditing}
              rows={10}
            />
          </div>
          <div className="cc-form-cols-1">
            <TextAreaGlobalSettings
              data={data.CorporateSettings_PropertyCustomSelectQuery_Street}
              isEditing={isEditing}
              rows={10}
            />
          </div>
          <div className="cc-form-cols-1">
            <TextAreaGlobalSettings
              data={data.CorporateSettings_AssessmentNoReturnQuery}
              isEditing={isEditing}
              rows={10}
            />
          </div>
          <div className="cc-form-cols-1">
            <TextAreaGlobalSettings
              data={data.CorporateSettings_CILPropertyCustomSelectQuery}
              isEditing={isEditing}
              rows={10}
            />
          </div>
          <div className="cc-form-cols-1">
            <SwitchGlobalSettings
              data={data.CorporateSettings_PropertyOwnerSelect}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          </div>
          {fetchPropertyOwnerDetail && (
            <div className="cc-form-cols-1">
              <TextAreaGlobalSettings
                data={data.CorporateSettings_PropertyOwnerSelectQuery}
                isEditing={isEditing}
                rows={10}
              />
            </div>
          )}
        </>
      )}
    </section>
  );
});
