import { CONTACT_ACTIVE_ODATA } from "@app/core/contacts/active/_filter/constant";
import {
  ContactActiveFilter,
  ContactManager_View_ActiveContact,
} from "@app/core/contacts/active/_filter/model";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const getContactActiveOData = (group: ContactActiveFilter) =>
  CONTACT_ACTIVE_ODATA.replace("[group]", group === "all" ? "" : group);

export const getBookmarkDisplayName = (
  selectedRow: ContactManager_View_ActiveContact
) => {
  const dynamicDisplayName = selectedRow.Id ? `- ${selectedRow.Id}` : "";
  return `Contact - Active ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (
  selectedRow: ContactManager_View_ActiveContact
) => {
  return getDisplayTextWithDashes([
    selectedRow.Classification,
    selectedRow.DisplayName,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return "Contact - Active";
};

export const getBookmarkListViewDetail = (
  filter: ContactActiveFilter
): string => {
  return `Contact - Active - ${
    filter === "all" ? "All" : filter.toUpperCase()
  }`;
};
