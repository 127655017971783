import { loadTransactions } from "@app/products/property/meters/[id]/components/child-screens/transactions/api";
import MeterTransactionDetails from "@app/products/property/meters/[id]/components/child-screens/transactions/components/detail/_index";
import { CCGrid } from "@components/cc-grid/_index";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colMeterTransactions } from "./config";

export const MeterTransactions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTransaction, setDataTransaction] = useState<any>([]);

  useEffectOnce(() => {
    setIsLoading(true);
    loadTransactions().then((data) => {
      setDataTransaction(data);
      setIsLoading(false);
    });
  });
  return (
    <div className="cc-property-meter-transaction">
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCGrid
                isLoading={isLoading}
                className="cc-meter-transaction"
                data={dataTransaction}
                state={{
                  sort: [{ field: "DateTime", dir: "desc" }],
                }}
                columnFields={colMeterTransactions}
                primaryField="TransactionId"
                detail={MeterTransactionDetails}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
