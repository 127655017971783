import { ExistedNoticeRun } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/forms/existed/_index";
import NewNoticeRun from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/forms/new/_index";
import { useNoticeRunsStore } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

function ManageNoticeRuns() {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const noticeRunId = params.id;
  const { loadNoticeRuns, resetStore } = useNoticeRunsStore();
  const location = useLocation();
  const state: any = location?.state;

  useEffect(() => {
    if (isNew) return;
    let notification = undefined;
    if (state) {
      notification = state?.notification;
      window.history.replaceState({}, "");
    }
    loadNoticeRuns(parseInt(noticeRunId), notification);
    // eslint-disable-next-line
  }, [isNew, loadNoticeRuns, noticeRunId]);

  useEffectOnce(() => {
    return () => resetStore();
  });

  if (isNew) {
    return <NewNoticeRun />;
  }

  return <ExistedNoticeRun />;
}

export default observer(ManageNoticeRuns);
