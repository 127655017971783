import { AnimalsMenu } from "@app/products/animals/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const noticesRoute: ICCRoute = {
  path: "notices",
  name: "Notices",
  enum: AnimalsMenu.Notices,
  children: [
    {
      path: "by-date",
      name: "By Date",
      enum: AnimalsMenu.NoticesByDate,
      component: require("./by-date/_index").default,
    },
    {
      path: "by-due-status",
      name: "By Due Status",
      enum: AnimalsMenu.NoticesByDueStatus,
      component: require("./by-due-status/_index").default,
    },
  ],
};
