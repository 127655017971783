import {
  loadAccountsInScheme,
  loadImportSchemeAccounts,
} from "@app/products/property/schemes/[id]/components/child-screens/accounts/api";
import { colAccountsInTheScheme } from "@app/products/property/schemes/[id]/components/child-screens/accounts/config";
import { mockImportSchemeAccounts } from "@app/products/property/schemes/[id]/components/child-screens/accounts/mock";
import { IAccountInTheScheme } from "@app/products/property/schemes/[id]/components/child-screens/accounts/model";
import { useIsEditable } from "@common/hooks/useIsEditable";
import { useIsNew } from "@common/hooks/useIsNew";
import { CCGrid } from "@components/cc-grid/_index";
import { FieldArray, FormRenderProps } from "@progress/kendo-react-form";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

interface Props {
  formRenderProps: FormRenderProps;
  isUpdateSchemeAccount?: boolean;
  setIsUpdateSchemeAccount?: React.Dispatch<React.SetStateAction<boolean>>;
}
const KEY_NAME = "Accounts";
export const AccountsInTheSchemeFormElement = (props: Props) => {
  return (
    <FieldArray name={KEY_NAME} {...props} component={AccountsInTheScheme} />
  );
};

export const AccountsInTheScheme = ({
  formRenderProps,
  isUpdateSchemeAccount,
  setIsUpdateSchemeAccount,
}: Props) => {
  const params: { id: string } = useParams();
  const schemeId = params.id;
  const isNew = useIsNew();
  const isEditable = useIsEditable();
  const [data, setData] = useState<IAccountInTheScheme[]>();
  const [isLoading, setIsLoading] = useState(false);

  const canEdit = useMemo(() => {
    if (!isNew && !isEditable) return false;

    return isNew || isEditable;
  }, [isNew, isEditable]);

  useEffect(() => {
    setIsLoading(true);
    if (canEdit) {
      if (isUpdateSchemeAccount) {
        loadImportSchemeAccounts().then((response) => {
          setData([response]);
          setIsLoading(false);
        });
      } else {
        setData([]);
        setIsLoading(false);
      }
      return;
    }
    if (setIsUpdateSchemeAccount) {
      loadAccountsInScheme(schemeId).then((response) => {
        setData([...response, mockImportSchemeAccounts]);
        setIsLoading(false);
      });
      setIsUpdateSchemeAccount(false);
    }
  }, [canEdit, isUpdateSchemeAccount, schemeId, setIsUpdateSchemeAccount]);

  return (
    <div className="cc-property-scheme-account">
      <CCGrid
        isLoading={isLoading}
        selectedRows={[]}
        data={data}
        columnFields={colAccountsInTheScheme(schemeId ?? "0")}
        primaryField={"SchemeAccountId"}
      />
    </div>
  );
};
