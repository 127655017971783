import { getRateNoticeAmountOwing } from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/components/panels/rate-notice/api";
import { colPaymentOptions } from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/components/panels/rate-notice/config";
import {
  IPaymentOption,
  IRateNotice,
} from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/components/panels/rate-notice/model";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<IPaymentOption>();
export const RateNoticeAmountsOwing = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<IRateNotice | undefined>();
  const { assessmentId } = useAssessmentStore();
  useEffectOnce(() => {
    setIsLoading(true);
    if (assessmentId) {
      getRateNoticeAmountOwing(assessmentId).then((data: IRateNotice) => {
        if (data) {
          setData(data);
        }
        setIsLoading(false);
      });
    }
  });
  if (isLoading) return <Loading isLoading />;
  return (
    <div className="cc-amount-owing-rate-notice">
      <div className="cc-form">
        {data && (
          <>
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <CCValueField
                  label="Arrears"
                  value={data?.Arrears}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
                <CCValueField
                  label="Levies raised"
                  value={data?.LeviesRaised}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
                <CCValueField
                  label="Rebates"
                  value={data?.Rebates}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
                <CCValueField
                  label="Nett rates"
                  value={data?.NettRates}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
              </div>
            </section>
            <hr className="cc-divider" />
          </>
        )}
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Payment options</label>
              <CCGrid
                data={data?.PaymentOptions ?? []}
                columnFields={colPaymentOptions}
                primaryField={nameOf("Id")}
              />
            </div>
          </div>
        </section>
      </div>
      <br />
    </div>
  );
});
