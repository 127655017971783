import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { SamplesAccordion } from "@app/core/samples/_index";
import { WastewaterForm } from "@app/products/waste-water/[id]/components/child-screen/general/_index";
import { WasteWaterContactTab } from "@app/products/waste-water/[id]/components/reference-sidebar/contact/_index";
import { WasteWaterDetailsTab } from "@app/products/waste-water/[id]/components/reference-sidebar/details/_index";
import { WasteWaterHistoryTab } from "@app/products/waste-water/[id]/components/reference-sidebar/history/_index";
import { useWasteWaterStore } from "@app/products/waste-water/[id]/store";
import { WastewaterRecordsState } from "@app/products/waste-water/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { splitCamelCase } from "@common/utils/formatting";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import { default as React, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedWastewater = observer(() => {
  const { id } = useParams<{ id: string }>(); // TODO: The id variable is only use in case reloading.
  const {
    wastewater,
    responseLoadError,
    isLoading,
    wasteWaterId,
    loadWasteWaterInfo,
  } = useWasteWaterStore();

  const formTitle = useMemo(() => {
    const permitNumber = wastewater?.PermitNumber;
    const applicant = wastewater?.Applicant?.DisplayName;

    // If these two are not falsy, return both, otherwise return one or default
    if (permitNumber && applicant) {
      return `${permitNumber} - ${applicant}`;
    }

    return permitNumber || applicant || "N/A";
  }, [wastewater]);

  const [wastewaterRecords] = useState<WastewaterRecordsState | undefined>(
    undefined
  );
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: formTitle,
      LinkUrl: managePageUrl,
      LinkText: `Waste Water - ${wasteWaterId ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.WasteWater,
      RecordType_ENUM: RECORDTYPE.WW_Product,
      Record_ID: wasteWaterId ?? 0,
    },
  ];
  const [showSlideBar, setShowSlideBar] = useState(true);

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Actions",
      totalRecord: wastewaterRecords?.actions,
      component: (
        <Actions id={wasteWaterId} recordType={RECORDTYPE.WW_System} />
      ),
    },
    {
      title: "Comments",
      totalRecord: wastewaterRecords?.comments,
      component: (
        <Comments id={wasteWaterId} recordType={RECORDTYPE.WW_System} />
      ),
    },
    {
      title: "Contacts",
      totalRecord: wastewaterRecords?.contacts,
      component: (
        <Contacts id={wasteWaterId} recordType={RECORDTYPE.WW_System} />
      ),
    },
    {
      title: "Documents",
      totalRecord: wastewaterRecords?.documents,
      component: (
        <Documents id={wasteWaterId} recordType={RECORDTYPE.WW_System} />
      ),
    },
    {
      title: "Fees",
      totalRecord: wastewaterRecords?.fees,
      component: <Fees id={wasteWaterId} recordType={RECORDTYPE.WW_System} />,
    },
    {
      title: "Inspections",
      totalRecord: wastewaterRecords?.samples,
      component: (
        <SamplesAccordion id={wasteWaterId} recordType={RECORDTYPE.WW_System} />
      ),
    },
    {
      title: "Services",
      totalRecord: wastewaterRecords?.samples,
      component: (
        <SamplesAccordion id={wasteWaterId} recordType={RECORDTYPE.WW_System} />
      ),
    },
    {
      title: "Samples",
      totalRecord: wastewaterRecords?.samples,
      component: (
        <SamplesAccordion id={wasteWaterId} recordType={RECORDTYPE.WW_System} />
      ),
    },
    {
      title: "Conditions",
      totalRecord: wastewaterRecords?.samples,
      component: (
        <SamplesAccordion id={wasteWaterId} recordType={RECORDTYPE.WW_System} />
      ),
    },
    {
      title: "Related",
      totalRecord: wastewaterRecords?.samples,
      component: (
        <SamplesAccordion id={wasteWaterId} recordType={RECORDTYPE.WW_System} />
      ),
    },
    {
      title: "Events",
      totalRecord: wastewaterRecords?.samples,
      component: (
        <SamplesAccordion id={wasteWaterId} recordType={RECORDTYPE.WW_System} />
      ),
    },
  ];

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <WasteWaterDetailsTab /> },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Contacts",
        component: <WasteWaterContactTab />,
      },
      {
        title: "History",
        component: <WasteWaterHistoryTab />,
      },
    ],
  });

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Waste Water"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadWasteWaterInfo(id, false);
          }}
        />
      ) : (
        <>
          <FormTitle
            title={formTitle}
            badge={splitCamelCase(wastewater?.Status.Name || "")}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Allocate to planner"} />
                <CCNavButton title={"Failure"} />
                <CCNavButton title={"Revision received"} />
                <CCNavButton title={"Withdraw application"} />
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Attachment"} />
                <AddCommentButton
                  id={wasteWaterId}
                  recordType={RECORDTYPE.WW_System}
                />
                {/* TODO: enhance later */}
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.WasteWater}
                  recordType={RECORDTYPE.WW_Product}
                  recordId={parseInt(id)}
                />
                <CCNavButton title={"Complaint"} />
                <CCNavButton title={"Document"} />
                <CCNavButton title={"Fee"} />
                <CCNavButton title={"Inspection"} />
                <CCNavButton title={"Notice"} />
                <CCNavButton title={"Prosecution"} />
                <CCNavButton title={"Related"} />
                <CCNavButton title={"Samples"} />
                <CCNavButton title={"Standard condition"} />
              </CCNavButton>,
              <CCNavButton title={"View"} type="sub">
                <CCNavButton title={"Documents"} />
                <CCNavButton title={"Journal"} />
                <CCNavButton title={"Project"} />
              </CCNavButton>,
              <CCNavButton title={"Print"} />,
              <CCNavButton title={"More Options"} type="more">
                <CCNavButton title={"Alert"} />
                <CCNavButton title={"Conditions"} />
                <CCNavButton title={"Assessment"} />
                <CCNavButton title={"System details"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {wastewater && (
                  <CCGeneralPanel component={<WastewaterForm />} />
                )}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
