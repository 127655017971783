import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<SettingField>();
export const gridDynamicRender: IColumnFields[] = [
  {
    field: nameOf("Title"),
    title: "Name",
  },
  {
    field: nameOf("Value"),
    title: "Value",
    editable: true,
    isGrow: true,
  },
];
