import { EAssessmentError } from "@app/products/property/assessments/components/constant";
import { DTO_CreateAssessment_Parcel } from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/associations-parcel/model";
import { isNil } from "lodash";

export const validatorAssociatedAssessmentNew = (
  values: any
): string | undefined => {
  if (
    values?.AssociateParcels?.length === 0 ||
    isNil(values?.AssociateParcels)
  ) {
    return EAssessmentError.ValidateExistParcel;
  }
  // if have any folio with AssociatedAssessmentAreaTotal >= 100 => return error
  if (
    values.AssociateParcels?.some(
      (parcel: DTO_CreateAssessment_Parcel) =>
        (parcel?.AssociatedAssessmentAreaTotal ?? 0) >= 100
    )
  ) {
    return EAssessmentError.ValidateFactorPercentage;
  }
  return undefined;
};
