import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RecordCountPacket } from "@common/models/recordCountPacket";
import { IntegratedReportImport } from "@common/pages/report/integrated-reports/component/buttons/export-xml/model";

export const postImportXML = async (
  fileData: IntegratedReportImport
): Promise<APIResponse<RecordCountPacket | any | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/core/internal/integratedreports/import`,
      fileData
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
