import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { GlobalSettingSwitch } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/forms/components/application-setting-switch/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export default ({ formRenderProps }: ICommonFormProps) => {
  const { configData } = useSettingGlobalStore();

  if (!configData) return <></>;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <GlobalSettingSwitch
          enumParam={
            ECorporateSettingsField.FurtherInformation_EnableFIConfirmationg
          }
          formRenderProps={formRenderProps}
        />
      </div>
    </section>
  );
};
