export interface IDecisionData {
  InfoInAdequate: boolean;
}

export interface IDecision {
  Option: string;
}

export enum OPTION_DECISION {
  Adequate = "Adequate",
  Inadequate = "Inadequate",
  Option = "Option",
}
