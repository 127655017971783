import { useJournalTransactionStore } from "@app/products/property/journals/[id]/components/child-screens/items/store";
import "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/modify-transaction/_index.scss";
import {
  getDataJournalTransaction,
  postTransaction,
} from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/modify-transaction/api";
import { DTO_Transaction } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/modify-transaction/model";
import { useModifyTransactionStore } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/modify-transaction/store";
import { useJournalStore } from "@app/products/property/journals/[id]/store";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { DTO_LOV } from "@common/models/odataResponse";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { isNull } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface INewArrangementDialog {
  onClose: () => void;
  onSubmit: () => void;
  nameForTransactionId?: string;
}

export const ModifyTransactionDialog = observer(
  (props: INewArrangementDialog) => {
    const [initialValues, setInitialValues] = useState<any | null>(null);
    const [isLoadingModify, setIsLoadingModify] = useState<boolean>(false);
    const { journalId } = useJournalStore();
    const {
      journalTransaction,
      loadJournalTransaction,
      assessRebateEntitlementsLOVs,
      isRebateTypeTransactionLOVs,
      responseLoadError,
      isLoading,
      resetStore,
    } = useModifyTransactionStore();
    const { selectedTransaction } = useJournalTransactionStore();
    const assessRebateEntitlements = convertValueLOVToNumber<DTO_LOV>(
      assessRebateEntitlementsLOVs ?? [],
      "Code"
    );
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const { pushNotification } = useCCAppNotificationStore();

    useEffectOnce(() => {
      journalId &&
        selectedTransaction?.[`${props.nameForTransactionId}`] &&
        loadJournalTransaction(
          journalId,
          selectedTransaction?.[`${props.nameForTransactionId}`]
        );
      return () => {
        resetStore();
      };
    });

    useEffect(() => {
      if (journalTransaction) {
        setInitialValues({
          ...journalTransaction,
        });
      } else {
        setInitialValues({});
      }
    }, [journalTransaction]);

    const handleSubmit = async (event: FormSubmitClickEvent) => {
      if (!event.isValid || !event.isModified) return;
      let values = event.values as DTO_Transaction;
      setIsLoadingModify(true);
      const response =
        journalId && selectedTransaction?.[`${props.nameForTransactionId}`]
          ? await postTransaction(
              journalId,
              selectedTransaction?.[`${props.nameForTransactionId}`],
              values
            )
          : undefined;
      setIsLoadingModify(false);
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          pushNotification({
            title: "Transaction details was completed successfully",
            type: "success",
          });
          props.onSubmit();
        } else {
          notificationRef.current?.pushNotification({
            autoClose: false,
            type: "error",
            description: response?.data?.ErrorMessage,
          });
        }
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title:
            response?.data?.ErrorMessage ??
            "Transaction details could not be completed",
          type: "error",
        });
      }
    };

    return (
      <Form
        onSubmitClick={handleSubmit}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        render={(formRenderProps: FormRenderProps) => (
          <CCDialog
            titleHeader={"Transaction Details"}
            onClose={props.onClose}
            maxHeight="75%"
            maxWidth="40%"
            bodyElement={
              isLoading ? (
                <Loading isLoading={isLoading} />
              ) : responseLoadError ? (
                <CCLoadFailed
                  responseError={responseLoadError}
                  onReload={() => {
                    journalId &&
                      selectedTransaction?.[`${props.nameForTransactionId}`] &&
                      getDataJournalTransaction(
                        journalId,
                        selectedTransaction?.[`${props.nameForTransactionId}`]
                      );
                  }}
                />
              ) : (
                <div className="cc-modify-transaction cc-form">
                  <FormElement
                    onSubmit={formRenderProps.onSubmit}
                    className="cc-field-group"
                  >
                    <div className="cc-form-cols-1">
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">Date/Time</label>
                          <Field
                            name="DateTime"
                            component={CCDateTimePicker}
                            disabled
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Transaction Id</label>
                          <Field
                            name="TransactionId"
                            component={CCInput}
                            format={NUMBER_FORMAT.NUMBER2}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">Transaction Type</label>
                          <Field
                            name="TransactionType"
                            component={CCInput}
                            readOnly
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Reference</label>
                          <Field
                            name="Reference"
                            component={CCInput}
                            readOnly
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Description</label>
                          <Field
                            name="Description"
                            component={CCTextArea}
                            rows={4}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Notes</label>
                          <Field name="Notes" component={CCTextArea} rows={4} />
                        </div>
                      </div>
                      <div className="cc-form-cols-3">
                        <div className="cc-field">
                          <label className="cc-label">Amount</label>
                          <Field
                            name="Amount"
                            component={CCCurrencyInput}
                            disabled
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Is Proposed</label>
                          <Field
                            name="IsProposed"
                            component={CCSwitch}
                            disabled
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Is Cancelled</label>
                          <Field
                            name="IsCancelled"
                            component={CCSwitch}
                            disabled
                          />
                        </div>
                      </div>
                      {isRebateTypeTransactionLOVs &&
                        isNull(
                          journalTransaction?.AssessmentRebateEntitlementId
                        ) && (
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <label className="cc-label">
                                Assess Rebate Entitlements
                              </label>
                              <Field
                                name={"AssessRebateEntitlements"}
                                component={CCDropDownList}
                                data={assessRebateEntitlements}
                                textField={"Name"}
                                dataItemKey={"Code"}
                              />
                            </div>
                          </div>
                        )}
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">Source</label>
                          <Field name="Source" component={CCInput} readOnly />
                        </div>
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Journal Number</label>
                        <Field
                          name="JournalNumber"
                          component={CCInput}
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Session Number</label>
                        <Field
                          name="SessionNumber"
                          component={CCInput}
                          readOnly
                        />
                      </div>
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">Created on</label>
                          <Field
                            name="CreatedOn"
                            component={CCDateTimePicker}
                            disabled
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Created by</label>
                          <Field
                            name="CreatedBy"
                            component={CCInput}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </FormElement>
                </div>
              )
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={props.onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  disabled={!formRenderProps.modified}
                  iconClass={isLoadingModify ? "fas fa-spinner fa-spin" : ""}
                  onClick={formRenderProps.onSubmit}
                >
                  OK
                </Button>
              </div>
            }
          />
        )}
      />
    );
  }
);
