import { IReOpenAction } from "@common/pages/actions/components/action-bar/dialog/reopen-action/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";
export interface IReopenActionDialogProps {
  onClosePopup: () => void;
  onSubmit: (dataSubmit: IReOpenAction) => void;
  isLoading: boolean;
}

export const ReopenActionDialog = observer(
  ({ onClosePopup, onSubmit, isLoading }: IReopenActionDialogProps) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const [initialValues, setInitialValues] = useState<IReOpenAction>();
    const nameOf = nameOfFactory<IReOpenAction>();

    const handleSubmit = async (event: FormSubmitClickEvent) => {
      const data = event.values;
      const dataSubmit: IReOpenAction = {
        Details: data.Details,
        AlertRequestor: data.AlertRequestor,
      };
      onSubmit(dataSubmit);
    };

    useEffectOnce(() => {
      setInitialValues({
        AlertRequestor: true,
      });
    });

    return (
      <Form
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        onSubmitClick={handleSubmit}
        render={({
          onSubmit,
          modified,
          valid,
          valueGetter,
        }: FormRenderProps) => {
          return (
            <FormElement>
              <CCDialog
                maxWidth="45%"
                height="auto"
                disabled={isLoading}
                titleHeader={"Re-Open Details"}
                onClose={onClosePopup}
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <section className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Re-open details
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            placeholder={"Re-Open Details"}
                            name={nameOf("Details")}
                            rows={4}
                            component={CCTextArea}
                            validator={requiredValidator}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Alert requestor of re-opening?
                          </label>
                          <Field
                            name={nameOf("AlertRequestor")}
                            component={CCSwitch}
                            checked={valueGetter(nameOf("AlertRequestor"))}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button
                      className={"cc-dialog-button"}
                      onClick={() => {
                        onClosePopup();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!modified || !valid}
                      themeColor="primary"
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                      onClick={onSubmit}
                      className={"cc-dialog-button"}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
