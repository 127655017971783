import { eventEmitter } from "@/App";
import { CCSettingListEventType } from "@app/products/property/system-admin/settings/_category/constant";
import { useSettingPropertyStore } from "@app/products/property/system-admin/settings/_category/store";
import { cloneSetting } from "@app/products/property/system-admin/settings/api";
import { useSettingCloneButtonStore } from "@app/products/property/system-admin/settings/components/action-bar/buttons/clone/store";
import { APIResponseStatus } from "@common/constants/response-status";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useLocation } from "react-router-dom";

export const PropertyActionBarSettingClone = observer(() => {
  const { isEditable, canClone, setIsClone } = useSettingPropertyStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { lastSelectedRow, setGridSelectedRows, gridSelectedRows } =
    useCCProductListViewStore();
  const location = useLocation();
  const { isLoading, setIsLoading } = useSettingCloneButtonStore();
  const state: any = location.state;
  const handleCloneButton = async () => {
    const recordIsClone: any = lastSelectedRow;
    const url =
      "/" +
      recordIsClone.ProductType_Enum +
      "/" +
      recordIsClone.SettingCategoryName +
      "/" +
      recordIsClone.Name +
      "/Clone/" +
      recordIsClone.ItemId;
    setIsLoading(true);
    await cloneSetting(url)
      .then((response: any) => {
        if (response.data && response.status === APIResponseStatus.SUCCESS) {
          setIsClone(true);
          eventEmitter.emit(CCSettingListEventType.RefreshOData, {
            notificationContentSuccess: "Cloned successfully",
            stateData: state,
          });
          setGridSelectedRows([]);
        } else if (
          response.data &&
          response.status === APIResponseStatus.BAD_REQUEST
        ) {
          const res = response.data;
          // const messages = res.Messages ? res.Messages : [];
          const typeMessage =
            res.MessageType === "Flash Information"
              ? "information"
              : res.MessageType.toLowerCase();
          const isClose =
            res.MessageType === "Flash Information" ? true : false;
          //TODO for using later
          // pushNotification(
          //   {
          //     primaryContent: res.HeaderMessage,
          //     secondaryContents: messages,
          //     type: typeMessage,
          //     isAutoClose: isClose,
          //   },
          // );
          pushNotification({
            title: res.HeaderMessage,
            type: typeMessage,
            autoClose: isClose,
          });
        } else {
          pushNotification({
            title: "Error occurred",
            type: "error",
          });
        }
      })
      .catch(() => {
        pushNotification({
          title: "Error occurred",
          type: "error",
        });
      });
    setIsLoading(false);
  };

  return (
    <>
      {isEditable && canClone && (
        <CCNavButton
          iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
          title="Clone"
          onClick={() => {
            handleCloneButton();
          }}
          disabled={gridSelectedRows.length !== 1 || isLoading}
        />
      )}
    </>
  );
});
