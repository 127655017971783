import { CoreAPIService } from "@/common/apis/coreAPIService";
import { ApplicationDecisionDetails } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/application-decision/model";
import { PPRApplicationIdentityPacket } from "@app/products/town-planning/ppr/[id]/model";
import { APIResponse } from "@common/apis/model";

export const putPanelHearingDecision = async (
  id: number,
  data: ApplicationDecisionDetails
): Promise<APIResponse<PPRApplicationIdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
      `/api/tp/ppr/internal/application/psar/${id}/panelhearingdecision`,
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
