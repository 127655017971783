import { getDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_category/api";
import { settingColumnField } from "@app/products/town-planning/ppr/system-admin/settings/_category/config";
import { MODE_SETTING } from "@app/products/town-planning/ppr/system-admin/settings/_category/model";
import {
  EClientSequenceKey,
  IData,
} from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import { processConfigData } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IColumnFields } from "@components/cc-grid/model";
import { newCCProductListViewStore } from "@components/cc-product-list-view/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class SettingGlobalStore {
  private _dataSetting?: any = undefined;
  private _isLoading: boolean = false;
  private _modeSetting: string = MODE_SETTING.NONE;
  private _primaryKeyTable: string = "Title";
  private _columnsFields: IColumnFields[] = [];
  private _responseLoadError?: APIResponseError = undefined;
  private _currentBreadcrumb: any = null;
  private _canClone: boolean = false;
  private _isClone: boolean = false;
  private _isDelete: boolean = false;
  private _isNew = false;
  private _isExistAfterCreated: boolean = false;
  private _configData: IData | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * check isDelete in action-bar list view
   */
  get isDelete(): boolean {
    return toJS(this._isDelete);
  }
  /**
   * set isDelete
   * @param isDelete
   */
  setIsDelete = (isDelete: boolean) => {
    runInAction(() => {
      this._isDelete = isDelete;
    });
  };

  /**
   * responseLoadError
   */
  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  /**
   * get mode for Setting
   */
  get modeSetting() {
    return this._modeSetting;
  }
  setModeSetting = (mode: string) => {
    runInAction(() => {
      this._modeSetting = mode;
    });
  };

  /**
   * check can clone
   */
  get canClone(): boolean {
    return toJS(this._canClone);
  }
  setCanClone = (canClone: boolean) => {
    runInAction(() => {
      this._canClone = canClone;
    });
  };

  /**
   * check isClone
   */
  get isClone(): boolean {
    return toJS(this._isClone);
  }
  setIsClone = (isClone: boolean) => {
    runInAction(() => {
      this._isClone = isClone;
    });
  };

  /**
   * get data breadcrumb
   */
  get currentBreadcrumb() {
    return this._currentBreadcrumb;
  }
  setCurrentBreadcrumb = (breadcrumb: any) => {
    runInAction(() => {
      this._currentBreadcrumb = breadcrumb;
    });
  };

  /**
   * get data Setting 2 types: Setting Folder and Setting Item
   */
  get dataSetting() {
    return toJS(this._dataSetting);
  }
  setDataSetting = (data?: any) => {
    runInAction(() => {
      this._dataSetting = data;
    });
  };

  /**
   * check is Loading
   */
  get isLoading(): boolean {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  /**
   * get primary key. It's dynamic field
   */
  get primaryKeyTable(): string {
    return this._primaryKeyTable;
  }
  setPrimaryKeyTable = (key: string) => {
    runInAction(() => {
      this._primaryKeyTable = key;
    });
  };

  /**
   * get dynamic column
   */
  get columnFields(): IColumnFields[] {
    return this._columnsFields;
  }
  setColumnField = (columnFields: IColumnFields[]) => {
    runInAction(() => {
      this._columnsFields = columnFields;
    });
  };

  /**
   * check is New
   */
  get isNew(): boolean {
    return toJS(this._isNew);
  }
  setIsNew = (isNew: boolean) => {
    runInAction(() => {
      this._isNew = isNew;
    });
  };

  /**
   * check Exist After Created
   */
  get isExistAfterCreated(): boolean {
    return toJS(this._isExistAfterCreated);
  }
  setIsExistAfterCreated = (isExist: boolean) => {
    this._isExistAfterCreated = isExist;
  };

  get configData(): IData | undefined {
    return toJS(this._configData);
  }
  setConfigData = (configData?: IData) => {
    runInAction(() => {
      this._configData = configData;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._dataSetting = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  /**
   * load data setting
   * check mode setting
   * set some properties
   * @param requestUrl
   * @returns
   */
  loadDataSetting = async (requestUrl: string) => {
    this.setIsLoading(true);
    const response = await getDataSetting(requestUrl);
    newCCProductListViewStore.setRequestUrl(requestUrl); // store request URL => keep state of CCProductListView
    let newDataSetting = undefined;
    let errorResponse = undefined;
    if (isSuccessResponse(response)) {
      let newColumns: IColumnFields[] = [];
      if (response.data === null || response === null) {
        this.setModeSetting(MODE_SETTING.SETTINGS_FOLDER);
        this.setIsLoading(false);
        this.setDataSetting([]);
        return;
      }
      if (response.data.hasOwnProperty("SettingsFolders")) {
        const title: string = response.data.TitleColumnHeader;
        const description: string = response.data.DescriptionColumnHeader;
        newColumns = settingColumnField(
          response.data.UseIsActive,
          title,
          description,
          false
        );
        this.setColumnField(newColumns);
        this.setPrimaryKeyTable("Title");
        newDataSetting = response.data.SettingsFolders;
        this.setModeSetting(MODE_SETTING.SETTINGS_FOLDER);
      } else if (
        response.data.Mode === "SettingsItem" ||
        response.data.hasOwnProperty("SettingFields")
      ) {
        if (response.data.hasOwnProperty("SettingFields")) {
          this.setModeSetting(MODE_SETTING.SETTINGS_ITEM);
          const processData = processConfigData(response.data?.SettingFields);
          if (processData) {
            this.setConfigData(processData);
          }
          newDataSetting = response.data;
        }
      } else {
        newDataSetting = response.data;
      }
      if (response.data) {
        this.setCurrentBreadcrumb(response.data);
        this.setCanClone(response.data.CanClone ?? false);
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
      this.setModeSetting(MODE_SETTING.NONE);
      this.setCurrentBreadcrumb(undefined);
    }
    this.setResponseLoadError(errorResponse);
    this.setDataSetting(newDataSetting);
    this.setIsLoading(false);
  };

  getConfigDataField = (
    setting: ECorporateSettingsField | string | EClientSequenceKey
  ) => {
    if (!this.configData) return undefined;
    return this.configData[setting.toString()];
  };
}

export const SettingGlobalStoreInstance = new SettingGlobalStore();
const settingGlobalStoreContext = createContext(SettingGlobalStoreInstance);
export const useSettingGlobalStore = () => {
  return useContext(settingGlobalStoreContext);
};
