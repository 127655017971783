import { renderOptionRequestor } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/config";
import {
  CS_RequestorType,
  CS_ServiceStandardType,
  ElementDisplayStatus,
} from "@app/products/crms/[id]/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { CRMSStandardDocuments } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/child-screens/standard-documents/_index";
import { AssetDetail } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/asset-details/_index";
import { AutomaticActions } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/automatic-actions/_index";
import { CaseDetailsSection } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/case-details/_index";
import { CommunicationSettings } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/communication-settings/_index";
import { CustomerServiceValidation } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/customer-service-validation/_index";
import { InternetInformationDetails } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/internet-information-details/_index";
import { RecordsDetails } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/records-details/_index";
import { Security } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/security/_index";
import { SpecialInterest } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/special-interest/_index";
import { TargetDays } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/target-days/_index";
import { NewCategoryDialog } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/dialogs/new-category/_index";
import {
  dataActionPersonAllocation,
  dataServiceStandardType,
  nameOfOrgUnit,
  optionOrgUnits,
  renderOptionActionOfficer,
} from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/form-element/config";
import {
  EModeCategoryDialog,
  IShowDialogCategory,
} from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/form-element/model";
import {
  mappingKeyCategory,
  mappingSerStandardCategory,
  mappingTitleDialogCategory,
} from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/form-element/util";
import {
  ServiceStandard,
  ServiceStandardHandlerRequest,
  ServiceStandardMapObj,
  Svc_FormAction,
} from "@app/products/crms/service-standards/[id]/model";
import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { ACCESSRIGHTS, RECORDSSYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboxSearchLov } from "@components/cc-combox-search-lov/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { OrgUnit } from "@components/cc-pick-org-units/model";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  RadioGroupChangeEvent,
  SwitchChangeEvent,
} from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

const nameOf = nameOfFactory<ServiceStandard>();
const nameOfServiceStandardMapObj = nameOfFactory<ServiceStandardMapObj>();
const nameOfKeyValuePacket = nameOfFactory<KeyValuePacket>();

export interface ICRMSFormElementProps {
  formRenderProps: FormRenderProps;
}

export const CRMSServiceStandardFormElement = observer(
  ({ formRenderProps }: ICRMSFormElementProps) => {
    const { onChange, valueGetter } = formRenderProps;
    const { settings } = useCommonCoreStore();
    const {
      crmsServiceStandardId,
      serviceStandardLovs,
      uiControl,
      serviceStandardChangeHandler,
      contactManagerDataURL,
      isExpandedActionAccordion,
      isExpandedSpecialInterestAccordion,
      isExpandedSecurityAccordion,
      isExpandedTargetDays,
      isFormModified,
      isExpandInternetInformationDetails,
      accessRight,
    } = useCRMSServiceStandardStore();
    const [showDialogCategory, setShowDialogCategory] = useState<
      IShowDialogCategory | undefined
    >();
    const serviceStandardFormObj = valueGetter(
      nameOfServiceStandardMapObj("ServiceStandard")
    );

    const recordsSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
    );

    const labelOfSubcategoryLevel3 = getStringValueSetting(
      settings[
        ECorporateSettingsField
          .CUSTOMERSERVICE_ServiceStandardSubCategoryLevel3Caption
      ]
    );
    const subcategoryLevel3IsMandatory = getBoolValueSetting(
      settings[
        ECorporateSettingsField
          .CUSTOMERSERVICE_ServiceStandardSubCategoryLevel3Mandatory
      ]
    );
    const labelOfSubcategoryLevel4 = getStringValueSetting(
      settings[
        ECorporateSettingsField
          .CUSTOMERSERVICE_ServiceStandardSubCategoryLevel4Caption
      ]
    );
    const subcategoryLevel4IsMandatory = getBoolValueSetting(
      settings[
        ECorporateSettingsField
          .CUSTOMERSERVICE_ServiceStandardSubCategoryLevel4Mandatory
      ]
    );
    const enableDistributedWithinSetting = getBoolValueSetting(
      settings[
        ECorporateSettingsField
          .CUSTOMERSERVICE_Event_RestrictAllocateOfficerOrgStructure
      ]
    );
    const actionOfficerLabel = getStringValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_Common_ActionOfficerLabel
      ]
    );
    const coordinatorLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_CoordinatorLabel]
    );
    const enableUrgentSetting = getBoolValueSetting(
      settings[ECorporateSettingsField.CUSTOMERSERVICE_Event_EnableUrgent]
    );
    const cxLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_ProductName]
    );

    const productNameLabel = uiControl?.FlDisplayAsCRMSChoice?.Value;
    const PNFLabel =
      getStringValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_PNFSystemLable]
      ) ?? "PNF";
    const isPNF = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_UsePNFSystem]
    );
    const townPlanningLabel = isPNF ? PNFLabel : undefined;

    const isShowDistributedWithin =
      uiControl?.DivDistributedWithin?.DisplayStatus ===
        ElementDisplayStatus.Visible && enableDistributedWithinSetting;

    const category = valueGetter(
      `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf("Category")}`
    )?.ServiceStandardCategory_Id;

    const subcategory = valueGetter(
      `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
        "SubCategory"
      )}`
    )?.ServiceStandardSubCategory_Id;

    const subcategoryLevel3 = valueGetter(
      `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
        "SubCategoryLevel3"
      )}`
    );

    const subcategoryLevel4 = valueGetter(
      `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
        "SubCategoryLevel4"
      )}`
    );

    const isShowSubcategoryLevel3 =
      uiControl?.DivSubCategoryLevel3.DisplayStatus ===
      ElementDisplayStatus.Visible;
    const isShowSubcategoryLevel4 =
      uiControl?.DivSubCategoryLevel4.DisplayStatus ===
      ElementDisplayStatus.Visible;
    const isShowWasteWater =
      uiControl?.DivWasteWater.DisplayStatus === ElementDisplayStatus.Visible;
    const isShowITSupport =
      uiControl?.DivITSupport.DisplayStatus === ElementDisplayStatus.Visible;
    const isShowKennel =
      uiControl?.DivKennel.DisplayStatus === ElementDisplayStatus.Visible;
    const isShowHardRubbish =
      uiControl?.DivHardRubbishFlag.DisplayStatus ===
      ElementDisplayStatus.Visible;
    const isShowControlledSubstance =
      uiControl?.DivControlledSubstance.DisplayStatus ===
      ElementDisplayStatus.Visible;
    const isShowAnimal =
      uiControl?.DivAnimal.DisplayStatus === ElementDisplayStatus.Visible;
    const isShowWWMS_CSM =
      uiControl?.DivWWMS_CSM.DisplayStatus === ElementDisplayStatus.Visible;
    const isDisabled = accessRight === ACCESSRIGHTS.CanRead;

    const handleChangeOrgStructure = (values: OrgUnit) => {
      let params: ServiceStandardHandlerRequest = {
        ServiceStandardFormAction: Svc_FormAction.PickOrgStructure,
        ServiceStandard: serviceStandardFormObj,
        EventArgs: [],
        IsFirstTimeLoad: false,
      };
      if (values) {
        params = { ...params, EventArgs: [values?.ID] };
      }

      serviceStandardChangeHandler(params, "Change org structure fail", true);
    };

    const handleChangeCategory = (data: any) => {
      let params: ServiceStandardHandlerRequest | undefined = undefined;
      if (data) {
        params = {
          ServiceStandardFormAction: Svc_FormAction.ChangeCategory,
          ServiceStandard: serviceStandardFormObj,
          EventArgs: { ServiceStandardCategory_Id: data.Key },
          IsFirstTimeLoad: false,
        };
      } else {
        params = {
          ServiceStandardFormAction: Svc_FormAction.ChangeCategory,
          ServiceStandard: serviceStandardFormObj,
          EventArgs: {},
          IsFirstTimeLoad: false,
        };
      }
      serviceStandardChangeHandler(params, "Change category fail");
    };

    const handleChangeSubCategory = (data: any) => {
      let params: ServiceStandardHandlerRequest | undefined = undefined;
      if (data) {
        params = {
          ServiceStandardFormAction: Svc_FormAction.ChangeSubCategory,
          ServiceStandard: serviceStandardFormObj,
          EventArgs: { ServiceStandardSubCategory_Id: data.Key },
          IsFirstTimeLoad: false,
        };
      } else {
        params = {
          ServiceStandardFormAction: Svc_FormAction.ChangeSubCategory,
          ServiceStandard: serviceStandardFormObj,
          EventArgs: {},
          IsFirstTimeLoad: false,
        };
      }
      serviceStandardChangeHandler(params, "Change subcategory fail");
    };

    const handleChangeSubCategoryLevel3 = (data: any) => {
      let params: ServiceStandardHandlerRequest | undefined = undefined;
      if (data) {
        params = {
          ServiceStandardFormAction: Svc_FormAction.ChangeSubCategoryL3,
          ServiceStandard: serviceStandardFormObj,
          EventArgs: { SubCategoryLevel3: data.Value },
          IsFirstTimeLoad: false,
        };
      } else {
        params = {
          ServiceStandardFormAction: Svc_FormAction.ChangeSubCategoryL3,
          ServiceStandard: serviceStandardFormObj,
          EventArgs: {},
          IsFirstTimeLoad: false,
        };
      }
      serviceStandardChangeHandler(params, "Change subcategory fail");
    };

    const handleChangeSubCategoryLevel4 = (data: any) => {
      let params: ServiceStandardHandlerRequest | undefined = undefined;
      if (data) {
        params = {
          ServiceStandardFormAction: Svc_FormAction.ChangeSubCategoryL4,
          ServiceStandard: serviceStandardFormObj,
          EventArgs: { SubCategoryLevel4: data.Value },
          IsFirstTimeLoad: false,
        };
      } else {
        params = {
          ServiceStandardFormAction: Svc_FormAction.ChangeSubCategoryL4,
          ServiceStandard: serviceStandardFormObj,
          EventArgs: {},
          IsFirstTimeLoad: false,
        };
      }
      serviceStandardChangeHandler(params, "Change subcategory fail");
    };

    const handleChangeLocationType = (data: MultiSelectChangeEvent) => {
      onChange(
        `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
          "IssueLocationTypes"
        )}`,
        { value: data.value }
      );
    };

    const handleChangeCoordinator = (data: any) => {
      let params: ServiceStandardHandlerRequest | undefined = undefined;
      if (data) {
        params = {
          ServiceStandardFormAction: Svc_FormAction.PickCoordinator,
          ServiceStandard: serviceStandardFormObj,
          EventArgs: { Coordinator_SiteUser_ID: data.Id },
          IsFirstTimeLoad: false,
        };
      } else {
        params = {
          ServiceStandardFormAction: Svc_FormAction.PickCoordinator,
          ServiceStandard: serviceStandardFormObj,
          EventArgs: {},
          IsFirstTimeLoad: false,
        };
      }

      serviceStandardChangeHandler(params, "Change coordinator fail");
    };

    const handleChangeActionOfficer = (data: any) => {
      let params: ServiceStandardHandlerRequest | undefined = undefined;
      if (data) {
        params = {
          ServiceStandardFormAction: Svc_FormAction.PickActionOfficer,
          ServiceStandard: serviceStandardFormObj,
          EventArgs: { ActionOfficer_SiteUser_ID: data.Id },
          IsFirstTimeLoad: false,
        };
      } else {
        params = {
          ServiceStandardFormAction: Svc_FormAction.PickActionOfficer,
          ServiceStandard: serviceStandardFormObj,
          EventArgs: {},
          IsFirstTimeLoad: false,
        };
      }

      serviceStandardChangeHandler(params, "Change action officer fail");
    };

    const handleChangeAutomaticAllocation = (event: SwitchChangeEvent) => {
      const params: ServiceStandardHandlerRequest | undefined = {
        ServiceStandardFormAction: Svc_FormAction.PickActionOfficer,
        ServiceStandard: serviceStandardFormObj,
        EventArgs: {},
        IsFirstTimeLoad: false,
      };

      onChange(
        `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
          "Flag_ActionOfficer_StaffMember"
        )}`,
        {
          value: dataActionPersonAllocation[0]?.value,
        }
      );
      onChange(
        `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
          "AutomaticAllocation"
        )}`,
        {
          value: event.value,
        }
      );

      serviceStandardChangeHandler(params, "Change action officer fail");
    };

    const handleChangeHasTargetDay = (event: SwitchChangeEvent) => {
      onChange(
        `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
          "EnableTargetDays"
        )}`,
        { value: event.value }
      );

      if (event.value) {
        onChange(
          `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
            "Targets_FinalNotification"
          )}`,
          { value: false }
        );

        onChange(
          `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
            "Targets_IncludeWeekendInCalculation"
          )}`,
          { value: false }
        );

        onChange(
          `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
            "Targets_AllowRescheduling"
          )}`,
          { value: false }
        );
      }
    };

    useEffect(() => {
      if (isFormModified) {
        onChange(
          `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
            "ServiceStandard_Id"
          )}`,
          {
            value: valueGetter(
              `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "ServiceStandard_Id"
              )}`
            ),
          }
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormModified]);

    const handleSubmitCategory = (
      type: EModeCategoryDialog,
      name: string,
      dataLov?: IKeyValuePacket[]
    ) => {
      let params: ServiceStandardHandlerRequest | undefined = undefined;
      const typeCategory = mappingKeyCategory?.[type];
      const serviceStandardForm = mappingSerStandardCategory?.[type];
      params = {
        ServiceStandardFormAction: serviceStandardForm,
        ServiceStandard: serviceStandardFormObj,
        EventArgs: { [typeCategory?.key]: name },
        IsFirstTimeLoad: false,
      };

      // Check duplicate category
      if (dataLov?.length) {
        const isDuplicate = dataLov.find(
          (item: IKeyValuePacket) => item.Value === name
        );
        if (!!isDuplicate) {
          switch (serviceStandardForm) {
            case Svc_FormAction.CreateSubCategoryL3:
              params = {
                ...params,
                ServiceStandardFormAction: Svc_FormAction.ChangeSubCategoryL3,
                EventArgs: { SubCategoryLevel3: name },
              };
              break;
            case Svc_FormAction.CreateSubCategoryL4:
              params = {
                ...params,
                ServiceStandardFormAction: Svc_FormAction.ChangeSubCategoryL4,
                EventArgs: { SubCategoryLevel4: name },
              };
              break;
          }
        }
      }
      serviceStandardChangeHandler(params, typeCategory?.message);
    };

    return (
      <>
        {showDialogCategory && (
          <NewCategoryDialog
            titleDialog={mappingTitleDialogCategory[showDialogCategory?.type]}
            onClose={() => setShowDialogCategory(undefined)}
            onSubmit={(name: string) => {
              handleSubmitCategory(
                showDialogCategory?.type,
                name,
                showDialogCategory?.dataLov
              );
              setShowDialogCategory(undefined);
            }}
            data={showDialogCategory?.data}
          />
        )}
        <FormElement>
          <CCGeneralPanel
            component={
              <>
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Category" isMandatory />
                      <Field
                        component={CCComboxSearchLov}
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Category")}`}
                        dataLov={uiControl?.DdlCategory?.Dataset ?? []}
                        textField={nameOfKeyValuePacket("Value")}
                        dataItemKey={nameOfKeyValuePacket("Key")}
                        onChange={handleChangeCategory}
                        validator={requiredValidator}
                        valueField={category}
                        isShowNewFooter
                        onChangeNewButton={(searchKey: string) =>
                          setShowDialogCategory({
                            type: EModeCategoryDialog.Category,
                            data: searchKey,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Subcategory" isMandatory />
                      <Field
                        isShowNewFooter
                        component={CCComboxSearchLov}
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("SubCategory")}`}
                        dataLov={uiControl?.DdlSubCategory?.Dataset ?? []}
                        textField={nameOfKeyValuePacket("Value")}
                        dataItemKey={nameOfKeyValuePacket("Key")}
                        onChange={handleChangeSubCategory}
                        validator={requiredValidator}
                        valueField={subcategory}
                        onChangeNewButton={(searchKey: string) =>
                          setShowDialogCategory({
                            type: EModeCategoryDialog.Subcategory,
                            data: searchKey,
                          })
                        }
                      />
                    </div>
                    {isShowSubcategoryLevel3 && (
                      <div className="cc-field">
                        <CCLabel
                          title={
                            labelOfSubcategoryLevel3 ?? "Subcategory Level 3"
                          }
                          isMandatory={subcategoryLevel3IsMandatory}
                        />
                        <Field
                          component={CCComboxSearchLov}
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("SubCategoryLevel3")}`}
                          dataLov={
                            uiControl?.DdlSubCategoryLevel3?.Dataset ?? []
                          }
                          textField="Value"
                          dataItemKey="Value"
                          onChange={handleChangeSubCategoryLevel3}
                          validator={
                            subcategoryLevel3IsMandatory
                              ? requiredValidator
                              : undefined
                          }
                          valueField={subcategoryLevel3}
                          isShowNewFooter
                          onChangeNewButton={(searchKey: string) =>
                            setShowDialogCategory({
                              type: EModeCategoryDialog.SubCategoryLevel3,
                              data: searchKey,
                              dataLov:
                                uiControl?.DdlSubCategoryLevel3?.Dataset ?? [],
                            })
                          }
                        />
                      </div>
                    )}
                    {isShowSubcategoryLevel4 && (
                      <div className="cc-field">
                        <CCLabel
                          title={
                            labelOfSubcategoryLevel4 ?? "Subcategory Level 4"
                          }
                          isMandatory={subcategoryLevel4IsMandatory}
                        />
                        <Field
                          component={CCComboxSearchLov}
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("SubCategoryLevel4")}`}
                          dataLov={
                            uiControl?.DdlSubCategoryLevel4?.Dataset ?? []
                          }
                          textField="Value"
                          dataItemKey="Value"
                          onChange={handleChangeSubCategoryLevel4}
                          validator={
                            subcategoryLevel4IsMandatory
                              ? requiredValidator
                              : undefined
                          }
                          valueField={subcategoryLevel4}
                          isShowNewFooter
                          onChangeNewButton={(searchKey: string) =>
                            setShowDialogCategory({
                              type: EModeCategoryDialog.SubCategoryLevel4,
                              data: searchKey,
                              dataLov:
                                uiControl?.DdlSubCategoryLevel4?.Dataset ?? [],
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Precis" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Name")}`}
                        component={CCInput}
                        placeholder={"Precis"}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <CCLabel title="Description" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Description")}`}
                        placeholder="Description"
                        rows={6}
                        component={CCTextArea}
                      />
                    </div>
                  </div>
                </section>
                <hr className="cc-divider" />
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Org structure" isMandatory />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Org1_Id")}`}
                        nameDisplay="DisplayName"
                        placeholder="Select org units"
                        component={InputPickerSearch}
                        keyMapGrid={nameOfOrgUnit("Hierarchy")}
                        valueMapGrid={uiControl?.LblOrgStructure?.Value}
                        onChange={handleChangeOrgStructure}
                        validator={requiredValidator}
                        options={optionOrgUnits}
                        value={uiControl?.LblOrgStructure?.Value}
                      />
                    </div>
                    {isShowDistributedWithin && (
                      <div className="cc-field">
                        <CCLabel title="Distributed within" isMandatory />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("DistributedWithin")}`}
                          textField={nameOfKeyValuePacket("Value")}
                          dataItemKey={nameOfKeyValuePacket("Key")}
                          data={uiControl?.DivDistributedWithin?.Dataset ?? []}
                          component={CCSearchComboBox}
                          validator={requiredValidator}
                          isUseDefaultOnchange
                        />
                      </div>
                    )}
                  </div>
                </section>
                <hr className="cc-divider" />
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel
                        title={coordinatorLabel ?? "Coordinator"}
                        isMandatory
                      />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Coordinator")}`}
                        nameDisplay="DisplayName"
                        placeholder="Select requestor"
                        component={InputPickerSearch}
                        valueMapGrid={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Coordinator_SiteUser_ID")}`
                        )}
                        onChange={handleChangeCoordinator}
                        validator={requiredValidator}
                        options={renderOptionRequestor(
                          CS_RequestorType.InternalPerson
                        )}
                      />
                    </div>
                  </div>

                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Automatic allocation" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("AutomaticAllocation")}`}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("AutomaticAllocation")}`
                        )}
                        component={CCSwitch}
                        onChange={handleChangeAutomaticAllocation}
                        value={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("AutomaticAllocation")}`
                        )}
                      />
                    </div>
                    {valueGetter(
                      `${nameOfServiceStandardMapObj(
                        "ServiceStandard"
                      )}.${nameOf("AutomaticAllocation")}`
                    ) && (
                      <div className="cc-field">
                        <CCLabel
                          title={`${
                            actionOfficerLabel ?? "Action officer"
                          } allocation`}
                        />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_ActionOfficer_StaffMember")}`}
                          component={CCRadioGroup}
                          layout={"horizontal"}
                          data={dataActionPersonAllocation}
                        />
                      </div>
                    )}

                    {valueGetter(
                      `${nameOfServiceStandardMapObj(
                        "ServiceStandard"
                      )}.${nameOf("AutomaticAllocation")}`
                    ) &&
                      valueGetter(
                        `${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Flag_ActionOfficer_StaffMember")}`
                      ) && (
                        <div className="cc-field">
                          <CCLabel
                            title={actionOfficerLabel ?? "Action officer"}
                            isMandatory
                          />
                          <Field
                            disabled={isDisabled}
                            name={`${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("ActionOfficer")}`}
                            nameDisplay="DisplayName"
                            placeholder="Select action officer"
                            component={InputPickerSearch}
                            valueMapGrid={valueGetter(
                              `${nameOfServiceStandardMapObj(
                                "ServiceStandard"
                              )}.${nameOf("ActionOfficer_SiteUser_ID")}`
                            )}
                            onChange={handleChangeActionOfficer}
                            validator={requiredValidator}
                            options={renderOptionActionOfficer(
                              contactManagerDataURL
                            )}
                          />
                        </div>
                      )}
                    {valueGetter(
                      `${nameOfServiceStandardMapObj(
                        "ServiceStandard"
                      )}.${nameOf("AutomaticAllocation")}`
                    ) &&
                      !valueGetter(
                        `${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Flag_ActionOfficer_StaffMember")}`
                      ) && (
                        <div className="cc-field">
                          <CCLabel title="Roster type" isMandatory />
                          <Field
                            disabled={isDisabled}
                            name={`${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("RosterType_ENUM")}`}
                            textField="Value"
                            dataItemKey="Key"
                            data={serviceStandardLovs?.RosterType}
                            component={CCSearchComboBox}
                            validator={requiredValidator}
                            isUseDefaultOnchange
                          />
                        </div>
                      )}
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel
                        title={`Send to ${coordinatorLabel ?? "coordinator"}`}
                        isMandatory
                      />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("SendToCoordinator")}`}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("SendToCoordinator")}`
                        )}
                        component={CCSwitch}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel
                        title={`Send to ${
                          actionOfficerLabel ?? "action officer"
                        }`}
                        isMandatory
                      />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("SendToActionOfficer")}`}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("SendToActionOfficer")}`
                        )}
                        component={CCSwitch}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Allow anonymous" isMandatory />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("AllowAnonymous")}`}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("AllowAnonymous")}`
                        )}
                        component={CCSwitch}
                      />
                    </div>
                  </div>
                </section>
                <hr className="cc-divider" />
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Service standard type" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("ServiceStandardType_ENUM")}`}
                        component={CCRadioGroup}
                        layout={"horizontal"}
                        data={dataServiceStandardType}
                        onChange={(e: RadioGroupChangeEvent) => {
                          onChange(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("ServiceStandardType_ENUM")}`,
                            {
                              value: e.value,
                            }
                          );
                        }}
                      />
                    </div>
                    {valueGetter(
                      `${nameOfServiceStandardMapObj(
                        "ServiceStandard"
                      )}.${nameOf("ServiceStandardType_ENUM")}`
                    ) === CS_ServiceStandardType.Incident && (
                      <div className="cc-field">
                        <CCLabel title="PHMS details" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_IncidentTypeLookup")}`}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("Flag_IncidentTypeLookup")}`
                          )}
                          component={CCSwitch}
                        />
                      </div>
                    )}
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Additional reference number" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Flag_ReferenceNumberAdditional")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_ReferenceNumberAdditional")}`
                        )}
                      />
                    </div>
                    {valueGetter(
                      `${nameOfServiceStandardMapObj(
                        "ServiceStandard"
                      )}.${nameOf("Flag_ReferenceNumberAdditional")}`
                    ) && (
                      <div className="cc-field">
                        <CCLabel title="Additional ref no label" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("ReferenceNumberAdditionalLabel")}`}
                          component={CCInput}
                        />
                      </div>
                    )}
                  </div>
                </section>
                <hr className="cc-divider" />
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Allow recategorise" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("AllowRecategorise")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("AllowRecategorise")}`
                        )}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Allow reallocate officer" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("AllowReallocateOfficer")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("AllowReallocateOfficer")}`
                        )}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Enable urgent" />
                      <Field
                        disabled={isDisabled || !enableUrgentSetting}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("EnableUrgent")}`}
                        component={CCSwitch}
                        checked={
                          enableUrgentSetting
                            ? valueGetter(
                                `${nameOfServiceStandardMapObj(
                                  "ServiceStandard"
                                )}.${nameOf("EnableUrgent")}`
                              )
                            : enableUrgentSetting
                        }
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Has notifications / alerts" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("HasAlerts")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("HasAlerts")}`
                        )}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Has target days" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("EnableTargetDays")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("EnableTargetDays")}`
                        )}
                        onChange={handleChangeHasTargetDay}
                        value={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("EnableTargetDays")}`
                        )}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Has special interest" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("HasSpecialInterest")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("HasSpecialInterest")}`
                        )}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Allow quick close" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("EnableQuickClose")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("EnableQuickClose")}`
                        )}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Assessment no is mandatory" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Flag_AssessmentNo")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_AssessmentNo")}`
                        )}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Requestor address is mandatory" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Flag_RequestorAddressMandatory")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_RequestorAddressMandatory")}`
                        )}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel
                        title="On behalf of (for internal requests)"
                        isMandatory
                      />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("OnBehalfOfType_ENUM")}`}
                        textField="Value"
                        dataItemKey="Key"
                        data={serviceStandardLovs?.OnBehalfOf}
                        component={CCSearchComboBox}
                        validator={requiredValidator}
                        isUseDefaultOnchange
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Prompt for location" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Flag_PropertySearch")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_PropertySearch")}`
                        )}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Set me as requestor" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Flag_MeAsRequestor")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_MeAsRequestor")}`
                        )}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Add attachment" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Flag_AddAttachment")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_AddAttachment")}`
                        )}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Show map" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Flag_ShowMap")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_ShowMap")}`
                        )}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Lock event on send for action" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Flag_LockEvent")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_LockEvent")}`
                        )}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Enable complainee" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("IsComplaineeEnabled")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("IsComplaineeEnabled")}`
                        )}
                      />
                    </div>
                    {valueGetter(
                      `${nameOfServiceStandardMapObj(
                        "ServiceStandard"
                      )}.${nameOf("IsComplaineeEnabled")}`
                    ) && (
                      <div className="cc-field">
                        <CCLabel title="Complainee label" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("ComplaineeLabel")}`}
                          component={CCInput}
                          placeholder={"Complainee label"}
                        />
                      </div>
                    )}

                    <div className="cc-field">
                      <CCLabel title="Email requestor on schedule" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("EmailRequestorOnSchedule")}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("EmailRequestorOnSchedule")}`
                        )}
                      />
                    </div>

                    <div className="cc-field">
                      <CCLabel title="Default request type" />
                      <Field
                        disabled={isDisabled}
                        name={`${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("DefaultEventType_ID")}`}
                        data={serviceStandardLovs?.EventType}
                        textField="Value"
                        dataItemKey="Key"
                        component={CCSearchComboBox}
                        isUseDefaultOnchange
                      />
                    </div>
                  </div>
                </section>
                <hr className="cc-divider" />
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <CCLabel title="System interface" />
                  </div>
                  <div className="cc-field-group cc-custom-sub-panel-bar">
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel
                          title={
                            productNameLabel ??
                            `Display in ${cxLabel ?? CRMS_PRODUCT_NAME}`
                          }
                        />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("DisplayAsCRMSChoice")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("DisplayAsCRMSChoice")}`
                          )}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Knowledge base" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("DisplayInBulletinBoard")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("DisplayInBulletinBoard")}`
                          )}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="System" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_System")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("Flag_System")}`
                          )}
                        />
                      </div>
                    </div>
                    {isShowWWMS_CSM && (
                      <div className="cc-form-cols-3">
                        {isShowWasteWater && (
                          <div className="cc-field">
                            <CCLabel title="Waste Water" />
                            <Field
                              disabled={isDisabled}
                              name={`${nameOfServiceStandardMapObj(
                                "ServiceStandard"
                              )}.${nameOf("HasWasteWater")}`}
                              component={CCSwitch}
                              checked={valueGetter(
                                `${nameOfServiceStandardMapObj(
                                  "ServiceStandard"
                                )}.${nameOf("HasWasteWater")}`
                              )}
                            />
                          </div>
                        )}
                        {isShowControlledSubstance && (
                          <div className="cc-field">
                            <CCLabel title="Controlled substance" />
                            <Field
                              disabled={isDisabled}
                              name={`${nameOfServiceStandardMapObj(
                                "ServiceStandard"
                              )}.${nameOf("HasControlledSubstance")}`}
                              component={CCSwitch}
                              checked={valueGetter(
                                `${nameOfServiceStandardMapObj(
                                  "ServiceStandard"
                                )}.${nameOf("HasControlledSubstance")}`
                              )}
                            />
                          </div>
                        )}
                        {isShowAnimal && (
                          <div className="cc-field">
                            <CCLabel title="Animal" />
                            <Field
                              disabled={isDisabled}
                              name={`${nameOfServiceStandardMapObj(
                                "ServiceStandard"
                              )}.${nameOf("IsAnimal")}`}
                              component={CCSwitch}
                              checked={valueGetter(
                                `${nameOfServiceStandardMapObj(
                                  "ServiceStandard"
                                )}.${nameOf("IsAnimal")}`
                              )}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    <div className="cc-form-cols-3">
                      {isShowITSupport && (
                        <div className="cc-field">
                          <CCLabel title="IT support" />
                          <Field
                            disabled={isDisabled}
                            name={`${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("Flag_ITSupport")}`}
                            component={CCSwitch}
                            checked={valueGetter(
                              `${nameOfServiceStandardMapObj(
                                "ServiceStandard"
                              )}.${nameOf("Flag_ITSupport")}`
                            )}
                          />
                        </div>
                      )}
                      <div className="cc-field">
                        <CCLabel title="Health" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("IsHealth")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("IsHealth")}`
                          )}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Local laws" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("IsLocalLaws")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("IsLocalLaws")}`
                          )}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel title={townPlanningLabel ?? "Town planning"} />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("IsTownPlanning")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("IsTownPlanning")}`
                          )}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Building" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("IsBuilding")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("IsBuilding")}`
                          )}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Internet choice" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("DisplayAsInternetChoice")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("DisplayAsInternetChoice")}`
                          )}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel title="EMS" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("IsEMS")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("IsEMS")}`
                          )}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Parking permits" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("IsParkingPermit")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("IsParkingPermit")}`
                          )}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Infringements" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("IsInfringements")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("IsInfringements")}`
                          )}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Land management" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("IsLandManagement")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("IsLandManagement")}`
                          )}
                        />
                      </div>
                      {isShowKennel && (
                        <div className="cc-field">
                          <CCLabel title="Kennel" />
                          <Field
                            disabled={isDisabled}
                            name={`${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("IsKennels")}`}
                            component={CCSwitch}
                            checked={valueGetter(
                              `${nameOfServiceStandardMapObj(
                                "ServiceStandard"
                              )}.${nameOf("IsKennels")}`
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </section>
                <hr className="cc-divider" />
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <label className="cc-label">Additional interface</label>
                  </div>
                  <div className="cc-field-group cc-custom-sub-panel-bar">
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel title="Simple form" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_SimpleForm")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("Flag_SimpleForm")}`
                          )}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Inspections" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_Inspection")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("Flag_Inspection")}`
                          )}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Enable animals lookup" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("IsAnimalsLookUpEnabled")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("IsAnimalsLookUpEnabled")}`
                          )}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel title="Notices" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("IsNotice")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("IsNotice")}`
                          )}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Auto actions" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_WorkflowCreateActions")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("Flag_WorkflowCreateActions")}`
                          )}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Enable bins lookup" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("IsEnableBinsLookUp")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("IsEnableBinsLookUp")}`
                          )}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel title="Assets" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("HasAsset")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("HasAsset")}`
                          )}
                        />
                      </div>
                      {isShowHardRubbish && (
                        <div className="cc-field">
                          <CCLabel title="Hard rubbish" />
                          <Field
                            disabled={isDisabled}
                            name={`${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("Flag_HardRubbish")}`}
                            component={CCSwitch}
                            checked={valueGetter(
                              `${nameOfServiceStandardMapObj(
                                "ServiceStandard"
                              )}.${nameOf("Flag_HardRubbish")}`
                            )}
                          />
                        </div>
                      )}
                      <div className="cc-field">
                        <CCLabel title="Payroll" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_Payroll")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("Flag_Payroll")}`
                          )}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel title="Collection date" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("isCollectionDate")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("isCollectionDate")}`
                          )}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Enable school patrols" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("EnableSchoolPatrols")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("EnableSchoolPatrols")}`
                          )}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Enable nuisance act" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("EnableNuisanceAct")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("EnableNuisanceAct")}`
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <hr className="cc-divider" />
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <CCLabel title="After hours" />
                  </div>
                  <div className="cc-field-group cc-custom-sub-panel-bar">
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel title="After hours send email" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("Flag_AfterHoursSendEmail")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("Flag_AfterHoursSendEmail")}`
                          )}
                        />
                      </div>
                      {valueGetter(
                        `${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("Flag_AfterHoursSendEmail")}`
                      ) && (
                        <div className="cc-field">
                          <CCLabel title="After hours email address" />
                          <Field
                            disabled={isDisabled}
                            name={`${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("AfterHoursEmailAddress")}`}
                            component={CCInput}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </section>
                <hr className="cc-divider" />
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <CCLabel title="Issue location types" />
                  </div>
                  <div className="cc-field-group cc-custom-sub-panel-bar">
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel title="Use system defaults" />
                        <Field
                          disabled={isDisabled}
                          name={`${nameOfServiceStandardMapObj(
                            "ServiceStandard"
                          )}.${nameOf("IssueLocationTypes_UseDefaults")}`}
                          component={CCSwitch}
                          checked={valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("IssueLocationTypes_UseDefaults")}`
                          )}
                        />
                      </div>
                      {!valueGetter(
                        `${nameOfServiceStandardMapObj(
                          "ServiceStandard"
                        )}.${nameOf("IssueLocationTypes_UseDefaults")}`
                      ) && (
                        <div className="cc-field">
                          <CCLabel title="Issue location types" />
                          <Field
                            disabled={isDisabled}
                            name={`${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("IssueLocationTypes")}`}
                            textField={"Name"}
                            dataItemKey={"LookupTable_Id"}
                            data={serviceStandardLovs?.IssueLocationTypes}
                            component={CCMultiSelectDropdown}
                            onChange={handleChangeLocationType}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </section>

                {uiControl?.DivComplaintHandling?.DisplayStatus ===
                  ElementDisplayStatus.Visible && (
                  <>
                    <hr className="cc-divider" />
                    <section className="cc-field-group">
                      <div className="cc-form-cols-3">
                        <CCLabel title="Complaint handling" />
                      </div>
                      <div className="cc-field-group cc-custom-sub-panel-bar">
                        <div className="cc-form-cols-3">
                          <div className="cc-field">
                            <CCLabel title="Enable tier lookup" />
                            <Field
                              name={`${nameOfServiceStandardMapObj(
                                "ServiceStandard"
                              )}.${nameOf("EnableTierLookup")}`}
                              component={CCSwitch}
                              checked={valueGetter(
                                `${nameOfServiceStandardMapObj(
                                  "ServiceStandard"
                                )}.${nameOf("EnableTierLookup")}`
                              )}
                              disabled={isDisabled}
                            />
                          </div>

                          {valueGetter(
                            `${nameOfServiceStandardMapObj(
                              "ServiceStandard"
                            )}.${nameOf("EnableTierLookup")}`
                          ) && (
                            <div className="cc-field">
                              <CCLabel title="Default tier" />
                              <Field
                                name={`${nameOfServiceStandardMapObj(
                                  "ServiceStandard"
                                )}.${nameOf("Tier_ID")}`}
                                textField="Value"
                                dataItemKey="Key"
                                data={serviceStandardLovs?.Tier ?? []}
                                component={CCSearchComboBox}
                                isUseDefaultOnchange
                                placeholder="Default tier"
                                disabled={isDisabled}
                              />
                            </div>
                          )}
                        </div>

                        <div className="cc-form-cols-3">
                          <div className="cc-field">
                            <CCLabel title="Enable information/procedure" />
                            <Field
                              name={`${nameOfServiceStandardMapObj(
                                "ServiceStandard"
                              )}.${nameOf(
                                "EnableInformationOrProcedureDisplay"
                              )}`}
                              component={CCSwitch}
                              checked={valueGetter(
                                `${nameOfServiceStandardMapObj(
                                  "ServiceStandard"
                                )}.${nameOf(
                                  "EnableInformationOrProcedureDisplay"
                                )}`
                              )}
                              disabled={isDisabled}
                            />
                          </div>
                        </div>

                        <div className="cc-form-cols-3">
                          <div className="cc-field">
                            <CCLabel title="Enable customer contacted" />
                            <Field
                              name={`${nameOfServiceStandardMapObj(
                                "ServiceStandard"
                              )}.${nameOf("EnableCustomerContacted")}`}
                              component={CCSwitch}
                              checked={valueGetter(
                                `${nameOfServiceStandardMapObj(
                                  "ServiceStandard"
                                )}.${nameOf("EnableCustomerContacted")}`
                              )}
                              disabled={isDisabled}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                )}
              </>
            }
          />
          <CustomPanelBar
            expandMode="multiple"
            sort={false}
            listPanelBar={[
              // { Implement and recheck later
              //   title: "Hard rubbish",
              //   component: <HardRubbish formRenderProps={formRenderProps} />,
              //   isVisible:
              //     valueGetter(
              //       `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              //         "Flag_HardRubbish"
              //       )}`
              //     ) ?? false,
              // },
              {
                title: "Asset Details",
                component: <AssetDetail formRenderProps={formRenderProps} />,
                isVisible:
                  valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "HasAsset"
                    )}`
                  ) ?? false,
              },
              {
                title: "Automatic Actions",
                component: (
                  <AutomaticActions formRenderProps={formRenderProps} />
                ),
                isVisible:
                  valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "Flag_WorkflowCreateActions"
                    )}`
                  ) ?? false,
                expanded: isExpandedActionAccordion,
              },
              {
                title: "Case Details",
                component: (
                  <CaseDetailsSection formRenderProps={formRenderProps} />
                ),
                isVisible:
                  valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "HasCaseDetails"
                    )}`
                  ) ?? false,
              },
              {
                title: "Communication Settings (Email & SMS)",
                component: (
                  <CommunicationSettings formRenderProps={formRenderProps} />
                ),
              },
              {
                title: "Customer Service Validation and Resolution Responses",
                component: (
                  <CustomerServiceValidation
                    formRenderProps={formRenderProps}
                  />
                ),
              },

              {
                title: "Records",
                component: <RecordsDetails />,
                isVisible: recordsSystem !== RECORDSSYSTEM.None,
              },
              {
                title: "Internet Information Details",
                component: (
                  <InternetInformationDetails
                    formRenderProps={formRenderProps}
                  />
                ),
                isVisible: valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "DisplayAsInternetChoice"
                  )}`
                ),
                expanded: isExpandInternetInformationDetails,
              },
              {
                title: "Security",
                component: <Security formRenderProps={formRenderProps} />,
                expanded: isExpandedSecurityAccordion,
              },
              {
                title: "Special Interest",
                component: (
                  <SpecialInterest formRenderProps={formRenderProps} />
                ),
                expanded: isExpandedSpecialInterestAccordion,
                isVisible:
                  valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "HasSpecialInterest"
                    )}`
                  ) ?? false,
              },
              {
                title: "Target Days",
                component: <TargetDays formRenderProps={formRenderProps} />,
                isVisible:
                  valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "EnableTargetDays"
                    )}`
                  ) ?? false,
                expanded: isExpandedTargetDays,
              },
              {
                title: "Standard Documents",
                component: <CRMSStandardDocuments id={crmsServiceStandardId} />,
              },
            ]}
          />
          {/* TODO:Implement later */}
          {/* <CustomPanelBar
       expandMode="multiple"
       listPanelBar={[
         {
           title: "Health Manager",
           expanded: true,
           component: (
             <section className="cc-field-group">
               <div className="cc-form-cols-3">
                 <div className="cc-field">
                   <label className="cc-label">Mobile choice</label>
                   <Field
                     name={nameOf("MobileChoice")}
                     checked={valueGetter(nameOf("MobileChoice"))}
                     component={CCSwitch}
                   />
                 </div>
                 <div className="cc-field">
                   <label className="cc-label">Mobile icon</label>
                   <Field
                     name={nameOf("MobileIcon")}
                     textField="Value"
                     dataItemKey="Key"
                     data={mockCRMSCategory}
                     component={CCSearchComboBox}
                     value={getDropdownValue(
                       valueGetter(nameOf("MobileIcon")),
                       mockCRMSCategory,
                       "Key"
                     )}
                     onChange={(event: ComboBoxChangeEvent) => {
                       onChange(nameOf("MobileIcon"), {
                         value: event.target.value?.Key ?? null,
                       });
                     }}
                   />
                 </div>
               </div>
             </section>
           ),
         },
         {
           title: "Mobile Details",
           expanded: true,
           component: (
             <section className="cc-field-group">
               <div className="cc-form-cols-3">
                 <div className="cc-field">
                   <label className="cc-label">FSR code</label>
                   <Field
                     name={nameOf("FsrCode")}
                     textField="Value"
                     dataItemKey="Key"
                     data={mockCRMSFSRCode}
                     component={CCSearchComboBox}
                     value={getDropdownValue(
                       valueGetter(nameOf("FsrCode")),
                       mockCRMSFSRCode,
                       "Key"
                     )}
                     onChange={(event: ComboBoxChangeEvent) => {
                       onChange(nameOf("FsrCode"), {
                         value: event.target.value?.Key ?? null,
                       });
                     }}
                   />
                 </div>
               </div>
               <div className="cc-form-cols-3">
                 <div className="cc-field">
                   <label className="cc-label">
                     Show health fields on event closure
                   </label>
                   <Field
                     name={nameOf("ShowHealthFieldsOnEventClosure")}
                     checked={valueGetter(
                       nameOf("ShowHealthFieldsOnEventClosure")
                     )}
                     component={CCSwitch}
                   />
                 </div>
               </div>
             </section>
           ),
         },
       ]}
     /> */}
        </FormElement>
      </>
    );
  }
);
