import { useDebtRecoveryStore } from "@app/products/property/assessments/debt-recovery/[id]/store";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { DebtRecoveryFormElement } from "./components/form-element/_index";

export const DebtRecoveryForm = observer(() => {
  const { debtRecovery, isLoading } = useDebtRecoveryStore();
  if (isLoading) return null;
  return (
    <div className="cc-form">
      <Form
        initialValues={debtRecovery}
        render={(formRenderProps: FormRenderProps) => (
          <DebtRecoveryFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
