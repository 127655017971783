import { nameOfFactory } from "@common/utils/common";
import { IOfficers } from "@components/cc-contact-manager/model";
import { IColumnFields } from "@components/cc-grid/model";

export const nameOfContactOfficer = nameOfFactory<IOfficers>();

export const colAddContactOfficer: IColumnFields[] = [
  {
    field: nameOfContactOfficer("Name"),
    title: "Name",
  },
  {
    field: nameOfContactOfficer("Position"),
    title: "Position",
  },
  {
    field: nameOfContactOfficer("Role"),
    title: "Role",
  },
  {
    field: nameOfContactOfficer("Email"),
    title: "Email",
  },
  {
    field: nameOfContactOfficer("WorkPhone"),
    title: "Work Phone",
  },
  {
    field: nameOfContactOfficer("HomePhone"),
    title: "Home Phone",
  },
  {
    field: nameOfContactOfficer("Mobile"),
    title: "Mobile",
  },
];
