import { IFinancialSummaryDetail } from "@app/products/property/assessments/financial-summaries/[id]/components/reference-sidebar/detail/model";
import { mockGridSummary } from "@app/products/property/assessments/financial-summaries/[id]/mock";
import { sleep } from "@common/utils/common";
import { totalColumnField } from "@common/utils/grid";

export const getFinancialSummaryDetailsTabById = async (
  financialSummaryId: number
): Promise<IFinancialSummaryDetail> => {
  await sleep(1000);
  return {
    ID: financialSummaryId,
    Name: "Levy Balances As At 22/3/2010, post ACTH levy code change",
    TotalAmount: totalColumnField("Amount", mockGridSummary),
    TotalBalance: totalColumnField("Total", mockGridSummary),
    TotalInterest: totalColumnField("Interest", mockGridSummary),
  };
};
