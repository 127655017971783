import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingFinanceOSFees: IColumnFields[] = [
  {
    field: BuildingFieldMapping.ReferenceNumber,
    title: "Application Number",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.ContactName,
    title: "Applicant Name",
  },
  {
    field: BuildingFieldMapping.CreatedDate,
    title: "Recorded",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Type,
    title: "Type",
  },
  {
    field: BuildingFieldMapping.FeeType,
    title: "Fee Type",
  },
  {
    field: BuildingFieldMapping.AmountIssued,
    title: "Issued",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.AmountOutstanding,
    title: "Outstanding",
  },
  {
    field: BuildingFieldMapping.CostCode,
    title: "Cost Code",
  },
];
