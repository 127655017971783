import { FileStreamEntry } from "@app/core/documents/model";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import { encode } from "base64-arraybuffer";
import { isNil } from "lodash";

export const readFileAsDataURL = async (file: File) => {
  return await new Promise((resolve) => {
    let fileReader: any = new FileReader();
    fileReader.onloadend = async () => resolve(encode(await fileReader.result));
    fileReader.readAsArrayBuffer(file);
  });
};

export const convertToFileStreamEntry = async (fileUpload: UploadFileInfo) => {
  let fileStreamEntry: FileStreamEntry = {};
  if (fileUpload?.getRawFile) {
    const fileRaw = fileUpload?.getRawFile();
    fileStreamEntry.FileName = fileRaw?.name;
    fileStreamEntry.ContentType = fileRaw?.type;
    fileStreamEntry.FileSize = fileRaw?.size;
    const base64 = await readFileAsDataURL(fileRaw);
    fileStreamEntry.Base64FileAttachment =
      typeof base64 === "string" ? base64 : undefined;
    fileStreamEntry.FileAttachment =
      typeof base64 === "string" ? base64 : undefined;
    return fileStreamEntry;
  }
};

export const checkIsValidFile = (files?: UploadFileInfo[]) => {
  return files?.some((item: UploadFileInfo) => isNil(item.validationErrors));
};
