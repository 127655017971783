export const assetsRouteStateGlobalSettings = [
  {
    name: "Assets_AssetIntegrationSettings",
    component: require("./forms/asset-integration-settings").default,
  },
  {
    name: "Assets_WorkOrderIntegrationSettings",
    component: require("./forms/work-order-integration-settings").default,
  },
  {
    name: "Assets_SiteSelection",
    component: require("./forms/site-selection").default,
  },
];
