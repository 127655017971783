import { KennelAnimalFormElement } from "@app/products/animals/kennels/[id]/component/child-screens/general/form-element/_index";

import {
  AnimalsKennel,
  Svc_AnimalKennel,
} from "@app/products/animals/kennels/[id]/model";
import { useAnimalsKennelStore } from "@app/products/animals/kennels/[id]/store";
import { animalFormValidator } from "@app/products/animals/util";
import { TypeSubmitActions } from "@common/constants/enumerations";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef } from "react";

export const AnimalsKennelForm = observer(() => {
  const { animalsKennel, saveAnimalsKennel, isFormModified, setOnSubmit } =
    useAnimalsKennelStore();
  const { pushNotification } = useCCAppNotificationStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const action = event.event?.currentTarget.name as TypeSubmitActions;
    const { values, isModified, isValid } = event;
    if (!(action in TypeSubmitActions)) return;
    if (!isValid) return;
    if (
      !isModified &&
      !isFormModified &&
      [TypeSubmitActions.Save, TypeSubmitActions.New].includes(action)
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    const animalKennel = values?.Kennel as AnimalsKennel;
    if (isNil(animalKennel?.Owner?.Contact)) {
      delete animalKennel.Owner;
      saveAnimalsKennel(
        { ...animalKennel, Owner_RID: null } as AnimalsKennel,
        action
      );
    } else if (isNil(animalKennel?.AdditonalHolder?.Contact)) {
      delete animalKennel.AdditonalHolder;
      saveAnimalsKennel(
        { ...animalKennel, AdditonalHolder_RID: null } as AnimalsKennel,
        action
      );
    } else {
      saveAnimalsKennel(animalKennel as AnimalsKennel, action);
    }
  };

  const initialValues: Svc_AnimalKennel = useMemo(() => {
    return { Kennel: animalsKennel } as Svc_AnimalKennel;
  }, [animalsKennel]);

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, animalsKennel]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        validator={animalFormValidator}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <KennelAnimalFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
