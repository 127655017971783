import { IColumnFields } from "@components/cc-grid/model";
import { CCBooleanFilter } from "@components/cc-product-list-view/components/list-view-filter-bar/components/cc-boolean-filter/_index";
import { CCDateFilter } from "@components/cc-product-list-view/components/list-view-filter-bar/components/cc-date-filter/_index";
import { CCEnumFilter } from "@components/cc-product-list-view/components/list-view-filter-bar/components/cc-enum-filter";
import { CCNumericFilter } from "@components/cc-product-list-view/components/list-view-filter-bar/components/cc-numeric-filter/_index";
import { CustomFilter } from "@components/cc-product-list-view/components/list-view-filter-bar/components/custom-filter/_index";
import { ITypesColumn } from "@components/cc-product-list-view/components/list-view-filter-bar/model";
import {
  EnumFilter,
  Operators,
  TextFilter,
} from "@progress/kendo-react-data-tools";
import React from "react";

export const filterConfig = {
  filterState: {
    logic: "and",
    filters: [],
  },
};

export const getColumnDataType = (dataType: string, item: IColumnFields) => {
  if (item?.filterByEnum) {
    return {
      filter: (event: any) => (
        <CCEnumFilter {...event} filterByEnum={item.filterByEnum} />
      ),
      operators: Operators.boolean,
    };
  }

  const typesColumn: ITypesColumn = {
    text: {
      filter: (event: any) => {
        event.filter.value = event.filter.value ?? "";
        return <TextFilter {...event} />;
      },
      operators: Operators.text,
    },
    boolean: {
      // Display the label "yes/no" when a filter with the boolean format in the list views
      filter: (event: any) => <CCBooleanFilter {...event} />,
      operators: Operators.boolean,
    },
    numeric: {
      filter: (event: any) => <CCNumericFilter {...event} item={item} />,
      operators: Operators.numeric,
    },
    enumLookup: {
      filter: CustomFilter,
      operators: Operators.numeric,
    },
    date: {
      filter: (event: any) => <CCDateFilter {...event} item={item} />,
      operators: Operators.date,
    },
    enum: {
      filter: EnumFilter,
      operators: Operators.text,
    },
  };
  if (!dataType) return typesColumn["text"];
  return typesColumn[dataType];
};
