import { postIssueDocument } from "@app/products/property/components/dialogs/issue-document/api";
import { useDocumentIssuedStore } from "@app/products/property/components/grid/document-issued-grid/store";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useGlobalStore } from "@common/stores/global/store";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";

interface IIssueDocumentDialogProps {
  title?: string;
  onClose: () => void;
  reloadManagePage?: (notification?: IAppNotificationItemAddProps) => void;
}
export const IssueDocumentDialog = observer(
  ({ title, onClose, reloadManagePage }: IIssueDocumentDialogProps) => {
    const { currentUserInfo } = useGlobalStore();
    const { pushNotification } = useCCAppNotificationStore();
    const {
      documentIssueSelectedRow,
      reLoadDocumentsIssued,
      setDocumentIssueSelectedRow,
    } = useDocumentIssuedStore();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const initialValues = {
      DocumentType: documentIssueSelectedRow?.DocumentType ?? "",
      Issued_On: new Date(),
      Issued_By: currentUserInfo?.userDisplayName,
    };

    const handleSubmit = async () => {
      if (!documentIssueSelectedRow?.Component_Document_Id) {
        onClose();
        return;
      }
      setIsLoadingSubmit(true);
      const response = await postIssueDocument(
        documentIssueSelectedRow.Component_Document_Id
      );
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        const successMess = {
          type: "success",
          title:
            response?.data?.Notification ??
            response?.data?.SuccessMessage ??
            "Document was issued successfully.",
        } as IAppNotificationItemAddProps;
        if (reloadManagePage) {
          reloadManagePage(successMess);
        } else {
          reLoadDocumentsIssued();
          pushNotification(successMess);
        }
        setDocumentIssueSelectedRow(undefined);
        onClose();
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title:
            response?.data?.ErrorMessage ??
            response?.error ??
            "Document could not be issued.",
          type: "error",
        });
      }
      setIsLoadingSubmit(false);
    };

    return (
      <Form
        onSubmitClick={handleSubmit}
        initialValues={initialValues}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCDialog
              titleHeader={title ?? "Issue Document"}
              onClose={onClose}
              maxHeight="50%"
              maxWidth="30%"
              bodyElement={
                <FormElement className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-field-group">
                    <div className="cc-field">
                      <label className="cc-label">Type</label>
                      <Field
                        name={"DocumentType"}
                        placeholder={"Type"}
                        component={CCInput}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Issued on</label>
                      <Field
                        name={"Issued_On"}
                        placeholder={"Issued On"}
                        component={CCDatePicker}
                        disabled
                        format={DATE_FORMAT.DATE_CONTROL}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Issued by</label>
                      <Field
                        name={"Issued_By"}
                        placeholder={"Issued By"}
                        component={CCInput}
                        readOnly
                      />
                    </div>
                  </div>
                </FormElement>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    themeColor="primary"
                    iconClass={isLoadingSubmit ? "fas fa-spinner fa-spin" : ""}
                    disabled={isLoadingSubmit}
                    className={"cc-dialog-button"}
                    onClick={formRenderProps.onSubmit}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
