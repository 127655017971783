import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { putUpdateStatDays } from "@app/products/town-planning/ppr/psa-referrals/components/action-bar/button/update-stat-days/api";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const PSARUpdateStatDaysButton = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();

  const handleUpdateStatDays = async () => {
    setIsLoading(true);
    const response = await putUpdateStatDays();
    if (isSuccessResponse(response)) {
      clearErrorNotification();
      pushNotification({
        title: "Updated stat days successfully",
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
    } else {
      pushNotification({
        title: "Updated stat days failed",
        description: response.data.Errors ?? response.statusText,
        type: "error",
      });
    }
    setIsLoading(false);
  };

  return (
    <CCNavButton
      title="Update Stat Days"
      isLoading={isLoading}
      onClick={handleUpdateStatDays}
    />
  );
};
