import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { NumericTextBoxProps } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import React from "react";
import "./_index.scss";
interface ICCCurrencyInputProps {
  maxDecimalPlaces?: number;
  validationMessage?: string | null;
  visited?: boolean;
  customColorNegativeNumber?: boolean;
}

export const CCCurrencyInput = ({
  value = null,
  disabled = false,
  onChange,
  maxDecimalPlaces = 2,
  validationMessage,
  visited,
  customColorNegativeNumber = false,
  className = "",
  ...others
}: ICCCurrencyInputProps & NumericTextBoxProps) => {
  const formatOptions = "c" + maxDecimalPlaces;
  return (
    <>
      <CCNumericTextBox
        value={value}
        className={`cc-currency-input ${className} ${
          customColorNegativeNumber && value && value < 0
            ? "cc-currency-negative-input"
            : ""
        }`}
        placeholder={"$"}
        onChange={onChange}
        disabled={disabled}
        spinners={false}
        format={formatOptions}
        {...others}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
