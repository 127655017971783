import { history } from "@/AppRoutes";
import { isSuccessResponse } from "@common/apis/util";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { putReturnToSender } from "@common/pages/actions/components/action-bar/buttons/return-to-sender/api";
import { actionStore } from "@common/pages/actions/[id]/store";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class ReturnToSenderButtonStore {
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  returnToSender = async (id: number) => {
    this.setIsLoading(true);
    const response = await putReturnToSender(id);
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        actionStore.reLoadAction().then(() => {
          appNotificationStore.clearErrorNotification();
          appNotificationStore.pushNotification({
            onClose: () => {
              history.goBack();
            },
            type: "success",
            title: "Return To Sender successfully",
          });
        });
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Return To Sender failed",
          type: "error",
          description: response.data?.Errors,
        });
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Return To Sender failed",
        type: "error",
      });
    }
    this.setIsLoading(false);
  };
}
export const returnToSenderButtonStore = new ReturnToSenderButtonStore();
const returnToSenderButtonContext = createContext(returnToSenderButtonStore);
export const useReturnToSenderButtonStore = () => {
  return useContext(returnToSenderButtonContext);
};
