import { isEmpty } from "lodash";

export const templateValidator = (values: any) => {
  if (
    !values["SelectedCategory"] ||
    isEmpty(values["SelectedMailMergeDocumentIDs"])
  )
    return "Please complete form!";
  return undefined;
};
