import { DTO_Create_Assessment_LOVs } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NewAssessmentDialogStore {
  private _isLoadingOnDialog: boolean = false;
  private _isLoadingOnDialogCancel: boolean = false;
  private _isLoadingSendBack: boolean = false;
  private _isLoadingAllocate: boolean = false;
  private _isLoadingReallocate: boolean = false;
  private _isLoadingApprove: boolean = false;
  private _isOpenedUrlDialog: boolean = false;
  private _createAssessmentLOVs?: DTO_Create_Assessment_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isOpenedUrlDialog(): boolean {
    return this._isOpenedUrlDialog;
  }
  setIsOpenedUrlDialog = (_isOpenedUrlDialog: boolean) => {
    runInAction(() => {
      this._isOpenedUrlDialog = _isOpenedUrlDialog;
    });
  };

  get isLoadingOnDialogCancel(): boolean {
    return this._isLoadingOnDialogCancel;
  }
  setIsLoadingOnDialogCancel = (isLoadingOnDialogCancel: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialogCancel = isLoadingOnDialogCancel;
    });
  };
  get isLoadingOnDialog(): boolean {
    return this._isLoadingOnDialog;
  }
  setIsLoadingOnDialog = (isLoadingOnDialog: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialog = isLoadingOnDialog;
    });
  };

  get isLoadingSendBack(): boolean {
    return this._isLoadingSendBack;
  }
  setIsLoadingSendBack = (isLoadingSendBack: boolean) => {
    runInAction(() => {
      this._isLoadingSendBack = isLoadingSendBack;
    });
  };

  get isLoadingAllocate(): boolean {
    return this._isLoadingAllocate;
  }
  setIsLoadingAllocate = (isLoadingAllocate: boolean) => {
    runInAction(() => {
      this._isLoadingAllocate = isLoadingAllocate;
    });
  };

  get isLoadingReallocate(): boolean {
    return this._isLoadingReallocate;
  }
  setIsLoadingReallocate = (isLoadingReallocate: boolean) => {
    runInAction(() => {
      this._isLoadingReallocate = isLoadingReallocate;
    });
  };

  get isLoadingApprove(): boolean {
    return this._isLoadingApprove;
  }
  setIsLoadingApprove = (isLoadingApprove: boolean) => {
    runInAction(() => {
      this._isLoadingApprove = isLoadingApprove;
    });
  };

  get createAssessmentLOVs() {
    return this._createAssessmentLOVs;
  }
  setCreateAssessmentLOVs = (
    createAssessmentLOVs?: DTO_Create_Assessment_LOVs
  ) => {
    runInAction(() => {
      this._createAssessmentLOVs = createAssessmentLOVs;
    });
  };
}

const newAssessmentDialogStoreContext = createContext(
  new NewAssessmentDialogStore()
);
export const useNewAssessmentDialogStore = () => {
  return useContext(newAssessmentDialogStoreContext);
};
