import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const postLLPIssueFeeBatch = async (
  listPermitID: number[],
  isAllRecords: boolean = true
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `/api/locallaw/internal/renewals/renewal-issue-fee-batch?isAllRecords=${isAllRecords}`,
      listPermitID
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
