import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { OfficerAndOfficerRegion } from "@app/products/property/components/fields/officer-and-officer-region/_index";
import { nameOfLov } from "@app/products/property/model";
import { EKeysOfStepsModifyPIC } from "@app/products/property/pic/list/components/action-bar/form-steps/modify-pic/model";
import { useNewPICDetailStepStoreContext } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/details/store";
import { removeWrongDateFromAndTo } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/details/util";
import { DTO_PIC_Swine } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewPICDetailFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfGIS = nameOfFactory<DTO_GIS>();
const nameOfSwine = nameOfFactory<DTO_PIC_Swine>();
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isDisabled: false,
      isFromModifyPIC: false,
      isDisabledStatus: false,
    },
  }: IFormStepElement) => {
    //store
    const { lovDetail } = useNewPICDetailStepStoreContext();
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const handleRemoveWrongDatePIDDetailStep = () => {
      const currentGIS =
        valueGetter(`${EKeysOfStepsModifyPIC.PICDetail}.GisReferences`) ?? [];
      const currentSwines =
        valueGetter(`${EKeysOfStepsModifyPIC.PICDetail}.Swines`) ?? [];
      const newGIS = removeWrongDateFromAndTo(
        currentGIS,
        nameOfGIS("Effective_From"),
        nameOfGIS("Effective_To")
      );
      const newSwines = removeWrongDateFromAndTo(
        currentSwines,
        nameOfSwine("Effective_From"),
        nameOfSwine("Effective_To")
      );
      onChange(`${EKeysOfStepsModifyPIC.PICDetail}.GisReferences`, {
        value: newGIS,
      });
      onChange(`${EKeysOfStepsModifyPIC.PICDetail}.Swines`, {
        value: newSwines,
      });
    };

    return (
      <>
        <OfficerAndOfficerRegion
          formRenderProps={formRenderProps}
          nameOf={nameOf}
          isDisabled={options?.isDisabled}
        />
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title='Applicant name' />
              <Field
                name={nameOf("ApplicantName")}
                component={CCInput}
                readOnly={options?.isReadOnly}
                placeholder="Applicant name"
              />
            </div>
            <div className="cc-field">
              <CCLabel title={options?.isFromModifyPIC
                ? "Reason for change"
                : "Reason for creation"} isMandatory />
              <Field
                name={nameOf("ReasonId")}
                component={CCSearchComboBox}
                data={lovDetail?.ReasonForCreate ?? []}
                textField="Name"
                dataItemKey="Code"
                validator={options?.isReadOnly ? undefined : requiredValidator}
                disabled={options?.isReadOnly}
                isUseDefaultOnchange
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Reference"></CCLabel>
              <Field
                name={nameOf("Reference")}
                readOnly={options?.isReadOnly}
                component={CCInput}
                placeholder="Reference"
              />
            </div>
            {options?.isFromModifyPIC ? (
              <div className="cc-field">
                <CCLabel
                  title="Status"
                  isMandatory={options?.isFromModifyPIC}
                />
                <Field
                  name={nameOf("PICStatus")}
                  component={CCSearchComboBox}
                  data={lovDetail?.PICStatus ?? []}
                  textField="Name"
                  dataItemKey="Code"
                  disabled={options?.isReadOnly || options?.isDisabledStatus}
                  validator={
                    options?.isReadOnly ? undefined : requiredValidator
                  }
                  value={getDropdownValue(
                    getFieldValue("PICStatus"),
                    lovDetail?.PICStatus ?? [],
                    nameOfLov("Code")
                  )}
                  onChange={(event: DropDownListChangeEvent) => {
                    onChange(nameOf("PICStatus"), {
                      value: event.value?.Code ?? null,
                    });
                    //Logic belong to Modify PIC
                    if (
                      options?.isFromModifyPIC &&
                      event.value?.Code === 1 //Inactive
                    ) {
                      handleRemoveWrongDatePIDDetailStep();
                    }
                  }}
                />
              </div>
            ) : (
                <CCValueField
                  label="Status"
                  value={getFieldValue("PICStatusName") ?? ""}
                />
              )}
          </div>
        </section>
      </>
    );
  }
);
