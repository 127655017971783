import { getContactRoleAccordion } from "@app/core/contacts/_id/components/child-screens/contact-roles/api";
import { ContactRoles } from "@app/core/contacts/_id/components/child-screens/contact-roles/_index";
import { loadAssociations } from "@app/products/property/components/associations/api";
import { DTO_AssociatedItem_Address } from "@app/products/property/components/associations/components/address/model";
import { AssociationsAddress } from "@app/products/property/components/associations/components/address/_index";
import { DTO_AssociatedItem_Arrangement } from "@app/products/property/components/associations/components/arrangement/model";
import { AssociationsArrangement } from "@app/products/property/components/associations/components/arrangement/_index";
import { ContactAssociationAssessmentRebateEntitlement } from "@app/products/property/components/associations/components/assessment-rebate-entitlement/contact-assessment-rebate/_index";
import { DTO_AssociatedItem_AssessmentRebateEntitlement } from "@app/products/property/components/associations/components/assessment-rebate-entitlement/model";
import { AssociationsAssessmentRebateEntitlement } from "@app/products/property/components/associations/components/assessment-rebate-entitlement/standard-assessment-rebate/_index";
import { ContactAssociationAssessment } from "@app/products/property/components/associations/components/assessment/contact-assessment/_index";
import { DTO_AssociatedItem_Assessment } from "@app/products/property/components/associations/components/assessment/model";
import { AssociationsAssessment } from "@app/products/property/components/associations/components/assessment/standard-assessment/_index";
import { DTO_AssociatedItem_AssociatedName } from "@app/products/property/components/associations/components/associated-name/model";
import { AssociationsAssociatedName } from "@app/products/property/components/associations/components/associated-name/_index";
import { DTO_AssociatedItem_Certificate } from "@app/products/property/components/associations/components/certificate/model";
import { AssociationsCertificate } from "@app/products/property/components/associations/components/certificate/_index";
import { DTO_AssociatedItem_ChargeRun } from "@app/products/property/components/associations/components/charge-run/model";
import { AssociationsChargeRun } from "@app/products/property/components/associations/components/charge-run/_index";
import { DTO_AssociatedItem_Compliance } from "@app/products/property/components/associations/components/compliance/model";
import { AssociationsCompliance } from "@app/products/property/components/associations/components/compliance/_index";
import { DTO_AssociatedItem_DebtRecoveryExemption } from "@app/products/property/components/associations/components/debt-recovery-exemption/model";
import { AssociationsDebtRecoveryExemption } from "@app/products/property/components/associations/components/debt-recovery-exemption/_index";
import { DTO_AssociatedItem_DebtRecovery } from "@app/products/property/components/associations/components/debt-recovery/model";
import { AssociationsDebtRecovery } from "@app/products/property/components/associations/components/debt-recovery/_index";
import { DTO_AssociatedItem_DeferredDuty } from "@app/products/property/components/associations/components/deferred-duty/model";
import { AssociationsDeferredDuty } from "@app/products/property/components/associations/components/deferred-duty/_index";
import { DTO_AssociatedItem_Generic } from "@app/products/property/components/associations/components/entity-name-and-address/model";
import { AssociationsEntityNameAndAddress } from "@app/products/property/components/associations/components/entity-name-and-address/_index";
import { DTO_AssociatedItem_MasterProperty } from "@app/products/property/components/associations/components/master-property/model";
import { AssociationsMasterProperty } from "@app/products/property/components/associations/components/master-property/_index";
import { DTO_AssociatedItem_Meter } from "@app/products/property/components/associations/components/meter/model";
import { AssociationsMeter } from "@app/products/property/components/associations/components/meter/_index";
import { ContactAssociationParcel } from "@app/products/property/components/associations/components/parcel/contact-parcel/_index";
import { DTO_AssociatedItem_Parcel } from "@app/products/property/components/associations/components/parcel/model";
import { AssociationsParcel } from "@app/products/property/components/associations/components/parcel/standard-parcel/_index";
import { DTO_AssociatedItem_PICChargeRun } from "@app/products/property/components/associations/components/pic-charge-run/model";
import { AssociationsPICChargeRun } from "@app/products/property/components/associations/components/pic-charge-run/_index";
import { DTO_AssociatedItem_PICs } from "@app/products/property/components/associations/components/pics/model";
import { AssociationsPICS } from "@app/products/property/components/associations/components/pics/_index";
import { DTO_AssociatedItem_RegisterAccount } from "@app/products/property/components/associations/components/register-account/model";
import { AssociationsRegisterAccount } from "@app/products/property/components/associations/components/register-account/_index";
import { DTO_AssociatedItem_SchemeAccount } from "@app/products/property/components/associations/components/scheme-account/model";
import { AssociationsSchemeAccount } from "@app/products/property/components/associations/components/scheme-account/_index";
import { DTO_AssociatedItem_Sessions } from "@app/products/property/components/associations/components/sessions/model";
import { AssociationsSessions } from "@app/products/property/components/associations/components/sessions/_index";
import { DTO_AssociatedItem_Supplementary } from "@app/products/property/components/associations/components/supplementary/model";
import { AssociationsSupplementary } from "@app/products/property/components/associations/components/supplementary/_index";
import { ContactAssociationTitle } from "@app/products/property/components/associations/components/title/contact-title/_index";
import { DTO_AssociatedItem_Title } from "@app/products/property/components/associations/components/title/model";
import { AssociationsTitle } from "@app/products/property/components/associations/components/title/standard-title/_index";
import {
  DTO_ComponentAssociationItemGroup,
  eComponent,
} from "@app/products/property/components/associations/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { Label } from "@common/stores/products/config";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

interface IPropertyAssociationProps {
  componentNumber: eComponent;
  id?: number | string;
  isNoControl?: boolean;
  inActive?: boolean;
  //TODO: Remove after enhanced load failed for child grid
  isShowEmptyWhenError?: boolean;
  narID?: number | null;
}

export const PropertyAssociations = React.forwardRef(
  (
    {
      componentNumber,
      id,
      isNoControl = false,
      inActive = false,
      isShowEmptyWhenError = false,
      narID = 0,
    }: IPropertyAssociationProps,
    ref
  ) => {
    const isContactAssociations = componentNumber === eComponent.Entity;
    const [isLoading, setIsLoading] = useState(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);
    const [selectedFilterValue, setSelectedFilterValue] = useState<any>({
      inActive,
      secondaryAssociations: false,
    });
    const [associationComponents, setAssociationComponents] = useState<
      IListPanelBar[]
    >([]);

    const isShowContactRole = useMemo(() => {
      return (
        componentNumber === eComponent.Entity ||
        componentNumber === eComponent.CORE_Contact
      );
    }, [componentNumber]);

    let components: any[] = [];

    //Get labels
    const [assessmentLabel, titleLabel] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Assessment,
      ECustomColNameProperty.Title,
    ]);

    const handleAssociationComponents = (
      item: DTO_ComponentAssociationItemGroup
    ) => {
      if (item.Associations) {
        switch (item.Group_Value) {
          case eComponent.Assessment:
            if (isContactAssociations) {
              components.push({
                title: assessmentLabel,
                totalRecord: item.Associations.length,
                id: `cc-accordion-title-${ECustomColNameProperty.Assessment}`,
                component: (
                  <ContactAssociationAssessment
                    data={item.Associations as DTO_AssociatedItem_Assessment[]}
                  />
                ),
              });
            } else {
              components.push({
                title: assessmentLabel,
                totalRecord: item.Associations.length,
                id: `cc-accordion-title-${ECustomColNameProperty.Assessment}`,
                component: (
                  <AssociationsAssessment
                    associationsAssessmentInfo={
                      item.Associations as DTO_AssociatedItem_Assessment[]
                    }
                  />
                ),
              });
            }
            break;
          case eComponent.Address:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsAddress
                  associationsAddressInfo={
                    item.Associations as DTO_AssociatedItem_Address[]
                  }
                />
              ),
            });
            break;
          case eComponent.Associated_Name:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsAssociatedName
                  associationsAssociatedNameInfo={
                    item.Associations as DTO_AssociatedItem_AssociatedName[]
                  }
                />
              ),
            });
            break;
          case eComponent.Title:
            if (isContactAssociations) {
              components.push({
                title: titleLabel,
                totalRecord: item.Associations.length,
                id: `cc-accordion-title-${ECustomColNameProperty.Title}`,
                component: (
                  <ContactAssociationTitle
                    data={item.Associations as DTO_AssociatedItem_Title[]}
                  />
                ),
              });
            } else {
              components.push({
                title: titleLabel,
                totalRecord: item.Associations.length,
                id: `cc-accordion-title-${ECustomColNameProperty.Title}`,
                component: (
                  <AssociationsTitle
                    associationsTitleInfo={
                      item.Associations as DTO_AssociatedItem_Title[]
                    }
                  />
                ),
              });
            }
            break;
          case eComponent.Lease:
          case eComponent.Entity:
          case eComponent.Contact_Name_and_Address:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsEntityNameAndAddress
                  associationsEntityNameAndAddressInfo={
                    item.Associations as DTO_AssociatedItem_Generic[]
                  }
                />
              ),
            });
            break;
          case eComponent.Parcel:
            if (isContactAssociations) {
              components.push({
                title: item.Group,
                totalRecord: item.Associations.length,
                component: (
                  <ContactAssociationParcel
                    data={item.Associations as DTO_AssociatedItem_Parcel[]}
                  />
                ),
              });
            } else {
              components.push({
                title: item.Group,
                totalRecord: item.Associations.length,
                component: (
                  <AssociationsParcel
                    associationsParcelInfo={
                      item.Associations as DTO_AssociatedItem_Parcel[]
                    }
                  />
                ),
              });
            }
            break;
          case eComponent.Certificate:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsCertificate
                  associationsCertificateInfo={
                    item.Associations as DTO_AssociatedItem_Certificate[]
                  }
                />
              ),
            });
            break;
          case eComponent.Master_Property:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsMasterProperty
                  associationsMasterPropertyInfo={
                    item.Associations as DTO_AssociatedItem_MasterProperty[]
                  }
                />
              ),
            });
            break;
          case eComponent.Meter:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsMeter
                  associationsMeterInfo={
                    item.Associations as DTO_AssociatedItem_Meter[]
                  }
                />
              ),
            });
            break;
          case eComponent.Register_Account:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsRegisterAccount
                  associationsRegisterAccountInfo={
                    item.Associations as DTO_AssociatedItem_RegisterAccount[]
                  }
                />
              ),
            });
            break;
          case eComponent.Scheme_Account:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsSchemeAccount
                  associationsSchemeAccountInfo={
                    item.Associations as DTO_AssociatedItem_SchemeAccount[]
                  }
                />
              ),
            });
            break;
          case eComponent.Debt_Recovery:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsDebtRecovery
                  associationsDebtRecoveryInfo={
                    item.Associations as DTO_AssociatedItem_DebtRecovery[]
                  }
                />
              ),
            });
            break;
          case eComponent.Debt_Recovery_Exemption:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsDebtRecoveryExemption
                  associationsDebtRecoveryExemptionInfo={
                    item.Associations as DTO_AssociatedItem_DebtRecoveryExemption[]
                  }
                />
              ),
            });
            break;
          case eComponent.Arrangement:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsArrangement
                  associationsArrangementInfo={
                    item.Associations as DTO_AssociatedItem_Arrangement[]
                  }
                />
              ),
            });
            break;
          case eComponent.Assessment_Rebate_Entitlement:
            if (isContactAssociations) {
              components.push({
                title: item.Group,
                totalRecord: item.Associations.length,
                component: (
                  <ContactAssociationAssessmentRebateEntitlement
                    data={
                      item.Associations as DTO_AssociatedItem_AssessmentRebateEntitlement[]
                    }
                  />
                ),
              });
            } else {
              components.push({
                title: item.Group,
                totalRecord: item.Associations.length,
                component: (
                  <AssociationsAssessmentRebateEntitlement
                    associationsAssessmentRebateEntitlementInfo={
                      item.Associations as DTO_AssociatedItem_AssessmentRebateEntitlement[]
                    }
                  />
                ),
              });
            }
            break;
          case eComponent.Compliance:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsCompliance
                  associationsComplianceInfo={
                    item.Associations as DTO_AssociatedItem_Compliance[]
                  }
                />
              ),
            });
            break;
          case eComponent.Deferred_Duty_Account:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsDeferredDuty
                  associationsDeferredDutyInfo={
                    item.Associations as DTO_AssociatedItem_DeferredDuty[]
                  }
                />
              ),
            });
            break;
          case eComponent.Session:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsSessions
                  associationsSessionsInfo={
                    item.Associations as DTO_AssociatedItem_Sessions[]
                  }
                />
              ),
            });
            break;
          case eComponent.Supplementary:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsSupplementary
                  associationsSupplementaryInfo={
                    item.Associations as DTO_AssociatedItem_Supplementary[]
                  }
                />
              ),
            });
            break;
          case eComponent.PIC:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsPICS
                  associationsPICsInfo={
                    item.Associations as DTO_AssociatedItem_PICs[]
                  }
                />
              ),
            });
            break;
          case eComponent.Charge_Run:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsChargeRun
                  associationsChargeRunInfo={
                    item.Associations as DTO_AssociatedItem_ChargeRun[]
                  }
                />
              ),
            });
            break;
          case eComponent.PIC_Charge_Run:
            components.push({
              title: item.Group,
              totalRecord: item.Associations.length,
              component: (
                <AssociationsPICChargeRun
                  associationsPICChargeRunInfo={
                    item.Associations as DTO_AssociatedItem_PICChargeRun[]
                  }
                />
              ),
            });
            break;
        }
      } else {
        components.push({
          title: `${item.Group}`,
          component: <NoData vertical={!isNoControl} />,
        });
      }

      return components;
    };

    const loadData = async () => {
      if (!id) {
        return;
      }
      let responseContactRole;
      let response;
      let errorResponse = undefined;
      setIsLoading(true);
      if (isShowContactRole) {
        responseContactRole = await getContactRoleAccordion(narID);
        if (
          isSuccessResponse(responseContactRole) &&
          responseContactRole?.data
        ) {
          if (responseContactRole.data?.length) {
            components.push({
              title: "Contact Role",
              totalRecord: responseContactRole.data?.length,
              component: (
                <ContactRoles contactRoleData={responseContactRole.data} />
              ),
            });
            setAssociationComponents(components);
          }
        } else {
          errorResponse = {
            status: responseContactRole?.status,
            error: responseContactRole?.error,
          } as APIResponseError;
        }
      }
      if (componentNumber !== eComponent.CORE_Contact) {
        response = await loadAssociations(
          componentNumber,
          id,
          selectedFilterValue.inActive,
          selectedFilterValue.secondaryAssociations
        );
        if (isSuccessResponse(response)) {
          if (
            response?.data?.AssociationsByComponent &&
            response?.data?.AssociationsByComponent.length
          ) {
            response.data.AssociationsByComponent.map((item) =>
              handleAssociationComponents(item)
            );
            setAssociationComponents(components);
          } else {
            setAssociationComponents([]);
          }
        } else {
          errorResponse = {
            status: response?.status,
            error: response?.error,
          } as APIResponseError;
        }
      }
      setResponseLoadError(errorResponse);
      setIsLoading(false);
    };

    useImperativeHandle(ref, () => ({
      reloadData: () => {
        loadData();
      },
    }));

    useEffect(() => {
      loadData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      id,
      componentNumber,
      selectedFilterValue.inActive,
      selectedFilterValue.secondaryAssociations,
    ]);

    if (isLoading && isNoControl) return <Loading isLoading={isLoading} />;

    return (
      <div className="cc-association-panel-container">
        {!isNoControl && (
          <div className="cc-grid-control-left">
            <span className="cc-control-item">Include:</span>
            <Checkbox
              className="cc-control-item"
              title="Inactive"
              label="Inactive"
              checked={selectedFilterValue.inActive}
              onChange={(event: CheckboxChangeEvent) => {
                setSelectedFilterValue({
                  ...selectedFilterValue,
                  inActive: event.value,
                });
              }}
            />
            <Checkbox
              className="cc-control-item"
              title="Secondary Associations"
              label="Secondary associations"
              checked={selectedFilterValue.secondaryAssociations}
              onChange={(event: CheckboxChangeEvent) => {
                setSelectedFilterValue({
                  ...selectedFilterValue,
                  secondaryAssociations: event.value,
                });
              }}
            />
          </div>
        )}
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : responseLoadError && !isShowEmptyWhenError ? (
          <CCLoadFailed
            responseError={responseLoadError}
            onReload={() => {
              setIsLoading(true);
              loadData();
            }}
          />
        ) : associationComponents && associationComponents.length ? (
          <div className={!isNoControl ? "cc-custom-sub-panel-bar" : ""}>
            <CustomPanelBar
              listPanelBar={associationComponents}
              sort={false}
              isShowedTotalRecord
            />
          </div>
        ) : (
          <NoData vertical={!isNoControl} />
        )}
      </div>
    );
  }
);
