import { getViewConfigurations } from "@app/products/property/api";
import { DTO_Session } from "@app/products/property/journals/list/components/dialogs/create-session/model";
import { DTO_Journal_Summary } from "@app/products/property/journals/[id]/model";
import {
  ICCViewConfiguration,
  ResponseMessage,
  ViewConfiguration,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IPropertyAssessmentNoticesAndConfigsColumn =
  | [
      APIResponse<ICCViewConfiguration | undefined>,
      APIResponse<DTO_Journal_Summary[]>
    ]
  | APIResponse<ICCViewConfiguration>
  | APIResponse<DTO_Journal_Summary[]>;

export const loadJournalSummaryByIdsAndConfigsColumn = async (
  journalIds: number[]
): Promise<IPropertyAssessmentNoticesAndConfigsColumn> => {
  try {
    return await Promise.all([
      getViewConfigurations(ViewConfiguration.Journal_SummaryList),
      CoreAPIService.getClient().post<DTO_Journal_Summary[]>(
        `api/property/internal/journal/summaryList`,
        journalIds
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postJournalSummaryByIds = async (
  session: DTO_Session
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/session/create`,
      session
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
