import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const serviceStandardPublishToWeb = async (): Promise<
  APIResponse<IdentityPacket | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get(
      "/api/crms/internal/svc-stda-publish-to-web"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
