import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import {
  optionInputPickerOverlay,
  optionInputPickerZone,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/detail/config";
import { CoreKeyword } from "@common/models/coreKeyword";
import { getStringDisplayFromArrayObject } from "@common/utils/formatting";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isArray } from "lodash";
import React from "react";

interface IDetailFormElement {
  formRenderProps: FormRenderProps;
  notificationRef: any;
}

export const DetailFormElement = ({ formRenderProps }: IDetailFormElement) => {
  const { onChange, valueGetter } = formRenderProps;
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">Zone</label>
          <Field
            key={JSON.stringify(valueGetter("Zone_Display"))}
            name={"Zone_Display"}
            value={valueGetter("Zone_Display")}
            placeholder={"Zone"}
            component={InputPickerSearch}
            nameDisplayMerge="Name"
            onChange={(value: CoreKeyword[]) => {
              if (!value) {
                onChange("ZoneList", { value: [] });
                onChange("Zone_Display", {
                  value: "",
                });
                onChange("Address.PropertyAssessment.Zones", {
                  value: "",
                });
                onChange("Address.PropertyAssessment.ZoneIDs", {
                  value: [],
                });
              } else {
                let newZone = undefined;
                if (!isArray(value)) {
                  newZone = [value];
                } else {
                  newZone = [...value];
                }
                onChange("ZoneList", { value: newZone });
                onChange("Zone_Display", { value: newZone });
                onChange("Address.PropertyAssessment.Zones", {
                  value: getStringDisplayFromArrayObject(newZone, "Name"),
                });
                onChange("Address.PropertyAssessment.ZoneIDs", {
                  value: newZone.map(
                    (keyword: CoreKeyword) => keyword.Keyword_ID
                  ),
                });
              }
            }}
            options={{
              ...optionInputPickerZone,
              zoneIds: valueGetter("Zone_IDs"),
            }}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Overlay</label>
          <Field
            key={JSON.stringify(valueGetter("Overlay_Display"))}
            name={"Overlay_Display"}
            placeholder={"Overlay"}
            component={InputPickerSearch}
            nameDisplayMerge="Name"
            onChange={(value: CoreKeyword[]) => {
              if (!value) {
                onChange("OverlayList", { value: [] });
                onChange("Overlay_Display", {
                  value: "",
                });
                onChange("Address.PropertyAssessment.Overlay", {
                  value: "",
                });
                onChange("Address.PropertyAssessment.OverlayIDs", {
                  value: [],
                });
              } else {
                let newOverlay = undefined;
                if (!isArray(value)) {
                  newOverlay = [value];
                } else {
                  newOverlay = [...value];
                }
                onChange("OverlayList", { value: newOverlay });
                onChange("Overlay_Display", { value: newOverlay });
                onChange("Address.PropertyAssessment.Overlay", {
                  value: getStringDisplayFromArrayObject(newOverlay, "Name"),
                });
                onChange("Address.PropertyAssessment.OverlayIDs", {
                  value: newOverlay.map(
                    (keyword: CoreKeyword) => keyword.Keyword_ID
                  ),
                });
              }
            }}
            options={optionInputPickerOverlay}
          />
        </div>
      </div>
    </section>
  );
};
