import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export interface CommunicationTemplate extends DTOBaseClass_Standard {
  CommunicationTemplate_ID: number;
  CommunicationTemplate_Name: string;
  CommunicationUsage_ENUM: CommunicationUsage | null;
  CommunicationUsage_Name: string;
  CommunicationUsage_ENUM_int: number;
  Dataset_ENUM: MAILMERGEDATASET | null;
  Dataset_Name: string;
  Subject: string;
  SMSBody: string;
  EmailBody: string;
  FaxBody: string;
  ShowInCommunicationDialog: boolean;
  MailMergeDocument_ID: number | null;
}

export interface CommunicationTemplateLOVs {
  CommunicationUsageList?: IKeyValuePacket[];
  Dataset?: IKeyValuePacket[];
  MailMergeDocument?: Svc_MailMergeDocuments[];
  FieldSelections?: Svc_FieldSelections[];
}

export enum CommunicationTemplateSubmitActions {
  Save = "Save",
  New = "New",
}

export interface Svc_MailMergeDocuments {
  ID: number;
  Name: string;
  Document_Usage: string;
  Dataset_Name: string;
  SaveFormat: string;
  WhenLoaded: Date;
  AdditionalInformation: string;
  FileName: string;
}

export interface Svc_FieldSelections {
  Key: string;
  Value: string;
}

export interface ICommunicationTemplateParentSection {
  parentId?: number;
}

export enum CommunicationUsage {
  Default = 0,
  CM_ScheduleAppointmentToClient = 1,
  CM_RescheduleAppointmentToClient = 2,
  CM_CancelAppointmentToClient = 3,
  CM_ScheduleAppointmentToStaff = 4,
  CM_RescheduleAppointmentToStaff = 5,
  CM_CancelAppointmentToStaff = 6,
  CM_CreateDebtorNotificationToARO = 51,
  CM_RefundDebtorOnVoidedLicenceNotificationToARO = 52,
  CM_LicenceCommunicationTest = 99,
  CM_PlaceExpiredSticker1ToStaff = 101,
  CM_RemoveExpiredSticker1ToStaff = 102,
  CM_PlaceExpiredSticker2ToStaff = 103,
  CM_PlaceCancelledStickerToStaff = 151,
  CM_PlaceHeritageAssessmentSticker = 152,
  CM_RequestPhotographyToStaff = 201,
  CM_RequestHeritageAssessmentToStaff = 202,
  CM_DisposeCrematedRemainsAndMonumentsToStaff = 251,
  CM_DisposeMonumentsToStaff = 252,
  CM_BurialIntermentNotificationToStaff = 301,
  CM_CremationOrderNotificationToStaff = 302,
  CM_CrematedRemainsRemovalRetrieveCrematedRemains = 351,
  CM_BookingReceipt = 401,
  CM_BookingConfirmation = 402,
  CM_NotifyStaffBookingFullyPaid = 403,
  CM_ExportTransaction = 451,
  CM_NotifyStaffLaybyConsultationFullyPaid = 452,
  CM_BIONotificationToStaff = 501,
  CM_NotifyStaffMWOFullyPaid = 551,
  CM_CRIO_IntermentScheduled = 661,
  CUSTOMERSERVICE_Event_SendForAction_NotifyActionOfficer = 1001,
  CUSTOMERSERVICE_Event_SendForAction_NotifyCoordinator = 1002,
  CUSTOMERSERVICE_Event_SendForAction_NotifySpecialInterest = 1003,
  CUSTOMERSERVICE_Event_SendForAction_NotifyRequestor_External = 1005,
  CUSTOMERSERVICE_Event_SendForAction_NotifyRiskIssueOfficer = 1006,
  CUSTOMERSERVICE_Event_SendForAction_NotifyRequestor_Councillor = 1007,
  CUSTOMERSERVICE_Event_SendForAction_EmailDesignatedAddresses = 1008,
  CUSTOMERSERVICE_Event_SendForAction_NotifyOnBehalfOf = 1009,
  CUSTOMERSERVICE_Event_SendForAction_WorkflowActions = 1010,
  CUSTOMERSERVICE_Event_EventReschedule_NotifySpecialInterest = 1011,
  CUSTOMERSERVICE_Event_EventReschedule_NotifyRequestor_Councillor = 1012,
  CUSTOMERSERVICE_Event_EventReschedule_NotifyAdhocGroup = 1013,
  CUSTOMERSERVICE_Event_EventReschedule_NotifyRequestor = 1014,
  CUSTOMERSERVICE_Event_EventRecategorised_NotifySpecialInterest = 1021,
  CUSTOMERSERVICE_Event_EventRecategorised_NotifyActionOfficer = 1022,
  CUSTOMERSERVICE_Event_EventRecategorised_NotifyRiskIssueOfficer = 1023,
  CUSTOMERSERVICE_Event_EventRecategorised_NotifyRecordsOfficer = 1024,
  CUSTOMERSERVICE_Event_SaveToRecordsError_NotifyRecordsOfficer = 1025,
  CUSTOMERSERVICE_Event_EventClosure_NotifyActionOfficer = 1031,
  CUSTOMERSERVICE_Event_EventClosure_NotifyCoordinator = 1032,
  CUSTOMERSERVICE_Event_EventClosure_NotifySpecialInterest = 1033,
  CUSTOMERSERVICE_Event_EventClosure_NotifyRequestor_Councillor = 1034,
  CUSTOMERSERVICE_Event_EventClosure_NotifyRequestor = 1035,
  CUSTOMERSERVICE_Event_EventClosure_NotifyRiskIssueOfficer = 1036,
  CUSTOMERSERVICE_Event_EventClosure_EmailDesignatedAddresses = 1037,
  CUSTOMERSERVICE_Event_EventClosure_SendSMSToRequestor = 1038,
  CUSTOMERSERVICE_Event_EventReopened_NotifyActionOfficer = 1041,
  CUSTOMERSERVICE_Event_EventReopened_NotifyCoordinator = 1042,
  CUSTOMERSERVICE_Event_EventReopened_NotifySpecialInterest = 1043,
  CUSTOMERSERVICE_Event_EventReopened_NotifyRiskIssueOfficer = 1044,
  CUSTOMERSERVICE_Event_EventReopenedOnEmail_NotifyActionOfficer = 1045,
  CUSTOMERSERVICE_Event_EventCreated_NotifySpecialInterest = 1051,
  CUSTOMERSERVICE_Event_EventCreated_NotifyCoordinator = 1052,
  CUSTOMERSERVICE_Event_EventCreated_NotifyAfterHoursEmail = 1053,
  CUSTOMERSERVICE_Event_EventCreated_NotifyRequestor_External = 1061,
  CUSTOMERSERVICE_Event_EventCreated_NotifyRequestor_Internal = 1062,
  CUSTOMERSERVICE_Event_OverdueEvent_Alert_1 = 1071,
  CUSTOMERSERVICE_Event_OverdueEvent_Alert_2 = 1072,
  CUSTOMERSERVICE_Event_OverdueEvent_NotifySpecialInterest = 1073,
  CUSTOMERSERVICE_Event_EventReallocated_NotifyActionOfficer = 1081,
  CUSTOMERSERVICE_Event_EventReallocated_NotifyRiskIssueOfficer = 1082,
  CUSTOMERSERVICE_Event_EventLocationAddressChanged_NotifyRecordsOfficer = 1091,
  CUSTOMERSERVICE_Event_MailMessaging_NotifyActionOfficer = 1092,
  CUSTOMERSERVICE_ColdChain_BreachAdvice_DeliveryIssue = 1101,
  CUSTOMERSERVICE_ColdChain_BreachAdvice_ServiceProviderIssue = 1102,
  CUSTOMERSERVICE_Event_ITSupport_EventCreated_NotifyRequestor_Internal = 1151,
  CUSTOMERSERVICE_Event_ITSupport_EventCreated_NotifyCoordinator = 1152,
  CUSTOMERSERVICE_Event_ITSupport_SendForAction_NotifyRequestor_Internal = 1153,
  CUSTOMERSERVICE_Event_Safety_OpenReminder_NotifyActionOfficer_NotDue = 1201,
  CUSTOMERSERVICE_Event_Safety_OverdueReminder_NotifyCoordinator = 1202,
  CUSTOMERSERVICE_Event_Safety_OpenReminder_NotifyActionOfficer_Overdue = 1203,
  CUSTOMERSERVICE_KB_SendForApproval_EmailAdministrator = 1251,
  CUSTOMERSERVICE_PhoneMessage_SendMessage = 1261,
  CUSTOMERSERVICE_PhoneMessage_ReturnReceipt = 1262,
  CUSTOMERSERVICE_PhoneMessage_ForwardMessage = 1263,
  CUSTOMERSERVICE_RiskEvent_SendForReview_NotifyResponsibleOfficer = 1300,
  CUSTOMERSERVICE_RiskEvent_SendForFinalisation_NotifyRiskManagement = 1301,
  CUSTOMERSERVICE_Event_SendCommunication = 1999,
  CUSTOMERSERVICE_Event_Rejection_NotifyCoordinator = 2000,
  CRS_Registration_SendCommunication = 2001,
  CRS_Registration_ChangeDetails = 2002,
  CRS_Profile_Change = 2003,
  TP_Application_SendCommunication = 3001,
  Building_Application_SendCommunication = 3002,
  TP_SendRequesterEmailWhenAllocatePlanner = 3003,
  TP_PSASendApprovalRequestEmailToMiinister = 3004,
  TP_PSASendFinalAmendmentEmailToMiinister = 3005,
  TP_SendPublishDocumentNotification = 3006,
  TP_SendFIApprovalNotification = 3007,
  TP_SendFIApprovedNotification = 3008,
  TP_SendReporOrReponsetLetterApprovalNotification = 3009,
  TP_SendPlansToComplyLetterApprovalNotification = 3010,
  TP_PlansToComplyLetterApprovedMaterialAdequateNotification = 3011,
  TP_PlansToComplyLetterApprovedMaterialInAdequateNotification = 3012,
  TP_PlansToComplyLetterNotApprovedMaterialAdequateNotification = 3013,
  TP_PlansToComplyLetterNotApprovedMaterialInAdequateNotification = 3014,
  TP_SendRequesterEmailWhenAllocatePlanner_NC = 3015,
  TP_SendAppealLodgeNotification = 3016,
  TP_PPRSendAppealApprovalNotification = 3017,
  TP_PPRSendAppealApprovedNotification = 3018,
  TP_PPRSendAppealRejectNotification = 3019,
  TP_PPRSendEnquiryLodgeNotification = 3020,
  TP_PPRSendEnquiryApprovalNotification = 3021,
  TP_PPRSendEnquiryApprovedNotification = 3022,
  TP_PPRSendEnquiryRejectNotification = 3023,
  TP_PPRSendLodgeNotification = 3024,
  TP_PPRSendAllocateToVRNotification = 3025,
  TP_PPRSendTeamLeaderNotification = 3026,
  TP_SendReportOrReponsetLetterApprovedNotification = 3027,
  TP_PSARSendReportOrResponseLetterApprovalNotification = 3028,
  TP_PSARSendReportOrReponsetLetterApprovedNotification = 3029,
  TP_PSARSendLodgeNotification = 3030,
  TP_SendPublishDocumentSC = 3031,
  TP_SendPublishDocumentEnquiry = 3032,
  TP_SendPublishDocumentEOT = 3033,
  TP_SendPublishDocumentCertificate = 3034,
  TP_PPRSendAllocateToOfficerNotification = 3035,
  TP_PPRSendAllocateToTeamLeaderNotification = 3036,
  TP_EPlanning_SendEmailApplicantOnLodgement = 3037,
  TP_EPlanning_SendEmailOAApplicantOnLodgement = 3038,
  TP_PNFNotification = 3039,
  TP_SendRequesterEmailWhenAllocatePlanner_SC = 3040,
  TP_SendRequesterEmailWhenAllocatePlanner_EOT = 3041,
  TP_SendRequesterEmailWhenAllocatePlanner_PE = 3042,
  TP_PPRSendApplicantLodgeNotification = 3043,
  TP_PPRSendApplicantEnquiryLodgeNotification = 3044,
  CORE_Appointment_Internal_Draft = 4001,
  CORE_Appointment_Internal_Scheduled = 4002,
  CORE_Appointment_Internal_ReScheduled = 4003,
  CORE_Appointment_Internal_Cancelled = 4004,
  CORE_Appointment_Internal_Completed = 4005,
  CORE_Appointment_External_Scheduled = 4006,
  CORE_Appointment_External_ReScheduled = 4007,
  CORE_Appointment_External_Cancelled = 4008,
  CORE_Appointment_External_Completed = 4009,
  CORE_Appointment_Communication = 4010,
  CORE_CustomCommunicationTemplate = 4011,
  CORE_Action_SendToActionOfficer = 4012,
  CORE_Action_ReturnToSender = 4013,
  CORE_Notice_NotifyApprover = 4014,
  CORE_Notice_NotifyApprovedToOfficer = 4015,
  CORE_Notice_NotifyDeclineToOfficer = 4016,
  CORE_Caution_NotifyApprover = 4017,
  CORE_Caution_NotifyApprovedToOfficer = 4018,
  CORE_Caution_NotifyDeclineToOfficer = 4019,
  CORE_Condition_NotifyApprover = 4020,
  CORE_Condition_NotifyApprovedToOfficer = 4021,
  CORE_Condition_NotifyDeclineToOfficer = 4022,
  CORE_Undertaking_NotifyApprover = 4023,
  CORE_Undertaking_NotifyApprovedToOfficer = 4024,
  CORE_Undertaking_NotifyDeclineToOfficer = 4025,
  CORE_CautionRemoval_NotifyOfficerAndApprover = 4026,
  LAMP_Application_SendCommunication = 7001,
  LAMP_Authorisation_SendCommunication = 7002,
  MCH_Registration_SendCommunication = 8001,
  TM_TrainingRequest_IssueForApproval_Approver1 = 9001,
  TM_TrainingRequest_DeclinedByApprover1_NotifyRequestor = 9002,
  TM_TrainingRequest_DeclinedByApprover1_NotifyCourseAdministrator = 9003,
  TM_TrainingRequest_IssueForApproval_Approver2 = 9004,
  TM_TrainingRequest_DeclinedByApprover2_NotifyRequestor = 9005,
  TM_TrainingRequest_DeclinedByApprover2_NotifyCourseAdministrator = 9006,
  TM_TrainingRequest_ApprovalCompleted_NotifyRequestor = 9007,
  TM_TrainingRequest_AllocationConfirmed_NotifyRequestor = 9008,
  TM_TrainingRequest_AllocationConfirmed_NotifyCourseAdministrator = 9009,
  TM_TrainingRequest_AllocationConfirmed_NotifyOrgManager = 9010,
  TM_TrainingRequest_TrainingCancelled = 9011,
  TM_TrainingRequest_TrainingDeclined = 9012,
  TM_TrainingRequest_ApprovedByApprover1_NotifyCourseAdministrator = 9013,
  TM_TrainingRequest_ApprovedByApprover2_NotifyCourseAdministrator = 9014,
  TM_TrainingRequest_ManagerNotify_SetApprover = 9015,
  TM_TrainingRequest_DeclinedByApprover1_NotifyApprover2 = 9016,
  TM_TrainingRequest_AllocationConfirmationRequired_NotifyRequestor = 9017,
  TM_TrainingRequest_SubmitRequest_Requestedby = 9018,
  TM_TrainingRequest_IssueForApproval_CourseAdministrator = 9019,
  TM_TrainingRequest_ReAllocationConfirmed_NotifyRequestor = 9020,
  TM_TrainingRequest_ReAllocationConfirmed_NotifyCourseAdministrator = 9021,
  TM_TrainingRequest_ReAllocationConfirmed_NotifyOrgManager = 9022,
  TM_TrainingRequest_TrainingCompleteNotify = 9023,
  TM_TrainingRequest_SubmitRequest = 9100,
  Permit_LodgeApplication_EmailResponsibleOfficer = 10001,
  Permit_IssueApplication_EmailOnIssue = 10002,
  LLS_Permit_LodgeApplication_EmailAOApprover = 10003,
  LLS_Permit_LodgeApplication_EmailBIOApprover = 10004,
  LLS_Permit_ApproveApplication_EmailLLSOfficer = 10005,
  LLS_Permit_Issued_NotifyCAOGroup = 10006,
  SSMS_OnChangeStatus_SendEmail = 11001,
  SRU_Compliance_NotifyDivisions = 12001,
  SRU_Application_NewRegistration_ReviewerSubmit_NotifyOfficer = 12011,
  SRU_Application_NewRegistration_OfficerRecommendForApproval_NotifyManager = 12012,
  SRU_Application_NewRegistration_OfficerRecommendForRefusal_NotifyManager = 12013,
  SRU_Application_NewRegistration_ManagerEndorseApproval_NotifyDelegate = 12014,
  SRU_Application_NewRegistration_ManagerEndorseRefusal_NotifyDelegate = 12015,
  SRU_Application_NewRegistration_ManagerSendBack_NotifyOfficer = 12016,
  SRU_Application_NewRegistration_DelegateSendBack_NotifyOfficer = 12017,
  SRU_Application_NewRegistration_OfficerSentBackRecommendForApproval_NotifyManager = 12018,
  SRU_Application_NewRegistration_OfficerSentBackRecommendForRefusal_NotifyManager = 12019,
  SRU_Application_NewRegistration_DelegateProposeRefusal_NotifyOfficer = 12020,
  SRU_Application_NewRegistration_DelegateProposeRefusal_NotifyApplicant = 12021,
  SRU_Application_NewRegistration_ApplicantResponded_NotifyPanelMember = 12022,
  SRU_Application_NewRegistration_PanelRecommendForApproval_NotifyDelegate = 12023,
  SRU_Application_NewRegistration_PanelRecommendForRefusal_NotifyDelegate = 12024,
  SRU_Application_NewRegistration_DelegateApproved_NotifyOfficer = 12025,
  SRU_Application_NewRegistration_DelegateRefused_NotifyOfficer = 12026,
  SRU_Application_NewRegistration_DelegateApproved_NotifyApplicant = 12027,
  SRU_Application_NewRegistration_DelegateRefused_NotifyApplicant = 12028,
  SRU_Application_NewRegistration_Cancelled_NotifyOfficer = 12029,
  SRU_Application_ChangeOfDetails_ReviewerSubmit_NotifyOfficer = 12030,
  SRU_Application_ChangeOfDetails_OfficerApproved_NotifyApplicant = 12031,
  SRU_Application_ChangeOfDetails_OfficerRecommendForApproval_NotifyManager = 12032,
  SRU_Application_ChangeOfDetails_ManagerEndorseForApproval_NotifyDelegate = 12033,
  SRU_Application_ChangeOfDetails_ManagerSendBack_NotifyOfficer = 12034,
  SRU_Application_ChangeOfDetails_DelegateSendBack_NotifyOfficer = 12035,
  SRU_Application_ChangeOfDetails_OfficerSentBackRecommendForApproval_NotifyManager = 12036,
  SRU_Application_ChangeOfDetails_DelegateApproved_NotifyOfficer = 12037,
  SRU_Application_ChangeOfDetails_DelegateApproved_NotifyApplicant = 12038,
  SRU_Application_ChangeOfDetails_OfficerCancelled_NotifyApplicant = 12039,
  SRU_Issue_InitialNotification_ToOrganisation = 12043,
  SRU_Issue_OfficerRecommendForApproval_NotifyManager = 12044,
  SRU_Issue_OfficerRecommendForRefusal_NotifyManager = 12045,
  SRU_Issue_ManagerSendBack_NotifyOfficer = 12046,
  SRU_Issue_Renotification_ToOrganisation = 12047,
  SRU_Issue_ManagerEndorseApproval_NotifyDelegate = 12048,
  SRU_Issue_ManagerEndorseApproval_NotifyOfficer = 12049,
  SRU_Issue_DelegateSendBack_NotifyOfficer = 12050,
  SRU_Issue_DelegateApproved_NotifyOfficer = 12051,
  SRU_Issue_CompletedNotification_ToOrganisation = 12052,
  SRU_Issue_OfficerSentBackRecommendForApproval_NotifyManager = 12053,
  SRU_Organisation_NotificationOfPlannedDate_ToFundedDivisions = 12054,
  SRU_Issue_ReviewerSubmitIssue_NotifyOfficer = 12055,
  SRU_Issue_PortalSubmitIssue_NotifyOfficer = 12056,
  SRU_Issue_PortalWithdrawIssue_NotifyOfficer = 12057,
  SRU_Issue_PortalFIReceived_NotifyOfficer = 12058,
  SRU_Issue_PortalFIRequested_NotifyComplainant = 12059,
  SRU_Application_NewCondition_OfficerRecommendForApproval_NotifyManager = 12060,
  SRU_Application_NewCondition_ManagerEndorseApproval_NotifyDelegate = 12061,
  SRU_Application_NewCondition_ManagerSendBack_NotifyOfficer = 12062,
  SRU_Application_NewCondition_DelegateSendBack_NotifyOfficer = 12063,
  SRU_Application_NewCondition_OfficerSentBackRecommendForApproval_NotifyManager = 12064,
  SRU_Application_NewCondition_DelegateProposeApproval_NotifyOfficer = 12065,
  SRU_Application_NewCondition_DelegateProposeApproval_NotifyOrganisation = 12066,
  SRU_Application_NewCondition_OrganisationResponseNotReceived_NotifyDelegate = 12067,
  SRU_Application_NewCondition_OrganisationResponseSatisfactory_NotifyDelegate = 12068,
  SRU_Application_NewCondition_OrganisationResponseUnsatisfactory_NotifyDelegate = 12069,
  SRU_Application_NewCondition_DelegateApproved_NotifyOfficer = 12070,
  SRU_Application_NewCondition_DelegateCancelled_NotifyOfficer = 12071,
  SRU_Application_NewCondition_DelegateApproved_NotifyOrganisation = 12072,
  SRU_Application_NewCondition_DelegateCancelled_NotifyOrganisation = 12073,
  SRU_Application_NewCondition_Cancelled_NotifyOfficer = 12074,
  SRU_Application_RegistrationRevocation_OfficerRecommendForApproval_NotifyManager = 12080,
  SRU_Application_RegistrationRevocation_ManagerEndorseApproval_NotifyDelegate = 12081,
  SRU_Application_RegistrationRevocation_ManagerSendBack_NotifyOfficer = 12082,
  SRU_Application_RegistrationRevocation_DelegateSendBack_NotifyOfficer = 12083,
  SRU_Application_RegistrationRevocation_OfficerSentBackRecommendForApproval_NotifyManager = 12084,
  SRU_Application_RegistrationRevocation_DelegateProposeApproval_NotifyOfficer = 12085,
  SRU_Application_RegistrationRevocation_DelegateProposeApproval_NotifyOrganisation = 12086,
  SRU_Application_RegistrationRevocation_OrganisationResponseNotReceived_NotifyDelegate = 12087,
  SRU_Application_RegistrationRevocation_OrganisationResponseSatisfactory_NotifyDelegate = 12088,
  SRU_Application_RegistrationRevocation_OrganisationResponseUnsatisfactory_NotifyDelegate = 12089,
  SRU_Application_RegistrationRevocation_DelegateApproved_NotifyOfficer = 12090,
  SRU_Application_RegistrationRevocation_DelegateCancelled_NotifyOfficer = 12091,
  SRU_Application_RegistrationRevocation_DelegateApproved_NotifyOrganisation = 12092,
  SRU_Application_RegistrationRevocation_DelegateCancelled_NotifyOrganisation = 12093,
  SRU_Application_RegistrationRevocation_Cancelled_NotifyOfficer = 12094,
  SRU_Application_PrescribedReportableIncident_FormCompleted_NotifyManager = 12095,
  SRU_Organisation_CertificationSuspended_NotifyDelegate = 12101,
  SRU_Application_EnforcementPlan_OfficerRecommendForApproval_NotifyManager = 12102,
  SRU_Application_EnforcementPlan_ManagerEndorseApproval_NotifyDelegate = 12103,
  SRU_Application_EnforcementPlan_ManagerSendBack_NotifyOfficer = 12104,
  SRU_Application_EnforcementPlan_DelegateSendBack_NotifyOfficer = 12105,
  SRU_Application_EnforcementPlan_OfficerSentBackRecommendForApproval_NotifyManager = 12106,
  SRU_Application_EnforcementPlan_DelegateProposeApproval_NotifyOfficer = 12107,
  SRU_Application_EnforcementPlan_DelegateProposeApproval_NotifyOrganisation = 12108,
  SRU_Application_EnforcementPlan_OrganisationResponseNotReceived_NotifyDelegate = 12109,
  SRU_Application_EnforcementPlan_OrganisationResponseSatisfactory_NotifyDelegate = 12110,
  SRU_Application_EnforcementPlan_OrganisationResponseUnsatisfactory_NotifyDelegate = 12111,
  SRU_Application_EnforcementPlan_DelegateApproved_NotifyOfficer = 12112,
  SRU_Application_EnforcementPlan_DelegateCancelled_NotifyOfficer = 12113,
  SRU_Application_EnforcementPlan_DelegateApproved_NotifyOrganisation = 12114,
  SRU_Application_EnforcementPlan_DelegateCancelled_NotifyOrganisation = 12115,
  SRU_Application_EnforcementPlan_Cancelled_NotifyOfficer = 12116,
  SRU_Application_ChangeOfDetails_SRS_ReviewerSubmit_NotifyOfficer = 12117,
  SRU_Application_ChangeOfDetails_SRS_OfficerApproved_NotifyApplicant = 12118,
  SRU_Application_ChangeOfDetails_SRS_OfficerRecommendForApproval_NotifyManager = 12119,
  SRU_Application_ChangeOfDetails_SRS_ManagerEndorseForApproval_NotifyDelegate = 12120,
  SRU_Application_ChangeOfDetails_SRS_ManagerSendBack_NotifyOfficer = 12121,
  SRU_Application_ChangeOfDetails_SRS_DelegateSendBack_NotifyOfficer = 12122,
  SRU_Application_ChangeOfDetails_SRS_OfficerSentBackRecommendForApproval_NotifyManager = 12123,
  SRU_Application_ChangeOfDetails_SRS_DelegateApproved_NotifyOfficer = 12124,
  SRU_Application_ChangeOfDetails_SRS_DelegateApproved_NotifyApplicant = 12125,
  SRU_Application_ChangeOfDetails_SRS_OfficerCancelled_NotifyApplicant = 12126,
  SRU_Application_ChangeOfDetails_SRS_DelegateRefused_NotifyOfficer = 12127,
  SRU_Application_ChangeOfDetails_SRS_DelegateRefused_NotifyApplicant = 12128,
  SRU_Application_ChangeOfDetails_SRS_ManagerRefused_NotifyOfficer = 12129,
  SRU_Application_ChangeOfDetails_SRS_ManagerRefused_NotifyApplicant = 12130,
  SRU_Application_ReportableIncident_OfficerRecommendForApproval_NotifyManager = 12140,
  SRU_Application_ReportableIncident_ManagerEndorseForApproval_NotifyDelegate = 12141,
  SRU_Application_ReportableIncident_ManagerSendBack_NotifyOfficer = 12142,
  SRU_Application_ReportableIncident_DelegateSendBack_NotifyOfficer = 12143,
  SRU_Application_ReportableIncident_OfficerSentBackRecommendForApproval_NotifyManager = 12144,
  SRU_Application_ReportableIncident_DelegateApproved_NotifyOfficer = 12145,
  HM_Recall_Communication = 12501,
  DWCMS_Application_NewRegistration_ReadyForDecision_NotifyDelegate = 12601,
  DWCMS_Application_NewRegistration_NoticeandFinalise_NotifyOfficer = 12602,
  DWCMS_Application_NewRegistration_ProposedDecisionandSolution_NotifyOfficer = 12603,
  DWCMS_Application_NewRegistration_FinalDetermination_NotifyDelegate = 12604,
  DWCMS_Application_NewRegistration_UnderAssessment_NotifyOfficer = 12605,
  DWCMS_SuspendWorker_NotifyApprover = 12606,
  DWCMS_SuspendWorker_NotifyApprovedToOfficer = 12607,
  DWCMS_SuspendWorker_NotifyDeclineToOfficer = 12608,
  DWCMS_SuspendWorkerRemoval_NotifyOfficerAndApprover = 12609,
  Poisons_PestObservation_SubmittedForReview_NotifyBioSecurityGroup = 12701,
  Poisons_PestAnimalActivity_SubmittedOnCloseFinalise_NotifyAssignedOfficer = 12702,
  Poisons_Indemnity_SentOnFinalise_NotifyCAOGroup = 12703,
  Poisons_Indemnity_SentOnApproveFinalise_NotifyCAOGroup = 12704,
  Poisons_AerialBaiting_SentForApproval_NotifyTeamLeaders = 12705,
  Poisons_AerialBaiting_SentForApproval_NotifyGeneralManager = 12706,
  Poisons_AerialBaiting_SentForApproval_NotifyStrategyCoordinator = 12707,
  Poisons_PoisonRegister_PercentageRule_NotifySupervisorACO = 12708,
  Poisons_PoisonRegister_Unlock_NotifyRegisterOwnerACO = 12709,
}
