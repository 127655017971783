import { ICCRoute } from "@common/constants/ICCRoute";

export const exportsRoute: ICCRoute = {
  path: "exports",
  name: "Exports",
  children: [
    {
      path: "actions",
      name: "Actions",
      component: require("./actions/_index").default,
    },
    {
      path: "comments",
      name: "Comments",
      component: require("./comments/_index").default,
    },
    {
      path: "documents",
      name: "Documents",
      component: require("./documents/_index").default,
    },
    {
      path: "fees",
      name: "Fees",
      component: require("./fees/_index").default,
    },
  ],
};
