import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSServiceStandardsByCategoryBookmark = {
  getBookmarkListViewDisplayName() {
    return `${CRMS_PRODUCT_NAME} - Service Standards`;
  },
  getBookmarkListViewDetail() {
    return `${CRMS_PRODUCT_NAME} - Service Standards - By Category`;
  },
};
