import { ElementDisplayStatus } from "@app/products/crms/[id]/model";
import { FormAction, ViewActionButton } from "@app/products/crms/model";
import { isNil } from "lodash";

/**
 * Checks whether a specific form action has permission based on the provided view action buttons.
 *
 * @param viewActionButton - An array of view action buttons containing form action and display status.
 * @param formAction - The action associated with the form for which permission is checked.
 * @returns A boolean indicating whether the form action has permission.
 *   - `true` if at least one view action button matches the form action and is set as visible.
 *   - `false` if either the view action button or form action is undefined/null.
 */
export const hasPermissionButton = (
  viewActionButton?: ViewActionButton[],
  formAction?: FormAction
) => {
  // Check if either view action buttons or form action is undefined or null
  if (isNil(viewActionButton) || isNil(formAction)) {
    return false;
  }
  // Check if any view action button matches the form action and is set as visible
  return viewActionButton.some(
    (item: ViewActionButton) =>
      item.FormAction === formAction &&
      item?.DisplayStatus === ElementDisplayStatus.Visible
  );
};
