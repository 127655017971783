import {
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";

export interface IListDialog {
  type?: WorkflowTypes;
  data: any;
  props?: any;
  typeList?: TypesActionsWorkflow;
}

export enum WorkflowStatus {
  Saved = 0,
  Parked = 1,
  Incomplete = 2,
  Completed = 3,
  Cancelled = 4,
}

export enum WorkflowApprovalStatus {
  NotApplicable = 0,
  ToBeApproved = 1,
  Approved = 2,
  Rejected = 3,
  AwaitingGisApproval = 4,
}

export enum WorkflowStepApprovalStatus {
  Current = 0,
  Pending = 1,
  Approved = 2,
  Rejected = 3,
}

export enum EGISOption {
  Unknown = 0,
  GISSingleWorkflow = 1,
  GISApprovalWorkflow = 2,
}

export enum TypesActionsWorkflow {
  Incomplete = "Incomplete",
  Cancelled = "Cancelled",
  ToBeApproved = "To Be Approved",
  BeingApproved = "Being Approved",
  Approved = "Approved",
  MyApprovals = "MyApprovals",
  Rejected = "Rejected",
  SpatialListView = "Spatial List View",
  SpatialManagePage = "Spatial Manage Page",
  NewAssessment = "New Assessment",
  ModifyAssessment = "Modify Assessment",
  ModifyParcel = "Modify Parcel",
  NewMasterProperty = "New Master Property",
  NewDebtRecovery = "Create DebtRecovery",
  AddToSupplementaryDialog = "Associate Supplementary",
  NewSupplementaryDialog = "New Supplementary",
  NewAssessmentTransaction = "New Assessment Transaction",
  //TODO:Using when AdvanceSearch integrate API
  AdvanceSearch = "Advance Search",
  ModifyMasterProperty = "Modify Master Property",
  NewJournalDialog = "Create Journal",
  ModifyDebtRecovery = "Modify Debt Recovery",
  CreateDeferredDuty = "Create DeferredDuty",
  ModifyDeferredDuty = "Modify DeferredDuty",
  RaiseDeferredDutyInterest = "Raise DeferredDuty Interest",
  ModifyCompliance = "Modify Compliance",
}

export interface INewProcessWorkflow<T> {
  payload: T;
  actionSuccess: (e: any) => void;
  actionFail?: (e: any) => void;
  customActionFail?: (e: any) => void;
  setLoading: () => void;
  actionClose?: () => void;
  defaultFailedMessage: string;
  modeProcess: WorkflowProcessMode;
}

//Remove when changing all
export interface IProcessWorkflow<T> {
  payload: T;
  actionSuccess: (e: any) => void;
  defaultFailedMessage: string;
  modeProcess: WorkflowProcessMode;
}

export enum ESecondaryWorkflowStatus {
  Completed = "Completed",
  Parked = "Parked",
  ToBeCompleted = "To be Completed",
}

export enum EWorkflowStatusUX {
  //Park
  Incomplete = `${WorkflowStatus.Incomplete}${WorkflowApprovalStatus.NotApplicable}`,
  Save = `${WorkflowStatus.Saved}${WorkflowApprovalStatus.NotApplicable}`,
  Park = `${WorkflowStatus.Parked}${WorkflowApprovalStatus.NotApplicable}`,
  //SentForApproval
  ToBeApproved = `${WorkflowStatus.Completed}${WorkflowApprovalStatus.ToBeApproved}`,
  WaitingGIS = `${WorkflowStatus.Completed}${WorkflowApprovalStatus.AwaitingGisApproval}`,
  //Approved
  Approved = `${WorkflowStatus.Completed}${WorkflowApprovalStatus.Approved}`,
  //Cancel
  Cancel = `${WorkflowStatus.Cancelled}${WorkflowApprovalStatus.NotApplicable}`,
  //Reject
  Rejected = `${WorkflowStatus.Completed}${WorkflowApprovalStatus.Rejected}`,
}
