import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { DTO_AssociatedItem_Assessment } from "@app/products/property/components/associations/components/assessment/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_Assessment>();
export const colContactAssociationAssessment: IColumnFields[] = [
  {
    field: nameOf("AssessmentNumber"),
    title: "Assessment Number",
    linkTo: (dataItem: DTO_AssociatedItem_Assessment) =>
      `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Id}`,
  },
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("PropertyName"),
    title: "Holding Name",
  },
  {
    field: nameOf("BalanceOwing"),
    title: "Balance Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("AssessmentReference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("PropertyName_Address_Locality"),
    title: "Property Address",
  },
  {
    field: nameOf("RatepayerName"),
    title: "Ratepayer",
  },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("BalanceOutstanding"),
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("BeingPostponed"),
    title: "Being Postponed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("AssessmentGroup"),
    title: "Group",
  },
  {
    field: nameOf("ValuationGroup"),
    title: "Valuation Group",
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("LandUses"),
    title: "Land Uses",
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
