import { history } from "@/AppRoutes";
import { ILodgeApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/lodge-application/model";
import { LodgeApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/lodge-application/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import {
  putCreateAndLodgeApplication,
  putLodgeApplication,
} from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/lodge-application/api";
import { useLodgeApplicationStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/lodge-application/store";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import {
  PPRApplicationIdentityPacket,
  PPRSubmitActions,
} from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { APIResponse } from "@common/apis/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useIsNew } from "@common/hooks/useIsNew";
import {
  IAppNotificationItem,
  IAppNotificationItemAddProps,
} from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const LodgeApplicationButton = observer(() => {
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const isNew = useIsNew();
  const { ppr, onSubmit, pprId, setPPRWithLoading } = usePPRStore();
  const {
    isShowDialog,
    setIsShowDialog,
    isLoading,
    setIsLoading,
    newApplication,
  } = useLodgeApplicationStore();
  const isVisible = useMemo(() => {
    return Application_Status.NewApplication === ppr?.Status_ENUM;
  }, [ppr]);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  const handleSubmit = async (data: ILodgeApplicationDialog) => {
    setIsLoading(true);
    let response: APIResponse<PPRApplicationIdentityPacket> | undefined =
      undefined;
    if (isNew && newApplication) {
      const newPPRApplication = cloneDeep(newApplication);
      newPPRApplication.LodgedDate = data.DateLodged;
      response = await putCreateAndLodgeApplication(newPPRApplication);
    } else {
      response = await putLodgeApplication(data.DateLodged, pprId);
    }

    if (isSuccessIdentityPacket(response)) {
      setIsShowDialog(false);
      clearNotifications();
      if (isNew) {
        const notifications: IAppNotificationItemAddProps[] = [
          { title: "Application saved successfully", type: "success" },
        ];
        if (
          response.data?.Notifications &&
          response.data?.Notifications.length > 0
        ) {
          notifications.push({
            autoClose: false,
            title: "Lodge application.",
            type: "warning",
            description: response.data?.Notifications,
          });
        }
        history.replace(`${TOWN_PLANNING_PPR_ROUTE}/${response?.data?.ID}`, {
          notification: notifications,
        });
      } else {
        setPPRWithLoading(response.data.Application);
        pushNotification({
          type: "success",
          title: "Application lodged successfully.",
        });
        if (
          response.data?.Notifications &&
          response.data?.Notifications.length > 0
        ) {
          pushNotification({
            autoClose: false,
            title: "Lodge application.",
            type: "warning",
            description: response.data?.Notifications,
          });
        }
      }
    } else {
      setNotifications([
        {
          id: "1",
          title: "Application could not be lodged.",
          type: "error",
          autoClose: false,
          description: response.data?.Errors ?? response.statusText,
        },
      ]);
    }
    setIsLoading(false);
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Lodge application"
        onClick={onSubmit}
        isLoading={isLoading}
        name={
          isNew
            ? PPRSubmitActions.CreateAndLodgeApplication
            : PPRSubmitActions.LodgeApplication
        }
      />
      {isShowDialog && (
        <LodgeApplicationDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleSubmit}
          notifications={notifications}
          isLoading={isLoading}
        />
      )}
    </>
  ) : null;
});
