import { PERCENTAGE_FORMAT } from "@common/constants/common-format";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { NumericTextBoxProps } from "@progress/kendo-react-inputs";
import React from "react";
import "./_index.scss";

export const CCPercentInput: React.FC<NumericTextBoxProps> = ({
  value = null,
  format = PERCENTAGE_FORMAT.PERCENTAGE,
  onChange,
  min = 0,
  ...others
}) => {
  return (
    <CCNumericTextBox
      value={value}
      className="cc-percent-input"
      onChange={onChange}
      format={format}
      {...others}
      min={min}
    />
  );
};
