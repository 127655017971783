import { CreateRequests } from "@app/products/property/centrelink-customer-confirmation/view-request-file/components/dialogs/create-requests/_index";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const CreateRequestsButton = () => {
  const [isShowCreateRequests, setIsShowCreateRequests] = useState(false);

  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
        privilegesEnum={EPrivilegesWorkflow.Centrelink_Request_Create}
      >
        <CCNavButton
          title="Create requests"
          onClick={() => setIsShowCreateRequests(true)}
        />
      </PrivilegeWrapper>
      {isShowCreateRequests && (
        <CreateRequests
          onClose={() => {
            setIsShowCreateRequests(false);
          }}
        />
      )}
    </>
  );
};
