import { Comments } from "@app/core/comments/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { NoData } from "@components/no-data/NoData";
import React from "react";

interface ICommentsSectionProps {
  id?: number | string;
  recordType: RECORDTYPE;
}
const CommentsSection = ({ id, recordType }: ICommentsSectionProps) => {
  return (
    <div className="cc-contact-comments-section cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Comments</label>
            <Comments id={id} recordType={recordType} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Memos</label>
            <NoData vertical />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsSection;
