import {
  mockAssessment,
  mockMeterAssociation,
} from "@app/products/property/meters/[id]/components/form-steps/installation/components/form-elements/association/mock";
import { sleep } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { SearchField } from "./model";

export const loadSearchAssessment = async (filter: FilterDescriptor) => {
  const assessmentList = mockAssessment.map((assessment: any) => {
    return {
      ...assessment,
      [SearchField.AssessmentNumber]:
        assessment.AssessmentNumber +
        " - " +
        assessment.AccountAttachedPropertyAddress,
      [SearchField.AssessmentId]:
        assessment.AssessmentId +
        " - " +
        assessment.AccountAttachedPropertyAddress,
    };
  });

  await sleep(1000);
  return filterBy([...assessmentList], filter);
};

export const loadMeterAssociationByID = async (id: number) => {
  await sleep(1000);
  return { ...mockMeterAssociation };
};
