import { getViewConfigurations } from "@app/products/property/api";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PROPERTY_JOURNALS_ASSESSMENT_TRANSACTION_ROUTE } from "@app/products/property/journals/not-yet-in-journal/assessment-transactions/constant";
import { colListJournalCashLinkPayments } from "@app/products/property/journals/not-yet-in-journal/cashlink-payments/config";
import { JournalAssessmentCashlinkPaymentsBookmark } from "@app/products/property/journals/not-yet-in-journal/cashlink-payments/util";
import { journalsRoute } from "@app/products/property/journals/route";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { PROPERTY_JOURNALS_CAST_LINK_PAYMENTS_VIEW_URL } from "@app/products/property/journals/not-yet-in-journal/cashlink-payments/constant";
import { VO_Journal_Transaction_NotIn_Journal } from "@app/products/property/journals/not-yet-in-journal/assessment-transactions/model";
import { JournalAssessmentTransactionsDetailTab } from "@app/products/property/journals/not-yet-in-journal/assessment-transactions/components/reference-sidebar/detail";

const nameOf = nameOfFactory<VO_Journal_Transaction_NotIn_Journal>();
export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={journalsRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        isDisabledRecordListView
        linkUrl={PROPERTY_JOURNALS_ASSESSMENT_TRANSACTION_ROUTE}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Journal}
        listViewDetail={
          JournalAssessmentCashlinkPaymentsBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          JournalAssessmentCashlinkPaymentsBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <JournalAssessmentTransactionsDetailTab />,
      },
    ],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [
    processedJournalCashLinkPaymentsCol,
    setProcessedJournalCashLinkPaymentsCol,
  ] = useState<IColumnFields[]>(colListJournalCashLinkPayments);

  const loadData = async () => {
    setIsLoading(true);
    const response = await getViewConfigurations(
      ViewConfiguration.OData_TransactionsNotInJournal
    );
    if (isSuccessResponse(response)) {
      const viewConfig: ICCViewColumn[] | undefined =
        response?.data?.ColumnDefinitions?.Columns;
      if (!viewConfig || viewConfig?.length === 0) {
        setProcessedJournalCashLinkPaymentsCol([]);
        setIsLoading(false);
        return;
      }
      setProcessedJournalCashLinkPaymentsCol(
        processDynamicColumns(colListJournalCashLinkPayments, viewConfig)
      );
    } else {
      setResponseLoadError({
        status: response.status,
        error: response.error ?? "Load failed",
      });
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadData();
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      dataUrl={PROPERTY_JOURNALS_CAST_LINK_PAYMENTS_VIEW_URL}
      columnFields={processedJournalCashLinkPaymentsCol}
      primaryField={nameOf("Transaction_Id")}
    />
  );
};
