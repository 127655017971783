import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter } = formRenderProps;

  const data = useDataSetting(configData, [
    "CorporateSettings_EnableInspectionExportSystem",
    "CorporateSettings_InspectionExportSystem",
    "CorporateSettings_InspectionExportSystemURL",
    "CorporateSettings_InspectionExportSystemEnvironment",
    "CorporateSettings_InspectionExportSystemUserName",
    "CorporateSettings_InspectionExportSystemPassword",
  ]);

  const allowInspectionExport = valueGetter(
    ECorporateSettingsField.CorporateSettings_EnableInspectionExportSystem.toString()
  );

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <SwitchGlobalSettings
            data={data.CorporateSettings_EnableInspectionExportSystem}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />

          {allowInspectionExport && (
            <>
              <CCSearchComboBoxGlobalSettings
                data={data.CorporateSettings_InspectionExportSystem}
                isEditing={isEditing}
              />
              <InputGlobalSettings
                data={data.CorporateSettings_InspectionExportSystemURL}
                isEditing={isEditing}
              />
              <InputGlobalSettings
                data={data.CorporateSettings_InspectionExportSystemEnvironment}
                isEditing={isEditing}
              />
              <InputGlobalSettings
                data={data.CorporateSettings_InspectionExportSystemUserName}
                isEditing={isEditing}
              />
              <InputGlobalSettings
                data={data.CorporateSettings_InspectionExportSystemPassword}
                isEditing={isEditing}
              />
            </>
          )}
        </div>
      </section>
    </>
  );
});
