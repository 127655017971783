import { IViewAttachments } from "@app/core/view/components/dialogs/view-documents/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const nameOfViewAttachments = nameOfFactory<IViewAttachments>();

export const colViewAttachments: IColumnFields[] = [
  {
    field: nameOfViewAttachments("Title"),
    title: "Title",
    locked: true,
  },
  {
    field: nameOfViewAttachments("SortOrder"),
    title: "Sort",
  },
  {
    field: nameOfViewAttachments("RecordedDate"),
    title: "Recorded",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfViewAttachments("RecordedBy"),
    title: "Recorded By",
  },
  {
    field: nameOfViewAttachments("FileName"),
    title: "File Name",
  },
  {
    field: nameOfViewAttachments("FileNumber"),
    title: "File Number",
  },
  {
    field: nameOfViewAttachments("FileFolderNumber"),
    title: "File Folder Number",
  },
];
