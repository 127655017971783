import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSKnowledgeBaseNoServiceBookmark = {
  getBookmarkListViewDisplayName() {
    return `${CRMS_PRODUCT_NAME} - Knowledge Base`;
  },
  getBookmarkListViewDetail() {
    return `${CRMS_PRODUCT_NAME} - Knowledge Base - No Service Standard(s)`;
  },
};
