import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingSystemAdminConditions: IColumnFields[] = [
  {
    field: BuildingFieldMapping.SortIndex,
    title: "Sort",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.Type,
    title: "Type",
  },
  {
    field: BuildingFieldMapping.Title,
    title: "Title",
  },
  {
    field: BuildingFieldMapping.ConditionCode,
    title: "Code",
  },
  {
    field: BuildingFieldMapping.Condition,
    title: "Condition",
  },
];
