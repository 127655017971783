// import { DTO_Certificate_Detail_LOVs } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/certificate-details/model";
import { DTO_Applicant_Detail_LOVs } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/components/element-steps/applicant-details/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ApplicantDetailStepStore {
  private _applicantDetailStepLOVs?: DTO_Applicant_Detail_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get applicantDetailStepLOVs() {
    return toJS(this._applicantDetailStepLOVs);
  }
  setApplicantDetailStepLOVs = (data?: DTO_Applicant_Detail_LOVs) => {
    runInAction(() => {
      this._applicantDetailStepLOVs = data;
    });
  };
}

const applicantDetailStepStoreContext = createContext(
  new ApplicantDetailStepStore()
);
export const useApplicantDetailStepStore = () => {
  return useContext(applicantDetailStepStoreContext);
};
