import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { ICON_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSFees4yoInvoices: IColumnFields[] = [
  {
    field: CRSFieldMapping.InvoiceNumber,
    title: "Invoice Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: CRSFieldMapping.InvoiceSent,
    title: "Sent",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  { field: CRSFieldMapping.PreferredMethod, title: "Preference Method" },
  {
    field: CRSFieldMapping.DebtorNumber,
    title: "Debtor Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.Child_DisplayName, title: "Child Name" },
  {
    field: CRSFieldMapping.YearAttending,
    title: "Year Att",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.CentrePlaced, title: "Placed At" },
  { field: CRSFieldMapping.Parent1Name, title: "Parent Name" },
  { field: CRSFieldMapping.Parent1HomePhone, title: "Phone" },
];
