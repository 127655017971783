import { getRelatedAppealComplete } from "@app/products/town-planning/ppr/components/action-bar/dialogs/undo-appeal-complete/api";
import { IUndoAppealCompleteDialogData } from "@app/products/town-planning/ppr/components/action-bar/dialogs/undo-appeal-complete/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IUndoAppealCompleteDialogProps {
  onClose: () => void;
  onSubmit: (value: IUndoAppealCompleteDialogData) => void;
  isLoading: boolean;
  notifications?: IAppNotificationItem[];
}

const nameOf = nameOfFactory<IUndoAppealCompleteDialogData>();

export const UndoAppealCompleteDialog = ({
  onClose,
  onSubmit,
  isLoading,
  notifications,
}: IUndoAppealCompleteDialogProps) => {
  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    onSubmit(event.values as IUndoAppealCompleteDialogData);
  };

  const { id } = useParams<{ id: string }>();
  const [initialValues, setInitialValues] = useState<IKeyValuePacket[]>([]);
  const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
  let responseLoadError: APIResponseError | undefined = undefined;

  useEffectOnce(() => {
    if (id) {
      setIsLoadingDialog(true);
      loadFormData();
    }
  });

  const loadFormData = () => {
    getRelatedAppealComplete(parseInt(id)).then(
      (response: APIResponse<IKeyValuePacket[] | undefined>) => {
        responseLoadError = response;
        if (isSuccessResponse(response) && response.data) {
          setInitialValues(response.data);
        }
        setIsLoadingDialog(false);
      }
    );
  };

  return (
    <>
      <Loading isLoading={isLoadingDialog} isFullScreen />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadFormData();
          }}
        />
      ) : (
        <Form
          onSubmitClick={handleOnSubmitClick}
          render={(formRenderProps: FormRenderProps) => {
            return (
              <FormElement>
                <CCDialog
                  height="auto"
                  maxWidth="50%"
                  titleHeader={"Appeal Complete - Undo"}
                  onClose={onClose}
                  disabled={isLoading}
                  bodyElement={
                    <div className="cc-form">
                      <CCLocalNotification
                        defaultNotifications={notifications}
                      />
                      <section className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">
                              Appeal
                              <CCTooltip type="validator" />
                            </label>
                            <Field
                              name={nameOf("SelectedAppeal")}
                              component={CCSearchComboBox}
                              textField={"Value"}
                              dataItemKey={"Key"}
                              data={initialValues}
                              validator={requiredValidator}
                            />
                          </div>
                        </div>
                      </section>
                    </div>
                  }
                  footerElement={
                    <div className={"cc-dialog-footer-actions-right"}>
                      <Button className={"cc-dialog-button"} onClick={onClose}>
                        Cancel
                      </Button>
                      <Button
                        className="cc-dialog-button"
                        themeColor="primary"
                        disabled={!formRenderProps.valid}
                        onClick={formRenderProps.onSubmit}
                        iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                      >
                        Save
                      </Button>
                    </div>
                  }
                />
              </FormElement>
            );
          }}
        />
      )}
    </>
  );
};
