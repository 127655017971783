export interface NSWPointSuggestion {
  address: string;
  id: string;
  rank: number;
}

export interface NSWPointServiceSuggestions {
  suggestions: NSWPointSuggestion[];
  HasError: boolean;
  ErrorMessage: string;
}

export enum EAddressValidation {
  None = 0,
  VMAS_2 = 1,
  DataTools = 2,
  DELWP_MapShare = 3,
  NSW_POINT = 4,
}
