import { eventEmitter } from "@/App";
import { postRemoveTransaction } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/action-bar/buttons/remove-transaction/api";
import { REBATE_TRANSACTION_GRID_ID } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/child-screens/general/components/form-element/constant";
import { useRebateClaimStore } from "@app/products/property/assessments/rebates/rebate-claims/[id]/store";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const RemoveTransactionButton = observer(() => {
  const { selectedTransactions, setSelectedTransactions } =
    useRebateClaimStore();
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams<{ id: string }>();

  const handleOnConfirmYes = async () => {
    clearNotifications();
    setIsLoading(true);
    const transactionIds = selectedTransactions?.map(
      (transaction) => transaction?.Transaction_Id
    );
    const response = await postRemoveTransaction(id, transactionIds);
    setIsLoading(false);
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      pushNotification({
        title: response.data?.SuccessMessage,
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData, {
        gridIds: [REBATE_TRANSACTION_GRID_ID],
      });
      setSelectedTransactions([]);
    } else {
      pushNotification({
        title: response.data?.ErrorMessage,
        type: "error",
        autoClose: false,
      });
    }
    setShowDialog(false);
  };

  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Workflow}
        privilegesEnum={EPrivilegesWorkflow.Journal_Remove_Transaction}
      >
        <CCNavButton
          title={"Remove transaction"}
          disabled={!selectedTransactions?.length}
          onClick={() => setShowDialog(true)}
        />
      </PrivilegeWrapper>

      {showDialog && (
        <ConfirmDialog
          title={"Confirmation"}
          subMessage={"Are you sure you wish to remove the transaction?"}
          onClosePopup={() => {
            setShowDialog(false);
          }}
          onAsyncConfirm={handleOnConfirmYes}
          isLoadingYes={isLoading}
        />
      )}
    </>
  );
});
