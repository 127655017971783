export const mockProduceLetter = [
  {
    Condition: "Short Instalment Amount",
    GraceDays: 20,
    MiniumAmount: 500,
    IssueLetter: "Arrangement Letter 2008/09",
    ListIssueLetter: [
      {
        key: 0,
        value: "Arrangement Letter 2008/09",
      },
      {
        key: 1,
        value: "Arrangement Letter 2009/10",
      },
      {
        key: 2,
        value: "Cancellation of Arrangement 2008/09",
      },
      {
        key: 3,
        value: "Cancellation of Arrangement 2009/10",
      },
      {
        key: 4,
        value: "Multiple Payments in Arrears 2008/09",
      },
      {
        key: 5,
        value: "Multiple Payments in Arrears 2009/10",
      },
      {
        key: 6,
        value: "Part Payment in Arrears 2008/09",
      },
      {
        key: 7,
        value: "Part Payment in Arrears 2009/10",
      },
      {
        key: 8,
        value: "Single Payment in Arrears 2008/09",
      },
      {
        key: 9,
        value: "Single Payment in Arrears 2009/10",
      },
    ],
    ChangeStatusTo: "Active",
    ListChangeStatusTo: [
      {
        key: 0,
        value: "Active",
      },
      {
        key: 1,
        value: "Cancelled",
      },
      {
        key: 2,
        value: "Defaulted",
      },
      {
        key: 3,
        value: "Missed Instalment",
      },
      {
        key: 4,
        value: "On final warning",
      },
    ],
  },
  {
    Condition: "One Overdue Instalment",
    GraceDays: 20,
    MiniumAmount: 500,
    IssueLetter: "Arrangement Letter 2008/09",
    ListIssueLetter: [
      {
        key: 0,
        value: "Arrangement Letter 2008/09",
      },
      {
        key: 1,
        value: "Arrangement Letter 2009/10",
      },
      {
        key: 2,
        value: "Cancellation of Arrangement 2008/09",
      },
      {
        key: 3,
        value: "Cancellation of Arrangement 2009/10",
      },
      {
        key: 4,
        value: "Multiple Payments in Arrears 2008/09",
      },
      {
        key: 5,
        value: "Multiple Payments in Arrears 2009/10",
      },
      {
        key: 6,
        value: "Part Payment in Arrears 2008/09",
      },
      {
        key: 7,
        value: "Part Payment in Arrears 2009/10",
      },
      {
        key: 8,
        value: "Single Payment in Arrears 2008/09",
      },
      {
        key: 9,
        value: "Single Payment in Arrears 2009/10",
      },
    ],
    ChangeStatusTo: "Active",
    ListChangeStatusTo: [
      {
        key: 0,
        value: "Active",
      },
      {
        key: 1,
        value: "Cancelled",
      },
      {
        key: 2,
        value: "Defaulted",
      },
      {
        key: 3,
        value: "Missed Instalment",
      },
      {
        key: 4,
        value: "On final warning",
      },
    ],
  },
  {
    Condition: "Two Overdue Instalment ",
    GraceDays: 5,
    MiniumAmount: 500,
    IssueLetter: "Arrangement Letter 2008/09",
    ListIssueLetter: [
      {
        key: 0,
        value: "Arrangement Letter 2008/09",
      },
      {
        key: 1,
        value: "Arrangement Letter 2009/10",
      },
      {
        key: 2,
        value: "Cancellation of Arrangement 2008/09",
      },
      {
        key: 3,
        value: "Cancellation of Arrangement 2009/10",
      },
      {
        key: 4,
        value: "Multiple Payments in Arrears 2008/09",
      },
      {
        key: 5,
        value: "Multiple Payments in Arrears 2009/10",
      },
      {
        key: 6,
        value: "Part Payment in Arrears 2008/09",
      },
      {
        key: 7,
        value: "Part Payment in Arrears 2009/10",
      },
      {
        key: 8,
        value: "Single Payment in Arrears 2008/09",
      },
      {
        key: 9,
        value: "Single Payment in Arrears 2009/10",
      },
    ],
    ChangeStatusTo: "Active",
    ListChangeStatusTo: [
      {
        key: 0,
        value: "Active",
      },
      {
        key: 1,
        value: "Cancelled",
      },
      {
        key: 2,
        value: "Defaulted",
      },
      {
        key: 3,
        value: "Missed Instalment",
      },
      {
        key: 4,
        value: "On final warning",
      },
    ],
  },
  {
    Condition: "> 2 Overdue Instalment",
    GraceDays: 5,
    MiniumAmount: 500,
    IssueLetter: "Arrangement Letter 2008/09",
    ListIssueLetter: [
      {
        key: 0,
        value: "Arrangement Letter 2008/09",
      },
      {
        key: 1,
        value: "Arrangement Letter 2009/10",
      },
      {
        key: 2,
        value: "Cancellation of Arrangement 2008/09",
      },
      {
        key: 3,
        value: "Cancellation of Arrangement 2009/10",
      },
      {
        key: 4,
        value: "Multiple Payments in Arrears 2008/09",
      },
      {
        key: 5,
        value: "Multiple Payments in Arrears 2009/10",
      },
      {
        key: 6,
        value: "Part Payment in Arrears 2008/09",
      },
      {
        key: 7,
        value: "Part Payment in Arrears 2009/10",
      },
      {
        key: 8,
        value: "Single Payment in Arrears 2008/09",
      },
      {
        key: 9,
        value: "Single Payment in Arrears 2009/10",
      },
    ],
    ChangeStatusTo: "Active",
    ListChangeStatusTo: [
      {
        key: 0,
        value: "Active",
      },
      {
        key: 1,
        value: "Cancelled",
      },
      {
        key: 2,
        value: "Defaulted",
      },
      {
        key: 3,
        value: "Missed Instalment",
      },
      {
        key: 4,
        value: "On final warning",
      },
    ],
  },
];
