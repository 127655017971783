import { ENoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/model";

export const listTypeHideLevyBalances = [
  ENoticeType.AMOUNTS_DUE,
  ENoticeType.FINAL_NOTICES,
  ENoticeType.INSTALMENT_NOTICES,
  ENoticeType.LAND_RENT_ANNUAL_INSTALMENT_NOTICES,
  ENoticeType.LAND_RENT_ARREARS_NOTICES,
  ENoticeType.VALUATION_NOTICES,
];

export const listTypeShowAssessmentBalance = [
  ENoticeType.RATE_NOTICE_FROM_CHARGE_RUN,
  ENoticeType.RATE_NOTICE_FROM_CHARGE_RUN_AND_JOURNALS,
];

export const listTypeHideUtilityAgent = [
  ENoticeType.LAND_RENT_ANNUAL_INSTALMENT_NOTICES,
  ENoticeType.LAND_RENT_ARREARS_NOTICES,
  ENoticeType.VALUATION_NOTICES,
];

export const listTypeHideFinancialAreas = [ENoticeType.VALUATION_NOTICES];
