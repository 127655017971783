import { useFINotRequiredButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/fi-not-required/store";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
const withdrawValidatorList = [
  Application_Status.AssessedByPlanner,
  Application_Status.PreliminaryAssessment,
  Application_Status.Validated,
];
export const FiNotRequiredButton = observer(() => {
  const { ppr, onSubmit } = usePPRStore();
  const { isLoading } = useFINotRequiredButtonStore();

  const isVisible = useMemo(() => {
    return withdrawValidatorList.some((item) => item === ppr?.Status_ENUM);
  }, [ppr]);

  return isVisible ? (
    <CCNavButton
      title="Further information not required"
      disabled={isLoading}
      isLoading={isLoading}
      onClick={onSubmit}
      name={PPRSubmitActions.FiNotRequired}
    />
  ) : null;
});
