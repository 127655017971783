import Breadcrumb from "@app/products/property/system-admin/settings/components/breadcrumb/_index";
import { PPRActionBarNavDropdown } from "@app/products/town-planning/ppr/components/action-bar/nav-dropdown/_index";
import { defaultPPRCategoryConfig } from "@app/products/town-planning/ppr/system-admin/settings/_category/config";
import { MODE_SETTING } from "@app/products/town-planning/ppr/system-admin/settings/_category/model";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import PPRSettingManage from "@app/products/town-planning/ppr/system-admin/settings/_id/_index";
import { PPRSettingHistoryTab } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/reference-sidebar/history/_index";
import { ICategoryConfig } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBarStore } from "@components/cc-list-view-action-bar/store";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { EListType } from "@components/cc-product-list-view/components/hooks/useStoreStateListView";
import { useReferenceSideBarStore } from "@components/cc-reference-sidebar/store";
import Loading from "@components/loading/Loading";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./_index.scss";

export default observer(() => {
  const referenceSideBarStore = useReferenceSideBarStore();
  const location = useLocation();
  const history = useHistory();
  const state: any = location.state;

  const actionBarStore = useCCListViewActionBarStore();
  const {
    loadDataSetting,
    responseLoadError,
    dataSetting,
    modeSetting,
    currentBreadcrumb,
    isLoading,
    columnFields,
    primaryKeyTable,
    setModeSetting,
  } = useSettingPPRStore();

  const title = "Town Planning";
  const actionBarLeft = [<PPRActionBarNavDropdown />];
  const actionBarRight = [
    <ActionBarExportIcon />,
    <ActionBarFilterIcon />,
    <ActionBarInfoIcon />,
  ];

  useEffect(() => {
    actionBarStore.setTitle(title);
    actionBarStore.setLeftComponents(actionBarLeft);

    actionBarStore.setRightComponents(actionBarRight);
    referenceSideBarStore.setReferenceComponents([
      {
        title: "History",
        component: <PPRSettingHistoryTab />,
      },
    ]);
    return () => {
      actionBarStore.resetDefault();
      referenceSideBarStore.resetDefault();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state]);

  useEffect(() => {
    const newConfig = processCategoryConfig();
    if (newConfig && newConfig.endPoint !== "") {
      let requestUrl =
        state && state.requestUrl ? state.requestUrl : newConfig.endPoint;
      loadDataSetting(requestUrl);
    } else {
      setModeSetting(MODE_SETTING.SETTINGS_FOLDER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, history.location.state]);

  const processCategoryConfig = () => {
    let categoryConfig: ICategoryConfig | undefined;
    const categoryState = state?.category;
    //TODO: refactor later- handle with case categoryConfig = undefined
    if (!isNil(categoryState)) {
      categoryConfig = {
        name: state?.category,
        endPoint: state?.requestUrl,
        product: state?.product,
      };
    } else {
      categoryConfig = {
        name: defaultPPRCategoryConfig.name,
        endPoint: defaultPPRCategoryConfig.endPoint,
        product: defaultPPRCategoryConfig.product,
      };
    }
    return categoryConfig;
  };

  if (isLoading) return <Loading isLoading isFullScreen />;

  return (
    <div className="cc-system-admin-setting">
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            const newConfig = processCategoryConfig();
            if (newConfig && newConfig.endPoint !== "") {
              let requestUrl =
                state && state.requestUrl
                  ? state.requestUrl
                  : newConfig.endPoint;
              loadDataSetting(requestUrl);
            } else {
              setModeSetting(MODE_SETTING.SETTINGS_FOLDER);
            }
          }}
        />
      ) : (
        <>
          <Breadcrumb
            breadcrumb={currentBreadcrumb}
            isSettingFolder={modeSetting === MODE_SETTING.SETTINGS_FOLDER}
          />
          {modeSetting === MODE_SETTING.SETTINGS_FOLDER ? (
            <CCProductListView
              columnFields={columnFields}
              data={dataSetting}
              primaryField={primaryKeyTable}
              typeList={EListType.Setting}
            />
          ) : modeSetting === MODE_SETTING.SETTINGS_ITEM ? (
            <>
              {dataSetting && (
                <PPRSettingManage
                  isNew={state?.isNew ?? false}
                  data={dataSetting}
                />
              )}
            </>
          ) : null}
        </>
      )}
    </div>
  );
});
