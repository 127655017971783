import { PremisesBatchUpdateLocationState } from "@app/products/hm/components/action-bar/buttons/batch-update/model";
import { HMToolsRoute } from "@app/products/hm/model";
import { useSelectionListener } from "@components/cc-grid/hooks/useSelectionListener";
import { SelectTypeEnum } from "@components/cc-grid/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

export interface IBatchUpdateButton {
  isDisabled?: boolean;
  isVisible?: boolean;
  gridId?: string;
}

export const BatchUpdateButton = observer(
  ({ isDisabled = false, isVisible = true, gridId }: IBatchUpdateButton) => {
    const history = useHistory();

    const { gridSelectedIds } = useCCProductListViewStore();
    const { loader } = useSelectionListener(gridId);

    const handleOnClick = async () => {
      history.push(HMToolsRoute.HM_BATCH_UPDATE, {
        gridSelectedIds,
        isSelectedAll: loader.current.peek.type === SelectTypeEnum.ALL,
      } as PremisesBatchUpdateLocationState);
    };

    if (!isVisible) return null;
    return (
      <CCNavButton
        title="Batch Update"
        disabled={isDisabled || gridSelectedIds.length === 0}
        onClick={handleOnClick}
      />
    );
  }
);
