import { isSuccessResponse } from "@common/apis/util";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { ACTIONSTATUS } from "@common/pages/actions/[id]/model";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { putReopenAction } from "@common/pages/actions/components/action-bar/buttons/reopen-action/api";
import { ReopenActionDialog } from "@common/pages/actions/components/action-bar/dialog/reopen-action/_index";
import { IReOpenAction } from "@common/pages/actions/components/action-bar/dialog/reopen-action/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

interface IReOpenActionButtonProps {
  disabled?: boolean;
}

export const ReOpenActionButton = observer(
  ({ disabled }: IReOpenActionButtonProps) => {
    const { action, reLoadAction } = useActionStore();
    const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { clearNotifications, pushNotification } =
      useCCAppNotificationStore();
    const { id } = useParams<{ id: string }>();

    const handleSubmit = async (dataSubmit: IReOpenAction) => {
      setIsLoading(true);
      const response = await putReopenAction(+id, dataSubmit);
      setIsLoading(false);
      setIsShowDialog(false);
      if (isSuccessResponse(response)) {
        if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
          clearNotifications();
          reLoadAction().then(() => {
            clearNotifications();
            pushNotification({
              type: "success",
              title: "Record reopened successfully",
            });
          });
        } else {
          clearNotifications();
          pushNotification({
            autoClose: false,
            title: "Reopen record failed",
            type: "error",
            description: response.data?.Errors ?? response.statusText,
          });
        }
      } else {
        clearNotifications();
        pushNotification({
          autoClose: false,
          title: "Reopen record failed",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };

    const isVisible = useMemo(() => {
      return (
        action?.ActionType &&
        !(
          action?.ActionType.IsRegulatoryAction &&
          action?.ActionType.IsRegulatoryAction === true
        ) &&
        !(
          action?.ActionType.IsAssessment &&
          action?.ActionType.IsAssessment === true
        ) &&
        (action?.Status_ENUM === ACTIONSTATUS.APPROVED ||
          action?.Status_ENUM === ACTIONSTATUS.COMPLETE) &&
        action?.ActionRequired &&
        !action?.ActionType.Flag_IsMeeting
      );
    }, [action]);

    return isVisible ? (
      <>
        <CCNavButton
          title="Reopen action"
          onClick={() => setIsShowDialog(true)}
          disabled={disabled}
        />
        {isShowDialog && (
          <ReopenActionDialog
            onClosePopup={() => setIsShowDialog(false)}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
        )}
      </>
    ) : null;
  }
);
