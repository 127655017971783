import { IModifyRegisterData } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/modify-register/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

const mockCategory: IKeyValuePacket[] = [
  { Key: 1, Value: "cemetery" },
  { Key: 2, Value: "option1" },
  { Key: 3, Value: "option2" },
  { Key: 4, Value: "option3" },
];

const mockStatus: IKeyValuePacket[] = [
  { Key: 1, Value: "Active" },
  { Key: 2, Value: "Cancelled" },
  { Key: 3, Value: "Historical" },
];

const mockLocality: IKeyValuePacket[] = [
  { Key: 1, Value: "Adams Estate" },
  { Key: 2, Value: "Almurta" },
  { Key: 3, Value: "Anderson" },
  { Key: 4, Value: "Archies Creek" },
  { Key: 5, Value: "Bass" },
  { Key: 6, Value: "Cape Paterson" },
  { Key: 7, Value: "Cape Woolamai" },
  { Key: 8, Value: "Churchill Island" },
  { Key: 9, Value: "Corinella" },
  { Key: 10, Value: "Coronet Bay" },
  { Key: 11, Value: "Cowes" },
  { Key: 12, Value: "Dalyston" },
  { Key: 13, Value: "Glen Alvie" },
  { Key: 14, Value: "Glen Forbes" },
  { Key: 15, Value: "Grantville" },
  { Key: 16, Value: "Harmers Haven" },
  { Key: 17, Value: "Inverloch" },
  { Key: 18, Value: "Jam Jerrup" },
  { Key: 19, Value: "Kernot" },
  { Key: 20, Value: "Kilcunda" },
  { Key: 21, Value: "Kongwak" },
];

export const mockModifyRegister: IModifyRegisterData = {
  DateTime: new Date("26-Aug-2019 11:22 AM"),
  AccountReference: "MGW-28",
  Category: { Key: 1, Value: "cemetery" },
  Status: { Key: 1, Value: "Active" },
  Description: null,
  AutomaticallyUpdatePropertyAddressFromParcels: true,
  PropertyName: null,
  Address: "<Property Address will be updated when account is saved>",
  Locality: null,
  Postcode: null,
  _option: {
    Category: {
      Data: mockCategory,
    },
    Status: {
      Data: mockStatus,
    },
    Locality: {
      Data: mockLocality,
    },
  },
};
