import AssociateMeterDialog from "@app/products/property/meters/list/components/action-bar/dialogs/associate-meter/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const AssociateMeterButton = () => {
  const [showAssociateMeterDialog, setShowAssociateMeterDialog] =
    useState(false);

  return (
    <>
      <CCNavButton
        title="Associate meter"
        onClick={() => setShowAssociateMeterDialog(true)}
      />
      {showAssociateMeterDialog && (
        <AssociateMeterDialog
          onClosePopup={() => {
            setShowAssociateMeterDialog(false);
          }}
          onConfirmYes={() => {
            setShowAssociateMeterDialog(false);
          }}
        />
      )}
    </>
  );
};
