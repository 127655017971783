import { FurtherInfoDetailTab } from "@app/core/further-info/[id]/components/reference-sidebar/detail/_index";
import { FurtherInfoHistoryTab } from "@app/core/further-info/[id]/components/reference-sidebar/history/_index";
import { FURTHER_INFO_ROUTE } from "@app/core/further-info/[id]/constant";
import { PPRActionBarNavDropdown } from "@app/products/town-planning/ppr/components/action-bar/nav-dropdown/_index";
import { colPPRFIRequestAll } from "@app/products/town-planning/ppr/fi-requests/all/config";
import { TOWN_PLANNING_PPR_FI_REQUEST_VIEW_API_URL } from "@app/products/town-planning/ppr/fi-requests/all/constant";
import { TPFurtherInfoRequest } from "@app/products/town-planning/ppr/fi-requests/all/model";
import { FIRequestDeleteButton } from "@app/products/town-planning/ppr/fi-requests/components/action-bar/buttons/delete/_index";
import { pprRoute } from "@app/products/town-planning/ppr/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "./util";
const nameOf = nameOfFactory<TPFurtherInfoRequest>();

export default () => {
  useCCListViewActionBar({
    title: pprRoute.name,
    leftComponents: [<PPRActionBarNavDropdown />],
    centerComponents: [<FIRequestDeleteButton />],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={FURTHER_INFO_ROUTE}
        productType={PRODUCT_TYPE.TownPlanning}
        recordType={RECORDTYPE.TP_PPRApplication}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <FurtherInfoDetailTab /> },
      {
        title: "History",
        component: <FurtherInfoHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colPPRFIRequestAll}
      dataUrl={TOWN_PLANNING_PPR_FI_REQUEST_VIEW_API_URL}
      primaryField={nameOf("ID")}
      state={{
        group: [
          {
            field: nameOf("DueStatus"),
          },
        ],
        sort: [{ field: nameOf("DueStatusSort"), dir: "asc" }],
      }}
    />
  );
};
