import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import React from "react";

export const NameCell = (data: any) => {
  return (
    <TextCell
      value={
        data?.dataItem?.Contact_DisplayName ?? data?.dataItem?.OrgUnit_Hierarchy
      }
    />
  );
};
