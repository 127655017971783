import {
  initIWorkOrder,
  isOverdue,
} from "@app/products/ams/api/data/workorders/workorder-helper";
import { useStores } from "@app/products/ams/api/helpers/use-stores";
import { AmsDateInput } from "@app/products/ams/components/fields-input/ams-date-input/_index";
import { AmsDropDownList } from "@app/products/ams/components/fields-input/ams-drop-down-list/_index";
import { AmsTextInput } from "@app/products/ams/components/fields-input/ams-text-input/_index";
import { AmsRoute } from "@app/products/ams/constants/ams.route";
import {
  IWorkOrderResource,
  IWorkOrderResourceValues,
  IWorkOrderTask,
  IWorkOrderTaskValues,
  IWorkOrderValues,
} from "@app/products/ams/model/workOrder";
import Loading from "@components/loading/Loading";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Grid,
  GridColumn,
  GridDetailRow,
  GridExpandChangeEvent,
} from "@progress/kendo-react-grid";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import SidebarContainerAutoTabPage from "../components/SidebarContainerAutoTabPage";
import { AssetListFragment } from "../components/info/AssetListFragment";
import MapInfoFragment from "../components/info/MapInfoFragment";
import WorkOrderInfoFragment from "../components/info/WorkOrderInfoFragment";

interface WorkOrderProps {
  id?: string;
}

const ManageWorkOrder: React.FC<WorkOrderProps> = ({ id: propsId }) => {
  let location = useLocation();
  let history = useHistory();
  const {
    dataStores: {
      lookupStore: { getLookupItemDescription, getLookupItem, getLookupItems },
      workOrderStore,
    },
  } = useStores();
  const manageasseturl: string = AmsRoute.ASSET_MANAGE_PATH; // "/manageasset/";
  const [workOrder, setWorkOrder] = useState<IWorkOrderValues>(initIWorkOrder);
  const [, setResources] = useState<IWorkOrderResourceValues[]>();

  var id: string = propsId ? propsId : "";

  useEffect(() => {
    if (workOrderStore.workOrderRegistry.size === 0) {
      workOrderStore.loadFakeWorkOrders();
      //expandChange = expandChange.bind(this);
    }

    if (id.length > 0) {
      workOrderStore.loadWorkOrder(id);
      setWorkOrder(workOrderStore.workOrder);
    } else {
      workOrderStore.createWorkOrder();
    }

    return () => {};
  }, [
    workOrderStore.loadWorkOrder,
    workOrderStore.createWorkOrder,
    id,
    workOrderStore.newWorkOrderId,
    workOrderStore.setNewWorkOrderId,
    workOrderStore.isSubmitting,
    workOrderStore,
  ]);

  if (workOrderStore.isLoadingInitial || !workOrder)
    return <Loading isLoading />;
  if (workOrderStore.isSubmitting) return <Loading isLoading />;

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    let editing = { ...workOrder, [name]: value };
    editing.isOverdue = isOverdue(editing);
    setWorkOrder(editing);
  };

  const handleLupInputChange = (event: DropDownListChangeEvent) => {
    let editing = { ...workOrder, [event.target.name!]: event.target.value.id };
    setWorkOrder(editing);
  };

  const handleSubmit = () => {
    if (workOrder.WorkOrderId === 0) {
      let newWorkOrderId = workOrderStore.getNewWorkOrderId();
      let revisedWko: IWorkOrderValues = {
        ...workOrder,
        WorkOrderId: newWorkOrderId,
        WorkOrderNumber: workOrderStore.getNewWorkOrderNumber(newWorkOrderId),
      };
      setWorkOrder(revisedWko);
      workOrderStore.saveNewWorkOrder(revisedWko);
    } else {
      workOrderStore.saveExistingWorkOrder(workOrder);
    }
  };

  const assetsRowRender = (trElement: any, dataItem: any) => {
    const trProps = {
      ...trElement.props,
      onContextMenu: (e: any) => {
        e.preventDefault();
        //handleContextMenuOpen(e, dataItem.dataItem);
      },
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  const tasksRowRender = (trElement: any, dataItem: any) => {
    const trProps = {
      ...trElement.props,
      onContextMenu: (e: any) => {
        e.preventDefault();
      },
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  const resourcesRowRender = (trElement: any, dataItem: any) => {
    const trProps = {
      ...trElement.props,
      onContextMenu: (e: any) => {
        e.preventDefault();
      },
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  const expandChange = (event: GridExpandChangeEvent) => {
    let taskId = event.dataItem.TaskId;
    workOrderStore.setTaskExpanded(taskId, event.value);
    if (!event.value || !event.dataItem.ResourceValues) {
      setResources([]);
      return;
    }
    setResources(event.dataItem.ResourceValues);
  };

  const getTaskValues = (
    taskArray: IWorkOrderTask[]
  ): IWorkOrderTaskValues[] => {
    let retVal = taskArray.map((item: IWorkOrderTask) =>
      Object.assign({}, item)
    );
    retVal.forEach((item: IWorkOrderTaskValues) => {
      if (item.BudgetId)
        item.Budget =
          getLookupItemDescription("BudgetCode", item.BudgetId) ?? "";
      if (item.TaskTypeId)
        item.TaskType =
          getLookupItemDescription("WorkOrderTaskType", item.TaskTypeId) ?? "";
    });
    return retVal;
  };

  const allResourcesCount = (workOrder: IWorkOrderValues): number => {
    let count: number = 0;
    if (workOrder.Tasks && workOrder.Tasks.length > 0) {
      workOrder.Tasks.forEach((task) => {
        if (task.Resources && task.Resources.length > 0) {
          count = count + task.Resources.length;
        }
      });
    }
    return count;
  };

  const getAllResourceValues = (
    workOrder: IWorkOrderValues
  ): IWorkOrderResourceValues[] => {
    let resources: IWorkOrderResourceValues[] = [];
    if (workOrder.Tasks && workOrder.Tasks.length > 0) {
      workOrder.Tasks.forEach((task) => {
        if (task.Resources && task.Resources.length > 0) {
          let resourceValues = task.Resources.map((item: IWorkOrderResource) =>
            Object.assign({}, item)
          );
          resourceValues.forEach((resource: IWorkOrderResourceValues) => {
            resource.TaskId = task.TaskId;
            resource.TaskResourceId =
              task.TaskId.toString() + "/" + resource.ResourceId.toString();
            resource.ResourceType =
              getLookupItemDescription(
                "ResourceType",
                resource.ResourceTypeId
              ) ?? "";
            if (resource.SupplierId)
              resource.Supplier =
                getLookupItemDescription("Supplier", resource.SupplierId) ?? "";
            resources.push(resource);
          });
        }
      });
    }
    return resources;
  };

  return (
    <Fragment>
      <h6 className="pl-2 pt-3 pb-2 d-flex align-items-center">
        <i
          className="fa fa-arrow-circle-left ccBackButton mr-2"
          onClick={() => {
            history.goBack();
          }}
        />
        <span style={{ color: "#aaa" }}>
          {workOrder.WorkOrderId === 0
            ? "Create Work Order "
            : "Manage Work Order "}
        </span>
      </h6>
      <div className="container-fluid pl-4">
        {/* <Loading isLoading={isLoading} /> */}
        <h2>
          <span className="badge badge-pill ">
            {workOrder.WorkOrderNumber}&nbsp;
          </span>
          &nbsp;
          {workOrder.Description}&nbsp;&nbsp;&nbsp;
          {workOrder.PriorityId && workOrder.PriorityId > 0 ? (
            <span className="badge badge-pill badge-warning">
              Priority:{" "}
              {getLookupItemDescription(
                "WorkOrderPriority",
                workOrder.PriorityId
              )}
            </span>
          ) : null}
          &nbsp;
          {workOrder.StatusId && workOrder.StatusId > 0 ? (
            <span className="badge badge-pill badge-success">
              Status:{" "}
              {getLookupItemDescription("WorkOrderStatus", workOrder.StatusId)}
            </span>
          ) : null}
          &nbsp;
          {workOrder.isOverdue ? (
            <span className="badge badge-pill badge-danger">Overdue</span>
          ) : null}
          &nbsp;
        </h2>
        <div
          className="row col-md-12 border-bottom border-top"
          style={{ backgroundColor: "var(--secondary)" }}
        >
          {workOrder.WorkOrderId > 0 ? (
            <div className="col-auto pt-2">
              <label className="label btn-link" onClick={handleSubmit}>
                Save Work Order
              </label>
            </div>
          ) : (
            <div className="col-auto pt-2">
              <label className="label btn-link" onClick={handleSubmit}>
                Save New Work Order
              </label>
            </div>
          )}
          {workOrder.WorkOrderId > 0 ? (
            <div className="col-auto pt-2">
              <label className="label btn-link">Start Work Order</label>
            </div>
          ) : null}
          {workOrder.WorkOrderId > 0 ? (
            <div className="col-auto pt-2">
              <label className="label btn-link">Cancel Work Order</label>
            </div>
          ) : null}
          <div className="col-auto pt-2">
            <label className="label btn-link">Add Asset</label>
          </div>
          <div className="col-auto pt-2">
            <label className="label btn-link">Add Task</label>
          </div>
          {workOrder.WorkOrderId > 0 ? (
            <div className="col-auto pt-2 border-right">
              <label
                className="label btn-link"
                data-toggle="modal"
                data-target="#commentsModal"
              >
                Add Comment
              </label>
            </div>
          ) : null}
          <div className="col-auto pt-2">
            <label className="label text-muted">More Options</label>
          </div>
        </div>
        <br />
        <div className="row">
          <form className="col-md-9 border-right">
            <PanelBar expandMode={"multiple"}>
              {/* ------  General   -------- */}
              <PanelBarItem
                expanded={true}
                title={
                  <div style={{ width: "100%" }}>
                    <h6 style={{ cursor: "pointer" }}>General&nbsp;</h6>
                    <hr style={{ borderColor: "var(--component-color)" }} />
                  </div>
                }
              >
                <div className="form-row">
                  <AmsTextInput
                    name="WorkOrderNumber"
                    label="Work order number "
                    value={workOrder.WorkOrderNumber}
                    isMandatory={true}
                    onChange={handleInputChange}
                  />
                  <AmsDropDownList
                    name="StatusId"
                    label="Status"
                    data={getLookupItems("WorkOrderStatus").items}
                    textField="description"
                    dataItemKey="id"
                    value={getLookupItem("WorkOrderStatus", workOrder.StatusId)}
                    //value={getLookup('WorkOrderStatus')!.items.find((e) => e.id === workOrder.StatusId)}
                    onChange={handleLupInputChange}
                  />
                  <AmsTextInput
                    name="Contact"
                    label="Contact"
                    value={workOrder.Contact ?? ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-row">
                  <AmsTextInput
                    name="Description"
                    label="Description"
                    value={workOrder.Description}
                    isMultiline={true}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-row">
                  <AmsDropDownList
                    name="PriorityId"
                    label="Priority"
                    data={getLookupItems("WorkOrderPriority").items}
                    textField="description"
                    dataItemKey="id"
                    value={getLookupItem(
                      "WorkOrderPriority",
                      workOrder.PriorityId
                    )}
                    //onChange={(event: any) => { setWorkOrder({...workOrder, PriorityId: event.target.value.Key }); }}
                    onChange={handleLupInputChange}
                  />
                  <AmsDropDownList
                    name="DivisionId"
                    label="Division"
                    data={getLookupItems("Division").items}
                    textField="description"
                    dataItemKey="id"
                    value={getLookupItem("Division", workOrder.DivisionId)}
                    onChange={handleLupInputChange}
                  />
                  <AmsDropDownList
                    name="ActioningOfficerId"
                    label="Actioning officer"
                    data={getLookupItems("ActioningOfficer").items}
                    textField="description"
                    dataItemKey="id"
                    value={getLookupItem(
                      "ActioningOfficer",
                      workOrder.ActioningOfficerId
                    )}
                    onChange={handleLupInputChange}
                  />
                </div>
                <div className="form-row">
                  <AmsDropDownList
                    name="MaintenanceTypeId"
                    label="Maintenance type"
                    data={getLookupItems("MaintenanceType").items}
                    textField="description"
                    dataItemKey="id"
                    value={getLookupItem(
                      "MaintenanceType",
                      workOrder.MaintenanceTypeId
                    )}
                    onChange={handleLupInputChange}
                  />
                  <AmsDropDownList
                    name="BudgetId"
                    label="Budget"
                    data={getLookupItems("BudgetCode").items}
                    textField="description"
                    dataItemKey="id"
                    value={getLookupItem("BudgetCode", workOrder.BudgetId)}
                    onChange={handleLupInputChange}
                  />
                  <AmsDropDownList
                    name="ProjectId"
                    label="Project"
                    data={getLookupItems("Project").items}
                    textField="description"
                    dataItemKey="id"
                    value={getLookupItem("Project", workOrder.ProjectId)}
                    onChange={handleLupInputChange}
                  />
                </div>
                <div className="form-row">
                  <AmsTextInput
                    name="SpecialInstructions"
                    label="Special instructions"
                    value={workOrder.SpecialInstructions}
                    onChange={handleInputChange}
                    isMultiline={true}
                  />
                </div>
                <div className="form-row">
                  {/* <OOSwitchInput
                    name="Reportable"
                    label="Reportable"
                    checked={workOrder.Reportable ?? false}
                    onChange={handleInputChange}
                  /> */}
                </div>
              </PanelBarItem>
              <br />
              {/* ------  Dates   -------- */}
              <PanelBarItem
                expanded={true}
                title={
                  <div style={{ width: "100%" }}>
                    <h6 style={{ cursor: "pointer" }}>Dates&nbsp;</h6>
                    <hr style={{ borderColor: "var(--component-color)" }} />
                  </div>
                }
              >
                <div className="form-row">
                  <AmsDateInput
                    name="StatusDate"
                    label="Status date"
                    value={workOrder.StatusDate}
                    onChange={handleInputChange}
                  />
                  <AmsDateInput
                    name="CreatedDate"
                    label="Created date"
                    value={workOrder.CreatedDate}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-row">
                  <AmsDateInput
                    name="ScheduledStartDate"
                    label="Scheduled start date"
                    value={
                      workOrder.ScheduledStartDate ??
                      workOrder.ScheduledStartDate!
                    }
                    onChange={handleInputChange}
                  />
                  <AmsDateInput
                    name="ExpectedStartDate"
                    label="Expected start date"
                    value={
                      workOrder.ExpectedStartDate ??
                      workOrder.ExpectedStartDate!
                    }
                    onChange={handleInputChange}
                  />
                  <AmsDateInput
                    name="ActualStartDate"
                    label="Actual start date"
                    value={
                      workOrder.ActualStartDate ?? workOrder.ActualStartDate!
                    }
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-row">
                  <AmsDateInput
                    name="ScheduledFinishDate"
                    label="Scheduled finish date"
                    value={
                      workOrder.ScheduledFinishDate ??
                      workOrder.ScheduledFinishDate!
                    }
                    onChange={handleInputChange}
                  />
                  <AmsDateInput
                    name="ExpectedFinishDate"
                    label="Expected finish date"
                    value={
                      workOrder.ExpectedFinishDate ??
                      workOrder.ExpectedFinishDate!
                    }
                    onChange={handleInputChange}
                  />
                  <AmsDateInput
                    name="ActualFinishDate"
                    label="Actual finish date"
                    value={
                      workOrder.ActualFinishDate ?? workOrder.ActualFinishDate!
                    }
                    onChange={handleInputChange}
                  />
                </div>
              </PanelBarItem>
              <br />
              {/* ------  Assets   -------- */}
              <PanelBarItem
                title={
                  <div style={{ width: "100%" }}>
                    <h6 style={{ cursor: "pointer" }}>
                      Assets&nbsp;
                      <span className="disabled">
                        ( {workOrder.Assets.length} )
                      </span>
                    </h6>
                    <hr style={{ borderColor: "var(--component-color)" }} />
                  </div>
                }
              >
                <div>
                  {workOrder.Assets.length > 0 ? (
                    <div>
                      <Grid
                        resizable={true}
                        data={workOrder.Assets}
                        rowRender={assetsRowRender}
                      >
                        <GridColumn
                          field="AssetNumber"
                          title="Asset Number"
                          cell={(props: any) => {
                            return (
                              <td>
                                <Link
                                  key={props.dataItem.WorkOrderId}
                                  to={{
                                    pathname: `${manageasseturl}${props.dataItem.AssetId}`,
                                    state: { background: location },
                                  }}
                                >
                                  <div
                                    className="label btn-link text-overflow-ellipsis"
                                    color="var(--accent) !important"
                                  >
                                    {props.dataItem[props.field]}
                                  </div>
                                </Link>
                              </td>
                            );
                          }}
                        />
                        <GridColumn
                          field="AssetDescription"
                          title="Description"
                        />
                        <GridColumn field="Location" title="Location" />
                      </Grid>
                    </div>
                  ) : null}
                </div>
              </PanelBarItem>
              <br />
              {/* ------  Tasks   -------- */}
              <PanelBarItem
                title={
                  <div style={{ width: "100%" }}>
                    <h6 style={{ cursor: "pointer" }}>
                      Tasks&nbsp;
                      <span className="disabled">
                        ( {workOrder.Tasks?.length} )
                      </span>
                    </h6>
                    <hr style={{ borderColor: "var(--component-color)" }} />
                  </div>
                }
              >
                <div>
                  <Grid
                    resizable={true}
                    data={getTaskValues(workOrder.Tasks)}
                    rowRender={tasksRowRender}
                    detail={ResourceComponent}
                    expandField="expanded"
                    onExpandChange={expandChange}
                  >
                    <GridColumn
                      field="TaskId"
                      title="Task Number"
                      width="80px"
                    />
                    <GridColumn
                      field="TaskType"
                      title="Task Type"
                      width="120px"
                    />
                    <GridColumn field="Description" title="Description" />
                    <GridColumn field="Budget" title="Budget" width="150px" />
                    {/* <GridColumn field="TaskEndDate" title="End Date" /> */}
                    <GridColumn
                      field="Cost"
                      title="Cost"
                      format="{0:c}"
                      width="100px"
                    />
                  </Grid>
                </div>
              </PanelBarItem>
              <br />
              {/* ------  Resources   -------- */}
              <PanelBarItem
                title={
                  <div style={{ width: "100%" }}>
                    <h6 style={{ cursor: "pointer" }}>
                      Resources&nbsp;
                      <span className="disabled">
                        ( {allResourcesCount(workOrder)} )
                      </span>
                    </h6>
                    <hr style={{ borderColor: "var(--component-color)" }} />
                  </div>
                }
              >
                <div>
                  <Grid
                    resizable={true}
                    data={getAllResourceValues(workOrder)}
                    rowRender={resourcesRowRender}
                  >
                    <GridColumn
                      field="ResourceType"
                      title="Resource Type"
                      width="100px"
                    />
                    <GridColumn
                      field="TaskResourceId"
                      title="Task/Res Number"
                      width="100px"
                    />
                    {/* <GridColumn field="ResourceId" title="Resource Number" width="100px" /> */}
                    <GridColumn field="Name" title="Resource Name" />
                    <GridColumn field="Description" title="Description" />
                    <GridColumn field="Supplier" title="Supplier" />
                    <GridColumn
                      field="Rate"
                      title="Rate"
                      format="{0:c}"
                      width="80px"
                    />
                    <GridColumn
                      field="Quantity"
                      title="Quantity"
                      width="80px"
                    />
                    <GridColumn
                      field="Cost"
                      title="Cost"
                      format="{0:c}"
                      width="100px"
                    />
                  </Grid>
                </div>
              </PanelBarItem>
              <br />
              {/* ------  Comments   -------- */}
              <PanelBarItem
                title={
                  <div style={{ width: "100%" }}>
                    <h6 style={{ cursor: "pointer" }}>
                      Comments&nbsp;
                      <span className="disabled">( 0 )</span>
                    </h6>
                    <hr style={{ borderColor: "var(--component-color)" }} />
                  </div>
                }
              >
                <div>Comments will go here</div>
              </PanelBarItem>
              <br />
              {/* ------  Documents   -------- */}
              <PanelBarItem
                title={
                  <div style={{ width: "100%" }}>
                    <h6 style={{ cursor: "pointer" }}>
                      Documents&nbsp;
                      <span className="disabled">( 0 )</span>
                    </h6>
                    <hr style={{ borderColor: "var(--component-color)" }} />
                  </div>
                }
              >
                <div>Documents will go here</div>
              </PanelBarItem>
              <br />
            </PanelBar>
            <br />
            <br />
          </form>
          <div className="col-md-3 overflow-auto">
            {workOrder.WorkOrderId > 0 ? (
              <Fragment>
                <SidebarContainerAutoTabPage>
                  <WorkOrderInfoFragment workOrderInfo={workOrder} />
                  <MapInfoFragment />
                  <AssetListFragment assets={workOrder.Assets} />
                </SidebarContainerAutoTabPage>
              </Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default observer(ManageWorkOrder);

class ResourceComponent extends GridDetailRow {
  //static contextType = LookupStore;

  unpackLupVals = (
    resourceArray: IWorkOrderResource[]
  ): IWorkOrderResourceValues[] => {
    const lookupStore = this.context;
    let retVal = resourceArray.map((item: IWorkOrderResource) =>
      Object.assign({}, item)
    );
    retVal.forEach((item: IWorkOrderResourceValues) => {
      item.ResourceType =
        lookupStore.getLookupItemDescription(
          "ResourceType",
          item.ResourceTypeId
        ) ?? "";
      item.Supplier =
        lookupStore.getLookupItemDescription("Supplier", item.SupplierId) ?? "";
    });
    return retVal;
  };

  render() {
    const data = this.unpackLupVals(this.props.dataItem.Resources);
    if (data) {
      return (
        <Grid data={data}>
          <GridColumn field="ResourceId" title="Resource Number" width="90px" />
          <GridColumn
            field="ResourceType"
            title="Resource Type"
            width="100px"
          />
          <GridColumn field="Name" title="Resource Name" />
          <GridColumn field="Description" title="Description" />
          <GridColumn field="Supplier" title="Supplier" />
          <GridColumn field="Rate" title="Rate" format="{0:c}" width="80px" />
          <GridColumn field="Quantity" title="Quantity" width="80px" />
          <GridColumn field="Cost" title="Cost" format="{0:c}" width="100px" />
        </Grid>
      );
    }
    return (
      <div style={{ height: "50px", width: "100%" }}>
        <div style={{ position: "absolute", width: "100%" }}>
          <div className="k-loading-image" />
        </div>
      </div>
    );
  }
}

ManageWorkOrder.defaultProps = {
  id: "",
};
