import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Svc_CommunicationTemplateTabDetails } from "@common/pages/settings/communication/template/_id/components/reference-sidebar/detail/model";

export const getCommunicationTemplateTabDetailsById = async (
  communicationTemplateId: number
): Promise<APIResponse<Svc_CommunicationTemplateTabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Svc_CommunicationTemplateTabDetails>(
      `/api/core/internal/communicationtemplate/${communicationTemplateId}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
