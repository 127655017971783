import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { EListSubmitButton } from "@app/products/property/model";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

export const ConfirmCloseDialog = observer(() => {
  const {
    isLoadingClose,
    setDataForCloseDialog,
    dataForCloseDialog,
    resetStore,
  } = useConfirmCloseDialogStore();
  /**
   * handle confirmation dialog
   * confirm NO => call CANCEL api
   */
  const handleConfirmNoRetain = () => {
    if (!dataForCloseDialog) return;
    const retainEvent = {
      currentTarget: { id: EListSubmitButton.ConfirmCloseNo },
    };
    const cancelCallback = () => dataForCloseDialog.closeCallback(retainEvent);
    setDataForCloseDialog(undefined);
    return cancelCallback();
  };
  /**
   * handle confirmation dialog
   * confirm YES => call PARK api
   */
  const handleConfirmRetain = () => {
    if (!dataForCloseDialog) return;
    const noRetainEvent = {
      currentTarget: { id: EListSubmitButton.ConfirmCloseYes },
    };
    return dataForCloseDialog.closeCallback(noRetainEvent);
  };

  useEffectOnce(() => {
    return () => resetStore();
  });

  return (
    <>
      {dataForCloseDialog && (
        <ConfirmDialog
          title="Confirmation"
          message="Do you wish to retain this workflow?"
          height="auto"
          btnCancelVisible={false}
          btnYesId={EListSubmitButton.ConfirmCloseYes}
          isLoadingYes={isLoadingClose}
          onConfirmNo={handleConfirmNoRetain}
          onClosePopup={() => setDataForCloseDialog(undefined)}
          onAsyncConfirm={handleConfirmRetain}
        />
      )}
    </>
  );
});
