import { SettingField } from "@app/products/property/system-admin/settings/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { APIResponseStatus } from "@common/constants/response-status";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

export const getSetting = async <T>(
  ProductType: PRODUCT_TYPE,
  ItemId: CORPORATE_SETTINGS_FIELD
): Promise<T | undefined> => {
  try {
    const response = await CoreAPIService.getClient().get<T>(
      `/api/core/internal/setting/${ProductType}/${ItemId}`
    );
    return !response ||
      response.status !== APIResponseStatus.SUCCESS ||
      !response.data
      ? undefined
      : response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const getSettings = async (
  settingEnums: ECorporateSettingsField[]
): Promise<APIResponse<SettingField[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<SettingField[]>(
      `/api/core/internal/setting/core?ids=${settingEnums.toString()}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
