import { AssociatedAssessments } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/components/associated-assessments/_index";
import { RebateSummaryDetail } from "@app/products/property/charge-and-notice-runs/charge-runs/components/detail/components/rebate-summary-detail/_index";
import { colRebateSummary } from "@app/products/property/charge-and-notice-runs/charge-runs/components/detail/config";
import { VO_Charge_Run_Rebate_Summary } from "@app/products/property/charge-and-notice-runs/charge-runs/components/detail/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import "./_index.scss";

import { LeviesSummary } from "@app/products/property/charge-and-notice-runs/charge-runs/components/detail/components/levies-summary/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import { Label } from "@common/stores/products/config";
import { observer } from "mobx-react-lite";

const nameOfRebateSummary = nameOfFactory<VO_Charge_Run_Rebate_Summary>();
export const DetailComponent = observer(({ dataItem }: GridDetailRowProps) => {
  //Get label
  const assessmentsLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessments
  );

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Levy Summary",
      component: <LeviesSummary data={dataItem} />,
    },
    {
      title: "Rebate Summary",
      component: (
        <CCGrid
          className="cc-charge-runs-list-children-grid"
          dataUrl={`/odata/property/internal/chargerunrebateregister/${dataItem?.Charge_Run_Id}?$count=true&`}
          columnFields={colRebateSummary}
          primaryField={nameOfRebateSummary("Rebate_Type_Id")}
          detail={RebateSummaryDetail}
        />
      ),
    },
    {
      title: assessmentsLabel,
      component: <AssociatedAssessments data={dataItem} />,
    },
  ];

  return (
    <div className="cc-list-charge-runs-detail-grid">
      <CustomPanelBar listPanelBar={listPanelBar} sort={false} />
    </div>
  );
});
