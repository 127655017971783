import { loadManualMeterReading } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/manual-reading/api";
import { FormManualReading } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/manual-reading/components/form/_index";
import { IManualMeterReading } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/manual-reading/model";
import { IMeterFormGeneral } from "@app/products/property/meters/[id]/model";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IManualReadingDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  meterInfo: IMeterFormGeneral | undefined;
}

export const ManualReadingDialog = ({
  onClose,
  onSubmit,
  meterInfo,
}: IManualReadingDialogProps) => {
  const [meterInfoExtend, setMeterInfoExtend] = useState<IManualMeterReading>();
  const [isLoading, setIsLoading] = useState(false);

  useEffectOnce(() => {
    setIsLoading(true);
    loadManualMeterReading().then((data) => {
      if (meterInfo?.MeterNumber && meterInfo?.Id) {
        setMeterInfoExtend({
          ...data,
          MeterNumber: meterInfo?.MeterNumber,
          Id: meterInfo?.Id,
        });
        setIsLoading(false);
      }
    });
  });

  return (
    <FormManualReading
      meterInfoExtend={meterInfoExtend}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
      title={"Manual Meter Reading"}
    />
  );
};
