import { CCDocumentsInput } from "@app/core/documents/components/documents-input/_index";
import { IAttachmentFile } from "@app/core/documents/components/documents-input/model";
import { Attachment } from "@app/core/documents/model";
import {
  MAILMERGEDATASET,
  MAILMERGEDOCUMENTUSAGE,
} from "@app/core/new-mail-merge/dialogs/model";
import { DownloadFile } from "@common/constants/downloadfile";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  MAX_LENGTH_DOCUMENT_NAME,
  makeActiveData,
  saveAsFormatData,
} from "@common/pages/settings/lookups/documents/_id/components/child-screens/general/components/form-element/config";
import { nameOfCCDocument } from "@common/pages/settings/lookups/documents/_id/model";
import { useLookupDocumentStore } from "@common/pages/settings/lookups/documents/_id/store";
import {
  fileUploadValidator,
  getProductTypeByMailMergeDataset,
} from "@common/pages/settings/lookups/documents/_id/util";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FieldProps,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import "./_index.scss";
interface ILookupDocumentFormElementProps {
  formRenderProps: FormRenderProps;
}

export const LookupDocumentFormElement = observer(
  ({ formRenderProps }: ILookupDocumentFormElementProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { settings } = useCommonCoreStore();
    const { documentMailMergeDataset, documentUsage, isInactive } =
      useLookupDocumentStore();
    const { pushNotification } = useCCAppNotificationStore();
    const [isChangeImageFieldSettingsHint, setIsChangeImageFieldSettingsHint] =
      useState(false);

    const handleOnChangeUsage = (event: ComboBoxChangeEvent) => {
      const usage: MAILMERGEDOCUMENTUSAGE = event.target.value?.Key ?? null;
      onChange(nameOfCCDocument("Document_Usage_ENUM_Int"), {
        value: usage,
      });
      onChange("_options.IsDisabledSaveAsFormat", { value: false });
      onChange("_options.IsDisabledAllowLiveMailMerge", { value: false });
      onChange("_options.IsDisabledMailMergeDataset", { value: true });

      if (usage === MAILMERGEDOCUMENTUSAGE.CustomDocumentTemplates) {
        onChange("_options.MailMergeDataset", {
          value: documentMailMergeDataset?.filter(
            (item) => item?.Key !== MAILMERGEDATASET.CORE_LiveData
          ),
        });
        onChange("_options.IsDisabledMailMergeDataset", { value: false });
        onChange(nameOfCCDocument("Dataset_ENUM_Int"), {
          value: 0,
        });
      } else if (usage === MAILMERGEDOCUMENTUSAGE.CORE_LiveData) {
        onChange(nameOfCCDocument("Dataset_ENUM_Int"), {
          value: MAILMERGEDATASET.CORE_LiveData,
        });

        onChange("_options.IsDisabledMailMergeDataset", { value: true });
        onChange("_options.MailMergeDataset", {
          value: documentMailMergeDataset,
        });

        onChange("_options.IsDisabledSaveAsFormat", { value: true });
        onChange("_options.SaveAsFormat", {
          value: saveAsFormatData[1]?.Key,
        });

        onChange("_options.IsDisabledAllowLiveMailMerge", { value: true });
        onChange(nameOfCCDocument("AllowLiveMerge"), { value: true });
      } else {
        let mailMergeDataset: MAILMERGEDATASET | null = null;
        switch (usage) {
          case MAILMERGEDOCUMENTUSAGE.HM_RegistrationCertificate:
          case MAILMERGEDOCUMENTUSAGE.HM_RegistrationCertificate_NoNotifications:
          case MAILMERGEDOCUMENTUSAGE.HM_RegistrationCertificate_OnlyNotifications:
          case MAILMERGEDOCUMENTUSAGE.HM_RenewalNotice:
            mailMergeDataset = MAILMERGEDATASET.HealthManager_Premises;
            break;
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_Disabled_Paper:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_Disabled_Sticky:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_DisabledOrg_Paper:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_DisabledOrg_Sticky:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_ContractManagedGeneral_Sticky:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_ContractManagedGeneral_Paper:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_ContractManaged_SpecificVehicle_Paper:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_ContractManaged_SpecificVehicle_Sticky:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_SpecialPurpose_Paper:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_SpecialPurpose_Sticky:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_SpecificVehicle_Paper:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_SpecificVehicle_Sticky:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_Staff_Paper:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_Staff_Sticky:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_TemporaryResident_Paper:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_TemporaryResident_Sticky:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_TemporaryStaff_Paper:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_TemporaryStaff_Sticky:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_Tradesman_Paper:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_Tradesman_Sticky:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_Variable_Paper:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_Variable_Sticky:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_Visitor_Paper:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_Visitor_Sticky:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_DisabledTemporary_Paper:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_DisabledTemporary_Sticky:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_Municipal_Paper:
          case MAILMERGEDOCUMENTUSAGE.DISRES_PermitLabel_Municipal_Sticky:
            mailMergeDataset = MAILMERGEDATASET.DISRES_PermitLabel;
            onChange(nameOfCCDocument("UseRegions"), { value: false });
            onChange("_options.IsDisabledUseRegion", { value: true });
            break;
          case MAILMERGEDOCUMENTUSAGE.DISRES_AddressLabel:
            mailMergeDataset = MAILMERGEDATASET.DISRES_Permit;
            onChange(nameOfCCDocument("UseRegions"), { value: false });
            onChange("_options.IsDisabledUseRegion", { value: true });
            break;
          case MAILMERGEDOCUMENTUSAGE.CRS_SwitchFromCentreToGroup3YO:
            mailMergeDataset = MAILMERGEDATASET.CRS_3yoRegistration;
            break;
          case MAILMERGEDOCUMENTUSAGE.CRS_SwitchFromCentreToGroup4YO:
            mailMergeDataset = MAILMERGEDATASET.CRS_4yoRegistration;
            break;
          case MAILMERGEDOCUMENTUSAGE.CRS_POA_3YO:
            mailMergeDataset = MAILMERGEDATASET.CRS_Registration;
            break;
          case MAILMERGEDOCUMENTUSAGE.CRS_POA_4YO:
            mailMergeDataset = MAILMERGEDATASET.CRS_Registration;
            break;
          case MAILMERGEDOCUMENTUSAGE.CUSTOMERSERVICE_Event:
          case MAILMERGEDOCUMENTUSAGE.CUSTOMERSERVICE_Event_FinalNotice:
          case MAILMERGEDOCUMENTUSAGE.CUSTOMERSERVICE_Event_Acknowledgement:
          case MAILMERGEDOCUMENTUSAGE.CUSTOMERSERVICE_ColdChainBreach_Advice:
            mailMergeDataset = MAILMERGEDATASET.CUSTOMERSERVICE_Event;
            break;
          case MAILMERGEDOCUMENTUSAGE.TOWNPLANNING_Application:
            mailMergeDataset = MAILMERGEDATASET.TOWNPLANNING_Application;
            break;
          case MAILMERGEDOCUMENTUSAGE.TOWNPLANNING_Subdivision:
            mailMergeDataset = MAILMERGEDATASET.TOWNPLANNING_Subdivision;
            break;
          case MAILMERGEDOCUMENTUSAGE.TOWNPLANNING_Application_Advertising:
            mailMergeDataset =
              MAILMERGEDATASET.TOWNPLANNING_Application_Advertising;
            break;
          case MAILMERGEDOCUMENTUSAGE.TOWNPLANNING_Application_Appeals:
            mailMergeDataset =
              MAILMERGEDATASET.TOWNPLANNING_Application_Appeals;
            break;
          case MAILMERGEDOCUMENTUSAGE.TOWNPLANNING_Application_Fees:
            mailMergeDataset = MAILMERGEDATASET.TOWNPLANNING_Application_Fees;
            break;
          case MAILMERGEDOCUMENTUSAGE.TOWNPLANNING_Application_FurtherInformationRequests:
            mailMergeDataset =
              MAILMERGEDATASET.TOWNPLANNING_Application_FurtherInformationRequests;
            break;
          case MAILMERGEDOCUMENTUSAGE.TOWNPLANNING_Application_Inspections:
            mailMergeDataset =
              MAILMERGEDATASET.TOWNPLANNING_Application_Inspections;
            break;
          case MAILMERGEDOCUMENTUSAGE.TOWNPLANNING_Application_Notices:
            mailMergeDataset =
              MAILMERGEDATASET.TOWNPLANNING_Application_Notices;
            break;
          case MAILMERGEDOCUMENTUSAGE.TOWNPLANNING_Application_WithRegions:
            mailMergeDataset = MAILMERGEDATASET.TOWNPLANNING_Application;
            break;
          case MAILMERGEDOCUMENTUSAGE.BUILDING_CertificateOfCompletion:
            mailMergeDataset =
              MAILMERGEDATASET.TOWNPLANNING_BuildingApplication;
            break;
          case MAILMERGEDOCUMENTUSAGE.BUILDING_Application_FurtherInformationRequests:
            mailMergeDataset =
              MAILMERGEDATASET.BUILDING_Application_FurtherInformationRequests;
            break;
          case MAILMERGEDOCUMENTUSAGE.DWCMS_Application:
            mailMergeDataset = MAILMERGEDATASET.DWCMS_Application;
            break;
          case MAILMERGEDOCUMENTUSAGE.DWCMS_Registration:
            mailMergeDataset = MAILMERGEDATASET.DWCMS_Registration;
            break;
          case MAILMERGEDOCUMENTUSAGE.DWCMS_Worker:
            mailMergeDataset = MAILMERGEDATASET.DWCMS_Worker;
            break;
          case MAILMERGEDOCUMENTUSAGE.Register_CouncilConsent:
            mailMergeDataset = MAILMERGEDATASET.Register_CouncilConsent;
            break;
          case MAILMERGEDOCUMENTUSAGE.Register_Demolition:
            mailMergeDataset = MAILMERGEDATASET.Register_Demolition;
            break;
          case MAILMERGEDOCUMENTUSAGE.Register_ESBuilding:
            mailMergeDataset = MAILMERGEDATASET.Register_ESBuilding;
            break;
          case MAILMERGEDOCUMENTUSAGE.Register_NonCompliance:
            mailMergeDataset = MAILMERGEDATASET.Register_NonCompliance;
            break;
          case MAILMERGEDOCUMENTUSAGE.Register_PlanningCertficate:
            mailMergeDataset = MAILMERGEDATASET.Register_PlanningCertficate;
            break;
          case MAILMERGEDOCUMENTUSAGE.Register_PlanningEnquiry:
            mailMergeDataset = MAILMERGEDATASET.Register_PlanningEnquiry;
            break;
          case MAILMERGEDOCUMENTUSAGE.Register_PlansToComply:
            mailMergeDataset = MAILMERGEDATASET.Register_PlansToComply;
            break;
          case MAILMERGEDOCUMENTUSAGE.Register_POPE:
            mailMergeDataset = MAILMERGEDATASET.Register_POPE;
            break;
          case MAILMERGEDOCUMENTUSAGE.Register_SecondaryConsent:
            mailMergeDataset = MAILMERGEDATASET.Register_SecondaryConsent;
            break;
          case MAILMERGEDOCUMENTUSAGE.Register_EOT:
            mailMergeDataset = MAILMERGEDATASET.Register_EOT;
            break;
          case MAILMERGEDOCUMENTUSAGE.Register_FurtherInformationRequests:
            mailMergeDataset =
              MAILMERGEDATASET.Register_FurtherInformationRequests;
            break;
          case MAILMERGEDOCUMENTUSAGE.Register_BuildingCertificate:
            mailMergeDataset = MAILMERGEDATASET.Register_BuildingCertificate;
            break;
          case MAILMERGEDOCUMENTUSAGE.Register_Contacts:
            mailMergeDataset = MAILMERGEDATASET.Register_Contacts;
            break;
          case MAILMERGEDOCUMENTUSAGE.Register_BuildingPoolSpa:
            mailMergeDataset = MAILMERGEDATASET.Register_BuildingPoolsSpa;
            break;
          case MAILMERGEDOCUMENTUSAGE.TrainingManager_CourseOutline:
          case MAILMERGEDOCUMENTUSAGE.TrainingManager_CourseOutline_Doc2:
          case MAILMERGEDOCUMENTUSAGE.TrainingManager_CourseOutline_Doc3:
            mailMergeDataset = MAILMERGEDATASET.TrainingManager_CourseOutline;
            break;
          case MAILMERGEDOCUMENTUSAGE.TrainingManager_CourseSchedule:
          case MAILMERGEDOCUMENTUSAGE.TrainingManager_CourseSchedule_Doc2:
          case MAILMERGEDOCUMENTUSAGE.TrainingManager_CourseSchedule_Doc3:
            mailMergeDataset = MAILMERGEDATASET.TrainingManager_CourseSchedule;
            break;
          case MAILMERGEDOCUMENTUSAGE.TrainingManager_TrainingRequest:
          case MAILMERGEDOCUMENTUSAGE.TrainingManager_TrainingRequest_Doc2:
          case MAILMERGEDOCUMENTUSAGE.TrainingManager_TrainingRequest_Doc3:
            mailMergeDataset = MAILMERGEDATASET.TrainingManager_TrainingRequest;
            onChange(nameOfCCDocument("UseRegions"), { value: true });
            onChange("_options.IsDisabledUseRegion", { value: true });
            break;
        }
        if (!isNil(mailMergeDataset)) {
          onChange(nameOfCCDocument("Dataset_ENUM_Int"), {
            value: mailMergeDataset,
          });
        } else {
          pushNotification({
            title: "Mail merge dataset is not available for selected usage",
            type: "error",
            autoClose: false,
          });
        }
      }
    };

    const handleOnDownload = (attachmentInfo?: Attachment) => {
      if (!isNil(attachmentInfo)) {
        DownloadFile(
          attachmentInfo?.FileEntry?.Base64FileAttachment ?? "",
          attachmentInfo?.FileEntry?.ContentType ?? "",
          attachmentInfo?.FileEntry?.FileName ?? ""
        );
      }
    };

    const handleUploadFile = (attachments: IAttachmentFile[]) => {
      onChange("_options.FileUpload", {
        value: attachments,
      });
      onChange(nameOfCCDocument("FileEntry"), {
        value: {
          ...attachments[0]?.FileEntry,
          FileAttachment: attachments[0]?.FileEntry?.Base64FileAttachment,
        },
      });
    };

    const imageFieldSettingsHintValue: string | undefined = useMemo(() => {
      let result =
        "Usage: FieldName,Width,Height|<br />Example:PhotoField1,100,200|PhotoField2,150,200<br /><br />";
      const productType = getProductTypeByMailMergeDataset(
        valueGetter(nameOfCCDocument("Dataset_ENUM_Int"))
      );
      if (!isNil(productType)) {
        const imageFieldNames: string =
          settings[
            ECorporateSettingsField.CorporateSettings_MailMerge_ImageFieldNames
          ]?.Value.toString();
        if (!isEmpty(imageFieldNames)) {
          result += `use the following field name(s):<br />
            ${imageFieldNames}`;
        }
      }
      return result;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChangeImageFieldSettingsHint]);

    const DocumentField = useCallback(
      (props: FieldProps) => {
        const { disabled, ...others } = props;
        return (
          <Field {...others} disabled={isInactive ? isInactive : disabled} />
        );
      },
      [isInactive]
    );

    return (
      <FormElement>
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Document name
                <CCTooltip
                  type="validator"
                  position="right"
                  content="Document name is a required field"
                />
              </label>
              <DocumentField
                name={nameOfCCDocument("Name")}
                component={CCInput}
                validator={requiredValidator}
                maxLength={MAX_LENGTH_DOCUMENT_NAME}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Save as format</label>
              <DocumentField
                name={"_options.SaveAsFormat"}
                component={CCSearchComboBox}
                data={saveAsFormatData ?? []}
                textField="Value"
                dataItemKey="Key"
                isUseDefaultOnchange
                disabled={
                  valueGetter("_options.IsDisabledSaveAsFormat") ?? false
                }
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Usage</label>
              <DocumentField
                name={nameOfCCDocument("Document_Usage_ENUM_Int")}
                component={CCSearchComboBox}
                data={valueGetter("_options.Usages") ?? documentUsage ?? []}
                textField="Value"
                dataItemKey="Key"
                value={getDropdownValue(
                  valueGetter(nameOfCCDocument("Document_Usage_ENUM_Int")),
                  valueGetter("_options.Usages") ?? documentUsage ?? []
                )}
                onChange={handleOnChangeUsage}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Get data from</label>
              <DocumentField
                name={nameOfCCDocument("Dataset_ENUM_Int")}
                data={
                  valueGetter("_options.MailMergeDataset") ??
                  documentMailMergeDataset ??
                  []
                }
                textField="Value"
                dataItemKey="Key"
                isKeyValueDropdown
                disabled={
                  valueGetter("_options.IsDisabledMailMergeDataset") ??
                  valueGetter(nameOfCCDocument("Document_Usage_ENUM_Int")) !==
                    MAILMERGEDOCUMENTUSAGE.CustomDocumentTemplates
                }
                component={CCSearchComboBox}
                value={getDropdownValue(
                  valueGetter(nameOfCCDocument("Dataset_ENUM_Int")),
                  valueGetter("_options.MailMergeDataset") ??
                    documentMailMergeDataset ??
                    []
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOfCCDocument("Dataset_ENUM_Int"), {
                    value: event.target.value?.Key ?? null,
                  });
                  setIsChangeImageFieldSettingsHint(
                    !isChangeImageFieldSettingsHint
                  );
                }}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Availability</label>
              <DocumentField
                name={"_options.Availability"}
                component={CCSearchComboBox}
                data={makeActiveData ?? []}
                textField="Value"
                dataItemKey="Key"
                isUseDefaultOnchange
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Use mail merge regions</label>
              <DocumentField
                name={nameOfCCDocument("UseRegions")}
                checked={valueGetter(nameOfCCDocument("UseRegions"))}
                disabled={valueGetter("_options.IsDisabledUseRegion") ?? false}
                component={CCSwitch}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Files
                <CCTooltip type="validator" position="right" />
              </label>
              <DocumentField
                name={"_options.FileUpload"}
                value={valueGetter("_options.FileUpload") ?? []}
                restrictions={{
                  allowedExtensions: [".doc", ".docx"],
                }}
                mode={"single"}
                onChange={handleUploadFile}
                component={CCDocumentsInput}
                onClick={handleOnDownload}
                validator={fileUploadValidator}
                isUploadOnAdd={false}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Additional info</label>
              <DocumentField
                name={nameOfCCDocument("AdditionalInformation")}
                rows={4}
                component={CCTextArea}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Allow live merge</label>
              <DocumentField
                name={nameOfCCDocument("AllowLiveMerge")}
                checked={valueGetter(nameOfCCDocument("AllowLiveMerge"))}
                disabled={
                  valueGetter("_options.IsDisabledAllowLiveMailMerge") ?? false
                }
                component={CCSwitch}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Image field settings</label>
              <DocumentField
                name={nameOfCCDocument("ImageFieldSettings")}
                component={CCInput}
              />
            </div>
            <CCValueField
              className={"cc-image-field-setting-hint cc-col-span-2"}
              value={sanitizeHtml(imageFieldSettingsHintValue ?? "")}
            />
          </div>
        </section>
      </FormElement>
    );
  }
);
