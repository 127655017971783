import { sanitizeHtml } from "@common/utils/sanitized-parser";

export const sanitizeAndConvertLineBreaks = (
  value?: string | undefined,
  searchValue: string | RegExp = "\r\n",
  replaceValue: string = "<br />"
): string | undefined => {
  return (
    value &&
    (sanitizeHtml(replaceAll(value, searchValue, replaceValue)) as string)
  );
};

const replaceAll = (
  ...args: [string, string | RegExp, string, ...any[]]
): string => {
  return args.length < 3 ? args[0] : args[0].replaceAll(args[1], args[2]);
};
