import { getContactConcessionCard } from "@app/products/property/contacts-central-names/[id]/components/child-screens/concession-cards/api";
import {
  colContactConcessionCardsHeld,
  colContactVerificationHistory,
} from "@app/products/property/contacts-central-names/[id]/components/child-screens/concession-cards/config";
import {
  DTO_ConcessionCard,
  DTO_ConcessionCard_VerificationHistory,
  DTO_Entity_ConcessionCards,
} from "@app/products/property/contacts-central-names/[id]/components/child-screens/concession-cards/model";
import { useContactsCentralNamesStore } from "@app/products/property/contacts-central-names/[id]/store";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
const nameOfConcessionCardGrid = nameOfFactory<DTO_ConcessionCard>();
const nameOfContactVerificationHistoryGrid =
  nameOfFactory<DTO_ConcessionCard_VerificationHistory>();

export const ContactConcessionCard = observer(() => {
  const { contactId } = useContactsCentralNamesStore();
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [concessionCardData, setConcessionCardData] =
    useState<DTO_Entity_ConcessionCards>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadDataConcessionCard = async () => {
    if (contactId) {
      setIsLoading(true);
      let errorResponse = undefined;
      const response = await getContactConcessionCard(contactId);
      if (isSuccessResponse(response) && response?.data) {
        let concessionCard = response?.data;
        if (response?.data?.VerificationHistory?.length) {
          concessionCard.VerificationHistory =
            response?.data?.VerificationHistory.map((item, index) => {
              return { ...item, Id: ++index };
            });
        }
        setConcessionCardData(concessionCard);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
      setResponseLoadError(errorResponse);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadDataConcessionCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);
  if (isLoading) return <Loading isLoading />;

  //show load failed
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadDataConcessionCard();
        }}
      />
    );

  if (!concessionCardData) return <NoData />;

  return (
    <div className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Concession cards held</label>
          <CCGrid
            data={concessionCardData?.ConcessionCards ?? []}
            columnFields={colContactConcessionCardsHeld}
            primaryField={nameOfConcessionCardGrid("CC_Id")}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">
            Verification history for concession cards
          </label>
          <CCGrid
            data={concessionCardData?.VerificationHistory ?? []}
            columnFields={colContactVerificationHistory}
            primaryField={nameOfContactVerificationHistoryGrid("Id")}
          />
        </div>
      </div>
    </div>
  );
});
