import { InputSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/input/_index";
import { SwitchSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/switch/_index";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const EnableABNACNCheckForm = ({
  formRenderProps,
}: ICommonFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter } = formRenderProps;
  const data = useDataSetting(configData, [
    "CorporateSettings_EnableABNChecker",
    "CorporateSettings_ABNChecker_AuthenticationGuid",
  ]);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <SwitchSettings
          data={data.CorporateSettings_EnableABNChecker}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        {valueGetter(
          data.CorporateSettings_EnableABNChecker?.FieldName as string
        ) && (
          <InputSettings
            data={data.CorporateSettings_ABNChecker_AuthenticationGuid}
            isEditing={isEditing}
          />
        )}
      </div>
    </section>
  );
};

export default observer(EnableABNACNCheckForm);
