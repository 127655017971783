import { PPRRegion } from "@app/products/town-planning/ppr/system-admin/regions/model";
import { LOOKUP_REGION_ROUTE } from "@common/pages/settings/lookups/regions/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<PPRRegion>();
export const colPprLookupRegions: IColumnFields[] = [
  {
    field: nameOf("Region_Name"),
    title: "Region",
    locked: true,
    linkTo: (dataItem: PPRRegion) =>
      `${LOOKUP_REGION_ROUTE}/${dataItem.Region_ID}`,
  },
  {
    field: nameOf("Council_Name"),
    title: "Municipality",
  },
  {
    field: nameOf("Suburb"),
    title: "Suburb",
  },
  {
    field: nameOf("Coordinator_DisplayName"),
    title: "Coordinator",
  },
  {
    field: nameOf("State"),
    title: "State",
  },
  {
    field: nameOf("PostCode"),
    title: "Postcode",
  },
];
