import {
  getCategories,
  getCategoriesFormWorkflow,
} from "@app/products/property/components/action-bar/dialog/add-attachment/api";
import {
  IAddAttachmentDialog,
  eUploadMode,
} from "@app/products/property/components/action-bar/dialog/add-attachment/model";
import { checkIsValidFile } from "@app/products/property/components/action-bar/dialog/add-attachment/util";
import { WorkflowTypes, nameOfLov } from "@app/products/property/model";

import { RECORDSSYSTEM } from "@app/products/town-planning/ppr/[id]/model";
import { getKeywords } from "@common/apis/coreKeyword";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { DTO_LOV } from "@common/models/odataResponse";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import {
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { isEmpty } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";
interface IAddAttachmentPropertyDialogProps {
  onClose: () => void;
  onSubmit: (data: IAddAttachmentDialog) => void;
  isLoadingButton?: boolean;
  isDisableWebLink?: boolean;
  isAllowMultiple?: boolean;
  workflowType?: WorkflowTypes;
  subType?: number;
  recordType: RECORDTYPE;
}

//Note: Same UX with Core Attachment, please recheck both if you update UX
export const AddAttachmentPropertyDialog = ({
  onClose,
  onSubmit,
  isLoadingButton = false,
  isDisableWebLink = false,
  isAllowMultiple,
  workflowType,
  subType,
  recordType,
}: IAddAttachmentPropertyDialogProps) => {
  const [initialValues, setInitialValues] = useState<IAddAttachmentDialog>();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const { settings } = useCommonCoreStore();
  const [isLoading, setIsLoading] = useState(false);
  const nameOf = nameOfFactory<IAddAttachmentDialog>();
  const nameOfCoreKeyword = nameOfFactory<CoreKeyword>();
  const [categoriesData, setCategoriesData] = useState<DTO_LOV[]>();
  const [webLinkCategoriesData, setWebLinkCategoriesData] =
    useState<CoreKeyword[]>();
  const recordsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
  );
  const filterTypesStr: string =
    settings[ECorporateSettingsField.CorporateSettings_FileExtension]?.Value;
  const isEnableAttachmentSizeRestriction = getBoolValueSetting(
    settings[ECorporateSettingsField.Global_EnableAttachmentSizeRestriction]
  );

  const isAllowMultipleFileAttachments =
    isAllowMultiple ??
    getBoolValueSetting(
      settings[ECorporateSettingsField.Global_EnableFileAttachmentMultiple]
    );

  const attachmentSizeRestrictionSize = getNumberValueSetting(
    settings[ECorporateSettingsField.Global_AttachmentSizeRestrictionSize]
  );

  const allowedExtensions = useMemo(() => {
    if (!isEmpty(filterTypesStr)) {
      return filterTypesStr
        .toUpperCase()
        .split("|")
        .map((item) => item.replace(".", "").replace("*", ""));
    }
  }, [filterTypesStr]);

  const maxFileSize = useMemo(() => {
    if (isEnableAttachmentSizeRestriction && attachmentSizeRestrictionSize) {
      return attachmentSizeRestrictionSize;
    }
  }, [attachmentSizeRestrictionSize, isEnableAttachmentSizeRestriction]);

  const uploadModeData = [
    {
      label: "Upload file",
      value: eUploadMode.UPLOAD_FILE,
    },
    {
      label: "Add weblink",
      value: eUploadMode.UPLOAD_WEB_LINK,
      disabled: isDisableWebLink,
    },
  ];

  const getCategoriesLovsAndKeywords = async () => {
    setIsLoading(true);
    const response = await getKeywords(
      KEYWORD_TYPE.Core_WebLinkCategory,
      PRODUCT_TYPE_NUMBER.Core
    );
    if (isSuccessResponse(response)) {
      if (response?.data) setWebLinkCategoriesData(response?.data);
    } else {
      notificationRef.current?.pushNotification({
        title: `Web link categories load failed`,
        type: "warning",
      });
    }
    if (workflowType) {
      const responseGetCategories = await getCategoriesFormWorkflow(
        workflowType,
        subType
      );
      setIsLoading(false);
      if (
        isSuccessResponse(responseGetCategories) &&
        responseGetCategories?.data
      ) {
        setCategoriesData(responseGetCategories?.data.Document_Type);
      } else {
        notificationRef.current?.pushNotification({
          title: `Categories load failed`,
          type: "warning",
        });
      }
    } else {
      const responseGetCategories = await getCategories(recordType);
      setIsLoading(false);
      if (isSuccessResponse(responseGetCategories)) {
        if (responseGetCategories?.data)
          setCategoriesData(responseGetCategories?.data.Document_Type);
      } else {
        notificationRef.current?.pushNotification({
          title: `Categories load failed`,
          type: "warning",
        });
      }
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    setInitialValues({
      UploadMode: eUploadMode.UPLOAD_FILE,
    });
    getCategoriesLovsAndKeywords();
  });

  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    const data = event.values as IAddAttachmentDialog;
    onSubmit(data);
  };

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      render={(formRenderProps: FormRenderProps) => {
        const { onChange, valueGetter } = formRenderProps;
        const isDisabled =
          !formRenderProps.valid ||
          (valueGetter("UploadMode") === eUploadMode.UPLOAD_FILE &&
            !checkIsValidFile(valueGetter(nameOf("File"))));
        return (
          <CCDialog
            titleHeader={"Add Attachment"}
            height={"auto"}
            maxWidth={"35%"}
            onClose={onClose}
            disabled={isLoadingButton}
            bodyElement={
              isLoading ? (
                <Loading isLoading={isLoading} />
              ) : (
                <FormElement className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <Field
                        name={nameOf("UploadMode")}
                        component={RadioGroup}
                        layout={"horizontal"}
                        data={uploadModeData}
                      />
                    </div>
                    {valueGetter("UploadMode") === eUploadMode.UPLOAD_FILE ? (
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            File
                            <CCTooltip type="validator" position="right" />
                            {isAllowMultipleFileAttachments && (
                              <div className="cc-icon-label-button-info">
                                <CCTooltip
                                  type="info"
                                  position="right"
                                  content="To select multiple files, hold down the CTRL key, or use your mouse to highlight the items you want to upload."
                                />
                              </div>
                            )}
                          </label>
                          <Field
                            name={nameOf("File")}
                            component={CCUploadFile}
                            autoUpload={false}
                            batch={false}
                            multiple={isAllowMultipleFileAttachments ?? false}
                            defaultFiles={[]}
                            withCredentials={false}
                            files={valueGetter(nameOf("File"))}
                            restrictions={{
                              allowedExtensions: allowedExtensions,
                              maxFileSize: maxFileSize,
                            }}
                            onAdd={(event: UploadOnAddEvent) => {
                              onChange(nameOf("File"), {
                                value: event.newState,
                              });
                            }}
                            onRemove={(event: UploadOnRemoveEvent) => {
                              onChange(nameOf("File"), {
                                value: event.newState,
                              });
                            }}
                            showActionButtons={false}
                            validator={requiredValidator}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">
                            Title
                            <CCTooltip
                              type={"info"}
                              position="auto"
                              content={
                                "Enter a value to be used as the common Title for all the attachments, OR Leave blank to use the physical file name of each attachment as it’s title"
                              }
                            />
                          </label>
                          <Field
                            name={nameOf("Title")}
                            component={CCInput}
                            placeholder={"Title"}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Category</label>
                          <Field
                            name={"Category"}
                            textField={nameOfLov("Name")}
                            dataItemKey={nameOfLov("Code")}
                            data={categoriesData ?? []}
                            component={CCSearchComboBox}
                            validator={
                              recordsSystem &&
                              recordsSystem === RECORDSSYSTEM.infoXpert
                                ? requiredValidator
                                : undefined
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Weblink
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            name={nameOf("WebLink")}
                            component={CCInput}
                            placeholder={"Weblink"}
                            validator={requiredValidator}
                          />
                        </div>

                        <div className="cc-field">
                          <label className="cc-label">Tag</label>
                          <Field
                            name={nameOf("Tag")}
                            placeholder={"Tag"}
                            component={CCInput}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Weblink category</label>
                          <Field
                            name={nameOf("WebLinkCategory")}
                            textField={nameOfCoreKeyword("Name")}
                            dataItemKey={nameOfCoreKeyword("Keyword_ID")}
                            data={webLinkCategoriesData ?? []}
                            component={CCSearchComboBox}
                          />
                        </div>
                      </div>
                    )}
                  </section>
                </FormElement>
              )
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Close
                </Button>
                <Button
                  themeColor="primary"
                  disabled={isDisabled}
                  className={"cc-dialog-button"}
                  type={"submit"}
                  onClick={formRenderProps.onSubmit}
                  iconClass={isLoadingButton ? "fas fa-spinner fa-spin" : ""}
                >
                  Save
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
