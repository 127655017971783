import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import { DTO_Transaction } from "@app/products/property/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { SortDescriptor } from "@progress/kendo-data-query";
import React from "react";

const nameOf = nameOfFactory<DTO_Transaction>();
export const colTransactions: IColumnFields[] = [
  {
    field: nameOf("JournalNumber"),
    title: "Journal Number",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem: DTO_Transaction) =>
      `${PROPERTY_JOURNALS_ROUTE}/${dataItem.JournalNumber}`,
  },
  {
    field: nameOf("TransactionType"),
    title: "Type",
  },
  {
    field: nameOf("DateTime"),
    title: "Transaction Date Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell />,
  },
  {
    field: nameOf("ProgressiveBalance"),
    title: "Progressive Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Reference"),
    title: "Reference",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("ReceiptNumber"),
    title: "Receipt Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Notes"),
    title: "Notes",
  },
  {
    field: nameOf("TransactionSource"),
    title: "Source",
  },
  {
    field: nameOf("SourceId"),
    title: "Source ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("IsProposed"),
    title: "Is Proposed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("IsCancelled"),
    title: "Is Cancelled",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("AutoAllocate"),
    title: "Auto Allocate",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("PaymentAllocationNumber"),
    title: "Allocation Sequence Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("FinancialYear"),
    title: "Financial Year",
  },
  {
    field: nameOf("CreatedOn"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "RebateClaimId",
    title: "Rebate Claim",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "AssessmentRebateEntitlementId",
    title: "Rebate Entitlement ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("CreatedBy"),
    title: "Created By",
  },
  {
    field: nameOf("TransactionId"),
    title: "Transaction ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];

export const defaultSortTransactions: SortDescriptor[] = [
  {
    field: nameOf("DateTime"),
    dir: "desc",
  },
  {
    field: nameOf("TransactionId"),
    dir: "desc",
  },
];
