import { BuildingParentSection } from "@app/products/building/components/forms/building-parent-section/_index";
import { BuildingFieldMapping } from "@app/products/building/model";
import {
  appNoFields,
  officerFields,
  regulationsFields,
} from "@app/products/building/registers/council-consent/[id]/components/child-screens/general/components/form-element/config";
import { dropdownCouncilConsent } from "@app/products/building/registers/council-consent/[id]/components/child-screens/general/components/form-element/mock";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { validateRequired } from "@common/utils/field-validators";
import { formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";
interface IBuildingRegisterCouncilConsentFormElementProps {
  formRenderProps: FormRenderProps;
}

export const BuildingRegisterCouncilConsentFormElement = ({
  formRenderProps,
}: IBuildingRegisterCouncilConsentFormElementProps) => {
  const { pushNotification } = useCCAppNotificationStore();
  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }

    if (!formRenderProps.valid) return;

    pushNotification({
      title: "The form is not modified",
      type: "warning",
    });
  };

  const getValue = formRenderProps.valueGetter;

  return (
    <FormElement>
      {/* Reference by a label in WastewaterActionBar.tsx */}
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />

      <BuildingParentSection formRenderProps={formRenderProps} />
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              name={BuildingFieldMapping.Description}
              placeholder="Description"
              rows={8}
              value={getValue(BuildingFieldMapping.Description)}
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Officer</label>
            <Field
              component={InputPicker}
              {...officerFields(formRenderProps)}
              name={BuildingFieldMapping.Officer}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Application number</label>
            <Field
              component={InputPicker}
              {...appNoFields(formRenderProps)}
              name={BuildingFieldMapping.AppNo}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">File number</label>
            <Field
              name={BuildingFieldMapping.FileNumber}
              placeholder="File number"
              component={CCInput}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <CCValueField
            label="Lodged"
            value={getValue(BuildingFieldMapping.Lodged)}
            format={DATE_FORMAT.DATE}
          />
          <div className="cc-field">
            <label className="cc-label">
              Regulations
              <CCTooltip
                type="validator"
                position="right"
                content={formRenderProps.errors.Regulations}
              />
            </label>
            <Field
              component={InputPicker}
              {...regulationsFields(formRenderProps)}
              name={BuildingFieldMapping.Regulations}
              validator={validateRequired}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Council consent</label>
            <Field
              name={BuildingFieldMapping.CouncilConsent}
              dataItemKey="Key"
              textField="Value"
              data={dropdownCouncilConsent}
              isUseDefaultOnchange
              component={CCSearchComboBox}
            />
          </div>
        </div>
      </div>
      <hr className="cc-divider" />
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <CCValueField
            label="OS fees"
            value={getValue(BuildingFieldMapping.OutstandingFees)}
            format={CURRENCY_FORMAT.CURRENCY1}
            style={formatOSFees(getValue(BuildingFieldMapping.OutstandingFees))}
          />
          <div className="cc-field">
            <label className="cc-label">Debtor number</label>
            <Field
              name={BuildingFieldMapping.DebtorNumber}
              placeholder="Debtor number"
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Cost of works</label>
            <Field
              name={BuildingFieldMapping.CostOfWorks}
              placeholder="Cost of works"
              component={CCInput}
            />
          </div>
        </div>
      </div>
    </FormElement>
  );
};
