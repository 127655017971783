import { getPlannerDecisions } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/approve-report-and-response/api";
import { IProponentDecision } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/approve-decision/model";
import { plannerDecisionNumberMap } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/approve-decision/util";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { isNull } from "lodash";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IPSARApproveDecisionDialogProps {
  onClose: () => void;
  onSubmit: (value: IProponentDecision) => void;
  isSubmitLoading: boolean;
  notifications?: IAppNotificationItem[];
}

export const PSARApproveDecisionDialog = ({
  onClose,
  onSubmit,
  isSubmitLoading,
  notifications,
}: IPSARApproveDecisionDialogProps) => {
  const { ppr } = usePSARStore();
  const [initialValues, setInitialValues] = useState<IProponentDecision>();
  const nameOf = nameOfFactory<IProponentDecision>();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [plannerDecisionsData, setPlannerDecisionsData] = useState<
    IKeyValuePacket[]
  >([]);

  useEffectOnce(() => {
    getPlannerDecisions().then((res) => {
      if (isSuccessResponse(res)) {
        if (res?.data) setPlannerDecisionsData(res.data);
      } else {
        notificationRef.current?.pushNotification({
          title: `Planner decisions load failed`,
          type: "warning",
        });
      }
    });

    if (!isNull(ppr?.AssessmentPlanner)) {
      if (ppr?.AssessmentPlanner?.PSARPlanningDecisionType_ENUM) {
        setInitialValues({
          DecisionDate: ppr?.DecisionDate ?? new Date(),
          Decision:
            plannerDecisionNumberMap[
              ppr.AssessmentPlanner.PSARPlanningDecisionType_ENUM
            ],
        });
      } else {
        setInitialValues({
          DecisionDate: ppr?.DecisionDate ?? new Date(),
        });
      }
    }
  });

  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    onSubmit(event.values as IProponentDecision);
  };

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      render={({ valueGetter, onChange, valid, onSubmit }: FormRenderProps) => {
        return (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="25%"
              titleHeader={"Approve Application"}
              onClose={onClose}
              disabled={isSubmitLoading}
              bodyElement={
                <div className="cc-form">
                  <CCLocalNotification
                    defaultNotifications={notifications}
                    ref={notificationRef}
                  />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Decision
                          <CCTooltip type="validator" />
                        </label>
                        <Field
                          name={nameOf("Decision")}
                          textField={"Value"}
                          dataItemKey={"Key"}
                          component={CCSearchComboBox}
                          data={plannerDecisionsData ?? []}
                          value={getDropdownValue(
                            valueGetter(nameOf("Decision")),
                            plannerDecisionsData ?? [],
                            "Key"
                          )}
                          onChange={(event: ComboBoxChangeEvent) => {
                            onChange(nameOf("Decision"), {
                              value: event.target.value?.Key ?? null,
                            });
                          }}
                          validator={requiredValidator}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Decision date</label>
                        <Field
                          name={nameOf("DecisionDate")}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE_CONTROL}
                          defaultValue={new Date()}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={!valid}
                    onClick={onSubmit}
                    iconClass={isSubmitLoading ? "fas fa-spinner fa-spin" : ""}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
