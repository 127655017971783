import {
  PPREnquiryIdentityPacket,
  Register,
} from "@app/products/town-planning/ppr/enquiries/_id/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { UITriggerRegister } from "@common/constants/uiTrigger";

export const getPPREnquiryById = async (
  pprEnquiryId: number
): Promise<APIResponse<Register | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Register>(
      `api/tp/ppr/internal/enquiry/${pprEnquiryId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postPPREnquiry = async (
  requestBody: Register,
  parentID?: number,
  parentRecordType?: RECORDTYPE
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post<Register>(
      `api/tp/ppr/internal/enquiry`,
      requestBody,
      {
        params: {
          parentID,
          parentRecordType,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putValidateAndSaveRegister = async (
  id: number,
  workflow: UITriggerRegister,
  saveEnquiries: boolean,
  register: Register
): Promise<APIResponse<PPREnquiryIdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<PPREnquiryIdentityPacket>(
      `/api/tp/ppr/internal/enquiry/${id}/validatesave?saveEnquiries=${saveEnquiries}&workflow=${workflow}`,
      register
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
