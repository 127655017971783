import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DTO_DeferredDuty_Balance } from "@app/products/property/deferred-duty/[id]/components/child-screens/balances/model";

export const getDeferredDutyBalances = async (
  id: number
): Promise<APIResponse<DTO_DeferredDuty_Balance>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_DeferredDuty_Balance>(
      `api/property/internal/deferredduty/account/${id}/balance`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
