import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colNominateProperty: IColumnFields[] = [
  {
    field: "EffectiveDate",
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "FinishDate",
    title: "Finish Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "AssessmentId",
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: "PropertyAddress", title: "Property Address" },
  {
    field: "NominatedId",
    title: "Nominated Property ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
export const colVotingEntitlementByElection: IColumnFields[] = [
  { field: "Election", title: "Election" },
  { field: "CouldVote", title: "Could Vote?", format: BOOLEAN_FORMAT.BOOLEAN },
  { field: "Role", title: "Role" },
  {
    field: "AssessmentId",
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: "PropertyAddress", title: "Property Address" },
];
