import { ComplianceStatus } from "@app/products/property/assessments/compliance/component/dialog/create-request-status/model";
import { CreateRequestDialog } from "@app/products/property/assessments/compliance/component/dialog/create-request-status/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface INoReviewRequiredButtonProps {
  buttonName?: string;
}

export const NoReviewRequiredButton = observer(
  ({ buttonName = "No Review Required" }: INoReviewRequiredButtonProps) => {
    const { isEmptyGridSelectedRow } = useCCProductListViewStore();
    const [isOpenNoReview, setIsOpenNoReview] = useState(false);
    return (
      <>
        <CCNavButton
          title={buttonName}
          disabled={isEmptyGridSelectedRow}
          onClick={() => setIsOpenNoReview(true)}
        />
        {isOpenNoReview && (
          <CreateRequestDialog
            onClose={() => {
              setIsOpenNoReview(false);
            }}
            requestStatus={ComplianceStatus.No_Review_Required}
          />
        )}
      </>
    );
  }
);
