import { eventEmitter } from "@/App";
import { isSuccessResponse } from "@common/apis/util";
import { deleteReport } from "@common/pages/report/integrated-reports/component/buttons/delete-report/api";
import { IntegratedReport } from "@common/pages/report/integrated-reports/custom-reports/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useState } from "react";

interface DeleteReportButtonProps {
  disabled?: boolean;
}
export const DeleteReportButton = observer(
  ({ disabled }: DeleteReportButtonProps) => {
    const { isEmptyGridSelectedRow, gridSelectedRows, setGridSelectedRows } =
      useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();
    const [isOpenConfirmDialog, setIsOpenConfirmDialog] =
      useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDelete = async () => {
      const selectedList = gridSelectedRows ? [...gridSelectedRows] : [];
      const getListIds = selectedList.map((item: IntegratedReport) => item.ID);
      const defaultTitleSuccess = `${pluralize(
        "Report",
        getListIds?.length
      )} deleted successfully`;
      const defaultTitleFailed = `${pluralize(
        "Report",
        getListIds?.length
      )} could not be deleted`;

      if (!getListIds) return;
      setIsLoading(true);
      await deleteReport(getListIds).then((res) => {
        if (isSuccessResponse(res)) {
          setIsLoading(false);
          if (res?.data?.SuccessIDs?.length > 0) {
            setGridSelectedRows([]);
            eventEmitter.emit(CCGridEventType.RefreshOData);
            pushNotification({
              title: defaultTitleSuccess,
              type: "success",
            });
          } else {
            pushNotification({
              title: defaultTitleFailed,
              type: "error",
            });
          }
        } else {
          setIsLoading(false);
          pushNotification({
            title: defaultTitleFailed,
            type: "error",
          });
        }
        setIsOpenConfirmDialog(false);
      });
    };

    return (
      <>
        <CCNavButton
          title="Delete Report"
          disabled={isEmptyGridSelectedRow || disabled}
          onClick={() => setIsOpenConfirmDialog(true)}
        />
        {isOpenConfirmDialog && (
          <ConfirmDialog
            isLoadingYes={isLoading}
            title="Confirm Delete"
            subMessage="Are you sure you want to delete this report? Note this operation cannot be undone."
            height="auto"
            width="35%"
            onClosePopup={() => setIsOpenConfirmDialog(false)}
            onAsyncConfirm={handleDelete}
          />
        )}
      </>
    );
  }
);
