import { IAuthHeader } from "@common/models/auth";
import { globalStoreInstance } from "../stores/global/store";

export default function authHeader(): IAuthHeader | null {
  const userData = JSON.parse(localStorage.getItem("userData") || "null");
  if (userData && userData.token) {
    return {
      Authorization: "Bearer " + userData.token,
      "X-API-KEY": globalStoreInstance.allSetting.apiKey + "",
    };
  } else {
    return null;
  }
}
