import { IOrgUnit } from "@app/products/town-planning/ppr/[id]/components/input-picker/pick-org-structure/model";
import { CorporateAuth } from "@common/pages/settings/security/corporate-authorisations/_id/model";
import { Svc_SecurityTemplate } from "@common/pages/settings/security/security-templates/model";
import {
  HomePageFunction,
  HomePageView,
  SiteUserView,
} from "@common/pages/settings/security/site-users/model";
import { SITE_USER_ROUTE } from "@common/pages/settings/security/site-users/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<SiteUserView>();
const nameOfOrgUnit = nameOfFactory<IOrgUnit>();
const nameOfSecurityTemplate = nameOfFactory<Svc_SecurityTemplate>();
const nameOfCorpAuth = nameOfFactory<CorporateAuth>();
const nameOfHomepageShortcuts = nameOfFactory<HomePageFunction>();
const nameOfHomepageViews = nameOfFactory<HomePageView>();

export const colSettingsSiteUsers: IColumnFields[] = [
  {
    field: nameOf("UserName"),
    title: "Login Name",

    locked: true,
    linkTo: (dataItem) => {
      return `${SITE_USER_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: nameOf("FirstName"),
    title: "First Name",
  },
  {
    field: nameOf("LastName"),
    title: "Last Name",
  },
  {
    field: nameOf("Email"),
    title: "Email",
  },
  {
    field: nameOf("SecurityTemplates"),
    title: "Security Templates",
  },
  {
    field: nameOf("IsActive"),
    title: "Active",
  },
  {
    field: nameOf("OnLeave"),
    title: "On Leave",
  },
  {
    field: nameOf("IsLocked"),
    title: "Locked",
  },
  {
    field: nameOf("IsExternalUser"),
    title: "Ext. User",
  },
  {
    field: nameOf("IsCouncillor"),
    title: "Councillor",
  },
];

export const colOrgUnits: IColumnFields[] = [
  { title: "Org Unit", field: nameOfOrgUnit("Hierarchy") },
];

export const colPickSecurityTemplates: IColumnFields[] = [
  { title: "Template Name", field: nameOfSecurityTemplate("TemplateName") },
];

export const colPickCorpAuthorization: IColumnFields[] = [
  {
    title: "Name",
    field: nameOfCorpAuth("AuthorisationName"),
  },
];

export const colPickHomepageShortcuts: IColumnFields[] = [
  {
    title: "Shortcut",
    field: nameOfHomepageShortcuts("FunctionHeading"),
  },
];

export const colPickHomepageViews: IColumnFields[] = [
  {
    title: "View",
    field: nameOfHomepageViews("ViewHeading"),
  },
];
