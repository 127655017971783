import { Svc_PermitTypeFees } from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_PermitTypeFees>();

export const colPermitTypeFees: IColumnFields[] = [
  {
    field: nameOf("FeeDescription"),
    title: "Description",
    editable: true,
    minWidth: 300,
  },
  {
    field: nameOf("FeeAmount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    editable: true,
    minWidth: 200,
  },
  {
    field: nameOf("FeeDimension1"),
    title: "Multiplier",
    minWidth: 200,
  },
  {
    field: nameOf("FeeDimension2"),
    title: "Multiplier 2",
    minWidth: 200,
  },
  {
    field: nameOf("FeeMinAmount"),
    title: "Min ($0 for no min)",
    format: CURRENCY_FORMAT.CURRENCY1,
    editable: true,
  },
  {
    field: nameOf("FeeMaxAmount"),
    title: "Max ($0 for no max)",
    format: CURRENCY_FORMAT.CURRENCY1,
    editable: true,
  },
];
