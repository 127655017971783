import { UITownPlanning } from "@app/products/town-planning/model";
import WithdrawConfirmDialog from "@app/products/town-planning/ppr/components/action-bar/dialogs/withdraw-confirm/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { submitWithdrawComment } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/withdraw-application/api";
import { WithdrawReasonDialog } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/withdraw-reason/_index";
import { IPSARWithdraw } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/withdraw-reason/model";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import {
  appNotificationStore,
  useCCAppNotificationStore,
} from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

const withdrawValidatorList = [
  Application_Status.Lodged,
  Application_Status.RevisionReceived,
  Application_Status.AllocatedToPlanner,
  Application_Status.PreliminaryAssessment,
  Application_Status.Referral,
  Application_Status.TeamLeaderDecisionPending,
  Application_Status.Refused,
  Application_Status.ReadyToSendToCouncilForAuthorisation,
  Application_Status.FurtherInfoReceived,
  Application_Status.FurtherInfo,
];
export const WithDrawApplicationButton = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowWithDrawDialog, setIsShowWithDrawDialog] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const { clearNotifications } = useCCAppNotificationStore();
  const { ppr, onSubmit, setPPRWithLoading } = usePSARStore();

  const isVisible = useMemo(() => {
    return withdrawValidatorList.some((item) => {
      if (
        Application_Status.Lodged === ppr?.Status_ENUM &&
        !ppr?.Flag_PlansToComply
      )
        return true;
      return item === ppr?.Status_ENUM;
    });
  }, [ppr]);

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.Withdraw.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const handleSubmitWithdraw = async (withdrawData: IPSARWithdraw) => {
    if (withdrawData.ApplicationID) {
      setIsLoading(true);
      const response = await submitWithdrawComment(withdrawData);
      setIsShowWithDrawDialog(false);
      setIsLoading(false);
      if (isSuccessIdentityPacket(response) && response.data?.Application) {
        clearNotifications();
        setPPRWithLoading(response.data.Application);
        appNotificationStore.pushNotification({
          type: "success",
          title: "Application withdrawn successfully",
        });
      } else {
        setNotifications([
          {
            id: "1",
            title: "Withdraw application failed",
            type: "error",
            autoClose: false,
            description: response.data?.Errors ?? response.statusText,
          },
        ]);
      }
    }
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Withdraw application"
        onClick={onSubmit}
        isLoading={isLoading}
        disabled={isLoading}
        name={PSARSubmitActions.WithdrawnApplication}
      />

      {isShowDialog && (
        <WithdrawConfirmDialog
          onClosePopup={() => {
            setIsShowDialog(false);
          }}
          onConfirmYes={() => {
            setIsShowDialog(false);
            setIsShowWithDrawDialog(true);
          }}
        />
      )}

      {isShowWithDrawDialog && (
        <WithdrawReasonDialog
          onClose={() => {
            setIsShowWithDrawDialog(false);
          }}
          isLoading={isLoading}
          onSubmit={handleSubmitWithdraw}
          notifications={notifications}
        />
      )}
    </>
  ) : null;
});
