export enum JournalStatusName {
  Incomplete = "Incomplete",
  Cancelled = "Cancelled",
  ToBeAuthorised = "To be Authorised",
  Authorised = "Authorised",
  Completed = "Completed",
}

export interface DTO_Journal {
  Journal_Status: JournalStatus;
  Officer_Name: string;
  Officer_Id: number;
  Jnl_Credit_Total: number;
  Jnl_Debit_Total: number;
  Jnl_No_of_Transactions: number;
  Jnl_Notes: string;
  Supplementary_Id: number | null;
  Jnl_Reference: string;
  Jnl_DateTime: Date;
  Jnl_Can_Be_Authorised: boolean | null;
  Assigned_to_Authorise_Officer_Id: number | null;
  Authorising_Officer_Id: number | null;
  Journal_Type: number;
  Journal_Number: number;
  Jnl_Description: string;
}

export enum JournalStatus {
  Incomplete = 0,
  TobeAuthorised = 1,
  Authorised = 2,
  Cancelled = 9,
}
