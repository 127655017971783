import {
  DTO_NoticeRun_LOVs,
  DTO_Workflow_NoticeRunCreate,
  IFilterLOVsNoticeRun,
  RequestNoticeRunCreateObj,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postNewNoticeRun = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_NoticeRunCreate
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_NoticeRunCreate>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/int/workflow/noticerun/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataNewCreateNoticeRun =
  | [APIResponse<DTO_NoticeRun_LOVs>, APIResponse<DTO_Workflow_NoticeRunCreate>]
  | APIResponse<DTO_NoticeRun_LOVs | DTO_Workflow_NoticeRunCreate>;

export const getInitialDataNewNoticeRun = async (
  workflowDraftId: number | undefined,
  componentNumber: number = 1
): Promise<IGetInitialDataNewCreateNoticeRun> => {
  try {
    return await Promise.all([
      getLovNoticeRun(),
      !isNil(workflowDraftId)
        ? getWorkflowNewNoticeRun(workflowDraftId)
        : getNewWorkflowNewNoticeRun({
            ComponentNumber: componentNumber, //default value
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowNewNoticeRun = async (
  object: RequestNoticeRunCreateObj
): Promise<APIResponse<DTO_Workflow_NoticeRunCreate>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/workflow/noticerun/new`,
      object
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getLovNoticeRun = async (
  objectFilter?: IFilterLOVsNoticeRun
): Promise<APIResponse<DTO_NoticeRun_LOVs>> => {
  try {
    return CoreAPIService.getClient().get<DTO_NoticeRun_LOVs>(
      `api/property/int/noticerun/lovs`,
      {
        params: objectFilter,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowNewNoticeRun = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_NoticeRunCreate>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/int/workflow/noticerun/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
