import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";
import {
  DTO_Assessment_AmountsOwing,
  DTO_Assessment_AmountsOwingViews,
} from "./model";

export const loadPropertyAssessmentAmountOwing = async (
  assessmentId: number,
  viewId?: number,
  isShowPaidCharges?: boolean
): Promise<DTO_Assessment_AmountsOwing | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Assessment_AmountsOwing>(
        `/api/property/internal/assessment/${assessmentId}/amountsowing`,
        {
          params: {
            view: viewId,
            showPaidCharges: isShowPaidCharges,
          },
        }
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const loadPropertyAssessmentAmountOwingViews = async (): Promise<
  DTO_Assessment_AmountsOwingViews | undefined
> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Assessment_AmountsOwingViews>(
        `api/property/internal/assessmentamountsowingviews`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
