import { AppealHearingForm } from "@common/pages/appeal/_id/appeal-hearing/_id/components/child-screens/general/_index";
import { AppealHearingSubmitActions } from "@common/pages/appeal/_id/appeal-hearing/_id/model";
import { useAppealHearingStore } from "@common/pages/appeal/_id/appeal-hearing/_id/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCPanelsBar } from "@components/cc-panels-bar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewAppealHearing = observer(() => {
  const { isLoading, onSubmit } = useAppealHearingStore();
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Appeal Hearing"} />

      <FormTitle title="New Appeal Hearing" />

      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Save"}
            onClick={onSubmit}
            name={AppealHearingSubmitActions.New}
          />,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <div className="cc-manage-form-body">
            <AppealHearingForm />
            <CCPanelsBar initialPanels={[]} />
          </div>
        </div>
      </div>
    </>
  );
});
