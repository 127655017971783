import { DTO_LOV } from "@common/models/odataResponse";

//TODO: Remove when others refer are integrate api
export interface INoticeRunResponse {
  Id: number;
  Name: string;
  IssueDate: Date | string;
  Type: string;
  RatingPeriod: string;
  NoticeOrder: string;
  NumberOfNotices: number;
  NumberOfAssessments: number;
  NoticesProducedOn: Date | string;
  NoticesProducedBy: string;
  Balance: number;
  InterestBalance: number;
  TotalBalance: number;
  ArrearsBalance: number;
  ArrearsInterestBalance: number;
  CurrentBalance: number;
  PostponedBalance: number;
  PostponedInterestBalance: number;
  CreatedBy: string;
  CreatedOn: Date | string;
  MinimumNoticeAmount: number;
  IncludeIfNotInChargeRun: boolean;
  IncludeBalances: boolean;
  IncludeUngroupedNotices: boolean;
  ExcludeArrangements: boolean;
  ExcludeIfNoticeProducedSince: string;
  ExcludeAssessmentsWithTransfersAfter: string;
  ExcludeIfWithinDiscountPeriod: boolean;
  ExcludeAssessmentsWithArrangements: boolean;
  ExcludeAssessmentsWithRebates: boolean;
  ExcludeAssessmentsWithoutRebates: boolean;
  ExcludeInstalments: boolean;
  ExcludeIfInterestExempt: boolean;
  SetTransactionDateToIssueDate: boolean;
  OnlyIncludeAssessmentsPayingInFull: boolean;
  NoticeGroups: string;
  FinancialArea: string;
  Journals: string;
  Supplementaries: string;
  Instalments: string;
  Collections: string;
}
//New ĐTO
export interface DTO_NoticeRun_Summary {
  ExcludeTransfersAfter: Date | null;
  Journals: string[];
  FinancialAreas: string[];
  NoticeGroups: string[];
  IncludeUngroupedNotices: boolean;
  IncludeBalances: boolean;
  IncludeIfNotInChargeRun: boolean;
  ExcludeIfInterestExempt: boolean;
  ExcludeInstalments: boolean;
  ExcludeAssessmentsWithoutRebates: boolean;
  ExcludeAssessmentsWithRebates: boolean;
  ExcludeArrangements: boolean;
  ExcludeIfDiscountPeriod: boolean;
  ExcludeIfNoticeSince: Date | null;
  InstalmentPlans: string[];
  Supplementaries: string[];
  MinimumNoticeAmount: number | null;
  ProduceBy: string;
  IssueDate: Date;
  ProducedOn: Date | null;
  CreatedBy: string;
  CreatedOn: Date;
  NumberOfDuplicateNotices: number | null;
  NumberOfAssessments: number | null;
  NumberOfNotices: number | null;
  RatingPeriod: string;
  DocumentTemplate: string;
  NoticeOrder: string;
  NoticeTypeName: string;
  NoticeName: string;
  NoticeRunId: number;
  NoticeRunTotal: DTO_NoticeRunTotal[];
  Collections: string[];
  SupplementariesData: string;
  CollectionsData: string;
  InstalmentPlansData: string;
  NoticeGroupsData: string;
  FinancialAreasData: string;
  JournalsData: string;
  OutputHistory: DTO_NoticeOutputHistory[];
}

export interface DTO_NoticeRunTotal {
  Description: string;
  Balance: number | null;
  InterestBalance: number | null;
  Total: number | null;
  ID: number;
}

export const listFieldTextArea = [
  "Supplementaries",
  "Journals",
  "FinancialAreas",
  "NoticeGroups",
  "InstalmentPlans",
  "Collections",
];

export interface DTO_NoticeRun_LOVs {
  NoticeTypes: DTO_LOV_NoticeType[];
  NoticeGroups: DTO_LOV[];
  AssessmentGroups: DTO_LOV[];
  RatingPeriods: DTO_LOV[];
  ChargeRun: DTO_LOV[];
  InstalmentPlans: DTO_LOV[];
  Collections: DTO_LOV[];
  FinancialAreas: DTO_LOV[];
  FinancialGroups: DTO_LOV[];
  NoticeOrdering: DTO_LOV_NoticeOrdering[];
  DirectDebitScheme: DTO_LOV[];
  FinancialGroupId: number | null;
}

export interface DTO_LOV_NoticeType {
  Code: string;
  Name: string;
  ComponentNumber: number;
  AssessmentSelectionCriteriaGroupVisibility: boolean;
  AssessmentExclusionsVisibility: boolean;
  SetTransactionDateToIssueDateVisibility: boolean;
  JournalGroupVisibility: boolean;
  SupplementaryGroupVisibility: boolean;
  ChargeRunVisibility: boolean;
  InstalmentPlanVisibility: boolean;
  FireServicesValuationTypeVisibility: boolean;
  FireServicesReconciliationFieldsVisibility: boolean;
  RegisterSelectionCriteriaGroupVisibility: boolean;
  FireAndEmergencyServicesLevySelectionCriteriaGroupVisibility: boolean;
  VictorianFireServicesPropertyLevySelectionCriteriaGroupVisibility: boolean;
}

export interface DTO_LOV_NoticeOrdering {
  Code: string;
  Name: string;
  ComponentNumber: number;
}

export interface VO_Notice_Assessment_NoticeGroup {
  Notice_Run_Id: number;
  Notice_Name: string;
  Property_Address: string;
  Locality_Name: string;
  Assess_Property_Address: string;
  Assess_Property_Name: string;
  Assess_Reference: string;
  Assess_Number: number | null;
  Notice_Group_Id: number | null;
  NA_Total_Balance: number | null;
  NA_Balance: number | null;
  NA_Current_Interest_Balance: number | null;
  NA_Current_Balance: number | null;
  NA_Arrears_Interest_Balance: number | null;
  NA_Arrears_Balance: number | null;
  Notice_Id: number | null;
  Assessment_Id: number | null;
  Notice_Assessment_Id: number;
  NA_Interest_Balance: number | null;
  Notice_Group_Name: string;
}

export interface DTO_NoticeOutputHistory {
  ProduceOn: Date;
  ProduceBy: string;
  NoticeGroup: string;
  OutputFormat: string;
  FileName: string;
  ID: number;
}
