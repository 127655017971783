import { DTO_AssociatedItem_AssessmentRebateEntitlement } from "@app/products/property/components/associations/components/assessment-rebate-entitlement/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_AssessmentRebateEntitlement>();

export const colAssociationsAssessmentRebateEntitlement: IColumnFields[] = [
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("RebateType"),
    title: "Rebate Type",
  },
  {
    field: nameOf("Percentage"),
    title: "Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("ApplicationDate"),
    title: "Application Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("EffectiveDate"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("LossOfEligibilityDate"),
    title: "Loss of Eligibility Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("EntitledToAlternateRebateCap"),
    title: "Entitled to Alternate Rebate Cap",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Notes"),
    title: "Notes",
  },
  {
    field: nameOf("EntitledEntitiesAsString"),
    title: "Entitled Contacts As String",
  },
  {
    field: nameOf("ChangedOn"),
    title: "Changed On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ChangedBy"),
    title: "Changed By",
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
