import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSFeesExportedFees: IColumnFields[] = [
  {
    field: CRSFieldMapping.Registration_ReferenceNo,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.Child_DisplayName, title: "Child Name" },
  {
    field: CRSFieldMapping.Registration_DebtorNumber,
    title: "Debtor Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.Service, title: "Service" },
  {
    field: CRSFieldMapping.YearAttending,
    title: "Year Att",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.Term, title: "Term" },
  { field: CRSFieldMapping.InvoiceNumber, title: "Invoice Number" },
  { field: CRSFieldMapping.FeeType, title: "Type" },
  { field: CRSFieldMapping.Fee_Description, title: "Description" },
  { field: CRSFieldMapping.FeeType_Code, title: "Cost Code" },
  { field: CRSFieldMapping.Amount, title: "Amount" },
  { field: CRSFieldMapping.AmountPaid, title: "Paid" },
  { field: CRSFieldMapping.InvoicedDate, title: "Invoice Date" },
  { field: CRSFieldMapping.BPayReference, title: "BPAY Reference" },
  { field: CRSFieldMapping.Sys_CreatedDate, title: "Recorded" },
  {
    field: CRSFieldMapping.ExportedToFinanceDate,
    title: "Exported to Finance",
  },
];
