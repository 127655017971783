import {
  DTO_AssessmentLandRentAction,
  DTO_AssessmentLandRentAction_LOVs,
} from "@app/products/property/assessments/components/dialogs/assessment-land-rent-action/model";

export const assessmentLandRentActionMock: DTO_AssessmentLandRentAction = {
  Assessment_Id: 100001,
  Rating_Period: 1,
  Period_Start_Date: new Date(),
  Period_End_Date: new Date(),
  Land_Rent_Action_Type: 2,
  Actioned_Date: new Date(),
  Valuation: 1500000000,
};

export const assessmentLandRentActionLOVsMock: DTO_AssessmentLandRentAction_LOVs =
  {
    RatingPeriod: [
      {
        Code: "1",
        Name: "Mock rating period 1",
      },
      {
        Code: "2",
        Name: "Mock rating period 2",
      },
    ],
    LandRentActionType: [
      {
        Code: "1",
        Name: "Mock land rent action type 1",
      },
      {
        Code: "2",
        Name: "Mock land rent action type 2",
      },
    ],
  };
