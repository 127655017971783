import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colEntitlementCalculation: IColumnFields[] = [
  {
    field: "EntitlementId",
    title: "Entitlement ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "RatingPeriod",
    title: "Rating Period",
  },
  {
    field: "RebateType",
    title: "Rebate Type",
  },
  {
    field: "PercentageOfFull",
    title: "% of Full",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: "RebateAmount",
    title: "Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "PercentageOfPeriod",
    title: "% of Period",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: "ProrataRebate",
    title: "Prorata Rebate",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "ExistingRebates",
    title: "Existing Rebates",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Difference",
    title: "Difference",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Adjustment",
    title: "Adjustment",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "ExistingPeriod",
    title: "Existing (Period)",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
