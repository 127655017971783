import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingInspectionsByDate: IColumnFields[] = [
  {
    field: BuildingFieldMapping.DateInspected,
    title: "Inspection Date",
    format: DATE_FORMAT.DATE,
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.PermitNumber,
    title: "Permit Number",
  },
  {
    field: BuildingFieldMapping.InspectionType,
    title: "Type",
  },
  {
    field: BuildingFieldMapping.Officer,
    title: "Officer",
  },
  {
    field: BuildingFieldMapping.Compliance,
    title: "Compliance",
  },
  {
    field: BuildingFieldMapping.Reason,
    title: "Reason",
  },
];
