import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingPoolsSpasRenewalsNoticeSent: IColumnFields[] = [
  {
    field: BuildingFieldMapping.Register_Number,
    title: "Number",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.Date_Lodged,
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Address,
    title: "Address",
  },
  {
    field: BuildingFieldMapping.Locality,
    title: "Locality",
  },
  {
    field: BuildingFieldMapping.Contact,
    title: "Contact",
  },
  {
    field: BuildingFieldMapping.Type,
    title: "Type",
  },
  {
    field: BuildingFieldMapping.Description,
    title: "Description",
  },
  {
    field: BuildingFieldMapping.Status,
    title: "Status",
  },
  {
    field: BuildingFieldMapping.Officer,
    title: "Officer",
  },
  {
    field: BuildingFieldMapping.ReferenceNumber,
    title: "Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: BuildingFieldMapping.Date_Complete,
    title: "Decided",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.LegalDescription,
    title: "Legal Description",
  },
  {
    field: BuildingFieldMapping.Flag_RenewalNoticeSent,
    title: "Notice Sent",
  },
];
