import { newOwnerCols } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/names/config";
import { INewOwners } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/names/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

//@TODO: Mock, will be handled in future task
const nameOfNewOwners = nameOfFactory<INewOwners>();
export const NamesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(() => {
  return (
    <div className="cc-field">
      <CCLabel title="New owners" />
      <CCGrid
        data={[]}
        columnFields={newOwnerCols}
        primaryField={nameOfNewOwners("Entity_Name_Address_Id")}
      />
    </div>
  );
});
