import { IAssessmentCollectionStepsLOVs } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class AssessmentCollectionStepStore {
  private _assessmentCollectionStepLOVs?: IAssessmentCollectionStepsLOVs =
    undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get assessmentCollectionStepLOVs() {
    return this._assessmentCollectionStepLOVs;
  }
  setAssessmentCollectionStepLOVs = (
    assessmentCollectionStepLOVs?: IAssessmentCollectionStepsLOVs
  ) => {
    runInAction(() => {
      this._assessmentCollectionStepLOVs = assessmentCollectionStepLOVs;
    });
  };
}

const assessmentCollectionStepStoreContext = createContext(
  new AssessmentCollectionStepStore()
);
export const useAssessmentCollectionStepStore = () => {
  return useContext(assessmentCollectionStepStoreContext);
};
