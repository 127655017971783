export interface IComplianceCandidateLandRent {
  "Assessment(S-S-B-U)": string;
  Ratepayer?: string;
  Address?: string;
  PropertyAddress?: string;
  LastChangeOfOwnership?: string;
  LastComplianceDate?: string;
  LandTaxExemptionId?: number;
  NameAddressId?: number;
  AssessmentId?: number;
  ActiveFrom?: string;
  ActiveTo?: string;
  CreatedOn?: string;
  CreatedBy?: string;
  ExemptionType?: string;
}
export interface VO_Candidate_LandRent_Without_AnnualReview {
  Assessment_Id: number;
  Property_Name_Address_Locality: string;
  NA_Name: string;
  NA_Care_of: string;
  Last_Compliance_Date: string | null;
  Name_Address_Id: number;
  NA_Address_Locality: string;
  Assess_Reference: string;
}
export interface VO_Candidate_LandRent {
  Assessment_Id: number;
  Property_Name_Address_Locality: string;
  NA_Name: string;
  NA_Care_of: string;
  Last_Compliance_Date: string | null;
  Name_Address_Id: number;
  Last_Change_of_Ownership_Date: string | null;
  NA_Address_Locality: string;
  Assess_Reference: string;
}
export enum eOptionsAssessment {
  ASSESSMENT_WITH_REVIEW = 0,
  ASSESSMENT_WITHOUT_REVIEW = 1,
}
export interface IPopulateCandidateLandRentDataDialog {
  FromAssessment?: string;
  ToAssessment?: string;
  Assessment?: eOptionsAssessment;
}
