import { Register } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const putCreateApplication = async (
  register: Register
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<IdentityPacket>(
      `api/tp/ppr/internal/enquiry/createapplication`,
      register
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
