import { KeyValuePair } from "@app/core/documents/model";
import { InspectionType } from "@app/core/inspections/[id]/model";
import { IIdentityPacket } from "@common/models/identityPacket";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";

export interface InspectionTypeLovs {
  InspClass: KeyValuePair<number, string>[];
  FeeType: IKeyValuePacket[];
  FSRCode: IKeyValuePacket[];
  DeductionType: IKeyValuePacket[];
  DefaultAffectSchedule: IKeyValuePacket[];
  Group: KeyValuePair<string, string>[];
  InspectionReport: IKeyValuePacket[];
  ActionType: IKeyValuePacket[];
  SystemFilters: IKeyValuePacket[];
}

// #region Inspection Types Form
export type TApiInspectionType = {
  Lovs: IIdentityPacket<InspectionTypeLovs>;
  Value: IIdentityPacket<InspectionType>;
};
// #endregion

export const nameOfIKeyValuePacket = nameOfFactory<IKeyValuePacket>();
export const nameOfIKeyValuePair =
  nameOfFactory<KeyValuePair<string, string>>();
