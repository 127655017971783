import { LOCAL_LAWS_PERMIT_ROUTE } from "@app/products/local-laws/[id]/constant";
import {
  IGenerateFinancesColumnsProps,
  PermitFinances,
  nameOfPermitFinances,
} from "@app/products/local-laws/finance/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { PROPERTYSYSTEM, RECORDSSYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

export const colLocalLawsFinanceDebtorSummaryAll: IColumnFields[] = [
  {
    field: nameOfPermitFinances("PermitNumber"),
    title: "App Number",
    locked: true,
    linkTo: (dataItem: PermitFinances) => ({
      pathname: `${LOCAL_LAWS_PERMIT_ROUTE}/` + dataItem.ID,
    }),
  },
  { field: nameOfPermitFinances("DebtorNumber"), title: "Debtor Number" },
  /**
   * Need to confirm and implement later
   * Reason: Radium UX has this column but query select column does not have this column
   */
  { field: nameOfPermitFinances("FileNumber"), title: "File Number" },
  {
    field: nameOfPermitFinances("AssessmentNo"),
    title: "Assessment Number",
  },
  {
    field: nameOfPermitFinances("AmountOS"),
    title: "Customer Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  /**
   * Need to confirm and implement later
   * Reason: Radium UX has this column but query select column does not have this column
   * Only show on UI, don't test
   */
  {
    field: nameOfPermitFinances("AppBalance"),
    title: "App Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfPermitFinances("NARID"),
    title: "NAR ID",
  },
  {
    field: nameOfPermitFinances("CONTACTID"),
    title: "Contact ID",
  },
  {
    field: nameOfPermitFinances("Applicant"),
    title: "Permit Holder",
  },
  {
    field: nameOfPermitFinances("SiteAddress"),
    title: "Address",
  },
  {
    field: nameOfPermitFinances("Locality"),
    title: "Locality",
  },
  {
    field: nameOfPermitFinances("Type"),
    title: "Permit Type",
  },
  {
    field: nameOfPermitFinances("StatusDueStatus"),
    title: "Status",
  },
  {
    field: nameOfPermitFinances("Officer"),
    title: "Officer",
  },
  {
    field: nameOfPermitFinances("EffectiveFrom"),
    title: "Effective From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPermitFinances("EffectiveTo"),
    title: "Effective To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPermitFinances("UsePermitEventName"),
    title: "Event Name",
  },
];

export const generateDebtorSummaryColumnsBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateFinancesColumnsProps) => {
  if (!settings) return defaultConfig;

  const propertySystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_PropertySystem]
  );
  const recordsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
  );
  const registerDisplayDebtorNumber = getBoolValueSetting(
    settings[
      ECorporateSettingsField.CorporateSettings_RegisterDisplayDebtorNumber
    ]
  );
  const isEnableMultiLineInvoice = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
  );

  let columnsClone: IColumnFields[] = cloneDeep(defaultConfig);

  return columnsClone.filter((col) => {
    switch (col.field) {
      case nameOfPermitFinances("AppBalance"):
        return isEnableMultiLineInvoice ?? false;
      case nameOfPermitFinances("DebtorNumber"):
        return registerDisplayDebtorNumber ?? false;
      case nameOfPermitFinances("FileNumber"):
        return recordsSystem !== RECORDSSYSTEM.None;
      case nameOfPermitFinances("AssessmentNo"):
        return propertySystem !== PROPERTYSYSTEM.None;
      default:
        return true;
    }
  });
};
