import {
  IMailMergeButtonProps,
  MailMergeButton,
} from "@app/core/new-mail-merge/buttons/components/buttons/_index";

import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const AnimalIssueRenewalNoticeButton = observer(
  (props: IMailMergeButtonProps) => {
    const { isEmptyGridSelectedRow, gridSelectedRows, gridSelectedIds } =
      useCCProductListViewStore();

    return (
      <MailMergeButton
        {...props}
        isEmptyGridSelectedRow={isEmptyGridSelectedRow}
        gridSelectedRows={gridSelectedRows}
        selectedRecordIds={gridSelectedIds}
      />
    );
  }
);
