import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { getAmendPPR } from "@app/products/town-planning/ppr/[id]/api";
import { useAmendPermitButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/amend-permit/store";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import {
  Application_Decision,
  IPPRApplicationParentSection,
} from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

export const AmendPermitButton = observer(() => {
  const history = useHistory();
  const { ppr } = usePPRStore();
  const { isLoading } = useAmendPermitButtonStore();
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();

  const isVisible = useMemo(() => {
    if (!ppr?.Status_ENUM) return false;
    if (ppr.Status_ENUM === Application_Status.ApplicationComplete) {
      return ppr?.FinalDecision_ENUM === Application_Decision.VRRESPONDED;
    }
    return [
      Application_Status.PermitIssued,
      Application_Status.AmendmentIssued,
      Application_Status.NoticeOfDecision,
      Application_Status.AppealComplete,
    ].includes(ppr.Status_ENUM);
  }, [ppr]);

  const loadApplicationAmendPermit = async () => {
    if (ppr) {
      const response = await getAmendPPR(ppr);
      if (isSuccessResponse(response) && response?.data?.Application) {
        history.push(`${TOWN_PLANNING_PPR_ROUTE}/new`, {
          data: response?.data?.Application,
          isAmendPermit: true,
        } as IPPRApplicationParentSection);
      } else {
        clearErrorNotification();
        pushNotification({
          autoClose: false,
          description: response.data?.Errors ?? response.statusText,
          type: "error",
        });
      }
    } else {
      clearErrorNotification();
      pushNotification({
        autoClose: false,
        description: "Get data failed",
        type: "error",
      });
    }
  };

  const handleOnClickButton = () => {
    loadApplicationAmendPermit();
  };

  return isVisible ? (
    isLoading ? (
      <Loading isLoading={isLoading} isFullScreen />
    ) : (
      <CCNavButton title="Amend permit" onClick={handleOnClickButton} />
    )
  ) : null;
});
