import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSServiceStandardsPersonBookmark = {
  getBookmarkListViewDisplayName() {
    return `${CRMS_PRODUCT_NAME} - Service Standards`;
  },
  getBookmarkListViewDetail() {
    return `${CRMS_PRODUCT_NAME} - Service Standards - By Action Person`;
  },
};
