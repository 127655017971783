import { HOMEPAGE_SHORTCUTS_ROUTE } from "@common/pages/settings/homepage/homepage-shortcuts/constant";
import { Svc_HomepageShortcuts } from "@common/pages/settings/homepage/homepage-shortcuts/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_HomepageShortcuts>();

export const colHomepageShortcuts: IColumnFields[] = [
  { title: "Shortcut", field: nameOf("FunctionHeading") },
];

export const colHomepageShortcutsListView: IColumnFields[] = [
  {
    title: "Product Type",
    field: nameOf("ProductType_Name"),
    linkTo: (dataItem: Svc_HomepageShortcuts) => {
      return `${HOMEPAGE_SHORTCUTS_ROUTE}/${dataItem.ID}`;
    },
  },
  { title: "Function", field: nameOf("FunctionHeading") },
  { title: "Description", field: nameOf("FunctionDescription") },
];
