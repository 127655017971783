import { INewMeter } from "@app/products/property/meters/list/components/action-bar/dialogs/new-meter/model";

export const mockNewMeter: INewMeter = {
  MeterNumber: null,
  Make: null,
  Type: null,
  PurchaseDate: null,
  NumberOfDials: null,
  MeterSize: null,
  UnitOfMeasurement: null,
  MakeList: [
    { Key: 1, Value: "Davey-Shepherd" },
    { Key: 2, Value: "Elster" },
    { Key: 3, Value: "Email" },
    { Key: 4, Value: "R M C" },
  ],
  TypeList: [
    { Key: 1, Value: "Bypass" },
    { Key: 2, Value: "Fire Hydrant" },
    { Key: 3, Value: "Mains" },
  ],
  MeterSizeList: [
    { Key: 1, Value: "100KL" },
    { Key: 2, Value: "150KL" },
    { Key: 3, Value: "15KL" },
    { Key: 4, Value: "20KL" },
    { Key: 5, Value: "25KL" },
    { Key: 6, Value: "30KL" },
    { Key: 7, Value: "32KL" },
    { Key: 8, Value: "40KL" },
  ],
  UnitOfMeasurementList: [
    { Key: 1, Value: "Corrected Volume" },
    { Key: 2, Value: "Cubic Meter" },
    { Key: 3, Value: "Kilolitre" },
    { Key: 4, Value: "Kilowatt" },
    { Key: 5, Value: "Mega Joule" },
  ],
};
