import { ICCRoute } from "@common/constants/ICCRoute";

export const threeY0KinderRoute: ICCRoute = {
  path: "3yo-kinder",
  name: "3YO Kinder",
  children: [
    {
      path: "all-by-preferences",
      name: "All By Preferences",
      component: require("./all-by-preferences/_index").default,
    },
    {
      path: "all-by-weighting",
      name: "All By Weighting",
      component: require("./all-by-weighting/_index").default,
    },
    {
      path: "all-by-status",
      name: "All By Status",
      component: require("./all-by-status/_index").default,
    },
    {
      path: "wait-list-by-centre",
      name: "Wait List by Centre",
      component: require("./wait-list-by-centre/_index").default,
    },
    {
      path: "wait-list-by-group",
      name: "Wait List by Group",
      component: require("./wait-list-by-group/_index").default,
    },
    {
      path: "offers",
      name: "Offers",
      component: require("./offers/_index").default,
    },
    {
      path: "placements",
      name: "Placements",
      component: require("./placements/_index").default,
    },
    {
      path: "deferrals",
      name: "Deferrals",
      component: require("./deferrals/_index").default,
    },
    {
      path: "2nd-year-enrolments",
      name: "2nd Year Enrolments",
      component: require("./2nd-year-enrolments/_index").default,
    },
    {
      path: "cancellations",
      name: "Cancellations",
      component: require("./cancellations/_index").default,
    },
  ],
};
