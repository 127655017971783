import { getEventFormNavigation } from "@app/products/crms/[id]/api";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { IEventsParentSection } from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { NewSimpleEventDialog } from "@app/products/crms/components/dialogs/new-simple-event/_index";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
interface INewEventButtonProps {
  recordType?: RECORDTYPE;
}

export const NewEventButton = observer(
  ({ recordType }: INewEventButtonProps) => {
    const history = useHistory();
    const { pushNotification } = useCCAppNotificationStore();
    const {
      isShowNewSimpleEventDialog,
      setIsShowNewSimpleEventDialog,
      loadCRMSSimpleEvent,
    } = useCRMSEventStore();
    const { cancelToken, cancelRequest } = useCancelRequest();
    const [isLoading, setIsLoading] = useState(false);

    const handleCheckFormNavigation = async () => {
      setIsLoading(true);
      const response = await getEventFormNavigation();

      if (isSuccessResponse(response) && response.data) {
        if (response.data.ReturnObj.isSimpleForm) {
          loadCRMSSimpleEvent(cancelToken());
          setIsShowNewSimpleEventDialog(true);
        } else {
          let state = undefined;
          switch (recordType) {
            case RECORDTYPE.Animals_Registration:
              state = {
                isFromAnimals: true,
              } as IEventsParentSection;
              break;
            case RECORDTYPE.LLP_Permit:
              state = {
                isFromPermit: true,
              } as IEventsParentSection;
              break;
          }
          history.push(`${CRMS_ROUTE}/new`, state);
        }
      } else {
        pushNotification({
          type: "error",
          title: response?.error ?? "Load form navigation failed.",
          autoClose: false,
        });
      }
      setIsLoading(false);
    };

    return (
      <>
        <Loading isLoading={isLoading} isFullScreen />

        <CCNavButton title="New Event" onClick={handleCheckFormNavigation} />
        {isShowNewSimpleEventDialog && (
          <NewSimpleEventDialog
            onClose={() => {
              setIsShowNewSimpleEventDialog(false);
              cancelRequest("Cancel simple event handle request");
            }}
          />
        )}
      </>
    );
  }
);
