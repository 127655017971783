import { eComponent } from "@app/products/property/components/associations/model";
import { getPropertyDocuments } from "@app/products/property/components/child-screen/documents/api";
import { colDocuments } from "@app/products/property/components/child-screen/documents/config";
import {
  DTO_Document,
  DTO_Documents,
} from "@app/products/property/components/child-screen/documents/model";
import { ICCViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class DocumentStore {
  private _documents?: DTO_Document[] = undefined;
  private _selectedDocuments?: DTO_Document[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _colDocument: IColumnFields[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get documents() {
    return toJS(this._documents);
  }
  setDocuments = (documents: DTO_Document[] | undefined) => {
    runInAction(() => {
      this._documents = documents;
    });
  };

  get colDocument() {
    return toJS(this._colDocument);
  }
  setColDocument = (columns: IColumnFields[] = []) => {
    runInAction(() => {
      this._colDocument = columns;
    });
  };

  get selectedDocuments() {
    return toJS(this._selectedDocuments);
  }
  setSelectedDocuments = (selectedDocuments: DTO_Document[]) => {
    runInAction(() => {
      this._selectedDocuments = selectedDocuments;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._documents = undefined;
      this._selectedDocuments = [];
      this._isLoading = false;
    });
  };

  loadDocuments = async (componentNumber: eComponent, componentId: number) => {
    if (isNil(componentNumber) && isNil(componentId)) return;
    this.setIsLoading(true);
    const response = await getPropertyDocuments(componentNumber, componentId);
    let errorResponse = undefined;
    if (
      Array.isArray(response) &&
      response?.[0]?.data &&
      !response?.[0]?.data?.hasOwnProperty("MessageType") &&
      response?.[1]?.data &&
      !response?.[1]?.data?.hasOwnProperty("MessageType")
    ) {
      const [dataDocument, viewConfig] = response;
      this.setDocuments(dataDocument?.data?.Documents ?? []);
      this.setColDocument(
        processDynamicColumns(
          colDocuments,
          viewConfig?.data?.ColumnDefinitions?.Columns ?? []
        )
      );
    } else {
      if (Array.isArray(response)) {
        let responseError: APIResponse<
          DTO_Documents | ICCViewConfiguration | undefined
        > = response?.[0];
        if (!isSuccessResponse(response?.[1]) && response?.[1]) {
          responseError = response?.[1];
        }
        errorResponse = {
          status: responseError?.status,
          error: responseError?.error,
        };
      } else {
        const responseError = response as APIResponse;
        errorResponse = {
          status: responseError.status,
          error: responseError.error,
        };
      }
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  reloadDocuments = async (
    componentNumber: eComponent,
    componentId: number
  ) => {
    if (!isNil(componentNumber) && !isNil(componentId))
      await this.loadDocuments(componentNumber, componentId);
  };
}
const propertyDocumentStoreContext = createContext(new DocumentStore());
export const useDocumentStore = () => {
  return useContext(propertyDocumentStoreContext);
};
