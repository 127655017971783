import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const multiTenancyRoute: ICCRoute = {
  path: "multi-tenancy",
  name: "Multi Tenancy",
  enum: HealthManagerMenu.MultiTenancy,
  children: [
    {
      path: "register-multi-tenancies",
      name: "Multi Tenancies",
      enum: HealthManagerMenu.RegisterMultiTenancy,
      // mock route: not implemented yet
      //component: require("./register-multi-tenancies/_index").default,
    },
    {
      path: "premises-multi-tenancies",
      name: "Premises Multi Tenancies",
      enum: HealthManagerMenu.PremisesMultiTenancy,
      // mock route: not implemented yet
      //component: require("./premises-multi-tenancies/_index").default,
    },
  ],
};
