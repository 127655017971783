import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IParentSection } from "@common/pages/contacts/model";

export const dropdownCertificateTypeData: IKeyValuePacket[] = [
  { Key: 1, Value: "Semi-Professional" },
  { Key: 2, Value: "Professional" },
  { Key: 3, Value: "Expert" },
];

export const parentPage: IParentSection[] = [];
