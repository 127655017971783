import { Button } from "@progress/kendo-react-buttons";
import React from "react";

interface IRemoveConditionsButtonProps {
  onSubmit: () => void;
  isDisabled?: boolean;
}

export const RemoveConditionsButton = ({
  onSubmit,
  isDisabled = false,
}: IRemoveConditionsButtonProps) => {
  return (
    <Button
      type="button"
      iconClass="fas fa-minus"
      title="Remove conditions"
      onClick={() => {
        onSubmit();
      }}
      disabled={isDisabled}
    />
  );
};
