import {
  DTO_ConcessionCard,
  DTO_Entity_Concession_Card,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";

/**
 * @param lovsConcessionCards lovs concession cards
 * @returns concession cards array with default values
 */
const processConcessionCardLovs = (
  lovsConcessionCards: DTO_ConcessionCard[]
) => {
  return lovsConcessionCards.map((concessionCard: DTO_ConcessionCard) => ({
    Id: concessionCard.Id,
    Concession_Card: concessionCard.Name,
    Card_Number: concessionCard.Card_Number,
    Is_Active: false,
    Presented_Date: undefined,
    Confirmation_Date: undefined,
    Card_Number_InputMask: concessionCard.Card_Number_InputMask,
  }));
};

/**
 * @param workflowConcessionCards workflow concession cards
 * @param lovsConcessionCards lovs concession cards
 * @param needToMapLovs flag to check if need to map lovs
 * @returns processed concession cards
 */
export const processConcessionCards = (
  workflowConcessionCards: DTO_Entity_Concession_Card[],
  lovsConcessionCards: DTO_ConcessionCard[],
  needToMapLovs: boolean = false
) => {
  if (!needToMapLovs) {
    return workflowConcessionCards;
  }
  const lovsConcessionCardsProcessed =
    processConcessionCardLovs(lovsConcessionCards);
  return lovsConcessionCardsProcessed.map(
    (concessionCard: DTO_Entity_Concession_Card) => {
      const existedWorkflowConcessionCard = workflowConcessionCards?.find(
        (workflowConcessionCard: DTO_Entity_Concession_Card) => {
          return workflowConcessionCard.Id === concessionCard.Id;
        }
      );
      if (existedWorkflowConcessionCard) {
        return {
          ...concessionCard,
          ...existedWorkflowConcessionCard,
        };
      }
      return concessionCard;
    }
  );
};
