import { DTO_Register_Memos } from "@app/products/property/registers/[id]/components/child-screens/memos/model";

export const mockRegisterMemos: DTO_Register_Memos = {
  Memos: [
    {
      Memo: "Rate Notice CRN 74981 with Check Digit 6 postponed for implementation in the Property.Gov system",
      MemoDate: new Date("2018-07-15T16:30:00Z"),
      Category: "Assessment Memos",
      ChangedOn: "2018-08-05T16:59:00Z",
      ChangedBy: "Steven SPAGNOLO",
      Id: 167827,
    },
    {
      Memo: "Rate Notice Number 100001 with Check Digit 4 prior to August 2006 and in the previous Place rating system",
      MemoDate: new Date("2018-06-06T17:51:00Z"),
      Category: "Assessment Memos",
      ChangedOn: "2018-06-06T17:51:00Z",
      ChangedBy: "Steven SPAGNOLO",
      Id: 162195,
    },
    {
      Memo: "Bank Reference Number 1 with Check Digit 5 from August 2006 to July 2014 in the Property.Gov system",
      MemoDate: new Date("2018-06-06T17:51:00Z"),
      Category: "Assessment Memos",
      ChangedOn: "2018-06-06T17:51:00Z",
      ChangedBy: "Steven SPAGNOLO",
      Id: 167271,
    },
    {
      Memo: "Household rubbish collection w/c 11/5/15 Ref: 1493463, 1493465",
      MemoDate: new Date("2019-05-06T12:40:00Z"),
      Category: "Garbage/Waste Memos",
      ChangedOn: "2019-05-06T12:41:00Z",
      ChangedBy: "Cate ALDER",
      Id: 175712,
    },
    {
      Memo: "Houshold Rubbish Collection w/c 10 March 2014 Ref No: 1276119",
      MemoDate: new Date("2018-04-28T11:13:00Z"),
      Category: "Garbage/Waste Memos",
      ChangedOn: "2018-04-28T11:14:00Z",
      ChangedBy: "Cate ALDER",
      Id: 156466,
    },
    {
      Memo: "16A(1)",
      MemoDate: new Date("2007-07-02T00:00:00Z"),
      Category: "General Information Memos",
      ChangedOn: "2018-04-28T11:13:00Z",
      ChangedBy: "Cate ALDER",
      Id: 113,
    },
    {
      Memo: "BALSOM MR D & MRS J L\r\n20 ABBEY STREET\r\nHUNTERS HILL  NSW  2110",
      MemoDate: new Date("2002-07-06T00:00:00Z"),
      Category: "Previous Owners Memos",
      ChangedOn: "2010-04-28T21:32:00Z",
      ChangedBy: "Stan Mozel (AUSSOFT)",
      Id: 147650,
    },
  ],
};
