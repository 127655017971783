import { ITransactionViewProps } from "@app/products/property/model";
import { VO_Supplementary_Transaction } from "@app/products/property/supplementary-rates/[id]/components/child-screens/transaction/model";
import { useSupplementaryRatesTransactionStore } from "@app/products/property/supplementary-rates/[id]/components/child-screens/transaction/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { SupplementaryTransactionAllocation } from "./components/details/_index";
import "./_index.scss";

const nameOf = nameOfFactory<VO_Supplementary_Transaction>();

export const SupplementaryTransactions = observer(() => {
  const {
    columnFields,
    transactionView,
    isLoading,
    isLoadingGrid,
    responseLoadErrorView,
    showCancelledTransactions,
    viewOption,
    setTransactionView,
    setShowCancelledTransactions,
    resetStore,
    loadViews,
    loadTransactionConfigurationColumns,
  } = useSupplementaryRatesTransactionStore();
  const { id } = useParams<{ id: string }>();

  const odataUrl = useMemo(() => {
    return `odata/property/internal/supplementaryratestransactionsregister/${parseInt(
      id
    )}?&cancelled=${showCancelledTransactions}&view=${
      transactionView?.Id
    }&year=${transactionView?.FinancialYear?.Year ?? 0}&$count=true&`;
  }, [id, showCancelledTransactions, transactionView]);

  useEffectOnce(() => {
    loadViews();
    loadTransactionConfigurationColumns();
    return () => resetStore();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadErrorView)
    return (
      <CCLoadFailed
        responseError={responseLoadErrorView}
        onReload={() => {
          loadViews();
        }}
      />
    );

  return (
    <div className="cc-property-supplementary-transactions">
      <div className="cc-grid-control-left">
        <label className="cc-control-item cc-label">View</label>
        <DropDownList
          className="cc-control-item cc-change-view"
          data={viewOption}
          textField="Name"
          dataItemKey="TempId"
          value={viewOption.find(
            (view: ITransactionViewProps) =>
              view.TempId === transactionView?.TempId
          )}
          onChange={(event: DropDownListChangeEvent) => {
            setTransactionView(event.value);
          }}
        />
        <Checkbox
          className="cc-control-item"
          title="Show Cancelled Transactions"
          label="Show cancelled transactions"
          checked={showCancelledTransactions}
          onChange={(event: CheckboxChangeEvent) => {
            setShowCancelledTransactions(event.value);
          }}
        />
      </div>

      {isLoadingGrid ? (
        <Loading isLoading />
      ) : (
        <CCGrid
          dataUrl={odataUrl}
          columnFields={columnFields}
          primaryField={nameOf("Transaction_Id")}
          detail={SupplementaryTransactionAllocation}
        />
      )}
    </div>
  );
});
