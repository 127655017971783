import { ReportsActionBarNavDropdown } from "@common/pages/report/components/action-bar/nav-dropdown/_index";
import { POWER_BI_URL } from "@common/pages/report/powerbi/custom-reports/constant";
import { powerBIRoute } from "@common/pages/report/powerbi/route";
import { reportsRoute } from "@common/pages/report/route";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { usePermissionStore } from "@common/pages/settings/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.CORE_DASHBOARD_REPORTS,
      1
    );
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: reportsRoute.name,
    leftComponents: [
      <ReportsActionBarNavDropdown category={powerBIRoute.path} />,
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <>
      <CCAppNotification />
      <iframe
        title="Dashboard - Health Manager"
        width="100%"
        height="100%"
        src={hasPermission ? POWER_BI_URL : undefined}
        frameBorder={0}
        allowFullScreen
      />
    </>
  );
};
