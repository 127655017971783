import { getAssessmentRebatesConfigAndData } from "@app/products/property/assessments/[id]/components/child-screens/rebates/api";
import {
  IRebatesAppliedOnAssessment,
  RebatesAppliedOnAssessment,
} from "@app/products/property/assessments/[id]/components/child-screens/rebates/components/applied-on-assessment/_index";
import RebatesConcessionCards, {
  IRebatesConcessionCards,
} from "@app/products/property/assessments/[id]/components/child-screens/rebates/components/concession-cards/_index";
import { useEntitledToRebateStore } from "@app/products/property/assessments/[id]/components/child-screens/rebates/components/entitled-to-rebate/store";
import {
  IRebatesEntitledToRebate,
  RebatesEntitledToRebate,
} from "@app/products/property/assessments/[id]/components/child-screens/rebates/components/entitled-to-rebate/_index";
import { AssessmentRebatesEventType } from "@app/products/property/assessments/[id]/components/child-screens/rebates/constant";
import {
  DTO_Assessment_Rebates,
  DTO_ConcessionCard,
  DTO_Transaction,
} from "@app/products/property/assessments/[id]/components/child-screens/rebates/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { CommunityProperty } from "@common/stores/products/config";
import { scrollToView } from "@common/utils/animation";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { RefObject, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

interface IRebatesProps {
  assessmentId: number;
  rebateRef?: RefObject<any>;
}

export const Rebates = observer(
  ({ assessmentId, rebateRef }: IRebatesProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState<any>();
    const [
      columnRebatesAppliedOnAssessment,
      setColumnRebatesAppliedOnAssessment,
    ] = useState<IColumnFields[]>([]);
    const [showHistoricalEntitlements, setShowHistoricalEntitlements] =
      useState<boolean>(false);
    const [dataRebatesGrid, setdataRebatesGrid] = useState<
      DTO_Assessment_Rebates | undefined
    >();
    const [dataRebatesConcessionCards, setDataRebatesConcessionCards] =
      useState<DTO_ConcessionCard[]>([]);
    const [dataRebatesAppliedOnAssessment, setDataRebatesAppliedOnAssessment] =
      useState<DTO_Transaction[]>([]);

    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();

    const { setSelectedEntitledToRebate } = useEntitledToRebateStore();
    const { isLLS } = CommunityProperty.getFlagOfStates();

    const loadAssessmentRebatesConfigAndData = async (
      assessmentId: number,
      showHistoricalEntitlements?: boolean
    ) => {
      let errorResponse = undefined;
      setIsLoading(true);
      const response = await getAssessmentRebatesConfigAndData(
        assessmentId,
        showHistoricalEntitlements
      );

      if (Array.isArray(response)) {
        const [responseConfig, responseAssessmentRebates] = response;
        const dataAssessmentRebates = responseAssessmentRebates?.data;
        if (
          Array.isArray(responseConfig) &&
          isSuccessResponse(responseAssessmentRebates) &&
          dataAssessmentRebates
        ) {
          setColumnRebatesAppliedOnAssessment(responseConfig);
          setRebateData(dataAssessmentRebates);
        } else {
          errorResponse = responseConfig as APIResponseError;
          if (!isSuccessResponse(responseAssessmentRebates)) {
            errorResponse = responseAssessmentRebates;
          }
        }
      } else {
        const responseError = response as APIResponse;
        errorResponse = {
          status: responseError.status,
          error: responseError.error,
        };
      }
      setResponseLoadError(errorResponse);
      setIsLoading(false);
    };

    useAddUniqueEventEmitter([
      {
        eventType: AssessmentRebatesEventType.reloadRebatesGrid,
        listener: () => {
          setSelectedEntitledToRebate([]);
          setShowHistoricalEntitlements(false);
          loadAssessmentRebatesConfigAndData(assessmentId);
        },
      },
    ]);

    useEffectOnce(() => {
      scrollToView(rebateRef);
      loadAssessmentRebatesConfigAndData(assessmentId);
    });

    useEffect(() => {
      if (!filter) return;
      loadAssessmentRebatesConfigAndData(
        assessmentId,
        showHistoricalEntitlements
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showHistoricalEntitlements]);

    const setRebateData = (rebates: DTO_Assessment_Rebates) => {
      setdataRebatesGrid(rebates);
      setDataRebatesConcessionCards(
        rebates?.ConcessionCards?.map((item: DTO_ConcessionCard) => ({
          ...item,
          assessmentId,
        })) ?? []
      );
      setDataRebatesAppliedOnAssessment(
        rebates?.RebateAppliedOnAssessment?.map((item: DTO_Transaction) => ({
          ...item,
          assessmentId,
        })) ?? []
      );
      const rebatesAppliedOnAssessmentTotals: any = rebates?.Total;
      if (rebatesAppliedOnAssessmentTotals) {
        columnRebatesAppliedOnAssessment?.forEach((item) => {
          if (
            Object.keys(rebatesAppliedOnAssessmentTotals).includes(item.field)
          ) {
            item.footerCell = (
              <CurrencyCell
                value={rebatesAppliedOnAssessmentTotals[item.field]}
              />
            );
          }
        });
      }
    };

    const handleHistoricalEntitlements = (event: CheckboxChangeEvent) => {
      setShowHistoricalEntitlements(event.value);
      setFilter({ ...filter, historicalEntitlements: event.value });
    };

    const propsRebatesEntitledToRebate: IRebatesEntitledToRebate = {
      assessmentId,
      handleHistoricalEntitlements,
      showHistoricalEntitlements,
      dataRebatesGrid,
    };

    const propsRebatesConcessionCards: IRebatesConcessionCards = {
      dataRebatesConcessionCards,
    };

    const propsRebatesAppliedOnAssessment: IRebatesAppliedOnAssessment = {
      dataRebatesAppliedOnAssessment,
      columnRebatesAppliedOnAssessment,
    };

    if (isLoading) return <Loading isLoading={isLoading} />;
    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadAssessmentRebatesConfigAndData(assessmentId);
          }}
        />
      );

    return (
      <div className="cc-property-assessment-rebates" ref={rebateRef}>
        <div className="cc-form">
          <div className="cc-field-group">
            <div className="cc-form-cols-1">
              <RebatesEntitledToRebate {...propsRebatesEntitledToRebate} />
              {!isLLS && (
                <RebatesConcessionCards {...propsRebatesConcessionCards} />
              )}
              <RebatesAppliedOnAssessment
                {...propsRebatesAppliedOnAssessment}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
