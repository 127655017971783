import { DTO_Title } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { EPICStatus } from "@app/products/property/pic/list/components/action-bar/form-steps/modify-pic/components/form-element/associations/model";
import { isDate, isNil, isNumber } from "lodash";

export const validatorAssociation = (values: any): string | undefined => {
  if (
    isNil(values?.Folios) ||
    isNil(values?.Holdings) ||
    (!isNil(values?.Folios) && values?.Folios?.length < 1) ||
    (!isNil(values?.Holdings) && values?.Holdings?.length < 1)
  )
    return "ValidateBothGrid";
  if (values?.PrimaryHolding === 0 || isNil(values?.PrimaryHolding))
    return "PrimaryHolding";
  if (values?.PICStatus === EPICStatus.Inactive)
    return validatorRequireActiveTo(values);
  return validatorRequirePercentArea(values);
};

const validatorRequireActiveTo = (values: any) => {
  const notDateItem = values?.Folios?.find(
    (item: DTO_Title) => !isDate(item?.Active_To)
  );
  if (notDateItem) {
    return "ActiveToIsRequired";
  }
  return undefined;
};

const validatorRequirePercentArea = (values: any) => {
  const notPercentAreaItem = values?.Folios?.find(
    (item: DTO_Title) =>
      !(
        isNumber(item?.AssociatedPICAreaSet) &&
        item?.AssociatedPICAreaSet > 0 &&
        item?.AssociatedPICAreaSet <= 100
      )
  );
  if (notPercentAreaItem) {
    return "PercentAreaIsRequired";
  }
  return undefined;
};

interface TObject {
  [key: string]: any;
}

/**
 * Process data grid
 * Input: previous data, current data, field name
 * @param preData
 * @param currData
 * @param primaryKey
 * @returns
 */
export const processCombineData = <T extends TObject>(
  preData: T[],
  currData: T[],
  primaryKey: string
) => {
  if (preData?.length === 0) return currData;
  if (currData?.length === 1) {
    const existedItem = preData?.find(
      (e: T) => e[primaryKey] === currData[0]?.[primaryKey]
    );
    return isNil(existedItem) ? [currData[0], ...preData] : preData;
  } else {
    const listIds = preData?.map((item: any) => item[primaryKey]);
    const newData =
      currData.filter((item: any) => !listIds.includes(item[primaryKey])) ?? [];
    return [...newData, ...preData];
  }
};

export const combineValueWithDash = (
  values: string[],
  characterJoin: string = "-"
) => {
  return values?.filter((item) => item.length).join(characterJoin);
};
