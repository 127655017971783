import { ECustomColNameProperty } from "@app/products/property/config";
import { DTO_Assessment } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colAssessments } from "./config";

export const ASSESSMENT_FORM_STEP = "Assessment";
export const AssessmentsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfAssessment = nameOfFactory<DTO_Assessment>();
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const [processedCol, setProcessedCol] =
      useState<IColumnFields[]>(colAssessments);

    //Get labels
    const [assessmentLabel, assessmentNumberLabel, assessmentIDLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.Assessment,
        ECustomColNameProperty.AssessmentNumber,
        ECustomColNameProperty.AssessmentID,
      ]);

    useEffectOnce(() => {
      const newCols = colAssessments.map((column: IColumnFields) => {
        switch (column.field) {
          case nameOfAssessment("Assess_Number"):
            return {
              ...column,
              title: assessmentNumberLabel ?? column.title,
              id: `cc-grid-cell-${ECustomColNameProperty.AssessmentNumber}`,
            };
          case nameOfAssessment("Assessment_Id"):
            return {
              ...column,
              title: assessmentIDLabel ?? column.title,
              id: `cc-grid-cell-${ECustomColNameProperty.AssessmentID}`,
            };
          default:
            return column;
        }
      });
      setProcessedCol(newCols);
    });

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              {assessmentLabel} to receive new charge
            </label>
            <CCGrid
              data={getFieldValue("Assessments") || []}
              primaryField={nameOfAssessment("Assessment_Id")}
              columnFields={processedCol}
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>
      </section>
    );
  }
);
