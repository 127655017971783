import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IODataResponse } from "@common/models/odataResponse";
import { CancelTokenSource } from "axios";

export const loadSearchCombobox = async <T extends {}>(
  urlAPI: string,
  filter: string,
  keySearch: string,
  requestController?: CancelTokenSource
): Promise<APIResponse<IODataResponse<T> | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `${urlAPI}?$count=true&$top=200&$skip=0${
        filter ? `&$filter=contains(${keySearch}, '${filter}')` : ""
      }`,
      {
        cancelToken: requestController?.token,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error:
        requestController?.token.reason?.message ??
        errorResponse?.data?.Message,
    };
  }
};
