import { IAssessmentJournalTransactionStepsLOVs } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class AssessmentAddTransactionJournalStepStore {
  private _newAssessmentJournalStepLOVs?: IAssessmentJournalTransactionStepsLOVs =
    undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get newAssessmentJournalStepLOVs() {
    return toJS(this._newAssessmentJournalStepLOVs);
  }
  setNewAssessmentJournalStepLOVs = (
    newAssessmentJournalStepLOVs?: IAssessmentJournalTransactionStepsLOVs
  ) => {
    runInAction(() => {
      this._newAssessmentJournalStepLOVs = newAssessmentJournalStepLOVs;
    });
  };
}

const assessmentAddTransactionJournalStepStoreContext = createContext(
  new AssessmentAddTransactionJournalStepStore()
);
export const useAssessmentAddTransactionJournalStepStore = () => {
  return useContext(assessmentAddTransactionJournalStepStoreContext);
};
