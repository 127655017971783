import { getViewConfigurations } from "@app/products/property/api";
import { colSelectLandUses } from "@app/products/property/components/dialogs/add-land-uses-dialog/config";
import { PROPERTY_LANDUSE_REGISTER_ODATA } from "@app/products/property/components/dialogs/add-land-uses-dialog/constant";
import { VO_Land_Use } from "@app/products/property/components/dialogs/add-land-uses-dialog/model";
import { ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAddLandUseDialogProps {
  onClose: () => void;
  isLoadingFinish?: boolean;
  handleSelectLandUse: (data: VO_Land_Use[]) => void;
}

const nameOf = nameOfFactory<VO_Land_Use>();
export const AddLandUsesDialog = ({
  onClose,
  isLoadingFinish = false,
  handleSelectLandUse,
}: IAddLandUseDialogProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [selectedLandUse, setSelectedLandUse] = useState<VO_Land_Use[]>([]);
  const [landUseColumns, setLandUseColumns] =
    useState<IColumnFields[]>(colSelectLandUses);
  const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);

  const loadViewConfiguration = async () => {
    setIsLoadingDialog(true);
    const response = await getViewConfigurations(
      ViewConfiguration.LandUseLookup
    );
    setIsLoadingDialog(false);
    let errorResponse = undefined;
    if (isSuccessResponse(response) && response?.data) {
      setLandUseColumns(
        processDynamicColumns(
          colSelectLandUses,
          response?.data?.ColumnDefinitions?.Columns
        )
      );
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Load view configuration failed",
      };
    }
    setResponseLoadError(errorResponse);
  };

  useEffectOnce(() => {
      loadViewConfiguration();
  });

  return (
    <Form
      onSubmitClick={() => {
        handleSelectLandUse(selectedLandUse ?? []);
      }}
      render={(formRenderProps: FormRenderProps) => {
        return (
          <CCDialog
            titleHeader="Select Land Uses"
            maxWidth="50%"
            height="auto"
            onClose={onClose}
            bodyElement={
              <FormElement className="cc-form">
                <section className="cc-field-group">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-form-cols-1">
                    {isLoadingDialog ? (
                      <Loading isLoading={isLoadingDialog} />
                    ) : responseLoadError ? (
                      <CCLoadFailed
                        responseError={responseLoadError}
                        onReload={() => {
                          loadViewConfiguration();
                        }}
                      />
                    ) : (
                      <div className="cc-form-cols-1">
                        <CCGrid
                          columnFields={landUseColumns}
                          primaryField={nameOf("Land_Use_Id")}
                          dataUrl={PROPERTY_LANDUSE_REGISTER_ODATA}
                          selectableMode="multiple"
                          onSelectionChange={(dataItem: VO_Land_Use[]) => {
                            setSelectedLandUse(dataItem);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </section>
              </FormElement>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  iconClass={isLoadingFinish ? "fas fa-spinner fa-spin" : ""}
                  themeColor="primary"
                  onClick={formRenderProps.onSubmit}
                  type="submit"
                  disabled={selectedLandUse?.length === 0}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
