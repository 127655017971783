import { LLPermitsMenu } from "@app/products/local-laws/model";
import { permitTypesRoute } from "@app/products/local-laws/system-admin/permit-types/route";
import { ICCRoute } from "@common/constants/ICCRoute";

export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  enum: LLPermitsMenu.Administration,
  children: [
    permitTypesRoute,
    {
      path: "conditions",
      name: "Conditions",
      enum: LLPermitsMenu.Conditions,
      component: require("./conditions/_index").default,
    },
    {
      path: "settings",
      name: "System Settings",
      enum: LLPermitsMenu.Settings,
      component: require("./settings/_category/_index").default,
    },
  ],
};
