import { PPRApplication } from "@app/products/town-planning/ppr/permit-referrals/all/register/model";
import { handlePermitReferralsAllLinkTo } from "@app/products/town-planning/ppr/permit-referrals/all/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<PPRApplication>();
export const colPprAllApplicationsRegisterSearch: IColumnFields[] = [
  {
    field: nameOf("ApplicationNumber"),
    title: "Number",
    locked: true,
    linkTo: handlePermitReferralsAllLinkTo,
  },
  { field: nameOf("LodgedDate"), title: "Lodged", format: DATE_FORMAT.DATE },
  { field: nameOf("SiteAddress"), title: "Site Address" },
  {
    field: nameOf("ResponsibleAuthority"),
    title: "Responsible Authority",
  },
  {
    field: nameOf("Planner"),
    title: "Planner",
  },
  { field: nameOf("Status_Name"), title: "Status" },
  { field: nameOf("Applicant"), title: "Applicant" },
  {
    field: nameOf("ApplicantAddress"),
    title: "Applicant Address",
  },
  {
    field: nameOf("ReasonForPermit"),
    title: "Reason for Permit",
  },
  {
    field: nameOf("CouncilReferenceNo"),
    title: "Council Reference Number",
  },
  {
    field: nameOf("ApplicationType"),
    title: "Application Type",
  },
  { field: nameOf("AssessmentNo"), title: "Assess Number" },
  {
    field: nameOf("FileNo"),
    title: "File Number",
  },
  {
    field: nameOf("SpearRef"),
    title: "Spear Reference",
  },
];
