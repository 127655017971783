import { IMeterReadingRoutes } from "@app/products/property/meters/reading-routes/model";

export const mockMeterReadingRoutes: IMeterReadingRoutes[] = [
  {
    MeterReadingRouteNumber: 1000,
    RouteName: "Proserpine 1",
    AssignedReadingDevice: "PDA_ALL",
    AssignedDeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reading 1",
    ActiveMeters: 479,
    MetersWithStandardReadings: 0,
    MetersWithSpecialReadings: 0,
    ReadingsWaitingVerification: 22,
    StandardReadingRequired: 479,
    SpecialReadingRequired: 0,
    ReadingRouteId: 1,
  },
  {
    MeterReadingRouteNumber: 1001,
    RouteName: "Proserpine 2",
    AssignedReadingDevice: "PDA_ALL",
    AssignedDeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reading 1",
    ActiveMeters: 83,
    MetersWithStandardReadings: 0,
    MetersWithSpecialReadings: 0,
    ReadingsWaitingVerification: 3,
    StandardReadingRequired: 83,
    SpecialReadingRequired: 0,
    ReadingRouteId: 2,
  },
  {
    MeterReadingRouteNumber: 1002,
    RouteName: "Proserpine 3",
    AssignedReadingDevice: "PDA_ALL",
    AssignedDeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reading 1",
    ActiveMeters: 305,
    MetersWithStandardReadings: 1,
    MetersWithSpecialReadings: 0,
    ReadingsWaitingVerification: 14,
    StandardReadingRequired: 305,
    SpecialReadingRequired: 0,
    ReadingRouteId: 3,
  },
  {
    MeterReadingRouteNumber: 1003,
    RouteName: "Proserpine 4",
    AssignedReadingDevice: "PDA_ALL",
    AssignedDeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reading 1",
    ActiveMeters: 293,
    MetersWithStandardReadings: 1,
    MetersWithSpecialReadings: 0,
    ReadingsWaitingVerification: 27,
    StandardReadingRequired: 293,
    SpecialReadingRequired: 0,
    ReadingRouteId: 4,
  },
  {
    MeterReadingRouteNumber: 1004,
    RouteName: "Proserpine 5",
    AssignedReadingDevice: "PDA_ALL",
    AssignedDeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reading 1",
    ActiveMeters: 400,
    MetersWithStandardReadings: 0,
    MetersWithSpecialReadings: 0,
    ReadingsWaitingVerification: 35,
    StandardReadingRequired: 400,
    SpecialReadingRequired: 0,
    ReadingRouteId: 5,
  },
  {
    MeterReadingRouteNumber: 2000,
    RouteName: "Mt Julian - Ahute Harbour Rd",
    AssignedReadingDevice: "PDA_ALL",
    AssignedDeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reading 1",
    ActiveMeters: 243,
    MetersWithStandardReadings: 0,
    MetersWithSpecialReadings: 0,
    ReadingsWaitingVerification: 28,
    StandardReadingRequired: 243,
    SpecialReadingRequired: 0,
    ReadingRouteId: 6,
  },
  {
    MeterReadingRouteNumber: 3000,
    RouteName: "Cannonvale 1",
    AssignedReadingDevice: "PDA_ALL",
    AssignedDeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reading 1",
    ActiveMeters: 417,
    MetersWithStandardReadings: 0,
    MetersWithSpecialReadings: 0,
    ReadingsWaitingVerification: 85,
    StandardReadingRequired: 417,
    SpecialReadingRequired: 0,
    ReadingRouteId: 7,
  },
  {
    MeterReadingRouteNumber: 3001,
    RouteName: "Cannonvale 2",
    AssignedReadingDevice: "PDA_ALL",
    AssignedDeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reading 1",
    ActiveMeters: 424,
    MetersWithStandardReadings: 0,
    MetersWithSpecialReadings: 2,
    ReadingsWaitingVerification: 26,
    StandardReadingRequired: 422,
    SpecialReadingRequired: 0,
    ReadingRouteId: 8,
  },
  {
    MeterReadingRouteNumber: 3002,
    RouteName: "Cannonvale 3",
    AssignedReadingDevice: "PDA_ALL",
    AssignedDeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reading 1",
    ActiveMeters: 583,
    MetersWithStandardReadings: 0,
    MetersWithSpecialReadings: 0,
    ReadingsWaitingVerification: 30,
    StandardReadingRequired: 585,
    SpecialReadingRequired: 0,
    ReadingRouteId: 9,
  },
  {
    MeterReadingRouteNumber: 3003,
    RouteName: "Cannonvale 4",
    AssignedReadingDevice: "PDA_ALL",
    AssignedDeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reading 1",
    ActiveMeters: 203,
    MetersWithStandardReadings: 0,
    MetersWithSpecialReadings: 0,
    ReadingsWaitingVerification: 13,
    StandardReadingRequired: 201,
    SpecialReadingRequired: 2,
    ReadingRouteId: 10,
  },
  {
    MeterReadingRouteNumber: 4000,
    RouteName: "Airlie",
    AssignedReadingDevice: "PDA_ALL",
    AssignedDeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reading 1",
    ActiveMeters: 344,
    MetersWithStandardReadings: 1,
    MetersWithSpecialReadings: 0,
    ReadingsWaitingVerification: 30,
    StandardReadingRequired: 344,
    SpecialReadingRequired: 0,
    ReadingRouteId: 11,
  },
  {
    MeterReadingRouteNumber: 5000,
    RouteName: "Jubilee 1",
    AssignedReadingDevice: "PDA_ALL",
    AssignedDeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reading 1",
    ActiveMeters: 436,
    MetersWithStandardReadings: 1,
    MetersWithSpecialReadings: 0,
    ReadingsWaitingVerification: 16,
    StandardReadingRequired: 436,
    SpecialReadingRequired: 0,
    ReadingRouteId: 12,
  },
  {
    MeterReadingRouteNumber: 5001,
    RouteName: "Jubilee 2",
    AssignedReadingDevice: "PDA_ALL",
    AssignedDeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reading 1",
    ActiveMeters: 293,
    MetersWithStandardReadings: 0,
    MetersWithSpecialReadings: 0,
    ReadingsWaitingVerification: 12,
    StandardReadingRequired: 293,
    SpecialReadingRequired: 0,
    ReadingRouteId: 13,
  },
  {
    MeterReadingRouteNumber: 6000,
    RouteName: "Shute Harbour",
    AssignedReadingDevice: "PDA_ALL",
    AssignedDeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reading 1",
    ActiveMeters: 95,
    MetersWithStandardReadings: 0,
    MetersWithSpecialReadings: 0,
    ReadingsWaitingVerification: 5,
    StandardReadingRequired: 95,
    SpecialReadingRequired: 0,
    ReadingRouteId: 14,
  },
  {
    MeterReadingRouteNumber: 9998,
    RouteName: "Active Meters not in Route",
    ReadingRouteId: 15,
  },
  {
    MeterReadingRouteNumber: 9999,
    RouteName: "Meters not being read",
    MetersWithStandardReadings: 0,
    MetersWithSpecialReadings: 0,
    ReadingsWaitingVerification: 1,
    ReadingRouteId: 0,
  },
];
