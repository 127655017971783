import { appealsRoute } from "@app/products/town-planning/appeals/route";
import { certificationsRoute } from "@app/products/town-planning/certifications/route";
import { complaintsRoute } from "@app/products/town-planning/complaints/route";
import { crmsApplicationsRoute } from "@app/products/town-planning/crms-applications/route";
import { ePlanningDevelopmentsRoute } from "@app/products/town-planning/eplanning-developments/route";
import { financeRoute } from "@app/products/town-planning/finance/route";
import { nonComplianceRoute } from "@app/products/town-planning/non-compliance/route";
import { otherRoute } from "@app/products/town-planning/other/route";
import { plansToComplyRoute } from "@app/products/town-planning/plans-to-comply/route";
import { psaRoute } from "@app/products/town-planning/psa/route";
import { recycleBinRoute } from "@app/products/town-planning/recycle-bin/route";
import { registersRoute } from "@app/products/town-planning/register/route";
import { spearRoute } from "@app/products/town-planning/spear/route";
import { systemAdminRoute } from "@app/products/town-planning/system-admin/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";
import { allApplicationsRoute } from "./all-applications/route";
import { amendmentsRoute } from "./amendments/route";

export const townPlanningRoute: ICCRoute = {
  path: "town-planning",
  name: "Town Planning",
  enum: eProductType.TownPlanning,
  children: [
    allApplicationsRoute,
    amendmentsRoute,
    plansToComplyRoute,
    certificationsRoute,
    spearRoute,
    appealsRoute,
    registersRoute,
    crmsApplicationsRoute,
    otherRoute,
    complaintsRoute,
    psaRoute,
    nonComplianceRoute,
    financeRoute,
    ePlanningDevelopmentsRoute,
    systemAdminRoute,
    recycleBinRoute,
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
