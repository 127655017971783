import { KeyValuePair } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/documents/model";
import {
  SecondaryModeCheckField,
  SecondaryModeField,
} from "@app/products/hm/premises/[id]/model";
import { HEALTHMANAGER_CONFIGURATIONMODE } from "@common/constants/enumerations";
import { isEmpty } from "lodash";

//Convert paste mapField and check if it is disabled
export const checkSecondaryMode = (
  enableSecondaryMode: boolean,
  sFieldMapping: string,
  checkField: SecondaryModeCheckField,
  configurationMode?: HEALTHMANAGER_CONFIGURATIONMODE
) => {
  if (!enableSecondaryMode || !sFieldMapping || sFieldMapping.length === 0) {
    return false;
  }
  let isDisabled = false;
  let secondaryModeMapping = convertFieldMapping(sFieldMapping);

  switch (checkField) {
    case SecondaryModeCheckField.BtnRegister:
      // this.btnRegister.Enabled = false;
      isDisabled = secondaryModeMapping.some(
        (x) => x.Key === SecondaryModeField.map_DATE_LODGED
      );
      break;
    case SecondaryModeCheckField.LbtAddress:
      // this.lbtAddress.RenderMode = ControlRenderMode.Read;
      isDisabled = secondaryModeMapping.some((x) => {
        return (
          x.Key === SecondaryModeField.map_PREMISES_POSTCODE ||
          x.Key === SecondaryModeField.map_PREMISES_STREETNAME ||
          x.Key === SecondaryModeField.map_PREMISES_STREETNO ||
          x.Key === SecondaryModeField.map_ASSESSMENT_ID ||
          x.Key === SecondaryModeField.map_ASSESSMENT_NO ||
          x.Key === SecondaryModeField.map_PREMISES_SUBURB
        );
      });
      break;
    case SecondaryModeCheckField.LbtPremisesType:
      // this.lbtPremisesType.RenderMode = ControlRenderMode.Read;
      isDisabled = secondaryModeMapping.some(
        (x) => x.Key === SecondaryModeField.map_PREMISES_TYPE
      );
      break;
    case SecondaryModeCheckField.LbtProprietor:
      //this.lbtProprietor.RenderMode = ControlRenderMode.Read;
      isDisabled = secondaryModeMapping.some((x) => {
        return (
          x.Key === SecondaryModeField.map_PROPRIETOR_COMPANY_NUMBER ||
          x.Key === SecondaryModeField.map_PROPRIETOR_COMPANYNAME ||
          x.Key === SecondaryModeField.map_PROPRIETOR_WORK_PHONE ||
          x.Key === SecondaryModeField.map_PROPRIETOR_MOBILE ||
          x.Key === SecondaryModeField.map_PROPRIETOR_EMAIL ||
          x.Key === SecondaryModeField.map_PROPRIETOR_HOME_PHONE ||
          x.Key === SecondaryModeField.map_PROPRIETOR_POSTAL_ADDRESS
        );
      });
      break;
    case SecondaryModeCheckField.TxtRegistrationNo:
      // txtRegistrationNo.RenderMode = ControlRenderMode.Read;
      isDisabled = secondaryModeMapping.some(
        (x) => x.Key === SecondaryModeField.map_REGISTRATION_NUMBER
      );
      break;
    case SecondaryModeCheckField.TxtTradingName:
      //txtTradingName.RenderMode = ControlRenderMode.Read;
      isDisabled = secondaryModeMapping.some(
        (x) => x.Key === SecondaryModeField.map_TRANDING_NAME
      );
      break;
    case SecondaryModeCheckField.TxtDebtorNo:
      let checkMapDebtorNo = secondaryModeMapping.some(
        (x) => x.Key === SecondaryModeField.map_DEBTOR_NUMBER
      );
      if (
        checkMapDebtorNo &&
        configurationMode !== HEALTHMANAGER_CONFIGURATIONMODE.WATER_AUTHORITY
      ) {
        // txtDebtorNo.RenderMode = ControlRenderMode.Read;
        isDisabled = true;
      }
      break;
    // #region LbtOfficer Commented in Radium
    // case SecondaryModeCheckField.LbtOfficer:
    //   // this.lbtOfficer.RenderMode = ControlRenderMode.Read;
    //   isDisabled = secondaryModeMapping.some(
    //     (x) => x.Key === SecondaryModeField.map_OFFICER
    //   );
    //   break;
    // #endregion
  }

  return isDisabled;
};

const SeparatorBEL = "\u0007"; // ASCII name BEL
const SeparatorInItemData = "|"; // Replace with the actual separator if different
const convertFieldMapping = (sFieldMapping: string) => {
  if (!isEmpty(sFieldMapping)) {
    const fldMapping = sFieldMapping.split(SeparatorBEL);
    const secondaryModeFieldMapping: KeyValuePair<string, string>[] = [];

    fldMapping.forEach((pair) => {
      const data = pair.split(SeparatorInItemData);
      if (data[0] && data[1]) {
        secondaryModeFieldMapping.push({ Key: data[0], Value: data[1] }); // Local field, Primary system field
      }
    });

    return secondaryModeFieldMapping;
  }
  return [];
};
