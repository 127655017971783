import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const registersRoute: ICCRoute = {
  path: "registers",
  name: "Registers",
  enum: eMenuAction.Register_Generate_Menu_Items_for_each_Registers,
  children: [
    {
      path: "list",
      name: "List",
      component: require("./list/_index").default,
      enum: eMenuAction.Register_List,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
