import {
  DTO_Certificate_AreaOfBalance,
  DTO_Certificate_Rebate,
} from "@app/products/property/certificates/[id]/components/child-screens/balances/model";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleProminent } from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";
const nameOfBalancesFinancial = nameOfFactory<DTO_Certificate_AreaOfBalance>();
export const colBalancesFinancial: IColumnFields[] = [
  {
    field: nameOfBalancesFinancial("Financial_Area_Name"),
    title: "Financial Area",
  },
  {
    field: nameOfBalancesFinancial("Gross_Amount_Levied"),
    title: "Gross Amount Levied",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(
        nameOfBalancesFinancial("Gross_Amount_Levied"),
        gridData
      ),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfBalancesFinancial("Rebates"),
    title: "Rebates",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOfBalancesFinancial("Rebates"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfBalancesFinancial("Current_Balance"),
    title: "Current Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOfBalancesFinancial("Current_Balance"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfBalancesFinancial("Current_Interest_Balance"),
    title: "Current Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(
        nameOfBalancesFinancial("Current_Interest_Balance"),
        gridData
      ),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfBalancesFinancial("Arrears_Balance"),
    title: "Arrears Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOfBalancesFinancial("Arrears_Balance"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfBalancesFinancial("Arrears_Interest_Balance"),
    title: "Arrears Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(
        nameOfBalancesFinancial("Arrears_Interest_Balance"),
        gridData
      ),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfBalancesFinancial("Deferred_Balance"),
    title: "Deferred Arrears Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOfBalancesFinancial("Deferred_Balance"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfBalancesFinancial("Deferred_Interest_Balance"),
    title: "Deferred Arrears Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(
        nameOfBalancesFinancial("Deferred_Interest_Balance"),
        gridData
      ),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfBalancesFinancial("Postponed_Balance"),
    title: "Postponed Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOfBalancesFinancial("Postponed_Balance"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfBalancesFinancial("Postponed_Interest_Balance"),
    title: "Postponed Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(
        nameOfBalancesFinancial("Postponed_Interest_Balance"),
        gridData
      ),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
];

const nameOfRebates = nameOfFactory<DTO_Certificate_Rebate>();
export const colRebates: IColumnFields[] = [
  {
    field: nameOfRebates("Rebate_Type_Name"),
    title: "Rebate Type",
  },
  {
    field: nameOfRebates("Rebate_Amount"),
    title: "Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOfRebates("Rebate_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
];
