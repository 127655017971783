import { CoreKeyword } from "@common/models/coreKeyword";
import { DTO_LOV } from "@common/models/odataResponse";
import { UploadFileInfo } from "@progress/kendo-react-upload";

export interface IAddAttachmentDialog {
  UploadMode?: eUploadMode;
  File?: UploadFileInfo[];
  Title?: string;
  Category?: DTO_LOV;
  WebLink?: string;
  WebLinkCategory?: CoreKeyword;
  Tag?: string;
}

export enum eUploadMode {
  UPLOAD_FILE = 1,
  UPLOAD_WEB_LINK = 2,
}
