import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colNewDocsFI: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.ApplicationNumber,
    title: "Number",
    width: 250,
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.Applicant,
    title: "Applicant",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.ePlanningCategory,
    title: "Category",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.SiteAddress,
    title: "Site Address",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.Locality,
    title: "Locality",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.DateLodged,
    title: "Date",
    width: 250,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.Title,
    title: "Title",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.FileName,
    title: "File Name",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.Status,
    title: "Status",
    width: 250,
  },
];
