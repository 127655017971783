import {
    ACCOUNT_NAMES_FORM_STEP,
    AccountNamesFormStep,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/names/_index";
import {
    AccountFormStep,
    CREATE_REGISTER_ACCOUNT_FORM_STEP,
} from "@app/products/property/registers/list/components/action-bar/form-steps/create-register/components/account/_index";
import {
    CREATE_REGISTER_PARCEL_FORM_STEP,
    ParcelFormStep,
} from "@app/products/property/registers/list/components/action-bar/form-steps/create-register/components/parcels/_index";
import { defaultParcel } from "@app/products/property/registers/list/components/action-bar/form-steps/create-register/config";
import { isShowParkButton } from "@app/products/property/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";

interface ICreateRegisterDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
}
export const CreateRegisterDialog = ({
  onClose,
  onSubmit,
  isIncompleteMode = false,
  isFromActionList = false,
}: ICreateRegisterDialogProps) => {
  const steps: IStep[] = [
    {
      label: "Account",
      initialValues: { Account: [] },
      component: AccountFormStep,
      visible: true,
      key: CREATE_REGISTER_ACCOUNT_FORM_STEP,
    },
    {
      label: "Parcels",
      initialValues: defaultParcel,
      component: ParcelFormStep,
      visible: true,
      key: CREATE_REGISTER_PARCEL_FORM_STEP,
    },
    {
      label: "Names",
      component: AccountNamesFormStep,
      visible: true,
      key: ACCOUNT_NAMES_FORM_STEP,
    },
  ];
  return (
    <CCFormStep
      onSubmit={onSubmit}
      initialSteps={steps}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="45%"
          maxHeight="55%"
          titleHeader={"Register Account Creation"}
          onClose={onClose}
          bodyElement={renderProps.children}
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              {isShowParkButton(isFromActionList, isIncompleteMode) && (
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Park
                </Button>
              )}
              <Button className={"cc-dialog-button"} onClick={onClose}>
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                disabled={renderProps.nextButton.disabled}
                className={"cc-dialog-button"}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
