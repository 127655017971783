import { CCComboBox } from "@components/cc-combo-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxProps } from "@progress/kendo-react-dropdowns";
import React, { useState } from "react";
import "./_index.scss";
interface ICCComboBoxProps extends ComboBoxProps {
  name: string;
  isShowNewFooter?: boolean;
  searchKey?: string;
  onChangeNewButton?: (searchKey?: string) => void;
}
export const CCComboboxWithNewOption = (props: ICCComboBoxProps) => {
  const {
    name,
    isShowNewFooter = false,
    searchKey,
    onChangeNewButton,
    ...others
  } = props;
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);

  return (
    <div className="cc-field cc-combo-box-with-new">
      <CCComboBox
        {...others}
        name={name}
        filterable
        suggest
        onOpen={() => {
          setIsOpenPopup(true);
        }}
        onClose={() => {
          setIsOpenPopup(false);
        }}
        opened={isOpenPopup}
        footer={
          isShowNewFooter ? (
            <div className="cc-combo-box-with-new-footer">
              <Button
                style={{
                  color: "var(--accent)",
                }}
                title={"New"}
                onClick={() => {
                  if (onChangeNewButton) onChangeNewButton(searchKey);
                  setIsOpenPopup(false);
                }}
                fillMode="flat"
                themeColor="primary"
                icon="plus"
                onMouseDown={(e: any) => e.preventDefault()}
              >
                New
              </Button>
            </div>
          ) : null
        }
      />
    </div>
  );
};
