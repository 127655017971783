import { Attachment } from "@app/core/documents/model";

export interface IAttachmentFile extends Attachment {
  _uid: string;
  _isLoading?: boolean;
  _isFailed?: boolean;
  _validationErrors?: string;
}

export enum eFileUploadValidationErrors {
  InvalidFileExtension = "invalidFileExtension",
  InvalidFiles = "invalidFiles",
  InvalidMaxFileSize = "invalidMaxFileSize",
  InvalidMinFileSize = "invalidMinFileSize",
}
