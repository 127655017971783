export enum CONTEXTIDENTIFIER {
  HOME = 1,
  MANAGE_ACTION = 2,

  HM_PREMISES_REGISTER = 1000,
  HM_TOBACCO_REGISTER = 1001,
  HM_INSPECTION = 1002,

  AMS_ASSET_REGISTER = 2000,
  AMS_WORK_ORDER = 2001,

  ANIMALS = 3000,
  ANIMALS_ALL_BY_DUE_STATUS = 3201,
  ANIMALS_REQUIRES_RENEWALS_FEE = 3202,
  ANIMALS_REQUIRES_RENEWALS_NOTICE = 3203,
  ANIMALS_NOTICE_SENT = 3204,

  TOWNPLANING = 4000,
  LOCALLAWS = 5000, // TODO: Mock data
  ACTIONS = 9999,
  CRMS = 6000, //TODO: Mock data
  CRMS_PHONEMESSAGE = 6001, //TODO: Mock data
  CRMS_KBARTICLE = 6002, //TODO: Mock data
  CRMS_SERVICE_STANDARDS = 6043, //TODO: Mock data
  PARKINGPERMITS = 7000,
  EVENTMANAGEMENT = 9000,
  BUILDING = 8000,
  BUILDING_REGISTERS_COUNCILCONSENT = 8001,
  BUILDING_REGISTERS_ENQUIRY = 8002,
  BUILDING_REGISTERS_POPE = 8003,
  BUILDING_POOLSSPAS = 8004,
  BUILDING_REGISTERS_DEMOLITION = 8005,
  BUILDING_REGISTERS_STORMWATER = 8006,
  BUILDING_REGISTERS_ESSENTIAL_SAFETY = 8007,
  BUILDING_REGISTERS_SITINGAPPROVALS = 8008,
  BUILDING_REGISTERS_CERTIFICATES = 8012,
  BUILDING_REGISTERS_EASEMENTS = 8010,
  BUILDING_REGISTERS_ENFORCEMENTS = 8011,
  BUILDING_FINANCE = 8013,

  WASTEWATER = 10000,
  CSL = 11000,
  //TODO: Mock data
  CSL_CONDITIONS = 11001,
  CSL_CATEGORIES = 11002,
  CRS = 8092,

  SETTING = 3,
  SETTING_SYSTEMADMIN = 4,

  PROPERTY = 1999,
  PROPERTY_ASSESSMENTS = 1501,
  PROPERTY_CERTIFICATES = 1502,
  PROPERTY_SUPPLEMENTARY_RATES = 1503,
}

export const KEY_CODES = {
  ARROW_UP: "ArrowUp",
  ARROW_DOWN: "ArrowDown",
};
