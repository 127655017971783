import { DTO_WorkflowHeader } from "@app/products/property/model";

export enum EKeysOfStepsCreateDeferredDuty {
  Details = "Detail",
  OtherInfo = "OtherInfo",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfSendStepsCreateDeferredDuty = [
  EKeysOfStepsCreateDeferredDuty.Details,
  EKeysOfStepsCreateDeferredDuty.OtherInfo,
];

export interface DTO_Workflow_CreateDeferredDuty {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_CreateDeferredDuty;
}

export interface DTO_WorkflowDetail_CreateDeferredDuty {
  AccountDetails: DTO_Account_Detail;
  Interest: DTO_Interest;
  TRSInformation: DTO_TRS_Information;
}

export interface DTO_Account_Detail {
  AccountId: number | null;
  AccountNumber: number;
  Description: string;
  AssessmentId: number;
  PropertyAddress: string;
  NameAndAddress: string;
  AssessmentReference: string;
  OriginalDutyAssessed: number;
  DutyDeferred: number;
  ExecutionDate: Date | null;
  DefermentDate: Date | null;
  PaymentCommencement: Date | null;
  PaymentCompletion: Date | null;
}

export interface DTO_Interest {
  InterestCalculatedToDate: Date | null;
  MinimumAmountForInterest: number;
  InterestRate: number;
  PremiumRate: number;
}

export interface DTO_TRS_Information {
  ClientId: number;
  AssessmentId: number;
  LodgementNumber: number | null;
  RevenueType: string;
}

export interface DTO_Other_Information_Custom {
  ClientId: number;
  AssessmentId: number;
  LodgementNumber: number | null;
  RevenueType: string;
  InterestCalculatedToDate: string | null;
  MinimumAmountForInterest: number;
  InterestRate: number;
  PremiumRate: number;
}
