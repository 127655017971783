import { RosterView } from "@app/products/crms/system-admin/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<RosterView>();

export const colRosters: IColumnFields[] = [
  {
    field: nameOf("RosterType"),
    title: "Roster Type",
    linkTo: (dataItem: RosterView) => `rosters/${dataItem.ID}`,
  },
  {
    field: nameOf("RosterName"),
    title: "Roster Name",
  },
];
