import { mockLeakage } from "@app/products/property/meters/[id]/components/child-screens/leakage/mock";
import { IOccurrences } from "@app/products/property/meters/[id]/components/child-screens/leakage/model";
import { sleep } from "@common/utils/common";
import { ILeakage } from "./model";

export const getMeterLeakage = async () => {
  await sleep(1000);
  return mockLeakage as ILeakage;
};

export const postOccurrenceAPI = async (
  newOccurrence: IOccurrences,
  currentLeakage: ILeakage
) => {
  await sleep(1000);
  let newLeakage = { ...currentLeakage };
  newLeakage.Occurrences?.push(newOccurrence);
  return newLeakage;
};

export const putOccurrenceAPI = async (
  editOccurrence: IOccurrences,
  currentLeakage: ILeakage
) => {
  await sleep(1000);
  let newLeakage = { ...currentLeakage };
  if (newLeakage?.Occurrences && editOccurrence) {
    const editIndex = newLeakage?.Occurrences.findIndex(
      (leakage) => leakage.MeterLeakageId === editOccurrence.MeterLeakageId
    );
    if (editIndex !== -1) {
      newLeakage.Occurrences.splice(editIndex, 1, editOccurrence);
    }
  }
  return newLeakage;
};

export const deleteLeakageAPI = async (
  removeOccurrence: IOccurrences[],
  currentLeakage: ILeakage
) => {
  await sleep(1000);
  let newLeakage = { ...currentLeakage };
  if (newLeakage?.Occurrences) {
    newLeakage.Occurrences = newLeakage.Occurrences.filter((item) => {
      return !removeOccurrence.some(
        (removeItem) => removeItem.MeterLeakageId === item.MeterLeakageId
      );
    });
    return newLeakage;
  }
  return currentLeakage;
};
