import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
import { getDeferredDutyBalances } from "@app/products/property/deferred-duty/[id]/components/child-screens/balances/api";
import { DTO_DeferredDuty_Account_Balance } from "@app/products/property/deferred-duty/[id]/components/child-screens/balances/model";
import { APIResponseError } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";

configure({ enforceActions: "always" });

class DeferredDutyBalanceStore {
  private _deferredDuty?: DTO_DeferredDuty_Account_Balance = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get deferredDuty() {
    return this._deferredDuty;
  }
  setDeferredDuty = (deferredDuty?: DTO_DeferredDuty_Account_Balance) => {
    runInAction(() => {
      this._deferredDuty = deferredDuty;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._deferredDuty = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadDeferredDuty = async (deferredDutyId: number) => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    let newDeferredDuty = undefined;
    const response = await getDeferredDutyBalances(deferredDutyId);
    if (isSuccessResponse(response)) {
      newDeferredDuty = response.data?.DeferredDutyAccountBalance;
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setDeferredDuty(newDeferredDuty);
    if (newDeferredDuty && newDeferredDuty?.AlertMessage) {
      appNotificationStore.pushNotification({
        title: newDeferredDuty?.AlertMessage,
        type: "warning",
        autoClose: false,
      });
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };
}

const deferredDutyBalanceStoreContext = createContext(
  new DeferredDutyBalanceStore()
);
export const useDeferredDutyBalanceStore = () => {
  return useContext(deferredDutyBalanceStoreContext);
};
