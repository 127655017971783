import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { getCorpAuthTabDetailsById } from "@common/pages/settings/security/corporate-authorisations/_id/components/reference-sidebar/detail/api";
import { Svc_CorporateAuthorisation_TabDetail } from "@common/pages/settings/security/corporate-authorisations/_id/components/reference-sidebar/detail/model";
import { useCorporateAuthStore } from "@common/pages/settings/security/corporate-authorisations/_id/store";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const CorpAuthTabDetails = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { corporateAuthId } = useCorporateAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const [corpAuthTabDetailsInfo, setCorpAuthTabDetailsInfo] =
    useState<Svc_CorporateAuthorisation_TabDetail>();
  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();

  const corpAuthID = corporateAuthId ?? lastSelectedRow?.ID;

  const loadDetailData = async () => {
    setIsLoading(true);
    const response = await getCorpAuthTabDetailsById(corpAuthID);
    if (isSuccessResponse(response)) {
      setCorpAuthTabDetailsInfo(response.data);
    } else {
      setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!corpAuthID) return;
    loadDetailData();
    // eslint-disable-next-line
  }, [corpAuthID]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => loadDetailData()}
      />
    );
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Name:"}
          value={corpAuthTabDetailsInfo?.DisplayName}
        />
        <ProductReferenceRow
          title={"Description:"}
          value={corpAuthTabDetailsInfo?.Description}
        />
        <ProductReferenceRow
          title={"Product Type:"}
          value={corpAuthTabDetailsInfo?.ProductType}
        />
        <ProductReferenceRow
          title={"Email:"}
          value={corpAuthTabDetailsInfo?.Email}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
