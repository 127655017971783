import { IChangeStatus } from "@app/products/town-planning/ppr/components/action-bar/dialogs/update-status/model";
import { convertTypeApplicationStatus } from "@app/products/town-planning/ppr/components/action-bar/dialogs/update-status/util";
import { PPRUpdateStatusDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/update-status/_index";
import {
  getPSARStatus,
  putPSARStatus,
} from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/tools/change-status/api";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { StatusChangeDetails } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-status/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const PSARChangeStatusButton = observer(() => {
  const { ppr, pprId, setPPRWithLoading } = usePSARStore();
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showUpdateStatusDialog, setShowUpdateStatusDialog] = useState(false);
  const [initialValues, setInitialValues] = useState<IChangeStatus>();

  const handleOnClick = async () => {
    setIsLoading(true);
    const resStatus = await getPSARStatus(
      convertTypeApplicationStatus(ppr?.Status_ENUM)
    );
    setIsLoading(false);
    setShowUpdateStatusDialog(true);
    if (isSuccessResponse(resStatus)) {
      if (resStatus?.data) {
        setInitialValues({
          CurrentStatus: resStatus?.data[0]?.Key,
          _options: {
            StatusList: resStatus?.data ?? [],
            IsPlansToComply: ppr?.Flag_PlansToComply,
            IsShowDecisionDate: false,
            IsShowDecisionType: false,
          },
        });
      }
    } else {
      pushNotification({
        title: resStatus?.statusText ?? "Status list load failed",
        type: "error",
        autoClose: false,
      });
    }
  };

  const handleOnSubmit = async (data: IChangeStatus) => {
    if (!pprId || !data?.CurrentStatus) return;
    setIsUpdating(true);
    const reqBody: StatusChangeDetails = {
      ApplicationIDs: [pprId],
      Status: data?.CurrentStatus,
      Decision: data?.CurrentDecision,
      DecisionDate: data?.DecisionDate,
    };
    const resUpdateStatus = await putPSARStatus(pprId, reqBody);
    setIsUpdating(false);
    setShowUpdateStatusDialog(false);
    if (isSuccessIdentityPacket(resUpdateStatus)) {
      clearErrorNotification();
      setPPRWithLoading(resUpdateStatus?.data?.Application);
      pushNotification({
        type: "success",
        title: "Status changed successfully",
      });
    } else {
      pushNotification({
        title: resUpdateStatus?.statusText ?? "Change status failed",
        type: "error",
        autoClose: false,
      });
    }
  };

  return (
    <>
      <CCNavButton title="Change status" onClick={handleOnClick} />
      {showUpdateStatusDialog && (
        <PPRUpdateStatusDialog
          isLoading={isLoading}
          isUpdating={isUpdating}
          initialValues={initialValues}
          onClose={() => setShowUpdateStatusDialog(false)}
          onSubmit={handleOnSubmit}
        />
      )}
    </>
  );
});
