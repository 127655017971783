import { IMetersReading } from "@app/products/property/meters/[id]/components/child-screens/reading/model";
import { useMeterReadingsStore } from "@app/products/property/meters/[id]/components/child-screens/reading/store";
import { ConfirmVerifyReadingsDialog } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/verify-readings/components/dialogs/confirm-verify-readings/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const VerifyReadingButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isDisableVerify, setIsDisableVerify] = useState(true);
  const { verifyReadings, selectedReadings } = useMeterReadingsStore();

  useEffect(() => {
    setIsDisableVerify(
      !selectedReadings?.some(
        (item: IMetersReading) => item.ToBeVerified === true
      )
    );
  }, [selectedReadings]);

  return (
    <React.Fragment>
      <CCNavButton
        title="Verify readings"
        disabled={isDisableVerify}
        onClick={() => {
          setIsShowDialog(true);
        }}
      />
      {isShowDialog && (
        <ConfirmVerifyReadingsDialog
          onClosePopup={() => {
            setIsShowDialog(false);
          }}
          onConfirmYes={() => {
            verifyReadings();
            setIsShowDialog(false);
          }}
        />
      )}
    </React.Fragment>
  );
});
