import { DTO_Journal } from "@app/products/property/journals/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";

export enum WriteOffBalancesKeysOfSteps {
  WhichJournal = "WhichJournal",
  NewJournal = "CreateJournal",
  Account = "Account",
  BalanceAdjustment = "BalanceAdjustment",
  Comments = "Comments",
  Documents = "Documents",
}
////////////////////////////
export interface DTO_Workflow_JournalDDWriteOffBalance {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_JournalDDWriteOffBalance;
}

export interface DTO_WorkflowDetail_JournalDDWriteOffBalance {
  JournalNumber: number;
  JournalMode: number;
  CreateJournal: DTO_Journal;
  Transaction: DTO_DDTransaction;
  Accounts: DTO_DeferredDuty_Account[];
}

export interface DTO_DDTransaction {
  AccountId: number;
  TransactionTypeId: number | null;
  TransactionDate: string | null;
  Reference: string;
  Description: string;
  Notes: string;
  DeferredDutyAmount: number | null;
  InterestAmount: number | null;
  OtherAmount: number | null;
}

export enum DDAccountStatus {
  NewAccount = 0,
  Active = 1,
  UnabletoCalculateStatus = 2,
  ActiveNoInterest = 3,
  Inactive = 9,
  PaidOut = 10,
  Overpaid = 11,
  DeferredDutyCancelled = 12,
}

export interface DTO_DeferredDuty_Account {
  AccountId: number;
  AccountNumber: number | null;
  AccountName: string;
  AssessmentNumber: number | null;
  AssessmentReference: string;
  PropertyAddress: string;
  Description: string;
  LegalDescription: string;
  Status: string;
  DutyBalance: number | null;
  Interest: number | null;
  OtherAmount: number | null;
  DDSTextColour: number | null;
  AssessmentId: number | null;
}

export interface DTO_WriteOffBalances_LOVs {
  WriteOffBalancesLovs: any[];
}
