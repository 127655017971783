import log from "loglevel";
import prefix from "loglevel-plugin-prefix";
// initialize loglevel with prefix plugin
prefix.reg(log);
prefix.apply(log, {
  format(level, name, timestamp) {
    return `${timestamp} [${name}] ${level}:`;
  },
});
// define custom logger function
export const customLogger = (name: string) => {
  const logger = log.getLogger(name);

  const customLoggerInstance = {
    info: logger.info.bind(logger),
    debug: logger.debug.bind(logger),
    error: logger.error.bind(logger),
    trace: logger.trace.bind(logger),
    warn: logger.warn.bind(logger),
    getLevel: logger.getLevel.bind(logger),
    setDefaultLevel: logger.setDefaultLevel.bind(logger),
  };

  return customLoggerInstance;
};
