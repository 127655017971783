import { useIsNew } from "@common/hooks/useIsNew";
import { searchContact } from "@common/pages/contacts/api";
import { AutoComplete, ListItemProps } from "@progress/kendo-react-dropdowns";
import React, { ReactElement, useEffect, useState } from "react";

interface Props {
  onSelected?: (value: any) => unknown;
}

export const ContactNameSearch = ({ onSelected }: Props) => {
  const isNew = useIsNew();
  const [keyword, setKeyword] = useState<any>({ value: "" });
  const [dataSource, setDataSource] = useState<any[]>([]);

  useEffect(() => {
    if (!keyword.value) return;
    searchContact(keyword.value).then((data: any) => {
      setDataSource(data);
    });
  }, [keyword.value]);

  const handleChange = (e: any) => {
    const keyword = e.target.value || "";
    setKeyword({ value: keyword });
  };

  const itemRender = (li: ReactElement, itemProps: ListItemProps) => {
    // const index = itemProps.index;
    const item = itemProps.dataItem;
    const itemChildren = (
      <span
        onClick={() => {
          if (onSelected) {
            onSelected(item);
          }
        }}
      >
        {item.GoldUser ? (
          <i className={item.GoldUser + " text-warning mr-2"}></i>
        ) : (
          " "
        )}
        <span className="font-medium">{item.DisplayName}</span>
        {item.ContactInfo ? " - " + item.ContactInfo : " "}
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  if (!isNew) return null;

  return (
    <div className="cc-form-cols-3">
      <div className="cc-field cc-col-span-2">
        <label className="cc-label">Name search</label>
        <AutoComplete
          data={dataSource}
          textField={"DisplayName"}
          suggest={true}
          value={keyword.value}
          onChange={handleChange}
          placeholder="Name search"
          itemRender={itemRender}
        />
      </div>
    </div>
  );
};
