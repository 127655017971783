import { IParkingPermitsForm } from "@app/products/parking-permits/[id]/model";

export const mockParkingPermitForm: IParkingPermitsForm = {
  PermitNo: "C16622",
  PermitType: "Business-focused",
  DebtorNumber: 64276,
  FileNumber: 14590,
  OSFees: 60008,
  Status_ENUM: "Current",
  ContactPermitHolder: "(955) 852-3448 x4663",
  ContactPerson: "Madelyn Cremin",
  ContactNumPerson: "1-765-486-4663",
  PropertyNumber: 44739,
  PostalAddress: "548 Gibson Overpass",
  PermitAddress: "41 Heritage Bvd Pakenham VIC 3810",
  IsSameAsPermitAddress: true,
  IsPropertyAddress: false,
  OnBehalfOf: true,
  IssueDate: "2017-06-30",
  IssueTime: "2017-06-30T23:00:00.782Z",
  ExpiryTime: "2017-06-30T23:00:00.782Z",
  ID: 48135,
  PermitType_Name: "Planner",
  PermitHolder_DisplayName: "Luna",
  StreetName: "648 Effertz Via",
  Suburb: "Luisa_Goldner58",
  Address_Search: "Suite 762",
  Permit_Status: "Grocery",
  Permit_SubStatus: "ex do",
  IssuedDate: "Sun Nov 14 2021 11:23:04 GMT+0700 (Indochina Time)",
  ExpiryDate: "Sun Mar 21 2021 08:41:06 GMT+0700 (Indochina Time)",
  PermitNumber: 35786,
  RegNo: 17040,
  PermitArea: "Optimization",
  ZoneCode: 45484,
  ZoneName: "Hettingerstad",
  PermitCategory_Name: "Senior Accounts Executive",
  StaffArea: "Reggie Buckridge",
  StaffOrg: "Maurine Welch",
  FeesOutstanding: "168.00",
  PrintStatus: "Land",
  Status: "New Jersey",
  Permit_DisplayStatus: "Books",
  PermitHolder_Email: "Claude.Jacobs@gmail.com",
  ReferenceNumber: 72429,
  PermitHolder: "Cruz Aufderhar",
  CreatedDate: "Sat Jun 20 2020 18:38:57 GMT+0700 (Indochina Time)",
  FeeType: "Producer",
  AmountIssued: "7.00",
  AmountOutstanding: 245,
  CostCode: 2801,
  InvoiceNumber: 79394,
  SecurePay_Reference: 47464,
  AmountPaid: 58631,
  ReceiptNumber: 14650,
  ReceiptDate: "Mon May 11 2020 16:38:57 GMT+0700 (Indochina Time)",
  ReceiptedBy: "Maida Okuneva",
  Method: "Regional",
  SentToFinanceDate: "Sat Jul 04 2020 04:26:30 GMT+0700 (Indochina Time)",
  SentToFinanceBatch: 57422,
  Sys_DBRowState: "Intelligent",
  ResidentAreaCode: 6782,
  PermitRenewalDate: "Mon Dec 14 2020 03:56:34 GMT+0700 (Indochina Time)",
  MaximumPermits: 33059,
  NoOfFreePermits: 23023,
  NoOfCost1: 93719,
  Cost1: 40185,
  NoOfCost2: 51575,
  Cost2: 17276,
  NoOfCost3: 65850,
  Cost3: 97159,
  AllowableSuburbs: "Port Velda",
  AreaBoundaries: "Weberport",
  Area: "Cassinside",
  Code: 84875,
  Type: "Tactics",
  RenewalDate: "Fri Feb 28 2020 19:51:25 GMT+0700 (Indochina Time)",
  FullAddress: "Port Ahmed",
  AssessmentNo: 20022,
  Reason: "Architect",
  Sys_CreatedBy: "Carson Swaniawski",
  PermitSubType: "Consultant",
  PropertyType: "Representative",
  Org: "collaborative",
  Street: "92222 Sophie Via",
  RenewalStatus: "B2C",
  LetterSent: "Idaho",
  Comments: "",
};
