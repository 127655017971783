import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface ICreateChargeRunButtonProps {
  isPICChargeRun?: boolean;
}

export const CreateChargeRunButton = observer(
  ({ isPICChargeRun = false }: ICreateChargeRunButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();
    return (
      <CCNavButton
        title="Create charge run"
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Create_ChargeRun,
              data: {},
              props: {
                isPICChargeRun: isPICChargeRun,
              },
            },
          ]);
        }}
      />
    );
  }
);
