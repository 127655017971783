import { PPRActionBarNavDropdown } from "@app/products/town-planning/ppr/components/action-bar/nav-dropdown/_index";
import { pprRoute } from "@app/products/town-planning/ppr/route";
import { colPprKeywords } from "@app/products/town-planning/ppr/system-admin/keywords/config";
import { TOWN_PLANNING_PPR_KEYWORDS_LIST_VIEW_URL } from "@app/products/town-planning/ppr/system-admin/keywords/constant";
import { SystemSettingKeywordBookmark } from "@app/products/town-planning/ppr/system-admin/keywords/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { KeywordLite } from "@common/models/keyword";
import { DeleteKeywordButton } from "@common/pages/settings/lookups/keywords/components/action-bar/buttons/delete-keywords/_index";
import { NewKeywordButton } from "@common/pages/settings/lookups/keywords/components/action-bar/buttons/new-keywords/_index";
import { LookupKeywordsTabDetails } from "@common/pages/settings/lookups/keywords/_id/components/reference-sidebar/detail/_index";
import { LookupKeywordsHistoryTab } from "@common/pages/settings/lookups/keywords/_id/components/reference-sidebar/history/_index";
import { SETTINGS_LOOKUPS_KEYWORDS_ROUTE } from "@common/pages/settings/lookups/keywords/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

const nameOf = nameOfFactory<KeywordLite>();

export default () => {
  useCCListViewActionBar({
    title: pprRoute.name,
    leftComponents: [<PPRActionBarNavDropdown />],
    centerComponents: [<NewKeywordButton />, <DeleteKeywordButton />],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={SETTINGS_LOOKUPS_KEYWORDS_ROUTE}
        productType={PRODUCT_TYPE.TownPlanning}
        recordType={RECORDTYPE.CORE_Keyword}
        detail={SystemSettingKeywordBookmark.getBookmarkDetail}
        displayName={SystemSettingKeywordBookmark.getBookmarkDisplayName}
        listViewDetail={SystemSettingKeywordBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          SystemSettingKeywordBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <LookupKeywordsTabDetails /> },
      {
        title: "History",
        component: <LookupKeywordsHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colPprKeywords}
      dataUrl={TOWN_PLANNING_PPR_KEYWORDS_LIST_VIEW_URL}
      state={{
        group: [{ field: nameOf("KeywordType_Name"), dir: "asc" }],
        sort: [
          { field: nameOf("KeywordType_Name"), dir: "asc" },
          { field: nameOf("Sys_SortOrderIndex"), dir: "asc" },
          { field: nameOf("Name"), dir: "asc" },
        ],
      }}
      primaryField={nameOf("Keyword_ID")}
    />
  );
};
