import { IDataForCloseDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ConfirmCloseDialogStore {
  private _dataForCloseDialog?: IDataForCloseDialog = undefined;
  private _isLoadingClose: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get dataForCloseDialog(): IDataForCloseDialog | undefined {
    return this._dataForCloseDialog;
  }
  setDataForCloseDialog = (dataForCloseDialog?: IDataForCloseDialog) => {
    runInAction(() => {
      this._dataForCloseDialog = dataForCloseDialog;
    });
  };

  get isLoadingClose(): boolean {
    return this._isLoadingClose;
  }
  setIsLoadingClose = (isLoadingClose: boolean) => {
    runInAction(() => {
      this._isLoadingClose = isLoadingClose;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoadingClose = false;
      this._dataForCloseDialog = undefined;
    });
  };
}

const confirmCloseDialogStoreContext = createContext(
  new ConfirmCloseDialogStore()
);
export const useConfirmCloseDialogStore = () => {
  return useContext(confirmCloseDialogStoreContext);
};
