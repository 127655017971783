import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colRebatesClaims } from "@app/products/property/assessments/rebates/rebate-claims/config";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { IColumnFields } from "@components/cc-grid/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class RebatesClaimsStore {
  private _columnConfigRebatesClaims: IColumnFields[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get columnConfigRebatesClaims() {
    return this._columnConfigRebatesClaims;
  }
  setColumnConfigRebatesClaims = (columnConfig: IColumnFields[]) => {
    runInAction(() => {
      this._columnConfigRebatesClaims = columnConfig;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  /**
   * Calling api get configuration column
   * process columns
   */
  loadViewConfigRebateClaims = async () => {
    this.setIsLoading(true);
    this.setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.OData_Rebates_RebateClaims,
      colRebatesClaims
    );
    if (Array.isArray(response)) {
      this.setColumnConfigRebatesClaims(response);
    } else {
      this.setResponseLoadError(response);
    }
    this.setIsLoading(false);
  };

  //restore
  resetStore = () => {
    runInAction(() => {
      this.setColumnConfigRebatesClaims([]);
      this.setIsLoading(false);
      this.setResponseLoadError();
    });
  };

  //reload
  reLoadApiConfig = () => {
    this.loadViewConfigRebateClaims();
  };
}

const rebatesStoreContext = createContext(new RebatesClaimsStore());
export const useRebatesClaimsStore = () => {
  return useContext(rebatesStoreContext);
};
