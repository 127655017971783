import { ICalculateValueBalance } from "@/app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/balance-adjustment/model";
import { isNil, isNumber } from "lodash";

export const validatorBalanceAdjustment = (values: any): string | undefined => {
  const newDeferredDutyAmount: number | undefined | null =
    values["DeferredDutyAmount"];
  const newInterestAmount: number | undefined | null = values["InterestAmount"];
  const newOtherAmount: number | undefined | null = values["OtherAmount"];
  if (
    isNumber(newDeferredDutyAmount) &&
    isNumber(newInterestAmount) &&
    isNumber(newOtherAmount)
  )
    return undefined;
  return "New balances must be not empty.";
};

export const calculateValueAdjustment = ({
  existValue,
  value,
}: ICalculateValueBalance): number | null => {
  if (isNullOrUndefined(existValue, value)) return null;
  if (!isNumber(existValue)) existValue = 0;
  if (!isNumber(value)) value = 0;

  return value - existValue;
};

export const calculateValueNewBalance = ({
  existValue,
  value,
}: ICalculateValueBalance): number | null => {
  if (isNullOrUndefined(existValue, value)) return null;
  if (!isNumber(existValue)) existValue = 0;
  if (!isNumber(value)) value = 0;

  return value + existValue;
};

const isNullOrUndefined = (
  firstValue?: number | null,
  secondValue?: number | null
): boolean => {
  return isNil(firstValue) && isNil(secondValue);
};
