import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { SystemLogsDetailDialog } from "@common/pages/settings/system-admin/system-logs/components/dialogs/system-logs-detail/_index";
import { colSystemLogs } from "@common/pages/settings/system-admin/system-logs/config";
import { Log } from "@common/pages/settings/system-admin/system-logs/model";
import { nameOfFactory } from "@common/utils/common";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import Loading from "@components/loading/Loading";
import { cloneDeep } from "lodash";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<Log>();

export default () => {
  const [isShowDetailDialog, setIsShowDetailDialog] = useState(false);
  const [selectedLog, setSelectedLog] = useState<Log | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    const responseCheckGlobalSettings = await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );

    if (responseCheckGlobalSettings) {
      await checkPermissionForCurrentMenu(
        FormActionCheckPermission.SETTINGS_MENU,
        1
      );
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
    ],
  });

  const updateSystemLogs = useMemo(() => {
    const retVal = cloneDeep(colSystemLogs);
    retVal[0].handleOnClick = (e: Log) => {
      setSelectedLog(e);
      setIsShowDetailDialog(true);
    };
    return retVal;
  }, []);

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <>
      <CCProductListView
        columnFields={updateSystemLogs}
        dataUrl={`/odata/core/internal/systemlogs/GetSystemsLogs?$count=true&`}
        primaryField={nameOf("ID")}
        state={{ sort: [{ field: nameOf("Sys_CreatedDate"), dir: "desc" }] }}
        disabled={!hasPermission}
      />
      {isShowDetailDialog && selectedLog && (
        <SystemLogsDetailDialog
          onClose={() => {
            setIsShowDetailDialog(false);
          }}
          LogDetailData={selectedLog}
        />
      )}
    </>
  );
};
