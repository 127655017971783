import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingBAMSNewApplications: IColumnFields[] = [
  {
    field: BuildingFieldMapping.ApplicationNumber,
    title: "Number",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.PermitNumber,
    title: "Permit Number",
  },
  {
    field: BuildingFieldMapping.AssessmentNo,
    title: "Assess Number",
  },
  {
    field: BuildingFieldMapping.SiteAddress,
    title: "Site Address",
  },
  {
    field: BuildingFieldMapping.BAMSRefNo,
    title: "BAMS Reference Number",
  },
];
