import { DTO_Transaction } from "@app/products/property/assessments/[id]/components/child-screens/rebates/model";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
import AppliedOnAssessmentDetails from "./components/detail/_index";

export interface IRebatesAppliedOnAssessment {
  dataRebatesAppliedOnAssessment: DTO_Transaction[];
  columnRebatesAppliedOnAssessment: IColumnFields[];
}
export const RebatesAppliedOnAssessment = ({
  dataRebatesAppliedOnAssessment,
  columnRebatesAppliedOnAssessment,
}: IRebatesAppliedOnAssessment) => {
  return (
    <div className="cc-field">
      <label className="cc-label">Rebates applied</label>
      <CCGrid
        className="cc-rebates-concession-applied-on-assessment-grid"
        data={dataRebatesAppliedOnAssessment}
        columnFields={columnRebatesAppliedOnAssessment}
        primaryField="TransactionId"
        detail={AppliedOnAssessmentDetails}
      />
    </div>
  );
};
