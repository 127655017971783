import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { getDropdownValue } from "@common/utils/common";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { FormRenderProps } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export type IProcessExternalSummaryProps = {
  formRenderProps: FormRenderProps;
};

const ProcessExternalSummary = ({
  formRenderProps,
}: IProcessExternalSummaryProps) => {
  const { valueGetter, onChange } = formRenderProps;
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();

  //Value getter
  const baseURL = configData
    ? valueGetter(configData["BaseURL"]?.FieldName)
    : "";
  const keyType = configData
    ? valueGetter(configData["KeyType"]?.FieldName)
    : "";
  const key = configData ? valueGetter(configData["Key"]?.FieldName) : "";

  //States
  const [exampleURL, setExampleURL] = useState<string>("");

  //Handle events
  const handleChangeExampleURL = (value: string, field: string) => {
    onChange(field, { value: value });
    if (!configData) return;

    const keyTypeId =
      field === configData["KeyType"]?.FieldName ? value : keyType;
    let keyTypeParam =
      getDropdownValue(keyTypeId, configData["KeyType"]?.LookupItems, "Id")
        ?.Name ?? "";

    const keyParam = field === configData["Key"]?.FieldName ? value : key;
    let url: string = `${baseURL}/Login.aspx?ReturnUrl=/Secure/Content/Core/CorporateAdmin/ProcessExternal/Summary.aspx?keytype=${keyTypeParam}%26key=${keyParam}`;
    setExampleURL(url);
  };

  return configData ? (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <CCSearchComboBoxGlobalSettings
          data={configData["KeyType"]}
          isEditing={isEditing}
          onChange={(event: ComboBoxChangeEvent) => {
            handleChangeExampleURL(
              event.value,
              configData["KeyType"]?.FieldName
            );
          }}
        />
        <InputGlobalSettings
          data={configData["Key"]}
          isEditing={isEditing}
          onChange={(event: InputChangeEvent) => {
            handleChangeExampleURL(event.value, configData["Key"]?.FieldName);
          }}
        />
      </div>
      <CCValueField
        name="ExampleURL"
        label={configData["ExampleURL"]?.Title ?? ""}
        value={
          //TODO: Disable the clickable link for now, will create ProcessExternal screen and link to it later
          exampleURL
        }
      />
    </section>
  ) : null;
};

export default observer(ProcessExternalSummary);
