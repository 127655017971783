import { eventEmitter } from "@/App";
import { COMMENT_ACCORDION_GRID_ID } from "@app/core/comments/constant";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import {
  getInitialDataMasterProperty,
  postProcessModifyMasterProperty,
} from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/api";
import { AssociationsFormStep } from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/components/form-elements/associations/_index";
import { DetailsFormStep } from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/components/form-elements/detail/_index";
import { ConfirmCloseButton } from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/dialog/confirm-close-button/_index";
import {
  DTO_MP_Assessment,
  DTO_MasterProperty_LOVs,
  DTO_Workflow_MasterProperty_Edit,
  EKeysOfSteps,
  keysOfSendSteps,
} from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/model";
import { useModifyMasterPropertyDialogStore } from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/store";
import { abandonWorkflow } from "@app/products/property/components/action-bar/property-workflow/api";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import {
  getSuffixTitle,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { PROPERTY_ACTIONS_APPROVED_LIST_ROUTE } from "@app/products/property/components/action-bar/property-workflow/constant";
import {
  IListDialog,
  IProcessWorkflow,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { PropertyDocumentEventType } from "@app/products/property/components/child-screen/documents/constant";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { head, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IModifyMasterPropertyDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  masterPropertyId: number;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  isShowSearchType?: boolean;
  isShowReasonRejection?: boolean;
  isShowReason?: boolean;
  isSaveOnNextStep?: boolean;
  suffixTitle?: string;
  isRedirectManagePage?: boolean;
}

export const ModifyMasterPropertyDialog = observer(
  ({
    onClose,
    masterPropertyId,
    dataFromActionList,
    prefixTitle,
    isSaveOnNextStep = false,
    suffixTitle,
    isRedirectManagePage = false,
  }: IModifyMasterPropertyDialogProps) => {
    const history = useHistory();
    const {
      isReadOnly,
      isIncompleteMode,
      isShowCancelWorkflowButton,
      statusBadge,
      isFromActionList,
      isToBeApprovalMode,
    } = usePropertyWorkflow(dataFromActionList);
    const { currentFormTitle } = useCommonProductStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setListDialog } = usePropertyWorkflowStore();
    const {
      setIsLoadingOnDialog,
      setIsLoadingOnDialogCancel,
      setMasterPropertyLOVs,
    } = useModifyMasterPropertyDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { clearSelectedItems } = useCCProductListViewStore();

    const [isShowCloseDialog, setIsShowCloseDialog] = useState<boolean>(false);
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    const [modifyMasterPropertyInitData, setModifyMasterPropertyInitData] =
      useState<DTO_Workflow_MasterProperty_Edit>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);

    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      // const title = "Modify Master Property";
      const title = currentFormTitle(formId ?? 0) || "Modify Master Property";

      return getTitleWorkflow(
        title,
        prefixTitle,
        getSuffixTitle(
          suffixTitle,
          isToBeApprovalMode,
          workflowHeader?.WorkflowApprovals
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, isToBeApprovalMode, workflowHeader]);

    const initialValues = useMemo(() => {
      let initDetails: any = {};
      let initAssociations: {
        Assessments: DTO_MP_Assessment[];
      } = { Assessments: [] };
      let initSecondaryWorkflow: any = {};
      if (modifyMasterPropertyInitData) {
        const workflowDetail = modifyMasterPropertyInitData?.WorkflowDetail;

        //Details
        if (workflowDetail?.MasterProperty) {
          initDetails = {
            ...initDetails,
            ...workflowDetail?.MasterProperty,
          };
        }

        if (workflowDetail?.ReasonForChange !== 0) {
          initDetails.ReasonForChange = workflowDetail?.ReasonForChange;
        }

        //Associations - Assessment List
        initAssociations.Assessments = workflowDetail?.Assessments ?? [];

        //---step Secondary Workflow---
        initSecondaryWorkflow = secondaryWorkflowUtilProcessing.processDataInit(
          modifyMasterPropertyInitData
        );
      }

      return {
        [EKeysOfSteps.Details]: initDetails,
        [EKeysOfSteps.Associations]: initAssociations,
        [EKeysOfSteps.Comments]: {},
        [EKeysOfSteps.Documents]: {},
        [EKeysOfSteps.SecondaryWorkflow]: initSecondaryWorkflow,
      };
    }, [modifyMasterPropertyInitData]);

    const steps: IStep[] = [
      {
        label: "Details",
        initialValues: initialValues.MasterProperty,
        component: DetailsFormStep,
        visible: true,
        key: EKeysOfSteps.Details,
        options: {
          isReadOnly,
          workflowDraftId,
        },
      },
      {
        label: "Related",
        initialValues: initialValues.Assessments,
        component: AssociationsFormStep,
        visible: true,
        key: EKeysOfSteps.Associations,
        options: {
          isReadOnly,
          workflowDraftId,
        },
      },
      {
        label: "Comments",
        initialValues: initialValues.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfSteps.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Master_Property,
        },
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValues.Documents,
        visible: true,
        key: EKeysOfSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          //Only use for modify workflow
          componentId: masterPropertyId,
          workflowType: WorkflowTypes.Edit_MasterProperty,
        },
      },
      {
        label: "Workflow",
        initialValues: initialValues.SecondaryWorkflow,
        component: WorkflowStepFormStep,
        visible: true,
        key: EKeysOfSteps.SecondaryWorkflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];

    /**
     * Process data before sending to API
     * @param data
     */
    const processData = (data: any) => {
      let workflowDetail: any = {};
      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfSendSteps.includes(key as EKeysOfSteps)) {
          return { [key]: value };
        }
      });
      workflowDetail.Master_Property_Id =
        modifyMasterPropertyInitData?.WorkflowDetail?.Master_Property_Id;

      if (sendSteps) {
        for (const [key, value] of Object.entries(sendSteps)) {
          if (value && value._option) {
            delete value._option;
          }
          let dataStep;
          switch (key) {
            case EKeysOfSteps.Details:
              dataStep = { ...value };
              delete dataStep.ReasonForChange;
              workflowDetail.ReasonForChange = value.ReasonForChange;
              break;
            case EKeysOfSteps.Associations:
              dataStep = [...value.Assessments];
              break;
            default:
              dataStep = { ...value };
          }
          if (key === EKeysOfSteps.SecondaryWorkflow) {
            secondaryWorkflowUtilProcessing.processData(
              value,
              workflowHeader,
              workflowDetail
            );
          } else {
            workflowDetail[key as keyof DTO_Workflow_MasterProperty_Edit] =
              dataStep;
          }
        }
      }
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          await sendApproveMasterProperty(processData(data));
          break;
        case EListSubmitButton.Save:
          await sendSaveMasterProperty(processData(data), true);
          break;
        case EListSubmitButton.SaveWorkflow:
          await sendSaveMasterProperty(processData(data), false, true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.ConfirmCloseYes:
        case EListSubmitButton.Close:
          await sendParkMasterProperty(processData(data));
          break;
      }
    };

    /**
     * Handle reject process
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessModifyMasterProperty,
          dataReject: data,
          defaultSuccessMessage:
            "Create master property application was rejected successfully",
          defaultErrorMessage: "Create master property reject failed",
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_MasterProperty_Edit
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessModifyMasterProperty,
        dataSendBack: payload,
        defaultSuccessMessage:
          "Master property approval sent back successfully",
        defaultErrorMessage: "Send master property back failed",
      });
    };

    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_MasterProperty_Edit
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessModifyMasterProperty,
        dataReallocate: payload,
        defaultSuccessMessage: "Master property approved successfully",
        defaultErrorMessage: "Approve master property failed",
      });
    };

    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_MasterProperty_Edit
    ) => {
      const dataProcessed = processData(payload);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage: `The master property will be created based on the information provided. Are you sure you want to submit?`,
      });
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_MasterProperty_Edit
    ) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_MasterProperty_Edit> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          clearSelectedItems();
          eventEmitter.emit(CCGridEventType.RefreshOData);
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              e?.Notification ?? `New master property created successfully`,
            type: "success",
          };
          !isRedirectManagePage && pushNotification(notificationContent);
        },
        defaultFailedMessage: `Create new master property failed`,
        modeProcess: WorkflowProcessMode.Finish,
      };

      const setLoading = () => {};
      //calling api process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };

    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_MasterProperty_Edit>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      let secondaryWorkflowSelectedList: IListDialog[] = [];
      if (modeProcess === WorkflowProcessMode.Finish) {
        if (payload.WorkflowDetail?.SecondaryWorkflowSelectedList) {
          secondaryWorkflowSelectedList =
            payload.WorkflowDetail.SecondaryWorkflowSelectedList ?? [];
          delete payload.WorkflowDetail.SecondaryWorkflowSelectedList;
        }
      }
      const response = await postProcessModifyMasterProperty(
        modeProcess,
        payload
      );

      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (!isFromActionList) {
            //Refresh document and comment accordion after updated successfully
            eventEmitter.emit(PropertyDocumentEventType.RefreshData);
            eventEmitter.emit(CCGridEventType.RefreshOData, {
              gridIds: [COMMENT_ACCORDION_GRID_ID],
            });
          }
          actionSuccess(response?.data);
          //Show secondary workflow
          if (
            modeProcess === WorkflowProcessMode.Finish &&
            secondaryWorkflowSelectedList?.length
          ) {
            setListDialog(secondaryWorkflowSelectedList);
          }
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    const sendSaveMasterProperty = async (
      payload: DTO_Workflow_MasterProperty_Edit,
      isCloseDialog: boolean = false,
      isRefreshWorkflowData: boolean = false
    ) => {
      const response = await postProcessModifyMasterProperty(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultSuccessMessage = "Master property was saved successfully";
      const defaultFailedMessage =
        "The modify master property process could not be saved";
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (isCloseDialog) {
          onClose();
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        }
        if (isFirstSave) {
          setIsFirstSave(false);
          setWorkflowDraftId(response?.data?.ID ?? 0);
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID ?? 0,
            },
          });
        }
        // TODO: Show notification after reloading the step -> enhance later
        if (isRefreshWorkflowData) {
          getWorkflowData().then(() => {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: response?.data?.Notification ?? defaultSuccessMessage,
                type: "success",
              });
          });
        }
        return true;
      } else {
        const showNotification = () => {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title:
                (isRefreshWorkflowData
                  ? head(response?.data?.Errors)
                  : response.data?.ErrorMessage) ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        };
        if (isRefreshWorkflowData) {
          getWorkflowData().then(showNotification);
        } else {
          showNotification();
        }
        return false;
      }
    };

    const sendApproveMasterProperty = async (
      payload: DTO_Workflow_MasterProperty_Edit
    ) => {
      setIsLoadingApprove(true);
      const defaultSuccessMessage =
        "Modify master property approved successfully";
      const defaultFailedMessage = "Approve modify master property failed";

      const response = await postProcessModifyMasterProperty(
        WorkflowProcessMode.Approve,
        payload
      );
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          history.push(PROPERTY_ACTIONS_APPROVED_LIST_ROUTE);
          pushNotification({
            title: response.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
          });
      }
      setIsLoadingApprove(false);
    };

    const sendParkMasterProperty = async (
      payload: DTO_Workflow_MasterProperty_Edit
    ) => {
      setIsLoadingPark(true);
      setIsLoadingOnDialog(true);
      const defaultSuccessMessage = "Master property parked successfully";
      const defaultFailedMessage = "Park master property failed";
      const response = await postProcessModifyMasterProperty(
        WorkflowProcessMode.Park,
        payload
      );
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
          });
      }
      setIsLoadingOnDialog(false);
      setIsLoadingPark(false);
    };

    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessModifyMasterProperty,
          dataCancel: data,
          defaultSuccessMessage:
            "Modify master property was cancelled successfully.",
          defaultErrorMessage: "Modify master property could not be cancelled.",
        });
      } else {
        onClose();
      }
    };

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setIsShowCloseDialog(true);
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name &&
        dataFromActionList.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    const handleConfirmNoRetain = async () => {
      setIsLoadingOnDialogCancel(true);
      await abandonWorkflow().then(() => {
        setIsLoadingOnDialogCancel(false);
        onClose();
      });
    };

    const handleNextButton = async (data: any) => {
      setIsLoadingOnNext(true);
      return await sendSaveMasterProperty(processData(data));
    };

    const getWorkflowData = async () => {
      const id = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      return await getInitialDataMasterProperty(
        WorkflowTypes.Edit_MasterProperty,
        masterPropertyId,
        id
      ).then((response) => {
        if (Array.isArray(response)) {
          const [lovsMasterPropertyResponse, workflowDataResponse] = response;
          const lovsMasterPropertyData = lovsMasterPropertyResponse?.data;
          const workflowData = workflowDataResponse?.data;
          if (
            isSuccessResponse(response[0]) &&
            isSuccessResponse(response[1]) &&
            lovsMasterPropertyData &&
            workflowData
          ) {
            //set Lovs Data MasterProperty
            setMasterPropertyLOVs(lovsMasterPropertyData);
            //set Modify MasterProperty InitData
            setModifyMasterPropertyInitData({
              WorkflowDetail: workflowData?.WorkflowDetail,
              WorkflowHeader: workflowData?.WorkflowHeader,
            });
            //set workflow header and workflow Draft Id
            if (workflowData?.WorkflowHeader) {
              setWorkflowHeader(workflowData.WorkflowHeader);
              setWorkflowDraftId(
                workflowData?.WorkflowHeader?.WorkflowDraft
                  ?.Workflow_Draft_Id ?? 0
              );
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
          } else {
            let responseError: APIResponse<
              | DTO_MasterProperty_LOVs
              | DTO_Workflow_MasterProperty_Edit
              | ResponsePacket
            > = response[0];
            if (!isSuccessResponse(response[1])) {
              responseError = response[1];
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error:
                  (responseError.data as ResponsePacket)?.Errors ??
                  "Load workflow failed",
              },
            });
          }
        } else {
          const responseError = response as APIResponse;
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error: "Load workflow failed",
            },
          });
        }
      });
    };

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          onSubmit={handleSubmit}
          listButtonId={listSubmitButton}
          initialValues={initialValues}
          initialSteps={steps}
          saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
          renderForm={(renderProps: ICCFormStepRender) => (
            <CCDialog
              maxWidth="60%"
              titleHeader={titleHeader}
              onClose={() => handleCloseDialog(renderProps)}
              bodyElement={renderProps.children}
              badge={statusBadge}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingApprove || isLoadingOnNext || isLoadingPark
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}

                    {isToBeApprovalMode &&
                      workflowHeader?.OfficerCanApprove && (
                        <>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.SendBack}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Send Back
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reallocate}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reallocate
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Approve}
                            disabled={
                              isLoadingApprove ||
                              renderProps.nextButton.disabled
                            }
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                            iconClass={
                              isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reject}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                  {isShowCloseDialog && (
                    <ConfirmCloseButton
                      onClose={() => setIsShowCloseDialog(false)}
                      onConfirmYes={renderProps.submitButton.onClick}
                      onConfirmNo={handleConfirmNoRetain}
                    />
                  )}
                </>
              }
            />
          )}
        />
      </>
    );
  }
);
