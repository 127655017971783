import { IAttributeData } from "@app/products/property/model";
import { useTitleAttributesUDAStore } from "@app/products/property/titles/[id]/components/child-screens/attributes-uda/store";
import { useTitleStore } from "@app/products/property/titles/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { colTitleAttributesUDA } from "./config";

const nameOf = nameOfFactory<IAttributeData>();
export const TitleAttributesUDA = observer(() => {
  const { attributesUDA, loadAttributesUDA, resetStore } =
    useTitleAttributesUDAStore();
  const { titleId } = useTitleStore();

  useEffect(() => {
    if (titleId) loadAttributesUDA(titleId);
    return () => resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleId]);

  return (
    <div className="cc-title-attributes-uda">
      <div className="cc-grid-control-between">
        <div className="cc-grid-control-left">
          <label className="cc-label">User defined attributes</label>
        </div>
      </div>
      <CCGrid
        className="cc-attributes-uda-grid"
        data={attributesUDA ?? []}
        columnFields={colTitleAttributesUDA}
        primaryField={nameOf("Id")}
      />
    </div>
  );
});
