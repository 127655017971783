import { history } from "@/AppRoutes";
import { Attachment } from "@app/core/documents/model";
import {
  KeyValuePair,
  MAILMERGEDOCUMENTUSAGE,
  MAILMERGESAVEFORMAT,
} from "@app/core/new-mail-merge/dialogs/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { DBRowState } from "@common/models/baseClassStandard";
import {
  getDocumentTemplateById,
  getMailMergeDataset,
  getUsage,
  postDocumentTemplate,
} from "@common/pages/settings/lookups/documents/_id/api";
import { eBooleanType } from "@common/pages/settings/lookups/documents/_id/components/child-screens/general/components/form-element/model";
import { SETTINGS_LOOKUPS_DOCUMENTS_ROUTE } from "@common/pages/settings/lookups/documents/_id/constant";
import {
  CCDocument,
  CCDocument_Ext,
  ILookupDocumentParentSection,
  LookupDocumentActions,
} from "@common/pages/settings/lookups/documents/_id/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class LookupDocumentStore {
  private _lookupDocument?: CCDocument = undefined;
  private _documentUsage?: KeyValuePair<number, string>[] = [];
  private _documentMailMergeDataset?: KeyValuePair<number, string>[] = [];
  private _isLoading: boolean = false;
  private _isInactive: boolean = false;
  private _parentSection?: ILookupDocumentParentSection = undefined;
  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get document() {
    return toJS(this._lookupDocument);
  }
  setDocument = (document?: CCDocument) => {
    runInAction(() => {
      this._lookupDocument = document;
    });
  };

  setLookupDocumentWithLoading = async (document?: CCDocument) => {
    runInAction(() => {
      this._isLoading = true;
      this._lookupDocument = document;
      this._isLoading = false;
    });
  };

  get documentUsage() {
    return toJS(this._documentUsage);
  }
  setDocumentUsage = (documentUsage?: KeyValuePair<number, string>[]) => {
    runInAction(() => {
      this._documentUsage = documentUsage;
    });
  };

  get documentMailMergeDataset() {
    return toJS(this._documentMailMergeDataset);
  }
  setDocumentMailMergeDataset = (
    documentMailMergeDataset?: KeyValuePair<number, string>[]
  ) => {
    runInAction(() => {
      this._documentMailMergeDataset = documentMailMergeDataset;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: ILookupDocumentParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get isInactive() {
    return this._isInactive;
  }
  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get documentId() {
    return toJS(this.document?.Document_ID);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._lookupDocument = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._parentSection = undefined;
      this._onSubmit = undefined;
      this._isInactive = false;
    });
  };

  reloadLookupDocument = async (): Promise<boolean> => {
    if (this.documentId) {
      return await this.loadDocument(this.documentId);
    }
    return false;
  };

  loadDocument = async (
    documentId: number,
    isNew?: boolean
  ): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    // Load LOVs data
    const resDocumentUsage = await getUsage();
    if (isSuccessResponse(resDocumentUsage)) {
      if (resDocumentUsage?.data) this.setDocumentUsage(resDocumentUsage?.data);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: resDocumentUsage?.error ?? "Usage load failed",
        type: "warning",
      });
    }

    const resDocumentMailMergeDataset = await getMailMergeDataset();
    if (isSuccessResponse(resDocumentMailMergeDataset)) {
      if (resDocumentMailMergeDataset?.data)
        this.setDocumentMailMergeDataset(resDocumentMailMergeDataset?.data);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title:
          resDocumentMailMergeDataset?.error ??
          "Mail merge dataset load failed",
        type: "warning",
      });
    }

    if (isNew) {
      this.setDocument({
        AllowLiveMerge: true,
        Document_Usage_ENUM_Int: MAILMERGEDOCUMENTUSAGE.CustomDocumentTemplates,
      } as CCDocument_Ext);
      if (!isNil(this.parentSection?.parentDocument)) {
        this.setDocument(this.parentSection?.parentDocument);
      }
    } else {
      let newDocument = undefined;
      const response = await getDocumentTemplateById(documentId);
      if (isSuccessResponse(response)) {
        newDocument = {
          ...response?.data,
          _options: {
            SaveAsFormat:
              response?.data?.SaveFormat_ENUM_Int === MAILMERGESAVEFORMAT.Pdf
                ? eBooleanType.TRUE
                : eBooleanType.FALSE,
            Availability:
              response?.data?.Sys_DBRowState === DBRowState.Active
                ? eBooleanType.TRUE
                : eBooleanType.FALSE,
            FileUpload: [
              {
                FileEntry: {
                  ...response?.data?.FileEntry,
                  Base64FileAttachment:
                    response?.data?.FileEntry?.FileAttachment,
                },
              },
            ] as Attachment[],
            Usages: this._documentUsage ?? [],
            MailMergeDataset: this._documentMailMergeDataset ?? [],
          },
        } as CCDocument_Ext;

        if (response.data?.Sys_DBRowState === DBRowState.Inactive) {
          this.setIsInactive(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important Note",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        } else {
          this.setIsInactive(false);
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      this.setDocument(newDocument);
      if (this.parentSection?.notification) {
        this.parentSection?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setParentSection({
          ...this._parentSection,
          notification: [],
        } as ILookupDocumentParentSection);
      }
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  saveDocument = async (
    documentInfo: CCDocument,
    action: LookupDocumentActions
  ) => {
    this.setIsLoading(true);
    const response = await postDocumentTemplate(documentInfo);
    this.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      if (this.documentId) await this.loadDocument(this.documentId);
      this.runActions(action, response);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Save document failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
  };

  runActions = (action: LookupDocumentActions, response?: APIResponse) => {
    switch (action) {
      case LookupDocumentActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Document saved successfully",
          type: "success",
        });
        break;
      case LookupDocumentActions.New:
        appNotificationStore.clearNotifications();
        history.replace(
          `${SETTINGS_LOOKUPS_DOCUMENTS_ROUTE}/${response?.data?.ID}`,
          {
            notification: [
              {
                title: "Document saved successfully",
                type: "success",
              },
            ],
          }
        );
        break;
      default:
        break;
    }
  };
}

export const lookupDocumentInstance = new LookupDocumentStore();
const lookupDocumentContext = createContext(lookupDocumentInstance);
export const useLookupDocumentStore = () => {
  return useContext(lookupDocumentContext);
};
