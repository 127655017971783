import { AddToNoticeGroupDialog } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/update-notice-group/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

interface IAddToNoticeGroupButtonProps {
  isHidden: boolean;
}

export const AddToNoticeGroupButton = ({
  isHidden,
}: IAddToNoticeGroupButtonProps) => {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);

  if (isHidden) return null;

  return (
    <>
      <CCNavButton
        title="Update notice group"
        onClick={() => setIsShowDialog(true)}
      />
      {isShowDialog && (
        <AddToNoticeGroupDialog onClose={() => setIsShowDialog(false)} />
      )}
    </>
  );
};
