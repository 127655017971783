import { msalInstance } from "@/App";
import { history } from "@/AppRoutes";
import AuthService from "@common/apis/auth.service";
import { ExpiredTimeToGetNewToken } from "@common/apis/model";
import { dataTransformer } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { setSessionRedirectURL } from "@common/pages/login/util";
import { LOGIN_MODE } from "@common/pages/settings/constant";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { globalStoreInstance } from "../stores/global/store";

export class CoreAPIService {
  private static axiosClient: AxiosInstance;
  private static requests: Array<() => void> = [];
  private static isRefreshingToken = false;

  initialize(config?: AxiosRequestConfig) {
    CoreAPIService.axiosClient = axios.create({
      transformRequest: [dataTransformer].concat(
        axios.defaults.transformRequest
      ),
      ...config,
      timeout: 0,
    });
    CoreAPIService.axiosClient.interceptors.request.use(
      async function (requestConfig: AxiosRequestConfig) {
        let token = AuthService.getToken();
        if (token && token !== "") {
          const expTime = AuthService.getTokenExpireTime(token);
          if (expTime - Date.now() <= ExpiredTimeToGetNewToken) {
            if (!CoreAPIService.isRefreshingToken) {
              CoreAPIService.isRefreshingToken = true;
              if (
                globalStoreInstance.allSetting.authenticationProvider ===
                LOGIN_MODE.AZUREAD
              ) {
                await msalInstance
                  .acquireTokenSilent({
                    scopes:
                      globalStoreInstance.allSetting.azureAD?.scopes || [],
                    account: msalInstance.getAllAccounts()[0],
                  })
                  .then((response: any) => {
                    const currentUser = AuthService.getCurrentUser();
                    const userData = {
                      ...currentUser,
                      token: response?.accessToken,
                    };
                    localStorage.setItem("userData", JSON.stringify(userData));
                  })
                  .catch((error) => {
                    console.error("acquireTokenSilent error", error);
                    localStorage.removeItem("userData");
                    history.push("/login");
                  });
              } else {
                const result = await AuthService.getTokenSilently();
                let currentUserData = JSON.parse(
                  localStorage.getItem("userData") || "null"
                );
                currentUserData.access_token = result?.data?.access_token;
                //store user access token & refresh token into local storage
                AuthService.storeUserData(currentUserData);
              }

              CoreAPIService.isRefreshingToken = false;
              //release other requests
              CoreAPIService.requests.forEach((fnc: any) => fnc());
            } else {
              ///wait for the refreshing token progress until it is done
              await new Promise<void>((resolve) => {
                CoreAPIService.requests.push(() => {
                  resolve();
                });
              });
            }
          }
        }

        requestConfig.headers = {
          "Content-Type":
            requestConfig.headers?.["Content-Type"] ?? "application/json",
          Authorization: token ? `Bearer ${AuthService.getToken()}` : "",
          "X-API-KEY": globalStoreInstance.allSetting.apiKey,
        };
        return requestConfig;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    CoreAPIService.axiosClient.interceptors.response.use(
      (response: AxiosResponse): AxiosResponse => {
        return response;
      },
      function (error) {
        const response = error.response;
        const responseCode = error.code;
        let status = APIResponseStatus.NETWORK_ERROR;
        if (response && response.status === APIResponseStatus.UN_AUTHORIZE) {
          setSessionRedirectURL();
          //@TODO: Should call handleOnLogout() or requestRefreshToken()
          history.push("/login");
          localStorage.removeItem("userData");
        }
        if (responseCode === "ECONNABORTED") {
          status = APIResponseStatus.TIME_OUT;
        }

        return Promise.reject({
          ...(response ?? {
            status,
          }),
          errorCause: error,
        });
      }
    );
  }

  static getClient() {
    if (!this.axiosClient) {
      new CoreAPIService().initialize();
      return this.axiosClient;
    }
    return this.axiosClient;
  }
}
