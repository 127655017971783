import { NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colNominatedProperty: IColumnFields[] = [
  {
    field: "AssessmentId",
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: "PropertyAddress", title: "Property Address" },
  {
    field: "Role",
    title: "Role",
  },
];
