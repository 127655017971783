import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const RebateConcessionCardContactTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  return (
    <PropertyContactTab
      id={lastSelectedRow?.Card_Holder_Id}
      componentNumber={eComponent.Entity}
    />
  );
});
