import { getAssignContractor } from "@app/products/property/fire-prevention/components/dialogs/assign/api";
import { colAssignContractor } from "@app/products/property/fire-prevention/components/dialogs/assign/config";
import { IAssignContractor } from "@app/products/property/fire-prevention/components/dialogs/assign/model";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IAssignDialogProps {
  onAssign: (data: any) => void;
  onClose: () => void;
}

export const AssignDialog = ({ onAssign, onClose }: IAssignDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assignContractorData, setAssignContractorData] = useState<
    IAssignContractor[]
  >([]);
  const [selectedContractor, setSelectedContractor] = useState<
    IAssignContractor[]
  >([]);
  const nameOf = nameOfFactory<IAssignContractor>();

  useEffectOnce(() => {
    setIsLoading(true);
    getAssignContractor().then((response) => {
      if (!response || response.length === 0) {
        setIsLoading(false);
        return;
      }
      setAssignContractorData(response);
      setIsLoading(false);
    });
  });

  return (
    <CCDialog
      maxWidth="55%"
      maxHeight="38%"
      titleHeader={"Select Contractor to Clear Property"}
      onClose={onClose}
      bodyElement={
        <div className="cc-form">
          <div className="cc-field-group">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCGrid
                  isLoading={isLoading}
                  data={assignContractorData || []}
                  columnFields={colAssignContractor}
                  primaryField={nameOf("ContractorId")}
                  selectableMode={"single"}
                  onSelectionChange={(data) => setSelectedContractor(data)}
                />
              </div>
            </div>
          </div>
        </div>
      }
      footerElement={
        <div className={"cc-dialog-footer-actions-right"}>
          <Button className="cc-dialog-button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            name="EnterAndNew"
            themeColor="primary"
            className={"cc-dialog-button"}
            type="button"
            onClick={() => onAssign(selectedContractor)}
            disabled={!selectedContractor.length}
          >
            Set as Contractor
          </Button>
        </div>
      }
    />
  );
};
