import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";

export const getKeywords = async (
  keywordType: KEYWORD_TYPE,
  productType: PRODUCT_TYPE_NUMBER
): Promise<APIResponse<CoreKeyword[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<CoreKeyword[]>(
      "/api/core/int/getkeywordbykeywordtypeandproduct",
      {
        params: {
          keywordType: keywordType,
          productType: productType,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getKeywordsByType = async (
  keywordType: KEYWORD_TYPE,
  productType: PRODUCT_TYPE_NUMBER
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      `/odata/core/internal/cckeyword/GetKeywordsByType(keywordType=${keywordType},productType=${productType})`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
