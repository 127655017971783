import { cslRoute } from "@app/products/csl/route";
import { CCActionBarNavDropdown } from "@components/cc-list-view-action-bar/components/nav-dropdown/_index";
import React from "react";

interface ICSLActionBarNavDropdownProps {
  category: string;
}

export const CSLActionBarNavDropdown = ({
  category,
}: ICSLActionBarNavDropdownProps) => {
  return (
    <CCActionBarNavDropdown
      category={category}
      product={cslRoute.path}
      route={cslRoute}
    />
  );
};
