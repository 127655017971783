import { eventEmitter } from "@/App";
import { CORE_OFFICERS_ODATA } from "@app/core/components/common/constant";
import { isSuccessResponse } from "@common/apis/util";
import { postChangeOwner } from "@common/pages/report/integrated-reports/component/buttons/change-owner/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { IOfficers } from "@components/cc-contact-manager/model";
import { CCContactManager } from "@components/cc-contact-manager/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ChangeOwnerButton = observer(() => {
  const [isShowChangeOwnerDialog, setIsShowChangeOwnerDialog] = useState(false);
  const { gridSelectedIds, isEmptyGridSelectedRow } =
    useCCProductListViewStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { pushNotification } = useCCAppNotificationStore();
  const handleOnSubmit = async (data: IOfficers[]) => {
    setIsLoading(true);
    const response = await postChangeOwner(gridSelectedIds, data[0].ID);
    if (isSuccessResponse(response) && response?.data?.FailureCount === 0) {
      setIsShowChangeOwnerDialog(false);
      eventEmitter.emit(CCGridEventType.RefreshOData);
      setIsLoading(false);
      pushNotification({
        title: `Report owner changed successfully`,
        type: "success",
      });
    } else {
      setIsShowChangeOwnerDialog(false);
      setIsLoading(false);
      pushNotification({
        title: response.data?.Errors?.[0] ?? `Change Owner report failed`,
        type: "error",
      });
    }
  };
  return (
    <>
      <CCNavButton
        title="Change owner"
        onClick={() => setIsShowChangeOwnerDialog(true)}
        disabled={isEmptyGridSelectedRow}
      />
      {isShowChangeOwnerDialog && (
        <CCContactManager
          dataUrl={CORE_OFFICERS_ODATA}
          setShowDialog={setIsShowChangeOwnerDialog}
          onSubmit={handleOnSubmit}
          isLoading={isLoading}
          isMultiple={false}
          isCloseDialogAfterSubmit={false}
          onClose={() => setIsShowChangeOwnerDialog(false)}
        />
      )}
    </>
  );
});
