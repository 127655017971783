import { colAssociationsSupplementary } from "@app/products/property/components/associations/components/supplementary/config";
import { DTO_AssociatedItem_Supplementary } from "@app/products/property/components/associations/components/supplementary/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsSupplementaryProps {
  associationsSupplementaryInfo: DTO_AssociatedItem_Supplementary[] | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_Supplementary>();

export const AssociationsSupplementary = ({
  associationsSupplementaryInfo,
}: IAssociationsSupplementaryProps) => {
  return (
    <CCGrid
      data={associationsSupplementaryInfo || []}
      columnFields={colAssociationsSupplementary}
      primaryField={nameOf("Id")}
    />
  );
};
