import { PrivateRoute } from "@/AppRoutes";
import { ProductRouterSwitch } from "@/ProductRouterSwitch";
import { wasteWaterRoute } from "@app/products/waste-water/route";
import {
  convertRouteDynamic,
  IConvertRouteDynamic,
} from "@common/utils/convertRouteDynamic";
import React from "react";

export const WasteWaterRouter = (props: any) => {
  return (
    <ProductRouterSwitch>
      {wasteWaterRoute.children &&
        convertRouteDynamic(wasteWaterRoute.children).map(
          (route: IConvertRouteDynamic, index: number): JSX.Element => {
            return (
              route.component && (
                <PrivateRoute
                  exact
                  key={index}
                  path={`${props.match.url}/${route.path}`}
                  component={route.component}
                />
              )
            );
          }
        )}
    </ProductRouterSwitch>
  );
};
