import { FINANCE_FEE_MANAGE_ROUTE } from "@app/core/fees/[id]/constant";
import {
  PermitFees,
  nameOfPermitFees,
} from "@app/products/local-laws/finance/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colLocalLawsFinanceFeesAll: IColumnFields[] = [
  {
    field: nameOfPermitFees("InvoiceNumber"),
    title: "Invoice Number",
    locked: true,
    linkTo: (dataItem: PermitFees) => ({
      pathname: `${FINANCE_FEE_MANAGE_ROUTE}/` + dataItem.ID,
    }),
  },
  { field: nameOfPermitFees("DebtorNumber"), title: "Debtor Number" },
  {
    field: nameOfPermitFees("CreatedDate"),
    title: "Recorded",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPermitFees("AmountIssued"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  { field: nameOfPermitFees("CostCode"), title: "CostCode" },

  {
    field: nameOfPermitFees("FeeType"),
    title: "Fee Type",
  },
  {
    field: nameOfPermitFees("Type"),
    title: "App Type",
  },
  {
    field: nameOfPermitFees("ContactName"),
    title: "Name",
  },
  {
    field: nameOfPermitFees("ReferenceNumber"),
    title: "Number",
  },
  {
    field: nameOfPermitFees("AmountOutstanding"),
    title: "Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
