import { DirectDebitSessionFormElement } from "@app/products/direct-debit/sessions/[id]/components/general/components/form-element/_index";
import { DirectDebitSessionHeader } from "@app/products/direct-debit/sessions/model";
import { useFlexibleFormState } from "@common/stores/flexible-form/useFlexibleFormState";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const DirectDebitSessionForm = observer(() => {
  const { setFormRef, initialDataForm } = useFlexibleFormState();

  // #region INITIAL_VALUES
  const directDebitSession = initialDataForm as DirectDebitSessionHeader;
  // #endregion INITIAL_VALUES

  return (
    <div className="cc-form">
      <Form
        ref={setFormRef}
        initialValues={directDebitSession}
        key={JSON.stringify(directDebitSession)}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={<DirectDebitSessionFormElement />}
            />
          );
        }}
      />
    </div>
  );
});
