import { postIssueAction } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/dialog/issue-action/api";
import { DebtRecoveryAction } from "@app/products/property/assessments/debt-recovery/[id]/model";
import { useDebtRecoveryStore } from "@app/products/property/assessments/debt-recovery/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useGlobalStore } from "@common/stores/global/store";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";

interface IIssueActionDialogProps {
  onCloseDialog: () => void;
}

const nameOf = nameOfFactory<DebtRecoveryAction>();

export const IssueActionDialog = observer(
  ({ onCloseDialog }: IIssueActionDialogProps) => {
    const { currentUserInfo } = useGlobalStore();
    const {
      debtRecoveryActionSelectedRow,
      setDebtRecoveryActionSelectedRow,
      loadDebtRecovery,
    } = useDebtRecoveryStore();

    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const { id } = useParams<{ id: string }>();

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const initialValues = {
      Type: debtRecoveryActionSelectedRow?.Type,
      DocumentIssuedOn: new Date(),
      DocumentIssuedBy: currentUserInfo?.userDisplayName,
    };

    const handleSubmit = async (values: any) => {
      setIsLoadingSubmit(true);
      const response = await postIssueAction();
      if (isSuccessResponse(response)) {
        if (id) {
          loadDebtRecovery(+id, {
            title: "Action document was issued successfully.",
            type: "success",
          });
          setDebtRecoveryActionSelectedRow(undefined);
          onCloseDialog();
        }
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title:
            response?.data?.ErrorMessage ??
            "Action document could not be issued.",
          type: "error",
        });
      }
      setIsLoadingSubmit(false);
    };

    return (
      <Form
        onSubmitClick={handleSubmit}
        initialValues={initialValues}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCDialog
              titleHeader={"Issue Action Document"}
              onClose={onCloseDialog}
              maxHeight="350px"
              maxWidth="30%"
              bodyElement={
                <FormElement className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-field-group">
                    <div className="cc-field">
                      <CCLabel title="Type" />
                      <Field
                        name={nameOf("Type")}
                        placeholder={"Type"}
                        component={CCInput}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Issued on" />
                      <Field
                        name={nameOf("DocumentIssuedOn")}
                        placeholder={"Issued on"}
                        component={CCDatePicker}
                        disabled
                        format={DATE_FORMAT.DATE_CONTROL}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Issued by" />
                      <Field
                        name={nameOf("DocumentIssuedBy")}
                        placeholder={"Issued by"}
                        component={CCInput}
                        readOnly
                      />
                    </div>
                  </div>
                </FormElement>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    themeColor="primary"
                    iconClass={isLoadingSubmit ? "fas fa-spinner fa-spin" : ""}
                    disabled={isLoadingSubmit}
                    className={"cc-dialog-button"}
                    onClick={formRenderProps.onSubmit}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
