import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const deleteContactRelationship = async (
  id: number,
  reason: string
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().delete<IdentityPacket>(
      `/api/core/internal/contact-relationships/additional-contacts/${id}`,
      {
        data: JSON.stringify(reason),
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
