import { ICustomEditCell } from "@components/cc-grid/model";
import {
  MaskedTextBox,
  MaskedTextBoxProps,
} from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { KEY_ENTER } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/concession-cards/components/custom-cells/constant";

interface ICCMaskedEditCellProps
  extends ICustomEditCell,
    Pick<MaskedTextBoxProps, "mask" | "className"> {}

export const CCMaskedEditCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
  mask,
  className,
}: ICCMaskedEditCellProps) => {
  const [data, setData] = useState<any>({ value: value });

  useEffect(() => {
    const keydownEnterHandler = (e: any) => {
      if (e.key === KEY_ENTER) {
        if (onChange) onChange(data);
      }
    };

    document
      .getElementById("cc-masked-text")
      ?.addEventListener("keydown", keydownEnterHandler);

    return () => {
      document
        .getElementById("cc-masked-text")
        ?.removeEventListener("keydown", keydownEnterHandler);
    };
  }, [data, onChange]);

  return (
    <MaskedTextBox
      id="cc-masked-text"
      className={className}
      value={data?.value ?? ""}
      onChange={(event) => {
        setData({
          field: field,
          dataIndex: dataIndex || 0,
          dataItem,
          syntheticEvent: event.syntheticEvent,
          value: event.value,
        });
      }}
      onBlur={() => {
        if (field && onChange) {
          onChange(data);
        }
      }}
      mask={mask}
    />
  );
};
