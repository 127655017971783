import { ICCRoute } from "@common/constants/ICCRoute";
import { extendDataItem, mapTree } from "@progress/kendo-react-common";
import { filterBy } from "@progress/kendo-react-data-tools";

export const processTreeData = (data: any, state: any, fields: any) => {
  const { selectField, expandField, dataItemKey, subItemsField } = fields;
  const { expanded, value, filter } = state;
  const filtering = Boolean(filter && filter.value);

  return mapTree(
    filtering ? filterBy(data, [filter], subItemsField) : data,
    subItemsField,
    (item) => {
      const props = {
        [expandField]: expanded.includes(item[dataItemKey]),
        [selectField]: value && item[dataItemKey] === value[dataItemKey],
      };
      return filtering
        ? extendDataItem(item, subItemsField, props)
        : { ...item, ...props };
    }
  );
};

export const expandedState = (item: any, dataItemKey: any, expanded: any) => {
  const nextExpanded = expanded.slice();
  const itemKey = item[dataItemKey];
  const index = expanded.indexOf(itemKey);
  index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);

  return nextExpanded;
};

export const getTreeStructure = (
  localProduct: any,
  startLever: number,
  parent: any,
  treeConfig: any,
  callback?: (treeItem: any) => void
) => {
  const groupName = "group" + startLever;
  const filterGroup = localProduct[groupName]
    ? localProduct[groupName].filter(
        (productItem: any) => productItem.parentKey === parent.key
      )
    : undefined;
  if (filterGroup && filterGroup.length) {
    return filterGroup.map((filterItem: any) => {
      let newDataKey = filterItem[treeConfig.dataItemKey] || filterItem.key;
      newDataKey = parent[treeConfig.dataItemKey]
        ? parent[treeConfig.dataItemKey] + "/" + newDataKey
        : newDataKey;

      let newDisplayName = parent[treeConfig.displayName]
        ? parent[treeConfig.displayName] + " > " + filterItem.value
        : filterItem.value;

      const newTreeItem = {
        ...filterItem,
        [treeConfig.dataItemKey]: newDataKey,
        [treeConfig.displayName]: newDisplayName,
      };

      if (callback) callback(newTreeItem);

      return {
        ...newTreeItem,
        [treeConfig.subItemsField]: getTreeStructure(
          localProduct,
          startLever + 1,
          newTreeItem,
          treeConfig,
          callback
        ),
      };
    });
  }
  return undefined;
};

//@TODO: Will be change after
interface IGetPropertyTreeStructure {
  tree: any;
  list: any[];
}
//@TODO: Will be change after
export const getPropertyTreeStructure = (
  router: ICCRoute,
  parent: any,
  treeConfig: any
): IGetPropertyTreeStructure | undefined => {
  if (!router.name) return undefined;
  let newTreeItem = {
    [treeConfig.dataItemKey]: parent[treeConfig.dataItemKey]
      ? parent[treeConfig.dataItemKey] + "/" + router.path
      : router.path,
    [treeConfig.displayName]: parent[treeConfig.displayName]
      ? parent[treeConfig.displayName] + " > " + router.name
      : router.name,
    [treeConfig.textField]: router.name,
  };

  if (router.children) {
    let newList: any[] = [];
    return {
      tree: {
        ...newTreeItem,
        [treeConfig.subItemsField]: router.children
          .map((childRoute: ICCRoute) => {
            const childTree = getPropertyTreeStructure(
              childRoute,
              newTreeItem,
              treeConfig
            );
            if (!childTree) return undefined;
            newList = [...newList, ...childTree.list];
            return childTree.tree;
          })
          .filter((child) => child !== undefined),
      },
      list: [newTreeItem, ...newList],
    };
  }
  return { tree: newTreeItem, list: [newTreeItem] };
};

export const getFirstChildTree: any = (tree: any, treeConfig: any) => {
  if (tree[treeConfig.subItemsField] && tree[treeConfig.subItemsField][0])
    return getFirstChildTree(tree[treeConfig.subItemsField][0], treeConfig);
  return tree;
};
