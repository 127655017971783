import { Svc_DashboardViewResultRow } from "@common/pages/home/components/event-section/components/dashboard-view/model";
import { nameOfFactory } from "@common/utils/common";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { IColumnFields } from "@components/cc-grid/model";
import { isHTML } from "@components/cc-input-picker/util";
import {
  GRID_COL_INDEX_ATTRIBUTE,
  GridCellProps,
} from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<Svc_DashboardViewResultRow>();
export const colEvent: IColumnFields[] = [
  {
    field: nameOf("MainDetail"),
    title: "Description",
  },
  {
    field: nameOf("SubDetail"),
    title: "Additional Info",
    cell: (props: GridCellProps) => {
      return <CustomCell props={props} fieldName={nameOf("SubDetail")} />;
    },
  },
  {
    field: nameOf("Status"),
    title: "Status",
    cell: (props: GridCellProps) => {
      return <CustomCell props={props} fieldName={nameOf("Status")} />;
    },
  },
];

export const CustomCell = ({
  props,
  fieldName,
}: {
  props: GridCellProps;
  fieldName: keyof Svc_DashboardViewResultRow;
}) => {
  const {
    ariaColumnIndex,
    columnIndex,
    colSpan,
    isSelected,
    className,
    style,
    dataItem,
  } = props;
  return (
    <td
      style={style}
      className={`${className}`}
      colSpan={colSpan}
      role={"gridcell"}
      aria-colindex={ariaColumnIndex}
      aria-selected={isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: columnIndex,
      }}
    >
      {isHTML(dataItem[fieldName])
        ? sanitizeHtml(dataItem[fieldName])
        : dataItem[fieldName]}
    </td>
  );
};
