import { getToBeApprovedRecordDetailsTabById } from "@app/products/property/actions/to-be-approved-list/components/reference-sidebar/detail/api";
import { IActionsToBeApprovedDetailTab } from "@app/products/property/actions/to-be-approved-list/components/reference-sidebar/detail/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const ActionToBeApprovedDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recordInfo, setRecordInfo] = useState<
    IActionsToBeApprovedDetailTab | undefined
  >();
  const { lastSelectedRow } = useCCProductListViewStore();
  const recordId: number = lastSelectedRow?.Id;
  useEffect(() => {
    if (recordId) {
      setIsLoading(true);
      getToBeApprovedRecordDetailsTabById(recordId).then((data) => {
        setRecordInfo(data);
        setIsLoading(false);
      });
    }
  }, [recordId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!recordInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"ID:"}
              value={recordInfo?.Id?.toString()}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Component:"}
              value={recordInfo.Component}
            />
            <ProductReferenceRow
              title={"Workflow:"}
              value={recordInfo?.Workflow}
            />
            <ProductReferenceRow
              title={"Created date:"}
              value={formatDisplayValue(recordInfo?.Created, DATE_FORMAT.DATE)}
            />
            <ProductReferenceRow
              title={"Modified date:"}
              value={formatDisplayValue(recordInfo?.Modified, DATE_FORMAT.DATE)}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
