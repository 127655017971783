import { observer } from "mobx-react-lite";
import React from "react";

const ProcessExternal = () => {
  return (
    <section className="cc-field-group">
      <div className="cc-field">
        <label className="cc-label-field leading-tight">
          Open Office offers external applications / third party vendors the
          ability to initiate actions within CommunityHub modules. This
          functionality is supported through the{" "}
          <span className="text-accent">processExternalAction.aspx?</span> page.
          and allows external applications / third party vendors to directly
          initiate code blocks or actions. Current uses for this page include
          GIS integration and the ability to create new records in Comunityhub
          with prepopulated data.
        </label>
      </div>
      <div className="cc-field">
        <label className="cc-label-field leading-tight">
          <span className="text-accent">Use case: </span>
          <br />
          GIS passing assessment number to this function and automatically
          creating and lunching a new CRMS events with prepopulated address
          information.
        </label>
      </div>
    </section>
  );
};

export default observer(ProcessExternal);
