import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { AddToSupplementaryCommonButton } from "@app/products/property/assessments/master-properties/components/actionbar/buttons/add-to-supplementary/common/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export const AddToSupplementaryButtonAtCollections = observer(() => {
  const { gridSelectedRows, isEmptyData, gridSelectedIds } =
    useDetailAssessmentListViewStore();
  const { id } = useParams<{ id: string }>();

  const isDisabled = useMemo(() => {
    return (
      (!isEmptyData && gridSelectedRows.length <= 0 && !id) ||
      (!id && isEmptyData)
    );
  }, [gridSelectedRows.length, isEmptyData, id]);

  const assessmentIDs = useMemo(() => {
    if (gridSelectedIds?.length) {
      return gridSelectedIds;
    }
    return id ? [+id] : [];
  }, [gridSelectedIds, id]);

  return (
    <AddToSupplementaryCommonButton
      componentNumber={eComponent.Assessment}
      statusId={gridSelectedRows[0]?.Assessment?.StatusId}
      isDisabled={isDisabled}
      ids={assessmentIDs}
    />
  );
});
