import { IMetersReading } from "@app/products/property/meters/[id]/components/child-screens/reading/model";
import { useMeterReadingsStore } from "@app/products/property/meters/[id]/components/child-screens/reading/store";
import { ConfirmUnVerifyReadingsDialog } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/un-verify-readings/components/dialogs/confirm-un-verify-readings/components/dialogs/confirm-un-verify-readings/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const UnVerifyReadingButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isDisableUnVerify, setIsDisableUnVerify] = useState(true);
  const { unVerifyReadings, selectedReadings } = useMeterReadingsStore();

  useEffect(() => {
    setIsDisableUnVerify(
      !selectedReadings?.some(
        (item: IMetersReading) => item.ToBeVerified === false
      )
    );
  }, [selectedReadings]);

  return (
    <React.Fragment>
      <CCNavButton
        title="Unverify readings"
        disabled={isDisableUnVerify}
        onClick={() => {
          setIsShowDialog(true);
        }}
      />
      {isShowDialog && (
        <ConfirmUnVerifyReadingsDialog
          onClosePopup={() => {
            setIsShowDialog(false);
          }}
          onConfirmYes={() => {
            unVerifyReadings();
            setIsShowDialog(false);
          }}
        />
      )}
    </React.Fragment>
  );
});
