import { VO_Master_Property } from "@app/products/property/assessments/master-properties/model";

export enum eSearchMasterPropertyBy {
  MasterPropertyNumber = "Property_Assessment_Number",
  MasterPropertyId = "Id",
  MasterPropertyValuationNumber = "Property_Valuation_Number",
}

export interface ISearchBy {
  Key: eSearchMasterPropertyBy;
  Value: string;
}

export interface IAddMasterPropertyDataForm {
  MasterProperty?: VO_Master_Property;
  _option: {
    SearchBy: {
      Value: ISearchBy;
      Data: ISearchBy[];
    };
    MasterProperty: {
      Data: VO_Master_Property[];
      Loading: boolean;
    };
  };
}
