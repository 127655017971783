import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Svc_BubbleUpDetails } from "@common/pages/actions/[id]/components/reference-sidebar/details/model";
import { AppealTabDetails } from "@common/pages/appeal/_id/components/reference-sidebar/detail/model";

export const getAppealDetailsTabById = async (
  id: number
): Promise<APIResponse<AppealTabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<AppealTabDetails>(
      `/api/core/internal/appeals/${id}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAppealBubbleupDetails = async (
  id: number
): Promise<APIResponse<Svc_BubbleUpDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/core/internal/appeals/${id}/bubbleup`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetAppealDetailTabResponse =
  | [
      APIResponse<AppealTabDetails | undefined>,
      APIResponse<Svc_BubbleUpDetails | undefined>
    ]
  | APIResponse<AppealTabDetails | Svc_BubbleUpDetails>
  | undefined;

export const getAppealDetailTab = async (
  id: number
): Promise<IGetAppealDetailTabResponse> => {
  try {
    return await Promise.all([
      getAppealDetailsTabById(id),
      getAppealBubbleupDetails(id),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
