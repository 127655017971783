import { GeneralLedgerAccount } from "@app/products/property/schemes/[id]/components/forms/existed/components/action-bar/buttons/edit-gl-account/model";

export const mockGeneralLedgerAccounts: GeneralLedgerAccount[] = [
  {
    GLAccountType: "Principal Debtor Account",
    DefaultGLAccReference: "1000005015",
  },
  {
    GLAccountType: "Interest Debtor Account",
    DefaultGLAccReference: "1000005015",
  },
  {
    GLAccountType: "Principal Income Account",
    DefaultGLAccReference: "1000005186",
  },
  {
    GLAccountType: "Interest Income Account",
    DefaultGLAccReference: "1001751025",
  },
  {
    GLAccountType: "Other Income Account",
    DefaultGLAccReference: "1000005186",
  },
  {
    GLAccountType: "Other Debtor Account",
    DefaultGLAccReference: "1000005015",
  },
  {
    GLAccountType: "Bank",
    DefaultGLAccReference: "1000005001",
  },
  {
    GLAccountType: "Discount",
    DefaultGLAccReference: "1000005186",
  },
  {
    GLAccountType: "Principal Write Off",
    DefaultGLAccReference: "1000005186",
  },
  {
    GLAccountType: "Interest Write Off",
    DefaultGLAccReference: "1000005186",
  },
  {
    GLAccountType: "Other Write Off",
    DefaultGLAccReference: "1000005186",
  },
  {
    GLAccountType: "Interim Bank Account",
  },
];
