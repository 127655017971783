import { history } from "@/AppRoutes";
import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { IParentAnimalsRegistrationSection } from "@app/products/animals/[id]/model";
import {
  getPermitFormById,
  postSavePermitForm,
} from "@app/products/local-laws/[id]/api";
import { ErrorMessageFromApi } from "@app/products/local-laws/[id]/components/buttons/save/constant";
import { LOCAL_LAWS_PERMIT_ROUTE } from "@app/products/local-laws/[id]/constant";
import {
  Permit,
  TApiIdentityResponse,
  TApiLoadPermitByIdResponse,
} from "@app/products/local-laws/[id]/model";
import {
  changePermitGeneralForm,
  getAlerts,
  processPushNotificationPortal,
} from "@app/products/local-laws/[id]/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { PickDebtorNumberDialog } from "@common/dialog/debtor-number/_index";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { RefNumberUsage } from "@common/input-pickers/debtor-number/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface ISavePermitButtonProps {
  isDisabled?: boolean;
}

export const SavePermitButton = observer(
  ({ isDisabled }: ISavePermitButtonProps) => {
    //#region STORE ========/
    const {
      submitFormGetData,
      isLoadingForm,
      dataForms,
      setIsLoadingForm,
      setInitialDataForms,
      setMiddlewareSubmitFormOptions,
    } = useFlexibleFormStore();
    //#endregion STORE =====/

    //#region HOOK ========/
    const { pushNotificationPortal } = useNotificationPortalStore();
    const isNew = useIsNew();
    const { cancelToken } = useCancelRequest();
    const [isShowPickDebtorDialog, setIsShowPickDebtorDialog] = useState(false);
    const [contactId, setContactId] = useState<number>();
    //#endregion HOOK =====/

    //#region METHOD ========/
    const savePermitButtonSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const permit: Permit = initialData;
            return postSavePermitForm(permit, cancelToken());
          },
          handleSuccess: ({ dataFromApi }) => {
            if (isNew && isNil(dataForms?.ParentSection?.animalID)) {
              if (dataFromApi?.data?.ID) {
                const urlRedirect =
                  LOCAL_LAWS_PERMIT_ROUTE + "/" + dataFromApi.data.ID;
                pushNotificationPortal({
                  title: "Permit saved successfully.",
                  type: "success",
                  route: urlRedirect,
                  callBack() {
                    history.replace(urlRedirect);
                  },
                });
              } else {
                pushNotificationPortal({
                  title: "Permit could not be saved.",
                  type: "error",
                  autoClose: false,
                });
              }
              return false;
            }
          },
          extractParamForNextAPI: ({ dataFromApi }) => {
            const response: TApiIdentityResponse = dataFromApi;
            return response?.data?.ID;
          },
          handleError: ({ errorFromApi, initialData }) => {
            if (isNew && errorFromApi.error?.[0] === ErrorMessageFromApi) {
              const permit = initialData as Permit;
              setContactId(permit?.Contact?.Contact?.Contact_ID);
              setIsShowPickDebtorDialog(true);
              changePermitGeneralForm({
                CCFormOptions: { forceModified: true },
              });
            } else {
              pushNotificationPortal({
                title: "Save permit failed.",
                type: "error",
                description: errorFromApi.error,
                autoClose: false,
              });
            }
          },
        },

        {
          fetch: ({ dataFromExtractParam }) => {
            const id: number = dataFromExtractParam;
            return getPermitFormById(id, cancelToken());
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: TApiLoadPermitByIdResponse = dataFromApi;
            const permitFormData = response?.data?.ReturnObj;
            //#region save data to store ========/
            setInitialDataForms({
              GeneralForm: permitFormData,
            });
            //#endregion save data to store =====/
            if (dataForms?.ParentSection?.animalID) {
              history.replace(
                `${ANIMALS_ROUTE}/${dataForms?.ParentSection?.animalID}`,
                {
                  parent: {
                    notification: [
                      {
                        title: "Permit saved successfully.",
                        type: "success",
                      },
                    ],
                    referenceNumber: permitFormData?.Permit?.Permit_Number,
                  } as IParentAnimalsRegistrationSection,
                }
              );
            } else {
              // Push alerts
              const alerts = getAlerts(permitFormData?.Permit);
              processPushNotificationPortal(alerts, pushNotificationPortal);

              // Push notification
              pushNotificationPortal({
                title: "Permit saved successfully.",
                type: "success",
              });
            }
          },
          handleError: ({ errorFromApi, initialData }) => {
            pushNotificationPortal({
              title: initialData?.errorMsg,
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    //#endregion METHOD =====/
    const handleClickButton = async (_event: any, debtorNumber?: string) => {
      if (debtorNumber) {
        setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      }

      const dataForm = await submitFormGetData("GeneralForm");
      let permit = dataForm?.Permit as Permit;
      if (isNil(permit)) return;

      permit.TypeOfDescription = permit.TypeOfDescription ?? "";
      permit.ReferenceNumber = permit.ReferenceNumber ?? "";
      permit.FileNumber = permit.FileNumber ?? "";
      permit.InsurancePolicyNumber = permit.InsurancePolicyNumber ?? "";
      permit.Description = permit.Description ?? "";

      if (debtorNumber) {
        permit.DebtorNumber = debtorNumber;
      }
      setIsLoadingForm(true);
      await savePermitButtonSlider.fetchApi({
        initialData: permit,
      });
      setIsLoadingForm(false);
    };

    const handlePickDebtor = (value: RefNumberUsage[]) => {
      setIsShowPickDebtorDialog(false);
      handleClickButton(null, value?.[0]?.Sys_DebtorNumber);
    };

    return (
      <>
        <CCNavButton
          title="Save"
          onClick={handleClickButton}
          disabled={isLoadingForm || isDisabled}
        />
        {isShowPickDebtorDialog && (
          <PickDebtorNumberDialog
            onClose={() => {
              setContactId(undefined);
              setIsShowPickDebtorDialog(false);
            }}
            onSubmit={(value: RefNumberUsage[]) => handlePickDebtor(value)}
            contactId={contactId}
            recordId={0}
            recordType={RECORDTYPE.LLP_Permit}
          />
        )}
      </>
    );
  }
);
