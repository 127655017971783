import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { GIS, PROPERTYSYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { TextAreaGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/text-area/_index";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { FormRenderProps } from "@progress/kendo-react-form";
import { isNumber } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

interface IGlobalSettingsProps {
  formRenderProps: FormRenderProps;
}

export default observer(({ formRenderProps }: IGlobalSettingsProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter } = formRenderProps;
  const data = useDataSetting(configData, [
    "CorporateSettings_GIS",
    "CorporateSettings_GISMapKeySeparator",
    "CorporateSettings_PickGISMapKeySeparator",
    "CorporateSettings_CustomSelectQuery_MapKeyByAssessmentNo",
    "CorporateSettings_CustomSelectQuery_AssessmentNoByMapKey",
    "CorporateSettings_CustomSelectQuery_GetContactFromMap",
    "CorporateSettings_GISMapInfoLinkName",
    "CorporateSettings_GISExecutable",
    "CorporateSettings_GISTempDir",
    "CorporateSettings_GISURL",
    "CorporateSettings_GISWorkContext",
    "CorporateSettings_GISPickupPath",
    "CorporateSettings_GISAssessmentNoPrefix",
    "CorporateSettings_GISWeaveURL",
    "CorporateSettings_GISWeaveEvent",
    "CorporateSettings_GISWeaveEntity",
    "CorporateSettings_GISIntraMapsURL",
    "CorporateSettings_GISIntraMapsModule",
    "CorporateSettings_GISGeoCortexURL",
    "CorporateSettings_GISGeoCortexModule",
    "CorporateSettings_GISDekhoURL",
    "CorporateSettings_GISDekhoQueryName",
    "CorporateSettings_ArcGISBaseURL",
    "CorporateSettings_ArcGISAssessmentNumberRelativeURL",
    "CorporateSettings_ArcGISViewMapBaseURL",
    "CorporateSettings_GISOverlay",
    "CorporateSettings_GISConnectionString",
    "CorporateSettings_GISReturnQuery",
    "CorporateSettings_GISAssessmentNoPrefix_Blank",
    "CorporateSettings_PropertySystem",
  ]);
  const fieldSettingLabel =
    configData?.["CorporateSettings_GISAssessmentNoPrefix_Blank"];
  const ddlPropertiesSystem = +valueGetter(
    ECorporateSettingsField.CorporateSettings_PropertySystem.toString()
  );
  const gis = +valueGetter(
    ECorporateSettingsField.CorporateSettings_GIS.toString()
  );
  const gisOverlay = valueGetter(
    ECorporateSettingsField.CorporateSettings_GISOverlay.toString()
  );
  const [isShowWeave, setIsShowWeave] = useState(false);
  const [isShowExponare, setIsShowExponare] = useState(false);
  const [isShowGISMapInfo, setIsShowGISMapInfo] = useState(false);
  const [isShowGISIntraMap, setIsShowGISIntraMap] = useState(false);
  const [isShowGISDekho, setShowGISDekho] = useState(false);
  const [isShowGeoCortex, setShowGeoCortex] = useState(false);
  const [isShowArcGIS, setShowArcGIS] = useState(false);
  const [isShowGetContactFromMap, setShowGetContactFromMap] = useState(true);
  const [isShowGISAllowOverlay, setShowGISAllowOverlay] = useState(true);
  const [isShowGISMapKeySeparator, setShowGISMapKeySeparator] = useState(false);
  const [
    isShowGISCustomGISKeyFromAssessmentNoQuery,
    setShowGISCustomGISKeyFromAssessmentNoQuery,
  ] = useState(false);
  const [
    isShowGISCustomAssessmentNoFromGISKeyQuery,
    setShowGISCustomAssessmentNoFromGISKeyQuery,
  ] = useState(false);

  useEffect(() => {
    if (isNumber(gis) && isNumber(ddlPropertiesSystem)) {
      setIsShowWeave(false);
      setIsShowExponare(false);
      setIsShowGISMapInfo(false);
      setIsShowGISIntraMap(false);
      setShowGISDekho(false);
      setShowGeoCortex(false);
      setShowArcGIS(false);
      setShowGetContactFromMap(true);
      setShowGISAllowOverlay(true);
      setShowGISMapKeySeparator(false);
      setShowGISCustomAssessmentNoFromGISKeyQuery(false);
      setShowGISCustomGISKeyFromAssessmentNoQuery(false);
      switch (gis) {
        case GIS.Weave:
          setIsShowWeave(true);
          break;
        case GIS.Bing:
        case GIS.Exponare:
          setIsShowExponare(true);
          setShowSomeFields();
          break;
        case GIS.MapInfo:
          setIsShowExponare(true);
          setIsShowGISMapInfo(true);
          setShowSomeFields();
          break;
        case GIS.IntraMaps:
        case GIS.Pozi:
        case GIS.SpectrumSpatial:
          setIsShowGISIntraMap(true);
          setShowSomeFields();
          break;
        case GIS.Dekho:
          setShowGISDekho(true);
          setShowSomeFields();
          break;
        case GIS.Geocortex:
          setShowGeoCortex(true);
          setShowSomeFields();
          break;
        case GIS.ArcGIS:
          setShowArcGIS(true);
          setShowGetContactFromMap(false);
          setShowGISAllowOverlay(false);
          if (ddlPropertiesSystem === PROPERTYSYSTEM.Custom) {
            setShowGISCustomAssessmentNoFromGISKeyQuery(true);
            setShowGISCustomGISKeyFromAssessmentNoQuery(true);
          }
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gis, ddlPropertiesSystem]);

  const setShowSomeFields = () => {
    if (ddlPropertiesSystem === PROPERTYSYSTEM.Custom) {
      setShowGISMapKeySeparator(true);
      setShowGISCustomAssessmentNoFromGISKeyQuery(true);
      setShowGISCustomGISKeyFromAssessmentNoQuery(true);
    }
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <CCSearchComboBoxGlobalSettings
          data={data.CorporateSettings_GIS}
          isEditing={isEditing}
        />
        {isShowGISMapKeySeparator && (
          <>
            <InputGlobalSettings
              data={data.CorporateSettings_GISMapKeySeparator}
              isEditing={isEditing}
            />
            <InputGlobalSettings
              data={data.CorporateSettings_PickGISMapKeySeparator}
              isEditing={isEditing}
            />
          </>
        )}
      </div>
      {isShowGISCustomGISKeyFromAssessmentNoQuery && (
        <div className="cc-form-cols-1">
          <TextAreaGlobalSettings
            data={data.CorporateSettings_CustomSelectQuery_MapKeyByAssessmentNo}
            isEditing={isEditing}
            rows={10}
          />
        </div>
      )}
      {isShowGISCustomAssessmentNoFromGISKeyQuery && (
        <div className="cc-form-cols-1">
          <TextAreaGlobalSettings
            data={data.CorporateSettings_CustomSelectQuery_AssessmentNoByMapKey}
            isEditing={isEditing}
            rows={10}
          />
        </div>
      )}
      {isShowGetContactFromMap && (
        <div className="cc-form-cols-1">
          <TextAreaGlobalSettings
            data={data.CorporateSettings_CustomSelectQuery_GetContactFromMap}
            isEditing={isEditing}
            rows={10}
          />
        </div>
      )}
      {isShowGISMapInfo && (
        <InputGlobalSettings
          data={data.CorporateSettings_GISMapInfoLinkName}
          isEditing={isEditing}
        />
      )}
      {isShowExponare && (
        <>
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={data.CorporateSettings_GISExecutable}
              isEditing={isEditing}
            />
          </div>
          <div className="cc-form-cols-2">
            <InputGlobalSettings
              data={data.CorporateSettings_GISTempDir}
              isEditing={isEditing}
            />
            <InputGlobalSettings
              data={data.CorporateSettings_GISURL}
              isEditing={isEditing}
            />
          </div>
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={data.CorporateSettings_GISWorkContext}
              isEditing={isEditing}
            />
          </div>
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={data.CorporateSettings_GISPickupPath}
              isEditing={isEditing}
            />
          </div>
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={data.CorporateSettings_GISAssessmentNoPrefix}
              isEditing={isEditing}
            />
          </div>
          <div className="cc-form-cols-1">
            <label className="cc-label">{fieldSettingLabel?.Title ?? ""}</label>
            {fieldSettingLabel?.Description && (
              <label className="cc-settings-description">
                {sanitizeHtml(fieldSettingLabel?.Description)}
              </label>
            )}
          </div>
        </>
      )}

      {isShowWeave && (
        <div className="cc-form-cols-3">
          <InputGlobalSettings
            data={data.CorporateSettings_GISWeaveURL}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_GISWeaveEvent}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_GISWeaveEntity}
            isEditing={isEditing}
          />
        </div>
      )}
      <div className="cc-form-cols-3">
        {isShowGISIntraMap && (
          <>
            <InputGlobalSettings
              data={data.CorporateSettings_GISIntraMapsURL}
              isEditing={isEditing}
            />
            <InputGlobalSettings
              data={data.CorporateSettings_GISIntraMapsModule}
              isEditing={isEditing}
            />
          </>
        )}
        {isShowGeoCortex && (
          <>
            <InputGlobalSettings
              data={data.CorporateSettings_GISGeoCortexURL}
              isEditing={isEditing}
            />
            <InputGlobalSettings
              data={data.CorporateSettings_GISGeoCortexModule}
              isEditing={isEditing}
            />
          </>
        )}
        {isShowGISDekho && (
          <>
            <InputGlobalSettings
              data={data.CorporateSettings_GISDekhoURL}
              isEditing={isEditing}
            />
            <InputGlobalSettings
              data={data.CorporateSettings_GISDekhoQueryName}
              isEditing={isEditing}
            />
          </>
        )}
        {isShowArcGIS && (
          <>
            <InputGlobalSettings
              data={data.CorporateSettings_ArcGISBaseURL}
              isEditing={isEditing}
            />
            <InputGlobalSettings
              data={data.CorporateSettings_ArcGISAssessmentNumberRelativeURL}
              isEditing={isEditing}
            />
            <InputGlobalSettings
              data={data.CorporateSettings_ArcGISViewMapBaseURL}
              isEditing={isEditing}
            />
          </>
        )}
      </div>
      {isShowGISAllowOverlay && (
        <>
          <div className="cc-form-cols3">
            <SwitchGlobalSettings
              data={data.CorporateSettings_GISOverlay}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          </div>
          {gisOverlay && (
            <>
              <div className="cc-form-cols-1">
                <InputGlobalSettings
                  data={data.CorporateSettings_GISConnectionString}
                  isEditing={isEditing}
                />
              </div>
              <div className="cc-form-cols-1">
                <InputGlobalSettings
                  data={data.CorporateSettings_GISReturnQuery}
                  isEditing={isEditing}
                />
              </div>
            </>
          )}
        </>
      )}
    </section>
  );
});
