import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React from "react";

interface IDeleteGISReferenceConfirmProps {
  onClose: () => void;
  onConfirmYes: () => void;
}

export const DeleteGISReferenceConfirmDialog = observer(
  ({ onConfirmYes, onClose }: IDeleteGISReferenceConfirmProps) => {
    return (
      <ConfirmDialog
        title={"Delete GIS Reference Confirmation"}
        subMessage={"Do you wish to delete the selected GIS Reference?"}
        height={"auto"}
        width={"auto"}
        onClosePopup={onClose}
        onConfirmYes={onConfirmYes}
      />
    );
  }
);
