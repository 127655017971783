import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { DTO_Workflow_PIC_Amalgamate } from "@app/products/property/pic/list/components/action-bar/form-steps/amalgamate-pic/model";

import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postAmalgamatePIC = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_PIC_Amalgamate
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_PIC_Amalgamate>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/int/workflow/picamalgamate/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getInitialDataAmalgamatePIC = async (
  picIds: number[] = [],
  workflowDraftId: number | undefined
): Promise<APIResponse<DTO_Workflow_PIC_Amalgamate>> => {
  try {
    return workflowDraftId !== 0 && !isNil(workflowDraftId)
      ? getWorkflowAmalgamatePIC(workflowDraftId)
      : await CoreAPIService.getClient().post(
          `api/property/int/workflow/picamalgamate/new`,
          { PIC_Ids: picIds }
        );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowAmalgamatePIC = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_PIC_Amalgamate>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/int/workflow/picamalgamate/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
