import {
  FurtherInformationRequest,
  FURTHERINFOSTATUS,
} from "@app/core/further-info/[id]/model";
import { mapRecordType, RECORDTYPE } from "@common/constants/recordtype";

export const getFurtherStatusFriendlyName = (
  type: FURTHERINFOSTATUS
): string => {
  switch (type) {
    case FURTHERINFOSTATUS.InfoRequested:
      return "Further information requested";
    case FURTHERINFOSTATUS.ExtensionRequested:
      return "Extension requested";
    case FURTHERINFOSTATUS.ExtensionGranted:
      return "Extension granted";
    case FURTHERINFOSTATUS.ExtensionDeclined:
      return "Extension declined";
    case FURTHERINFOSTATUS.InfoReceivedAwaitingAssessment:
      return "Further information received - awaiting assessment";
    case FURTHERINFOSTATUS.InfoReceived:
      return "Further information received";
    case FURTHERINFOSTATUS.Cancelled:
      return "Cancelled";
    case FURTHERINFOSTATUS.InfoWaitingForApproval:
      return "Further information awaiting approval";
    case FURTHERINFOSTATUS.InfoApproved:
      return "Further information approved";
    case FURTHERINFOSTATUS.InfoRejected:
      return "Further information rejected";
    case FURTHERINFOSTATUS.InfoRequestedDraft:
      return "Further information requested";
    case FURTHERINFOSTATUS.InfoNotReceived:
      return "Further information not received";
    case FURTHERINFOSTATUS.InfoRequestedNew:
      return "Further information requested new";
  }
  return type.toString();
};

export const checkIsPPR = (
  furtherInfo: FurtherInformationRequest | undefined
): boolean => {
  if (!furtherInfo || !furtherInfo?._BubbleUps) return true;
  return furtherInfo?._BubbleUps.some(
    (bubbleUp) =>
      mapRecordType[bubbleUp.SourceIdentifier._RecordSourceType_ENUM ?? ""] ===
      RECORDTYPE.TP_PPRApplication
  );
};
