import { VO_Assessment_RebateEntitlementEntity } from "@app/products/property/assessments/rebates/list/components/detail/model";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<VO_Assessment_RebateEntitlementEntity>();
export const colRebatesEntitiesAssociated: IColumnFields[] = [
  {
    field: nameOf("Entity_Name"),
    title: "Contact",
  },
  {
    field: nameOf("Entitled_To_Rebate"),
    title: "Entitled to Rebate",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Concession_Cards"),
    title: "Concession Cards Held",
  },
  {
    field: nameOf("Entity_Id"),
    title: "Contact ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
