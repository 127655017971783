import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export const CreateDebtRecoveryExemptionButton = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();

  const { gridSelectedRows, isEmptyData } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const isDisabled = useMemo(() => {
    return (
      (!isEmptyData && gridSelectedRows.length !== 1 && !id) ||
      (!id && isEmptyData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridSelectedRows.length, id, isEmptyData]);

  return (
    <CCNavButton
      title="Create debt recovery exemption"
      disabled={isDisabled}
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Create_DebtRecoveryExemption,
            data: {},
          },
        ]);
      }}
    />
  );
});
