export interface DTO_Component_External_Process {
  ExternalProcessId: number;
  Variables: DTO_Variable[];
}

export interface DTO_Variable {
  Id: number;
  Variable_Id: number;
  Variable_Name: string;
  Variable_Prompt: string;
  Variable_Default: string;
  Variable_Data_Type: string;
  DT_Type: number | null;
  Variable_Value: string;
}

// Create from UX to detect which data type. From API it is just a string(Variable_Data_Type)
export enum eSqlDataType {
  VARCHAR = "varchar(100)",
  MONEY = "Money",
  BIT = "BIT",
  INT = "Int",
}
