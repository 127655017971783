import { eventEmitter } from "@/App";
import { getUUID } from "@common/utils/common";
import { useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import { defaultSelectionLoaderEvent } from "../constant";
import { SelectionLoaderEvent } from "../model";
import { getSelectionEmitterId } from "../util";

export function useSelectionListener(gridId?: string): {
  gridId: string;
  loader: React.MutableRefObject<SelectionLoaderEvent>;
} {
  const [id] = useState(() => gridId || getUUID());
  const loader = useRef<SelectionLoaderEvent>(defaultSelectionLoaderEvent);
  useEffectOnce(() => {
    const identifier = getSelectionEmitterId(id);
    if (identifier === null) return;
    const subscription = eventEmitter.addListener(
      identifier,
      (message: SelectionLoaderEvent) => {
        loader.current = message;
      }
    );
    return () => {
      subscription.remove();
    };
  });

  return { gridId: id, loader };
}
