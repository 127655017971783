import { getInfoXpertContainers } from "@app/core/infoExpert/container/api";
import { getInfoExpertContainerInputPickerOption } from "@app/core/infoExpert/container/config";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { Svc_InfoExpertContainer } from "@common/models/corporateSetting";
import { nameOfFactory } from "@common/utils/common";
import { isArray } from "lodash";
import React from "react";

interface ITrimContainerProps {
  value: any;
  rootFolder?: string;
  appendNoneOption?: boolean;
}

export const InfoXpertContainerInputPicker = (props: ITrimContainerProps) => {
  const { value, rootFolder = "", appendNoneOption = false, ...others } = props;
  const nameOfInfoXpertContainer = nameOfFactory<Svc_InfoExpertContainer>();

  return (
    <InputPickerSearch
      nameDisplayMerge={(value: any) => {
        if (isArray(value) && value.length > 0) {
          const selectedValue = value[0] as Svc_InfoExpertContainer;
          return `${selectedValue?.ID?.toString()} ${selectedValue?.Description?.toString()}`;
        }
        return "";
      }}
      options={getInfoExpertContainerInputPickerOption(undefined, {
        selectableMode: "single",
      })}
      value={value}
      localSearchOptions={{
        enableLocalSearch: true,
        searchingPropKey: nameOfInfoXpertContainer("Name"),
      }}
      customGetData={async () =>
        await getInfoXpertContainers(rootFolder, appendNoneOption)
      }
      showClearButton={true}
      {...others}
    />
  );
};
