import {
  DTO_DebtRecovery,
  DTO_DebtRecovery_Ext,
} from "@app/products/property/assessments/[id]/components/child-screens/debt-recovery/model";
import { cloneDeep } from "lodash";

export const convert = (response: DTO_DebtRecovery) => {
  let result: DTO_DebtRecovery_Ext = cloneDeep(response);

  if (response.NameAndAddress?.NameAndAddress) {
    result.NewNameAndAddress = response.NameAndAddress?.NameAndAddress;
  }
  return result;
};
