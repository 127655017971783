import { DTO_Assessment_Rebates } from "@app/products/property/assessments/[id]/components/child-screens/rebates/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const loadAssessmentRebateEntitiesDetail = async (
  assessmentId: number,
  rebateEntitlementId: number
): Promise<DTO_Assessment_Rebates | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Assessment_Rebates>(
        `/api/property/internal/assessment/${assessmentId}/rebateEntitlement/${rebateEntitlementId}`
      );

    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
