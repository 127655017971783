import { IFieldsRecipientByFillInfo } from "@app/core/communication/dialogs/components/form-elememts/to/components/add-recipient/components/add-recipient-by-fill-info/model";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IAddRecipientByFillInfoProps {
  onClose: any;
  onAddRecipientByFillInfo:
    | ((
        values: {
          [name: string]: any;
        },
        event?: React.SyntheticEvent<any, Event> | undefined
      ) => void)
    | undefined;
}
const nameOf = nameOfFactory<IFieldsRecipientByFillInfo>();

export const AddRecipientByFillInfo = observer(
  ({ onClose, onAddRecipientByFillInfo }: IAddRecipientByFillInfoProps) => {
    const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
    return (
      <Form
        onSubmit={onAddRecipientByFillInfo}
        render={({ onSubmit, modified, onChange }: FormRenderProps) => (
          <CCDialog
            height="auto"
            width="auto"
            unsetMinHeight={true}
            titleHeader={"Add New Recipient"}
            onClose={() => onClose(false)}
            bodyElement={
              <div className="cc-form">
                <div className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Full name</label>
                      <Field
                        name={nameOf("DisplayName")}
                        component={CCInput}
                        placeholder="Full name"
                      />
                    </div>
                    <div className="cc-field">
                      <Field
                        name={nameOf("Email")}
                        placeholder="Email"
                        component={CCInputEmail}
                        validator={validateEmail}
                        onChangeForm={onChange}
                        label="Email"
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Mobile</label>
                      <Field
                        name={nameOf("Mobile")}
                        placeholder="Mobile"
                        component={CCInputPhone}
                        validator={validatePhoneNumber}
                        onChangeForm={onChange}
                        skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                        mode={EModePhoneNumber.Mobile}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                <Button
                  className={"cc-dialog-button"}
                  onClick={() => onClose(false)}
                >
                  Cancel
                </Button>

                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={onSubmit}
                  disabled={!modified}
                >
                  OK
                </Button>
              </div>
            }
          />
        )}
      />
    );
  }
);
