import { IMailMergeDocumentsTemplate as IMailMergeDocumentGrid } from "@app/core/new-mail-merge/form-steps/template/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IMailMergeDocumentGrid>();
export const colMailMergeDocumentGrid: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("SaveFormat"),
    title: "Save As",
  },
  {
    field: nameOf("MailMergeDocumentUsage"),
    title: "Usage",
  },
];
