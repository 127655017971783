import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

interface ISubdivideConsolidateButtonProps {
  isHidden?: boolean;
  associationsRef: React.MutableRefObject<any>;
}

export const SubdivideConsolidateButton = observer(
  ({ isHidden, associationsRef }: ISubdivideConsolidateButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();
    const { assessment } = useAssessmentStore();
    const { id } = useParams<{ id: string }>();

    return !isHidden ? (
      <CCNavButton
        title="Subdivide/Consolidate"
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Subdivisions_Consolidations,
              data: {},
              props: {
                assessmentId: +(id ?? 0),
                statusId: assessment?.Assessment?.StatusId ?? null,
                associationsRef,
              },
            },
          ]);
        }}
      />
    ) : null;
  }
);
