import { IDirectDebitAuthorisationProps } from "@app/products/direct-debit/authorisations/model";
import { nameOfFactory } from "@common/utils/common";

export const nameOfDDAuthorisation =
  nameOfFactory<IDirectDebitAuthorisationProps>();

export const DD_AUTHORISATION_DETAILS_STEP = "AuthorisationDetailsStep";
export const DD_AUTHORISATION_BANKING_STEP = "AuthorisationBankingStep";
export const DD_AUTHORISATION_SELECT_ACCOUNTS_STEP =
  "AuthorisationSelectAccountsStep";

export const AUTHORISATION_NOTIFICATION_PORTAL_PLACE_ID =
  "DDCreateAuthorisationDialog";
