import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const inspScheduleRoute: ICCRoute = {
  path: "insp-schedule",
  name: "Insp Schedule",
  enum: HealthManagerMenu.InspectionSchedule,
  children: [
    {
      path: "my-schedule",
      name: "My Schedule",
      enum: HealthManagerMenu.PremisesMyInspSchedule,
      component: require("./my-schedule/_index").default,
    },
    {
      path: "calendar",
      name: "Calendar",
      enum: HealthManagerMenu.PremisesMyInspScheduleCalendar,
      component: require("./calendar/_index").default,
    },
    {
      path: "all-by-officer",
      name: "All By Officer",
      enum: HealthManagerMenu.PremisesInspScheduleAll,
      component: require("./all-by-officer/_index").default,
    },
    {
      path: "not-inspected",
      name: "Not Inspected",
      enum: HealthManagerMenu.PremisesNotInspected,
      component: require("./not-inspected/_index").default,
    },
    {
      path: "inspections-due",
      name: "Inspections Due",
      enum: HealthManagerMenu.PremisesInspDue,
      component: require("./inspections-due/_index").default,
    },
    {
      path: "audit-schedule",
      name: "Audit Schedule",
      enum: HealthManagerMenu.PremisesInspScheduleAudits,
      component: require("./audit-schedule/_index").default,
    },
  ],
};
