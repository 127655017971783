import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import React from "react";

export const TabPeople = () => {
  // Will integrate validation for phone number & email for this component later
  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Owners</label>
            <Input placeholder={"Owners"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Attention of</label>
            <Input placeholder={"Attention of"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Care of</label>
            <Input placeholder={"Care of"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Address</label>
            <Input placeholder={"Address"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Locality</label>
            <Input placeholder={"Locality"} />
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">State</label>
              <Input placeholder={"State"} />
            </div>
            <div className="cc-field">
              <label className="cc-label">Postcode</label>
              <Input placeholder={"Postcode"} />
            </div>
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">Country</label>
              <Input placeholder={"Country"} />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Phone</label>
              <Input placeholder={"Phone"} />
            </div>
            <div className="cc-field">
              <label className="cc-label">Mobile</label>
              <Input placeholder={"Mobile"} />
            </div>
            <div className="cc-field">
              <label className="cc-label">Email</label>
              <Input placeholder={"Email"} />
            </div>
            <div className="cc-field">
              <label className="cc-label">Fax</label>
              <Input placeholder={"Fax"} />
            </div>
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">Entity ID</label>
              <NumericTextBox placeholder={"Entity ID"} />
            </div>
          </div>
        </div>
      </div>

      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Ratepayers</label>
            <Input placeholder={"Ratepayers"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Attention of</label>
            <Input placeholder={"Attention of"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Care of</label>
            <Input placeholder={"Care of"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Address</label>
            <Input placeholder={"Address"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Locality</label>
            <Input placeholder={"Locality"} />
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">State</label>
              <Input placeholder={"State"} />
            </div>
            <div className="cc-field">
              <label className="cc-label">Postcode</label>
              <Input placeholder={"Postcode"} />
            </div>
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">Country</label>
              <Input placeholder={"Country"} />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Phone</label>
              <Input placeholder={"Phone"} />
            </div>
            <div className="cc-field">
              <label className="cc-label">Mobile</label>
              <Input placeholder={"Mobile"} />
            </div>
            <div className="cc-field">
              <label className="cc-label">Email</label>
              <Input placeholder={"Email"} />
            </div>
            <div className="cc-field">
              <label className="cc-label">Fax</label>
              <Input placeholder={"Fax"} />
            </div>
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">Entity ID</label>
              <NumericTextBox placeholder={"Entity ID"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
