import { NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colParcelIncludedCertificate: IColumnFields[] = [
  {
    field: "ParcelId",
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "Reference",
    title: "Reference",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "Address",
    title: "Address",
  },
  {
    field: "LegalDescription",
    title: "Legal Description",
  },
  {
    field: "Owners",
    title: "Owners",
  },
];

export const colAssessmentIncludedCertificate: IColumnFields[] = [
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "AssessmentReference",
    title: "Assessment Reference",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "LegalDescription",
    title: "Legal Description",
  },
  {
    field: "Owners",
    title: "Owners",
  },
  {
    field: "AssessmentId",
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const comboBoxSearchData = {
  _option: {
    Assessment: {
      Data: [],
      Loading: false,
    },
  },
};
