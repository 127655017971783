import { IKeyValuePacketBoolean } from "@app/products/property/system-admin/document-template/[id]/model";

export const booleanData: IKeyValuePacketBoolean[] = [
  {
    Key: true,
    Value: "Yes",
  },
  {
    Key: false,
    Value: "No",
  },
];
