import { loadPropertyAssessmentAmountOwing } from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/api";
import {
  DTO_Assessment_AmountsOwing,
  mAssessmentAmountsOwingView,
} from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class AssessmentAmountOwingStore {
  private _amountOwing?: DTO_Assessment_AmountsOwing = undefined;
  private _isLoading: boolean = false;
  private _isLoadingDetailPanel: boolean = false;
  private _view?: mAssessmentAmountsOwingView = undefined;
  private _isShowPaidCharges?: boolean = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get amountOwing() {
    return this._amountOwing;
  }
  setAmountOwing = (amountOwing?: DTO_Assessment_AmountsOwing) => {
    runInAction(() => {
      this._amountOwing = amountOwing;
    });
  };

  get view() {
    return this._view;
  }
  setView = (view?: mAssessmentAmountsOwingView) => {
    runInAction(() => {
      this._view = view;
    });
  };

  get isShowPaidCharges() {
    return this._isShowPaidCharges;
  }
  setIsShowPaidCharges = (isShowPaidCharges?: boolean) => {
    runInAction(() => {
      this._isShowPaidCharges = isShowPaidCharges;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingDetailPanel() {
    return this._isLoadingDetailPanel;
  }
  setIsLoadingDetailPanel = (isLoadingDetailPanel: boolean) => {
    runInAction(() => {
      this._isLoadingDetailPanel = isLoadingDetailPanel;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._amountOwing = undefined;
      this._isLoading = false;
      this._isLoadingDetailPanel = false;
      this._view = undefined;
      this._isShowPaidCharges = undefined;
    });
  };
  loadAmountOwing = async (
    assessmentId: number,
    viewId?: number,
    isShowPaidCharges?: boolean
  ) => {
    if (viewId !== undefined) {
      this.setIsLoadingDetailPanel(true);
    } else {
      this.setIsLoading(true);
    }
    const newAmountOwing = await loadPropertyAssessmentAmountOwing(
      assessmentId,
      viewId,
      isShowPaidCharges
    );
    this.setAmountOwing(newAmountOwing);
    this.setIsLoading(false);
    this.setIsLoadingDetailPanel(false);
    if (viewId === undefined && newAmountOwing !== undefined) {
      this.setView(newAmountOwing?.AmountsOwingViews?.Views?.[0]);
    }
  };
}

const assessmentAmountOwingStoreContext = createContext(
  new AssessmentAmountOwingStore()
);
export const useAssessmentAmountOwingStore = () => {
  return useContext(assessmentAmountOwingStoreContext);
};
