import { getRebateClaimOutputFile } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/action-bar/buttons/produce-claim-file/api";
import { isSuccessResponse } from "@common/apis/util";
import { DownloadFile } from "@common/constants/downloadfile";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ProduceClaimFileButton = () => {
  const { id: rebateClaimId } = useParams<{ id: string }>();

  //State
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //Store
  const { pushNotification } = useCCAppNotificationStore();

  //Handle event
  const handleOnclick = async () => {
    if (isNil(rebateClaimId)) return;
    setIsLoading(true);
    const response = await getRebateClaimOutputFile(rebateClaimId);
    if (isSuccessResponse(response)) {
      DownloadFile(
        response.data?.FileContents ?? "",
        response.data?.ContentType ?? "",
        response.data?.FileDownloadName ?? ""
      );
    } else {
      pushNotification({
        autoClose: false,
        description: response?.error ?? "Download claim file failed",
        type: "error",
      });
    }
    setIsLoading(false);
  };

  return (
    <CCNavButton
      title="Produce claim file"
      isLoading={isLoading}
      onClick={handleOnclick}
    />
  );
};
