import { BuildingFieldMapping } from "@app/products/building/model";
import { dropdownTypeData } from "@app/products/building/registers/enforcements/[id]/components/child-screens/general/components/form-element/mock";
import { RegisterAddressPopup } from "@app/products/property/components/register-address-popup/_index";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { validateRequired } from "@common/utils/field-validators";
import { formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export interface IBuildingEnforcementFormElementProps {
  formRenderProps: FormRenderProps;
}

export const BuildingRegisterEnforcementFormElement = ({
  formRenderProps,
}: IBuildingEnforcementFormElementProps) => {
  const history = useHistory();
  const isNew = useIsNew();
  const { pushNotification } = useCCAppNotificationStore();

  const [isOpenRegisterAddressPopup, setIsOpenRegisterAddressPopup] =
    useState(false);

  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }

    if (!formRenderProps.valid) return;

    pushNotification({
      title: "The form is not modified",
      type: "warning",
    });
  };

  const getValue = formRenderProps.valueGetter;

  return (
    <FormElement onSubmit={formRenderProps.onSubmit}>
      {isOpenRegisterAddressPopup && (
        <RegisterAddressPopup
          onClosePopup={() => setIsOpenRegisterAddressPopup(false)}
        />
      )}

      {/* Reference by a label in ActionBar.tsx */}
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />

      <div>
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Contact
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                onClick={() => {
                  history.push(`/managecontactrelationship/${"123"}`);
                }}
                autoComplete="off"
                name={"Contact"}
                className="disabled"
                component={InputPicker}
                placeholder={"Contact"}
                validator={validateRequired}
              />
            </div>
            <CCValueField
              label="Address"
              value={getValue("Applicant_Address")}
            />
            <CCValueField
              label="Contact"
              value={getValue("PreferredContact_Contact")}
            />
          </div>
        </div>
        <hr className="cc-divider" />

        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Site address
                <CCTooltip type="validator" position="right" />
              </label>

              <Field
                component={InputPicker}
                name={"SiteAddress"}
                placeholder={"Site address"}
                validator={validateRequired}
                readOnly
                onClick={() => {
                  setIsOpenRegisterAddressPopup(true);
                }}
                autoComplete="off"
                className="disabled"
              />
            </div>
            <CCValueField label="Owner" value={getValue("SiteAddress_Owner")} />
            <CCValueField
              label="Details"
              value={getValue("SiteAddress_Details")}
            />
          </div>
        </div>
        <hr className="cc-divider" />

        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Type</label>
              <Field
                name={"Type"}
                dataItemKey="Key"
                textField="Value"
                data={dropdownTypeData}
                isUseDefaultOnchange
                component={CCSearchComboBox}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Description</label>
              <Field
                rows={8}
                placeholder={"Description"}
                component={CCTextArea}
                name={"Description"}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />

        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Officer</label>
              <Field
                component={InputPicker}
                name={"Officer"}
                titleHeader={"Pick Office"}
                placeholder={"Officer"}
                readOnly
                value={formRenderProps.valueGetter(
                  BuildingFieldMapping.Officer
                )}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Reference number</label>
              <Field
                component={CCInput}
                name={"RefNo"}
                placeholder={"Reference number"}
                readOnly
                fieldName={"RefNo"}
                value={formRenderProps.valueGetter(BuildingFieldMapping.RefNo)}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <CCValueField
              label="Lodged"
              value={getValue(BuildingFieldMapping.Date_Lodged)}
              format={DATE_FORMAT.DATE}
            />
            <div className="cc-field">
              <label className="cc-label">Due date</label>
              <Field
                component={CCDatePicker}
                name={"DateDue"}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
            <CCValueField
              label="Completed date"
              value={getValue(BuildingFieldMapping.Date_Completed)}
              format={DATE_FORMAT.DATE}
            />
          </div>
        </div>
        <hr className="cc-divider" />

        {!isNew && (
          <>
            <div className="cc-field-group">
              <div className="cc-form-cols-3">
                <CCValueField
                  label="Planner assessment"
                  value={getValue(BuildingFieldMapping.AssessmentNo)}
                />
              </div>
            </div>
            <hr className="cc-divider" />
          </>
        )}
        {/* ROW */}
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCValueField
              label="OS fees"
              value={getValue(BuildingFieldMapping.OutstandingFees)}
              style={formatOSFees(
                getValue(BuildingFieldMapping.OutstandingFees)
              )}
              format={CURRENCY_FORMAT.CURRENCY1}
            />

            <div className="cc-field">
              <label className="cc-label">Debtor number</label>
              <Field
                name={"DebtorNumber"}
                component={CCInput}
                value={getValue(BuildingFieldMapping.DebtorNumber)}
                placeholder={"Debtor number"}
              />
            </div>
          </div>
        </div>
      </div>
    </FormElement>
  );
};
