import { AnimalType } from "@app/products/animals/model";
import { FeePart } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-part/_index";
import { FeeRule } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IFeeCalculationProps {
  animalTypeFormObject: AnimalType;
  feeCalculationLabel: string;
  formRenderProps: FormRenderProps;
}

export const FeeCalculation = observer(
  ({
    animalTypeFormObject,
    feeCalculationLabel,
    formRenderProps,
  }: IFeeCalculationProps) => {
    const { settings } = useCommonCoreStore();
    const isUseOOFeeCalculator = getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_UseOpenOfficeFeeCalculator]
    );

    return (
      <>
        {!isUseOOFeeCalculator ? (
          <FeePart
            feeCalculationLabel={feeCalculationLabel}
            formRenderProps={formRenderProps}
          />
        ) : (
          <FeeRule
            formRenderProps={formRenderProps}
            animalTypeFormObject={animalTypeFormObject}
          />
        )}
      </>
    );
  }
);
