import { IMetersReading } from "@app/products/property/meters/[id]/components/child-screens/reading/model";
import { useMeterReadingsStore } from "@app/products/property/meters/[id]/components/child-screens/reading/store";
import { ConfirmVoidReadingsDialog } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/void-readings/components/dialogs/confirm-void-readings/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const VoidReadingsButton = observer(() => {
  const [showConfirmDialog, setShowConfirmDialogs] = useState<boolean>(false);
  const { selectedReadings, voidReadings } = useMeterReadingsStore();
  const { gridSelectedRows } = useCCProductListViewStore();

  const isDisabled = useMemo(() => {
    return selectedReadings?.some(
      (item: IMetersReading) => item.IsVoid === false
    ) || gridSelectedRows.length
      ? false
      : true;
  }, [selectedReadings, gridSelectedRows]);

  return (
    <>
      <CCNavButton
        title="Void readings"
        onClick={() => setShowConfirmDialogs(true)}
        disabled={isDisabled}
      />

      {showConfirmDialog && (
        <ConfirmVoidReadingsDialog
          onClosePopup={() => {
            setShowConfirmDialogs(false);
          }}
          onConfirmYes={() => {
            voidReadings();
            setShowConfirmDialogs(false);
          }}
        />
      )}
    </>
  );
});
