import { getDisplayTextWithDashes } from "@common/utils/common";

export const getAnimalsInspectionPlanner = (groupBy?: string) => {
  return `odata/animals/internal/animalsinspectionplanner/GetAnimalsInspectionPlanner${
    groupBy ? `(groupby='${groupBy}')` : ""
  }?$count=true&`;
};

export const InspectionPlannerBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow?.ID}` : "";
    return `Animals - Inspection Planner - ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow?.Area ? selectedRow?.Area : "No Area",
      selectedRow?.LastInspection,
    ]);
  },
};
