import {
  DTO_DebtRecoveryExemption_LOVs,
  DTO_Workflow_CreateDebtRecoveryExemption,
  RequestObjFromSpatialCreateDebtRecoveryExemption,
} from "@app/products/property/assessments/debt-recovery/candidates/components/action-bar/dialogs/create-debt-recovery-exemption/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postNewWorkflowCreateDebtRecoveryExemption = async (
  data: RequestObjFromSpatialCreateDebtRecoveryExemption
): Promise<APIResponse<DTO_Workflow_CreateDebtRecoveryExemption>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/createdebtrecoveryexemption/new`,
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postCreateDebtRecoveryExemption = async (
  mode: WorkflowProcessMode,
  payload: DTO_Workflow_CreateDebtRecoveryExemption | undefined
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_CreateDebtRecoveryExemption>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/createdebtrecoveryexemption/process/${mode}`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCreateDebtRecoveryExemptionDraft = async (
  workflowDraftId: number | undefined
): Promise<APIResponse<DTO_Workflow_CreateDebtRecoveryExemption>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/createdebtrecoveryexemption/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataCreateDebtRecoveryExemption =
  | [
      APIResponse<DTO_DebtRecoveryExemption_LOVs>,
      APIResponse<DTO_Workflow_CreateDebtRecoveryExemption>
    ]
  | APIResponse<
      DTO_DebtRecoveryExemption_LOVs | DTO_Workflow_CreateDebtRecoveryExemption
    >
  | undefined;
export const getInitialDataCreateDebtRecoveryExemption = async (
  workflowDraftId: number | undefined,
  data: RequestObjFromSpatialCreateDebtRecoveryExemption
): Promise<IGetInitialDataCreateDebtRecoveryExemption> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_DebtRecoveryExemption_LOVs>(
        `api/property/internal/workflow/createdebtrecoveryexemption/lovs`
      ),
      !isNil(workflowDraftId)
        ? getCreateDebtRecoveryExemptionDraft(workflowDraftId)
        : postNewWorkflowCreateDebtRecoveryExemption(data),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
