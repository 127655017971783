import { DTO_PIC_Swine } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { ICON_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfSwine = nameOfFactory<DTO_PIC_Swine>();
export const colSwineBrandSummary: IColumnFields[] = [
  {
    field: nameOfSwine("Swine_Type_Name"),
    title: "Swine Type",
  },
  {
    field: nameOfSwine("PS_Is_Active"),
    title: "Is Active",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
];
