import { actionsRoute } from "@app/products/property/actions/route";
import { advancedSearchRoute } from "@app/products/property/advanced_search/route";
import { annualLandStockReturnsRoute } from "@app/products/property/annual-land-stock-returns/route";
import { assessmentsRoute } from "@app/products/property/assessments/route";
import { auditHistoryRoute } from "@app/products/property/audit-history/route";
import { centrelinkCustomerConfirmationRoute } from "@app/products/property/centrelink-customer-confirmation/route";
import { certificateRoute } from "@app/products/property/certificates/route";
import { changesOfOwnershipRoute } from "@app/products/property/changes-of-ownership/route";
import { chargeAndNoticeRunsRoute } from "@app/products/property/charge-and-notice-runs/route";
import { collectionsRoute } from "@app/products/property/collections/route";
import { propertyContactRoute } from "@app/products/property/contacts-central-names/route";
import { deferredDutyRoute } from "@app/products/property/deferred-duty/route";
import { propertyFirePreventionRoute } from "@app/products/property/fire-prevention/route";
import { journalsRoute } from "@app/products/property/journals/route";
import { metersRoute } from "@app/products/property/meters/route";
import { noticeGroupsRoute } from "@app/products/property/notice-groups/route";
import { parcelRoute } from "@app/products/property/parcels/route";
import { picRoute } from "@app/products/property/pic/route";
import { processesRoute } from "@app/products/property/processes/route";
import { registersRoute } from "@app/products/property/registers/route";
import { reportsRoute } from "@app/products/property/reports/route";
import { schemesRoute } from "@app/products/property/schemes/route";
import { supplementaryRatesRoute } from "@app/products/property/supplementary-rates/route";
import { systemAdminRoute } from "@app/products/property/system-admin/route";
import { taskScheduleListRoute } from "@app/products/property/task-schedule-list/route";
import { titlesRoute } from "@app/products/property/titles/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";

export const propertyRoute: ICCRoute = {
  path: "property",
  name: "Property",
  enum: eProductType.CommunityProperty,
  children: [
    advancedSearchRoute,
    actionsRoute,
    assessmentsRoute,
    parcelRoute,
    titlesRoute,
    supplementaryRatesRoute,
    schemesRoute,
    metersRoute,
    journalsRoute,
    changesOfOwnershipRoute,
    certificateRoute,
    chargeAndNoticeRunsRoute,
    deferredDutyRoute,
    ...propertyFirePreventionRoute,
    registersRoute,
    picRoute,
    collectionsRoute,
    reportsRoute,
    noticeGroupsRoute,
    processesRoute,
    auditHistoryRoute,
    taskScheduleListRoute,
    centrelinkCustomerConfirmationRoute,
    systemAdminRoute,
    annualLandStockReturnsRoute,
    ...propertyContactRoute,
  ],
};
