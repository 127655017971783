import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

export const renderTopSwitchApplicationWorkflowListEnums = [
  ECorporateSettingsField.TP_EnableReadyforDecision_Approval_Workflow,
  ECorporateSettingsField.TP_Enable_ReasonforPermit_Dialogbox,
  ECorporateSettingsField.TP_Enable_RevisionReceived_Button,
  ECorporateSettingsField.TP_Enable_AppWithdraw_Button,
  ECorporateSettingsField.TP_Enable_PermitNotRequired_Button,
  ECorporateSettingsField.TP_Enable_AppLapsed_Button,
  ECorporateSettingsField.TP_Enable_IssueNOD_Button,
  ECorporateSettingsField.TP_Application_EnableRecommendationsReadyForDecision,
  ECorporateSettingsField.TP_Enable_StateGovt_ApplicationNumber,
  ECorporateSettingsField.TP_EnableBestPracticeWorkFlow,
];
