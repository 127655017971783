export const mockUsages = [
  {
    AssessmentNumber: "7052900",
    PropertyAddress: ",",
    Utility: "Water Usage",
    FromDate: "6-Jun-2020",
    ToDate: "27-Sep-2021",
    NumberOfDays: 478,
    MeterNumber: "03W521424",
    Percentage: 100,
    GrossUsage: 127,
    ChargeableUsage: 127,
    DailyAverageUsage: 0.2657,
    TariffTotal: 91.63,
    Amount: 91.63,
    Tariff: "Water Usage Whitsunday (W1)",
    Usage: 8,
    Unit: "kl",
    UsageTariffUnits: 8,
    Rate: 0,
    TariffAmount: 0,
    Levy: "Water Usage Whitsunday",
    JournalNumber: 10378,
    AssessmentId: 1849,
    UsageId: 171868,
    Id: 1,
  },
  {
    AssessmentNumber: "7052900",
    PropertyAddress: ",",
    Usage: 119,
    Unit: "kl",
    UsageTariffUnits: 119,
    Rate: 0.77,
    TariffAmount: 91.63,
    Levy: "Water Usage Whitsunday",
    JournalNumber: 10378,
    AssessmentId: 1849,
    UsageId: 171868,
    Id: 2,
  },
];
