import { PROPERTY_NOTICE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/constant";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { VO_Notice_Run } from "@app/products/property/charge-and-notice-runs/notice-runs/model";

const nameOf = nameOfFactory<VO_Notice_Run>();
export const colListNoticeRuns: IColumnFields[] = [
  {
    field: nameOf("Notice_Run_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
    locked: true,
    linkTo: (dataItem) =>
      `${PROPERTY_NOTICE_RUNS_ROUTE}/${dataItem.Notice_Run_Id}`,
  },
  {
    field: nameOf("NR_Name"),
    title: "Name",
  },
  {
    field: nameOf("NR_Issue_Date"),
    title: "Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Notice_Type_Name"),
    title: "Type",
  },
  {
    field: nameOf("RP_Name"),
    title: "Rating Period",
  },
  {
    field: nameOf("IP_Name"),
    title: "Instalment Plan",
  },
  {
    field: nameOf("Charge_Run_Id"),
    title: "Charge Run ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Notice_Ordering_Name"),
    title: "Notice Order",
  },
  {
    field: nameOf("NR_No_of_Notices"),
    title: "Number of Notices",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("NR_No_of_Assessments"),
    title: "Number of Assessments",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("NR_Notices_Printed_or_Exported"),
    title: "Notices Produced On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("NR_Charges_Raised"),
    title: "Charges Raised",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NR_Rebate_Amount"),
    title: "Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InterestBalance"),
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("TotalBalance"),
    title: "Total Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NR_Arrears_Balance"),
    title: "Arrears Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NR_Arrears_Interest_Balance"),
    title: "Arrears Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NR_Current_Balance"),
    title: "Current Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NR_Postponed_Balance"),
    title: "Postponed Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NR_Postponed_Interest_Balance"),
    title: "Postponed Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NR_Postponed_Charges_Raised"),
    title: "Postponed Charges Raised",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NR_Created_By_UserId"),
    title: "Created By",
  },
  {
    field: nameOf("NR_Created_On_DateTime"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("NR_Report_Template"),
    title: "Report Template",
  },
  {
    field: nameOf("NR_Minimum_Notice_Amount"),
    title: "Minimum Notice Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NR_Include_if_not_in_Charge_Run"),
    title: "Include If Not in Charge Run",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("NR_Include_Balances"),
    title: "Include Balances",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("NR_Include_Ungrouped_Notices"),
    title: "Include Ungrouped Notices",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("NR_Exclude_Arrangements"),
    title: "Exclude Arrangements",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("NR_Exclude_Assess_with_Rebates"),
    title: "Exclude Assessments With Rebates",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("NR_Exclude_without_Rebates"),
    title: "Exclude Assessments Without Rebates",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("NR_Exclude_Instalments"),
    title: "Exclude Instalments",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("NR_Exclude_If_Interest_Exempt"),
    title: "Exclude If Interest Exempt",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("NR_Set_TransactionDate_to_IssueDate"),
    title: "Set Transaction Date to Issue Date",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("NR_Pay_In_Full_process_Only"),
    title: "Only Include Assessments Paying in Full",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];
