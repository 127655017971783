import { DTO_Constraint } from "@app/products/property/parcels/[id]/components/child-screens/constraints/model";

export const mockParcelConstraints: DTO_Constraint[] = [
  {
    ParcelConstraintId: 1,
    CategoryName: "CategoryName1",
    ConstraintName: "ConstraintName1",
    ConstraintCode: "ConstraintCode1",
    ChangedOn: new Date(),
    ChangedBy: "Tin Nguyen",
  },
  {
    ParcelConstraintId: 2,
    CategoryName: "CategoryName2",
    ConstraintName: "ConstraintName2",
    ConstraintCode: "ConstraintCode2",
    ChangedOn: new Date(),
    ChangedBy: "Tin Nguyen",
  },
  {
    ParcelConstraintId: 3,
    CategoryName: "CategoryName3",
    ConstraintName: "ConstraintName3",
    ConstraintCode: "ConstraintCode3",
    ChangedOn: new Date(),
    ChangedBy: "Tin Nguyen",
  },
];
