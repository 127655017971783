import { PropertyFinancialSummariesForm } from "@app/products/property/assessments/financial-summaries/components/manage-dialog/components/general-form-dialog/_index";
import React from "react";
interface Props {
  onClose: () => void;
}

export const NewPropertyFinancialSummaries = ({ onClose }: Props) => {
  return (
    <PropertyFinancialSummariesForm
      title="New Financial Summary"
      isNew={true}
      onClose={onClose}
      formData={undefined}
    />
  );
};
