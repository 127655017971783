import { getDisplayTextWithDashes } from "@common/utils/common";

export const CollectionBookmark = {
  getBookmarkListViewDisplayName() {
    return "Property - Collections";
  },

  getBookmarkListViewDetail() {
    return "Property - Collections - List";
  },

  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.Collection_Id
      ? `- ${selectedRow.Collection_Id}`
      : "";
    return `Property - Collections - List ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.Collection_Id + ", " + selectedRow.Collection_Name,
    ]);
  },
};
