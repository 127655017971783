import { history } from "@/AppRoutes";
import { Attachment } from "@app/core/documents/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { TypeSubmitActions } from "@common/constants/enumerations";
import { APIResponseStatus } from "@common/constants/response-status";
import { getHelpDocumentById, postHelpDocument } from "@common/pages/settings/lookups/help-documents/_id/api";
import { SETTINGS_LOOKUPS_HELP_DOCUMENTS_ROUTE } from "@common/pages/settings/lookups/help-documents/_id/constant";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
import { ICCHelpDocument, ICCHelpDocument_Ext, ILookupHelpDocumentParentSection } from "./model";
import { DBRowState } from "@app/products/crms/[id]/model";

configure({ enforceActions: "always" });

class LookupDocumentStore {
  private _lookupHelpDocument?: ICCHelpDocument = undefined;
  private _isLoading: boolean = false;
  private _isInactive: boolean = false;
  private _parentSection?: ILookupHelpDocumentParentSection = undefined;
  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get helpDocument() {
    return toJS(this._lookupHelpDocument);
  }
  setHelpDocument = (document?: ICCHelpDocument) => {
    runInAction(() => {
      this._lookupHelpDocument = document;
    });
  };

  setLookupHelpDocumentWithLoading = async (document?: ICCHelpDocument) => {
    runInAction(() => {
      this._isLoading = true;
      this._lookupHelpDocument = document;
      this._isLoading = false;
    });
  };


  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: ILookupHelpDocumentParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get isInactive() {
    return this._isInactive;
  }
  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get helpDocumentId() {
    return toJS(this.helpDocument?.Attachment_ID);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._lookupHelpDocument = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._parentSection = undefined;
      this._onSubmit = undefined;
      this._isInactive = false;
    });
  };

  loadHelpDocument = async (
    helpDocumentId: number,
    isNew?: boolean
  ): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    // Load LOVs data
    if (isNew) {
      let helpDocument = {
        AddToRecords: true,
        FileNumber: "",
        ExternalDocument: false
      } as ICCHelpDocument_Ext
      if (!isNil(this.parentSection?.parentDocument)) {
        helpDocument = { ...helpDocument, ...this.parentSection.parentDocument };
      }
      this.setHelpDocument(helpDocument);
    } else {
      let newHelpDocument = undefined;
      const response = await getHelpDocumentById(helpDocumentId);

      if (isSuccessResponse(response)) {
        newHelpDocument = {
          ...response?.data,
          _options: {
            FileUpload: [
              {
                FileEntry: {
                  ...response?.data?.FileEntry,
                  Base64FileAttachment:
                    response?.data?.FileEntry?.FileAttachment,
                },
              },
            ] as Attachment[],
          },
        } as ICCHelpDocument_Ext;
        if ((response.data?.Sys_DBRowState === DBRowState.Inactive)) {
          this.setIsInactive(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important Note",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        } else {
          this.setIsInactive(false);
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      this.setHelpDocument(newHelpDocument);
      if (this.parentSection?.notification) {
        this.parentSection?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setParentSection({
          ...this._parentSection,
          notification: [],
        } as ILookupHelpDocumentParentSection);
      }
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  saveHelpDocument = async (
    documentInfo: ICCHelpDocument,
    action: TypeSubmitActions
  ) => {
    this.setIsLoading(true);
    const response = await postHelpDocument(documentInfo);
    this.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      if (this.helpDocumentId) await this.loadHelpDocument(this.helpDocumentId);
      this.runActions(action, response);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Save help document failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
  };

  runActions = (action: TypeSubmitActions, response?: APIResponse) => {
    switch (action) {
      case TypeSubmitActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Help document saved successfully",
          type: "success",
        });
        break;
      case TypeSubmitActions.New:
        appNotificationStore.clearNotifications();
        history.replace(
          `${SETTINGS_LOOKUPS_HELP_DOCUMENTS_ROUTE}/${response?.data?.ID}`,
          {
            notification: [
              {
                title: "Help document saved successfully",
                type: "success",
              },
            ],
          }
        );
        break;
      default:
        break;
    }
  };
}

export const lookupDocumentInstance = new LookupDocumentStore();
const lookupDocumentContext = createContext(lookupDocumentInstance);
export const useLookupHelpDocument = () => {
  return useContext(lookupDocumentContext);
};
