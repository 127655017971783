import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { Svc_RefUsage_RecycleBin } from "@common/pages/settings/system-admin/recycle-bin/model";
import { nameOfFactory } from "@common/utils/common";
import { getPageLinkByRecordType } from "@common/utils/getPageLinkByRecordType";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_RefUsage_RecycleBin>();
export const colRecycleBin: IColumnFields[] = [
  {
    field: nameOf("Id"),
    title: "Record Id",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem: Svc_RefUsage_RecycleBin) =>
      getPageLinkByRecordType(dataItem.RecordType_ENUM, dataItem.Id),
  },
  {
    field: nameOf("DeletedDate"),
    title: "Deleted Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DeletedBy"),
    title: "Deleted By",
  },
  {
    field: nameOf("RecordType"),
    title: "Record Type",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
];
