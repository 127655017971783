import { useChangeOfOwnershipDialogStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/store";
import { getDropdownValue } from "@common/utils/common";
import { ICustomEditCell } from "@components/cc-grid/model";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React from "react";

export const UnitRoleCell = observer(
  ({ value, onChange, dataIndex, dataItem, field }: ICustomEditCell) => {
    const { dynamicRoles } = useChangeOfOwnershipDialogStore();
    return (
      <DropDownList
        textField="Role_Name"
        dataItemKey="Role_Id"
        data={dynamicRoles ?? []}
        value={getDropdownValue(value, dynamicRoles ?? [], "Role_Name")}
        onChange={(event) => {
          if (onChange) {
            onChange({
              field: field,
              dataIndex: dataIndex || 0,
              dataItem,
              syntheticEvent: event.syntheticEvent,
              value: event.value?.Role_Name,
            });
          }
        }}
      />
    );
  }
);
