import {
  apiCoreGetAddress,
  apiCoreGetAddressLookApprox,
} from "@app/core/components/common/utils";
import { VMASResult } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/address/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const searchWastewaterAddress = async (
  searchText: string
): Promise<APIResponse<VMASResult[]>> => {
  try {
    return await CoreAPIService.getClient().get<VMASResult[]>(
      apiCoreGetAddress(),
      {
        params: {
          valueSearch: searchText,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const checkValidAddress = async (
  suburb: string,
  streetName: string
): Promise<APIResponse<VMASResult[]>> => {
  try {
    return await CoreAPIService.getClient().get<VMASResult[]>(
      apiCoreGetAddressLookApprox(),
      {
        params: {
          suburb,
          streetName,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
