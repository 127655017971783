import { IBuildingRegisterEasementFormData } from "@app/products/building/registers/easements/[id]/model";

export const mockBuildingRegisterEasement: IBuildingRegisterEasementFormData = {
  ID: 1,
  RegisterNo: "20171",
  Contact: "DA SILVA, Florindo Duque",
  ContactAddress: "83 Sherwoood Rd Narre Warren VIC 3805",
  ContactContact: "No Preferred Contact",
  Address: "4 Casey Court Pakenham VIC 3810",
  Owners: "Da Silva, J W & H M4 Casey Ct Pakenham, Vic 3810",
  Details: "L715 PS616073 V11105 F165 Assess: 1",

  Description: "1111111",
  Officer: "Justin Da Silva",
  AppNo: "",
  RefNumber: "",
  FileNumber: "",
  Lodged: new Date("09/10/2019"),
  Type: "Approved",
  Complete: new Date("02/08/2020"),

  OutstandingFees: 100,
  DebtorNumber: 0,
};
