import { AnimalsRegister } from "@app/products/animals/model";
import { animalsPickerOptions } from "@app/products/animals/pound-register/[id]/components/child-screens/general/form-element/config";
import { PanelID } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/case-details/animal-wandering/model";
import {
  ContentHeaderDefault,
  DisplayContentHeader,
  caseDetailAnimalWanderingLaunchAnimal,
  caseDetailAnimalWanderingLaunchPound,
} from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/case-details/animal-wandering/util";
import { useCRMSEventCaseDetailsStore } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/case-details/store";
import {
  CaseDetailAnimalsWandering,
  Event,
  EventHandlerRequest,
  EventMapObj,
  EventUpdateTriggers,
  Svc_EventFormAction,
} from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { ServiceStandard } from "@app/products/crms/service-standards/[id]/model";
import { checkProductionIsActive } from "@app/products/crms/util";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import {
  YesNoDropDownValue,
  getDropdownValue,
  nameOfFactory,
} from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isEmpty, isNil, isNumber } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const nameOfEventMapObj = nameOfFactory<EventMapObj>();
const nameOfEvent = nameOfFactory<Event>();
const nameOfServicesStandard = nameOfFactory<ServiceStandard>();
const nameOfCaseDetailAnimalsWandering =
  nameOfFactory<CaseDetailAnimalsWandering>();
const getNameOfCaseDetail = (name: keyof CaseDetailAnimalsWandering) =>
  `${nameOfEventMapObj("Event")}.${nameOfEvent(
    "CaseDetailAnimalsWandering"
  )}.${nameOfCaseDetailAnimalsWandering(name)}`;

interface ICaseDetailsAnimalWanderingProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}

export const CaseDetailsAnimalWandering = observer(
  ({
    formRenderProps,
    isDisabled = false,
  }: ICaseDetailsAnimalWanderingProps) => {
    const { valueGetter, onChange } = formRenderProps;
    //#region Store
    const {
      accessRights,
      setIsExpandedCaseDetails,
      onSubmit,
      eventChangeHandler,
    } = useCRMSEventStore();
    const { settings, siteBaseUrl } = useCommonCoreStore();
    const { productsActive } = useGlobalStore();
    const {
      animalTypeId,
      animalType,
      animalLOVs,
      loadAnimalLOVs,
      isLoadingAnimalWandering,
      setIsLoadingAnimalWandering,
      breedLOVs,
      populateBreeds,
      councilContact,
      loadCouncilDetails,
    } = useCRMSEventCaseDetailsStore();
    //#endregion Store

    //#region State
    const [headerDetails, setHeaderDetails] = useState<ContentHeaderDefault>();
    //#endregion State

    //#region Variables
    const isActiveAnimalProduction = checkProductionIsActive(
      productsActive,
      PRODUCT_TYPE.Animals
    );
    const eventFormObj = valueGetter(nameOfEventMapObj("Event"));
    const animalsWanderingLabel =
      valueGetter(
        `${nameOfEventMapObj("Event")}.${nameOfEvent(
          "ServiceStandard"
        )}.${nameOfServicesStandard("CaseDetails_Label")}`
      ) ?? "Animals wandering";
    const animalWanderingPound = valueGetter(
      getNameOfCaseDetail("PoundRegister_ID")
    );
    const animalWanderingAniRegister = valueGetter(
      getNameOfCaseDetail("AnimalRegistration_ID")
    );

    var animalOwner =
      valueGetter(
        `${getNameOfCaseDetail("AnimalOwner")}.Contact.DisplayName`
      ) ?? "";

    var litPreferredAddress =
      valueGetter(
        `${getNameOfCaseDetail(
          "AnimalOwner"
        )}.Contact.PreferredAddress.Formatted_SingleLine`
      ) ?? "";

    var litOwnerContact =
      valueGetter(`${getNameOfCaseDetail("AnimalOwner")}.Contact
      .PreferredMethod_Formatted`) ?? "";
    //#endregion Variables

    //#region Get settings
    const enabledPoundRegister = getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_EnablePoundRegister]
    );
    const councilDetailsID = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_CouncilDetails]
    );
    //#endregion Get settings

    //#region Memo variables
    const disableAnimalRegisteredWhenCollected = useMemo(() => {
      if (isActiveAnimalProduction && !isNil(animalWanderingAniRegister)) {
        return true;
      }
      return !isNil(animalWanderingPound);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animalWanderingPound, productsActive, animalWanderingAniRegister]);

    const animalOwnerDisplay = useMemo(() => {
      var animalOwnerTxt = animalOwner;
      if (!isEmpty(litPreferredAddress)) {
        animalOwnerTxt += ", " + litPreferredAddress;
      }
      return animalOwnerTxt;
    }, [animalOwner, litPreferredAddress]);

    const isShowEnterInPound = useMemo(() => {
      return (
        isActiveAnimalProduction &&
        enabledPoundRegister &&
        isNil(animalWanderingPound)
      );
    }, [isActiveAnimalProduction, enabledPoundRegister, animalWanderingPound]);
    //#endregion Memo variables

    //#region Functions
    const animalsPickerHandler = async (value: AnimalsRegister) => {
      if (isNil(value)) {
        onChange(getNameOfCaseDetail("AnimalNumber"), { value: "" });
        onChange(getNameOfCaseDetail("AnimalRegistration_ID"), { value: null });
      } else {
        const params: EventHandlerRequest = {
          EventFormAction: Svc_EventFormAction.Form_PickAnimal,
          Event: eventFormObj,
          EventArgs: value?.ID ?? 0,
          isFirstTimeLoad: false,
        };
        eventChangeHandler(params, "Change animal failed.");
        setIsExpandedCaseDetails(true);
      }
    };

    const handleAnimalTypeChange = async (event: ComboBoxChangeEvent) => {
      if (event.target.value?.Key) {
        var animalTypeID = event.target.value.Key;
        setIsLoadingAnimalWandering(true);
        onChange(getNameOfCaseDetail("AnimalType_ID"), {
          value: animalTypeID,
        });

        populateBreeds(animalTypeID);
        onChange(getNameOfCaseDetail("AnimalType"), {
          value: animalType,
        });
        setIsLoadingAnimalWandering(false);
      } else {
        onChange(getNameOfCaseDetail("AnimalType_ID"), {
          value: null,
        });
      }
    };

    const loadFirstTime = async () => {
      setIsLoadingAnimalWandering(true);
      await loadAnimalLOVs();
      if (councilDetailsID && isNumber(councilDetailsID)) {
        await loadCouncilDetails(councilDetailsID);
      }
      const formAnimalTypeID = valueGetter(
        getNameOfCaseDetail("AnimalType_ID")
      );
      if (formAnimalTypeID !== animalTypeId) {
        await populateBreeds(formAnimalTypeID);
        onChange(getNameOfCaseDetail("AnimalType"), {
          value: animalType,
        });
      }
      setIsLoadingAnimalWandering(false);
    };

    const updateSaveTriggers = (triggers: EventUpdateTriggers) => {
      let saveTriggers: EventUpdateTriggers[] =
        valueGetter(
          `${nameOfEventMapObj("Event")}.${nameOfEvent("SaveTriggers")}`
        ) ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(
          `${nameOfEventMapObj("Event")}.${nameOfEvent("SaveTriggers")}`,
          {
            value: saveTriggers,
          }
        );
      }
    };
    //#endregion Functions

    useEffect(() => {
      var newHeaderDetails = DisplayContentHeader(
        PanelID.pnlCaseAnimalsWandering,
        eventFormObj,
        accessRights
      );
      setHeaderDetails(newHeaderDetails);
    }, [eventFormObj, accessRights]);

    useEffectOnce(() => {
      loadFirstTime();
      //Set initial value
      if (isNil(valueGetter(getNameOfCaseDetail("WasAnimalFound"))))
        onChange(getNameOfCaseDetail("WasAnimalFound"), { value: false });
      if (isNil(valueGetter(getNameOfCaseDetail("AnimalReturnedToOwner"))))
        onChange(getNameOfCaseDetail("AnimalReturnedToOwner"), {
          value: false,
        });
      if (isNil(valueGetter(getNameOfCaseDetail("AnimalImpound"))))
        onChange(getNameOfCaseDetail("AnimalImpound"), { value: false });
      if (
        isNil(valueGetter(getNameOfCaseDetail("AnimalRegisteredWhenCollected")))
      )
        onChange(getNameOfCaseDetail("AnimalRegisteredWhenCollected"), {
          value: false,
        });

      updateSaveTriggers(EventUpdateTriggers.SaveCaseDetailAnimalsWandering);
    });

    return isLoadingAnimalWandering ? (
      <Loading isLoading={isLoadingAnimalWandering} />
    ) : (
      <>
        {/* Detail header */}
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCValueField
              label={headerDetails?.HeaderSubTitle1 ?? ""}
              value={headerDetails?.HeaderSubTitle3 ?? ""}
            />
            <CCValueField
              label={headerDetails?.HeaderSubTitle5 ?? ""}
              value={headerDetails?.HeaderSubTitle6 ?? ""}
            />
            <CCValueField
              label={headerDetails?.HeaderSubTitle2 ?? ""}
              value={headerDetails?.HeaderSubTitle4 ?? ""}
            />
          </div>
        </section>
        <br />

        {/* Animals Wandering */}
        <section className="cc-field-group ">
          <label className="cc-label">{animalsWanderingLabel}</label>
          <div className="cc-custom-sub-panel-bar cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Was the animal found?" isMandatory />
                <Field
                  name={getNameOfCaseDetail("WasAnimalFound")}
                  component={CCSwitch}
                  checked={valueGetter(getNameOfCaseDetail("WasAnimalFound"))}
                  validator={requiredValidator}
                  disabled={!isNil(animalWanderingPound) || isDisabled}
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title="Was the animal returned to the owner?"
                  isMandatory
                />
                <Field
                  name={getNameOfCaseDetail("AnimalReturnedToOwner")}
                  component={CCSwitch}
                  checked={valueGetter(
                    getNameOfCaseDetail("AnimalReturnedToOwner")
                  )}
                  validator={requiredValidator}
                  disabled={!isNil(animalWanderingPound) || isDisabled}
                />
              </div>
              {!enabledPoundRegister && (
                <div className="cc-field">
                  <CCLabel title="Was the animal impounded?" isMandatory />
                  <Field
                    name={getNameOfCaseDetail("AnimalImpound")}
                    component={CCSwitch}
                    checked={valueGetter(getNameOfCaseDetail("AnimalImpound"))}
                    validator={requiredValidator}
                    disabled={!isNil(animalWanderingPound) || isDisabled}
                  />
                </div>
              )}
              <div className="cc-field">
                <CCLabel title={councilContact} isMandatory />
                <Field
                  name={getNameOfCaseDetail("AnimalRegisteredWhenCollected")}
                  component={CCSwitch}
                  checked={valueGetter(
                    getNameOfCaseDetail("AnimalRegisteredWhenCollected")
                  )}
                  validator={requiredValidator}
                  disabled={disableAnimalRegisteredWhenCollected || isDisabled}
                />
              </div>
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Animal registration{" "}
                  {valueGetter(
                    getNameOfCaseDetail("AnimalRegisteredWhenCollected")
                  ) && <CCTooltip type="validator" position="right" />}
                  {animalWanderingAniRegister && (
                    <i
                      className="fas fa-external-link-alt fa-xs cc-link-icon cc-grid-link-text"
                      onClick={() => {
                        // if (!isNil(animalWanderingPound))
                        caseDetailAnimalWanderingLaunchAnimal(
                          animalWanderingAniRegister,
                          productsActive,
                          siteBaseUrl
                        );
                      }}
                    />
                  )}
                </label>
                <Field
                  component={InputPickerSearch}
                  valueMapGrid={valueGetter(
                    getNameOfCaseDetail("AnimalRegistration_ID")
                  )}
                  name={getNameOfCaseDetail("AnimalRegistration_ID")}
                  nameDisplay="DisplayName"
                  value={valueGetter(getNameOfCaseDetail("AnimalNumber"))}
                  options={animalsPickerOptions}
                  onChange={animalsPickerHandler}
                  disabled={!isNil(animalWanderingPound) || isDisabled}
                  validator={
                    valueGetter(
                      getNameOfCaseDetail("AnimalRegisteredWhenCollected")
                    )
                      ? requiredValidator
                      : undefined
                  }
                  isCRMS
                />
              </div>
              {!isEmpty(
                valueGetter(getNameOfCaseDetail("PoundRegister_No"))
              ) && (
                <div className="cc-field">
                  <label className="cc-label">
                    Pound register{" "}
                    <i
                      className="fas fa-external-link-alt fa-xs cc-link-icon cc-grid-link-text"
                      onClick={() => {
                        caseDetailAnimalWanderingLaunchPound(
                          valueGetter(getNameOfCaseDetail("PoundRegister_ID")),
                          productsActive,
                          siteBaseUrl
                        );
                      }}
                    />
                  </label>
                  <Field
                    component={CCLabel}
                    name={getNameOfCaseDetail("PoundRegister_No")}
                    title={valueGetter(getNameOfCaseDetail("PoundRegister_No"))}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
        <br />

        {/* Animals Details */}
        <section className="cc-field-group">
          <label className="cc-label">Animals Details</label>
          <div className="cc-custom-sub-panel-bar cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Animal name" />
                <Field
                  name={getNameOfCaseDetail("AnimalName")}
                  component={CCInput}
                  disabled={!isNil(animalWanderingPound) || isDisabled}
                />
              </div>
              {animalWanderingAniRegister && (
                <div className="cc-field">
                  <CCValueField
                    label="Animal owner"
                    value={animalOwnerDisplay}
                  />
                </div>
              )}
              <div className="cc-field">
                <CCLabel title="Tag number" />
                <Field
                  name={getNameOfCaseDetail("TagNumber")}
                  component={CCInput}
                  disabled={!isNil(animalWanderingPound) || isDisabled}
                />
              </div>
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCValueField
                  label="Registered address"
                  value={
                    valueGetter(
                      `${getNameOfCaseDetail(
                        "RegisteredAddress"
                      )}.Formatted_SingleLine`
                    ) ?? ""
                  }
                />
              </div>
              <div className="cc-field">
                <CCValueField label="Owner contact" value={litOwnerContact} />
              </div>
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Animal type" />
              <Field
                name={getNameOfCaseDetail("AnimalType_ID")}
                dataItemKey="Key"
                textField="Value"
                data={animalLOVs?.AnimalTypes ?? []}
                value={getDropdownValue(
                  valueGetter(getNameOfCaseDetail("AnimalType_ID")),
                  animalLOVs?.AnimalTypes ?? []
                )}
                onChange={handleAnimalTypeChange}
                component={CCSearchComboBox}
                disabled={!isNil(animalWanderingPound) || isDisabled}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Colour" />
              <Field
                name={getNameOfCaseDetail("Colour_KWD")}
                dataItemKey="Key"
                textField="Value"
                data={animalLOVs?.Animals_Colour ?? []}
                isUseDefaultOnchange
                component={CCSearchComboBox}
                disabled={!isNil(animalWanderingPound) || isDisabled}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Gender" />
              <Field
                name={getNameOfCaseDetail("AnimalSex")}
                textField="Value"
                dataItemKey="Key"
                data={animalLOVs?.Animals_Gender ?? []}
                isUseDefaultOnchange
                component={CCSearchComboBox}
                disabled={!isNil(animalWanderingPound) || isDisabled}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Microchipped" />
              <Field
                name={getNameOfCaseDetail("Flag_Microchipped")}
                dataItemKey="Key"
                textField="Value"
                data={YesNoDropDownValue}
                isUseDefaultOnchange
                component={CCSearchComboBox}
                disabled={!isNil(animalWanderingPound) || isDisabled}
              />
            </div>
            {valueGetter(getNameOfCaseDetail("Flag_Microchipped")) === true ? (
              <div className="cc-field">
                <CCLabel title="Microchip number" />
                <Field
                  name={getNameOfCaseDetail("MicrochipNumber")}
                  component={CCInput}
                  placeholder={"Microchip number"}
                  disabled={!isNil(animalWanderingPound) || isDisabled}
                />
              </div>
            ) : null}
            <div className="cc-field">
              <CCLabel title="Breed" />
              <Field
                name={getNameOfCaseDetail("AnimalBreed_KWD")}
                dataItemKey="Key"
                textField="Value"
                data={breedLOVs ?? []}
                isUseDefaultOnchange
                component={CCSearchComboBox}
                disabled={!isNil(animalWanderingPound) || isDisabled}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            {/* Code Radium display none */}
            {/* <div className="cc-field">
            <CCLabel title="Cross breed" />
            <Field
              name={getNameOfCaseDetail("CrossBreed_KWD")}
              dataItemKey="Key"
              textField="Value"
              data={[]}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={!isNil(animalWanderingPound)}
            />
          </div> */}
            <div className="cc-field">
              <CCLabel title="Size" />
              <Field
                name={getNameOfCaseDetail("Size_KWD")}
                dataItemKey="Key"
                textField="Value"
                data={animalLOVs?.Animals_Size ?? []}
                isUseDefaultOnchange
                component={CCSearchComboBox}
                disabled={!isNil(animalWanderingPound) || isDisabled}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Condition" />
              <Field
                name={getNameOfCaseDetail("Condition_KWD")}
                dataItemKey="Key"
                textField="Value"
                data={animalLOVs?.Animals_Condition ?? []}
                isUseDefaultOnchange
                component={CCSearchComboBox}
                disabled={!isNil(animalWanderingPound) || isDisabled}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Disposition" />
              <Field
                name={getNameOfCaseDetail("Disposition_KWD")}
                dataItemKey="Key"
                textField="Value"
                data={animalLOVs?.Animals_Disposition ?? []}
                isUseDefaultOnchange
                component={CCSearchComboBox}
                disabled={!isNil(animalWanderingPound) || isDisabled}
              />
            </div>
          </div>

          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Comments" />
              <Field
                name={getNameOfCaseDetail("Comment")}
                component={CCTextArea}
                placeholder="Comments"
                rows={4}
                disabled={!isNil(animalWanderingPound) || isDisabled}
              />
            </div>
          </div>

          {isShowEnterInPound && (
            <div className="cc-form-cols-3">
              <Button
                className="cc-button"
                themeColor="primary"
                onClick={onSubmit}
                name={ActionSubmitActions.EventEnterIntoPound}
                disabled={isDisabled}
                // iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
              >
                Enter into Pound
              </Button>
            </div>
          )}
        </section>
      </>
    );
  }
);
