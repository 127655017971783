import { useAnimalsKennelStore } from "@app/products/animals/kennels/[id]/store";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { formatDisplayValue, formatOSFees } from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export interface IAnimalsKennelDetailsTabProps {
  isTabDetail?: boolean;
}

export const AnimalsKennelDetailsTab = observer(
  ({ isTabDetail = true }: IAnimalsKennelDetailsTabProps) => {
    const isNew = useIsNew();
    const { lastSelectedId } = useCCProductListViewStore();
    const {
      animalsKennel,
      loadDetails,
      responseLoadError,
      osFee,
      UIControl,
      isLoadingDetails,
      initData,
    } = useAnimalsKennelStore();

    const { id } = useParams<{ id: string }>();
    const animalsKennelId: number = id ? parseInt(id) : lastSelectedId;
    useEffect(() => {
      if (!isNew && !id) loadDetails(animalsKennelId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animalsKennelId]);

    if (isLoadingDetails) return <Loading isLoading={isLoadingDetails} />;

    if (responseLoadError) {
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            animalsKennelId && loadDetails(animalsKennelId);
          }}
        />
      );
    }

    if (!animalsKennel) return <NoData />;
    return (
      <ProductReferenceBody>
        <ProductReferenceHeading
          title={"Registration number:"}
          value={animalsKennel.KennelNo}
          primary
        />
        <ProductReferenceBlock>
          <ProductReferenceRow
            title={"Address:"}
            value={sanitizeHtml(
              isTabDetail
                ? UIControl?.LitAddressUnFormat?.Value
                : initData?.LitAddressUnFormat?.Value
            )}
          />
          <ProductReferenceRow
            title={"Holder:"}
            value={sanitizeHtml(
              isTabDetail
                ? UIControl?.LitContactUnFormat?.Value
                : initData?.LitContactUnFormat?.Value
            )}
          />
          <ProductReferenceRow
            title={"Lodged:"}
            value={formatDisplayValue(
              animalsKennel?.Date_Lodged,
              DATE_FORMAT.DATE
            )}
          />
          <ProductReferenceRow
            title={"Issue:"}
            value={formatDisplayValue(
              animalsKennel?.Date_EffectiveFrom,
              DATE_FORMAT.DATE
            )}
          />
          {!!animalsKennel?.Date_EffectiveTo && (
            <ProductReferenceRow
              title={"Expires:"}
              value={formatDisplayValue(
                animalsKennel?.Date_EffectiveTo,
                DATE_FORMAT.DATE
              )}
            />
          )}
          <ProductReferenceRow
            title={"OS fees:"}
            format="currency"
            value={
              osFee
                ? formatDisplayValue(osFee, CURRENCY_FORMAT.CURRENCY1)
                : "$0"
            }
            style={formatOSFees(osFee)}
            isPropertyStyle={false}
          />
        </ProductReferenceBlock>
      </ProductReferenceBody>
    );
  }
);
