import { ModifyRegisterDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/modify-register/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const ModifyRegisterButton = () => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { pushNotification } = useCCAppNotificationStore();
  return (
    <>
      <CCNavButton
        title="Modify register"
        onClick={() => setIsShowDialog(true)}
      />
      {isShowDialog && (
        <ModifyRegisterDialog
          onClose={() => setIsShowDialog(false)}
          onSubmit={(data) => {
            setIsShowDialog(false);
            pushNotification({
              title: "Account modified successfully",
              type: "success",
            });
          }}
        />
      )}
    </>
  );
};
