import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { CRMS_SERVICE_STANDARDS_ROUTE } from "@app/products/crms/service-standards/[id]/constant";
import {
  ServiceStandardAccordion,
  ServiceStandardInvoiceAccordion,
  ServiceStandardSubcategoriesAccordion,
} from "@app/products/crms/system-admin/categories/[id]/model";
import { CRMS_SYSTEM_ADMIN_CATEGORY_ROUTE } from "@app/products/crms/system-admin/categories/constant";
import { ServiceStandardCategories } from "@app/products/crms/system-admin/model";
import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { getStringValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

const nameOf = nameOfFactory<ServiceStandardCategories>();
const nameOfSubcategoriesAccordion =
  nameOfFactory<ServiceStandardSubcategoriesAccordion>();
const nameOfServiceStandardAccordion =
  nameOfFactory<ServiceStandardAccordion>();

const nameOfInvoiceAccordion = nameOfFactory<ServiceStandardInvoiceAccordion>();

export const colCategories: IColumnFields[] = [
  {
    field: nameOf("Category"),
    title: "Category",
    linkTo: (dataItem) => `${CRMS_SYSTEM_ADMIN_CATEGORY_ROUTE}/${dataItem.ID}`,
  },
];

export const colSubCategoriesAccordion: IColumnFields[] = [
  {
    field: nameOfSubcategoriesAccordion("SubCategory"),
    title: "SubCategory",
    minWidth: 500,
    locked: true,
    linkTo: (dataItem: ServiceStandardSubcategoriesAccordion) => {
      return `${CRMS_SYSTEM_ADMIN_CATEGORY_ROUTE}/${dataItem.Category}/subcategory/${dataItem.ID}`;
    },
  },
  {
    field: nameOfSubcategoriesAccordion("Category"),
    title: "Category",
    minWidth: 500,
  },
];

export const colServiceStandardsAccordion: IColumnFields[] = [
  {
    field: nameOfServiceStandardAccordion("FullName"),
    title: "Category",
    locked: true,
    linkTo: (dataItem: ServiceStandardAccordion) => {
      return `${CRMS_SERVICE_STANDARDS_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: nameOfServiceStandardAccordion("Name"),
    title: "Precis",
  },
  {
    field: nameOfServiceStandardAccordion("Org2"),
    title: "Org2",
  },
  {
    field: nameOfServiceStandardAccordion("FileNumber"),
    title: "File Number",
  },
  {
    field: nameOfServiceStandardAccordion("CRMSChoice"),
    title: "CRMS Choice",
  },
];

export const generateColServiceStandardAccordion = (
  defaultColumnFields: IColumnFields[],
  settings: SettingsMap
) => {
  if (!settings) return defaultColumnFields;

  const cxLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_ProductName]
  );

  let newColumnFields: IColumnFields[] = cloneDeep(defaultColumnFields);

  newColumnFields = newColumnFields.map((columnField: IColumnFields) => {
    if (columnField.field === nameOfServiceStandardAccordion("CRMSChoice")) {
      return {
        field: columnField.field,
        title: `${cxLabel ?? CRMS_PRODUCT_NAME} Choice` ?? columnField.field,
      };
    }
    return columnField;
  });

  return newColumnFields;
};

export const colInvoiceAccordion: IColumnFields[] = [
  {
    field: nameOfInvoiceAccordion("ID"),
    title: "Invoicce",
    locked: true,
  },
  {
    field: nameOfInvoiceAccordion("RecordedDate"),
    title: "Invoice date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfInvoiceAccordion("DueDate"),
    title: "Due date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfInvoiceAccordion("Description"),
    title: "Description",
  },
  {
    field: nameOfInvoiceAccordion("Amount"),
    title: "Amount",
  },
];
