import { CoreAPIService } from "@/common/apis/coreAPIService";
import { IEnquiriesSendResponse } from "@app/products/town-planning/ppr/enquiries/_id/components/dialogs/not-for-vr/model";
import { PPREnquiryIdentityPacket } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { APIResponse } from "@common/apis/model";

export const putSendResponse = async (
  id: number,
  sendResponse: IEnquiriesSendResponse
): Promise<APIResponse<PPREnquiryIdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<PPREnquiryIdentityPacket>(
      `/api/tp/ppr/internal/enquiry/${id}/sendresponse`,
      sendResponse
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putSendResponseApplicant = async (
  id: number
): Promise<APIResponse<PPREnquiryIdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<PPREnquiryIdentityPacket>(
      `/api/tp/ppr/internal/enquiry/${id}/sendresponseapplicant`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
