import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class SettingManageFormActionBarStore {
  private _isLoadingSubmitNew: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoadingSubmitNew() {
    return toJS(this._isLoadingSubmitNew);
  }
  setIsLoadingSubmitNew = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingSubmitNew = isLoading;
    });
  };
}

const settingManageFormActionBarStoreContext = createContext(
  new SettingManageFormActionBarStore()
);
export const useSettingManageFormActionBarStore = () => {
  return useContext(settingManageFormActionBarStoreContext);
};
