import { EventManagementFieldMapping } from "@app/products/event-management/constant";
import { EVENT_MANAGEMENT_MANAGE_ROUTE } from "@app/products/event-management/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colEventManagementFinancePaidFees: IColumnFields[] = [
  {
    field: EventManagementFieldMapping.ReferenceNumber,
    title: "Reference Number",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => {
      return `${EVENT_MANAGEMENT_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: EventManagementFieldMapping.Description,
    title: "Event Description",
    width: 200,
  },
  {
    field: EventManagementFieldMapping.CreatedDate,
    title: "Recorded",
    width: 200,
    format: DATE_FORMAT.DATE,
  },
  { field: EventManagementFieldMapping.FeeType, title: "Type", width: 200 },
  {
    field: EventManagementFieldMapping.AmountIssued,
    title: "Issued",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: EventManagementFieldMapping.AmountPaid, title: "Paid", width: 200 },
  {
    field: EventManagementFieldMapping.ReceiptNumber,
    title: "Receipt Number",
    width: 200,
  },
  {
    field: EventManagementFieldMapping.ReceiptDate,
    title: "Receipt Date",
    width: 200,
    format: DATE_FORMAT.DATE,
  },
  {
    field: EventManagementFieldMapping.ReceiptedBy,
    title: "Receipted By",
    width: 200,
  },
  { field: EventManagementFieldMapping.Method, title: "Method", width: 200 },
  {
    field: EventManagementFieldMapping.CostCode,
    title: "Cost Code",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: EventManagementFieldMapping.InvoiceNumber,
    title: "Invoice Number",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: EventManagementFieldMapping.DebtorNumber,
    title: "Debtor Number",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: EventManagementFieldMapping.SecurePay_Reference,
    title: "Payment Reference",
    width: 200,
  },
];
