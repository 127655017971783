import { IToAccountStepsLOVs } from "@app/products/property/deferred-duty/[id]/components/forms/existed/components/form-steps/transfer-transaction/components/form-elements/to-account/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ToAccountStepStore {
  private _toAccountStepLOVs?: IToAccountStepsLOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get toAccountStepLOVs() {
    return this._toAccountStepLOVs;
  }
  setToAccountStepLOVs = (toAccountStepLOVs?: IToAccountStepsLOVs) => {
    runInAction(() => {
      this._toAccountStepLOVs = toAccountStepLOVs;
    });
  };
}

const toAccountStepStoreContext = createContext(new ToAccountStepStore());
export const useToAccountStepStore = () => {
  return useContext(toAccountStepStoreContext);
};
