import {
  ConditionReference,
  Svc_ConditionReference_Group,
  Svc_Form_ConditionReference,
} from "@app/core/condition-reference/[id]/model";
import { IParentConditionSection } from "@app/core/condition/[id]/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { CCFormOptions } from "@common/stores/flexible-form/constant";
import { nameOfFactory } from "@common/utils/common";

//#region NAME_OF_CONDITION_REFERENCE ========/
//#region Base ========/
export type TCCOptionsConditionReference = {
  parentState?: IParentConditionSection;
  fieldSelectionOption?: KeyValuePacket;
  lovDataGroupOption?: Svc_ConditionReference_Group[];
};

type TConditionReference = Svc_Form_ConditionReference & {
  [CCFormOptions]?: TCCOptionsConditionReference;
};
//#endregion Base =====/

export const nameOfSvcConditionReference = nameOfFactory<TConditionReference>();

// NameOf Condition Reference
export const nameOfConditionReference = (
  conditionReferenceKey: keyof ConditionReference
) => {
  return `${nameOfSvcConditionReference(
    "ConditionReference"
  )}.${nameOfFactory<ConditionReference>()(conditionReferenceKey)}`;
};

// NameOf Condition Reference Options
export const nameOfConditionReferenceOption = (
  tConditionReferenceOptionKey: keyof TCCOptionsConditionReference
) => {
  return `${nameOfSvcConditionReference(
    "CCFormOptions"
  )}.${nameOfFactory<TCCOptionsConditionReference>()(
    tConditionReferenceOptionKey
  )}`;
};
//#endregion NAME_OF_CONDITION_REFERENCE =====/
