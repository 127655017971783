import { IInstallations } from "@app/products/property/meters/[id]/components/child-screens/installation/model";

export const mockInstallations: IInstallations = {
  MeterInstallation: ",Proserpine",
  ListMeterInstallation: [",Proserpine", "P2", "P3"],
  ReadingDetail: {
    NextScheduledReadingDate: new Date("28-Oct-2020"),
    ReadingFrequency: 4,
    RouteName: {
      Key: 4,
      Value: "Cannonvale 2",
    },
    ListRouteName: [
      {
        Key: 1,
        Value: "Active Meters not in Route",
      },
      {
        Key: 2,
        Value: "Airlie",
      },
      {
        Key: 3,
        Value: "Cannonvale 1",
      },
      {
        Key: 4,
        Value: "Cannonvale 2",
      },
      {
        Key: 5,
        Value: "Cannonvale 3",
      },
      {
        Key: 6,
        Value: "Cannonvale 4",
      },
      {
        Key: 7,
        Value: "Jubilee 1",
      },
      {
        Key: 8,
        Value: "Jubilee 2",
      },
      {
        Key: 9,
        Value: "Meters not being read",
      },

      {
        Key: 10,
        Value: "Mt Julian - Shute Harbour Rd",
      },
      {
        Key: 11,
        Value: "Proserpine 1",
      },
      {
        Key: 12,
        Value: "Proserpine 2",
      },
      {
        Key: 13,
        Value: "Proserpine 3",
      },
      {
        Key: 14,
        Value: "Proserpine 4",
      },
      {
        Key: 15,
        Value: "Proserpine 5",
      },
      {
        Key: 16,
        Value: "Shute Harbour",
      },
    ],
    SequenceWithinRoute: 211500,
    AssignedReadingDevice: "PDA ALL",
    DeviceAssignedTo: "Meter Reader 1",
    Locality: { Key: 1, Value: "Proserpine" },
    ListLocality: [
      { Key: 1, Value: "Proserpine" },
      {
        Key: 2,
        Value: "Airlie Beach",
      },
      {
        Key: 3,
        Value: "Andromache",
      },
      {
        Key: 4,
        Value: "Brandy Creek",
      },
      {
        Key: 5,
        Value: "Breadalbane",
      },
      {
        Key: 6,
        Value: "Cannon Valley",
      },
      {
        Key: 7,
        Value: "Cannonvale",
      },
      {
        Key: 8,
        Value: "Cape Conway",
      },
      {
        Key: 9,
        Value: "Cape Gloucester",
      },
      {
        Key: 10,
        Value: "Conway",
      },
      {
        Key: 11,
        Value: "Conway Beach",
      },
      {
        Key: 12,
        Value: "Crystal book",
      },
      {
        Key: 13,
        Value: "Dingo Beach",
      },
      {
        Key: 14,
        Value: "Dittmer",
      },
      {
        Key: 15,
        Value: "Flametree",
      },
      {
        Key: 16,
        Value: "Flametree",
      },
      {
        Key: 17,
        Value: "Foxdale",
      },
      {
        Key: 18,
        Value: "Glen Isla",
      },
      {
        Key: 19,
        Value: "Goorganga Creek",
      },
      {
        Key: 20,
        Value: "Goorganga Plains",
      },
    ],
    KeysAreRequired: false,
    AutoProperty: true,
    PhoneBeforeReading: false,
    EmailBeforeReading: false,
  },
  InstallationDetails: {
    InstallationDate: new Date("22-Dec-2010"),
  },
  AssociationMeter: [],
  BillingDetails: {
    Category: { Key: 6, Value: "Whitsunday Water Usage (W1)" },
    ListCategories: [
      { Key: 1, Value: "Check Meter - Statistics Only" },
      { Key: 2, Value: "No Charge Meter" },
      { Key: 3, Value: "Proserpine Stand Pipe Usage (H1)" },
      { Key: 4, Value: "Whitsunday Water Usage (P1)" },
      { Key: 5, Value: "Whitsunday Water Usage (F1)" },
      { Key: 6, Value: "Whitsunday Water Usage (W1)" },
    ],
    AssessmentsAssociated: [
      {
        Type: "Assessment",
        AssessmentNumber: "106200000",
        MeterNumber: "00A312709",
        BillingGroup: "Standard Periodic Billing",
        UsagePercentage: 0,
        PropertyAddress: "10 Hermitage Drive, Airlie Beach",
        Description: "L0/SP172269.PAR CONWAY - 'LE JARDEN CTS 33735'",
        Improvements: "10 UNITS",
        PrimaryLandUse: "08-Building Unit",
        AssessmentType: "Common Property",
        Status: "Active",
        AssessmentId: 2979,
        ReadingOnRemoval: 400,
      },
      {
        Type: "Assessment",
        AssessmentNumber: "106200000.000",
        MeterNumber: "00B312709",
        BillingGroup: "Standard Periodic Billing",
        UsageEntitlement: 6,
        UsagePercentage: 10,
        DateAttached: new Date("16-May-2015"),
        PropertyAddress: ",",
        Improvements: "UNIT",
        PrimaryLandUse: "Building Units (primary Use",
        AssessmentType: "Part of Master Property",
        Status: "Active",
        AssessmentId: 9019,
      },
      {
        Type: "Assessment",
        AssessmentNumber: "106200000.0001",
        MeterNumber: "00A125498",
        BillingGroup: "Standard Periodic Billing",
        UsageEntitlement: 6,
        UsagePercentage: 10,
        DateAttached: new Date("16-May-2015"),
        PropertyAddress: ",",
        Improvements: "UNIT",
        PrimaryLandUse: "Building Units (primary Use",
        AssessmentType: "Part of Master Property",
        Status: "Active",
        AssessmentId: 9020,
      },
      {
        Type: "Assessment",
        AssessmentNumber: "106200000.0002",
        BillingGroup: "Standard Periodic Billing",
        MeterNumber: "00A455455",
        UsageEntitlement: 6,
        UsagePercentage: 10,
        DateAttached: new Date("16-May-2015"),
        PropertyAddress: ",",
        Improvements: "UNIT",
        PrimaryLandUse: "Building Units (primary Use",
        AssessmentType: "Part of Master Property",
        Status: "Active",
        AssessmentId: 9021,
      },
      {
        Type: "Assessment",
        AssessmentNumber: "106200000.0003",
        MeterNumber: "00A546546",
        BillingGroup: "Standard Periodic Billing",
        UsageEntitlement: 6,
        UsagePercentage: 10,
        DateAttached: new Date("16-May-2015"),
        PropertyAddress: ",",
        Improvements: "UNIT",
        PrimaryLandUse: "Building Units (primary Use",
        AssessmentType: "Part of Master Property",
        Status: "Active",
        AssessmentId: 9022,
      },
      {
        Type: "Assessment",
        AssessmentNumber: "106200000.0004",
        MeterNumber: "00A345459",
        BillingGroup: "Standard Periodic Billing",
        UsageEntitlement: 6,
        UsagePercentage: 10,
        DateAttached: new Date("16-May-2015"),
        PropertyAddress: ",",
        Improvements: "UNIT",
        PrimaryLandUse: "Building Units (primary Use",
        AssessmentType: "Part of Master Property",
        Status: "Active",
        AssessmentId: 9023,
      },
    ],
  },
};
