import { ICCRoute } from "@common/constants/ICCRoute";

export const powerBIRoute: ICCRoute = {
  path: "power-bi",
  name: "Power BI",
  children: [
    {
      path: "custom-report",
      name: "Custom Report",
      component: require("./custom-reports/_index").default,
    },
    {
      path: "list",
      name: "List",
      component: require("./list/_index").default,
    },
    {
      path: ":id(.+)",
      component: require("./[id]/_index").default,
    },
  ],
};
