import { useAssessmentLandRentActionDialogStore } from "@app/products/property/assessments/components/dialogs/assessment-land-rent-action/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const LandRentDetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const { assessmentLandRentActionLOVs } =
      useAssessmentLandRentActionDialogStore();
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Rating period" />
            <Field
              name={nameOf("Rating_Period")}
              data={assessmentLandRentActionLOVs?.RatingPeriod ?? []}
              component={CCSearchComboBox}
              textField="Name"
              dataItemKey="Code"
              value={getDropdownValue(
                getFieldValue("Rating_Period")?.toString(),
                assessmentLandRentActionLOVs?.RatingPeriod ?? [],
                "Code"
              )}
              onChange={(event: ComboBoxChangeEvent) =>
                onChange(nameOf("Rating_Period"), {
                  value: event.value?.Code ?? null,
                })
              }
              disabled={options?.isReadOnly}
            />
          </div>
        </div>

        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Period start date" />
            <Field
              name={nameOf("Period_Start_Date")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Period end date" />
            <Field
              name={nameOf("Period_End_Date")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>

        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Land rent action type" />
            <Field
              name={nameOf("Land_Rent_Action_Type")}
              data={assessmentLandRentActionLOVs?.LandRentActionType ?? []}
              component={CCSearchComboBox}
              textField="Name"
              dataItemKey="Code"
              value={getDropdownValue(
                getFieldValue("Land_Rent_Action_Type")?.toString(),
                assessmentLandRentActionLOVs?.LandRentActionType ?? [],
                "Code"
              )}
              onChange={(event: ComboBoxChangeEvent) =>
                onChange(nameOf("Land_Rent_Action_Type"), {
                  value: event.value?.Code ?? null,
                })
              }
              disabled={options?.isReadOnly}
            />
          </div>
        </div>

        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Actioned date" />
            <Field
              name={nameOf("Actioned_Date")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Valuation" />
            <Field
              name={nameOf("Valuation")}
              component={CCCurrencyInput}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
      </section>
    );
  }
);
