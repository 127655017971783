import { ICCRoute } from "@common/constants/ICCRoute";

export const siteUsersRoute: ICCRoute = {
  path: "site-users",
  name: "Site Users",
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./_id/_index").default,
    },
  ],
};
