import { isNil } from "lodash";
import { useState } from "react";
import { useEffectOnce } from "react-use";

export const attributeName = "page-type";

export const PAGE_TYPE = {
  ProductListView: "isProductListView",
  ManagePage: "isManagePage",
};

export interface IPageType {
  isProductListView: boolean;
  isManagePage: boolean;
}

export const divPropPageType = (pageTypeProp: string) => {
  return { [attributeName]: pageTypeProp };
};

export const usePageType = (): IPageType => {
  const [pageType, setPageType] = useState<IPageType>({
    isProductListView: false,
    isManagePage: false,
  });

  useEffectOnce(() => {
    const pageTypeValue = document
      .querySelector(`[${attributeName}]`)
      ?.getAttribute(attributeName);
    if (
      !isNil(pageTypeValue) &&
      Object.values(PAGE_TYPE).includes(pageTypeValue)
    ) {
      setPageType({
        ...pageType,
        [pageTypeValue]: true,
      });
    }
  });
  return pageType;
};
