import { VO_Constraint } from "@app/products/property/components/dialogs/add-constraints-dialog/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Constraint>();

export const colSelectConstraint: IColumnFields[] = [
  {
    field: nameOf("Constraint_Category_Name"),
    title: "Category",
  },
  {
    field: nameOf("Constraint_Name"),
    title: "Constraint Name",
  },
  {
    field: nameOf("Constraint_Code"),
    title: "Constraint Code",
  },
  {
    field: nameOf("Constraint_Id"),
    title: "Constraint ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
