import { categoriesRoute } from "@app/products/csl/system-admin/categories/route";
import { conditionsRoute } from "@app/products/csl/system-admin/conditions/route";
import { ICCRoute } from "@common/constants/ICCRoute";

export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  children: [
    categoriesRoute,
    conditionsRoute,
    {
      path: "setting",
      name: "Setting",
      component: require("./settings/_index").default,
    },
  ],
};
