import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import {
  enumValuesList,
  isIncludeListNull,
} from "@components/cc-grid/components/grid-column-menus/column-menu-filter/components/custom-grid-column-menu-filter/config";
import { IColumnFields, ICustomFormat } from "@components/cc-grid/model";
import { dataFilterByEnum } from "@components/cc-product-list-view/components/list-view-filter-bar/components/cc-enum-filter";
import { isCurrencyFilter } from "@components/cc-product-list-view/components/list-view-filter-bar/util";
import {
  DatePickerChangeEvent,
  DateTimePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { GridFilterOperator } from "@progress/kendo-react-grid/dist/npm/interfaces/GridFilterOperator";
import {
  Input,
  InputChangeEvent,
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { isFunction, isNumber, isObject, isString } from "lodash";
import React from "react";

interface ICustomColumnMenuFilterCell
  extends Omit<GridFilterCellProps, "onChange"> {
  columnConfig: IColumnFields;
  uniqueKey: string;
  onChange: (event: {
    name: string;
    value: any;
    operator: string | Function;
    syntheticEvent?: React.SyntheticEvent<any>;
    error?: string;
  }) => void;
}
export const CustomColumnMenuFilterCell = (
  props: ICustomColumnMenuFilterCell
) => {
  const { filterType, field, columnConfig, uniqueKey } = props;
  let data = columnConfig?.filterByEnum ? enumValuesList : props.operators;
  const valueField = props.value;
  let operator =
    props.operators.find(
      (item: GridFilterOperator) => item.operator === props.operator
    ) || null;
  const renderOperatorEditor = () => {
    return (
      <CCDropDownList
        value={operator ?? data[0]}
        onChange={operatorChange}
        data={data}
        textField="text"
        name={uniqueKey}
      />
    );
  };

  const inputChange = (value: any, event: any) => {
    if (!props.operator) {
      return;
    }
    props.onChange({
      name: `${field}_${uniqueKey}`,
      value: value,
      operator: props.operator,
      syntheticEvent: event,
      error: filterType === "date" ? requiredValidator(value) : "",
    });
  };

  const getTypeComponent = (type: string, item: any) => {
    if (isIncludeListNull(props.operator)) return null;
    let format = item.format;
    let formatChecker = item.format;

    if (isFunction(item.format) || isObject(item.format)) {
      const newFormatAndChecker: ICustomFormat = isFunction(item.format)
        ? item.format()
        : {
            defaultFormat: item.format.defaultFormat,
            customFormat: item.format.customFormat,
          };

      formatChecker = newFormatAndChecker?.defaultFormat;
      format = newFormatAndChecker?.customFormat;
    }

    switch (type) {
      case "numeric":
        if (isCurrencyFilter(formatChecker)) {
          return (
            <CCCurrencyInput
              name={`${field}_${uniqueKey}`}
              onChange={(e: NumericTextBoxChangeEvent) => {
                inputChange(e.target.value, e);
              }}
              value={valueField}
            />
          );
        }
        return (
          <NumericTextBox
            format={format}
            onChange={(e: NumericTextBoxChangeEvent) => {
              inputChange(e.target.value, e);
            }}
            name={`${field}_${uniqueKey}`}
            value={valueField}
          />
        );
      case "date":
        if (Object.values(DATE_FORMAT).includes(formatChecker)) {
          return (
            <CCDatePicker
              format={DATE_FORMAT.DATE_CONTROL}
              onChange={(e: DatePickerChangeEvent) => {
                inputChange(e.target.value, e);
              }}
              name={field + uniqueKey}
              value={valueField}
            />
          );
        }
        return (
          <CCDateTimePicker
            format={DATETIME_FORMAT.DATETIME_CONTROL}
            onChange={(e: DateTimePickerChangeEvent) => {
              inputChange(e.target.value, e);
            }}
            name={field + uniqueKey}
            value={valueField}
          />
        );
      case "boolean":
        return null;
      case "text":
      default:
        if (item?.filterByEnum) {
          const data: dataFilterByEnum[] = [];
          const arrayValues = Object.values(item.filterByEnum).filter((value) =>
            isString(value)
          );
          const arrayKeys = Object.values(item.filterByEnum).filter((value) =>
            isNumber(value)
          );
          arrayValues.forEach((item, i) => {
            data.push({ text: item as string, value: arrayKeys?.[i] ?? item });
          });
          return (
            <Field
              name={`${field}_${uniqueKey}`}
              component={CCDropDownList}
              isKeyValueDropdown
              data={data}
              textField="value"
              dataItemKey="text"
              onChange={(e: DropDownListChangeEvent) => {
                inputChange(e.value, e);
              }}
              defaultValue={
                valueField
                  ? {
                      text: valueField,
                      value: valueField,
                    }
                  : null
              }
              className={"k-filter-and"}
            />
          );
        } else {
          return (
            <Field
              onChange={(e: InputChangeEvent) => {
                inputChange(e.target.value, e);
              }}
              name={`${field}_${uniqueKey}`}
              value={valueField}
              component={Input}
            />
          );
        }
    }
  };

  const operatorChange = (event: DropDownListChangeEvent) => {
    props.onChange({
      name: `${field}_${uniqueKey}_logic`,
      value: isIncludeListNull(event.target.value.operator)
        ? null
        : props.value,
      operator: event.target.value.operator,
      syntheticEvent: event.syntheticEvent,
    });
  };

  return (
    <div>
      {renderOperatorEditor()}
      {getTypeComponent(filterType, columnConfig)}
    </div>
  );
};
