import { getRelatedAnimalsUrl } from "@app/products/animals/[id]/util";
import { colRelatedAnimalsKennelAccordion } from "@app/products/animals/kennels/[id]/component/child-screens/general/form-element/components/related-animals/config";
import { AnimalsRegister } from "@app/products/animals/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IRelatedAnimalsKennelProps {
  id?: number;
  pidNO?: string;
}

const nameOf = nameOfFactory<AnimalsRegister>();
export const RelatedKennelAnimals = ({
  id,
  pidNO,
}: IRelatedAnimalsKennelProps) => {
  return (
    <CCGrid
      dataUrl={getRelatedAnimalsUrl(id, pidNO)}
      primaryField={nameOf("ID")}
      columnFields={colRelatedAnimalsKennelAccordion}
    />
  );
};
