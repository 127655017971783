export const mockFinancialSummary = {
  EstimatedCosts: 8208.56,
  EstimatedPercentage: 6.43,
  ActualPercentage: "",
  Size: "",
  Coefficient: "",
  NoofUnits: "",
  PrincipalBalance: 8208.56,
  InterestBalance: 0.0,
  OtherBalance: 0.0,
  BalanceOutstanding: 8208.56,
  WithheldInterestBalance: 0.0,
  AmountDue: 8208.56,
  PayoutAmount: 8208.56,
  InterestRate: 2.83,
  InterestRaisedUpTo: "",
  MaximumInterestAmount: "",
  NextInterestDate: "",
  WithholdUntil: "",
  SchemeNumber: 32049,
  SchemeId: 26,
  Name: "Wheeler Road Maryknoll Special Charge Scheme",
  SchemesType: "Private Streets Scheme",
  Initiated: "28-Jul-2017",
  EstimatedCost: 127709.18,
  Declared: "",
  Subsidy: "",
  Commenced: "",
  CostToAssessments: 127709.18,
  Comment: "",
  Type: "Fixed Instalment of varying Principal & Actual Interest Raised",
  InstalmentAmount: "",
  NumberOfInstalments: 28,
  NextInstalmentDate: "",
  LastInstalmentNo: 0,
  TakeOverpaymentsOffPrincipalBalance: true,
};
