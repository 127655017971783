import { dataOptionDecision } from "@app/products/town-planning/ppr/components/action-bar/dialogs/approve-letter-application/components/dialogs/decision/config";
import {
  IDecision,
  OPTION_DECISION,
} from "@app/products/town-planning/ppr/components/action-bar/dialogs/approve-letter-application/components/dialogs/decision/model";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { Ref } from "react";

interface IDecisionDialogProps {
  onClose: () => void;
  onSubmitData: (infoInadequate: boolean) => void;
  isLoading: boolean;
  notificationRef: Ref<ICCLocalNotificationHandle>;
}
const nameOf = nameOfFactory<IDecision>();
export const DecisionDialog = observer(
  ({
    onClose,
    onSubmitData,
    isLoading,
    notificationRef,
  }: IDecisionDialogProps) => {
    const handleOnSubmitClick = async (event: FormSubmitClickEvent) => {
      let infoInadequate: boolean = false;
      if (event.values[OPTION_DECISION.Option] === OPTION_DECISION.Inadequate) {
        infoInadequate = true;
      }
      onSubmitData(infoInadequate);
    };

    return (
      <Form
        onSubmitClick={handleOnSubmitClick}
        initialValues={{ Option: "Adequate" }}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                titleHeader={"Decision"}
                onClose={onClose}
                maxWidth={"20%"}
                height={"auto"}
                disabled={isLoading}
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <section className="cc-field-group">
                      <label className="cc-label">Submitted plans are</label>
                      <div className="cc-custom-sub-panel-bar">
                        <Field
                          name={nameOf("Option")}
                          component={RadioGroup}
                          data={dataOptionDecision}
                        />
                      </div>
                    </section>
                  </div>
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button className="cc-dialog-button" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                      onClick={onSubmit}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
