import { IMarkAsComplete } from "@app/products/property/fire-prevention/current/record-work-complete/components/dialogs/mark-as-complete/model";

export const mockMarkAsComplete: IMarkAsComplete = {
  NotificationDate: new Date(),
  DateCleared: null,
  DefaultComment: null,
  DefaultCommentData: [
    "Normal Clearance no Issues",
    "Not Cleared. Unable to access Property",
    "Cleared prior to contractor arriving",
    "Not property cleared due to dangerous ground",
    "Not cleared occupant issued threats",
  ],
  ActualComment: null,
};
