import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { NewCSLButton } from "@app/products/csl/components/action-bar/buttons/new-csl/_index";
import { CSLActionBarNavDropdown } from "@app/products/csl/components/action-bar/nav-dropdown/_index";
import { colCSLFeesOSFees } from "@app/products/csl/fees/os-fees/config";
import { CSLFeesOSFeesBookmark } from "@app/products/csl/fees/os-fees/util";
import { feesRoute } from "@app/products/csl/fees/route";
import { CSLBookmark } from "@app/products/csl/util";
import { loadAllCSL } from "@app/products/csl/[id]/api";
import { CSLContactTab } from "@app/products/csl/[id]/components/reference-sidebar/contact/_index";
import { CSLDetailTab } from "@app/products/csl/[id]/components/reference-sidebar/detail/_index";
import { CSLHistoryTab } from "@app/products/csl/[id]/components/reference-sidebar/history/_index";
import { CSL_ROUTE } from "@app/products/csl/[id]/constant";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

export default () => {
  useCCListViewActionBar({
    title: feesRoute.name,
    leftComponents: [<CSLActionBarNavDropdown category={feesRoute.path} />],
    centerComponents: [
      <NewCSLButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CSM}
        dataSetFilter={MAILMERGEDATASET.CSM_Fees}
        recordType={RECORDTYPE.CSM_Licence}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CSM}
        recordType={RECORDTYPE.CSM_Licence}
        keyField={"ID"}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CSL_ROUTE}
        productType={PRODUCT_TYPE.CSM}
        //TODO don't have exactly record type, temporary use CSM_Licence
        recordType={RECORDTYPE.CSM_Licence}
        detail={CSLBookmark.getBookmarkDetail}
        displayName={CSLBookmark.getBookmarkDisplayName}
        listViewDetail={CSLFeesOSFeesBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          CSLFeesOSFeesBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CSLDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <CSLContactTab /> },
      {
        title: "History",
        component: <CSLHistoryTab />,
      },
    ],
  });
  return (
    <CCProductListView
      columnFields={colCSLFeesOSFees}
      data={loadAllCSL()}
      state={{ sort: [{ field: "ReferenceNumber", dir: "desc" }] }}
      /*  dataUrl={CSLUrl.GET_VIEW_CSL_FEES_OSFEES} */
      primaryField="ID"
    />
  );
};
