import {
  ISendFromAddresses,
  MailMergeIdentifier,
} from "@app/core/communication/dialogs/components/form-elememts/to/model";
import { COMMUNICATION_API_ROUTE } from "@app/core/communication/dialogs/constant";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { globalStoreInstance } from "@common/stores/global/store";

export const getCommunicationSetup = async (
  query: string,
  productType: PRODUCT_TYPE_NUMBER,
  recordType: RECORDTYPE,
  recipientTypes: string | null
): Promise<APIResponse> => {
  const recipientTypesParam = recipientTypes
    ? `&recipientTypes=${recipientTypes}`
    : "";
  try {
    return await CoreAPIService.getClient().get(
      `${globalStoreInstance.allSetting.baseAPIUrl}${COMMUNICATION_API_ROUTE}/setup?ids=${query}&productType=${productType}&recordType=${recordType}${recipientTypesParam}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSendFromAddresses = async (
  identifier: MailMergeIdentifier
): Promise<APIResponse<ISendFromAddresses | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<ISendFromAddresses>(
      "/api/core/internal/communication/sendFromAddresses",
      identifier
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
