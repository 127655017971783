import { VO_Assessment_w_Detail } from "@app/products/property/assessments/list/model";
import { VO_Title } from "@app/products/property/titles/list/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfTitle = nameOfFactory<VO_Title>();
export const colFoliosAssessment: IColumnFields[] = [
  {
    field: nameOfTitle("Reference"),
    title: "Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfTitle("Legal_Description"),
    title: "Legal Description",
  },
  {
    field: nameOfTitle("Status"),
    title: "Status",
  },
  {
    field: nameOfTitle("Lot"),
    title: "Lot",
  },
  {
    field: nameOfTitle("Factor"),
    title: "Factor %",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOfTitle("PlanType"),
    title: "Plan Type",
  },
  {
    field: nameOfTitle("Plan_Number"),
    title: "Plan Number",
  },
  {
    field: nameOfTitle("Section"),
    title: "Section",
  },
  {
    field: nameOfTitle("Title_Part_Lot_Number"),
    title: "Part Lot",
  },
  {
    field: nameOfTitle("Address"),
    title: "Name Address",
  },
  {
    field: nameOfTitle("Volume"),
    title: "Volume",
  },
  {
    field: nameOfTitle("Folio"),
    title: "Folio",
  },
  {
    field: nameOfTitle("County"),
    title: "County",
  },
  {
    field: nameOfTitle("ParishName"),
    title: "Parish",
  },
  {
    field: nameOfTitle("Division_Date"),
    title: "Division Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfTitle("Frontage"),
    title: "Frontage",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOfTitle("Land_Area"),
    title: "Land Area (m2)",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfTitle("Is_Area_Surveyed"),
    title: "Is Area Surveyed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfTitle("CoverageName"),
    title: "Coverage",
  },
  {
    field: nameOfTitle("Title_Id"),
    title: "Title Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

const nameOfHolding = nameOfFactory<VO_Assessment_w_Detail>();
export const colHoldingAmalgamate: IColumnFields[] = [
  {
    field: nameOfHolding("Assessment_Reference"),
    title: "Number",
  },
  {
    field: nameOfHolding("Assessment_Number_Formatted"),
    title: "Number",
  },
  {
    field: nameOfHolding("Property_Name"),
    title: "Property Name",
  },
  {
    field: nameOfHolding("Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOfHolding("Property_Locality"),
    title: "Property Locality",
  },
  {
    field: nameOfHolding("Property_Location_Descriptor"),
    title: "Property Location",
  },
  { field: nameOfHolding("Legal_Description"), title: "Legal Description" },
  { field: nameOfHolding("Improvements"), title: "Improvements" },
  {
    field: nameOfHolding("Primary_Land_Use_Name"),
    title: "Primary Land Use",
  },
  { field: nameOfHolding("Assessment_Type_Name"), title: "Type" },
  { field: nameOfHolding("Assessment_Status_Name"), title: "Status" },
  { field: nameOfHolding("Owners_Name"), title: "Owners Name" },
  { field: nameOfHolding("Owners_State"), title: "Owners State" },
  { field: nameOfHolding("Owners_Address"), title: "Owners Address" },
  { field: nameOfHolding("Owners_Locality"), title: "Owners Locality" },
  {
    field: nameOfHolding("Owners_Name_Address"),
    title: "Owners Name Address",
  },
  { field: nameOfHolding("Owners_Postcode"), title: "Owners Postcode" },
  {
    field: nameOfHolding("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
