import {
  ConditionReference,
  Svc_ConditionReference_Group,
  Svc_ConditionReference_Lovs,
  Svc_Form_ConditionReference,
} from "@app/core/condition-reference/[id]/model";
import {
  nameOfConditionReference,
  nameOfConditionReferenceOption,
  nameOfSvcConditionReference,
  TCCOptionsConditionReference,
} from "@app/core/condition-reference/[id]/util";
import "@app/products/animals/components/forms/components/form-element/_index.scss";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboxSearchLov } from "@components/cc-combox-search-lov/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FormElement } from "@progress/kendo-react-form";
import { isNil, mapKeys } from "lodash";
import React from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export const ConditionReferenceFormElement = () => {
  //#region STORE ========/
  const { dataForms, updateFormFields, getFormFields } = useFlexibleFormStore();
  const { settings } = useCommonCoreStore();
  //#endregion STORE =====/

  //#region Settings ========/
  const HealthManager_DisplayConditionsInGroup = getStringValueSetting(
    settings[
      ECorporateSettingsField
        .HealthManager_Views_Conditions_DisplayConditionsInGroup
    ]
  );

  const isHideHelpText =
    dataForms?.ParentSection?.productType ===
      PRODUCT_TYPE_NUMBER.HealthManager &&
    HealthManager_DisplayConditionsInGroup !== null &&
    HealthManager_DisplayConditionsInGroup !== "1";

  const isShowUsedOn = dataForms?.GeneralForm.UsedOn > 0;

  const isShowFieldSelection = !isNil(dataForms?.ParentSection?.productType);
  //#endregion Settings =====/

  // Get Value Field & Options Condition Reference
  const conditionReference = {
    value: (item: keyof ConditionReference) =>
      getFormFields("GeneralForm", nameOfConditionReference(item)),

    valueSvc: (item: keyof Svc_Form_ConditionReference) =>
      getFormFields("GeneralForm", nameOfSvcConditionReference(item)),

    change: (dataUpdateObject: Partial<ConditionReference>) => {
      const dataUpdateObjectWithNameOf = mapKeys(
        dataUpdateObject,
        (_value, key) =>
          nameOfConditionReference(key as keyof ConditionReference)
      );
      updateFormFields("GeneralForm", dataUpdateObjectWithNameOf);
    },

    option: (item: keyof TCCOptionsConditionReference) =>
      getFormFields("GeneralForm", nameOfConditionReferenceOption(item)),

    changeOption: (dataUpdateObject: Partial<TCCOptionsConditionReference>) => {
      const dataUpdateObjectWithNameOf = mapKeys(
        dataUpdateObject,
        (_value, key) =>
          nameOfConditionReferenceOption(
            key as keyof TCCOptionsConditionReference
          )
      );
      updateFormFields("GeneralForm", dataUpdateObjectWithNameOf);
    },
  };

  // Get LOVs
  const conditionReferenceLovs =
    dataForms?.GeneralFormLovs as Svc_ConditionReference_Lovs;

  useEffectOnce(() => {
    if (!conditionReference.option("lovDataGroupOption")) {
      conditionReference.changeOption({
        lovDataGroupOption: conditionReferenceLovs?.Groups ?? [],
      });
    }
    conditionReference.change({
      ProductType_ENUM: dataForms?.ParentSection?.productType,
    });
  });

  const handleAddNewGroup = (searchKey: string) => {
    const groupIsExisted = conditionReferenceLovs?.Groups?.some(
      (item: Svc_ConditionReference_Group) => item.Group === searchKey
    );
    if (!groupIsExisted) {
      conditionReferenceLovs?.Groups.push({
        Group: searchKey,
        ProductType_ENUM: null,
      } as Svc_ConditionReference_Group);
      conditionReference.changeOption({
        lovDataGroupOption: conditionReferenceLovs?.Groups ?? [],
      });
    }
  };

  const handleAddField = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const fieldSelectionOption = conditionReference.option(
      "fieldSelectionOption"
    );
    const condition = conditionReference.value("Condition");
    const newCondition = `${condition}[${fieldSelectionOption?.Value}]`;
    conditionReference.change({
      Condition: newCondition,
    });
  };

  return (
    <FormElement>
      {!isHideHelpText && (
        <>
          <section className="cc-field-group">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCValueField
                  value={
                    "- To create a new line within a condition or clause use [NEWLINE]"
                  }
                  className="cc-text-info"
                />
                <CCValueField
                  value={
                    "- To sort the group, enter the sort number between two pipes e.g. |1|GROUP NAME"
                  }
                  className="cc-text-info"
                />
              </div>
            </div>
          </section>

          <hr className="cc-divider" />
        </>
      )}

      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Sort order" isMandatory />
            <Field
              name={nameOfConditionReference("SortIndex")}
              component={CCNumericTextBox}
              placeholder="Sort order"
              validator={requiredValidator}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Group" />
            <Field
              component={CCComboxSearchLov}
              name={nameOfConditionReference("Group")}
              dataLov={conditionReference.option("lovDataGroupOption") ?? []}
              textField={"Group"}
              dataItemKey={"Group"}
              valueField={conditionReference.value("Group")}
              onChange={(data: Svc_ConditionReference_Group) => {
                conditionReference.change({
                  Group: data?.Group,
                });
              }}
              isShowNewFooter
              onChangeNewButton={handleAddNewGroup}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Title" isMandatory />
            <Field
              name={nameOfConditionReference("Title")}
              component={CCInput}
              placeholder="Title"
              validator={requiredValidator}
            />
          </div>
        </div>
      </section>

      <hr className="cc-divider" />

      <section className="cc-field-group">
        {isShowFieldSelection && (
          <div className="cc-form-cols-3">
            <div className="cc-field-selection cc-field">
              <div>
                <CCLabel title="Field selection" />
                <Field
                  name={nameOfConditionReferenceOption("fieldSelectionOption")}
                  textField="Value"
                  dataItemKey="Key"
                  data={conditionReferenceLovs.FieldSelection ?? []}
                  component={CCSearchComboBox}
                />
              </div>
              <div>
                <CCLabel title="&nbsp;" />
                <Button onClick={handleAddField}>Add Field</Button>
              </div>
            </div>
          </div>
        )}

        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Condition" />
            <Field
              name={nameOfConditionReference("Condition")}
              placeholder="Condition"
              rows={6}
              component={CCTextArea}
            />
          </div>
        </div>
      </section>

      {isShowUsedOn && (
        <div>
          <hr className="cc-divider" />
          <CCValueField
            name={nameOfSvcConditionReference("UsedOn")}
            label="Used On"
            value={conditionReference.valueSvc("UsedOn")}
          />

          {/* <Button // TODO: need to implement this feature later
            onClick={handleUpdateInUseConditions}
          >
            Update Conditions in Use
          </Button> */}
        </div>
      )}
    </FormElement>
  );
};
